export class ContactTypes {
    public static readonly SOLICITOR : string = "SOLICITOR";
    public static readonly LAW_FIRM : string = "LAW_FIRM";
    public static readonly SURVEYOR : string = "SURVEYOR";
    public static readonly REALESTATEBROKER : string = "REALESTATEBROKER";
    public static readonly MORTGAGE_BROKER : string = "MORTGAGE_BROKER";
    public static readonly RESIDENCE_ASSOCIATION : string = "RESIDENCE_ASSOCIATION";
    public static readonly MANAGEMENT_COMPANY : string = "MANAGEMENT_COMPANY";
    public static readonly MORTGAGEE : string = "MORTGAGEE";
    public static readonly CONDO_CORPORATION : string = "CONDO_CORPORATION";
    public static readonly INSURANCE_BROKER : string = "INSURANCE_BROKER";
}

export class ContactKeys {
    public static readonly LAWYER : string = "LAWYER";
    public static readonly LAW_FIRM : string = "LAW_FIRM";
    public static readonly SURVEYOR : string = "SURVEYOR";
    public static readonly REALESTATEBROKER : string = "REALESTATEBROKER";
    public static readonly MORTGAGE_BROKER : string = "MORTGAGE_BROKER";
    public static readonly RESIDENCE_ASSOCIATION : string = "RESIDENCE_ASSOCIATION";
    public static readonly MANAGEMENT_COMPANY : string = "MANAGEMENT_COMPANY";
    public static readonly MORTGAGEE : string = "MORTGAGEE";
    public static readonly CONDO_CORPORATION : string = "CONDO_CORPORATION";
    public static readonly INSURANCE_BROKER : string = "INSURANCE_BROKER";
    public static readonly JURISDICTION : string = "JURISDICTION";
    public static readonly DEPARTMENT : string = "DEPARTMENT";
}

export class InstanceTypes {
    public static readonly organization : string = "organization";
    public static readonly legalFirm : string = "legalFirm";
    public static readonly solicitor : string = "solicitor";
}
//TODO remove hard code for Gender Type
export class GenderTypes {
    public static readonly QUESTION : string = 'QUESTION';
    public static readonly MALE : string = 'MALE';
    public static readonly FEMALE : string = 'FEMALE';
    public static readonly MALEPOA : string = 'MALEPOA';
    public static readonly FEMALEPOA : string = 'FEMALEPOA';
    public static readonly ESTATE : string = 'ESTATE';
    public static readonly OTHERENTITY : string = 'OTHERENTITY';
    public static readonly CORPORATION : string = 'CORPORATION';

    public static readonly ALL: string[] = [this.QUESTION, this.MALE, this.FEMALE, this.MALEPOA, this.FEMALEPOA, this.ESTATE, this.OTHERENTITY, this.CORPORATION];
}

export const contactTypeMapping = {

    //This array gives the mapping between contact type, instance type & Gender. As one contact key can be mapped to different contact type (or instance
    // type) based on the selected gender. Currently this array contains only those contact keys which can be further segregated based on gender, for example
    // guarantor can be categorized into guarantor_person or guarantor_organization depending upon the selected gender. It is used to update the contact
    // type on gender change.
    GENDER_CONTACT_TYPE_MAPPING : [
        {
            contactKey : 'GUARANTOR',
            contactTypeEnum: 'GUARANTOR_PERSON',
            instanceType : 'person' ,
            GENDER: ['MALE','FEMALE','QUESTION','MALEPOA','FEMALEPOA','ESTATE']
        },
        {
            contactKey : 'GUARANTOR',
            contactTypeEnum: 'GUARANTOR_ORGANIZATION',
            instanceType : InstanceTypes.organization ,
            GENDER: ['OTHERENTITY','CORPORATION']
        },
        {
            contactKey : 'TRANSFEROR',
            contactTypeEnum: 'TRANSFEROR_PERSON',
            instanceType : 'person' ,
            GENDER: ['MALE','FEMALE','QUESTION','MALEPOA','FEMALEPOA','ESTATE']
        },
        {
            contactKey : 'TRANSFEROR',
            contactTypeEnum: 'TRANSFEROR_ORGANIZATION',
            instanceType : InstanceTypes.organization ,
            GENDER: ['OTHERENTITY','CORPORATION']
        },
        // {
        //     contactKey : 'OTHER_PARTY',
        //     contactTypeEnum: 'OTHER_PARTY_PERSON',
        //     instanceType : 'person' ,
        //     GENDER: ['MALE','FEMALE','QUESTION','MALEPOA','FEMALEPOA','ESTATE']
        // },
        // {
        //     contactKey : 'OTHER_PARTY',
        //     contactTypeEnum: 'OTHER_PARTY_ORGANIZATION',
        //     instanceType : InstanceTypes.organization ,
        //     GENDER: ['OTHERENTITY','CORPORATION' ,'DEPARTMENT']
        // },
        {
            contactKey : 'PRIVATE_LENDER', //Private lender has been replaced with client, therefore mapping it to client's contactType
            contactTypeEnum: 'PERSON',
            instanceType : 'person' ,
            GENDER: ['MALE','FEMALE','QUESTION','MALEPOA','FEMALEPOA','ESTATE']
        },
        {
            contactKey : 'PRIVATE_LENDER', //Private lender has been replaced with client, therefore mapping it to client's contactType
            contactTypeEnum: 'ORGANIZATION',
            instanceType : InstanceTypes.organization ,
            GENDER: ['OTHERENTITY','CORPORATION']
        },
        {
            contactKey : 'CLIENT',
            contactTypeEnum: 'PERSON',
            instanceType : 'person' ,
            GENDER: ['MALE','FEMALE','QUESTION','MALEPOA','FEMALEPOA','ESTATE']
        },
        {
            contactKey : 'CLIENT',
            contactTypeEnum: 'ORGANIZATION',
            instanceType : InstanceTypes.organization ,
            GENDER: ['OTHERENTITY','CORPORATION']
        },
        {
            contactKey : 'CONDO_CORPORATION',
            contactTypeEnum: 'ORGANIZATION',
            instanceType : InstanceTypes.organization ,
            GENDER: ['CORPORATION']
        },
        {
            contactKey : ContactKeys.MANAGEMENT_COMPANY,
            contactTypeEnum: 'ORGANIZATION',
            instanceType : InstanceTypes.organization ,
            GENDER: ['CORPORATION']
        },
        {
            contactKey : 'PRIVATE_CONTACT',
            contactTypeEnum: 'PRIVATE_CONTACT_ORGANIZATION',
            instanceType : InstanceTypes.organization ,
            GENDER: ['DEPARTMENT']
        },
        {
            contactKey : 'PRIVATE_CONTACT',
            contactTypeEnum: 'PRIVATE_CONTACT',
            instanceType : 'person' ,
            GENDER: ['MALE','FEMALE','QUESTION']
        }
    ],

    /// Common Fields are not included in this list because they are across all tables, those include: Checkbox cells, burgermenu cells, last updated cells.
    CONTACTTABLEDATA : [
        {
            contactKey : 'CLIENT' ,
            tableHeaders : [ { headerName : 'NAME' , headerClass : 'cl-tc-width-name-small1'},
                             { headerName : 'CATEGORY' , headerClass : 'cl-tc-width-category'},
                             { headerName : 'PHONE' , headerClass : 'cl-tc-width-phone'},
                             { headerName : 'ADDRESS' , headerClass : 'cl-tc-width-address-large2'},
                             { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xxsmall'},
                             { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated'}]
        },{
            contactKey : 'CONDO_CORPORATION',
            tableHeaders : [{ headerName : 'NAME', headerClass : 'cl-tc-width-name-small'} ,
                            { headerName : 'NUMBER', headerClass : 'cl-tc-width-number'},
                            { headerName : 'ABBREVIATED_NAME', headerClass : 'cl-tc-width-abb-name'},
                            { headerName : 'ATTENTION', headerClass : 'cl-tc-width-attn'},
                            { headerName : 'PHONE', headerClass : 'cl-tc-width-phone-small'},
                            { headerName : 'ADDRESS', headerClass : 'cl-tc-width-address-small'},
                            { headerName : 'PROVINCE', headerClass : 'cl-tc-width-province'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xsmall'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated-small'}]
        },
        {
            contactKey : ContactKeys.INSURANCE_BROKER,
            tableHeaders : [{ headerName : 'PRIVATE_GLOBAL', headerClass : 'cl-tc-width-name-small1'},
                            { headerName : 'INSURER_BROKER', headerClass : 'cl-tc-width-insurers'},
                            { headerName : 'PHONE', headerClass : 'cl-tc-width-phone'},
                            { headerName : 'ADDRESS', headerClass : 'cl-tc-width-address'},
                            { headerName : 'PROVINCE', headerClass : 'cl-tc-width-province'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xxsmall'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated-small'}]
        },
        {
            contactKey : ContactKeys.MANAGEMENT_COMPANY,
            tableHeaders : [{ headerName : 'PRIVATE_GLOBAL', headerClass : 'cl-tc-width-name-small1'} ,
                            { headerName : 'ATTENTION', headerClass : 'cl-tc-width-attn'},
                            { headerName : 'PHONE', headerClass : 'cl-tc-width-phone-med'},
                            { headerName : 'ADDRESS', headerClass : 'cl-tc-width-address'},
                            { headerName : 'PROVINCE', headerClass : 'cl-tc-width-province'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xxsmall'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated-small'}]
        },
        {
            contactKey : 'MORTGAGE_BROKER',
            tableHeaders : [{ headerName : 'PRIVATE_GLOBAL', headerClass : 'cl-tc-width-name'} ,
                            { headerName : 'ATTENTION', headerClass : 'cl-tc-width-attn'},
                            { headerName : 'PHONE', headerClass : 'cl-tc-width-phone'},
                            { headerName : 'ADDRESS', headerClass : 'cl-tc-width-address-med'},
                            { headerName : 'PROVINCE', headerClass : 'cl-tc-width-province-xs-small'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xsmall'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated-small'}]
        },
        {
            contactKey : 'RESIDENCE_ASSOCIATION',
            tableHeaders : [{ headerName : 'NAME', headerClass : 'cl-tc-width-name-small'} ,
                            { headerName : 'DESCRIPTION', headerClass : 'cl-tc-width-description'},
                            { headerName : 'INSTRUMENT_NO', headerClass : 'cl-tc-width-instrumentno'},
                            { headerName : 'FEE_AMOUNT', headerClass : 'cl-tc-width-feeamount'},
                            { headerName : 'FISCAL_YEAR_END', headerClass : 'cl-tc-width-lastupdated-small'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xsmall'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated-small'}]
        },
        {
            contactKey : ContactKeys.MORTGAGEE,
            tableHeaders : [{ headerName : 'PRIVATE_GLOBAL', headerClass : 'cl-tc-width-name-small'} ,
                            { headerName : 'MORTGAGES_ACCEPTED_TYPE', headerClass : 'cl-tc-width-name-small'} ,
                            { headerName : 'ATTENTION', headerClass : 'cl-tc-width-attn'},
                            { headerName : 'ALTERNATE_NAME', headerClass : 'cl-tc-width-alternate'},
                            { headerName : 'BRANCH', headerClass : 'cl-tc-width-branch'},
                            { headerName : 'TRANSIT_NO', headerClass : 'cl-tc-width-transit'},
                            { headerName : 'ADDRESS', headerClass : 'cl-tc-width-address-small'},
                            { headerName : 'PROVINCE', headerClass : 'cl-tc-width-province-small'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xxsmall'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated-small'}]
        },
        // {
        //     contactKey : 'OTHER_PARTY',
        //     tableHeaders : [{ headerName : 'NAME', headerClass : 'cl-tc-width-name'} ,{ headerName : 'PHONE', headerClass : 'cl-tc-width-phone-large'},{ headerName : 'STATUS', headerClass : 'cl-tc-width-status'},{ headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated-large'}]
        // },
        {
            contactKey : 'PRIVATE_LENDER',
            tableHeaders : [{ headerName : 'NAME', headerClass : 'cl-tc-width-name'},
                { headerName : 'PHONE', headerClass : 'cl-tc-width-phone'},
                { headerName : 'ADDRESS', headerClass : 'cl-tc-width-address'},
                { headerName : 'STATUS', headerClass : 'cl-tc-width-status'},
                { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated'}]
        },
        {
            contactKey : 'REALESTATEAGENT',
            tableHeaders : [{ headerName : 'NAME_OF_AGENT', headerClass : 'cl-tc-width-name'},
                            { headerName : 'PHONE', headerClass : 'cl-tc-width-phone-large'},
                            { headerName : 'EMAIL', headerClass : 'cl-tc-width-email-large'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-med'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated'}]
        },
        {
            contactKey : ContactKeys.REALESTATEBROKER,
            tableHeaders : [{ headerName : 'PRIVATE_GLOBAL', headerClass : 'cl-tc-width-name-small1'},
                            { headerName : 'PHONE', headerClass : 'cl-tc-width-phone'},
                            { headerName : 'ADDRESS', headerClass : 'cl-tc-width-address-large'},
                            { headerName : 'PROVINCE', headerClass : 'cl-tc-width-province'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xxsmall'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated'}]
        },
        {
            contactKey : 'LAW_FIRM',
            tableHeaders : [{ headerName : 'PRIVATE_GLOBAL', headerClass : 'cl-tc-width-name'},
                            { headerName : 'ADDRESS', headerClass : 'cl-tc-width-address-large2'},
                            { headerName : 'PHONE', headerClass : 'cl-tc-width-phone'},
                            { headerName : 'PROVINCE', headerClass : 'cl-tc-width-province'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xsmall'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated-small'}]
        },

        {
            contactKey : 'SOLICITOR',
            tableHeaders : [{ headerName : 'PRIVATE_GLOBAL', headerClass : 'cl-tc-width-name'}  ,
                            { headerName : 'LAW_FIRM_NAME', headerClass : 'cl-tc-width-law-firm-name'},
                            { headerName : 'ADDRESS', headerClass : 'cl-tc-width-address-small'},
                            { headerName : 'PHONE', headerClass : 'cl-tc-width-phone-medsmall'},
                            { headerName : 'PROVINCE', headerClass : 'cl-tc-width-province-med'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xsmall'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated-small'}]
        },
        {
            contactKey : 'SURVEYOR',
            tableHeaders : [{ headerName : 'PRIVATE_GLOBAL', headerClass : 'cl-tc-width-name-small1'}  ,
                            { headerName : 'ATTENTION', headerClass : 'cl-tc-width-attn'} ,
                            { headerName : 'PHONE', headerClass : 'cl-tc-width-phone'} ,
                            { headerName : 'ADDRESS', headerClass : 'cl-tc-width-address-med'} ,
                            { headerName : 'PROVINCE', headerClass : 'cl-tc-width-province'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xxsmall'},
                            { headerName : 'LAST_UPDATED' , headerClass : 'cl-tc-width-lastupdated-small'} ]
        },
        {
            contactKey : 'JURISDICTION',
            tableHeaders : [{ headerName : 'PRIVATE_GLOBAL', headerClass : 'cl-tc-width-name'},
                            { headerName : 'PROVINCE', headerClass : 'cl-tc-width-province-xs-small'},
                            { headerName : 'STATUS', headerClass : 'cl-tc-width-status-xxsmall'} ]
        }

    ],

    //If there are multiple contact types then add default one in below mapping
    CONTACTTYPES : [

        {
            contactKey : 'LAW_FIRM' ,
            contactType : 'LAW_FIRM',
            instanceType : 'legalFirm' ,
            contactListApiType : 'LAW_FIRM' ,
            label : 'Law Firm' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'LAW_FIRM',
            genders: [{label : 'LAW_FIRM', value : 'LAW_FIRM'}],
            localizedLabels : {
                'BC': 'Law/Notary Firm'
            }
        },
        {
            contactKey : 'LAWCLERK' ,
            contactType : 'LAWCLERK',
            instanceType : 'solicitor' ,
            contactListApiType : 'LAWCLERK' ,
            label : 'Law Clerk' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : ' ', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'}
            ]
        },
        {
            contactKey : 'OTHER' ,
            contactType : 'OTHER',
            instanceType : 'solicitor' ,
            contactListApiType : 'OTHER' ,
            label : 'Other' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : ' ', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'}
            ]
        },
        {
            contactKey : 'LAWYER' ,
            contactType : 'SOLICITOR',
            instanceType : 'solicitor' ,
            contactListApiType : 'SOLICITOR' ,
            label : 'Lawyer' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : ' ', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'}
            ]
        },
        {
            contactKey : ContactKeys.REALESTATEBROKER ,
            contactType : ContactTypes.REALESTATEBROKER,
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'REALESTATEBROKER' ,
            label : 'Real Estate Broker' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : 'Corporation', value : 'CORPORATION'}
            ]
        },
        {
            contactKey : 'REALESTATEAGENT' ,
            contactType : 'REALESTATEAGENT',
            instanceType : 'person' ,
            contactListApiType : 'REALESTATEAGENT' ,
            label : 'Real Estate Agent' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : ' ', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'}
            ]
        },
        {
            contactKey : ContactKeys.MORTGAGEE ,
            contactType : ContactTypes.MORTGAGEE,
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'MORTGAGEE' ,
            label : 'Mortgagee' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : 'Corporation', value : 'CORPORATION'}
            ]
        },
        {
            contactKey : 'MORTGAGE_BROKER' ,
            contactType : 'MORTGAGE_BROKER',
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'MORTGAGE_BROKER' ,
            label : 'Mortgage Broker' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : 'Corporation', value : 'CORPORATION'}
            ]
        },
        {
            contactKey : 'RESIDENCE_ASSOCIATION' ,
            contactType : 'RESIDENCE_ASSOCIATION',
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'RESIDENCE_ASSOCIATION' ,
            label : 'Residents\' Associations' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : 'Corporation', value : 'CORPORATION'}
            ]
        },
        {
            contactKey : ContactKeys.INSURANCE_BROKER ,
            contactType : ContactTypes.INSURANCE_BROKER,
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'INSURANCE_BROKER' ,
            label : 'Insurance Company' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : 'Corporation', value : 'CORPORATION'}
            ]
        },
        {
            contactKey : 'PRIVATE_CONTACT' ,
            contactType : 'PRIVATE_CONTACT',
            instanceType : 'person' ,
            contactListApiType : 'PRIVATE_CONTACT,PRIVATE_CONTACT_ORGANIZATION' ,
            label : 'Attention' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : ' ', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'},
                      {label : 'Department', value : 'DEPARTMENT'}
            ]
        },
        {
            contactKey : 'DIRECTOR_OFFICER' ,
            contactType : 'DIRECTOR_OFFICER',
            instanceType : 'person' ,
            contactListApiType : 'DIRECTOR_OFFICER' ,
            label : 'Attention' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : ' ', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'}
            ]
        },
        {
            contactKey : 'CLIENT' ,
            contactType : 'PERSON',
            instanceType : 'person' ,
            contactListApiType : 'PERSON' ,
            label : 'Client' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : '', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'},
                      {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
                      {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
                      {label:  'Estate', value: 'ESTATE' },
                      {label : 'Corporation', value : 'CORPORATION'},
                      {label : 'Other Entity', value : 'OTHERENTITY'}
            ],
        },
        {   //ToDo: we should not have contactKey : 'PERSON' because there is no such contact type exposed in application
            contactKey : 'PERSON' ,
            contactType : 'PERSON',
            instanceType : 'person' ,
            contactListApiType : 'PERSON' ,
            label : 'PERSON' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : '', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'},
                      {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
                      {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
                      {label:  'Estate', value: 'ESTATE' },
                      {label : 'Corporation', value : 'CORPORATION'},
                      {label : 'Other Entity', value : 'OTHERENTITY'}
            ]
        },
        {   //ToDo: we should not have contactKey : 'ORGANIZATION' because there is no such contact type exposed in application
            contactKey : 'ORGANIZATION' ,
            contactType : 'ORGANIZATION',
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'ORGANIZATION' ,
            label : 'ORGANIZATION' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : '', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'},
                      {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
                      {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
                      {label:  'Estate', value: 'ESTATE' },
                      {label : 'Corporation', value : 'CORPORATION'},
                      {label : 'Other Entity', value : 'OTHERENTITY'}
            ]
        },
        {
            contactKey : 'GUARANTOR' ,
            contactType : 'GUARANTOR_PERSON',
            instanceType : 'person' ,
            contactListApiType : 'GUARANTOR_PERSON,GUARANTOR_ORGANIZATION' ,
            label : 'Guarantor' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : '', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'},
                      {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
                      {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
                      {label:  'Estate', value: 'ESTATE' },
                      {label : 'Corporation', value : 'CORPORATION'},
                      {label : 'Other Entity', value : 'OTHERENTITY'}
            ]
        },
        {
            contactKey : 'TRANSFEROR' ,
            contactType : 'TRANSFEROR_PERSON',
            instanceType : 'person' ,
            contactListApiType : 'TRANSFEROR_PERSON,TRANSFEROR_ORGANIZATION' ,
            label : 'Transferor' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : '', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'},
                      {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
                      {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
                      {label:  'Estate', value: 'ESTATE' },
                      {label : 'Corporation', value : 'CORPORATION'},
                      {label : 'Other Entity', value : 'OTHERENTITY'}
            ]
        },
        // {
        //     contactKey : 'OTHER_PARTY' ,
        //     contactType : 'OTHER_PARTY_PERSON',
        //     instanceType : 'person' ,
        //     contactListApiType : 'OTHER_PARTY_PERSON,OTHER_PARTY_ORGANIZATION' ,
        //     label : 'Other Party' ,
        //     contactListReturnType :  'Contacts',
        //     partyRole : 'OTHER_PARTY',
        //     isPrivate : true,
        //     defaultGender: 'QUESTION',
        //     genders: [{label : ' ', value : 'QUESTION'},
        //              {label : 'Male', value : 'MALE'},
        //              {label : 'Female', value : 'FEMALE'},
        //              {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
        //              {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
        //              {label:  'Estate', value: 'ESTATE' },
        //              {label : 'Corporation', value : 'CORPORATION'},
        //              {label : 'Other Entity', value : 'OTHERENTITY'}
        //     ]
        // },
        // {
        //     contactKey : 'OTHER_PARTY_PERSON' ,
        //     contactType : 'OTHER_PARTY_PERSON',
        //     instanceType : 'person' ,
        //     contactListApiType : 'OTHER_PARTY_PERSON' ,
        //     label : 'Other Party' ,
        //     contactListReturnType :  'Contacts',
        //     partyRole : 'OTHER_PARTY',
        //     isPrivate : true,
        //     defaultGender: 'QUESTION',
        //     genders: [{label : ' ', value : 'QUESTION'},
        //               {label : 'Male', value : 'MALE'},
        //               {label : 'Female', value : 'FEMALE'},
        //               {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
        //               {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
        //               {label:  'Estate', value: 'ESTATE' },
        //               {label : 'Corporation', value : 'CORPORATION'},
        //               {label : 'Other Entity', value : 'OTHERENTITY'}
        //     ]
        // },
        // {
        //     contactKey : 'OTHER_PARTY_ORGANIZATION' ,
        //     contactType : 'OTHER_PARTY_ORGANIZATION',
        //     instanceType : InstanceTypes.organization ,
        //     contactListApiType : 'OTHER_PARTY_ORGANIZATION' ,
        //     label : 'Other Party' ,
        //     contactListReturnType :  'Contacts',
        //     partyRole : 'OTHER_PARTY',
        //     isPrivate : true,
        //     defaultGender: 'QUESTION',
        //     genders: [{label : ' ', value : 'QUESTION'},
        //               {label : 'Male', value : 'MALE'},
        //               {label : 'Female', value : 'FEMALE'},
        //               {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
        //               {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
        //               {label:  'Estate', value: 'ESTATE' },
        //               {label : 'Corporation', value : 'CORPORATION'},
        //               {label : 'Other Entity', value : 'OTHERENTITY'}
        //     ]
        // },
        {
            contactKey : 'SURVEYOR' ,
            contactType : 'SURVEYOR',
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'SURVEYOR' ,
            label : 'Surveyor' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : 'Corporation', value : 'CORPORATION'}
            ]
        },
        {
            contactKey : 'PRIVATE_LENDER' , //Private lender has been replaced with client, therefore mapping it to client's contactType
            contactType : 'PERSON',
            instanceType : 'person' ,
            contactListApiType : 'PERSON' ,
            label : 'PERSON' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : '', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'},
                      {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
                      {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
                      {label:  'Estate', value: 'ESTATE' },
                      {label : 'Corporation', value : 'CORPORATION'},
                      {label : 'Other Entity', value : 'OTHERENTITY'}
            ]
        },
        {
            contactKey : 'PRIVATE_LENDER_PERSON' , //Private lender has been replaced with client, therefore mapping it to client's contactType
            contactType : 'PERSON',
            instanceType : 'person' ,
            contactListApiType : 'PERSON' ,
            label : 'PERSON' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'QUESTION',
            genders: [{label : '', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'},
                      {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
                      {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
                      {label:  'Estate', value: 'ESTATE' },
                      {label : 'Corporation', value : 'CORPORATION'},
                      {label : 'Other Entity', value : 'OTHERENTITY'}
            ]
        },
        {
            contactKey : 'PRIVATE_LENDER_ORGANIZATION' , //Private lender has been replaced with client, therefore mapping it to client's contactType
            contactType : 'ORGANIZATION',
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'ORGANIZATION' ,
            label : 'ORGANIZATION' ,
            contactListReturnType :  'Contacts',
            partyRole : 'CLIENT',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : '', value : 'QUESTION'},
                      {label : 'Male', value : 'MALE'},
                      {label : 'Female', value : 'FEMALE'},
                      {label:  'Male (Under power of attorney)', value: 'MALEPOA' },
                      {label:  'Female (Under power of attorney)', value: 'FEMALEPOA' },
                      {label:  'Estate', value: 'ESTATE' },
                      {label : 'Corporation', value : 'CORPORATION'},
                      {label : 'Other Entity', value : 'OTHERENTITY'}
            ]
        },
        {
            contactKey : ContactKeys.MANAGEMENT_COMPANY ,
            contactType : ContactTypes.MANAGEMENT_COMPANY,
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'MANAGEMENT_COMPANY' ,
            label : 'Management Company' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : 'Corporation', value : 'CORPORATION'}]
        },
        {
            contactKey : 'CONDO_CORPORATION' ,
            contactType : ContactTypes.CONDO_CORPORATION,
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'CONDO_CORPORATION' ,
            label : 'Condominium Corporation' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : 'Corporation', value : 'CORPORATION'}],
            localizedLabels : {
                'BC': 'Strata Corporation'
            }
        },
        {
            contactKey : 'STRATA_CORPORATION' ,
            contactType : ContactTypes.CONDO_CORPORATION,
            instanceType : InstanceTypes.organization ,
            contactListApiType : 'CONDO_CORPORATION' ,
            label : 'Strata Corporation' ,
            contactListReturnType :  'Contacts',
            partyRole : 'OTHER_PARTY',
            isPrivate : true,
            defaultGender: 'CORPORATION',
            genders: [{label : 'Corporation', value : 'CORPORATION'}]
        },
        {
          contactKey : 'WILL_PARTICIPANT' ,
          contactType : 'PRIVATE_CONTACT',
          instanceType : 'person' ,
          contactListApiType : 'OTHER' ,
          label : 'Participant' ,
          contactListReturnType :  'Contacts',
          partyRole : 'CLIENT',
          isPrivate : true,
          defaultGender: 'QUESTION',
          genders: [{label : ' ', value : 'QUESTION'},
                    {label : 'Male', value : 'MALE'},
                    {label : 'Female', value : 'FEMALE'}
          ]
      },
    ],


    CHECK_CONTACT_FIELDS_MAPPING : [
        {
            contactKey      : ContactKeys.INSURANCE_BROKER,
            contactType     : ContactTypes.INSURANCE_BROKER,
            instanceType    : InstanceTypes.organization ,
            checkFields     : ['insuranceBrokerType', 'organizationName', 'MAILING', 'WORK', 'MOBILE', 'FAX', 'email', 'activeFlag']

        },
        {
            contactKey      : ContactKeys.MANAGEMENT_COMPANY ,
            contactType     : ContactTypes.MANAGEMENT_COMPANY,
            instanceType    : InstanceTypes.organization ,
            checkFields     : ['organizationName', 'MAILING', 'WORK', 'MOBILE', 'FAX', 'email', 'activeFlag']
        },
        {
            contactKey      : ContactKeys.MORTGAGE_BROKER ,
            contactType     : ContactTypes.MORTGAGE_BROKER,
            instanceType    : InstanceTypes.organization ,
            checkFields     : ['organizationName', 'MAILING', 'WORK', 'MOBILE', 'FAX', 'email', 'activeFlag']
        },
        {
            contactKey      : ContactKeys.SURVEYOR ,
            contactType     : ContactTypes.SURVEYOR,
            instanceType    : InstanceTypes.organization ,
            checkFields     : ['organizationName', 'MAILING', 'WORK', 'MOBILE', 'FAX', 'email', 'activeFlag']
        },
        {
            contactKey      : ContactKeys.REALESTATEBROKER ,
            contactType     : ContactTypes.REALESTATEBROKER,
            instanceType    : InstanceTypes.organization ,
            checkFields     : ['organizationName', 'MAILING', 'WORK', 'MOBILE', 'FAX', 'email', 'activeFlag']
        },
        {
            contactKey      : ContactKeys.MORTGAGEE ,
            contactType     : ContactTypes.MORTGAGEE,
            instanceType    : InstanceTypes.organization ,
            checkFields     : ['organizationName', 'alternateName', 'institutionNo', 'transitNo', 'branch',
                               'MAILING', 'SERVICE', 'REPORT',
                               'WORK', 'MOBILE', 'FAX', 'email', 'activeFlag']
        },
        {
            contactKey      : ContactKeys.LAW_FIRM ,
            contactType     : ContactTypes.LAW_FIRM,
            instanceType    : InstanceTypes.legalFirm ,
            checkFields     : ['legalFirmName', 'barristerSolicitor', 'MAILING', 'WORK', 'MOBILE', 'FAX', 'email', 'activeFlag']
        },
        {
            contactKey      : ContactKeys.LAWYER ,
            contactType     : ContactTypes.SOLICITOR,
            instanceType    : InstanceTypes.solicitor ,
            checkFields     : ['contactName', 'MAILING', 'WORK', 'MOBILE', 'FAX', 'email', 'activeFlag']
        }
    ],

    CHECK_JURISDICTION_DEPARTMENT_FIELDS_MAPPING : [
        {
            contactKey      : ContactKeys.JURISDICTION,
            checkFields     : ['jurisdictionName', 'comments', 'CITY', 'MUNICIPALITY', 'DEEDTRANSFERTAXRATE', 'REGISTRYOFFICE','activeFlag']
        },
        {
            contactKey      : ContactKeys.DEPARTMENT,
            checkFields     : ['payableToLine1', 'comments', 'phone', 'fax','fee','urlForWebForm','hstRegistrationNum','hst','WRITETO','email']
        }
    ]

};
