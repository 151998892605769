import {SoAdjWithTax} from './so-adj-with-tax';
import Utils from '../../../shared-main/utils';
import {StatementAdjustmentAmountTypes} from '../statement-adjustment-constants';
import {SelectItem} from 'primeng/api';
import {tenancyDropDowns} from './so-adj-drop-downs';

export type  TenantOccupancyType = 'BASEMENT' | 'FIRST_FLOOR' | 'SECOND_FLOOR' | 'THIRD_FLOOR' | 'OTHER';

export class SoAdjTenancyCurrent extends SoAdjWithTax {

    id: number;

    tenantOccupiesEntirePremises: boolean;

    tenantOccupies : TenantOccupancyType;

    areaOccupiedDescription: string;

    rentalAmount: number;

    commencementDate: string;
    endDate: string;

    vendorCollectedRent: boolean;

    get tenantOccupiesEntirePremisesBoolean(): boolean {
        return Utils.convertToBoolean(this.tenantOccupiesEntirePremises);
    }

    get vendorCollectedRentBoolean(): boolean {
        return Utils.convertToBoolean(this.vendorCollectedRent);
    }

    get hstOrGstValueFromRentalAmount(): number{
        return this.getHstOrGstValueForAmount(this.rentalAmount);
    }

    get pstValueFromRentalAmount(): number{
        return this.getPstValueForAmount(this.rentalAmount);

    }

    get rentalAmountPlusTax(): number{
        return + this.rentalAmount +
            this.hstOrGstValueFromRentalAmount +
            this.pstValueFromRentalAmount;
    }

    get paymentPeriodInDays(): number{

        if(this.isNotValidDate(this.commencementDate) || this.isNotValidDate(this.endDate)){
            return 0;
        } else {
            return this.getDateDiff(this.commencementDate, this.endDate) + 1;
        }
    }


    getNumberOfDaysElapsed(closingDate: string, isPaysForDateOfClosingVendor?:boolean): number {
        if(this.isNotValidDate(this.commencementDate) || this.isNotValidDate(closingDate)){
            return 0;
        } else {
            let elapsedDays: number = this.getDateDiff(this.commencementDate, closingDate);
            if(isPaysForDateOfClosingVendor){
                elapsedDays++;
            }
            return elapsedDays;
        }
    }

    getNumberOfDaysElapsedStr(closingDate: string, isPaysForDateOfClosingVendor?:boolean): string {
        let node: number = this.getNumberOfDaysElapsed(closingDate,isPaysForDateOfClosingVendor);
        if(node >= 0){
            return `${node} day`+ (node > 1 ?'s' :'');
        }
        return '??? days';
    }


    /*
        =IF(
            AND(C15-C7>=0,ROUND(C18,0)>=C19),
                IF( ((SUM(C24:C25)/ROUND(C18,0))*C19)<=SUM(C24:C25),
                    MIN((SUM(C24:C25)/C18)*C19,SUM(C24:C25)),
                "???????.??"),
            "???????.??")
    * */
    getVendorsShare(closingDate: string, isPaysForDateOfClosingVendor?:boolean): number {

        let node: number = this.getNumberOfDaysElapsed(closingDate,isPaysForDateOfClosingVendor);

        if (node >= 0 && this.paymentPeriodInDays >= node) {
            if (((this.rentalAmountPlusTax / this.paymentPeriodInDays) * node) <= this.rentalAmountPlusTax) {
                return Math.min(Number((this.rentalAmountPlusTax / this.paymentPeriodInDays) * node), this.rentalAmountPlusTax);
            }
        }
        return -1;
    }

    getTotalAmount(closingDate: string,isPaysForDateOfClosingVendor?:boolean): number {
        if (this.vendorCollectedRentBoolean) {
            return +this.rentalAmountPlusTax - this.getVendorsShare(closingDate, isPaysForDateOfClosingVendor);
        } else{
            return this.getVendorsShare(closingDate, isPaysForDateOfClosingVendor);
        }
    }

    get creditType(): string{
        if(this.vendorCollectedRentBoolean){
            return StatementAdjustmentAmountTypes.PURCHASER;
        }
        return StatementAdjustmentAmountTypes.VENDOR;
    }

    get adjustmentDescription(): string {
        let result: string = 'TENANCY';
        if(!this.tenantOccupiesEntirePremisesBoolean) {
            if (this.tenantOccupies != 'OTHER') {
                let tOccupies: SelectItem = tenancyDropDowns.tenantOccupancyTypes.find(tot => tot.value == this.tenantOccupies);
                if (tOccupies) {
                    result += ' - ' + tOccupies.label;
                }
            }
            else {
                result += ' - ' + this.areaOccupiedDescription;
            }
        }
        return result;
    }

}
