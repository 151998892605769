import {
    DdDischargeRegisteredByType,
    DdDispositionType,
    DdlTypeForCorrespondence,
    DdlTypeForYesNo,
    DdMortgageeType,
} from '../../shared/mortgage';
import {RequisitionCategoryDropdown, RequisitionStatusDropdown} from '../../shared/requisition-template';
import {MortgageDispositionType} from '../../../shared-main/constants';
import {TitleDetailsTypeDropdown} from '../../shared/mortgage-contact-info';

export const yesNoOptions: DdlTypeForYesNo[] = <DdlTypeForYesNo[]>(
    [{
        label: "Y/n",
        value: "Y_n"
    }, {
        label: "Yes",
        value: "YES"
    }, {
        label: "No",
        value: "NO"
    }]
);

export const PurchaserVendorOptions = [
    { label: 'Purchaser' , value:'PURCHASER'},
    { label: 'Vendor' , value:'VENDOR'}
];

export const Form4AddressedTo = {
    OFFERORS: 'OFFERORS',
    PURCHASERS: 'PURCHASERS'
}

export const Form4AddressedToOptions = [
    { label: 'Offeror(s) if not blank, otherwise purchaser(s)' , value: Form4AddressedTo.OFFERORS},
    { label: 'Purchaser(s)' , value: Form4AddressedTo.PURCHASERS}
];

export const noYesOptions: DdlTypeForYesNo[] = <DdlTypeForYesNo[]>(
    [{
        label: "N/y",
        value: "N_y"
    }, {
        label: "Yes",
        value: "YES"
    }, {
        label: "No",
        value: "NO"
    }]
);


export const PurposeOfPayoutOptions = [
    { label: 'Rate' , value:'RATE'},
    { label: 'Convenience' , value:'CONVENIENCE'},
    { label: 'Product features' , value:'PRODUCT_FEATURES'},
    { label: 'Declined by BMO' , value:'DECLINED_BY_BMO'},
    { label: 'Service' , value:'SERVICE'},
    { label: 'Property Sold/Own Resources value' , value:'PROPERTY_SOLD_OWN_RESOURCE_VALUE'},
    { label: 'Death of Customer / Destroyed by Fire' , value:'DEATH_OF_CUSTOMER_DESTROYED_BY_FIRE'},
    { label: 'Collections / Bankruptcy / Fraud' , value:'COLLECTIONS_BANKRUPTCY_FRAUD'},
    { label: 'Other' , value:'OTHER'},
];

export const PurposeOfPayoutOptionsForBmo = [
    { label: 'Rate' , value:'RATE'},
    { label: 'Convenience' , value:'CONVENIENCE'},
    { label: 'Product features' , value:'PRODUCT_FEATURES'},
    { label: 'Declined by BMO' , value:'DECLINED_BY_BMO'},
    { label: 'Service' , value:'SERVICE'},
    { label: 'Property Sold/Own Resources value' , value:'PROPERTY_SOLD_OWN_RESOURCE_VALUE'},
    { label: 'CDC Relocation' , value:'CDC_RELOCATION'},
    { label: 'Refinance with BMO' , value:'REFINANCE_WITH_BMO'},
    { label: 'Death of Customer / Destroyed by Fire' , value:'DEATH_OF_CUSTOMER_DESTROYED_BY_FIRE'},
    { label: 'Collections / Bankruptcy / Fraud' , value:'COLLECTIONS_BANKRUPTCY_FRAUD'},
    { label: 'Other' , value:'OTHER'},
];

export const yesNoOnlyOptions: DdlTypeForYesNo[] = <DdlTypeForYesNo[]>(
    [{
        label: "Yes",
        value: "YES"
    }, {
        label: "No",
        value: "NO"
    }]
);

export const correspondenceOptions: DdlTypeForCorrespondence[] = <DdlTypeForCorrespondence[]>(
    [{
        label: "",
        value: ""
    },{
        label: "Mortgagee",
        value: "MORTGAGEE"
    },{
        label: "Solicitor",
        value: "SOLICITOR"
    }]
);

export const mortgageeTypeOptions: DdMortgageeType[] = <DdMortgageeType[]>(
    [{
        label: "",
        value: ""
    },{
        label: "Institution",
        value: "INSTITUTION"
    },{
        label: "Private Lender",
        value: "PRIVATE_LENDER"
    }]
);



export const requisitionStatusDropdownOptions: RequisitionStatusDropdown[] = <RequisitionStatusDropdown[]>(
    [{
        label: "Resolved",
        value: "RESOLVED"
    }, {
        label: "Unresolved",
        value: "UNRESOLVED"
    },{
        label: "Accept undertaking",
        value: "ACCEPT_UNDERTAKING"
    },{
        label: "Requires actual discharge",
        value: "REQUISITION_ACTUAL_DISCHARGE"
    },{
        label: "Waived (accepted)",
        value: "WAIVED_ACCEPTED"
    }]
);

export const requisitionCategoryDropdownOptions: RequisitionCategoryDropdown[] = <RequisitionCategoryDropdown[]>(
    [{
        label: "A",
        value: "A"
    }, {
        label: "B",
        value: "B"
    },{
        label: "C",
        value: "C"
    },{
        label: "n/a",
        value: null
    }]
);

export const TitleDetailsTypeOptions: TitleDetailsTypeDropdown[] = <TitleDetailsTypeDropdown[]>(
    [{
        label: "Auto Populated",
        value: "AUTO_POPULATED"
    }, {
        label: "Manually Entered",
        value: "MANUALLY_ENTERED"
    }]
);

export const requisitionDropDowns = {
    code : [
        {label : '<<1[[Enter the Instrument No.]]>>', value : '<<1[[Enter the Instrument No.]]>>'},
        {label : '<<2[[Enter the Date of Registration]]>>', value : '<<2[[Enter the Date of Registration]]>>'},
        {label : '<<3[[Enter the Instrument Type]]>>', value : '<<3[[Enter the Instrument Type]]>>'},
        {label : '<<4[[Enter the Instrument Amount]]>>', value : '<<4[[Enter the Instrument Amount]]>>'},
        {label : '<<5[[Enter the Party From]]>>', value : '<<5[[Enter the Party From]]>>'},
        {label : '', value : ''},
    ],

}
export const dropDowns = {

    calculated : [
        {label : 'Half-Yearly, not in advance', value : 'HALF_YEAR'},
        {label : 'Semi-Annually, not in advance', value : 'SEMI_ANNUALLY'},
        {label : 'Monthly, not in advance', value : 'MONTHLY'},
        {label : 'Annually', value : 'ANNUALLY'},
        {label : 'Weekly', value : 'WEEKLY'},
        {label : 'Bi-Weekly', value : 'BI_WEEKLY'},
        {label : 'Quarterly', value : 'QUARTERLY'}
    ],


    insuranceAmount : [
        {label : 'Full Insurable Value', value : 'FULL_INSURABLE_VALUE'},
        {label :  'See Standard Charge Terms', value : 'SEE_STANDARD_CHARGE_TERMS'},
        {label :  'Other', value : 'OTHER'}, //only used by existing matters that have Other selected. Not available for new matters.
    ],


    paymentFrequency : [
        {label : 'Monthly', value : 'MONTHLY'},
        {label :  'Semi-Monthly', value : 'SEMI_MONTHLY'},
        {label :  'Bi-weekly', value : 'BI_WEEKLY'},
        {label :  'Weekly', value : 'WEEKLY'},
        {label :  'Quarterly', value : 'QUARTERLY'}
    ],
    calcMethod : [
        {label : 'Manually edit Net Advance amount from Trust Ledger tab', value : 'MANUAL_EDIT'},
        {label : 'The full principal amount is being advanced', value : 'FULL_AMOUNT'},
        {label : 'Calculate Net Advance (by totaling debits and credits)', value : 'CALCULATE_ADVANCE'},
        {label : 'Specify amount of the Net Advance', value : 'SPECIFY_AMOUNT'},
        {label : 'There is no advance (e.g. line of credit)', value : 'NO_ADVANCE'}
    ],
    compoundPeriod : [
        {label : 'Semi Annually', value : 'SEMI_ANNUALLY'},
        {label : 'Monthly', value : 'MONTHLY'},
        {label : 'Annually', value : 'ANNUALLY'},
        {label : 'No Compounding', value : 'NO_COMPOUNDING'}
    ],
    regPaymentFrequency : [
        {label : 'Monthly', value : 'MONTHLY'},
        {label :  'Bi-weekly Accelerated (reduce am.)', value : 'BI_WEEKLY_ACCELERATED'},
        {label :  'Bi-weekly Regular (maintain am.)', value : 'BI_WEEKLY_REGULAR'},
        {label :  'Weekly Accelerated (reduce am.)', value : 'WEEKLY_ACCELERATED'},
        {label :  'Weekly Regular (maintain am.)', value : 'WEEKLY_REGULAR'},
        {label :  'Quarterly', value : 'QUARTERLY'},
        {label :  'Semi-Monthly', value : 'SEMI_MONTHLY'}
    ],
    financingTypeOptions : [
        {label : 'Mortgage', value : 'MORTGAGE'},
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment', value : 'ASSIGNMENT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : "Builders' Lien", value : 'BUILDER_LIEN'},
        {label : "Builders' Lien Caveat", value : 'BUILDER_LIEN_CAVEAT'},
        {label : 'Certificates Of Lis Pendens', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Encroachment Agreement', value : 'ENCROACHMENT_AGREEMENT'},
        {label : 'Encumbrance', value : 'ENCUMBRANCE'},
        {label : 'Home Owners Association', value : 'HOME_OWNERS_ASSOCIATION'},
        {label : 'Judgement', value : 'JUDGEMENT'},
        {label : 'Lien', value : 'LIEN'},
        {label : 'Mobile Home', value : 'MOBILE_HOME'},
        {label : 'Release of Dower', value : 'RELEASE_OF_DOWER'},
        {label : 'Restrictive Covenant', value : 'RESTICTIVE_COVENANT'},
        {label : 'Surface Lease', value : 'SURFACE_LEASE'},
        {label : 'Tax Notification', value : 'TAX_NOTIFICATION'},
        {label : 'Utility Right of Way', value : 'UTILITY_RIGHT_OF_WAY'},
        {label : 'Vendor\'s Lien Caveat', value : 'VENDORS_LIEN_CAVEAT'},
        {label : 'Writ', value : 'WRIT'},
        {label : 'Zoning Regulations', value : 'ZONING_REGULATIONS'}

    ],
    financingTypeOptionsNS : [
        {label : 'Mortgage', value : 'MORTGAGE'},
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment', value : 'ASSIGNMENT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : "Builders' Lien", value : 'BUILDER_LIEN'},
        {label : 'Certificates Of Lis Pendens', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Encroachment Agreement', value : 'ENCROACHMENT_AGREEMENT'},
        {label : 'Encumbrance', value : 'ENCUMBRANCE'},
        {label : 'Judgement', value : 'JUDGEMENT'},
        {label : 'Lien', value : 'LIEN'},
        {label : 'Mobile Home', value : 'MOBILE_HOME'},
        {label : 'Restrictive Covenant', value : 'RESTICTIVE_COVENANT'},
        {label : 'Surface Lease', value : 'SURFACE_LEASE'},
        {label : 'Tax Notification', value : 'TAX_NOTIFICATION'},
        {label : 'Utility Right of Way', value : 'UTILITY_RIGHT_OF_WAY'},
        {label : 'Writ', value : 'WRIT'},
        {label : 'Zoning Regulations', value : 'ZONING_REGULATIONS'}

    ],
    financingTypeOptionsNB : [
        {label : 'Mortgage', value : 'MORTGAGE'},
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment', value : 'ASSIGNMENT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : 'Certificates Of Lis Pendens', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Encroachment Agreement', value : 'ENCROACHMENT_AGREEMENT'},
        {label : 'Encumbrance', value : 'ENCUMBRANCE'},
        {label : 'Judgement', value : 'JUDGEMENT'},
        {label : 'Lien', value : 'LIEN'},
        {label : 'Mechanics\' Lien', value : 'BUILDER_LIEN'},
        {label : 'Mobile Home', value : 'MOBILE_HOME'},
        {label : 'Restrictive Covenant', value : 'RESTICTIVE_COVENANT'},
        {label : 'Surface Lease', value : 'SURFACE_LEASE'},
        {label : 'Tax Notification', value : 'TAX_NOTIFICATION'},
        {label : 'Utility Right of Way', value : 'UTILITY_RIGHT_OF_WAY'},
        {label : 'Writ', value : 'WRIT'},
        {label : 'Zoning Regulations', value : 'ZONING_REGULATIONS'}

    ],

    instrumentTypeOptions : [
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment', value : 'ASSIGNMENT'},
        {label : 'Amending Agreement', value : 'AMENDING_AGREEMENT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : 'Certificates Of Lis Pendens', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Construction Lien', value : 'BUILDER_LIEN'},
        {label : 'Encroachment Agreement', value : 'ENCROACHMENT_AGREEMENT'},
        {label : 'Encumbrance', value : 'ENCUMBRANCE'},
        {label : 'Judgement', value : 'JUDGEMENT'},
        {label : 'Lien', value : 'LIEN'},
        {label : 'Mobile Home', value : 'MOBILE_HOME'},
        {label : 'Postponement', value : 'POSTPONEMENT'},
        {label : 'Restrictive Covenant', value : 'RESTICTIVE_COVENANT'},
        {label : 'Surface Lease', value : 'SURFACE_LEASE'},
        {label : 'Tax Notification', value : 'TAX_NOTIFICATION'},
        {label : 'Utility Right of Way', value : 'UTILITY_RIGHT_OF_WAY'},
        {label : 'Writ', value : 'WRIT'},
        {label : 'Zoning Regulations', value : 'ZONING_REGULATIONS'}
    ],

    instrumentTypeOptions_MB : [
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment', value : 'ASSIGNMENT'},
        {label : 'Amending Agreement', value : 'AMENDING_AGREEMENT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : 'Certificates Of Lis Pendens', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Construction Lien', value : 'BUILDER_LIEN'},
        {label : 'Encroachment Agreement', value : 'ENCROACHMENT_AGREEMENT'},
        {label : 'Encumbrance', value : 'ENCUMBRANCE'},
        {label : 'Judgement', value : 'JUDGEMENT'},
        {label : 'Homestead Consent', value : 'HOMESTEAD_CONSENT'},
        {label : 'Lien', value : 'LIEN'},
        {label : 'Mobile Home', value : 'MOBILE_HOME'},
        {label : 'Postponement', value : 'POSTPONEMENT'},
        {label : 'Restrictive Covenant', value : 'RESTICTIVE_COVENANT'},
        {label : 'Surface Lease', value : 'SURFACE_LEASE'},
        {label : 'Tax Notification', value : 'TAX_NOTIFICATION'},
        {label : 'Utility Right of Way', value : 'UTILITY_RIGHT_OF_WAY'},
        {label : 'Writ', value : 'WRIT'},
        {label : 'Zoning Regulations', value : 'ZONING_REGULATIONS'}
    ],
};


//Existing Mortgage
export const mortgageDispositionTypeOptions: DdDispositionType[] = <DdDispositionType[]>(
    [{
        label: "",
        value: MortgageDispositionType.BLANK
    }, {
        label: "be Discharged",
        value: MortgageDispositionType.DISCHARGED,
    },{
        label: "be Assumed",
        value: MortgageDispositionType.ASSUMED,
    },{
        label: "Bridge Financing",
        value: MortgageDispositionType.BRIDGE_FINANCING,
    },{
        label: "Remain",
        value: MortgageDispositionType.REMAIN,
    }]
);



export const dischargeRegisteredByOptions: DdDischargeRegisteredByType[] = <DdDischargeRegisteredByType[]>(
    [{
        label: "",
        value: ""
    }, {
        label: "mortgagee",
        value: "MORTGAGEE"
    },{
        label: "our firm",
        value: "OUR_FIRM"
    }]
);
