import Utils from '../../shared-main/utils';
import {ContactName} from './contact-name';

const CorporationIdentifiers = ['LIMITED', 'LTD.', 'Ltd.' , 'INCORPORATED', 'INC.', 'CORPORATION', 'CORP.', 'LIMITEE',
    'LTEE', 'INCORPOREE', 'AUTHORITY', 'COMPANY', 'LLP', 'LTD', 'INC', 'CORP', 'TRUSTEES', 'ACADEMY'];
export class ContactNameUtil {

    /**
     * Compares if names that are in [SURNAME, LASTNAME MIDDLENAME] or [SURNAME, LASTNAME] or [SURNAME] format have the same SURNAME
     * For Corporations uses the whole name
     * @param {string[]} input
     * @param {string[]} compareWith
     * @returns {boolean}
     */
    static surnamesMatch(inputStrArr: string[], compareWithStrArr: string[]) : boolean{

        if(inputStrArr == null || inputStrArr == undefined || compareWithStrArr == null || compareWithStrArr == undefined) {
            return false;
        }

        const input = inputStrArr.filter( inputStr => inputStr != "" && inputStr != "*** COMPLETELY DELETED ***");
        const compareWith = compareWithStrArr.filter( compareWithStr => compareWithStr != "" && compareWithStr != "*** COMPLETELY DELETED ***");

        if(input.length == 0 || compareWith.length == 0) {
            return false;
        }

        if((input.length == 1 && input[0] == "") || (compareWith.length == 1 && compareWith[0] == "") ) {
            return false;
        }

        if(input.some( inp => this.isItCorporation(inp) )){ // how about mixture of ownership Corporation + Person ... is that possible ?
            return input.every( inp => compareWith.some( cWith => cWith === inp));
        }

        // get surnames on both sides
        let inputSurnames: string[] = input.map(inp => this.extractSurname(inp));
        let compareWithSurnames: string[] = compareWith.map(cw => this.extractSurname(cw));

        return inputSurnames.every( inp => compareWithSurnames.some( cWith => cWith === inp));

    }

    /**
     * Compares if names that are in [SURNAME, LASTNAME MIDDLENAME] or [SURNAME, LASTNAME] or [SURNAME] format have the same SURNAME
     * For Corporations uses the whole name
     * @param {string[]} input
     * @param {string[]} compareWith
     * @returns {boolean}
     */
    static getFirstSurnameMatch(input: string[], compareWith: string[]) : string{
        let surnameMatch : string = '';

        if(input == null || input == undefined || compareWith == null || compareWith == undefined) {
            return surnameMatch;
        }

        if(input.length == 0 || compareWith.length == 0) {
            return surnameMatch;
        }

        if((input.length == 1 && input[0] == "") || (compareWith.length == 1 && compareWith[0] == "") ) {
            return surnameMatch;
        }

        if(input.some( inp => this.isItCorporation(inp) )){
            input.filter(inp => this.isItCorporation(inp)).forEach(
                inp => {
                   if(compareWith.indexOf(inp) > -1) {
                       surnameMatch = inp;
                       return ;
                   }
                }
            );
            return surnameMatch;
        }

        // get surnames on both sides
        let inputSurnames: string[] = input.map(inp => this.extractSurname(inp));
        let compareWithSurnames: string[] = compareWith.map(cw => this.extractSurname(cw));

        inputSurnames.forEach( inp => {
            if(compareWithSurnames.indexOf(inp) > -1){
                surnameMatch = inp;
                return;
            }
        })
        return surnameMatch;
    }

    /**
     * Compares if names that are in [SURNAME, LASTNAME MIDDLENAME] or [SURNAME, LASTNAME] or [SURNAME] format have the same SURNAME
     * For Corporations uses the whole name
     * @param {string[]} input
     * @param {string[]} compareWith
     * @returns {boolean}
     */
    static getSurnameMatches(input: string[], compareWith: string[]) : string[]{
        let surnameMatches : string[] = [];

        if(input == null || input == undefined || compareWith == null || compareWith == undefined) {
            return surnameMatches;
        }

        if(input.length == 0 || compareWith.length == 0) {
            return surnameMatches;
        }

        if((input.length == 1 && input[0] == "") || (compareWith.length == 1 && compareWith[0] == "") ) {
            return surnameMatches;
        }

        if(input.some( inp => this.isItCorporation(inp) )){
            input.filter(inp => this.isItCorporation(inp)).forEach(
                inp => {
                    if(compareWith.indexOf(inp) > -1) {
                        surnameMatches.push(inp);
                    }
                }
            );
            return surnameMatches;
        }

        // get surnames on both sides
        let inputSurnames: string[] = input.map(inp => this.extractSurname(inp));
        let compareWithSurnames: string[] = compareWith.map(cw => this.extractSurname(cw));

        inputSurnames.forEach( inp => {
            if(compareWithSurnames.indexOf(inp) > -1){
                surnameMatches.push(inp);
                return;
            }
        })
        return surnameMatches;
    }

    /**
     * extracts SURNAME from names in this format: [SURNAME, LASTNAME MIDDLENAME] or [SURNAME, LASTNAME] or [SURNAME]
     * @param {string} input
     * @returns {string}
     */
    static extractSurname(input: string): string {
        let splittedName = this.splitLastAndGivenName(input);
        return splittedName.length > 0 ? splittedName[0] : '';
    }

    /**
     * This method splits the input string into surname &  given names.
     *
     * @param {string} input: Input string can be in format of [SURNAME, FIRSTNAME MIDDLENAME] or [SURNAME, FIRSTNAME] or [SURNAME].
     * @returns {string[]}: It returns array with SURNAME at the first index and "FIRSTNAME MIDDLENAME" at second index
     */
    static splitLastAndGivenName(input: string): string[] {
        if (input != undefined && input != null) {
            return input.split(",").map(value => {return value.trim()});
        } else {
            return [];
        }
    }

    static isItCorporation(input: string) {
        if(!input){
            return false
        }

        return CorporationIdentifiers.findIndex(c => {
            let re = new RegExp('(^|\\W)' + c.replace('.', '\\.') + '($|\\W)');
            return re.test(input);
        } ) > -1;

    }

    static splitLastFirstAndMiddleName(input: string): string[] {
        let arr : string[] = [];
        if (input != undefined && input != null && input.indexOf(',') > -1) {
            let splittedName = this.splitLastAndGivenName(input);

            if(splittedName.length > 0)
            {
                arr.push(splittedName[0]);
                if(splittedName.length > 1)
                {
                    arr.push(splittedName[1].split(/\s(.+)/)[0]);  //everything before the first space
                    arr.push(splittedName[1].split(/\s(.+)/)[1]);
                }

            }


        }
        return arr;
    }

    /**
     * Takes combined name field with LASTNAME, FIRSTNAME or FIRSTNAME [MIDDLENAME] LASTNAME format and parses names.
     *
     * @param {string} inputName
     * @returns {string[]} Array with LASTNAME, FIRSTNAME, MIDDLENAME
     */
    static parseLastFirstMiddleNames(inputName: string) : string[] {
        let parsedNames : string[] = [];
        if (inputName) {
            if (inputName.includes(',')) {
                parsedNames = this.splitLastFirstAndMiddleName(inputName);
            } else if (inputName.includes(' ')) {
                let splitNames: string[] = inputName.split(' ');
                parsedNames.push(splitNames.pop()); //last name is last word

                if (splitNames.length > 0) {
                    parsedNames.push(splitNames.shift()); //first name is first word
                }

                if (splitNames.length > 0) {
                    parsedNames.push(splitNames.join(' ')); //middle name is any remaining words recombined with space
                }
            } else {
                parsedNames.push(inputName); //unable to split so returning as last name
            }
        }

        return parsedNames;

    }

    static convertStringArrayToContactName(names: string[], contactName : ContactName, toMixedCase?: boolean): void {
        contactName.lastName = names.length > 0 ? ( toMixedCase ?  Utils.toMixedCase(names[0]) : names[0] ).substring(0,100) : '';
        contactName.firstName = names.length > 1 ? ( toMixedCase ?  Utils.toMixedCase(names[1]) : names[1] ).substring(0,50) : '';
        contactName.middleName = names.length > 2 ? ( toMixedCase ?  Utils.toMixedCase(names[2]) : names[2] ).substring(0,50) : '';
    }

    /*
    Attorney name parts are separated with space
    input value :  one word -> lastname;
                  two words -> firstname lastname;
                  more than two words -> firstname middleName(s) lastname

                  return array: size 3 -> firstname  middleName(s) lastname
     */

    static parseAttorneyName(attorneyName: string) : string[] {
        let parsedNames : string[] = ["", "", ""];
        if (attorneyName) {
            if (attorneyName.includes(' ')) {
                let splitNames: string[] = attorneyName.split(' ');
                // parsedNames.push(splitNames.pop()); //last name is last word
                parsedNames[2] = splitNames.pop();

                if (splitNames.length > 0) {
                    // parsedNames.push(splitNames.shift()); //first name is first word
                    parsedNames[0] = splitNames.shift();
                }

                if (splitNames.length > 0) {
                    // parsedNames.push(splitNames.join(' ')); //middle name is any remaining words recombined with space
                    parsedNames[1] = splitNames.join(' ');
                }
            } else {
                parsedNames[2] = attorneyName;
            }
        }
        return parsedNames;
    }

    /*
      input array contains value firstName (middleNames) lastName
     */
    static createAttorneyName(attorneyNameParts: string[]) : string {
        let attorneyName : string = "";
        if (Array.isArray(attorneyNameParts)) {
            attorneyName = attorneyNameParts.filter(part => !Utils.isEmptyString(part)).join(' ');
        }
        return attorneyName;
    }

    /*
       input string contains combination of Trustee Name for Estate with personal representative name
      */
    static parseTrusteeName(parcelReisterName: string) : string {
        let trusteeName : string = "";
        if (parcelReisterName) {
            trusteeName = parcelReisterName.substring(0, parcelReisterName.indexOf("(personal representative)"));
        }
        return trusteeName.trim();
    }

    static parseContactName(parcelReisterName: string) : string {
        let contactName : string = "";
        if (parcelReisterName && parcelReisterName.includes("for the estate of")) {
            contactName = parcelReisterName.split("for the estate of")[1];
        } else {
            contactName = parcelReisterName;
        }
        return contactName.trim();
    }

    static isSameLastName(lastName1: string, lastName2: string): boolean{
        let ret: boolean = false;
        if(lastName1 && lastName2){
            ret = lastName1.trim() == lastName2.trim();
        } else if(!lastName1 && !lastName2){
            ret = true;
        }
        return ret;
    }
}
