import {MatterParticipant} from '../matter-participant';
import * as _ from 'lodash';
import {Mortgage} from '../mortgage';
import {Matter} from '../matter';
import {Contact} from '../contact';
import {MatterParticipantRole, MatterParticipantRoleTypes} from '../matter-participant-role-types';
import {FamilyLawAct} from '../fla-data';
import {MatterParticipantWrapper} from '../matter-participant-wrapper';
import {ContactNameUtil} from '../contact-name-util';
import {GenderTypes} from '../../../contact/contact-type-mapping';
import Utils from '../../../shared-main/utils';
import {UUIDUtil} from '../../../main/uuid-util';
import {DpBooleanValueTypes} from '../dp-boolean';
import {SESSION_STORAGE_KEYS} from '../../../shared';
import {MISSING_EMAIL} from '../../../shared-main/constants';
import {ContactAssociation} from '../contact-association';

export class MatterParticipantInfo {
    id: number;
    label: string;
    matterParticipant: MatterParticipant;
    email: string;
}

export class CreateNewMatterSigningOfficerResult {
    matterParticipant: MatterParticipant;
    enableMatter: boolean;
    emitChange: boolean;
}

export class MatterParticipantUtil {

    //This method add a new participant with given contact.
    //withCloning - If true then cloning is done on the clientside else its done on server side
    //For "add spouse", when creating a new participant
    // 1. It does not get contact information from server side
    // 2. It doesn't know if the new participant need to add into the matterParticipants list
    // 3. It doesn't know withCloning information
    //So createMatterParticipant merhord is created for creating a new matterParticipant
    // and seperate "enrolContactToParticipant" and "push participant into the matterParticipants list" with createMatterParticipant
    static addMatterParticipant(matter: Matter, contact: Contact, withCloning: boolean, participantRole: MatterParticipantRole, mortgageId?: number, parentParticipant?: MatterParticipant, index?: number): MatterParticipant {
        let participant: MatterParticipant = matter.createMatterParticipant(participantRole, mortgageId);
        if(participantRole == MatterParticipantRoleTypes.CONSENTING_SPOUSE){
            if(parentParticipant) {
                participant.parentParticipantId = parentParticipant.matterParticipantId;
                let familyLawAct: FamilyLawAct = parentParticipant.getConsentedSpouseFamilyLawAct();
                if (familyLawAct) {
                    familyLawAct.consentedSpouseParticipant = participant;
                    familyLawAct.relationshipCreatedInMatter = true;
                    familyLawAct.consentSpouseMatterParticipantId = participant.matterParticipantId;
                }
            }
        }
        participant.enrolContactToParticipant(contact, withCloning);
        if(index >= 0){
            matter.matterParticipants.splice(index, 0, participant);
        } else {
            matter.matterParticipants.push(participant);
        }

        if (participantRole == matter.otherPartyMPRoleLawFirm) {
            matter.resetStatementOfAdjustmentPayable();
        }
        return participant;
    }

    /** adds a unique matter participant
     *  sets the contact data on matter participant
     **/
    static createUniqueMatterParticipant(matter: Matter, sourceContact: Contact, participantRole: MatterParticipantRole): MatterParticipant {
        let participant: MatterParticipant = matter.matterParticipants.find(item => item.matterParticipantRole == participantRole);
        //if participant exists and it refers to different source contact then remove it
        if (participant && participant.contact.id != sourceContact.id) {
            matter.removeMatterParticipant(participant)
            participant = null;
        }
        //if participant doesn't exist or it refers to different source contact then create new one
        if (!participant) {
            participant = matter.createMatterParticipant(participantRole);
            matter.matterParticipants.push(participant);
        }

        participant.contactReferenceId = sourceContact.id;
        participant.contact = sourceContact;


        return participant;
    }

    static createMatterParticipant(matter: Matter, participantRole: MatterParticipantRole, mortgageId?: number): MatterParticipant {
        let participant: MatterParticipant = new MatterParticipant();
        participant.matterParticipantRole = participantRole;
        participant.mortgageId = mortgageId;
        if (!matter.matterParticipants) {
            matter.matterParticipants = [];

        }
        while (matter.matterParticipants.find((p: MatterParticipant) => p.matterParticipantId === participant.matterParticipantId)) {
            console.log(`Found an existing participant with the matterParticipantId of ${participant.matterParticipantId}, assigning a new matterParticipantId`);
            participant.matterParticipantId = -(new Date()).getTime();

            if (matter.isWillMatter()) {
                participant.participantType = 'WILL';
            }
        }
        return participant;
    }

    static createOrUpdateOtherSideLawClerkMatterParticipantFromContactInfo(matter: Matter): MatterParticipant {
        if (!matter) {
            return null;
        }
        if (!matter.matterParticipants) {
            matter.matterParticipants = [];
        }
        let otherSideLawClerkMatterParticipant = matter.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'OTHERPARTY_LAWCLERK';
        });
        if (matter.otherPartyContactInfo && matter.otherPartyContactInfo.lawClerkName) {
            if (!otherSideLawClerkMatterParticipant) {
                otherSideLawClerkMatterParticipant = MatterParticipantUtil.createMatterParticipant(matter, 'OTHERPARTY_LAWCLERK');
                matter.matterParticipants.push(otherSideLawClerkMatterParticipant);
            }
            if (!otherSideLawClerkMatterParticipant.contact) {
                otherSideLawClerkMatterParticipant.contact = new Contact();
                otherSideLawClerkMatterParticipant.contact.id = UUIDUtil.getUUID();
                otherSideLawClerkMatterParticipant.contact.customerAccountId = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId));
                otherSideLawClerkMatterParticipant.contact.resetContactTypeInstanceType();
                otherSideLawClerkMatterParticipant.contact.snapshotFlag = true;
                otherSideLawClerkMatterParticipant.contact.privateFlag = true;
                otherSideLawClerkMatterParticipant.contact.canadianResidentFlag = 'Y/n';
                otherSideLawClerkMatterParticipant.contact.activeFlag = DpBooleanValueTypes.Y_n;
                otherSideLawClerkMatterParticipant.contact.gender = 'QUESTION';
                otherSideLawClerkMatterParticipant.contact.contactType = 'OTHER_LAWCLERK';
                otherSideLawClerkMatterParticipant.contact.instanceType = 'solicitor';
            }
            otherSideLawClerkMatterParticipant.contact.email = matter.otherPartyContactInfo.lawClerkEmail;
            otherSideLawClerkMatterParticipant.contact.contactName.lastName = matter.otherPartyContactInfo.lawClerkName ? matter.otherPartyContactInfo.lawClerkName : " ";
            otherSideLawClerkMatterParticipant.contact.sourceContactId = matter.otherPartyContactInfo.lawClerkId;
            // Below code is incorrect , because it is not clearing ids
            // Telephone are not used for notifications, so not need to copy
            //otherSideLawClerkMatterParticipant.contact.telephone = matter.otherPartyContactInfo.telephones;

        }
        return otherSideLawClerkMatterParticipant;
    }

    /**
     * This method reorders a participant in Up or down direction. As participant list can contain different type of participants therefore for reordering
     * first we need to find the other participant with same role and then it is swapped with the selected one.
     * @param selectedParticipant
     * @param moveUp
     */
    static reorderParticipant(matter: Matter, selectedParticipant: MatterParticipant, moveUp: boolean): void {
        if (matter.matterParticipants && matter.matterParticipants.length > 1) {
            let selectedParticipantIndex: number = _.findIndex(matter.matterParticipants, function (p: MatterParticipant) {
                return p === selectedParticipant;
            });

            let otherParticipant: MatterParticipant;
            let otherParticipantIndex: number;
            let participantsSubArray: Array<MatterParticipant> = [];


            if (moveUp) {
                //If moving up then take the items before selected Participant Index
                participantsSubArray = matter.matterParticipants.slice(0, selectedParticipantIndex);
                //Finding participant with same role in sub-array from last
                if (participantsSubArray && participantsSubArray.length > 0) {
                    otherParticipant = _.findLast(participantsSubArray, function (p: MatterParticipant) {
                        return p.matterParticipantRole === selectedParticipant.matterParticipantRole;
                    });
                }
            } else {
                //If moving down then take the items selected Participant Index
                participantsSubArray = matter.matterParticipants.slice(selectedParticipantIndex + 1, matter.matterParticipants.length);
                //Finding participant with same role in sub-array
                if (participantsSubArray && participantsSubArray.length > 0) {
                    otherParticipant = _.find(participantsSubArray, function (p: MatterParticipant) {
                        return p.matterParticipantRole === selectedParticipant.matterParticipantRole;
                    });
                }
            }

            //If there is otherParticipant with same role as selected one then getting it's index from actual participant list and that will be used for
            // swapping
            if (otherParticipant) {
                otherParticipantIndex = _.findIndex(matter.matterParticipants, function (p: MatterParticipant) {
                    return p === otherParticipant;
                });
            }

            if (otherParticipantIndex >= 0 && otherParticipantIndex < matter.matterParticipants.length) {
                //Swapping selected and other purchasers in the array
                matter.matterParticipants[selectedParticipantIndex] = otherParticipant;
                matter.matterParticipants[otherParticipantIndex] = selectedParticipant;
            }
        }
    }

    static reorderParticipantByMortgage(matter: Matter, selectedParticipant: MatterParticipant, moveUp: boolean, mortgage: Mortgage): void {
        if (matter && matter.matterParticipants && matter.matterParticipants.length > 1) {
            let selectedParticipantIndex: number = _.findIndex(matter.matterParticipants, function (p: MatterParticipant) {
                return p === selectedParticipant && p.mortgageId == mortgage.id;
            });

            let otherParticipant: MatterParticipant;
            let otherParticipantIndex: number;
            let participantsSubArray: Array<MatterParticipant> = [];


            if (moveUp) {
                // If moving up then take the items before selected Participant Index
                participantsSubArray = matter.matterParticipants.slice(0, selectedParticipantIndex);
                // Finding participant with same role in sub-array from last
                if (participantsSubArray && participantsSubArray.length > 0) {
                    otherParticipant = _.findLast(participantsSubArray, function (p: MatterParticipant) {
                        return p.matterParticipantRole === selectedParticipant.matterParticipantRole && p.mortgageId == mortgage.id;
                    });
                }
            } else {
                // If moving down then take the items selected Participant Index
                participantsSubArray = matter.matterParticipants.slice(selectedParticipantIndex + 1, matter.matterParticipants.length);
                // Finding participant with same role in sub-array
                if (participantsSubArray && participantsSubArray.length > 0) {
                    otherParticipant = _.find(participantsSubArray, function (p: MatterParticipant) {
                        return p.matterParticipantRole === selectedParticipant.matterParticipantRole && p.mortgageId == mortgage.id;
                    });
                }
            }

            // If there is otherParticipant with same role as selected one then getting it's index from actual participant list and that will be used for
            // swapping
            if (otherParticipant) {
                otherParticipantIndex = _.findIndex(matter.matterParticipants, function (p: MatterParticipant) {
                    return p === otherParticipant && p.mortgageId == mortgage.id;
                });
            }

            if (otherParticipantIndex >= 0 && otherParticipantIndex < matter.matterParticipants.length) {
                // Swapping selected and other purchasers in the array
                matter.matterParticipants[selectedParticipantIndex] = otherParticipant;
                matter.matterParticipants[otherParticipantIndex] = selectedParticipant;
            }
        }
    }

    //This method sorts the matter participants enrolled into this matter. It takes the purchasers and offerors sort them by priority
    // and put them at the end of list.
    static sortMatterParticipants(matter: Matter, ) {
        if (matter && matter.matterParticipants && matter.matterParticipants.length > 1) {
            let sortedParticipants: Array<MatterParticipant> = [];
            let parentDependentParticipants = matter.matterParticipants.filter(item => !!item.parentParticipantId);
            //Grouping matter participants by role.
            let participantsByType: Array<any> = _.map(_.groupBy(matter.matterParticipants.filter(item => !item.parentParticipantId), 'matterParticipantRole'), function (a) {
                return {
                    'participantRole': a[0].matterParticipantRole,
                    'participants': a
                }
            });

            //Sorting each group by matter participant priority. The participants for which priority doesn't apply they will be sorted in their natural ordering
            participantsByType.forEach((a) => {
                if (a.participants[0].matterParticipantPriority) {
                    a.participants.sort((a: MatterParticipant, b: MatterParticipant) => (a.matterParticipantPriority - b.matterParticipantPriority));
                }
                sortedParticipants = sortedParticipants.concat(a.participants);
            });
            if(parentDependentParticipants && parentDependentParticipants.length > 0){
                sortedParticipants = sortedParticipants.concat(parentDependentParticipants);
            }
            matter.matterParticipants = sortedParticipants;
        }
    }

    static deleteAllMatterParticipantByRole(matter: Matter, selectedMatterParticipantRole: MatterParticipantRole, mortgageId?: number): void {
        let selectedMatterParticipants: MatterParticipant[] = [];
        if (mortgageId) {
            selectedMatterParticipants = _.filter(matter.matterParticipants, matterParticipant => matterParticipant.matterParticipantRole === selectedMatterParticipantRole && matterParticipant.mortgageId == mortgageId);
        } else {
            selectedMatterParticipants = _.filter(matter.matterParticipants, matterParticipant => matterParticipant.matterParticipantRole === selectedMatterParticipantRole);
        }

        for (let i = 0; i < selectedMatterParticipants.length; i++) {
            let matterParticipantIndex: number = _.findIndex(matter.matterParticipants, matterParticipant => matterParticipant === selectedMatterParticipants[i])
            if (matterParticipantIndex >= 0) {
                matter.matterParticipants.splice(matterParticipantIndex, 1);
            }
        }
    }


    static is2MaleOrFemaleGenderClients(wrappers: Array<MatterParticipantWrapper>): boolean{
        return !!(wrappers && wrappers.length == 2
            && wrappers[0].matterParticipant && wrappers[0].matterParticipant.contact && wrappers[0].matterParticipant.contact.isMaleOrFemaleGender()
            && wrappers[1].matterParticipant && wrappers[1].matterParticipant.contact && wrappers[1].matterParticipant.contact.isMaleOrFemaleGender());
    }

    static is2DiffBlankAndMaleOrFemaleGenderClients(wrappers: Array<MatterParticipantWrapper>): boolean{
        return wrappers && wrappers.length == 2
            && wrappers[0].matterParticipant && wrappers[0].matterParticipant.contact
            && wrappers[1].matterParticipant && wrappers[1].matterParticipant.contact
            && ((wrappers[0].matterParticipant.contact.isMaleOrFemaleGender() && wrappers[1].matterParticipant.contact.isBlankGender())
             || (wrappers[1].matterParticipant.contact.isMaleOrFemaleGender() && wrappers[0].matterParticipant.contact.isBlankGender()));
    }

    static is2DiffMaleOrFemaleGenderClientsWithSameLastName(wrappers: Array<MatterParticipantWrapper>): boolean{
        return MatterParticipantUtil.is2MaleOrFemaleGenderClients(wrappers)
            && (wrappers[0].matterParticipant.contact.gender != wrappers[1].matterParticipant.contact.gender)
            && ContactNameUtil.isSameLastName(wrappers[0].matterParticipant.contact.lastName, wrappers[1].matterParticipant.contact.lastName);
    }

    static is2DiffMaleAndFemaleGenderClientsWithDiffLastName(wrappers: Array<MatterParticipantWrapper>): boolean{
        return MatterParticipantUtil.is2MaleOrFemaleGenderClients(wrappers)
            && wrappers[0].matterParticipant.contact.gender != wrappers[1].matterParticipant.contact.gender
            && !ContactNameUtil.isSameLastName(wrappers[0].matterParticipant.contact.lastName, wrappers[1].matterParticipant.contact.lastName);
    }

    static is2SameMaleOrFemaleGenderClients(wrappers: Array<MatterParticipantWrapper>): boolean{
        return MatterParticipantUtil.is2MaleOrFemaleGenderClients(wrappers)
            && wrappers[0].matterParticipant.contact.gender == wrappers[1].matterParticipant.contact.gender;
    }

    static is2DiffBlankAndMaleOrFemaleGenderClientsWithDiffLastName(wrappers: Array<MatterParticipantWrapper>): boolean{
        return MatterParticipantUtil.is2DiffBlankAndMaleOrFemaleGenderClients(wrappers)
            && wrappers[0].matterParticipant.contact.gender != wrappers[1].matterParticipant.contact.gender
            && !ContactNameUtil.isSameLastName(wrappers[0].matterParticipant.contact.lastName, wrappers[1].matterParticipant.contact.lastName);
    }

    static isIndividualClients(wrappers: Array<MatterParticipantWrapper>): boolean{
        return Array.isArray(wrappers) && wrappers.filter(item=>item && item.matterParticipant && item.matterParticipant.contact )
                                            .every(w=>w && w.matterParticipant && w.matterParticipant.contact && w.matterParticipant.contact.isMaleOrFemaleGender())
    }

    static is2FemaleAndFemalePoaGenderClients(wrappers: Array<MatterParticipantWrapper>): boolean{
        return wrappers && wrappers.length == 2 &&
            ((wrappers[0].matterParticipant.contact.gender == GenderTypes.FEMALE && wrappers[1].matterParticipant.contact.gender == GenderTypes.FEMALEPOA)
            || (wrappers[0].matterParticipant.contact.gender == GenderTypes.FEMALEPOA && wrappers[1].matterParticipant.contact.gender == GenderTypes.FEMALE));
    }

    static includeCorpEntity(wrappers: Array<MatterParticipantWrapper>): boolean{
        let ret : boolean = false;
        if(Array.isArray(wrappers)) {
            // There is at least one contact gender is CORPORATION or OTHERENTITY
            if(wrappers.some(wrapper=> wrapper.matterParticipant && wrapper.matterParticipant.contact
                && (wrapper.matterParticipant.contact.gender == GenderTypes.CORPORATION || wrapper.matterParticipant.contact.gender == GenderTypes.OTHERENTITY))){
                ret = true;
            }
        }
        return ret;
    }

    /**
     *  There is at least one contact gender is CORPORATION or OTHERENTITY. Or There is Not anyone contact gender is MALE, FEMALE or BLANK.
     * @param wrappers
     */
    static includeCorpEntityOrAllExcludeMaleFemaleBlank(wrappers: Array<MatterParticipantWrapper>): boolean{
        let ret : boolean = false;
        if(Array.isArray(wrappers)) {
            // There is at least one contact gender is CORPORATION or OTHERENTITY
            ret = MatterParticipantUtil.includeCorpEntity(wrappers);
            //There is Not anyone contact gender is MALE, FEMALE or BLANK
            if(wrappers.filter(wrapper=> wrapper.matterParticipant && wrapper.matterParticipant.contact)
                .every(item=>item.matterParticipant.contact.gender != GenderTypes.MALE
                    && item.matterParticipant.contact.gender != GenderTypes.FEMALE
                    && item.matterParticipant.contact.gender != GenderTypes.QUESTION)){
                ret = true;
            }
        }
        return ret;
    }

    /**
     *  There is not one contact gender is CORPORATION or OTHERENTITY  and only one contact gender is male. female or blank. All others gender is POA or Estate
     * @param wrappers
     */
    static excludeCorpEntityAndOneMaleFemaleBlank(wrappers: Array<MatterParticipantWrapper>): boolean{
        let ret : boolean = false;
        if(Array.isArray(wrappers)) {
            let notNullWrappers = wrappers.filter(wrapper => wrapper.matterParticipant && wrapper.matterParticipant.contact);
            // There is not one contact gender is CORPORATION or OTHERENTITY
            if (notNullWrappers.every(wrapper => (wrapper.matterParticipant.contact.gender != GenderTypes.CORPORATION) && (wrapper.matterParticipant.contact.gender != GenderTypes.OTHERENTITY))) {
                //There is only one contact gender is male, female or BLank
                let maleFemaleBlankClient = notNullWrappers.filter(item => item.matterParticipant.contact.gender == GenderTypes.MALE
                    || item.matterParticipant.contact.gender == GenderTypes.FEMALE
                    || item.matterParticipant.contact.gender == GenderTypes.QUESTION);
                if (Array.isArray(maleFemaleBlankClient) && maleFemaleBlankClient.length ==1) {
                    ret = true;
                }
            }
        }

        return ret;
    }

    static primaryContactEmail(matter: Matter): string {
        return MatterParticipantUtil.primaryContactFieldWithValidEmail(matter, 'firstEmail');
    }

    static primaryContactFullName(matter: Matter): string {
        return MatterParticipantUtil.primaryContactFieldWithValidEmail(matter, 'contactFullNameStartWithFirstName');
    }

    static primaryContactFieldWithValidEmail(matter: Matter, field: string): string {
        if(matter && matter.primaryMainClient && matter.primaryMainClient.contact) {
            switch (matter.primaryMainClient.contact.gender) {
                case 'OTHERENTITY':
                case 'ESTATE':
                case 'MALEPOA':
                case 'FEMALEPOA':
                case 'CORPORATION': {
                    const fiduciaries = matter.getAllSigners(matter.primaryMainClient);
                    const fiduciary = fiduciaries.find(mp => mp.primary);
                    if(fiduciary && fiduciary.contact && Utils.validateEmail(fiduciary.contact.firstEmail)) {
                        return fiduciary.contact[field];
                    }
                    return matter.primaryMainClient.contact[field];
                }
                default:
                    return matter.primaryMainClient.contact[field];
            }
        }
        console.error(`failed to retrieve main client contact ${field}`);
        return '';
    }

    static contactFieldWithEmailInfo(matterParticipant: MatterParticipant, matter: Matter): MatterParticipantInfo{
        if(matter && matterParticipant && matterParticipant.contact) {
            switch (matterParticipant.contact.gender) {
                case 'OTHERENTITY':
                case 'ESTATE':
                case 'MALEPOA':
                case 'FEMALEPOA':
                case 'CORPORATION': {
                    const fiduciaries = matter.getAllSigners(matterParticipant);
                    const fiduciary = fiduciaries.find(mp => mp.primary);
                    if(fiduciary && fiduciary.contact) {
                        let matterParticipantInfo: MatterParticipantInfo = new MatterParticipantInfo();
                        matterParticipantInfo.id = fiduciary.matterParticipantId;
                        matterParticipantInfo.label = Utils.validateEmail(fiduciary.contact.firstEmail)
                            ? fiduciary.contact['contactFullNameStartWithFirstName']
                            : fiduciary.contact['contactFullNameStartWithFirstName'] + MISSING_EMAIL;
                        matterParticipantInfo.matterParticipant = fiduciary;
                        matterParticipantInfo.email = fiduciary.contact.firstEmail;
                        return matterParticipantInfo;
                    }
                    break;
                }
                default:
                    let matterParticipantInfo: MatterParticipantInfo = new MatterParticipantInfo();
                    matterParticipantInfo.id = matterParticipant.matterParticipantId;
                    matterParticipantInfo.label = Utils.validateEmail(matterParticipant.contact.firstEmail)
                        ? matterParticipant.contact['contactFullNameStartWithFirstName']
                        : matterParticipant.contact['contactFullNameStartWithFirstName']  + MISSING_EMAIL;
                    matterParticipantInfo.matterParticipant = matterParticipant;
                    matterParticipantInfo.email = matterParticipant.contact.firstEmail;
                    return matterParticipantInfo;

                    break;
            }
        }
        return null;
    }

    static getMaxMatterSigningOfficerPriority(matterParticipants: MatterParticipant[]): number {
        let max: number = 0;
        if(matterParticipants && matterParticipants.length > 0) {
            for (let i = 0; i < matterParticipants.length; i++) {
                let signingOfficer: MatterParticipant = matterParticipants[i];
                if (!signingOfficer) {
                    continue;
                }
                if (!signingOfficer.matterParticipantPriority) {
                    continue;
                }
                if (signingOfficer.matterParticipantPriority > max) {
                    max = signingOfficer.matterParticipantPriority;
                }
            }
        }
        return max;
    }

    static isSignerCreatedForExistingContact(matter: Matter, parentParticipant): boolean {
        return (matter && parentParticipant && parentParticipant.sourceContact && parentParticipant.sourceContact.id > 0);
    }

    static createNewMatterSigningOfficerContactCore(contact: Contact, outsideParticipant: MatterParticipant, isContactSelected: boolean,
                                                    matter: Matter, parentContact: Contact, parentParticipant: MatterParticipant,
                                                    matterParticipantPriority: number, setPrimary: boolean, isMatterSnapShot: boolean,
                                                    isSignerOnMatter: boolean,
                                                    doNotUpdateAssociatedContact?: boolean): CreateNewMatterSigningOfficerResult {
        let createNewMatterSigningOfficerResult: CreateNewMatterSigningOfficerResult = new CreateNewMatterSigningOfficerResult();
        let person: any = {};
        let participant: MatterParticipant = null;

        //Creating snapshot from source contact to be added into matter participant
        if (this.isSignerCreatedForExistingContact(matter, parentParticipant) && !isMatterSnapShot) {
            let snapshot: Contact = new Contact();
            snapshot.createNewContactClone(contact);
            snapshot.snapshotFlag = true;
            snapshot.sourceContactId = contact.id;
            person.contact = snapshot;
        } else {
            contact.sourceContactId = contact.id;
            person.contact = contact;
        }

        person.matterParticipantRole = parentContact.signingOfficerAssociationRole;
        // person.matterParticipantPriority = this.getMaxMatterSigningOfficerPriority() + 1;
        person.matterParticipantPriority = matterParticipantPriority;
        person.signingMatter = true;
        if (setPrimary) {
            // if (this.selectedClientSigningOfficer.length === 1) {
            person.primary = true;

        }

        participant = new MatterParticipant(person);
        if (parentContact && !doNotUpdateAssociatedContact) {
            let associatedContact = new ContactAssociation();
            associatedContact.associatedContact = this.isSignerCreatedForExistingContact(matter, parentParticipant) && !isMatterSnapShot ? contact : participant.contact;
            associatedContact.contactAssociationType = parentContact.signingOfficerAssociationRole;
            if (!parentContact.contactAssociations) {
                parentContact.contactAssociations = [];
            }
            parentContact.contactAssociations.push(associatedContact);
            parentContact.isDirty = true;
            createNewMatterSigningOfficerResult.enableMatter = true;
        }

        if (outsideParticipant) {
            participant.matterParticipantId = outsideParticipant.matterParticipantId;
        }

        if (isContactSelected) {
            participant.contact.idInfoEnteredBy = null;
            participant.contact.contactIdInfoEnteredBy = null;
            participant.contact.enteredOn = null;
        }
        participant.contact.lastSyncedFromSource = contact.lastUpdatedTimeStamp;
        participant.sourceContact = contact;
        if (isSignerOnMatter) {
            if (!matter.matterParticipants) {
                matter.matterParticipants = [];
            }
            if (parentParticipant) {
                participant.parentParticipantId = parentParticipant.matterParticipantId;
            }
            matter.matterParticipants.push(participant);
            //  two emitDataChange for now, because envelope salutations fields need trigger after updating  matterParticipant
            // If we move enableMatterSave() here, it will cause other issue
            createNewMatterSigningOfficerResult.emitChange = true;
        }
        createNewMatterSigningOfficerResult.matterParticipant = participant;
        return createNewMatterSigningOfficerResult;
    }

    static addSigningOfficer(matter: Matter, matterParticipant: MatterParticipant, signerSourceContacts: Contact[]){
        if(matter && matterParticipant && matterParticipant.contact
            && signerSourceContacts && signerSourceContacts.length > 0) {
                matterParticipant.contact.isDirty = true;
                matterParticipant.sourceContactLockAcquired = true;
                signerSourceContacts.forEach(signerSourceContact=>{
                    let childSigners: MatterParticipant[] = matter.getChildSigners(matterParticipant)
                    MatterParticipantUtil.createNewMatterSigningOfficerContactCore(signerSourceContact, null, true,
                        matter, matterParticipant.contact, matterParticipant,
                        MatterParticipantUtil.getMaxMatterSigningOfficerPriority(childSigners) + 1,
                        childSigners && (childSigners.length === 0),
                        false, true);

                });

        }

    }
}
