import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Precedent} from '../../../shared/precedent';
import {PrecedentService} from './precedent.service';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {PrecedentTypes} from '../../../shared/mortgage-precedent-type';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {Observable} from 'rxjs';
import {currentMatter} from '../../../shared/current-matter';
import {Matter} from '../../../shared/matter';

class CommonPrecedentModalContext extends BSModalContext {
    public additionalClause : string;
    public action : string;
    public caller ? : string;
}

@Component({
               selector    : 'dp-mortgage-precedent',
               templateUrl : 'mortgage-precedent.component.html',
               styleUrls      : ['./mortgage-precedent.styles.scss'],
               providers : [PrecedentService]
           })

export class MortgagePrecedentComponent implements   ModalComponent<CommonPrecedentModalContext>, OnDestroy, OnInit, AfterViewInit {
    // @Input() mortgageReport : MortgageReport;
    // @Input() action : string;
    @ViewChild('precedentList') precedentList : ElementRef;
    @ViewChild('precedentNameInput') precedentNameInput : ElementRef;
    precedents : Precedent[] = [];
    selectedPrecedentIdentifier : number = null;
    context : CommonPrecedentModalContext;
    precedentName : string;
    // retBoolean : boolean = false;

    constructor(public mortgagePrecedentService : PrecedentService,
                public dialog : DialogRef<CommonPrecedentModalContext>,
                public dialogService : DialogService) {
        this.context = dialog.context;
        this.context.showClose = true;


    }

    get matter() : Matter{
        return currentMatter.value;
    }

    public addDefaultPrecedent() : void {
        if(this.context.caller == PrecedentTypes.MORTGAGE && (!this.precedents.find((precedent) => {return precedent.precedentName === 'OpenMTG'}) || this.precedents.length ==0)) {
            const defaultMortgagePrecedent : Precedent = new Precedent();
            defaultMortgagePrecedent.precedentName = 'OpenMTG';
            defaultMortgagePrecedent.precedentType = PrecedentTypes.MORTGAGE;
            defaultMortgagePrecedent.description = 'The mortgagor has the privilege of prepaying the whole or any part of the principal sum secured at any time or' +
                ' times without notice or bonus.';
            this.precedents.push(defaultMortgagePrecedent);
        }
        else if(this.context.caller == PrecedentTypes.REPORT_TO_PURCHASER && (!this.precedents.find((precedent) => {return precedent.precedentName === 'SURVEY'}) || this.precedents.length ==0)) {
            const defaultMortgagePrecedent : Precedent = new Precedent();
            defaultMortgagePrecedent.precedentName = 'SURVEY';
            defaultMortgagePrecedent.precedentType = PrecedentTypes.REPORT_TO_PURCHASER;
            defaultMortgagePrecedent.description = 'We confirm our advice to you that, insofar as the survey provided by the vendor was not up to date, we are unable to express an opinion with respect to zoning violations.';
            this.precedents.push(defaultMortgagePrecedent);
        }
    }

    ngOnInit() : void {

        //Get precedents from backend
        this.mortgagePrecedentService.getPrecedents(this.matter.provinceCode)
            .subscribe((data : Precedent[]) => {
                if(this.context.caller && data) {
                    this.precedents = data.filter(item => item.precedentType == this.context.caller && item.provinceCode == this.matter.provinceCode);
                    if (this.isInsert() && this.precedents && this.precedents.length > 0) {
                        this.selectedPrecedentIdentifier = this.precedents[0].identifier;
                    }
                }
                else  {
                    this.precedents = data;
                }
                this.addDefaultPrecedent();
            });
    }

    ngAfterViewInit() {
        if (!this.isInsert()) {
            this.precedentNameInput.nativeElement.focus();
        } else {
            this.precedentList.nativeElement.focus();
        }
    }

    ngOnDestroy() : void {
        this.onCancel();
    }

    onCancel() : void {
        const result = {action : 'cancel'};
        this.closeDialog(result);
    }

    closeDialog(result : any) : void {
        if(!this.dialog.destroyed) {
            console.log('Close Precedent Dialog');
            this.dialog.close(result);
        }
    }

    isInsert() : boolean {
        return this.context.action === 'INSERT'
    }

    get disableOKButton() : boolean {

        if(this.isInsert()) {
            return this.selectedPrecedentIdentifier == null;
        } else {
            return this.precedentName ?  !(this.precedentName.trim().length > 0) : true;
        }

    }

    // confirmDialog (text : string, index : number) {
    //     this.dialogService.confirm('Confirm Save As', text, false)
    //         .subscribe(res => {
    //             if(res == true) {
    //                 this.precedents[index].description = this.context.additionalClause;
    //                 this.mortgagePrecedentService.savePrecedent(this.precedents[index])
    //                     .subscribe((res) => {
    //                     });
    //                 this.closeDialog({action : 'ok'});
    //             } else {
    //                 console.log('Give up use same name.')
    //             }
    //
    //         });
    // }

    public preparePrecedent() : Observable<Precedent> {
        let ret : Observable<Precedent>;
        const index : number = this.precedents.findIndex(precedent => precedent.precedentName === this.precedentName);
        if(index < 0) { // New precedent
            const mortgagePrecedent = new Precedent();
            mortgagePrecedent.precedentName = this.precedentName;
            if(this.context.caller) {
                mortgagePrecedent.precedentType = this.context.caller;
            }
            else {
                mortgagePrecedent.precedentType = PrecedentTypes.REQUISITION;
            }
            mortgagePrecedent.description = this.context.additionalClause;
            mortgagePrecedent.provinceCode = this.matter.provinceCode;

            ret = Observable.create(observer => {
                observer.next(mortgagePrecedent);
            });
            // const result : Subject<Precedent> = new Subject();
            // result.next(mortgagePrecedent);
            // return result.asObservable();
        } else {
            const text : string = this.precedentName + ' already exists. ' + 'Do you want to replace it?';
            ret = this.dialogService.confirm('Confirmation', text, false)
                      .map(res => {
                          if(res) {
                              this.precedents[index].description = this.context.additionalClause;
                              return this.precedents[index];
                          } else {
                              console.log('Give up use same name.');
                              return null;
                          }
                      });
        }

        return ret;
    }

    public savePrecedent() {
        this.preparePrecedent().subscribe((precedent : Precedent) => {
            if(precedent) {
                this.mortgagePrecedentService.savePrecedent(precedent).subscribe((res) => {
                    this.closeDialog({action : 'ok'});
                });
            }
        });
    }


    public deletePrecedent(index : number) {
        const precedent : Precedent = index >= 0 && index < this.precedents.length && this.precedents[index];
        if(precedent) {
            this.mortgagePrecedentService.deletePrecedent(precedent).subscribe((res) => {
                if (res) {
                    this.precedents.splice(index, 1);
                    this.selectedPrecedentIdentifier = null;
                }
            });
        }
    }

    // save the contact form
    onOK() : void {
        // let result;
        // let waitSubscribe : boolean = false;

        if(this.isInsert()) {
            const precedent : Precedent = this.precedents.find((item : Precedent) => item.identifier === this.selectedPrecedentIdentifier);
            const result = {action : 'ok', additionalClause : precedent && precedent.description};
            this.closeDialog(result);
        } else {
            this.savePrecedent();
        }
    }

    onDelete() : void {
        const index : number = this.precedents.findIndex(precedent => this.selectedPrecedentIdentifier === precedent.identifier);
        if (index > -1) {
            this.dialogService.confirm("Confirmation", "Are you sure you would like to delete this precedent?", false, 'Delete').subscribe(res => {
                if (res) {
                    this.deletePrecedent(index);
                }
            });
        }
    }

    get disableDeleteBtn() : boolean {
        return this.selectedPrecedentIdentifier == null;
    }

    getTitle() : string {
        let retString : string = '';
        if(this.isInsert()) {
            retString = 'Insert Precedent';
        } else {
            retString = 'Save Precedent'
        }
        return retString;
    }

    selectPrecedent(precedent : Precedent) {
        this.selectedPrecedentIdentifier = precedent.identifier;
        if(this.context.action !== 'INSERT') {
            this.precedentName = precedent.precedentName;
        }

    }

    isSelectedPrecedent(precedent : Precedent) {
        return this.selectedPrecedentIdentifier != null && this.selectedPrecedentIdentifier === precedent.identifier;
    }

    onPrecedentNameChange() : void {
        // this.selectedPrecedentIdentifier = this.precedents.findIndex((item : Precedent) => {
        //     return item.precedentName === this.precedentName;
        // })
        this.selectedPrecedentIdentifier = null;
    }

    showPrecedentName(){
        return this.context.caller == PrecedentTypes.PURCHASE_UNDERTAKING;
    }

    public selectNextPrecedent(step: number) {
        if (Array.isArray(this.precedents) && this.precedents.length > 0) {
            let index : number = this.selectedPrecedentIdentifier && this.precedents.findIndex((item : Precedent) => item.identifier === this.selectedPrecedentIdentifier);
            if(index > -1) {
                index += step;
            } else {
                if(step > 0) {
                    index = 0;
                } else if(step < 0) {
                    index = this.precedents.length - 1;
                }
            }

            if(index < 0) {
                index = 0;
            } else if(index >= this.precedents.length) {
                index = this.precedents.length - 1;
            }

            this.selectedPrecedentIdentifier = this.precedents[index].identifier;
        }
    }

    onKeyPress(event : KeyboardEvent) : void {
        console.log(event);
        if(event.keyCode === 40) { //Down arrow key
            event.preventDefault();
            this.selectNextPrecedent(1);
        } else if(event.keyCode === 38) { //Up arrow key
            event.preventDefault();
            this.selectNextPrecedent(-1);
        }
    }
}
