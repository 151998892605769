import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import Utils from '../../../shared-main/utils';
import {SoAdjInterimEarlyPossessionFee} from '../model/so-adj-interim-early-possession-fee';

export class StatementAdjustmentDisplayInterimEarlyPossessionFeeBuilder {

    static getDisplayItems(soAdj: SoAdjInterimEarlyPossessionFee, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[]{
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        if (!toBeUsedOnModal) {
            let interestLabel: string = (mainBuilder.matter && mainBuilder.matter.project && ! mainBuilder.matter.project.isCondo && (mainBuilder.matter.project.isPOTL || mainBuilder.matter.project.occupancyDateRequired)) ?
                'Interest on Deferred Purchase Monies' :
                'Mortgage Interest';

            sadItemDetails.push(mainBuilder.getNewItemLine(
                interestLabel ,
                mainBuilder.getCurrency(soAdj.mortgageInterestTotal)
            ));

            sadItemDetails.push(mainBuilder.getNewItemLine(
                'Common Expenses',
                mainBuilder.getCurrency(soAdj.commonExpenseTotal)
            ));

            sadItemDetails.push(mainBuilder.getNewItemLine(
                'Taxes',
                mainBuilder.getCurrency(soAdj.taxesTotal)
            ));
        }

        sadItemDetails.push(mainBuilder.getNewItemLine(
            'Total Early Possession Fees',
            mainBuilder.getCurrency(soAdj.total)
        ));

        if (Utils.convertToBoolean(soAdj.applyTax)) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${soAdj.taxType} calculated at `+ mainBuilder.getPercentage(soAdj.hstRate),
                mainBuilder.getCurrency(soAdj.total*soAdj.hstRate / 100)));
        }

        let elapsedDays = Number(mainBuilder.matter.getElapsedDays(mainBuilder.adjustmentDate));
        let numberOfDaysInMonth = Number(mainBuilder.matter.getNumberofDaysMonth(mainBuilder.adjustmentDate));
        sadItemDetails.push(mainBuilder.getNewItemLine(
            `Purchaser's share for ${soAdj.numberOfDaysForPurchasersShare(elapsedDays, numberOfDaysInMonth)} days`,
            mainBuilder.getCurrency(soAdj.getPurchaserShare(elapsedDays, numberOfDaysInMonth))
        ));


        if(soAdj.calculateFor == 'THIS_MONTH_AND_NEXT_MONTH'){
            sadItemDetails.push(mainBuilder.getNewItemLine(
                "Next month's fees" + ((Utils.convertToBoolean(soAdj.applyTax)) ? `(including ${soAdj.taxType})`: ''),
                mainBuilder.getCurrency(soAdj.nextMonthFees)
                ));
        }

        if (toBeUsedOnModal) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Credit ${mainBuilder.vendorLabel}`,
                mainBuilder.getCurrency(soAdj.getCreditVendor(elapsedDays, numberOfDaysInMonth)),
                true
            ));
        }
        else{
            sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${mainBuilder.vendorLabel}`, ``));
        }

        return sadItemDetails;
    }
}
