import {Injectable} from '@angular/core';
import {Matter, Utils} from '../../matters/shared';
import {Mortgage} from '../../matters/shared/mortgage';
import {contactDropDowns} from '../../contact/contact-drop-downs';
import {ActingForValues} from '../constants';
import {DpEmailDirective} from '../../shared-main/email.directive';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ClipboardService} from 'ngx-clipboard';
import {Observable} from 'rxjs/Rx';
import moment from 'moment';

//The following keys represent the top parent component that affect the subject line
export const EmailKeys = {
    opportunityMatter : 'OPPORTUNITY_MATTER' ,
    matterOpening : 'MATTER-OPENING' ,
    vendorsSolicitor : 'VENDORS-SOLICITOR',
    mortgagee : 'MORTGAGEE',
    fireInsurance : 'FIRE-INSURANCE',
    jurisdictionDepartments : 'JURISDICTION-DEPARTMENTS'
};

export const EmailTypes = {
    isShareDoc : 'IS_SHARE_DOC',
    isCirf : 'IS_CIRF',
    isTaskAssignment : 'IS_TASK_ASSIGNMENT'
};

export class EmailData {
    taskName: string;
    dueDate :string;
};


@Injectable()
export class EmailFieldService {

    public matter : Matter;
    public key : string;
    public mortgage : Mortgage;
    public subjectLine : string = '';

    constructor(private dialogService : DialogService , private clipboardService: ClipboardService) {

    }

    // getMailSubject(fieldKey? : string, isShareDoc?: boolean, isCirf?: boolean, isTaskAssignment?: boolean,
    //                taskName?: string, dueDate?:string) : string {

    getMailSubject(fieldKey? : string, type?: string, emailData?: EmailData) : string {
        this.subjectLine = '';
        if(fieldKey) {
            this.key = fieldKey ;
        }
        if(this.matter){

            if(this.key==EmailKeys.matterOpening || this.key==EmailKeys.vendorsSolicitor || this.key==EmailKeys.fireInsurance) {
                this.createAgentSubjectLine();
            }

            if(this.key == EmailKeys.vendorsSolicitor
                && this.matter.otherPartyContactInfo
                && this.matter.otherPartyContactInfo.fileNumber){
                this.subjectLine += '; Your File No. ' + this.matter.otherPartyContactInfo.fileNumber ;
            }

            if(this.key == EmailKeys.fireInsurance
                && this.matter.fireInsuranceContactInfo
                && this.matter.fireInsuranceContactInfo.documentationType
                && this.matter.fireInsuranceContactInfo.documentationValue ){

                let documentationType = contactDropDowns.documentationTypes.find((type)=>{
                    return type.value == this.matter.fireInsuranceContactInfo.documentationType;
                });
                this.subjectLine += '; ' + documentationType.label + ': ' + this.matter.fireInsuranceContactInfo.documentationValue
            }

            if (this.key == EmailKeys.mortgagee) {
                this.createMortgageSubjectLine();
            }

            let closingDate =  ((type == EmailTypes.isTaskAssignment) && emailData)? emailData.dueDate : this.matter.matterCloseDate;
            let subjectLine = closingDate ? this.createClosingDatePart(closingDate) : null;

            switch (type) {
                case EmailTypes.isShareDoc:
                    if (subjectLine) {
                        this.subjectLine += '; ' + 'Closing: ' + subjectLine;
                    }
                    this.subjectLine = 'Received Shared Documents: ' + this.subjectLine;
                    break;
                case EmailTypes.isTaskAssignment:
                    if (subjectLine) {
                        this.subjectLine += '; ' + 'Due Date: ' + subjectLine;
                    }
                    if(emailData){
                        this.subjectLine = 'Task Assignment: ' + (emailData.taskName ? emailData.taskName : '')
                            + (this.subjectLine && emailData.taskName ? ' - ' + this.subjectLine : '');
                        break;
                    } else {
                        this.subjectLine = 'Task Assignment: Multiple tasks - ' + this.subjectLine;
                        break;
                    }

                case EmailTypes.isCirf:
                    if (subjectLine) {
                        this.subjectLine += '; ' + 'Closing: ' + subjectLine;
                    }
                    this.subjectLine = 'Received CIRF: ' + this.subjectLine;
                    break;
                default:
                    if (subjectLine) {
                        this.subjectLine += '; ' + subjectLine;
                    }
                    break;
            }
        }

        return this.subjectLine;
    }

    createClosingDatePart(closingDate: string): string {

        if (!Utils.isNotValidDate(closingDate)) {
            return moment(closingDate,'YYYY/MM/DD').format( 'ddd MMM, D YYYY');
        }

        let dateWrapper = Utils.createDateValues(closingDate);
        let subjectLineDate = '';
        let date = new Date();
        let currentYear = (new Date()).getFullYear();

        // Month only
        if (dateWrapper.monthPart && !dateWrapper.dayPart && !dateWrapper.yearPart) {
            date.setFullYear(currentYear, +dateWrapper.monthPart - 1)
            subjectLineDate = moment(date).format('MMMM');
        // Year only
        } else if (dateWrapper.yearPart && !dateWrapper.monthPart && !dateWrapper.dayPart) {
            date.setFullYear(+dateWrapper.yearPart);
            subjectLineDate = moment(date).format('YYYY');
        // Month and day
        } else if (dateWrapper.monthPart && dateWrapper.dayPart && !dateWrapper.yearPart) {
            date.setFullYear(currentYear, +dateWrapper.monthPart - 1, +dateWrapper.dayPart);
            subjectLineDate = moment(date).format( 'MMMM DD');
        // Month and year
        } else if (dateWrapper.monthPart && dateWrapper.yearPart && !dateWrapper.dayPart) {
            date.setFullYear(+dateWrapper.yearPart, +dateWrapper.monthPart - 1)
            subjectLineDate = moment(date).format('MMMM, YYYY');
        }

        return subjectLineDate;
    }

    createSubjectLineForAssign() : string {
        let subjectLine = '';
        if (this.matter.isCustomMatter()) {
            subjectLine = this.matter.customMatterTypeDesc ? (subjectLine + ' ' + this.matter.customMatterTypeDesc + ' ') : subjectLine;
        } else {
            subjectLine = this.matter.clientReLine? this.matter.clientReLine : '??????';
            subjectLine += this.getConnectingText();

            if(this.matter.isMortgage){
                this.getMortgageLenderReLine();
            } else {
                subjectLine += this.matter.otherPartyContactInfo && this.matter.otherPartyContactInfo.reline ? this.matter.otherPartyContactInfo.reline : '??????';
            }
        }
        return subjectLine;
    }

    createAgentSubjectLine() : void {
        if (this.matter.isCustomMatter()) {
            //Description from Matter Opening
            this.subjectLine = this.matter.matterPropertyWithCondo.legalDescriptionSummary ? this.matter.matterPropertyWithCondo.legalDescriptionSummary : '??????';
            this.subjectLine = this.matter.customMatterTypeDesc ? (this.subjectLine + ' ' + this.matter.customMatterTypeDesc + ' ') : this.subjectLine;
            if(this.matter.fileNumber || this.matter.matterRecordNumber){
                this.subjectLine += ' - Our File No. ' + (this.matter.fileNumber? this.matter.fileNumber : this.matter.matterRecordNumber);
            }
        } else {
            if (!this.matter.isProjectSale) {
                this.subjectLine = this.matter.clientReLine ? this.matter.clientReLine : '??????';
                this.subjectLine += this.getConnectingText();
            }

            if(this.matter.isMortgage){
                this.getMortgageLenderReLine();
            }
            else {
                this.subjectLine += this.matter.otherPartyContactInfo && this.matter.otherPartyContactInfo.reline ? this.matter.otherPartyContactInfo.reline : '??????';
            }

            if(this.matter.matterProperties.length && this.matter.matterProperties[0].address && this.matter.matterProperties[0].address.addressTextWithoutProvinceAndPostalCode){
                this.subjectLine += '; ' + this.matter.matterProperties[0].address.addressTextWithoutProvinceAndPostalCode;
            }
            if(this.matter.fileNumber || this.matter.matterRecordNumber){
                this.subjectLine += '; Our File No. ' + (this.matter.fileNumber? this.matter.fileNumber : this.matter.matterRecordNumber);
            }
        }

    }

    getMortgageLenderReLine() : void {
        if(this.matter.mortgages && this.matter.mortgages.length){
            let mortgage;
            if(this.mortgage){
                mortgage = this.mortgage;
            }
            else {
                //Get the highest priority New Mortgage
                let sortedMortgages = this.matter.mortgages.sort((a: Mortgage,b: Mortgage) => {
                    return (a.mortgagePriority < b.mortgagePriority && a.isUnityNewMortgage() && b.mortgageType ) ? -1 : 1;
                });
                mortgage = sortedMortgages && sortedMortgages.length? sortedMortgages[0] : null;
            }

            if(mortgage){
                this.matter.reCalculateLenderReLine(mortgage);
                this.subjectLine += mortgage.lenderReline ? mortgage.lenderReline : '??????';
            }
            else {
                this.subjectLine += '??????';
            }

        }else {
            if(this.matter && !this.matter.isMatterTypeDischarge){
                this.subjectLine += '??????';
            }
        }
    }

    createMortgageSubjectLine() : void {
        this.subjectLine = this.matter.clientReLine? this.matter.clientReLine : '??????';
        if(this.mortgage && this.matter.isPurchase){
            this.subjectLine += ' ' + this.mortgage.mortgagePriority + this.getPriorityExt(this.mortgage.mortgagePriority) + ' mortgage';
        }
        if(!this.matter.isPurchase){
            this.subjectLine += this.getConnectingText();
        }

        if(this.mortgage && this.matter.isSale) {
            this.subjectLine += this.matter.otherPartyContactInfo && this.matter.otherPartyContactInfo.reline? this.matter.otherPartyContactInfo.reline : '??????' ;
        }
        if(this.mortgage && this.matter.isMortgage && !this.mortgage.isExistingMortgage() ) {
            this.subjectLine += this.mortgage.lenderReline ? this.mortgage.lenderReline : '??????' ;
        }

        if(this.matter.matterProperties.length && this.matter.matterProperties[0].address && this.matter.matterProperties[0].address.addressTextWithoutProvinceAndPostalCode){
            this.subjectLine += '; ' + this.matter.matterProperties[0].address.addressTextWithoutProvinceAndPostalCode;
        }
        if(this.matter.fileNumber || this.matter.matterRecordNumber){
            this.subjectLine += '; Our File No. ' + (this.matter.fileNumber? this.matter.fileNumber : this.matter.matterRecordNumber);
        }
        if(this.mortgage && this.mortgage.loanNo){
            this.subjectLine += '; Mortgage Loan. No.' + this.mortgage.loanNo;
        }
    }

    getConnectingText() : string{
        if(this.matter.isPurchase){
            return ' p/f ';
        }
        if(this.matter.isSale){
            return ' s/t ';
        }
        if(this.matter.isMortgage){
            //return ' mtg to ';
            return this.matter.isMatterTypeDischarge ? ' discharge' : this.getMtgToText();
        }
        if(this.matter.isOpportunityMatter()) {
            return ': '
        }
        return ': ';
    }

    getPriorityExt(priority: number) : string {
        let str = '';
        //switch (priority.toString().split('').pop()) { // To get the last digit of the number
        switch (priority) {
            case 1  : str = 'st'; break;
            case 2  : str = 'nd'; break;
            case 3  : str = 'rd'; break;
            default : str = 'th'; break;
        }
        return str;
    }

    getMtgToText() : string {
        if(this.mortgage && this.mortgage.isExistingMortgage()){
            return ' refinance';
        }
        else {
            if( this.matter.actingFor === ActingForValues.MORTGAGEE || this.matter.actingFor === ActingForValues.BOTH_MORTGAGEE_PRIMARY ){
                return ' mtg loan to ';
            }
            else {
                return ' mtg to ';
            }
        }

    }

    openLocalEmailClient(recipientEmail : string , subject : string , body? : string) : Observable<boolean> {
        let mailToUrl = 'mailto:' + recipientEmail + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
        if(Utils.checkIfMailToUrlIsOverLimit(mailToUrl, true)) {
           return this.dialogService.confirm('Confirmation', 'The email message to be sent is too long for Unity to pass to your email client. The message body has been copied to your clipboard. Please paste it (ctrl+V) into the email that will be opened.', true, 'Ok').map(
                () => {
                    this.clipboardService.copyFromContent(body)
                    mailToUrl = 'mailto:' + recipientEmail + '?subject=' + encodeURIComponent(subject);
                    DpEmailDirective.initiateSendWithLocalEmailClient(mailToUrl);
                    return true;
                });
        }
        else{
            DpEmailDirective.initiateSendWithLocalEmailClient(mailToUrl);
            return Observable.of(true);
        }
    }

}
