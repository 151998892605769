import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {UserProfilesService} from '../../../shared-main/user-profiles.service';
import {User} from '../../../matters/shared/user';
import {AuthenticationService} from '../../../core/authentication.service';
import {Router} from '@angular/router';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {messages} from '../../../common';
import {AuthorizationService} from '../../../shared-main/authorization/authorization-service';
import {ErrorDirectiveMapping} from '../../../shared/error-handling/error-directive-mapping';
import {appRoutesUrlParts} from '../../../app.routes';
import {AcceptTermsConditions} from '../accept-terms-conditions/accept-terms-conditions';
import {AcceptTermsConditionsService} from '../accept-terms-conditions/accept-terms-conditions.service';
import {LockScreenService} from '../../../core/lock-screen.service';
import {ReferenceDataService} from '../../../shared-main/reference-data/reference-data.service';
import {UUIDService} from '../../../main/uuid.service';
import {UtilsService} from '../../../main/utils.service';

@Component({
               selector    : 'dp-setup-password',
               templateUrl : './setup-password.component.html',
               styleUrls      : [
                   '../../unity-login.styles.scss'
               ]
           })

export class SetupPasswordComponent implements OnInit{
    @ViewChild("setupPasswordForm") setupPasswordForm : NgForm;

    password : string;
    passwordRetype : string;
    user: User;
    isForgotPassword: boolean;
    errorMessage : string;
    constructor(public userProfilesService : UserProfilesService,
                public authenticationService : AuthenticationService,
                public router : Router,
                public authorizationService : AuthorizationService,
                public acceptTermsConditionsService : AcceptTermsConditionsService,
                public lockScreenService : LockScreenService,
                public referenceDataService: ReferenceDataService,
                public uuidService: UUIDService, public utilsService: UtilsService,
                public dialogService : DialogService) {}

    ngOnInit() {
        this.user = new User(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user)));
        if(this.router && this.router.url && this.router.url.indexOf(appRoutesUrlParts.resetPassword) !== -1){
            this.isForgotPassword = true;
        }
        this.lockScreenService.lockForUpdate = false;
    }

    updateProfile() {
        this.errorMessage = '';
        if(!this.password || !this.passwordRetype || (this.password.trim() == "" && this.passwordRetype.trim() == "")) {
            this.errorMessage =  messages.firstLogin.passwordBothFields;
        }

        if(this.setupPasswordForm.invalid) {
            return;
        }

        if(this.password != this.passwordRetype)
        {
            this.errorMessage =  messages.firstLogin.passwordBothFieldsSame;
        }

        this.user.password = this.password;
        this.user.retypePassword = this.passwordRetype;
        this.lockScreenService.lockForUpdate = true;
        if(this.user.passwordChangeRequired && !this.isForgotPassword){
            this.changeUserExpiredPassword();
        } else {
            this.resetPassword();
        }

    }

    changeUserExpiredPassword() {
        this.userProfilesService.updateUserPwd(this.user).subscribe(
            (res) => {
                this.user.password = null;
                this.user.retypePassword = null;
                this.navigateToNextStep(this.user);

            },
            (error : any) => {
                this.lockScreenService.lockForUpdate = false;
                if(error && error.errorCode === 'app.passwordAlreadyUsed') {
                    let errorDef = ErrorDirectiveMapping["user.password.used"];
                    if(errorDef) {
                        this.errorMessage = errorDef.ERROR_MESSAGE;
                    }
                }
                else if(error && error.errorCode === 'app.userLocked') {
                    this.errorMessage = messages.loginPage.locked;
                }
                else{
                    this.errorMessage = messages.loginPage.internal;
                }
            }
        );
    }

    resetPassword() {
        this.userProfilesService.updateUser(this.user, this.isForgotPassword).subscribe(
            (res: User) => {
                if(this.isForgotPassword){
                    //res User is missing some information so we are using this.user for the session setup
                    this.authenticationService.setUpUserSession(this.user);
                } else {
                    this.authenticationService.setUpUserSession(res);
                }
                this.navigateToNextStep(res);

            },
            (error : any) => {
                this.lockScreenService.lockForUpdate = false;
                if(error && error.errorCode === 'app.passwordAlreadyUsed') {
                    let errorDef = ErrorDirectiveMapping["user.password.used"];
                    if(errorDef) {
                        this.errorMessage = errorDef.ERROR_MESSAGE;
                    }
                }
                else if(error && error.errorCode === 'app.userLocked') {
                    this.errorMessage = messages.loginPage.locked;
                }
                else{
                    this.errorMessage = messages.loginPage.internal;
                }


            }
        );
    }


    navigateToNextStep(authenticatedUser: User){
        if(this.isForgotPassword) {
            //Coming from password reset flow
            this.authorizationService.navigateToGetRedirectUrl();
        } else {
            if(authenticatedUser.trustedPhoneNumberProvided){
                //Coming from password expiry flow
                if(authenticatedUser.caslAccepted){
                    this.authorizationService.initializationAfterLogin(this.referenceDataService, this.uuidService);
                    this.checkTermsAndConditionsVersionUptoDate(authenticatedUser);


                } else {
                    this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.acceptCasl}`]);
                }
            } else {
                //Coming from first time login flow
                this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.trustedPhoneNumber}`]);
            }
        }
    }

    checkTermsAndConditionsVersionUptoDate(user: User){
        this.acceptTermsConditionsService.getTermsAndConditions().subscribe(
            (res: AcceptTermsConditions) => {
                if(res){
                    let termsAcceptedDate = res.createdDate;
                    if(termsAcceptedDate <= user.termsAcceptedDate){
                        this.authorizationService.navigateToGetRedirectUrl();
                    }
                    else {
                        this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.termsAndConditions}`]);
                    }
                }

            },
            (error) =>{
                this.errorMessage = messages.loginPage.internal;
            }
        );
    }
}

