import {
    Component,
    ElementRef,
    ViewChild,
    Inject,
    OnInit,
    Output,
  } from '@angular/core';
  import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
  } from '@angular/material/dialog';
import { UtilsService } from '../utils.service';
  
  @Component({
    selector: 'app-chataitermsconditions',
    templateUrl: 'chat-AI-terms-condition.component.html',
    styleUrls: ['chat-AI-terms-condition.component.scss'],
  })
  export class ChatAITermsConditionsComponent implements OnInit {
    enableDeeDeeAI: boolean = false;
    isAcceptTnCRadioON: boolean = false;
    termsconditionsContent: string = '';
    isLoading: boolean = false;
  
    constructor(
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<ChatAITermsConditionsComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private utilsService: UtilsService
    ) {}
  
    ngOnInit() {
      this.isLoading = true;
      this.utilsService
        .getTermsnConditionsContent()
        .subscribe((result: any) => {
          this.isLoading = false;
          if (result && result.tc) {
            this.termsconditionsContent = result.tc.html;
          }
          if (this.isAcceptTnCRadioON) {
            this.enableDeeDeeAI = true;
          }
        });
    }
  
    closeModal() {
      this.dialogRef.close({ data: 'closeTnC' });
    }
  
    toggleEnableDeeDeeAIBtn(event: any) {
      if (event.checked && !this.isLoading) {
        this.isAcceptTnCRadioON = true;
        this.enableDeeDeeAI = true;
      } else if (event.checked) {
        this.isAcceptTnCRadioON = true;
      } else {
        this.isAcceptTnCRadioON = false;
        this.enableDeeDeeAI = false;
      }
    }
  
    EnableDeeDeeAI() {
      this.dialogRef.close({ data: 'showAI' });
    }
  }  