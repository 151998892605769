
export type EFormType = 'MORTGAGE' | 'CAVEAT' | 'DISCHARGE' | 'TRANSFER_OF_LAND' | 'UNIVERSAL_FORM' | 'CAVEAT_AOR' | 'TRANSFER';

export const EFormTypes : any = {
    MORTGAGE : 'MORTGAGE' ,
    CAVEAT : 'CAVEAT',
    CAVEAT_AOR : 'CAVEAT_AOR',
    DISCHARGE : 'DISCHARGE',
    TRANSFER_OF_LAND : 'TRANSFER_OF_LAND',
    TRANSFER : 'TRANSFER',
    UNIVERSAL_FORM : 'UNIVERSAL_FORM'
};

export class AltoEFormRequest {

    eFormType : EFormType;

    userName : string;

    userPassword : string;

    eFormIdentifier: string;

    documentType: string;

    documentId : number;

}
