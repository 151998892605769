import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent} from '@angular/router';
import {AppLoaderService} from './core/app-loader-service';
import {HttpClient} from './core';
import {accountApi} from './admin/accounts/shared/account-api';
import {UserRedirect} from './matters/shared/user';
import {DialogService} from './shared/dialog/dialog.service';

@Component({
    selector: 'dppm-app-loader',
    templateUrl: 'app-loader.component.html'
})


export class DppmAppLoaderComponent implements OnInit {

    isComponentLoading: boolean = false;
    scheduler: number = 0;
    callStack: string[] = ['main/tabs/matters',
        'main/projects/list',
        'main/admin/home',
        'main/contacts/home'];
    callStackCounter: number = 0;
    isLoginLoaded: boolean = false;
    redirectTokenId: string;
    redirectToken: string;

    constructor(public route: ActivatedRoute,
                public router: Router,
                public appLoaderService: AppLoaderService,
                public httpClient: HttpClient,
                public dialogService: DialogService
    ) {

        router.events.subscribe(
            (event: RouterEvent): void => {

                if (event instanceof RouteConfigLoadStart) {
                    this.isComponentLoading = true;

                } else if (event instanceof RouteConfigLoadEnd) {
                    this.isComponentLoading = false;
                }

            });
    }

    ngOnInit() {
        this.route
            .queryParams
            .subscribe(params => {
                // Defaults to 0 if no query param provided.
                this.redirectTokenId = params['redirectTokenId'];
                this.redirectToken = params['redirectToken'];
            });
        this.appLoaderService.isLoaderInitialize = true;
        this.checkLoadingStatus();

    }

    checkLoadingStatus(): void {
        if (!this.isComponentLoading && this.callStackCounter < this.callStack.length) {
            this.appLoaderService.loadedModuleCount = Math.round(((this.callStackCounter * 100) / this.callStack.length));
            this.stopPolling();
            this.router.navigate([this.callStack[this.callStackCounter], {moduleLoading: true}], {skipLocationChange: true});
            this.callStackCounter++;
            this.startPolling();

        } else if (!this.isComponentLoading && this.callStackCounter == this.callStack.length && this.appLoaderService.loadedModuleCount < 100) {
            this.appLoaderService.loadedModuleCount = Math.round(((this.callStackCounter * 100) / this.callStack.length));
            setTimeout(() => {
                this.isLoginLoaded = true;
                this.appLoaderService.isAllModuleLoaded = true;
                if (this.redirectTokenId && this.redirectToken) {
                    let url: string = accountApi.redirectUser(this.redirectTokenId, this.redirectToken);
                    this.redirectTokenId =  undefined;
                    this.redirectToken = undefined;
                    this.httpClient.get(url).subscribe(
                        response => {
                            if (response && response['UserRedirect']) {
                                const userRedirect = response['UserRedirect'];
                                if(userRedirect.redirectType == 'MATTER' && userRedirect.redirectId){
                                    this.appLoaderService.currentStateRoute = "main/tabs/matters?redirect="+userRedirect.redirectId+"&section="+userRedirect.section;
                                }
                                else{
                                    this.appLoaderService.currentStateRoute = "main/messages/redirectList";
                                }
                                if(userRedirect && !!userRedirect.sessionActive) {
                                    let message = '<b>A Unity session is already open.  Do you want to:</b>'
                                    message = message + '<br><br>' + 'A) Start a new session with the selected matter opened and close the old session?  Note that any work in the old session with be lost.'
                                    message = message + '<br><br>' + 'B)  Cancel and keep that session.  You will have to look at the Messages screen to find the given notification.'
                                    this.dialogService.confirm('Warning', message, false, 'Start New Session').subscribe((open) => {
                                        if (open) {
                                            this.router.navigate([this.appLoaderService.currentStateRoute]);
                                        } else {
                                            window.close();
                                        }
                                    })
                                }
                                else{
                                    this.router.navigate([this.appLoaderService.currentStateRoute]);
                                }
                            }
                            else{
                                this.router.navigate(['login/home']);
                            }
                        },
                        error => {
                            this.router.navigate(['login/home']);
                        });
                } else if (this.appLoaderService.currentStateRoute) {
                    this.router.navigate([this.appLoaderService.currentStateRoute]);
                } else {
                    this.router.navigate(['login/home']);
                }

            }, 1000);

        } else if (this.isLoginLoaded) {
            window.clearInterval(this.scheduler);
        }
    }

    startPolling(): void {
        window.clearInterval(this.scheduler);
        this.scheduler = window.setInterval(() => {
            this.checkLoadingStatus();
        }, 1000);
    }

    stopPolling(): void {
        window.clearInterval(this.scheduler);
    }

}


