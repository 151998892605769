import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {AuthorizationService} from './authorization/authorization-service';

@Directive({
               selector: '[user-access]',
           })
export class UserAccessDirective implements AfterViewInit {

    @Input('user-access') data;

    constructor(private elementRef: ElementRef , private rendrer: Renderer2, private  authorizationService : AuthorizationService) {}

    ngAfterViewInit() {
        if(this.data.operationName != null && this.data.permissionName != null)
        {
            if(!this.authorizationService.hasPermission(this.data.operationName,this.data.permissionName))
            {
                this.hideElement();
            }

        }
        else if(this.data.operationName != null)
        {
            if(!this.authorizationService.hasAccess(this.data.operationName))
            {
               this.hideElement();

            }

        }

    }

    hideElement() {
        this.rendrer.setStyle(this.elementRef.nativeElement, 'display','none');
    }

    setReadonly(){
        this.elementRef.nativeElement.attr.readonly = true;
    }

}
