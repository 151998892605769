import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {DepositReceivingStatus, DepositStatus} from './deposit';
import {Utils} from './utils';

export class ProjectDepositView extends BaseEntity{


    matterId: number;
    cusomterAccountId: number;
    fileNumber: string;
    projectRecordNumber: string;
    matterRecordNumber: string;
    depositId:  number; // unique key in data collection
    depositAmount:  number;
    depositName: string;
    depositDate: string;
    provinceCode: ProvinceCode;
    form4Sent: boolean;
    form4Date: string;
    daysElapsedValue : string;
    depositStatus: DepositStatus;
    isPaidOnOccupancy: boolean;


    constructor(pdv? : ProjectDepositView) {
        super(pdv);
    }

    get depositReceivingStatus(): DepositReceivingStatus {
        let days: number =  Utils.getDateDiff(this.depositDate,Utils.todaysDate());
        if(this.form4Sent || (!this.form4Sent && this.depositStatus == 'NSF' || this.depositStatus == 'NH')) {
           return DepositReceivingStatus.SENT;
        } else if(!this.form4Sent && this.depositStatus != 'NSF' && this.depositStatus != 'NH' && days >=0 && days <= 9) {
            return DepositReceivingStatus.RECEIVED_WITHIN_9_DAYS;
        } else if(!this.form4Sent && this.depositStatus != 'NSF' && this.depositStatus != 'NH' && days > 9) {
            return DepositReceivingStatus.RECEIVED_PAST_9_DAYS;
        } else if(!this.form4Sent &&  days < 0) {
            return DepositReceivingStatus.POST_DATED;
        } else {
            return DepositReceivingStatus.NO_STATUS;
        }
    }


}

export class ProjectDepositManagerResponse {

    projectDepositView: ProjectDepositView[];
    projectDepositTotalCnt: number;
    projectDepositTotalAmt : number;
    matterIds:[];

    constructor(projectDepositView : ProjectDepositView[], projectDepositTotalCnt : number, projectDepositTotalAmt : number, matterIds: []) {
        this.projectDepositView = projectDepositView;
        this.projectDepositTotalCnt = projectDepositTotalCnt;
        this.projectDepositTotalAmt = projectDepositTotalAmt;
        this.matterIds = matterIds;
    }
}
