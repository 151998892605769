import moment from 'moment';
import Utils from './utils';
import {MortgagePayout} from '../matters/mortgages/mortgage/mortgage-payout/mortgage-payout';
import {MortgagePayoutAdditionalPayments} from '../matters/mortgages/mortgage/mortgage-payout/mortgage-payout-amount';

export class MortgageUtil {

    // this does not work for dates like Nov 11,2018 - Nov 10,2020
    static calculateInterestOldStyle(interestCalculatedFrom: string, interestCalculatedTo: string, principalAmount: number, interestRate: number): number{

        if ((moment(interestCalculatedTo, "YYYY/MM/DD") < moment(interestCalculatedFrom, "YYYY/MM/DD"))) {
            return 0;
        }

        let numberOfYears = (moment(interestCalculatedTo, "YYYY/MM/DD").diff(moment(interestCalculatedFrom, "YYYY/MM/DD"), 'years'));
        let paymentPerDay : number = 0;
        let yearOfInterestFrom = Number(interestCalculatedFrom.split('/')[0]);
        let numberOfDaysForSpecificYear: number = 0;
        let interestSubTotal : number = 0;
        let yearOfInterest : number = 0;
        let interest : number = 0;
        if(numberOfYears < 1)
        {

            //  interestPerDay = this.calculateInterestPerDay((yearOfInterestFrom), (this.interestRate / 100));
            paymentPerDay = this.calculatePaymentPerDay((yearOfInterestFrom), (principalAmount *(interestRate / 100)));
            numberOfDaysForSpecificYear = Number(this.getNumberOfDaysPerYear(interestCalculatedFrom, interestCalculatedTo, yearOfInterest, numberOfYears));
            interest = Number(numberOfDaysForSpecificYear) *  paymentPerDay;
        } else {
            numberOfYears=numberOfYears+1;
            for(let i = 0; i < numberOfYears; i++) {
                yearOfInterest = yearOfInterestFrom + i;
                if(i==0){
                    paymentPerDay = this.calculatePaymentPerDay((yearOfInterestFrom), (principalAmount *(interestRate / 100)));
                    numberOfDaysForSpecificYear = Number(this.getNumberOfDaysPerYear(interestCalculatedFrom, (yearOfInterest+1)+"/01/01", yearOfInterest, numberOfYears));
                    interestSubTotal = Number(numberOfDaysForSpecificYear) *  paymentPerDay;
                } else if(i==(numberOfYears-1)){
                    paymentPerDay = this.calculatePaymentPerDay((yearOfInterest), (principalAmount *(interestRate / 100)));
                    numberOfDaysForSpecificYear = Number(this.getNumberOfDaysPerYear(yearOfInterest+"/01/01", interestCalculatedTo, yearOfInterest, numberOfYears));
                    interestSubTotal = Number(numberOfDaysForSpecificYear) *  paymentPerDay;
                } else {
                    paymentPerDay = this.calculatePaymentPerDay((yearOfInterest), (principalAmount *(interestRate / 100)));
                    numberOfDaysForSpecificYear = Number(this.getNumberOfDaysPerYear(yearOfInterest+"/01/01", (yearOfInterest+1)+"/01/01", yearOfInterest, numberOfYears));
                    interestSubTotal = Number(numberOfDaysForSpecificYear) *  paymentPerDay;
                }

                interest = interest + Number(interestSubTotal.toFixed(2));
            }
        }
        return interest;
    }


    static calculateInterest(interestCalculatedFrom: string, interestCalculatedTo: string, principalAmount: number, interestRate: number, includeLastDay?: boolean,isPaysForDateOfClosingVendor?:boolean): number{
         console.log('calculateInterest : '+interestCalculatedFrom+' to '+interestCalculatedTo);
        if (!interestCalculatedFrom || !interestCalculatedTo || principalAmount == 0){
            return 0;
        }

        if ((moment(interestCalculatedTo, "YYYY/MM/DD") < moment(interestCalculatedFrom, "YYYY/MM/DD"))) {
            return 0;
        }

        let yearFrom: number = Number(interestCalculatedFrom.split('/')[0]);
        let yearTo: number = Number(interestCalculatedTo.split('/')[0]);
        console.log('yearFrom : '+yearFrom+' yearTo '+yearTo);
        if (yearFrom !== yearTo) {
            return Utils.roundNumber(this.calculateInterest(interestCalculatedFrom, `${yearFrom}/12/31`    , principalAmount, interestRate, true,isPaysForDateOfClosingVendor) +
                   this.calculateInterest(`${yearFrom + 1}/01/01`, interestCalculatedTo, principalAmount, interestRate, includeLastDay,isPaysForDateOfClosingVendor), 2);
        }

        let paymentPerDay: number = this.calculatePaymentPerDay((yearFrom), (principalAmount * (interestRate / 100)));
        let numberOfDaysForSpecificYear: number = Number(this.getNumberOfDays(interestCalculatedFrom, interestCalculatedTo));
        if(isPaysForDateOfClosingVendor){
            numberOfDaysForSpecificYear++;
        }
        if (includeLastDay){
            numberOfDaysForSpecificYear ++;
        }
        console.log('Interest : '+(Number(numberOfDaysForSpecificYear) *  paymentPerDay));
        return Utils.roundNumber(Number(numberOfDaysForSpecificYear) *  paymentPerDay, 2);
    }

    static getNumberOfDays(fromDate: string,  toDate: string): number {
        let dateTo =  moment(toDate, "YYYY/MM/DD");
        let dateFrom =  moment(fromDate, "YYYY/MM/DD");
        return dateTo.diff(dateFrom, 'days');
    }

    static getNumberOfDaysPerYear(fromDate : string,  toDate : string,  dateYear : number , numberOfYears: number) : number {
        let dateTo =  moment(toDate, "YYYY/MM/DD");
        let dateFrom =  moment(fromDate, "YYYY/MM/DD");
        let dateYearEnd =  moment((dateYear+"/12/31"), "YYYY/MM/DD");
        if(numberOfYears==0)
        {
            return dateTo.diff(dateFrom, 'days');
        } else if(dateTo > dateYearEnd)  {
            return  moment((dateYear+1)+"/01/01", "YYYY/MM/DD").diff(dateFrom, 'days');
        } else {
            return  dateTo.diff(moment((dateYear)+"/01/01", "YYYY/MM/DD"), 'days');
        }
    }

   static calculatePaymentPerDay(year: number , payment : number) : number {
        if(new Date(year, 1, 29).getDate() === 29)
        {
            return payment/366;
        }
        else{
            return payment/365;
        }
    }

    static initializeMortgagePayout(mortgagePayout: MortgagePayout) : void {
        if(!mortgagePayout.mortgageInterestType)
        {
            mortgagePayout.mortgageInterestType = 'PER_DIEM';
        }
        if(!mortgagePayout.separateChequeForDischargeFee)
        {
            mortgagePayout.separateChequeForDischargeFee = 'N_y';
        }
        if(!mortgagePayout.mortgagePayoutAdditionalPayments || ( mortgagePayout.mortgagePayoutAdditionalPayments &&
            mortgagePayout.mortgagePayoutAdditionalPayments.length == 0))
        {
            mortgagePayout.mortgagePayoutAdditionalPayments = [];
            mortgagePayout.mortgagePayoutAdditionalPayments.push(new MortgagePayoutAdditionalPayments());
            mortgagePayout.mortgagePayoutAdditionalPayments.push(new MortgagePayoutAdditionalPayments());
            mortgagePayout.mortgagePayoutAdditionalPayments.push(new MortgagePayoutAdditionalPayments());
            mortgagePayout.mortgagePayoutAdditionalPayments.push(new MortgagePayoutAdditionalPayments());
        }
    }


}
