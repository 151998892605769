import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {constValues} from '../shared/const-values';

export const BrokerCommissionDropDowns = {
    // drop down on Property / Teranet for Purchase Type
    isCommissionBasedSalePriceOptions  : [
        {label : '', value : ''},
        {label : 'Yes', value : DpBooleanValueTypes.YES},
        {label : 'No',  value : DpBooleanValueTypes.NO}
    ],

    depositHeldByOptionsForProjectSale : [
        {label : 'Vendor\'s broker', value : constValues.depositHeldBy.VENDOR_BROKER},
        {label : 'Purchaser\'s broker',  value : constValues.depositHeldBy.PURCHASER_BROKER}
    ],

    appliedTowardsPaymentOfCommissionOptions : [
        {label : 'Y/n', value : DpBooleanValueTypes.Y_n},
        {label : 'Yes', value : DpBooleanValueTypes.YES},
        {label : 'No',  value : DpBooleanValueTypes.NO}
    ],

    commissionDisbursedByBC : [
        {label : '', value : ''},
        {label : 'Buyer Lawyer/Notary', value : constValues.commissionDisbursedBy.PURCHASER_LAWYER},
        {label : 'Seller Lawyer/Notary',  value : constValues.commissionDisbursedBy.VENDOR_LAWYER},
    ],

    brokerReleaseExcessToBC : [
        {label : '', value : ''},
        {label : 'Buyer Lawyer/Notary', value : constValues.brokerReleaseExcessTo.PURCHASER_LAWYER},
        {label : 'Seller Lawyer/Notary',  value : constValues.brokerReleaseExcessTo.VENDOR_LAWYER},
        {label : 'Builder',  value : constValues.brokerReleaseExcessTo.BUILDER},
        {label : 'Developer',   value : constValues.brokerReleaseExcessTo.DEVELOPER}
    ],

    commissionDisbursedToBC : [
        {label : 'Listing Broker', value : constValues.commissionDisbursedTo.VENDOR_BROKER_ONLY},
        {label : 'Selling Broker', value : constValues.commissionDisbursedTo.PURCHASER_BROKER_ONLY},
        {label : 'Both Listing and Selling Brokers', value : constValues.commissionDisbursedTo.BOTH_VENDOR_AND_PURCHASER_BROKER},
    ]
}
