import {BrokerContactType, BrokerContactTypes, BrokerMatterLevelInfo} from '../broker-matter-level-info';
import {Matter} from '../matter';

export class MatterBrokerMatterLevelInfoUtil{

    static getBrokersMatterLevelInfo(matter: Matter, brokerContactType : BrokerContactType): BrokerMatterLevelInfo{

        let brokerMatterLevelInfo: BrokerMatterLevelInfo;
        if (matter) {
            if(Array.isArray(matter.brokersMatterLevelInfo)) {
                brokerMatterLevelInfo = matter.brokersMatterLevelInfo.find((item : BrokerMatterLevelInfo) => item.brokerContactType === brokerContactType)
            } else {
                matter.brokersMatterLevelInfo = [];
            }

            if(!brokerMatterLevelInfo) {
                brokerMatterLevelInfo = this.addNewBrokersMatterLevelInfo(matter, brokerContactType);
            }
        }
        return brokerMatterLevelInfo;
    }

    static addNewBrokersMatterLevelInfo(matter: Matter, brokerContactType : BrokerContactType) : BrokerMatterLevelInfo{
        let tmpBrokersMatterLevelInfo: BrokerMatterLevelInfo;
        if (matter) {
            tmpBrokersMatterLevelInfo = new BrokerMatterLevelInfo();
            tmpBrokersMatterLevelInfo.brokerContactType = brokerContactType;//ContactInfoType.LISTING_BROKER;
            matter.brokersMatterLevelInfo.push(tmpBrokersMatterLevelInfo);
        }
        return tmpBrokersMatterLevelInfo;
    }

    static removePurchaserBrokersMatterLevelInfo(matter: Matter){
        if (matter) {
            if(Array.isArray(matter.brokersMatterLevelInfo)) {
                let brokerMatterLevelInfoIndex : number = matter.brokersMatterLevelInfo.findIndex((item : BrokerMatterLevelInfo) => item.brokerContactType === BrokerContactTypes.PURCHASER_BROKER)
                if(brokerMatterLevelInfoIndex > -1) {
                    matter.brokersMatterLevelInfo.splice(brokerMatterLevelInfoIndex, 1);
                }

            }
        }
    }
}
