import {OverrideMatterStatusType} from '../index';
import {Matter} from '../matter';
import {MatterOverviewStatusTypesValue} from '../../../shared-main/constants';

export class MatterOverviewUtil {

    static getMatterOverviewStatus(matter: Matter): OverrideMatterStatusType {
        let matterOverviewStatus = MatterOverviewStatusTypesValue.MATTER_IN_PROGRESS;
        if(matter.isMatterReady) {
            matterOverviewStatus = MatterOverviewStatusTypesValue.MATTER_READY;
        } else if (matter.matterFlagged) {
            matterOverviewStatus = MatterOverviewStatusTypesValue.FLAGGED;
        } else if (matter.matterStatus == 'INACTIVE') {
            matterOverviewStatus = MatterOverviewStatusTypesValue.MATTER_INACTIVE;
        } else if (matter.closed == 'YES') {
            matterOverviewStatus = MatterOverviewStatusTypesValue.MATTER_CLOSED;
        } else if (matter.hasPastClosingDate(true)) {
            matterOverviewStatus = MatterOverviewStatusTypesValue.MATTER_PAST_CLOSING_DATE;
        }

        return matterOverviewStatus;
    }

    static getMatterOverviewStatusLabel(matter: Matter): string {
        switch (matter.matterOverviewStatus) {
            case MatterOverviewStatusTypesValue.MATTER_READY:
                return 'Ready';
            case MatterOverviewStatusTypesValue.FLAGGED:
                return 'Flagged';
            case MatterOverviewStatusTypesValue.MATTER_INACTIVE:
                return 'Inactive';
            case MatterOverviewStatusTypesValue.MATTER_CLOSED:
                return 'Closed';
            case MatterOverviewStatusTypesValue.MATTER_PAST_CLOSING_DATE:
                return 'Overdue';
            case MatterOverviewStatusTypesValue.MATTER_IN_PROGRESS:
            default:
                return 'In Progress';
        }
    }

    static getWorkItemsSummaryText(matter: Matter) : string {
        return this.getNumberOfWorkitemsTasks(matter) ? `${this.getNumberOfCompletedTasks(matter)}/${this.getNumberOfWorkitemsTasks(matter)}` : '';
    }

    static getNumberOfWorkitemsTasks(matter: Matter) : number {
        let numberOfWorkitemsTasks = 0;
        if(matter && matter.matterWorkItems && matter.matterWorkItems.length){
            numberOfWorkitemsTasks = matter.matterWorkItems.map(item => item.numberOfTasks).reduce((prev, next) => prev + next);
        }
        return numberOfWorkitemsTasks;
    }

    static getNumberOfCompletedTasks(matter: Matter) : number {
        let numberOfCompletedTasks = 0;
        if(matter && matter.matterWorkItems && matter.matterWorkItems.length){
            numberOfCompletedTasks = matter.matterWorkItems.map(item => item.numberOfCompletedTasks).reduce((prev, next) => prev + next);
        }
        return numberOfCompletedTasks;
    }

    static getNumberOfOverDueTasks(matter: Matter) : number {
        let numberOfOverDueTasks = 0;
        if(matter && matter.matterWorkItems && matter.matterWorkItems.length){
            numberOfOverDueTasks = matter.matterWorkItems.map(item => item.numberOfOverdueTasks).reduce((prev, next) => prev + next);
        }
        return numberOfOverDueTasks;
    }
}
