import {SoaFee} from '../../shared-main/statement-account/fee-config/soa-fees';
import {DisbursementConfig, DisbursementSubjectToType} from '../../shared-main/statement-account/disbursement-config/disbursement-config';
import {AccountingItemKeys, SoaConstants} from './soa-constants';
import * as _ from 'lodash';
import {MatterType} from '../../matters/shared/matter-specific-type';
import {AccountNationalSetting} from '../shared/general-ledger-account-list';
import {SoaTemplate} from './soa-template';

/**
 * Statement of Account Configuration class
 * used for loading all available disbursements and fees (HST, NO_HST or SALE/PURCHASE/MORTGAGE)
 * and any other Statement of Accounts settings like accountingSoftware, teranetConnectFees
 *
 * feeds the disbursement and fees tables on Admin -> Statement of Account
 */
export class SoaConfig {

    id : number
    teranetConnectFees : string;


    // UI only
    soaSelectedTemplate : SoaTemplate;
    subjectToHst : boolean;
    subjectToHstType : DisbursementSubjectToType = 'SUBJECT_TO_HST';
    dirty : boolean;

    accountNationalSetting: AccountNationalSetting; //accountNationalSetting is referenced in soaConfig for accessing accountingSoftware

    separateFeeForMortgage: boolean;

    soaTemplates : SoaTemplate[] = [];

    //accountNationalSetting should be always present but just doing defensive null check.
    get accountingSoftware(): string {
        return this.accountNationalSetting ? this.accountNationalSetting.accountingSoftware : null;
    }

    set accountingSoftware(accountingSoftware: string) {
        if(this.accountNationalSetting) {
            this.accountNationalSetting.accountingSoftware = accountingSoftware;
        }
    }

    get hstOnComplianceFees(): string{
        return this.accountNationalSetting ? this.accountNationalSetting.hstOnComplianceFees : null;
    }

    set hstOnComplianceFees(value: string) {
        if(this.accountNationalSetting) {
            this.accountNationalSetting.hstOnComplianceFees = value;
        }
    }

    constructor(soa? : SoaConfig) {
        for(let prop in soa) {
            // Temp checking since backend still send fees and disbursementsConfig to support migration code ...
            if(soa.hasOwnProperty(prop) && prop != 'fees' && prop != 'disbursementsConfig') {
                this[prop] = soa[prop];
            }
        }
        this.soaTemplates = [];
        if(soa && Array.isArray(soa.soaTemplates)) {
            for(let i : number = 0; i < soa.soaTemplates.length; ++i) {
                this.soaTemplates[i] = new SoaTemplate(soa.soaTemplates[i]);
            }
        }
    }

    get fees() : SoaFee[] {
        return this.soaSelectedTemplate ? this.soaSelectedTemplate.fees : [];
    }
    get disbursementsConfig() : DisbursementConfig[] {
        return this.soaSelectedTemplate ? this.soaSelectedTemplate.disbursementsConfig : [];
    }

    addRetailSalesTaxRow() : void {
        if(!this.disbursementsConfig.find(item => item.isRetailSalesTaxChattels())) {
            let soaPurchase = new DisbursementConfig();
            soaPurchase.nameOfDisbursement = SoaConstants.RETAIL_SALES_TAX_CHATTELS_TEXT;
            soaPurchase.calculated = true;
            soaPurchase.readOnly = false;
            soaPurchase.subjectToHstType = 'NOT_SUBJECT_TO_HST';
            soaPurchase.code = AccountingItemKeys[AccountingItemKeys.RETAIL_SALES_TAX_CHATTELS];
            soaPurchase.matterType = "PURCHASE";
            this.disbursementsConfig.push(soaPurchase);
        }
    }

    removeRetailSalesTaxRow() : void {
        let RetailSalesTaxChattelIndex = this.disbursementsConfig.findIndex(item => item.isRetailSalesTaxChattels());
        if(RetailSalesTaxChattelIndex > -1) {
            this.disbursementsConfig.splice(RetailSalesTaxChattelIndex, 1);
        }

    }

    getDisbursementsConfig(subjectToHstType: DisbursementSubjectToType){
        return this.disbursementsConfig.filter(dlItem => !dlItem.deleted && dlItem.subjectToHstType == subjectToHstType);
    }

    /**
     * returns (Purchase/Sale/Mortgage)disbursements with or without HST based on local subjectToHst value
     * @param {MatterType} matterType
     * @returns {DisbursementConfig[]}
     */
    getDisbursementsLibrary(matterType: MatterType): DisbursementConfig[]{
        return this.getDisbursementsConfig( this.subjectToHstType);
    }

    initializeStatementOfAccountFromConfig(systemSoaConfig : SoaConfig, matterType: MatterType, subjectToHstType: DisbursementSubjectToType) : void {

        _.remove(this.disbursementsConfig, function(item) {
            return item.subjectToHstType == subjectToHstType && item.matterType == matterType;
        });

        systemSoaConfig.getDisbursementsConfig(subjectToHstType)
                .forEach( item => {
                    if (!item.code && item.identifier) {
                        item.code = item.identifier.toString();
                    }
                    this.disbursementsConfig.push(item);

                });
    }

    /**
     * Resets the Statement of Account fees for the specified matter type back to system defaults.
     * @param systemSoaConfig
     * @param matterType
     */
    initializeSoaFees(systemSoaConfig : SoaConfig, matterType : string) : void {
        //Remove fees for specified matter type from main list of fees
        if(this.soaSelectedTemplate) {
            this.soaSelectedTemplate.fees = this.soaSelectedTemplate.fees.filter(fee => fee.matterType != matterType);
            //Get subset of fees from system config for specified matter type
            let resetFees: SoaFee[] = systemSoaConfig.feeList.filter(fee => fee.matterType == matterType);
            //Add default fees from system config to main list of fees.
            for (let i = 0; i < resetFees.length; i++) {
                let soaFee = new SoaFee(resetFees[i]);
                if (!soaFee.code && soaFee.identifier) {
                    soaFee.code = soaFee.identifier.toString();
                }
                this.fees.push(soaFee);
            }
        }
    }

    getSoaFeeForMortgage() : SoaFee {
        return (this.fees.find(soaFee => soaFee.isFeeForMortgage()));
    }

    getSoaFeeForAdditionalMortgage() : SoaFee {
        return (this.fees.find(soaFee => soaFee.isFeeForAdditionalMortgage()));
    }

    getMLTT() : DisbursementConfig {
        return (this.disbursementsConfig.find(soaPurchase => soaPurchase.isMLTTConfiguration()));
    }

    getCondoCertificate(withHst: boolean) : DisbursementConfig {
        return (this.getDisbursementsConfig(withHst ? 'SUBJECT_TO_HST' : 'NOT_SUBJECT_TO_HST').find(soaPurchase => soaPurchase.isCondoCertificationFee()));
    }

    getCondoDocuments(withHst: boolean) : DisbursementConfig {
        return (this.getDisbursementsConfig(withHst ? 'SUBJECT_TO_HST' : 'NOT_SUBJECT_TO_HST').find(soaPurchase => soaPurchase.isCondoDocumentsFee()));
    }

    getRetailTax() : DisbursementConfig {
        return (this.disbursementsConfig.find(soaPurchase => soaPurchase.isRetailSalesTaxChattels()));
    }

    getHSTCompliance() : DisbursementConfig {
        return (this.getDisbursementsConfig('SUBJECT_TO_HST').find(soaPurchase => soaPurchase.isCompliance()));
    }

    getNoHSTCompliance() : DisbursementConfig {
        return (this.getDisbursementsConfig( 'NOT_SUBJECT_TO_HST').find(soaPurchase => soaPurchase.isCompliance()));
    }

    get feeList() : SoaFee[] {
        return this.fees.filter(soaFee => !soaFee.deleted);
    }

    //Return set of fees for specified matter type
    feesForMatterType(matterType : string) : SoaFee[] {
        return this.feeList.filter(soaFee => soaFee.matterType == matterType && soaFee.enabled);
    }

    //Only keep fees belonging to specified matter type
    filterFees(matterType : string) : void {
        if(this.soaSelectedTemplate) {
            this.soaSelectedTemplate.fees = this.soaSelectedTemplate.fees.filter(soaFee => soaFee.matterType == matterType);
        }
    }

    get isTeranetConnectFeesCombined() : boolean {
        return this.teranetConnectFees == 'COMBINE_ALL_TERANET_CONNECT_FEE_INTO_SEARCH_COSTS_IN_SOA';
    }

    get isShowAllAsSubjectToHst(): boolean{
        return this.hstOnComplianceFees == 'SHOW_ALL_AS_SUBJECT_TO_HST';
    }

    //remove the Fee that without any matterType
    cleanUpFees(){
        if(Array.isArray(this.fees) && this.soaSelectedTemplate) {
            this.soaSelectedTemplate.fees = this.fees.filter(fee => fee.matterType != null);
        }
    }

    orderFeesByMatterType(): void {
        if(this.soaSelectedTemplate) {
            this.soaSelectedTemplate.fees = _.orderBy(this.fees, ['matterType']);
        }
    }
}
