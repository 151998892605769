import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {OpportunityMatterSourceType, OpportunityMatterStatusType, OpportunityMatterStatusValue} from './matter-specific-type';

export class OpportunityInfo extends BaseEntity {
    opportunityStatus: OpportunityMatterStatusType;
    opportunitySource: OpportunityMatterSourceType;
    totalCommission: number;
    mortgageNotes: string;
    initializationRequired: boolean;
    sourceReferralId: number;
    associatedToLinkedMatters: boolean;

    canBeMarkedAsDuplicate() {
        let statuses = [
            OpportunityMatterStatusValue.closedDuplicate,
            OpportunityMatterStatusValue.closedConverted
        ];
        return statuses.indexOf(this.opportunityStatus) == -1;
    }

    isClosedDuplicate() {
        return this.opportunityStatus == OpportunityMatterStatusValue.closedDuplicate;
    }
    isClosedConverted() {
        return this.opportunityStatus == OpportunityMatterStatusValue.closedConverted;
    }
}
