import {Contact} from '../../matters/shared/contact';
import {ContactSuggestionSalutationEnum} from './contact-suggestion-salutation-enum.enum';
import {ContactSuggestionSalutationInterface} from './contact-suggestion-salutation-interface';
import {ContactSuggestionSalutation} from './contact-suggestion-salutation';
import {ContactSuggestionDear} from './contact-suggestion-dear';
import {ContactSuggestionSalutationContinued} from './contact-suggestion-salutation-continued';
import {MatterParticipantWrapper} from '../../matters/shared/matter-participant-wrapper';
import {Matter, MatterParticipant} from '../../matters';
import {Utils} from '../../matters/shared';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {
    EnvelopeSalutationFormats,
    EnvelopeSalutationFormatType
} from '../../admin/document-profile/document-profile-edit/misc/EnvelopeSalutationFormats';
import * as _ from 'lodash';
import {GenderTypes} from '../contact-type-mapping';
import {TitleMr, TitleMrs, TitleMs} from '../../shared-main/constants';

export const DearMaxLength = 75;
export const SalutationOrContMaxLength = 200;
export class ContactSuggestion {

    private contactSuggestions: Map<ContactSuggestionSalutationEnum, ContactSuggestionSalutationInterface>;

    private suggestions: Map<ContactSuggestionSalutationEnum, Map<EnvelopeSalutationFormatType, string>>;
    private filteredSuggestions: Map<ContactSuggestionSalutationEnum, any[]>;
    private showDropDown: Map<ContactSuggestionSalutationEnum, boolean>;

    constructor() {
        this.contactSuggestions = new Map<ContactSuggestionSalutationEnum, ContactSuggestionSalutationInterface>();

        this.contactSuggestions.set(ContactSuggestionSalutationEnum.salutation, new ContactSuggestionSalutation());
        this.contactSuggestions.set(ContactSuggestionSalutationEnum.salutationContinued, new ContactSuggestionSalutationContinued());
        this.contactSuggestions.set(ContactSuggestionSalutationEnum.dear, new ContactSuggestionDear());

        this.suggestions = new Map<ContactSuggestionSalutationEnum, any>();
        this.filteredSuggestions = new Map<ContactSuggestionSalutationEnum, any[]>();
        this.showDropDown = new Map<ContactSuggestionSalutationEnum, boolean>();

        this.resetAllSuggestions();
    }

    resetSuggestions(type$: ContactSuggestionSalutationEnum) {
        this.suggestions.set(type$, new Map<EnvelopeSalutationFormatType, string>());
        this.filteredSuggestions.set(type$, []);
        this.showDropDown.set(type$, false);
    }

    resetAllSuggestions() {
        this.resetSuggestions(ContactSuggestionSalutationEnum.salutation);
        this.resetSuggestions(ContactSuggestionSalutationEnum.salutationContinued);
        this.resetSuggestions(ContactSuggestionSalutationEnum.dear);
    }

    static getSuggestionsArrayFormMap(suggestionsMap: Map<EnvelopeSalutationFormatType, string>) : string[]{
        if (suggestionsMap){
            return _.uniq(Array.from(suggestionsMap.values()));
        }
        return [];
        // return _.uniq(Array.from(suggestionsMap.values()))
    }

    /**
     *
     *
     * @param gender
     */
    static getTitleMr(gender: string, isMs: boolean = true): string {
        let ret: string = '';
        if (gender == GenderTypes.MALE) {
            ret = TitleMr;
        } else if (gender == GenderTypes.FEMALE) {
            ret = isMs ? TitleMs : TitleMrs;
        }
        return ret;
    }

    static truncateOptions(type$: ContactSuggestionSalutationEnum, suggestionOptions : string[]): string[]{
        let ret : string[] = [];
        if(Array.isArray(suggestionOptions)) {
            if(type$ == ContactSuggestionSalutationEnum.dear){
                ret = suggestionOptions.map(item=> Utils.truncateStringForDisplay(item, DearMaxLength));
            } else {
                ret = suggestionOptions.map(item=> Utils.truncateStringForDisplay(item, SalutationOrContMaxLength));
            }
        }
        return ret;
    }

    initSuggestions(contact: Contact, type$: ContactSuggestionSalutationEnum, matter?: Matter, participant?: MatterParticipant) {

        if (contact) {
            this.resetSuggestions(type$);
            let mappedSuggestion = this.contactSuggestions.get(type$);
            if (mappedSuggestion) {
                let mappedSuggestionInit;
                if (ContactSuggestionSalutationEnum.dear == type$) {
                    mappedSuggestionInit = mappedSuggestion.initSuggestions(contact, matter, participant);
                } else {
                    mappedSuggestionInit = mappedSuggestion.initSuggestions(contact);
                }
                this.suggestions.set(type$, mappedSuggestionInit);
                this.filteredSuggestions.set(type$, ContactSuggestion.truncateOptions(type$, ContactSuggestion.getSuggestionsArrayFormMap(this.suggestions.get(type$))));
                this.showDropDown.set(type$, this.filteredSuggestions.get(type$).length > 0);
            }
        }
    }

    initSuggestionsForMatterParticipants(participants: Array<MatterParticipantWrapper>, type$: ContactSuggestionSalutationEnum, matter: Matter) {

        if (participants) {
            this.resetSuggestions(type$);
            let mappedSuggestion = this.contactSuggestions.get(type$);
            if (mappedSuggestion) {
                let suggestionsMap = mappedSuggestion.initMatterParticipantsSuggestions(participants, matter);
                this.suggestions.set(type$, suggestionsMap);
                this.filteredSuggestions.set(type$, ContactSuggestion.truncateOptions(type$, ContactSuggestion.getSuggestionsArrayFormMap(suggestionsMap)));
                this.showDropDown.set(type$, this.filteredSuggestions.get(type$).length > 0);
            }
        }
    }

    initAllSuggestions(contact: Contact) {
        this.initSuggestions(contact, ContactSuggestionSalutationEnum.salutation);
        this.initSuggestions(contact, ContactSuggestionSalutationEnum.salutationContinued);
        this.initSuggestions(contact, ContactSuggestionSalutationEnum.dear);
    }

    initAllSuggestionsForMatterParticipants(participants: Array<MatterParticipantWrapper>, matter: Matter) {
        this.initSuggestionsForMatterParticipants(participants, ContactSuggestionSalutationEnum.salutation, matter);
        this.initSuggestionsForMatterParticipants(participants, ContactSuggestionSalutationEnum.salutationContinued, matter);
        this.initSuggestionsForMatterParticipants(participants, ContactSuggestionSalutationEnum.dear, matter);
    }

    filterSuggestions(event, type$: number) {

        let filtered: any[] = [];
        let nonFiltered: any[] = Array.from(this.suggestions.get(type$).values());

        for (let i = 0; i < nonFiltered.length; i++) {
            let suggestion = nonFiltered[i];
            if (suggestion.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
                filtered.push(suggestion);
            }
        }

        this.filteredSuggestions.set(type$, filtered);
    }

    getSuggestionsMap(type$: ContactSuggestionSalutationEnum): Map<EnvelopeSalutationFormatType, string> {
        return this.suggestions.get(type$);
    }

    getFilteredSuggestions(type$: ContactSuggestionSalutationEnum): any[] {
        return this.filteredSuggestions.get(type$);
    }

    getShowDropDown(type$: ContactSuggestionSalutationEnum): boolean {
        return this.showDropDown.get(type$);
    }

    getDearText(): string {
        let suggestions = this.getFilteredSuggestions(ContactSuggestionSalutationEnum.dear);
        if (suggestions && suggestions.length) {
            return suggestions[0] ? suggestions[0].substring(0, 75) : ''; //"dear" field has limitation of 75 charachters
        } else {
            return '';
        }
    }

    getDefaultSalutationLine1OrDearOption(key: EnvelopeSalutationFormatType, suggestionsMap: Map<EnvelopeSalutationFormatType, string>): string {
        return suggestionsMap.get(key);
    }

    setDefaultSalutationLine1DearFromConfig(contact: Contact, defaultDocumentProfile: DocumentProfile,
                                            suggestionsMap: Map<EnvelopeSalutationFormatType, string>, isDear: boolean) {
        if (contact && defaultDocumentProfile && suggestionsMap
            && (contact.gender === 'MALE' || contact.gender === 'FEMALE')) {
            if (defaultDocumentProfile && defaultDocumentProfile.miscDocumentProfile && defaultDocumentProfile.miscDocumentProfile.contactDefaultEnvelopeData) {
                let key = isDear
                    ? (contact.gender === 'MALE'
                        ? defaultDocumentProfile.miscDocumentProfile.contactDefaultEnvelopeData.maleDear
                        : defaultDocumentProfile.miscDocumentProfile.contactDefaultEnvelopeData.femaleDear)
                    : (contact.gender === 'MALE'
                        ? defaultDocumentProfile.miscDocumentProfile.contactDefaultEnvelopeData.maleSalutation
                        : defaultDocumentProfile.miscDocumentProfile.contactDefaultEnvelopeData.femaleSalutation);
                if (isDear) {
                    contact.dear = this.getDefaultSalutationLine1OrDearOption(key, suggestionsMap);
                } else {
                    contact.envelopeSalutationLine1 = this.getDefaultSalutationLine1OrDearOption(key, suggestionsMap);
                }

            }
        }
    }

    setMatterParticipantsSuggestionDefaultValueFromConfig(defaultDocumentProfile: DocumentProfile,
                                                          suggestionsMap: Map<EnvelopeSalutationFormatType, string>, isDear: boolean) {
        let ret: string = '';
        if (defaultDocumentProfile && suggestionsMap) {
            if (defaultDocumentProfile && defaultDocumentProfile.miscDocumentProfile && defaultDocumentProfile.miscDocumentProfile.contactDefaultEnvelopeData) {
                ret = suggestionsMap.get(isDear ? defaultDocumentProfile.miscDocumentProfile.contactDefaultEnvelopeData.coupleDear : defaultDocumentProfile.miscDocumentProfile.contactDefaultEnvelopeData.coupleSalutation);
            }
        }
        return ret;
    }

    static createMaleOrFemaleSuggestion(contact: Contact, envelopeSalutationFormatType: EnvelopeSalutationFormatType): string {
        let ret: string = '';
        if (contact && contact.contactName) {
            switch (envelopeSalutationFormatType) {
                //Mr. J. Smith or Ms. M. Board
                case EnvelopeSalutationFormats.MR_FLFN_LN:
                    ret = ContactSuggestion.getTitleMr(contact.gender) + contact.contactName.firstLetterFirstNameFormat + contact.contactName.lastNameFormat;
                    break;
                //Mr. John Henry Smith or Ms. Maria Board
                case EnvelopeSalutationFormats.MR_FN_LN:
                    ret = ContactSuggestion.getTitleMr(contact.gender) + contact.contactName.firstNamePlusSpace + contact.contactName.lastNameFormat;
                    break;
                //John Henry Smith
                case EnvelopeSalutationFormats.FN_LN:
                    ret = contact.contactName.firstNamePlusSpace + contact.contactName.lastNameFormat;
                    break;
                //John Henry
                case EnvelopeSalutationFormats.FN:
                    ret = contact.contactName.firstNameFormat;
                    break;
                default:
                    break;
            }
        }
        return ret;
    }

    /**
     *  contact of the wrappers not null have be checked
     * @param wrappers
     * @param envelopeSalutationFormatType
     */
    static createMaleOrFemaleParticipantsSuggestion(wrappers: Array<MatterParticipantWrapper>, envelopeSalutationFormatType: EnvelopeSalutationFormatType): string[] {
        let ret: string[] = [];

        wrappers.forEach(wrapper=>{
            const suggestion: string = ContactSuggestion.createMaleOrFemaleSuggestion(wrapper.matterParticipant.contact, envelopeSalutationFormatType);
            if(suggestion){
                ret.push(suggestion)
            }
        });

        return ret;
    }

    static getDefaultSuggestions(wrappers: Array<MatterParticipantWrapper>, contactSuggestionSalutationEnum: ContactSuggestionSalutationEnum): string[] {
        let ret: string[] = []

        if(Array.isArray(wrappers)){
            //Only populate Male, female and blank
            wrappers.filter(item=>item && item.matterParticipant && item.matterParticipant.contact
                            &&  (item.matterParticipant.contact.gender == GenderTypes.MALE
                              || item.matterParticipant.contact.gender == GenderTypes.FEMALE
                              || item.matterParticipant.contact.gender == GenderTypes.QUESTION))
                .forEach(wrapper=>{
                    let pushValue: string;
                    switch(contactSuggestionSalutationEnum){
                        case ContactSuggestionSalutationEnum.salutation:
                            pushValue =  wrapper.matterParticipant.contact.envelopeSalutationLine1;
                            break;
                        case ContactSuggestionSalutationEnum.salutationContinued:
                            pushValue =  wrapper.matterParticipant.contact.envelopeSalutationLine2;
                            break;
                        case ContactSuggestionSalutationEnum.dear:
                            pushValue =  wrapper.matterParticipant.contact.dear;
                            break;
                        default:
                            break;
                    }
                    if(pushValue){
                        ret.push(pushValue);
                    }
            });
        }
        // Remove duplicate items --> we don't need to remove duplicate
        // return _.uniq(ret);
        return ret;
    }
}

