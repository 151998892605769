import {Injectable} from '@angular/core';
import {StewartAssystUser} from '../../shared-main/telus/stewart-assyst-user';
import {TelusService} from '../../shared-main/telus/telus-service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {FCTService} from '../../shared-main/fct/fct-service';
import {StewartMortgageInstruction} from '../../shared-main/telus/stewart-mortgage-instruction';
import {LockScreenService} from '../../core/lock-screen.service';
import {CustomThirdpartyModalComponentModal} from '../../shared/modal/custom-third-party.modal.component';
import {ApplicationError} from '../../core';
import {FctEmpMessages, TelusEmpMessages} from '../../shared-main/emp-messages';
import SharedUtils from '../../shared-main/utils';
import { AppConfig } from '../../shared-main/app-configuration';

@Injectable()
export class EmpPortalAccessService {
    private solicitorID : number;
    private stewartAssystUser : StewartAssystUser;

    constructor(public dialogService: DialogService,
                public telusService: TelusService,
                public fctService: FCTService,
                public lockScreenService: LockScreenService,
                public appConfig : AppConfig
                ) {
    }

    openEmpPortal(mortgageInstruction: StewartMortgageInstruction,callback?: Function) : void{
        if (mortgageInstruction.isFctInstruction){
            this.retrieveFctDealStatus(mortgageInstruction,callback,true);
        } else {
            if (!this.stewartAssystUser) {
                if (mortgageInstruction.solicitorId) {
                    this.telusService.getTelusUserForSolicitor(mortgageInstruction.solicitorId).subscribe(
                        (telusUser: StewartAssystUser) => {
                            this.stewartAssystUser = telusUser;
                            setTimeout(() => {
                                this.openTelusPortal(mortgageInstruction, this.stewartAssystUser);
                            }, 0);
                        });
                }
            } else {
                this.openTelusPortal(mortgageInstruction, this.stewartAssystUser);
            }
        }
    }





    openFctPortalCheckCancellation(mortgageInstruction : StewartMortgageInstruction,callback?: Function): void {
        if (mortgageInstruction.solicitorId) {
            this.fctService.getFctRedirectUrlInDealContext("LLCDEALRO", mortgageInstruction.solicitorId, mortgageInstruction.dealId).subscribe(res => {
                // window.open(res, '_blank');
                let provider: string = mortgageInstruction.provider;
                this.openFctPortalInModal(provider, res, mortgageInstruction,callback);
            }, (error) => {

                console.log(error);
                let errorText = 'Unexpected error happened on Server, please try later or contact the Administrator';
                if (error.statusText && error.status) {
                    errorText = error.status + " - " + error.statusText;
                } else if (error.errorCode && error.message) {
                    errorText = error.message + " - " + error.errorCode;
                }
                this.dialogService.confirm('Error', errorText, true, 'OK').subscribe(res => {

                })

            });
        }
    }

    openFctPortalInModal(title: string, securedUrl: string,mortgageInstruction : StewartMortgageInstruction, callback?: Function): void {
        //open FCT EMP Portal in readonly mode
        this.dialogService.content({
            content: CustomThirdpartyModalComponentModal,
            fullScreen: true,
            widthXl: true,
            context: {
                title: title,
                thirdPartyUrl: securedUrl
            },
            onFulfillment: (result: any) => {
                this.retrieveFctDealStatus(mortgageInstruction, callback);
            }
        });
    }

    retrieveFctDealStatus(mortgageInstruction: StewartMortgageInstruction, callback?: Function, openPortal? : boolean) {
        //Retrieve FCT deal status
        if (mortgageInstruction.solicitorId) {
            this.fctService.refreshDealStatus(mortgageInstruction.solicitorId,
                mortgageInstruction.dealId).subscribe(result => {
                if (result) {
                    if (result.mortgageInstructionData && result.mortgageInstructionData.fctStatusData
                        && result.mortgageInstructionData.fctStatusData.isCancelled()){
                        let confirmationMessage = FctEmpMessages.EMP081.replace('{DEAL_ID}', mortgageInstruction.getDealIdForDisplay()).replace('{LENDER_NAME}', mortgageInstruction.lender?mortgageInstruction.lender:'')
                            .replace('{MORTGAGE_PRIORITY}', SharedUtils.getOrdinal(mortgageInstruction.mortgageInstructionData.mortgagePriority));
                        this.dialogService.confirm('Confirmation', confirmationMessage, true, 'OK').subscribe(res => {
                            if (res) {
                                if (callback){
                                    setTimeout(() => {
                                        callback();
                                    }, 0);
                                }
                            }
                        });
                    } else if (openPortal){
                        this.openFctPortalCheckCancellation(mortgageInstruction,callback);
                    }
                }
            });
        }
    }

    openTelusPortal(mortgageInstruction : StewartMortgageInstruction, stewartAssystUser: StewartAssystUser): void {
        this.lockScreenService.lockForUpdate = true;
        this.telusService.getTelusWebUrl(stewartAssystUser.id, 'Documents', mortgageInstruction.dealId, true).finally(() => {
            this.lockScreenService.lockForUpdate = false;
        }).subscribe(url => {
                console.log("Returned url:" + url);
                // window.open(url);
                if (url) {
                    this.openAssystRealEstatePortal("Unity® Lender Centre", url);
                }
            },
            (error: ApplicationError) => {
                this.showNoAssystRealEstateCredentialAlert(error, mortgageInstruction.dealId);
            });
    }

    openAssystRealEstatePortal(title: string, thirdPartyUrl: string) {
        this.dialogService.content({
            content: CustomThirdpartyModalComponentModal,
            fullScreen: true,
            context: {
                title: title,
                thirdPartyUrl: thirdPartyUrl,
            },
            onFulfillment: (result: any) => {
            }
        });
    }

    showNoAssystRealEstateCredentialAlert(error: ApplicationError, dealId){
        let errMsg: string = (error.errorCode == 'app.stewartAssystUserNotFoundForSolicitor') ? TelusEmpMessages.LCEMP28 : error.message;
        const isAssyst = "Assyst Real Estate";
        const dealID = dealId ? dealId : '[DEAL_ID]';
        errMsg = (errMsg.includes(isAssyst) ? 'Unity® Lender Centre Deal ID ' + dealID + ' has been archived and is no longer available in the Unity® Lender Centre portal. Documents can be accessed from the Matter Documents tab.' : errMsg);
        this.dialogService.confirm('Information', errMsg, true);
    }}
