import {HttpClient} from '../core';
import {Injectable} from '@angular/core';
import {userAccountProfilesApi} from '../admin/shared/user-account-profiles-api';
import {UserAccountProfile} from '../admin/staff-profiles/user-account-profile';
import {Contact} from '../matters/shared/contact';
import {User} from '../matters/shared/user';
import {AccessProfile} from '../admin/staff-profiles/access-profile';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {StatusBarMessages} from './status-bar-messages';
import {SESSION_STORAGE_KEYS} from '../shared';
import {StaffProfiles} from '../admin/staff-profiles/staff-profiles';

/** Account service defines all api calls for account modules
 */
@Injectable()
export class UserProfilesService {

    constructor(private httpClient : HttpClient) { }

    getUserProfiles(accountId: string, loginId?: string) {
        let url = userAccountProfilesApi.userProfiles.replace('{accountId}', accountId);
        if(loginId) {
            url += `?filter=user.loginId_EQ_${loginId}&filterIgnoreCase=true`;
        }
        return this.httpClient.get(url)
            .map((res) => {
                let sorted = _.sortBy(res["UserAccountProfiles"], ['user.fullName']);
                let profiles = [];
                sorted.forEach(profile => {
                    profiles.push(new UserAccountProfile(profile));
                });
                return profiles;
            });
    }

    getStaffProfiles(accountId: string, loginId?: string) {

        let url = userAccountProfilesApi.staffProfiles.replace('{accountId}', accountId) ;
        if(loginId) {
            url += `?filter=user.loginId_EQ_${loginId}`;
        }
        return this.httpClient.get(url)
                   .map((res) => {
                       let sorted = _.sortBy(res["UserAccountProfiles"], ['user.fullName']);
                       let profiles = [];
                       sorted.forEach(profile => {
                           profiles.push(new UserAccountProfile(profile));
                       });
                       return profiles;
                   });
    }

    getAccessProfileTypes() {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        return this.httpClient.get(userAccountProfilesApi.accessProfileTypes.replace('{accountId}', accountId))
                   .map((res) => {
                       return res["AccessProfileTypes"];
                   });
    }

    getUserProfilesDropdownOptions(accountId: string) {
        let url = userAccountProfilesApi.unassignedContacts.replace('*', accountId);
        return this.httpClient.get(url)
                   .map((res) => {
                       let options = [];
                       res["Contacts"].forEach(contact => {
                           options.push(new Contact(contact));
                       });
                       return options;
                   });
    }

    createUser(user : User, accountId: string) {
        return this.httpClient.post(userAccountProfilesApi.users.replace('{accountId}', accountId), JSON.stringify(user))
                   .map((res ) => {
                       return res;
                   });
    }

    updateUser(user : User, isForgotPassword?) {
        if(user) {
            if(isForgotPassword) {
                return this.httpClient.put(userAccountProfilesApi.resetPassword, JSON.stringify(user))
                           .map((res ) => {
                               return new User(res['User']);
                           });

            } else {
                return this.httpClient.put(userAccountProfilesApi.setupPassword, JSON.stringify(user))
                           .map((res ) => {
                               return new User(res['User']);
                           });
            }
        }
    }

    getAccessProfileStatusTypes() {
        return this.httpClient.get(userAccountProfilesApi.accessProfileStatusTypes)
                   .map((res ) => {
                       return res["AccessProfileStatusTypes"];
                   });
    }

    getAccessProfileById(accountId : number , accessProfileId : number) {
        return this.httpClient.get(`${userAccountProfilesApi.accessProfiles.replace('{accountId}', accountId.toString())}/${accessProfileId}`)
                   .map((res ) => {
                       return new AccessProfile(res["AccessProfile"]);
                   });
    }

    getPermissionSets(userType : string, customerAccountId : string) {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        if(customerAccountId){
            accountId = customerAccountId;
        }

        return this.httpClient.get(userAccountProfilesApi.permissionSets.replace('{accountId}', accountId).replace('{accessProfileType}', userType))
                   .map((res ) => {
                       return res["PermissionSets"];
                   });
    }

    createAccessProfile(accessProfile : AccessProfile, accountId: string) {
        return this.httpClient.post(userAccountProfilesApi.accessProfiles.replace('{accountId}', accountId), JSON.stringify(accessProfile))
                   .map((res ) => {
                       return new AccessProfile(res["AccessProfile"]);

                   });
    }

    updateAccessProfile(accessProfile : AccessProfile, accountId: string) {
        let url = userAccountProfilesApi.accessProfiles.replace('{accountId}', accountId) + '/' + accessProfile.id;
        return this.httpClient.put(url, JSON.stringify(accessProfile))
                   .map((res ) => {
                       return new AccessProfile(res["AccessProfile"]);

                   });
    }

    getUserProfilesByAccessProfile(accessProfile : AccessProfile, accountId: string) : Observable<StaffProfiles[]> {
        let url = userAccountProfilesApi.accessProfileUserProfiles.replace('{accountId}', accountId).replace('{accessProfileId}', String(accessProfile.id));
        return this.httpClient.get(url)
                   .map((res ) => {
                       let userProfiles : StaffProfiles[] = [];
                       res["StaffProfiles"].forEach((userProfile)=>{
                           userProfiles.push(new StaffProfiles(userProfile))
                       });
                       return userProfiles;

                   });
    }

    copyAccessProfileToCustomerAccounts(sourceAccessProfile : AccessProfile) {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = userAccountProfilesApi.copyProfiles.replace('{accountId}', accountId).replace('{accessProfileId}', sourceAccessProfile.id.toString());
        return this.httpClient.post(url, '')
            .map((res ) => {
                return StatusBarMessages.accessProfile.COPIED_SUCCESSFUL;
            }).catch((e: any) => {
                console.log("Error while copying access profile to customer accounts:", e);
                return StatusBarMessages.accessProfile.COPIED_FAIL;
            });
    }

    updateUserPwd(user : User) : Observable<User> {
        return this.httpClient.put(`${userAccountProfilesApi.credentials}`, user)
                   .map((res: any) => {
                       return new User(res['User']);
                   });
    }

    updateEmailAndUser(user : User ) : Observable<User> {
        return this.httpClient.put(`${userAccountProfilesApi.loginId}`, JSON.stringify(user))
                   .map((res: any) => {
                       return new User(res['User']);
                   });
    }


    resendLoginEmail(accountId : string, userId : string) : Observable<any>{
        return this.httpClient.get(`${userAccountProfilesApi.resendLoginLink}${accountId}/users/${userId}/registration/resendLoginLink`)
                   .map((res) => {
                        return res["RESULT"];

                   })
    }

    getUserByContactId(contactId: number, lockScreen? : boolean): Observable<User> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        return this.httpClient.get(userAccountProfilesApi.userByContactId.replace('{accountId}', accountId).replace('{contactId}', contactId+''), lockScreen ? lockScreen : false)
            .map((res) => {
                return new User(res["User"]);
            }).catch(e => {
                if(e.errorCode === 'app.userNotFound') {
                    return Observable.of(null);
                }
            });
    }
}
