import {BaseEntity} from '../../shared/BaseEntity/base-entity';


export class DriverLicenceInfo extends BaseEntity {
    name: string;
    surName: string;
    _firstName: string;
    _middleName: string;
    genderCode: string;
    birthDate: string; //yyyymmdd format
    licenceNumber: string;
    addressLine1: string;
    city: string;
    provinceCode: string;
    postalCode: string;
    country: string = 'CANADA';
    placeOfIssue: string;
    expiryDate: string; //yyyymmdd format

    get firstName(): string {
        if (this._firstName) {
            return this._firstName;
        }
        if (this.name) {
            let nameArr = this.name.split(',');
            return nameArr && nameArr.length ? nameArr[0] : '';
        } else {
            return '';
        }
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get middleName(): string {
        if (this._middleName) {
            return this._middleName;
        }
        if (this.name) {
            let names = this.name.split(',');
            if (names && names.length > 1) {
                names.shift();
                return names.toString();
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    set middleName(value: string) {
        this._middleName = value;
    }

    get clientName(): string {
        return (this.firstName ? (this.firstName + ' ') : '') +
            (this.middleName ? (this.middleName + ' ') : '') +
            (this.surName ? this.surName : '');

    }

    get gender(): string {
        return this.genderCode == '1' ? 'MALE' : this.genderCode == '2' ? 'FEMALE' : '';
    }

    isMale() : boolean {
        return this.genderCode == '1';
    }

    isFemale() : boolean {
        return this.genderCode == '2';
    }


    /**
     * This method format the date
     * @param input format is yyyymmdd
     * @returns output format is yyyy-mm-dd
     */
    formatDate(input: string): string {
        if (input && input.length == 8) {
            /*
            * When we choose a date in the date picker, the selection is stored and manipulated in GMT
            * Adding time to the date to avoid changing the date to the day before
            * According to Greenwich timezone, the least Canadian time zone is -7h so we are adding 9h to be safe
            */
            return [input.slice(0, 4), '-', input.slice(4, 6), '-', input.slice(6)].join('') + 'T09:00:00.000Z';
        } else {
            return '';
        }
    }
}
