import {BaseEntity} from '../../shared/BaseEntity/base-entity';


export type DirectionReFundType = 'BALANCE_OF_FUNDS_PAYABLE_TO' | 'FUNDS_PAYABLE_TO' ;


export class DirectionReFund extends BaseEntity {

    constructor(directionReFund? : DirectionReFund) {
        super(directionReFund);
    }

    id                  : number;
    matterId            : number;
    reFundName          : string;
    amount              : number;
    directionReFundType : DirectionReFundType;
    // isSelected is used for record UI status
    isSelected          : boolean ;
    progressionStatus   : string;
}
