import {ConfigurationOption} from './configuration-option';

export class ConfigurationOptionValue {

    configurationValue: string;
    configurationId: string;
    configurationOption: ConfigurationOption;

    constructor(configurationOptionValue: ConfigurationOptionValue) {
        this.configurationValue = configurationOptionValue.configurationValue;
        this.configurationId = configurationOptionValue.configurationId;
        this.configurationOption = new ConfigurationOption(configurationOptionValue.configurationOption);
    }

}
