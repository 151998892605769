import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {DpBooleanValue} from './dp-boolean';
import {ProvinceCode} from '../../admin/accounts/shared/account-province';
import {dropDowns} from '../../admin/accounts/shared/account-drop-downs';

export type ProvinceCapacity = 'NONE' | 'COMMISSIONER' | 'NOTARY';

export class ContactProvinceCapacity extends BaseEntity {

    constructor(contactProvinceCapacity?: ContactProvinceCapacity) {
        super(contactProvinceCapacity);
    }

    id: number;
    contactId: number;
    provinceCode: ProvinceCode;
    provinceCapacity: ProvinceCapacity;
    insertNameAddressToRegistrationDocument : DpBooleanValue;
    commissionExpiry: string;


    get provinceName() : string {
        let ProvinceOptions = dropDowns.accessibleProvinces;
        let provinceOption = ProvinceOptions.find(province => province.value === this.provinceCode);
        return provinceOption ? provinceOption.label : '';
    }

}

