import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import Utils from '../../../shared-main/utils';
import {FinalPurchaserHasPaidFeeType, SoAdjFinalFee} from '../model/so-adj-final-fee';

export class StatementAdjustmentDisplayFinalBaseFeeBuilder {

    static getDisplayItems(soAdj: SoAdjFinalFee, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder, finalType: string = 'occupancy'): StatementOfAdjustmentDisplayItemLine[]{
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        let interimAdjustAsDate = mainBuilder.matter.adjustAsAtClosingDateFlagInterim === 'SPECIFY'? mainBuilder.matter.adjustAsAtClosingDateInterim : mainBuilder.matter.matterOccupancyDate;
        let finalAdjustAsDate: string = mainBuilder.matter.adjustAsAtClosingDateFlag  === 'SPECIFY'? mainBuilder.matter.adjustAsAtClosingDate : mainBuilder.matter.matterCloseDate;

        sadItemDetails.push(mainBuilder.getNewItemLine(
            `Monthly ${finalType} fees`,
            mainBuilder.getCurrency(soAdj.amount)
        ));

        if (Utils.convertToBoolean(soAdj.applyTax)) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${soAdj.taxType} calculated at ${mainBuilder.getPercentage(soAdj.hstRate)}`,
                mainBuilder.getCurrency(Number(soAdj.amount) * Number(soAdj.hstRate) / 100)));
        }

        sadItemDetails.push(mainBuilder.getNewItemLine(
            this.getPaidByLabel(soAdj.purchaserHasPaidFee, toBeUsedOnModal, finalType),
            ''
        ));

        sadItemDetails.push(mainBuilder.getNewItemLine(
            this.getMonthLine(soAdj, interimAdjustAsDate, finalAdjustAsDate),
            ''
        ));

        let purchaserShare: number = soAdj.getPurchaserShareAmount(mainBuilder.matter.occupancyDate, interimAdjustAsDate, finalAdjustAsDate,mainBuilder.matter.isPaysForDateOfClosingPurchaser,mainBuilder.matter.provinceCode);
        let purchaserShareStr: string = purchaserShare >= 0 ? mainBuilder.getCurrency(purchaserShare) : "???.??";
        let purchaserShareDays: number = soAdj.getPurchaserShareDays(mainBuilder.matter.occupancyDate, interimAdjustAsDate, finalAdjustAsDate, mainBuilder.matter.isPaysForDateOfClosingPurchaser,mainBuilder.matter.provinceCode);
        let purchaserShareDaysStr: string = purchaserShareDays >= 0 ? `${purchaserShareDays} ${Utils.getDaysLabel(purchaserShareDays)}` : "???";

        sadItemDetails.push(mainBuilder.getNewItemLine(
            `Purchaser's share for ${purchaserShareDaysStr}`,
            purchaserShareStr
        ));

        if (toBeUsedOnModal) {
            let creditAmount: number = soAdj.getCreditAmount(mainBuilder.matter.occupancyDate, interimAdjustAsDate, finalAdjustAsDate, mainBuilder.matter.isPaysForDateOfClosingPurchaser, mainBuilder.matter.provinceCode);
            let creditAmountStr: string = creditAmount >= 0 ? mainBuilder.getCurrency(creditAmount) : "?????.??";
            if (soAdj.purchaserHasPaidFee === 'AND_INCLUDING_MONTH_OF_CLOSING') {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `Credit ${mainBuilder.purchaserLabel}`,
                    creditAmountStr
                ));
            } else {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `Credit ${mainBuilder.vendorLabel}`,
                    creditAmountStr,
                    true
                ));
            }
        } else {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Credit ${soAdj.purchaserHasPaidFee === 'AND_INCLUDING_MONTH_OF_CLOSING' ? mainBuilder.purchaserLabel : mainBuilder.vendorLabel}`,
                ''
            ));
        }

        return sadItemDetails;
    }

    static getPaidByLabel(purchaserHasPaidFee: FinalPurchaserHasPaidFeeType, toBeUsedOnModal: boolean,  finalType: string = 'occupancy'): string{

        switch (purchaserHasPaidFee) {
            case "AND_INCLUDING_MONTH_OF_CLOSING": //C2
                return "Paid by Purchaser for";
            case "END_OF_MONTH_PRECEDING_CLOSING": //C3
                return "Not Paid by Purchaser for";
            case 'OTHER_ENTER_OWN_DATE': //C4
                return `Purchaser has paid ${finalType} fees`;
            case 'NO_OCCUPANCY_FEES_EVER_PAID': //C5
            case 'NOT_PAID_FOR_MONTH_OF_POSSESSION': //C6
                return toBeUsedOnModal ? "Not paid by Purchaser" : `Purchaser has not paid ${finalType} fees`;
            default:
                return "";
        }
    }

    /**
     *=IF(
     OR(E16=PickList!C2,E16=PickList!C3),"month of "&TEXT(F4,"MMMM YYYY"),
     IF(E16=PickList!C4,"to "&IF(E17<>"",TEXT(E17,"MMMM DD, YYYY"),"??????????????????"),
     IF(E16=PickList!C5,"from "&TEXT(F3,"MMMM DD, YYYY"),"from "&TEXT(F3,"MMMM DD, YYYY") & "
     */

    static getMonthLine(soAdj: SoAdjFinalFee, interimAdjustAsDate: string, finalAdjustAsDate: string): string {

        const question: string = "??????????????????";

        switch (soAdj.purchaserHasPaidFee) {
            case "AND_INCLUDING_MONTH_OF_CLOSING": //C2
            case "END_OF_MONTH_PRECEDING_CLOSING": //C3
                return `month of ${Utils.getMonthName(finalAdjustAsDate)} ${Utils.getYear(finalAdjustAsDate)}`;
            case 'OTHER_ENTER_OWN_DATE': //C4
                return `to ` + Utils.getFormattedDate(soAdj.paidOn, "MMMM DD, YYYY", question);
            case 'NO_OCCUPANCY_FEES_EVER_PAID': //C5
                return `from ` + Utils.getFormattedDate(interimAdjustAsDate, "MMMM DD, YYYY", question);
            case 'NOT_PAID_FOR_MONTH_OF_POSSESSION': //C6
                return `from ${Utils.getFormattedDate(interimAdjustAsDate, "MMMM DD, YYYY", question)} to ${Utils.getFormattedDate(Utils.lastDayOfMonthFromDate(interimAdjustAsDate), "MMMM DD, YYYY", question)}`;
            default:
                return "";
        }
    }
}
