

export const MultiDepositRowBurgerMenu = {
    addAbove : {text: 'Add above', key: 'add_above'},
    delete : {text: 'Delete', key: 'delete'},
    moveDown : {text: 'Move down', key: 'move_down'},
    moveUp : {text: 'Move up', key: 'move_up'},
    status : {text: 'Status', key: 'status'},
    statusNSF: {text: 'NSF', key: 'status_nsf'},
    statusClearNSF: {text: 'Clear NSF', key: 'status_clear_nsf'},
    statusNH: {text: 'NH', key: 'status_nh'},
    statusClearNH: {text: 'Clear NH', key: 'status_clear_nh'},
    extras: {text: 'Extras', key: 'extras'},
    extrasClear: {text: 'Clear Extras', key: 'extras_clear'},
    printForm4 : {text: 'Print Form 4', key: 'print_form4'},
}

export enum SalePriceOptionValue {
    AS_PER_AGREEMENT = 'SALE_PRICE_AS_PER_AGREEMENT',
    INCLUDING_CREDITS_TO_PV = 'SALE_PRICE_INCLUDING_CREDITS_PV'
}
