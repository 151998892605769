import {Injectable} from '@angular/core';
import {HttpClient} from '../../core/httpClient.service';
import {Observable} from 'rxjs';
import {UserDefinedField} from './user-defined-field';
import {api} from '../../common/api';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ProvinceCode} from '../../admin/accounts/shared/account-province';

export const CUSTOMER_ACT_USER_DEFINED_FIELDS_GET_URL: string = '/userDefinedFields';
export const CUSTOMER_ACT_USER_DEFINED_FIELDS_RESPONSE_KEY : string = 'UserDefinedFields';

@Injectable()
export class UserDefinedFieldService {

    constructor(private http: HttpClient) {

    }

    getUserDefinedFieldsForLoggedInCustomerAccount(matterType : string , provinceCode? : ProvinceCode) : Observable<UserDefinedField[]> {
        let customerAccountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = `${api}/accounts/${customerAccountId}${CUSTOMER_ACT_USER_DEFINED_FIELDS_GET_URL}?filter=matterType_EQ_${matterType}`;

        if(provinceCode) {
            url += ',provinceCode_EQ_'+provinceCode;
        }
        return this.http.get(url)
                   .map((res ) => {
                       let userDefinedFields : UserDefinedField[] = [];
                       let resp = res[CUSTOMER_ACT_USER_DEFINED_FIELDS_RESPONSE_KEY];
                       if(Array.isArray(resp)) {
                           resp.forEach(record => {
                               userDefinedFields.push(new UserDefinedField(record));
                           })
                       }
                       return userDefinedFields;
                   });
    }
}
