import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

class ChicagoTitlePortalContext extends BSModalContext {
    securedUrl : string;
}
@Component({
               selector    : 'dp-chicago-title-portal-modal',
               templateUrl : 'chicago-title-portal.modal.component.html',
           })
export class ChicagoTitlePortalModal implements OnInit, ModalComponent<ChicagoTitlePortalContext> {
    context : ChicagoTitlePortalContext;
    chicagoTitleFileContainerUrl: SafeUrl;

    @ViewChild('chicagoFrame') chicagoFrame: ElementRef;

    constructor(public dialog : DialogRef<ChicagoTitlePortalContext>,
                public sanitizer: DomSanitizer)
    {
        this.context = dialog.context;
    }

    ngOnInit() {
        this.chicagoTitleFileContainerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.securedUrl);
    }

    close() : void {
        this.dialog.close({action: 'Close'});
    }

}
