import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TitleInsurancePolicy} from '../title-insurance/title-insurance-policy';
import Utils from '../../shared-main/utils';
import {Address} from './address';
import {Mortgage} from './mortgage';
import {MortgageAction, ThirdPartyDocumentStatus} from '../../shared-main/constants';
import {AddressTypes} from './address-types';
import {Matter} from './index';
import * as _ from 'lodash';
import {ChicagoInvoiceItem} from '../title-insurance/chicago-invoice-item';
import {TitleInsuranceQuote} from '../title-insurance/title-insurance-quote';
import {ChicagoDocument} from '../../shared-main/chicago-title/chicago-document';
import {ChicagoTitleStatus} from '../../shared-main/chicago-title/chicago-title-constants';
import {ChicagoLawFirm} from '../../shared-main/chicago-title/chicago-law-firm';
import {FctGeneratedDocument} from '../../shared-main/fct/fct-generated-document';
import moment from 'moment';
import {DpBooleanValueTypes} from './dp-boolean';
import {StgDocument} from '../../shared-main/stewart-title/stg-document';
import {PinLegalDescription} from '../title-insurance/pin-legal-description';
import {TitlePlusDocument} from '../../shared-main/title-plus/title-plus-document';
import {titlePlusOrderStatus} from '../../shared-main/title-plus/title-plus-constants';

//The following is not a real type, they are the known FCT order status returned from FCT
export type FCT_ORDER_STATUS = "Draft" | "Cancelled" | "Completed" | "On Hold" | "Pending Cancellation" | "Pending Processing" | "Pending Update" | "Waiting For Documents" | "In Underwriting";

export class MatterTitleInsurance extends BaseEntity {

    id: number;

    matterId: number;

    policies: TitleInsurancePolicy[] = [];

    dealId: string;

    mmsDealExists: string;

    mmsDealId: string;

    invoiceOrderStatus: string;

    orderStatusCategory: string;

    orderDate: Date;

    quoteTimestamp : Date;

    grandTotal: number;

    policyTotal: number;

    policySalesTax: number;

    eCFee: number;

    eliteFee: number;

    insurerName: string;

    insurerAddress: Address;

    phone: string;

    fax: string;

    //client side only flag tracking whether matter title insurance should be cleared
    clearTitleInsurance: boolean = false;

    quoteAmount : number;

    fctPremium : number;

    invoiceDate : string;

    chicagoInvoiceItems : ChicagoInvoiceItem[] = [];

    chicagoDocuments : ChicagoDocument[] = [];

    titleInsuranceUpdateRequired: boolean;

    quote : TitleInsuranceQuote;

    fctPremiumData: TitleInsuranceQuote;

    lawFirmDataFromChicagoSubmitFileRequest: ChicagoLawFirm;

    fctDocuments : FctGeneratedDocument[] = [];

    stgDocuments: StgDocument[] = [];

    pinLegalDescriptions: PinLegalDescription[] = [];

    thirdPartyOrderId: string;

    titlePlusDocuments: TitlePlusDocument[] = [];


    constructor(titleInsurance?: MatterTitleInsurance) {
        super(titleInsurance);
        if(titleInsurance) {
            if (titleInsurance.insurerAddress) {
                this.insurerAddress = new Address(titleInsurance.insurerAddress);
            }
            if(titleInsurance.policies) {
                for(let t of titleInsurance.policies) {
                    this.policies.push(new TitleInsurancePolicy(t));
                }
            }
            if(titleInsurance.chicagoInvoiceItems) {
                for(let t of titleInsurance.chicagoInvoiceItems) {
                    this.chicagoInvoiceItems.push(new ChicagoInvoiceItem(t));
                }
            }

            if (titleInsurance.chicagoDocuments) {
                for (let d of titleInsurance.chicagoDocuments) {
                    this.chicagoDocuments.push(new ChicagoDocument(d));
                }
            }

            if (titleInsurance.fctDocuments) {
                for (let d of titleInsurance.fctDocuments) {
                    this.fctDocuments.push(new FctGeneratedDocument(d));
                }
            }

            if (titleInsurance.lawFirmDataFromChicagoSubmitFileRequest) {
                this.lawFirmDataFromChicagoSubmitFileRequest = new ChicagoLawFirm(titleInsurance.lawFirmDataFromChicagoSubmitFileRequest);
            }

            if(titleInsurance.stgDocuments) {
                for (let d of titleInsurance.stgDocuments) {
                    this.stgDocuments.push(new StgDocument(d));
                }
            }

            if(titleInsurance.pinLegalDescriptions) {
                for (let pinLegalDescription of titleInsurance.pinLegalDescriptions) {
                    this.pinLegalDescriptions.push(new PinLegalDescription(pinLegalDescription));
                }
            }

            if(titleInsurance.titlePlusDocuments) {
                for (let doc of titleInsurance.titlePlusDocuments) {
                    this.titlePlusDocuments.push(new TitlePlusDocument(doc));
                }
            }

        }
    }

    get formattedOrderDate(): string {
        return this.orderDate ? moment(this.orderDate).format('MMM DD, YYYY') : '';
    }

    get formattedOrderDateTime(): string {
        return this.orderDate ? moment(this.orderDate).format('MMM DD, YYYY hh:mm A') : '';
    }

    get premium(): string {
        return this.grandTotal ? '' + Utils.formattedCurrencyValue(this.grandTotal) : '';
    }

    public updateMmsDealFromExistingEmpMortgage(matter: Matter){
        if(matter && matter.isInsurerFCT()){
            let fctMmsDealId : string = matter.getMmsDealIdFromFctEmp();
            if(fctMmsDealId){
                this.mmsDealId =  fctMmsDealId;
                this.mmsDealExists = DpBooleanValueTypes.YES;
            }
        }
    }

    public updateMmsDealAfterMortgageChange(mortgageAction: string, mortgage: Mortgage){
        //US19892, only deal with FCT case
        if(mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.isFctMMSInstruction) {
            switch (mortgageAction) {

                case MortgageAction.ADD:
                    let empFctMortgageDealId : string = mortgage.stewartAssystMortgageInstruction.sourceFctUrn;
                    if(empFctMortgageDealId){//we expect the dealId should always in place
                        this.mmsDealId =  empFctMortgageDealId;
                        this.mmsDealExists = DpBooleanValueTypes.YES;
                    }
                    break;

                case MortgageAction.DELETE:
                    //if FCT Emp mortgage get deleted, clean the mms deal related fields, even it has user manually entered data
                    this.mmsDealExists = DpBooleanValueTypes.N_y;
                    this.mmsDealId = null;
                    break;

            }
        }
    }

    public updateAfterMortgageChange(matter: Matter, mortgageAction: string, priority?: number): void {
        switch (mortgageAction) {

            case MortgageAction.ADD:
                let policyIndex = this.policies.findIndex(item => item.mortgagePriority > priority);
                if (policyIndex > -1) {
                    this.policies.splice(policyIndex, 0, this.createPolicy(matter.newOrEmpMortgages, priority));
                } else {
                    this.policies.push(this.createPolicy(matter.newOrEmpMortgages, priority));
                }
                break;

            case MortgageAction.DELETE:
                let deletePolicyIndex: number = this.policies.findIndex(policy => policy.mortgagePriority == priority);
                if (deletePolicyIndex >= 0) {
                    this.policies.splice(deletePolicyIndex, 1);
                }

                //re-numbering remaining policies
                if (matter.isPurchase) {
                    this.mortgagePolicies.forEach((item, index) => {
                        item.mortgagePriority = Number(index) + 1;
                    });
                }
                break;

            case MortgageAction.CHANGE_PRIORITY:
                this.mortgagePolicies.forEach((policy: TitleInsurancePolicy) => {
                    let mortgage: Mortgage = matter.newOrEmpMortgages.find((mortgage: Mortgage) => mortgage.id == policy.mortgageId);
                    policy.mortgagePriority = mortgage.mortgagePriority;
                });
        }
    }

    private createPolicy(mortgages: Mortgage[], priority: number): TitleInsurancePolicy {
        const policy: TitleInsurancePolicy = new TitleInsurancePolicy();
        policy.includePolicy = 'YES';
        policy.ownerPolicy = false;
        policy.mortgagePriority = priority;
        const mortgage: Mortgage = mortgages.find(mortgage => mortgage.mortgagePriority == priority);

        //need to link title insurance policy to original mortgage it's based on
        if (mortgage) {
            policy.mortgageId = mortgage.id;
        }

        return policy;
    }

    private createPolicyFromMortgage(mortgage: Mortgage): TitleInsurancePolicy {
        const policy: TitleInsurancePolicy = new TitleInsurancePolicy();
        policy.includePolicy = 'YES';
        policy.ownerPolicy = false;
        policy.mortgagePriority = mortgage.mortgagePriority;
        if (mortgage) {
            policy.mortgageId = mortgage.id;
        }

        return policy;
    }


    public initializeOtherTitleInsurance(matter: Matter): void {
        if (matter.isPurchase && !this.ownerPolicy) {
            const ownerPolicy: TitleInsurancePolicy = new TitleInsurancePolicy();
            ownerPolicy.includePolicy = 'YES';
            ownerPolicy.ownerPolicy = true;
            this.policies.push(ownerPolicy);
        }

        if (!Array.isArray(this.mortgagePolicies) || !this.mortgagePolicies.length) {
            matter.newOrEmpMortgages.forEach((mortgage: Mortgage) => {
                this.policies.push(this.createPolicyFromMortgage(mortgage));
            });
        }

        if (!this.insurerAddress) {
            const mailingAddress = new Address();
            mailingAddress.addressTypeCode = AddressTypes.mailing;
            mailingAddress.setAddressHash();
            this.insurerAddress = mailingAddress;
        }
    }


    get ownerPolicy(): TitleInsurancePolicy {
        if (this.policies) {
            return this.policies.find( policy => policy.ownerPolicy);
        } else {
            return null;
        }
    }


    get mortgagePolicies(): TitleInsurancePolicy[] {
        if (this.policies) {
            let mortgagePolicies = this.policies.filter( policy => !policy.ownerPolicy);
            return _.sortBy(mortgagePolicies, ['mortgagePriority']);
        } else {
            return null;
        }
    }

    get isPolicyPresent(): boolean {
        return this.mortgagePolicies && this.mortgagePolicies.length > 0 && this.mortgagePolicies.some(policy => policy.policyNo && policy.policyNo.length > 0) || !!this.ownerPolicy;
    }

    isChicagoDownloadsCompleted(): boolean {
        return this.isChicagoDocumentsPresent() && !this.chicagoDocuments.some(document => document.downloadStatus == ThirdPartyDocumentStatus.NEW);
    }

    isChicagoDocumentsPresent(): boolean {
        return this.chicagoDocuments && this.chicagoDocuments.length > 0;
    }

    isChicagoReadyForPickup(): boolean {
        return this.invoiceOrderStatus == ChicagoTitleStatus.READY_FOR_PICKUP;
    }

    isChicagoCancelled(): boolean {
        return this.invoiceOrderStatus == ChicagoTitleStatus.CANCELLED;
    }

    isFCTDownloadsCompleted(): boolean {
        return this.isFCTDocumentsPresent() && !this.fctDocuments.some(document => document.downloadStatus == ThirdPartyDocumentStatus.NEW);
    }

    isFCTDocumentsPresent(): boolean {
        return this.fctDocuments && this.fctDocuments.length > 0;
    }

    isOrderStatusCancelled(): boolean {
        return this.invoiceOrderStatus == 'Cancelled' || this.invoiceOrderStatus == 'Pending Cancellation' || this.invoiceOrderStatus == 'FCT Reviewing Cancel Request';
    }

    isTitleInsuranceOrdered() : boolean {
        return this.orderStatusCategory && this.orderStatusCategory != 'Unordered';
    }

    isStgDocumentsPresent(): boolean {
        return this.stgDocuments && this.stgDocuments.length > 0;
    }

    hasNewStgDocuments(): boolean {
        return this.isStgDocumentsPresent() && this.stgDocuments.some(document => document.downloadStatus == ThirdPartyDocumentStatus.NEW);
    }

    cleanUpPolicyNo(){
        if(this.ownerPolicy && this.ownerPolicy.includePolicy === DpBooleanValueTypes.NO){
            this.ownerPolicy.policyNo = null;
        }
    }

    cleanUpMortgagePolicies(){
        //Use the temporary variable to avoid multi-filter for calling this.mortgagePolicies
        let mortgagePolicies: TitleInsurancePolicy[] = this.mortgagePolicies;
        if(Array.isArray(mortgagePolicies)){
            mortgagePolicies.forEach(mortgagePolicy => {
                if(mortgagePolicy.includePolicy === DpBooleanValueTypes.NO) {
                    mortgagePolicy.policyNo = null;
                }
            });
        }
    }

    hasTitlePlusDocuments(): boolean {
        return this.titlePlusDocuments && this.titlePlusDocuments.length > 0;
    }

    hasNewTitlePlusDocuments(): boolean {
        return this.hasTitlePlusDocuments() && this.titlePlusDocuments.some(document => document.downloadStatus == ThirdPartyDocumentStatus.NEW);
    }

    isTitlePlusIssued(): boolean {
        return this.invoiceOrderStatus == titlePlusOrderStatus.ISSUED;
    }
}
