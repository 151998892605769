import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class ReportTemplate extends BaseEntity {
    applicableForProvince: string;
    customerAccountId: number;
    description: string;
    filePath: string;
    id: number;
    lastUpdatedTimeStamp: number;
    public: boolean;
    templateName: string ;
}
