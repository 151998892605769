import {DialogService} from '../../shared/dialog/dialog.service';
import {HttpClient, TabsService} from '../../core/index';
import {Injectable} from '@angular/core';
import {Document} from '../../matters/document-production/document';
import {DocumentProductionService} from '../../matters/document-production/document-production.service';
import {Project} from './project';
import {ShareDocumentsModalComponent} from '../../matters/matter-list/modals/share-documents-modal.component';
import {Observable} from 'rxjs';
import {ProjectTab} from './project-tab';
import {Matter} from '../../matters/shared';
import {projectApi} from './project-api';
import {ProjectDocumentSharingNotification} from './project-doc-sharing-notifier';

@Injectable()
export class ProjectDocSharingNotifierService {

    constructor(private http: HttpClient,
                // private tabService: TabsService, // do not use this to avoid circular dependencies
                private dialogService: DialogService,
                private documentProductionService : DocumentProductionService,

    ) {
    }


    showProjectDocSharingNotifierDialog(projectTab: ProjectTab): Observable<void> {
        let observable: Observable<void> = new Observable<void>(observer => {
            if(projectTab && !!projectTab.showSharingNotifier) {
                this.dialogService.content({
                    content: ShareDocumentsModalComponent,
                    context: {
                        documentShareMode: 'PROJECT_DOC_SHARING',
                        projectName: projectTab.project.projectName
                    },
                    onFulfillment: (result) => {
                        if (result && result.projectDocSharingMessage) {
                            let projectDocumentSharingNotification = new ProjectDocumentSharingNotification();
                            projectDocumentSharingNotification.projectId = projectTab.project.id;
                            projectDocumentSharingNotification.notificationMessage = result.projectDocSharingMessage;
                            if (projectTab.matterSharedDocIds && projectTab.matterSharedDocIds.length > 0) {
                                projectDocumentSharingNotification.documentIds = [];
                                projectDocumentSharingNotification.documentIds.push(...projectTab.matterSharedDocIds);
                            }
                            let url: string = projectApi.projectSharingNotification.replace('{projectId}', '' + projectTab.project.id);
                            this.http.post(url, projectDocumentSharingNotification)
                                .subscribe((res) => {});
                        }
                        if (projectTab) {
                            projectTab.matterDocsBeforeUpdate = [];
                            projectTab.showSharingNotifier = false;
                            projectTab.matterDocsBeforeUpdate = projectTab.matterDocsAfterUpdate.slice(0);
                        }
                        observer.next();
                    }
                });
            }
            else{
                observer.next();
            }
        });
        return observable;
    }

    uploadDocChanges(tabService: TabsService, matter : Matter , project: Project, projectSharingDocs: Document[]) : void{
        this.documentProductionService.getDocuments(matter.id)
            .subscribe((matterDocs: Document[]) => {
                this.updateDocShareNotifierFlag(tabService, project, projectSharingDocs, matterDocs);
            });
    }

    updateDocShareNotifierFlag(tabService: TabsService, project: Project, projectSharingDocs: Document[], matterDocs: Document[]): void {
        if(!!project.projectDocumentsSharedWithOtherSide) {
            let autoSharedDocIds = matterDocs.filter(item => !!item.autoShared).map((doc) => {
                return doc.id;
            });
            let docSharingNotifierIds = [];
            if (projectSharingDocs && projectSharingDocs.length > 0) {
                // ALL NEWLY ADDED SHARED DOCUMENTS
                let documentIds = projectSharingDocs.map(item => {
                    return item.id;
                });
                docSharingNotifierIds.push(...autoSharedDocIds.filter(item => documentIds.indexOf(item) < 0));
                matterDocs.forEach(matterDocument => {
                    // All UPDATE SHARED DOCUMENTS AND REVOKED
                    let projectDocSharingNotifierDocument = projectSharingDocs.find(document => document.id == matterDocument.id);
                    if (projectDocSharingNotifierDocument && ((!!projectDocSharingNotifierDocument.autoShared
                        && !!matterDocument.autoShared && matterDocument.lastUpdatedTimeStamp > projectDocSharingNotifierDocument.lastUpdatedTimeStamp)
                        || (!matterDocument.autoShared && !!projectDocSharingNotifierDocument.autoShared)
                        || (!!matterDocument.autoShared && !projectDocSharingNotifierDocument.autoShared))) {
                        docSharingNotifierIds.push(matterDocument.id);
                    }

                });
            }
            else if (autoSharedDocIds && autoSharedDocIds.length > 0) {
                docSharingNotifierIds.push(...autoSharedDocIds);
            }
            let projectTab = tabService.getProjectTab(project.id);
            projectTab.matterDocsAfterUpdate = [];
            projectTab.matterDocsAfterUpdate = matterDocs.slice(0);
            if (projectTab) {
                projectTab.showSharingNotifier = docSharingNotifierIds.length > 0;
                projectTab.matterSharedDocIds.push(...docSharingNotifierIds.slice());
            }
        }

    }


}
