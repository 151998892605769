import {Component, ElementRef, ViewChild} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {BurgerMenuExtendedItem} from '../../../matters/shared/burger-menu-extended-item';
import {
    ExistingFile,
    FileUploadModal,
    FileUploadModalContext
} from '../../../matters/document-production/upload/file-upload-modal.component';
import {AppConfig} from '../../../shared-main/app-configuration';
import {CustomKeyCodesEnum} from '../../../common/index';
import * as _ from 'lodash';
import {AUTH_ROLE} from '../../../shared-main/authorization/authorization-keys';
import {AuthorizationService} from '../../../shared-main/authorization/authorization-service';
import {FocusFirstElementDecorator} from '../../../shared-main/focus-first-element-decorator';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {TprTemplatesService} from '../tpr-templates.service';
import {DocumentTemplateFile} from '../../../matters/document-production/document-template';
import {tprTemplatesApi} from '../tpr-templates-api';
import {EditTprTemplateDetailsComponent} from '../edit-tpr-template-details/edit-tpr-template-details.modal';
import {Account} from '../../../admin/accounts/shared/account';

declare var jQuery : any;

class ManageTprTemplatesModalContext extends BSModalContext {
    account: Account
}

class TprTemplateWrapper {
    tprTemplateFile: DocumentTemplateFile;
    burgerMenuItems : BurgerMenuExtendedItem[] = [];
}

@FocusFirstElementDecorator()

@Component({
        selector    : 'dp-manage-tpr-templates-modal',
        templateUrl : 'manage-tpr-templates.modal.component.html',
        providers   : [ErrorService, DialogService],
        styleUrls      : ['./manage-tpr-templates.modal.scss']
    })

    export class ManageTprTemplatesModal implements ModalComponent<ManageTprTemplatesModalContext> {

    ACCEPTED_FILE_EXTENSIONS = '*.xml';
    context : ManageTprTemplatesModalContext;
    rows: TprTemplateWrapper[] = [];

    @ViewChild('fileUploadElRef') fileUploadElRef: ElementRef;

    constructor(public dialog : DialogRef<ManageTprTemplatesModalContext>,
                public dialogService : DialogService,
                public tprTemplatesService : TprTemplatesService,
                public authorizationService : AuthorizationService,
                public appConfig : AppConfig ){

        this.context = dialog.context;
    }

    ngOnInit() {
        this.retrieveDocumentFiles();

    }



    ok() : void {
        this.close();
    }

    close() : void {
        this.dialog.close();
    }

    get acceptedFileExtensions() : string {
        return this.ACCEPTED_FILE_EXTENSIONS.replace(/\*/g, '');
    }

    initiateUpload(){
        let uploadFilesControl = this.fileUploadElRef.nativeElement;
        if(!(uploadFilesControl.value && uploadFilesControl.files && uploadFilesControl.files.length > 0)) {
            return;
        }
        //Loading the documents one more time before passing them to the upload control, as we need the latest locking status from the server
        this.tprTemplatesService.getAllReportTemplates()
            .subscribe((tprTemplates : DocumentTemplateFile[]) => {

                let uploadUrl = tprTemplatesApi.uploadTprXmlTemplate;

                let existingFiles : ExistingFile[] = tprTemplates.map((docFile: DocumentTemplateFile) => {
                    return {
                        name: docFile.fileName+ '.xml', //The file names comes without extension
                        lastUpdatedTimeStamp: docFile.lastUpdatedTimeStamp,
                        isOpen: false,
                        isProtected: false
                    } as ExistingFile;
                });

                console.log(existingFiles);
                if(uploadFilesControl.files.length){
                    this.dialogService.content({
                                                   content       : FileUploadModal,
                                                   context: {
                                                       filesSelectedForUpload: uploadFilesControl.files,
                                                       uploadUrl: uploadUrl,
                                                       existingFiles: existingFiles,
                                                       acceptedFileExtensions: this.ACCEPTED_FILE_EXTENSIONS,
                                                       maxFileSize: this.maxFileSizeInMb,
                                                       uploadExportTemplate: true
                                                   } as FileUploadModalContext,
                                                   onFulfillment : (result) => {
                                                       uploadFilesControl.value = '';
                                                       this.retrieveDocumentFiles();
                                                   },
                                                   onRejection : (result) => {
                                                       uploadFilesControl.value = '';
                                                       this.retrieveDocumentFiles();
                                                   }
                                               });
                }

            });

    }

    get maxFileSizeInMb(): number {
        return this.appConfig.maxUploadedFileSizeInMb;
    }

    retrieveDocumentFiles() : void {
        this.tprTemplatesService.getAllReportTemplates()
            .subscribe((data: DocumentTemplateFile[]) => {
               data = this.sortData(data);
                this.rows = data.map((file: DocumentTemplateFile) => {
                    let tprTemplateTempFileWrapper : TprTemplateWrapper = new TprTemplateWrapper();
                    tprTemplateTempFileWrapper.tprTemplateFile = file;
                    tprTemplateTempFileWrapper.burgerMenuItems = this.getBurgerMenuItems(tprTemplateTempFileWrapper.tprTemplateFile);
                    return tprTemplateTempFileWrapper;
                });

            });
    }

    sortData(data: DocumentTemplateFile[]) : DocumentTemplateFile[] {
        return _.sortBy(data, (item : DocumentTemplateFile)=>{
            return item.fileName  ? item.fileName.toLowerCase()  : '';
        });
    }

    getBurgerMenuItems(tprTemplateFile: DocumentTemplateFile) : BurgerMenuExtendedItem[] {
        let burgerMenuItems : BurgerMenuExtendedItem[] = [];
        this.addToBurgerMenu(burgerMenuItems, 'Edit Description', this.editTemplateDetails, !this.isUpdatingSystemAccount());
        this.addToBurgerMenu(burgerMenuItems, 'Download', this.downloadTemplate, false);
        this.addToBurgerMenu(burgerMenuItems, 'Delete', this.deleteTemplate,  !this.isUpdatingSystemAccount());
        return burgerMenuItems;
    }

    addToBurgerMenu(burgerMenuItems : BurgerMenuExtendedItem[], text : string, action : any, isDisabled: boolean) : BurgerMenuExtendedItem {
        let burgerMenuItem : BurgerMenuExtendedItem;
        burgerMenuItem = new BurgerMenuExtendedItem();
        burgerMenuItem.text = text;
        burgerMenuItem.action = action;
        burgerMenuItem.isDisabled = isDisabled;
        burgerMenuItems.push(burgerMenuItem);
        return burgerMenuItem;
    }

    clickBurgerMenu(tprTemplateFile: DocumentTemplateFile, clickedMenuOption : BurgerMenuExtendedItem) : void {
        if(clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
            clickedMenuOption.action(tprTemplateFile);
        }

    }


    hasSystemRole() : boolean {
        return this.authorizationService.hasRole(AUTH_ROLE.ROLE_SYSTEM_ADMINISTRATOR) || this.authorizationService.hasRole(AUTH_ROLE.ROLE_SYSTEM_USER)
    }

    isAdminLoggedIn() : boolean {
        return String(this.context.account.id) == sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }

    isUpdatingSystemAccount() : boolean {
        return this.hasSystemRole() && this.isAdminLoggedIn();
    }

    editTemplateDetails = (tprTemplateFile: DocumentTemplateFile)=> {
        this.dialogService.content({
            content       : EditTprTemplateDetailsComponent,
            context       : {
                documentTemplate     : tprTemplateFile.documentTemplate,
                accountId          : this.context.account.id
            },
            onFulfillment : (result) => {
            },
            fullScreen    : false,
            modalGrid     : 5
        });
    };

    downloadTemplate = (tprTemplateFile: DocumentTemplateFile)=> {
        this.tprTemplatesService.downloadReportTemplateFile(tprTemplateFile.id);
    };

    deleteTemplate = (documentTemplate: DocumentTemplateFile)=> {
        let message = '<p>Are you sure that you would like to delete the template?</p><br>';
        this.dialogService.confirm('Confirmation', message, false, 'Delete' , null , true)
            .subscribe(res =>{
                if(res) {
                    this.tprTemplatesService.deleteReportTemplateFile(documentTemplate.id)
                        .subscribe(
                            (response: any) =>{
                                (<any>this.rows).remove(this.rows.find((item)=>{
                                    return item.tprTemplateFile == documentTemplate;
                                }))
                            }
                        )}
            });
    };

    upload() : void {
        this.fileUploadElRef.nativeElement.click();
    }

    tableKeyCommands(index, event) : void {

        let charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));

        if(charCode === CustomKeyCodesEnum.Down) {
            event.preventDefault();
            this.keyCommandtoSelectNext(index);
        }
        if(charCode === CustomKeyCodesEnum.Up) {
            event.preventDefault();
            this.keyCommandtoSelectPrev(index);

        }

    }

    keyCommandtoSelectNext(index) {
        if(index < this.rows.length) {
            jQuery(document.activeElement).next('tr').focus();
        }
    }

    keyCommandtoSelectPrev(index) {
        if(index > 0) {
            jQuery(document.activeElement).prev('tr').focus();
        }
    }

    ngAfterViewInit() {}
}
