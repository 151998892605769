import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {ErrorService} from '../../shared/error-handling/error-service';
import {Component, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {LockScreenService} from '../../core/lock-screen.service';
import {ApplicationError} from '../../core/application-error';
import {FieldError} from '../../core/index';
import {TeranetUser} from '../../shared-main/teranet/teranet-user';
import {TeranetService} from '../../shared-main/teranet/teranet-service';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';

class TeranetChangePasswordContext extends BSModalContext {
    teranetUser: TeranetUser;
}

@Component({
    selector    : 'dp-teranet-change-password-modal',
    templateUrl : './teranet-change-password.modal.component.html',
    providers   : [ErrorService]
})

@FocusFirstElementDecorator()
export class TeranetChangePasswordModal implements   ModalComponent<TeranetChangePasswordContext> {

    @ViewChild('modalErrorComponent') modalErrorComponent : ModalErrorComponent;
    context : TeranetChangePasswordContext;
    tcCredential: TeranetUser;
    newPassword : string;
    tcPassword : string;

    constructor(public dialog : DialogRef<TeranetChangePasswordContext>,
                public lockScreenService : LockScreenService,
                public teranetService: TeranetService){
        this.context = dialog.context;
    }

    ngOnInit() : void {

        if(this.context.teranetUser){
            this.tcCredential = this.context.teranetUser;
        }
    }

    ok() : void {
        this.modalErrorComponent.removeAllDpFieldError();
       // this.lockScreenService.lockForUpdate = true;
         this.validateNewPassword();
         if(!this.modalErrorComponent.anyErrorExist()){
             this.tcCredential.teranetChangePassword=this.tcPassword;
             this.teranetService.changePassword(this.tcCredential).subscribe(
                 (teranetResult: TeranetUser) => {
                    // this.lockScreenService.lockForUpdate = false;
                     this.dialog.close({ action: "OK",
                                        teranetUser: teranetResult
                     });
                 },
                 (error: ApplicationError) => {
                     if(Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
                         error.fieldErrors.forEach((fieldError: FieldError) => {
                             this.modalErrorComponent.createCustomDPFieldError(fieldError.errorCode, fieldError.errorCode+":"+fieldError.message, null , "ERROR");
                         });
                     } else {
                         this.modalErrorComponent.createCustomDPFieldError(error.errorCode, error.errorCode+":"+error.errorMessage, null , "ERROR");
                     }
                 });

         }

    }

    validateNewPassword(){
        if(!(this.newPassword == this.tcPassword)){
            this.modalErrorComponent.createDPFieldError("teranet.changePassword.passwordNotMatch");
        }
    }

    close() : void {
        this.dialog.close();
    }

    ngAfterViewInit() {}
}



