export const errorAddressMessage = {
    'addressLine1': {
        'required': 'addressLine1 is required.'
    },
    'addressLine2': {
        'required': 'addressLine2 is required.'
    },
    'postalCode': ` Canadian postal code should be in format "X1X 1X1" ' +
                                 'where X is a letter and 1 is a digit.'`,
}
