import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptor } from './helpers/token.interceptor';

import { LandingWidgetsComponent } from './landing-widgets.component';
import { WidgetLoaderComponent } from './widget-loader/widget-loader.component';
import { EventTypeDisplayPipe } from './helpers/event-type-display.pipe';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: LandingWidgetsComponent
  }
];
@NgModule({
  declarations: [LandingWidgetsComponent, WidgetLoaderComponent, EventTypeDisplayPipe],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule.forChild([])
  ],
  exports: [LandingWidgetsComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
})
export class LandingWidgetsModule { }
