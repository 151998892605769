import {StatementOfAdjustmentHeading} from './statement-adjustment-heading';

import {MatterParticipant} from '../../shared/matter-participant';
import {MatterProperty} from '../../shared/matter-property';
import {StatementAdjustmentDisplayBalanceItem, StatementAdjustmentDisplayItem} from './statement-adjustment-display-item';
import {StatementOfAdjustmentPayable} from './statement-adjustment-payable';
import {StatementAdjustmentConfig} from '../../../admin/statement-adjustment/statement-adjustment-config';
import {soAdjProjectFooters} from './so-adj-drop-downs';
import {DocumentTypeType} from '../../document-production/document';
import {Matter} from '../../shared/matter';


/**
 * Data structure used to send data necessary for PDF production on server side
 * basically a reduced version of matter
 */
export class StatementAdjustmentPreview {

    statementOfAdjustmentHeading: StatementOfAdjustmentHeading;
    statementOfAdjustmentPayable: StatementOfAdjustmentPayable;
    documentProfileId : number;

    items: StatementAdjustmentDisplayItem[] = [];
    balanceItems: StatementAdjustmentDisplayBalanceItem[] = [];
    footers: string[] = [];

    matterParticipants: MatterParticipant[];
    matterProperties: MatterProperty[] = [];

    vendorRELine: string;
    purchaserRELine: string;

    adjustmentDate: string;

    splitDepositAdjustments: boolean;
    printBalanceDueOnClosingAtBottomOfPage: boolean = false;
    paperSizeCode: string;

    interimAdjustment: boolean = false;
    hasInterimAndFinal: boolean = false;
    projectOrProjectSaleMatter: boolean = false;

    // A cleanup other fields can be removed from this DTO as all of them will be available in matter.
    matter: Matter;

    outputFileType: DocumentTypeType = 'WORD';

    constructor(matter?: Matter) {


        if (matter) {

            this.statementOfAdjustmentHeading = matter.statementOfAdjustmentHeading;
            this.statementOfAdjustmentPayable = matter.statementOfAdjustmentPayable;
            if (['AB', 'MB', 'NS', 'NB'].indexOf(matter.provinceCode) > -1) { // AB, MB, NS and NB don't need the payableTo field
                this.statementOfAdjustmentPayable.payableTo = "";
                this.statementOfAdjustmentPayable.excludePrefix = true;
                this.statementOfAdjustmentPayable.excludeSuffix = true;
            }
            this.documentProfileId = matter.documentProfileId;

            this.matterParticipants = matter.matterParticipants;

            this.matterProperties = matter.matterProperties;

            if(matter.isPurchase){
                if (matter.clientReLine) {
                    this.purchaserRELine = matter.clientReLine;
                }
                if (matter.vendorContactInfo) {
                    this.vendorRELine = matter.vendorContactInfo.reline;
                }
            }

            if(matter.isSale){
                if (matter.clientReLine) {
                    this.vendorRELine = matter.clientReLine;
                }
                if( matter.purchaserContactInfo){
                    this.purchaserRELine = matter.purchaserContactInfo.reline;
                }
            }

            this.projectOrProjectSaleMatter = !!matter.project;
            this.adjustmentDate = matter.getClosingDate();

            this.splitDepositAdjustments = matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.adjustmentFormat == 'SEPARATE_ADJ_PER_DEPOSIT';

            this.addFooters(matter);

        }
    }

    /*
    *   { label: 'Unconditional', value: 'UNCONDITIONAL' },
        { label: 'Taxes not fully paid', value: 'TAX_NOT_FULLY_PAID' },
        { label: 'Condo', value: 'CONDO' },
        { label: 'Non condo', value: 'NON_CONDO' },
        { label: 'New home from builder', value: 'NEW_HOME_FROM_BUILDER' },
        { label: 'Resale home', value: 'RESALE_HOME' }
    * */
    isProjectOrProjectSaleApplicableFooter(matter: Matter, footer : StatementAdjustmentConfig) : boolean{
        let applyByDefault : boolean = footer.applyByDefault || !footer.projectFooterId;
        if (this.projectOrProjectSaleMatter){
            return footer.footerProgressionStatusAvailability
                ? (footer.footerProgressionStatusAvailability == matter.selectedProgressionStatus || footer.footerProgressionStatusAvailability==soAdjProjectFooters.applyToBoth.value)
                : applyByDefault;
        }
        return false;
    }

    addFooters(matter: Matter) {

        if (matter) {
            let sac = matter.matterStatementOfAdjustmentFooters;
            if (sac) {

                let vendorPaidFull: boolean = false;
                let soaTaxes = matter.statementOfAdjustments.filter(soa => soa.isAnyPropertyTaxes());
                if (soaTaxes) {
                    vendorPaidFull = soaTaxes.every(soa => soa.matterTax && soa.matterTax.vendorPaidFullAmount == 'YES')
                }

                sac.forEach(foot => {

                    if ((!this.projectOrProjectSaleMatter && foot.applyByDefault) || this.isProjectOrProjectSaleApplicableFooter(matter, foot)) {
                        if (foot.condition == 'UNCONDITIONAL'

                            || (foot.condition == 'TAX_NOT_FULLY_PAID' && !vendorPaidFull )

                            || (foot.condition == 'CONDO' && matter.isPropertyCondo() )
                            || (foot.condition == 'NON_CONDO' && !matter.isPropertyCondo())

                            || (foot.condition == 'NEW_HOME_FROM_BUILDER' && matter.matterProperties && matter.matterProperties[0].isPurchaseTypeNewBuilderHome())
                            || (foot.condition == 'RESALE_HOME' && matter.matterProperties && matter.matterProperties[0].isPurchaseTypeResaleHome())                           )
                        {
                            let ft = foot.description ? foot.description.replace(/(\r\n|\n|\r)/gm, "").trim() : '';
                            this.footers.push(ft);
                            this.footers.push('<p><br></p>');
                        }
                    }
                });
            }
        }
    }
}
