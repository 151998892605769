import {Component} from '@angular/core';
import {StatusBarService} from '../../shared-main/status-bar.service';


@Component({
               selector    : 'dp-modal-f9-help',
               templateUrl : 'modal-f9-help.component.html',
           })
export class ModalF9HelpComponent {

    constructor(public statusBarService : StatusBarService) {
    }

    ngOnInit(): void {
        this.statusBarService.initialize();
    }
 }
