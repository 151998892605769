import {api} from '../../common/api';

export const fctApi = {
    fctSubmitFile : `${api}/integrations/fct/matters/{matterId}/solicitors/{solicitorId}/files`,
    downloadDocument : `${api}/integrations/fct/matters/{matterId}/solicitors/{solicitorId}/deals/{dealId}/documents/{documentName}`,
    deleteTitleInsuranceDocuments : `${api}/integrations/fct/matters/{matterId}/solicitors/{solicitorId}/deals/{dealId}/documents`,
    getFctStatus(matterId : string, solicitorId : string, dealId : string) : string {
        return `${api}/integrations/fct/matters/${matterId}/solicitors/${solicitorId}/deals/${dealId}/status`;
    },

    getFctEmpSendTxnDataApi(matterId: number, solicitorId: number, fctEmpMortgageId: number){
        return `${api}/integrations/fct/emp/matters/${matterId}/solicitors/${solicitorId}/mortgages/${fctEmpMortgageId}`;
    },

    fctUserBySolicitor : `${api}/integrations/fct/solicitors/{solicitorId}/fctUser`,

    fctUser                    : `${api}/integrations/fct/accounts/{accountId}/users/currentUser`,
    forgotUrl                  : `${api}//integrations/fct/accounts/{accountId}/forgotUrl`,
    validateFctUser            : `${api}/integrations/fct/accounts/{accountId}/validateUser`,
    deleteFCTUser              : `${api}/integrations/fct/accounts/{accountId}/users/{userId}/fctUser/delete`,
    getMyProfile               : `${api}/integrations/fct/accounts/{accountId}/user/{userId}/myProfileUrl`,
    getFctUsers                : `${api}/accounts/{accountId}/solicitors/fctUsers`,
    getNewMortgageInstructions : `${api}/integrations/fct/accounts/{accountId}/mortgageInstructions/checkForNew`,
    getDealData                : `${api}/integrations/fct/accounts/{accountId}/solicitors/{solicitorId}/deals/{dealId}`,
    declineDeal                : `${api}/integrations/fct/accounts/{accountId}/solicitors/{solicitorId}/deals/{dealId}/refuse`,
    acceptDeal                 : `${api}/integrations/fct/accounts/{accountId}/solicitors/{solicitorId}/deals/{dealId}/accept`,
    signUpUrl                  : `${api}/integrations/fct/accounts/{accountId}/signUpUrl`,
    redirectUrl                : `${api}/integrations/fct/accounts/{accountId}/redirectUrl/{context}`,
    redirectUrlInDealContext   : `${api}/integrations/fct/accounts/{accountId}/solicitors/{solicitorId}/redirectUrl/{context}/{transactionId}`,
    refreshDealStatus          : `${api}/integrations/fct/accounts/{accountId}/solicitors/{solicitorId}/deals/{dealId}/refresh/status`,
    lenderChanges           : `${api}/integrations/fct/accounts/{accountId}/solicitors/{solicitorId}/deals/{dealId}/lenderChanges`,
    empDocuments               : `${api}/integrations/fct/emp/matters/{matterId}/solicitors/{solicitorId}/deals/{dealId}/documents`,
    empDocument                : `${api}/integrations/fct/emp/matters/{matterId}/solicitors/{solicitorId}/deals/{dealId}/documents/{documentId}`,
    fctBusinessRules           : `${api}/integrations/fct/accounts/{accountId}/lenders/{fiNumber}/fctBusinessRules/`
};

export const FCTStatus = {
    COMPLETED: 'COMPLETED',
};

export const FctXpathConfigs = {
    xPathConfigs : [
        {xpath:'$.matterRecordNumber', matterType : 'PM', xpathType: 'Object'},
        {xpath:'$.fileNumber', matterType : 'PM', xpathType: 'Object'},
        {xpath:'$.matterCloseDate', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.actingFor', matterType : 'M', xpathType : 'Object'},
        {xpath:'$.matterContactInfo.residingAtSubjectProperty', matterType : 'PM', xpathType: 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "SOLICITOR")].contact.displayName', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "LAWCLERK")].contact.displayName', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "OTHERPARTY_SOLICITOR")].contact.contactName.firstName', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "OTHERPARTY_SOLICITOR")].contact.contactName.lastName', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "OTHERPARTY_LAW_FIRM")].contact.displayName', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].purchaseIsOfCode', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].purchasePrice', matterType : 'P', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].propertyType', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].isCondominium', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].beingPart', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].onPlan', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].parcel', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].section', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].easementRightOfWay', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].city', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].isParcelOfTiedLand', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].address.addressLine1', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].address.addressLine2', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].address.city', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].address.provinceName', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[?(@.isCondominium == "YES")].condominiumExpenses[*].pinNumber', matterType : 'PM', xpathType : 'List'},
        {xpath:'$.matterProperties[?(@.isCondominium == "YES")].condominiumExpenses[*].unitNumber', matterType : 'PM', xpathType : 'List'},
        {xpath:'$.matterProperties[?(@.isCondominium == "YES")].condominiumExpenses[*].levelNumber', matterType : 'PM', xpathType : 'List'},
        {xpath:'$.matterProperties[*].pin', matterType : 'PM', xpathType : 'List'},
        {xpath:'$.matterProperties[0].nameOfCondominiumPlan', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].partLot', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].plan', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].interestEstate', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "PURCHASER")].contact.contactName.firstName', matterType : 'P', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "PURCHASER")].contact.contactName.middleName', matterType : 'P', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "PURCHASER")].contact.contactName.lastName', matterType : 'P', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "MORTGAGOR")].contact.contactName.firstName', matterType : 'M', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "MORTGAGOR")].contact.contactName.middleName', matterType : 'M', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "MORTGAGOR")].contact.contactName.lastName', matterType : 'M', xpathType : 'List'},
        {xpath:'$.mortgages[?(@.mortgageType == "NEW")].mortgagePriority', matterType : 'PM', xpathType : 'List'},
        {xpath:'$.mortgages[?(@.mortgageType == "NEW")].loanNo', matterType : 'PM', xpathType : 'List'},
        {xpath:'$.mortgages[?(@.mortgageType == "NEW")].mortgageTerm.principal', matterType : 'PM', xpathType : 'List'},
        {xpath:'$.matterParticipantsOfNewMortgage[?(@.matterParticipantRole == "PRIVATE_LENDER" || @.matterParticipantRole == "MORTGAGEE")].contact.displayName', matterType : 'PM', xpathType : 'List'},
        {xpath:'$.matterRequisitions[*].requisitionText', matterType : 'P', xpathType : 'List'},
        {xpath:'$.matterTitleInsurance.mmsDealExists', matterType : 'PM', xpathType : 'BooleanValue'},
        {xpath:'$.matterTitleInsurance.mmsDealId', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].numOfUnits', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].registryOrLt', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "OTHERPARTY_LAW_FIRM")].contact.legalFirmName', matterType : 'M', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "OTHERPARTY_SOLICITOR")].contact.contactName.firstName', matterType : 'M', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "OTHERPARTY_SOLICITOR")].contact.contactName.lastName', matterType : 'M', xpathType : 'Object'},
        {xpath:'$.realEstateBrokerName', matterType : 'P', xpathType : 'Object'},
        {xpath:'$.realEstateAgentName', matterType : 'P', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].nameOfCondominiumPlan', matterType : 'PM', xpathType : 'Object'},
        {xpath:'$.matterProperties[0].percentageInterest', matterType : 'PM', xpathType : 'Object'}
    ]
};

export type FctDealContext = "LLCDEAL" | "LLCDEALRO";

export type SigningOfficerNo = 1|2;

export const fctLinks = {
    register : "https://fct.ca/"
};
