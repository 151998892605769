import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {Telephone} from '../telephone';
import {TelephoneTypes} from '../telephone-types';

export class CirfBrokerInfo extends BaseEntity {
    name: string;
    telephones: Telephone[];
    email: string;

    constructor(cirfBrokerInfo?: CirfBrokerInfo) {
        super(cirfBrokerInfo);
        if(cirfBrokerInfo){
            this.telephones = [];
            if(cirfBrokerInfo.telephones){
                cirfBrokerInfo.telephones.forEach((telephone)=>{
                    this.telephones.push(new Telephone(telephone));
                });
            }
        }
    }

    get workPhone() : string {
        return this.getPhone(TelephoneTypes.work).telephoneNumber;
    }

    set workPhone(phone : string) {
        this.getPhone(TelephoneTypes.work).telephoneNumber = phone;
    }


    getPhone(type : string) : Telephone {
        if(!this.telephones) {
            this.telephones = [];
        }

        let phone : Telephone = this.telephones.find((t : Telephone) => t.phoneTypeCode === type);
        if(!phone) {
            phone = new Telephone();
            phone.phoneTypeCode = type;
            this.telephones.push(phone);
        }
        return phone;
    }
}
