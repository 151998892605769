import {Injectable} from '@angular/core';
import {StatementAdjustment} from './statement-adjustment';
import {Matter} from '../shared';
import {UUIDUtil} from '../../main/uuid-util';

@Injectable()
export class StatementAdjustmentService {

    addMassUpdateAdjustment(adjustment: StatementAdjustment, fieldCode: number, matter: Matter): StatementAdjustment {
        let statementAdjustment = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode, adjustment);
        statementAdjustment.clearAllIds();
        statementAdjustment.applyToAdjustmentRecord = adjustment.isSalePrice() || adjustment.isDepositAdjustment() ? true : adjustment.applyToAdjustmentRecord;
        statementAdjustment.matterId = null;
        statementAdjustment.id = UUIDUtil.getUUID();
        if (statementAdjustment.matterTax) {
            statementAdjustment.matterTax.id = UUIDUtil.getUUID();
        }
        statementAdjustment.fieldCode = fieldCode;
        return statementAdjustment;
    }

}
