import {Component, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {BurgerMenuExtendedItem} from '../../matters/shared/burger-menu-extended-item';
import * as _ from 'lodash';

@Component({
    selector : "dp-burger-menu",
    template : `<dp-dropdown-menu
                [dropDownItems]="dpItems"
                [addFocus]="addFocus"
                [menuSize]="menuSize"
                [disabled]="disabled"
                [allowPopup]="allowPopUp"
                [addTabStopClass]="addTabStopClass"
                [openSubMenuOnLeft]="openSubMenuOnLeft"
                [openSubSubMenuOnLeft]="openSubSubMenuOnLeft"
                (itemClicked)="burgerMenuClicked($event)"
                (menuOpened)="burgerMenuOpened($event)"

                >
                </dp-dropdown-menu>
    `,

})
export class DpBurgerMenuComponent implements OnInit {
    @Input() items : any[];
    @Input() dpItems : BurgerMenuExtendedItem[];
    @Input() disabledItems : string[] = [];
    @Input() disabledDpItems : BurgerMenuExtendedItem[] = [];
    @Input() menuSize: number[] =[];
    @Input() addFocus: boolean = false;
    @Input() readOnlyOverRide: boolean = false;
    @Input() openSubMenuOnLeft : boolean = false;
    @Input() openSubSubMenuOnLeft: boolean = false;
    @Input() allowPopUp : boolean = true;
    @Input() disabled : boolean = false;
    //  addTabStopClass related to dp-excel-style.ts to add classes for the tabbing sequence
    @Input() addTabStopClass : boolean = false;
    @Output() itemClicked = new EventEmitter();
    @Output() menuOpened = new EventEmitter();


    burgerMenuClicked(event: BurgerMenuExtendedItem){
        if(this.items && this.items.length){
            this.itemClicked.emit(event.key ? event.key : event.text);
        }
        else{
            this.itemClicked.emit(event);
        }

    }

    burgerMenuOpened(event){
        if(event == 'open'){
            this.constructDpItems();
        }
        this.menuOpened.emit(event);
    }

    public constructor(public renderer : Renderer2) {

    }

    public ngOnInit() : void {
        //this.constructDpItems();

    }

    constructDpItems(){
        if(this.items && this.items.length){
            this.dpItems = [];
            let menuItem: BurgerMenuExtendedItem;
            for(let i=0;i<this.items.length;i++){
                const item = this.items[i];
                menuItem = new BurgerMenuExtendedItem();
                menuItem.text = item.text ? item.text : item;
                menuItem.key = item.key ? item.key : null;
                if(this.disabledItems && this.disabledItems.length){
                    if(this.disabledItems.indexOf((item)) != -1){
                        menuItem.isDisabled = true;
                    }
                }
                this.dpItems.push(menuItem);
                if (_.isArray(item.items)) {
                    const subItems = item.items as any[];
                    for (let subItem of subItems) {
                       let subMenuItem = new BurgerMenuExtendedItem();
                        subMenuItem.text = subItem.text ? subItem.text : subItem;
                        subMenuItem.key = subItem.key ? subItem.key : null;
                        menuItem.items.push(subMenuItem);
                    }
                }
            }
        }
    }




}
