import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {AccountingItemKeys} from '../../soa/soa-constants';
import {MatterType} from '../../../matters/shared/matter-specific-type';

export type TrustLedgerExpenseType = 'Receipt' | 'Expenditure';

export class TrustLedgerConfig extends BaseEntity {

    id: number;
    reservedFor: string;
    expenseDescription: string;
    expenseType: TrustLedgerExpenseType;
    calculated: boolean;
    f9DefaultAmount: number;
    code: string;
    readOnly: boolean;
    isSelected: boolean;
    deleted: boolean;
    matterType: MatterType;

    constructor(purchaseTrustLedger?: TrustLedgerConfig) {
        super(purchaseTrustLedger);
    }

    isExpenseDescriptionReadOnly(){
        return (this.code === AccountingItemKeys[AccountingItemKeys.RESERVED_FOR_MORTGAGE] ||
                this.code === AccountingItemKeys[AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE] ||
                this.code === AccountingItemKeys[AccountingItemKeys.CONSTRUCTION_MORTGAGE_HOLDBACKS]
        );
    }

    isMortgage(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.RESERVED_FOR_MORTGAGE]);
    }

    isConstructionMortgageHoldbacks() : boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.CONSTRUCTION_MORTGAGE_HOLDBACKS]);

    }

    isOtherHoldbacks() : boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.OTHER_HOLDBACKS]);

    }

    isPreviousMortgage(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE])
    }

    isLegalFee(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.TRUST_LEDGER_LEGAL_FEES]);
    }

    isOntarioLTT(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.ONTARIO_LTT_KEY]);
    }

    isLttNS(): boolean{
        return (this.code === AccountingItemKeys[AccountingItemKeys.NS_DTT_KEY]);
    }

    isLttNB(): boolean{
        return (this.code === AccountingItemKeys[AccountingItemKeys.NB_LTT_KEY]);
    }

    isPaidToYou(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.PAID_TO_CLIENT]);
    }

    isReceivedFromYou(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.RECEIVED_FROM_CLIENT]);
    }

    isReceivedFromPurchaserOnClosing(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.RECEIVED_FROM_PURCHASER_ON_CLOSING]);
    }

    isPaidToVendor(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.PAID_TO_VENDOR]);
    }

    isPaidToRealEstateBroker(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.PAID_TO_REAL_ESTATE_BROKER]);
    }

    isNrst(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.NRST]);
    }

    isEmp(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY]);
    }

    isTitleInsurance(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.INSURANCE_PREMIUM_KEY]);
    }

    isPaidTitleRegistrationFee(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.PAID_TITLE_REGISTRATION_FEE]);
    }

    isPaidMortgageRegistrationFee(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE]);
    }

    isRegistrationOfMortgageDischarge(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY]);
    }

    isRegistrationOfDischargeOfOther(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY]);
    }

    isRegistrationOfCharge(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY]);
    }

    isRegistrationOfTransfer(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY]);
    }

    isRegistrationOfVtbMortgage(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY]);
    }

    isRegistrationOfMortgage(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY]);
    }

    isRegistrationOfVendorLienCaveat(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY]);
    }

    isDischargeVendorLienCaveat(): boolean {
        return (this.code === AccountingItemKeys[AccountingItemKeys.DISCHARGE_VENDOR_LIEN_CAVEAT_KEY]);
    }

}
