// for global matter save and global matter validation these two object will be shared
// between all the components.
//ToDo: Not in use should be removed
export const matterValidation = {
    purchaserValid: true,
    propertyValid: true,
    matterOpeningValid: true,
};

export const errorMessage = {
    errorMessage: '',
    errorMessageMatterOpening: '',
};

export const errorProperty = {
    pin: 'The PIN: is invalid. In order to proceed,' +
    'the PIN entered must be completed in 99999-9999 format.',
}

export const errorMatterOpening = {
    matterNo: 'Matter no is required',
}
