import {AddressTypes} from '../../matters/shared/address-types';

export const dropDowns = {
    countryList: [
        { label: 'Canada', value: 'CA' },
        { label: 'USA', value: 'US' },
        { label: 'Afghanistan', value: 'AF' },
        { label: 'Albania', value: 'AL' },
        { label: 'Algeria', value: 'DZ' },
        { label: 'Andorra', value: 'AD' },
        { label: 'Angola', value: 'AO' },
        { label: 'Anguilla', value: 'AI' },
        { label: 'Antigua & Barbuda', value: 'AG' },
        { label: 'Argentina', value: 'AR' },
        { label: 'Armenia', value: 'AM' },
        { label: 'Australia', value: 'AU' },
        { label: 'Austria', value: 'AT' },
        { label: 'Azerbaijan', value: 'AZ' },
        { label: 'Bahamas', value: 'BS' },
        { label: 'Bahrain', value: 'BH' },
        { label: 'Bangladesh', value: 'BD' },
        { label: 'Barbados', value: 'BB' },
        { label: 'Belarus', value: 'BY' },
        { label: 'Belgium', value: 'BE' },
        { label: 'Belize', value: 'BZ' },
        { label: 'Benin', value: 'BJ' },
        { label: 'Bermuda', value: 'BM' },
        { label: 'Bhutan', value: 'BT' },
        { label: 'Bolivia', value: 'BO' },
        { label: 'Bosnia & Herzegovina', value: 'BA' },
        { label: 'Botswana', value: 'BW' },
        { label: 'Brazil', value: 'BR' },
        { label: 'Brunei Darussalam', value: 'BN' },
        { label: 'Bulgaria', value: 'BG' },
        { label: 'Burkina Faso', value: 'BF' },
        { label: 'Myanmar/Burma', value: 'MM' },
        { label: 'Burundi', value: 'BI' },
        { label: 'Cambodia', value: 'KH' },
        { label: 'Cameroon', value: 'CM' },
        { label: 'Cape Verde', value: 'CV' },
        { label: 'Cayman Islands', value: 'KY' },
        { label: 'Central African Republic', value: 'CF' },
        { label: 'Chad', value: 'TD' },
        { label: 'Chile', value: 'CL' },
        { label: 'China', value: 'CN' },
        { label: 'Colombia', value: 'CO' },
        { label: 'Comoros', value: 'KM' },
        { label: 'Congo', value: 'CG' },
        { label: 'Costa Rica', value: 'CR' },
        { label: 'Croatia', value: 'HR' },
        { label: 'Cuba', value: 'CU' },
        { label: 'Cyprus', value: 'CY' },
        { label: 'Czech Republic', value: 'CZ' },
        { label: 'Democratic Republic of the Congo', value: 'CD' },
        { label: 'Denmark', value: 'DK' },
        { label: 'Djibouti', value: 'DJ' },
        { label: 'Dominican Republic', value: 'DO' },
        { label: 'Dominica', value: 'DM' },
        { label: 'Ecuador', value: 'EC' },
        { label: 'Egypt', value: 'EG' },
        { label: 'El Salvador', value: 'SV' },
        { label: 'Equatorial Guinea', value: 'GQ' },
        { label: 'Eritrea', value: 'ER' },
        { label: 'Estonia', value: 'EE' },
        { label: 'Ethiopia', value: 'ET' },
        { label: 'Fiji', value: 'FJ' },
        { label: 'Finland', value: 'FI' },
        { label: 'France', value: 'FR' },
        { label: 'French Guiana', value: 'GF' },
        { label: 'Gabon', value: 'GA' },
        { label: 'Gambia', value: 'GM' },
        { label: 'Georgia', value: 'GE' },
        { label: 'Germany', value: 'DE' },
        { label: 'Ghana', value: 'GH' },
        { label: 'Greece', value: 'GR' },
        { label: 'Grenada', value: 'GD' },
        { label: 'Guadeloupe', value: 'GP' },
        { label: 'Guatemala', value: 'GT' },
        { label: 'Guinea', value: 'GN' },
        { label: 'Guinea-Bissau', value: 'GW' },
        { label: 'Guyana', value: 'GY' },
        { label: 'Haiti', value: 'HT' },
        { label: 'Honduras', value: 'HN' },
        { label: 'Hungary', value: 'HU' },
        { label: 'Hong Kong', value: 'HK' },
        { label: 'Iceland', value: 'IS' },
        { label: 'India', value: 'IN' },
        { label: 'Indonesia', value: 'ID' },
        { label: 'Iran', value: 'IR' },
        { label: 'Iraq', value: 'IQ' },
        { label: 'Israel and the Occupied Territories', value: 'IL' },
        { label: 'Italy', value: 'IT' },
        { label: 'Ivory Coast', value: 'CI' },
        { label: 'Jamaica', value: 'JM' },
        { label: 'Japan', value: 'JP' },
        { label: 'Jordan', value: 'JO' },
        { label: 'Kazakhstan', value: 'KZ' },
        { label: 'Kenya', value: 'KE' },
        { label: 'Kosovo', value: 'XK' },
        { label: 'Kuwait', value: 'KW' },
        { label: 'Kyrgyz Republic (Kyrgyzstan)', value: 'KG' },
        { label: 'Laos', value: 'LA' },
        { label: 'Latvia', value: 'LV' },
        { label: 'Lebanon', value: 'LB' },
        { label: 'Lesotho', value: 'LS' },
        { label: 'Liberia', value: 'LR' },
        { label: 'Libya', value: 'LY' },
        { label: 'Liechtenstein', value: 'LI' },
        { label: 'Lithuania', value: 'LT' },
        { label: 'Luxembourg', value: 'LU' },
        { label: 'Republic of Macedonia', value: 'MK' },
        { label: 'Madagascar', value: 'MG' },
        { label: 'Malawi', value: 'MW' },
        { label: 'Malaysia', value: 'MY' },
        { label: 'Maldives', value: 'MV' },
        { label: 'Mali', value: 'ML' },
        { label: 'Malta', value: 'MT' },
        { label: 'Martinique', value: 'MQ' },
        { label: 'Mauritania', value: 'MR' },
        { label: 'Mauritius', value: 'MU' },
        { label: 'Mayotte', value: 'YT' },
        { label: 'Mexico', value: 'MX' },
        { label: 'Moldova, Republic of', value: 'MD' },
        { label: 'Monaco', value: 'MC' },
        { label: 'Mongolia', value: 'MN' },
        { label: 'Montenegro', value: 'ME' },
        { label: 'Montserrat', value: 'MS' },
        { label: 'Morocco', value: 'MA' },
        { label: 'Mozambique', value: 'MZ' },
        { label: 'Namibia', value: 'NA' },
        { label: 'Nepal', value: 'NP' },
        { label: 'Netherlands', value: 'NL' },
        { label: 'New Zealand', value: 'NZ' },
        { label: 'Nicaragua', value: 'NI' },
        { label: 'Niger', value: 'NE' },
        { label: 'Nigeria', value: 'NG' },
        { label: 'Korea, Democratic Republic of (North Korea)', value: 'KP' },
        { label: 'Norway', value: 'NO' },
        { label: 'Oman', value: 'OM' },
        { label: 'Pakistan', value: 'PK' },
        { label: 'Panama', value: 'PA' },
        { label: 'Papua New Guinea', value: 'PG' },
        { label: 'Paraguay', value: 'PY' },
        { label: 'Peru', value: 'PE' },
        { label: 'Philippines', value: 'PH' },
        { label: 'Poland', value: 'PL' },
        { label: 'Portugal', value: 'PT' },
        { label: 'Puerto Rico', value: 'PR' },
        { label: 'Qatar', value: 'QA' },
        { label: 'Reunion', value: 'RE' },
        { label: 'Romania', value: 'RO' },
        { label: 'Russian Federation', value: 'RU' },
        { label: 'Rwanda', value: 'RW' },
        { label: 'Saint Kitts and Nevis', value: 'KN' },
        { label: 'Saint Lucia', value: 'LC' },
        { label: 'Saint Vincent\'s & Grenadines', value: 'VC' },
        { label: 'Samoa', value: 'WS' },
        { label: 'Sao Tome and Principe', value: 'ST' },
        { label: 'Saudi Arabia', value: 'SA' },
        { label: 'Senegal', value: 'SN' },
        { label: 'Serbia', value: 'RS' },
        { label: 'Seychelles', value: 'SC' },
        { label: 'Sierra Leone', value: 'SL' },
        { label: 'Singapore', value: 'SG' },
        { label: 'Slovak Republic (Slovakia)', value: 'SK' },
        { label: 'Slovenia', value: 'SI' },
        { label: 'Solomon Islands', value: 'SB' },
        { label: 'Somalia', value: 'SO' },
        { label: 'South Africa', value: 'ZA' },
        { label: 'Korea, Republic of (South Korea)', value: 'KR' },
        { label: 'South Sudan', value: 'SS' },
        { label: 'Spain', value: 'ES' },
        { label: 'Sri Lanka', value: 'LK' },
        { label: 'Sudan', value: 'SD' },
        { label: 'Suriname', value: 'SR' },
        { label: 'Swaziland', value: 'SZ' },
        { label: 'Sweden', value: 'SE' },
        { label: 'Switzerland', value: 'CH' },
        { label: 'Syria', value: 'SY' },
        { label: 'Tajikistan', value: 'TJ' },
        { label: 'Tanzania', value: 'TZ' },
        { label: 'Thailand', value: 'TH' },
        { label: 'Timor Leste', value: 'TL' },
        { label: 'Togo', value: 'TG' },
        { label: 'Trinidad & Tobago', value: 'TT' },
        { label: 'Tunisia', value: 'TN' },
        { label: 'Turkey', value: 'TR' },
        { label: 'Turkmenistan', value: 'TM' },
        { label: 'Turks & Caicos Islands', value: 'TC' },
        { label: 'Uganda', value: 'UG' },
        { label: 'Ukraine', value: 'UA' },
        { label: 'United Arab Emirates', value: 'AE' },
        { label: 'United Kingdom', value: 'GB' },
        { label: 'United States Minor Outlying Islands', value: 'UM' },
        { label: 'Uruguay', value: 'UY' },
        { label: 'Uzbekistan', value: 'UZ' },
        { label: 'Venezuela', value: 'VE' },
        { label: 'Vietnam', value: 'VN' },
        { label: 'Virgin Islands (UK)', value: 'VG' },
        { label: 'Virgin Islands (US)', value: 'VI' },
        { label: 'Yemen', value: 'YE' },
        { label: 'Zambia', value: 'ZM' },
        { label: 'Zimbabwe', value: 'ZW' }
    ],
    provinceName: [
        { label: 'Ontario', value: 'ON' },
        { label: 'Alberta', value: 'AB' },
        { label: 'Saskatchewan', value: 'SK' },
        { label: 'Manitoba', value: 'MB' },
        { label: 'British Columbia', value: 'BC' },
        { label: 'Quebec', value: 'QC' },
        { label: 'Newfoundland and Labrador', value: 'NL' },
        { label: 'New Brunswick', value: 'NB' },
        { label: 'Prince Edward Island', value: 'PE' },
        { label: 'Nova Scotia', value: 'NS' },
        { label: 'Yukon', value: 'YT' },
        { label: 'Northwest Territories', value: 'NT' },
        { label: 'Nunavut', value: 'NU' }
    ],
    provinceCodeValidator: [
        { label: 'Ontario', value: 'ON' },
        { label: 'Alberta', value: 'AB' },
        { label: 'Saskatchewan', value: 'SK' },
        { label: 'Manitoba', value: 'MB' },
        { label: 'British Columbia', value: 'BC' },
        { label: 'Quebec', value: 'QC' },
        { label: 'Newfoundland and Labrador', value: 'NL' },
        { label: 'New Brunswick', value: 'NB' },
        { label: 'Prince Edward Island', value: 'PE' },
        { label: 'Nova Scotia', value: 'NS' },
        { label: 'Yukon', value: 'YT' },
        { label: 'Northwest Territories', value: 'NT' },
        { label: 'Nunavut', value: 'NU' },
        { label: 'ON', value: 'ON' },
        { label: 'AB', value: 'AB' },
        { label: 'SK', value: 'SK' },
        { label: 'MB', value: 'MB' },
        { label: 'BC', value: 'BC' },
        { label: 'QC', value: 'QC' },
        { label: 'NL', value: 'NL' },
        { label: 'NB', value: 'NB' },
        { label: 'PE', value: 'PE' },
        { label: 'NS', value: 'NS' },
        { label: 'YT', value: 'YT' },
        { label: 'NT', value: 'NT' },
        { label: 'NU', value: 'NU' },
        { label: 'Québec', value: 'QC' }
    ],
    addressTypes: [
        { label: '', value: '' },
        { label: 'Mailing', value: AddressTypes.mailing},
        { label: 'Residence', value: AddressTypes.residence },
        { label: 'Business', value: AddressTypes.business },
        { label: 'Registered Office', value: AddressTypes.registeredOffice },
        { label: 'Previous Mailing Address', value: AddressTypes.previousMailingAddress },
        { label: 'Other', value: AddressTypes.manuallyEntered }
    ]
};

export class AddressDropdowns {
    static sameAsMailing: string = "Same as mailing address";
    static sameAsPreClosing: string = "Same as Pre-Closing address";
    static sameAsSubject: string = "Same as Subject Property address";
    static sameAsSolicitor: string = "Same as solicitor address";
    static sameAsService: string = "Same as service address";
    static manuallyEntered: string = "Manually entered";
    static sameAsFirm: string = "Same as Firm";
    static sameAsAccount: string = "Same as Account";
};
