import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

declare var jQuery:any;

@Component({
    selector: 'dp-accordion',
    templateUrl : 'accordion.component.html'
})
export class DPPMAccordionComponent implements OnInit {

    // if expanded set to true, show expanded accordion otherwise by default show collapsed
    @Input('expanded') expanded : boolean = false;

    // if primary is set to true, shows accordian with offset pixels for cases of shutters on primary levels
    @Input('primary') primary : boolean = false;

    //**** USE THIS ONE FIRST then try the others, if primaryOther is set to true, shows accordian with offset pixels for certain cases of shutters on primary
    // levels
    @Input('primaryOther') primaryOther : boolean = false;

    // if secondary is set to true, shows accordian with offset pixels for cases of shutters within shutters.
    @Input('secondary') secondary : boolean = false;

    // if offset set to true, we offset the spacing of the accordian to account for more space
    @Input('offset') offset : boolean = false;

    // if offsetOther set to true, we offset the spacing of the accordian to account for more space in other types of scenerios like for ex on id details page
    @Input('offsetOther') offsetOther : boolean = false;

    // if hideShutter set to true, we hide the shutter arrows and background
    @Input('hideShutter') hideShutter : boolean = false;

    @Output() shutterClicked = new EventEmitter<boolean>();

    constructor(public elt:ElementRef) {


    }


    ngOnInit(): void {

    }

    toggleAccordion(e){

        if(e.keyCode==13 || e.keyCode==120 || e.type==='click') {
            e.preventDefault();
            if(this.expanded) {
                this.expanded = false;
            } else {
                this.expanded = true;
            }
            this.shutterClicked.emit(this.expanded);
        }
    }

}
