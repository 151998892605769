import {Contact} from '../../matters/shared/contact';
import {MatterParticipantWrapper} from '../../matters/shared/matter-participant-wrapper';
import {GenderTypes} from '../contact-type-mapping';

export class ContactSuggestionBase {

    protected mergeListOfNames(listOfNames: string[], lastJoin: string): string {

        if (listOfNames) {
            if (listOfNames.length === 0) {
                return '';
            }
            else if (listOfNames.length === 1) {
                return listOfNames[0];

            } else {
                let lastOne = listOfNames.pop();

                return listOfNames.join(', ') + lastJoin + lastOne;
            }
        }
        return '';
    }

    protected getTitle(contact: Contact): string {
        let result: string = '';
        if (contact) {
            if (contact.gender === 'MALE' || contact.gender === 'MALEPOA')
                result = 'Mr. ';
            if (contact.gender === 'FEMALE' || contact.gender === 'FEMALEPOA')
                result = 'Ms. ';
        }
        return result;
    }

    protected getName(contact: Contact, includeTitle: boolean, useFirstLetterFirstName: boolean, includeLastName: boolean = true): string {
        let result: string = '';

        if (contact && contact.contactName) {

            if (includeTitle)
                result = this.getTitle(contact);

            if (useFirstLetterFirstName)
                result += contact.contactName.firstName ? contact.contactName.firstName[0] + '. ' : '';
            else
                result += contact.contactName.firstName ? contact.contactName.firstName + ' ' : '';

            if (contact.contactName.lastName && includeLastName)
                result += contact.contactName.lastName;

        }
        return result.trim();
    }

    protected contactsOfOppositeGender(c0: Contact, c1: Contact): boolean{

        return (((c0.gender === 'MALE' || c0.gender === 'MALEPOA') && (c1.gender === 'FEMALE' || c1.gender === 'FEMALEPOA')) ||
                ((c1.gender === 'MALE' || c1.gender === 'MALEPOA') && (c0.gender === 'FEMALE' || c0.gender === 'FEMALEPOA')));
    }

    protected allParticipantsArePersons(participants: Array<MatterParticipantWrapper>){
        if(participants){
            return participants.every(p => (
                p.matterParticipant &&
                ( p.matterParticipant.contact.gender === 'MALE' ||
                  p.matterParticipant.contact.gender === 'MALEPOA' ||
                  p.matterParticipant.contact.gender === 'FEMALE' ||
                  p.matterParticipant.contact.gender === 'FEMALEPOA' ||
                  p.matterParticipant.contact.gender === 'ESTATE'
                )
            ));
        }
        return false;
    }

    protected isAnyParticipantWithoutGender(participants: Array<MatterParticipantWrapper>) : boolean{
        return participants.some(p =>  p.matterParticipant && p.matterParticipant.contact && p.matterParticipant.contact.gender == 'QUESTION') ;
    }

    protected personIsPOAOrEstate(contact: Contact): boolean {
        return contact.gender ==='MALEPOA' || contact.gender ==='FEMALEPOA' || contact.gender ==='ESTATE';
    }

    protected anySpouseIsPOAOrEstate(sp1: Contact, sp2: Contact): boolean {
        return this.personIsPOAOrEstate(sp1) || this.personIsPOAOrEstate(sp2);
    }

    protected allParticipantsArePersonsOrBlank(participants: Array<MatterParticipantWrapper>){
        if(participants){
            return participants.every(p => (
                p.matterParticipant && p.matterParticipant && p.matterParticipant.contact &&
                ( p.matterParticipant.contact.gender === GenderTypes.MALE ||
                    p.matterParticipant.contact.gender === GenderTypes.MALEPOA ||
                    p.matterParticipant.contact.gender === GenderTypes.FEMALE ||
                    p.matterParticipant.contact.gender === GenderTypes.FEMALEPOA ||
                    p.matterParticipant.contact.gender === GenderTypes.ESTATE    ||
                    p.matterParticipant.contact.gender === GenderTypes.QUESTION
                )
            ));
        }
        return false;
    }
}
