import {DPError} from '../../../shared/error-handling/dp-error';
import {AddressTypes} from '../../shared/address-types';
import {Address, dropDowns, Matter, MatterParticipant} from '../../shared';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {ERegStatus, ERegType} from '../../../shared-main/constants';
import {Mortgage} from '../../shared/mortgage';
import {MatterParticipantRoleTypes} from '../../shared/matter-participant-role-types';
import {ERegistrationForm} from './eregistrationform';

export class EregistrationUtil {

    static validateERegTransfer(matter : Matter,errorService : ErrorService , ignoreErrorsOnSave  : boolean = false , projectTemplateMatter ? : Matter) : boolean {

        if (matter.matterType == 'PURCHASE' || matter.matterType == 'SALE') {
            errorService.clearAllMissingFieldErrors();
            let errors: DPError[] = [];

            errors.push(...this.validatePurchaseAndSaleERegTransfer(matter, errorService, projectTemplateMatter));

            if (matter.isPurchase) {
                errors.push(...this.validatePurchaseERegTransfer(matter, errorService));
            }
            if (matter.isSale) {
                errors.push(...this.validateSaleERegTransfer(matter, errorService, projectTemplateMatter));
            }
            if (ignoreErrorsOnSave) {
                return errors.length > 0 ? true : false;
            }
            this.hideBubbleNotifications(errors);
            errors.forEach(error => errorService.addDpMissingFieldError(error));
            if (errorService.hasAnyMissingFieldErrors()) {
                errorService.setMissingFieldHeader('E-Reg \u2122 Transfer Errors');
            }
            return errorService.hasAnyMissingFieldErrors();
        }
    }

    static hideBubbleNotifications(errors : DPError[]): void{
        errors.forEach(error => error.showBubbleNotification = false);
    }

    static validatePurchaseAndSaleERegTransfer(matter: Matter, errorService: ErrorService , projectTemplateMatter?: Matter): DPError[] {
        let errors : DPError[] = [];
        if (matter.isPurchase || matter.isSale){
            if((matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPropertyCondominium() && matter.matterPropertyWithCondo.condominiumExpenses
                && matter.matterPropertyWithCondo.condominiumExpenses.length == 0) ||
                (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPropertyCondominium() && matter.matterPropertyWithCondo.pin == '') ||
                (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPropertyCondominium()
                    && matter.matterPropertyWithCondo.condominiumExpenses && matter.matterPropertyWithCondo.condominiumExpenses.length>0
                    && matter.matterPropertyWithCondo.condominiumExpenses.findIndex(item => item.pinNumber && this.validatePinLengthWithDash(item.pinNumber) == 9) < 0)) {
                errors.push(DPError.createDPError('matter.ereg.pin'));
            }
            else if(matter.matterPropertyWithCondo && !matter.matterPropertyWithCondo.isPropertyCondominium() && matter.matterProperties.length > 0
                && matter.matterProperties.findIndex(item => item.pin && this.validatePinLengthWithDash(item.pin) == 9) < 0) {
                errors.push(DPError.createDPError('matter.ereg.pin'));
            }
            if(!matter.considerationLtt || (matter.considerationLtt &&
                matter.considerationLtt && matter.considerationLtt.valueSubjectToLttAToE1 == 0)) {
                if(matter.isProjectSale && matter.isMatterProvinceON && matter.considerationLtt
                    && (matter.considerationLtt.completionOfTaxInfoType == 'COMPLETE_TAX_INFO_MANUALLY')){
                        errors.push(DPError.createDPError('matter.ereg.soa.consideration'));
                } else {
                    matter.isPurchase ? errors.push(DPError.createDPError('matter.ereg.ltt')) :
                        errors.push(DPError.createDPError('matter.ereg.soa'));
                }
            }

            if(matter.isProjectSale && matter.isTransferFromThirdPartyOnProject){
                if(!projectTemplateMatter || !projectTemplateMatter.transferors || projectTemplateMatter.transferors.length == 0) {
                    errors.push(DPError.createCustomDPError('matter.ereg.vendor',
                        'Name of 3rd Party Transferor has not been specified in Project Record','Project Record', "ERROR"));
                }else if(projectTemplateMatter.transferors && projectTemplateMatter.transferors.length > 0) {
                    let vendors = projectTemplateMatter.transferors.filter(item => item.matterParticipantRole == 'TRANSFEROR');
                    vendors.forEach(vendor => {
                        if(vendor && vendor.contact && vendor.contact.isCorporationOrOtherEntity && !this.isSigningOfficersExistOnMatter(projectTemplateMatter, vendor)) {
                            errors.push(DPError.createCustomDPError('matter.ereg.vendor.additionalName', 'No signing officer ' + (vendor.contact.organizationName ? 'for 3rd Party Transferor ' + vendor.contact.organizationName : ''),
                                'Project Record', "ERROR"));
                        }
                    });
                }
            }else{
                if(!matter.matterParticipants || matter.matterParticipants.length == 0 || (
                    matter.matterParticipants && matter.matterParticipants.length > 0 &&
                    matter.matterParticipants.findIndex(item => item.matterParticipantRole == 'VENDOR') < 0)) {

                    //this.errorService.addDpMissingFieldError(DPError.createDPError('matter.ereg.vendor'));
                    /*errorService.addDpMissingFieldError(DPError.createCustomDPError('matter.ereg.vendor',
                        'Name of Vendor has not been specified in ' + (matter.isPurchase ? 'Vendors & Solicitor' : 'Vendors'),
                        (matter.isPurchase ? 'Vendors & Solicitor' : 'Vendors'), "ERROR"));*/
                    errors.push(DPError.createCustomDPError('matter.ereg.vendor',
                        'Name of Vendor has not been specified in ' + (matter.isPurchase ? 'Vendors & Solicitor' : 'Vendors'),
                        (matter.isPurchase ? 'Vendors & Solicitor' : 'Vendors'), "ERROR"));
                } else if(matter.matterParticipants && matter.matterParticipants.length > 0
                    && matter.matterParticipants.findIndex(item => item.matterParticipantRole == 'VENDOR') > -1) {
                    let vendors = matter.matterParticipants.filter(item => item.matterParticipantRole == 'VENDOR');
                    vendors.forEach(vendor => {
                        if(vendor && vendor.contact && vendor.contact.isCorporationOrOtherEntity && !this.isSigningOfficersExistOnMatter(matter, vendor)) {
                            errors.push(DPError.createCustomDPError('matter.ereg.vendor.additionalName', 'No signing officer ' + (vendor.contact.organizationName ? 'for ' + vendor.contact.organizationName : ''),
                                (matter.isPurchase ? 'Vendors & Solicitor' : 'Vendors'), "ERROR"));
                        }
                    })

                }
            }

            if(matter.matterParticipants && matter.matterParticipants.length > 0
                && matter.matterParticipants.findIndex(item => item.matterParticipantRole == 'PURCHASER') > -1) {
                let purchasers = matter.matterParticipants.filter(item => item.matterParticipantRole == 'PURCHASER');
                purchasers.forEach((purchaser)=>{
                    if(purchaser && purchaser.contact && purchaser.contact.isCorporationOrOtherEntity && purchaser.contact.organizationName && purchaser.contact.organizationName.indexOf(',') > -1) {
                        errors.push(DPError.createCustomDPError('matter.ereg.vendor.organizationName'+purchaser.contact.id, 'Corporate name cannot' +
                            ' contain a comma ' + (purchaser.contact.organizationName),
                            (matter.isPurchase ? 'Purchasers' : 'Vendors'), "ERROR"));
                    }
                });
            }
            if(!matter.purchasers || (matter.purchasers && matter.purchasers.length == 0) ||
                (matter.purchasers && matter.purchasers.length > 0
                    && !(matter.purchasers.findIndex(item => item.contact && !item.contact.isContactNameNotSpecified()) > -1))) {
                //this.errorService.addDpMissingFieldError(DPError.createDPError('matter.ereg.Purchaser'));
                errors.push(DPError.createCustomDPError('matter.ereg.Purchaser.purchase', 'Name of Purchaser has not been specified in ' + (matter.isPurchase ? 'Purchaser' : 'Purchaser & Solicitor'),
                    (matter.isPurchase ? 'Purchaser' : 'Purchaser & Solicitor'), "ERROR"));
            }

            if(matter.purchasers && matter.purchasers.length > 0 && this.purchasersCapacity(matter) === 'OTHER') {
                matter.purchasers.forEach(purchaser => {
                    if((!purchaser.purchaserCapacity || !purchaser.purchaserShare) && purchaser.contact) {
                        errors.push(DPError.createCustomDPError('matter.ereg.purchaser.purchaserShare' + purchaser.identifier, 'Capacity not completed for ' + (purchaser.contact.contactFullNameStartWithFirstName), 'Purchaser', "ERROR"));
                    }
                });
            }
        }
        return errors;
    }

    static validatePurchaseERegTransfer(matter: Matter, errorService: ErrorService): DPError[] {
        let errors: DPError[] = [];
        if (matter.isPurchase) {
            if (!matter.considerationLtt || (matter.considerationLtt &&
                matter.considerationLtt && matter.considerationLtt.fairMarketValueOfLands > 0 && !matter.considerationLtt.isTableValueAmountInAEqualsAmountF())) {
                errors.push(DPError.createDPError('matter.ereg.fairMarket'));
            }
            if (!matter.matterContactInfo
                || (matter.matterContactInfo && !matter.matterContactInfo.postClosingAddress)
                || (matter.matterContactInfo && matter.matterContactInfo.postClosingAddress && matter.matterContactInfo.postClosingAddress.isEmpty
                    && (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.manuallyEntered || !matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode ||
                        matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == ''))
                || (matter.matterContactInfo && matter.matterContactInfo.postClosingAddress && matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.preClosingAddress &&
                    (!matter.matterContactInfo.preClosingAddress || (matter.matterContactInfo.preClosingAddress && matter.matterContactInfo.preClosingAddress.isEmpty)))
                || (matter.matterContactInfo && matter.matterContactInfo.postClosingAddress && matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.subjectAddress &&
                    (!matter.matterPropertyWithCondo || (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address.isEmpty)))
            ) {
                // this.errorService.addDpMissingFieldError(DPError.createDPError('matter.ereg.Purchaser.postClosingAddress'));
                errors.push(DPError.createCustomDPError('matter.ereg.postClosingAddress',
                    (matter.isPurchase ? 'Post Closing address has not been specified in Purchasers' : 'Vendor address for service has not been specified in Vendors'),
                    (matter.isPurchase ? 'Purchaser' : 'Vendors'), "ERROR"));
            }
        }
        return errors;
    }

    static validateSaleERegTransfer(matter: Matter, errorService: ErrorService , projectTemplateMatter?: Matter): DPError[] {
        let errors: DPError[] = [];
        if (matter.isProjectOrProjectSale || (matter.isSale && matter.isMatterProvinceON)){
            if (!matter.considerationLtt || (matter.considerationLtt &&
                Number(matter.considerationLtt.fairMarketValueOfLands) > 0)) {
                errors.push(DPError.createDPError('matter.ereg.fairMarket'));
            }
        }
        if (matter.isSale) {
            if (matter.isProjectSale && matter.isTransferFromThirdPartyOnProject) {
                if (!projectTemplateMatter || !projectTemplateMatter.transferors || projectTemplateMatter.transferors.length == 0 ||
                    (projectTemplateMatter.transferors.length > 0 && this.isServiceAddressNotAvailable(projectTemplateMatter.transferors[0]))) {
                    errors.push(DPError.createCustomDPError('matter.project.transferor.address',
                        '3rd Party Transferor address for service has not been specified in Project Record ',
                        'Project Record', "ERROR"));
                }
            } else {
                if ((!matter.otherPartyContactInfo)
                    || (matter.otherPartyContactInfo.isResideAtSubjectProperty && (!matter.matterPropertyWithCondo.address
                        || (matter.matterPropertyWithCondo.address && matter.matterPropertyWithCondo.address.isEmpty)))
                    || (!matter.otherPartyContactInfo.isResideAtSubjectProperty && (!matter.otherPartyContactInfo.serviceAddress
                        || (matter.otherPartyContactInfo.serviceAddress && matter.otherPartyContactInfo.serviceAddress.isEmpty)))
                ) {
                    errors.push(DPError.createDPError('matter.ereg.purchaser.address'));
                }
            }
        }
        return errors;
    }


    static isServiceAddressNotAvailable(transferor : MatterParticipant): boolean{
        return !transferor.contact || !(transferor.contact.address.findIndex(address => address.addressTypeCode === "SERVICE") > -1)
            || transferor.contact.address.find(address => address.addressTypeCode === "SERVICE").isEmpty;
    }

    static validateERegMortgage(mortgageId : number, matter : Matter , errorService : ErrorService , ignoreErrorsOnSave : boolean = false) : boolean {
        errorService.clearAllMissingFieldErrors();
        let errors : DPError[] = [];
        let selectedMortgage : Mortgage;
        if(mortgageId != undefined) {
            selectedMortgage = matter.newOrEmpMortgages.find(item => item.id == mortgageId);
        }

        if((matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPropertyCondominium() && matter.matterPropertyWithCondo.condominiumExpenses
            && matter.matterPropertyWithCondo.condominiumExpenses.length == 0) ||
            (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPropertyCondominium()
                && matter.matterPropertyWithCondo.condominiumExpenses && matter.matterPropertyWithCondo.condominiumExpenses.length > 0
                && matter.matterPropertyWithCondo.condominiumExpenses.findIndex(item => item.pinNumber && this.validatePinLengthWithDash(item.pinNumber) == 9) < 0) ){
            errors.push(DPError.createDPError('matter.ereg.pin'));
        }
        else if(matter.matterPropertyWithCondo && !matter.matterPropertyWithCondo.isPropertyCondominium() && matter.matterProperties.length > 0
            && matter.matterProperties.findIndex(item => item.pin && this.validatePinLengthWithDash(item.pin) == 9) < 0) {
            errors.push(DPError.createDPError('matter.ereg.pin'));
        }
        if(selectedMortgage && (!selectedMortgage.mortgageTerm || (selectedMortgage && selectedMortgage.mortgageTerm && (selectedMortgage.mortgageTerm.principal < 1
            || selectedMortgage.mortgageTerm.principal == undefined)))) {
            errors.push(DPError.createDPError('matter.ereg.principal.' + (matter.isProjectSale ? 'project' :  matter.matterType.toLowerCase())));

        }

        if(!this.mortgagePurchasers || (this.mortgagePurchasers && this.mortgagePurchasers.length == 0) ||
            (this.mortgagePurchasers && this.mortgagePurchasers.length > 0
                && !(this.mortgagePurchasers(matter).findIndex(item => item.contact && !item.contact.isContactNameNotSpecified()) > -1))) {
            errors.push(DPError.createDPError('matter.ereg.Purchaser.'  + matter.matterType.toLowerCase()));
        }
        else if(this.mortgagePurchasers && this.mortgagePurchasers.length > 0) {
            this.mortgagePurchasers(matter).forEach(item => {
                if(item && item.contact && item.contact.isCorporationOrOtherEntity && !this.isSigningOfficersExistOnMatter(matter,item)) {
                    let errorTopic: string = 'Purchaser';
                    if (matter.isMortgage) {
                        errorTopic = 'Mortgagors';
                    } else if (matter.isSale) {
                        errorTopic = 'Purchasers & Solicitor';
                    }
                    errors.push(DPError.createCustomDPError('matter.ereg.purchaser.additionalName', 'No signing officer ' + (item.contact.organizationName ? 'for ' + item.contact.organizationName : ''), errorTopic, "ERROR"));

                }
            });
        }

        if(matter.isPurchase &&
            (!matter.matterContactInfo
                || (matter.matterContactInfo && !matter.matterContactInfo.postClosingAddress)
                || (matter.matterContactInfo && matter.matterContactInfo.postClosingAddress && matter.matterContactInfo.postClosingAddress.isEmpty
                    && (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.manuallyEntered || !matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode ||
                        matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == ''))
                || (matter.matterContactInfo && matter.matterContactInfo.postClosingAddress && matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.preClosingAddress &&
                    (!matter.matterContactInfo.preClosingAddress || (matter.matterContactInfo.preClosingAddress && matter.matterContactInfo.preClosingAddress.isEmpty)))
                || (matter.matterContactInfo && matter.matterContactInfo.postClosingAddress && matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.subjectAddress &&
                    (!matter.matterPropertyWithCondo || (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address.isEmpty))))
        ) {
            errors.push(DPError.createDPError('matter.ereg.Purchaser.postClosingAddress'));
        }

        if (matter.isMortgage &&
            (!matter.matterContactInfo
                || (matter.matterContactInfo.isResideAtSubjectProperty && (!matter.matterPropertyWithCondo || (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address.isEmpty)))
                || (!matter.matterContactInfo.isResideAtSubjectProperty && (matter.matterContactInfo && matter.matterContactInfo.postClosingAddress && matter.matterContactInfo.postClosingAddress.isEmpty)))
        ) {
            errors.push(DPError.createDPError('matter.ereg.purchaser.address.mortgage'));
        } else if (matter.isSale &&
            (!matter.otherPartyContactInfo
                || (matter.otherPartyContactInfo.isResideAtSubjectProperty && (!matter.matterPropertyWithCondo || (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address.isEmpty)))
                || (!matter.otherPartyContactInfo.isResideAtSubjectProperty && (matter.otherPartyContactInfo && matter.otherPartyContactInfo.serviceAddress && matter.otherPartyContactInfo.serviceAddress.isEmpty)))
        ) {
            errors.push(DPError.createDPError('matter.ereg.purchaser.address.sale'));
        }

        if(!matter.matterParticipants || (matter.matterParticipants && matter.matterParticipants.length == 0) ||
            (matter.matterParticipants && matter.matterParticipants.length > 0
                && matter.matterParticipants.filter(p => selectedMortgage && p.mortgageId === selectedMortgage.id
                    && p.matterParticipantRole === (selectedMortgage.mortgageeType === 'INSTITUTION' ? 'MORTGAGEE' : 'PRIVATE_LENDER')).length == 0)
        ) {
            errors.push(DPError.createDPError('matter.ereg.mortgage.' + (matter.isProjectSale ? 'project': matter.matterType.toLowerCase())));
        }
        else if(matter.matterParticipants && matter.matterParticipants.length > 0
            && matter.matterParticipants.filter(p => selectedMortgage && p.mortgageId === selectedMortgage.id
                && p.matterParticipantRole === (selectedMortgage.mortgageeType === 'INSTITUTION' ? 'MORTGAGEE' : 'PRIVATE_LENDER')).length > 0) {
            let matterParticipants = matter.matterParticipants.filter(p => selectedMortgage && p.mortgageId === selectedMortgage.id
                && p.matterParticipantRole === (selectedMortgage.mortgageeType === 'INSTITUTION' ? 'MORTGAGEE' : 'PRIVATE_LENDER'));
            matterParticipants.forEach(item => {
                if(item && item.contact && item.contact.isCorporationOrOtherEntity && item.contact.organizationName && item.contact.organizationName.indexOf(',') > -1) {
                    errors.push(DPError.createCustomDPError('matter.ereg.mortgagee.organizationName'+item.identifier, 'Corporate name cannot' +
                        ' contain a comma ' + (item.contact.organizationName), 'Mortgagee', "ERROR"));
                }

                if(selectedMortgage && selectedMortgage.isCapacityOther() && item && !item.purchaserCapacity) {
                    const capacityName: string = item.contact.contactType === 'PERSON' ? item.contact.fullName : item.contact.organizationName;
                    errors.push(DPError.createCustomDPError('matter.ereg.mortgagee.capacity'+item.identifier, 'Capacity not completed ' + (capacityName ? 'for ' + capacityName : ''), 'Mortgagee', "ERROR"));
                }

                if (item && ((item.matterParticipantRole === 'PRIVATE_LENDER' && item.primary) || (item.matterParticipantRole != 'PRIVATE_LENDER')) &&  this.isMortgageeAddressForServiceEmpty(item, selectedMortgage , matter)) {
                    if(matter.isMortgage) {
                        errors.push(DPError.createCustomDPError('matter.ereg.participantInfo.address.' + item.matterParticipantId, 'Address for service' +
                            ' not completed for ' + item.contact.genericFullNameWithLastUpper, 'Mortgages', 'ERROR'));
                    }else{
                        errors.push(DPError.createCustomDPError('matter.ereg.participantInfo.address.' + item.matterParticipantId, 'Address for service' +
                            ' not completed for ' + this.participantName(item), 'Mortgages', 'ERROR'));
                    }
                }

            });
        }

        if(ignoreErrorsOnSave){
            return errors.length > 0 ? true : false;
        }
        this.hideBubbleNotifications(errors);
        errors.forEach(error => errorService.addDpMissingFieldError(error));
        if(errorService.hasAnyMissingFieldErrors()) {
            let index  = matter.newOrEmpMortgages.findIndex(item => item.id  == selectedMortgage.id);
            let matterPriority : number = matter.matterType === 'PURCHASE' ? index + 1 : selectedMortgage.mortgagePriority;
            errorService.setMissingFieldHeader('E-Reg \u2122 '+ matterPriority + this.ordinalPrefix(matterPriority) + ' Mortgage Errors');
        }

        return errorService.hasAnyMissingFieldErrors()
    }

    static getNewMortgageErrorTopicName(matter : Matter): string{
        return  (matter.isProjectSale ? 'Purchaser\'s Mortgage(s)' : matter.isPurchase ? 'Mortgages' : matter.isSale ? 'VTB Mortgage(s)': 'New Mortgages')
    }

    static validateERegDischarge(mortgageId : number ,mortgageIndex : number  , matter : Matter , errorService : ErrorService , ignoreErrorsOnSave : boolean = false) : boolean {
        if(matter.matterType == 'SALE' || matter.matterType == 'MORTGAGE'){
            errorService.clearAllMissingFieldErrors();
            let errors : DPError[] = [];
            let selectedMortgage : Mortgage;
            if(mortgageId != undefined) {
                selectedMortgage = matter.existingMortgages.find(item => item.id == mortgageId);
            }

            if((matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPropertyCondominium() && matter.matterPropertyWithCondo.condominiumExpenses
                && matter.matterPropertyWithCondo.condominiumExpenses.length == 0) || (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPropertyCondominium()
                && matter.matterPropertyWithCondo.condominiumExpenses && matter.matterPropertyWithCondo.condominiumExpenses.length > 0
                && matter.matterPropertyWithCondo.condominiumExpenses.findIndex(item => item.pinNumber &&  this.validatePinLengthWithDash(item.pinNumber) == 9) < 0) ){
                errors.push(DPError.createDPError('matter.ereg.pin'));
            }
            else if(matter.matterPropertyWithCondo && !matter.matterPropertyWithCondo.isPropertyCondominium() && matter.matterProperties.length > 0
                && matter.matterProperties.findIndex(item => item.pin &&  this.validatePinLengthWithDash(item.pin) == 9) < 0) {
                errors.push(DPError.createDPError('matter.ereg.pin'));
            }

            if(matter.existingMortgages && matter.existingMortgages.length >0){
                if(!selectedMortgage.mortgageRequestNo){
                    errors.push(DPError.createDPError("matter.ereg.mortgageRegNo"));
                }
                if(selectedMortgage.mortgageCorrespondenceType != 'SOLICITOR'){
                    let matterParticipants = matter.matterParticipants.filter(p => selectedMortgage && p.mortgageId === selectedMortgage.id
                        && p.matterParticipantRole === (selectedMortgage.mortgageeType === 'INSTITUTION' ? 'MORTGAGEE' : 'PRIVATE_LENDER'));
                    if(matterParticipants && matterParticipants.length > 0 && selectedMortgage.mortgageeType == 'INSTITUTION'){
                        let serviceAddress : Address = matterParticipants[0].contact.address.find(a => a.addressTypeCode === AddressTypes.serviceAddress);
                        if(!serviceAddress || (serviceAddress.sameAsAddressTypeCode == AddressTypes.manuallyEntered && serviceAddress.isEmpty)
                            ||(serviceAddress.sameAsAddressTypeCode != AddressTypes.manuallyEntered &&
                                matterParticipants[0].contact.address.find(a => a.addressTypeCode === serviceAddress.sameAsAddressTypeCode).isEmpty)){
                            errors.push(DPError.createDPError("matter.ereg.existingMortgagesAddress"));
                        }
                    }else{
                        let primaryMortgagee : MatterParticipant = matter.primaryPrivateLender(selectedMortgage);
                        if(primaryMortgagee){
                            let primaryMortgageeAddress : Address = primaryMortgagee.contact.address.find(a => a.addressTypeCode === AddressTypes.serviceAddress);
                            if(!primaryMortgageeAddress || (primaryMortgageeAddress.sameAsAddressTypeCode == AddressTypes.manuallyEntered && primaryMortgageeAddress.isEmpty)
                                ||(primaryMortgageeAddress.sameAsAddressTypeCode != AddressTypes.manuallyEntered && primaryMortgagee.contact.address.find(a => a.addressTypeCode === primaryMortgageeAddress.sameAsAddressTypeCode).isEmpty)){
                                errors.push(DPError.createDPError("matter.ereg.existingMortgagesAddress"));
                            }
                        }
                    }
                }else{
                    if(!matter.matterParticipants || !matter.matterParticipants.find(p => p.matterParticipantRole === 'MORTGAGE_SOLICITOR')){
                        errors.push(DPError.createDPError("matter.ereg.existingMortgagesAddress"));
                    }
                }
            }

            if(!matter.matterParticipants || (matter.matterParticipants && matter.matterParticipants.length == 0) ||
                (matter.matterParticipants && matter.matterParticipants.length > 0
                    && matter.matterParticipants.filter(p => selectedMortgage && p.mortgageId === selectedMortgage.id
                        && p.matterParticipantRole === (selectedMortgage.mortgageeType === 'INSTITUTION' ? 'MORTGAGEE' : 'PRIVATE_LENDER')).length == 0)
            ) {
                errors.push(DPError.createDPError('matter.ereg.existingMortgage'));

            }

            if(selectedMortgage && selectedMortgage.mortgageeType == 'INSTITUTION'){
                let institution : MatterParticipant = matter.matterParticipants.find(p => p.mortgageId == selectedMortgage.id);
                let signers = matter.getAllSigners(institution);
                if(institution && (selectedMortgage.includeAuthorizeSignOfficer == 'NO' || selectedMortgage.includeAuthorizeSignOfficer == 'N_y' ||
                    (selectedMortgage.includeAuthorizeSignOfficer == 'YES' && signers && signers.length == 0))){
                    errors.push(DPError.createCustomDPError('matter.ereg.discharge.additionalName',
                        'Signing officer not entered for ' + institution.contact.genericFullNameWithLastUpper, 'Existing Mortgages', "ERROR"));
                }

            }
            if(selectedMortgage && selectedMortgage.mortgageeType == 'PRIVATE_LENDER'){
                let participants : MatterParticipant[] = matter.matterParticipants
                    .filter( p => p.mortgageId === selectedMortgage.id && p.contact && p.contact.gender === 'OTHERENTITY');
                if(participants){
                    participants.forEach( p => {
                        let signers = matter.getAllSigners(p);
                        if(p.contact && signers && signers.length == 0){
                            errors.push(DPError.createCustomDPError('matter.ereg.discharge.additionalName',
                                'Signing officer not entered for ' + p.contact.genericFullNameWithLastUpper, 'Existing Mortgages', "ERROR"));
                        }
                    });
                }

            }
            if(ignoreErrorsOnSave){
                return errors.length > 0 ? true : false;
            }
            this.hideBubbleNotifications(errors);
            errors.forEach(error => errorService.addDpMissingFieldError(error));

            if(errorService.hasAnyMissingFieldErrors()) {
                let existingMortgagePriority : number = mortgageIndex + 1;
                errorService.setMissingFieldHeader('E-Reg \u2122 '+ existingMortgagePriority+ this.ordinalPrefix(existingMortgagePriority) + ' Discharge (7.1) Errors');
            }
            return errorService.hasAnyMissingFieldErrors()
        }

    }


    static validateERegFormModal(eReg : ERegistrationForm, errorService : ErrorService, matter: Matter) : boolean {

        errorService.clearAllSaveErrors();
        if(eReg && eReg.eregistrationData) {
            if(!eReg.eregistrationData.documentName) {
                errorService.addDpSaveError(DPError.createDPError('matter.ereg.documentName'));

            }
            if(!eReg.eregistrationData.instrumentNo && (eReg.eregistrationData.isDocumentTypePowerOfSale() || eReg.eregistrationData.isDocumentTypeNoticeOfChargeOfLease())) {
                //modalErrorComponent.createDPSaveError('matter.ereg.instrument');
                errorService.addDpSaveError(DPError.createDPError('matter.ereg.instrument'));
            }
            if(eReg.isDischarge() && !eReg.eregistrationData.isInstrumentExsMortgage && !eReg.eregistrationData.instrumentNo) {
                //modalErrorComponent.createDPSaveError('matter.ereg.instrument');
                errorService.addDpSaveError(DPError.createDPError('matter.ereg.instrument'));
            }
            if(eReg.isDischarge() && eReg.eregistrationData.overrideMortgageRegistrationNo != undefined && !eReg.eregistrationData.overrideMortgageRegistrationNo && !eReg.eregistrationData.mortgageRegistrationNo) {
                //modalErrorComponent.createDPSaveError('matter.ereg.mortgageRegistrationNo');
                errorService.addDpSaveError(DPError.createDPError('matter.ereg.assignmentInstrumentNo'));
            }
            if(eReg.eregistrationData.partyToAddressForService && eReg.eregistrationData.partyToAddressForService.length > 0) {
                eReg.eregistrationData.partyToAddressForService.forEach(item => {
                    if(!item.addressForService) {
                        if(eReg.isMortgage()){
                            /*modalErrorComponent.createCustomDPSaveError('matter.ereg.participantInfo.address.' + item.participantId, 'Address for service' +
                                ' not completed for ' + this.purchaserOrMortgageeName(item.participantId , matter), '', 'ERROR');*/
                            errorService.addDpSaveError(DPError.createCustomDPError('matter.ereg.participantInfo.address.' + item.participantId, 'Address for service' +
                                ' not completed for ' + this.purchaserOrMortgageeName(item.participantId , matter), '', 'ERROR'));
                        }else{
                            /*modalErrorComponent.createCustomDPSaveError('matter.ereg.participantInfo.address.' + item.participantId, 'Address for service' +
                                ' not completed for ' + this.purchaserNameById(item.participantId , matter), '', 'ERROR');*/
                            errorService.addDpSaveError(DPError.createCustomDPError('matter.ereg.participantInfo.address.' + item.participantId, 'Address for service' +
                                ' not completed for ' + this.purchaserNameById(item.participantId , matter), '', 'ERROR'));
                        }

                    }
                })
            }
        }
        return errorService.hasErrors();
    }

    static validateDischargeERegFormModal(eReg : ERegistrationForm, errorService : ErrorService) : boolean {
        errorService.removeDpSaveError('matter.ereg.documentType');
        if(eReg && eReg.isDischarge() && eReg.eregistrationData) {
            if(eReg.eregistrationData.isDocumentTypeApplicationDeleteHousingDevelopmentLien() || eReg.eregistrationData.isDocumentTypeDischargeOfCondo() ) {
                let documentType :any = dropDowns.eRegDischarge.find(item =>  item.value == eReg.eregistrationData.documentType);
                errorService.addDpSaveError(DPError.createCustomDPError('matter.ereg.documentType', 'The discharge document type of ' +
                    documentType.label +' cannot be submitted to Teraview using Unity.' , '', 'ERROR'));
            }
        }
        return errorService.hasErrors();
    }

    static purchaserOrMortgageeName(participantId : number , matter : Matter) : string{
        let participant : MatterParticipant = matter.matterParticipants
            .find(participant => participant.matterParticipantId === participantId);
        if(participant){
            return participant.contact.genericFullNameWithLastUpper
        }
    }
    static purchaserNameById(matterParticipantId : number, matter : Matter) : string {
        let matterParticipant = matter.matterParticipants
            .find(participant => participant.matterParticipantId === matterParticipantId);
        return matterParticipant && matterParticipant.contact ? matterParticipant.contact.genericFullNameWithLastUpper : '';

    }

    static ordinalPrefix(ordinal : number) : string {
        return (ordinal === 1 ? 'st' : ordinal === 2 ? 'nd' : ordinal === 3 ? 'rd' : 'th');
    }

    static validatePinLengthWithDash(pin) {
        if(pin && pin!=undefined) {
            let pinRemoveDash = pin.replace("-", "");
            return pinRemoveDash.length;
        } else {
            return 0;
        }

    }

    static participantName(matterParticipant : MatterParticipant) : string {
        return matterParticipant && matterParticipant.contact ? matterParticipant.contact.genericFullNameWithLastUpper : '';

    }

    static isMortgageeAddressForServiceEmpty(matterParticipant : MatterParticipant, mortgage: Mortgage, matter : Matter): boolean {
        if(mortgage.isMortgageCorrespondWithSolicitor()){
            let mortgageServiceAddress = mortgage.mortgageContactInfo && mortgage.mortgageContactInfo.serviceAddress;
            if(mortgageServiceAddress.sameAsAddressTypeCode == AddressTypes.solicitorAddress){
                let mortgageSolicitor = matter.matterParticipants.find( mp => mp.matterParticipantRole == MatterParticipantRoleTypes.MORTGAGE_SOLICITOR );
                //mortgageServiceAddress = mortgageSolicitor.contact.address.find(a => a.addressTypeCode === AddressTypes.mailing);
                if(mortgageSolicitor){
                    mortgageServiceAddress = mortgageSolicitor.contact.address.find(a => a.addressTypeCode === AddressTypes.mailing);
                }else {
                    mortgageServiceAddress =null;
                }
            }
            return (!mortgageServiceAddress || mortgageServiceAddress.isEmpty);
        } else {
            let primaryMortgageeAddress : Address = matterParticipant.contact.address.find(a => a.addressTypeCode === AddressTypes.serviceAddress);
            if (primaryMortgageeAddress && primaryMortgageeAddress.sameAsAddressTypeCode === AddressTypes.mailing){
                let mailingAddress : Address = matterParticipant.contact.address.find(a => a.addressTypeCode === AddressTypes.mailing);
                return (!mailingAddress || mailingAddress.isEmpty);
            } else {
                return !primaryMortgageeAddress || primaryMortgageeAddress.isEmpty ;

            }
        }

    }

    static mortgagePurchasers(matter : Matter) : MatterParticipant[] {
        return matter.isMortgage ? matter.mortgagors : matter.purchasers;
    }

    static purchasersCapacity(matter : Matter): string{
        return (matter.matterType == 'SALE') ? matter.otherPartiesCapacity : matter.purchasersCapacity;
    }

    static isERegTransferApplicable(matter : Matter): boolean {
        return (matter.isRegistrationElectronic() && (matter.isPurchase || matter.isSale) && matter.isMatterProvinceON);
    }

    static isERegChargeApplicable(matter : Matter , mortgage : Mortgage) : boolean{
        return  (matter.isRegistrationElectronic()&& (mortgage.isNewMortgage()|| mortgage.isEmpMortgage())  && matter.isMatterProvinceON );
    }

    static isERegDischargeApplicable(matter : Matter) : boolean{
        return  (matter.isRegistrationElectronic()&& (matter.isSale || matter.isMortgage) && matter.existingMortgages.length > 0 && matter.isMatterProvinceON );
    }

    static isSigningOfficersExistOnMatter(matter : Matter, corporation : MatterParticipant) : boolean{
        return matter.matterParticipants.findIndex(matterParticipant => corporation.matterParticipantId == matterParticipant.parentParticipantId &&  !!matterParticipant.signingMatter) > -1;
    }

    static signingOfficersOnMatter(matter : Matter ,participant : MatterParticipant): MatterParticipant[]{
        return matter.matterParticipants.filter(p => p.parentParticipantId == participant.matterParticipantId && !!p.signingMatter);
    }


    static validateAndUpdateERegFormStatus(matter : Matter , errorService : ErrorService, formId?: number, projectTemplateMatter ? : Matter){
        if(this.isERegTransferApplicable(matter)){
            let eRegTransfer = formId
                ? matter.eRegistrationForms.find(eRegTransfer => eRegTransfer.eregistrationType === ERegType.EREG_TRANSFER && eRegTransfer.id === formId)
                : matter.eRegistrationForms.find(eRegTransfer => eRegTransfer.eregistrationType === ERegType.EREG_TRANSFER);
            if (eRegTransfer) {
                eRegTransfer.buildEregistrationData(matter, null, null, false, projectTemplateMatter);
                eRegTransfer.eregistrationJson = JSON.stringify(eRegTransfer.eregistrationData);
                if (eRegTransfer && (eRegTransfer.isStatusIncomplete() || eRegTransfer.isStatusReady())) {
                    if (this.validateERegTransfer(matter, errorService, true , projectTemplateMatter)) {
                        eRegTransfer.eregistrationStatus = ERegStatus.EREG_INCOMPLETE;
                    } else {
                        eRegTransfer.eregistrationStatus = ERegStatus.EREG_READY;
                    }
                }
            }
        }
        if(matter.newOrEmpMortgages.length > 0){
            matter.newOrEmpMortgages.forEach((mortgage,index) => {
                if(this.isERegChargeApplicable(matter , mortgage)){
                    let eRegCharge = formId
                        ? matter.eRegistrationForms.find(eRegTransfer => eRegTransfer.eregistrationType === ERegType.EREG_MORTGAGE && eRegTransfer.mortgageId === mortgage.id && eRegTransfer.id === formId)
                        : matter.eRegistrationForms.find(eRegTransfer => eRegTransfer.eregistrationType === ERegType.EREG_MORTGAGE && eRegTransfer.mortgageId === mortgage.id);
                    if (eRegCharge) {
                        eRegCharge.buildEregistrationData(matter, mortgage.id, index, false, projectTemplateMatter);
                        eRegCharge.eregistrationJson = JSON.stringify(eRegCharge.eregistrationData);
                        if (eRegCharge && (eRegCharge.isStatusIncomplete() || eRegCharge.isStatusReady())) {
                            if (this.validateERegMortgage(eRegCharge.mortgageId, matter, errorService, true)) {
                                eRegCharge.eregistrationStatus = ERegStatus.EREG_INCOMPLETE;
                            } else {
                                eRegCharge.eregistrationStatus = ERegStatus.EREG_READY;
                            }
                        }
                    }
                }
            });
        }
        if(this.isERegDischargeApplicable(matter)){
            matter.existingMortgages.forEach((mortgage, index) => {
                if(mortgage.isMortgageDispositionDischarged() || mortgage.isMortgageDispositionBridgeFinancing()){
                    let eRegDischarge = formId
                        ? matter.eRegistrationForms.find(eRegTransfer => eRegTransfer.eregistrationType === ERegType.EREG_DISCHARGE && eRegTransfer.mortgageId === mortgage.id  && eRegTransfer.id === formId)
                        : matter.eRegistrationForms.find(eRegTransfer => eRegTransfer.eregistrationType === ERegType.EREG_DISCHARGE && eRegTransfer.mortgageId === mortgage.id);
                    if (eRegDischarge) {
                        eRegDischarge.buildEregistrationData(matter, mortgage.id, index, false, projectTemplateMatter);
                        eRegDischarge.eregistrationJson = JSON.stringify(eRegDischarge.eregistrationData);
                        if (eRegDischarge && (eRegDischarge.isStatusIncomplete() || eRegDischarge.isStatusReady())) {
                            if (this.validateERegDischarge(eRegDischarge.mortgageId, null, matter, errorService, true)) {
                                eRegDischarge.eregistrationStatus = ERegStatus.EREG_INCOMPLETE;
                            } else {
                                eRegDischarge.eregistrationStatus = ERegStatus.EREG_READY;
                            }
                        }
                    }
                }
            });

        }
    }



}
