import {Directive} from '@angular/core';

declare var jQuery : any;
@Directive({
    selector: '[ngModel][dp-preserve-cursor-position]',
    host: {
        '(input)': 'onInput($event)',
        '(ngModelChange)': 'onModelChange()'
    }
})
export class PreserveCursorPositionDirective {
    currentInputControl: any;
    currentCursorPosition: number;

    onInput($event): void {
        this.currentInputControl = $event.target;
        this.currentCursorPosition = $event.target.selectionStart;
    }

    onModelChange() {
        setTimeout(() => {
            let oldPosition: number = this.currentCursorPosition;
            if ((this.currentInputControl != null) && (oldPosition != null) && jQuery(this.currentInputControl).is(":focus")) {
                this.currentInputControl.setSelectionRange(oldPosition, oldPosition);
            }
        }, 100);
    }
}
