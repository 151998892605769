import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {Account} from '../../admin/accounts/shared/account';
import {Component, ViewChild} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {AccountService} from '../../admin/accounts/account.service';
import {UnityBillingService} from '../unity-billing-service';
import {UnityBilling} from '../unity-billing';
import {OnAccessibleWarningLevelDefaultValue, WarningLevelDefaultValue, warningLevelOptions} from '../../shared-main/constants';


declare var jQuery : any;

class WarningLevelModalContext extends BSModalContext {
    account : Account;
}

@Component({
               selector     : 'dp-warning-level-modal-content',
               templateUrl  : 'warning-level.modal.component.html',
               providers : [ErrorService],
           })

@AutoUnsubscribe()
export class WarningLevelModal implements   ModalComponent<WarningLevelModalContext> {
    @ViewChild('modalErrorComponent') modalErrorComponent : ModalErrorComponent;

    context : WarningLevelModalContext;
    account: Account;
    warningLevels : Array<number> = [];
    unityBilling : UnityBilling;
    selectedWarningLevel : number;
    OtherWarningLevel: boolean = false;
    updateFlag: boolean = false;


    constructor(public dialog : DialogRef<WarningLevelModalContext>,
                public accountService : AccountService,
                public unityBillingService : UnityBillingService

                ){
        this.context = dialog.context;


    }

    ngOnInit() {
        this.unityBilling = new UnityBilling();
        this.unityBilling.customerAccountId = this.accountId;
        this.account = new Account(this.context.account);
        this.warningLevels = warningLevelOptions;
        //Apply to any account that has Ontario as an accessible province
        if(this.account.isOnAccessible()){
            this.selectedWarningLevel = OnAccessibleWarningLevelDefaultValue;
        }else {
            this.selectedWarningLevel = WarningLevelDefaultValue;
        }

        this.getUnityBilling();


    }


    getUnityBilling() : void {
        this.unityBillingService.getUnityBilling()
            .subscribe((unityBilling : UnityBilling) => {
                    this.unityBilling = unityBilling ;
                    this.selectedWarningLevel = this.unityBilling.warningLevelAmount
                        ?  this.unityBilling.warningLevelAmount
                        : (this.account.isOnAccessible()? OnAccessibleWarningLevelDefaultValue : WarningLevelDefaultValue);
                    if(this.warningLevels.indexOf(this.selectedWarningLevel) == -1)
                    {
                        this.setWarningLevel(true,'#otherInputID', this.unityBilling.warningLevelAmount);
                    }
                    this.updateFlag = true;
                }
                ,
               error => {
                   //console.log(error);

               });
    }

    get accountId(): number{
        return Number(this.account ? this.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId));
    }


    setWarningLevel(flag: boolean, level: any , warningLevelAmount? ) {
        this.OtherWarningLevel = flag;
        if(flag)
        {
            this.selectedWarningLevel = (typeof warningLevelAmount === "undefined" || warningLevelAmount === null)? 0 : warningLevelAmount;
            setTimeout(()=>{
                jQuery(level).focus();
            },100)

        }
        else
        {
            this.selectedWarningLevel = level;
        }


    }

    close() : void {
        this.dialog.close();
    }



    save(){
        this.modalErrorComponent.removeAllDpFieldError();
        if(this.OtherWarningLevel && !this.selectedWarningLevel)
        {
            this.modalErrorComponent.createDPFieldError("billing.warningLevel.otherAmount");

        }
        else
        {
            this.unityBilling.warningLevelAmount = Number(this.selectedWarningLevel);
            if(this.updateFlag)
            {
                this.unityBillingService.updateUnityBilling(this.unityBilling.id, this.unityBilling)
                    .subscribe((response: any) => {
                                   this.dialog.close('ok');
                               },
                               error => {
                                   this.modalErrorComponent.createCustomDPFieldError(error.errorCode, error.message, null , "ERROR");
                               });
            }
            else
            {
                this.unityBillingService.createUnityBilling(this.unityBilling)
                    .subscribe((response: any) => {
                          this.dialog.close('ok');
                      },
                      error => {
                          this.modalErrorComponent.createCustomDPFieldError(error.errorCode, error.message, null , "ERROR");
                      });
            }

        }

    }

    ngOnDestroy() {}

}
