import {Subscription} from 'rxjs/Rx';

export class MatterPollingData {
    pollingType : string;
    pollingIntervalSubscription : Subscription;
    pollingPeriod: number = 5000;
    pollingData : any;

    isDocuSignPolling() : boolean{
        return this.pollingType == 'DocuSignStatusPolling'
    }

}
