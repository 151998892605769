import {AfterViewInit, Component} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';

declare var jQuery:any;
class DpModalContext extends BSModalContext {
    public errors : string[];
}

@Component({
               selector    : 'dp-modal-content',
               templateUrl : './dp.modal.component.html',
               styleUrls      : [
                   './dp.modal.component.scss'
               ],
           })
export class DpModal implements ModalComponent<DpModalContext> , AfterViewInit {

    context : DpModalContext;


    constructor(public dialog : DialogRef<DpModalContext>) {
        this.context = dialog.context;
    }

    ngOnInit() {

    }
    // After the view is initialized
    ngAfterViewInit() : void {

        setTimeout(() => {
            jQuery('.modal-dialog').addClass('relative-position')
        });
    }

    close() : void {
        this.dialog.close();
    }

}


