import {
    BulkMatterUpdate,
    CommissionPaidToType,
    matterApi,
    MatterListState,
    matterResponseKey,
    MatterType,
    MatterTypesValue
} from './shared';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../core';
import {Injectable} from '@angular/core';
import {Utils} from './shared/utils';
import {DocketService} from '../admin/docket/docket.service';
import * as _ from 'lodash';
import {ConsiderationTaxes} from './consideration-ltt/consideration-taxes';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {SESSION_ID_REQ_PARAM} from '../common/api';
import {MatterParticipantWrapper} from './shared/matter-participant-wrapper';
import {WillMatter} from "./shared/will-matter";
import {Contact} from './shared/contact';
import {ContactQueryService} from '../contact/contact-query.service';
import {TabsService} from '../core/tabs.service';
import {DialogService} from '../shared/dialog/dialog.service';
import {ContactService} from '../shared-main/contact.service';
import {MatterListFilter, ServerMatterListFilter} from './matter-list/matter-list-filter';
import {
    ActingForValues,
    Combined_Hydro_Water_Department,
    DefaultMatterListFilterName,
    Hydro_Department,
    MatterActionRequiredFilters,
    MatterClosingStatus,
    MatterOverviewStatusTypesValue,
    MAX_NUM_OF_UNIT,
    MortgageAction,
    PROJECT_SALE_MATTER,
    PROJECT_SALE_MATTER_OPTION_VALUE,
    RevertToGlobalMessage,
    Water_Department,
    WaterHydroDeptType_IS_COMBINED,
    WaterHydroDeptType_IS_SEPARATE
} from '../shared-main/constants';
import {MatterTab} from './matter-tab';
import {Subscription} from 'rxjs/index';
import {MatterTitleInsurance} from './shared/matter-title-insurance';
import {SoaTrustLedgerCollection} from './shared/soa-trustledger-collection';
import {ErrorService} from '../shared/error-handling/error-service';
import {LockScreenService} from '../core/lock-screen.service';
import {DocumentProfile} from '../admin/document-profile/document-profile';
import {DocumentProfileService} from '../admin/document-profile/document-profile-edit/document-profile.service';
import {AccountService} from '../admin/accounts/account.service';
import {CancelledMortgageInstruction} from './shared/cancelled-mortgage-instruction';
import {constValues, Jurisdiction, Matter} from '../matters/shared';
import {DpBooleanValueTypes} from '../matters/shared/dp-boolean';
import {DirectDepositInstruction} from './shared/direct-deposit-instruction';
import {TitleInsuranceConfiguration} from '../integrations/title-insurance-configuration';
import {SupplementalTaskCategory} from '../shared-main/supplemental-task-category/supplemental-task-category';
import {MatterSupplementalTaskCategory, showPaymentAmountsInitValue} from './shared/matter-supplemental-task-category';
import {MatterTopic} from './shared/matter-topic';
import {PurchaserReport} from './purchaser-report/purchaser-report';
import {StaffProfiles} from '../admin/staff-profiles/staff-profiles';
import {StaffProfilesService} from '../admin/staff-profiles/staff-profiles.service';
import {DocumentProfileCache} from '../shared-main/document-profile-cache.service';
import {TitleInsuranceConfigurationService} from '../integrations/title-insurance-configuration.service';
import {SupplementalTaskService} from '../shared-main/supplemental-task-category/supplemental-task-service';
import {Section} from './shared/section';
import {TaxRateService} from './consideration-ltt/tax-rate.service';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {TeraviewConfig} from '../admin/docket/teraview-config';
import {AppConfig} from '../shared-main/app-configuration';
import {StatementAdjustmentDisplayBuilder} from '../matters/statement-adjustment/builders/statement-adjustment-display-builder';
import {SalePriceAdjustmentFactory} from '../matters/statement-adjustment/sale-price-adjustment/sale-price-adjustment-factory';
import {StatementOfAdjustmentDisplay} from '../matters/shared/statement-of-adjustment-display';
import {TeranetDocket} from '../shared-main/teranet/teranet-docket';
import {StatementConfigService} from '../admin/shared/statement-config.service';
import {JurisdictionDepartment} from '../admin/jurisdiction-departments/jurisdiction-departments';
import {JurisdictionDepartmentsService} from '../admin/jurisdiction-departments';
import {StatementConfig} from '../admin/shared/statement-config';
import {SoaFeeRate} from '../matters/consideration-ltt/soa-fee-rate';
import {SoaTrustLedgerHelperService} from '../shared/soa-trustledger-helper.service';
import {SoaExportConfig} from '../matters/shared/soa-export-config';
import {AccountProvince, ProvinceCode} from '../admin/accounts/shared/account-province';
import {UserDefinedField} from '../shared-main/user-defined-field/user-defined-field';
import {UserDefinedFieldService} from '../shared-main/user-defined-field/user-defined-field-service';
import {DPError} from '../shared/error-handling/dp-error';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {Mortgage} from '../matters/shared/mortgage';
import {EFormType, EFormTypes} from '../shared-main/alto/alto-eform-request';
import {UnityBillingService} from '../billing/unity-billing-service';
import {ApplicationError, FieldError} from '../core/application-error';
import {StatusBarMessages} from '../shared-main/status-bar-messages';
import {ChicagoTitleXpathConfigs} from '../shared-main/chicago-title/chicago-title-constants';
import {FctXpathConfigs} from '../shared-main/fct/fct-constants';
import {DpDirtyCheckService} from '../shared-main/dp-dirty-check.service';
import {
    AltoTOLEformsPropertyAddressXpathConfigs,
    AltoTOLEformsServiceAddressXpathConfigs,
    AltoTOLEformsXpathConfigs,
    AltoUniversalEformsXpathConfigs
} from '../shared-main/alto/alto-eforms-constants';
import {AltoEFormStaleFlag} from '../shared-main/alto/alto-eform-stale-flag';
import {ChicagoLawFirm} from '../shared-main/chicago-title/chicago-law-firm';
import {Account} from '../admin/accounts/shared/account';
import {StatusBarService} from '../shared-main/status-bar.service';
import {UserProvince} from '../matters/shared/user-province';
import {Project} from '../projects/shared/project';
import {ProjectService} from '../projects/project.service';
import {CondominiumPlan} from './property-teranet/unit-level-plan/condominium-plan';
import {ProjectMatterCacheService} from '../core/project-matter-cache.service';
import {AdjustmentPropagationService} from '../matters/adjustment-propagation.service';
import moment from 'moment';
import * as jp from 'jsonpath';
import {EregistrationUtil} from './forms/eregistration/eregistration-util';
import {Subject} from 'rxjs/Subject';
import {SoaRealtyTaxAdjustmentUtil} from './statement-adjustment/modals/realty-tax/soa-realty-tax-adjustment-util';
import {SelectUnitLevelPlanResult} from './shared/select-unit-level-plan-result';
import {ActionTypes, GlobalLogger, LogLevelTypes} from '../core/global-logger';
import {ProgressionStatus} from './statement-adjustment/statement-adjustment';
import {MortgageHoldbackConfig} from './shared/advance-holdback/matter-holdback';
import {RentInterestRate} from './statement-adjustment/model/rent-interest-rate';
import {MatterHelperService} from '../shared-main/matter-helper.service';
import {CirfDocument} from './shared/cirf/cirf-document';
import {MortgageSoAdjService} from '../shared-main/mortgage-so-adj.service';
import {AuthorizationService} from '../shared-main/authorization/authorization-service';
import {MatterTypeInfo} from '../admin/shared/matter-type-info';
import {ReferralDocument} from './shared/referral/referral-document';
import {StatementOfAdjustmentPayable} from './statement-adjustment/model/statement-adjustment-payable';
import {UndertakingsConfigService} from '../admin/shared/undertaking-config.service';
import SharedUtils from '../shared-main/utils';
import {SharedDocumentsPackage} from './document-production/shared-documents-package';
import {UndertakingsConfig} from '../admin/mortgage-discharge/undertakings-config';
import {
    MatterUndertaking,
    MatterUndertakingNBDefaultConfig,
    MatterUndertakingStatus,
    MatterUndertakingStatusValues
} from './shared/matter-undertaking';
import {SoaExpenseAdjustmentUtil} from './statement-adjustment/modals/reserve-fund/soa-expense-adjustment-util';
import {MatterExtraDepositConfig} from './shared/matter-extra-deposit-config';
import {MatterParticipant} from './shared/matter-participant';
import {matterParticipantRoleLabels} from './shared/matter-participant-role-types';
import {MatterCleanUpUtil} from './shared/matter-utils/matter-clean-up-util';
import {MatterErrorUtil} from './shared/matter-utils/matter-error-util';
import {MatterWorkItemsUtilsService} from './matter-overview/matter-work-items-utils.service';
import {ChecklistTemplateConfig} from '../admin/checklist-templates/checklist-template-config';
import {JurisdictionService} from './property-teranet/jurisdiction.service';
import {Compliance} from './compliance/compliance';
import {Department} from './shared/department';
import {UUIDService} from '../main/uuid.service';
import {UUIDUtil} from '../main/uuid-util';
import {MatterRestrictedPeriodService} from '../core/matter-restricted-period.service';
import {UserStateService} from '../shared-main/user-state/user-state.service';
import {LegalDescriptionUtil} from './title-insurance/title-insurance-legal-description';
import {MatterNotificationConfigService} from '../admin/manage-messaging-notifications/matter-notification-config/matter-notification-config.service';
import {MatterNotificationConfig} from '../admin/manage-messaging-notifications/matter-notification-config/matter-notification-config';
import {MatterNotificationUtilService} from './matter-notification-service';
import {opportunityMatterApi} from '../opportunity-matter/opportunity-matter-api';
import {MatterParticipantService} from './matter-participant-service';
import {Referral} from './shared/referral/referral';
import {OpportunitiesService} from '../opportunities/opportunities.service';
import {MatterBillingResult} from './shared/matter-billing';
import {RemoteSigningConfigurationService} from '../admin/remote-signing-configuration/remote-signing-configuration.service';
import {RemoteSigningConfiguration} from '../admin/remote-signing-configuration/remote-signing-configuration';
import {ApplicablePayoutInstitutions, PayoutStatement} from '../shared-main/telus/payout-statement';
import {PayoutStatementStatus} from '../shared-main/telus/payout-statement-status';
import {TelusService} from '../shared-main/telus/telus-service';
import {AssystPayoutXpathConfigs, defaultMessageForCountNotMatch} from '../shared-main/telus/telus-constants';
import {AssystPayoutMessagesModalComponent} from './mortgages/mortgage/assyst-payout-messages/assyst-payout-messages.modal.component';
import {MortgageInstrument} from './shared/mortgage-instrument';
import {PinLegalDescription} from './title-insurance/pin-legal-description';
import {AssociatedMatterListState} from './shared/associated-matter-list-state';
import {LandTransferTaxRate} from './shared/land-transfer-tax-rate';
import {DateCalculationConfig} from '../admin/accounts/shared/date-calculation-config';

// This is a sharable service which is used for all the matter API.
@Injectable()
export class MatterService {

    uploadCompressionEnabled: boolean = true;
    bulkUpdateMode: string = '';
    fieldsUpdatedAfterAssystPayoutSubmission : string[]=[];

    constructor(private http: HttpClient,
                private dialogService: DialogService,
                private contactService: ContactService,
                private contactQueryService: ContactQueryService,
                private lockScreenService: LockScreenService,
                private documentProfileService: DocumentProfileService,
                private tabsService: TabsService,
                public errorService: ErrorService,
                private docketService: DocketService,
                private accountService: AccountService,
                private staffProfilesService: StaffProfilesService,
                private projectService: ProjectService,
                private documentProfileCache: DocumentProfileCache,
                private titleInsuranceConfigurationService: TitleInsuranceConfigurationService,
                private supplementalTaskService: SupplementalTaskService,
                private jurisdictionService: JurisdictionService,
                private taxRateService: TaxRateService, private appConfig: AppConfig, private soaConfigService: StatementConfigService,
                private jurisdictionDepartmentsService: JurisdictionDepartmentsService, private soaTrustLedgerHelperService: SoaTrustLedgerHelperService,
                private userDefinedFieldService: UserDefinedFieldService, private currencyPipe: CurrencyPipe,
                private decimalPipe: DecimalPipe,
                private percentPipe: PercentPipe,
                private unityBillingService: UnityBillingService,
                private globalLogger: GlobalLogger,
                public dpDirtyCheckService: DpDirtyCheckService,
                public statusBarService: StatusBarService,
                private projectMatterCacheService: ProjectMatterCacheService,
                public matterHelperService: MatterHelperService,
                public mortgageSoAdjService: MortgageSoAdjService,
                private authorizationService: AuthorizationService,
                public matterWorkItemsUtilsService: MatterWorkItemsUtilsService,
                public uuidService: UUIDService,
                public matterRestrictedPeriodService: MatterRestrictedPeriodService,
                public userStateService: UserStateService,
                private matterNotificationConfigService: MatterNotificationConfigService,
                public undertakingsConfigService: UndertakingsConfigService,
                public matterParticipantService: MatterParticipantService,
                public matterNotificationUtilService: MatterNotificationUtilService,
                public opportunitiesService: OpportunitiesService,
                public remoteSigningDefaultService: RemoteSigningConfigurationService, public telusService: TelusService) {

    }


    getMattersForEmp(query: string, filterSolcitor?: number, filterMatterTypes?: string, provinceAllowed?: string[]): Observable<Matter[]> {
        if (!Array.isArray(provinceAllowed) || provinceAllowed.length == 0) {
            return Observable.of(null);
        }
        let urlQuery = query;
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        urlQuery = Utils.escapeSearchText(urlQuery);
        let filteredMatterTypes = filterMatterTypes ? filterMatterTypes : 'PURCHASE!MORTGAGE';
        let url: string = `${matterApi.mattersForAccount(accountId)}?sort=lastUpdatedTimeStamp|DESC&filter=matterStatus_IN_ACTIVE!` + Utils.escapeSearchText('DEFAULT_ACTIVE') + `,matterType_IN_` + filteredMatterTypes + `,closed_IN_` + Utils.escapeSearchText('N_y') + `!NO`;
        {
            url += ',provinceCode_IN_';
            provinceAllowed.forEach(function (value, index) {
                if (index == 0) {
                    url += value;
                } else {
                    url += "!" + value;
                }
            });
        }

        if (filterSolcitor) {
            url += ',ANYmatterSearchView.hasLawyer_EQ_false|matterSearchView.lawyerSourceContactId_IN_' + filterSolcitor;
        }

        url += ',ANYmatterRecordNumber_EQ_' + urlQuery + '*|matterSearchView.clientReline_EQ_*' + urlQuery + '*|matterSearchView.propertyAddress_EQ_mailing:*' + urlQuery + '*&filterType=ALL&page=1&per_page=15';

        return this.http.get(url).map((res) => {

            return res[matterResponseKey.matters].map((item) => {
                return new Matter(item);
            });
        });
    }


    //In Progress
    getMattersToLinkSharedDocuments(query: string, provinceAllowed?: string[], isCalledFromConvertToMatter?:boolean): Observable<Matter[]> {
        if (!Array.isArray(provinceAllowed) || provinceAllowed.length == 0) {
            return Observable.of(null);
        }
        let urlQuery = query;
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        urlQuery = Utils.escapeSearchText(urlQuery);
        let matterTypeFilter :string ='';
        if (isCalledFromConvertToMatter) {
            if(this.authorizationService.hasFullAccessToConveyancingMatters() && !this.authorizationService.hasFullAccessToProjectMatters()){
                matterTypeFilter += ',matterSearchView.projectMatter_EQ_false';

            }else if(this.authorizationService.hasFullAccessToProjectMatters() && !this.authorizationService.hasFullAccessToConveyancingMatters()){
                matterTypeFilter += ',matterSearchView.projectMatter_EQ_true';
            }
        }else{
            matterTypeFilter = ',matterType_IN_PURCHASE!MORTGAGE!SALE!CUSTOM'
        }
        let url: string = `${matterApi.mattersForAccount(accountId)}?sort=lastUpdatedTimeStamp|DESC&filter=matterStatus_IN_ACTIVE!` + Utils.escapeSearchText('DEFAULT_ACTIVE') +  matterTypeFilter + `,closed_IN_` + Utils.escapeSearchText('N_y') + `!NO`;
        {
            url += ',provinceCode_IN_';
            provinceAllowed.forEach(function (value, index) {
                if (index == 0) {
                    url += value;
                } else {
                    url += "!" + value;
                }
            });
        }


        url += ',ANYmatterRecordNumber_EQ_' + urlQuery + '*|matterSearchView.clientReline_EQ_*' + urlQuery + '*|matterSearchView.propertyAddress_EQ_mailing:*' + urlQuery + '*&filterType=ALL&page=1&per_page=15';

        return this.http.get(url).map((res) => {

            return res[matterResponseKey.matters].map((item) => {
                return new Matter(item);
            });
        });
    }


    getMattersForUpdatingFromReferral(query: string, provinceAllowed?: string[], isCalledFromConvertToMatter?:boolean): Observable<Matter[]> {
        return this.getMattersToLinkSharedDocuments(query, provinceAllowed, isCalledFromConvertToMatter);
    }

    getMattersForAccount(query: string, provinceAllowed?: string[], customerAccountId ?: number ): Observable<Matter[]> {
        if (!Array.isArray(provinceAllowed) || provinceAllowed.length == 0) {
            return Observable.of(null);
        }
        let urlQuery = query;
        let accountId = customerAccountId ? customerAccountId :sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        urlQuery = Utils.escapeSearchText(urlQuery);
        let filteredMatterTypes = 'PURCHASE!MORTGAGE!SALE!CUSTOM!WILL';
        let url: string = `${matterApi.mattersForAccount(accountId.toString())}?accessAccountFromURL=true&sort=lastUpdatedTimeStamp|DESC&filter=matterStatus_IN_ACTIVE!` + Utils.escapeSearchText('DEFAULT_ACTIVE') + `,matterType_IN_` + filteredMatterTypes + `,closed_IN_` + Utils.escapeSearchText('N_y') + `!NO`;
        {
            url += ',provinceCode_IN_';
            provinceAllowed.forEach(function (value, index) {
                if (index == 0) {
                    url += value;
                } else {
                    url += "!" + value;
                }
            });
        }


        url += ',ANYmatterRecordNumber_EQ_' + urlQuery + '*|matterSearchView.clientReline_EQ_*' + urlQuery + '*|matterSearchView.propertyAddress_EQ_mailing:*' + urlQuery + '*&filterType=ALL&page=1&per_page=15';

        return this.http.get(url).map((res) => {

            return res[matterResponseKey.matters].map((item) => {
                return new Matter(item);
            });
        });
    }

    async getMatterIds(matterListState: MatterListState) {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let searchQuery = this.createMatterSearchRequest(matterListState);
        if (matterListState.excludeDraft) {
            searchQuery += `&excludeDraft=${matterListState.excludeDraft}`;
        }
        if(matterListState.taskStatusFilter){
            searchQuery += `&taskCriteria=${matterListState.taskStatusFilter}`;
            if(matterListState.taskDescription){
                searchQuery += `|${matterListState.taskDescription}`;
            }
        }
        let url = `${matterApi.mattersIdsForAccount(accountId)}?${searchQuery}`;

        return this.http.get(url).map((res) => {
            return res[matterResponseKey.mattersIds]
        }).toPromise();
    }

    // this method is for getting  all the matters from database
    getMatters(searchView: string, matterListState: MatterListState, perPage?: number, excludeDraft?: boolean): Observable<Matter[]> {


        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string;
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        if (searchView == DefaultMatterListFilterName.MY_RECENT) {
            url = `${matterApi.recentlyAccessedMatters(userId)}`;
            return this.http.get(url).map((res) => {
                return res[matterResponseKey.matters].map((item) => {
                    return new Matter(item);
                });
            });
        }

        let searchQuery = this.createMatterSearchRequest(matterListState);
        url = `${matterApi.mattersForAccount(accountId)}?` + searchQuery;
        if (matterListState.pageNo) {
            url += `&page=${matterListState.pageNo}&per_page=${perPage}`;
        }

        if (excludeDraft) {
            url += `&excludeDraft=${excludeDraft}`;
        }

        if(matterListState.taskStatusFilter){
            url += `&taskCriteria=${matterListState.taskStatusFilter}`;
            if(matterListState.taskDescription){
                url += `|${matterListState.taskDescription}`;
            }
        }

        return this.http.get(url).map((res) => {

            return res[matterResponseKey.matters].map((item) => {
                return new Matter(item);
            });
        });
    }
    getAssociatedSearchFilter(searchText : string) : string{
        let result: string ='';
        if (searchText != null && searchText != '' && searchText) {
            result =',ANYmatterRecordNumber_EQ_' + searchText + '*|matterSearchView.clientReline_EQ_*' + searchText + '*|matterSearchView.propertyAddress_EQ_mailing:*' + searchText + '*|matterSearchView.propertyAddress_EQT_mailing:*' + searchText + '*';
        }
        return result;
    }

    getAssociatedMattersAndOpportunitiesCountForAccount (contactId: number) : Observable<number>{
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = `${matterApi.associatedMattersAndOpportunitiesCountForAccount(accountId)}?contactId=${contactId}`;
        return this.http.get(url).map((res) => {
            return res[matterResponseKey.AssociatedMatterAndOpportunityCount];
        });
    }

    getAssociatedMattersForContact(contactId: number, matterListState?: AssociatedMatterListState, perPage?: number): Observable<Matter[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let  pageNo: number;
        let searchQuery: string;
        if (matterListState){
            pageNo = matterListState.pageNo? matterListState.pageNo: 1;
            searchQuery = matterListState.searchText;
            searchQuery = Utils.escapeSearchText(searchQuery);
        } else {
            pageNo =1;
        }
        let url = `${matterApi.mattersForAccount(accountId)}?sort=lastUpdatedTimeStamp|DESC&filter=matterStatus_IN_ACTIVE!` + Utils.escapeSearchText('DEFAULT_ACTIVE') + '!INACTIVE' +
            this.getAssociatedSearchFilter(searchQuery) +
            `,matterParticipants.contact.sourceContact.id_EQ_${contactId}`;
        url += `&includeAllParticipants=true`;
        if (pageNo) {
            if (!perPage){
                perPage = 50;
            }
            url += `&page=${pageNo}&per_page=${perPage}`;
        }
        return this.http.get(url).map((res) => {
            let matters : Matter[] = [];
            matters = res[matterResponseKey.matters].map((item) => {
                return new Matter(item);
            });
            let metadata = res[matterResponseKey.metadata];
            return matters;
        });
    }

    getAssociatedOpportunitiesForContact(contactId: number, matterListState?: AssociatedMatterListState, perPage?: number): Observable<Matter[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let  pageNo: number;
        if (matterListState){
            pageNo = matterListState.pageNo? matterListState.pageNo: 1;
        } else {
            pageNo =1;
        }
        let url = `${opportunityMatterApi.getOpportunities(accountId)}?sort=lastUpdatedTimeStamp|DESC&filter=matterStatus_IN_ACTIVE!` + Utils.escapeSearchText('DEFAULT_ACTIVE') + '!INACTIVE' +
            `,matterParticipants.contact.sourceContact.id_EQ_${contactId}`;
        url += `&includeAllParticipants=true`;
        if (pageNo) {
            if (!perPage){
                perPage = 50;
            }
            url += `&page=${pageNo}&per_page=${perPage}`;
        }
        return this.http.get(url).map((res) => {
            let matters : Matter[] = [];
            matters = res[matterResponseKey.matters].map((item) => {
                return new Matter(item);
            });
            return matters;
        });
    }

    getMatterByRecordNumber(matterRecordNumber: string, matterType?: string): Observable<Matter[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string;
        let searchQuery = 'matterRecordNumber_EQ_' + Utils.escapeSearchText(matterRecordNumber);
        if (matterType) {
            searchQuery += ',ANYmatterSearchView.derivedMatterType_IN_' + matterType;
        }
        url = `${matterApi.mattersForAccount(accountId)}?filter=` + searchQuery;
        return this.http.get(url).map((res) => {
            return res[matterResponseKey.matters].map((item) => {
                return new Matter(item);
            });
        });
    }

    //This method is for creating matter search request with all filters. it is used by both matter search and export
    createMatterSearchRequest(matterListState: MatterListState): string {
        let url: string;
        let urlQuery = matterListState.searchText;
        urlQuery = Utils.escapeSearchText(urlQuery);
        let sort: string = matterListState.sortQuery ? matterListState.sortQuery : 'lastUpdatedTimeStamp';
        let sortingType: string = matterListState.sortType ? matterListState.sortType : 'DESC';
        let filter: string = '';
        let matterStatusFilters: string = '';
        let matterClosingStatusFilters: string = '';
        let matterTypeFilters: string = '';
        let solicitorFilters: string = '';
        let lawClerkFilters: string = '';
        let filterFlag: boolean = false;

        if (matterListState) {

            if (Array.isArray(matterListState.selectedMatterStatuses)) {
                // For ACTIVE and INACTIVE MatterStatuses
                const activeAndInactiveMatterStatuses: string[] = matterListState.selectedMatterStatuses.filter(item => item === 'ACTIVE' || item === 'INACTIVE');
                filterFlag = true;
                if (activeAndInactiveMatterStatuses.length > 0) {
                    for (let i = 0; i < activeAndInactiveMatterStatuses.length; i++) {
                        if (i == 0) {
                            filter = Utils.addCommaToFilter(filter);
                            matterStatusFilters = 'ANYmatterStatus_IN_';
                        }
                        if (i == (activeAndInactiveMatterStatuses.length - 1)) {
                            matterStatusFilters += activeAndInactiveMatterStatuses[i] === 'ACTIVE' ? "ACTIVE!" + Utils.escapeSearchText('DEFAULT_ACTIVE') : activeAndInactiveMatterStatuses[i];
                        } else {
                            matterStatusFilters += (activeAndInactiveMatterStatuses[i] === 'ACTIVE' ? "ACTIVE!" + Utils.escapeSearchText('DEFAULT_ACTIVE') : activeAndInactiveMatterStatuses[i]) + '!';
                        }

                        filterFlag = true;
                    }
                }
                filter += matterStatusFilters;
                const matterClosingStatuses: string[] = matterListState.selectedMatterStatuses.filter(item => item === MatterClosingStatus.PENDING || item === MatterClosingStatus.ESCROW ||
                                                                                                      item === MatterClosingStatus.IN_FUNDS || item === MatterClosingStatus.POST_CLOSING || item === MatterClosingStatus.OCCUPANCY);
                if (matterClosingStatuses.length > 0){
                    filter = Utils.addCommaToFilter(filter);
                    filter += matterClosingStatusFilters = 'ANYmatterClosingStatus_IN_' + Utils.escapeSearchText(matterClosingStatuses.join('!'));
                }
                // For MatterOverviewStatusTypesValue.FLAGGED
                if (matterListState.selectedMatterStatuses.find(item => item === MatterOverviewStatusTypesValue.FLAGGED)) {
                    filterFlag = true;
                    filter = filter ? (filter + '|matterFlagged_EQ_true') : 'ANYmatterFlagged_EQ_true';
                }

                // For MatterOverviewStatusTypesValue.MATTER_READY
                if (matterListState.selectedMatterStatuses.find(item => item === MatterOverviewStatusTypesValue.MATTER_READY)) {
                    filterFlag = true;
                    filter = filter ? (filter + '|matterReady_EQ_true') : 'ANYmatterReady_EQ_true';
                }

                // For MatterOverviewStatusTypesValue.MATTER_PAST_CLOSING_DATE
                if (matterListState.selectedMatterStatuses.find(item => item === MatterOverviewStatusTypesValue.MATTER_PAST_CLOSING_DATE)) {
                    filterFlag = true;
                    filter = filter ? (filter + '|matterSearchView.overallMatterStatus_IN_PASTCLOSING') : 'matterSearchView.overallMatterStatus_IN_PASTCLOSING';
                }

            } else {
                filter += "matterStatus_IN_ACTIVE!" + Utils.escapeSearchText('DEFAULT_ACTIVE');
            }

            if (Array.isArray(matterListState.selectedMatterTypes)) {
                let selectedConveyancingMatterTypes = matterListState.selectedMatterTypes.slice(0).filter(item => item != PROJECT_SALE_MATTER_OPTION_VALUE);
                const isConveyancingMatterTypeSelected: boolean = Array.isArray(selectedConveyancingMatterTypes) && selectedConveyancingMatterTypes.length > 0;
                //the following indicate whether user pick one or more specific project instead of ALL project OR no project
                const isSpecificProjectsSelected: boolean = Array.isArray(matterListState.selectedProjects)
                                                                    && matterListState.selectedProjects.length > 0 && matterListState.selectedProjects.indexOf("ALL") == -1;

                if (matterListState.selectedMatterTypes.indexOf("ALL") > -1) {
                    // CHECK IF PROJECT SALE MATTER TYPE IS INCLUDED
                    if (matterListState.isProjectSaleMatterTypeFilterSelected) {
                        if (isSpecificProjectsSelected) {
                            //ignore matterTypes, only show the matters related to selected Projects
                            filter = Utils.addCommaToFilter(filter);
                            filter += 'unityProject.id_IN_' + matterListState.selectedProjects.join('!');
                        } else {
                            //No Extra Filter Needed when All MatterTypes selected and No specific Project selected
                        }
                    } else {
                        //All MatterTypes but not includes Ps, means user has no access to PS matters
                        filter = Utils.addCommaToFilter(filter);
                        filter += 'matterSearchView.projectMatter_EQ_false';
                    }
                } else if (matterListState.selectedMatterTypes && matterListState.selectedMatterTypes.length > 0) {
                    //user choose matter types other than 'ALL'

                    if (isConveyancingMatterTypeSelected && !isSpecificProjectsSelected) {
                        filter = Utils.addCommaToFilter(filter);
                        filter += 'ANYmatterSearchView.derivedMatterType_IN_' + selectedConveyancingMatterTypes.join('!');
                    }
                    // CHECK IF PROJECT SALE MATTER TYPE IS SELECTED
                    if (matterListState.isProjectSaleMatterTypeFilterSelected) {
                        //PS Matter Type selected, based on the condition of the Projects DropDown, adjust the search filter
                        if (Array.isArray(matterListState.selectedProjects)) {

                            if (matterListState.selectedProjects.indexOf("ALL") > -1 || matterListState.selectedProjects.length == 0) {
                                //ALL Projects selected OR No Project selected (they are treated as same)
                                filter = isConveyancingMatterTypeSelected ? (filter + '|') : Utils.addCommaToFilter(filter);
                                filter += 'matterSearchView.projectMatter_EQ_true';

                            } else if (matterListState.selectedProjects.length > 0) {
                                //if specific Projects selected, then ignore the selected matterTypes, only show the matters related to the selected Projects
                                filter = Utils.addCommaToFilter(filter);
                                filter += 'unityProject.id_IN_' + matterListState.selectedProjects.join('!');
                            }
                        }
                    }
                } else {
                    //when there is no matterType option checked, goes here ... No Extra Filter needed
                    //Svr Side will make sure matters with Types that user allowed to access are returned
                }
            }

            if (Array.isArray(matterListState.selectedProvinceCode)) {
                if (matterListState.selectedProvinceCode.indexOf("ALL") > -1) {
                    // No Filter
                } else {
                    for (let i = 0; i < matterListState.selectedProvinceCode.length; i++) {
                        if (i == 0) {
                            filter = Utils.addCommaToFilter(filter);
                            matterTypeFilters = 'provinceCode_IN_';
                        }
                        if (i == (matterListState.selectedProvinceCode.length - 1)) {
                            matterTypeFilters += matterListState.selectedProvinceCode[i];
                        } else {
                            matterTypeFilters += matterListState.selectedProvinceCode[i] + '!';
                        }

                        filterFlag = true;
                    }
                    filter += matterTypeFilters;
                }
            }

            if (Array.isArray(matterListState.selectedLawyers)) {
                let count: number = filter.length;
                filter = this.createMatterSearchRequestForLawyers(matterListState, filter);
                if (filter.length > count) {
                    filterFlag = true;
                }
            }

            if (Array.isArray(matterListState.selectedClerks)) {
                let count: number = filter.length;
                filter = this.createMatterSearchRequestForClerks(matterListState, filter);
                if (filter.length > count) {
                    filterFlag = true;
                }
            }

            // StartDate Filter
            if (matterListState.startDate) {
                filter = Utils.addCommaToFilter(filter);
                filterFlag = true;
                filter += `matterSearchView.closingDate_GTEQ_${moment(matterListState.startDate).format("YYYY-MM-DD")}`;
            }

            // endDate Filter
            if (matterListState.endDate) {
                filter = Utils.addCommaToFilter(filter);
                filterFlag = true;
                filter += `matterSearchView.closingDate_LTEQ_${moment(matterListState.endDate).format("YYYY-MM-DD")}`;
            }

            //Closing in Next days
            if (matterListState.closingDayFilter || matterListState.closingDayFilter === 0) {
                filter = Utils.addCommaToFilter(filter);
                filterFlag = true;

                filter += `matterSearchView.closingDate_GTEQ_${moment(new Date()).format('YYYY-MM-DD')}`;
                filter = Utils.addCommaToFilter(filter);
                filter += `matterSearchView.closingDate_LTEQ_${moment(new Date()).add(matterListState.closingDayFilter, 'days').format('YYYY-MM-DD')}`;
            }

            if (Array.isArray(matterListState.selectedActionRequiredFilters)) {
                let actionFilters: string[] = [];
                matterListState.selectedActionRequiredFilters.forEach(value => {

                    switch (value) {
                        case MatterActionRequiredFilters.OUTSTANDING_UNDERTAKINGS:
                            actionFilters.push("matterSearch.outstandingUndertakingsCount_GT_0");
                            break;
                        case MatterActionRequiredFilters.UNRESOLVED_REQUISITIONS:
                            actionFilters.push("matterSearch.unresolvedRequisitionsCount_GT_0");
                            break;
                        case MatterActionRequiredFilters.INCOMPLETE_MATTER_TOPICS:
                            actionFilters.push("matterSearch.incompleteMatterTopicsCount_GT_0");
                            break;
                        case MatterActionRequiredFilters.ATTENTION_MATTER_TOPICS:
                            actionFilters.push("matterSearch.attentionMatterTopicsCount_GT_0");
                            break;
                        case MatterActionRequiredFilters.INCOMPLETE_PRELIMINARY_REPORT_MORTGAGES:
                            actionFilters.push("matterSearch.incompletePreliminaryReportMortgagesCount_GT_0");
                            break;
                        case MatterActionRequiredFilters.INCOMPLETE_FINAL_REPORT_MORTGAGES:
                            actionFilters.push("matterSearch.incompleteFinalReportMortgagesCount_GT_0");
                            break;
                        case MatterActionRequiredFilters.NOT_RELEASED_MATTER_HOLDBACKS:
                            actionFilters.push("matterSearch.notReleasedMatterHoldbacksCount_GT_0");
                            break;
                        case MatterActionRequiredFilters.OUTSTANDING_TASKS:
                            actionFilters.push("matterSearch.hasOutstandingWorkItemTasks_EQ_true");
                            break;
                        case MatterActionRequiredFilters.OVERDUE_TASKS:
                            actionFilters.push("matterSearch.earliestWorkItemTaskDueDate_LT_" + Utils.escapeSearchText(moment(new Date()).format('YYYY/MM/DD')));
                            break;
                        default:
                            break;
                    }
                });
                if (actionFilters.length > 0) {
                    filter = Utils.addCommaToFilter(filter);
                    filterFlag = true;
                    filter += 'ANY' + (_.filter(actionFilters).join("|"));
                }
            }

        }

        if (urlQuery != null && urlQuery != '' && urlQuery) {
            filter = Utils.addCommaToFilter(filter);
            //_EQ_ will remove the non-alphaNumeric characters from the search term when search against address field, _EQT_ will not remove the non-alphaNumeric characters from the search term when search against address field
            filter += 'ANYmatterRecordNumber_EQ_' + urlQuery + '*|matterSearchView.clientReline_EQ_*' + urlQuery + '*|matterSearchView.propertyAddress_EQ_mailing:*' + urlQuery + '*|matterSearchView.propertyAddress_EQT_mailing:*' + urlQuery + '*';
            //  filter += 'matterRecordNumber_EQ_' + urlQuery + '*';
        }
        if (sort != null) {
            sort = "sort=" + sort + "|" + sortingType;
        }

        url = urlQuery || filterFlag ?
            `${sort}&filter=${filter}&filterType=ALL`
            : `${sort}`;

        return url;
    }

    createMatterSearchRequestForClerks(matterListState: MatterListState, filter: string): string {
        return this.matterHelperService.createMatterSearchRequestForContacts(matterListState.selectedClerks, filter, 'LawClerk', 'lawClerk');
    }

    createMatterSearchRequestForLawyers(matterListState: MatterListState, filter: string): string {
        return this.matterHelperService.createMatterSearchRequestForContacts(matterListState.selectedLawyers, filter, 'Lawyer', 'lawyer');
    }


    exportMatters(reportTemplateId: number, matterListState: MatterListState): void {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let searchQuery = this.createMatterSearchRequest(matterListState);
        let url = `${matterApi.exportMattersUsingReportTemplate(accountId)}?${SESSION_ID_REQ_PARAM}=` + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId) + '&' + searchQuery;
        url = url.replace('{reportTemplateId}', '' + reportTemplateId);
        window.open(this.http.encodePipe(this.http.normalizeUrl(url)), "_blank");
    }


    getMatterTypesDetails(account: Account): Observable<MatterTypeInfo[]> {
        return this.accountService.getMatterTypesDetails(account).map(
            (matterTypes: MatterTypeInfo[]) => {
                if (matterTypes) {
                    // In matter list display only the system matter types Or reserved custom matter types that account has access to
                    let filteredMatterTypes = matterTypes.filter(value => value.systemMatterType || value.customMatterType);
                    // Add Project Sale
                    if (this.authorizationService.hasReadOrFullAccessToProjectMatter()) {
                        let psMatterType = new MatterTypeInfo();
                        psMatterType.matterTypeCode = PROJECT_SALE_MATTER_OPTION_VALUE;
                        psMatterType.matterTypeDescription = PROJECT_SALE_MATTER;
                        psMatterType.reserved = false;
                        psMatterType.customMatterType = false;
                        //Add Project sale option after P,S,M
                        filteredMatterTypes.splice(4, 0, psMatterType);
                    }
                    return filteredMatterTypes;
                } else {
                    return [];
                }

            }
        );
    }


    // get matter by id from backend, MatterApi defaults to lock matter on this call
    getMatter(id: number, lockMatter = true, noAccessLogRequired : boolean = false): Observable<any> {
        return this.http.get(`${matterApi.matters}/${id}?lockRequired=${lockMatter}&noAccessLogRequired=${noAccessLogRequired}`)
            .map((res) => {
                const matter: Matter = new Matter(res['Matter']);
                matter.updateLocalGender();
                return matter;
            });
    }

    getMatterByMatterRecordNumber(matterRecordNumber: string, lockScreen?: boolean, projectId?: number, isOpportunityMatter?: boolean): Observable<any> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = `${matterApi.mattersForAccount(accountId)}?filter=matterRecordNumber_EQ_${Utils.escapeSearchText(matterRecordNumber)},unityProject.id_EQ_${projectId}`;
        if (isOpportunityMatter) {
            url = `${opportunityMatterApi.getOpportunities(accountId)}?filter=matterRecordNumber_EQ_${Utils.escapeSearchText(matterRecordNumber)}`;
        }
        return this.http.get(url, lockScreen ? lockScreen : false)
            .map((res) => {
                return res['Matters'];
            });
    }

    updateMatter(id: number, matter: any, unassignedMortgageInstructionId?: number, cancelledMortgageInstructionIds?: number[], saveAsync?: boolean): Observable<any> {

        let url: string = `${matterApi.matters}/${id}`;
        if (saveAsync) {
            url += '/async';
        }
        // Needed to add logic over rather than cleanup because after clean lot of other methods are called which might be using matter type
        matter.revertMatterTypeForCustomMatter();
        matter.revertMatterTypeForDischargeMatter();


        if (unassignedMortgageInstructionId != null) {
            url = matterApi.unassignMortgageInstruction.replace('{matterId}', '' + id).replace('{mortgageInstructionId}', '' + unassignedMortgageInstructionId);
        } else if ((<Matter>matter).isOpportunityMatter()) {
            url = opportunityMatterApi.updateOpportunity(String(id));
        }
        if ((matter.isPurchase || matter.isSale) && (!matter.adjustments || matter.adjustments.length == 0)) {
            matter.isMatterSaveInProgress = false;
            this.globalLogger.log(LogLevelTypes.WARN, 'Matter Service  : Update Matter called with no Adjustment Data for Matter id: ' + id);
            this.dialogService.confirm('Error', 'An unexpected error occurred and your matter cannot be saved. Please close and re-open the matter to continue editing it.', true).subscribe((response: any) => {
            });
        } else {
            let savedMatterObj;
            if (this.appConfig.isMatterSaveStringified) {
                savedMatterObj = JSON.parse(JSON.stringify(matter));
            } else {
                savedMatterObj = matter;
            }
            MatterCleanUpUtil.cleanupUIOnlyFields(savedMatterObj);
            if (Array.isArray(cancelledMortgageInstructionIds) && cancelledMortgageInstructionIds.length > 0) {
                url = matterApi.cancelMortgageInstruction.replace('{matterId}', '' + id);

                let cancelledMortgageInstruction: CancelledMortgageInstruction = new CancelledMortgageInstruction();
                cancelledMortgageInstruction.matterDTO = savedMatterObj;
                cancelledMortgageInstruction.mortgageInstructionIds = cancelledMortgageInstructionIds;

                return this.http.put(url, cancelledMortgageInstruction)
                    .map((res) => {
                        const matter: Matter = new Matter(res[matterResponseKey.matter]);
                        matter.updateLocalGender();
                        matter.dirty = false;
                        return matter;
                    });

            }


            return this.http.put(url, savedMatterObj, false, this.uploadCompressionEnabled)
                .map((res) => {
                    const matter: Matter = new Matter(res[matterResponseKey.matter]);
                    matter.updateLocalGender();
                    matter.dirty = false;
                    return matter;
                });
        }
    }

    addMatter(matter: any): Observable<any> {

        // console.log(matter);
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        // Needed to add logic over rather than cleanup because after clean lot of other methods are called which might be using matter type
        matter.revertMatterTypeForCustomMatter();
        matter.revertMatterTypeForDischargeMatter();
        let url = matterApi.mattersForAccount(accountId);
        if ((<Matter>matter).isOpportunityMatter()) {
            url = opportunityMatterApi.createOpportunity(accountId);
        }
        if ((<Matter>matter).isFromOpportunity) {
            url = matterApi.copyMatterFromOpportunity(accountId, (<Matter>matter).sourceOpportunityId);
        }
        if ((matter.isPurchase || matter.isSale) && (!matter.adjustments || matter.adjustments.length == 0)) {
            matter.isMatterSaveInProgress = false;
            this.globalLogger.log(LogLevelTypes.WARN, 'Matter Service  : Create Matter called with no Adjustment Data');
            this.dialogService.confirm('Error', 'An unexpected error occurred and your matter cannot be saved. Please close and re-open the matter to continue editing it.', true).subscribe((response: any) => {
            });
        } else {
            return this.http.postWithNoCatch(url, JSON.stringify(matter))
                .map((res) => {
                    const matter: Matter = new Matter(res[matterResponseKey.matter]);
                    matter.dirty = false;
                    matter.updateLocalGender();
                    return matter;
                });
        }
    }

    lockMatters(matters: Matter[]): Observable<any> {
        let obsMatterArray: any[] = [];
        matters.forEach(matter => {
            obsMatterArray.push(this.lockMatter(matter.id).catch(error => Observable.of(error)));
            if (matter.matterLink && matter.matterLink.linkedMatterId) {
                obsMatterArray.push(this.lockMatter(matter.matterLink.linkedMatterId).catch(error => Observable.of(error)));
            }
        });
        this.lockScreenService.lockForUpdate = true;
        return forkJoin(obsMatterArray).finally(() => {
            this.lockScreenService.lockForUpdate = false;
        }).flatMap(results => {
            return Observable.of(true);
        });
    }

    lockMatter(id: number, lastUpdatedTimeStamp?: Date) {
        if (id < 0) {
            return Observable.of(null);
        }

        let url: string = `${matterApi.lockMatter(String(id))}`;
        if (lastUpdatedTimeStamp) {
            url += '?lastUpdatedTimestamp=' + lastUpdatedTimeStamp;
        }

        return this.http.put(url, id)
            .map((res) => {
                return res['MatterLockStatus'];
            });
    }

    unlockMatters(matters: Matter[]): Observable<any> {
        let obsMatterArray: any[] = [];
        matters.forEach(matter => {
            if (matter.matterLink) {
                if (!this.tabsService.isMatterTabOpen(matter.id) && !this.tabsService.isMatterTabOpen(matter.matterLink.linkedMatterId)) {
                    obsMatterArray.push(this.unlockMatter(matter.id).catch(error => Observable.of(error)));
                    obsMatterArray.push(this.unlockMatter(matter.matterLink.linkedMatterId).catch(error => Observable.of(error)));
                }
            } else if (!this.tabsService.isMatterTabOpen(matter.id)) {
                obsMatterArray.push(this.unlockMatter(matter.id).catch(error => Observable.of(error)));
            }

        });

        this.lockScreenService.lockForUpdate = true;
        return forkJoin(obsMatterArray).finally(() => {
            this.lockScreenService.lockForUpdate = false;
        }).flatMap(results => {
            return Observable.of(true);
        });
    }

    unlockMatter(id: number) {
        if (id < 0) {
            return Observable.of(null);
        }
        return this.http.get(`${matterApi.matters}/${id}/unlock`)
            .map((res) => {
                return res;
            });
    }

    getMatterLockStatus(id: number) {
        return this.http.get(`${matterApi.matters}/${id}/lockstatus`)
            .map((res) => {
                return res;
            });
    }

    getMattersLockStatus(matterIds: number[]): Observable<boolean[]> {

        let result = Observable.of([]);

        if (matterIds && matterIds.length > 0) {
            let matterSubscriptions: Observable<boolean>[] = matterIds.map(id => this.getMatterLockStatus(id));
            result = Observable.forkJoin(...matterSubscriptions);
        }
        return result;
    }

    getMultiSelectFilterList(type) {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = `${matterApi.contactsList(accountId)}?contactType=${type}&filter=customerAccount.id_EQ_${accountId}&filterOtherFirmContact=true&sort=contactName.firstName|ASC`;
        return this.http.get(url).map((res) => {
            return res['Contacts'];
        });

    }

    addMatterListfilter(matterListFilter: MatterListFilter): Observable<any> {
        console.log('addMatterListfilter', matterListFilter);
        const serverMatterListFilter: ServerMatterListFilter = this.convertToServerData(matterListFilter);
        return this.http.post(matterApi.matterListFilters(serverMatterListFilter.customerAccountId + ''), JSON.stringify(serverMatterListFilter))
            .map((res) => {
                let data: ServerMatterListFilter = res['MatterListFilter'];
                return this.convertToUiData(data);
                // return this.convertToUiData(new ServerMatterListFilter(res[matterListFilter]));
            }).catch(error => {
                return Observable.throw(error);
            });
    }

    updateMatterListFilter(matterListFilter: MatterListFilter, id: string): Observable<any> {
        const serverMatterListFilter: ServerMatterListFilter = this.convertToServerData(matterListFilter);
        return this.http.put(`${matterApi.matterListFilters(serverMatterListFilter.customerAccountId + '')}/${id}`, JSON.stringify(serverMatterListFilter))
            .map((res) => {
                let data: ServerMatterListFilter = res['MatterListFilter'];
                return this.convertToUiData(data);
                // return this.convertToUiData(new ServerMatterListFilter(res[matterListFilter]));
            }).catch(error => {
                return Observable.throw(error);
            });
    }

    deleteMatterListfilter(id: string): Observable<any> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        return this.http.delete(`${matterApi.matterListFilters(accountId)}/${id}`)
            .map((res) => {
                return res;
            });
    }

    // get matter by id from backend
    getAllMatterListFilter(filterType: string): Observable<any> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = matterApi.matterListFilters(accountId);
        if(filterType){
            url += `?filter=filterType_EQ_${filterType}`;
        }
        return this.http.get(url)
            .map((res) => {
                let data: ServerMatterListFilter[] = res['MatterListFilters'];
                data.sort(function (a, b) {
                    let nameA: string = a.matterListFilterName;
                    let nameB: string = b.matterListFilterName;
                    let returnValue: number;
                    if (nameA === nameB) {
                        returnValue = 0; // No duplicate
                    } else if (nameA === 'All Matters' || nameA === 'All Opportunities') {
                        returnValue = -1; // Always smaller (at top).
                    } else if (nameA === 'My Recent') {
                        if (nameB === 'All Matters' || nameB === 'All Opportunities') {
                            returnValue = 1;
                        } else {
                            returnValue = -1; // Always smaller except if comparing with 'All Matters'
                        }
                    } else if (nameB === 'All Matters' || nameB === 'All Opportunities' || nameB === 'My Recent') {
                        returnValue = 1;  // Anything else is bigger than the ones at top
                    } else {
                        returnValue = nameA.localeCompare(nameB); // Regular alphabetical
                    }
                    return returnValue;
                });
                let matterListFilters: MatterListFilter[] = [];
                data.forEach(item => matterListFilters.push(this.convertToUiData(item)));
                return matterListFilters;
            });
    }

    revertToGlobalAction(participantWrapperToBeReverted: MatterParticipantWrapper, snapshotSourceContact: Contact,
                         mortgageId: number, matter: Matter) {
        this.dialogService.confirm('Confirmation', RevertToGlobalMessage, false).subscribe((response: any) => {
            if (response) {
                //After finishing the reverting global action, it will unlock this contact
                //Reloading the actual global Proxy contact from server
                this.contactService.revertProxyToGlobal(snapshotSourceContact.id)
                    .subscribe((revertedProxyContact: Contact) => {
                        this.setSnapshotDataWithProxyContact(participantWrapperToBeReverted, revertedProxyContact, mortgageId, matter);
                    });


            }
        });
    }

    setSnapshotDataWithProxyContact(participantWrapperToBeReverted: MatterParticipantWrapper, revertedProxyContact: Contact, mortgageId: number, matter: Matter): void {
        let snapshotContact: Contact;
        if (!(participantWrapperToBeReverted && participantWrapperToBeReverted.matterParticipant && participantWrapperToBeReverted.matterParticipant.contact)) {
            return;
        }
        //Unlock this contact
        this.contactService.unlockContact(participantWrapperToBeReverted.matterParticipant.contact.sourceContactId).subscribe();
        matter.removeMatterParticipant(participantWrapperToBeReverted.matterParticipant);
        //According to backend requirement, UI set law firm snapshot subContact as []
        if (revertedProxyContact) {
            snapshotContact = new Contact(revertedProxyContact);
            snapshotContact.subContacts = [];
        }

        participantWrapperToBeReverted.matterParticipant = matter.addMatterParticipant(snapshotContact, true,
            participantWrapperToBeReverted.matterParticipant.matterParticipantRole, mortgageId);
        participantWrapperToBeReverted.isStale = participantWrapperToBeReverted.retrieveStaleStatus(revertedProxyContact);
        participantWrapperToBeReverted.isClearFlagWithoutUpdatingMatter = participantWrapperToBeReverted.refreshClearFlagStatus(revertedProxyContact);
        participantWrapperToBeReverted.canBeUpdatedFromSourceContact = participantWrapperToBeReverted.isStale && !!participantWrapperToBeReverted.matterParticipant.contact.sourceContactId;
        participantWrapperToBeReverted.lastUpdatedOn = revertedProxyContact.lastUpdatedOnMsg;
        participantWrapperToBeReverted.selectedDetailsTabIndex = 0;
        participantWrapperToBeReverted.createdOrSelected = "Selected";
        participantWrapperToBeReverted.updateSourceContactFlagsForSnapshot(revertedProxyContact);
    }


    requestReset(id: number, certifiedBy: string): Observable<any> {
        return this.http.put(`${matterApi.matters}/${id}/resetMatter`, certifiedBy)
            .map((res) => {
                let matter: Matter = new Matter(res['Matter']);
                return matter;
            });
    }

    convertToServerData(matterListFilter: MatterListFilter): ServerMatterListFilter {
        if (!matterListFilter) {
            return null;
        }
        let serverMatterListFilter: ServerMatterListFilter = new ServerMatterListFilter();
        serverMatterListFilter.id = matterListFilter.id;
        serverMatterListFilter.customerAccountId = matterListFilter.customerAccountId;
        serverMatterListFilter.matterListFilterName = matterListFilter.matterListFilterName;
        serverMatterListFilter.matterListFilterSortBy = matterListFilter.matterListFilterSortBy;
        serverMatterListFilter.matterListFilterSortByOrder = matterListFilter.matterListFilterSortByOrder;
        serverMatterListFilter.matterTypeFilters = matterListFilter.matterTypeFilters && matterListFilter.matterTypeFilters.join(',');
        serverMatterListFilter.provinceCodeFilters = matterListFilter.provinceCodeFilters && matterListFilter.provinceCodeFilters.join(',');
        serverMatterListFilter.matterStatusFilters = matterListFilter.matterStatusFilters && matterListFilter.matterStatusFilters.join(',');
        serverMatterListFilter.actionRequiredFilters = matterListFilter.actionRequiredFilters && matterListFilter.actionRequiredFilters.join(',');
        serverMatterListFilter.selectedLawyerContactIds = matterListFilter.selectedLawyerContactIds && matterListFilter.selectedLawyerContactIds.join(',');
        serverMatterListFilter.selectedClerkContactIds = matterListFilter.selectedClerkContactIds && matterListFilter.selectedClerkContactIds.join(',');
        serverMatterListFilter.selectedProjectIds = matterListFilter.selectedProjectIds && matterListFilter.selectedProjectIds.join(',');
        serverMatterListFilter.closingDayFilter = matterListFilter.closingDayFilter;
        serverMatterListFilter.filterType = matterListFilter.filterType;
        serverMatterListFilter.selectedAssigneeIds = matterListFilter.selectedAssigneeIds && matterListFilter.selectedAssigneeIds.join(',');
        serverMatterListFilter.selectedOpportunityStatuses = matterListFilter.selectedOpportunityStatuses && matterListFilter.selectedOpportunityStatuses.join(',');
        serverMatterListFilter.selectedOpportunitySources = matterListFilter.selectedOpportunitySources && matterListFilter.selectedOpportunitySources.join(',');
        return serverMatterListFilter;
    }

    convertToUiData(serverMatterListFilter: ServerMatterListFilter): MatterListFilter {
        if (!serverMatterListFilter) {
            return null;
        }
        let matterListFilter: MatterListFilter = new MatterListFilter();
        matterListFilter.id = serverMatterListFilter.id;
        matterListFilter.customerAccountId = serverMatterListFilter.customerAccountId;
        matterListFilter.matterListFilterName = serverMatterListFilter.matterListFilterName;
        matterListFilter.matterListFilterSortBy = serverMatterListFilter.matterListFilterSortBy;
        matterListFilter.matterListFilterSortByOrder = serverMatterListFilter.matterListFilterSortByOrder;
        matterListFilter.matterTypeFilters = serverMatterListFilter.matterTypeFilters && serverMatterListFilter.matterTypeFilters.split(',');
        matterListFilter.provinceCodeFilters = serverMatterListFilter.provinceCodeFilters && serverMatterListFilter.provinceCodeFilters.split(',');
        matterListFilter.matterStatusFilters = serverMatterListFilter.matterStatusFilters && serverMatterListFilter.matterStatusFilters.split(',');
        matterListFilter.actionRequiredFilters = serverMatterListFilter.actionRequiredFilters && serverMatterListFilter.actionRequiredFilters.split(',');
        matterListFilter.selectedLawyerContactIds = serverMatterListFilter.selectedLawyerContactIds && serverMatterListFilter.selectedLawyerContactIds.split(',');
        matterListFilter.selectedClerkContactIds = serverMatterListFilter.selectedClerkContactIds && serverMatterListFilter.selectedClerkContactIds.split(',');
        matterListFilter.selectedProjectIds = serverMatterListFilter.selectedProjectIds && serverMatterListFilter.selectedProjectIds.split(',');
        matterListFilter.closingDayFilter = serverMatterListFilter.closingDayFilter;
        matterListFilter.filterType = serverMatterListFilter.filterType;
        matterListFilter.selectedAssigneeIds = serverMatterListFilter.selectedAssigneeIds && serverMatterListFilter.selectedAssigneeIds.split(',');
        matterListFilter.selectedOpportunityStatuses = serverMatterListFilter.selectedOpportunityStatuses && serverMatterListFilter.selectedOpportunityStatuses.split(',');
        matterListFilter.selectedOpportunitySources = serverMatterListFilter.selectedOpportunitySources && serverMatterListFilter.selectedOpportunitySources.split(',');
        return matterListFilter;
    }

    // tryToNumber(s : string) {
    //     const n : number = Number(s);
    //     return isNaN(n) ? s : n;
    // }

    callOperationAfterMatterSaved(matter: Matter, callback: Function, withTimeout: boolean = false): void {
        //If matter is not saved then user has to first save the matter
        if (matter.dirty || matter.isDraftMatter()) {
            if (matter.dirty) {
                this.dialogService.confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save').subscribe(res => {
                    if (res && res == true) {
                        this.saveMatterBeforeCalling(callback, withTimeout);
                    } else {
                        console.log("cancel");
                    }
                });
            } else {
                this.saveMatterBeforeCalling(callback);
            }
        } else {
            callback();
        }
    }

    // set "withTimeout" param to true if you need to push the callback down the stack
    // in order to let any setters of a component (that subscribed to matter updates)
    // to be called first (it might need to push matter to child components).
    public async saveMatterBeforeCalling(callback: Function, withTimeout: boolean = false): Promise<boolean> {
        let matterTab = this.tabsService.activeTab as MatterTab;
        if (matterTab && matterTab.matterComponent) {
            let result = await matterTab.matterComponent.validateAndSaveMatter().toPromise();
            if (result) {
                //Once matter is saved then call operation after the authentication
                if(withTimeout){
                    setTimeout( () => { callback() }, 10);
                } else {
                    callback();
                }
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    async saveMatterWithoutConfirmationDialog(): Promise<void> {
        let matterTab = this.tabsService.activeTab as MatterTab;
        if (matterTab && matterTab.matterComponent) {
            await matterTab.matterComponent.validateAndSaveMatter().toPromise();
        }
    }

    resetMatterTitleInsurance(matter: Matter, selectedInsurerValue: string): void {
        matter.matterTitleInsurance = new MatterTitleInsurance();
        //Clear All MissingFieldErrors
        this.errorService.clearAllMissingFieldErrors();
        //Clear Third Party Notification for Stewart Title
        this.errorService.removeDpThirdPartyNotification('notification.stewartTitle.status');
        this.errorService.removeDpThirdPartyNotification('notification.stewartTitle.invalid');
        //Clear Third Party Notification for Chicago Title
        this.errorService.removeDpThirdPartyNotification('notification.chicagoTitle.status');
        this.errorService.removeDpThirdPartyNotification('notification.chicagoTitle.invalid');
        //Clear Third Party Notification for FCT
        this.errorService.removeDpThirdPartyNotification('notification.fctTitle.invalid');
        this.errorService.removeDpThirdPartyNotification('notification.fctTitle.status');

        //Remove any title insurance entries from Trust Ledger and Statement of Account
        const soaTrustLedgerCollection: SoaTrustLedgerCollection = matter.soaTrustLedgerCollection;
        soaTrustLedgerCollection.removeTitleInsuranceFromTrust();
        soaTrustLedgerCollection.removeTitleInsuranceFromSOA();
        if (matter.secondarySoaSheetsCollection) {
            matter.secondarySoaSheetsCollection.forEach(collection => {
                collection.removeTitleInsuranceFromTrust();
                collection.removeTitleInsuranceFromSOA();
            })
        }
        //initialize the Title Insurance if select 'Other Title Insurer'
        if (matter.matterTitleInsurance && selectedInsurerValue === 'Other Title Insurer') {
            matter.matterTitleInsurance.initializeOtherTitleInsurance(matter);
        }

    }

    getMattersById(matterIds: number[], accountId: string): Observable<Matter[]> {
        return this.http.get(`${matterApi.mattersForAccount(accountId)}?filter=id_IN_` + matterIds.join('!')).map((res) => {
            return res[matterResponseKey.matters].map((item) => {
                return new Matter(item);
            });
        });
    }

    getMattersWithDetail(matterIds: number[], lockRequired = false, noAccessLogRequired : boolean = false): Observable<Matter[]> {

        let result = Observable.of([]);

        if (matterIds && matterIds.length > 0) {
            let matterSubscriptions: Observable<Matter>[] = matterIds.map(id => this.getMatter(id, lockRequired, noAccessLogRequired));
            result = Observable.forkJoin(...matterSubscriptions);
        }
        return result;
    }


    getMatterDocumentProfile(matter: Matter): Observable<DocumentProfile> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        if (matter.documentProfileId) {
            //DocumentProfile should be already cached so it should not be null and return immediately
            return this.documentProfileService.getById(matter.documentProfileId, accountId, false, matter);
        }
    }

    //TODO it will move solicitor and law clerk to cache. Now it keeps to call back end.
    getContactForMatter(contactId): Observable<Contact> {
        return this.contactQueryService.getContactForMatter(contactId);
    }


    setCommissionPaidTo(matter: Matter): void {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        this.accountService.getAccountProvince(accountId, matter.provinceCode).subscribe(accountProvince => {
            if (!matter.isTemplateMatterForMassUpdate) {
                if (matter.isMatterProvinceBC){
                    matter.commissionPaidTo = 'BOTH_VENDOR_AND_PURCHASER_BROKER';
                } else {
                    matter.commissionPaidTo = accountProvince && accountProvince.saleCommissionPaidTo ? accountProvince.saleCommissionPaidTo : constValues.commissionPaidTo.VENDOR_BROKER_ONLY as CommissionPaidToType;
                }
            }
        });
    }


    setUpExistingMatter(matter: Matter, considerationTaxes: ConsiderationTaxes[]): void {

        let realEstateBroker: any = _.filter(matter.matterParticipants, {'matterParticipantRole': 'REALESTATEBROKER'});

        if (realEstateBroker && realEstateBroker.length > 0) {
            if (realEstateBroker[0].contact.persons && realEstateBroker[0].contact.persons.length > 0) {
                let contactName = realEstateBroker[0].contact.persons[0].contactName;
                matter.agentName = `${contactName.firstName} ${contactName.middleName} ${contactName.lastName}`;
            } else {
                matter.agentName = undefined;
            }
        } else {
            matter.agentName = undefined;
        }

        if (matter.isInsurerChicagoTitle() && matter.matterPropertyWithCondo) {
            let numOfUnit: number = +matter.matterPropertyWithCondo.titleInsuranceNumOfUnits;
            if (numOfUnit > MAX_NUM_OF_UNIT) {
                matter.matterPropertyWithCondo.titleInsuranceNumOfUnits = '';
                matter.dirty = true;
            }
        }

        this.initializeDirectDepositInstructions(matter);

        if (!matter.teranetDocket) {
            matter.teranetDocket = new TeranetDocket();
        }
        matter.initializedFireInsuranceContactInfo();
        matter.setUpMatterContactInfo();

        this.checkIfUpdateTitleInsuranceRequired(matter, this.errorService);
        this.checkIfUpdateAltoEFormsRequired(matter, this.errorService);
        matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
        this.constructPinLegalDescriptions(matter);
    }

    constructPinLegalDescriptions(matter: Matter): void {
        if (matter.matterTitleInsurance && (matter.isInsurerStewartTitle() || matter.isInsurerTitlePlus())) {
            let existingMatterlegalDescription = null;
            //To handle existing matters if the overrideLegalDescription was true
            if (matter.overrideLegalDescription) {
                existingMatterlegalDescription = matter.legalDescription;
            }
            /*
             *  existingMatterlegalDescription is used only for migrating existing legal descriptions
             *  It is passed only when we open an existing matter that has the overrideLegalDescription flag equals to true
             */
            LegalDescriptionUtil.constructPinLegalDescriptions(matter, existingMatterlegalDescription);
        }
    }

    initializeAlbertaAltoEForms(newMatter: Matter): void {
        if (newMatter.isMatterProvinceAB) {
            if (newMatter.isSale) {
                newMatter.createTransferOfLandEForm();
            }
        }
    }

    initializeERegTransferForm(newMatter: Matter): void {
        if ((newMatter.isPurchase || newMatter.isSale) && newMatter.isMatterProvinceON) {
            newMatter.createERegTransferForm();
        }
    }

    initializeMBTPREForms(newMatter: Matter): void {
        if (newMatter.isMatterProvinceMB) {
            if (newMatter.isSale || newMatter.isPurchase && this.isMortgageLoanTypeBackToVendor(newMatter)) {
                newMatter.createTPRTransferEForm();
            }
        }
    }

    isMortgageLoanTypeBackToVendor(newMatter: Matter): boolean {
        if (newMatter.newOrEmpMortgages) {
            return newMatter.newOrEmpMortgages.some(mortgage => mortgage.isLoanTypeBackToVendor());
        } else {
            return false;
        }
    }


    initProjectSale(matter: Matter) {
        // After calling openMatter method, isProjectSale should get value
        if (matter.isProjectSale) {
            this.initProjectDefaultSettingsForMatterProperty(matter);
            if (matter.isMatterProvinceAB) {
                matter.actingFor = ActingForValues.VENDOR_ONLY;
                if (matter && matter.project) {
                    matter.interestRateSummary = matter.project.interestRate;
                }
            }
            if (!matter.contactTerminated) {
                matter.contactTerminated = DpBooleanValueTypes.N_y;
            }
            if (matter.isMatterProvinceON) {
                if (!matter.isReleaseDateSameAsOccupancyDate) {
                    matter.isReleaseDateSameAsOccupancyDate = DpBooleanValueTypes.Y_n;
                }
                // this.projectService.getProject(matter.unityProjectId).subscribe((project: Project) =>{
                if (matter.project && (matter.project.condominiumFlag || matter.project.occupancyDateRequired)) {

                    // matter.insertFileNoIntoTeraviewDocket = matter.project.insertFileNoIntoTeraviewDocket;
                    if (!matter.occupancyCompleted) {
                        matter.occupancyCompleted = DpBooleanValueTypes.N_y;
                    }
                }
                // });

            }

        }

    }

    /**
     * This method initialize the default fields for a matter property based on the project sale settings.
     * All the defaulting logic for new matter property from a
     * project sale should go in this method.
     * @param {Matter} matter
     */

    initProjectDefaultSettingsForMatterProperty(matter: Matter) {
        if (matter.project && matter.matterPropertyWithCondo) {
            matter.matterPropertyWithCondo.projectName = matter.project.projectName;
            matter.matterPropertyWithCondo.isCondominium = matter.project.condominiumFlag ? DpBooleanValueTypes.YES : DpBooleanValueTypes.NO;
            if (matter.matterPropertyWithCondo.isPropertyCondominium()) {
                this.initProjectMatterCondoPlans(matter);
            }
            matter.matterPropertyWithCondo.purchaseIsOfCode = 'NEW_BUILDER_HOME';
            if (matter.isMatterProvinceON) {
                matter.matterPropertyWithCondo.registryOrLt = matter.project.docRegistration && matter.project.docRegistration.registryOrLt;
                //potl
                matter.matterPropertyWithCondo.isParcelOfTiedLand = matter.project.potlFlag ? DpBooleanValueTypes.YES : DpBooleanValueTypes.NO;
                matter.matterPropertyWithCondo.nameOfCondominiumPlan = matter.project.potlPlanApplied ? matter.project.condominiumPlanName : null;
                matter.matterPropertyWithCondo.builderNumber = matter.project.tarionNo ? matter.project.tarionNo : null;
            }
        }
    }

    initProjectMatterCondoPlans(matter: Matter): void {
        if (matter.project && matter.project.projectCondo && matter.project && matter.project.projectCondo) {
            if (!matter.matterPropertyWithCondo.exceptionType) {
                matter.matterPropertyWithCondo.exceptionType = matter.project.projectCondo.exceptionType;
                if (matter.matterPropertyWithCondo.exceptionType == DpBooleanValueTypes.YES) {
                    matter.matterPropertyWithCondo.exceptionTypeDescription = matter.project.projectCondo.exceptionTypeDescription;
                }
            }
            if (!matter.matterPropertyWithCondo.condominiumJurisdiction) {
                matter.matterPropertyWithCondo.condominiumJurisdiction = matter.project.projectCondo.location;
            }

            if (matter.matterPropertyWithCondo.isCondominiumPlanEmpty()) {
                matter.matterPropertyWithCondo.condominiumPlans = [];
                if (matter.project.projectCondo.projectCondoPlans && matter.project.projectCondo.projectCondoPlans.length) {
                    matter.project.projectCondo.projectCondoPlans.forEach((projectCondoPlan) => {
                        let condominiumPlan = new CondominiumPlan();
                        condominiumPlan.condominiumPlanNumber = projectCondoPlan.condominiumPlanNumber;
                        condominiumPlan.condominiumPlanType = projectCondoPlan.condominiumPlanType;
                        matter.matterPropertyWithCondo.condominiumPlans.push(condominiumPlan);
                    });
                }

            }
        }
    }

    initProjectSaleMatter(matter: Matter, adjustmentPropagationService: AdjustmentPropagationService): Observable<boolean> {
        let initMatterSubject = new Subject<boolean>();
        this.initMatter(matter).subscribe(isMatterUpdated => {
            if (isMatterUpdated && adjustmentPropagationService) {
                if (matter.isMatterExisting()) {
                    adjustmentPropagationService.updateProjectLevelStatementOfAdjustments(matter).then(isMatterUpdated => {
                        initMatterSubject.next(isMatterUpdated);
                        initMatterSubject.complete();
                    });
                } else {
                    adjustmentPropagationService.addProjectLevelStatementOfAdjustments(matter).then(isMatterUpdated => {
                        initMatterSubject.next(isMatterUpdated);
                        initMatterSubject.complete();
                    });
                }
            } else {
                initMatterSubject.next(isMatterUpdated);
                initMatterSubject.complete();
            }
        });
        return initMatterSubject;
    }

    initMatter(matter: Matter): Observable<boolean> {
        let initMatterSubject = new Subject<boolean>();
        if (matter.isProjectSale && !matter.project) {
            this.projectService.getProject(matter.unityProjectId, false).subscribe((project: Project) => {
                console.log('>>>> finished project load  %s', matter.matterRecordNumber);
                matter.project = project;
                this.continueInitMatter(matter).subscribe((result) => {
                    initMatterSubject.next(result);
                    initMatterSubject.complete();
                });
            });
        } else if (matter.isPurchase && matter.matterLink && this.activeMatterTab && this.activeMatterTab.isMatter() && this.activeMatterTab.isLinkedMatterProjectSale()
            && !matter.project) {
            // This option only gets executed when you are trying to open purchase matter which is linked to project sale
            // we are trying to put project object on purchase matter so that when adjustment which are not applicable to purchase
            // are added to statement of adjustment object on matter they require project and project configuration data to render correctly
            if (this.activeMatterTab.linkedMatter.project) {
                matter.project = this.activeMatterTab.linkedMatter.project;
                matter.extraDepositConfig = new MatterExtraDepositConfig(this.activeMatterTab.linkedMatter.extraDepositConfig);
                return this.continueInitMatter(matter);
            } else {
                this.projectService.getProject(this.activeMatterTab.linkedMatter.unityProjectId, false).subscribe((project: Project) => {
                    console.log('>>>> finished project load  %s', matter.matterRecordNumber);
                    matter.project = project;
                    matter.extraDepositConfig = new MatterExtraDepositConfig(this.activeMatterTab.linkedMatter.extraDepositConfig);
                    this.continueInitMatter(matter).subscribe((result) => {
                        initMatterSubject.next(result);
                        initMatterSubject.complete();
                    });
                });
            }
        } else {
            if(matter.isDischargeMatter() || !!matter.isMatterTypeDischarge){
                this.staffProfilesService.getCachedLoggedInStaffProfile().subscribe((staffProfiles: StaffProfiles) => {
                    if (staffProfiles && staffProfiles.user) {
                        let userProvince = staffProfiles.user.userProvinces.find(userProvince => userProvince.provinceCode == matter.provinceCode);
                        if(!userProvince || (!!userProvince  && !userProvince.enabled)){
                            matter.isProvinceDisabled = true;
                        }
                        this.continueInitMatter(matter).subscribe((result) => {
                            initMatterSubject.next(result);
                            initMatterSubject.complete();
                        });
                    }else{
                        return this.continueInitMatter(matter);
                    }
                });
            }else{
                return this.continueInitMatter(matter);
            }
        }
        return initMatterSubject;
    }

    continueInitMatter(matter: Matter): Observable<boolean> {

        matter.readyForUI = false;
        let initMatterSubject = new Subject<boolean>();
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId).toString();
        if(matter.isDischargeMatter()){
            matter.isMatterTypeDischarge = true;
            matter.changeDischargeMatterToMortgageMatter();
        }
        if (!matter.altoEForms || (matter.altoEForms && !matter.altoEForms.length)) {
            this.initializeAlbertaAltoEForms(matter);
            this.initializeMBTPREForms(matter);
        }
        // obsMatterArray Holds all async call which are needed to init matter.
        let obsMatterArray: any[] = [];

        if (!matter.isProjectSale) {
            //get Checklist Only if new matter is not project sale
            if (!matter.isMatterExisting()) {
                if (!UUIDUtil.uuidService) {
                    UUIDUtil.setService(this.uuidService);
                }
                obsMatterArray.push(UUIDUtil.requestNIds(500));//Requesting some ids to avoid run out of ids when creating matter work items tasks.
                if(!matter.isOpportunityMatter()) {
                    obsMatterArray.push(this.matterWorkItemsUtilsService.getDefaultChecklistTemplate(matter));
                }
                else if(matter.isNewMatter()){
                    matter.sendExternalNotifications = 'NO';
                    matter.sendInternalNotifications = 'NO';
                }
            }
        }
        else if(matter.isNewMatter()){
            matter.sendExternalNotifications = 'NO';
            matter.sendInternalNotifications = 'NO';
        }


        if (matter.isNewMatter() && matter.isMatterProvinceONorABorMBorSK ) {
                obsMatterArray.push(this.remoteSigningDefaultService.getRemoteSigningDefault(accountId, matter && matter.provinceCode).catch(error => Observable.of(error)));
         }

        if (matter.isCustomMatter()) {
            obsMatterArray.push(this.accountService.getMatterTypesDetails().catch(error => Observable.of(error)));
        }

        if (!matter.isOpportunityMatter()) {
            this.addCallsForNonOpportunityMatters(obsMatterArray, matter, accountId);
        }

        if (matter.isOpportunityMatter() && !!matter.provinceCode && !!matter.customMatterTypeName) {
            // Get Soa Fee Rate
            obsMatterArray.push(this.taxRateService.getSoaFeesRates(matter.getSoaTaxRateEffectiveDate(), matter.provinceCode).catch(error => Observable.of(error)));
        }

        // get the matter Document Profile
        if (matter.documentProfileId) {
            obsMatterArray.push(this.documentProfileService.getById(matter.documentProfileId, accountId, false, matter).catch(error => Observable.of(error)));
        } else {
            if(matter.isMatterTyeDischargeAndProvinceDisabled){
                //Default Document Profile of Logged in Staff Profile/User
                obsMatterArray.push(this.documentProfileService.getById(null,accountId, false, matter).catch(error => Observable.of(error)));
            }else{
                //Default Document Profile of Logged in Staff Profile/User
                obsMatterArray.push(this.documentProfileService.getDefaultDocumentProfileOfLoggedInUser(accountId, matter.provinceCode).catch(error => Observable.of(error)));
            }

        }

        if (matter.mortgagees && matter.mortgagees.length > 0) {
            matter.mortgagees.filter(value => value.contact && value.contact.sourceContactId).forEach(value => {
                obsMatterArray.push(this.getContactForMatter(value.contact.sourceContactId).catch(error => Observable.of(error)));
            })
        }
        // Get Solicitior
        if (matter.solicitor) {
            obsMatterArray.push(this.getContactForMatter(matter.solicitor.contactReferenceId).catch(error => Observable.of(error)));
        } else if (matter.empMortgages && matter.empMortgages.length > 0 && matter.empMortgages[0].stewartAssystMortgageInstruction) {
            obsMatterArray.push(this.getContactForMatter(matter.empMortgages[0].stewartAssystMortgageInstruction.solicitorId).catch(error => Observable.of(error)));
        } else if (matter.id == 0 && matter.selectedSolicitorId) { // this is for initialization of new matter with default solicitor defined at user level
            obsMatterArray.push(this.getContactForMatter(matter.selectedSolicitorId).catch(error => Observable.of(error)));
        }


        // Get Statement Config
        if (!matter.statementConfigurationId) {
            if (matter.isProjectSale) {
                //Get the project statementConfig for New Project Sale Matters
                let projectStatementConfigurationId = matter.projectStatementConfigurationId;
                if (projectStatementConfigurationId) {
                    obsMatterArray.push(this.soaConfigService.getStatementConfig(accountId, projectStatementConfigurationId, matter.provinceCode).catch(error => Observable.of(error)));
                }
            } else {
                if(matter.isMatterTyeDischargeAndProvinceDisabled){
                    obsMatterArray.push(this.soaConfigService.getSystemStatementConfig(matter.provinceCode).catch(error => Observable.of(error)));
                }else{
                    obsMatterArray.push(this.soaConfigService.getStatementConfig(accountId, undefined, matter.provinceCode).catch(error => Observable.of(error)));
                }

            }

        } else {
            let statementConfigurationIds: number[] = [];
            statementConfigurationIds.push(matter.statementConfigurationId);
            statementConfigurationIds.push(...matter.secondarySoaSheets.filter(item => !!item.statementConfigurationId).map(config => {
                return config.statementConfigurationId;
            }));
            Array.from(new Set(statementConfigurationIds)).forEach(id => {
                obsMatterArray.push(this.soaConfigService.getStatementConfig(accountId, id, matter.provinceCode).catch(error => Observable.of(error)));
            })
        }

        if (matter.isMatterProvinceONOrNB) {
            obsMatterArray.push(this.accountService.getDateCalculationConfig(accountId,matter.provinceCode).catch(error => Observable.of(error)));
        }

        if (matter.isNewMatter()) {
            matter.matterNotifications = [];
            obsMatterArray.push(this.matterNotificationConfigService.getMatterNotificationConfig(accountId).catch(error => Observable.of(error)));
        }
        // SoaExportConfig
        if (matter.isMatterExisting()) {
            if (matter.isProjectSale) {
                obsMatterArray.push(this.soaTrustLedgerHelperService.getSoaExportConfig(matter.id, 'INTERIM').catch(error => Observable.of(error)));
                obsMatterArray.push(this.soaTrustLedgerHelperService.getSoaExportConfig(matter.id, 'FINAL').catch(error => Observable.of(error)));
            } else {
                obsMatterArray.push(this.soaTrustLedgerHelperService.getSoaExportConfig(matter.id).catch(error => Observable.of(error)));
            }

        }

        if(matter.isMatterExisting() &&  !matter.isPurchase && matter.existingMortgages && matter.existingMortgages.length){
            matter.existingMortgages.forEach(mortgage => {
                if(this.isAssystPayoutApplicable(mortgage, matter)){
                    obsMatterArray.push(this.telusService.fetchLatestPayoutStatement(matter.id, mortgage.id).catch(error => Observable.of(error)));
                }
            });
        }

        if(matter.isMatterExisting() &&  matter.isPurchase && matter.isMatterProvinceNB && matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.landTransferTaxRateId){
            obsMatterArray.push(this.taxRateService.getLandTransferTaxRates(matter.provinceCode).catch(error => Observable.of(error)));
        }



        this.lockScreenService.lockForUpdate = true;
        forkJoin(obsMatterArray).finally(() => {
            this.lockScreenService.lockForUpdate = false;
            matter.readyForUI = true;
        }).subscribe(results => {
            console.log('>>>> started result processing init  %s', matter.matterRecordNumber);

            if (results) {
                let documentProfile: DocumentProfile;
                let supplementalTaskCategories: SupplementalTaskCategory[];
                let jurisdictionDepartments: JurisdictionDepartment[];
                let statementConfigs: StatementConfig[] = [];
                let soaFeeRates: SoaFeeRate[];
                let considerationTaxes: ConsiderationTaxes[];
                let soaExportConfig: SoaExportConfig;
                let interimSoaExportConfig: SoaExportConfig;
                let staffProfiles: StaffProfiles;
                let requisitionDateCalculationConfig : DateCalculationConfig;
                results.forEach((result : any[]) => {
                    if (result && result.length > 0 && result[0] instanceof MatterTypeInfo) {
                        let matterType = result.find(item => item.matterTypeCode == matter.customMatterTypeName);
                        if (matterType) {
                            matter.customMatterTypeDesc = matterType.matterTypeDescription;
                        }
                        matter.changeCustomMatterToPurchaseMatter();
                    } else if (result && result.length > 0 && result[0] instanceof ConsiderationTaxes) {
                        this.setUpTaxRateOnMatter(matter, result);
                        considerationTaxes = result;
                    } else if (result && result.length > 0 && result[0] instanceof SupplementalTaskCategory) {
                        supplementalTaskCategories = result;
                    } else if (result && result instanceof TitleInsuranceConfiguration) {
                        this.getDeductEcCcEliteFeeFromSoa(matter, result);
                    } else if (result && result instanceof TeraviewConfig) {
                        matter.teraviewConfig = result;
                    }else if(result && result instanceof DateCalculationConfig){
                        matter.requisitionDateCalculationConfig = result;
                    } else if (result && result instanceof StatementConfig) {
                        statementConfigs.push(result);
                    } else if (result && result instanceof DocumentProfile) {
                        documentProfile = result;
                        if (!matter.isMatterExisting()) {
                            if (!matter.documentProfileId) {
                                matter.documentProfileId = documentProfile.id;
                            }
                            if (matter.isMatterProvinceAB && documentProfile.miscDocumentProfile && !matter.isProjectSale) {
                                matter.interestRateSummary = documentProfile.miscDocumentProfile.interestRateSummary;
                            }
                            this.populateJurisdictionData(matter, accountId, documentProfile);
                        }
                    } else if(result && result instanceof RemoteSigningConfiguration){
                        if(matter.isNewMatter()){
                            // When create a new Matter,
                            // the respective fields on the Matter will be defaulted based on the value selected for that configuration item,
                            // where the Province matches the Province of the Matter which configuration items for the following provinces: ON, AB, MB, SK
                            if (matter.isMatterProvinceONorABorMBorSK) {
                                matter.documentsToBeSignedRemotely = result.documentsToBeSignedRemotely;
                                matter.documentsToBeSignedDigitally = result.documentsToBeSignedDigitally;
                                matter.digitalSignaturePlatform = result.digitalSignaturePlatform;
                            }
                        }

                    }
                    else if (result && result.length > 0 && result[0] instanceof SoaFeeRate) {
                        soaFeeRates = result;
                    } else if (result && result.length > 0 && result[0] instanceof RentInterestRate) {
                        matter.rentInterestRates = _.sortBy(result, ['index']);
                    } else if (result && result instanceof SoaExportConfig) {
                        if (matter.isProjectSale) {
                            if (result.soaProgressionType == ProgressionStatus.INTERIM) {
                                interimSoaExportConfig = result;
                            } else {
                                soaExportConfig = result;
                            }
                        } else {
                            soaExportConfig = result;
                        }
                    } else if (result && result instanceof AccountProvince) {
                        if (result && !matter.commissionPaidTo && !matter.isTemplateMatterForMassUpdate) {
                            matter.commissionPaidTo = result.saleCommissionPaidTo;
                        }
                    } else if (result && result.length > 0 && result[0] instanceof UserDefinedField) {
                        if (result) {
                            matter.initializeMatterUserDefinedFields(result);
                        }
                    } else if (result && result.length > 0 && result[0] instanceof JurisdictionDepartment) {
                        jurisdictionDepartments = result;
                    } else if (result && result instanceof ChecklistTemplateConfig && !matter.isMatterExisting()) {
                            matter.matterWorkItems = this.matterWorkItemsUtilsService.buildWorkItemsFromChecklistTemplate(result);
                            matter.appliedChecklistTemplateConfigId = result.id;
                            this.matterNotificationUtilService.initializeTaskNotificationOnMatterCreation(matter);
                    } else if (result && result instanceof Contact) {
                        //when the result type is Contact, it can be solicitor | lawClerk | witness | commissioner, and even same contact can be selected/used for {solicitor, witness, commissioner} OR {lawClerk, witness}
                        if ((matter.solicitor && result.id == matter.solicitor.contactReferenceId)
                            || (matter.empMortgages && matter.empMortgages.length > 0 && matter.empMortgages[0].stewartAssystMortgageInstruction
                                && matter.empMortgages[0].stewartAssystMortgageInstruction.solicitorId == result.id)) {

                            if (!matter.selectedSolicitorId || matter.selectedSolicitorId != result.id) {//to prevent multi-call when solicitor uses the same contact as witness/commissioner
                                // console.log(">> populate selectedSolicitorId with value %s", result.id)
                                matter.updateMatterSolicitorInfo(new Contact(result));
                            }
                        } else if (!matter.solicitor && matter.id == 0 && result.id == matter.selectedSolicitorId) { // applying default law clerk contact id from user setting
                            matter.updateMatterSolicitorInfo(new Contact(result));
                        }
                        if (matter.lawClerk && result.id == matter.lawClerk.contactReferenceId) {
                            if (!matter.selectedLawClerkId || matter.selectedLawClerkId != result.id) {//to prevent multi-call when lawClerk uses the same contact as witness
                                // console.log(">> populate selectedLawClerkId with value %s", result.id)
                                matter.updateMatterLawClerkInfo(new Contact(result));
                            }
                        } else if (!matter.lawClerk && matter.id == 0 && result.id == matter.selectedLawClerkId) { // applying default law clerk contact id from user setting
                            matter.updateMatterLawClerkInfo(new Contact(result));
                        }

                        if (matter.commissioner && result.id == matter.commissioner.contactReferenceId) {
                            if (!matter.selectedCommissionerId || matter.selectedCommissionerId != result.id) {//to prevent multi-call when commissioner uses the same contact as solicitor/witness
                                // console.log(">> populate selectedCommissionerId with value %s", result.id)
                                matter.updateMatterCommissionerInfo(new Contact(result));
                            }
                        }
                        if (matter.witness && result.id == matter.witness.contactReferenceId) {
                            if (!matter.selectedWitnessId || matter.selectedWitnessId != result.id) {//to prevent multi-call when witness uses the same contact as commissioner/Solicitor/lawClerk
                                // console.log(">> populate selectedWitnessId with value %s", result.id)
                                matter.updateMatterWitnessInfo(new Contact(result));
                            }
                        }
                        if (matter.mortgagees && matter.mortgagees.length > 0) {
                            matter.mortgagees.filter(value => value.contact && value.contact.sourceContactId == result.id).forEach(value => {
                                value.sourceContact = result;
                            })
                        }
                    } else if (matter.isNewMatter() && result && result.length > 0 && result[0] instanceof MatterNotificationConfig) {
                        this.matterNotificationUtilService.initializeNotificationOnMatterCreation(matter, result);
                    }
                    else if (result && result instanceof PayoutStatement){
                        let mortgage = matter.existingMortgages.find(existingMortgage => existingMortgage.id == result.mortgageId);
                        if(mortgage){
                            mortgage.payoutStatement = result;
                        }
                    }
                    else if (matter.isMatterExisting() && matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.landTransferTaxRateId && result && result.length > 0 && result[0] instanceof LandTransferTaxRate) {
                        let landTransferTaxRate = result.find(item => item.id == matter.matterPropertyWithCondo.landTransferTaxRateId);
                        if(landTransferTaxRate) {
                            matter.matterPropertyWithCondo.landTransferTaxRate = landTransferTaxRate;
                        }
                    }
                });
                if (matter.isMatterExisting()) {
                    this.setUpExistingMatter(matter, considerationTaxes);
                    //for PROD bug 27653, need to cleanup the heading issue for existing matter
                    if (documentProfile && matter.soaHeadingNotExistsOrHasError()) {
                        this.initSoaHeading(matter, documentProfile);
                    }
                } else {
                    this.setUpNewMatter(matter, supplementalTaskCategories, documentProfile, considerationTaxes);
                }
                // Setup Trust Ledger and statement of account once everything is set on the matter.
                console.log('>>>> started updateMatterSoaTrustLedgerConfig  %s', matter.matterRecordNumber);
                this.soaTrustLedgerHelperService.updateMatterSoaTrustLedgerConfig(statementConfigs, documentProfile, soaFeeRates, matter, soaExportConfig, jurisdictionDepartments, interimSoaExportConfig, true).then( ()=> {
                    initMatterSubject.next(true);
                    initMatterSubject.complete();
                });

            }
            console.log('>>>> finished result processing init  %s', matter.matterRecordNumber);

        });
        return initMatterSubject;
    }

    addCallsForNonOpportunityMatters(obsMatterArray: any[], matter: Matter, accountId: string): void {
        if (!matter.defaultSupplementalTasksAdded && !matter.isMatterExisting()) {
            obsMatterArray.push(this.supplementalTaskService.getSupplementalTaskCategories(accountId, matter.provinceCode).catch(error => Observable.of(error)));
        }
        // Tax Rate Service
        obsMatterArray.push(this.taxRateService.cachedConsiderationTaxRate(matter.provinceCode).catch(error => Observable.of(error)));
        obsMatterArray.push(this.taxRateService.getTarionWarrantyEnrolmentPeriods().catch(error => Observable.of(error)));
        obsMatterArray.push(this.taxRateService.getHCRAFeeEnrolmentPeriods().catch(error => Observable.of(error)));
        obsMatterArray.push(this.taxRateService.getRentInterestRates(matter.provinceCode).catch(error => Observable.of(error)));
        // Commision Settings
        if (!matter.commissionPaidTo) {
            obsMatterArray.push(this.accountService.getAccountProvince(accountId, matter.provinceCode).catch(error => Observable.of(error)));
        }
        // Get jurisdiction department
        obsMatterArray.push(this.jurisdictionDepartmentsService.getDepartmentPairs(null, matter.provinceCode).catch(error => Observable.of(error)));

        // Get Soa Fee Rate
        obsMatterArray.push(this.taxRateService.getSoaFeesRates(matter.getSoaTaxRateEffectiveDate(), matter.provinceCode).catch(error => Observable.of(error)));

        // Get Law Clerk
        if (matter.lawClerk) {
            obsMatterArray.push(this.getContactForMatter(matter.lawClerk.contactReferenceId).catch(error => Observable.of(error)));
        } else if (matter.id == 0 && matter.selectedLawClerkId) { // this is for initialization of new matter with default law clerk defined at user level
            obsMatterArray.push(this.getContactForMatter(matter.selectedLawClerkId).catch(error => Observable.of(error)));
        }
        // Get witness
        if (matter.witness) {
            obsMatterArray.push(this.getContactForMatter(matter.witness.contactReferenceId).catch(error => Observable.of(error)));
        }
        // Get witness
        if (matter.commissioner) {
            obsMatterArray.push(this.getContactForMatter(matter.commissioner.contactReferenceId).catch(error => Observable.of(error)));
        }
        // Get Docket Value and Set it in Current matter
        if (!matter.teraviewConfig) {
            obsMatterArray.push(this.docketService.getTeraviewConfig(accountId).catch(error => Observable.of(error)));
        }
        // setup ec, Cc and Elite fee default
        obsMatterArray.push(this.titleInsuranceConfigurationService.getTitleInsuranceConfiguration(accountId).catch(error => Observable.of(error)));

        obsMatterArray.push(this.userDefinedFieldService.getUserDefinedFieldsForLoggedInCustomerAccount(matter.derivedMatterType, matter.provinceCode).catch(error => Observable.of(error)));

        if (matter.project && matter.project.templateMatterId) {
            obsMatterArray.push(this.projectMatterCacheService.getMatter(matter.project.templateMatterId).catch(error => Observable.of(error)));
        }

        //undertaking config
        obsMatterArray.push(this.undertakingsConfigService.getUndertakingsConfig(accountId, matter.provinceCode).catch(error => Observable.of(error)));
    }

    setUpNewMatter(matter: Matter, supplementalTaskCategories: SupplementalTaskCategory[], documentProfile: DocumentProfile, considerationTaxes: ConsiderationTaxes[]): void {

        if (matter.isProjectSale) {
            const templateMatter: Matter = this.getTemplateMatterForProject(matter);
            if (templateMatter) {
                matter.statementOfAdjustmentPayable = new StatementOfAdjustmentPayable(templateMatter.statementOfAdjustmentPayable);
                matter.statementOfAdjustmentPayable.id = null;
            }
        } else {
            // resetStatementOfAdjustmentPayable
            matter.resetStatementOfAdjustmentPayable(documentProfile, this.documentProfileCache.cachedDefaultDocumentProfile);
        }

        //Supplemental Task
        if (this.activeMatterTab && !this.activeMatterTab.isMassUpdateSubType()) {
            this.addDefaultSupplementalTasks(matter, supplementalTaskCategories, documentProfile);
        }


        if (documentProfile) {
            this.initSoaHeading(matter, documentProfile);
            this.initPaysForDateOfClosing(matter, documentProfile);
        }

        this.checkIfUpdateTitleInsuranceRequired(matter, this.errorService);
        this.checkIfUpdateAltoEFormsRequired(matter, this.errorService);
        if (!matter.uffiWarrantyCode) {
            matter.uffiWarrantyCode = documentProfile && documentProfile.miscDocumentProfile.uffiWarrantyCode;
        }
        if (matter.project) {
            matter.updateStatusMode(ProgressionStatus.FINAL);
        }

        if (matter.isMatterProvinceABorMBorSK) {
            if (matter.isSale) {
                matter.paymentAmountsDisplayed = true;
            } else if (matter.isMortgage) {
                matter.paymentAmountsDisplayed = false;
            }
        }

        if (matter.isMatterProvinceNB && (matter.isPurchase || matter.isSale) && (!matter.matterUndertakings || matter.matterUndertakings.length == 0)) {
            let undertakingsConfig: UndertakingsConfig = this.undertakingsConfigService.getCachedConfig(matter.provinceCode);
            if (undertakingsConfig) {
                if (undertakingsConfig.nonEncumbranceUndertakingsConfiguration) {
                    let nonEncumbranceUndertaking = new MatterUndertaking();
                    nonEncumbranceUndertaking.subject = MatterUndertakingNBDefaultConfig.Non_Encumbrance_Undertakings;
                    nonEncumbranceUndertaking.description = undertakingsConfig.nonEncumbranceUndertakingsConfiguration.nonEncumbranceUndertakingsText
                    nonEncumbranceUndertaking.matterUndertakingStatus = <MatterUndertakingStatus>MatterUndertakingStatusValues.not_approved;
                    nonEncumbranceUndertaking.approvedForSoAdjFlag = false;
                    matter.matterUndertakings.push(nonEncumbranceUndertaking);
                }
                if (undertakingsConfig.acknowledgementsConfiguration) {
                    let acknowledgmentUndertakings = new MatterUndertaking();
                    acknowledgmentUndertakings.subject = MatterUndertakingNBDefaultConfig.Acknowledgments;
                    acknowledgmentUndertakings.description = undertakingsConfig.acknowledgementsConfiguration.acknowledgementsText
                    acknowledgmentUndertakings.matterUndertakingStatus = <MatterUndertakingStatus>MatterUndertakingStatusValues.not_approved;
                    acknowledgmentUndertakings.approvedForSoAdjFlag = false;
                    matter.matterUndertakings.push(acknowledgmentUndertakings);
                }
            }
        }
    }


    initSoaHeading(matter: Matter, documentProfile: DocumentProfile): void {
        if (documentProfile != null && documentProfile.statementOfAdjustmentsProfile != null) {
            if (documentProfile.statementOfAdjustmentsProfile.sameAsDefaultProfileFlag) {
                let defaultDocProfile = this.documentProfileCache.cachedDefaultDocumentProfile;
                if (defaultDocProfile != null && defaultDocProfile.statementOfAdjustmentsProfile != null) {
                    matter.createStatementAdjustmentHeading(defaultDocProfile, matter.isProjectSale, true);
                }
            } else {
                matter.createStatementAdjustmentHeading(documentProfile, matter.isProjectSale, true);
            }
        }
    }

    initPaysForDateOfClosing(matter: Matter, documentProfile: DocumentProfile): void {
        if (documentProfile != null && documentProfile.statementOfAdjustmentsProfile != null) {
            if (documentProfile.statementOfAdjustmentsProfile.sameAsDefaultProfileFlag) {
                let defaultDocProfile = this.documentProfileCache.cachedDefaultDocumentProfile;
                if (defaultDocProfile != null && defaultDocProfile.statementOfAdjustmentsProfile != null) {
                    matter.paysForDateOfClosing = defaultDocProfile.statementOfAdjustmentsProfile.paysForDateOfClosing;
                }
            } else {
                matter.paysForDateOfClosing = documentProfile.statementOfAdjustmentsProfile.paysForDateOfClosing ;
            }
        }
    }

    initializeDirectDepositInstructions(matter: Matter): void {
        if (!Array.isArray(matter.directDepositInstructions)) {
            matter.directDepositInstructions = [];
        }

        if (matter.directDepositInstructions.length === 0) {
            this.addDirectDepositInstruction('VENDOR', matter);
            this.addDirectDepositInstruction('PURCHASER', matter);
        }
    }

    setUpTaxRateOnMatter(matter: Matter, considerationTaxes: ConsiderationTaxes[]): void {
        this.taxRateService.setUpTaxRateOnMatter(matter, considerationTaxes);
    }


    addDirectDepositInstruction(directDepositType: string, matter: Matter): void {
        const directDepositInstruction: DirectDepositInstruction = new DirectDepositInstruction();
        directDepositInstruction.directDepositType = directDepositType;
        directDepositInstruction.directDepositChoice = 'N_y';
        matter.directDepositInstructions.push(directDepositInstruction);
    }


    populateJurisdictionData(matter: Matter, accountId: string, currentDocumentProfile: DocumentProfile): void {
        let jurisdictionId: number;
        let jurisdictionName: string;
        if (!matter.purchaserExecDocsAt) {
            if (!currentDocumentProfile || !currentDocumentProfile.firmDocumentProfile || currentDocumentProfile.firmDocumentProfile.sameAsDefaultProfileFlag) {
                this.documentProfileCache.getDefault(accountId).subscribe(defaultDocumentProfile => {
                    if (defaultDocumentProfile.firmDocumentProfile.jurisdictionId) {
                        jurisdictionId = defaultDocumentProfile.firmDocumentProfile.jurisdictionId;
                        jurisdictionName = defaultDocumentProfile.firmDocumentProfile.jurisdictionName;
                        let selectedJurisdiction =
                            new Jurisdiction({
                                jurisdictionName: jurisdictionName,
                                id: jurisdictionId
                            } as Jurisdiction);
                        matter.purchaserExecDocsAt = selectedJurisdiction.jurisdictionName;
                        matter.jurisdictionId = selectedJurisdiction.id;
                    }
                });
            } else {
                if (currentDocumentProfile.firmDocumentProfile.jurisdictionId) {
                    let selectedJurisdiction =
                        new Jurisdiction({
                            jurisdictionName: currentDocumentProfile.firmDocumentProfile.jurisdictionName,
                            id: currentDocumentProfile.firmDocumentProfile.jurisdictionId
                        } as Jurisdiction);
                    matter.purchaserExecDocsAt = selectedJurisdiction.jurisdictionName;
                    matter.jurisdictionId = selectedJurisdiction.id;
                }
            }
        }
    }


    getDeductEcCcEliteFeeFromSoa(matter: Matter, titleInsuranceConfiguration: TitleInsuranceConfiguration) {
        if (titleInsuranceConfiguration && titleInsuranceConfiguration.id) {
            matter.deductEcFeeFromSoa = !titleInsuranceConfiguration.doNotDetectECFee; // opposite of what it is because flag var is opposite
            matter.deductEliteFeeFromSoaTrustLedger = !titleInsuranceConfiguration.doNotDetectEliteFee; // opposite of what it is because flag var is opposite
            matter.deductCcFeeFromSoaTrustLedger = !titleInsuranceConfiguration.doNotDetectConnectingCounselFee; // opposite of what it is because flag var is opposite
            matter.deductLegalCounselFee = !titleInsuranceConfiguration.doNotDeductLegalCounselFee;
        }

    }

    addDefaultSupplementalTasks(matter: Matter, data: SupplementalTaskCategory[], documentProfile: DocumentProfile): void {
        if (data) {
            data.filter(item => item.applicableMatterTypeCodes.indexOf(matter.derivedMatterType) > -1 && item.autoInsert === 'YES').forEach(item => {
                if (matter.supplementalTasks && Array.isArray(matter.supplementalTasks) &&
                    matter.supplementalTasks.length > 0 && matter.supplementalTasks.some(stc => stc.categoryName == item.categoryName)) {
                    //During MassOpen operation, the new created matter is based on existing matter, it may already contains STC
                    //we want to add the missing default STC, but don't want to add the duplicate one
                    return;
                }
                const matterSupplementalTaskCategory: MatterSupplementalTaskCategory
                    = MatterSupplementalTaskCategory.createFromDefaultCategory(item, documentProfile);
                MatterSupplementalTaskCategory.setOrderNumber(matterSupplementalTaskCategory, matter);
                matterSupplementalTaskCategory.showPaymentAmounts = showPaymentAmountsInitValue[matter.provinceCode];
                matter.supplementalTasks.push(matterSupplementalTaskCategory);
                matter.defaultSupplementalTasksAdded = true;
            });
        }

    }

    createMatterTopicsForNewMatter(matter: Matter): void {
        matter.matterTopics = [];
        this.applicableSections(matter).forEach(section => {
            let matterTopicInfo = new MatterTopic();
            matterTopicInfo.topicStatus = 'QUESTION';
            matterTopicInfo.matterTopicKey = section.sectionKey;
            matter.matterTopics.push(matterTopicInfo);
        });
    }


    applicableSections(matter: Matter): Section[] {
        return matter ? matter.applicableSections : [];
    }

    initMatterDataProvinceSpecific(matter: Matter): void {

        if (matter && matter.provinceCode) {
            switch (matter.provinceCode) {
                case 'AB' : {
                    if (!matter.actingFor) {
                        if (matter.matterType == 'PURCHASE') {
                            matter.actingFor = 'PURCHASER_ONLY';
                        }
                        if (matter.matterType == 'SALE') {
                            matter.actingFor = 'VENDOR_ONLY';
                        }
                    }
                    matter.protocolClosing = 'UNDETERMINED';
                    matter.purchaserFinancing = 'NEW_MORTGAGE';

                    matter.numberOfParcels = 1; //ToDo change this when SPIN gets implemented
                    break;
                }
                case 'MB': {
                    //interestRateSummary N/A
                    matter.protocolClosing = 'UNDETERMINED';
                    break;
                }
                case 'SK': {
                    matter.interestRateSummary = matter.isMortgage ? '' : 'Bank of Canada Overnight Target Rate + 4%';
                    matter.protocolClosing = 'NO';
                    break;
                }
            }
        }
    }


    private initPurchaseReport(matter: Matter): void {
        if (!matter.reportToPurchaser) {
            matter.reportToPurchaser = new PurchaserReport();
        }
        if (!matter.reportToPurchaser.commonExpensesPaidAmount || matter.reportToPurchaser.commonExpensesPaidAmount == null) {
            matter.reportToPurchaser.commonExpensesPaidAmount = 0;
        }
        if (!matter.reportToPurchaser.detailsIncludedOfUnfulfilledUndertaking || matter.reportToPurchaser.detailsIncludedOfUnfulfilledUndertaking == null) {
            if (matter.isMatterProvinceMBorSK) {
                matter.reportToPurchaser.detailsIncludedOfUnfulfilledUndertaking = DpBooleanValueTypes.Y_n;
            } else {
                matter.reportToPurchaser.detailsIncludedOfUnfulfilledUndertaking = DpBooleanValueTypes.N_y;
            }

        }
        if (!matter.reportToPurchaser.isPropertyTaxInfoInReport || matter.reportToPurchaser.isPropertyTaxInfoInReport == null) {
            if (matter.isMatterProvinceMBorSK) {
                matter.reportToPurchaser.isPropertyTaxInfoInReport = DpBooleanValueTypes.Y_n;
            } else {
                matter.reportToPurchaser.isPropertyTaxInfoInReport = DpBooleanValueTypes.N_y;
            }

        }
        if (!matter.reportToPurchaser.moniesPaidFromTrustForTaxes || matter.reportToPurchaser.moniesPaidFromTrustForTaxes == null) {
            matter.reportToPurchaser.moniesPaidFromTrustForTaxes = DpBooleanValueTypes.N_y;
        }
        if (!matter.reportToPurchaser.supplementaryTaxBill || matter.reportToPurchaser.supplementaryTaxBill == null) {
            matter.reportToPurchaser.supplementaryTaxBill = DpBooleanValueTypes.N_y;
        }
        if (!matter.reportToPurchaser.detailsOfPermittedRegistrations || matter.reportToPurchaser.detailsOfPermittedRegistrations == null) {
            matter.reportToPurchaser.detailsOfPermittedRegistrations = DpBooleanValueTypes.N_y;
        }
        if (!matter.reportToPurchaser.vendorPaidEntireTaxBill || matter.reportToPurchaser.vendorPaidEntireTaxBill == null) {
            matter.reportToPurchaser.vendorPaidEntireTaxBill = DpBooleanValueTypes.N_y;
        }
        if (!matter.reportToPurchaser.taxPaymentAmount || matter.reportToPurchaser.taxPaymentAmount == null) {
            matter.reportToPurchaser.taxPaymentAmount = 0;
        }
        if (!matter.reportToPurchaser.titleInsurancePolicy || matter.reportToPurchaser.titleInsurancePolicy == null) {
            matter.reportToPurchaser.titleInsurancePolicy = 'ENCLOSED_REPORT';
        }
        if (!matter.reportToPurchaser.mostRecentTaxBill || matter.reportToPurchaser.mostRecentTaxBill == null) {
            matter.reportToPurchaser.mostRecentTaxBill = '??????';
        }
        if (!matter.reportToPurchaser.reFuturePayments || matter.reportToPurchaser.reFuturePayments == null) {
            matter.reportToPurchaser.reFuturePayments = 'QUESTION';
        }
        if (!matter.reportToPurchaser.provideDetailedExplanationOfSoAdj) {
            matter.reportToPurchaser.provideDetailedExplanationOfSoAdj = DpBooleanValueTypes.N_y;
        }
    }

    setUpStatementAdjustment(matter: Matter): void {
        if (!matter.considerationLtt) {
            matter.createNewConsiderationLtt();
            matter.considerationLtt.salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(matter.adjustmentStatusMode, matter.provinceCode);
        }

        if (matter.statementOfAdjustments && matter.statementOfAdjustments.length === 0 && !matter.isProjectSale) {
            matter.createUpdateSalePriceAdjustment();
            matter.createUpdateDepositAdjustment();
        }

        if (!matter.statementOfAdjustmentDisplay) {
            this.updateStatementAdjustmentDisplayItems(matter);
        }
    }

    updateStatementAdjustmentDisplayItems(matter: Matter): void {

        let orgAdjustmentMode = matter.adjustmentStatusMode;
        if (matter.adjustmentStatusMode != matter.selectedProgressionStatus && (matter.isProjectSale || matter.isPurchaseMatterLinkedProjectSale())) {
            matter.adjustmentStatusMode = matter.selectedProgressionStatus;
        }
        let soAdjDisplayUtil = new StatementAdjustmentDisplayBuilder(this.decimalPipe, this.currencyPipe, this.percentPipe);
        soAdjDisplayUtil.documentProfileCache = this.documentProfileCache;
        soAdjDisplayUtil.setMatter(matter);
        soAdjDisplayUtil.setSoaConsiderationTaxes(this.createConsiderationTaxes(matter));
        soAdjDisplayUtil.setTarionWarrantyEnrolmentPeriods(this.taxRateService.getCachedTarionWarrantyEnrolmentPeriods());
        soAdjDisplayUtil.setHCRAFeeEnrolmentPeriods(this.taxRateService.getCachedHCRAFeeEnrolmentPeriods());
        // TODO: we need to create a common method in any service to create builder instance ...because there might other place people creating this instance and forget to call this methods
        soAdjDisplayUtil.setRentInterestRates(this.taxRateService.getCachedRentInterestRatesSorted(soAdjDisplayUtil.matter.provinceCode));

        soAdjDisplayUtil.updateStatementOfAdjustmentDisplayItems();
        soAdjDisplayUtil.updateStatementOfAdjustmentDisplayBalanceItems();

        if (!matter.statementOfAdjustmentDisplay) {
            matter.statementOfAdjustmentDisplay = new StatementOfAdjustmentDisplay();
        }

        matter.statementOfAdjustmentDisplay.updateData(soAdjDisplayUtil);
        matter.adjustmentStatusMode = orgAdjustmentMode;
    }

    // ConsiderationTaxes is needed in the soAdjDisplayUtil.populateTotalCreditAdjustedForRebate() to obtain Federal and Provincial Rebate amounts.
    // if matter doesn't have a soaHst at this point then the rebates will be zero.
    createConsiderationTaxes(matter: Matter): ConsiderationTaxes {

        let soaConsiderationTaxes = new ConsiderationTaxes();
        soaConsiderationTaxes.hstRate = (matter.soaHst) ? matter.soaHst : matter.matterHst;
        soaConsiderationTaxes.hstFederalPortion = (matter.soaFederalHst) ? matter.soaFederalHst : matter.matterFederalHst;
        soaConsiderationTaxes.hstProvincialPortion = (matter.soaProvincialHst) ? matter.soaProvincialHst : matter.matterProvincialHst;
        soaConsiderationTaxes.rateType = matter.matterTaxType;

        return soaConsiderationTaxes;
    }


    checkIfUpdateTitleInsuranceRequired(matter: Matter, customErrorService: ErrorService): void {
        if (matter.matterTitleInsurance && matter.matterTitleInsurance.titleInsuranceUpdateRequired) {
            if (!matter.matterTitleInsurance.isOrderStatusCancelled()) {
                let errorKey: string = 'notification.titleInsurance.updateRequired';
                customErrorService.removeDpThirdPartyNotification(errorKey);
                let errorMsg: string = '';
                let errorMsgTopic: string = '';
                if (matter.isInsurerFCT()) {
                    errorMsg = this.buildSubmitUpdateToTIRequiredMsg('FCT');
                    errorMsgTopic = 'FCT Title Insurance';
                } else if (matter.isInsurerChicagoTitle()) {
                    errorMsg = this.buildSubmitUpdateToTIRequiredMsg('Chicago Title');
                    errorMsgTopic = 'Chicago Title';
                }
                customErrorService.addDpThirdPartyNotification(DPError.createCustomDPError(errorKey, errorMsg, errorMsgTopic, "NOTIFICATION"));
            }
        }
    }

    checkIfUpdateAltoEFormsRequired(matter: Matter, customErrorService: ErrorService): void {
        this.checkIfUpdateAltoRequired(matter, EFormTypes.UNIVERSAL_FORM, 'Universal eForm', 'notification.altoUniversalForms.updateRequired', customErrorService);
        this.checkIfUpdateAltoRequired(matter, EFormTypes.TRANSFER_OF_LAND, 'Transfer of Land (eTFLA)', 'notification.altoTOLForms.updateRequired', customErrorService);
    }

    checkIfUpdateAltoRequired(matter: Matter, eFormType: EFormType, formTypeTxt: string, errorKey: string, customErrorService?: ErrorService): void {
        let altoEFormStaleFlag = matter.altoEFormStaleFlags && matter.altoEFormStaleFlags.find(item => item.eFormType == eFormType);
        if (altoEFormStaleFlag) {
            customErrorService.removeDpThirdPartyNotification(errorKey);
            let someFormsNeedUpdate = matter.altoEForms && matter.altoEForms.some(item =>
                item.eFormType == eFormType &&
                !!item.eFormIdentifier &&
                Number(item.lastUpdatedDate) < Number(altoEFormStaleFlag.unityDataUpdatedDate) &&
                !item.sent);
            if (someFormsNeedUpdate) {
                let errorMsg: string = `ALTO eForm - ${formTypeTxt} – Relevant data contained in the matter has been modified. Please submit the updates to ALTO.`;
                let errorMsgTopic: string = 'Forms';
                customErrorService.addDpThirdPartyNotification(DPError.createCustomDPError(errorKey, errorMsg, errorMsgTopic, "NOTIFICATION"));

            }
        }

    }


    buildSubmitUpdateToTIRequiredMsg(titleInsurer: string) {
        return `Relevant data contained in the matter has been modified since being submitted to ${titleInsurer}. Please submit the updates to ${titleInsurer}.`;
    }


    bulkMatterUpdate(bulkMatterUpdate: BulkMatterUpdate , saveAsync?: boolean): Observable<Matter[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = saveAsync ? matterApi.asyncBulkUpdateForMatters(accountId) : matterApi.bulkUpdateForMatters(accountId);
        if (bulkMatterUpdate.matters && bulkMatterUpdate.matters.length == 2 && bulkMatterUpdate.matters.some(matter => (matter.isPurchase || matter.isSale) && !matter.isProjectSale && !!matter.matterLink && (!matter.adjustments || matter.adjustments.length == 0))) {
            this.globalLogger.log(LogLevelTypes.WARN, 'Matter Service  : Update Matter called with no Adjustment Data for Linked Matter id: ' + bulkMatterUpdate.matters[0].id);
            this.dialogService.confirm('Error', 'An unexpected error occurred and your matter cannot be saved. Please close and re-open the matter to continue editing it.', true).subscribe((response: any) => {
            });
        } else {

            if (this.appConfig.isMatterSaveStringified && Array.isArray(bulkMatterUpdate.matters)) {
                let bulkMattersToUpdate: any[] = [];
                bulkMatterUpdate.matters.forEach(matter => bulkMattersToUpdate.push(JSON.parse(JSON.stringify(matter))));
                if (Array.isArray(bulkMattersToUpdate) && bulkMattersToUpdate.length > 0) {
                    bulkMatterUpdate.matters = [];
                    bulkMattersToUpdate.forEach(matter => MatterCleanUpUtil.cleanupUIOnlyFields(matter));
                    bulkMatterUpdate.matters = bulkMattersToUpdate;
                }
            }
            else if(Array.isArray(bulkMatterUpdate.matters)){
                bulkMatterUpdate.matters.forEach(matter => MatterCleanUpUtil.cleanupUIOnlyFields(matter));
            }

            return this.http.put(url, bulkMatterUpdate)
                .map((res) => {
                    return res[matterResponseKey.matters].map((item) => {
                        return new Matter(item);
                    });
                });
        }
    }

    validateMatter(matter: Matter, newMatterRecordNumber?: string, customErrorService?: ErrorService): Observable<boolean> {
        let matterRecodeNumber = newMatterRecordNumber ? newMatterRecordNumber : matter.matterRecordNumber;
        let errorService = customErrorService ? customErrorService : this.errorService;
        // Validate Matter Object for any errors or warnings
        if (MatterErrorUtil.anyErrorsExistOnMatter(matter, errorService, this.matterParticipantService, this.appConfig)) {
            this.errorService.openErrorFooterNotification();
            return Observable.of(false);
        } else if (matter.id && !newMatterRecordNumber) {
            return Observable.of(true);
        } else {
            // if it passes validation , check matter record number duplication
            errorService.removeDpSaveError("matter.matterOpening.uniqueMatterNo");
            return this.getMatterByMatterRecordNumber(matterRecodeNumber, true, matter.unityProjectId, matter.isOpportunityMatter())
                .map(
                    (matters: any) => {
                        if (matters.length > 0) {
                            errorService.addDpSaveError(DPError.createDPError("matter.matterOpening.uniqueMatterNo"));
                            this.errorService.openErrorFooterNotification();
                            return false;
                        } else {
                            return true;
                        }
                    }, (error: ApplicationError) => {
                        this.handleApiErrors(error, customErrorService);
                        return false;
                    });
        }

    }

    dirtyCheckBeforeMatterUpdate(matter: Matter): void {
        matter.ignoreSupplementalTasks = false;
        matter.ignoreMortgages = false;
        if (this.activeMatterTab.backEndMatter) {
            matter.ignoreSupplementalTasks = !this.dpDirtyCheckService.checkDirty(this.activeMatterTab.backEndMatter.supplementalTasks, matter.supplementalTasks);
            this.clearExistingMortgageMapping(this.activeMatterTab.backEndMatter);
            matter.ignoreMortgages = !this.dpDirtyCheckService.checkDirty(this.activeMatterTab.backEndMatter.mortgages, matter.mortgages);
        }
            if (matter.empMortgages && matter.empMortgages.length > 0) {
                matter.empMortgages.forEach(item => {
                    if (item.id) {
                        if (this.activeMatterTab.backEndMatter && this.activeMatterTab.backEndMatter.empMortgages && this.activeMatterTab.backEndMatter.empMortgages.length > 0) {
                            let backEndEmpMortgage = this.activeMatterTab.backEndMatter.empMortgages.find(empMortgage => empMortgage.id == item.id);
                            if (backEndEmpMortgage) {
                                if (!item.stewartAssystMortgageInstruction.isFctInstruction && (item.stewartAssystMortgageInstruction.isInstructionClosedByLender || item.stewartAssystMortgageInstruction.isInstructionCancelled)) {
                                    item.mortgageInstructionDataChanged = false;
                                } else {
                                    item.mortgageInstructionDataChanged = this.dpDirtyCheckService.checkDirty(backEndEmpMortgage.stewartAssystMortgageInstruction.reconciledBorrowers, item.stewartAssystMortgageInstruction.reconciledBorrowers);
                                }
                            }
                        } else {
                            item.mortgageInstructionDataChanged = true;
                        }
                    } else {
                        item.mortgageInstructionDataChanged = true;
                    }
                });
            }

    }

    clearExistingMortgageMapping(matter: Matter): void {
        if (matter && matter.existingMortgages && matter.existingMortgages.length > 0) {
            if (matter.mortgages) {
                matter.mortgages.push(...matter.existingMortgages);
            } else {
                matter.mortgages = [];
                matter.mortgages.push(...matter.existingMortgages);
            }
        }
    }

    /**
     * Reverting any private copies created by user that have no changes. Though source contact will be still unlocked with matter update
     * @param {Matter} matter
     */
    revertUneditedPrivateCopies(matter: Matter, errorService: ErrorService): void {
        errorService.removeDpfieldErrorByCoreErrorElementKey('contact.uneditedProxy');
        if (Array.isArray(matter.matterParticipants) && matter.matterParticipants.length > 0) {
            matter.matterParticipants.forEach((matterParticipant: MatterParticipant) => {
                if (matterParticipant.sourceContact && matterParticipant.contact.isDirty && matterParticipant.sourceContactLockAcquired
                    && matterParticipant.sourceContact.proxyEdited && !matterParticipant.sourceContact.isContactDataChanged(matterParticipant.contact)) {
                    matterParticipant.contact.isDirty = false;
                    matterParticipant.sourceContact.proxyEdited = false;

                    errorService.addDpFieldError(DPError.createCustomDPError('contact.uneditedProxy' + matterParticipant.matterParticipantId,
                        `Your Private Copy of the ${matterParticipantRoleLabels[matterParticipant.matterParticipantRole]} "${matterParticipant.contact.fullName}" is identical to the Global record.`,
                        this.getErrorTopic(matter, matterParticipant), 'WARNING'));
                }
            });
        }
    }

    getMortgageErrorTopic(matter: Matter, matterParticipant: MatterParticipant): string {
        let mortgageIndex = matter.mortgages.findIndex(item => item.id === matterParticipant.mortgageId);
        if (mortgageIndex > -1) {
            return SharedUtils.getDisplayOrder(mortgageIndex + 1) + ' Mortgage';
        } else {//
            let mortgage: Mortgage = matter.existingMortgages.find(item => item.id === matterParticipant.mortgageId);
            return mortgage ? (SharedUtils.getDisplayOrder(mortgage.mortgagePriority) + ' Existing Mortgage') : '';
        }
    }

    getErrorTopic(matter: Matter, matterParticipant: MatterParticipant): string {
        if (matter && matterParticipant) {
            switch (matterParticipant.matterParticipantRole) {
                case 'INSURER':
                case 'BROKER':
                    return 'Fire Insurance';
                case 'REALESTATEBROKER':
                    return 'RE Broker / Commission';
                case 'MORTGAGEE':
                case 'MORTGAGE_SOLICITOR':
                case 'MORTGAGE_LEGAL_FIRM':
                    return this.getMortgageErrorTopic(matter, matterParticipant);
                case 'OTHERPARTY_SOLICITOR':
                case 'OTHERPARTY_LAW_FIRM':
                    switch (matter.matterType) {
                        case MatterTypesValue.PURCHASE:
                            return 'Vendors & Solicitor';
                        case MatterTypesValue.SALE:
                            return 'Purchasers & Solicitor';
                        case MatterTypesValue.MORTGAGE:
                            return 'Other Solicitor';
                        case MatterTypesValue.CUSTOM:
                            return 'Other Side';
                        default:
                            return 'Error';
                    }
                default:
                    return 'Error';
            }
        }
    }

    checkAltoEFormFlags(matter: Matter): void {
        this.checkAltoEFormTypeFlags(matter, EFormTypes.UNIVERSAL_FORM, AltoUniversalEformsXpathConfigs.xPathConfigs);
        this.checkAltoEFormTypeFlags(matter, EFormTypes.TRANSFER_OF_LAND, AltoTOLEformsXpathConfigs.xPathConfigs);
    }

    checkAltoEFormTypeFlags(matter: Matter, eformType: EFormType, xPathConfigs: any[]): void {
        if (matter.altoEForms && matter.altoEForms.length) {
            if (matter.altoEForms.some(item => item.eFormType == eformType && !!item.eFormIdentifier)) {
                if (matter.altoEFormStaleFlags && !matter.altoEFormStaleFlags.find(flag => flag.eFormType == eformType)) {
                    let altoEformsUpdateRequired: boolean = false;
                    if (eformType == EFormTypes.UNIVERSAL_FORM) {
                        altoEformsUpdateRequired = this.isUpdateAltoUniversalEformsRequired(matter);
                    } else if (eformType == EFormTypes.TRANSFER_OF_LAND) {
                        altoEformsUpdateRequired = this.isUpdateAltoTOLEformsRequired(matter);
                    }
                    if (altoEformsUpdateRequired) {
                        let altoEFormStaleFlag = new AltoEFormStaleFlag();
                        altoEFormStaleFlag.eFormType = eformType;
                        altoEFormStaleFlag.unityDataUpdatedDate = (new Date()).getTime().toString();
                        matter.altoEFormStaleFlags.push(altoEFormStaleFlag);
                    }
                }
            }
        }
    }

    isUpdateAltoUniversalEformsRequired(matter: Matter): boolean {
        return this.isUpdateThirdPartyRequired(matter, AltoUniversalEformsXpathConfigs.xPathConfigs);
    };

    isUpdateAltoTOLEformsRequired(matter: Matter): boolean {
        let updateRequired = this.isUpdateThirdPartyRequired(matter, AltoTOLEformsXpathConfigs.xPathConfigs);
        if (!updateRequired) {
            if (matter.matterContactInfo && matter.matterContactInfo.residingAtSubjectProperty == DpBooleanValueTypes.NO) {
                updateRequired = this.isUpdateThirdPartyRequired(matter, AltoTOLEformsServiceAddressXpathConfigs.xPathConfigs);
            } else {
                updateRequired = this.isUpdateThirdPartyRequired(matter, AltoTOLEformsPropertyAddressXpathConfigs.xPathConfigs);
            }
        }
        return updateRequired;
    };


    isUpdateThirdPartyRequired(matter: Matter, xPathConfigs: any[]): boolean {
        let updateRequired: boolean = false;

        let oldMatter: Matter = this.activeMatterTab ? this.activeMatterTab.backEndMatter : null;

        if (oldMatter) {
            for (let i = 0; i < xPathConfigs.length; i++) {
                if (xPathConfigs[i].matterType.indexOf(matter.matterType ? matter.matterType.charAt(0) : '') != -1) {
                    console.log('currentMater: ' + xPathConfigs[i].xpath + ' :' + jp.value(matter, xPathConfigs[i].xpath));
                    console.log('OldMatter: ' + xPathConfigs[i].xpath + ' :' + jp.value(oldMatter, xPathConfigs[i].xpath));
                    if (xPathConfigs[i].xpathType === 'Object' && (jp.value(matter, xPathConfigs[i].xpath) != jp.value(oldMatter, xPathConfigs[i].xpath))
                        && !(!jp.value(matter, xPathConfigs[i].xpath) && !jp.value(oldMatter, xPathConfigs[i].xpath))) {
                        console.log('Data Modified At object: ' + xPathConfigs[i].xpath);
                        updateRequired = true;
                        break;
                    } else if (xPathConfigs[i].xpathType === 'List') {
                        let currentMatterData: any[] = jp.query(matter, xPathConfigs[i].xpath);
                        let oldMatterData: any[] = jp.query(oldMatter, xPathConfigs[i].xpath);
                        console.log('currentMater: ' + xPathConfigs[i].xpath + ' :' + currentMatterData);
                        console.log('OldMatter: ' + xPathConfigs[i].xpath + ' :' + oldMatterData);
                        if (this.shouldCompareLists(currentMatterData, oldMatterData)) {
                            if (currentMatterData && oldMatterData) {
                                if (currentMatterData.length !== oldMatterData.length) {
                                    console.log('Data Modified - Data Array Lengths Differ: ' + xPathConfigs[i].xpath);
                                    updateRequired = true;
                                    break;
                                } else {
                                    for (let j = 0; !updateRequired && j < currentMatterData.length; j++) {
                                        if (JSON.stringify(currentMatterData[j]) !== JSON.stringify(oldMatterData[j]) && !(!currentMatterData[j] && !oldMatterData[j])) {
                                            console.log('Data Modified - Array Stringify Differ: ' + xPathConfigs[i].xpath);
                                            updateRequired = true;
                                            break;
                                        }
                                    }
                                    if (updateRequired) {
                                        break;
                                    }
                                }
                            } else if ((!currentMatterData && oldMatterData) || (currentMatterData && !oldMatterData)) {
                                console.log('Either old matter or current matter data undefined: ' + xPathConfigs[i].xpath);
                                updateRequired = true;
                                break;
                            }
                        }
                    } else if (xPathConfigs[i].xpathType === 'BooleanValue') {
                        let currentMatterData: string = jp.value(matter, xPathConfigs[i].xpath);
                        let oldMatterData: string = jp.value(oldMatter, xPathConfigs[i].xpath);
                        console.log('currentMater booleanType value: ' + xPathConfigs[i].xpath + ' :' + currentMatterData);
                        console.log('OldMatter booleanType value: ' + xPathConfigs[i].xpath + ' :' + oldMatterData);
                        if (!Utils.isSameBooleanValue(currentMatterData, oldMatterData)) {
                            updateRequired = true;
                            break;
                        }
                    }
                }
            }
        }
        return updateRequired;
    }

    shouldCompareLists(currentMatterData: any[], oldMatterData: any[]): boolean {
        let currentValue = currentMatterData && currentMatterData.find(item => !!item);
        let oldValue = oldMatterData && oldMatterData.find(item => !!item);
        return currentValue || oldValue;
    }

    checkTitleInsuranceUpdateFlags(matter: Matter, loggedUserAccount: Account): void {
        let titleInsuranceXpathConfigs: any[] = [];
        if (matter.matterTitleInsurance && matter.matterTitleInsurance.dealId && !matter.matterTitleInsurance.titleInsuranceUpdateRequired) {
            if (matter.isInsurerChicagoTitle()) {
                titleInsuranceXpathConfigs = ChicagoTitleXpathConfigs.xPathConfigs;
            } else if (matter.isInsurerFCT() && matter.matterTitleInsurance.invoiceOrderStatus &&
                !matter.matterTitleInsurance.isOrderStatusCancelled()) {
                titleInsuranceXpathConfigs = FctXpathConfigs.xPathConfigs;
            }
            matter.matterTitleInsurance.titleInsuranceUpdateRequired = this.isUpdateTitleInsuranceRequired(matter, titleInsuranceXpathConfigs, loggedUserAccount);
        }
    }

    public isUpdateTitleInsuranceRequired(matter: Matter, titleInsuranceXpathConfigs: any[], loggedUserAccount: Account): boolean {
        let titleInsuranceUpdateRequired: boolean = this.isUpdateThirdPartyRequired(matter, titleInsuranceXpathConfigs);
        if (matter.isInsurerChicagoTitle() && !titleInsuranceUpdateRequired) {
            titleInsuranceUpdateRequired = this.hasAccountInfoModifiedAfterChicagoSubmitFile(matter, loggedUserAccount);
        }

        return titleInsuranceUpdateRequired;
    }

    public hasAccountInfoModifiedAfterChicagoSubmitFile(matter: Matter, loggedUserAccount: Account): boolean {
        var chicagoLawFirm: ChicagoLawFirm = matter.matterTitleInsurance.lawFirmDataFromChicagoSubmitFileRequest;
        if (loggedUserAccount && loggedUserAccount.firmName !== chicagoLawFirm.name) {
            console.log("Firm name is Modified");
            return true;
        } else {
            if (loggedUserAccount && (loggedUserAccount.mailingAddress.addressLine1 !== chicagoLawFirm.address.address1 ||
                loggedUserAccount.mailingAddress.addressLine2 !== chicagoLawFirm.address.address2 ||
                loggedUserAccount.mailingAddress.provinceName !== chicagoLawFirm.address.province ||
                loggedUserAccount.mailingAddress.city !== chicagoLawFirm.address.city ||
                loggedUserAccount.mailingAddress.postalCode !== chicagoLawFirm.address.postalCode)) {
                console.log("Account Address is Modified");
                return true;
            }
        }
        return false;
    }

    get activeMatterTab(): MatterTab {
        return this.tabsService && this.tabsService.activeTab as MatterTab;
    }

    updateMultipleMatter(matters: Matter[], loggedUserAccount?: Account, customErrorService?: ErrorService ,  saveAsync?: boolean): Observable<Matter[]> {
        let bulkMatterUpdate: BulkMatterUpdate = new BulkMatterUpdate();
        if (matters) {
            bulkMatterUpdate.matters = matters;
            return this.updateMultipleMatterUnAssignOrCancelEMP(bulkMatterUpdate, loggedUserAccount, customErrorService, saveAsync).map(matters => {
                return matters;
            })
        } else {
            return Observable.of(undefined);
        }
    }

    updateMultipleMatterUnAssignOrCancelEMP(bulkMatterUpdate: BulkMatterUpdate, loggedUserAccount?: Account, customErrorService?: ErrorService ,saveAsync?: boolean): Observable<Matter[]> {
        let errorService = customErrorService ? customErrorService : this.errorService;
        if (bulkMatterUpdate.matters) {
            bulkMatterUpdate.matters.forEach(matter => {
                matter.cleanUpMatterBeforeSaving(this);
                if (matter.id && matter.id > 0) {
                    this.executePreMatterSaveSteps(matter, errorService, loggedUserAccount);
                }

            });
            let matterBackupMapForCleanupUIOnlyField = new Map<number, any>();
            bulkMatterUpdate.matters.forEach(matter => {
                matterBackupMapForCleanupUIOnlyField.set(matter.id, this.generateBackupMapForCleanupUIOnlyFields(matter));
            });
            return this.bulkMatterUpdate(bulkMatterUpdate, saveAsync).map(matters => {
                return matters;
            }).catch((error: any) => {
                this.handleApiErrors(error, errorService);
                bulkMatterUpdate.matters.forEach(matter => {
                    MatterCleanUpUtil.revertMatterCleanUp(matter, matterBackupMapForCleanupUIOnlyField.get(matter.id), this.appConfig);
                });
                return Observable.of(undefined);
            });
        }
    }


    updateMatterAfterSave(updatedMatter: Matter, errorService: ErrorService, adjustmentPropagationService?: AdjustmentPropagationService): Observable<Matter> {

        if (updatedMatter && updatedMatter.isProjectSale) {
            this.projectService.saveMatterFieldCode(updatedMatter);
            return this.initProjectSaleMatter(updatedMatter, adjustmentPropagationService).map(isMatterInitialized => {
                if (isMatterInitialized) {
                    updatedMatter.dirty = false;
                    this.statusBarService.currentHelpText = StatusBarMessages.matter.UPDATE;
                    this.saveMatterTabState(updatedMatter);
                    return updatedMatter;
                }
            }).catch((error: any) => {
                this.handleApiErrors(error, errorService);
                return Observable.of(undefined);

            });
        } else {
            return this.initMatter(updatedMatter).map(isMatterInitialized => {
                if (isMatterInitialized) {
                    updatedMatter.dirty = false;
                    this.statusBarService.currentHelpText = StatusBarMessages.matter.UPDATE;
                    this.saveMatterTabState(updatedMatter);
                    return updatedMatter;
                }
            }).catch((error: any) => {
                this.handleApiErrors(error, errorService);
                return Observable.of(undefined);

            });
        }


    }

    executePreMatterSaveSteps(matter: Matter, errorService: ErrorService, loggedUserAccount?: Account): void {
        this.dirtyCheckBeforeMatterUpdate(matter);

        this.checkTitleInsuranceUpdateFlags(matter, loggedUserAccount);
        this.checkAssystPayoutDataChange(matter);

        this.checkAltoEFormFlags(matter);
        //Validate and Update Ereg Forms Status
        EregistrationUtil.validateAndUpdateERegFormStatus(matter, errorService, null, this.getTemplateMatterForProject(matter));
        this.matterNotificationUtilService.calculateNotificationsStatuses(matter);
        this.revertUneditedPrivateCopies(matter, errorService);
    }



    checkAssystPayoutDataChange(matter:Matter, assystPayoutMortgageId?:number){
        this.fieldsUpdatedAfterAssystPayoutSubmission = this.extractMessagesOnPayoutDataChange(matter,assystPayoutMortgageId);
    }

    extractMessagesOnPayoutDataChange(matter:Matter, assystPayoutMortgageId?:number): string[]{
        let assystPayoutMessages :string [] =[];
        if(this.isAssystPayoutApplicableForMatter(matter, assystPayoutMortgageId) && (!!matter.openAssystPayoutModalAfterSave || assystPayoutMortgageId)){
            //Updated Matter Fields has to processed once.
            assystPayoutMessages.push(...this.assystPayoutMessagesOnDataChange(matter.matterType, matter,this.getOldMatter(),
                AssystPayoutXpathConfigs.matterXPathConfigs));
            //Matter Participant Details
            let currentMatterParticipants : MatterParticipant[] = this.getMortgagors(matter);
            let oldMatterParticipants : MatterParticipant[]= this.getMortgagors(this.getOldMatter());
            if (currentMatterParticipants.length == oldMatterParticipants.length) {
                currentMatterParticipants.forEach((currentMatterParticipant, index) => {
                    if (currentMatterParticipant.contact && oldMatterParticipants[index].contact &&
                        currentMatterParticipant.contact.gender == oldMatterParticipants[index].contact.gender) {
                        let vendorDefaultMessage = matter.mainClientTitle + "s > " + matter.mainClientTitle + " " +currentMatterParticipant.matterParticipantPriority + " > ";
                        assystPayoutMessages.push(...this.assystPayoutMessagesOnDataChange(matter.matterType, currentMatterParticipant.contact,
                            oldMatterParticipants[index].contact, AssystPayoutXpathConfigs.contactXPathConfigs).map(i => vendorDefaultMessage + i));
                    } else {
                        assystPayoutMessages.push(defaultMessageForCountNotMatch.gender);
                    }
                });
            } else {
                assystPayoutMessages.push(matter.isSale ? defaultMessageForCountNotMatch.vendors : defaultMessageForCountNotMatch.mortgagors);
            }
            if(!matter.isMatterProvinceSK){
                assystPayoutMessages.push(...this.checkPINLegalDescriptions(matter,this.getOldMatter()));
            }

            matter.existingMortgages.filter(mortgage => mortgage.id != assystPayoutMortgageId).forEach((mortgage) => {
                let latestPayoutStatementStatus: PayoutStatementStatus = mortgage && mortgage.payoutStatement ?
                    mortgage.payoutStatement.getLatestPayoutStatementStatus(): null;
                if(this.isAssystPayoutApplicable(mortgage,matter) && (latestPayoutStatementStatus && !latestPayoutStatementStatus.isCancelled())){
                    //Mortgage Details
                    let mortgageBeforeMatterSave = this.getOldMortgage(mortgage);
                    let mortgageDefaultMessage = "Existing "+ SharedUtils.getOrdinal(mortgage.multiTemplatePriority) + ' Mortgage > ';
                    assystPayoutMessages.push(...this.assystPayoutMessagesOnDataChange(matter.matterType,mortgage , mortgageBeforeMatterSave,
                        AssystPayoutXpathConfigs.mortgageXPathConfigs).map(i => mortgageDefaultMessage + i));
                    if(mortgage.relatedInstruments && mortgageBeforeMatterSave &&
                        mortgage.relatedInstruments.length == mortgageBeforeMatterSave.relatedInstruments.length ){
                        mortgage.relatedInstruments.forEach((instrument, index) => {
                            let mortgageInstrumentDefaultMessage = "Existing "+ SharedUtils.getOrdinal(mortgage.multiTemplatePriority) + ' Mortgage > Related Instrument '
                                + SharedUtils.getOrdinal(index+1) +' > ';
                            assystPayoutMessages.push(...this.assystPayoutMessagesOnDataChange(matter.matterType,instrument ,
                                this.getOldMortgageInstrument(instrument, this.getOldMortgage(mortgage)),
                                AssystPayoutXpathConfigs.relatedInstrumentXPathConfigs).map(i => mortgageInstrumentDefaultMessage + i));
                        });
                    }else{
                        assystPayoutMessages.push(defaultMessageForCountNotMatch.relatedInstrument);
                    }
                }
            });
        }
        return assystPayoutMessages;
    }

    checkPINLegalDescriptions(currentMatter: Matter, backendMatter:Matter):string[]{
        let assystPayoutMessagesForPINs :string[] =[];
        let currentMatterPinLegalDescriptins: PinLegalDescription[]= LegalDescriptionUtil.createAssystPayoutPinLegalDescriptions(currentMatter);
        let backendMatterPinLegalDescriptins: PinLegalDescription[]= LegalDescriptionUtil.createAssystPayoutPinLegalDescriptions(backendMatter);
        if(currentMatterPinLegalDescriptins.length == backendMatterPinLegalDescriptins.length){
            currentMatterPinLegalDescriptins.forEach((pinLegalDescription, index) => {
                if(pinLegalDescription.pin == backendMatterPinLegalDescriptins[index].pin){
                    if(pinLegalDescription.legalDescription != backendMatterPinLegalDescriptins[index].legalDescription){
                        let pinDefaultMessage = "MatterProperty > Legal Description for PIN "+ pinLegalDescription.pin + ' was changed ';
                        assystPayoutMessagesForPINs.push(pinDefaultMessage);
                    }
                } else{
                    let pinDefaultMessage = "MatterProperty > PIN "+ SharedUtils.getOrdinal(index+1) + ' was changed ';
                    assystPayoutMessagesForPINs.push(pinDefaultMessage);
                }
            });
        }else{
            assystPayoutMessagesForPINs.push(defaultMessageForCountNotMatch.pins);
        }
        return assystPayoutMessagesForPINs;
    }

    getMortgagors(matter : Matter):MatterParticipant[]{
        if(matter.isSale){
            return matter.matterParticipants.filter(participant => participant.matterParticipantRole == 'VENDOR');
        }else{
            return matter.matterParticipants.filter(participant => participant.matterParticipantRole == 'MORTGAGOR');
        }
    }

    getOldMatter():Matter{
        return this.activeMatterTab ? this.activeMatterTab.backEndMatter : null;
    }

    getOldMortgage(currentMortgage :Mortgage): Mortgage{
        return this.getOldMatter() && this.getOldMatter().existingMortgages.find(mortgage => mortgage.id == currentMortgage.id);
    }
    getOldMortgageInstrument(currentMortgageInstrument :MortgageInstrument, oldMortgage : Mortgage): MortgageInstrument{
        return oldMortgage && oldMortgage.relatedInstruments.find(mortgageInstrument => mortgageInstrument.id == currentMortgageInstrument.id);
    }

    isAssystPayoutApplicable(mortgage: Mortgage, matter:Matter):boolean{
        return (!matter.isPurchase && matter.existingMortgages && matter.existingMortgages.length &&
            mortgage.isMortgageDispositionDischarged() && mortgage.isMortgageeAnInstitution() &&
            matter.matterParticipants.some(mp => mp.mortgageId && mp.mortgageId == mortgage.id
                && ApplicablePayoutInstitutions.some(applicableInstitution => applicableInstitution.institutionNo == mp.contact.institutionNo)));

    }

    isAssystPayoutApplicableForMatter(matter:Matter, assystPayoutMortgageId ? : number):boolean{
        if(!matter.isPurchase && matter.existingMortgages && matter.existingMortgages.length ){
            let assystMortgages : Mortgage[] = matter.existingMortgages.filter(mortgage =>  assystPayoutMortgageId != mortgage.id
                &&  mortgage.isMortgageDispositionDischarged() && mortgage.isMortgageeAnInstitution()
                && matter.matterParticipants.some(mp => mp.mortgageId && mp.mortgageId == mortgage.id
                    && ApplicablePayoutInstitutions.some(applicableInstitution => applicableInstitution.institutionNo == mp.contact.institutionNo)));
            if(assystMortgages && assystMortgages.some(mortgage => mortgage.payoutStatement &&
                !(mortgage.payoutStatement.getLatestPayoutStatementStatus().isCancelled() || mortgage.payoutStatement.isReturnedToSolicitor()))){
                return true;
            }else{
                return false;
            }
        }
        return false;

    }

    async getLatestPayoutStatement(matter: Matter, mortgage : Mortgage): Promise<PayoutStatement> {
        return await this.telusService.fetchLatestPayoutStatement(matter.id,mortgage.id).toPromise()
    }

    validateAndOpenAssystModal(assystPayoutMessagesOnDataChange :string[]): void{
        if(assystPayoutMessagesOnDataChange.length > 0){
            this.openAssystPayoutMessagesModal(assystPayoutMessagesOnDataChange);
        }
    }

    openAssystPayoutMessagesModal = async (assystPayoutMessagesOnDataChange :string[]): Promise<void> => {
        this.dialogService.content({
            content: AssystPayoutMessagesModalComponent,
            context: {
                fieldsUpdatedAfterAssystPayoutSubmission : assystPayoutMessagesOnDataChange
            },
            onFulfillment: (result) => {
                this.fieldsUpdatedAfterAssystPayoutSubmission = [];
            }
        });
    };

    assystPayoutMessagesOnDataChange(matterType : string ,currentObject: any, oldObject : any, xPathConfigs: any[]): string [] {
        let payoutDataChangedMessages: string [] = [];
        if (currentObject && oldObject) {
            for (let i = 0; i < xPathConfigs.length; i++) {
                if (xPathConfigs[i].matterType.indexOf(matterType ? matterType.charAt(0) : '') != -1) {
                    console.log('currentMater: ' + xPathConfigs[i].xpath + ' :' + jp.value(currentObject, xPathConfigs[i].xpath));
                    console.log('OldMatter: ' + xPathConfigs[i].xpath + ' :' + jp.value(oldObject, xPathConfigs[i].xpath));
                    if (xPathConfigs[i].xpathType === 'Object' && (jp.value(currentObject, xPathConfigs[i].xpath) != jp.value(oldObject, xPathConfigs[i].xpath))
                        && !(!jp.value(currentObject, xPathConfigs[i].xpath) && !jp.value(oldObject, xPathConfigs[i].xpath))) {
                        console.log('Data Modified At object: ' + xPathConfigs[i].xpath);
                        payoutDataChangedMessages.push(xPathConfigs[i].message);
                    } else if (xPathConfigs[i].xpathType === 'BooleanValue') {
                        let currentMatterData: string = jp.value(currentObject, xPathConfigs[i].xpath);
                        let oldMatterData: string = jp.value(oldObject, xPathConfigs[i].xpath);
                        console.log('currentMater booleanType value: ' + xPathConfigs[i].xpath + ' :' + currentMatterData);
                        console.log('OldMatter booleanType value: ' + xPathConfigs[i].xpath + ' :' + oldMatterData);
                        if (!Utils.isSameBooleanValue(currentMatterData, oldMatterData)) {
                            payoutDataChangedMessages.push(xPathConfigs[i].message);
                        }
                    }
                }
            }
        }
        return payoutDataChangedMessages;
    }


    saveMatter(matter: Matter, unassignedMortgageInstructionId?: number, cancelledMortgageInstructionIds?: number[], loggedUserAccount?: Account, customErrorService?: ErrorService, saveAsync?: boolean): Observable<Matter> {
        try {


            // Clean up Needs to be last method be called before updating or saving
            let errorService = customErrorService ? customErrorService : this.errorService;


            matter.cleanUpMatterBeforeSaving(this);
            let saveMatterStarTime: number = Date.now();
            if (matter.id && matter.id > 0) {

                this.executePreMatterSaveSteps(matter, errorService, loggedUserAccount);
                let backupMapforcleanupUIOnlyField: Map<string, any> = this.generateBackupMapForCleanupUIOnlyFields(matter);
                matter.isMatterSaveInProgress = true;
                return this.updateMatter(matter.id, matter, unassignedMortgageInstructionId, cancelledMortgageInstructionIds, saveAsync).map(
                    updatedMatter => {
                        this.globalLogger.logRequestDuration(LogLevelTypes.INFO, ActionTypes.clientSideMatterSave, saveMatterStarTime, matter.id);
                        updatedMatter.dirty = false;
                        matter.isMatterSaveInProgress = false;
                        this.statusBarService.currentHelpText = StatusBarMessages.matter.UPDATE;
                        this.validateAndOpenAssystModal(this.fieldsUpdatedAfterAssystPayoutSubmission);
                        this.getCachedLandTransferTaxRateForNB(matter, updatedMatter);
                        return updatedMatter;

                    }).catch((error: any) => {
                    matter.isMatterSaveInProgress = false;
                    this.globalLogger.logRequestDuration(LogLevelTypes.WARN, ActionTypes.clientSideMatterSave, saveMatterStarTime, matter.id);
                    this.handleApiErrors(error, errorService);
                    MatterCleanUpUtil.revertMatterCleanUp(matter, backupMapforcleanupUIOnlyField, this.appConfig);
                    throw error;
                });
            } else {

                matter.id = undefined;
                this.revertUneditedPrivateCopies(matter, errorService);
                this.matterNotificationUtilService.calculateNotificationsStatuses(matter);
                //Validate and Update Ereg Forms Status
                EregistrationUtil.validateAndUpdateERegFormStatus(matter, errorService, null, this.getTemplateMatterForProject(matter));
                //TODO: this needs cleaning up, this is not the right place to handle this logic, this component should not step
                //out of its context (which is one single matter)
                let backupMapforcleanupUIOnlyField: Map<string, any> = this.generateBackupMapForCleanupUIOnlyFields(matter);
                let isFromOpportunity = matter.isFromOpportunity;
                matter.isMatterSaveInProgress = true;
                return this.addMatter(matter).map(updatedMatter => {
                    this.globalLogger.logRequestDuration(LogLevelTypes.INFO, ActionTypes.clientSideMatterCreate, saveMatterStarTime, updatedMatter.id);
                    updatedMatter.dirty = false;
                    matter.isMatterSaveInProgress = false;
                    this.statusBarService.currentHelpText = StatusBarMessages.matter.CREATE;
                    this.getCachedLandTransferTaxRateForNB(matter, updatedMatter);
                    return updatedMatter;

                }).catch((error: any) => {
                    matter.isMatterSaveInProgress = false;
                    // Can't get the matter id
                    // this.globalLogger.logRequestDuration(LogLevelTypes.INFO, ActionTypes.clientSideMatterCreate, saveMatterStarTime, updatedMatter.id);
                    this.handleApiErrors(error, errorService);
                    MatterCleanUpUtil.revertMatterCleanUp(matter, backupMapforcleanupUIOnlyField , this.appConfig);
                    throw error;
                });
            }
        } catch (err) {
            matter.isMatterSaveInProgress = false;
            MatterCleanUpUtil.revertMatterCleanUp(matter);
            throw err;
        }
    }

    getCachedLandTransferTaxRateForNB(matter, updatedMatter) {
        let cachedLandTransferTaxRate = this.taxRateService.getCachedLandTransferTaxRates(matter.provinceCode);
        if (cachedLandTransferTaxRate && matter.provinceCode === 'NB') {
            let landTransferTaxRate = cachedLandTransferTaxRate.find(item => item.id == matter.propertyModel.landTransferTaxRateId);
            updatedMatter.propertyModel.landTransferTaxRate = landTransferTaxRate;
        }
    }

    getTemplateMatterForProject(matter: Matter): Matter {
        if (matter.isProjectSale && matter.project) {
            return this.projectMatterCacheService.getMatterFromCache(matter.project.templateMatterId);
        }
    }

    updateAfterMatterSave(matter: Matter): void {
        if (matter && matter.soaTrustLedgerCollection) {
            if (matter.soaTrustLedgerCollection && matter.soaTrustLedgerCollection.statementConfig) {
                matter.soaTrustLedgerCollection.statementConfig = matter.soaTrustLedgerCollection.statementConfig;
            }
            if (matter.soaTrustLedgerCollection && matter.soaTrustLedgerCollection.combinedAccountCodeDataArr) {
                matter.soaTrustLedgerCollection.combinedAccountCodeDataArr = matter.soaTrustLedgerCollection.combinedAccountCodeDataArr;
            }
            if (matter.soaTrustLedgerCollection && matter.soaTrustLedgerCollection.soaExportConfig) {
                matter.soaTrustLedgerCollection.soaExportConfig = matter.soaTrustLedgerCollection.soaExportConfig;
            }
            if (matter.soaTrustLedgerCollection && matter.soaTrustLedgerCollection.accountDepartments) {
                matter.soaTrustLedgerCollection.accountDepartments = matter.soaTrustLedgerCollection.accountDepartments;
            }
            if (matter.soaTrustLedgerCollection && matter.soaTrustLedgerCollection.soaFeesRates) {
                matter.soaTrustLedgerCollection.soaFeesRates = matter.soaTrustLedgerCollection.soaFeesRates.map(function (item) {
                    return new SoaFeeRate(item);
                });
            }
            if (matter.autoInsertAllF9Values) {
                matter.soaTrustLedgerCollection.updateF9TrustLedger();
            }
        }

        this.saveMatterTabState(matter);
    }

    public saveMatterTabState(matter: Matter) {
        let matterTab = this.tabsService.activeTab as MatterTab;
        //matterTab.matter = matter;
        if(matterTab.matter && matter){
            matter.selectedProgressionStatus = matterTab.matter.selectedProgressionStatus;
            matter.adjustmentStatusMode = matterTab.matter.adjustmentStatusMode;
        }
        matterTab.backEndMatter = new Matter(matter);
        //this.clearExistingMortgageMapping(matterTab.backEndMatter);
        this.syncUpdatedMatterModelWithMatterList(matter);

    }

    public syncUpdatedMatterModelWithMatterList(matter: Matter) {
        if (this.tabsService.anchorTab && this.tabsService.anchorTab.isMatter()) {
            let matterListTab: MatterTab = this.tabsService.openTabs[0] as MatterTab;
            if (matterListTab && matterListTab.matterListState) {
                matterListTab.matterListState.rows.forEach(function (m: Matter, index, matterList) {
                    if (m.id === matter.id) {
                        matterList[index] = matter;
                    }
                });
            }

        }
    }

    public handleApiErrors(error: any, customErrorService: ErrorService): void {
        if (error.errorCode === "unity.billing.unreachable") {
            this.dialogService.confirm('Error - Unity Billing not available', `The new matter cannot be saved at this time - please try again later.`, true);
        } else if (error.errorCode === "unity.billing.error") {
            this.dialogService.confirm('Error - Transaction Rejected by Unity Billing', error.message + '<br><br> The new matter cannot be saved.', true);
        } else if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
            //Move app.invalidFieldsDataError to httpClient service
            if (error.errorCode !== "app.invalidFieldsDataError") {
                error.fieldErrors.forEach((fieldError: FieldError) => {

                    customErrorService.addDpSaveError(DPError.createCustomDPError(fieldError.errorCode, customErrorService.getErrorMessageForSaveMatter(fieldError), null, "ERROR"));
                });
            }
        } else if (error && error.errorCode == "app.cannotSaveDataWithCreditCardNumbers") {
            // already handled
        } else if(error.errorCode == "app.duplicatePrivateCopyFirm") {
            this.handleDuplicatePrivateCopyLawFirmErrors(error.message)
        }
        else {

            customErrorService.addDpSaveError(DPError.createCustomDPError(error.errorCode, customErrorService.getErrorMessageForSaveMatter(error), null, "ERROR"));
        }
        this.errorService.openErrorFooterNotification();
    }

    handleDuplicatePrivateCopyLawFirmErrors(errorMessage) {
        let message = 'The below law firm(s) already exists, do you wish to continue \n\n' + errorMessage;
        this.dialogService.confirm('Confirmation', message, false, 'Save', "Don't Save").subscribe(res => {
            if (res) {
                let matterTab = this.tabsService.activeTab as MatterTab;
                if (matterTab && matterTab.matterComponent) {
                    matterTab.matter.skipLawFirmDuplicateCheck = true;
                    let subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
                        if (result) {
                            subscription.unsubscribe();
                        }
                    });
                }
            }
        });
    }

    isCustomeMatter(matterType: string): boolean {
        return matterType != MatterTypesValue.PURCHASE && matterType != MatterTypesValue.SALE && matterType != MatterTypesValue.MORTGAGE;
    }

    async checkMatterRestrictedPeriod(matterType: MatterType, provinceCode: ProvinceCode, project?: Project): Promise<boolean> {
        let canCreateMatter = true;
        if (!(this.isCustomeMatter(matterType) || project)) {
            let isTodayInRestrictedPeriod = this.matterRestrictedPeriodService.isTodayInRestrictedPeriod(provinceCode);
            if (isTodayInRestrictedPeriod) {
                let account = await this.userStateService.getAccount().toPromise();
                if (account) {
                    let createdMattersCount = await this.matterRestrictedPeriodService.getCreatedMattersCount(provinceCode).toPromise();
                    if (createdMattersCount && account.maxMattersAllowedInRestrictionPeriod) {
                        canCreateMatter = createdMattersCount < account.maxMattersAllowedInRestrictionPeriod;
                    }
                }
            }
        }
        return canCreateMatter;
    }

    createNewMatter(matterType: MatterType, provinceCode?: ProvinceCode, project?: Project, skipMatterRestrictedPeriodCheck?: boolean): Observable<Matter> {
        if (skipMatterRestrictedPeriodCheck) {
            return this.completeCreatingNewMatter(matterType, provinceCode, project);
        } else {
            let matterSubject = new Subject<Matter>();
            Observable.fromPromise(this.checkMatterRestrictedPeriod(matterType, provinceCode, project))
                .subscribe((canCreateMatter) => {
                    if (canCreateMatter) {
                        this.completeCreatingNewMatter(matterType, provinceCode, project)
                            .subscribe((matter: Matter) => {
                                matterSubject.next(matter);
                                matterSubject.complete();
                            });
                    } else {
                        this.showRestrictionErrorMessage();
                        matterSubject.next(null);
                        matterSubject.complete();
                    }
                });
            return matterSubject;
        }
    }

    showRestrictionErrorMessage(): void {
        this.dialogService.confirm('ERROR', 'You have exceeded the maximum number of matter creations.<br><br>' +
            'Please contact Customer Service.', true);
    }

    completeCreatingNewMatter(matterType: MatterType, provinceCode: ProvinceCode, project?: Project): Observable<Matter> {

        let newMatter: Matter = new Matter();
        // Check for custom matter type
        if (matterType == 'PURCHASE' || matterType == 'SALE' || matterType == 'MORTGAGE' || matterType == 'OPPORTUNITY') {
            newMatter.matterType = matterType;
        } else if (matterType == 'WILL') {
            newMatter.matterType = matterType;
            newMatter.willMatter = new WillMatter();
            newMatter.willMatter.willDocumentsToProduce = [];
        } else if(matterType == 'DISCHARGE') {
            newMatter.isMatterTypeDischarge = true;
            newMatter.changeDischargeMatterToMortgageMatter();
        } else {
            newMatter.customMatterTypeName = matterType;
            newMatter.changeCustomMatterToPurchaseMatter();

        }

        newMatter.provinceCode = provinceCode;
        newMatter.matterRecordNumber = '';
        newMatter.id = 0;
        this.initializeNewMatter(newMatter, project);
        if (newMatter.documentProfileId == null && !newMatter.isOpportunityMatter()) {
            return this.staffProfilesService.getLoggedInStaffProfile().map(
                (staffProfiles: StaffProfiles) => {
                    if (staffProfiles && staffProfiles.user) {
                        let userSelectedProvince = staffProfiles.user.userProvinces.find((userProvince: UserProvince) => {
                            return userProvince.provinceCode == newMatter.provinceCode;
                        });
                        if ((userSelectedProvince && userSelectedProvince.documentProfileId) || newMatter.isMatterTypeDischarge) {
                            if(userSelectedProvince && userSelectedProvince.documentProfileId){
                                newMatter.documentProfileId = userSelectedProvince.documentProfileId;
                            }
                            if (!newMatter.isOpportunityMatter()) {
                                newMatter.selectedLawClerkId = staffProfiles.user.responsibleLawClerkId;
                                newMatter.selectedSolicitorId = staffProfiles.user.responsibleSolicitorId;
                            }
                        } else {
                            newMatter = undefined;
                            this.dialogService.confirm('Error', 'There is no document profile associated with that province', true, 'Ok');
                        }
                    }
                    return newMatter;
                }).catch((error: any) => {
                this.handleApiErrors(error, this.errorService);
                return Observable.of(newMatter);
            });
        } else {
            return Observable.of(newMatter);
        }
    }

    /**
     * This method initialize the default fields for a new matter. It is getting the matter from outside because matter type, province code, matter record
     * number, file number are assigned via UI. All the defaulting logic for new matter should go in this method.
     * @param {Matter} matter
     * @param {Project} project
     */
    initializeNewMatter(matter: Matter, project?: Project) {
        matter.tempIdForNewMatter = -(new Date()).getTime();
        matter.unityProjectId = project ? project.id : null;
        matter.projectRecordNumber = project ? project.projectRecordNumber : '';
        matter.project = project ? project : null;
        matter.initSelectedProgressionStatus();

        if (matter.isOpportunityMatter()) {
            matter.initOpportunityMatter();
        } else {
            if (!matter.commissionPaidTo) {
                this.setCommissionPaidTo(matter);
            }
        }

        matter.setFileOpenDateAsTodayForNewMatter();
        this.initializeAlbertaAltoEForms(matter);
        this.initializeMBTPREForms(matter);
        this.initializeERegTransferForm(matter);
        matter.applicationFiledBy = "BUILDER";

        // initializeDirectDepositInstructions
        this.initializeDirectDepositInstructions(matter);
        matter.requisitionSubmitted = 'N_y';
        matter.setInsertExcessDepositIntoTrustLedgerDefaultValue();

        if (project) {
            if (!matter.documentProfileId) {
                matter.documentProfileId = project.defaultDocumentProfileId;
            }

            matter.initializePaidOnOccupancyDeposit();
        }

        if (!matter.registrationMethodCode) {
            matter.registrationMethodCode = constValues.registrationMethodsType.electronic;

            if (matter.isMatterProvinceON && project && project.docRegistration) {
                matter.registrationMethodCode = project.docRegistration.isRegistrationMethodElectronic() ? constValues.registrationMethodsType.electronic : constValues.registrationMethodsType.manual;

                if (matter && matter.teranetDocket) {
                    if (matter.teraviewDocketIdSameAsProjectField) {
                        matter.teranetDocket.teraviewDocketIdentifier = project.teraviewDocketIdentifier;
                    }
                }
            }
        }
        matter.matterStatus = 'DEFAULT_ACTIVE';
        if (!matter.transactionTitleInsuredCode) {
            matter.transactionTitleInsuredCode = 'N/y';
        }
        if (!matter.appointmentScheduledFlag) {
            matter.appointmentScheduledFlag = DpBooleanValueTypes.N_y;
        }
        if (!matter.documentsToBeSignedRemotely) {
            matter.documentsToBeSignedRemotely = DpBooleanValueTypes.N_y;
        }
        if (!matter.documentsToBeSignedDigitally) {
            matter.documentsToBeSignedDigitally = DpBooleanValueTypes.N_y;
        }
        if (!matter.otherSideDocumentsToBeSignedRemotely) {
            matter.otherSideDocumentsToBeSignedRemotely = DpBooleanValueTypes.N_y;
        }
        if (!matter.closed) {
            matter.closed = DpBooleanValueTypes.N_y;
        }

        matter.initReferredByList();

        this.createMatterTopicsForNewMatter(matter);
        this.initMatterDataProvinceSpecific(matter);
        if (matter.isPurchase) {
            this.initPurchaseReport(matter);
        }

        this.setUpStatementAdjustment(matter);

        if (!matter.teranetDocket) {
            matter.teranetDocket = new TeranetDocket();
        }
        matter.initializedFireInsuranceContactInfo();
        matter.setUpMatterContactInfo();
        this.initProjectSale(matter);
    }


    updateMatterWithUnitLevelPlanChange(matter: Matter, selectUnitLevelPlanResult: SelectUnitLevelPlanResult) {
        if (selectUnitLevelPlanResult) {
            if (selectUnitLevelPlanResult.plan) {
                matter.updateUnitLevelPlan(selectUnitLevelPlanResult.plan);
                this.updateExpenseAdjustmentsInProjectSale(matter, selectUnitLevelPlanResult);
            }
            if (selectUnitLevelPlanResult.editCommonExpenseCommand) {
                matter.updateInterimOccupanyFeeAdjOnUnitLevelChanges(selectUnitLevelPlanResult.editCommonExpenseCommand);
                this.updateStatementAdjustmentDisplayItems(matter);
            }
            SoaRealtyTaxAdjustmentUtil.updateRealtyTaxAdjustmentOnDateChange(matter);
            matter.updateInterimOccupancyFeeAdjOnChange();
        }
    }

    updateExpenseAdjustmentsInProjectSale(matter: Matter, selectUnitLevelPlanResult: SelectUnitLevelPlanResult) {
        if (matter.isProjectOrProjectSale) {
            SoaExpenseAdjustmentUtil.updateCommonExpenseAdjustments(matter, Number(selectUnitLevelPlanResult.plan.condominiumTotalExpenses));
            SoaExpenseAdjustmentUtil.updateSalesIncentiveAdjustments(matter, Number(selectUnitLevelPlanResult.plan.condominiumTotalExpenses));
        }
    }

    getMortgageHoldbackConfig(key: string, provinceCode: ProvinceCode, closingDate?: string): Observable<MortgageHoldbackConfig> {
        let formattedClosingDate = null;
        if (closingDate) {
            formattedClosingDate = moment(closingDate, "YYYY/MM/DD").format("YYYY-MM-DD");
        }
        return this.http.get(matterApi.mtgHoldbackConfig(key, provinceCode, formattedClosingDate))
            .map((res) => {
                return new MortgageHoldbackConfig(res[matterResponseKey.MortgageHoldbackConfig]);
            });
    }

    conveyCaUrl(): Observable<string> {
        return this.http.get(matterApi.conveyCaUrl)
            .map((res) => {
                if (res['ConveyCaUrl']) {
                    return res['ConveyCaUrl'];
                }
            });
    }

    importCirfMatterDocument(matterId: Number, cirfGuid: string, cirfDocument: CirfDocument): Observable<any> {
        let url: string = matterApi.importCirfDocument.replace('{matterId}', '' + matterId).replace('{cirfGuid}', cirfGuid).replace('{documentId}', '' + cirfDocument.id);

        return this.http.post(url, null)
            .map((res) => {
                return res['MatterDocument'];
            });
    }


    linkSharedDocumentsToMatter(packageId: number, linkMatterId: number): Observable<any> {
        let url: string = matterApi.linkSharedDocument.replace('{packageId}', '' + packageId)
            .replace('{matterId}', '' + linkMatterId);


        return this.http.post(url, null)
            .map((res) => {
                return res['MatterDocument'];
            });
    }

    unlinkMatterFromShareDocsPackage(sharedDocumentGuid: string, lockScreen?: boolean): Observable<SharedDocumentsPackage> {
        let url = matterApi.unlinkLinkMatterFromSharedDocsPackage.replace('{packageGuid}', sharedDocumentGuid);
        return this.http.put(url, lockScreen)
            .map((res) => {
                if (res && res['SharedDocumentsPackage']) {
                    return new SharedDocumentsPackage(res['SharedDocumentsPackage']);
                }
            });
    }

    removeThirdPartyNotificationsForEmpMortgage(mortgage: Mortgage): void {
        if (mortgage) {
            this.errorService.removeDpThirdPartyNotificationsByMortgageId(mortgage.id);
        }
    }

    replaceEmpMortgageWithBlankMortgage(matter: Matter, mortgage: Mortgage): void {
        let matterPriority: number = Number(mortgage.mortgagePriority);
        let mortgageIndex = matter.getMortgageIndexByMortgage(mortgage);
        let unassignedOrCancelledEmpMortgageIndex: number = matter.mortgages.findIndex(item => item.mortgagePriority === matterPriority);
        //need to cleanup the MTI based on the about-deleted Mortgage before deletion
        MatterCleanUpUtil.cleanTitleInsuranceData(matter, mortgage);
        matter.deleteMortgage(mortgage);
        matter.updateTitleInsurance(MortgageAction.DELETE, matterPriority);
        matter.mortgages.splice(unassignedOrCancelledEmpMortgageIndex, 0, matter.createMortgage('NEW', 'UNITY', matterPriority));
        // soaTrustLedgerCollection.matter need be update for getting the latest mortgages information
        if (matter.soaTrustLedgerCollection && matter.soaTrustLedgerCollection.matter) {
            matter.soaTrustLedgerCollection.matter.mortgages = matter.mortgages;
        }
        matter.updateTitleInsurance(MortgageAction.ADD, matterPriority);
        this.updateTrustLedgerAndStatementOfAdjustment(matter, mortgageIndex);
    }

    updateTrustLedgerAndStatementOfAdjustment(matter: Matter, mortgageIndex: number): void {
        if (matter.soaTrustLedgerCollection) {
            matter.soaTrustLedgerCollection.removeMortgageFromTrustLedger(mortgageIndex);
            let emps = matter.mortgages.filter(m => m.isEmpMortgage());
            if (!emps || emps && !emps.length) {
                //Remove Electronic Fees
                matter.soaTrustLedgerCollection.removeEmp();
                if (matter.secondarySoaSheetsCollection) {
                    matter.secondarySoaSheetsCollection.forEach(collection => {
                        collection.removeEmp();
                    })
                }
            }
            matter.soaTrustLedgerCollection.addOrRemoveFeeForAllMortgages();
            if (matter.secondarySoaSheetsCollection) {
                matter.secondarySoaSheetsCollection.forEach(collection => {
                    collection.addOrRemoveFeeForAllMortgages();
                })
            }
            matter.soaTrustLedgerCollection.addTrustLedgerMortgageRowForAllMortgages();
            matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
            if (matter.secondarySoaSheetsCollection) {
                matter.secondarySoaSheetsCollection.forEach(collection => {
                    collection.updateERegAndRegisterCharges();
                })
            }
        }
        this.mortgageSoAdjService.updateStatementOfAdjustment(matter);
    }

    // In case of needs to unassign multiple EMP on matter opening due to priority change, matter should be saved after each unassigned instruction.
    // Once saved matter acquires new references for objects including mortgages.
    // It is important to get a fresh reference to a specific EMP mortgage from the renewed matter for successful deletion in matter.deleteMortgage method.
    unAssignMortgage(matter: Matter, mortgageId: number, cancelledMortgageInstructionId?: number) {
        let mortgage: Mortgage = matter.mortgages.find(item => item.id == mortgageId);
        this.removeThirdPartyNotificationsForEmpMortgage(mortgage);
        this.replaceEmpMortgageWithBlankMortgage(matter, mortgage);
    }

    copyReferralDocumentToMatter(matterId: Number, referralId: number, document: ReferralDocument): Observable<any> {
        let url: string = matterApi.copyReferralDocumentToMatter
            .replace('{matterId}', '' + matterId)
            .replace('{referralId}', referralId.toString())
            .replace('{documentId}', document.id.toString());

        return this.http.post(url, null)
            .map((res) => {
                return res['MatterDocument'];
            });
    }

    //do it for Assyst/Unity® Lender Centre EMP
    verifyPurchasePriceWithInstructionPurchasePrice(matter: Matter) {
        if (matter && Array.isArray(matter.empMortgages) && matter.empMortgages.length > 0) {
            const matterPurchasePrice = matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.purchasePrice;
            matter.empMortgages.filter(emp => emp.stewartAssystMortgageInstruction && !emp.stewartAssystMortgageInstruction.isFctInstruction)
                .forEach(emp => {
                    const notificationKey: string = `notification.stewartAssyst.purchasePrice.${emp.id}`;
                    this.errorService.removeDpThirdPartyNotification(notificationKey);

                    const instructionPurchasePrice: number = emp.stewartAssystMortgageInstruction && emp.stewartAssystMortgageInstruction.mortgageInstructionData && emp.stewartAssystMortgageInstruction.mortgageInstructionData.purchasePrice;
                    //if purchase price provided in the thirdParty Mortgage Instruction, then we need to compare it with the matter Purchase Price
                    const needToVerifyPurchasePrice: boolean = !!instructionPurchasePrice;

                    if (needToVerifyPurchasePrice && (!matter.matterPropertyWithCondo || (instructionPurchasePrice != matter.matterPropertyWithCondo.purchasePrice))) {

                        const instructionPurchasePriceFormatted: string = this.currencyPipe.transform(instructionPurchasePrice, 'CAD', 'symbol', '1.2-2').replace("CA", "");
                        const matterPurchasePriceFormatted = this.currencyPipe.transform(matter.matterPropertyWithCondo.purchasePrice, 'CAD', 'symbol', '1.2-2').replace("CA", "");
                        const mortgageIndex: number = matter.mortgages.findIndex(item => item.id == emp.id);
                        const notificationTopic: string = `Unity® Lender Centre - ${SharedUtils.getOrdinal(mortgageIndex + 1)} Mortgage`;
                        const notificationMsg: string =
                            `The purchase price ${matterPurchasePriceFormatted} does not match the purchase price of ${instructionPurchasePriceFormatted} specified in the mortgage instructions`;

                        this.errorService.addDpThirdPartyNotification(DPError.createCustomDPError(notificationKey, notificationMsg, notificationTopic, 'NOTIFICATION', null, null, null, null, emp.id));
                    }
            })
        }
    }

    async getLinkedPurchaseMatters(matters: Matter[]): Promise<Matter[]> {
        let linkedPSMatters = matters.filter(matter => matter.matterLink && matter.matterLink.linkedMatterId);
        let linkedPurchaseMatters: Matter[] = [];
        if (linkedPSMatters && linkedPSMatters.length) {
            let matterServiceCalls: any = [];
            linkedPSMatters.forEach((matter) => {
                matterServiceCalls.push(this.getMatter(matter.matterLink.linkedMatterId));
            });
            let results = await Observable.forkJoin(matterServiceCalls).toPromise();
            results.forEach((matter: Matter) => {
                linkedPurchaseMatters.push(matter);
            });
            return linkedPurchaseMatters;
        } else {
            return [];
        }
    }

    isMatterComplianceInfoSameAsJurisdiction(matterCompliances: Compliance[], configDepartments: JurisdictionDepartment[], newJurisdiction: Jurisdiction, provinceCode: ProvinceCode): boolean {
        //If there is Hydro Department, it means it is "SEPARATE". Otherwise is "COMBINED"
        let hydroCompliance = Array.isArray(matterCompliances) && matterCompliances.find(item => item.departmentName === Hydro_Department);

        // If matterCompliances and newJurisdiction have different WaterHydroDeptType.
        if (provinceCode == 'ON') {
            if ((hydroCompliance && (newJurisdiction && newJurisdiction.isWaterHydroDeptTypeCombined))
                || (!hydroCompliance && (newJurisdiction && newJurisdiction.isWaterHydroDeptTypeSeparate))) {
                return false
            }
        }
        //  Changing compliance departments name to for comparison therefore creating a copy
        let jurisdictionDepartmentsCopy: JurisdictionDepartment[] = configDepartments.slice(0);
        // hydroDepartment doesn't exist, it means WaterHydroDeptType is "COMBINED".

        // If WaterHydroDeptType is combined, the departments of config has "Hydro Department",
        // however matterCompliances hasn't "Hydro Department".
        // isMatterComplianceNamesSameConfigs shouldn't compare this item in this situation.

        // Config departments uses "Water Department" and matterCompliances uses "Hydro/Water Dep't",
        // the config departmentName should be changed to "Hydro/Water Dep't".
        if (provinceCode == 'ON') {
            if (newJurisdiction.waterHydroDeptType === WaterHydroDeptType_IS_COMBINED && Array.isArray(jurisdictionDepartmentsCopy) && jurisdictionDepartmentsCopy.length > 0) {
                jurisdictionDepartmentsCopy = jurisdictionDepartmentsCopy.filter((jurisdictionDepartment: JurisdictionDepartment) => jurisdictionDepartment.departmentName != Hydro_Department);
                let combinedHydroWaterDepartmentIndex: number = jurisdictionDepartmentsCopy.findIndex(item => item.departmentName == Water_Department);
                // Config departments uses "Water Department". However matterCompliances uses "Hydro/Water Dep't"
                if (combinedHydroWaterDepartmentIndex > -1) {
                    jurisdictionDepartmentsCopy[combinedHydroWaterDepartmentIndex].departmentName = Combined_Hydro_Water_Department;
                }
            }
        }
        if (!matterCompliances && !jurisdictionDepartmentsCopy) {
            return true;
        } else if (Array.isArray(matterCompliances) && Array.isArray(jurisdictionDepartmentsCopy) && matterCompliances.length === jurisdictionDepartmentsCopy.length) {
            let sortedMatterCompliances = _.sortBy(matterCompliances, ['departmentPriority']);
            let sortedDepartments = _.sortBy(jurisdictionDepartmentsCopy, ['departmentPriority']);
            let ret: boolean = true;
            for (let i = 0; i < sortedMatterCompliances.length; i++) {
                //matterCompliances always sorted by departmentPriority
                ret = (sortedMatterCompliances[i].departmentPriority === sortedDepartments[i].departmentPriority)
                    && (sortedMatterCompliances[i].departmentName === sortedDepartments[i].departmentName);
                if (!ret) {
                    break;
                }
            }
            return ret;
        } else {
            return false;
        }
    }

    updateMatterComplianceWithLatestJurisdiction(jurisdictionDepartments: JurisdictionDepartment[], jurisdiction: Jurisdiction, matter: Matter): void {
        let departments: Department[] = jurisdiction.departments ? jurisdiction.departments : [];
        let configCompliances = Compliance.jurisdictionDepartmentsToCompliances(jurisdictionDepartments, matter.matterType, jurisdiction.waterHydroDeptType);
        //if jurisdiction changes, add any new departments and update some existing ones
        for (let department of departments) {

            let matterCompliance: Compliance = matter.matterCompliances.find(compliance => compliance.departmentPriority === department.departmentPriority);
            let configCompliance: Compliance = configCompliances.find(compliance => compliance.departmentPriority === department.departmentPriority);

            // Existing compliance
            // any populated values will remain, only dept name change will get reflected)
            if (matterCompliance && configCompliance) {
                // Change of Water and Hydro combination (Separated to Combined or Vice Versa - put values of the fields to default)
                // Note - This is applicable to all types of Jurisdiction contact record (Global, Private copy of global, Private)
                // configCompliance inculdes default values
                if (matterCompliance.departmentName == Water_Department && jurisdiction.waterHydroDeptType == WaterHydroDeptType_IS_COMBINED) {

                    matterCompliance.departmentName = Combined_Hydro_Water_Department;
                    matterCompliance.writeTo = configCompliance.writeTo;
                    matterCompliance.status = configCompliance.status;
                    matterCompliance.remarks = null;
                } else if (matterCompliance.departmentName == Combined_Hydro_Water_Department && jurisdiction.waterHydroDeptType == WaterHydroDeptType_IS_SEPARATE) {
                    matterCompliance.departmentName = Water_Department;
                    matterCompliance.writeTo = configCompliance.writeTo;
                    matterCompliance.status = configCompliance.status;
                    matterCompliance.remarks = null;
                } else {
                    matterCompliance.departmentName = department.departmentName;
                }
            } // New Compliance
            else if (configCompliance) {
                matter.matterCompliances.push(new Compliance(configCompliance));
            }
        }
        matter.matterCompliances = matter.matterCompliances
            .filter(compliance => configCompliances.find(keepDepartment => keepDepartment.departmentPriority === compliance.departmentPriority));

        matter.matterCompliances = _.sortBy(matter.matterCompliances, ['departmentPriority']);
    }

    public async updateCompliancesWithLatestJurisdiction(matter: Matter): Promise<void> {
        if (matter && matter.hasPropertyJurisdiction()) {
            //Getting both, the new jurisdiction and default configs from the back end which are needed to update compliances.
            let result = await Observable.forkJoin(
                this.jurisdictionDepartmentsService.getDepartmentPairs(null, matter.matterPropertyWithCondo.jurisdiction.provinceCode),
                this.jurisdictionService.getReadonlyJurisdiction(matter.matterPropertyWithCondo.jurisdiction.id)).toPromise();

            if (result && result.length == 2) {
                let jurisdictionDepartments: JurisdictionDepartment[] = result[0];
                let newJurisdiction: Jurisdiction = result[1];
                if (Array.isArray(jurisdictionDepartments) && newJurisdiction) {
                    if (!this.isMatterComplianceInfoSameAsJurisdiction(matter.matterCompliances, jurisdictionDepartments, newJurisdiction, matter.provinceCode)) {
                        matter.isDirty = true;
                        this.updateMatterComplianceWithLatestJurisdiction(jurisdictionDepartments, newJurisdiction, matter);

                        if (matter.soaTrustLedgerCollection) {
                            // accountDepartments of soaTrustLedgerCollection needs to be updated.
                            // The SoaTrustLedgerCollection is a transient helper class injected into matter and  all the properties in this class are
                            // supposed to be readOnly therefore it's ok to copy the instance's reference directly.
                            // But same should not be done if you are working with any persisted entity.
                            matter.soaTrustLedgerCollection.accountDepartments = jurisdictionDepartments;
                            matter.soaTrustLedgerCollection.addOrUpdateCompliance();
                            if (matter.secondarySoaSheetsCollection) {
                                matter.secondarySoaSheetsCollection.forEach(collection => {
                                    collection.addOrUpdateCompliance();
                                })
                            }
                        }
                    }
                }
            }
        }
    }

    async initializeOpportunity(matter: Matter, account : Account): Promise<void> {
        if (matter && matter.opportunity && !matter.fullyInitialized && matter.opportunity.sourceReferralId) {
            let referral: Referral = await this.opportunitiesService.getReferralById(matter.opportunity.sourceReferralId).toPromise();
            await this.addOtherSideSolicitor(referral, matter);
            await this.addOtherSideLawFirm(referral, matter);
            await this.addRealEstateBroker(referral, matter);
            this.addMortgage(referral, matter);
            matter.fullyInitialized = true;
            matter.reCalculateClientReLine();
            await this.setupOpportunityProvinceAndSoa(matter, account);
            await this.saveMatterWithoutConfirmationDialog();
        }
    }

    async setupOpportunityProvinceAndSoa(matter: Matter, account : Account) : Promise<void> {
        if(matter && account){
            let enabledProvinces = account.getEnabledProvincesCodes();
            if(enabledProvinces && enabledProvinces.length==1){
                matter.provinceCode = enabledProvinces[0];
                await this.fetchOpportunitySoaConfig(matter);
            }
        }
    }

    async fetchOpportunitySoaConfig(matter: Matter): Promise<void>{
        matter.statementConfigurationId = undefined;
        matter.primarySoaTemplateId = undefined;
        if(matter.customMatterTypeName && matter.provinceCode){
            await this.soaTrustLedgerHelperService.updateMatterSOAConfig(matter);
        }
    }

    async addOtherSideSolicitor(referral: Referral, matter: Matter): Promise<void> {
        if (referral.referralForm.otherSolicitorId) {
            let otherSideSolicitor: Contact = await this.contactQueryService.getContactForMatter(referral.referralForm.otherSolicitorId).toPromise();
            matter.addMatterParticipant(otherSideSolicitor, true, 'OTHERPARTY_SOLICITOR');
        } else if (referral.referralForm.otherSolicitorName) {
            if (!this.getOtherSideStickyNote(matter)) {
                let matterTopic: MatterTopic = new MatterTopic();
                matterTopic.matterTopicKey = 'VENDOR_SOLICITOR';
                matterTopic.topicStatus = 'QUESTION';
                matterTopic.topicNote = 'Manually entered Other Parties Solicitor - ' + referral.referralForm.otherSolicitorName + '\n';
                matter.matterTopics.push(matterTopic);
            } else {
                this.getOtherSideStickyNote(matter).topicNote = this.getOtherSideStickyNote(matter).topicNote + '\n' + 'Other Parties Solicitor - ' + +referral.referralForm.otherSolicitorName + '\n';
            }
        }
    }

    async addOtherSideLawFirm(referral: Referral, matter: Matter): Promise<void> {
        if (referral.referralForm.otherLawFirmId) {
            let legalFirmId: number;

            //Special Case: If only law firm is specified in referral, then contact_id is stored in otherLawFirmId. When both law firm and solicitor are specified in referral,
            //then customer_account_id is stored in otherLawFirmId.
            try {
                let otherSideCustomerAccount: Account = await this.accountService.getPublicCustomerAccount(String(referral.referralForm.otherLawFirmId)).toPromise();
                legalFirmId = otherSideCustomerAccount.legalFirmId;
            } catch (error) {
                legalFirmId = referral.referralForm.otherLawFirmId;
            }

            let otherSideLawFirm: Contact = await this.contactQueryService.getContactForMatter(legalFirmId).toPromise();
            let snapshotContact: Contact = new Contact(otherSideLawFirm);
            snapshotContact.subContacts = [];
            matter.addMatterParticipant(snapshotContact, true, matter.otherPartyMPRoleLawFirm);
        } else if (referral.referralForm.otherLawFirmName) {
            if (!this.getOtherSideStickyNote(matter)) {
                let matterTopic: MatterTopic = new MatterTopic();
                matterTopic.matterTopicKey = 'VENDOR_SOLICITOR';
                matterTopic.topicStatus = 'QUESTION';
                matterTopic.topicNote = 'Manually entered Other Parties Law Firm - ' + referral.referralForm.otherLawFirmName + '\n';
                matter.matterTopics.push(matterTopic);
            } else {
                this.getOtherSideStickyNote(matter).topicNote = this.getOtherSideStickyNote(matter).topicNote + '\n' + 'Other Parties Law Firm - ' + referral.referralForm.otherLawFirmName + '\n';
            }
        }
    }

    async addRealEstateBroker(referral: Referral, matter: Matter): Promise<void> {
        if (referral.referralForm.realEstateBrokerageFirmId) {
            let realEstateBroker: Contact = await this.contactQueryService.getContactForMatter(referral.referralForm.realEstateBrokerageFirmId).toPromise();
            matter.realEstateBrokerName = realEstateBroker.organizationName;
            matter.selectedBrokerId = realEstateBroker.id;
        }
    }

    getOtherSideStickyNote(matter: Matter): MatterTopic {
        return matter.getMatterTopicByKey('VENDOR_SOLICITOR');
    }

    addMortgage(referral: Referral, matter: Matter): void {
        if (referral.referralForm.newMortgageAmount) {
            matter.mortgages = [];
            let mortgage: Mortgage = matter.createMortgage('NEW', 'UNITY', 1);
            mortgage.mortgageTerm.principal = referral.referralForm.newMortgageAmount;
            matter.mortgages.push(mortgage);
        }
    }

    generateBackupMapForCleanupUIOnlyFields(matter: Matter): Map<string, any> {
        let backupMapForCleanupUIOnlyField = new Map<string, any>();
        if (!this.appConfig.isMatterSaveStringified) {
            backupMapForCleanupUIOnlyField.set('matterBurgerMenu', matter.matterBurgerMenu);
            backupMapForCleanupUIOnlyField.set('isCopyInProgress', matter.isCopyInProgress);
            backupMapForCleanupUIOnlyField.set('selectedSolicitorId', matter.selectedSolicitorId);
            backupMapForCleanupUIOnlyField.set('selectedLawClerkId', matter.selectedLawClerkId);
            backupMapForCleanupUIOnlyField.set('selectedWitnessId', matter.selectedWitnessId);
            backupMapForCleanupUIOnlyField.set('soaTrustLedgerCollection', matter.soaTrustLedgerCollection);
            backupMapForCleanupUIOnlyField.set('selectedCommissionerId', matter.selectedCommissionerId);
            backupMapForCleanupUIOnlyField.set('finalDirectionReFunds', matter._finalDirectionReFunds);
            backupMapForCleanupUIOnlyField.set('interimAdjustments', matter._interimAdjustments);
            backupMapForCleanupUIOnlyField.set('finalAdjustments', matter._finalAdjustments);
            backupMapForCleanupUIOnlyField.set('interimAdjustmentsUnApplied', matter._interimAdjustmentsUnApplied);
            backupMapForCleanupUIOnlyField.set('finalAdjustmentsUnApplied', matter._finalAdjustmentsUnApplied);
            backupMapForCleanupUIOnlyField.set('interimDirectionReFunds', matter._interimDirectionReFunds);
            backupMapForCleanupUIOnlyField.set('readyForUI', matter.readyForUI);
            backupMapForCleanupUIOnlyField.set('referralId', matter.referralId);
            backupMapForCleanupUIOnlyField.set('SolicitorOrLawClerkDirty', matter.isSolicitorOrLawClerkDirty);
            backupMapForCleanupUIOnlyField.set('isTemplateMatterForMassUpdate', matter.isTemplateMatterForMassUpdate);
            backupMapForCleanupUIOnlyField.set('project', matter.project);
        }
        return backupMapForCleanupUIOnlyField;
    }

    getAssociatedMattersForOpportunity(opportunityId: number): Observable<Matter[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = `${matterApi.mattersAssociatedWithOpportunity(accountId, opportunityId)}`;
        return this.http.get(url).map((res) => {
            return res[matterResponseKey.matters].map((item) => {
                return new Matter(item);
            });
        });
    }


    getDuplicateOpportunities(opportunityId: number): Observable<Matter[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = `${matterApi.duplicateOpportunities(accountId, opportunityId)}`;
        return this.http.get(url).map((res) => {
            return res[matterResponseKey.matters].map((item) => {
                return new Matter(item);
            });
        });
    }


    isMatterLockedOrOpen(matter: Matter, tabsService: TabsService, errorService: ErrorService): boolean {
        let matterTypeName = matter.isOpportunityMatter()? 'opportunity' : 'matter'
        if (matter.locked) {
            let matterLockedByUserName = (matter.lockedByUser && matter.lockedByUser.firstName ?
                matter.lockedByUser.firstName : '') + " " + (matter.lockedByUser && matter.lockedByUser.lastName ? matter.lockedByUser.lastName : '');
            errorService.addDpSaveError(
                DPError.createCustomDPError("matter.need.select.existingMatter.locked",
                    `The selected ${matterTypeName} is currently locked  by ${matterLockedByUserName}`,  "", "ERROR"));
            return true;
        } else if (this.isMatterOpen(matter.id, tabsService)) {
            errorService.addDpSaveError(
                DPError.createCustomDPError("matter.need.select.existingMatter.opened",
                    `The selected ${matterTypeName} is opened in another tab. Please close the ${matterTypeName} first.`,  "", "ERROR"));
            return true;
        } else {
            return false;
        }
    }

    isMatterOpen(id: number, tabsService: TabsService): boolean {
        return tabsService.isMatterTabOpen(id);
    }

    updateMatterDocumentsFromOpportunity(matterId: number, opportunityId: number): Observable<Matter> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = matterApi.updateMatterFromOpportunity(accountId, matterId, opportunityId);
        return this.http.put(url, null)
            .map((res) => {
                return new Matter(res['Matter']);
            });
    }

    enableMatterEditingFlagPostClosing(matterId: number, accountId : number): Observable<Matter> {
        let url = matterApi.enableMatterEditingPostClosingFlag(accountId, matterId);
        return this.http.put(url, null)
            .map((res) => {
                return new Matter(res['Matter']);
            });
    }


    chargeTeranetConnectFees(matterId: number): Observable<MatterBillingResult> {
        let url = matterApi.chargeTeranetConnect(String(matterId));
        return this.http.post(url, null)
            .map((res) => {
                return new MatterBillingResult(res['MatterBillingResult']);
            });
    }

    searchTaskNames(searchText: string) : Observable<String[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = matterApi.searchTaskNames(String(accountId)) + '?taskName=' + searchText;
        return this.http.get(url)
            .map((res) => {
                return res['TaskDescriptionsList'].map((item) => {
                    return item;
                });
            });
    }

    lockMattersByIds(selectedMatterIds: number[]) {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = matterApi.lockMattersByIds(accountId)

        return this.http.post(url,  selectedMatterIds);
    }

    unlockMattersByIds(selectedMatterIds: number[]) {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = matterApi.unlockMattersByIds(accountId)

        return this.http.post(url, selectedMatterIds);
    }
}
