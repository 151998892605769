import moment from 'moment';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class GeneralLedgerAccountCode extends  BaseEntity {

    id : number;
    statementConfigurationId : number;
    accountNumber : string;
    accountName : string;
    accountType : string;
    departmentCostCategoryCenter : string;
    status : string;


    constructor(generalLedgerAccountCode? : GeneralLedgerAccountCode) {
        super(generalLedgerAccountCode);
    }
}
