import {Component} from '@angular/core';

// this component will be loaded when the user click on the home link form nav bar.
@Component({
  selector: 'dp-home',
  templateUrl : 'home.component.html',
   providers: [
  ],
})
export class HomeComponent {

}

