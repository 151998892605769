import {DocumentTemplate} from '../../document-production/document-template';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {MatterType} from '../../shared';

// ToDo rename this FormTemplateCategory in 1.1
export type GovernmentSiteLinkCategoryType = 'LTT_MLTT_AFFIDAVIT'|'GST_HST_APPLICATION'|'POLARIS_FORM'|'CONDOMINIUM_ACT'|'SCHEDULE'|'REQUEST_TRANSMISSION'|'MISC_DOCUMENT'|'CORRECTION_TO_STATUTORY_EVIDENCE'|'CLAIM_FOR_LIEN'|'MORTGAGE';

export class FormTemplate extends BaseEntity{

    id: number;
    categoryType: GovernmentSiteLinkCategoryType;
    matterType: MatterType;
    templateName: string;
    formName: string;
    outputFormName: string;
    documentTemplate: DocumentTemplate;
    supplementaryInfo: boolean;
}
