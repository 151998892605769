import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class TitleDeedNameTypes {
    public static readonly SAME_AS_CLIENT : TitleDeedNameType = "SAME_AS_CLIENT";
    public static readonly MANUAL : TitleDeedNameType = "MANUAL";
}

export type TitleDeedNameType = 'SAME_AS_CLIENT' | 'MANUAL' ;


export class TitleDeed extends BaseEntity {
    nameType: TitleDeedNameType;
    surName: string;
    firstName: string
    middleName: string

    constructor(titleDeedName? : TitleDeed) {
        super(titleDeedName);
        if(!titleDeedName){
            this.nameType = TitleDeedNameTypes.SAME_AS_CLIENT;
        }
    }

    get fullName(): string {
        return (this.surName ? this.surName  + ', ' : '') +
            (this.firstName ? this.firstName  + ' ' : '' ) +
            (this.middleName ? this.middleName : '');
    }

    isManuallyEnter() : boolean {
        return this.nameType === TitleDeedNameTypes.MANUAL;
    }

    cleanUpTitleDeedName()  {
        this.surName = null;
        this.firstName = null;
        this.middleName = null;
    }
}
