import {Tab} from '../shared/tabbing/tab';
import {ErrorTab} from '../shared/error-handling/error-tab';
import {EventListState} from './event-list-state';
import {EventComponent} from './event.component';
import {EventData} from './event-data';

export class EventTab extends Tab {

    constructor(json? : EventTab) {
        super(json);
        if(json) {
            this.eventData = new EventData(json.eventData);
            this.errorTab = new ErrorTab(json.errorTab);
        }

    }

    eventData : EventData;
    eventComponent: EventComponent;
    errorTab: ErrorTab;

    /* state for event anchor tab */
    eventListState: EventListState;
    staffEventListState: EventListState;
    queryParams : any;

    get id() : number {
        return this.eventData && this.eventData.id;
    }

    /**
     * We need the setter so the property is not made readonly (relevant when deserializing from json) but we don't store the value,
     * as we're retrieving it dynamically from the matter (see the getter).
     * @param title
     */
    set id(id : number) {
        //Ignore, retrieved from matter
    }

    deCoupleUIComponent() : void {
        this.eventComponent = null;
    }

}
