import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class LSOForm9 extends BaseEntity {

    principalAmount : number;
    amountAdvanced: number;
    percentageOfTotalLoan: number;
    approximateValueOfProperty: number;
    meansToDetermineValueOfProperty: string;
    percentageValueMortgaged : number;
    bonusHoldBackSpecialTerms : string;
    collectionOrAdminFee: number;
    collectionOrAdminFeePayableTo: string;
    trusteePaymentDateInfo: string;
    legalBrokerageOrOtherFees: string;
    detailsOfExistingEncumbrancesOutstanding: string;
    currentAppraisalRequired: string;
    appraisalPaidBy: string;
    lawyerDoesNotExpressValidityAppraisal: string;
    lawyerHasNoInterestInBorrower: string;
    lawyerHasNoInterestInBorrowerAdvisedDate: string;
    principalAdvancedDate: string;
    detailsOfSecurity: string;
    detailsOfExistingEncumbrancesProvided: string;
    detailsOfExistingEncumbrancesProvidedDate: string;
    prospectusProvided: string;
    prospectusProvidedDate: string;
    independentAppraisalProvided: string;
    independentAppraisalProvidedDate: string;
}
