import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isSameMonth} from 'date-fns';
import {Subject} from 'rxjs';
import {CalendarEvent, CalendarMonthViewDay} from 'angular-calendar';
import EventUtils from '../event.utils';
import {PublicHolidayService} from '../../shared-main/public-holiday/public-holiday.service';

declare var jQuery : any;
@Component({
    selector: 'dp-event-calendar',
    templateUrl: 'event-calendar.component.html',
     styleUrls: [
                   './event-calendar.component.scss'
               ]
})
export class EventCalendarComponent implements OnInit {
    @Input() events : CalendarEvent[];
    @Input() viewDate: Date;
    @Input() loading: boolean;
    @Input() printView: boolean = false;
    @Input() calendarAlwaysVisible : boolean = false;
    @Input() showEventsOnClick : boolean = true;
    @Output() openMatterTab = new EventEmitter();
    @Output() dayClick = new EventEmitter();
    @Output() beforeViewRender = new EventEmitter();
    @Input() showHoliday: boolean = true;
    refresh: Subject<any> = new Subject();
    activeDayIsOpen: boolean = false;

    constructor(private publicHolidayService: PublicHolidayService) {
    };

    ngOnInit() {

    }

    getEntries(obj) {
        // hack to support browsers like IE11 that do not support Object.entries
        return Object.entries ? Object.entries(obj) : Object.keys(obj).map(key => [key, obj[key]]);
    }
    beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
        // setting up customization on individual calendar days for grouping the calendar entries into categories, see #customCellTemplate in HTML
        body.forEach(cell => {
            const groups: any = {};
            cell.events.forEach((event: CalendarEvent<{ type: string }>) => {
                groups[event.meta.type] = groups[event.meta.type] || [];
                groups[event.meta.type].push(event);
            });
            cell['eventGroups'] = this.getEntries(groups);
            if(this.viewDate.getMonth() == cell.date.getMonth() && this.publicHolidayService && this.publicHolidayService.getHolidayByFullDate(cell.date)) {
                 cell.cssClass = 'nationalHolidayDayBg';
                //cell.cssClass = 'cal-weekend';
            }
        });
        this.beforeViewRender.emit();
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate) && !this.printView) {
            this.viewDate = date;
            this.dayClick.emit({value : date});
            events.length > 0 && this.showEventsOnClick ? this.activeDayIsOpen = true : this.activeDayIsOpen = false;
            setTimeout(() => {
                // hack for printing background colors of event dots
                jQuery('.cal-event').each(function() {
                let styles =  jQuery(this).attr('style');
                styles = styles.replace(");", ") !important;");
                jQuery(this).attr('style', styles + '-webkit-print-color-adjust: exact !important;');
            })}, 1000);
            setTimeout(()=>{
                // add 'dp-selected-cell' class to the selected cell to style it if needed
                jQuery('mwl-calendar-month-cell').removeClass('dp-selected-cell');
                jQuery('.current-selected-day').closest('mwl-calendar-month-cell').addClass('dp-selected-cell');
            }, 0);

        }
    }


    handleEvent(action: string, event: CalendarEvent): void {
        this.openMatterTab.emit({value : event});
    }


    isCurrentSelectedDaySameAsCalendarDay(currentSelectedDate:string,displayDate:string): boolean{
        return EventUtils.formatDateUsingMoment(currentSelectedDate)==EventUtils.formatDateUsingMoment(displayDate);
    }

    getHoliday(date: Date) {
        if(this.viewDate.getMonth() == date.getMonth()) {
            const holiday = this.publicHolidayService.getHolidayByFullDate(date);
            return holiday ? holiday : '';
        }
        return '';
    }

    isHoliday(date: Date) {
        const holiday = this.publicHolidayService.getHolidayByFullDate(date);
        return !!holiday ;
    }
}
