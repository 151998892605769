import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';
import moment from 'moment';
import {MortgagePayoutAdditionalPayments} from './mortgage-payout-amount';
import {MortgageUtil} from '../../../../shared-main/mortgage-utility';

export class MortgagePayout extends BaseEntity {
    id : number;
    useMortgagePayoutCalculator : boolean;
    amountPayableToDischarge : number;
    inclusiveOfInterestToDate : string;
    principalDate : string;
    principalAmount : number;
    separateChequeForDischargeFee : string;
    mortgageInterestType : string;
    interestPerDay : number;
    interestPerAnnum : number;
    dischargeAmount : number;
    chequePayableTo : string;
    perDiemCalculationPeriod : string;
    mortgagePayoutAdditionalPayments : MortgagePayoutAdditionalPayments[];
    mortgagePayoutInterest : number;
    //the following field may means equals 'Trust Ledger Balance' OR 'Direction To Pay Balance'
    // depends on the paymentAmountsDisplayed in the matter + MatterType  + ProvinceCode
    payoutEqualsTrustBalance : boolean;
    insertSeparateChequeTL: boolean;

    constructor(mortgagePayout? : MortgagePayout) {
        super(mortgagePayout);
        if(!mortgagePayout) {
            this.useMortgagePayoutCalculator = false;
            this.payoutEqualsTrustBalance = false;
        }
        else if(mortgagePayout){
            this.mortgagePayoutAdditionalPayments = [];
            if(mortgagePayout.mortgagePayoutAdditionalPayments && mortgagePayout.mortgagePayoutAdditionalPayments.length > 0)
            {
                if(Array.isArray(mortgagePayout.mortgagePayoutAdditionalPayments)) {
                    for(let i : number = 0; i < mortgagePayout.mortgagePayoutAdditionalPayments.length; ++i) {
                        this.mortgagePayoutAdditionalPayments[i] = new MortgagePayoutAdditionalPayments(mortgagePayout.mortgagePayoutAdditionalPayments[i]);
                    }
                }
            }
        }
    }

    isPlusOptionPerDiem() : boolean {
        return (this.mortgageInterestType == 'PER_DIEM');
    }

    calculateDateDiff() : number {
        let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;

        if(this.principalDate && this.perDiemCalculationPeriod && pattern.test(this.perDiemCalculationPeriod) && pattern.test(this.principalDate) &&
            (moment(this.perDiemCalculationPeriod, "YYYY/MM/DD") >= moment(this.principalDate, "YYYY/MM/DD"))) {
            return (moment(this.perDiemCalculationPeriod, "YYYY/MM/DD").diff(moment(this.principalDate, "YYYY/MM/DD"), 'days') );
        }
        else {
            return 0;
        }
    }

    calculateInterest():number {
        let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
        if(this.principalDate && this.perDiemCalculationPeriod && pattern.test(this.perDiemCalculationPeriod) && pattern.test(this.principalDate) &&
            (moment(this.perDiemCalculationPeriod, "YYYY/MM/DD") >= moment(this.principalDate, "YYYY/MM/DD"))) {

            this.mortgagePayoutInterest = MortgageUtil.calculateInterest(this.principalDate, this.perDiemCalculationPeriod, this.principalAmount, this.interestPerAnnum);
            return this.mortgagePayoutInterest;
        } else {
            return 0;
        }
    }

/*  THIS METHOD IS DEPRICATED AS IT DID NOT CALCULATE LEAP YEAR CORRECTLY
    calculateInterest() : number {
        let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
        if(this.principalDate && this.perDiemCalculationPeriod && pattern.test(this.perDiemCalculationPeriod) && pattern.test(this.principalDate) &&
            (moment(this.perDiemCalculationPeriod, "YYYY/MM/DD") >= moment(this.principalDate, "YYYY/MM/DD"))) {
            let interest : number = 0;
            let yearOfInterestFrom = Number(this.principalDate.split('/')[0]);
            let numberOfYears = (moment(this.perDiemCalculationPeriod, "YYYY/MM/DD").diff(moment(this.principalDate, "YYYY/MM/DD"), 'years'));
            if(numberOfYears < 1)
            {

                let numberOfDays = this.getNumberOfDays(Number(yearOfInterestFrom),numberOfYears);
                let yearOfInterest = Number(this.principalDate.split('/')[0]);
                let interestPerDay = this.calculateInterestPerDay(yearOfInterest, (this.interestPerAnnum/100));
                interest = (numberOfDays * interestPerDay * this.principalAmount);
            }
            else{
                let numberOfDays : number = 0;

                for(let i =0 ; i <= numberOfYears ; i++)
                {
                    let yearOfInterest = Number(yearOfInterestFrom) + i;
                    let interestPerDay = this.calculateInterestPerDay(yearOfInterest, (this.interestPerAnnum/100));
                    numberOfDays = (i ==0 ? Number(this.getNumberOfDays(Number(yearOfInterest)+1,numberOfYears)) :
                                    Number(this.getNumberOfDays(Number(yearOfInterest)+1,numberOfYears, yearOfInterest+"/01/01")));

                    interest = interest + ( i == numberOfYears ? Number(numberOfDays * interestPerDay * this.principalAmount) : Number((numberOfDays + 1)  * interestPerDay * this.principalAmount));

                }
            }
            return Number(interest.toFixed(2));
        }
        else {
            return 0;
        }

    }

    private getNumberOfDays( year : number , noOfYears : number , fromDate? : string) : number {
        let dateTo =  moment(this.perDiemCalculationPeriod, "YYYY/MM/DD");
        let dateYearEnd =  moment(year+"/01/01", "YYYY/MM/DD");
        let dateFrom =  fromDate ?  moment(fromDate, "YYYY/MM/DD") : moment(this.principalDate, "YYYY/MM/DD");
        if(noOfYears == 0)
        {
            return dateTo.diff(dateFrom, 'days');
        }
        else if(dateTo > dateYearEnd)
        {
            return  moment((year -1)+"/12/31", "YYYY/MM/DD").diff(dateFrom, 'days');
        }
        else
        {
            return dateTo.diff(moment((year -1)+"/01/01", "YYYY/MM/DD"), 'days');
        }
    }

    private calculateInterestPerDay(year: number , interest : number) : number {

        if(new Date(year, 2, 29).getDate() === 2)
        {
            return interest/366;
        }
        else{
            return interest/365;
        }
    }
    */

    calculateInterestPerDiem() : number {
        this.mortgagePayoutInterest = this.interestPerDay ? Number((this.calculateDateDiff() * this.interestPerDay).toFixed(2)) : 0;
        return this.mortgagePayoutInterest;
    }

    get total() : number{
      return  Number(this.dischargeAmount && !this.isDischargeFeeApplied() ? this.dischargeAmount : 0) + Number(this.principalAmount ? this.principalAmount : 0)+ Number(this.isPlusOptionPerDiem() ? this.calculateInterestPerDiem() : this.calculateInterest())
        + Number(this.mortgagePayoutAdditionalPayments && this.mortgagePayoutAdditionalPayments.length > 0 ? this.mortgagePayoutAdditionalPayments.map(item => item.additionalAmount).reduce((prev, next) => Number(prev) + Number(next)): 0);
    }

    isDischargeFeeApplied() : boolean {
        return(this.separateChequeForDischargeFee == 'YES');
    }

    cleanUpMortgagePayout() : void {
        if(this.useMortgagePayoutCalculator)
        {
            this.inclusiveOfInterestToDate = undefined;
            this.amountPayableToDischarge = 0;
        }
        else{
            this.principalDate = undefined;
            this.principalAmount = 0;
            this.mortgageInterestType = undefined;
            this.interestPerDay = 0;
            this.interestPerAnnum = 0;
            this.perDiemCalculationPeriod = undefined;
            this.mortgagePayoutAdditionalPayments = [];
            this.mortgagePayoutInterest = 0;
        }
    }
}
