import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {Telephone} from '../telephone';
import {Address} from '../address';
import {TelephoneTypes} from '../telephone-types';
import {AddressTypes} from '../address-types';
import {CirfIdentification} from './cirf-identification';
import {DpBooleanValueTypes} from '../dp-boolean';
import {CirfUdf} from './cirf-udf';

export class CirfClientInfo extends BaseEntity {

    id : string;
    firstName : string ;
    middleName : string ;
    lastName : string ;
    gender : string ;
    birthDate : string;
    isCanadianResident : string ;
    email : string ;
    firstTimeHomeBuyer : string;

    residentStatus: string;
    spousalStatus: string;
    isFamilyResidence: string;
    isSpouseOnTitle: string;
    spouseName: string;
    telephones : Telephone[] = [];
    addresses : Address[] = [];
    occupation: string ;
    identifications: CirfIdentification[];
    // This flag indicates if the user checked "I do not have all details for this client" checkbox.
    allowMissingFields: boolean;

    spouseId: string;
    cirfClientInfoUdfs : CirfUdf[] = [];
    hasErrors : boolean ;

    constructor(cirfClientInfo?: CirfClientInfo) {
        super (cirfClientInfo);
        if(cirfClientInfo){
            this.telephones = [];
            if(cirfClientInfo.telephones){
                cirfClientInfo.telephones.forEach((telephone)=>{
                    this.telephones.push(new Telephone(telephone));
                });
            }
            this.addresses = [];
            if(cirfClientInfo.addresses){
                cirfClientInfo.addresses.forEach((address)=>{
                    this.addresses.push(new Address(address));
                });
            }
            this.identifications = [];
            if (cirfClientInfo.identifications) {
                cirfClientInfo.identifications.forEach((identification: CirfIdentification) => {
                    this.identifications.push(new CirfIdentification(identification));
                });
            }

            if(Array.isArray(cirfClientInfo.cirfClientInfoUdfs)){
                this.cirfClientInfoUdfs = cirfClientInfo.cirfClientInfoUdfs.map(cirfUdf => new CirfUdf(cirfUdf));
            }

        } else {
            this.firstName = '';
            this.middleName = '';
            this.lastName = '';
            this.gender = 'QUESTION';
            this.birthDate = '';
            this.isCanadianResident = 'Y_n';
            this.email = '';
            this.firstTimeHomeBuyer = '';

            this.residentStatus = '';
            this.spousalStatus = '';
            this.isFamilyResidence = '';
            this.isSpouseOnTitle = '';
            this.spouseName = '';
            this.initializePhones([TelephoneTypes.work, TelephoneTypes.cell, TelephoneTypes.home]);
            this.occupation = '';
            this.initializeAddresses([AddressTypes.clientIdBusiness]);

            this.hasErrors = false;
        }
    }

    initializePhones(phoneTypes : string[]) : void {
        for(let phoneType of phoneTypes) {
            let phone : Telephone = this.telephones.find((t : Telephone) => t.phoneTypeCode === phoneType);

            if(!phone) {
                phone = new Telephone();

                phone.phoneTypeCode = phoneType;

                this.telephones.push(phone);
            }
        }
    }

    get homePhone() : string {
        return this.getPhone(TelephoneTypes.home).telephoneNumber;
    }

    set homePhone(phone : string) {
        this.getPhone(TelephoneTypes.home).telephoneNumber = phone;

    }

    get cellPhone() : string {
        return this.getPhone(TelephoneTypes.cell).telephoneNumber;
    }

    set cellPhone(phone : string) {
        this.getPhone(TelephoneTypes.cell).telephoneNumber = phone;
    }

    get workPhone() : string {
        return this.getPhone(TelephoneTypes.work).telephoneNumber;
    }

    set workPhone(phone : string) {
        this.getPhone(TelephoneTypes.work).telephoneNumber = phone;
    }

    getPhone(type : string) : Telephone {
        if(!this.telephones) {
            this.telephones = [];
        }

        let phone : Telephone = this.telephones.find((t : Telephone) => t.phoneTypeCode === type);
        if(!phone) {
            phone = new Telephone();
            phone.phoneTypeCode = type;
            this.telephones.push(phone);
        }
        return phone;
    }

    initializeAddresses(addressTypes : string[]) : void {
        for(let addressType of addressTypes) {
            let address : Address = this.addresses.find((address : Address) => {
                return address.addressTypeCode === addressType;
            });

            if(!address) {
                address = new Address();

                address.addressTypeCode = addressType;
                address.setAddressHash();

                this.addresses.push(address);
            }
        }
    }

    getAddress(type: string) : Address {
        if(!this.addresses){
            this.addresses = [];
        }
        let address : Address = this.addresses.find((address : Address) => {
            return address.addressTypeCode === type;
        });
        if(!address) {
            address = new Address();
            address.addressTypeCode = type;
            this.addresses.push(address);
        }
        return address;
    }

    get businessAddress() : Address {
        return this.getAddress(AddressTypes.clientIdBusiness);
    }

    set businessAddress(newAddress : Address) {
        let businessAddress = this.getAddress(AddressTypes.clientIdBusiness);
        businessAddress.addressLine1 = newAddress.addressLine1;
        businessAddress.addressLine2 = newAddress.addressLine2;
        businessAddress.city = newAddress.city;
        businessAddress.provinceCode = newAddress.provinceCode;
        businessAddress.postalCode = newAddress.postalCode;
        businessAddress.country = newAddress.country;
    }

    getIdentification(index: number): CirfIdentification {
        return this.identifications.length > index-1 && this.identifications[index];
    }

    hasIdentifications(): boolean {
        return this.identifications && !!this.identifications.length;
    }

    isCanadianResidentYes() : boolean {
        return this.isCanadianResident == DpBooleanValueTypes.YES;
    }

    isSpousalStatusMarried() : boolean {
        return this.spousalStatus == 'MARRIED';
    }

    isSpouseNotOnTitle() : boolean {
        return this.isSpouseOnTitle == DpBooleanValueTypes.NO;
    }

    isSpouseNameVisible() : boolean {
        return this.isSpouseNotOnTitle() && this.isSpousalStatusMarried();
    }

    isDriverLicenceSelected(index: number) : boolean {
        return this.identifications
               && this.identifications.length > index
               &&this.identifications[index].identificationTypeCode === "Driver's License";
    }

    getFullName(): string {
        let fullName: string = (this.firstName ? (this.firstName+' ') : '')+
            (this.middleName ? (this.middleName+' ') : '')+
            (this.lastName ? this.lastName : '');

        return fullName.trim();
    }

    udfNotes(): string {
        let note = '';
        if (Array.isArray(this.cirfClientInfoUdfs)) {
            this.cirfClientInfoUdfs.forEach(udf => {
                note += udf.fieldValue ? `${udf.fieldLabel}: ${udf.fieldValue}\n` : '';
            });
        }
        return note;
    }
}


