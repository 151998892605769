import { Address } from "..";
import { BaseEntity } from "../../shared/BaseEntity/base-entity";
import { BeneficiariesOrCoOwners } from "./beneficiaries-or-coowners";

export type AssetType = 'PROPERTIES' | 'SPECIFIC_LEGACY' | 'PERSONAL_BELONGINGS' | 'SPECIFIC_ASSETS' | 'DESIGNATED_BENEFICIARIES' | 'LOANS' | 'JOINTLY_HELD';
export type SpecificAssetType = 'ITEM' | 'VEHICLE' | 'GENETIC_MATERIAL';
export type DesignatedBeneficiariesType = 'INSURANCE' | 'RESP' | 'DESIGNATED_ACCOUNTS';
export type OtherTypes = 'REAL_PROPERTY' | 'LIFE_INTEREST' | 'OPTION_TO_PURCHASE_PROPERTY' | 'HOME_TRUST_FOR_CHILDREN';
export type WillAssetAndDebtType = SpecificAssetType | OtherTypes | DesignatedBeneficiariesType | LoanTypeEnum | JointlyHeldAssetType;
export type JointlyHeldAssetType = 'GENERAL' | 'SPECIFIC'
export type VehicleType = 'PERSONAL' | 'SPECIFIC';
export type WillPersonalBelongingsReceiverType = 'CHILDREN' | 'CHILDREN_TRUST' | 'TRUSTEE_DISCRETION' | 'INDIVIDUAL';
export type TransferType = 'TRUSTEE_DISCRETION' | 'INDIVIDUAL';
export type BeneficiaryType = 'INDIVIDUAL' | 'CHARITY' | 'CHILDREN';

export enum WillAssetAndDebtTypeEnum {
    INSURANCE = 'Insurance',
    RESP = 'Registered Education Savings Plans',
    DESIGNATED_ACCOUNTS = 'Designated Accounts and Plans'
}
export enum LoanTypeEnum {
    FORGIVENESS_DEBT = 'FORGIVENESS_DEBT',
    HOTCHPOT_CHILDREN = 'HOTCHPOT_CHILDREN',
    HOTCHPOT_SPECIFIC_LOAN = 'HOTCHPOT_SPECIFIC_LOAN'
}
export type CoOwnerType = 'CO_OWNER' | 'ESTATE';

export class WillAssetAndDebts extends BaseEntity {
    id?: number;
    address?: Address;
    ageOfMajorityOfYoungestChild?: number;
    assetAmount?: number;
    assetType: AssetType;
    beneficiaryType?: BeneficiaryType;
    beneficiariesOrCoOwners?: BeneficiariesOrCoOwners[] = [];
    charityName: string;
    charityRegNo: string;
    description?: string;
    dispositionAppliesToSpouse?: boolean;
    distributePersonalBelongingsTo?: WillPersonalBelongingsReceiverType;
    includeVehiclesAndAccessories?: boolean;
    issuingCompany?: string
    itemsInProperty?: string;
    lastUpdatedTimeStamp?: string;
    lifeEstateName?: string;
    location?: string;
    petDispositionAppliesToSpouse?: boolean;
    policyNo?: string;
    type?: WillAssetAndDebtType;
    willVehicleType?: VehicleType;
    vin?: string;
    willMatterId: number;
    transferTo?: TransferType | CoOwnerType;

    isDirty: boolean = false;
    orderIndex?: number;

    constructor(willAssetsAndDebts?: WillAssetAndDebts) {
        super(willAssetsAndDebts);
        if (willAssetsAndDebts) {
            if (willAssetsAndDebts.beneficiariesOrCoOwners?.length) {
                willAssetsAndDebts.beneficiariesOrCoOwners
                    .forEach((beneficiary) => this.beneficiariesOrCoOwners
                        .push(new BeneficiariesOrCoOwners(beneficiary)));
            }
        }
    }
}
