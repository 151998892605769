import {Injectable} from '@angular/core';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ThirdPartyCredentialsComponent} from '../../main/manage-thirdparty-credentials/third-party-credentials.component';
import {Observable} from 'rxjs/Rx';
import {TitlePlusUser} from './title-plus-user';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {HttpClient} from '../../core';
import {titlePlusApi} from './title-plus-constants';
import {TitlePlusMatterUser} from './title-plus-matter-user';
import {Matter} from '../../matters';
import {TitlePlusCreateDealResponse} from './title-plus-create-deal-response';
import {TitlePlusDealOrderStatusResponseDto} from './title-plusp-deal-order-status-response.dto';
import {TitlePlusDocument} from './title-plus-document';

@Injectable()
export class TitlePlusService {


    constructor(private dialogService: DialogService,
                private http: HttpClient) {
    }

    saveTitlePlusUser(titlePlusUser: TitlePlusUser): Observable<TitlePlusUser> {
        let observable: Observable<TitlePlusUser> = new Observable<TitlePlusUser>(observer => {
            this.createOrUpdateTitlePlusUser(titlePlusUser).subscribe((savedUser: TitlePlusUser) => {
                observer.next(new TitlePlusUser(savedUser));
                this.dialogService.confirm('Confirmation', ThirdPartyCredentialsComponent.getSuccessMessage('TitlePLUS'), true, 'OK').subscribe(res => {
                    if (res) {
                    }
                });
            });
        });
        return observable;


    }

    getTitlePlusAuthUrl(): Observable<string> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url = titlePlusApi.titlePlusAuthenticationUrl();
        return this.http.get(url)
            .map((res) => {
                return res['TitlePlusAuthorizationUrl'];
            });
    }

    createOrUpdateTitlePlusUser(titlePlusUser: TitlePlusUser): Observable<TitlePlusUser> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url: string = titlePlusApi.titlePlusUser(userId);

        if(titlePlusUser && titlePlusUser.id) {
            url += "/"+titlePlusUser.id;
            return this.http.put(url, titlePlusUser)
                .map((res) => {
                    return new TitlePlusUser(res['TitlePlusUser']);
                });
        } else {
            return this.http.post(url, titlePlusUser)
                .map((res) => {
                    return new TitlePlusUser(res['TitlePlusUser']);
                });
        }
    }

    deleteTitlePlusUser(titlePlusUser : TitlePlusUser): Observable<any> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url: string = titlePlusApi.titlePlusUser(userId);
        url += "/"+titlePlusUser.id;
        return this.http.delete(url);
    }

    getTitlePlusUser(): Observable<TitlePlusUser> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url: string = titlePlusApi.titlePlusUser(userId);
        return this.http.get(url)
            .map((res) => {
                return new TitlePlusUser(res['TitlePlusUser']);
            });
    }

    getSolicitorTitlePlusUsers(): Observable<TitlePlusUser[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = titlePlusApi.solicitorTitlePlusUsers(accountId);
        return this.http.get(url)
            .map((res) => {
                return (res['SolicitorTitlePlusUsers']).map((item) => {
                    return new TitlePlusUser(item);
                });
            });
    }

    getTitlePlusUsersForMatter(matterId: number): Observable<TitlePlusMatterUser> {
        return this.http.get(titlePlusApi.titlePlusUsersForMatter(matterId))
            .map((res) => {
                return new TitlePlusMatterUser(res['TitlePlusMatterUsers']);
            });
    }

    createDealRequest(matter: Matter, titleUser: TitlePlusMatterUser): Observable<TitlePlusCreateDealResponse> {
        let url: string = titlePlusApi.titlePlusCreateDeal(matter.id,titleUser.relevantUser.id, titleUser.solicitorUser.id);
        return this.http.post(url, matter.id) //As post method require some data therefore passing matter id in it, though it's present in url too.
            .map((response) => {
                return new TitlePlusCreateDealResponse(response['TitlePlusDeal']);
            });
    }

    refreshDealStatus (matter: Matter, titleUser: TitlePlusUser, dealId: string): Observable<TitlePlusDealOrderStatusResponseDto> {
        let url: string = titlePlusApi.titlePlusOrderStatus(matter.id,titleUser.id,dealId);
        return this.http.post(url, matter.id)
            .map((response) => {
                return new TitlePlusDealOrderStatusResponseDto(response['TitlePlusOrderStatusResponse']);
            });
    }

    getTitlePlusPolicyURL(matterId: number,  titleUser: TitlePlusUser): Observable<String> {
        return this.http.get(titlePlusApi.titlePlusPolicyURL(matterId, titleUser.id))
            .map((res) => {
                return (res['TitlePlusPolicyURL']);
            });
    }

    deleteDocuments(user: TitlePlusUser, matter: Matter): Observable<any>{
        let url: string = titlePlusApi.titlePlusDeleteDocuments(matter.id, user.id);
        return this.http.delete(url);
    }

    downloadDocument(user: TitlePlusUser, matter: Matter, doc: TitlePlusDocument): Observable<any> {
        let url: string = titlePlusApi.titlePlusDownloadDocument(matter.id, user.id);
        return this.http.post(url, doc);
    }


}
