import {Injectable} from '@angular/core';
import {HttpClient} from '../../../core';
import {accountApi} from '../../accounts/shared/account-api';
import {AccountReminderConfiguration} from './account-reminder-configuration';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class AccountReminderConfigurationService {

    constructor(private http: HttpClient) {
    }

    updateAccountReminderConfiguration(accountReminderConfiguration: AccountReminderConfiguration, accountId: string): Observable<AccountReminderConfiguration> {
        let url = accountApi.accountReminderConfiguration(accountId);
        return this.http.put(url, JSON.stringify(accountReminderConfiguration))
            .map((res) => {
                let data = res['AccountReminderConfiguration'];
                return new AccountReminderConfiguration(data);
            });
    }

    getReminderNotificationConfig(accountId: string): Observable<AccountReminderConfiguration> {
        let url = accountApi.accountReminderConfiguration(accountId);
        return this.http.get(url)
            .map((res ) => {
                let data = res['AccountReminderConfiguration'];
                return new AccountReminderConfiguration(data);
            });
    }
}
