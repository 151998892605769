import {SalePriceAdjustmentBase} from './sale-price-adjustment-base';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {ManitobaSalePriceAdjustmentConstants, SalePriceAdjustmentThresholdItem} from './sale-price-adjustment-constants';
import Utils from '../../../shared-main/utils';

export class SalePriceAdjustmentManitobaStrategy extends SalePriceAdjustmentBase{

    readonly divideSalePriceFactor: number = 1.05;

    provinceCode: ProvinceCode = 'MB';

    showRebateProvincialPortion(): boolean{
        return false; // no provincial rebate for Alberta
    }

    getDivideSalePriceFactor(rate : number){
        return Number(1 + rate/100);
    }

    calculateDerivedNetSalePrice(excludeAdditionalConsiderationsInclHst: boolean = false) : number {
        const total = this.total(excludeAdditionalConsiderationsInclHst);
        const multiplier = this.getMultiplier(this.getThreshold(total));
        const hasFederalRebate = this.federalPortion
            && (multiplier == ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESH0LD || multiplier == ManitobaSalePriceAdjustmentConstants.DIVIDE_THRESH0LD_MULTIPLIER);

        let federalRebateAdjustment = 0;
        if(hasFederalRebate) {
            federalRebateAdjustment = (Number(ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_MULTIPLIER) * Number(ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD));
        }

        const totalPrice : number = Number(total) + Number(federalRebateAdjustment);
        return Number(((this.isDivideSalePrice() ? total : totalPrice) / multiplier).toFixed(2));
    }

    calculateRebate(hstPortion : number) : number {
        if(this.isDivideSalePrice() || !this.isInclusivePrice()) {
            return 0;
        }
        else {
            if(this.federalPortion) {
                let derivedNetSalePrice:number;
                derivedNetSalePrice = this.calculateDerivedNetSalePrice();

                if(derivedNetSalePrice > ManitobaSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD) {
                    return 0;
                }
                else if(derivedNetSalePrice < ManitobaSalePriceAdjustmentConstants.DEMINISHING_FEDERAL_THRESHOLD) {
                    let netPrice : number = (derivedNetSalePrice * ((hstPortion / 100) * ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION));
                    if((ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD * (-1)) > (netPrice * (-1))) {
                        return ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD;
                    }
                    else {
                        return netPrice;
                    }
                }
                else {
                    let netPrice : number = (derivedNetSalePrice * ((hstPortion / 100) * ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION));
                    if((ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD * (-1)) > (netPrice * (-1))) {
                        return ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD * ((ManitobaSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD - derivedNetSalePrice) / 100000);
                    }
                    else {
                        return netPrice * ((ManitobaSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD - derivedNetSalePrice) / 100000);
                    }
                }
            }
            else {
                return 0;
            }
        }
    }

    calculateFederalRebatePortion(federalTaxRate: number, netSalePrice: number) : number {
        if(!this.netOutHstFromHSTSalePrice){
            this.netOutHstFromHSTSalePrice = 'NO';
        }
        switch(this.netOutHstFromHSTSalePrice){
            case 'NO' :
            case 'YES_DIVIDE_SALE_PRICE':
                if(netSalePrice > ManitobaSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD){
                    return 0;
                }
                let rebateAmt : number = federalTaxRate && netSalePrice ? (federalTaxRate / 100)  * ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION * netSalePrice : 0;
                let federalRebate = rebateAmt > ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD ? ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD : rebateAmt;
                if(netSalePrice < ManitobaSalePriceAdjustmentConstants.DEMINISHING_FEDERAL_THRESHOLD){
                    return federalRebate;
                }else{
                    return  federalRebate * (ManitobaSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD - netSalePrice) / 100000;
                }
            case 'YES_FACTOR_IN_HST_REBATE':
                // adopt the same value from same itemLine as in Sale Price Adjustment
                return this.calculateRebate(federalTaxRate);
            default:
                return 0;
        }
    }

    federalRebatePortion() : number {
        return ManitobaSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION;
    }

    /**
     * DPPMP-25311: adding new logic for GST calculation
     */
    calculateHstPortion(hstPortion : number, isTaxOut? : boolean, returnAsIs?: boolean) : number {

        let result: number = 0;
        if(isTaxOut){
            result = Number(this.agreementSalePrice) * (hstPortion / 100);

        } else {
            const total = this.total();
            if(this.isDivideSalePrice()) {
                result = Number( (total / (this.getDivideSalePriceFactor(hstPortion) * 100)) * hstPortion);

            } else if ( this.isFactorInHstRebate()) {
                const multiplier = this.getMultiplier(this.getThreshold(total));
                if (multiplier === ManitobaSalePriceAdjustmentConstants.GST_TIER_THRESHOLD) {
                    result = (hstPortion && hstPortion != 0.0) ? total * hstPortion / (100 * multiplier) : 0;
                } else {
                    result = (hstPortion && hstPortion != 0.0) ? total - this.calculateNetSalePrice(hstPortion) : 0;
                }
            }
        }

        if(returnAsIs) {
            return result; // rounding is not needed to avoid extra penny in certain cases
        }
        else {
            return Utils.roundCurrencyTCStyle(result); // rounding is needed to avoid extra penny in certain cases
        }
    }

    private getThreshold(calculatedTotal: number) : SalePriceAdjustmentThresholdItem {
        const key = 'F_' + (this.federalPortion ? 'E' : 'D') ;
        const thItems = ManitobaSalePriceAdjustmentConstants.TIER_1_THRESHOLD[key];
        return thItems.find(item => item.THRESHOLD_AMOUNT >= calculatedTotal);
    }

    private getMultiplier( tier1Threshold : SalePriceAdjustmentThresholdItem) : number {

        let multiplier = ManitobaSalePriceAdjustmentConstants.DIVIDE_NET_OUT_MULTIPLIER;
        if(tier1Threshold !== undefined && tier1Threshold !== null && tier1Threshold.MULTIPLIER > 0) {
            multiplier = tier1Threshold.MULTIPLIER;
        }
        return multiplier;
    }

    private calculateNetSalePrice(hstPortion : number) :number {
        return this.calculateDerivedNetSalePrice() - this.calculateFederalRebatePortion(hstPortion, this.agreementSalePrice);
    }

    totalNetSalePrice(federalHstPortion: number, provinceHstPortion: number, excludeAdditionalVendorConsid: boolean = false): number {
        let result: number = this.total();
        let currValue: number = Number(this.calculateHstPortion(federalHstPortion));

        result = Utils.roundCurrency(result - currValue);
        currValue = Number(this.calculateRebate(federalHstPortion));

        result = Utils.roundCurrency(result + currValue);
        if (!excludeAdditionalVendorConsid) {
            currValue = Number(this.additionalVendorConsidNotEligibleForTaxRebate);
            result = Utils.roundCurrency(result + currValue);
            currValue = Number(this.additionalVendorConsidNotEligibleForTaxRebatePlusTax);
            result = Utils.roundCurrency(result + currValue);
        }
        return result;
    }

}
