import {Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {Subject} from 'rxjs';
import {TemplatePortal} from '@angular/cdk/portal';
import {PopoverService} from './popover.service';

declare var jQuery: any;

@Directive({
    selector: '[popoverTrigger]'
})
export class PopoverDirective implements OnDestroy, OnInit {
    @Input()
    popoverTrigger!: TemplateRef<object>;

    @Input()
    closeOnClickOutside: boolean = false;

    @Input()
    popoverData: any;

    @Input()
    startPosition: boolean;


    private unsubscribe = new Subject();
    private overlayRef!: OverlayRef;
    private defaultPosition: any = {overlayX: 'center', overlayY: 'top'};


    constructor(
        private elementRef: ElementRef,
        private overlay: Overlay,
        private vcr: ViewContainerRef,
        private popoverService: PopoverService
    ) {
    }

    ngOnInit(): void {
        this.createOverlay();
        this.popoverService.getState().subscribe(resp => {
            if (resp) {
                this.detachOverlay();
            }
        });
    }

    ngOnDestroy(): void {
        this.detachOverlay();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    @HostListener('mouseenter') mouseenter() {
        this.attachOverlay();
    }

    @HostListener('mouseleave') mouseleave() {
        if (!this.overlayRef.getConfig().hasBackdrop) {
            this.detachOverlay();
        }
    }

    @HostListener('click') dblclick() {
        this.attachOverlay(true);
    }

    private updateLeftPosition(): void {
        let browserWidth = Math.floor(window.innerWidth);
        let elementXDistance = Math.floor(this.elementRef.nativeElement.getBoundingClientRect().x);
        let distance = browserWidth - elementXDistance;
        if (distance < 395) {
            const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withFlexibleDimensions(false).withPositions([{
                originX: "start",
                originY: "top",
                overlayX: "center",
                overlayY: "top"
            }])
            this.overlayRef.addPanelClass('left-staff-view-popover');
            this.overlayRef.updatePositionStrategy(positionStrategy);
        }

    }

    private updateTopPosition(): void {
        let topDistance = Math.floor(jQuery('.staff-view-popover').height() + this.elementRef.nativeElement.getBoundingClientRect().top);
        let maxDistance = window.innerHeight - 30;
        if (topDistance > maxDistance) {
            const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withFlexibleDimensions(false).withPositions([{
                originX: "start",
                originY: "top",
                overlayX: "center",
                overlayY: "top"
            }])
            this.overlayRef.updatePositionStrategy(positionStrategy);
            let topHeight = -Math.floor(jQuery('.staff-view-popover').height() + 10) + 'px';
            //jQuery(this.overlayRef.overlayElement).find('.staff-view-popover').css({left : '0px !important' , top : topHeight+"px !important"});
            jQuery(this.overlayRef.overlayElement).find('.staff-view-popover').css({ left: '0px' });
            jQuery(this.overlayRef.overlayElement).find('.staff-view-popover').css({ top: topHeight });
            this.overlayRef.addPanelClass('top-staff-view-popover');
        }
    }

    @HostListener('document:keydown.escape') escape() {
        this.detachOverlay();
    }

    private createOverlay(): void {
        const scrollStrategy = this.overlay.scrollStrategies.noop();
        let positionOriginX: any = this.startPosition ? 'start' : 'end';
        let positionOriginY: any = this.startPosition ? 'bottom' : 'top';
        let positionStrategy: any = this.overlay.position().flexibleConnectedTo(this.elementRef).withFlexibleDimensions(false).withPositions([{
            originX: positionOriginX,
            originY: positionOriginY,
            overlayX: "center",
            overlayY: "top"
        }]);
        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy,
            hasBackdrop: false,
            backdropClass: ''
        });
    }

    private attachOverlay(addBackdropOverlay?: true): void {
        if ((!this.overlayRef.hasAttached() || addBackdropOverlay) && this.popoverData && ((this.popoverData.nonAppointments && this.popoverData.nonAppointments.length > 0) || (this.popoverData.appointments && this.popoverData.appointments.length > 0))) {
            this.updateLeftPosition();
            this.overlayRef.getConfig().hasBackdrop = false;
            this.overlayRef.getConfig().backdropClass = [];
            const periodSelectorPortal = new TemplatePortal(
                this.popoverTrigger,
                this.vcr,
                {
                    $implicit: this.popoverData,
                    close: this.detachOverlayFromContainer,
                    hasBackdrop: !!addBackdropOverlay,
                    startPosition: !!this.startPosition
                }
            );
            if (addBackdropOverlay) {
                this.overlayRef.detach();
                this.overlayRef.getConfig().hasBackdrop = true;
                this.overlayRef.getConfig().backdropClass = ['cdk-overlay-backdrop-events'];
            }
            this.overlayRef.attach(periodSelectorPortal);
            this.overlayRef.updatePosition();
            this.updateTopPosition();
        }
    }

    detachOverlayFromContainer = () => {
        this.detachOverlay();
    };

    private detachOverlay(): void {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }
}
