import {MatterParticipant} from '../matter-participant';
import {Matter} from '../matter';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {DPError} from '../../../shared/error-handling/dp-error';
import {GuarantorLastNameFieldCodes, MatterOverviewStatusTypesValue} from '../../../shared-main/constants';
import {PurchaserFamilyLawAct} from '../../purchaser/family-law-act/purchaser-family-law-act';
import Utils from '../../../shared-main/utils';
import {MatterCleanUpUtil} from './matter-clean-up-util';
import {Contact} from '../contact';
import {Address} from '../address';
import * as _ from 'lodash';
import {AddressTypes} from '../address-types';
import {MatterParticipantService} from '../../matter-participant-service';
import {provinceBasedFieldLabels} from '../../../shared-main/province-based-field-labels';
import SharedUtils from "../../../shared-main/utils";
import moment, { Moment } from 'moment';
import { AppConfig } from '../../../shared-main/app-configuration';

export class MatterErrorUtil {

    static getValidationErrors(matter: Matter): string[] {
        const errors: string[] = [];

        if (matter) {
            if (!matter.matterRecordNumber || matter.matterRecordNumber.trim().length == 0) {
                errors.push(`Matter no is required`);
            }

            if (matter.matterParticipants) {
                matter.matterParticipants.forEach((mp: MatterParticipant, mpIndex: number) => {
                    mp.validationErrors(errors, mpIndex);
                });
            }
        }
        return errors;
    }

    static checkParticipantDuplicateMailingAddress(matter: Matter, errorService: ErrorService){
        if(matter.matterParticipants && matter.matterParticipants.length > 0){
            matter.matterParticipants.forEach(mp=>{
                if(mp.contact && mp.contact.checkDuplicateMailingAddress()) {
                    errorService.addDpSaveError(DPError.createCustomDPError("matter.client.duplicateMailingAddress",
                        'There can be only one mailing address in contact ' + mp.contact.fullOrBusinessName, "Matter", 'ERROR'));

                }
            });
        }
    }

    static  anyErrorsExistOnMatter(matter: Matter, errorService: ErrorService, matterParticipantService: MatterParticipantService, appConfig: AppConfig): boolean {

        if (matter && errorService) {
            errorService.provinceCode = matter.provinceCode;
            errorService.matterType = matter.matterType;
            errorService.clearAllSaveErrors();

            if (!matter.matterRecordNumber || matter.matterRecordNumber.trim().length == 0) {
                errorService.addDpSaveError(DPError.createDPError("matter.matterOpening.matterNo", '', '', 'M10001'));
            }

            MatterErrorUtil.checkMortgagePriorityForMortgageMatter(matter, errorService);

            matter.guarantors.forEach((p: MatterParticipant) => {
                let c = p.contact;
                let mortgageIndex = matter.getMortgageIndexOrdinal(p.mortgageId);
                let index = matter.mortgages.findIndex(item => item.id == p.mortgageId);
                if (c) {
                    switch (c.gender) {
                        case 'CORPORATION':
                        case 'OTHERENTITY':
                            if (!c.organizationName) {

                                errorService.addDpSaveError(DPError.createCustomDPError(`matter.mortgages.1st.mortgagee.terms`, 'Name for Guarantor # is' +
                                    ' required.', mortgageIndex + ' Mortgage Term', 'ERROR', 0, `name_${c.identifier}`, null, `guarantorFieldIndex_${p.identifier}`, null, `mattermortgages1stmortgageeterms_container`));
                            }
                            break;

                        case 'MALE':
                        case 'FEMALE':
                        case 'QUESTION':
                        case 'MALEPOA':
                        case 'FEMALEPOA':
                        case 'ESTATE':
                        default:
                            if (!c.lastName) {
                                let fieldCode = GuarantorLastNameFieldCodes[index][matter.getGuarantors(matter.mortgages[index]).findIndex(item => item == p)];
                                errorService.addDpSaveError(DPError.createCustomDPError(`matter.mortgages.1st.mortgagee.terms`, 'Surname for Guarantor # is' +
                                    ' required', mortgageIndex + ' Mortgage Term', 'ERROR', 0, `lastName_${c.identifier}`, fieldCode, `guarantorFieldIndex_${p.identifier}`, p.mortgageId, null, `mattermortgages1stmortgageeterms_container`));
                            }
                    }
                }

            });

            matter.mainClients.forEach((purchaser: MatterParticipant) => {
                // Validating Tenure
                if (matter.isMatterProvinceMBorSKorNSorNB && matter.purchasersCapacity == 'OTHER' && (!purchaser.purchaserShare || (purchaser.purchaserShare == '0'))) {
                    errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient_tenureShareRequired`, 'A value must be entered in Tenure/Share field',
                        '<MAIN_TOPIC>', 'ERROR', 0, null, null, null, null, null));

                }
                // Validating FLA data only if it applicable
                if (PurchaserFamilyLawAct.isFamilyLawActVisibleForParticipant(purchaser, false, matter.provinceCode, matter.mainClients.length, matter.matterType)) {
                    if (MatterErrorUtil.checkFamilyLawActsErrorCondition(purchaser)) {

                        if (purchaser.authorizedByCourt || purchaser.interspousalAgreement) {
                            errorService.addDpSaveError(DPError.createDPError('matter.mainClient.interspousalAgreementSpouseNameRequired',
                                `interspousalAgreementSpouseName_${purchaser.identifier}`, 'matterPurchaserContainer', null, `clientFieldIndex_${purchaser.identifier}`, null));

                        } else {
                            if (!matter.isMatterProvinceNS && !matter.isMatterProvinceNB) {
                                errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient.consentingSpouseLastNameRequired`,
                                    "<MAIN_CLIENT> " + purchaser.matterParticipantPriority
                                    + "> " + "Spouse's surname in " + provinceBasedFieldLabels.get("participant.fla.tab.label", matter.provinceCode) +  " section is required.",
                                    '<MAIN_TOPIC>', 'ERROR', 0, `registeredOn_${purchaser.identifier}`, null,
                                    `clientFieldIndex_${purchaser.matterParticipantId}`, null, null));
                            }
                        }
                    }
                    if ((matter.isMatterProvinceAB || matter.isMatterProvinceSK) && purchaser.registeredOnDate && purchaser.registeredOnDate !== "//" && Utils.isNotValidDate(purchaser.registeredOnDate)) {
                        errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient_consentingSpouseDateRequired`,
                            "<MAIN_CLIENT> " + purchaser.matterParticipantPriority + "> Registered on Date is invalid.",
                            '<MAIN_TOPIC>', 'ERROR', 0, `registeredOn_${purchaser.identifier}`, null, `clientFieldIndex_${purchaser.identifier}`, null, null));
                    }

                    MatterErrorUtil.createNsFlaError(matter, purchaser, errorService);
                    MatterErrorUtil.createNbFlaError(matter, purchaser, errorService);
                    MatterCleanUpUtil.cleanUpNbFlaData(matter, purchaser, matterParticipantService);
                }

                const contact: Contact = purchaser.contact;
                if (contact) {
                    switch (contact.gender) {
                        case 'CORPORATION':
                        case 'OTHERENTITY':
                            if (!contact.organizationName) {
                                errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient_organizationNameRequired`, 'Name for <MAIN_CLIENT> ' + purchaser.matterParticipantPriority + ' is required.',
                                    '<MAIN_TOPIC>', 'ERROR', 0, `name_${contact.identifier}`, null, `clientFieldIndex_${purchaser.identifier}`, null, null));
                            }
                            break;

                        case 'MALE':
                        case 'FEMALE':
                        case 'QUESTION':
                        case 'MALEPOA':
                        case 'FEMALEPOA':
                        case 'ESTATE':
                        default:
                            if (!contact.lastName) {
                                errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient_lastNameRequired`, 'Surname for <MAIN_CLIENT> ' + purchaser.matterParticipantPriority + ' is required.',
                                    '<MAIN_TOPIC>', 'ERROR', 0, `lastName_${contact.identifier}`, null, `clientFieldIndex_${purchaser.identifier}`, null, null));
                            }
                            if (contact.contactCategory === 'QUESTION') {
                                errorService.addDpFieldError(DPError.createCustomDPError(`matter.mainClient.new.manage.contact.category.empty`, 'Category for' +
                                    ' <MAIN_CLIENT>' + ' ' + purchaser.matterParticipantPriority + ' is required.', '<MAIN_TOPIC>', 'ERROR', 0, `category_${contact.identifier}`, null, `categoryFieldIndex_${contact.identifier}`, null, null));
                            } else {
                                errorService.removeDpFieldError("matter.mainClient.new.manage.contact.category.empty", `category_${contact.identifier}`);
                            }
                            if (contact.contactCategory === 'OTHER_SPECIFY' && !contact.otherCategoryName) {
                                errorService.addDpFieldError(DPError.createCustomDPError(`matter.mainClient.new.manage.contact.specify.empty`, 'Specify' +
                                    ' Category for' +
                                    ' <MAIN_CLIENT>' + ' ' + purchaser.matterParticipantPriority + ' is required.', '<MAIN_TOPIC>', 'ERROR', 0, `specify_${contact.identifier}`, null, `specifyFieldIndex_${contact.identifier}`, null, null));
                            } else {
                                errorService.removeDpFieldError("matter.mainClient.new.manage.contact.specify.empty", `specify_${contact.identifier}`);
                            }
                    }
                    MatterErrorUtil.anyErrorsExistOnMainClientTitleDeedName(matter, purchaser, errorService);
                }

            });

            matter.otherSideClients.forEach((participant: MatterParticipant) => {
                // Validating Tenure
                if(matter.isMatterProvinceMBorSK && matter.otherPartiesCapacity == 'OTHER' && (!participant.purchaserShare || (participant.purchaserShare == '0'))) {
                    errorService.addDpSaveError(DPError.createCustomDPError(`matter.otherSideClients_tenureShareRequired`, 'A value must be entered in Tenure/Share field',
                                                                            '<TAB_C_TOPIC>', 'ERROR', 0, null, null, null, null, null));

                }
            });

            matter.offerors.forEach((participant: MatterParticipant , index) => {
                let offerorIndex = index +1;
                let signingOffiers = matter.matterParticipants.filter(item => item.parentParticipantId == participant.matterParticipantId && item.isSigningOfficer);
                if(signingOffiers) {
                    signingOffiers.forEach(( officer , index ) =>{
                        let officerIndex = index +1;
                        if(!officer.contact.lastName) {
                            errorService.addDpSaveError(DPError.createCustomDPError(`matter.offerorContact.signingOfficer_nameRequired`, 'Name for ' + officer.contact.signingOfficerTypeText +
                                officerIndex +' of Offeror'+ offerorIndex+' is required.',
                                '<MAIN_TOPIC>', 'ERROR',0, `name_${officer.contact.identifier}`));
                        }
                    })
                }
            });

            MatterErrorUtil.anyErrorsExistOnMainSideDeveloper(matter, errorService);

            MatterErrorUtil.checkParticipantDuplicateMailingAddress(matter, errorService);

            let vendorIndex = 0;
            matter.tabCClients.forEach((vendor: MatterParticipant) => {
                vendorIndex++;
                if (MatterErrorUtil.checkFamilyLawActsErrorCondition(vendor)) {
                    if (vendor.authorizedByCourt || vendor.interspousalAgreement) {
                        errorService.addDpSaveError(DPError.createDPError('matter.otherParty.interspousalAgreementSpouseNameRequired',
                            `interspousalAgreementSpouseName_${vendor.identifier}`, 'matterOtherPartyContainer', null, `clientFieldIndex_${vendor.identifier}`, null));
                    } else {

                        errorService.addDpSaveError(DPError.createDPError('matter.otherParty.consentingSpouseRequired' + matter.provinceCode,
                            `consentingSpouse_${vendor.identifier}`, 'matterOtherPartyContainer', null, `clientFieldIndex_${vendor.identifier}`, null));
                    }
                }

                if (matter.isMatterProvinceSK && vendor.registeredOnDate && vendor.registeredOnDate !== "//" && Utils.isNotValidDate(vendor.registeredOnDate)) {
                    errorService.addDpSaveError(DPError.createCustomDPError(`matter.otherParty_consentingSpouseDateRequired`,
                        "<OTHER_PARTY> " + vendorIndex + "> Registered on Date is invalid.",
                        '<TAB_C_TOPIC>', 'ERROR', 0, `registeredOn_${vendor.identifier}`, null, `clientFieldIndex_${vendor.identifier}`, null, null));
                }

                const contact: Contact = vendor.contact;
                if (contact) {
                    switch (contact.gender) {
                        case 'CORPORATION':
                        case 'OTHERENTITY':
                            if (!contact.organizationName) {
                                errorService.addDpSaveError(DPError.createCustomDPError(`matter.otherParty_organizationNameRequired`, 'Name for <OTHER_PARTY> ' + vendorIndex + ' is required.',
                                    '<TAB_C_TOPIC>', 'ERROR', 0, `name_${contact.identifier}`));
                            }
                            break;

                        case 'MALE':
                        case 'FEMALE':
                        case 'QUESTION':
                        case 'MALEPOA':
                        case 'FEMALEPOA':
                        case 'ESTATE':
                        default:
                            if (!contact.lastName) {
                                const errorDescription = `Surname for <OTHER_PARTY> ${vendorIndex} is required.`;
                                const topic = matter.isMatterProvinceBC ? 'Buyers & Lawyer/Notary' : '<TAB_C_TOPIC>';
                                const error = DPError.createCustomDPError('matter.otherParty_lastNameRequired', errorDescription, topic, 'ERROR', 0, `lastName_${contact.identifier}`);
                                errorService.addDpSaveError(error);
                            }
                            if (contact.contactCategory === 'QUESTION') {
                                errorService.addDpFieldError(DPError.createCustomDPError(`matter.otherParty.new.manage.contact.category.empty`, 'Category for' +
                                    ' <OTHER_PARTY>' + ' ' + vendorIndex + ' is required.', '<MAIN_TOPIC>', 'ERROR', 0, `category_${contact.identifier}`, null, `categoryFieldIndex_${contact.identifier}`, null, null));
                            } else {
                                errorService.removeDpFieldError("matter.otherParty.new.manage.contact.category.empty", `category_${contact.identifier}`);
                            }
                            if (contact.contactCategory === 'OTHER_SPECIFY' && !contact.otherCategoryName) {
                                errorService.addDpFieldError(DPError.createCustomDPError(`matter.otherParty.new.manage.contact.specify.empty`, 'Specify' +
                                    ' Category for' +
                                    ' <OTHER_PARTY>' + ' ' + vendorIndex + ' is required.', '<MAIN_TOPIC>', 'ERROR', 0, `specify_${contact.identifier}`, null, `specifyFieldIndex_${contact.identifier}`, null, null));
                            } else {
                                errorService.removeDpFieldError("matter.otherParty.new.manage.contact.specify.empty", `specify_${contact.identifier}`);
                            }
                    }
                }

            });

            if (matter.mortgagees) {
                matter.mortgagees.forEach(mp => {
                    if (!mp.contact.organizationName) {
                        errorService.addDpSaveError(DPError.createCustomDPError(`matter.mortgagee_organizationNameRequired` + mp.identifier, 'Name for Mortgagee is required.', 'Mortgage', 'ERROR'));
                    }
                    if(mp.contact.mortgageeLenderInstitutionLinkStatus == 'MULTIPLE_MATCHED' && mp.contact.isPrivateExcludeProxy) {
                        errorService.addDpSaveError(DPError.createDPError('contact.mortgagee.lenderLinkingNotCompleted'));
                    }
                    let mpMortgage = matter.getMortgageById(mp.mortgageId);
                    if (mpMortgage === undefined) {
                        mpMortgage = matter.getExistingMortgageById(mp.mortgageId);
                    }

                    let mpPrefix = matter.getMortgagePriorityById(mpMortgage);
                    let mpTabName = '';
                    if (mpMortgage && mpMortgage.isExistingMortgage()) {
                        mpTabName = ' Existing';
                    }
                    mpTabName = Utils.getOrdinal(mpPrefix) + mpTabName + ' Mortgage';

                    if (mpMortgage) {

                        if (!mpMortgage.isEmpMortgage() && !mp.contact.transitNo && mp.sourceContact && mp.sourceContact.isGlobal) {
                            errorService.addDpSaveError(DPError.createCustomDPError(`matter.mortgagee_transitNoRequired` + mp.identifier, 'Transit number is' +
                                ' required.', mpTabName, 'ERROR'));
                        } else if (!mpMortgage.isEmpMortgage() && mp.contact.transitNo && mp.contact.transitNo.match(/^0+$/) && mp.sourceContact && mp.sourceContact.isPrivateExcludeProxy) {
                            errorService.addDpSaveError(DPError.createCustomDPError(`matter.mortgagee_transitNoNotZero` + mp.identifier, 'Transit No. cannot be 00000', mpTabName, 'ERROR'));
                        }
                    } else {

                        if (!mp.contact.transitNo && mp.sourceContact && mp.sourceContact.isGlobal) {
                            errorService.addDpSaveError(DPError.createCustomDPError(`matter.mortgagee_transitNoRequired` + mp.identifier, 'Transit number is' +
                                ' required.', mpTabName, 'ERROR'));
                        } else if (mp.contact.transitNo && mp.contact.transitNo.match(/^0+$/) && mp.sourceContact && mp.sourceContact.isPrivateExcludeProxy) {
                            errorService.addDpSaveError(DPError.createCustomDPError(`matter.mortgagee_transitNoNotZero` + mp.identifier, 'Transit No. cannot be 00000', mpTabName, 'ERROR'));
                        }
                    }

                });
            }

            if (matter.privateLenders) {
                let privateLenders = matter.sortMortgagesMp(matter.privateLenders);
                let privateLenderIndex = 0;
                let oldMortgageId = null;
                privateLenders.forEach(mp => {
                    if (mp && mp.mortgageId == oldMortgageId) {
                        privateLenderIndex++;
                    } else {
                        privateLenderIndex = 1;
                        oldMortgageId = mp.mortgageId;
                    }
                    if (mp &&
                        ((mp.contact.gender != 'OTHERENTITY' && mp.contact.gender != 'CORPORATION' && !mp.contact.lastName)
                            || ((mp.contact.gender == 'OTHERENTITY' || mp.contact.gender == 'CORPORATION') && !mp.contact.organizationName))) {
                        let mortgageTypeStr = '';
                        let privateLenderTitle = 'Private Lender';
                        if (matter.isMortgage) {
                            mortgageTypeStr = ' New';
                        }
                        if (matter.isSale) {
                            mortgageTypeStr = ' VTB';
                            privateLenderTitle = 'Mortgagee';
                        }
                        let index = matter.mortgages.findIndex(item => item.id == mp.mortgageId);
                        if (index < 0) {
                            index = matter.existingMortgages.findIndex(item => item.id == mp.mortgageId);
                            mortgageTypeStr = ' Existing';
                            privateLenderTitle = 'Private Lender';
                        }

                        let mpMortgage = matter.getMortgageById(mp.mortgageId);
                        if (mpMortgage === undefined) {
                            mpMortgage = matter.getExistingMortgageById(mp.mortgageId);
                        }
                        let mpPrefix = matter.getMortgagePriorityById(mpMortgage);

                        errorService.addDpSaveError(DPError.createCustomDPError(`matter.mortgagee_lastNameRequired` + privateLenderIndex + '_' + mp.mortgageId,
                            'Name for ' + privateLenderTitle + ' ' + privateLenderIndex + ' is required.', Utils.getOrdinal(mpPrefix) + mortgageTypeStr + ' Mortgage', 'ERROR'));
                    }
                });
            }

            if (matter.condoCorporation) {
                // For the short term solution, remove the restriction regarding the province of the Condo corporation
                // matter.checkCondoCorporationProvince(errorService);

                if (matter.condoCorporation.contact.sourceContactId) {
                    if (!matter.condoCorporation.contact.organizationName) {
                        errorService.addDpSaveError(DPError.createDPError("matter.condoCorporation_organizationNameRequired"));
                    }

                    // Validating that if condoCorporation is self managed then management participant should be added
                    if (matter.isCondoCorporation || matter.isMatterProvinceBC) {
                        if (matter.condoCorporation.contact.isManagedByManagementCompany() && !matter.condoCorporation.contact.sourceParentOrganizationId) {
                            errorService.addDpFieldError(DPError.createDPError("condo_corp.management_company_required"));
                        } else {
                            errorService.removeDpFieldError("condo_corp.management_company_required");
                        }
                    } else {
                        errorService.removeDpSaveError("condo_corp.management_company_required");
                        errorService.removeDpFieldError("condo_corp.management_company_required");
                    }
                }
            }

            if (matter.managementCompany) {
                if (!matter.managementCompany.contact.organizationName) {
                    errorService.addDpSaveError(DPError.createCustomDPError(`matter.management_organizationNameRequired`, 'Name for Management Company is' +
                        ' required.', 'Condo Corporation', 'ERROR'));
                }
            }

            if (matter.realEstateAgent) {
                if (!matter.realEstateAgent.contact.lastOrBusinessName) {
                    if (matter.isPurchase) {
                        errorService.addDpSaveError(DPError.createCustomDPError(`matter.realEstateAgent.lastNameRequired`, 'Name for Real Estate Agent is' +
                            ' required.', 'Matter Opening', 'ERROR'));

                    } else {
                        errorService.addDpSaveError(DPError.createCustomDPError(`matter.listingAgent_lastNameRequired`, 'Name for Listing Agent is' +
                            ' required.', 'RE Broker / Commission', 'ERROR'));
                    }
                }
            }

            if (matter.realEstateBroker) {
                if (!matter.realEstateBroker.contact.organizationName) {
                    errorService.addDpSaveError(DPError.createCustomDPError(`matter.listingBroker_organizationNameRequired`, 'Name for Listing Broker is' +
                        ' required.', 'RE Broker / Commission', 'ERROR'));
                }
            }

            const vendorLawFirm: MatterParticipant = matter.otherPartyLawFirm;
            if (vendorLawFirm && vendorLawFirm.contact && !vendorLawFirm.contact.organizationName) {
                errorService.addDpSaveError(DPError.createDPError('matter.otherParty.contact.firmSolicitor.lawFirm.firmName.MISSING'));
            }

            const vendorSolicitor: MatterParticipant = matter.otherPartySolicitor;
            if (vendorSolicitor && vendorSolicitor.contact && !vendorSolicitor.contact.lastName) {
                errorService.addDpSaveError(DPError.createDPError('matter.otherParty.solicitorContact.surname'));
            }

            if (Array.isArray(matter.matterParticipants)) {
                matter.matterParticipants.forEach((mp: MatterParticipant) => {
                    if ((mp.matterParticipantRole === 'MORTGAGE_LEGAL_FIRM') && mp.contact && !mp.contact.organizationName) {
                        errorService.addDpSaveError(DPError.createDPError('mortgage.'
                            + (matter.mortgages.findIndex(item => item.id == mp.mortgageId) + 1)
                            + '.contact.firmSolicitor.lawFirm.firmName.MISSING'));
                    }
                    if ((mp.matterParticipantRole === 'MORTGAGE_SOLICITOR') && mp.contact && !mp.contact.lastName) {
                        errorService.addDpSaveError(DPError.createDPError('mortgage.'
                            + (matter.mortgages.findIndex(item => item.id == mp.mortgageId) + 1)
                            + '.solicitorContact.surname'));
                    }
                });
            }

            if (matter.matterPropertyWithCondo && matter.isTitleInsured() && matter.matterPropertyWithCondo.isTitleInsurancePropertyTypeMultiUnit()
                && !matter.matterPropertyWithCondo.titleInsuranceNumOfUnits) {
                if (matter.matterPropertyWithCondo.isPropertyTypeRoomingHouse()) {
                    errorService.addDpSaveError(DPError.createDPError('matter.propertyTeranet.noOfRooms'));
                } else {
                    errorService.addDpSaveError(DPError.createDPError('matter.propertyTeranet.noOfUnits'));
                }
            }

            if(matter.isWillMatter()) {
                MatterErrorUtil.validateCompletionDateForWIllMatter(matter, errorService);
                MatterErrorUtil.validateAssetsDebtsAndGifts(matter, errorService);
            }

            MatterErrorUtil.checkEmpErrors(matter, errorService, appConfig);

            MatterErrorUtil.checkMatterStatusError(matter, errorService);
            MatterErrorUtil.validateLincOnBlanketMortgageProperty(matter,errorService);

            return errorService.hasErrors();
        }
    }

    static validateCompletionDateForWIllMatter(matter, errorService): void {
        if(matter.matterCloseDate && matter.fileOpenDate && moment(matter.matterCloseDate).isBefore(moment(matter.fileOpenDate))) {
            errorService.addDpSaveError(DPError.createCustomDPError('matter.matterCloseDate', 'Completion Date cannot be before file opening date',
            'Matter Opening', 'ERROR'));
        }
    }

    static validateAssetsDebtsAndGifts(matter: Matter, errorService: ErrorService): void {
        let personalBelongingsErrorKey = 'matter.personalBelongings.distributePersonalBelongingsTo';

        let personalBelongings = matter.willMatter?.willAssetAndDebts?.find((asset) => 'PERSONAL_BELONGINGS' === asset.assetType);
        if (personalBelongings?.isDirty && (personalBelongings?.distributePersonalBelongingsTo === null || personalBelongings?.distributePersonalBelongingsTo === undefined)) {
            errorService.addDpSaveError(DPError.createCustomDPError(
                personalBelongingsErrorKey,
                'Field `Distribute personal belongings to` is required when personal belongings are edited',
                'Assets, Debts and Gifts',
                'ERROR'))
        }
    }

    static validateLincOnBlanketMortgageProperty(matter, errorService){
        if(matter.isMatterProvinceAB){
            this.checkDuplicationOfLINC(matter, errorService);
            errorService.removeDpfieldErrorByCoreErrorElementKey('unitNumberBM_');
            matter.matterProperties.filter(matterProperty => matterProperty.mortgageId !== undefined && matterProperty.mortgageId != null &&
                (!matterProperty.lincNumber || (matterProperty.lincNumber && matterProperty.lincNumber.trim() == '')))
                .forEach(matterProperty => {
                    let blanketMortgage = matter.mortgages.find(m => m.id == matterProperty.mortgageId);
                    let fieldId: string = `lincNumberBM_${matterProperty.identifier}`;
                    errorService.addDpSaveError(DPError.createCustomDPError(fieldId, `LINC Number is required.`,
                        (blanketMortgage ? Utils.getOrdinal(blanketMortgage.mortgagePriority):'')+' Mortgage', 'ERROR'));
                });
            matter.mortgages.forEach(mortgage => {
                if (mortgage.isBlanketMortgage) {
                    let blanketMortgageProperties = matter.getBlanketMortgageProperties(mortgage.id);
                    let matterProperties =  matter.matterProperties.filter(matterProperty => matterProperty.mortgageId == undefined || matterProperty.mortgageId == null );
                    let combinedProperties = [];
                    combinedProperties.push(...matterProperties, ...blanketMortgageProperties);
                    if(blanketMortgageProperties){
                        blanketMortgageProperties.forEach( prop =>{
                            if(prop.isTitleInsurancePropertyTypeMultiUnit() && !prop.titleInsuranceNumOfUnits){
                                let fieldId: string = `unitNumberBM_${prop.identifier}`;
                                let propIndex = combinedProperties.findIndex( cp => cp.identifier == prop.identifier);
                                errorService.addDpSaveError(DPError.createCustomDPError(fieldId,`In order to proceed, the No. of Units for LINC ${propIndex > -1 ? Number(propIndex+1) : ''}  must be selected`,
                                    Utils.getOrdinal(mortgage.mortgagePriority)+' Mortgage', 'ERROR'));
                            }
                        })
                    }

                }
            });
        }
    }

    static checkDuplicationOfLINC(matter: Matter, errorService: ErrorService) {
        matter.matterProperties.forEach( (matterProperty, index) =>{
            let findIndex  = matter.matterProperties.findIndex(mp => mp.lincNumber == matterProperty.lincNumber && matterProperty.identifier != mp.identifier)
            if(findIndex > -1 && index > findIndex){
                if(matterProperty.mortgageId) {
                    let fieldIdBM: string = `lincNumberBM_${matterProperty.identifier}`;
                    if(!errorService.isAnyDpFieldError(fieldIdBM)){
                        let mortgage = matter.mortgages.find( m =>m.id == matterProperty.mortgageId);
                        errorService.addDpFieldError(DPError.createCustomDPError(fieldIdBM, `The list of properties included on a blanket mortgage should be unique. LINC Number "${matterProperty.lincNumber}" is a duplicate.`,
                            (mortgage ? SharedUtils.getOrdinal(mortgage.mortgagePriority) : '') + ' Mortgage', 'ERROR'));
                    }
                }
                else{
                    let fieldId: string = `lincNumber_${matterProperty.identifier}`;
                    if(!errorService.isAnyDpFieldError(fieldId)){
                        errorService.addDpFieldError(DPError.createCustomDPError(fieldId, `LINC Number "${matterProperty.lincNumber}" already added.`, 'Property', 'ERROR'));
                    }
                }
            }
            else {
                if(matterProperty.mortgageId) {
                    let fieldIdBM: string = `lincNumberBM_${matterProperty.identifier}`;
                    if(errorService.isAnyDpFieldError(fieldIdBM)){
                        errorService.removeDpFieldError(fieldIdBM);
                    }
                }
                else{
                    let fieldId: string = `lincNumber_${matterProperty.identifier}`;
                    if(errorService.isAnyDpFieldError(fieldId)){
                        errorService.removeDpFieldError(fieldId);
                    }
                }
            }
        })
    }




    static anyErrorsExistOnMainSideDeveloper(matter: Matter, errorService: ErrorService) {
        if (matter && errorService) {
            if (matter.isProjectSale && matter.developers.length > 0) {
                matter.developers.forEach((participant: MatterParticipant) => {
                    // Validating FLA data only if it applicable
                    if (PurchaserFamilyLawAct.isFamilyLawActVisibleForParticipant(participant, false, matter.provinceCode, matter.developers.length, matter.matterType)) {
                        if (MatterErrorUtil.checkFamilyLawActsErrorCondition(participant)) {
                            errorService.addDpSaveError(DPError.createDPError('matter.mainDeveloper.consentingSpouseRequired' + matter.provinceCode,
                                `consentingSpouse_${participant.matterParticipantId}`, 'matterPurchaserContainer', null, `clientFieldIndex_${participant.matterParticipantId}`, null));
                        }
                    }

                    const contact: Contact = participant.contact;
                    const mainTopic = 'Developers';
                    const mainClient = 'Developer';
                    if (contact) {
                        switch (contact.gender) {
                            case 'CORPORATION':
                            case 'OTHERENTITY':
                                if (!contact.organizationName) {
                                    errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainDeveloper.organizationNameRequired`, 'Name for ' +
                                        mainTopic + ' ' + participant.matterParticipantPriority + ' is' +
                                        ' required.',
                                        mainTopic, 'ERROR', 0, `name_${participant.matterParticipantId}`,
                                        null, `clientFieldIndex_${participant.matterParticipantId}`, null, null));
                                }
                                break;

                            case 'MALE':
                            case 'FEMALE':
                            case 'QUESTION':
                            case 'MALEPOA':
                            case 'FEMALEPOA':
                            case 'ESTATE':
                            default:
                                if (!contact.lastName) {
                                    errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient_lastNameRequired`,
                                        'Surname for ' + mainClient + ' ' + participant.matterParticipantPriority + ' is required.',
                                        mainTopic, 'ERROR', 0, `lastName_${participant.matterParticipantId}`,
                                        null, `clientFieldIndex_${participant.matterParticipantId}`, null, null));
                                }
                                if (contact.contactCategory === 'QUESTION') {
                                    errorService.addDpFieldError(DPError.createCustomDPError(`matter.mainClient.new.manage.contact.category.empty`,
                                        'Category for ' + mainClient + ' ' + participant.matterParticipantPriority + ' is required.',
                                        mainTopic, 'ERROR', 0, `category_${participant.matterParticipantId}`,
                                        null, `categoryFieldIndex_${participant.matterParticipantId}`, null, null));
                                } else {
                                    errorService.removeDpFieldError("matter.mainClient.new.manage.contact.category.empty", `category_${participant.matterParticipantId}`);
                                }
                                if (contact.contactCategory === 'OTHER_SPECIFY' && !contact.otherCategoryName) {
                                    errorService.addDpFieldError(DPError.createCustomDPError(`matter.mainClient.new.manage.contact.specify.empty`, 'Specify' +
                                        ' Category for ' + mainClient + ' ' + participant.matterParticipantPriority + ' is required.',
                                        mainTopic, 'ERROR', 0, `specify_${participant.matterParticipantId}`,
                                        null, `specifyFieldIndex_${participant.matterParticipantId}`, null, null));
                                } else {
                                    errorService.removeDpFieldError("matter.mainClient.new.manage.contact.specify.empty", `specify_${participant.matterParticipantId}`);
                                }
                        }
                    }

                });

            }
        }
    }

    static anyErrorsExistOnMainClientTitleDeedName(matter: Matter, mainClientParticipant: MatterParticipant, errorService: ErrorService) {
        if (matter && errorService) {
            if (!mainClientParticipant || !mainClientParticipant.contact || !mainClientParticipant.titleDeed) {
                return;
            }
            switch (mainClientParticipant.contact.gender) {
                case 'MALE':
                case 'FEMALE':
                case 'QUESTION':
                case 'MALEPOA':
                case 'FEMALEPOA':
                    if (mainClientParticipant.titleDeed.isManuallyEnter() && !mainClientParticipant.titleDeed.surName) {
                        errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient.TitleDeedName.lastNameRequired`,
                            'Surname of title deed name for' + ' <MAIN_CLIENT> ' + mainClientParticipant.matterParticipantPriority + ' is required.',
                            '<MAIN_TOPIC>', 'ERROR', 0, `name_${mainClientParticipant.matterParticipantId}`, null, `clientFieldIndex_${mainClientParticipant.matterParticipantId}`, null, null));
                    }
                    break;

                default:
                    break;
            }
        }
    }

    static createNsFlaError(matter: Matter, participant: MatterParticipant, errorService: ErrorService) {
        if (matter && errorService) {
            if (matter.isMatterProvinceNS) {
                if (MatterErrorUtil.checkFamilyLawActsErrorCondition(participant)) {
                    if (participant.consentedSpouse) {
                        errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient.consentingSpouseLastNameRequired`,
                            "<MAIN_CLIENT> " + participant.matterParticipantPriority + "> Consenting Spouse's surname in Marital Status section is required.",
                            '<MAIN_TOPIC>', 'ERROR', 0, `registeredOn_${participant.identifier}`, null,
                            `clientFieldIndex_${participant.matterParticipantId}`, null, null));

                    } else if (participant.notMatrimonialHome || participant.spouseBySeparationAgreement || participant.spouseByVirtueOfOrder || participant.designatedAnotherPropertyAsMatrimonialHome) {
                        errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient.nameOfSpouseRequired`,
                            "<MAIN_CLIENT> " + participant.matterParticipantPriority + "> Name of Spouse's" +
                            " surname in Marital Status section is required.",
                            '<MAIN_TOPIC>', 'ERROR', 0, `registeredOn_${participant.identifier}`, null,
                            `clientFieldIndex_${participant.matterParticipantId}`, null, null));
                    }
                }

                if (matter.isMatterProvinceNS && participant.designationDate && participant.designationDate !== "//" && Utils.isNotValidDate(participant.designationDate)) {
                    errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient.consentingSpouseDesignationDateRequired`,
                        "<MAIN_CLIENT> " + participant.matterParticipantPriority + "> Date of Designation" +
                        " in Marital Status section is invalid.",
                        '<MAIN_TOPIC>', 'ERROR', 0, `registeredOn_${participant.identifier}`, null,
                        `clientFieldIndex_${participant.matterParticipantId}`, null, null));
                }
            }
        }
    }

    static createNbFlaError(matter: Matter, participant: MatterParticipant, errorService: ErrorService) {
        // In NB, only when maritalStatus is MARRIED or (propertyOccupiedAsMaritalHome is yes and applicableProvisionis is SPOUSE_IS_CONSENTING),
        // it has consenting Spouse field.
        if (matter && errorService) {
            if (matter.isMatterProvinceNB) {
                if (matter.isConsentedSpouseSelectedForNB(participant)) {
                    if (MatterErrorUtil.checkFamilyLawActsErrorCondition(participant)) {
                        errorService.addDpSaveError(DPError.createCustomDPError(`matter.mainClient.consentingSpouseLastNameRequired`,
                            "<MAIN_CLIENT> " + participant.matterParticipantPriority
                            + "> " + participant.familyLawActs[0].getConsentingSpouseLabel(true) + "'s surname" +
                            " in Marital Status section is required.",
                            '<MAIN_TOPIC>', 'ERROR', 0, `registeredOn_${participant.identifier}`, null,
                            `clientFieldIndex_${participant.matterParticipantId}`, null, null));

                    }
                }

            }
        }
    }

    // if (matter && errorService) {
    // matter: Matter
    static checkMortgagePriorityForMortgageMatter(matter: Matter, errorService: ErrorService): void {
        if (matter && errorService) {
            matter.missingMortgagePriority.forEach(missingPriorityNo => {
                errorService.addDpSaveError(DPError.createCustomDPError("matter.newMortgage.mortgage.priority." + missingPriorityNo, 'A mortgage with priority ' + missingPriorityNo + ' is' +
                    ' missing', 'New Mortgage', 'WARNING'));
            });
        }
    }

    static checkMatterStatusError(matter: Matter, errorService: ErrorService) {
        if (matter && errorService) {
            if (matter.matterOverviewStatus == MatterOverviewStatusTypesValue.FLAGGED && matter.matterStatus == 'INACTIVE') {
                errorService.addDpSaveError(DPError.createDPError('matter.matterOpening.inactiveErrorForMatterOverviewStatusIsFlagged'));
            }
        }
    }

    static checkEmpErrors(matter: Matter, errorService: ErrorService, appConfig: AppConfig) {
        if (matter && errorService) {
            if (!matter.isSale) {
                errorService.removeDpThirdPartyNotificationByPartialKey('notification.stewartAssyst.closingDateNotMatching*');
                errorService.removeDpThirdPartyNotificationByPartialKey('notification.stewartAssyst.purchasePriceNotMatching*');
                errorService.removeDpThirdPartyNotificationByPartialKey('notification.fct.standardChargeTermNo.*');
                errorService.removeDpThirdPartyNotificationByPartialKey('notification.stewartAssyst.standardChargeTermNo.*');
                if (matter.mortgages && matter.mortgages.length > 0) {
                    matter.mortgages.forEach((empMortgage) => {
                        if (empMortgage.isEmpMortgage()) {
                            matter.isEMPMortgagePriceClosingDateMatches(empMortgage, errorService);
                            matter.isStandardChargeTermNoProvided(empMortgage, errorService, appConfig);
                        }
                    });
                }
            }
        }
    }

    static checkFamilyLawActsErrorCondition(matterParticipant: MatterParticipant): boolean {
        let consentedSpouseContact: Contact = matterParticipant && matterParticipant.getConsentedSpouseFamilyLawAct() && matterParticipant.getConsentedSpouseFamilyLawAct().consentedSpouseParticipant && matterParticipant.getConsentedSpouseFamilyLawAct().consentedSpouseParticipant.contact;
        if (consentedSpouseContact
            && (!consentedSpouseContact.lastName
                || consentedSpouseContact.lastName == ""
                || (consentedSpouseContact.lastName
                    && consentedSpouseContact.lastName.toString().trim() == ""))) {
            return true;
        }
        return false;
    }

    static checkCondoCorporationProvince(matter: Matter, errorService: ErrorService) {
        if (matter && errorService) {
            const condoCorporation: MatterParticipant = matter.condoCorporation;
            if (matter.condoCorporation && matter.condoCorporation.contact && Array.isArray(matter.condoCorporation.contact.address)) {
                let mailingAddress: Address = _.find(matter.condoCorporation.contact.address, (address: Address) => {
                    return address.addressTypeCode === AddressTypes.mailing;
                });
                if (mailingAddress && mailingAddress.provinceCode != matter.provinceCode) {
                    errorService.addDpSaveError(DPError.createDPError("contact.provinceCode"));
                }
            }
        }
    }

    static checkHSTRebateToDisplayWarningMessage(matter: Matter, errorService: ErrorService, showBubbleNotification) {
        if (matter && errorService) {
            const existingErrors: DPError[] = errorService.copyOfExistingErrors;

            errorService.removeDpFieldError('statementofadjustments.hstSalePrice.hstTypeSale.creditVendor');
            errorService.removeDpFieldError('statementofadjustments.hstSalePrice.hstTypeRebate.creditPurchaser');
            errorService.removeDpFieldError('statementofadjustments.hstSalePrice.hstTypeRebate.creditVendor');
            // PST SK
            errorService.removeDpFieldError('statementofadjustments.pstSkSalePrice.hstTypeSale.creditVendor');
            errorService.removeDpFieldError('statementofadjustments.pstSkSalePrice.hstTypeRebate.creditPurchaser');
            errorService.removeDpFieldError('statementofadjustments.pstSkSalePrice.hstTypeRebate.creditVendor');

            if (matter.statementOfAdjustments && matter.considerationLtt && matter.considerationLtt.salePriceAdjustment &&
                (matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'YES_FACTOR_IN_HST_REBATE' || matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'YES_DIVIDE_SALE_PRICE' ||
                    (matter.isMatterProvinceSK && matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'NO'))) {

                let msg: string = '';
                let hasHstSalePriceCreditVendor: boolean = false;
                let hasHstSalePriceCreditPurchaser: boolean = false;
                let hasHstRebateCreditPurchaser: boolean = false;
                let hasHstRebateCreditVendor: boolean = false;
                // PST SK
                let hasPstSkSalePriceCreditVendor: boolean = false;
                let hasPstSkSalePriceCreditPurchaser: boolean = false;
                let hasPstSkRebateCreditPurchaser: boolean = false;
                let hasPstSkRebateCreditVendor: boolean = false;

                matter.allStatementAdjustments.forEach(statementOfAdjustment => {
                    if (!matter.isMatterProvinceSK) {
                        if (statementOfAdjustment && statementOfAdjustment.hstSalePrice) {
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_SALE_PRICE' && statementOfAdjustment.hstSalePrice.isVendorSelected) {
                                hasHstSalePriceCreditVendor = true;
                            }
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_SALE_PRICE' && !statementOfAdjustment.hstSalePrice.isVendorSelected) {
                                hasHstSalePriceCreditPurchaser = true;
                            }
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' && statementOfAdjustment.hstSalePrice.isVendorSelected) {
                                hasHstRebateCreditVendor = true;
                            }
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' && !statementOfAdjustment.hstSalePrice.isVendorSelected) {
                                hasHstRebateCreditPurchaser = true;
                            }
                        }
                    } else { // GST & PST SK
                        if (statementOfAdjustment && statementOfAdjustment.hstSalePrice) {
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_SALE_PRICE' && statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'GST') {
                                hasHstSalePriceCreditVendor = true;
                            }
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_SALE_PRICE' && !statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'GST') {
                                hasHstSalePriceCreditPurchaser = true;
                            }
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' && statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'GST') {
                                hasHstRebateCreditVendor = true;
                            }
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' && !statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'GST') {
                                hasHstRebateCreditPurchaser = true;
                            }

                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_SALE_PRICE' && statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'PST') {
                                hasPstSkSalePriceCreditVendor = true;
                            }
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_SALE_PRICE' && !statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'PST') {
                                hasPstSkSalePriceCreditPurchaser = true;
                            }
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' && !statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'PST') {
                                hasPstSkRebateCreditPurchaser = true;
                            }
                            if (statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' && statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'PST') {
                                hasPstSkRebateCreditVendor = true;
                            }
                        }
                    }
                })
                // PST SK Messages
                if (matter.isSkReminderVisible()) {
                    if (!hasPstSkSalePriceCreditVendor && !matter.suppressSkPstSalePriceCreditVendorWarning && (matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentFormat === 'CREDIT_VENDOR_NET_SALE_PRICE' || matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings.length == 0)) {
                        errorService.addDpFieldErrorWithNoDupBubble(DPError.createDPError("statementofadjustments.pstSkSalePrice.hstTypeSale.creditVendor", '', '', 'M50004', undefined, undefined, undefined, showBubbleNotification), existingErrors);
                    }
                    if (!hasPstSkRebateCreditPurchaser && !matter.suppressSkPstRebateCreditPurchaserWarning) {
                        errorService.addDpFieldErrorWithNoDupBubble(DPError.createDPError("statementofadjustments.pstSkSalePrice.hstTypeRebate.creditPurchaser", '', '', 'M50005', undefined, undefined, undefined, showBubbleNotification), existingErrors);
                    }
                    if (!hasPstSkRebateCreditVendor && matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentFormat === 'CREDIT_VENDOR_INCLUSIVE' && !matter.suppressSkPstRebateCreditVendorWarning) {
                        errorService.addDpFieldErrorWithNoDupBubble(DPError.createDPError("statementofadjustments.pstSkSalePrice.hstTypeRebate.creditVendor", '', '', 'M50006', undefined, undefined, undefined, showBubbleNotification), existingErrors);
                    }
                }
                if (matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'YES_FACTOR_IN_HST_REBATE' || matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'YES_DIVIDE_SALE_PRICE') {
                    if (!hasHstSalePriceCreditVendor && matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentFormat === 'CREDIT_VENDOR_NET_SALE_PRICE') {
                        errorService.addDpFieldErrorWithNoDupBubble(DPError.createDPError("statementofadjustments.hstSalePrice.hstTypeSale.creditVendor", '', '', 'M50001', undefined, undefined, undefined, showBubbleNotification), existingErrors);
                    }

                    if (!hasHstRebateCreditPurchaser && matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentFormat === 'CREDIT_VENDOR_NET_SALE_PRICE' && matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'YES_FACTOR_IN_HST_REBATE' && matter.isRebateReminderVisible()) {
                        errorService.addDpFieldErrorWithNoDupBubble(DPError.createDPError("statementofadjustments.hstSalePrice.hstTypeRebate.creditPurchaser", '', '', 'M50002', undefined, undefined, undefined, showBubbleNotification), existingErrors);
                    }

                    if (!hasHstRebateCreditPurchaser && matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentFormat === 'CREDIT_VENDOR_INCLUSIVE' && matter.isRebateReminderVisible()) {
                        errorService.addDpFieldErrorWithNoDupBubble(DPError.createDPError("statementofadjustments.hstSalePrice.hstTypeRebate.creditPurchaser", '', '', 'M50002', undefined, undefined, undefined, showBubbleNotification), existingErrors);
                    }

                    if (!hasHstRebateCreditVendor && matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentFormat === 'CREDIT_VENDOR_INCLUSIVE' && matter.isRebateReminderVisible()) {
                        errorService.addDpFieldErrorWithNoDupBubble(DPError.createDPError("statementofadjustments.hstSalePrice.hstTypeRebate.creditVendor", '', '', 'M50003', undefined, undefined, undefined, showBubbleNotification), existingErrors);
                    }
                }
            }
        }
    }

    static hasApplicableSelPriceAdjustment(matter : Matter): boolean {
        return matter.isMatterProvinceSK && matter.statementOfAdjustments && matter.considerationLtt && matter.considerationLtt.salePriceAdjustment &&
            (matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'YES_FACTOR_IN_HST_REBATE' || matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'YES_DIVIDE_SALE_PRICE' ||
                matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'NO');
    }

    static hasSkPstSalePriceCreditVendorWarning(matter : Matter): boolean{
        if (this.hasApplicableSelPriceAdjustment(matter)){
            let hasPstSkSalePriceCreditVendor: boolean = false;
            matter.allStatementAdjustments.forEach(statementOfAdjustment => {
                if (statementOfAdjustment.hstSalePrice && statementOfAdjustment.hstSalePrice.hstType === 'HST_SALE_PRICE' && statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'PST') {
                    hasPstSkSalePriceCreditVendor = true;
                }
            });
            return !hasPstSkSalePriceCreditVendor && (matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentFormat === 'CREDIT_VENDOR_NET_SALE_PRICE' || matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings.length == 0)
        }
        return false;
    }

    static hasSkPstRebateCreditVendorWarning(matter : Matter): boolean{
        if (this.hasApplicableSelPriceAdjustment(matter)){
            let hasPstSkRebateCreditVendor: boolean = false;
            matter.allStatementAdjustments.forEach(statementOfAdjustment => {
                if (statementOfAdjustment.hstSalePrice && statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' && statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'PST') {
                    hasPstSkRebateCreditVendor = true;
                }
            });
            return !hasPstSkRebateCreditVendor && matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentFormat === 'CREDIT_VENDOR_INCLUSIVE'
        }
        return false;
    }

    static hasSkPstRebateCreditPurchaserWarning(matter : Matter): boolean{
        if (this.hasApplicableSelPriceAdjustment(matter)){
            let hasPstSkRebateCreditPurchaser: boolean = false;
            matter.allStatementAdjustments.forEach(statementOfAdjustment => {
                if (statementOfAdjustment.hstSalePrice && statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' && !statementOfAdjustment.hstSalePrice.isVendorSelected && statementOfAdjustment.hstSalePrice.rateType === 'PST') {
                    hasPstSkRebateCreditPurchaser = true;
                }
            });
            return !hasPstSkRebateCreditPurchaser;
        }
        return false;
    }
}
