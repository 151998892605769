import {DdlTypeForLoan} from '../matters/shared/mortgage';
import {dropDowns} from '../matters/shared/matter-drop-downs';
import {ActingForValues, dischargeRegisteredByType} from "./constants";
import {constValues} from "../matters/shared/const-values";

export const businessRoleOptions = {
    "BusinessRoleOptions_ON": {
        "SOLICITOR" : "Lawyer",
        "LAWCLERK"  : "Law Clerk",
        "OTHER"     : "Other",
    },
    "BusinessRoleOptions_BC": {
        "SOLICITOR" : "Lawyer/Notary",
        "LAWCLERK"  : "Assistant",
        "OTHER"     : "Other",
    },
    "BusinessRoleOptions_OTHER": {
        "SOLICITOR" : "Lawyer",
        "LAWCLERK"  : "Legal Assistant",
        "OTHER"     : "Other",
    },
    "BusinessRoleOptions_NS"        :{
        "SOLICITOR" : "Lawyer",
        "LAWCLERK"  : "Paralegal",
        "OTHER"     : "Other",
    }
};

export const provinceBasedBusinessRoleOptions = {
    'ON' : businessRoleOptions.BusinessRoleOptions_ON,
    'NB' : businessRoleOptions.BusinessRoleOptions_ON,
    'NS' : businessRoleOptions.BusinessRoleOptions_NS,
    'AB' : businessRoleOptions.BusinessRoleOptions_OTHER,
    'MB' : businessRoleOptions.BusinessRoleOptions_OTHER,
    'SK' : businessRoleOptions.BusinessRoleOptions_OTHER,
    'BC' : businessRoleOptions.BusinessRoleOptions_BC,
};

export const businessRoleDropDowns = {
    BusinessRoleOptions_ON         :[
        {value : 'LAWCLERK' , label : 'Law Clerk' },
        {value : 'SOLICITOR' , label : 'Lawyer' },
        {value : 'OTHER' , label : 'Other' }
    ],

    BusinessRoleOptions_OTHER         :[
        {value : 'LAWCLERK' , label : 'Legal Assistant' },
        {value : 'SOLICITOR' , label : 'Lawyer' },
        {value : 'OTHER' , label : 'Other' }
    ],

    BusinessRoleOptions_BC         :[
        {value : 'LAWCLERK' , label : 'Assistant' },
        {value : 'SOLICITOR' , label : 'Lawyer/Notary' },
        {value : 'OTHER' , label : 'Other' }
    ],
    BusinessRoleOptions_NS        :[
        {value : 'LAWCLERK' , label : 'Paralegal' },
        {value : 'SOLICITOR' , label : 'Lawyer' },
        {value : 'OTHER' , label : 'Other' }
    ]
};

export const cashOnClosingDateDropDowns = {
    CashOnClosingDateOptions_AB: [
        {value : "NIL", label : "Nil"},
        {value : "CASH_TO_CLOSE", label : "Cash to Close"},
        {value : "CASH_DIFFERENCE", label : "Cash Difference"},
    ],
    CashOnClosingDateOptions_SK: [
        {value : "NIL", label: "Nil"},
        {value : "BALANCE_TO_CLOSE", label : "Balance to Close"},
        {value : "BALANCE_OF_DOWN_PAYMENT", label : "Balance of Down Payment"},
    ],
    CashOnClosingDateOptions_MB: [
        {value : "NIL", label: "Nil"},
        {value : "BALANCE_TO_CLOSE", label : "Balance to Close"},
        {value : "BALANCE_OF_DOWN_PAYMENT", label : "Balance of Down Payment"},
    ],
};

export const provinceBasedCashOnClosingDropDown = {
    'AB' : cashOnClosingDateDropDowns.CashOnClosingDateOptions_AB,
    'MB' : cashOnClosingDateDropDowns.CashOnClosingDateOptions_MB,
    'SK' : cashOnClosingDateDropDowns.CashOnClosingDateOptions_SK
};


export const provinceBasedBusinessRoleDropDowns = {
    'ON' : businessRoleDropDowns.BusinessRoleOptions_ON,
    'NB' : businessRoleDropDowns.BusinessRoleOptions_ON,
    'NS' : businessRoleDropDowns.BusinessRoleOptions_NS,
    'AB' : businessRoleDropDowns.BusinessRoleOptions_OTHER,
    'MB' : businessRoleDropDowns.BusinessRoleOptions_OTHER,
    'SK' : businessRoleDropDowns.BusinessRoleOptions_OTHER,
    'BC' : businessRoleDropDowns.BusinessRoleOptions_BC,
};

export const provinceBasedTaxTypes = {
    'ON' : dropDowns.taxTypes,
    'AB' : dropDowns.taxTypesAB,
    'NS' : dropDowns.taxTypes,
    'NB' : dropDowns.taxTypes,
    'MB' : dropDowns.taxTypes,
    'SK' : dropDowns.taxTypes,
    'BC' : dropDowns.taxTypes,
};



export const financingTypeDropDowns = {
        financingTypeOptions_DEFAULT : [
            {label : 'Mortgage', value : 'MORTGAGE'},
            {label : 'Caveat', value : 'CAVEAT'},
            {label : 'Assignment', value : 'ASSIGNMENT'},
            {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
            {label : "Builders' Lien", value : 'BUILDER_LIEN'},
            {label : "Builders' Lien Caveat", value : 'BUILDER_LIEN_CAVEAT'},
            {label : 'Certificates Of Lis Pendens', value : 'CERTIFICATES_OF_LIS_PENDENS'},
            {label : 'Encroachment Agreement', value : 'CERTIFICATES_OF_LIS_PENDENS'},
            {label : 'Encumbrance', value : 'ENCUMBRANCE'},
            {label : 'Home Owners Association', value : 'HOME_OWNERS_ASSOCIATION'},
            {label : 'Judgement', value : 'JUDGEMENT'},
            {label : 'Lien', value : 'LIEN'},
            {label : 'Mobile Home', value : 'MOBILE_HOME'},
            {label : 'Release of Dower', value : 'RELEASE_OF_DOWER'},
            {label : 'Restrictive Covenant', value : 'RESTICTIVE_COVENANT'},
            {label : 'Surface Lease', value : 'SURFACE_LEASE'},
            {label : 'Tax Notification', value : 'TAX_NOTIFICATION'},
            {label : 'Utility Right of Way', value : 'UTILITY_RIGHT_OF_WAY'},
            {label : 'Vendor\'s Lien Caveat', value : 'VENDORS_LIEN_CAVEAT'},
            {label : 'Writ', value : 'WRIT'},
            {label : 'Zoning Regulations', value : 'ZONING_REGULATIONS'}

        ],
    financingTypeOptions_NS : [
        {label : 'Mortgage', value : 'MORTGAGE'},
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment', value : 'ASSIGNMENT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : "Builders' Lien", value : 'BUILDER_LIEN'},
        {label : 'Certificates Of Lis Pendens', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Encroachment Agreement', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Encumbrance', value : 'ENCUMBRANCE'},
        {label : 'Judgement', value : 'JUDGEMENT'},
        {label : 'Lien', value : 'LIEN'},
        {label : 'Mobile Home', value : 'MOBILE_HOME'},
        {label : 'Restrictive Covenant', value : 'RESTICTIVE_COVENANT'},
        {label : 'Surface Lease', value : 'SURFACE_LEASE'},
        {label : 'Tax Notification', value : 'TAX_NOTIFICATION'},
        {label : 'Utility Right of Way', value : 'UTILITY_RIGHT_OF_WAY'},
        {label : 'Writ', value : 'WRIT'},
        {label : 'Zoning Regulations', value : 'ZONING_REGULATIONS'}

    ],
    financingTypeOptions_NB : [
        {label : 'Mortgage', value : 'MORTGAGE'},
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment', value : 'ASSIGNMENT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : 'Certificates Of Lis Pendens', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Encroachment Agreement', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Encumbrance', value : 'ENCUMBRANCE'},
        {label : 'Judgement', value : 'JUDGEMENT'},
        {label : 'Lien', value : 'LIEN'},
        {label : 'Mechanics\' Lien', value : 'BUILDER_LIEN'},
        {label : 'Mobile Home', value : 'MOBILE_HOME'},
        {label : 'Restrictive Covenant', value : 'RESTICTIVE_COVENANT'},
        {label : 'Surface Lease', value : 'SURFACE_LEASE'},
        {label : 'Tax Notification', value : 'TAX_NOTIFICATION'},
        {label : 'Utility Right of Way', value : 'UTILITY_RIGHT_OF_WAY'},
        {label : 'Writ', value : 'WRIT'},
        {label : 'Zoning Regulations', value : 'ZONING_REGULATIONS'}

    ],
    //Values are from Teranet-Connect Imported Instruments
    financingTypeOptions_ON : [
        {label : 'Mortgage', value : 'MORTGAGE'},
        {label : 'Application for Inhibiting Order', value : 'APL INH ORDER-LAND'},
        {label : 'Application to Annex Restrictive Cov s.118', value : 'APL ANNEX REST COV'},
        {label : 'Caution of Agreement of Purchase and Sale', value : 'CAU AGR PUR &amp; SALE'},
        {label : 'Caution - Charge', value : 'CAUTION-CHARGE'},
        {label : 'Caution - Charge (Bankruptcy and Insolvency Act)', value : 'CAUTION BKRUPT-CH'},
        {label : 'Caution-Land', value : 'CAUTION-LAND'},
        {label : 'Caution - Land (Estates Administration Act)', value : 'CAU L EST ADM ACT)'},
        {label : 'Caution of Agreement of Purchase and Sale', value : 'CAU AGR PUR &amp; SALE)'},
        {label : 'Certificate of Lien - Housing Development Act', value : 'CERT LIEN HD ACT'},
        {label : 'Charge', value : 'CHARGE'},
        {label : 'Condominium Lien', value : 'CONDOMINIUM LIEN'},
        {label : 'Construction Lien', value : 'CONSTRUCTION LIEN'},
        {label : 'Land Registrar\'s Caution', value : 'LR\'S CAUTION'},
        {label : 'Lease', value : 'LEASE'},
        {label : 'Mobile Home', value : 'MOBILE_HOME'},
        {label : 'Notice of Agreement of Purchase and Sale', value : 'NO AGR PUR &amp; SALE'},
        {label : 'Notice of Assignment of Rent General', value : 'NO ASSGN RENT GEN'},
        {label : 'Notice of Assignment of Rents', value : 'NO ASSGN RENT SPEC'},
        {label : 'Notice of Lease', value : 'NOTICE OF LEASE'},
        {label : 'Notice of Oil & Gas Lease', value : 'NO OIL &amp; GAS LEASE'},
        {label : 'Notice of Option to Lease', value : 'NO OPTION LEASE'},
        {label : 'Notice of Option to Purchase', value : 'NO OPTION PURCHASE'},
        {label : 'Notice of Security Interest', value : 'NO SEC INTEREST'},
        {label : 'Notice of Subdivision Agreement', value : 'NO SUB AGREEMENT'},
        {label : 'Notice of Vendor\'s Lien', value : 'NO VENDORS LIEN'},
        {label : 'Restrictive Covenants Application to Annex', value : 'REST COV APL ANNEX'},
    ],

    financingTypeOptions_BC : [
        {label : 'Mortgage', value : 'MORTGAGE'},
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : 'Modification', value : 'MODIFICATION'},
        {label : 'Extension of Mortgage', value : 'EXTENSION_OF_MORTGAGE'},
        {label : 'Priority Agreement', value : 'PRIORITY_AGREEMENT'},
        {label : 'Transfer of Charge', value : 'TRANSFER_OF_CHARGE'},
        {label : 'Claim of Builders Lien', value : 'CLAIM_OF_BUILDERS_LIEN'},
        {label : 'Bank Act Charge', value : 'BANK_ACT_CHARGE'},
        {label : 'Builders Lien', value : 'BUILDERS_LIEN'},
        {label : 'Certificate of Levy', value : 'CERTIFICATE_OF_LEVY'},
        {label : 'Corporation Capital Tax Act Lien', value : 'CORPORATION_CAPITAL_TAX_ACT_LIEN'},
        {label : 'Court Order', value : 'COURT_ORDER'},
        {label : 'Covenant', value : 'COVENANT'},
        {label : 'Crown Debt', value : 'CROWN_DEBT'},
        {label : 'Crown Lien', value : 'CROWN_LIEN'},
        {label : 'Crystallized Floating Charge', value : 'CRYSTALLIZED_FLOATING_CHARGE'},
        {label : 'Easement', value : 'EASEMENT'},
        {label : 'Easement and Indemnity Agreementa', value : 'EASEMENT_AND_INDEMNITY_AGREEMENTA'},
        {label : 'Equitable Charge', value : 'EQUITABLE_CHARGE'},
        {label : 'Equitable Interest', value : 'EQUITABLE_INTEREST'},
        {label : 'Grant Of Timber', value : 'GRANT_OF_TIMBER'},
        {label : 'Insurance Premium Tax Act Lien', value : 'INSURANCE_PREMIUM_TAX_ACT_LIEN'},
        {label : 'Judgment', value : 'JUDGMENT'},
        {label : 'Land Tax Deferment Act Agreement', value : 'LAND_TAX_DEFERMENT_ACT_AGREEMENT'},
        {label : 'Lease', value : 'LEASE'},
        {label : 'Legal Profession Act Charge', value : 'LEGAL_PROFESSION_ACT_CHARGE'},
        {label : 'Life Estate', value : 'LIFE_ESTATE'},
        {label : 'Logging Tax Act Lien', value : 'LOGGING_TAX_ACT_LIEN'},
        {label : 'Mines Act Charge', value : 'MINES_ACT_CHARGE'},
        {label : 'Mining Agreement', value : 'MINING_AGREEMENT'},
        {label : 'Mineral Tax Act Lien', value : 'MINERAL_TAX_ACT_LIEN'},
        {label : 'Motor Fuel Tax Act', value : 'MOTOR_FUEL_TAX_ACT'},
        {label : 'Notice Of Interest Builders Lien Act', value : 'NOTICE_OF_INTEREST_BUILDERS_LIEN_ACT'},
        {label : 'Notice of Maintenance Order', value : 'NOTICE_OF_MAINTENANCE_ORDER'},
        {label : 'Notice Of Property Agreement (FLA)', value : 'NOTICE_OF_PROPERTY_AGREEMENT_FLA'},
        {label : 'Option To Lease', value : 'OPTION_TO_LEASE'},
        {label : 'Option To Purchasea', value : 'OPTION_TO_PURCHASEA'},
        {label : 'Party Wall Agreement', value : 'PARTY_WALL_AGREEMENT'},
        {label : 'Possibility of Reverter', value : 'POSSIBILITY_OF_REVERTER'},
        {label : 'Property Transfer Tax Act Charge', value : 'PROPERTY_TRANSFER_TAX_ACT_CHARGE'},
        {label : 'Property Transfer Tax Act Lien', value : 'PROPERTY_TRANSFER_TAX_ACT_LIEN'},
        {label : 'Profit A Prendre', value : 'PROFIT_A_PRENDRE'},
        {label : 'Provincial Sales Tax Act Lien', value : 'PROVINCIAL_SALES_TAX_ACT_LIEN'},
        {label : 'Rent Charge', value : 'RENT_CHARGE'},
        {label : 'Resort Municipality Of Whistler Charge', value : 'RESORT_MUNICIPALITY_OF_WHISTLER_CHARGE'},
        {label : 'Restrictive Covenant', value : 'RESTRICTIVE_COVENANT'},
        {label : 'Right Of Entry', value : 'RIGHT_OF_ENTRY'},
        {label : 'Right of First Refusal', value : 'RIGHT_OF_FIRST_REFUSAL'},
        {label : 'Right Of First Refusal To Lease', value : 'RIGHT_OF_FIRST_REFUSAL_TO_LEASE'},
        {label : 'Right Of First Refusal To Mortgage', value : 'RIGHT_OF_FIRST_REFUSAL_TO_MORTGAGE'},
        {label : 'Right Of Way', value : 'RIGHT_OF_WAY'},
        {label : 'Right To Acquire A Party Wall Agreement', value : 'RIGHT_TO_ACQUIRE_A_PARTY_WALL_AGREEMENT'},
        {label : 'Right To Acquire A Restrictive Covenant', value : 'RIGHT_TO_ACQUIRE_A_RESTRICTIVE_COVENANT'},
        {label : 'Right To Acquire An Easement', value : 'RIGHT_TO_ACQUIRE_AN_EASEMENT'},
        {label : 'Right To Acquire Statutory Right of Way', value : 'RIGHT_TO_ACQUIRE_STATUTORY_RIGHT_OF_WAY'},
        {label : 'Right to Purchase', value : 'RIGHT_TO_PURCHASE'},
        {label : 'Royalty Agreement', value : 'ROYALTY_AGREEMENT'},
        {label : 'Security Act Charge', value : 'SECURITY_ACT_CHARGE'},
        {label : 'Social Service Tax Act Lien', value : 'SOCIAL_SERVICE_TAX_ACT_LIEN'},
        {label : 'Statutory Building Scheme', value : 'STATUTORY_BUILDING_SCHEME'},
        {label : 'Statutory Charge', value : 'STATUTORY_CHARGE'},
        {label : 'Statutory Right Of Way', value : 'STATUTORY_RIGHT_OF_WAY'},
        {label : 'Sub Right To Purchase', value : 'SUB_RIGHT_TO_PURCHASE'},
        {label : 'Sub Sublease', value : 'SUB_SUBLEASE'},
        {label : 'Sublease', value : 'SUBLEASE'},
        {label : 'Taxation (Rural Area) Act Lien', value : 'TAXATION_(RURAL_AREA)_ACT_LIEN'},
        {label : 'Timber Agreement', value : 'TIMBER_AGREEMENT'},
        {label : 'Tobacco Tax Act Lien', value : 'TOBACCO_TAX_ACT_LIEN'},
        {label : 'Treaty First Nation Debt', value : 'TREATY_FIRST_NATION_DEBT'},
        {label : 'Waiver of Right of First Refusal', value : 'WAIVER_OF_RIGHT_OF_FIRST_REFUSAL'},
        {label : 'Wills, Estates and Succession Act Charge', value : 'WILLS,_ESTATES_AND_SUCCESSION_ACT_CHARGE'},
        {label : 'Affordable Housing Agreement', value : 'AFFORDABLE_HOUSING_AGREEMENT'},
        {label : 'Agricultural Credit Act Charge', value : 'AGRICULTURAL_CREDIT_ACT_CHARGE'},
        {label : 'Agricultural Land Reserve - Exclusion', value : 'AGRICULTURAL_LAND_RESERVE_EXCLUSION'},
        {label : 'Assignment of Rents-Paper Form Signed Prior to July 1, 2014', value : 'ASSIGNMENT_OF_RENTS-PAPER_FORM_SIGNED_PRIOR_TO_JULY_1_2014'},
        {label : 'Builders Lien (By Court Order)', value : 'BUILDERS_LIEN_BY_COURT_ORDER'},
        {label : 'Certificate of Pending Litigation - Builders Lien Act', value : 'CERTIFICATE_OF_PENDING_LITIGATION_BUILDERS_LIEN_ACT'},
        {label : 'Certificate of Pending Litigation - Builders Lien Act', value : 'CERTIFICATE_OF_PENDING_LITIGATION_BUILDERS_LIEN_ACT'},
        {label : 'Charge by Court Order (No PTT)', value : 'CHARGE_BY_COURT_ORDER_NO_PTT'},
        {label : 'Charge By Court Order (With PTT)', value : 'CHARGE_BY_COURT_ORDER_WITH_PTT'},
        {label : 'Charge By Effluxion of Time', value : 'CHARGE_BY_EFFLUXION_OF_TIME'},
        {label : 'Claim of Builders Lien by Court Order', value : 'CLAIM_OF_BUILDERS_LIEN_BY_COURT_ORDER'},
        {label : 'Claim of Builders Lien By Effluxion of Time', value : 'CLAIM_OF_BUILDERS_LIEN_BY_EFFLUXION_OF_TIME'},
        {label : 'Claim of Builders Lien Notice to Commence an Action', value : 'CLAIM_OF_BUILDERS_LIEN_NOTICE_TO_COMMENCE_AN_ACTION'},
        {label : 'Court Order - Family Maintenance Enforcement Act', value : 'COURT_ORDER_FAMILY_MAINTENANCE_ENFORCEMENT_ACT'},
        {label : 'Cremation, Interment and Funderal Services Act', value : 'CREMATION_INTERMENT_AND_FUNDERAL_SERVICES_ACT'},
        {label : 'Crown Debt', value : 'CROWN_DEBT'},
        {label : 'Escheat Act - Cancellation of Mortgage', value : 'ESCHEAT_ACT_CANCELLATION_OF_MORTGAGE'},
        {label : 'Expropriation Act - Abandoned (Provincial)', value : 'EXPROPRIATION_ACT_ABANDONED_PROVINCIAL'},
        {label : 'Expropriation Act - Cancellation (Provincial)', value : 'EXPROPRIATION_ACT_CANCELLATION_PROVINCIAL'},
        {label : 'Forest Act Notice', value : 'FOREST_ACT_NOTICE'},
        {label : 'Heritage Status Notice', value : 'HERITAGE_STATUS_NOTICE'},
        {label : 'Hospital Act Notice', value : 'HOSPITAL_ACT_NOTICE'},
        {label : 'Human Resource Facility Act Notice', value : 'HUMAN_RESOURCE_FACILITY_ACT_NOTICE'},
        {label : 'Industrial Operation Compensation Act Agreement', value : 'INDUSTRIAL_OPERATION_COMPENSATION_ACT_AGREEMENT'},
        {label : 'Injunction', value : 'INJUNCTION'},
        {label : 'Judgment (By Court Order or Certificate)', value : 'JUDGMENT_BY_COURT_ORDER_OR_CERTIFICATE'},
        {label : 'Land (Spouse Protection) Act Charge', value : 'LAND_SPOUSE_PROTECTION_ACT_CHARGE'},
        {label : 'Land Tax Deferment Act Agreement', value : 'LAND_TAX_DEFERMENT_ACT_AGREEMENT'},
        {label : 'Land Use Contract', value : 'LAND_USE_CONTRACT'},
        {label : 'Lease (Breach of Terms)', value : 'LEASE_BREACH_OF_TERMS'},
        {label : 'Life Estate (With Death Certificate)', value : 'LIFE_ESTATE_WITH_DEATH_CERTIFICATE'},
        {label : 'Mountain Resort Improvement District -Dissolved', value : 'MOUNTAIN_RESORT_IMPROVEMENT_DISTRICT_-DISSOLVED'},
        {label : 'Modification', value : 'MODIFICATION'},
        {label : 'Mortgage-Paper Form C Original Signed Prior to July 1, 2014', value : 'MORTGAGE-PAPER_FORM_C_ORIGINAL_SIGNED_PRIOR_TO_JULY_1_2014'},
        {label : 'Municipal Government Notice', value : 'MUNICIPAL_GOVERNMENT_NOTICE'},
        {label : 'Notation by Effluxion of Time', value : 'NOTATION_BY_EFFLUXION_OF_TIME'},
        {label : 'Notice of Abandonment (Federal Expropriation)', value : 'NOTICE_OF_ABANDONMENT_FEDERAL_EXPROPRIATION'},
        {label : 'Notice of Contaminated Land, Land Title Act S.392', value : 'NOTICE_OF_CONTAMINATED_LAND_LAND_TITLE_ACT_S_392'},
        {label : 'Notice of Interest Builders Lien Act', value : 'NOTICE_OF_INTEREST_BUILDERS_LIEN_ACT'},
        {label : 'Notice of Maintenance Order (Court Order)', value : 'NOTICE_OF_MAINTENANCE_ORDER_COURT_ORDER'},
        {label : 'Notice of Property Agreement (Court Order)', value : 'NOTICE_OF_PROPERTY_AGREEMENT_COURT_ORDER'},
        {label : 'Notice of Tax Sale Redemption', value : 'NOTICE_OF_TAX_SALE_REDEMPTION'},
        {label : 'Personal Property Security Act Notice', value : 'PERSONAL_PROPERTY_SECURITY_ACT_NOTICE'},
        {label : 'Petroleum and Natural Gas Act Order', value : 'PETROLEUM_AND_NATURAL_GAS_ACT_ORDER'},
        {label : 'Possiblity of Reverter', value : 'POSSIBLITY_OF_REVERTER'},
        {label : 'Priority Agreement', value : 'PRIORITY_AGREEMENT'},
        {label : 'Public Health Act Notice', value : 'PUBLIC_HEALTH_ACT_NOTICE'},
        {label : 'Range Act Notice', value : 'RANGE_ACT_NOTICE'},
        {label : 'Resort Associations Act Area - Dissolved', value : 'RESORT_ASSOCIATIONS_ACT_AREA_DISSOLVED'},
        {label : 'Revocation of Power of Attorney/Representation Agreement', value : 'REVOCATION_OF_POWER_OF_ATTORNEY/REPRESENTATION_AGREEMENT'},
        {label : 'Right of Resumption Community Charger', value : 'RIGHT_OF_RESUMPTION_COMMUNITY_CHARGER'},
        {label : 'Right to Purchase (By Court Order)', value : 'RIGHT_TO_PURCHASE_BY_COURT_ORDER'},
        {label : 'Scheme of Replotting', value : 'SCHEME_OF_REPLOTTING'},
        {label : 'Securities Act Charge', value : 'SECURITIES_ACT_CHARGE'},
        {label : 'Special Survey, Land Title Act Order', value : 'SPECIAL_SURVEY_LAND_TITLE_ACT_ORDER'},
        {label : 'Stone and Timer Reservation', value : 'STONE_AND_TIMER_RESERVATION'},
        {label : 'Strata Property Act Lien', value : 'STRATA_PROPERTY_ACT_LIEN'},
        {label : 'Wills, Estates and Succession Act Charge (Court Order)', value : 'WILLS_ESTATES_AND_SUCCESSION_ACT_CHARGE_COURT_ORDER'},
        {label : 'Wills, Estates and Succession Act Legal Notation', value : 'WILLS_ESTATES_AND_SUCCESSION_ACT_LEGAL_NOTATION'},
    ],
};

export const instrumentTypeOptions = {
    instrumentTypeOptionsDefault : [
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment', value : 'ASSIGNMENT'},
        {label : 'Amending Agreement', value : 'AMENDING_AGREEMENT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : 'Certificates Of Lis Pendens', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Construction Lien', value : 'BUILDER_LIEN'},
        {label : 'Encroachment Agreement', value : 'ENCROACHMENT_AGREEMENT'},
        {label : 'Encumbrance', value : 'ENCUMBRANCE'},
        {label : 'Judgement', value : 'JUDGEMENT'},
        {label : 'Lien', value : 'LIEN'},
        {label : 'Mobile Home', value : 'MOBILE_HOME'},
        {label : 'Postponement', value : 'POSTPONEMENT'},
        {label : 'Restrictive Covenant', value : 'RESTICTIVE_COVENANT'},
        {label : 'Surface Lease', value : 'SURFACE_LEASE'},
        {label : 'Tax Notification', value : 'TAX_NOTIFICATION'},
        {label : 'Utility Right of Way', value : 'UTILITY_RIGHT_OF_WAY'},
        {label : 'Writ', value : 'WRIT'},
        {label : 'Zoning Regulations', value : 'ZONING_REGULATIONS'}
    ],

    instrumentTypeOptionsMB : [
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment', value : 'ASSIGNMENT'},
        {label : 'Amending Agreement', value : 'AMENDING_AGREEMENT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : 'Certificates Of Lis Pendens', value : 'CERTIFICATES_OF_LIS_PENDENS'},
        {label : 'Construction Lien', value : 'BUILDER_LIEN'},
        {label : 'Encroachment Agreement', value : 'ENCROACHMENT_AGREEMENT'},
        {label : 'Encumbrance', value : 'ENCUMBRANCE'},
        {label : 'Judgement', value : 'JUDGEMENT'},
        {label : 'Homestead Consent', value : 'HOMESTEAD_CONSENT'},
        {label : 'Lien', value : 'LIEN'},
        {label : 'Mobile Home', value : 'MOBILE_HOME'},
        {label : 'Postponement', value : 'POSTPONEMENT'},
        {label : 'Restrictive Covenant', value : 'RESTICTIVE_COVENANT'},
        {label : 'Surface Lease', value : 'SURFACE_LEASE'},
        {label : 'Tax Notification', value : 'TAX_NOTIFICATION'},
        {label : 'Utility Right of Way', value : 'UTILITY_RIGHT_OF_WAY'},
        {label : 'Writ', value : 'WRIT'},
        {label : 'Zoning Regulations', value : 'ZONING_REGULATIONS'}
    ],

    instrumentTypeOptionsBC : [
        {label : 'Mortgage', value : 'MORTGAGE'},
        {label : 'Caveat', value : 'CAVEAT'},
        {label : 'Assignment of Rents', value : 'ASSIGNMENT_OF_RENTS'},
        {label : 'Modification', value : 'MODIFICATION'},
        {label : 'Extension of Mortgage', value : 'EXTENSION_OF_MORTGAGE'},
        {label : 'Priority Agreement', value : 'PRIORITY_AGREEMENT'},
        {label : 'Transfer of Charge', value : 'TRANSFER_OF_CHARGE'},
        {label : 'Claim of Builders Lien', value : 'CLAIM_OF_BUILDERS_LIEN'},
        {label : 'Bank Act Charge', value : 'BANK_ACT_CHARGE'},
        {label : 'Builders Lien', value : 'BUILDERS_LIEN'},
        {label : 'Certificate of Levy', value : 'CERTIFICATE_OF_LEVY'},
        {label : 'Corporation Capital Tax Act Lien', value : 'CORPORATION_CAPITAL_TAX_ACT_LIEN'},
        {label : 'Court Order', value : 'COURT_ORDER'},
        {label : 'Covenant', value : 'COVENANT'},
        {label : 'Crown Debt', value : 'CROWN_DEBT'},
        {label : 'Crown Lien', value : 'CROWN_LIEN'},
        {label : 'Crystallized Floating Charge', value : 'CRYSTALLIZED_FLOATING_CHARGE'},
        {label : 'Easement', value : 'EASEMENT'},
        {label : 'Easement and Indemnity Agreementa', value : 'EASEMENT_AND_INDEMNITY_AGREEMENTA'},
        {label : 'Equitable Charge', value : 'EQUITABLE_CHARGE'},
        {label : 'Equitable Interest', value : 'EQUITABLE_INTEREST'},
        {label : 'Grant Of Timber', value : 'GRANT_OF_TIMBER'},
        {label : 'Insurance Premium Tax Act Lien', value : 'INSURANCE_PREMIUM_TAX_ACT_LIEN'},
        {label : 'Judgment', value : 'JUDGMENT'},
        {label : 'Land Tax Deferment Act Agreement', value : 'LAND_TAX_DEFERMENT_ACT_AGREEMENT'},
        {label : 'Lease', value : 'LEASE'},
        {label : 'Legal Profession Act Charge', value : 'LEGAL_PROFESSION_ACT_CHARGE'},
        {label : 'Life Estate', value : 'LIFE_ESTATE'},
        {label : 'Logging Tax Act Lien', value : 'LOGGING_TAX_ACT_LIEN'},
        {label : 'Mines Act Charge', value : 'MINES_ACT_CHARGE'},
        {label : 'Mining Agreement', value : 'MINING_AGREEMENT'},
        {label : 'Mineral Tax Act Lien', value : 'MINERAL_TAX_ACT_LIEN'},
        {label : 'Motor Fuel Tax Act', value : 'MOTOR_FUEL_TAX_ACT'},
        {label : 'Notice Of Interest Builders Lien Act', value : 'NOTICE_OF_INTEREST_BUILDERS_LIEN_ACT'},
        {label : 'Notice of Maintenance Order', value : 'NOTICE_OF_MAINTENANCE_ORDER'},
        {label : 'Notice Of Property Agreement (FLA)', value : 'NOTICE_OF_PROPERTY_AGREEMENT_FLA'},
        {label : 'Option To Lease', value : 'OPTION_TO_LEASE'},
        {label : 'Option To Purchasea', value : 'OPTION_TO_PURCHASEA'},
        {label : 'Party Wall Agreement', value : 'PARTY_WALL_AGREEMENT'},
        {label : 'Possibility of Reverter', value : 'POSSIBILITY_OF_REVERTER'},
        {label : 'Property Transfer Tax Act Charge', value : 'PROPERTY_TRANSFER_TAX_ACT_CHARGE'},
        {label : 'Property Transfer Tax Act Lien', value : 'PROPERTY_TRANSFER_TAX_ACT_LIEN'},
        {label : 'Profit A Prendre', value : 'PROFIT_A_PRENDRE'},
        {label : 'Provincial Sales Tax Act Lien', value : 'PROVINCIAL_SALES_TAX_ACT_LIEN'},
        {label : 'Rent Charge', value : 'RENT_CHARGE'},
        {label : 'Resort Municipality Of Whistler Charge', value : 'RESORT_MUNICIPALITY_OF_WHISTLER_CHARGE'},
        {label : 'Restrictive Covenant', value : 'RESTRICTIVE_COVENANT'},
        {label : 'Right Of Entry', value : 'RIGHT_OF_ENTRY'},
        {label : 'Right of First Refusal', value : 'RIGHT_OF_FIRST_REFUSAL'},
        {label : 'Right Of First Refusal To Lease', value : 'RIGHT_OF_FIRST_REFUSAL_TO_LEASE'},
        {label : 'Right Of First Refusal To Mortgage', value : 'RIGHT_OF_FIRST_REFUSAL_TO_MORTGAGE'},
        {label : 'Right Of Way', value : 'RIGHT_OF_WAY'},
        {label : 'Right To Acquire A Party Wall Agreement', value : 'RIGHT_TO_ACQUIRE_A_PARTY_WALL_AGREEMENT'},
        {label : 'Right To Acquire A Restrictive Covenant', value : 'RIGHT_TO_ACQUIRE_A_RESTRICTIVE_COVENANT'},
        {label : 'Right To Acquire An Easement', value : 'RIGHT_TO_ACQUIRE_AN_EASEMENT'},
        {label : 'Right To Acquire Statutory Right of Way', value : 'RIGHT_TO_ACQUIRE_STATUTORY_RIGHT_OF_WAY'},
        {label : 'Right to Purchase', value : 'RIGHT_TO_PURCHASE'},
        {label : 'Royalty Agreement', value : 'ROYALTY_AGREEMENT'},
        {label : 'Security Act Charge', value : 'SECURITY_ACT_CHARGE'},
        {label : 'Social Service Tax Act Lien', value : 'SOCIAL_SERVICE_TAX_ACT_LIEN'},
        {label : 'Statutory Building Scheme', value : 'STATUTORY_BUILDING_SCHEME'},
        {label : 'Statutory Charge', value : 'STATUTORY_CHARGE'},
        {label : 'Statutory Right Of Way', value : 'STATUTORY_RIGHT_OF_WAY'},
        {label : 'Sub Right To Purchase', value : 'SUB_RIGHT_TO_PURCHASE'},
        {label : 'Sub Sublease', value : 'SUB_SUBLEASE'},
        {label : 'Sublease', value : 'SUBLEASE'},
        {label : 'Taxation (Rural Area) Act Lien', value : 'TAXATION_(RURAL_AREA)_ACT_LIEN'},
        {label : 'Timber Agreement', value : 'TIMBER_AGREEMENT'},
        {label : 'Tobacco Tax Act Lien', value : 'TOBACCO_TAX_ACT_LIEN'},
        {label : 'Treaty First Nation Debt', value : 'TREATY_FIRST_NATION_DEBT'},
        {label : 'Waiver of Right of First Refusal', value : 'WAIVER_OF_RIGHT_OF_FIRST_REFUSAL'},
        {label : 'Wills, Estates and Succession Act Charge', value : 'WILLS,_ESTATES_AND_SUCCESSION_ACT_CHARGE'},
        {label : 'Affordable Housing Agreement', value : 'AFFORDABLE_HOUSING_AGREEMENT'},
        {label : 'Agricultural Credit Act Charge', value : 'AGRICULTURAL_CREDIT_ACT_CHARGE'},
        {label : 'Agricultural Land Reserve - Exclusion', value : 'AGRICULTURAL_LAND_RESERVE_EXCLUSION'},
        {label : 'Assignment of Rents-Paper Form Signed Prior to July 1, 2014', value : 'ASSIGNMENT_OF_RENTS-PAPER_FORM_SIGNED_PRIOR_TO_JULY_1_2014'},
        {label : 'Builders Lien (By Court Order)', value : 'BUILDERS_LIEN_BY_COURT_ORDER'},
        {label : 'Certificate of Pending Litigation - Builders Lien Act', value : 'CERTIFICATE_OF_PENDING_LITIGATION_BUILDERS_LIEN_ACT'},
        {label : 'Certificate of Pending Litigation - Builders Lien Act', value : 'CERTIFICATE_OF_PENDING_LITIGATION_BUILDERS_LIEN_ACT'},
        {label : 'Charge by Court Order (No PTT)', value : 'CHARGE_BY_COURT_ORDER_NO_PTT'},
        {label : 'Charge By Court Order (With PTT)', value : 'CHARGE_BY_COURT_ORDER_WITH_PTT'},
        {label : 'Charge By Effluxion of Time', value : 'CHARGE_BY_EFFLUXION_OF_TIME'},
        {label : 'Claim of Builders Lien by Court Order', value : 'CLAIM_OF_BUILDERS_LIEN_BY_COURT_ORDER'},
        {label : 'Claim of Builders Lien By Effluxion of Time', value : 'CLAIM_OF_BUILDERS_LIEN_BY_EFFLUXION_OF_TIME'},
        {label : 'Claim of Builders Lien Notice to Commence an Action', value : 'CLAIM_OF_BUILDERS_LIEN_NOTICE_TO_COMMENCE_AN_ACTION'},
        {label : 'Court Order - Family Maintenance Enforcement Act', value : 'COURT_ORDER_FAMILY_MAINTENANCE_ENFORCEMENT_ACT'},
        {label : 'Cremation, Interment and Funderal Services Act', value : 'CREMATION_INTERMENT_AND_FUNDERAL_SERVICES_ACT'},
        {label : 'Crown Debt', value : 'CROWN_DEBT'},
        {label : 'Escheat Act - Cancellation of Mortgage', value : 'ESCHEAT_ACT_CANCELLATION_OF_MORTGAGE'},
        {label : 'Expropriation Act - Abandoned (Provincial)', value : 'EXPROPRIATION_ACT_ABANDONED_PROVINCIAL'},
        {label : 'Expropriation Act - Cancellation (Provincial)', value : 'EXPROPRIATION_ACT_CANCELLATION_PROVINCIAL'},
        {label : 'Forest Act Notice', value : 'FOREST_ACT_NOTICE'},
        {label : 'Heritage Status Notice', value : 'HERITAGE_STATUS_NOTICE'},
        {label : 'Hospital Act Notice', value : 'HOSPITAL_ACT_NOTICE'},
        {label : 'Human Resource Facility Act Notice', value : 'HUMAN_RESOURCE_FACILITY_ACT_NOTICE'},
        {label : 'Industrial Operation Compensation Act Agreement', value : 'INDUSTRIAL_OPERATION_COMPENSATION_ACT_AGREEMENT'},
        {label : 'Injunction', value : 'INJUNCTION'},
        {label : 'Judgment (By Court Order or Certificate)', value : 'JUDGMENT_BY_COURT_ORDER_OR_CERTIFICATE'},
        {label : 'Land (Spouse Protection) Act Charge', value : 'LAND_SPOUSE_PROTECTION_ACT_CHARGE'},
        {label : 'Land Tax Deferment Act Agreement', value : 'LAND_TAX_DEFERMENT_ACT_AGREEMENT'},
        {label : 'Land Use Contract', value : 'LAND_USE_CONTRACT'},
        {label : 'Lease (Breach of Terms)', value : 'LEASE_BREACH_OF_TERMS'},
        {label : 'Life Estate (With Death Certificate)', value : 'LIFE_ESTATE_WITH_DEATH_CERTIFICATE'},
        {label : 'Mountain Resort Improvement District -Dissolved', value : 'MOUNTAIN_RESORT_IMPROVEMENT_DISTRICT_-DISSOLVED'},
        {label : 'Modification', value : 'MODIFICATION'},
        {label : 'Mortgage-Paper Form C Original Signed Prior to July 1, 2014', value : 'MORTGAGE-PAPER_FORM_C_ORIGINAL_SIGNED_PRIOR_TO_JULY_1_2014'},
        {label : 'Municipal Government Notice', value : 'MUNICIPAL_GOVERNMENT_NOTICE'},
        {label : 'Notation by Effluxion of Time', value : 'NOTATION_BY_EFFLUXION_OF_TIME'},
        {label : 'Notice of Abandonment (Federal Expropriation)', value : 'NOTICE_OF_ABANDONMENT_FEDERAL_EXPROPRIATION'},
        {label : 'Notice of Contaminated Land, Land Title Act S.392', value : 'NOTICE_OF_CONTAMINATED_LAND_LAND_TITLE_ACT_S_392'},
        {label : 'Notice of Interest Builders Lien Act', value : 'NOTICE_OF_INTEREST_BUILDERS_LIEN_ACT'},
        {label : 'Notice of Maintenance Order (Court Order)', value : 'NOTICE_OF_MAINTENANCE_ORDER_COURT_ORDER'},
        {label : 'Notice of Property Agreement (Court Order)', value : 'NOTICE_OF_PROPERTY_AGREEMENT_COURT_ORDER'},
        {label : 'Notice of Tax Sale Redemption', value : 'NOTICE_OF_TAX_SALE_REDEMPTION'},
        {label : 'Personal Property Security Act Notice', value : 'PERSONAL_PROPERTY_SECURITY_ACT_NOTICE'},
        {label : 'Petroleum and Natural Gas Act Order', value : 'PETROLEUM_AND_NATURAL_GAS_ACT_ORDER'},
        {label : 'Possiblity of Reverter', value : 'POSSIBLITY_OF_REVERTER'},
        {label : 'Priority Agreement', value : 'PRIORITY_AGREEMENT'},
        {label : 'Public Health Act Notice', value : 'PUBLIC_HEALTH_ACT_NOTICE'},
        {label : 'Range Act Notice', value : 'RANGE_ACT_NOTICE'},
        {label : 'Resort Associations Act Area - Dissolved', value : 'RESORT_ASSOCIATIONS_ACT_AREA_DISSOLVED'},
        {label : 'Revocation of Power of Attorney/Representation Agreement', value : 'REVOCATION_OF_POWER_OF_ATTORNEY/REPRESENTATION_AGREEMENT'},
        {label : 'Right of Resumption Community Charger', value : 'RIGHT_OF_RESUMPTION_COMMUNITY_CHARGER'},
        {label : 'Right to Purchase (By Court Order)', value : 'RIGHT_TO_PURCHASE_BY_COURT_ORDER'},
        {label : 'Scheme of Replotting', value : 'SCHEME_OF_REPLOTTING'},
        {label : 'Securities Act Charge', value : 'SECURITIES_ACT_CHARGE'},
        {label : 'Special Survey, Land Title Act Order', value : 'SPECIAL_SURVEY_LAND_TITLE_ACT_ORDER'},
        {label : 'Stone and Timer Reservation', value : 'STONE_AND_TIMER_RESERVATION'},
        {label : 'Strata Property Act Lien', value : 'STRATA_PROPERTY_ACT_LIEN'},
        {label : 'Wills, Estates and Succession Act Charge (Court Order)', value : 'WILLS_ESTATES_AND_SUCCESSION_ACT_CHARGE_COURT_ORDER'},
        {label : 'Wills, Estates and Succession Act Legal Notation', value : 'WILLS_ESTATES_AND_SUCCESSION_ACT_LEGAL_NOTATION'},
    ],
}

export const instrumentType = {
    'ON' : instrumentTypeOptions.instrumentTypeOptionsDefault,
    'AB' :instrumentTypeOptions.instrumentTypeOptionsDefault,
    'NS' : instrumentTypeOptions.instrumentTypeOptionsDefault,
    'NB' : instrumentTypeOptions.instrumentTypeOptionsDefault,
    'MB' : instrumentTypeOptions.instrumentTypeOptionsMB,
    'SK' : instrumentTypeOptions.instrumentTypeOptionsDefault,
    'BC' : instrumentTypeOptions.instrumentTypeOptionsBC
};

export const provinceBasedFinancingTypeDropDown = {
    'ON' : financingTypeDropDowns.financingTypeOptions_ON,
    'NB' : financingTypeDropDowns.financingTypeOptions_NB,
    'NS' : financingTypeDropDowns.financingTypeOptions_NS,
    'MB' : financingTypeDropDowns.financingTypeOptions_DEFAULT,
    'AB' : financingTypeDropDowns.financingTypeOptions_DEFAULT,
    'SK' : financingTypeDropDowns.financingTypeOptions_DEFAULT,
    'BC' : financingTypeDropDowns.financingTypeOptions_BC,
};

export const SubjectPropertyTitle = {
    Subject_Property: 'Subject Property',
    Property_Teranet: 'Property/Teranet Connect',
    Subject_Property_SPIN: 'Subject Property/SPIN',
    Subject_Property_TPR: 'Subject Property/TPR',
    Subject_Property_ISC: 'Subject Property/ISC',
    Property: 'Properties'
}

export const LawClerkTitle = {
    Law_Clerk : 'Law Clerk',
    Legal_Assistant : 'Legal Assistant',
    Conveyancer : 'Conveyancer',
    Paralegal : 'Paralegal',
    Assistant : 'Assistant'
};

export const LawClerkPluralTitle = {
    Law_Clerk : 'Law Clerks',
    Legal_Assistant : 'Legal Assistants',
    Conveyancer : 'Conveyancers',
    Paralegal : 'Paralegals',
    Assistant : 'Assistants'
};

export const SolicitorTitle = {
    Lawyer_Notary : 'Lawyer/Notary',
    Solicitor : 'Solicitor'
};

export const SolicitorPluralTitle = {
    Lawyer_Notary : 'Lawyers/Notaries',
    Lawyer : 'Lawyers',
    Solicitor : 'Solicitors'
};

export const LawClerkName = {
    Law_Clerk : 'Law Clerk Name',
    Legal_Assistant : 'Legal Assistant Name',
    Conveyancer : 'Conveyancer Name',
    Paralegal : 'Paralegal Name',
    Assistant : 'Assistant Name'
};

export const LateClosingFieldName = {
    Late_Closing : 'Late Closing',
    Interest : 'Interest'
};

export const OnClosingDateName = {
   On_Closing_Date : 'On Closing Date',
   On_Possession : 'On Possession'
};

export const WitnessName = {
    Witness_Name : 'Witness Name',
    Witness_Notary_Name : 'Witness/Notary Name'
};

export const Witness = {
    Witness : 'Witness',
    Witness_Notary : 'Witness/Notary'
};

export const MortgageIsToTitle = {
    IS_TO : 'is to',
    MORTGAGE_IS_TO : 'Mortgage is to'
};

export const MortgageeNameTitle = {
    ON_MORTGAGEE_NAME : 'Mortgagee Name(s) from Teranet Connect',
    AB_MORTGAGEE_NAME : 'Mortgagee Name(s) from SPIN',
    SK_MORTGAGEE_NAME : 'Mortgagee Name(s) from ISC',
    MB_MORTGAGEE_NAME : 'Mortgagee Name(s) from TPR'
};

export const AssignmentRegNoTitle = {
    ON_ASSIGNMENT_REG_NO : 'Assignment Reg. No.',
    AB_ASSIGNMENT_REG_NO : 'Assignment Rents',
    SK_ASSIGNMENT_REG_NO : 'Assignment Reg. No.',
    NB_ASSIGNMENT_REG_NO : 'Assignment Reg. No.',
    NS_ASSIGNMENT_REG_NO : 'Assignment Doc. No.',
};


export const ClientCapacity = {
    CAPACITY : 'Capacity',
    TENURE : 'Tenure',
    TENURE_SHARE : 'Tenure/Share',
    TENANCY : 'Tenancy',
};
export const LawyerTitle = {
    Lawyer : 'Lawyer',
    Lawyer_Notary : 'Lawyer/Notary'
};
export const LawyerTitleInPlural = {
    Lawyer : 'Lawyers',
    Lawyer_Notary : 'Lawyers/Notaries'
};
export const FeeForCondoStatusCertificateTitle = {
    Fee_For_Status_Certificate : 'Fee for Condominium Status Certificate',
    Fee_For_Estoppel_Certificate : 'Fee for Condominium Estoppel Certificate'
};

export const Section13ReserveFundStudyDateTitle = {
    Section13_Reserve_Fund_Study_Date : 'Section 13 Reserve Fund Study Date',
    Reserve_Fund_Study_Date : 'Reserve Fund Study Date'
};

export const Section13CurrentReserveFundAmount = {
    Section13_Current_Reserve_Fund_Amount : 'Section 13 Current Reserve Fund Amount',
    Current_Reserve_Fund_Amount : 'Current Reserve Fund Amount'
};

export const Section15AnnualReserveFundAmount = {
    Section15_Annual_Reserve_Fund_Amount : 'Section 15 Annual Reserve Fund Amount',
    Annual_Reserve_Fund_Amount : 'Annual Reserve Fund Amount'
};

export const StatusCertificateInformationTitle = {
    Status_Certificate_Information : 'Status Certificate Information',
    Estoppel_Certificate_Information : 'Estoppel Certificate Information'
};


export const FeeForStatusCertificateTitle = {
    Fee_For_Status_Certificate : 'Fee for Status Certificate',
    Fee_For_Estoppel_Certificate : 'Fee for Estoppel Certificate'
};

export const provinceBasedLawyerTitle = {
    'ON' : LawyerTitle.Lawyer,
    'NB' : LawyerTitle.Lawyer,
    'NS' : LawyerTitle.Lawyer,
    'AB' : LawyerTitle.Lawyer,
    'MB' : LawyerTitle.Lawyer,
    'SK' : LawyerTitle.Lawyer,
    'BC' : LawyerTitle.Lawyer_Notary
};

export const provinceBasedLawyerTitleInPlural = {
    'ON' : 'Lawyers',
    'NB' : 'Lawyers',
    'NS' : 'Lawyers',
    'AB' : 'Lawyers',
    'MB' : 'Lawyers',
    'SK' : 'Lawyers',
    'BC' : 'Lawyers/Notaries'
};



export const provinceBasedLawClerkTitle = {
    'ON' : LawClerkTitle.Law_Clerk,
    'NB' : LawClerkTitle.Law_Clerk,
    'NS' : LawClerkTitle.Paralegal,
    'AB' : LawClerkTitle.Legal_Assistant,
    'MB' : LawClerkTitle.Legal_Assistant,
    'SK' : LawClerkTitle.Legal_Assistant,
    'BC' : LawClerkTitle.Assistant
};

export const provinceBasedLawClerkTitleInPlural = {
    'ON' : LawClerkPluralTitle.Law_Clerk,
    'NB' : LawClerkPluralTitle.Law_Clerk,
    'NS' : LawClerkPluralTitle.Paralegal,
    'AB' : LawClerkPluralTitle.Legal_Assistant,
    'MB' : LawClerkPluralTitle.Legal_Assistant,
    'SK' : LawClerkPluralTitle.Legal_Assistant,
    'BC' : LawClerkPluralTitle.Assistant
};

export const provinceBasedLawClerkName = {
    'ON' : LawClerkTitle.Law_Clerk,
    'NB' : LawClerkTitle.Law_Clerk,
    'NS' : LawClerkTitle.Law_Clerk,
    'AB' : LawClerkTitle.Legal_Assistant,
    'MB' : LawClerkTitle.Legal_Assistant,
    'SK' : LawClerkTitle.Legal_Assistant,
    'BC' : LawClerkTitle.Assistant
};

export const Tax_RATE = {
    HST_RATE : 'HST_RATE',
    ONTARIO_LTT_RATE : 'ONTARIO_LTT_RATE',
    TORONTO_LTT_RATE: 'TORONTO_LTT_RATE'
};

export const TaxRateTierTypes = {
    RESIDENTIAL: "RESIDENTIAL",
    COMMERCIAL: "COMMERCIAL"
}

export const contactProvinceSectionLabels = {
    statusCertificateInformation : {
        'ON' : 'Status Certificate<br/>Information',
        'NB' : 'Estoppel Certificate<br/>Information',
        'NS' : 'Estoppel Certificate<br/>Information',
        'AB' : 'Status Certificate<br/>Information',
        'MB' : 'Status Certificate<br/>Information',
        'SK' : 'Status Certificate<br/>Information',
        'BC' : 'Status Certificate<br/>Information'
    },
}

export const provinceSectionLabels = {
    propertyTeranet : {
        'ON' : 'Property/Teranet Connect',
        'NB' : 'Subject Property',
        'NS' : 'Subject Property',
        'AB' : 'Subject Property/SPIN',
        'MB' : 'Subject Property/TPR',
        'SK' : 'Subject Property/ISC',
        'BC' : 'Properties'},
    trustLedger : {
        'ON' : 'Trust Ledger',
        'NB' : 'Trust Ledger',
        'NS' : 'Trust Ledger',
        'AB' : 'Trust Ledger (Reconciliation)',
        'MB' : 'Trust Ledger (Reconciliation)',
        'SK' : 'Trust Ledger',
        'BC' : 'Order to Pay/Trust Reconciliation'},
    directionReFunds : {
        'ON' : 'Direction re Funds',
        'NB' : 'Direction re Funds',
        'NS' : 'Direction re Funds',
        'AB' : 'Direction to Pay',
        'MB' : 'Direction to Pay',
        'SK' : 'Direction to Pay',
        'BC' : 'Direction to Pay'},
    executionAffidavits : {
        'ON' : 'Executions Affidavits',
        'NB' : 'Executions Affidavits',
        'NS' : 'Judgement Declaration',
        'AB' : 'Executions Affidavits',
        'MB' : 'Executions Affidavits',
        'SK' : 'Executions Affidavits',
        'BC' : 'Executions Affidavits'},
    vendors : {
        'ON' : 'Vendors',
        'NB' : 'Vendors',
        'NS' : 'Vendors',
        'AB' : 'Vendors',
        'MB' : 'Vendors',
        'SK' : 'Vendors',
        'BC' : 'Sellers'
    },
    matterOpening : {
        'ON' : 'Matter Opening',
        'NB' : 'Matter Opening',
        'NS' : 'Matter Opening',
        'AB' : 'Matter Opening',
        'MB' : 'Matter Opening',
        'SK' : 'Matter Opening',
        'BC' : 'Matter Details'
    },
    purchasersSolicitor : {
        'ON' : 'Purchasers & Solicitor',
        'NB' : 'Purchasers & Solicitor',
        'NS' : 'Purchasers & Solicitor',
        'AB' : 'Purchasers & Solicitor',
        'MB' : 'Purchasers & Solicitor',
        'SK' : 'Purchasers & Solicitor',
        'BC' : 'Buyers & Lawyer/Notary'
    },
    existingMortgage : {
        'ON' : 'Existing Mortgage(s)',
        'NB' : 'Existing Mortgage(s)',
        'NS' : 'Existing Mortgage(s)',
        'AB' : 'Existing Mortgage(s)',
        'MB' : 'Existing Mortgage(s)',
        'SK' : 'Existing Mortgage(s)',
        'BC' : 'Charges to Remove'
    },
};

export const provinceSectionPageTitles = {
    propertyTeranet : {
        'ON' : SubjectPropertyTitle.Property_Teranet,
        'NB' : SubjectPropertyTitle.Subject_Property,
        'NS' : SubjectPropertyTitle.Subject_Property,
        'AB' : SubjectPropertyTitle.Subject_Property_SPIN,
        'MB' : SubjectPropertyTitle.Subject_Property_TPR,
        'SK' : SubjectPropertyTitle.Subject_Property_ISC,
        'BC' : SubjectPropertyTitle.Property}
};

export const loanTypeOptions_ON: DdlTypeForLoan[] = <DdlTypeForLoan[]>(
    [{
        label: "",
        value: ""
    }, {
        label: "Arranged",
        value: "ARRANGED"
    },{
        label: "Assumed",
        value: "ASSUMED"
    },{
        label: "Back to Vendor",
        value: "BACK_TO_VENDOR"
    },{
        label: "Bridge Financing",
        value: "BRIDGE"
    }]
);

export const mtgLoanTypeOptions_ON : DdlTypeForLoan[] = <DdlTypeForLoan[]>(
    [{
        label: "Arranged",
        value: "ARRANGED"
    },{
        label: "Back to Vendor",
        value: "BACK_TO_VENDOR"
    }]
);

export const mtgLoanTypeOptions_AB : DdlTypeForLoan[] = <DdlTypeForLoan[]>(
    [{
        label: "New",
        value: "NEW"
    },{
        label: "Back to Vendor",
        value: "BACK_TO_VENDOR"
    }]
);

export const loanTypeOptions_AB: DdlTypeForLoan[] = <DdlTypeForLoan[]>(
    [{
        label: "",
        value: ""
    }, {
        label: "New",
        value: "ARRANGED"
    },{
        label: "Assumed",
        value: "ASSUMED"
    },{
        label: "Back to Vendor",
        value: "BACK_TO_VENDOR"
    },{
        label: "Bridge Financing",
        value: "BRIDGE"
    }]
);

export const loanTypeOptions_SK: DdlTypeForLoan[] = <DdlTypeForLoan[]>(
    [{
        label: "",
        value: ""
    }, {
        label: "New",
        value: "ARRANGED"
    },{
        label: "Assumed",
        value: "ASSUMED"
    },{
        label: "Back to Vendor",
        value: "BACK_TO_VENDOR"
    },{
        label: "Bridge Financing",
        value: "BRIDGE"
    }]
);

export const ProvinceBasedLoanTypeOptions  = {
    'ON' : loanTypeOptions_ON,
    'NB' : loanTypeOptions_ON,
    'NS' : loanTypeOptions_ON,
    'AB' : loanTypeOptions_AB,
    'MB' : loanTypeOptions_AB,
    'SK' : loanTypeOptions_SK,
    'BC' : loanTypeOptions_ON,
};

export const MtgLoanTypeOptions  = {
    'ON' : mtgLoanTypeOptions_ON,
    'AB' : mtgLoanTypeOptions_AB
};

export const adjustAsOptions_Default = [
    { label: "Closing Date", value: "CLOSING_DATE" },
    { label: "Specify", value: "SPECIFY" }
];

export const adjustAsOptions_MB = [
    { label: "Possession Date", value: "CLOSING_DATE" },
    { label: "Specify", value: "SPECIFY" }
];

export const adjustAsOptions_AB = [
    { label: "Closing Date", value: "CLOSING_DATE" },
    { label: "Specify", value: "SPECIFY" },
    { label: "Occupancy Date", value: "OCCUPANCY_DATE" }
];

export const adjustAsOptions_ON_Interim_SOA_Project = [
    { label: "Occupancy Date", value: "OCCUPANCY_DATE" },
    { label: "Specify", value: "SPECIFY" }
];

export const adjustAsOptions  = {
    'ON' : adjustAsOptions_Default,
    'NB' : adjustAsOptions_Default,
    'NS' : adjustAsOptions_Default,
    'AB' : adjustAsOptions_AB,
    'MB' : adjustAsOptions_MB,
    'SK' : adjustAsOptions_MB,
    'BC' : adjustAsOptions_Default,
    'ON_INTERIM_SOA_PROJECT' : adjustAsOptions_ON_Interim_SOA_Project
};
export const interestEstateOptions = {
    'InterestEstateOptions_Default' : [
        {label : 'Fee Simple', value : 'FEE_SIMPLE'},
        {label : 'Leasehold', value : 'LEASEHOLD'},
        {label : 'Easement', value : 'EASEMENT'},
        {label : 'Fee Simple with New Easement', value : 'FEE_SIMPLE_NEW_EASEMENT'},
        {label : 'Fee Simple - Surface Rights', value : 'FEE_SIMPLE_SURFACE_RIGHTS'},
        {label : 'Fee Simple - Mining Rights', value : 'FEE_SIMPLE_MINING_RIGHTS'},
        {label : 'Life Estate Remainder in Fee', value : 'LIFE_ESTATE_REMAINDER'},
        {label : 'Other (requires pre-approval)', value : 'OTHER'}
    ],
    'InterestEstateOptions_AB' : [
        {label: 'Fee Simple', value: 'FEE_SIMPLE'},
        {label : 'Leasehold', value : 'LEASEHOLD'},
        {label: 'Life Interest', value: 'LIFE_INTEREST'},
        {label: 'Fee Simple subject to a Life Interest', value: 'FEE_SIMPLE_LIFE_INTEREST'},
        {label : 'Other', value : 'OTHER'}
     ],
    'InterestEstateOptions_BC' : [
        {label: 'Fee Simple', value: 'FEE_SIMPLE'},
        {label: 'Agreement for Sale', value: 'AGREEMENT_SALE'},
        {label: 'Life Estate', value: 'LIFE_ESTATE'},
        {label: 'Foreclosure', value: 'FORECLOSURE'},
        {label: 'Lease', value: 'LEASE'},
        {label: 'Court Order', value: 'COURT_ORDER'},
        {label: 'Quit Claim', value: 'QUIT_CLAIM'},
        {label: 'Lease Modification', value: 'LEASE_MODIFICATION'},
        {label: 'Amalgamation', value: 'AMALGAMATION'},
        {label: 'Prepaid Lease', value: 'PREPAID_LEASE'},
        {label: 'Correcting Transaction', value: 'CORRECTING_TRANSACTION'},
        {label: 'Other', value: 'OTHER'}
     ],
    'InterestEstateOptions_SK' : [
        {label: 'Fee Simple', value: 'FEE_SIMPLE'},
        {label : 'Leasehold', value : 'LEASEHOLD'}
    ],
    'InterestEstateOptions_NB' : [
        {label : 'Fee Simple', value : 'FEE_SIMPLE'},
        {label : 'Leasehold', value : 'LEASEHOLD'},
        {label: 'Life Interest', value: 'LIFE_INTEREST'},
        {label: 'Fee Simple subject to a Life Interest', value: 'FEE_SIMPLE_LIFE_INTEREST'},
        {label : 'Other', value : 'OTHER'}
    ]
};
export const ProvinceBasedInterestEstateOptions  = {
    'ON' : interestEstateOptions.InterestEstateOptions_Default,
    'NB' : interestEstateOptions.InterestEstateOptions_NB,
    'NS' : interestEstateOptions.InterestEstateOptions_AB,
    'AB' : interestEstateOptions.InterestEstateOptions_AB,
    'MB' : interestEstateOptions.InterestEstateOptions_SK,
    'SK' : interestEstateOptions.InterestEstateOptions_SK,
    'BC' : interestEstateOptions.InterestEstateOptions_BC,
};

export const  condoPlanTypeOptions = { 'condoPlanOptions_Default' : [
    {label : 'Standard Condominium Plan', value : 'STANDARD_CONDOMINIUM_PLAN', type : 'SCP'},
    {label : 'Condominium Plan', value : 'CONDOMINIUM_PLAN', type : 'CP'},
    {label : 'Common Elements Condominium Plan', value : 'COMMON_ELEMENTS_CONDOMINIUM_PLAN', type : 'CECP'},
    {label : 'Phased Condominium Plan', value : 'PHASED_CONDOMINIUM_PLAN', type : 'PCP'},
    {label : 'Vacant Land Condominium Plan', value : 'VACANT_LAND_CONDOMINIUM_PLAN', type : 'VLCP'},
    {label : 'Leasehold Condominium Plan', value : 'LEASEHOLD_CONDOMINIUM_PLAN', type : 'LCP'}
    ],
    'condoPlanOptions_AB' : [
        {label : 'Conventional Condominium', value : 'CONVENTIONAL_CONDOMINIUM_PLAN', type : 'CP'},
        {label : 'Bare Land Condominium', value : 'BARE_LAND_PLAN', type : 'BLCP'},
    ]

};

export const condoPlanTypeDropDownOptions  = {
    'ON' : condoPlanTypeOptions.condoPlanOptions_Default,
    'NB' : condoPlanTypeOptions.condoPlanOptions_Default,
    'NS' : condoPlanTypeOptions.condoPlanOptions_Default,
    'AB' : condoPlanTypeOptions.condoPlanOptions_AB,
    'MB' : condoPlanTypeOptions.condoPlanOptions_Default,
    'SK' : condoPlanTypeOptions.condoPlanOptions_Default,
    'BC' : condoPlanTypeOptions.condoPlanOptions_Default,
};

export const  condoUnitTypeOptions = { 'condoUnitTypeOptions_Default' : [
        {label : '', value : ''},
        {label : 'Dwelling Unit', value : 'DWELLING_UNIT'},
        {label : 'Parking Space', value : 'PARKING_SPACE'},
        {label : 'Locker', value : 'LOCKER'},
        {label : 'Parking Unit', value : 'PARKING_UNIT'},
        {label : 'Storage Unit', value : 'STORAGE_UNIT'},
        {label : 'Parking + Storage', value : 'PARKING_PLUS_STORAGE'}
    ],
    'condoUnitTypeOptions_AB' : [
        {label : '', value : ''},
        {label : 'Dwelling Unit', value : 'DWELLING_UNIT'},
        {label : 'Parking Space', value : 'PARKING_SPACE'},
        {label : 'Locker', value : 'LOCKER'},
        {label : 'Parking Unit', value : 'PARKING_UNIT'},
        {label : 'Storage Unit', value : 'STORAGE_UNIT'},
        {label : 'Parking + Storage', value : 'PARKING_PLUS_STORAGE'},
        {label : 'Other', value : 'OTHER'}
    ],
    'condoUnitTypeOptions_SK' : [
        {label : '', value : ''},
        {label : 'Dwelling Unit', value : 'DWELLING_UNIT'},
        {label : 'Locker', value : 'LOCKER'},
        {label : 'Parking Unit', value : 'PARKING_UNIT'},
        {label : 'Storage Unit', value : 'STORAGE_UNIT'},
        {label : 'Parking + Storage', value : 'PARKING_PLUS_STORAGE'},
        {label : 'Other', value : 'OTHER'}
    ]

};

export const condoUnitTypeDropDownOptions  = {
    'ON' : condoUnitTypeOptions.condoUnitTypeOptions_Default,
    'NB' : condoUnitTypeOptions.condoUnitTypeOptions_Default,
    'NS' : condoUnitTypeOptions.condoUnitTypeOptions_Default,
    'AB' : condoUnitTypeOptions.condoUnitTypeOptions_AB,
    'MB' : condoUnitTypeOptions.condoUnitTypeOptions_Default,
    'SK' : condoUnitTypeOptions.condoUnitTypeOptions_SK,
    'BC' : condoUnitTypeOptions.condoUnitTypeOptions_Default,
};

export const  AmountAdditionalConsiderationToVendorOptions = {
    amountAdditionalConsiderationToVendorOptionsDefault(taxRateType) : any {
       return [
           {label: 'No', value: 'NO'},
           {label: `Yes (eligible for ${taxRateType} rebate)`, value: 'YES_ELIGIBLE_FOR_REBATE'},
           {label: `Yes (not eligible for ${taxRateType} rebate)`, value: 'YES_NOT_ELIGIBLE_FOR_REBATE'},
           {label: `Yes (not eligible for ${taxRateType} rebate - incl. ${taxRateType})`, value: 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX'}
       ];
    },
    amountAdditionalConsiderationToVendorOptionsAB(taxRateType) : any {
        return [
            {label: 'No', value: 'NO'},
            {label: `Yes (eligible for ${taxRateType} rebate)`, value: 'YES_ELIGIBLE_FOR_REBATE'},
            {label: `Yes (not eligible for ${taxRateType} rebate)`, value: 'YES_NOT_ELIGIBLE_FOR_REBATE'},
        ];
    }
};

export const amountAdditionalConsiderationToVendorDropDownOptions  = {
    getDropDownOptions(taxRateType: string, provinceCode: string) : any {
        if(['AB','SK','MB', 'NB', 'NS'].indexOf(provinceCode) > -1) {
            return AmountAdditionalConsiderationToVendorOptions.amountAdditionalConsiderationToVendorOptionsAB(taxRateType);
        }
        return AmountAdditionalConsiderationToVendorOptions.amountAdditionalConsiderationToVendorOptionsDefault(taxRateType);
    }
};

export const calculationMethodTypeForPrePaidTenancy = {
    calculationMethodType_ON: [
        {label: "Ontario Residential Tenancies Act, 2006", value: "ONTARIO_RESIDENTIAL_TENANCIES_ACT_2006"},
        {label: "Flat Interest rate", value: "FLAT_INTEREST_RATE"}
    ],
    calculationMethodType_AB : [
        {label: "Use Interest Table", value: "USE_INTEREST_TABLE"},
        {label: "Flat Interest rate", value: "FLAT_INTEREST_RATE"}   ]
};

export const calculationMethodTypeForPrePaidTenancyOptions  = {
    'ON' : calculationMethodTypeForPrePaidTenancy.calculationMethodType_ON,
    'NB' : calculationMethodTypeForPrePaidTenancy.calculationMethodType_AB,
    'NS' : calculationMethodTypeForPrePaidTenancy.calculationMethodType_AB,
    'AB' : calculationMethodTypeForPrePaidTenancy.calculationMethodType_AB,
    'MB' : calculationMethodTypeForPrePaidTenancy.calculationMethodType_AB,
    'SK' : calculationMethodTypeForPrePaidTenancy.calculationMethodType_AB,
    'BC' : calculationMethodTypeForPrePaidTenancy.calculationMethodType_ON
};

export const condoDocuments = {
    condoDocumentsAB : [
        {label : 'By-Laws', value : 'BY-LAWS'},
        {label : 'Latest Financial Statement', value : 'LATEST_FINANCIAL_STATEMENT'},
        {label : 'Certificate of Insurance', value : 'CERTIFICATE_OF_INSURANCE'},
        {label : 'Information Statement', value : 'INFORMATION_STATEMENT'},
        {label : 'Current Estoppel Certificate', value : 'CURRENT_ESTOPPEL_CERTIFICATE'}
    ],
    condoDocumentsMB : [
        {label : 'Status Certificate', value : 'STATUS_CERTIFICATE'},
        {label : 'Certificate of Insurance', value : 'CERTIFICATE_OF_INSURANCE'},
        {label : 'Addition of the Mortgagee to the Register', value : 'ADDITION_MORTGAGEE_REGISTER'}
    ],
    condoDocumentsSK : [
        {label : 'By-Laws', value : 'BY-LAWS'},
        {label : 'Financial Statement(s)', value : 'FINANCIAL_STATEMENTS'},
        {label : 'Certificate of Insurance', value : 'CERTIFICATE_OF_INSURANCE'},
        {label : 'Information Statement', value : 'INFORMATION_STATEMENT'},
        {label : 'Current Estoppel Certificate', value : 'CURRENT_ESTOPPEL_CERTIFICATE'}
    ],
    condoDocumentsBC : [
        {label : 'Form B - Information Certificate', value : 'B_INFORMATION_CERTIFICATE'},
        {label : 'Rules', value : 'RULES'},
        {label : 'Form F - Certificate of Payment', value : 'F_PAYMENT_CERTIFICATE'},
        {label : 'Warranty', value : 'WARRANTY'},
        {label : 'Depreciation Report', value : 'DEPRECATION_REPORT'},
        {label : 'Bylaws', value : 'BY-LAWS'},
        {label : 'Financial Statements (most recent)', value : 'LATEST_FINANCIAL_STATEMENT'},
        {label : 'Remediation/Building Envelope Reports or Summary', value : 'BUILDING_ENVELOPE_REPORTS'},
        {label : 'Annual General Meeting Minutes', value : 'AGM_MINUTES'},
        {label : 'Strata Council Meeting Minutes', value : 'SCM_MINUTES'},
        {label : 'Special General Meeting Minutes', value : 'SGM_MINUTES'},
        {label : 'Form J - Rental Disclosure Statement', value : 'J_RENTAL_DISCLOSURE'},
        {label : 'Plan', value : 'PLAN'},
        {label : 'Form B - without required Documents', value : 'B_WITHOUT_REQUIRED_DOCS'},
        {label : 'Insurance Document', value : 'CERTIFICATE_OF_INSURANCE'},
    ],
};


export const condoCorpDocuments = {
    'AB' : condoDocuments.condoDocumentsAB,
    'MB' : condoDocuments.condoDocumentsMB,
    'SK' : condoDocuments.condoDocumentsSK,
    'BC' : condoDocuments.condoDocumentsBC,
};

export const actingForOptions = {
    actingForOptionsDefault: {
        'DISCHARGE': [
            {label: '', value: ActingForValues.QUESTION},
            {label: 'Mortgagor only', value: ActingForValues.MORTGAGOR},
            {label: 'Mortgagee only', value: ActingForValues.MORTGAGEE},
            {label: 'Both (primary client is mortgagor)', value: ActingForValues.BOTH_MORTGAGOR_PRIMARY},
            {label: 'Both (primary client is mortgagee)', value: ActingForValues.BOTH_MORTGAGEE_PRIMARY}
        ],
        'MORTGAGE': [
            {label: '', value: ActingForValues.QUESTION},
            {label: 'Mortgagor only', value: ActingForValues.MORTGAGOR},
            {label: 'Mortgagee only', value: ActingForValues.MORTGAGEE},
            {label: 'Both (primary client is mortgagor)', value: ActingForValues.BOTH_MORTGAGOR_PRIMARY},
            {label: 'Both (primary client is mortgagee)', value: ActingForValues.BOTH_MORTGAGEE_PRIMARY}
        ],
        'PURCHASE': [
            {label: 'Purchaser only', value: ActingForValues.PURCHASER_ONLY},
            {label: 'Purchaser and vendor', value: ActingForValues.PURCHASER_VENDOR}
        ],
        //DWH
        'SALE': [
            {label: 'Vendor only', value: ActingForValues.VENDOR_ONLY},
            {label: 'Vendor and purchaser', value: ActingForValues.VENDOR_PURCHASER}
        ],
        'PROJECT_SALE': [
            {label: 'Vendor only', value: ActingForValues.VENDOR_ONLY},
            {label: 'Vendor and mortgagee', value: ActingForValues.VENDOR_MORTGAGEE},
            {label: 'Vendor and purchaser', value: ActingForValues.VENDOR_PURCHASER},
            {label: 'Vendor, purchaser and mortgagee', value: ActingForValues.VENDOR_PURCHASER_MORTGAGEE}
        ],
    },

    actingForOptionsBC: {
        'DISCHARGE': [
            {label: '', value: ActingForValues.QUESTION},
            {label: 'Mortgagor only', value: ActingForValues.MORTGAGOR},
            {label: 'Mortgagee only', value: ActingForValues.MORTGAGEE},
            {label: 'Both (primary client is mortgagor)', value: ActingForValues.BOTH_MORTGAGOR_PRIMARY},
            {label: 'Both (primary client is mortgagee)', value: ActingForValues.BOTH_MORTGAGEE_PRIMARY}
        ],
        'MORTGAGE': [
            {label: '', value: ActingForValues.QUESTION},
            {label: 'Mortgagor only', value: ActingForValues.MORTGAGOR},
            {label: 'Mortgagee only', value: ActingForValues.MORTGAGEE},
            {label: 'Both (primary client is mortgagor)', value: ActingForValues.BOTH_MORTGAGOR_PRIMARY},
            {label: 'Both (primary client is mortgagee)', value: ActingForValues.BOTH_MORTGAGEE_PRIMARY}
        ],
        'PURCHASE': [
            {label: 'Buyer only', value: ActingForValues.PURCHASER_ONLY},
            {label: 'Buyer and Seller', value: ActingForValues.PURCHASER_VENDOR}
        ],
        'SALE': [
            {label: 'Seller only', value: ActingForValues.VENDOR_ONLY},
            {label: 'Seller and Buyer', value: ActingForValues.VENDOR_PURCHASER}
        ],
        'PROJECT_SALE': [
            {label: 'Vendor only', value: ActingForValues.VENDOR_ONLY},
            {label: 'Vendor and mortgagee', value: ActingForValues.VENDOR_MORTGAGEE},
            {label: 'Vendor and purchaser', value: ActingForValues.VENDOR_PURCHASER},
            {label: 'Vendor, purchaser and mortgagee', value: ActingForValues.VENDOR_PURCHASER_MORTGAGEE}
        ],
    }
}

export const otherHoldbackTypeOptions = {
    otherHoldbackTypeOptionsDefault : [
        {label : '', value : null},
        {label : "Builders' Lien", value : "Builders' Lien"},
        {label : 'Fence', value : 'Fence'},
        {label : 'Holdback for improvement', value : 'Holdback for improvement'},
        {label : 'Landscaping', value : 'Landscaping'},
        {label : 'Real Property Report', value : 'Real Property Report'},
        {label : 'Rough grade', value : 'Rough grade'},
        {label : 'Seasonal', value : 'Seasonal'},
    ],

    otherHoldbackTypeOptionsON : [
        {label : '', value : null},
        {label : 'Construction Lien', value : 'Construction Lien'},
        {label : 'Fence', value : 'Fence'},
        {label : 'Holdback for improvement', value : 'Holdback for improvement'},
        {label : 'Landscaping', value : 'Landscaping'},
        {label : 'Rough grade', value : 'Rough grade'},
        {label : 'Seasonal', value : 'Seasonal'},
    ],

    otherHoldbackTypeOptionsBC: [
        {label: '', value: null},
        {label: "Builders' Lien", value: "Builders' Lien"},
        {label: 'Deficiency', value: 'Deficiency'},
        {label: 'Fence', value: 'Fence'},
        {label: 'Holdback for improvement', value: 'Holdback for improvement'},
        {label: 'Landscaping', value: 'Landscaping'},
        {label: 'Rough grade', value: 'Rough grade'},
        {label: 'Seasonal', value: 'Seasonal'},
    ],

    otherHoldbackTypeOptionsNB : [
        {label : '', value : null},
        {label : 'Fence', value : 'Fence'},
        {label : 'Holdback for improvement', value : 'Holdback for improvement'},
        {label : 'Landscaping', value : 'Landscaping'},
        {label : "Mechanics' Lien", value : "Mechanics' Lien"},
        {label : 'Real Property Report', value : 'Real Property Report'},
        {label : 'Rough grade', value : 'Rough grade'},
        {label : 'Seasonal', value : 'Seasonal'},

    ]
};

export const otherHoldbackTypes = {
    'ON' : otherHoldbackTypeOptions.otherHoldbackTypeOptionsON,
    'NB' : otherHoldbackTypeOptions.otherHoldbackTypeOptionsNB,
    'NS' : otherHoldbackTypeOptions.otherHoldbackTypeOptionsDefault,
    'AB' : otherHoldbackTypeOptions.otherHoldbackTypeOptionsDefault,
    'MB' : otherHoldbackTypeOptions.otherHoldbackTypeOptionsDefault,
    'SK' : otherHoldbackTypeOptions.otherHoldbackTypeOptionsDefault,
    'BC': otherHoldbackTypeOptions.otherHoldbackTypeOptionsBC,
};

export const  prepaymentPrivilegeOptions = {
    prepaymentPrivilegeDefault: [
        {label: ' ', value: 'QUESTION'},
        {label: 'Page No. _____ of the Standard Charge Terms', value: 'PAGE_NO_OF_THE_STANDARD_CHARGE_TERMS'},
        {label: 'Page No. _____ of the Mortgage Document', value: 'PAGE_NO_OF_THE_MORTGAGE_DOCUMENT'},
        {label: 'Section No. _____ of the Standard Charge Terms', value: 'SECTION_NO_OF_STANDARD_CHARGE_TERMS'},
        {label: 'Section No. _____ of the Mortgage Document', value: 'SECTION_NO_OF_MORTGAGE_DOCUMENT'},
        {label: 'Box No. _____ of the Mortgage Document', value: 'BOX_NO_OF_MORTGAGE_DOCUMENT'},
        {label: 'Not Applicable', value: 'NOT_APPLICABLE'}

    ],

    prepaymentPrivilegeForAB: [
        {label: ' ', value: 'QUESTION'},
        {label: 'Page No. _____ of the Standard Charge Terms', value: 'PAGE_NO_OF_THE_STANDARD_CHARGE_TERMS'},
        {label: 'Page No. _____ of the Mortgage Document', value: 'PAGE_NO_OF_THE_MORTGAGE_DOCUMENT'},
        {label: 'Page No. _____ of Cost of Borrowing or Disclosure Statement', value: 'PAGE_NO_OF_COST_OF_BORROWING_OR_DISCLOSURE_STATEMENT'},
        {label: 'Section No. _____ of the Standard Charge Terms', value: 'SECTION_NO_OF_STANDARD_CHARGE_TERMS'},
        {label: 'Section No. _____ of the Mortgage Document', value: 'SECTION_NO_OF_MORTGAGE_DOCUMENT'},
        {label: 'Box No. _____ of the Mortgage Document', value: 'BOX_NO_OF_MORTGAGE_DOCUMENT'},
        {label: 'Not Applicable', value: 'NOT_APPLICABLE'}

    ],
};

export const dischargeRegisteredByOptions = {
    dischargeRegisteredByOptionsDefault: [
        {
            label: "",
            value: ""
        }, {
            label: "mortgagee",
            value: dischargeRegisteredByType.MORTGAGEE
        }, {
            label: "our firm",
            value: dischargeRegisteredByType.OUR_FIRM

}
    ],

    dischargeRegisteredByOptionsBC: [
        {
            label: "lender",
            value: dischargeRegisteredByType.MORTGAGEE
        },
        {
            label: "our firm",
            value: dischargeRegisteredByType.OUR_FIRM
        }
    ],
};

export const correspondenceOptions = {
    correspondenceOptionsDefault: [
        {
            label: "",
            value: ""
        }, {
            label: "Mortgagee",
            value: "MORTGAGEE"
        }, {
            label: "Solicitor",
            value: "SOLICITOR"
        }
    ],

    correspondenceOptionsBC: [
        {
            label: "",
            value: ""
        }, {
            label: "Registered Owner",
            value: "MORTGAGEE"
        }, {
            label: "Lawyer/Notary",
            value: "SOLICITOR"
        }
    ]
};

export const  correspondence = {
    'AB' : correspondenceOptions.correspondenceOptionsDefault,
    'NB' : correspondenceOptions.correspondenceOptionsDefault,
    'NS' : correspondenceOptions.correspondenceOptionsDefault,
    'ON' : correspondenceOptions.correspondenceOptionsDefault,
    'MB' : correspondenceOptions.correspondenceOptionsDefault,
    'SK' : correspondenceOptions.correspondenceOptionsDefault,
    'BC' : correspondenceOptions.correspondenceOptionsBC,
};

export const  dischargeRegisteredBy = {
    'AB' : dischargeRegisteredByOptions.dischargeRegisteredByOptionsDefault,
    'NB' : dischargeRegisteredByOptions.dischargeRegisteredByOptionsDefault,
    'NS' : dischargeRegisteredByOptions.dischargeRegisteredByOptionsDefault,
    'ON' : dischargeRegisteredByOptions.dischargeRegisteredByOptionsDefault,
    'MB' : dischargeRegisteredByOptions.dischargeRegisteredByOptionsDefault,
    'SK' : dischargeRegisteredByOptions.dischargeRegisteredByOptionsDefault,
    'BC' : dischargeRegisteredByOptions.dischargeRegisteredByOptionsBC,
};

export const mortgageeTypeOptions = {
    mortgageeTypeOptionsDefault: [
        {
            label: "",
            value: ""
        }, {
            label: "Institution",
            value: "INSTITUTION"
        }, {
            label: "Private Lender",
            value: "PRIVATE_LENDER"
        }
    ],

    mortgageeTypeOptionsBC: [
        {
            label: "",
            value: ""
        }, {
            label: "Institutional Lender",
            value: "INSTITUTION"
        }, {
            label: "Other",
            value: "PRIVATE_LENDER"
        }
    ]
};

export const  mortgageeType = {
    'AB' : mortgageeTypeOptions.mortgageeTypeOptionsDefault,
    'NB' : mortgageeTypeOptions.mortgageeTypeOptionsDefault,
    'NS' : mortgageeTypeOptions.mortgageeTypeOptionsDefault,
    'ON' : mortgageeTypeOptions.mortgageeTypeOptionsDefault,
    'MB' : mortgageeTypeOptions.mortgageeTypeOptionsDefault,
    'SK' : mortgageeTypeOptions.mortgageeTypeOptionsDefault,
    'BC' : mortgageeTypeOptions.mortgageeTypeOptionsBC,
};

export const  prepaymentPrivilege = {
    'AB' : prepaymentPrivilegeOptions.prepaymentPrivilegeForAB,
    'NB' : prepaymentPrivilegeOptions.prepaymentPrivilegeDefault,
    'NS' : prepaymentPrivilegeOptions.prepaymentPrivilegeDefault,
    'ON' : prepaymentPrivilegeOptions.prepaymentPrivilegeDefault,
    'MB' : prepaymentPrivilegeOptions.prepaymentPrivilegeDefault,
    'SK' : prepaymentPrivilegeOptions.prepaymentPrivilegeDefault,
    'BC' : prepaymentPrivilegeOptions.prepaymentPrivilegeDefault
};

export const vendorWarrantyOptions = {
    'ON' : dropDowns.vendorWarrantyOptions,
    'NB' : dropDowns.vendorWarrantyOptions,
    'NS' : dropDowns.vendorWarrantyOptionsNS,
    'AB' : dropDowns.vendorWarrantyOptions,
    'MB' : dropDowns.vendorWarrantyOptions,
    'SK' : dropDowns.vendorWarrantyOptions,
    'BC' : dropDowns.vendorWarrantyOptions
};

export const actingForDropdownOptions = {
    'ON' : actingForOptions.actingForOptionsDefault,
    'NB' : actingForOptions.actingForOptionsDefault,
    'NS' : actingForOptions.actingForOptionsDefault,
    'AB' : actingForOptions.actingForOptionsDefault,
    'MB' : actingForOptions.actingForOptionsDefault,
    'SK' : actingForOptions.actingForOptionsDefault,
    'BC' : actingForOptions.actingForOptionsBC
};

export const purchaserCapacityDropdownOptions = {
    purchaserCapacityDropdownOptionsDefault: [
        {value: '', label: ''},
        {value: 'UNSPECIFIED_CAPACITY', label: 'Silent (capacity not to be specified)'},
        {value: 'ESTATE_WITH_WILL', label: 'Estate Trustee with a Will'},
        {value: 'ESTATE_WITHOUT_WILL', label: 'Estate Trustee without a Will'},
        {value: 'ESTATE_DURING_LITIGATION', label: 'Estate Trustee During Litigation'},
        {value: 'FIRM_NAME', label: 'Firm Name'},
        {value: 'GENERAL_PARTNER', label: 'General Partner'},
        {value: 'JOINT_ACCOUNT', label: 'Joint Account'},
        {value: 'JOINT_ACCOUNT_SURVIVORSHIP', label: 'Joint Account, Right of Survivorship'},
        {value: 'JOINT_TENANTS', label: 'Joint Tenants'},
        {value: 'LIFE_ESTATE', label: 'Life Estate'},
        {value: 'OFFICIAL_GUARDIAN', label: 'Official Guardian'},
        {value: 'PARTNER', label: 'Partner'},
        {value: 'PUBLIC_GUARDIAN_AND_TRUSTEE', label: 'Public Guardian and Trustee'},
        {value: 'REMAINDER', label: 'Remainder'},
        {value: 'REGISTERED_OWNER', label: 'Registered Owner'},
        {value: 'TRUSTEE_IN_BANKRUPTCY', label: 'Trustee in Bankruptcy'},
        {value: 'TENANTS_IN_COMMON', label: 'Tenants in Common'},
        {value: 'TRUSTEE', label: 'Trustee'}
    ],

    purchaserCapacityDropdownOptionsBC: [
        {value: 'UNSPECIFIED_CAPACITY', label: 'Silent (Tenancy not to be specified)'},
        {value: 'JOINT_TENANTS', label: 'As joint tenants'},
    ]
};

export const purchaserCapacityDropdown = {
    'ON' : purchaserCapacityDropdownOptions.purchaserCapacityDropdownOptionsDefault,
    'NB' : purchaserCapacityDropdownOptions.purchaserCapacityDropdownOptionsDefault,
    'NS' : purchaserCapacityDropdownOptions.purchaserCapacityDropdownOptionsDefault,
    'AB' : purchaserCapacityDropdownOptions.purchaserCapacityDropdownOptionsDefault,
    'MB' : purchaserCapacityDropdownOptions.purchaserCapacityDropdownOptionsDefault,
    'SK' : purchaserCapacityDropdownOptions.purchaserCapacityDropdownOptionsDefault,
    'BC' : purchaserCapacityDropdownOptions.purchaserCapacityDropdownOptionsBC,
}

export const depositHeldByDropdownOptions = {
    depositHeldByDropdownOptionsDefault: [
        {label : 'Vendor\'s broker', value : constValues.depositHeldBy.VENDOR_BROKER},
        {label : 'Purchaser\'s broker',  value : constValues.depositHeldBy.PURCHASER_BROKER},
        {label : 'Vendor\'s lawyer',  value : constValues.depositHeldBy.VENDOR_LAWYER},
        {label : 'Purchaser\'s lawyer',  value : constValues.depositHeldBy.PURCHASER_LAWYER},
        {label : 'Builder',  value : constValues.depositHeldBy.BUILDER},
        {label : 'Developer',  value : constValues.depositHeldBy.DEVELOPER}
    ],
    depositHeldByDropdownOptionsBC: [
        {label: 'Listing Broker', value: constValues.depositHeldBy.VENDOR_BROKER},
        {label: 'Selling Broker', value: constValues.depositHeldBy.PURCHASER_BROKER},
        {label: 'Seller\'s Lawyer/Notary', value: constValues.depositHeldBy.VENDOR_LAWYER},
        {label: 'Buyer\'s Lawyer/Notary', value: constValues.depositHeldBy.PURCHASER_LAWYER},
        {label: 'Builder', value: constValues.depositHeldBy.BUILDER},
        {label: 'Developer', value: constValues.depositHeldBy.DEVELOPER}
    ],
};

export const depositHeldByDropdown = {
    'ON' : depositHeldByDropdownOptions.depositHeldByDropdownOptionsDefault,
    'NB' : depositHeldByDropdownOptions.depositHeldByDropdownOptionsDefault,
    'NS' : depositHeldByDropdownOptions.depositHeldByDropdownOptionsDefault,
    'AB' : depositHeldByDropdownOptions.depositHeldByDropdownOptionsDefault,
    'MB' : depositHeldByDropdownOptions.depositHeldByDropdownOptionsDefault,
    'SK' : depositHeldByDropdownOptions.depositHeldByDropdownOptionsDefault,
    'BC' : depositHeldByDropdownOptions.depositHeldByDropdownOptionsBC,
};

export const commissionPaidToDropdownOptions = {
    commissionPaidToDropdownOptionsDefault: [
        {label : 'Vendor\'s broker only', value : constValues.commissionPaidTo.VENDOR_BROKER_ONLY},
        {label : 'Both Vendor\'s and Purchaser\'s brokers', value : constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER},
    ],

    commissionPaidToDropdownOptionsBC: [
        {label : 'Listing Broker', value : constValues.commissionPaidTo.VENDOR_BROKER_ONLY},
        {label : 'Selling Broker', value : constValues.commissionPaidTo.PURCHASER_BROKER_ONLY},
        {label : 'Both Listing and Selling Brokers', value : constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER},
    ],
}

export const commissionPaidToDropdown = {
    'ON' : commissionPaidToDropdownOptions.commissionPaidToDropdownOptionsDefault,
    'NB' : commissionPaidToDropdownOptions.commissionPaidToDropdownOptionsDefault,
    'NS' : commissionPaidToDropdownOptions.commissionPaidToDropdownOptionsDefault,
    'AB' : commissionPaidToDropdownOptions.commissionPaidToDropdownOptionsDefault,
    'MB' : commissionPaidToDropdownOptions.commissionPaidToDropdownOptionsDefault,
    'SK' : commissionPaidToDropdownOptions.commissionPaidToDropdownOptionsDefault,
    'BC' : commissionPaidToDropdownOptions.commissionPaidToDropdownOptionsBC,
};
