import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
               selector: '[fieldCode]'
           })
export class FieldCodeDirective {

    @Input('fieldCode') fieldCodeInput: string;

    constructor (private _elRef: ElementRef, private _renderer: Renderer2) {

    }

    ngOnInit() {
        this._renderer.setAttribute(this._elRef.nativeElement, 'fieldCode',this.fieldCodeInput);
    }

}
