import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {CustomPickListService} from './custom-pick-list.service';
import {Utils} from '../../matters/shared';
import * as _ from 'lodash';
import {Logger} from '@nsalaun/ng-logger';
import {customPickListKey} from './custom-pick-list-key';
import {DialogService} from '../dialog/dialog.service';
import {SpecialComment} from './special-comment';
import {SpecialCommentToDo} from './special-comment-to-do';

declare var jQuery : any;
class CommentModalContext extends BSModalContext {
    pickListType: string;
    referralByComments: SpecialComment[];
}

@Component({
    selector: 'dp-custom-pick-list-modal',
    templateUrl: './custom-pick-list-modal.component.html',
    styleUrls: [
        './custom-pick-list.styles.scss'
    ]
})
export class CustomPickListModalComponent implements OnInit, OnDestroy,   ModalComponent<CommentModalContext> {
    @Output() onComplete = new EventEmitter();
    @Output() onSelect = new EventEmitter();
    @ViewChild('scrollable') public scrollable: ElementRef;
    @ViewChild('newComment') public newComment: ElementRef;

    pickListType: string;
    specialComments: SpecialComment[];
    currentComment: SpecialComment;
    specialCommentsToDo: SpecialCommentToDo[] = [];
    currentCommentToDo: SpecialCommentToDo;
    isSelectDisabled: boolean = true;
    isMoveUpDisabled: boolean = true;
    isMoveDownDisabled: boolean = true;
    isEditDisabled: boolean = true;
    isDeleteDisabled: boolean = true;
    preventUpdate: boolean = false;
    preventEscape: boolean = false;
    newCommentText: string = '';
    header: string = '';
    utils: any;
    context : CommentModalContext;

    constructor(
        public dialog : DialogRef<CommentModalContext>,

        public customPickListService: CustomPickListService,
        public dialogService: DialogService,
        public logger: Logger) {

        this.context = dialog.context;

        //

        this.pickListType = this.context.pickListType;
        this.utils = new Utils();
    }

    ngOnInit(): void {
        if (this.pickListType === customPickListKey.FeeQuotes) {
            this.header = 'Edit Fee Quotes';
        } else if(this.pickListType === customPickListKey.SpecialComments) {
            this.header = 'Edit Special Comments';
        } else if(this.pickListType === customPickListKey.ReferredBy) {
            this.header = 'Edit Referred By';
        } else if(this.pickListType === customPickListKey.IdentificationDocumentType) {
            this.header = 'Edit ID Type';
        } else if(this.pickListType === customPickListKey.MortgageAdvanceList) {
            this.header = 'Mortgage Advance List';
        } else if(this.pickListType === customPickListKey.StatusCertificatePosition) {
            this.header = 'Edit Position List';
        } else if(this.pickListType === customPickListKey.OtherCategoryName) {
            this.header = 'Edit Other Category List';
        } else if(this.pickListType === customPickListKey.PlaintiffList) {
            this.header = 'Edit Plaintiff List';
        }

        setTimeout(() => {
            this.newComment && this.newComment.nativeElement.focus();
            setTimeout(() => {
                this.newComment && this.newComment.nativeElement.focus();
            }, 100);
        }, 100);

        this.initializeCustomPickList();
    }

    ngOnDestroy():void {
    }

    /** First method which is gets called on click of Show comments button -after constructor */
    initializeCustomPickList(): void {
        this.specialComments = [];
        this.currentComment = undefined;
        this.specialCommentsToDo = [];
        this.currentCommentToDo = undefined;
        this.newCommentText = '';

        if(this.pickListType === customPickListKey.ReferredBy){
            this.specialComments = this.context.referralByComments;
            this.getCustomPickList(this.specialComments);
        } else {
            // Get custom pick list items
            this.customPickListService.getCustomPickList(this.pickListType)
                .subscribe((data: SpecialComment[]) => {
                    this.getCustomPickList(data);
                });
        }
    }

    getCustomPickList(data){
        if(data) {
            this.specialComments = data;
            let sorted: any[] = [];
            sorted = _.sortBy(data, ['customPickListItemSequence']);

            sorted.forEach((comment: SpecialComment) => {
                let commentToDo: SpecialCommentToDo = new SpecialCommentToDo(comment);
                this.specialCommentsToDo.push(commentToDo);
            });

            this.checkDisabilities();
            //this.displayDialog = true;
        }
    }

    trackByFunc(index, item): any {
        return item['id'];
    }

    specialCommentToDoConvertSpecialComment(){
        let specialComments: SpecialComment[] = [];
        this.specialCommentsToDo.forEach((commentToDo: SpecialCommentToDo) => {
            let comment: SpecialComment = new SpecialComment();
            comment.id = commentToDo.id;
            comment.customPickListItemSequence =  commentToDo.customPickListItemSequence;
            comment.customPickListItemValue =  commentToDo.customPickListItemValue;
            comment.customPickListTypeCode =  commentToDo.customPickListTypeCode;
            comment.id = commentToDo.id;
            comment.defaultType = commentToDo.defaultType;
            specialComments.push(comment);
        });
        return specialComments;
    }

    /** Normally close modal by selecting an item */
    closeDialog(): void{
        let currentComment: any = this.getComment();
        this.specialComments = [];
        this.specialCommentsToDo = [];
        this.dialog.close({
            comment: currentComment,
            action: "select",
            value: true,
            type: this.pickListType,
            refferedByDataOptions: this.pickListType === customPickListKey.ReferredBy ? this.specialCommentToDoConvertSpecialComment() : []
        });
    }

    /** Handle cancel button */
    cancelDialog(): void {
        this.dialog.close({
            action: "cancel",
            value: true,
            type: this.pickListType,
            refferedByDataOptions: this.pickListType === customPickListKey.ReferredBy ? this.specialCommentToDoConvertSpecialComment() : []
        });
    }

    /** Add new comment to list */
    addNewComment(commentValue: string): boolean {
        if (this.utils.isEmptyString(commentValue.trim())) {
            this.newCommentText = '';
            return true;
        }

        let newComment: SpecialComment = <SpecialComment>{};
        if (this.specialCommentsToDo.length > 0) {
            newComment.customPickListItemSequence =
                this.specialCommentsToDo[this.specialCommentsToDo.length - 1].customPickListItemSequence + 1;
        } else {
            newComment.customPickListItemSequence = 1;
        }
        newComment.customPickListItemValue = commentValue;
        newComment.customPickListTypeCode = this.pickListType;
        let specialCommentToDo: SpecialCommentToDo = new SpecialCommentToDo(newComment);

        this.customPickListService.addNewComment(newComment)
            .subscribe(res => {
                newComment.id = res['CUSTOMPICKLIST'].id;
                specialCommentToDo.id = newComment.id;
                this.specialCommentsToDo.push(specialCommentToDo);
                this.setCommentProperty(specialCommentToDo, 'completed', true);
                this.scrollToBottom();
                this.checkDisabilities();
                this.newCommentText = '';

                setTimeout(() => {
                    jQuery(".active").focus();
                }, 500);
            });
    }

    editComment(comment: SpecialCommentToDo): void {
        comment.editing = true;

        setTimeout(() => {
            this.setFocusToEditingTextBox();
        }, 100);
    }

    getComment(): SpecialCommentToDo {
        return this.specialCommentsToDo.find(item => item.completed === true);
    }

    updateComment(event: any, editedTitle: string, index: number, comment: SpecialCommentToDo): void {
        //console.log("blur");
        this.cancelEvent(event, true);

        if (this.utils.isEmptyString(editedTitle)) {
            return ;
        }

        comment.customPickListItemValue = editedTitle;
        let newComment: SpecialComment = this.copyCommentObject(comment);

        this.customPickListService.update(comment.id, newComment)
            .subscribe(res => {
                comment.editing = false;
                this.checkDisabilities();
                this.setCurrentFocus();
            });
    }

    moveUp(comment: SpecialCommentToDo): void {

        let currentIndex: number = this.getCurrentIndex(comment);
        this.bringFieldIntoVisibleArea(".todo-list li.active","MOVE_UP", currentIndex);
        if (currentIndex > 0) {
            let temp: any = this.specialCommentsToDo[currentIndex];
            let tempSeq1: number = this.specialCommentsToDo[currentIndex].customPickListItemSequence;
            let tempSeq2: number = this.specialCommentsToDo[currentIndex - 1].customPickListItemSequence;

            this.specialCommentsToDo[currentIndex] = this.specialCommentsToDo[currentIndex - 1];
            this.specialCommentsToDo[currentIndex - 1] = temp;
            this.specialCommentsToDo[currentIndex].customPickListItemSequence = tempSeq2;
            this.specialCommentsToDo[currentIndex - 1].customPickListItemSequence = tempSeq1;

            let specialComment1: SpecialComment = this.copyCommentObject(this.specialCommentsToDo[currentIndex - 1]);
            let specialComment2: SpecialComment = this.copyCommentObject(this.specialCommentsToDo[currentIndex]);
            specialComment1.customPickListItemSequence = currentIndex;
            specialComment2.customPickListItemSequence = currentIndex +1;

            this.customPickListService.update(specialComment1.id, specialComment1)
                .subscribe(res => {
                    this.checkDisabilities();
                });



            this.customPickListService.update(specialComment2.id, specialComment2)
                .subscribe(res => {
                    this.checkDisabilities();
                });


        }
    }

    moveDown(comment: SpecialCommentToDo): void {

        let currentIndex: number = this.getCurrentIndex(comment);
        this.bringFieldIntoVisibleArea(".todo-list li.active","MOVE_DOWN", currentIndex);
        let lastIndex = this.specialCommentsToDo.length - 1;

        if (currentIndex < lastIndex) {
            let temp: any = this.specialCommentsToDo[currentIndex];
            let tempSeq1: number = this.specialCommentsToDo[currentIndex].customPickListItemSequence;
            let tempSeq2: number = this.specialCommentsToDo[currentIndex + 1].customPickListItemSequence;

            this.specialCommentsToDo[currentIndex] = this.specialCommentsToDo[currentIndex + 1];
            this.specialCommentsToDo[currentIndex + 1] = temp;

            this.specialCommentsToDo[currentIndex].customPickListItemSequence = currentIndex + 1;
            this.specialCommentsToDo[currentIndex + 1].customPickListItemSequence = currentIndex + 2;

            let specialComment1: SpecialComment = this.copyCommentObject(this.specialCommentsToDo[currentIndex]);
            this.customPickListService.update(specialComment1.id, specialComment1)
                .subscribe(res => {
                    this.checkDisabilities();
                });

            let specialComment2: SpecialComment = this.copyCommentObject(this.specialCommentsToDo[currentIndex + 1]);
            this.customPickListService.update(specialComment2.id, specialComment2)
                .subscribe(res => {
                    this.checkDisabilities();
                });

        }
    }

    selectComment(): void {
        console.warn("  -- selectComment() | this.getComment() = ", this.getComment());
        this.closeDialog();
    }

    removeComment(comment: SpecialCommentToDo): void {

        let id: number = comment.id;
        this.dialogService.confirm('Confirmation', 'Do you wish to delete this item?', false, 'Delete').subscribe(res => {

            if(res == true) {
                this.customPickListService.delete(id).subscribe(
                    (data: SpecialComment) => {
                        this.deleteComment(id);

                        if (this.specialCommentsToDo.length > 0) {
                            this.specialCommentsToDo[0].completed = true;
                        }

                        this.scrollToTop();
                        this.checkDisabilities();
                    }
                );

            }


        });

    }

    cancelComment(event: any, comment: SpecialCommentToDo): void {
        if (event.keyCode === 27) { // For escape key
            this.preventEscape = true;
            this.cancelEvent(event, true);

            setTimeout(() => {
                comment.editing = false;
            }, 100);
        }
    }

    toggleCompletion(comment: SpecialCommentToDo): void {
        setTimeout(() => {
            for (let i = 0; i < this.specialCommentsToDo.length; i = i + 1) {
                this.specialCommentsToDo[i].completed = false;
            }
            comment.completed = !comment.completed;
            this.checkDisabilities();
        }, 100);
    }

    removeFocus(): void {
    }

    /** Keyup event handler */
    onSelectionChange(event: any, comment: SpecialCommentToDo): void {
        //console.warn("CustomPickListModalComponent | onSelectionChange | event = ", event);

        if (event.which === 13) { /* enter */
            this.selectComment();
        }
        if (event.which === 9) { /* tab */
            //stop tabbing through list items
            this.cancelEvent(event);

            if(event.shiftKey){
                // SHIFT+TAB : focus on the INPUT textbox
                jQuery(".todoapp .new-todo").focus();
            } else {
                //TAB : focus on the default button
                jQuery(".modal-footer .dp-btn").focus();
            }
        }

        if (event.which === 33) { /* page up */
            this.moveByPageUp();
        }

        if (event.which === 34) { /* page down */
            this.moveByPageDown();
        }

        if (event.which === 38) { /* up arrow */
            let currentIndex: number = this.getCurrentIndex(comment);

            if (currentIndex > 0) {
                let previousItem: SpecialCommentToDo = this.getPreviousItem(currentIndex);
                this.setCommentsToDefaults('completed', false);
                previousItem.completed = true;

                let listItem: any = this.getSibling(currentIndex, 'up');
                listItem.focus();
            }
        }

        if (event.which === 40) { /* down arrow */
            let currentIndex: number = this.getCurrentIndex(comment);

            if (currentIndex < this.specialCommentsToDo.length - 1) {
                let nextItem: SpecialCommentToDo = this.getNextItem(currentIndex);
                this.setCommentsToDefaults('completed', false);
                nextItem.completed = true;

                let listItem: any = this.getSibling(currentIndex, 'down');
                listItem.focus();
            }
        }
        if (event && event.which in {13: 1, 33: 1, 34: 1, 38: 1, 40: 1}) {
            this.cancelEvent(event, true);
        }
        this.checkDisabilities();
    }
    cancelEvent(event: any, immediate?: boolean){
        event.stopPropagation();
        event.preventDefault();
        if(immediate){
            event.stopImmediatePropagation();
        }
    }

    public moveByPageUp(): void {
        let element: any = this.scrollable.nativeElement;
        let {scrollTop, scrollHeight, clientHeight, itemHeight, itemsInPage} = this.getScrollParams();

        this.setCommentsToDefaults('completed', false);

        if (scrollTop > 0) {
            let itemsAboveTop: number = Math.floor(scrollTop / itemHeight);

            if (itemsAboveTop > itemsInPage) {
                this.specialCommentsToDo[itemsAboveTop - itemsInPage].completed = true;
                element.scrollTop = scrollTop - clientHeight;
            } else {
                this.specialCommentsToDo[0].completed = true;
                element.scrollTop = 0;
            }
        } else {
            this.specialCommentsToDo[0].completed = true;
        }

        this.checkDisabilities();
    }

    public moveByPageDown(): void {
        let element: any = this.scrollable.nativeElement;
        let {scrollTop, scrollHeight, clientHeight, itemHeight, itemsInPage} = this.getScrollParams();

        this.setCommentsToDefaults('completed', false);

        let itemsBelowBottom: number = Math.floor((scrollHeight - scrollTop - clientHeight) / itemHeight);
        let lastIndex: number = this.specialCommentsToDo.length - 1;

        let indexToMove = Math.floor(scrollTop / itemHeight) + itemsInPage;
        if (indexToMove  < lastIndex) {
            if (itemsBelowBottom >= itemsInPage) {
                this.specialCommentsToDo[indexToMove].completed = true;
                element.scrollTop = indexToMove * itemHeight;
            } else {
                this.specialCommentsToDo[lastIndex - itemsInPage + 1].completed = true;
                element.scrollTop = indexToMove * itemHeight;
            }
        } else {
            this.specialCommentsToDo[lastIndex].completed = true;
        }

        this.checkDisabilities();
    }

    public getScrollParams(): any {
        let element: any = this.scrollable.nativeElement;
        let scrollTop: number = element.scrollTop;
        let scrollHeight: number = element.scrollHeight;
        let clientHeight: number = element.clientHeight;
        let item: any = element.querySelector('li');
        let itemHeight: number = item.clientHeight;

        return {
            scrollTop: scrollTop,
            scrollHeight: scrollHeight,
            clientHeight: clientHeight,
            itemHeight: itemHeight,
            itemsInPage: Math.floor(clientHeight / itemHeight)
        };
    }

    public getPreviousItem(currentIndex: number): any {
        if (currentIndex > 0 && currentIndex <= this.specialCommentsToDo.length - 1) {
            return this.specialCommentsToDo[currentIndex - 1];
        } else {
            return this.specialCommentsToDo[currentIndex];
        }
    }

    public getNextItem(currentIndex: number): any {
        if (currentIndex >= 0 && currentIndex < this.specialCommentsToDo.length - 1) {
            return this.specialCommentsToDo[currentIndex + 1];
        } else {
            return this.specialCommentsToDo[currentIndex];
        }
    }

    public getCurrentIndex({id}: {id: number}): number {
        return _.findIndex(this.specialCommentsToDo, (item: SpecialCommentToDo) => item.id === id);
    }

    public setCurrentFocus() {
        let comment: SpecialCommentToDo = this.getComment();
        let index = this.getCurrentIndex(comment);
        let items: any[] = this.scrollable.nativeElement.querySelectorAll('li');
        if (items.length > index) {
            items[index].focus();
        }
    }

    public setFocusToEditingTextBox(): void {
        let textBox: HTMLInputElement =
            this.scrollable.nativeElement.querySelector('input.edit');

        if (textBox) {
            textBox.focus();
            let value: string = textBox.value;
            let length: number = value? value.length: 0;
            textBox.setSelectionRange(length, length);
        }
    }

    public getSibling(index: number, direction: 'up'| 'down'): any {
        let items: HTMLLIElement[] =
            this.scrollable.nativeElement.querySelectorAll('li');

        if (direction === 'up') {
            return items[index - 1];
        }

        if (direction === 'down') {
            return items[index + 1];
        }
    }

    public deleteComment(id: number): void {
        _.remove(this.specialCommentsToDo, {id: id});
    }

    public setCommentsToDefaults(field: string, value: boolean): void {
        for (let comment of this.specialCommentsToDo) {
            comment[field] = value;
        }
    }

    public setCommentProperty(comment: SpecialCommentToDo, field: string, value: boolean): void {
        this.setCommentsToDefaults(field, !value);
        comment[field] = value;
    }

    public scrollToTop(): void {
        setTimeout(() => {
            this.scrollable.nativeElement.scrollTop = 0;
        }, 100);
    }

    public scrollToBottom(): void {
        setTimeout(() => {
            this.scrollable.nativeElement.scrollTop = this.scrollable.nativeElement.scrollHeight;
        }, 100);
    }

    public copyCommentObject(comment: SpecialCommentToDo): SpecialComment {
        let specialComment: SpecialComment = <SpecialComment>{};
        specialComment.id = comment.id;
        specialComment.customPickListItemSequence = comment.customPickListItemSequence;
        specialComment.customPickListItemValue = comment.customPickListItemValue;
        specialComment.customPickListTypeCode = comment.customPickListTypeCode;

        return specialComment;
    }

    public hasEditing(): boolean {
        return this.specialCommentsToDo.some((item: SpecialCommentToDo) => item.editing === true);
    }

    public checkDisabilities(): void {
        setTimeout(() => {
            let currentComment: SpecialCommentToDo = this.getComment();
            let currentIndex: number = currentComment ? this.getCurrentIndex(currentComment): -1;
            let lastIndex: number = this.specialCommentsToDo.length - 1;

            if (currentIndex === 0) {
                this.isSelectDisabled = false;
                this.isMoveUpDisabled = true;
                this.isMoveDownDisabled = false;
                this.isEditDisabled = false;
                this.isDeleteDisabled = false;
            }

            if (currentIndex > 0 && currentIndex < lastIndex) {
                this.isSelectDisabled = false;
                this.isMoveUpDisabled = false;
                this.isMoveDownDisabled = false;
                this.isEditDisabled = false;
                this.isDeleteDisabled = false;
            }

            if (currentIndex === lastIndex) {
                this.isSelectDisabled = false;
                this.isMoveUpDisabled = false;
                this.isMoveDownDisabled = true;
                this.isEditDisabled = false;
                this.isDeleteDisabled = false;
            }

            if (this.hasEditing() || this.specialCommentsToDo.length === 0) {
                this.isSelectDisabled = true;
                this.isMoveUpDisabled = true;
                this.isMoveDownDisabled = true;
                this.isEditDisabled = true;
                this.isDeleteDisabled = true;
            }

            if (this.specialCommentsToDo.length === 1) {
                this.isSelectDisabled = false;
                this.isMoveUpDisabled = true;
                this.isMoveDownDisabled = true;
                this.isEditDisabled = false;
                this.isDeleteDisabled = false;
            }
        }, 100);
    }

    bringFieldIntoVisibleArea(target, action, index) {

        let listHeight  = jQuery("ul.todo-list").outerHeight(),
            listTop     = jQuery("ul.todo-list").offset().top,

            itemHeight  = jQuery("ul.todo-list li.active").outerHeight(),
            activeTop   = jQuery("ul.todo-list li.active").offset().top;

        if (action === "MOVE_DOWN") {
            let newIndex = index + 1;
            if(activeTop > listTop + listHeight - 3 * itemHeight || activeTop < listTop){
                jQuery("ul.todo-list").scrollTop(itemHeight * (newIndex - 5));
            }
        } else {
            let newIndex = index - 1;
            if (activeTop < listTop + 2 * itemHeight || activeTop > listTop + listHeight){
                jQuery("ul.todo-list").scrollTop(itemHeight * (newIndex - 1));
            }
        }
    }
}
