import {DocumentTemplateCategory} from './document-template-category';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Mortgage} from '../shared/mortgage';
import Utils from '../../shared-main/utils';
import {Matter} from '../shared/matter';
import * as _ from 'lodash';
import {Declaration} from '../executions-affidavits/declaration';
import {HOLDBACK_TYPE} from '../shared/advance-holdback/matter-holdback';

export const preProduceValidationFlags = {
    amortizationSschedule : 'AMORTIZATION_SCHEDULE',
    aHashConstruct : 'A_HASH_CONSTRUCT'
};

export class DocumentTemplate extends BaseEntity {

    docGenTemplateId: number;
    fileName: string;
    description: string;
    sharedWith?: string;
    //This field is added in model only for the frontend to keep mortgage number with multi-priority template
    mortgageIndex: number;
    affidavitIndex: number;
    holdbackIndex: number;
    documentTemplateCategories: DocumentTemplateCategory[];
    applicableMatterTypeCodes: string[];
    numberOfCategories: number;
    numberOfSupplementalTasks : number;
    updatedByUser: string; //Name of the user last updating this file, no need to pull the whole user info
    lastUpdatedTimeStamp: number;
    virusScanStatus : string;
    templateProductionClass : string;
    templateHasStopCodes: boolean;
    preProduceValidationFlags: string[];

    // Setting to true is for test only will be removed once the backend is integrated.
    //trustLedgerBalancedValidationRequired : boolean = true;
    trustLedgerBalancedValidationRequired : boolean;

    constructor(documentTemplate? : DocumentTemplate){
        super(documentTemplate);
        if (documentTemplate) {

            this.documentTemplateCategories = [];
            if(Array.isArray(documentTemplate.documentTemplateCategories)) {
                this.documentTemplateCategories = documentTemplate.documentTemplateCategories.map(cat=>{return new DocumentTemplateCategory(cat)});
            }
        }


    }

    // This function returns the template id concatenated with mortgage index if applies
    get templateIdWithMortgageOrAffidavitIndex(): string {
        if (this.mortgageIndex != null) {

            return this.docGenTemplateId + "-" + this.mortgageIndex;

        } else if (this.affidavitIndex != null) {

            // for affidavit index add an "A" suffix
            return this.docGenTemplateId + "-" + this.affidavitIndex + "-A";

        } if (this.holdbackIndex != null) {

            // for Holdback index add an "HB" suffix
            return this.docGenTemplateId + "-" + this.holdbackIndex + "-HB";

        } else {

            return String(this.docGenTemplateId);

        }
    }
    get isInfected() : boolean {
        return this.virusScanStatus=='INFECTED';
    }
    get isVirusScanPending() : boolean {
        return (this.virusScanStatus=='PENDING' || this.virusScanStatus=='IN_PROGRESS');
    }

    get isTemplateFundSum() : boolean {
        return  this.fileName && this.fileName.toUpperCase().indexOf('FUND') > -1 && this.fileName.toUpperCase().indexOf('SUM') > -1
            && this.trustLedgerBalancedValidationRequired && !DocumentTemplateUtil.hasValidationFlag(this, preProduceValidationFlags.aHashConstruct);
    }
}

export class DocumentTemplateFile extends BaseEntity {
    id : number;
    fileName: string;
    filePath: string;
    createdTimeStamp: string;
    type: string;
    documentTemplate: DocumentTemplate;
    include: boolean;
    accountFileFolderId: number;
    lastUpdatedTimeStamp: number;
    virusScanStatus: string;

    get isInfected() : boolean {

        return this.virusScanStatus=='INFECTED';
    }

    get isVirusScanPending() : boolean {
        return (this.virusScanStatus=='PENDING' || this.virusScanStatus=='IN_PROGRESS');
    }

    get lastUpdatedTimestampForSorting():string{
        return this.lastUpdatedTimeStamp.toString();
    }


    constructor(documentTemplateFile? : DocumentTemplateFile){
        super(documentTemplateFile);
        if (documentTemplateFile && documentTemplateFile.documentTemplate) {
            this.documentTemplate = new DocumentTemplate(documentTemplateFile.documentTemplate);
        }
    }

}

export class DocumentTemplateUtil {

    static readonly MULTI_PRIORITY_TEMPLATE_REGEX = new RegExp("(M)#", "i");
    static readonly MULTI_EXISTING_PRIORITY_TEMPLATE_REGEX = new RegExp("(X)#", "i");
    static readonly MULTI_AFFIDAVIT_PRIORITY_TEMPLATE_REGEX = new RegExp("excn.*(aff|ltr)#", "i");
    static readonly MULTI_JUDGEMENT_DECLARATION_TEMPLATE_REGEX = new RegExp("djdg#", "i");
    static readonly MULTI_ADVANCE_HOLDBACK_TEMPLATE_REGEX = new RegExp("(H)#", "i");
    static readonly MULTI_OTHER_HOLDBACK_TEMPLATE_REGEX = new RegExp("(O)#", "i");

    public static isMultiPriorityTemplate(templateName : string) : boolean {
        const match = templateName.match(this.MULTI_PRIORITY_TEMPLATE_REGEX);
        return match !== null && match.length > 0;
    }

    public static isMultiExistingPriorityTemplate(templateName : string) : boolean {
        const match = templateName.match(this.MULTI_EXISTING_PRIORITY_TEMPLATE_REGEX);
        return match !== null && match.length > 0;
    }

    public static isMultiAffidavitPriorityTemplate(templateName : string) : boolean {
        const match = templateName.match(this.MULTI_AFFIDAVIT_PRIORITY_TEMPLATE_REGEX);
        const matchJudgement = templateName.match(this.MULTI_JUDGEMENT_DECLARATION_TEMPLATE_REGEX);
        return (match !== null && match.length > 0) || (matchJudgement !== null && matchJudgement.length > 0);
    }

    public static isMultiAdvanceHoldbackTemplate(templateName : string) : boolean {
        const match = templateName.match(this.MULTI_ADVANCE_HOLDBACK_TEMPLATE_REGEX);
        return match !== null && match.length > 0;
    }

    public static isMultiOtherHoldbackTemplate(templateName : string) : boolean {
        const match = templateName.match(this.MULTI_OTHER_HOLDBACK_TEMPLATE_REGEX);
        return match !== null && match.length > 0;
    }

    /**
     * Returns an array containing unique priority numbers of all new mortgages of the selected matters. For example,
     * Matter A has new mortgages with priority 1st and 3rd, matter B has new Mortgage with priority 5th, the result
     * will be [1, 3, 5].
     *
     * @param matters
     */
    public static findNewMortgagePriorities(matters : Readonly<Matter>[]) : number[] {

        let priorities : number[][] = matters.map(matter => matter.mortgages
                                                                  .filter(mortgage => _.isFinite(mortgage.mortgagePriority))
                                                                  .map(mortgage => mortgage.mortgagePriority)
                                                                  .filter(priority => priority > 0)
        );

        return priorities.reduce((prev: number[], curr: number[]) => {
            return prev.concat(curr.filter(v => prev.indexOf(v) < 0));
        }, []).sort();
    }


    public static replicateMultiPriorityTemplatesForNewMortgage(matters : Readonly<Matter>[], template : Readonly<DocumentTemplate>) : DocumentTemplate[] {
        let priorityList: number[] = this.findNewMortgagePriorities(matters);
        return this.replicateMultiPriorityTemplateForNewMortgage(template, priorityList);
    }

    /**
     * @param template
     * @param priorityList
     */
    public static replicateMultiPriorityTemplateForNewMortgage(template : Readonly<DocumentTemplate>, priorityList : number[]) : DocumentTemplate[] {

        let multiPriorityTemplates : DocumentTemplate[] = [];

        if(template !== undefined && template !== null) {
            // ensure template is for existing mortgages
            const match = template.fileName.match(this.MULTI_PRIORITY_TEMPLATE_REGEX);
            if(match !== null && match.length > 0) {
                priorityList.forEach(p => {
                    let templateInstance : DocumentTemplate = new DocumentTemplate(template);
                    templateInstance.fileName = template.fileName.replace(match[0], match[1] + String(p));
                    templateInstance.mortgageIndex = p;

                    //Replace first # in template description with Mortgage number but in ordinal format
                    if(template.description) {
                        templateInstance.description = template.description.replace("#", Utils.getOrdinal(p));
                    }
                    multiPriorityTemplates.push(templateInstance);
                });
            }
        }
        return multiPriorityTemplates;
    }

    private static readonly EXISTING_MORTGAGES_SELECTOR = (m : Readonly<Matter>) : Mortgage[] => {
        return m ? m.existingMortgages : null;
    };

    private static readonly DECLARATIONS_SELECTOR = (m : Readonly<Matter>) : Declaration[] => {
        return m ? m.declarations : null;
    };

    /**
     * Find the max count of existing mortgages or declarations of all given matters.
     *
     * @param matters
     * @param dataSelector inject a function to be used to select the existing mortgages or declarations
     *        from given matters.
     */
    public static findMaxCountOfExistingMortgagesOrDeclarations(matters : Readonly<Matter>[], dataSelector : (m : Readonly<Matter>) => Mortgage[] | Declaration[]) : number | null {

        let max : number | null = null;
        if(_.isArray(matters) && matters.length > 0) {
            let counts = matters.map(m => dataSelector.apply(this, new Array(m)))
                                .map(coll => coll !== undefined && coll !== null ? coll.length : 0);
            max = Math.max(...counts);
        }
        return max;
    }

    public static replicateMultiPriorityTemplatesForExistingMortgages(matters : Readonly<Matter>[], template : Readonly<DocumentTemplate>) : DocumentTemplate[] {

        let max = this.findMaxCountOfExistingMortgagesOrDeclarations(matters, this.EXISTING_MORTGAGES_SELECTOR);
        return this.replicateMultiPriorityTemplateForExistingMortgages(template, max);
    }

    public static replicateMultiPriorityTemplateForExistingMortgages(template : Readonly<DocumentTemplate>, maxNum: number|null) : DocumentTemplate[] {

        let multiPriorityTemplates : DocumentTemplate[] = [];
        if (maxNum !== null && maxNum > 0) {
            // ensure template is for existing mortgages
            const match = template.fileName.match(this.MULTI_EXISTING_PRIORITY_TEMPLATE_REGEX);
            if(match !== null && match.length > 0) {

                for (let i=0; i<maxNum; i++) {

                    let templateInstance : DocumentTemplate = new DocumentTemplate(template);

                    //Replace # in matched characters with Existing Mortgage Number
                    templateInstance.fileName = template.fileName.replace(match[0], match[1] + String(i+1));
                    templateInstance.mortgageIndex = i;

                    //Replace first # in template description with Mortgage number but in ordinal format
                    if(template.description) {
                        templateInstance.description = template.description.replace("#", Utils.getOrdinal(i + 1));
                    }

                    multiPriorityTemplates.push(templateInstance);
                }
            }
        }
        return multiPriorityTemplates;
    }

    public static replicateAffidavitTemplatesBasedOnAffidavitCount(matters : Readonly<Matter>[], template : Readonly<DocumentTemplate>) : DocumentTemplate[] {

        let maxNum : number|null = this.findMaxCountOfExistingMortgagesOrDeclarations(matters, this.DECLARATIONS_SELECTOR);
        return this.replicateAffidavitTemplate(template, maxNum);
    }

    /**
     *
     * @param template
     * @param max
     */
    public static replicateAffidavitTemplate(template : Readonly<DocumentTemplate>, max: number|null) : DocumentTemplate[] {

        let templateInstances : DocumentTemplate[] = [];

        if (max !== null && max > 0) {

            const match = template.fileName.match(this.MULTI_AFFIDAVIT_PRIORITY_TEMPLATE_REGEX);
            if(match !== null && match.length > 0) {

                for(let i=0; i< max; i++) {

                    let templateInstance = new DocumentTemplate(template);

                    // Replace # in matched characters with Affidavit Number
                    templateInstance.fileName = template.fileName.replace("#", "" + String(i + 1));
                    templateInstance.affidavitIndex = i;

                    //Replace first # in template description with Mortgage number but in ordinal format
                    if(template.description) {
                        templateInstance.description = template.description.replace("#", Utils.getOrdinal(i + 1));
                    }

                    templateInstances.push(templateInstance);
                }
            }
        }
        return templateInstances;
    }

    public static findMaxCountOfHoldbacks(matters : Readonly<Matter>[], holdbackType: string) : number {
        let maxNum = 0;
        if(matters && matters.length){
            matters.forEach((matter: Matter)=> {
                let holdbacks = matter.holdbacks.filter(hb => hb.holdbackType == holdbackType);
                if (holdbacks && holdbacks.length && maxNum < holdbacks.length) {
                    maxNum = holdbacks.length;
                }
            });
        }
        return maxNum;
    }

    public static replicateHoldbackTemplatesBasedOnHoldbackCount(matters : Readonly<Matter>[], template : Readonly<DocumentTemplate>, holdbackType: string) : DocumentTemplate[] {

        let maxNum : number = this.findMaxCountOfHoldbacks(matters, holdbackType);
        return this.replicateHoldbackTemplate(template, maxNum, holdbackType);
    }

    public static replicateHoldbackTemplate(template : Readonly<DocumentTemplate>, max: number, holdbackType: string): DocumentTemplate[] {
        let templateInstances : DocumentTemplate[] = [];

        if (max) {
            let match;
            if(holdbackType == HOLDBACK_TYPE.advance){
                match = template.fileName.match(this.MULTI_ADVANCE_HOLDBACK_TEMPLATE_REGEX);
            } else {
                match = template.fileName.match(this.MULTI_OTHER_HOLDBACK_TEMPLATE_REGEX);
            }

            if(match !== null && match.length > 0) {

                for(let i=0; i< max; i++) {

                    let templateInstance = new DocumentTemplate(template);

                    // Replace # in matched characters with Affidavit Number
                    templateInstance.fileName = template.fileName.replace("#", "" + String(i + 1));
                    templateInstance.holdbackIndex = i+1;

                    //Replace first # in template description with Mortgage number but in ordinal format
                    if(template.description) {
                        templateInstance.description = template.description.replace("#", Utils.getOrdinal(i + 1));
                    }

                    templateInstances.push(templateInstance);
                }
            }
        }
        return templateInstances;
    }

    public static hasValidationFlag(template : Readonly<DocumentTemplate>, flag: string): boolean {
        if (!Array.isArray(template.preProduceValidationFlags)) {
            return false;
        }
        return template.preProduceValidationFlags.filter(element => element == flag).length > 0;
    }
}
