import {MatDialogRef} from '@angular/material/dialog';
import {Component} from '@angular/core';

declare var jQuery : any;
@Component({
    selector : 'unsavedchanges-dialog',
    template: `
    <div class="confirm-dialog" (keydown)="onKeyDown($event)">
        <div class="row modal-header">
            <div class="col-lg-12">
                <h1>Confirmation<button class="btn-close float-right" type="button" (click)="cancel()"  tabIndex="-1">
                    <span aria-hidden="true">×</span>
                    </button>
                </h1>
            </div>
         </div>
        <div class="row modal-body">
            <!-- prefer innerHtml binding over interpolation, allowing message contain some simple html tags such as <br>, <p>, <b> etc.   -->
            <p class="text-center col-md-12" style="padding: 10px 40px 20px 40px;" [innerHtml]="message"></p>
        </div>
        <div class="row modal-footer">
            <div class="col-md-12 text-center">
            <button class="dp-btn-green margin-right-10" type="button" id="unsavedChangesOK"  tabIndex="0"
                (click)="save()"  (focusout)="checkTarget($event);" [hidden]="!saveBtnOption"><span>Save</span></button>
            <button class="dp-btn-white margin-right-10" type="button" id="btnDontSave"  (focusout)="checkTarget($event);" tabIndex="0"
                (click)="dontSave()" [hidden]="!dontSaveBtnOption"><span>Don't Save</span></button>
            <button class="dp-btn-white" type="button" (focusout)="checkTarget($event);"  id="btnCancel"  tabIndex="0"
                (click)="cancel()"><span>Cancel</span></button>
            </div>
        </div>
    </div>
    `,
})
export class UnSavedChangesDialog {

    public message: string;
    public saveBtnOption : boolean;
    public dontSaveBtnOption : boolean;
    public keyCommandListener: Function;

    constructor(public dialogRef: MatDialogRef<UnSavedChangesDialog>) { }

    ngOnInit() {
        console.log('dontSaveBtnOption', this.dontSaveBtnOption);
    }

    checkTarget(event){
    /// keep focus on confirmation buttons so user cannot focus on fields behind modal
        console.log(event);
        if(event.relatedTarget==null || event.relatedTarget.id=="" ){
            jQuery('#unsavedChangesOK').focus();
        }
    }
    onKeyDown($event){
        console.log("UnSavedChangesDialog::onKeyDown, $event=", $event);
        if($event.which === 9){
            event.stopPropagation();
        }
        if($event.which===27){
            this.cancel();
            event.stopPropagation();
        }
        if($event.which===13){
            event.preventDefault();
            event.stopPropagation();
            if($event.target.id === "unsavedChangesOK"){
                this.save();
            } else if($event.target.id === "btnDontSave"){
                this.dontSave();
            } else if($event.target.id === "btnCancel"){
                this.cancel();
            }
        }
    }
    save(){
        this.dialogRef.close('SAVE');
    }
    dontSave(){
        this.dialogRef.close('DONT_SAVE');
    }
    cancel(){
        this.dialogRef.close('CANCEL');
    }
}
