import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {Observable} from 'rxjs/Observable';
import {api} from '../../common/api';
import {HttpClient} from '../../core/httpClient.service';
import {StatementConfig} from './statement-config';
import {StatementAdjustmentConfig} from '../statement-adjustment/statement-adjustment-config';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ProvinceCode} from '../accounts/shared/account-province';
import {AccountNationalSetting} from './general-ledger-account-list';

@Injectable()
export class StatementConfigService {

    readonly urlStmntConfig         = `${api}/accounts/{accountId}/statementConfigurations`;
    readonly urlSystemStmntConfig   = `${api}/accounts/{accountId}/systemStatementConfigurations/provinces/{provinceCode}`;
    readonly urlCustomStmntConfig   = `${api}/accounts/{accountId}/customStatementConfigurations`;
    readonly urlAccNatSetting       = `${api}/accounts/{accountId}/nationalSettings`;
    readonly urlSystemAccNatSetting = `${api}/public/accountNationalSettings`;

    private statementConfigCachedList : StatementConfig[] = [];

    constructor(private http : HttpClient, private logger : Logger) { }

    getSystemStatementConfig(provinceCode: string) : Observable<StatementConfig> {

        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = this.urlSystemStmntConfig
            .replace('{accountId}', accountId)
            .replace('{provinceCode}',provinceCode);

        return this.http.get(url)
                   .map((response ) => {
                       if(response) {
                           return new StatementConfig(response['StatementConfigurations']);
                       }
                   });
    }

    getSystemStatementAdjustmentConfig(provinceCode: ProvinceCode) :Observable<StatementAdjustmentConfig[]>  {

        return this.http.get(`${api}/customerAccounts/systemStatementOfAdjustmentConfigs?provinceCode=${provinceCode}`)
                   .map((response ) => {
                       if(response['StatementOfAdjustmentConfigs']) {
                           let statementAdjustmentConfigList : StatementAdjustmentConfig[] = [];
                           response['StatementOfAdjustmentConfigs'].forEach((item) =>{
                               statementAdjustmentConfigList.push(new StatementAdjustmentConfig(item));
                           })
                           return statementAdjustmentConfigList;
                       }
                   });
    }

    getStatementConfig(accountId : string, id? : number, provinceCode?: ProvinceCode) : Observable<StatementConfig> {
        let statementConfigSelected = this.statementConfigCachedList.find(item => item.id == id);
        if(statementConfigSelected) {
            let statementConfigCached = new StatementConfig(statementConfigSelected)
            return Observable.of(statementConfigCached)
        }
        else {
            let url = this.urlStmntConfig.replace('{accountId}', accountId);

            if(id) {
                url = url + `?statementConfigurationId=${id}`
            } else if(provinceCode) {
                url = url + `?provinceCode=${provinceCode}`
            }

            return this.http.get(url).map(response => {
                   if(response) {
                       this.statementConfigCachedList.push(new StatementConfig(response['StatementConfigurations']));
                       return new StatementConfig(response['StatementConfigurations']);
                   }
               });
        }
    }

    getStatementConfigBySoaTemplateId(soaTemplateId : number) : Observable<StatementConfig> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId).toString();
        let url = this.urlStmntConfig.replace('{accountId}', accountId);
        url = url + `?soaTemplateId=${soaTemplateId}`
        return this.http.get(url)
            .map((response ) => {
                if(response){
                    return new StatementConfig(response['StatementConfigurations']);
                }
            });
    }


    getStatementAdjustmentConfig(accountId : string, provinceCode? : ProvinceCode) : Observable<StatementAdjustmentConfig[]> {
        let url = `${api}/customerAccounts/*/statementOfAdjustmentConfigs`;
        let urlEdited = url.replace('*', accountId);
        if(provinceCode) {
            urlEdited += '?provinceCode='+provinceCode;
        }

        return this.http.get(urlEdited)
                   .map((response ) => {
                       if(response['StatementOfAdjustmentConfigs']) {
                           let statementAdjustmentConfigList : StatementAdjustmentConfig[] = [];
                           response['StatementOfAdjustmentConfigs'].forEach((item) =>{
                               statementAdjustmentConfigList.push(new StatementAdjustmentConfig(item));
                           })
                           return statementAdjustmentConfigList;
                       }

                   });

    }

    getSystemAccountingSoftwareConfig() : Observable<AccountNationalSetting> {
        return this.http.get(this.urlSystemAccNatSetting)
            .map((response ) => {
                if(response){
                    return new AccountNationalSetting(response['AccountNationalSettings']);
                }
            });
    }

    getAccountingSoftwareConfig(accountId : string) : Observable<AccountNationalSetting> {
        return this.http.get(this.urlAccNatSetting.replace('{accountId}', accountId))
            .map((response ) => {
                if(response){
                    return new AccountNationalSetting(response['AccountNationalSettings']);
                }
            });
    }

    updateStatementConfig(statementConfig : StatementConfig, accountId : string) : Observable<any> {
        let url = this.urlStmntConfig.replace('{accountId}', accountId) + `/${statementConfig.id}`;

        return this.http.put(url, statementConfig)
                   .map((response ) => {
                       if(response) {
                           return new StatementConfig(response['StatementConfigurations']);
                       }
                   });
    }

    updateStatementAdjustmentConfig(soAdjConfigList : StatementAdjustmentConfig[], accountId : string, provinceCode: ProvinceCode) : Observable<any[]> {
        let url = `${api}/customerAccounts/*/province/{provinceCode}/statementOfAdjustmentConfig`;
        url = url.replace('*', accountId).replace('{provinceCode}',provinceCode);
        return this.http.put(url, soAdjConfigList)
                   .map((response ) => {
                       if(response['StatementOfAdjustmentConfigs']) {
                           let statementAdjustmentConfigList : StatementAdjustmentConfig[] = [];
                           response['StatementOfAdjustmentConfigs'].forEach((item) =>{
                               statementAdjustmentConfigList.push(new StatementAdjustmentConfig(item));
                           })
                           return statementAdjustmentConfigList;
                       }

                   });
    }

    createCustomStatementConfig(statementConfig : StatementConfig, accountId : string) : Observable<StatementConfig> {
        let url = this.urlCustomStmntConfig.replace('{accountId}', accountId);
        return this.http.post(url, statementConfig)
                   .map((response ) => {
                       if(response) {
                           this.statementConfigCachedList.push(new StatementConfig(response['StatementConfigurations']));
                           return new StatementConfig(response['StatementConfigurations']);
                       }

                   });
    }
}
