import {ConsiderationTaxes} from './consideration-taxes';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {matterApi} from '../shared/matter-api';
import {HttpClient} from '../../core/httpClient.service';
import {SoaFeeRate} from './soa-fee-rate';
import {RentInterestRate} from '../statement-adjustment/model/rent-interest-rate';
import {TarionWarrantyEnrolmentPeriod} from '../statement-adjustment/model/tarion-warranty-enrolment-period';
import {Tax_RATE} from '../../shared-main/province-based-dropdowns';
import * as _ from 'lodash';

import moment from 'moment';
import {Matter} from '../shared/matter';
import {Subject} from 'rxjs/Subject';
import {UserStateService} from '../../shared-main/user-state/user-state.service';
import {LockScreenService} from '../../core/lock-screen.service';
import {ManitobaLTTTier} from './manitoba-ltt-tier';
import {InterestRate} from '../statement-adjustment/model/interest-rate';
import {InterestRateList} from '../statement-adjustment/model/interest-rate-list';
import {LandTransferTaxRate} from '../shared/land-transfer-tax-rate';

@Injectable()
export class TaxRateService {

    private cachedConsiderationTaxRates : ConsiderationTaxes[] = [];
    private cachedSoaFeeRates : Map<string, SoaFeeRate[]> = new Map<string, SoaFeeRate[]>();
    private _cachedRentInterestRates:  Map<string, RentInterestRate[]> = new Map<string, RentInterestRate[]>();
    private _cachedLandTransferTaxRates: Map<string, LandTransferTaxRate[]> = new Map<string, LandTransferTaxRate[]>();
    private _cachedTarionWarrantyEnrolmentPeriods: TarionWarrantyEnrolmentPeriod[] = [];
    private _cachedHCRAFeeEnrolmentPeriods: TarionWarrantyEnrolmentPeriod[] = [];
    private _cachedManitobaLTTTiers: ManitobaLTTTier[] = [];
    private _cachedInterestRates: Map<string, InterestRate[]> = new Map<string, InterestRate[]>();

    constructor(private http : HttpClient,
                private lockScreenService : LockScreenService,
                private userStateService : UserStateService) { }

    private loadConsiderationTaxRates(provinceCode : string = 'ON') : Observable<ConsiderationTaxes[]> {
        this.lockScreenService.lockForUpdate = true;
        return this.http.get(`${matterApi.considerationTaxRate}?provinceCode=${provinceCode}`)
            .finally(() => { this.lockScreenService.lockForUpdate = false; })
                   .map((response) => {
                       if(response && response['TaxRates'] && response['TaxRates'].length > 0) {
                           let taxRates : any[] = response['TaxRates'];
                           for(let i = 0; i < taxRates.length; i++) {
                               let cachedConsiderationTaxRate = this.cachedConsiderationTaxRates.find(ConsiderationTaxRate=>
                                   taxRates[i].instanceType == ConsiderationTaxRate.instanceType &&
                                   taxRates[i].provinceCode == ConsiderationTaxRate.provinceCode &&
                                   taxRates[i].effectiveDate == ConsiderationTaxRate.effectiveDate );
                               if(!cachedConsiderationTaxRate){
                                   this.cachedConsiderationTaxRates.push(new ConsiderationTaxes(taxRates[i]));
                               }
                           }
                           return this.filterCachedConsiderationTaxRates(provinceCode);
                       }
                   });
    }


    public  getSoaFeesRate(matterClosingDate : string, provinceCode: string) : Observable<SoaFeeRate[]> {
        let soaFeesRates : SoaFeeRate[] = [];
        let url = `${matterApi.soaFeesRate}?matterClosingDate=${matterClosingDate}&provinceCode=${provinceCode}`;
        return this.http.get(url)
                   .map((response) => {
                       if(response && response['SoaFeesRate'] && response['SoaFeesRate'].length > 0) {
                           let taxRates : SoaFeeRate[] = response['SoaFeesRate'];
                           soaFeesRates = taxRates.map(function(item){ return new SoaFeeRate(item)});
                           this.cachedSoaFeeRates.set(matterClosingDate+provinceCode,soaFeesRates);
                           return soaFeesRates;
                       }
                   });
    }


    public  getSoaFeesRates(matterClosingDate : string, provinceCode: string) : Observable<SoaFeeRate[]> {
        let soaFeeRates : SoaFeeRate[] = this.cachedSoaFeeRates.get(matterClosingDate+provinceCode);
        if(soaFeeRates && soaFeeRates.length > 0)
        {
            return Observable.of(soaFeeRates);
        }
        else{
            return this.getSoaFeesRate(matterClosingDate, provinceCode);
        }
    }


    cachedConsiderationTaxRate(provinceCode: string) : Observable<ConsiderationTaxes[]> {
        if(!this.cachedConsiderationTaxRates ||
            (this.cachedConsiderationTaxRates && this.cachedConsiderationTaxRates.length == 0) ||
            (this.cachedConsiderationTaxRates && this.cachedConsiderationTaxRates.length && !this.isProvinceTaxRateExists(provinceCode))
        ) {
            return this.loadConsiderationTaxRates(provinceCode);
        }
        else{
            return Observable.of(this.filterCachedConsiderationTaxRates(provinceCode));
        }

    }

    isProvinceTaxRateExists(provinceCode: string) : boolean {
        return !!this.cachedConsiderationTaxRates.find(considerationTaxRates=>considerationTaxRates.provinceCode == provinceCode);
    }

    filterCachedConsiderationTaxRates(provinceCode : string): ConsiderationTaxes[] {
        return this.cachedConsiderationTaxRates.filter(considerationTaxRates=>considerationTaxRates.provinceCode == provinceCode )
    }

    getRentInterestRates(provinceCode:string = 'ON'): Observable<RentInterestRate[]> {
        let url: string =  matterApi.rentInterestRates + '?provinceCode=' + provinceCode;
        let rentInterestRates : RentInterestRate[] = [];
        return this.http.get(url)
            .map(rslt => {
                if (rslt && rslt['RentInterestRates'] && rslt['RentInterestRates'].length > 0) {
                    let rentRates : RentInterestRate[] = rslt['RentInterestRates'];
                    rentInterestRates = rentRates.map(function(item){ return new RentInterestRate(item)});
                    this._cachedRentInterestRates.set(provinceCode,rentInterestRates);
                    return rentInterestRates;
                }
            });
    }
    cachedRentInterestRates(provinceCode:string): Observable<RentInterestRate[]>{
        let rentInterestRates : RentInterestRate[] = this._cachedRentInterestRates.get(provinceCode);
        if( rentInterestRates &&  rentInterestRates.length > 0)
        {
            return Observable.of(rentInterestRates);
        }
        else{
            return this.getRentInterestRates(provinceCode);
        }
    }

    //Returns sorted rent interest rates from cache which is loaded in matter init. Use this method when Rent Interest Rates required synchronously.
    getCachedRentInterestRatesSorted(provinceCode:string): RentInterestRate[] {

        let cachedRentInterestRates: RentInterestRate[] = this._cachedRentInterestRates.get(provinceCode);

        if (cachedRentInterestRates && cachedRentInterestRates.length > 0) {
            let rentInterestRates = cachedRentInterestRates.map(r => new RentInterestRate(r));
            let rentInterestRatesSorted = _.sortBy(rentInterestRates, ['index']);
            return rentInterestRatesSorted;
        }

        return cachedRentInterestRates;
    }

    getTarionWarrantyEnrolmentPeriods(): Observable<TarionWarrantyEnrolmentPeriod[]> {
        return this.http.get(matterApi.tarionWarrantyEnrolmentPeriods)
            .map(rslt => {
                if (rslt && rslt['TarionWarrantyEnrolmentPeriods'] && rslt['TarionWarrantyEnrolmentPeriods'].length > 0) {
                    this._cachedTarionWarrantyEnrolmentPeriods = rslt['TarionWarrantyEnrolmentPeriods'].map(twep => new TarionWarrantyEnrolmentPeriod(twep));
                    return this._cachedTarionWarrantyEnrolmentPeriods;
                }
            });
    }

    getHCRAFeeEnrolmentPeriods(): Observable<TarionWarrantyEnrolmentPeriod[]> {
        return this.http.get(matterApi.hcraEnrolmentPeriods)
            .map(rslt => {
                if (rslt && rslt['HCRAEnrolmentPeriods'] && rslt['HCRAEnrolmentPeriods'].length > 0) {
                    this._cachedHCRAFeeEnrolmentPeriods = rslt['HCRAEnrolmentPeriods'].map(twep => new TarionWarrantyEnrolmentPeriod(twep));
                    return this._cachedHCRAFeeEnrolmentPeriods;
                }
            });
    }

    //Returns enrolment periods from cache if present or load it from backend. Use this method if enrolment periods required before matter init or asynchronously.
    cachedTarionWarrantyEnrolmentPeriods(): Observable<TarionWarrantyEnrolmentPeriod[]>{
        return (!this._cachedTarionWarrantyEnrolmentPeriods || (this._cachedTarionWarrantyEnrolmentPeriods && this._cachedTarionWarrantyEnrolmentPeriods.length == 0)) ?
               this.getTarionWarrantyEnrolmentPeriods() :
               Observable.of(this._cachedTarionWarrantyEnrolmentPeriods);
    }

    //Returns enrolment periods from cache which is loaded in matter init. Use this method when enrolment periods required synchronously.
    getCachedTarionWarrantyEnrolmentPeriods(): TarionWarrantyEnrolmentPeriod[]{
        return this._cachedTarionWarrantyEnrolmentPeriods;
    }

    cachedHCRAFeeEnrolmentPeriods(): Observable<TarionWarrantyEnrolmentPeriod[]>{
        return (!this._cachedHCRAFeeEnrolmentPeriods || (this._cachedHCRAFeeEnrolmentPeriods && this._cachedHCRAFeeEnrolmentPeriods.length == 0)) ?
            this.getHCRAFeeEnrolmentPeriods() :
            Observable.of(this._cachedHCRAFeeEnrolmentPeriods);
    }

    //Returns enrolment periods from cache which is loaded in matter init. Use this method when enrolment periods required synchronously.
    getCachedHCRAFeeEnrolmentPeriods(): TarionWarrantyEnrolmentPeriod[]{
        return this._cachedHCRAFeeEnrolmentPeriods;
    }


    findConsiderationHstRateAccordingToEffectiveDate(considerationTaxes: ConsiderationTaxes[] , effectiveDate? : string) : ConsiderationTaxes {
        effectiveDate = effectiveDate? effectiveDate :  moment().format("YYYY-MM-DD");
        let provinceConsiderationTaxes = considerationTaxes.filter(item => item.instanceType == Tax_RATE.HST_RATE);
        if(provinceConsiderationTaxes && provinceConsiderationTaxes.length >1){
            //Sort by effectiveDate DESC so we can find the first one which is the newest date
            provinceConsiderationTaxes.sort((a,b)=>{return new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime()});
            let considerationTax =  provinceConsiderationTaxes.find(item => moment(item.effectiveDate).format("YYYY-MM-DD")  <= effectiveDate );
            return considerationTax;
        } else {
            return provinceConsiderationTaxes[0];
        }

    }

    setUpTaxRateOnMatter(matter: Matter, considerationTaxes: ConsiderationTaxes[]): void {
        if (considerationTaxes && considerationTaxes.length > 0 && !matter.matterHst) {
            //let provinceHst = considerationTaxes.find(item => item.instanceType == Tax_RATE.HST_RATE);
            let provinceHst = this.findConsiderationHstRateAccordingToEffectiveDate(considerationTaxes, matter.getSoaTaxRateEffectiveDate());
            if (provinceHst) {
                matter.matterHst = provinceHst.hstRate;
                matter.matterProvincialHst = provinceHst.hstProvincialPortion;
                matter.matterFederalHst = provinceHst.hstFederalPortion;
                matter.matterFederalHst = provinceHst.hstFederalPortion;
                matter.matterTaxType = provinceHst.rateType;
                matter.initializeMatterTaxRates();
            }
        }
    }

    loadMatterTaxRates(matter : Matter) : Observable<ConsiderationTaxes> {
        let considerationTaxesSubject = new Subject<ConsiderationTaxes>();
        let provinceCode = matter ? matter.provinceCode : this.userStateService.defaultProvinceCode;

        let matterClosingDate = (matter && typeof matter.getSoaTaxRateEffectiveDate == 'function') ? matter.getSoaTaxRateEffectiveDate() : null;
        this.cachedConsiderationTaxRate(provinceCode).subscribe(
            (considerationTaxes : ConsiderationTaxes[]) => {
                if(considerationTaxes && considerationTaxes.length > 0){
                    let provinceHstRateSlab = this.findConsiderationHstRateAccordingToEffectiveDate(considerationTaxes, matterClosingDate);
                    setTimeout(()=>{
                        considerationTaxesSubject.next(provinceHstRateSlab);
                    },0);

                }
            });
        return considerationTaxesSubject;
    }

    getManitobaLTTTiers(): Observable<ManitobaLTTTier[]> {
        return this.http.get(matterApi.manitobaLTTTiers)
            .map(rslt => {
                if (rslt && Array.isArray(rslt['ManitobaLTTTier']) && rslt['ManitobaLTTTier'].length > 0) {
                    this._cachedManitobaLTTTiers = rslt['ManitobaLTTTier'].map(mlttt => new ManitobaLTTTier(mlttt));
                    return this._cachedManitobaLTTTiers;
                }
            });
    }
    cachedManitobaLTTTiers(): Observable<ManitobaLTTTier[]>{
        return (!this._cachedManitobaLTTTiers || (this._cachedManitobaLTTTiers && this._cachedManitobaLTTTiers.length == 0)) ?
            this.getManitobaLTTTiers() :
            Observable.of(this._cachedManitobaLTTTiers);
    }

    getInterestRates(provinceCode: string = 'ON'): Observable<InterestRate[]> {
        //Remove UI hard code according to Suraj's suggestion
        return this.http.get(`${matterApi.interestRates}?provinceCode=${provinceCode}`)
            .map(rslt => {
                if (rslt && rslt['InterestRateList']) {
                    let interestRateList: InterestRateList = new InterestRateList(rslt['InterestRateList']);

                    if (interestRateList && Array.isArray(interestRateList.interestRates)) {
                        if (provinceCode) {
                               this._cachedInterestRates.set(provinceCode, interestRateList.interestRates.map((ir: InterestRate) => new InterestRate(ir.effectiveFrom, ir.interestRate)));
                        }
                        if (this._cachedInterestRates.get(provinceCode)) {
                            return this._cachedInterestRates.get(provinceCode);
                        }
                    }
                }
                // send default value in case backend does not have values for this province
                return [];
            });
    }

    cachedInterestRates(provinceCode: string): Observable<InterestRate[]>{

        let interestRates : InterestRate[] = this._cachedInterestRates.get(provinceCode);
        if( interestRates &&  interestRates.length > 0)
        {
            return Observable.of(interestRates);
        }
        else{
            return this.getInterestRates(provinceCode);
        }
    }

    async getOntarioTaxRateSlab() {
        let considerationTaxes: ConsiderationTaxes[]
        this.cachedConsiderationTaxRate('ON')
            .subscribe(value => {considerationTaxes = value});
        if(considerationTaxes && considerationTaxes.length > 0) {
            const OntarioTaxRates = considerationTaxes.filter(
                item => {
                    return item.instanceType == Tax_RATE.ONTARIO_LTT_RATE &&
                        new Date(item.effectiveDate).getTime() < new Date().getTime()
                });
            return TaxRateService.findLatestRateBasedOnEffectiveDate(OntarioTaxRates);
        }
    }

    async getTorontoTaxRateSlab() {
        let considerationTaxes: ConsiderationTaxes[]
        this.cachedConsiderationTaxRate('ON')
            .subscribe(value => {considerationTaxes = value});
        if(considerationTaxes && considerationTaxes.length > 0) {
            const TorontoTaxRates = considerationTaxes.filter(
                item => {
                    return item.instanceType == Tax_RATE.TORONTO_LTT_RATE &&
                        new Date(item.effectiveDate).getTime() < new Date().getTime()
                });
            return TaxRateService.findLatestRateBasedOnEffectiveDate(TorontoTaxRates);
        }
    }

    static findLatestRateBasedOnEffectiveDate(taxRates: ConsiderationTaxes[]): ConsiderationTaxes | null {
        if (taxRates && taxRates.length > 0) {
            const initialLatest = taxRates[0]
            return taxRates.reduce((currentLatest, newValue) => {
                if (new Date(currentLatest.effectiveDate).getTime() < new Date(newValue.effectiveDate).getTime()) {
                    return newValue
                }
                return currentLatest
            }, initialLatest)
        }
        return null
    }

    static getLatestTorontoTaxRateSlabFromConsiderationTaxes
        (considerationTaxes: ConsiderationTaxes[]): ConsiderationTaxes | null {
        if(considerationTaxes && considerationTaxes.length > 0) {
            const TorontoTaxRates = considerationTaxes.filter(
                item => {
                    return item.instanceType == Tax_RATE.TORONTO_LTT_RATE &&
                        new Date(item.effectiveDate).getTime() < new Date().getTime()
                });
            return TaxRateService.findLatestRateBasedOnEffectiveDate(TorontoTaxRates);
        }
        return null
    }

    static getLatestOntarioTaxRateSlabFromConsiderationTaxes
    (considerationTaxes: ConsiderationTaxes[]): ConsiderationTaxes | null {
        if(considerationTaxes && considerationTaxes.length > 0) {
            const OntarioTaxRates = considerationTaxes.filter(
                item => {
                    return item.instanceType == Tax_RATE.ONTARIO_LTT_RATE &&
                        new Date(item.effectiveDate).getTime() < new Date().getTime()
                });
            return TaxRateService.findLatestRateBasedOnEffectiveDate(OntarioTaxRates);
        }
        return null
    }

    getLandTransferTaxRates(provinceCode:string): Observable<LandTransferTaxRate[]> {
        let url: string =  matterApi.landTransferTaxRates + '?provinceCode=' + provinceCode;
        let landTransferTaxRates : LandTransferTaxRate[] = this._cachedLandTransferTaxRates.get(provinceCode);
        if(landTransferTaxRates){
            return Observable.of(landTransferTaxRates)
        } else {
            let landTransferTaxRates: LandTransferTaxRate[] = [];
            return this.http.get(url)
                .map(rslt => {
                    if (rslt && rslt['LandTransferTaxRates'] && rslt['LandTransferTaxRates'].length > 0) {

                        let landTransferTaxRates1: LandTransferTaxRate[] = rslt['LandTransferTaxRates'];
                        landTransferTaxRates = landTransferTaxRates1.map(function (item) { return new LandTransferTaxRate(item) });
                        this._cachedLandTransferTaxRates.set(provinceCode, landTransferTaxRates);
                        return landTransferTaxRates;
                    }
                });
        }
    }

    getCachedLandTransferTaxRates(provinceCode:string): LandTransferTaxRate[]{
        let landTransferTaxRates : LandTransferTaxRate[] = this._cachedLandTransferTaxRates.get(provinceCode);
        return landTransferTaxRates;
    }
}
