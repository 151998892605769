import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {SoAdjTaxOther} from '../model';

export class StatementAdjustmentDisplayTaxOtherBuilder {

    static  getDisplayItems(soAdj: SoAdjTaxOther, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        if (soAdj.chargeType == 'COST_PLUS_TAX') {
            sadItemDetails.push(mainBuilder.getNewItemLine(`Cost`, mainBuilder.getCurrency(soAdj.cost)));
            sadItemDetails.push(mainBuilder.getNewItemLine(`${soAdj.taxType} calculated at ${mainBuilder.getPercentage(soAdj.percentage)}`,
                mainBuilder.getCurrency(soAdj.taxValue), undefined, "HstOther.TaxCalculated"));
        }

        if (soAdj.chargeType == 'TAX_ONLY') {
            sadItemDetails.push(mainBuilder.getNewItemLine(`Calculated at ${mainBuilder.getPercentage(soAdj.percentage)} of `+ mainBuilder.getCurrency(soAdj.cost) , undefined, undefined, "HstOther.TaxCalculated"));
        }
        sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${soAdj.creditType}`, ''));

        return sadItemDetails;
    }
}
