import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {MortgageDischargeConfig} from './config/mortgage-discharge-config';
import {ProvinceCode} from '../accounts/shared/account-province';
import {NonEncumbranceUndertakingsConfiguration} from './config/non-encumbrance-undertakings-configuration/non-encumbrance-undertakings-configuration';
import {AcknowledgementsConfiguration} from './config/acknowledgements-configuration/acknowledgements-configuration';

export class UndertakingsConfig extends BaseEntity{

    id: number;
    customerAccountId: number;
    provinceCode: ProvinceCode;
    mortgageDischargedConfiguration: MortgageDischargeConfig;
    nonEncumbranceUndertakingsConfiguration: NonEncumbranceUndertakingsConfiguration;
    acknowledgementsConfiguration: AcknowledgementsConfiguration;

    constructor(undertakingsConfig?: UndertakingsConfig) {
        if(undertakingsConfig){
            super(undertakingsConfig);

            if(undertakingsConfig.mortgageDischargedConfiguration){
                this.mortgageDischargedConfiguration = new MortgageDischargeConfig(undertakingsConfig.mortgageDischargedConfiguration);
            }

            if(undertakingsConfig.nonEncumbranceUndertakingsConfiguration){
                this.nonEncumbranceUndertakingsConfiguration = new NonEncumbranceUndertakingsConfiguration(undertakingsConfig.nonEncumbranceUndertakingsConfiguration);
            }

            if(undertakingsConfig.acknowledgementsConfiguration){
                this.acknowledgementsConfiguration = new AcknowledgementsConfiguration(undertakingsConfig.acknowledgementsConfiguration);
            }
        }
    }

}
