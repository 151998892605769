import {Injectable} from '@angular/core';
import {HttpClient} from '../core';
import {accountApi} from '../admin/accounts/shared/account-api';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class NotificationOptOutService {

    optedOutEmails: string[];

    constructor(public httpClient: HttpClient){}

    pullOptedOutEmails() : void {
        this.loadOptOutEmails().subscribe();
        setTimeout(()=>{
            this.loadOptOutEmails().subscribe();
        }, 900000); // Pull every 15 min
    }

    loadOptOutEmails = () : Observable<any> =>{
        let url = accountApi.optOutEmails;
        return this.httpClient.get(url)
            .map((res) => {
                let data = res['NotificationOptOutEmails'];
                if(data){
                    this.optedOutEmails =  data;
                }
            });
    }



    isOptedOutEmail(email: string) : boolean {
        if(this.optedOutEmails && this.optedOutEmails.length){
            return !!this.optedOutEmails.find(item=>item==email);
        }
        return false;
    }
}
