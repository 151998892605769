export class FieldCodeMapping {
    id : number;
    fieldValue : any;
    fieldMetaData  : FieldMetaData;
    fieldCode : any;
    fieldMetaDataJson  : any;
    manuallyEntered: boolean;

    constructor(fieldCodeMap?: FieldCodeMapping) {
        if (fieldCodeMap) {
            for (let prop in fieldCodeMap) {
                if (fieldCodeMap.hasOwnProperty(prop)) {
                    this[prop] = fieldCodeMap[prop];
                }
            }
            if (fieldCodeMap.fieldMetaData) {
                this.fieldMetaData = new FieldMetaData(fieldCodeMap.fieldMetaData);
            }
            else if (fieldCodeMap.fieldMetaDataJson) {
                this.fieldMetaData = new  FieldMetaData(JSON.parse(fieldCodeMap.fieldMetaDataJson));
            }
        }
    }

}

export class FieldMetaData {

    elementId : any;
    elementName  : any;
    elementXpath : any;
    elementOriginalXpath : any;
    elementValue : any;
    elementLabel : any;
    elementIdentifierXpath : any;
    elementAutoSuggestIdentifier : any;
    elementPartialDateIdentifier : any;
    elementAddressIdentifier : any;
    elementMortgageIndex : any;
    elementRoute : any;
    elementActionList : any[]=[];

    constructor(fieldMetaData?:FieldMetaData) {
        if (fieldMetaData) {
            for (let prop in fieldMetaData) {
                if (fieldMetaData.hasOwnProperty(prop)) {
                    this[prop] = fieldMetaData[prop];
                }
            }
        }
    }
}
