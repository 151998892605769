import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'ordinal'
})
export class OrdinalNumberPipe implements PipeTransform {

    transform(num: number): string {
        const n = num.toString();
        let suffix = '';
        if (n == '11' || n == '12' || n == '13') {
            return `${n}th`;
        }
        switch (n[n.length - 1]) {
            case '1':
                suffix = 'st';
                break;
            case '2':
                suffix = 'nd';
                break;
            case '3':
                suffix = 'rd';
                break;
            default:
                suffix = 'th';
                break;
        }
        return `${n}${suffix}`;
    }
}
