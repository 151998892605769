import {ReferralForm} from './referral-form';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {ReferralDocument} from './referral-document';
import {MatterType} from '../index';

export type referralStatusType =  'DRAFT' | 'SUBMITTED' | 'ACCEPTED' | 'DECLINED';
export type referralSourceSystem = 'UNITY_CONNECT'| 'THIRD_PARTY';

export class Referral extends BaseEntity {

    id: number;
    status: referralStatusType;
    formData : string;
    referralForm : ReferralForm;
    targetCustomerAccountId: number;
    targetLawyerId : number;
    lawFirm: string;
    propertyAddress: string;
    matterType: MatterType; //probably not used
    lastUpdatedTimeStamp : string;
    documents: ReferralDocument[] = [];
    assignedMatterId: number;
    opportunityMatterId: number;
    source: referralSourceSystem

    // UI only
    solicitorChanged: boolean = false; //UI side flag to indicate that the solicitor was updated
    selectedDocuments : number[] = []; // selected document for import
    reviewStatus: string[] = []; //all referral tabs that user has clicked on

    constructor(referral?: Referral) {
        super(referral);
        if (referral) {
            if(referral.formData){
                this.referralForm = new ReferralForm(JSON.parse(referral.formData));
            }

            this.documents = [];
            if (Array.isArray(referral.documents)) {
                for (let document of referral.documents) {
                    this.documents.push(new ReferralDocument(document));
                }
            }
        }
    }

    isCreatedFromUnity() {
        return this.source == 'UNITY_CONNECT';
    }
}
