import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {OAuthService} from 'angular-oauth2-oidc';
import {KEYCLOAK_DOMAIN} from '../../common';
import {FeatureService} from '../../feature-service';

@Injectable()
export class AccountSelectionInterceptor implements HttpInterceptor {
    private isRefreshing = false;

    constructor(private oauthService: OAuthService, private featureService: FeatureService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // if (!this.featureService.isSSOEnable) {
        //     return next.handle(req);
        // }

        let request;
        if (req.url && req.url.startsWith(KEYCLOAK_DOMAIN)) {
            request = req;
        } else {
            if (localStorage.getItem('public-account-id') === null) {
                return next.handle(req);
            }
            request = req.clone({
                setHeaders: { 'Selected-Public-Account-Id': localStorage.getItem('public-account-id')},
                withCredentials: true,
            });
        }

        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (req.url.startsWith(KEYCLOAK_DOMAIN)) {
                        this.oauthService.logOut();
                    }
                    if (error instanceof HttpErrorResponse && error.status === 401) {
                        return this.handle401Error(request, next);
                    }
                })
            )
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.oauthService.refreshToken().then( () => {
                this.isRefreshing = false;
                return next.handle(request);
            }).catch((err) => {
                this.oauthService.logOut();
            });

            return next.handle(request);
        }
    }
}
