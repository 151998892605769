import {api} from '../../common/api';

export const projectApi = {
    projects : `${api}/projects`,
    projectsForAccount(accountId: string): string { return`${api}/accounts/${accountId}/projects`; },
    projectForAccount(accountId: string, projectId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}`; },
    createProject(accountId: string): string { return`${api}/accounts/${accountId}/projects`; },
    customPickList : `${api}/public/customPickList/default`,
    validateFieldCode(accountId: string, projectId: number, fieldCode: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/soadjFieldCodes/${fieldCode}/validate`; },
    firstAvailableFieldCode(accountId: string, projectId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/soadjFieldCodes/firstAvailable`; },
    getProjectFieldCodes(accountId: string, projectId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/soadjFieldCodes`; },
    getProjectMattersFieldCodes(accountId: string, projectId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/soadjFieldCodes/matterFieldCodes`; },
    getMatterFieldCodes(accountId: string, projectId: number, matterId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/soadjFieldCodes/matterFieldCodes/${matterId}`; },
    saveProjectFieldCodes(accountId: string, projectId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/soadjFieldCodes`; },
    saveMatterFieldCodes(accountId: string, projectId: number, matterId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/soadjFieldCodes/matterFieldCodes/${matterId}`; },
    duplicatePins(accountId: string, projectId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/duplicatePins`; },
    duplicateLots(accountId: string, projectId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/duplicateLotPlan`; },
    duplicateAddresses(accountId: string, projectId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/duplicateStreetAddress`; },
    duplicateUnits(accountId: string, projectId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/duplicateUnitLevel`; },
    createERegBulkXML(accountId: string, projectId: number): string { return`${api}/accounts/${accountId}/projects/${projectId}/bulk/eReg/xml`; },
    holdbackReport(accountId: string, projectId: number, holdbackType: string, province: string): string {
        return`${api}/accounts/${accountId}/projects/${projectId}/matterHoldbacks/csv?holdbackType=${holdbackType}&province=${province}`; },
    holdbackSummary(accountId: string, projectId: number): string {
        return`${api}/accounts/${accountId}/projects/${projectId}/matterHoldbackSummary/notReleased`; },

    generatePinListXML: `${api}/accounts/{accountId}/projects/{projectId}/documents/xml/{teraviewImportFileType}`,
    downloadGeneratedPinListXML: `${api}/{sessionId}/projects/{projectId}/documents/xml/{teraviewImportFileType}/download`,
    projectSharingNotification : `${api}/projects/{projectId}/documentsSharingNotifications`,
    projectBeginTransaction(accountId: string, projectId: number): string { return `${api}/accounts/${accountId}/projects/${projectId}/beginTransaction`;},
    projectRollBackTransaction  (accountId: string, transactionId : number): string { return`${api}/accounts/${accountId}/transactions/${transactionId}/rollback`},
    projectCommitTransaction (accountId: string, transactionId: number): string { return `${api}/accounts/${accountId}/transactions/${transactionId}/commit`},
    projectTransactionInfo (accountId: string, transactionId: number): string { return `${api}/accounts/${accountId}/transactions/${transactionId}`},
    getAllProjectMatters(accountId: string, projectId: number): string { return `${api}/accounts/${accountId}/projects/${projectId}/matters`;},
};

export const projectBaseUrl : string = 'main/projects/project';
