import {Directive, ElementRef} from '@angular/core';

declare var jQuery : any;

@Directive({
    selector: '[dp-radio-label-event-directive]'
})

export class DpRadioEventDirective {
    constructor(private el: ElementRef) {   }

    ngOnInit(){

    }

    ngAfterViewInit(): void{
        jQuery(this.el.nativeElement).attr("tabindex","0");
        setTimeout(()=>{
        this.initRadioAttributes();
        },1000);
    }

    initRadioAttributes(){
        jQuery(this.el.nativeElement).attr("tabindex","0");
        jQuery(this.el.nativeElement).on('keydown',  (e) => {
            this.radioKeyCommands(e);
        });
    }



    radioKeyCommands(event){
        if(event.keyCode==13 || event.keyCode==32) {
            event.preventDefault();
            jQuery(event.target).trigger('click');
        }
    }

}
