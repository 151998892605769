import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class SalePriceAdjustmentHeading extends BaseEntity{
    id: number;
    static clientAssignedIdentifierEntity: boolean = true;//Marker property for indicating client side assigns the ID to this entity
    name: string;
    cost: number;
    autoCalculate : boolean;
    salePriceAdjustmentHeadingItems: SalePriceAdjustmentHeadingItem[] = [];

    constructor(salePriceAdjustmentHeading?: SalePriceAdjustmentHeading) {
        super(salePriceAdjustmentHeading);
        if (salePriceAdjustmentHeading) {
            this.salePriceAdjustmentHeadingItems = [];
            if (Array.isArray(salePriceAdjustmentHeading.salePriceAdjustmentHeadingItems)) {
                salePriceAdjustmentHeading.salePriceAdjustmentHeadingItems.forEach((item)=>{
                    this.salePriceAdjustmentHeadingItems.push(new SalePriceAdjustmentHeadingItem(item));
                });
            }
        }
    }
}

export class SalePriceAdjustmentHeadingItem extends BaseEntity {
    id: number;
    description: string;
    cost : number;

    constructor(salePriceAdjustmentHeadingItem?: SalePriceAdjustmentHeadingItem) {
        super(salePriceAdjustmentHeadingItem);

    }
}
