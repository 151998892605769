import {Injectable} from '@angular/core';
import {HttpClient} from '../../../core';
import {Logger} from '@nsalaun/ng-logger';
import {Observable} from 'rxjs';
import {MatterNotificationConfig} from './matter-notification-config';
import {accountApi} from '../../accounts/shared/account-api';


@Injectable()
export class MatterNotificationConfigService {

    constructor(private http : HttpClient, private logger : Logger) {
    }


    updateMatterNotificationConfig(matterNotificationConfigs: MatterNotificationConfig[],accountId : string): Observable<MatterNotificationConfig[]> {
        let url = accountApi.matterNotificationConfig(accountId);
        return this.http.put(url, JSON.stringify(matterNotificationConfigs))
                   .map((res) => {
                       let data = res['MatterNotificationConfig'];
                       let result: MatterNotificationConfig[] = [];
                       if(data && data.length>0) {
                           data.forEach(item => {
                               result.push(new MatterNotificationConfig(item))
                           });
                       }
                       return result;
                   });
    }

    getMatterNotificationConfig(accountId : string): Observable<MatterNotificationConfig[]> {
        //// use this only for admin users // if regular non-admin user needs to get account info use getShallowAccount()
        //let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = accountApi.matterNotificationConfig(accountId);
        return this.http.get(url)
                   .map((res ) => {
                       let data = res['MatterNotificationConfig'];
                       let result : MatterNotificationConfig[] = [];
                       if (data && data.length > 0) {
                           data.forEach(item => {
                               result.push(new MatterNotificationConfig(item))
                           });
                       }
                       return result;
                   });
    }
}
