import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class FctGeneratedDocument extends BaseEntity{
    name: string;
    displayName: string;
    generatedDateTime: string;
    downloadStatus: string;


    constructor(t?: FctGeneratedDocument) {
        super(t);
    }
}
