import {BaseEntity} from '../../shared/BaseEntity/base-entity';


export class MatterTopic extends BaseEntity {
    id : number;
    topicNote : string;
    topicStatus : string;
    matterTopicKey : string;


    constructor(matterTopic? : MatterTopic) {
        super(matterTopic);
    }


}
