import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {HttpClient} from '../../core/httpClient.service';
import {ApplicationError} from '../../core/application-error';

@Component({
               selector: 'dp-file-upload',
               templateUrl: 'file-upload-component.html'
           })
export class FileUploadComponent {
    @Input() multiple: boolean = false;
    @Input() accept: string = '.docx';
    @Input() url: string;
    @Input() placeholder: string = "Select file to upload";
    @Input() options: Map<string, any>;
    @Input() tabIndex : string = '0';
    @ViewChild('fileInput') inputEl: ElementRef;
    @Output() success: EventEmitter<boolean> = new EventEmitter<boolean>();

    public error: string;
    public uploadedFile: string;
    public uploadInProgress: boolean = false;


    constructor(public httpClient: HttpClient, public el: ElementRef) {}

    upload() {
        this.error = null;
        this.uploadedFile = null;
        let inputEl: HTMLInputElement = this.inputEl.nativeElement;
        let fileCount: number = inputEl.files.length;
        let formData = new FormData();
        //This can be extracted in a service if we settle for this upload approach
        if(fileCount > 0) {// a file was selected
            let fileName = ''; //just deal with one name for now
            for(let i = 0; i < fileCount; i++) {
                if(!inputEl.files[i].name.endsWith('.docx')){
                    this.error = "Please select a word document file (.docx file extension)";
                    return;
                } else if(inputEl.files[i].name.length > 50){
                    this.error = "File name is too long";
                    return;
                }
                formData.append('uploadedFile', inputEl.files.item(i), inputEl.files[i].name);
                fileName = inputEl.files[i].name;
                if(this.options) {
                    this.options.forEach((value: any, key: string) => {
                        formData.append(key, value);
                    });
                }
            }
            this.markInProgress(true);
            this.httpClient.uploadFiles(this.url, formData).subscribe(
                data => {
                    console.log(data)
                    this.uploadedFile = fileName;
                    inputEl.value = "";
                    this.markInProgress(false);
                    this.success.emit(true);
                },
                (err: ApplicationError) => {
                    console.log(err)
                    this.error = err.errorMessage   ;
                    inputEl.value = "";
                    this.markInProgress(false);
                }
            );

        }
    }

    reset(): void {
        this.uploadedFile = null;
        this.error = null;
    }

    markInProgress(inProgress: boolean){
        this.uploadInProgress = inProgress;
    }
}
