import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {SharedDocument} from '../../share-documents/shared-document';
import {PROVINCE_CODES} from '../shared/user-province';
import {DocSharingMessage} from './doc-sharing-message';
import {MatterInfo} from './matter-info';

export type SharedDocumentsPackageType = 'UNITY' | 'CONNECT' ;

export type  ConnectRecipientRoleType = 'CLIENT' | 'OTHER_PARTY_LAW_CLERK' | 'VENDOR' | 'OTHER_PARTY_SOLICITOR' | 'MORTGAGE_SOLICITOR' | 'SOLICITOR' | 'INSURER' | 'BROKER' | 'CONDO_CORPORATION' |  'MORTGAGEE' | 'MORTGAGE_BROKER'
    | 'REAL_ESTATE_AGENT' | 'REAL_ESTATE_BROKER' | 'OTHER_PARTY_REAL_ESTATE_AGENT' | 'OTHER_PARTY_REAL_ESTATE_BROKER';

export type PackageSharingStatus = 'AVAILABLE' | 'REVOKED' | 'PARTIALLY_REVOKED';

export class SharedDocumentsPackage extends BaseEntity {

    id: number;
    documentIds: number[] = [];
    matterId: number;
    linkedMatterId: number;
    connectRecipientEmail: string;
    connectRecipientRole: string;
    recipientName: string;
    recipientDearName: string; //Only for UI
    unityRecipientIds: number[] = [];
    guid: string;
    status: string;
    packageType: SharedDocumentsPackageType;
    unitySharingMessageSubject: string;
    documents : SharedDocument[] = [];
    bulkShareTemplateIds: number[] = [];
    unitySharingMessageText: string;
    provinceCode: string;
    matterInfo: MatterInfo;
    lastUpdatedTimeStamp: number;
    docSharingMessages : DocSharingMessage[] = [];
    connectRecipientRoleType : ConnectRecipientRoleType;
    packageSharingStatus: PackageSharingStatus ;
    packageFromConnect: boolean;

    constructor(sharedDocumentsPackage?: SharedDocumentsPackage) {
        super(sharedDocumentsPackage);
        if(sharedDocumentsPackage){
            this.documents = [];
            if (Array.isArray(sharedDocumentsPackage.documents)) {
                for (let dc of sharedDocumentsPackage.documents) {
                    this.documents.push(new SharedDocument(dc));
                }
            }
            this.docSharingMessages = [];
            if (Array.isArray(sharedDocumentsPackage.docSharingMessages)) {
                for (let dsm of sharedDocumentsPackage.docSharingMessages) {
                    this.docSharingMessages.push(new DocSharingMessage(dsm));
                }
            }

            if(sharedDocumentsPackage.matterInfo){
                this.matterInfo = new MatterInfo(sharedDocumentsPackage.matterInfo);
            }
        }
    }

    isProvinceABorMBorSK() : boolean {
        return this.provinceCode === PROVINCE_CODES.ALBERTA ||
               this.provinceCode === PROVINCE_CODES.MANITOBA ||
              this.provinceCode === PROVINCE_CODES.SASKATCHEWAN;
    }


    getLatestDocSharingMessageId() : number {
        return this.docSharingMessages && this.docSharingMessages.length > 0 ? this.docSharingMessages.sort((a, b): number => {return a.lastUpdatedTimeStamp > b.lastUpdatedTimeStamp ? -1: (a.lastUpdatedTimeStamp < b.lastUpdatedTimeStamp ? 1 : 0)})[0].id : -1;
    }

    getSelectedDocSharingMessage(selectedId : number) : DocSharingMessage {
        return this.docSharingMessages && this.docSharingMessages.length > 0 && selectedId > 0 ? this.docSharingMessages.find(item => item.id == selectedId) : undefined;
    }

    get isDeclined(): boolean{
        return this.status === 'DECLINED';
    }

    isSharingRevoked() : boolean {
        return this.packageSharingStatus == 'REVOKED';
    }

    isSharingPartiallyRevoked() : boolean {
        return this.packageSharingStatus == 'PARTIALLY_REVOKED';
    }

    isSharingAvailable() : boolean {
        return this.packageSharingStatus == 'AVAILABLE';
    }

}

export class RevokeSharing {
    revokeSharingMessageSubject: string;
    revokeSharingMessageBody: string;
    keepSharedProjectDocuments : boolean = false;
}
