import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {SoAdjTarionWarranty} from '../model/so-adj-tarion-warranty';
import {SalePriceAdjustment} from '../sale-price-adjustment/sale-price-adjustment';
import {TarionWarrantyEnrolmentPeriod} from '../model/tarion-warranty-enrolment-period';
import {TarionWarrantyRate} from '../model/tarion-warranty-rate';
import {ProjectAdjustmentConfig} from '../../../projects/project-adjustments/project-adjustment-config';

export class StatementAdjustmentDisplayTarionWarrantyBuilder {

    static getDisplayItems(soaAdj: SoAdjTarionWarranty, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder, taxRateType: string , projectAdjustmentConfig : ProjectAdjustmentConfig): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        if (soaAdj.builderRegistrationNumber && soaAdj.builderRegistrationNumber.length > 0){

            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Registration number of Vendor: ${soaAdj.builderRegistrationNumber}`,
                ''
            ));
        }

        if (soaAdj.enrolmentNumber && soaAdj.enrolmentNumber.length > 0){

            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Enrolment number of subject property: ${soaAdj.enrolmentNumber}`,
                ''
            ));
        }

        if (soaAdj.applicableSection && soaAdj.applicableSection.length > 0){

            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Pursuant to section ${soaAdj.applicableSection} of Agreement`,
                ''
            ));
        }


        //if (mainBuilder.matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice != 'NO'){

        let salePriceAdj: SalePriceAdjustment = mainBuilder.matter.considerationLtt.salePriceAdjustment;
        let amountToCalculateTarionRate: number = StatementAdjustmentDisplayTarionWarrantyBuilder.calculateAmountForTarionRate(salePriceAdj, soaAdj, mainBuilder, projectAdjustmentConfig);
        let enrolmentFee: number = StatementAdjustmentDisplayTarionWarrantyBuilder.calculatedEnrolmentFee(salePriceAdj, soaAdj, mainBuilder ,projectAdjustmentConfig);
        let enrolmentFeePlusTax: number = StatementAdjustmentDisplayTarionWarrantyBuilder.calculatedEnrolmentFeePlusTax(salePriceAdj, soaAdj, mainBuilder,projectAdjustmentConfig);

        sadItemDetails.push(mainBuilder.getNewItemLine(
            toBeUsedOnModal ? `Enrolment fee (based on ${mainBuilder.getCurrency(amountToCalculateTarionRate)})` : `Enrolment fee`  ,
            `${mainBuilder.getCurrency(enrolmentFee)}`
        ));

        if (toBeUsedOnModal) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${soaAdj.taxType}`,
                `${mainBuilder.getCurrency(enrolmentFeePlusTax - enrolmentFee)}`
            ));
        }
        else{
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${soaAdj.taxType} calculated at ${mainBuilder.getPercentage(soaAdj.appliedTax)}`,
                `${mainBuilder.getCurrency(enrolmentFeePlusTax - enrolmentFee)}`, undefined, `Tarion.HST`
            ));

        }

        if (!toBeUsedOnModal) { // modal will handle credit line differently
            if (soaAdj.creditType == 'NO_ADJUSTMENT' && soaAdj.amountAdditionalConsiderationToVendor == 'NO') {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `No Adjustment`,
                    ``
                ));
            } else if (soaAdj.amountAdditionalConsiderationToVendor == 'NO' || mainBuilder.isSalePriceFormatINCLUSIVE) {
                let creditLabel = soaAdj.creditType == 'VENDOR' ? mainBuilder.vendorLabel : mainBuilder.purchaserLabel;
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `Credit ${creditLabel}`,
                    ``, undefined, `Tarion.Credit.${creditLabel}`
                ));
            }
        }

        return sadItemDetails;
    }

    static calculateAdjustmentAmount(salePriceAdj: SalePriceAdjustment, soaAdj: SoAdjTarionWarranty, mainBuilder: StatementAdjustmentDisplayBuilder, projectAdjustmentConfig : ProjectAdjustmentConfig): number {

        if (salePriceAdj.isSalePriceFormatINCLUSIVE()){ // “Format of Sale Price adjustment” field is IN (‘Credit Vendor = HST-inclusive price in Agreement’ or ‘Sale Price in Agreement’)
            return StatementAdjustmentDisplayTarionWarrantyBuilder.calculatedEnrolmentFeePlusTax(salePriceAdj, soaAdj, mainBuilder , projectAdjustmentConfig);
        }

        if(soaAdj.amountAdditionalConsiderationToVendor == 'NO'){
            return StatementAdjustmentDisplayTarionWarrantyBuilder.calculatedEnrolmentFeePlusTax(salePriceAdj, soaAdj, mainBuilder , projectAdjustmentConfig);
        }
        if(soaAdj.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'){
            return StatementAdjustmentDisplayTarionWarrantyBuilder.calculatedEnrolmentFeeTax(salePriceAdj, soaAdj, mainBuilder, projectAdjustmentConfig);
        }
        return 0;
    }

    static calculatedEnrolmentFeeTax(salePriceAdj: SalePriceAdjustment, soaAdj: SoAdjTarionWarranty, mainBuilder: StatementAdjustmentDisplayBuilder , projectAdjustmentConfig : ProjectAdjustmentConfig): number {

        let enrolmentFee: number = StatementAdjustmentDisplayTarionWarrantyBuilder.calculatedEnrolmentFee(salePriceAdj, soaAdj, mainBuilder , projectAdjustmentConfig);

        if (soaAdj.taxType == 'GST'){
            return  Number(enrolmentFee * soaAdj.gstRate / 100);
        }
        return Number(enrolmentFee * soaAdj.hstRate / 100);
    }

    static calculatedEnrolmentFeePlusTax(salePriceAdj: SalePriceAdjustment, soaAdj: SoAdjTarionWarranty, mainBuilder: StatementAdjustmentDisplayBuilder,  projectAdjustmentConfig : ProjectAdjustmentConfig): number {

        let enrolmentFee: number = StatementAdjustmentDisplayTarionWarrantyBuilder.calculatedEnrolmentFee(salePriceAdj, soaAdj, mainBuilder , projectAdjustmentConfig);

        if (soaAdj.taxType == 'GST'){
            return Number(enrolmentFee) + Number(enrolmentFee * soaAdj.gstRate / 100);
        }
        return Number(enrolmentFee) + Number(enrolmentFee * soaAdj.hstRate / 100);
    }

    static calculatedEnrolmentFee(salePriceAdj: SalePriceAdjustment, soaAdj: SoAdjTarionWarranty, mainBuilder: StatementAdjustmentDisplayBuilder , projectAdjustmentConfig : ProjectAdjustmentConfig): number {
        let amountToCalculateTarionRate: number = StatementAdjustmentDisplayTarionWarrantyBuilder.calculateAmountForTarionRate(salePriceAdj, soaAdj, mainBuilder ,projectAdjustmentConfig);
        let selectedPeriod: TarionWarrantyEnrolmentPeriod = mainBuilder.tarionWarrantyEnrolmentPeriods.find( twep => twep.id == soaAdj.enrolmentPeriodId);
        if (selectedPeriod){

            let rate: TarionWarrantyRate = selectedPeriod.rates.find( sp => {
                if (sp.fromAmount == 0){
                    return amountToCalculateTarionRate <= sp.toAmount;
                }
                else if (sp.toAmount == 0){
                    return amountToCalculateTarionRate > sp.fromAmount;
                }
                else {
                    return sp.fromAmount < amountToCalculateTarionRate && amountToCalculateTarionRate <= sp.toAmount;
                }});

            if (rate){
                return rate.fee;
            }
        }
        return 0;
    }

    static calculateAmountForTarionRate(salePriceAdj: SalePriceAdjustment, soaAdj: SoAdjTarionWarranty, mainBuilder: StatementAdjustmentDisplayBuilder , projectTaxAdjustmentConfig : ProjectAdjustmentConfig): number{
        let netSalePrice: number = salePriceAdj.totalNetSalePrice(mainBuilder.federalHstRate, mainBuilder.provinceHstRate);
        if(projectTaxAdjustmentConfig && projectTaxAdjustmentConfig.ignoreCreditInTarionAdjustmentisYes()) {
            netSalePrice = salePriceAdj.totalNetSalePrice(mainBuilder.federalHstRate, mainBuilder.provinceHstRate,true,true, true, true, true);
        }
        let agreedSalePrice: number = salePriceAdj.agreementSalePrice;

        // let netSalePriceLessRebate: number = Number(netSalePrice) - Number(salePriceAdj.totalRebate(mainBuilder.provinceHstRate, mainBuilder.federalHstRate));
        let netSalePriceLessRebate: number = Number(salePriceAdj.totalNetSalePrice(mainBuilder.federalHstRate, mainBuilder.provinceHstRate)) - Number(salePriceAdj.totalRebate(mainBuilder.provinceHstRate, mainBuilder.federalHstRate));

        let amountToCalculateTarionRate: number = 0;

        if (soaAdj.salePriceUsedInCalculation == 'NET_SALE_PRICE'){
            amountToCalculateTarionRate = netSalePrice;
        }
        else if (soaAdj.salePriceUsedInCalculation == 'AGREED_SALE_PRICE' ){
            amountToCalculateTarionRate = agreedSalePrice;
        }
        else if (soaAdj.salePriceUsedInCalculation == 'NET_SALE_PRICE_LESS_REBATE' ){
            amountToCalculateTarionRate = netSalePriceLessRebate;
        }
        else if (soaAdj.salePriceUsedInCalculation == 'NET_SALE_PRICE_SPECIFIED_SUM'){
            amountToCalculateTarionRate = Number(netSalePrice) + Number(soaAdj.specificSum);
        }
        return amountToCalculateTarionRate;

    }


}
