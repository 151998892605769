import * as _ from 'lodash';
import Utils from '../../shared-main/utils';
import {UUIDUtil} from '../../main/uuid-util';
import moment from 'moment';
import {DEPOSIT_FORMAT_ONE_ITEM_ADJ, DEPOSIT_FORMAT_SEPARATE_PER_DEPOSIT} from './matter-drop-downs';

export enum DepositStatus {
    NSF = 'NSF', NH = 'NH'
}
export enum DepositReceivingStatus {
    SENT = 'SENT', RECEIVED_WITHIN_9_DAYS = 'RECEIVED_WITHIN_9_DAYS', RECEIVED_PAST_9_DAYS = 'RECEIVED_PAST_9_DAYS',POST_DATED = 'POST_DATED', NO_STATUS = 'NO_STATUS'
}

export type DepositPrintFormatType = 'SELECTED_DEPOSIT' | 'AGGREGATE_DEPOSITS_EXCEPT_OCCUPANCY' | 'FORM_NOT_SENT_ON_SEPARATE_PAGE' | 'FORM_NOT_SENT_ON_SINGLE_PAGE' | 'ALL_DEPOSITS_ON_SINGLE_PAGE' | 'ALL_DEPOSITS_ON_SINGLE_PAGE_EXCEPT_OCCUPANCY';
export class Deposit {

    id : number;
    depositDate : string;
    depositName : string;
    depositAmount : string = "0";
    depositStatus : DepositStatus;
    extras : boolean;
    paidOnOccupancy : boolean;
    depositPrintFormat: DepositPrintFormatType;//Only UI field
    form4Date: string;
    form4Sent: boolean;

    constructor(deposit? : Deposit) {
        if(deposit) {
            for(let prop in deposit) {
                if(deposit.hasOwnProperty(prop)) {
                    this[prop] = deposit[prop];
                }
            }
        }
        if (!this.id) {
            this.id = UUIDUtil.getUUID();
        }
    }

    isNSF(): boolean {
        return this.depositStatus == DepositStatus.NSF;
    }

    isNH(): boolean {
        return this.depositStatus == DepositStatus.NH;
    }

    /** DPPMP-30930, Total Deposits (including extras) */
    static calculateTotalDepositsIncludingExtras(deposits: Deposit[]) : number {
        if (!deposits || !_.isArray(deposits)) {
            return 0.0;
        }
        deposits = deposits.filter(d => !d.paidOnOccupancy);
        let total = deposits.reduce((sum : number, deposit : Deposit) : number => {
            if(!deposit.isNH() && !deposit.isNSF()) {
                return sum + Utils.toNumber(deposit.depositAmount);
            } else {
                return sum;
            }
        }, 0.0);
        total = Utils.roundCurrencyTCStyle(total);
        return total;
    }

    /**
     * DPPMP-30930, Total Deposits excluding extras.
     */
    static calculateTotalDepositsExcludingExtras(deposits: Deposit[]) : number {
        if (!deposits || !_.isArray(deposits)) {
            return 0.0;
        }
        deposits = deposits.filter(d => !d.paidOnOccupancy);
        let total = deposits.reduce((sum : number, deposit : Deposit) : number => {
            if(!deposit.extras && !deposit.isNH() && !deposit.isNSF()) {
                return sum + Utils.toNumber(deposit.depositAmount);
            } else {
                return sum;
            }
        }, 0.0);
        total = Utils.roundCurrencyTCStyle(total);
        return total;
    }

    updateDepositName(adjustmentFormatUpdated : string) : void {
        let adjustmentFormat = !adjustmentFormatUpdated ? 'ONE_ITEM_ADJ' : adjustmentFormatUpdated;
        let date : string = this.depositDate; // date: yyyy/mm/dd
        let ret = '';
        let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
        if(pattern.test(date)) {
            const ds = moment(date, "YYYY/MM/DD").format('MMMM D, YYYY');
            switch(adjustmentFormat) {
                case DEPOSIT_FORMAT_ONE_ITEM_ADJ:
                    ret = `${ds} deposit`;
                    break;
                case DEPOSIT_FORMAT_SEPARATE_PER_DEPOSIT:
                    ret = `Deposit - ${ds}`;
            }
        }
        if (this.isNSF()) {
            ret = ret + ` (${DepositStatus.NSF})`;
        } else if (this.isNH()) {
            ret = ret + ` (${DepositStatus.NH})`;
        }
        this.depositName = ret;
    }
}
