import {HttpClient} from '../../core';
import {Account} from '../../admin/accounts/shared/account';
import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {api} from '../../common/api';

@Injectable()
export class TempAccountService {
    constructor(private http: HttpClient, private logger: Logger) { }

    getAccount(id: string) {
        return this.http.get(`${api}/customerAccounts/${id}`)
            .map((res) => {
                return new Account(res['CUSTOMERACCOUNT']);
            });
    }
}
