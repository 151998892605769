import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export type AddressSourceType = 'GOOGLE' | 'CANADA_POST';
export type NextStepType = 'Find' | 'Retrieve';
//Place response for addresses auto-suggest
export class Place extends BaseEntity{
    constructor(place? : Place) {
        super(place);
    }
    placeId: string;
    _description: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    provinceCode: string;
    provinceName: string;
    country: string;
    postalCode: string;
    source: AddressSourceType;
    nextStep: NextStepType;

    get description() : string{
        //extract the text before '- NN Address', which will be displayed at the left side of the template
        let pattern = new RegExp(/\d Addresses$/i);
        if(pattern.test(this._description)){
            let orgValue : string = this._description;
            let lastDashPos = orgValue.lastIndexOf(("-"))
            let firstPart = orgValue.substr(0, lastDashPos);
            return lastDashPos == -1 ? orgValue : firstPart;
        }else {
            return this._description;
        }
    }

    get moreAddressIndicator() : string{
        //extract the optional text like '- NN Address', which will be displayed at the right side of the template, which is the indicator of do another step of
        // search (FIND)
        let pattern = new RegExp(/\d Addresses$/i);
        if(pattern.test(this._description)){
            let orgValue : string = this._description;
            let lastDashPos = orgValue.lastIndexOf(("-"))
            let lastPart = orgValue.substr(lastDashPos+1);
            return lastDashPos == -1 ? "" : lastPart + '\xa0\u2771';
        }else {
            return "";
        }
    }

    calculateNeededSpaces(descriptionLength : number, maxLength : number) : string{
        var filler = new Array(maxLength - descriptionLength).join('\xa0');
        return filler;
    }

    set description(value : string){
        this._description = value;
    }
}
