import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {TabsService} from '../../../../core';
import {DialogRef} from 'ngx-modialog-7';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import { AppConfig } from '../../../../shared-main/app-configuration';


export class AssystPayoutMessagesModalContext extends BSModalContext{
    fieldsUpdatedAfterAssystPayoutSubmission: string [];
}

@Component({
    selector: 'dp-assyst-payout-messages-modal-content',
    templateUrl: 'assyst-payout-messages.modal.component.html',
    providers   : [ErrorService]
})
export class AssystPayoutMessagesModalComponent  implements OnInit{

    @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
    context: AssystPayoutMessagesModalContext;

    constructor(public dialog: DialogRef<AssystPayoutMessagesModalContext>,
                public errorService: ErrorService,
                public dialogService : DialogService, public teranetService: TeranetService,
                public tabsService : TabsService,
                public appConfig : AppConfig) {
        this.context = dialog.context;
    }

    ngOnInit() {}

    close() {
        this.dialog.close();
    }

}
