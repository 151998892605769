import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {BillingIssue} from './billing-issue';

export type MatterBillingStatus = 'BILLED' | 'UNBILLED' | 'PREBILLED';

export class MatterBilling extends BaseEntity{
    requestId: string;
    orderNumber: string;
    transactionCharge: number;
    federalTax: number;
    provincialTax: number;
    transactionType: string;
    createdTimeStamp: string;
    billingStatus: MatterBillingStatus;

    isTeranetConnectTransaction() : boolean {
        return this.transactionType == TransactionType.TERANETCONNECTFEES;
    }

    isBilledOrPrebilled() : boolean {
        return this.billingStatus == 'BILLED' || this.billingStatus == 'PREBILLED';
    }

    isBilled() : boolean {
        return this.billingStatus == 'BILLED' ;
    }

    isTeranetConnectTransactionBilledOrPrebilled() : boolean {
        return this.isTeranetConnectTransaction() && this.isBilledOrPrebilled();
    }

    isTeranetConnectTransactionBilled() : boolean {
        return this.isTeranetConnectTransaction() && this.isBilled();
    }

}

export const TransactionType = {
    CHARGE : 'CHARGE',
    REFUND : 'REFUND',
    TERANETCONNECTFEES : 'TERANET_CONNECT_FEES'
};

export class MatterBillingResult extends BaseEntity{
    successful: boolean;
    billingIssues: BillingIssue;
    matterBilling: MatterBilling;

    constructor(matterBillingResult?: MatterBillingResult) {
        super(matterBillingResult);
        if(matterBillingResult){
            if(matterBillingResult.matterBilling){
                this.matterBilling = new MatterBilling(matterBillingResult.matterBilling);
            }
            if(matterBillingResult.billingIssues){
                this.billingIssues = new BillingIssue(matterBillingResult.billingIssues);
            }
        }
    }

}
