import {BaseEntity} from '../shared/BaseEntity/base-entity';

export type MeetingParticipantType =  'CONSENTED_SPOUSE' | 'VENDOR' | 'MORTGAGOR' | 'PURCHASER' | 'GUARANTOR' | 'OFFEROR'| 'OTHERPARTY_LAWCLERK'
    | 'REALESTATEAGENT' | 'OTHERPARTY_REALESTATEAGENT' | 'SURVEYOR' | 'OTHERPARTY_SOLICITOR' | 'MORTGAGE_SOLICITOR' | 'STAFF_PARTICIPANT'
    | 'MORTGAGEE' | 'PRIVATE_LENDER' | 'TESTATOR';

export class MeetingParticipant extends BaseEntity {
    id: number;
    name: string; //UI Only
    namePrefix: string; //UI Only - extra text added to the start or the name. For example ( Corp Name - Signing Officer#1 )
    participantLabel: string; //UI Only - For Example (Guarantors, Client, Listing Agent ...)
    tabLabel: string; //UI Only - For Example (Guarantors, Client, Listing Agent ...)
    email: string; //UI Only
    participantIdOnMatter: number; // This can be contact.id for most matter participants or contactInfo.id for 'OTHERPARTY_SOLICITOR'
    meetingParticipantRole: string; //Always 'INVITEE'
    meetingParticipantType: MeetingParticipantType; // This is the matter participant role or 'OTHERPARTY_SOLICITOR'
    subContactIndex: number;
    deleted: boolean; // Backend doesn't filter the deleted meetingParticipant. UI uses this flag for filter and presents the warning message.
    virtualMeetingLink: string; //each participant has a unique meeting link
    participantUpdated: boolean;
    mobileNumber:string;

    isStaffParticipant() : boolean {
        return this.meetingParticipantType == 'STAFF_PARTICIPANT'
    }

}
