import {Contact} from './contact';
import * as _ from 'lodash';

export class PrivateContacts {
    sourcePersons : Contact[];

    constructor(sourcePersons : Contact[]) {
        this.sourcePersons = sourcePersons;

    }

    get persons(): Contact[] {
        if(this.sourcePersons && this.sourcePersons.length > 0) {
            return this.sourcePersons.filter((c : Contact) => {
                return (c && (c.contactType === 'PRIVATE_CONTACT' || c.contactType === 'PRIVATE_CONTACT_ORGANIZATION'));
            });
        } else {
            return [];
        }
    }

    clearPersons(): void {
        if(this.persons) {
            this.persons.forEach((c : Contact) => {
                this.deletePrivateContact(c);
            });
        }
    }

    addOrUpdatePrivateContact(c: Contact) {
        let privateContactIndex : number = _.findIndex(this.sourcePersons, privateContactObj => privateContactObj === c);
        if(privateContactIndex < 0) {
            this.addPrivateContact(c);
        } else {
            this.updatePrivateContact(c, privateContactIndex);
        }
    }

    addPrivateContact(c: Contact) {
        this.sourcePersons.push(c);
    }

    deletePrivateContact(c: Contact) {
        let privateContactIndex : number = _.findIndex(this.sourcePersons, privateContactObj => privateContactObj === c);
        this.sourcePersons.splice(privateContactIndex, 1);
    }

    updatePrivateContact(c: Contact, index: number) {
        this.sourcePersons.splice(index, 1, c);
    }
}
