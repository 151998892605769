import {HttpClient} from '../../core';
import {Injectable} from '@angular/core';
import {userAccountProfilesApi} from '../../admin/shared/user-account-profiles-api';


@Injectable()
export class ForgotPasswordService {

    constructor(private http : HttpClient) { }

    resetPassword(email, loginId, publicAccountId) {
        let data = {
            "email": email,
            "loginId": loginId,
            "publicAccountId": publicAccountId
        };
        return this.http.post(userAccountProfilesApi.forgotPassword, JSON.stringify(data))
                   .map((res ) => {
                       return res["User"];
                   });
    }
}

