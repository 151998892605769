import {AfterViewInit, Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
               selector: '[ngModel][dp-phone-number]',
               host: {
                   '(input)': 'onInputChange($event)'
               }
           })

export class PhoneNumberDirective  implements OnInit, AfterViewInit {

    constructor(private model: NgModel,  private elementRef: ElementRef) {
    }

    ngOnInit() : void {
    }

    ngAfterViewInit() : void {
        if (this.model.model) {
            setTimeout( ()=> {
                // If there is not timeout, the view will not be update
                this.onValueChange(this.model.model, false)
            }, 0)
        }
    }

    onInputChange($event): void{
        //If it is delete action, do nothing to figure out the following issue.
        //If delete '-' and continue the following code, it will always put '-' back.
        //So it stops deleting action to work well.
        if('deleteContentBackward' == $event.inputType){
            return
        }
        //Before timeout, the viewModel value doesn't get the latest value
        setTimeout(_ => {
            this.onValueChange(this.model.viewModel, true);
        }, 0)
    }

    getFormatPhoneNumberValue(phoneNumber : string): string{
        if(phoneNumber && phoneNumber.length > 0){
            const group = phoneNumber.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            return group[1] + (group[1].length == 3 ? '-' : '')+ group[2] + (group[2].length == 3 ? '-' : '')  + group[3] ;
        } else{
            return phoneNumber;
        }
    }
    private onValueChange (value : string, resetCursor : boolean) : void {
        // No need to process empty string
        if(value == null || value === "") {
            return;
        }

        const strVal: string = String(value.trim());
        if(!strVal){
            if(value != strVal){
                this.model.valueAccessor.writeValue(strVal);
                //Use timeout to set the ngModel value to avoid the value isn't changed to the viewModel by angular system
                setTimeout(_ => {
                    this.model.viewToModelUpdate(strVal);
                }, 0);
            }
        } else {
            const filteredValue = strVal.replace(/\D/g, "");
            let phoneNumberView = this.getFormatPhoneNumberValue(filteredValue);
            let elm = this.elementRef.nativeElement;
            let startPos = elm.selectionStart;
            let endPos = elm.selectionEnd;
            if(resetCursor) {
                // 3 means it will add the first '-' position
                // 7 means it will add the second '-' position eg.647-123
                if(startPos == 3 || startPos == 7) {
                    startPos++;
                    endPos++
                }
            }
            if(phoneNumberView != value){
                this.model.valueAccessor.writeValue(phoneNumberView);
            }
            if(resetCursor) {
                //Put cursor to correct position after add '-'
                elm.setSelectionRange(startPos, endPos);
            }
            setTimeout(_ => {
                if(value != filteredValue) {
                    this.model.viewToModelUpdate(filteredValue);
                }
            }, 0)
        }
    }

    @HostListener('blur') onBlur(): void {
        this.onValueChange(this.model.viewModel, false);
    }
}
