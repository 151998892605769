import {OtherContactInformation} from './other-contact-information';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DefaultFieldsService} from '../default-fields.service';

interface ConfigurationOption {
    configurationOptionId: number;
    configurationValue: string;
    label: String;
}

@Component({
    selector: 'dp-other-contact-info-fields',
    templateUrl: './other-contact-info-fields.components.html',
    providers: [DefaultFieldsService]
})
export class OtherContactInfoFieldsComponent implements OnInit {
    @Input('disabled') disabled: boolean = false;
    @Input() closeShutter: boolean ;
    @Input() usercloseShutter : boolean;
    @Input() otherContactInfoFields: OtherContactInformation[];
    @Output() onChange: EventEmitter<OtherContactInformation> = new EventEmitter<OtherContactInformation>();
    @Output() onShutterChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input('offsetShutter') offsetShutter: boolean = false;
    @Input('offsetShutterOther') offsetShutterOther: boolean = false;
    @Input('primaryShutter') primaryShutter: boolean = false;
    @Input('primaryShutterOther') primaryShutterOther: boolean = false;
    @Input('secondaryShutter') secondaryShutter: boolean = false;

    openFormFlag: boolean = false;

        otherContactOptions: {
        label: String,
        value: String,
    }[] = [];


    constructor(public defaultFieldsService: DefaultFieldsService) {
    }

    ngOnInit(): void {
        this.defaultFieldsService.getLabels().subscribe(
            (res) => {
                res.forEach(item => {
                    this.otherContactOptions.push({label : item.label, value : item.configurationValue});
                });
            });
        this.openFormFlag = !this.closeShutter && !(Array.isArray(this.otherContactInfoFields)
        && this.otherContactInfoFields.length !== 0
                            && this.otherContactInfoFields.some(
                                (item: OtherContactInformation) =>  !!item.value ));
    }

    get openForm() : boolean {
        return this.usercloseShutter === undefined ? this.openFormFlag : !this.usercloseShutter;
    }

    emitChange(event) : void {
        this.onChange.emit(event);
    }

    getCount(): number {
        return Array.isArray(this.otherContactInfoFields)
                    ? this.otherContactInfoFields.reduce((count: number, item: OtherContactInformation): number => {
                        return count + (item.value ? 1 : 0);
            }, 0)
                    : 0;
    }

    emitShutterClicked(event) : void {
        this.onShutterChange.emit(event);
    }
}
