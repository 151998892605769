import {Component, ElementRef, Input, OnInit} from '@angular/core';


@Component({
    selector: 'dp-checkbox',
    template: `<ng-content></ng-content><label [attr.tabindex]="hasTabIndex ? '0' : '-1'" [attr.for]="fieldId" [ngClass]="fieldClass"
                                               [attr.title]="titleText ? titleText : null"
                                               (keydown)="handleKeyboardToggle($event);"></label>`
})
export class DPPMCheckBoxComponent implements OnInit {

    constructor(public elt:ElementRef) {
    }


    @Input() fieldId: string;
    @Input() titleText: string;
    @Input('hasTabIndex') hasTabIndex: boolean = true;
    @Input() fieldClass: string;

    ngOnInit(): void {

    }


    handleKeyboardToggle(e){
        if (e.keyCode == 0 || e.keyCode == 32 || e.keyCode == 120){
            e.preventDefault();
           // console.log(this.elt.nativeElement.childNodes[0]);
            if(this.elt.nativeElement.childNodes[0].type === "checkbox"){
                this.elt.nativeElement.childNodes[0].click();
            } else {
                this.elt.nativeElement.childNodes[1].click();

            }
        }
    }

}
