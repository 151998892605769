import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DPPMCheckBoxComponent} from './checkbox.component';
import {DPPMMultiSelectComponent} from './multi-select.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {DPPMInfiniteScrollMultiSelectComponent} from './is-multi-select.component';
import {ClipboardModule} from 'ngx-clipboard';

@NgModule({
imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    ClipboardModule
],
declarations: [
    DPPMCheckBoxComponent,
    DPPMMultiSelectComponent,
    DPPMInfiniteScrollMultiSelectComponent
],
exports: [
    DPPMCheckBoxComponent,
    DPPMMultiSelectComponent,
    DPPMInfiniteScrollMultiSelectComponent
],
providers: [
]
})
export class DPPMCheckBoxModule { }
