
// client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe"
// a male client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe". Example of a female client with a first name of "Jane Mary", middle name of "Fredericka" and last name of "Doe"
// FLFN = FirstLetterFirstName
// FLMN = FirstLetterMiddletName
// FN = FirstName
// LN = LastName

export class EnvelopeSalutationFormats {
    public static readonly NO_DEFAULT : EnvelopeSalutationFormatType = "NO_DEFAULT";
    public static readonly MR_FLFN_LN : EnvelopeSalutationFormatType = "MR_FLFN_LN";
    public static readonly MR_FN_LN : EnvelopeSalutationFormatType = "MR_FN_LN";
    public static readonly MR_LN : EnvelopeSalutationFormatType = "MR_LN";

    public static readonly FN_LN : EnvelopeSalutationFormatType = "FN_LN";
    public static readonly SIR : EnvelopeSalutationFormatType = "SIR";

    public static readonly MS_FLFN_LN : EnvelopeSalutationFormatType = "MS_FLFN_LN";
    public static readonly MS_FN_LN : EnvelopeSalutationFormatType = "MS_FN_LN";

    public static readonly MRS_FLFN_LN : EnvelopeSalutationFormatType = "MRS_FLFN_LN";
    public static readonly MRS_FN_LN : EnvelopeSalutationFormatType = "MRS_FN_LN";

    public static readonly MISS_FLFN_LN : EnvelopeSalutationFormatType = "MISS_FLFN_LN";
    public static readonly MISS_FN_LN : EnvelopeSalutationFormatType = "MISS_FN_LN";

    public static readonly FN_FLMN_LN : EnvelopeSalutationFormatType = "FN_FLMN_LN";

    public static readonly MFN_FFN : EnvelopeSalutationFormatType = "MFN_FFN";

    public static readonly ESTATE_OF_FN_LN : EnvelopeSalutationFormatType = "ESTATE_OF_FN_LN";

    public static readonly ORGANIZATION_NAME : EnvelopeSalutationFormatType = "ORGANIZATION_NAME";

    public static readonly MS_LN : EnvelopeSalutationFormatType = "MS_LN";
    public static readonly MRS_LN : EnvelopeSalutationFormatType = "MRS_LN"
    public static readonly MISS_LN : EnvelopeSalutationFormatType = "MISS_LN";
    public static readonly MR_AMP_MRS_LN : EnvelopeSalutationFormatType = "MR_AMP_MRS_LN";

    public static readonly FN : EnvelopeSalutationFormatType = "FN";
    public static readonly MADAM : EnvelopeSalutationFormatType = "MADAM";
    public static readonly DEAR_SIR_OR_MADAM : EnvelopeSalutationFormatType = "DEAR_SIR_OR_MADAM";
    public static readonly SIR_OR_MADAM : EnvelopeSalutationFormatType = "SIR_OR_MADAM";

    public static readonly MR_MFLFN_MLN_AMP_MS_FFLFN_FLN : EnvelopeSalutationFormatType = "MR_MFLFN_MLN_AMP_MS_FFLFN_FLN";
    public static readonly MR_MFLFN_MLN_AND_MS_FFLFN_FLN : EnvelopeSalutationFormatType = "MR_MFLFN_MLN_AND_MS_FFLFN_FLN";
    public static readonly MR_MFLFN_MLN_AMP_MRS_FFLFN_FLN : EnvelopeSalutationFormatType = "MR_MFLFN_MLN_AMP_MRS_FFLFN_FLN";
    public static readonly MR_MFLFN_MLN_AND_MRS_FFLFN_FLN : EnvelopeSalutationFormatType = "MR_MFLFN_MLN_AND_MRS_FFLFN_FLN";

    public static readonly MR_MFN_MLN_AMP_MS_FFN_FLN : EnvelopeSalutationFormatType = "MR_MFN_MLN_AMP_MS_FFN_FLN";
    public static readonly MR_MFN_MLN_AND_MS_FFN_FLN : EnvelopeSalutationFormatType = "MR_MFN_MLN_AND_MS_FFN_FLN";
    public static readonly MR_MFN_MLN_AMP_MRS_FFN_FLN : EnvelopeSalutationFormatType = "MR_MFN_MLN_AMP_MRS_FFN_FLN";
    public static readonly MR_MFN_MLN_AND_MRS_FFN_FLN : EnvelopeSalutationFormatType = "MR_MFN_MLN_AND_MRS_FFN_FLN";

    public static readonly MR_MFLFN_MLN_AMP_MR_MFLFN_MLN : EnvelopeSalutationFormatType = "MR_MFLFN_MLN_AMP_MR_MFLFN_MLN";
    public static readonly MR_MFLFN_MLN_AND_MR_MFLFN_MLN : EnvelopeSalutationFormatType = "MR_MFLFN_MLN_AND_MR_MFLFN_MLN";
    public static readonly MRS_MFLFN_MLN_AMP_MRS_MFLFN_MLN : EnvelopeSalutationFormatType = "MRS_MFLFN_MLN_AMP_MRS_MFLFN_MLN";
    public static readonly MRS_MFLFN_MLN_AND_MRS_MFLFN_MLN : EnvelopeSalutationFormatType = "MRS_MFLFN_MLN_AND_MRS_MFLFN_MLN";
    public static readonly MISS_MFLFN_MLN_AMP_MISS_MFLFN_MLN : EnvelopeSalutationFormatType = "MISS_MFLFN_MLN_AMP_MISS_MFLFN_MLN";
    public static readonly MISS_MFLFN_MLN_AND_MISS_MFLFN_MLN : EnvelopeSalutationFormatType = "MISS_MFLFN_MLN_AND_MISS_MFLFN_MLN";

    public static readonly MR_MFN_MLN_AMP_MR_MFN_MLN : EnvelopeSalutationFormatType = "MR_MFN_MLN_AMP_MR_MFN_MLN";
    public static readonly MR_MFN_MLN_AND_MR_MFN_MLN : EnvelopeSalutationFormatType = "MR_MFN_MLN_AND_MR_MFN_MLN";
    public static readonly MRS_MFN_MLN_AMP_MRS_MFN_MLN : EnvelopeSalutationFormatType = "MRS_MFN_MLN_AMP_MRS_MFN_MLN";
    public static readonly MRS_MFN_MLN_AND_MRS_MFN_MLN : EnvelopeSalutationFormatType = "MRS_MFN_MLN_AND_MRS_MFN_MLN";
    public static readonly MISS_MFN_MLN_AMP_MISS_MFN_MLN : EnvelopeSalutationFormatType = "MISS_MFN_MLN_AMP_MISS_MFN_MLN";
    public static readonly MISS_MFN_MLN_AND_MISS_MFN_MLN : EnvelopeSalutationFormatType = "MISS_MFN_MLN_AND_MISS_MFN_MLN";


    public static readonly SIRS_OR_MADAMS : EnvelopeSalutationFormatType = "SIRS_OR_MADAMS";

    public static readonly MR_AMP_MS_MFLFN_MLN : EnvelopeSalutationFormatType = "MR_AMP_MS_MFLFN_MLN";
    public static readonly MR_AND_MS_MFLFN_MLN : EnvelopeSalutationFormatType = "MR_AND_MS_MFLFN_MLN";
    public static readonly MR_AMP_MRS_MFLFN_MLN : EnvelopeSalutationFormatType = "MR_AMP_MRS_MFLFN_MLN";
    public static readonly MR_AND_MRS_MFLFN_MLN : EnvelopeSalutationFormatType = "MR_AND_MRS_MFLFN_MLN";

    public static readonly MR_AMP_MS_MFLFN_AMP_FFLFN_MLN : EnvelopeSalutationFormatType = "MR_AMP_MS_MFLFN_AMP_FFLFN_MLN";
    public static readonly MR_AND_MS_MFLFN_AMP_FFLFN_MLN : EnvelopeSalutationFormatType = "MR_AND_MS_MFLFN_AMP_FFLFN_MLN";
    public static readonly MR_AND_MS_MFLFN_AND_FFLFN_MLN : EnvelopeSalutationFormatType = "MR_AND_MS_MFLFN_AND_FFLFN_MLN";

    public static readonly MR_AMP_MRS_MFLFN_AMP_FFLFN_MLN : EnvelopeSalutationFormatType = "MR_AMP_MRS_MFLFN_AMP_FFLFN_MLN";
    public static readonly MR_AND_MRS_MFLFN_AMP_FFLFN_MLN : EnvelopeSalutationFormatType = "MR_AND_MRS_MFLFN_AMP_FFLFN_MLN";
    public static readonly MR_AND_MRS_MFLFN_AND_FFLFN_MLN : EnvelopeSalutationFormatType = "MR_AND_MRS_MFLFN_AND_FFLFN_MLN";

    public static readonly MR_AMP_MS_MFN_AMP_FFN_MLN : EnvelopeSalutationFormatType = "MR_AMP_MS_MFN_AMP_FFN_MLN";
    public static readonly MR_AND_MS_MFN_AMP_FFN_MLN : EnvelopeSalutationFormatType = "MR_AND_MS_MFN_AMP_FFN_MLN";
    public static readonly MR_AND_MS_MFN_AND_FFN_MLN : EnvelopeSalutationFormatType = "MR_AND_MS_MFN_AND_FFN_MLN";

    public static readonly MR_AMP_MRS_MFN_AMP_FFN_MLN : EnvelopeSalutationFormatType = "MR_AMP_MRS_MFN_AMP_FFN_MLN";
    public static readonly MR_AND_MRS_MFN_AMP_FFN_MLN : EnvelopeSalutationFormatType = "MR_AND_MRS_MFN_AMP_FFN_MLN";
    public static readonly MR_AND_MRS_MFN_AND_FFN_MLN : EnvelopeSalutationFormatType = "MR_AND_MRS_MFN_AND_FFN_MLN";

    public static readonly MFN_AMP_FFN_MLN : EnvelopeSalutationFormatType = "MFN_AMP_FFN_MLN";
    public static readonly MFN_AND_FFN_MLN : EnvelopeSalutationFormatType = "MFN_AND_FFN_MLN";
    public static readonly MFN_MLN_AMP_FFN_FLN : EnvelopeSalutationFormatType = "MFN_MLN_AMP_FFN_FLN";
    public static readonly MFN_MLN_AND_FFN_FLN : EnvelopeSalutationFormatType = "MFN_MLN_AND_FFN_FLN";

    public static readonly MR_AMP_MS_MLN : EnvelopeSalutationFormatType = "MR_AMP_MS_MLN";
    public static readonly MR_AND_MS_MLN : EnvelopeSalutationFormatType = "MR_AND_MS_MLN";
    public static readonly MR_AMP_MRS_MLN : EnvelopeSalutationFormatType = "MR_AMP_MRS_MLN";
    public static readonly MR_AND_MRS_MLN : EnvelopeSalutationFormatType = "MR_AND_MRS_MLN";

    public static readonly MR_MLN_AMP_MS_FLN : EnvelopeSalutationFormatType = "MR_MLN_AMP_MS_FLN";
    public static readonly MR_MLN_AND_MS_FLN : EnvelopeSalutationFormatType = "MR_MLN_AND_MS_FLN";
    public static readonly MR_MLN_AMP_MRS_FLN : EnvelopeSalutationFormatType = "MR_MLN_AMP_MRS_FLN";
    public static readonly MR_MLN_AND_MRS_FLN : EnvelopeSalutationFormatType = "MR_MLN_AND_MRS_FLN";

    public static readonly MFN_AMP_FFN : EnvelopeSalutationFormatType = "MFN_AMP_FFN";
    public static readonly MFN_AND_FFN : EnvelopeSalutationFormatType = "MFN_AND_FFN";
    public static readonly SIR_AMP_MADAM : EnvelopeSalutationFormatType = "SIR_AMP_MADAM";
    public static readonly SIR_AND_MADAM : EnvelopeSalutationFormatType = "SIR_AND_MADAM";

    public static readonly MR_AMP_MRS_MFN_MLN : EnvelopeSalutationFormatType = "MR_AMP_MRS_MFN_MLN";

    public static readonly MFN_MLN : EnvelopeSalutationFormatType = "MFN_MLN";

    public static readonly TITLE_FLFN_LN_JOIN_WITH_AMP : EnvelopeSalutationFormatType = "TITLE_FLFN_LN_JOIN_WITH_AMP";
    public static readonly TITLE_FN_LN_JOIN_WITH_AMP : EnvelopeSalutationFormatType = "TITLE_FN_LN_JOIN_WITH_AMP";
    public static readonly TITLE_FLFN_LN_JOIN_WITH_AND : EnvelopeSalutationFormatType = "TITLE_FLFN_LN_JOIN_WITH_AND";
    public static readonly TITLE_FN_LN_JOIN_WITH_AND : EnvelopeSalutationFormatType = "TITLE_FN_LN_JOIN_WITH_AND";
    public static readonly FN_LN_JOIN_WITH_AMP : EnvelopeSalutationFormatType = "FN_LN_JOIN_WITH_AMP";
    public static readonly FN_LN_JOIN_WITH_AND : EnvelopeSalutationFormatType = "FN_LN_JOIN_WITH_AND";
    public static readonly FN_JOIN_WITH_AMP : EnvelopeSalutationFormatType = "FN_JOIN_WITH_AMP";
    public static readonly FN_JOIN_WITH_AND : EnvelopeSalutationFormatType = "FN_JOIN_WITH_AND";

    public static readonly JOIN_ASSOCIATED_CONTACT : EnvelopeSalutationFormatType = "JOIN_ASSOCIATED_CONTACT";

    public static readonly TITLE_LN_JOIN_WITH_AMP : EnvelopeSalutationFormatType = "TITLE_LN_JOIN_WITH_AMP";
    public static readonly TITLE_LN_JOIN_WITH_AND : EnvelopeSalutationFormatType = "TITLE_LN_JOIN_WITH_AND";

    public static readonly MR_MFLFN_MLN_AMP_FN_LN : EnvelopeSalutationFormatType = "MR_MFLFN_MLN_AMP_FN_LN";
    public static readonly MR_MFLFN_MLN_AND_FN_LN : EnvelopeSalutationFormatType = "MR_MFLFN_MLN_AND_FN_LN";
    public static readonly MR_MFN_MLN_AMP_FN_LN : EnvelopeSalutationFormatType = "MR_MFN_MLN_AMP_FN_LN";
    public static readonly MR_MFN_MLN_AND_FN_LN : EnvelopeSalutationFormatType = "MR_MFN_MLN_AND_FN_LN";

    public static readonly FN_AMP_FN_LN : EnvelopeSalutationFormatType = "FN_AMP_FN_LN";
    public static readonly FN_AND_FN_LN : EnvelopeSalutationFormatType = "FN_AND_FN_LN";
    public static readonly FN_LN_AMP_FN_LN : EnvelopeSalutationFormatType = "FN_LN_AMP_FN_LN";
    public static readonly FN_LN_AND_FN_LN : EnvelopeSalutationFormatType = "FN_LN_AND_FN_LN";
    public static readonly FN_AMP_FN : EnvelopeSalutationFormatType = "FN_AMP_FN";
    public static readonly FN_AND_FN : EnvelopeSalutationFormatType = "FN_AND_FN";

    public static readonly CONCATENATED_VALUE : EnvelopeSalutationFormatType = "CONCATENATED_VALUE";

}

export type EnvelopeSalutationFormatType = 'NO_DEFAULT'
    | 'MR_FLFN_LN'                     | 'MR_FN_LN'                       | 'MR_LN'
    | 'FN_LN'                          | 'FN'                             | 'FN_FLMN_LN'                     | 'MFN_FFN'
    | 'SIR'                            | 'MADAM'
    | 'DEAR_SIR_OR_MADAM'              | 'SIR_OR_MADAM'
    | 'ESTATE_OF_FN_LN'                | 'ORGANIZATION_NAME'
    | 'MS_LN'                          | 'MRS_LN'                         | 'MISS_LN'                        | 'MR_AMP_MRS_LN'
    | 'MS_FLFN_LN'                     | 'MS_FN_LN'
    | 'MRS_FLFN_LN'                    | 'MRS_FN_LN'
    | 'MISS_FLFN_LN'                   | 'MISS_FN_LN'
    | 'MR_MFLFN_MLN_AMP_MS_FFLFN_FLN'  | 'MR_MFLFN_MLN_AND_MS_FFLFN_FLN'  | 'MR_MFLFN_MLN_AMP_MRS_FFLFN_FLN' | 'MR_MFLFN_MLN_AND_MRS_FFLFN_FLN'
    | 'MR_MFN_MLN_AMP_MS_FFN_FLN'      | 'MR_MFN_MLN_AND_MS_FFN_FLN'      | 'MR_MFN_MLN_AMP_MRS_FFN_FLN'     | 'MR_MFN_MLN_AND_MRS_FFN_FLN'
    | 'MR_MFLFN_MLN_AMP_MR_MFLFN_MLN'  | 'MR_MFLFN_MLN_AND_MR_MFLFN_MLN'  | 'MR_MFN_MLN_AMP_MR_MFN_MLN'      | 'MR_MFN_MLN_AND_MR_MFN_MLN'
    | 'MRS_MFN_MLN_AMP_MRS_MFN_MLN'    | 'MRS_MFN_MLN_AND_MRS_MFN_MLN'    | 'MISS_MFN_MLN_AMP_MISS_MFN_MLN'  | 'MISS_MFN_MLN_AND_MISS_MFN_MLN'
    | 'MRS_MFLFN_MLN_AMP_MRS_MFLFN_MLN'| 'MRS_MFLFN_MLN_AND_MRS_MFLFN_MLN'| 'MISS_MFLFN_MLN_AMP_MISS_MFLFN_MLN' | 'MISS_MFLFN_MLN_AND_MISS_MFLFN_MLN'
    | 'SIRS_OR_MADAMS'
    | 'MR_AMP_MS_MFLFN_MLN'            | 'MR_AND_MS_MFLFN_MLN'            | 'MR_AMP_MRS_MFLFN_MLN'           | 'MR_AND_MRS_MFLFN_MLN'
    | 'MR_AMP_MS_MFLFN_AMP_FFLFN_MLN'  | 'MR_AND_MS_MFLFN_AMP_FFLFN_MLN'  | 'MR_AND_MS_MFLFN_AND_FFLFN_MLN'
    | 'MR_AMP_MRS_MFLFN_AMP_FFLFN_MLN' | 'MR_AND_MRS_MFLFN_AMP_FFLFN_MLN' | 'MR_AND_MRS_MFLFN_AND_FFLFN_MLN'
    | 'MR_AMP_MS_MFN_AMP_FFN_MLN'      | 'MR_AND_MS_MFN_AMP_FFN_MLN'      | 'MR_AND_MS_MFN_AND_FFN_MLN'
    | 'MR_AMP_MRS_MFN_AMP_FFN_MLN'     | 'MR_AND_MRS_MFN_AMP_FFN_MLN'     | 'MR_AND_MRS_MFN_AND_FFN_MLN'
    | 'MFN_AMP_FFN_MLN'                | 'MFN_AND_FFN_MLN'                | 'MFN_MLN_AMP_FFN_FLN'            | 'MFN_MLN_AND_FFN_FLN'
    | 'MR_AMP_MS_MLN'                  | 'MR_AND_MS_MLN'                  | 'MR_AMP_MRS_MLN'                 | 'MR_AND_MRS_MLN'
    | 'MR_MLN_AMP_MS_FLN'              | 'MR_MLN_AND_MS_FLN'              | 'MR_MLN_AMP_MRS_FLN'             | 'MR_MLN_AND_MRS_FLN'
    | 'MFN_AMP_FFN'                    | 'MFN_AND_FFN'                    | 'SIR_AMP_MADAM'                  | 'SIR_AND_MADAM'
    | 'MR_AMP_MRS_MFN_MLN'             | 'MFN_MLN'
    | 'TITLE_FLFN_LN_JOIN_WITH_AMP'    | 'TITLE_FN_LN_JOIN_WITH_AMP'      | 'TITLE_FLFN_LN_JOIN_WITH_AND'    | 'TITLE_FN_LN_JOIN_WITH_AND'
    | 'FN_LN_JOIN_WITH_AMP'            | 'FN_LN_JOIN_WITH_AND'            | 'JOIN_ASSOCIATED_CONTACT'
    | 'FN_JOIN_WITH_AMP'               | 'FN_JOIN_WITH_AND'
    | 'TITLE_LN_JOIN_WITH_AMP'         | 'TITLE_LN_JOIN_WITH_AND'
    | 'MR_MFLFN_MLN_AMP_FN_LN'         | 'MR_MFLFN_MLN_AND_FN_LN'         | 'MR_MFN_MLN_AMP_FN_LN'           | 'MR_MFN_MLN_AND_FN_LN'
    | 'FN_LN_AMP_FN_LN'                | 'FN_LN_AND_FN_LN'                | 'FN_AMP_FN_LN'                   | 'FN_AND_FN_LN'
    | 'FN_AMP_FN'                      | 'FN_AND_FN'
    | 'CONCATENATED_VALUE';
