import {Directive, ElementRef, HostBinding, HostListener, Input, Renderer2, SimpleChanges} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {FocusFirstElementModalDecorator} from './focus-first-element-modal-decorator';


declare var jQuery : any;

@Directive({
    selector: '[dp-printable-dialog]'
})

@FocusFirstElementModalDecorator()
export class DpPrintableDialogDirective {

    constructor(private el: ElementRef, private logger : Logger, private renderer: Renderer2) {
    }

    @Input('hide-print') hidePrint : boolean = false;
    @Input('show-export') showExport : boolean = false;

    @HostBinding('class')
    className = 'printable-dialog';

    @HostListener('window:keydown', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.key == "p" && event.ctrlKey) {
            event.preventDefault();
            event.stopPropagation();
            this.printDialog();
        }
    }

    ngAfterViewInit(){
        setTimeout(()=>{ this.createPrintButton();},1000);

        if (this.showExport) {
            setTimeout(()=>{ this.createExportToCSVButton();},1000);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // this will trigger when Input parameters change
        console.log("ngOnChanges | changes:", changes);
    }

    createExportToCSVButton(){
        let headerDiv = document.querySelector(".modal-header .col-lg-12");
        let buttonElement = document.createElement('button');
        let buttonText = document.createTextNode('Export');
        buttonElement.appendChild(buttonText);
        buttonElement.className = "dp-btn focus-first";
        buttonElement.style.backgroundColor = "white";
        buttonElement.style.color = "black";
        buttonElement.setAttribute("id", "dpExportBtnId");
        buttonElement.click();
        headerDiv.appendChild(buttonElement);
        if(this.hidePrint){
            buttonElement.style.visibility = "hidden";
        }
    }


    createPrintButton(){
        let headerDiv = document.querySelector(".modal-header .col-lg-12");
        let buttonElement = document.createElement('button');
        let buttonText = document.createTextNode('Print');
        buttonElement.appendChild(buttonText);
        buttonElement.className = "dp-btn focus-first";
        buttonElement.style.backgroundColor = "white";
        buttonElement.style.color = "black";
        buttonElement.setAttribute("id", "dpPrintBtnId");
        buttonElement.onclick = this.printDialog;
        headerDiv.appendChild(buttonElement);
        if(this.hidePrint){
            buttonElement.style.visibility = "hidden";
        }
    }

    printDialog() {
        let domElement = document.getElementsByClassName("printable-dialog");
        if(domElement.length>0){
            let domClone = domElement[0].cloneNode(true);

            let $printSection = document.getElementById("printSection");

            if (!$printSection) {
                $printSection = document.createElement("div");
                $printSection.id = "printSection";
                document.body.appendChild($printSection);
            }
            $printSection.innerHTML = "";
            $printSection.appendChild(domClone);

            window.print();
            $printSection.innerHTML = "";
        }
    }


}
