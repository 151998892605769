import {Directive, ElementRef, Inject, Input, Renderer2} from '@angular/core';
import {Constants} from './constants';

@Directive({
    selector: '[disableItem]'
})

export class DisableAutoCompleteItemDirective {
    @Input() disableItem: any;

    constructor(@Inject(ElementRef) private element: ElementRef, public renderer : Renderer2) {}

    ngOnInit() {
        let contactString = JSON.stringify(this.disableItem);
        if(contactString.indexOf(Constants.NO_RESULTS_FOUND) > -1) {
            this.renderer.addClass(this.element.nativeElement, 'disabled-cursor-not-allowed');
        }
    }

}
