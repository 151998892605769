/**
 * Created by asvetl01 on 11/9/2016.
 */
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../core';
import {configApi} from './config-api';
import {Injectable} from '@angular/core';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {DialogService} from '../shared/dialog/dialog.service';
import {AppConfig} from '../shared-main/app-configuration';
import {Subject} from "rxjs";

@Injectable()
export class UtilsService {

    private _dndBgImagePathStyle : string =  "url(/assets/img/bg/dnd_bg_image_00.png) no-repeat";

    private openLenderCenterSubject = new Subject();
    openLenderCenterSubject$ = this.openLenderCenterSubject.asObservable();

    constructor(private http: HttpClient,
                public dialogService: DialogService,
                public appConfig: AppConfig) {
    }

    setUserSentimentForAIResponse(partitionkey: string, rowkey: string, fdbk: any) {
        var payload = {
            partitionKey: partitionkey,
            rowKey: rowkey,
            userSentiment: fdbk
        }

        return this.http.put(`${configApi.addSentimentToAIResponse}`, payload, true)
    }

    getTnCAcceptanceFlagForUser() {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let payload = {
            userId: userId
        };
        return this.http.post(`${configApi.getTnCAcceptanceCheckApi}`, payload);
    }

    getFAQDocument() {
        return this.http.get(`${configApi.getFAQLink}`);
    }

    getTermsnConditionsContent() {
        return this.http.get(`${configApi.getTermsConditionsContent}`);
    }

    SaveTnCAcceptanceForUser() {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let payload = {
            userId: userId
        };
        return this.http.post(`${configApi.saveTnCAcceptanceApi}`, payload);
    }

    callAIChatAssitant(querytext: any) {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let payload = {
            prompt: querytext,
            userId: userId
        };
        return this.http.post(`${configApi.chatAIApi}`, payload, true);
    }

    ClearAIChatSession() {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let payload = {
            userId: userId
        };
        return this.http.post(`${configApi.clearchatAISession}`, payload, true);
    }

    getApiVersion(): Observable<any> {
        return this.http.get(`${configApi.apiVersion}`);

    }

    getDoceboConfiguration() : Observable<any> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url = configApi.doceboConfiguration(userId);
        return this.http.get(`${url}`)
                   .map((response) => {
                       if(response && response['DoceboSamlSSO'] ) {
                           let doceboSamlSSO : any = response['DoceboSamlSSO'];
                           return doceboSamlSSO;
                       }
                   });
    }

    getDoceboSamlSSO() : Observable<any> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let sessionId = sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId);
        let url = configApi.doceboSAMLSSOApi(userId,sessionId);
        return this.http.get(`${url}`)
            .map((response) => {
                if(response && response['DoceboSamlDetails'] ) {
                    let doceboSamlDetails : any = response['DoceboSamlDetails'];
                    return doceboSamlDetails;
                }
            });
    }


    getUnityStatusUrl(){
        return 'https://unity.status.io/';
    }

    getUnityDriveInstallUrl(): Observable<string> {
        return this.http.get(`${configApi.unityDriveInstallParam}`)
            .map((res) => {
                if (res && res['UnityDriveInstall']) {
                    let params = res['UnityDriveInstall'];
                    return params && params["url"];
                }
            });
    }

    set dndBgImagePathStyle(bgImagePathStyle : string) {
        this._dndBgImagePathStyle = bgImagePathStyle;
    }

    get dndBgImagePathStyle() : string{
        return  this._dndBgImagePathStyle;
    }

    get currentYear() : number {
        return  new Date().getFullYear();
    }

    isMatterTypeDisabled(matterTypeCode: string){
        if(this.appConfig.isHiddenForBCProvince && (matterTypeCode === 'PURCHASE'
            || matterTypeCode === 'SALE'
            || matterTypeCode === 'MORTGAGE'
            || matterTypeCode === 'DISCHARGE')){
            return true;
        }
        return false;
    }

    openLenderCenter() {
      this.openLenderCenterSubject.next();
    }
}


