/**
 * Data transfer object (NOT entity) for retrieving information about duplicate Plan/Block/Lot in a project
 */
export class DuplicateAddressMatterInfo{
    matterRecordNumber: string;
    purchaserReline: string;
    streetAddress : string;
    uiStreetAddress : string;
    projectRecordNumber : string;


    //Not an entity, defining the constructor explicitly
    constructor(json?) {
        if(json) {
            for(let prop in json) {
                if(json.hasOwnProperty(prop)) {
                    this[prop] = json[prop];
                }
            }
        }

    }

}
