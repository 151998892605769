import {Component, ElementRef, ViewChild} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogService} from '../../shared/dialog/dialog.service';
import {PasswordRegexConstant} from '../../login/first-login/setup-password/password-regex-constant';
import {User} from '../../matters/shared/user';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {messages} from '../../common/messages';
import {UserProfilesService} from '../../shared-main/user-profiles.service';
import {DPError} from '../../shared/error-handling/dp-error';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ConfirmPasswordModalComponent} from '../confirm-password-modal/confirm-password.modal.component';
import {TrustedPhoneNumberService} from '../../login/first-login/trusted-phone-number/trusted-phone-number.service';
import {AuthChallenge, AuthChallengePhone} from '../../login/first-login/trusted-phone-number/trusted-phone-number';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {ApplicationError} from '../../core';

declare var jQuery : any;

const sendPasscodeTimeOut = 15000; //15 seconds

export const credentialSectionTitles = {
    changePassword : 'Change Password',
    emailAndUserID : 'Email and User ID',
    manageTrustedPhones : 'Manage Trusted Phone(s)'
};

export class UnityCredentialSection extends BaseEntity {
    title : string;
    active : boolean;

    isChangePassword() : boolean {
        return (this.title == credentialSectionTitles.changePassword);
    }
    isEmailAndUserId() : boolean {
        return (this.title == credentialSectionTitles.emailAndUserID);
    }
    isTrustedPhones() : boolean {
        return (this.title == credentialSectionTitles.manageTrustedPhones);
    }
}


export const credentialSections : any[] = [
        {
            title         : credentialSectionTitles.changePassword,
            active        : false,
        },
        {
            title         : credentialSectionTitles.emailAndUserID,
            active        : false,
        },
        {
            title         : credentialSectionTitles.manageTrustedPhones,
            active        : false,
        }

    ];


class UpdateCredentialsContext extends BSModalContext {
    user: User;
}

@Component({
               selector    : 'dp-update-credentials-modal-content',
               templateUrl : 'update-credentials.modal.component.html',
               styleUrls: ['./update-credentials.modal.scss'],
               providers : [ErrorService]
           })

@FocusFirstElementDecorator()

export class UpdateCredentialsModal implements   ModalComponent<UpdateCredentialsContext> {

    @ViewChild('modalErrorComponent') modalErrorComponent : ModalErrorComponent;

    context : UpdateCredentialsContext;
    errorMessages : string[] = [];
    errorMessagesReType : string[] = [];
    user : User;
    loginId : string;
    emailId: string;
    pwdsDoNoMatch: boolean = false;
    unityCredentialSections: UnityCredentialSection[];
    activeSection : UnityCredentialSection;
    trustedPhones: AuthChallengePhone[] = [];
    newPhone: AuthChallengePhone;
    passcodeText : string;
    showPasscode: boolean = false;
    passcode: string;
    isSendPasscodeTimeout: boolean = false;
    @ViewChild('newPassword') newPasswordField: ElementRef;
    @ViewChild('emailField') emailField: ElementRef;
    @ViewChild('newPhoneNo') newPhoneNoField: ElementRef;



    constructor(public dialog : DialogRef<UpdateCredentialsContext>,
                public errorService : ErrorService,
                public userProfileService : UserProfilesService,
                public dialogService : DialogService,
                public trustedPhoneNumberService : TrustedPhoneNumberService
    ) {
        this.context = dialog.context;

    }

    ngOnInit() {

        this.unityCredentialSections = credentialSections.map(integrationSection => {
                       return new UnityCredentialSection(integrationSection);
                   }
        );
        this.unityCredentialSections[0].active = true;
        this.activeSection = this.unityCredentialSections[0];

        this.user = new User(this.context.user);
        this.resetPasswordFields();
        this.loginId = this.user.loginId;
        this.emailId = this.user.emailId;

        this.trustedPhones = this.user.authChallengePhones.map((phone)=>{
            return new AuthChallengePhone(phone) ;
        });
        this.newPhone = new AuthChallengePhone();

    }


    checkPassword(event) : void {
        this.errorMessages = [];
        if(event.target.value) {
            this.errorMessages = this.verifyPassword(event);
        }
    }

    checkReTypePassword(event) : void {
        this.errorMessagesReType = [];
        if(event.target.value)
        {
            this.errorMessagesReType = this.verifyPassword(event);
            if(this.user.password != this.user.retypePassword)
            {
                this.errorService.addDpFieldError(DPError.createDPError("user.retypePassword.INVALID"));
                this.pwdsDoNoMatch = true;
            }
            else{
                this.errorService.removeDpFieldError("user.retypePassword.INVALID");
                this.pwdsDoNoMatch = false;
            }
        }

    }

    updatePassword = () : void =>{
        if(this.user.validateUpdatePassword(this.errorService))
        {
            this.userProfileService.updateUserPwd(this.user).subscribe((res: User) => {
                this.user = res;
                this.resetPasswordFields();
                let response : any = {};
                response.user = res;
                this.dialogService.confirm('Confirmation', 'Your Password has been successfully changed.', true);

            },
            (error: ApplicationError) => {
                if(error.statusText == 'Forbidden'){
                    this.confirmPassword(this.updatePassword);
                }
                else {
                    if(error && error.errorCode === 'app.passwordAlreadyUsed') {
                        this.errorService.addDpSaveError(DPError.createDPError("user.password.used"));
                    }
                    else if(error && error.errorCode === 'app.userLocked') {
                        let response : any = {};
                        response.error = 'locked';
                        this.dialog.close(response);
                    }
                }


            });
        }

    };

    resetPasswordFields() : void {
        this.user.password = undefined;
        this.user.retypePassword = undefined;
    }

    updateEmailAndUser = () => {
        if(this.validateEmailAndUserId()){
            this.user.loginId = this.loginId;
            this.user.emailId = this.emailId;
            this.userProfileService.updateEmailAndUser(this.user).subscribe((res: User) => {
                if(res){
                    this.user = res;
                    this.resetPasswordFields();
                    let response : any = {};
                    response.user = res;
                    this.dialogService.confirm('Confirmation', 'Your Information has been successfully changed', true);
                }
            },
            (error: ApplicationError)=>{
                if(error.statusText == 'Forbidden'){
                    this.confirmPassword(this.updateEmailAndUser);
                }
                else {
                    if(error.errorCode == 'app.alreadyExistsUserId'){
                        this.errorService.addDpSaveError(DPError.createCustomDPError("user.id",error.message,'ERROR','ERROR'));
                    } else if(error.errorCode == 'app.duplicateStaffProfileEmail') {
                        this.errorService.addDpSaveError(DPError.createCustomDPError("staffProfile.contact.email",error.message,'ERROR','ERROR'));
                    } else if(error.errorCode == 'app.duplicateStaffProfileEmailAndUserId') {
                            this.errorService.addDpSaveError(DPError.createCustomDPError("user.id",'User ID already exists.','ERROR','ERROR'));
                            this.errorService.addDpSaveError(DPError.createCustomDPError("staffProfile.contact.email",'Email already exists.','ERROR','ERROR'));
                        } else {
                                this.errorService.addDpSaveError(DPError.createCustomDPError("serverError",error.message,'ERROR','ERROR'));
                            }
                    }
            });

        }
    };

    confirmPassword(retryFn : any, param? : AuthChallengePhone): void {
        this.dialogService.content({
                                       content       : ConfirmPasswordModalComponent,
                                       context       : {
                                           user         : this.context.user
                                       },
                                       modalGrid     : 5,
                                       onFulfillment : (response : any) => {
                                           if(response){
                                               param ? retryFn(param) : retryFn();

                                           }
                                           if(response && response.error) {

                                           }
                                       }
                                   });
    }

    validateEmailAndUserId() : boolean {
        this.errorService.clearAllSaveErrors();
        if(!this.loginId){
            this.errorService.addDpSaveError(DPError.createDPError("user.id"));
        }
        if(!this.emailId){
            this.errorService.addDpSaveError(DPError.createDPError("staffProfile.contact.email.MISSING"));
        }
        return this.errorService.hasNoErrors();
    }

    close() : void {
        this.dialog.close();
    }

    verifyPassword(event) : string[] {

        let pwdErrorMessages = [];

        let pwd = event.target.value;

        let allowedChars : RegExp = PasswordRegexConstant.ALLOWED_CHARS_REGEX;
        let oneUppercase : RegExp = PasswordRegexConstant.ONE_UPPER_CASE_REGEX;
        let oneNumber : RegExp = PasswordRegexConstant.ONE_NUMBER_CASE_REGEX;
        let oneSpecial : RegExp = PasswordRegexConstant.ONE_SPECIAL_REGEX;
        let consecChars : RegExp = PasswordRegexConstant.CONSEC_CHAR_CHECK_REGEX;

        if(pwd.length < 8) {
            pwdErrorMessages.push(messages.passwordErrorMessages.pwd8CharacterLimit);
        }

        if(!allowedChars.test(pwd)) {
            pwdErrorMessages.push(messages.passwordErrorMessages.pwdSpecialCharacter);
        }

        if(!oneUppercase.test(pwd)) {
            pwdErrorMessages.push(messages.passwordErrorMessages.pwd1UpperCaseLetter);
        }

        if(!oneNumber.test(pwd)) {
            pwdErrorMessages.push(messages.passwordErrorMessages.pwd1Number);
        }

        if(!oneSpecial.test(pwd)) {
            pwdErrorMessages.push(messages.passwordErrorMessages.pwd1SpecialCharacter);
        }

        if(consecChars.test(pwd)) {
            pwdErrorMessages.push(messages.passwordErrorMessages.pwd2SameCharacter);
        }

        return pwdErrorMessages;
    }


    checkNewPasswordValidity = () =>  {
        return !(this.errorMessages && this.errorMessages.length > 0)
    };
    checkReEnterPasswordValidity = () =>  {
        return !this.pwdsDoNoMatch;
    };

    openCredentialSection(section: UnityCredentialSection) : void {
        this.errorService.clearAllSaveErrors();
        this.errorService.removeAllDpFieldError();
        this.unityCredentialSections.map(item => {
            item.active = false;
            return item;
        });
        section.active = true;
        this.activeSection = section;
        setTimeout(()=>{
            this.activeSection.isChangePassword() ? this.validateChangePasswordSection() :
            (this.activeSection.isEmailAndUserId() ? this.emailField.nativeElement.focus() : this.newPhoneNoField && this.newPhoneNoField.nativeElement.focus());
        },100);


    }

    validateChangePasswordSection(): void {
        this.newPasswordField.nativeElement.focus();
        this.user.retypePassword = undefined;
    }

    requestAuthChallenge = () : void => {
        this.errorService.clearAllSaveErrors();
        this.showPasscode = false;
        this.passcode = null;
        if(this.validPhoneNumber()){
            this.passcodeText = null;
            let authChallenge = new AuthChallenge();
            authChallenge.phoneNumber = this.newPhone.telephoneNumber;
            this.trustedPhoneNumberService.requestAuthChallenge(authChallenge, null)
                .subscribe((res)=>{
                    if(res){
                        this.showPasscode = true;
                        this.isSendPasscodeTimeout = true;
                        setTimeout(()=>{
                            this.isSendPasscodeTimeout = false;
                        }, sendPasscodeTimeOut);
                        setTimeout(()=>{
                            jQuery('#code').focus();
                        }, 100);

                    }
                },
           (error: ApplicationError)=>{
               if(error.statusText == 'Forbidden'){
                   this.confirmPassword(this.requestAuthChallenge);
               } else {
                   this.errorService.addDpSaveError(DPError.createCustomDPError("serverError",error.errorMessage,'ERROR','ERROR'));
               }
           });

        }
    };

    validPhoneNumber(): boolean {
        this.errorService.clearAllSaveErrors();
        if(this.newPhone && this.newPhone.telephoneNumber.length != 10){
            this.errorService.addDpSaveError(DPError.createDPError("user.newPhone"));
        }
        return this.errorService.hasNoErrors();
    }

    validPasscode(): boolean {
        this.errorService.clearAllSaveErrors();
        if(!this.passcode){
            this.errorService.addDpSaveError(DPError.createDPError("user.passcode"));
        }
        return this.errorService.hasNoErrors();
    }

    confirmDeletePhoneNumber(phone: AuthChallengePhone) : void {
        this.errorService.clearAllSaveErrors();
        if(this.trustedPhones && this.trustedPhones.length < 2){
            this.errorService.addDpSaveError(DPError.createDPError("user.newPhone.INVALID"));
        }
        else if(!this.newPhone.telephoneNumber && phone){
            let telephone = phone.telephoneNumber.length > 4 ? phone.telephoneNumber.substring(6) : phone.telephoneNumber;
            this.dialogService.confirm('Confirmation', 'Are you sure you want to delete the Trusted Phone Number ending with ' +
                telephone +'?', false, 'Delete').subscribe(res => {
                if(res) {
                    this.deletePhoneNumber(phone);
                }
            });
        }
    }

    deletePhoneNumber = (phone: AuthChallengePhone) : void => {
        this.errorService.clearAllSaveErrors();
        this.trustedPhoneNumberService.deletePhoneNumber(String(phone.id))
            .subscribe((res)=>{
                if(res){
                    (<any>this.trustedPhones).remove(phone);
                    setTimeout(()=>{
                        this.newPhoneNoField.nativeElement.focus()
                    },100);
                }
            }, (error: ApplicationError) => {
                if(error.statusText == 'Forbidden'){
                    this.confirmPassword(this.deletePhoneNumber, phone);
                } else {
                    this.errorService.addDpSaveError(DPError.createCustomDPError("serverError",error.errorMessage,'ERROR','ERROR'));
                }
            });
    };

    verifyAuthChallenge = (): void =>{
        this.errorService.clearAllSaveErrors();
        if(this.validPasscode()){
            let authChallenge = new AuthChallenge();
            authChallenge.authChallengeCode = this.passcode;
            authChallenge.phoneNumber = this.newPhone.telephoneNumber;
            this.trustedPhoneNumberService.verifyAuthChallengeAndSave(authChallenge)
                .subscribe((res : User)=>{
                    if(res){
                        this.resetPage();
                        this.user = res;
                        this.resetPasswordFields();
                        this.trustedPhones = this.user.authChallengePhones.map((phone)=>{
                            return new AuthChallengePhone(phone) ;
                        });
                    }
                }, (err) => {
                    if(err.errorCode == 'app.authChallenge.codeExpired'){
                        this.errorService.addDpSaveError(DPError.createDPError("user.passcode.MISSING"));
                        this.passcode = null;
                    } else if(err.errorCode == "app.authChallenge.incorrectCode"){
                        this.errorService.addDpSaveError(DPError.createDPError("user.passcode.INVALID"));
                        this.passcode = null;
                    } else {
                        this.confirmPassword(this.verifyAuthChallenge);
                    }

                });
        }

    };

    get disableSendPasscodeButton() : boolean{
        return (this.newPhone && !this.newPhone.telephoneNumber) || (this.newPhone && this.newPhone.telephoneNumber && this.newPhone.telephoneNumber.length < 10)
    }

    resetPage(): void {
        this.newPhone.telephoneNumber = null;
        this.passcode = null;
        this.showPasscode = false;
    }

    ngAfterViewInit() {}
}


