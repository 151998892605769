import {BaseEntity} from '../shared/BaseEntity/base-entity';
import {Address, Utils as SharedUtils} from '../matters/shared';
import {EventTypes, StaffAvailabilityType} from './event.constants';
import {eventDropDowns} from './event-dropdown';
import {UUIDUtil} from '../main/uuid-util';
import {SigningEnvelope} from './signing-envelope';
import {maxFieldLength} from '../matters/shared/max-field-length';
import moment from 'moment';
import {MeetingParticipant} from './meeting-participant';
import {DigitalSignaturePlatform, DigitalSignaturePlatformLabel} from '../shared-main/constants';
import {PublicHolidayService} from '../shared-main/public-holiday/public-holiday.service';
import Utils from '../shared-main/utils';
import {WorkItemTaskStatusType, WorkItemTaskStatusValues} from '../matters/shared/matter-work-item';
import {SESSION_STORAGE_KEYS} from '../shared';
import {PROVINCE_CODES} from '../matters/shared/user-province';

export type EventType = 'CLOSING' | 'REQUISITION' | 'OCCUPANCY' | 'UNDERTAKING' | 'SUPPLEMENTAL' | 'APPOINTMENT' | 'HOLDBACK' | 'WORKITEMTASK' | 'COMPLETION' | 'POSSESSION';
export type EventStatus = 'COMPLETED' | 'PENDING' | 'CLOSED' | 'UNSPECIFIED' | 'PERFORMED' | 'CANCELLED' | 'POSTPONED';
export type EventLocation = 'ZOOM' | 'MS_TEAMS' | 'GOOGLE_MEET' | 'IN_PERSON' | 'OTHER';
export type EventDocsStatus = 'DOCS_PENDING' | 'DOCS_READY' | 'NA';

export class TypeOfEventStatuseValue {
    public static readonly COMPLETED : EventStatus = "COMPLETED";
    public static readonly PENDING : EventStatus = "PENDING";
    public static readonly CLOSED : EventStatus = "CLOSED";
    public static readonly UNSPECIFIED : EventStatus = "UNSPECIFIED";
    public static readonly PERFORMED : EventStatus = "PERFORMED";
    public static readonly CANCELLED : EventStatus = "CANCELLED";
    public static readonly POSTPONED : EventStatus = "POSTPONED";
}

export class EventData extends BaseEntity {
    constructor(eventData?: EventData) {
        super(eventData);

        if(eventData) {
            this.propertyAddress= new Address(eventData.propertyAddress);
            if(eventData.signingEnvelope){
                this.signingEnvelope= new SigningEnvelope(eventData.signingEnvelope);
            }

            //appointments will have a startTimeStamp and endTimestamp in UTC
            //use the time stamp to populate the startDate and startTime fields based on user's local time zone
            if (eventData.startTimestamp) {
                let startMoment: moment.Moment = moment(eventData.startTimestamp);
                this.startDate = startMoment.format('YYYY/MM/DD');
                this.startTime = startMoment.format('hh:mm A');
            }

            if (eventData.endTimestamp) {
                let endMoment: moment.Moment = moment(eventData.endTimestamp);
                this.endDate = endMoment.format('YYYY/MM/DD');
                this.endTime = endMoment.format('hh:mm A');
            }

            this.meetingParticipants = [];
            this.deletedMeetingParticipants = [];
            if(Array.isArray(eventData.deletedMeetingParticipants) &&  eventData.deletedMeetingParticipants.length > 0) {
                // If the meetingParticipants have already separated deletedMeetingParticipants and deletedMeetingParticipants
                // We only make sure to get class.
                if(Array.isArray(eventData.meetingParticipants) && eventData.meetingParticipants.length > 0){
                    this.meetingParticipants = eventData.meetingParticipants.map(item=>new MeetingParticipant(item));
                }
                this.deletedMeetingParticipants = eventData.deletedMeetingParticipants.map(item=>new MeetingParticipant(item));
            } else {
                // We should use new MeetingParticipant to change Object to Class
                // If the meetingParticipants have NOT separated deletedMeetingParticipants and deletedMeetingParticipants,
                // We need separate the array
                if(Array.isArray(eventData.meetingParticipants) && eventData.meetingParticipants.length > 0){
                    this.meetingParticipants = eventData.meetingParticipants.filter(itemFilter=>!itemFilter.deleted).map(item=>new MeetingParticipant(item));
                    this.deletedMeetingParticipants = eventData.meetingParticipants.filter(itemFilter=>itemFilter.deleted).map(item=>new MeetingParticipant(item));
                }
            }
        }

        if (!this.inviteGuid) {
            this.inviteGuid = UUIDUtil.getUUID();
        }
    }

    id : number;
    eventType : EventType;
    eventSubtype: string;
    inviteeParticipantIds: number[];
    meetingParticipants: MeetingParticipant[];
    deletedMeetingParticipants: MeetingParticipant[];// It is only for UI
    inviteeList: string;
    eventStatus : EventStatus;
    eventDescription : string;
    eventNote: string;
    matterId : number;
    matterType : string;
    customMatterTypeName: string;
    matterRecordNumber : string;
    matterProvinceCode : string;
    showNotesOnOpen: boolean;
    clientReLine : string;
    lawClerkInitials : string;
    solicitorInitials : string;
    actingFor : string;
    triggerId : number;
    vendorReline: string;
    propertyAddress: Address;
    weekRange: string;
    startDate: string;
    startTime: string;
    startTimestamp: string; //used for appointments
    endTime: string;
    endDate: string;
    endTimestamp: string; //used for appointments
    allDayEvent: boolean;
    scheduledForSolicitorId: number;
    eventDocsStatus: EventDocsStatus;
    unityProjectId: number;
    projectRecordNumber: string;
    eventLocation: EventLocation;
    meetingLink: string; //eg zoom meeting link
    externalUserId: string; //external id of User
    externalUserName: string; //full name of External user
    externalMeetingId: number; //external Meeting ID
    inviteGuid: number // To generate the link in email for invitee
    virtualMeetingAccountIntegrationId: number //id of credential used in case of multiple third party credentials at account level, BK change the name
    virtualSigningAccountIntegrationId: number
    signingDigitally : boolean;
    signingEnvelope : SigningEnvelope;
    version: number;
    clientOffset: string;
    dateHoliday : string;
    showStaffAvailabilityAs: StaffAvailabilityType;
    matterWorkItemTaskStatus : WorkItemTaskStatusType;
    isOverDueForStaffView : boolean = false;


    isAppointment() : boolean {
        return this.eventType == EventTypes.APPOINTMENT;
    }

    isWorItemTask() : boolean {
        return this.eventType == EventTypes.WORKITEMTASK;
    }

    isWorItemTaskAssigned() : boolean {
        return this.isWorItemTask() && this.matterWorkItemTaskStatus == WorkItemTaskStatusValues.assigned;
    }

    isEventDocsStatusEmptyOrNA() : boolean {
        return !this.eventDocsStatus || this.eventDocsStatus == 'NA';
    }

    get eventDocsStatusLabel(): string{
        if(this.isEventDocsStatusEmptyOrNA()){
            return '';
        }
        const matchedOption = eventDropDowns.eventDocsStatusOptions.find(option => option.value == this.eventDocsStatus);
        return matchedOption ? matchedOption.label : ''
    }

    isCustomMatter(): boolean{
        return this.matterType == 'CUSTOM';
    }

    isDischargeMatter(): boolean{
        return this.matterType == 'DISCHARGE';
    }

    isWillMatter(): boolean {
        return this.matterType === 'WILL'
    }

    get description() : string {
        return  SharedUtils.truncateStringForDisplay(this.longTitle, 60, '...') ;
    }

    get longTitle(): string {
        return this.isAppointment() ?
            (this.getAppointmentTime() + `${this.eventDescription ?  ', ' + this.eventDescription : ''}` ):
            this.eventDescription;
    }

    getEventType(): string{
        if((this.isCustomMatter() || sessionStorage.getItem(SESSION_STORAGE_KEYS.defaultProvinceCode) == PROVINCE_CODES.BRITISH_COLOMBIA || this.isWillMatter()) && this.eventType == 'CLOSING'){
            return 'COMPLETION';
        }
        else if(this.eventType == 'SUPPLEMENTAL'){
            return 'EXTENDED WORKFLOW';
        }
        else if(sessionStorage.getItem(SESSION_STORAGE_KEYS.defaultProvinceCode) == PROVINCE_CODES.BRITISH_COLOMBIA && this.eventType == 'OCCUPANCY'){
            return 'POSSESSION';
        }
        else{
            return EventData.filterEventType(this.eventType, this.matterProvinceCode);
        }
    }

    /**
     *  This method is ony for event-calendar
     *  BA wants "Closing count will be modified to report Opportunity Closing independently" only in Calender view.
     *  CalendarMonthViewComponent is third part code.
     *  If we want to court independently, it should provide different type of meta between "Opportunity Closing" and other Closing.
     *  The third part interface according to type to report the count independently
     *  We can't use getEventType().
     */
    getCalenderEventEventType(): string{
        if(this.eventType == 'CLOSING' && this.isOpportunityType()){
            return 'CLOSING-OPPORTUNITY'
        }
        else if(this.isCustomMatter() && this.eventType == 'CLOSING'){
            return 'COMPLETION';
        }else{
            return EventData.filterEventType(this.eventType, this.matterProvinceCode);
        }
    }

    getEventDescription() {
        if((this.isCustomMatter() || sessionStorage.getItem(SESSION_STORAGE_KEYS.defaultProvinceCode) == PROVINCE_CODES.BRITISH_COLOMBIA || this.isWillMatter()) && this.eventType == 'CLOSING'){
            return 'Completion Date';
        }else if(sessionStorage.getItem(SESSION_STORAGE_KEYS.defaultProvinceCode) == PROVINCE_CODES.BRITISH_COLOMBIA && this.eventType == 'OCCUPANCY'){
            return 'Possession Date';
        }
        else{
            return EventData.filterEventDescription(this.description, this.matterProvinceCode);
        }
    }

    getAppointmentTime(): string {
        return `${this.startDate ? Utils.getFormattedDate(this.startDate, "MMM DD ", '') : ''}`
            +`${this.startTime ? this.startTime : ''}`
            + `${this.startTime && this.endTime ? ' - ' : ''}`
            + `${this.endDate ? Utils.getFormattedDate(this.endDate, "MMM DD ", '') : ''}`
            +`${this.endTime ? this.endTime : ''}`;
    }

    // used on the mouse-over for event description
    getLongDescription(): string {
        const eventDescription: string = this.getEventDescription();
        if (this.isAppointment()) {

            const descr = `${eventDescription ? '\n' + eventDescription : ''}`;
            const invitees = `${this.inviteeList ? '\n' + this.inviteeList : ''}`;
            const eventDocsStatusLabel: string = `${this.isEventDocsStatusEmptyOrNA() ? '' :  ', ' + this.eventDocsStatusLabel}`;
            const note = `${this.eventNote ? '\n\n' + this.eventNote : ''}`;

            return `${descr}${invitees}${eventDocsStatusLabel}${note}`;
        }
        return eventDescription;
    }

    get isProjectSale(): boolean {
        return !!this.unityProjectId;
    }

    public static filterOutCanclePostoneEvent(eventData : EventData[]) : EventData[]{
        if(Array.isArray(eventData)){
            return eventData
                    .filter(item=>item.eventStatus != TypeOfEventStatuseValue.CANCELLED && item.eventStatus != TypeOfEventStatuseValue.POSTPONED);

        } else {
            return [];
        }
    }

    public static filterEventType(eventType: string, matterProvinceCode: string) {
        if(matterProvinceCode == 'NS' && 'REQUISITION' == eventType){
            return 'TITLE COND';
        } else {
            return eventType == EventTypes.WORKITEMTASK ? 'TASK' : eventType;
        }
    }

    public static filterEventDescription(description: string, matterProvinceCode: string) {
        if(matterProvinceCode == 'NS' && description && (description.indexOf('Requisition Date')> -1)){
            return description.replace(/Requisition Date/gi, 'Title Conditions Date');
        } else {
            return description
        }
    }

    public static populateHolidays(eventData : EventData[], publicHolidayService: PublicHolidayService) {
        eventData.forEach(event => {
            event.dateHoliday = publicHolidayService.getHolidayByFullDateString(event.startDate);
        });
    }

    getInviteeListTruncated(maxLength: number): string {
        return  SharedUtils.truncateStringForDisplay(this.inviteeList, maxLength, '...') ;
    }

    getDefaultEventDescription(): string {
        switch (this.eventType) {
            case "APPOINTMENT": return 'Appointment';
            case "CLOSING":     return 'Closing Date';
            case "OCCUPANCY":   return 'Occupancy Date';
            case "REQUISITION": return 'Requisition Date';
            case "SUPPLEMENTAL":return 'Extended Workflows Placeholder';
            case "UNDERTAKING": return 'Undertaking Placeholder';
            case "HOLDBACK":    return 'Holdback';
            case "WORKITEMTASK":return 'Task';
            default: return 'Unknown event type';
        }
    }

    getBCEventDescription(): string {
        switch (this.eventType) {
            case "APPOINTMENT": return 'Appointment';
            case "CLOSING":     return 'Completion Date';
            case "OCCUPANCY":   return 'Possession Date';
            case "REQUISITION": return 'Requisition Date';
            case "SUPPLEMENTAL":return 'Supplemental Placeholder';
            case "UNDERTAKING": return 'Undertaking Placeholder';
            case "HOLDBACK":    return 'Holdback';
            case "WORKITEMTASK":return 'Task';
            default: return 'Unknown event type';
        }
    }

    isZoomMeeting(): boolean {
        return this.eventLocation == 'ZOOM';
    }

    isMSTeamsMeeting(): boolean {
        return this.eventLocation == 'MS_TEAMS';
    }

    isGoogleMeeting(): boolean {
        return this.eventLocation == 'GOOGLE_MEET';
    }

    isMeetingCreated(): boolean {
        return !!this.externalMeetingId;
    }

    isVirtualMeeting() : boolean {
        return this.isZoomMeeting() || this.isMSTeamsMeeting() || this.isGoogleMeeting();
    }


    isMeetingInPerson(): boolean {
        return this.eventLocation == 'IN_PERSON';
    }

    isMeetingOther(): boolean {
        return this.eventLocation == 'OTHER';
    }

    isCancelled(): boolean {
        return this.eventStatus == 'CANCELLED';
    }

    get appointmentStatusLabel() : string {
        let label = '';
        let eventStatusOptions = eventDropDowns.eventStatusOptions;
        if(this.isAppointment() && eventStatusOptions && eventStatusOptions.length && this.eventStatus){
            let statusOption = eventStatusOptions.find(option=>option.value == this.eventStatus);
            label = statusOption ? statusOption.label : '';
        }
        return label;
    }

    /*
    Use this when length of description might go over max chars
     */
    setEventDescription(descr: string){
        this.eventDescription = SharedUtils.cutStringValue(descr, maxFieldLength.event.eventDescription);
    }

    isDigitalSignPlatformDocuSign() : boolean {
        return this.signingEnvelope && this.signingEnvelope.digitalSigningPlatform == DigitalSignaturePlatform.DOCU_SIGN;
    }

    isDigitalSignPlatformSyngrafii() : boolean {
        return this.signingEnvelope && this.signingEnvelope.digitalSigningPlatform == DigitalSignaturePlatform.SYNGRAFII;
    }

    isOpportunityType(): boolean {
        return this.matterType == 'OPPORTUNITY';
    }

    getSigningPlatformLabel(): string {
        if (this.isDigitalSignPlatformSyngrafii()) {
            return DigitalSignaturePlatformLabel.SYNGRAFII;
        } else {
            return DigitalSignaturePlatformLabel.DOCU_SIGN;
        }
    }

    isPackageTypeSequential(): boolean {
        return this.signingEnvelope && this.signingEnvelope.packageType == 'SEQUENTIAL';
    }

    isPackageTypeVirtualSigningRoom(): boolean {
        return this.signingEnvelope && this.signingEnvelope.packageType == 'VIDEO_SIGNING_ROOM';
    }

    getShowStaffAvailabilityAs() : string{
        if(this.isAppointment() && this.showStaffAvailabilityAs == 'OUT_OF_OFFICE'){
            return 'Out of Office ';
        }
        else if(this.isAppointment() &&this.showStaffAvailabilityAs == 'TENTATIVE'){
            return 'Tentative ';
        }
        else if(this.isAppointment() && this.showStaffAvailabilityAs == 'BUSY'){
            return 'Busy ';
        }
        else{
            return '';
        }
    }

    get timeDescription() : string {
        let startMoment : moment.Moment;
        let timeDesc : string;
        if (this.startTimestamp) {
            startMoment = moment(this.startTimestamp);
        }
        if (this.endTimestamp && startMoment) {
            let endMoment: moment.Moment = moment(this.endTimestamp);
            if(moment(this.startTimestamp).isSame(this.endTimestamp, 'day')){
                return startMoment.format('dddd, MMMM Do hh:mm A')+' to '+endMoment.format('hh:mm A');
            }
            else{
                return startMoment.format('dddd, MMMM Do hh:mm A')+' to '+ endMoment.format('dddd, MMMM Do hh:mm A');
            }
        }
        else if(startMoment){
            return startMoment.format('dddd, MMMM Do hh:mm A');
        }
        else{
            return '';
        }
    }

    getStaffAppointmentDescription() : string {
        let desc = `${this.getShowStaffAvailabilityAs() + ' '}`;
        if(this.matterId){
            desc = `${this.eventLocation ? this.getEventLocationText() + ' ' : '' }`;
        }
        desc += `from ${this.timeDescription}`;
        return desc;
    }

    getEventLocationText() : string {
        let textList = this.eventLocation.split('_');
        textList = textList.map(item => item.charAt(0) + item.substring(1, item.length).toLowerCase());
        return textList.join(' ');
    }
}
