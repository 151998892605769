import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {SoAdjInterimOccupancyFee} from '../model/so-adj-interim-occupancy-fee';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import Utils from '../../../shared-main/utils';
import moment from 'moment';

export class StatementAdjustmentDisplayInterimOccupancyFeeBuilder {

    static getDisplayItems(soAdj: SoAdjInterimOccupancyFee, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[]{
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        if (!toBeUsedOnModal) {
            if (mainBuilder.matter.project &&
                ((mainBuilder.matter.project.projectCondo && mainBuilder.matter.project.projectCondo.isOccupancyFeesCalculatedBasedOnPurchaseMonies)
                    || (!mainBuilder.matter.project.isCondo && mainBuilder.matter.project.isPOTL))){
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    'Interest on Deferred Purchase Monies',
                    mainBuilder.getCurrency(soAdj.mortgageInterestTotal)
                ));
            } else {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    mainBuilder.matter.isMatterProvinceAB ? 'Interest' :  'Mortgage Interest',
                    mainBuilder.getCurrency(soAdj.mortgageInterestTotal)
                ));
            }

            sadItemDetails.push(mainBuilder.getNewItemLine(
                mainBuilder.matter.isMatterProvinceAB ? 'Condominium Fees' : 'Common Expenses',
                mainBuilder.getCurrency(soAdj.commonExpenseTotal)
            ));

            sadItemDetails.push(mainBuilder.getNewItemLine(
                'Taxes',
                mainBuilder.getCurrency(soAdj.taxesTotal)
            ));
        }

        sadItemDetails.push(mainBuilder.getNewItemLine(
            'Total Occupancy Fees',
            mainBuilder.getCurrency(soAdj.total)
        ));

        if (Utils.convertToBoolean(soAdj.applyTax)) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${soAdj.taxType} calculated at `+ mainBuilder.getPercentage(soAdj.hstRate),
                mainBuilder.getCurrency(soAdj.total*soAdj.hstRate / 100)));
        }

        let elapsedDays =  mainBuilder.matter.isMatterProvinceAB ?  Number( moment(mainBuilder.adjustmentDate, "YYYY/MM/DD").date()) : Number(mainBuilder.matter.getElapsedDays(mainBuilder.adjustmentDate));
        let numberOfDaysInMonth = Number(mainBuilder.matter.getNumberofDaysMonth(mainBuilder.adjustmentDate));
        let numberOfDaysForPurchasersShare: number = soAdj.numberOfDaysForPurchasersShare(elapsedDays, numberOfDaysInMonth);
        if(mainBuilder.matter.isPaysForDateOfClosingPurchaser && mainBuilder.matter.isMatterProvinceAB ){
            numberOfDaysForPurchasersShare = numberOfDaysForPurchasersShare +1;
        }
        sadItemDetails.push(mainBuilder.getNewItemLine(
            `Purchaser's share for ${numberOfDaysForPurchasersShare} day` + (numberOfDaysForPurchasersShare === 1 ? '' : 's'),
            mainBuilder.getCurrency(soAdj.getPurchaserShare(elapsedDays, numberOfDaysInMonth, mainBuilder.matter.isPaysForDateOfClosingPurchaser, mainBuilder.matter.provinceCode))
        ));


        if(soAdj.calculateFor == 'THIS_MONTH_AND_NEXT_MONTH'){
            sadItemDetails.push(mainBuilder.getNewItemLine(
                "Next month's fees" + ((Utils.convertToBoolean(soAdj.applyTax)) ? `(including ${soAdj.taxType})`: ''),
                mainBuilder.getCurrency(soAdj.nextMonthFees)
                ));
        }

        if (toBeUsedOnModal) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Credit ${mainBuilder.vendorLabel}`,
                mainBuilder.getCurrency(soAdj.getCreditVendor(elapsedDays, numberOfDaysInMonth, mainBuilder.matter.isPaysForDateOfClosingPurchaser, mainBuilder.matter.provinceCode)),
                true
            ));
        }
        else{
            sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${mainBuilder.vendorLabel}`, ``));
        }
        return sadItemDetails;
    }
}
