import {api} from '../../common/api';

export const telusApi = {
    telusUser                  : `${api}/integrations/stewart-assyst/accounts/{accountId}/users/currentUser`,
    telusUserForSolicitor      : `${api}/integrations/stewart-assyst/accounts/{accountId}/solicitors/{solicitorId}`,
    validateTelusUser          : `${api}/integrations/stewart-assyst/accounts/{accountId}/validateUser`,
    deleteStewartAssysteUser   : `${api}/integrations/stewart-assyst/accounts/{accountId}/users/{userId}/stewartAssystUser/delete`,
    getwebUrl                  : `${api}/integrations/stewart-assyst/accounts/{accountId}/users/{userId}/webUrl`,
    getNewMortgageInstructions : `${api}/integrations/stewart-assyst/accounts/{accountId}/mortgageInstructions/checkForNew`,
    unityMortgageInstructions  : `${api}/integrations/emp/accounts/{accountId}/mortgageInstructions/{status}`,
    unityMortgageInstructionsCSVExport  : `${api}/integrations/emp/accounts/{accountId}/mortgageInstructions/{status}/exportToCsv`,
    getStewartUsers            : `${api}/accounts/{accountId}/solicitors/stewartUsers`,
    getDealData                : `${api}/integrations/stewart-assyst/accounts/{accountId}/solicitors/{solicitorId}/deals/{dealId}`,
    declineDeal                : `${api}/integrations/stewart-assyst/accounts/{accountId}/solicitors/{solicitorId}/deals/{dealId}/refuse`,
    acceptDeal                 : `${api}/integrations/stewart-assyst/accounts/{accountId}/solicitors/{solicitorId}/deals/{dealId}/accept`,
    unlockDeal                 : `${api}/integrations/stewart-assyst/accounts/{accountId}/solicitors/{solicitorId}/deals/{dealId}/unlock`,
    saveFileData               : `${api}/integrations/stewart-assyst/users/{userId}/matters/{matterId}/mortgages/{mortgageId}/saveFileData`,
    downloadDocument           : `${api}/integrations/stewart-assyst/matters/{matterId}/deals/{dealId}/documents/{documentNumber}`,
    deleteDocuments            : `${api}/integrations/stewart-assyst/matters/{matterId}/deals/{dealId}/documents`,
    assystRealEstateSupportUrl : `${api}/integrations/stewart-assyst/supportUrl`,
    stewartTitleSupportUrl     : `${api}/integrations/stewart-title/supportUrl`,
    forgotPasswordUrl          : `${api}//integrations/stewart-assyst/accounts/{accountId}/forgotUrl`,
    deleteStewartAssysteDeal   : `${api}/integrations/stewart-assyst/accounts/{accountId}/solicitors/{solicitorId}/deal/{dealId}`,
    submitPayout               : `${api}/integrations/stewart-assyst/solicitors/{solicitorId}/matters/{matterId}/mortgages/{mortgageId}/submitPayoutRequest`,
    latestPayoutStatement      : `${api}/integrations/stewart-assyst/matters/{matterId}/mortgages/{mortgageId}/payoutStatements/current`,
    cancelPayoutRequest        : `${api}/integrations/stewart-assyst/solicitors/{solicitorId}/matters/{matterId}/payoutStatements/{payoutStatementId}/cancelPayout`,
    payoutStatements           : `${api}/integrations/stewart-assyst/matters/{matterId}/mortgages/{mortgageId}/payoutStatements`,
    parsePayoutData           : `${api}/integrations/stewart-assyst/matters/{matterId}/thirdPartyDocuments/{thirdPartyDocumentId}/parse`,

}

export const telusConfigurations = {
    MAX_PINS : 9
}

export const AssystPayoutXpathConfigs = {
    matterXPathConfigs : [
        {xpath:'$.matterCloseDate', matterType : 'SM', xpathType : 'Object', message: 'Closing Date was changed.'},
        {xpath:'$.fileNumber', matterType : 'SM', xpathType : 'Object', message: 'Requestor File Number was changed.'},
        {xpath:'$.matterProperties[0].address.addressLine1', matterType : 'SM', xpathType : 'Object', message: 'Address Line1 was changed.'},
        {xpath:'$.matterProperties[0].address.addressLine2', matterType : 'SM', xpathType : 'Object',message: 'Address Line2 was changed.'},
        {xpath:'$.matterProperties[0].address.city', matterType : 'SM', xpathType : 'Object',message: 'City was changed.'},
        {xpath:'$.matterProperties[0].address.provinceName', matterType : 'SM', xpathType : 'Object',message: 'Province was changed.'},
        {xpath:'$.matterProperties[0].address.postalCode', matterType : 'SM', xpathType : 'Object',message: 'Postal Code was changed.'},
        {xpath:'$.matterProperties[0].registryOrLt', matterType : 'SM', xpathType : 'Object',message: 'Registry was changed.'},
        {xpath:'$.matterProperties[0].registryOfficeName', matterType : 'SM', xpathType : 'Object',message: 'Land Title District was changed.'},
    ],
    mortgageXPathConfigs : [
        {xpath:'$.loanNo', matterType : 'PSM', xpathType : 'Object', message: 'Mortgage Loan No was changed.'},
        {xpath:'$.mortgageRequestDate', matterType : 'PSM', xpathType : 'Object', message: 'Mortgage registration date was changed.'},
        {xpath:'$.mortgageRequestNo', matterType : 'PSM', xpathType : 'Object', message: 'Mortgage  registration number was changed.'},
        {xpath:'$.financingType', matterType : 'PSM', xpathType : 'Object', message: 'Financing Type was changed.'},
        {xpath:'$.rollBookNumber', matterType : 'PSM', xpathType : 'Object', message: 'Roll Book No was changed.'},
        {xpath:'$.framePageNumber', matterType : 'PSM', xpathType : 'Object', message: 'Frame Page No was changed.'},
        {xpath:'$.collateralMortgage', matterType : 'PSM', xpathType : 'Object', message: 'Mortgage Type was changed.'},

    ],
    relatedInstrumentXPathConfigs : [
        {xpath:'$.instrumentType', matterType : 'PSM', xpathType : 'Object', message: 'Mortgage Instrument Type was changed.'},
        {xpath:'$.registrationNumber', matterType : 'PSM', xpathType : 'Object', message: 'Registration No was changed.'},
        {xpath:'$.registrationDate', matterType : 'PSM', xpathType : 'Object', message: 'Registration Date Date was changed.'},
        {xpath:'$.rollBookNumber', matterType : 'PSM', xpathType : 'Object', message: 'Roll Book No was changed.'},
        {xpath:'$.framePageNumber', matterType : 'PSM', xpathType : 'Object', message: 'Frame Page No was changed.'}
    ],
    contactXPathConfigs : [
        {xpath:'$.organizationName', matterType : 'PSM', xpathType : 'Object', message: 'Company Name was changed.'},
        {xpath:'$.contactName.firstName', matterType : 'PSM', xpathType : 'Object', message: 'Fist Name was changed.'},
        {xpath:'$.contactName.middleName', matterType : 'PSM', xpathType : 'Object', message: 'Middle Name was changed.'},
        {xpath:'$.contactName.lastName', matterType : 'PSM', xpathType : 'Object', message: 'last Name was changed.'},
    ]
};

export const defaultMessageForCountNotMatch = {
    relatedInstrument : 'The list of Mortgage Instruments was changed',
    mortgagors : 'The list of Mortgagors was changed',
    vendors :  'The list of Vendors was changed',
    gender :  'The list of Gender was changed',
    pins : 'The list of PIN\'s was changed',

}

