import {MatterTopicKey, ProjectTopicKey} from '../../shared-main/constants';
import {jurisdictionDepartmentName} from '../../shared-main/province-based-field-labels';
import {PROVINCE_CODES} from "./user-province";

export interface Section {

    title: string;
    route: string;
    active: boolean;
    applicableFor?: string;
    shortCutKey?: string;
    sectionKey? : MatterTopicKey;
    projectSectionKey?: ProjectTopicKey;
    hideForProvince? : string[];
}

export class MatterSectionsRouteTypes {
    //matterOpening route types
    static readonly MATTER_OPENING : string = 'matterOpening';
    static readonly OPPORTUNITY_DETAIL : string = 'opportunityDetail';

    static readonly PURCHASERS : string = 'purchaser';
    static readonly VENDORS : string = 'vendor';
    static readonly MORTGAGORS : string = 'mortgagor';

    //undertakings route types
    static readonly UNDERTAKINGSRECEIVED : string = 'undertakingsReceived';
    static readonly UNDERTAKINGSGIVEN : string = 'undertakingsGiven';
    static readonly UNDERTAKINGS : string = 'undertakings';

    static readonly PROPERTY: string = 'propertyTeranet';



    //supplementalTasks route types
    static readonly SUPPLEMENTALTASKS : string = 'supplementalTasks';


    //mortgages route types
    static readonly MORTGAGES : string = 'mortgages';

    static readonly NOTES : string = 'notes';

    static readonly MATTER_DOCUMENTS : string = 'documentsForThisMatter';

    static readonly OVERVIEW : string = 'overview';

    static readonly OPPORTUNITY_OVERVIEW : string = 'opportunityOverview';

    static readonly WORKITEMS : string = 'workItems';

    static readonly ASSOCIATED_ITEMS = 'associatedFiles';

    static readonly CORRESPONDENCE : string = 'correspondence';

    static readonly TASKS : string = 'tasks';
}

export class MatterSectionsTitles {
    //matterOpening route types
    static readonly OVERVIEW : string = 'Overview';
    static readonly MATTER_OPENING : string = 'Matter Opening';
    static readonly OPPORTUNITY_DETAIL : string = 'Opportunity Detail';
    static readonly PURCHASERS : string = 'Purchasers';
    static readonly VENDORS: string = 'Vendors';
    static readonly MORTGAGORS : string = 'Mortgagors';
    static readonly MY_CLIENTS : string = 'My Clients';
    static readonly MY_PROSPECTS : string = 'My Prospects';
    static readonly FAMILY_AND_FRIENDS : string = 'Family and Friends';
    static readonly VENDORS_SOLICITORS : string = 'Vendors & Solicitor';
    static readonly PURCHASERS_SOLICITOR : string = 'Purchasers & Solicitor';

    static readonly OTHER_SOLICITOR : string = 'Other Solicitor';
    static readonly OTHER_SIDE : string = 'Other Side';
    static readonly PROPERTY_TERANET_CONNECT : string = 'Property/Teranet Connect';
    static readonly DEAL_DETAILS : string = 'Deal Details'
    static readonly ASSETS_AND_GIFTS : string = 'Assets, Debts & Gifts';
    static readonly CAPACITY_ASSESSMENT : string = 'Capacity Assessment';
    static readonly PARTIES_AND_ROLES : string = 'Parties & Roles';
    static readonly RESIDUE_AND_TRUSTS : string = 'Residue and Trusts';
    static readonly VISUAL_DISTRIBUTION : string = 'Visual Distribution';
    static readonly ESTATE_PLAN : string = 'Estate Plan';
    static readonly CLIENT_HOMEWORK : string = 'Client Homework';
    static readonly CONSIDERATION_LTT : string = 'Consideration & LTT';
    static readonly COMPLIANCE : string = 'Compliance';
    static readonly MORTGAGE : string = 'Mortgage(s)';
    static readonly EXISTING_MORTGAGE : string = 'Existing Mortgage(s)';
    static readonly VTB_MORTGAGE : string = 'VTB Mortgage(s)';
    static readonly RE_BROKER_COMMISION : string = 'RE Broker / Commission';
    static readonly NEW_MORTGAGE : string = 'New Mortgage';
    static readonly CONDO_CORPORATION : string = 'Condo Corporation';
    static readonly STRATA_CORPORATION : string = 'Strata Corporation';
    static readonly FIRE_INSURANCE : string = 'Fire Insurance';
    static readonly PURCHASER_FIRE_INSURANCE : string = 'Purchaser\'s Fire Insurance';
    static readonly REQUISITIONS : string = 'Requisitions';
    static readonly RESPONSE_TO_REQUISITIONS : string = 'Response to Requisitions';
    static readonly UNDERTAKINGS_RECEIVED : string = 'Undertakings Received';
    static readonly UNDERTAKINGS_GIVEN : string = 'Undertakings Given';
    static readonly UNDERTAKINGS : string = 'Undertakings';
    static readonly STATEMENT_OF_ADJUSTMENTS : string = 'Statement of Adjustments';
    static readonly DIRECT_REFUNDS : string = 'Direction Re Funds';
    static readonly SUPPLEMENTAL_TASKS : string = 'Extended Workflows';
    static readonly STATEMENT_OF_ACCOUNT : string = 'Statement of Account'
    static readonly TRUST_LEDGER : string = 'Trust Ledger';
    static readonly REPORT_TO_PURCHASER : string = 'Report to Purchaser';
    static readonly EXECUTION_AFFADAVITS : string = 'Executions Affidavits';
    static readonly FORMS : string = 'Forms';
    static readonly DOCUMENT_PRODUCTION : string = 'Document Production';
    static readonly MATTER_DOCUMENTS : string = 'Matter Documents';
    static readonly OPPORTUNITY_DOCUMENTS : string = 'Opportunity Documents';
    static readonly MATTER_CLOSING : string = 'Matter Closing';
    static readonly NOTES : string = 'Notes';
    static readonly WORK_ITEMS : string = 'Tasks';
    static readonly ASSOCIATED_FILES : string = 'Associated Files';
    static readonly TITLE_INSURANCE : string = 'Title Insurance';
    static readonly FORM_EDITOR: string = 'Form Editor';
    static readonly CLIENT_INFO: string = 'Client Info';
    static readonly CORRESPONDENCE : string = 'Correspondence';
    static readonly TASKS : string = 'Tasks';
}

//matter sections
//custom ordering of Matter Sections for BC by title
export const matterSectionsBCOrdering: string[] = [
    MatterSectionsTitles.OVERVIEW,
    MatterSectionsTitles.MATTER_OPENING,
    MatterSectionsTitles.OPPORTUNITY_DETAIL,
    MatterSectionsTitles.PURCHASERS,
    MatterSectionsTitles.VENDORS,
    MatterSectionsTitles.MORTGAGORS,
    MatterSectionsTitles.MY_CLIENTS,
    MatterSectionsTitles.MY_PROSPECTS,
    MatterSectionsTitles.VENDORS_SOLICITORS,
    MatterSectionsTitles.PURCHASERS_SOLICITOR,
    MatterSectionsTitles.OTHER_SOLICITOR,
    MatterSectionsTitles.OTHER_SIDE,
    MatterSectionsTitles.PROPERTY_TERANET_CONNECT,
    MatterSectionsTitles.DEAL_DETAILS,
    MatterSectionsTitles.ASSETS_AND_GIFTS,
    MatterSectionsTitles.PARTIES_AND_ROLES,
    MatterSectionsTitles.RESIDUE_AND_TRUSTS,
    MatterSectionsTitles.CONSIDERATION_LTT,
    MatterSectionsTitles.COMPLIANCE,
    MatterSectionsTitles.MORTGAGE,
    MatterSectionsTitles.EXISTING_MORTGAGE,
    MatterSectionsTitles.VTB_MORTGAGE,
    MatterSectionsTitles.RE_BROKER_COMMISION,
    MatterSectionsTitles.NEW_MORTGAGE,
    MatterSectionsTitles.STRATA_CORPORATION,
    MatterSectionsTitles.PURCHASER_FIRE_INSURANCE,
    MatterSectionsTitles.FIRE_INSURANCE,
    MatterSectionsTitles.REQUISITIONS,
    MatterSectionsTitles.RESPONSE_TO_REQUISITIONS,
    MatterSectionsTitles.UNDERTAKINGS_RECEIVED,
    MatterSectionsTitles.UNDERTAKINGS_GIVEN,
    MatterSectionsTitles.UNDERTAKINGS,
    MatterSectionsTitles.STATEMENT_OF_ADJUSTMENTS,
    MatterSectionsTitles.DIRECT_REFUNDS,
    MatterSectionsTitles.SUPPLEMENTAL_TASKS,
    MatterSectionsTitles.STATEMENT_OF_ACCOUNT,
    MatterSectionsTitles.TRUST_LEDGER,
    MatterSectionsTitles.REPORT_TO_PURCHASER,
    MatterSectionsTitles.EXECUTION_AFFADAVITS,
    MatterSectionsTitles.FORMS,
    MatterSectionsTitles.DOCUMENT_PRODUCTION,
    MatterSectionsTitles.MATTER_DOCUMENTS,
    MatterSectionsTitles.OPPORTUNITY_DOCUMENTS,
    MatterSectionsTitles.NOTES,
    MatterSectionsTitles.ASSOCIATED_FILES,
    MatterSectionsTitles.TITLE_INSURANCE,
    MatterSectionsTitles.TASKS,
    // shortCutKey : '6' was used by overview
];

export const matterSections: Section[] = [
    // now Letter J in the sections is representing Projct Sale in applicableSections for applicableFor
    { title: MatterSectionsTitles.OVERVIEW, route: MatterSectionsRouteTypes.OVERVIEW, active: false , applicableFor: 'PSMCDWJ' , shortCutKey : '6' ,  sectionKey : 'OVERVIEW'},
    //Notes Added a new section for Tasks which was Work_Items for matter overview
    //The value tasks in class  matter-work-items component etc. are the value for tasks now.
    { title: MatterSectionsTitles.TASKS, route: MatterSectionsRouteTypes.TASKS, active: true , applicableFor: 'PSMCDW' , shortCutKey : '7' ,  sectionKey : 'OVERVIEW'},

    { title: MatterSectionsTitles.MATTER_OPENING, route: MatterSectionsRouteTypes.MATTER_OPENING, active: true , applicableFor: 'PSMCDWJ' , shortCutKey : 'A' ,  sectionKey : 'MATTER_OPENING'},
    { title: MatterSectionsTitles.OPPORTUNITY_DETAIL, route: MatterSectionsRouteTypes.OPPORTUNITY_DETAIL, active: true , applicableFor: 'O' , shortCutKey : 'A' ,  sectionKey : 'MATTER_OPENING'},


    { title: MatterSectionsTitles.PURCHASERS, route: 'purchaser', active: false , applicableFor: 'P' , shortCutKey : 'B' ,  sectionKey : 'PURCHASER'},
    { title: MatterSectionsTitles.VENDORS, route: 'vendor', active: false , applicableFor: 'SJ' , shortCutKey : 'B' ,  sectionKey : 'VENDOR'},
    { title: MatterSectionsTitles.MORTGAGORS, route: 'mortgagor', active: false , applicableFor: 'MD' , shortCutKey : 'B' ,  sectionKey : 'MORTGAGOR'},
    { title: MatterSectionsTitles.MY_CLIENTS, route: 'purchaser', active: false , applicableFor: 'C' , shortCutKey : 'B' ,  sectionKey : 'PURCHASER'},
    { title: MatterSectionsTitles.MY_PROSPECTS, route: 'prospect', active: false , applicableFor: 'O' , shortCutKey : 'B' ,  sectionKey : 'PURCHASER'},
    { title: MatterSectionsTitles.CLIENT_INFO, route: 'clientInfo', active: false , applicableFor: 'W' , shortCutKey : 'L' ,  sectionKey : 'CLIENT_INFO'},

    { title: MatterSectionsTitles.VENDORS_SOLICITORS, route: 'vendorsSolicitor', active: false , applicableFor: 'P' , shortCutKey : 'C' ,  sectionKey : 'VENDOR_SOLICITOR'},
    { title: MatterSectionsTitles.PURCHASERS_SOLICITOR, route: 'purchasersSolicitor', active: false , applicableFor: 'SJ' , shortCutKey : 'C' ,  sectionKey : 'PURCHASER_SOLICITOR'},
    { title: MatterSectionsTitles.OTHER_SOLICITOR, route: 'otherSolicitor', active: false , applicableFor: 'M' , shortCutKey : 'C' ,  sectionKey : 'OTHER_SOLICITOR'},
    { title: MatterSectionsTitles.OTHER_SIDE, route: 'vendorsSolicitor', active: false , applicableFor: 'CO' , shortCutKey : 'C' ,  sectionKey : 'VENDOR_SOLICITOR'},

    { title: MatterSectionsTitles.PROPERTY_TERANET_CONNECT, route: 'propertyTeranet', active: false , applicableFor: 'PSMDJ' , shortCutKey : 'D' ,  sectionKey : 'PROPERTY'},

    { title: MatterSectionsTitles.DEAL_DETAILS, route: 'dealDetails', active: false , applicableFor: 'O' , shortCutKey : 'D' ,  sectionKey : 'PROPERTY'},

    { title: MatterSectionsTitles.PARTIES_AND_ROLES, route: 'partiesAndRoles', active: false , applicableFor: 'W' , shortCutKey : 'F' ,  sectionKey : 'PARTIES_ROLES'},
    { title: MatterSectionsTitles.ASSETS_AND_GIFTS, route: 'assetsGifts', active: false , applicableFor: 'W' , shortCutKey : 'D' ,  sectionKey : 'ASSETS'},
    { title: MatterSectionsTitles.RESIDUE_AND_TRUSTS, route: 'residueAndTrusts', active: false , applicableFor: 'W' , shortCutKey : 'G' ,  sectionKey : 'RESIDUE_TRUSTS'},

    { title: MatterSectionsTitles.CONSIDERATION_LTT, route: 'considerationLtt', active: false , applicableFor: 'P' , shortCutKey : 'E' ,  sectionKey : 'CONSIDERATION_LTT', hideForProvince : ['AB', 'MB', 'SK', 'NB','NS']},

    { title: MatterSectionsTitles.COMPLIANCE, route: 'compliance', active: false , applicableFor: 'P' , shortCutKey : 'F' ,  sectionKey : 'COMPLIANCE', hideForProvince : ['AB', 'MB', 'SK', 'NB','NS'] },
    { title: MatterSectionsTitles.COMPLIANCE, route: 'compliance', active: false , applicableFor: 'SJ' , shortCutKey : 'E' ,  sectionKey : 'COMPLIANCE', hideForProvince : ['AB', 'MB', 'SK', 'NB','NS','BC']},
    { title: MatterSectionsTitles.COMPLIANCE, route: 'compliance', active: false , applicableFor: 'M' , shortCutKey : 'E' ,  sectionKey : 'COMPLIANCE', hideForProvince : ['AB', 'MB', 'SK', 'NB','NS']},

    { title: MatterSectionsTitles.MORTGAGE, route: MatterSectionsRouteTypes.MORTGAGES, active: false , applicableFor: 'P' , shortCutKey : 'G' ,  sectionKey : 'MORTGAGES_MORTGAGEE'}, // This will be updated dynamically to specify the
    // number of mortgages
    { title: MatterSectionsTitles.EXISTING_MORTGAGE, route: 'existingMortgages', active: false , applicableFor: 'SMDJ' , shortCutKey : 'F',  sectionKey : 'EXISTING_MORTGAGE'}, // This will be updated dynamically
// with the number of
    // mortgages

    { title: MatterSectionsTitles.VTB_MORTGAGE, route: 'vtbMortgages', active: false , applicableFor: 'SJ' ,  shortCutKey : 'G' ,  sectionKey : 'MORTGAGES_MORTGAGEE', hideForProvince : ['BC']}, // This will be updated dynamically with
// the number of mortgages
    { title: MatterSectionsTitles.RE_BROKER_COMMISION, route: 'reBrokerCommission', active: false , applicableFor: 'SJ' , shortCutKey : 'H' ,  sectionKey : 'REAL_ESTATE_BROKER'},

    { title: MatterSectionsTitles.NEW_MORTGAGE, route: 'mortgages', active: false , applicableFor: 'M' , shortCutKey : 'G' ,  sectionKey : 'MORTGAGES_MORTGAGEE'},

    { title: MatterSectionsTitles.CONDO_CORPORATION, route: 'condoCorporation', active: false , applicableFor: 'PM', shortCutKey : 'H' ,  sectionKey : 'CONDO_CORPORATION' , hideForProvince: ['BC']},
    { title: MatterSectionsTitles.CONDO_CORPORATION, route: 'condoCorporation', active: false , applicableFor: 'SJ' , shortCutKey : 'I' ,  sectionKey : 'CONDO_CORPORATION' , hideForProvince: ['BC']},

    { title: MatterSectionsTitles.STRATA_CORPORATION, route: 'strataCorporation', active: false , applicableFor: 'PM', shortCutKey : 'H' ,  sectionKey : 'CONDO_CORPORATION' , hideForProvince: ['AB','MB','NB','NS','ON','SK']},
    { title: MatterSectionsTitles.STRATA_CORPORATION, route: 'strataCorporation', active: false , applicableFor: 'SJ' , shortCutKey : 'I' ,  sectionKey : 'CONDO_CORPORATION' , hideForProvince: ['AB','MB','NB','NS','ON','SK']},

    { title: MatterSectionsTitles.FIRE_INSURANCE, route: 'fireInsurance', active: false , applicableFor: 'P' , shortCutKey : 'I' ,  sectionKey : 'FIRE_INSURANCE'},
    { title: MatterSectionsTitles.PURCHASER_FIRE_INSURANCE, route: 'fireInsurance', active: false , applicableFor: 'SJ' , shortCutKey : 'J' ,  sectionKey : 'FIRE_INSURANCE', hideForProvince : ['BC']},
    { title: MatterSectionsTitles.FIRE_INSURANCE, route: 'fireInsurance', active: false , applicableFor: 'M' , shortCutKey : 'I' ,  sectionKey : 'FIRE_INSURANCE'},

    { title: MatterSectionsTitles.REQUISITIONS, route: 'requisitions', active: false , applicableFor: 'PM' , shortCutKey : 'J' ,  sectionKey : 'REQUISITIONS', hideForProvince: ['AB', 'MB', 'SK', 'NB','NS']},
    { title: MatterSectionsTitles.RESPONSE_TO_REQUISITIONS, route: 'requisitionResponse', active: false , applicableFor: 'SJ' , shortCutKey : 'K' ,  sectionKey : 'REQUISITIONS', hideForProvince: ['AB', 'MB', 'SK', 'NB','NS','BC']},

    { title: MatterSectionsTitles.UNDERTAKINGS_RECEIVED, route: MatterSectionsRouteTypes.UNDERTAKINGSRECEIVED, active: false , applicableFor: 'P' , shortCutKey : 'K' ,  sectionKey : 'UNDERTAKINGS'},
    { title: MatterSectionsTitles.UNDERTAKINGS_GIVEN, route: MatterSectionsRouteTypes.UNDERTAKINGSGIVEN, active: false , applicableFor: 'SJ' , shortCutKey : 'L' ,  sectionKey : 'UNDERTAKINGS'},
    { title: MatterSectionsTitles.UNDERTAKINGS, route: MatterSectionsRouteTypes.UNDERTAKINGS, active: false , applicableFor: 'MD' , shortCutKey : 'K' ,  sectionKey : 'UNDERTAKINGS'},

    { title: MatterSectionsTitles.STATEMENT_OF_ADJUSTMENTS, route: 'statementOfAdjustments', active: false , applicableFor: 'P' , shortCutKey : 'L' ,  sectionKey : 'STATEMENT_OF_ADJUSTMENT'},
    { title: MatterSectionsTitles.STATEMENT_OF_ADJUSTMENTS, route: 'statementOfAdjustments', active: false , applicableFor: 'SJ' , shortCutKey : 'M'  ,  sectionKey : 'STATEMENT_OF_ADJUSTMENT', hideForProvince: ['BC']},

    { title: MatterSectionsTitles.DIRECT_REFUNDS, route: 'directionReFunds', active: false , applicableFor: 'SJ', shortCutKey : 'N' ,  sectionKey : 'DIRECTION_RE_FUNDS' , hideForProvince: ['NS','BC']},

    { title: MatterSectionsTitles.SUPPLEMENTAL_TASKS, route: MatterSectionsRouteTypes.SUPPLEMENTALTASKS, active: true , applicableFor: 'P', shortCutKey : 'M' ,  sectionKey : 'SUPPLEMENTAL_TASKS'},
    { title: MatterSectionsTitles.SUPPLEMENTAL_TASKS, route: MatterSectionsRouteTypes.SUPPLEMENTALTASKS, active: true , applicableFor: 'SJ', shortCutKey : 'O' ,  sectionKey : 'SUPPLEMENTAL_TASKS'},
    { title: MatterSectionsTitles.SUPPLEMENTAL_TASKS, route: MatterSectionsRouteTypes.SUPPLEMENTALTASKS, active: true , applicableFor: 'M', shortCutKey : 'L' ,  sectionKey : 'SUPPLEMENTAL_TASKS'},
    { title: MatterSectionsTitles.SUPPLEMENTAL_TASKS, route: MatterSectionsRouteTypes.SUPPLEMENTALTASKS, active: true , applicableFor: 'C', shortCutKey : 'M' ,  sectionKey : 'SUPPLEMENTAL_TASKS'},

    { title: MatterSectionsTitles.STATEMENT_OF_ACCOUNT, route: 'statementOfAccount', active: false , applicableFor: 'P', shortCutKey : 'N' ,  sectionKey : 'STATEMENT_OF_ACCOUNT'},
    { title: MatterSectionsTitles.STATEMENT_OF_ACCOUNT, route: 'statementOfAccount', active: false , applicableFor: 'SJ', shortCutKey : 'P' ,  sectionKey : 'STATEMENT_OF_ACCOUNT'},
    { title: MatterSectionsTitles.STATEMENT_OF_ACCOUNT, route: 'statementOfAccount', active: false , applicableFor: 'MD', shortCutKey : 'M' ,  sectionKey : 'STATEMENT_OF_ACCOUNT'},
    { title: MatterSectionsTitles.STATEMENT_OF_ACCOUNT, route: 'statementOfAccount', active: false , applicableFor: 'C', shortCutKey : 'N' ,  sectionKey : 'STATEMENT_OF_ACCOUNT'},
    { title: MatterSectionsTitles.STATEMENT_OF_ACCOUNT, route: 'statementOfAccount', active: false , applicableFor: 'O', shortCutKey : 'N' ,  sectionKey : 'STATEMENT_OF_ACCOUNT'},
    { title: MatterSectionsTitles.STATEMENT_OF_ACCOUNT, route: 'statementOfAccount', active: false , applicableFor: 'W', shortCutKey : 'N' ,  sectionKey : 'STATEMENT_OF_ACCOUNT'},

    { title: MatterSectionsTitles.TRUST_LEDGER, route: 'trustLedger', active: false , applicableFor: 'P', shortCutKey : 'O' ,  sectionKey : 'TRUST_LEDGER'},
    { title: MatterSectionsTitles.TRUST_LEDGER, route: 'trustLedger', active: false , applicableFor: 'SJ', shortCutKey : 'Q' ,  sectionKey : 'TRUST_LEDGER'},
    { title: MatterSectionsTitles.TRUST_LEDGER, route: 'trustLedger', active: false , applicableFor: 'MD', shortCutKey : 'N' ,  sectionKey : 'TRUST_LEDGER'},

    { title: MatterSectionsTitles.REPORT_TO_PURCHASER, route: 'reportToPurchaser', active: false , applicableFor: 'P', shortCutKey : 'P' ,  sectionKey : 'REPORT_TO_PURCHASER'},

    { title: MatterSectionsTitles.EXECUTION_AFFADAVITS, route: 'executionAffidavits', active: false , applicableFor: 'P' , shortCutKey : 'Q' ,  sectionKey : 'EXECUTION_AFFIDAVITS', hideForProvince: ['AB', 'MB', 'SK','NB']},
    { title: MatterSectionsTitles.EXECUTION_AFFADAVITS, route: 'executionAffidavits', active: false , applicableFor: 'SJ' , shortCutKey : 'R' ,  sectionKey : 'EXECUTION_AFFIDAVITS', hideForProvince: ['AB', 'MB', 'SK','NB','BC']},
    { title: MatterSectionsTitles.EXECUTION_AFFADAVITS, route: 'executionAffidavits', active: false , applicableFor: 'M' , shortCutKey : 'O' ,  sectionKey : 'EXECUTION_AFFIDAVITS', hideForProvince: ['AB', 'MB', 'SK', 'NB']},

    { title: MatterSectionsTitles.FORMS, route: 'forms', active: false , applicableFor: 'PSMJ' , shortCutKey : '1' ,  sectionKey : 'FORMS'},

    { title: MatterSectionsTitles.DOCUMENT_PRODUCTION, route: 'documentProduction', active: false , applicableFor: 'PSMCDWJ' , shortCutKey : '2' ,  sectionKey : 'DOCUMENT_PRODUCTION'},

    { title: MatterSectionsTitles.MATTER_DOCUMENTS, route: MatterSectionsRouteTypes.MATTER_DOCUMENTS, active: false , applicableFor: 'PMCDW' , shortCutKey : '3' ,  sectionKey : 'MATTER_DOCUMENTS'},
    { title: MatterSectionsTitles.MATTER_DOCUMENTS, route: MatterSectionsRouteTypes.MATTER_DOCUMENTS, active: false , applicableFor: 'SJ' , shortCutKey : '3' ,  sectionKey : 'MATTER_DOCUMENTS'},
    { title: MatterSectionsTitles.OPPORTUNITY_DOCUMENTS, route: MatterSectionsRouteTypes.MATTER_DOCUMENTS, active: false , applicableFor: 'O' , shortCutKey : 'C' ,  sectionKey : 'MATTER_DOCUMENTS'},

    { title: MatterSectionsTitles.NOTES, route: MatterSectionsRouteTypes.NOTES, active: false , applicableFor: 'PMSCODWJ',  shortCutKey : '4', sectionKey : 'NOTES'},

    { title: MatterSectionsTitles.WORK_ITEMS, route: MatterSectionsRouteTypes.OPPORTUNITY_OVERVIEW, active: true , applicableFor: 'O' , shortCutKey : 'A' ,  sectionKey : 'OVERVIEW'},
    { title: MatterSectionsTitles.ASSOCIATED_FILES, route: MatterSectionsRouteTypes.ASSOCIATED_ITEMS, active: true , applicableFor: 'O' , shortCutKey : 'F' ,  sectionKey : 'ASSOCIATED_FILES'},

    { title: MatterSectionsTitles.TITLE_INSURANCE, route: 'titleInsurance', active: false , applicableFor: 'PM',  shortCutKey : '5' ,  sectionKey : 'TITLE_INSURANCE'},

    //Notes Added a new section for Correspondence which was Matter_Notifications for matter overview
    //The value correspondence in class  matter-notifications component etc. are the value for correspondence now.
    { title: MatterSectionsTitles.CORRESPONDENCE, route: MatterSectionsRouteTypes.CORRESPONDENCE, active: false , applicableFor: 'PSMCDW' , shortCutKey : '8' ,  sectionKey : 'OVERVIEW'},
    // shortCutKey : '6' was used by overview
];

export class MatterSections {

    static readonly matterSectionsMap : Map<string, Section[]> = new Map();

    static {
        matterSections.forEach((section) => {
        if (!this.matterSectionsMap.has(section.title)){
            this.matterSectionsMap.set(section.title, [])
        }
            this.matterSectionsMap.get(section.title).push(section)
        });
    }

    /**
     * Get Matter Sections ordered for a province
     * @param provinceCode province code of province
     */
    static getMatterSectionsOrderedForProvince(provinceCode?: string) : Section[] {
        let result : Section[] = [];

        let ordering : string[];
        switch(provinceCode) {
            case PROVINCE_CODES.BRITISH_COLOMBIA: {
                ordering = matterSectionsBCOrdering;
                break;
            }
            default: {
                return matterSections;
            }
        }

        //reorder matterSections based on 'ordering'
        ordering.forEach((sectionTitle) => result.push(...this.matterSectionsMap.get(sectionTitle)));

        return result;
    }
}

//Below are the sections for contact create/edit page. by default global information tab will be active
export const contactSections: Section[] = [
    { title: 'General Information', route: 'general-information', active: true },
    { title: 'My Contacts', route: 'my-contacts', active: false, applicableFor: 'nonDpUser' },
];

//Below are the sections for contact create/edit page. by default global information tab will be active
export const otherPartySections: Section[] = [
    { title: 'Profile', route: 'profile', active: true },

];

export const AssociatedMattersTitle = 'Associated Files';

// Below are the sections for contact create/edit page. by default porfile tab will be active
export const contactClientSections: Section[] = [
    { title: 'Profile', route: 'profile', active: true },
    { title: 'ID Details', route: 'id-details', active: false },
    { title: 'Notes', route: 'notes', active: false },
    { title: AssociatedMattersTitle, route: 'associated-matters', active: false },
    { title: 'Relationships', route: 'relationships', active: false },
];

//Below are the sections for contact insurer broker page. by default general information tab will be active
export const insuranceBrokersSections: Section[] = [
    { title: 'General Information', route: 'general-information', active: true },
];

export const privateLenderSections: Section[] = [
    { title: 'General Information', route: 'general-information', active: true }
];

export const lawFirmSections: Section[] = [
    { title: 'Law Firm', route: 'law-firm', active: true },
    { title: 'Solicitors', route: 'solicitor', active: false },
    { title: AssociatedMattersTitle, route: 'associated-matters', active: false },
];

export const lawFirmSectionsBCTitleMapper = {
    'Law Firm': 'Law/Notary Firm',
    'Solicitors': 'Lawyers/Notaries'
};

export const contactManagementCompanySections: Section[] = [
    { title: 'General Information', route: 'general-information', active: true },
    { title: 'My Contacts', route: 'my-contacts', active: false, applicableFor: 'nonDpUser' },
];

export const mortgageBrokerSections: Section[] = [
    { title: 'General Information', route: 'general-information', active: true },
    { title: 'My Contacts', route: 'my-contacts', active: false, applicableFor: 'nonDpUser' }
];

export const ResidenceAssociationSections: Section[] = [
    { title: 'General Information', route: 'general-information', active: true }
];

export const condoCorpSections: Section[] = [
    { title: 'General Information', route: 'general-information', active: true },
    { title: "Status Certificate<br/>Information", route: 'status-certificate-information', active: false },
    { title: 'My Contacts', route: 'my-contacts', active: false }
];

export const jurisdictionDepartmentsSections: Section[] = [
    { title: 'General', route: 'general', active: true },
    { title: "Land Registry Office", route: 'land-registry-office', active: false },
];

export const jurisdictionDepartmentsGeneral: Section =
    { title: jurisdictionDepartmentName.GENERAL, route: 'general', active: true }
;

export const jurisdictionDepartmentsLandRegistryOffice: Section =
    { title: jurisdictionDepartmentName.LAND_REGISTRY_OFFICE, route: 'land-registry-office', active: false }
;

export const jurisdictionDepartmentsLandTitlesOffice: Section =
    { title: jurisdictionDepartmentName.LAND_TITLES_OFFICE, route: 'land-registry-office', active: false }
;

export const jurisdictionSecondLandTitlesOffice: Section =
    { title: jurisdictionDepartmentName.LAND_TITLES_OFFICE, route: 'land-title-office', active: false }
;

export const jurisdictionDepartmentsLandRegistrationOffice: Section =
    { title: jurisdictionDepartmentName.LAND_REGISTRATION_OFFICE, route: 'land-registry-office', active: false }
;

// TODO: move to StaffProfiles dirctory
export const StaffProfilesSections: Section[] = [
    { title: 'Personal Details', route: 'personal-details', active: true },
    { title: 'Unity User Details', route: 'unity-user-details', active: true },
    { title: 'Trust Accounts', route: 'trust-accounts', active: true },
];

export const DocumentProfilesSections: Section[] = [
    { title: 'General', route: 'general', active: true },
    { title: 'Law Firm', route: 'law-firm', active: true },
    { title: 'Template Folder', route: 'template-folder', active: true },
    { title: 'SOA Preferences', route: 'soa-preferences', active: true },
    { title: 'Misc', route: 'misc', active: true }
];

export const DocumentProfilesSectionsBC: Section[] = [
    { title: 'General', route: 'general', active: true },
    { title: 'Law/Notary Firm', route: 'law-firm', active: true },
    { title: 'Template Folder', route: 'template-folder', active: true },
    { title: 'Misc', route: 'misc', active: true }
];
