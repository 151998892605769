import moment from 'moment';
import Utils from '../../../shared-main/utils';
import {RentInterestRate} from './rent-interest-rate';
import {InterestRateUtils} from '../../../shared-main/interest-rate-utils';
import {InterestDateCalendarYearItem} from '../../../shared-main/interest-date-calendar-year-item';

export class SoAdjTenancyPrepaidUtil {

    static calculateRatesForFlatInterestRate(fromDate: string, toDate: string, flatInterestRate: number, amount: number, isPaysForDateOfClosingVendor?:boolean): InterestDateCalendarYearItem[]{
        return InterestRateUtils.calculateInterestPerCalendarYear(fromDate, toDate, flatInterestRate, amount, isPaysForDateOfClosingVendor);
    }

    static updateInterestItemWithInterestAmountOfMultipleYearPeriod(
        item: InterestDateCalendarYearItem, amount: number, interestRate: RentInterestRate,
        isPaysForDateOfClosingVendor?:boolean
    ) {
        let currentYear = moment(item.fromDate).year();
        let toDateYear = moment(item.toDate).year();
        while (currentYear <= toDateYear) {
            let periodStartDate = moment(item.fromDate).year() == currentYear?
                item.fromDate: `${currentYear}/01/01`;
            let periodEndDate = toDateYear == currentYear?
                item.toDate: `${currentYear}/12/31`;

            let numberOfDays: number = Utils.getDateDiff(periodStartDate, periodEndDate);
            if(
                (periodEndDate == `${currentYear}/12/31` || periodEndDate == interestRate.toDate) ||
                isPaysForDateOfClosingVendor
            ){
                numberOfDays++; // include also the last day of the period
            }
            if(item.numberOfDays)
                item.numberOfDays += numberOfDays;
            else
                item.numberOfDays = numberOfDays;

            if (item.rateAmount)
                item.rateAmount += Utils.roundCurrency(
                    InterestRateUtils.getInterestPerDay(item.rate, periodStartDate) *
                    numberOfDays * amount / 100
                );
            else
                item.rateAmount = Utils.roundCurrency(
                    InterestRateUtils.getInterestPerDay(item.rate, periodStartDate) *
                    numberOfDays * amount / 100
                );
            currentYear ++;
        }
        return item;
    }

    static calculateRatesBasedOnOntarioResidentialTenanciesAct(fromDate: string, toDate: string, amount: number, rentInterestRates: RentInterestRate[], isPaysForDateOfClosingVendor?:boolean): InterestDateCalendarYearItem[]{
        let items: InterestDateCalendarYearItem[] = [];
        if (fromDate) {
            for (let i: number = 0; i < rentInterestRates.length; i++) {
                if (moment(fromDate).isBetween(rentInterestRates[i].fromDate, rentInterestRates[i].toDate, null, '[]')) {
                    let item: InterestDateCalendarYearItem = new InterestDateCalendarYearItem();
                    item.fromDate = fromDate;
                    item.rate = rentInterestRates[i].interestRate;

                    // InterestRatePeriod has same year for from and to date
                    if (moment(rentInterestRates[i].fromDate).year() == moment(rentInterestRates[i].toDate).year()){
                        // toDate and fromDate are from the same period
                        if (moment(toDate).isBetween(rentInterestRates[i].fromDate, rentInterestRates[i].toDate, null, '[]')) {
                            item.toDate = toDate;
                            let numberOfDays: number = Utils.getDateDiff(item.fromDate, item.toDate);
                            if(isPaysForDateOfClosingVendor){
                                numberOfDays++;
                            }
                            item.numberOfDays =  numberOfDays;
                            item.rateAmount = Utils.roundCurrency(InterestRateUtils.getInterestPerDay(item.rate, item.fromDate) * item.numberOfDays * amount / 100);
                            items.push(item);
                            break;
                        }
                        else {
                            item.toDate = rentInterestRates[i].toDate;
                            item.numberOfDays = Utils.getDateDiff(item.fromDate, item.toDate) + 1; // include also the last day of the period
                            item.rateAmount = Utils.roundCurrency(InterestRateUtils.getInterestPerDay(item.rate, item.fromDate) * item.numberOfDays * amount / 100);
                            items.push(item);

                            // look in the next period if exist in the rates table
                            if (i > 0) {
                                items.push(...SoAdjTenancyPrepaidUtil.calculateRatesBasedOnOntarioResidentialTenanciesAct(rentInterestRates[i - 1].fromDate, toDate, amount, rentInterestRates, isPaysForDateOfClosingVendor));
                            }
                            else{
                                console.error(`Rent interest rates are not defined for all or parts of selected period (${fromDate} - ${toDate})`);
                            }
                        }
                    }
                    else {
                        // toDate and fromDate are from the same period
                        if (moment(toDate).isBetween(rentInterestRates[i].fromDate, rentInterestRates[i].toDate, null, '[]')) {
                            item.toDate = toDate;
                            item = this.updateInterestItemWithInterestAmountOfMultipleYearPeriod(
                                item, amount, rentInterestRates[i], isPaysForDateOfClosingVendor
                            )
                            items.push(item);
                            break;
                        }
                        else {
                            item.toDate = rentInterestRates[i].toDate;
                            item = this.updateInterestItemWithInterestAmountOfMultipleYearPeriod(
                                item, amount, rentInterestRates[i], isPaysForDateOfClosingVendor
                            )
                            items.push(item);

                            // look in the next period if exist in the rates table
                            if (i > 0) {
                                items.push(...SoAdjTenancyPrepaidUtil.calculateRatesBasedOnOntarioResidentialTenanciesAct(rentInterestRates[i - 1].fromDate, toDate, amount, rentInterestRates, isPaysForDateOfClosingVendor));
                            }
                            else{
                                console.error(`Rent interest rates are not defined for all or parts of selected period (${fromDate} - ${toDate})`);
                            }
                        }
                    }
                }
            }
        }
        return items;
    }

}
