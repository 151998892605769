import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {Observable, Subject} from 'rxjs/Rx';
import {AutoComplete} from 'primeng/autocomplete';
import {Utils} from '../../matters/shared/utils';

export const NO_RECORDS_AVAILABLE = 'No records available';

@Component({
    selector: 'dp-document-profile-auto-complete-select',
    templateUrl: 'document-profile-auto-complete-select.component.html'
})
export class DocumentProfileAutoCompleteSelectComponent implements OnInit, OnDestroy {
    selectedAutoCompleteItem: any; //It can be object or string;
    searchAutoCompleteItem = new Subject<string>();
    autoCompleteSelectSubscription: any;
    autoCompleteSelectSuggestions: any[] = [];
    prevSelectedFolder: number;
    _autoCompleteSelectOptions: any[] = [];
    setDefaultFlag: boolean = false;
    autoCompleteIdOrName: string;
    _defaultDocumentProfileId: number;

    @Output() onChange = new EventEmitter();
    @Input('index') index: number;
    @Input('fieldValueName') fieldValueName: string;
    @Input('fieldLabelName') fieldLabelName: string;

    @Input() set defaultDocumentProfileId(defaultDocumentProfileId: number) {
        this._defaultDocumentProfileId = defaultDocumentProfileId;
        if (!this.setDefaultFlag) {//sometime the default value comes from backend, it can't get it when run ngOnInit()
            this.setDefault();
        }
    }

    get defaultDocumentProfileId(): number {
        return this._defaultDocumentProfileId;
    }

    @Input() set autoCompleteSelectOptions(autoCompleteSelectOptions: any[]) {
        if (Array.isArray(autoCompleteSelectOptions)) {
            this._autoCompleteSelectOptions = autoCompleteSelectOptions;
            if (!this.setDefaultFlag) {//sometime the dropdown list comes from backend, it can't get it when run ngOnInit()
                this.setDefault();
            }
        } else {
            this._autoCompleteSelectOptions = [];
        }
    }

    get autoCompleteSelectOptions(): any[] {
        return this._autoCompleteSelectOptions;
    }

    @ViewChild('autoCompleteSelect') autoCompleteSelect: AutoComplete;

    constructor(public logger: Logger,
                public elementRef: ElementRef) {
    }

    getSuggestions(term: string): Observable<any> {
        if (term && term.trim()) {
            let suggestions = [];
            if (this.fieldLabelName) {
                suggestions = this.autoCompleteSelectOptions && this.autoCompleteSelectOptions.filter(item => item[this.fieldLabelName] && item[this.fieldLabelName].toLowerCase().includes(term.trim().toLowerCase()));
            }
            return Observable.of(suggestions);
        } else {
            return Observable.of(this.autoCompleteSelectOptions);
        }
    }

    getNoRecordItem(): any {
        let noRecorditem = {};
        noRecorditem[this.fieldLabelName] = NO_RECORDS_AVAILABLE;
        noRecorditem[this.fieldValueName] = null;
        return noRecorditem;
    }

    setDefault() {
        if (this.defaultDocumentProfileId && this.autoCompleteSelectOptions && this.autoCompleteSelectOptions.length > 0 && this.fieldValueName) {
            this.prevSelectedFolder = this.defaultDocumentProfileId
            const selectedItem = this.autoCompleteSelectOptions.find(item => item[this.fieldValueName] == this.defaultDocumentProfileId);
            this.selectedAutoCompleteItem = selectedItem && selectedItem[this.fieldLabelName];
            this.setDefaultFlag = true;
        }
    }

    ngOnInit() {
        this.autoCompleteIdOrName = this.index ? ('autocomplete-select' + '-' + this.index) : 'autocomplete-select';
        this.prevSelectedFolder = this.defaultDocumentProfileId;
        this.setDefault();
        this.searchAutoCompleteItem = Utils.refreshSubject(this.searchAutoCompleteItem);
        this.autoCompleteSelectSubscription = this.searchAutoCompleteItem
            .switchMap((term: string) => {
                return this.getSuggestions(term);
            })
            .subscribe(
                (suggestions) => {

                    if (Array.isArray(suggestions) && suggestions.length > 0) {
                        //Use slice to copy a new array and not mess up the input options list
                        this.autoCompleteSelectSuggestions = suggestions.slice();
                    } else {
                        this.autoCompleteSelectSuggestions = [];
                        this.autoCompleteSelectSuggestions.push(this.getNoRecordItem());

                    }
                },
                err => this.logger.warn(err)
            );
    }

    isSelectedNoRecordItem(event) {
        return this.autoCompleteSelectSuggestions && (this.autoCompleteSelectSuggestions.length === 1)
            && !event[this.fieldValueName] && (event[this.fieldLabelName] === NO_RECORDS_AVAILABLE);
    }

    dataSelected(event) {
        setTimeout(() => {
            if (this.isSelectedNoRecordItem(event)) {
                if (this.prevSelectedFolder) {
                    const selectedItem = this.autoCompleteSelectOptions.find(item => item[this.fieldValueName] == this.prevSelectedFolder);
                    this.selectedAutoCompleteItem = selectedItem && selectedItem[this.fieldLabelName];
                } else {
                    this.selectedAutoCompleteItem = null
                }
            } else if (event && event[this.fieldValueName]) {
                this.prevSelectedFolder = event[this.fieldValueName];
                this.selectedAutoCompleteItem = event[this.fieldLabelName];
                this.onChange.emit(event[this.fieldValueName]);
            }
        }, 0);

    }

    showTemplateFoldersList() {
        this.autoCompleteSelectSuggestions = this.autoCompleteSelectSuggestions && this.autoCompleteSelectSuggestions.slice();
    }

    /**
     *  Click Space key, it alway show dropdown list no matter if the ngModel of p-autoComplete has value
     *  Select an item, click enter key p-autoComplete select this item automatically
     *  Use p-autoComplete to simulate a normal selection
     * @param event
     */
    displayOptionList(event?: any) {
        if (event) {
            event.stopImmediatePropagation();
        }
        this.showTemplateFoldersList();
        this.autoCompleteSelect.show();
    }

    searchOnDropDownList(event): void {
        let entered: string = event.query;
        this.searchAutoCompleteItem.next(entered);
    }

    onLeavingOmniBarWithoutFreeInput() {
        setTimeout(() => {
            let option = this.autoCompleteSelectOptions && this.autoCompleteSelectOptions.find(item => item[this.fieldLabelName] == this.selectedAutoCompleteItem);
            if (!option || option[this.fieldValueName] != this.prevSelectedFolder) {
                if (this.prevSelectedFolder) {
                    const selectedItem = this.autoCompleteSelectOptions && this.autoCompleteSelectOptions.find(item => item[this.fieldValueName] == this.prevSelectedFolder);
                    if (selectedItem) {
                        this.selectedAutoCompleteItem = selectedItem[this.fieldLabelName];
                        this.onChange.emit(this.prevSelectedFolder);
                    }
                } else {
                    this.selectedAutoCompleteItem = null;
                    this.onChange.emit(null);
                }
            }
        }, 0);

    }

    autoDisplayItem(event): string {
        return event && event[this.fieldLabelName];
    }

    ngOnDestroy(): void {
    }

}

