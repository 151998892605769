import {BaseEntity} from '../../../shared/BaseEntity/base-entity';


export type StatementOfAdjustmentCreditType = 'VENDOR' | 'PURCHASER' | 'NO_ADJUSTMENT';
export type AdditionalVendorConsiderationType =  'NO' | 'YES_ELIGIBLE_FOR_REBATE' | 'YES_NOT_ELIGIBLE_FOR_REBATE' | 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX';

export class SoAdjHCRAFee extends BaseEntity {
    id: number;

    creditType: StatementOfAdjustmentCreditType;

    enrolmentPeriodId: number;
    builderRegistrationNumber: string;
    enrolmentNumber: string;
    applicableSection: string;

    taxType: string;

    hstRate: number;
    gstRate: number;
    pstRate: number;

    applyPst: boolean;
    amountAdditionalConsiderationToVendor: AdditionalVendorConsiderationType;

    enrolmentFee: number;


    get adjustmentDescription(): string {
        return 'HCRA REGULATORY OVERSIGHT FEE';
    }

    get appliedTax(): number{
        if (this.taxType == 'GST'){
            return this.gstRate;
        }
        if (this.taxType == 'PST'){
            return this.pstRate;
        }
        return this.hstRate;
    }

    get enrolmentFeePlusTax(): number {
        return Number(this.enrolmentFee) + Number((this.enrolmentFee * Number(this.appliedTax)) / 100);
    }
}
