import {api} from '../../common/api';

export const cirfConfigApi = {
    getCirfConfig(accountId: string, matterType: string): string {
        return `${api}/accounts/${accountId}/cirfConfig/matterType/${matterType}`
    },
    updateCirfConfig(accountId: string): string {
        return `${api}/accounts/${accountId}/cirfConfig`
    }
};
