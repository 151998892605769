import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormFieldComponent} from './form-field.component';

@NgModule({
imports: [
CommonModule,
FormsModule,
ReactiveFormsModule,
],
declarations: [
    FormFieldComponent,
],
exports: [
    FormFieldComponent
],
providers: [
]
})
export class FormFieldsModule { }
