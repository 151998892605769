// User model for login page
import {BaseEntity} from '../shared/BaseEntity/base-entity';

export class LoginDTO extends BaseEntity{
    public loginId: string;
    public publicAccountId: string;
    public password: string;
    public userStatus: string;
    public rememberMe: boolean;
    public registrationToken: string;
    public agentId: string;

}
