import {Mortgage} from '../mortgage';
import {MatterParticipant} from '../matter-participant';
import {Matter} from '../matter';
import {ProgressionStatus} from '../../statement-adjustment/statement-adjustment';
import {SoaTrustLedgeConfigKeys} from '../soa-trustledger-collection';
import {TrustLedgerMatterMortgageObjectTemplate} from '../matter-specific-type';
import {SoaConstants} from '../../../admin/soa/soa-constants';
import Utils from '../../../shared-main/utils';

export class MatterTrustLedgerUtil {
    static reCalculateTrustLedgerMortgageeLineByMortgage(matter: Matter, mortgage: Mortgage): void {
        if (matter) {
            let reLineNames: string[] = [];
            matter.getMortgagees(mortgage).forEach((matterParticipant: MatterParticipant) => {
                let nameForReLine: string = matterParticipant.contact.reLineFirstNameFormat;
                if (!reLineNames.some(x => x === nameForReLine)) {
                    reLineNames.push(nameForReLine);
                }
            });
            if (matter.soaTrustLedgerCollection) {
                let privateLenderNames: string = reLineNames.length < 2 ? reLineNames.join(', ') : reLineNames.slice(0, -1).join(', ') + ' and ' + reLineNames.slice(-1);
                if (mortgage.isExistingMortgage()) {
                    matter.soaTrustLedgerCollection.updateTrustLedgerExistingPrivateLenderNames(matter.getExistingMortgageIndexById(mortgage.id),
                        privateLenderNames,
                        mortgage.isMortgageDispositionDischarged(),
                        mortgage.isMortgageDispositionTypeBridgeFinancing(), mortgage);
                } else {
                    if (matter.isProjectSale) {
                        privateLenderNames = mortgage.lenderReline;
                    }
                    matter.soaTrustLedgerCollection.updateTrustLedgerPrivateLenderNames(matter.getMortgageIndexById(mortgage.id),
                        privateLenderNames,
                        mortgage.isLoanTypeArranged(),
                        mortgage.isLoanTypeBridge());
                }
            }
        }
    }

    static reCalculateTrustLedgerReceivedFromPurchaserLine(matter: Matter): void {
        if (matter) {
            let reLineNames: string[] = [];
            matter.otherSideClients.forEach((matterParticipant: MatterParticipant) => {
                let nameForReLine: string = matterParticipant.contact.reLineFirstNameFormat;
                if (!reLineNames.some(x => x === nameForReLine)) {
                    reLineNames.push(nameForReLine);
                }
            });
            if (matter.soaTrustLedgerCollection) {
                let purchaserNames: string = reLineNames.length < 2 ? reLineNames.join(', ') : reLineNames.slice(0, -1).join(', ') + ' and ' + reLineNames.slice(-1);
                matter.soaTrustLedgerCollection.updateTrustLedgerReceivedFromPurchaserNames(purchaserNames);
                if (matter.isProjectConfigDocForTrustLedger()) {
                    let progressStatus = matter.soaTrustLedgerCollection.progressionStatus;
                    matter.soaTrustLedgerCollection.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
                    matter.soaTrustLedgerCollection.updateTrustLedgerReceivedFromPurchaserNames(purchaserNames);
                    matter.soaTrustLedgerCollection.progressionStatus = progressStatus;
                }

            }
        }
    }

    static reCalculateTrustLedgerPurchaserLine(matter: Matter): void {
        if (matter) {
            let reLineNames: string[] = [];

            // It is valid for other matter types. so we should use mainClients
            matter.mainClients.forEach((matterParticipant: MatterParticipant) => {
                let nameForReLine: string = matterParticipant.contact.reLineFirstNameFormat;
                if (!reLineNames.some(x => x === nameForReLine)) {
                    reLineNames.push(nameForReLine);
                }
            });
            if (matter.soaTrustLedgerCollection) {
                let purchaserNames: string = reLineNames.length < 2 ? reLineNames.join(', ') : reLineNames.slice(0, -1).join(', ') + ' and ' + reLineNames.slice(-1);
                matter.soaTrustLedgerCollection.updateTrustLedgerPurchaserNames(purchaserNames);
            }
        }
    }

    static  reCalculateTrustLedgerPrivateLenderLine(matter: Matter, mortgage: Mortgage): void {

        if (matter) {
            let reLineNames: string[] = [];

            matter.privateLenders.forEach((matterParticipant: MatterParticipant) => {
                let nameForReLine: string = matterParticipant.contact.reLineFirstNameFormat;
                if (!reLineNames.some(x => x === nameForReLine)) {
                    reLineNames.push(nameForReLine);
                }
            });
            let privateLenderNames: string = reLineNames.length < 2 ? reLineNames.join(', ') : reLineNames.slice(0, -1).join(', ') + ' and ' + reLineNames.slice(-1);
            if (matter.soaTrustLedgerCollection) {
                if (mortgage.isExistingMortgage()) {
                    matter.soaTrustLedgerCollection.updateTrustLedgerExistingPrivateLenderNames(matter.getExistingMortgageIndexById(mortgage.id),
                        privateLenderNames,
                        mortgage.isMortgageDispositionDischarged(),
                        mortgage.isMortgageDispositionTypeBridgeFinancing(), mortgage);
                } else {
                    matter.soaTrustLedgerCollection.updateTrustLedgerPrivateLenderNames(matter.getMortgageIndexById(mortgage.id),
                        privateLenderNames,
                        mortgage.isLoanTypeArranged(),
                        mortgage.isLoanTypeBridge());
                }
            }
        }
    }

    static reCalculateTrustLedgerPrivateLenderLineByMortgage(matter: Matter, mortgage: Mortgage): void {
        if (matter) {
            let reLineNames: string[] = [];

            matter.getPrivateLenders(mortgage).forEach((matterParticipant: MatterParticipant) => {
                let nameForReLine: string = matterParticipant.contact.reLineFirstNameFormat;
                if (!reLineNames.some(x => x === nameForReLine)) {
                    reLineNames.push(nameForReLine);
                }
            });
            let privateLenderNames: string = reLineNames.length < 2 ? reLineNames.join(', ') : reLineNames.slice(0, -1).join(', ') + ' and ' + reLineNames.slice(-1);
            if (matter.soaTrustLedgerCollection) {
                if (mortgage.isExistingMortgage()) {
                    matter.soaTrustLedgerCollection.updateTrustLedgerExistingPrivateLenderNames(matter.getExistingMortgageIndexById(mortgage.id),
                        privateLenderNames,
                        mortgage.isMortgageDispositionDischarged(),
                        mortgage.isMortgageDispositionTypeBridgeFinancing(), mortgage);
                } else {
                    matter.soaTrustLedgerCollection.updateTrustLedgerPrivateLenderNames(matter.getMortgageIndexById(mortgage.id),
                        privateLenderNames,
                        mortgage.isLoanTypeArranged(),
                        mortgage.isLoanTypeBridge());
                }

            }
        }
    }

    static sortTrustLedgerMatterByPriority(matter: Matter) {
        if (matter.soaTrustLedgerCollection && Array.isArray(matter.soaTrustLedgerCollection.matterTrustLedgers) && matter.soaTrustLedgerCollection.matterTrustLedgers.length > 0) {
            const filteredMatterTrustLedgers = matter.soaTrustLedgerCollection.matterTrustLedgers
                .filter(matterTrustLedger => matterTrustLedger.itemKey === SoaTrustLedgeConfigKeys.L1_MG);

            filteredMatterTrustLedgers.sort((a, b): number => {
                const priorityA: number = matter.getMortgagePriorityByMortgageIndex(a.fromMortgageIndex);
                const priorityB: number = matter.getMortgagePriorityByMortgageIndex(b.fromMortgageIndex);

                return priorityA > priorityB
                    ? 1
                    : (priorityA < priorityB ? -1 : 0);
            });
            // New logic after allowing trust legder to be arranged by the user. The old logic has to be changed.
            let pos = matter.soaTrustLedgerCollection.matterTrustLedgers.findIndex(item => item.itemKey === SoaTrustLedgeConfigKeys.L1_MG && item.fromMortgageIndex == 0);
            if (pos > -1) {
                let firstPos = matter.soaTrustLedgerCollection.matterTrustLedgers.findIndex(item => item.itemKey === SoaTrustLedgeConfigKeys.L1_MG);
                if (firstPos && firstPos != -1 && firstPos < pos) {
                    pos = pos - 1;
                }
                // remove filteredMatterTrustLedgers
                for (let trustLedger of filteredMatterTrustLedgers) {
                    (<any>matter.soaTrustLedgerCollection.matterTrustLedgers).remove(trustLedger);
                }
                // Add filteredMatterTrustLedgers at the new position
                matter.soaTrustLedgerCollection.matterTrustLedgers.splice(pos, 0, ...filteredMatterTrustLedgers);

            }
        }
    }

    static sortTrustLedgerMatterByMortgageIndex(matter: Matter, configKey: string, extraKey?: string) {
        if (matter.soaTrustLedgerCollection && Array.isArray(matter.soaTrustLedgerCollection.matterTrustLedgers) && matter.soaTrustLedgerCollection.matterTrustLedgers.length > 0) {
            let filteredMatterTrustLedgers = [];
            if (extraKey) {
                filteredMatterTrustLedgers = matter.soaTrustLedgerCollection.matterTrustLedgers
                    .filter(matterTrustLedger => matterTrustLedger.itemKey === configKey || matterTrustLedger.itemKey === extraKey);
            } else {
                filteredMatterTrustLedgers = matter.soaTrustLedgerCollection.matterTrustLedgers
                    .filter(matterTrustLedger => matterTrustLedger.itemKey === configKey);
            }


            filteredMatterTrustLedgers.sort((a, b): number => {
                return a.fromMortgageIndex > b.fromMortgageIndex
                    ? 1
                    : (a.fromMortgageIndex < b.fromMortgageIndex ? -1 : 0);
            });
            // New logic after allowing trust legder to be arranged by the user. The old logic has to be changed.
            let pos = matter.soaTrustLedgerCollection.matterTrustLedgers.findIndex(item => item.itemKey == configKey && item.fromMortgageIndex==0); //test with Mortgage matter
            if (pos > -1) {
                let firstPos: number;
                if(extraKey){
                    firstPos = matter.soaTrustLedgerCollection.matterTrustLedgers.findIndex(item => item.itemKey == configKey || item.itemKey == extraKey);
                } else {
                    firstPos = matter.soaTrustLedgerCollection.matterTrustLedgers.findIndex(item => item.itemKey == configKey);
                }
                if(firstPos && firstPos != -1 && firstPos < pos){
                    pos = pos -1;
                }
                // remove filteredMatterTrustLedgers
                for(let trustLedger of filteredMatterTrustLedgers) {
                    (<any>matter.soaTrustLedgerCollection.matterTrustLedgers).remove(trustLedger);
                }
                // Add filteredMatterTrustLedgers at the new position
                matter.soaTrustLedgerCollection.matterTrustLedgers.splice(pos, 0, ...filteredMatterTrustLedgers);
            }
        }
    }

    static updateTrustLedgerItemNameForUpdatePrioritySingleView(matter: Matter): void {
        if (matter && matter.soaTrustLedgerCollection && Array.isArray(matter.soaTrustLedgerCollection.matterTrustLedgers)) {
            matter.soaTrustLedgerCollection.matterTrustLedgers.forEach(matterTrustLedgerMG => {

                if (matterTrustLedgerMG.itemKey == SoaTrustLedgeConfigKeys.L1_MG) {
                    const mortgageePrivateLenderNames: string = matter.getMortgageePrivateLenderNamesByMortgageIndex(matterTrustLedgerMG.fromMortgageIndex);
                    if (matter.isMortgage) {
                        matterTrustLedgerMG.itemName
                            = SoaConstants.RECEIVED_NEW_ARRANGED_FINANCING.replace('###',
                            matter.getMortgagePriorityByMortgageIndex(matterTrustLedgerMG.fromMortgageIndex) + '') + (mortgageePrivateLenderNames
                            ? ' - ' + mortgageePrivateLenderNames : '');
                    }
                    if (matter.isPurchase) {
                        matterTrustLedgerMG.itemName
                            = SoaConstants.RECEIVED_ARRANGED_FINANCING.replace('###',
                            matter.getMortgagePriorityByMortgageIndex(matterTrustLedgerMG.fromMortgageIndex) + '') + (mortgageePrivateLenderNames
                            ? ' - ' + mortgageePrivateLenderNames : '');
                    }
                    if (matter.isProjectSale) {
                        if (mortgageePrivateLenderNames) {
                            matterTrustLedgerMG.itemName = SoaConstants.RECEIVED_FROM_PURCHASER_MORTGAGEE
                                .replace('###', Utils.getOrdinal(matter.getMortgagePriorityByMortgageIndex(matterTrustLedgerMG.fromMortgageIndex))) + ' - ' + mortgageePrivateLenderNames;
                        } else {
                            matterTrustLedgerMG.itemName = SoaConstants.RECEIVED_FROM_PURCHASER_MORTGAGE
                                .replace('###', Utils.getOrdinal(matter.getMortgagePriorityByMortgageIndex(matterTrustLedgerMG.fromMortgageIndex)));
                        }

                    }
                }
            });
        }
    }

    static buildTrustLedgerMatterMortgageObjectTemplate(matter: Matter): TrustLedgerMatterMortgageObjectTemplate[] {
        let templates: TrustLedgerMatterMortgageObjectTemplate[] = [];
        if (matter.soaTrustLedgerCollection && Array.isArray(matter.soaTrustLedgerCollection.matterTrustLedgers)) {
            matter.soaTrustLedgerCollection.matterTrustLedgers.forEach(matterTrustLedger => {
                if (matterTrustLedger.itemKey == SoaTrustLedgeConfigKeys.L1_MG) {
                    // let matterTrustLedger : TrustLedgerMatter;
                    let template: TrustLedgerMatterMortgageObjectTemplate = new TrustLedgerMatterMortgageObjectTemplate();
                    template.trustLedgerMatter = matterTrustLedger;
                    if (Array.isArray(matter.mortgages)
                        && matterTrustLedger.fromMortgageIndex != null
                        && matter.mortgages.length > matterTrustLedger.fromMortgageIndex
                        && matterTrustLedger.fromMortgageIndex >= 0) {
                        template.mortgage = matter.mortgages[matterTrustLedger.fromMortgageIndex];
                        templates.push(template);
                    }
                }
            });
        }

        return templates;
    }

    static updateInterimTrustLedger(matter: Matter): void {
        if (matter && matter.soaTrustLedgerCollection && matter.isProjectConfigDocForTrustLedger()) {
            let currentProgressionStatus = matter.soaTrustLedgerCollection.progressionStatus ? matter.soaTrustLedgerCollection.progressionStatus : ProgressionStatus.FINAL;
            matter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.INTERIM;

            matter.calculateStatementAdjustment();
            matter.soaTrustLedgerCollection.addTrustLedgerMortgageRowForAllMortgages();
            matter.soaTrustLedgerCollection.addTrustLedgerHoldbacksRows();
            if (matter.mortgages && matter.mortgages.length > 0) {
                matter.soaTrustLedgerCollection.updateTLF9MortgageFee();
            }
            matter.soaTrustLedgerCollection.updateTrustLedgerPaidFees();
            if (matter.autoInsertAllF9Values) {
                matter.soaTrustLedgerCollection.updateF9TrustLedger();
            }

            matter.soaTrustLedgerCollection.progressionStatus = currentProgressionStatus;
        }
    }
}
