import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {InterestSoa} from '../interest-soa';
import {StatementAdjustmentKey} from '../statement-adjustment-constants';

export class StatementAdjustmentDisplayInterestBuilder {

    static getDisplayItems(soAdj: InterestSoa, mainBuilder: StatementAdjustmentDisplayBuilder, statementAdjustmentKey?: string): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        let provinceCode: string = mainBuilder.matter.provinceCode;

        sadItemDetails.push(mainBuilder.getNewItemLine('Principal amount', mainBuilder.getCurrency(soAdj.principalAmount)));
        sadItemDetails.push(mainBuilder.getNewItemLine(`Interest at ${mainBuilder.getPercentage(soAdj.interestRate)} calculated`, ''));
        sadItemDetails.push(mainBuilder.getNewItemLine(`from ${mainBuilder.formattedDate(soAdj.interestCalculatedFrom, true)} to`, ''));
        if (mainBuilder && mainBuilder.matter) {

            let isInterestOnDeferredMoniesAdjustment: boolean = (statementAdjustmentKey === StatementAdjustmentKey.INTEREST_ON_DEFERRED_MONIES_DURING_OCCUPANCY);
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${mainBuilder.formattedDate(soAdj.interestCalculatedTo, true)} (${soAdj.calculateDateDiff(mainBuilder.matter.isPaysForDateOfClosingVendor, provinceCode, isInterestOnDeferredMoniesAdjustment)} days)`,
                mainBuilder.getCurrency(soAdj.calculateSoAInterest(provinceCode, mainBuilder.matter.isPaysForDateOfClosingVendor, isInterestOnDeferredMoniesAdjustment))));
        }
        sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${soAdj.isInterestCreditTo}`, ''));

        return sadItemDetails;
    }
}
