import {BaseEntity} from "../../shared/BaseEntity/base-entity";
import {WillDocumentsToProduce} from "./matter-specific-type";
import {WillAssetAndDebts} from "./will-asset-and-debts";
import { WillTypeOfBeneficiary } from "./will-type-of-beneficiary";

export type CompensationType = 'NONE' | 'PERCENTAGE';
export type PropertyDutyToAccount = 'IMMEDIATE_FAMILY' | 'SIX_MONTHS_NOT_SPOUSE' | 'SIX_MONTHS';
export type GuardianType = 'INDIVIDUAL' | 'JOINT_CAN_ACT_ALONE' | 'JOINT_LEGALLY_MARRIED' | 'JOINT_COHABITING';
export type GrandchildrenInclusionType = 'ANY_CHILD' | 'ANY_CHILD_PLUS_STEP_GRANDCHILDREN' | 'NONE';
export type WillChildrenUnderAge = 'NO' | 'YES_ONLY_ONE' | 'YES'

export class WillMatter extends BaseEntity {
    id?: number;
    willDocumentsToProduce: WillDocumentsToProduce[];
    willAssetAndDebts: WillAssetAndDebts[];

    willCompensationType?: CompensationType;
    compensationPercentage?: number;
    willPropertyDutyToAccount?: PropertyDutyToAccount;
    propertyCompensation?: boolean;
    specificHealthInstructions?: boolean;
    accommodationInstructions?: boolean;
    addGuardianship?: boolean;
    addAlternateGuardianship?: boolean;
    includeChildOrChildrenNotYetBorn?: boolean;
    areAnyChildrenUnderAge?: WillChildrenUnderAge;
    guardianType?: GuardianType;
    alternateGuardianType?: GuardianType;
    grandchildrenInclusionType?: GrandchildrenInclusionType;
    willTypeOfBeneficiaries?: WillTypeOfBeneficiary[];
    cdCharityNameOne?: string;
    cdCharityNameTwo?: string;
    cdCharityRegOne?: string;
    cdCharityRegTwo?: string;

    constructor(willMatter?: WillMatter) {
        super(willMatter);
        this.willAssetAndDebts = [];
        if (willMatter) {
            if (willMatter.willAssetAndDebts?.length) {
                willMatter.willAssetAndDebts.forEach(asset => this.willAssetAndDebts.push(new WillAssetAndDebts(asset)));
            }
        }
    }
}
