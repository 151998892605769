import {Component, OnInit} from '@angular/core';
import {DialogRef} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {ExportTemplatesService} from '../export-templates.service';
import {ReportTemplate} from '../../../matters/report-template/report-template';

export class EditExportTemplateDetailsContext extends BSModalContext {
    reportTemplate: ReportTemplate;
    accountId : number
}

@Component({
               selector    : 'dp-edit-export-template-details-modal-content',
               templateUrl : 'edit-export-template-details.modal.html',
           })

export class EditExportTemplateDetailsComponent implements OnInit {
    context : EditExportTemplateDetailsContext;
    reportTemplate: ReportTemplate;

    constructor(public dialog : DialogRef<EditExportTemplateDetailsContext>,
                private exportTemplatesService : ExportTemplatesService
    ) {
        this.context = dialog.context;

    }

    ngOnInit() {
        this.reportTemplate = new ReportTemplate(this.context.reportTemplate);
    }

    save() {

        this.exportTemplatesService.editReportTemplateFile(this.context.accountId, this.reportTemplate)
            .subscribe(data => {
                this.context.reportTemplate.description = this.reportTemplate.description;
                this.dialog.close();

            });

    }

    close() {
        this.dialog.close();
    }


}
