import {HttpClient} from './../../../core';
import {accountApi} from './../../../admin/accounts/shared/account-api';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {User} from '../../../matters/shared/user';

@Injectable()
export class RegisterService {
    redirectUrl: string = accountApi + 'setup-password';

    constructor(private httpClient: HttpClient) { }


    /**
     * Register service for "/register/{token}" and "/register/validateUser""
     *
     */
    getUser(token: string): Observable<User> {
        return this.httpClient
                   .post(`${accountApi.register}/${token}`, undefined)
                   .map((res) => {
                       return new User(res['User']);
                   });
    }

    getResetPasswordUser(token: string) : Observable<User> {
        return this.httpClient
                   .post(`${accountApi.register}/${token}`, undefined)
                   .map((res) => {
                       let authenticatedUser = res['User'];
                       if(!authenticatedUser.loginId){
                           authenticatedUser.loginId = authenticatedUser.username;
                       }
                       authenticatedUser.registrationToken = token;
                       this.setRegisterUserInSessionStorage(new User(authenticatedUser));
                       return authenticatedUser;
                   });
    }

    validateUser(user: User): Observable<User> {
        return this.httpClient
                   .post(`${accountApi.register}/validateUser`, user)
                   .map((res) => {
                        return new User(res['User']);
                   });
    }

    /**
     * This method sets the user in session storage so it can be accessed from setup password page.
     * @param user
     */
    setRegisterUserInSessionStorage(user: User): void {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.user, JSON.stringify(user));
        sessionStorage.setItem(SESSION_STORAGE_KEYS.userId, String(user.id));
    }

    /**
     * This method will remove the register user from session storage once registration process finishes.
     */
    clearRegisterUserFromSessionStorage(): void {
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.authenticatedUser);
    }
}
