import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-widget-loader',
  templateUrl: './widget-loader.component.html',
  styleUrls: ['./widget-loader.component.css']
})
export class WidgetLoaderComponent implements OnInit {
  @Input('compClass') className: string | undefined
  constructor() { }

  ngOnInit() {
    if(this.className == undefined){
      this.className == "lds-ring";
    }
  }

}
