import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class ReminderIntervalTypes {
    public static readonly MINUTE : ReminderIntervalType = "MINUTE";
    public static readonly HOUR : ReminderIntervalType = "HOUR";
    public static readonly DAY : ReminderIntervalType = "DAY";
}

export type ReminderIntervalType = 'MINUTE' | 'HOUR' | 'DAY' ;

export const ReminderIntervalsDropDowns = {
    timePeriodList: [
        {label: 'Minute(s)', value: ReminderIntervalTypes.MINUTE},
        {label: 'Hour(s)', value: ReminderIntervalTypes.HOUR},
        {label: 'Day(s)', value: ReminderIntervalTypes.DAY},
    ],
}

export class AccountReminderConfiguration extends BaseEntity{

    id: number;

    customerAccountId:number;
    enableEventReminders: boolean;
    eventReminderBeginBefore: number;
    eventReminderRepeatFrequency: number;
    enableAppointmentReminders: boolean;
    clientAptReminderReceiveBefore: number;
    staffAptReminderReceiveBefore: number;
    staffAptReminderIntervalType: ReminderIntervalType;

    constructor(accountReminderConfiguration?: AccountReminderConfiguration) {
        super(accountReminderConfiguration);
    }

}
