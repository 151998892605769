import {Directive, ElementRef, Input} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
    selector: '[ngModel][dp-pinFormat]'
})

export class PinFormatDirective {

    //By default model is bound with non-formatted (without hyphen) pin. Pass true in below parameter if required formatted pin in model.
    @Input() persistFormattedPin: boolean = false;
    @Input() pinFormatMaxLength: number = 9;

    constructor(private model: NgModel,
                private element: ElementRef) {
        this.model.valueChanges.subscribe(this.onValueChanges);
    }

    private onValueChanges = (newValue: any): void => {
        if(!newValue) {
            return;
        }

        newValue = newValue.toString();
        if (newValue) {
            // Convert viewValue to modelValue
            const modelValue: string = newValue.replace(/[^\d]/g, '').slice(0, this.pinFormatMaxLength);


            if(modelValue === "")
            {
                this.model.control.setValue(modelValue, {emitEvent: false, emitViewToModelChange: false});
                return;
            }

            // Format new view value
            let viewValue = modelValue;
            if(this.persistFormattedPin) {
                  if (modelValue.length >= 5) {
                      viewValue = modelValue.slice(0, 5) + '-' + modelValue.slice(5);
                  } else {
                      viewValue = modelValue + ' '.repeat(5 - modelValue.length) + '-';
                  }
            }
            if (viewValue !== newValue) {
                this.model.control.setValue(viewValue, {emitEvent: false, emitViewToModelChange: false});
                if (modelValue.length <= 5) {
                    this.element.nativeElement.selectionStart = modelValue.length;
                    this.element.nativeElement.selectionEnd = modelValue.length;
                }
            }

            if(this.persistFormattedPin) {
                this.model.viewToModelUpdate(viewValue);
            } else {
                this.model.viewToModelUpdate(modelValue);
            }

        }
    }
}
