import {AfterViewChecked, Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({selector: '[tabIndexIgnore]'})
export class TabIndexIgnoreDirective implements AfterViewChecked {
    element: Element;

    constructor(elementRef: ElementRef, private renderer: Renderer2) {
        this.element = elementRef.nativeElement;
    }

    ngAfterViewChecked() {
        if (this.element) {
            const button = this.element.querySelector('button');
            if (button) {
                this.renderer.setAttribute(button, 'tabindex', '-1');
            }
        }
    }
}
