import {ContactTab} from './../../contact/contact-tab';
import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {Logger} from '@nsalaun/ng-logger';
import {Tab} from './tab';
import {MatterService} from '../../matters/matter.service';
import {Matter} from '../../matters/shared/matter';
import {MatterTab} from '../../matters/matter-tab';
import {TabsService} from '../../core/tabs.service';
import {ContactService} from '../../shared-main/contact.service';
import {AdminTab} from '../../admin/admin-tab';
import {BillingTab} from '../../billing/billing-tab';
import {Account} from '../../admin/accounts/shared/account';
import {AccountService} from '../../admin/accounts/account.service';
import {Observable} from 'rxjs';
import {StatusBarService} from '../../shared-main/status-bar.service';
import {ErrorService} from '../error-handling/error-service';
import {DPError} from '../error-handling/dp-error';
import {LockScreenService} from '../../core/lock-screen.service';
import * as _ from 'lodash';
import {UnSavedChangesComponent} from '../../shared-main/unsavedchanges.guard';
import {DialogService} from '../dialog/dialog.service';
import {Contact} from '../../matters/shared/contact';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {DocumentProfileService} from '../../admin/document-profile/document-profile-edit/document-profile.service';
import {TaxRateService} from '../../matters/consideration-ltt/tax-rate.service';
import {StatementConfigService} from '../../admin/shared/statement-config.service';
import {AuthorizationService} from '../../shared-main/authorization/authorization-service';
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {JurisdictionTab} from '../../contact/jurisdiction-tab';
import {FieldCodeRouteMapping} from '../error-handling/field-code-route-mapping';
import {ApplicationError} from '../../core/application-error';
import {EventTab} from '../../event/event-tab';
import {IntegrationsTab} from '../../integrations/integrations-tab';
import {Section} from '../../matters/shared/section';
import {ContactQueryService} from '../../contact/contact-query.service';
import {DocServicesTab} from '../../doc-services/doc-services-tab';
import {SESSION_STORAGE_KEYS} from '../session-storage-keys';
import {DpFooterNotification} from '../error-handling/dp-footer-notification';
import {currentMatter, MatterParticipant, matterSections, MatterSectionsRouteTypes} from '../../matters/shared';
import {GetGlobalSaveModelService} from '../../matters/shared/get-global-save-model.service';
import {AdjudicationTab} from '../../adjudications/adjudication-tab';
import {JurisdictionService} from '../../matters/property-teranet/jurisdiction.service';
import {UserConfigurationService} from '../../shared-main/user-configuration.service';
import {UserStateService} from '../../shared-main/user-state/user-state.service';
import {FieldCodeService} from '../../shared-main/field-code.service';
import {FieldCodeHarvestUtil} from '../../shared-main/field-codes/field-code-harvest-util';
import {TeranetChangeFieldCodeModal} from '../../shared-main/field-codes/teranet-change-field-code.modal.component';
import {Project} from '../../projects/shared/project';
import {ProjectService} from '../../projects/project.service';
import {ProjectTab} from '../../projects/shared/project-tab';
import {GlobalLogger, LogLevelTypes, logSkippedMatterRelockingPrefix} from '../../core/global-logger';
import {Subject} from 'rxjs/Subject';
import {DepositManagerTab} from '../../deposit-manager/deposit-manager-tab';
import {ExportFileManagerTab} from '../../export-file-manager/export-file-manager.tab';
import {ShareDocumentsTab} from '../../share-documents/share-documents.tab';
import {OpportunitiesTab} from '../../opportunities/opportunities-tab';
import {HttpClient} from '../../core';
import {PROJECT_MATTER_INCOMPLETE_MSG_OPEN} from '../../projects/shared/project-consts';
import {ProjectDocSharingNotifierService} from '../../projects/shared/project-doc-sharing-notifier.service';
import {LendersTab} from '../../doc-services/lenders-tab';
import {MassUpdateTab} from './mass-update-tab';
import {OpportunityMatterTab} from '../../opportunity-matter/opportunity-matter-tab';
import {WizardTab} from './wizard-tab';
import {ContactUtil} from '../../matters/shared/contact-util';
import {MatterParticipantUtil} from '../../matters/shared/matter-utils/matter-participant-util';
import { MortgageInstructionsTab } from '../../integrations/mortgage-instructions-tab';
import {AppConfig} from '../../shared-main/app-configuration';

declare var jQuery: any;

export class ExactMatchProspectAndMissSigner{
    matchProspect: MatterParticipant;
    missSigners: MatterParticipant[];
    missSignerSourceContacts: Contact[];
}
/* Holder for all the open tabs in the application. This is the container where a collection of tab components will be displayed */
@Component({
    selector: 'dp-tabs',
    templateUrl: 'tabs.component.html',
    providers: [ContactService, StatementConfigService, DialogService],
    host: {
        '(document:keydown)': 'onDocumentKeyDown($event)'
    },
})
export class TabsComponent implements OnInit, OnDestroy {
    tabs: Tab[];
    subscription: Subscription;
    isMenuClosed: boolean;
    goToLanding: boolean = false;
    redirectParams : any;
    unsavedChangesSubscription: Subscription;
    tempContactsToUpdate: Contact[] = [];
    fieldCodeHarvestUtil: FieldCodeHarvestUtil;
    projectMattersSubscription: Subscription;

    constructor(public router: Router,
                public logger: Logger,
                public route: ActivatedRoute,
                public contactService: ContactService,
                public contactQueryService: ContactQueryService,
                public jurisdictionService: JurisdictionService,
                public matterService: MatterService,
                public projectService: ProjectService,
                public tabsStateService: TabsService,
                public statusBarService: StatusBarService,
                public fieldCodeService: FieldCodeService,
                public accountService: AccountService,
                public errorService: ErrorService,
                public lockScreenService: LockScreenService,
                public dialogService: DialogService,
                public elementRef: ElementRef,
                public taxRateService: TaxRateService,
                public getGlobalSaveModelService: GetGlobalSaveModelService,
                public documentProfileCache: DocumentProfileCache,
                public documentProfileService: DocumentProfileService,
                public authorizationService: AuthorizationService,
                public viewContainerRef: ViewContainerRef,
                public renderer: Renderer2,
                public userStateService: UserStateService,
                public modal: Modal,
                public userConfigurationService: UserConfigurationService,
                public httpClient: HttpClient,
                public appConfig : AppConfig,
                public globalLogger: GlobalLogger , public projectDocSharingNotifierService : ProjectDocSharingNotifierService){

        this.httpClient.postReloginHandler = this.acquireTabsLockAfterRelogin;
    }

    // this method will initialize the tabs and listen to the changed by Subscription
    ngOnInit(): void {
        this.fieldCodeHarvestUtil = new FieldCodeHarvestUtil(this.fieldCodeService);
        currentMatter.tabService = this.tabsStateService;
        currentMatter.getGlobalSaveModelService = this.getGlobalSaveModelService;
        this.errorService.tabService = this.tabsStateService;
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.errorService.resetfocusErrorElement();
            }
        });
        this.dialogService.setDialogServiceModal(this.modal);
        this.dialogService.viewContainerRef = this.viewContainerRef;
        this.dialogService.tabStateService = this.tabsStateService;
        this.dialogService.rendererRef = this.renderer;

        this.isMenuClosed = true;
        this.tabsStateService.tabsState.subscribe((tabs: Tab[]) => this.tabs = tabs);
        this.route
            .queryParams
            .subscribe(params => {
                // Defaults to 0 if no query param provided.
                this.goToLanding = params['landing'];
                if(params['redirect']){
                    this.redirectParams = { 'redirect' : params['redirect'] , 'redirectType' : params['redirectType'], 'section' :  params['section']}
                }
            });
        /*this.subscription = this.notify.navItem$
         .subscribe((tabs: Tab[]) => {

         // this.logger.info('Subscription navItems return');
         // this.logger.info(matterTab);
         if (JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.openTabs)) !== null) {
         // this.logger.info('already present');
         this.refreshTabsState(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.openTabs)));
         } else {
         this.refreshTabsState(tabs);
         }

         });*/
        this.statusBarService.initialize();
        this.initializeTabs();
        this.documentProfileService.getDefault().subscribe((documentProfile: DocumentProfile) => {
            if (documentProfile) {
                this.documentProfileCache.cacheDocumentProfile(documentProfile);
            }
        });


        this.documentProfileService.getDefaultProfileForAccount(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId)).subscribe();
        // .subscribe((defaultDocProfile: DocumentProfile) => {
        //     sessionStorage.setItem(SESSION_STORAGE_KEYS.defaultDocumentProfileId, defaultDocProfile.id.toString());
        // })


        if (this.elementRef.nativeElement.querySelector('.matters-content')) {
            this.elementRef.nativeElement.querySelector('.matters-content')
                .addEventListener('keyup', function (event) {
                    if (event.keyCode == 9) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                }, true);
        }

        //Initialize user configured theme
        this.userConfigurationService.getUserConfiguration()
            .subscribe((uc) => {
                if (uc.configValues.theme) {
                    this.tabsStateService.themeStyle = uc.configValues.theme;
                } else {
                    uc.configValues.theme = this.tabsStateService.themeStyle;
                    this.userConfigurationService.updateUserConfiguration();
                }
            });
    }


    ngOnDestroy(): void {
        if (this.unsavedChangesSubscription) {
            this.unsavedChangesSubscription.unsubscribe();
        }
    }

    get horizontalTabs(): Tab[] {
        //   if(this.tabs.length <= 6) {
        //     return this.tabs;
        // } else {
        return this.tabs.slice(0, 6);
        //  }
    }


    openSecondListMenu(event) {
        event.stopPropagation();
        this.isMenuClosed = !this.isMenuClosed;
    }

    isTabReadOnly(): boolean {
        let currentTab: Tab = this.tabsStateService.activeTab;
        if (currentTab instanceof MatterTab || (currentTab instanceof Tab && this.router.url && this.router.url.indexOf("matters") > -1)) {
            return (this.authorizationService.isMatterReadOnlyAccess()) || ((<MatterTab>currentTab).matter && this.tabsStateService.isMatterProjectLocked((<MatterTab>currentTab).matter));
        } else if (currentTab instanceof ContactTab || (currentTab instanceof Tab && this.router.url && this.router.url.indexOf("contacts/list") > -1)) {
            return this.authorizationService.isContactReadOnlyAccess();
        } else {
            return false;
        }
    }

    isDependantMatterDirty(tab: Tab): boolean {
        return (tab && tab.isMatter() && (tab as MatterTab).linkedMatter && this.tabsStateService.isLinkedMatterDirty(tab));
    }

    // this method will handel the dynamic tab selection on the click of tab
    // putting the tabs in session storage to manage the refresh functionality.
    // add the dynamic class active to selected tab
    openSelectedTab(tab: Tab): void {

        let currentTab: Tab = this.tabsStateService.activeTab;
        if (currentTab instanceof AdminTab) {
            let component: UnSavedChangesComponent = (currentTab as AdminTab).component;
            if (component) {

                if (this.unsavedChangesSubscription) {
                    this.unsavedChangesSubscription.unsubscribe();
                }

                let unsavedChanges: boolean | Observable<boolean> = currentTab.component.unsavedChanges();
                if (!(unsavedChanges instanceof Observable)) {
                    unsavedChanges = Observable.of(unsavedChanges);
                }
                this.unsavedChangesSubscription = unsavedChanges.flatMap(unsaved => {
                    if (!unsaved) {

                        return this.dialogService
                            .confirmUnsavedChange(component.saveBtnOption, component.setUnsavedChangesFormMessage())
                            .flatMap((response: any) => {
                                console.log(response);
                                if (response == "DONT_SAVE") {
                                    component.enableGuardCheck(false);
                                    return Observable.of(true);
                                }
                                else if (response == "CANCEL") {
                                    return Observable.of(false);
                                }
                                else if (response == "SAVE") {
                                    let saveChanges: boolean | Observable<boolean> = component.saveChanges();
                                    if (!(saveChanges instanceof Observable)) {
                                        saveChanges = Observable.of(saveChanges);
                                    }
                                    return saveChanges;
                                }
                                else {
                                    return Observable.of(true);
                                }
                            });

                    }
                    else {
                        return Observable.of(true);
                    }
                }).subscribe(result => {
                    if (result) {
                        (currentTab as AdminTab).component = null;
                        this.tabsStateService.openTab(tab);
                        this.orderTabsDropDownToTop(tab);
                        this.statusBarService.currentHelpText = "";
                    }
                });

            }
            else {
                //todo
                //this should never get executed once all admin components implement UnSavedChangesComponent
                //and add themselves to active tab onInit
                this.tabsStateService.openTab(tab);
                this.orderTabsDropDownToTop(tab);
                this.statusBarService.currentHelpText = "";
            }
        }
        else if(currentTab instanceof ProjectTab){
            this.projectDocSharingNotifierService.showProjectDocSharingNotifierDialog(currentTab).subscribe(
                () =>{
                    this.tabsStateService.openTab(tab);
                    this.orderTabsDropDownToTop(tab);
                    this.statusBarService.currentHelpText = "";
                }
            )
        }
        else if(tab instanceof EventTab){
            this.tabsStateService.openTab(tab,tab.queryParams);
            this.orderTabsDropDownToTop(tab);
            this.statusBarService.currentHelpText = "";
        }
        else {
            this.tabsStateService.openTab(tab);
            this.orderTabsDropDownToTop(tab);
            this.statusBarService.currentHelpText = "";
        }

    }


    get activeMatter(): Matter {
        let activeTab = this.tabsStateService.activeTab;
        if (activeTab && (activeTab.isMatter() || activeTab.isOpportunityMatter())) {
            return (activeTab as MatterTab).matter;
        } else if(activeTab && activeTab.isProject()){
            return (activeTab as ProjectTab).matter;
        }else if(activeTab && activeTab.isWizard()) {
            return (activeTab as WizardTab).matter;
        }else {
            return null;
        }
    }

    // replaceMainMatterClient(message: string){
    //     let matter = this.activeMatter;
    //     if(matter && message){
    //         //This is just replacing Purchaser, as we don't have the messages defining a placeholder for the clientType. We can clean this up
    //         //later and use a $clientType placeholder that gets populated with the actual value
    //         let clientTitle = matter.mainClientTitle;
    //         return message.replace('Purchaser', clientTitle).replace('purchaser', clientTitle.toLocaleLowerCase());
    //     } else {
    //         return message;
    //     }
    // }


    // this method will close the selected tab and recreate the tabs.
    closeTab(tab: Tab, event): void {

        if(event){
            event.stopPropagation();
        }


        if (tab && tab.tabType == 'contact') {
            // only unlock existing contacts and unlock if contact was locked by current user.
            if (tab.id > 0 && ((tab as ContactTab).contact.lockedByCurrentUser)) {

                this.contactQueryService.unlockContactHierarchy(tab.id).subscribe((res) => {
                    this.logger.info('contact has been unlocked now');
                    this.logger.info(res);
                });
            }

            this.removeContactTab(tab as ContactTab);
        } else if (tab && tab.tabType == 'admin') {
            this.removeAdminTab(tab as AdminTab);
        } else if (tab && tab.tabType == 'project') {
            this.removeProjectTab(tab as ProjectTab);
        } else if (tab && tab.tabType == 'exportFileManager') {
            this.removeExportFileManagerTab(tab as ExportFileManagerTab);
        } else if (tab && tab.tabType == 'depositManager') {
            this.removeDepositManagerTab(tab as DepositManagerTab);
        } else if (tab && tab.tabType == 'shareDocuments') {
            this.removeShareDocumentsTab(tab as ShareDocumentsTab);
        } else if (tab && tab.tabType == 'lenders') {
            this.removeLenderTab(tab as LendersTab);
        } else if (tab && tab.tabType == 'jurisdiction') {
            this.removeJurisdictionTab(tab as JurisdictionTab);
            if (tab.id > 0 && (((tab as JurisdictionTab).jurisdiction.lockedByCurrentUser))) {
                this.jurisdictionService.unlockJurisdiction(tab.id).subscribe((res) => {
                    //Do nothing
                });
            }

        } else if (tab && tab.tabType == 'billing') {
            this.removeBillingTab(tab as BillingTab);
        } else if (tab && tab.tabType == 'integrations') {
            if (this.appConfig.enableGeneralEnhancementsPh1){
                this.removeMortgageInstructionTab(tab as MortgageInstructionsTab);
            }
            else this.removeIntegrationsTab(tab as IntegrationsTab);
        } else if (tab && tab.tabType == 'Mortgage Instructing') {
            this.removeMortgageInstructionTab(tab as MortgageInstructionsTab);
        }else if (tab && tab.tabType == 'opportunities') {
            this.removeOpportunitiesTab(tab as OpportunitiesTab);
        } else if (tab && tab.tabType == 'wizard') {
            this.removeWizardTab(tab as WizardTab);
        }
        else if (tab && tab.tabType == 'dayAtGlance') {
            this.removeTabAndOpenNextTab(tab);
        }
        else if (tab && tab.isMatter() && tab.isMassUpdateSubType()) {
            this.tabsStateService.closeMassUpdateTab(tab as MassUpdateTab, this.matterService);
        } else {
            this.removeMatterTab(tab as MatterTab);
        }

        this.statusBarService.currentHelpText = "";
    }

    createTempContactForContactList(data: Contact) {
        // temp list is to retain contact list state when you visit other tabs
        if (this.tempContactsToUpdate.length > 0) {
            // if this record exists, remove it
            if (this.tempContactsToUpdate.findIndex((c: Contact) => c.sourceContactId === data.sourceContactId) > -1) {
                this.tempContactsToUpdate.splice(this.tempContactsToUpdate.findIndex((c: Contact) => c.sourceContactId === data.sourceContactId), 1);
            }
        }
        // add the record to array
        this.tempContactsToUpdate.push(new Contact(data));
        console.log(this.tempContactsToUpdate);

    }

    removeTempContacts() {
        // clear the list contact list
        this.tempContactsToUpdate = [];
    }

    public orderTabsDropDownToTop(tab: Tab) {


        let indexofTab = _.findIndex(this.tabs, ['id', tab.id]);
        /// if the open tab has an index greater than 4 (it means its part of the tabs dropdown area) so we need to push it to the top of the dropdown list
        //TODO: #5 position of dropdown shouldn't be hard coded this should probably be a constant
        if (indexofTab > 5) {


            let openTabs: Tab[] = this.tabs;
            /// find the index of the tab to move to the top
            // let index : number = _.findIndex(openTabs, (chr : any) => chr == tab);
            // we remove the tab from the tabs list
            openTabs.splice(indexofTab, 1);
            // we add it back in to the first position of the dropdown

            //TODO: #5 position of dropdown shouldn't be hard coded this should be a constant
            // Fifth Object is replace by last object and but that fifth need to be added at the end.
            let fifthTabObject: Tab = openTabs[5];
            openTabs.splice(5, 1);
            tab.setActive(true);
            openTabs.splice(5, 0, tab);
            openTabs.push(fifthTabObject);
            this.tabs = openTabs;
        }
    }

    public removeTabAndOpenNextTab(tab: Tab) {
        this.tabsStateService.removeTabAndOpenNextTab(tab, this.tabs);

    }

    public removeTabAndOpenFirstTab(tab: Tab) {

        this.tabsStateService.removeTab(tab);
        //TODO: these will be updated to navigate to a last used tab or something, going to the first one for now
        this.tabsStateService.openTab(this.tabs[0]);
    }

    public removeAdminTab(tab: AdminTab) {

        if (tab.hasDirtyModel()) {
            this.tabsStateService.showContactTabDirtyDialog(tab).subscribe((response: any) => {
                if (response) {
                    if (response == "DONT_SAVE") {

                        if (tab.staffProfiles && tab.staffProfiles.contact && tab.staffProfiles.contact.id > 0) {
                            this.removeContactLocking(tab.staffProfiles.contact);
                        }
                        this.removeTabAndOpenNextTab(tab);
                    } else if (response == "SAVE") {
                        let delegateSave: boolean | Observable<boolean> = tab.delegateSave();
                        if (!(delegateSave instanceof Observable)) {
                            delegateSave = Observable.of(delegateSave);
                        }

                        delegateSave.subscribe((saved: boolean) => {
                            if (saved) {
                                if (tab.staffProfiles && tab.staffProfiles.contact && tab.staffProfiles.contact.id > 0) {
                                    this.removeContactLocking(tab.staffProfiles.contact);
                                }
                                this.removeTabAndOpenNextTab(tab);
                            }
                        });
                    }

                }
            });
        } else {
            if (tab.staffProfiles && tab.staffProfiles.contact && tab.staffProfiles.contact.id > 0) {
                this.removeContactLocking(tab.staffProfiles.contact);
            }
            this.removeTabAndOpenNextTab(tab);
        }
    }

    public removeBillingTab(tab: BillingTab) {
        this.removeTabAndOpenNextTab(tab);
    }

    public removeIntegrationsTab(tab: IntegrationsTab) {
        this.removeTabAndOpenNextTab(tab);
    }

    public removeMortgageInstructionTab(tab: MortgageInstructionsTab) {
        this.removeTabAndOpenNextTab(tab);
    }

    public removeOpportunitiesTab(tab: OpportunitiesTab) {
        // ToDo maybe decrease pooling interval ?
        this.removeTabAndOpenNextTab(tab);
    }

    public removeContactLocking(contact: Contact): void {
        if (contact && contact.id > 0) {
            this.contactService.unlockContact(contact.id).subscribe((res) => {
                this.logger.info('contact has been unlocked now');
                this.logger.info(res);
            });
        }
    }

    public removeContactTab(tab: ContactTab) {

        if (tab.contact && !tab.contact.locked && !this.isTabReadOnly() && !tab.contact.isReadOnly && tab.contact.isDirty) {
            this.tabsStateService.showContactTabDirtyDialog(tab).subscribe((response: any) => {

                if (response) {
                    if (response == "DONT_SAVE") {
                        this.removeTabAndOpenNextTab(tab);
                    } else if (response == "SAVE") {
                        if (tab.commonContactComponent) {
                            tab.commonContactComponent.createOrUpdateContact().subscribe((isContactSaved: boolean) => {
                                if (isContactSaved) {
                                    this.removeTabAndOpenNextTab(tab);
                                }
                            });
                        }


                    }

                }
            });
        }
        else {
            this.removeTabAndOpenNextTab(tab);
        }
    }

    public removeProjectTab(tab: ProjectTab) {
        if (tab.isDirty() && !tab.isLocked() && !this.isTabReadOnly()) {
            this.tabsStateService.openTab(tab);
            this.projectDocSharingNotifierService.showProjectDocSharingNotifierDialog(tab).subscribe( () => {
                this.tabsStateService.showContactTabDirtyDialog(tab).subscribe((response: any) => {

                    if (response) {
                        if (response == "DONT_SAVE") {
                            this.unlockProject(tab);
                            this.unlockProjectMatters(tab);
                            this.removeTabAndOpenNextTab(tab);
                        } else if (response == "SAVE") {
                            setTimeout(() => {
                               let isProjectMatterUnlockedByProjectUpdate = (tab.dataPropagationCommands && tab.dataPropagationCommands.length && tab.projectMatters && tab.projectMatters.length > 0);
                                let subscription: Subscription = tab.projectComponent.validateSaveProjectAndTemplateMatter().subscribe((result: boolean) => {
                                    if (result) {
                                        this.unlockProject(tab);
                                        if (!isProjectMatterUnlockedByProjectUpdate) {
                                            if (tab && tab.project && tab.project.lockedByCurrentUser) {
                                                this.unlockProjectMatters(tab);
                                            }
                                        }
                                        this.removeTabAndOpenNextTab(tab);
                                        subscription.unsubscribe();
                                    }
                                });
                            }, 0);
                        }
                    }
                });
            });
        }
        else {
            this.projectDocSharingNotifierService.showProjectDocSharingNotifierDialog(tab).subscribe( () =>{
                this.unlockProject(tab);
                this.unlockProjectMatters(tab);
                this.removeTabAndOpenNextTab(tab);
            })
        }
    }

    public removeExportFileManagerTab(tab: ExportFileManagerTab) {
        // nothing to save, just a list of eForms
        this.removeTabAndOpenNextTab(tab);
    }

    public removeDepositManagerTab(tab: DepositManagerTab) {

        if (tab.isDirty() && !this.isTabReadOnly()) {
            this.tabsStateService.showContactTabDirtyDialog(tab).subscribe((response: any) => {

                if (response) {
                    if (response == "DONT_SAVE") {
                        this.removeTabAndOpenNextTab(tab);
                    } else if (response == "SAVE") {
                        // add save method here

                    }

                }
            });
        } else {
            this.removeTabAndOpenNextTab(tab);
        }
    }

    public removeShareDocumentsTab(tab: ShareDocumentsTab) {
        //For now, nothing else can do. So just simply close the tab
        this.removeTabAndOpenNextTab(tab);
    }

    public removeJurisdictionTab(tab: JurisdictionTab) {

        if (tab.isDirty() && !this.isTabReadOnly()) {
            this.tabsStateService.showContactTabDirtyDialog(tab).subscribe((response: any) => {

                if (response) {
                    if (response == "DONT_SAVE") {
                        this.removeTabAndOpenNextTab(tab);
                    } else if (response == "SAVE") {
                        tab.jurisdictionSectionedComponent.saveJurisdiction()
                        this.removeTabAndOpenNextTab(tab);
                    }

                }
            });
        } else {
            this.removeTabAndOpenNextTab(tab);
        }
    }

    public removeLenderTab(tab: LendersTab) {

        if (tab.isDirty() && !this.isTabReadOnly()) {
            this.tabsStateService.showContactTabDirtyDialog(tab).subscribe((response: any) => {

                if (response) {
                    if (response == "DONT_SAVE") {
                        this.removeTabAndOpenNextTab(tab);
                    } else if (response == "SAVE") {
                        if(tab && tab.lender){
                            this.contactService.saveLendingInstitution(tab.lender)
                                .subscribe((result)=>{
                                    this.removeTabAndOpenNextTab(tab);
                                });
                        }
                    }
                }
            });
        } else {
            this.removeTabAndOpenNextTab(tab);
        }
    }

    async removeMatterTab(tab: MatterTab, removeTabFlag: boolean = true): Promise<boolean> {
        return this.tabsStateService.removeMatterTab(tab, this.tabs, this.isTabReadOnly(), removeTabFlag, this.matterService);
    }

    async removeWizardTab(tab: MatterTab, removeTabFlag: boolean = true): Promise<boolean> {
        return this.tabsStateService.removeWizardTab(tab, this.tabs, this.isTabReadOnly(), removeTabFlag, this.matterService);
    }


    //If user closes the tab without saving then we need to release any locked matter participants
    releaseLockFromMatterParticipants(matter: Matter): void {
        if (matter.matterParticipants && matter.matterParticipants.length > 0) {
            matter.matterParticipants.forEach(mp => {
                if (mp.sourceContactLockAcquired) {
                    this.contactQueryService.unlockContact(mp.contact.sourceContactId);
                }
            });
        }
    }

    openMultipleMatters(matterList: Matter[], totaltabstobeopened: number): void {
        let matterServiceCalls: any = [];
        let counter: number = 0;
        for (let i = 0; i < matterList.length; i++) {
            counter = counter + 1;
            if (counter <= totaltabstobeopened || (counter == 1 && totaltabstobeopened < 2)) {
                matterServiceCalls.push(this.matterService.getMatter(matterList[i].id));
            }
        }
        this.lockScreenService.lockForUpdate = true;
        Observable.forkJoin(matterServiceCalls).finally(() => {
            this.lockScreenService.lockForUpdate = false;
        }).subscribe(results => {
            for (let i = 0; i < results.length; i++) {
                let matter = results[i] as Matter;
                console.log(matter.matterRecordNumber);
                let matterTab = MatterTab.createMatterTab(matter);
                matterTab.section = 'overview';
                this.saveProjectMattersToMatterTab(matter, matterTab);
                this.tabsStateService.openTab(matterTab);
                this.orderTabsDropDownToTop(matterTab);
                // ensure linkedMatter is available
                if (matter.matterLink && matter.matterLink.linkedMatterId && !matterTab.linkedMatter) {
                    this.logger.debug('openMultipleMatters(): loading linkedMatter - matterId=${matter.id},' +
                        ' linkedMatterId=${matter.matterLink.linkedMatterId}');
                    this.matterService.getMatter(matter.matterLink.linkedMatterId).subscribe((linkedMatter: Matter) => {
                        matterTab.linkedMatter = linkedMatter;
                    });
                }
            }
        });
    }


    openMatterTab(matter: Matter, section?: Section, queryParams?: any): void {
        // if (!matter.matterHst) {
        //     this.taxRateService.cachedConsiderationTaxRate(matter.provinceCode)
        //         .subscribe(
        //             (considerationTaxes: ConsiderationTaxes[]) => {
        //                 if (considerationTaxes && considerationTaxes.length > 0) {
        //                     //let provinceHst = considerationTaxes.find(item => item.instanceType == Tax_RATE.HST_RATE);
        //                     let provinceHst = this.taxRateService.findConsiderationHstRateAccordingToEffectiveDate(considerationTaxes, matter.getSoaTaxRateEffectiveDate());
        //                     if (provinceHst) {
        //                         matter.matterHst = provinceHst.hstRate;
        //                         matter.matterProvincialHst = provinceHst.hstProvincialPortion;
        //                         matter.matterFederalHst = provinceHst.hstFederalPortion;
        //                         matter.matterFederalHst = provinceHst.hstFederalPortion;
        //                         matter.matterTaxType = provinceHst.rateType;
        //                     }
        //                 }
        //                 this.completeOpenMatterTab(matter, section, queryParams);
        //             });
        // } else {
        this.completeOpenMatterTab(matter, section, queryParams);
        //}

    }

    /* Older implementation using Json paths
        addFieldCodeToElements(): void {
            if (this.tabsStateService && this.tabsStateService.activeTab && this.tabsStateService.activeTab.isMatter()) {
                let matterTab = (this.tabsStateService.activeTab as MatterTab);
                if (matterTab && matterTab.fieldCodeMatter && matterTab.fieldCodeMappingData) {
                    let matterElement = jQuery('.matter-form-area dp-matter-opening form');

                    if (matterElement && matterElement.length > 0) {
                        jQuery(matterElement[0]).each(function (i, obj) {
                            jQuery(obj).find('input, select, textarea').each(function (j, element) {
                                let elementValue = jQuery(element).val();
                                if (elementValue) {
                                    let fieldCodeMappingData = matterTab.fieldCodeMappingData.find(item => item.id == elementValue);
                                    if (fieldCodeMappingData) {
                                        let parent = jQuery(element).parent();
                                        if (jQuery(parent).hasClass('partial-dates')) {
                                            jQuery("<span class='field-code-span field-code-span-date'>" + fieldCodeMappingData.fieldCode + "</span>").insertAfter(jQuery(parent));
                                            jQuery(element).val('');
                                        }
                                        else {
                                            jQuery("<span class='field-code-span'>" + fieldCodeMappingData.fieldCode + "</span>").insertAfter(jQuery(element));
                                            jQuery(element).val('');
                                        }


                                    }
                                }

                            });
                        });
                    }
                }
            }
        }


    addFieldCodeToElements(): void {
        if (this.tabsStateService && this.tabsStateService.activeTab && this.tabsStateService.activeTab.isMatter()) {
            let matterTab = (this.tabsStateService.activeTab as MatterTab);
            if (matterTab && matterTab.fieldCodeMatter && matterTab.fieldCodeMappingData) {
                let matterElement = jQuery('.matters-content');

                if (matterElement && matterElement.length > 0) {
                    jQuery(matterElement[0]).each(function (i, obj) {
                        jQuery(obj).find('input, select, textarea').each(function (j, element) {
                            let elementValue = jQuery(element).val();
                            if (elementValue) {
                                let mappingObject = JSON.parse(JSON.stringify(fieldCodeMappings));
                                let fieldMappings: Map<string, string> = new Map();
                                Object.keys(mappingObject).forEach(key => {
                                    fieldMappings.set(mappingObject[key], key);
                                });
                                if (fieldMappings.has(elementValue)) {
                                    let parent = jQuery(element).parent();
                                    if (jQuery(parent).hasClass('partial-dates')) {
                                        jQuery("<span class='field-code-span field-code-span-date'>" + fieldMappings.get(elementValue) + "</span>").insertAfter(jQuery(parent));
                                        jQuery(element).val('');
                                    }
                                    else {
                                        jQuery("<span class='field-code-span'>" + fieldMappings.get(elementValue) + "</span>").insertAfter(jQuery(element));
                                        jQuery(element).val('');
                                    }
                                }
                            }
                        });
                    });
                }
            }
        }
    }

    buildMap(object: any): Map<string, string> {
        let map = new Map<string, string>();
        Object.keys(object).forEach(key => {
            map.set(object[key], key);
        });
        return map;
    }

    Older implemenation using json paths
    assignFieldCodeMapping(matterTab: MatterTab): void {
        if (matterTab && !matterTab.isFieldCodeTurnedOn) {
            let fieldCodeMappingData: any[] = [];
            matterTab.fieldCodeMatter = new Matter(matterTab.matter);
            if (matterTab.matter.soaTrustLedgerCollection) {
                matterTab.matter.soaTrustLedgerCollection.matter = null;
            }
            let matterObject = matterTab.matter;
            MatterFieldCodeMapping.forEach(item => {
                if (item[2] != "$") {
                    item[2] = item[2].replace('$.Matter.', '$.');
                    let val = jp.query(matterObject, item[2]);
                    //let parentJsonPath = item[2].substring(0, item[2].lastIndexOf('.'));
                    //let missingTerminalNode = item[2].substring(item[2].lastIndexOf('.')+1,item[2].length);
                    if (val && val.length > 0 && val[0] !== Object(val[0])) {
                        let uidStr = UUID.UUID();
                        jp.value(matterObject, item[2], uidStr);
                        fieldCodeMappingData.push({'id': uidStr, 'fieldCode': item[0], 'xpath': item[2]});
                    }
                    // else if(parentJsonPath && parentJsonPath != '$' && missingTerminalNode && missingTerminalNode.indexOf('=') < 0 &&  missingTerminalNode.indexOf('@') < 0)
                    // {
                    //
                    //     let val = jp.query(matterObject, parentJsonPath);
                    //     if (val && val.length > 0) {
                    //         let uidStr = UUID.UUID();
                    //         val[0][missingTerminalNode] = uidStr;
                    //         fieldCodeMappingData.push({'id': uidStr, 'fieldCode': item[0], 'xpath': item[2]});
                    //     }
                    //     else {
                    //         fieldCodeMappingData.push({'id': undefined, 'fieldCode': item[0], 'xpath': item[2]});
                    //     }
                    // }
                    // else if(parentJsonPath && parentJsonPath == '$' && missingTerminalNode && missingTerminalNode.indexOf('=') < 0 &&  missingTerminalNode.indexOf('@') < 0)
                    // {
                    //
                    //     let uidStr = UUID.UUID();
                    //
                    //     matterObject[missingTerminalNode] = uidStr;
                    //     fieldCodeMappingData.push({'id': uidStr, 'fieldCode': item[0], 'xpath': item[2]});
                    // }
                    else {
                        fieldCodeMappingData.push({'id': undefined, 'fieldCode': item[0], 'xpath': item[2]});
                    }

                } else {
                    fieldCodeMappingData.push({'id': undefined, 'fieldCode': item[0], 'xpath': item[2]});
                }
            });
            matterTab.matter = new Matter(matterObject);
            matterTab.matter.soaTrustLedgerCollection = matterTab.fieldCodeMatter.soaTrustLedgerCollection;
            if (matterTab.matter.soaTrustLedgerCollection) {
                matterTab.matter.soaTrustLedgerCollection.matter = matterTab.matter;
            }
            matterTab.fieldCodeMappingData = fieldCodeMappingData;
            matterTab.isFieldCodeTurnedOn = true;
            let comp = this;
            setTimeout(function () {
                comp.addFieldCodeToElements();
                if (matterTab) {
                    matterTab.matter = new Matter(matterTab.fieldCodeMatter);
                }

            }, 100);

        }
        else if (matterTab.isFieldCodeTurnedOn) {
            matterTab.matter = new Matter(matterTab.fieldCodeMatter);
            matterTab.isFieldCodeTurnedOn = false;
            this.removeFieldCodeSpan();
        }
    }
*/

    saveProjectMattersToMatterTab(matter: Matter, matterTab: MatterTab): void {
        if (matter.unityProjectId) {
            this.getProjectMatters(matter.unityProjectId)
                .subscribe((matters) => {
                    if (matters && matters.length) {
                        /*
                         * Sort matters natural numerical order
                         * "Natural" sort order refers to treating numeric strings as a whole number rather than as individual digits.
                         * So for example A2A comes before A11A because 2 is less than 11.
                         * Rather than A11A coming before A2A because the second digit in A11A is less than the second digit in A2A.
                         */
                        matterTab.sortedProjectMatters = matters.sort((a, b) =>{
                            /*
                             * The localeCompare() method returns a number -ve, +ve, or 0 indicating whether
                             * a reference string comes before, or after, or is the same as the given string in sort order.
                             */
                            return a.matterRecordNumber.localeCompare(b.matterRecordNumber, undefined, { numeric: true });
                        });
                    }
                });
        }
    }


    completeOpenMatterTab(matter: Matter, section?: Section, queryParams?: any): void {
        matter.updateLocalGender();
        let matterTab = MatterTab.createMatterTab(matter);
        this.saveProjectMattersToMatterTab(matter, matterTab);
        if (section && matter.id && matter.matterType && section.route) {
            matterTab.section = section.route;
        }
        // when you open matter tab you need to load your linked Matter and save it in tab then open your current matter
        if (matter.matterLink && matter.matterLink.linkedMatterId) {
            this.matterService.getMatter(matter.matterLink.linkedMatterId).subscribe((linkedMatter: Matter) => {
                matterTab.linkedMatter = linkedMatter;
                this.openAndOrderMatterTab(matterTab, section, queryParams);
            });
        }
        else {
            this.openAndOrderMatterTab(matterTab, section, queryParams);
        }

    }

    completeOpenProjectTab(projectTab: ProjectTab, project: Project, section?: Section, queryParams?: any): void {
        if (section && project.id && section.route) {
            projectTab.section = section.route;
        }
        this.openAndOrderProjectTab(projectTab, section, queryParams);

    }

    private openAndOrderMatterTab(matterTab: MatterTab, section?: Section, queryParams?: any): void {
        //DPPMP-21035 - commenting below code to enable third party data refresh when the matter is opened from integrations tab after assigning an emp mortgage
        /*if (section && matter.id && matter.matterType && section.route && section.route.indexOf('mortgages/mortgage/') > -1) {
            matterTab.updateThirdPartyData = true;
        }*/
        this.tabsStateService.openTab(matterTab, queryParams);
        this.orderTabsDropDownToTop(matterTab);
        this.lockScreenService.lockForUpdate = false;
        //Open the section
        if (section && matterTab && matterTab.matterComponent) {
            matterTab.matterComponent.openMatterSection(section.route, section);
        }
    }

    private openAndOrderProjectTab(projectTab: ProjectTab, section?: Section, queryParams?: any): void {

        this.tabsStateService.openTab(projectTab, queryParams);
        this.orderTabsDropDownToTop(projectTab);
        this.lockScreenService.lockForUpdate = false;
        //Open the section
        if (section && projectTab && projectTab.projectComponent) {
            projectTab.projectComponent.openProjectSection(section.route, section);
        }
    }

    // create the dynamic tab for matters page and emit the tabs to matters component.
    openMatter(matter: Matter, accessDeniedHandler?: Function, section?: Section, queryParams?: any, checkNotesSectionForOpen: boolean = false): void {
        if (matter.id && matter.id > 0) {
            if (!this.tabsStateService.canOpenTab()) {
                return;
            }
            else {
                this.lockScreenService.lockForUpdate = true;
                //Fetch the matter from the server so we create a tab with complete matter data (the matter received from the list has a limited
                // set of fields filled in).
                this.matterService.getMatter(matter.id).subscribe((matter: Matter) => {
                    let newSection: Section = section;
                    if (checkNotesSectionForOpen && matter.showNotesOnOpen) {
                        newSection = matterSections.find((section) => section.route === MatterSectionsRouteTypes.NOTES);
                    }
                    if (!matter.fullyInitialized && matter.isProjectSale) {
                        this.matterService.unlockMatter(matter.id).subscribe();
                        this.lockScreenService.lockForUpdate = false;
                        this.dialogService.confirm("Error", PROJECT_MATTER_INCOMPLETE_MSG_OPEN, false, 'Open Project').subscribe(result => {
                            if (result) {
                                let project = new Project();
                                project.id = matter.unityProjectId;
                                this.openProject(project);
                            }
                        });
                    }
                    else {
                        this.openMatterTab(matter, newSection, queryParams);
                    }

                }, (error: ApplicationError) => {
                    this.lockScreenService.lockForUpdate = false;
                    this.isMenuClosed = true;
                    if (accessDeniedHandler && error.errorCode == 'app.access.forbidden') {
                        accessDeniedHandler();
                    }
                });
            }
        }
        else {
            //This is a new matter, so we'll create the tab with this locally created matter structure, it's not persisted yet
            this.openMatterTab(matter, section, queryParams);
        }
        this.isMenuClosed = true;

    }


    // create the dynamic tab for matters page and emit the tabs to matters component.
    openProject(project: Project, accessDeniedHandler?: Function, section?: Section): void {
        if (project.id && project.id > 0) {
            if (!this.tabsStateService.canOpenTab()) {
                return;
            }
            let isAnyMatterOpened: boolean = this.tabsStateService.isAnyProjectMatterOpen(project.id);
            this.lockScreenService.lockForUpdate = true;
            this.lockProjectMatters(project.id, isAnyMatterOpened)
                .subscribe((result) => {
                    //In case we have new matters open
                    let matterOpenFlag = this.tabsStateService.isAnyProjectMatterOpen(project.id) && !this.authorizationService.isProjectReadOnlyAccess();
                    let lockProject = result.lockProject && !matterOpenFlag;
                    this.projectService.getProject(project.id, lockProject)
                        .subscribe((project: Project) => {
                            let projectTab = ProjectTab.createProjectTab(project);
                            if (lockProject) {
                                projectTab.projectMatters = result.matters;
                            }
                            projectTab.isProjectMatterOpen = !lockProject;
                            this.completeOpenProjectTab(projectTab, project, section);

                        }, (error: ApplicationError) => {
                            this.lockScreenService.lockForUpdate = false;
                            this.isMenuClosed = true;
                            if (accessDeniedHandler && error.errorCode == 'app.access.forbidden') {
                                accessDeniedHandler();
                            }
                        });
                });


        } else {
            //This is a new project, so we'll create the tab with this locally created matter structure, it's not persisted yet
            let projectTab = ProjectTab.createProjectTab(project);
            this.completeOpenProjectTab(projectTab, project, section);
        }
        this.isMenuClosed = true;
    }

    // create the dynamic tab for Account page and emit the tabs to Account component.
    openAccount(account: Account): void {
        if (account.id && account.id > 0) {
            let tab: AdminTab = new AdminTab();
            tab.tabType = 'admin';
            tab.tabSubType = 'anchor';
            tab.account = account;
            tab.type = "Account Administration";
            tab.route = 'main/admin/home';
            tab.id = account.id;
            tab.title = account.name;
            this.tabsStateService.clearAllTabs();
            this.tabsStateService.openTab(tab);
        }
    }

    openAdminTab(route: string, type: string): void {

        let tab: Tab = new Tab();
        tab.type = type;
        tab.tabSubType = 'anchor';
        tab.tabType = 'admin';
        tab.route = route;
        this.tabsStateService.openTab(tab);
    }

    openBillingTab(route: string, type: string): void {
        let tab: Tab = new Tab();
        tab.type = type;
        tab.tabSubType = 'anchor';
        tab.tabType = 'billing';
        tab.title = '';
        tab.lineNumber = '';
        tab.route = route;
        this.tabsStateService.openTab(tab);
    }

    openIntegrationsTab(): void {
        let tab: Tab = new Tab();
        tab.tabSubType = 'anchor';
        tab.type = this.appConfig.enableGeneralEnhancementsPh1 ? "Mortgage Instructions" : "integrations"  ;
        tab.tabType = this.appConfig.enableGeneralEnhancementsPh1 ? "Mortgage Instructing" : "integrations" ;
        tab.route = this.appConfig.enableGeneralEnhancementsPh1 ? 'main/integrations/mortgage-instructions': 'main/integrations/home';
        this.tabsStateService.openTab(tab);
    }


    openContactsTab(): void {
        let tab: Tab = new Tab();
        tab.type = "Contacts";
        tab.tabSubType = "anchor";
        tab.tabType = 'contact';
        tab.route = 'main/contacts/list';
        this.tabsStateService.openTab(tab);
    }

    openDocServicesTab(route: string, type: string): void {
        let tab: DocServicesTab = new DocServicesTab();
        tab.type = type;
        tab.tabSubType = "anchor";
        tab.tabType = 'docServices';
        tab.title = '';
        tab.lineNumber = '';
        tab.route = route;
        this.tabsStateService.openTab(tab);
    }

    openEventTab(route: string, type: string ,queryParams? : any): void {
        let tab: EventTab = new EventTab();
        tab.type = type;
        tab.tabSubType = "anchor";
        tab.tabType = 'event';
        tab.title = '';
        tab.lineNumber = '';
        tab.route = route;
        tab.queryParams = queryParams;
        if(queryParams && queryParams.eventLanding == 'staff'){
            tab.tabDisplayLabel = 'Staff Availability View';
        } else {
            tab.tabDisplayLabel = 'Events';
        }
        this.tabsStateService.openTab(tab , queryParams);
    }

    openAdjudicationTab(route: string, type: string): void {
        let tab: AdjudicationTab = new AdjudicationTab();
        tab.type = type;
        tab.tabSubType = "anchor";
        tab.tabType = 'adjudication';
        tab.title = '';
        tab.lineNumber = '';
        tab.route = route;
        this.tabsStateService.openTab(tab);
    }

    // this function will get the current values from the session at the time of
    // component loading and selecting on matter from list
    public initializeTabs(): void {
        // if (JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.openTabs)) !== null)
        //  logic if Admin Anchor Tab is opened
        if (this.router.url && this.router.url.indexOf("main/admin/list") > -1) {
            if (this.tabsStateService.anyTabExistsByType('admin')) {
                this.tabs = this.tabsStateService.openTabs;
            } else {
                // if admin tab does not exists then remove all other tabs and open admin anchor Tab
                this.tabsStateService.clearAllTabs();
                this.openAdminTab('main/admin/list', 'Account Management');
            }

        } else if (this.router.url && this.router.url.indexOf("main/admin") > -1) {
            if (this.tabsStateService.anyTabExistsByType('admin')) {
                this.tabs = this.tabsStateService.openTabs;
            } else {
                // if admin tab does not exists then remove all other tabs and open admin anchor Tab
                this.tabsStateService.clearAllTabs();
                this.openAdminTab('main/admin/home', 'Account Administration');
            }

        } else if (this.router.url && this.router.url.indexOf("/main/contacts/list") > -1) {

            //if Contact List already opened, just switch to it, otherwise need to do the cleaning before open
            if(!this.tabsStateService.isContactListTabAlreadyOpened()){
                this.tabsStateService.removeAnchorTab();
            }
            this.tabsStateService.openTab(Tab.createContactAnchorTab());
        }
        else if (this.router.url && this.router.url.indexOf("main/integrations/mortgage-instructions") > -1) {
            this.tabsStateService.removeAnchorTab();
            this.tabsStateService.openTab(Tab.createMortgageInstructionTab());
        }
        else if (this.router.url && this.router.url.indexOf("main/integrations") > -1) {
            this.tabsStateService.removeAnchorTab();
            this.openIntegrationsTab();
        } else if (this.router.url && this.router.url.indexOf("main/integrations/home") > -1) {
            this.tabsStateService.removeAnchorTab();
            if (this.appConfig.enableGeneralEnhancementsPh1){
                this.tabsStateService.openTab(Tab.createMortgageInstructionTab());
            }
            else{
                this.tabsStateService.openTab(Tab.createIntegrationsTab());
            }
        } else if (this.router.url && this.router.url.indexOf("main/billing") > -1) {
            this.tabsStateService.removeAnchorTab();
            this.openBillingTab('main/billing/home', 'Billing');

        } else if (this.router.url && this.router.url.indexOf("main/events/list") > -1) {
            // In DPPMP-13473 Retain State of Events List, it need to save scroll bar place and filter values in eventListState
            // If it remove the events anchor tab, it will lost all status of the evenListSate in Event-tab.ts
            // When switching pages between the event anchor tab and other tabs which are not anchor tab, it will not remove
            // the event anchor tab.
            if (this.tabsStateService.anchorTab && this.tabsStateService.anchorTab.isEvents()) {
                this.tabsStateService.openTab(this.tabsStateService.anchorTab , (this.tabsStateService.anchorTab as EventTab).queryParams);
            } else {
                this.tabsStateService.removeAnchorTab();
                this.openEventTab('main/events/list', 'events', {eventLanding : (this.router.url.indexOf('eventLanding=staff') > -1) ? 'staff' : 'List' , landing: true});
            }
        }
        else if (this.router.url && this.router.url.indexOf("main/events/dayAtGlance") > -1) {
            if (this.tabsStateService.anchorTab && this.tabsStateService.anchorTab.isDayAtGlance()) {
                this.tabsStateService.openTab(this.tabsStateService.anchorTab);
            } else {
                this.tabsStateService.removeAnchorTab();
                this.tabsStateService.openTab(Tab.createDayAtGlanceAnchorTab());
            }
        }
        else if (this.router.url && this.router.url.indexOf("main/adjudication/adjudication-requests/list") > -1) {
            // In DPPMP-13473 Retain State of Events List, it need to save scroll bar place and filter values in eventListState
            // If it remove the events anchor tab, it will lost all status of the evenListSate in Event-tab.ts
            // When switching pages between the event anchor tab and other tabs which are not anchor tab, it will not remove
            // the event anchor tab.
            if (this.tabsStateService.anchorTab.isAdjudication()) {
                this.tabsStateService.openTab(this.tabsStateService.anchorTab);
            } else {
                this.tabsStateService.removeAnchorTab();
                this.openAdjudicationTab('main/adjudication/adjudication-requests/list', 'adjudication');
            }
        } else if (this.router.url && this.router.url.indexOf("main/doc-services") > -1) {
            this.tabsStateService.removeAnchorTab();
            this.openDocServicesTab('main/doc-services/home', 'Document Services Administration');
        }
        else if (this.router.url && this.router.url.indexOf("/main/tabs/matters") > -1) {
            //if Matter List tab already opened, just switch to it, otherwise need to do the cleaning before open
            if(!this.tabsStateService.isMatterListTabAlreadyOpened()){
                this.tabsStateService.removeAnchorTab();
            }
            this.tabsStateService.openTab(Tab.createMatterAnchorTab(this.redirectParams) );
        }
        else if (this.router.url && this.router.url.indexOf("/main/projects/list") > -1) {
            this.tabsStateService.removeAnchorTab();
            this.tabsStateService.openTab(Tab.createProjectAnchorTab());
        }
        else if (this.router.url && this.router.url.indexOf("/main/export-file-manager/list") > -1) {
            if (this.tabsStateService.anchorTab && this.tabsStateService.anchorTab.isExportFileManager()) {
                this.tabsStateService.openTab(this.tabsStateService.anchorTab);
            } else {
                this.tabsStateService.removeAnchorTab();
                this.tabsStateService.openTab(Tab.createExportFileManagerAnchorTab());
            }
        }
        else if (this.router.url && this.router.url.indexOf("/main/messages/list") > -1) {
            this.tabsStateService.removeAnchorTab();
            this.tabsStateService.openTab(Tab.createMessagesTab());
        }
        else if (this.router.url && this.router.url.indexOf("/main/deposit-manager/list") > -1) {
            if (this.tabsStateService.anchorTab && this.tabsStateService.anchorTab.isDepositManager()) {
                this.tabsStateService.openTab(this.tabsStateService.anchorTab);
            } else {
                this.tabsStateService.removeAnchorTab();
                this.tabsStateService.openTab(Tab.createDepositManagerTab());
            }

        }
        else if (this.router.url && this.router.url.indexOf("/main/opportunities/list") > -1) {
            if (this.tabsStateService.anchorTab && this.tabsStateService.anchorTab.isOpportunities()) {
                this.tabsStateService.openTab(this.tabsStateService.anchorTab);
            } else {
                this.tabsStateService.removeAnchorTab();
                this.tabsStateService.openTab(Tab.createOpportunitiesAnchorTab());
            }
        } else if (this.router.url && this.router.url.indexOf("/main/opportunity-matter/list") > -1) {
            if (this.tabsStateService.anchorTab && this.tabsStateService.anchorTab.isOpportunityMatter()) {
                this.tabsStateService.openTab(this.tabsStateService.anchorTab);
            } else {
                this.tabsStateService.removeAnchorTab();
                this.tabsStateService.openTab(Tab.createOpportunityMatterAnchorTab());
            }
        }
        else if (!this.tabs || (this.tabs && this.tabs.length == 0)) {
            this.tabs = this.tabsStateService.openTabs;
        }

    }

    createMatterAnchorTab(): Tab {
        let matterAnchorTab: Tab = new Tab();
        matterAnchorTab.id = undefined;
        matterAnchorTab.type = 'Matters';
        matterAnchorTab.tabSubType = 'anchor';
        matterAnchorTab.tabType = 'matter';
        matterAnchorTab.title = undefined;
        matterAnchorTab.lineNumber = 'undefined';
        matterAnchorTab.route = '/main/tabs/matters';
        matterAnchorTab.active = 'active';
        return matterAnchorTab;
    }


    getErrorMessage(error: DPError): string {
        // return this.replaceMainMatterClient(this.errorService.getErrorMessageWithLatestIndex(error));
        let rawMsg = this.errorService.getErrorMessageWithLatestIndex(error);
        let replacedMsg = this.errorService.createMatterTypeAwareMessage(this.activeMatter, rawMsg,error);
        if(this.activeMatter){
            if (this.activeMatter.isOpportunityMatter()) {
                replacedMsg = this.errorService.substituteOpportunityText(error.errorElementKey, replacedMsg);
            } else {
                replacedMsg = this.errorService.substituteProvinceText(error.errorElementKey, replacedMsg, this.activeMatter.provinceCode, this.activeMatter.matterType);
            }
        }
        return replacedMsg;
    }

    replacePlaceHolderInErrorMessage(error: DPError) {
        let topic: string = this.errorService.createMatterTypeAwareMessage(this.activeMatter, error.errorTopic, error);
        if (this.activeMatter && this.activeMatter.provinceCode) {
            return this.errorService.substituteProvinceText(error.errorElementKey, topic, this.activeMatter.provinceCode, this.activeMatter.matterType);
        } else {
            return topic;
        }
    }

    isMortgageInstructingTabType(tab: Tab): boolean {
        return tab.type == 'Mortgage Instructing';
    }

    isIntegrationsTabType(tab: Tab): boolean {
        return tab.type == 'integrations';
    }

    isOpportunitiesTabType(tab: Tab): boolean {
        return tab.type == 'Opportunities';
    }

    isEventsTabType(tab: Tab): boolean {
        return tab.type == 'events';
    }

    isProjectsTabType(tab: Tab): boolean {
        return tab.type == 'Projects';
    }

    isExportFileManagerTabType(tab: Tab): boolean {
        return tab.type == 'Export File Manager';
    }

    isMessagesTabType(tab: Tab): boolean {
        return tab.type == 'messages';
    }

    isAdjudicationTabType(tab: Tab): boolean {
        return tab.type == 'adjudication';
    }

    isContactsTabType(tab: Tab): boolean {
        return tab.type == 'Contacts';
    }

    isDespositManagerTabType(tab: Tab): boolean {
        return tab.type == 'Deposit Manager';
    }

    isOpportunityMatterTabType(tab: Tab): boolean {
        return tab.type == 'Opportunity Matter';
    }

    showProvinceInMatterTab(tab: Tab): string {
        let province = '';
        if (tab.tabType == 'matter' && (tab as MatterTab).matterProvinceCode) {
            let enabledProvinces = this.userStateService.getEnabledUserProvinceCodes();
            if (enabledProvinces && enabledProvinces.length > 1) {
                province = `(${(tab as MatterTab).matterProvinceCode})`;
            }
        }

        if (tab.type == 'project' && (tab as ProjectTab).project.provinceCode) {
            province = '(' + (tab as ProjectTab).project.provinceCode + ')';
        }

        return province;
    }


    get dpFooterNotifications(): DpFooterNotification[] {
        return this.errorService.dpFooterNotifications;
    }

    openFooterNotificationTab(item: DpFooterNotification): void {
        this.errorService.closeFooterNotification();
        item.isActive = true;
    }

    closeFooterNotification(): void {
        this.errorService.closeFooterNotification();
    }

    isAnyNotificationActive(): boolean {
        return this.errorService.isAnyNotificationActive();
    }

    activeNotificationList(): DPError[] {
        return this.errorService.activeNotificationList();
    }

    totalNotificationCount(): number {
        return this.errorService.totalNotificationCount();
    }

    openNotification(): void {
        this.errorService.openNotification();
    }

    focusErrorElement(dpError: DPError): void {
        let currentComponent = this;

        if (dpError.fieldCode) {

            let focusedElement = jQuery('dppm-app').find("[fieldCode='" + dpError.fieldCode + "']");
            let focusedElementMortgageId = jQuery('dppm-app').find("[fieldCodeMortgageId='" + dpError.mortgageId + "']");
            if (focusedElement && focusedElement.length > 0 && dpError && dpError.mortgageId && focusedElement.attr('fieldcodemortgageid') && focusedElementMortgageId
                && focusedElementMortgageId.length > 0) {
                this.errorService.setfocusErrorElement(dpError);
                this.errorService.focusElement();
            }
            else if (focusedElement && focusedElement.length > 0 && !focusedElement.attr('fieldcodemortgageid')) {
                this.errorService.setfocusErrorElement(dpError);
                this.errorService.focusElement();
            } else {
                let routeMapping = FieldCodeRouteMapping.find(item => item.fieldCodes.indexOf(dpError.fieldCode) > -1);
                if (routeMapping && this.tabsStateService.activeTab && this.tabsStateService.activeTab.isMatter()) {
                    let matterTab = (this.tabsStateService.activeTab as MatterTab);
                    let matter = matterTab.matter;
                    let route = routeMapping.route;
                    if (dpError.mortgageId && matter.mortgages && routeMapping.route.indexOf('mortgages') > -1) {
                        let mi = matter.mortgages.findIndex(item => item.id == dpError.mortgageId);
                        if (mi >= 0) {
                            route = route + '/' + mi;
                        }
                        else {
                            route = route + '/0';
                        }
                    }
                    let matterComponent = matterTab.matterComponent;
                    matterComponent.openMatterSection(route);
                    setTimeout(function () {
                        currentComponent.errorService.setfocusErrorElement(dpError);
                        let matterSectionIndex: number = matterComponent.getSelectedSectionIndex();
                        matterComponent.setActiveSection(matterSectionIndex);
                    }, 0);
                }
            }
        }

    }

    notificationCount(item): number {
        let footerNotificationItem = new DpFooterNotification(item);
        return footerNotificationItem.notificationCount();
    }


    onDocumentKeyDown($event: KeyboardEvent) {
        // tab closes the flyout error modal.
        if ($event.keyCode === 9) {
            this.closeFooterNotification();
        }
        // F4 - to show field code related all fields on UI (Mostly to be used for Testing)
        if ($event.keyCode === 115) {
            if(this.fieldCodeHarvestUtil.showFeildCode)
            {
                this.fieldCodeHarvestUtil.showFeildCode = false;
                this.fieldCodeHarvestUtil.removeFieldCodeLabelsFromMatter();
            }
            else{
                this.fieldCodeHarvestUtil.showFeildCode = true;
                this.fieldCodeHarvestUtil.showFieldCodeLabelOnMatter(this.router.url , this.activeMatter);
            }

        }
        // F7 - To open Popup to user to add Field Code and save it to Mapping on the backend.
        if ($event.keyCode === 118 && this.authorizationService.hasFieldCodeCreateAndUpdateAccess()) {
            let fieldMetaData = this.fieldCodeHarvestUtil.generateFieldMetaData($event.target, this.router.url);
            if (fieldMetaData) {
                this.dialogService.content({
                    content: TeranetChangeFieldCodeModal,
                    context: {
                        fieldMetaData: fieldMetaData,
                        fieldCodeService : this.fieldCodeService,
                        route: this.router.url
                    },
                    onFulfillment: () => {
                        this.fieldCodeHarvestUtil.removeFieldCodeLabelsFromMatter();
                        this.fieldCodeHarvestUtil.showFieldCodeLabelOnMatter(this.router.url , this.activeMatter);
                    },
                    onRejection: (reject: any) => {
                    }
                });
            }
            else {
                this.dialogService.confirm('ERROR', 'Field Code Request Can not be Completed', true, null, null, true).subscribe(() => {
                });
            }
        }
        if(sessionStorage.getItem('isFieldHarvestingModeOn') == 'ON') {
            // F8 - To Show Field For that particular element in bottom status bar
            if ($event.keyCode === 119) {
                this.fieldCodeHarvestUtil.onFocusFieldCode($event.target , this.router.url);
            }
            // F10 - Run Field Code Mapper and Generate Field Code Mapping on Backend
            if ($event.keyCode === 121) {
                let okBtnConfirm : Element = document.body.querySelector('.modal-dialog button#okBtnConfirm');
                let okBtn : Element = document.body.querySelector('.modal-dialog button#okBtn');
                if(jQuery(".modal-dialog") && jQuery(".modal-dialog").length > 0 && (okBtn || okBtnConfirm)){
                    if(okBtnConfirm){
                        okBtnConfirm.dispatchEvent(new CustomEvent('click'));
                    }
                    else if(okBtn){
                        okBtn.dispatchEvent(new CustomEvent('click'));
                    }
                    let ref = this;
                    setTimeout(() => {
                        ref.fieldCodeHarvestUtil.generateFieldCodeObjectMap(ref.activeMatter, ref.router.url);
                    }, 50);

                }
                else{
                    this.fieldCodeHarvestUtil.generateFieldCodeObjectMap(this.activeMatter, this.router.url);
                }

            }
        }
    }

    getThemeClass() {
        return this.tabsStateService.themeStyle;
        //Load the configured value, if overwritten by the user
    }

    getFontSizeClass() {
        return `percent-${this.userConfigurationService.fontSize}`;
    }

    unlockProject(tab: ProjectTab): void {
        if (tab && tab.project && tab.project.lockedByCurrentUser) {
            this.projectService.unlockProject(tab.id).subscribe((res) => { });
            if(tab.project && tab.project.templateMatterId){
                this.matterService.unlockMatter(tab.project.templateMatterId).subscribe((res) => { });
            }

        }
    }

    getProjectMatters(projectId: number): Observable<Matter[]> {
        return this.projectService.getProjectMatters(projectId);
    }

    lockProjectMatters(projectId: number, isAnyMatterOpened?: boolean): Observable<any> {
        let getMattersSubject = new Subject<any>();
        this.getProjectMatters(projectId)
            .subscribe(
                (matters: Matter[]) => {
                    if (!matters || matters && !matters.length) {
                        //If no matters are associated with the project, then we open the project in edit mode & lock it
                        getMattersSubject.next({lockProject: true, matters: []});
                        getMattersSubject.complete();
                        //if some matters are associated with the project, first we check if any of them is locked
                    } else if (matters.every(matter => !matter.lockedByUser) && !isAnyMatterOpened) {
                        //If all the matters are not opened by another user, we lock all of them
                        this.matterService.lockMatters(matters)
                            .subscribe(() => {
                                //Open the project in edit mode
                                getMattersSubject.next({lockProject: true, matters: matters});
                                getMattersSubject.complete();
                            }, error => {
                                //Open the project in readonly mode
                                getMattersSubject.next({lockProject: false, matters: []});
                                getMattersSubject.complete();
                            });
                    } else {
                        //If some matters are locked by other users, open the project in readonly mode
                        getMattersSubject.next({lockProject: false, matters: []});
                        getMattersSubject.complete();
                    }

                }, (error) => {
                    getMattersSubject.next({lockProject: false, matters: []});
                    getMattersSubject.complete();
                });

        return getMattersSubject;
    }

    unlockProjectMatters(tab: ProjectTab): void {
        if (tab && tab.projectMatters && tab.projectMatters.length) {
            let projectMatters = [];
            //Unlock all the project matters that are not opened in a tab
            tab.projectMatters.forEach((matter) => {
                if (!this.tabsStateService.isMatterTabOpen(matter.id)) {
                    projectMatters.push(matter);
                }
            });
            if (projectMatters && projectMatters.length) {
                this.matterService.unlockMatters(projectMatters).subscribe();
            }

        }
    }

    showFileTextIcon(tab: Tab): boolean {
        return !this.isEventsTabType(tab) &&
            !this.isMortgageInstructingTabType(tab)&&
            !this.isIntegrationsTabType(tab) &&
            !this.isContactsTabType(tab) &&
            !this.isAdjudicationTabType(tab) &&
            !this.isMessagesTabType(tab) &&
            !this.isProjectsTabType(tab) &&
            !this.isDespositManagerTabType(tab) &&
            !this.isExportFileManagerTabType(tab) &&
            !this.isOpportunitiesTabType(tab) &&
            !this.isOpportunityMatterTabType(tab);
    }


    get isContentLoading(): boolean {
        return this.tabsStateService && this.tabsStateService.activeTab && this.tabsStateService.activeTab.isMatter() && (this.tabsStateService.activeTab as MatterTab).isMatterTabLoading;
    }

    // Reacquire locks for all the opened tabs (Matters & Contacts)
    // Triggered when the user server session is renewed
    acquireTabsLockAfterRelogin = async () => {
        let openTabs = this.tabsStateService.openTabs;
        let matterTabs = openTabs.filter(tab => tab.isMatter());
        let matterTabsCannotBeLocked: MatterTab[] = [];
        let errorMessage = '';
        errorMessage += await this.lockMatterTabs(matterTabs, matterTabsCannotBeLocked);
        let contactTabs = openTabs.filter(tab => tab.isContact());
        let contactTabsCannotBeLocked: ContactTab[] = [];
        errorMessage += await this.lockContactTabs(contactTabs, contactTabsCannotBeLocked);

        if (matterTabsCannotBeLocked.length || contactTabsCannotBeLocked.length) {
            let tabsCannotBeLocked = [...matterTabsCannotBeLocked, ...contactTabsCannotBeLocked];
            this.dialogService.confirm('ERROR', errorMessage, true, null, null, true).subscribe(() => {
                for (let tab of tabsCannotBeLocked) {
                    this.tabsStateService.removeTab(tab);
                }

                if (!this.tabsStateService.activeTab) {
                    this.tabsStateService.openAnchorTab();
                }

            });
        }
    };

    async lockMatterTabs(matterTabs: Tab[], matterTabsCannotBeLocked: MatterTab[]): Promise<string> {
        let message = '';
        if (matterTabs && matterTabs.length) {
            for (let matterTab of matterTabs) {
                await this.acquireMatterLock((matterTab as MatterTab), matterTabsCannotBeLocked);
            }

            if (matterTabsCannotBeLocked.length) {
                message = "The following matters may have been updated by another user within your firm. " +
                    "These matters need to be reopened and any unsaved changes will be discarded.<br><br>";
                matterTabsCannotBeLocked.forEach((tab, index) => {
                    message += `${tab.matter.matterRecordNumber} <br>`;
                });
            }
        }
        return Promise.resolve(message);
    }

    async lockContactTabs(contactTabs: Tab[], contactTabsCannotBeLocked: ContactTab[]): Promise<string> {
        let message = '';
        if (contactTabs && contactTabs.length) {
            for (let contactTab of contactTabs) {
                await this.acquireContactLock((contactTab as ContactTab), contactTabsCannotBeLocked);
            }
            if (contactTabsCannotBeLocked.length) {
                message = "<br><br>The following contacts may have been updated by another user within your firm. " +
                    "These contacts need to be reopened and any unsaved changes will be discarded.<br><br>";
                contactTabsCannotBeLocked.forEach((tab, index) => {
                    message += `${tab.contact.fullName} <br>`;
                });
            }
        }
        return Promise.resolve(message);
    }

    async acquireMatterLock(matterTab: MatterTab, matterTabsCannotBeLocked: MatterTab[]): Promise<void> {
        if (matterTab && matterTab.matter) {
            if (matterTab.matter.id > 0) {
                if (matterTab.matter.matterParticipants
                    && matterTab.matter.matterParticipants.length > 0
                    && matterTab.matter.matterParticipants.some(mp => mp.sourceContactLockAcquired)
                ) {
                    this.globalLogger.log(LogLevelTypes.WARN, logSkippedMatterRelockingPrefix +
                        `Skipped matter re-locking as there are also some locked participant for matter with id = ${matterTab.matter.id}`);
                    matterTabsCannotBeLocked.push(matterTab);
                    return Promise.resolve();
                } else {
                    let result = await this.matterService.lockMatter(matterTab.matter.id, matterTab.matter.lastUpdatedTimeStamp).toPromise();
                    if (!result) {
                        matterTabsCannotBeLocked.push(matterTab);
                        return Promise.resolve();
                    }
                }
            }
        }
    }

    async acquireContactLock(contactTab: ContactTab, contactTabsCannotBeLocked: ContactTab[]): Promise<void> {
        if (contactTab && contactTab.contact) {
            if (contactTab.contact.id > 0) {
                let result = await this.contactService.lockContact(contactTab.contact.id, contactTab.contact.lastUpdatedTimeStamp).toPromise();
                if (!result) {
                    contactTabsCannotBeLocked.push(contactTab);
                    return Promise.resolve();
                }
            }
        }
    }

    async findMissSignersContactIdsForExactMatchProspect(matter: Matter): Promise<ExactMatchProspectAndMissSigner[]>{
        let exactMatchProspectAndMissSigners: ExactMatchProspectAndMissSigner [] = [];

        if(matter){
            let prospectObservables: Observable<any>[] = [];
            let missSignerParticipants: MatterParticipant[] = matter.matterParticipants.filter(item=>item.addSignerToParentContact);
            let prospects: MatterParticipant[] = [];
            missSignerParticipants.forEach(missSigner=>{
                let prospect: MatterParticipant = matter.matterParticipants.find(participant=>participant.matterParticipantId == missSigner.parentParticipantId);
                prospects.push(prospect);
            })

            if(prospects && prospects.length > 0) {
                for(let i=0; i < prospects.length; i++ ){
                    let contactObservables: Observable<any>[] = [];
                    let exactMatchProspectAndMissSigner: ExactMatchProspectAndMissSigner = new ExactMatchProspectAndMissSigner();
                    exactMatchProspectAndMissSigner.matchProspect = prospects[i];
                    let signerSourceContacts: Contact[] = [];

                    let sourceContact = await this.contactQueryService.getContact(prospects[i] && prospects[i].contact && prospects[i].contact.sourceContactId).toPromise();
                    let signerParticipants = missSignerParticipants.filter(item=>(item.parentParticipantId == prospects[i].matterParticipantId) && item.contact);
                    //delete signer officer snapshot,
                    // if lock fail, we will add it by manual
                    // if lock success, we will add it by addSigningOfficer method
                    missSignerParticipants.forEach(signingOfficerParticipant => matter.deleteMatterParticipant(signingOfficerParticipant));

                    if(sourceContact && sourceContact.locked){
                        this.dialogService.confirm('Error',
                            ContactUtil.getConcurrencyIssuesErrorMsg(sourceContact, signerParticipants.map(item=>item.contact)), true, 'Ok');
                    } else {
                        if(signerParticipants && signerParticipants.length > 0){
                            contactObservables = signerParticipants.map(item => this.contactQueryService.getContactForMatter(item && item.contact && item.contact.sourceContactId));
                        }
                        if(contactObservables) {
                            signerSourceContacts = await Observable.forkJoin(contactObservables).toPromise();
                        }
                        exactMatchProspectAndMissSigner.missSignerSourceContacts = signerSourceContacts;
                        exactMatchProspectAndMissSigners.push(exactMatchProspectAndMissSigner);
                    }
                }
                return exactMatchProspectAndMissSigners;
            } else {
                return exactMatchProspectAndMissSigners;
            }
        } else {
            return exactMatchProspectAndMissSigners;
        }

    }

    openOpportunity(matter: Matter, accessDeniedHandler?: Function, section?: Section, queryParams?: any, checkNotesSectionForOpen: boolean = false): void {
        if (matter.id && matter.id > 0) {
            if (!this.tabsStateService.canOpenTab()) {
                return;
            }
            else {
                this.lockScreenService.lockForUpdate = true;
                //Fetch the matter from the server so we create a tab with complete matter data (the matter received from the list has a limited
                // set of fields filled in).
                this.matterService.getMatter(matter.id).subscribe(async (getMatter: Matter) => {
                    let matter = getMatter;
                    let exactMatchProspectAndMissSigner: ExactMatchProspectAndMissSigner[] = await this.findMissSignersContactIdsForExactMatchProspect(matter);
                    if(Array.isArray(exactMatchProspectAndMissSigner) && exactMatchProspectAndMissSigner.length > 0) {
                        exactMatchProspectAndMissSigner.forEach(item=> MatterParticipantUtil.addSigningOfficer(matter, item.matchProspect, item.missSignerSourceContacts));
                        matter = await this.matterService.updateMatter(matter.id, matter).toPromise();
                    }

                    let newSection: Section = section;
                    if (checkNotesSectionForOpen && matter.showNotesOnOpen) {
                        newSection = matterSections.find((section) => section.route === MatterSectionsRouteTypes.NOTES);
                    }

                    this.openOpportunityTab(matter, newSection, queryParams);

                }, (error: ApplicationError) => {
                    this.lockScreenService.lockForUpdate = false;
                    this.isMenuClosed = true;
                    if (accessDeniedHandler && error.errorCode == 'app.access.forbidden') {
                        accessDeniedHandler();
                    }
                });
            }
        }
        else {
            //This is a new matter, so we'll create the tab with this locally created matter structure, it's not persisted yet
            this.openOpportunityTab(matter, section, queryParams);
        }
        this.isMenuClosed = true;

    }

    openOpportunityTab(matter: Matter, section?: Section, queryParams?: any): void {
        matter.updateLocalGender();
        let opportunityTab = OpportunityMatterTab.createOpportunityTab(matter);
        if (section && matter.id && matter.matterType && section.route) {
            opportunityTab.section = section.route;
        }
        this.openAndOrderOpportunityTab(opportunityTab, section, queryParams);
    }

    private openAndOrderOpportunityTab(opportunitiesTab: OpportunityMatterTab, section?: Section, queryParams?: any): void {
        this.tabsStateService.openTab(opportunitiesTab, queryParams);
        this.orderTabsDropDownToTop(opportunitiesTab);
        this.lockScreenService.lockForUpdate = false;
        //Open the section
        if (section && opportunitiesTab && opportunitiesTab.matterComponent) {
            opportunitiesTab.matterComponent.openMatterSection(section.route, section);
        }
    }

    openMultipleOpportunities(matterList: Matter[], totaltabstobeopened: number): void {
        let matterServiceCalls: any = [];
        let counter: number = 0;
        for (let i = 0; i < matterList.length; i++) {
            counter = counter + 1;
            if (counter <= totaltabstobeopened || (counter == 1 && totaltabstobeopened < 2)) {
                matterServiceCalls.push(this.matterService.getMatter(matterList[i].id));
            }
        }
        this.lockScreenService.lockForUpdate = true;
        Observable.forkJoin(matterServiceCalls).finally(() => {
            this.lockScreenService.lockForUpdate = false;
        }).subscribe(results => {
            for (let i = 0; i < results.length; i++) {
                let matter = results[i] as Matter;
                let section: Section;
                if (matter.showNotesOnOpen) {
                    section = matterSections.find((section) => section.route === MatterSectionsRouteTypes.NOTES);
                }
                this.openOpportunityTab(matter, section);
            }
        });
    }

}
