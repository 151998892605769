import {DpBooleanValue, DpBooleanValueTypes} from './dp-boolean';
import {Address} from './address';
import {MortgageContactInfo} from './mortgage-contact-info';
import {Contact} from './contact';
import {MortgageTerm} from './mortgage-term';
import {MortgageReport} from './mortgage-report';
import {AddressTypes} from './address-types';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {MortgagePayout} from '../mortgages/mortgage/mortgage-payout/mortgage-payout';
import {Matter} from './matter';
import {MatterParticipant} from './matter-participant';
import {NameModifierUtil} from './name-modifier-util';
import {MortgageDispositionType} from '../../shared-main/constants';
import {StewartMortgageInstruction} from '../../shared-main/telus/stewart-mortgage-instruction';
import {dropDowns} from '../mortgages/mortgage/dropdown-options';
import {LSOForm9} from './lso-form9';
import {MortgageInstrument} from './mortgage-instrument';
import {PayoutStatement} from '../../shared-main/telus/payout-statement';

export class MortgageCorrespondenceTypes {
    public static readonly SOLICITOR : MortgageCorrespondenceType = "SOLICITOR";
    public static readonly MORTGAGEE : MortgageCorrespondenceType = "MORTGAGEE";
}
export type LoanType = "NEW" | "ARRANGED" | "ASSUMED" | "BACK_TO_VENDOR" | "BRIDGE" | "";
export type MortgageCorrespondenceType = "MORTGAGEE" | "SOLICITOR" | "";
export type MortgageType = 'NEW' | 'EXISTING';
export type MortgageSource = 'UNITY' | 'EMP';
export type MortgageeType = '' | 'INSTITUTION' | 'PRIVATE_LENDER';
export type MortgageCapacityType = 'SILENT' | 'JOINT' | 'COMMON_SPECIFIED' | 'COMMON_UNSPECIFIED' | 'OTHER';
export type MortgageContactInfoType = 'MORTGAGEE' | 'PRIVATE_LENDER' | 'SOLICITOR';

export type DispositionType = "DISCHARGED" | "ASSUMED" | "BRIDGE_FINANCING" | "" | "REMAIN";
export type DischargeRegisteredByType = "MORTGAGEE" | "OUR_FIRM" | "";
export type MortgageeInterestNotedOnPolicy = "QUESTION" | "YES" | "NO";
export type FinancingType = 'MORTGAGE' | 'CAVEAT';


export interface DdlTypeForYesNo {
    label : string;
    value : DpBooleanValue;
}

export interface DdMortgageeType {
    label : string;
    value : MortgageeType;
}

export interface DdlTypeForLoan {
    label : string;
    value : LoanType;
}

export interface DdDispositionType {
    label : string;
    value : DispositionType;
}

export interface DdDischargeRegisteredByType {
    label : string;
    value : DischargeRegisteredByType;
}

export interface DdlTypeForCorrespondence {
    label : string;
    value : MortgageCorrespondenceType;
}

export interface MortgageCapacityOption {
    label : string;
    value : MortgageCapacityType;
}


export const SctNoF9Value = {
    Several : 'several'
};

export class Mortgage extends BaseEntity {

    id : number;
    static clientAssignedIdentifierEntity: boolean = true; //Marker property for indicating client side assigns the ID to this entity
    loanType : LoanType;
    mortgageType : MortgageType;
    mortgageSource : MortgageSource;
    mortgageeType : MortgageeType;
    mortgageCorrespondenceType : MortgageCorrespondenceType;
    insured : DpBooleanValue;
    acting : DpBooleanValue;
    addresses : Address[];
    lawFirmName : string;
    loanNo : string;
    commitmentDate : string;
    branchOrTransitNo : string;
    instructionsReceived : DpBooleanValue;
    preliminaryReportSubmitted : DpBooleanValue;
    requestForFundsSubmitted : DpBooleanValue;
    finalReportSubmitted : DpBooleanValue;
    mortgageContactInfo : MortgageContactInfo;
    mortgageTerm : MortgageTerm;
    guarantorPartyToMortgage : boolean;
    includeAuthorizeSignOfficer : DpBooleanValue;
    interimEnclosures : number[] = [];
    interimEnclosuresDefaultsOverridden : boolean = false;
    finalEnclosures : number[];
    finalEnclosuresDefaultsOverridden : boolean = false;
    mortgageReport : MortgageReport;
    mortgageCapacityType : MortgageCapacityType;
    mortgageCapacityDescription : string;
    mortgageBrokerAttention : string;
    mortgageBrokerAttentionId : number;
    brokerageFee : number;
    automaticallyUpdateBrokerageFeeTrustLedger : boolean;
    mortgageInstructionDataChanged : boolean;
    //Existing Mortgage
    mortgageDispositionType : string;
    statementForInformation : DpBooleanValue;
    mortgageRequestNo : string;
    assignmentRequestNo : string;
    mortgageRequestDate : string;
    amountPayableToDischarge : number;
    dischargeRegisteredBy : string;
    dischargePreparedBy: string;
    principalOfMortgageAssumed : number;
    mortgagePayout : MortgagePayout;
    mortgagePriority : number;
    deductMonies : string;
    lenderReline : string;
    undertakingId : number; //references undertaking auto-created from existing mortgage
    permittedRegistrationId : number; //references permitted registration auto-created from existing mortgage
    automaticallyUpdateSOA: string;
    teranetSuggestedMortgagees : string;
    stewartAssystMortgageInstruction : StewartMortgageInstruction;
    undertakingDirty : boolean; //Client side flag for detecting if update to undertaking (or permitted registration) for existing mortgage is needed. Not saved to back end.
    instrumentId: number; //used on UI only -- populated when an existing mortgage was added from an instrument (eg. Alberta - SPIN)
    lienHoldback : DpBooleanValue;
    isBlanketMortgage : DpBooleanValue;

    //EMP Mortgage

    mortgageSigningDate : string;
    mortgageSigningDateSelected: boolean;
    reconciled: boolean;
    itemizeMortgageAdvanceInTrustAdvance: boolean;

    //This field is displayed on Fire insurance tab but saved with mortgage
    mortgageeInterestNotedOnPolicy: MortgageeInterestNotedOnPolicy;
    multiTemplatePriority : number;
    financingType : string;
    iscClientNumber: string;

    //Fields for Project Sale only
    autoIncludeVtbMortgageAmount: DpBooleanValue;
    autoCreateVtbMortgageAdjustment: DpBooleanValue;

    //LSOForm9 object will be saved in a JSON format
    lsoForm9: LSOForm9;

    relatedInstruments: MortgageInstrument[] = [];

    rollBookNumber: number;
    framePageNumber: number;

    collateralMortgage : DpBooleanValue;

    payoutStatement: PayoutStatement;  //Field is only on UI to store Assyst/Unity® Lender Centre Payout

    constructor(mortgage? : Mortgage) {
        super(mortgage);
        if(mortgage) {
            if(Array.isArray(mortgage.addresses)) {
                this.addresses = [];
                for(let i : number = 0; i < mortgage.addresses.length; i++) {
                    this.addresses[i] = new Address(mortgage.addresses[i]);
                }
            }

            if(Array.isArray(mortgage.relatedInstruments)) {
                this.relatedInstruments = [];
                for(let i : number = 0; i < mortgage.relatedInstruments.length; i++) {
                    this.relatedInstruments[i] = new MortgageInstrument(mortgage.relatedInstruments[i]);
                }
            }

            if(mortgage.mortgageTerm) {
                this.mortgageTerm = new MortgageTerm(mortgage.mortgageTerm);
            }
            else {
                this.mortgageTerm = new MortgageTerm();
            }
            if(mortgage.interimEnclosures) {
                this.interimEnclosures = mortgage.interimEnclosures;
            }
            if(mortgage.finalEnclosures) {
                this.finalEnclosures = mortgage.finalEnclosures;
            }
            if(mortgage.mortgageContactInfo) {
                this.mortgageContactInfo = new MortgageContactInfo(mortgage.mortgageContactInfo);
            } else {
                this.mortgageContactInfo = new MortgageContactInfo();
            }
            if(mortgage.mortgageReport) {
                this.mortgageReport = new MortgageReport(mortgage.mortgageReport);
            }
            else {
                this.mortgageReport = new MortgageReport();
            }

            if(mortgage.mortgagePayout) {
                this.mortgagePayout = new MortgagePayout(mortgage.mortgagePayout);
            }
            else {
                this.mortgagePayout = new MortgagePayout();
            }

            if(mortgage.stewartAssystMortgageInstruction) {
                this.stewartAssystMortgageInstruction = new StewartMortgageInstruction(mortgage.stewartAssystMortgageInstruction);
            }
            else {
                this.stewartAssystMortgageInstruction = new StewartMortgageInstruction();
            }

            if(mortgage.lsoForm9) {
                this.lsoForm9 = new LSOForm9(mortgage.lsoForm9);
            } else {
                this.lsoForm9 = new LSOForm9();
            }
            if(mortgage.payoutStatement) {
                this.payoutStatement = new PayoutStatement(mortgage.payoutStatement)
            }

        }
        else {
            this.addresses = [];
            this.mortgageContactInfo = new MortgageContactInfo();
            this.mortgageTerm = new MortgageTerm();
            this.mortgageReport = new MortgageReport();
            this.mortgagePayout = new MortgagePayout();
        }
    }

    update(source : Mortgage) {
        if(source) {
            for(let prop in source) {
                if(source.hasOwnProperty(prop)) {
                    this[prop] = source[prop];
                }
            }
        }
    }

    isMortgageePrivateLender() : boolean {
        return (this.mortgageeType == 'PRIVATE_LENDER');
    }

    isMortgageeAnInstitution(): boolean {
        return (this.mortgageeType === 'INSTITUTION');
    }

    isMortgageeBlank() : boolean {
        return (this.mortgageeType === '');
    }

    isLoanTypeArranged() : boolean {
        return (this.loanType == 'ARRANGED');
    }

    isLoanTypeNew() : boolean {
        return (this.loanType == 'NEW');
    }

    isLoanTypeBridge() : boolean {
        return (this.loanType == 'BRIDGE');
    }

    isLoanTypeAssumed() : boolean {
        return (this.loanType == 'ASSUMED');
    }

    isMortgageDispositionTypeDischarged() : boolean {
        return (this.mortgageDispositionType == MortgageDispositionType.DISCHARGED);
    }

    isMortgageDispositionTypeBridgeFinancing() : boolean {
        return (this.mortgageDispositionType == MortgageDispositionType.BRIDGE_FINANCING);
    }

    isLoanTypeBackToVendor() : boolean {
        return (this.loanType == 'BACK_TO_VENDOR');
    }

    isMortgageCorrespondWithSolicitor() : boolean {
        return this.mortgageCorrespondenceType == 'SOLICITOR';
    }

    cleanUpMortgage() : void {
        if( !(this.loanType == 'ARRANGED' || this.loanType == 'NEW') && !this.isExistingMortgage() ) {
            this.insured = 'N_y';
            this.acting = 'Y_n';
            this.mortgageCorrespondenceType = '';
            this.lawFirmName = '';
        }

        if(!this.isExistingMortgage() && !this.isMortgageeAnInstitution) {
            this.includeAuthorizeSignOfficer = "N_y";
        }

        if(this.loanType === 'ASSUMED' || this.loanType === 'BACK_TO_VENDOR' || this.loanType === 'BRIDGE') {
            this.commitmentDate = '';
            if(this.loanType !== 'BRIDGE') {
                this.interimEnclosures = [];
                this.interimEnclosuresDefaultsOverridden = false;
            }
        }
        if(this.loanType === 'BACK_TO_VENDOR'){
            this.autoIncludeVtbMortgageAmount = DpBooleanValueTypes.Y_n;
        }

        if(!this.isLoanTypeArranged()) {
            this.autoCreateVtbMortgageAdjustment = DpBooleanValueTypes.N_y;
        }

        if(this.mortgageeType && this.mortgageeType !== 'INSTITUTION') {
            this.branchOrTransitNo = '';
        }

        if(this.mortgageContactInfo) {

            if(this.includeAuthorizeSignOfficer === "N_y" || this.includeAuthorizeSignOfficer === "NO") {
                this.mortgageContactInfo.additionalName1 = null;
                this.mortgageContactInfo.titleOfOfficeHeld1 = null;
                this.mortgageContactInfo.additionalName2 = null;
                this.mortgageContactInfo.titleOfOfficeHeld2 = null;
            }


            if(!this.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode) {
                this.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode = AddressTypes.manuallyEntered;
            } else if(this.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode !== AddressTypes.manuallyEntered) {
                const sameAsAddressTypeCode = this.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode;
                const identifier = this.mortgageContactInfo.serviceAddress.identifier;
                this.mortgageContactInfo.serviceAddress = new Address();
                this.mortgageContactInfo.serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
                this.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode = sameAsAddressTypeCode;
                this.mortgageContactInfo.serviceAddress.identifier = identifier;
            }
            if(!this.mortgageContactInfo.reportAddress.sameAsAddressTypeCode) {
                this.mortgageContactInfo.reportAddress.addressTypeCode = AddressTypes.reportAddress;
                this.mortgageContactInfo.reportAddress.sameAsAddressTypeCode = AddressTypes.manuallyEntered;
            } else if(this.mortgageContactInfo.reportAddress.sameAsAddressTypeCode !== AddressTypes.manuallyEntered) {
                const sameAsAddressTypeCode = this.mortgageContactInfo.reportAddress.sameAsAddressTypeCode;
                const identifier = this.mortgageContactInfo.reportAddress.identifier;
                this.mortgageContactInfo.reportAddress = new Address();
                this.mortgageContactInfo.reportAddress.addressTypeCode = AddressTypes.reportAddress;
                this.mortgageContactInfo.reportAddress.sameAsAddressTypeCode = sameAsAddressTypeCode;
                this.mortgageContactInfo.reportAddress.identifier = identifier;

            }
        }
    }

    cleanUpExistingMortgage(provinceCode: string, matterType: string) : void {
        if(this.mortgageDispositionType == '') {
            this.principalOfMortgageAssumed = null;
            this.mortgageRequestNo = null;
            this.mortgageRequestDate = null;
            this.assignmentRequestNo = null;
            this.amountPayableToDischarge = null;
            this.dischargeRegisteredBy = null;
            this.dischargePreparedBy = null;
            this.mortgagePriority = null;
        }

        if(this.mortgageDispositionType === MortgageDispositionType.DISCHARGED) {
            this.principalOfMortgageAssumed = null;
            this.mortgagePriority = null;
        }

        if(this.mortgageDispositionType != MortgageDispositionType.DISCHARGED
            && this.mortgageDispositionType != MortgageDispositionType.BRIDGE_FINANCING)
        {
            this.mortgagePayout = undefined;
        }

        if(this.mortgageDispositionType === MortgageDispositionType.ASSUMED) {
            this.assignmentRequestNo = null;
            this.amountPayableToDischarge = null;
            this.dischargeRegisteredBy = null;
            this.dischargePreparedBy = null;
        }

        if (this.mortgageDispositionType === MortgageDispositionType.BRIDGE_FINANCING) {
            this.principalOfMortgageAssumed = null;
            if(matterType === 'SALE' && provinceCode === 'AB'){
                this.mortgageRequestNo = null;
                this.mortgageRequestDate = null;
                this.assignmentRequestNo = null;
            }
        }

        if(this.mortgageContactInfo) {

            if(this.includeAuthorizeSignOfficer === "N_y" || this.includeAuthorizeSignOfficer === "NO") {
                this.mortgageContactInfo.additionalName1 = null;
                this.mortgageContactInfo.titleOfOfficeHeld1 = null;
                this.mortgageContactInfo.additionalName2 = null;
                this.mortgageContactInfo.titleOfOfficeHeld2 = null;
            }
        }

        // Only DISCHARGED and ASSUMED inlcude relatedInstruments
        if(this.mortgageDispositionType !== MortgageDispositionType.DISCHARGED
            && this.mortgageDispositionType !== MortgageDispositionType.BRIDGE_FINANCING){
            this.relatedInstruments = [];
        }

        if(this.relatedInstruments && this.relatedInstruments.length > 0){
            let relatedInstruments = this.relatedInstruments.filter(item => !item.isMortgageInstrumentEmpty());
            if(relatedInstruments && relatedInstruments.length == 0){
                this.relatedInstruments = [];
            }
        }
    }

    /**
     * Get mortgagee name with modifiers. Used for undertakings (M and Q).
     * @param {Matter} matter
     * @param {string} modifier
     * @returns {string}
     */
    getMortgageeNameWithModifier(matter: Matter, modifier: string): string {
        let modifiedName: string = '';

        if (modifier == 'M' || modifier == 'Q') {
            if (this.mortgageeType == 'PRIVATE_LENDER') {
                let privateLenders: MatterParticipant[] = matter.getPrivateLenders(this);
                if (privateLenders && privateLenders.length > 0) {
                    const privateLenderContacts: Contact[] = privateLenders.map(matterParticipant => matterParticipant.contact);
                    modifiedName = NameModifierUtil.applyModifier(privateLenderContacts, modifier);
                }
            } else {
                let mortgagees: MatterParticipant[] = matter.getMortgagees(this);
                if (mortgagees && mortgagees.length > 0 && mortgagees[0].contact) {
                    modifiedName = mortgagees[0].contact.organizationName;
                }
            }
        }

        return modifiedName;
    }

    isCorrespondWithSolicitor(matter: Matter) : boolean {
        if(matter.isPurchase) {
            return (this.acting === 'NO') && (this.mortgageCorrespondenceType === 'SOLICITOR');
        } else {
            return this.mortgageCorrespondenceType == 'SOLICITOR';
        }
    }


    isMortgageDispositionDischarged() : boolean {
        return (this.mortgageDispositionType  == MortgageDispositionType.DISCHARGED);
    }
    isMortgageDispositionBlank() : boolean {
        return (this.mortgageDispositionType  == MortgageDispositionType.BLANK || !this.mortgageDispositionType);
    }
    isMortgageDispositionAssumed() : boolean {
        return (this.mortgageDispositionType  == MortgageDispositionType.ASSUMED);
    }
    isMortgageDispositionBridgeFinancing() : boolean {
        return (this.mortgageDispositionType  == MortgageDispositionType.BRIDGE_FINANCING);
    }
    isMortgageDispositionRemain() : boolean {
        return (this.mortgageDispositionType  == MortgageDispositionType.REMAIN);
    }

    isMortgageFinancingTypeCaveat():boolean {
        return (this.financingType == 'CAVEAT')
    }

    isMortgageFinancingTypeMortgage():boolean {
        return (this.financingType && this.financingType.toUpperCase() == 'MORTGAGE')
    }

    isMortgageFinancingTypeCharge():boolean {
        return (this.financingType && this.financingType.toUpperCase() == 'CHARGE')
    }

    isMortgageFinancingTypeChargeOrMortgage():boolean {
        return (this.isMortgageFinancingTypeCharge() || this.isMortgageFinancingTypeMortgage())
    }

    isExistingMortgage() : boolean {
        return (this.mortgageType == 'EXISTING' && this.mortgageSource == 'UNITY');
    }

    isUnityNewMortgage() : boolean {
        return (this.mortgageType == 'NEW' && this.mortgageSource == 'UNITY');
    }

    isEmpMortgage() : boolean {
        return (this.mortgageType == 'NEW' && this.mortgageSource == 'EMP');
    }

    //consider both Unity New Mortage and EMP Mortgage
    isNewMortgage() : boolean {
        return this.mortgageType == 'NEW';
    }
    isDischargeRegisteredByOurFirm() : boolean{
        return (this.dischargeRegisteredBy == "OUR_FIRM");
    }

    isCapacityOther() : boolean {
        return ( this.mortgageCapacityType == 'OTHER');
    }

    get isStandardChargeTermNo(): boolean{
        return !(this.mortgageTerm &&
                   (!this.mortgageTerm.sctNo || this.mortgageTerm.sctNo.toUpperCase() === 'SEE SCT'));
     }

    // Better put it here instead of  ERegistrationModalComponent
    get interestRate():string{
        if(this && this.mortgageTerm && this.stewartAssystMortgageInstruction
            && this.stewartAssystMortgageInstruction.mortgageInstructionData)
        {

            var interestRate = this.stewartAssystMortgageInstruction.getInterestRateForRegistrationByProvince(this.stewartAssystMortgageInstruction.matterProvince);

                if( interestRate != null && interestRate.trim() != '' &&  !isNaN(Number(interestRate))){
                    return  Number(interestRate).toFixed(2)  + ' %';
                } else if(interestRate){
                    return interestRate;
                } else{
                    return '';
                }
        }
        else if(this && this.mortgageTerm){
            if(this.mortgageTerm.variableInterestRate == 'NO' || this.mortgageTerm.variableInterestRate == 'N_y'){
                return this.mortgageTerm.interest && this.mortgageTerm.interest != null ? parseFloat(this.mortgageTerm.interest).toFixed(2) + ' % per annum' : '';
            }else if(this.mortgageTerm.variableInterestRate == 'YES'){
                return this.mortgageTerm.variableRate;
            }
            else{
                return "";
            }
        }
        else{
            return "";
        }
    }

    // Better put it here instead of  ERegistrationModalComponent
    getCalculationPeriodFromMatter() : string{
        let calculationPeriod : any =  dropDowns.calculated.find(item => this.mortgageTerm && item.value == this.mortgageTerm.calculated);
        if(calculationPeriod){
            return calculationPeriod.label
        }else if(this.mortgageTerm && this.mortgageTerm.calculated){
            return this.mortgageTerm.calculated;
        }
        else {
            return '';
        }
    }

    get groupId(): string{
        return this.identifier ? this.identifier+'' : '';
    }

    getDischargedMortgageGroupId(): string{
        return this.groupId + '-dispositionTypeDischarged';
    }

    get notBlankDispositionTypeGroupId(): string{
        return this.groupId + '-dispositionTypeNotBlank';
    }

    isMortgageLienHoldback() : boolean {
        return this.lienHoldback == DpBooleanValueTypes.YES;
    }

    get empMortgageProvider(): string {
        if (this.isEmpMortgage() && this.stewartAssystMortgageInstruction){
            return this.stewartAssystMortgageInstruction.provider;
        }
        return '';
    }

    isCollateralMortgage():boolean{
        return this.collateralMortgage === 'Y_n' || this.collateralMortgage === 'YES'
    }
}

