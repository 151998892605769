import {StatementAdjustment} from '../statement-adjustment';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {creditVendorTaxRebateShowTypes} from '../model/so-adj-drop-downs';

export class StatementAdjustmentDisplayCreditVendorTaxRebateBuilder {

    static  getDisplayItems(item : StatementAdjustment, mainBuilder : StatementAdjustmentDisplayBuilder) : StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails : StatementOfAdjustmentDisplayItemLine[] = [];
        if(mainBuilder.salePriceAdjustment && item.soAdjCreditVendorTaxRebate) {
            let salePrice : number = mainBuilder.matter.getSalePriceForTaxAdjustments(mainBuilder.federalHstRate, mainBuilder.provinceHstRate);
            if(mainBuilder.matter.isMatterProvinceON){
                sadItemDetails.push(mainBuilder.getNewItemLine(`HST Rebate Federal Portion`,
                    mainBuilder.getCurrency(mainBuilder.salePriceAdjustment.calculateFederalRebatePortion(mainBuilder.federalHstRate, salePrice))
                ));

                sadItemDetails.push(mainBuilder.getNewItemLine(`HST Rebate Provincial Portion`,
                    mainBuilder.getCurrency(mainBuilder.salePriceAdjustment.calculateOntarioRebatePortion(mainBuilder.provinceHstRate, salePrice))
                ));
            }

            sadItemDetails.push(mainBuilder.getNewItemLine(`Amount`, mainBuilder.getCurrency(mainBuilder.salePriceAdjustment.totalRebatePortion(mainBuilder.provinceHstRate,mainBuilder.federalHstRate, salePrice))
                                                          ));
            if(item.soAdjCreditVendorTaxRebate.isAdjusted === 'YES') {
                sadItemDetails.push(mainBuilder.getNewItemLine(`${item.soAdjCreditVendorTaxRebate.getTotalRebatePortionTaxLabel()}`,
                                                               mainBuilder.getCurrency(mainBuilder.salePriceAdjustment.calculateTotalRebatePortionTax(mainBuilder.provinceHstRate, mainBuilder.federalHstRate, salePrice, item.soAdjCreditVendorTaxRebate.percentage))));
            }

            if(item.soAdjCreditVendorTaxRebate.showAdjustmentAs === creditVendorTaxRebateShowTypes.separateAdjustment.value) {
                sadItemDetails.push(mainBuilder.getNewItemLine(`Credit Vendor`, ''));
            }

        }
        return sadItemDetails;
    }
}
