import {
    MatterParticipantRole,
    matterParticipantRoleLabels,
    MatterParticipantRoleTypes
} from '../../matters/shared/matter-participant-role-types';
import {InviteeWrapper} from './invitee-wrapper';
import {ContactInfo} from '../../matters/shared/contact-info';
import * as _ from 'lodash';
import Utils from '../../shared-main/utils';
import {Contact, Matter, MatterParticipant, MatterUtil} from '../../matters/shared';
import {EventData} from '../event-data';
import {MeetingParticipant, MeetingParticipantType} from '../meeting-participant';
import {UUIDUtil} from '../../main/uuid-util';


/*
* Important note to help understand how we collect the meetingParticipant meetingParticipantType and participantIdOnMatter
* meetingParticipantType        ---->   participantIdOnMatter
* _____________________________________________________________
* CONSENTED_SPOUSE              ---->   mp.familyLawActs[0].consentedSpouse.id //Consented spouse contact id
* VENDOR                        ---->   mp.contact.sourceContactId
* MORTGAGOR                     ---->   mp.contact.sourceContactId
* PURCHASER                     ---->   mp.contact.sourceContactId
* GUARANTOR                     ---->   mp.contact.sourceContactId
* REALESTATEAGENT               ---->   mp.contact.sourceContactId
* OTHERPARTY_REALESTATEAGENT    ---->   mp.contact.sourceContactId
* SURVEYOR                      ---->   mp.contact.sourceContactId
* OTHERPARTY_SOLICITOR          ---->   matter.contactInfo.id
* MORTGAGE_SOLICITOR            ---->   mp.contact.id
* */

export class AppointmentUtil {

    static loadMeetingParticipants(matter: Matter, eventMeetingParticipants: MeetingParticipant[]) : MeetingParticipant[] {
        let meetingParticipants: MeetingParticipant[] = [];
        meetingParticipants.push(...this.loadMainClients(matter, eventMeetingParticipants,"<MAIN_TOPIC>"));
        meetingParticipants.push(...this.loadOtherParties(matter, eventMeetingParticipants,"<TAB_C_TOPIC>"));
        meetingParticipants.push(...AppointmentUtil.loadMatterParticipant(matter, eventMeetingParticipants, MatterParticipantRoleTypes.OFFEROR,
            'Offeror', matter.isProjectSale? "<TAB_C_TOPIC>" : "<MAIN_TOPIC>"));
        meetingParticipants.push(...AppointmentUtil.loadMatterParticipant(matter, eventMeetingParticipants, MatterParticipantRoleTypes.GUARANTOR, 'Guarantor', null));
        meetingParticipants.push(...AppointmentUtil.loadMortgagees(matter, eventMeetingParticipants));
        meetingParticipants.push(...AppointmentUtil.loadMatterParticipant(matter, eventMeetingParticipants, MatterParticipantRoleTypes.REALESTATEAGENT, 'Listing Agent',
            matter.isSale? 'RE Broker / Commission' :'Matter Opening'));
        meetingParticipants.push(...AppointmentUtil.loadMatterParticipant(matter, eventMeetingParticipants, MatterParticipantRoleTypes.OTHERPARTY_REALESTATEAGENT, 'Purchaser Agent ', 'RE Broker / Commission'));
        meetingParticipants.push(...AppointmentUtil.loadMatterParticipant(matter, eventMeetingParticipants, 'SURVEYOR', 'Surveyor',
            MatterUtil.getSubjectProperty(matter, 'Property/Teranet Connect')));
        // Remove repeated names and add the additional names to the meeting participant name.
        meetingParticipants = this.filterMeetingParticipantsWithUniqueNamesAndEmail(meetingParticipants);
        return meetingParticipants;
    }

    /*
    * There should only be one instance of a contact in the appointment invitee list
    * in case of duplicate the first instance is displayed
    * Uniqueness is based solely on the contact's name and email
    * */
    static filterMeetingParticipantsWithUniqueNamesAndEmail(meetingParticipants: MeetingParticipant[]) : MeetingParticipant[] {
        let result : MeetingParticipant[] = [];
        if(meetingParticipants && meetingParticipants.length){
            result = this.uniqueBy(meetingParticipants, ['name', 'email']);
        }

        return result;
    }

    /*
    * This function takes an array of objects and an array of properties
    * It returns an array with unique entries for those specific properties
    * Eg:
    * arr = [{name: 'Noha1', email:'noha@doprocess.com', role:'Client'},
    *       {name: 'Noha1', email:'noha@doprocess.com', role:'Guarantor'},
    *       {name: 'Noha1', email:'noha@dyedurham.com', role:'Offeror'},
    *       {name: 'Noha2', email:'noha@test.com', role:'SURVEYOR'}]
    * keyProps = ['name', 'email']
    * @return: [{name: 'Noha1', email:'noha@doprocess.com', role:'Client'},
    *       {name: 'Noha1', email:'noha@dyedurham.com', role:'Offeror'},
    *       {name: 'Noha2', email:'noha@test.com', role:'SURVEYOR'}]
    * */
    static uniqueBy(arr, keyProps) : any[]{
        let keyValueArray = arr.map(entry => {
            let propValues = keyProps.map(k => entry[k] && entry[k].toLowerCase());
            if(propValues && propValues.length){
                let key = propValues.join('|');
                return [key, entry];
            }
        });
        // this will remove duplicate entry with same key.
        // reverse to keep the first not the last
        let map = new Map(keyValueArray.reverse());
        // convert back to array from all entry's value
        // reverse to keep the original order
        return (Array.from(map.values())).reverse();
    }

    static loadMainClients(matter: Matter,  eventMeetingParticipants: MeetingParticipant[], tabLabel: string): MeetingParticipant[] {

        let invitees: MeetingParticipant[] = [];
        if (Array.isArray(matter.matterParticipants) && matter.matterParticipants.length > 0) {
            let clientsOnly: MatterParticipant[] = matter.matterParticipants.filter(mp => mp.matterParticipantRole == matter.mainClientType);
            let primaryClient = clientsOnly.find(client => client.primary);
            if(primaryClient){
                invitees.push(...this.createMeetingParticipantFromMatterParticipant(matter, primaryClient, 'My Primary Client', eventMeetingParticipants, tabLabel));
                let consentedSpouseMeetingParticipant = this.createMeetingParticipantForConsentedSpouse(primaryClient, eventMeetingParticipants, tabLabel);
                if(consentedSpouseMeetingParticipant){
                    invitees.push(consentedSpouseMeetingParticipant)
                }

                if (matter.matterType === 'WILL' && primaryClient.matterParticipantSpouse) {
                    invitees.push(...this.createMeetingParticipantFromMatterParticipant(
                        matter,
                        matter.getMatterParticipantByRole('TESTATOR_SPOUSE'),
                        'My Spouse Client',
                        eventMeetingParticipants,
                        tabLabel
                    ));
                }
            }

            // for custom matters also add TAB-C matter participants
            if (matter.isCustomMatter()) {
                clientsOnly.push( ... matter.matterParticipants.filter(mp => mp.matterParticipantRole == matter.otherSideClientType));
            }
            let nonPrimaryClients = clientsOnly.filter(client => !client.primary);
            //Adding myClient Mortgagee
            if(matter.isMortgageMatterAndMortgageePrimary()){
                let myClientMortgagee = matter.getMyClientMortgagee();
                if(myClientMortgagee){
                    nonPrimaryClients.push(myClientMortgagee);
                }
            }
            nonPrimaryClients = _.sortBy(nonPrimaryClients, '[contact.displayName]');
            nonPrimaryClients.forEach((client: MatterParticipant)=>{
                invitees.push(...this.createMeetingParticipantFromMatterParticipant(matter, client, 'My Client', eventMeetingParticipants, "<MAIN_TOPIC>"));
                let consenterSpouseMeetingParticipant = this.createMeetingParticipantForConsentedSpouse(client, eventMeetingParticipants,"<MAIN_TOPIC>");
                if(consenterSpouseMeetingParticipant){
                    invitees.push(consenterSpouseMeetingParticipant)
                }
            });
        }
        return invitees;
    }

    static createMeetingParticipantForConsentedSpouse(mp: MatterParticipant, eventMeetingParticipants: MeetingParticipant[], tabLabel: string) : MeetingParticipant {
        let consentedSpouse: Contact = mp.consentingSpouseContact;
        if(consentedSpouse && consentedSpouse.id ){
            return this.createMeetingParticipant((consentedSpouse.genericName) ? consentedSpouse.genericName : consentedSpouse.contactName.fullName,
                this.getMeetingParticipantId(consentedSpouse, 'CONSENTING_SPOUSE'), 'CONSENTED_SPOUSE',
                consentedSpouse.firstEmail, 'Consented Spouse', eventMeetingParticipants, null,null,consentedSpouse.getMobileNumber(), tabLabel);
        } else {
            return null;
        }
    }

    static createMeetingParticipantFromMatterParticipant(matter: Matter, mp: MatterParticipant, participantLabel: string, eventMeetingParticipants: MeetingParticipant[], tabLabel: string) : MeetingParticipant[] {
        if(mp.contact && (mp.contact.isPoaEstate0rOtherEntity || mp.contact.isCorporation)){
            let childMeetingParticipants: MeetingParticipant[] = [];
            let childMatterParticipants = matter.getChildSigners(mp);
            if(childMatterParticipants && childMatterParticipants.length){
                childMatterParticipants.forEach((childMp : MatterParticipant, index: number)=>{
                    let namePrefix = ((mp.contact.genericName) ? mp.contact.genericName : mp.contact.contactName.fullName) + ` - ${matterParticipantRoleLabels[childMp.matterParticipantRole]} #${index+1} `;
                    childMeetingParticipants.push(this.createMeetingParticipant((childMp.contact.genericName) ? childMp.contact.genericName : childMp.contact.contactName.fullName,
                        this.getMeetingParticipantId(childMp.contact, mp.matterParticipantRole),
                        <MeetingParticipantType>mp.matterParticipantRole, mp.contact && childMp.contact.firstEmail,
                        participantLabel, eventMeetingParticipants, null, namePrefix,childMp.contact && childMp.contact.getMobileNumber(), tabLabel));
                });
            }
            return childMeetingParticipants;
        } else {
            return [this.createMeetingParticipant((mp.contact.genericName) ? mp.contact.genericName : mp.contact.contactName.fullName,
                this.getMeetingParticipantId(mp.contact, mp.matterParticipantRole), <MeetingParticipantType>mp.matterParticipantRole, mp.contact && mp.contact.firstEmail,
                participantLabel, eventMeetingParticipants, null,null,mp.contact && mp.contact.getMobileNumber() , tabLabel)];
        }
    }

    static getAdditionalNameLabel(contact: Contact) : string {
        if(contact.isCorporationOrOtherEntity){
            return 'Signing Officer';
        }
        if(contact.isEstate){
           return 'Estate Trustee';
        }
        if(contact.isMalePoaOrFemalePoa){
            return 'Attorney';
        }
        return '';
    }

    static createMeetingParticipant(name: string, participantIdOnMatter: number, meetingParticipantType: MeetingParticipantType, email: string, label:string,
                                    existingMeetingParicipants: MeetingParticipant[], subContactIndex?: number, namePrefix?: string, mobileNumber ?: string, tabLabel?: string) : MeetingParticipant {
        let meetingParticipant = this.findExistingMeetingParticipant(existingMeetingParicipants, participantIdOnMatter, subContactIndex);
        if(!meetingParticipant){
            meetingParticipant = new MeetingParticipant();
            meetingParticipant.id = UUIDUtil.getUUID();
        }
        meetingParticipant.name = name;
        meetingParticipant.meetingParticipantRole = 'INVITEE';
        meetingParticipant.participantIdOnMatter = participantIdOnMatter;
        meetingParticipant.meetingParticipantType = meetingParticipantType;
        meetingParticipant.email = email;
        meetingParticipant.participantLabel = label;
        meetingParticipant.tabLabel = tabLabel;
        if(subContactIndex){
            meetingParticipant.subContactIndex = subContactIndex;
        }
        if(mobileNumber){
            meetingParticipant.mobileNumber = mobileNumber;
        }
        if(namePrefix){
            meetingParticipant.namePrefix = namePrefix;
        }
        return meetingParticipant;
    }

    static loadOtherParties(matter: Matter, eventMeetingParticipants: MeetingParticipant[], tabLabel: string): MeetingParticipant[]{
        let invitees: MeetingParticipant[] = this.createMeetingParticipantsByRole(matter, eventMeetingParticipants,  'OTHERPARTY_SOLICITOR', 'Other Side Solicitor',  tabLabel);

        //Other side law clerk is not a matter participant
        if(matter && matter.contactInfo){
            const otherSideLawClerk: ContactInfo = matter.contactInfo.find( contactInfo => contactInfo.lawClerkName !== null);
            if(otherSideLawClerk){
                let id = this.getMeetingParticipantId(null, null, otherSideLawClerk);
                invitees.push(this.createMeetingParticipant(otherSideLawClerk.lawClerkName, id, 'OTHERPARTY_LAWCLERK',
                    otherSideLawClerk.lawClerkEmail, 'Other Side Law Clerk', eventMeetingParticipants, null, null,otherSideLawClerk.lawClerkCellPhone, tabLabel));
            }
        }
        return invitees;
    }

    static createMeetingParticipantsByRole(matter: Matter, eventMeetingParticipants: MeetingParticipant[],  matterParticipantRole: MatterParticipantRole, participantLabel: string, tabLabel: string) : MeetingParticipant[] {
        let invitees: MeetingParticipant[] = [];
        if (Array.isArray(matter.matterParticipants) && matter.matterParticipants.length > 0) {
            let filteredMps = _.sortBy(matter.matterParticipants.filter(mp => mp.matterParticipantRole === matterParticipantRole), '[contact.displayName]');
            filteredMps.forEach((mp)=>{
                invitees.push(...this.createMeetingParticipantFromMatterParticipant(matter, mp, participantLabel, eventMeetingParticipants, tabLabel));
            });
        }
        return invitees;
    }

    static loadMatterParticipant(matter: Matter, eventMeetingParticipants: MeetingParticipant[], matterParticipantRole: MatterParticipantRole, participantLabel: string, tabLabel: string): MeetingParticipant[] {
        let invitees: MeetingParticipant[] = [];
        if (Array.isArray(matter.matterParticipants) && matter.matterParticipants.length > 0) {
            let filteredMps = _.sortBy(matter.matterParticipants.filter(mp => mp.matterParticipantRole == matterParticipantRole), '[contact.displayName]');
            filteredMps.forEach((mp)=>{
                invitees.push(...this.createMeetingParticipantFromMatterParticipant(matter, mp, participantLabel, eventMeetingParticipants,
                    tabLabel? tabLabel
                            : (matterParticipantRole == MatterParticipantRoleTypes.GUARANTOR
                                ? this.getMortgageTab(matter, mp.mortgageId)
                                : '')));
            });
        }
        return invitees;
    }

    static loadMortgagees(matter: Matter,eventMeetingParticipants: MeetingParticipant[]): MeetingParticipant[] {
        let invitees: MeetingParticipant[] = [];
        if (Array.isArray(matter.matterParticipants) && matter.matterParticipants.length > 0) {
            invitees = matter.matterParticipants.filter(mp => mp.matterParticipantRole == MatterParticipantRoleTypes.MORTGAGE_SOLICITOR)
                .map(mp =>
                    this.createMeetingParticipant(mp.contact.genericName, this.getMeetingParticipantId(mp.contact, MatterParticipantRoleTypes.MORTGAGE_SOLICITOR), 'MORTGAGE_SOLICITOR',
                        mp.contact.firstEmail, this.getMortgageTitle(matter, mp.mortgageId) + ' Solicitor', eventMeetingParticipants,null,null,mp.contact.getMobileNumber(), this.getMortgageTab(matter, mp.mortgageId)));
        }
        return invitees;
    }

    static findExistingMeetingParticipant(eventMeetingParticipants: MeetingParticipant[] , participantIdOnMatter: number, subContactIndex?: number) : MeetingParticipant {
        if(eventMeetingParticipants && eventMeetingParticipants.length){
            if(subContactIndex){
                return eventMeetingParticipants.find(mp=>mp.participantIdOnMatter == participantIdOnMatter && mp.subContactIndex == subContactIndex);
            } else {
                return eventMeetingParticipants.find(mp=>mp.participantIdOnMatter == participantIdOnMatter);
            }
        }
    }

    static getMortgageTitle(matter: Matter, mortgageId: number): string {
        if(matter && matter.existingMortgages){
            const existingMortgage = matter.existingMortgages.find(mrtg => mrtg.id === mortgageId);
            if (existingMortgage) {
                return Utils.getOrdinal(matter.existingMortgages.indexOf(existingMortgage) + 1) + ' Existing Mtge';
            }
        }
        if(matter && matter.mortgages){
            const newMortgage = matter.mortgages.find(mrtg => mrtg.id === mortgageId);
            if (newMortgage) {
                return Utils.getOrdinal(newMortgage.mortgagePriority) + ' New Mtge';
            }
        }
        return 'Unknown Mtge'; // it should not come to this point
    }

    static getMortgageTab(matter: Matter, mortgageId: number) {
        const existingMortgage = matter.existingMortgages.find(mrtg => mrtg.id === mortgageId);
        if (existingMortgage) {
            return Utils.getOrdinal(matter.existingMortgages.indexOf(existingMortgage) + 1) + ' Existing Mortgage';
        }
        if(matter && matter.mortgages){
            const newMortgage = matter.mortgages.find(mrtg => mrtg.id === mortgageId);
            if (newMortgage) {
                if(matter.isPurchase){
                    return Utils.getOrdinal(matter.mortgages.indexOf(newMortgage) + 1) + ' Mortgage';
                } else {
                    return Utils.getOrdinal(newMortgage.mortgagePriority)
                        + (matter.isMortgage
                            ? ' New Mortgage'
                            : (matter.isProjectSale)? " Purchaser\'s Mortgage" : " VTB Mortgage");
                }

            }
        }

        return 'Unknown Mortgages'; // it should not come to this point
    }

    static selectedParticipantsList(meetingParticipants: MeetingParticipant [], eventInvitees: InviteeWrapper[]): string {
        if (meetingParticipants && meetingParticipants.length > 0) {
            return meetingParticipants.map(mp => mp.namePrefix ? mp.namePrefix + mp.name : mp.name).join(', ');
        }
        if (eventInvitees) {
            return `No invitee${eventInvitees.length > 0 ? '' : 's'} selected`;
        }
        return '';
    }

    static getScheduledForList(lawClerkSolicitorList : Contact[]) : any[] {
        let scheduledForList: any[] = [{
            label: '',
            value: 'N/A'
        }];
        lawClerkSolicitorList
            .forEach( (item) => {
                    scheduledForList.push(
                        {
                            label: item.contactName.surnameLastFullName,
                            value: item.id,
                            email: item.email,
                            initials: item.initials
                        }
                    );
                }
            );
        return scheduledForList;
    }

    static createEventInviteesFromMeetingParticipants(meetingParticipants: MeetingParticipant[]) : InviteeWrapper[] {
        let eventInvitees : InviteeWrapper[] = [];
        if(meetingParticipants){
            meetingParticipants.forEach((mp)=>{
                let name = mp.namePrefix ? mp.namePrefix + mp.name : mp.name;
                eventInvitees.push(new InviteeWrapper(name, mp.participantLabel, String(mp.id), mp.email))
            });
        }

        return eventInvitees;
    }

    // Converts inviteeParticipantIds to meetingParticipants to take care of existing appoitnments
    static convertInviteeParticipantIdsToMeetingParticipants(eventData: EventData , matterMeetingParticipants: MeetingParticipant[]) : void {
        if(eventData && eventData.meetingParticipants && eventData.meetingParticipants.length){
            return;
        } else {
            eventData.meetingParticipants = [];
        }
        if(eventData && eventData.inviteeParticipantIds && eventData.inviteeParticipantIds.length && matterMeetingParticipants && matterMeetingParticipants.length){
            eventData.inviteeParticipantIds.forEach((id)=>{
                let eventMeetingParticpnat = matterMeetingParticipants.find(mp=>mp.participantIdOnMatter == id);
                if(eventMeetingParticpnat) {
                    eventData.meetingParticipants.push(eventMeetingParticpnat);
                }
            });
        }
    }

    static loadStaffMeetingParticipants(staffList: any[], existingMeetingParicipants: MeetingParticipant[]) : MeetingParticipant[] {
        let meetingParticipants = [];
        staffList.forEach((item)=>{
            meetingParticipants.push(this.createMeetingParticipant(item.label, item.value, 'STAFF_PARTICIPANT', item.email, '',  existingMeetingParicipants,null,null,item.mobileNumber ))
        });
        return meetingParticipants;
    }

    static getMeetingParticipantId(contact: Contact, matterParticipantRole: MatterParticipantRole, contactInfo?: ContactInfo) : number {
        if(contactInfo){
            return contactInfo.id;
        }
        if(contact){
            let id = contact.sourceContactId ? contact.sourceContactId : contact.id;
            if(matterParticipantRole){
                switch(matterParticipantRole) {
                    case 'MORTGAGE_SOLICITOR' :
                    case 'CONSENTING_SPOUSE' :
                        id = contact.id; break;
                }
            }
            return id;
        }
    }

}
