import {SoAdjInterimFee} from './so-adj-interim-fee';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';

export class SoAdjInterimEarlyPossessionFee extends SoAdjInterimFee{

    static getDefault(considerationTaxes: ConsiderationTaxes): SoAdjInterimEarlyPossessionFee {
        let newAdj: SoAdjInterimEarlyPossessionFee = new SoAdjInterimEarlyPossessionFee();

        newAdj.calculateFor = 'THIS_MONTH_ONLY';

        newAdj.mortgageInterest = 0;
        newAdj.mortgageInterestPercentage = 0.0;
        newAdj.mortgageInterestTotal = 0;
        newAdj.postDatedChequesToCommence = 2;

        newAdj.commonExpense = 0;
        newAdj.commonExpensePercentage = 0.0;
        newAdj.commonExpenseTotal = 0;
        newAdj.postDatedChequesToCommence = 2;

        newAdj.taxes = 0;
        newAdj.taxesPercentage = 0.0;
        newAdj.taxesTotal = 0;
        newAdj.postDatedChequesToCommence = 2;

        newAdj.applyTax = true;
        newAdj.taxType = considerationTaxes.rateType;
        newAdj.hstRate = considerationTaxes.hstRate;

        newAdj.postDatedChequesToCommence = 0;

        return newAdj;
    }
}
