import {FlaStatement} from './fla-statement';
import {Contact} from './contact';
import {DpBooleanValue, DpBooleanValueTypes} from './dp-boolean';
import {MatterParticipant} from './matter-participant';
import {MatterParticipantService} from '../matter-participant-service';
import {Matter} from './matter';
import {FlaStatementType} from '../../shared-main/constants';

export type MaritalStatusType = 'NOT_MARRIED' | 'MARRIED';

export class MaritalStatusTypes {
    public static readonly NOT_MARRIED : MaritalStatusType = "NOT_MARRIED";
    public static readonly MARRIED : MaritalStatusType = "MARRIED";
}

export type ApplicableProvisionOptionsType = 'SPOUSE_IS_A_PARTY' | 'SPOUSE_IS_CONSENTING' | 'DOMESTIC_CONTRACT' | 'RELEASED_BY_COURT_ORDER' | 'RELEASED_BY_DEED' | 'DISPOSITION_AUTHORIZED_BY_COURT_ORDER';

export class ApplicableProvisionOptionsTypes {
    public static readonly SPOUSE_IS_A_PARTY : ApplicableProvisionOptionsType = "SPOUSE_IS_A_PARTY";
    public static readonly SPOUSE_IS_CONSENTING : ApplicableProvisionOptionsType = "SPOUSE_IS_CONSENTING";
    public static readonly DOMESTIC_CONTRACT : ApplicableProvisionOptionsType = "DOMESTIC_CONTRACT";
    public static readonly RELEASED_BY_COURT_ORDER : ApplicableProvisionOptionsType = "RELEASED_BY_COURT_ORDER";
    public static readonly RELEASED_BY_DEED : ApplicableProvisionOptionsType = "RELEASED_BY_DEED";
    public static readonly DISPOSITION_AUTHORIZED_BY_COURT_ORDER : ApplicableProvisionOptionsType = "DISPOSITION_AUTHORIZED_BY_COURT_ORDER";
}

export const FlaDropDown = {
    maritalStatusOptions : [
        {label: '', value: null},
        {label: 'not married', value: MaritalStatusTypes.NOT_MARRIED},
        {label: 'married', value: MaritalStatusTypes.MARRIED},
    ],
    propertyOccupiedAsMaritalHomeOptions : [
        {label : '', value : null},
        {label : 'Yes', value : DpBooleanValueTypes.YES},
        {label : 'No',  value : DpBooleanValueTypes.NO}
    ],
    applicableProvisionOptions : [
        {label: '', value: null},
        {label : 'Spouse is a party', value : ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY},
        {label : 'Spouse is consenting', value : ApplicableProvisionOptionsTypes.SPOUSE_IS_CONSENTING},
        {label : 'Domestic contract',  value : ApplicableProvisionOptionsTypes.DOMESTIC_CONTRACT},
        {label : 'Released by court order', value : ApplicableProvisionOptionsTypes.RELEASED_BY_COURT_ORDER},
        {label : 'Released by deed', value : ApplicableProvisionOptionsTypes.RELEASED_BY_DEED},
        {label : 'Disposition authorized by court order',  value : ApplicableProvisionOptionsTypes.DISPOSITION_AUTHORIZED_BY_COURT_ORDER}
    ],
}

export class FlaData{
    FamilyLawActStatementTypes: FlaStatement[];

    constructor (flaData?: FlaData) {
        if(flaData) {
            for (let prop in flaData) {
                if (flaData.hasOwnProperty(prop)) {
                    this[prop] = flaData[prop];
                }
            }

            this.FamilyLawActStatementTypes = [];
            if (Array.isArray(flaData.FamilyLawActStatementTypes)) {
                for (let i: number = 0; i < flaData.FamilyLawActStatementTypes.length; i++) {
                        this.FamilyLawActStatementTypes[i] = new FlaStatement(flaData.FamilyLawActStatementTypes[i]);
                }
            }
        }
    }

    getFlaStatementByTypeCode(typeCode: string): FlaStatement {
        return this.FamilyLawActStatementTypes.find((flaStatement: FlaStatement) => flaStatement.flaTypeCode === typeCode);
    }

    getMaskingStatementTypes(typeCode: string): string[] {
        return this.FamilyLawActStatementTypes
            .filter((fs: FlaStatement) => fs.inCompatibleStatements.findIndex((v: string) => v === typeCode) !== -1)
            .map((fs: FlaStatement) => fs.flaTypeCode);
    }

}

export class FamilyLawAct {
    constructor(fla?: FamilyLawAct){
        if (fla) {
            this.merge(fla);
        }
    }

    merge?(fla: FamilyLawAct): void {
        if(fla) {
            for (let prop in fla) {
                if (fla.hasOwnProperty(prop)) {
                    this[prop] = fla[prop];
                }
            }
            if(fla.consentedSpouse) {
                this.consentedSpouse = new Contact(this.consentedSpouse);
            }
        }
    }

    copy?(fla: FamilyLawAct): void {
        for (let prop in this) {
            if (prop !== 'familyLawActStatementType' && this.hasOwnProperty(prop)) {
                delete this[prop];
            }
        }

        this.merge(fla);
    }

    cleanUpDataForNB?(matter: Matter, matterParticipantService : MatterParticipantService) {
        //In NB, only when maritalStatus is MARRIED or (propertyOccupiedAsMaritalHome is yes and applicableProvisionis is SPOUSE_IS_CONSENTING),
        // it has consenting Spouse field.
        //If it is not in the above condition, it should clean up consentedSpouse
        if(!(this.isConsentedSpouseSelectedForNB())) {
            this.consentedSpouse = null;
            if(this.consentedSpouseParticipant) {
                this.consentedSpouseParticipant = null;
                matterParticipantService.removeConsentingSpouse(this.consentedSpouseParticipant, matter);
            }
        }
        //In NB, only when propertyOccupiedAsMaritalHome is yes and (applicableProvisionis is DOMESTIC_CONTRACT, RELEASED_BY_COURT_ORDER or RELEASED_BY_COURT_ORDER
        // it has courtOrderDate field.
        //If it is not in the above condition, it should clean up courtOrderDate
        if(!(this.propertyOccupiedAsMaritalHome == DpBooleanValueTypes.YES
            && (this.applicableProvision ==  ApplicableProvisionOptionsTypes.DOMESTIC_CONTRACT
                || this.applicableProvision ==  ApplicableProvisionOptionsTypes.RELEASED_BY_COURT_ORDER
                || this.applicableProvision ==  ApplicableProvisionOptionsTypes.DISPOSITION_AUTHORIZED_BY_COURT_ORDER))) {
            this.courtOrderDate = null;
        }
        //In NB, only when propertyOccupiedAsMaritalHome is yes and applicableProvisionis is RELEASED_BY_DEED, it has instrumentNo field.
        //If it is not in the above condition, it should clean up instrumentNo
        if(!(this.propertyOccupiedAsMaritalHome == DpBooleanValueTypes.YES
            && this.applicableProvision ==  ApplicableProvisionOptionsTypes.RELEASED_BY_DEED)) {
            this.instrumentNo = null;
        }

    }

    getConsentingSpouseLabel?(notIncludeBr?: boolean): string {
        // notIncludeBr is true for error message
        // notIncludeBr isn't true for label
        if(this.maritalStatus == MaritalStatusTypes.NOT_MARRIED){
            return notIncludeBr ? `Former Spouse Name`: `Former Spouse<br>  Name`;
        }else if(this.applicableProvision != ApplicableProvisionOptionsTypes.SPOUSE_IS_CONSENTING){
            return notIncludeBr ? `Name of Spouse` : `Name of<br> Spouse`;
        } else {
            return notIncludeBr ? `Consenting Spouse` : `Consenting<br> Spouse`;
        }
    }

    isConsentedSpouseSelectedForNB?(): boolean{
        return (this.maritalStatus == MaritalStatusTypes.MARRIED  && this.applicableProvision !=  ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY)
            || (this.maritalStatus != MaritalStatusTypes.MARRIED  && this.applicableProvision == ApplicableProvisionOptionsTypes.SPOUSE_IS_CONSENTING);
    }

    isSpouseAParty?(): boolean{
        return this.applicableProvision ==  ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY;
    }

    isConsentedSpouse?(): boolean{
        if(!this.familyLawActStatementType){
            return false;
        } else {
            return this.familyLawActStatementType == 'CONSENTED_SPOUSE' ||
                this.familyLawActStatementType == 'CONSENTED_SPOUSE_REGISTERED_IN_LTO' ||
                this.familyLawActStatementType == 'INTERSPOUSAL_AGREEMENT' || this.familyLawActStatementType == 'AUTHORIZED_BY_COURT'
                || this.familyLawActStatementType == FlaStatementType.NOT_MATRIMONIAL_HOME || this.familyLawActStatementType == FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_SEPARATION_AGREEMENT
                || this.familyLawActStatementType == FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER || this.familyLawActStatementType == FlaStatementType.DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME
                || (this.familyLawActStatementType == FlaStatementType.MARITAL_STATUS_NB && this.isConsentedSpouseSelectedForNB());
        }

    }

    familyLawActStatementType: string;
    id?: number;
    instrumentNo?: string;
    registeredOnDate?: string;
    lastUpdatedTimeStamp?: string;
    matterParticipantId?: number;
    // spouseName?: string;
    otherText?: string;
    consentedSpouse? : Contact; // It is only for cirf import
    consentedSpouseParticipant?: MatterParticipant; // It is for UI ONly. The real data in matterParticipants list of matter
    spouseMatterParticipantId?: number;
    consentSpouseMatterParticipantId?: number;
    designationDate?: string;
    bookNumber?: string;
    pageNumber?: string;
    // This flag is to inform the backend that a new relationship has been established in matter
    // and it should be pushed to source contact but it's not persisted in DB
    relationshipCreatedInMatter?: boolean;

    //The begin of NB fields
    maritalStatus?: MaritalStatusType;
    propertyOccupiedAsMaritalHome?: DpBooleanValue;
    applicableProvision?: ApplicableProvisionOptionsType;
    courtOrderDate?: string;
    //The end of NB fields


}

