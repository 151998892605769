import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {StewartTitleUser} from '../../../shared-main/stewart-title/stewart-title-user';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {StewartTitleService} from '../../../shared-main/stewart-title/stewart-title-service';
import {WindowRef} from '../../../shared/window.ref';
import Utils from '../../../shared-main/utils';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {AppConfig} from '../../../shared-main/app-configuration';

class StewartTitleAuthenticationContext extends BSModalContext {
    stewartTitleUser : StewartTitleUser;
    message: string;
}
@Component({
               selector    : 'dp-stewart-title-authentication-modal',
               templateUrl : 'stewart-title-authentication.modal.component.html',
               providers   : [ErrorService]
           })
@AutoUnsubscribe()
export class StewartTitleAuthenticationModal implements OnInit, ModalComponent<StewartTitleAuthenticationContext> {
    context : StewartTitleAuthenticationContext;
    stewartTitleAuthUrl: SafeUrl;
    @ViewChild('modalErrorComponent') modalErrorComponent : ModalErrorComponent;

    constructor(public dialog : DialogRef<StewartTitleAuthenticationContext>,
                public sanitizer: DomSanitizer,
                public stewartTitleService : StewartTitleService,
                public winRef: WindowRef,
                public appConfig : AppConfig
    ) {
        this.context = dialog.context;
    }

    ngOnInit() {
        this.stewartTitleService.getStewartTitleAuthUrl().subscribe(url => {
            this.stewartTitleAuthUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            //this.redirectToStewartTitleAuthentication(url);
            this.registerAuthenticationCallbackMethod();
        });
    }

    //Temporary solution opening stewart title url in new window instead of iFrame
    redirectToStewartTitleAuthentication(stewartTitleAuthUrl) {
        let newWindow = this.winRef.nativeWindow.open(stewartTitleAuthUrl);
        this.registerAuthenticationCallbackMethod();
    }

    registerAuthenticationCallbackMethod() {
        //Callback method called after stewart title authenticates the user successfully.
        //redirectUrl - This is the unity url to which stewart title redirects to after successful authentication. We extract refreshToken and accessToken
        // from it for further communication with them.
        this.winRef.nativeWindow.stewartTitleAuthenticationCallback = (redirectUrl) => {
            const refreshToken = Utils.extractQueryParamFromUrl(redirectUrl, 'refresh_token');
            const accessToken = Utils.extractQueryParamFromUrl(redirectUrl, 'access_token');
            if(refreshToken) {
                this.dialog.close({refreshToken: refreshToken, accessToken: accessToken});
            } else {
                this.modalErrorComponent.createCustomDPSaveError('none', 'Stewart title cannot be persisted because invalid re-direction to '+redirectUrl,
                                                                 '', 'ERROR');
            }
        }
    }

    close() : void {
        this.dialog.close();
    }

    get stewartTitleUser(): StewartTitleUser {
        return this.context.stewartTitleUser;
    }

    get message(): string {
        return this.context.message;
    }

    ngOnDestroy() {}
}
