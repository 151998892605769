import {StatementAdjustmentPreview} from '../../statement-adjustment/model/statement-adjustment-preview';
import {DirectionReFundsPreview} from '../../direction-re-funds/model/direction-re-funds-preview';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {TemplateStopCode} from '../../document-production/template-stop-code';
import {MatterStopCode} from '../../document-production/multiple-matters-document-call';

export class BulkSharing {
    selectedTemplates: TemplateStopCode[] = []; // List of selected Templates for bulk sharing
    selectedPreviews: string[]; // List of selected Previews.
    sharingMessageSubject : string; // message subject common for all matters
    sharingMessageText: string; // message text
    produceDocumentBeforeSharing: boolean;
    selectedMatters: BulkShareMatterData[]; // List of  BulkShareMatterData

}

export class BulkShareMatterData {
    matterId: number;
    connectRecipientEmail: string;
    recipientName: string;
    unityRecipientIds: number[];
    connectRecipientRole: string;
    sharingMessageSubject : string; // message subject does not common for all matters now DPPMP-52039
    statementAdjustmentPreview : StatementAdjustmentPreview;
    directionReFundsPreview: DirectionReFundsPreview;
}

export const sharingPreviews = {
    statementOfAdjustment : 'STATEMENT_OF_ADJUSTMENT_PREVIEW',
    directionRefunds : 'DIRECTION_REFUNDS_PREVIEW'
};

export class BulkSharingResponse extends BaseEntity{
    bulkSharingResult : string;
    matterStopCodes: MatterStopCode[];
    sharedDocuments: BulkSharedDocumentResponse[];
    errorMessage : string;

    constructor(response?: BulkSharingResponse) {
        super(response);
        if(response){
            if(response.matterStopCodes){
                this.matterStopCodes = response.matterStopCodes.map(stopCode =>new MatterStopCode(stopCode));
            }
            if(response.sharedDocuments){
                this.sharedDocuments = response.sharedDocuments.map(doc =>new BulkSharedDocumentResponse(doc));
            }
        }

    }
}

export const bulkShareResultStatus = {
    COMPLETED : 'COMPLETED',
    PARTIAL : 'PARTIAL',
    FAILED : 'FAILED'
};

export class BulkSharedDocumentResponse extends BaseEntity{
    matterId : number;
    matterRecordNumber: string;
    templateId: number;
    templateName: string;
    documentName: string;

    constructor(response? : BulkSharedDocumentResponse) {
        super(response);
    }
}


