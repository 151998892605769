import {TemplateStopCode} from './template-stop-code';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {DocumentStopCode} from './document-stop-code';

export class MatterStopCode extends BaseEntity{
    matterId : number;
    stopCodesList: Array<DocumentStopCode> = [];
    templateName : string;
    templateId: number;
    //single document mail multipriority indexes
    mortgageIndex : number;
    holdbackIndex : number;

    constructor(matterStopCode?: MatterStopCode) {
        super(matterStopCode);
        if (matterStopCode) {
            this.stopCodesList = [];
            if (matterStopCode.stopCodesList) {
                for (let sc of matterStopCode.stopCodesList) {
                    this.stopCodesList.push(new DocumentStopCode(sc));
                }
            }
        }
    }
}

export class MultipleMattersDocumentCall extends BaseEntity{

    matterIds: number[] = [];
    template : TemplateStopCode;
    masterMatterDocumentId : number;
    matterStopCodes : MatterStopCode[] = [];



    constructor(multipleMattersDocumentRequest?: MultipleMattersDocumentCall) {
        super(multipleMattersDocumentRequest);
        if(multipleMattersDocumentRequest)
        {
            this.matterStopCodes = [];
            if (multipleMattersDocumentRequest.matterStopCodes) {
                for (let matterStopCode of multipleMattersDocumentRequest.matterStopCodes) {
                    this.matterStopCodes.push(new MatterStopCode(matterStopCode));
                }
            }
        }
    }

    static of(_matterIds: number[], _templateId: any, _produceFileType?: string, isLenderDocument?: boolean, customTemplateNameMap?: any[] , matterStopCodes? : MatterStopCode[]): MultipleMattersDocumentCall {
        let multipleMattersDocumentRequest: MultipleMattersDocumentCall = new MultipleMattersDocumentCall();
        multipleMattersDocumentRequest.matterIds = _matterIds;
        if(matterStopCodes && matterStopCodes.length > 0)
        {
            multipleMattersDocumentRequest.matterStopCodes = matterStopCodes
        }

        if (_templateId) {
            //For multi-priority template array has concatenated template id and mortgage index therefore splitting it below
            let templateIdArr: string[] = _templateId.toString().split("-");
            let templateStopCode: TemplateStopCode = new TemplateStopCode();
            templateStopCode.templateId = parseInt(templateIdArr[0]);
            if (_produceFileType == 'WORD') {
                templateStopCode.requestedFileTypes.push('WORD');
            } else if (_produceFileType == 'WORDPERFECT') {
                templateStopCode.requestedFileTypes.push('WORDPERFECT');
            }else if (_produceFileType == 'PDF') {
                templateStopCode.requestedFileTypes.push('PDF');
            } else if (_produceFileType == 'WORD_AND_PDF') {
                templateStopCode.requestedFileTypes.push('WORD');
                templateStopCode.requestedFileTypes.push('PDF');
            }
            if (templateIdArr.length >= 2) {
                if (templateIdArr.length === 3 ) {
                    if(templateIdArr[2] == "A"){
                        templateStopCode.affidavitIndex = parseInt(templateIdArr[1]);
                    } else if(templateIdArr[2] == "HB") {
                        templateStopCode.holdbackIndex = parseInt(templateIdArr[1]);
                    }
                } else {
                    templateStopCode.mortgageIndex = parseInt(templateIdArr[1]);
                }
            }
            if (isLenderDocument) {
                templateStopCode.lenderDocument = true;
            }
            if (customTemplateNameMap && customTemplateNameMap.length > 0) {
                let customTemplate = customTemplateNameMap.find(m => m.templateId == templateStopCode.templateId);
                if (customTemplate) {
                    templateStopCode.customTemplateName = customTemplate.customTemplateName;
                    templateStopCode.isForm = customTemplate.isForm;
                    if (customTemplate.mortgageIndex != undefined) {
                        templateStopCode.mortgageIndex = customTemplate.mortgageIndex;
                    }
                    if (customTemplate.holdbackIndex) {
                        templateStopCode.holdbackIndex = customTemplate.holdbackIndex;
                    }
                }

            }
            multipleMattersDocumentRequest.template = templateStopCode;
        }
        return multipleMattersDocumentRequest;
    }
}
