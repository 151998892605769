//Base class for StewartTitleUser and TitlePlusUser
import {ContactName} from '../matters/shared/contact-name';
import {BaseEntity} from '../shared/BaseEntity/base-entity';

export class TitleInsuranceBaseUser extends BaseEntity{
    id: number;
    profileId: string;
    tempCode: string;
    userAccountProfileId: number;
    solicitorContactId: number;
    solicitorName: ContactName;

    constructor(s? : TitleInsuranceBaseUser) {
        super(s);
        if(s) {
            if(s.solicitorName) {
                this.solicitorName = new ContactName(s.solicitorName)
            }
        }
    }
}
