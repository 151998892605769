import {DialogService} from '../shared/dialog/dialog.service';
import {FirstFocusMatterUtil} from './first-focus-matter-util';

declare var jQuery : any;

export function FocusFirstElementModalDecorator() {

    let componenetDialogService : any;

    return function(constructor) {
        const original = constructor.prototype.ngAfterViewInit;
        constructor.prototype.ngAfterViewInit = function() {

            for ( let prop in this ) {
                const property = this[ prop ];
                if ( property && (property instanceof DialogService) ) {
                    componenetDialogService = property;
                }
            }

            setTimeout( ()=> {
                FirstFocusMatterUtil.setFocusOnModalOnFirstAvailableElement();
            }, 0);

            jQuery(".modal-dialog").on('focus click', 'input', function(e) {
                if(componenetDialogService)
                {
                    componenetDialogService.setCurrentTarget(e.target);
                }
            });
            jQuery(".modal-dialog").on('keypress', 'button', function(e) {
                if(componenetDialogService)
                {
                    componenetDialogService.setCurrentTarget(e.target);

                }
            });
            jQuery(".modal-dialog").on('click', 'a', function(e) {
                if(componenetDialogService )
                {
                }
            });
            original && typeof original === 'function' && original.apply(this);
        };
    }
}
