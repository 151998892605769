import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';


class ZendeskPortalContext extends BSModalContext {
    requestType : string;
}
@Component({
    selector    : 'dp-zendesk-portal-modal',
    templateUrl : 'zendesk-portal.modal.component.html',
})
export class ZendeskPortalModal implements OnInit, ModalComponent<ZendeskPortalContext> {
    context : ZendeskPortalContext;
    zendeskUrl: SafeUrl;
    requestType: String;

    @ViewChild('zendeskFrame') zendeskFrame: ElementRef;

    constructor(public dialog : DialogRef<ZendeskPortalContext>,
                public sanitizer: DomSanitizer) {
        this.context = dialog.context;
    }

    ngOnInit() {
        //this.zendeskUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        // call backend to get the url based on this.context.requestType
        let url: string = 'https://doprocess.zendesk.com/hc/en-ca/requests/new?ticket_form_id=360000259472';
        this.zendeskUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    close() : void {
        this.zendeskFrame.nativeElement.src = '';
        this.dialog.close({action: 'Close'});
    }
}
