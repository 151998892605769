import {api} from '../common/api';

export const configApi = {

    apiVersion: "api/app",

    customerAccountConfiguration: `${api}/accounts/{accountId}/customerAccountConfiguration`,

    doceboConfiguration(userId: string): string {return  `${api}/integrations/university/users/${userId}/doceboSAMLApi`; },

    doceboSAMLSSOApi(userId: string, sessionId: string): string {return `${api}/integrations/university/users/${userId}/doceboSAMLSSOApi?SessionId=${sessionId}`; },

    unityDriveInstallParam:  `${api}/reference/unityDrive/installable`,

    chatAIApi: `${api}/chatbot/sendmessage`,

    clearchatAISession: `${api}/chatbot/clearsession`,

    addSentimentToAIResponse: `${api}/chatbot/addusersentiment`,

    getTermsConditionsContent: `${api}/chatbot/tnc`,

    getFAQLink: `${api}/chatbot/faq`,

    getTnCAcceptanceCheckApi: `${api}/chatbot/tncaccepted`,

    saveTnCAcceptanceApi:`${api}/chatbot/tncaccept`

};
