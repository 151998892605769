import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthorizationService} from '../shared-main/authorization/authorization-service';
import {AccessGuardHelper} from './access-guard-key';

@Injectable()
export class AccessGuard implements CanActivate {

    constructor(private authorizationService : AuthorizationService,
                private router : Router) { }

    canActivate(route : ActivatedRouteSnapshot, state : RouterStateSnapshot) : boolean {


        let authKeys = AccessGuardHelper.getAccessGuardKey(state.url);
        if(authKeys && authKeys.length) {
            let accessFlag = false;
            authKeys.forEach((authKey)=>{
                if(this.authorizationService.hasAccess(authKey)) {
                    accessFlag = true;
                }
            });
            if(accessFlag){
                return true;
            }
            else {
                this.router.navigate(['/main/home']);
                return false;
            }

        }
        else {
            this.router.navigate(['/main/home']);
            return false;
        }

    }
}
