import {Directive, Input} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
    selector: '[ngModel][numberOnly]',
    host: {
        '(input)': 'onInput($event)'
    }
})

export class NumberOnly {
    @Input('max') max : number;
    @Input('min') min: number;

    constructor(private model: NgModel) {
    }

    onInput() {
        if (this.model.value) {
        	let val = this.model.value.replace(/\D/g, '');
        	if (this.model.value != val) {
            	this.model.reset(val);
            	this.model.valueAccessor.writeValue(val);
        	}
            if(this.max &&this.model.value > this.max) {
                this.model.valueAccessor.writeValue('');
            }
            if(this.min && this.model.value < this.min) {
                this.model.valueAccessor.writeValue('');
            }
        }
    }
}
