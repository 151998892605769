import {ContactInfo} from '../contact-info';
import {Matter} from '../matter';
import {ContactInfoType} from '../contact-info-type';

export class MatterFireInsuranceUtil {

    static copyFireInsuranceInfoData(matter: Matter, targetMatter: Matter): void {
        if (matter.fireInsuranceContactInfo) {
            if (targetMatter.fireInsuranceContactInfo) {
                (<any>targetMatter.contactInfo).remove(targetMatter.fireInsuranceContactInfo);
            }

            let matterFireInsuranceContactInfo: ContactInfo = new ContactInfo(matter.fireInsuranceContactInfo);
            matterFireInsuranceContactInfo.clearIds();
            if (!Array.isArray(targetMatter.contactInfo)) {
                targetMatter.contactInfo = [];
            }
            if (!targetMatter.fireInsuranceContactInfo) {
                targetMatter.contactInfo.push(matterFireInsuranceContactInfo);
            }
        }
    }

    static initializedFireInsuranceContactInfo(matter: Matter): void {
        if (!Array.isArray(matter.contactInfo)) {
            matter.contactInfo = [];
        }
        if (!matter.fireInsuranceContactInfo) {
            matter.contactInfo.push(MatterFireInsuranceUtil.createFireInsurerContactInfo());
        }
    }

    static createFireInsurerContactInfo(): ContactInfo {
        const contactInfo: ContactInfo = new ContactInfo();
        contactInfo.contactInfoType = ContactInfoType.FIRE_INSURER;
        contactInfo.instanceType = 'fireInsurer';
        contactInfo.coverage = 0;
        return contactInfo;
    }
}
