//This is a wrapper class created on top of Matter participant. It handles the logic of displaying participant in UI
//and for the data delegate to matter participant.
import {MatterParticipant} from './matter-participant';
import {Contact} from './contact';
import {
    FlaStatementType,
    OUT_OF_SYNC_POSTFIX_MSG,
    OUT_OF_SYNC_PRE_MSG,
    OUT_OF_SYNC_UNDERLINE_MSG,
    SnapshotBurgerMenuActions
} from '../../shared-main/constants';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import * as _ from 'lodash';
import {ApplicableProvisionOptionsTypes, FamilyLawAct, MaritalStatusTypes} from './fla-data';
import {SpouseType, SpouseTypes} from '../suggested-spouse-modal/suggested-spouse';
import {DpBooleanValueTypes} from './dp-boolean';
import {Cirf} from './cirf/cirf';
import {Matter} from './matter';
import {MatterParticipantRoleTypes} from './matter-participant-role-types';

export class LogDiffInfo {
    name;
    source;
    snapshot;
}
export class MatterParticipantWrapper extends BaseEntity{
    //Following set of fields are used for UI logic
    dataModel : any; //data model refers to the contact selected in auto-search
    editMode : boolean;
    isStale : boolean; //isStale flag is true if contact is not reset and data is different
    isLockedElsewhere : boolean;
    isClearFlagWithoutUpdatingMatter : boolean; //it is true if contact has been reset without updating matter. Contact cannot be edited from matter in this
    // case.
    canBeUpdatedFromSourceContact : boolean;
    lastUpdatedOn : string;
    //index of the tab which should be shown as selected
    selectedDetailsTabIndex : number;
    selectedDetailsTabText : string;
    selectedTab : string;
    spouseNameOptions : any[];
    expanded : boolean = false;
    createdOrSelected : string = null;
    showLabel : boolean;
    //Flag to indicate the wrapper is not to be used anymore, it is used when user leaves the tab and we want to stop any executions which would have been
    // initiated before tab was left but didn't get complete.
    obsolete: boolean;
    //While calculating participant state (like stale flag) shutter should not be allowed to open, otherwise it can get lock even if contact is stale.
    isLoadingParticipantState: boolean;

    //ToDo: we can remove lockedSourceContact from wrapper and instead of it use sourcContact from participant directly. Though that would need some
    // code refactor.
    // refreshed after lock/unlock call
    lockedSourceContact : Contact;
    sourceContactLegfirmId : number;

    //ToDo: We don't need all this flags in wrapper. Instead added sourcContact in participant for getting this information
    sourcePrivateFlag : boolean;
    sourceProxyForGlobal : boolean;
    _sourceProxyEdited : boolean;
    isPrivateLenderMortgagee : boolean = false;

    isCirfMenuOptions: boolean = false; //display Cirf related burger menu options
    participantCirf: Cirf; //reference to participant Cirf
    matterCirf: Cirf; //referenc to Matter Cirf
    matter: Matter; //used for Cirf menu related logic
    signerOrder : number;

    logDiffInfo : LogDiffInfo;

    private _matterParticipant : MatterParticipant; //points to backing data model

    //Following set of properties belongs to matter participant
    private _primary : boolean;
    private _matterParticipantId : number;
    private _matterParticipantPriority : number;
    private _id : number; //id of the  contact

    constructor(matterParticipantWrapper? : MatterParticipantWrapper) {
        super(matterParticipantWrapper);
        if(matterParticipantWrapper) {
            this.spouseNameOptions = [];
            if(Array.isArray(matterParticipantWrapper.spouseNameOptions)) {
                this.spouseNameOptions = _.cloneDeep(matterParticipantWrapper.spouseNameOptions);
            }
            if(matterParticipantWrapper.matterParticipant){
                this.matterParticipant = matterParticipantWrapper.matterParticipant;
            }
        }
    }
    //This is a common function for creating burger menu options for a matter participant
    getBurgerMenuItemsForMatterParticipant(selectedMatterParticipantIndex?: number, matterParticipantsLength?: number, addSpouseItem? : boolean, isMovingDisabled = false): string[] {
        let menuItems: string[] = [];

        if (!isMovingDisabled && (selectedMatterParticipantIndex || selectedMatterParticipantIndex == 0)) {
            if (selectedMatterParticipantIndex > 0) {
                menuItems.push(SnapshotBurgerMenuActions.MOVE_UP);
            }

            if (matterParticipantsLength && (selectedMatterParticipantIndex < matterParticipantsLength - 1)) {
                menuItems.push(SnapshotBurgerMenuActions.MOVE_DOWN);
            }
        }
        menuItems.push(SnapshotBurgerMenuActions.DELETE);

        if(addSpouseItem) {
            menuItems.push(SnapshotBurgerMenuActions.ADD_SPOUSE);
        }
        menuItems.push(...this.getStateBasedMenuItems());
        if (!this.isWillMatter()) {
            menuItems.push(...this.getCirfBasedMenuItems());
        }

        return menuItems;
    }

    //these options can be enabled or disabled based on participant state
    getStateBasedMenuItems(): string[] {
        let menuItems:string[] = [];
        if(this.matterParticipant && this.matterParticipant.sourceContact) {
            if(this.matterParticipant.sourceContact.isProxyCopyOfGlobal) {
                menuItems.push(SnapshotBurgerMenuActions.REVERT_TO_GLOBAL);
            }
            if(this.isStale) {
                menuItems.push(SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
            }
        }
        return menuItems;
    }

    getCirfBasedMenuItems(): string[] {
        let menuItems:string[] = [];

        if (this.isCirfMenuOptions) {
            if (this.participantCirf) {
                if (this.participantCirf.status === 'SUBMITTED') {
                    menuItems.push(SnapshotBurgerMenuActions.REVIEW_PROCESS_INTAKE);
                    menuItems.push(SnapshotBurgerMenuActions.CANCEL_REQUEST);
                }
                if (this.participantCirf.status === 'OUTSTANDING' || this.participantCirf.status === 'INPROGRESS') {
                    menuItems.push(SnapshotBurgerMenuActions.CANCEL_REQUEST);
                    // no need to test for a ProjectSale here because a welcome package precedent wouldn't be present on such matter type
                    if (this.matterParticipant.welcomePackagePrecedentId){
                        menuItems.push(SnapshotBurgerMenuActions.RESEND_CIRF_WELCOME_EMAIL);
                    } else {
                        menuItems.push(SnapshotBurgerMenuActions.RESEND_CIRF_EMAIL);
                    }
                }
                if (this.participantCirf.status === 'CANCELLED' || this.participantCirf.status === 'EXPIRED') {
                    menuItems.push(SnapshotBurgerMenuActions.RE_OPEN_REQUEST);
                }
                if (this.participantCirf.status === 'PROCESSED') {
                    menuItems.push(SnapshotBurgerMenuActions.REVIEW_PROCESS_INTAKE);
                    menuItems.push(SnapshotBurgerMenuActions.CIRF_INFO);
                }
            } else if (this.matter && this.matter.mainClients.length > 1 && !this.isMatterCirfAlreadySentToParticipant() ) {
                if (this.matter.isProjectSale || (this.matterParticipant.welcomePackagePrecedentId && this.matterParticipant.welcomePackageStatus === 'SENT_WITHOUT_CIRF')) {
                    menuItems.push(SnapshotBurgerMenuActions.SEND_PARTICIPANT_CIRF);
                    if (this.matterParticipant.welcomePackagePrecedentId) {
                        menuItems.push(SnapshotBurgerMenuActions.RESEND_CIRF_WELCOME_EMAIL);
                    }
                } else {
                    menuItems.push(SnapshotBurgerMenuActions.SEND_PARTICIPANT_WELCOME_PACKAGE);
                }
            }
        }

        return menuItems;
    }

    isMatterCirfAlreadySentToParticipant(): boolean {
        return this.matterCirf && !this.matterCirf.isCancelled() && this.matterCirf.clientEmail
            && this.matterParticipant && this.matterParticipant.contact && this.matterParticipant.contact.firstEmail
            && this.matterCirf.clientEmail.toLocaleLowerCase() == this.matterParticipant.contact.firstEmail.toLocaleLowerCase();
    }

    //Disabled items are same as regular items
    getDisabledBurgerMenuItemsForMatterParticipant():string[] {
        return this.matterParticipant && this.matterParticipant.sourceContact && this.isLockedElsewhere ? this.getStateBasedMenuItems() : [];
    }


    //This method used to show global icon if contact is global for the customer account
    isGlobalIconVisible(): boolean {
        return !!(this.matterParticipant && this.matterParticipant.sourceContact && this.matterParticipant.sourceContact.isOwnedBySystemAccount);
    }

    //This method used to show the address book icon if global contact is edited as private copy
    isAddressBookIconVisible() : boolean  {
        return !!(this.matterParticipant && this.matterParticipant.sourceContact && this.matterParticipant.sourceContact.isProxyCopyOfGlobal);
    }

    //In some cases unlock is done implicitly in API call so no need to call tabservice unLock method but still need to reset wrapper flags.
    resetOnUnlock() {
        this.lockedSourceContact = null;
        this.isLockedElsewhere = false;
        this.matterParticipant.sourceContactLockAcquired = false;
        this.matterParticipant.contact.isDirty = false;
    }

    get lockedByUserFullName(): string {
        return this.lockedSourceContact && this.lockedSourceContact.lockedByUser && this.lockedSourceContact.lockedByUser.fullName;
    }

    //ToDo: Why this method is added in wrapper? It's already exists in contact class why not referred from there.
    get isOwnedBySystemAccount() : boolean {
        return this.isGlobal || this.isProxySameAsGlobal;
    }

    //ToDo: Why this method is added in wrapper? It's already exists in contact class why not referred from there.
    get isGlobal() : boolean {
        //As we want to return TRUE only if explicit value is false and in all other cases (like undefined, null or true) should return FALSE, therefore
        // comparing value with false instead of negate operator.
        return this.sourcePrivateFlag == false;
    }

    //ToDo: Why this method is added in wrapper? It's already exists in contact class why not referred from there.
    get isProxySameAsGlobal() : boolean {
        // console.log('isProxySameAsGlobal :: this.sourceProxyForGlobal : ', this.sourceProxyForGlobal, 'this.sourceProxyEdited', this.sourceProxyEdited);
        return this.sourceProxyForGlobal && !this.sourceProxyEdited;
    }

    //ToDo: Why this method is added in wrapper? It's already exists in contact class why not referred from there.
    //It returns true if contact is proxy of global but has some local changes made by customer account.
    get isProxyCopyOfGlobal() : boolean {
        // console.log('isProxyCopyOfGlobal :: this.sourceProxyForGlobal : ', this.sourceProxyForGlobal, 'this.sourceProxyEdited', this.sourceProxyEdited);

        return this.sourceProxyForGlobal && this.sourceProxyEdited;
    }

    get matterParticipant() : MatterParticipant {
        return this._matterParticipant;
    }

    set matterParticipant(value : MatterParticipant) {
        this._matterParticipant = value;
    }

    get primary() : boolean {
        return this._matterParticipant ? this._matterParticipant.primary : true;
    }


    set primary(value : boolean) {
        this._matterParticipant.primary = value;
    }

    get myClient() : boolean {
        return this._matterParticipant ? this._matterParticipant.myClient : false;
    }

    set myClient(value : boolean) {
        this._matterParticipant.myClient = value;
    }

    get matterParticipantId() : number {
        return this._matterParticipant && this._matterParticipant.matterParticipantId;
    }

    get matterParticipantPriority() : number {
        return this._matterParticipant.matterParticipantPriority;
    }

    set matterParticipantPriority(value : number) {
        this._matterParticipant.matterParticipantPriority = value;
    }

    get contactId() : number {
        return this._matterParticipant && this._matterParticipant.contact && this._matterParticipant.contact.id;
    }

    get sourceContactId() : number {
        return this._matterParticipant && this._matterParticipant.contact && this._matterParticipant.contact.sourceContactId;
    }

    get sourceContactIsLocked() : boolean {
        return !!(this.lockedSourceContact && this.lockedSourceContact.lockedByUser);
    }

    get outOfSyncPreMsg() : string {
        return OUT_OF_SYNC_PRE_MSG;
    }

    get outOfSyncUnderlineMsg() : string {
        return OUT_OF_SYNC_UNDERLINE_MSG;
    }

    get outOfSyncPostFixMsg() : string {
        return OUT_OF_SYNC_POSTFIX_MSG;
    }

    setSourceContactFlagsAsPrivate(){
        this.sourcePrivateFlag = true;
        this.sourceProxyForGlobal = false;
        this.sourceProxyEdited = false;
    }


    menuItemsForRevert(menuItems : any) {
        if(this.isProxyCopyOfGlobal) {
            menuItems.push(SnapshotBurgerMenuActions.REVERT_TO_GLOBAL);
        }
    }

    updateSourceContactFlagsForSnapshot(sourceContact : Contact) {
        if(sourceContact) {
            this.sourcePrivateFlag = sourceContact.privateFlag;
            if(this.matterParticipant){
                this.matterParticipant.sourceContact = sourceContact;
            }
            //When switching pages, it retire proxy edit status
            if(this.matterParticipant && this.matterParticipant.contact
                && this.matterParticipant.sourceContactLockAcquired
                && this.matterParticipant.contact.isDirty && sourceContact.proxyForGlobal === true) {
                this.sourceProxyEdited = true;
            } else {
                this.sourceProxyEdited = sourceContact.proxyEdited;
            }
            this.sourceProxyForGlobal = sourceContact.proxyForGlobal;
        }
    }

    retrieveStaleStatus (source : Contact) : boolean{
        let isStale : boolean = false;

        if(this.matterParticipant && this.matterParticipant.contact) {
            switch(this.matterParticipant.matterParticipantRole) {
                case 'VENDOR' :
                case 'PURCHASER' :
                case 'DEVELOPER' :
                    isStale = this.matterParticipant.contact.isStale(source)
                    // Please do not delete the following comment code. It can be used debug the equal issue
                    // if(isStale) {
                    //     console.log('contact.equal(): this:', JSON.stringify(this.matterParticipant.contact, this.matterParticipant.contact.replacer));
                    //     console.log('contact.equal(): other:', JSON.stringify(source, source.replacer));
                    // }
                    break;

                case 'DEVELOPER_SOLICITOR' :
                case 'OTHERPARTY_SOLICITOR' :
                case 'MORTGAGE_SOLICITOR' :
                    isStale = this.matterParticipant.contact.isStaleSolicitor(source)
                    break;

                case 'MORTGAGE_LEGAL_FIRM' :
                case 'OTHERPARTY_LAW_FIRM' :
                case 'DEVELOPER_LAW_FIRM' :
                    isStale = this.matterParticipant.contact.isStaleLawFirm(source)
                    break;

                case 'PRIVATE_LENDER' :
                    isStale =  this.matterParticipant.contact.isStalePrivateLender(source);
                    break;
            }
        }

        this.isStale = isStale;
        return isStale;
    }

    refreshClearFlagStatus(source : Contact) : boolean {
        let isClear : boolean = false;
        if(this.matterParticipant && this.matterParticipant.contact) {
            switch(this.matterParticipant.matterParticipantRole) {
                case 'VENDOR' :
                case 'PURCHASER' :
                case 'DEVELOPER' :
                    isClear = this.matterParticipant.contact.isClearFlagWithoutUpdatingMatter(source)
                    break;

                case 'DEVELOPER_SOLICITOR' :
                case 'OTHERPARTY_SOLICITOR' :
                case 'MORTGAGE_SOLICITOR' :
                    isClear = this.matterParticipant.contact.isSolicitorClearFlagWithoutUpdatingMatter(source)
                    break;

                case 'DEVELOPER_SOLICITOR' :
                case 'MORTGAGE_LEGAL_FIRM' :
                case 'OTHERPARTY_LAW_FIRM' :
                    isClear = this.matterParticipant.contact.isLawFirmClearFlagWithoutUpdatingMatter(source);
                    break;

                case 'PRIVATE_LENDER' :
                    isClear =  this.matterParticipant.contact.isPrivateLenderClearFlagWithoutUpdatingMatter(source)
                    break;

            }
        }
        this.isClearFlagWithoutUpdatingMatter = isClear;
        return isClear;
    }

    isTabSelected(tabValue : string) : boolean{
        return tabValue ? tabValue === this.selectedTab : false;
    }


    //In following scenarios contact shutter should be shown readonly:
    //- Lock on source contact not acquired
    //- contact is stale (even if contact is available for locking but if data is stale then contact should be shown readonly)
    //- contact is global (Contact needs to be locked for reverting or synchronizing, however if it is global contact then still it would be shown readonly
    // despite it got the lock for those actions.)
    //- contact stale flag is cleared but since data is still older then it would show readonly.
    //This method should be used by all components for showing snapshot data readonly or editable
    //I can Not understand this.matterParticipant.sourceContactLockAcquired
    // No matter isClearFlagWithoutUpdatingMatter, isStale or isOwnedBySystemAccount value, the return value is always !this.matterParticipant.sourceContactLockAcquired
    get isParticipantDataReadOnly(): boolean {
        // If there is not sourceContactId, it means it is snapshot only
        if(!this.sourceContactId){
            return false;
        }
        if(this.matterParticipant.sourceContactLockAcquired) {
            return false;
        } else {
            return this.isClearFlagWithoutUpdatingMatter
                || this.isStale
                || !this.matterParticipant.sourceContactLockAcquired
                || this.matterParticipant.sourceContact && this.matterParticipant.sourceContact.isOwnedBySystemAccount;

        }
    }

    //In following scenarios contact shutter should be shown readonly:
    //- contact is stale (even if contact is available for locking but if data is stale then contact should be shown readonly)
    //- contact is global (Contact needs to be locked for reverting or synchronizing, however if it is global contact then still it would be shown readonly
    // despite it got the lock for those actions.)
    //- contact stale flag is cleared but since data is still older then it would show readonly.
    //This method should be used by all components for showing snapshot data readonly or editable
    get isAgentBrokerParticipantDataReadOnly(): boolean {
        if(this.matterParticipant.sourceContactLockAcquired) {
            return false;
        } else {
            return this.isClearFlagWithoutUpdatingMatter
                || this.isStale
                || this.matterParticipant.sourceContact && this.matterParticipant.sourceContact.isOwnedBySystemAccount;

        }
    }

    setSpouseGenderAndContactCategory(contact: Contact, spouseContact: Contact){
        if(contact && spouseContact){
            const originalGender : string = contact.gender;
            spouseContact.contactCategory = 'CLIENT';
            switch(originalGender) {
                case 'MALE':
                    spouseContact.gender = 'FEMALE';
                    break;
                case 'FEMALE':
                    spouseContact.gender = 'MALE';
                    break;
                case 'MALEPOA':
                    spouseContact.gender = 'FEMALEPOA';
                    break;
                case 'FEMALEPOA':
                    spouseContact.gender = 'MALEPOA';
                    break;
                default:
                    spouseContact.gender = originalGender;
                    break;
            }
        }
    }

    setSpouseDefaultValues(spouseParticipant : MatterParticipant) {
        if(this.matterParticipant && spouseParticipant) {
            spouseParticipant.setSpouse(this.matterParticipant.matterParticipantId);
            this.matterParticipant.setSpouse(spouseParticipant.matterParticipantId);
            if(this.matterParticipant.getFamilyLawAct('MATTER_PARTICIPANT_SPOUSE')) {
                spouseParticipant.addFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
            }
            if(this.matterParticipant.getFamilyLawAct('OTHER')) {
                spouseParticipant.addFamilyLawAct('OTHER');
                spouseParticipant.otherText = this.matterParticipant.otherText;
            }
            if(this.matterParticipant.contact) {
                this.setSpouseGenderAndContactCategory(this.matterParticipant.contact, spouseParticipant.contact);
            }
        }
    }

    setSpouseIsAParty(familyLawAct: FamilyLawAct, spouseId: number) {
        familyLawAct.maritalStatus = MaritalStatusTypes.MARRIED;
        familyLawAct.propertyOccupiedAsMaritalHome = DpBooleanValueTypes.YES;
        familyLawAct.applicableProvision = ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY;
        familyLawAct.spouseMatterParticipantId = spouseId;
    }

    setSpouseDefaultValuesForNB(spouseParticipant : MatterParticipant) {
        if(this.matterParticipant && spouseParticipant) {
            if(!Array.isArray(spouseParticipant.familyLawActs) || spouseParticipant.familyLawActs.length == 0 ){
                spouseParticipant.familyLawActs = [];
                spouseParticipant.initFlaForNB();
            }

            //Update new spouse relation
            spouseParticipant.familyLawActs[0].familyLawActStatementType = FlaStatementType.MARITAL_STATUS_NB;
            this.setSpouseIsAParty(spouseParticipant.familyLawActs[0], this.matterParticipant.matterParticipantId);
            //Update selected matterParticipant
            this.setSpouseIsAParty(this.matterParticipant.familyLawActs[0], spouseParticipant.matterParticipantId);

            if(this.matterParticipant.contact) {
                this.setSpouseGenderAndContactCategory(this.matterParticipant.contact, spouseParticipant.contact);
            }
        }
    }

    private addSuggestedSpouseAsFlaConsentedSpouseForNB(spouseType?: SpouseType) {
        this.matterParticipant.familyLawActs = [];
        this.matterParticipant.familyLawActs.push(new FamilyLawAct());
        this.matterParticipant.familyLawActs[0].familyLawActStatementType = FlaStatementType.MARITAL_STATUS_NB;
        if(spouseType == SpouseTypes.As_A_CONSENTING) {
            this.matterParticipant.familyLawActs[0].maritalStatus = MaritalStatusTypes.MARRIED;
            this.matterParticipant.familyLawActs[0].applicableProvision = ApplicableProvisionOptionsTypes.SPOUSE_IS_CONSENTING;
        } else if(spouseType == SpouseTypes.As_A_FORMER_CONSENTING) {
            this.matterParticipant.familyLawActs[0].maritalStatus = MaritalStatusTypes.NOT_MARRIED;
            this.matterParticipant.familyLawActs[0].applicableProvision = ApplicableProvisionOptionsTypes.SPOUSE_IS_CONSENTING;
        }
        this.matterParticipant.familyLawActs[0].propertyOccupiedAsMaritalHome = DpBooleanValueTypes.YES;
    }

    //Move this methord from component to here
    addSuggestedSpouseAsFlaConsentedSpouse(spouseContactSource : Contact, familyLawActStatementType : string, matter: Matter, spouseType?: SpouseType){
        switch (familyLawActStatementType) {
            case FlaStatementType.MARITAL_STATUS_NB:
                this.addSuggestedSpouseAsFlaConsentedSpouseForNB(spouseType);
                break;
            case 'CONSENTED_SPOUSE':
                this.matterParticipant.consentedSpouse = true;
                break;
            case 'AUTHORIZED_BY_COURT':
                this.matterParticipant.authorizedByCourt = true;
                break;
            case 'INTERSPOUSAL_AGREEMENT':
                this.matterParticipant.interspousalAgreement = true;
                break;
            case FlaStatementType.NOT_MATRIMONIAL_HOME:
                this.matterParticipant.notMatrimonialHome = true;
                break;
            case FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_SEPARATION_AGREEMENT:
                this.matterParticipant.spouseBySeparationAgreement = true;
                break;
            case FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER:
                this.matterParticipant.spouseByVirtueOfOrder = true;
                break;
            case FlaStatementType.DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME:
                this.matterParticipant.designatedAnotherPropertyAsMatrimonialHome = true;
                break;
            default:
                return;

        }
        // let spouseContactSnapshot = new Contact();
        // //Clean all id by createNewContactClone
        // spouseContactSnapshot.createNewContactClone(spouseContactSource);
        // spouseContactSnapshot.loadConsentedSpouseSettings();
        // this.matterParticipant.getConsentedSpouseFamilyLawAct().consentedSpouse = spouseContactSnapshot;
        matter.addMatterParticipant(spouseContactSource, true, MatterParticipantRoleTypes.CONSENTING_SPOUSE, null, this.matterParticipant);
    }

    static clearSelectedClientsIfNotSelected(selectedClientWrappers : MatterParticipantWrapper[]){
        if(Array.isArray(selectedClientWrappers)) {
            let selectedClientPurchaserIndex : number = selectedClientWrappers.findIndex(selectedClient => !selectedClient.dataModel && !selectedClient.matterParticipant);
            if(selectedClientPurchaserIndex > -1) {
                selectedClientWrappers.splice(selectedClientPurchaserIndex, 1);
            }
        }
    }

    get sourceProxyEdited(): boolean{
        return this._sourceProxyEdited;
    }

    set sourceProxyEdited(sourceProxyEdited){
        this._sourceProxyEdited = sourceProxyEdited;
        if(this.matterParticipant && this.matterParticipant.sourceContact){
            this.matterParticipant.sourceContact.proxyEdited = sourceProxyEdited;
        }
    }

    hasParticipantWithoutSourceContact(): boolean {
        return this.matterParticipant ? this.matterParticipant.hasSnapshotWithoutSourceContact() : false;
    }

    getNotesTab(){
        return this.matterParticipant && this.matterParticipant.contact && this.matterParticipant.contact.getNotesTitle();
    }

    setMatterAndCirfFields(matter: Matter, cirf: Cirf) : void {
        this.matter = matter;
        this.matterCirf = cirf;
        this.isCirfMenuOptions = matter && !matter.isOpportunityMatter();
    }

    showDifference() {
        if(this.matterParticipant && this.matterParticipant.contact && this.matterParticipant.sourceContact){
            this.logDiffInfo = new LogDiffInfo();
            this.matterParticipant.contact.equals(this.matterParticipant.sourceContact, this.matterParticipant.contact, 'Contact.', this.logDiffInfo );
        }
    }

    isWillMatter(){
        return this.matter && this.matter.isWillMatter();
    }
    static createWrapperFromMatterParticipant(matterParticipant: MatterParticipant, defaultTab): MatterParticipantWrapper {
        let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
        matterParticipantWrapper.matterParticipant = matterParticipant;
        matterParticipantWrapper.dataModel = {fullName: matterParticipant.contact.genericFullName};
        matterParticipantWrapper.editMode = false;
        matterParticipantWrapper.selectedTab = defaultTab;
        return matterParticipantWrapper;
    }
}

