import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {Component, OnInit} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';

export class CustomerSupportContext extends BSModalContext {

}

@Component({
    selector    : 'dp-customer-support',
    templateUrl : 'customer-support.component.html',
    styleUrls: ['./customer-support.component.scss']

})

export class CustomerSupportComponent implements OnInit, ModalComponent<CustomerSupportContext> {

    context: CustomerSupportContext;

    constructor(public dialog: DialogRef<CustomerSupportContext>) { }

    ngOnInit(): void {

    }

    close() {
        this.dialog.close();
    }

    submit() {
        let url = 'https://dyedurham.ca/support/unity/';
        this.dialog.close();
        window.open(url, "_blank");
    }

}