import {ErrorService} from '../../shared/error-handling/error-service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Component} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';

class CustomerBillingPortalModalContext extends BSModalContext {
    url : string;
}

@Component({
               selector     : 'dp-customer-billing-portal-modal-content',
               templateUrl  : 'customer-billing-portal.modal.component.html',
               providers : [ErrorService],
                styleUrls      : [
                    './customer-billing-portal.scss'
                ],
           })

@AutoUnsubscribe()
export class CustomerBillingPortalModal implements   ModalComponent<CustomerBillingPortalModalContext> {
    context : CustomerBillingPortalModalContext;
    customerBillingPortalUrl: SafeUrl;

    constructor(public dialog : DialogRef<CustomerBillingPortalModalContext>,
                public sanitizer: DomSanitizer
                ) {
        this.context = dialog.context;

    }

    ngOnInit() {
        this.customerBillingPortalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.url);
    }

    cancel(): void {
        this.dialog.close();
    }

    ngOnDestroy() {}
}
