import {Component, OnInit} from '@angular/core';
import {DialogRef} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {TprTemplatesService} from '../tpr-templates.service';
import {DocumentTemplate} from '../../../matters/document-production/document-template';

export class EditTprTemplateDetailsContext extends BSModalContext {
    documentTemplate: DocumentTemplate;
    accountId : number
}

@Component({
               selector    : 'dp-edit-tpr-template-details-modal-content',
               templateUrl : 'edit-tpr-template-details.modal.html',
           })

export class EditTprTemplateDetailsComponent implements OnInit {
    context : EditTprTemplateDetailsContext;
    documentTemplate: DocumentTemplate;

    constructor(public dialog : DialogRef<EditTprTemplateDetailsContext>,
                private exportTemplatesService : TprTemplatesService
    ) {
        this.context = dialog.context;

    }

    ngOnInit() {
        this.documentTemplate = new DocumentTemplate(this.context.documentTemplate);
    }

    save() {

        this.exportTemplatesService.editReportTemplateFile(this.context.accountId, this.documentTemplate)
            .subscribe(data => {
                this.context.documentTemplate.description = this.documentTemplate.description;
                this.dialog.close();
            });

    }

    close() {
        this.dialog.close();
    }


}
