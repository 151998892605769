import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {StatusBarService} from './status-bar.service';

declare var jQuery : any;
@Directive({
  selector: '[statusBarHelp]'
})
export class StatusBarHelpDirective {

  constructor(private el: ElementRef, private statusBarService: StatusBarService) { }

  @Input() fieldKey: string
  @Input() dynamicHelpCallBackFunction : any;

  @HostListener('focusin', ['$event.target']) // using this event instead of 'focus' because of Partial Date fields
  onFocus(target) {
   // console.log("statusBarHelp directive | fieldKey = ", this.fieldKey);

      this.bringFieldIntoVisibleArea(target);
      if (this.dynamicHelpCallBackFunction) {
          let message : string = this.dynamicHelpCallBackFunction;
          this.statusBarService.dynamicHelpFocus(message);
      }else{
          this.statusBarService.onFocus(this.fieldKey);
      }

  }

  @HostListener('focusout', ['$event.target'])
  onFocusout(target) {
    this.statusBarService.onFocusOut(this.fieldKey)
  }

  bringFieldIntoVisibleArea(target) {

      var pos = jQuery(target).offset(),
          wX = jQuery(window).scrollLeft(), wY = jQuery(window).scrollTop(),
          wH = (jQuery(window).height() - 30), wW = jQuery(window).width(),
          oH = jQuery(target).outerHeight(), oW = jQuery(target).outerWidth();

      // check the edges
      if(pos.left >= wX && pos.top >= wY &&
          oW + pos.left <= wX + wW && oH + pos.top <= wY + wH) {
          console.log('Div #' + jQuery(target).attr('id') + ' is fully visible');
      } else {
          if(((pos.left <= wX && pos.left + oW > wX) ||
              (pos.left >= wX && pos.left <= wX + wW)) &&
              ((pos.top <= wY && pos.top + oH > wY) ||
              (pos.top >= wY && pos.top <= wY + wH))) {
             //console.log('Div #' + jQuery(target).attr('id') + ' is partially visible');

              var y = jQuery(window).scrollTop();  //your current y position on the page
              jQuery(window).scrollTop(y + 150);
          } else {
              //console.log('Div #' + jQuery(target).attr('id') + ' is not visible');
          }
      }
  }
}
