import {NgModule} from '@angular/core';
import {NoContent} from './no-content';


@NgModule({
    exports: [
         NoContent
    ],
    declarations: [
        NoContent
    ],
})
export class NoContentModule { }
