import {accountApi} from '../shared/account-api';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../../../core';
import {accountResponseKey} from '../shared/account-response-key';
import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {Contact} from '../../../matters/shared/contact';

@Injectable()
export class LegalFirmService {

    constructor(private http: HttpClient, private logger: Logger) {
    }

    addLegalFirm(object) {
        return this.http.post(accountApi.legalFirm, object)
            .map((res) => {
                return res[accountResponseKey.legalFirm];
            });
    }

    getLegalFirms(token: string): Observable<any[]> {
        let url = `${accountApi.legalFirm}?filter=legalFirmName_EQ_${token}*&sort=legalFirmName|ASC`;
        return this.http.get(url)
            .map((res) => {

                let data: any[] = res[accountResponseKey.legalFirms];

                if(data.length > 15) {
                    data = data.slice(0, 15);
                } else if(data.length === 0) {
                    this.noRecordFound(data);
                }

                let legalFirms : Contact[] = [];
                data.forEach(val => {
                    legalFirms.push(new Contact(val));
                });

                return legalFirms;
            });
    }

    // No record found item for LawClerk and solicitor
    noRecordFound(data) {
        let person : Contact = new Contact();
        person.legalFirmName = 'No records available';
        data.push(person);
        return data;
    }
}

