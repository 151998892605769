import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ProvinceCode} from '../accounts/shared/account-province';

export const JurisdictionDepartmentsMaxLength : number = 65;
// export const JurisdictionDepartmentsReadOnlyLength : number = 5;

export class JurisdictionDepartment extends BaseEntity {
    constructor(jurisdictionDepartment? : JurisdictionDepartment) {
        super(jurisdictionDepartment);
        if(jurisdictionDepartment) {
            for(let prop in jurisdictionDepartment) {
                if(jurisdictionDepartment.hasOwnProperty(prop)) {
                    this[prop] = jurisdictionDepartment[prop];
                }
            }
        }
    }


    id : number;
    customerAccountId : number;
    departmentName : string;
    departmentKey : string;
    departmentPriority : number;
    writeTo : string;
    private : boolean;
    // isReadOnly : boolean;
    provinceCode: ProvinceCode;

}
