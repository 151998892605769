import {Component, ViewChild} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {UserStateService} from '../shared-main/user-state/user-state.service';
import {AUTH_OPERATION} from '../shared-main/authorization/authorization-keys';
import {ApplicationError} from '../core';
import {StewartTitleService} from '../shared-main/stewart-title/stewart-title-service';
import {StewartTitleUser} from '../shared-main/stewart-title/stewart-title-user';
import {StewartTitleAuthenticationService} from '../shared-main/stewart-title/stewart-title-authentication-service';
import {LockScreenService} from '../core/lock-screen.service';
import {DialogService} from '../shared/dialog/dialog.service';
import {CHICAGO_TITLE_APPLICABLE_PROVINCES} from '../shared-main/constants';
import {AuthorizationService} from '../shared-main/authorization/authorization-service';
import {titlePlusLinks} from '../shared-main/title-plus/title-plus-constants';
import {AppConfig} from '../shared-main/app-configuration';
import {SESSION_STORAGE_KEYS} from '../shared';
import {StatementConfig} from '../admin/shared/statement-config';
import {StatementConfigService} from '../admin/shared/statement-config.service';
import {ProvinceCode} from '../admin/accounts/shared/account-province';
import {Account} from '../admin/accounts/shared/account';
import {AccountService} from '../admin/accounts/account.service';
import {FCTService} from '../shared-main/fct/fct-service';
import {AccountingExportModalComponent} from './accounting-export.modal.component';
import {DPError} from '../shared/error-handling/dp-error';
import {ErrorService} from '../shared/error-handling/error-service';
import {ModalErrorComponent} from '../shared/error-handling/modal-error/modal-error.component';
import {chicagoTitleLinks} from '../shared-main/chicago-title/chicago-title-constants';

class OtherIntegrationServicesModalComponentContext extends BSModalContext {
}

@Component({
    selector: 'dp-other-integration-services-modal-content',
    templateUrl: './other-integration-services.modal.component.html',
    providers: []
})
export class OtherIntegrationServicesModalComponent implements ModalComponent<OtherIntegrationServicesModalComponentContext>{
    accountId: string = null;
    authKeys: any;
    context: OtherIntegrationServicesModalComponentContext;
    userAccessibleProvinces: string[] = [];
    stewartTitleCredentials: StewartTitleUser;
    stewartTitleTransactionUrl: string = '';
    titlePlusPremiumCalculator:  string = titlePlusLinks.premiumCalculator;
    isStatementConfigAvailable: boolean = false;
    account : Account;
    showDownloadAccExpFileOpt: boolean = true;
    localChicagoTitleLinks = chicagoTitleLinks;

    constructor(
        public dialog : DialogRef<OtherIntegrationServicesModalComponentContext>,
        public userStateService : UserStateService,
        public stewartTitleService: StewartTitleService,
        public stewartTitleAuthenticationService: StewartTitleAuthenticationService,
        public lockScreenService : LockScreenService,
        public dialogService : DialogService,
        public authorizationService : AuthorizationService,
        public appConfig : AppConfig,
        public soaConfigService : StatementConfigService,
        public accountService : AccountService,
        public fctService : FCTService,
        public errorService: ErrorService,
    ){
        this.context = dialog.context;
    }

    ngOnInit() {
        if(this.appConfig && !this.appConfig.isEsiLawIntegrationEnabled){
            this.isStatementConfigAvailable = true;
        } else {
            this.loadSoaConfig();
        }
        this.authKeys = AUTH_OPERATION;
        let loggedUserId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        this.accountService.getAccount(loggedUserId).subscribe((account : Account) => {
            if(account) {
                this.account = new Account(account);
            }
            if(this.appConfig && this.appConfig.isEsiLawIntegrationEnabled){
                this.initSoaConfig();
            }
        });

        this.userAccessibleProvinces = this.userStateService.getEnabledUserProvinceCodes();
    }

    ngAfterViewInit() {
        this.errorService.errorTab.saveErrors = [];
    }



    close(){
        this.errorService.errorTab.saveErrors = [];
        this.dialog.close();

    }
     isTitleInsuranceAvailable(): boolean {
        //If the user only has 'Saskatchewan' and 'Nova Scotia' as  Accessible Provinces, then the contents under Title Insurance OR EMP Section should be hidden.
        if(this.userAccessibleProvinces.length == 1 && (this.userAccessibleProvinces[0] == 'SK' || this.userAccessibleProvinces[0] == 'NS')){
            return false;
        }
        if( this.userAccessibleProvinces.length == 2 && (this.userAccessibleProvinces.indexOf('SK') != -1 && this.userAccessibleProvinces.indexOf('NS') != -1)) {
            return false;
        }
        return true;
    }

    openHomeToolsWindow(): void {
        this.stewartTitleService.getStewartTitleUser().subscribe(res => {
            this.stewartTitleCredentials = res;
            if (this.stewartTitleCredentials) {
                this.errorService.errorTab.saveErrors = [];
                this.stewartTitleService.getHomeToolsUrl().subscribe((url: string) => {
                    this.stewartTitleTransactionUrl = url;
                    window.open(this.stewartTitleTransactionUrl, "_blank");
                }, (error: ApplicationError) => {
                    //console.log(error);
                    if (error && error.errorCode === 'INVALID_GRANT') {
                        this.stewartTitleAuthenticationService.revalidateStewartTitleUser(this.stewartTitleCredentials);
                    } else {
                        this.displayErrorModal();
                    }
                });
            } else {
                this.displayErrorModal();
            }
        }, (error: ApplicationError) => {
            console.log(error);
            this.displayErrorModal();
        });
    }

    openPremiumCalculator(): void {
        this.lockScreenService.lockForUpdate = true;
        this.stewartTitleService.getPremiumCalculatorUrl().finally(() => {
            this.lockScreenService.lockForUpdate = false;
        }).subscribe(
            (calculatorUrl: string) => {
                window.open(calculatorUrl);
            },
            (error: ApplicationError) => {
                if (error) {
                    this.dialogService.confirm('Error', error.message, true, 'OK').subscribe(res => {
                    });
                }
            }
        );
    }

    isChicagoTitleVisible(): boolean {
        let flag: boolean = false;
        if(this.userAccessibleProvinces.length > 0) {
            this.userAccessibleProvinces.forEach(value => {
                if(CHICAGO_TITLE_APPLICABLE_PROVINCES.indexOf(value) > -1) {
                    flag = true;
                }
            });
        }
        return flag;
    }

    isTitlePlusVisible(): boolean {
        let flag: boolean = false;
        if (this.userAccessibleProvinces.length > 0 && this.appConfig && this.appConfig.titlePlusEnabledProvinces.length>0){
            this.userAccessibleProvinces.forEach(value => {
                if (this.appConfig.titlePlusEnabledProvinces.indexOf(value)>-1){
                    flag = true;
                }
            });
        }
        return flag;
    }

    hasTitlePLUSAccess() : boolean {
        return this.authorizationService.hasAccess(AUTH_OPERATION.TITLE_PLUS_INTEGRATIONS);
    }

    get titleInsuranceOrEmpNotSupportedMsg(): string{
        return `Not Available in ${this.userAccessibleProvinces.join(' or ')}`;
    }

    hasFCTAccess() : boolean {
        return this.authorizationService.hasAccess(AUTH_OPERATION.FCT_INTEGRATIONS);
    }

    isFctEnabled(): boolean {
        return this.appConfig.isFctEnabled && this.isFctEnabledForUser;
    }
    get isFctEnabledForUser() : boolean {
        return sessionStorage.getItem(SESSION_STORAGE_KEYS.fctEnabled) == 'true';
    }

    loadSoaConfig(): void {
        let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        this.soaConfigService
            .getStatementConfig(id)
            .subscribe((statementConfig: StatementConfig) => {
                if(statementConfig && statementConfig.accountNationalSetting && statementConfig.accountNationalSetting.accountingSoftware != 'ESILAW') {
                    this.isStatementConfigAvailable = true;
                }
            });
    }

    initSoaConfig() : void {
        if(this.account.defaultProvinceCode) {
            let id = this.account ? this.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
            this.soaConfigService
                .getStatementConfig(id, null, (this.account.defaultProvinceCode as ProvinceCode))
                .finally( () =>{
                    this.isStatementConfigAvailable = true;
                })
                .subscribe((statementConfig: StatementConfig) => {
                    if (statementConfig && statementConfig.accountNationalSetting && statementConfig.accountNationalSetting.accountingSoftware == 'ESILAW') {
                        this.showDownloadAccExpFileOpt = false;
                    }
                });
        }

    }

    openFctInfo(context): void {
        this.fctService.getFCTRedirectUrl(context).subscribe(res => {
            window.open(res,'_blank');
        }, (error) => {

            console.log(error);
            let errorText = 'Unexpected error happened on Server, please try later or contact the Administrator';
            if(error.statusText && error.status){
                errorText = error.status + " - " + error.statusText;
            } else if(error.errorCode && error.message){
                errorText = error.message  + " - " + error.errorCode;
            }
            this.dialogService.confirm('Error', errorText, true, 'OK').subscribe(res => {
            })

        });
    }

    openAccountingExportModal(): void {
        this.dialogService.content({
            content       : AccountingExportModalComponent,
            context       : { },
            onFulfillment : (result) => {

            }
        });
    }

    displayErrorModal(): void{
        this.errorService.errorTab.saveErrors.shift();
        this.errorService.errorTab.saveErrors.unshift(DPError.createDPError("integrations.stewartTitle.credentialsNotFound"));
    }

}
