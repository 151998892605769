export const CIRF_CONFIG_KEYS = {
    cirfClientInfo : {
        firstName : 'PERSONAL_DETAILS.CLIENT_PROFILE.FIRST_NAME',
        middleName : 'PERSONAL_DETAILS.CLIENT_PROFILE.MIDDLE_NAME',
        lastName : 'PERSONAL_DETAILS.CLIENT_PROFILE.LAST_NAME',
        gender : 'PERSONAL_DETAILS.CLIENT_PROFILE.GENDER',
        birthDate : 'PERSONAL_DETAILS.CLIENT_PROFILE.BIRTH_DATE',
        isCanadianResident : 'PERSONAL_DETAILS.CLIENT_PROFILE.CANADIAN_RESIDENT',
        email : 'PERSONAL_DETAILS.CLIENT_PROFILE.EMAIL',
        firstTimeHomeBuyer : 'PERSONAL_DETAILS.CLIENT_PROFILE.ARE_YOU_A_FIRST_TIME_HOME_OWNER',
        residentStatus : 'PERSONAL_DETAILS.CLIENT_PROFILE.RESIDENT_STATUS',
        spousalStatus : 'PERSONAL_DETAILS.CLIENT_PROFILE.WHAT_IS_YOUR_SPOUSAL_STATUS',
        isFamilyResidence : 'PERSONAL_DETAILS.CLIENT_PROFILE.IS_THIS_THE_FAMILY_RESIDENCE',
        isSpouseOnTitle : 'PERSONAL_DETAILS.CLIENT_PROFILE.IS_SPOUSE_ON_TITLE',
        spouseName : 'PERSONAL_DETAILS.CLIENT_PROFILE.SPOUSE_NAME',
        occupation : 'PERSONAL_DETAILS.CLIENT_PROFILE.OCCUPATION',
        phone1 : {
            telephoneNumber : 'PERSONAL_DETAILS.CLIENT_PROFILE.HOME_PHONE_NUMBER',
        },
        phone2 : {
            telephoneNumber : 'PERSONAL_DETAILS.CLIENT_PROFILE.MOBILE_PHONE_NUMBER',
        },
        phone3 : {
            telephoneNumber : 'PERSONAL_DETAILS.CLIENT_PROFILE.WORK_PHONE_NUMBER',
        },

        address1 : {
            addressLine1 : 'PERSONAL_DETAILS.CLIENT_PROFILE.ADDRESS_1',
            addressLine2 : 'PERSONAL_DETAILS.CLIENT_PROFILE.ADDRESS_2',
            city : 'PERSONAL_DETAILS.CLIENT_PROFILE.CITY',
            country : 'PERSONAL_DETAILS.CLIENT_PROFILE.COUNTRY',
            postalCode : 'PERSONAL_DETAILS.CLIENT_PROFILE.POSTAL_CODE',
            provinceCode : 'PERSONAL_DETAILS.CLIENT_PROFILE.PROVINCE',
        },
        identification1 : {
            identificationTypeCode : 'PERSONAL_DETAILS.PRIMARY_IDENTIFICATION_VERIFICATION.TYPE_OF_ID',
            identificationNumber : 'PERSONAL_DETAILS.PRIMARY_IDENTIFICATION_VERIFICATION.NUMBER',
            placeOfIssue : 'PERSONAL_DETAILS.PRIMARY_IDENTIFICATION_VERIFICATION.PLACE_OF_ISSUE',
            expiryDate : 'PERSONAL_DETAILS.PRIMARY_IDENTIFICATION_VERIFICATION.EXPIRY_DATE',
            country : 'PERSONAL_DETAILS.PRIMARY_IDENTIFICATION_VERIFICATION.COUNTRY',
        },
        identification2 :{
            identificationTypeCode : 'PERSONAL_DETAILS.SECONDARY_IDENTIFICATION_VERIFICATION.TYPE_OF_ID',
            identificationNumber : 'PERSONAL_DETAILS.SECONDARY_IDENTIFICATION_VERIFICATION.NUMBER',
            placeOfIssue : 'PERSONAL_DETAILS.SECONDARY_IDENTIFICATION_VERIFICATION.PLACE_OF_ISSUE',
            expiryDate : 'PERSONAL_DETAILS.SECONDARY_IDENTIFICATION_VERIFICATION.EXPIRY_DATE',
            country : 'PERSONAL_DETAILS.SECONDARY_IDENTIFICATION_VERIFICATION.COUNTRY',
        },
        clientNotes : 'PERSONAL_DETAILS.CLIENT_NOTES.CLIENT_NOTES',
    },

    matterContactInfo : {
        clientCapacity : 'TRANSACTION_DETAILS.MATTER_CONTACT_INFO.CAPACITY',
        residingAtSubjectProperty : 'TRANSACTION_DETAILS.MATTER_CONTACT_INFO.RESIDING_AT_SUBJECT_PROPERTY',
        address : {
            addressLine1 : 'TRANSACTION_DETAILS.MATTER_CONTACT_INFO.ADDRESS_1',
            addressLine2 : 'TRANSACTION_DETAILS.MATTER_CONTACT_INFO.ADDRESS_2',
            city : 'TRANSACTION_DETAILS.MATTER_CONTACT_INFO.CITY',
            country : 'TRANSACTION_DETAILS.MATTER_CONTACT_INFO.COUNTRY',
            postalCode : 'TRANSACTION_DETAILS.MATTER_CONTACT_INFO.POSTAL_CODE',
            provinceCode : 'TRANSACTION_DETAILS.MATTER_CONTACT_INFO.PROVINCE',
        },
    },

    fireInsuranceDetail : {
        companyName : 'TRANSACTION_DETAILS.INSURANCE_DETAILS.NAME_OF_COMPANY',
        policyNumber : 'TRANSACTION_DETAILS.INSURANCE_DETAILS.POLICY_NO',
        broker : {
            name: 'TRANSACTION_DETAILS.INSURANCE_DETAILS.NAME_OF_BROKER',
            email : 'TRANSACTION_DETAILS.INSURANCE_DETAILS.EMAIL',
        },
    },

    existingMortgage : {
        bankName: 'EXISTING_MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.NAME_OF_BANK',
        referenceNumber: 'EXISTING_MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.REFERENCE_NO',
        broker : {
            name: 'EXISTING_MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.NAME_OF_BROKER',
            email : 'EXISTING_MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.EMAIL',
        },
        mortgageNotes : 'EXISTING_MORTGAGE_LOAN_DETAILS.MORTGAGE_NOTES.MORTGAGE_NOTES'
    },
    newMortgage : {
        bankName: 'MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.NAME_OF_BANK',
        referenceNumber: 'MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.REFERENCE_NO',
        broker : {
            name: 'MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.NAME_OF_BROKER',
            email : 'MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.EMAIL',
        },
        mortgageNotes : 'MORTGAGE_LOAN_DETAILS.MORTGAGE_NOTES.MORTGAGE_NOTES'
    },

    matterNotes : 'TRANSACTION_DETAILS.GENERAL_MATTER_NOTES.GENERAL_MATTER_NOTES',
    insuranceNotes : 'TRANSACTION_DETAILS.INSURANCE_DETAILS.FIRE_INSURANCE_NOTES',
    cirfClientInfoUdf: 'PERSONAL_DETAILS.CLIENT_PROFILE.UDF',
    identification1Udf: 'PERSONAL_DETAILS.PRIMARY_IDENTIFICATION_VERIFICATION.UDF',
    identification2Udf: 'PERSONAL_DETAILS.SECONDARY_IDENTIFICATION_VERIFICATION.UDF',
    fireInsuranceDetailUdf: 'TRANSACTION_DETAILS.INSURANCE_DETAILS.UDF',
    matterContactInfoUdf: 'TRANSACTION_DETAILS.MATTER_CONTACT_INFO.UDF',
    newMortgageUdf: 'MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.UDF',
    existingMortgageUdf: 'EXISTING_MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.UDF',

    fireInsuranceBroker : {
        phone1 : {
            telephoneNumber : 'TRANSACTION_DETAILS.INSURANCE_DETAILS.PHONE_NUMBER',
        },
    },

    existingMortgageBroker : {
        phone1 : {
            telephoneNumber : 'EXISTING_MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.PHONE_NUMBER',
        },
    },

    newMortgageBroker : {
        phone1 : {
            telephoneNumber : 'MORTGAGE_LOAN_DETAILS.MORTGAGE_DETAILS.PHONE_NUMBER',
        },
    }
};
