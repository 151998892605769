import {CustomKeyCodesEnum} from '../../common/key-code-enum';

declare var jQuery : any;
declare var CKEDITOR : any;

export class CkEditorUtil {

    static setUpEditor(editor : any): void {
        if(editor) {
           editor.on('key', (event) => {
               if(event.data.keyCode == CustomKeyCodesEnum.F8){
                       var dummyElement = editor.document.createElement( 'img', {
                                                                             attributes : {
                                                                                     src : 'null',
                                                                                     width : 0,
                                                                                     height : 0
                                                                                 }
                                                                         });
                       // get cursor location
                       editor.insertElement( dummyElement );

                       var x = 0;
                       var y  = 0;

                       var obj = dummyElement.$;

                       while (obj.offsetParent){
                           x += obj.offsetLeft;
                           y  += obj.offsetTop;
                           obj    = obj.offsetParent;
                       }
                       x += obj.offsetLeft;
                       y  += obj.offsetTop;

                       dummyElement.remove();

                       let scrollPos = jQuery('.cke_wysiwyg_frame').contents().scrollTop();
                        if(jQuery('.ckeditor-f8-menu').hasClass('hide')){
                            // set menu to cursor location
                            jQuery('.ckeditor-f8-menu').css("top",(y+50 - scrollPos) + "px");
                            jQuery('.ckeditor-f8-menu').css("left",(x) + "px");
                            jQuery('.ckeditor-f8-menu').addClass('show');
                            jQuery('.ckeditor-f8-menu').removeClass('hide');
                        } else {
                            jQuery('.ckeditor-f8-menu').addClass('hide');
                            jQuery('.ckeditor-f8-menu').removeClass('show');
                        }
                }

            });
           editor.on( 'contentDom', function() {
                var editable = editor.editable();
                if(editable)
                {
                    editable.attachListener( editable, 'click', function( evt ) {
                        var link = new CKEDITOR.dom.elementPath( evt.data.getTarget(), this ).contains( 'a' );
                        if ( link && evt.data.$.button != 2 && link.isReadOnly() && link.getAttribute( 'href' ) && link.getAttribute( 'href' ).indexOf('mailto') < 0) {
                            window.open( link.getAttribute( 'href' ) );
                        }
                        else if(link && evt.data.$.button != 2 && link.isReadOnly() && link.getAttribute( 'href' ) && link.getAttribute( 'href' ).indexOf('mailto') > -1){
                            var windowRef = window.open(link.getAttribute( 'href' ));
                            setTimeout(function(){
                                    windowRef.close();
                            }, 0);

                        }
                    }, null, null, 15 );
                }

            } );

            editor.on('paste', function(event) {
                //console.log(event.data.dataValue);
                if(event.data.type == 'html'){
                    // Strip formatting prior to pasting into RTF Editor
                    //To keep the new line we have to keep all <p> & </p>
                    let newLine = '_DP_NEW_LINE_';
                    event.data.dataValue = event.data.dataValue.replace(/<\/p>/g, newLine);  //Replace all occurance of </p> with _DP_NEW_LINE_
                    event.data.dataValue = event.data.dataValue.replace(/<\/?[^>]+(>|$)/g, "");  //Remove All the HTML meta tags
                    event.data.dataValue = event.data.dataValue.split(newLine).map( para => '<p>'+para+'</p>' ).join(''); //Place <p> & </p> around each line.
                    //console.log(event.data.dataValue);
                }
            }, editor.element.$);

            editor.ui.addButton('dpbold', {
                label   : "Bold",
                command : "bold",
                toolbar : 'basicstyles,1000'
            });

            editor.ui.addButton('dpunderline', {
                label   : "Underline",
                command : "underline",
                toolbar : 'basicstyles,1005'
            });

            editor.ui.addButton('dpitalic', {
                label   : "Italic",
                command : "italic",
                toolbar : 'basicstyles,1010'
            });

            editor.ui.addButton('dpnormal', {
                label   : "Nor<span style='text-decoration: underline'>m</span>al",
                command : "removeFormat",
                toolbar : 'basicstyles,1015'
            });

            editor.ui.addButton('dpindent', {
                label   : "<span></span>In<span style='text-decoration: underline'>d</span>ent",
                command : "dpindent",
                toolbar : 'basicstyles,1020'
            });

            editor.addCommand("dpindent", {
                exec : function(editor) {
                    editor.insertHtml('&raquo;');
                }
            });

            editor.ui.addButton('dptab', {
                label   : "Tab (<span style='text-decoration: underline'>1</span>)",
                command : "dptab",
                toolbar : 'basicstyles,1025'
            });

            editor.addCommand("dptab", {
                exec : function(editor) {
                    editor.insertHtml('&not;');
                }
            });

            editor.ui.addButton('dpparano', {
                label   : "Para No",
                command : "dpparano",
                toolbar : 'basicstyles,1030'
            });

            editor.addCommand("dpparano", {
                exec : function(editor) {
                    editor.insertHtml('&sect;');
                }
            });

            //Change the title of the html buttons to use user friendly text
            editor.on('instanceReady',() => {
                jQuery(".cke_button__"+"dpnormal").attr({'title':'Normal Format (Ctrl+M)'});
                jQuery(".cke_button__"+"dpindent").attr({'title':'Indent (Ctrl+D)'});
                jQuery(".cke_button__"+"dptab").attr({'title':'Tab (Ctrl+1)'});
            });

            editor.setKeystroke(CKEDITOR.CTRL + 49, 'dptab'); // 1
            editor.setKeystroke(CKEDITOR.CTRL + 68, 'dpindent'); // d
            editor.setKeystroke(CKEDITOR.CTRL + 80, 'dpparano'); //p
            editor.setKeystroke(CKEDITOR.CTRL + 77, 'removeFormat'); //m
            CKEDITOR.config.resize_enabled = false; //Disabled Resizing
            CKEDITOR.config.menu_groups = 'clipboard'; //Added the standard right click menu
            //Default setting
            // CKEDITOR.config.menu_groups="clipboard,form,tablecell,tablecellproperties,tablerow,tablecolumn,table,anchor,link,image,flash,checkbox,radio,textfield,hiddenfield,imagebutton,button,select,textarea,div";
            let htmlF8Menu = '<div class="ckeditor-f8-menu">\n' +
                '\n' +
                '    <ul>\n' +
                '        <li><a><u>I</u>ndent</a></li>\n' +
                '        <li><a><u>T</u>ab</a></li>\n' +
                '        <li><a><u>P</u>ara No</a></li>\n' +
                '    </ul>\n' +
                '</div>'


            jQuery(editor.element.$).parent().append(htmlF8Menu);
            jQuery('.ckeditor-f8-menu').addClass("hide");
            jQuery('.ckeditor-f8-menu').on('click', 'li a', (e) => {
               console.log(e);
                if(e.target.innerText.toUpperCase()=='INDENT'){
                    editor.insertHtml('&raquo;');
                }
                if(e.target.innerText.toUpperCase()=='TAB'){
                    editor.insertHtml('&not;');
                }
                if(e.target.innerText.toUpperCase()=='PARA NO'){
                    editor.insertHtml('&sect;');
                }
                jQuery('.ckeditor-f8-menu').removeClass("show");
                jQuery('.ckeditor-f8-menu').addClass("hide");
            });


        }
    }

}
