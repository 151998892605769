import {Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as Highcharts from 'highcharts';
import moment from 'moment';
import { interval } from 'rxjs';
import { EventReduceHelper } from './helpers/event-reduce.helper';
import { LandingWidgetsService } from './landing-widgets.service';

declare var jQuery : any;

@Component({
  selector: 'lib-LandingWidgets',
  templateUrl: './landing-widgets.component.html',
  styleUrls: ['./landing-widgets.component.css']
})
export class LandingWidgetsComponent implements OnInit {

  @Output() enableContinueBtn = new EventEmitter<boolean>();
  // Show the popup message box
  @ViewChild("lawyerChart") lawyerChart: ElementRef | undefined;
  @ViewChild("cityChart") cityChart: ElementRef | undefined;
  @ViewChild("clerkChart") clerkChart: ElementRef | undefined;
  @ViewChild("ClosedMatters") ClosedMatters: ElementRef | undefined;
  @ViewChild("ClosingForecast") ClosingForecast: ElementRef | undefined;
  @ViewChild("OpeningForecast") OpeningForecast: ElementRef | undefined;
  @ViewChild('messageBox') messageBox: TemplateRef<any> | undefined;
  @ViewChild('tooltip') tooltip: TemplateRef<any> | undefined;
  @ViewChild('closeAddExpenseModal') closeAddExpenseModal: TemplateRef<any> | undefined;
  @ViewChild("MatterRevenue") MatterRevenue: ElementRef | undefined;
  @ViewChild("thisMonthTransaction") thisMonthTransaction: ElementRef | undefined;
  @ViewChild("thisYearTransaction") thisYearTransaction: ElementRef | undefined;
  @ViewChild("pastMonthMatterTypes") pastMonthMatterTypes: ElementRef | undefined;
  @ViewChild("thisYearMatterTypes") thisYearMatterTypes: ElementRef | undefined;
  @ViewChild("referrals") referrals: ElementRef | undefined;
  errorMsg: String = "";

  // Display loading indiactor
  showLoadingIndicatorForEvent: boolean = false;
  showLoadingIndicatorForMatterRevenue: boolean = false;
  showLoadingIndicatorForReferrals: boolean = false;
  showLoadingIndicatorForMatter: boolean = false;
  showLoadingIndicatorForForecast: boolean = false;
  showLoadingAnimationForMatterClosed: boolean = false;
  showLoadingIndicatorForOpeningForecast: boolean = false;
  showLoadingAnimation = false;

  // Binding chart data
  clerkChartData: any[] = [];
  lawyerChartData: any[] = [];
  cityChartData: any[] = [];
  thisMonthReferalsData: any[] = [];
  referralsByCategory: any[] = [];
  referralsByName: any[] = [];
  referralsData: any[] = [];
  thisMonthReferalsCategoryData: any[] = [];
  matterClosingForcastData: any[] = [];
  showNoMatterClosingForcastData: boolean = false;
  thisYearReferalsData: any[] = [];
  thisYearReferalsCategoryData: any[] = [];
  ClerkDataNamesArray: string[] = [];
  ClerkDataValuesArray: string[] = [];
  LawyerDataNamesArray: string[] = [];
  LawyerDataValuesArray: string[] = [];
  CityDataNamesArray: string[] = [];
  CityDataValuesArray: string[] = [];

  thisMonthReferalsNamesArray: string[] = [];
  thisYearReferalsNamesArray: string[] = [];
  thisMonthReferalsCategoryNamesArray: string[] = [];
  thisYearReferalsCategoryNamesArray: string[] = [];
  thisMonthReferalsValuesArray: string[] = [];
  thisYearReferalsValuesArray: string[] = [];
  thisMonthReferalsCategoryValuesArray: string[] = [];
  thisYearReferalsCategoryValuesArray: string[] = [];
  thisMonthReferals: string[] = [];
  thisYearReferals: string[] = [];
  thisMonthReferalsCategory: string[] = [];
  thisYearReferalsCategory: string[] = [];
  thisYearMatterTypesData: any[] = [];
  pastMonthMatterTypesData: any[] = [];

  TodaysDataArray: any[] = [];
  ThisWeekDataArray: any[] = [];
  AppointmentDataArray: any[] = [];
  MyWeekDataArray: any[] = [];
  MyTodayDataArray: any[] = [];
  ThisWeekArray: string[] = [];
  ForecastMonthsArray: string[] = [];
  ForecastThisYearValues: number[] = [];
  ForecastPreviousYearValues: number[] = [];
  ForecastThisYearString: string = "";
  ForecastPreviousYearString: string = "";
  ForecastChartMaximumValue: number = 0;
  ForecastChartIntervalValue: number = 0;
  clerkChartEmptyView: number = 0;
  cityChartEmptyView: number = 0;
  lawyerChartEmptyView: number = 0;
  todaysEventEmptyView: number = 0;
  thisWeekEventEmptyView: number = 0;
  MyTodaysDataArrayEmptyView: number = 0;
  MyWeekDataArrayEmptyView: number = 0;
  openingForecastMonthsArray: string[] = [];
  openingForecastThisYearValues: number[] = [];
  openingForecastPreviousYearValues1: number[] = [];
  openingForecastPreviousYearValues2: number[] = [];
  openingForecastNextYearValues: number[] = [];
  openingForecastThisYearString: string = "";
  openingForecastPreviousYearString1: string = "";
  openingForecastPreviousYearString2: string = "";
  openingForecastNextYearString: string = "";
  openingForecastChartMaximumValue: number = 0;
  openingForecastChartIntervalValue: number = 0;
  clerkData: string[] = [];
  chart: Highcharts.Chart | undefined;
  clerkChartHeight: Number = 0;
  lawyerChartHeight: Number = 0;
  cityChartHeight: Number = 0;
  contact_id: Number = 0;
  matterId: Number = 0;
  show: boolean = false;
  showPrevYear2Bar: boolean = false;
  thisMonthConveyancer: number = 0;
  thisMonthUnity: number = 0;
  thisYearUnity: number = 0;
  thisMonthEstateBase: number = 0;
  thisMonthFastCompany: number = 0;
  thisMonthWillBuilder: number = 0;
  thisYearConveyancer: number = 0;
  thisYearEstateBase: number = 0;
  thisYearFastCompany: number = 0;
  thisYearWillBuilder: number = 0;
  showThisMonthNoTransaction: boolean = false;
  showThisYearNoTransaction: boolean = false;
  showThisMonthNoMatterTypes: boolean = false;
  showThisYearNoMatterTypes: boolean = false;
  thisMonthData: any[] = [];
  thisYearData: any[] = [];
  mattersOpenedCurrentMonthValue: number = 0;

  revenueMonth: string[] = [];
  revenueMatter: number[] = [];
  revenueFees: number[] = [];

  // Set chart visibility
  showLawyerChart: boolean = false;
  showClerkChart: boolean = false;
  showCityChart: boolean = false;
  showThisMonthReferals: boolean = false;
  showNoReferralsByName: boolean = false;
  showNoReferralsByCategory: boolean = false;
  showThisMonthReferalsCategory: boolean = false;
  showThisYearReferalsCategory: boolean = false;
  showTodayEventStatus: boolean = false;
  showThisweekEventStatus: boolean = false;
  showNoOpeningForecast: boolean = false;
  showRevenue: boolean = true;
  showMatterRevenue: boolean = false;

  // Set upcoming and matter widget filter options visibility
  eventFilterVisibility: boolean = false;
  matterFilterVisibility: boolean = false;
  referralsFilterVisibility: boolean = false;
  modalFilter: boolean = false;
  eventType: string | null = "All Events";
  matterType: string | null = "Previous Month";
  referralType: string | null = "Current Month";
  WeekDataDefaultVisibleCount = 5;
  TodayEventDefaultVisibleCount = 8;
  dashboardVisibility: boolean = true;

  sunday: Number = 0;
  monday: Number = 0;
  tuesday: Number = 0;
  wednesday: Number = 0;
  thursday: Number = 0;
  friday: Number = 0;
  saturday: Number = 0;
  upcomingDisplayDate: Date = new Date;
  revenueMatterInterval: Number = 0;
  revenueFeesInterval: Number = 0;
  activeMonday: boolean = false;
  activeSunday: boolean = false;
  activeTuesday: boolean = false;
  activeWednesday: boolean = false;
  activeThursday: boolean = false;
  activeFriday: boolean = false;
  activeSaturday: boolean = false;

  // Check the OS type
  isIOS: boolean = false;
  OSType: string | null = null;

  // Set default options for upcoming and matter widget
  filterEvents: any[] = [
    { Id: 1, Status: 'All Events', isChecked: true },
    { Id: 2, Status: 'My Events', isChecked: false }
  ];
  filterMatters: any[] = [
    { Id: 1, Status: 'Previous Month', isChecked: false },
    { Id: 2, Status: 'Current Month', isChecked: true },
    { Id: 3, Status: 'Next Month', isChecked: false }
  ];
  filterReferrals: any[] = [
    { Id: 1, Status: 'Current Month', isChecked: true },
    { Id: 2, Status: 'Yearly', isChecked: false }
  ];
  filterEventsData: any[] = [];
  filterMattersData: any[] = [];
  filterReferralsData: any[] = [];
  filterTitle: string = "";
  showBackAnimation: boolean = false;
  isFirstForDashboardGetCSV: boolean = true;

  //Referral Widget Visibility
  ShowReferalsWidget: boolean = false;
  //Transaction Portfolio Widget Visibility
  ShowTransactionsWidget: boolean = true;

  //Local Cache
  EventCachedData:any;
  OpeningForecastCachedData:any;
  MatterCachedData: any;

  // HTML Content
  loggedInUserContent: SafeHtml = "";
  nonLoggedInUserContent: SafeHtml = "";

  // Flags
  isConveyancingUser: boolean;
  hasErrors: boolean;

  constructor(private service: LandingWidgetsService, private sanitized: DomSanitizer) {
    this.hasErrors = false;
    this.isConveyancingUser = false;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    if(this.service.getIsConveyancingUser()){
        this.isConveyancingUser = true;
        var today = new Date();

        this.thisWeekData(today.getDay() + 1);
        this.getCurrentWeekDates();

        this.getMatterSummaryFromAPI();
        this.getOpeningForecastFromAPI();
        this.getMatterRevenueFromAPI();

        this.service.getLoggedInUserContent()
        .subscribe(html => {
            this.loggedInUserContent = this.sanitized.bypassSecurityTrustHtml(html);
        }, error => {
            console.error(error);
            this.hasErrors = true;
        });
    }
    else{
        this.isConveyancingUser = false;
        this.service.getNonLoggedInUserContent()
        .subscribe(html => {
            this.nonLoggedInUserContent = this.sanitized.bypassSecurityTrustHtml(html);
            //this.enableContinueBtn.emit(true);
        }, error => {
            console.error(error);
            this.hasErrors = true;
        });;
    }
  }


  //Toogle Active for Tabs
  toogleTabActive(parentElement: any, currentElement:any, navContentElement: any){

    const currentTabId = currentElement.target.getAttribute('data-tab-id');

    parentElement.childNodes.forEach(element => {

        const id = element.getAttribute('data-tab-id');
        if(currentTabId == id)
        {
            element.classList.add('active')
        }
        else{
            element.classList.remove('active')
        }
    });

    navContentElement.childNodes.forEach(element => {
        const id = element.getAttribute('data-tab-id');
        if(currentTabId == id)
        {
            element.classList.add('d-block');
            element.classList.remove('d-none');
        }
        else{
            element.classList.add('d-none');
            element.classList.remove('d-block');
        }
    });
  }

  // Get weekdays for upcoming widget calendar
  getCurrentWeekDates() {
    var curr = new Date;
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    this.sunday = firstday.getDate();
    this.monday = this.addDays(firstday, 1).getDate();
    this.tuesday = this.addDays(firstday, 2).getDate();
    this.wednesday = this.addDays(firstday, 3).getDate();
    this.thursday = this.addDays(firstday, 4).getDate();
    this.friday = this.addDays(firstday, 5).getDate();
    this.saturday = this.addDays(firstday, 6).getDate();
  }

  // Adding days to the date by using the parameters
  addDays(date: any, days: any) {
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }

  //Doing Reset for weekdays active status
  ResetWeekDaysActiveStatus() {
    this.activeSunday = false;
    this.activeMonday = false;
    this.activeTuesday = false;
    this.activeWednesday = false;
    this.activeThursday = false;
    this.activeFriday = false;
    this.activeSaturday = false;
  }

  // Used to format the date and month (adding zero when it is single digit in prefix)
  pad(n: any) {
    return n < 10 ? '0' + n : n
  }

  // Userd to redirect to the matter details page
  showMatterDetails(index: number, type: string) {
    if (type == "allDay") {
      this.matterId = this.ThisWeekDataArray[index].matterId;
    }
    else {
      this.matterId = this.AppointmentDataArray[index].matterId;
    }

    if (this.matterId != null) {
      // TODO: This has to be implemented as per Unity
      //this.router.navigate(['/matters/matterdetails', this.matterId]);
    }
  }

  // Calculate matter's clerk chart height depends on counts
  getClerkChartHeight() {
    if (this.clerkChartData.length < 2) {
        this.clerkChartHeight = (this.clerkChartData.length * 25);
    }
    else if (this.clerkChartData.length <= 2) {
        this.clerkChartHeight = (this.clerkChartData.length * 18);
    }
    else if (this.clerkChartData.length <= 3) {
        this.clerkChartHeight = (this.clerkChartData.length * 15);
    }
    else if (this.clerkChartData.length <= 5) {
        this.clerkChartHeight = (this.clerkChartData.length * 15);
    }
    else {
        this.clerkChartHeight = (this.clerkChartData.length * 15);
    }
}

// Calculate matter's lawyer chart height depends on counts
getLawyerChartHeight() {
    if (this.lawyerChartData.length < 2) {
        this.lawyerChartHeight = (this.lawyerChartData.length * 25);
    }
    else if (this.lawyerChartData.length <= 2) {
        this.lawyerChartHeight = (this.lawyerChartData.length * 18);
    }
    else if (this.lawyerChartData.length <= 3) {
        this.lawyerChartHeight = (this.lawyerChartData.length * 15);
    }
    else if (this.lawyerChartData.length <= 5) {
        this.lawyerChartHeight = (this.lawyerChartData.length * 15);
    }
    else {
        this.lawyerChartHeight = (this.lawyerChartData.length * 15);
    }
}

// Calculate matter's city chart height depends on counts
getCityChartHeight() {
    if (this.cityChartData.length < 2) {
        this.cityChartHeight = (this.cityChartData.length * 25);
    }
    else if (this.cityChartData.length <= 2) {
        this.cityChartHeight = (this.cityChartData.length * 18);
    }
    else if (this.cityChartData.length <= 3) {
        this.cityChartHeight = (this.cityChartData.length * 15);
    }
    else if (this.cityChartData.length <= 5) {
        this.cityChartHeight = (this.cityChartData.length * 15);
    }
    else {
        this.cityChartHeight = (this.cityChartData.length * 15);
    }
}

  // Change UpcomingWidget Filter
  ChangeFilterForUpcomingWidget(event: any){
    this.eventType = event.target.value;
    this.processEventData(this.EventCachedData);
  }

  // Change Matters Filter
  ChangeFilterForMattersWidget(event: any){
    this.matterType = event.target.value;
    this.processMatterData(this.MatterCachedData);
  }

  // Change Refferals Filter
  ChangeFilterForRefferalsWidget(event: any){
    this.referralType = event.target.value;
    this.processReferalsData(this.MatterCachedData);
  }

  // Calling filter options page to change the default options of upcoming's all events and matter's current month options
  filterCall(filterType: any) {
    this.showBackAnimation = false;
    if (filterType == "events") {
      this.filterTitle = "Upcoming Events";
      //this.commonService.changeStatus(false)
      this.modalFilter = true;
      this.eventFilterVisibility = true;
      this.matterFilterVisibility = false;
      this.referralsFilterVisibility = false;
      this.dashboardVisibility = false;
    }
    else if (filterType == "matters") {
      this.filterTitle = "Matters";
      this.modalFilter = true;
      //this.commonService.changeStatus(false)
      this.matterFilterVisibility = true;
      this.eventFilterVisibility = false;
      this.referralsFilterVisibility = false;
      this.dashboardVisibility = false;
    }
    else if (filterType == "referrals") {
      this.filterTitle = "Referrals";
      this.modalFilter = true;
      //this.commonService.changeStatus(false)
      this.referralsFilterVisibility = true;
      this.matterFilterVisibility = false;
      this.eventFilterVisibility = false;
      this.dashboardVisibility = false;
    }
    else {
      this.modalFilter = false;
      this.matterFilterVisibility = false;
      this.eventFilterVisibility = false;
      this.referralsFilterVisibility = false;
      this.dashboardVisibility = true;
    }
  }

  //Set active status for the day
  thisWeekData(day: any) {
    this.ResetWeekDaysActiveStatus();
    if (day == 1) {
      this.activeSunday = true;
    }
    if (day == 2) {
      this.activeMonday = true;
    }
    if (day == 3) {
      this.activeTuesday = true;
    }
    if (day == 4) {
      this.activeWednesday = true;
    }
    if (day == 5) {
      this.activeThursday = true;
    }
    if (day == 6) {
      this.activeFriday = true;
    }
    if (day == 7) {
      this.activeSaturday = true;
    }
    var curr = new Date;
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    //Display selected date
    this.upcomingDisplayDate = this.addDays(firstday, day - 1);

    this.getEventFromAPI();

  }

  // Used to convert numbers into months
  convertNumberToMonth(month: any) {
    var monthName: string;
    switch (month) {
        case 1:
            return monthName = 'Jan';
            break;
        case 2: return monthName = 'Feb';
            break;
        case 3: return monthName = 'Mar';
            break;
        case 4: return monthName = 'Apr';
            break;
        case 5: return monthName = 'May';
            break;
        case 6: return monthName = 'Jun';
            break;
        case 7: return monthName = 'Jul';
            break;
        case 8: return monthName = 'Aug';
            break;
        case 9: return monthName = 'Sep';
            break;
        case 10: return monthName = 'Oct';
            break;
        case 11: return monthName = 'Nov';
            break;
        case 12: return monthName = 'Dec';
            break;
        default:
            return monthName = 'Undefined';
    }
}

// Get values for matter revenue from API
getMatterRevenueFromAPI(): void {
    this.showLoadingIndicatorForMatterRevenue = true;
    this.service.getRevenueMatterData()
        .subscribe(dashboardData => {
            this.processMatterRevenueData(dashboardData);
            this.showLoadingIndicatorForMatterRevenue = false;
        }, error => {
            this.showLoadingIndicatorForMatterRevenue = false;
            if (error.status == 401) {
                //alert("Login Failure, Check Credentials.")
              }
              else {
                this.errorMsg = "Something went wrong, Check Console Log.";
                //alert(this.errorMsg);
                console.error(error);

              }
              this.hasErrors = true;
        });
}

  // Get values for matter data, referals data, closing forecast data from API(Getcsv)
  getMatterSummaryFromAPI(): void {
    this.showLoadingIndicatorForMatter = true;
    this.showLoadingIndicatorForForecast = true;
    this.showLoadingIndicatorForReferrals = true;
    this.service.getDashboardMatters()
        .subscribe(matterData => {
            this.MatterCachedData = matterData;
            this.processMatterData(matterData);
            this.processReferalsData(matterData);
            this.processClosingForecastData(matterData);
            this.processMatterTypesData(matterData);
            this.processCurrentMonthData(matterData);

            //this.getOpeningForecastFromAPI(account_id);
            this.showLoadingIndicatorForMatter = false;
        }, error => {
            this.showLoadingIndicatorForMatter = false;
            if (error.status == 401) {
              //alert("Login Failure, Check Credentials.")
            }
            else {
              this.errorMsg = "Something went wrong, Check Console Log.";
              //alert(this.errorMsg);
              console.error(error);

            }
            this.showLoadingIndicatorForMatter = false;
            this.showLoadingIndicatorForForecast = false;
            this.showLoadingIndicatorForReferrals = false;
            this.hasErrors = true;
        });
}

  getEventFromAPI(): void {
    this.showLoadingIndicatorForEvent = true;
    this.service.getDashboardEvents()
      .subscribe(dashboardData => {
        this.EventCachedData = dashboardData;
        this.processEventData(dashboardData);
        this.showLoadingIndicatorForEvent = false;
      }, error => {
        this.showLoadingIndicatorForEvent = false;
        if (error.status == 401) {
        }
        else {
          this.errorMsg = "Something went wrong, Contact Administrator.";
          //alert(this.errorMsg);
          console.error(error);
        }
        this.hasErrors = true;
      });
  }

  // Get values for matters opened from API
  getOpeningForecastFromAPI(): void {
    this.showLoadingIndicatorForOpeningForecast = true;
    this.service.getOpeningForecastChartData()
        .subscribe(openingForecastData => {
            this.OpeningForecastCachedData = openingForecastData;
            this.processOpeningForecastData(openingForecastData);
            this.showLoadingIndicatorForOpeningForecast = false;
        }, error => {
            this.showLoadingIndicatorForOpeningForecast = false;
            if (error != null && error != undefined) {
                if (error.error != null && error.error != undefined) {
                    if (error.error.Error != null && error.error.Error != undefined) {
                        if (error.error.Error == "Access is denied") {
                            this.showNoOpeningForecast = true;
                        }
                    }
                }
            }
            if (error.status == 401) {
              //alert("Login Failure, Check Credentials.")
            }
            else {
              this.errorMsg = "Something went wrong, Check Console Log.";
              //alert(this.errorMsg);
              console.error(error);
            }
            this.hasErrors = true;
        });
}
  // Processing values for upcoming widget
  processEventData(data: any) {
    this.ThisWeekDataArray = [];
    this.thisWeekEventEmptyView = 0;

    this.ThisWeekDataArray = [];
    var currentDate = new Date(this.upcomingDisplayDate);
    var day = currentDate.getDate();
    var month = currentDate.getMonth();
    var year = currentDate.getFullYear();
    var inputDate = year + "-" + this.pad(month + 1) + "-" + this.pad(day);

    this.AppointmentDataArray = [];

    let eventReducer = new EventReduceHelper();

    let allevents = eventReducer.filterevent(data.AllEvents);
    data.AllEvents = allevents;

    let myevents = eventReducer.filterevent(data.MyEvents);
    data.MyEvents = myevents;

    // Get all events
    if (this.eventType == "All Events") {
      if (data.AllEvents !== null && data.AllEvents !== undefined) {
        data.AllEvents.forEach((element: { eventStatus: string; eventDueDate: any; eventType: string | null | undefined; clientReLine: string | any[] | null | undefined; allDayEvent: boolean; startTime: string; eventSubtype: any; matterId: any; startDate: any; eventDescription: any; }) => {

          if (element.eventStatus != "POSTPONED" && element.eventStatus != "Postponed" && element.eventStatus != "CANCELLED" && element.eventStatus != "Cancelled") {
            var eventDay = element.eventDueDate;
            var i = eventDay.indexOf('T');
            eventDay = eventDay.substring(0, i);

            if (element.eventType == "" || element.eventType == null || element.eventType == undefined) {
              element.eventType = "--";
            }
            if (inputDate == eventDay && element.eventType != "APPOINTMENT") {
              var name: any = element.clientReLine;
              if (name == null || name == undefined || name == "" || name.trim() == '') {
                this.ThisWeekDataArray.push(element.clientReLine = "Unassigned");
              }
              this.ThisWeekDataArray.push(element);
            }
            if (inputDate == eventDay && element.eventType == "APPOINTMENT") {
              if (element.allDayEvent == true) {
                this.ThisWeekDataArray.push(element);
              }
              else {
                if (element.startTime.startsWith("0")) {
                  element.startTime = element.startTime.substring(1);
                }
                var reline: any = "Unassigned";
                if (element.clientReLine != null && element.clientReLine != undefined) {
                  if (element.clientReLine.length > 0) {
                    reline = element.clientReLine;
                  }
                }
                this.AppointmentDataArray.push({
                  "name": reline,
                  "appointmentTime": element.startTime,
                  "subject": element.eventSubtype,
                  "matterId": element.matterId,
                  "startDate": element.startDate,
                  "startTime": element.startTime,
                  "eventDescription": element.eventDescription
                });
              }
            }
          }

        });

        this.thisWeekEventEmptyView = this.ThisWeekDataArray.length;
      }
    }
    // Get my events
    else {
      data.MyEvents.forEach((element: { eventStatus: string; eventDueDate: any; eventType: string | null | undefined; clientReLine: string | any[]; allDayEvent: boolean; startTime: string; eventSubtype: any; matterId: any; startDate: any; eventDescription: any; }) => {
        if (element.eventStatus != "POSTPONED" && element.eventStatus != "Postponed" && element.eventStatus != "CANCELLED" && element.eventStatus != "Cancelled") {
          var eventDay = element.eventDueDate;
          var i = eventDay.indexOf('T');
          eventDay = eventDay.substring(0, i);

          if (element.eventType == "" || element.eventType == null || element.eventType == undefined) {
            element.eventType = "--";
          }

          if (inputDate == eventDay && element.eventType != "APPOINTMENT") {
            this.ThisWeekDataArray.push(element);
            var name = element.clientReLine;
            if (name == null || name == undefined) {
              this.ThisWeekDataArray.push(element.clientReLine = "Unassigned");
            }
          }
          if (inputDate == eventDay && element.eventType == "APPOINTMENT") {
            if (element.allDayEvent == true) {
              this.ThisWeekDataArray.push(element);
            }
            else {
              if (element.startTime.startsWith("0")) {
                element.startTime = element.startTime.substring(1);
              }
              var reline: any = "Un assigned"
              if (element.clientReLine.length > 0) {
                reline = element.clientReLine;
              }
              this.AppointmentDataArray.push({
                "name": reline,
                "appointmentTime": element.startTime,
                "subject": element.eventSubtype,
                "matterId": element.matterId,
                "startDate": element.startDate,
                "startTime": element.startTime,
                "eventDescription": element.eventDescription
              });
            }
          }
        }
      });
      this.thisWeekEventEmptyView = this.ThisWeekDataArray.length;
    }

    this.showLoadingIndicatorForEvent = false;
    if ((this.thisWeekEventEmptyView == 0 || this.thisWeekEventEmptyView == null || this.thisWeekEventEmptyView == undefined)
      && (this.AppointmentDataArray.length == 0 || this.AppointmentDataArray.length == null || this.AppointmentDataArray.length == undefined)) {
      this.showThisweekEventStatus = true;
    }
    else {
      this.showThisweekEventStatus = false;
    }
    this.ThisWeekDataArray.forEach(function (item, index, object) {
      if (item === "Unassigned") {
        object.splice(index, 1);
      }
    });
  }

  // Processing values for closing forcast widget
  processClosingForecastData(data: any) {
    this.ForecastPreviousYearValues = [];
    this.ForecastMonthsArray = [];
    this.ForecastThisYearValues = [];
    this.ForecastThisYearString = new Date().getFullYear().toString();
    this.ForecastPreviousYearString = String(Number(this.ForecastThisYearString) -1)
    let maxValue = 0;
    if (data != null && data != undefined) {
        if (data.MattersClosingForcastData != null && data.MattersClosingForcastData != undefined) {
            this.matterClosingForcastData = data.MattersClosingForcastData;
            this.matterClosingForcastData.forEach(element => {
                if(element.CurrentYearSeries){
                    // FOr the current year, populate months arrage and this year value
                    element.Month = element.Month.substring(0, 3);
                    this.ForecastMonthsArray.push(element.Month);
                    this.ForecastThisYearValues.push(element.Count);
                }
                else{
                    this.ForecastPreviousYearValues.push(element.Count);
                }
                if (element.Count > maxValue) {
                    maxValue = element.Count;
                }
            });
            this.showLoadingIndicatorForForecast = false;
        }
        else {
            this.showNoMatterClosingForcastData = true;
            this.showLoadingIndicatorForForecast = false;
        }

        this.ForecastChartMaximumValue = maxValue + 10;
        this.ForecastChartIntervalValue = Math.round(this.ForecastChartMaximumValue / 4);
        const subscription = interval(100)
            .subscribe(() => {
                this.showLoadingIndicatorForForecast = false;
                this.drawForecastChart();
                subscription.unsubscribe();
            });
    }
    else {
        this.showLoadingIndicatorForForecast = false;
        this.showNoMatterClosingForcastData = true;
    }
}

// Processing values for matters opened widget
processOpeningForecastData(data: any) {
  this.openingForecastMonthsArray = [];
  this.openingForecastThisYearValues = [];
  this.openingForecastMonthsArray = [];
  this.openingForecastPreviousYearValues1 = [];
  this.openingForecastPreviousYearValues2 = [];
  let thisYear: any = new Date().getFullYear();
  let prevYear1: any = thisYear - 1;
  let prevYear2: any = prevYear1 - 1;
  let nextYear: any = thisYear + 1;

  //This Year Data
  var currentDate = new Date();
  const momentObject: any = moment(new Date()).toDate();
  const firstMomentObject: any = moment().subtract(1, 'months').toDate();
  const secondMomentObject: any = moment().subtract(2, 'months').toDate();
  const thirdMomentObject: any = moment().subtract(3, 'months').toDate();
  const fourthMomentObject: any = moment().subtract(4, 'months').toDate();
  const fifthMomentObject: any = moment().subtract(5, 'months').toDate();

  var currentMonth: any = momentObject.getMonth() + 1;
  var firstMonth: any = firstMomentObject.getMonth() + 1;
  var firstMonthYear: any = firstMomentObject.getFullYear();
  var secondMonth: any = secondMomentObject.getMonth() + 1;
  var thirdMonth: any = thirdMomentObject.getMonth() + 1;
  var fourthMonth: any = fourthMomentObject.getMonth() + 1;
  var fifthMonth: any = fifthMomentObject.getMonth() + 1;

  //Log Months
  var firstMonthValue: any = 0;
  var secondMonthValue: any = 0;
  var thirdMonthValue : any= 0;
  var fourthMonthValue: any = 0;
  var fifthMonthValue: any = 0;

  //Previous Year Data
  var prevCurrentMonthValue1: any = 0;
  var prevFirstMonthValue1: any = 0;
  var prevSecondMonthValue1: any = 0;
  var prevThirdMonthValue1: any = 0;
  var prevFourthMonthValue1: any = 0;
  var prevFifthMonthValue1: any = 0;

  var prevCurrentMonthValue2: any = 0;
  var prevFirstMonthValue2: any = 0;
  var prevSecondMonthValue2: any = 0;
  var prevThirdMonthValue2: any = 0;
  var prevFourthMonthValue2: any = 0;
  var prevFifthMonthValue2: any = 0;

  this.openingForecastThisYearString = thisYear + "";
  this.openingForecastPreviousYearString1 = prevYear1 + "";
  this.openingForecastPreviousYearString2 = prevYear2 + "";
  this.openingForecastNextYearString = nextYear + "";
  let maxValue: any = 0;
  if (data != null && data != undefined) {
      data.TransactionHistory.forEach((element: { year: string; month: number; matterType: string; transactionCount: string | number; }) => {
          if (element.year == firstMonthYear) {
              if (element.month == firstMonth) {
                  if (element.matterType == "Unity") {
                      this.thisMonthUnity += Number(element.transactionCount);
                  }
                  else if (element.matterType == "Conveyancer") {
                      this.thisMonthConveyancer += Number(element.transactionCount);
                  }
                  else if (element.matterType == "Fast Company") {
                      this.thisMonthFastCompany += Number(element.transactionCount);
                  }
                  else if (element.matterType == "Will Builder") {
                      this.thisMonthWillBuilder += Number(element.transactionCount);
                  }
                  else if (element.matterType == "Estate-a-Base") {
                      this.thisMonthEstateBase += Number(element.transactionCount);
                  }
              }
              if (element.matterType == "Conveyancer" || element.matterType == "Unity") {

                  if (element.month == firstMonth) {
                      firstMonthValue += element.transactionCount;
                  }
                  else if (element.month == secondMonth) {
                      secondMonthValue += element.transactionCount;
                  }
                  else if (element.month == thirdMonth) {
                      thirdMonthValue += element.transactionCount;
                  }
                  else if (element.month == fourthMonth) {
                      fourthMonthValue += element.transactionCount;
                  }
                  else if (element.month == fifthMonth) {
                      fifthMonthValue += element.transactionCount;
                  }

                  if (element.transactionCount > maxValue) {
                      maxValue = element.transactionCount;
                  }
              }
          }
          if (element.year == this.openingForecastPreviousYearString1) {
              if (element.matterType == "Conveyancer" || element.matterType == "Unity") {
                  if (element.month == currentMonth) {
                      prevCurrentMonthValue1 += element.transactionCount;
                  }
                  else if (element.month == firstMonth) {
                      prevFirstMonthValue1 += element.transactionCount;
                  }
                  else if (element.month == secondMonth) {
                      prevSecondMonthValue1 += element.transactionCount;
                  }
                  else if (element.month == thirdMonth) {
                      prevThirdMonthValue1 += element.transactionCount;
                  }
                  else if (element.month == fourthMonth) {
                      prevFourthMonthValue1 += element.transactionCount;
                  }
                  else if (element.month == fifthMonth) {
                      prevFifthMonthValue1 += element.transactionCount;
                  }

                  if (element.transactionCount > maxValue) {
                      maxValue = element.transactionCount;
                  }
              }
          }
          else if (element.year == this.openingForecastPreviousYearString2) {
              if (element.month > 6 && currentMonth < 6) {
                  if (element.matterType == "Conveyancer" || element.matterType == "Unity") {
                      if (element.month == currentMonth) {
                          prevCurrentMonthValue2 += element.transactionCount;
                      }
                      else if (element.month == firstMonth) {
                          prevFirstMonthValue2 += element.transactionCount;
                      }
                      else if (element.month == secondMonth) {
                          prevSecondMonthValue2 += element.transactionCount;
                      }
                      else if (element.month == thirdMonth) {
                          prevThirdMonthValue2 += element.transactionCount;
                      }
                      else if (element.month == fourthMonth) {
                          prevFourthMonthValue2 += element.transactionCount;
                      }
                      else if (element.month == fifthMonth) {
                          prevFifthMonthValue2 += element.transactionCount;
                      }

                      if (element.transactionCount > maxValue) {
                          maxValue = element.transactionCount;
                      }
                  }
              }
          }

          //Processing matters pie chart data
          if (element.year == this.openingForecastThisYearString) {
              if (element.matterType == "Unity") {
                  this.thisYearUnity += Number(element.transactionCount);
              }
              else if (element.matterType == "Conveyancer") {
                  this.thisYearConveyancer += Number(element.transactionCount);
              }
              else if (element.matterType == "Fast Company") {
                  this.thisYearFastCompany += Number(element.transactionCount);
              }
              else if (element.matterType == "Will Builder") {
                  this.thisYearWillBuilder += Number(element.transactionCount);
              }
              else if (element.matterType == "Estate-a-Base") {
                  this.thisYearEstateBase += Number(element.transactionCount);
              }
          }
      });
      if ((this.thisMonthConveyancer != null && this.thisMonthConveyancer != undefined && this.thisMonthConveyancer != 0)
          || (this.thisMonthFastCompany != null && this.thisMonthFastCompany != undefined && this.thisMonthFastCompany != 0)
          || (this.thisMonthWillBuilder != null && this.thisMonthWillBuilder != undefined && this.thisMonthWillBuilder != 0)
          || (this.thisMonthEstateBase != null && this.thisMonthEstateBase != undefined && this.thisMonthEstateBase != 0)
          || (this.thisMonthUnity != null && this.thisMonthUnity != undefined && this.thisMonthUnity != 0)) {
          this.showThisMonthNoTransaction = false;
      }
      else {
          this.showThisMonthNoTransaction = true;
      }
      if ((this.thisYearConveyancer != null && this.thisYearConveyancer != undefined && this.thisYearConveyancer != 0)
          || (this.thisYearFastCompany != null && this.thisYearFastCompany != undefined && this.thisYearFastCompany != 0)
          || (this.thisYearWillBuilder != null && this.thisYearWillBuilder != undefined && this.thisYearWillBuilder != 0)
          || (this.thisYearEstateBase != null && this.thisYearEstateBase != undefined && this.thisYearEstateBase != 0)
          || (this.thisYearUnity != null && this.thisYearUnity != undefined && this.thisYearUnity != 0)) {
          this.showThisYearNoTransaction = false;
      }
      else {
          this.showThisYearNoTransaction = true;
      }

      //Push Current Year Data for each month
      this.openingForecastMonthsArray.push(this.convertNumberToMonth(fifthMonth));
      this.openingForecastThisYearValues.push(fifthMonthValue);

      this.openingForecastMonthsArray.push(this.convertNumberToMonth(fourthMonth));
      this.openingForecastThisYearValues.push(fourthMonthValue);

      this.openingForecastMonthsArray.push(this.convertNumberToMonth(thirdMonth));
      this.openingForecastThisYearValues.push(thirdMonthValue);

      this.openingForecastMonthsArray.push(this.convertNumberToMonth(secondMonth));
      this.openingForecastThisYearValues.push(secondMonthValue);

      this.openingForecastMonthsArray.push(this.convertNumberToMonth(firstMonth));
      this.openingForecastThisYearValues.push(firstMonthValue);

      this.openingForecastMonthsArray.push(this.convertNumberToMonth(currentMonth));
      this.openingForecastThisYearValues.push(this.mattersOpenedCurrentMonthValue);

      //Push Previous Year data for each month
      this.openingForecastPreviousYearValues1.push(prevFifthMonthValue1);
      this.openingForecastPreviousYearValues1.push(prevFourthMonthValue1);
      this.openingForecastPreviousYearValues1.push(prevThirdMonthValue1);
      this.openingForecastPreviousYearValues1.push(prevSecondMonthValue1);
      this.openingForecastPreviousYearValues1.push(prevFirstMonthValue1);
      this.openingForecastPreviousYearValues1.push(prevCurrentMonthValue1);

      //Push Previous Year2 data for each month
      this.openingForecastPreviousYearValues2.push(prevFifthMonthValue2);
      this.openingForecastPreviousYearValues2.push(prevFourthMonthValue2);
      this.openingForecastPreviousYearValues2.push(prevThirdMonthValue2);
      this.openingForecastPreviousYearValues2.push(prevSecondMonthValue2);
      this.openingForecastPreviousYearValues2.push(prevFirstMonthValue2);
      this.openingForecastPreviousYearValues2.push(prevCurrentMonthValue2);
      this.openingForecastPreviousYearValues2.forEach(x => {
          if (x != null && x != undefined && currentMonth < 6) {
              this.showPrevYear2Bar = true;
          }
          if (x === 0) {
          }
      })
      this.openingForecastChartMaximumValue = maxValue + 10;
      this.openingForecastChartIntervalValue = Math.round(this.openingForecastChartMaximumValue / 4);
      const subscription = interval(100)
          .subscribe(() => {
              this.showLoadingIndicatorForOpeningForecast = false;
              this.drawOpeningForecastChart();
              this.drawTransactionPortfolio();
              subscription.unsubscribe();
          });
  }
}

//process and get current month data for matters opening chart
processCurrentMonthData(data: any) {
  if (data != null && data != undefined) {
      if (data.CurrentMonthFileOpenedCount != null && data.CurrentMonthFileOpenedCount != undefined) {
          this.mattersOpenedCurrentMonthValue = data.CurrentMonthFileOpenedCount;
      }
  }
}

// Processing values matters widget
processMatterData(data: any) {
    this.ClerkDataNamesArray = [];
    this.ClerkDataValuesArray = [];
    this.LawyerDataValuesArray = [];
    this.LawyerDataNamesArray = [];
    this.lawyerChartData = [];
    this.clerkChartData = [];
    this.cityChartData = [];
    this.CityDataNamesArray = [];
    this.CityDataValuesArray = [];

    if (data != null && data != undefined) {
        if (this.matterType == "Previous Month") {
            if (data.PreviousMonthLawyerData != null && data.PreviousMonthLawyerData != undefined) {
                this.lawyerChartData = data.PreviousMonthLawyerData;
            }
            if (data.PreviousMonthClerkData != null && data.PreviousMonthClerkData != undefined) {
                this.clerkChartData = data.PreviousMonthClerkData;
            }
            if (data.PreviousMonthCityData != null && data.PreviousMonthCityData != undefined) {
                this.cityChartData = data.PreviousMonthCityData.slice(0, 5);
            }
        }
        else if (this.matterType == "Next Month") {
            if (data.NextMonthLawyerData != null && data.NextMonthLawyerData != undefined) {
                this.lawyerChartData = data.NextMonthLawyerData;
            }
            if (data.NextMonthClerkData != null && data.NextMonthClerkData != undefined) {
                this.clerkChartData = data.NextMonthClerkData;
            }
            if (data.NextMonthCityData != null && data.NextMonthCityData != undefined) {
                this.cityChartData = data.NextMonthCityData.slice(0, 5);
            }
        }
        else {
            if (data.CurrentMonthLawyerData != null && data.CurrentMonthLawyerData != undefined) {
                this.lawyerChartData = data.CurrentMonthLawyerData;
            }
            if (data.CurrentMonthClerkData != null && data.CurrentMonthClerkData != undefined) {
                this.clerkChartData = data.CurrentMonthClerkData;
            }
            if (data.CurrentMonthCityData != null && data.CurrentMonthCityData != undefined) {
                this.cityChartData = data.CurrentMonthCityData.slice(0, 5);
            }
        }

        if (this.lawyerChartData != null && this.lawyerChartData != undefined) {
            this.lawyerChartEmptyView = this.lawyerChartData.length;
        }

        if (this.lawyerChartEmptyView == 0 || this.lawyerChartEmptyView == null || this.lawyerChartEmptyView == undefined) {
            this.showLawyerChart = true;
            this.showLoadingIndicatorForMatter = false;
        }
        else {
            this.showLawyerChart = false;
        }

        if (this.clerkChartData != null && this.clerkChartData != undefined) {
            this.clerkChartEmptyView = this.clerkChartData.length;
        }
        if (this.clerkChartEmptyView == 0 || this.clerkChartEmptyView == null || this.clerkChartEmptyView == undefined) {
            this.showClerkChart = true;
            this.showLoadingIndicatorForMatter = false;
        }
        else {
            this.showClerkChart = false;
        }
        if (this.cityChartData != null && this.cityChartData != undefined) {
            this.cityChartEmptyView = this.cityChartData.length;
        }
        if (this.cityChartEmptyView == 0 || this.cityChartEmptyView == null || this.cityChartEmptyView == undefined) {
            this.showCityChart = true;
            this.showLoadingIndicatorForMatter = false;
        }
        else {
            this.showCityChart = false;
        }

        if (this.clerkChartData != null) {
            let clerkName: string[] = [];

            this.clerkChartData.forEach(element => {
                if (element.name == null || element.name == "" || element.name == undefined) {
                    this.ClerkDataNamesArray.push("Unassigned");
                }
                else {
                    var currentClerkName = (((element.name.length > 10) ? (element.name.slice(0, 10)) + '..' : (element.name)));
                    if (clerkName.find(x => x === element.name)) {
                        let contactID = element.id.toString();
                        currentClerkName = currentClerkName.slice(0, 10);
                        currentClerkName = currentClerkName + "(" + contactID.charAt(contactID.length - 1) + ")";
                        element.name = currentClerkName;
                    }
                    else {
                        element.name = currentClerkName;
                        clerkName.push(currentClerkName);
                    }

                    this.ClerkDataNamesArray.push(currentClerkName);
                }
                this.ClerkDataValuesArray.push(element.count);
            });
        }

        if (this.lawyerChartData != null) {
            let lawyerName: string[] = [];
            this.lawyerChartData.forEach(element => {
                if (element.name == null || element.name == "" || element.name == undefined) {
                    this.LawyerDataNamesArray.push("Unassigned");
                }
                else {
                    var currentLawyerName = (((element.name.length > 10) ? (element.name.slice(0, 10)) + '..' : (element.name)));

                    if (lawyerName.find(x => x === currentLawyerName)) {
                        let contactID = element.id.toString();
                        currentLawyerName = currentLawyerName.slice(0, 10);
                        currentLawyerName = currentLawyerName + "(" + contactID.charAt(contactID.length - 1) + ")";
                        element.name = currentLawyerName;
                    }
                    else {
                        element.name = currentLawyerName;
                        lawyerName.push(currentLawyerName);
                    }
                    this.LawyerDataNamesArray.push(currentLawyerName);
                }
                this.LawyerDataValuesArray.push(element.count);
            });
        }

        if (this.cityChartData != null) {
            let cityName: string[] = [];
            this.cityChartData.forEach(element => {
                if (element.name == null || element.name == "" || element.name == undefined) {
                    this.CityDataNamesArray.push("Unassigned");
                }
                else {
                    var currentCityName = (((element.name.length > 10) ? (element.name.slice(0, 10)) + '..' : (element.name)));

                    if (cityName.find(x => x === currentCityName)) {
                        let contactID = element.id.toString();
                        currentCityName = currentCityName.slice(0, 10);
                        currentCityName = currentCityName + "(" + contactID.charAt(contactID.length - 1) + ")";
                        element.name = currentCityName;
                    }
                    else {
                        element.name = currentCityName;
                        cityName.push(currentCityName);
                    }
                    this.CityDataNamesArray.push(currentCityName);
                }
                this.CityDataValuesArray.push(element.count);
            });
        }

        const subscription = interval(100)
            .subscribe(() => {
                this.showLoadingIndicatorForMatter = false;
                this.drawClerkAndLawyerChart();
                subscription.unsubscribe();
            });
    }
}

// Processing values for matter types widget
processMatterTypesData(data: any) {
    this.thisYearMatterTypesData = [];
    this.pastMonthMatterTypesData = [];

    if (data != null && data != undefined) {

        if (data.PastMonthMatterTypes != null && data.PastMonthMatterTypes != undefined) {
            // Reorder the Data in Custom Order
            var ordering: any = {},
                sortOrder = ['Purchase','Sale','Mortgage', 'Project Sale', 'Custom'];
            for (var i=0; i<sortOrder.length; i++)
                ordering[sortOrder[i]] = i;
            data.PastMonthMatterTypes.sort( function(a: any, b: any) {
                return (ordering[a.mattertype] - ordering[b.mattertype]);
            });

            // Custom Format the data
            data.PastMonthMatterTypes.forEach((element: any) => {
                let matterType = element.mattertype;
                let matterCount = element.count;
                this.pastMonthMatterTypesData.push([matterType + ` (${matterCount})`, matterCount]);
            });
        }
        if (data.ThisYearMatterTypes != null && data.ThisYearMatterTypes != undefined) {
            // Reorder the Data in Custom Order
            var ordering: any = {},
                sortOrder = ['Purchase','Sale','Mortgage', 'Project Sale', 'Custom'];
            for (var i=0; i<sortOrder.length; i++)
                ordering[sortOrder[i]] = i;
            data.ThisYearMatterTypes.sort( function(a: any, b: any) {
                return (ordering[a.mattertype] - ordering[b.mattertype]);
            });

            // Custom Format the data
            data.ThisYearMatterTypes.forEach((element: any) => {
                let matterType = element.mattertype;
                let matterCount = element.count;
                this.thisYearMatterTypesData.push([matterType + ` (${matterCount})`, matterCount]);
            });
        }
    }

    this.drawMatterTypesChart();
}

// Process referals chart data
processReferalsData(data: any) {
    // For referals by names
    this.thisMonthReferalsNamesArray = [];
    this.thisYearReferalsNamesArray = [];
    this.thisMonthReferalsValuesArray = [];
    this.thisYearReferalsValuesArray = [];

    // For referals by category
    this.thisMonthReferalsCategoryNamesArray = [];
    this.thisYearReferalsCategoryNamesArray = [];
    this.thisMonthReferalsCategoryValuesArray = [];
    this.thisYearReferalsCategoryValuesArray = [];

    if (data != null && data != undefined) {
        // For referals by names
        if (data.ThisMonthReferals != null && data.ThisMonthReferals != undefined) {
            this.thisMonthReferalsData = data.ThisMonthReferals;
        }
        else {
            this.showNoReferralsByCategory = true;
        }
        if (this.thisMonthReferalsData.length >= 0) {
            this.showLoadingIndicatorForReferrals = false;
        }
        if (this.thisMonthReferalsData.length == 0) {
            this.showThisMonthReferals = true;
        }

        if (this.referralType == "Current Month") {
            this.referralsByName = data.ThisMonthReferals;
            this.referralsByCategory = data.ThisMonthReferalsCategory;
        }
        else if (this.referralType == "Yearly") {
            if (data.ThisYearReferals != null && data.ThisYearReferals != undefined) {
                this.referralsByName = data.ThisYearReferals;
                this.showNoReferralsByName = false;
            }
            else {
                this.showNoReferralsByName = true;
            }
            if (data.ThisYearReferalsCategory != null && data.ThisYearReferalsCategory != undefined) {
                this.referralsByCategory = data.ThisYearReferalsCategory;
                this.showNoReferralsByCategory = false;
            }
            else {
                this.showNoReferralsByCategory = true;
            }
        }
        if (this.referralsByName.length == 0 || this.referralsByName == null || this.referralsByName == undefined) {
            this.showNoReferralsByName = true;
        }
        if (this.referralsByCategory.length == 0 || this.referralsByCategory == null || this.referralsByCategory == undefined) {
            this.showNoReferralsByCategory = true;
        }

        // //New Logic To Hide entire widget if no referrals (both)
        // if(!this.showNoReferralsByName && !this.showNoReferralsByCategory){
        //     this.ShowReferalsWidget = true;
        // }
         // Update to above logic (//New Logic To Hide entire widget if no referrals (both))
         if((data.ThisMonthReferals != undefined && data.ThisMonthReferals != null && data.ThisMonthReferals.length > 0)
         ||
         (data.ThisYearReferals != undefined && data.ThisYearReferals != null && data.ThisYearReferals.length > 0))
        {
            this.ShowReferalsWidget = true;
        }
        // For referals by category
        if (data.ThisMonthReferalsCategory != null && data.ThisMonthReferalsCategory != undefined) {
            this.thisMonthReferalsCategoryData = data.ThisMonthReferalsCategory;
        }
        if (data.thisYearReferalsCategoryData != null && data.thisYearReferalsCategoryData != undefined) {
            this.thisYearReferalsCategoryData = data.ThisYearReferalsCategory;
        }

        if (this.thisMonthReferalsCategoryData.length >= 0) {
            this.showLoadingIndicatorForReferrals = false;
        }
        if (this.thisYearReferalsCategoryData.length >= 0) {
            this.showLoadingIndicatorForReferrals = false;
        }

        var topValues = this.thisYearReferalsCategoryData.sort((a, b) => b.count - a.count).slice(0, 5);
        this.thisYearReferalsCategoryData = topValues;

        //JSON.stringify(this.thisYearReferalsCategoryData);

        this.thisYearReferalsCategoryData.forEach(element => {
            element.name = (((element.name.length > 25) ? (element.name.slice(0, 20)) + '..' : (element.name)));
        });
    }
    else {
        this.showThisMonthReferals = true;
        this.showNoReferralsByName = true;
        this.showNoReferralsByCategory = true;
        this.showThisMonthReferalsCategory = true;
        this.showThisYearReferalsCategory = true;
        this.showLoadingIndicatorForReferrals = false;
    }
}

// Processing values for closed matter revenue widget
processMatterRevenueData(data: any) {
    let matterMaxValue = 0;
    let feesMaxValue = 0;

    if (data != null && data != undefined) {
        this.showRevenue = true;
        this.revenueMonth = [];
        this.revenueMatter = [];
        this.revenueFees = [];

        data.forEach((element: { Matters: number; Fees: number; Month: string; }) => {
            if (element.Matters > matterMaxValue) {
                matterMaxValue = element.Matters;
            }
            if (element.Fees > feesMaxValue) {
                feesMaxValue = element.Fees;
            }

            this.revenueMonth.push(element.Month.substring(0, 3));
            this.revenueMatter.push(element.Matters);
            this.revenueFees.push(element.Fees);
        });
        var matter = Math.round(matterMaxValue);
        var fees = Math.round(feesMaxValue);

        this.revenueMatterInterval = Math.round((matter) / 4);
        this.revenueFeesInterval = Math.round((fees) / 4);

        const subscription = interval(100)
            .subscribe(() => {
                this.showLoadingIndicatorForForecast = false;
                this.drawMatterRevenue();
                subscription.unsubscribe();
            });
    }
    else {
        this.showRevenue = false;
    }
}

// Assign values for closing forecast and drawing the chart
drawForecastChart() {
  try {
      let closingForecast:
          any = {
          chart: {
              backgroundColor: 'transparent',
              type: 'line',
          },
          title: {
              text: null
          },
          xAxis: {
              gridLineWidth: 1,
              lineWidth: 1,
              lineColor: '#E0E0E0',
              tickColor: 'transparent',
              gridLineColor: '#E0E0E0',
              categories: this.ForecastMonthsArray,
              crosshair: true,
              labels: {
                  style: {
                      color: '#616161',
                      fontSize: '15px'
                  }
              }
          },
          yAxis: {
              gridLineWidth: 1,
              lineWidth: 1,
              lineColor: '#E0E0E0',
              tickColor: 'transparent',
              gridLineColor: '#E0E0E0',
              min: 0,
              title: {
                  text: null
              },
              tickInterval: this.ForecastChartIntervalValue,
              labels: {
                  style: {
                      color: '#616161',
                      fontSize: '15px'
                  }
              }
          },
          plotOptions: {
              column: {
                  pointPadding: 0,
                  borderWidth: 0,
                  pointWidth: 20
              },
              series: {
                  events: {
                      legendItemClick: function (e: any) {
                          e.preventDefault();
                      }
                  }
              },
              allowPointSelect: false
          },
          credits: {
              enabled: false
          },
          legend: {
              itemStyle: {
                  color: '#616161'
              },
              symbolHeight: 14,
              symbolWidth: 14,
              symbolRadius: 2,
              align: 'right'
          },
          series: [
              {
                  showInLegend: true,
                  name: this.ForecastPreviousYearString,
                  data: this.ForecastPreviousYearValues, color: '#4482E6'
              },
              {
                  showInLegend: true,
                  name: this.ForecastThisYearString,
                  data: this.ForecastThisYearValues, color: '#3ABCBF'
              }
          ]
      };

      this.chart = Highcharts.chart(this.ClosingForecast.nativeElement, closingForecast);
  }
  catch
  {

  }
}

// Assign values for matters opened and drawing the chart
drawOpeningForecastChart() {
  try {
      let openingForecast:
          any = {
          chart: {
              backgroundColor: 'transparent',
              type: 'column',
          },
          title: {
              text: null
          },
          xAxis: {
              gridLineWidth: 1,
              lineWidth: 1,
              lineColor: '#E0E0E0',
              tickColor: 'transparent',
              gridLineColor: '#E0E0E0',
              categories: this.openingForecastMonthsArray,
              crosshair: true,
              labels: {
                  style: {
                      color: '#616161',
                      fontSize: '15px'
                  }
              }
          },
          yAxis: {
              gridLineWidth: 1,
              lineWidth: 1,
              lineColor: '#E0E0E0',
              tickColor: 'transparent',
              gridLineColor: '#E0E0E0',
              min: 0,
              title: {
                  text: null
              },
              tickInterval: this.openingForecastChartIntervalValue,
              labels: {
                  style: {
                      color: '#616161',
                      fontSize: '15px'
                  }
              }
          },
          plotOptions: {
              column: {
                  pointPadding: 0,
                  borderWidth: 0,
                  pointWidth: 14
              },
              series: {
                  pointPadding: 0,
                  events: {
                      legendItemClick: function (e: any) {
                          e.preventDefault();
                      }
                  }
              },
              allowPointSelect: false
          },
          credits: {
              enabled: false
          },
          legend: {
              itemStyle: {
                  color: '#616161'
              },
              symbolHeight: 14,
              symbolWidth: 14,
              symbolRadius: 2,
              align: 'right'
          },
          series: (this.showPrevYear2Bar == true ? [{
              name: this.openingForecastPreviousYearString2,
              data: this.openingForecastPreviousYearValues2, color: '#4482E6'
          }, {
              name: this.openingForecastPreviousYearString1,
              data: this.openingForecastPreviousYearValues1, color: ''
          }, {
              name: this.openingForecastThisYearString,
              data: this.openingForecastThisYearValues, color: '#3ABCBF'
          }] :
              [{
                  name: this.openingForecastPreviousYearString1,
                  data: this.openingForecastPreviousYearValues1, color: '#4482E6'
              }, {
                  name: this.openingForecastThisYearString,
                  data: this.openingForecastThisYearValues, color: '#3ABCBF'
              }]
          )
      };

      Highcharts.chart(this.OpeningForecast.nativeElement, openingForecast);
  }
  catch
  {

  }
}

// Assign values for Transaction portfolio and drawing the chart
drawTransactionPortfolio() {
  try {
      //Gather Data
      this.thisMonthData = [];
      var thisMonthColor = [];
      if (this.thisMonthUnity != null && this.thisMonthUnity != undefined && this.thisMonthUnity != 0) {
          this.thisMonthData.push(['Unity (' + this.thisMonthUnity + ')', this.thisMonthUnity]);
          thisMonthColor.push('#CC3369');
      }
      if (this.thisMonthConveyancer != null && this.thisMonthConveyancer != undefined && this.thisMonthConveyancer != 0) {
          this.thisMonthData.push(['Conveyancer (' + this.thisMonthConveyancer + ')', this.thisMonthConveyancer]);
          thisMonthColor.push('#4482E6');
      }
      if (this.thisMonthEstateBase != null && this.thisMonthEstateBase != undefined && this.thisMonthEstateBase != 0) {
          this.thisMonthData.push(['Estate-E-Base (' + this.thisMonthEstateBase + ')', this.thisMonthEstateBase]);
          thisMonthColor.push('#5E5DDD');
      }
      if (this.thisMonthFastCompany != null && this.thisMonthFastCompany != undefined && this.thisMonthFastCompany != 0) {
          this.thisMonthData.push(['Fast Company (' + this.thisMonthFastCompany + ')', this.thisMonthFastCompany]);
          thisMonthColor.push('#A05E94');
      }
      if (this.thisMonthWillBuilder != null && this.thisMonthWillBuilder != undefined && this.thisMonthWillBuilder != 0) {
          this.thisMonthData.push(['Will Builder (' + this.thisMonthWillBuilder + ')', this.thisMonthWillBuilder]);
          thisMonthColor.push('#3ABCBF');
      }

      //Gather Data
      this.thisYearData = [];
      var thisYearsColor = [];
      if (this.thisYearUnity != null && this.thisYearUnity != undefined && this.thisYearUnity != 0) {
          this.thisYearData.push(['Unity (' + this.thisYearUnity + ')', this.thisYearUnity]);
          //this.thisYearData.push({"name":'Unity (' + this.thisYearUnity + ')', "y":this.thisYearUnity});
          thisYearsColor.push('#CC3369');
      }
      if (this.thisYearConveyancer != null && this.thisYearConveyancer != undefined && this.thisYearConveyancer != 0) {
          this.thisYearData.push(['Conveyancer (' + this.thisYearConveyancer + ')', this.thisYearConveyancer]);
          //this.thisYearData.push({"name":'Conveyancer (' + this.thisYearConveyancer + ')', "y":this.thisYearConveyancer});
          thisYearsColor.push('#4482E6');
      }
      if (this.thisYearEstateBase != null && this.thisYearEstateBase != undefined && this.thisYearEstateBase != 0) {
          this.thisYearData.push(['Estate-E-Base (' + this.thisYearEstateBase + ')', this.thisYearEstateBase]);
          //this.thisYearData.push({"name":'Estate-E-Base (' + this.thisYearEstateBase + ')', "y": this.thisYearEstateBase});
          thisYearsColor.push('#5E5DDD');
      }
      if (this.thisYearFastCompany != null && this.thisYearFastCompany != undefined && this.thisYearFastCompany != 0) {
          this.thisYearData.push(['Fast Company (' + this.thisYearFastCompany + ')', this.thisYearFastCompany]);
          //this.thisYearData.push({"name":'Fast Company (' + this.thisYearFastCompany + ')', "y":this.thisYearFastCompany});
          thisYearsColor.push('#A05E94');
      }
      if (this.thisYearWillBuilder != null && this.thisYearWillBuilder != undefined && this.thisYearWillBuilder != 0) {
          this.thisYearData.push(['Will Builder (' + this.thisYearWillBuilder + ')', this.thisYearWillBuilder]);
          //this.thisYearData.push({"name":'Will Builder (' + this.thisYearWillBuilder + ')', "y":this.thisYearWillBuilder});
          thisYearsColor.push('#3ABCBF');
      }

      let monthlyTransaction:
          any = {

          chart: {
              height: 200,
              type: 'pie',
              backgroundColor: 'transparent'
          },
          title: {
              text: null
          },
          plotOptions: {
              pie: {
                  point: {
                      events: {
                          legendItemClick: function (e: any) {
                              e.preventDefault();
                          }
                      }
                  },
                  colors: thisMonthColor,
                  dataLabels: {
                      enabled: true,
                      distance: -50,

                      style: {
                          fontWeight: 'bold',
                          color: 'white',
                          textShadow: '0px 1px 2px black'
                      }
                  },
                  startAngle: -180,
                  endAngle: 180,
                  center: ['53%', '52%'],
                  borderWidth: 0
              },
              series: {
                  tooltip: {
                      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                  }
              }
          },
          legend: {
              align: 'right',
              layout: 'vertical',
              verticalAlign: 'middle',
              squareSymbol: false,
              symbolWidth: 12,
              symbolRadius: 0,
              itemMarginBottom: 7,
              itemStyle: {
                  color: '#616161'
              },
              x: 12,
              y: 0,
          },
          credits: {
              enabled: false
          },
          series: [{
              type: 'pie',
              innerSize: '50%',
              data: this.thisMonthData,
              size: '120%',
              showInLegend: true,
              dataLabels: {
                  enabled: false
              }
          }]
      }

      let yearlyTransaction:
          any = {
          chart: {
              height: 200,
              type: 'pie',
              backgroundColor: 'transparent'
          },
          title: {
              text: null
          },
          plotOptions: {
              pie: {
                  point: {
                      events: {
                          legendItemClick: function (e: any) {
                              e.preventDefault();
                          }
                      }
                  },
                  colors: thisYearsColor,
                  allowPointSelect: false,
                  dataLabels: {
                      enabled: true,
                      style: {
                          fontWeight: 'bold',
                          color: 'white',
                          textShadow: '0px 1px 2px black'
                      }
                  },
                  startAngle: -180,
                  endAngle: 180,
                  center: ['53%', '52%'],
                  borderWidth: 0
              },
              series: {
                  tooltip: {
                      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                  }
              }
          },
          legend: {
              align: 'right',
              layout: 'vertical',
              verticalAlign: 'middle',
              squareSymbol: false,
              symbolWidth: 12,
              symbolRadius: 0,
              itemMarginBottom: 7,
              itemStyle: {
                  color: '#616161'
              },
              x: 18,
              y: 0,
          },
          credits: {
              enabled: false
          },
          series: [
              {
                  name: "Percentage",
                  data: this.thisYearData,
                  type: 'pie',
                  innerSize: '50%',
                  size: '120%',
                  showInLegend: true,
                  dataLabels: {
                      enabled: false
                  }
              }]
      }


      if(!this.showThisMonthNoTransaction){
          this.chart = Highcharts.chart(this.thisMonthTransaction.nativeElement, monthlyTransaction);
          // this.chart = chart(this.thisMonthTransaction?.nativeElement, opt);
          // Highcharts.chart(this.thisMonthTransaction?.nativeElement, opt);
      }
      if (!this.showThisYearNoTransaction) {
          //this.chart = chart(this.thisYearTransaction?.nativeElement, yearlyTransaction);
          this.chart = Highcharts.chart(this.thisYearTransaction.nativeElement, yearlyTransaction);
      }

      //For Transaction Widget Visibility
      if(this.showThisMonthNoTransaction && this.showThisYearNoTransaction){
          //Both year and month has no values so don't show
          this.ShowTransactionsWidget = false;
          //console.log("Transaction Widget Visibility Status", "Both year and month has no values so don't show");
      }
      else{
          if((this.thisMonthData.length == 1 && this.thisMonthData.some(x => x[0].startsWith("Unity")))
              &&
              (this.thisMonthData.length == 1 && this.thisMonthData.some(x => x[0].startsWith("Unity")))){
                  // Both month & year data has unity only, so don't show.
                  this.ShowTransactionsWidget = false;
                  //console.log("Transaction Widget Visibility Status", "Both month & year data has unity only, so don't show.");
          }
          else{
              //Show since it has more meaningful data
              this.ShowTransactionsWidget = true;
              //console.log("Transaction Widget Visibility Status", "Show");
          }
      }

  }
  catch
  {
      console.error("Error")
  }
}

// Assign values for Matter widget (By clerk) and drawing the chart
drawClerkEventsChart() {
    try {
        this.getClerkChartHeight();
        let clerk: any = {
            chart: {
                backgroundColor: 'transparent',
                type: 'bar',
                height: this.clerkChartHeight + '%'
            },
            title: {
                text: null
            },
            xAxis: {
                categories: this.ClerkDataNamesArray,
                lineWidth: 1,
                allowDecimals: false,
                lineColor: '#E0E0E0',
                gridLineColor: '#E0E0E0',
                tickColor: 'transparent',
                labels: {
                    style: {
                        color: '#616161',
                        fontSize: '15px'
                    }
                }
            },
            yAxis: {
                gridLineWidth: 1,
                lineWidth: 1,
                allowDecimals: false,
                lineColor: '#E0E0E0',
                gridLineColor: '#E0E0E0',
                tickColor: 'transparent',
                min: 0,
                title: {
                    text: null
                },
                labels: {
                    style: {
                        color: '#616161',
                        fontSize: '15px'
                    }
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    cursor: 'pointer',
                    pointWidth: 16
                }
            }, credits: {
                enabled: false
            },
            tooltip: { enabled: false },
            series: [{
                showInLegend: false,
                name: 'John',
                data: this.ClerkDataValuesArray, color: '#4482E6', borderWidth: 0
            }]
        };
        if(this.clerkChart !== undefined)
        this.chart = Highcharts.chart(this.clerkChart.nativeElement, clerk);
    }
    catch
    {

    }
}

// Assign values for Matter widget (By lawyer) and drawing the chart
drawLawyerEventsChart() {
    try {
        this.getLawyerChartHeight();
        let lawyer: any = {
            chart: {
                backgroundColor: 'transparent',
                type: 'bar',
                height: this.lawyerChartHeight + '%'
            },
            title: {
                text: null
            },
            xAxis: {
                categories: this.LawyerDataNamesArray,
                lineWidth: 1,
                lineColor: '#E0E0E0',
                gridLineColor: '#E0E0E0',
                tickColor: 'transparent',
                allowDecimals: false,
                labels: {
                    style: {
                        color: '#616161',
                        fontSize: '15px'
                    }
                }
            },
            yAxis: {
                gridLineWidth: 1,
                lineWidth: 1,
                lineColor: '#E0E0E0',
                gridLineColor: '#E0E0E0',
                tickColor: 'transparent',
                allowDecimals: false,
                min: 0,
                title: {
                    text: null
                },
                labels: {
                    style: {
                        color: '#616161',
                        fontSize: '15px'
                    }
                }
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    grouping: true,
                    pointWidth: 16,
                    cursor: 'pointer'
                }
            }, credits: {
                enabled: false
            },
            tooltip: { enabled: false },
            series: [{
                showInLegend: false,
                name: 'John',
                data: this.LawyerDataValuesArray,
                color: '#4482E6', borderWidth: 0
            }]
        };
        if(this.lawyerChart !== undefined)
        this.chart = Highcharts.chart(this.lawyerChart.nativeElement, lawyer);
    }
    catch
    {

    }
}

// Assign values for Matter widget (By lawyer) and drawing the chart
drawCityEventsChart() {
    try {
        this.getCityChartHeight();
        let city: any = {
            chart: {
                backgroundColor: 'transparent',
                type: 'bar',
                height: this.cityChartHeight + '%'
            },
            title: {
                text: null
            },
            xAxis: {
                categories: this.CityDataNamesArray,
                lineWidth: 1,
                lineColor: '#E0E0E0',
                gridLineColor: '#E0E0E0',
                tickColor: 'transparent',
                allowDecimals: false,
                labels: {
                    style: {
                        color: '#616161',
                        fontSize: '15px'
                    }
                }
            },
            yAxis: {
                gridLineWidth: 1,
                lineWidth: 1,
                lineColor: '#E0E0E0',
                gridLineColor: '#E0E0E0',
                tickColor: 'transparent',
                allowDecimals: false,
                min: 0,
                title: {
                    text: null
                },
                labels: {
                    style: {
                        color: '#616161',
                        fontSize: '15px'
                    }
                }
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    grouping: true,
                    pointWidth: 16,
                    cursor: 'pointer'
                }
            }, credits: {
                enabled: false
            },
            tooltip: { enabled: false },
            series: [{
                showInLegend: false,
                name: 'John',
                data: this.CityDataValuesArray,
                color: '#4482E6', borderWidth: 0
            }]
        };
      if(this.cityChart !== undefined)
        this.chart = Highcharts.chart(this.cityChart.nativeElement, city);
    }
    catch
    {

    }
}

// Check condition whether the chart has values or not and draw the chart if it has values
drawClerkAndLawyerChart() {
    // if (this.clerkChartEmptyView != 0 && this.clerkChartEmptyView != null && this.clerkChartEmptyView != undefined) {
    if (this.clerkChartEmptyView != null && this.clerkChartEmptyView != undefined) {
        this.drawClerkEventsChart();
    }
    // if (this.lawyerChartEmptyView != 0 && this.lawyerChartEmptyView != null && this.lawyerChartEmptyView != undefined) {
    if (this.lawyerChartEmptyView != null && this.lawyerChartEmptyView != undefined) {
        this.drawLawyerEventsChart();
    }
    // if (this.cityChartEmptyView != 0 && this.cityChartEmptyView != null && this.cityChartEmptyView != undefined) {
    if (this.cityChartEmptyView != null && this.cityChartEmptyView != undefined) {
        this.drawCityEventsChart();
    }
}


    // Dra Matter Types Chart
    drawMatterTypesChart(){
        let chartColors = ["#CC3369","#4482E6","#5E5DDD","#A05E94","#3ABCBF"];
        let pastMonthModel:
            any = {
            chart: {
                height: 200,
                type: 'pie',
                backgroundColor: 'transparent'
            },
            title: {
                text: null
            },
            plotOptions: {
                pie: {
                    point: {
                        events: {
                            legendItemClick: function (e: any) {
                                e.preventDefault();
                            }
                        }
                    },
                    colors: chartColors,
                    allowPointSelect: false,
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: 'white',
                            textShadow: '0px 1px 2px black'
                        }
                    },
                    startAngle: -180,
                    endAngle: 180,
                    center: ['53%', '52%'],
                    borderWidth: 0
                },
                series: {
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    }
                }
            },
            legend: {
                align: 'right',
                layout: 'vertical',
                verticalAlign: 'middle',
                squareSymbol: false,
                symbolWidth: 14,
                symbolHeight: 14,
                symbolRadius: 2,
                itemMarginBottom: 7,
                itemStyle: {
                    color: '#616161'
                },
                x: 18,
                y: 0,
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "Percentage",
                    data: this.pastMonthMatterTypesData,
                    type: 'pie',
                    innerSize: '50%',
                    size: '120%',
                    showInLegend: true,
                    dataLabels: {
                        enabled: false
                    }
                }]
        };

        let yearModel:
            any = {
            chart: {
                height: 200,
                type: 'pie',
                backgroundColor: 'transparent'
            },
            title: {
                text: null
            },
            plotOptions: {
                pie: {
                    point: {
                        events: {
                            legendItemClick: function (e: any) {
                                e.preventDefault();
                            }
                        }
                    },
                    colors: chartColors,
                    allowPointSelect: false,
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: 'white',
                            textShadow: '0px 1px 2px black'
                        }
                    },
                    startAngle: -180,
                    endAngle: 180,
                    center: ['53%', '52%'],
                    borderWidth: 0
                },
                series: {
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    }
                }
            },
            legend: {
                align: 'right',
                layout: 'vertical',
                verticalAlign: 'middle',
                squareSymbol: false,
                symbolWidth: 14,
                symbolHeight: 14,
                symbolRadius: 2,
                itemMarginBottom: 7,
                itemStyle: {
                    color: '#616161'
                },
                x: 18,
                y: 0,
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "Percentage",
                    data: this.thisYearMatterTypesData,
                    type: 'pie',
                    innerSize: '50%',
                    size: '120%',
                    showInLegend: true,
                    dataLabels: {
                        enabled: false
                    }
                }]
        };

        Highcharts.chart(this.pastMonthMatterTypes.nativeElement, pastMonthModel);
        Highcharts.chart(this.thisYearMatterTypes.nativeElement, yearModel);
    }

    // Assign values for Closed matter revenue and drawing the chart
    drawMatterRevenue() {
        try {
            let revenue: any = {
                chart: {
                    backgroundColor: 'transparent',
                    zoomType: 'xy'
                },
                title: {
                    text: null
                },
                xAxis: [{
                    gridLineWidth: 0,
                    lineWidth: 1,
                    lineColor: '#E0E0E0',
                    tickColor: 'transparent',
                    gridLineColor: 'transparant',
                    min: 0,
                    title: {
                        text: null
                    },
                    categories: this.revenueMonth,
                    crosshair: true,
                    labels: {
                        style: {
                            color: '#616161',
                            fontSize: '15px'
                        }
                    }
                }],
                yAxis: [{ // Primary yAxis
                    gridLineWidth: 0,
                    lineWidth: 0,
                    lineColor: '#E0E0E0',
                    tickColor: 'transparent',
                    tickInterval: this.revenueFeesInterval,
                    gridLineColor: 'transparent',
                    labels: {
                        format: '${value}',
                        style: {
                            color: '#616161',
                            fontSize: '15px'
                        }
                    },
                    opposite: true,
                    title: {
                        text: null
                    }
                },
                { // Secondary Y axis
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: '#E0E0E0',
                    tickColor: 'transparent',
                    tickInterval: this.revenueMatterInterval,
                    labels: {
                        style: {
                            color: '#616161',
                            fontSize: '15px'
                        }
                    },
                    gridLineColor: '#E0E0E0',
                    title: {
                        text: null
                    }
                }],
                plotOptions: {
                    column: {
                        pointPadding: 0,
                        borderWidth: 0,
                        pointWidth: 20
                    },
                    series: {
                        events: {
                            legendItemClick: function (e: any) {
                                e.preventDefault();
                            }
                        }
                    },
                    allowPointSelect: false
                },
                credits: {
                    enabled: false
                },
                legend: {
                    itemStyle: {
                        color: '#616161'
                    },
                    align: 'right',
                    symbolHeight: 14,
                    symbolWidth: 14,
                    symbolRadius: 2
                },
                series: [{
                    showInLegend: true,
                    type: 'column',
                    name: 'Matter Closed',
                    yAxis: 1,
                    data: this.revenueMatter,
                    color: '#4482E6'
                }, {
                    showInLegend: true,
                    name: 'Fees Collected',
                    type: 'spline',
                    data: this.revenueFees,
                    color: '#3ABCBF',
                    tooltip: {
                        valuePrefix: '$',
                    }
                }]
            };
            if (this.MatterRevenue != null) {
                this.chart = Highcharts.chart(this.MatterRevenue.nativeElement, revenue);
            }
        }
        catch
        {

        }
    }
}
