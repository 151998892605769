import {Directive, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {PasswordRegexConstant} from './password-regex-constant';

@Directive({
               selector  : '[password][ngModel]',
               providers : [
                   {provide : NG_VALIDATORS, useExisting : forwardRef(() => PasswordValidator), multi : true}
               ]
           })
export class PasswordValidator implements Validator {

    validate(c : AbstractControl) : {[key : string] : any} {
        if(!c.value) {
            return null;
        } // Assume valid

        let allowedChars : RegExp = PasswordRegexConstant.ALLOWED_CHARS_REGEX;
        let oneUppercase : RegExp = PasswordRegexConstant.ONE_UPPER_CASE_REGEX;
        let oneNumber : RegExp = PasswordRegexConstant.ONE_NUMBER_CASE_REGEX;
        let oneSpecial : RegExp = PasswordRegexConstant.ONE_SPECIAL_REGEX;
        // var consecChars : RegExp = /([A-Za-z0-9!"#\$%&'\(\)\*\+,\-\./:;<=>\?@\[\\\]\^_`\{\|\}~])\1{2,}/i;
        let consecChars : RegExp = PasswordRegexConstant.CONSEC_CHAR_CHECK_REGEX;

        let errorMessages : string[] = [];

        if(c.value.length < 8) {
            errorMessages.push("Password must be at least 8 characters in length or greater");
        }

        if(!allowedChars.test(c.value)) {
            errorMessages.push("No spaces allowed. Allowed characters are: A to Z, a to z, 0 to 9 and special characters");
        }

        if(!oneUppercase.test(c.value)) {
            errorMessages.push("Password must have at least one uppercase letter");
        }

        if(!oneNumber.test(c.value)) {
            errorMessages.push("Password must have at least one number");
        }

        if(!oneSpecial.test(c.value)) {
            errorMessages.push("Password must have at least one special character");
        }

        if(consecChars.test(c.value)) {
            errorMessages.push("Password cannot have more than 2 consecutive characters being the same letter, number or special character");
        }

        if(errorMessages.length == 0) {
            return null; // Valid
        } else {
            return {
                password : {
                    valid  : false,
                    errors : errorMessages
                }
            };
        }
    }
}
