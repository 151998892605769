import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {Document} from './document';
import {DocumentProductionService} from './document-production.service';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Subscription} from 'rxjs';
import {ITreeOptions, TreeComponent, TreeNode} from '@circlon/angular-tree-component';

declare var jQuery : any;
class DpCopyAndRenameModalContext extends BSModalContext {
    public document : Document;
    public matterId : number;
    public action : string;
    matterDocuments : Document[];
    nodeData: any;
    documentNodes : any[];
}
@AutoUnsubscribe()
@Component({
               selector    : 'dp-copy-rename-modal-content',
               templateUrl : './dp-copy-rename-modal.component.html',
               providers   : [ErrorService, DocumentProductionService]
           })
export class DpCopyAndRenameModalComponent implements ModalComponent<DpCopyAndRenameModalContext>, AfterViewInit {
    @ViewChild('modalErrorComponent') modalErrorComponent : ModalErrorComponent;
    context : DpCopyAndRenameModalContext;
    fileName : string;
    fullFileName : any;
    fileExtension : string;
    description: string;
    renameSub : Subscription;
    copySub : Subscription;
    documentList : any = [];
    options: ITreeOptions = {
        allowDrag: (node) => {
            return false;
        }
    }
    documentCopyNodes: any[] = [];
    @ViewChild(TreeComponent)
    private tree: TreeComponent;
    constructor(public dialog : DialogRef<DpCopyAndRenameModalContext>, public documentProductionService : DocumentProductionService) {
        this.context = dialog.context;
    }

    ngOnInit() {
        if(this.context.documentNodes) {
            this.documentCopyNodes = this.context.documentNodes.slice(0);
        }

        let lastIndex : number = this.context.document.documentName.lastIndexOf(".");
        if(lastIndex > -1) {
            this.fullFileName = this.context.document.documentName.substring(0, lastIndex);
        }
        this.fileExtension = this.context.document.documentName.substring(lastIndex + 1);
        this.fileName = this.fullFileName;
        if(this.context.action == "COPY") {
            this.fileName = this.setupCopyFileName();
        }
        if(this.context.action === "EDIT_DETAILS" && this.context.document && this.context.document.description) {
            this.description = this.context.document.description;
        }
    }

    // After the view is initialized
    ngAfterViewInit() : void {
        if(this.tree && this.tree.treeModel) {
            this.tree.treeModel.filterNodes((node) => {
                return node.data.isFolder && node.data.isNodeEditable;

            });
            this.tree.treeModel.collapseAll();
            if (this.context.nodeData && !this.context.nodeData.isNodeEditable) {
                this.tree.treeModel.setFocusedNode(this.documentCopyNodes[0]);
            } else if (this.context.nodeData) {
                let node: TreeNode = this.tree.treeModel.getNodeById(this.context.nodeData.id);
                if (node.parent) {
                    this.tree.treeModel.setExpandedNode(node.parent.parent, true);
                    node.parent.setActiveAndVisible();
                    this.tree.treeModel.update();
                }
            }
        }
    }

    get isTreeStructureVisible() : boolean {
        return this.context.nodeData && this.documentCopyNodes && this.documentCopyNodes.length > 0 && this.documentCopyNodes[0].children && this.documentCopyNodes[0].children.some(item => item.isFolder && item.isNodeEditable);
    }

    public isFileNameExisting(copiedFileName : string) : boolean {
       return(this.context.matterDocuments && (this.context.matterDocuments.filter(f => !this.context.document || f.subpath == this.context.document.subpath).findIndex(item => item.documentName == copiedFileName) > -1));
    }

    setupCopyFileName() {

        let copyFileName : string = this.fileName + "- Copy";
        if(this.isFileNameExisting(copyFileName+'.'+this.fileExtension)){
            if(copyFileName.indexOf('- Copy (') < 0)
            {
                copyFileName = this.fileName + "- Copy (2)";
            }
            if(this.isFileNameExisting(copyFileName+'.'+this.fileExtension)) {
                let res = copyFileName.match(/Copy(.*?)(\d+)/g);
                if(res && res.length > 0) {
                    let fileNumber = Number(res[0].substr(res[0].length - 1));
                    copyFileName = copyFileName.replace('Copy (' + fileNumber + ')', 'Copy (' + (Number(fileNumber) + 1) + ')');
                    if(this.isFileNameExisting(copyFileName+'.'+this.fileExtension)) {
                        // 100 is Number Assumed and user will not copy more than 100 times
                        // TODO : in future we can use regex to find highest number and just append it ..
                        for(let i = fileNumber; i < 100; i++) {
                            fileNumber = Number(fileNumber) + 1;
                            copyFileName = copyFileName.replace('Copy (' + fileNumber + ')', 'Copy (' + (Number(fileNumber) + 1) + ')');
                            if(!this.isFileNameExisting(copyFileName+'.'+this.fileExtension)) {
                                break;
                            }
                        }
                    }
                }
            }
        }
        console.log(' File Name : '+copyFileName+'.'+this.fileExtension);
        return copyFileName;
    }

    save() : void {
        this.renameSub = this.documentProductionService.getDocuments(this.context.matterId)
                             .subscribe(data => {
                                 this.documentList = data;
                                 this.fileName = this.fileNameRemoveSpecialChars();
                                 this.checkForErrors();
                                 if(!this.modalErrorComponent.anyErrorExist()) {
                                     let newFileName : string = this.fileName + "." + this.fileExtension;
                                     if(this.context.action == "RENAME") {
                                         this.renameSub = this.documentProductionService.renameFile(this.context.matterId, this.context.document.id, this.fileName, this.fileExtension).subscribe(res => {
                                             this.dialog.close({newFileName : newFileName});
                                         });
                                     }
                                     if(this.context.action == "COPY") {
                                         let subPath = this.context.nodeData && this.tree && this.tree.treeModel && this.tree.treeModel.focusedNode
                                             && this.tree.treeModel.focusedNode.data ? this.tree.treeModel.focusedNode.data.path : undefined;
                                         this.copySub = this.documentProductionService.copyFile(this.context.matterId, this.context.document.id, this.fileName, this.fileExtension,this.context.document.isThirdPartyDocument,
                                             subPath )
                                                            .subscribe(res => {
                                             this.dialog.close({newFileName : newFileName, res : res});
                                         });
                                     }
                                     if(this.context.action == "EDIT_DETAILS") {
                                         this.renameSub = this.documentProductionService.renameFile(this.context.matterId, this.context.document.id, this.fileName, this.fileExtension, this.description).subscribe(res => {
                                             this.dialog.close({newFileName : newFileName,description : this.description});
                                         });
                                     }
                                 }
                             });

    }

    checkIfFileNameisDuplicate() {
        let isDuplicate : boolean = false;
        let newFile : string = this.fileName + "." + this.fileExtension;
        let subPath = this.context.nodeData && this.tree && this.tree.treeModel && this.tree.treeModel.focusedNode
        && this.tree.treeModel.focusedNode.data ? this.tree.treeModel.focusedNode.data.path : undefined;
        let fileList = (this.documentList.filter(f => !this.context.document || f.subpath == subPath));

        for(let i = 0; i < fileList.length; i++) {
            if(fileList[i].documentName && newFile.toLowerCase() === fileList[i].documentName.toLowerCase()) {
                isDuplicate = true;
            }
        }
        return isDuplicate;
    }

    checkIfFileIsLockedByUser() {
        let index = this.documentList.findIndex((r : any) => r.id === this.context.document.id);
        if(this.documentList[index].isOpen) {
            return true;
        } else {
            return false;
        }
    }

    fileNameRemoveSpecialChars() {
        const regex = /[\\\<\>\:\"\/\|\?\*]/g;
        let str : string = this.fileName;
        let subst = '';

        return str.replace(regex, subst);
    }


    checkForErrors() {
        this.modalErrorComponent.removeAllDpSaveError();

        if(this.fileName == "" || this.fileName == null) {
            this.modalErrorComponent.createCustomDPSaveError('matter.documentProduction.renameFile', 'File Name is required.', '', 'ERROR');
        }

        else if(this.checkIfFileNameisDuplicate() && (this.context.action == "RENAME" || this.context.action == "COPY")) {
            this.modalErrorComponent.createCustomDPSaveError('matter.documentProduction.renameFile', 'File name already exists. Please change name and try' +
                                                             ' again.', '', 'ERROR');
        }

        else if(this.checkIfFileIsLockedByUser() && this.context.action == "RENAME") {
            this.modalErrorComponent.createCustomDPSaveError('matter.documentProduction.renameFile', 'Not able to rename the file as it is open for editing. Please close Word document and try again.', '', 'ERROR');
        }

    }


    close() : void {
        this.dialog.close();
    }

    ngOnDestroy() {}
}


