import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {AccountingItemKeys} from '../../../admin/soa/soa-constants';
import {MatterType} from '../../../matters/shared';
import * as _ from 'lodash';

export type DisbursementSubjectToType = 'SUBJECT_TO_HST' | 'NOT_SUBJECT_TO_HST' | 'ADDITIONAL' | 'SUBJECT_TO_PST' | 'SUBJECT_TO_GST_PST' | 'SUBJECT_TO_GST' | 'NOT_SUBJECT_TO_GST_PST' | 'OTHER_SUBJECT_TO_GST' | 'OTHER_SUBJECT_TO_PST' | 'OTHER_SUBJECT_TO_GST_PST' | 'NOT_OTHER_GST_PST';

export class DisbursementConfig  extends BaseEntity {

    id: number
    nameOfDisbursement: string;
    f9DefaultAmount: number;
    readOnly: boolean;
    isSelected: boolean;
    subjectToHstType: DisbursementSubjectToType;
    deleted: boolean;
    calculated: boolean;
    code: string;
    matterType : MatterType;
    configAccountCode: string;
    // Property added only for UI to display dynamic options
    configAccountCodeArray : any[] = [];

    populateConfigAccountCodeArray(combinedAccountCodeArr: any[]): any[]{
        this.configAccountCodeArray =[];
        combinedAccountCodeArr.forEach( item => this.configAccountCodeArray.push(item));
        let codeIndex : number = _.findIndex(this.configAccountCodeArray, item => item.value === this.configAccountCode);
        if(codeIndex === -1) {
            this.configAccountCodeArray.push({'label': this.configAccountCode,'value': this.configAccountCode})
        }
        return this.configAccountCodeArray;
    }

    constructor(disblibItem?: DisbursementConfig) {
        super(disblibItem);
    }

    isMLTTConfiguration(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.HST_MLTT_ADMIN_FEE]);
    }

    isSearchCost(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.SEARCH_COSTS]);
    }

    isExecutionCost(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.EXECUTIONS_CERTIFICATE]);
    }


    isTransactionLevySurcharge(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.TRANSACTION_LEVY_SURCHARGE]);
    }

    isCondoCertificationFee(): boolean {
        return (
            this.code == AccountingItemKeys[AccountingItemKeys.CONDO_STATUS_CERTIFICATE_FEE] ||
            this.code == AccountingItemKeys[AccountingItemKeys.ESTOPPEL_CERTIFICATE]
        );
    }

    isCondoDocumentsFee(): boolean {
        return (
            this.code == AccountingItemKeys[AccountingItemKeys.CONDOMINIUM_DOCUMENTS] ||
            this.code == AccountingItemKeys[AccountingItemKeys.STATUS_CERTIFICATE] ||
            this.code == AccountingItemKeys[AccountingItemKeys.ESTOPPEL_CERTIFICATE] );
    }


    isRetailSalesTaxChattels(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.RETAIL_SALES_TAX_CHATTELS]);
    }

    isERegServiceCharge(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY]);
    }

    isRegistrationOfMortgageDischarge() : boolean{
        return (this.code == AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY]);
    }


    isRegistrationOfVTBMortgage() : boolean{
        return (this.code == AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY]);
    }

    isRegistrationTransfer(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY]);

    }

    isRegistrationCharge(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY]);

    }

    isRegistrationOfMortgage() : boolean {
        return (this.code ==  AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY]);
    }

    isCompliance(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.COMPLIANCE_ITEMS]);

    }

    isNrst(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.NRST]);

    }

    isTitleInsurance(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.INSURANCE_PREMIUM_KEY]);

    }

    isTitleRegistrationFee(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.TITLE_REGISTRATION_FEE]);
    }

    isMortgageRegistrationFee(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.MORTGAGE_REGISTRATION_FEE]);
    }

    isEMP(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY]);

    }

    isRegistrationDischargeOfOther(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY]);
    }

    isRegisterVendorsLienCaveat(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY]);
    }

    isDischargeVendorsLienCaveat(): boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.DISCHARGE_VENDOR_LIEN_CAVEAT_KEY]);
    }

    isTeranetConnectCharges() : boolean {
        return (this.code == AccountingItemKeys[AccountingItemKeys.TERANET_CONNECT_USAGE_CHARGE_KEY]);
    }
}
