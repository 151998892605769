import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {UUIDUtil} from '../../main/uuid-util';
import {Matter} from './matter';
import {Mortgage} from './mortgage';

export const PermittedRegistrationMaxLength : number = 150;

export class PermittedRegistration extends BaseEntity{

    constructor(permittedRegistration? : PermittedRegistration) {
        super(permittedRegistration);

        //generate a UUID for new permitted registration (needed for reference from existing mortgage)
        if (!this.id) {
            this.id = UUIDUtil.getUUID();
            this.identifier = this.id;
        }
    }

    id: number;
    static clientAssignedIdentifierEntity: boolean = true;//Marker property for indicating client side assigns the ID to this entity
    matterId: number
    permittedRegistrationValue: string;
    instrumentId: number; //permitted registration created from instrument

    updateFromMortgage(matter: Matter, mortgage: Mortgage) : void {

        let mortgageeName: string = mortgage.getMortgageeNameWithModifier(matter, 'M');

        this.permittedRegistrationValue = mortgage.financingType
            + (mortgage.mortgageRequestNo ? ' No. ' + mortgage.mortgageRequestNo: '')
            + (mortgageeName ? ' (' + mortgageeName + ')' : '');

        if(matter.isMatterProvinceMB && mortgage.instrumentId) {
            let tprInstrument = matter.getTprInstrumentByInstrumentId(mortgage.instrumentId);
            if(tprInstrument && tprInstrument.affectedByInstrumentTypeCode && tprInstrument.affectedByInstrumentTypeCode == 'AA') { //Amending Agreement - TPR
                this.permittedRegistrationValue = mortgage.financingType
                    + (mortgage.mortgageRequestNo ? ' No. ' + mortgage.mortgageRequestNo: '')
                    + (mortgage.mortgageRequestDate ? ', ' + mortgage.mortgageRequestDate: '')
                    + (mortgageeName ? ' - ' + mortgageeName : '');
            }
        }
        //associating permitted registration with instrument used to create existing mortgage
        this.instrumentId = mortgage.instrumentId;
    }


};
