type AccessProfileStatus = "ACTIVE" | "INACTIVE" | "SUSPENDED" | "TEST";

export class AccessProfile {
    accessProfileName: string;
    accessProfileType: string;
    id: number;
    lastUpdatedTimeStamp: string;
    lastUpdatedUser : string;
    profileDescription: string;
    status: AccessProfileStatus;
    accessProfilePermissionSets : any[];
    customerAccountId : number;
    isDirty : boolean = false;
    selectedTabIndex : number ;


    constructor(profile? : AccessProfile) {
        if(profile) {
            for(let prop in profile) {
                if(profile.hasOwnProperty(prop)) {
                    this[prop] = profile[prop];
                }
            }
        }
    }

    equals(other : AccessProfile) : boolean {
        return JSON.stringify(this, this.replacer) === JSON.stringify(other, this.replacer);
    }

    //This method is to list the properties which should be ignored in stringify
    replacer(key, val) : any {
        if(key !== "isDirty" && key !== "isValid" && key !== "errorMessage" && key !== "identifier" &&  key !== "selectedTabIndex") {
            return val;
        }
    }
}
