import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {CirfBrokerInfo} from './cirf-broker-info';
import {CirfUdf} from './cirf-udf';

export type CirfMortgageType = 'NEW' | 'EXISTING';

export const MortgageTypeValue = {
    New: 'NEW',
    Existing: 'EXISTING'
};

export class CirfMortgage extends BaseEntity {
    bankName: string;
    referenceNumber: string;
    mortgageBroker: CirfBrokerInfo = new CirfBrokerInfo();
    mortgageType: CirfMortgageType;
    mortgageUdfs : CirfUdf[] = [];

    constructor(cirfMortgage?: CirfMortgage) {
        super(cirfMortgage);
        if(cirfMortgage) {
            if(cirfMortgage.mortgageBroker) {
                this.mortgageBroker = new CirfBrokerInfo(cirfMortgage.mortgageBroker);
            }
            if(Array.isArray(cirfMortgage.mortgageUdfs)){
                this.mortgageUdfs = cirfMortgage.mortgageUdfs.map(cirfUdf => new CirfUdf(cirfUdf));
            }
        }
    }

    indexOfFirstNonNullUdfField(): number {
        for(let i=0; i < this.mortgageUdfs.length; i++){
            if(this.mortgageUdfs[i].fieldValue){
                return  i;
            }
        }
        return 0;
    }

    udfNotes(): string {
        let note = '';
        if (Array.isArray(this.mortgageUdfs)) {
            this.mortgageUdfs.forEach(udf => {
                note += udf.fieldValue ? `${udf.fieldLabel}: ${udf.fieldValue}\n` : '';
            });
        }
        return note;
    }

}
