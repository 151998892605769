import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Matter} from './matter';
import {AutoUnsubscribeDecorator} from '../../shared-main/auto-unsubcribe-decorator';
import {TabsService} from '../../core';
import {MatterTab} from '../matter-tab';
import {Project} from '../../projects/shared/project';
import {ProjectTab} from '../../projects/shared/project-tab';
import {OpportunityMatterTab} from '../../opportunity-matter/opportunity-matter-tab';
import {WizardTab} from '../../shared/tabbing/wizard-tab';

@Directive({selector: '[dpShowByProvince]'})
@AutoUnsubscribeDecorator()
export class DpShowByProvince implements OnInit {

    private viewCreated: boolean;
    private provinceKey: string;
    private provinceCode : string;
    private matterType : string;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private tabsService: TabsService) {
    }

    ngOnInit(): void {
        //This is needed for the cases where the tabs are switched and the component tree is reused (with new matter data), we need to re-evaluate, as
        //a matter of different type can be made active
        if(this.matter && this.provinceKey)
        {
           this.enableSection();
        }

    }

    get matter(): Matter {
        if (this.tabsService && this.tabsService.activeTab && (this.tabsService.activeTab.isMatter() || this.tabsService.activeTab.isOpportunityMatter() || this.tabsService.activeTab.isWizard())) {
            return this.tabsService.activeTab.isMatter()? (this.tabsService.activeTab as MatterTab).matter :
                this.tabsService.activeTab.isWizard() ? (this.tabsService.activeTab as WizardTab).matter
                : (this.tabsService.activeTab as OpportunityMatterTab).matter ;
        } else {
            return undefined;
        }
    }

    get project(): Project {
        if (this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isProject()) {
            return (this.tabsService.activeTab as ProjectTab).project;
        } else {
            return undefined;
        }
    }

    @Input()
    set dpShowByProvinceCode(provinceCode : string) {
        if(provinceCode) {
            this.provinceCode = provinceCode;
            this.enableSection();
        }

    }

    @Input()
    set dpShowByProvinceMatterType(matterType : string) {
        if(matterType) {
            this.matterType = matterType;
        }
    }



    private enableSection() {
        let provinceKeyCode = this.provinceKey;
        let matterTypeCode: string = '';
        //Default Project Type Code is s - which mean it is applicable to non - project sale
        let projectTypeCode : string = 's';

        let provinceCode = this.provinceCode;
        if(!provinceCode){
            if (this.matter){
                provinceCode = this.matter.provinceCode;
            } else if (this.project){
                provinceCode = this.project.provinceCode;
            }
            else {
                provinceCode = undefined;
            }
        }
        if (this.matter) {
            matterTypeCode =  this.matter.isOpportunityMatter() && this.matter.customMatterTypeName ? this.matter.customMatterTypeName.charAt(0) : this.matter  && this.matter.matterType ? this.matter.matterType.charAt(0) : '';
        } else {
            matterTypeCode = this.matterType ? this.matterType.charAt(0) : '';
        }

        if (this.matter && matterTypeCode && this.matter.isProjectSale) {
                //Change Project Type Code to Z - which mean it say check for project sale type
                projectTypeCode = 'z';
        }



        if ((DpShowByProvince.visibleFieldRestrictions[provinceKeyCode] &&
            DpShowByProvince.visibleFieldRestrictions[provinceKeyCode].some( item =>
                item[provinceCode] && (!matterTypeCode || (item[provinceCode].indexOf(matterTypeCode) >= 0 || item[provinceCode].indexOf(projectTypeCode) >= 0) )
            )) ||
            (DpShowByProvince.hiddenFieldRestrictions[provinceKeyCode] &&
                !(DpShowByProvince.hiddenFieldRestrictions[provinceKeyCode].some(item =>
                    item[provinceCode] && (!matterTypeCode || (item[provinceCode].indexOf(matterTypeCode) >= 0 || item[provinceCode].indexOf(projectTypeCode) >= 0) )
                )))) {
            if (!this.viewCreated) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.viewCreated = true;
            }
        }
        else {
            this.viewContainer.clear();
            this.viewCreated = false;
        }
    }

    @Input()
    set dpShowByProvince(provinceKey: string) {
        if (provinceKey) {
            this.provinceKey = provinceKey;
            this.enableSection();
        }
    }

    // For Project Sale
    // Use s (small alphabet s) to denote it is only specific to sale matter and not to project sale
    // Use S (big alphabet S - which is currently default for sale matter) to denote it is applicable to Project Sale and Normal Sale Matter.
    // Use z (small alphabet z) to denote it is applicable to Project Sale
    // Examples - where you need to hide for project sale but applicable to Normal Sale - use small s - 'key' : ['AB' : 's']
    // Examples - where you need to show for project sale but not applicable to regular sale - use small s - 'key' : ['AB' : 'z']

    // Visible for specific Province.
    static visibleFieldRestrictions: any = {
        'matter.condoCorporation.documents' : [
            {'AB': 'PSM'},
            {'BC': 'PSM'},
            {'MB': 'PSM'},
            {'SK': 'PSM'},
            ],
        'matter.mortgage.existingMortgage.mortgageTo' : [{'AB' : 'SM'}, {'SK' : 'SM'}, {'MB' : 'SM'}, {'NB' : 'SM'}, {'NS' : 'SM'},{'ON' : 'SM'},{'BC' : 'S'}],
        'matter.mortgage.existingMortgage.mortgageRegNo' : [{'AB'  : 'SM'}],
        'matter.mortgage.existingMortgage.payoutEqualsTrustBalance' : [{ 'ON' : 'S'},{ 'AB' : 'SM'},{ 'MB' : 'SM'},{ 'SK' : 'SM'},{'BC': 'S'}],
        'matter.mainClient.offeror': [
            {'ON': 'P'},
            {'BC': 'P'}],
        'matter.matterOpening.dateOfAgrtPS': [
            {'ON': 'PS'},
            {'AB': 'PS'},
            {'NB': 'PS'},
            {'NS': 'PS'},
            {'MB': 'PS'},
            {'SK': 'PS'},
            {'BC': 'PSM'}],
        'matter.matterOpening.actingOnBehalfOf': [
            {'ON': 'PsM'},
            {'AB': 'PSM'},
            {'NB': 'M'},
            {'NS': 'M'},//NS is same as ON
            {'MB': 'PSM'},
            {'SK': 'PSM'},
            {'BC': 'PSM'}],
        'matter.matterOpening.requisitionDate': [
            {'ON': 'PS'},
            {'NB': 'P'},
            {'NS': 'PS'},
            {'BC': 'PM'}],
        'matter.matterOpening.requisitionSubmitted': [
            {'ON': 'PM'},
            {'NB': 'P'},
            {'NS': 'P'},
            {'MB': 'PS'},
            {'SK': 'PS'},
            {'BC': 'PM'}],
        /*'matter.matterOpening.occupancyDate': [
            {'ON': 'PS'}, // controlled by other fields
            {'AB': 'PS'},
            {'NB': 'PS'},
            {'NS': 'PS'},
            {'BC': 'PSM'}],*/

        'matter.matterOpening.adjustmentDate': [
            {'BC': 'S'}],
        'matter.matterOpening.commissioner': [
            {'ON': 'PSM'},
            {'AB': 'PSM'},
            {'NB': 'PSM'},
            {'NS': 'PSM'},
            {'MB': 'PSM'},
            {'SK': 'PSM'},
            {'BC': 'PM'}],
        'matter.matterOpening.witness': [
            {'ON': 'PSM'},
            {'AB': 'PSM'},
            {'NB': 'PSM'},
            {'NS': 'PSM'},
            {'MB': 'PSM'},
            {'SK': 'PSM'},
            {'BC': 'PM'}],
        'matter.matterOpening.closingDate': [
            {'ON': 'PSM'},
            {'AB': 'PSM'},
            {'NB': 'PSM'},
            {'NS': 'PSM'},
            {'MB': 'M'},
            {'SK': 'M'},
            {'BC': 'PSM'}],
        'matter.matterOpening.possessionTime': [
            {'MB': 'PS'},
            {'SK': 'PS'}],
        'lateClosingInterestModal.sameAs1stMortgage' : [
            {'AB': 'PS'},
            {'MB': 'PS'},
            {'SK': 'PS'}],
        'lateClosingInterestModal.invalidDelayedClosingDate' : [
            {'MB': 'PS'},
            {'SK': 'PS'}],
        'lateClosingInterestModal.interestEstimateOn' : [
            {'MB': 'PS'},
            {'SK': 'PS'}],
        'matter.matterOpening.registrationMethod': [
            {'ON': 'PSM'}],
        'matter.matterOpening.teraviewDocketIdentifier': [
            {'ON': 'PSM'}],
        'matter.matterOpening.protocolClosing': [
            {'MB': 'PSM'},
            {'SK': 'PSM'},
            {'AB': 'PSM'}],
        'matter.matterOpening.purchaserFinancing': [
            {'AB': 'S'}],
        'matter.matterOpening.interestRateSummary': [
            {'SK': 'PSM'},
            {'AB': 'PSM'}],
        'matter.matterOpening.interestRateBOC': [
            {'SK': 'PSM'}],
        'matter.matterOpening.cashOnClosingDate': [
            {'SK': 'PS'},
            {'MB': 'PS'},
            {'AB': 'PS'}],
        'matter.matterOpening.documentsToBeSignedRemotely': [
            {'ON': 'PSM'},
            {'MB': 'PSM'},
            {'AB': 'PSM'},
            {'SK': 'PSM'}],
        'matter.otherParty.documentsToBeSignedRemotely': [
            {'ON': 'PSM'},
            {'SK': 'PSM'}],
        'matterAndContact.jurisdiction.formOne': [{ 'AB'  : 'PSM'}],
        'matter.mortgage.titleDetails': [{ 'ON'  : 'PM'},
                                         { 'MB'  : 'N/A'},
                                         { 'SK'  : 'N/A'},
                                         { 'BC'  : 'PM'}],
        'matter.mortgage.titleDetailsSection': [{ 'AB'  : 'PM'},
                                                { 'NB'  : 'PSM'},
                                                { 'NS'  : 'PSM'},
                                                { 'MB'  : 'PSM'}],
        'matter.mortgage.iscClientNumber': [{ 'SK'  : 'PSM'}],
        'matter.mortgage.detail.interimEnclosures': [{ 'ON'  : 'PM'},
                                                     { 'MB'  : 'PM'},
                                                     { 'SK'  : 'PM'},
                                                     { 'BC'  : 'PM'}],
        'matter.mortgage.detail.requestForFundsSubmitted': [{ 'AB'  : 'PSM'}],
        'matter.mortgage.term.insuranceAmount': [{ 'ON'  : 'PSM'},
                                                 { 'MB'  : 'N/A'},
                                                 { 'SK'  : 'N/A'},
                                                 { 'BC'  : 'PSM'}],
        'matter.mortgage.term.sctNo': [{ 'ON'  : 'PSM'},
                                       { 'NB'  : 'PSM'},
                                       { 'NS'  : 'PSM'},
                                       { 'MB'  : 'N/A'},
                                       { 'SK'  : 'N/A'},
                                       { 'BC'  : 'PSM'}],

        'matter.mortgage.term.standardMortgageForm': [{ 'AB'  : 'PSM'},
                                                      { 'SK'  : 'PSM'},
                                                      { 'MB'  : 'PSM'}],

        'matter.mortgage.term.semiAnnualEquivalent': [{ 'SK'  : 'PSM'}],

        'matter.mortgage.term.additionalMortgageDetails': [{ 'MB'  : 'PSM'}],

        'matter.mortgage.term.sctFiledBy': [{ 'SK'  : 'PSM'},
                                            { 'MB'  : 'PSM'}],

        'matter.mortgage.term.guarantor.guarantorType': [{ 'SK'  : 'PSM'}],

        'matter.mortgage.report.generateReportingLetterTo': [{ 'ON'  : 'PM'},
                                                             { 'MB'  : 'PM'},
                                                             { 'SK'  : 'PM'},
                                                             { 'BC'  : 'PM'}],
        'matter.mortgage.report.includeClauseMtgeeConsentRequiredIfMtgToBeAssumed':
                                                            [   { 'ON'  : 'PSM'},
                                                                { 'AB'  : 'PSM'},
                                                                { 'MB'  : 'PSM'},
                                                                { 'SK'  : 'PSM'},
                                                                { 'BC'  : 'PSM'}],
        'matter.mortgage.report.mortgagePrepaymentPrivilege':
                                                            [   { 'ON'  : 'PSM'},
                                                                { 'AB'  : 'PSM'},
                                                                { 'MB'  : 'PSM'},
                                                                { 'SK'  : 'PSM'},
                                                                { 'BC'  : 'PSM'}],

        'matter.mortgage.relatedInstruments': [{ 'ON'  : 'SM'},
            { 'AB'  : 'SM' },
            { 'SK'  : 'SM'},
            { 'NB'  : 'SM'},
            { 'MB'  : 'SM'},
            { 'NS'  : 'SM'},
            { 'BC' : 'S'}
        ],

        'admin.document.lawFirm.trustLetterFormat': [{ 'AB'  : 'N/A'},{'BC' : 'N/A'}],
        'matter.soa.paysForDateOfClosing' : [{'AB' : 'PSM'}, {'SK' : 'PS'}, {'MB' : 'PS'}, {'NS' : 'PS'}, {'NB' : 'PS'}],
        'matter.mainClient.titleDetails': [
            { 'AB'  : 'PM' },
            { 'SK'  : 'PSM'},
            { 'MB'  : 'PSM'},
            { 'NB'  : 'PS'},
            { 'NS'  : 'PS'},
        ],

        'matter.otherParty.titleDetails': [{ 'AB'  : 'S'}, { 'SK'  : 'S'}, { 'MB'  : 'S'}, { 'BC'  : 'S'}],
        'matter.subjectProperty.titleNumber': [{ 'AB'  : 'PSM'}],
        'matter.subjectProperty.landTitleOfficeLocation': [{ 'AB'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.lincNo': [{ 'AB'  : 'PSM'}],
        'matter.subjectProperty.assessmentRollNumber': [{ 'SK'  : 'PSM'}],
        'matter.subjectProperty.lastInstrument' :  [{ 'AB'  : 'PSM'},{ 'ON'  : 'PM'}, { 'NS'  : 'S'}],
        'matter.subjectProperty.isVendorToSupplyRPR' :  [{ 'AB'  : 'PS'},{ 'SK'  : 'PS'}],
        'matter.subjectProperty.isRPRApplicable' :  [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.residentAssociation' :  [{ 'AB'  : 'PSM'}],
        'matter.subjectProperty.section501'          : [{ 'ON'  : 'P'}],
        'matter.subjectProperty.annualFeeAndFiscalYearEnd' :  [{ 'AB'  : 'PSM'}],
        'matter.subjectProperty.instrumentNumber' :  [{ 'AB'  : 'PSM'}],
        'matter.subjectProperty.purchaserShare' :  [{ 'AB'  : 'PS'}],
        'matter.subjectProperty.taxCertificates' :  [{ 'AB'  : 'PSM'}],
        'matter.subjectProperty.registryOfficeName' :  [{ 'ON'  : 'PSM'}, { 'NB'  : 'PSM'}, {'NS' : 'PSM'}],
        'matter.subjectProperty.landTitleOffice' :  [{ 'AB'  : 'PSM'}],
        'matter.subjectProperty.landTitlesOffice' :  [{ 'MB'  : 'PSM'}],
        'matter.subjectProperty.legalDescription' :  [{ 'AB'  : 'PSM'}, { 'NS'  : 'PSM'}, { 'BC'  : 'S'}],
        'matter.subjectProperty.legalDescription.planNumber' :  [{ 'AB'  : 'PSM'}, { 'BC'  : 'S'}],
        'matter.subjectProperty.legalDescription.exceptionWording' :  [{ 'AB'  : 'PSM'}, { 'NS'  : 'PSM'}],

        'matter.subjectProperty.landTransferTax' :  [{ 'MB'  : 'P'}, { 'NS'  : 'P'}],
        'matter.subjectProperty.recordingMortgage' : [{ 'NS'  : 'PM'}],
        'matter.subjectProperty.recordingDeed' : [{ 'NS'  : 'P'}],
        'matter.subjectProperty.purchasePrice.simple.input': [{'BC': 'S'}],
        'matter.subjectProperty.mobileHomeRegistration': [{'BC': 'S'}],
        'matter.stc.showPaymentAmounts': [{ 'AB'  : 'PSM'}],
        'matter.directionToPay.showPaymentAmounts': [
            { 'AB'  : 'S'},
            { 'MB'  : 'S'},
            { 'SK'  : 'S'}],
        'matter.mainClient.capacity': [{ 'ON'  : 'PM'},
            { 'AB'  : 'PSM'},
            { 'NB'  : 'PM'},
            { 'NS'  : 'PM'},
            { 'MB'  : 'PSM'},
            { 'SK'  : 'PSM'},
            { 'BC'  : 'PSM'}],
        'matter.mainClient.execution': [{ 'NB'  : 'PSM'}],
        'matter.mainClient.snapshot.municipality': [{ 'ON'  : 'PSM'}],
        'admin.statement.account.config.separateFeeForMortgage' : [{ 'ON'  : 'P'},
                                                                   { 'AB'  : 'P'},
                                                                   { 'NB'  : 'P'},
                                                                   { 'NS'  : 'P'},
                                                                   { 'MB'  : 'P'},
                                                                   { 'SK'  : 'P'},
                                                                   { 'BC'  : 'P'}],
        'admin.statement.account.config.disbursement.additional' : [{'AB' : 'PSM'}],
        'admin.statement.account.config.disbursement.subject.to.pst' : [{'MB' : 'PSM'},{'SK' : 'PSM'}],
        'admin.statement.account.config.disbursement.subject.to.gst.and.pst' : [{'MB' : 'PSM'},{'SK' : 'PSM'}],
        'admin.mortgageEnclosure.interimElcosure.tableHead.show' : [{'AB' : 'NA'}],
        'admin.mortgageEnclosure.interimElcosure.tableBody.show' : [{'AB' : 'NA'}],
        'admin.statement.account.config.ELRSAFeesType' : [{'ON' : 'NA'}, {'AB' : 'NA'}, {'MB' : 'NA'}, {'NS' : 'NA'}, {'NB' : 'NA'}, {'SK' : 'NA'}],
        'admin.statement.account.config.landTransferTax' : [{'ON' : 'NA'}, {'NS' : 'NA'}, {'NB' : 'NA'}],
        'admin.statement.account.config.ontarioTorontoLTT' : [{'ON' : 'NA'}],
        'admin.statement.account.config.titleMortgageRegFees' : [{'ON' : 'NA'}, {'AB' : 'NA'}, {'SK' : 'NA'}, {'MB' : 'NA'}, {'NS' : 'NA'}, {'NB' : 'NA'}],
        'admin.statement.account.config.nrst' : [{'ON' : 'NA'}],
        'admin.statement.account.config.teranetConnectFees' : [{'ON' : 'NA'}],
        'matter.reportToPurchaser.insertPrecedent' :  [{'AB' : 'P'}, {'SK' : 'P'}, {'MB' : 'P'}, {'NS' : 'P'}, {'NB' : 'P'}],
        'matter.reportToPurchaser.detailsOfPermittedRegistrations' : [{'AB' : 'P'}, {'SK' : 'P'}, {'MB' : 'P'}],
        'matter.reportToPurchaser.yes-no-options' : [{'SK' : 'P'}, {'MB' : 'P'}],
        'matter.matterProperty.unitLevelPlan.dividedShares' : [{'AB' : 'PSM'}],
        'matter.matterProperty.unitLevelPlan.rollNumber' : [{'AB' : 'PSM'}],
        'matter.considerationModal' : [{'AB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo' : [{'AB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.rateOfMatterTaxRateType' : [{'ON'  : 'PSM'}, {'AB'  : 'PSM'}, {'NS'  : 'PSM'}, {'NB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.rateOfPST' : [{'SK'  : 'PSM'}, {'MB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.rateOfGST' : [{'SK'  : 'PSM'}, {'MB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.HstFee' : [{'ON'  : 'PSM'}, {'AB'  : 'PSM'}, {'NS'  : 'PSM'}, {'NB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.GstFee' : [{'SK'  : 'PSM'}, {'MB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.PstFee' : [{'SK'  : 'PSM'}, {'MB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.disbursementsSubjectToHst' : [{'ON'  : 'PSM'}, {'AB'  : 'PSM'}, {'NS'  : 'PSM'}, {'NB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.disbursementsSubjectGSTAndPSTSkMb' : [{'SK'  : 'PSM'}, {'MB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.disbursementsSubjectGSTSkMb' : [{'SK'  : 'PSM'}, {'MB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.disbursementsSubjectPSTSkMb' : [{'SK'  : 'PSM'}, {'MB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.disbursementsNotSubjectToHst' : [{'ON'  : 'PSM'}, {'AB'  : 'PSM'}, {'NS'  : 'PSM'}, {'NB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.disbursementsNotSubjectGSTSkMb' : [{'SK'  : 'PSM'}, {'MB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.totalHst' : [{'ON'  : 'PSM'}, {'AB'  : 'PSM'}, {'NS'  : 'PSM'}, {'NB'  : 'PSM'}],
        'matter.supplementalTaskSoaInfo.totalGSTAndPSTSkMb' : [{'SK'  : 'PSM'}, {'MB'  : 'PSM'}],
        'matter.subjectProperty.unitLevelPlan.exception' : [{'AB' : 'PSM'}],
        'matter.subjectProperty.spin' : [{'AB' : 'PSM'}, {'SK' : 'PSM'}, {'MB' : 'PSM'}],
        'matter.subjectProperty.upload' : [{'AB' : 'PSM'}],
        'matter.subjectProperty.spin.instDropdownMenu': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.spin.undertakingStatus': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.propertyTaxes.taxesPaidUnderTIPP' : [{'AB' : 'PSM'}, {'SK' : 'PSM'}, {'MB' : 'PSM'}],

        'matter.undertaking.addNewBurgerMenu' : [{ 'AB'  : 'PSM'},
                                                 { 'SK'  : 'PSM'},
                                                 { 'MB'  : 'PSM'},
                                                 { 'NS'  : 'PSM'}],
        'matter.undertaking.cct' : [{ 'AB'  : 'PSM'},
                                    { 'SK'  : 'PSM'},
                                    { 'MB'  : 'PSM'},
                                    { 'NS'  : 'PSM'}],
        'matter.undertaking.permittedRegistrations':  [ { 'AB'  : 'PSM'},
                                                        { 'SK'  : 'PSM'},
                                                        { 'MB'  : 'PSM'},
                                                        { 'NS'  : 'PSM'}],
        'matters.purchaser.capacity.capacityShare' : [{ 'ON'  : 'PSM'},
                                                      { 'AB'  : 'NA'},
                                                      { 'MB'  : 'NA'},
                                                      { 'SK'  : 'NA'},
                                                      { 'BC'  : 'S'},


        ],
        'matters.purchaser.capacity.shareNomalInput' : [{ 'ON'  : 'PSM'},
                                                      { 'AB'  : 'PSM'},
                                                      { 'NB'  : 'PSM'},
                                                      { 'NS'  : 'PSM'},
                                                      { 'MB'  : 'NA'},
                                                      { 'SK'  : 'NA'},
                                                      { 'BC'  : 'PSM'}],
        'matters.purchaser.capacity.sharePercentage' : [{ 'ON'  : 'NA'},
                                              { 'AB'  : 'NA'},
                                              { 'NB'  : 'NA'},
                                              { 'NS'  : 'NA'},
                                              { 'MB'  : 'PSM'},
                                              { 'SK'  : 'PSM'},
                                              { 'BC'  : 'NA'}],
        'matter.soa.modal.adjustmentHeading' :  [{ 'AB'  : 'PSM', 'MB' : 'PSM', 'SK' : 'PSM'}],
        'matter-soAccount-disbursementsAdditional' :  [{ 'AB'  : 'PSM'}],
        'matter.undertaking.subjectAB' :  [{ 'AB'  : 'PSM'}],
        'matters.property.typeFilter.teranetConnect' : [{ 'ON'  : 'PSM'},
                                                      { 'NB'  : 'PSM'},
                                                      { 'NS'  : 'PSM'},
                                                      { 'BC'  : 'PSM'}],
        'matter.subjectProperty.typeFilter.spin' :  [{ 'AB'  : 'PSM'}],
        'matter.subjectProperty.typeFilter.isc' :  [{ 'SK'  : 'PSM'}],
        'matter.subjectProperty.typeFilter.tpr' :  [{ 'MB'  : 'PSM'}],
        'matters.property.teranetConnect.pin' : [{ 'ON'  : 'PSM'},
                                                 { 'NB'  : 'PSM'},
                                                 { 'NS'  : 'PSM'},
                                                 { 'BC'  : 'PSM'}],
        'matters.property.teranetConnect.lt' : [{ 'ON'  : 'PSM'},
                                                { 'NB'  : 'PSM'},
                                                { 'NS'  : 'PSM'},
                                                { 'BC'  : 'PSM'}],
        'matters.property.teranetConnect.nodeBurgerMenu' : [{ 'ON'  : 'PSM'},
                                                            { 'NB'  : 'PSM'},
                                                            { 'NS'  : 'PSM'},
                                                            { 'BC'  : 'PSM'}],
        'matter.subjectProperty.title' :  [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.owner.share' : [{ 'ON'  : 'PSM'},
                                                { 'NB'  : 'PSM'},
                                                { 'NS'  : 'PSM'},
                                                { 'BC'  : 'PSM'}],
        'matter.subjectProperty.propertyDetails.linc' :  [{ 'AB'  : 'PSM'}],
        'matter.teranetProperty.propertyDetails' : [{ 'ON'  : 'PSM'},
                                                { 'NB'  : 'PSM'},
                                                { 'NS'  : 'PSM'},
                                                { 'MB'  : 'PSM'},
                                                { 'SK'  : 'PSM'},
                                                { 'BC'  : 'PSM'}],
        'matter.propertyTaxes.separateTaxAdjustmentByRollNumber' : [{'AB' : 'PS'}, { 'MB'  : 'PS'}],
        'matter.propertyTaxes.autoInsertTaxAdjustmentInSOA' : [{'NS' : 'PSM'}],
        'matter.propertyTeranet.propertyTax.label.AB.Mortgage' : [{ 'AB'  : 'M' }],
 		'matter.soa.prepaidTenancy.interestRateModel.legal.other': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.address': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.property.spin.tenure': [{ 'AB'  : 'SM'}, { 'SK'  : 'SM'}, { 'MB'  : 'SM'}],
        'matter.property.spin.importData.singleAddress': [{ 'ON'  : 'PSM'}],
        'matter.property.spin.importData.mutipleAddress': [{ 'AB'  : 'PSM'}, { 'MB'  : 'PSM'}, { 'SK'  : 'PSM'}],
        'matter.property.spin.importData.unitLevelPin': [{ 'ON'  : 'PSM'}],
        'matter.property.spin.importData.partLot': [{ 'ON'  : 'PSM'}],
        'matter.property.spin.importData.condominiumPlans.condominiumPlanType': [{ 'AB'  : 'PSM'},{ 'SK'  : 'PSM'}],
        'matter.property.spin.importData.condominiumPlans.condominiumPlanNumber': [{ 'AB'  : 'PSM'},{ 'SK'  : 'PSM'}],
        'matter.property.isc.importData.condominiumPlansFields': [{ 'SK'  : 'PSM'}],
        'matter.property.isc.importData.titleNumber': [{ 'SK'  : 'PSM'}],
        'matter.property.spin.importData.condominiumExpenses.unitNumber': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.condominiumExpenses.noUndividedShare': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.exception': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.exceptionTypeDescription': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.block': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.lot': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.linc': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.titleNo': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.shortLegalDescription': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.legalDescription': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.cityTC': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}],
        'matter.property.spin.importData.municipalityTC': [{ 'AB'  : 'PSM'}],
        'matter.property.spin.importData.beingPart': [{ 'ON'  : 'PSM'}],
        'matter.property.spin.importData.onPlan': [{ 'ON'  : 'PSM'}],
        'matter.property.spin.importData.parcel': [{ 'ON'  : 'PSM'}],
        'matter.property.spin.importData.section': [{ 'ON'  : 'PSM'}],
        'matter.property.spin.importData.easementOfWay': [{ 'ON'  : 'PSM'}],
        'matter.property.spin.importData.pin': [{ 'ON'  : 'PSM'}],
        'matter.property.spin.importData.lastTransferNo': [{ 'ON'  : 'PSM'}],
        'matter.property.importData.municipality': [{ 'ON'  : 'PSM'},{ 'AB'  : 'PSM'}],
        'matter.property.importData.registryOffice': [{ 'ON'  : 'PSM'}, { 'AB'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.property.importData.lastInstrumentNo': [{ 'AB'  : 'PSM'},{ 'ON'  : 'PSM'}],
        'matter.property.importData.condoCorporation': [{ 'MB'  : 'PSM'}],
        'matter.property.importData.percentageInterest': [{ 'MB'  : 'PSM'}],
        'matter.property.importData.landTitleOffice': [{ 'MB'  : 'PSM'}],
        'matter.property.importData.condominiumPlanNumber': [{ 'MB'  : 'PSM'}],
        'matter.property.importData.parcelLegalDescription.unit': [{ 'MB'  : 'PSM'}],
        'matter.property.importData.titleRollNumber.parcelNumber': [{ 'MB'  : 'PSM'}],
        'matter.property.importData.memo': [{ 'MB'  : 'PSM'}],
        'matter.mainClient.witnessKnowsTransferor': [{ 'AB'  : 'S' }],
        'matter.solicitor.documentsExecutedAt' : [{ 'ON'  : 'PS'},{'AB' : 'S'}, {'SK' : 'S'}, {'MB' : 'S'}],
        'matter.subjectProperty.condominiumTypePlan': [{ 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.mainClient.developerSection': [{ 'AB'  : 'z'}],
        'matter.subjectProperty.parcel': [{ 'SK'  : 'PSM'}],
        'matter.subjectProperty.titleRollNumberLegalDescription' : [{ 'MB'  : 'PSM'}],
        'matter.subjectProperty.zoningMemorandum': [{ 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.rentalAgreements': [{ 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.titleRegistrationFee': [{ 'SK'  : 'P'}],
        'matter.subjectProperty.mortgageRegistrationFee': [{ 'SK'  : 'PM'}],
        'matter.subjectProperty.occupancy': [{ 'SK'  : 'S'}, { 'MB'  : 'S'}],
        'matter.propertyTeranet.purchaseIsOf': [{ 'ON'  : 'PS'}, { 'AB'  : 'PS'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PS'}, { 'NB'  : 'PS'},{ 'NS'  : 'PS'}, {'BC': 'S'}],
        'matter.subjectProperty.overrideParcels': [{ 'SK'  : 'PS'}],
        'matter.reportToPurchaser.asInstrumentNos': [{ 'SK'  : 'P'}],
        'matter.reportToPurchaser.contactTaxDepartment' : [{'AB' : 'P'}],
        'matter.propertyTeranet.farmlandEvidence' : [{ 'MB'  : 'PSM'}],
        'matter.propertyTeranet.titleRollNumber' : [{ 'MB'  : 'PSM'}],
        'matter.subjectProperty.totalValueOfParcels': [{ 'SK'  : 'PSM'}],
        'matter.subjectProperty.separateCommonElementFeeAdj': [{ 'MB'  : 'PSM'}],
        'soa.commonExpenseAmount.editAmountMBSK': [{ 'MB'  : 'PSM'},{ 'SK'  : 'PSM'},{ 'ON'  : 'z'}],
        'matter.subjectProperty.editAmount': [{ 'MB'  : 'PSM'},{ 'SK'  : 'PSM'}],
        'emp.insert.lender.unitLevelPlan.show': [{ 'AB'  : 'PM'},
                                                { 'BC'  : 'PM'},
                                                { 'ON'  : 'PM'},
                                                { 'NS'  : 'PM'}],
        'emp.insert.lender.unitLevelPlanNB.show': [{ 'NB'  : 'PM'}],
        'emp.insert.lender.parcelSK.show' : [{ 'SK'  : 'PM'}],
        'emp.insert.lender.condominiumPlanNumber.show': [{ 'MB'  : 'PM'}, { 'SK'  : 'PM'}],
        'emp.insert.lender.condominiumType.show': [{ 'SK'  : 'PM'}],
        'emp.insert.lender.titleRollNumber.show': [{ 'MB'  : 'PM'}],
        'emp.insert.lender.partLotPlan.show' : [{'AB' :'PM'}],
        'emp.insert.lender.propertyDescriptionType.show' : [{'AB' :'PM'}, {'NS': 'PSM'}],
        'emp.insert.lender.planNumber.show' : [{'AB' :'PM'}],
        'emp.insert.lender.pidPamNumber.show' : [{'NB' :'PM'}],
        'emp.insert.lender.lotNumber.show' : [{'AB' :'PM'}, {'NS': 'PSM'}],
        'emp.insert.lender.exceptionWording.show' : [{'AB' :'PM'}, {'NS': 'PSM'}],
        'emp.insert.lender.fullLegalDescription.show' : [{'AB' :'PM'}, {'NS': 'PSM'}],
        'emp.insert.lender.shortLegalDescription.show' : [{'AB' :'PM'}, {'NS': 'PSM'}],
        'matter.statementAccount.GSTPST' : [{'MB' :'PSM'}, {'SK' :'PSM'}, {'BC': 'PSM'}],
        'matter.statementAccount.charges': [{'BC': 'PSM'}],
        'matter.statementAdjustment.GSTPST' : [{'MB' :'PSM'}, {'SK' :'PSM'}],
        'matter.matterProperty.purchaserPrice.readOnlyInfo' : [ {'SK' :'PSM'}],
        'matter.matterOpening.buildNo': [{'ON': 'z'},{'AB': 'z'}],
        'matter.matterOpening.contactTerminated': [{'ON': 'z'},{'AB': 'z'}],
        'matter.matterOpening.isReleaseDateSameAsOccupancyDate': [{'ON': 'z'}],
        'matter.matterOpening.releaseDate': [{'ON': 'z'}],
        'matter.matterOpening.occupancyCompleted': [{'ON': 'z'}],
        'matter.matterProperty.unitLevelPlan.percentageShareOfTotalRealtyTaxes' : [{'AB' : 'z'}],
        'matter.matterProperty.nameOfProject' : [{'ON': 'z'},{'AB': 'z'}],
        'matter.propertyTeranet.propertyType' : [{'NB': 'PSM'}, {'NS': 'PSM'}],
        'matter.subjectProperty.affidavitTobeSignedByItemLabelText' : [{'AB': 'N/A'}],
        'matter.otherParty.eligibleForTaxRebate' : [{'ON': 'z'},{'AB': 'z'}],
        'matter.otherParty.serviceAddressToUFFIWarrantySection': [{'ON': 'z'},{'AB': 'z'}],
        'matter.otherParty.offeror': [{'ON': 'z'}],
        'ps.matter.subjectProperty.unitLevelPlan.fieldLbl.show': [{ 'ON'  : 'z'}],
        'ps.matter.subjectProperty.unitLevelPlan.sharePercent.column.show': [{ 'ON'  : 'z'}],
        'ps.matter.soa.modal.adjustmentPaidOnInterimClosing' : [{ 'ON'  : 'z'}],
        'ps.matter.soa.modal.adj.fixed.infoOnly' : [{ 'ON'  : 'z'}],
        'ps.matter.soa.modal.adj.VTBMortgage.infoOnly' : [{ 'ON'  : 'z'},{ 'AB'  : 'z'}],
        'ps.matter.subjectProperty.unitLevelPlan.idNumber.column.show': [{ 'ON'  : 'z'}],
        'ps.matter.subjectProperty.unitLevelPlan.overrideDescInd.column.show': [{ 'ON'  : 'z'}],
        'matter.subjectProperty.unitLevelPlan.condominiumCorporationName' : [{'NS'  : 'PSM'},
                                                                             {'NB'  : 'PSM'}],
        'matter.matterProperty.unitLevelPlan.condominiumTotalSharePercentage': [{ 'ON'  : 'z'}],
        'admin.documentProfile.misc.manageUFFIWarranty': [{ 'ON'  : 'PSM'}, { 'NS'  : 'PSM'}, { 'NB'  : 'PSM'}],
        'admin.documentProfile.misc.excludeMineralParcels': [{ 'SK'  : 'PSM'}],
        'admin.documentProfile.misc.interestRateSummary': [{ 'AB'  : 'PSM'}],
        'admin.documentProfile.misc.syngrafiiSetting': [{'ON' : 'PSM'}, { 'AB'  : 'PSM'}, {'MB' : 'PSM'}, {'SK' : 'PSM'}, {'BC' : 'PSM'} ],
        'matter.subjectProperty.overrideLegalDesc':[{'ON' : 'z'}],
        'copy.matter.target.fireInsurance' : [{ 'ON'  : 'PM'}, { 'AB'  : 'PM'}, { 'MB'  : 'PM'}, { 'SK'  : 'PM'}, { 'NS'  : 'PM'}, { 'NB'  : 'PM'}],
        'matter.matterParticipant.titleDeedName': [{ 'NB'  : 'SM'}],
        'matter.subjectProperty.surveyInCurrentOwnersName' : [{ 'NB'  : 'PSM'}, { 'NS'  : 'PSM'}],
        'matter.subjectProperty.changeInPropertyAfterSurvey' : [{ 'NB'  : 'PSM'}, { 'NS'  : 'PSM'}],
        'matter.subjectProperty.heatType'  : [{ 'NS'  : 'PSM'}],
        'matter.subjectProperty.hasPropertyMigrated'  : [{ 'NS'  : 'PSM'}],
        'matter.subjectProperty.pan' : [{'NB' : 'PSM'}, { 'NS'  : 'PSM'}],
        'matter.subjectProperty.assessedValue' : [{'NB' : 'P'}],
        'matter.subjectProperty.landTransferTaxRate' : [{'NB' : 'P'}],
        'matter.subjectProperty.form54' : [{'NB' : 'P'}],
        'matter.subjectProperty.rOfWCovenants' : [ {'NB' : 'PSM'}],
        'matter.mortgage.existingMortgage.dischargePreparedBy' : [{'NB'  : 'SM'},{'NS'  : 'SM'}],
        'matter.subjectProperty.pid' : [{'NS'  : 'PSM'}, { 'NB'  : 'PSM'}, {'BC' : 'S'}],
        'admin.documentProfile.soa.interestRateOnPrepaidRent': [{'NS'  : 'PSM'}, { 'NB'  : 'PSM'}],
        'admin.undertakings.mortgage.discharge.config.toMortgagee' : [{ 'NB'  : 'PSM'}],
        'matter.undertaking-detail-modal.bottom.checkboxes' :  [{ 'NB'  : 'PS'}],
        'matter.undertaking.list.column.soadj' :  [{ 'NB'  : 'PS'}],
        'matter.undertaking.list.column.purch' :  [{ 'NB'  : 'PS'}],
        'matter.title.insurance.lender.policy.amount' : [{'NB'  : 'PSM'}],
        'matter.matteropening.linkToMatter.btn' : [{'AB' : 'z'}, {'ON' : 'z'}],
        'matter.subjectProperty.legalDescription.pinDetails' : [{ 'BC' : 'S'}],
        'matter.contact.id.gstNumber' :  [{'BC' : 'S'}],
        'matter.otherParty.notarySocietyMemberLink' : [{ 'BC' : 'S'}],
        'matter.mortgage.existingMortgage.dischargeRegisteredBy' : [{'BC' : 'S'}],
        'matters.purchaser.capacity.fractionalInterest' : [{'BC' : 'S'}],
        'matter.subjectProperty.NRDTTax' : [{'NS' : 'P'}],
        'matter.sale.brokerCommission.commissionDisbursedTo' : [{ 'BC'  : 'S'}]

    };

    // Hidden for specific Province
    static hiddenFieldRestrictions: any = {
        'matter.condoCorporationSubjectToTax' : [{ 'AB'  : 'PSM'}],
        'admin.documentProfile.soa.directionOfFundsPaperSizeCode' : [{ 'AB'  : 'N/A'},
                                                                     { 'MB'  : 'N/A'},
                                                                     { 'SK'  : 'N/A'},
                                                                     //{ 'NB'  : 'N/A'},
                                                                     { 'BC'  : 'N/A'},
                                                                     //{ 'ON'  : 'N/A'},
                                                                     //{ 'NS'  : 'N/A'}
                                                                     ],
        'admin.documentProfile.soa.wordingDirectionFunds' :    [{ 'AB'  : 'N/A'},
                                                                { 'SK'  : 'N/A'},
                                                                { 'NB'  : 'N/A'},
                                                                { 'NS'  : 'N/A'},
                                                                { 'MB'  : 'N/A'}],
        'admin.documentProfile.misc.autoInsertFileNoFlag' : [{ 'AB'  : 'N/A'},
                                                             { 'MB'  : 'N/A'},
                                                             { 'SK'  : 'N/A'},
                                                             { 'NB'  : 'N/A'},
                                                             { 'BC'  : 'N/A'},
                                                             { 'ON'  : 'N/A'},
                                                             { 'NS'  : 'N/A'}],
        'admin.documentProfile.misc.autoCreateTeleFileFlag' : [{ 'AB'  : 'N/A'},
                                                               { 'MB'  : 'N/A'},
                                                               { 'SK'  : 'N/A'},
                                                               { 'NB'  : 'N/A'},
                                                               { 'BC'  : 'N/A'},
                                                               { 'ON'  : 'N/A'},
                                                               { 'NS'  : 'N/A'}],
        'admin.documentProfile.misc.defaultTextPolarisMortgageCode' : [{ 'AB'  : 'N/A'},
                                                                       { 'MB'  : 'N/A'},
                                                                       { 'SK'  : 'N/A'},
                                                                       { 'NB'  : 'N/A'},
                                                                       { 'BC'  : 'N/A'},
                                                                       { 'ON'  : 'N/A'},
                                                                       { 'NS'  : 'N/A'}],
        'admin.documentProfile.misc.autoCompletePlanningActFlag' : [{ 'AB'  : 'N/A'},
                                                                    { 'MB'  : 'N/A'},
                                                                    { 'SK'  : 'N/A'},
                                                                    { 'NB'  : 'N/A'},
                                                                    { 'BC'  : 'N/A'},
                                                                    { 'ON'  : 'N/A'},
                                                                    { 'NS'  : 'N/A'}],
        'admin.documentProfile.misc.wpPrintCommand' :  [{ 'NB'  : 'N/A'},
                                                        { 'ON'  : 'N/A'},
                                                        { 'NS'  : 'N/A'}],
        'admin.documentProfile.misc.swornBeforeMeOrDeclaredBeforeMe' : [{ 'MB'  : 'N/A'},
                                                                        { 'SK'  : 'N/A'}],
        'admin.documentProfile.misc.titleNoInPurchaserOption' : [{ 'AB'  : 'N/A'},
                                                                 { 'NB'  : 'N/A'},
                                                                 { 'BC'  : 'N/A'},
                                                                 { 'ON'  : 'N/A'},
                                                                 { 'NS'  : 'N/A'}],
        'admin.documentProfile.misc.defaultAddressOfService' : [{ 'AB'  : 'N/A'},
                                                                { 'NB'  : 'N/A'},
                                                                { 'BC'  : 'N/A'},
                                                                { 'ON'  : 'N/A'},
                                                                { 'NS'  : 'N/A'}],
        'admin.documentProfile.misc.bindTheCorporationFlag' : [{ 'AB'  : 'NA'},{ 'BC'  : 'NA'}],
        'admin.document.lawFirm.municipality' : [{ 'AB'  : 'N/A'},{ 'BC'  : 'N/A'}],
        'admin.document.lawFirm.docPreparedBy' : [{ 'AB'  : 'N/A'},
                                                  { 'MB'  : 'N/A'},
                                                  { 'SK'  : 'N/A'},
                                                  { 'NB'  : 'N/A'},
                                                  { 'BC'  : 'N/A'},
                                                  { 'NS'  : 'N/A'}],
        'admin.document.lawFirm.lttPreparedBy' : [{ 'AB'  : 'N/A'},
                                                  { 'MB'  : 'N/A'},
                                                  { 'SK'  : 'N/A'},
                                                  { 'NB'  : 'N/A'},
                                                  { 'BC'  : 'N/A'},
                                                  { 'NS'  : 'N/A'}],
        'matter.sale.brokerCommission.AutoInsUpdExcessDepositTrustLedger' : [{'BC' : 'S'}],
        'matter.sale.brokerCommission.commissionBasedOnFixedPercentageOfSalePrice' : [{ 'AB'  : 'S', 'BC' : 'S'}],
        'matter.sale.brokerCommission.totalCommissionPercentage' : [{ 'AB'  : 'S'},
                                                                    { 'SK'  : 'S'},
                                                                    { 'BC' : 'S'}],
        'matter.sale.brokerCommission.vendorsBrokerPercentageOfCommission' : [{ 'BC'  : 'S'}],
        'matter.sale.brokerCommission.purchasersBrokerPercentageOfCommission' : [{ 'BC'  : 'S'}],
        'matter.otherParty.flaTab' : [{'AB' : 'PS'}, {'MB' : 'PS'}, {'NB' : 'PS'}, {'NS' : 'PS'}],
        'matter.common.contact.modal.flaTab' : [{'AB' : 'PS'}, {'MB' : 'PS'}, {'NS' : 'PS'}],
        'matter.titleDetails.fla' : [{'NS' : 'PS'},  {'NB' : 'PS'}],
        'matter.titleDetails.capacity' : [{'NS' : 'PS'},  {'NB' : 'PS'}],

        'matter.otherParty.serviceAddressToUFFIWarrantySection' : [{'AB' : 'P'}, {'SK' : 'P'}, {'MB' : 'P'},{'NB' : 'P'}, {'NS' : 'P'}],

        'matter.mortgage.existingMortgage.payoutEqualsTrustBalance.bridge.financing' : [{ 'AB' : 'S'},{ 'ON' : 'S'},{'SK' : 'S'}, {'MB' : 'S'}],
        'matter.mortgage.existingMortgage.separateChequeDischargeFee' : [{'AB'  : 'SM'}],
        'matter.mortgage.existingMortgage.dischargeFeeSection' : [{'AB'  : 'SM'}],
        'matterAndContact.jurisdiction.waterAndHydroDepartment': [{ 'AB'  : 'PSM'},
                                                         { 'MB'  : 'PSM'},
                                                         { 'NS'  : 'PSM'},
                                                         { 'NB'  : 'PSM'},
                                                         { 'SK'  : 'PSM'}],
        'matterAndContact.jurisdiction.municipalDeedTransferTaxRate': [{ 'AB'  : 'PSM'},
                                                        { 'MB'  : 'PSM'},
                                                        { 'ON'  : 'PSM'},
                                                        { 'NB'  : 'PSM'},
                                                        { 'SK'  : 'PSM'}],
        'matterAndContact.jurisdiction.municipality': [{ 'AB'  : 'PSM'},
                                              { 'MB'  : 'PSM'},
                                              { 'SK'  : 'PSM'}],
        'matterAndContact.jurisdiction.officeNumber': [{ 'AB'  : 'PSM'},
                                              { 'MB'  : 'PSM'},
                                              { 'SK'  : 'PSM'}],
        'matterAndContact.jurisdiction.urlForWebForm': [{ 'AB'  : 'PSM'}],
        'contact.condoCorporation.threeFess' : [{'AB' : 'N/A'}, {'SK' : 'N/A'}, {'MB' : 'N/A'}],
        'matter.condoCorporation.threeFess' : [{'AB' : 'PSM'}, {'SK' : 'PSM'}, {'MB' : 'PSM'}, {'BC' : 'S'}],

        'matter.subjectProperty.writs': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.qualifiers': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.propertyDetails': [{ 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.addAndOrder': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.billing': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.action': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.imageCheckbox': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.subjectProperty.amount': [{ 'AB'  : 'PSM'}],
        'matter.subjectProperty.partiesFrom': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, {'MB' :'PSM'}],
        'matter.subjectProperty.certCred': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, {'MB' :'PSM'}],
        'matter.propertyTeranet.isParcelOfTiedLand' :[{'AB' :'PSM'}, {'SK' :'PSM'}, {'MB' :'PSM'}, {'NS' : 'PSM'},{'NB' :'PSM'}, {'BC': 'S'}],
        'matter.propertyTeranet.registryOrLt' :[{'AB' :'PSM'}, {'SK' :'PSM'}, {'MB' :'PSM'}, {'BC': 'S'}],
        'matter.propertyTeranet.propertyTax.label.AB' : [{ 'AB'  : 'M'}],
        'matter.subjectProperty.condoFields' :[{'AB' :'PSM'}, {'SK' :'PSM'}, {'MB' :'PSM'}, {'NS' : 'PSM'}, {'BC': 'S'}],
        'matter.subjectProperty.parcelSection' : [{'AB' : 'PSM'}, {'SK' : 'PSM'}, {'MB' : 'PSM'}, {'NS' : 'PSM'},{'NB' : 'PSM'}, {'BC': 'S'}],
        'matter.propertyTeranet.isCondo': [{'BC': 'S'}],
        'matter.subjectProperty.city': [{'BC': 'S'}],
        'matter.subjectProperty.beingPartAndPlan' : [{'NB' : 'PSM'}],
        'matter.subjectProperty.easementRightOfWay' : [{'AB' : 'PSM'} , {'SK' : 'PSM'}, {'MB' : 'PSM'}, {'NB' : 'PSM'}, {'NS' : 'PSM'}, {'BC': 'S'}],
        'matter.subjectProperty.municipality' : [{'AB' : 'PSM'}, {'SK' : 'PSM'}, {'MB' : 'PSM'}, {'BC': 'S'}],
        'matter.condoCorporation.feeForStatusCertificate' : [{'AB': 'PSM'}],
        'admin.mortgageEnclosure.interimElcosure.tableHead' : [{'AB' : 'NA'}],
        'admin.mortgageEnclosure.interimElcosure.tableBody' : [{'AB' : 'NA'}],
        'matter.mainClient.client.titleDetailsLink' : [{'AB' : 'PSM'},{'SK' : 'PSM'},{'MB' : 'PSM'},{'BC' : 'PSM'},{'NB' : 'PM'},{'NS' : 'PM'}],
        'matter.reportToPurchaser.mostRecentTaxBill' : [{'AB' : 'P'}, {'SK' : 'P'}, {'MB' : 'P'}],
        'matter.reportToPurchaser.moniesPaid' : [{'AB' : 'P'}, {'SK' : 'P'}, {'MB' : 'P'}],
        'matter.reportToPurchaser.reFuturePayments' : [{'AB' : 'P'}, {'SK' : 'P'}, {'MB' : 'P'}],
        'matter.reportToPurchaser.instalmentDates' : [{'AB' : 'P'}, {'SK' : 'P'}, {'MB' : 'P'}],
        'matter.reportToPurchaser.supplementaryTaxBill' : [{'AB' : 'P'}, {'SK' : 'P'}, {'MB' : 'P'}],
        'matter.reportToPurchaser.precedentsRequisitions' : [{'AB' : 'P'}, {'SK' : 'P'}, {'MB' : 'P'}, {'NS' : 'P'}, {'NB' : 'P'}],
        'matter.reportToPurchaser.no-yes-options' : [{'SK' : 'P'}, {'MB' : 'P'}],
        'matter.subjectProperty.rollNumberFields' : [{'AB' : 'PSM'}, {'SK' : 'PSM'}, {'MB' : 'PSM'}, {'NS' : 'PSM'},{'NB' : 'PSM'},{'BC': 'S'}],
        'matter.subjectProperty.lastTransfer' : [{'AB' : 'PSM'}, {'SK' : 'PSM'}, {'MB' : 'PSM'}, {'NS' : 'PSM'},{'NB' : 'PSM'}, {'BC': 'S'}],
        'matter.subjectProperty.unitLevelPlan.location' : [{'AB'  : 'PSM'},
                                                           {'NS'  : 'PSM'},
                                                           {'NB'  : 'PSM'}],
        'matter.subjectProperty.unitLevelPlan.planType' : [{ 'NS'  : 'PSM'},
                                                           { 'NB'  : 'PSM'}],
        'matters.property.teranetConnect.pdf' : [{ 'MB'  : 'PSM'}],
        'matter.matterProperty.unitLevelPlan.level' : [{'AB' : 'PSM'}],
        'matter.matterProperty.unitLevelPlan.pin' : [{'AB' : 'PSM'}],
        'matter.undertaking.addNewButton' : [{ 'AB'  : 'PSM'},
                                             { 'SK'  : 'PSM'},
                                             { 'MB'  : 'PSM'},
                                             { 'NS'  : 'PSM'}],
        'matter.undertaking.fromLabel' :  [{ 'AB'  : 'P'}, { 'SK'  : 'P'}, { 'MB'  : 'P'}, { 'NS'  : 'P'}, { 'NB'  : 'P'}],
        'matter.undertaking.discharged' :  [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.undertaking.registered' :  [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'matter.undertaking-detail-modal.discharged' :  [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'admin.trustLedger.landTransferTaxes' : [{ 'AB'  : 'NA'}, { 'SK'  : 'NA'}, { 'MB'  : 'NA'}],
        'admin.trustLedger.ontarioTorontoLTT' : [{ 'NS'  : 'NA'}, { 'NB'  : 'NA'}],
        'admin.trustLedger.non.resident.speculation.tax' : [{ 'NS'  : 'NA'}, { 'NB'  : 'NA'}],
        'matter.soa.hst-sale-price-modal.creditTo' : [{ 'AB'  : 'PSM'},{ 'SK'  : 'PSM'},{ 'MB'  : 'PSM'}],
        'matter.soa.hst-sale-price-modal.adjustFor' : [{ 'AB'  : 'PSM'},{ 'SK'  : 'PSM'},{ 'MB'  : 'PSM'}],
        'matter.soa.modal.calculatedAt' : [{ 'AB'  : 'PSM'}],
        'matter.soa.sale-price-modal.creditVendor' : [{ 'AB'  : 'PSM'},{ 'MB'  : 'PSM'},{ 'SK'  : 'PSM'}],
        'matter.undertaking.subject' :  [{ 'AB'  : 'PSM'}],
        'matter.subjectProperty.adjacentParcelAnalysis': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'},{ 'MB'  : 'PSM'}],
        'matter.subjectProperty.instDropdownMenu': [{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'},{ 'MB'  : 'PSM'}],
        'matter.soa.other-prorated-modal.hstRate' : [{ 'AB'  : 'PSM'},{ 'MB'  : 'PSM'},{ 'SK'  : 'PSM'}],
		'matter.soa.prepaidTenancy.interestRateModel.legal': [{ 'AB'  : 'PSM'},{ 'MB'  : 'PSM'},{ 'SK'  : 'PSM'},{ 'NB'  : 'PSM'}, { 'NS'  : 'PSM'}],
        'matter.reportToPurchaser.asInstrumentNo': [{ 'SK'  : 'P'}],
        'matter.reportToPurchaser.provideTaxInfo' : [{ 'AB'  : 'P'}],
        'matter.reportToPurchaser.provideTaxInfoDiv' : [{ 'NS'  : 'P'}],
        'matter.mortgage.assignmentRequestNo': [{ 'ON'  : 'SM'}, { 'MB'  : 'SM'}, { 'SK'  : 'SM'}, { 'NB'  : 'SM'},  { 'NS'  : 'SM'}, { 'AB'  : 'SM'}, {'BC'  : 'SM'}],
        'matter.subjectProperty.unitLevelPlan': [{ 'SK'  : 'PSM'}, { 'MB'  : 'PSM'}],
        'ps.matter.subjectProperty.unitLevelPlan.fieldLbl.hide': [{ 'ON'  : 'z'}],
        'admin.statement.account.config.electronicMortgage' : [{ 'SK'  : 'NA'}, {'NS' : 'NA'}],
        'admin.statement.account.config.titleInsurancePremium' : [{ 'NS'  : 'NA'}],
        'matter.subjectProperty.condominiumType' : [{ 'MB'  : 'PSM'}],
        'soa.commonExpenseAmount.editAmount' : [{ 'MB'  : 'PSM'}, {'SK'  : 'PSM'}],
        'matter.soa.water-soa-modal.adjustFor' : [{ 'MB'  : 'PS'}, {'SK'  : 'PS'}],
        'emp.insert.lender.parcel.hide': [{'AB' : 'PM'}, { 'MB'  : 'PSM'}, { 'NB'  : 'PM'}, { 'SK'  : 'PM'},  { 'NS'  : 'PSM'}],
        'emp.insert.lender.partLotPlan.hide' :[{'AB' :'PM'}, {'MB' :'PM'}, {'SK' :'PM'},  { 'NS'  : 'PSM'}],
        'emp.insert.lender.beingPart.hide' :[{'AB' :'PM'}, {'MB' :'PM'}, { 'NB'  : 'PM'}, { 'SK'  : 'PM'},  { 'NS'  : 'PSM'}],
        'matter.statementAccount.NoMBSKBC' : [{'MB' :'PSM'}, {'SK' :'PSM'}, {'BC': 'PSM'}],
        'matter.statementAdjustment.NoMBSK' : [{'MB' :'PSM'}, {'SK' :'PSM'}],
        'matter.subjectProperty.purchasePrice' :  [{ 'ON'  : 'Mz'},{ 'AB'  : 'M'}, { 'SK'  : 'M'}, { 'MB'  : 'M'}, { 'NB'  : 'M'}, { 'NS'  : 'M'}, { 'BC'  : 'PSM'}],
        'matter.subjectProperty.depositAmount'  :  [{ 'ON'  : 'Mz'},{ 'AB'  : 'Mz'}, { 'SK'  : 'M'}, { 'MB'  : 'M'}, { 'NB'  : 'M'}, { 'NS'  : 'M'}],
        'matter.propertyTeranet.propertyTax' : [{ 'ON'  : 'Mz'}, { 'AB' : 'z'}, { 'NB'  : 'M'}, { 'NS'  : 'M'}, {'BC': 'S'}],
        'matter.mainClient.uffi': [{ 'ON'  : 'Pz'},{ 'AB'  : 'PSM'}, { 'SK'  : 'PSM'}, { 'MB'  : 'PSM'},{ 'NB'  : 'PM'},{ 'NS'  : 'PM'},{ 'BC'  : 'PSM'}],
        'matter.mainClient.residingAtSubjectProperty' : [{ 'ON'  : 'z'},{ 'AB'  : 'z'}],
        'matter.matterOpening.occupancyDate': [ {'ON': 'z'},  {'AB': 'M'}, {'NB': 'M'}, {'NS': 'M'}],
        'matter.subjectProperty.propertyTaxesSummaryOption':[{ 'ON'  : 'z'}, { 'NS'  : 'PSM'}, { 'NB'  : 'PSM'}, { 'BC' : 'S'}],
        'matter.subjectProperty.addressOption': [{ 'ON'  : 'z'},  { 'NS'  : 'PSM'}, { 'NB'  : 'PSM'}, {'BC' : 'S'}],
        'matter.matterOpening.outOfProvincePurchaserExecDocsAt' : [{ 'ON'  : 'PSMW'}, {'BC' : 'PSM'}, {'AB' : 'W'}, {'NS' : 'W'}, {'NB' : 'W'}, {'MB' : 'W'}, {'SK' : 'W'}],
        'matter.signingLocation.outOfProvince' : [{ 'ON'  : 'PSM'}],
        'matter.vendors-solicitor.outOfProvinceVendorExecDocsAt'  : [{ 'ON'  : 'PSM'}],
        'matter.matterOpening.conveyDotCa' : [{ 'ON'  : 'P'}, { 'AB'  : 'P'}],
        'matter.mortgage.report.mtgFinalReportToMortgageeEnclosures': [{ 'NB'  : 'S'}, { 'NS'  : 'S'}],
        // 'matter.propertyTeranet.insurer.propertyType': [{ 'NB'  : 'PSM'}],
        'matter.subjectProperty.pin' : [{ 'NS'  : 'PSM'}, { 'NB'  : 'PSM'}, {'BC' : 'S'}],
        'matter.subjectProperty.jurisdiction': [{ 'BC' : 'S'}],
        'matter.subjectProperty.isCondo': [{ 'BC' : 'S'}],
        'matter.subjectProperty.pinDetails' : [{'AB' : 'PSM'}, {'SK' : 'PSM'}, {'MB' : 'PSM'}, { 'BC' : 'S'}],
        'matter.propertyTaxes.payOutOfTrustAccount' : [{ 'NB' : 'PSM'}],
        'matter.matteropening.linkToMatter.burgerMenu' : [{'AB' : 'z'}],
        'matter.mortgage.existingMortgage.mortgageDispositionType' : [{'BC' : 'S'}],
        'matter.matterOpening.wizard.referral' : [{'BC' : 'S'}],
        'matters.purchaser.capacity.purchaserShare' : [{'BC' : 'S'}],
        'matter.mortgage.mortgagee.tenure' : [{'BC' : 'S'}]
    };

    ngOnDestroy() {}
    ngAfterViewInit() {}
}
