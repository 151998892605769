import {StewartTitleService} from '../../../shared-main/stewart-title/stewart-title-service';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';


class TitlePlusPortalContext extends BSModalContext {
    url: string;
}


@Component({
    selector: 'dp-title-plus-portal-modal',
    templateUrl: 'title-plus-portal.modal.component.html',
})
export class TitlePlusPortalModalComponent implements OnInit, ModalComponent<TitlePlusPortalContext> {

    context: TitlePlusPortalContext;
    titlePlusPortalURL : SafeUrl;

    @ViewChild('titlePlusFrame') titlePlusFrame: ElementRef;

    constructor(public dialog: DialogRef<TitlePlusPortalContext>,
                public sanitizer: DomSanitizer,
                public stewartTitleService: StewartTitleService
    ) {
        this.context = dialog.context;
    }

    ngOnInit() {
      this.titlePlusPortalURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.url);
    }

    close(): void {
        this.dialog.close({action: 'Close'});
    }

}
