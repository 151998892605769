import {Directive, EventEmitter, Output} from '@angular/core';

@Directive({
    selector: '[ngModel][dp-lowercase]',
    host: {
        '(input)': 'onInputChange($event)'
    }
})
export class LowercaseDirective {

    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
    value: string;

    onInputChange($event): void{
        this.value = $event.target.value.toLowerCase();
        this.ngModelChange.emit(this.value);
    }
}
