import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NgModel} from '@angular/forms';
import Utils from './utils';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

declare var jQuery:any;

@Directive({
    selector: '[ngModel][dp-percentage]',
    host: {
        '(input)': 'onInput($event)'
    }
})

@AutoUnsubscribe()
export class PercentageDirective {

    @Input('max-decimals') maxFractDigits : number = 3;
    @Input('max-int') maxInt: number = 100;
    @Input('max-value') maxValue;
    @Input('padding-zero') paddingZero : boolean = false;
    @Input('pad-whole-numbers') padWholeNumbers: boolean = true;

    element: HTMLInputElement;
    valueChangesSubscription: Subscription;

    constructor(private model: NgModel,
                private elementRef: ElementRef,) {
        this.element = this.elementRef.nativeElement;
    }

    ngOnInit() : void {
        if(!this.maxValue){
            this.maxValue = this.maxInt;
        }
        // When click save button, sometimes back end lost the end zeros (for example: 2.3000 becomes 2.3).
        // When the model valueChange and without focus, it will do the format.
        this.valueChangesSubscription = this.model.valueChanges.subscribe(this.onValueChanges);
    }

    ngAfterViewInit() {
        if(this.isPaddingRequired()) {
            // If there is not timeout, this.model.value will be empty
            setTimeout(() => { this.element.value = Utils.formatWithDecimals(Number(this.model.value), this.maxFractDigits);}, 0);
        }
    }

    private onValueChanges = () : void => {
        //When click save button, sometimes back end lost the end zeros (for example: 2.3000 becomes 2.3)
        //If it is onValueChanges without focus, it will do the format.
        if(!jQuery(this.element).is(':focus')) {
            if(this.isPaddingRequired()) {
                this.element.value = Utils.formatWithDecimals(Number(this.model.value), this.maxFractDigits);
            }
        }
    }

    onInput() {
        if (this.model.value) {
            //console.log('input:' + this.model.value);

            let val = this.model.value.replace(/[^\d\.]/g, '');

            // more then one . ?
            if(val.indexOf(".") > -1 && val.split(".").length > 2){
                val = val.substring(0,val.split(val, 1).join(val).length);
            }
            if(val.indexOf(".") > -1) {
                let parts = val.split('.');
                let intPart: number = Number(parts[0]);
                let fractPart: string = parts[1];

                if (intPart >= this.maxValue){
                    intPart = this.maxValue;
                    if (this.maxValue == 100) {
                        fractPart = '0';
                    }
                    else {
                        if (Number(fractPart) > 99) {
                            fractPart = '99'; // 999.99%
                        }
                    }

                }
                else{
                    if (fractPart.length > this.maxFractDigits){
                        fractPart = fractPart.toString().substring(0, this.maxFractDigits);
                    }
                }
                if (parts[1].length == 0){ // don't force a 0 if fractPart was empty as "backspace" will not work
                    val = `${intPart}.`;
                }
                else {
                    val = `${intPart}.${fractPart}`;
                }
            }
            else{
                let intPart = +val;
                if(intPart > this.maxValue){
                    intPart = this.maxValue;
                }
                val = intPart;
            }
            this.model.reset(val);
        }
    }


    @HostListener('blur') onBlur(): void {
        let val: string = this.model.viewModel;

        if (val != undefined && val != null && !Number.isNaN(parseFloat(val)))  {

            if (this.isPaddingRequired()) {
                val = Utils.formatWithDecimals(Number(this.model.value), this.maxFractDigits);
            }
        }
        else{
            val = this.isPaddingRequired() ? Utils.formatWithDecimals(Number('0'), this.maxFractDigits) : '0.00';
        }
        this.model.reset(val);
    }

    isPaddingRequired(): boolean {
        //when padWholeNumbers is false, only pad numbers that contain decimals
        return this.paddingZero && (this.padWholeNumbers || String(this.model.value).indexOf('.') > 0);
    }

    ngOnDestroy() {}

}
