import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DocumentProductionData} from '../document-production-data';
import {DocumentStopCode} from '../document-stop-code';


@Component({
               selector: 'dp-stop-codes-common',
               templateUrl: 'stop-codes-common.component.html',
               styleUrls: ['./stop-codes-common.component.scss']
           })
export class StopCodesCommonComponent implements OnInit, OnDestroy, OnChanges{

    @Input('documentProductionData') documentProductionData : DocumentProductionData;
    public templateStopCodesArray:any = [];

    constructor() {

   }

    ngOnInit() : void {
        this.createStopCodesParentLink();
    }

    ngOnDestroy() : void {

    }

    ngOnChanges(changes : SimpleChanges) : void {
        // only run when property "documentProductionData" changed
        if (changes['documentProductionData']) {
            this.createStopCodesParentLink();
        }
    }



    /**
     * This method creates the link list of stop code where each stop code points to the previous stop code in the list that has same code.
     * we are creating this linkage to handle the logic that is if same stop code appears again then it's value will be defaulted with the
     * response entered in previous stop code with same code. However, the default value is provided only on a move-forward basis which means
     * if user overrides the default value then the default text in all subsequent stop codes will be also updated with the new response.
     */
    createStopCodesParentLink(): void {
        this.templateStopCodesArray = [];
        if(this.documentProductionData !== undefined && this.documentProductionData.templates.length > 0) {
            for(let template of this.documentProductionData.templates) {
                let stopCodeWrappers : Array<DocumentStopCodeWrapper> = [];
                if(template.stopCodes.length > 0) {
                    for(let i = 0; i < template.stopCodes.length; i++) {
                        let stopCode = template.stopCodes[i];
                        let parentStopCode : DocumentStopCodeWrapper;
                        //If stop code is valid then set it's parent for sourcing the default value
                        if(stopCode.valid) {
                            parentStopCode = this.getParentStopCode(stopCodeWrappers, stopCode);
                        } else {
                            // The parent stop code is the previous stop code in list
                            // which is non valid code with same multiClientStopIndex.
                            parentStopCode = this.getParentStopCodeForNonValidCodes(stopCodeWrappers, stopCode);
                        }
                        let documentStopCodeWrapper : DocumentStopCodeWrapper = new DocumentStopCodeWrapper({
                            documentStopCode : stopCode,
                            parentStopCode   : parentStopCode,
                            hasUserDefinedValue : !!stopCode.value,

                        } as DocumentStopCodeWrapper);
                        //add the stop code wrapper to the array that will be used in UI to display stop codes
                        stopCodeWrappers.push(documentStopCodeWrapper);
                    }
                    this.templateStopCodesArray.push({template : template, stopCodeWrappers : stopCodeWrappers})
                }
            }
        }
    }

    /**
     * This method finds the parent stop code for a given stop code. The parent stop code is the previous stop code in list that exists
     * with same code.
     * @param stopCodeWrappers
     * @param currentStopCode
     * @returns {DocumentStopCodeWrapper}
     */
    getParentStopCode(stopCodeWrappers : Array<DocumentStopCodeWrapper>, currentStopCode: DocumentStopCode): DocumentStopCodeWrapper {
        if(stopCodeWrappers.length > 0) {
            for(let i = stopCodeWrappers.length-1; i>=0; i--) {
                let parentStopCode = stopCodeWrappers[i];
                if(parentStopCode.documentStopCode.code == currentStopCode.code) {
                    return parentStopCode;
                }
            }
        }
    }


    /**
     * This method finds the parent stop code for a given stop code. The parent stop code is the previous stop code in list that exists
     * which is non valid code with same multiClientStopIndex.
     * @param stopCodeWrappers
     * @param currentStopCode
     * @returns {DocumentStopCodeWrapper}
     */
    getParentStopCodeForNonValidCodes(stopCodeWrappers : Array<DocumentStopCodeWrapper>, currentStopCode: DocumentStopCode): DocumentStopCodeWrapper {
        if(stopCodeWrappers.length > 0) {
            for(let i = stopCodeWrappers.length-1; i>=0; i--) {
                if(currentStopCode.multiClientStopIndex && (stopCodeWrappers[i].documentStopCode.multiClientStopIndex == currentStopCode.multiClientStopIndex)){
                    return stopCodeWrappers[i];
                }
            }
        }
        return null;
    }

    onClickOk(): void {
        this.copyValueFromWrapperToDocumentStopCode();
    }

    onClickCancel(): void {
        this.clearStopCodeValues();
    }

    clearStopCodeValues(): void {
        for(let template of this.documentProductionData.templates) {
            for(let stopCode of template.stopCodes) {
                stopCode.value = "";
            }
        }
    }

    /**
     * This method is called after user submits the stop codes for processing.
     * Since wrapper class is bound to UI therefore in this method copying the
     * value of stop code from wrapper to actual stop code.
     */
    copyValueFromWrapperToDocumentStopCode(): void {
        if(this.templateStopCodesArray.length > 0) {
            for(let item of this.templateStopCodesArray) {
                if(item.stopCodeWrappers.length > 0) {
                    for(let stopCodeWrapper of item.stopCodeWrappers) {
                        stopCodeWrapper.documentStopCode.value = stopCodeWrapper.displayValue;
                    }
                }
            }
        }
    }

    getClientName(stopCodeWrapper: DocumentStopCodeWrapper): string {
        return stopCodeWrapper.documentStopCode && stopCodeWrapper.documentStopCode.clientName
            ? ('Client Name: ' + stopCodeWrapper.documentStopCode.clientName): '';
    }
}


//This is a wrapper class created on top of Document Stop code. It handles the logic of displaying stop code value in UI,
//If user has entered the value in a stop code then it displays that value else retrieves the value from parent stop code.
class DocumentStopCodeWrapper {
    //reference to the actual stop code
    documentStopCode: DocumentStopCode;
    //reference of the previous stop code in the list that has same code as this one
    parentStopCode: DocumentStopCodeWrapper;
    //indicates if the value of this stop code has been entered by use. if yes then it won't be override with default value.
    hasUserDefinedValue: boolean = false;
    //this is the actual data model property which bounded with UI
    public _displayValue: string;


    constructor(documentStopCodeWrapper? : DocumentStopCodeWrapper) {
        if (documentStopCodeWrapper) {
            for(let prop in documentStopCodeWrapper) {
                if(documentStopCodeWrapper.hasOwnProperty(prop)) {
                    this[prop] = documentStopCodeWrapper[prop];
                }
            }
        }
    }


    /**
     * This method returns the value to be displayed in UI for a stop code.
     * It checks if user has already entered the value in this stop code then returns that
     * else if retrieves the display value from the parent stop code.
     * It is a recursive call will keep checking parent stop code unless it reaches to the node
     * that has no parent or has a user defined value
     * @returns {any}
     */
    get displayValue() : string {
        if(this.hasUserDefinedValue) {
            return this.documentStopCode.value;
        } else if(this.parentStopCode !== null && this.parentStopCode !== undefined) {
            return this.parentStopCode.displayValue;
        } else {
            return "";
        }
    }

    /**
     * This method bounds the value entered by user in stop code on UI to the data model.
     * It sets that value into the actual document stop code and marks it as ownValueDefined
     * @param value
     */
    set displayValue(value : string) {
        this.documentStopCode.value = value;
        this.hasUserDefinedValue = true;
        this._displayValue = value;
    }
}
