import {Tab} from '../shared/tabbing/tab';
import {Matter} from './shared/matter';
import {ErrorTab} from '../shared/error-handling/error-tab';
import {MatterComponent} from './matter.component';
import {MatterListState} from './shared/matter-list-state';
import {VendorsSolicitorState} from './vendors-solicitor/vendors-solicitor-state';
import {TeranetUser} from '../shared-main/teranet/teranet-user';
import {MatterListFilter} from './matter-list/matter-list-filter';
import {Cirf} from './shared/cirf/cirf';
import {MatterPollingData} from '../../app/core/matter-polling-data';

/**

 */
export class MatterTab extends Tab {


    constructor(json?: MatterTab) {
        super(json);
        if (json) {
            this.matter = new Matter(json.matter);
            if (json._backEndMatter) {
                this.backEndMatter = new Matter(json.backEndMatter);
            }
            this.errorTab = new ErrorTab(json.errorTab);
        }

    }

    //Current state of matter in Tab
    matter: Matter;
    //This is the copy of matter used to compare between current matter and backend matter.
    _backEndMatter: Matter;
    //This is the copy of matter used to check if DP codes used in matter need to be re-calculated. It compares the current matter with the matter state
    // last used for fetching DP codes. It is initialized same as backend matter copy.
    docGenReferenceMatter: Matter;
    linkedMatter: Matter;
    isFieldCodeTurnedOn: boolean = false;
    matterComponent: MatterComponent;
    errorTab: ErrorTab;
    updateThirdPartyData: boolean = false;
    fieldCodeMappingData: any[] = [];
    /* state for matter anchor tab */
    matterListState: MatterListState;
    /* Authenticated teranet user*/
    loggedInTeranetUser: TeranetUser;
    //Fields used to navigate between project sale matters
    sortedProjectMatters: Matter[];
    focusedElement: any;
    childComponentSelection: string; // Used to land on the same child component within the parent tab
    isMatterTabLoading: boolean = false;
    /* state for vendors & solicitors */
    vendorsSolicitorState: VendorsSolicitorState;

    massUpdateMatterListFilter: MatterListFilter;

    /* Component State Attributes starts */
    /*  In case of switching between matter tabs while opening the same component,
        the following attributes are used to save the current state of the component
        For example checkboxes states  */
    //DocumentProductionTemplateComponent
    documentProductionSelectedDocumentIds: string[] = [];
    documentProductionMultiSelectStatus: boolean;
    //DocumentsForThisMatterComponent
    matterDocumentsAllTemplatesCheckBox: boolean;

    altoUserName: string;
    altoPassword: string;

    matterCirf: Cirf;
    participantCirfs: Cirf[] = [];
    inProgressPolling: MatterPollingData[] = [];

    showSnapshotDiffSourceContact: boolean = false;

    /* Component State Attributes ends */

    softDeleteSourceContactIdList = [];

    softDeleteSignSourceContactIdList = [];

    duplicateProspectCheckPerformed: boolean;

    static createMatterTab(matter: Matter): MatterTab {
        let tab: MatterTab = new MatterTab();
        tab.tabType = 'matter';
        tab.matter = matter;
        tab.backEndMatter = new Matter(matter);
        tab.type = matter.matterType;
        tab.route = 'main/tabs/matter';
        return tab;
    }

    initiateMatterTab(matter: Matter): void {
        this.matter = matter;
        this.backEndMatter = new Matter(matter);
        this.isFieldCodeTurnedOn = false;
        this.updateThirdPartyData = false;
    }

    set backEndMatter(matter: Matter) {
        this._backEndMatter = matter;
        this.docGenReferenceMatter = matter;
    }

    get backEndMatter(): Matter {
        return this._backEndMatter;
    }

    get tabTypeForDisplay(): string {
        if (this.matter.isProjectSale) {
            return (this.isMassUpdateSubType() ? 'Mass Update' : 'Project ' + this.type);
        }
        else if (this.matter.isCustomMatter()) {
            return this.matter.customMatterTypeDesc;
        }
        else if(this.matter.isMatterTypeDischarge){
            return 'DISCHARGE';
        }
        else {
            return this.type;
        }
    }


    get matterProvinceCode(): string {
        return this.matter && (this.matter.provinceCode as string);
    }

    get title(): string {
        let matterTabTitle = this.matter.matterRecordNumber;
        if (this.matter.isProjectSale) {
            matterTabTitle = (this.matter.projectRecordNumber ? this.matter.projectRecordNumber + ' - ' : '') + matterTabTitle;
        }
        return matterTabTitle;
    }

    /**
     * We need the setter so the property is not made readonly (relevant when deserializing from json) but we don't store the value,
     * as we're retrieving it dynamically from the matter (see the getter).
     * @param title
     */
    set title(title: string) {
        //Ignore, retrieved from matter
    }

    get lineNumber(): string {
        return this.matter.getMatterReline();
    }

    isLocked(): boolean {
        return this.matter.locked;
    }

    isDirty(): boolean {
        return this.matter.dirty;
    }

    /**
     * We need the setter so the property is not made readonly (relevant when deserializing from json) but we don't store the value,
     * as we're retrieving it dynamically from the matter (see the getter).
     * @param title
     */
    set lineNumber(lineNumber: string) {
        //Ignore, retrieved from matter
    }


    get subjectProperty(): string {
        let subjectProperty: string = '';
        if (this.matter.matterProperties && this.matter.matterProperties[0]) {
            if (this.matter.matterProperties[0].address.addressLine1 != null) {
                subjectProperty = this.matter.matterProperties[0].address.addressLine1;
            }
            if (this.matter.matterProperties[0].address.city != null) {
                subjectProperty = subjectProperty + ", " + this.matter.matterProperties[0].address.city;
            }
            if (this.matter.matterProperties[0].address.provinceCode != null) {
                subjectProperty = subjectProperty + ", " + this.matter.matterProperties[0].address.provinceCode;
            }

        }
        return subjectProperty;
    }

    /**
     * We need the setter so the property is not made readonly (relevant when deserializing from json) but we don't store the value,
     * as we're retrieving it dynamically from the matter (see the getter).
     * @param title
     */
    set subjectProperty(sp: string) {
        //Ignore, retrieved from matter
    }

    get id(): number {
        return (!this.matter.id && this.matter.tempIdForNewMatter) ? this.matter.tempIdForNewMatter : this.matter.id;
    }

    /**
     * We need the setter so the property is not made readonly (relevant when deserializing from json) but we don't store the value,
     * as we're retrieving it dynamically from the matter (see the getter).
     * @param title
     */
    set id(id: number) {
        //Ignore, retrieved from matter
    }

    deCoupleUIComponent(): void {
        this.matterComponent = null;
        [this.matter, this.backEndMatter, this.linkedMatter] // add here any other instances of the matter that are added to this tab (do we need more ?)
            .filter(Boolean)
            .forEach( m => m.clearCircularReferencesBeforeStringify());
    }

    isLinkedMatterProjectSale() : boolean {
        return this.linkedMatter && this.linkedMatter.isProjectSale;
    }

    get docuSingInProgressPolling() : MatterPollingData {
        return  this.inProgressPolling.find( ip => ip.isDocuSignPolling());
    }

    removeDocuSignInProgressPolling() : void {
      let index  =  this.inProgressPolling.findIndex( ip => ip.isDocuSignPolling());
        let inProgressPoller  =  this.inProgressPolling.find( ip => ip.isDocuSignPolling());
        if (inProgressPoller && inProgressPoller.pollingIntervalSubscription && !inProgressPoller.pollingIntervalSubscription.closed) {
            inProgressPoller.pollingIntervalSubscription.unsubscribe();
        }
        this.inProgressPolling.splice(index,1);
    }
}
