import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetPropertyOwner} from './teranet-property-owner';
import {TeranetPinInfo} from './teranet-pin-info';

export class TeranetParcelRegisterSearchResponse extends BaseEntity {
    lroNumber: string;
    propertyOwners: TeranetPropertyOwner[] = [];
    searchedProperties: TeranetPinInfo[] = [];

    constructor(t?: TeranetParcelRegisterSearchResponse) {
        super(t);
        if(t) {
            if(t.propertyOwners) {
                for(let f of t.propertyOwners) {
                    this.propertyOwners.push(new TeranetPropertyOwner(f));
                }
            }

            if(t.searchedProperties) {
                for(let f of t.searchedProperties) {
                    this.searchedProperties.push(new TeranetPinInfo(f));
                }
            }
        }
    }
}
