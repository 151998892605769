import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';

export class MortgagePayoutAdditionalPayments extends BaseEntity {
    id : number;
    additionalExplanation : string;
    additionalAmount : number;
    mortgagePayoutId : number;

    constructor(mortgagePayoutAdditionalPayment? : MortgagePayoutAdditionalPayments) {
        super(mortgagePayoutAdditionalPayment);
        if(!mortgagePayoutAdditionalPayment)
        {
            this.additionalAmount = 0;
        }
    }
}
