import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {TabsService} from '../../core/tabs.service';
import {UnSavedChangesComponent} from '../../shared-main/unsavedchanges.guard';
import {messages} from '../../shared-main/messages';
import {Account} from '../accounts/shared/account';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../shared/dialog/dialog.service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {TabsComponent} from '../../shared/tabbing/tabs.component';
import {SupplementalTaskService} from '../../shared-main/supplemental-task-category/supplemental-task-service';
import {SupplementalTaskCategory} from '../../shared-main/supplemental-task-category/supplemental-task-category';
import {ProvinceCode} from '../accounts/shared/account-province';
import {ConfigTab} from '../../shared/tabbing/config-tab';
import {AppConfig} from '../../shared-main/app-configuration';
import {MatterTypeUtil} from '../../shared-main/matter-type/matter-type-util';
import {MatterTypeInfo} from '../shared/matter-type-info';
import {AccountService} from '../accounts/account.service';


@Component({
    selector    : 'dp-supplemental-task-categories',
    templateUrl : './supplemental-task-categories.component.html',
    styleUrls: [
        './supplemental-task-categories.component.scss'
    ]
})

@FocusFirstElementDecorator()

export class SupplementalTaskCategoriesComponent implements OnInit, UnSavedChangesComponent {

    constructor(public tabsStateService: TabsService, public route : ActivatedRoute, public supplementalTaskService : SupplementalTaskService , public appConfig: AppConfig,
                public dialogService : DialogService, public router : Router ,public parentTabsComponent : TabsComponent,
                public accountService: AccountService) {}

    tableData: SupplementalTaskCategory[];
    burgerMenuItems: string[] = ['Edit'];
    msgs: any = messages;
    account: Account;
    matterTypeInfos: MatterTypeInfo[] = [];

    get activeAccountTab() : ConfigTab {
        return this.tabsStateService.activeTab as ConfigTab;
    }

    get selectedProvinceCode(): ProvinceCode {
        return this.activeAccountTab.selectedProvince.provinceCode;
    }

    initializeAccount() : void {
        if(this.activeAccountTab.account) {
            this.account = this.activeAccountTab.account;
        }
    }

    ngOnInit() {
        this.initializeAccount();
        this.getSupplementaryTaskCategories();
        this.loadMatterTypeDetails();
    }

    async loadMatterTypeDetails() {
        this.matterTypeInfos = await this.accountService.getMatterTypesDetails(this.account).toPromise();
    }

    public getSupplementaryTaskCategories() : void {
        let id = this.account? this.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        this.supplementalTaskService.getSupplementalTaskCategories(id, this.selectedProvinceCode)
            .subscribe(
                (data: SupplementalTaskCategory[]) => {
                    this.tableData = data;
                    console.log(this.tableData);
                },
                error => console.log(),
                () => console.log('completed')
            );
    }

    unsavedChanges(): Observable<boolean> | boolean {
        return true;
    }
    setUnsavedChangesFormMessage() : string {
        return  "There are unsaved changes in User Profiles, Do you wish to save before proceeding ?";
    }

    saveChanges() : Observable<boolean> | boolean {
        return true;
    }

    createNewTask() {
        if(Array.isArray(this.tableData) && this.tableData.length >= this.appConfig.maxEntriesForStcConfiguration){
            //max limit reached, not allowed to add more
            this.dialogService.confirm('Information', `Maximum of ${this.appConfig.maxEntriesForStcConfiguration} Extended Workflows Categories are allowed.`, true)
            return;
        }
        let supplementalTaskCategory : SupplementalTaskCategory = new SupplementalTaskCategory();
        supplementalTaskCategory.provinceCode = this.selectedProvinceCode;
        const tab =  this.activeAccountTab.createSupplementalTaskCategoryTab(supplementalTaskCategory, this.account);

        this.tabsStateService.openTab(tab);
        this.parentTabsComponent.orderTabsDropDownToTop(tab);

    }

    burgerMenuClicked(selectedOption : string, row : SupplementalTaskCategory) : void {
        switch(selectedOption) {
            case 'Edit': // "Open"
                this.openSupplementalTaskCategory(row);
                //return;
            default:
                return;
        }
    }


    goBackToAnchorTabPage() : void {
        if(this.tabsStateService.openTabs.length > 1) {
            this.dialogService.confirm('Information', 'To proceed, all active tabs must first be closed.', true)
        }
        else {
            this.tabsStateService.anchorTab.isDocServices() ?  this.router.navigate(['main/doc-services/']) :  this.router.navigate(['main/admin/']);
        }
    }

    getAppliesToString(supplementalCategory: SupplementalTaskCategory): string {
        if(!supplementalCategory.applicableMatterTypeCodes) {
            return "";
        }
        return MatterTypeUtil.convertMatterTypeCodesOrDescriptionToString(supplementalCategory.applicableMatterTypeCodes, this.matterTypeInfos, false);
    }

    public openSupplementaryTaskCategoryTab(supplementaryTaskCategory: SupplementalTaskCategory): void {
        const accountId = this.account && this.account.id.toString();
        const supplementalTaskCategoryId = supplementaryTaskCategory.id;
        this.supplementalTaskService.getSupplementalTaskCategory(accountId, supplementaryTaskCategory.id ).subscribe(
            (supplementalTaskCategory: SupplementalTaskCategory) => {
                // TODO: Remove this part after 'id' is return from server
                if (!supplementalTaskCategory.id) {
                    supplementalTaskCategory.id = supplementalTaskCategoryId;
                }

                const tab : ConfigTab = this.activeAccountTab.createSupplementalTaskCategoryTab(supplementalTaskCategory, this.account);
                this.tabsStateService.openTab(tab);
                this.parentTabsComponent.orderTabsDropDownToTop(tab);
            });

    }


    openSupplementalTaskCategory(supplementalTaskCategory : SupplementalTaskCategory) : void {

        const tab: ConfigTab = this.tabsStateService.getTab(supplementalTaskCategory.id, "admin") as ConfigTab;

        if(tab) {
            this.parentTabsComponent.openSelectedTab(tab);
        } else {
            if(supplementalTaskCategory.id > 0) {
                this.openSupplementaryTaskCategoryTab(supplementalTaskCategory);
            } else {
                //this.createNewStaff();
            }
        }
    }

    ngAfterViewInit() {}
}
