import {Matter, MatterProperty} from '../shared';
import {UnitLevelPlanUtil} from '../property-teranet/unit-level-plan/unit-level-plan-util';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {PROVINCE_CODES} from '../shared/user-province';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {PinLegalDescription} from './pin-legal-description';
import {CondominiumExpense} from '../property-teranet/unit-level-plan/condominium-expense';
import {CondominiumPlan} from '../property-teranet/unit-level-plan/condominium-plan';
import {condoPlanTypeDropDownOptions} from '../../shared-main/province-based-dropdowns';

export const PINIDENTIFIERPATH = {
   'MB' : 'matterProperties/{propertyId}/parcelLegalDescriptions/{id}',
   'ONCONDO' : 'matterProperties/{propertyId}/condominiumExpenses/clientSideIdentifier/{id}',
   'ONNONCONDO' : 'matterProperties/{propertyId}',
    'NBCONDO' : 'matterProperties/{propertyId}/condominiumExpenses/clientSideIdentifier/{id}',
    'NBNONCONDO' : 'matterProperties/{propertyId}',
    'ABCONDO' : 'matterProperties/{propertyId}/condominiumExpenses/clientSideIdentifier/{id}',
    'ABNONCONDO' : 'matterProperties/{propertyId}'
};


export class LegalDescriptionUtil {
    static getTILegalDescriptionON(matterProperty : MatterProperty) : string{
        if (matterProperty.propertyTaxesSummary) {
            return matterProperty.propertyTaxesSummary;
        }
        let result = [];
        if (matterProperty.isCondominium == 'YES') {
            matterProperty.unitLevelPlan && result.push(matterProperty.unitLevelPlan);
            matterProperty.parcel && result.push(matterProperty.parcel);
            matterProperty.section && result.push(matterProperty.section);
            matterProperty.easementRightOfWay && result.push(matterProperty.easementRightOfWay);
            matterProperty.city && result.push(matterProperty.city);
        } else {
            matterProperty.partLot && result.push(matterProperty.partLot);
            matterProperty.plan && result.push(matterProperty.plan);
            matterProperty.beingPart && result.push(matterProperty.beingPart);
            matterProperty.onPlan && result.push(matterProperty.onPlan);
            matterProperty.parcel && result.push(matterProperty.parcel);
            matterProperty.section && result.push(matterProperty.section);
            matterProperty.easementRightOfWay && result.push(matterProperty.easementRightOfWay);
            matterProperty.city && result.push(matterProperty.city);
        }
        if (matterProperty.isParcelOfTiedLand == 'YES') {
            result.push(`together with an undivided common interest in the ${matterProperty.nameOfCondominiumPlan}`);
        }
        return result.join(', ');
    }

    static getTILegalDescriptionAB(matterProperty : MatterProperty, provinceCode: ProvinceCode, addBothShortAndFullLegalDescription : boolean = false): string {
        if (matterProperty.propertyTaxesSummary) {
            return matterProperty.propertyTaxesSummary;
        }
        let result = [];
        let separator = ', ';
        if (matterProperty.isCondominium == 'YES') { //CONDO

            if(matterProperty.unitLevelPlan) {
                //The format used in TI page is different from Property Page
                let unitLevelPlan: string = UnitLevelPlanUtil.generateUnitLevelPlan(matterProperty, provinceCode, true,addBothShortAndFullLegalDescription);
                if(unitLevelPlan.includes('Conventional')) {
                    unitLevelPlan = unitLevelPlan.replace(/Conventional /g, '');
                }
                unitLevelPlan = unitLevelPlan.replace(/Condominium No./g, 'Condominium Plan No.');
                if (addBothShortAndFullLegalDescription){
                    unitLevelPlan = unitLevelPlan.replace(/Condominium Plan /g, 'Condominium Plan No.');
                }
                result.push(unitLevelPlan);
            }

            if (matterProperty.exceptionType &&
                (matterProperty.exceptionType == 'YES' || matterProperty.exceptionType == 'Y_n') &&
                matterProperty.exceptionTypeDescription) {
                result.push(matterProperty.exceptionTypeDescription);
            }

        } else { //NON-CONDO
            if(matterProperty.propertyDescriptionType) {
                switch (matterProperty.propertyDescriptionType) {
                    case 'PLAN_BLOCK_LOT':
                        matterProperty.plan && result.push('Plan ' + matterProperty.plan);
                        matterProperty.block && result.push('Block ' + matterProperty.block);
                        matterProperty.lot && result.push('Lot ' + matterProperty.lot);
                        if (matterProperty.exceptionType &&
                            (matterProperty.exceptionType == 'YES' || matterProperty.exceptionType == 'Y_n') &&
                            matterProperty.exceptionTypeDescription) {
                            result.push(matterProperty.exceptionTypeDescription);
                        }
                        break;
                    case 'METES_AND_BOUNDS':
                        if (addBothShortAndFullLegalDescription) {
                            separator = '; '; // only for FCT METES_AND_BOUNDS diferent
                            if (matterProperty.shortLegalDescription) {
                                result.push(matterProperty.shortLegalDescription);
                            }
                            if (matterProperty.fullLegalDescription) {
                                result.push(matterProperty.fullLegalDescription);
                            }
                        } else {
                            if (matterProperty.fullLegalDescription) {
                                result.push(matterProperty.fullLegalDescription);
                            } else if (matterProperty.shortLegalDescription) {
                                result.push(matterProperty.shortLegalDescription);
                            }
                        }
                        break;
                }
            }
        }
        return result.join(separator);

    }

    static getTILegalDescriptionNB(matterProperty : MatterProperty): string {
        if (matterProperty.isCondominium === DpBooleanValueTypes.YES) {
            let unitLevelPlan: string;
            if(matterProperty.unitLevelPlan) {
                unitLevelPlan = UnitLevelPlanUtil.generateUnitLevelPlan(matterProperty, PROVINCE_CODES.NEW_BRUNSWICK, true, false);
            }
            return [unitLevelPlan, matterProperty.plan, matterProperty.city, matterProperty.municipality].filter(Boolean).join(', ');

        } else {
            return [matterProperty.partLot, matterProperty.plan, matterProperty.city, matterProperty.municipality].filter(Boolean).join(', ');
        }
    }

    static getStgLegalDescriptionNB(matterProperty : MatterProperty): string {
        if (matterProperty.isCondominium === DpBooleanValueTypes.YES) {
            let unitLevelPlan: string;
            if(matterProperty.unitLevelPlan) {
                unitLevelPlan = UnitLevelPlanUtil.generateUnitLevelPlan(matterProperty, PROVINCE_CODES.NEW_BRUNSWICK, true, false);
            }
            return [unitLevelPlan, matterProperty.plan, matterProperty.getROfWCovenants(), matterProperty.city].filter(Boolean).join(', ');

        } else {
            return [matterProperty.partLot, matterProperty.plan, matterProperty.getROfWCovenants(), matterProperty.city].filter(Boolean).join(', ');
        }
    }

    static getProvinceDefaultLegalDescriptionFromProperty(matter: Matter, matterProperty : MatterProperty, index: number): string {
        if(matter.isMatterProvinceMB){
            return this.getStgDefaultLegalDescriptionMB(matterProperty, index);
        }
        if(matter.isMatterProvinceON){
            return this.getStgDefaultLegalDescriptionON(matterProperty, index);
        }
        if(matter.isMatterProvinceNB){
            return this.getStgDefaultLegalDescriptionNB(matterProperty, index);
        }
        if(matter.isMatterProvinceAB){
            return this.getStgDefaultLegalDescriptionAB(matterProperty, index);
        }
    }

    static getStgDefaultLegalDescriptionMB(matterProperty : MatterProperty, index: number): string {
        if(matterProperty && matterProperty.isCondominium == DpBooleanValueTypes.YES){
            return matterProperty && matterProperty.legalDescriptionSummary;
        } else {
            return matterProperty.parcelLegalDescriptions[index].memo;
        }
    }

    static getStgDefaultLegalDescriptionON(matterProperty: MatterProperty, index: number): string {
        if(matterProperty && matterProperty.isCondominium == DpBooleanValueTypes.YES){
            return this.getDefaultLegalDescriptionForCondoPropertyON(matterProperty, matterProperty.condominiumExpenses[index], matterProperty.condominiumJurisdiction, matterProperty.condominiumPlans);
        } else {
            return this.getDefaultLegalDescriptionForNonCondoPropertyON(matterProperty);
        }
    }

    static getStgDefaultLegalDescriptionNB(matterProperty: MatterProperty, index?: number): string {
        if(matterProperty && matterProperty.isCondominium == DpBooleanValueTypes.YES){
            let unit = '';
            if(matterProperty.condominiumExpenses[index].unitNumber){
                unit += 'Unit ' + matterProperty.condominiumExpenses[index].unitNumber;
            }
            let level = '';
            if(matterProperty.condominiumExpenses[index].unitNumber){
                level += 'Level ' + matterProperty.condominiumExpenses[index].levelNumber;
            }
            return [unit, level, matterProperty.condominiumCorporationName, matterProperty.getROfWCovenants(), matterProperty.city].filter(Boolean).join(', ');

        } else {
            return [matterProperty.partLot, matterProperty.plan, matterProperty.getROfWCovenants(), matterProperty.city, matterProperty.municipality].filter(Boolean).join(', ');
        }
    }

    static getStgDefaultLegalDescriptionAB(matterProperty: MatterProperty, index: number): string {
        if(matterProperty && matterProperty.isCondominium == DpBooleanValueTypes.YES){
            return this.getDefaultLegalDescriptionForCondoPropertyAB(matterProperty, index);
        } else {
            return this.getDefaultLegalDescriptionForNonCondoPropertyAB(matterProperty);
        }
    }

    /*
     *  existingMatterlegalDescription is used only for migrating existing legal descriptions
     *  It is passed only when we open an existing matter that has the overrideLegalDescription flag equals to true
     */
    static constructPinLegalDescriptions(matter: Matter, existingMatterlegalDescription?: string) : void {
        if(matter.matterTitleInsurance){
            if(matter.isMatterProvinceMB){
                matter.matterTitleInsurance.pinLegalDescriptions =
                    LegalDescriptionUtil.constructPinLegalDescriptionsMB(matter.matterTitleInsurance.pinLegalDescriptions, matter.matterProperties[0], existingMatterlegalDescription);
            }
            if(matter.isMatterProvinceON){
                matter.matterTitleInsurance.pinLegalDescriptions =
                    LegalDescriptionUtil.constructPinLegalDescriptionsON(matter.matterTitleInsurance.pinLegalDescriptions, matter,  matter.matterProperties[0], existingMatterlegalDescription);
            }
            if(matter.isMatterProvinceNB){
                matter.matterTitleInsurance.pinLegalDescriptions =
                    LegalDescriptionUtil.constructPinLegalDescriptionsNB(matter.matterTitleInsurance.pinLegalDescriptions, matter,  matter.matterProperties[0], existingMatterlegalDescription);
            }
            if(matter.isMatterProvinceAB){
                matter.matterTitleInsurance.pinLegalDescriptions =
                    LegalDescriptionUtil.constructPinLegalDescriptionsAB(matter.matterTitleInsurance.pinLegalDescriptions, matter,  matter.matterProperties[0], existingMatterlegalDescription);
            }
        }
    }

    static constructPinLegalDescriptionsMB(currentPinLegalDescriptions: PinLegalDescription[], matterProperty : MatterProperty, existingMatterlegalDescription: string) : PinLegalDescription[] {
        if(!currentPinLegalDescriptions){
            currentPinLegalDescriptions = [];
        }
        let newPinLegalDescriptions : PinLegalDescription[] = [];
        if (matterProperty && matterProperty.isCondominium === DpBooleanValueTypes.YES) { // Condo
            //If we find pinLegalDescriptions
            if(currentPinLegalDescriptions.length >= 1){
                currentPinLegalDescriptions[0].pin = matterProperty.parcelLegalDescriptions.map(parcel=>parcel.parcelNumber).join(', ');
                if(!currentPinLegalDescriptions[0].overriddenLegalDescription){
                    currentPinLegalDescriptions[0].legalDescription = matterProperty.legalDescriptionSummary;
                }
                newPinLegalDescriptions.push(currentPinLegalDescriptions[0]);
            } else {
                //If no pinLegalDescriptions, we construct it from matterProperty.parcelLegalDescriptions
                if(matterProperty.parcelLegalDescriptions && matterProperty.parcelLegalDescriptions.length){
                    let pinLegalDescription = new PinLegalDescription();
                    pinLegalDescription.pin = matterProperty.parcelLegalDescriptions.map(parcel=>parcel.parcelNumber).join(', ');
                    pinLegalDescription.legalDescription = existingMatterlegalDescription ? existingMatterlegalDescription : matterProperty.legalDescriptionSummary;
                    pinLegalDescription.overriddenLegalDescription = !!existingMatterlegalDescription;
                    newPinLegalDescriptions.push(pinLegalDescription);
                }
            }
        } else {
            //Non Condo
            if(matterProperty.parcelLegalDescriptions && matterProperty.parcelLegalDescriptions.length){
                matterProperty.parcelLegalDescriptions.forEach((parcel)=>{
                    let pinLegalDesc = currentPinLegalDescriptions.find(pld=>pld.pinIdentifier == this.getPinIdentifier(PINIDENTIFIERPATH.MB, String(matterProperty.id), String(parcel.id)));
                    if(pinLegalDesc){
                        if(!pinLegalDesc.overriddenLegalDescription){
                            pinLegalDesc.legalDescription = parcel.memo;
                        }
                        pinLegalDesc.pin = parcel.parcelNumber ?  String(parcel.parcelNumber): '';
                        newPinLegalDescriptions.push(pinLegalDesc);
                    } else {
                        let pinLegalDescription = new PinLegalDescription();
                        pinLegalDescription.pinIdentifier = this.getPinIdentifier(PINIDENTIFIERPATH.MB, String(matterProperty.id), String(parcel.id));
                        pinLegalDescription.pin = parcel.parcelNumber ?  String(parcel.parcelNumber): '';
                        pinLegalDescription.legalDescription = existingMatterlegalDescription ? existingMatterlegalDescription : parcel.memo;
                        pinLegalDescription.overriddenLegalDescription = !!existingMatterlegalDescription;
                        newPinLegalDescriptions.push(pinLegalDescription);
                    }
                });
            }
        }
        return newPinLegalDescriptions;
    }

    static constructPinLegalDescriptionsON(currentPinLegalDescriptions: PinLegalDescription[], matter: Matter, matterProperty : MatterProperty, existingMatterlegalDescription: string) : PinLegalDescription[] {
        if(!currentPinLegalDescriptions){
            currentPinLegalDescriptions = [];
        }
        let newPinLegalDescriptions : PinLegalDescription[] = [];
        //let legalDescription = this.getTILegalDescriptionON(matterProperty);
        if (matterProperty && matterProperty.isCondominium === DpBooleanValueTypes.YES) { //Condo
            if(matterProperty.condominiumExpenses && matterProperty.condominiumExpenses.length){
                matterProperty.condominiumExpenses.filter(condoExpense => condoExpense.isNonEmptyObject()).forEach((condoExpense, index)=>{
                    let legalDescription = this.getDefaultLegalDescriptionForCondoPropertyON(matterProperty, condoExpense, matterProperty.condominiumJurisdiction, matterProperty.condominiumPlans);
                    let pinLegalDesc = currentPinLegalDescriptions.find(pld=>pld.pinIdentifier == this.getPinIdentifier(PINIDENTIFIERPATH.ONCONDO, String(matterProperty.id), String(condoExpense.clientSideIdentifier)));
                    if(pinLegalDesc){
                        if(!pinLegalDesc.overriddenLegalDescription){
                            pinLegalDesc.legalDescription = legalDescription;
                        }
                        pinLegalDesc.pin = condoExpense.pinNumber ?  String(condoExpense.pinNumber) : '';
                        newPinLegalDescriptions.push(pinLegalDesc);
                    } else {
                        let pinLegalDescription = new PinLegalDescription();
                        pinLegalDescription.pinIdentifier = this.getPinIdentifier(PINIDENTIFIERPATH.ONCONDO, String(matterProperty.id), String(condoExpense.clientSideIdentifier));
                        pinLegalDescription.pin = condoExpense.pinNumber ?  String(condoExpense.pinNumber) : '';
                        pinLegalDescription.legalDescription = existingMatterlegalDescription ? existingMatterlegalDescription : legalDescription;
                        pinLegalDescription.overriddenLegalDescription = !!existingMatterlegalDescription;
                        newPinLegalDescriptions.push(pinLegalDescription);
                    }
                });
            }
        } else {
            //Non Condo
            if(matter.matterProperties && matter.matterProperties.length){
                matter.matterProperties.forEach((property, index)=>{
                    let legalDescription = this.getDefaultLegalDescriptionForNonCondoPropertyON(property);
                    let pinLegalDesc = currentPinLegalDescriptions.find(pld=>pld.pinIdentifier == this.getPinIdentifier(PINIDENTIFIERPATH.ONNONCONDO, String(property.id), null));
                    if(pinLegalDesc){
                        if(!pinLegalDesc.overriddenLegalDescription){
                            pinLegalDesc.legalDescription = legalDescription;
                        }
                        pinLegalDesc.pin = property.pin ? String(property.pin) : '';
                        newPinLegalDescriptions.push(pinLegalDesc);
                    } else {
                        let pinLegalDescription = new PinLegalDescription();
                        pinLegalDescription.pinIdentifier = this.getPinIdentifier(PINIDENTIFIERPATH.ONNONCONDO, String(property.id), null);
                        pinLegalDescription.pin = property.pin ? String(property.pin) : '';
                        pinLegalDescription.legalDescription = existingMatterlegalDescription ? existingMatterlegalDescription : legalDescription;
                        pinLegalDescription.overriddenLegalDescription = !!existingMatterlegalDescription;
                        newPinLegalDescriptions.push(pinLegalDescription);
                    }
                });
            }
        }
        return newPinLegalDescriptions;
    }

    static constructPinLegalDescriptionsNB(currentPinLegalDescriptions: PinLegalDescription[], matter: Matter, matterProperty : MatterProperty, existingMatterlegalDescription: string) : PinLegalDescription[] {
        if(!currentPinLegalDescriptions){
            currentPinLegalDescriptions = [];
        }
        let newPinLegalDescriptions : PinLegalDescription[] = [];
        if (matterProperty && matterProperty.isCondominium === DpBooleanValueTypes.YES) { //Condo
            if(matterProperty.condominiumExpenses && matterProperty.condominiumExpenses.length){
                matterProperty.condominiumExpenses.filter(condoExpense => condoExpense.isNonEmptyObject()).forEach((condoExpense, index)=>{
                    let legalDescription = this.getStgDefaultLegalDescriptionNB(matterProperty, index);
                    let pinLegalDesc = currentPinLegalDescriptions.find(pld=>pld.pinIdentifier == this.getPinIdentifier(PINIDENTIFIERPATH.NBCONDO, String(matterProperty.id), String(condoExpense.clientSideIdentifier)));
                    if(pinLegalDesc){
                        if(!pinLegalDesc.overriddenLegalDescription){
                            pinLegalDesc.legalDescription = legalDescription;
                        }
                        pinLegalDesc.pin = condoExpense.pinNumber ?  String(condoExpense.pinNumber) : '';
                        newPinLegalDescriptions.push(pinLegalDesc);
                    } else {
                        let pinLegalDescription = new PinLegalDescription();
                        pinLegalDescription.pinIdentifier = this.getPinIdentifier(PINIDENTIFIERPATH.NBCONDO, String(matterProperty.id), String(condoExpense.clientSideIdentifier));
                        pinLegalDescription.pin = condoExpense.pinNumber ?  String(condoExpense.pinNumber) : '';
                        pinLegalDescription.legalDescription = existingMatterlegalDescription ? existingMatterlegalDescription : legalDescription;
                        pinLegalDescription.overriddenLegalDescription = !!existingMatterlegalDescription;
                        newPinLegalDescriptions.push(pinLegalDescription);
                    }
                });
            }
        } else {
            //Non Condo
            if(matter.matterProperties && matter.matterProperties.length){
                matter.matterProperties.forEach((property)=>{
                    let legalDescription = this.getStgDefaultLegalDescriptionNB(matterProperty);
                    let pinLegalDesc = currentPinLegalDescriptions.find(pld=>pld.pinIdentifier == this.getPinIdentifier(PINIDENTIFIERPATH.NBNONCONDO, String(property.id), null));
                    if(pinLegalDesc){
                        if(!pinLegalDesc.overriddenLegalDescription){
                            pinLegalDesc.legalDescription = legalDescription;
                        }
                        pinLegalDesc.pin = property.pin ? String(property.pin) : '';
                        newPinLegalDescriptions.push(pinLegalDesc);
                    } else {
                        let pinLegalDescription = new PinLegalDescription();
                        pinLegalDescription.pinIdentifier = this.getPinIdentifier(PINIDENTIFIERPATH.NBNONCONDO, String(property.id), null);
                        pinLegalDescription.pin = property.pin ? String(property.pin) : '';
                        pinLegalDescription.legalDescription = existingMatterlegalDescription ? existingMatterlegalDescription : legalDescription;
                        pinLegalDescription.overriddenLegalDescription = !!existingMatterlegalDescription;
                        newPinLegalDescriptions.push(pinLegalDescription);
                    }
                });
            }
        }
        return newPinLegalDescriptions;
    }

    static constructPinLegalDescriptionsAB(currentPinLegalDescriptions: PinLegalDescription[], matter: Matter, matterProperty : MatterProperty, existingMatterlegalDescription: string) : PinLegalDescription[] {
        if(!currentPinLegalDescriptions){
            currentPinLegalDescriptions = [];
        }
        let newPinLegalDescriptions : PinLegalDescription[] = [];
        if (matterProperty && matterProperty.isCondominium === DpBooleanValueTypes.YES) { //Condo
            if(matterProperty.condominiumExpenses && matterProperty.condominiumExpenses.length){
                matterProperty.condominiumExpenses.filter(condoExpense => condoExpense.isNonEmptyObject()).forEach((condoExpense, index)=>{
                    let legalDescription = this.getDefaultLegalDescriptionForCondoPropertyAB(matterProperty, index);
                    let pinLegalDesc = currentPinLegalDescriptions.find(pld=>pld.pinIdentifier == this.getPinIdentifier(PINIDENTIFIERPATH.ABCONDO, String(matterProperty.id), String(condoExpense.clientSideIdentifier)));
                    if(pinLegalDesc){
                        if(!pinLegalDesc.overriddenLegalDescription){
                            pinLegalDesc.legalDescription = legalDescription;
                        }
                        pinLegalDesc.pin = condoExpense.lincNumber ?  String(condoExpense.lincNumber) : '';
                        newPinLegalDescriptions.push(pinLegalDesc);
                    } else {
                        let pinLegalDescription = new PinLegalDescription();
                        pinLegalDescription.pinIdentifier = this.getPinIdentifier(PINIDENTIFIERPATH.ABCONDO, String(matterProperty.id), String(condoExpense.clientSideIdentifier));
                        pinLegalDescription.pin = condoExpense.lincNumber ?  String(condoExpense.lincNumber) : '';
                        pinLegalDescription.legalDescription = existingMatterlegalDescription ? existingMatterlegalDescription : legalDescription;
                        pinLegalDescription.overriddenLegalDescription = !!existingMatterlegalDescription;
                        newPinLegalDescriptions.push(pinLegalDescription);
                    }
                });
            }
        } else {
            //Non Condo
            let lincNumbers = matter.nonBlanketMatterProperty.map(mp=>mp.lincNumber).filter(Boolean).join(', ');
            let legalDescription = this.getDefaultLegalDescriptionForNonCondoPropertyAB(matterProperty);
            let pinLegalDesc = currentPinLegalDescriptions.find(pld=>pld.pinIdentifier == this.getPinIdentifier(PINIDENTIFIERPATH.ABNONCONDO, String(matterProperty.id), null));
            if(pinLegalDesc){
                if(!pinLegalDesc.overriddenLegalDescription){
                    pinLegalDesc.legalDescription = legalDescription;
                }
                pinLegalDesc.pin = lincNumbers ? String(lincNumbers) : '';
                newPinLegalDescriptions.push(pinLegalDesc);
            } else {
                let pinLegalDescription = new PinLegalDescription();
                pinLegalDescription.pinIdentifier = this.getPinIdentifier(PINIDENTIFIERPATH.ABNONCONDO, String(matterProperty.id), null);
                pinLegalDescription.pin = lincNumbers ? String(lincNumbers) : '';
                pinLegalDescription.legalDescription = existingMatterlegalDescription ? existingMatterlegalDescription : legalDescription;
                pinLegalDescription.overriddenLegalDescription = !!existingMatterlegalDescription;
                newPinLegalDescriptions.push(pinLegalDescription);
            }

        }
        return newPinLegalDescriptions;
    }


    static constructAssystPayoutPinLegalDescriptionsAB(currentPinLegalDescriptions: PinLegalDescription[], matter: Matter, matterProperty : MatterProperty, existingMatterlegalDescription: string) : PinLegalDescription[] {
        if(!currentPinLegalDescriptions){
            currentPinLegalDescriptions = [];
        }
        let newPinLegalDescriptions : PinLegalDescription[] = [];
        if (matterProperty && matterProperty.isCondominium === DpBooleanValueTypes.YES) { //Condo
            if(matterProperty.condominiumExpenses && matterProperty.condominiumExpenses.length){
                matterProperty.condominiumExpenses.filter(condoExpense => condoExpense.isNonEmptyObject()).forEach((condoExpense, index)=>{
                    let legalDescription = this.getDefaultLegalDescriptionForCondoPropertyAB(matterProperty, index);
                    let pinLegalDesc = currentPinLegalDescriptions.find(pld=>pld.pinIdentifier == this.getPinIdentifier(PINIDENTIFIERPATH.ABCONDO, String(matterProperty.id), String(condoExpense.clientSideIdentifier)));
                    if(pinLegalDesc){
                        if(!pinLegalDesc.overriddenLegalDescription){
                            pinLegalDesc.legalDescription = legalDescription;
                        }
                        pinLegalDesc.pin = condoExpense.lincNumber ?  String(condoExpense.lincNumber) : '';
                        newPinLegalDescriptions.push(pinLegalDesc);
                    } else {
                        let pinLegalDescription = new PinLegalDescription();
                        pinLegalDescription.pinIdentifier = this.getPinIdentifier(PINIDENTIFIERPATH.ABCONDO, String(matterProperty.id), String(condoExpense.clientSideIdentifier));
                        pinLegalDescription.pin = condoExpense.lincNumber ?  String(condoExpense.lincNumber) : '';
                        pinLegalDescription.legalDescription = existingMatterlegalDescription ? existingMatterlegalDescription : legalDescription;
                        pinLegalDescription.overriddenLegalDescription = !!existingMatterlegalDescription;
                        newPinLegalDescriptions.push(pinLegalDescription);
                    }
                });
            }
        } else {
            //Non Condo
            if(matter.matterProperties && matter.matterProperties.length){
                matter.matterProperties.forEach((property)=>{
                    let legalDescription = this.getDefaultLegalDescriptionForNonCondoPropertyAB(property);
                    let pinLegalDesc = currentPinLegalDescriptions.find(pld=>pld.pinIdentifier == this.getPinIdentifier(PINIDENTIFIERPATH.ABNONCONDO, String(matterProperty.id), null));
                    if(pinLegalDesc){
                        if(!pinLegalDesc.overriddenLegalDescription){
                            pinLegalDesc.legalDescription = legalDescription;
                        }
                        pinLegalDesc.pin = property.lincNumber ? String(property.lincNumber) : '';
                        newPinLegalDescriptions.push(pinLegalDesc);
                    } else {
                        let pinLegalDescription = new PinLegalDescription();
                        pinLegalDescription.pinIdentifier = this.getPinIdentifier(PINIDENTIFIERPATH.ABNONCONDO, String(matterProperty.id), null);
                        pinLegalDescription.pin = property.lincNumber ? String(property.lincNumber) : '';
                        pinLegalDescription.legalDescription = existingMatterlegalDescription ? existingMatterlegalDescription : legalDescription;
                        pinLegalDescription.overriddenLegalDescription = !!existingMatterlegalDescription;
                        newPinLegalDescriptions.push(pinLegalDescription);
                    }

                });
            }
        }
        return newPinLegalDescriptions;
    }

    static getPinIdentifier(source: string, propertyId: string, pinId: string) : string {
        let pinIdentifier = source.replace('{propertyId}', propertyId);
        if(pinId){
            pinIdentifier = pinIdentifier.replace('{id}', pinId);
        }
        return pinIdentifier;
    }

    static getDefaultLegalDescriptionForCondoPropertyON(matterProperty: MatterProperty, condominiumExpense: CondominiumExpense,
                                                        condominiumJurisdiction: string, condominiumPlans: CondominiumPlan[]) : string {
        if(matterProperty.propertyTaxesSummary){
            return matterProperty.propertyTaxesSummary;
        }

        let  condominiumPlan: CondominiumPlan = condominiumPlans.find(condoplan => condoplan.condominiumPlanNumber && condoplan.condominiumPlanNumber == condominiumExpense.planNumber);
        if(!condominiumPlan){
            condominiumPlan = condominiumPlans[0];
        }
        let planInfo : string = (condominiumJurisdiction? condominiumJurisdiction + ' ' : '')  + this.getCondominiumPlanType(condominiumPlan.condominiumPlanType, 'ON')  + " No. " +
            (condominiumPlan.condominiumPlanNumber ? condominiumPlan.condominiumPlanNumber : '');
        let legalDescStringList = [];
        if(condominiumExpense.unitNumber) {
            legalDescStringList.push("Unit " + condominiumExpense.unitNumber);
        }
        if(condominiumExpense.levelNumber) {
            legalDescStringList.push("Level " + condominiumExpense.levelNumber);
        }
        legalDescStringList.push(planInfo);
        legalDescStringList.push(matterProperty.parcel);
        legalDescStringList.push(matterProperty.section);
        legalDescStringList.push(matterProperty.city);

        return legalDescStringList.filter(val=>!!val).join(', ');
    }

    static getDefaultLegalDescriptionForNonCondoPropertyON(matterProperty: MatterProperty) : string {
        if(matterProperty.propertyTaxesSummary) {
            return matterProperty.propertyTaxesSummary
        } else {
            return [matterProperty.partLot, matterProperty.plan, matterProperty.beingPart, matterProperty.onPlan,
                matterProperty.parcel, matterProperty.section, matterProperty.easementRightOfWay, matterProperty.city].filter(val=>!!val).join(', ');
        }
    }

    static getDefaultLegalDescriptionForCondoPropertyAB(matterProperty: MatterProperty, index: number) : string {
        let legalDescription: string[] = [];
        let condoExpense = matterProperty.condominiumExpenses[index];
        if(condoExpense.unitNumber){
            legalDescription.push("Unit " + condoExpense.unitNumber);
        }
        let condoPlanNumber = condoExpense.planNumber;
        if(condoPlanNumber){
            let planPart = "";
            if(!condoPlanNumber.startsWith("CP  No.") && !condoPlanNumber.startsWith("BLCP  No.")) {
                condoPlanNumber = condoPlanNumber.substring(1);
                planPart = condoPlanNumber;
            }
            if(condoPlanNumber.startsWith("CP  No.")){
                planPart = condoPlanNumber.replace("CP  No.", "Condominium Plan No.");
            }else if (condoPlanNumber.startsWith("BLCP  No.")){
                planPart = condoPlanNumber.replace("BLCP  No.", "Bare Land Condominium Plan No.");
            }
            legalDescription.push(planPart);
        }
        if (matterProperty.exceptionType &&
            (matterProperty.exceptionType == 'YES' || matterProperty.exceptionType == 'Y_n') &&
            matterProperty.exceptionTypeDescription) {
            legalDescription.push(matterProperty.exceptionTypeDescription);
        }

        return legalDescription.filter(Boolean).join(', ');
    }

    static getDefaultLegalDescriptionForNonCondoPropertyAB(matterProperty: MatterProperty) : string {
        let result = [];
        let separator = ', ';
        if(matterProperty.propertyDescriptionType) {
            switch (matterProperty.propertyDescriptionType) {
                case 'PLAN_BLOCK_LOT':
                    matterProperty.plan && result.push('Plan ' + matterProperty.plan);
                    matterProperty.block && result.push('Block ' + matterProperty.block);
                    matterProperty.lot && result.push('Lot ' + matterProperty.lot);
                    if (matterProperty.exceptionType &&
                        (matterProperty.exceptionType == 'YES' || matterProperty.exceptionType == 'Y_n') &&
                        matterProperty.exceptionTypeDescription) {
                        result.push(matterProperty.exceptionTypeDescription);
                    }
                    break;
                case 'METES_AND_BOUNDS':
                    if (matterProperty.fullLegalDescription) {
                        result.push(matterProperty.fullLegalDescription);
                    } else if (matterProperty.shortLegalDescription) {
                        result.push(matterProperty.shortLegalDescription);
                    }
                    break;
            }
        }
        return result.join(separator);
    }

    static getCondominiumPlanType(type:string , provinceCode : string) : string{
        let condoPlanType : any = condoPlanTypeDropDownOptions[provinceCode].find(condoPlanTypeObj => condoPlanTypeObj.value == type);
        return condoPlanType ? condoPlanType.label : '';
    }

    static createAssystPayoutPinLegalDescriptions(matter : Matter):PinLegalDescription[]{
        let existingMatterLegalDescription:string;
        let currentPinLegalDescription =[];
        if(matter.isMatterProvinceMB){
            return LegalDescriptionUtil.constructPinLegalDescriptionsMB(currentPinLegalDescription, matter.matterProperties[0], existingMatterLegalDescription);
        }
        if(matter.isMatterProvinceON){
            const pinLegalDescriptions: PinLegalDescription[] = LegalDescriptionUtil.constructPinLegalDescriptionsON(currentPinLegalDescription, matter,  matter.matterProperties[0], existingMatterLegalDescription);
            // try to format as "99999-9999"
            pinLegalDescriptions.forEach(pinLglDescr => {
                let pins = pinLglDescr.pin;
                if(pins){
                    let pinArray = pins.split(',');
                    let pinFormattedArray = pinArray.map(pin => {
                        let formattedPin = pin.replace(/\s+/g, '');
                        if(formattedPin.length > 8){
                            return formattedPin.substring(0,5) + '-' + formattedPin.substring(5);
                        }
                    })
                    pinLglDescr.pin = pinFormattedArray.filter(Boolean).join(', ');
                }
            })
            return pinLegalDescriptions;
        }
        if(matter.isMatterProvinceNBorNS){
            return LegalDescriptionUtil.constructPinLegalDescriptionsNB(currentPinLegalDescription, matter,  matter.matterProperties[0], existingMatterLegalDescription);
        }
        if(matter.isMatterProvinceAB) {
            const pinLegalDescriptions: PinLegalDescription[] = LegalDescriptionUtil.constructAssystPayoutPinLegalDescriptionsAB(currentPinLegalDescription, matter, matter.matterProperties[0], existingMatterLegalDescription);
            // try to format as "9999 999 999"
            pinLegalDescriptions.forEach(pinLglDescr => {
                let pins = pinLglDescr.pin;
                if(pins){
                    let pinArray = pins.split(',');
                    let pinFormattedArray = pinArray.map(pin => {
                        let formattedPin = pin.replace(/\s+/g, '');
                        if(formattedPin.length > 9){
                            return formattedPin.substring(0,4) + ' ' + formattedPin.substring(4,7) + ' '  + formattedPin.substring(7,10);
                        }
                    })
                    pinLglDescr.pin = pinFormattedArray.filter(Boolean).join(', ');
                }
            })
            return pinLegalDescriptions;
        }
    }
}
