import {Matter} from '../matters/shared/matter';
import {MatterTab} from '../matters/matter-tab';
import {OpportunityMatterListState} from './opportunity-matter-list/opportunity-matter-list-state';

export class OpportunityMatterTab extends MatterTab {

    subRoute: string;
    subRouteId: number;
    opportuniyMatterListState: OpportunityMatterListState;

    constructor(src?: OpportunityMatterTab) {
        super(src);
    }

    get tabTypeForDisplay(): string {
        return this.tabDisplayLabel;
    }


    static createOpportunityTab(matter: Matter): OpportunityMatterTab {
        let tab: OpportunityMatterTab = new OpportunityMatterTab();
        tab.tabType = 'opportunityMatter';
        tab.matter = matter;
        tab.backEndMatter = new Matter(matter);
        tab.type = matter.matterType;
        tab.route = 'main/opportunity-matter/matter';
        tab.tabDisplayLabel = 'Opportunity';
        return tab;
    }

}
