import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export type CirfFieldStatusType = 'MANDATORY' | 'OPTIONAL' | 'HIDDEN';
export const CirfFieldStatusTypeValues = {
    mandatory: <CirfFieldStatusType>'MANDATORY',
    optional: <CirfFieldStatusType>'OPTIONAL',
    hidden: <CirfFieldStatusType>'HIDDEN',
    visible: 'VISIBLE'
};

export const cirfStatusOptions = [
    {label: 'Mandatory', value: CirfFieldStatusTypeValues.mandatory},
    {label: 'Optional', value: CirfFieldStatusTypeValues.optional},
];

export const cirfVisibilityOptions = [
    {label: 'Show', value: CirfFieldStatusTypeValues.visible},
    {label: 'Hide', value: CirfFieldStatusTypeValues.hidden},
];

export class CirfConfig extends BaseEntity {
    matterType: string;
    cirfTopics: CirfConfigTopic[];

    constructor(props?: CirfConfig) {
        super(props);
        if (props) {
            if (props.cirfTopics) {
                this.cirfTopics = props.cirfTopics.map(topic => new CirfConfigTopic(topic));
            }
        } else {
            this.cirfTopics = [];
        }
    }

}

export class CirfConfigTopic extends BaseEntity {
    topicLabel: string
    topicKey: string;
    topicSections: CirfTopicSection[];

    constructor(props?: CirfConfigTopic) {
        super(props);
        if (props) {
            if (props.topicSections) {
                this.topicSections = props.topicSections.map(section => new CirfTopicSection(section));
            }
        } else {
            this.topicSections = [];
        }
    }
}

export class CirfTopicSection extends BaseEntity {
    sectionLabel: string;
    sectionKey: string;
    cirfFields: CirfField[];
    hasUdf: boolean;

    constructor(props?: CirfTopicSection) {
        super(props);
        if (props) {
            if (props.cirfFields) {
                this.cirfFields = props.cirfFields.map(field => new CirfField(field));
            }
        } else {
            this.cirfFields = [];
        }
    }
}

export class CirfField extends BaseEntity {
    fieldLabel: string;
    fieldKey: string;
    fieldStatus: CirfFieldStatusType;
    parentKey: string;
    isUdf: boolean;
    isStatusReadOnly: boolean;
    isVisibilityReadOnly: boolean;
    index: number;


    get mandatoryStatus(): CirfFieldStatusType {
        return this.isMandatory() ? CirfFieldStatusTypeValues.mandatory : CirfFieldStatusTypeValues.optional;
    }

    get visibilityStatus(): string {
        return this.isHidden() ? CirfFieldStatusTypeValues.hidden : CirfFieldStatusTypeValues.visible;
    }

    isHidden() : boolean {
        return this.fieldStatus == CirfFieldStatusTypeValues.hidden;
    }

    isMandatory() : boolean {
        return this.fieldStatus == CirfFieldStatusTypeValues.mandatory
    }
}
