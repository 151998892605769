import {DocumentTemplate} from './document-template';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class DocumentTemplateCategory extends BaseEntity {

    id: number;
    customerAccountId: number;
    categoryDescription: string;
    applicationType: string;
    documentTemplates: DocumentTemplate[];
    applicableMatterTypeCode: string;
    defaultCategory: boolean;
    documentProfileId: number;
    accountFileFolderId: number;

    constructor(documentTemplateCategory?: DocumentTemplateCategory) {
        super(documentTemplateCategory);
        if (documentTemplateCategory) {

            this.documentTemplates = [];
            if (Array.isArray(documentTemplateCategory.documentTemplates)) {
                this.documentTemplates = documentTemplateCategory.documentTemplates.map(template => {
                    return new DocumentTemplate(template);
                });
            }
        }
    }
}
