import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';
import {CurrencyPipe} from '@angular/common';
import Utils from './utils';

declare var jQuery:any;
@Directive({
               selector  : '[ngModel][dp-currency]',
               providers : [
                   CurrencyPipe
               ]
           })
export class CurrencyDirective implements OnInit {
    @Input('significant-currency-digits') significantCurrencyDigits : number = 9;

    @Input('allow-negative') allowNegative : boolean = false;

    @Input('allow-blank') allowBlank : boolean = false;

    @Input('dp-currency') allowMaxLength : boolean;

    @Input('max-decimals') maxDecimals : number = 2;


    private digitCheckRegex : RegExp ;
    private prevVal : any;

    constructor(private model : NgModel,
                private currencyPipe : CurrencyPipe , private elementRef: ElementRef) {
    }

    ngOnInit() : void {
        this.digitCheckRegex = this.allowNegative ? /[^\d.-]/g : /[^\d.]/g ;
        this.model.valueChanges.subscribe(this.onValueChanges);

    }

    ngAfterViewInit(){
        jQuery(this.elementRef.nativeElement).addClass('dp-currency');
    }

    private onValueChanges = (newValue : string) : void => {

        if(!jQuery(this.elementRef.nativeElement).is(':focus'))
        {
            if(newValue != undefined && newValue != null && !Number.isNaN(parseFloat(newValue)))   {
                if(!this.allowNegative){
                    newValue = newValue.toString().replace(/-/g,"");
                }
                this.model.valueAccessor.writeValue(this.formatVal(newValue));
            }
            else{
                const value = this.allowBlank ? '' : this.formatVal('0.00');
                this.model.valueAccessor.writeValue(value);
            }

        }
        else{

            if(this.checkFieldValues(newValue))
            {
                let ret : string = Utils.cleanUpCurrencyFieldValue(newValue, this.digitCheckRegex);
                this.model.viewToModelUpdate(ret);
                this.model.valueAccessor.writeValue(ret);
                this.prevVal = ret;
            }
            else {
                setTimeout(()=>{
                    this.model.viewToModelUpdate(this.prevVal);
                    this.model.valueAccessor.writeValue(this.prevVal);
                }, 0);
            }

        }


    };

/*
    //the method has been moved to Utils.ts to facilitate the unit testing.
    cleanUpFieldValue(newValue: string) : string {
        return newValue && newValue.toString().replace(this.digitCheckRegex, '')
                            .replace(/(?!^)-/g, '')
                            .replace(/(\.)(?=.*\1)/g, "") // remove multiple decimals
                            .replace('(\.\d{0,' + this.maxDecimals + '}).*', '$1')
                            .replace(new RegExp('(^[+-]?\\d{0,'+ Number(this.significantCurrencyDigits) +'})\\d*'), '$1')
                            .replace(/^0{2,}/, '0');
    }*/

    checkFieldValues(newValue : string) : boolean {
        let pieces = newValue.toString().split('.');
        //check the number of significant digits
        //allow the value starts with ., then pieces[0] == ''
        if(!newValue || (pieces[0] != null && pieces[0].length <= this.significantCurrencyDigits)) {
            //check the number of decimals
            return !(pieces[1] && pieces[1].length > this.maxDecimals);
        }
        else {
            return false;
        }
    }

    @HostListener('focus' , ['$event.target']) onFocus(target: any) : void {

        let val : string = this.model.viewModel;
        if(val != undefined && val != null) {
            // this.formatVal return string or null
            // If the this.model.viewModel is '', the val will be null after formatVal
            val = this.formatVal(val.toString().replace(this.digitCheckRegex, ''));
            if(val != null) {
                val = val.replace('$', '');
                val = val.replace(/,/g, '');
                //If the number is an integer, remove the decimal on focus
                if(Number(val) == parseInt(val, 10) && val.indexOf('.')!= -1 ){
                    val = val.split(".")[0];
                }
            }

            this.prevVal = this.model.viewModel;
        }
        this.model.valueAccessor.writeValue(val);
        target.select();
    }

    @HostListener('blur') onBlur() : void {
        let val : string =  Utils.cleanUpCurrencyFieldValue(this.model.viewModel, this.digitCheckRegex);
        if(val != undefined && val != null && !Number.isNaN(parseFloat(val)))  {
            this.model.viewToModelUpdate(val);//set the cleaned value back into the model
            val = this.formatVal(val);
        }
        else{
            if(this.allowBlank){
                this.model.viewToModelUpdate('');//set the cleaned value back into the model
                val = this.formatVal('');
            }else{
                this.model.viewToModelUpdate('0');//set the cleaned value back into the model
                val = this.formatVal('0.00');
            }
        }
        this.model.valueAccessor.writeValue(val); //set the formatted value for display
    }

    @HostListener('keyup') onKeyUp() : void {
        let val : string = Utils.cleanUpCurrencyFieldValue(this.model.viewModel, this.digitCheckRegex);
        if(val != null) {
            // val = String(val).replace(',', '').replace('$', '');
            if(!this.allowNegative){
                val = val.replace('-', '');
            }
            this.model.viewToModelUpdate(val);

        }

    }

    private formatVal(val : string) : string {
        let result =this.currencyPipe.transform(val, 'USD', 'symbol', `1.${this.maxDecimals}-${this.maxDecimals}`);
        return result;
    }
}
