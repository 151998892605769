export const cirfDropDowns = {

    residentStatusOptions: [
        {label: '', value: ''},
        {label: 'Canadian Citizen', value: 'CANADIAN_CITIZEN'},
        {label: 'Permanent Resident', value: 'PERMANENT_RESIDENT'}
    ],

    countryOptions: [
        {label: 'Canada', value: 'CANADA'},
        {label: 'USA', value: 'USA'}
    ],

    provinceOptions: [
        {label: '', value: ''},
        {label: 'Alberta', value: 'AB'},
        {label: 'British Columbia', value: 'BC'},
        {label: 'Manitoba', value: 'MB'},
        {label: 'Newfoundland and Labrador', value: 'NL'},
        {label: 'New Brunswick', value: 'NB'},
        {label: 'Northwest Territories', value: 'NT'},
        {label: 'Nova Scotia', value: 'NS'},
        {label: 'Nunavut', value: 'NU'},
        {label: 'Ontario', value: 'ON'},
        {label: 'Prince Edward Island', value: 'PE'},
        {label: 'Quebec', value: 'QC'},
        {label: 'Saskatchewan', value: 'SK'},
        {label: 'Yukon', value: 'YT'}
    ],

    identificationOptions: [
        { label: '', value: '' },
        { label: 'Birth Certificate', value: 'Birth Certificate' },
        { label: "Driver's License", value: "Driver's License" },
        { label: 'Canadian Passport', value: 'Canadian Passport' },
        { label: 'Certificate of Canadian Citizenship', value: 'Certificate of Canadian Citizenship' },
        { label: 'Certificate of Naturalization', value: 'Certificate of Naturalization' },
        { label: 'Permanent Residence Card', value: 'Permanent Residence Card' },
        { label: 'Foreign Passport', value: 'Foreign Passport' },
    ],

    //credit card option only for secondary ID
    secondaryIdentificationOptions: [
        { label: '', value: '' },
        { label: 'Birth Certificate', value: 'Birth Certificate' },
        { label: "Driver's License", value: "Driver's License" },
        { label: 'Canadian Passport', value: 'Canadian Passport' },
        { label: 'Certificate of Canadian Citizenship', value: 'Certificate of Canadian Citizenship' },
        { label: 'Certificate of Naturalization', value: 'Certificate of Naturalization' },
        { label: 'Permanent Residence Card', value: 'Permanent Residence Card' },
        { label: 'Foreign Passport', value: 'Foreign Passport' },
        { label: 'Credit Card', value: 'Credit Card' }
    ],

}
