import {supplementalTaskCategoryApi} from './supplemental-task-category-api';
import {HttpClient} from '../../core';
import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {CategoryField} from './category-field';
import {SupplementalTaskCategory} from './supplemental-task-category';
import {Observable} from 'rxjs';
import {ProvinceCode} from '../../admin/accounts/shared/account-province';


/** Supplemental Task service defines all api calls for Supplemental Task Category modules
 */
@Injectable()
export class SupplementalTaskService {

    constructor(private http: HttpClient, private logger: Logger) { }


    getSupplementalTaskCategories(accountId: string, provinceCode?: ProvinceCode): Observable<SupplementalTaskCategory[]> {
        let url: string = supplementalTaskCategoryApi.supplementalTaskCategories.replace('*', accountId);

        url += '?filter=deleted_EQ_false';
        if(provinceCode) {
            url += `,provinceCode_EQ_${provinceCode}`;
        }

        return this.http.get(url)
            .map((res) => {
                let defaultCategories:SupplementalTaskCategory[] = [];
                let data = res['SupplementalTaskCategories'];
                data.forEach(item => {
                    defaultCategories.push(new SupplementalTaskCategory(item))
                });
                return defaultCategories;
            });
    }

    createSupplementalTaskCategory(accountId : string , supplementalTaskCategory : SupplementalTaskCategory) {
        return this.http.post(supplementalTaskCategoryApi.supplementalTaskCategory.replace('*', accountId), JSON.stringify(supplementalTaskCategory))
            .map((res) => {
                return new SupplementalTaskCategory(res['SupplementalTaskCategory']);
            });
    }

    saveSupplementalTaskCategory(accountId  : string , supplementalTaskCategory : SupplementalTaskCategory) : Observable<SupplementalTaskCategory>{
        if(supplementalTaskCategory.id){
           return this.editSupplementalTaskCategory(accountId , supplementalTaskCategory , supplementalTaskCategory.id);
        }else{
            return this.createSupplementalTaskCategory(accountId  , supplementalTaskCategory );
        }
    }

    getSupplementalTaskCategory(accountId : string , categoryId : number) {
        let url: string = supplementalTaskCategoryApi.supplementalTaskCategory.replace('*', accountId)+ '/' + categoryId;
        return this.http.get(url)
            .map((res) => {
                return new SupplementalTaskCategory(res['SupplementalTaskCategory']);
            });
    }

    editSupplementalTaskCategory(accountId : string , supplementalTaskCategory : SupplementalTaskCategory , categoryId : number) {
        let url: string = supplementalTaskCategoryApi.supplementalTaskCategory.replace('*', accountId)+ '/' + categoryId;
        return this.http.put(url, JSON.stringify(supplementalTaskCategory))
            .map((res) => {
                return new SupplementalTaskCategory(res['SupplementalTaskCategory']);
            });
    }

    get defaultCategoryFields () : CategoryField[]{
        let defaultCategoryFieldsArr : CategoryField[] = [];
        defaultCategoryFieldsArr.push(SupplementalTaskCategory.createCategoryFieldRecord('NAME_ADDRESS' , 'CONTACT', 'Name & Address' , 'Name & Address'));
        defaultCategoryFieldsArr.push(SupplementalTaskCategory.createCategoryFieldRecord('ACCOUNT_NUMBER' , 'TEXT', 'Account Number' , 'Account Number'));
        defaultCategoryFieldsArr.push(SupplementalTaskCategory.createCategoryFieldRecord('COMMENCEMENT_DATE' , 'DATE', 'Commencement Date' , 'Commencement Date'));
        defaultCategoryFieldsArr.push(SupplementalTaskCategory.createCategoryFieldRecord('DETAILS' , 'TEXT', 'Details' , 'Details'));
        defaultCategoryFieldsArr.push(SupplementalTaskCategory.createCategoryFieldRecord('FEE_AMOUNT' , 'AMOUNT', 'Fee/Amount' , 'Fee/Amount'));
        defaultCategoryFieldsArr.push(SupplementalTaskCategory.createCategoryFieldRecord('NOTES_1' , 'TEXT', 'Notes 1' , 'Notes 1'));
        defaultCategoryFieldsArr.push(SupplementalTaskCategory.createCategoryFieldRecord('NOTES_2' , 'TEXT', 'Notes 2' , 'Notes 2'));
        defaultCategoryFieldsArr.push(SupplementalTaskCategory.createCategoryFieldRecord('NOTES_3' , 'TEXT', 'Notes 3' , 'Notes 3'));
        defaultCategoryFieldsArr.push(SupplementalTaskCategory.createTicklerGroup('TICKLER_GROUP','COMPOSITE','Include Follow up / Tickler Fields', 'Include Follow up / Tickler Fields'));
        return defaultCategoryFieldsArr;
    }


}
