import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {FctServiceMessage} from './fct-service-message';

export class SendLenderChangesStatusResponse extends BaseEntity {

    statusCode: number;
    serviceMessages: FctServiceMessage[] = [];

    constructor(t?: SendLenderChangesStatusResponse) {
        super(t);
        if (t && t.serviceMessages) {
            this.serviceMessages = t.serviceMessages;
        }
    }

    get isSuccessful(): boolean {
        return this.statusCode == 200;
    }

    hasMessage(){
        return this.serviceMessages && Array.isArray(this.serviceMessages) && this.serviceMessages.length > 0;
    }

    getMessage(): string{
        let message: string = '';
        if(this.serviceMessages && Array.isArray(this.serviceMessages)){
            this.serviceMessages.forEach(serviceMsg => {
                if(serviceMsg.message){
                    message += `${serviceMsg.message} (${serviceMsg.id})<br><br>`;
                }
            });
        }
        return message;
    }

}
