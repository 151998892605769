export class ApplicationError {

    constructor(applicationError?: ApplicationError) {

        if (applicationError) {
            for (let prop in applicationError) {
                if (applicationError.hasOwnProperty(prop)) {
                    this[prop] = applicationError[prop];
                }
            }
            this.fieldErrors = [];
            if(applicationError.fieldErrors){
                for (let i: number = 0; i < applicationError.fieldErrors.length; i++) {
                    this.fieldErrors[i] = new FieldError(applicationError.fieldErrors[i]);
                }
            }

        }

    }

    fieldErrors: FieldError[];

    // just for the legacy codes that rely on the previously returened errorMessage:
    summary: string;
    message: string;
    errorCode: string;
    location: string;
    accountId: string;
    userId: string;
    stack: string;
    status: string;
    statusText: string;
    url: string;
    browserInfo: string;

    get errorMessage(): string {
        let errorCode = this.message ? this.message + ` [${this.errorCode}]` : this.errorCode;
        return this.errorCode ? errorCode : this.summary;
    }
}

export class FieldError {

    constructor(fieldError?: FieldError) {

        if (fieldError) {
            for (let prop in fieldError) {
                if (fieldError.hasOwnProperty(prop)) {
                    this[prop] = fieldError[prop];
                }
            }
        }
    }

    errorCode: string;

    message: string;

    field: string;
}
