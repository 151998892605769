import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {PackageSharingStatus} from './shared-documents-package';

export class SharedDocumentsSummaryItem{
    id: number;
    documentId: number;
    sourceDocumentId: number;
    guid: string;
    recipient: string;
    recipientRole: string;
    fileName: string;
    description: string;
    lastViewedTimeStamp: string;
    lastSharedTimeStamp: string;

    visible: boolean = true;
    expanded: boolean = true;
    isRecipientName: boolean = false;
    isRecipientRole: boolean = false;
    burgerMenu: BurgerMenuExtendedItem[];
    isDocSharingRevoked: boolean;
    packageSharingStatus: PackageSharingStatus ;
    packageStatus: string;

    isPackageSharingRevoked() : boolean {
        return this.packageSharingStatus == 'REVOKED';
    }

    isPackageSharingPartiallyRevoked() : boolean {
        return this.packageSharingStatus == 'PARTIALLY_REVOKED';
    }

    isPackageSharingAvailable() : boolean {
        return this.packageSharingStatus == 'AVAILABLE';
    }

    isSharedDocumentPackageDeclinedOrAck(): boolean{
        return (this.packageStatus == 'DECLINED' || this.packageStatus == 'DECLINE_ACKNOWLEDGED');
    }

    getPackageStatusToDisplay(): string{
        return this.packageStatus == 'DECLINE_ACKNOWLEDGED' ? 'DECLINE ACKNOWLEDGED': this.packageStatus;
    }
}
