import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class CondominiumPlan  extends BaseEntity{

    id: number;
    condominiumPlanType : string;
    condominiumPlanNumber : string;

    constructor(condominiumPlan? : CondominiumPlan) {
        super(condominiumPlan)
    }
}
