import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import Utils from '../../../app/shared-main/utils';
import {WindowRef} from '../../../app/shared/window.ref';

declare var jQuery : any;

class DocusignPortalContext extends BSModalContext {
    title: string
    securedUrl : string;
}
@Component({
               selector    : 'dp-docusign-portal-modal',
               templateUrl : 'docusign-portal.modal.component.html',
           })
export class DocusignPortalModalComponent implements OnInit, ModalComponent<DocusignPortalContext> {
    context : DocusignPortalContext;
    docuSignUrl: SafeUrl;


    @ViewChild('docuSignFrame') docuSignFrame: ElementRef;

    constructor(public dialog : DialogRef<DocusignPortalContext>,
                public sanitizer: DomSanitizer,public winRef: WindowRef)
    {
        this.context = dialog.context;

    }

    ngOnInit() {
        this.docuSignUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.securedUrl);
        this.registerDocuSignCallbackMethod();

    }

    registerDocuSignCallbackMethod() {
        this.winRef.nativeWindow.docuSignCallback = (redirectUrl) => {
            const event = Utils.extractQueryParamFromUrl(redirectUrl, 'event');
            const envelopeId = Utils.extractQueryParamFromUrl(redirectUrl, 'envelopeId');
            this.dialog.close({ event  :event  , envelopeId  : envelopeId });
        }
    }
}
