import {FieldMetaData} from '../matters/shared/field-code-mapping';
import {TabsService} from '../core';

declare var jQuery: any;

export class FirstFocusMatterUtil {

    static setFocusOnFirstAvailableElement(): void {
        if (jQuery(".matters-content") && !(jQuery('.relogin-modal') && jQuery('.relogin-modal')).length) {
            let focusFirstElement = jQuery(".matters-content:last").find('.focus-first').filter(':visible:first');
            if (focusFirstElement && focusFirstElement.length != 0) {
                focusFirstElement.focus().scrollLeft(0);
            } else {
                jQuery(".matters-content:last").find('input[type=text],dp-checkbox label,textarea,select').filter(':visible:first')
                    .focus().scrollLeft(0);
            }
        }

    }


    static setFocusOnModalOnFirstAvailableElement(): void {
        if (jQuery(".modal-dialog") && !(jQuery('.relogin-modal') && jQuery('.relogin-modal')).length) {
            let focusFirstElement = jQuery(".modal-dialog:last").find('.focus-first');
            if (focusFirstElement && focusFirstElement.length != 0) {
                focusFirstElement.focus().scrollLeft(0);
            } else {
                jQuery(".modal-dialog:last").find('input[type=text],input[type=checkbox] ,dp-checkbox label,textarea,select').filter(':visible:first')
                    .focus().scrollLeft(0);
            }
        }

    }

    static findAndFocusElementUsingElementMetaData(tabService: TabsService): void {
        let elementMetaData = tabService.activeTab.lastFocusedElementMetaData;
        if (elementMetaData.elementIdentifierXpath && elementMetaData.elementActionList && elementMetaData.elementActionList.length > 0) {
            tabService.lockScreenService.lockForUpdate = true;
            let noOfEventDispatched = 0;
            // Execute all actions until end of list is reached and then try to use original xpath to focus
            elementMetaData.elementActionList.forEach((elementAction, index) => {
                setTimeout(() => {
                    let focusedShutterElement = jQuery(document).xpath("/" + elementAction.xpath.toString().toLowerCase());

                    if (focusedShutterElement && focusedShutterElement.length > 0) {
                        // if its shutter then try to see if shutter is already open ....
                        if ((!jQuery(focusedShutterElement).hasClass("shutter-arrow") && !jQuery(focusedShutterElement).hasClass("accordion-wrapper")) || jQuery(focusedShutterElement).find("img[src$='plus.svg']").length > 0) {
                            setTimeout(() => {
                                // Open shutter or tab ...
                                jQuery(focusedShutterElement)[0].dispatchEvent(new CustomEvent('click'));
                                noOfEventDispatched = noOfEventDispatched + 1;
                                this.focusUsingOriginalXpath(elementMetaData, noOfEventDispatched, tabService, focusedShutterElement);
                            }, 500);
                        }
                        else {
                            noOfEventDispatched = noOfEventDispatched + 1;
                            this.focusUsingOriginalXpath(elementMetaData, noOfEventDispatched, tabService, focusedShutterElement);
                        }
                    }
                    else {
                        noOfEventDispatched = noOfEventDispatched + 1;
                        this.focusUsingOriginalXpath(elementMetaData, noOfEventDispatched, tabService);
                        // TODO: Find shutter and tab using identifier
                    }
                }, index * 1010);
            });

        }
        else {
            tabService.lockScreenService.lockForUpdate = true;
            setTimeout(() => {
                this.findElement(elementMetaData, tabService);
            }, 250);
        }
    }

    static findElement(elementMetaData: FieldMetaData, tabService: TabsService, lastActionElement?: any) {
        tabService.lockScreenService.lockForUpdate = false;
        let focusElement;
        let element = jQuery(document).xpath("/" + elementMetaData.elementOriginalXpath.toString().toLowerCase());
        let fieldMetaData = element && element.length > 0 ? tabService.generateFieldMetaData(element[0]) : undefined;
        if (element && element.length > 0 && fieldMetaData && ((!!fieldMetaData.elementId && elementMetaData.elementId == fieldMetaData.elementId) ||
            (!!fieldMetaData.elementName && elementMetaData.elementName == fieldMetaData.elementName) ||
            (!!fieldMetaData.elementLabel && elementMetaData.elementLabel == fieldMetaData.elementLabel) ||
            (!!fieldMetaData.elementAddressIdentifier && elementMetaData.elementAddressIdentifier == fieldMetaData.elementAddressIdentifier) ||
            (!!fieldMetaData.elementAutoSuggestIdentifier && elementMetaData.elementAutoSuggestIdentifier == fieldMetaData.elementAutoSuggestIdentifier) ||
            (!!fieldMetaData.elementPartialDateIdentifier && elementMetaData.elementPartialDateIdentifier == fieldMetaData.elementPartialDateIdentifier)
        )) {
            focusElement = element;
        }
        else if (lastActionElement) {
            let shutterBgElements = jQuery(lastActionElement[0]).parents('.shutter-bg');
            if(shutterBgElements && shutterBgElements.length > 0){
                if (elementMetaData.elementId) {
                    focusElement = jQuery(shutterBgElements[0]).find("[id=" + elementMetaData.elementId + "]");
                }
                else if (elementMetaData.elementName) {
                    focusElement = jQuery(shutterBgElements[0]).find("[name=" + elementMetaData.elementName + "]");
                }
            }

        }
        else if (elementMetaData.elementIdentifierXpath) {
            let elementIdentifier = jQuery(document).xpath("//" + elementMetaData.elementIdentifierXpath.toString().split('/')[0].toLowerCase());
            let query = [];
            if (elementIdentifier) {
                if (elementMetaData.elementAutoSuggestIdentifier) {
                    query.push("#" + (elementMetaData.elementAutoSuggestIdentifier));
                    query.push("[name=" + (elementMetaData.elementAutoSuggestIdentifier) + "]");
                }
                else if (elementMetaData.elementPartialDateIdentifier) {
                    query.push("[xpathid='"+ (elementMetaData.elementPartialDateIdentifier) +"']");
                }
                else {
                    if (elementMetaData.elementId || elementMetaData.elementName) {
                        if(elementMetaData.elementId) {
                            query.push("#" + (elementMetaData.elementId));
                        }
                        if(elementMetaData.elementName) {
                            query.push("[name=" + (elementMetaData.elementName) + "]");
                        }
                    }

                }
                let sc = jQuery(elementIdentifier).find(query.join(", "));
                if (sc && sc.length > 0 && sc[0].tagName == 'P-AUTOCOMPLETE') {
                    focusElement = jQuery(sc[0]).find('.ui-inputtext');
                }
                else if (sc && sc.length > 0 && sc[0].tagName == 'DP-PARTIAL-DATE') {
                    focusElement = jQuery(sc[0]).find('[name='+elementMetaData.elementName+']');
                }
                else {
                    focusElement = sc;
                }
            }
        }
        if (focusElement && focusElement.length > 0) {
            jQuery(focusElement[0]).focus();
        }
        else {
            this.setFocusOnFirstAvailableElement();
        }
    }

    static focusUsingOriginalXpath(elementMetaData: FieldMetaData, noOfEventDispatched: number, tabService: TabsService, lastActionElement?: any): void {
        if (elementMetaData.elementActionList.length == noOfEventDispatched) {
            setTimeout(() => {
                this.findElement(elementMetaData, tabService, lastActionElement);
            }, 500);
        }
    }

}
