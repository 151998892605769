import {Telephone} from './telephone';

import * as _ from 'lodash';
import {PhoneTypeCode} from './telephone-types';
import {KeyCode} from '../../shared-main/constants';
import moment from 'moment';
import {DatePipe} from '@angular/common';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {provinceBasedFieldDisplay} from '../../shared-main/province-based-field-labels';
import {Subject} from 'rxjs/Subject';

declare var jQuery : any;

const datePattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;

const maxMailToUrl = 2000;

export class Utils {

    constructor() { }

    isEmptyString(value: string): boolean {

        if (value === undefined || value === '' || value === null) {
            return true;
        } else {
            return false;
        }
    }

    static returnValidString(value: string): string {

        if (value === undefined || value === null) {
            return '';
        } else {
            return value;
        }
    }

    // add comma when value is present
    static getNameWithComma(value: string): string {

        if (value === undefined || value === null || value === ' ') {
            return '';
        } else {
            return ', ' + value;
        }
    }


    // return if browser is IE 11
    isIE11() {
        return /Trident.*rv[ :]*11\./.test(navigator.userAgent);
    }

    // return if any IE Browser
    isIE(){
            let ua = window.navigator.userAgent;
            let msie = ua.indexOf("MSIE ");
            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return true
            {
                return true
            }
            else  // If another browser, return false
            {
                return false;
            }

    }

    isIEOrEdge(): boolean {
        return navigator.appVersion.toString().indexOf('.NET') > 0 || navigator.appVersion.toString().indexOf('Edge') > 0 ;
    }

    getBrowserInfo() : string {
        return window.navigator.userAgent;
    }

    // get startTypeTo string for lawClerk/ solicitor
    getStartTypingToPlaceholder(): any {

        let contact: any = {
            contactName: {}
        };

        contact.contactName.initials = 'Start typing to search for desired INITIALS or Manually Enter';
        let contacts: any[] = [];
        contacts.push(contact);

        return contacts;
    }

    // get startTypeTo string for RealEstateBroker
    getStartTypingToRealEstateBroker() {

        let realEstateBroker: any = {};
        realEstateBroker.nameOnDocuments =
            'Start typing to search for desired BROKER or NAME OF AGENT';

        let realEstateBrokers: any[] = [];
        realEstateBrokers.push(realEstateBroker);

        return realEstateBrokers;
    }


    // get startTypeTo string for RealEstateAgent
    getStartTypingToRealEstateAgent() {

        let realEstateAgent: any = {};
        realEstateAgent.nameOnDocuments =
            'Start typing to search for desired Agent or NAME OF AGENT';

        let realEstateAgents: any[] = [];
        realEstateAgents.push(realEstateAgent);

        return realEstateAgents;
    }

    // get startTypeTo string for purchaser
    getStartTypingToContact() {

        let purchaser: any = {};
        purchaser.displayName = `Start typing to search for purchaser(s) or to add new one(s)`;

        let purchasers: any = [];
        purchasers.push(purchaser);

        return purchasers;
    }

    // get startTypeTo string for Vendor
    getStartTypingToVendor() {

        let otherParty: any = {};
        otherParty.displayName = `Start typing to search for vendor or to add new vendor`;

        // let vendors: any = [];
        // vendors.push(otherParty);

        return otherParty;
    }

    // get startTypeTo string for JURISDICTION
    getStartTypingToJurisdiction() {

        let jurisdiction: any = {};
        jurisdiction.jurisdictionName = 'Start typing to search for desired NAME OF JURISDICTION';

        let jurisdictions: any[] = [];
        jurisdictions.push(jurisdiction);

        return jurisdictions;
    }

    /** This method is write for added currency with symbol */
    static addedCurrencySymbol(currency) {
        return '$' + Number(currency).toFixed(2);
    }


    static removeCurrencySymbol(currencyValue: string): string {
        if (typeof currencyValue !== 'number' && !currencyValue) {
            return undefined;
        }

        let currencyStringVal: string = '' + currencyValue;

        if (currencyStringVal.length > 0) {
            currencyStringVal = currencyStringVal.replace(/[\$,]/g, '');
        }

        return currencyStringVal;
    }

    static toUpperCase(value: string){
        return value ? value.toUpperCase() : value;
    }


    static capitalizeTypedName(string : string) {
        if(!string) {
            return null;
        }
        if(string.indexOf(' ') >= 0) {
            return string.replace(/\w\S*/g, function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        } else {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    /**
     * This is a helper method to escape the special characters in search text.
     * As Underscore & comma are used as delimiters in search logic on server side
     * therefore they are escaped with a leading backslash.
     * @param searchValue
     * @returns {string}
     */
    static escapeSearchText(searchValue: string): string {
        if(searchValue) {
            searchValue = searchValue.split("_").join("\\_");
            searchValue = searchValue.split("|").join("\\|");
            searchValue = searchValue.split(",").join("\\,");
            //Encode URI component for any other special characters
            searchValue = encodeURIComponent(searchValue);
        }
        return searchValue;
    }

    static parseSort(sorting,sortType) : string{
        let res:any[] = [];
        let multipleSorting:string = '';
        if(sorting!=null){

            if (sorting.indexOf(',') > -1)
            {

                res = sorting.split(",");
                for (let i = 0; i < res.length; i++) {
                    if(i== (res.length-1)){
                        multipleSorting = multipleSorting + res[i] + "|" + sortType;
                    } else {
                        multipleSorting = multipleSorting + res[i] + "|" + sortType + ",";
                    }

                }
                return multipleSorting;
            } else {
                return sorting + "|" + sortType;
            }
        } else {
            return "";
        }
    }

    static addCommaToFilter(filter) : string{
        if(filter != '') {
            filter += ',';
        }
        return filter;
    }

    handleKeyDown(event: any, dataField:any, callback: Function){
        //console.log("handle KeyDown event with dataField=", dataField);
        if(event.keyCode== KeyCode.Enter || (event.keyCode == KeyCode.Space && !dataField)) {
            event.preventDefault();
            event.stopPropagation();
        }
        if(!dataField){

            callback(event);
        }
    }


    handleSpaceKeyDropdown(event: any, dataField:any, callback: Function){
        console.log("handleSpaceKeyDropdown | callback=", callback);
        if(!dataField || (dataField && !(dataField.trim()))){
            event.stopImmediatePropagation();
            //event.preventDefault();
            callback(event);
        }
    }

    handleEnterKeyDropdown(event: any, dataField:any, callback: Function){
        //console.warn("handleEnterKeyDropdown | event=", event);
        if(dataField && dataField.trim()){
            return false;
        }
        event.stopImmediatePropagation();
        //event.preventDefault();
        callback(event);

    }

    static isValidPin(pin: string, maxLength: number = 9): boolean {
        if(pin) {
            let re = new RegExp(`^[0-9]{${maxLength}}$`);
            return re.test(pin);
        } else {
            return true;
        }
    }

    static isFormattedPinValid(pin: string): boolean {
        if(pin) {
            let re = new RegExp("^[0-9]{5}-[0-9]{4}$");
            return re.test(pin);
        } else {
            return true;
        }
    }


    //assume YES == Y_n, NO == N_y
    static isSameBooleanValue(value1: string, value2: string) : boolean{
        if(value1 && value2){
            if((value1.toUpperCase() == 'YES' || value1.toUpperCase() == 'Y_N') && (value2.toUpperCase() == 'YES' || value2.toUpperCase() == 'Y_N')){
                return true;
            }
            if((value1.toUpperCase() == 'NO' || value1.toUpperCase() == 'N_Y') && (value2.toUpperCase() == 'NO' || value2.toUpperCase() == 'N_Y')){
                return true;
            }
            return value1.toUpperCase() == value2.toUpperCase();
        }else{
            return value1 == value2;
        }

    }

    static isWritFormatValid(writ: string): boolean {
        if(writ) {
            let re = new RegExp("^[0-9a-zA-Z]{2}-[0-9]{7}$");
            return re.test(writ);
        } else {
            return true;
        }
    }

    static cutStringValue(value: string, maxLength : number){
        if(value == null || value.length <= maxLength){
            return value;
        }
        return value.substr(0, maxLength);
    }

    static truncateStringForDisplay(input: string, maxLength: number, suffix: string = ''): string{
        if (!input){
            return '';
        }
        if (input.length <= maxLength){
            return input;
        }
        if (!suffix || suffix.length >= maxLength){
            return input.substr(0, maxLength);
        }
        return input.substr(0, maxLength - suffix.length) + suffix;
    }

    static getFirstPhoneNumberBasedOnType(phones: Telephone[], type: PhoneTypeCode) : string{
        if(Array.isArray(phones) && type){
            let foundPhone : Telephone = _.find(phones, phone=>phone.phoneTypeCode===type);
            if(foundPhone){
                return foundPhone.telephoneNumber;
            }
        }
        return '';
    }

    static getPhoneNumbersBasedOnType(phones: Telephone[], type: PhoneTypeCode, separator? : string) : string{
        if(Array.isArray(phones) && type){
            let phonesWithType = phones.filter(phone=>phone.phoneTypeCode===type)
            if(Array.isArray(phonesWithType)){
                // return foundPhone.telephoneNumber;
                let phoneNumString = phonesWithType.map(phone => phone.telephoneNumber).join(separator ? separator : ', ');
                return phoneNumString;
            }
        }
        return '';
    }

    static getPhoneNumbersBasedOnTypeOrder(phones: Telephone[], type: PhoneTypeCode, separator? : string) : string{
        if(Array.isArray(phones) && type){
            let phonesWithType = phones.filter(phone=>phone.phoneTypeCode===type)
            if(Array.isArray(phonesWithType)){
                // return foundPhone.telephoneNumber;
                let phoneNumStringArray = phonesWithType.map(phone => phone.telephoneNumber);
                phoneNumStringArray= phoneNumStringArray.sort();
                let phoneNumString = phoneNumStringArray.join(separator ? separator : ', ');

                return phoneNumString;
            }
        }
        return '';
    }

    static bringFieldIntoVisibleArea(target: any) {
        // TODO:  need to mod this for modal windows popups, can only be used for full page layout
        let scrollAmount = jQuery(window).scrollTop();
        let fixedElementsFooterHeight = jQuery('.inspector-footer').height();
        let winHeight = jQuery(window).height();
        let docHeight = jQuery(document).height();
        let pos = jQuery(target).offset();
        let targetHeight = jQuery(target).height();
        let maxScroll = docHeight - winHeight;
        let fixedElementsHeaderHeight = jQuery('nav').outerHeight() + jQuery('.matter-header').outerHeight() + jQuery('.matter-title').outerHeight();
        // add other fixed elements to figure out scrolling area looks for any elements that contain class .fixed-element-for-scrolling
        let otherElementsInFixedHeaderHeight: number  = 0;
        jQuery('.fixed-element-for-scrolling').each(function() {
            otherElementsInFixedHeaderHeight += jQuery(this).outerHeight();
        });
        fixedElementsHeaderHeight = fixedElementsHeaderHeight + otherElementsInFixedHeaderHeight;

        // all elements that impede on viewable area ie. 0px + winHeight = viewable area
        // check if element is in visible area
        // 1. find out how much we are scrolled in percent
        let percentScrolled = scrollAmount/maxScroll;
        // 2. find out the current visible area
        let currentVisibleAreaStart = ((docHeight-winHeight)*percentScrolled)+fixedElementsHeaderHeight;
        let currentVisibleAreaEnd = currentVisibleAreaStart + winHeight - fixedElementsHeaderHeight - fixedElementsFooterHeight;
        /// add element on screen for testing purposes
        /*
           let newDiv = '<div class="div-dp-table-excel" style="position: absolute; z-index: 1000; left: 545px; display: block; width: 100px; background:' +
               ' rgba(255,0,0,.3);' +
               ' height:' +
               ' ' + (currentVisibleAreaEnd  - currentVisibleAreaStart) + 'px;' +
           ' top: '+ currentVisibleAreaStart +'px;">Test Div</div>';
           jQuery('.div-dp-table-excel').remove();
           jQuery('.main-outlet-content').append(newDiv);
        */
        // 3. check if element is in visible area
        let isElementInVisibleArea: boolean = false;
        if((pos.top)> currentVisibleAreaStart && (pos.top+targetHeight)<currentVisibleAreaEnd){
            isElementInVisibleArea = true;
        }
        // 4. If element is not in visible area we move the scroll bar to visible area for element
        // scroll to the element and subtract fixed header height as well as 100 pixels of padding

        let newVisibleAreaStart = pos.top - fixedElementsHeaderHeight - 200;
        // if the new visible area is greater than 0, we can scroll to it, else we scroll to 0.
        if(!isElementInVisibleArea && newVisibleAreaStart>0){
            jQuery(window).scrollTop(newVisibleAreaStart);

        } else if(!isElementInVisibleArea && newVisibleAreaStart<=0){
            jQuery(window).scrollTop(0);
        }

    }

    static stringDifferent(transitNo1 : string, transitNo2 : string) : boolean{
        if(transitNo1 && transitNo2) {
            return transitNo1 != transitNo2;
        } else if(!transitNo1 && !transitNo2) {
            return false;
        } else {
            return true;
        }
    }

    static convertStringToTitleCase(word: string): string {
        return  word.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
    }

    static convertAmountToNumber(amountStr: string): number {
        if (amountStr) {
            return Number(amountStr.replace(/[^0-9\.]+/g, ""));
        }
        return 0;
    }

    //This method preserves the -ve sign while converting string to number
    static convertAmountToNumberWithSign(amountStr: string): number {
        if (amountStr) {
            return Number(amountStr.replace(/[^-0-9\.]+/g, ""));
        }
        return 0;
    }

    static getDateDiffInDays(first: Date, second: Date): number{
        return Math.round((second.getTime()-first.getTime())/(1000*60*60*24));
    }

    /**
     * returns number of days between [[from]] and [[to]]
     * for invalid inputs (i.e. null, undefined, partial dates) returns 0
     *
     * @param {string} from
     * @param {string} to
     * @returns {number}
     */
    static getDateDiff(from : string, to : string) : number {
        if(this.isNotValidDate(from) || this.isNotValidDate(to)) {
            return 0;
        } else {
            let fromDate = moment(from, "YYYY/MM/DD");
            let toDate = moment(to, "YYYY/MM/DD");
            return toDate.diff(fromDate, 'days');
        }
    }

    /**
     * checks for invalid input or partial dates
     *
     * @param {string} dt
     * @returns {boolean}
     */
    static isNotValidDate(dt : string) : boolean {
        return (dt == null || dt == '' || dt == undefined || dt.length < 10 || (dt && !datePattern.test(dt)));

    }

    static isDatePartial(dt : string) : boolean {
        if(dt != null && dt != '//' && dt.trim() != ''){
            return dt.length < 10 || !datePattern.test(dt);
        }
        return false;
    }

    static getDateTimeStamp(dateTxt: string) : string {
        if(dateTxt){
            return new DatePipe('en-US').transform(dateTxt, 'mediumDate') + ', ' + new DatePipe('en-US').transform(dateTxt, 'shortTime');
        } else {
            return '';
        }

    }

    static getMediumDateFormat(dateTxt: string) : string {
        if(dateTxt){
            return new DatePipe('en-US').transform(dateTxt, 'mediumDate');
        } else {
            return '';
        }
    }

    /* numberToWord is a function takes a number and return the Alpha Numeric value for that number. It's valid for numbers between 1-999 */
    static numberToWord(num : number) : string {

        let a = ['','ONE ','TWO ','THREE ','FOUR ', 'FIVE ','SIX ','SEVEN ','EIGHT ','NINE ','TEN ',
            'ELEVEN ','TWELVE ','THIRTEEN ','FOURTEEN ','FIFTEEN ','SIXTEEN ','SEVENTEEN ','EIGHTEEN ','NINETEEN '];
        let b = ['', '', 'TWENTY','THIRTY','FORTY','FIFTY', 'SIXTY','SEVENTY','EIGHTY','NINETY'];

        if ((num.toString()).length > 3) return 'OVERFLOW';
        let n = ('000' + num).substr(-3).match(/^(\d{1})(\d{2})$/);
        if (!n) return '';
        let str = '';
        str += (Number(n[1]) != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'HUNDRED ' : '';
        str += (Number(n[2]) != 0) ? ((str != '') ? 'and ' : '') + ( a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]] )  : '';
        return str;
    }

    static showByProvinceAndMatterType(keyName: string, provinceCode: ProvinceCode, matterTypeCode: string): boolean{
        if(provinceBasedFieldDisplay[keyName]){
            return provinceBasedFieldDisplay[keyName].some(item => {
                return item[provinceCode] && (!matterTypeCode ||  item[provinceCode].indexOf(matterTypeCode) >= 0 );
            })
        }
        return false;
    }

    static getFileNameWithoutExt(fileName : string) : string {
        let newName = '';
        if(fileName){
            newName = fileName.split('.').slice(0, -1).join('.');
        }
        return newName;
    }


    static formatDate(dt: string, defaultReturnForInvalidInput: string = ""): string {
        if(this.isNotValidDate(dt)) {
            return defaultReturnForInvalidInput;
        }
        return moment(dt, "YYYY/MM/DD").format('MMMM DD, YYYY');

    }
    static todaysDate() : string {
        return  moment(new Date()).format("YYYY/MM/DD");
    }

    static getDateFormat(event) : string {
        let date : string;
        date = `${((event.year) ? event.year : '')}/${((event.month) ? event.month : '')}/${((event.day) ? event.day : '')}`;
        return date;
    }

    //expected dateValue format is yyyy/MM/dd
    static createDateValues(dateValue: string = null, yearValue: string = null, monthValue: string = null, dayValue: string = null): {dateValue: string, yearPart: string, monthPart: string, dayPart: string}{
        let yearPartValue: string = '';
        let monthPartValue: string = '';
        let dayPartValue: string = '';
        if(dateValue){
            let values : string[] = dateValue.split('/');
            if(Array.isArray(values) && values.length == 3){
                yearPartValue = values[0] && values[0].trim().toLowerCase() != 'undefined' ? values[0].trim() : '';
                monthPartValue = values[1] && values[1].trim().toLowerCase() != 'undefined' ? values[1].trim() : '';
                dayPartValue = values[2] && values[2].trim().toLowerCase() != 'undefined' ? values[2].trim() : '';
            }
        }else{
            yearPartValue = yearValue && yearValue.trim().toLowerCase() != 'undefined' ? yearValue.trim() : '';
            monthPartValue = monthValue && monthValue.trim().toLowerCase() != 'undefined' ? monthValue : '';
            dayPartValue = dayValue && dayValue.trim().toLowerCase() != 'undefined' ? dayValue : '';
        }

        if(yearPartValue || monthPartValue || dayPartValue){
            return {dateValue: `${yearPartValue}/${monthPartValue}/${dayPartValue}`, yearPart: yearPartValue, monthPart: monthPartValue, dayPart: dayPartValue};
        }else{
            return {dateValue: null, yearPart: null, monthPart: null, dayPart: null};
        }
    }

    static refreshSubject(subject: Subject<string> | Subject<any>) : Subject<string> | Subject<any>{
        if(subject.isStopped){
            subject = new Subject<string | any>();
        }
        return subject;
    }

    static checkIfMailToUrlIsOverLimit(mailToUrl: string, alreadyEncoded: boolean = false, extraCharsLength: number = 0): boolean{
        if (!mailToUrl){
            return false;
        }
        if (!alreadyEncoded){
            mailToUrl  = encodeURIComponent(mailToUrl);
        }
        if (mailToUrl.length + extraCharsLength > maxMailToUrl){
            console.error('Potential over-sized mail to url, please check for failed emails.');
            console.error(mailToUrl);
            return true;
        }
        return false;
    }

    static isValidWindowsFileName(fname : string) : boolean{
        let rg1=/^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
        let rg2=/^\./; // cannot start with dot (.)
        let rg3=/^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names;
        return rg1.test(fname)&&!rg2.test(fname)&&!rg3.test(fname);
    }

    static signingOfficerTypeText(gender:string):string{
        switch(gender){
            case "CORPORATION":
            case "OTHERENTITY":
                return 'Signing Officer';
            case "ESTATE":
                return  'Estate Trustee';
            case "FEMALEPOA":
            case "MALEPOA":
                return 'Name of Attorney';
        }
        return ''
    }

    static signingOfficerType(gender:string):string{
        switch(gender){
            case "CORPORATION":
            case "OTHERENTITY":
                return 'signer';
            case "ESTATE":
                return  'estate trustee';
            case "FEMALEPOA":
            case "MALEPOA":
                return 'attorney';
        }
        return ''
    }


}

export const OMNIBAR_PLACEHOLDERS: any = {
    "lawclerk": [{"lawClerkInitials" : "Search for desired INITIALS or Manually Enter"}]
};
