import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import Utils from '../../../shared-main/utils';


export class SoAdjTaxOther extends BaseEntity {

    id: number;

    creditType: string;
    adjustmentHeading: string;
    cost: number;
    taxType: string;
    percentage: number;
    chargeType: string;

    isVendorSelected : boolean ;

    get taxValue(): number { return Utils.roundCurrency(this.cost * this.percentage / 100); }

    get costPlusTaxValue(): number  {return Number(this.cost) + Number(this.taxValue); }

    get adjValue(): number {
        if(this.chargeType == 'COST_PLUS_TAX')  {
            return this.costPlusTaxValue;
        }
        if(this.chargeType == 'TAX_ONLY')       {
            return this.taxValue;
        }
        return 0;
    }

    isTaxOnly() : boolean {
        return (this.chargeType == 'TAX_ONLY');
    }

}
