import {ProvinceCode} from '../../admin/accounts/shared/account-province';


export class MatterParticipantSigningLocation {

    constructor(matterParticipantSigningLocation? : MatterParticipantSigningLocation) {

        if(matterParticipantSigningLocation) {
            for(let prop in matterParticipantSigningLocation) {
                if(matterParticipantSigningLocation.hasOwnProperty(prop)) {
                    this[prop] = matterParticipantSigningLocation[prop];
                }
            }
        }
    }

    id : number;
    jurisdictionName : string;
    signerMatterParticipantId : number;
    provinceCode: ProvinceCode;
    city : string;
    municipality : string;
    outOfProvince: boolean;
}
