import {api} from '../../../common/api';
import {ProvinceCode} from './account-province';

export const accountApi = {

    login: `${api}/authorize/login`,

    fakeLogin: `${api}/authorize/sso-login`,

    logout: `${api}/authorize/logout`,

    ssoLogout: `${api}/authorize/sso-logout`,

    register: `${api}/register`,

    registerForUser(userId: number): string {return `${api}/register/users/${userId}`; },

    accounts: `${api}/customerAccounts`,

    accountFileFolders(accountId: string): string {return `${api}/accounts/${accountId}/accountFileFolders`; },

    systemAccountFileFolder:`${api}/public/accounts/accountFileFolders`,

    accountAdd: `${api}/customerAccounts`,

    legalFirm: `${api}/public/legalFirms`,

    publicCustomerAccount(accountId: string): string {return `${api}/public/customerAccounts/${accountId}`;},

    ConfigurationOptionLabels: `${api}/accounts/{accountId}/configurationOptionLabels/DefaultOtherPersonInfoFields`,

    ConfigurationOptionValues: `${api}/accounts/{accountId}/configurationOptionValues/DefaultOtherPersonInfoFields`,

    SystemConfigurationUrl: `${api}/accounts/{accountId}/configurationOptionValues/SystemConfiguration`,

    ConfigurationTypeResponseKey: 'ConfigurationType',

    accessProfiles: `${api}/accounts/{accountId}/accessProfiles/`,

    requisitionInstrumentConfig: `${api}/customerAccounts/{customerId}/config/requisitionInstrument`,

    getAccountProvince(accountId: string, provinceCode: ProvinceCode): string {return `${api}/accounts/${accountId}/province/${provinceCode}`; },

    putAccountProvince(accountId: string, provinceId: number): string {return `${api}/accounts/${accountId}/province/${provinceId}`; },

    getDateCalculationConfig(accountId: string): string {return `${api}/customerAccounts/${accountId}/dateCalculationConfig`; },

    updateDateCalculationConfig(accountId: string): string {return `${api}/customerAccounts/${accountId}/dateCalculationConfig`; },

    creditCardCheckExclusions:`${api}/maintenance/creditCardCheckExclusions`,

    deleteCreditCardCheckExclusion( id: number): string {return `${api}/maintenance/creditCardCheckExclusions/${id}`; },

    addCreditCardCheckExclusion:`${api}/maintenance/creditCardCheckExclusions`,

    matterTypes( accountId: string): string {return `${api}/accounts/${accountId}/matter/matterTypes`; },

    restrictedPeriods() : string {return `${api}/public/matters/restrictions`;},

    createdMattersCount(accountId: string, startDate: string, endDate: string, provinceCode: string): string
        {return `${api}/accounts/${accountId}/matters/count?startDate=${startDate}&endDate=${endDate}&provinceCode=${provinceCode}`},

    matterNotificationConfig(accountId: string)
        { return`${api}/accounts/${accountId}/matter/notification/config`},

    accountReminderConfiguration(accountId: string)
    { return`${api}/accounts/${accountId}/reminders/configuration`},

    accountNotificationConfig(accountId: string)   { return`${api}/accounts/${accountId}/notification/config`},

    optOutEmails: `${api}/public/notifications/optout/emails`,

    billedMattersForThisMonth(accountId: string) { return`${api}/accounts/${accountId}/matters/billedMattersForThisMonth`},

    accountRemoteSigningDefault(accountId: string, provinceCode: string)
    { return`${api}/accounts/${accountId}/remoteSigningConfiguration?provinceCode=${provinceCode}`},

    setAccountRemoteSigningDefault(accountId: string, provinceCode: string)
    { return`${api}/accounts/${accountId}/province/${provinceCode}/remoteSigningConfiguration`},

    redirectUser(userId: string, unityMessageId: string)
    { return`${api}/users/${userId}/messages/${unityMessageId}/redirectUser`},


};
