export const AltoUniversalEformsXpathConfigs = {
    xPathConfigs : [
        {xpath:'$.fileNumber', matterType : 'PSM', xpathType: 'Object'}
    ]
};

export const AltoTOLEformsXpathConfigs = {
    xPathConfigs : [
        //SubjectProperty
        {xpath:'$.matterProperties[0].titleNumber', matterType : 'S', xpathType: 'Object'},
        {xpath:'$.matterProperties[0].purchasePrice', matterType : 'S', xpathType: 'Object'},

        //Matter Opening
        {xpath:'$.purchaserExecDocsAt', matterType : 'S', xpathType: 'Object'},
        {xpath:'$.purchaserExecDocsDate', matterType : 'S', xpathType: 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "WITNESS")].contact.firstName', matterType : 'S', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "WITNESS")].contact.lastName', matterType : 'S', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "WITNESS" && contact.address[0].sameAsAddressTypeCode!= "CUSTOMER_ACCOUNT")].contact.address[0].city', matterType : 'S', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "WITNESS" && contact.address[0].sameAsAddressTypeCode!= "CUSTOMER_ACCOUNT")].contact.address[0].provinceName', matterType : 'S', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "WITNESS" && contact.address[0].sameAsAddressTypeCode!= "CUSTOMER_ACCOUNT")].contact.address[0].country', matterType : 'S', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "WITNESS" && @.contact.contactType == "LAWCLERK")].contact.contactProvinceCapacities[?(@.provinceCode == "AB")].provinceCapacity', matterType : 'S', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "WITNESS")].contact.contactProvinceCapacities[?(@.provinceCode == "AB" && @.provinceCapacity == "COMMISSIONER")].commissionExpiry', matterType : 'S', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "COMMISSIONER")].contact.firstName', matterType : 'S', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "COMMISSIONER")].contact.lastName', matterType : 'S', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "COMMISSIONER" && @.contact.contactType == "LAWCLERK")].contact.contactProvinceCapacities[?(@.provinceCode == "AB")].provinceCapacity', matterType : 'S', xpathType : 'Object'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "COMMISSIONER")].contact.contactProvinceCapacities[?(@.provinceCode == "AB" && @.provinceCapacity == "COMMISSIONER")].commissionExpiry', matterType : 'S', xpathType : 'Object'},

        //Vendor(s)
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "VENDOR")].familyLawActs[0].registeredOnDate', matterType : 'S', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "VENDOR")].familyLawActs[0].instrumentNo', matterType : 'S', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "VENDOR")].familyLawActs[0].consentedSpouse.firstName', matterType : 'S', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "VENDOR")].familyLawActs[0].consentedSpouse.lastName', matterType : 'S', xpathType : 'List'},

        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "VENDOR")].contact.firstName', matterType : 'S', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "VENDOR")].contact.lastName', matterType : 'S', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "VENDOR")].contact.additionalName1', matterType : 'S', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "VENDOR")].contact.executingUnderSealFlag', matterType : 'S', xpathType : 'List'},
        {xpath:'$.matterContactInfo.witnessKnowsTransferors', matterType : 'S', xpathType : 'Object'},

        //Purchaser(s)
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "PURCHASER")].contact.firstName', matterType : 'S', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "PURCHASER")].contact.lastName', matterType : 'S', xpathType : 'List'},
        {xpath:'$.matterParticipants[?(@.matterParticipantRole == "PURCHASER")].purchaserShare', matterType : 'S', xpathType : 'List'},

        //Address
        {xpath:'$.matterContactInfo.residingAtSubjectProperty', matterType : 'S', xpathType: 'Object'},

    ]
};

export const AltoTOLEformsServiceAddressXpathConfigs = {
    xPathConfigs : [
        {xpath:'$.otherPartyContactInfo.serviceAddress.addressLine1', matterType : 'S', xpathType: 'Object'},
        {xpath:'$.otherPartyContactInfo.serviceAddress.city', matterType : 'S', xpathType: 'Object'},
        {xpath:'$.otherPartyContactInfo.serviceAddress.provinceName', matterType : 'S', xpathType: 'Object'},
        {xpath:'$.otherPartyContactInfo.serviceAddress.postalCode', matterType : 'S', xpathType: 'Object'},

    ]
};

export const AltoTOLEformsPropertyAddressXpathConfigs = {
    xPathConfigs : [
        {xpath:'$.matterProperties[0].address.addressLine1', matterType : 'S', xpathType: 'Object'},
        {xpath:'$.matterProperties[0].address.city', matterType : 'S', xpathType: 'Object'},
        {xpath:'$.matterProperties[0].address.provinceName', matterType : 'S', xpathType: 'Object'},
        {xpath:'$.matterProperties[0].address.postalCode', matterType : 'S', xpathType: 'Object'},

    ]
};
