import {Matter} from '../../shared';
import {MatterTax} from '../../shared/property-taxes';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {InterimTaxMultiplier} from '../../../shared-main/constants';

export class PropertyTaxesUtil{

    static interimTaxMultiplier : number = 2;

    //isProject && propertyTax.adjustmentBasedOnPercentageOfTotalTaxes=='YES' && (matter.isBaseTaxAdjustmentEqualDivisionAmongUnits || matter.isBaseTaxAdjustmentOnPercentageOfTaxesForProject) && !realtyTaxesPaidByVendor && !isAdjOfTypeTaxBeginningOfYearUntilOcc">
    static isProportionateShareVisible(matter: Matter, propertyTax: MatterTax): boolean{
        return this.isProjectOrPS(matter) &&  propertyTax.adjustmentBasedOnPercentageOfTotalTaxes=='YES' &&
            ( (matter.isBaseTaxAdjustmentEqualDivisionAmongUnits && propertyTax.proportionateShareCalculated) || matter.isBaseTaxAdjustmentOnPercentageOfTaxesForProject)
    }


    static isProjectOrPS(matter: Matter) : boolean {
        return matter && (matter.isProjectSale || matter.templateForProject || (matter.project && matter.isPurchase && !!matter.matterLink));
    }

    //always return string[2]
    static getProportionateShareLabels(matter: Matter, propertyTax: MatterTax, currencyPipe: CurrencyPipe, decimalPipe: DecimalPipe): string[]{
        let labels: string[] = [];
        labels.push('Proportionate share calculated');
        let formattedPurchaserPortaion: string = decimalPipe.transform(propertyTax.purchaserPortionOfTotalTax, '1.2-9');
        labels.push(`at ${formattedPurchaserPortaion} % of ${this.getFormattedCurrencyValue(currencyPipe, this.getProportionateShare(propertyTax))}`)
        return labels;
    }

    static isProportionateShareVisibleForVendor(matter: Matter, propertyTax: MatterTax): boolean{
        return this.isProjectOrPS(matter) &&  propertyTax.adjustmentBasedOnPercentageOfTotalTaxes=='YES' &&
            ( matter.isBaseTaxAdjustmentEqualDivisionAmongUnits  || matter.isBaseTaxAdjustmentOnPercentageOfTaxesForProject)
    }

    static getProportionateShareLabelsForVendor(vendorLabel:string, propertyTax: MatterTax, currencyPipe: CurrencyPipe, decimalPipe: DecimalPipe): string[]{
        let labels: string[] = [];
        labels.push('Proportionate share of '+ vendorLabel+  ' has');
        let formattedPurchaserPortaion: string = decimalPipe.transform(propertyTax.purchaserPortionOfTotalTax, '1.2-9');
        labels.push(`at ${formattedPurchaserPortaion} % of ${this.getFormattedCurrencyValue(currencyPipe, propertyTax.vendorWillHavePaid)}`)
        return labels;
    }



    static getFormatedProportionateShareAmt(propertyTax: MatterTax, currencyPipe: CurrencyPipe): string{
        return this.getFormattedCurrencyValue(currencyPipe, propertyTax.calculateProportionateShare(InterimTaxMultiplier));
    }

    static  getFormattedCurrencyValue(currencyPipe: CurrencyPipe, currencyValue : number) : string {
        if(currencyValue != undefined && !isNaN(currencyValue)
            && currencyValue != null && currencyValue.toString().trim() != "")
        {
            return  currencyPipe.transform(currencyValue,'CAD','symbol','1.2-2').replace("CA", "");
        }
        else{
            return currencyPipe.transform('0.00','CAD','symbol','1.2-2').replace("CA", "");;
        }

    }

    static getProportionateShare(propertyTax: MatterTax):number {
        return propertyTax.proportionateShare(InterimTaxMultiplier);
    }

    static calculateVendorShareDifference(matter: Matter, propertyTax: MatterTax) : number {
        return propertyTax.calculateVendorShareDifference(InterimTaxMultiplier, matter.getClosingDate(), matter.isPaysForDateOfClosingVendor, matter.isProjectProportionateShare, matter.provinceCode);
    }

    static calculateVendorHasPaidProjectForBeginningOfYearUntilOccp(soAdj: MatterTax, matter: Matter){
        if((matter.isBaseTaxAdjustmentOnPercentageOfTaxesForProject || matter.isBaseTaxAdjustmentEqualDivisionAmongUnits) && soAdj.adjustmentBasedOnPercentageOfTotalTaxes=='YES'){
            return Number(soAdj.vendorWillHavePaid)*Number(soAdj.purchaserPortionOfTotalTax)/100;
        }else{
            return soAdj.vendorWillHavePaid;
        }
    }
}
