export const AUTHORIZATION_KEYS = {

    ROLE_PREFIX : 'ROLE_',
    AUTHORITY_SEPARATOR : '-'
};
export enum AUTH_OPERATION {
    ADMINISTRATION,
    MATTERS,
    PROJECTS,
    MANAGE_PROJECTS,
    PROJECT_MATTERS,
    CONTACT_MANAGEMENT,
    MESSAGING,
    WORK_MANAGEMENT,
    REPORTING,
    account_list_view,
    GENERAL_ACCOUNT_MAINTENANCE,
    configure_teraview,
    CUSTOMER_BILLING_INFORMATION,
    forms,
    EVENTS,
    MANAGE_ADJUDICATION,
    CUSTOMER_ACCOUNT_LIST,
    CUSTOMER_DETAILS_NOTES,
    CUSTOMER_STAFF,
    CUSTOMER_DOCUMENTS,
    CUSTOMER_SYSTEM_CONFIGURATION,
    DOPROCESS_ACCOUNT_MANAGEMENT,
    ADD_CUSTOMER_ACCOUNT,
    CHICAGO_TITLE_INTEGRATIONS,
    FCT_INTEGRATIONS,
    STEWART_NEXT_STEPS_INTEGRATIONS,
    TELUS_ASSYST_INTEGRATIONS,
    MANAGE_GLOBAL_CONTACTS,
    DEPOSIT_MANAGER,
    EXPORT_FILE_MANAGER,
    OPPORTUNITIES,
    TEMPLATE_AND_CATEGORY_MANAGEMENT,
    CONVEYANCING,
    FIELD_CODE_UI_MAPPING,
    TITLE_PLUS_INTEGRATIONS,
    MANAGE_DATA_MOVE_UTILITY,
    TCOL_INTEGRATIONS
}
//Todo think about how to deal ROLE_FULLY_AUTHENTICATED_USER which was added currtenly
export enum AUTH_ROLE {
    ROLE_SYSTEM_ADMINISTRATOR,
    ROLE_ADMINISTRATOR,
    ROLE_USER,
    ROLE_SYSTEM_USER,
}
export enum AUTH_PERMISSION {
    READ,
    CREATE,
    WRITE,
    DELETE,
    UPDATE
}
