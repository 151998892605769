import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class CosmolexExpenseCode extends  BaseEntity {

    id : number;
    expenseType : string;
    expenseCode : string;
    description:string;
    unitPrice : number;
    status : string;




}
