import {Component} from '@angular/core';

@Component({
  selector: 'no-content',
  templateUrl: 'not-found.html'
})

/*This component will be loaded when url is not matched with app  routes.
*/
export class NoContent {

}
