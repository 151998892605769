import {StatementAdjustment} from '../statement-adjustment';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';

export class StatementAdjustmentDisplayHstBuilder {

    static getDisplayItems(item: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        let provincialLabel: string = mainBuilder.matter.isMatterProvinceON ? 'Ontario' : 'Provincial';
        if (item.hstSalePrice.isHstSalePrice()) {

            if (!mainBuilder.isTaxRateGst && !mainBuilder.matter.isMatterProvinceNBorNS) {

                if(mainBuilder.matter && !mainBuilder.matter.isMatterProvinceAB) {
                    let federalHstRate: number = mainBuilder.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(mainBuilder.federalHstRate, mainBuilder.federalHstRate, mainBuilder.provinceHstRate);
                    sadItemDetails.push(mainBuilder.getNewItemLine(
                        `Federal Portion ${mainBuilder.addDecimalsToPercent(mainBuilder.federalHstRate)}% of ${mainBuilder.getCurrency(mainBuilder.netSalePriceExcludeAdditionalVendorConsid)}`,
                        mainBuilder.getCurrency(federalHstRate)
                    ));
                }

                if(mainBuilder.matter && !mainBuilder.matter.isMatterProvinceAB){
                    let provinceHstRate: number = mainBuilder.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(mainBuilder.provinceHstRate, mainBuilder.federalHstRate, mainBuilder.provinceHstRate);
                    sadItemDetails.push(mainBuilder.getNewItemLine(
                        `${provincialLabel} Portion ${mainBuilder.addDecimalsToPercent(mainBuilder.provinceHstRate)}% of ${mainBuilder.getCurrency(mainBuilder.netSalePriceExcludeAdditionalVendorConsid)}`,
                        mainBuilder.getCurrency(provinceHstRate)
                    ));
                }

            }
            else if(!mainBuilder.isTaxRateGst && mainBuilder.matter.isMatterProvinceNBorNS){
                let federalHstRate: number = mainBuilder.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(mainBuilder.federalHstRate, mainBuilder.federalHstRate, mainBuilder.provinceHstRate);
                let provinceHstRate: number = mainBuilder.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(mainBuilder.provinceHstRate, mainBuilder.federalHstRate, mainBuilder.provinceHstRate);
                let salePrice: number = mainBuilder.matter.getSalePriceBasedOnAdjustment(mainBuilder.federalHstRate, mainBuilder.provinceHstRate, item.hstSalePrice.isRateTypePST());
                //DPPMP-39252 show the same amount(salePrice) as the modal
                sadItemDetails.push(mainBuilder.getNewItemLine(`Calculated at: ${mainBuilder.addDecimalsToPercent(Number(mainBuilder.federalHstRate)+ Number(mainBuilder.provinceHstRate))}% of ${mainBuilder.getCurrency(salePrice)}`, ``));
            }
            else {
                let salePrice: number = mainBuilder.matter.getSalePriceBasedOnAdjustment(mainBuilder.federalHstRate, mainBuilder.provinceHstRate, item.hstSalePrice.isRateTypePST());
                let taxRate = item.hstSalePrice.isRateTypePST() ? mainBuilder.provinceHstRate : mainBuilder.federalHstRate;
                sadItemDetails.push(mainBuilder.getNewItemLine(`Calculated at: ${mainBuilder.addDecimalsToPercent(taxRate)}% of ${mainBuilder.getCurrency(salePrice)}`, ``));
            }
            if (mainBuilder.purchaserLabel === item.hstSalePrice.creditType) {
                if(!mainBuilder.matter.isMatterProvinceAB) {
                    sadItemDetails.push(mainBuilder.getNewItemLine(`Credit to ${item.hstSalePrice.creditType}`, ``));
                }
                sadItemDetails.push(mainBuilder.getNewItemLine(`pursuant to Agreement`, ``));
            }
        }
        else {
            let salePrice: number = mainBuilder.matter.getSalePriceBasedOnAdjustment(mainBuilder.federalHstRate, mainBuilder.provinceHstRate, item.hstSalePrice.isRateTypePST());
            if (mainBuilder.documentProfileCache && mainBuilder.documentProfileCache.cachedDocumentProfile && mainBuilder.documentProfileCache.cachedDocumentProfile.statementOfAdjustmentsProfile &&
                mainBuilder.documentProfileCache.cachedDocumentProfile.statementOfAdjustmentsProfile.showNetSalePriceInAdjustment && mainBuilder.documentProfileCache.cachedDocumentProfile.statementOfAdjustmentsProfile.showNetSalePriceInAdjustment.toUpperCase() === 'YES'){
                sadItemDetails.push(mainBuilder.getNewItemLine(`Based on Net Sale Price of ${mainBuilder.getCurrency(salePrice)}`, ``));
            }

            if(mainBuilder.matter && !mainBuilder.matter.isMatterProvinceABorMBorSK && !mainBuilder.matter.isMatterProvinceNBorNS) {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `Federal Portion`,
                    mainBuilder.getCurrency((mainBuilder.salePriceAdjustment ? mainBuilder.salePriceAdjustment.calculateFederalRebatePortion(mainBuilder.federalHstRate, salePrice) : 0))
                    , undefined, 'HST.Rebate.Fed'
                ));

                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `${provincialLabel} Portion`,
                    mainBuilder.getCurrency((mainBuilder.salePriceAdjustment ? mainBuilder.salePriceAdjustment.calculateOntarioRebatePortion(mainBuilder.provinceHstRate, salePrice) : 0))
                    , undefined, 'HST.Rebate.Prov'
                ));
            }
        }

        if (item.amount > 0) {
            sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${item.hstSalePrice.creditType}`, ``));
        }
        else if (item.amount === 0) {
            sadItemDetails.push(mainBuilder.getNewItemLine(`No Adjustment`, ``));
        }

        return sadItemDetails;
    }

}
