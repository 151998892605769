import {Directive, EventEmitter, Output} from '@angular/core';

@Directive({
    selector: '[ngModel][dp-uppercase]',
    host: {
        '(input)': 'onInputChange($event)',
        '(keyup)': 'onInputChange($event)',
        '(blur)': 'onInputChange($event)',
    }
})
export class UppercaseDirective {
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
    value: string;

    onInputChange($event): void {
        this.value = $event.target.value.toUpperCase();
        this.ngModelChange.emit(this.value);
    }
}
