import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Matter} from './matter';

export class TemplateCodeValues extends BaseEntity{
    matterId: number;
    matter: Matter;
    templateCodes: string[] = [];
    templateCodesValues: Map<string, string> = new Map();


    constructor(templateCodes? : TemplateCodeValues) {
        super(templateCodes);

        if(templateCodes && templateCodes.templateCodesValues){
            for(let prop in templateCodes.templateCodesValues) {
                if(templateCodes.templateCodesValues.hasOwnProperty(prop)) {
                    this.templateCodesValues.set(prop, templateCodes.templateCodesValues[prop])
                }
            }
        }
    }

    getValueByTemplateCode(key): string {
        return this.templateCodesValues.get(key);
    }

    hasCode(code): boolean {
        return this.templateCodesValues.has(code);
    }
}
