import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

declare var jQuery : any;
@Directive({
               selector : '[dp-read-only]',
               host     : {
                   '(keydown)'   : 'inputKeyDownEvent($event)',
                   '(mousedown)'   : 'selectBoxMousedownEvent($event)',
                   '(paste)'     : 'dropEvent($event)',
                   '(cut)'       : 'dropEvent($event)',
                   '(drop)'      : 'dropEvent($event)',
                   '(drag)'      : 'dropEvent($event)'
               }
           })
export class ReadOnlyDirective {
    element : Element;
    @Input('dp-read-only') dpReadOnly : boolean = true;

    constructor(private elementRef : ElementRef, private renderer : Renderer2) {
        this.element = elementRef.nativeElement;
    }

    inputKeyDownEvent(event) {
        if(this.dpReadOnly) {
            // console.log(event);
            // keys allowed - left/right arrow, tab, ctrl-a, ctrl-c
            if(event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 9 || (event.ctrlKey == true && event.keyCode == 65) || (event.ctrlKey == true && event.keyCode == 67)) {
                // allow keys to pass
            } else {
                event.preventDefault();
            }
        }
    }

    //selectBoxClickEvent never be called
    //when mousedown, it should call selectBoxMousedownEvent.
    selectBoxMousedownEvent(event) {
        if(this.element.tagName == 'SELECT' && this.dpReadOnly) {
            event.preventDefault()
        }
    }

    //DPPMP-17400
    dropEvent(event) {
        if(this.dpReadOnly) {
            event.preventDefault();
        }
    }

    ngAfterViewChecked() {
        if(this.dpReadOnly) {
            if(this.element) {
                this.renderer.addClass(this.element, 'dp-read-only-input');
            }
        } else {
            if(this.element) {
                this.renderer.removeClass(this.element, 'dp-read-only-input');
            }
        }
        //Make dp-read-only work with select and input[type="radio"]
        this.elementRef.nativeElement.disabled = this.dpReadOnly && !this.isPenButtonAvailable();
    }

    isPenButtonAvailable() : boolean{

        let parent = jQuery(this.element).parent();
        let targetInput = jQuery(parent).find("input");
        let penIcon = jQuery(parent).find('.glyphicon-edit');
        let penIconParentButton = jQuery(penIcon).parent();
        if(penIconParentButton && penIconParentButton.length > 0){
            jQuery(penIconParentButton).attr('tabindex', '0');
        }
        return ( targetInput && targetInput.length > 0 ) && ( penIcon && penIcon.length > 0 );
    }

}
