import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DPPMAccordionComponent} from './accordion.component';


@NgModule({
imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
],
declarations: [
    DPPMAccordionComponent
],
exports: [
    DPPMAccordionComponent
],
providers: [
]
})
export class DPPMAccordionModule { }
