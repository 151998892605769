import {Component, OnInit} from '@angular/core';
import {DialogRef} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {Contact} from '../../../matters/shared';
import {DriverLicenceInfo} from '../driver-licence.info';
import {IdentificationDocument} from '../../../matters/shared/identification-document';
import {SelectItem} from 'primeng/api';
import {contactDropDowns} from '../../../contact/contact-drop-downs';
import {IdentificationRecord} from '../../../matters/shared/identification-record';
import {cirfDropDowns} from '../../../matters/purchaser/cirf/cirf-import-data/cirf-drop-downs';
import Utils from '../../utils';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {StatusBarService} from '../../status-bar.service';

export class ImportDriverLicenceModalContext extends BSModalContext {
    contact: Contact;
    identificationRecord: IdentificationRecord;
    identificationDocument: IdentificationDocument;
    driverLicenceInfo: DriverLicenceInfo
}

@Component({
    selector: 'dp-import-driver-licence-modal-content',
    templateUrl: 'import-driver-licence-modal.component.html',
    providers : [ErrorService, StatusBarService]
})

export class ImportDriverLicenceModalComponent implements OnInit {
    context: ImportDriverLicenceModalContext;
    allFieldsSelected: boolean;
    localContact: Contact;
    localIdentificationDocument: IdentificationDocument;
    localIdentificationRecord: IdentificationRecord;
    genders: SelectItem[];
    countryOptions : SelectItem[]= cirfDropDowns.countryOptions;
    provinceOptions : SelectItem[]= cirfDropDowns.provinceOptions;

    constructor(public dialog: DialogRef<ImportDriverLicenceModalContext>,
                public errorService: ErrorService ) {
        this.context = dialog.context;
    }

    ngOnInit() {
        this.allFieldsSelected = false;
        this.genders = contactDropDowns.GENDER;
        this.localContact = new Contact(this.context.contact);
        this.localIdentificationDocument = new IdentificationDocument(this.context.identificationDocument);
        this.localIdentificationRecord = new IdentificationRecord(this.context.identificationRecord);
    }

    close() {
        this.dialog.close();
    }

    getBirthDate() : string {
        return this.localContact.isBlankMaleFemale ? this.localContact.birthDate : this.localIdentificationRecord.birthDate;
    }

    getFormatedDLDate(date: string) : string {
        return date.length==8 ? date.substring(0, 4)+'/'+date.substring(4, 6)+'/'+date.substring(6, 8) : '';

    }


    applyChanges() {
        this.copyLocalData();
        this.dialog.close({
            action: "OK"
        });
    }

    copyLocalData() {
        this.context.contact.gender = this.localContact.gender;
        this.context.contact.birthDate = this.localContact.birthDate;
        this.context.identificationRecord.birthDate = this.localIdentificationRecord.birthDate;
        this.context.identificationDocument.nameOnId = this.localIdentificationDocument.nameOnId;
        this.context.contact.primaryAddress.addressLine1 = this.localContact.primaryAddress.addressLine1;
        this.context.contact.primaryAddress.city = this.localContact.primaryAddress.city;
        this.context.contact.primaryAddress.postalCode = this.localContact.primaryAddress.postalCode;
        this.context.contact.primaryAddress.provinceCode = this.localContact.primaryAddress.provinceCode;
        this.context.contact.primaryAddress.provinceName = this.localContact.primaryAddress.provinceName;
        this.context.contact.primaryAddress.country = this.localContact.primaryAddress.country;
        this.context.identificationDocument.identificationNumber = this.localIdentificationDocument.identificationNumber;
        this.context.identificationDocument.placeOfIssue = this.localIdentificationDocument.placeOfIssue;
        this.context.identificationDocument.expiryDate = this.localIdentificationDocument.expiryDate;
        this.context.identificationDocument.country = this.localIdentificationDocument.country;
        if(this.isNameOnDriverLicenceDifferent()){
            this.context.identificationDocument.sameAsOfficerNameFlag = false;
        }

    }


    copyAllDriverLicenceInfo(event) {

        this.changeName(event);
        this.changeGender(event);
        this.changeBirthDate(event);
        this.changeAddress(event, 'addressLine1', true);
        this.changeAddress(event, 'city', true);
        this.changeAddress(event, 'postalCode');
        this.changeAddress(event, 'provinceCode');
        this.changeAddress(event, 'country');
        this.changeLicenceNumber(event);
        this.changePlaceOfIssue(event);
        this.changeExpiryDate(event);
        this.changeIdCountry(event);

    }

    changeName(event) {
        if(event.srcElement.checked) {
            this.localIdentificationDocument.nameOnId = Utils.toMixedCase(this.context.driverLicenceInfo.clientName);
        } else {
            this.localIdentificationDocument.nameOnId = this.context.identificationDocument.nameOnId;
        }

    }

    changeGender(event) {
        if(this.context.contact && this.context.contact.isBlankMaleFemale){
            if(event.srcElement.checked) {
                if(this.context.driverLicenceInfo.gender && (this.context.driverLicenceInfo.isMale() || this.context.driverLicenceInfo.isFemale())){
                    this.localContact.gender = this.context.driverLicenceInfo.gender;
                }
            } else {
                this.localContact.gender = this.context.contact.gender;
            }
        }
    }

    changeBirthDate(event) {
        if(event.srcElement.checked) {
            if(this.context.contact.isBlankMaleFemale) {
                this.localContact.birthDate = this.getFormatedDLDate(this.context.driverLicenceInfo.birthDate);
            } else {
                this.localIdentificationRecord.birthDate = this.getFormatedDLDate(this.context.driverLicenceInfo.birthDate);
            }
        } else {
            if(this.context.contact.isBlankMaleFemale) {
                this.localContact.birthDate = this.context.contact.birthDate;
            } else {
                this.localIdentificationRecord.birthDate = this.context.identificationRecord.birthDate;
            }
        }
    }

    onDateChangeBirthday(event) {
        if(this.context.contact.isBlankMaleFemale){
            this.localContact.birthDate = event.rawDate;
        } else {
            this.localIdentificationRecord.birthDate = event.rawDate
        }
    }

    isNameOnDriverLicenceDifferent() : boolean {
        return this.context.identificationDocument && this.context.driverLicenceInfo.clientName != this.context.identificationDocument.nameOnId;
    }

    changeAddress(event, addressField: string, isMixedCase?: boolean) {
        if(this.context.contact && this.context.contact.isBlankMaleFemale){
            if (event.srcElement.checked) {
                this.localContact.primaryAddress[addressField] = isMixedCase ? Utils.toMixedCase(this.context.driverLicenceInfo[addressField]) : this.context.driverLicenceInfo[addressField];
                if (addressField=='provinceCode') {
                    let provinceOption: SelectItem = this.provinceOptions.find(provinceOption => provinceOption.value == this.context.driverLicenceInfo.provinceCode);
                    if (provinceOption) {
                        this.localContact.primaryAddress.provinceName = provinceOption.label;
                    }
                }
            } else {
                this.localContact.primaryAddress[addressField] = this.context.contact.primaryAddress[addressField];
                if (addressField=='provinceCode') {
                    this.localContact.primaryAddress.provinceName = this.context.contact.primaryAddress.provinceName;
                }
            }
        }
    }

    changeProvinceName() {
        let provinceOption: SelectItem = this.provinceOptions.find(provinceOption => provinceOption.value == this.localContact.primaryAddress.provinceCode);
        if (provinceOption) {
            this.localContact.primaryAddress.provinceName = provinceOption.label;
        }
    }

    changeLicenceNumber(event) {
        if(event.srcElement.checked) {
            this.localIdentificationDocument.identificationNumber = this.context.driverLicenceInfo.licenceNumber;
        } else {
            this.localIdentificationDocument.identificationNumber = this.context.identificationDocument.identificationNumber;
        }
    }

    changePlaceOfIssue(event) {
        if(event.srcElement.checked) {
            this.localIdentificationDocument.placeOfIssue = this.context.driverLicenceInfo.placeOfIssue;
        } else {
            this.localIdentificationDocument.placeOfIssue = this.context.identificationDocument.placeOfIssue;
        }
    }

    changeExpiryDate(event) {
        if(event.srcElement.checked) {
            this.localIdentificationDocument.expiryDate = this.getFormatedDLDate(this.context.driverLicenceInfo.expiryDate);

        } else {
            this.localIdentificationDocument.expiryDate = this.context.identificationDocument.expiryDate;
        }
    }

    onChangeExpiryDate(event) {
        this.localIdentificationDocument.expiryDate = event.rawDate;
    }

    changeIdCountry(event) {
        if(event.srcElement.checked) {
            this.localIdentificationDocument.country = 'Canada';
        } else {
            this.localIdentificationDocument.country = this.context.identificationDocument.country;
        }
    }


}
