import {Directive, EventEmitter, Output} from '@angular/core';

@Directive({
    selector: '[ngModel][dp-keyupdropdown]',
    host: {
        '(keyup)': 'onInputChange($event)'
    }
})
export class KeyupDropDownDirective {

    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
    value: string;

    onInputChange($event): void{

        if ($event.keyCode === 120) {

            if($event.target.options.selectedIndex == ($event.target.options.length - 1))
            {
                $event.target.options.selectedIndex = 0;
            }
            else {
                $event.target.options.selectedIndex = $event.target.options.selectedIndex+1;
            }
       }

        this.value = $event.target.value;
        this.ngModelChange.emit(this.value);
    }
}
