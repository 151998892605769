import {Component, OnInit, ViewChild} from '@angular/core';
import {DocumentProductionData} from '../document-production-data';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {StopCodesCommonComponent} from './stop-codes-common.component';

class StopCodeModalContext extends BSModalContext {
    documentProductionData: DocumentProductionData;
}

@Component({
               selector: 'dp-stop-codes',
               templateUrl: 'stop-codes.component.html',
           })
export class StopCodesComponent implements OnInit, ModalComponent<StopCodeModalContext> {

    context : StopCodeModalContext;
    @ViewChild('dpStopCodesCommon') dpStopCodesCommon : StopCodesCommonComponent;

    constructor(public dialog : DialogRef<StopCodeModalContext>) {
        this.context = dialog.context;

    }

    ngOnInit() : void {
    }


    get documentProductionData(): DocumentProductionData {
        return this.context.documentProductionData;
    }


    onClickOk(): void {
        this.dpStopCodesCommon.copyValueFromWrapperToDocumentStopCode();
        this.dialog.close(this.documentProductionData);
    }

    onClickCancel(): void {
        this.dpStopCodesCommon.clearStopCodeValues();
        this.dialog.close(this.documentProductionData);
    }

}
