import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class PurchaserCommission extends BaseEntity {
    // TODO: Confirm server side if it needs id
    // id : number;

    commissionPayableToPurchaserBroker : number;
    purchaserBrokerCommission : number;
    purchaserBrokerPercentageOfCommission : number;
    purchaserHstOnCommission : number;
    purchaserTotalCommissionInclHst : number;

    constructor(purchaserCommission? : PurchaserCommission) {
        super(purchaserCommission);
    }
}
