import {Component} from '@angular/core';
import {TabsService} from '../../core/tabs.service';
import {UserConfigurationService} from '../../shared-main/user-configuration.service';

@Component({
    selector    : 'dp-change-font',
    templateUrl : 'change-font.component.html',
    styleUrls: [
        './change-font.styles.scss'
    ],

})

export class ChangeFontComponent {

    constructor(public tabsStateService: TabsService,
                public userConfigurationService: UserConfigurationService){
    }

    ngOnInit() : void {

    }

    increaseFont() : void {
        this.userConfigurationService.increasePageFont();
    }

    decreaseFont() : void {
        this.userConfigurationService.decreasePageFont();
    }

    resetFont() : void {
        this.userConfigurationService.resetPageFont();
    }

}
