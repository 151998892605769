export const messages = {
    "type": {
        "USER": "User",
        "ADMINISTRATOR": "Administrator",
        "SYSTEM_ADMINISTRATOR": "DP Administrator",
        "SYSTEM_USER": "DP User"
    },
    "status": {
        "ACTIVE"    : "Active",
        "INACTIVE"  : "Inactive",
        "SUSPENDED" : "Suspended",
        "TEST"      : "Test"
    },
    "isRecordActive": {
        "Y_n"    : "Y/n",
        "YES"       : "Yes",
        "NO"        : "No",
    },
    "BusinessRoleOptions_ON": {
        "SOLICITOR" : "Lawyer",
        "LAWCLERK"  : "Law Clerk",
        "OTHER"     : "Other",
    },
    "BusinessRoleOptions_BC": {
        "SOLICITOR" : "Lawyer/Notary",
        "LAWCLERK"  : "Conveyancer",
        "OTHER"     : "Other",
    },
    "BusinessRoleOptions_OTHER": {
        "SOLICITOR" : "Lawyer",
        "LAWCLERK"  : "Legal Assistant",
        "OTHER"     : "Other",
    },
    "dpBoolean": {
        "YES": "Yes",
        "NO": "No",
        "Y_n": "Y/n",
        "N_y": "N/y",
        "Y/n": "Y/n",
        "N/y": "N/y"
    }
};

