import {Injectable} from '@angular/core';
import {DocumentProfile} from '../admin/document-profile/document-profile';
import {Observable} from 'rxjs';
import {documentProfilesApi} from '../admin/document-profile/shared/document-profile-api';
import {documentProfileKey} from '../admin/document-profile/shared/document-profile-key';
import {HttpClient} from '../core/httpClient.service';
import {JurisdictionService} from '../matters/property-teranet/jurisdiction.service';
import {Jurisdiction} from '../matters/shared';

@Injectable()
export class DocumentProfileCache {

    private readonly responseKey: string = 'DOCUMENTPROFILE';

    constructor(private httpClient: HttpClient,
                private jurisdictionService : JurisdictionService
                ) { }


    private defaultDocumentProfile : DocumentProfile;
    private documentProfile : DocumentProfile;

    get cachedDocumentProfile() : DocumentProfile{
        return this.documentProfile;
    }

    get cachedDefaultDocumentProfile() : DocumentProfile{
        return this.defaultDocumentProfile;
    }

    cacheDocumentProfile( documentProfilePassed : DocumentProfile) : void {
          this.documentProfile = documentProfilePassed;
    }

    cacheDefaultDocumentProfile(accountId: string) : void {
        // this.defaultDocumentProfile = documentProfilePassed;
        this.getDefault(accountId).subscribe( defaultDocumentProfile => {
            this.defaultDocumentProfile = defaultDocumentProfile;
        });
    }

    cacheDefaultDocProfile(docProfile : DocumentProfile) : void {
        if(docProfile){
            this.defaultDocumentProfile = docProfile;
        }
    }

    getDefault(accountId: string): Observable<DocumentProfile> {

        return this.httpClient
                   .get(`${documentProfilesApi.documentProfiles(accountId)}?filter=defaultProfileFlag_EQ_TRUE`)
                   .map((response) => {
                       return response[documentProfileKey.documentProfiles];
                   })
                   .flatMap((profiles: DocumentProfile[]) =>
                                this.httpClient.get(`${documentProfilesApi.documentProfiles(accountId)}/${profiles[0].id}`))
                   .map((response) => {
                       return new DocumentProfile(response[this.responseKey]);
                   });
    }

    //This method returns the default jurisdiction based on the user's document profile
    getDocumentProfileJurisdiction(): Observable<Jurisdiction> {
        if(this.cachedDocumentProfile && this.cachedDocumentProfile.firmDocumentProfile
            && this.cachedDocumentProfile.firmDocumentProfile.jurisdictionName && this.cachedDocumentProfile.firmDocumentProfile.jurisdictionName.length > 0){
            let sort: string = "id|ASC";
            let query: string = "jurisdictionName_EQ_"+this.cachedDocumentProfile.firmDocumentProfile.jurisdictionName;
            return this.jurisdictionService.getAllJurisdictions(query,sort).map(
                (jurisdictions : Jurisdiction[]) => {
                    if(jurisdictions && jurisdictions.length > 0) {
                        return jurisdictions[0]; //If multiple jurisdictions found then defaulting to the first one
                    }
                });
        } else {
            return Observable.of(null)
        }

    }
}
