import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ContactName} from '../../matters/shared/contact-name';

export type ThirdPartyType = 'STEWART_ASSYST' | 'FCT' | 'TCOL';
export class ThirdPartyUser extends BaseEntity{
    id: number;
    userName : string;
    password : string;
    solicitorContactId: number;
    solicitorName: ContactName;
    instanceType : string;

    constructor(s? : ThirdPartyUser) {
        super(s);
        if(s) {
            if(s.solicitorName) {
                this.solicitorName = new ContactName(s.solicitorName)
            }
        }
    }
}
