import {User} from './user';
import {MatterParticipant} from './matter-participant';
import {MatterProperty} from './matter-property';
import {WillMatter} from './will-matter';
import {MatterContactInfo} from './matter-contact-info';
import {DpBooleanValue, DpBooleanValueTypes} from './dp-boolean';
import {ConsiderationLtt} from '../consideration-ltt/consideration-ltt';
import {Mortgage, MortgageeInterestNotedOnPolicy, MortgageSource, MortgageType} from './mortgage';
import {Contact} from './contact';
import {AddressTypes} from './address-types';
import {Address, SameAsAddressOption} from './address';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DirectDepositInstruction} from './direct-deposit-instruction';
import * as _ from 'lodash';
import {SoaMatter} from '../statement-account/soa-matter';
import {SoaTrustLedgeConfigKeys, SoaTrustLedgerCollection} from './soa-trustledger-collection';
import {ContactInfo} from './contact-info';
import {ContactInfoType} from './contact-info-type';
import {TrustLedgerMatter} from '../trust-ledger/trust-ledger-matter';
import {ProgressionStatus, StatementAdjustment, StatementAdjustmentOrder} from '../statement-adjustment/statement-adjustment';
import {dropDowns} from './matter-drop-downs';
import {RequisitionTemplate} from './requisition-template';
import {StatementConfig} from '../../admin/shared/statement-config';
import {UserDefinedField} from '../../shared-main/user-defined-field/user-defined-field';
import {
    ActingForValues,
    BillingStatus,
    CashOnClosingDateValues,
    Combined_Hydro_Water_Department,
    creationStages,
    DEFAULT_POSSESSION_TIME,
    DEPOSIT_PAID_ON_OCCUPANCY,
    DigitalSignaturePlatform,
    DirectionReFundTypes,
    InterimTaxMultiplier,
    MatterClosingStatus,
    MatterOverviewStatusTypesValue,
    MatterStatus,
    MatterTopicKey,
    MortgageAction,
    MortgageDispositionType,
    ReferredByTypeValues,
    SolicitorReportAddressDdString,
    SolicitorServiceAddressDdString,
    staticFieldCodes,
    Water_Department,
    WaterHydroDeptType_IS_COMBINED,
    YesNoTypes
} from '../../shared-main/constants';
import {MatterUndertaking, MatterUndertakingStatus, MatterUndertakingStatusValues} from './matter-undertaking';
import {MatterCalculatedAmount} from './matter-calculated-amount';
import {PurchaserReport} from '../purchaser-report/purchaser-report';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {
    DepositOnOccupancyCalculatedBasedOn,
    StatementAdjustmentAmountTypes,
    StatementAdjustmentConstants,
    StatementAdjustmentKey
} from '../statement-adjustment/statement-adjustment-constants';
import {MatterTax} from './property-taxes';
import {PurchaserCapacity} from '../purchaser/capacity/purchaser-capacity';
import {Declaration, DeclarationType} from '../executions-affidavits/declaration';
import {ConsiderationTaxes} from '../consideration-ltt/consideration-taxes';
import {Compliance} from '../compliance/compliance';
import {MatterSupplementalTaskCategory} from './matter-supplemental-task-category';
import {StatementAdjustmentConfig} from '../../admin/statement-adjustment/statement-adjustment-config';
import {StatementOfAdjustmentHeading} from '../statement-adjustment/model/statement-adjustment-heading';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {HstSalePrice} from '../statement-adjustment/model/hst-sale-price-soa';
import {MatterParticipantRole, MatterParticipantRoleTypes} from './matter-participant-role-types';
import {TeranetDocket} from '../../shared-main/teranet/teranet-docket';
import {SoaCommonExpense} from '../statement-adjustment/model/common-expense-soa';
import {ParcelRegister} from '../../shared-main/teranet/parcel-register';
import {messages} from '../../common/messages';
import {RequisitionResponse} from './requisition-response';
import {constValues} from './const-values';
import {ERegistrationForm} from '../forms/eregistration/eregistrationform';
import {ERegistrationJson} from '../forms/eregistration/eregistrationjson';
import {TeraviewConfig} from '../../admin/docket/teraview-config';
import {MatterBilling, TransactionType} from './matter-billing';
import {StatementOfAdjustmentPayable} from '../statement-adjustment/model/statement-adjustment-payable';
import {Jurisdiction} from './jurisdiction';
import {JurisdictionDepartment} from '../../admin/jurisdiction-departments/jurisdiction-departments';
import {Department} from './department';
import Utils from '../../shared-main/utils';
import {BrokerCommission} from '../broker-commission/broker-commission';
import {StatementOfAdjustmentDisplay} from './statement-of-adjustment-display';
import {Writ} from '../../shared-main/teranet/property-writ';
import {StatementAdjustmentUtil} from '../statement-adjustment/statement-adjustment-util';
import {MatterTitleInsurance} from './matter-title-insurance';
import {DirectionReFund} from '../direction-re-funds/direction-re-funds';
import {UnitLevelPlanUtil} from '../property-teranet/unit-level-plan/unit-level-plan-util';
import {
    BorrowerMapping,
    RemovedReconciledBorrowerRelatedData,
    StewartMortgageInstruction
} from '../../shared-main/telus/stewart-mortgage-instruction';
import {AddressDropdowns} from '../../shared-main/address-Form/drop-downs';
import {dropDowns as dropDownOption, mortgageeTypeOptions} from '../mortgages/mortgage/dropdown-options';
import {NotesList} from '../../admin/account-notes/account-notes';
import {MatterTopic} from './matter-topic';
import {AddressUtil} from './address-util';
import {PROVINCE_CODES} from './user-province';
import {CondoCorporationDocumentation} from '../condo-corporation/condo-corporation-documentation';
import {TitleInsuranceConfiguration} from '../../integrations/title-insurance-configuration';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {provinceBasedTaxTypes, Tax_RATE} from '../../shared-main/province-based-dropdowns';
import {ClosingDatePayment} from './closing-date-payment';
import {TippInstallmentConstants} from './property-taxes-tipp-installment';
import {SoAdjTenancyPrepaid} from '../statement-adjustment/model/so-adj-tenancy-prepaid';
import {PermittedRegistration} from './permitted-registration';
import {SalePriceAdjustmentHeading} from '../consideration-ltt/sale-price-adjustment-heading';
import {SoAdjTenancyPrepaidUtil} from '../statement-adjustment/model/so-adj-tenancy-prepaid-util';
import {InterestDateCalendarYearItem} from '../../shared-main/interest-date-calendar-year-item';
import {StatementAdjustmentDisplayBuilder} from '../statement-adjustment/builders/statement-adjustment-display-builder';
import {AltoEForm} from '../../shared-main/alto/alto-eform';
import {EFormType} from '../../shared-main/alto/alto-eform-request';
import {AltoEFormStaleFlag} from '../../shared-main/alto/alto-eform-stale-flag';
import {MatterLink} from '../matter-opening/matter-link';
import {MatterPricing} from '../../billing/matter-pricing';
import {ParcelLegalDescription} from './parcel-legal-description';
import {SoaFeeRate} from '../consideration-ltt/soa-fee-rate';
import {SelectItem} from 'primeng/api';
import {Instrument} from '../../shared-main/teranet/instrument';
import {RollNumberMatterTax} from '../../matters/shared/roll-number-matter-tax';
import {Project} from '../../projects/shared/project';
import {MatterService} from '../matter.service';
import {SalePriceAdjustment} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment';
import {SalePriceOptionValue} from '../property-teranet/deposit/deposit-modal-constants';
import {Deposit} from './deposit';
import {OccupancyFeesCalculatedBasedOn} from '../../projects/project-condo/project-condo';
import {MatterExtraDepositConfig} from './matter-extra-deposit-config';
import {BaseTaxAdjustmentOn, ProjectTaxAdjustmentConfig} from '../../projects/project-adjustments/project-tax-adjustment-config';
import {UUIDUtil} from '../../main/uuid-util';
import {DepositModalContextValue} from '../property-teranet/deposit/deposit.modal.component';
import {SoaRealtyTaxAdjustmentUtil} from '../statement-adjustment/modals/realty-tax/soa-realty-tax-adjustment-util';
import {TaxesPaidDuringOccupancyUtil} from '../statement-adjustment/modals/taxes-paid-during-occupancy/taxes-paid-during-occupancy-util';
import {CondominiumExpense} from '../property-teranet/unit-level-plan/condominium-expense';
import {ProjectAdjustmentConfig} from '../../projects/project-adjustments/project-adjustment-config';
import {MortgageSoAdjService} from '../../shared-main/mortgage-so-adj.service';
import moment from 'moment';
import {InterestRate} from '../statement-adjustment/model/interest-rate';
import {
    AdvanceMatterHoldbackConfig,
    HOLDBACK_STATUS_TYPE,
    HOLDBACKSTATUS,
    MatterHoldback,
    MATTERHOLDBACKTYPE
} from './advance-holdback/matter-holdback';
import {RentInterestRate} from '../statement-adjustment/model/rent-interest-rate';
import {MatterParticipantWrapper} from './matter-participant-wrapper';
import {BurgerMenuExtendedItem} from './burger-menu-extended-item';
import {Subject} from 'rxjs/Subject';
import {MatterForm, TypeOfMatterFormValue} from './matterForm';
import {BillingIssue} from './billing-issue';
import {MatterTaxRate, taxRateTypeValues} from './matter-tax-rate';
import {ContactInfoInstanceTypes} from './contact-info-instance-types';
import {projectConsts} from '../../projects/shared/project-consts';
import {MatterSections, MatterSectionsTitles, Section} from './section';
import {ApplicableProvisionOptionsTypes, FamilyLawAct} from './fla-data';
import {UndertakingsConfigService} from '../../admin/shared/undertaking-config.service';
import {
    NewHomePstRebateForm,
    RebateAssignmentTypes
} from '../forms/eregistration/modal/pst-rebate-form-new-home-modal/new-home-pst-rebate-form';
import {UndertakingsConfig} from '../../admin/mortgage-discharge/undertakings-config';
import {TitleInsurancePolicy} from '../title-insurance/title-insurance-policy';
import {SoAdjInterimFee} from '../statement-adjustment/model/so-adj-interim-fee';
import {EventData} from '../../event/event-data';
import {MatterCleanUpUtil} from './matter-utils/matter-clean-up-util';
import {MatterErrorUtil} from './matter-utils/matter-error-util';
import {MatterEFormUtil} from './matter-utils/matter-eform-util';
import {MatterTitleDetailsUtil} from './matter-utils/matter-title-details-util';
import {MatterStatementAdjustmentUtil} from './matter-utils/matter-statement-adjustment-util';
import {MatterMortgageUtil} from './matter-utils/matter-mortgage-util';
import {MatterCommissionUtil} from './matter-utils/matter-commission-util';
import {MatterOverviewUtil} from './matter-utils/matter-overview-util';
import {MatterPropertyUtil} from './matter-utils/matter-property-util';
import {
    CashOnClosingDateType,
    CommissionPaidToType,
    CommissionDisbursedToType,
    CommissionDisbursedByType,
    BrokerReleaseExcessToType,
    MatterType,
    MatterTypesValue,
    OpportunityMatterSourceValue,
    OpportunityMatterStatusValue,
    OverrideMatterStatusType,
    ProtocolClosingType,
    PurchaserFinancingType,
    TrustLedgerMatterMortgageObjectTemplate
} from './matter-specific-type';
import {MatterFireInsuranceUtil} from './matter-utils/matter-fire-insurance-util';
import {MatterTrustLedgerUtil} from './matter-utils/matter-trust-ledger-util';
import {MatterParticipantUtil} from './matter-utils/matter-participant-util';
import {BrokerContactTypes, BrokerMatterLevelInfo} from './broker-matter-level-info';
import {MatterBrokerMatterLevelInfoUtil} from './matter-utils/matter-broker-matter-level-info-util';
import {MatterWorkItem, MatterWorkItemTask} from './matter-work-item';
import {MatterSoaSheet} from '../../../app/matters/shared/matter-soa-sheet';
import {ReferredByInfo, ReferredByType} from './referred-by-info';
import {MatterNotification} from './matter-notification';
import {AppConfig} from '../../shared-main/app-configuration';
import {OpportunityInfo} from './opportunityInfo';
import {MatterNotificationConfig} from '../../admin/manage-messaging-notifications/matter-notification-config/matter-notification-config';
import {DuplicateContactData} from '../../opportunity-matter/duplicate-prospect/duplicate-contact-data';
import {MatterUtil} from './matter-util';
import {SoaExpenseAdjustmentUtil} from '../statement-adjustment/modals/reserve-fund/soa-expense-adjustment-util';
import {DateCalculationConfig, DateTypes} from '../../admin/accounts/shared/date-calculation-config';
import {PropertyTaxesUtil} from "../property-teranet/property-taxes/property-taxes-util";
import {provinceBasedFieldLabels} from "../../shared-main/province-based-field-labels";

export class Matter {

    constructor(matter?: Matter) {

        if (matter) {
            for (let prop in matter) {
                if (matter.hasOwnProperty(prop)) {
                    this[prop] = matter[prop];
                }
            }
            if (matter.matterTaxRates) {
                this.matterTaxRates = [];
                matter.matterTaxRates.forEach((item) => {
                    this.matterTaxRates.push(new MatterTaxRate(item));
                })
            }

            this.matterContactInfo = new MatterContactInfo(matter.matterContactInfo);

            if (matter.project) {
                this.project = new Project(matter.project);
            }
            if (matter.teranetDocket) {
                this.teranetDocket = new TeranetDocket(matter.teranetDocket);
            }

            if (matter.billingIssues) {
                this.billingIssues = new BillingIssue(matter.billingIssues);
            }


            this.contactInfo = [];
            if (Array.isArray(matter.contactInfo)) {
                for (let mc of matter.contactInfo) {
                    this.contactInfo.push(new ContactInfo(mc));
                }
            }

            this.brokersMatterLevelInfo = [];
            if (Array.isArray(matter.brokersMatterLevelInfo)) {
                for (let mc of matter.brokersMatterLevelInfo) {
                    this.brokersMatterLevelInfo.push(new BrokerMatterLevelInfo(mc));
                }
            }

            if (matter.considerationLtt) {
                this.considerationLtt = new ConsiderationLtt(matter.adjustmentStatusMode, matter.considerationLtt, matter.provinceCode);
            }


            if (matter.interimStatementOfAdjustmentsOrder) {
                this.interimStatementOfAdjustmentsOrder = matter.interimStatementOfAdjustmentsOrder;
                this.interimStatementAdjustmentOrders = JSON.parse(matter.interimStatementOfAdjustmentsOrder).map((item) => {
                    return new StatementAdjustmentOrder(item);
                });
                this.interimStatementAdjustmentOrders = this.interimStatementAdjustmentOrders.sort((a, b) => Number(a.adjustmentIndex) < Number(b.adjustmentIndex) ? -1 : Number(a.adjustmentIndex) > Number(b.adjustmentIndex) ? 1 : 0);
            }

            if (matter.finalStatementOfAdjustmentsOrder) {
                this.finalStatementOfAdjustmentsOrder = matter.finalStatementOfAdjustmentsOrder;
                this.finalStatementAdjustmentOrders = JSON.parse(matter.finalStatementOfAdjustmentsOrder).map((item) => {
                    return new StatementAdjustmentOrder(item);
                });
                this.finalStatementAdjustmentOrders = this.finalStatementAdjustmentOrders.sort((a, b) => Number(a.adjustmentIndex) < Number(b.adjustmentIndex) ? -1 : Number(a.adjustmentIndex) > Number(b.adjustmentIndex) ? 1 : 0);
            }


            this.matterParticipants = [];

            if (matter.matterParticipants) {
                for (let mp of matter.matterParticipants) {
                    let newMp  = new MatterParticipant(mp);
                    let fla: FamilyLawAct = newMp.getConsentedSpouseFamilyLawAct();
                    if(fla){
                        fla.consentedSpouseParticipant = null;
                    }
                    this.matterParticipants.push(newMp);
                }
                this.matterParticipants.forEach((participant: MatterParticipant) =>{
                    if(participant.matterParticipantRole == MatterParticipantRoleTypes.CONSENTING_SPOUSE){
                        let parentParticipant : MatterParticipant = this.matterParticipants.find(mp=>mp.matterParticipantId == participant.parentParticipantId);
                        let fla: FamilyLawAct = parentParticipant && parentParticipant.getConsentedSpouseFamilyLawAct();
                        if(fla){
                            fla.consentedSpouseParticipant = participant;
                        }
                    }
                });
            }

            this.matterProperties = [];
            if (Array.isArray(matter.matterProperties)) {
                for (let i: number = 0; i < matter.matterProperties.length; ++i) {
                    this.matterProperties[i] = new MatterProperty(matter.matterProperties[i]);
                }
            }

            if (this.matterProperties.length === 0) {
                this.matterProperties.push(MatterProperty.createDefaultMatterProperty());
            }

            this.mortgages = [];
            this.existingMortgages = [];
            if (Array.isArray(matter.mortgages)) {
                let newMortgages = matter.mortgages.filter(item => ((new Mortgage(item)).isUnityNewMortgage() || (new Mortgage(item)).isEmpMortgage()));
                if (newMortgages.length) {
                    for (let i: number = 0; i < newMortgages.length; ++i) {
                        this.mortgages[i] = new Mortgage(newMortgages[i]);
                    }
                }
                let existingMortgages = matter.mortgages.filter(item => (new Mortgage(item)).isExistingMortgage());
                if (existingMortgages.length) {

                    for (let i: number = 0; i < existingMortgages.length; ++i) {
                        this.existingMortgages[i] = new Mortgage(existingMortgages[i]);
                    }
                }
                else {
                    if (matter.existingMortgages && matter.existingMortgages.length) {
                        for (let j: number = 0; j < matter.existingMortgages.length; ++j) {
                            this.existingMortgages[j] = new Mortgage(matter.existingMortgages[j]);
                        }
                    }
                }
            }

            //TODO: cover the rest of the aggregated entities
            if(this.considerationLtt && this.isMatterProvinceON && this.isProjectSale){
                this.considerationLtt.isMatterParticipantSpouseSelected = null;
            } else {
                if (this.matterParticipantSpouse().length >= 2 && this.considerationLtt) {
                    this.considerationLtt.isMatterParticipantSpouseSelected = true;
                } else if (this.considerationLtt) {
                    this.considerationLtt.isMatterParticipantSpouseSelected = false;
                }
            }

            this.directDepositInstructions = [];
            if (Array.isArray(matter.directDepositInstructions)) {
                for (let i: number = 0; i < matter.directDepositInstructions.length; ++i) {
                    this.directDepositInstructions[i] = new DirectDepositInstruction(matter.directDepositInstructions[i]);
                }
            }

            this.secondarySoaSheets = [];
            if (Array.isArray(matter.secondarySoaSheets)) {
                for (let i: number = 0; i < matter.secondarySoaSheets.length; ++i) {
                    this.secondarySoaSheets [i] = new MatterSoaSheet(matter.secondarySoaSheets[i]);
                }
            }

            if(Array.isArray(matter.secondarySoaSheets) && !!matter.dirty){
                this.secondarySoaSheets.forEach( sheet =>{
                    let collection = this.secondarySoaSheetsCollection.find(item => item.sheetId  == sheet.id);
                                  if(collection && (Array.isArray(collection.allFees)
                            || Array.isArray(collection.allPurchaseDisbursementsAdditional)
                            || Array.isArray(collection.allPurchaseDisbursementsHST)
                            || Array.isArray(collection.allPurchaseDisbursementsNotHST)
                            || Array.isArray(collection.allPurchaseTaxableOtherChargesGST)
                            || Array.isArray(collection.allPurchaseTaxableOtherChargesPST)
                            || Array.isArray(collection.allPurchaseTaxableOtherChargesGSTandPST)
                            || Array.isArray(collection.allPurchaseNonTaxableOtherCharges)
                            || Array.isArray(collection.allPurchaseDisbursementsPST)
                            || Array.isArray(collection.allPurchaseDisbursementsGSTandPST))) {
                        // In Case of Refresh Re creating matterSoas List from SoaTrustLedgerCollection
                        let soaTrustLedgerCollections = (collection.allFees
                            .concat(collection.allPurchaseDisbursementsAdditional)
                            .concat(collection.allPurchaseDisbursementsHST))
                            .concat(collection.allPurchaseDisbursementsNotHST)
                            .concat(collection.allPurchaseTaxableOtherChargesGST)
                            .concat(collection.allPurchaseTaxableOtherChargesPST)
                            .concat(collection.allPurchaseTaxableOtherChargesGSTandPST)
                            .concat(collection.allPurchaseNonTaxableOtherCharges)
                            .concat(collection.allPurchaseDisbursementsPST)
                            .concat(collection.allPurchaseDisbursementsGSTandPST);
                        sheet.soaItems = [];
                        for (let i: number = 0; i < soaTrustLedgerCollections.length; ++i) {
                            sheet.soaItems[i] = new SoaMatter(soaTrustLedgerCollections[i]);
                        }

                    }
                })
            }

            this.matterSoas = [];
            // SOA items are added into soaTrustLedgerCollection instead of matterSoas. After saving, it updates the matterSoas.
            // Avoid to lose the SOA items of soaTrustLedgerCollection when switch tab, it should build SOA from soaTrustLedgerCollection when matter is dirty
            if (Array.isArray(matter.matterSoas)&& !matter.dirty) {
                for (let i: number = 0; i < matter.matterSoas.length; ++i) {
                    this.matterSoas[i] = new SoaMatter(matter.matterSoas[i]);
                }

            }
            else if (this.soaTrustLedgerCollection && (Array.isArray(this.soaTrustLedgerCollection.allFees)
                || Array.isArray(this.soaTrustLedgerCollection.allPurchaseDisbursementsAdditional)
                || Array.isArray(this.soaTrustLedgerCollection.allPurchaseDisbursementsHST)
                || Array.isArray(this.soaTrustLedgerCollection.allPurchaseDisbursementsNotHST)
                || Array.isArray(this.soaTrustLedgerCollection.allPurchaseTaxableOtherChargesGST)
                || Array.isArray(this.soaTrustLedgerCollection.allPurchaseTaxableOtherChargesPST)
                || Array.isArray(this.soaTrustLedgerCollection.allPurchaseTaxableOtherChargesGSTandPST)
                || Array.isArray(this.soaTrustLedgerCollection.nonTaxableOtherCharges)
                || Array.isArray(this.soaTrustLedgerCollection.allPurchaseDisbursementsPST)
                || Array.isArray(this.soaTrustLedgerCollection.allPurchaseDisbursementsGSTandPST))) {
                // In Case of Refresh Re creating matterSoas List from SoaTrustLedgerCollection
                let soaTrustLedgerCollections = (this.soaTrustLedgerCollection.allFees
                    .concat(this.soaTrustLedgerCollection.allPurchaseDisbursementsAdditional)
                    .concat(this.soaTrustLedgerCollection.allPurchaseDisbursementsHST))
                    .concat(this.soaTrustLedgerCollection.allPurchaseDisbursementsNotHST)
                    .concat(this.soaTrustLedgerCollection.allPurchaseTaxableOtherChargesGST)
                    .concat(this.soaTrustLedgerCollection.allPurchaseTaxableOtherChargesPST)
                    .concat(this.soaTrustLedgerCollection.allPurchaseTaxableOtherChargesGSTandPST)
                    .concat(this.soaTrustLedgerCollection.nonTaxableOtherCharges)
                    .concat(this.soaTrustLedgerCollection.allPurchaseDisbursementsPST)
                    .concat(this.soaTrustLedgerCollection.allPurchaseDisbursementsGSTandPST);
                for (let i: number = 0; i < soaTrustLedgerCollections.length; ++i) {
                    this.matterSoas[i] = new SoaMatter(soaTrustLedgerCollections[i]);
                }
            }

            this.matterTrustLedgers = [];
            // TrustLedger items are added into soaTrustLedgerCollection instead of matterTrustLedgers. After saving, it updates the matterTrustLedgers.
            // Avoid to lose the TrustLedger items of soaTrustLedgerCollection when switch tab, it should build TrustLedger from soaTrustLedgerCollection when matter is dirty
            if (Array.isArray(matter.matterTrustLedgers)&& !matter.dirty) {
                for (let i: number = 0; i < matter.matterTrustLedgers.length; ++i) {
                    this.matterTrustLedgers[i] = new TrustLedgerMatter(matter.matterTrustLedgers[i]);
                }

            }
            else if (this.soaTrustLedgerCollection && Array.isArray(this.soaTrustLedgerCollection.allMatterTrustLedgers)) {
                // In Case of Refresh Re creating matterTrustLedgers List from SoaTrustLedgerCollection
                for (let i: number = 0; i < this.soaTrustLedgerCollection.allMatterTrustLedgers.length; ++i) {
                    this.matterTrustLedgers[i] = new TrustLedgerMatter(this.soaTrustLedgerCollection.allMatterTrustLedgers[i]);
                }
            }

            if (this.matterSoas.length > 0 || this.matterTrustLedgers.length > 0) {
                let statementConfig;
                if (this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.statementConfig) {
                    statementConfig = new StatementConfig(this.soaTrustLedgerCollection.statementConfig);
                }
                this.soaTrustLedgerCollection = new SoaTrustLedgerCollection(this, this.matterSoas, this.matterTrustLedgers);

                // In Case of Refresh Re creating statementConfig from SoaTrustLedgerCollection
                if (statementConfig) {
                    this.soaTrustLedgerCollection.statementConfig = statementConfig;
                }
            }


            if (this.secondarySoaSheets && this.secondarySoaSheets.length > 0) {
                this.secondarySoaSheetsCollection = [];
                this.secondarySoaSheets.forEach( sheet =>{
                    let soaTrustLedgerCollection = new SoaTrustLedgerCollection(this, sheet.soaItems, this.matterTrustLedgers);
                    soaTrustLedgerCollection.sheetId = sheet.id;
                    this.secondarySoaSheetsCollection.push(soaTrustLedgerCollection);
                })
            }

            this.matterRequisitions = [];
            if (Array.isArray(matter.matterRequisitions)) {
                for (let i: number = 0; i < matter.matterRequisitions.length; ++i) {
                    this.matterRequisitions[i] = new RequisitionTemplate(matter.matterRequisitions[i]);
                }
            }

            this.matterUndertakings = [];
            if (Array.isArray(matter.matterUndertakings)) {
                for (let i: number = 0; i < matter.matterUndertakings.length; ++i) {
                    this.matterUndertakings[i] = new MatterUndertaking(matter.matterUndertakings[i]);
                }
            }

            this.matterUserDefinedFields = [];
            if (Array.isArray(matter.matterUserDefinedFields)) {
                for (let i: number = 0; i < matter.matterUserDefinedFields.length; ++i) {
                    this.matterUserDefinedFields[i] = new UserDefinedField(matter.matterUserDefinedFields[i]);
                }
            }

            if (matter.reportToPurchaser) {
                this.reportToPurchaser = new PurchaserReport(matter.reportToPurchaser);
            }


            this.adjustments = [];
            this._finalAdjustments = [];
            if (Array.isArray(matter._finalAdjustments)) {
                for (let i: number = 0; i < matter._finalAdjustments.length; ++i) {
                    this._finalAdjustments[i] = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode, matter._finalAdjustments[i]);
                }
            }

            this._interimAdjustments = [];
            if (Array.isArray(matter._interimAdjustments)) {
                for (let i: number = 0; i < matter._interimAdjustments.length; ++i) {
                    this._interimAdjustments[i] = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode, matter._interimAdjustments[i]);
                }
            }
            // In Case matter object is json object from backend then your _finalAdjustments && _interimAdjustments will undefined
            // then you need to fill those list with adjustments from backend
            if (!matter._finalAdjustments && !matter._interimAdjustments && Array.isArray(matter.adjustments)) {
                this._finalAdjustments.push(...matter.adjustments.filter(item => item.adjustmentStatus != ProgressionStatus.INTERIM).map(adjustment => {
                    return new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode, adjustment)
                }));
                this._interimAdjustments.push(...matter.adjustments.filter(item => item.adjustmentStatus == ProgressionStatus.INTERIM).map(adjustment => {
                    return new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode, adjustment)
                }));
            }


            this.declarations = [];
            if (Array.isArray(matter.declarations)) {
                for (let i: number = 0; i < matter.declarations.length; ++i) {
                    this.declarations[i] = new Declaration(matter.declarations[i]);
                }
            }

            this.matterCompliances = [];
            if (Array.isArray(matter.matterCompliances)) {
                for (let i: number = 0; i < matter.matterCompliances.length; ++i) {
                    this.matterCompliances[i] = new Compliance(matter.matterCompliances[i]);
                }
            }

            this.supplementalTasks = [];
            if (Array.isArray(matter.supplementalTasks)) {
                for (let i: number = 0; i < matter.supplementalTasks.length; ++i) {
                    this.supplementalTasks[i] = new MatterSupplementalTaskCategory(matter.supplementalTasks[i]);
                }
            }

            this.matterStatementOfAdjustmentFooters = [];
            if (Array.isArray(matter.matterStatementOfAdjustmentFooters)) {
                for (let i: number = 0; i < matter.matterStatementOfAdjustmentFooters.length; ++i) {
                    this.matterStatementOfAdjustmentFooters[i] = new StatementAdjustmentConfig(matter.matterStatementOfAdjustmentFooters[i]);
                }
            }

            if (matter.requisitionResponse) {
                this.requisitionResponse = new RequisitionResponse(matter.requisitionResponse);
            }


            this.eRegistrationForms = [];
            if (Array.isArray(matter.eRegistrationForms)) {
                for (let i: number = 0; i < matter.eRegistrationForms.length; ++i) {
                    this.eRegistrationForms[i] = new ERegistrationForm(matter.eRegistrationForms[i]);
                }

                this.eRegistrationForms.forEach(item => {
                    if (item.eregistrationJson) {
                        item.eregistrationData = new ERegistrationJson(JSON.parse(item.eregistrationJson));
                    } else {
                        item.eregistrationData = new ERegistrationJson();
                    }


                })
            }

            this.directionReFunds = [];
            if (Array.isArray(matter.directionReFunds)) {
                for (let i: number = 0; i < matter.directionReFunds.length; ++i) {
                    this.directionReFunds[i] = new DirectionReFund(matter.directionReFunds[i]);
                }
            }

            this._interimDirectionReFunds = [];
            if (Array.isArray(matter._interimDirectionReFunds)) {
                this._interimDirectionReFunds.push(...matter._interimDirectionReFunds
                    .map(item => {
                        return new DirectionReFund(item)
                    }));
            }
            this._finalDirectionReFunds = [];
            if (Array.isArray(matter._finalDirectionReFunds)) {
                this._finalDirectionReFunds.push(...matter._finalDirectionReFunds
                    .map(item => {
                        return new DirectionReFund(item)
                    }));
            }
            if (!matter._finalDirectionReFunds && !matter._interimDirectionReFunds && Array.isArray(matter.directionReFunds)) {
                this._finalDirectionReFunds.push(...matter.directionReFunds
                    .filter(item => item.progressionStatus == ProgressionStatus.FINAL)
                    .map(item => {
                        return new DirectionReFund(item)
                    }));

                this._interimDirectionReFunds.push(...matter.directionReFunds
                    .filter(item => item.progressionStatus == ProgressionStatus.INTERIM)
                    .map(item => {
                        return new DirectionReFund(item)
                    }));
            }

            if (matter.teraviewConfig) {
                this.teraviewConfig = new TeraviewConfig(matter.teraviewConfig);
            }

            if (matter.billingTransactions) {
                for (let i = 0; i < matter.billingTransactions.length; i++) {
                    this.billingTransactions[i] = new MatterBilling(matter.billingTransactions[i]);
                }
            }

            if (matter.statementOfAdjustmentDisplay) {
                this.statementOfAdjustmentDisplay = new StatementOfAdjustmentDisplay(matter.statementOfAdjustmentDisplay);
            }

            if (matter.matterTitleInsurance) {
                this.matterTitleInsurance = new MatterTitleInsurance(matter.matterTitleInsurance);
                this.titleInsuranceBuildMortgagePolicies();
            } else {
                this.matterTitleInsurance = new MatterTitleInsurance();
            }


            if (matter.notesList) {
                this.notesList = new NotesList(matter.notesList);
            }
            this.matterTopics = [];
            if (Array.isArray(matter.matterTopics)) {
                for (let i: number = 0; i < matter.matterTopics.length; ++i) {
                    this.matterTopics[i] = new MatterTopic(matter.matterTopics[i]);
                }

            }

            if (!matter.matterTaxType) {
                this.matterTaxType = 'HST';
            }

            if (matter.condoCorporationDocumentation) {
                this.condoCorporationDocumentation = new CondoCorporationDocumentation(matter.condoCorporationDocumentation);
            }

            // if (!matter.paysForDateOfClosing && (this.isMatterProvinceAB || this.isMatterProvinceNBorNS)) {
            //     this.paysForDateOfClosing = 'VENDOR';
            // } else if (!matter.paysForDateOfClosing && !this.isMatterProvinceAB) {
            //     this.paysForDateOfClosing = 'PURCHASER';
            // }


            if (matter.closingDatePayment) {
                this.closingDatePayment = new ClosingDatePayment(matter.closingDatePayment);
            } else if (this.isMatterProvinceAB && !matter.closingDatePayment) {
                this.closingDatePayment = new ClosingDatePayment();
                if (matter.isSale) {
                    this.closingDatePayment.delayedAmountSameAsTabG = false;
                }
            }

            this.permittedRegistrations = [];
            if (matter.permittedRegistrations) {
                for (let i = 0; i < matter.permittedRegistrations.length; i++) {
                    this.permittedRegistrations[i] = new PermittedRegistration(matter.permittedRegistrations[i]);
                }
            }

            this.altoEForms = [];
            if (Array.isArray(matter.altoEForms)) {
                matter.altoEForms.forEach((form: AltoEForm) => this.altoEForms.push(new AltoEForm(form)));
            }

            this.altoEFormStaleFlags = [];
            if (Array.isArray(matter.altoEFormStaleFlags)) {
                matter.altoEFormStaleFlags.forEach((form: AltoEFormStaleFlag) => this.altoEFormStaleFlags.push(new AltoEFormStaleFlag(form)));
            }


            if (matter.advanceMatterHoldbackConfig) {
                this.advanceMatterHoldbackConfig = new AdvanceMatterHoldbackConfig(matter.advanceMatterHoldbackConfig);
            }

            this.holdbacks = [];
            if (Array.isArray(matter.holdbacks)) {
                matter.holdbacks.forEach((holdback: MatterHoldback) => this.holdbacks.push(new MatterHoldback(holdback)));
            }


            if (matter.matterLink) {
                this.matterLink = new MatterLink(matter.matterLink)
            }

            if (matter.matterPricing) {
                this.matterPricing = new MatterPricing(matter.matterPricing);
            }

            if (matter.brokerCommission) {
                this.brokerCommission = new BrokerCommission(matter.brokerCommission);
            }

            // need to always init some values inside
            this.extraDepositConfig = new MatterExtraDepositConfig(matter.extraDepositConfig);

            this.matterForms = [];
            if (Array.isArray(matter.matterForms)) {
                matter.matterForms.forEach((form: MatterForm) => this.matterForms.push(new MatterForm(form)));
            }
            this.updatePiedRegistrationFees();

            this.matterEvents = [];
            if (Array.isArray(matter.matterEvents)) {
                matter.matterEvents.forEach((event) => this.matterEvents.push(new EventData(event)));
            }

            this.matterWorkItems = [];
            if (Array.isArray(matter.matterWorkItems)) {
                matter.matterWorkItems.forEach((item) => this.matterWorkItems.push(new MatterWorkItem(item)));
            }

            this.referredByList = [];
            if (Array.isArray(matter.referredByList)) {
                matter.referredByList.forEach((item) => this.referredByList.push(new ReferredByInfo(item)));
            }

            this.matterNotifications = [];
            if (Array.isArray(matter.matterNotifications)) {
                matter.matterNotifications.forEach((item) => this.matterNotifications.push(new MatterNotification(item)));
            }

            if(matter.opportunity){
                this.opportunity = new OpportunityInfo(matter.opportunity);
            }

            this.duplicateProspectDataList = [];
            if (Array.isArray(matter.duplicateProspectDataList)) {
                matter.duplicateProspectDataList.forEach((item) => this.duplicateProspectDataList.push(new DuplicateContactData(item)));
            }
            if (!matter.matterClosingStatus){
                this.matterClosingStatus = MatterClosingStatus.QUESTION;
            }
            if (matter.willMatter) {
                this.willMatter = new WillMatter(matter.willMatter);
            }
        } else {
            this.matterTaxRates = [];
            this.initSelectedProgressionStatus();
            this._finalAdjustments = [];
            this._interimAdjustments = [];
            this.adjustments = [];
            this.directDepositInstructions = [];
            this.matterParticipants = [];
            this.initializeHoldbacks();
            this.matterProperties.push(MatterProperty.createDefaultMatterProperty());
            if (!this.teranetDocket) {
                this.teranetDocket = new TeranetDocket();
            }

            this.matterWorkItems = [];
            this.referredByList = [];
            this.matterNotifications = [];
            if(!this.opportunity){
                this.opportunity = new OpportunityInfo();
            }
            this.matterClosingStatus = MatterClosingStatus.QUESTION;
        }

    }

    matterBurgerMenu: BurgerMenuExtendedItem[] = []; //UI Only for matter list
    isCopyInProgress: boolean = false; //UI for copying matter
    isMatterSaveInProgress: boolean = false; //UI for saving matter
    unityProjectId: number;
    projectRecordNumber: string;
    actingFor: string = 'QUESTION';
    clientReLine: string;
    lenderReLine: string;
    fileName: string;
    fileNo: string;
    lockboxCode: string;
    createdTimeStamp: string;
    createdByUser: User;
    lastAccessedDate: string;
    provinceCode: ProvinceCode;
    matterContactInfo: MatterContactInfo;
    contactInfo: ContactInfo[] = [];
    occupancyDate: string;
    accountingNumber: string;
    teraviewDocketIdentifier: any;
    teranetDocket: TeranetDocket;
    uffiWarrantyCode: any;
    documentsToBeSignedRemotely: DpBooleanValue;
    documentsToBeSignedDigitally: DpBooleanValue;
    digitalSignaturePlatform : string;
    otherSideDocumentsToBeSignedRemotely: DpBooleanValue;
    //only available for Purchase Matter
    vendorExecDocsAt: string;
    outOfProvinceVendorExecDocsAt: boolean;
    documentsExecutedAtJurisdictionId;
    agentName: string;
    appointmentScheduledFlag: string;
    cashOnClosingDate: CashOnClosingDateType;
    purchaserFinancing: PurchaserFinancingType;
    protocolClosing: ProtocolClosingType;
    interestRateSummary: string;
    brokerName: string;
    closed: string;
    closingFileNumber: string;
    feeQuote: string;
    fileInactiveDate: string;
    fileInactiveNotes: string;
    fileNumber: string;
    fileOpenDate: string;
    id: number;
    sourceMatterId: number;
    tempIdForNewMatter: number;
    documentProfileId: number;
    insurer: string;
    lastUpdatedTimeStamp: Date;
    creationStage: string;

    //TODO review this - may need to change to use Date type so that date/time localization is easier and more reliable
    matterCloseDate: string;
    possessionTime: string = DEFAULT_POSSESSION_TIME;

    matterStatus: string;
    matterClosingStatus: string;
    matterType: MatterType;
    customMatterTypeName: string;
    customMatterTypeDesc: string;
    isMatterTypeDischarge:boolean //UI field is true for Discharge Matters(whereas MatterType field is Mortgage such that it works as Mortgage Matter)
    isProvinceDisabled : boolean //UI field for Province in case of Discharge Matters
    permittedRegistrations: PermittedRegistration[] = [];
    purchaseSaleAgreementDate: string;
    purchaserExecDocsAt: string;
    outOfProvincePurchaserExecDocsAt: boolean;
    purchaserExecDocsDate: string;
    referredByList : ReferredByInfo[] = []; //replace referredBy, referredByType and referredByAttention
    registrationMethodCode: string;
    requisitionDate: string;
    requisitionSubmitted: DpBooleanValue;
    requisitionResponse: RequisitionResponse;

    selectedSolicitorId: number; //UI data
    selectedLawClerkId: number; //UI data
    selectedWitnessId: number; //UI data
    selectedCommissionerId: number; //UI data

    specialComments: string;
    teraviewDocketId: string;
    transactionTitleInsuredCode: string;
    updatedTimeStamp: string;
    vendorSurname: string;
    matterRecordNumber: string;
    /*
        value === undefined  ==> field never initialized
        value === null ==> field get cleanup manually
        other value ==> manually selected OR derived from document profile  (tab A)
    */
    jurisdictionId: number;
    updatedByUser: User;
    lockedByUser: User;
    //Matter can become inaccessible if some mass update transaction cannot be completed
    inaccessible: boolean;
    inaccessibleReason: string;
    matterParticipants: MatterParticipant[];
    matterProperties: MatterProperty[] = [];
    teraviewConfig: TeraviewConfig;
    //mainClient's capacity from Tab B
    purchasersCapacity: string;
    //developer's capacity from Tab B
    developersCapacity: string;
    //from Tab C
    otherPartiesCapacity: string;
    realEstateBrokerName: string;
    realEstateAgentName: string;
    selectedBrokerId: number;
    selectedAgentId: number;
    realEstateAgentEmail: string;
    considerationLtt: ConsiderationLtt;
    // for a 'SALE' matter these are VTB Mortgages
    mortgages: Mortgage[] = [];
    existingMortgages: Mortgage[] = [];
    directDepositInstructions: DirectDepositInstruction[];

    survivalAfterClosing: string;
    matterSoas: SoaMatter[];
    secondarySoaSheets : MatterSoaSheet[];
    matterTrustLedgers: TrustLedgerMatter[];
    receivedOnAccount: number = 0;
    receivedOnAccountInterim: number = 0;
    soaFeesCalculatedOnAllInclusivePrice: number = 0;
    soaInterimFeesCalculatedOnAllInclusivePrice: number = 0;
    feeCalculatedOnInclusivePriceFlag: boolean = false;
    interimFeeCalculatedOnInclusivePriceFlag: boolean = false;
    primarySoaTemplateId:number;
    primarySoaDetail : string;
    matterHst: number = 0;
    matterProvincialHst: number = 0;
    matterFederalHst: number = 0;
    matterTaxType: string; // careful when using this in UI as it might contain "GST+PST"
    // soaHst: number;
    // soaProvincialHst: number;
    // soaFederalHst: number;
    matterTaxRates: MatterTaxRate[] = [];
    // Does Not save to backed, created Temporary to work in different List in Statement of Account
    soaTrustLedgerCollection: SoaTrustLedgerCollection;
    secondarySoaSheetsCollection: SoaTrustLedgerCollection[] = [];
    statementConfigurationId: number;
    autoInsertAllF9Values: boolean; // please note that this only refers to TrustLedger as it gets initialized from statementConfig.trustLedger.allF9Values
    autoInsertCashShortfall: boolean;
    adjustments: StatementAdjustment[] = [];
    _interimAdjustments: StatementAdjustment[];
    _finalAdjustments: StatementAdjustment[];
    _interimAdjustmentsUnApplied: StatementAdjustment[] = [];
    _finalAdjustmentsUnApplied: StatementAdjustment[] = [];
    deductEcFeeFromSoa: boolean; // configuration for SOA to add tax to title insurance premium
    deductEliteFeeFromSoaTrustLedger: boolean; // configuration for SOA to add tax to title insurance premium about Elite Fee
    deductCcFeeFromSoaTrustLedger: boolean; // configuration for SOA to add tax to title insurance premium about the Connecting Counsel Fee
    deductLegalCounselFee: boolean; // configuration for SOA to add tax to title insurance premium about the Legal Counsel Fee
    matterRequisitions: RequisitionTemplate[] = [];
    matterUserDefinedFields: UserDefinedField[] = [];
    matterUndertakings: MatterUndertaking[] = [];
    matterCalculatedAmount: MatterCalculatedAmount;
    reportToPurchaser: PurchaserReport;
    adjustAsAtClosingDate: string;
    adjustAsAtClosingDateFlag: string;
    adjustAsAtClosingDateInterim: string;
    adjustAsAtClosingDateFlagInterim: string;
    interestRateOnDeferredPurchaseMoniesInterim: number;
    interestRateOnDeferredPurchaseMoniesFinal: number;
    form4AddressedTo: string;
    declarations: Declaration[] = [];
    matterCompliances: Compliance[] = [];
    defaultSupplementalTasksAdded: boolean = false;
    supplementalTasks: MatterSupplementalTaskCategory[] = [];
    matterStatementOfAdjustmentFooters: StatementAdjustmentConfig[] = [];
    statementOfAdjustmentHeading: StatementOfAdjustmentHeading;
    statementOfAdjustmentDisplay: StatementOfAdjustmentDisplay;
    isPurchaseTypeValueChanged: boolean = false;
    soaDefaultDocumentTemplateId: number;
    trustLedgerDefaultDocumentTemplateId: number;
    autoInsertHst: boolean;
    accessGroupId: number;
    eRegistrationForms: ERegistrationForm[] = [];
    matterForms: MatterForm[] = [];
    applicationFiledBy: string;
    //matterBilling: MatterBilling;
    billingTransactions: MatterBilling[] = [];
    statementOfAdjustmentPayable: StatementOfAdjustmentPayable;
    updateSupplemetalTaskExpenditureInTrustLedger: boolean;
    matterTitleInsurance: MatterTitleInsurance;
    overrideLegalDescription: boolean;
    legalDescription: string;
    reset: boolean;
    paysForDateOfClosing: string;
    /**
     * Client side flag (not present in the server side model), used for keeping track of whether a matter has been changed since
     * it's been retrieved from the server. This will default to false when the matter is initially retrieved.
     */
    isDirty: boolean;
    dirtySubject: Subject<boolean>; //used for tracking sections updated in mass update
    ignoreSupplementalTasks: boolean;
    ignoreMortgages: boolean;
    // RE Broker/CommissionMatterTopicInfo
    separateEntriesForCommissionPaidToTrustLedger: DpBooleanValue;
    insertExcessDepositIntoTrustLedger: boolean;
    commissionPaidTo: CommissionPaidToType;
    commissionDisbursedTo: CommissionDisbursedToType;
    commissionBasedOnFixedPercentageOfSalePrice: string;
    brokerCommission: BrokerCommission;

    // Direction Re Funds
    directionReFunds: DirectionReFund[];
    _interimDirectionReFunds: DirectionReFund[];
    _finalDirectionReFunds: DirectionReFund[];

    maxSupplementalTaskSeq: number;

    brokerPercentageOfCommissionOn: number;


    notesList: NotesList;

    showNotesOnOpen: boolean;

    showHiddenNotes: boolean;

    // If one matterParticipant select/deselect Show Hidden Notes checkbox, it will affect tab b and tab c all clients
    // matterParticipantsHiddenNotesVisible is matter level. Just one indicator for the matter
    matterParticipantsHiddenNotesVisible : boolean; //UI only. The show hidden notes checkbox will not be persisted in the database.

    matterTopics: MatterTopic[] = [];

    condoCorporationDocumentation: CondoCorporationDocumentation;

    numberOfParcels: number = 1;

    overrideHomeOwnerPolicyAmount: boolean;

    closingDatePayment: ClosingDatePayment;

    lateClosingText: string;

    altoEForms: AltoEForm[];

    altoEFormStaleFlags: AltoEFormStaleFlag[];

    matterLink: MatterLink;

    matterPricing: MatterPricing;

    matterPrice: number = 0;


    builderNumber: string;

    contactTerminated: DpBooleanValue;

    isReleaseDateSameAsOccupancyDate: DpBooleanValue;

    releaseDate: string;

    selectedProgressionStatus: string;

    private _adjustmentStatusMode: string = ProgressionStatus.FINAL;

    docsExecutedOn: string;

    legalDescriptionForSearch: string;

    /* related to deposit SOA */
    extraDepositConfig: MatterExtraDepositConfig;

    daysElapsedSinceEarliestUnreceiptedDepositReceivedFinal: number;

    daysElapsedSinceEarliestUnreceiptedDepositReceivedInterim: number;

    massUpdateMortgageeInterestNotedOnPolicies: MortgageeInterestNotedOnPolicy [] = ['QUESTION', 'QUESTION', 'QUESTION', 'QUESTION', 'QUESTION', 'QUESTION', 'QUESTION', 'QUESTION'];

    interimStatementAdjustmentOrders: StatementAdjustmentOrder[] = [];

    finalStatementAdjustmentOrders: StatementAdjustmentOrder[] = [];

    interimStatementOfAdjustmentsOrder: string;

    finalStatementOfAdjustmentsOrder: string;

    interestRates: InterestRate[] = [];

    holdbacks: MatterHoldback[] = [];
    advanceMatterHoldbackConfig: AdvanceMatterHoldbackConfig;
    rentInterestRates: RentInterestRate[] = [];
    conveyCaTransaction: boolean = false;
    conveyCaLogin: string;
    conveyCaPassword: string;

    paymentAmountsDisplayed: boolean; //used by 'Direction To Pay' topic

    fullyInitialized: boolean = true; //in the case of opportunities, indicates whether opportunity has been initialized from referral
    readyForUI: boolean = true; // UI field only, used for signaling that initialization process has completed
    billingStatus: string; //BILLED, UNBILLED, PREBILLED
    //This is only populated for delayed billing
    billingIssues: BillingIssue;

    referralId: number; //used only in UI to track if this is a new matter created from a referral.
    createdFromReferral: boolean;

    //Move it from BrokerCommission
    depositHeldBy: string;

    commissionDisbursedBy: CommissionDisbursedByType;

    brokerReleaseExcessTo: BrokerReleaseExcessToType;

    isSolicitorOrLawClerkDirty: boolean; //UI only

    //Flag for passing user's decision from frontend to backend. Ideally should be passed as request param but that would need change in signature of lot of
    // methods
    skipLawFirmDuplicateCheck: boolean;

    matterFlagged: boolean; //For Set to Flagged checkbox
    matterFlaggedReason: string; // For "Details" text box
    isMatterReady: boolean

    matterEvents: EventData[] = [];

    massUpdateTransactionId  : number;

    occupancyCompleted: DpBooleanValue;
    isTemplateMatterForMassUpdate: boolean;//Only for UI
    project: Project; //Only for UI
    templateForProject: boolean;

    brokersMatterLevelInfo: BrokerMatterLevelInfo[] = [];

    matterWorkItems: MatterWorkItem[] = [];
    appliedChecklistTemplateConfigId: number;
    matterNotifications: MatterNotification[] = [];
    receiveCustomerIndividualLetterFlag : boolean;
    suppressSkPstRebateCreditVendorWarning: boolean = false;
    suppressSkPstRebateCreditPurchaserWarning: boolean = false;
    suppressSkPstSalePriceCreditVendorWarning: boolean = false;

    // Opportunity Matter Only Fields
    opportunity: OpportunityInfo;

    duplicateOfOpportunityId: number;

    selectedOpportunityAssignedTo: number; //UI data
    /*
    * UI Flag to indicate that the matter is copied from an opportunity
    * It is used to decide which API to call when saving the matter for the first time
    * */
    isFromOpportunity: boolean;

    /*
    * UI field to store Opportunity Id.
    * It is used while converting Opportunity to matter.
    * */
    sourceOpportunityId:number

    duplicateProspectDataList: DuplicateContactData[] = []; //UI only

    sendInternalNotifications : string;

    sendExternalNotifications : string;

    openAssystPayoutModalAfterSave : boolean = true; // UI Property to check whether to open modal after save (Method name - checkAssystPayoutDataChange)

    matterEditingLockOverrideTimestamp:string;

    isMultipleCondoPropBlanketMortgage : boolean = false;

    requisitionDateCalculationConfig: DateCalculationConfig; //UI Object loaded at matter initialization

    willMatter?: WillMatter;

    get sellerBrokerMatterLevelInfo(): BrokerMatterLevelInfo{
        return MatterBrokerMatterLevelInfoUtil.getBrokersMatterLevelInfo(this, BrokerContactTypes.SELLER_BROKER);
    }

    get purchaserBrokerMatterLevelInfo(): BrokerMatterLevelInfo{
        return MatterBrokerMatterLevelInfoUtil.getBrokersMatterLevelInfo(this, BrokerContactTypes.PURCHASER_BROKER);
    }

    isDepositHeldByVendorSolicitor(): boolean {
        return this.depositHeldBy == constValues.depositHeldBy.VENDOR_LAWYER;
    }

    //if matter not billed and has billing business errors then open matter in readonly mode. if not billed because of system error then user can continue on it
    hasBillingFailed(): boolean {
        return !this.isBilled() && this.billingIssues && this.billingIssues.hasBusinessError()
    }


    //As occupancy deposit amount exists in two places in matter modal therefore created this method to update both of them.
    setOccupancyDepositAmount(amount: number): void {
        if (this.extraDepositConfig) {
            this.extraDepositConfig.depositOnOccupancyAmount = amount;
        }
        if (this.matterPropertyWithCondo && this.matterPropertyWithCondo.occupancyDeposit) {
            this.matterPropertyWithCondo.occupancyDeposit.depositAmount = "" + amount;
        }
    }

    get _directionReFundsStatusMode(): string {
        return this.adjustmentStatusMode ? this.adjustmentStatusMode : ProgressionStatus.FINAL;
    }

    set _directionReFundsStatusMode(mode: string) {
        if (mode) {
            this.adjustmentStatusMode = mode;
        }

    }

    get adjustmentStatusMode(): string {
        return this._adjustmentStatusMode ? this._adjustmentStatusMode : ProgressionStatus.FINAL;
    }

    set adjustmentStatusMode(mode: string) {
        if (mode) {
            this._adjustmentStatusMode = mode;
            if (this.considerationLtt && this.considerationLtt.salePriceAdjustment) {
                this.considerationLtt.salePriceAdjustment.matterAdjustmentStatusMode = mode;
            }
        }
    }

    get statementOfAdjustments(): StatementAdjustment[] {
        return this.isAdjustmentStatusModeFinal ? this.finalStatementAdjustments : this.interimStatementAdjustments;
    }

    get statementOfAdjustmentsFocused(): StatementAdjustment[] {
        return this.isSelectedAdjustmentStatusModeFinal ? this.finalStatementAdjustments : this.interimStatementAdjustments;
    }

    get statementOfAdjustmentsUnApplied(): StatementAdjustment[] {
        return this.isAdjustmentStatusModeFinal ? this.finalStatementAdjustmentsUnApplied : this.interimStatementAdjustmentsUnApplied;
    }

    get interimStatementAdjustments(): StatementAdjustment[] {
        return this._interimAdjustments
    }

    getInterimUpdatedStatmentAdjutmentsApplied(matter: Matter): StatementAdjustment[] {
        return this.interimStatementAdjustments ? this.interimStatementAdjustments.filter(item => !matter.allStatementAdjustments.some(interimAdj => (!!interimAdj.sourceProjectAdjustmentId && !!item.id && interimAdj.sourceProjectAdjustmentId == item.id)))
            .filter(adj => !matter.interimStatementAdjustments.some(interimAdj => (!!interimAdj.linkId && !!adj.linkId && interimAdj.linkId == adj.linkId)))
            .filter(adj => !adj.linkId || !matter.finalStatementAdjustments.some(matterFinalAdj => !!matterFinalAdj.applyToAdjustmentRecord && this.finalStatementAdjustments.some(finalAdj => finalAdj.linkId == adj.linkId && matterFinalAdj.sourceProjectAdjustmentId == finalAdj.id)))
            .filter(adj => !matter.interimStatementAdjustments.some(matterInterimAdj => !!matterInterimAdj.applyToAdjustmentRecord && this.finalStatementAdjustments.some(finalAdj => finalAdj.linkId == adj.linkId && matterInterimAdj.sourceProjectAdjustmentId == finalAdj.id))) : []
    }

    getFinalUpdatedStatmentAdjutmentsApplied(matter: Matter): StatementAdjustment[] {
        return this.finalStatementAdjustments ? this.finalStatementAdjustments.filter(item => !!item.applyToAdjustmentRecord && !matter.allStatementAdjustments.some(finalAdj => (!!finalAdj.sourceProjectAdjustmentId && !!item.id && finalAdj.sourceProjectAdjustmentId == item.id)))
            .filter(adj => !matter.finalStatementAdjustments.some(matterFinalAdj => (!!matterFinalAdj.linkId && !!adj.linkId && matterFinalAdj.linkId == adj.linkId)))
            .filter(adj => !adj.linkId || !matter.interimStatementAdjustments.some(matterInterimAdj => !!matterInterimAdj.applyToAdjustmentRecord && this.interimStatementAdjustments.some(interimAdj => interimAdj.linkId == adj.linkId && matterInterimAdj.sourceProjectAdjustmentId == interimAdj.id)))
            .filter(adj => !matter.finalStatementAdjustments.some(matterFinalAdj => !!matterFinalAdj.applyToAdjustmentRecord && this.interimStatementAdjustments.some(interimAdj => interimAdj.linkId == adj.linkId && matterFinalAdj.sourceProjectAdjustmentId == interimAdj.id))) : []
    }

    get finalStatementAdjustments(): StatementAdjustment[] {
        return this._finalAdjustments
    }


    get interimStatementAdjustmentsUnApplied(): StatementAdjustment[] {
        return this._interimAdjustmentsUnApplied
    }

    get finalStatementAdjustmentsUnApplied(): StatementAdjustment[] {
        return this._finalAdjustmentsUnApplied
    }

    get allUnAppliedStatementAdjustments(): StatementAdjustment[] {
        return this.finalStatementAdjustmentsUnApplied.concat(this.interimStatementAdjustmentsUnApplied);
    }

    get allStatementAdjustments(): StatementAdjustment[] {
        return this.finalStatementAdjustments.concat(this.interimStatementAdjustments);
    }

    //This uniqueStatementAdjustments includes the statement adjustment without link item(it means linkId is null)
    // and if the statement adjustments with link item(it means linkId is NOT null), only one is included
    get uniqueStatementAdjustments(): StatementAdjustment[] {
        let uniqueStatementAdjustments: StatementAdjustment[] = [];
        let allStatementAdjustments: StatementAdjustment[] = this.allStatementAdjustments;

        if (allStatementAdjustments && allStatementAdjustments.length > 0) {
            for (let i = 0; i < allStatementAdjustments.length; i++) {
                if (!allStatementAdjustments[i].linkId) {
                    uniqueStatementAdjustments.push(allStatementAdjustments[i]);
                } else {
                    if (!uniqueStatementAdjustments.find(item => item.linkId === allStatementAdjustments[i].linkId)) {
                        uniqueStatementAdjustments.push(allStatementAdjustments[i])
                    }
                }
            }
        }
        return uniqueStatementAdjustments;
    }

    get purchasePriceDeferredPortionSoas(): StatementAdjustment[] {
        if (!this.interimStatementAdjustments) {
            return [];
        }
        return this.interimStatementAdjustments.filter((soa: StatementAdjustment) => {
            return soa.itemKey == StatementAdjustmentKey.PURCHASE_PRICE_DEFERRED_PORTION;
        });
    }

    clearStatmentOfAdjutments(): void {
        this.isAdjustmentStatusModeFinal ? this._finalAdjustments = [] : this._interimAdjustments = [];
    }

    get isAdjustmentStatusModeFinal(): boolean {
        return (this.adjustmentStatusMode != ProgressionStatus.INTERIM);
    }

    get isSelectedAdjustmentStatusModeFinal(): boolean {
        return (this.selectedProgressionStatus != ProgressionStatus.INTERIM);
    }

    get isDirectionReFundsInterim(): boolean {
        return (this._directionReFundsStatusMode === ProgressionStatus.INTERIM);
    }

    isStatementAdjustmentInterimAndFinal(): boolean {
        return this.project && this.project.isStatmentOfAdjustmentInterim();
    }

    isProjectConfigDocForDirectionReFund(): boolean {
        return this.project && this.project.isProjectConfigDocForDirectionReFund();
    }

    isProjectConfigDocForSoa(): boolean {
        return this.project && this.project.isProjectConfigDocForSoa();
    }

    isProjectConfigDocForTrustLedger(): boolean {
        return this.project && this.project.isProjectConfigDocForTl();
    }

    // Trust Ledger topic total Expenditure is equal to total Receipts.
    isTrustLedgerTopicsBalanced(): boolean {
        let totalExpenditureCents = this.matterTrustLedgers.filter(item => item.itemType === 'Expenditure').reduce((totalCents: number, current: TrustLedgerMatter) => Number(totalCents + Math.round(current.itemValue * 100)), 0);
        let totalReceiptCents = this.matterTrustLedgers.filter(item => item.itemType === 'Receipt').reduce((totalCents: number, current: TrustLedgerMatter) => Number(totalCents + Math.round(current.itemValue * 100)), 0);
        return totalExpenditureCents == totalReceiptCents;
    }



    checkTrustLedgerSoaLegalFeeBalanced() : string[] {
        let errorMessages : string [] = [];
        let primarySoaGrandTotal = (this.matterCalculatedAmount && this.matterCalculatedAmount.soaGrandTotal) ? this.matterCalculatedAmount.soaGrandTotal : 0;
        let trustLedgerLegalFee = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgeConfigKeys.L1_LF && mtl.progressionStatus == this.soaTrustLedgerCollection.progressionStatus
        && mtl.matterSoaSheetId == undefined);
        let legalFeesPrimaryTL = (trustLedgerLegalFee && trustLedgerLegalFee.itemValue) ? trustLedgerLegalFee.itemValue : 0;
        if(legalFeesPrimaryTL != primarySoaGrandTotal){
            errorMessages.push('Document(s) cannot be produced because the total of the fees & disbursements in the Statement ' +
                'of Account '+(this.soaPrimarySheetDetail)+' ('+ Utils.formattedCurrencyValue(primarySoaGrandTotal) +') is not' +
                ' equal to the Paid Legal Fees & Disbursements ('+ Utils.formattedCurrencyValue(legalFeesPrimaryTL) +') in the' +
                ' Trust Ledger')
        }
        this.secondarySoaSheets.forEach( sheet =>{
            let secondarySoaGrandTotal = (sheet.matterCalculatedAmount && sheet.matterCalculatedAmount.soaGrandTotal) ? sheet.matterCalculatedAmount.soaGrandTotal : 0;
            let trustLedgerLegalFee = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgeConfigKeys.L1_LF && mtl.progressionStatus == this.soaTrustLedgerCollection.progressionStatus
                && mtl.matterSoaSheetId == sheet.id);
            let legalFeesSecondaryTL = (trustLedgerLegalFee && trustLedgerLegalFee.itemValue) ? trustLedgerLegalFee.itemValue : 0;
            if(legalFeesSecondaryTL != secondarySoaGrandTotal){
                errorMessages.push('Document(s) cannot be produced because the total of the fees & disbursements in the Statement ' +
                    'of Account'+(sheet.details ? ' - '+sheet.details : '')+' ('+ Utils.formattedCurrencyValue(secondarySoaGrandTotal) +') is not' +
                    ' equal to the Paid Legal Fees & Disbursements ('+ Utils.formattedCurrencyValue(legalFeesSecondaryTL) +') in the' +
                    ' Trust Ledger')
            }
        });
        return errorMessages;

    }


    public getMatterParticipantByContactId = (contactId: number): MatterParticipant => {

        let participant: MatterParticipant = this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.contact.id === contactId;
        });

        return participant;
    }

    /**
     * Retrieves a participant given their full name. This assumes only one participant with a given name in a matter.
     * If the assumption changes in the future, this logic will have to be revisited.
     * @param participantName
     * @returns {undefined|MatterParticipant}
     */
    public getMatterParticipantByFullName(participantName: string): MatterParticipant {
        return this.matterParticipants.find((mp: MatterParticipant) => mp.contact.fullName === participantName);
    }

    /**
     * Retrieves a participant given their full name. If there are more than one purchaser with same name then it returns
     * the first participant with given name but excluding selected one.
     * @param participantName
     * @returns {undefined|MatterParticipant}
     */
    public getMatterParticipantByFullNameExcludingSelected(participantId: number, selectedMatterParticipant: MatterParticipant): MatterParticipant {
        return this.matterParticipants.find((mp: MatterParticipant) => {
            return selectedMatterParticipant != mp && mp.matterParticipantId === participantId;
        });
    }

    public getSpouseParticipant(participantId: number): MatterParticipant {
        return this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantSpouse && mp.spouseParticipantId === participantId;
        });
    }

    public getMatterParticipantByRole(matterParticipantRole: MatterParticipantRole): MatterParticipant {
        if (!this.matterParticipants) {
            return null;
        }
        return this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === matterParticipantRole);
    }

    public getMatterParticipantByRoleAndMortgage(matterParticipantRole: string, mortgage: Mortgage): MatterParticipant {
        if (!this.matterParticipants || !mortgage) {
            return null;
        }
        return this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === matterParticipantRole && mp.mortgageId == mortgage.id);
    }

    update(source: Matter) {
        if (source) {
            for (let prop in source) {
                if (source.hasOwnProperty(prop)) {
                    this[prop] = source[prop];
                }
            }
        }
    }

    get isOpportunityMatterWithNoProvinceOrMatterType():boolean {
        return this.isOpportunityMatter() && (!this.customMatterTypeName || !this.provinceCode);
    }

    get isRegularMatterOrOpportunityMatterWithRequiredInfo(): boolean{
        return !this.isOpportunityMatter() || !this.isOpportunityMatterWithNoProvinceOrMatterType;
    }


    get isMatterActive(): boolean {
        return this.matterStatus !== 'INACTIVE';
    }

    get isSale(): boolean {
        if (this.matterType) {
            return this.matterType.toLocaleUpperCase() == 'SALE';
        } else {
            return false;
        }
    }

    get isPurchase(): boolean {
        if (this.matterType) {
            return this.matterType.toUpperCase() == 'PURCHASE';
        } else {
            return false;
        }
    }

    get isMortgage(): boolean {
        if (this.matterType) {
            return this.matterType.toUpperCase() == 'MORTGAGE'
        } else {
            return false;
        }
    }

    get isSaleOpportunity(): boolean{
        if(this.customMatterTypeName){
            return this.matterType == MatterTypesValue.OPPORTUNITY &&  this.customMatterTypeName.toUpperCase() == 'SALE'
        }else{
            return false;
        }
    }

    get isMortgageOpportunity(): boolean {
        if (this.customMatterTypeName) {
            return this.matterType == MatterTypesValue.OPPORTUNITY && this.customMatterTypeName.toUpperCase() == 'MORTGAGE'
        } else {
            return false;
        }
    }

    get isPurchaseOpportunity(): boolean {
        if (this.customMatterTypeName) {
            return this.matterType == MatterTypesValue.OPPORTUNITY && this.customMatterTypeName.toUpperCase() == 'PURCHASE';
        } else {
            return false;
        }
    }

    get isDischargeOpportunity(): boolean {
        if (this.customMatterTypeName) {
            return this.customMatterTypeName.toUpperCase() == 'DISCHARGE';
        } else {
            return false;
        }
    }

    get isProjectSale(): boolean {
        return !!this.unityProjectId;
    }

    get isMortgageAB(): boolean {
        return this.isMortgage && this.isMatterProvinceAB;
    }

    get mainClientType(): MatterParticipantRole {
        if (this.isSale) {
            return 'VENDOR';
        }
        if (this.isMortgage || this.isMatterTypeDischarge ) {
            return 'MORTGAGOR';
        }
        if(this.isOpportunityMatter()) {
            return 'PROSPECT';
        }
        if(this.isWillMatter()) {
            return 'TESTATOR';
        }
        return 'PURCHASER';
    }

    // Return 'Vendor', 'Mortgagor' and 'Purchaser'
    get mainClientTypeName(): string {
        return Utils.capitalizeWord(this.mainClientType);
    }

    get otherPartyContactInfoType(): string {
        if (this.isSale) {
            return ContactInfoType.PURCHASER;
        }
        if (this.isMortgage) {
            return ContactInfoType.LENDER;
        }
        return ContactInfoType.VENDOR;
    }

    get otherSideClientType(): MatterParticipantRole {
        if (this.isSale) {
            return 'PURCHASER';
        }
        if (this.isMortgage) {
            return 'MORTGAGOR';
        }
        return 'VENDOR';
    }


    get otherSideDirectDepostClientType(): MatterParticipantRole {
        if (this.isSale) {
            return 'PURCHASER';
        }
        if (this.isMortgage) {
            return 'OTHERPARTY_SOLICITOR';
        }
        return 'VENDOR';
    }

    // Return 'Purchaser', 'Mortgagor' and 'Vendor'
    get otherSideClientTypeName(): string {
        return Utils.capitalizeWord(this.otherSideClientType);
    }

    get otherPartyMPRoleSolicitor(): MatterParticipantRole {
        return 'OTHERPARTY_SOLICITOR';
    }

    get otherPartyMPRoleLawFirm(): MatterParticipantRole {
        return 'OTHERPARTY_LAW_FIRM';
    }


    /**
     * Generically retrieve the other's party contact info. This varies depending on the matter type
     * Purchase matter  -> vendor
     * Sale matter      -> purchaser
     * Mortgage matter  -> lender
     * @returns {ContactInfo}
     */
    get otherPartyContactInfo(): ContactInfo {
        switch (this.matterType) {
            case MatterTypesValue.PURCHASE :
            case MatterTypesValue.OPPORTUNITY :
            case MatterTypesValue.WILL:
                return this.vendorContactInfo;
            case MatterTypesValue.SALE     :
                return this.purchaserContactInfo;
            case MatterTypesValue.MORTGAGE :
                return this.lenderContactInfo;
            default         :
                return null; //Shouldn't happen, but need the case
        }
    }

    createOtherPartyContactInfo() {
        const tmpContactInfo: ContactInfo = new ContactInfo()
        tmpContactInfo.contactInfoType = this.otherPartyContactInfoType;
        tmpContactInfo.instanceType = ContactInfoInstanceTypes.contactInfo;
        this.contactInfo.push(tmpContactInfo);
    }

    /**
     * Used to get the other party's contact information in a mortgage transaction.
     * @returns {ContactInfo}
     */
    get lenderContactInfo(): ContactInfo {
        return Array.isArray(this.contactInfo) && this.contactInfo.find((ci: ContactInfo) => ci.contactInfoType == ContactInfoType.LENDER);
    }

    /**
     * Used to the the other's party contact info in a sale transaction. Note that this is not where the purchaser information is stored for a purchase matter
     * @returns {ContactInfo}
     */
    get purchaserContactInfo(): ContactInfo {
        return Array.isArray(this.contactInfo) && this.contactInfo.find((ci: ContactInfo) => ci.contactInfoType == ContactInfoType.PURCHASER);
    }

    get vendorContactInfo(): ContactInfo {
        return Array.isArray(this.contactInfo) && this.contactInfo.find((ci: ContactInfo) => ci.contactInfoType == ContactInfoType.VENDOR);
    }

    get developerContactInfo(): ContactInfo {
        return Array.isArray(this.contactInfo) && this.contactInfo.find((ci: ContactInfo) => ci.contactInfoType == ContactInfoType.DEVELOPER);
    }

    get ListingAgentContactInfo(): ContactInfo {
        return Array.isArray(this.contactInfo) && this.contactInfo.find((ci: ContactInfo) => ci.contactInfoType == ContactInfoType.LISTING_AGENT);
    }

    get ListingBrokerContactInfo(): ContactInfo {
        return Array.isArray(this.contactInfo) && this.contactInfo.find((ci: ContactInfo) => ci.contactInfoType == ContactInfoType.LISTING_BROKER);
    }

    get fireInsuranceContactInfo(): ContactInfo {
        return Array.isArray(this.contactInfo) && this.contactInfo.find((ci: ContactInfo) => ci.contactInfoType == ContactInfoType.FIRE_INSURER);
    }

    get isFireInsuranceContactBroker(): boolean {
        return this.fireInsuranceContactInfo && this.fireInsuranceContactInfo.insuranceBrokerType === MatterParticipantRoleTypes.BROKER;
    }

    get fireInsurerOrBrokerContact(): Contact {
        return this.isFireInsuranceContactBroker ?
            this.brokerMatterParticipant && this.brokerMatterParticipant.contact :
            this.insurerMatterParticipant && this.insurerMatterParticipant.contact;

    }

    removeContactInfoByType(contactInfoType: string): void {
        _.remove(this.contactInfo, (ci: ContactInfo) => ci.contactInfoType === contactInfoType);
    }

    get matterClosingDateYear(): string {
        return this.isClosingDateAvailable() ? (new Date(this.matterCloseDate)).getFullYear().toString() : '????';
    }

    // The arrow function - "public deleteMatterParticipant = (selectedMatterParticipant: MatterParticipant): void => {"
    // prevent to bind this to the latest matter.
    // After switching tab, deleteMatterParticipant still bind to the old matter
    public deleteMatterParticipant(selectedMatterParticipant: MatterParticipant): void {
        // public deleteMatterParticipant = (selectedMatterParticipant: MatterParticipant): void => {
        if (selectedMatterParticipant) {
            let matterParticipantIndex: number = _.findIndex(this.matterParticipants, matterParticipant => matterParticipant.matterParticipantId === selectedMatterParticipant.matterParticipantId);
            if (matterParticipantIndex >= 0) {
                this.matterParticipants.splice(matterParticipantIndex, 1);
            }
        }

    }

    public deleteMatterParticipantByRole(selectedMatterParticipantRole: MatterParticipantRole): void {
        //TODO: this is misleading, it only deletes one participant. Works for cases where there's only one for a role, but confusing otherwise
        let matterParticipantIndex: number = _.findIndex(this.matterParticipants, matterParticipant => matterParticipant.matterParticipantRole === selectedMatterParticipantRole);
        if (matterParticipantIndex >= 0) {
            this.matterParticipants.splice(matterParticipantIndex, 1);
        }

    }

    public deleteAllMatterParticipantByRole(selectedMatterParticipantRole: MatterParticipantRole, mortgageId?: number): void {
        MatterParticipantUtil.deleteAllMatterParticipantByRole(this, selectedMatterParticipantRole, mortgageId);
    }

    /**
     * Sorted list (by priority) of participants enrolled in the matter as purchasers
     * @returns {MatterParticipant[]}
     * @deprecated - TBD, should we use @mainClients, as it generically returns the appropriate participant type based on matter type.
     * We'll decide once we clarify what features are available for the other matter types
     */
    get purchasers(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'PURCHASER';
        }).sort((mp1: MatterParticipant, mp2: MatterParticipant) => mp1.matterParticipantPriority - mp2.matterParticipantPriority);
    }

    /**
     * Sorted list (by priority) of participants enrolled in the matter as main otherParties (purchasers for PURCHASE matter, vendors for SALE matter and
     * mortgagors for MORTGAGE matters)
     * @returns {MatterParticipant[]}
     */
    get mainClients(): MatterParticipant[] {
        let participantClientType = this.mainClientType;
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole == participantClientType || (this.isWillMatter() && mp.matterParticipantRole === 'TESTATOR_SPOUSE');
        }).sort((mp1: MatterParticipant, mp2: MatterParticipant) => mp1.matterParticipantPriority - mp2.matterParticipantPriority);
    }

    //This method returns the other side of participants added on matter (vendor - Purchaser Matter, purchaser - Vendor Matter & mortgagor - Mortgage)
    get otherSideClients(): MatterParticipant[] {
        let participantClientType = this.otherSideClientType;
        if (!this.matterParticipants || this.isMortgage) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole == participantClientType;
        });
    }

    isMainClient(mp : MatterParticipant) {
        return this.mainClients.find(p => p.matterParticipantId == mp.matterParticipantId);
    }

    //This method returns the other side of participants added on matter (vendor - Purchaser Matter, purchaser - Vendor Matter & mortgagor - Mortgage)
    get developers(): MatterParticipant[] {
        let participantClientType = MatterParticipantRoleTypes.DEVELOPER;
        if (!this.isProjectSale || !this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole == participantClientType;
        });
    }

    containsMatterOnlyClients(): boolean {
        return Array.isArray(this.otherSideClients) && this.otherSideClients.some((mp => mp.contact && !mp.contact.sourceContactId));
    }

    get tabCClients(): MatterParticipant[] {
        let participantClientType = this.tabCClientType;
        if (!this.matterParticipants || this.isMortgage) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole == participantClientType;
        });
    }

    get tabCClientType(): MatterParticipantRole {
        if (this.isSale) {
            return 'PURCHASER';
        }
        if (this.isMortgage) {
            return 'OTHERPARTY_SOLICITOR';
        }
        return 'VENDOR';
    }


    get compliances(): Compliance[] {
        if (!this.matterCompliances) {
            return [];
        }

        return _.sortBy(this.matterCompliances, ['departmentPriority']);
    }

    get mortgageBrokers(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'MORTGAGE_BROKER';
        }).sort((mp1: MatterParticipant, mp2: MatterParticipant) => mp1.matterParticipantPriority - mp2.matterParticipantPriority);
    }

    getMortgageBrokerFeeByMortgageId(mortgageId: number): number {
        if (this.mortgages && this.mortgages.length > 0) {

            let mrtg = this.mortgages.find(m => m.id == mortgageId);
            if (mrtg) {
                return mrtg.brokerageFee;
            }
        }
        return 0;
    }

    getMortgageBrokerNameByMortgageId(mortgageId): string {
        if (this.mortgageBrokers && this.mortgageBrokers.length > 0) {

            let mrtgBroker = this.mortgageBrokers.find(mb => mb.mortgageId == mortgageId);
            if (mrtgBroker && mrtgBroker.contact) {
                return mrtgBroker.contact.reLineFirstNameFormat;
            }
        }
        return '';
    }

    get anyMortgageWithBrokerFee(): boolean {
        if (this.mortgages && this.mortgages.length > 0) {
            return this.mortgages.some(m => m.brokerageFee > 0);
        }
        return false;
    }

    get anyDischargedExistingMortgageWithSeparateChequeAndNotAutoInsert() : boolean {
        if ( this.existingMortgages && this.existingMortgages.length > 0 ){
            if(this.isProjectSale){
                return this.existingMortgages.some(em => em.mortgageDispositionType === MortgageDispositionType.DISCHARGED && em.mortgagePayout && em.mortgagePayout.separateChequeForDischargeFee === YesNoTypes.YES);
            }else{
                return this.existingMortgages.some(em => em.mortgageDispositionType === MortgageDispositionType.DISCHARGED && em.mortgagePayout && em.mortgagePayout.separateChequeForDischargeFee === YesNoTypes.YES && !em.mortgagePayout.insertSeparateChequeTL);
            }
        }
        return false;
    }

    get containsPayBillsOrDebtsTask(): boolean {
        if (this.supplementalTasks && this.supplementalTasks.length > 0) {
            return this.supplementalTasks.some(st => st.isPayBillOrDebt);
        }
        return false;
    }

    matterParticipantSpouse(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'PURCHASER' && mp.getFamilyLawActStatus('MATTER_PARTICIPANT_SPOUSE')
        }).sort((mp1: MatterParticipant, mp2: MatterParticipant) => mp1.matterParticipantPriority - mp2.matterParticipantPriority);
    }

    get offerors(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'OFFEROR';
        }).sort((mp1: MatterParticipant, mp2: MatterParticipant) => mp1.matterParticipantPriority - mp2.matterParticipantPriority);
    }

    get mortgagees(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'MORTGAGEE';
        });
    }

    get empMortgages(): Mortgage[] {
        if (this.mortgages && this.mortgages.length > 0) {
            return this.mortgages.filter((m: Mortgage) => {
                return m.isEmpMortgage();
            });
        }
        return [];
    }

    getStewartMortgageInstructionByMortgage(mortgage: Mortgage): StewartMortgageInstruction {
        let empMortgage = mortgage ? this.empMortgages.find(item => item.id == mortgage.id) : undefined;
        return empMortgage ? empMortgage.stewartAssystMortgageInstruction : undefined;
    }

    getMortgagees(mortgage: Mortgage): MatterParticipant[] {
        if (!this.matterParticipants || !mortgage) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'MORTGAGEE' && mp.mortgageId == mortgage.id;
        });
    }

    get insurerMatterParticipant(): MatterParticipant {
        if (!this.matterParticipants) {
            return null;
        }

        return this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'INSURER';
        });
    }

    get brokerMatterParticipant(): MatterParticipant {
        if (!this.matterParticipants) {
            return null;
        }

        return this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'BROKER';
        });
    }

    get realEstateAgentParticipant(): MatterParticipant {
        if (!this.matterParticipants) {
            return null;
        }

        return this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantRole === MatterParticipantRoleTypes.REALESTATEAGENT;
        });
    }

    get surveyorMatterParticipant(): MatterParticipant {
        if (!this.matterParticipants) {
            return null;
        }

        return this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'SURVEYOR';
        });
    }

    get residentAssociationMatterParticipant(): MatterParticipant {
        if (!this.matterParticipants) {
            return null;
        }

        return this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'RESIDENCE_ASSOCIATION';
        });
    }

    public getMatterParticipantsByMortgage(mortgage: Mortgage): MatterParticipant[] {
        return this.matterParticipants ? this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.mortgageId === mortgage.id;
        }) : [];
    }

    get privateLenders(): MatterParticipant[] {
        return this.matterParticipants ? this.matterParticipants.filter((mp: MatterParticipant) => mp.matterParticipantRole === 'PRIVATE_LENDER') : [];
    }

    getMortgageSolicitorAddress(mortgage: Mortgage): Address {
        let solicitorContact: Contact = this.getSolicitorOfMortgage(mortgage);
        if (solicitorContact && solicitorContact.mailingAddress) {
            if (solicitorContact.mailingAddress.sameAsAddressTypeCode == AddressTypes.firmMailing) {
                const legalFirmContact: Contact = this.getLegalFirmOfMortgage(mortgage);
                return legalFirmContact && legalFirmContact.mailingAddress;
            } else {
                return solicitorContact.mailingAddress;
            }
        }
        return null;
    }

    getSolicitorOfMortgage(mortgage: Mortgage): Contact {
        let solicitorMP: MatterParticipant = this.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', mortgage);
        return solicitorMP && solicitorMP.contact;
    }

    getLegalFirmOfMortgage(mortgage: Mortgage): Contact {
        let solicitorMP: MatterParticipant = this.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', mortgage);
        return solicitorMP && solicitorMP.contact;
    }

    removePrivateLendersFromMortgage(mortgage: Mortgage) {
        if (!mortgage) {
            return;
        }
        return this.matterParticipants = this.matterParticipants ? this.matterParticipants.filter((mp: MatterParticipant) => mp.matterParticipantRole !== 'PRIVATE_LENDER' || mp.mortgageId != mortgage.id) : [];
    }

    removeMortgageeFromMortgage(mortgage: Mortgage) {
        if (!mortgage) {
            return;
        }
        return this.matterParticipants = this.matterParticipants ? this.matterParticipants.filter((mp: MatterParticipant) => mp.matterParticipantRole !== 'MORTGAGEE' || mp.mortgageId != mortgage.id) : [];
    }

    getPrivateLenders(mortgage: Mortgage): MatterParticipant[] {
        if (!mortgage) {
            return [];
        }
        return this.matterParticipants ? this.matterParticipants.filter((mp: MatterParticipant) => mp.matterParticipantRole === 'PRIVATE_LENDER' && mp.mortgageId == mortgage.id) : [];
    }

    primaryPrivateLender(mortgage: Mortgage): MatterParticipant {
        return this.getPrivateLenders(mortgage).find(p => p.primary);
    }

    get primaryMainClient(): MatterParticipant {
        return this.mainClients.find(p => p.primary);
    }

    get mortgageBroker(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === 'MORTGAGE_BROKER') : undefined;
    }

    getMortgageBroker(mortgage: Mortgage): MatterParticipant {
        if (!this.matterParticipants || !mortgage) {
            return undefined;
        }
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === 'MORTGAGE_BROKER' && mp.mortgageId == mortgage.id) : undefined;
    }

    get guarantors(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'GUARANTOR';
        });
    }

    getParticipantsByRole(mpRole: MatterParticipantRole): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === mpRole;
        });
    }

    getGuarantors(mortgage: Mortgage): MatterParticipant[] {
        if (!this.matterParticipants || !mortgage) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'GUARANTOR' && mp.mortgageId == mortgage.id;
        });
    }

    get vendors(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'VENDOR';
        });
    }

    get transferors(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'TRANSFEROR';
        });
    }

    get signingOfficers(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'SIGNING_OFFICER';
        });
    }

    get mortgagors(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'MORTGAGOR';
        }).sort((mp1: MatterParticipant, mp2: MatterParticipant) => mp1.matterParticipantPriority - mp2.matterParticipantPriority);
    }

    get mortgageSolicitors(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === MatterParticipantRoleTypes.MORTGAGE_SOLICITOR;
        });
    }

    get mortgageLegalFirms(): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole === MatterParticipantRoleTypes.MORTGAGE_LEGAL_FIRM;
        });
    }

    get otherPartyLawFirm(): MatterParticipant {
        if (!Array.isArray(this.matterParticipants)) {
            return null;
        }
        return this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'OTHERPARTY_LAW_FIRM';
        });
    }

    get otherPartySolicitor(): MatterParticipant {
        if (!Array.isArray(this.matterParticipants)) {
            return null;
        }
        return this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'OTHERPARTY_SOLICITOR';
        });
    }

    get otherPartyLawClerk(): MatterParticipant {
       return MatterParticipantUtil.createOrUpdateOtherSideLawClerkMatterParticipantFromContactInfo(this);
    }

    get developerLawFirm(): MatterParticipant {
        if (!Array.isArray(this.matterParticipants)) {
            return null;
        }
        return this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'DEVELOPER_LAW_FIRM';
        });
    }

    get developerSolicitor(): MatterParticipant {
        if (!Array.isArray(this.matterParticipants)) {
            return null;
        }
        return this.matterParticipants.find((mp: MatterParticipant) => {
            return mp.matterParticipantRole === 'DEVELOPER_SOLICITOR';
        });
    }


    get condoCorporation(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === 'CONDO_CORPORATION') : null;
    }

    get managementCompany(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === 'MANAGEMENT_COMPANY') : null;
    }

    get condoCorpAttention(): MatterParticipant {
        if (this.condoCorporation.contact.isSelfManaged()) {
            return this.findMatterParticipant(MatterParticipantRoleTypes.CONDO_CORPORATION_ATTENTION);
        } else if (this.condoCorporation.contact.isManagedByManagementCompany() && this.managementCompany) {
            return this.findMatterParticipant(MatterParticipantRoleTypes.MANAGEMENT_COMPANY_ATTENTION);
        }
        return null;
    }

    get realEstateAgent(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === 'REALESTATEAGENT') : null;
    }

    get realEstateBroker(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === 'REALESTATEBROKER') : null;
    }

    get purchaserRealEstateAgent(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === MatterParticipantRoleTypes.OTHERPARTY_REALESTATEAGENT) : null;
    }

    get purchaserRealEstateBroker(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === MatterParticipantRoleTypes.OTHERPARTY_REALESTATEBROKER) : null;
    }

    get lawClerk(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === MatterParticipantRoleTypes.LAWCLERK) : null;
    }

    get solicitor(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === MatterParticipantRoleTypes.SOLICITOR) : null;
    }

    get witness(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === MatterParticipantRoleTypes.WITNESS) : null;
    }

    get commissioner(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === MatterParticipantRoleTypes.COMMISSIONER) : null;
    }

    get opportunityAssignee(): MatterParticipant {
        return this.matterParticipants ? this.matterParticipants.find((mp: MatterParticipant) => mp.matterParticipantRole === MatterParticipantRoleTypes.OPPORTUNITY_ASSIGNEE) : null;
    }

    getMatterReline(): string {
        if(this.isMortgageMatterAndMortgageePrimary()){
            return this.lenderReLine;
        }
        if (this.isProjectSale && this.otherPartyContactInfo) {
            return this.otherPartyContactInfo.reline;
        } else if (!this.isProjectSale) {
            return this.clientReLine;
        } else {
            return '';
        }
    }

    getPurchaserReLineForProjectSale(): string {
        if (this.isProjectSale && this.otherPartyContactInfo) {
            return this.otherPartyContactInfo.reline;
        }
        return "";
    }

    getMatterAddress(): string {
        if (this.isProjectSale || this.isCustomMatter()) {
            return this.legalDescriptionForSearch;
        }

        return this.getMatterPropertyAddress();
    }

    getMatterPropertyAddress(): string {
        return MatterPropertyUtil.getMatterPropertyAddress(this);
    }

    getSubjectPropertyAddress(): Address {
        return Array.isArray(this.matterProperties) && this.matterProperties.length > 0 && this.matterProperties[0].address;
    }

    getlawClerkName(): string {
        return this.getPersonInitialsByMatterParticipantRole('LAWCLERK');
    }

    getlawSolicitorName(): string {
        return this.getPersonInitialsByMatterParticipantRole('SOLICITOR');
    }

    getAssigneeName() : string {
        return this.getPersonInitialsByMatterParticipantRole(MatterParticipantRoleTypes.OPPORTUNITY_ASSIGNEE);
    }

    getPersonInitialsByMatterParticipantRole(matterParticipantRole: MatterParticipantRole) : string {
        let surname: MatterParticipant[] = this.matterParticipants;
        if (surname.length > 0) {
            let surnameToDisplay: any = _.filter(surname, {'matterParticipantRole': matterParticipantRole});
            if (surnameToDisplay.length > 0 && surnameToDisplay[0].contact.contactName) {
                if(matterParticipantRole == MatterParticipantRoleTypes.OPPORTUNITY_ASSIGNEE){
                    /*
                    * Assignee displays the initials of the assigned staff member,
                    * if assignee has a business role of "Other" and does not have initials it will display their last name.
                    * */
                    return surnameToDisplay[0].contact.contactName.initials ? surnameToDisplay[0].contact.contactName.initials : surnameToDisplay[0].contact.contactName.lastName;
                } else {
                    return surnameToDisplay[0].contact.contactName.initials;
                }

            } else {
                return ' ';
            }
        } else {
            return ' ';
        }
    }

    public reCalculateClientReLine(): void {

        let reLineNames: string[] = [];

        this.mainClients.forEach((matterParticipant: MatterParticipant) => {
            let nameForReLine: string = matterParticipant.contact.reLineName;
            if (!reLineNames.some(x => x === nameForReLine)) {
                reLineNames.push(nameForReLine);
            }
        });
        // No matter if lost data information, limit maxlength="75" for Client's RE: Line according to BA suggestion
        this.clientReLine = reLineNames.join('/').slice(0, 75);
        this.reCalculateTrustLedgerPurchaserLine();

    }


    public reCalculateLenderReLine(mortgage: Mortgage): void {
        if (mortgage) {
            let reLineNames: string[] = [];
            let mortgageeType = mortgage.isMortgageePrivateLender() ? 'PRIVATE_LENDER' : 'MORTGAGEE';
            if (this.matterParticipants && this.matterParticipants.length > 0) {
                this.matterParticipants.filter(item => item.mortgageId == mortgage.id && item.matterParticipantRole == mortgageeType).forEach((matterParticipant: MatterParticipant) => {
                    let nameForReLine: string = matterParticipant.contact.reLineName;
                    if (!reLineNames.some(x => x === nameForReLine)) {
                        reLineNames.push(nameForReLine);
                    }
                });
            }
            mortgage.lenderReline = reLineNames.join('/').slice(0, 75);
        }
    }

    public reCalculateTrustLedgerPurchaserLine(): void {
        MatterTrustLedgerUtil.reCalculateTrustLedgerPurchaserLine(this);
    }

    public reCalculateTrustLedgerReceivedFromPurchaserLine(): void {
        MatterTrustLedgerUtil.reCalculateTrustLedgerReceivedFromPurchaserLine(this);
    }

    public reCalculateTrustLedgerReceivedDepositHeldInTrust(): void {
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.reCalculateTrustLedgerReceivedDepositHeldInTrustForInterimFinal();
        }
    }

    public reCalculateTrustLedgerPaidToRealEstateBroker(): void {
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.reCalculateTrustLedgerPaidToRealEstateBrokerForInterimFinal();
        }
    }

    public updateExcessDepositInTrustLedger(): void {
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.updateExcessDepositInTrustLedger();
        }
    }

    getExistingMortgageIndex(existingMtg: Mortgage): number {
        if (!existingMtg) {
            return -1;
        }
        let index = this.existingMortgages.findIndex(item => item.id == existingMtg.id)
        return index >= 0 ? (Number(index) + 1) : index;
    }

    public reCalculateTrustLedgerMortgageeLineByMortgage(mortgage: Mortgage): void {
        MatterTrustLedgerUtil.reCalculateTrustLedgerMortgageeLineByMortgage(this, mortgage);
    }

    public getExistingMortgagesToBeDischargedBridge(): Mortgage[] {
        return Array.isArray(this.existingMortgages)
            ? this.existingMortgages.filter(existingMtg => existingMtg.isMortgageDispositionDischarged() || existingMtg.isMortgageDispositionTypeBridgeFinancing())
            : [];
    }

    public getExistingMortgagesToBeDischarged(): Mortgage[] {
        let toBeDischargedExistingMtgs: Mortgage[] = [];
        if (Array.isArray(this.existingMortgages) && this.existingMortgages.length > 0) {
            toBeDischargedExistingMtgs = this.existingMortgages.filter(existingMtg => existingMtg.isMortgageDispositionDischarged())
        }
        return (Array.isArray(toBeDischargedExistingMtgs) && toBeDischargedExistingMtgs.length > 0) ? toBeDischargedExistingMtgs : [];
    }

    public updateValueForExistingDischargedMtgWithPayoutEqualsBalance(updatedAmt: number): void {
        let existingMtgWithPayoutEqualsBalance: Mortgage = this.getExistingDischargedMtgWithPayoutEqualsBalance();
        if (existingMtgWithPayoutEqualsBalance && existingMtgWithPayoutEqualsBalance.mortgagePayout) {
            existingMtgWithPayoutEqualsBalance.mortgagePayout.amountPayableToDischarge = updatedAmt;
            existingMtgWithPayoutEqualsBalance.amountPayableToDischarge = updatedAmt;
        }
    }

    public getExistingDischargedMtgWithPayoutEqualsBalance(): Mortgage {
        if (this.hasExistingMortgageChargedAndPayoutEqualsTrustLedgerBalance()) {
            return this.getExistingMortgagesToBeDischarged().find(dischargedMtg => dischargedMtg.mortgagePayout && dischargedMtg.mortgagePayout.payoutEqualsTrustBalance)
        }
        return null;
    }

    public hasExistingMortgageChargedAndPayoutEqualsTrustLedgerBalance(): boolean {
        return (this.isMatterProvinceABorMBorSK
            && (this.isSale || this.isMortgage)
            && this.paymentAmountsDisplayed != null
            && !this.paymentAmountsDisplayed)//if first group conds all met, then unity will allow to choose 'Same as TL balance'.
            && this.hasExistingMortgageChargedAndPayoutEqualsBalance();
    }

    public hasExistingMortgageChargedAndPayoutEqualsDirectionToPayBalance(): boolean {
        return (this.isMatterProvinceABorMBorSK
            && (this.isSale || this.isMortgage)
            && this.paymentAmountsDisplayed != null
            && this.paymentAmountsDisplayed)//if first group conditions all met, then unity will allow to choose 'Same as Direction To Pay balance'.
            && this.hasExistingMortgageChargedAndPayoutEqualsBalance();
    }

    public getMtgIdxForExistingDischargedMtgWithPayoutEqualsBalance(): number {

        const dischargedMtg: Mortgage = this.getExistingDischargedMtgWithPayoutEqualsBalance();
        return dischargedMtg ? this.getExistingMortgageIndex(dischargedMtg) : -1;
    }

    //payout equals balance includes 'Trust Ledger' balance OR 'Direction To Pay' balance
    public hasExistingMortgageChargedAndPayoutEqualsBalance(): boolean {
        return this.getExistingMortgagesToBeDischarged().some(dischargedMtg => dischargedMtg.mortgagePayout && dischargedMtg.mortgagePayout.payoutEqualsTrustBalance);
    }

    public hasExistingMortgageChargeBridgedAndPayoutEqualsBalance(): boolean {
        return this.getExistingMortgagesToBeDischargedBridge().some(dischargedMtg => dischargedMtg.mortgagePayout && dischargedMtg.mortgagePayout.payoutEqualsTrustBalance);
    }

    public getExistingDischargedBridgeMtgWithPayoutEqualsBalance(): Mortgage {
        if (this.hasExistingMortgageChargeBridgedAndPayoutEqualsBalance()) {
            return this.getExistingMortgagesToBeDischargedBridge().find(dischargedMtg => dischargedMtg.mortgagePayout && dischargedMtg.mortgagePayout.payoutEqualsTrustBalance)
        }
        return null;
    }

    public reCalculateTrustLedgerPrivateLenderLineByMortgage(mortgage: Mortgage): void {
        MatterTrustLedgerUtil.reCalculateTrustLedgerPrivateLenderLineByMortgage(this, mortgage);
    }

    public reCalculateTrustLedgerPrivateLenderLine(mortgage: Mortgage): void {
        MatterTrustLedgerUtil.reCalculateTrustLedgerPrivateLenderLine(this, mortgage);
    }

    public reCalculateOtherPartyReLine(): void {

        let reLineNames: string[] = [];
        let otherPartyParticipants: MatterParticipant[];

        //Use offerors for reline if no purchasers exist in Project Sale matter
        if (this.isProjectSale && this.isMatterProvinceON && !this.otherSideClients.length && this.offerors.length) {
            otherPartyParticipants = this.offerors;
        } else {
            otherPartyParticipants = this.otherSideClients;
        }

        otherPartyParticipants.forEach((matterParticipant: MatterParticipant) => {
            let nameForReLine: string = matterParticipant.contact.reLineName;
            if (!reLineNames.some(x => x === nameForReLine)) {
                reLineNames.push(nameForReLine);
            }
        });

        if (!this.otherPartyContactInfo) {
            this.createOtherPartyContactInfo();
        }
        // No matter if lost data information, limit maxlength="75" for Client's RE: Line according to BA suggestion
        this.otherPartyContactInfo.reline = reLineNames.join('/').slice(0, 75);
        this.reCalculateTrustLedgerReceivedFromPurchaserLine();
    }

    public sortMatterParticipants() {
       MatterParticipantUtil.sortMatterParticipants(this);
    }

    public resetMatterContactInfo(): void {
        if (this.matterContactInfo) {
            this.matterContactInfo.faxNumber = null;
            this.matterContactInfo.phoneNumberSummary = null;
            this.matterContactInfo.email = null;
            this.matterContactInfo.preClosingAddress = new Address();
            if (this.isPurchase) {
                //this.matterContactInfo.postClosingAddress.sameAsAddressTypeCode = AddressTypes.mailing;
                //story DPPMP-7446, the following three fields not reset even all the purchasers are deleted
                // this.matterContactInfo.envelopeSalutation = null;
                // this.matterContactInfo.envelopeSalutationContinued = null;
                // this.matterContactInfo.dearText = null;
            } else {
                //Reset the address for sale and mortgage matters
                this.matterContactInfo.postClosingAddress.update(new Address());
                this.matterContactInfo.postClosingAddress.addressTypeCode = AddressTypes.postClosingAddress;
                this.matterContactInfo.postClosingAddress.sameAsAddressTypeCode = AddressTypes.manuallyEntered;
            }
        }
    }

    public resetVendorContactInfo(): void {
        if (this.vendorContactInfo) {
            this.vendorContactInfo.reline = null;
        }
    }

    resetOtherPartyContactInfo(): void {
        if (this.otherPartyContactInfo) {
            this.otherPartyContactInfo.reline = null;
        }
    }

    get valid(): boolean {
        return this.validationErrors.length <= 0;
    }

    getMortgageIndexOrdinal(mortgageId: number): string {
        let index = this.mortgages.findIndex(item => item.id == mortgageId);
        if (index >= 0) {
            return Utils.getOrdinal(Number(index) + 1);
        }
        else {
            return '';
        }
    }


    getMortgageIndexById(mortgageId: number): number {
        return this.mortgages.findIndex(item => item.id == mortgageId);
    }

    getExistingMortgageIndexById(mortgageId: number): number {
        return this.existingMortgages.findIndex(item => item.id == mortgageId);
    }

    getExistingMortgageDisplayOrder(mortgage: Mortgage): string {
        let displayOrder: string;
        const index = this.getExistingMortgageIndexById(mortgage.id);
        if (index < 0) {
            return null;
        }

        displayOrder = Utils.getDisplayOrder(index + 1);

        return displayOrder;
    }

    getMortgagePrincipalByMortgageId(mortgageId: number) {
        let mrtg = this.mortgages.find(item => item.id == mortgageId);
        return (mrtg && mrtg.mortgageTerm && mrtg.mortgageTerm.principal) ? mrtg.mortgageTerm.principal : 0;
    }

    sortMortgagesMp(matterParticipants: MatterParticipant[]): MatterParticipant[] {
        return matterParticipants.sort((mp1, mp2) => mp1.mortgageId - mp2.mortgageId);
    }

    getMortgageById(id: number): Mortgage {
        return id ? this.mortgages.find((mort: Mortgage) => {
            return mort.id == id;
        }) : null;
    }

    getExistingMortgageById(id: number): Mortgage {
        return id ? this.existingMortgages.find((mort: Mortgage) => {
            return mort.id == id;
        }) : null;
    }

    getMortgagePriorityById(mrtg: Mortgage): number {
        if (mrtg && mrtg.mortgagePriority > 0 && mrtg.isUnityNewMortgage()) {
            return mrtg.mortgagePriority;
        } else if (mrtg && this.existingMortgageIndex(mrtg) > -1 && mrtg.isExistingMortgage()) {
            return this.existingMortgageIndex(mrtg) + 1;
        } else {
            return 1;
        }
    }

    get validationErrors(): string[] {
        return MatterErrorUtil.getValidationErrors(this);
    }

    public isClosed(): boolean {
        return this.closed === DpBooleanValueTypes.YES;
    }

    updateMatterPropertyFields(): void {

        for (let i = 0; i < this.matterProperties.length; i++) {

            this.matterProperties[i].propertyTaxesSummaryOption = this.matterProperties[i].propertyTaxesSummary ? true : false;
            this.matterProperties[i].addressOption = this.matterProperties[i].address && !this.matterProperties[i].address.isEmpty ? true : false;
        }
    }

    public deleteMatterProperty(selectedMatterProperty: MatterProperty): void {
        let matterPropertyIndex: number = _.findIndex(this.matterProperties, matterProperty => matterProperty === selectedMatterProperty);
        if (matterPropertyIndex >= 0) {
            this.matterProperties.splice(matterPropertyIndex, 1);
        }
    }

    removeAdditionalMatterProperty(): void {
        let additionalMatterPropertyList: MatterProperty[] = this.additionalMatterProperty;
        for (let i = 0; i < additionalMatterPropertyList.length; i++) {
            this.deleteMatterProperty(additionalMatterPropertyList[i]);
        }
    }

    get isPartialPinAllowed(): boolean {
        return this.isMatterProvinceON && (this.isTemplateMatterForMassUpdate || this.isProjectSale);
    }

    removeAdditionalMatterPropertyWithoutPin(): void {
        MatterPropertyUtil.removeAdditionalMatterPropertyWithoutPin(this);
    }

    get matterPropertyWithCondo(): MatterProperty {
        return _.find(this.matterProperties, matterProperty => matterProperty.isCondominium != undefined);
    }

    hasPropertyJurisdiction(): boolean {
        return !!(this.matterPropertyWithCondo && this.matterPropertyWithCondo.jurisdiction && this.matterPropertyWithCondo.jurisdiction.id);
    }

    updateMatterPropertyWithCondoPurchasePrice(purchasePrice: number, soaPurchasePrice: number, purchasePriceType: string) {
        if (this.matterPropertyWithCondo) {
            this.matterPropertyWithCondo.purchasePrice = purchasePrice;
            this.matterPropertyWithCondo.soaPurchasePrice = soaPurchasePrice;
            this.matterPropertyWithCondo.purchasePriceType = purchasePriceType;
        }
    }

    calculateConfigDate(closingDate):string{
        if(this.requisitionDateCalculationConfig && !this.requisitionDate && Utils.isValidDate(closingDate)){
            let outputDate;// = moment();
            let currentDate = moment();
            if(this.requisitionDateCalculationConfig.isBeforeSelected()){
                outputDate = moment(closingDate, "YYYY/MM/DD").subtract(this.requisitionDateCalculationConfig.duration , 'days');
            }else{
                outputDate = moment(closingDate, "YYYY/MM/DD").add(this.requisitionDateCalculationConfig.duration , 'days');
            }
           return  moment(outputDate).isBefore(currentDate) ? currentDate.format("YYYY/MM/DD") : outputDate.format("YYYY/MM/DD");
        }
    }

    isConfigurationSourceDateEmptyOnMatter(rawDate){
        if(this.requisitionDateCalculationConfig && this.requisitionDateCalculationConfig.sourceDate == DateTypes.OCCUPANCY_DATE ){
            return MatterCleanUpUtil.isOccupancyDateVisible(this) ? ((!this.occupancyDate  || !Utils.isValidDate(this.occupancyDate)) &&  Utils.isValidDate(rawDate))   : false;
        }else {
            return  ((!this.matterCloseDate || !Utils.isValidDate(this.matterCloseDate)) && Utils.isValidDate(rawDate)) ;
        }
    }

    /**
     * Checks whether the matter has a past (or today) closing date. This drives the availability of other closing related fields
     */
    isClosingDateAvailable(): boolean {
        return (this.matterCloseDate && !this.matterCloseDate.startsWith('/') && !this.matterCloseDate.endsWith('/') && this.matterCloseDate.indexOf('//') < 0)
    }

    /**
     * Checks whether the matter has a past (or today) closing date.
     * If excludeToday is true, checks whether the matter has a past ( and exclude today)
     */
    hasPastClosingDate(excludeToday?: boolean): boolean {
        if (!this.matterCloseDate || this.matterCloseDate.startsWith('/') || this.matterCloseDate.endsWith('/') || this.matterCloseDate.indexOf('//') > -1) {
            return false;
        }

        let today: Date = new Date();
        let month = today.getMonth() + 1;
        let todayCompare = today.getFullYear() + '/' + (month < 10 ? '0' + month : '' + month) + '/' + (today.getDate() < 10 ? '0' + today.getDate() : '' + today.getDate());
        let ret = this.matterCloseDate.localeCompare(todayCompare);
        if(excludeToday) {
            return this.matterCloseDate.localeCompare(todayCompare) < 0;
        } else {
            return this.matterCloseDate.localeCompare(todayCompare) < 1;
        }
    }


    updateMatterPropertyWithCondo(matterProperty: MatterProperty) {
        let matterPropertyIndex: number = _.findIndex(this.matterProperties, matterProperty => matterProperty.isCondominium != undefined && matterProperty.isCondominium != null);
        this.matterProperties[matterPropertyIndex] = matterProperty;
    }

    get additionalMatterProperty(): MatterProperty[] {
        return _.filter(this.matterProperties, matterProperty => !matterProperty.mortgageId && (matterProperty.isCondominium === undefined || matterProperty.isCondominium === null));
    }

    get nonBlanketMatterProperty(): MatterProperty[] {
        return _.filter(this.matterProperties, matterProperty => !matterProperty.mortgageId);
    }

    getBlanketMortgageProperties(mortgageId:number): MatterProperty[] {
        return _.filter(this.matterProperties, matterProperty => matterProperty.mortgageId == mortgageId);
    }

    isPropertyCondo(): boolean {
        return (this.matterPropertyWithCondo && this.matterPropertyWithCondo.isPropertyCondominium())
    }

    getUnitLevelPlanFieldLabelForPS(): string {
        if (this.isPropertyCondo()) {
            return (this.matterPropertyWithCondo.condominiumPlans && this.matterPropertyWithCondo.condominiumPlans.length > 1) ? projectConsts.unitLvlPlanFieldLbl.unitLevelPlan : projectConsts.unitLvlPlanFieldLbl.unitLevel;
        }
        return '';
    }

    isPropertyParcelOfTiedLand(): boolean {
        return (this.matterPropertyWithCondo && this.matterPropertyWithCondo.isPropertyParcelOfTiedLand());
    }

    get isCondoCorporationTabEnabled(): boolean {
        return (this.isPropertyCondo() || this.isPropertyParcelOfTiedLand() || this.isMatterProvinceBC);
    }

    isPropertyTeranetJurisdictionToronto(): boolean {
        let matterProperty = this.matterPropertyWithCondo;
        if (matterProperty && matterProperty.jurisdiction && matterProperty.jurisdiction.jurisdictionName &&
            matterProperty.jurisdiction.jurisdictionName.toLowerCase() === 'toronto') {
            return true;
        }
        else if (matterProperty && matterProperty.city && matterProperty.city.toLowerCase() === 'toronto') {
            return true;
        }
        else {
            return false;
        }
    }

    get jurisdictionName(): string {
        let matterProperty = this.matterPropertyWithCondo;
        if (matterProperty && matterProperty.jurisdiction && matterProperty.jurisdiction.jurisdictionName) {
            return matterProperty.jurisdiction.jurisdictionName;
        }
        else {
            return '';
        }
    }

    revertMatterTypeForCustomMatter(): void {
        if (this.isCustomMatter()) {
            this.matterType = 'CUSTOM';
        }
    }

    changeCustomMatterToPurchaseMatter(): void {
        if (this.isCustomMatter()) {
            this.matterType = 'PURCHASE';
        }
    }

    changeDischargeMatterToMortgageMatter():void {
        if(!!this.isMatterTypeDischarge){
            this.matterType = 'MORTGAGE';
        }
    }
    revertMatterTypeForDischargeMatter(): void{
        if(!!this.isMatterTypeDischarge){
            this.matterType = 'DISCHARGE';
        }
    }

    updatePurchaserInformationSection(newHomePstRebateForm: NewHomePstRebateForm) {
        if(newHomePstRebateForm && Array.isArray(newHomePstRebateForm.newHomePurchasers)
            && newHomePstRebateForm.newHomePurchasers.length > 0 && newHomePstRebateForm.newHomePurchasers[0].purchaserId) {
            //If the purchases of Selected a Purchaser is removed in matter, the field should be null
            const purchasers : MatterParticipant[] = this.isPurchase ? this.mainClients : this.otherSideClients;
            const purchaser : MatterParticipant = purchasers.find(item => item.matterParticipantId == newHomePstRebateForm.newHomePurchasers[0].purchaserId);
            if(!purchaser) {
                newHomePstRebateForm.updatePurchaserInforByPurchaserContact(null);
            }
        }
    }

    updateSinglePurchaserField(newHomePstRebateForm: NewHomePstRebateForm) {
        if(newHomePstRebateForm.rebateAssignmentType == RebateAssignmentTypes.A_SINGLE_PURCHASER){
            const purchasers : MatterParticipant[] = this.isPurchase ? this.mainClients : this.otherSideClients;
            const purchaser : MatterParticipant = purchasers.find(item => item.matterParticipantId == newHomePstRebateForm.purchaserId);
            if(!purchaser) {
                newHomePstRebateForm.purchaserId = null;
            }
        }
    }



    cleanUpMatterBeforeSaving(matterService: MatterService): void {
        //If the purchasersCapacity is null, it will hit 500 error
        //I am not sure if this place is the best place. Maybe we can put it in new Matter constructor
        this.initMainClientsCapacity();
        if (this.isProjectSale && this.isMatterProvinceAB) {
            this.initMainDevelopersCapacity();
        }
        //TODO: NEED TO KNOW WHETHER THIS CLEANUP METHOD NEEDS TO BE INVOKED FIRST OR NOT
        this.removeAdditionalMatterPropertyWithoutPin();
        this.copyCalculatedAmountFromSoaWrapperToMatter();
        if (this.isSale) {
            this.copyBrokerCommissionTabValuesForSaleMatter();
        }
        this.sortMatterParticipants();
        this.updateUndertakings(matterService.undertakingsConfigService);
        // if (!this.statementOfAdjustmentPayable && documentProfileCache) {
        //     this.resetStatementOfAdjustmentPayable(documentProfileCache.cachedDocumentProfile, documentProfileCache.cachedDefaultDocumentProfile);
        // }
        // End...

        MatterCleanUpUtil.cleanUpConsiderationLtt(this);

        MatterCleanUpUtil.cleanUpMortgages(this);

        MatterCleanUpUtil.cleanUpMatterParticipant(this);

        MatterCleanUpUtil.cleanUpMatterOpening(this);

        MatterCleanUpUtil.cleanUpMatterInfo(this);

        MatterCleanUpUtil.cleanUpBankAccount(this);

        MatterCleanUpUtil.cleanUpClosingData(this);

        MatterCleanUpUtil.cleanUpStatementOfAccount(this , matterService);

        MatterCleanUpUtil.cleanUpCondoCorporation(this);

        MatterCleanUpUtil.cleanUpFireInsurance(this);

        MatterCleanUpUtil.cleanUpReportToPurchaser(this);

        MatterCleanUpUtil.cleanUpUndertaking(this);

        MatterCleanUpUtil.cleanUpMatterProperties(this);

        MatterCleanUpUtil.cleanUpMatterContactInfo(this);

        MatterCleanUpUtil.cleanUpOtherPartyContactInfo(this);

        MatterCleanUpUtil.cleanUpOthers(this);

        MatterCleanUpUtil.cleanUpSupplementalTasks(this);

        MatterCleanUpUtil.cleanUpMatterTaxRate(this);

        MatterCleanUpUtil.cleanUpBrokerCommission(this);

        MatterCleanUpUtil.cleanUpDirectionReFunds(this);

        MatterCleanUpUtil.cleanUpTitleInsurance(this);

        MatterCleanUpUtil.cleanUpPstRebateForm(this);

        MatterStatementAdjustmentUtil.updateStatementOfAdjustment(this, matterService);

        MatterCleanUpUtil.cleanUpERegistrationForms(this);

        MatterCleanUpUtil.cleanUpSoaTrustLedgerCollectionMatterRef(this);

        //this.matterTitleInsurance = null; //matter title insurance is read-only and not included when saving matter

        MatterCleanUpUtil.cleanUpMatterEvents(this);

        MatterCleanUpUtil.cleanUpProjectTransferors(this);
    }

    copyOtherSolicitorLawFirmValuesForContactInfo(otherPartyContactInfo: ContactInfo, solicitorParticipant: MatterParticipant, lawFirmParticipant: MatterParticipant) {
        if (otherPartyContactInfo) {
            if (solicitorParticipant && solicitorParticipant.contact) {
                if (solicitorParticipant.contact.contactName) {
                    otherPartyContactInfo.solicitorName = solicitorParticipant.contact.contactName.surnameLastFullName;
                }
                otherPartyContactInfo.solicitorId = solicitorParticipant.contact.sourceContactId;
            }

            if (lawFirmParticipant && lawFirmParticipant.contact) {
                otherPartyContactInfo.firmName = lawFirmParticipant.contact.legalFirmName;
                otherPartyContactInfo.firmId = lawFirmParticipant.contact.sourceContactId;
                otherPartyContactInfo.barristerSolicitor = lawFirmParticipant.contact.barristerSolicitor;
            }
        }
    }

    getMmsDealIdFromFctEmp(): string {
        let fctMmsMortgage: Mortgage[] = this.mortgages && this.mortgages.filter(mortgage => mortgage.isEmpMortgage() && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.isFctMMSInstruction);
        if (Array.isArray(fctMmsMortgage) && fctMmsMortgage.length > 0) {
            return fctMmsMortgage[0].stewartAssystMortgageInstruction && fctMmsMortgage[0].stewartAssystMortgageInstruction.sourceFctUrn;
        }
        return null;
    }

    //Matter participant contact snapshot should not refer to a parent contact.
    removeParentContactReferenceFromMatterParticipant(contact: Contact): void {
        //Currently we are removing any parent reference from a contact snapshot otherwise snapshot will be also aggregated by original parent.
        contact.legalFirmId = null;
        contact.organizationId = null;
    }

    parseAddressForMatterParticipant(mp: MatterParticipant) {
        if (mp && mp.contact && mp.contact.address) {
            mp.contact.address.forEach(address => AddressUtil.populateParsedData(address));
        }
    }

    isFlaApplicableForEstate(participant): boolean{
        return this.isFlaApplicableForEstateGender() && participant && participant.isContactGenderEstate();
    }

    isFlaApplicableForEstateGender(): boolean{
        return (this.matterType == MatterTypesValue.PURCHASE || this.matterType == MatterTypesValue.SALE )
            && this.isMatterProvinceON;
    }

    updateMortgageeInterestOnNotedPolicy(mortgage: Mortgage): void {
        if (!this.isSale && this.getMatterParticipantsByMortgage(mortgage).length == 0) {
            //For purchase matter if there are no mortgagees then mortgageeInterestNotedOnPolicy is not applicable therefore setting it to null.
            mortgage.mortgageeInterestNotedOnPolicy = null;
        } else {
            //The below check covers the case when new mortgage or mortgagee added then assigning blank value to mortgageeInterestNotedOnPolicy
            if (!mortgage.mortgageeInterestNotedOnPolicy) {
                mortgage.mortgageeInterestNotedOnPolicy = 'QUESTION';
            }
        }
    }

    addMatterParticipant(contact: Contact, withCloning: boolean, participantRole: MatterParticipantRole, mortgageId?: number, parentParticipant?: MatterParticipant, index?: number): MatterParticipant {
        return MatterParticipantUtil.addMatterParticipant(this, contact, withCloning, participantRole, mortgageId, parentParticipant, index);
    }

    createUniqueMatterParticipant(sourceContact: Contact, participantRole: MatterParticipantRole): MatterParticipant {
        return MatterParticipantUtil.createUniqueMatterParticipant(this, sourceContact, participantRole);
    }

    createMatterParticipant(participantRole: MatterParticipantRole, mortgageId?: number): MatterParticipant {
        return MatterParticipantUtil.createMatterParticipant(this, participantRole, mortgageId);
    }

    removeMatterParticipant(selectedParticipant: MatterParticipant): void {
        if (selectedParticipant) {
            this.matterParticipants = _.filter(this.matterParticipants, mp => mp.matterParticipantId !== selectedParticipant.matterParticipantId);
        }
    }


    removeReconciledBorrowersByMatterParticipant(mp: MatterParticipant, removedReconciledBorrowerList?: RemovedReconciledBorrowerRelatedData[]): void {
        if (this.mortgages) {
            this.mortgages.filter(m => m.isEmpMortgage())
                .filter(empMortgage => empMortgage.stewartAssystMortgageInstruction && empMortgage.stewartAssystMortgageInstruction.reconciledBorrowers)
                .map(empMortgage => empMortgage.stewartAssystMortgageInstruction)
                .forEach(mortgageInstruction => {
                    let borrowerMapping = mortgageInstruction.reconciledBorrowers.find(borrowerMapping => borrowerMapping.matterParticipantId == mp.matterParticipantId);
                    if(Array.isArray(removedReconciledBorrowerList) && borrowerMapping){
                        let removedReconciledBorrower = new RemovedReconciledBorrowerRelatedData();
                        removedReconciledBorrower.reconciledBorrower = new BorrowerMapping(borrowerMapping);
                        removedReconciledBorrower.sourceContactId = mp && mp.contact && mp.contact.sourceContactId;
                        removedReconciledBorrower.stewartMortgageInstruction = mortgageInstruction;
                        removedReconciledBorrowerList.push(removedReconciledBorrower);
                    }
                    (<any>mortgageInstruction.reconciledBorrowers).remove(borrowerMapping);
                })

        }

    }

    //This method checks if a participant with same role already exists then it overrides the contact in that else create a new participant
    setMatterParticipant(contact: Contact, withCloning: boolean, participantRole: MatterParticipantRole, mortgageId?: number): MatterParticipant {
        let participant: MatterParticipant = this.findMatterParticipant(participantRole, mortgageId);

        if (participant) {
            participant.enrolContactToParticipant(contact, withCloning);
        } else {
            participant = this.addMatterParticipant(contact, withCloning, participantRole, mortgageId);
        }
        return participant;
    }

    findMatterParticipant(participantRole: MatterParticipantRole, mortgageId?: number): MatterParticipant {
        if (this.matterParticipants) {
            return _.find(this.matterParticipants,
                chr => chr.matterParticipantRole === participantRole && (!mortgageId || chr.mortgageId === mortgageId));
        }
    }

    // cleanup Bank Account



    getClosingDate(): string {
        return this.isAdjustAsAtSpecify() ? (this.isAdjustmentStatusModeFinal ? this.adjustAsAtClosingDate : this.adjustAsAtClosingDateInterim) :
            (this.isMatterAdjustAsAtOccupancyDate() ? this.matterOccupancyDate : this.matterCloseDate);
    }

    getFinalClosingDate(): string {
        return this.adjustAsAtClosingDateFlag === 'SPECIFY' ? this.adjustAsAtClosingDate : this.matterCloseDate;
    }

    getInterimClosingDate(): string {
        return this.adjustAsAtClosingDateFlagInterim === 'SPECIFY' ? this.adjustAsAtClosingDateInterim : this.matterCloseDate;
    }

    getInterimOccupancyDate(): string {
        return this.adjustAsAtClosingDateFlagInterim === 'SPECIFY' ? this.adjustAsAtClosingDateInterim : this.matterOccupancyDate;
    }

    getClosingDateForFinalAdj(): string {
        return this.isFinalAdjustAsAtSpecify() ? (this.adjustAsAtClosingDate) :
            (this.isMatterAdjustAsAtOccupancyDate() ? this.matterOccupancyDate : this.matterCloseDate);
    }

    getClosingDateForInterimAdj(): string {
        return this.isInterimAdjustAsAtSpecify() ? (this.adjustAsAtClosingDateInterim) :
            (this.isMatterAdjustAsAtOccupancyDate() ? this.matterOccupancyDate : this.matterCloseDate);
    }

    getInterimAdjustmentDate():string{
        return this.isInterimAdjustAsAtSpecify() ? (this.adjustAsAtClosingDateInterim) :
            (this.isMatterAdjustAsAtOccupancyDateForInterim() ? this.matterOccupancyDate : this.matterCloseDate);
    }


    reorderParticipant(selectedParticipant: MatterParticipant, moveUp: boolean): void {
        MatterParticipantUtil.reorderParticipant(this, selectedParticipant, moveUp);
    }


    reorderParticipantByMortgage(selectedParticipant: MatterParticipant, moveUp: boolean, mortgage: Mortgage): void {
        MatterParticipantUtil.reorderParticipantByMortgage(this, selectedParticipant, moveUp, mortgage);
    }


    createNewConsiderationLtt(): void {
        MatterStatementAdjustmentUtil.createNewConsiderationLtt(this);
    }

    createMatterPropertyWithCondo(): void {
        if (this.matterProperties) {
            this.matterProperties = [];
        }

        this.matterProperties.push(MatterProperty.createDefaultMatterProperty());
    }


    updateMatterPropertyWithCondoAdjustmentAmount(isPaysForDateOfClosingVendor?: boolean): void {
        let matterPropertyWithCondo = this.matterPropertyWithCondo;
        let closingDate = this.getClosingDate();
        if (matterPropertyWithCondo && matterPropertyWithCondo.matterTax &&
            matterPropertyWithCondo.matterTax.totalTaxes && Number(matterPropertyWithCondo.matterTax.totalTaxes) > 0) {
            matterPropertyWithCondo.matterTax.vendorShareAmount = matterPropertyWithCondo.matterTax.calculateVendorShare(2, closingDate, isPaysForDateOfClosingVendor, this.isProjectProportionateShare , undefined, this.provinceCode);
        }
    }

    get projectTaxAdjustmentConfig(): ProjectTaxAdjustmentConfig {
        if (!this.project || !this.project.projectAdjustmentConfig) {
            return null;
        }
        if (this.project.isStatmentOfAdjustmentInterim()) {
            return this.isAdjustmentStatusModeFinal && !this.project.projectAdjustmentConfig.taxesSameAsInterim
                ? this.project.projectAdjustmentConfig.finalTaxAdjustment
                : this.project.projectAdjustmentConfig.interimTaxAdjustment;
        } else {
            return this.project.projectAdjustmentConfig.finalTaxAdjustment;
        }
    }

    get projectTaxAdjustmentConfigInterim(): ProjectTaxAdjustmentConfig {
        if (!this.project || !this.project.projectAdjustmentConfig) {
            return null;
        }
        if (this.project.isStatmentOfAdjustmentInterim()) {
            return this.project.projectAdjustmentConfig.interimTaxAdjustment;
        } else {
            return this.project.projectAdjustmentConfig.finalTaxAdjustment;
        }
    }

    get projectTaxAdjustmentConfigFinal(): ProjectTaxAdjustmentConfig {
        if (!this.project || !this.project.projectAdjustmentConfig) {
            return null;
        }
        if (this.project.isStatmentOfAdjustmentInterim()) {
            return this.project.projectAdjustmentConfig.taxesSameAsInterim
                ? this.project.projectAdjustmentConfig.interimTaxAdjustment
                : this.project.projectAdjustmentConfig.finalTaxAdjustment;
        } else {
            return this.project.projectAdjustmentConfig.finalTaxAdjustment;
        }
    }

    get isBaseTaxAdjustmentOnPercentageOfTaxesForProject(): boolean {
        return this.project && this.projectTaxAdjustmentConfig && this.projectTaxAdjustmentConfig.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfTaxesForProject;
    }

    get isBaseTaxAdjustmentEqualDivisionAmongUnits(): boolean {
        return this.project && this.projectTaxAdjustmentConfig && this.projectTaxAdjustmentConfig.taxAdjustmentType == BaseTaxAdjustmentOn.EqualDivisionAmongUnits;
    }

    get isProjectProportionateShare(): boolean {
        return this.project && this.projectTaxAdjustmentConfig && (this.isBaseTaxAdjustmentOnPercentageOfTaxesForProject || this.isBaseTaxAdjustmentEqualDivisionAmongUnits);
    }

    get isInterimProjectProportionateShare(): boolean {
        return this.project && this.projectTaxAdjustmentConfigInterim && (this.projectTaxAdjustmentConfigInterim.taxAdjustmentType === BaseTaxAdjustmentOn.percentageOfTaxesForProject || this.projectTaxAdjustmentConfigInterim.taxAdjustmentType === BaseTaxAdjustmentOn.EqualDivisionAmongUnits);
    }

    get isFinalProjectProportionateShare(): boolean {
        return this.project && this.projectTaxAdjustmentConfigInterim && (this.projectTaxAdjustmentConfigFinal.taxAdjustmentType === BaseTaxAdjustmentOn.percentageOfTaxesForProject || this.projectTaxAdjustmentConfigFinal.taxAdjustmentType === BaseTaxAdjustmentOn.EqualDivisionAmongUnits);
    }

    updateTaxAdjustmentsAfterSalePriceAdjustmentUpdate(interimTaxMultiplier: number) {
        MatterStatementAdjustmentUtil.updateTaxAdjustmentsAfterSalePriceAdjustmentUpdate(this, interimTaxMultiplier);
    }

    updatePropertyTaxAdjustmentTotalTaxFromProjectAdjustmentSettings(propertyTax: MatterTax, projectTaxAdjustmentConfig?: ProjectTaxAdjustmentConfig): void {
        if (!projectTaxAdjustmentConfig) {
            projectTaxAdjustmentConfig = this.project && this.projectTaxAdjustmentConfig;
        }
        if (propertyTax && propertyTax.adjustmentBasedOnPercentageOfTotalTaxes === 'YES' && projectTaxAdjustmentConfig) {
            this.updatePropertyTaxForProject(propertyTax, projectTaxAdjustmentConfig);
        }
    }


    updatePropertyTaxForProject(propertyTax: MatterTax, projectTaxAdjustmentConfig: ProjectTaxAdjustmentConfig): void {
        let propertyPurchasePrice: number = this.matterPropertyWithCondo.purchasePrice;
        if (this.considerationLtt && this.considerationLtt.salePriceAdjustment && this.considerationLtt.salePriceAdjustment.isInclusivePrice() && this.matterPropertyWithCondo && this.matterPropertyWithCondo.purchasePrice) {
            propertyPurchasePrice = this.considerationLtt.salePriceAdjustment.totalPriceWithTax();
        }
        if (projectTaxAdjustmentConfig && projectTaxAdjustmentConfig.taxAdjustmentType) {
            switch (projectTaxAdjustmentConfig.taxAdjustmentType) {
                case BaseTaxAdjustmentOn.percentageOfTaxesForProject:
                    propertyTax.totalTaxes = projectTaxAdjustmentConfig.totalTaxes;
                    propertyTax.purchaserPortionOfTotalTax = this.getPurchaserPortionOfTotalTaxes(projectTaxAdjustmentConfig ,propertyTax.purchaserPortionOfTotalTax);
                    propertyTax.vendorWillHavePaid = projectTaxAdjustmentConfig.totalVendorTaxesPaid;
                    break;
                case BaseTaxAdjustmentOn.percentageOfUnitPurchasePrice:
                    propertyTax.totalTaxes = Number(projectTaxAdjustmentConfig.unitPurchasePrice) / 100 * SoaRealtyTaxAdjustmentUtil.agreedSalePrice(this);
                    break;
                case BaseTaxAdjustmentOn.EqualDivisionAmongUnits:
                    propertyTax.totalTaxes = projectTaxAdjustmentConfig.totalTaxes;
                    propertyTax.purchaserPortionOfTotalTax = this.getPurchaserPortionOfTotalTaxes(projectTaxAdjustmentConfig,propertyTax.purchaserPortionOfTotalTax);
                    propertyTax.vendorWillHavePaid = projectTaxAdjustmentConfig.totalVendorTaxesPaid;
                    break;
                case BaseTaxAdjustmentOn.percentageOfUnitPurchasePriceIncludingCredits:
                    propertyTax.totalTaxes = Number(projectTaxAdjustmentConfig.unitPurchasePrice) / 100 * propertyPurchasePrice;
                    break;
            }
        }
    }


    updatePropertyTaxesOnSOAOnClosingDate(isPaysForDateOfClosingVendor?: boolean): void {
        let closingDate = this.getClosingDate();
        this.statementOfAdjustments.filter(item => item.isAnyPropertyTaxes()).forEach((item: StatementAdjustment) => {
            // originalPropertyTaxes(created from SubjectProperty tab) don't have a matterTax but a link to property's matterTax by the means of propertyMatterTaxId
            if (!item.isOriginPropertyTaxes() && item.matterTax) {
                this.updateTippInstallmentOnClosingDateChange(closingDate, item.matterTax);
                item.matterTax.vendorShareAmount = item.matterTax.calculateVendorShare(2, closingDate, isPaysForDateOfClosingVendor, this.isProjectProportionateShare, item.isTaxesBeginningOfYearUntilOccupancy(), this.provinceCode);
            }
            this.createUpdateAdjustmentPropertyTaxes(item);

            if (item.isOriginPropertyTaxes() && this.matterPropertyWithCondo && this.matterPropertyWithCondo.matterTax) {
                this.matterPropertyWithCondo.updatePropertyTaxes(this.matterPropertyWithCondo.matterTax,
                    this.matterPropertyWithCondo.matterTax.formattedAllTaxesAmount(this, InterimTaxMultiplier),
                    this.matterPropertyWithCondo.matterTax.formattedTrustAmount(), this.provinceCode);
            }
        });
    }

    updateTippInstallmentOnClosingDateChange(closingDate: string, matterTax: MatterTax) {
        if (this.isMatterProvinceAB && !matterTax.overrideTippPayment) {

            if (matterTax.tippInstallment) {
                for (let prop in matterTax.tippInstallment) {
                    if (matterTax.tippInstallment.hasOwnProperty(prop)) {
                        if (this.isMonthFieldDisabled(TippInstallmentConstants[prop], closingDate)) {
                            matterTax.tippInstallment[prop] = 0;
                        }
                    }
                }
                //2 pre-conditions for update <vendorWillHavePaid> from <totalTippInstallments>:
                // matterTax.hasPaidTaxesUnderTIPP == 'YES' && !matterTax.overrideTippPayment
                if (matterTax.hasPaidTaxesUnderTIPP == DpBooleanValueTypes.YES) {
                    matterTax.vendorWillHavePaid = matterTax.tippInstallment.totalTippInstallments;
                }
            }
        }
    }

    isMonthFieldDisabled(month: number, adjustmentDate: string): boolean {
        if (adjustmentDate) {
            var closingDate = moment(adjustmentDate, 'YYYY/MM/DD');
            var closingMonth = closingDate.format('M');
            return (month > Number(closingMonth));
        }
        return true;
    }

    updateLocalGender(): void {
        if (Array.isArray(this.matterParticipants)) {
            this.matterParticipants.forEach((participant: MatterParticipant) => {
                if (participant.contact) {
                    participant.contact.localGender = participant.contact.gender;
                }
            });
        }
    }

    private getDirectDepositInstructionByType(type: string): DirectDepositInstruction {
        return this.directDepositInstructions.find((item: DirectDepositInstruction) => {
            return item.directDepositType === type;
        })
    }

    get directDepositInstructionOtherParty(): DirectDepositInstruction {
        return this.getDirectDepositInstructionByType(this.otherSideDirectDepostClientType);
    }

    // get directDepositInstructionVendor() : DirectDepositInstruction {
    //     return this.getDirectDepositInstructionByType('VENDOR');
    // }

    get directDepositInstructionPurchaser(): DirectDepositInstruction {
        return this.getDirectDepositInstructionByType(this.mainClientType);
    }

    addDirectDepositInstruction(type: string): DirectDepositInstruction {
        let ddi = this.getDirectDepositInstructionByType(type);
        if (!ddi) {
            ddi = new DirectDepositInstruction();
            ddi.directDepositType = type;
            this.directDepositInstructions.push(ddi);
        }
        return ddi;
    }

    addDirectDepositInstructionOtherParty(): DirectDepositInstruction {
        let ddi = this.getDirectDepositInstructionByType(this.otherSideDirectDepostClientType);
        if (!ddi) {
            ddi = new DirectDepositInstruction();
            ddi.directDepositType = this.otherSideDirectDepostClientType;
            this.directDepositInstructions.push(ddi);
        }
        return ddi;
    }

    updateInterimTrustLedger(): void {
        MatterTrustLedgerUtil.updateInterimTrustLedger(this);
    }

    updateLTTOnStatementOfAccount(): void {
        // Sale and Mortgage matter don't include LTT
        if (this.isSale || this.isMortgage) {
            return;
        }

        this.updateLTT(this.soaTrustLedgerCollection);
        if(this.secondarySoaSheetsCollection) {
            this.secondarySoaSheetsCollection.forEach(collection => {
                this.updateLTT(collection);
            })
        }
    }

   private updateLTT(soaTrustLedgerCollection : SoaTrustLedgerCollection) : void {
        if (soaTrustLedgerCollection && this.considerationLtt && soaTrustLedgerCollection.soaConfig) {
            soaTrustLedgerCollection.addLTT();
            soaTrustLedgerCollection.updateOntarioLtt();
            soaTrustLedgerCollection.updateTorontoLtt();
            soaTrustLedgerCollection.updateOntarioTorontoLtt();
            soaTrustLedgerCollection.updateFeeBasedOnAllIncPrice();
            soaTrustLedgerCollection.updateF9ManitobaLtt();
            if(this.isMatterProvinceNS){
                this.calculateLandTransferTaxNS();
                soaTrustLedgerCollection.updateLttNS();
            }
            if(this.isMatterProvinceNB){
                this.calculateLandTransferTaxNB();
                soaTrustLedgerCollection.updateLttNB();
            }
        }
    }

    calculateLandTransferTaxNB() {
        if (this.propertyModel.landTransferTaxRateId) {
            const landTransferTaxRate = this.propertyModel.landTransferTaxRate;
            if (landTransferTaxRate) {
                if (Number(this.propertyModel.purchasePrice) > this.propertyModel.assessedValue) {
                    this.propertyModel.landTransferTax = Math.round(Number(this.propertyModel.purchasePrice) * landTransferTaxRate.taxRate / 100 * 100) / 100;
                    // this.propertyModel.landTransferTax =  Number(this.propertyModel.purchasePrice)* landTransferTaxRate.taxRate;
                } else if (this.propertyModel.assessedValue > Number(this.propertyModel.purchasePrice)) {
                    this.propertyModel.landTransferTax = Math.round(Number(this.propertyModel.assessedValue) * landTransferTaxRate.taxRate / 100 * 100) / 100;
                    // this.propertyModel.landTransferTax =  Number(this.propertyModel.assessedValue)* landTransferTaxRate.taxRate;
                } else {
                    this.propertyModel.landTransferTax = 0;
                }
            }
        }
    }

    calculateLandTransferTaxNS():void{
        let landTransferTaxRate : number = 0.00;
        const propertyModel = this.matterPropertyWithCondo;
        // for newly created matter assessedValue is undefined and it cause expression below "if(Number(purchaserPrice)..." always sets landTransferTax = 0
        if (!propertyModel.assessedValue){
            propertyModel.assessedValue = 0;
        }
        if (propertyModel.jurisdiction){
            landTransferTaxRate = propertyModel.jurisdiction.municipalDeedTransferTaxRate;
        }else{
            landTransferTaxRate = 0.00;
            propertyModel.landTransferTax = 0;
        }
        if(landTransferTaxRate>0){
            const purchaserPrice: number = this.considerationLtt && this.considerationLtt.salePriceAdjustment? this.considerationLtt.salePriceAdjustment.agreementSalePrice: 0;
            if(Number(purchaserPrice) > propertyModel.assessedValue ){
                propertyModel.landTransferTax = Math.round(Number(purchaserPrice) * landTransferTaxRate/100 *100)/100;
            }else if(this.propertyModel.assessedValue  >  Number(purchaserPrice)){
                propertyModel.landTransferTax = Math.round(Number(propertyModel.assessedValue) * landTransferTaxRate/100 *100)/100;
            }else{
                propertyModel.landTransferTax = 0;
            }
        }
    }

    isFirstMortgageArranged(): boolean {
        return (this.mortgages && this.mortgages.length > 0 && this.mortgages[0].loanType == 'ARRANGED');
    }

    isFirstMortgageBridge(): boolean {
        return (this.mortgages && this.mortgages.length > 0 && this.mortgages[0].loanType == 'BRIDGE');
    }

    isFirstMortgageTermManualCalculated(): boolean {
        return (this.mortgages && this.mortgages.length > 0 && this.mortgages[0].mortgageTerm && this.mortgages[0].mortgageTerm.isCalculationMethodManual());
    }

    isMortgageAvailable(): boolean {
        return (this.mortgages && this.mortgages.length > 0);
    }

    updateApplyTorontoTaxFlagForToronto(): void {
        if (this.isPropertyTeranetJurisdictionToronto() && this.considerationLtt) {
            this.considerationLtt.applyCityOfTorontoLandTransferTax = 'YES';
        }
        else if (this.considerationLtt) {
            this.considerationLtt.applyCityOfTorontoLandTransferTax = 'QUESTION';
        }

    }

    createUpdateSalePriceAdjustment(considerationTaxes?: ConsiderationTaxes , doNotCreateAdjustment? : boolean): void {
        let adjustmentAmt: number = 0;
        if (this.considerationLtt && considerationTaxes) {
            adjustmentAmt = this.considerationLtt.creditVendorPrice(considerationTaxes);
        }
        let salePriceSA: StatementAdjustment = this.getSalePriceStatementAdjustment();
        if (salePriceSA) {
            salePriceSA.amount = adjustmentAmt;
            if (this.matterPropertyWithCondo && (!this.matterPropertyWithCondo.purchasePriceType || this.matterPropertyWithCondo.isPurchaserPriceTypeSOA())) {
                this.matterPropertyWithCondo.soaPurchasePrice = adjustmentAmt;
            }
            this.createSalesPriceHeadings();
        } else if(!doNotCreateAdjustment) {
            this.addAdjustment(this.adjustmentStatusMode, StatementAdjustmentConstants.SALE_PRICE, adjustmentAmt, StatementAdjustmentKey.SALE_PRICE, StatementAdjustmentAmountTypes.VENDOR);
            this.createSalesPriceHeadings();
            // TODO : Code needs to be removed once we have linking in place for sale price.
            if (this.templateForProject) {
                let salePriceSA: StatementAdjustment = this.getSalePriceStatementAdjustment();
                salePriceSA.fieldCode = staticFieldCodes.salePriceAdjustment;
            }
        }
    }

    getSalePriceStatementAdjustment(): StatementAdjustment {
        return this.statementOfAdjustments.find(item => item.description == StatementAdjustmentConstants.SALE_PRICE);
    }

    createSalesPriceHeadings(): void {
        if(this.isMatterProvinceABorMBorSK) {
            if (this.considerationLtt && this.considerationLtt.salePriceAdjustment && this.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings) {
                let salePriceAdjustmentHeadingsSOA = this.statementOfAdjustments.filter(soa => soa.itemKey == StatementAdjustmentKey.SALE_PRICE_ADJUSTMENT_HEADING
                    && this.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings.map(sh => {
                        return sh.id;
                    }).indexOf(soa.salePriceAdjustmentHeadingId) < 0);
                if (salePriceAdjustmentHeadingsSOA) {
                    salePriceAdjustmentHeadingsSOA.forEach((soa) => {
                        this.removeStatementAdjustment(soa);
                    });
                }
            }
            if (this.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings.length) {
                let statementAdjustmentOrders = this.isAdjustmentStatusModeFinal ? this.finalStatementAdjustmentOrders : this.interimStatementAdjustmentOrders;
                let statementAdjustmentOrder: StatementAdjustmentOrder;
                if (statementAdjustmentOrders && statementAdjustmentOrders.length > 0) {
                    statementAdjustmentOrder = statementAdjustmentOrders[0];
                }
                this.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings.forEach((salePriceAdjustmentHeading: SalePriceAdjustmentHeading, index) => {
                    let statementAdjustment = this.statementOfAdjustments.find(soa => soa.itemKey == StatementAdjustmentKey.SALE_PRICE_ADJUSTMENT_HEADING && soa.salePriceAdjustmentHeadingId == salePriceAdjustmentHeading.id);
                    let statementAdjustmentAmountTypes = salePriceAdjustmentHeading.cost >= 0 ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
                    if (statementAdjustment) {
                        if (statementAdjustmentOrders) {
                            statementAdjustmentOrder = statementAdjustmentOrders.find(order => order.adjustmentId == statementAdjustment.id);
                        }
                        statementAdjustment.description = salePriceAdjustmentHeading.name;
                        statementAdjustment.amount = Math.abs(salePriceAdjustmentHeading.cost);
                        statementAdjustment.statementOfAdjustmentCreditType = statementAdjustmentAmountTypes;
                    } else {
                        let statementAdjustment = StatementAdjustmentUtil.createStatementAdjustment(this.adjustmentStatusMode, this.provinceCode, salePriceAdjustmentHeading.name, Math.abs(salePriceAdjustmentHeading.cost), StatementAdjustmentKey.SALE_PRICE_ADJUSTMENT_HEADING, statementAdjustmentAmountTypes, null, null, salePriceAdjustmentHeading.id, null);
                        statementAdjustment.adjustmentStatus = this.adjustmentStatusMode ? this.adjustmentStatusMode : ProgressionStatus.FINAL;
                        this.statementOfAdjustments.splice(index + 1, 0, statementAdjustment);
                        this.createStatementAdjustmentOrder(statementAdjustment, statementAdjustmentOrder);
                    }
                });
            }
        }
    }


    createUpdateDepositAdjustment(infoOnly = false): void {

        if (this.matterPropertyWithCondo) {
            const creditPurchaserDeposit = this.calculateCreditPurchaserForDeposit();
            let statementAdjustment: StatementAdjustment = this.findStatementAdjustment(StatementAdjustmentKey.DEPOSIT);
            if (statementAdjustment) {
                statementAdjustment.amount = creditPurchaserDeposit;
                statementAdjustment.infoOnly = infoOnly;
            } else {
                this.addAdjustment(this.adjustmentStatusMode, StatementAdjustmentConstants.DEPOSIT, creditPurchaserDeposit, StatementAdjustmentKey.DEPOSIT, StatementAdjustmentAmountTypes.PURCHASER, null, null, null, null, infoOnly);
            }
        } else {
            this.addAdjustment(this.adjustmentStatusMode, StatementAdjustmentConstants.DEPOSIT, 0, StatementAdjustmentKey.DEPOSIT, StatementAdjustmentAmountTypes.PURCHASER, null, null, null, null, infoOnly);
        }

    }

    calculateCreditPurchaserForDeposit(): number {
        let depositAmount = Utils.getNumberOrDefault(this.matterPropertyWithCondo.depositAmount, 0.0);
        if (this.isAdjustmentStatusModeFinal) {
            const depositOnOccupancy = Utils.getNumberOrDefault(this.extraDepositConfig && this.extraDepositConfig.depositOnOccupancyAmount, 0.0);
            depositAmount = depositAmount + depositOnOccupancy;
        }
        return depositAmount;
    }

    updateMatterPropertyDepositAmount() : void {
           if(this.matterPropertyWithCondo && !this.matterPropertyWithCondo.isDepositAdjustmentSingle){
               this.matterPropertyWithCondo.depositAmount = Deposit.calculateTotalDepositsIncludingExtras(this.matterPropertyWithCondo.deposits);
           }
    }


    getAdjustmentAmount(itemKey: string): number {
        let statementAdjustment = this.findStatementAdjustment(itemKey);
        return statementAdjustment ? statementAdjustment.amount : undefined;
    }

    /**
     *
     * @param {string} itemKey, one of the constants of StatementAdjustmentKey.
     * @return {StatementAdjustment}
     */
    findStatementAdjustment(itemKey: string) {
        let statementAdjustment: StatementAdjustment;
        if(this.statementOfAdjustments){
            statementAdjustment = this.statementOfAdjustments.find(item => item.itemKey == itemKey);
        }
        return statementAdjustment;
    }

    // Most removing adjustment from dialog to call removeAdjustmentPropertyTaxes instead of removeStatementAdjustment(statementAdjustment) in StatementAdjustmentComponent;
    // TODO It is better to refactor removeStatementAdjustment and removeAdjustmentPropertyTaxes
    removeStatementAdjustment(soAdjToBeRemoved: StatementAdjustment) {
        let statementAdjustmentIndex: number = this.statementOfAdjustments.findIndex(item => item == soAdjToBeRemoved || item.id == soAdjToBeRemoved.id);
        // let statementAdjustment = this.statementOfAdjustments.find(item => item == soAdjToBeRemoved || item.id == soAdjToBeRemoved.id);
        if (statementAdjustmentIndex > -1) {
            let existingConsiderationFromTarionOrOtherFixed = StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(this.statementOfAdjustments);
            // if(statementAdjustment.linkId != undefined){
            //     let adjustment = (this.isAdjustmentStatusModeFinal ?this.interimStatementAdjustments : this.finalStatementAdjustments).find(item => item.linkId == statementAdjustment.linkId);
            //     if(adjustment){
            //           adjustment.linkId = undefined;
            //     }
            // }
            this.statementOfAdjustments.splice(statementAdjustmentIndex, 1);
            if (soAdjToBeRemoved.isTarionWarranty() || soAdjToBeRemoved.isOtherFixed() ||  soAdjToBeRemoved.isHCRAFee() || soAdjToBeRemoved.isOtherFixedPayableOnOccupancy() || soAdjToBeRemoved.isOtherProratedOnPercentageInterest()) {
                StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(this.considerationLtt.salePriceAdjustment, this.statementOfAdjustments, existingConsiderationFromTarionOrOtherFixed);
                StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(this.considerationLtt.salePriceAdjustment, this.uniqueStatementAdjustments, existingConsiderationFromTarionOrOtherFixed);
            }
            //If delete Interim Occupancy Fee item, it should recalculate Taxes Paid During Occupancy item
            if (soAdjToBeRemoved.isInterimOccupancyFee() ) {
                this.recalculateTaxesPaidDuringOccupancy();
            }
            if(soAdjToBeRemoved.matterTax && this.soaTrustLedgerCollection){
                this.soaTrustLedgerCollection.clearPropertyTaxPaidTrustLedgerStatementAdjustment(soAdjToBeRemoved);
            }
        }

    }

    createUpdateAdjustmentPropertyTaxesByRollNumber(infoOnly?: boolean): void {
        MatterStatementAdjustmentUtil.createUpdateAdjustmentPropertyTaxesByRollNumber(this, infoOnly);
    }

    createUpdateAdjustmentPropertyTaxes(statementAdjustmentAvailable?: StatementAdjustment, propertyTaxByRollNumber ?: MatterTax, rollNumber ?: string, infoOnly?: boolean): void {
        MatterStatementAdjustmentUtil.createUpdateAdjustmentPropertyTaxes(this, statementAdjustmentAvailable, propertyTaxByRollNumber, rollNumber, infoOnly);
    }

    createUpdateAdjustmentCommonExp(parcelLegalDescription?: ParcelLegalDescription, skCondoFee?: number, doNotCreateAdjustment? : boolean): void {
        MatterStatementAdjustmentUtil.createUpdateAdjustmentCommonExp(this, parcelLegalDescription, skCondoFee , doNotCreateAdjustment);
    }

    totalCreditVendorAmount(): number {
        return this.statementOfAdjustments && this.statementOfAdjustments.filter(item => item.statementOfAdjustmentCreditType == StatementAdjustmentAmountTypes.VENDOR).reduce(function (total, row) {
            return Number(total) + ((row.isOutOfRange || row.infoOnly || row.isProjectAdjustmentNotAppliedOnMatter()) ? 0 : Utils.roundCurrencyTCStyle(Number(row.amount)));
        }, 0);
    }

    totalCreditPurchaserAmount(): number {
        return this.statementOfAdjustments && this.statementOfAdjustments.filter(item => item.statementOfAdjustmentCreditType == StatementAdjustmentAmountTypes.PURCHASER).reduce(function (total, row) {
            return Number(total) + ((row.isOutOfRange || row.infoOnly || row.isProjectAdjustmentNotAppliedOnMatter()) ? 0 : Utils.roundCurrencyTCStyle(Number(row.amount)));
        }, 0);
    }

    totalCreditVendorAmountFromFocusedSoAdjSheet(): number {
        return this.statementOfAdjustmentsFocused.filter(item => item.statementOfAdjustmentCreditType == StatementAdjustmentAmountTypes.VENDOR).reduce(function (total, row) {
            return Number(total) + ((row.isOutOfRange || row.infoOnly || row.isProjectAdjustmentNotAppliedOnMatter()) ? 0 :  Utils.roundCurrencyTCStyle(Number(row.amount)));
        }, 0);
    }

    totalCreditPurchaserAmountFromFocusedSoAdjSheet(): number {
        return this.statementOfAdjustmentsFocused.filter(item => item.statementOfAdjustmentCreditType == StatementAdjustmentAmountTypes.PURCHASER).reduce(function (total, row) {
            return Number(total) + ((row.isOutOfRange || row.infoOnly || row.isProjectAdjustmentNotAppliedOnMatter()) ? 0 : Utils.roundCurrencyTCStyle(Number(row.amount)));
        }, 0);
    }

    calculatePayableCreditVendor(): number {
        return this.totalCreditVendorAmount() + this.calculateBalanceDueOnClosingCreditVendor();

    }

    calculateBalanceDueOnClosingCreditVendor(): number {
        let purchaser = this.totalCreditPurchaserAmount();
        let vendor = this.totalCreditVendorAmount();

        if (this.totalCreditPurchaserAmount() > this.totalCreditVendorAmount()) {
            return (Number(this.totalCreditPurchaserAmount()) - Number(this.totalCreditVendorAmount()));
        }
        else {
            return 0;
        }
    }

    calculateBalanceDueOnClosingCreditPurchaser(): number {
        let purchaser = this.totalCreditPurchaserAmount();
        let vendor = this.totalCreditVendorAmount();

        if (this.totalCreditVendorAmount() > this.totalCreditPurchaserAmount()) {
            return (Number(this.totalCreditVendorAmount()) - Number(this.totalCreditPurchaserAmount()));
        }
        else {
            return 0;
        }
    }

    //used by Project Sale Matter
    calculateBalanceDueOnClosingCreditPurchaserFromFocusedSoAdjSheet(): number {
        const creditPurchaserAmountFromFocusedSoAdjSheet: number = this.totalCreditPurchaserAmountFromFocusedSoAdjSheet();
        const creditVendorAmountFromFocusedSoAdjSheet: number = this.totalCreditVendorAmountFromFocusedSoAdjSheet();

        if (creditVendorAmountFromFocusedSoAdjSheet > creditPurchaserAmountFromFocusedSoAdjSheet) {
            return Number(creditVendorAmountFromFocusedSoAdjSheet) - Number(creditPurchaserAmountFromFocusedSoAdjSheet);
        } else {
            return 0;
        }
    }

    calculatePayablePurchase(): number {
        return this.totalCreditPurchaserAmount() + this.calculateBalanceDueOnClosingPurchase();
    }

    //'Deposit' will be a read only $ field defaulted to '$0.00'
    // and will display the 'Deposit' field value from 'Statement of Adjustments' section (Tab M)
    // if 'Applied towards payment of commission?' picklist is set to 'Y/n' or 'Yes' else will show default value ($0.00)
    calculateBrokerDeposit(): number {
        if (!this.brokerCommission) {
            return 0;
        }
        if (this.brokerCommission.appliedTowardsPaymentOfCommission === DpBooleanValueTypes.NO) {
            return 0;
        } else if (this.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER
            && (this.depositHeldBy === constValues.depositHeldBy.VENDOR_LAWYER
                || this.depositHeldBy === constValues.depositHeldBy.PURCHASER_LAWYER
                || this.depositHeldBy === constValues.depositHeldBy.BUILDER
                || this.depositHeldBy === constValues.depositHeldBy.DEVELOPER)) {
            // When 'Commission Paid to' = 'both Vendor's and Purchaser's brokers' scenario (See wireframe in attachment)
            // In this scenario, 'Deposit' field will not be visible if the 'Deposit held by' field is set to any other options other than Vendor's Broker
            // or Purchaser's broker. consider Deposit=0.00
            return 0;
        } else {
            const depositAmt: number = this.getAdjustmentAmount(StatementAdjustmentKey.DEPOSIT);
            if (this.isProjectSale) {
                //return the deposit Amt from the deposit SoAdj in the Focused Sheet
                return this.getDepositAmountFromFocusedSoAdj();
            } else {
                //this.matterPropertyWithCondo.depositAmount is string, it should change it to number
                return this.matterPropertyWithCondo && Utils.toNumber(this.matterPropertyWithCondo.depositAmount);
            }
        }
    }

    getDepositAmountFromFocusedSoAdj() {
        const soAdjInSelectedProgressionStatus: StatementAdjustment[] = (this.selectedProgressionStatus == ProgressionStatus.INTERIM) ? this.interimStatementAdjustments : this.finalStatementAdjustments;
        let statementAdjustment: StatementAdjustment;
        if(soAdjInSelectedProgressionStatus){
            statementAdjustment = soAdjInSelectedProgressionStatus.find(item => item.itemKey == StatementAdjustmentKey.DEPOSIT);
        }
        return statementAdjustment ? statementAdjustment.amount : undefined;
    }

    calculateBrokerCommission(forSeparatePayToRealEstateBroker?: boolean): number {
        return MatterCommissionUtil.calculateBrokerCommission(this, forSeparatePayToRealEstateBroker);
    }
    calculateBalanceDueOnClosingPurchase(): number {

        const vendorTotal = this.totalCreditVendorAmount();
        const purchaserTotal = this.totalCreditPurchaserAmount();
        if (vendorTotal > purchaserTotal) {
            return Number(Utils.roundCurrencyTCStyle(Number(vendorTotal)) - Utils.roundCurrencyTCStyle(Number(purchaserTotal)));
        }
        else {
            return 0;
        }
    }

    addAdjustment(adjustmentMode: string, description: string, amount: number, itemKey: string, amountType: string, commonExpense?: SoaCommonExpense, index?: number, salePriceAdjustmentHeadingId?: number, parcelLegalDescriptionId?: number, infoOnly?: boolean) {
        let statementAdjustment = StatementAdjustmentUtil.createStatementAdjustment(adjustmentMode, this.provinceCode, description, amount, itemKey, amountType, infoOnly, commonExpense, salePriceAdjustmentHeadingId, parcelLegalDescriptionId);
        statementAdjustment.adjustmentStatus = this.adjustmentStatusMode ? this.adjustmentStatusMode : ProgressionStatus.FINAL;
        if (index) {
            this.statementOfAdjustments.splice(index, 0, statementAdjustment);
        } else {
            this.statementOfAdjustments.push(statementAdjustment);
        }

    }

    getTaxTypeLabel(type: string): string {
        let taxTypeSelected = _.find(this.getTaxTypes(), taxType => taxType.value == type);
        if (taxTypeSelected) {
            return taxTypeSelected.label;
        }
        else {
            return "";
        }
    }

    getTaxTypes(): SelectItem[] {
        let selectItem: SelectItem[] = _.cloneDeep(provinceBasedTaxTypes[this.provinceCode]);
        if (selectItem && this.isMatterProvinceSK) {
            const realtyTaxesItem: SelectItem[] = selectItem.filter(item => item.value == 'REALTY_TAXES');
            if (realtyTaxesItem && realtyTaxesItem.length > 0) {
                realtyTaxesItem[0].label = 'Property Taxes';
            }
        }
        return selectItem;
    }

    getNumberofDaysMonth(closingDate: string): string {
        return Utils.getNumberofDaysMonth(closingDate);
    }

    getElapsedDays(closingDate: string): string {
        return Utils.getElapsedDays(closingDate);
    }

    get unresolvedRequisitions(): RequisitionTemplate[] {
        if (Array.isArray(this.matterRequisitions)) {
            return _.filter(this.matterRequisitions, function (o: RequisitionTemplate) {
                return o.requisitionStatus === 'UNRESOLVED';
            })
        }
    }

    get unfulfilledMatterUndertakings(): MatterUndertaking[] {
        if (Array.isArray(this.matterUndertakings)) {
            return _.filter(this.matterUndertakings, function (o: MatterUndertaking) {
                return o.matterUndertakingStatus === 'OUTSTANDING';
            })
        }
    }

    calculateStatementAdjustment(): void {
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.updateF9PaidToVendor();
            this.soaTrustLedgerCollection.updateF9ReceivedFromPurchaser();
            this.soaTrustLedgerCollection.updateF9PaidToBroker();
            this.soaTrustLedgerCollection.updateF9PaidToPreviousMortgage();
            this.soaTrustLedgerCollection.updateF9ForPaidToYouAndOtherTrustLedgers();
        }
    }

    getUserDefinedFields(topicName: MatterTopicKey, mortgageId ?: number): UserDefinedField[] {
        if (Array.isArray(this.matterUserDefinedFields)) {
            if (topicName && mortgageId) {
                return _.filter(this.matterUserDefinedFields, function (udf: UserDefinedField) {
                    return udf.topicName === topicName && udf.mortgageId === mortgageId;
                })
            } else if (topicName) {
                return _.filter(this.matterUserDefinedFields, function (udf: UserDefinedField) {
                    return udf.topicName === topicName;
                })
            }
        }

        return [];
    }


    getProvinceAwaredUserDefinedFields(topicName: MatterTopicKey, mortgageId ?: number): UserDefinedField[] {
        if (Array.isArray(this.matterUserDefinedFields)) {
            if (topicName && mortgageId) {
                return _.filter(this.matterUserDefinedFields, function (udf: UserDefinedField) {
                    return udf.topicName === topicName && udf.mortgageId === mortgageId;
                })
            } else if (topicName) {
                return _.filter(this.matterUserDefinedFields, function (udf: UserDefinedField) {
                    return udf.topicName === topicName;
                })
            }
        }

        return [];
    }


    initializeMatterUserDefinedFields(customerActUserDefinedFields: UserDefinedField[]) {

        if (Array.isArray(customerActUserDefinedFields)) {
            // Business requirements:
            // Configuration UDFs created after the Matter was saved, will be ADDED to the Matter UDFs, if:
            //       - configured UDF is NEW (not existing in Matter UDFs) and Active
            // Existing Matter UDFs will NOT SHOW for entering values, if they later became Inactive, and no value was entered for those UDFs
            // Existing Matter UDFs will SHOW for entering values, even if they later became Inactive, but a value was already entered for those UDFs

            console.log("initializeMatterUserDefinedFields: this.matterUserDefinedFields=", this.matterUserDefinedFields);
            for (let i: number = 0; i < customerActUserDefinedFields.length; ++i) {

                let customerUDF: UserDefinedField = new UserDefinedField(customerActUserDefinedFields[i]);

                let isMortgageTopic = (customerUDF.topicName === "MORTGAGES_MORTGAGEE" || customerUDF.topicName === "MORTGAGES_TERMS" || customerUDF.topicName === "MORTGAGES_REPORT");

                let foundInMatter = false;
                for (let j: number = 0; j < this.matterUserDefinedFields.length; j++) {
                    if (customerUDF.id == this.matterUserDefinedFields[j].sourceUDFId && customerUDF.topicName == this.matterUserDefinedFields[j].topicName) {
                        foundInMatter = true;
                        break;
                    }
                }

                if (customerUDF.active && !foundInMatter) {
                    customerUDF.id = null;
                    customerUDF.instanceType = 'MATTER_UDF';
                    customerUDF.sourceUDFId = customerActUserDefinedFields[i].id;
                    customerUDF.userDefinedFieldOrder = customerActUserDefinedFields[i].userDefinedFieldOrder;
                    if (isMortgageTopic) {
                        // for every mortgage, add an instance of UserDefinedField, with mortgageId set
                        if (this.mortgages) {
                            for (let k: number = 0; k < this.mortgages.length; k++) {
                                let mortgageUDF: UserDefinedField = new UserDefinedField(customerUDF);
                                mortgageUDF.mortgageId = this.mortgages[k].id;
                                this.matterUserDefinedFields.push(mortgageUDF);
                            }
                        }
                    } else {
                        this.matterUserDefinedFields.push(customerUDF);
                    }
                }
            }
            this.matterUserDefinedFields.sort((a: UserDefinedField, b: UserDefinedField) => {
                return a.userDefinedFieldOrder - b.userDefinedFieldOrder;
            })
        }
    }

    isMortgageTopic(topicName: string): boolean {
        return (topicName === "MORTGAGES_MORTGAGEE" || topicName === "MORTGAGES_TERMS" || topicName === "MORTGAGES_REPORT");
    }

    initializedFireInsuranceContactInfo(): void {
        MatterFireInsuranceUtil.initializedFireInsuranceContactInfo(this);
    }

//Before saving coping all calculated amount from wrapper getter methods in matter data structure
    copyCalculatedAmountFromSoaWrapperToMatter(): void {
        if (this.soaTrustLedgerCollection) {
            if (!this.matterCalculatedAmount) {
                this.matterCalculatedAmount = new MatterCalculatedAmount();
            }
            this.matterCalculatedAmount.soaFeesTotal = this.soaTrustLedgerCollection.calculateFeeTotal;
            this.matterCalculatedAmount.soaFeesHst = this.soaTrustLedgerCollection.feesHst();
            this.matterCalculatedAmount.soaHstDisbursementTotal = this.soaTrustLedgerCollection.totalDisbursementsSubjectToTax;
            this.matterCalculatedAmount.soaHstDisbursementHstAmount = this.soaTrustLedgerCollection.taxAmountOfDisbursementsSubjectToTax();
            this.matterCalculatedAmount.soaNonHstDisbursementTotal = this.soaTrustLedgerCollection.totalDisbursementsNotSubjectToTax;
            this.matterCalculatedAmount.soaGrandTotal = this.soaTrustLedgerCollection.totalRounded();
            this.matterCalculatedAmount.soaBalanceOwing = this.soaTrustLedgerCollection.balanceOwing();
            this.matterCalculatedAmount.soaTotalHst = this.soaTrustLedgerCollection.totalTaxes()
        }
        if(this.secondarySoaSheets){
            this.secondarySoaSheets.forEach( sheet =>{
                let collection = this.secondarySoaSheetsCollection.find( cl => cl.sheetId  == sheet.id);
                if(collection) {
                    if (!sheet.matterCalculatedAmount) {
                        sheet.matterCalculatedAmount = new MatterCalculatedAmount();
                    }
                    sheet.matterCalculatedAmount.soaFeesTotal = collection.calculateFeeTotal;
                    sheet.matterCalculatedAmount.soaFeesHst = collection.feesHst();
                    sheet.matterCalculatedAmount.soaHstDisbursementTotal = collection.totalDisbursementsSubjectToTax;
                    sheet.matterCalculatedAmount.soaHstDisbursementHstAmount = collection.taxAmountOfDisbursementsSubjectToTax();
                    sheet.matterCalculatedAmount.soaNonHstDisbursementTotal = collection.totalDisbursementsNotSubjectToTax;
                    sheet.matterCalculatedAmount.soaGrandTotal = collection.totalRounded();
                    sheet.matterCalculatedAmount.soaBalanceOwing = collection.balanceOwing();
                    sheet.matterCalculatedAmount.soaTotalHst = collection.totalTaxes();
                }
            })
        }
    }

    copyBrokerCommissionTabValuesForSaleMatter(): void {
        if (!this.matterCalculatedAmount) {
            this.matterCalculatedAmount = new MatterCalculatedAmount();
        }
        this.matterCalculatedAmount.brokerPercentageOfCommissionOn = this.brokerPercentageOfCommissionOn;
        this.matterCalculatedAmount.vendorDeposit = this.calculateBrokerDeposit();
        this.matterCalculatedAmount.purchaserDeposit = this.calculateBrokerDeposit();
        if(this.secondarySoaSheets){
            this.secondarySoaSheets.forEach( sheet =>{
                let collection = this.secondarySoaSheetsCollection.find( cl => cl.sheetId  == sheet.id);
                if(collection) {
                    if (!sheet.matterCalculatedAmount) {
                        sheet.matterCalculatedAmount = new MatterCalculatedAmount();
                    }
                    sheet.matterCalculatedAmount.brokerPercentageOfCommissionOn = this.brokerPercentageOfCommissionOn;
                    sheet.matterCalculatedAmount.vendorDeposit = this.calculateBrokerDeposit();
                    sheet.matterCalculatedAmount.purchaserDeposit = this.calculateBrokerDeposit();
                }
            })
        }
    }

    isMatterTitleInsured(): boolean {
        return (this.transactionTitleInsuredCode && (this.transactionTitleInsuredCode !== dropDowns.yesNoCustom1[0].value) && (this.transactionTitleInsuredCode !== dropDowns.yesNoCustom1[4].value))
    }


//Returns true if locked by other user
//Else returns false if locked by self or not-locked
    get locked(): boolean {
        const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        return (this.lockedByUser && this.lockedByUser.id !== Number(userId));
    }

    get lockedByLoggedInUser(): boolean {
        const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        return (this.lockedByUser && this.lockedByUser.id == Number(userId));
    }

    get islockedByAnyUser(): boolean {
        if ((this.lockedByUser) && (this.lockedByUser.id)) {
            return true;
        }
        return false;
    }

    set locked(ignore: boolean) {
        //Do nothing
    }

    get refunded(): boolean {
        let matterBilling: MatterBilling = this.billingTransactions.find((element) => {
            return element.transactionType === TransactionType.REFUND;
        });
        return !!matterBilling;
    }

    get trialTransaction(): boolean {

        let matterBilling: MatterBilling = this.billingTransactions.find((element) => {
            return element.transactionType === TransactionType.CHARGE && !element.transactionCharge;
        });
        return !!matterBilling;

    }

    isAdjustAsAtSpecify(): boolean {
        return (this.isAdjustmentStatusModeFinal ? (this.adjustAsAtClosingDateFlag === 'SPECIFY') : (this.adjustAsAtClosingDateFlagInterim === 'SPECIFY'));
    }

    isFinalAdjustAsAtSpecify(): boolean {
        return (this.adjustAsAtClosingDateFlag === 'SPECIFY');
    }

    isInterimAdjustAsAtSpecify(): boolean {
        return (this.adjustAsAtClosingDateFlagInterim === 'SPECIFY');
    }


    isMatterAdjustAsAtOccupancyDate(): boolean {
        return (this.isAdjustmentStatusModeFinal ? (this.adjustAsAtClosingDateFlag === 'OCCUPANCY_DATE') : (this.adjustAsAtClosingDateFlagInterim === 'OCCUPANCY_DATE'));
    }

    isMatterAdjustAsAtOccupancyDateForInterim(): boolean {
        return this.adjustAsAtClosingDateFlagInterim === 'OCCUPANCY_DATE';
    }

    /**
     * returns number of mortgages of type "Arranged" and "Back to Vendor"
     * @returns {number}
     */
    getRegisteredInstruments(): number {
        return this.mortgages ? this.mortgages.filter(item =>
            (item.isLoanTypeArranged() || item.isLoanTypeBackToVendor()) && item.id).length : 0;
    }

    getRegisteredVTBInstruments(): number {
        if (this.matterType == 'SALE' || this.matterType == 'MORTGAGE') {
            return this.mortgages ? this.mortgages.filter(item => (item.isUnityNewMortgage() || item.isEmpMortgage())).length : 0;
        }
        return 0;
    }

    getDischargedInstruments(): number {
        if (this.matterType == 'SALE' || this.matterType == 'MORTGAGE') {
            return this.mortgages ? this.existingMortgages.filter(item => item.isMortgageDispositionDischarged() && item.isDischargeRegisteredByOurFirm()).length : 0;
        }
        return 0;
    }


    getDischargedInstrumentsForFinanceMortgage(): number {
        if (this.matterType == 'SALE' || this.matterType == 'MORTGAGE') {
            return this.mortgages ? this.existingMortgages.filter(item => item.isMortgageDispositionDischarged() && item.isMortgageFinancingTypeMortgage()).length : 0;
        }
        return 0;
    }

    getDischargedInstrumentsForFinanceCavet(): number {
        if (this.matterType == 'SALE' || this.matterType == 'MORTGAGE') {
            return this.mortgages ? this.existingMortgages.filter(item => item.isMortgageDispositionDischarged() && !item.isMortgageFinancingTypeMortgage()).length : 0;
        }
        return 0;
    }

    getDischargedInstrumentsForFinanceCavetAndRegisteredByOurFirm(): number {
        if (this.matterType == 'SALE' || this.matterType == 'MORTGAGE') {
            return this.mortgages ? this.existingMortgages.filter(item => item.isMortgageDispositionDischarged() && !item.isMortgageFinancingTypeMortgage() && item.isDischargeRegisteredByOurFirm()).length : 0;
        }
        return 0;
    }

    getDischargedInstrumentsForFinanceAndRegisteredByOurFirm(): number {
        if (this.matterType == 'SALE' || this.matterType == 'MORTGAGE') {
            return this.existingMortgages ? this.existingMortgages.filter(item => item.isMortgageDispositionDischarged() && item.isMortgageFinancingTypeMortgage() && item.isDischargeRegisteredByOurFirm()).length : 0;
        }
        return 0;
    }

    getDischargedInstrumentsByFinanceTypeOther(): number {
        if (this.isSale || this.isMortgage) {
            return this.getDischargedMortgagesRegisteredByOurFirm() &&  this.getDischargedMortgagesRegisteredByOurFirm().length
                ? this.getDischargedMortgagesRegisteredByOurFirm().filter(item =>  !item.isMortgageFinancingTypeChargeOrMortgage()).length : 0;
        }
        return 0;
    }

    getDischargedMortgagesRegisteredByOurFirm(): Mortgage[] {
        if (this.isSale || this.isMortgage) {
            return this.existingMortgages ? this.existingMortgages.filter(item => item.isMortgageDispositionDischarged() && item.isDischargeRegisteredByOurFirm()) : [];
        }
        return [];
    }

    getDischargedInstrumentsByFinanceTypeAsMortgageOrCharge(): number {
        if (this.isSale || this.isMortgage) {
            return this.getDischargedMortgagesRegisteredByOurFirm() &&  this.getDischargedMortgagesRegisteredByOurFirm().length
                ? this.getDischargedMortgagesRegisteredByOurFirm().filter(item =>  item.isMortgageFinancingTypeChargeOrMortgage()).length : 0;
        }
        return 0;
    }

    getSoaTaxRateEffectiveDate(): string {
        return this.isClosingDateAvailable() ? moment(this.matterCloseDate, 'YYYY/MM/DD').format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    }

    findDeclarationByNameAndType(clientName: string, declarationType: DeclarationType): Declaration {
        return this.declarations.find((d: Declaration) => {
            return d.clientName === clientName && d.declarationType === declarationType;
        });
    }

    get solicitorDeclarations(): Declaration[] {
        return this.declarations.filter((d: Declaration) => {
            return d.declarationType === 'SOLICITOR';
        });
    }

    findClientDeclarationByWritId(writId: number): Declaration {
        return this.declarations.find(declaration => (declaration.writId == writId && declaration.declarationType == 'CLIENT'));
    }

    findClientDeclarationByWrit(writ: Writ): Declaration {
        return writ.id ? this.findClientDeclarationByWritId(writ.id) : null;
    }

    findSolicitorDeclarationByWritId(writId: number): Declaration {
        return this.declarations.find(declaration => (declaration.writId == writId && declaration.declarationType == 'SOLICITOR'));
    }

    findSolicitorDeclarationByWrit(writ: Writ): Declaration {
        return writ.id ? this.findSolicitorDeclarationByWritId(writ.id) : null;
    }

    get propertyRollNumber(): string {
        return this.matterProperties && this.matterProperties.length > 0 ? this.matterProperties[0].concatenatedRollNumber : null;
    }

    createStatementAdjustmentHeading(documentProfile: DocumentProfile, isProjectSale: boolean, replaceExisting: boolean = false): void {
        MatterStatementAdjustmentUtil.createStatementAdjustmentHeading(this, documentProfile, isProjectSale, replaceExisting);
    }

    hideShowSalePriceSoA(priceType: string): void {
        if(this.allStatementAdjustments && this.allStatementAdjustments.length > 0){
            this.allStatementAdjustments.filter(item => item.isSalePrice()).forEach(item => item.addedFlag = (priceType == 'SALE_PRICE'));
        }
    }

    createUpdateHstAdjustments(salePrice: number, federalHstRate: number, provinceHstRate: number) {
        this.createAutomaticHstAdjustment(salePrice, federalHstRate, provinceHstRate);
        MatterStatementAdjustmentUtil.updateManualHstAdjustments(this, salePrice, federalHstRate, provinceHstRate);
    }

    calculateHstSalePriceAdjAmount(salePrice: number, federalHstRate: number, provinceHstRate: number): number {
        if (this.considerationLtt && this.considerationLtt.salePriceAdjustment) {
            // ToDo in a non-RFPD ticket make this in synch with StatementAdjComp.getHstSalePriceAdjustmentAmount
            if (this.isMatterProvinceMB || this.isMatterProvinceSK) {
                return Utils.roundCurrency(this.considerationLtt.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(federalHstRate, federalHstRate, provinceHstRate));
            }
            if(this.isMatterProvinceNBorNS){
                return Utils.roundCurrency(this.considerationLtt.salePriceAdjustment.calculateHstPortion(provinceHstRate) +
                    (this.considerationLtt.salePriceAdjustment.calculateHstPortion(federalHstRate)));
            }
            return Utils.roundCurrency(this.considerationLtt.salePriceAdjustment.calculateHstPortion(provinceHstRate)) +
                Utils.roundCurrency(this.considerationLtt.salePriceAdjustment.calculateHstPortion(federalHstRate));
        }
        return Utils.roundCurrency((salePrice * provinceHstRate) / 100) + Utils.roundCurrency((salePrice * federalHstRate) / 100);
    }

    getHstSalePriceAdjustmentAmount(hstSalePriceSoa: HstSalePrice, salePriceAdjustment: SalePriceAdjustment, provinceHstRate : number , federalHstRate : number ): number {
        return MatterStatementAdjustmentUtil.getHstSalePriceAdjustmentAmount(this, hstSalePriceSoa, salePriceAdjustment, provinceHstRate, federalHstRate);
    }

    public updateHstSalePriceAdjustment(provinceHstRate : number , federalHstRate : number ) : void {
        this.statementOfAdjustments
            .filter(item => item.hstSalePrice != undefined && item.isHstAdjustment())
            .forEach(soAdj => {
                    soAdj.amount = this.getHstSalePriceAdjustmentAmount(soAdj.hstSalePrice, this.considerationLtt.salePriceAdjustment, provinceHstRate, federalHstRate);
                    if (soAdj.amount == 0) {
                        soAdj.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
                    } else {
                        if (soAdj.hstSalePrice.isVendorSelected == undefined) { // isVendorSelected is not saved on db
                            soAdj.hstSalePrice.isVendorSelected = (soAdj.hstSalePrice.creditType == this.getVendorLabel());
                        }
                        soAdj.statementOfAdjustmentCreditType = soAdj.hstSalePrice.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
                    }
                }
            );
    }

    createAutomaticHstAdjustment(salePrice: number, federalHstRate: number, provinceHstRate: number): void {
        MatterStatementAdjustmentUtil.createAutomaticHstAdjustment(this, salePrice, federalHstRate, provinceHstRate);
    }

    isAutoCreatedHstRebateSoa(sa: StatementAdjustment): boolean {
        return sa && !sa.addedFlag && sa.isHstAdjustment() && sa.hstSalePrice && sa.hstSalePrice.isHstRebate()
    }

    eligibleForAutoCreationOfHstRebateSoa(): boolean {
        if (this.autoInsertHst && this.considerationLtt && this.considerationLtt.salePriceAdjustment) {
            return this.considerationLtt.salePriceAdjustment.isFactorInHstRebate() || (this.considerationLtt.salePriceAdjustment.isDivideSalePrice() && this.considerationLtt.salePriceAdjustment.salePriceAdjustmentFormat === 'CREDIT_VENDOR_INCLUSIVE');
        }
        return false;
    }

    taxRateByProvinceForHSTSalePrice(): string{
        //For Province MB and SK we get (GST_PST) so returning GST in that case
        return this.matterTaxType.indexOf('GST') > -1 ? 'GST': this.matterTaxType;
    }

    get mainClientTitle(): string {
        if (this.isSale) {
            return provinceBasedFieldLabels.get('matter.title.vendor', this.provinceCode);
        }
        if(this.isMortgage || this.isDischargeMatter()) {
            return 'Mortgagor';
        }
        if (this.isCustomMatter()) {
            return 'Client';
        }
        if(this.isOpportunityMatter()){
            return 'Prospect';
        }
        if (this.isWillMatter()) {
            return "Testator";
        }
        return provinceBasedFieldLabels.get('matter.title.purchaser', this.provinceCode);
    }

    get mainClientHeader(): string {
        if (this.isOpportunityMatter()) {
            return 'Prospect(s)';
        } else if (this.isWillMatter()) {
            return 'Client Info';
        }
        return this.mainClientTitle;
    }

    get otherPartyTitle(): string {
        if (this.isSale) {
            return provinceBasedFieldLabels.get('matter.title.purchaser', this.provinceCode);
        }
        if (this.isMortgage) {
            return 'Solicitor Detail';
        }
        if (this.isCustomMatter()) {
            return 'Other Client';
        }
        if(this.isOpportunityMatter()) {
            return 'Other Side Client';
        }
        if(this.isWillMatter()) {
            return 'Witness'
        }
        return provinceBasedFieldLabels.get('matter.title.vendor', this.provinceCode);
    }


    updateSameParcelRegisterIndex(parcelRegister: ParcelRegister): void {
        let samePinParcelRegister = _.maxBy(this.teranetDocket.parcelRegisters.filter(parcel => parcel.pin == parcelRegister.pin && parcel.samePinIndex != undefined), function (item) {
            return item.samePinIndex;
        });
        if (samePinParcelRegister) {
            parcelRegister.samePinIndex = Number(samePinParcelRegister.samePinIndex) + 1;
        }
        else {
            let parcelRegisterAvailable = this.teranetDocket.parcelRegisters.find(parcel => parcel.pin == parcelRegister.pin);
            if (parcelRegisterAvailable && parcelRegister && parcelRegisterAvailable.identifier != parcelRegister.identifier) {
                parcelRegisterAvailable.samePinIndex = 1;
                parcelRegister.samePinIndex = Number(parcelRegisterAvailable.samePinIndex) + 1;
            }
        }
    }

    isInsurerStewartTitle(): boolean {
        return (messages.insurerValues && this.insurer == messages.insurerValues.find(item => item.class == 'stewart').value);
    }

    isInsurerChicagoTitle(): boolean {
        return (messages.insurerValues && this.insurer == messages.insurerValues.find(item => item.class == 'chicago').value);
    }

    isInsurerFCT(): boolean {
        return (messages.insurerValues && this.insurer == messages.insurerValues.find(item => item.class == 'fct').value);
    }

    isInsurerTitlePlus(): boolean {
        return (messages.insurerValues && this.insurer == messages.insurerValues.find(item => item.class == 'titlePlus').value);
    }

    isMainTitleInsurer(): boolean {
        return this.isInsurerStewartTitle() || this.isInsurerChicagoTitle() || this.isInsurerFCT() || this.isInsurerTitlePlus();
    }

    isItemTitleInsuranceReadonly(item: TrustLedgerMatter): boolean{
        return this.isMainTitleInsurer()  && item.isTitleInsurance  && !this.isOtherTitleInsurer();
    }

    isOtherTitleInsurer(): boolean {
        return this.transactionTitleInsuredCode == 'Yes - with Other Title Insurer';
    }

    isNotTitleInsured(): boolean {
        return this.insurer == 'Transaction Is Not Title Insured';
    }

    isInsurerNotYetDetermined(): boolean {
        return this.insurer == 'Not Yet Determined';
    }

    isTitleInsured(): boolean {
        //"Yes - Title Insurer" or "Yes - with Other Title Insurer"
        return this.transactionTitleInsuredCode && this.transactionTitleInsuredCode.startsWith('Yes');
    }

    isRegistrationElectronic(): boolean {
        return (this.registrationMethodCode == constValues.registrationMethodsType.electronic);
    }

    public updateTitleInsurance(mortgageAction: string, priority?: number, empMortgage?: Mortgage): void {
        if (this.isOtherTitleInsurer() && this.matterTitleInsurance) {
            this.matterTitleInsurance.updateAfterMortgageChange(this, mortgageAction, priority);
        }
        //if current TI insurer is FCT and new Added EMP is MMS, then story 19892
        if (this.isInsurerFCT() && this.matterTitleInsurance && empMortgage) {
            this.matterTitleInsurance.updateMmsDealAfterMortgageChange(mortgageAction, empMortgage);
        }
    }

    resetStatementOfAdjustmentPayable(docProfile ?: DocumentProfile, defaultDocProfile ?: DocumentProfile) {
        MatterStatementAdjustmentUtil.resetStatementOfAdjustmentPayable(this, docProfile, defaultDocProfile);
    }

    getStatementOfAdjustmentPayableTo(documentProfile?: DocumentProfile, defaultDocProfile?: DocumentProfile): string {
        return MatterStatementAdjustmentUtil.getStatementOfAdjustmentPayableTo(this, documentProfile, defaultDocProfile);
    }

    updateMLTTAndLTTOnJurisdictionChange(ontarioTaxRateSlab: ConsiderationTaxes, torontoTaxRateSlab: ConsiderationTaxes): void {
        this.updateApplyTorontoTaxFlagForToronto();
        if (this.considerationLtt && ontarioTaxRateSlab && torontoTaxRateSlab) {
            this.considerationLtt.calculateTaxRebate(ontarioTaxRateSlab, torontoTaxRateSlab);
            this.updateLTTOnStatementOfAccount();
        }
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.addOrRemoveMLTT();
            if(this.secondarySoaSheetsCollection){
                this.secondarySoaSheetsCollection.forEach( collection =>{
                    collection.addOrRemoveMLTT();
                })
            }

        }

    }

    updateAdjacentParcelRegister(parentParcelId: number, adjacentParcel: ParcelRegister) {
        let parentParcel = this.teranetDocket.parcelRegisters.find(pr => pr.id == parentParcelId);
        if (parentParcel != null) {
            let idx = parentParcel.adjacentParcels.findIndex(adjPr => adjacentParcel.id == adjPr.id);
            if (idx > -1) {
                parentParcel.adjacentParcels[idx] = adjacentParcel;
                parentParcel.updAdjAnalysisAvail();
            }
        }
    }

    getComplianceFromDepartmentJurisdiction(jurisdictionDepartments: JurisdictionDepartment[], waterHydroDeptType?: string): Compliance[] {
        return Compliance.jurisdictionDepartmentsToCompliances(jurisdictionDepartments, this.matterType, waterHydroDeptType);
    }

    jurisdictionDepartmentsComplianceMapping(jurisdictionDepartments: JurisdictionDepartment[], waterHydroDeptType?: string): Compliance[] {
        let compliances: Compliance[] = this.getComplianceFromDepartmentJurisdiction(jurisdictionDepartments, waterHydroDeptType);
        if (compliances && this.matterCompliances) {
            compliances.forEach(item => {
                if (item && item.departmentPriority) {
                    let matterComplianceObj = this.matterCompliances.find(matterCompliance => matterCompliance.departmentPriority == item.departmentPriority);
                    if (matterComplianceObj) {
                        item.fee = matterComplianceObj.fee;
                        item.hst = matterComplianceObj.hst;
                    }
                }
            })
        }
        ;
        return compliances;
    }

    jurisdictionChange(jurisdictionDepartments: JurisdictionDepartment[], jurisdiction: Jurisdiction): void {
        let departments: Department[] = jurisdiction.departments ? jurisdiction.departments : [];
        let configCompliances = Compliance.jurisdictionDepartmentsToCompliances(jurisdictionDepartments, this.matterType, jurisdiction.waterHydroDeptType);

        //if jurisdiction changes, add any new departments and update some existing ones
        for (let department of departments) {

            let matterCompliance: Compliance = this.matterCompliances.find(compliance => compliance.departmentPriority === department.departmentPriority);
            let configCompliance: Compliance = configCompliances.find(compliance => compliance.departmentPriority === department.departmentPriority);

            // Existing compliance
            if (matterCompliance && configCompliance) {
                matterCompliance.writeTo = configCompliance.writeTo;
                matterCompliance.hst = department.hst;
                matterCompliance.fee = department.fee;
                if (matterCompliance.departmentName == Water_Department && jurisdiction.waterHydroDeptType == WaterHydroDeptType_IS_COMBINED) {
                    matterCompliance.departmentName = Combined_Hydro_Water_Department;
                }
            } // New Compliance
            else if (configCompliance) {
                configCompliance.hst = department.hst;
                configCompliance.fee = department.fee;
                this.matterCompliances.push(new Compliance(configCompliance));
            }
        }
        this.matterCompliances = this.matterCompliances
                                     .filter(compliance => configCompliances.find(keepDepartment => keepDepartment.departmentPriority === compliance.departmentPriority));

        this.matterCompliances = _.sortBy(this.matterCompliances, ['departmentPriority']);
    }

    addCondominiumParticipant() {
        const condoCorporationContact = new Contact();
        condoCorporationContact.setDefaultValues('CONDO_CORPORATION', 'CORPORATION');
        this.addMatterParticipant(condoCorporationContact, true, 'CONDO_CORPORATION');
    }

    onCondominumChange(doNotCreateAdjustment? : boolean): void {
        if (this.matterPropertyWithCondo && this.matterPropertyWithCondo.isPropertyCondominium()) {
            if (!this.condoCorporation) {
                this.addCondominiumParticipant()
            }
            if (this.soaTrustLedgerCollection) {
                this.soaTrustLedgerCollection.addOrRemoveCondoFee();
                if(this.secondarySoaSheetsCollection){
                    this.secondarySoaSheetsCollection.forEach( collection =>{
                        collection.addOrRemoveCondoFee();
                    })
                }

            }
        }
        else if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.removeCondoFee();
            if(this.secondarySoaSheetsCollection){
                this.secondarySoaSheetsCollection.forEach( collection =>{
                    collection.removeCondoFee();
                })
            }

        }
        this.createUpdateAdjustmentCommonExp(undefined, undefined,doNotCreateAdjustment);
    }


    //only applied in Mortgage Matter.
    //DPPMP-11254, if we are acting For's value is any starts with BOTH, then disply the information msg that Other Solicitor info can not be entered
    get actingForBothPartiesInMortgageMatter(): boolean {
        if (this.isMortgage) {
            if (this.actingFor) {
                return this.actingFor.startsWith("BOTH_");
            }
        }
        return false;
    }


    get matterOrderNumber(): string {
        let matterBilling: MatterBilling = this.billingTransactions.find((element) => {
            return element.transactionType === TransactionType.CHARGE;
        });
        return matterBilling ? matterBilling.orderNumber : '';
    }

    deleteMortgage(selectedMortgage: Mortgage): void {
        let myClientMortgagee = this.getMyClientMortgagee();
        let isUpdateMyClientMortgageeNeeded = myClientMortgagee && myClientMortgagee.mortgageId == selectedMortgage.id;
        let mortgageIndex: number = _.findIndex(this.mortgages, mortgage => mortgage === selectedMortgage);
        let mortgageId: number = selectedMortgage.id;
        if (mortgageIndex >= 0) {
            this.mortgages.splice(mortgageIndex, 1);
        }
        MatterCleanUpUtil.cleanUpMortgageParticipant(this);
        if(isUpdateMyClientMortgageeNeeded){
            MatterUtil.setFirstMortgageeMyClientFlag(this);
        }
        MatterCleanUpUtil.cleanUpMortgageAdvances(this, selectedMortgage.id);
        MatterEFormUtil.removeEregistrationFormByMortgage(this, mortgageId);
    }

    get postClosingAddress(): string {
        if (this.matterContactInfo && this.matterContactInfo.postClosingAddress && this.matterContactInfo.postClosingAddress.wrapperSameAsAddressTypeCode === AddressTypes.subjectAddress) {
            return this.matterPropertyWithCondo.address.addressTextWithProvinceName;
        } else if (this.matterContactInfo && this.matterContactInfo.postClosingAddress && this.matterContactInfo.postClosingAddress.wrapperSameAsAddressTypeCode === AddressTypes.preClosingAddress) {
            return this.matterContactInfo.preClosingAddress.addressTextWithProvinceName;
        } else if (this.matterContactInfo && this.matterContactInfo.postClosingAddress) {
            return this.matterContactInfo.postClosingAddress.addressTextWithProvinceName;
        } else {
            return '';
        }
    }

    // clearAdjustmentsIdentifiers(): void{
    //     this.adjustments.forEach( adj => adj.clearAllIdentifiers());
    //     this._interimAdjustments.forEach( adj => adj.clearAllIdentifiers());
    //     this._finalAdjustments.forEach( adj => adj.clearAllIdentifiers());
    // }


    get matterOccupancyDate(): string {
        if (this.occupancyDate) {
            return this.occupancyDate;
        } else if (this.requisitionDate) {
            return this.requisitionDate;
        } else if (this.templateForProject) {
            return this.matterCloseDate;
        }
        return null;
    }


    initializePaidOnOccupancyDeposit(): void {
        let paidOnOccupancyDeposit: Deposit = new Deposit();
        paidOnOccupancyDeposit.depositName = DEPOSIT_PAID_ON_OCCUPANCY;
        paidOnOccupancyDeposit.paidOnOccupancy = true;
        this.addDepositToPrimaryProperty(paidOnOccupancyDeposit);
    }

    addDepositToPrimaryProperty(deposit: Deposit): void {
        if (!this.matterPropertyWithCondo.deposits) {
            this.matterPropertyWithCondo.deposits = [];
        }
        this.matterPropertyWithCondo.deposits.push(deposit);
    }


    /**
     * Update balanceOfFundsPayableTo based on directionReFunds , sale price and deposit
     */
    public updateBalanceOfFundsPayableTo(): void {
        let amount: number = 0;
        if (Array.isArray(this.filteredDirectionReFunds) && this.filteredDirectionReFunds.length > 0) {
            for (let i = 0; i < this.filteredDirectionReFunds.length; i++) {
                if (this.filteredDirectionReFunds[i].directionReFundType === DirectionReFundTypes.FUNDS_PAYABLE_TO
                    && this.filteredDirectionReFunds[i].amount) {
                    amount = Number(amount) + Number(this.filteredDirectionReFunds[i].amount);
                }
            }
        }

        this.calculateDirectionRefundBalanceOfFundsPayableTo(amount);
        if (this.isSale) {
            //update mortgagePayout.amountPayableToDischarge;
            const mortgage = this.getExistingDischargedBridgeMtgWithPayoutEqualsBalance();
            if (mortgage && mortgage.mortgagePayout) {
                if (this.isProjectConfigDocForDirectionReFund() && this._finalDirectionReFunds && this._finalDirectionReFunds.length) {
                    mortgage.mortgagePayout.amountPayableToDischarge = this._finalDirectionReFunds[0].amount;
                } else {
                    mortgage.mortgagePayout.amountPayableToDischarge = this.balanceOfFundsPayableTo.amount;
                }

                mortgage.amountPayableToDischarge = mortgage.mortgagePayout.amountPayableToDischarge;
            }
        }
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.updateF9ForPaidToYouAndOtherTrustLedgers();
        }
    }

    calculateDirectionRefundBalanceOfFundsPayableTo(amount: number): void {
        if (this.isStatementAdjustmentInterimAndFinal()          // Statement of adjustment has 2 views
            && !this.isProjectConfigDocForDirectionReFund()     // Direction refund has one view
            && !this.isSelectedAdjustmentStatusModeFinal        // SelectedProgressionStatus is Interim
            && this.adjustmentStatusMode != this.selectedProgressionStatus) {
            //The statement of adjustment has 2 views & the direction refund has one view
            //Then balanceOfFundsPayableTo should be calculated according to the interim mode
            let currentAdjustmentStatusMode = this.adjustmentStatusMode;
            this.adjustmentStatusMode = ProgressionStatus.INTERIM;
            this.balanceOfFundsPayableTo.amount = Number(this.calculateBalanceDueOnClosingCreditPurchaser()) - Number(amount);
            this.adjustmentStatusMode = currentAdjustmentStatusMode;
        } else {
            if (this.isMatterProvinceABorMBorSK) {
                if (this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.receiptTotal) {
                    // this.syncAdjModesAndExecute( () => { this.balanceOfFundsPayableTo.amount = Number(this.soaTrustLedgerCollection.receiptTotal.valueOf()) - Number(amount); } );

                    this.balanceOfFundsPayableTo.amount =
                        Number(this.soaTrustLedgerCollection.getReceiptTotalByProgressionStatus(this.balanceOfFundsPayableTo.progressionStatus))
                        - Number(amount);

                } else {
                    this.balanceOfFundsPayableTo.amount = 0;
                }
            } else {
                this.balanceOfFundsPayableTo.amount = Number(this.calculateBalanceDueOnClosingCreditPurchaser()) - Number(amount);
            }
        }
    }

    syncAdjModesAndExecute(func: Function): void {
        if (func) {
            if (this.isStatementAdjustmentInterimAndFinal() && this.adjustmentStatusMode !== this.selectedProgressionStatus) {
                let currentAdjustmentStatusMode = this.adjustmentStatusMode;
                try {
                    this.adjustmentStatusMode = this.selectedProgressionStatus;
                    func();
                } finally {
                    this.adjustmentStatusMode = currentAdjustmentStatusMode;
                }
            } else {
                func();
            }
        }
    }

    /**
     * Update undertakings based on existing mortgage(s)
     */
    public updateUndertakings(undertakingsConfigService: UndertakingsConfigService): void {
        if (this.isMortgage || this.isSale) {
            for (let mortgage of this.existingMortgages) {
                this.assignInstrumentReference(mortgage);
                this.updateUndertaking(mortgage, undertakingsConfigService);
                if (this.isMatterProvinceAB || this.isMatterProvinceSK || this.isMatterProvinceMB) {
                    this.updatePermittedRegistration(mortgage);
                }
                mortgage.undertakingDirty = false; //clear flag as update(s) done
            }
        }
    }

    //assign reference to instrument if this mortgage was created from an instrument
    private assignInstrumentReference(mortgage: Mortgage): void {
        if (!mortgage.instrumentId) {
            if (mortgage.undertakingId) {
                let undertaking: MatterUndertaking = this.matterUndertakings.find((undertaking: MatterUndertaking) => mortgage.undertakingId == undertaking.id);
                mortgage.instrumentId = undertaking ? undertaking.instrumentId : null;
            } else if (mortgage.permittedRegistrationId) {
                let permittedRegistration: PermittedRegistration = this.permittedRegistrations.find((permittedRegistration: PermittedRegistration) => mortgage.permittedRegistrationId == permittedRegistration.id);
                mortgage.instrumentId = permittedRegistration ? permittedRegistration.instrumentId : null;
            }
        }
    }

    private updateUndertaking(mortgage: Mortgage, undertakingsConfigService: UndertakingsConfigService) {
        //check if there are any existing undertakings for mortgage
        let undertaking: MatterUndertaking = this.matterUndertakings.find(undertaking => undertaking.id == mortgage.undertakingId);

        if (mortgage.mortgageDispositionType != MortgageDispositionType.DISCHARGED && undertaking) {
            //mortgage is no longer "to be discharged" but has an undertaking, so need to delete it
            mortgage.undertakingId = null;
            this.matterUndertakings.splice(this.matterUndertakings.indexOf(undertaking), 1);
        } else if (mortgage.mortgageDispositionType == MortgageDispositionType.DISCHARGED && mortgage.undertakingDirty) {
            if (!undertaking) {
                undertaking = new MatterUndertaking(); //create new undertaking if necessary
                undertaking.matterUndertakingStatus = <MatterUndertakingStatus>MatterUndertakingStatusValues.outstanding;
            }

            undertaking.updateUndertakingFromMortgage(this, mortgage, undertakingsConfigService, undertaking.matterUndertakingStatus);

            if (!mortgage.undertakingId) {
                mortgage.undertakingId = undertaking.id;
                this.matterUndertakings.push(undertaking);
            }
        }
    }

    private updatePermittedRegistration(mortgage: Mortgage) {
        //check if there are any existing permitted registrations for mortgage
        let permittedRegistration: PermittedRegistration = this.permittedRegistrations.find(permittedRegistration => permittedRegistration.id == mortgage.permittedRegistrationId);

        if (mortgage.mortgageDispositionType != MortgageDispositionType.ASSUMED && mortgage.mortgageDispositionType != MortgageDispositionType.REMAIN && permittedRegistration) {
            //mortgage is no longer "to be assumed/remain" but has a permitted registration, so need to delete it
            mortgage.permittedRegistrationId = null;
            this.permittedRegistrations.splice(this.permittedRegistrations.indexOf(permittedRegistration), 1);
        } else if ((mortgage.mortgageDispositionType == MortgageDispositionType.ASSUMED || mortgage.mortgageDispositionType == MortgageDispositionType.REMAIN) && mortgage.undertakingDirty) {
            if (!permittedRegistration) {
                permittedRegistration = new PermittedRegistration(); //create new permitted registration if necessary
            }

            permittedRegistration.updateFromMortgage(this, mortgage);

            if (!mortgage.permittedRegistrationId) {
                mortgage.permittedRegistrationId = permittedRegistration.id;
                this.permittedRegistrations.push(permittedRegistration);
            }
        }
    }

    deleteUndertaking(undertaking: MatterUndertaking): void {
        if (undertaking) {
            const idx = Array.isArray(this.matterUndertakings)
                && this.matterUndertakings.findIndex((item: MatterUndertaking) =>
                    item.identifier === undertaking.identifier);
            if (idx > -1) {
                this.matterUndertakings.splice(idx, 1);
                this.dirty = true;
            }
        }
    }


    get txnType(): string {
        if (this.isSale) {
            return "Sale";
        }
        if (this.isMortgage) {
            return "NA";
        }
        return "Purchase";
    }

    get newMortgages(): Mortgage[] {
        return this.mortgages.filter(item => item.isUnityNewMortgage());
    }

    get newOrEmpMortgages(): Mortgage[] {
        return this.mortgages.filter(item => item.isUnityNewMortgage() || item.isEmpMortgage());
    }

    isAnyVTBMortgages(): boolean {
        return (this.isSale && this.newMortgages && this.newMortgages.length) || (this.isPurchase && this.newMortgages && (this.newMortgages.some((mortgage: Mortgage) => mortgage.isLoanTypeBackToVendor()))) ? true : false;
    }


    //try to get the purchasers' capacity, can be from Tab B or Tab C
    participantPurchaserCapacity(participantId: number, participant: MatterParticipant[]): string {
        let capacity: string = (this.matterType == 'SALE') ? this.otherPartiesCapacity : this.purchasersCapacity;

        if (capacity === 'OTHER') {
            let purchaserCapacityOption = PurchaserCapacity.purchaserCapacityOptions.find(option => option.value === this.purchasers.find(p => p.matterParticipantId === participantId).purchaserCapacity);
            return purchaserCapacityOption ? purchaserCapacityOption.label : '';
        } else {
            let noOfPurchasers = participant.length;
            let capacityOption = PurchaserCapacity.getMatterPurchasersCapacityOptions(noOfPurchasers, this.provinceCode).find(option => option.value == capacity);
            return capacityOption ? capacityOption.label : '';
        }
    }

    mortgageePurchaserCapacity(participantId: number, mortgage: Mortgage): string {
        if (mortgage.mortgageCapacityType === 'OTHER') {
            let mortgageeCapacityOption = PurchaserCapacity.purchaserCapacityOptions.find(option => option.value === this.matterParticipants.find(p => p.matterParticipantId === participantId).purchaserCapacity);
            return mortgageeCapacityOption ? mortgageeCapacityOption.label : '';
        } else {
            let noOfPrivateLenders = this.matterParticipants.filter(p => p.mortgageId === mortgage.id).length
            let capacityOption = PurchaserCapacity.getMortgageCapacityOptions(noOfPrivateLenders, this.provinceCode).find(option => option.value == mortgage.mortgageCapacityType);
            return capacityOption ? capacityOption.label : '';
        }

    }


    existingDispositionDischargedMortgage(): Mortgage[] {
        return this.existingMortgages ? this.existingMortgages.filter(item => item.isMortgageDispositionDischarged()) : [];
    }

    existingMortgageIndex(mortgage: Mortgage): number {
        return this.existingMortgages ? this.existingMortgages.findIndex(item => item.id == mortgage.id) : -1;
    }

    get missingMortgagePriority(): number[] {
        let missingMortgagePriority: number[] = [];
        let existingMortgages = this.existingMortgages ? this.existingMortgages : [];
        if ((this.isMortgage) && this.mortgages && this.mortgages.length > 0) {
            let maxMortgagePriority = Math.max(... this.mortgages.map(item => {
                return item.mortgagePriority;
            }));
            for (let i = 1; i < maxMortgagePriority; i++) {
                if (existingMortgages.filter(item => item.isMortgageDispositionRemain()).findIndex(item => item.mortgagePriority == i) < 0
                    && this.mortgages.findIndex(item => item.mortgagePriority == i) < 0) {
                    missingMortgagePriority.push(i);
                }
            }

        }
        return missingMortgagePriority;
    }

    mortgageListAfterClosing(): Mortgage[] {
        return (this.existingMortgages ? this.existingMortgages.filter(item => item.isMortgageDispositionRemain()) : []).concat(this.mortgages ? this.mortgages : []);
    }

    // Centralize method to get Mortgage Priority

    occupiedMortgagePriority(): any[] {
        let availableList = this.mortgageListAfterClosing();
        return availableList && availableList.length > 0 ? (this.existingMortgages.filter(item => item.isMortgageDispositionRemain()).concat(this.mortgages ? this.mortgages : []).map(mortgage => {
            return Number(mortgage.mortgagePriority);
        })) : [];
    }

    availableMortgagePriority(assignedPriority: number): any[] {
        let mortgagePriority = [];
        for (let i = 1; i < 17; i++) {
            if (this.occupiedMortgagePriority().indexOf(i) < 0 || i == assignedPriority) {
                mortgagePriority.push({label: Utils.getOrdinal(i), value: i});
            }
        }
        return mortgagePriority;
    }

    generateMortgageId(): number {
        return UUIDUtil.getUUID();
    }

    createMortgage(mortgageType: MortgageType, mortgageSource: MortgageSource = 'UNITY', newMortgagePriority?: number): Mortgage {
        return MatterMortgageUtil.createMortgage(this, mortgageType, mortgageSource, newMortgagePriority);
    }

    addNewExistingMortgage(): Mortgage {
       return MatterMortgageUtil.addNewExistingMortgage(this);
    }

    addNewVtbMortgage(): Mortgage {
        return MatterMortgageUtil.addNewVtbMortgage(this);
    }

    getMaxPriorityUsedByNewMortgage(): number {
        if (Array.isArray(this.mortgages) && this.mortgages.length > 0) {
            return Math.max.apply(Math, this.mortgages.map(mortgage => mortgage.mortgagePriority));
        }
        return 0;
    }

    deleteExistingMortgage(selectedMortgage: Mortgage, errorService?: ErrorService): void {
        MatterMortgageUtil.deleteExistingMortgage(this, selectedMortgage, errorService);
    }

    deleteExistingMortgageWithCleanup(deletedIndex: number, mortgage: Mortgage, errorService?: ErrorService) {
        MatterMortgageUtil.deleteExistingMortgageWithCleanup(this, deletedIndex, mortgage, errorService);
    }

    deleteVtbMortgage(vtbMortgage: Mortgage, mortgageSoAdjService: MortgageSoAdjService) {
        MatterMortgageUtil.deleteVtbMortgage(this, vtbMortgage, mortgageSoAdjService);
    }

    getMortgageIndexByMortgage(mortgage: Mortgage): number {
        //this.matter.existingMortgage  or  this.matter.mortgages
        let index: number;
        if (mortgage.isExistingMortgage()) {
            index = this.existingMortgages.findIndex(item => item.id == mortgage.id)
        } else {
            index = this.mortgages.findIndex(item => item.id == mortgage.id)
        }

        return index;
    }

    updateStatementOfAccountForMortgage(mortgage: Mortgage): void {
        const mortgageIndex: number = this.getMortgageIndexByMortgage(mortgage);
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.addOrRemoveFeeForMortgage(mortgage, mortgageIndex);
            if(this.secondarySoaSheetsCollection){
                this.secondarySoaSheetsCollection.forEach( collection =>{
                    collection.addOrRemoveFeeForMortgage(mortgage, mortgageIndex);
                })
            }
            if (!mortgage.isExistingMortgage() && (this.isPurchase || this.isMortgage)) {
                this.soaTrustLedgerCollection.addTrustLedgerMortgageRow(mortgageIndex, mortgage.isLoanTypeBridge(), mortgage.isLoanTypeArranged());
            }
            if (mortgage.isExistingMortgage() && (this.isSale || this.isMortgage)) {
                this.soaTrustLedgerCollection.addTrustLedgerExistingMortgageRow(mortgageIndex,
                    mortgage.isMortgageDispositionTypeBridgeFinancing(),
                    mortgage.isMortgageDispositionTypeDischarged(), mortgage);
            }
            mortgage.mortgageeType === 'PRIVATE_LENDER' ?
                this.reCalculateTrustLedgerPrivateLenderLineByMortgage(mortgage) :
                this.reCalculateTrustLedgerMortgageeLineByMortgage(mortgage);
            this.soaTrustLedgerCollection.updateERegAndRegisterCharges();
            if(this.secondarySoaSheetsCollection){
                this.secondarySoaSheetsCollection.forEach( collection =>{
                    collection.updateERegAndRegisterCharges();
                })
            }
        }
    }

    get isCondoCorporation(): boolean {
        return this.isPropertyCondo();
    }

    getMortgagePriorityByMortgageIndex(mortgageIndex: number): number {
        if (Array.isArray(this.mortgages) && this.mortgages.length > 0 &&
            this.mortgages.length > mortgageIndex && mortgageIndex >= 0) {
            const mortgage: Mortgage = this.mortgages[mortgageIndex];
            return mortgage.mortgagePriority;
        }

        return -1;
    }

    getMortgageePrivateLenderNamesByMortgageIndex(mortgageIndex: number): string {
        if (Array.isArray(this.mortgages) && this.mortgages.length > 0 &&
            this.mortgages.length > mortgageIndex && mortgageIndex >= 0) {
            const mortgage: Mortgage = this.mortgages[mortgageIndex];

            let reLineNames: string[] = [];

            const excludedParticipants: MatterParticipant[]
                = (mortgage.mortgageeType === 'PRIVATE_LENDER') ? this.getPrivateLenders(mortgage) : this.getMortgagees(mortgage);
            if (Array.isArray(excludedParticipants)) {
                excludedParticipants.forEach((matterParticipant: MatterParticipant) => {
                    let nameForReLine: string = matterParticipant.contact.reLineFirstNameFormat;
                    if (!reLineNames.some(x => x === nameForReLine)) {
                        reLineNames.push(nameForReLine);
                    }
                });
                return reLineNames.length < 2 ? reLineNames.join(', ') : reLineNames.slice(0, -1).join(', ') + ' and ' + reLineNames.slice(-1);
            }
        }

        return '';
    }

    updateTrustLedgerItemNameForUpdatePriority(): void {
        this.updateTrustLedgerItemNameForUpdatePrioritySingleView();
        if (this.soaTrustLedgerCollection && this.isProjectConfigDocForTrustLedger()) {
            let progressStatus = this.soaTrustLedgerCollection.progressionStatus;
            this.soaTrustLedgerCollection.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
            this.updateTrustLedgerItemNameForUpdatePrioritySingleView();
            this.soaTrustLedgerCollection.progressionStatus = progressStatus;
        }
    }

    updateTrustLedgerItemNameForUpdatePrioritySingleView(): void {
        MatterTrustLedgerUtil.updateTrustLedgerItemNameForUpdatePrioritySingleView(this);
    }

    buildTrustLedgerMatterMortgageObjectTemplate(): TrustLedgerMatterMortgageObjectTemplate[] {
       return MatterTrustLedgerUtil.buildTrustLedgerMatterMortgageObjectTemplate(this);
    }

    updateTrustLedgerMatterPriorityInTemplate(templateList: TrustLedgerMatterMortgageObjectTemplate[]) {
        if (templateList && Array.isArray(templateList) && this.mortgages) {
            templateList.forEach(item => {
                item.trustLedgerMatter.fromMortgageIndex = this.mortgages.findIndex(mortgage => mortgage.id === item.mortgage.id);
            });
        }

    }

    sortTrustLedgerMatterByPriority() {
        MatterTrustLedgerUtil.sortTrustLedgerMatterByPriority(this);
    }

    // For SoaTrustLedgeConfigKeys.L1_PM
    sortTrustLedgerMatterByMortgageIndex(configKey: string, extraKey?: string) {
        MatterTrustLedgerUtil.sortTrustLedgerMatterByMortgageIndex(this, configKey, extraKey);
    }

    // === The start of direction-re-funds Section ===

    // For Menu Option 2
    // OTHERPARTY_LAW_FIRM  is MatterParticipantRole of PURCHASE matter
    // OTHERPARTY_LAW_FIRM  is MatterParticipantRole of SALE matter
    // OTHERPARTY_LAW_FIRM  is MatterParticipantRole of MORTGAGE matter
    get otherSideClientFirmName(): string {
        const otherPartyLawFirmParticipant: MatterParticipant
            = this.otherPartyLawFirm;

        return otherPartyLawFirmParticipant && otherPartyLawFirmParticipant.contact
            ? otherPartyLawFirmParticipant.contact.organizationName + ', in trust' : null;

    }

    // For Menu Option 3
    get mainClientsNamesArray(): string[] {
        let mainClientParticipants: MatterParticipant[] = this.mainClients;
        let names: string[] = [];

        if (!mainClientParticipants || (Array.isArray(mainClientParticipants) && mainClientParticipants.length === 0)) {
            return null;
        }

        names = mainClientParticipants.map((participant) => {
            return participant.contact && participant.contact.genericName;
        });

        return names;
    }

    get mainClientsName(): string {
        const mainTypeName: string = this.mainClientTypeName;

        return Utils.concatenateNames(this.mainClientsNamesArray, mainTypeName);
    }

    // For Menu Option 4
    get otherPartyParticipantsName(): string {
        let otherSideClientParticipant: MatterParticipant[] = this.otherSideClients;
        let otherSideClientTypeName = this.otherSideClientTypeName;
        let names: string[] = [];

        if (!otherSideClientParticipant
            || (Array.isArray(otherSideClientParticipant) && otherSideClientParticipant.length === 0)
            || this.isMortgage) {
            return null;
        }

        names = otherSideClientParticipant.map((participant) => {
            return participant.contact && participant.contact.genericName;
        });

        return Utils.concatenateNames(names, otherSideClientTypeName);
    }

    get supplementalCategoryPayBillsOrDebts(): MatterSupplementalTaskCategory[] {
        return this.supplementalTasks.filter(supplementalTask => supplementalTask.categoryName === 'PAY BILLS OR DEBTS');
    }

    // === The end of direction-re-funds Section ===


    updateAdjustments(adjustmentDate: string, statementAdjustmentDisplayUtil?: StatementAdjustmentDisplayBuilder): void {
        MatterStatementAdjustmentUtil.updateAdjustments(this, adjustmentDate, statementAdjustmentDisplayUtil);
    }

    recalculateAllInterimOccupancyFee(project: Project) {
        let elapsedDays = this.isMatterProvinceAB ?  Number( moment(this.getInterimAdjustmentDate(), "YYYY/MM/DD").date())  : Number(this.getElapsedDays(this.getInterimAdjustmentDate()));
        let numberOfDaysInMonth = Number(this.getNumberofDaysMonth(this.getInterimAdjustmentDate()));
        this.interimStatementAdjustments.filter(item => item.isInterimOccupancyFee())
            .forEach((item: StatementAdjustment) => {
                item.updateInterimOccupancyFeeOnClosingDateChange(elapsedDays, numberOfDaysInMonth, this, project);
            });
    }

    recalculateAllTaxesPaidDuringOccupancy() {
        this.statementOfAdjustments.filter(item => item.isTaxesPaidDuringOccupancy() && item.taxPortion)
            .forEach((statementAdjustment: StatementAdjustment) => {
                statementAdjustment.amount = TaxesPaidDuringOccupancyUtil.calculateShareForTaxPortion(statementAdjustment.taxPortion, this);
            });
    }

    recalculateAllTenancyCurrent(){
        // update Tenancy Current
        this.statementOfAdjustments.filter(item => item.isTenancyCurrent())
            .forEach((item: StatementAdjustment) => {
                item.amount = item.soAdjTenancyCurrent.getTotalAmount(this.getClosingDate(), this.isPaysForDateOfClosingVendor);

            });
    }

    recalculateAllAssumedMortgage(){
        // updateAssumedMortgage()
        this.statementOfAdjustments.filter(item => item.isAssumedMortgage())
            .forEach((item: StatementAdjustment) => {
                item.soAdjAssumedMortgage.updateTotalAmount(this.getClosingDate(), this.isPaysForDateOfClosingVendor);
                item.amount = item.soAdjAssumedMortgage.totalCreditPurchaser;
                item.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
            });
    }

    updateInterimOccupancyFeeAdjOnChange(){
        if(this.project){
            this.recalculateAllInterimOccupancyFee(this.project);
            this.recalculateAllFinalOccupancyFee();
            this.recalculateTaxesPaidDuringOccupancy();
        }
    }

    // FinalEarlyPossessionFees might get their amount from InterimEarlyPossessionFee
    recalculateAllFinalEarlyPossessionFee() {
        let interimAdj: StatementAdjustment = this.allStatementAdjustments.find((adj: StatementAdjustment) => adj.isInterimEarlyPossessionFee());
        let newAmount: number = interimAdj ? Number(interimAdj.soAdjInterimEarlyPossessionFee.total) : 0;
        // DPPMP-37851 when "SPECIFY" are not selected in adjustAsAtClosingDateFlagInterim/adjustAsAtClosingDateFlag,
        // null were passed in updateFinalEarlyPossessionFeeOnClosingDateChange method
        // because this.adjustAsAtClosingDateInterim and this.adjustAsAtClosingDate were null
        // interimAdjustAsDate/finalAdjustAsDate should depend on adjustAsAtClosingDateFlagInterim/adjustAsAtClosingDateFlag condition to get value
        let interimAdjustAsDate: string = this.adjustAsAtClosingDateFlagInterim === 'SPECIFY' ? this.adjustAsAtClosingDateInterim : this.matterCloseDate;
        let finalAdjustAsDate: string = this.adjustAsAtClosingDateFlag === 'SPECIFY' ? this.adjustAsAtClosingDate : this.matterCloseDate;

        this.allStatementAdjustments.filter(adj => adj.isFinalEarlyPossessionFee()).forEach(adj => {
            if (adj.soAdjFinalEarlyPossessionFee.useAmountFromInterim === 'YES') {
                adj.soAdjFinalEarlyPossessionFee.amount = newAmount;
            }
            adj.updateFinalEarlyPossessionFeeOnClosingDateChange(this.occupancyDate, interimAdjustAsDate, finalAdjustAsDate);
        });
    }

    // FinalOccupancyFees might get their amount from InterimOccupancyFee
    getInterimAdjustment(statementAdjustment: StatementAdjustment[]): StatementAdjustment{
        if (this.project && this.project.isNonCondoPotlON){
            return statementAdjustment.find((adj: StatementAdjustment) => adj.isInterimEarlyPossessionFee());
        }else{
            return statementAdjustment.find((adj: StatementAdjustment) => adj.isInterimOccupancyFee());
        }
    }

    getSoAdjInterimFee(interimAdj: StatementAdjustment): SoAdjInterimFee{
        if (this.project && this.project.isNonCondoPotlON){
            return interimAdj.soAdjInterimEarlyPossessionFee;
        }else{
            return interimAdj.soAdjInterimOccupancyFee;
        }
    }
    recalculateAllFinalOccupancyFee() {
        //let interimAdj: StatementAdjustment = this.allStatementAdjustments.find((adj: StatementAdjustment) => adj.isInterimOccupancyFee());
        let interimAdj: StatementAdjustment = this.getInterimAdjustment(this.allStatementAdjustments);
        let newAmount: number = interimAdj ? Number(this.getSoAdjInterimFee(interimAdj).total) : 0;
        let newAmountWithTax: number = interimAdj ? Number(this.getSoAdjInterimFee(interimAdj).total) - Number(this.getSoAdjInterimFee(interimAdj).taxesTotal) : 0;
        let interimAdjustAsDate: string = this.adjustAsAtClosingDateFlagInterim === 'SPECIFY' ? this.adjustAsAtClosingDateInterim : this.matterCloseDate;
        let finalAdjustAsDate: string = this.adjustAsAtClosingDateFlag === 'SPECIFY' ? this.adjustAsAtClosingDate : this.matterCloseDate;

        this.allStatementAdjustments.filter(adj => adj.isFinalOccupancyFee()).forEach(adj => {

            if (adj.soAdjFinalOccupancyFee.useAmountFromInterim === 'YES') {
                adj.soAdjFinalOccupancyFee.amount = newAmount;
            } else if (adj.soAdjFinalOccupancyFee.useAmountFromInterim === 'YES_EXCLUDING_TAXES') {
                adj.soAdjFinalOccupancyFee.amount = newAmountWithTax;
            }
            adj.updateFinalOccupancyFeeOnClosingDateChange(this.occupancyDate, interimAdjustAsDate, finalAdjustAsDate,this.isPaysForDateOfClosingPurchaser, this.provinceCode);
        });
        this.recalculateTotalFinalOccupancyFee();
    }

    recalculateTotalFinalOccupancyFee() {
        this.finalStatementAdjustments.filter(adj => adj.isTotalOccupancyFee()).forEach(adj => {
            if (adj.soAdjTotalOccupancyFee) {
                adj.updateTotalOccupancyFeeOnClosingDateChange(this, this.project);
            }
        });
    }

    // recalulate used when changing occupancy date and adjusting Interim Occupancy Adjustment
    // taxesPaidDuringOccupancy adjustment only on final adjustment, using TotalTax from Interim Occupancy Adjustment
    // If interimOccupancyFeeSoaTaxesTotalChanged is not passed, it should use the following condition
    //(interimOccupancyFeeAdj && interimOccupancyFeeAdj.soAdjInterimOccupancyFee && interimOccupancyFeeAdj.soAdjInterimOccupancyFee.taxesTotal &&
    // interimOccupancyFeeAdj.soAdjInterimOccupancyFee.taxesTotal>0 && finalTaxesPaidDuringOccupancyAdj)
    // If interimOccupancyFeeSoaTaxesTotalChanged is passed, it should use interimOccupancyFeeSoaTaxesTotalChanged && finalTaxesPaidDuringOccupancyAdj
    recalculateTaxesPaidDuringOccupancy(interimOccupancyFeeSoaTaxesTotalChanged?: boolean): void {
        let interimOccupancyFeeAdj: StatementAdjustment = this.interimStatementAdjustments.find((adj: StatementAdjustment) => adj.isInterimOccupancyFee());
        let finalTaxesPaidDuringOccupancyAdj: StatementAdjustment = this.finalStatementAdjustments.find((adj: StatementAdjustment) => adj.isTaxesPaidDuringOccupancy());
        // Add "!!" to fix the compile issue
        const updateFinalTaxesPaidDuringOccupancyAdj: boolean = !!(interimOccupancyFeeSoaTaxesTotalChanged === undefined
                                        ? (interimOccupancyFeeAdj
                                           && interimOccupancyFeeAdj.soAdjInterimOccupancyFee
                                           && interimOccupancyFeeAdj.soAdjInterimOccupancyFee.taxesTotal
                                           && interimOccupancyFeeAdj.soAdjInterimOccupancyFee.taxesTotal>0
                                           && finalTaxesPaidDuringOccupancyAdj)
                                        : (interimOccupancyFeeSoaTaxesTotalChanged && finalTaxesPaidDuringOccupancyAdj));
        if (updateFinalTaxesPaidDuringOccupancyAdj) {
            const interimOccupancyFeeSoaTaxesTotal: number = (interimOccupancyFeeAdj && interimOccupancyFeeAdj.soAdjInterimOccupancyFee && interimOccupancyFeeAdj.soAdjInterimOccupancyFee.taxesTotal) ? +interimOccupancyFeeAdj.soAdjInterimOccupancyFee.taxesTotal : 0;
            finalTaxesPaidDuringOccupancyAdj.taxPortion = interimOccupancyFeeSoaTaxesTotal * 12; //the interimOccupancyFee's taxesTotal is month based value
            finalTaxesPaidDuringOccupancyAdj.amount = TaxesPaidDuringOccupancyUtil.calculateShareForTaxPortion(finalTaxesPaidDuringOccupancyAdj.taxPortion, this);
        }

    }

    recalculateForm4Charges(): void {
        let interimForm4Adjs: StatementAdjustment[] = this.allStatementAdjustments.filter((adj: StatementAdjustment) => adj.isForm4Charges());
        interimForm4Adjs.forEach(adj => {
            if (adj && adj.soAdjForm4Charge) {
                if (!adj.soAdjForm4Charge.overrideCalculatedValue && this.matterPropertyWithCondo && this.matterPropertyWithCondo.deposits) {
                    adj.soAdjForm4Charge.numberOfForm4Issued = adj.soAdjForm4Charge.getNumberOfDepositsWithForm4MarkedAsSent(this.matterPropertyWithCondo.deposits);
                }
                adj.amount = adj.soAdjForm4Charge.calculateForm4Total;
            }
        });

    }

    getPropertyTaxAdjustmentDescription(statementAdjustmentKey: string, matterTax: MatterTax): string {
        if (statementAdjustmentKey === StatementAdjustmentKey.REALTY_TAXES_PAID_BY_VENDOR) {
            return matterTax.getAdjustmentHeadingForPaidByVendor().toUpperCase();
        }

        let selectItem: SelectItem[] = _.cloneDeep(provinceBasedTaxTypes[this.provinceCode]);
        let taxTypeSelected = _.find(selectItem, taxType => taxType.value == matterTax.taxType);
        return taxTypeSelected && taxTypeSelected.label;
    }

    recalculateTaxBeginningOfYearUntilOcc(statementAdjustment: StatementAdjustment): void {
        if (statementAdjustment && statementAdjustment.matterTax) {
            // interim and final might have different settings
            let ptac: ProjectTaxAdjustmentConfig = statementAdjustment.isAdjustmentStatusFinal() ? this.projectTaxAdjustmentConfigFinal : this.projectTaxAdjustmentConfigInterim;
            statementAdjustment.amount = statementAdjustment.matterTax.calculateCreditAmountForTaxBeginningOfYearUntilOcc(ptac, InterimTaxMultiplier, this.adjustmentDateForTaxBeginningOfYearUntilOcc, this.isPaysForDateOfClosingVendor, this.isProjectProportionateShare);
            statementAdjustment.description = this.getPropertyTaxAdjustmentDescription(statementAdjustment.itemKey, statementAdjustment.matterTax);
            statementAdjustment.statementOfAdjustmentCreditType = PropertyTaxesUtil.calculateVendorHasPaidProjectForBeginningOfYearUntilOccp(statementAdjustment.matterTax,this) > statementAdjustment.matterTax.vendorShareAmount ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
        }
    }

    recalculateAllTaxBeginningOfYearUntilOcc() {
        this.statementOfAdjustments.filter(adj => adj.isTaxesBeginningOfYearUntilOccupancy()).forEach(adj => this.recalculateTaxBeginningOfYearUntilOcc(adj));
    }

    recalculateOtherProRatedOnPercentageInterest(): void {
        let existingConsiderationFromTarionOrOtherFixed = StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(this.statementOfAdjustments);
        StatementAdjustmentUtil.updateOtherProRatedOnPercentageInterest(this.considerationLtt.salePriceAdjustment, this.allStatementAdjustments, this.isMatterProvinceAB ? Number(this.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes) : Number(this.matterPropertyWithCondo.condominiumTotalSharePercentage), this.statementOfAdjustmentHeading);
        StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(this.considerationLtt.salePriceAdjustment, this.statementOfAdjustments, existingConsiderationFromTarionOrOtherFixed);
        StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(this.considerationLtt.salePriceAdjustment, this.uniqueStatementAdjustments, existingConsiderationFromTarionOrOtherFixed);
    }

    updateDepositOccupancyDate(): void {
        if (this.matterPropertyWithCondo.occupancyDeposit) {
            if(this.isMatterProvinceAB && this.isProjectSale){
                this.matterPropertyWithCondo.occupancyDeposit.depositDate = this.occupancyDate;
            }else{
                this.matterPropertyWithCondo.occupancyDeposit.depositDate = this.requisitionDate;
            }
        }
    }

    updateCalculatedInterestForPrePaidTenancy(localAdj: SoAdjTenancyPrepaid, statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder): SoAdjTenancyPrepaid {
        if (localAdj.performInterestCalculationBoolean && localAdj.receiveCreditWithInterestBoolean) {
            let items: InterestDateCalendarYearItem[] = [];
            if (localAdj.calculationMethod == 'FLAT_INTEREST_RATE') {
                items.push(...SoAdjTenancyPrepaidUtil.calculateRatesForFlatInterestRate(localAdj.interestCalculatedFromDate, this.getClosingDate(), localAdj.interestRate, localAdj.prepaidRentAmount, this.isPaysForDateOfClosingVendor));
            } else {
                let rentInterestRates: RentInterestRate[] = (statementAdjustmentDisplayUtil && statementAdjustmentDisplayUtil.rentInterestRates) ? statementAdjustmentDisplayUtil.rentInterestRates : this.rentInterestRates;
                items.push(...SoAdjTenancyPrepaidUtil.calculateRatesBasedOnOntarioResidentialTenanciesAct(localAdj.interestCalculatedFromDate, this.getClosingDate(), localAdj.prepaidRentAmount, rentInterestRates, this.isPaysForDateOfClosingVendor));
            }
            localAdj.calculatedInterest = 0;
            items.forEach(itm => localAdj.calculatedInterest += itm.rateAmount);
        }
        return localAdj;
    }


    get purchaseIsOfANewHomeFromABuilder(): boolean {
        return this.matterProperties && this.matterProperties.length > 0 && this.matterProperties[0].purchaseIsOfCode == 'NEW_BUILDER_HOME';
    }

    getMortgageeOrPrivateLender(mortgage: Mortgage): MatterParticipant[] {
        let mortgageeWithinMortgage: MatterParticipant[] = null;
        if (mortgage != null) {
            if (this.matterParticipants && this.matterParticipants.length > 0) {
                mortgageeWithinMortgage = this.matterParticipants
                    .filter(mp => mp.mortgageId === mortgage.id
                        && mp.matterParticipantRole === (mortgage.isMortgageeAnInstitution() ? 'MORTGAGEE' : 'PRIVATE_LENDER'));

            }
        }
        return (Array.isArray(mortgageeWithinMortgage) && mortgageeWithinMortgage.length > 0) ? mortgageeWithinMortgage : [];
    }

    getMortgageeOrPrimaryPrivateLenderName(mortgage: Mortgage): string {
        let name: string = null;
        if (mortgage.isMortgageePrivateLender()) {
            const matterParticipant: MatterParticipant = this.primaryPrivateLender(mortgage);
            name = matterParticipant && matterParticipant.contact && matterParticipant.contact.contactName
                && matterParticipant.contact.contactName.surnameLastFullName;
        } else {
            const matterParticipants: MatterParticipant[] = this.getMortgagees(mortgage);
            // TODO Refactor Mortgagee component because there should be only one Mortgagee
            if (Array.isArray(matterParticipants) && matterParticipants.length === 1) {
                name = matterParticipants[0].contact && matterParticipants[0].contact.organizationName;
            }
        }
        return name;
    }

    getTarionWarrantyStatementAdjustments(): StatementAdjustment[] {
        let tarionWarrantyStatementAdjustments: StatementAdjustment[] = [];
        if (Array.isArray(this.statementOfAdjustments)) {
            tarionWarrantyStatementAdjustments = this.statementOfAdjustments.filter(item => item.isTarionWarranty());
        }
        return tarionWarrantyStatementAdjustments;
    }

    // === The end of direction-re-funds Section ===

    updateUnitLevelPlanCreated(unitLevelPlan: MatterProperty): void {
        if (this.matterPropertyWithCondo.isCondominium == 'YES') {
            this.matterPropertyWithCondo.condominiumExpenses = unitLevelPlan.condominiumExpenses;
            this.matterPropertyWithCondo.condominiumPlans = unitLevelPlan.condominiumPlans;
            this.matterPropertyWithCondo.condominiumTotalExpenses = unitLevelPlan.condominiumTotalExpenses;
            this.matterPropertyWithCondo.condominiumJurisdiction = unitLevelPlan.condominiumJurisdiction;
            this.matterPropertyWithCondo.condominiumCorporationName = unitLevelPlan.condominiumCorporationName;
            this.matterPropertyWithCondo.unitLevelPlan = UnitLevelPlanUtil.generateUnitLevelPlan(unitLevelPlan, this.provinceCode);
            this.matterPropertyWithCondo.pin = UnitLevelPlanUtil.generatePinNumber(unitLevelPlan);
            this.matterPropertyWithCondo.assessmentAccountNumberSummary = UnitLevelPlanUtil.generateAssessmentAccountNumberSummary(unitLevelPlan);
            this.matterPropertyWithCondo.lincNumber = UnitLevelPlanUtil.generateLincNumber(unitLevelPlan);
            this.matterPropertyWithCondo.exceptionType = unitLevelPlan.exceptionType;
            if (unitLevelPlan.exceptionType == 'YES') {
                this.matterPropertyWithCondo.exceptionTypeDescription = unitLevelPlan.exceptionTypeDescription;
            } else {
                this.matterPropertyWithCondo.exceptionTypeDescription = '';
            }
            this.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes = unitLevelPlan.percentageShareOfTotalRealtyTaxes;
            if (this.matterPropertyWithCondo.rollNumber && this.isMatterProvinceAB) {
                this.matterPropertyWithCondo.rollNumber.city = UnitLevelPlanUtil.generateRollNumber(unitLevelPlan);
            }
            if ((this.isProjectSale || this.templateForProject)) {
                this.matterPropertyWithCondo.condominiumTotalSharePercentage = unitLevelPlan.condominiumTotalSharePercentage;
            }
            if ((this.isProjectSale || this.templateForProject)) {
                this.allStatementAdjustments
                    .filter(adj => adj.isTaxesBeginningOfYearUntilOccupancy())
                    .forEach(adj => {
                        adj.matterTax.updatePurchaserPortionValue(this);
                        this.recalculateTaxBeginningOfYearUntilOcc(adj);
                    });
                this.recalculateOtherProRatedOnPercentageInterest();
            }
        }
    }

    copyCondoExpensesFromTemplateMatterIntoUnitLevelPlan(unitLevelPlan: MatterProperty): void {
        if (this.matterPropertyWithCondo.isCondominium == 'YES') {
            this.matterPropertyWithCondo.condominiumExpenses = [];
            unitLevelPlan.condominiumExpenses.forEach(unitPlan => {
                unitPlan.id = null;
                unitPlan.identifier = null;
                this.matterPropertyWithCondo.condominiumExpenses.push(new CondominiumExpense(unitPlan));
            });
            this.matterPropertyWithCondo.condominiumTotalExpenses = unitLevelPlan.condominiumTotalExpenses;
            this.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes = unitLevelPlan.percentageShareOfTotalRealtyTaxes;
            if ((this.isProjectSale || this.templateForProject) && this.isMatterProvinceON) {
                this.matterPropertyWithCondo.condominiumTotalSharePercentage = unitLevelPlan.condominiumTotalSharePercentage;
            }
        }

    }

    refreshUnitLevelPlanValue(): void {
        if (this.matterPropertyWithCondo) {
            this.matterPropertyWithCondo.unitLevelPlan = UnitLevelPlanUtil.generateUnitLevelPlan(this.matterPropertyWithCondo, this.provinceCode);
        }
    }

    updateSalePrice(result: any, ontarioTaxRateSlab: ConsiderationTaxes, torontoTaxRateSlab: ConsiderationTaxes, soaConsiderationTaxes: ConsiderationTaxes, provinceHstRateSlab: ConsiderationTaxes): void {
        if (result.priceType) {
            this.matterPropertyWithCondo.purchasePriceType = result.priceType;
            this.hideShowSalePriceSoA(result.priceType);
        }
        if (result.salePrice != undefined && result.priceType) {

            this.matterPropertyWithCondo.purchasePrice = result.priceType && result.priceType == 'SALE_PRICE_AS_IN_SOA' ? result.salePriceSoa : result.salePrice;
        }
        if (result.salePriceSoa != undefined) {
            this.matterPropertyWithCondo.soaPurchasePrice = result.salePriceSoa;
        }
        this.autoInsertHst = result.autoInsertHst;
        if (this.considerationLtt) {
            this.recalculateConsiderationLTTBasedOnSalePrice(soaConsiderationTaxes, ontarioTaxRateSlab, torontoTaxRateSlab);
            this.updateLTTOnStatementOfAccount();
            this.considerationLtt.salePriceAdjustment = result.salePriceAdjustment;
            this.createUpdateSalePriceAdjustment(soaConsiderationTaxes);
        }
        if (provinceHstRateSlab) {
            MatterStatementAdjustmentUtil.createUpdateHstAdjustments(this, result.salePriceSoa, provinceHstRateSlab.hstFederalPortion, provinceHstRateSlab.hstProvincialPortion);
        }
        this.calculateStatementAdjustment();
        this.updateBalanceOfFundsPayableTo();
    }


    get isAnyMortgageEMP(): boolean {
        if (this.mortgages.length > 0) {
            for (let i = 0; i < this.mortgages.length; i++) {
                if (this.mortgages[i].isEmpMortgage()) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    getCoreCommissionPayableToPurchaserBrokerLabel(): string {
        return MatterCommissionUtil.getCoreCommissionPayableToPurchaserBrokerLabel(this);
    }

    getCoreCommissionPayableToVendorBrokerLabel(): string {
        return MatterCommissionUtil.getCoreCommissionPayableToVendorBrokerLabel(this);
    }

    isAnyEregCreatedOrUploaded(): boolean {
        return (this.eRegistrationForms && this.eRegistrationForms.length) ?
            !!(this.eRegistrationForms.find((eregForm) => {
                return eregForm.isStatusCreated() || eregForm.isStatusUploaded()
            })) : false;
    }

    //either borrower or guarantor
    isMatterParticipantReconciled(empMortgage: Mortgage, matterParticipantId: number): boolean {
        if (matterParticipantId && empMortgage && empMortgage.stewartAssystMortgageInstruction && empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData) {
            let reconciledBorrowers: BorrowerMapping[] = empMortgage.stewartAssystMortgageInstruction.reconciledBorrowers;
            let borrowerMapping = reconciledBorrowers && reconciledBorrowers.find(mapping => mapping.matterParticipantId == matterParticipantId)
            return !!borrowerMapping;
        }
        return false;
    }

    isEMPMortgageReconciled(mortgage: Mortgage): boolean {
        let isBorrowerReconciled = false;
        let isGuarantorReconciled = false;
        if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.mortgageInstructionData) {
            if (mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.borrowersExcludingGuarantors.length == this.mainClients.length) {
                let borrowers = mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.borrowersExcludingGuarantors;
                if (borrowers && borrowers.length > 0) {
                    let borrowerIds = borrowers.map(item => {
                        return item.borrowerID;
                    })
                    let borrowerMappingList = mortgage.stewartAssystMortgageInstruction.reconciledBorrowers.filter(borrowerMapping => borrowerMapping.borrower && borrowerMapping.borrower.isBorrower() && borrowerIds.indexOf(borrowerMapping.borrower.borrowerID) > -1);
                    isBorrowerReconciled = borrowerMappingList.length == borrowerIds.length;
                }
                else {
                    isBorrowerReconciled = true;
                }
            }
            if (mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.guarantors.length == this.getGuarantors(mortgage).length) {
                let guarantors = mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.guarantors;
                if (guarantors && guarantors.length > 0) {
                    let guarantorIds = guarantors.map(item => {
                        return item.borrowerID;
                    })
                    let borrowerMappingList = mortgage.stewartAssystMortgageInstruction.reconciledBorrowers.filter(borrowerMapping => borrowerMapping.borrower && borrowerMapping.borrower.isGuranator() && guarantorIds.indexOf(borrowerMapping.borrower.borrowerID) > -1);
                    isGuarantorReconciled = borrowerMappingList.length == guarantorIds.length;
                }
                else {
                    isGuarantorReconciled = true;
                }

            }
        }
        return (isBorrowerReconciled && isGuarantorReconciled);

    }

    isEMPMortgagePriceClosingDateMatches(mortgage: Mortgage, errorService: ErrorService): void {
        let stewartClosingDate: string;
        let matterCloseDate: string;

        if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.mortgageInstructionData &&
            mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.isFctInstruction) {
            let errorMsg: string = '';
            let errorMsgTopic: string = '';
            if (mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.isFctLLCInstruction) {
                errorMsgTopic = 'FCT-LLC - ';
            } else if (mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.isFctMMSInstruction) {
                errorMsgTopic = 'FCT-MMS - ';
            }
            errorMsgTopic = errorMsgTopic + Utils.getOrdinal(mortgage.mortgagePriority) + ' Mortgage';
            if (mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.closingDate != null) {
                stewartClosingDate = moment(mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.closingDateFormatted)
                    .format('YYYY/MM/DD');
            }
            if (Utils.isValidDate(this.matterCloseDate)) {
                matterCloseDate = moment(this.matterCloseDate).format('YYYY/MM/DD');
            }
            if (Utils.isValidDate(stewartClosingDate) && Utils.isValidDate(matterCloseDate) && matterCloseDate != stewartClosingDate) {
                const closingDateLabel = this.isPurchase && this.isMatterProvinceMB ? 'Possession Date' : 'Closing Date'
                errorMsg = this.buildMismatchedRequiredMsg(Utils.formatDateString(matterCloseDate, true, ''),
                    Utils.formatDateString(stewartClosingDate, true, ''), closingDateLabel);
                errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.stewartAssyst.closingDateNotMatching'
                    + mortgage.identifier, errorMsg, errorMsgTopic, 'NOTIFICATION'));
            }
            if (Utils.isValidDate(stewartClosingDate) && !Utils.isValidDate(matterCloseDate)) {
                errorMsg = this.buildMismatchedRequiredMsg('??????',
                    Utils.formatDateString(stewartClosingDate, true, ''), 'closing date');
                errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.stewartAssyst.closingDateNotMatching'
                    + mortgage.identifier, errorMsg, errorMsgTopic, 'NOTIFICATION'));
            }
            if (this.isPurchase && mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.purchasePrice != null &&
                this.matterPropertyWithCondo.purchasePrice != mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.purchasePrice) {
                errorMsg = this.buildMismatchedRequiredMsg(Utils.formattedCurrencyValue(this.matterPropertyWithCondo.purchasePrice),
                    Utils.formattedCurrencyValue(mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.purchasePrice), 'purchase price');
                errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.stewartAssyst.purchasePriceNotMatching'
                    + mortgage.identifier, errorMsg, errorMsgTopic, 'NOTIFICATION'));
            }
        }
    }

    isStandardChargeTermNoProvided(mortgage: Mortgage, errorService: ErrorService, appConfig: AppConfig): void {
        if (mortgage.stewartAssystMortgageInstruction
            && !mortgage.isStandardChargeTermNo) {

            if (mortgage.stewartAssystMortgageInstruction.isFctInstruction &&
                mortgage.stewartAssystMortgageInstruction.isLenderCMHC) {
                //CMHC only available as a Lender for FCT, and will skip the SCT# validation
                return;
            }

            let mortgageIndex = this.mortgages.findIndex(item => item.id == mortgage.id);
            if (mortgage.stewartAssystMortgageInstruction.isFctInstruction) {
                let fctProviderName = mortgage.stewartAssystMortgageInstruction.provider &&
                    mortgage.stewartAssystMortgageInstruction.provider.toUpperCase();
                errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.fct.standardChargeTermNo.' +
                    mortgage.id, 'Please confirm Standard Charge Term No. for registration.',
                    `${fctProviderName} - ${Utils.getOrdinal(mortgageIndex + 1)} Mortgage`, "NOTIFICATION"));
            } else if (mortgage.stewartAssystMortgageInstruction.isTelusInstruction) {
                errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.stewartAssyst.standardChargeTermNo.' +
                    mortgage.id, 'Please confirm Standard Charge Term No. for registration.',
                    'Unity® Lender Centre - ' + Utils.getOrdinal(mortgageIndex + 1) + ' Mortgage', "NOTIFICATION"));
            }
        }
    }

    private buildMismatchedRequiredMsg(matterData: string, instructionData: string, specifiedMsg: string) {
        return `The ${specifiedMsg} ${matterData} does not match the ${specifiedMsg} of ${instructionData} specified in the mortgage instructions.`;
    }

    getNumberofEMPMortgages(): number {

        let lg = _.filter(this.mortgages, {mortgageSource: 'EMP'});
        return lg.length;
    }

    getMortgageEMPCharges(includeTax?: boolean): number {
        let charges: number = 0;
        let isTaxes: boolean = false;
        if (includeTax) {
            isTaxes = includeTax;
        }
        for (let i = 0; i < this.mortgages.length; i++) {
            if (this.mortgages[i].isEmpMortgage() && this.mortgages[i].stewartAssystMortgageInstruction && this.mortgages[i].stewartAssystMortgageInstruction.mortgageFeesData && this.mortgages[i].stewartAssystMortgageInstruction.mortgageFeesData.fee) {
                if (isTaxes) {
                    charges = charges + this.mortgages[i].stewartAssystMortgageInstruction.mortgageFeesData.fee + this.mortgages[i].stewartAssystMortgageInstruction.mortgageFeesData.pstFee + this.mortgages[i].stewartAssystMortgageInstruction.mortgageFeesData.gstFee;
                } else {
                    charges = charges + this.mortgages[i].stewartAssystMortgageInstruction.mortgageFeesData.fee;
                }

            }
        }
        return charges;
    }

    getMortgageeDisplayName(id: number): string | undefined {
        const mortgagee = this.mortgagees.find(mortgagee => mortgagee.mortgageId == id);
        return mortgagee && mortgagee.contact && mortgagee.contact.displayName;
    }

    getTitleInsuranceCharges(): number {
        let charges: number = 0;
        let isEccFee: boolean = this.deductEcFeeFromSoa;
        /// **** charges from different policies ****///
        /// policyTotal without EccFee is Stewart Title Guaranty Company
        /// grandTotal with EccFee is Stewart Title Guaranty Company
        /// grandTotal is Chicago Title Insurance Company
        /// fctPremium is for FCT
        if (this.matterTitleInsurance && (this.matterTitleInsurance.policyTotal || this.matterTitleInsurance.fctPremium || this.matterTitleInsurance.grandTotal)) {
            if (!isEccFee) {
                if (this.matterTitleInsurance.policyTotal && this.insurer == constValues.titleInsuranceCompanies.STEWART) {
                    charges = this.matterTitleInsurance.policyTotal;
                } else if (this.matterTitleInsurance.fctPremium) {
                    charges = this.matterTitleInsurance.fctPremium;
                } else if (this.matterTitleInsurance.grandTotal && this.insurer == constValues.titleInsuranceCompanies.CHICAGO) {
                    charges = this.matterTitleInsurance.grandTotal;
                }
            } else {
                if (this.matterTitleInsurance.grandTotal && (this.insurer == constValues.titleInsuranceCompanies.STEWART || this.insurer == constValues.titleInsuranceCompanies.CHICAGO)) {
                    charges = this.matterTitleInsurance.grandTotal;
                } else if (this.matterTitleInsurance.fctPremium) {
                    charges = this.matterTitleInsurance.fctPremium;
                }
            }
        }

        //if ccFee is checked use feeInfo.totalAmount (final amount before any discount)
        //if ccFee is not checked use FeeInfo.amountDue (final amount after any discount)
        // totalAmount is grandTotal; amountDue is fctPremium

        if (this.matterTitleInsurance && this.insurer == constValues.titleInsuranceCompanies.FCT) {
            if (!this.deductCcFeeFromSoaTrustLedger) {
                charges = this.matterTitleInsurance.grandTotal;
            } else {
                charges = this.matterTitleInsurance.fctPremium;
            }
        }

        //elitefee appears as a negative value.
        // If we are to include elitefee, use the value from netamountdue.
        // If we are not including it, we need to add the amount back in (would equal InvoiceTotal)
        if (this.matterTitleInsurance && this.insurer == constValues.titleInsuranceCompanies.CHICAGO) {
            if (this.deductEliteFeeFromSoaTrustLedger) {
                charges = this.matterTitleInsurance.grandTotal;
            } else {//eliteFee is a negative value.
                charges = this.matterTitleInsurance.grandTotal - this.matterTitleInsurance.eliteFee;
            }
        }


        /******************TitlePLUS calculations********************
        * If the check box for 'Do not deduct Legal Counsel Fee from premium in Trust Ledger or Statement of Account' associated with TitlePLUS on the Integrations Tab, Title Insurance → Configuration screen
        * is checked then
        *    the TotalAmount value from the TitlePLUS OrderStatus call will be utilized including the PST amount.
        *
        * If the check box for 'Do not deduct Legal Counsel Fee from premium in Trust Ledger or Statement of Account' associated with TitlePLUS on the Integrations Tab, Title Insurance → Configuration screen
        * is NOT checked then
        *     the NetAmount value from the TitlePLUS OrderStatus call will be utilized including the PST amount.
         * */
        if (this.matterTitleInsurance && this.matterTitleInsurance.grandTotal && this.insurer == constValues.titleInsuranceCompanies.TitlePlus) {
            if (this.deductLegalCounselFee) {
                charges = this.matterTitleInsurance.grandTotal;
            } else {
                charges = this.matterTitleInsurance.grandTotal + this.matterTitleInsurance.eCFee; // as of Nov10 grandTotal has the ecFee(a.k.a DiscountAmount) substracted already...putting it back
            }
        }

        return charges == null ? 0 : charges;
    }


    // solicitorServiceAddressDdstring : string[] = [AddressDropdowns.sameAsSolicitor, AddressDropdowns.manuallyEntered];
    createMortgageSolicitorServiceAddressDdOptions(solicitorAddress: Address, mortgage: Mortgage): SameAsAddressOption[] {
        return SolicitorServiceAddressDdString.map(item => {
            return {
                description: item,
                srcAddress: item === AddressDropdowns.sameAsSolicitor ? solicitorAddress : null
            };
        });
    }

    // solicitorReportAddressDdstring : string[] = [AddressDropdowns.sameAsSolicitor, AddressDropdowns.sameAsService, AddressDropdowns.manuallyEntered];
    createMortgageSolicitorReportAddressDdOptions(solicitorAddress: Address, mortgage: Mortgage): SameAsAddressOption[] {
        return SolicitorReportAddressDdString.map(item => {
            let reportAddress: Address = null;
            if (item === AddressDropdowns.sameAsSolicitor) {
                reportAddress = solicitorAddress;
            } else if (item === AddressDropdowns.sameAsService) {
                if (mortgage && mortgage.mortgageContactInfo && mortgage.mortgageContactInfo.serviceAddress) {
                    if (mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode == 'SOLICITOR') {
                        reportAddress = solicitorAddress;
                    } else {
                        reportAddress = mortgage.mortgageContactInfo.serviceAddress;
                    }
                }
            }

            return {
                description: item,
                srcAddress: reportAddress
            };
        });
    }

    updateTrustLedgerMortgageInstructionData(empMortgage: Mortgage): void {
        if (empMortgage && this.soaTrustLedgerCollection) {

            this.soaTrustLedgerCollection.setItemizeMortgageAdvance(empMortgage);
            this.soaTrustLedgerCollection.addTrustLedgerMortgageRowForAllMortgages();
            this.soaTrustLedgerCollection.updateTrustLedgeMortgageValues(this.getMortgageIndexById(empMortgage.id), empMortgage.mortgageTerm, empMortgage);
            this.soaTrustLedgerCollection.updateItemizePrincipalForEmp();
            this.reCalculateTrustLedgerMortgageeLineByMortgage(empMortgage);
            const trustLedgerMatterMortgageObjectTemplateList: TrustLedgerMatterMortgageObjectTemplate[]
                = this.buildTrustLedgerMatterMortgageObjectTemplate();
            this.updateTrustLedgerMatterPriorityInTemplate(trustLedgerMatterMortgageObjectTemplateList);
            this.sortTrustLedgerMatterByMortgageIndex(SoaTrustLedgeConfigKeys.L1_MG);
            this.updateTrustLedgerItemNameForUpdatePriority();
            this.soaTrustLedgerCollection.addOrRemoveEmpSoaAndTrustLedger();
            if(this.secondarySoaSheetsCollection){
                this.secondarySoaSheetsCollection.forEach( collection =>{
                    collection.addOrRemoveEmpSoaAndTrustLedger();
                })
            }
            this.soaTrustLedgerCollection.updateF9forTrustEmpMortgage();
        }
    }

    updateAndMapMortgageInstructionToMortgage(empMortgage: Mortgage, mortgageInstruction: StewartMortgageInstruction): void {
        //this.updateStewartAssystReconcilation(empMortgage);
        MatterMortgageUtil.mapMortgageInstructionToMortgage(this, empMortgage, mortgageInstruction);
        let matterParticipant: MatterParticipant = this.getMatterParticipantByRoleAndMortgage('MORTGAGEE', empMortgage);
        if (matterParticipant) {
            this.removeMatterParticipant(matterParticipant);
        }

        let matterAttentionParticipant: MatterParticipant = this.getMatterParticipantByRoleAndMortgage('MORTGAGEE_ATTENTION', empMortgage);
        if (matterAttentionParticipant) {
            this.removeMatterParticipant(matterAttentionParticipant);
        }

        MatterMortgageUtil.mapStewartAssystMortgageInstructionToMortgagee(this, empMortgage, mortgageInstruction);
        this.updateTrustLedgerMortgageInstructionData(empMortgage);
    }

    orderMortgagesByPriority(): void {
        if (this.soaTrustLedgerCollection) {
            //during mortgage re-order, the mortgageIndex get changed, need to update the fromMortgageIndex for MatterTrustLedger
            this.soaTrustLedgerCollection.populateMortgageIdentifierForMatterTrustLedger(this.mortgages);
            this.mortgages = _.orderBy(this.mortgages, ['mortgagePriority'], ['asc']);
            this.soaTrustLedgerCollection.refreshFromMortgageIndexForMatterTrustLedger(this.mortgages);
        } else {
            this.mortgages = _.orderBy(this.mortgages, ['mortgagePriority'], ['asc']);
        }
    }

    createAndMapMortgageInstructionToMortgage(mortgageInstruction: StewartMortgageInstruction): void {
        let empMortgage = MatterMortgageUtil.createMortgage(this, 'NEW', 'EMP', mortgageInstruction.mortgageInstructionData.mortgagePriority);
        MatterMortgageUtil.mapMortgageInstructionToMortgage(this, empMortgage, mortgageInstruction);
        MatterMortgageUtil.mapStewartAssystMortgageInstructionToMortgagee(this, empMortgage, mortgageInstruction);
        this.mortgages.push(empMortgage);
        this.orderMortgagesByPriority();
        this.updateTrustLedgerMortgageInstructionData(empMortgage);
        this.updateTitleInsurance(MortgageAction.ADD, empMortgage.mortgagePriority, empMortgage);
    }

    private toLowerCaseAndAddComma(interestCalculated: string): string {
        if (interestCalculated && dropDownOption.calculated) {
            let calculatedDropDownOptions = dropDownOption.calculated.slice(0);
            let calculationPeriod: any = calculatedDropDownOptions.find(item => item.label && item.label.replace(/[, ]+/g, "").trim().toUpperCase() == interestCalculated.replace(/[, ]+/g, "").trim().toUpperCase());
            return calculationPeriod ? calculationPeriod.value : '';
        }
        return "";
    }

    getFormattedMatterType(): string {
        return this.matterTypeCode + this.matterType.slice(1).toLowerCase();
    }

    get matterTypeCode(): string {
        return this.matterType ? this.matterType.charAt(0) : null;
    }

    //Use below method where custom matter type name needed as matter Type. For example matter search by matter type
    //For Discharge Matter Types as well.
    get derivedMatterType(): string {
        if (this.customMatterTypeName && this.customMatterTypeName.length > 0) {
            return this.customMatterTypeName;
        }else if(!!this.isMatterTypeDischarge) {
            return MatterTypesValue.DISCHARGE;
        }else {
            return this.matterType
        }
    }

    get isThirdPartyDataAvailable(): boolean {
        return (this.empMortgages && this.empMortgages.length > 0) || (this.matterTitleInsurance && this.matterTitleInsurance.dealId != undefined)
    }

    updateMatterOnIsResidingFlagChange(): void {
        let postClosingAddress: any = this.matterContactInfo.postClosingAddress;
        if(this.isSale) {
            if(this.matterContactInfo && this.matterContactInfo.postClosingAddress && this.matterContactInfo.residingAtSubjectProperty == DpBooleanValueTypes.NO) {
                const primaryMainClient : MatterParticipant = this.primaryMainClient; // Use loacl varible to improve perfermance
                if(primaryMainClient && primaryMainClient.contact && primaryMainClient.contact.primaryAddress) {
                    this.matterContactInfo.postClosingAddress.update(primaryMainClient.contact.primaryAddress);
                    this.matterContactInfo.postClosingAddress.sameAsAddressTypeCode = AddressTypes.manuallyEntered;
                }
            }
        } else {
            if(this.matterContactInfo && this.matterContactInfo.residingAtSubjectProperty !== 'NO') {
                if(postClosingAddress && (postClosingAddress.sameAsAddressTypeCode === AddressTypes.subjectAddress
                    || postClosingAddress.sameAsAddressTypeCode === AddressTypes.preClosingAddress)) {
                    postClosingAddress.sameAsAddressTypeCode = AddressTypes.subjectAddress;
                }
            } else {
                if(postClosingAddress && (postClosingAddress.sameAsAddressTypeCode === AddressTypes.subjectAddress
                    || postClosingAddress.sameAsAddressTypeCode === AddressTypes.preClosingAddress)) {
                    postClosingAddress.sameAsAddressTypeCode = AddressTypes.preClosingAddress;
                }
            }
        }
    }

    replaceEmpMortgageWithBlankMortgage(mortgage: Mortgage, mortgageSoAdjService: MortgageSoAdjService): void {
        let matterPriority: number = Number(mortgage.mortgagePriority);
        let unassignedOrCancelledEmpMortgageIndex: number = this.mortgages.findIndex(item => item.mortgagePriority === matterPriority);
        MatterCleanUpUtil.cleanTitleInsuranceData(this, mortgage);
        this.deleteMortgage(mortgage);
        this.updateTitleInsurance(MortgageAction.DELETE, matterPriority);
        let newMortgage = this.createMortgage('NEW', 'UNITY', matterPriority);
        this.mortgages.splice(unassignedOrCancelledEmpMortgageIndex, 0, newMortgage);
        this.updateTrustLedgerAndStatementOfAdjustment(mortgageSoAdjService, newMortgage);
        this.updateTitleInsurance(MortgageAction.ADD, matterPriority);
        //this.openMortgage(this.unassignedOrCancelledEmpMortgageIndex);
    }

    updateTrustLedgerAndStatementOfAdjustment(mortgageSoAdjService: MortgageSoAdjService, mortgage?: Mortgage): void {
        if (this.soaTrustLedgerCollection) {
            if (mortgage) {
                this.soaTrustLedgerCollection.removeMortgageFromTrustLedger(this.getMortgageIndexByMortgage(mortgage));
                this.soaTrustLedgerCollection.removeEMPFromTrust(this.getMortgageIndexById(mortgage.id));
            }
            this.soaTrustLedgerCollection.addOrRemoveFeeForAllMortgages();
            if(this.secondarySoaSheetsCollection){
                this.secondarySoaSheetsCollection.forEach( collection =>{
                    collection.addOrRemoveFeeForAllMortgages();
                })
            }
            this.soaTrustLedgerCollection.addTrustLedgerMortgageRowForAllMortgages();
            this.soaTrustLedgerCollection.addTrustLedgerHoldbacksRows();
            this.soaTrustLedgerCollection.updateERegAndRegisterCharges();
            if(this.secondarySoaSheetsCollection){
                this.secondarySoaSheetsCollection.forEach( collection =>{
                    collection.updateERegAndRegisterCharges();
                })
            }
            this.soaTrustLedgerCollection.updateF9ForPaidToYouAndOtherTrustLedgers();
        }
        mortgageSoAdjService.updateStatementOfAdjustment(this);
    }

    isSkReminderVisible() : boolean {
        if (this.statementOfAdjustments && this.considerationLtt && this.considerationLtt.salePriceAdjustment && this.matterPropertyWithCondo) {
            return this.isMatterProvinceSK && this.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'NO'
                 && (this.matterPropertyWithCondo.isNewHomeFromBuilder || this.matterPropertyWithCondo.isFarmLand)
                 && this.matterPropertyWithCondo.purchasePrice > 0.00 && !this.isMortgage;
        }
        return false;
    }

    isRebateReminderVisible(): boolean {
        if (this.considerationLtt && this.considerationLtt.salePriceAdjustment) {
            let rebate = this.considerationLtt.salePriceAdjustment.totalRebatePortion(
                this.soaProvincialHst,
                this.soaFederalHst,
                this.getSalePriceForTaxAdjustments(this.soaFederalHst, this.soaProvincialHst));
            return rebate > 0;
        }
        return true;
    }

    public checkOutOfRangeWarning(errorService: ErrorService) {
        MatterStatementAdjustmentUtil.checkOutOfRangeWarning(this, errorService);
    }

    public isItemOutOfRange(soaItem: StatementAdjustment): boolean {
        return MatterStatementAdjustmentUtil.isItemOutOfRange(this, soaItem);
    }

   get isSolicitorActingForBoth(): boolean {
        return (this.actingFor == ActingForValues.BOTH_MORTGAGOR_PRIMARY
            || this.actingFor == ActingForValues.BOTH_MORTGAGEE_PRIMARY
            || this.actingFor == ActingForValues.PURCHASER_VENDOR
            || this.actingFor == ActingForValues.VENDOR_PURCHASER
            || this.actingFor == ActingForValues.VENDOR_PURCHASER_MORTGAGEE);
    }

    get isActingForMortgageeORBothMortgageePrimary(): boolean {
        return (this.actingFor == ActingForValues.MORTGAGEE || this.actingFor == ActingForValues.BOTH_MORTGAGEE_PRIMARY);
    }

    get isActingForMortgagee(): boolean {
        return (this.actingFor == ActingForValues.VENDOR_MORTGAGEE || this.actingFor == ActingForValues.VENDOR_PURCHASER_MORTGAGEE);
    }

    get isActingForBothPurchaseAndSaleMatters(): boolean {
        return this.actingFor == ActingForValues.PURCHASER_VENDOR || this.actingFor == ActingForValues.VENDOR_PURCHASER;
    }

    get isActingForPurchaserVendorAndMortgageeMatters(): boolean {
        return this.actingFor == ActingForValues.PURCHASER_VENDOR || this.actingFor == ActingForValues.VENDOR_PURCHASER || this.actingFor == ActingForValues.VENDOR_PURCHASER_MORTGAGEE;
    }

    get balanceOfFundsPayableTo(): DirectionReFund {
        if (Array.isArray(this.filteredDirectionReFunds) && this.filteredDirectionReFunds.length >= 1
            && this.filteredDirectionReFunds[0].directionReFundType === DirectionReFundTypes.BALANCE_OF_FUNDS_PAYABLE_TO) {
            return this.filteredDirectionReFunds[0];
        } else {
            this.clearFileredDirectionReFunds();
            let balanceOfFundsPayableTo: DirectionReFund = new DirectionReFund();
            balanceOfFundsPayableTo.directionReFundType = DirectionReFundTypes.BALANCE_OF_FUNDS_PAYABLE_TO;
            balanceOfFundsPayableTo.progressionStatus = this._directionReFundsStatusMode;
            this.filteredDirectionReFunds.unshift(balanceOfFundsPayableTo);
            const fundsPayableTo: DirectionReFund = new DirectionReFund();
            fundsPayableTo.progressionStatus = this._directionReFundsStatusMode;
            fundsPayableTo.directionReFundType = DirectionReFundTypes.FUNDS_PAYABLE_TO;
            this.filteredDirectionReFunds.push(fundsPayableTo);
            return this.filteredDirectionReFunds[0];
        }
    }

    get matterParticipantsOfNewMortgage(): MatterParticipant[] {
        let mpList: MatterParticipant[] = [];
        if (Array.isArray(this.mortgages) && this.mortgages.length > 0) {
            let newMortgages: Mortgage[] = this.mortgages.filter(mortgage => mortgage.isNewMortgage());
            if (Array.isArray(newMortgages) && newMortgages.length > 0) {
                newMortgages.forEach(newMortgage => {
                    mpList.push(...this.matterParticipants.filter(mp => mp.mortgageId == newMortgage.id));
                });
                return mpList.sort((mp1, mp2) => mp1.mortgageId - mp2.mortgageId);
            }
        }
        return null;
    }

    get lawClerkName(): string {
        if (this.lawClerk && this.lawClerk.contact) {
            return this.lawClerk.contact.getFullNameForContact(false);
        }
        return "";
    }

    get lawClerkInitials(): string {
        if (this.lawClerk && this.lawClerk.contact) {
            return this.lawClerk.contact.getInitials();
        }
        return "";
    }

    set lawClerkInitials(initials: string) {
        // ToDo look into the Matter from backend that sends a null lawClerkInitials all the time
    }

    updateMatterLawClerkInfo(contact: Contact) {
        if (contact) {
            this.selectedLawClerkId = contact.id;
            this.createUniqueMatterParticipant(contact, MatterParticipantRoleTypes.LAWCLERK);
        }
    }

    updateOpportunityAssignedToInfo(contact: Contact) {
        if (contact) {
            this.selectedOpportunityAssignedTo = contact.id;
            this.createUniqueMatterParticipant(contact, MatterParticipantRoleTypes.OPPORTUNITY_ASSIGNEE);
        }
    }

    get solicitorName(): string {
        if (this.solicitor && this.solicitor.contact) {
            return this.solicitor.contact.getFullNameForContact(false);
        }
        return "";
    }

    get solicitorInitials(): string {
        if (this.solicitor && this.solicitor.contact) {
            return this.solicitor.contact.getInitials();
        }
        return "";
    }

    updateMatterSolicitorInfo(contact: Contact) {
        if (contact) {
            this.selectedSolicitorId = contact.id;
            this.createUniqueMatterParticipant(contact, MatterParticipantRoleTypes.SOLICITOR);
        }
    }

    get witnessInitials(): string {
        if (this.witness && this.witness.contact) {
            return this.witness.contact.getInitials();
        }
        return "";
    }

    updateMatterWitnessInfo(contact: Contact) {
        if (contact) {
            this.selectedWitnessId = contact.id;
            this.createUniqueMatterParticipant(contact, MatterParticipantRoleTypes.WITNESS);
        }
    }

    get commissionerInitials(): string {
        if (this.commissioner && this.commissioner.contact) {
            return this.commissioner.contact.getInitials();
        }
        return "";
    }

    updateMatterCommissionerInfo(contact: Contact) {
        if (contact) {
            this.selectedCommissionerId = contact.id;
            this.createUniqueMatterParticipant(contact, MatterParticipantRoleTypes.COMMISSIONER);
        }
    }

    updateOnClosingDate(errorService?: ErrorService): void {
        this.updateMatterPropertyWithCondoAdjustmentAmount();
        if (!this.isAdjustAsAtSpecify()) {
            this.updateInterimAndFinalAdjustmentsOnDateChange();
            //this.updateAdjustments(this.matterCloseDate)
        }
        this.calculateStatementAdjustment();
        this.recalcuateDelayedInterestMatterClosingDateChange();
        if (errorService) {
            this.checkOutOfRangeWarning(errorService);
        }
        this.updateMortgageRegistrationFee();

    }

    setOnDateUsingCloseDate(date, defaultDay): void {
        let closingDateDay = date.day;
        let closingDateMonth = date.month;
        let closingDateYear = date.year;
        if (!closingDateDay) {
            this.purchaserExecDocsDate = "//";
        } else {
            if (defaultDay && Number(closingDateDay) <= Number(defaultDay)) {
                this.purchaserExecDocsDate = closingDateYear + "//";
            } else if (defaultDay && Number(closingDateDay) > Number(defaultDay)) {
                this.purchaserExecDocsDate = closingDateYear + "/" + closingDateMonth + "/";
            }
        }
    }


    isDeductFeeConfigChange(res: TitleInsuranceConfiguration) {
        // opposite of what it is because flag var is opposite
        return this.deductEcFeeFromSoa != !res.doNotDetectECFee
            || this.deductEliteFeeFromSoaTrustLedger != !res.doNotDetectEliteFee
            || this.deductCcFeeFromSoaTrustLedger != !res.doNotDetectConnectingCounselFee
            || this.deductLegalCounselFee != !res.doNotDeductLegalCounselFee;
    }

    updateDeductFeeConfigChange(res: TitleInsuranceConfiguration) {
        // opposite of what it is because flag var is opposite
        this.deductEcFeeFromSoa = !res.doNotDetectECFee;
        this.deductEliteFeeFromSoaTrustLedger = !res.doNotDetectEliteFee;
        this.deductCcFeeFromSoaTrustLedger = !res.doNotDetectConnectingCounselFee;
        this.deductLegalCounselFee = !res.doNotDeductLegalCounselFee;
    }

    get isMatterProvinceBC(): boolean {
        return this.provinceCode == PROVINCE_CODES.BRITISH_COLOMBIA;
    }

    get isMatterProvinceAB(): boolean {
        return this.provinceCode == PROVINCE_CODES.ALBERTA;
    }

    get isMatterProvinceON(): boolean {
        return this.provinceCode === PROVINCE_CODES.ONTARIO;
    }

    get isMatterProvinceONOrAB(): boolean {
        return this.isMatterProvinceON || this.isMatterProvinceAB;
    }

    get isMatterProvinceONOrNB(): boolean {
        return this.isMatterProvinceON || this.isMatterProvinceNB;
    }

    get isMatterProvinceMB(): boolean {
        return this.provinceCode === PROVINCE_CODES.MANITOBA;
    }

    get isMatterProvinceSK(): boolean {
        return this.provinceCode === PROVINCE_CODES.SASKATCHEWAN;
    }

    get isMatterProvinceNB(): boolean {
        return this.provinceCode === PROVINCE_CODES.NEW_BRUNSWICK;
    }

    get isMatterProvinceNS(): boolean {
        return this.provinceCode === PROVINCE_CODES.NOVA_SCOTIA;
    }

    get isMatterProvinceMBorSK(): boolean {
        return this.isMatterProvinceMB || this.isMatterProvinceSK;
    }

    get isMatterProvinceBCorMBorSK(): boolean {
        return this.isMatterProvinceBC || this.isMatterProvinceMBorSK;
    }

    get isMatterProvinceABorSK(): boolean {
        return this.isMatterProvinceAB || this.isMatterProvinceSK;
    }

    get isMatterProvinceNBorNS(): boolean {
        return this.isMatterProvinceNB || this.isMatterProvinceNS;
    }

    get isMatterProvinceBCorNBorNS(): boolean {
        return this.isMatterProvinceBC || this.isMatterProvinceNB || this.isMatterProvinceNS;
    }

    get isMatterProvinceABorONorNBorNS(): boolean {
        return this.isMatterProvinceAB || this.isMatterProvinceON || this.isMatterProvinceNB || this.isMatterProvinceNS;
    }

    get isMatterProvinceABorNBorNS(): boolean {
        return this.isMatterProvinceAB || this.isMatterProvinceNB || this.isMatterProvinceNS;
    }

    get isMatterProvinceABorMBorSK(): boolean {
        return this.isMatterProvinceAB || this.isMatterProvinceMB || this.isMatterProvinceSK;
    }

    get isMatterProvinceABorMBorSKorNSorNB(): boolean {
        return this.isMatterProvinceAB || this.isMatterProvinceMB || this.isMatterProvinceSK || this.isMatterProvinceNS || this.isMatterProvinceNB;
    }

    get isMatterProvinceMBorSKorNSorNB(): boolean {
        return this.isMatterProvinceMBorSK || this.isMatterProvinceNBorNS;
    }


    get isMatterProvinceABorMB(): boolean {
        return this.isMatterProvinceAB || this.isMatterProvinceMB;
    }

    get isMatterProvinceONorMBorSK(): boolean {
        return this.isMatterProvinceON || this.isMatterProvinceMB || this.isMatterProvinceSK;
    }

    get isMatterProvinceONorNBorNS(): boolean {
        return this.isMatterProvinceON || this.isMatterProvinceNB || this.isMatterProvinceNS;
    }

    get isMatterProvinceONorABorMBorSK(): boolean {
        return this.isMatterProvinceON || this.isMatterProvinceAB || this.isMatterProvinceMB || this.isMatterProvinceSK;
    }

    get isMatterProvinceONorMBorSKorNS(): boolean {
        return this.isMatterProvinceON || this.isMatterProvinceMB || this.isMatterProvinceSK || this.isMatterProvinceNS;
    }

    get isMatterProvinceONorABorMBorSKorNS(): boolean {
        return this.isMatterProvinceON || this.isMatterProvinceAB || this.isMatterProvinceMB || this.isMatterProvinceSK || this.isMatterProvinceNS;
    }

    get isMatterProvinceONorABorMBorSKorNSorNB(): boolean {
        return this.isMatterProvinceON || this.isMatterProvinceAB || this.isMatterProvinceMB || this.isMatterProvinceSK || this.isMatterProvinceNS || this.isMatterProvinceNB;
    }

    get isMatterProvinceONorSK(): boolean {
        return this.isMatterProvinceON || this.isMatterProvinceSK;
    }

    get isMatterProvinceONorNS(): boolean {
        return this.isMatterProvinceON || this.isMatterProvinceNS;
    }

    isInProvince(provinceList: string[]): boolean{
        if(Array.isArray(provinceList)){
            return provinceList.includes(this.provinceCode);
        }
        return false;
    }

    isTitleDetailsManual(): boolean {
        return (this.matterContactInfo && this.matterContactInfo.titleDetailsType == 'MANUALLY_ENTERED')
    }

    isIndividualTitleDetails(): boolean {
        return (this.matterContactInfo && this.matterContactInfo.individualTitleDetails)
    }


    get hasCorporationOtherGenderInMainClient(): boolean {
        const mainClients: MatterParticipant[] = this.mainClients;
        if (Array.isArray(mainClients)) {
            return !!mainClients.find(item => item && item.contact && (item.contact.gender == 'CORPORATION' || item.contact.gender == 'OTHERENTITY'));
        }
        return false;
    }

    updateConsiderationDataForAb(affidavitTobeSignedBy: string) {
        if (this.provinceCode == 'AB' || this.provinceCode == 'MB') {
            this.matterProperties[0].affidavitTobeSignedBy = affidavitTobeSignedBy;
        }
    }

    get totalFeesFromSoa(): number {
        return this.soaTrustLedgerCollection.total() > 0 ? this.soaTrustLedgerCollection.total() : 0;
    }

    get totalFeesOption(): string {
        if (this.totalFeesFromSoa > 0) {
            return 'Total Fees and Disbursement from Statement of Account : ' + Utils.formattedCurrencyValue(this.totalFeesFromSoa);
        }
        return '';
    }


    getPayableToFromSoa(loggedInUserLawFirmName: string): string {
        return this.statementOfAdjustmentPayable && this.statementOfAdjustmentPayable.payableTo
            ? this.statementOfAdjustmentPayable.payableTo
            : loggedInUserLawFirmName ? loggedInUserLawFirmName + ' in trust' : '';
    }

    get isPaysForDateOfClosingVendor(): boolean {
        return this.paysForDateOfClosing == 'VENDOR';

    }

    get isPaysForDateOfClosingPurchaser(): boolean {
        return this.paysForDateOfClosing == 'PURCHASER';

    }


    updateMatterContactInfoFromPrimaryContact(primaryContact: Contact, initialPrimarySelection = false) {
        let contact = new Contact(primaryContact);
        let mcInfo: MatterContactInfo = this.matterContactInfo;
        if (mcInfo) {
            mcInfo.faxNumber = contact.faxPhone;
            if (mcInfo.emailSameAsPrimaryContact) {
                mcInfo.email = contact.email;
            }
            mcInfo.phoneNumberSummary = contact.phonesText;
            if (contact.address || contact.address.length > 0) {
                mcInfo.preClosingAddress = new Address(contact.primaryAddress); //TODO: figure out which address is to be used here
                mcInfo.preClosingAddress.id =  UUIDUtil.getUUID();
                mcInfo.preClosingAddress.identifier =  UUIDUtil.getUUID();
            }

            if (this.isMortgage || (this.isSale && this.matterContactInfo && this.matterContactInfo.residingAtSubjectProperty == 'NO')) {
                if (mcInfo.postClosingAddress) {
                    mcInfo.postClosingAddress.update(contact.primaryAddress);
                    mcInfo.postClosingAddress.sameAsAddressTypeCode = AddressTypes.manuallyEntered; //Make sure we keep the manually entered
                }
            }
            // we move envelope fields change to PurchaserComponent and contact-suggestion directory
            // if (initialPrimarySelection || !mcInfo.envelopeSalutation) {
            //     mcInfo.envelopeSalutation = contact.envelopeSalutationLine1;
            // }
            // if (initialPrimarySelection || !mcInfo.envelopeSalutationContinued) {
            //     mcInfo.envelopeSalutationContinued = contact.envelopeSalutationLine2;
            // }
            // if (initialPrimarySelection || !mcInfo.dearText) {
            //     mcInfo.dearText = contact.dear;
            // }
        }
    }

    addMatterToTrustLedgerCollection(): void {
        if (this.soaTrustLedgerCollection && !this.soaTrustLedgerCollection.matter) {
            this.updateSoaTrustLedgerCollectionMatterAfterStringfy();
        }
    }

    isClosingDayTypeNil(): boolean {
        return this.cashOnClosingDate == CashOnClosingDateValues.NIL;
    }

    isClosingDayTypeCashDifference(): boolean {
        return this.cashOnClosingDate == CashOnClosingDateValues.CASH_DIFFERENCE;
    }

    isClosingDayTypeBalanceOfDownPayment(): boolean {
        return this.cashOnClosingDate == CashOnClosingDateValues.BALANCE_OF_DOWN_PAYMENT;
    }

    isClosingDayTypeCashtoClose(): boolean {
        return this.cashOnClosingDate == CashOnClosingDateValues.CASH_TO_CLOSE || this.cashOnClosingDate == CashOnClosingDateValues.BALANCE_TO_CLOSE;//'Balance to Close' is for 'SK'/'MB', 'Cash to Close' is for 'AB'
    }

    setDefaultValueForCashOnClosingDateField(): void {
        if (!this.cashOnClosingDate) {
            switch (this.provinceCode) {
                case 'AB':
                    this.cashOnClosingDate = CashOnClosingDateValues.CASH_TO_CLOSE;
                    break;
                case 'MB':
                    this.cashOnClosingDate = CashOnClosingDateValues.BALANCE_TO_CLOSE;
                    break;
                case 'SK':
                    this.cashOnClosingDate = CashOnClosingDateValues.BALANCE_TO_CLOSE;
                    break;
            }
        }
    }


    isClosingDateValid(): boolean {
        let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
        return pattern.test(this.matterCloseDate);
    }


    syncCashToClose(): void {
        if (this.closingDatePayment && this.closingDatePayment.sameAsSoa) {
            this.closingDatePayment.cashToClose = this.calculateBalanceDueOnClosingPurchase();
        }
        if (this.isClosingDayTypeNil()) {
            this.closingDatePayment.delayedAmount = this.closingDatePayment.cashToClose;
        }
        this.calculateDelayedAmount();
    }

    syncSegmentAdjustment(): void {
        if (this.closingDatePayment && this.closingDatePayment.autoCalculate) {
            this.closingDatePayment.delayedAmountSameAsTabG = false;
        }
        this.calculateDelayedAmount();
    }

    syncDelayedAmount(): void {
        //only consider the value of delayedAmountSameAsTabG when !this.isClosingDayTypeNil() && !this.isSale condition is met
        const requiredToSyncWithMortgageTab: boolean = (!this.isClosingDayTypeNil() && !this.isSale) && (this.closingDatePayment && this.closingDatePayment.delayedAmountSameAsTabG);
        if (requiredToSyncWithMortgageTab) {
            let totalNetAdvance : number = 0;
            if (this.newOrEmpMortgages && !this.isSale) {
                this.newOrEmpMortgages.filter(item => item.mortgageTerm).forEach(item => {
                    totalNetAdvance = Number(totalNetAdvance) + Number(item.mortgageTerm.getNetAdvanceAmount());
                })
            }
            this.closingDatePayment.delayedAmount = totalNetAdvance;
        }
        this.calculateDelayedAmount();

    }

    calculateDelayedAmount(): void {
        if (this.closingDatePayment && !this.closingDatePayment.autoCalculate) {
            this.closingDatePayment.cashDifference = Number(this.closingDatePayment.cashToClose) - Number(this.closingDatePayment.delayedAmount);
        }
        else if (this.closingDatePayment && this.closingDatePayment.autoCalculate) {
            this.closingDatePayment.delayedAmount = Number(this.closingDatePayment.cashToClose) - Number(this.closingDatePayment.cashDifference);
        }
    }

    syncMortgageAmount(): void {
        if (this.closingDatePayment && this.closingDatePayment.registeredMortgageAmountSameAsTabG) {
            let totalPrincipal = 0;
            if (this.newMortgages && !this.isSale) {
                this.newMortgages.filter(item => item.mortgageTerm).forEach(item => {
                    totalPrincipal = totalPrincipal + item.mortgageTerm.principal;
                })
            }
            this.closingDatePayment.registeredMortgageAmount = totalPrincipal;
        }
    }

    daysToCalculate(): void {
        let matterCloseDate;
        if (this.isClosingDateAvailable() && this.isClosingDateValid()) {

            matterCloseDate = moment(this.matterCloseDate, "YYYY/MM/DD");
        }
        let closingDatePayment;
        if (this.closingDatePayment.isLateClosingDateValid()) {
            closingDatePayment = moment(this.closingDatePayment.lateClosingDate, "YYYY/MM/DD");
        }
        this.closingDatePayment.daysToCalculate = 'Days to Calculate ' + this.getMatterClosingLateClosingDateDifference() + ' ( ' + (matterCloseDate ? moment(this.matterCloseDate).format('MMM DD, YYYY') : '')
            + ' to ' + (closingDatePayment ? moment(this.closingDatePayment.lateClosingDate).format('MMM DD, YYYY') : '') + ' ) ';
    }

    getMatterClosingLateClosingDateDifference(): string {
        let matterCloseDate;
        if (this.isClosingDateAvailable() && this.isClosingDateValid()) {

            matterCloseDate = moment(this.matterCloseDate, "YYYY/MM/DD");
        }

        let closingDatePayment;
        if (this.closingDatePayment.isLateClosingDateValid()) {
            closingDatePayment = moment(this.closingDatePayment.lateClosingDate, "YYYY/MM/DD");
        }
        let closingDays = (matterCloseDate && closingDatePayment && closingDatePayment.diff(matterCloseDate, 'days') > 0 ? closingDatePayment.diff(matterCloseDate, 'days') : '0');
        return closingDays <= 0 ? '0 days' : (closingDays == 1 ? '1 day' : (+closingDays + ' days'));
    }

    recalcuateDelayedInterestCashToCloseChange(): void {
        if (this.closingDatePayment) {
            this.syncCashToClose();
            this.daysToCalculate();
            this.closingDatePayment.reCalculateDelayedInterest(this.matterCloseDate, this.provinceCode);
            if (this.soaTrustLedgerCollection) {
                this.soaTrustLedgerCollection.addOrRemoveLateClosingInterest();
            }
        }
    }

    recalcuateDelayedInterestDelayedAmountChange(): void {
        if (this.closingDatePayment) {
            this.syncDelayedAmount();
            this.daysToCalculate();
            this.closingDatePayment.reCalculateDelayedInterest(this.matterCloseDate, this.provinceCode);
            if (this.soaTrustLedgerCollection) {
                this.soaTrustLedgerCollection.addOrRemoveLateClosingInterest();
            }
        }
    }

    recalcuateDelayedInterestRegMortgageAmountChange(): void {
        if (this.closingDatePayment) {
            this.syncMortgageAmount();
            this.daysToCalculate();
            this.closingDatePayment.reCalculateDelayedInterest(this.matterCloseDate, this.provinceCode);
            if (this.soaTrustLedgerCollection) {
                this.soaTrustLedgerCollection.addOrRemoveLateClosingInterest();
            }
        }
    }

    recalcuateDelayedInterestMatterClosingDateChange(): void {
        // As of now update first row , next story will cover logic to find correct object to update it
        if (this.closingDatePayment && this.isClosingDateAvailable() && this.isClosingDateValid()) {
            if (this.closingDatePayment.lateClosingInterests && this.closingDatePayment.lateClosingInterests.length > 0) {
                this.closingDatePayment.onMatterClosingDateChange(this.matterCloseDate);
                this.daysToCalculate();
                this.closingDatePayment.reCalculateDelayedInterest(this.matterCloseDate, this.provinceCode);
            }

            if (this.soaTrustLedgerCollection) {
                this.soaTrustLedgerCollection.addOrRemoveLateClosingInterest();
            }
        }
    }

    recalculateProjectSaleConsiderationGivenBackToVendor(): void {
        if (this.isProjectSale && this.isMatterProvinceON) {
            if (!this.considerationLtt) {
                this.createNewConsiderationLtt();
            }
            if (this.considerationLtt &&
                (this.considerationLtt.inTableBelowType === 'CALCULATE_F_TOTALLING_A_THOUGH_E'
                    || this.considerationLtt.inTableBelowType === 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F'
                    || this.considerationLtt.completionOfTaxInfoType === 'LINE_A_EQUAL_NET_SALE_PRICE'
                    || this.considerationLtt.completionOfTaxInfoType === 'COMPLETE_TAX_INFO_MANUALLY')) {
                    let givenBackToVendorAmount: number = this.mortgages
                        .filter(mrtg =>
                            (mrtg.isLoanTypeArranged() && (mrtg.autoIncludeVtbMortgageAmount === DpBooleanValueTypes.YES || mrtg.autoIncludeVtbMortgageAmount == DpBooleanValueTypes.Y_n)) ||
                            (mrtg.isLoanTypeBackToVendor())
                        )
                        .reduce((amount: number, mrtg: Mortgage) => amount = amount + Number(mrtg.mortgageTerm.principal), 0);
                    this.considerationLtt.mortgagesGivenBackToVendor = givenBackToVendorAmount;
            }
        }
    }

    getLateClosingText(): string {
        let lateClosingDesc: string = '';
        /*        if(!this.isClosingDateAvailable() || !this.isClosingDateValid()){
                    lateClosingDesc ='***Incomplete Closing Date in Tab "A"';
                }
                else if(this.closingDatePayment.lateClosingDate && (moment(this.closingDatePayment.lateClosingDate, "YYYY/MM/DD") <= moment(this.matterCloseDate, "YYYY/MM/DD"))){
                    lateClosingDesc = '***Invalid Closing Date ,  The late closing date must be after '+moment(this.closingDatePayment.lateClosingDate).format('MMM DD, YYYY');
                }
                else if(this.closingDatePayment.lateClosingDate && (moment(this.closingDatePayment.lateClosingDate, "YYYY/MM/DD") > moment(this.matterCloseDate, "YYYY/MM/DD"))){
                    lateClosingDesc = moment(this.closingDatePayment.lateClosingDate).format('MMM DD, YYYY')+'; '+Utils.formattedCurrencyValue(this.closingDatePayment.lateInterestAmountTotal)+' interest';
                }*/
        switch (this.provinceCode) {
            case 'MB':
            case 'SK':
                if (!this.isClosingDateAvailable() ||
                    !this.isClosingDateValid() ||
                    !this.closingDatePayment ||
                    !Array.isArray(this.closingDatePayment.lateClosingInterests) ||
                    this.closingDatePayment.lateClosingInterests.length == 0) {
                    lateClosingDesc = '???';
                } else {
//                 ◾'Est'd =' <Interest Estimate>; 'Actual = ' <Interest Re Delay>
//                 ◾Where value for the <Interest Estimate> is $0.00 then replace them above with '???'
                    const estimatedInterest: number = this.getEstimatedInterest()
                    let part1: string = estimatedInterest == 0 ? '???' : Utils.formattedCurrencyValue(estimatedInterest);
                    let part2: string = this.getInterestAmountTotal();
                    return `Est'd = ${part1}; Actual = ${part2}`;
                }
                break;
            case 'ON':
            case 'AB':
            default:
                if (!this.isClosingDateAvailable() || !this.isClosingDateValid()) {
                    lateClosingDesc = '***Incomplete Closing Date in Tab "A"';
                }
                else if (this.closingDatePayment.lateClosingDate && (moment(this.closingDatePayment.lateClosingDate, "YYYY/MM/DD") <= moment(this.matterCloseDate, "YYYY/MM/DD"))) {
                    lateClosingDesc = '***Invalid Closing Date ,  The late closing date must be after ' + moment(this.closingDatePayment.lateClosingDate).format('MMM DD, YYYY');
                }
                else if (this.closingDatePayment.lateClosingDate && (moment(this.closingDatePayment.lateClosingDate, "YYYY/MM/DD") > moment(this.matterCloseDate, "YYYY/MM/DD"))) {
                    lateClosingDesc = moment(this.closingDatePayment.lateClosingDate).format('MMM DD, YYYY') + '; ' + Utils.formattedCurrencyValue(this.closingDatePayment.lateInterestAmountTotal) + ' interest';
                }
                break;

        }
        this.lateClosingText = lateClosingDesc;
        return lateClosingDesc;

    }

    getPrivateLendersTitleDetails(mortgage: Mortgage): string {
        return MatterTitleDetailsUtil.getPrivateLendersTitleDetails(this, mortgage);
    }

    initMainClientsCapacity() {
        if (!this.purchasersCapacity) {
            this.purchasersCapacity = 'UNSPECIFIED_CAPACITY';
        }
    }

    resetMainClientsCapacity() {
        if (this.mainClients.length < 2) {
            this.purchasersCapacity = 'UNSPECIFIED_CAPACITY';
        }
    }

    initMainDevelopersCapacity() {
        if (!this.developersCapacity) {
            this.developersCapacity = 'UNSPECIFIED_CAPACITY';
        }
    }

    resetMainDevelopersCapacity() {
        if (this.developers.length < 2) {
            this.developersCapacity = 'UNSPECIFIED_CAPACITY';
        }
    }

    isMatterExisting(): boolean {
        return (this.id > 0);
    }

    isNewMatter(): boolean {
        return (this.id <= 0);
    }

    getAltoFormsCreatedButNotSent(eFormType: EFormType): AltoEForm[] {
        return this.altoEForms.filter(item => item.eFormType == eFormType && !!item.eFormIdentifier && !item.sent);
    }

    getSentAltoForms(eFormType: EFormType): AltoEForm[] {
        return this.altoEForms.filter(item => item.eFormType == eFormType && !!item.eFormIdentifier && item.sent);
    }

    setStgByPassUnitValidation() {
        if (this.matterProperties && this.matterProperties.length > 0) {
            if (this.matterPropertyWithCondo && this.matterPropertyWithCondo.address && !this.matterPropertyWithCondo.address.unitNo && this.matterPropertyWithCondo.titleInsurancePropertyType == 'RESIDENTIAL_CONDO_TOWNHOUSE') {
                this.matterProperties[0].byPassUnitValidation = true;
            } else {
                this.matterProperties[0].byPassUnitValidation = false;
            }
        }
    }

    populateParsedDataForBlanketMortgageProperties(){
        this.matterProperties.forEach(property => {
            if(property.mortgageId && property.address){
                AddressUtil.populateParsedData(property.address);
            }
        })
    }

    get commissionPaidToVendorBrokerOnly(): boolean {
        return this.commissionPaidTo === 'VENDOR_BROKER_ONLY';
    }

    get commissionPaidToBothVendorAndPurchaserBroker(): boolean {
        return this.commissionPaidTo === 'BOTH_VENDOR_AND_PURCHASER_BROKER';
    }

    getFirstMortgageInterestRate(): number {
        let interestRate: number = 0;
        //if mortgage exists, get the 1 mortgage interest rate, otherwise use 0
        if (Array.isArray(this.newMortgages) && this.newMortgages.length > 0) {
            const firstNewMortgage: Mortgage = this.newMortgages[0];
            if (firstNewMortgage.mortgageTerm && firstNewMortgage.mortgageTerm.variableInterestRate == 'YES') {
                interestRate = 0;
            } else if (firstNewMortgage.interestRate) {
                const parsedValue: number = parseFloat(firstNewMortgage.interestRate);
                interestRate = isNaN(parsedValue) ? 0 : parsedValue;
            }
        }
        return interestRate;
    }

    updateFirstMortgageRelatedFields(): void {
        //for MB, SK, there is fields related to 1st Mortgage's interest rate
        if (this.provinceCode == 'MB' || this.provinceCode == 'SK') {
            if (this.closingDatePayment &&
                Array.isArray(this.closingDatePayment.lateClosingInterests) &&
                this.closingDatePayment.lateClosingInterests.length > 0 &&
                this.closingDatePayment.lateClosingInterests[0].sameAs1stMortgage == true) {

                this.closingDatePayment.lateClosingInterests[0].interestRate = this.getFirstMortgageInterestRate();
                this.closingDatePayment.calculateInterest(this.closingDatePayment.lateClosingInterests[0], this.provinceCode);
            }
        }
    }

    getEstimatedInterest(): number {
        return this.closingDatePayment ? this.closingDatePayment.getEstimatedInterest() : 0;
    }

    getInterestAmountTotal(): string {
        this.closingDatePayment.updateLateInterestAmountTotal(this.provinceCode);
        return Utils.formattedCurrencyValue(this.closingDatePayment.lateInterestAmountTotal);
    }

    getLastLateInterestAmt(): number {
          return this.closingDatePayment ? this.closingDatePayment.getLastLateInterestAmt() : 0;
    }

    getVendorLabel(): string {
        return (this.statementOfAdjustmentHeading && this.statementOfAdjustmentHeading.rightColumnListsCreditsTo
            ? this.statementOfAdjustmentHeading.rightColumnListsCreditsTo : '');
    }

    getPurchaserLabel(): string {
        return (this.statementOfAdjustmentHeading ? this.statementOfAdjustmentHeading.leftColumnListsCreditsTo : '');
    }

    getPayToLabel(propertyTax: MatterTax): string {
        let vendorProportionWillHavePaid: number = propertyTax.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && this.isProjectProportionateShare && propertyTax.baseTaxAdjustmentTaxBillType == 'INTERIM' ? propertyTax.vendorWillHavePaid * (propertyTax.purchaserPortionOfTotalTax / 100) : propertyTax.vendorWillHavePaid;

        if (propertyTax && vendorProportionWillHavePaid > propertyTax.vendorShareAmount) {
            return this.getVendorLabel()
        } else if (propertyTax && vendorProportionWillHavePaid === propertyTax.vendorShareAmount) {
            return '';
        } else {
            return this.getPurchaserLabel();
        }

    }


    isEditCommonElementFeesBtnVisible(): boolean {
        if (this.isMatterProvinceMB) {
            if (this.matterPropertyWithCondo.parcelLegalDescriptions) {
                return this.matterPropertyWithCondo.parcelLegalDescriptions.length == 1 ? true : (this.matterPropertyWithCondo.parcelLegalDescriptions.length > 1 && !this.matterPropertyWithCondo.separateCommonElementFeeAdj);
            } else {
                return false;
            }
        } else if (this.isMatterProvinceSK) {
            if (this.matterPropertyWithCondo.parcelLegalDescriptions) {
                return this.matterPropertyWithCondo.parcelLegalDescriptions.length > 0 ? (this.commonExpenseAdjustmentFromProperty != undefined) : false;
            } else {
                return false;
            }
        }
    }


    getMatterDocketValue(): string {
        return MatterPropertyUtil.getMatterDocketValue(this);
    }


    getSoaFeesRates(): SoaFeeRate[] {
        return (this.soaTrustLedgerCollection && Array.isArray(this.soaTrustLedgerCollection.soaFeesRates)) ? this.soaTrustLedgerCollection.soaFeesRates : null;
    }

    // for SK only
    updateTitleRegistrationFee(): void {
        if (this.isMatterProvinceSK && this.isPurchase) {
            if (!this.matterPropertyWithCondo) {
                this.createMatterPropertyWithCondo();
            }
            const purchasePrice: number = this.matterPropertyWithCondo.purchasePrice;
            let titleRegPrice: number = 0;
            let purchasePriceThresHold: number= 6300;
            if(this.matterCloseDate && moment(this.matterCloseDate, 'YYYY/MM/DD').isBefore(moment('2023/07/29', 'YYYY/MM/DD'))) {
                purchasePriceThresHold = 8400;
            }
            if (purchasePrice) {
                if (Number(purchasePrice) >= 0 && Number(purchasePrice) <= 500) {
                    titleRegPrice = 0;
                } else if (Number(purchasePrice) > 500 && Number(purchasePrice) <= purchasePriceThresHold) {
                    titleRegPrice = 25;
                } else if (Number(purchasePrice) > purchasePriceThresHold) {
                    const feeRate: SoaFeeRate = this.getSoaFeesRates() ? this.getSoaFeesRates().find(item => item.isTitleRegistrationFee()) : null;
                    if (feeRate) {
                        const salePriceFactor: number = feeRate.salePriceFactor;
                        titleRegPrice = Number((Number(purchasePrice) * Number(salePriceFactor)).toFixed(2));
                    }
                }
            }
            this.matterPropertyWithCondo.titleRegistrationFee = titleRegPrice;
            if (this.soaTrustLedgerCollection) {
                this.soaTrustLedgerCollection.addOrUpdateTitleRegistrationToSOA();
                if(this.secondarySoaSheetsCollection){
                    this.secondarySoaSheetsCollection.forEach( collection =>{
                        collection.addOrUpdateTitleRegistrationToSOA();
                    })
                }
                this.soaTrustLedgerCollection.updateTitleRegistrationToTrustForSkMb();
            }
        }
    }

    //for SK only, for purchase and mortgage matter only
    updatePiedRegistrationFees(){
        if (this.soaTrustLedgerCollection && this.isMatterProvinceSK && (this.isPurchase || this.isMortgage) &&
           this.matterPropertyWithCondo){
           if (this.matterPropertyWithCondo.titleRegistrationFee) {
               this.soaTrustLedgerCollection.addOrUpdateTitleRegistrationToSOA();
               if(this.secondarySoaSheetsCollection){
                   this.secondarySoaSheetsCollection.forEach( collection =>{
                       collection.addOrUpdateTitleRegistrationToSOA();
                   })
               }
           }
           if (this.matterPropertyWithCondo.mortgageRegistrationFee) {
               this.soaTrustLedgerCollection.addOrUpdateMortgageRegistrationToSOA();
               if(this.secondarySoaSheetsCollection){
                   this.secondarySoaSheetsCollection.forEach( collection =>{
                       collection.addOrUpdateMortgageRegistrationToSOA();
                   })
               }
           }
        }
    }

    //for SK only, for purchase and mortgage matter only
    updateMortgageRegistrationFee(): void {
        if (this.isMatterProvinceSK && (this.isPurchase || this.isMortgage)) {
            let feeValue: number = 0;
            if (!this.matterPropertyWithCondo) {
                this.createMatterPropertyWithCondo();
            }
            const parcelTitlesCnt: number = this.matterPropertyWithCondo.allParcelTitlesCnt;
            if (parcelTitlesCnt > 0) {
                let mortgageCnt: number = 0;
                let mortgages: Mortgage[] = [];
                if (this.isPurchase) {
                    mortgages = Array.isArray(this.mortgages) ? this.mortgages.filter(mortgage => !mortgage.isLoanTypeAssumed() && !mortgage.isLoanTypeBridge()) : [];

                } else {
                    //for Mortgage matter, the mortgageCnt default to  1 even there is no New/Emp mortgage
                    mortgages = (Array.isArray(this.newOrEmpMortgages) && this.newOrEmpMortgages.length > 0) ? this.newOrEmpMortgages : [];
                }
                mortgageCnt = this.isPurchase ? mortgages.length : (mortgages.length > 0 ? mortgages.length : 1)
                if (mortgageCnt > 0) {
                    const configuredFeeRate: SoaFeeRate = this.getSoaFeesRates() ? this.getSoaFeesRates().find(feeRate => feeRate.isRegistrationCharge()) : null;
                    if (configuredFeeRate) {
                        const registrationCharge: number = configuredFeeRate.fees;
                        if(this.matterCloseDate && moment(this.matterCloseDate, 'YYYY/MM/DD').isBefore(moment('2023/07/29', 'YYYY/MM/DD'))) {
                        const extraRegistrationCharge: number = configuredFeeRate.extraParcelFactor;
                        let feeFactor: number = registrationCharge;
                        if (parcelTitlesCnt > 4) {
                            feeFactor += (parcelTitlesCnt - 4) * extraRegistrationCharge;
                        }
                        feeValue = Number((Number(feeFactor) * Number(mortgageCnt)).toFixed(2));
                    }
                        else{
                            let totalRegistrationCharge: number = 0;
                            const extraRegistrationCharge: number = configuredFeeRate.extraParcelFactor;
                            if(mortgages && mortgages.length > 0) {
                                mortgages.forEach(mortgage => {
                                    const totalPrincipal = mortgage.mortgageTerm && mortgage.mortgageTerm.principal > 0 ? mortgage.mortgageTerm.principal : 0;
                                    if (totalPrincipal >= 0 && totalPrincipal < 250000) {
                                        totalRegistrationCharge = totalRegistrationCharge + registrationCharge;
                                    } else if (totalPrincipal >= 250000 && totalPrincipal <= 500000) {
                                        totalRegistrationCharge = totalRegistrationCharge + 250;
                                    } else if (totalPrincipal > 500000 && totalPrincipal <= 750000) {
                                        totalRegistrationCharge = totalRegistrationCharge + 500;
                                    } else if (totalPrincipal > 750000 && totalPrincipal <= 1000000) {
                                        totalRegistrationCharge = totalRegistrationCharge + 750;
                                    } else if (totalPrincipal > 1000000) {
                                        totalRegistrationCharge = totalRegistrationCharge + 1000;
                                    }

                                    if (parcelTitlesCnt > 4) {
                                        totalRegistrationCharge += Number((parcelTitlesCnt - 4) * extraRegistrationCharge);
                                    }
                                })
                            }
                            feeValue = Number(totalRegistrationCharge);
                         }
                    }
                }
            }
            this.matterPropertyWithCondo.mortgageRegistrationFee = feeValue;
            if (this.soaTrustLedgerCollection) {
                this.soaTrustLedgerCollection.updateTitleRegistrationToTrustForSkMb();
                this.soaTrustLedgerCollection.addOrUpdateMortgageRegistrationToSOA();
                if(this.secondarySoaSheetsCollection){
                    this.secondarySoaSheetsCollection.forEach( collection =>{
                        collection.addOrUpdateMortgageRegistrationToSOA();
                    })
                }
            }
        }


        if (this.soaTrustLedgerCollection && this.isMatterProvinceMBorSK && (this.isPurchase || this.isMortgage)) {
            this.soaTrustLedgerCollection.updateMortgageRegistrationToTrustForSkMb();
        }
    }


    setInsertExcessDepositIntoTrustLedgerDefaultValue(): void {
        this.insertExcessDepositIntoTrustLedger = this.isSale && !this.isMatterProvinceON && !this.isProjectSale;
    }

    onCashClosingChange(): void {
        if (this.isMatterProvinceABorMBorSK) {
            this.addOrRemoveLateClosingInterest();
        }
    }

    addOrRemoveLateClosingInterest(): void {
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.addOrRemoveLateClosingInterest();
        }
    }

    soaHeadingNotExistsOrHasError(): boolean {
        return !this.statementOfAdjustmentHeading ||
            this.statementOfAdjustmentHeading.vendorDataCode == null ||
            this.statementOfAdjustmentHeading.purchaserDataCode == null ||
            this.statementOfAdjustmentHeading.addressDataCode == null ||
            this.statementOfAdjustmentHeading.adjustDateDataCode == null;
    }


    get isMatterCondoDocumentsVisible(): boolean {
        return this.isMatterProvinceAB || this.isMatterProvinceMB || this.isMatterProvinceSK || this.isMatterProvinceBC;
    }

    setCondoCorporationDocumentation(): void {
        if (this.isMatterCondoDocumentsVisible) {
            this.condoCorporationDocumentation = new CondoCorporationDocumentation();
            // Condominium documents is only visible for AB/SK/MB
            // Although Tax rate for SK/MB is "GST+PST" but Condominium document should only be calculated for GST Only
            this.condoCorporationDocumentation.taxRate = this.matterFederalHst;
        }
    }

    public updateCondoFeeOnSOA(): void {
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.updateCondoFee()
        }
    }

    updateMatterDataOnCondoCorpChange() {
        this.setCondoCorporationDocumentation();
        this.updateCondoFeeOnSOA();
    }

    public addCondoFeeOnSOA(): void {
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.addOrRemoveCondoFee();
            if(this.secondarySoaSheetsCollection){
                this.secondarySoaSheetsCollection.forEach( collection =>{
                    collection.addOrRemoveCondoFee();
                })
            }
        }

    }

    public updateCondoOnSOA(): void {
        this.addCondoFeeOnSOA();
        this.updateCondoFeeOnSOA();
    }


    getTprInstrumentByInstrumentId(instrumentId: number): Instrument {
        let instrument: Instrument = null;
        if (this.teranetDocket && this.teranetDocket.parcelRegisters && this.teranetDocket.parcelRegisters.length > 0) {
            this.teranetDocket.parcelRegisters.filter(value => value.isTprParcelRegister()).forEach(tprParcelRegister => {
                if (instrument == null) {
                    instrument = tprParcelRegister.instruments.find(value => value.id == instrumentId);
                }
            });
        }
        return instrument;

    }

    setMatterTaxToMatterTaxByRollNumber(matterTaxByRollNumber: RollNumberMatterTax, rollNumberOrder: number): void {
        if (this.matterPropertyWithCondo.matterTaxesByRollNumber && this.matterPropertyWithCondo.matterTaxesByRollNumber.length > 0) {
            let matterTax = this.matterPropertyWithCondo.matterTaxesByRollNumber.find(item => item.rollNumberOrder === rollNumberOrder);
            if (matterTax) {
                matterTaxByRollNumber.matterTax = new MatterTax(matterTax);
            }
        }
    }

    matterTaxByRollNumbers(): RollNumberMatterTax[] {

        let materTaxesByRollNumbers: RollNumberMatterTax [] = [];
        if (this.isMatterProvinceAB) {
            if (this.matterPropertyWithCondo) {
                if (this.matterPropertyWithCondo.isCondominium == DpBooleanValueTypes.YES) {
                    if (this.matterPropertyWithCondo.condominiumExpenses) {
                        this.matterPropertyWithCondo.condominiumExpenses.forEach((condoExpense, index) => {
                            if (condoExpense.rollNumber) {
                                let matterTaxByRollNumber = new RollNumberMatterTax();
                                matterTaxByRollNumber.rollNumber = condoExpense.rollNumber
                                this.setMatterTaxToMatterTaxByRollNumber(matterTaxByRollNumber, index);
                                materTaxesByRollNumbers.push(matterTaxByRollNumber);
                            }
                        })
                    }
                } else {
                    this.matterProperties.forEach((matterProperty, index) => {
                        if (matterProperty && matterProperty.rollNumber && matterProperty.rollNumber.city != '') {
                            let matterTaxByRollNumber = new RollNumberMatterTax();
                            matterTaxByRollNumber.rollNumber = matterProperty.rollNumber.city;
                            if (this.matterPropertyWithCondo.matterTaxesByRollNumber && this.matterPropertyWithCondo.matterTaxesByRollNumber.length > 0) {
                                let matterTax = this.matterPropertyWithCondo.matterTaxesByRollNumber.find(item => item.rollNumberOrder == index);
                                //let matterTax = this.matterPropertyWithCondo.matterTaxesByRollNumber[index];
                                if (matterTax) {
                                    matterTaxByRollNumber.matterTax = new MatterTax(matterTax);
                                }
                            }
                            materTaxesByRollNumbers.push(matterTaxByRollNumber);
                        }
                    });
                }
            }
        }

        if (this.isMatterProvinceMB) {
            if (this.matterPropertyWithCondo && this.matterPropertyWithCondo.parcelLegalDescriptions) {
                this.matterPropertyWithCondo.parcelLegalDescriptions.forEach((parcelLegalDescription) => {
                    if (parcelLegalDescription.rollNumbers) {
                        parcelLegalDescription.rollNumbers.forEach(rollNo => {
                            let matterTaxByRollNumber = new RollNumberMatterTax();
                            matterTaxByRollNumber.rollNumber = rollNo;
                            this.setMatterTaxToMatterTaxByRollNumber(matterTaxByRollNumber, ParcelLegalDescription.getTitleRollNumberOrder(this.matterPropertyWithCondo.parcelLegalDescriptions, rollNo, parcelLegalDescription));
                            materTaxesByRollNumbers.push(matterTaxByRollNumber);
                        });
                    }
                });
            }
        }
        return materTaxesByRollNumbers;

    }

    isDepositSOAInfoOnly(): boolean {
        let deposit = this.findStatementAdjustment(StatementAdjustmentKey.DEPOSIT);
        return deposit ? deposit.infoOnly : false;
    }

    public hasNewMortgageOfPriority(priority: number): boolean {
        return _.isArray(this.mortgages) && this.mortgages.filter(mortgage => mortgage.mortgagePriority == priority).length > 0;
    }

    public hasExistingMortgageOfSequenceNo(seq: number): boolean {
        return _.isArray(this.existingMortgages) && this.existingMortgages.length >= seq;
    }

    public hasAdvanceHoldbackOfSequenceNo(seq: number): boolean {
        return _.isArray(this.advanceHoldbacks) && this.advanceHoldbacks.length >= seq;
    }

    public hasOtherHoldbackOfSequenceNo(seq: number): boolean {
        return _.isArray(this.otherHoldbacks) && this.otherHoldbacks.length >= seq;
    }

    get advanceHoldbacks(): MatterHoldback[] {
        if (this.holdbacks && this.holdbacks.length) {
            return this.holdbacks.filter(hb => hb.isAdvanceHoldback());
        } else {
            return [];
        }
    }

    get otherHoldbacks(): MatterHoldback[] {
        if (this.holdbacks && this.holdbacks.length) {
            return this.holdbacks.filter(hb => hb.isOtherHoldback());
        } else {
            return [];
        }
    }

    getHoldBacksByMortgageId(mortgageId: number): MatterHoldback[] {
        return this.holdbacks.filter(hb => hb.mortgageId == mortgageId);
    }

    isResidingPpropertyNoABMBSKTabB(): boolean {
        return this.matterContactInfo && this.matterContactInfo.residingAtSubjectProperty === 'NO' && (this.isMatterProvinceMBorSK || this.isMatterProvinceAB);
    }

    generateAndUpdateTitleDetailsAB(): string {
        return MatterTitleDetailsUtil.generateAndUpdateTitleDetailsAB(this);
    }

    setFileOpenDateAsTodayForNewMatter(): void {
        if (!this.fileOpenDate) {
            this.fileOpenDate = moment(new Date()).format("YYYY/MM/DD")
        }
    }

    updateMatterRecordNumber(newMatterRecordNumber: string): void {
        if (newMatterRecordNumber) {
            if (this.matterRecordNumber == this.fileNumber) {
                this.fileNumber = newMatterRecordNumber;
            }
            if (this.matterRecordNumber == this.accountingNumber) {
                this.accountingNumber = newMatterRecordNumber;
            }
            this.matterRecordNumber = newMatterRecordNumber;
        }
    }

    setUpMatterContactInfo(): void {
        //Initialize the matter info structure, if not present yet
        if (this.matterContactInfo == undefined) {
            this.matterContactInfo = new MatterContactInfo();
        }

        if (this.matterContactInfo.emailSameAsPrimaryContact == undefined) {
            this.matterContactInfo.emailSameAsPrimaryContact = true;
        }

        if (!this.matterContactInfo.residingAtSubjectProperty) {
            this.matterContactInfo.residingAtSubjectProperty = 'Y_n';
        }
        if (!this.matterContactInfo.titleDetailsType) {
            this.matterContactInfo.titleDetailsType = 'AUTO_POPULATED';
        }
        if (!this.matterContactInfo.witnessKnowsTransferors) {
            this.matterContactInfo.witnessKnowsTransferors = DpBooleanValueTypes.N_y;
        }

        if (!this.matterContactInfo.preClosingAddress) {
            this.matterContactInfo.preClosingAddress = new Address();
        }

        if (!this.matterContactInfo.postClosingAddress) {
            this.matterContactInfo.postClosingAddress = new Address();
            this.matterContactInfo.postClosingAddress.addressTypeCode = AddressTypes.postClosingAddress;
            if (this.isPurchase) {
                this.matterContactInfo.postClosingAddress.sameAsAddressTypeCode =
                    this.matterContactInfo.residingAtSubjectProperty === 'NO'
                        ? AddressTypes.preClosingAddress : AddressTypes.subjectAddress;
            } else {
                this.matterContactInfo.postClosingAddress.sameAsAddressTypeCode = AddressTypes.manuallyEntered;
            }
        }
    }

    get isRequisitionDateEmpty(): boolean {
        return this.isPartialDateEmpty(this.requisitionDate);
    }

    isPartialDateEmpty(partialDate: string): boolean {
        if (!partialDate) {
            return true;
        }

        let ret = true;
        let partialDateSplit: string[] = [];

        partialDateSplit = partialDate.split('/');
        if (Array.isArray(partialDateSplit) && partialDateSplit.length == 3) {
            if (partialDateSplit.some(item => item != null && item != '')) {
                ret = false;
            }
        }

        return ret;
    }

    get needOccupancyDate(): boolean {
        return !this.isProjectSale || (this.project && (this.project.condominiumFlag || this.project.occupancyDateRequired));
    }

    get needOccupancyCompleted(): boolean {
        //The RequisitionDate field will be renamed to Occupancy Date for the province of Ontario
        if (!this.needOccupancyDate || this.isRequisitionDateEmpty) {
            return false;
        }

        let requisitionDateSplit: string[] = [];
        let today: string = moment().format("YYYY/MM/DD");
        let ret: boolean = true;
        if (this.requisitionDate) {
            requisitionDateSplit = this.requisitionDate.split('/');
            if (Array.isArray(requisitionDateSplit) && requisitionDateSplit.length == 3) {
                if ((requisitionDateSplit.some(item => item != null && item != ''))
                    && (this.requisitionDate > today)) {
                    ret = false;
                }
            }
        }
        return ret;
    }

    get needIsReleaseDateSameAsOccupancyDate(): boolean {
        // It is same as needOccupancyCompleted check
        return this.needOccupancyCompleted;
    }

    // For ON Project Sale matters occupancy date is stored on Requisition date
    getOccupancyDateForMatterType() {
        return this.isProjectSale && this.isMatterProvinceON ? this.requisitionDate : this.occupancyDate;
    }

    createSalePriceInterimAdjustmentAndSyncWithFinal(): void {
        MatterStatementAdjustmentUtil.createSalePriceInterimAdjustmentAndSyncWithFinal(this);
    }

    createDepositInterimAdjustmentAndSyncWithFinal(): void {
        MatterStatementAdjustmentUtil.createDepositInterimAdjustmentAndSyncWithFinal(this);
    }

    getFirstPurchaserMortgage(): Mortgage { // TODO review if this cover all scenarios?
        return this.mortgages ? this.mortgages.find(m => m.mortgageType == 'NEW') : null;
    }

    getFirstVTBMortgage(): Mortgage {
        return this.mortgages ? this.mortgages.find(m => m.mortgageType == 'NEW' && m.isLoanTypeBackToVendor()) : null;
    }

    // DPPMP-28748: using sale price adjustment
    //  Sale Price As Per Agreement + Additional Considerations
    //   - Credit to Purchaser, inc. HST
    //   - Buydowns
    //   - Sum of all other fixed amounts payable on occupancy
    getSalePriceIncludingCreditsToPV(salePriceTotalOption: string): number {

        let amount = 0.0;

        if (salePriceTotalOption === undefined || salePriceTotalOption == SalePriceOptionValue.AS_PER_AGREEMENT) {
            // default is "as per agreement"
            amount = this.considerationLtt ? this.considerationLtt.salePriceAdjustment.agreementSalePrice : 0.0;

        } else if (salePriceTotalOption == SalePriceOptionValue.INCLUDING_CREDITS_TO_PV) {
            const salePriceAdjustment: SalePriceAdjustment = this.considerationLtt && this.considerationLtt.salePriceAdjustment ? this.considerationLtt.salePriceAdjustment : null;
            const salePricePerAgreement: number = Utils.toNumber(salePriceAdjustment.agreementSalePrice);
            const additionalConsiderations: number = salePriceAdjustment ? Utils.toNumber(salePriceAdjustment.additionalConsiderationsInclHst) : 0.0;
            const creditPurchaserIncludingHst: number = salePriceAdjustment ? Utils.toNumber(salePriceAdjustment.creditsToPurchaserInclHst) : 0.0;
            const buydowns = salePriceAdjustment && salePriceAdjustment.buyDowns ? Utils.toNumber(salePriceAdjustment.otherItems) : 0.0;
            const sumOfAllOtherFixedAmountsPayableOnOccupancy: number = Number(this.totalOtherFixedAmountPayableOnOccupancy());
            amount = Utils.roundCurrency(salePricePerAgreement + additionalConsiderations - creditPurchaserIncludingHst - buydowns - sumOfAllOtherFixedAmountsPayableOnOccupancy);
        }
        return amount;
    }

    // DPPMP-28748, Deposit Adjustment Project.xlsx, D21
    getCalculatedSalePriceTotal(config: MatterExtraDepositConfig, projectOccupancyFeesCalBasedOn: string): number {

        let total = 0.0;
        if (!config.depositOnOccupancyFlag && !config.deferredPurchaseMoniesFlag) {
            // D17+D18+D19+D20
            let d17 = this.calculateDepositOnOccupancy(config, projectOccupancyFeesCalBasedOn);
            let d18 = this.calculateDeferredPurchaseMoniesAmount(config, projectOccupancyFeesCalBasedOn);
            let d19 = Utils.toNumber(config.vtbMortgageMonies);
            let d20 = this.calculateSumOfDeposits();
            total = d17 + d18 + d19 + d20;

        } else if (config.salePriceTotalOption == SalePriceOptionValue.AS_PER_AGREEMENT) {
            // C5 - sale price per agreement
            total = this.considerationLtt ? this.considerationLtt.salePriceAdjustment.agreementSalePrice : 0.0;

        } else {
            // C5+C6-C7-C8+C9
            total = this.getSalePriceIncludingCreditsToPV(config.salePriceTotalOption);
        }

        return total;
    }

    calculateTotalOfDeposits(): number {
        const prop = this.matterPropertyWithCondo;
        const deposits = prop ? prop.depositsExcludePayOnOccupancy : [];
        return Deposit.calculateTotalDepositsIncludingExtras(deposits);
    }

    /**
     * DPPMP-28748, Deposit Adjustment Project.xlsx, D20;
     */
    calculateSumOfDeposits(): number {
        const prop = this.matterPropertyWithCondo;
        const deposits = prop ? prop.depositsExcludePayOnOccupancy : [];
        return Deposit.calculateTotalDepositsExcludingExtras(deposits);
    }

    // DPPMP-28748, Deposit Adjustment Project.xlsx, D17
    calculateDepositOnOccupancy(config: MatterExtraDepositConfig, projectOccupancyFeesCalBasedOn: string): number {
        let deposit = 0.0;
        if (!config.depositOnOccupancyFlag) {
            // C17
            deposit = Utils.toNumber(config.depositOnOccupancyAmount);

        } else if (config.depositOnOccupancyCalBasedOn == DepositOnOccupancyCalculatedBasedOn.BDOC_IN_INTERIM_SOA) {
            // in this case the currency field (depositOnOccupancyAmount) is populated with the Balance
            // Due on Closing of Credit Purchaser column from Interim SOA
            deposit = Utils.toNumber(config.depositOnOccupancyAmount);

        } else {
            // depositOnOccupancyFlag==true && depositOnOccupancyCalBasedOn=='Unadjusted balance':
            // D21-D20-D19-D18
            let d21 = this.getCalculatedSalePriceTotal(config, projectOccupancyFeesCalBasedOn);
            let d20 = this.calculateSumOfDeposits();
            let d19 = Utils.toNumber(config.vtbMortgageMonies);
            let d18 = this.calculateDeferredPurchaseMoniesAmount(config, projectOccupancyFeesCalBasedOn);

            deposit = d21 - d20 - d19 - d18;
        }

        return deposit;
    }

    // DPPMP-28748, Deposit Adjustment Project.xlsx, D18
    calculateDeferredPurchaseMoniesAmount(config: MatterExtraDepositConfig, projectOccupancyFeesCalBasedOn: string): number {
        let amount = 0.0;

        if (projectOccupancyFeesCalBasedOn == OccupancyFeesCalculatedBasedOn.phantomMortgage) {
            // no op
        } else if (!config.deferredPurchaseMoniesFlag) {
            // C18
            amount = Utils.toNumber(config.deferredPurchaseMoniesAmount);
        } else {
            // D21-D20-D19-D17
            let d21 = this.getCalculatedSalePriceTotal(config, projectOccupancyFeesCalBasedOn);
            let d20 = this.calculateSumOfDeposits();
            let d19 = Utils.toNumber(config.vtbMortgageMonies);
            let d17 = this.calculateDepositOnOccupancy(config, projectOccupancyFeesCalBasedOn);
            amount = d21 - d20 - d19 - d17;
        }

        return amount;
    }

    private totalOtherFixedAmountPayableOnOccupancy() {
        return this.statementOfAdjustments
            .filter(adj => adj.isOtherFixedPayableOnOccupancy())
            .reduce(function (sum: number, adj: StatementAdjustment) {
                const amount = Utils.toNumber(adj.soAdjOtherFixedPayableOnOccupancy.amount);
                const hst = Utils.toNumber(adj.soAdjOtherFixedPayableOnOccupancy.hstAmount);
                return sum + (amount ? amount : 0.0) + (hst ? hst : 0.0);
            }, 0.0);
    }


    get teraviewDocketIdSameAsProjectField(): boolean {
        return this.project &&
            this.project.useSameDocketIdentifier &&
            this.project.docRegistration &&
            this.project.docRegistration.isRegistrationMethodElectronic()
    }

    teraviewDocketIdSameAsFileNo(): boolean {
        return this.project &&
            !this.project.useSameDocketIdentifier &&
            this.project.docRegistration &&
            this.project.insertFileNoIntoTeraviewDocket;
    }

    getLinkedAdjustment(statementAdjustment: StatementAdjustment): StatementAdjustment {
        return (statementAdjustment.linkId) ?
            (statementAdjustment.isAdjustmentStatusFinal() ? this.interimStatementAdjustments : this.finalStatementAdjustments).find(item => item.linkId == statementAdjustment.linkId) : undefined;
    }

    getUnAppliedLinkedAdjustment(statementAdjustment: StatementAdjustment): StatementAdjustment {
        return (statementAdjustment.linkId) ?
            (statementAdjustment.isAdjustmentStatusFinal() ? this.interimStatementAdjustmentsUnApplied : this.finalStatementAdjustmentsUnApplied).find(item => item.linkId == statementAdjustment.linkId) : undefined;
    }

    updateStatementOfAccount(): void {
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.addOrRemoveFeeForAllMortgages();
            if(this.secondarySoaSheetsCollection){
                this.secondarySoaSheetsCollection.forEach( collection =>{
                    collection.addOrRemoveFeeForAllMortgages();
                })
            }
            this.soaTrustLedgerCollection.addTrustLedgerMortgageRowForAllMortgages();
            this.soaTrustLedgerCollection.addTrustLedgerHoldbacksRows();
            this.soaTrustLedgerCollection.updateERegAndRegisterCharges();
            if(this.secondarySoaSheetsCollection){
                this.secondarySoaSheetsCollection.forEach( collection =>{
                    collection.updateERegAndRegisterCharges();
                })
            }
        }
    }

    isInterimOccupancyAdjCommonExpOrTaxes(): boolean {
        return this.interimStatementAdjustments ? this.interimStatementAdjustments.filter(item => item.isInterimOccupancyFee()).some(item => item.soAdjInterimOccupancyFee && (item.soAdjInterimOccupancyFee.isCommonExpenseTypeAutomatic() || item.soAdjInterimOccupancyFee.isTaxTypeAutomatic())) : false;

    }

    isInterimOccupancyAdjCommonExpAndTaxes(): boolean {
        return this.interimStatementAdjustments ? (this.isInterimOccupancyAdjCommonExp() && this.isInterimOccupancyAdjTaxes()) : false;

    }

    isInterimOccupancyAdjCommonExp(): boolean {
        return this.interimStatementAdjustments ? this.interimStatementAdjustments.filter(item => item.isInterimOccupancyFee()).some(item => item.soAdjInterimOccupancyFee && (item.soAdjInterimOccupancyFee.isCommonExpenseTypeAutomatic())) : false;

    }

    isInterimOccupancyAdjTaxes(): boolean {
        return this.interimStatementAdjustments ? this.interimStatementAdjustments.filter(item => item.isInterimOccupancyFee()).some(item => item.soAdjInterimOccupancyFee && (item.soAdjInterimOccupancyFee.isTaxTypeAutomatic())) : false;

    }

    updateInterimOccupanyFeeAdjOnUnitLevelChanges(editCommonExpenseCommand: string): void {
        if (editCommonExpenseCommand == 'PRESERVE_COMMON_EXP_TAXES') {
            this.interimStatementAdjustments.filter(item => item.isInterimOccupancyFee() && item.soAdjInterimOccupancyFee && (item.soAdjInterimOccupancyFee.isCommonExpenseTypeAutomatic() && item.soAdjInterimOccupancyFee.isTaxTypeAutomatic())).forEach(interimAdjustment => {
                interimAdjustment.soAdjInterimOccupancyFee.taxesType = 'MANUAL';
                interimAdjustment.soAdjInterimOccupancyFee.recalculateTaxesTotal();
                interimAdjustment.soAdjInterimOccupancyFee.commonExpenseType = 'MANUAL';
                interimAdjustment.soAdjInterimOccupancyFee.recalculateCommonExpenseTotal();
            })
        } else if (editCommonExpenseCommand == 'PRESERVE_COMMON_EXP') {
            this.interimStatementAdjustments.filter(item => item.isInterimOccupancyFee() && item.soAdjInterimOccupancyFee && (item.soAdjInterimOccupancyFee.isCommonExpenseTypeAutomatic())).forEach(interimAdjustment => {
                interimAdjustment.soAdjInterimOccupancyFee.commonExpenseType = 'MANUAL';
                interimAdjustment.soAdjInterimOccupancyFee.recalculateCommonExpenseTotal();
            })
        } else if (editCommonExpenseCommand == 'PRESERVE_TAXES') {
            this.interimStatementAdjustments.filter(item => item.isInterimOccupancyFee() && item.soAdjInterimOccupancyFee && (item.soAdjInterimOccupancyFee.isTaxTypeAutomatic())).forEach(interimAdjustment => {
                interimAdjustment.soAdjInterimOccupancyFee.taxesType = 'MANUAL';
                interimAdjustment.soAdjInterimOccupancyFee.recalculateTaxesTotal();
            })
        } else if (editCommonExpenseCommand == 'RECALCULATE') {
            this.recalculateAllInterimOccupancyFee(this.project);
        }
    }


    /**
     * returns list of DirectionReFunds based on _directionReFundsStatusMode status
     */
    get filteredDirectionReFunds(): DirectionReFund[] {
        if (this.isProjectConfigDocForDirectionReFund()) {
            return (this._directionReFundsStatusMode == ProgressionStatus.FINAL) ? this._finalDirectionReFunds : this._interimDirectionReFunds;
        } else {
            return this._finalDirectionReFunds;
        }

    }

    clearFileredDirectionReFunds() {
        if (this._directionReFundsStatusMode == ProgressionStatus.FINAL) {
            this._finalDirectionReFunds = [];
        }
        else {
            this._interimDirectionReFunds = [];
        }
    }


    getMatterSoAdjFieldCodes(): number[] {
        let allFieldCodes = [];
        let allSoaj = [...this.interimStatementAdjustments, ...this.finalStatementAdjustments];
        allSoaj.forEach((soaj) => {
            if (soaj.fieldCode && !soaj.sourceProjectAdjustmentId) {
                allFieldCodes.push(soaj.fieldCode);
            }
        });
        return allFieldCodes;
    }

    updateMatterDepositAdjustment(result: DepositModalContextValue): void {

        if (result.depositAmount >= 0) {
            this.matterPropertyWithCondo.depositAmount = result.depositAmount;
        }
        if (this.matterPropertyWithCondo) {
            if (result.multipleDeposit) {
                this.matterPropertyWithCondo.multipleDeposit = result.multipleDeposit;
            }
            if (result.adjustmentFormat) {
                this.matterPropertyWithCondo.adjustmentFormat = result.adjustmentFormat;
            } else {//in case, Multiple Deposits = 'No'
                this.matterPropertyWithCondo.adjustmentFormat = null;
            }

            this.updateDepositsInMatter(result.deposits);
            this.recalculateForm4Charges();

        }
        this.matterPropertyWithCondo.payDepositOutOfTrust = result.payDepositOutOfTrust;
        if (this.matterPropertyWithCondo.payDepositOutOfTrust == DpBooleanValueTypes.YES) {
            this.reCalculateTrustLedgerReceivedDepositHeldInTrust();
        }
        if (result.extraDepositConfig) {
            this.extraDepositConfig = new MatterExtraDepositConfig(result.extraDepositConfig);
            this.recalculateAllPurchasePriceDeferredPortion();
        }
        this.createUpdateDepositAdjustment(result.infoOnly);
        StatementAdjustmentUtil.updateInterestOnDepositInterimAndFinal(this);
        StatementAdjustmentUtil.updateInterestOnDeferredMoniesDuringOccupancy(this, this.project);
    }

    updateDepositsInMatter(updatedDeposits: Deposit[]): void {

        if (updatedDeposits) {
            /*
             If result deposits contains occupancyDeposit then replace all else only remove non-occupancy deposits. This method is called from two places
             DepositModal and Project propagation. In case of DepositModal we need to replace all deposits with the latest copy returned from modal.
             */
            if (updatedDeposits.findIndex(d => d.paidOnOccupancy) > -1) {
                this.matterPropertyWithCondo.removeAllDepositsIncludingOccupancy();
            } else {
                this.matterPropertyWithCondo.removeNonOccupancyDeposits();
            }

            for (let i = 0; i < updatedDeposits.length; i++) {
                let deposit = new Deposit(updatedDeposits[i]);
                this.addDepositToPrimaryProperty(deposit);
            }
        }
    }

    copyFireInsuranceInfoData(targetMatter: Matter): void {
        MatterFireInsuranceUtil.copyFireInsuranceInfoData(this, targetMatter);
    }

    getSoaConsiderationTaxes(considerationTaxes: ConsiderationTaxes[]): ConsiderationTaxes {
        let provinceHstRateSlab = considerationTaxes.find(item => item.instanceType == Tax_RATE.HST_RATE);

        if (this.soaHst) {
            let soaConsiderationTaxes = new ConsiderationTaxes();
            soaConsiderationTaxes.hstRate = this.soaHst;
            soaConsiderationTaxes.hstFederalPortion = this.soaFederalHst;
            soaConsiderationTaxes.hstProvincialPortion = this.soaProvincialHst;
            soaConsiderationTaxes.rateType = this.matterTaxType ? this.matterTaxType : provinceHstRateSlab.rateType;
            return soaConsiderationTaxes;
        }
        else {
            return provinceHstRateSlab;
        }
    }

    onSalePriceUpdate(result: any, considerationTaxes: ConsiderationTaxes[]): void {
        MatterStatementAdjustmentUtil.onSalePriceUpdate(this, result, considerationTaxes);
    }

    get ignoreCreditsInDeedConsiderationInON(): string {
        let ignoreCreditsInDeedConsideration: string = "NO";
        if (this.isMatterProvinceON && (this.isSale || this.isProjectOrProjectSale)) {
            if (this.project && this.project.docRegistration) {
                ignoreCreditsInDeedConsideration = this.project.docRegistration.ignoreCreditsInDeedConsideration;
            }
        }
        return ignoreCreditsInDeedConsideration;
    }

    get isProjectHstReductionInSalePriceAdjustment(): boolean {
        if (this.project && this.project.projectAdjustmentConfig && this.project.projectAdjustmentConfig.hstReductionInSalePriceAdjustment) {
            return Utils.convertToBoolean(this.project.projectAdjustmentConfig.hstReductionInSalePriceAdjustment);
        }
        return false;
    }

    recalculateConsiderationLTTBasedOnSalePrice(soaConsiderationTaxes: ConsiderationTaxes, ontarioTaxRateSlab: ConsiderationTaxes, torontoTaxRateSlab: ConsiderationTaxes, ignoreCreditsInDeedConsiderationInON?: string, isProjectHstReductionInSalePriceAdjustment?: boolean): void {
        let forERegConsideration: boolean = this.isMatterProvinceON && (this.isSale || this.isProjectOrProjectSale);
        let ignrCredInDeedConsidInON: string = ignoreCreditsInDeedConsiderationInON ? ignoreCreditsInDeedConsiderationInON : this.ignoreCreditsInDeedConsiderationInON;
        let isProjHstReducInSalePriceAdj: boolean = isProjectHstReductionInSalePriceAdjustment !== null ? isProjectHstReductionInSalePriceAdjustment : this.isProjectHstReductionInSalePriceAdjustment;
        this.considerationLtt.updateSalePrice(this.matterPropertyWithCondo.purchasePrice, forERegConsideration, ignrCredInDeedConsidInON, isProjHstReducInSalePriceAdj, soaConsiderationTaxes);
        this.considerationLtt.calculateSubjectValueAndTotalConsideration(ontarioTaxRateSlab, torontoTaxRateSlab,
            this.isMatterProvinceON && this.isProjectOrProjectSale  ? this.considerationLtt.getTotalNetSalePrice(ignrCredInDeedConsidInON, isProjHstReducInSalePriceAdj, soaConsiderationTaxes): null);
    }

    get projectStatementConfigurationId(): number {
        return this.isProjectSale && this.project && this.project.statementConfiguration ? this.project.statementConfiguration.id : null;
    }

    isDepositOnOccupancyCalBasedOnInterimBDOC(): boolean {
        return this.extraDepositConfig && this.extraDepositConfig.depositOnOccupancyFlag && this.extraDepositConfig.depositOnOccupancyCalBasedOn === DepositOnOccupancyCalculatedBasedOn.BDOC_IN_INTERIM_SOA;
    }

    updateInterimAndFinalAdjustmentsOnDateChange() {
        //To do - method needs to be moved to centralized place for updating both interim and final adjustments.
        let adjustmentDate = this.getClosingDate();
        this.updateAdjustments(adjustmentDate);
        let adjustmentMode = this.adjustmentStatusMode;
        if (adjustmentMode === ProgressionStatus.FINAL) {
            if (this.interimStatementAdjustments && this.interimStatementAdjustments.length > 0) {
                this.adjustmentStatusMode = ProgressionStatus.INTERIM;
                this.updateAdjustments(this.getClosingDate());
            }
        } else {
            this.adjustmentStatusMode = ProgressionStatus.FINAL;
            this.updateAdjustments(this.getClosingDate());// getClosingDate() always return correct closing date associated with Progression Status
        }
        this.adjustmentStatusMode = adjustmentMode;
    }

    updateStatusMode(mode: string): void {
        mode = mode == ProgressionStatus.INTERIM ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
        this.adjustmentStatusMode = mode;
        this.selectedProgressionStatus = this.selectedProgressionStatus ? mode : null;
    }

    // adjustment date for TaxBeginningOfYearUntilOcc is based on file open date for Projects or occupancy date for ProjectSAle
    get adjustmentDateForTaxBeginningOfYearUntilOcc(): string {
        let dateForAdj: string = this.isMatterProvinceAB ? this.occupancyDate : this.requisitionDate;
        if (this.project && !this.isProjectSale && !dateForAdj) {
            dateForAdj = this.fileOpenDate;
        }
        return dateForAdj;
    }

    getSalePriceForTaxAdjustments(federalHstRate: number, provinceHstRate: number): number {
        let salePrice: number = 0;
        if (this.considerationLtt && this.considerationLtt.salePriceAdjustment) {
            // if netOutHstFromHSTSalePrice is "NO" and province is "AB", "MB", "SK", "NB" or "NS", it should use getTotalConsiderationCost
            if (this.considerationLtt.salePriceAdjustment.isNotInclusivePrice() && this.isInProvince([PROVINCE_CODES.ALBERTA, PROVINCE_CODES.MANITOBA, PROVINCE_CODES.SASKATCHEWAN, PROVINCE_CODES.NEW_BRUNSWICK,  PROVINCE_CODES.NOVA_SCOTIA])) {
                salePrice = this.considerationLtt.salePriceAdjustment.getTotalConsiderationCost(this.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings);
            } else {
                salePrice = this.considerationLtt.salePriceAdjustment.totalNetSalePrice(federalHstRate, provinceHstRate, true);
            }
        }
        return salePrice;
    }

    getSalePriceBasedOnAdjustment(federalHstRate: number, provinceHstRate: number, useSalePriceAmountSubjectToPST : boolean): number{
        if(this.considerationLtt && this.considerationLtt.salePriceAdjustment
            && this.considerationLtt.salePriceAdjustment.isNotInclusivePrice() && useSalePriceAmountSubjectToPST){
            return this.considerationLtt.salePriceAdjustment.getTotalConsiderationSubjectToPST(this.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings);
        }else{
            return this.getSalePriceForTaxAdjustments(federalHstRate , provinceHstRate);
        }
    }

    updateTeraviewLegalDescs(condominiumExpense?: CondominiumExpense, matterProperty?: MatterProperty): void {
        MatterPropertyUtil.updateTeraviewLegalDescs(this, condominiumExpense, matterProperty);
    }

    calculateTeraviewLegalDesc(condominiumExpense: CondominiumExpense, matterProperty: MatterProperty): void {
        MatterPropertyUtil.calculateTeraviewLegalDesc(this, condominiumExpense, matterProperty);
    }

    calculateTeraviewLegalDescForMatterProperty(matterProperty: MatterProperty): void {
        MatterPropertyUtil.calculateTeraviewLegalDescForMatterProperty(this, matterProperty);
    }

    getMortgageeTitle(mortgageId: number): string {
        let mortgage: Mortgage = this.mortgages.find(m => m.id === mortgageId);
        if (this.matterParticipants) {
            let matterParticipants: MatterParticipant[] = this.matterParticipants.filter(p => p.mortgageId === mortgage.id
                && p.matterParticipantRole === (mortgage.mortgageeType === 'INSTITUTION' ? 'MORTGAGEE' : 'PRIVATE_LENDER'));
            if (matterParticipants && matterParticipants.length > 0) {
                if (mortgage.mortgageeType === 'PRIVATE_LENDER') {
                    return this.formatPrivateLenders(matterParticipants);
                } else {
                    return matterParticipants[0].contact.genericFullName;
                }
            } else {
                return '???';
            }
        }
    }

    formatPrivateLenders(matterParticipants: MatterParticipant[]): string {
        let title: string[] = [];

        matterParticipants.forEach((matterParticipant: MatterParticipant) => {
            let nameForReLine: string = matterParticipant.contact.reLineName;
            if (!title.some(x => x === nameForReLine)) {
                title.push(nameForReLine);
            }
        });
        return title.length < 2 ? title.join(', ') : title.slice(0, -1).join(', ') + ' and ' + title.slice(-1);
    }

    getMortgageeName(mortgage: Mortgage, fullNameFlag?: boolean): string {
        let matterParticipant: MatterParticipant;
        if (mortgage.mortgageeType == 'INSTITUTION') {
            matterParticipant = this.matterParticipants
                .find(mp => (mp.matterParticipantRole === 'MORTGAGEE') && mp.mortgageId === mortgage.id);
            if (fullNameFlag) {
                return matterParticipant && matterParticipant.contact ? matterParticipant.contact.fullOrBusinessName : '???';
            } else {
                return matterParticipant && matterParticipant.contact ? matterParticipant.contact.lastNameForIndividualOrCorporationName : '???';
            }

        } else {
            let matterParticipants: MatterParticipant[] = this.matterParticipants
                .filter(mp => (mp.matterParticipantRole === 'PRIVATE_LENDER') && mp.mortgageId === mortgage.id);
            if (matterParticipants && matterParticipants.length > 1) {
                matterParticipant = matterParticipants.find(p => p.primary);
            } else {
                matterParticipant = matterParticipants[0];
            }
            if (fullNameFlag) {
                return matterParticipant && matterParticipant.contact ? matterParticipant.contact.fullOrBusinessName : '???';
            } else {
                return matterParticipant && matterParticipant.contact ? matterParticipant.contact.lastNameForIndividualOrCorporationName : '???';
            }

        }
    }

    isTransactionTitleInsured(): boolean {
        return this.transactionTitleInsuredCode && (this.transactionTitleInsuredCode === 'Yes - Title Insurer' || this.transactionTitleInsuredCode === 'Yes - with Other Title Insurer');
    }

    isTitlePlusInsuranceCancelledOrDeleted(): boolean{
        return this.isInsurerTitlePlus() && this.matterTitleInsurance && (this.matterTitleInsurance.invoiceOrderStatus == 'Cancelled' || this.matterTitleInsurance.invoiceOrderStatus == 'Deleted');
    }

    initSelectedProgressionStatus(): void {
        if (this.project && this.project.isStatmentOfAdjustmentInterim()) {
            this.selectedProgressionStatus = ProgressionStatus.FINAL;
        } else {
            this.selectedProgressionStatus = null;
        }
    }

    // Occupancy Date(matter.requisitionDate)
    get soAdjExpenseOccupancyPeriodOccupancyDate(): string {
        return this.templateForProject ? moment(this.createdTimeStamp).format('YYYY/MM/DD') : this.isMatterProvinceON ?  this.requisitionDate : this.occupancyDate;
    }

    // Occupancy Date(matter.requisitionDate)
    get soAdjExpenseOccupancyPeriodClosedate(): string {
        return this.templateForProject
            ? (this.isAdjustAsAtSpecify() ? (this.isAdjustmentStatusModeFinal ? this.adjustAsAtClosingDate : this.adjustAsAtClosingDateInterim) :
                (moment(this.createdTimeStamp).format('YYYY/MM/DD')))
            : this.getClosingDate();
    }


    updateStatementAdjustments() {
        this.updateTotalDuringOccupancyPeriodAdjustment();
    }

    updateUnitLevelPlan(matterProperty: MatterProperty): void {
        this.updateUnitLevelPlanCreated(matterProperty);
        this.calculateStatementAdjustment();
        this.updateStatementAdjustments();
        this.removeDeprecatedMatterTaxAndAdjustmentForCondo();
    }

    copyCondoExpensesIntoUnitLevelPlan(matterProperty: MatterProperty, updateAdjustment: boolean = true): void {
        this.copyCondoExpensesFromTemplateMatterIntoUnitLevelPlan(matterProperty);
        if (updateAdjustment) {
            this.calculateStatementAdjustment();
            this.updateStatementAdjustments();
            this.removeDeprecatedMatterTaxAndAdjustmentForCondo();
        }
    }

    removeMatterTaxeByRollNumber(index: number) {
        let matterTaxByRollRollNumberIndex = this.propertyModel.matterTaxesByRollNumber.findIndex(item => item.rollNumberOrder === index);
        if (matterTaxByRollRollNumberIndex > -1) {
            this.propertyModel.matterTaxesByRollNumber.splice(matterTaxByRollRollNumberIndex, 1);
        }
    }

    //Removing MatterTaxes for ID's not found
    removeDeprecatedMatterTaxAndAdjustmentForCondo() {
        if (this.isMatterProvinceAB && this.propertyModel.separateTaxAdjustmentByRollNumber &&
            this.propertyModel.isCondominium === DpBooleanValueTypes.YES && this.propertyModel.condominiumExpenses) {
            this.propertyModel.condominiumExpenses.forEach((condoExpense, index) => {
                if (!condoExpense.rollNumber) {
                    if (this.propertyModel.matterTaxesByRollNumber && this.propertyModel.matterTaxesByRollNumber.length > 0) {
                        this.removeMatterTaxeByRollNumber(index);
                    }
                }
            })
            this.removeDeprecatedAdjustmentsPropertyTaxByRollNumber();
            this.resetSeparateTaxAdjustmentByRollNumber();
        }
    }

    //Removing adjustment for ID's not found
    removeDeprecatedAdjustmentsPropertyTaxByRollNumber() {
        if (this.statementOfAdjustments && this.statementOfAdjustments.length > 0) {
            this.statementOfAdjustments.forEach(adj => {
                if (adj.isOriginPropertyTaxes()) {
                    let matterTaxByRollNumber = this.propertyModel.matterTaxesByRollNumber.find(item => item && item.id === adj.propertyMatterTaxId);
                    if (matterTaxByRollNumber == undefined) {
                        this.removeStatementAdjustment(adj);
                    }
                }
            })
        }
    }

    resetSeparateTaxAdjustmentByRollNumber() {
        if (this.propertyModel.isCondominium === DpBooleanValueTypes.YES) {
            let condoExpensesWithRollNumber = this.propertyModel.condominiumExpenses.filter(item => (item.rollNumber != undefined && item.rollNumber != ''));
            if (condoExpensesWithRollNumber.length < 2) {
                this.propertyModel.separateTaxAdjustmentByRollNumber = false;
                this.propertyModel.matterTax = this.propertyModel.matterTaxesByRollNumber[0];
                this.propertyModel.updatePropertyTaxes(this.propertyModel.matterTax, this.formattedAllTaxesAmount(this.propertyModel.matterTax), this.formattedTrustAmount(this.propertyModel.matterTax), this.provinceCode);
            }
        } else {
            let matterPropertiesWithRollNumber = this.matterProperties.filter(item => item.rollNumber.city != '');
            if (matterPropertiesWithRollNumber.length < 2) {
                this.propertyModel.separateTaxAdjustmentByRollNumber = false;
                this.propertyModel.matterTax = this.propertyModel.matterTaxesByRollNumber[0];
                this.propertyModel.updatePropertyTaxes(this.propertyModel.matterTax, this.formattedAllTaxesAmount(this.propertyModel.matterTax), this.formattedTrustAmount(this.propertyModel.matterTax), this.provinceCode);
            }
        }

    }

    // Move into matter rather than property model
    get propertyModel(): MatterProperty {
        if (!this.matterPropertyWithCondo) {
            this.matterProperties.push(MatterProperty.createDefaultMatterProperty());
        }
        return this.matterPropertyWithCondo;
    }

    formattedAllTaxesAmount(propertyTax: MatterTax): string {
        return propertyTax ? propertyTax.formattedAllTaxesAmount(this, InterimTaxMultiplier) : '';
    }

    formattedTrustAmount(propertyTax: MatterTax): string {
        return propertyTax ? propertyTax.formattedTrustAmount() : '';
    }

    getUnitLevelPlanCondominiumTotalSharePercentage(): Number {
        return this.matterPropertyWithCondo && this.matterPropertyWithCondo.condominiumTotalSharePercentage;
    }

    getLincPlanCondominiumTotalSharePercentage(): Number {
        return this.matterPropertyWithCondo && Number(this.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes);
    }

    getCondominiumTotalSharePercentage(): Number{
        return this.isMatterProvinceON
            ? this.getUnitLevelPlanCondominiumTotalSharePercentage(): this.getLincPlanCondominiumTotalSharePercentage();
    }

    getUnitLevelPlanCondominiumTotalExpenses(): Number {
        return this.matterPropertyWithCondo && this.matterPropertyWithCondo.condominiumTotalExpenses;
    }

    get projectAdjustmentConfig(): ProjectAdjustmentConfig {
        return this.project && this.project.projectAdjustmentConfig;
    }

    get eRegTransferDocumentTypeForProject(): string {
        return this.project && this.project.docRegistration && this.project.docRegistration.transferDocumentType;
    }

    get eRegChargeDocumentTypeForProject(): string {
        return this.project && this.project.docRegistration && this.project.docRegistration.chargeDocumentType;
    }

    updateTotalDuringOccupancyPeriodAdjustment() {
        let occupancyPeriodSOAList: StatementAdjustment[] = this.statementOfAdjustments.filter(adj => adj.itemKey === StatementAdjustmentKey.OCCUPANCY_PERIOD);
        if (occupancyPeriodSOAList) {
            occupancyPeriodSOAList.forEach(occupancyPeriodSOA => {
                if (occupancyPeriodSOA.soAdjExpenseOccupancyPeriod) {
                    occupancyPeriodSOA.soAdjExpenseOccupancyPeriod.updateSoAdjExpenseOccupancyPeriod(this.getUnitLevelPlanCondominiumTotalExpenses(),
                        this.getUnitLevelPlanCondominiumTotalSharePercentage(),
                        this.soAdjExpenseOccupancyPeriodClosedate,
                        this.soAdjExpenseOccupancyPeriodOccupancyDate,
                        occupancyPeriodSOA);
                }

            });
        }
    }

    get firstInterimEarlyPossessionFeeSoa(): StatementAdjustment {
        return this.interimStatementAdjustments ? this.interimStatementAdjustments.find(adj => adj.itemKey === StatementAdjustmentKey.INTERIM_EARLY_POSSESSION_FEE) : null
    }

    get interimEarlyPossessionFeeSoas(): StatementAdjustment[] {
        if (!this.interimStatementAdjustments) {
            return [];
        }
        return this.interimStatementAdjustments.filter((soa: StatementAdjustment) => {
            return soa.itemKey == StatementAdjustmentKey.INTERIM_EARLY_POSSESSION_FEE;
        });
    }

    recalculateAllPurchasePriceDeferredPortion() {
        const firstInterimEarlyPossessionFeeSoa: StatementAdjustment = this.firstInterimEarlyPossessionFeeSoa;
        let purchasePriceDeferredPortionSoas: StatementAdjustment[] = this.purchasePriceDeferredPortionSoas;
        if (purchasePriceDeferredPortionSoas) {
            purchasePriceDeferredPortionSoas.forEach((soa: StatementAdjustment) => {
                if (this.project) {
                    soa.soAdjPurchasePriceDeferredPortion.updateDeferredPortionOfPurchase(this.project.isCondo,
                        this.project.isPOTL,
                        this.project.projectCondo && this.project.projectCondo.occupancyFeesCalculatedBasedOn,
                        this.project.isOccupancyDateRequired,
                        this.extraDepositConfig && this.extraDepositConfig.deferredPurchaseMoniesAmount,
                        firstInterimEarlyPossessionFeeSoa && firstInterimEarlyPossessionFeeSoa.soAdjInterimEarlyPossessionFee && firstInterimEarlyPossessionFeeSoa.soAdjInterimEarlyPossessionFee.mortgageInterest,
                        soa)
                }
            });
        }
    }

    isInterimEarlyPossessionFeeRemovable(): boolean {
        return !(this.project && !this.project.isCondo && (this.project.isPOTL || this.project.isOccupancyDateRequired)
            && this.purchasePriceDeferredPortionSoas.length > 0 && this.interimEarlyPossessionFeeSoas.length === 1);
    }

    /**
     * updates those POTL adjustments that might use the commonExpenses for amount
     */
    updatePOTLAdjustmentsWithCommonExpense(): void {
        this.allStatementAdjustments.filter(adj => adj.isPOTLCommonExpenseAdjustment()).forEach(adj => {
            if (adj.soaCommonExpense && adj.soaCommonExpense.usePotlCommonExpenseAmount === 'YES') {
                adj.soaCommonExpense.commonExpenseAmount = Number(this.matterPropertyWithCondo.commonExpenses);
                adj.updateFromCommonExpense(this.getClosingDate(), this.isPaysForDateOfClosingVendor);
            }
        });
        if (this.isProjectSale && this.matterPropertyWithCondo && !this.matterPropertyWithCondo.isPropertyCondominium() && this.matterPropertyWithCondo.isPropertyParcelOfTiedLand()) {
            SoaExpenseAdjustmentUtil.updateReserveFundAdjustment(this.allStatementAdjustments,this.matterPropertyWithCondo.commonExpenses.valueOf());
        }
    }

    // It will return the first CreditVendorTaxRebate StatementAdjustment with the selected partOfSalePrice option

    get rebatePurchaserNotEligibleSoa(): StatementAdjustment {
        return (this.isProjectSale || (this.isPurchase && this.isMatterProvinceAB && this.matterLink) || this.templateForProject) && this.statementOfAdjustments
            ? this.statementOfAdjustments.find(adj =>
                adj.itemKey === StatementAdjustmentKey.CREDIT_VENDOR_TAX_REBATE
                && adj.soAdjCreditVendorTaxRebate
                && adj.soAdjCreditVendorTaxRebate.isPartOfSalePrice())
            : null
    }


    updateAdditionalRebatePurchaserNotEligible() {
        if (this.considerationLtt && this.considerationLtt.salePriceAdjustment) {
            let rebateSoa = this.rebatePurchaserNotEligibleSoa;
            if (rebateSoa && rebateSoa.soAdjCreditVendorTaxRebate) {
                this.considerationLtt.salePriceAdjustment.additionalRebatePurchaserNotEligible
                    = this.considerationLtt.salePriceAdjustment.totalRebatePortionWithTax(this.soaProvincialHst,
                    this.soaFederalHst,
                    this.getSalePriceForTaxAdjustments(this.soaFederalHst, this.soaProvincialHst),
                    rebateSoa.soAdjCreditVendorTaxRebate.isAdjusted === 'YES' ? rebateSoa.soAdjCreditVendorTaxRebate.percentage : null);
            } else {
                this.considerationLtt.salePriceAdjustment.additionalRebatePurchaserNotEligible = 0;
            }
        }
    }

    updateChattelsConsiderationLTTFromSalePriceAdjustment(): void {
        //we should only overwrite the this.considerationLtt.valueOfAllChattles when the chattelsIncludedInSalePrice in salePriceAdjustment is changed as in JR40554
        if (this.isMatterProvinceON && (this.isPurchase || this.isSale || this.isProjectOrProjectSale)){
            if( this.considerationLtt.needToUpdateValueOfAllChattles ){
                this.considerationLtt.valueOfAllChattles = this.considerationLtt.salePriceAdjustment.chattelsIncludedInSalePrice;
                this.considerationLtt.needToUpdateValueOfAllChattles = false;//reset the flag
            }
        }
    }

    updateSalePriceConsiderationLTT(ontarioTaxRateSlab, torontoTaxRateSlab, considerationTaxes: ConsiderationTaxes): void {
        // Update Statement of Adjustment
        this.createUpdateSalePriceAdjustment(considerationTaxes);
        //Update Consideration Taxes
        if (this.matterPropertyWithCondo && this.matterPropertyWithCondo.isSalePriceInSoa()) {
            let purchasePriceSOA = this.matterPropertyWithCondo.soaPurchasePrice;
            this.matterPropertyWithCondo.purchasePrice = purchasePriceSOA;
            if (!this.considerationLtt) {
                this.createNewConsiderationLtt();
            }
            if (this.considerationLtt) {
                this.recalculateConsiderationLTTBasedOnSalePrice(considerationTaxes, ontarioTaxRateSlab, torontoTaxRateSlab);
                this.updateLTTOnStatementOfAccount();
            }
        }

        MatterStatementAdjustmentUtil.updateCreditVendorTaxRebateAdjustment(this);

        if(this.isMatterProvinceNS){
            this.calculateLandTransferTaxNS();
            this.soaTrustLedgerCollection.updateLttNS();
        }
        if(this.isMatterProvinceNB){
            this.calculateLandTransferTaxNB();
            this.soaTrustLedgerCollection.updateLttNB();
        }
    }

    getTemplateCodesUsedInMatter(): string [] {
        let templateCodes: string[] = [];

        templateCodes.push(...this.getTemplateCodesUsedInAdjustments());
        return _.uniq(templateCodes);
    }

    getTemplateCodesUsedInAdjustments(): string [] {
        let templateCodes: string[] = [];
        this.allStatementAdjustments.filter(soa => soa.isComponentAdjustment()).forEach(soa => {
            templateCodes.push(...soa.soAdjComponent.extractTemplateCodesFromComponentAdj())
        });
        return templateCodes;
    }

    isExtrasSubjectToGSTExists(): boolean {
        return this.considerationLtt && this.considerationLtt.salePriceAdjustment && this.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice == 'NO'
            && this.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings.length > 0;
    }

    isAnyAdjustmentWithFirstAdditionalConsideration(checkInterimAndFinal? : boolean): boolean {
        return MatterStatementAdjustmentUtil.isAnyAdjustmentWithFirstAdditionalConsideration(this, checkInterimAndFinal);
    }

    isAnyAdjustmentWithSecondAdditionalConsideration(checkInterimAndFinal? : boolean): boolean {
        return MatterStatementAdjustmentUtil.isAnyAdjustmentWithSecondAdditionalConsideration(this, checkInterimAndFinal);
    }

    isAnyAdjustmentWithThirdAdditionalConsideration(checkInterimAndFinal? : boolean): boolean {
        return MatterStatementAdjustmentUtil.isAnyAdjustmentWithThirdAdditionalConsideration(this , checkInterimAndFinal);
    }

    createStatementAdjustmentOrder(adjustment: StatementAdjustment, selectedStatementAdjustmentOrderObject?: StatementAdjustmentOrder): void {
        MatterStatementAdjustmentUtil.createStatementAdjustmentOrder(this, adjustment, selectedStatementAdjustmentOrderObject);
    }

    updateSalePriceAdjWithHstReduction(isProjectHstReductionInSalePriceAdjustment: boolean, ontarioTaxRateSlab: ConsiderationTaxes, torontoTaxRateSlab: ConsiderationTaxes, considerationTaxes: ConsiderationTaxes) {
        if (this.considerationLtt && this.considerationLtt.salePriceAdjustment) {
            this.considerationLtt.salePriceAdjustment.isProjectHstReductionInSalePriceAdjustment = isProjectHstReductionInSalePriceAdjustment;
        }
        let soaSelectedProgressionStatus = this.selectedProgressionStatus;
        this.updateStatusMode(ProgressionStatus.FINAL);
        this.updateSalePriceConsiderationLTT(ontarioTaxRateSlab, torontoTaxRateSlab, considerationTaxes);
        this.updateStatusMode(ProgressionStatus.INTERIM);
        this.updateSalePriceConsiderationLTT(ontarioTaxRateSlab, torontoTaxRateSlab, considerationTaxes);
        this.updateStatusMode(soaSelectedProgressionStatus);

    }

    updateProjectTaxChange(considerationTaxes: ConsiderationTaxes, projectAdjustment: ProjectAdjustmentConfig): void {
        if (considerationTaxes && projectAdjustment) {
            this.createUpdateSalePriceAdjustment(considerationTaxes);
            this.project.projectAdjustmentConfig.interimTaxAdjustment = new ProjectTaxAdjustmentConfig(projectAdjustment.interimTaxAdjustment);
            this.project.projectAdjustmentConfig.finalTaxAdjustment = new ProjectTaxAdjustmentConfig(projectAdjustment.finalTaxAdjustment);
            this.updateTaxAdjustmentsAfterSalePriceAdjustmentUpdate(InterimTaxMultiplier);
        }
    }

    initializeHoldbacks(): void {

        this.holdbacks = [];
        this.advanceMatterHoldbackConfig = new AdvanceMatterHoldbackConfig();
        this.advanceMatterHoldbackConfig.lawyerResponsible = DpBooleanValueTypes.N_y;
        this.advanceMatterHoldbackConfig.releaseStatus = <HOLDBACKSTATUS>HOLDBACK_STATUS_TYPE.notReleased;
    }

    createHoldback(sourceMatterHoldback: MatterHoldback, holdbackType: string, mortgageId?: number, ignoreMortgageIdInCopyHoldBack: boolean = false): void {
        if (!this.holdbacks || this.holdbacks && !this.holdbacks.length && !ignoreMortgageIdInCopyHoldBack) {
            this.initializeHoldbacks();
        }
        let matterHoldback = new MatterHoldback(sourceMatterHoldback);
        matterHoldback.id = UUIDUtil.getUUID();
        matterHoldback.holdbackType = <MATTERHOLDBACKTYPE>holdbackType;
        matterHoldback.holdbackOrder = this.holdbacks.length ? (this.holdbacks[this.holdbacks.length - 1].holdbackOrder + 1) : 1;
        if (mortgageId && !ignoreMortgageIdInCopyHoldBack) {
            matterHoldback.mortgageId = mortgageId;
            sourceMatterHoldback.mortgageId = mortgageId;
        } else {
            matterHoldback.mortgageId = null;
        }
        matterHoldback.advanceHoldbackPriority = sourceMatterHoldback.getHoldbackMortgagePriorityWithoutOrdinal(this, ignoreMortgageIdInCopyHoldBack);
        this.holdbacks.push(matterHoldback);
    }

    get isProjectOrProjectSale(): boolean {
        return this.isProjectSale || this.templateForProject;
    }

    get isTransferFromThirdPartyOnProject(): boolean {
        return this.project && this.project.docRegistration && this.project.docRegistration.isTransferFromThirdPartySelected();
    }


    isLateClosingAllowed(): boolean {
        switch (this.provinceCode) {
            case 'AB' :
                return this.isClosingDayTypeCashDifference() || this.isClosingDayTypeNil();
            case 'SK':
            case 'MB':
                return this.isClosingDayTypeBalanceOfDownPayment() || this.isClosingDayTypeNil();
            default:
                return false;
        }
    }

    set dirty(dirty: boolean) {
        this.isDirty = dirty;

        //subject is initialized in matter.component.ts during mass update only
        if (this.dirtySubject) {
            //emit event that dirty flag was set
            this.dirtySubject.next(dirty);
        }
    }

    get dirty() {
        return this.isDirty;
    }

    setPrivateLenderPrimaryFlag(matterParticipant: MatterParticipant, matterParticipantWrapperArray: MatterParticipantWrapper[]): void {
        if (matterParticipant.primary) {
            matterParticipantWrapperArray.forEach((w: MatterParticipantWrapper) => {
                if (w.matterParticipant) {
                    w.matterParticipant.primary = false;
                }
            });
            matterParticipant.primary = true;
        }
        else if (matterParticipantWrapperArray.length === 1) {
            matterParticipant.primary = true;
        }
    }

    get commonExpenseAdjustmentFromProperty(): StatementAdjustment {
        return this.statementOfAdjustments.find(item => item.isCommonExpenseAdjustmentFromProperty());
    }

    isLawFirmVisible(mortgage): boolean {
        if (this.isPurchase) {
            return mortgage.loanType == 'ARRANGED' && mortgage.acting == 'NO' && mortgage.mortgageCorrespondenceType == 'SOLICITOR';
        } else {
            return mortgage.mortgageCorrespondenceType == 'SOLICITOR';
        }
    }

    getMatterTopicByKey(topicKey): MatterTopic {
        return this.matterTopics.find(item => item.matterTopicKey == topicKey);
    }

    get matterOpeningTopic(): MatterTopic {
        return this.getMatterTopicByKey('MATTER_OPENING');
    }

    get matterInsuranceTopic(): MatterTopic {
        return this.getMatterTopicByKey('FIRE_INSURANCE');
    }

    get mainClientTopic(): MatterTopic {
        return this.getMatterTopicByKey(this.mainClientType);
    }

    get existingMortgageTopic(): MatterTopic {
        return this.getMatterTopicByKey('EXISTING_MORTGAGE');
    }

    get newMortgageTopic(): MatterTopic {
        return this.getMatterTopicByKey('MORTGAGES_MORTGAGEE');
    }

    get residentialRentalRebateMatterForm(): MatterForm {
        return this.matterForms.find(item => item.formType == TypeOfMatterFormValue.RESIDENTIAL_RENTAL_REBATE_FORM);
    }

    set residentialRentalRebateMatterForm(matterForm: MatterForm) {
        if (matterForm) {
            let matterFormIndex: number = this.matterForms.findIndex(item => item.formType == TypeOfMatterFormValue.RESIDENTIAL_RENTAL_REBATE_FORM);
            if (matterFormIndex >= 0) {
                this.matterForms.splice(matterFormIndex, 1);
            }

            this.matterForms.push(matterForm);
        }
    }

    get pstRebateFormNewHomeMatterForm(): MatterForm {
        return this.matterForms.find(item => item.formType == TypeOfMatterFormValue.NEW_HOME_PST_REBATE_FORM);
    }

    set pstRebateFormNewHomeMatterForm(matterForm: MatterForm) {
        if (matterForm) {
            let matterFormIndex: number = this.matterForms.findIndex(item => item.formType == TypeOfMatterFormValue.NEW_HOME_PST_REBATE_FORM);
            if (matterFormIndex >= 0) {
                this.matterForms.splice(matterFormIndex, 1);
            }

            this.matterForms.push(matterForm);
        }
    }
    get residentialRentalRebateMatterFormIndex(): number {
        return this.matterForms.findIndex(item => item.formType == TypeOfMatterFormValue.RESIDENTIAL_RENTAL_REBATE_FORM);
    }

    get isMigratedMatter(): boolean {
        return this.matterOrderNumber === "migrated";
    }

    get soAccountTaxRate(): MatterTaxRate[] {
        return this.matterTaxRates.filter(item => item.isSoAccountTaxRate());
    }

    initializeMatterTaxRates() {
        this.createOrUpdateTaxRate(taxRateTypeValues.soAdjustment, ProgressionStatus.FINAL);
        this.createOrUpdateTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.FINAL);

        if(this.isProjectSale){
            this.createOrUpdateTaxRate(taxRateTypeValues.soAdjustment, ProgressionStatus.INTERIM);
            this.createOrUpdateTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.INTERIM);
        }

    }

    createOrUpdateTaxRate(taxRateType: string, progressionStatus: string) {
        let taxRate = this.matterTaxRates.find(item => item.taxRateType == taxRateType && item.progressionStatus == progressionStatus);
        if(!taxRate){
            this.createTaxRate(taxRateType, progressionStatus);
        } else {
            this.updateMatterTaxRate(taxRateType, progressionStatus);
        }
    }

    createTaxRate(taxRateType: string, progressionStatus: string, matterSupplementalTaskCategoryId?: number , matterSoaSheetId? : number): MatterTaxRate {
        let taxRate :MatterTaxRate;
        if(matterSoaSheetId){
            let existingSoaTaxRateIndex : number = this.matterTaxRates.findIndex(item => item.matterSoaSheetId == matterSoaSheetId);
            if(existingSoaTaxRateIndex > -1){
                taxRate = this.matterTaxRates[existingSoaTaxRateIndex];
            }else{
                taxRate = new MatterTaxRate();
                this.matterTaxRates.push(taxRate);
            }
        }else{
            taxRate = new MatterTaxRate();
            this.matterTaxRates.push(taxRate);
        }

        taxRate.taxRateType = taxRateType;
        taxRate.progressionStatus = progressionStatus;
        taxRate.hstRate = this.matterHst;
        taxRate.provincialHstRate = this.matterProvincialHst;
        taxRate.federalHstRate = this.matterFederalHst;
        if (matterSupplementalTaskCategoryId) {
            taxRate.matterSupplementalTaskCategoryId = matterSupplementalTaskCategoryId;
        }
        if (matterSoaSheetId) {
            taxRate.matterSoaSheetId = matterSoaSheetId;
        }

        return taxRate;
    }

    updateMatterTaxRate(taxRateType: string, progressionStatus: string, matterSupplementalTaskCategoryId?: number): void {
        let taxRate = this.matterTaxRates.find(item => item.taxRateType == taxRateType && item.progressionStatus == progressionStatus
            && (!matterSupplementalTaskCategoryId || (matterSupplementalTaskCategoryId && item.matterSupplementalTaskCategoryId == matterSupplementalTaskCategoryId)));
        if (taxRate) {
            taxRate.hstRate = this.matterHst;
            taxRate.provincialHstRate = this.matterProvincialHst;
            taxRate.federalHstRate = this.matterFederalHst;
        }
    }

    get soAccountTaxRateInterim(): MatterTaxRate {
        if (this.soAccountTaxRate) {
            let taxRate = this.soAccountTaxRate.find(item => item.isInterim() && !item.matterSoaSheetId);
            return taxRate ? taxRate : this.createTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.INTERIM);
        } else {
            this.createTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.INTERIM);
        }
    }

    get soAccountTaxRateFinal(): MatterTaxRate {
        if (this.soAccountTaxRate) {
            let taxRate = this.soAccountTaxRate.find(item => item.isFinal() && !item.matterSoaSheetId);
            return taxRate ? taxRate : this.createTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.FINAL);
        } else {
            this.createTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.FINAL);
        }
    }

    get soAccountHst(): number {
        return this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.isSoaInterimMode ? this.soAccountTaxRateInterim.hstRate : this.soAccountTaxRateFinal.hstRate;
    }

    set soAccountHst(value: number) {
        if (this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.isSoaInterimMode) {
            this.soAccountTaxRateInterim.hstRate = value;
        } else {
            this.soAccountTaxRateFinal.hstRate = value;
        }
    }

    get soAccountFederalHst(): number {
        return this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.isSoaInterimMode ? this.soAccountTaxRateInterim.federalHstRate : this.soAccountTaxRateFinal.federalHstRate;

    }


    getSoAccountFederalHstBySheet(sheetId : number) : number{
        if (this.soAccountTaxRate) {
            let taxRate = this.soAccountTaxRate.find(item => item.isFinal() && item.matterSoaSheetId == sheetId );
            let soAccountTaxRateFinal = taxRate ? taxRate : this.createTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.FINAL, undefined,sheetId);
            return soAccountTaxRateFinal.federalHstRate
        } else {
            let soAccountTaxRateFinal = this.createTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.FINAL, undefined,sheetId);
            return soAccountTaxRateFinal.federalHstRate
        }

    }

    set soAccountFederalHst(value: number) {
        if (this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.isSoaInterimMode) {
            this.soAccountTaxRateInterim.federalHstRate = value;
        } else {
            this.soAccountTaxRateFinal.federalHstRate = value;
        }
    }

    get soAccountProvincialHst(): number {
        return this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.isSoaInterimMode ? this.soAccountTaxRateInterim.provincialHstRate : this.soAccountTaxRateFinal.provincialHstRate;
    }


    getSoAccountProvincialHstBySheet(sheetId : number) : number{
        if (this.soAccountTaxRate) {
            let taxRate = this.soAccountTaxRate.find(item => item.isFinal() && item.matterSoaSheetId == sheetId);
            let soAccountTaxRateFinal = taxRate ? taxRate : this.createTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.FINAL, undefined,sheetId);
            return soAccountTaxRateFinal.provincialHstRate
        } else {
            let soAccountTaxRateFinal = this.createTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.FINAL, undefined,sheetId);
            return soAccountTaxRateFinal.provincialHstRate
        }

    }

    getSoAccountHstBySheet(sheetId : number) : number{
        if (this.soAccountTaxRate) {
            let taxRate = this.soAccountTaxRate.find(item => item.isFinal() && item.matterSoaSheetId == sheetId);
            let soAccountTaxRateFinal = taxRate ? taxRate : this.createTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.FINAL, undefined,sheetId);
            return soAccountTaxRateFinal.hstRate
        } else {
            let soAccountTaxRateFinal = this.createTaxRate(taxRateTypeValues.soAccount, ProgressionStatus.FINAL, undefined,sheetId);
            return soAccountTaxRateFinal.hstRate
        }

    }

    set soAccountProvincialHst(value: number) {
        if (this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.isSoaInterimMode) {
            this.soAccountTaxRateInterim.provincialHstRate = value;
        } else {
            this.soAccountTaxRateFinal.provincialHstRate = value;
        }
    }

    updateSoAccountTaxRate(considerationTaxes: ConsiderationTaxes) {
        this.soAccountHst = considerationTaxes.hstRate;
        this.soAccountFederalHst = considerationTaxes.hstFederalPortion;
        this.soAccountProvincialHst = considerationTaxes.hstProvincialPortion;
    }

    get feeCalculatedOnInclusivePriceSelectionMatter(): boolean {
        return this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.isSoaInterimMode ? this.interimFeeCalculatedOnInclusivePriceFlag : this.feeCalculatedOnInclusivePriceFlag;
    }

    set soaFeesCalculatedOnAllInclusivePriceValueMatter(value: number) {
        if (this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.isSoaInterimMode) {
            this.soaInterimFeesCalculatedOnAllInclusivePrice = (value != undefined && value != null) ? value : 0;
        } else {
            this.soaFeesCalculatedOnAllInclusivePrice =  (value != undefined && value != null) ? value : 0;
        }
    }
    set feeCalculatedOnInclusivePriceSelectionMatter(value: boolean) {
        if (this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.isSoaInterimMode) {
            this.interimFeeCalculatedOnInclusivePriceFlag = value;
        } else {
            this.feeCalculatedOnInclusivePriceFlag = value;
        }
    }

    get soaFeesCalculatedOnAllInclusivePriceValueMatter(): number {
        return this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.isSoaInterimMode ? this.soaInterimFeesCalculatedOnAllInclusivePrice : this.soaFeesCalculatedOnAllInclusivePrice;
    }

    getSupplementalTaskTaxRate(id: number): MatterTaxRate {
        let taxRate = this.matterTaxRates.find(item => item.matterSupplementalTaskCategoryId == id);
        return taxRate ? taxRate : this.createTaxRate(taxRateTypeValues.supplementalTask, ProgressionStatus.FINAL, id);
    }

    get needToPopulateReceivedDepositHeldInTrustTL(): boolean {
        let result: boolean = false;
        if (this.matterPropertyWithCondo) {
            result = this.matterPropertyWithCondo.isPayDepositOutOfTrust;
            if (this.isPurchase) {
                return result && (this.depositHeldBy == constValues.depositHeldBy.PURCHASER_LAWYER);
            } else if (this.isProjectSale) {
                return result;
            } else if (this.isSale) {
                return result && (this.depositHeldBy == constValues.depositHeldBy.VENDOR_LAWYER);
            }
        }
        return result;
    }

    addNewMortgageeParticipant(contact: Contact, mortgageId: number): MatterParticipant {
        let participant: MatterParticipant = this.addMatterParticipant(contact, true, 'MORTGAGEE', mortgageId);
        if (participant) {
            participant.includeAuthorizeSignOfficer = 'N_y';
        }
        return participant;
    }

    populateFieldsFromInsurerOrBroker(insurerOrBroker: Contact): void {
        if (this.fireInsuranceContactInfo) {
            this.fireInsuranceContactInfo.mailingAddress = insurerOrBroker.mailingAddress;
            this.fireInsuranceContactInfo.mailingAddress.id = null;
            this.fireInsuranceContactInfo.workPhone = insurerOrBroker.workPhone;
            this.fireInsuranceContactInfo.faxPhone = insurerOrBroker.faxPhone;
        }
    }

    resetFieldsFromInsurerOrBroker(): void {
        if (this.fireInsuranceContactInfo) {
            this.fireInsuranceContactInfo.clearMailingAddress();
            this.fireInsuranceContactInfo.workPhone = null;
            this.fireInsuranceContactInfo.faxPhone = null;
            this.fireInsuranceContactInfo.email = null;
            this.fireInsuranceContactInfo.dear = null;
            this.fireInsuranceContactInfo.agent = null;
        }

    }

    isCustomMatter(): boolean {
        return (!!this.customMatterTypeName) && !this.isOpportunityMatter();
    }

    isDischargeMatter():boolean{
        return  this.matterType == MatterTypesValue.DISCHARGE;
    }

    isWillMatter():boolean{
        return this.matterType === MatterTypesValue.WILL;
    }

    isOpportunityMatter(): boolean {
        return this.matterType == MatterTypesValue.OPPORTUNITY;
    }

    isOpportunityMatterAndTypeWill(): boolean {
        return this.matterType == MatterTypesValue.OPPORTUNITY && this.customMatterTypeName === MatterTypesValue.WILL;
    }

    initializeTargetMatterTeraviewDocketId() {
        if (!this.teranetDocket) {
            this.teranetDocket = new TeranetDocket();
        }
        this.teranetDocket.teraviewDocketIdentifier = '';
        // If useSameDocketIdentifier is true, use project's teraviewDocketIdentifier and set it read only
        if (this.project.useSameDocketIdentifier) {
            this.teranetDocket.teraviewDocketIdentifier = this.project.teraviewDocketIdentifier;
        } else {
            // If insertFileNoIntoTeraviewDocket is true, use fileNumber as default value
            if (this.project.insertFileNoIntoTeraviewDocket && this.fileNumber) {
                if (this.fileNumber.length <= 10) {
                    this.teranetDocket.teraviewDocketIdentifier = this.fileNumber;
                }
                else {
                    this.teranetDocket.teraviewDocketIdentifier = this.fileNumber.substring(0, 10);
                }
            }
        }
    }

    isBilled(): boolean {
        return this.billingStatus == BillingStatus.BILLED || this.billingStatus == BillingStatus.PREBILLED;
    }

    isPreBilled() : boolean {
        return this.billingStatus == BillingStatus.PREBILLED;
    }

    isFullMatter(): boolean {
        return this.creationStage == creationStages.full;
    }

    isDraftMatter(): boolean {
        return this.creationStage == creationStages.draft;
    }

    // Soa Adjustment taxes


    get soAdjustmentTaxRateInterim(): MatterTaxRate {
        if (this.soAdjustmentTaxRate) {
            let taxRate = this.soAdjustmentTaxRate.find(item => item.isInterim());
            return taxRate ? taxRate : this.createTaxRate(taxRateTypeValues.soAdjustment, ProgressionStatus.INTERIM);
        } else {
            this.createTaxRate(taxRateTypeValues.soAdjustment, ProgressionStatus.INTERIM);
        }
    }

    get soAdjustmentTaxRateFinal(): MatterTaxRate {
        if (this.soAdjustmentTaxRate) {
            let taxRate = this.soAdjustmentTaxRate.find(item => item.isFinal());
            return taxRate ? taxRate : this.createTaxRate(taxRateTypeValues.soAdjustment, ProgressionStatus.FINAL);
        } else {
            this.createTaxRate(taxRateTypeValues.soAdjustment, ProgressionStatus.FINAL);
        }
    }

    get soAdjustmentTaxRate(): MatterTaxRate[] {
        return this.matterTaxRates.filter(item => item.isSoAdjustmentTaxRate());
    }

    //get the tax rate used at the selected Statement Of Adjustment
    getSoaHstInSelectedSheet(): number{
        return this.isSelectedAdjustmentStatusModeFinal ? this.soAdjustmentTaxRateFinal.hstRate : this.soAdjustmentTaxRateInterim.hstRate;
    }

    get soaHst(): number {
        return this.isAdjustmentStatusModeFinal ? this.soAdjustmentTaxRateFinal.hstRate : this.soAdjustmentTaxRateInterim.hstRate;
    }

    set soaHst(value: number) {
        if (this.isAdjustmentStatusModeFinal) {
            this.soAdjustmentTaxRateFinal.hstRate = value;
        } else {
            this.soAdjustmentTaxRateInterim.hstRate = value;
        }
    }

    //get the tax rate used at the selected Statement Of Adjustment
    getSoaFederalHstInSelectedSheet(): number {
        return this.isSelectedAdjustmentStatusModeFinal ? this.soAdjustmentTaxRateFinal.federalHstRate : this.soAdjustmentTaxRateInterim.federalHstRate;
    }

    get soaFederalHst(): number {
        return this.isAdjustmentStatusModeFinal ? this.soAdjustmentTaxRateFinal.federalHstRate : this.soAdjustmentTaxRateInterim.federalHstRate;
    }

    set soaFederalHst(value: number) {
        if (this.isAdjustmentStatusModeFinal) {
            this.soAdjustmentTaxRateFinal.federalHstRate = value;
        } else {
            this.soAdjustmentTaxRateInterim.federalHstRate = value;
        }
    }

    getSoaProvincialHstInSelectedSheet(): number {
        return this.isSelectedAdjustmentStatusModeFinal ? this.soAdjustmentTaxRateFinal.provincialHstRate : this.soAdjustmentTaxRateInterim.provincialHstRate;
    }

    get soaProvincialHst(): number {
        return this.isAdjustmentStatusModeFinal ? this.soAdjustmentTaxRateFinal.provincialHstRate : this.soAdjustmentTaxRateInterim.provincialHstRate;
    }

    set soaProvincialHst(value: number) {
        if (this.isAdjustmentStatusModeFinal) {
            this.soAdjustmentTaxRateFinal.provincialHstRate = value;
        } else {
            this.soAdjustmentTaxRateInterim.provincialHstRate = value;
        }
    }

    get applicableSections(): Section[] {
        let matterTypeStart = this.matterType && this.isCustomMatter() ? 'C' :  !!this.isMatterTypeDischarge ? 'D' : this.isProjectSale ? 'J' : (this && this.matterType ? this.matterType.charAt(0) : '');
        // now Letter J in the sections is representing Projct Sale in applicableSections for applicableFor
        return MatterSections.getMatterSectionsOrderedForProvince(this.provinceCode).filter((section: Section) => section.applicableFor && section.applicableFor.indexOf(matterTypeStart) != -1
            && this.filterOverviewPage(section) && this.filterCorrespondencePage(section) && this.filterTasksPage(section)
            && (!section.hideForProvince || (section.hideForProvince && section.hideForProvince.indexOf(this.provinceCode) == -1)));
    }

    filterOverviewPage(section: Section): boolean {
        return (section.title != MatterSectionsTitles.OVERVIEW) || !!this.id;
    }

    filterCorrespondencePage(section: Section): boolean {
        return (section.title != MatterSectionsTitles.CORRESPONDENCE) || !!this.id;
    }

    filterTasksPage(section: Section): boolean {
        return (section.title != MatterSectionsTitles.TASKS) || !!this.id || section.applicableFor == 'O';
    }

    filterWillsPages(section: Section): boolean {
        if(!this.isWillMatter()) return true;
        return this.isWillMatter() &&
        !([MatterSectionsTitles.PARTIES_AND_ROLES, MatterSectionsTitles.CLIENT_INFO, MatterSectionsTitles.ASSETS_AND_GIFTS, MatterSectionsTitles.RESIDUE_AND_TRUSTS].includes(section.title)) || !!this.id;
    }

    getPropertyTaxSoa(): StatementAdjustment{
        let statementAdjustment : StatementAdjustment;
        if (this.statementOfAdjustments && this.statementOfAdjustments.length > 0) {
            statementAdjustment = this.statementOfAdjustments.find(soa => soa.itemKey == StatementAdjustmentKey.PROPERTY_TAX && this.propertyModel.matterTax &&  soa.propertyMatterTaxId ==
                this.propertyModel.matterTax.id);
        }
        return statementAdjustment;
    }

    updateRecordingsForNS(): void {
        if (this.isMatterProvinceNS && this.soaTrustLedgerCollection) {
            this.matterPropertyWithCondo.recordingDeed = this.soaTrustLedgerCollection.getF9DefaultValueForRegistrationTransfer();
            this.matterPropertyWithCondo.recordingMortgage = this.soaTrustLedgerCollection.getF9DefaultValueForRegistrationCharge();
        }
    }

    updateDefaultUndertakingForNB(previousValue : string , undertakingsConfig : UndertakingsConfig) : void {
        if(this.isMatterProvinceNB && this.isSale && this.matterPropertyWithCondo && undertakingsConfig) {
            if (this.matterPropertyWithCondo.isPurchaseTypeNewBuilderHome() && previousValue != 'NEW_BUILDER_HOME') {
               let nonEncumbranceUndertaking = this.matterUndertakings.find(item => item.isNonEncumbranceUndertakingNB());
               if(nonEncumbranceUndertaking){
                   nonEncumbranceUndertaking.description = undertakingsConfig.nonEncumbranceUndertakingsConfiguration.nonEncumbranceUndertakingsText+' '+undertakingsConfig.nonEncumbranceUndertakingsConfiguration.additionalNonEncumbranceUndertakingsText
                   nonEncumbranceUndertaking.matterUndertakingStatus = <MatterUndertakingStatus>MatterUndertakingStatusValues.not_approved;
                   nonEncumbranceUndertaking.approvedForSoAdjFlag = false;
               }
                let acknowledgmentsUndertaking = this.matterUndertakings.find(item => item.isAcknowledgmentsNB());
                if(acknowledgmentsUndertaking){
                    acknowledgmentsUndertaking.description = undertakingsConfig.acknowledgementsConfiguration.acknowledgementsText+' '+undertakingsConfig.acknowledgementsConfiguration.additionalAcknowledgementsText;
                    acknowledgmentsUndertaking.matterUndertakingStatus = <MatterUndertakingStatus>MatterUndertakingStatusValues.not_approved;
                    acknowledgmentsUndertaking.approvedForSoAdjFlag = false;
                }
            }
            else if(previousValue == 'NEW_BUILDER_HOME' && !this.matterPropertyWithCondo.isPurchaseTypeNewBuilderHome()){
                let nonEncumbranceUndertaking = this.matterUndertakings.find(item => item.isNonEncumbranceUndertakingNB());
                if(nonEncumbranceUndertaking){
                    nonEncumbranceUndertaking.description = undertakingsConfig.nonEncumbranceUndertakingsConfiguration.nonEncumbranceUndertakingsText
                    nonEncumbranceUndertaking.matterUndertakingStatus = <MatterUndertakingStatus>MatterUndertakingStatusValues.not_approved;
                    nonEncumbranceUndertaking.approvedForSoAdjFlag = false;
                }
                let acknowledgmentsUndertaking = this.matterUndertakings.find(item => item.isAcknowledgmentsNB());
                if(acknowledgmentsUndertaking){
                    acknowledgmentsUndertaking.description = undertakingsConfig.acknowledgementsConfiguration.acknowledgementsText;
                    acknowledgmentsUndertaking.matterUndertakingStatus = <MatterUndertakingStatus>MatterUndertakingStatusValues.not_approved;
                    acknowledgmentsUndertaking.approvedForSoAdjFlag = false;
                }
            }
        }
    }

    // for NB EMP mortgages can have mortgage principal 0 and the user needs to have the option to modify that before requesting TI
    titleInsuranceBuildMortgagePolicies(): void{
        if (!this.isMatterProvinceNB){
            return; // only for NB for now
        }
        if (this.matterTitleInsurance && this.mortgages && this.mortgages.length > 0){
            this.mortgages.forEach( mrtg => {
                let policy: TitleInsurancePolicy = this.matterTitleInsurance.policies.find( policy => policy.mortgageId === mrtg.id);
                if (policy){
                    this.updatePolicyFromMortgage(policy, mrtg);
                } else {
                    policy = new TitleInsurancePolicy();
                    policy.mortgageId = mrtg.id;
                    this.updatePolicyFromMortgage(policy, mrtg);
                    this.matterTitleInsurance.policies.push(policy);
                }
            });
            this.removeOrphanTIPolicies();
        }
    }

    //Removes policies associated with deleted mortgages or unassigned EMPs
    removeOrphanTIPolicies() : void {
        let orphanPolicies : TitleInsurancePolicy[] = [];
        this.matterTitleInsurance.policies.forEach((policy)=>{
            if(!this.mortgages.find(mtg => mtg.id == policy.mortgageId)){
                orphanPolicies.push(policy);
            }
        });
        if(orphanPolicies.length){
            orphanPolicies.forEach((policy)=>{
                (<any>this.matterTitleInsurance.policies).remove(policy);
            });
        }
    }

    updatePolicyFromMortgage(policy: TitleInsurancePolicy, mrtg: Mortgage): void {
        policy.fromEMPMortgageWithPrincipalZero = mrtg.isEmpMortgage() && mrtg.mortgageTerm.principal == 0;
        policy.mortgagePriority = mrtg.mortgagePriority;
        if (!policy.fromEMPMortgageWithPrincipalZero || !policy.lenderPolicyAmount) {
            policy.lenderPolicyAmount = mrtg.mortgageTerm.principal;
        }
        let lenderName: string = this.getMortgageeOrPrimaryPrivateLenderName(mrtg);
        let lenderPolicyDescription: string = `${Utils.getOrdinal(mrtg.mortgagePriority)} Mortgage - ${lenderName ? lenderName : ''}${mrtg.isEmpMortgage() ? " from " + mrtg.empMortgageProvider : ""}`;
        policy.lenderPolicyDescription = lenderPolicyDescription;
    }

    matterParticipantHasFLA(matterParticipant: MatterParticipant): boolean{
        return matterParticipant && matterParticipant.hasFla();
    }

    isConsentedSpouseSelectedForNB(matterParticipant: MatterParticipant): boolean{
        return matterParticipant && matterParticipant.isConsentedSpouseSelectedForNB();
    }

    removeSpouseParticipantSpouseInfoForNB(matterParticipant: MatterParticipant){
        if(this.matterParticipantHasFLA(matterParticipant)
            && matterParticipant.familyLawActs[0].applicableProvision == ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY
            && matterParticipant.familyLawActs[0].spouseMatterParticipantId){
            let currentSpouse: MatterParticipant = this.getMatterParticipantByFullNameExcludingSelected(matterParticipant.familyLawActs[0].spouseMatterParticipantId, matterParticipant);
            this.removeParticipantSpouseInfoForNB(currentSpouse);
        }
    }

    removeParticipantSpouseInfoForNB(matterParticipant: MatterParticipant){
        if(this.matterParticipantHasFLA(matterParticipant)){
            matterParticipant.familyLawActs[0].applicableProvision = null;
            matterParticipant.familyLawActs[0].spouseMatterParticipantId = null;
        }
    }

    getParticpantForPrintingIdVerification() : MatterParticipant[] {
        let privateLendersWithIDForms : MatterParticipant[] = [];
        for(let privateLender of this.privateLenders ) {
            if(privateLender.mortgageId){
                let mortgage = this.getMortgageById(privateLender.mortgageId);
                if(mortgage && !mortgage.isExistingMortgage()){
                    privateLendersWithIDForms.push(privateLender);
                }
            }
        }
        return this.mainClients.concat(this.guarantors).concat(privateLendersWithIDForms).concat(this.isProjectSale ? this.developers : []);
    }

    getPurchaserPortionOfTotalTaxes(projectTaxAdjustmentConfig: ProjectTaxAdjustmentConfig , purchaserPortionOfTotalTax : number): number{
        if (projectTaxAdjustmentConfig) {
            if (projectTaxAdjustmentConfig.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfTaxesForProject && (!this.isCondoCorporation || (this.project && !this.project.isCondo))) {
                return purchaserPortionOfTotalTax;
            }
            else if (projectTaxAdjustmentConfig.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfTaxesForProject) {
                return this.isMatterProvinceAB ?
                    Number(this.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes) :
                    Number(this.matterPropertyWithCondo.condominiumTotalSharePercentage);

            }
            else if (projectTaxAdjustmentConfig.taxAdjustmentType == BaseTaxAdjustmentOn.EqualDivisionAmongUnits) {
                return projectTaxAdjustmentConfig.numberOfUnits == 0 ?
                    0 :
                    Number(Number(100 / Number(projectTaxAdjustmentConfig.numberOfUnits)).toFixed(13));

            }
            else{
                return 0;
            }
        }
        return 0;
    }
    getDepositInterestAdjustment() : StatementAdjustment{
        let result : StatementAdjustment;
        result = this._interimAdjustments.find(item => item.isInterestOnDepositCondo());
        if (!result){
            result = this._finalAdjustments.find(item => item.isInterestOnDepositCondo());
        }
        return result;
    }

    getFinalInterestOnDepositUnderOldCondoAdjustment() : StatementAdjustment{
        return this._finalAdjustments.find(item => item.isInterestOnDepositUnderOldCondo());
    }

    get isProvinceABWithProject() : boolean{
        return (this.isProjectSale || this.templateForProject) && this.isMatterProvinceAB;
    }

    //It should always look up to the interim for the "Interest on Deposit - Old Condo Act"
    //If there is no one on the interim, then it should check the final
    //If there is an "Interest on Deposit - Old Condo Act" SOA added first on the final and then on the interim , then it should also consider the
    // Interim one first
    get firstInterestOnDepositUnderOldCondoSoa(): StatementAdjustment {
        let finalInterestOnDepositUnderOldCondoSoas = [];
        let allStatementAdjustments = this.interimStatementAdjustments.concat(this.finalStatementAdjustments);
        let allStatementAdjustmentOrders = this.interimStatementAdjustmentOrders.concat(this.finalStatementAdjustmentOrders);
        //Project matter use finalStatementAdjustmentOrders and interimStatementAdjustmentOrders to get the first one.
        if(this.isProjectSale){
            allStatementAdjustments.filter(adj =>adj.isInterestOnDepositUnderOldCondo()).forEach(soa=>{
                let statementAdjustmentOrder : StatementAdjustmentOrder = allStatementAdjustmentOrders.find(order=>order.adjustmentId === soa.id);
                if(statementAdjustmentOrder){
                    finalInterestOnDepositUnderOldCondoSoas.push({adjustmentIndex: statementAdjustmentOrder.adjustmentIndex , statementAdjustment: soa});
                }
            });
            if(finalInterestOnDepositUnderOldCondoSoas.length>0){
                finalInterestOnDepositUnderOldCondoSoas.sort((a, b) => {
                    if(a.statementAdjustment.isAdjustmentStatusFinal() === b.statementAdjustment.isAdjustmentStatusFinal()){
                        return Number(a.adjustmentIndex) - Number(b.adjustmentIndex);
                    } else {
                        return a.statementAdjustment.isAdjustmentStatusFinal()?  1 : -1;
                    }
                })
                return finalInterestOnDepositUnderOldCondoSoas[0].statementAdjustment;
            } else {
                return null;
            }
        } else {
            return allStatementAdjustments.find(adj =>adj.isInterestOnDepositUnderOldCondo())
        }

    }

    clearCircularReferencesBeforeStringify(){
        if (this.soaTrustLedgerCollection && this.soaTrustLedgerCollection.matter){
            this.soaTrustLedgerCollection.matter = null; // it will be set on initializing TrustLedger component
        }
        if(this.secondarySoaSheetsCollection){
            this.secondarySoaSheetsCollection.forEach( collection =>{
                collection.matter = null;
            })
        }
        if(this.dirtySubject){
            this.dirtySubject = undefined;
        }
    }

    // If only key parameter provided function is searching in all adjustments (interim and final)
    // If Both Key and progressionStatus provided function is searching in adjustments specific to a progressionStatus
    // for DPPMP-32700 chnanged requirements added one more parameter sortFinalAdjustmentsFirs. It is available only if progressionStatus is undefined.
    // if sortFinalAdjustmentsFirs is true all found final adjustments move to te top.
    findFirstAdjustmentByKey(key: string, sortFinalAdjustmentsFirs? : boolean, progressionStatus? : string): StatementAdjustment{
        return MatterStatementAdjustmentUtil.findFirstAdjustmentByKey(this, key, sortFinalAdjustmentsFirs, progressionStatus);
    }

    // function is searching in selected by UI adjustments (Final only or Interim only)
    findFirstAdjustmentByKeyInDefaultAdjustments(key: string): StatementAdjustment{
        return MatterStatementAdjustmentUtil.findFirstAdjustmentByKeyInDefaultAdjustments(this, key);
    }

    get matterOverviewStatus(): OverrideMatterStatusType {
        return MatterOverviewUtil.getMatterOverviewStatus(this);
    }

    get matterOverviewStatusLabel(): string {
        return MatterOverviewUtil.getMatterOverviewStatusLabel(this);
    }

    isPurchaseMatterLinkedProjectSale() : boolean {
        return (this.isPurchase && this.matterLink && this.isMatterProvinceAB && ((this._interimAdjustments && this._interimAdjustments.length > 0) || this.adjustments.some(item => !item.isAdjustmentStatusFinal())));
    }

    checkHSTRebateToDisplayWarningMessage(errorService: ErrorService, showBubbleNotification: boolean = true) {
        MatterErrorUtil.checkHSTRebateToDisplayWarningMessage(this, errorService, showBubbleNotification);
    }

    createERegChargeForms(mortgage: Mortgage){
        MatterEFormUtil.createERegChargeForms(this, mortgage);
    }

    createTPRMortgageEForms(mortgage: Mortgage) {
        MatterEFormUtil.createTPRMortgageEForms(this, mortgage);
    }

    createTransferOfLandEForm() {
        MatterEFormUtil.createTransferOfLandEForm(this);
    }

    createERegTransferForm() {
        MatterEFormUtil.createERegTransferForm(this);
    }

    createTPRTransferEForm() {
        MatterEFormUtil.createTPRTransferEForm(this);
    }

    removeEregistrationFormByMortgage(mortgageId: number): void {
        MatterEFormUtil.removeEregistrationFormByMortgage(this, mortgageId);
    }

    isDocumentToBeSignedRemotely() : boolean {
        return this.documentsToBeSignedRemotely == DpBooleanValueTypes.YES
    }

    isDocumentToBeSignedDigitally() : boolean {
        return this.documentsToBeSignedDigitally == DpBooleanValueTypes.YES
    }

    isDigitalSignPlatformDocuSign() : boolean{
        return this.digitalSignaturePlatform == DigitalSignaturePlatform.DOCU_SIGN;
    }

    isDigitalSignPlatformSyngrafii() : boolean{
        return this.digitalSignaturePlatform == DigitalSignaturePlatform.SYNGRAFII;
    }

    isDigitalSignPlatformSet() : boolean {
        return this.isDigitalSignPlatformDocuSign() || this.isDigitalSignPlatformSyngrafii();
    }



    get appointments(): EventData[]{
        return this.matterEvents && this.matterEvents.filter(evnt => evnt.eventType === 'APPOINTMENT');
    }

    initReferredByList() {
        this.referredByList =[];
        let referredByInfo : ReferredByInfo = new ReferredByInfo();
        referredByInfo.referredByType = <ReferredByType> ReferredByTypeValues.MANUAL_ENTRY;
        this.referredByList.push(referredByInfo);
    }

    copyReferredByList(referredByList: ReferredByInfo[]){
        this.referredByList = [];
        if (Array.isArray(referredByList)) {
            referredByList.forEach((item) => this.referredByList.push(new ReferredByInfo(item)));
        }
    }

    updateSoaTrustLedgerCollectionMatterAfterStringfy(){
        if (this.soaTrustLedgerCollection) {
            this.soaTrustLedgerCollection.matter = this;
        }
        if(this.secondarySoaSheetsCollection) {
            this.secondarySoaSheetsCollection.forEach( collection =>{
                collection.matter = this;
            })
        }
    }

    get soaPrimarySheetDetail() : string {
      return this.primarySoaDetail && this.secondarySoaSheets.length > 0  ? this.primarySoaDetail : (this.isProjectSale ? '' : 'Primary');
    }

    initOpportunityMatter() : void {
        if(!this.opportunity){
            this.opportunity = new OpportunityInfo();
        }
        this.opportunity.opportunityStatus = OpportunityMatterStatusValue.qualifying;
        this.opportunity.opportunitySource = OpportunityMatterSourceValue.blank;
        this.fullyInitialized = true; //no initialization needed since not creating from referral
    }

    isDigitalSignatureEnabledInProvince(appConfig: AppConfig): boolean{
        return appConfig && appConfig.digitalSignatureEnabledProvinces.indexOf(this.provinceCode) > -1;
    }

    isTitlePlusEnabledInProvince(appConfig: AppConfig): boolean{
        return appConfig && appConfig.titlePlusEnabledProvinces.indexOf(this.provinceCode) > -1;
    }

    isProjectSaleMatterNoInitWithSoaTL() : boolean{
        return  this.isProjectSale && (this.matterSoas.length == 0 || this.matterTrustLedgers.length == 0) && this.fullyInitialized != undefined && !this.fullyInitialized
    }

    /*
    * Check if a specific mortgage has interest only term
    * */
    isMortgageHasInterestOnlyTerm(mortgageIndex: number): boolean {
        if (this.mortgages && this.mortgages.length > mortgageIndex) {
            return this.mortgages[mortgageIndex] && this.mortgages[mortgageIndex].mortgageTerm && this.mortgages[mortgageIndex].mortgageTerm.isInterestOnlyYes();
        } else {
            return false;
        }
    }

    /*
    * Check if any mortgage has interest only term
    * */
    someMortgageWithInterestOnlyTerm() : boolean {
        return this.mortgages && this.mortgages.length && this.mortgages.some(mtg => mtg.mortgageTerm && mtg.mortgageTerm.isInterestOnlyYes());
    }

    getChildMatterParticipants(parentMatterParticipant: MatterParticipant) : MatterParticipant[] {
        return this.matterParticipants && parentMatterParticipant && parentMatterParticipant.matterParticipantId ?
            this.matterParticipants.filter(mp=>mp.parentParticipantId == parentMatterParticipant.matterParticipantId) : [];
    }

    getAllSigners(parentMatterParticipant: MatterParticipant) : MatterParticipant[] {
        return this.matterParticipants && parentMatterParticipant && parentMatterParticipant.matterParticipantId ?
            this.matterParticipants.filter(mp=>mp.parentParticipantId == parentMatterParticipant.matterParticipantId && mp.isSigningOfficer) : [];
    }

    getChildSigners(parentMatterParticipant: MatterParticipant) : MatterParticipant[] {
        return this.matterParticipants && parentMatterParticipant && parentMatterParticipant.matterParticipantId ?
            this.matterParticipants.filter(mp=>mp.parentParticipantId == parentMatterParticipant.matterParticipantId && mp.signingMatter) : [];
    }

    extractTitleForSigningOfficerOnMatter(participant : MatterParticipant): string{
        return participant.associatedContactTitle;
    }

    get consentingSpouseParticipants() : MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return mp.matterParticipantRole == MatterParticipantRoleTypes.CONSENTING_SPOUSE;
        });
    }

    getConsentSpouseParticipantByParentParticipantId(parentParticipantId: number) {
        return  this.consentingSpouseParticipants.find((mp: MatterParticipant) => {
            return mp.parentParticipantId == parentParticipantId;
        });

    }

    get isOpportunityPurchase() : boolean {
        return this.isOpportunityMatter() && this.customMatterTypeName == MatterTypesValue.PURCHASE;
    }

    get isOpportunitySale() : boolean {
        return this.isOpportunityMatter() && this.customMatterTypeName == MatterTypesValue.SALE;
    }

    get isOpportunityMortgage() : boolean {
        return this.isOpportunityMatter() && this.customMatterTypeName == MatterTypesValue.MORTGAGE;
    }

    get isOpportunityDischarge() : boolean {
        return this.isOpportunityMatter() && this.customMatterTypeName == MatterTypesValue.DISCHARGE;
    }

    get isOpportunityPurchaseOrSale() : boolean {
        return this.isOpportunityPurchase || this.isOpportunitySale;
    }

    get isOpportunityPurchaseSaleOrMortgage() : boolean {
        return this.isOpportunityPurchase || this.isOpportunitySale || this.isOpportunityMortgage;
    }

    get isOpportunityPurchaseSaleMortgageOrDischarge() : boolean {
        return this.isOpportunityPurchase || this.isOpportunitySale || this.isOpportunityMortgage || this.isOpportunityDischarge;
    }

    isOpportunityExistingMortgageSectionVisible() : boolean {
        return this.isOpportunitySale || this.isOpportunityMortgage || this.isOpportunityDischarge ||
                (this.isOpportunityPurchase && this.isActingForBothPurchaseAndSaleMatters );
    }

    isOpportunityNewMortgageSectionVisible() : boolean {
        return this.isOpportunityPurchase || this.isOpportunityMortgage ||
                (this.isOpportunitySale && this.isActingForBothPurchaseAndSaleMatters );
    }

    getContactAssociationParticipantsByParentParticipantId(matterParticipantRole : MatterParticipantRole, parentParticipantId: number): MatterParticipant[] {
        if (!this.matterParticipants) {
            return [];
        }
        return this.matterParticipants.filter((mp: MatterParticipant) => {
            return (mp.matterParticipantRole === matterParticipantRole) && (parentParticipantId === mp.parentParticipantId);
        });
    }

    getAssociationParticipants(contact: Contact, parentParticipant: MatterParticipant): MatterParticipant[] {
        let associationParticipants: MatterParticipant[] = [];
        let matterParticipantRole: MatterParticipantRole = contact.getContactAssociationParticipantRoleByGender();
        if(matterParticipantRole){
            associationParticipants = this.getContactAssociationParticipantsByParentParticipantId(matterParticipantRole, parentParticipant.matterParticipantId);
            if(contact.gender === 'CORPORATION'){ //If contact.gender === 'CORPORATION', only  the identified signing officers are tagged as signers will be concatenated
                associationParticipants = associationParticipants.filter(item=>item.signingMatter);
            }
        }
        return associationParticipants;
    }

    getMainClientType(pluralize?: boolean): string {
        return (this.isPurchase ? 'Purchaser' : (this.isSale ? 'Vendor' : 'Mortgagor')) + (pluralize? 's' : '');
    }

    isTeranetConnectChargesApplicable(): boolean {
        return (this.isPurchase || this.isSale || this.isMortgage) && this.isMatterProvinceON;
    }

    isTeranetConnectChargesApplied() : boolean {
        if(this.billingTransactions && this.billingTransactions.length){
            let teranetBillingTransaction = this.billingTransactions.find(transaction => transaction.isTeranetConnectTransactionBilledOrPrebilled());
            return teranetBillingTransaction && teranetBillingTransaction.transactionCharge > 0;
        } else {
            return false;
        }
    }

    isTeranetConnectTransactionStatusBilled() : boolean {
        if(this.billingTransactions && this.billingTransactions.length){
            return !!this.billingTransactions.find(transaction => transaction.isTeranetConnectTransactionBilled());
        } else {
            return false;
        }
    }

    getTeranetConnectChargesForSOA() : number {
        let teranetBillingTransaction = this.billingTransactions.find(transaction => transaction.isTeranetConnectTransactionBilledOrPrebilled());
        return teranetBillingTransaction && (teranetBillingTransaction.transactionCharge - teranetBillingTransaction.federalTax);
    }

    getMatterWorkItemTaskByNotificationId(taskIdentifier : number) : MatterWorkItemTask {
        let matterWorkItemTasks: MatterWorkItemTask[] = [];
        this.matterWorkItems.forEach(item => {
            matterWorkItemTasks.push(...(item.matterWorkItemTasks || []));
        });
        let matterWorkItemTask =  this.getMatterNotificationConfigByNotificationId(taskIdentifier);
        return  matterWorkItemTasks.find(task => matterWorkItemTask && task.id == matterWorkItemTask.taskIdentifier);
    }

    getMatterNotificationConfigByNotificationId(taskIdentifier : number) : MatterNotificationConfig {
        let matterNotificationConfig: MatterNotificationConfig[] = [];
        this.matterWorkItems.forEach(item => {
            matterNotificationConfig.push(...(item.tasksNotificationConfig || []));
        });
        return matterNotificationConfig.find(config => config.id == taskIdentifier);
    }

    isMatterInProgress() : boolean {
        return this.matterOverviewStatus == MatterOverviewStatusTypesValue.MATTER_IN_PROGRESS;
    }

    isMatterClosed() : boolean {
        return this.closed == DpBooleanValueTypes.YES;
    }

    isFileInactive() : boolean {
        return this.matterStatus == MatterStatus.INACTIVE;
    }

    isMortgageMatterAndMortgageePrimary() : boolean {
        return this.isMortgage && this.isActingForMortgageeORBothMortgageePrimary;
    }

    getMyClientMortgagee() : MatterParticipant {
        if (this.matterParticipants && this.matterParticipants.length > 0) {
            return this.matterParticipants.find(mp => mp.myClient && (mp.matterParticipantRole == 'MORTGAGEE' || mp.matterParticipantRole == 'PRIVATE_LENDER'));
        } else {
            return null;
        }
    }

    public reCalculateMatterLenderReLine(): void {
        let myClientMortgagee = this.getMyClientMortgagee();
        this.lenderReLine =  myClientMortgagee && myClientMortgagee.contact ? myClientMortgagee.contact.reLineName : '';
    }

    getNumberOfMortgageesAndPrivateLendersCanBeMyClient() : number {
        let mortgages : Mortgage[] = !!this.isMatterTypeDischarge? this.existingMortgages: this.mortgages;
        if (this.matterParticipants && this.matterParticipants.length > 0 && mortgages && mortgages.length) {
            let mortgagesIds = mortgages.map(item=>item.id);
            let matterParticipants = this.matterParticipants.filter(mp =>  (mp.matterParticipantRole == 'MORTGAGEE' ||  mp.matterParticipantRole == 'PRIVATE_LENDER')
                        && mortgagesIds && mortgagesIds.length && mortgagesIds.includes(mp.mortgageId) );
            return matterParticipants.length;

        } else {
            return 0;
        }
    }

    get isMatterTyeDischargeAndProvinceDisabled():boolean{
        return !!this.isMatterTypeDischarge && !!this.isProvinceDisabled;
    }

    get isMatterEditingDisabled():boolean{
        return !this.matterEditingLockOverrideTimestamp ||
        ( this.matterEditingLockOverrideTimestamp && Utils.getDateDiff(moment(this.matterEditingLockOverrideTimestamp).format('YYYY/MM/DD'), moment().format('YYYY/MM/DD')) > 0 );

    }

    isOppurtunityLinkedMatters() : boolean {
        return this.isOpportunityPurchaseOrSale
            && this.isActingForBothPurchaseAndSaleMatters
            && this.opportunity
            && !this.opportunity.isClosedDuplicate();
    }

    //if there is only one solicitor or law clerk in the firm, then pre-populate the new matter with that solicitor/clerk
    initializeSolicitorLawClerk(contacts: Contact[], type: string): void {
        if(Array.isArray(contacts)) {
            let filteredList: Contact[] = contacts.filter(item => "YES" === item.activeFlag || "Y_n" === item.activeFlag || "Y/n" === item.activeFlag);

            if (type === "SOLICITOR" && filteredList && filteredList.length == 1) {
                this.updateMatterSolicitorInfo(filteredList[0]);
            }
            if (type === "LAWCLERK" && filteredList && filteredList.length == 1) {
                this.updateMatterLawClerkInfo(filteredList[0]);
            }
        }
    }

    addMatterLink(linkedMatter: Matter) : void {
        if (linkedMatter) {
            this.matterLink = new MatterLink();
            this.matterLink.id = linkedMatter.matterLink.id;
            this.matterLink.linkedMatterId = linkedMatter.id;
            this.matterLink.linkedMatterNumber = linkedMatter.matterRecordNumber;
        }
    }

    get legalDescriptionPlanBlockLot(): string {
        let desc = '';
        if (this && (this.isMatterProvinceAB || this.isMatterProvinceNS) && this.propertyModel) {
            this.propertyModel.partLot = '';

            if (this.propertyModel && this.propertyModel.isPlanBlockLot()) {

                if (this.isMatterProvinceAB) {
                    desc = desc + 'Plan ' + (this.propertyModel.plan ? this.propertyModel.plan : '________') + ', ';
                    desc = desc + 'Block ' + (this.propertyModel.block ? this.propertyModel.block : '________') + ', ';
                }
                desc = desc + 'Lot ' + (this.propertyModel.lot ? this.propertyModel.lot : '________');

                if (this.propertyModel.isExceptionForCondo() && this.propertyModel.exceptionTypeDescription) {
                    desc = desc + ', ' + this.propertyModel.exceptionTypeDescription
                }

                this.propertyModel.partLot = desc;
            }
            else {
                if (this.propertyModel.fullLegalDescription) {
                    desc = this.propertyModel.fullLegalDescription && this.propertyModel.fullLegalDescription.replace(/\n/g, ' ');
                }
                else if (this.propertyModel.shortLegalDescription) {
                    desc = this.propertyModel.shortLegalDescription
                }
            }
            this.propertyModel.partLot = desc;
        }
        return desc;

    }

    calculateRecordings(): void {
        if (this.isMatterProvinceNS){
            this.updateRecordingsForNS();
        }
    }

    updateMultipleCondoPropBlanketMortgage() : void {
        this.isMultipleCondoPropBlanketMortgage = false;
        if(this.mortgages && this.mortgages.some( mrg => !!mrg.isBlanketMortgage) && this.isMatterProvinceAB) {
            this.mortgages.forEach(mortgage => {
                if (mortgage.isBlanketMortgage && !this.isMultipleCondoPropBlanketMortgage) {
                    let blanketMortgageProperties = this.getBlanketMortgageProperties(mortgage.id);
                    const titleInsuranceBlanketPropertyCondoTotal = blanketMortgageProperties.filter(bm => bm.isTitleInsuranceBlanketPropertyCondo()).length;
                    this.isMultipleCondoPropBlanketMortgage = (titleInsuranceBlanketPropertyCondoTotal > 1);
                }
            });
        }
    }

    createMortgageByProvince(provinceCode: string, mortgageType: MortgageType, mortgageSource: MortgageSource = 'UNITY', newMortgagePriority?: number){
        let mortgage = this.createMortgage(mortgageType, mortgageSource, newMortgagePriority);
        if(provinceCode == 'BC'){
            mortgage.mortgageeType = mortgageeTypeOptions[0].value;
        }
        if(mortgageType == 'NEW'){
            this.mortgages.push(mortgage);
        } else if (mortgageType == 'EXISTING') {
            this.existingMortgages.push(mortgage);
        }
    }
}


export class BulkMatterUpdate {

    matters: Matter[];
    matterIdWithCancelledOrUnassignedEmp : number;
    unassignedMortgageInstructionId?: number;
    cancelledMortgageInstructionIds: number[] =[];

}
