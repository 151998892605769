import {Component, ElementRef, ViewChild} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {BurgerMenuExtendedItem} from '../../../matters/shared/burger-menu-extended-item';
import {
    ExistingFile,
    FileUploadModal,
    FileUploadModalContext
} from '../../../matters/document-production/upload/file-upload-modal.component';
import {AppConfig} from '../../../shared-main/app-configuration';
import {CustomKeyCodesEnum} from '../../../common/index';
import {ReportTemplate} from '../../../matters/report-template/report-template';
import * as _ from 'lodash';
import {ExportTemplatesService} from '../export-templates.service';
import {EditExportTemplateDetailsComponent} from '../edit-export-template-details/edit-export-template-details.modal';
import {reportTemplatesApi} from '../export-templates-api';
import {AUTH_ROLE} from '../../../shared-main/authorization/authorization-keys';
import {AuthorizationService} from '../../../shared-main/authorization/authorization-service';
import {FocusFirstElementDecorator} from '../../../shared-main/focus-first-element-decorator';
import {Account} from '../../accounts/shared/account';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';

declare var jQuery : any;

class ManageExportTemplatesModalContext extends BSModalContext {
    selectedProvinceCode: string;
    account: Account
}

class ReportTemplateWrapper {
    reportTemplateFile: ReportTemplate;
    burgerMenuItems : BurgerMenuExtendedItem[] = [];
}

@FocusFirstElementDecorator()

@Component({
        selector    : 'dp-manage-export-templates-modal',
        templateUrl : 'manage-export-templates.modal.component.html',
        providers   : [ErrorService, DialogService],
        styleUrls      : ['./manage-export-templates.modal.scss']
    })

    export class ManageExportTemplatesModal implements ModalComponent<ManageExportTemplatesModalContext> {

    ACCEPTED_FILE_EXTENSIONS = '*.csv';
    context : ManageExportTemplatesModalContext;
    rows: ReportTemplateWrapper[] = [];

    @ViewChild('fileUploadElRef') fileUploadElRef: ElementRef;

    constructor(public dialog : DialogRef<ManageExportTemplatesModalContext>,
                public dialogService : DialogService,
                public exportTemplatesService : ExportTemplatesService,
                public authorizationService : AuthorizationService,
                public appConfig : AppConfig ){

        this.context = dialog.context;
    }

    ngOnInit() {
        this.retrieveDocumentFiles();

    }



    ok() : void {
        this.close();
    }

    close() : void {
        this.dialog.close();
    }

    get acceptedFileExtensions() : string {
        return this.ACCEPTED_FILE_EXTENSIONS.replace(/\*/g, '');
    }

    initiateUpload(){
        let uploadFilesControl = this.fileUploadElRef.nativeElement;
        if(!(uploadFilesControl.value && uploadFilesControl.files && uploadFilesControl.files.length > 0)) {
            return;
        }
        //Loading the documents one more time before passing them to the upload control, as we need the latest locking status from the server
        this.exportTemplatesService.getAllReportTemplates(this.context.account.id, this.context.selectedProvinceCode)
            .subscribe((reportTemplateFiles : ReportTemplate[]) => {

                let uploadUrl = reportTemplatesApi.uploadTemplates.replace('{accountId}', '' + this.context.account.id);

                let existingFiles : ExistingFile[] = reportTemplateFiles.map((docFile: ReportTemplate) => {
                    return {
                        name: docFile.templateName + '.csv', //The file names comes without extension
                        lastUpdatedTimeStamp: docFile.lastUpdatedTimeStamp,
                        isOpen: false,
                        isProtected: false
                    } as ExistingFile;
                });

                console.log(existingFiles);
                if(uploadFilesControl.files.length){
                    this.dialogService.content({
                                                   content       : FileUploadModal,
                                                   context: {
                                                       filesSelectedForUpload: uploadFilesControl.files,
                                                       uploadUrl: uploadUrl,
                                                       existingFiles: existingFiles,
                                                       acceptedFileExtensions: this.ACCEPTED_FILE_EXTENSIONS,
                                                       maxFileSize: this.maxFileSizeInMb,
                                                       applicableProvince: this.context.selectedProvinceCode,
                                                       uploadExportTemplate: true
                                                   } as FileUploadModalContext,
                                                   onFulfillment : (result) => {
                                                       uploadFilesControl.value = '';
                                                       this.retrieveDocumentFiles();
                                                   },
                                                   onRejection : (result) => {
                                                       uploadFilesControl.value = '';
                                                       this.retrieveDocumentFiles();
                                                   }
                                               });
                }

            });

    }

    get maxFileSizeInMb(): number {
        return this.appConfig.maxUploadedFileSizeInMb;
    }

    retrieveDocumentFiles() : void {
        this.exportTemplatesService.getAllReportTemplates(this.context.account.id, this.context.selectedProvinceCode)
            .subscribe((data: ReportTemplate[]) => {
               data = this.sortData(data);
                this.rows = data.map((file: ReportTemplate) => {
                    let reportTempFileWrapper : ReportTemplateWrapper = new ReportTemplateWrapper();
                    reportTempFileWrapper.reportTemplateFile = file;
                    reportTempFileWrapper.burgerMenuItems = this.getBurgerMenuItems(reportTempFileWrapper.reportTemplateFile);
                    return reportTempFileWrapper;
                });

            });
    }

    sortData(data: ReportTemplate[]) : ReportTemplate[] {
        return _.sortBy(data, (item : ReportTemplate)=>{
            return item.templateName  ? item.templateName.toLowerCase()  : '';
        });
    }

    getBurgerMenuItems(reportTemplate: ReportTemplate) : BurgerMenuExtendedItem[] {
        let burgerMenuItems : BurgerMenuExtendedItem[] = [];
        this.addToBurgerMenu(burgerMenuItems, 'Edit Description', this.editTemplateDetails, reportTemplate.public && !this.isUpdatingSystemAccount());
        this.addToBurgerMenu(burgerMenuItems, 'Download', this.downloadTemplate, false);
        this.addToBurgerMenu(burgerMenuItems, 'Delete', this.deleteTemplate, reportTemplate.public && !this.isUpdatingSystemAccount());
        return burgerMenuItems;
    }

    addToBurgerMenu(burgerMenuItems : BurgerMenuExtendedItem[], text : string, action : any, isDisabled: boolean) : BurgerMenuExtendedItem {
        let burgerMenuItem : BurgerMenuExtendedItem;
        burgerMenuItem = new BurgerMenuExtendedItem();
        burgerMenuItem.text = text;
        burgerMenuItem.action = action;
        burgerMenuItem.isDisabled = isDisabled;
        burgerMenuItems.push(burgerMenuItem);
        return burgerMenuItem;
    }

    clickBurgerMenu(reportTemplate: ReportTemplate, clickedMenuOption : BurgerMenuExtendedItem) : void {
        if(clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
            clickedMenuOption.action(reportTemplate);
        }

    }


    hasSystemRole() : boolean {
        return this.authorizationService.hasRole(AUTH_ROLE.ROLE_SYSTEM_ADMINISTRATOR) || this.authorizationService.hasRole(AUTH_ROLE.ROLE_SYSTEM_USER)
    }

    isAdminLoggedIn() : boolean {
        return String(this.context.account.id) == sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }

    isUpdatingSystemAccount() : boolean {
        return this.hasSystemRole() && this.isAdminLoggedIn();
    }



    editTemplateDetails = (reportTemplate: ReportTemplate)=> {
        this.dialogService.content({
                                       content       : EditExportTemplateDetailsComponent,
                                       context       : {
                                           reportTemplate     : reportTemplate,
                                           accountId          : this.context.account.id
                                       },
                                       onFulfillment : (result) => {
                                       },
                                       fullScreen    : false,
                                       modalGrid     : 5
                                   });
    };

    downloadTemplate = (reportTemplate: ReportTemplate)=> {
        this.exportTemplatesService.downloadReportTemplateFile(this.context.account.id, reportTemplate.id);
    };

    deleteTemplate = (reportTemplate: ReportTemplate)=> {
        let message = '<p>Are you sure that you would like to delete the template?</p><br>';
        this.dialogService.confirm('Confirmation', message, false, 'Delete' , null , true)
            .subscribe(res =>{
                if(res) {
                    this.exportTemplatesService.deleteReportTemplateFile(this.context.account.id, reportTemplate.id)
                        .subscribe(
                            (response: any) =>{
                                (<any>this.rows).remove(this.rows.find((item)=>{
                                    return item.reportTemplateFile == reportTemplate;
                                }))
                            }
                        )}
            });
    };

    upload() : void {
        this.fileUploadElRef.nativeElement.click();
    }

    tableKeyCommands(index, event) : void {

        let charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));

        if(charCode === CustomKeyCodesEnum.Down) {
            event.preventDefault();
            this.keyCommandtoSelectNext(index);
        }
        if(charCode === CustomKeyCodesEnum.Up) {
            event.preventDefault();
            this.keyCommandtoSelectPrev(index);

        }

    }

    keyCommandtoSelectNext(index) {
        if(index < this.rows.length) {
            jQuery(document.activeElement).next('tr').focus();
        }
    }

    keyCommandtoSelectPrev(index) {
        if(index > 0) {
            jQuery(document.activeElement).prev('tr').focus();
        }
    }

    ngAfterViewInit() {}
}
