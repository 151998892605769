import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DialogRef} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DocumentProfileTemplateFolder} from '../document-profile-template-folder';
import {DocumentTemplateFile} from '../../../../../matters/document-production/document-template';
import {BurgerMenuExtendedItem} from '../../../../../matters/shared/burger-menu-extended-item';
import {CustomKeyCodesEnum} from '../../../../../common';
import {DocumentProfileService} from '../../document-profile.service';
import {SESSION_STORAGE_KEYS} from '../../../../../shared/session-storage-keys';
import {DialogService} from '../../../../../shared/dialog/dialog.service';
import {EditDocumentTemplateDetailsComponent} from '../edit-document-template-details-modal/edit-document-template-details.modal';
import * as _ from 'lodash';
import {FocusFirstElementDecorator} from '../../../../../shared-main/focus-first-element-decorator';
import {
    ExistingFile,
    FileUploadModal,
    FileUploadModalContext
} from '../../../../../matters/document-production/upload/file-upload-modal.component';
import {documentProfilesApi} from '../../../shared';
import {AppConfig} from '../../../../../shared-main/app-configuration';
import {Matter} from '../../../../../matters/shared';
import {ImportDoProcessTemplateModalComponent} from './import-doprocess-template.modal.component';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Subscription} from 'rxjs/Subscription';
import {PROVINCE_CODES} from '../../../../../matters/shared/user-province';
import {MatterTypeUtil} from '../../../../../shared-main/matter-type/matter-type-util';
import {AccountService} from '../../../../accounts/account.service';
import {MatterTypeInfo} from '../../../../shared/matter-type-info';
import {Account} from '../../../../accounts/shared/account';
import {TemplateProductionClass} from '../../../../../shared-main/constants';
import {AuthorizationService} from '../../../../../shared-main/authorization/authorization-service';
import {AUTH_ROLE} from '../../../../../shared-main/authorization/authorization-keys';

declare var jQuery: any;

export type UploadableTypes = 'MASTER' | 'INCLUDE' | 'DOTX' | 'RTF' | 'RTF_INCLUDE';

class DocumentTemplateFileWrapper {
    documentTemplateFile: DocumentTemplateFile;
    burgerMenuItems: BurgerMenuExtendedItem[] = [];
}

export class ManageFolderTemplatesModalContext extends BSModalContext {
    accountFileFolder: DocumentProfileTemplateFolder;
    documentProfileId: number;
    account: Account;
    matter: Matter;
    isMatterReadOnlyAccess: boolean;
}


@FocusFirstElementDecorator()
@AutoUnsubscribe()
@Component({
               selector    : 'dp-manage-folder-templates-modal-content',
               templateUrl : 'manage-folder-templates.modal.html',
               styleUrls      : [
                   './manage-folder-templates.modal.scss'
               ],
               providers   : [DocumentProfileService]
           })

export class ManageFolderTemplatesModalComponent implements OnInit, OnDestroy, AfterViewInit {

    ACCEPTED_FILE_EXTENSIONS_DOCX = '*.docx';
    ACCEPTED_FILE_EXTENSIONS_WPD = '*.wpd';
    ACCEPTED_FILE_EXTENSIONS_WORD_DPS_LRT = '*.dotx';
    ACCEPTED_FILE_EXTENSIONS_RTF = '*.rtf';
    uploadMsWordMasters  = 'Master Template(s)';
    uploadMsWordIncludes  = 'Include Template(s)';
    uploadWord_DPS_LTR  = 'MS-Word Template (DPS_LTR.dotx)';
    MS_WORD_Template_FileName = 'DPS_LTR.DOTX';
    uploadRtfMasters  = 'RTF Template(s)';
    uploadRtfIncludes  = 'RTF Include Template(s)'; // ToDo

    uploadUrl: string;

    actionDropDownItems: any[] = [];
    docTemplateFilesSubscription: Subscription;
    allTemplatesSelected: boolean;
    selectedTemplateIds: number[];

    @ViewChild('mf') dataTableMf: any;
    @ViewChild('fileUploadMasters') fileUploadMasters: ElementRef;
    @ViewChild('fileUploadIncludes') fileUploadIncludes: ElementRef;
    @ViewChild('fileUploadWord_DPS_LTR') fileUploadWord_DPS_LTR: ElementRef;
    @ViewChild('fileUploadRtfMasters') fileUploadRtfMasters: ElementRef;
    @ViewChild('fileUploadRtfIncludes') fileUploadRtfIncludes: ElementRef;

    context: ManageFolderTemplatesModalContext;
    rows: DocumentTemplateFileWrapper[] = [];
    accountFileFolder: DocumentProfileTemplateFolder;
    uploadMenuExpanded: boolean;
    scheduler: any;
    matterTypeInfos: MatterTypeInfo[] = [];

    constructor(public dialog: DialogRef<ManageFolderTemplatesModalContext> ,
                private documentProfileService: DocumentProfileService,
                private dialogService: DialogService,
                private authorizationService : AuthorizationService,
                public accountService: AccountService,
                public appConfig: AppConfig
    ) {
        this.context = dialog.context;

    }

    close() {
        this.dialog.close();
    }



    ngOnInit() {
        this.selectedTemplateIds = [];
        if (this.context.accountFileFolder) {
            this.accountFileFolder = this.context.accountFileFolder;
            this.initializeManageFolderTemplateActions();
        } else {
            // Get the account file folder
            this.documentProfileService.getById(this.context.documentProfileId, this.accountId, false, this.context.matter).subscribe(data => {
                this.accountFileFolder = data.accountFileFolder;
                this.initializeManageFolderTemplateActions();

            });
        }
    }

    async initializeManageFolderTemplateActions(): Promise<void> {
        this.uploadUrl = documentProfilesApi.docTemplateFiles.replace('{id}', '' + this.accountFileFolder.id);
        if (this.accountFileFolder.isRtfProcessorType) {
            this.actionDropDownItems = [
                {text: this.uploadRtfMasters},
                {text: this.uploadRtfIncludes}
                ];
        } else {
            this.actionDropDownItems = [{text: this.uploadMsWordMasters}, {text: this.uploadMsWordIncludes}];
            if (this.accountFileFolder && this.accountFileFolder.isWordProcessorType) {
                this.actionDropDownItems.push({text: this.uploadWord_DPS_LTR});
            }
        }
        await this.loadMatterTypeDetails();
        this.retrieveDocumentFiles();
    }

    async loadMatterTypeDetails() {
        this.matterTypeInfos = await this.accountService.getMatterTypesDetails(this.context.account).toPromise();
    }


    selectFiles(event): void {
        // clear the file path value so it can detect a change event
        this.fileUploadMasters.nativeElement.value = '';
        this.fileUploadIncludes.nativeElement.value = '';
        this.fileUploadWord_DPS_LTR.nativeElement.value = '';

        switch (event.text) {
            case this.uploadMsWordMasters:  this.fileUploadMasters.nativeElement.click();      break;
            case this.uploadMsWordIncludes: this.fileUploadIncludes.nativeElement.click();     break;
            case this.uploadWord_DPS_LTR:   this.fileUploadWord_DPS_LTR.nativeElement.click(); break;
            case this.uploadRtfMasters:     this.fileUploadRtfMasters.nativeElement.click();   break;
            case this.uploadRtfIncludes:    this.fileUploadRtfIncludes.nativeElement.click();  break;
        }
    }

    startPolling(): void {
        if (this.scheduler && this.scheduler.state == 'running') {
            this.stopPolling();
        }
        this.scheduler = window.setInterval(() => {
                this.checkVirusScanStatus();
            }, 2000);
    }

    stopPolling(): void {
        window.clearInterval(this.scheduler);
    }

    ngOnDestroy(): void {
        this.stopPolling();
    }


    checkVirusScanStatus() {
             this.documentProfileService.getDocTemplateFiles(this.accountFileFolder.id)
            .subscribe((data: DocumentTemplateFile[]) => {
                const file: DocumentTemplateFile = data.find(docTempFile => docTempFile.isVirusScanPending);
                if (!file) {
                    this.stopPolling();
                }
                data = this.sortData(data);
                this.rows = data.map((file: DocumentTemplateFile) => {
                    const docTempFileWrapper: DocumentTemplateFileWrapper = new DocumentTemplateFileWrapper();
                    docTempFileWrapper.documentTemplateFile = file;
                    docTempFileWrapper.burgerMenuItems = this.getBurgerMenuItems(docTempFileWrapper.documentTemplateFile.include, docTempFileWrapper.documentTemplateFile);
                    return docTempFileWrapper;
                });
            });
    }

    getTemplateFileName(file: DocumentTemplateFile): string {
        if (file && file.fileName) {

            if (['.wpd', '.docx', '.dotx', '.rtf'].some(extension => file.fileName.endsWith(extension))) {
                return file.fileName;
            }

            return file.fileName + this.getFileExtensionByProcessorType();
        }
        return '';
    }

    retrieveDocumentFiles(): void {
        this.docTemplateFilesSubscription = this.documentProfileService.getDocTemplateFiles(this.accountFileFolder.id)
            .subscribe((data: DocumentTemplateFile[]) => {
                const file: DocumentTemplateFile = data.find(docTempFile => docTempFile.isVirusScanPending);
                if (file) {
                    this.startPolling();
                }
                data = this.sortData(data);
                this.rows = data.map((file: DocumentTemplateFile) => {
                    const docTempFileWrapper: DocumentTemplateFileWrapper = new DocumentTemplateFileWrapper();
                    docTempFileWrapper.documentTemplateFile = file;
                    docTempFileWrapper.burgerMenuItems = this.getBurgerMenuItems(docTempFileWrapper.documentTemplateFile.include, docTempFileWrapper.documentTemplateFile);
                    return docTempFileWrapper;
                });

            });
    }

    sortData(data: DocumentTemplateFile[]): DocumentTemplateFile[] {
        return _.sortBy(data, (item: DocumentTemplateFile) => {
            return item.fileName  ? item.fileName.toLowerCase() + (item.include ? '_incl' : '') : ''; // Includes come after master files with the same name, add a
            // suffix for sorting
        });
    }

    get accountId(): string {
        return this.context.account ? this.context.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }

    clickBurgerMenu(docTemplateFile: DocumentTemplateFile, clickedMenuOption: BurgerMenuExtendedItem): void {
        if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
            clickedMenuOption.action(docTemplateFile);
        }

    }

    getDisabledItems(row: any) {
        const disabledMenuItems = [];
        if (row.documentTemplateFile.isInfected) {
            disabledMenuItems.push('Download');
        }

        return disabledMenuItems;
    }

    getBurgerMenuItems(isInclude: boolean, docTemplateFile: DocumentTemplateFile): BurgerMenuExtendedItem[] {
        const burgerMenuItems: BurgerMenuExtendedItem[] = [];

        if (!isInclude  && !docTemplateFile.isVirusScanPending && (docTemplateFile.fileName && !docTemplateFile.fileName.toUpperCase().endsWith('.DOTX'))) {
            this.addToBurgerMenu(burgerMenuItems, 'Edit Details', this.editTemplateDetails);
        }
        if (!docTemplateFile.isInfected && !docTemplateFile.isVirusScanPending) {
            this.addToBurgerMenu(burgerMenuItems, 'Download', this.downloadTemplate);
         }

         this.addToBurgerMenu(burgerMenuItems, 'Delete', this.deleteTemplate);

        return burgerMenuItems;
    }

    editTemplateDetails = (docTemplateFile: DocumentTemplateFile) => {
        console.log(docTemplateFile);
        this.dialogService.content({
                                       content       : EditDocumentTemplateDetailsComponent,
                                       context       : {

                                           documentTemplateFile     : docTemplateFile,
                                           documentProfileId        : this.context.documentProfileId,
                                           accountFileFolderId      : this.accountFileFolder.id,
                                           account                  : this.context.account
                                       },
                                       onFulfillment : (result) => {
                                       },
                                       fullScreen    : false,
                                       modalGrid     : 5
                                   });
    }

    downloadTemplate = (docTemplateFile: DocumentTemplateFile) => {
        console.log('accountFileFolder id:' + this.accountFileFolder.id);
        console.log('docTemplateFile:' + docTemplateFile);
        this.documentProfileService.downloadDocTemplateFile(this.accountFileFolder.id, docTemplateFile.id);
    }

    deleteTemplate = (docTemplateFile: DocumentTemplateFile) => {
        console.log(docTemplateFile);
        let message = '<p>Are you sure that you would like to delete the template?</p><br>';
        this.documentProfileService.getDocTemplateFile(this.accountFileFolder.id, docTemplateFile.id)
            .subscribe(data => {
                    message += '<ul class="confirmation padding-left-40">';
                    if (data.documentTemplate && data.documentTemplate.numberOfCategories) {
                        message += '<li>The template is associated with ' + data.documentTemplate.numberOfCategories + ' document category(s). By deleting' +
                            ' the template, its reference in these categories will also be removed.</li>';
                    }
                    if (data.documentTemplate && data.documentTemplate.numberOfSupplementalTasks) {
                        message += '<li>The template is associated with ' + data.documentTemplate.numberOfSupplementalTasks + ' extended workflow(s). By deleting' +
                            ' the template, its reference in these tasks will also be removed.</li>';
                    }
                    message += '</ul>';
                    // TO-DO will use the data once the API is complete to update the message
                    this.dialogService.confirm('Confirmation', message, false, 'Delete' , null , true)
                        .subscribe(res => {
                            if (res) {
                                this.documentProfileService.deleteDocTemplateFile(this.accountFileFolder.id, docTemplateFile)
                                    .subscribe(
                                        (response: any) => {
                                            (<any>this.rows).remove(this.rows.find((item) => {
                                                return item.documentTemplateFile == docTemplateFile;
                                            }));
                                        }
                                    ); }
                        });

                });

    }

    addToBurgerMenu(burgerMenuItems: BurgerMenuExtendedItem[], text: string, action?: any): BurgerMenuExtendedItem {
        let burgerMenuItem: BurgerMenuExtendedItem;
        burgerMenuItem = new BurgerMenuExtendedItem();
        burgerMenuItem.text = text;
        burgerMenuItem.action = action;
        burgerMenuItems.push(burgerMenuItem);
        return burgerMenuItem;
    }

    tableKeyCommands(index, event): void {

        const charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));

        if (charCode === CustomKeyCodesEnum.Down) {
            event.preventDefault();
            this.keyCommandtoSelectNext(index);
        }
        if (charCode === CustomKeyCodesEnum.Up) {
            event.preventDefault();
            this.keyCommandtoSelectPrev(index);

        }

    }

    keyCommandtoSelectNext(index) {
        if (index < this.rows.length) {
            jQuery(document.activeElement).next('tr').focus();
        }
    }


    keyCommandtoSelectPrev(index) {
        if (index > 0) {
            jQuery(document.activeElement).prev('tr').focus();
        }
    }

    getFileType(documentTemplateFile: DocumentTemplateFile): string {
        if (documentTemplateFile.include) {
            return 'Include';
        } else if (documentTemplateFile.fileName && documentTemplateFile.fileName.toUpperCase().endsWith('.DOTX')) {
            return 'MS-Word Template';
        } else {
            return 'Master';
        }
    }

    convertMatterTypeCodesToString(typeCodes: string[], shortVersion: boolean): string {
        return MatterTypeUtil.convertMatterTypeCodesOrDescriptionToString(typeCodes, this.matterTypeInfos, shortVersion);
    }


    isMasterFile(documentTemplateFile: DocumentTemplateFile): boolean {
        return !documentTemplateFile.include && !!documentTemplateFile.documentTemplate;
    }

    fileUploadErrorDialog(numberOfFiles: number) {
        let msg = '';
        if (numberOfFiles > 1) {
            msg = 'Multiple files cannot be uploaded, file format must be MS-Word Template with name "DPS_LTR.dotx"';
        } else {
            msg = 'File selected cannot be uploaded, file format must be MS-Word Template with name "DPS_LTR.dotx"';
        }
        this.dialogService.confirm('File Upload Error', msg, true).subscribe(res => {

        });
    }


    initiateUpload(includeFiles: boolean, uploadType: UploadableTypes) {
        let uploadFilesControl: any;
        switch (uploadType) {
            case 'MASTER'     : uploadFilesControl = this.fileUploadMasters.nativeElement;      break;
            case 'INCLUDE'    : uploadFilesControl = this.fileUploadIncludes.nativeElement;     break;
            case 'DOTX'       : uploadFilesControl = this.fileUploadWord_DPS_LTR.nativeElement; break;
            case 'RTF'        : uploadFilesControl = this.fileUploadRtfMasters.nativeElement;   break;
            case 'RTF_INCLUDE': uploadFilesControl = this.fileUploadRtfIncludes.nativeElement;  break;
        }

        if (!(uploadFilesControl.value && uploadFilesControl.files && uploadFilesControl.files.length > 0)) {
            return;
        } else if (uploadType == 'DOTX' && uploadFilesControl.files.length > 1) {
            // if more than 1 .dotx file selected then show dialog error
            this.fileUploadErrorDialog(uploadFilesControl.files.length);
            return;
        } else if (uploadType == 'DOTX' && uploadFilesControl.files && uploadFilesControl.files.length == 1 && uploadFilesControl.files[0] && uploadFilesControl.files[0].name.toUpperCase() != this.MS_WORD_Template_FileName) {
            // file name doesn't match the only acceptable MS WORD TEMPLATE DPS_LTR.DOTX
            this.fileUploadErrorDialog(uploadFilesControl.files.length);
            return;
        }


        // Loading the documents one more time before passing them to the upload control, as we need the latest locking status from the server
        console.log('Upload type (include=true): ' + includeFiles);

        this.documentProfileService.getDocTemplateFiles(this.accountFileFolder.id)
            .subscribe((documentTemplateFiles: DocumentTemplateFile[]) => {



                const templatesWithSameTypeAsUploads = documentTemplateFiles.filter((docFile: DocumentTemplateFile) => {
                    // Only take into account the files that line up with the upload type (include files and master template files are treated as different files
                    return docFile.include === includeFiles;
                });
                const existingFiles: ExistingFile[] = templatesWithSameTypeAsUploads.map((docFile: DocumentTemplateFile) => {
                    return {
                        name: (uploadType === 'DOTX' || !this.accountFileFolder.isWordProcessorType) ? docFile.fileName : docFile.fileName + '.docx',
                        lastUpdatedTimeStamp: docFile.lastUpdatedTimeStamp,
                        isOpen: false,
                        isProtected: false
                    } as ExistingFile;
                });

                console.log(existingFiles);
                if (uploadFilesControl.files.length) {
                    this.dialogService.content({
                        content: FileUploadModal,
                        context: {
                            filesSelectedForUpload: uploadFilesControl.files,
                            uploadUrl: this.uploadUrl,
                            existingFiles: existingFiles,
                            acceptedFileExtensions: this.getAcceptedFileExtensionsByUploadType(uploadType, true),
                            maxFileSize: this.maxFileSizeInMb,
                            uploadOptions: {includeFilesUpload: includeFiles}
                        } as FileUploadModalContext,
                        onFulfillment: (result) => {
                            uploadFilesControl.value = '';
                            this.retrieveDocumentFiles();
                        },
                        onRejection: (result) => {
                            uploadFilesControl.value = '';
                            this.retrieveDocumentFiles();
                        }
                    });
                }

            });

    }

    getAcceptedFileExtensionsByUploadType(uploadType: UploadableTypes, includeStar: boolean = false): string {
        let fileExtension: string = this.ACCEPTED_FILE_EXTENSIONS_DOCX;
        switch (uploadType) {
            case 'MASTER'     :
            case 'INCLUDE'    :
            case 'RTF'        :
            case 'RTF_INCLUDE': fileExtension = this.getFileExtensionByProcessorType(includeStar); break;
            case 'DOTX'       : fileExtension = this.ACCEPTED_FILE_EXTENSIONS_WORD_DPS_LRT; break;
        }
        return includeStar ? fileExtension : fileExtension.replace(/\*/g, '');
    }

    getFileExtensionByProcessorType(includeStar: boolean = false): string {
        let fileExtension: string = this.ACCEPTED_FILE_EXTENSIONS_DOCX;
        if (this.accountFileFolder) {
            if (this.accountFileFolder.isWordProcessorType) {
                fileExtension = this.ACCEPTED_FILE_EXTENSIONS_DOCX;
            }
            if (this.accountFileFolder.isWpdProcessorType) {
                fileExtension = this.ACCEPTED_FILE_EXTENSIONS_WPD;
            }
            if (this.accountFileFolder.isRtfProcessorType) {
                fileExtension = this.ACCEPTED_FILE_EXTENSIONS_RTF;
            }
        }
        return includeStar ? fileExtension : fileExtension.replace(/\*/g, '');
    }

    get maxFileSizeInMb(): number {
        return this.appConfig.maxUploadedFileSizeInMb;
    }

    existingDocumentTemplates(): DocumentTemplateFile[] {
        const existingDocumentTemplates: DocumentTemplateFile[] = [];
        this.rows.map(documentTemplateWrapper => existingDocumentTemplates.push(documentTemplateWrapper.documentTemplateFile));
        return existingDocumentTemplates;
    }

    importDoProcessTemplates(): void {
        this.dialogService.content({
            content       : ImportDoProcessTemplateModalComponent,
            context       : {
                accountFileFolder    : this.accountFileFolder,
                existingDocumentTemplates : this.existingDocumentTemplates(),
                account : this.context.account
            },
            onFulfillment : (result) => {
                this.retrieveDocumentFiles();
            },
            fullScreen    : false
        });
    }

    get linkToDocGenDocumentation(): string {
        if (this.accountFileFolder && this.accountFileFolder.provinceCode === PROVINCE_CODES.BRITISH_COLOMBIA) {
            return 'https://www.doprocess.com/codes/BC-codes.pdf'; // not available yet
        }
        return 'https://www.doprocess.com/codes/ON-codes.pdf';
    }

    isCustomeMatterType(typeCode: string) : boolean {
        return MatterTypeUtil.isCustomeMatterType(typeCode);
    }

    ngAfterViewInit() {}

    toggleSelectMultipleTemplates(isCheckBoxSelected : boolean) : void {
        if(isCheckBoxSelected){
            this.selectedTemplateIds = [];
            let cleanTemplates = this.rows.filter(row=>!row.documentTemplateFile.isInfected && !row.documentTemplateFile.isVirusScanPending);
            cleanTemplates.forEach((row)=>{
                this.selectedTemplateIds.push(row.documentTemplateFile.id);
            });
            this.allTemplatesSelected = true;
        } else {
            this.selectedTemplateIds = [];
            this.allTemplatesSelected = false;
        }
    }

    isSelected(templateId : number) : boolean {
        return this.selectedTemplateIds && this.selectedTemplateIds.length && !!this.selectedTemplateIds.find(id => id == templateId);
    }

    clickRow(row: DocumentTemplateFileWrapper) : void {
        if(!this.selectedTemplateIds){
            this.selectedTemplateIds = [];
        }
        if(!this.isSelected(row.documentTemplateFile.id)) {
            this.selectedTemplateIds.push(row.documentTemplateFile.id)
        } else {
            this.allTemplatesSelected = false;
            (<any>this.selectedTemplateIds).remove(row.documentTemplateFile.id)
        }
    }

    downloadSelectedTemplates() : void {
        if(this.selectedTemplateIds && this.selectedTemplateIds.length){
            this.documentProfileService.downloadMultipleDocTemplateFiles(this.accountFileFolder.id, this.selectedTemplateIds);
        }
    }

    isMultiClietTemplate(row: any): boolean{
        if (row && row.documentTemplateFile && row.documentTemplateFile.documentTemplate) {
            return row.documentTemplateFile.documentTemplate.templateProductionClass === TemplateProductionClass.MULTI_CLIENT;
        }
        return false;
    }

    isSystemUser() : boolean{
        return this.authorizationService.hasRole(AUTH_ROLE.ROLE_SYSTEM_ADMINISTRATOR);
    }
}
