import {Injectable} from '@angular/core';
import {ProvinceCode} from '../accounts/shared/account-province';
import {Observable} from 'rxjs/Observable';
import {UndertakingsConfig} from '../mortgage-discharge/undertakings-config';
import {api} from '../../common/api';
import {HttpClient} from '../../core/httpClient.service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';

@Injectable({
    providedIn: 'root',
})
export class UndertakingsConfigService {

    readonly urlUndertakingsConfig         = `${api}/accounts/{accountId}/undertakings/configurations`;

    //cache will get initialized when user logs in
    cachedConfigs: Map<ProvinceCode, UndertakingsConfig> = new Map<ProvinceCode, UndertakingsConfig>();

    constructor(private http: HttpClient) { }

    getAllConfigs() : Observable<UndertakingsConfig[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = this.urlUndertakingsConfig.replace('{accountId}', accountId);

        return this.http.get(url).map(response => {
            if(response) {
                let undertakingsConfigs: UndertakingsConfig[] = [];
                let data = response['UndertakingsConfigurations'];
                data.forEach(item => {
                    let undertakingsConfig = new UndertakingsConfig(item);
                    undertakingsConfigs.push(new UndertakingsConfig(item));
                    this.cachedConfigs.set(undertakingsConfig.provinceCode, undertakingsConfig);
                });
                return undertakingsConfigs;
            }
        });
    }

    getUndertakingsConfig(accountId : string, provinceCode?: ProvinceCode) : Observable<UndertakingsConfig> {
        let url = this.urlUndertakingsConfig.replace('{accountId}', accountId);

       if (provinceCode) {
           url = url + `?provinceCode=${provinceCode}`
        }

        return this.http.get(url).map(response => {
            if(response) {
                let undertakingsConfig = new UndertakingsConfig(response['UndertakingsConfiguration']);
                this.cachedConfigs.set(provinceCode, new UndertakingsConfig(undertakingsConfig));
                return undertakingsConfig;
            }
        });
    }

    updateUndertakingsConfig(accountId : string, undertakingsConfig : UndertakingsConfig, provinceCode: ProvinceCode) : Observable<UndertakingsConfig> {
        if(accountId && undertakingsConfig && undertakingsConfig.id){
            const url = this.urlUndertakingsConfig.replace('{accountId}', accountId) + `/${undertakingsConfig.id}`;

            return this.http.put(url, undertakingsConfig)
                .map((response) => {
                    if (response) {
                        let updatedConfig = new UndertakingsConfig(response['UndertakingsConfiguration']);
                        this.cachedConfigs.set(provinceCode, new UndertakingsConfig(updatedConfig));
                        return updatedConfig;
                    }
                });
        }
    }

    getCachedConfig(provinceCode: ProvinceCode): UndertakingsConfig {
        return this.cachedConfigs.get(provinceCode);
    }

}
