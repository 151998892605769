import {Directive} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
    selector: '[ngModel][uppercased]',
    host: {
        '(input)': 'TextChange($event)'
    }
})
export class UppercasedInput {


    constructor(private model: NgModel) {
    }

    TextChange(input) {
        if (this.model.value) {
            this.model.reset(this.model.value.toUpperCase());
            this.model.valueAccessor.writeValue(this.model.value.toUpperCase());
        }
    }
}
