import {Component} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {AccountService} from '../accounts/account.service';
import {Account} from '../accounts/shared/account';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {FormBuilder} from '@angular/forms';
import {DocketService} from './docket.service';
import {TeraviewConfig} from './teraview-config';
import {CustomTeraviewDocumentNameFields} from '../../shared-main/constants';
import {Project} from '../../projects/shared/project';


class TeravieConfigModalContext extends BSModalContext {
    account : Account;
    project: Project;
}

//better put it into constants.ts of src/app/shared-main.
// export const CustomTeraviewDocumentNameFields = [
//     { path : 'matterRecordNumber' , label : 'Matter No'},
//     {  path : 'documentType' ,label: 'Document Type'  , key : 'DOC_TYPE'},
//     {  path : 'teraviewDocketIdentifier' ,label: 'Docket ID', key: 'TERANET_DOCKET'},
//     {  path : 'clientReLine' ,label: "Client RE: Line"},
//     {  path : 'otherPartyContactInfo.reline' ,label: "Other Party RE: Line", key : 'MORTGAGE'},
//     {  path : 'matterProperties' ,label: 'Unit and Level' , key : 'UNIT_LEVEL'},
//     {  path : 'matterPropertyWithCondo.address.addressLine1' ,label: 'Address Line 1'},
//     {  path : 'matterPropertyWithCondo.address.addressLine2' ,label: 'Address Line 2'},
//     {  path : 'fileNumber' ,label: 'File No'},
//     {  path : 'lawClerkInitials' ,label: 'Law Clerk Initials'},
//     {  path : 'solicitorInitials' ,label: 'Solicitors Initials'},
//     {  path : 'matterCloseDate' ,label: 'Closing Date' , key : 'CLOSING_DATE'},
//     ];



@Component({
               selector    : 'dp-teraview-config-modal',
               templateUrl : 'teraview-config.modal.component.html'
           })
export class TeraviewConfigModal implements   ModalComponent<TeravieConfigModalContext> {

    context : TeravieConfigModalContext;
    teraviewConfig : TeraviewConfig;
    availableGroupList : string[] = CustomTeraviewDocumentNameFields.map(item => {
        return item.label;
    });
    selectedGroupList : string[] = [];
    selectedOptionValue : string;
    selectedUserIDArr : string[] = [];
    account : Account;

    constructor(public dialog : DialogRef<TeravieConfigModalContext>, public accountService : AccountService, public docketService : DocketService, public fb : FormBuilder) {
        this.context = dialog.context;

    }

    ngOnInit() {
        if(this.isPartOfProject){
            this.teraviewConfig = this.project.teraviewConfiguration;
            this.updateSelectedList();
        }else{
            if(this.context.account) {
                this.account = new Account(this.context.account);
                this.getTeraviewConfiguration();
            } else {
                let loggedUserId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
                this.accountService.getAccount(loggedUserId).subscribe((account : Account) => {
                    this.account = new Account(account);
                    this.getTeraviewConfiguration();
                });
            }

        }



    }

    getTeraviewConfiguration() : void {

        if(this.account)
        {
            this.docketService.getTeraviewConfig(this.account.id.toString()).subscribe(data => {
                    if(data) {
                        this.teraviewConfig = new TeraviewConfig(data);
                        this.teraviewConfig.customDocumentNameFields = data.customDocumentNameFields;
                    }
                    else {
                        this.teraviewConfig = new TeraviewConfig();
                    }
                    this.updateSelectedList();
                }
            );
        }
    }

    get project(): Project{
        return this.context.project;
    }

    get isPartOfProject(): boolean{
        return this.project != null;
    }


    updateSelectedList() : void {
        if(this.teraviewConfig && this.teraviewConfig.documentNameType == 'CUSTOM' && this.teraviewConfig.customDocumentNameFields) {
            this.selectedGroupList = this.teraviewConfig.customDocumentNameFields;
            this.availableGroupList = this.availableGroupList.filter(item => this.selectedGroupList.indexOf(item) < 0);
        }
    }


    ok() : void {
        if(this.teraviewConfig && this.teraviewConfig.documentNameType == 'CUSTOM') {
            this.teraviewConfig.customDocumentNameFields = this.selectedGroupList;
        }
        else {
            this.teraviewConfig.customDocumentNameFields = [];
        }
        if(this.isPartOfProject){
            this.project.teraviewConfiguration = this.teraviewConfig;
            this.project.dirty = true;
            this.dialog.close();
        }else{
            let id = this.account ? this.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
            this.docketService
                .updateTeraviewConfig(this.teraviewConfig, id)
                .subscribe(
                    (res) => {
                        this.dialog.close();
                    },
                    () => {}
                );
        }

    }


    close() : void {
        this.dialog.close();
    }


    moveItemRight() : void {
        let item = this.selectedUserIDArr[0];
        this.selectedGroupList.push(item);
        (<any>this.availableGroupList).remove(item);
        if(this.availableGroupList.length == 0) {
            this.selectedUserIDArr[0] = null;
        }
        else {
            this.selectedUserIDArr[0] = this.availableGroupList[0];
        }

    }

    moveItemLeft() : void {
        let item = this.selectedUserIDArr[1];
        this.availableGroupList.push(item);
        this.availableGroupList = this.sortUserArr(this.availableGroupList);
        (<any>this.selectedGroupList).remove(item);
        if(this.selectedGroupList.length == 0) {
            this.selectedUserIDArr[1] = null;
        }
        else {
            this.selectedUserIDArr[1] = this.selectedGroupList[0];
        }

    }

    sortUserArr(inArray : string[]) : string[]{
        let outArr = [];
        CustomTeraviewDocumentNameFields.forEach(item=>{
            let el = inArray.find(element=>{
                return element == item.label;
            });
            if(el){
                outArr.push(el);
            }
        });
        return outArr;
    }

    get currentSelectedIndex() : number{
        return (this.selectedGroupList.findIndex(item => item  == this.selectedUserIDArr[1]));
    }

    moveUp() : void {
        if(this.selectedGroupList && this.selectedGroupList.length > 0)
        {
            let selectedIndex : number = this.currentSelectedIndex;
            if(selectedIndex > 0)
            {
                let element = this.selectedGroupList[selectedIndex];
                this.selectedGroupList.splice(selectedIndex, 1);
                this.selectedGroupList.splice(selectedIndex-1, 0, element);
            }

        }

    }

    moveDown() : void {
        if(this.selectedGroupList && this.selectedGroupList.length > 0)
        {
            let selectedIndex : number = this.currentSelectedIndex;
            if(selectedIndex < this.selectedGroupList.length)
            {
                let element = this.selectedGroupList[selectedIndex];
                this.selectedGroupList.splice(selectedIndex, 1);
                this.selectedGroupList.splice(selectedIndex+1, 0, element);
            }

        }

    }

    get okBtnLabel(): string{
        return this.isPartOfProject ? 'OK' : 'Save';
    }
}


