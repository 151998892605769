import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {CirfClientInfo} from './cirf-client-info';
import {CirfMortgage, MortgageTypeValue} from './cirf-mortgage';
import {CirfInsuranceDetail} from './cirf-insurance-detail';
import {CirfMatterContactInfo} from './cirf-matter-contact-info';
import {MatterType, MatterTypesValue} from '../index';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';

/**
 * This class is for capturing any matter level information in cirf request and other aggregated entities
 */
export class CirfMatterData extends BaseEntity {
    matterNotes: string;
    insuranceNotes: string;
    mortgageNotes: string;
    existingMortgageNotes: string;
    clientNotes: string;
    cirfClients : CirfClientInfo[] = [];
    mortgages: CirfMortgage[] = [];
    fireInsuranceDetail: CirfInsuranceDetail = new CirfInsuranceDetail();
    matterContactInfo: CirfMatterContactInfo = new CirfMatterContactInfo();
    matterType: MatterType;
    customMatterTypeCode: string;
    customMatterTypeDesc: string;
    firmName: string;
    propertyAddressText: string;
    legalDescriptionSummary: string;
    closingDate: string;
    provinceCode: ProvinceCode;

    constructor(cirfMatterData?: CirfMatterData) {
        super(cirfMatterData);
        if(cirfMatterData) {
            this.cirfClients = [];
            if(Array.isArray(cirfMatterData.cirfClients)) {
                for(let cc of cirfMatterData.cirfClients) {
                    this.cirfClients.push(new CirfClientInfo(cc));
                }
            }
            this.mortgages = [];
            if(Array.isArray(cirfMatterData.mortgages)) {
                for(let cc of cirfMatterData.mortgages) {
                    this.mortgages.push(new CirfMortgage(cc));
                }
            }
            if(cirfMatterData.fireInsuranceDetail) {
                this.fireInsuranceDetail = new CirfInsuranceDetail(cirfMatterData.fireInsuranceDetail);
            }
            if(cirfMatterData.matterContactInfo) {
                this.matterContactInfo = new CirfMatterContactInfo(cirfMatterData.matterContactInfo);
            }
        }
        if(!this.provinceCode){
            this.provinceCode = 'ON';
        }
    }


    get newMortgages() : CirfMortgage[]{
        return this.mortgages.filter(mtg=>mtg.mortgageType == MortgageTypeValue.New);
    }

    get existingMortgages() : CirfMortgage[]{
        return this.mortgages.filter(mtg=>mtg.mortgageType == MortgageTypeValue.Existing);
    }

    isCustomMatter() : boolean {
        return !!this.customMatterTypeCode;
    }

    isPurchase() : boolean {
        return this.matterType == MatterTypesValue.PURCHASE;
    }
}
