import {Contact, SpousalStatusTypes} from '../matters/shared/contact';
import {BaseEntity} from '../shared/BaseEntity/base-entity';
import {contactDropDowns} from './contact-drop-downs';
import * as _ from 'lodash';

export const SpouseStatusOptions = [
    {label : 'Current Spouse', value : 'CURRENT_SPOUSE'},
    {label : 'Divorced', value : 'DIVORCED'},
    {label : 'Separated', value : 'SEPARATED'},
    {label : 'Separated by Death', value : 'SEPARATED_BY_DEATH'}
]

export class ContactRelationship extends BaseEntity {
    id: number;
    baseContactId: number
    spouse : Contact;
    spousalStatus : string;

    relationshipsBurgerMenuItems;

    constructor(contactRelationship? : ContactRelationship) {
        super(contactRelationship);
        if(contactRelationship && contactRelationship.spouse) {
            this.spouse = new Contact(contactRelationship.spouse);
        }
    }

    get associatedContactName(): string {
        if(this.spouse.isPoaEstate0rOtherEntity || this.spouse.isCorporationOrOtherEntity){
            return this.spouse.displayName
        }
        else{
            return  this.spouse.fullName
        }

    }

    get spouseContactName(): string {
        if(this.spouse.isPoaEstate0rOtherEntity || this.spouse.isCorporationOrOtherEntity){
            return this.spouse.displayName
        }
        else{
            return  this.spouse.surnameLastFullName
        }

    }

    get associatedContactCategory(): string {
        let label: string = "";
        if(this.spouse && this.spouse.contactCategory) {
            let theType = _.find(contactDropDowns.categories, categoryTypeObj => categoryTypeObj.value === this.spouse.contactCategory);
            if(theType) {
                label = theType.label;
            }
        }
        return label;
    }

    get associatedContactPhones(): string {
        return this.spouse && this.spouse.phonesText;
    }

    get associatedContactAddress(): string {
        if(this.spouse && this.spouse.primaryAddress) {
            return this.spouse.primaryAddress.addressTextWithProvinceName;
        }
        else {
            return "";
        }
    }

    get associatedContactStatus(): string {
        let entry = contactDropDowns.statusTypes.find((type) => (this.spouse && this.spouse.activeFlag) === type.value);
        return entry ? entry.label : '';
    }

    isSpouse() {
        return this.spousalStatus === SpousalStatusTypes.CURRENT_SPOUSE
            || this.spousalStatus === SpousalStatusTypes.DIVORCED
            || this.spousalStatus === SpousalStatusTypes.SEPARATED
            || this.spousalStatus === SpousalStatusTypes.SEPARATED_BY_DEATH;
    }
}
