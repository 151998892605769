import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

declare var jQuery : any;

@Component({
               selector : "dp-options-button",
               templateUrl : 'options-button.html',
               styleUrls   : [
                   './options.component.scss'
               ]

})
export class OptionsButtonComponent implements OnInit {

    @Input() buttonState: string = 'DEFAULT';
    @Input() titleText: string;
    @Input() disabled: boolean = false;
    @Output() itemClicked = new EventEmitter();



    ngOnInit(){


    }


    toggleButtonState(){

        switch(this.buttonState) {
            case 'DEFAULT':
                this.buttonState = 'OVERRIDE';
                break;
            case 'OMIT':
                this.buttonState = 'DEFAULT';
                break;
            case 'OVERRIDE':
                this.buttonState = 'OMIT';
                break;
            default:
                this.buttonState = 'DEFAULT';
        }

        this.itemClicked.emit(this.buttonState);
    }
}
