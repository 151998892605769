import {
    ContactSuggestion,
    DearMaxLength,
    SalutationOrContMaxLength
} from '../../contact/contact-suggestion/contact-suggestion';
import { ContactSuggestionSalutationEnum } from '../../contact/contact-suggestion/contact-suggestion-salutation-enum.enum';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {Utils as SharedUtils} from './utils';
import {Contact} from './contact';
import Utils from '../../shared-main/utils';
import {NotificationOptOutService} from '../../main/notification-opt-out.service';
import {GenderTypes} from '../../contact/contact-type-mapping';
import {MatterParticipant} from './matter-participant';
import {Matter} from './matter';
import {MatterParticipantWrapper} from './matter-participant-wrapper';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {MatterParticipantUtil} from './matter-utils/matter-participant-util';
import {EnvelopeSalutationFormatType} from '../../admin/document-profile/document-profile-edit/misc/EnvelopeSalutationFormats';
import {ContactName} from './contact-name';
import {ContactNameUtil} from './contact-name-util';
import {OwnerData} from '../property-teranet/teranet-connect-detail/import-data/teranet-owner-import.modal.component';
import {TelephoneTypes} from './telephone-types';
import {Address} from './address';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';

export class ContactUtil {
    static setDefaultEnvelopeSalutationLine1(contact: Contact, contactSalutationSuggestion: ContactSuggestion, defaultDocumentProfile: DocumentProfile): void {
        if (contact && contactSalutationSuggestion && defaultDocumentProfile) {
            let suggestions = contactSalutationSuggestion.getFilteredSuggestions(ContactSuggestionSalutationEnum.salutation);
            let suggestionsMap = contactSalutationSuggestion.getSuggestionsMap(ContactSuggestionSalutationEnum.salutation);
            if (suggestions.length) {
                contact.envelopeSalutationLine1 = suggestions[0];
                //For male and female if miscDocumentProfile has config, we need to overwrite it.
                contactSalutationSuggestion.setDefaultSalutationLine1DearFromConfig(contact, defaultDocumentProfile, suggestionsMap, false);

            } else {
                contact.envelopeSalutationLine1 = '';
            }
            contact.envelopeSalutationLine1
                = contact.envelopeSalutationLine1 ? SharedUtils.truncateStringForDisplay(contact.envelopeSalutationLine1, SalutationOrContMaxLength) : '';
        }

    }

    static setDefaultEnvelopeSalutationLine2(contact: Contact, contactSalutationSuggestion: ContactSuggestion, defaultDocumentProfile: DocumentProfile): void {
        if (contact && contactSalutationSuggestion && defaultDocumentProfile) {
            let suggestions = contactSalutationSuggestion.getFilteredSuggestions(ContactSuggestionSalutationEnum.salutationContinued);
            if (suggestions.length) {
                contact.envelopeSalutationLine2 = suggestions[0];
            } else {
                contact.envelopeSalutationLine2 = '';
            }
            contact.envelopeSalutationLine2
                = contact.envelopeSalutationLine2 ? SharedUtils.truncateStringForDisplay(contact.envelopeSalutationLine2, SalutationOrContMaxLength) : '';
        }
    }

    static setDefaultDear(contact: Contact, contactSalutationSuggestion: ContactSuggestion, defaultDocumentProfile: DocumentProfile): void {
        if (contact && contactSalutationSuggestion && defaultDocumentProfile) {
            let suggestions = contactSalutationSuggestion.getFilteredSuggestions(ContactSuggestionSalutationEnum.dear);
            let suggestionsMap = contactSalutationSuggestion.getSuggestionsMap(ContactSuggestionSalutationEnum.dear);
            if (suggestions && suggestions.length) {
                contact.dear = suggestions[0] ? suggestions[0].substring(0, 75) : ''; //"dear" field has limitation of 75 charachters
                //For male and female if miscDocumentProfile has config, we need to overwrite it.
                contactSalutationSuggestion.setDefaultSalutationLine1DearFromConfig(contact, defaultDocumentProfile, suggestionsMap, true);
            } else {
                contact.dear = '';
            }
            contact.dear = contact.dear ? SharedUtils.truncateStringForDisplay(contact.dear, DearMaxLength) : '';
        }
    }

    static setDefaultSalutations(contact: Contact, defaultDocumentProfile: DocumentProfile): void {
        if (defaultDocumentProfile && contact) {
            let contactSuggestion = new ContactSuggestion();
            contactSuggestion.initAllSuggestions(contact);
            ContactUtil.setDefaultEnvelopeSalutationLine1(contact, contactSuggestion, defaultDocumentProfile);
            ContactUtil.setDefaultEnvelopeSalutationLine2(contact, contactSuggestion, defaultDocumentProfile);
            ContactUtil.setDefaultDear(contact, contactSuggestion, defaultDocumentProfile);
        }
    }

    static updatesForContactSetDefaultSalutations(matterParticipant: MatterParticipant, defaultDocumentProfile: DocumentProfile){
        if(matterParticipant && matterParticipant.contact){
            let tmpContact  = new Contact(matterParticipant.contact);
            this.setDefaultSalutations(matterParticipant.contact, defaultDocumentProfile)
            if(tmpContact.dear != matterParticipant.contact.dear
                || tmpContact.envelopeSalutationLine1 != matterParticipant.contact.envelopeSalutationLine1
                || tmpContact.envelopeSalutationLine2 != matterParticipant.contact.envelopeSalutationLine2){
                matterParticipant.contact.isDirty = true;
                matterParticipant.sourceContactLockAcquired = true;

            }
        }
    }

    static needUpdateAllSuggestions(defaultDocumentProfile: DocumentProfile,
                                    originalMainClients: MatterParticipant[], targetMainClients: MatterParticipant[]): boolean {
        let ret: boolean = false;
        //    matter : Matter;
        //     originalMatter : Matter;
        // let originalMainClients = this.context.originalMatter.mainClients;
        // let targetMainClients = this.context.matter.mainClients;
        if (originalMainClients.length != targetMainClients.length) { //if imported new client, the length should be different
            ret = true;
            let newTargetClients = targetMainClients.filter(client => client.contact && !client.contact.id);// filter new client
            newTargetClients.forEach(client => this.updatesForContactSetDefaultSalutations(client, defaultDocumentProfile));
        } else {
            targetMainClients.forEach(tClient => {//there is no new client
                let oClient = originalMainClients.find(item => item.contact && tClient.contact && (item.contact.id == tClient.contact.id));
                if (oClient.contact && tClient.contact) {
                    if (oClient.contact.gender != tClient.contact.gender) { //If gender is different, we need update envelope salutation fields
                        ret = true;

                    } else {//If name is different, we need update envelope salutation fields
                        if (oClient.contact.gender == GenderTypes.CORPORATION || oClient.contact.gender == GenderTypes.OTHERENTITY) {
                            if (oClient.contact.organizationName != tClient.contact.organizationName) {
                                ret = true;
                            }
                        } else {
                            if (oClient.contact.fullName != tClient.contact.fullName) {
                                ret = true;
                            }
                        }
                    }
                } else {
                    ret = true;
                }
                if (ret) {
                    this.updatesForContactSetDefaultSalutations(tClient, defaultDocumentProfile)
                }
            });
        }

        return ret;
    }

    static extractFirstEmail(email: string): string {
        if (email && /[\s;,]+/.test(email)) {
            const emails = email.split(/[\s;,]+/).map(email => email.trim()).filter(Boolean);
            for (let idx = 0; idx < emails.length; idx++) {
                if (Utils.validateEmail(emails[idx])) {
                    return emails[idx];
                }
            }
        }
        return email;
    }

    static isOptedOutEmail(contact: Contact, notificationOptOutService: NotificationOptOutService): boolean {
        return contact && contact.firstEmail && Utils.validateEmail(contact.firstEmail) && notificationOptOutService.isOptedOutEmail(contact.firstEmail);
    }

    static truncateEnvelopeFields(matter: Matter) {
        matter.matterContactInfo.envelopeSalutation
            = matter.matterContactInfo.envelopeSalutation ? SharedUtils.truncateStringForDisplay(matter.matterContactInfo.envelopeSalutation, SalutationOrContMaxLength) : '';
        matter.matterContactInfo.envelopeSalutationContinued
            = matter.matterContactInfo.envelopeSalutationContinued ? SharedUtils.truncateStringForDisplay(matter.matterContactInfo.envelopeSalutationContinued, SalutationOrContMaxLength) : '';
        matter.matterContactInfo.dearText = matter.matterContactInfo.dearText ? SharedUtils.truncateStringForDisplay(matter.matterContactInfo.dearText, DearMaxLength) : '';
    }

    static setConcatDefaultSuggestions(matter: Matter, selectedClientPurchasers: Array<MatterParticipantWrapper>) {
        let defaultSuggestionSalutations = ContactSuggestion.getDefaultSuggestions(selectedClientPurchasers, ContactSuggestionSalutationEnum.salutation);
        let defaultSuggestionSalutationConts = ContactSuggestion.getDefaultSuggestions(selectedClientPurchasers, ContactSuggestionSalutationEnum.salutationContinued);
        let defaultSuggestionDears = ContactSuggestion.getDefaultSuggestions(selectedClientPurchasers, ContactSuggestionSalutationEnum.dear);

        matter.matterContactInfo.envelopeSalutation = Utils.concatenateNames(defaultSuggestionSalutations, null, 'and');
        matter.matterContactInfo.envelopeSalutationContinued = Utils.concatenateNames(defaultSuggestionSalutationConts, null, 'and');
        matter.matterContactInfo.dearText = Utils.concatenateNames(defaultSuggestionDears, null, 'and');

        this.truncateEnvelopeFields(matter);
    }

    static setConcatDefaultSuggestionsWrapperAction(matter: Matter, selectedClientPurchasers: Array<MatterParticipantWrapper>, suggestionsSubject: Subject<void>) {
        this.setConcatDefaultSuggestions(matter, selectedClientPurchasers);
        suggestionsSubject.next();
        suggestionsSubject.complete();
    }

    //After snapShot finishing update, then update matter level fields
    // Cirf import can't use time out  because new matter will be called before time out callback
    static setConcatDefaultSuggestionsWrapper(matter: Matter, selectedClientPurchasers: Array<MatterParticipantWrapper>, suggestionsSubject: Subject<void>, needTimeOut: boolean) {
        if (needTimeOut) {
            setTimeout(() => {
                this.setConcatDefaultSuggestionsWrapperAction(matter, selectedClientPurchasers, suggestionsSubject);
            }, 100);
        } else {
            this.setConcatDefaultSuggestionsWrapperAction(matter, selectedClientPurchasers, suggestionsSubject);
        }

    }

    static updateAllSuggestions(selectedClientPurchasers: Array<MatterParticipantWrapper>, matter: Matter, contactSalutationSuggestion: ContactSuggestion, documentProfile: DocumentProfile,
                                purchaserDelete: boolean = false, isPurchaserUpdated: boolean = false, retrieveConfig: boolean = false, needTimeOut: boolean = true): Observable<void> {
        let suggestionsSubject: Subject<void> = new Subject<void>();

        if (purchaserDelete) {
            matter.matterContactInfo.envelopeSalutation = '';
            matter.matterContactInfo.envelopeSalutationContinued = '';
            matter.matterContactInfo.dearText = '';
        }

        if (isPurchaserUpdated) {
            if (MatterParticipantUtil.includeCorpEntityOrAllExcludeMaleFemaleBlank(selectedClientPurchasers)) {
                matter.matterContactInfo.envelopeSalutation = '';
                matter.matterContactInfo.envelopeSalutationContinued = '';
                matter.matterContactInfo.dearText = '';
            }
        }

        contactSalutationSuggestion.initAllSuggestionsForMatterParticipants(selectedClientPurchasers, matter);


        if (selectedClientPurchasers && selectedClientPurchasers.length > 0) {

            let p1 = selectedClientPurchasers[0].matterParticipant && selectedClientPurchasers[0].matterParticipant.contact;

            /*http://confluence/display/DPPM01/DPPM-T022-E020-U030-270+-+Salutation+Fields+on+Purchaser
            * When single Purchaser is on the matter, the Salutation fields will be populated with values from contact record,
            * For a single purchaser only if all three salutation fields from purchaser's contact info are blank the default values are populated on matter fields
            * For multiple purchasers default values are always populated on matter fields
            * The above logic was removed by https://doprocess.atlassian.net/browse/DPPMP-45338
            * */
            const envelopeSalutationValues: any[] = contactSalutationSuggestion.getFilteredSuggestions(ContactSuggestionSalutationEnum.salutation);
            const envelopeSalutationContinuedValues: any[] = contactSalutationSuggestion.getFilteredSuggestions(ContactSuggestionSalutationEnum.salutationContinued);
            const dearTextValues: any[] = contactSalutationSuggestion.getFilteredSuggestions(ContactSuggestionSalutationEnum.dear);
            // Even if envelopeSalutation, envelopeSalutationContinued and dearText was manually input, they should be be overwrite
            if (selectedClientPurchasers.length === 1 && p1 && isPurchaserUpdated) {
                matter.matterContactInfo.envelopeSalutation = p1.envelopeSalutationLine1 ? p1.envelopeSalutationLine1 : '';
                matter.matterContactInfo.envelopeSalutationContinued = p1.envelopeSalutationLine2 ? p1.envelopeSalutationLine2 : '';
                matter.matterContactInfo.dearText = p1.dear ? p1.dear : '';
                this.truncateEnvelopeFields(matter);
                suggestionsSubject.next();
                suggestionsSubject.complete();
            }
                //BA confirmed with Teri, if 3 Envelope fields are all blank, we set matter fields are all blank. So comment the following code.
                // else if ((selectedClientPurchasers.length === 1
                //     && (!p1 || utils.isEmptyString(p1.envelopeSalutationLine1) && utils.isEmptyString(p1.envelopeSalutationLine2) && utils.isEmptyString(p1.dear)))
                //     || selectedClientPurchasers.length > 1
                // ) {
                //     if (envelopeSalutationValues.length > 0
                //         && (matter.matterContactInfo.envelopeSalutation == '' || !matter.matterContactInfo.envelopeSalutation
                //             || (envelopeSalutationValues.indexOf(matter.matterContactInfo.envelopeSalutation) == -1 && isPurchaserUpdated))) {
                //         matter.matterContactInfo.envelopeSalutation = envelopeSalutationValues[0];
                //     }
                //     if (envelopeSalutationContinuedValues.length > 0
                //         && (matter.matterContactInfo.envelopeSalutationContinued == '' || !matter.matterContactInfo.envelopeSalutationContinued
                //             || envelopeSalutationContinuedValues.indexOf(matter.matterContactInfo.envelopeSalutationContinued) == -1 && isPurchaserUpdated)) {
                //         matter.matterContactInfo.envelopeSalutationContinued = envelopeSalutationContinuedValues[0];
                //     }
                //     if (dearTextValues.length > 0
                //         && (!matter.matterContactInfo.dearText || matter.matterContactInfo.dearText == ''
                //             || (dearTextValues.indexOf(matter.matterContactInfo.dearText) == -1 && isPurchaserUpdated))) {
                //         matter.matterContactInfo.dearText = dearTextValues[0];
                //     }
            // }
            else if ((isPurchaserUpdated && selectedClientPurchasers.length === 2) &&
                MatterParticipantUtil.is2DiffMaleOrFemaleGenderClientsWithSameLastName(selectedClientPurchasers)) {
                const envelopeSalutationMap: Map<EnvelopeSalutationFormatType, string> = contactSalutationSuggestion.getSuggestionsMap(ContactSuggestionSalutationEnum.salutation);
                const dearMap: Map<EnvelopeSalutationFormatType, string> = contactSalutationSuggestion.getSuggestionsMap(ContactSuggestionSalutationEnum.dear);
                if (retrieveConfig) {
                    let envelopeSalutationValue = contactSalutationSuggestion.setMatterParticipantsSuggestionDefaultValueFromConfig(documentProfile, envelopeSalutationMap, false);
                    let envelopeDearValue = contactSalutationSuggestion.setMatterParticipantsSuggestionDefaultValueFromConfig(documentProfile, dearMap, true);
                    let defaultSuggestionSalutationConts = ContactSuggestion.getDefaultSuggestions(selectedClientPurchasers, ContactSuggestionSalutationEnum.salutationContinued);

                    matter.matterContactInfo.envelopeSalutation = envelopeSalutationValue ? envelopeSalutationValue : '';
                    matter.matterContactInfo.envelopeSalutationContinued = Utils.concatenateNames(defaultSuggestionSalutationConts, null, 'and');
                    matter.matterContactInfo.dearText = envelopeDearValue ? envelopeDearValue : '';
                    this.truncateEnvelopeFields(matter);
                    suggestionsSubject.next();
                    suggestionsSubject.complete();
                } else {
                    //After snapShot finishing update, then update matter level fields
                    // Cirf import can't use time out  because new matter will be called before time out callback
                    this.setConcatDefaultSuggestionsWrapper(matter, selectedClientPurchasers, suggestionsSubject, needTimeOut);
                }

            } else if ((isPurchaserUpdated && selectedClientPurchasers.length === 2) &&
                (MatterParticipantUtil.is2DiffMaleAndFemaleGenderClientsWithDiffLastName(selectedClientPurchasers)
                    || MatterParticipantUtil.is2SameMaleOrFemaleGenderClients(selectedClientPurchasers)
                    || MatterParticipantUtil.is2DiffBlankAndMaleOrFemaleGenderClientsWithDiffLastName(selectedClientPurchasers))) {
                //After snapShot finishing update, then update matter level fields
                // Cirf import can't use time out  because new matter will be called before time out callback
                this.setConcatDefaultSuggestionsWrapper(matter, selectedClientPurchasers, suggestionsSubject, needTimeOut);
            } else if (isPurchaserUpdated && selectedClientPurchasers.length > 2 && MatterParticipantUtil.isIndividualClients(selectedClientPurchasers)) {
                //After snapShot finishing update, then update matter level fields
                // Cirf import can't use time out  because new matter will be called before time out callback
                this.setConcatDefaultSuggestionsWrapper(matter, selectedClientPurchasers, suggestionsSubject, needTimeOut);
            } else if (selectedClientPurchasers.length > 1) {
                if (!MatterParticipantUtil.includeCorpEntityOrAllExcludeMaleFemaleBlank(selectedClientPurchasers)) {
                    if (envelopeSalutationValues.length > 0 && isPurchaserUpdated) {
                        matter.matterContactInfo.envelopeSalutation = envelopeSalutationValues[0];
                    }
                    if (isPurchaserUpdated) {
                        // When envelopeSalutationContinuedValues drop down is null, then we need concatenated values
                        if (envelopeSalutationContinuedValues.length > 0) {
                            matter.matterContactInfo.envelopeSalutationContinued = envelopeSalutationContinuedValues[0];
                        } else {
                            let defaultSuggestionSalutationConts = ContactSuggestion.getDefaultSuggestions(selectedClientPurchasers, ContactSuggestionSalutationEnum.salutationContinued);
                            matter.matterContactInfo.envelopeSalutationContinued = Utils.concatenateNames(defaultSuggestionSalutationConts, null, 'and');
                        }
                    }
                    if (dearTextValues.length > 0 && isPurchaserUpdated) {
                        matter.matterContactInfo.dearText = dearTextValues[0];
                    }
                    this.truncateEnvelopeFields(matter);
                }
                suggestionsSubject.next();
                suggestionsSubject.complete();
            }
        }
        return suggestionsSubject;
    }

    static createOwnerDataContact(contact: Contact, ownerData: OwnerData, matter: Matter, isWizard?: boolean): void {

        contact.contactName = new ContactName();
        if (ownerData.isContactCorporation) {
            contact.organizationName = Utils.toMixedCase(ownerData.parcelRegisterName);
        } else if (ownerData.isEstate) {
            contact.estateTrustee1 = ContactNameUtil.parseTrusteeName(ownerData.parcelRegisterName);
            let names: string[] = ContactNameUtil.parseLastFirstMiddleNames(ContactNameUtil.parseContactName(ownerData.parcelRegisterName));
            ContactNameUtil.convertStringArrayToContactName(names, contact.contactName);
            contact.estateStatusFlag = ownerData.estateStatus;
        } else if(isWizard && ownerData.isOtherentity){
            contact.organizationName = Utils.toMixedCase(ownerData.parcelRegisterName);
        }
        else {
            // After confirm with BA, populate names in mixed case when create a new client
            let names: string[] = [];
            names = matter.isMatterProvinceAB || matter.isMatterProvinceSK || matter.isMatterProvinceMB
                ? ContactNameUtil.parseLastFirstMiddleNames(Utils.toMixedCase(ownerData.parcelRegisterName))
                : ContactNameUtil.splitLastFirstAndMiddleName(Utils.toMixedCase(ownerData.parcelRegisterName));
            names = names.filter(name => !!name);
            if(isWizard && names.length == 0){
                names.push(Utils.toMixedCase(ownerData.parcelRegisterName));
            }

            ContactNameUtil.convertStringArrayToContactName(names, contact.contactName);
        }

        contact.gender = 'QUESTION';
        contact.contactCategory = 'CLIENT';
        contact.setDefaultValues('CLIENT', ownerData.isContactCorporation ? 'CORPORATION' : ownerData.isEstate ? 'ESTATE' :  ownerData.isOtherentity ? 'OTHERENTITY' :undefined);
        contact.initialLocalGender();
    }


    static createOwnerDataFullContact(contact: Contact, documentProfileFirmAddress?: Address, documentProfileCache?: DocumentProfileCache): void {
        contact.cleanupRedundantData();
        // Keep the same initialize rule with matter and contact  to avoid out of syc issue
        contact.initializeContactIdDetails();
        contact.setIdDetailsDefaultLabel();
        contact.getPhone(TelephoneTypes.home);
        contact.getPhone(TelephoneTypes.work);
        contact.getPhone(TelephoneTypes.cell);
        contact.getPhone(TelephoneTypes.fax);
        contact.setClientAddressDefaultValue(documentProfileFirmAddress);
        contact.setClientIdBusinessAddressDefaultValue(documentProfileCache);
        contact.nameOnDocuments = contact.reLineName;
        contact.initializeContactIdDetails();
        if(documentProfileCache&& documentProfileCache.cachedDocumentProfile) {
            this.setDefaultSalutations(contact, documentProfileCache && documentProfileCache.cachedDocumentProfile);
        } else {

            let contactSuggestionSal = new ContactSuggestion();
            contactSuggestionSal.initSuggestions(contact, ContactSuggestionSalutationEnum.salutation);
            let suggestionsSal = contactSuggestionSal.getFilteredSuggestions(ContactSuggestionSalutationEnum.salutation);
            if (suggestionsSal.length && suggestionsSal.length > 0) {
                contact.envelopeSalutationLine1 = suggestionsSal[0];
            } else {
                contact.envelopeSalutationLine1 = '';
            }
            let contactSuggestionSalcontd = new ContactSuggestion();
            contactSuggestionSalcontd.initSuggestions(contact, ContactSuggestionSalutationEnum.salutationContinued);
            let suggestionsSalcontd = contactSuggestionSalcontd.getFilteredSuggestions(ContactSuggestionSalutationEnum.salutationContinued);
            if (suggestionsSalcontd.length && suggestionsSalcontd.length > 0) {
                contact.envelopeSalutationLine2 = suggestionsSalcontd[0];
            } else {
                contact.envelopeSalutationLine2 = '';
            }
            let contactSuggestionSalconDear = new ContactSuggestion();
            contactSuggestionSalconDear.initSuggestions(contact, ContactSuggestionSalutationEnum.dear);
            contact.dear = contactSuggestionSalconDear.getDearText();
        }

    }

    static getFullNameForContacts(contacts: Contact[], addComma : boolean, lastNameFirst : boolean = false): string{
        let nameListString: string = '';
        if(Array.isArray(contacts) && contacts.length > 0){
            let humanContacts = contacts.filter(item=>item && item.contactName);
            if(Array.isArray(humanContacts) && humanContacts.length > 0){
                let humanContactNames: string[]
                    = humanContacts.map(humanContact=>{
                    let name = humanContact.getFullNameForContact(addComma, lastNameFirst);
                    return name ? name : '';
                });
                if(Array.isArray(humanContactNames) && humanContactNames.length > 0){
                    nameListString = Utils.concatenateNames(humanContactNames)
                }
            }
        }
        return nameListString;
    }

    static getConcurrencyIssuesErrorMsg(sourceContact: Contact, signers: Contact[]): string{
        let errorMsg: string = '';
        if(sourceContact && Array.isArray(signers) && signers.length > 0){
            errorMsg = 'Due to concurrency issues, we were unable to add ' + ContactUtil.getFullNameForContacts(signers, false)
                + (signers.length > 1 ? ' as signing officers for ' : ' as a signing officer for ')
                + sourceContact.genericFormatName;
        }
        return errorMsg;
    }
}
