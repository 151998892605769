import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {CategoryFieldDataType, CategoryFieldType} from '../../shared-main/constants';
import {CategoryField} from '../../shared-main/supplemental-task-category/category-field';
import {Contact} from './contact';
import {contactTypeMapping} from '../../contact/contact-type-mapping';
import * as _ from 'lodash';

export const RequestTransmissionKeys = [
    {label: '1. Request approval of sale of the property under Mortgage No. <MortgageNo> from <Vendors> to <Purchasers>.' ,
        value:'Option 1: Request approval of sale of the property...'},
    {label: '2. A transfer of Land under Power of Sale dated <TransferOfLandDate> in which <Vendors> as Transferor and <Purchasers> as Purchaser and Transferee in pursuance of the mortgage sale proceedings under The Real Property Act and/or the Mortgage.' ,
        value:'Option 2: A Transfer of Land under Power of Sale dated...'},
    {label: '3. Request the issuance of a Notice of Application for Final Order of Foreclosure/Final Notice to Redeem under Mortgage No. <MortgageNo>.' ,
        value:'Option 3: Request the issuance of a Notice of Application for Final Order...'},
    {label: '4. Request the issuance of a Final Order of Foreclosure under Mortgage No. <MortgageNo> and Notice of Application for Order of Foreclosure/Final Notice to Redeem No.[ Manually enter AFOF No. ]' ,
        value:'Option 4: Request the issuance of a Final Order of Foreclosure...'},
    {label: '5. A Final Order of Foreclosure issued under Mortgage No. <MortgageNo>' ,
        value:'Option 5: A Final Order of Foreclosure issued under...'},
    {label: '6. Request an Order for Sale by either public auction and/or private contract pursuant to proceedings under Notice of Exercising Power of Sale [ manual entry of NEPS No. ] and Mortgage No. <MortgageNo>  and in support of this Request submits the documentation as set out in Schedule "A".' ,
        value:'Option 6: Request an Order for Sale by either public auction and/or private contract...'}
];

export class MatterSupplementalTaskField extends BaseEntity {
    id: number;
    fieldValue: string;
    fieldLabel: string;
    fieldType: CategoryFieldType;
    fieldDataType: CategoryFieldDataType;
    subFields: MatterSupplementalTaskField[] = [];
    fieldContact: Contact; //Used for Name & Address
    fieldDefaultLabel: string;

    constructor(field?: MatterSupplementalTaskField) {
        super(field);
        if(field) {
            if (field.fieldContact) {
                this.fieldContact = new Contact(field.fieldContact);
            }
            if (field.subFields) {
                for(let subField of field.subFields) {
                    this.subFields.push(new MatterSupplementalTaskField(subField));
                }
            }
        }
    }

    static clone(source: MatterSupplementalTaskField): MatterSupplementalTaskField  {
        let clonedCategoryField: MatterSupplementalTaskField = new MatterSupplementalTaskField();
        for(let prop in source) {
            if(source.hasOwnProperty(prop)) {
                clonedCategoryField[prop] = source[prop];
            }
            clonedCategoryField.id = null;
            if(source.fieldContact) {
                clonedCategoryField.fieldContact = new Contact();
                clonedCategoryField.fieldContact.createNewContactClone(source.fieldContact);
            }
            clonedCategoryField.subFields = [];
            if(source.subFields) {
                source.subFields.forEach(item => {
                    clonedCategoryField.subFields.push(MatterSupplementalTaskField.clone(item));
                })
            }
        }
        return clonedCategoryField;
    }


    static createFromDefaultCategoryField(defaultCategoryField: CategoryField): MatterSupplementalTaskField {
        let matterSupplementalTaskField = new MatterSupplementalTaskField();
        matterSupplementalTaskField.fieldLabel = defaultCategoryField.fieldLabel;
        matterSupplementalTaskField.fieldType = defaultCategoryField.fieldType;
        matterSupplementalTaskField.fieldDataType = defaultCategoryField.fieldDataType;
        if(matterSupplementalTaskField.fieldType == 'NAME_ADDRESS') {
            matterSupplementalTaskField.initializeNameAddressContact();
        }
        matterSupplementalTaskField.fieldDefaultLabel = defaultCategoryField.fieldDefaultLabel;
        if(defaultCategoryField.subFields) {
            defaultCategoryField.subFields.forEach((defaultSubField: CategoryField) => {
                if(defaultSubField.enabled) {
                    matterSupplementalTaskField.subFields.push(MatterSupplementalTaskField.createFromDefaultCategoryField(defaultSubField))
                }
            });
        }

        if(matterSupplementalTaskField.fieldDataType === "YES_NO") {
            matterSupplementalTaskField.fieldValue = 'Y_n'; //Set default value
        } else if(matterSupplementalTaskField.fieldDataType === "NO_YES") {
            matterSupplementalTaskField.fieldValue = 'N_y'; //Set default value
        }


        return matterSupplementalTaskField;
    }

    get isYesNoTypeField(): boolean {
        return this.fieldDataType === 'YES_NO';
    }

    get isNoYesTypeField(): boolean {
        return this.fieldDataType === 'NO_YES';
    }

    get isTextTypeField(): boolean {
        return this.fieldDataType === 'TEXT';
    }

    get isDateTypeField(): boolean {
        return this.fieldDataType === 'DATE';
    }

    get isAmountTypeField(): boolean {
        return this.fieldDataType === 'AMOUNT';
    }

    get isNumberTypeField(): boolean {
        return this.fieldDataType === 'NUMBER';
    }

    get isDetailsFieldType(): boolean {
        return this.fieldType === 'DETAILS';
    }

    get isUserDefinedField() : boolean {
        return this.fieldType === 'USER_DEFINED_FIELD';
    }

    get isRequestTransmissionFormField() : boolean {
        return this.fieldLabel === 'Request/Transmission - Form 5.1 - Box 2 wording';
    }

    get isMortgageNoField() : boolean {
        return this.fieldLabel === 'Mortgage No.';
    }

    get isDateOfTransferOfLandField() : boolean {
        return this.fieldLabel === 'Date of Transfer of Land under Power of Sale';
    }



    getFieldForContactName() : string {
        if (this.fieldContact && this.fieldContact.contactType) {
            if(this.fieldContact.contactType === 'REALESTATEBROKER' ||
                this.fieldContact.contactType === 'CONDO_CORPORATION' ||
                this.fieldContact.contactType === 'MANAGEMENT_COMPANY' ||
                this.fieldContact.contactType === 'MORTGAGE_BROKER' ||
                this.fieldContact.contactType === 'INSURANCE_BROKER' ||
                this.fieldContact.contactType === 'MORTGAGEE' ||
                this.fieldContact.contactType === 'SURVEYOR') {
                return 'organizationName';
            }
            else if(this.fieldContact.contactType === 'REALESTATEAGENT') {
                return 'surnameLastFullName';
            }
            else if(this.fieldContact.contactType === 'PERSON') {
                return this.fieldContact.isCorporationOrOtherEntity ? 'organizationName' : 'fullName';
            }
            else if(this.fieldContact.contactType === 'PRIVATE_CONTACT') {
                return 'lastName';
            }
            else if(this.fieldContact.isPrivateLender || this.fieldContact.contactType === 'RESIDENCE_ASSOCIATION') {
                return 'displayName';
            }
            else if(this.fieldContact.contactType === 'SOLICITOR') {
                return 'genericName';
            }
        }
    }

    initializeNameAddressContact(): void {
        let privateContactType = _.find(contactTypeMapping.CONTACTTYPES, contactTypeObj => contactTypeObj.contactKey === 'PRIVATE_CONTACT');
        this.fieldContact = new Contact();
        this.fieldContact.setDefaultValues(privateContactType.contactKey);
        this.fieldContact.snapshotFlag = true;
        this.fieldContact.lastName = '';
    }
}
