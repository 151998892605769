import {BaseEntity} from '../shared/BaseEntity/base-entity';

export const reportToPurchaseDefaultSettingsOptions = [
    {
        key: 'ENCLOSE_POLICY_WITH_REPORTING_LETTER',
        value: 'Enclose policy with reporting letter'
    },
    {
        key: 'POLICY_TO_FOLLOW_REPORTING_LETTER',
        value: 'Policy to follow reporting letter'
    }
];

export class TitleInsuranceConfiguration extends BaseEntity {
    id: number;
    defaultSettingsInReportToPurchase: string;
    doNotDetectECFee: boolean;
    doNotDetectEliteFee : boolean;
    doNotDetectConnectingCounselFee : boolean;
    doNotDeductLegalCounselFee : boolean;
    customerAccountId: number;
}
