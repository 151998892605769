import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class MatterInstallmentPayment  extends  BaseEntity{

    constructor(matterInstallmentPayment?: MatterInstallmentPayment) {
        super(matterInstallmentPayment);
    }

    id : number;
    installmentDate : string;
    installmentAmount: number;

    isInstallmentDateAvailable() : boolean {
        return (this.installmentDate && !this.installmentDate.startsWith('/') && !this.installmentDate.endsWith('/') && this.installmentDate.indexOf('//') < 0)
    }

}
