import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {BasicUserInfo} from '../../matters/shared/basic-user-info';
import {
    Address,
    Borrower,
    FileStatus,
    StewartAssystDocument,
    StewartAssystMortgageInstructionData, THIRD_PARTY_INTEGRATION
} from './stewart-assyst-mortgage-instruction-data';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ContactName} from '../../matters/shared/contact-name';
import {StewartAssystMortgageFeesData} from './stewart-assyst-mortgage-fees-data';
import {MortgageInstructionDecorator} from '../../integrations/mortgage-instructions/mortgage-instruction-decorator';
import {FctMortgageInstructionDecorator} from '../../integrations/mortgage-instructions/fct-mortgage-instruction-decorator';
import {TelusMortgageInstructionDecorator} from '../../integrations/mortgage-instructions/telus-mortgage-instruction-decorator';
import {ON_DEMAND_LABEL, ON_DEMAND_VALUE} from '../constants';
import Utils from '../utils';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {Ignore} from '../../admin/shared/dp-ignore-decorator';
import {DpBooleanValue, DpBooleanValueTypes} from '../../matters/shared/dp-boolean';

const propertyIgnoreList = ['locked','lockedByUser','solicitorId','matterRecordNumber','matterId','matterProvince','matterType',
                            'reconciledBorrowers','mortgageInstructionDecorator','sourceFctUrn'];
export type MortgageInstructionStatus = 'DRAFT' | 'UNASSIGNED' | 'ASSIGNED';
export class BorrowerMapping extends BaseEntity {
    id : number;
    borrower: Borrower;
    matterParticipantId: number;
    constructor(bm? : BorrowerMapping) {
        super(bm);
        if(bm) {
            if (bm.borrower) {
                this.borrower = new Borrower(bm.borrower)
            }
        }
    }
}

export class RemovedReconciledBorrowerRelatedData {
    stewartMortgageInstruction: StewartMortgageInstruction;
    sourceContactId: number;
    reconciledBorrower: BorrowerMapping;
}

export class StewartMortgageInstruction extends BaseEntity {
    id: number;
    dealId: string;
    locked: boolean;
    lockedByUser : BasicUserInfo = null;
    solicitorId: number;
    mortgageInstructionData: StewartAssystMortgageInstructionData;
    mortgageInstructionStatus: MortgageInstructionStatus;
    matterRecordNumber: string;
    matterId: number;
    matterProvince: string;
    matterType: string;
    mortgageFeesData: StewartAssystMortgageFeesData;
    reconciledBorrowers : BorrowerMapping[] = [];
    @Ignore
    mortgageInstructionDecorator: MortgageInstructionDecorator;
    sourceFctUrn: string;


    constructor(s? : StewartMortgageInstruction) {
        super(s);
        if(s) {
            this.lockedByUser = s.lockedByUser ? new BasicUserInfo(s.lockedByUser) : null;
            if(s.mortgageInstructionData) {
                this.mortgageInstructionData = new StewartAssystMortgageInstructionData(s.mortgageInstructionData);
            }
            if(s.mortgageFeesData) {
                this.mortgageFeesData = new StewartAssystMortgageFeesData(s.mortgageFeesData);
            }
            this.reconciledBorrowers = [];

            if (s.reconciledBorrowers) {
                for (let rb of s.reconciledBorrowers) {
                    this.reconciledBorrowers.push(new BorrowerMapping(rb));
                }
            }

            if(this.isFctInstruction) {
                this.mortgageInstructionDecorator = new FctMortgageInstructionDecorator();
            } else {
                this.mortgageInstructionDecorator = new TelusMortgageInstructionDecorator();
            }
        }
    }

    shouldKeyBeChecked(key): boolean {
        return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
    }

    //for FCT, display value from sourceFctUrn, other provider display value from dealId field
    getDealIdForDisplay(){
        return this.isFctInstruction ? this.sourceFctUrn : this.dealId;
    }

    get hasOnlyNewFileStatus(): boolean {
        return this.mortgageInstructionDecorator.hasOnlyNewFileStatus(this);
    }

    hasFileClosedByLender(): boolean {
       return (this.mortgageInstructionData.isFiNumberBNS() ||
           this.mortgageInstructionData.isFiNumberBMO() ||  this.mortgageInstructionData.isFiNumberSimpliiFinancial())
         && this.mortgageInstructionData.fileStatuses.some(value => value.status == MortgageInstructionFileStatusCode.FILE_CLOSED_BY_LENDER);
    }

    hasFileClosedByOtherLender(): boolean {
        return !this.mortgageInstructionData.isFiNumberBMO() &&
            !this.mortgageInstructionData.isFiNumberSimpliiFinancial() &&
            !this.mortgageInstructionData.isFiNumberBNS() &&
            this.mortgageInstructionData.fileStatuses.some(value => value.status == MortgageInstructionFileStatusCode.FILE_CLOSED);
    }

    get isTelusInstruction(): boolean {
        return (
            this.provider && (this.provider.toLowerCase() === THIRD_PARTY_INTEGRATION.UNITY_LENDER_CENTRE
                || this.provider.toLowerCase() === THIRD_PARTY_INTEGRATION.ASSYST_REAL_ESTATE)
        );
    }

    get isFctInstruction(): boolean {
        return this.provider == 'FCT-LLC' || this.provider == 'FCT-MMS';
    }

    get isFctDealInCompletedStatus(): boolean{
        if(this.isFctInstruction){
            if(this.mortgageInstructionData &&  this.mortgageInstructionData.fctStatusData && this.mortgageInstructionData.fctStatusData.isCompleted()){
                return true;
            }
        }
        return false;
    }

    get isFctDealCompletedAndWithDocForDownload(): boolean{
        return this.isFctDealInCompletedStatus && this.areDocumentsAvailableToDownload && this.hasOutstandingDocumentsForDownload;
    }

    get isFctMMSInstruction(): boolean {
        return this.provider == 'FCT-MMS';
    }

    get isFctLLCInstruction(): boolean {
        return this.provider == 'FCT-LLC';
    }

    get lenderRefNo(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.lenderRefNo : null;
    }

    get solicitor(): string {
        return this.mortgageInstructionData ? new ContactName(this.mortgageInstructionData.solicitorName).surnameLastFullName : null;
    }

    get prelim(): string {
        return this.mortgageInstructionDecorator.getPrelim(this.mortgageInstructionData);
    }

    get final(): string {
        return this.mortgageInstructionDecorator.getFinal(this.mortgageInstructionData);
    }

    get isDealAccepted(): boolean {
        return this.hasStatus(MortgageInstructionFileStatusCode.FILE_ACCEPTED);
    }

    get isFinalReportTransmitted(): boolean {
        return this.hasStatus(MortgageInstructionFileStatusCode.FILE_FINAL_TRANSMITTED);
    }

    get isPreliminaryReportTransmitted(): boolean {
        return this.hasStatus(MortgageInstructionFileStatusCode.FILE_PRELIMINARY_TRANSMITTED);
    }

    hasStatus(status: string): boolean {
        return this.fileStatuses.findIndex(value => value.status === status) > -1;
    }

    get isPreliminaryReportApplicable(): boolean {
        return this.prelim === 'N/A' ? false : true;
    }

    get isPreliminaryReportGenerated(): boolean {
        return this.prelim === 'Yes' ? true : false;
    }

    get isFinalReportApplicable(): boolean {
        return this.final === 'N/A' ? false : true;
    }

    get isFinalReportGenerated(): boolean {
        return this.final === 'Yes' ? true : false;
    }


    //Primary borrower name can be found in two places. For new mortgage instruction list it exists in 'mortgageInstructionData.borrower' but for
    // getDealData it returns list of borrowers and first one is shown as primary
    get primaryBorrower(): string {
        if(this.mortgageInstructionData) {
            if(this.mortgageInstructionData.borrower) {
                return this.mortgageInstructionData.borrower
            } else if(this.mortgageInstructionData.borrowers.length > 0) {
                return this.mortgageInstructionData.borrowers[0].borrowerName.fullName
            }
        }

        return null;
    }

     get lenderRemarks() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.lenderRemarks : null;
    }

    get solicitorRemarks() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.solicitorRemarks : null;
    }

    get lender(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.lenderName : null;
    }

    get isLenderCMHC(): boolean{
        return this.mortgageInstructionData ? this.mortgageInstructionData.isFiNumberCMHC() : false;
    }

    get closingDate(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.closingDate : null;
    }

    get closingDateFormatted(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.closingDateFormatted : null;
    }

    get expiresIn(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.expiresIn : null;
    }


    get isInstructionCancelled(): boolean {
        return this.mortgageInstructionDecorator.isMortgageInstructionCancelled(this.mortgageInstructionData);
    }

    get isInstructionClosed(): boolean {
        return !!(this.mortgageInstructionData && this.mortgageInstructionData.fileStatuses.find(value => value.status == MortgageInstructionFileStatusCode.FILE_CLOSED));
    }
    get isInstructionClosedByLender(): boolean {
        return !!(this.mortgageInstructionData && this.mortgageInstructionData.fileStatuses.find(value => value.status == MortgageInstructionFileStatusCode.FILE_CLOSED_BY_LENDER));
    }
    get borrowers(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.borrowerNames : null;
    }

    get acceptedOn(): string {
        if(this.mortgageInstructionData) {
            return this.mortgageInstructionDecorator.acceptedOnDate(this.mortgageInstructionData);
        }
        return null;
    }

    get prelimExecutedOn(): string {
        if(this.mortgageInstructionData) {
            let fileAcceptedStatus: FileStatus = this.mortgageInstructionData.fileStatuses.find(value => value.status === MortgageInstructionFileStatusCode.FILE_PRELIMINARY_TRANSMITTED);
            if(fileAcceptedStatus) {
                return fileAcceptedStatus.dateExecuted;
            }
        }
        return null;
    }

    get finalExecutedOn(): string {
        if(this.mortgageInstructionData) {
            let fileAcceptedStatus: FileStatus = this.mortgageInstructionData.fileStatuses.find(value => value.status === MortgageInstructionFileStatusCode.FILE_FINAL_TRANSMITTED);
            if(fileAcceptedStatus) {
                return fileAcceptedStatus.dateExecuted;
            }
        }
        return null;
    }

    //Converts  024:57:00 to 024 hr 57 min
    get formattedExpireTiming() {
        return this.mortgageInstructionDecorator.formattedExpireTiming(this);
    }

    get noteIndicator(): boolean {
        return this.mortgageInstructionData ? this.mortgageInstructionData.noteIndicator : null;
    }

    get fileStatuses(): FileStatus[] {
        return this.mortgageInstructionData ? this.mortgageInstructionData.fileStatuses : [];
    }

    get documents(): StewartAssystDocument[] {
        return this.mortgageInstructionData ? this.mortgageInstructionData.documents : [];
    }

    get provider(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.provider : null;
    }

    get isUnassignedMortgage(): boolean {
        return this.mortgageInstructionStatus == 'UNASSIGNED'
    }

    get isAssignedMortgage(): boolean {
        return this.mortgageInstructionStatus == 'ASSIGNED';
    }

    get isNewMortgage(): boolean {
        return !this.mortgageInstructionStatus || this.mortgageInstructionStatus == 'DRAFT';
    }

    get isLockedByOtherUser(): boolean {
        const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        return (this.lockedByUser && this.lockedByUser.id !== Number(userId));
    }

    get isLockedByCurrentUser(): boolean {
        const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        return (this.lockedByUser && this.lockedByUser.id === Number(userId));
    }

    get invoiceAmount(): number {
        return this.mortgageFeesData ? this.mortgageFeesData.totalFee : null;
    }

    get mortgagePriority() : number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.mortgagePriority : null;
    }

    get mortgageProduct() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.mortgageProduct : null;
    }

    get principalAmount() : number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.principalAmount : null;
    }

    get principalAmountText() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.principalAmountText : null;
    }

    get lenderBranchName() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.lenderBranchName : null;
    }

    get contactName() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.contactName : null;
    }

    get contactPhone() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.contactPhone : null;
    }

    get mortgagee() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.mortgagee : null;
    }

    get mortgageeAddress() : Address {
        return this.mortgageInstructionData ? this.mortgageInstructionData.mortgageeAddress : null;
    }

    get mortgageeBranchName() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.mortgageeBranchName : null;
    }

    get mortgageeBranchContactName() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.mortgageeBranchContactName : null;
    }

    get mortgageeBranchEmail() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.mortgageeBranchEmail : null;
    }

    get mortgageeBranchPhone() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.mortgageeBranchPhone : null;
    }

    get mortgageeBranchFax() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.mortgageeBranchFax : null;
    }

    get mortgageeBranchTransit() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.mortgageeBranchTransit : null;
    }


    get servicingBranchAddress() : Address {
        return this.mortgageInstructionData ? this.mortgageInstructionData.servicingBranchAddress : null;
    }

    get servicingBranchName() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.servicingBranchName : null;
    }

    get servicingBranchContactName() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.servicingBranchContactName : null;
    }

    get servicingBranchEmail() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.servicingBranchEmail : null;
    }

    get servicingBranchPhone() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.servicingBranchPhone : null;
    }

    get servicingBranchFax() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.servicingBranchFax : null;
    }

    get servicingBranchTransit() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.servicingBranchTransit : null;
    }

    get servicingBranchTransitOrg() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.servicingBranchTransitOrg : null;
    }

    get originatingBranchAddress() : Address {
        return this.mortgageInstructionData ? this.mortgageInstructionData.originatingBranchAddress : null;
    }

    get originatingBranchName() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.originatingBranchName : null;
    }

    get originatingBranchEmail() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.originatingBranchEmail : null;
    }

    get originatingBranchPhone() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.originatingBranchPhone : null;
    }

    get originatingBranchFax() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.originatingBranchFax : null;
    }

    get originatingBranchTransit() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.originatingBranchTransit : null;
    }

    get originatingBranchTransitOrg() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.originatingBranchTransitOrg : null;
    }

    get loanType() : string {
        let field =   this.getMappingValue('loanType');
        return field? field.value : null;
    }

    get rateType() : string {
        let field =   this.getMappingValue('rateType');
        return field? field.value : null;
    }

    get cashBack() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.cashBack : null;
    }

    get appraisalFees() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.appraisalFees : null;
    }

    get loanAmount() : Number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.loanAmount : null;
    }

    get retainedByLender() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.retainedByLender : null;
    }

    get mortgageInsurer() : string {
        if(this.isFctInstruction) {
            return this.mortgageInstructionData ? this.mortgageInstructionData.mortgageInsurer : null;
        } else {
            let field =   this.getMappingValue('mortgageInsurer');
            return field? field.value : null;
        }
    }

    get insurancePremium() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.insurancePremium: null;
    }

    get insurancePremiumIncluded() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.insurancePremiumIncluded : null;
    }

    get taxOnInsurancePremium(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.taxOnInsurancePremium : null;
    }

    get insuranceApplicationFee(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.insuranceApplicationFee : null;
    }

    get lenderFee(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.lenderFee : null;
    }

    get miscellaneousFee(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.miscellaneousFee : null;
    }

    get holdBackFee(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.holdBackFee : null;
    }

    get taxHoldBack(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.taxHoldBack : null;
    }

    get buyDownAtCommitmentFee(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.buyDownAtCommitmentFee : null;
    }

    get wireFee(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.wireFee : null;
    }

    get fctFee(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.fctFee : null;
    }

    get lineOfCredit(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.lineOfCredit : null;
    }

    get internalMortgagePayout(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.internalMortgagePayout : null;
    }

    get bridgeLoanApplicationFee(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.bridgeLoanApplicationFee : null;
    }

    get rebate(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.rebate : null;
    }

    get constructionMortgage(): string {
        return this.mortgageInstructionData && this.mortgageInstructionData.constructionMortgage ? "Yes" : "No";
    }

    get assignmentOfRents(): string {
        return this.mortgageInstructionData && this.mortgageInstructionData.assignmentOfRents ? "Yes" : "No";
    }

    get netAdvance() : string {
        return (this.mortgageInstructionData &&
            this.mortgageInstructionData.netAdvance &&
            Number(this.mortgageInstructionData.netAdvance) != this.mortgageInstructionData.principalAmount)?
               this.mortgageInstructionData.netAdvance : 'N/A';
    }

    getInterestRateForRegistrationByProvince(provinceCode: string): string {
        if (provinceCode === 'MB'){
            return  this.mortgageInstructionData ?(this.mortgageInstructionData.interestRateForManitobaRegistration ? this.mortgageInstructionData.interestRateForManitobaRegistration : this.mortgageInstructionData.interestRateForRegistration): null;
        }
        return this.mortgageInstructionData ? this.mortgageInstructionData.interestRateForRegistration: null;
    }

    get interestRate() : string {
        if (this.mortgageInstructionData) {
            return this.mortgageInstructionData.isFctInstruction ? this.mortgageInstructionData.interestRate : this.mortgageInstructionData.interestRateForRegistration;
        }
        return null;
    }

    isNumber(str: string) : boolean {
        return !isNaN(Number(str)) ;
    }

    get interestOnly() : string {
        let field =   this.getMappingValue('flagInterestOnly');
        return field? field.value : null;
    }

    get blendedPayment() : string {
        let field =   this.getMappingValue('flagBlendedPayment');
        return field? field.value : null;

    }

    get mortgageInsured(): DpBooleanValue{
        if(this.mortgageInstructionData){
            let data : any;
            if(this.isFctInstruction){
                let mortgageInsurer :string = this.mortgageInstructionData['mortgageInsurer'];
                if(!mortgageInsurer){
                    return DpBooleanValueTypes.NO;
                }else{
                    data = MapMortgageInstructionToUnityMortgage['mortgageInsurerFCT'].find(item=>{
                        return item.key == mortgageInsurer.toLowerCase().trim();
                    });
                    return data ? data.value : DpBooleanValueTypes.YES;
                }
            }else{
                data = MapMortgageInstructionToUnityMortgage['mortgageInsured'].find(item=>{
                    return item.key == this.mortgageInstructionData['mortgageInsurer'];
                });
                return data ? data.value : null;
            }
            return null;
        }
    }

    get regularRate() : string {
        let field =   this.getMappingValue('flagRegularRate');
        return field? field.value : null;
    }

    get convertible() : string {
        let field =   this.getMappingValue('flagConvertible');
        return field? field.value : null;
    }

    variableRate(provinceCode :string) : string {
        let dataFieldName='variableRate';
        if(provinceCode == 'MB' && !this.mortgageInstructionData.isProvideStewartAssyst()){
            dataFieldName= 'registeredInstructionVariableRate';
        }
        let field= this.mortgageInstructionData? EMPFieldsMapping['variableRate'].find(item=>{return item.key == this.mortgageInstructionData[dataFieldName]; }) : null;
        return field? field.value : 'N_y';
    }

    getVariableRateForUI(provinceCode: string) : string {
        return this.variableRate(provinceCode) == 'N_y' ? null : this.variableRate(provinceCode);
    }

    get baseRate() : number {
        return this.mortgageInstructionData && this.mortgageInstructionData.baseRate ? this.mortgageInstructionData.baseRate : null;
    }

    get rateDifferential() : number {
        return this.mortgageInstructionData && this.mortgageInstructionData.rateDifferential ? this.mortgageInstructionData.rateDifferential : null;
    }

    get minimumRate() : number {
        return this.mortgageInstructionData && this.mortgageInstructionData.minimumRate ? this.mortgageInstructionData.minimumRate : null;
    }

    get maximumRate() : number {
        return this.mortgageInstructionData && this.mortgageInstructionData.maximumRate ? this.mortgageInstructionData.maximumRate  : null;
    }

    get reimbursementRate() : number {
        return this.mortgageInstructionData && this.mortgageInstructionData.reimbursementRate ? this.mortgageInstructionData.reimbursementRate  : null;
    }

    get endOfRateDate() : Date {
        return this.mortgageInstructionData ? this.mortgageInstructionData.endOfRateDate : null;
    }

    get endOfRateDateFormatted() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.endOfRateDateFormatted : null;
    }

    get interestCalculated() : string {
        // let field =   this.getMappingValue('interestCalculated');
        // return field? field.value : null;
        return this.mortgageInstructionData ? this.mortgageInstructionData.interestCalculated : null;
    }

    get loanTerm() : number {
        return this.mortgageInstructionData && this.mortgageInstructionData.loanTerm? this.mortgageInstructionData.loanTerm  : null;
    }

    get loanAmortization() : number {
        return this.mortgageInstructionData && this.mortgageInstructionData.loanAmortization ? this.mortgageInstructionData.loanAmortization : null;
    }

    get standardChargeTermNo() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.standardChargeTermNo : null;
    }

    get bridgeLoanAmount(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.bridgeLoanAmount : null;
    }

    get bridgeInterestRate(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.bridgeInterestRate : null;
    }

    get bridgeInterestAdjustmentDate(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.bridgeInterestAdjustmentDate : null;
    }

    get bridgeFirstPaymentDate(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.bridgeFirstPaymentDate : null;
    }

    get bridgeMaturityDate(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.bridgeMaturityDate : null;
    }

    get bridgePropertyAddress(): Address {
        return this.mortgageInstructionData ? this.mortgageInstructionData.bridgePropertyAddress : null;
    }

    get paymentFrequency() : string {
        if(this.isFctInstruction) {
            return this.mortgageInstructionData ? this.mortgageInstructionData.paymentFrequency : null;
        } else {
            let field =   this.getMappingValue('paymentFrequency');
            return field? field.value : null;
        }
    }

    get paymentsDue() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.paymentsDue : null;
    }

    get interestAdjustmentDateAsRegistered() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.interestAdjustmentDateAsRegistered : null;
    }

    get interestAdjustmentDateAsRegisteredFormatted() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.interestAdjustmentDateAsRegisteredFormatted : null;
    }

    get interestAdjustmentDateText() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.interestAdjustmentDateText : null;
    }

    get firstPaymentDateAsRegistered() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.firstPaymentDateAsRegistered : null;
    }

    get firstPaymentDateAsRegisteredFormatted() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.firstPaymentDateAsRegisteredFormatted : null;
    }

    get firstPaymentDateText() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.firstPaymentDateText : null;
    }

    get firstPaymentDateAlternate(): string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.firstPaymentDateAlternate : null;
    }

    get maturityDateAsRegistered() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.maturityDateAsRegistered : null;
    }

    get maturityDateAsRegisteredFormatted() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.maturityDateAsRegisteredFormatted : null;
    }

    /**
     * this value should be used for downstream logic like eReg, DocGen
     */
    get firstPaymentDateValue(): string{
        if(this.isFctInstruction && this.isLenderCMHC){
            return '';
        }
        return this.firstPaymentDateAsRegisteredFormatted ? Utils.formatDate(this.firstPaymentDateAsRegisteredFormatted) : '';
    }

    /**
     * this value should be used for downstream logic like eReg, DocGen
     */
    get interestAdjustmentDateValue(): string{
        if(this.isFctInstruction && this.isLenderCMHC){
            return '';
        }
        return this.interestAdjustmentDateAsRegisteredFormatted ? Utils.formatDate(this.interestAdjustmentDateAsRegisteredFormatted) : '';
    }

    /**
     * this value should be used for downstream logic like eReg, DocGen
     */
    get maturityDateValue(): string{
        if(this.isFctInstruction && this.isLenderCMHC){
            return '';
        }else if(this.maturityDateAsRegisteredFormatted){
             return Utils.formatDate(this.maturityDateAsRegisteredFormatted);
        }else if(this.lastPaymentDateText){
            //for Mortgage Instructions from Third Party, lastPaymentDateText contains the value from FCT -> maturityDateText.getTextEn
            //US34820, only return 'ON_DEMAND' if FCT value is equalIgnoreCase 'On Demand', otherwise ignore it
            if(this.lastPaymentDateText.trim().toUpperCase() == ON_DEMAND_LABEL.toUpperCase()){
                return ON_DEMAND_VALUE;
            }else{
                //any other possible FCT value like 'First day of month', '20th August 2024'
                //will be displayed as readOnly on EmpMortgage page, but not used for downstream logic
                return '';
            }
        }
        return '';
    }

    get lastPaymentDateText() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.lastPaymentDateText : null;
    }

    get principalAndInterestAsRegistered() : number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.principalAndInterestAsRegistered : null;
    }

    get taxesAsRegistered() : number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.taxesAsRegistered : null;
    }

    get otherAsRegistered() : number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.otherAsRegistered : null;
    }

    get totalAsRegistered() : number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.totalAsRegistered : null;
    }

    get paymentFrequencyAlternate() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.paymentFrequencyAlternate : null;
    }

    get paymentsDueAlternate() : string {
        return this.mortgageInstructionData ? this.mortgageInstructionData.paymentsDueAlternate : null;
    }

    get principalAndInterestAlternate() : number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.principalAndInterestAlternate : null;
    }

    get taxesAlternate() : number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.taxesAlternate : null;
    }

    get otherAlternate() : number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.otherAlternate : null;
    }

    get totalAlternate() : number {
        return this.mortgageInstructionData ? this.mortgageInstructionData.totalAlternate : null;
    }

    getMappingValue(fieldName: string) : any {
        return this.mortgageInstructionData?  EMPFieldsMapping[fieldName].find(item=>{return item.key == this.mortgageInstructionData[fieldName]; }) : null
    }

    get areDocumentsAvailableToDownload(): boolean {
        return this.mortgageInstructionDecorator.areDocumentsAvailableToDownload(this);
    }

    get downloadableDocuments(): StewartAssystDocument[] {
       return this.mortgageInstructionDecorator.downloadableDocuments(this.mortgageInstructionData);
    }

    get hasOutstandingDocumentsForDownload(): boolean {
        return this.downloadableDocuments && this.downloadableDocuments.length > 0
    }

    getRegisteredInterestRate(provinceCode: ProvinceCode): string{
        if (this.isFctInstruction && this.isLenderCMHC){
            return '';
        } else if (this.getInterestRateForRegistrationByProvince(provinceCode)){
            return this.isNumber(this.getInterestRateForRegistrationByProvince(provinceCode)) ?
                (this.getInterestRateForRegistrationByProvince(provinceCode) + '%' ) :
                this.getInterestRateForRegistrationByProvince(provinceCode);
        }

        return '';
    }

}
export const MortgageInstructionFileStatusCode = {
    NEW_FILE_RECEIVED : '010',
    FILE_ACCEPTED : '020',
    COST_OF_BORROWING_REQUESTED : '030',
    FILE_PRELIMINARY_TRANSMITTED : '070',
    FILE_FINAL_TRANSMITTED : '100',
    FILE_CLOSED_BY_LENDER : '109',
    FILE_CLOSED : '110',
    FILE_CANCELLED_BY_TELUS : '990',
    FILE_CANCELLED_BY_FI : '999'
};

export const MapMortgageInstructionToUnityMortgage = {

    mortgageInsured: [{key: '00', value: DpBooleanValueTypes.NO},
        {key: '01', value: DpBooleanValueTypes.NO},
        {key: '02', value: DpBooleanValueTypes.YES},
        {key: '03', value: DpBooleanValueTypes.YES},
        {key: '04', value: DpBooleanValueTypes.YES},
        {key: '05', value: DpBooleanValueTypes.YES}
    ],
    mortgageInsurerFCT: [{key: 'na', value: DpBooleanValueTypes.NO},
        {key: 'not applicable', value: DpBooleanValueTypes.NO},
        {key: 'unknown', value: DpBooleanValueTypes.NO},
        {key: '', value: DpBooleanValueTypes.NO},
        {key: 'not insured', value: DpBooleanValueTypes.NO}
    ]
}

export const  EMPFieldsMapping = {
    loanType: [
        {key: '01', value: 'Open'},
        {key: '02', value: 'Closed'},
        {key: '03', value: 'Rate Capper'}
    ],

    rateType : [{key: '00', value: 'Fixed Rate'},
                {key: '01', value: 'Variable Rate'},
                {key: '02', value: 'Protected Rate'}
    ],


    flagInterestOnly : [{key: '0', value: 'No'},
                    {key: '1', value: 'Yes'}
    ],

    flagBlendedPayment : [{key: '0', value: 'No'},
                      {key: '1', value: 'Yes'}
    ],

    flagRegularRate : [{key: '0', value: 'No'},
                   {key: '1', value: 'Yes'}
    ],

    flagConvertible : [{key: '0', value: 'No'},
                     {key: '1', value: 'Yes'}
    ],

    variableRate : [{key: '00', value: 'No'},
                   {key: '01', value: 'Yes'},
                   {key: '02', value: 'Yes'}
    ],


    // interestCalculated: [{key: '1', value: 'annually'},
    //                      {key: '2', value: 'half-yearly'},
    //                      {key: '4', value: 'quarterly'},
    //                      {key: '6', value: 'bi-monthly'},
    //                      {key: '12', value: 'monthly'},
    //                      {key: '24', value: 'semi-monthly'},
    //                      {key: '26', value: 'bi-weekly'},
    //                      {key: '52', value: 'weekly'},
    //                      {key: '365', value: 'daily '}
    // ],

    paymentFrequency: [{key: '01', value: 'Weekly'},
                       {key: '11', value: 'Weekly'},
                       {key: '02', value: 'Bi-Weekly'},
                       {key: '21', value: 'Bi-Weekly'},
                       {key: '03', value: 'Monthly'},
                       {key: '31', value: 'semi-monthly'},
                       {key: '04', value: 'Quarterly'},
                       {key: '05', value: 'Bi-Annually'},
                       {key: '06', value: 'Annually'},
                       {key: '07', value: 'On Demand'}
    ],

    paymentFrequencyAlternate: [{key: '01', value: 'Weekly'},
                                {key: '11', value: 'Weekly'},
                                {key: '02', value: 'Bi-Weekly'},
                                {key: '21', value: 'Bi-Weekly'},
                                {key: '31', value: 'semi-monthly'}
    ],

    mortgageInsurer: [{key: '00', value: 'Not applicable'},
                      {key: '01', value: 'Conventional'},
                      {key: '02', value: 'CMHC (SCHL)'},
                      {key: '03', value: 'GFC'},
                      {key: '04', value: 'GENWORTH'},
                      {key: '05', value: 'Other Insurer'}
    ]

};
