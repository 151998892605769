import { Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-chataitoaster',
  templateUrl: 'chat-AI-toaster.component.html'
})
export class ChatAIToasterComponent {
  @Output() closeToasterClicked = new EventEmitter<boolean>();
  @Input() messageText: string
  constructor() {}

  clickCloseToaster() {
    this.closeToasterClicked.emit(false);
  }
}
