import {UUID} from 'angular2-uuid';
import {Telephone} from '../../matters/shared';

/**
 * Created by unasir01 on 6/5/2017.
 */
const propertyIgnoreList = ['identifier'];
const telephoneLists = ['telephone', 'telephones', 'lawClerkTelephones']; //collections of Telephone
const ID_PROPERTY_NAMES = ['id', 'matterParticipantId', 'matterDocketId']; //This array contains the name of all ID columns used in matter graph

export class BaseEntity {

    identifier: number;

    constructor(json?: BaseEntity) {
        if (json) {
            for (let prop in json) {
                if (json.hasOwnProperty(prop)) {
                    this[prop] = json[prop];
                }
            }
        }

        if (this['id']) {
            this.identifier = this['id'];
        } else if (!this['identifier']) {
            let uidStr = UUID.UUID();
            if (uidStr) {
                let repStr = uidStr.replace(/\D/g, '');
                this.identifier = this.toFixed(Number(repStr));
            }

        }
    }

    isNonBlackedKey(key: string, blacklist: string[]): boolean {
        const ownPropertyDescriptor = Object.getOwnPropertyDescriptor(this, key);
        return ownPropertyDescriptor && ownPropertyDescriptor.hasOwnProperty('value') && (blacklist.indexOf(key) < 0);
    }

    shouldKeyBeChecked(key): boolean {
        // We only check if it is a normal data property
        return this.isNonBlackedKey(key, propertyIgnoreList);
    }

    logDiff(source, snapshot, path?, diff?){
        if(diff){
            diff.name = path;
            diff.source = source;
            diff.snapshot = snapshot;
        }
    }

    equals(x, a?, path?, diff?) : boolean {
        if (!x) {
            this.logDiff(x, a, path, diff);
            return false;
        }
        if (typeof (a) === 'undefined') {
            a = this;
        }
        let p;
        //We treat all falsy values are  equal.
        for (p in a) {
            if (!a.shouldKeyBeChecked || a.shouldKeyBeChecked(p)) {
                if (a[p]) {
                    switch (typeof (a[p])) {
                        case 'object':
                            if (Array.isArray(a[p]) && telephoneLists.indexOf(p) > -1) { //telephones are compared differently due to possible ordering differences of the arrays
                                let telephones1: string[] = this.getTelephonesForComparison(a[p]);
                                let telephones2: string[] = this.getTelephonesForComparison(x[p]);

                                let isAllTelephonesEqual: boolean = telephones1.length === telephones2.length && telephones1.every((value, index) => value === telephones2[index]);

                                if (!isAllTelephonesEqual) {
                                    this.logDiff(telephones2, telephones1, path + p, diff);
                                    return false;
                                }

                            }
                            else if (x[p] && !!x[p]['equals']) {
                                if(!x[p].equals(a[p], undefined, path + p, diff )){
                                    return false;
                                }

                            }else if(!this.equals(x[p], a[p], path + p + '.', diff)) {
                                return false;
                            }
                            break;
                        case 'function':
                            if (typeof (x[p]) === 'undefined' ||
                                (p !== 'equals' && a[p].toString() !== x[p].toString())) {
                                this.logDiff(x[p], a[p], path + p , diff);
                                return false;
                            }
                            break;
                        default:
                            if (a[p] != x[p]) {
                                this.logDiff(x[p], a[p], path + p, diff);
                                return false;
                            }
                    }
                } else if(x[p]) {
                    this.logDiff(x[p], a[p], path + p, diff);
                    return false;
                }
            }
        }

        for (p in x) {
            if ((!x.shouldKeyBeChecked || x.shouldKeyBeChecked(p)) && x[p] && typeof (a[p]) === 'undefined') {
                this.logDiff([p], a[p], path + p, diff);
                return false;
            }
        }
        return true;
    }


    getTelephonesForComparison(telephones: Telephone[]): string[] {
        let telephoneString: string[] = [];

        telephones.forEach((telephone: Telephone) => {
            telephoneString.push(telephone.toStringForComparison());
        });

        return telephoneString.sort(); //effectively sorting by phone type
    }

    arrayEqual(orignalArray: any[], targetArray: any[]): boolean {
        if ((!Array.isArray(orignalArray) || orignalArray.length == 0)
            && (!Array.isArray(targetArray) || targetArray.length == 0)) {
            return true;
        }

        return this.equals(orignalArray, targetArray);
    }

    // This method prevents scientific notation that breaks jQuery during validation
    toFixed(x) {
        if (Math.abs(x) < 1.0) {
            var e = parseInt(x.toString().split('e-')[1]);
            if (e) {
                x *= Math.pow(10, e - 1);
                x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
            }
        } else {
            var e = parseInt(x.toString().split('+')[1]);
            if (e > 20) {
                e -= 20;
                x /= Math.pow(10, e);
                x += (new Array(e + 1)).join('0');
            }
        }
        return x;
    }

    clearAllIds(passedObj?: any): void {
        let obj = passedObj ? passedObj : this;

        for (let property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (property == 'id') {
                    obj['id'] = null;
                } else if (property == 'identifier') {
                    obj['identifier'] = null;
                } else if (obj[property] instanceof Object) {
                    this.clearAllIds(obj[property]);
                }
            }
        }
    }

    static clearAllIdentifiers(passedObj?: any): void {
        let obj = passedObj ? passedObj : this;
        for (let property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (property === 'identifier') {
                    obj['identifier'] = null;
                } else if (obj[property] instanceof Object) {
                    BaseEntity.clearAllIdentifiers(obj[property]);
                }
            }
        }
    }

    copyFrom(fromObject: any, listOfIgnoredIds: string[] = ['id', 'identifier']): any {
        if (!fromObject) {
            return this;
        }
        if (Object.prototype.toString.call(this) !== Object.prototype.toString.call(fromObject)) { // test if the same type
            return this;
        }
        for (let property in this) {
            if (this.hasOwnProperty(property) && fromObject.hasOwnProperty(property) && listOfIgnoredIds.indexOf(property) < 0) {
                if (this[property] instanceof Object) {
                    //if (this[property] instanceof BaseEntity)
                    if (typeof this[property]['copyFrom'] === 'function') {
                        this[property]['copyFrom'](fromObject[property], listOfIgnoredIds);
                    }
                } else if (this[property] instanceof Array) {
                    // ToDo
                } else {
                    this[property] = fromObject[property];
                }
            }
        }
        return this;
    }

    static isIdProperty(property: string): boolean {
        return ID_PROPERTY_NAMES.findIndex(value => value == property) > -1;
    }
}
