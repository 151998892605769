import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class  EmailData extends BaseEntity{
    body: string;
    headers: string;
    subject: string;
    senderEmail: string;
    senderName: string;
    //There are other fields that can be added when needed.
}

@Injectable()
export class MailParseService {

    constructor() {
    }

    async getEmailSubject(file: File) : Promise<string> {
        let emailData: EmailData;
        if(this.isMsgFile(file)){
            emailData = await this.parseMsgMailFile(file).toPromise();
        }
        if(this.isEmlFile(file)){
            emailData = await this.parseEmlMailFile(file).toPromise();
        }
        if(emailData){
            return emailData.subject;
        } else {
            return null;
        }
    }

    parseMsgMailFile(file: File) : Observable<EmailData>{
        let emailDataSubject = new Subject<EmailData>();
        let fileReader = new FileReader();
        fileReader.onload = () =>{
            let arrayBuffer = fileReader.result;
            import("msgreader").then( module => {
                let msgReader = new module.default(arrayBuffer);
                let fileData = new EmailData(msgReader.getFileData()) ;
                emailDataSubject.next(fileData);
                emailDataSubject.complete();
            }).catch(error => {
                    console.log(error);
                    emailDataSubject.next(null);
                    emailDataSubject.complete();
                }) ;

        };
        fileReader.readAsArrayBuffer(file);
        return emailDataSubject;
    }

    parseEmlMailFile(file: File) : Observable<EmailData>{
        let emailDataSubject = new Subject<EmailData>();
        let fileReader = new FileReader();
        fileReader.onload = () =>{
            try {
                let emailText = fileReader.result.toString();
                let fileData = this.convertTextToEmailData(emailText);
                emailDataSubject.next(fileData);
                emailDataSubject.complete();
            } catch (err) {
                emailDataSubject.next(null);
                emailDataSubject.complete();
            }

        };
        fileReader.readAsText(file);
        return emailDataSubject;
    }

    isMsgFile(file : File) : boolean {
        let fileType = '*' + file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        return fileType == '*.msg';
    }

    isEmlFile(file : File) : boolean {
        let fileType = '*' + file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        return fileType == '*.eml';
    }

    /*
     * Only gets the subject field for now.
     * Can be updated to parse the rest of the fields
     */
    convertTextToEmailData(text: string) : EmailData {
        let emailData = new EmailData();
        let emailLines = text.split('\n');
        emailData.subject = this.getEmailDataByKey('Subject', emailLines);
        return emailData;
    }

    getEmailDataByKey(key: string, emailLines: string[]) : string {
        let data = '';
        for(let line of emailLines){
            if(line.startsWith(key)){
                let lineParts: string[] = line.split(':');
                if(lineParts && lineParts.length > 1){
                    return lineParts[1];
                }
            }
        }
        return data;
    }
}
