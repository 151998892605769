import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetAddress} from './teranet-address';
import * as _ from 'lodash';

export class TeranetPinInfo extends BaseEntity {
    pin: string;
    registrationType: string;
    registrationNumber: string;
    statusInd: string;
    legalDesc: string;
    address: TeranetAddress;

    purchasePin: boolean;
    includeDeletedInstruments: boolean = true;//by default it will be true

    constructor(t?: TeranetPinInfo) {
        super(t);
        if(t) {
            if(t.address) {
               this.address = new TeranetAddress(t.address);
            }
        }
    }

    get fullAddress(): string {
        if(!this.address || this.address.isAddressEmpty()) {
            return 'N/A';
        } else {
            return  _.toLower(this.address.getFullAddress().trim()).split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
        }
    }

    get statusIndForDisplay(): string {
        return this.isActive ? "Active" : "Inactive";
    }

    get isActive(): boolean{
        return this.statusInd === 'A';
    }

    get registrationTypeForDisplay(): string {
        return this.registrationType === 'D' ? 'PDFR' : this.registrationType;
    }
}
