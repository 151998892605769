import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomeComponent} from '../home';

/**
 * Home Module
 */
@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        HomeComponent
    ],
    exports: [

    ],
    providers: [
    ]
})
export class HomeModule { }


