import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class ChicagoLawFirmAddress extends BaseEntity{
    address1: string;
    address2: string;
    province: string;
    city: string;
    postalCode: string;

    constructor(c?: ChicagoLawFirmAddress) {
        super(c);
    }
}
