import {Injectable} from '@angular/core';

@Injectable()
export class FeatureService {

    private _featureFlags = {};

    public setFeatureFlags(value) {
        this._featureFlags = value;
    }

    get isSSOEnable(): boolean {
        return this._featureFlags && this._featureFlags['SINGLE_SIGN_ON'];
    }

    public disableSSOWhenFail() {
        this._featureFlags['SINGLE_SIGN_ON'] = false;
    }
}
