import {BaseEntity} from '../../../../../shared/BaseEntity/base-entity';
import {Address} from '../../../../shared/address';
import {SalePriceAdjustment} from '../../../../statement-adjustment/sale-price-adjustment/sale-price-adjustment';
import {Matter} from '../../../../shared/matter';
import {MatterParticipant} from '../../../../shared/matter-participant';
import {MatterProperty} from '../../../../shared/matter-property';

export type NewHomeSalePriceSourceType = 'SALE_PRICE_AS_IN_STATEMENT_OF_ADJUSTMENT' | 'SALE_PRICE_AS_IN_TAB_D' | 'MANUALLY_ENTERED';

export class NewHomeSalePriceSourceTypes {
    public static readonly SALE_PRICE_AS_IN_STATEMENT_OF_ADJUSTMENT : NewHomeSalePriceSourceType = "SALE_PRICE_AS_IN_STATEMENT_OF_ADJUSTMENT";
    public static readonly SALE_PRICE_AS_IN_TAB_D : NewHomeSalePriceSourceType = "SALE_PRICE_AS_IN_TAB_D";
    public static readonly MANUALLY_ENTERED : NewHomeSalePriceSourceType = "MANUALLY_ENTERED";
}

export type LessAmountSourceType = 'CHATTEL_AMOUNT_AS_IN_STATEMENT_OF_ADJUSTMENT' | 'MANUALLY_ENTERED_VALUE_OF_FURNITURE_AND_APPLIANCES';

export class LessAmountSourceTypes {
    public static readonly CHATTEL_AMOUNT_AS_IN_STATEMENT_OF_ADJUSTMENT : LessAmountSourceType = "CHATTEL_AMOUNT_AS_IN_STATEMENT_OF_ADJUSTMENT";
    public static readonly MANUALLY_ENTERED_VALUE_OF_FURNITURE_AND_APPLIANCES : LessAmountSourceType = "MANUALLY_ENTERED_VALUE_OF_FURNITURE_AND_APPLIANCES";
}

export type BuilderType = 'SAME_AS_VENDOR' | 'MANUALLY_ENTERED';

export class BuilderTypes {
    public static readonly SAME_AS_VENDOR : BuilderType = "SAME_AS_VENDOR";
    public static readonly MANUALLY_ENTERED : BuilderType = "MANUALLY_ENTERED";
}

export type RebateAssignmentType = 'THE_BUILDER' | 'A_SINGLE_PURCHASER';

export class RebateAssignmentTypes {
    public static readonly THE_BUILDER : RebateAssignmentType = "THE_BUILDER";
    public static readonly A_SINGLE_PURCHASER : RebateAssignmentType = "A_SINGLE_PURCHASER";
}

export class NewHomePurchaser extends  BaseEntity{
    purchaserId : number;

    purchaserName : string;

    purchaserAddress : Address;

    purchaserEmail : string;

    purchaserPhone : string;

    primary : boolean;

    constructor( newHomePurchaser?: NewHomePurchaser) {
        super(newHomePurchaser);
        if(newHomePurchaser) {
            if(newHomePurchaser.purchaserAddress) {
                this.purchaserAddress = new Address(newHomePurchaser.purchaserAddress);
            }
        }
    }
}

export const PST_REBATE_TIER1_LIMIT = 350000;
export const PST_REBATE_TIER2_LIMIT = 450000;
export const PST_REBATE_PURPOSES_RATE1 = 0.06;
export const PST_REBATE_PURPOSES_RATE2 = 0.42;
export const PST_REBATE_AMOUNT_TIER1_MAX = 8820;
export const PST_REBATE_AMOUNT_RANGE_BETWEEN_TIER1_AND_TIER2 = 100000;

export const NewHomePstRebateFormDropDown = {
    LessAmountSourceOptions : [
        {label: 'Less: Chattel amount as entered in the Statement of Adjustments*', value: LessAmountSourceTypes.CHATTEL_AMOUNT_AS_IN_STATEMENT_OF_ADJUSTMENT},
        {label: 'Less: Manually enter value of furniture, furnishing and appliances*', value: LessAmountSourceTypes.MANUALLY_ENTERED_VALUE_OF_FURNITURE_AND_APPLIANCES},
    ],
    rebateAssignmentOptions : [
        {label: 'the Builder', value: RebateAssignmentTypes.THE_BUILDER},
        {label: 'a single Purchaser', value: RebateAssignmentTypes.A_SINGLE_PURCHASER},
    ],
}

export class BuilderInfo extends  BaseEntity {
    builderType: BuilderType;

    builderName: string;

    builderPrimaryContact: string;

    builderAddress: Address;

    builderEmail: string;

    builderPhone: string;

    builderPstVendorNumber: string;

    constructor( builderInfo?: BuilderInfo) {
        super(builderInfo);
        if(builderInfo) {
            if(builderInfo.builderAddress) {
                this.builderAddress = new Address(builderInfo.builderAddress);
            }
        }
    }
}

export class NewHomePstRebateForm  extends  BaseEntity {

    id : number;

    //Contract price for a newly-constructed home, before taxes
    newHomeSalePriceSource: NewHomeSalePriceSourceType;

    newHomeSalePrice: number;//field paired with newHomeSalePriceSource

    //Less: value of land ::involved in calculation of bare construction price
    valueOfLand: number;

    //Other Less Amount Type ::involved in calculation of bare construction price
    lessAmountSource: LessAmountSourceType;

    lessAmount: number; //field paired with lessAmountSource

    totalPriceForPstRebate: number;

    pstRebateAmount: number;

    //Builder Information Section

    builderInfo: BuilderInfo;

    //Assignment of Rebate Section

    rebateAssignmentType: RebateAssignmentType;

    //The following two attributes only applicable if the rebateAssignmentType = 'THE_BUILDER"
    //optional checkbox: Check if builder is offsetting the rebate amount against the PST to be reported on their PST return
    builderOffsetRebateAmount: boolean;

    //Builder's authorized signing officer
    builderAuthorizedSigningOfficer: string;

    //Builder's Selected a Purchaser
    purchaserId: number;

    //Purchaser Information Section:
    newHomePurchasers: NewHomePurchaser[];

    //Other Section
    dataEntryDate: string;

    constructor( newHomePstRebateForm?: NewHomePstRebateForm) {
        super(newHomePstRebateForm);
        if(newHomePstRebateForm) {
            if(newHomePstRebateForm.builderInfo) {
                this.builderInfo = new BuilderInfo(newHomePstRebateForm.builderInfo);
            }
            this.newHomePurchasers = [];
            if(Array.isArray(newHomePstRebateForm.newHomePurchasers)) {
                for(let i : number = 0; i < newHomePstRebateForm.newHomePurchasers.length; i++) {
                    this.newHomePurchasers[i] = new NewHomePurchaser(newHomePstRebateForm.newHomePurchasers[i]);
                }
            }
        }else {
            this.builderInfo = new BuilderInfo();
            this.newHomePurchasers = [];
        }

    }

    getTotalPriceForPstRebate(): number {
        this.totalPriceForPstRebate
            = this.newHomeSalePrice - this.valueOfLand - this.lessAmount;
        if(this.totalPriceForPstRebate > PST_REBATE_TIER2_LIMIT){
            this.pstRebateAmount = null;
        }
        return this.totalPriceForPstRebate;
    }

    getPstRebateAmount(matter: Matter): number{
        if(this.totalPriceForPstRebate <= PST_REBATE_TIER1_LIMIT){
            //FORMULA: 'Total price for PST rebate purposes' * value of PST% from SoAdj) * 42%
            const pstRateFromSoAdj: number = (matter == null ? 0 : matter.soaProvincialHst);
            this.pstRebateAmount = this.totalPriceForPstRebate * (pstRateFromSoAdj/100) * PST_REBATE_PURPOSES_RATE2;
        } else if(this.totalPriceForPstRebate == PST_REBATE_TIER2_LIMIT){
            this.pstRebateAmount = 0;
        } else if(this.totalPriceForPstRebate > PST_REBATE_TIER2_LIMIT){
            this.pstRebateAmount = null;
        } else { //between 350,000 and 450,000 are excluded
            this.pstRebateAmount = (PST_REBATE_TIER2_LIMIT - this.totalPriceForPstRebate)/PST_REBATE_AMOUNT_RANGE_BETWEEN_TIER1_AND_TIER2 * PST_REBATE_AMOUNT_TIER1_MAX
        }

        if(this.totalPriceForPstRebate <= PST_REBATE_TIER2_LIMIT){
            this.pstRebateAmount = Math.round(this.pstRebateAmount * 100)/100;
        }
        return this.pstRebateAmount;
    }

    getCreditVendorNetSalePriceByMatter(matter: Matter): number{
        let salePriceAdjustment: SalePriceAdjustment
            = this.getSalePriceAdjustment(matter);
        return this.getCreditVendorNetSalePrice(salePriceAdjustment, matter.soaFederalHst, matter.soaProvincialHst);
    }

    getCreditVendorNetSalePrice(salePriceAdjustment: SalePriceAdjustment, soaFederalHst: number, soaProvincialHst: number): number{
        let creditVendorNetSalePrice: number;
        if(salePriceAdjustment){
            creditVendorNetSalePrice = salePriceAdjustment.isSalePriceFormatNSP()
                ? salePriceAdjustment.totalNetSalePrice(soaFederalHst, soaProvincialHst)
                : salePriceAdjustment.agreementSalePrice;
        } else {
            creditVendorNetSalePrice = 0;
        }
        return creditVendorNetSalePrice;
    }

    getSalePriceAdjustment(matter: Matter): SalePriceAdjustment{
        return matter && matter.considerationLtt && matter.considerationLtt.salePriceAdjustment;
    }

    updateNewHomePstRebateForm( matterPropertyWithCondo: MatterProperty, salePriceAdjustment: SalePriceAdjustment, soaFederalHst: number, soaProvincialHst: number, matter: Matter){

        if(salePriceAdjustment){

                //Update newHomeSalePrice
            switch (this.newHomeSalePriceSource) {
                case NewHomeSalePriceSourceTypes.SALE_PRICE_AS_IN_STATEMENT_OF_ADJUSTMENT:
                    if(salePriceAdjustment.isInclusivePrice()){
                       this.newHomeSalePrice = this.getCreditVendorNetSalePrice(salePriceAdjustment, soaFederalHst, soaProvincialHst);
                    } else if(salePriceAdjustment.isNotInclusivePrice){
                       this.newHomeSalePrice
                            = matterPropertyWithCondo? matterPropertyWithCondo.soaPurchasePrice : 0;
                    }
                    break;
                case NewHomeSalePriceSourceTypes.SALE_PRICE_AS_IN_TAB_D:
                   this.newHomeSalePrice
                        = matterPropertyWithCondo? matterPropertyWithCondo.purchasePrice : 0;
                    break;
                default:
                    break;
            }
        }

        //update priceOfLand
        if(salePriceAdjustment && salePriceAdjustment.isNotInclusivePrice()){
            this.valueOfLand = salePriceAdjustment.priceOfLand;
        }

        // Update lessAmount
        if(this.lessAmountSource == LessAmountSourceTypes.CHATTEL_AMOUNT_AS_IN_STATEMENT_OF_ADJUSTMENT) {
            this.lessAmount = salePriceAdjustment.chattelsIncludedInSalePrice;
        }

        // Update totalPriceForPstRebate
        this.getTotalPriceForPstRebate();

        // Update pstRebateAmount
        this.getPstRebateAmount(matter)
    }

    cleanUpBuilderTypeFields(vendorsNumber: Number, primaryVendorParticipant : MatterParticipant) {
        if(this.builderInfo.builderType == BuilderTypes.SAME_AS_VENDOR) {
            if(primaryVendorParticipant){
                this.updateBuilderTypeFieldsWithPrimaryVendor(vendorsNumber, primaryVendorParticipant);
            }else {
                this.builderInfo.builderName = null;
                this.builderInfo.builderAddress = new Address();

                this.builderInfo.builderEmail = null;

                this.builderInfo.builderPhone = null;
            }
        }
    }

    updateBuilderTypeFieldsWithPrimaryVendor(vendorsNumber: Number, primaryVendorParticipant : MatterParticipant){
        if(this.builderInfo.builderType == BuilderTypes.SAME_AS_VENDOR){
            //'Builder Name' field will be populated, depending on the Gender of the Vendor when only one Vendor
            if( 1 == vendorsNumber && primaryVendorParticipant && primaryVendorParticipant.contact) {
                switch(primaryVendorParticipant.contact.gender) {
                    case 'MALE':
                    case 'FEMALE':
                    case 'QUESTION':
                        break;
                    case 'MALEPOA':
                    case 'FEMALEPOA':
                        this.builderInfo.builderName = primaryVendorParticipant.contact.surnameLastFullName;
                        break;
                    case 'ESTATE':
                        this.builderInfo.builderName = 'Estate of ' + primaryVendorParticipant.contact.surnameLastFullName;
                        break;
                    case 'CORPORATION':
                    case 'OTHERENTITY':
                        this.builderInfo.builderName = primaryVendorParticipant.contact.organizationName;
                        break;
                    default:
                        this.builderInfo.builderName = null;
                        break;
                }
            } else {
                this.builderInfo.builderName = null;
            }

            if( primaryVendorParticipant && primaryVendorParticipant.contact) {
                //builderPrimaryContact
                switch(primaryVendorParticipant.contact.gender) {
                    case 'MALE':
                    case 'FEMALE':
                    case 'QUESTION':
                        this.builderInfo.builderPrimaryContact = primaryVendorParticipant.contact.surnameLastFullName;
                        break;
                    case 'MALEPOA':
                    case 'FEMALEPOA':
                        this.builderInfo.builderPrimaryContact = 'Attorney 1';
                        break;
                    case 'ESTATE':
                        this.builderInfo.builderPrimaryContact = 'Trustee 1';
                        break;
                    case 'CORPORATION':
                    case 'OTHERENTITY':
                        this.builderInfo.builderPrimaryContact = 'Signing Officer 1 ';
                        break;
                    default:
                        this.builderInfo.builderPrimaryContact = null;
                        break;
                }

                this.builderInfo.builderAddress = primaryVendorParticipant.contact.primaryAddress;

                this.builderInfo.builderEmail = primaryVendorParticipant.contact.email;

                this.builderInfo.builderPhone = primaryVendorParticipant.contact.workPhone;
            }

        }
    }

    updatePurchaserInformationSection(purchasers: MatterParticipant[]) {
        //Use matterParticipantId because contact id is null before save matter
        let selectedPurchaser: MatterParticipant;
        if(purchasers && purchasers.length > 0){
            selectedPurchaser = purchasers.find(item=>item.matterParticipantId == this.newHomePurchasers[0].purchaserId);
        }
        this.updatePurchaserInforByPurchaserContact(selectedPurchaser);
    }

    updatePurchaserInforByPurchaserContact(purchaser: MatterParticipant) {
        if(purchaser && purchaser.contact){
            this.newHomePurchasers[0].purchaserId = purchaser.matterParticipantId;
            this.newHomePurchasers[0].purchaserAddress = new Address(purchaser.contact.primaryAddress);
            this.newHomePurchasers[0].purchaserEmail = purchaser.contact.email;
            this.newHomePurchasers[0].purchaserPhone = purchaser.contact.workPhone;
        }else {
            this.newHomePurchasers[0].purchaserId = null;
            this.newHomePurchasers[0].purchaserAddress = new Address();
            this.newHomePurchasers[0].purchaserEmail = null;
            this.newHomePurchasers[0].purchaserPhone = null;
        }
    }
}
