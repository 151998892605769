export class ApplicationLog {

    constructor(applicationLog?: ApplicationLog) {

        if (applicationLog) {
            for (let prop in applicationLog) {
                if (applicationLog.hasOwnProperty(prop)) {
                    this[prop] = applicationLog[prop];
                }
            }
            // this.fieldErrors = [];
            // if(applicationLog.fieldErrors){
            //     for (let i: number = 0; i < applicationLog.fieldErrors.length; i++) {
            //         this.fieldErrors[i] = new FieldError(applicationLog.fieldErrors[i]);
            //     }
            // }

        }

    }

    // fieldErrors: FieldError[];

    // just for the legacy codes that rely on the previously returened errorMessage:
    summary: string;
    message: string;
    errorCode: string;
    // location: string;
    accountId: string;
    userId: string;
    stack: string;
    status: string;
    statusText: string;
    url: string;
    browserInfo: string;

    // get errorMessage(): string {
    //     let errorCode = this.message ? this.message + ` [${this.errorCode}]` : this.errorCode;
    //     return this.errorCode ? errorCode : this.summary;
    // }
}

// export class FieldError {
//
//     constructor(fieldError?: FieldError) {
//
//         if (fieldError) {
//             for (let prop in fieldError) {
//                 if (fieldError.hasOwnProperty(prop)) {
//                     this[prop] = fieldError[prop];
//                 }
//             }
//         }
//     }
//
//     errorCode: string;
//
//     message: string;
//
//     field: string;
// }
