import {Injectable} from '@angular/core';
import {HttpClient} from '../../../core/httpClient.service';
import {Matter} from '../../shared/matter';
import {MatterType} from '../../shared/index';
import {matterApi} from '../../shared/matter-api';
import {Observable} from 'rxjs';
import {ERegistrationForm} from './eregistrationform';
import {DocumentIdentificationData} from '../../purchaser/print-id/document-identification-data';
import {GovermentSiteLink} from './goverment-site-link';
import {DocumentTemplate} from '../../document-production/document-template';
import {FormTemplate} from './form-template';
import {ProvinceCode} from '../../../admin/accounts/shared/account-province';
import {TprValidationResponse} from './tpr-validation-response';
import {Document} from '../../document-production/document';
import {tprTemplatesApi} from '../../../doc-services/manage-tpr-templates/tpr-templates-api';
import {MatterService} from '../../matter.service';
import {SESSION_STORAGE_KEYS} from '../../../shared';
import {ERegistrationFormBulkUpdate} from './eregistration-form-bulk-update';

@Injectable()
export class ERegistrationService {


    constructor(private http : HttpClient,
                public matterService : MatterService) {

    }

    downloadERegXML(matter: Matter, eRegForm: ERegistrationForm, mortgageId: number): Observable<any> {

        let url: string;
        if (eRegForm.isMortgage())
        {
            url = matterApi.downloadERegMortgageXML.replace('{matterId}', '' + matter.id).replace('{mortgageId}', ''+ mortgageId);
        }
        else if(eRegForm.isDischarge()){
            url = matterApi.downloadERegDischargeXML.replace('{matterId}', '' + matter.id).replace('{existingMortgageId}', ''+ mortgageId);
        }
        else{
            url = matterApi.downloadERegXML.replace('{matterId}', '' + matter.id);
        }

        let modifiedMatter = new Matter(matter);
        modifiedMatter.clearCircularReferencesBeforeStringify()

        return this.http.post(url, modifiedMatter)
                   .map((response) => {
                       return response;
                   });
    }


    openERegXML(matter: Matter , eReg: ERegistrationForm) : Observable<string> {
        let url: string = matterApi.getERegXML.replace('{matterId}', '' + matter.id);
        url = url.replace('{eRegFormId}', '' + eReg.id);
        return this.http.get(url)
            .map((response) => {
                return response['ERegXML'];
            });
    }

    generateIdentificationForm(matterId: number, eRegFormIndex: number, docTemplateName: string, mortgageIndex: number): Observable<any> {
        let url: string = matterApi.produceDocumentSingleTypeAsync
            .replace('{matterId}', matterId.toString())
            .replace('{templateName}', docTemplateName)
            .replace('{eRegFormIndex}', (eRegFormIndex != null ? eRegFormIndex.toString() : '-1'))
            .replace('{mortgageIndex}', (mortgageIndex != null ? mortgageIndex.toString() : '-1'));

        return this.http.get(url)
                   .map((response) => {
                       if (response) {
                           return new DocumentIdentificationData(response['DocumentIdentificationData']);
                       }
                   });
    }

    getGovermentSiteLink(matterType : MatterType, provinceCode?: ProvinceCode ) : Observable<GovermentSiteLink[]> {
        let url:string = `${matterApi.getGovernmentSiteLinks}?matterType=${matterType}`;
        if(provinceCode){
            url += `&provinceCode=${provinceCode}`;
        }
        return this.http.get(url)
                   .map((response ) => {
                       if(response) {
                           let govermentSiteLinks: GovermentSiteLink[] = [];
                           response['GovermentSiteLink'].forEach(govermentSiteLink => {
                               govermentSiteLinks.push(new GovermentSiteLink(govermentSiteLink));
                           });
                           return govermentSiteLinks;
                       }
                   });
    }

    // Get status for document Id corresponding to Identification Form
    getIdentificationFormsStatus(matterId: number, documentId : number){
        let url:string = matterApi.generateIdentificationForm.replace('{matterId}', ''+matterId);
        url = url + "/"+documentId+"/status";
        return this.http.get(
            `${url}`)
                   .map((response) => {
                       return response['DocumentStatus'];
                   });
    }

    getSystemTemplates() : Observable<DocumentTemplate[]> {
        let url:string = `${matterApi.systemTemplates}`;
        return this.http.get(url)
                   .map((response ) => {
                       if(response) {
                           return response['Templates'].map((doc)=>{
                               return new DocumentTemplate(doc);
                           });
                       }
                   });
    }

    getFormTemplates( categories: string, matterType: MatterType, provinceCode: ProvinceCode): Observable<FormTemplate[]>{
        let url: string = matterApi.formTemplates.replace('{category}', categories).replace('{matterType}', matterType).replace('{provinceCode}', provinceCode);
        return this.http.get(url)
            .map((response) => {
                if (response) {
                    return response['FormTemplates']
                           .map((doc) => {
                                return new FormTemplate(doc);
                            });
                }
            });
    }


    validateTPRTransfer( matter: Matter, formType: string, mortgageId?: number): Observable<TprValidationResponse>{
        let modifiedMatter = new Matter(matter);
        modifiedMatter.clearCircularReferencesBeforeStringify();
        this.matterService.clearExistingMortgageMapping(modifiedMatter); // we need existing mortgages
        let url: string = matterApi.validateTPRForm
                            .replace('{matterId}', modifiedMatter.id.toString())
                            .replace('{eFormType}', formType);
        if(mortgageId){
            url += `?mortgageId=${mortgageId}`
        }
        return this.http.post(url, JSON.stringify(modifiedMatter))
            .map((res) => {
                return new TprValidationResponse(res['TPRValidationResponse']);
            });
    }

    produceTPRTransfer( matterId: string, formType: string, mortgageId?: number){
        let url: string = matterApi.produceTPRForm
                            .replace('{matterId}', matterId)
                            .replace('{eFormType}', formType);
        if(mortgageId){
            url += `?mortgageId=${mortgageId}`
        }
        return this.http.post(url, null)
            .map((response) => {
                if (response) {
                    return new Document(response['TprDocument']) ;

                }
            });
    }

    deleteTPRForm( matterId: number, eformId: number){
        let url: string = matterApi.deleteTPRForm
                            .replace('{matterId}', matterId.toString())
                            .replace('{eFormId}', eformId.toString());
        return this.http.delete(url)
            .map((response) => {
                return response;
            });
    }

    getTPRContactUrl(): Observable<string> {
        let url: string = tprTemplatesApi.contactUrl;
        return this.http.get(url)
                   .map((res) => {
                       return res['TPRContactUrl'];
                   });
    }

    getTPRLink(): Observable<string> {
        let url: string = tprTemplatesApi.tprLink;
        return this.http.get(url)
                   .map((res) => {
                       return res['TPRLinkUrl'];
                   });
    }

    getERegUrl(matterId: number, eRegForm: ERegistrationForm) : string {
        return this.getERegUrlForFile(matterId , eRegForm.id , eRegForm.eRegistrationFileName);

    }

    getERegUrlForFile(matterId: number, eRegFormId : number , fileName : string) : string {
        let documentUrl: string = '';
        if(eRegFormId > 0 && matterId > 0) {
            let loc = window.location;
            let url : string = matterApi.openERegXML.replace('{matterId}', '' + matterId);
            url = url.replace('{eRegFormId}', '' + eRegFormId);
            url = url.replace('{fileName}', '' + encodeURIComponent(fileName));
            url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
            documentUrl = loc.protocol + "//" + loc.host + url;
        }
        return documentUrl;
    }

    setBulkStatus(matterId: number, erfbu: ERegistrationFormBulkUpdate): Observable<number>{

        let url: string = matterApi.setERegistrationBulkStatusUpdate.replace('{matterId}', matterId.toString())
            ;

        return this.http.post(url,  JSON.stringify(erfbu))
            .map((res) => {
                return res['ERegistration'];
            });
    }



}
