import {Component, Input, OnInit} from '@angular/core';
import {DocumentUtilityService} from '../../../document-production/document-utility.service';
import {CustomKeyCodesEnum} from '../../../../common/key-code-enum';
import {UnityConnectDocument} from '../../../shared/unity-connect-document';
import {OpportunitiesService} from '../../../../opportunities/opportunities.service';

declare var jQuery: any;

@Component({
               selector    : 'dp-unity-connect-import-attachment',
               templateUrl : 'unity-connect-import-attachment.component.html',


           })
export class UnityConnectImportAttachmentComponent implements OnInit {
    @Input() documents: UnityConnectDocument[];
    @Input() selectedDocuments: number [];
    @Input() sourceTransactionId: number; //This can be the ReferralId or CirfId if needed
    @Input() hideCheckBoxes: boolean;
    @Input() isDownoadDocumentsAllowed: boolean;

    checkBoxAllTemplate: boolean = false;

    constructor(public documentUtility: DocumentUtilityService,
                public opportunitiesService: OpportunitiesService) {
    }

    ngOnInit() {
        // console.log('CirfImportAttachmentComponent');
    }

    get rows(): UnityConnectDocument[] {
        if (this.documents.length > 0) {
            return this.documents;
        }
        return [];
    }

    selectAllTemplate(): void {
        if (this.documents && this.checkBoxAllTemplate) {
            while (this.selectedDocuments.length > 0) {
                this.selectedDocuments.pop();
            }
            this.documents.forEach(template => {
                if (!template.isInfected && !template.isVirusScanPending) {
                        this.selectedDocuments.push(template.id);
                }
            });

        } else {
            while (this.selectedDocuments.length > 0) {
                this.selectedDocuments.pop();
            }
        }
    }

    isCheckBoxAllTemplateReadOnly() : boolean{
        if(this.documents ){
            return !this.documents.some(item => (!item.isInfected && !item.isVirusScanPending));
        }
    }

    isDocumentSelected(document: UnityConnectDocument): boolean {
        let selectedIndex = this.selectedDocuments.findIndex(item => item == document.id);
        return selectedIndex != -1;
    }

    selectDocument(document: UnityConnectDocument, event): void {

        if (!this.isDocumentSelected(document)) {
            this.selectedDocuments.push(document.id);
        } else {
            (<any>this.selectedDocuments).remove(this.selectedDocuments.find(item => {
                return item == document.id;
            }));

        }
    }

    tableKeyCommands(event): void {

        let charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));

        if (charCode === CustomKeyCodesEnum.Down) {
            event.preventDefault();

            this.keyCommandtoHiliteNextMatter(event);
        }
        if (charCode === CustomKeyCodesEnum.Up) {
            event.preventDefault();

            // hilite prev matter with up arrow
            this.keyCommandtoHilitePreviousMatter(event);
        }
    }

    keyCommandtoHiliteNextMatter(event) {

        if (document.activeElement.className === "toggleBurger") {
            jQuery(event.target).closest('tr').next('tr').focus();
        } else {
            jQuery(document.activeElement).next('tr').focus();
        }
    }

    keyCommandtoHilitePreviousMatter(event) {

        if (document.activeElement.className === "toggleBurger") {
            jQuery(event.target).closest('tr').prev('tr').focus();
        } else {
            jQuery(document.activeElement).prev('tr').focus();
        }
    }

    downloadDocument(row: UnityConnectDocument) {
        if(this.sourceTransactionId && this.isDownoadDocumentsAllowed){
            this.opportunitiesService.downloadReferralDocument(this.sourceTransactionId, row.id);
        }
    }
}
