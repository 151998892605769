import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '../../core';
import {LockScreenService} from '../../core/lock-screen.service';
import {ErrorService} from '../../shared/error-handling/error-service';
import {Logger} from '@nsalaun/ng-logger';
import {altoApi} from './alto-constants';
import {AltoEFormRequest} from './alto-eform-request';
import {AltoEFormResponse} from './alto-eform-response';
import {AltoEFormDocumentType} from './alto-eform-document-type';


@Injectable()
export class ALTOService {

    constructor(private http: HttpClient,
                private lockScreenService: LockScreenService,
                private errorService: ErrorService,
                private logger: Logger) {
    }

    createEForm(matterId: number, eFormRequest: AltoEFormRequest[]): Observable<AltoEFormResponse[]> {
        let url: string = altoApi.createEForm.replace('{matterId}', matterId.toString());

        return this.http.post(url, JSON.stringify(eFormRequest))
            .map((res: AltoEFormResponse[]) => {
                let result: AltoEFormResponse[] = [];
                let arrayResp = res['AltoCreateEForm'];
                if(Array.isArray(arrayResp)){
                    arrayResp.forEach( ar => result.push(new AltoEFormResponse(ar)));
                }
                return result;
            });
    }

    deleteEForms(matterId: number, eFormRequest: AltoEFormRequest[]): Observable<AltoEFormResponse[]> {
        let url: string = altoApi.deleteEForm.replace('{matterId}', matterId.toString());

        return this.http.post(url, JSON.stringify(eFormRequest))
            .map((res: AltoEFormResponse[]) => {
                let result: AltoEFormResponse[] = [];
                let arrayResp = res['AltoDeleteEForm'];
                if(Array.isArray(arrayResp)){
                    arrayResp.forEach( ar => result.push(new AltoEFormResponse(ar)));
                }
                return result;
            });
    }

    getAltoDocumentTypes(): Observable<AltoEFormDocumentType[]> {
        let url: string = altoApi.documentTypes;

        return this.http.get(url)
            .map((res) => {
                let result: AltoEFormDocumentType[] = [];
                let arrayResp = res['AltoDocumentTypes'];
                if (Array.isArray(arrayResp)) {
                    arrayResp.forEach(ar => result.push(new AltoEFormDocumentType(ar)));
                }
                return result;
            });
    }

    getAltoContactUrl(): Observable<string> {
        let url: string = altoApi.contactUrl;
        return this.http.get(url)
            .map((res) => {
                return res['AltoContactUrl'];
            });
    }
}
