import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class EsiLawAccountExplanation extends BaseEntity{

    id : number;
    statementConfigurationId : number;
    esiLawAccountCodeId : number;
    accountNumber : string;
    code : string;
    explanation : string;
    sum : string;
    status : string;


    constructor(esiLawAccountExplnation? : EsiLawAccountExplanation) {
        super(esiLawAccountExplnation);
    }
}
