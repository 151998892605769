import {HttpClient} from '../core/httpClient.service';
import {Injectable} from '@angular/core';
import {api, SESSION_ID_REQ_PARAM} from '../common/api';
import {Observable} from 'rxjs/index';
import {SoaExportConfig} from '../matters/shared/soa-export-config';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';


@Injectable()
export class AccountingExportService {

    constructor(private http: HttpClient) { }


    getAccountingExportData(accountId: string): Observable<SoaExportConfig[]> {
        const url = `${api}/accounts/${accountId}/soaExportConfigurations`;
        let soaExportConfigArray : SoaExportConfig[]= [];
        return this.http.get(url)
            .map((res) => {
                return (res['SoaExportConfig']).map((item) => {
                    return new SoaExportConfig(item);
                });
            });
    }

    downloadAccountingExportData(accountId: string,isEsiLawFileFormat: boolean) {
        let url:string = `${api}/accounts/${accountId}/soaExport/download?${SESSION_ID_REQ_PARAM}={sessionId}&isEsiLawFileFormat=`+isEsiLawFileFormat;
        url = url.replace('{sessionId}', ''+sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
        var windowObject = window.open(url, "_blank");
    }






}
