import {ConsiderationTaxes} from './consideration-taxes';
import {SalePriceAdjustment} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment';
import Utils from '../../shared-main/utils';
import {SalePriceAdjustmentFactory} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment-factory';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {StatementAdjustmentUtil} from '../statement-adjustment/statement-adjustment-util';
import {StatementAdjustment} from '../statement-adjustment/statement-adjustment';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TaxRateTierTypes} from "../../shared-main/province-based-dropdowns";

export type InTableBelowType = 'CALCULATE_F_TOTALLING_A_THOUGH_E' | 'AMOUNT_IN_A_EQUALS_AMOUNT_F' | 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F' | 'DEFAULT';
export type CompletionOfTaxInfoType = 'LINE_A_EQUAL_NET_SALE_PRICE' | 'HIDDEN_TAX_AND_SET_AMOUNT_AS_2' | 'COMPLETE_TAX_INFO_MANUALLY' | 'HIDDEN_TAX_AND_USE_NET_SALE_PRICE'| 'DEFAULT';

export const TERAVIEW_AMOUNT_AS_TWO = 2

export class ConsiderationLtt extends BaseEntity{


    id : number;
    lttAffidavitExecutedBy : string;
    lttRateBasedOnType : string;
    valueOfSingleFamilyResidence : number = 0;
    remainderOfLandUsedFor : string;
    inTableBelowType : InTableBelowType;
    moniesPaidOrToBePaidIn : number = 0;
    mortgagesAssumed : number = 0;
    mortgagesGivenBackToVendor : number = 0;
    propertyTransferredInExchange : number = 0;
    otherConsiderationSubjectToTax : number = 0;
    fairMarketValueOfLands : number = 0;
    valueSubjectToLttAToE1 : number = 0;
    valueSubjectToLttAToE2 : number = 0;
    valueOfAllChattles : number = 0;
    otherConsiderationNotInFOrG : number = 0;
    totalConsideration : number = 0;
    applyCityOfTorontoLandTransferTax : string;
    lttRefundEntitlementOntario : string;
    lttRefundEntitlementToronto : string;
    percentageClaimed : string;
    ontarioLandTransferTax : number = 0;
    ontarioNetLttPayable : number = 0;
    totalOntarioTorontoLttPayable : number = 0;
    torontoLandTransferTax : number = 0;
    torontoLessLttRefund : number = 0;
    torontoNetLttPayable : number = 0;
    ontarioLessLttRefund : number = 0;
    isMatterParticipantSpouseSelected : boolean;
    purchasePriceType : string;
    netOutHst : string;
    chattelsSoa : number = 0;
    considerationSoa : number = 0;
    purchaserCredit : number = 0;
    salePriceAdjustment : SalePriceAdjustment;
    applyNrst : string;
    nrstAmount : number = 0;
    nrstReceiptNo : string;

    completionOfTaxInfoType: CompletionOfTaxInfoType;

    needToUpdateValueOfAllChattles: boolean = false;//UI used only

    constructor(adjustmentMode : string, considerationLtt? : ConsiderationLtt, provinceCode? : ProvinceCode) {
        super(considerationLtt);
        if(considerationLtt) {
            //we need to do it again as default value overwrite the super set values https://github.com/microsoft/TypeScript/issues/6110
            for(let prop in considerationLtt) {
                if(considerationLtt.hasOwnProperty(prop)) {
                    this[prop] = considerationLtt[prop];
                }
            }
            this.salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(adjustmentMode, provinceCode, considerationLtt.salePriceAdjustment);
        }
        else {
            this.salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(adjustmentMode ,provinceCode, null);
        }

    }

    isTableValueAmountInAEqualsAmountF() : boolean {
        return (this.inTableBelowType === 'AMOUNT_IN_A_EQUALS_AMOUNT_F')
    }

    calculateSubjectValueAndTotalConsideration(ontarioTaxRateSlab : ConsiderationTaxes, torontoTaxRateSlab : ConsiderationTaxes, netSalePrice? : number) : void {

        if(this.inTableBelowType === 'AMOUNT_IN_A_EQUALS_AMOUNT_F') {

            if(this.moniesPaidOrToBePaidIn != null) {
                this.valueSubjectToLttAToE1 = this.moniesPaidOrToBePaidIn;
                this.valueSubjectToLttAToE2 = this.moniesPaidOrToBePaidIn;
            }
            else {
                this.valueSubjectToLttAToE2 = null;
                this.valueSubjectToLttAToE1 = null;
                this.totalConsideration = null;
            }
            if(this.valueOfAllChattles != null && this.otherConsiderationNotInFOrG != null && this.valueSubjectToLttAToE1 != null) {
                this.totalConsideration = Number(this.valueSubjectToLttAToE1) + Number(this.valueOfAllChattles) + Number(this.otherConsiderationNotInFOrG);
            }
            else {
                this.totalConsideration = null;
            }


        }
        else if(this.inTableBelowType === 'CALCULATE_F_TOTALLING_A_THOUGH_E') {
            if(this.moniesPaidOrToBePaidIn != null && this.mortgagesAssumed != null
                && this.mortgagesGivenBackToVendor != null && this.propertyTransferredInExchange != null
                && this.otherConsiderationSubjectToTax != null && this.fairMarketValueOfLands != null
            ) {
                this.valueSubjectToLttAToE1 = Number(this.moniesPaidOrToBePaidIn) + Number(this.mortgagesAssumed) + Number(this.mortgagesGivenBackToVendor)
                    + Number(this.propertyTransferredInExchange) + Number(this.otherConsiderationSubjectToTax) + Number(this.fairMarketValueOfLands);
                this.valueSubjectToLttAToE2 = this.valueSubjectToLttAToE1;
            }
            else {
                this.valueSubjectToLttAToE2 = null;
                this.valueSubjectToLttAToE1 = null;
                this.totalConsideration = null;
            }
            if(this.valueOfAllChattles != null && this.otherConsiderationNotInFOrG != null && this.valueSubjectToLttAToE1 != null) {
                this.totalConsideration = Number(this.valueSubjectToLttAToE1) + Number(this.valueOfAllChattles) + Number(this.otherConsiderationNotInFOrG);
            } else {
                this.totalConsideration = null;
            }

        }
        else if(this.inTableBelowType === 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F'
            || this.completionOfTaxInfoType === 'LINE_A_EQUAL_NET_SALE_PRICE') {
            if(this.mortgagesAssumed != null
                && this.mortgagesGivenBackToVendor != null && this.propertyTransferredInExchange != null
                && this.otherConsiderationSubjectToTax != null && this.fairMarketValueOfLands != null) {
                if(this.moniesPaidOrToBePaidIn != null) {
                    this.moniesPaidOrToBePaidIn = 0;
                }
                let totalValue = (
                Utils.getNumberOrDefault(this.mortgagesAssumed, 0)
                + Utils.getNumberOrDefault(this.mortgagesGivenBackToVendor, 0)
                + Utils.getNumberOrDefault(this.propertyTransferredInExchange, 0)
                + Utils.getNumberOrDefault(this.otherConsiderationSubjectToTax, 0)
                + Utils.getNumberOrDefault(this.fairMarketValueOfLands, 0));
                // if(Number(this.valueSubjectToLttAToE1) > totalValue && this.moniesPaidOrToBePaidIn != null) {
                //     this.moniesPaidOrToBePaidIn = Number(this.valueSubjectToLttAToE1) - totalValue;
                // }
                // else {
                //     this.valueSubjectToLttAToE1 = Number(totalValue.toFixed(2));
                // }
                //POC from DPPMP-12722, the moniesPaid field value can be negative
                this.moniesPaidOrToBePaidIn = Number(this.valueSubjectToLttAToE1) - totalValue;

                this.valueSubjectToLttAToE2 = this.valueSubjectToLttAToE1;
            }

            else {
                this.valueSubjectToLttAToE2 = null;
                this.valueSubjectToLttAToE1 = null;
                this.totalConsideration = null;
            }
            if(this.valueOfAllChattles != null && this.otherConsiderationNotInFOrG != null && this.valueSubjectToLttAToE1 != null) {
                this.totalConsideration = Number(this.valueSubjectToLttAToE1) + Number(this.valueOfAllChattles) + Number(this.otherConsiderationNotInFOrG);
            } else {
                this.totalConsideration = null;
            }
        }
        else if(this.completionOfTaxInfoType === 'COMPLETE_TAX_INFO_MANUALLY'){
            if(this.moniesPaidOrToBePaidIn != null && this.mortgagesAssumed != null
                && this.mortgagesGivenBackToVendor != null && this.propertyTransferredInExchange != null
                && this.otherConsiderationSubjectToTax != null && this.fairMarketValueOfLands != null) {
                let totalValue = (Utils.getNumberOrDefault(this.moniesPaidOrToBePaidIn, 0)
                    + Utils.getNumberOrDefault(this.mortgagesAssumed, 0)
                    + Utils.getNumberOrDefault(this.mortgagesGivenBackToVendor, 0)
                    + Utils.getNumberOrDefault(this.propertyTransferredInExchange, 0)
                    + Utils.getNumberOrDefault(this.otherConsiderationSubjectToTax, 0)
                    + Utils.getNumberOrDefault(this.fairMarketValueOfLands, 0));
                this.valueSubjectToLttAToE1 = totalValue;
                this.valueSubjectToLttAToE2 = this.valueSubjectToLttAToE1;
            }
            else {
                this.valueSubjectToLttAToE2 = null;
                this.valueSubjectToLttAToE1 = null;
                this.totalConsideration = null;
            }
            if(this.valueOfAllChattles != null && this.otherConsiderationNotInFOrG != null && this.valueSubjectToLttAToE1 != null) {
                this.totalConsideration = Number(this.valueSubjectToLttAToE1) + Number(this.valueOfAllChattles) + Number(this.otherConsiderationNotInFOrG);
            } else {
                this.totalConsideration = null;
            }
        }
        else if(this.completionOfTaxInfoType === 'HIDDEN_TAX_AND_SET_AMOUNT_AS_2'){
            this.moniesPaidOrToBePaidIn = TERAVIEW_AMOUNT_AS_TWO;
            this.valueSubjectToLttAToE2 = TERAVIEW_AMOUNT_AS_TWO;
            this.valueSubjectToLttAToE1 = TERAVIEW_AMOUNT_AS_TWO;
            this.totalConsideration = TERAVIEW_AMOUNT_AS_TWO;
        }
        else if(this.completionOfTaxInfoType === 'HIDDEN_TAX_AND_USE_NET_SALE_PRICE'){
            this.moniesPaidOrToBePaidIn = netSalePrice;
            this.valueSubjectToLttAToE2 = netSalePrice;
            this.valueSubjectToLttAToE1 = netSalePrice;
            this.totalConsideration = netSalePrice;
        }
        this.calculateTaxRebate(ontarioTaxRateSlab, torontoTaxRateSlab);

    }


    calculateTaxRebate(ontarioTaxRateSlab : ConsiderationTaxes, torontoTaxRateSlab : ConsiderationTaxes) : void {

        const salePriceThresholdForTax: number = 200;

        if(ontarioTaxRateSlab && torontoTaxRateSlab) {

            this.resetCalculations();

            if(this.lttRateBasedOnType && (this.lttRateBasedOnType == 'QUESTION' || this.lttRateBasedOnType == 'RESIDENTIAL') && this.valueSubjectToLttAToE1 != null) {
                this.ontarioLandTransferTax = this.valueSubjectToLttAToE1 < salePriceThresholdForTax ? 0 : this.generateOntarioResTaxRebate(this.valueSubjectToLttAToE1, ontarioTaxRateSlab);
                if(this.applyCityOfTorontoLandTransferTax == 'YES') {
                    this.torontoLandTransferTax = this.valueSubjectToLttAToE1 < salePriceThresholdForTax ? 0 : this.generateTorontoResTaxRebate(this.valueSubjectToLttAToE1, torontoTaxRateSlab);
                }

            }
            else if(this.lttRateBasedOnType && this.lttRateBasedOnType == 'COMMERCIAL' && this.valueSubjectToLttAToE1 != null) {
                if(this.applyCityOfTorontoLandTransferTax == 'YES') {
                    this.torontoLandTransferTax = this.valueSubjectToLttAToE1 < salePriceThresholdForTax ? 0 : this.generateTorontoComTaxRebate(this.valueSubjectToLttAToE1, torontoTaxRateSlab);
                }
                this.ontarioLandTransferTax = this.valueSubjectToLttAToE1 < salePriceThresholdForTax ? 0 : this.generateOntarioComTaxRebate(this.valueSubjectToLttAToE1, ontarioTaxRateSlab);
            }
            else if(this.lttRateBasedOnType && this.lttRateBasedOnType == 'APPORTION') {

                if(this.valueOfSingleFamilyResidence && this.valueOfSingleFamilyResidence != null && this.valueOfSingleFamilyResidence != 0) {

                    let subjectSalePrice = this.valueSubjectToLttAToE1;
                    if(this.valueSubjectToLttAToE1 === null || (Number(this.valueOfSingleFamilyResidence) >= Number(this.valueSubjectToLttAToE1))) {
                        subjectSalePrice = this.valueOfSingleFamilyResidence;
                        this.ontarioLandTransferTax = this.generateOntarioResTaxRebate(subjectSalePrice, ontarioTaxRateSlab);
                        this.torontoLandTransferTax = this.generateTorontoResTaxRebate(subjectSalePrice, torontoTaxRateSlab);

                    }
                    else {

                        let ontarioComLandSaleTransferTax = this.generateOntarioComTaxRebate(subjectSalePrice, ontarioTaxRateSlab);
                        let ontarioComLandResTransferTax = this.generateOntarioComTaxRebate(this.valueOfSingleFamilyResidence, ontarioTaxRateSlab);
                        let ontarioResLandResTransferTax = this.generateOntarioResTaxRebate(this.valueOfSingleFamilyResidence, ontarioTaxRateSlab);
                        this.ontarioLandTransferTax = Number(ontarioComLandSaleTransferTax - ontarioComLandResTransferTax) + Number(ontarioResLandResTransferTax);


                        let torontoComLandSaleTransferTax = this.generateTorontoComTaxRebate(subjectSalePrice, torontoTaxRateSlab);
                        ;
                        let torontoComLandResTransferTax = this.generateTorontoComTaxRebate(this.valueOfSingleFamilyResidence, torontoTaxRateSlab);
                        let torontoResLandResTransferTax = this.generateTorontoResTaxRebate(this.valueOfSingleFamilyResidence, torontoTaxRateSlab);
                        this.torontoLandTransferTax = Number(torontoComLandSaleTransferTax - torontoComLandResTransferTax) + Number(torontoResLandResTransferTax);
                    }

                }

            }
            this.calculateRefund(ontarioTaxRateSlab, torontoTaxRateSlab);
        }
        else {

            this.resetCalculations();
        }

    }

    resetTaxRebate() {
        this.resetCalculations();
    }

    private resetCalculations() : void {
        this.ontarioLandTransferTax = 0;
        this.torontoLandTransferTax = 0;
        this.torontoNetLttPayable = 0;
        this.ontarioNetLttPayable = 0;
        this.torontoLessLttRefund = 0;
        this.ontarioLessLttRefund = 0;
        this.totalOntarioTorontoLttPayable = 0;
    }

    calculateRefund(ontarioTaxRateSlab : ConsiderationTaxes, torontoTaxRateSlab : ConsiderationTaxes) : void {

        let lttOntarioMaxRebate = ontarioTaxRateSlab && ontarioTaxRateSlab.lttRebateMaximum;
        let lttTorontoMaxRebate = torontoTaxRateSlab && torontoTaxRateSlab.lttRebateMaximum;
        let lttOntarioMinRebate = ontarioTaxRateSlab && ontarioTaxRateSlab.lttMinimum;
        let lttTorontoMinRebate = torontoTaxRateSlab && torontoTaxRateSlab.lttMinimum;

        if(this.percentageClaimed && (this.lttRefundEntitlementOntario == 'Yes' || this.lttRefundEntitlementOntario == 'YES')) {


            let ontarioRefund = (lttOntarioMaxRebate * Number(this.percentageClaimed)) / 100;
            let ontarioTaxRefund = (this.ontarioLandTransferTax * Number(this.percentageClaimed)) / 100;
            this.ontarioLessLttRefund = lttOntarioMaxRebate <= this.ontarioLandTransferTax ? ontarioRefund : ontarioTaxRefund;
            this.ontarioNetLttPayable = this.ontarioLandTransferTax - this.ontarioLessLttRefund;

            let torontoRefund = (lttTorontoMaxRebate * Number(this.percentageClaimed)) / 100;
            let torontoTaxRefund = (this.torontoLandTransferTax * Number(this.percentageClaimed)) / 100;
            this.torontoLessLttRefund = lttTorontoMaxRebate <= this.torontoLandTransferTax ? torontoRefund : torontoTaxRefund;
            this.torontoNetLttPayable = this.torontoLandTransferTax - this.torontoLessLttRefund;
        }
        else {
            this.ontarioNetLttPayable = this.ontarioLandTransferTax;
            this.torontoNetLttPayable = this.torontoLandTransferTax;
        }

        if(this.torontoNetLttPayable < lttTorontoMinRebate) {
            this.torontoNetLttPayable = 0;
        }
        if(this.ontarioNetLttPayable < lttOntarioMinRebate) {
            this.ontarioNetLttPayable = 0;
        }

        this.totalOntarioTorontoLttPayable = Number(this.ontarioNetLttPayable) + Number(this.torontoNetLttPayable);
    }


    generateOntarioResTaxRebate(salePrice : number, ontarioTaxRateSlab : ConsiderationTaxes) : number {
        const taxRateTiers = ontarioTaxRateSlab.tiers
            .filter(taxRateTier => taxRateTier.tierType == TaxRateTierTypes.RESIDENTIAL)
            .sort((a, b) => {
                if (a.minimum < b.minimum) { return -1; }
                else if (a.minimum > b.minimum) { return 1;}
                else { return 0; }
            })

        let ontarioResTaxRebateValue : number = 0;
        if(salePrice) {
            taxRateTiers.forEach(taxRateTier => {
                ontarioResTaxRebateValue += this.generateTaxRebateValue(
                    salePrice, taxRateTier.maximum, taxRateTier.minimum, taxRateTier.rate)
            })
        }
        return Utils.roundCurrencyTCStyle(ontarioResTaxRebateValue);

    }


    generateOntarioComTaxRebate(salePrice : number, ontarioTaxRateSlab : ConsiderationTaxes) : number {
        const taxRateTiers = ontarioTaxRateSlab.tiers
            .filter(taxRateTier => taxRateTier.tierType == TaxRateTierTypes.COMMERCIAL)
            .sort((a, b) => {
                if (a.minimum < b.minimum) { return -1; }
                else if (a.minimum > b.minimum) { return 1;}
                else { return 0; }
            })

        let ontarioComTaxRebateValue : number = 0;
        if(salePrice) {
            taxRateTiers.forEach(taxRateTier => {
                ontarioComTaxRebateValue += this.generateTaxRebateValue(
                    salePrice, taxRateTier.maximum, taxRateTier.minimum, taxRateTier.rate)
            })
        }
        return Number(ontarioComTaxRebateValue.toFixed(2));
    }


    generateTorontoResTaxRebate(salePrice : number, torontoTaxRateSlab : ConsiderationTaxes) : number {
        const taxRateTiers = torontoTaxRateSlab.tiers
            .filter(taxRateTier => taxRateTier.tierType == TaxRateTierTypes.RESIDENTIAL)
            .sort((a, b) => {
                if (a.minimum < b.minimum) { return -1; }
                else if (a.minimum > b.minimum) { return 1;}
                else { return 0; }
            })

        let torontoResTaxRebateValue : number = 0;

        if(salePrice) {
            taxRateTiers.forEach(taxRateTier => {
                torontoResTaxRebateValue += this.generateTaxRebateValue(
                    salePrice, taxRateTier.maximum, taxRateTier.minimum, taxRateTier.rate)
            })
        }
        return Number(torontoResTaxRebateValue.toFixed(2));


    }

    generateTorontoComTaxRebate(salePrice : number, torontoTaxRateSlab : ConsiderationTaxes) : number {
        const taxRateTiers = torontoTaxRateSlab.tiers
            .filter(taxRateTier => taxRateTier.tierType == TaxRateTierTypes.COMMERCIAL)
            .sort((a, b) => {
                if (a.minimum < b.minimum) { return -1; }
                else if (a.minimum > b.minimum) { return 1;}
                else { return 0; }
            })

        let torontoComTaxRebateValue : number = 0;
        if(salePrice) {
            taxRateTiers.forEach(taxRateTier => {
                torontoComTaxRebateValue += this.generateTaxRebateValue(
                    salePrice, taxRateTier.maximum, taxRateTier.minimum, taxRateTier.rate)
            })
        }

        return Number(torontoComTaxRebateValue.toFixed(2));

    }

    private generateTaxRebateValue(salePrice : number, TierMaximum : number, TierMinimum : number, currentTierRate : number) : number {

        let taxableAmount : number = 0;
        if(TierMaximum && salePrice <= TierMaximum && salePrice >= TierMinimum) {
            taxableAmount = salePrice - TierMinimum;
        }
        else if (TierMaximum == null && salePrice >= TierMinimum) {
            taxableAmount = salePrice - TierMinimum;
        }
        else if(TierMaximum && salePrice > TierMaximum) {
            taxableAmount = TierMaximum - TierMinimum;
        }

        return taxableAmount? taxableAmount * currentTierRate / 1000: taxableAmount;
    }

    // uses project.docRegistration.ignoreCreditsInDeedConsideration
    updateSalePriceForERegConsideration(ignoreCreditsInDeedConsideration: string, isProjectHstReductionInSalePriceAdjustment: boolean, soaConsiderationTaxes: ConsiderationTaxes, netSalePrice? : number): void {

        if (this.salePriceAdjustment) {
            let salePrice: number = 0;
            if (this.salePriceAdjustment.isNotInclusivePrice()) {
                salePrice = Number(this.salePriceAdjustment.agreementSalePrice);
            } else {
                let existingRealEstatePortionOfNSP: boolean = this.salePriceAdjustment.calculateRealEstateNSP(soaConsiderationTaxes.hstFederalPortion, soaConsiderationTaxes.hstProvincialPortion) > 0;
                if (existingRealEstatePortionOfNSP) {
                    if (ignoreCreditsInDeedConsideration === 'YES') {
                        salePrice = this.salePriceAdjustment.calculateRealEstateNSP(soaConsiderationTaxes.hstFederalPortion, soaConsiderationTaxes.hstProvincialPortion, true, true, true, false, isProjectHstReductionInSalePriceAdjustment);
                    } else {
                        salePrice = this.salePriceAdjustment.calculateRealEstateNSP(soaConsiderationTaxes.hstFederalPortion, soaConsiderationTaxes.hstProvincialPortion);
                    }
                } else {
                    if (ignoreCreditsInDeedConsideration === 'YES') {
                        salePrice = this.salePriceAdjustment.totalNetSalePrice(soaConsiderationTaxes.hstFederalPortion, soaConsiderationTaxes.hstProvincialPortion, true, true, true, false, isProjectHstReductionInSalePriceAdjustment);
                    } else {
                        salePrice = this.salePriceAdjustment.totalNetSalePrice(soaConsiderationTaxes.hstFederalPortion, soaConsiderationTaxes.hstProvincialPortion);
                    }
                }
            }
            this.updateSalePriceForNonProject(salePrice, netSalePrice);
            // debugger;
            // console.log(`updateSalePriceForERegConsideration(ignoreCreditsInDeedConsideration = ${ignoreCreditsInDeedConsideration}, isProjectHstReductionInSalePriceAdjustment=${isProjectHstReductionInSalePriceAdjustment})-> salePrice = ${salePrice}`);
        }
    }

    updateSalePriceForNonProject(salePrice: number, netSalePrice? : number): void {
        if (this.inTableBelowType === 'AMOUNT_IN_A_EQUALS_AMOUNT_F') {
            this.moniesPaidOrToBePaidIn = salePrice;
        } else if (this.inTableBelowType === 'CALCULATE_F_TOTALLING_A_THOUGH_E' && !this.moniesPaidOrToBePaidIn) {
            this.moniesPaidOrToBePaidIn = salePrice;
        } else if (this.inTableBelowType === 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F'
            || this.completionOfTaxInfoType === 'LINE_A_EQUAL_NET_SALE_PRICE') {
            this.valueSubjectToLttAToE1 = salePrice;
        }else if(this.completionOfTaxInfoType === 'COMPLETE_TAX_INFO_MANUALLY'){
            this.moniesPaidOrToBePaidIn = netSalePrice;
        }else if(this.completionOfTaxInfoType === 'HIDDEN_TAX_AND_SET_AMOUNT_AS_2'){
            this.moniesPaidOrToBePaidIn = TERAVIEW_AMOUNT_AS_TWO;
            this.valueSubjectToLttAToE1 = TERAVIEW_AMOUNT_AS_TWO;
        } else if (this.completionOfTaxInfoType === 'HIDDEN_TAX_AND_USE_NET_SALE_PRICE') {
            this.moniesPaidOrToBePaidIn = netSalePrice;
            this.valueSubjectToLttAToE1 = netSalePrice;
        }

    }

    updateSalePrice(salePrice : number, forERegConsideration: boolean, ignoreCreditsInDeedConsideration: string, isProjectHstReductionInSalePriceAdjustment: boolean, soaConsiderationTaxes: ConsiderationTaxes) : void {
        if (forERegConsideration){
            this.updateSalePriceForERegConsideration(ignoreCreditsInDeedConsideration, isProjectHstReductionInSalePriceAdjustment, soaConsiderationTaxes, this.getTotalNetSalePrice(ignoreCreditsInDeedConsideration, isProjectHstReductionInSalePriceAdjustment, soaConsiderationTaxes));
        } else {
            this.updateSalePriceForNonProject(salePrice, this.getTotalNetSalePrice(ignoreCreditsInDeedConsideration, isProjectHstReductionInSalePriceAdjustment, soaConsiderationTaxes));
        }
    }

    // value A = value F - (value B + ... + value E)
    isCalculateABySubstractBThroughEFromFOrManualOrLINE_A() : boolean {
        return this.inTableBelowType === 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F'
            || this.completionOfTaxInfoType === 'LINE_A_EQUAL_NET_SALE_PRICE'
            || this.completionOfTaxInfoType === 'COMPLETE_TAX_INFO_MANUALLY';
    }

    applyMLTTBasedOnSubjectToLTT() : boolean {
        return (this.valueSubjectToLttAToE2 >= 14400);
    }

    get landTransferTaxValueOnt() : number {
        return (this.lttRefundEntitlementOntario == 'Yes' || this.lttRefundEntitlementOntario == 'YES') ? this.ontarioNetLttPayable : this.ontarioLandTransferTax;
    }

    get landTransferTaxValueTor() : number {
        return (this.lttRefundEntitlementOntario == 'Yes' || this.lttRefundEntitlementOntario == 'YES') ? this.torontoNetLttPayable : this.torontoLandTransferTax;
    }

    updateSalePriceAdjustment(newSoaSalePrice : number) : void {
        this.salePriceAdjustment.agreementSalePrice = newSoaSalePrice;
    }

    creditVendorPrice(considerationTaxes : ConsiderationTaxes, modalsalePriceAdjustment? : SalePriceAdjustment) : number {
        let salePriceAdjustment = modalsalePriceAdjustment ? modalsalePriceAdjustment : this.salePriceAdjustment;
        if(!salePriceAdjustment.isInclusivePrice()) {

            if(salePriceAdjustment.isSalePriceFormatNSP()) {
                return Number(salePriceAdjustment.totalWithoutTaxOrRebatesButWithConsiderations());
            } else {
                return salePriceAdjustment.agreementSalePrice;
            }
        } else if(salePriceAdjustment.isSalePriceFormatNSP()) {
            return Utils.roundCurrency(salePriceAdjustment.totalNetSalePrice((considerationTaxes ? considerationTaxes.hstFederalPortion : 0), (considerationTaxes ? considerationTaxes.hstProvincialPortion : 0))
                    + salePriceAdjustment.additionalRebatePurchaserNotEligible);
        } else {
            return salePriceAdjustment.agreementSalePrice;
        }
    }

    isNrstApplied() : boolean {
        return (this.applyNrst == 'YES');
    }

    /*
     A: moniesPaidOrToBePaidIn
     B1: mortgagesAssumed
     B2: mortgagesGivenBackToVendor
     C: propertyTransferredInExchange
     D: otherConsiderationSubjectToTax
     E: fairMarketValueOfLands
     F: valueSubjectToLttAToE1
     */
    isSalePriceValid(salePrice : number) : boolean {
        if(salePrice) {
            let totalValueFromBToE : number = (
            Utils.getNumberOrDefault(this.mortgagesAssumed, 0)
            + Utils.getNumberOrDefault(this.mortgagesGivenBackToVendor, 0)
            + Utils.getNumberOrDefault(this.propertyTransferredInExchange, 0)
            + Utils.getNumberOrDefault(this.otherConsiderationSubjectToTax, 0)
            + Utils.getNumberOrDefault(this.fairMarketValueOfLands, 0));

            return salePrice >= totalValueFromBToE;
        }
        return false;

    }

    calculateMoneyPaid() : number {
        let totalValueFromBToE : number = (
        Utils.getNumberOrDefault(this.mortgagesAssumed, 0)
        + Utils.getNumberOrDefault(this.mortgagesGivenBackToVendor, 0)
        + Utils.getNumberOrDefault(this.propertyTransferredInExchange, 0)
        + Utils.getNumberOrDefault(this.otherConsiderationSubjectToTax, 0)
        + Utils.getNumberOrDefault(this.fairMarketValueOfLands, 0));

        let salePrice = Utils.getNumberOrDefault(this.valueSubjectToLttAToE1, 0);
        return salePrice - totalValueFromBToE;

    }

    calculateSalePriceAdjustmentHeadingsCost() : number {
        let costs : number = 0;
        if(this.salePriceAdjustment && this.salePriceAdjustment.isNotInclusivePrice()) {
            if(Array.isArray(this.salePriceAdjustment.salePriceAdjustmentHeadings)) {
                costs = this.salePriceAdjustment.salePriceAdjustmentHeadings.reduce((prevCost, heading) => {
                    return prevCost + heading.cost;
                }, 0);
            }
        }
        return costs;
    }

    additionalConsideration1Exists(statementOfAdjustments: StatementAdjustment[]) : boolean {
        return this.salePriceAdjustment && this.salePriceAdjustment.additionalConsiderationsInclHst > 0
            &&(StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments)
                || StatementAdjustmentUtil.isHCRAAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments)
                || StatementAdjustmentUtil.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount(statementOfAdjustments));
    }

    getTotalNetSalePrice(ignoreCreditsInDeedConsideration: string, isProjectHstReductionInSalePriceAdjustment: boolean, soaConsiderationTaxes: ConsiderationTaxes) : number{
        if(!this.salePriceAdjustment) {return 0;}

        if (ignoreCreditsInDeedConsideration === 'YES') {
            return this.salePriceAdjustment.totalNetSalePrice(soaConsiderationTaxes.hstFederalPortion, soaConsiderationTaxes.hstProvincialPortion, true, true, true, false, isProjectHstReductionInSalePriceAdjustment);
        } else {
            return this.salePriceAdjustment.totalNetSalePrice(soaConsiderationTaxes.hstFederalPortion, soaConsiderationTaxes.hstProvincialPortion);
        }
    }
}
