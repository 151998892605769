import {Component} from '@angular/core';
import {AuthorizationService} from '../shared-main/authorization/authorization-service';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {AccountingExportService} from './accounting-export.service';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {SoaExportConfig} from '../matters/shared/soa-export-config';
import {ApplicationError} from '../core';
import moment from 'moment';
import {AccountNationalSetting} from '../admin/shared/general-ledger-account-list';
import {StatementConfigService} from '../admin/shared/statement-config.service';
import {AppConfig} from '../shared-main/app-configuration';

@Component({
    selector: 'dp-accounting-export-modal-content',
    templateUrl: './accounting-export.modal.component.html',
    providers: [AuthorizationService]
})
export class AccountingExportModalComponent implements ModalComponent<null>{

    loading: boolean = false;
    accountId: string = null;
    soaExportConfigs : SoaExportConfig[]=[];
    fileDownloaded: boolean= false;
    accountNationalSetting: AccountNationalSetting;
    isEsiLawFormat: boolean = false;

    constructor(public dialog : DialogRef<null>,
                public appConfig : AppConfig,
                public accountingExportService : AccountingExportService,
                public soaConfigService : StatementConfigService
                ){}

    ngOnInit() {
        this.loading = true;
        this.accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        this.loadSoaExportConfig();
        this.loadAccountNationalSetting();
    }

    loadSoaExportConfig() {
        this.soaExportConfigs = [];
        this.accountingExportService.getAccountingExportData(this.accountId)
            .subscribe((res: SoaExportConfig[]) => {
                if (res) {
                    this.loading = false;
                    this.soaExportConfigs = res;
                }
            }, (error: ApplicationError) => {
                this.loading = false;
            }
            );
    }

    exportedByUser(soaExportConfig: SoaExportConfig):string{
        if(soaExportConfig && soaExportConfig.createdByUser){
            let user = soaExportConfig.createdByUser;
            return (user.firstName ? (user.firstName + ' '+ user.lastName) : ''+user.lastName) ;
        }
    }

    exportedTimeStamp(soaExportConfig: SoaExportConfig ,dateFormat:string): string{
        if(soaExportConfig && soaExportConfig.createdTimeStamp){
            return moment(soaExportConfig.createdTimeStamp).format(dateFormat);
        }
    }

    close() : void {
        this.dialog.close();
    }

    downloadAccountingData() : void {
        this.accountingExportService.downloadAccountingExportData(this.accountId ,this.isEsiLawFormat);
        setTimeout( () => {
            if (!this.fileDownloaded) {
                this.fileDownloaded = true;
            }
        }, 200);


    }

    loadAccountNationalSetting(): void {
        this.soaConfigService
            .getAccountingSoftwareConfig(this.accountId)
            .subscribe((accNatSetting : AccountNationalSetting) => {
                this.accountNationalSetting = accNatSetting;
            });
    }
}
