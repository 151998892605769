export class ProjectDocumentSharingNotification{
    projectId : number;
    notificationMessage : string;
    documentIds: number[] = [];

    //Not an entity, defining the constructor explicitly
    constructor(json?) {
        if(json) {
            for(let prop in json) {
                if(json.hasOwnProperty(prop)) {
                    this[prop] = json[prop];
                }
            }
        }

    }
}
