import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class AccountNotificationConfig extends BaseEntity{

    id: number;
    lawyerNotification: boolean;
    allOtherStaffNotification: boolean;
    customerAccountId:number

    constructor(assignmentNotificationConfig?: AccountNotificationConfig) {
        super(assignmentNotificationConfig);
    }

}
