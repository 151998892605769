import {User} from '../shared/user';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {SharedDocument} from '../../share-documents/shared-document';
import {DigitalSignaturePlatform, DOCUMENT_UD_DIR_MARKER_FILE_NAME} from '../../shared-main/constants';

export type DocumentTypeType = "WORD" | "EXCEL" | "IMAGE" | "PDF" | "WORD" | "WORDPERFECT" | "XML" | "TXT" | "EMAIL" | "RTF";
export type GeneratedDocumentTypeType = 'SPIN' | 'ISC' | 'TERANET_CONNECT' | 'FORMS' | 'STEWART_ASSYST' | 'CHICAGO_TITLE' | 'FCT' | 'FCT_LLC' | 'FCT_MMS' | 'PRODUCED' | 'SUPPLEMENTALTASK' | 'USER_UPLOADED' | 'CLIENT_UPLOADED' | 'BROKER_UPLOADED' | 'REALTOR_UPLOADED' | 'STEWART_TITLE' | 'TPR' | 'SHARED' | 'THIRD_PARTY_SIGNED' | 'TITLE_PLUS';

export class MatterDocumentMetadata extends  BaseEntity{
    matterId: number;
    aggregateDocumentsStatusHash: string;
}

export class Document extends BaseEntity{
    documentName: string;
    originalDocumentName: string;
    id: number;
    lastUpdatedTimeStamp: number;
    status: string;
    templateId: number;
    description: string;
    lockedByUser: User;
    docType: string;
    matterSupplementalTaskCategoryId: number;
    currentMortgageIndex: number;
    currentAffidavitIndex: number;
    currentHoldbackIndex: number;
    documentType: DocumentTypeType;
    generatedDocumentType: GeneratedDocumentTypeType;
    initials: string;
    writeProtected: boolean;
    source: DocumentSource;
    documentNumber: string;
    documentVersionNumber: number;
    documentGenerationDate: number;
    virusScanStatus : string;
    hasBarcode: boolean;
    currentMatterSoaSheetId: number;

    //Shared Document fields to calculate and show the status New/Updated
    lastSharedTimeStamp: string;
    lastViewedTimeStamp: string;

    autoShared: boolean;
    submittedForDigitalSigningTo : string;
    subpath : string;

    constructor(document) {
        super(document);
        if (document.lockedByUser) {
            this.lockedByUser = new User(document.lockedByUser);
        }
    }

    //returns true if locked by other user else false
    get isLocked(): boolean {
        const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        return (this.lockedByUser && this.lockedByUser.id !== Number(userId));
    }

    //returns true if locked by current user else false
    get isLockedByUser(): boolean {
        const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        return (this.lockedByUser && this.lockedByUser.id == Number(userId));
    }

    //If document is locked by anybody(including user itself) then it's considered open
    get isDocumentOpened(): boolean {
        return !!this.lockedByUser;
    }

    get isWord() : boolean {
        return this.documentType == 'WORD';
    }

    get isPdf() : boolean {
        return this.documentType == 'PDF';
    }

    get isImage() : boolean {
        return this.documentType == 'IMAGE';
    }

    get isExcel() : boolean  {
        return this.documentType == 'EXCEL';
    }

    get isTxt(): boolean{
        return this.documentType == "TXT";
    }

    get isXml(): boolean{
        return this.documentType == "XML";
    }

    get isEmail(): boolean{
        return this.documentType == "EMAIL";
    }

    get isWordPerfect() : boolean  {
        return this.documentType == 'WORDPERFECT';
    }

    get isWordOrWordPerfect() : boolean {
        return this.documentType == 'WORD' || this.documentType == 'WORDPERFECT';
    }


    get isOther(): boolean{
        return !(this.isWordOrWordPerfect || this.isPdf || this.isTxt || this.isXml || this.isEmail);
    }

    get isTeranetConnectDocument(): boolean {
        return this.generatedDocumentType == 'TERANET_CONNECT';
    }

    get isSpinDocument(): boolean {
        return this.generatedDocumentType == 'SPIN';
    }

    get isISCDocument(): boolean {
        return this.generatedDocumentType == 'ISC';
    }

    get isThirdPartyDocument() : boolean {
        return Document.isThirdPartyDocumentType(this.generatedDocumentType);
    }

    static isThirdPartyDocumentType(generatedDocumentType: string) {
        return generatedDocumentType === 'TERANET_CONNECT' ||
            generatedDocumentType === 'STEWART_ASSYST' || generatedDocumentType === 'STEWART_TITLE' ||
            generatedDocumentType === 'CHICAGO_TITLE' ||
            generatedDocumentType === 'FCT' || generatedDocumentType === 'FCT_LLC' || generatedDocumentType === 'FCT_MMS' ||
            generatedDocumentType === 'SPIN' || generatedDocumentType === 'ISC' ||
            generatedDocumentType === 'TPR' || generatedDocumentType === 'TITLE_PLUS';
    }

    get isFctLlcMms(): boolean{
        return this.generatedDocumentType === 'FCT_LLC' || this.generatedDocumentType === 'FCT_MMS';
    }

    get showDocumentGenerationDate(): boolean{
        return this.generatedDocumentType === 'STEWART_TITLE' || this.isFctLlcMms || this.generatedDocumentType === 'TITLE_PLUS';
    }

    get isForms(): boolean {
        return this.generatedDocumentType == 'FORMS';
    }

    get isCompleted() : boolean {
        return this.status == 'COMPLETED';
    }

    get isInfected() : boolean {

        return this.virusScanStatus=='INFECTED';
    }
    get isVirusScanPending() : boolean {
        return (this.virusScanStatus=='PENDING' || this.virusScanStatus=='IN_PROGRESS');
    }

    get isTPRPdfFile() : boolean {
        return this.generatedDocumentType == 'TPR' && this.documentType == 'PDF';
    }

    get isSharedDocument():boolean{
        return this.generatedDocumentType == 'SHARED';
    }

    get isThirdPartySigned() : boolean {
        return this.generatedDocumentType == 'THIRD_PARTY_SIGNED';
    }

    //Getting the document status (New/Updated) based on shared Document fields.
    get docStatus() : string {
        if(this.isSharedDocument){
            let sharedDocument = new SharedDocument();
            sharedDocument.lastSharedTimeStamp = this.lastSharedTimeStamp;
            sharedDocument.lastViewedTimeStamp= this.lastViewedTimeStamp;
            return sharedDocument.docStatus;
        }else{
            return '';
        }
    }

    get isDocumentDigitallySignedByDocuSign() : boolean{
        return this.submittedForDigitalSigningTo && this.submittedForDigitalSigningTo == DigitalSignaturePlatform.DOCU_SIGN;
    }

    get isDocumentDigitallySignedBySyngrafii() : boolean{
        return this.submittedForDigitalSigningTo && this.submittedForDigitalSigningTo == DigitalSignaturePlatform.SYNGRAFII;
    }

    isSourceDocuSign() : boolean {
        return this.source == DigitalSignaturePlatform.DOCU_SIGN;
    }

    isSourceDocSyngrafii() : boolean {
        return this.source == DigitalSignaturePlatform.SYNGRAFII;
    }

    getThirdPartySignedLabel() : string {
        if(this.isThirdPartySigned){
            if(this.isSourceDocuSign()){
                return 'Signed with DocuSign';
            }
            if(this.isSourceDocSyngrafii()) {
                return 'Signed with Syngrafii'
            }
        }
        return '';
    }

    isUdDirMarketTxtFile() : boolean{
        return this.documentName ==  DOCUMENT_UD_DIR_MARKER_FILE_NAME
    }

}

export type DocumentSource = "DOCUMENT_PRODUCTION" | "USER_UPLOAD" | "CLIENT_UPLOAD" | "DOCU_SIGN" | "SYNGRAFII";
