import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {loginResponseKey} from './login-response-key';
import {accountApi} from '../admin/accounts/shared/account-api';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {LoginDTO} from '../login';
import {AuthenticatedUser} from '../shared-main/authorization/authenticated-user';
import {AuthorizationService} from '../shared-main/authorization/authorization-service';
import {userAccountProfilesApi} from '../admin/shared/user-account-profiles-api';
import {Observable} from 'rxjs/index';
import {HttpClient} from './httpClient.service';
import {FeatureService} from '../feature-service';
import {ReferenceDataService} from '../shared-main/reference-data/reference-data.service';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable()
export class AuthenticationService {

    redirectUrl: string;

    constructor(private httpClient: HttpClient,
                private logger: Logger,
                private oauthService: OAuthService,
                private authorizationService: AuthorizationService,
                private featureService: FeatureService,
                public referenceDataService: ReferenceDataService,
    ) {}

    navigateToGetRedirectUrl() {
        return this.authorizationService.navigateToGetRedirectUrl();
    }

    getAuthenticatedUser() {
        return this.httpClient
            .get(userAccountProfilesApi.info)
            .map(res => {
                let authenticationSuccessResponse = res[loginResponseKey.loginSuccess];
                this.authorizationService.setUpUserStateService(authenticationSuccessResponse.defaultProvinceCode, authenticationSuccessResponse.userProvinces);
                this.setUpUserSession(authenticationSuccessResponse);
                this.referenceDataService.initializeReferenceData();
            });
    }

    // On logout remove authentication details from session for loggedInUser
    async logout(): Promise<boolean>  {
        this.authorizationService.removeAuthenticatedUser();
        if (this.featureService.isSSOEnable) {
            const idToken = this.oauthService.getIdToken();
            await this.httpClient.ssoLogout().toPromise();
            await this.oauthService.logOut({id_token_hint: idToken });
        } else {
            this.httpClient.logout();
        }
        return true;
    }

    /**
     * Login service for authorization
     * After authorization it puts authentication details in session storage for the loggedInUser
     */
    login(user: LoginDTO) {
        return this.httpClient.post(accountApi.login, user)
                   .map(res => {
                       let authenticationSuccessResponse = res[loginResponseKey.loginSuccess];
                       if (authenticationSuccessResponse) {
                           this.authorizationService.setUpUserStateService(authenticationSuccessResponse.defaultProvinceCode, authenticationSuccessResponse.userProvinces);
                           this.setUpUserSession(authenticationSuccessResponse);
                           return true;
                       } else {
                           return false;
                       }
                   });
    }

    fakeLogin(user: LoginDTO): Observable<any> {
        return this.httpClient.post(accountApi.fakeLogin, user).map(response => {
                // Extract the access token from the response header
                let authenticationRedirectURL = response[loginResponseKey.redirectUrl];
                let sessionToken : any  = sessionStorage.getItem(SESSION_STORAGE_KEYS.tokens).split('Bearer ')[1];
                console.log("sessionToken", sessionToken);
                console.log("authenticationRedirectURL", authenticationRedirectURL);
                // Validate the access token (you can implement this logic)
                if (sessionToken && sessionToken.length) {
                    // Decode the token
                    // const decodedToken: any = jwtDecode(sessionToken)
                    // console.log("decodedToken", decodedToken);
                    // console.log("user", decodedToken.User);
                    // Token is valid, proceed with setup
                    // Example: setup automatic refresh, fetch user info, etc.
                    this.oauthService.setupAutomaticSilentRefresh();

                    //TODO later this should trigger re-login for authentication
                    // this.sessionSetUpForFakeLogin(decodedToken.User);
                    return authenticationRedirectURL;
                } else {
                    console.error('Token not found in session storage');
                    // Handle missing token (e.g., redirect to login page)
                    return ('https://dyedurham.ca');
                }

            }
        );
    }


    /**
     * This methods is used in AuthGuard service
     * Checks if user is authenticated by checking the details in the sessionStorage
     * If user is authenticated return true
     * If user is not authenticated returns false
     */
    checkCredentials() {
        let authorization = this.httpClient.getToken();
        let loggedInUser = sessionStorage.getItem(SESSION_STORAGE_KEYS.userLogin);
        if (authorization && loggedInUser) {
            return true;
        } else {
            this.logger.debug(`authorization failed`);
            return false;
        }
    }

    verifyPassword(verifyRequest: LoginDTO) : Observable<any> {
        return this.httpClient.post(`${userAccountProfilesApi.updateLoginParams}`, verifyRequest)
                   .map((res) => {
                       return res;
                   });
    }

    setUpUserSession(authenticatedUserResponse : any) : void {
        let authenticatedUserObj: AuthenticatedUser = AuthenticatedUser.createAuthenticatedUserFromApiResponse(authenticatedUserResponse);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.authenticatedUser, JSON.stringify(authenticatedUserObj));
        sessionStorage.setItem(SESSION_STORAGE_KEYS.user, JSON.stringify(authenticatedUserResponse));
        sessionStorage.setItem(SESSION_STORAGE_KEYS.userLogin, authenticatedUserResponse.username);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.accountId, authenticatedUserResponse.customerAccountId);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.legalFirmId, authenticatedUserResponse.legalFirmId);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.userDocumentProfileId, authenticatedUserResponse.documentProfileId);

        sessionStorage.setItem(SESSION_STORAGE_KEYS.userContactId,  authenticatedUserResponse.contactId);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.userId, authenticatedUserResponse.id);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.userInitiatedResetsAllowed, authenticatedUserResponse.userInitiatedResetsAllowed);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.disablePostClosedMattersEdit, authenticatedUserResponse.disablePostClosedMattersEdit);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.disableMatterEditAfterClosingDays, authenticatedUserResponse.disableMatterEditAfterClosingDays);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.fctEnabled, authenticatedUserResponse.fctEnabled); // Customer Account level flag to show or hide FCT EMP functionality to user
        sessionStorage.setItem(SESSION_STORAGE_KEYS.isConveyancingUser, authenticatedUserResponse.conveyancingCustomer);
    }
}
