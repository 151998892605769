import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {JurisdictionDepartment} from '../../admin/jurisdiction-departments/jurisdiction-departments';
import {
    Combined_Hydro_Water_Department,
    Hydro_Department,
    Water_Department,
    WaterHydroDeptType_IS_COMBINED
} from '../../shared-main/constants';

export type WriteToType = 'YES' | 'NO';
export type StatusType = 'QUESTION' | 'YES' | 'NOT_OK';

export class Compliance extends BaseEntity {
    constructor(compliance? : Compliance) {
        super(compliance);
    }

    id : number;
    departmentName : string;
    hst : string;
    fee : number;
    departmentKey : string;
    departmentPriority : number;
    writeTo : WriteToType ; //yesno
    status : StatusType;
    remarks : string;


    /**
     * Converting jurisdiction departments to compliances.
     * @param {JurisdictionDepartment[]} jurisdictionDepartments
     * @param {string} matterType
     * @param {string} waterHydroDeptType -- Optional. When there is no jurisdiction, there will be no waterHydroDeptType.
     * @returns {Compliance[]}
     */
    static jurisdictionDepartmentsToCompliances(jurisdictionDepartments: JurisdictionDepartment[], matterType: string, waterHydroDeptType?: string): Compliance[] {
        let convertedCompliances: Compliance[] = [];
        if(Array.isArray(jurisdictionDepartments)){
            convertedCompliances = jurisdictionDepartments.map((department: JurisdictionDepartment) => {
                let convertedCompliance: Compliance = new Compliance();
                convertedCompliance.departmentKey=department.departmentKey;
                convertedCompliance.departmentName=department.departmentName;
                convertedCompliance.departmentPriority=department.departmentPriority;
                convertedCompliance.status='QUESTION';

                //Water is renamed as Hydro/Water if both are combined.
                if (waterHydroDeptType == WaterHydroDeptType_IS_COMBINED && convertedCompliance.departmentName == Water_Department) {
                    convertedCompliance.departmentName = Combined_Hydro_Water_Department;
                }

                if (matterType) {
                    let matterTypeCode: string = matterType.charAt(0);
                    if (department.writeTo && department.writeTo.includes(matterTypeCode)) {
                        convertedCompliance.writeTo='YES';
                    } else {
                        convertedCompliance.writeTo='NO';
                    }
                }

                return convertedCompliance;
            });
        }

        //Remove Hydro Department if Water/Hydro are combined.
        if (waterHydroDeptType == WaterHydroDeptType_IS_COMBINED) {
            let hydroDeptIndex = convertedCompliances.findIndex(compliance => compliance.departmentName == Hydro_Department);
            if (hydroDeptIndex >= 0) {
                convertedCompliances.splice(hydroDeptIndex,1);
            }
        }

        return convertedCompliances;
    }

    noHstApplied() : boolean {
       return(this.hst == "NO");
    }

    isWriteToYes() : boolean {
        return(this.writeTo == "YES");
    }

}
