import {Injectable} from '@angular/core';
import {Matter} from '../matters/shared';
import {HttpClient} from '../core';
import {api} from '../common';
import {Observable} from 'rxjs';
import {FieldCodeMapping, FieldMetaData} from '../matters/shared/field-code-mapping';

/**
 *  This service provides a help text in the Status Bar on field focus
**/
@Injectable()
export class FieldCodeService {

    fieldCodeMappingsUrl : string = `${api}/config/fieldCodeMappings`;

    fieldCodeText: string;

    fieldCodeMappings  : FieldCodeMapping[] = [] ;

    constructor(private http : HttpClient) {

    }

    onFocusFieldCode(fieldMetaData : FieldMetaData): void {
        let fieldCode = this.getFieldCode(fieldMetaData);
        if (fieldCode) {
            this.fieldCodeText = 'Field Code = '+fieldCode;
        }
    }

    onFocusOutFieldCode(): void {
        this.fieldCodeText='';
    }

    getFieldCode(fieldMetaData : FieldMetaData) : string {
        let fieldCodeMapping ;
        if(fieldMetaData){
           fieldCodeMapping = this.fieldCodeMappings.find(item => item.fieldMetaData.elementIdentifierXpath == fieldMetaData.elementIdentifierXpath
                && ( (!!fieldMetaData.elementId &&  item.fieldMetaData.elementId ==  fieldMetaData.elementId) ||
                    (!!fieldMetaData.elementName  &&  item.fieldMetaData.elementName  ==  fieldMetaData.elementName) ||
                    (!!fieldMetaData.elementLabel &&  item.fieldMetaData.elementLabel ==  fieldMetaData.elementLabel)
               )
               && ( (!fieldMetaData.elementAddressIdentifier && !fieldMetaData.elementAutoSuggestIdentifier && !fieldMetaData.elementPartialDateIdentifier)
                     ||
                    (!!fieldMetaData.elementAddressIdentifier &&  item.fieldMetaData.elementAddressIdentifier ==  fieldMetaData.elementAddressIdentifier) ||
                    (!!fieldMetaData.elementAutoSuggestIdentifier &&  item.fieldMetaData.elementAutoSuggestIdentifier ==  fieldMetaData.elementAutoSuggestIdentifier) ||
                    (!!fieldMetaData.elementPartialDateIdentifier &&  item.fieldMetaData.elementPartialDateIdentifier ==  fieldMetaData.elementPartialDateIdentifier)
                )
            )
        }
        return fieldCodeMapping ? fieldCodeMapping.fieldCode : undefined;
    }


    updateFieldCodeMapping(fieldCodeMappings  : FieldCodeMapping[] , matter?  : Matter ) : Observable<boolean> {
        let ref = this;
        return this.http.post(this.fieldCodeMappingsUrl, JSON.stringify({fieldCodeMappings : fieldCodeMappings , matter : matter}))
                   .map((res) => {
                       let fieldCodeMappingsUpdated =  res['FieldCodeMapping'].map(fcm => new FieldCodeMapping(fcm));
                       fieldCodeMappingsUpdated.forEach( fieldCodeMapping =>{
                           if(!!fieldCodeMapping.fieldCode){
                               let fieldCodeMappingIndex = ref.fieldCodeMappings.findIndex( item  =>   item.fieldCode == fieldCodeMapping.fieldCode);
                               if(fieldCodeMappingIndex > -1){
                                   this.fieldCodeMappings.splice(fieldCodeMappingIndex, 1);
                               }
                               ref.fieldCodeMappings.push(fieldCodeMapping);
                           }
                       })
                       return true;
                   }).catch((error: any) => {
                return Observable.of(false);

            });;
    }


    getFieldCodeMappings() : void {
        let ref = this;
        this.http.get(this.fieldCodeMappingsUrl)
                   .subscribe((res) => {
                       if(res['FieldCodeMappings']) {
                           ref.fieldCodeMappings = [];
                           ref.fieldCodeMappings.push(...res['FieldCodeMappings'].map(fcm => {
                               return new FieldCodeMapping(fcm)
                           }));
                       }
                   });
    }


}
