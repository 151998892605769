import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ErrorService} from '../../shared/error-handling/error-service';
import moment from 'moment';
import {Moment} from 'moment';
import {DPError} from '../../shared/error-handling/dp-error';
import {Utils} from '../../matters/shared';

const INVALID_TIME = 'Invalid time';

@Component({
    selector    : 'dp-date-time-picker',
    templateUrl : './date-time-picker.component.html',
    styleUrls      : [
        './date-time-picker.styles.scss'
    ]
})

export class DateTimePickerComponent implements OnInit {

    constructor(public errorService : ErrorService) {}

    @Input() startDate: string;
    @Input() endDate: string;
    @Input() allowMultiDays: boolean = false;
    @Input() startTime: string = '12:00 AM';
    @Input() endTime: string = '12:00 AM';
    @Input() allDayEvent: boolean = false;
    @Input() showAllDayEvent: boolean = true;
    @Input() interval: number = 15;
    @Input() fieldKey : string;
    @Input() startTimeErrorMessage : string;
    @Input() endTimeErrorMessage : string;
    @Input() fullStartDateRequired: boolean = true;
    @Input() fullEndDateRequired: boolean = true;
    @Input() startDateLabelClass: string = 'col-md-2';
    @Input() startDateFieldClass: string = 'col-md-4';
    @Input() startTimeSelectClass: string = 'col-md-6';
    @Input() endDateLabelClass: string = 'col-md-2';
    @Input() endDateFieldClass: string = 'col-md-4';
    @Input() endTimeSelectClass: string = 'col-md-6';

    @Output() dateTimeChange = new EventEmitter();

    localStartTime: string = '';
    localEndTime: string = '';
    startTimeIntervals : string[] ;
    endTimeIntervals : string[] ;

    ngOnInit() : void {
        this.localStartTime = this.startTime;
        this.localEndTime = this.endTime;
        this.initTimeIntervals();
    }

    get sameDate() : boolean {
        return this.startDate == this.endDate;
    }

    isEndDateIsAfterStartDate() : boolean {
        return moment(this.endDate).isAfter(moment(this.startDate));
    }

    initTimeIntervals() : void {
        this.startTime = this.localStartTime;
        this.endTime = this.localEndTime;
        this.startTimeIntervals = this.calculateTimeIntervals(moment().startOf('day'), moment().endOf('day'));
        this.setEndDateIntervals();

    }

    setEndDateIntervals() : void {
        if(this.sameDate) {
            let startTime = this.convertStringToMoment(this.startTime);
            let endTime = moment().endOf('day').add('m', this.interval);
            this.endTimeIntervals = this.calculateTimeIntervals(startTime, endTime);
        } else {
            this.endTimeIntervals = this.calculateTimeIntervalsForAllDay();
        }
    }

    calculateTimeIntervalsForAllDay() : string[] {
        return this.calculateTimeIntervals(moment().startOf('day'), moment().endOf('day'));
    }

    calculateTimeIntervals(startTime: Moment, endTime: Moment)  {
        let timeStops = [];
        let time = moment(startTime);
        while(time <= endTime){
            timeStops.push(moment(time).format('hh:mm A'));
            time.add('m', this.interval);
        }
        return timeStops;
    }

    allDayChange(event) : void {
        this.removeErrorMessage('dpDateTimePicker.startTime.');
        this.removeErrorMessage('dpDateTimePicker.endTime.');
        if(this.allDayEvent){
            this.startTime = '12:00 AM';
            this.endTime = '12:00 AM';
            if(!Utils.isNotValidDate(this.startDate)){
                this.endDate = moment(this.startDate, "YYYY/MM/DD").add(1, 'days').format("YYYY/MM/DD");
            }
        } else {
            this.initTimeIntervals();
        }
        this.emitChanges();
    }

    startTimeChange(event) : void {
        this.removeErrorMessage('dpDateTimePicker.startTime.');
        this.startTime = this.startTime.toUpperCase();
        if(this.isValidTime(this.startTime)){
            this.calculateEndTime();
            this.emitChanges();
        } else {
            this.showErrorMessage('dpDateTimePicker.startTime.', this.startTimeErrorMessage);
        }
    }

    calculateEndTime() : void {
        let startTime = this.convertStringToMoment(this.startTime);
        let endTime = moment().endOf('day').add('m', this.interval);
        if(this.sameDate){
            this.endTimeIntervals = this.calculateTimeIntervals(startTime, endTime);
        } else {
            this.endTimeIntervals = this.calculateTimeIntervalsForAllDay();
        }
        if(this.endTime){
            //Change the endTime if only selected endTime < selected startTime
            let selectedEndTime = this.convertStringToMoment(this.endTime);
            if(selectedEndTime < startTime){
                this.endTime = this.endTimeIntervals[0];
            }
        } else {
            this.endTime = this.endTimeIntervals[0];
        }
    }

    endTimeChange(event) : void {
        this.removeErrorMessage('dpDateTimePicker.endTime.');
        this.endTime = this.endTime.toUpperCase();
        if(!this.isValidTime(this.endTime)){
            this.showErrorMessage('dpDateTimePicker.endTime.', this.endTimeErrorMessage);
        }
        this.emitChanges();
    }
    convertStringToMoment(time: string) : Moment { //Time is in the format of 'hh:mm PM'
        if(!time || time && time.length < 8){
            return moment(INVALID_TIME);
        }
        let colonIndex = time.indexOf(':');
        if(colonIndex < 1){
            return moment(INVALID_TIME);
        }
        let hour = Number(time.substring(0,colonIndex));
        if(hour < 0 || hour > 12){
            return moment(INVALID_TIME);
        }
        let min = Number(time.substring(colonIndex+1,colonIndex+3));
        if(min < 0 || min > 59){
            return moment(INVALID_TIME);
        }
        let ampm = time.substring(colonIndex+3,time.length).trim();
        if(ampm == 'PM'){
            hour+= (hour==12) ? 0 : 12;
        } else if(ampm != 'AM') {
            return moment(INVALID_TIME);
        }
        let momentTime = moment();
        momentTime.hour(hour);
        momentTime.minute(min);
        return momentTime;
    }

    isValidTime(time: string) : boolean {
        return moment(this.convertStringToMoment(time).date()).isValid();
    }

    emitChanges() : void {
        this.dateTimeChange.emit({startDate: this.startDate,
            startTime: this.startTime ,
            endDate: this.endDate,
            endTime: this.endTime,
            allDayEvent: this.allDayEvent
        });
    }

    showErrorMessage(fieldKey: string, message?: string) : void {
        let errorMessage = message? message : INVALID_TIME;
        this.errorService.addDpFieldError(DPError.createCustomDPError(fieldKey+this.fieldKey,
            errorMessage, 'ERROR', 'ERROR'));
    }

    removeErrorMessage(fieldKey: string): void {
        this.errorService.removeDpFieldError(fieldKey+this.fieldKey);
    }

    onStartDateChange(event) : void {
        let tmpDate = event.rawDate;
        if (tmpDate !== this.startDate) {
            this.startDate = tmpDate;
            if(this.allDayEvent && !Utils.isNotValidDate(this.startDate)){
                this.endDate = moment(this.startDate, "YYYY/MM/DD").add(1, 'days').format("YYYY/MM/DD");
            } else {
                this.endDate = this.startDate;
            }

            this.setEndDateIntervals();
            this.emitChanges();
        }
    }

    onEndDateChange(event) : void {
        let tmpDate = event.rawDate;
        if (tmpDate !== this.endDate) {
            this.endDate = tmpDate;
            this.setEndDateIntervals();
            this.emitChanges();
        }
    }

}
