import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {StatementAdjustmentDisplayFinalBaseFeeBuilder} from './statement-adjustment-display-final-base-fee-builder';
import {SoAdjFinalEarlyPossessionFee} from '../model/so-adj-final-early-possession-fee';

export class StatementAdjustmentDisplayFinalEarlyPossessionFeeBuilder {

    static getDisplayItems(soAdj: SoAdjFinalEarlyPossessionFee, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[]{
       return StatementAdjustmentDisplayFinalBaseFeeBuilder.getDisplayItems(soAdj, toBeUsedOnModal, mainBuilder, 'early possession');
    }

}
