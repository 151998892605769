
// This is a sharable service which is used for all the deposit manager API.

import {Injectable} from '@angular/core';
import {HttpClient} from '../core';
import {matterApi, matterResponseKey} from '../matters/shared';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {Observable} from 'rxjs/Observable';
import {ProjectDepositManagerResponse, ProjectDepositView} from '../matters/shared/projectDepositView';
import {SESSION_ID_REQ_PARAM} from '../common';
import {Utils} from '../matters/shared/utils';
import {Deposit} from '../matters/shared/deposit';
import {TemplateStopCode} from '../matters/document-production/template-stop-code';
import {DocumentProductionData} from '../matters/document-production/document-production-data';
import {Matter} from '../matters/shared/matter';
import {DialogConfigParams, DialogService} from '../shared/dialog/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {ERegistrationService} from '../matters/forms/eregistration/eregistration.service';
import {DocumentProductionService} from '../matters/document-production/document-production.service';
import {MatterService} from '../matters/matter.service';
import {ProgressionStatus} from '../matters/statement-adjustment/statement-adjustment';


@Injectable()
export class DepositManagerService {

    constructor(private http: HttpClient,
                public dialogService : DialogService,
                public documentProductionService : DocumentProductionService,
                public matterService: MatterService,
                public eRegistrationService : ERegistrationService) {

    }

    // this method is for filtering the deposits from database
    getDeposits(pageNo:number,pageSize:number, sortQuery:string, sortType:string,  selectedProvince : string, selectedProjects?: string[], selectedDepositType?: string, selectedDateFilter? : string, dateFrom? : string, dateTo?: string, returnAll?: boolean): Observable<ProjectDepositManagerResponse> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string;
        let searchQuery:string  = this.createMatterSearchRequest(selectedProvince, selectedProjects,selectedDepositType, selectedDateFilter, dateFrom, dateTo);
        let sort: string = "&sort=" + sortQuery + "|" + sortType;
        url = `${matterApi.depositManagerList(accountId)}?` + searchQuery + sort;
        if (pageNo) {
            url = url + `&page=${pageNo}&per_page=${pageSize}`;
        }
        if (returnAll) {
            url = url + `&all=${returnAll}`;
        }
        return this.http.get(url).map((res) => {
            let depositsList = res[matterResponseKey.depositsManagerList].map ((item) => {return new ProjectDepositView(item)});
            let depositsTotalCount = res[matterResponseKey.depositsManagerTotalCount] as number;
            let depositsTotalAmount =res[matterResponseKey.depositsManagerTotalAmount] as number;
            let matterIds = res[matterResponseKey.depositsManagerMatterIds];
            return new ProjectDepositManagerResponse( depositsList,depositsTotalCount, depositsTotalAmount, matterIds);
        });
    }

    getSelectedDepositTypeFilter(selectedDepositType) : string {
        switch(selectedDepositType) {
            case 'ALL_DEPOSITS_EXCLUDING_OCCUPANCY':
                return 'isPaidOnOccupancy_EQ_FALSE';
            case 'ALL_DEPOSITS_FORM_4_SENT':
                return 'isForm4Sent_EQ_TRUE';
            case 'ALL_DEPOSITS_FORM_4_NOT_SENT':
                return 'isForm4Sent_EQ_FALSE';
            case 'ONLY_DEPOSITS_ON_OCCUPANCY':
                return 'isPaidOnOccupancy_EQ_TRUE';
        }
    }

    createMatterSearchRequest(selectedProvince : string, selectedProjects?: string[], selectedDepositType?: string, selectedDateFilter? : string, dateFrom? : string, dateTo?: string): string{

        let filter: string = 'provinceCode_IN_'+selectedProvince;
        let selectedProjectsEscaped: string[] = [];

        if (selectedProjects && selectedProjects.length > 0) {
            selectedProjects.forEach((item) => {
                selectedProjectsEscaped.push(Utils.escapeSearchText(item));
            });
            filter = !filter ? '' : Utils.addCommaToFilter(filter);
            filter += 'projectRecordNumber_IN_';
            filter += selectedProjectsEscaped.join('!');
        }

        if(selectedDepositType && selectedDepositType!='ALL_DEPOSITS'){
            filter = !filter ? '' : Utils.addCommaToFilter(filter);
            filter += this.getSelectedDepositTypeFilter(selectedDepositType);
        }

        if(selectedDateFilter && dateFrom && dateTo){
            filter = !filter ? '' : Utils.addCommaToFilter(filter);
            filter +=   selectedDateFilter=='FILTER_BY_DATE' ? 'depositDate_GTEQ_' + dateFrom : 'form4Date_GTEQ_' + dateFrom;
            filter +=   selectedDateFilter=='FILTER_BY_DATE' ? ',depositDate_LTEQ_' + dateTo : ',form4Date_LTEQ_' + dateTo;
        }

        if(selectedDateFilter && dateFrom && !dateTo){
            filter = !filter ? '' : Utils.addCommaToFilter(filter);
            filter +=   selectedDateFilter=='FILTER_BY_DATE' ? 'depositDate_GTEQ_' + dateFrom : 'form4Date_GTEQ_' + dateFrom;
        }

        if(selectedDateFilter && !dateFrom && dateTo){
            filter = !filter ? '' : Utils.addCommaToFilter(filter);
            filter +=   selectedDateFilter=='FILTER_BY_DATE' ? 'depositDate_LTEQ_' + dateTo : 'form4Date_LTEQ_' + dateTo;
        }

        return `filter=${filter}&filterIgnoreCase=false&filterType=ALL`;

        //return 'filter=provinceCode_IN_ON' + '&filterIgnoreCase=false&filterType=ANY';
    }

    exportDepositListCSV(sortQuery:string, sortType:string, selectedProvince : string, selectedProjects?: string[], selectedDepositType?: string, selectedDateFilter? : string, dateFrom? : string, dateTo?: string): void {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let searchQuery = this.createMatterSearchRequest(selectedProvince, selectedProjects,selectedDepositType, selectedDateFilter, dateFrom, dateTo);
        let sort: string = "&sort=" + sortQuery + "|" + sortType;
        let url = `${matterApi.depositManagerListCSV(accountId)}?${SESSION_ID_REQ_PARAM}=` + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId) + '&' + searchQuery + sort;
        window.open(this.http.encodePipe(this.http.normalizeUrl(url)), "_blank");
    }

    exportTarionListCSV(sortQuery:string, sortType:string, selectedProvince : string, selectedProjects?: string[], selectedDepositType?: string, selectedDateFilter? : string, dateFrom? : string, dateTo?: string): void {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        // we have to load prior data also with given time range. So passing null for startDate
        let searchQuery = this.createMatterSearchRequest(selectedProvince, selectedProjects,selectedDepositType, selectedDateFilter, null, dateTo);
        let sort: string = "&sort=" + sortQuery + "|" + sortType;
        let url = `${matterApi.tarionManagerListCSV(accountId)}?${SESSION_ID_REQ_PARAM}=` + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId) + '&' + searchQuery + sort + '&dateFrom='+dateFrom;
        window.open(this.http.encodePipe(this.http.normalizeUrl(url)), "_blank");
    }

    printForm4(matter: Matter, selectedDeposit: Deposit): void {
        // Display the confirm dialog, present user with canceling option.
        let userCancelProduceDocFlag = false;
        let dialogRef : MatDialogRef<any>;
        dialogRef = this.dialogService.confirmDialog('Documents', 'PDF Document is being generated. Please wait.', true, 'Cancel');
        dialogRef.afterClosed().subscribe(res => {
            if(res === undefined) {

            } else {
                userCancelProduceDocFlag = true;
                console.log(">> User cancel the pdf generation, popup return status", res);
                return;
            }
        });

        let documentProductionData;
        if (selectedDeposit.depositPrintFormat == 'FORM_NOT_SENT_ON_SEPARATE_PAGE') {
            //create a different documentProductionData  by looping thru matter.matterPropertyWithCondo.deposits and filtering for form4Sent being false.
            documentProductionData = this.setDocumentProductionDateForMultipleDeposits(matter);
        } else {
            // Pass parameters needed to get to the backend in the fly (not being persisted to the matter)
            documentProductionData = this.setDocumentProductionDate(matter, selectedDeposit);
        }

        // This is needed to pass the matter object to the backend for Doc Gen, Instead of the loading by matter Id.
        let clonedMatter = new Matter(matter);
        clonedMatter.cleanUpMatterBeforeSaving(this.matterService);
        documentProductionData.matter = clonedMatter;

        let documentProductionSubscription = this.documentProductionService.produceProjectForm4(documentProductionData, selectedDeposit.depositPrintFormat)
                                                  .subscribe((response : DocumentProductionData) => {
                                                      const documentId : number = response.matterDocumentIds[0];
                                                      let checkPdfGenStatusIntervalID = window.setInterval(() => {

                                                          this.eRegistrationService.getIdentificationFormsStatus(matter.id, documentId)
                                                              .subscribe(data => {
                                                                  let generationProgressStatus : string = data;
                                                                  if(generationProgressStatus === "COMPLETED" || userCancelProduceDocFlag) {
                                                                      window.clearInterval(checkPdfGenStatusIntervalID);
                                                                      dialogRef.close();
                                                                      if(!userCancelProduceDocFlag) {
                                                                          const contentModalConfig : DialogConfigParams = {
                                                                              title                : 'Documents',
                                                                              message              : 'PDF Document generation is complete. Do you want to open the document?',
                                                                              hideCancelBtn        : false,
                                                                              customConfirmBtnText : 'Open',
                                                                              confirmFunction       :  () => {
                                                                                  this.documentProductionService.openFileInNewWindow(matter.id, documentId, true, false);
                                                                              },
                                                                          };
                                                                          this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(res => { console.log(">> Response from openPdfModal: ", res); });                                                                               }
                                                                  }
                                                              });
                                                      }, 500);
                                                  });
    }

    printMergedForm4(selectedDeposits : ProjectDepositView[]): void {
        // Display the confirm dialog, present user with canceling option.
        let userCancelProduceDocFlag = false;
        let dialogRef : MatDialogRef<any>;
        dialogRef = this.dialogService.confirmDialog('Documents', 'PDF Document is being generated. Please wait.', true, 'Cancel');
        dialogRef.afterClosed().subscribe(res => {
            if(res === undefined) {

            } else {
                userCancelProduceDocFlag = true;
                console.log(">> User cancel the pdf generation, popup return status", res);
                return;
            }
        });

        let matterId: number = selectedDeposits[0].matterId;
        let documentProductionSubscription = this.documentProductionService.produceMergedDepositsForm4(selectedDeposits)
                                                 .subscribe((response : DocumentProductionData) => {
                                                     const documentId : number = response.matterDocumentIds[0];
                                                     let checkPdfGenStatusIntervalID = window.setInterval(() => {

                                                         this.eRegistrationService.getIdentificationFormsStatus(matterId, documentId)
                                                             .subscribe(data => {
                                                                 let generationProgressStatus : string = data;
                                                                 if(generationProgressStatus === "COMPLETED" || userCancelProduceDocFlag) {
                                                                     window.clearInterval(checkPdfGenStatusIntervalID);
                                                                     dialogRef.close();
                                                                     if(!userCancelProduceDocFlag) {
                                                                         const contentModalConfig : DialogConfigParams = {
                                                                             title                : 'Documents',
                                                                             message              : 'PDF Document generation is complete. Do you want to open the document?',
                                                                             hideCancelBtn        : false,
                                                                             customConfirmBtnText : 'Open',
                                                                             confirmFunction       :  () => {
                                                                                 this.documentProductionService.openFileInNewWindow(matterId, documentId, true, false);
                                                                             },
                                                                         };
                                                                         this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(res => { console.log(">> Response from openPdfModal: ", res); });                                                                               }
                                                                 }
                                                             });
                                                     }, 500);
                                                 });
    }

    setDocumentProductionDate(matter: Matter, selectedDeposit: Deposit): DocumentProductionData {

        let documentProductionData = new DocumentProductionData();
        let templates: Array<TemplateStopCode> = [];

        let templateStopCode = new TemplateStopCode();
        templateStopCode.currentAdjustmentDepositId = selectedDeposit.id;

        templateStopCode.adjustmentDepositPrintFormat = selectedDeposit.depositPrintFormat;
        templates.push(templateStopCode);

        documentProductionData.matterId = matter.id;
        documentProductionData.templates = templates;
        return documentProductionData;
    }

    setDocumentProductionDateForMultipleDeposits(matter: Matter): DocumentProductionData {

        let documentProductionData = new DocumentProductionData();
        let templates: Array<TemplateStopCode> = [];

        let notSentDeposits: Deposit[];
        // For Interim we need to filter paidOnOccupancy
        if (matter.adjustmentStatusMode == ProgressionStatus.INTERIM) {
            notSentDeposits =  matter.matterPropertyWithCondo.deposits.filter(d => !d.form4Sent && !d.paidOnOccupancy);
        } else {
            // This is the FINAL mode, paidOnOccupancy can be included
            notSentDeposits =  matter.matterPropertyWithCondo.deposits.filter(d => !d.form4Sent);
        }

        notSentDeposits.forEach((item)=>{
            let templateStopCode = new TemplateStopCode();
            templateStopCode.currentAdjustmentDepositId = item.id;
            templateStopCode.adjustmentDepositPrintFormat =  'FORM_NOT_SENT_ON_SEPARATE_PAGE';
            templates.push(templateStopCode);
        });

        documentProductionData.matterId = matter.id;
        documentProductionData.templates = templates;
        return documentProductionData;
    }

    updateDepositsForm4Date(payload) {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = matterApi.updateDepositsForm4Date(accountId);

        return this.http.put(url, payload);
    }
}
