import {Component} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {DocumentProfileService} from '../../admin/document-profile/document-profile-edit/document-profile.service';
import {StaffProfiles} from '../../admin/staff-profiles/staff-profiles';
import {StaffProfilesService} from '../../admin/staff-profiles/staff-profiles.service';
import {ErrorService} from '../../shared/error-handling/error-service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {Subscription} from 'rxjs';

import * as _ from 'lodash';
import {DialogService} from '../../shared/dialog/dialog.service';

class UserPreferencesContext extends BSModalContext {
    dppmUserLogin : string;
    accessProfileName : string;
    documentProfileName : string;
}

@Component({
               selector    : 'dp-user-preferences-modal-content',
               templateUrl : 'user-preferences.modal.component.html'
           })
export class UserPreferencesModal implements   ModalComponent<UserPreferencesContext> {

    context : UserPreferencesContext;
    documentProfiles : DocumentProfile[];
    staffProfile : StaffProfiles;
    loggedInStaffProfile : Subscription;
    documentProfileId : number;

    constructor(public dialog : DialogRef<UserPreferencesContext>,
                public documentProfileService: DocumentProfileService,
                public staffProfilesService : StaffProfilesService,
                public dialogService : DialogService,
                public errorSerice : ErrorService) {
        this.context = dialog.context;

    }

    ngOnInit() {

        this.documentProfileId = null;
        this.loggedInStaffProfile = this.staffProfilesService.getLoggedInStaffProfile().subscribe(
            (staffProfiles : StaffProfiles) => {
                if(staffProfiles) {
                    this.staffProfile = staffProfiles;
                    this.documentProfileId = (this.staffProfile && this.staffProfile.user) ? this.staffProfile.user.documentProfileId : null;
                }
            });

        this.documentProfiles = [];

        let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        this.documentProfileService.getDocumentProfileList(id).subscribe((data: DocumentProfile[]) => {
            if (!!data && Array.isArray(data)) {
                this.processDocumentProfileList(data);
            }
        });
    }

    public inActiveDocumentProfileList(documentProfiles: DocumentProfile[], id: number): boolean {
        return !!_.find(documentProfiles, (item: DocumentProfile) => {
            return item.id === id;
        });
    }

    public processDocumentProfileList(data: DocumentProfile[]): void {
        if (data !== null && data.length === 1) {
            this.documentProfiles = data;
            this.documentProfileId = data[0].id;

            return;
        }

        if (this.documentProfileId == null) {
            this.documentProfiles = this.documentProfileService.resetDocumentProfileList(data);
            let defaultDocumentProfile: DocumentProfile = _.find(data, (item: DocumentProfile) =>
                                                                 item.defaultFlag === true && item.profileName != null
            );

            this.documentProfileId = defaultDocumentProfile && defaultDocumentProfile.id;

            return;
        }

        if (!this.inActiveDocumentProfileList(data, +this.documentProfileId)) {
                let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
                this.documentProfileService.getById(this.documentProfileId, id)
                    .subscribe((response: DocumentProfile) => {
                        this.documentProfiles =  this.documentProfileService.resetDocumentProfileList(data, response);
                    });
        } else {
               this.documentProfiles =  this.documentProfileService.resetDocumentProfileList(data);
        }
    }

    ok() : void {
        if(this.staffProfile.isValid(this.errorSerice, true)) {

            this.staffProfile.user.documentProfileId = this.documentProfileId;
            this.staffProfilesService.saveMyProfile(this.staffProfile)
                .subscribe((staffProfiles : StaffProfiles) => {
                    this.dialog.close(staffProfiles);
                });
        }
    }


    close() : void {
        this.dialog.close();
    }
}

