import {Injectable} from '@angular/core';
import {accountApi} from '../../admin/accounts/shared/account-api';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {AppConfig} from '../app-configuration';
import {Configuration} from './configuration';
import {HttpClient} from '../../core/httpClient.service';
import {UserConfig} from '../../matters/shared/user-config';
import {Subscription} from 'rxjs/Subscription';
import {UserConfigurationService} from '../user-configuration.service';
import {concatMap} from 'rxjs/operators';


const TIMEZONE_OFFSET_HEADER = 'x-dppmApp-timezone-offset';


function checkServerTimezoneOffset(resp)  {
    if (resp.headers.has(TIMEZONE_OFFSET_HEADER)) {
        const offset = resp.headers.get(TIMEZONE_OFFSET_HEADER);
        if (offset !== undefined && offset !== null && offset.length > 0) {
            sessionStorage.setItem(SESSION_STORAGE_KEYS.timezoneOffset, offset);
        }
    }
    return resp;
}

//This service loads the reference data at startup and cache for later use in app
@Injectable()
export class ReferenceDataService {

    userConfigSub: Subscription;

    constructor(private http: HttpClient, private appConfig: AppConfig, private userConfigurationService: UserConfigurationService) {
    }

    //This method is called by application at startup. It loads the reference data.
    initializeReferenceData(): void {
        this.initializeAppConfig();
    }

    initializeAppConfig(){
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

        if (accountId != null) {
            let url = accountApi.SystemConfigurationUrl.replace('{accountId}', accountId);
            this.http.get(url, false, checkServerTimezoneOffset)
                .map(
                    (response) => {
                        return response[accountApi.ConfigurationTypeResponseKey] || {} as Configuration;
                    }
                )
                .subscribe(
                    (data: Configuration) => {
                        // If data successfully returned then load configurations into AppConfig
                        this.appConfig.loadAppConfig(new Configuration(data));
                        this.initializeUserConfig();
                    }
                );
        }
    }

    initializeUserConfig(): void {
        this.userConfigSub = this.userConfigurationService.getUserConfiguration()
            .subscribe(
                (uc: UserConfig) => {
                    if (uc) {
                        // mostly for first login
/*                        if (!uc.soaDefaultDocumentTemplate && this.appConfig.soaDefaultDocumentTemplate){
                            uc.soaDefaultDocumentTemplate = this.appConfig.soaDefaultDocumentTemplate;
                        }
                        if (!uc.trustLedgerDefaultDocumentTemplate && this.appConfig.trustLedgerDefaultDocumentTemplate){
                            uc.trustLedgerDefaultDocumentTemplate = this.appConfig.trustLedgerDefaultDocumentTemplate;
                        }*/
                        this.userConfigurationService.initializeUserConfig(uc);
                        this.userConfigurationService.updateUserConfiguration();
                    }
                }
            );
    }

    initializeAppConfigurations(){
       return this.initializeAppConfigExt()
            .pipe(concatMap(() => this.initializeUserConfigExt()))
    }

    initializeAppConfigExt(){
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

        if (accountId != null) {
            let url = accountApi.SystemConfigurationUrl.replace('{accountId}', accountId);
            return this.http.get(url, false, checkServerTimezoneOffset)
                .map(
                    (response) => {
                        if(response){
                            let data: Configuration = response[accountApi.ConfigurationTypeResponseKey] || {} as Configuration;
                            this.appConfig.loadAppConfig(new Configuration(data));
                        }
                    }
                )
        }
    }

    initializeUserConfigExt() {
        return this.userConfigurationService.getUserConfiguration()
            .map(
                (response : UserConfig) => {
                    if(response){
                        this.userConfigurationService.initializeUserConfig(response);
                        this.userConfigurationService.updateUserConfiguration();
                        return true;
                    }
                    return false;
                }
            );
    }

}
