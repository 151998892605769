import {Address} from './address';
import {AddressTypes} from './address-types';

export type TitleDetailsType = "AUTO_POPULATED" | "MANUALLY_ENTERED" ;

export interface TitleDetailsTypeDropdown {
    label: string;
    value: TitleDetailsType;
}

export class MortgageContactInfo {
    id : number;
    mortgageId : number;
    firmName : string;
    firmId : number;
    // solicitorName : string;
    // solicitorId : number;
    mailingAddress : Address;
    reportAddress : Address;
    serviceAddress : Address;
    email: string;
    fax : string;
    phone : string;
    dear : string;
    name : string;
    /** About additionalName1 use
     * lable: Signing Officer #1,   Used by gender == CORPORATION || OTHERENTITY
     * lable: Name of Attorney(#1),   Used by gender == MALEPOA || FEMALEPOA
     * lable: Estate Trustee (#1),   Used by gender == Estate
     */
    additionalName1 : string;
    titleOfOfficeHeld1 : string;
    /** About additionalName1 use
     * lable: Signing Officer #2,   Used by gender == CORPORATION || OTHERENTITY
     * lable: Name of Attorney(#2),   Used by gender == MALEPOA || FEMALEPOA
     * lable: Estate Trustee (#2),   Used by gender == Estate
     */
    additionalName2 : string;
    titleOfOfficeHeld2 : string;

    titleDetailsType : TitleDetailsType;
    titleDetailsDescription : string;

    constructor(mortgageContactInfo? : MortgageContactInfo) {

        if(mortgageContactInfo) {
            for(let prop in mortgageContactInfo) {
                if(mortgageContactInfo.hasOwnProperty(prop)) {
                    this[prop] = mortgageContactInfo[prop];
                }
            }

            if(mortgageContactInfo.mailingAddress) {
                this.mailingAddress = new Address(mortgageContactInfo.mailingAddress);
            }
            if(mortgageContactInfo.serviceAddress) {
                this.serviceAddress = new Address(mortgageContactInfo.serviceAddress);
            }
            if(mortgageContactInfo.reportAddress) {
                this.reportAddress = new Address(mortgageContactInfo.reportAddress);
            }
        }

        if (!this.mailingAddress) {
            this.mailingAddress = new Address();
            this.mailingAddress.addressTypeCode = AddressTypes.mailing;
        }
        if (!this.reportAddress) {
            this.reportAddress = new Address();
            this.reportAddress.addressTypeCode = AddressTypes.reportAddress;
            this.reportAddress.sameAsAddressTypeCode = AddressTypes.mailing;
        }
        if (!this.serviceAddress) {
            this.serviceAddress = new Address();
            this.serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
            this.serviceAddress.sameAsAddressTypeCode = AddressTypes.mailing;
        }
    }

    isTitleDetailsManual() : boolean {
        return (this.titleDetailsType  == 'MANUALLY_ENTERED')
    }
}
