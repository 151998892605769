import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {Component} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ErrorService} from '../error-handling/error-service';


class CustomThirdPartyModalContext extends BSModalContext {
    title: string;
    thirdPartyUrl : string;
}

@Component({
               selector     : 'dp-custom-third-party',
               templateUrl  : 'custom-third-party.modal.component.html',
               providers : [ErrorService],
           })
export class CustomThirdpartyModalComponentModal implements   ModalComponent<CustomThirdPartyModalContext> {
    context : CustomThirdPartyModalContext;
    sanitizedThirdPartyUrl: SafeUrl;

    constructor(public dialog : DialogRef<CustomThirdPartyModalContext>,
                public sanitizer: DomSanitizer
    ) {
        this.context = dialog.context;

    }

    ngOnInit() {
        this.sanitizedThirdPartyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.thirdPartyUrl);
    }

    close(): void {
        this.dialog.close();
    }
}
