import {ApplicationError} from './../../../core/application-error';

import {RegisterService} from './register.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {DppmCookieService} from '../../../main/dppm-cookie.service';
import {User} from '../../../matters/shared/user';
import {UtilsService} from '../../../main/utils.service';

@Component({
               selector    : 'dp-register',
               templateUrl : './register.component.html',
               styleUrls      : [
                   '../../unity-login.styles.scss'
               ]
})

export class RegisterComponent implements OnInit {
    user: User = new User();
    lockUserFlag: boolean = false;
    validUser: boolean = true;
    isHelpTextVisible : boolean = false;
    validToken: boolean = true;
    registrationToken: string;
    errorMsg: string;
    submitted : boolean = false;
    fromDataMissing : boolean = false;
    openHelpShutter : boolean = false;
    constructor(public route: ActivatedRoute,
                public router: Router,
                public registerService: RegisterService, public utilsService: UtilsService,
                public cookieService: DppmCookieService) {}

    ngOnInit(): void {

        this.route.params
            .switchMap((params: Params) => {
                this.registrationToken = params['token'];
                return this.registerService.getUser(params['token'])})
            .subscribe(
                (respondUser: User) => {
                    this.validToken = true;
                    this.user = respondUser;
                    this.user.publicAccountId = null;
                    if(!this.user.loginId){
                        this.user.loginId = respondUser.username;
                    }
                    this.user.registrationToken = this.registrationToken;

                    if (this.user.userStatus !== 'ACTIVE') {
                        this.lockUserFlag = true;
                        this.errorMsg = 'Your account is either locked or suspended. Please contact your Administrator.';
                    }
                },
                (error: ApplicationError) => {
                    this.validToken = false;
                    this.errorMsg = error && error.message ? error.message : 'Server error';
                }
            );
    }

    validateUserProfile() {
        this.submitted = true;
        this.errorMsg = '';
        this.fromDataMissing = true;
        if(this.user.publicAccountId) {
            this.fromDataMissing = false;
            this.registerService.validateUser(this.user).subscribe(
                (res: User) => {
                    this.validUser = true;
                    this.registerService.setRegisterUserInSessionStorage(res);
                    this.cookieService.setRememberMeOptions(this.user);
                    this.router.navigate(['first-login/setup-password']);

                },
                (error: ApplicationError) => {
                    this.submitted = false;
                    this.lockUserFlag = (error && error.errorCode === 'app.accountLockedOut') ? true : false;

                    if (this.lockUserFlag) {
                        this.errorMsg = 'Your account is locked. Please contact your Administrator.';
                    } else {
                        this.errorMsg = error && error.message ? error.message : 'Server error';
                    }

                    this.validUser = false;
                }
            );
        }
    }
}
