import { Component, Input, OnInit } from '@angular/core';
 
@Component({
  selector: 'app-spinning',
  templateUrl: './app-spinning.component.html',
  styleUrls: ['./app-spinning.component.scss'],
})
export class SpinningComponent implements OnInit {
  @Input() imageUrl: string =
    'https://piethemesstaging.blob.core.windows.net/spidercubed/default/images/logo-theme.svg';
  @Input() strokeWidth: string = '5';
  @Input() diameter: number = 100;
  @Input() showLogo: boolean = true;
  constructor() {}
 
  ngOnInit(): void {}
}