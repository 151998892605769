import {EventData} from './event-data';
import {EventDateRangeTypes} from './event.constants';
import {PROJECT_SALE_MATTER_TYPE} from '../shared-main/constants';
import {BaseEntity} from '../shared/BaseEntity/base-entity';

export class EventListState {
    isCalendarViewActive: boolean = false;
    isNew: boolean = true;
    currentDateRange: string;
    rows: EventData[] = [];
    selectedStatus: string = 'ACTIVE';
    selectedEvents: EventData[] = [];
    searchText: string;
    // lastSearchText: string;
    sortQuery: string = 'startDate';
    sortType: string = 'ASC';
    // matterTypeRowData : any[] = []
    lawyersList: any[] = [];
    clerksList: any[] = [];
    projectList: any[] = [];
    provinceTypes: any[] = [];
    selectedLawyers: string[] = [];
    selectedClerks: string[] = [];
    selectedProjects: string[] = [];
    selectedEventTypes: string[] = [];
    mattterTypes: string[] = [];
    selectedProvinces: string[] = [];
    selectedStatuses: string[] = [];
    pageNo: number = 1;
    listEndReached: boolean = false;
    scrollY: number;
    // currentSearchView : string = 'recent';
    // radioStatus : string = 'ACTIVE';
    // showFilters : boolean = false;
    eventDate: string;
    isMultiSelectVisible: boolean = false;
    isStateInitialized : boolean = false;

    isNext30DaysView(): boolean {
        return this.currentDateRange == EventDateRangeTypes.NEXT30DAYS;
    }

    isDayView(): boolean {
        return this.currentDateRange == EventDateRangeTypes.DAY;
    }

    isWeekView(): boolean {
        return this.currentDateRange == EventDateRangeTypes.WEEK;
    }

    isMonthView(): boolean {
        return this.currentDateRange == EventDateRangeTypes.MONTH;
    }

    get isProjectSaleMatterTypeFilterSelected(): boolean {
        return this.mattterTypes && this.mattterTypes.some(item => item == PROJECT_SALE_MATTER_TYPE);
    }
}

export class EventAvailabilityState extends BaseEntity {
    constructor(eventAvailabilityState?: EventAvailabilityState) {
        super(eventAvailabilityState);
        if (eventAvailabilityState) {
            if (eventAvailabilityState.resourceData) {
                this.resourceData = [];
                eventAvailabilityState.resourceData.forEach((item) => {
                    this.resourceData.push(new EventAvailabilityResource(item));
                });
            }
        }


    }

    resourceData: EventAvailabilityResource[] = [];
    eventStartDate: string;
    eventEndDate: string;
    eventStartTime: string;
    eventEndTime: string;
    eventDateRange: string;
    selectedEventTypes: string[] = [];
    selectedStatuses: string[] = [];
    openInModal: boolean;
    isMultipleDateAvailable: boolean;
    isOpenForScheduleAssistant: boolean;
    subject: string;

    isDayView(): boolean {
        return this.eventDateRange == EventDateRangeTypes.DAY;
    }

    isWeekView(): boolean {
        return this.eventDateRange == EventDateRangeTypes.WEEK;
    }

    isMonthView(): boolean {
        return this.eventDateRange == EventDateRangeTypes.MONTH;
    }

    isCustomView(): boolean {
        return this.eventDateRange == 'CUSTOM';
    }
}

export class EventAvailabilityResource extends BaseEntity {
    contactId: number;
    resourceName: string;

    constructor(eventAvailabilityResource?: EventAvailabilityResource) {
        super(eventAvailabilityResource);
    }
}

