import {Directive, ElementRef, Input, Renderer2, SimpleChanges} from '@angular/core';
import {NgModel} from '@angular/forms';
import {Logger} from '@nsalaun/ng-logger';

declare var jQuery : any;

@Directive({
    selector: '[ngModel][dpPlaceholder]'
})

export class DpPlaceholderDirective {

    constructor(private el: ElementRef, private model: NgModel, private logger : Logger, private renderer: Renderer2) {
    }

    @Input('dpPlaceholder') placeholder: string;
    label: any;
    text: any;

    // @HostListener('input', ['$event'])
    // onInput() {
    //     // react to changes in the host input field
    //     this.setPaceholderVisibility();
    // }

    ngOnInit() : void {
        this.model.valueChanges.subscribe(this.onValueChanges);
    }

    ngAfterViewInit(){
        let hostWidth = this.el.nativeElement.clientWidth;

        // label containing the "placeholder" text, positioned on top of the host input field
        this.label = this.renderer.createElement('label');

        /* All these styles could be moved into a class,
           but this way the directive is self-contained */
        this.renderer.setStyle(this.label, 'color', '#999999');
        this.renderer.setStyle(this.label, 'position', 'absolute');
        this.renderer.setStyle(this.label, 'left', '22px');
        this.renderer.setStyle(this.label, 'top', '9px');
        this.renderer.setStyle(this.label, 'font-size', '12px');
        this.renderer.setStyle(this.label, 'font-style', 'italic');
        this.renderer.setStyle(this.label, 'pointer-events', 'none');
        this.renderer.setStyle(this.label, 'width', hostWidth-30 +'px');
        this.renderer.setStyle(this.label, 'height', '14px');
        this.renderer.setStyle(this.label, 'white-space', 'nowrap');
        this.renderer.setStyle(this.label, 'text-overflow', 'ellipsis');
        this.renderer.setStyle(this.label, 'overflow-x', 'hidden');
        this.renderer.setStyle(this.label, 'overflow-y', 'hidden');

        this.text = this.renderer.createText(this.placeholder);
        this.renderer.appendChild(this.label, this.text);

        // insert our label in the DOM before the host input field
        this.el.nativeElement.parentNode.insertBefore(this.label, this.el.nativeElement);
        setTimeout(() => { this.setPaceholderVisibility() }, 100);
    }

    ngOnChanges(changes: SimpleChanges) {
        // this will trigger when Input parameters change
        let newPlaceholder = changes['placeholder'].currentValue;
        const parent = this.renderer.parentNode(this.el.nativeElement);

        jQuery(parent).find("label").html(newPlaceholder);
        setTimeout(() => { this.setPaceholderVisibility() }, 100);
    }

    private setPaceholderVisibility(): void {
        // show or hide the "placeholder", based on the host input model value
        if (this.model.value) {
            this.renderer.setStyle(this.label, 'visibility', 'hidden');
        } else {
            this.renderer.setStyle(this.label, 'visibility', 'visible');
        }
    }

    private onValueChanges = (newValue: any): void => {
        // show or hide the "placeholder", based on the ngModel value
        if(newValue) {
            this.renderer.setStyle(this.label, 'visibility', 'hidden');
        } else {
            this.renderer.setStyle(this.label, 'visibility', 'visible');
        }
    }

}
