import {Injectable} from '@angular/core';
import {TabsService} from '../core/tabs.service';
import {HttpClient} from '../core';
import {Contact} from './shared/contact';
import {MatterParticipantWrapper} from './shared/matter-participant-wrapper';
import {Matter, MatterParticipant} from './shared';
import {ContactQueryService} from '../contact/contact-query.service';
import {ContactService} from '../shared-main/contact.service';
import {
    CondoManagedTypeConstValue,
    FlaErrorValues,
    FlaStatementType,
    MainClientFlaErrorValues,
    OtherSideFlaErrorValues,
    RevertToGlobalMessage,
    SnapshotBurgerMenuActions,
} from '../shared-main/constants';

import {ErrorService} from '../shared/error-handling/error-service';
import {PurchaserFamilyLawAct} from './purchaser/family-law-act/purchaser-family-law-act';
import {MatterParticipantRole, MatterParticipantRoleTypes} from './shared/matter-participant-role-types';
import {ApplicableProvisionOptionsTypes, FamilyLawAct} from './shared/fla-data';
import {DPError} from '../shared/error-handling/dp-error';
import {DialogService} from '../shared/dialog/dialog.service';
import {FamilyLawActComponent} from './purchaser/family-law-act/family-law-act.component';
import {GenderTypes} from '../contact/contact-type-mapping';
import {UUIDUtil} from '../main/uuid-util';
import {LockScreenService} from '../core/lock-screen.service';

//This is a common service for all matter participant related functions
@Injectable()
export class MatterParticipantService {

    constructor(private http: HttpClient,
                private dialogService: DialogService,
                private tabsService: TabsService,
                private errorService: ErrorService,
                private contactService: ContactService,
                private contactQueryService: ContactQueryService, private lockScreenService : LockScreenService) {

    }

    //This method updates participant state and lock source contact on tab opening. In some cases contact needs to be locked as soon as user goes on that
    // tab as it doesn't has shutter.
    updateParticipantStateOnTabOpening(matter: Matter, matterParticipantWrapper: MatterParticipantWrapper, callback?: Function): void {
        //If contact is already locked then we dont need to do anything as this matter has the latest state
        if (!matterParticipantWrapper.matterParticipant.sourceContactLockAcquired) {
            //Making two calls here first load the contact without locking just to check if it's global or out-of-sync state. If it's not then acquiring the
            // lock in second call. to optimize it can be done in single call but that requires some other changes
            this.contactQueryService.getContactForMatter(matterParticipantWrapper.matterParticipant.contact.sourceContactId)
                .subscribe((sourceContact: Contact) => {
                    matterParticipantWrapper.editMode = false;
                    //Update the wrapper with latest state of source contact to check it's stale or not
                    this.updateParticipantWrapperState(matterParticipantWrapper, sourceContact);

                    //In addition to other conditions, before sending lock request we are also checking that matter participant shouldn't be obsolete.
                    //As we can have a scenario where user could leave the tab before the above call (getContactForMatter) is resolved, therefore pursuing with
                    // lock if user still on same tab.
                    if (matter.locked
                        || (matterParticipantWrapper.matterParticipant && matterParticipantWrapper.matterParticipant.sourceContact && matterParticipantWrapper.matterParticipant.sourceContact.isOwnedBySystemAccount)
                        || matterParticipantWrapper.isStale
                        || matterParticipantWrapper.isClearFlagWithoutUpdatingMatter || matterParticipantWrapper.obsolete) {
                        //Do nothing
                    } else {
                        //Delaying the lock acquire as there might be unlock request in flight from another matter tab
                        setTimeout(() => {
                            this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, true).subscribe((result: Contact) => {
                                //Update the wrapper with latest state of source contact
                                this.updateParticipantWrapperState(matterParticipantWrapper, result);
                            });
                        }, 200);

                    }

                    if (callback) {
                        callback(sourceContact.subContacts);
                    }
                });
        }
    }

    unlockParticipantOnTabClose(matterParticipantWrapper: MatterParticipantWrapper, matter: Matter): void {
        // Add "null" check condition to avoid breaking UI
        const participant: MatterParticipant = matterParticipantWrapper && matterParticipantWrapper.matterParticipant;
        console.log('Unlocking contact from matter '
            + (matter && matter.id) + ' from wrapper matter '
            + (participant && participant.matterId) + ' source contact' + ' lock '
            + (participant && participant.sourceContactLockAcquired) + ' contact dirty flag is '
            + (participant && participant.contact && participant.contact.isDirty) + ', obsolete flag '
            + (matterParticipantWrapper && matterParticipantWrapper.obsolete));
        //On closing tab release the lock from source contact if it was not edited
        if (participant && participant.sourceContactLockAcquired && !participant.contact.isDirty) {
            this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, false).subscribe((result: Contact) => {
                //Update the wrapper with latest state of source contact
                this.updateParticipantWrapperState(matterParticipantWrapper, result);
            });
        }
    }


    //This method updates the participant expanded flag when shutter clicked, in addition it also locks or unlocks the contact.
    async updateParticipantStateOnShutterClick(matter: Matter, matterParticipantWrapper: MatterParticipantWrapper): Promise<void> {
        //Target shutter expansion status
        const toggledExpandedStatus: boolean = !matterParticipantWrapper.expanded;
        // "toggledExpandedStatus true" means it will open shutter and lock this contact
        // "toggledExpandedStatus false" means it will close shutter and unlock this contact if not it's not dirty. If dirty then contact remains locked
        //Making sure sourceContact exists otherwise if user tries to un-shutter right after page is loaded then it breaks
        if (matterParticipantWrapper.matterParticipant && matterParticipantWrapper.matterParticipant.sourceContact) {
            let matterParticipant: MatterParticipant = matterParticipantWrapper.matterParticipant;

            //If matter is already locked then just open or close the shutter. No need to update contact lock status
            if (matter && matter.locked) {
                matterParticipantWrapper.expanded = toggledExpandedStatus;
            } else {//Else update contact's lock status depending upon shutter open or closed and participant's state

                // "toggledExpandedStatus true" means it will open shutter and lock this contact
                if (toggledExpandedStatus) {
                    //If user tries to open the shutter while participant state is being calculated then just return and don't open the shutter.
                    if (matterParticipantWrapper.isLoadingParticipantState) {
                        return;
                    }

                    //If this participant has already acquired the lock on this contact or it's a global source contact then just open the shutter.
                    //If contact is stale or have cleared the flag previously then don't acquire the lock
                    if (matterParticipant.sourceContactLockAcquired || matterParticipant.sourceContact.isOwnedBySystemAccount
                        || matterParticipantWrapper.isStale || matterParticipantWrapper.isClearFlagWithoutUpdatingMatter) {
                        matterParticipantWrapper.expanded = toggledExpandedStatus;
                    } else {
                        matterParticipantWrapper.isLoadingParticipantState = true;
                        //Try to acquire the lock on source contact. Setting the participant's expanded flag only after getting the result for lock source
                        // contact. As shutter would open in the read-only or editable mode depending upon the lock result.
                        try {
                            let result: Contact = await this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, true).toPromise();
                            //migration contacts will get missing data added to it by the UI when loaded
                            //Due to this if this is a migration contact it should be marked as dirty
                            matterParticipantWrapper.matterParticipant.contact.isDirty = this.isFirstLoadOfMigrationContact(matterParticipantWrapper.matterParticipant.contact);
                            // Update the wrapper with latest state of source contact
                            this.updateParticipantWrapperState(matterParticipantWrapper, result);
                            matterParticipantWrapper.expanded = toggledExpandedStatus;
                        } finally {
                            matterParticipantWrapper.isLoadingParticipantState = false;
                        }
                    }
                } else { // "toggledExpandedStatus false" means it will close shutter and unlock this contact.

                    //If participant had acquired the lock but did not edit the contact then unlock it else just close the shutter
                    if (matterParticipant.sourceContactLockAcquired && !matterParticipantWrapper.matterParticipant.contact.isDirty) {
                        try {
                            let result: Contact = await this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, false).toPromise();
                            // Update the wrapper with latest state of source contact
                            this.updateParticipantWrapperState(matterParticipantWrapper, result);
                            matterParticipantWrapper.expanded = toggledExpandedStatus;
                        } finally {
                            matterParticipantWrapper.isLoadingParticipantState = false;
                        }
                    } else {
                        matterParticipantWrapper.expanded = toggledExpandedStatus;
                    }
                }
            }
        }
    }

    editContactAsPrivateCopy(matterParticipantWrapper: MatterParticipantWrapper) {
        if (matterParticipantWrapper.matterParticipant) {
            //To edit as private acquire the lock on source contact and if it's successful then update proxy's flag
            this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, true).subscribe((result: Contact) => {
                if (matterParticipantWrapper.matterParticipant.sourceContactLockAcquired) {
                    matterParticipantWrapper.matterParticipant.contact.isDirty = true;
                    matterParticipantWrapper.matterParticipant.sourceContact.proxyEdited = true;
                } else {
                    //Update the wrapper with latest state of source contact
                    this.updateParticipantWrapperState(matterParticipantWrapper, result);
                }
            });
        }
    }

    revertToGlobal(matterParticipantWrapper: MatterParticipantWrapper, callback: Function) {
        this.dialogService.confirm('Confirmation', RevertToGlobalMessage, false).subscribe((response: any) => {
            if (response) {
                this.revertToGlobalAction(matterParticipantWrapper, callback);
            }
        });
    }

    revertToGlobalAction(matterParticipantWrapper: MatterParticipantWrapper, callback: Function) {
        if (matterParticipantWrapper.matterParticipant) {
            this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, true).subscribe((sourceContact: Contact) => {
                if (matterParticipantWrapper.matterParticipant.sourceContactLockAcquired) {
                    //After finishing the reverting global action, it will unlock this contact
                    //Reloading the actual global ProxyGlobal contact from server
                    this.contactService.revertProxyToGlobal(sourceContact.id)
                        .subscribe((revertedSourceContact: Contact) => {
                            //Re-enroll the reverted contact into matter participant as snapshot
                            matterParticipantWrapper.matterParticipant.enrolContactToParticipant(revertedSourceContact, true);
                            //Snapshot should not have any sub contacts but not sure if it applies in all the cases. if yes, then probably this code should
                            // be moved into "enrolContactToParticipant"
                            matterParticipantWrapper.matterParticipant.contact.subContacts = [];

                            //After reverting to global releasing the lock this can be moved to server side.
                            this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, false).subscribe((result: Contact) => {
                                console.log('Lock released after the contact\'s clear flag has been updated');
                                this.updateParticipantWrapperState(matterParticipantWrapper, result);
                            });


                            //After revert execute callback method with successful result
                            callback(true, matterParticipantWrapper);
                        });
                } else {
                    this.updateParticipantWrapperState(matterParticipantWrapper, sourceContact);
                    //If cannot acquired then execute callback method with unsuccessful result
                    callback(false, matterParticipantWrapper);
                }

            });
        }
    }

    //This method updates the pulls the data into matter from source contact.
    updateMatterContactFromSourceContact(matterParticipantWrapper: MatterParticipantWrapper, action: string, callback: Function, matter: Matter): void {
        if (matterParticipantWrapper.matterParticipant) {
            let processedSuccessfully: boolean = false;
            const oldSnapshotReference: Contact = new Contact(matterParticipantWrapper.matterParticipant.contact);
            this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, true).subscribe(async (sourceContact: Contact) => {
                if (matterParticipantWrapper.matterParticipant.sourceContactLockAcquired) {
                    if (SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT === action) {
                        matterParticipantWrapper.matterParticipant.contact.update(sourceContact);
                        matter.getAllSigners(matterParticipantWrapper.matterParticipant).forEach(matterParticipant => {
                                this.tabsService.unLockSourceContactForParticipant(matterParticipant);
                                (<any>matter.matterParticipants).remove(matterParticipant);
                        });
                        //After wait signing officer finish the update, then the following update
                        await this.createMatterParticipantAssociatedContactForClient(matterParticipantWrapper.matterParticipant.contact, matterParticipantWrapper.matterParticipant, matter, matterParticipantWrapper);

                    }
                    processedSuccessfully = true;

                }
                //Update the wrapper with latest state of source contact
                this.updateParticipantWrapperState(matterParticipantWrapper, sourceContact);
                callback(processedSuccessfully, matterParticipantWrapper, oldSnapshotReference, sourceContact);
            });
        }
    }


    async createMatterParticipantAssociatedContactForClient(contact: Contact, parentMatterParticipant: MatterParticipant, matter: Matter, matterParticipantWrapper?: MatterParticipantWrapper, isContactSnapShotOnly?: boolean): Promise<void> {
        if (contact.contactAssociations) {
            try {
                this.lockScreenService.lockForUpdate = true;
                for (let i = 0; i < contact.contactAssociations.length; i++) {
                    let ac = contact.contactAssociations[i];
                    if (ac.associatedContact) {
                        let contactId = !ac.associatedContact.snapshotFlag ? ac.associatedContact.id : ac.associatedContact.sourceContactId;
                        if (!!ac.associatedContact.legacySigner || !contactId) {
                            let associatedContactParticipant = this.createNewMatterSigningOfficerContact(ac.associatedContact, parentMatterParticipant, true, matter);
                            associatedContactParticipant.matterParticipantRole = parentMatterParticipant.signingOfficerParticipantRole;
                            associatedContactParticipant.associatedContactTitle = ac.associatedContactTitle;
                            associatedContactParticipant.parentParticipantId = parentMatterParticipant.matterParticipantId;
                            if (isContactSnapShotOnly || ac.associatedContact.legacySigner) {
                                if (!associatedContactParticipant.contact.id) {
                                    associatedContactParticipant.contact.id = UUIDUtil.getUUID();
                                }
                            }
                        } else {
                            const contactForMatter: Contact = await (this.contactQueryService.getContactForMatter(contactId)).toPromise();
                            let associatedContactParticipant = this.createNewMatterSigningOfficerContact(contactForMatter, parentMatterParticipant, false, matter);
                            associatedContactParticipant.matterParticipantRole = parentMatterParticipant.signingOfficerParticipantRole;
                            associatedContactParticipant.associatedContactTitle = ac.associatedContactTitle;
                            associatedContactParticipant.parentParticipantId = parentMatterParticipant.matterParticipantId;

                        }
                    }
                }
            }
            finally {
                this.lockScreenService.lockForUpdate = false;
            }
            if(isContactSnapShotOnly && !parentMatterParticipant.contact.sourceContactId) {
                parentMatterParticipant.contact.contactAssociations = [];
            }
            if (matterParticipantWrapper && !!matterParticipantWrapper.expanded) {
                matterParticipantWrapper.expanded = false;
                setTimeout(() => {
                    matterParticipantWrapper.expanded = true;
                }, 0);
            }
        }
    }

    copyAssociatedContactsForSnapshot(sourceMatterParticipant: MatterParticipant, targetMatterParticipant, sourceMatter: Matter, targetMatter: Matter) : void {
        if(sourceMatterParticipant && sourceMatterParticipant.contact && !sourceMatterParticipant.contact.sourceContactId){
            let childrenParticipants = sourceMatter.getChildMatterParticipants(sourceMatterParticipant);
            childrenParticipants.forEach((childMp)=>{
                this.copyAndUpdateSignerMatterParticipant(childMp, targetMatterParticipant, targetMatter);
            })
        }
    }

    copyAndUpdateSignerMatterParticipant(sourceSigner: MatterParticipant, parentMatterParticipant: MatterParticipant, matter: Matter) : void {
        let associatedContactParticipant = this.createNewMatterSigningOfficerContact(sourceSigner.contact, parentMatterParticipant, true, matter);
        associatedContactParticipant.matterParticipantRole = parentMatterParticipant.signingOfficerParticipantRole;
        associatedContactParticipant.associatedContactTitle = sourceSigner.associatedContactTitle;
        associatedContactParticipant.parentParticipantId = parentMatterParticipant.matterParticipantId;
        if (!associatedContactParticipant.contact.id) {
            associatedContactParticipant.contact.id = UUIDUtil.getUUID();
        }
    }


    public getMaxMatterSigningOfficerPriority(matter: Matter , parentMatterParticipant: MatterParticipant): number {
        let max: number = 0;
        let signerList = matter.getAllSigners(parentMatterParticipant);
        for (let i = 0; i < signerList.length; i++) {
            let signingOfficer = signerList[i];
            if (!signingOfficer) {
                continue;
            }
            if (!signingOfficer.matterParticipantPriority) {
                continue;
            }
            if (signingOfficer.matterParticipantPriority > max) {
                max = signingOfficer.matterParticipantPriority;
            }
        }
        return max;
    }


    createNewMatterSigningOfficerContact(contact: Contact, parentMatterParticipant: MatterParticipant, createSnapShotWithoutSource: boolean, matter: Matter): MatterParticipant {
        let person: any = {};
        let participant: MatterParticipant = null;

        //Creating snapshot from source contact to be added into matter participant

        let snapshot: Contact = new Contact();
        snapshot.createNewContactClone(contact);
        snapshot.snapshotFlag = true;
        if (!createSnapShotWithoutSource) {
            snapshot.sourceContactId = contact.id;
        }
        person.contact = snapshot;


        person.matterParticipantRole = parentMatterParticipant.signingOfficerParticipantRole;
        person.matterParticipantPriority = this.getMaxMatterSigningOfficerPriority(matter,parentMatterParticipant) + 1;
        person.signingMatter = true;
        let signerIndex = matter.matterParticipants.findIndex(item => item.parentParticipantId == parentMatterParticipant.matterParticipantId && item.isSigningOfficer);
        if (signerIndex < 0) {
            person.primary = true;
        }

        participant = new MatterParticipant(person);
        participant.contact.lastSyncedFromSource = contact.lastUpdatedTimeStamp;
        participant.sourceContact = contact;

        if (!matter.matterParticipants) {
            matter.matterParticipants = [];
        }
        participant.parentParticipantId = parentMatterParticipant.matterParticipantId;
        matter.matterParticipants.push(participant);

        return participant;
    }

    //This method updates the wrapper data like lock, stale & other flags based on the source contact data. Instead of putting this logic in each component, all
    // of them should use this common method while creating wrapper structure.
    //This method should not be called if source contact is already locked
    //Shall we need to check if source contact is already locke when call this method ?
    updateParticipantWrapperState(matterParticipantWrapper: MatterParticipantWrapper, sourceContact: Contact) {
        // Add "null' check to avoid break UI
        if (!matterParticipantWrapper.matterParticipant) {
            return;
        }

        matterParticipantWrapper.matterParticipant.sourceContact = sourceContact;

        matterParticipantWrapper.isStale = matterParticipantWrapper.matterParticipant.contact?.isStaleContact(sourceContact);
        matterParticipantWrapper.isClearFlagWithoutUpdatingMatter = matterParticipantWrapper.matterParticipant.contact?.isContactStaleFlagClearedWithoutUpdatingMatter(sourceContact);
        matterParticipantWrapper.canBeUpdatedFromSourceContact = matterParticipantWrapper.isStale && !!matterParticipantWrapper.sourceContactId;
        matterParticipantWrapper.lastUpdatedOn = sourceContact.lastUpdatedOnMsg;

        //If wrapper hasn't already got the lock and source contact is locked by other user or in same session by other matter then set the lock fields on
        // wrapper
        if (!matterParticipantWrapper.matterParticipant.sourceContactLockAcquired &&
            (sourceContact.locked || (matterParticipantWrapper.matterParticipant.contact && this.tabsService.isContactAlreadyLocked(matterParticipantWrapper.matterParticipant.contact)))) {
            matterParticipantWrapper.lockedSourceContact = sourceContact;
            matterParticipantWrapper.isLockedElsewhere = true;
            matterParticipantWrapper.matterParticipant.sourceContactLockAcquired = false;
        }
    }

    //This method should be used for removing matter participant. it makes sure source contact is unlocked if it was locked by this user before deletion.
    //In some cases participant might be created with a snapshot contact only without source contact. If no sourceContact then no need to send unlock request
    removeParticipant(matterParticipantWrapper: MatterParticipantWrapper, matter: Matter, unlockSourceContact: boolean = true) {
        if (unlockSourceContact && matterParticipantWrapper.matterParticipant && matterParticipantWrapper.matterParticipant.sourceContactLockAcquired) {
            this.tabsService.unLockSourceContact(matterParticipantWrapper);
        }
        // Clean consentedSpouseParticipant in parentParticipant if this participant is consenting spouse
        if(matterParticipantWrapper.matterParticipant.matterParticipantRole == MatterParticipantRoleTypes.CONSENTING_SPOUSE){
            this.cleanParentParticipantConsentingSpouse(matter.matterParticipants && matter.matterParticipants.find(mp=>mp.matterParticipantId == matterParticipantWrapper.matterParticipant.parentParticipantId));
        } else {
            // Remove consentedSpouseParticipant in matterParticipant list of matter  and unlock source contact
            let consentedSpouseParticipant =  matter.getConsentSpouseParticipantByParentParticipantId(matterParticipantWrapper.matterParticipant.matterParticipantId)
            if(consentedSpouseParticipant){
                this.removeConsentingSpouse(consentedSpouseParticipant, matter);
            }
        }


        matter.removeMatterParticipant(matterParticipantWrapper.matterParticipant);
        //Update wrapper flags
        matterParticipantWrapper.matterParticipant = null;
        matterParticipantWrapper.dataModel = null;
        matterParticipantWrapper.editMode = true;
        matterParticipantWrapper.isStale = false;
        matterParticipantWrapper.expanded = false;
        matterParticipantWrapper.isLockedElsewhere = false;
        matterParticipantWrapper.isClearFlagWithoutUpdatingMatter = false;

    }

    cleanParentParticipantConsentingSpouse(parentParticipant : MatterParticipant) {
        if(parentParticipant) {
            let fla: FamilyLawAct = parentParticipant.getConsentedSpouseFamilyLawAct();
            if (fla) {
                fla.consentedSpouseParticipant = null;
                fla.consentSpouseMatterParticipantId = null;
            }
        }
    }

    removeConsentingSpouse(consentedSpouseParticipant : MatterParticipant, matter : Matter) {
        if(consentedSpouseParticipant){
            // We need a wrapper shell for call service method although it looks a little weird
            let wrapper : MatterParticipantWrapper = new MatterParticipantWrapper();
            wrapper.matterParticipant = consentedSpouseParticipant;
            this.removeParticipant(wrapper, matter);
        }

    }

    updateWrappersFlaErrorMessage(wrappers: MatterParticipantWrapper[], matterParticipantRole: MatterParticipantRole, isMainClient: boolean, provinceCode: string, matter: Matter): void {
        if (Array.isArray(wrappers)) {
            wrappers.forEach((wrapper: MatterParticipantWrapper) => {
                if (wrapper.matterParticipant) {
                    //Clean all FLA error message
                    this.removeFlaSpouseNotAllowedErr(wrapper.matterParticipant.matterParticipantId, isMainClient, provinceCode);
                    this.removeFlaSpouseNameRequiredErr(wrapper.matterParticipant.matterParticipantId, isMainClient, provinceCode);
                    wrapper.spouseNameOptions = PurchaserFamilyLawAct.buildSpouseNameOptions(wrapper.matterParticipant, matterParticipantRole, matter);
                    if (!PurchaserFamilyLawAct.isSingleFamilyLawAct(wrapper.matterParticipant, matterParticipantRole, matter)) {
                        wrapper.matterParticipant.familyLawActs.forEach((fla: FamilyLawAct) => {
                            if ((fla.familyLawActStatementType === 'MATTER_PARTICIPANT_SPOUSE' || (fla.applicableProvision == ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY && fla.familyLawActStatementType === FlaStatementType.MARITAL_STATUS_NB))
                                && !fla.spouseMatterParticipantId) {
                                this.addFlaSpouseNameRequiredErr(wrapper.matterParticipant.matterParticipantId, isMainClient, provinceCode);
                            }
                        });
                    } else {
                        wrapper.matterParticipant.familyLawActs.forEach((fla: FamilyLawAct) => {
                            if ((fla.familyLawActStatementType === 'MATTER_PARTICIPANT_SPOUSE' || (fla.applicableProvision == ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY && fla.familyLawActStatementType === FlaStatementType.MARITAL_STATUS_NB))
                                && !fla.spouseMatterParticipantId) {
                                this.addFlaSpouseNotAllowedErr(wrapper.matterParticipant.matterParticipantId, isMainClient, provinceCode);
                            }

                        });
                    }
                }
            });
        }
    }

    getFlaSpouseNameRequiredMsgKey(isMainClient: boolean, provinceCode: string): string {
        let fieldPrefix: string;
        if (isMainClient) {
            fieldPrefix = MainClientFlaErrorValues.fieldPrefix;
        } else {
            fieldPrefix = OtherSideFlaErrorValues.fieldPrefix;
        }
        switch (provinceCode) {
            case 'NB':
                return fieldPrefix + FlaErrorValues.flaSpouseNameRequiredFieldKeyNB;
            case 'SK':
            case 'MB':
                return fieldPrefix + FlaErrorValues.spouseOwnerNameRequiredFieldKey;
            default:
                return fieldPrefix + FlaErrorValues.flaSpouseNameRequiredFieldKey;
        }
    }

    getFlaSpouseNotAllowedMsgKey(isMainClient: boolean, provinceCode: string): string {
        let fieldPrefix: string;
        if (isMainClient) {
            fieldPrefix = MainClientFlaErrorValues.fieldPrefix;
        } else {
            fieldPrefix = OtherSideFlaErrorValues.fieldPrefix;
        }
        switch (provinceCode) {
            case 'NB':
                return fieldPrefix + FlaErrorValues.flaSpouseNotAllowedFieldKeyNB;
            case 'SK':
            case 'MB':
                return fieldPrefix + FlaErrorValues.spouseOwnerNotAllowedFieldKey;
            default:
                return fieldPrefix + FlaErrorValues.flaSpouseNotAllowedFieldKey;
        }
    }

    //The issue is the identifier is changed after saving matter. We can’t catch the pre-added error.
    // Use matterParticipantId to replace matterParticipant Identifier
    removeFlaSpouseNotAllowedErr(matterParticipantId: number, isMainClient: boolean, provinceCode: string) {
        this.errorService.removeDpFieldError(this.getFlaSpouseNotAllowedMsgKey(isMainClient, provinceCode),
            FlaErrorValues.flaSpouseNameFieldId + matterParticipantId);
    }

    removeFlaSpouseNameRequiredErr(matterParticipantId: number, isMainClient: boolean, provinceCode: string) {
        this.errorService.removeDpFieldError(this.getFlaSpouseNameRequiredMsgKey(isMainClient, provinceCode),
            FlaErrorValues.flaSpouseNameFieldId + matterParticipantId);
    }

    // The issue is the identifier is changed after saving matter. We can’t catch the pre-added error.
    // Use matterParticipantId to replace matterParticipant Identifier
    // In MatterParticipant constructor, UI set matterParticipantId with UUIDUtil.getUUID(). It means matterParticipantId  is never null.
    addFlaSpouseNotAllowedErr(matterParticipantId: number, isMainClient: boolean, provinceCode: string) {
        this.errorService.addDpFieldError(
            DPError.createDPError(this.getFlaSpouseNotAllowedMsgKey(isMainClient, provinceCode),
                FlaErrorValues.flaSpouseNameFieldId + matterParticipantId,
                isMainClient ? MainClientFlaErrorValues.containerId : OtherSideFlaErrorValues.containerId,
                null,
                FlaErrorValues.fieldIndexId + '_' + matterParticipantId));
    }

    addFlaSpouseNameRequiredErr(matterParticipantId: number, isMainClient: boolean, provinceCode: string) {
        this.errorService.addDpFieldError(
            DPError.createDPError(this.getFlaSpouseNameRequiredMsgKey(isMainClient, provinceCode),
                FlaErrorValues.flaSpouseNameFieldId + matterParticipantId,
                isMainClient ? MainClientFlaErrorValues.containerId : OtherSideFlaErrorValues.containerId,
                null,
                FlaErrorValues.fieldIndexId + '_' + matterParticipantId));
    }

    async addCondoCorporationToMatter(condoCorporationSourceContactId: number, matter: Matter): Promise<void> {
        if (matter.condoCorporation) {
            matter.removeMatterParticipant(matter.condoCorporation);
        }
        if (matter.managementCompany) {
            matter.removeMatterParticipant(matter.condoCorporation);
        }

        if (condoCorporationSourceContactId) {
            let condoCorporationContact: Contact = await this.contactQueryService.getContactForMatter(condoCorporationSourceContactId).toPromise();
            if (condoCorporationContact) {
                let condoCorporationParticipant: MatterParticipant = matter.addMatterParticipant(condoCorporationContact, true, 'CONDO_CORPORATION');
                if (condoCorporationParticipant && condoCorporationParticipant.contact) {
                    //Setting management contact id into snapshot's sourceParentOrganizationId
                    condoCorporationParticipant.contact.sourceParentOrganizationId = condoCorporationContact.organizationId;
                    if (condoCorporationParticipant.contact.selfManagedManagementCompanyType == CondoManagedTypeConstValue.MANAGEMENT_COMPANY) {
                        let condoManagementCorporationContact: Contact = await this.contactQueryService.getContactForMatter(condoCorporationSourceContactId).toPromise();
                        if (condoManagementCorporationContact) {
                            matter.addMatterParticipant(condoManagementCorporationContact, true, 'MANAGEMENT_COMPANY');
                        }
                    }
                }
                matter.updateMatterDataOnCondoCorpChange();
            }
        }
    }

    updateTemplateParticipantWithTargetParticipant(targetParticipant: MatterParticipant, templateParticipant: MatterParticipant) {

        if (templateParticipant && targetParticipant) {
            targetParticipant.sourceContactLockAcquired = templateParticipant.sourceContactLockAcquired;
        }
        this.updateTemplateParticipantWithTargetContactInfo(targetParticipant, templateParticipant);
    }

    /**
     * As target participant is created with template snapshot contact (to keep data in sync) so this method is copyin source original contact info
     * @param {MatterParticipant} templateParticipant
     * @param {MatterParticipant} targetParticipant
     */
    updateTemplateParticipantWithTargetContactInfo(targetParticipant: MatterParticipant, templateParticipant: MatterParticipant): void {
        if (targetParticipant && targetParticipant.contact && templateParticipant && templateParticipant.contact) {
            targetParticipant.contact.sourceContactId = templateParticipant.contact.sourceContactId;
            targetParticipant.contact.sourceParentOrganizationId = templateParticipant.contact.sourceParentOrganizationId;
            targetParticipant.sourceContact = templateParticipant.sourceContact;
            targetParticipant.contact.sourceParentOrganizationId = templateParticipant.contact.sourceParentOrganizationId;
            targetParticipant.contact.lastSyncedFromSource = templateParticipant.contact.lastSyncedFromSource;
            targetParticipant.attentionName = templateParticipant.attentionName;
        }
    }

    // Move it from mass-update.service.ts
    updateParticipantFla(templateParticipant: MatterParticipant, targetParticipant: MatterParticipant, familyLawActComponent : FamilyLawActComponent, syncIdInfoEnteredByAndEnteredOn: boolean, templateMatter: Matter, targetMatter: Matter) {
        if (templateParticipant && templateParticipant.familyLawActs) {
            let updatedFamilyLawActs = [];
            templateParticipant.familyLawActs.forEach(familyLawActObj => {
                let familyLawAct = new FamilyLawAct(familyLawActObj);
                familyLawAct.id = undefined;
                if(templateParticipant.isConsentedSpouse(familyLawAct)){
                    familyLawAct.consentedSpouseParticipant = null;
                }
                updatedFamilyLawActs.push(familyLawAct);
            });
            targetParticipant.familyLawActs = updatedFamilyLawActs;
            let targetConsentedSpouseParticipant: MatterParticipant = targetMatter.getConsentSpouseParticipantByParentParticipantId(targetParticipant.matterParticipantId);
            if(targetConsentedSpouseParticipant) {
                let selectedConsentingSpouse: MatterParticipantWrapper = new MatterParticipantWrapper();
                selectedConsentingSpouse.matterParticipant = targetConsentedSpouseParticipant;
                this.removeParticipant(selectedConsentingSpouse, targetMatter);
                // targetParticipant.getConsentedSpouseFamilyLawAct().consentedSpouse = undefined;
            }
            let templateConsentedSpouseParticipant: MatterParticipant = templateMatter.getConsentSpouseParticipantByParentParticipantId(templateParticipant.matterParticipantId);
            if (templateParticipant.getConsentedSpouseFamilyLawAct() && templateConsentedSpouseParticipant) {
                this.copyConsentingSpouseParticipant(templateMatter, targetMatter, templateParticipant, targetParticipant, syncIdInfoEnteredByAndEnteredOn );
            }
        }
    }

    copyConsentingSpouseParticipant(templateMatter: Matter, targetMatter: Matter, templateParticipant: MatterParticipant, targetParticipant: MatterParticipant, syncIdInfoEnteredByAndEnteredOn: boolean) {
        let templateConsentedSpouseParticipant: MatterParticipant = templateMatter.getConsentSpouseParticipantByParentParticipantId(templateParticipant.matterParticipantId);
        // we can't call matter.addMatterParticipant because we don't have source contact
        let fla: FamilyLawAct = targetParticipant.getConsentedSpouseFamilyLawAct();
        if(fla && templateConsentedSpouseParticipant){
            let newTargetConsentedSpouseParticipant: MatterParticipant = new MatterParticipant(templateConsentedSpouseParticipant);
            newTargetConsentedSpouseParticipant.matterParticipantId = UUIDUtil.getUUID();
            newTargetConsentedSpouseParticipant.parentParticipantId = targetParticipant.matterParticipantId;
            newTargetConsentedSpouseParticipant.contact = new Contact();
            newTargetConsentedSpouseParticipant.contact.createNewContactClone(templateConsentedSpouseParticipant.contact);
            if(!syncIdInfoEnteredByAndEnteredOn){
                newTargetConsentedSpouseParticipant.contact.enteredOn = null;
                newTargetConsentedSpouseParticipant.contact.idInfoEnteredBy = null;
                newTargetConsentedSpouseParticipant.contact.contactIdInfoEnteredBy = null;
            }
            fla.consentedSpouseParticipant = newTargetConsentedSpouseParticipant;
            fla.consentSpouseMatterParticipantId = newTargetConsentedSpouseParticipant.matterParticipantId;
            targetMatter.matterParticipants.push(newTargetConsentedSpouseParticipant);
        }
    }

    // Move it from mass-update.service.ts
    updateMainClientParticipant(templateParticipant: MatterParticipant, targetParticipant: MatterParticipant, familyLawActComponent : FamilyLawActComponent, syncIdInfoEnteredByAndEnteredOn: boolean, templateMatter: Matter, targetMatter: Matter) {
        if (targetParticipant && targetParticipant.contact && templateParticipant && templateParticipant.contact) {
            this.updateParticipantFla(templateParticipant, targetParticipant, familyLawActComponent, syncIdInfoEnteredByAndEnteredOn, templateMatter, targetMatter);
            targetParticipant.purchaserCapacity = templateParticipant.purchaserCapacity;
            targetParticipant.purchaserShare = templateParticipant.purchaserShare;
            if (syncIdInfoEnteredByAndEnteredOn) {
                targetParticipant.contact.idInfoEnteredBy = templateParticipant.contact.idInfoEnteredBy;
                targetParticipant.contact.contactIdInfoEnteredBy = templateParticipant.contact.contactIdInfoEnteredBy;
                targetParticipant.contact.enteredOn = templateParticipant.contact.enteredOn;
            }
            targetParticipant.primary = templateParticipant.primary;
            targetParticipant.contact.sourceContactId = templateParticipant.contact.sourceContactId;
            targetParticipant.sourceContact = templateParticipant.sourceContact;
            targetParticipant.contact.lastSyncedFromSource = templateParticipant.contact.lastSyncedFromSource;
            targetParticipant.sourceContactLockAcquired = templateParticipant.sourceContactLockAcquired;

        }
    }

    // Move it from mass-update.service.ts
    updateFlaSpouseId(templateClients: MatterParticipant[], targetClients: MatterParticipant[]) {
        if (Array.isArray(templateClients) && Array.isArray(targetClients)) {
            templateClients.forEach(templateClient => {
                let matterParticipantSpouseFamilyLawAct: FamilyLawAct = templateClient.getMatterParticipantSpouseFamilyLawAct();
                if (matterParticipantSpouseFamilyLawAct && matterParticipantSpouseFamilyLawAct.spouseMatterParticipantId) {
                    let templateClientSpouse: MatterParticipant = templateClients.find(item => item.matterParticipantId == matterParticipantSpouseFamilyLawAct.spouseMatterParticipantId);

                    let targetClient: MatterParticipant;
                    let targetClientSpouse: MatterParticipant;
                    if (templateClient.contact && templateClient.contact.sourceContactId) {
                        targetClient = targetClients
                            .find((participant: MatterParticipant) => participant.contact && participant.contact.sourceContactId == templateClient.contact.sourceContactId);
                    }
                    if (templateClientSpouse && templateClientSpouse.contact && templateClientSpouse.contact.sourceContactId) {
                        targetClientSpouse = targetClients
                            .find((participant: MatterParticipant) => participant.contact && participant.contact.sourceContactId == templateClientSpouse.contact.sourceContactId);
                    }
                    if (targetClient && targetClientSpouse) {
                        let targetParticipantSpouseFamilyLawAct: FamilyLawAct = targetClient.getMatterParticipantSpouseFamilyLawAct();
                        let targetSpouseParticipantSpouseFamilyLawAct: FamilyLawAct = targetClientSpouse.getMatterParticipantSpouseFamilyLawAct();
                        if (targetParticipantSpouseFamilyLawAct && targetSpouseParticipantSpouseFamilyLawAct) {
                            targetParticipantSpouseFamilyLawAct.spouseMatterParticipantId = targetClientSpouse.matterParticipantId;
                            targetSpouseParticipantSpouseFamilyLawAct.spouseMatterParticipantId = targetClient.matterParticipantId;

                        } else {
                            console.log('targetParticipantSpouseFamilyLawAct && targetSpouseParticipantSpouseFamilyLawAct failed : is werid');
                        }
                    } else {
                        console.log('targetMainClient && targetMainClientSpouse failed : is werid');
                    }
                }

            });
        }

    }

    isFirstLoadOfMigrationContact(contact: Contact): boolean {
        let result = false;
        if (contact && contact.partyRole === 'CLIENT') {
            //migrated clients only have the mailing address so we can detect based on the number of addresses if this contact is one
            //JR41827, for Mortgagee's Attention, there is no address, so we need to exlucde the no address case, have confirmed with Danny
            if (Array.isArray(contact.address) && contact.address.length > 0 && contact.address.length < 3) {
                result = true;
            }
        }
        return result;
    }

    updateOtherMatterParticipantsPriorities(matter: Matter, deletedPriority: number): void {
        let lowestPriorityMatterParticipants = matter.mainClients.filter(mp => mp.matterParticipantPriority > deletedPriority);
        if (lowestPriorityMatterParticipants && lowestPriorityMatterParticipants.length) {
            lowestPriorityMatterParticipants.forEach((mp) => {
                mp.matterParticipantPriority--;
            });
        }
    }

    cleanFla(matterParticipant, matter: Matter) {
        if(matterParticipant && matterParticipant.contact && Array.isArray(matterParticipant.familyLawActs) && matterParticipant.familyLawActs.length > 0) {
            let consentingSpouseFla: FamilyLawAct = matterParticipant.getConsentedSpouseFamilyLawAct();
            switch (matterParticipant.contact.gender) {

                case GenderTypes.ESTATE:
                    if(consentingSpouseFla && consentingSpouseFla.consentedSpouseParticipant){
                        this.removeConsentingSpouse(consentingSpouseFla.consentedSpouseParticipant, matter);
                    }
                    if(matter.isMatterProvinceON) {
                        matterParticipant.familyLawActs = matterParticipant.familyLawActs.filter(fla=>fla.familyLawActStatementType == 'OTHER')
                    } else {
                        matterParticipant.familyLawActs = [];
                    }

                    break;

                case GenderTypes.OTHERENTITY:
                case GenderTypes.CORPORATION:
                    if(consentingSpouseFla && consentingSpouseFla.consentedSpouseParticipant){
                        this.removeConsentingSpouse(consentingSpouseFla.consentedSpouseParticipant, matter);
                    }
                    matterParticipant.familyLawActs = [];
                    break;
                default:
                    break;

            }
        }
    }

    removeConsentingSpouseSigningOfficerCleanup(matter: Matter, consentingSpouseParticipant: MatterParticipant, keepContactLocked?: boolean){
        if(matter && consentingSpouseParticipant ) {
            if(!keepContactLocked){
                matter.matterParticipants.filter(selectedSigningOfficer => selectedSigningOfficer.parentParticipantId === consentingSpouseParticipant.matterParticipantId).forEach(matterParticipant => {
                    this.tabsService.unLockSourceContactForParticipant(matterParticipant);
                })

            }
            matter.matterParticipants.filter(selectedSigningOfficer => selectedSigningOfficer.parentParticipantId === consentingSpouseParticipant.matterParticipantId).forEach(matterParticipant => {
                (<any>matter.matterParticipants).remove(matterParticipant);
            })

        }
    }
}
