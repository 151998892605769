import {Injectable} from '@angular/core';
import {HttpClient} from '../../core';
import {Observable} from 'rxjs/Rx';
import {CirfConfig, CirfFieldStatusType, CirfFieldStatusTypeValues} from './cirf-config';
import {cirfConfigApi} from './cirf-config-api';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {CIRF_CONFIG_KEYS} from './cirf-config-keys';

@Injectable()
export class CirfConfigService {

    private fieldKeyStatusMap: Map<string, CirfFieldStatusType>; // FieldKey - FieldStatus Map

    constructor(private httpClient: HttpClient) {
    }

    getCirfConfig(matterType: string): Observable<CirfConfig> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = cirfConfigApi.getCirfConfig(accountId, matterType);
        return this.httpClient.get(url)
            .map((res) => {
                const newCirfConfig = new CirfConfig(res['CirfConfig']);
                this.updateFieldKeyStatusMap(newCirfConfig);
                return newCirfConfig;
            });
    }

    updateCirfConfig(cirfConfig: CirfConfig): Observable<CirfConfig> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = cirfConfigApi.updateCirfConfig(accountId);
        return this.httpClient.put(url, cirfConfig)
            .map((res) => {
                return new CirfConfig(res['CirfConfig']);
            });
    }

    updateFieldKeyStatusMap(cirfConfig: CirfConfig) : void {
        this.fieldKeyStatusMap = new Map();
        cirfConfig.cirfTopics.forEach(topic =>
            topic.topicSections.forEach(section =>
                section.cirfFields.forEach((field) => {
                    this.fieldKeyStatusMap.set(field.fieldKey, field.fieldStatus);
                })));

    }

    /**
     * returns the status of the field
     * @param fieldKey - For example "PERSONAL_DETAILS.CLIENT_PROFILE.FIRST_NAME"
     * @return CirfFieldStatusType
     *
     */
    getFieldStatus(fieldKey: string): CirfFieldStatusType {
        if (this.fieldKeyStatusMap && this.fieldKeyStatusMap.has(fieldKey)) {
            return this.fieldKeyStatusMap.get(fieldKey);
        }
        // the reason the default value for a given key is HIDDEN is because some fields (like matterInfo.transactionDetails.Capacity) might show on a Purchase but not on other matters
        // while building a list of available fields based on CIRF_CONFIG_KEYS the key "Capacity" would be available as a key on list of items for the section but there would be no value from CirfConfig for it
        return (fieldKey && fieldKey.length) ? CirfFieldStatusTypeValues.hidden : CirfFieldStatusTypeValues.optional;
    }

    isFieldHidden(fieldName: string): boolean {
        const fieldKey: string = this.getFieldKeyFromConfigKey(CIRF_CONFIG_KEYS, fieldName);
        //console.log(`testing isFieldHidden ${fieldName} ${this.getFieldStatus(fieldKey)}`);
        return this.getFieldStatus(fieldKey) === CirfFieldStatusTypeValues.hidden;
    }

    isUDFHidden(fieldKey: string) : boolean {
        return this.isKeyHidden(fieldKey);
    }

    private isKeyHidden(fieldKey: string): boolean {
        return this.getFieldStatus(fieldKey) === CirfFieldStatusTypeValues.hidden;
    }

    private getFieldKeyFromConfigKey(configKey: any , key: string): any {
        if (key.indexOf('.') > -1) {
            const firstConfigKey = key.split('.')[0];
            if (configKey[firstConfigKey]) {
                return this.getFieldKeyFromConfigKey(configKey[firstConfigKey], key.substring(firstConfigKey.length + 1));
            }
            console.log(`${configKey} not found`);
            return ''; // not found
        }
        if (configKey[key]) {
            return configKey[key];
        }
        return configKey;
    }

}
