// export class DpBooleanTypes {
//     public static readonly Y_n : DpBoolean = "Y/n";
//     public static readonly N_y : DpBoolean = "N/y";
//     public static readonly YES : DpBoolean = "YES";
//     public static readonly NO : DpBoolean = "NO";
//     public static readonly BLANK : DpBoolean = "";
// }

//Transition version, that we'll migrate to so as to standardize on the approach. Keeping the old one in place until we migrate everything
export class DpBooleanValueTypes {
    public static readonly Y_n : DpBooleanValue = "Y_n";
    public static readonly N_y : DpBooleanValue = "N_y";
    public static readonly YES : DpBooleanValue = "YES";
    public static readonly NO : DpBooleanValue = "NO";
    public static readonly BLANK : DpBooleanValue = "";

    public static isTrue(dpBooleanValue: DpBooleanValueTypes): boolean {
        return (dpBooleanValue && (dpBooleanValue === DpBooleanValueTypes.YES || dpBooleanValue === DpBooleanValueTypes.Y_n));
    }
}

/**
 * Enhanced DoProcess style boolean, where default boolean values are distinguished from explicitly chosen values
 */
// export type DpBoolean = "YES" | "NO" | "Y/n" | "N/y" | "";

//Transition version, that we'll migrate to so as to standardize on the approach. Keeping the old one in place until we migrate everything
export type DpBooleanValue = "YES" | "NO" | "Y_n" | "N_y" | "";
