import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';

export type InterimClosingAdjustmentFormat = 'ITEMIZED_ADJUSTMENT' | 'ITEMIZED_ADJUSTMENT_HEADINGS_UPPER_CASE' | 'CREDIT_PURCHASER_ONLY';

export class InterimClosingAdjustmentFormatTypes {
    public static readonly ITEMIZED_ADJUSTMENT : InterimClosingAdjustmentFormat = "ITEMIZED_ADJUSTMENT";
    public static readonly ITEMIZED_ADJUSTMENT_HEADINGS_UPPER_CASE : InterimClosingAdjustmentFormat = "ITEMIZED_ADJUSTMENT_HEADINGS_UPPER_CASE";
    public static readonly CREDIT_PURCHASER_ONLY : InterimClosingAdjustmentFormat = "CREDIT_PURCHASER_ONLY";
}

export type AdditionalConsiderationAmountType = 'ELIGIBLE_FOR_HST_REBATE'|'NOT_ELIGIBLE_FOR_HST_REBATE'|'NOT_ELIGIBLE_FOR_HST_REBATE_INCL_HST';

export class AdditionalConsiderationAmountTypes {
    public static readonly ELIGIBLE_FOR_HST_REBATE : AdditionalConsiderationAmountType = "ELIGIBLE_FOR_HST_REBATE";
    public static readonly NOT_ELIGIBLE_FOR_HST_REBATE : AdditionalConsiderationAmountType = "NOT_ELIGIBLE_FOR_HST_REBATE";
    public static readonly NOT_ELIGIBLE_FOR_HST_REBATE_INCL_HST : AdditionalConsiderationAmountType = "NOT_ELIGIBLE_FOR_HST_REBATE_INCL_HST";
}

export class SoAdjConsiderationPaidOnInterimClosing extends BaseEntity {
    id : number;
    adjustmentFormat : InterimClosingAdjustmentFormat;
    adjustmentHeading : string;

    // infoOnly only for UI, after close modal, it will add it to SOA
    infoOnly: boolean;

    isItemizedAdjustment() : boolean {
        return this.adjustmentFormat === InterimClosingAdjustmentFormatTypes.ITEMIZED_ADJUSTMENT;
    }

    isItemizedAdjustmentHeadingsUpperCase() : boolean {
        return this.adjustmentFormat === InterimClosingAdjustmentFormatTypes.ITEMIZED_ADJUSTMENT_HEADINGS_UPPER_CASE;
    }

    isCreditPurchaserOnly() : boolean {
        return this.adjustmentFormat === InterimClosingAdjustmentFormatTypes.CREDIT_PURCHASER_ONLY;
    }

}
