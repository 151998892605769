export class MatterParticipantRoleTypes {
    public static readonly INSURER : MatterParticipantRole = "INSURER";
    public static readonly BROKER : MatterParticipantRole = "BROKER";
    public static readonly OTHERPARTY_SOLICITOR : MatterParticipantRole = "OTHERPARTY_SOLICITOR";
    public static readonly OTHERPARTY_LAWCLERK : MatterParticipantRole ='OTHERPARTY_LAWCLERK'
    public static readonly OTHERPARTY_LAW_FIRM : MatterParticipantRole = "OTHERPARTY_LAW_FIRM";
    public static readonly MORTGAGE_SOLICITOR : MatterParticipantRole = "MORTGAGE_SOLICITOR";
    public static readonly MORTGAGE_LEGAL_FIRM : MatterParticipantRole = "MORTGAGE_LEGAL_FIRM";
    public static readonly MORTGAGEE_ATTENTION : MatterParticipantRole = "MORTGAGEE_ATTENTION";
    public static readonly CONDO_CORPORATION_ATTENTION : MatterParticipantRole = "CONDO_CORPORATION_ATTENTION";
    public static readonly MANAGEMENT_COMPANY_ATTENTION : MatterParticipantRole = "MANAGEMENT_COMPANY_ATTENTION";
    public static readonly MORTGAGEE : MatterParticipantRole = "MORTGAGEE";
    public static readonly CONDO_CORPORATION : MatterParticipantRole = "CONDO_CORPORATION";
    public static readonly MANAGEMENT_COMPANY : MatterParticipantRole = "MANAGEMENT_COMPANY";
    public static readonly OTHERPARTY_REALESTATEBROKER : MatterParticipantRole = "OTHERPARTY_REALESTATEBROKER";
    public static readonly OTHERPARTY_REALESTATEAGENT : MatterParticipantRole = "OTHERPARTY_REALESTATEAGENT";
    public static readonly REALESTATEBROKER : MatterParticipantRole = "REALESTATEBROKER";
    public static readonly REALESTATEAGENT : MatterParticipantRole = "REALESTATEAGENT";
    public static readonly LAWCLERK : MatterParticipantRole = "LAWCLERK";
    public static readonly SOLICITOR : MatterParticipantRole = "SOLICITOR";
    public static readonly COMMISSIONER : MatterParticipantRole = "COMMISSIONER";
    public static readonly WITNESS : MatterParticipantRole = "WITNESS";
    public static readonly WILL_WITNESS_1 : MatterParticipantRole = "WILL_WITNESS_1";
    public static readonly WILL_WITNESS_2 : MatterParticipantRole = "WILL_WITNESS_2";
    public static readonly DEVELOPER : MatterParticipantRole = "DEVELOPER";
    public static readonly DEVELOPER_LAW_FIRM : MatterParticipantRole = "DEVELOPER_LAW_FIRM";
    public static readonly DEVELOPER_SOLICITOR : MatterParticipantRole = "DEVELOPER_SOLICITOR";
    public static readonly FORM4_SIGNING_SOLICITOR : MatterParticipantRole = "FORM4_SIGNING_SOLICITOR";
    public static readonly MORTGAGE_BROKER : MatterParticipantRole = "MORTGAGE_BROKER";
    public static readonly OPPORTUNITY_ASSIGNEE : MatterParticipantRole = "OPPORTUNITY_ASSIGNEE";
    public static readonly CONSENTING_SPOUSE : MatterParticipantRole = "CONSENTING_SPOUSE";
    public static readonly OFFEROR : MatterParticipantRole = "OFFEROR";
    public static readonly GUARANTOR : MatterParticipantRole = "GUARANTOR";
    public static readonly SURVEYOR : MatterParticipantRole = "SURVEYOR";
    public static readonly PRIVATE_LENDER : MatterParticipantRole ='PRIVATE_LENDER';
    public static readonly TESTATOR : MatterParticipantRole ='TESTATOR';
    public static readonly TESTATOR_SPOUSE : MatterParticipantRole ='TESTATOR_SPOUSE';
    public static readonly WILL_BENEFICIARY : MatterParticipantRole ='WILL_BENEFICIARY';

}

export type MatterParticipantRole = 'PURCHASER' | 'OFFEROR' | 'SOLICITOR' | 'VENDOR' | 'MORTGAGOR' | 'REALESTATEBROKER' | 'REALESTATEAGENT' | 'SURVEYOR' |
    'LAWCLERK' | 'MORTGAGEE' | 'PRIVATE_LENDER' | 'GUARANTOR' | 'MORTGAGE_BROKER' | 'MORTGAGE_SOLICITOR' | 'MORTGAGE_LEGAL_FIRM' | 'INSURER' | 'BROKER' |
    'UNSPECIFIED_INSURER' | 'CONDO_CORPORATION' | 'MANAGEMENT_COMPANY' |'OTHERPARTY_LAW_FIRM' | 'OTHERPARTY_SOLICITOR' | 'OTHERPARTY_LAWCLERK' | 'MORTGAGEE_ATTENTION'
    | 'CONDO_CORPORATION_ATTENTION' | 'MANAGEMENT_COMPANY_ATTENTION' | 'OTHERPARTY_REALESTATEBROKER' | 'OTHERPARTY_REALESTATEAGENT' | 'COMMISSIONER' | 'WITNESS' | 'RESIDENCE_ASSOCIATION'
    |'DEVELOPER' | 'DEVELOPER_LAW_FIRM' | 'DEVELOPER_SOLICITOR' | 'TRANSFEROR' | 'FORM4_SIGNING_SOLICITOR' | 'OPPORTUNITY_ASSIGNEE' | 'PROSPECT'
    |'SIGNING_OFFICER' | 'ESTATE_TRUSTEE' | 'POWER_OF_ATTORNEY' | 'CONSENTING_SPOUSE' | 'TESTATOR' | 'TESTATOR_SPOUSE' | 'WILL_BENEFICIARY' | 'WILL_WITNESS_1' | 'WILL_WITNESS_2';


export const matterParticipantRoleLabels = {
    'PURCHASER'             : 'Purchaser',
    'OFFEROR'               : 'Offeror',
    'SOLICITOR'             : 'Solicitor',
    'VENDOR'                : 'Vendor',
    'MORTGAGOR'             : 'Mortgagor',
    'REALESTATEBROKER'      : 'Real Estate Broker',
    'REALESTATEAGENT'       : 'Real Estate Agent',
    'SURVEYOR'              : 'Surveyor',
    'LAWCLERK'              : 'Law Clerk',
    'MORTGAGEE'             : 'Mortgagee',
    'PRIVATE_LENDER'        : 'Other Lender',
    'GUARANTOR'             : 'Guarantor',
    'MORTGAGE_BROKER'       : 'Mortgage Broker',
    'MORTGAGE_SOLICITOR'    : 'Mortgage Solicitor',
    'MORTGAGE_LEGAL_FIRM'   : 'Mortgage Legal Firm',
    'OTHERPARTY_SOLICITOR'  : 'Vendor Solicitor',
    'OTHERPARTY_LAW_FIRM'   : 'Vendor Law Firm',
    'INSURER'               : 'Insurer',
    'BROKER'                : 'Broker',
    'UNSPECIFIED_INSURER'   : 'Unspecified Insurer',
    'CONDO_CORPORATION'     : 'Condo Corporation',
    'MANAGEMENT_COMPANY'    : 'Management Company',
    'TRANSFEROR'            : 'Transferor',
    'OPPORTUNITY_ASSIGNEE'  : 'Assigned To',
    'PROSPECT'              : 'Prospect',
    'SIGNING_OFFICER'       : 'Signing Officer',
    'ESTATE_TRUSTEE'        : 'Trustee',
    'POWER_OF_ATTORNEY'     : 'Attorney',
    'TESTATOR'              : 'Testator'
};
