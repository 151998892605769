import {ProvinceCode} from '../accounts/shared/base-province';

export class UserTrustAccount {
    constructor(userTrustAccount? : UserTrustAccount) {
        if(userTrustAccount) {
            for(let prop in userTrustAccount) {
                if(userTrustAccount.hasOwnProperty(prop)) {
                    this[prop] = userTrustAccount[prop];
                }
            }
        }
    }

    id : number;
    contactId : number;
    firmTrustAccountId : number;
    provinceCode : ProvinceCode;
    default : boolean;
}
