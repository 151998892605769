import {DepositOnOccupancyCalculatedBasedOn} from '../statement-adjustment/statement-adjustment-constants';
import {SalePriceOptionValue} from '../property-teranet/deposit/deposit-modal-constants';
import * as _ from 'lodash';
import {TrustAccount} from '../../admin/trust-account/trust-account';

export class MatterExtraDepositConfig {

    depositOnOccupancyFlag? : boolean;
    depositOnOccupancyAmount? : number;
    depositOnOccupancyCalBasedOn? : DepositOnOccupancyCalculatedBasedOn;
    deferredPurchaseMoniesFlag? : boolean;
    deferredPurchaseMoniesAmount? : number;
    vtbMortgageMonies? : number;
    calculatedSalePriceTotal? : number;
    // either sale price per agreement; or Sale price including credits to P & V
    salePriceTotalOption?: SalePriceOptionValue;
    salePriceTotal? : number;
    bankAccountNumber? : string;
    matterFirmTrustAccount : TrustAccount;

    // all currency amount values are modified by the dp-currency directive, which changes the value
    // type to string, so we have to ensure numeric values are written to the properties
    numericValue(val: string | number) : number {
        let v = Number(val);
        return !_.isFinite(v) ? 0.0 : v;
    }

    constructor(values? : MatterExtraDepositConfig) {

        if (values) {
            for (let prop in values) {
                if (values.hasOwnProperty(prop)) {
                    this[prop] = values[prop];
                }
            }
            if(values.matterFirmTrustAccount) {
                this.matterFirmTrustAccount = new TrustAccount(values.matterFirmTrustAccount);
            }
        }


        if (!this.salePriceTotalOption) {
            // story requirement: default value
            this.salePriceTotalOption = SalePriceOptionValue.AS_PER_AGREEMENT;
        }

        this.depositOnOccupancyAmount = this.numericValue(this.depositOnOccupancyAmount);
        this.deferredPurchaseMoniesAmount = this.numericValue(this.deferredPurchaseMoniesAmount);
        this.vtbMortgageMonies = this.numericValue(this.vtbMortgageMonies);
        this.calculatedSalePriceTotal = this.numericValue(this.calculatedSalePriceTotal);
        this.salePriceTotal = this.numericValue(this.salePriceTotal);
    }

    setTrustAccountData(trustAccount: TrustAccount){
        if(trustAccount){
            this.matterFirmTrustAccount = new TrustAccount(trustAccount);
            this.bankAccountNumber = trustAccount.trustAccountNumber;
        }else{
            this.matterFirmTrustAccount = undefined;
            this.bankAccountNumber = undefined;
        }
    }
}
