import {Component, ViewChild} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import {JournalNote} from './account-notes';

class AddNoteDialogContext extends BSModalContext {
    notes: string; //existing notes, if any
    showNotesOnOpen: boolean;
    isShowNotesOnOpenVisible: boolean;
}

@Component({
    selector: 'dp-add-modal-note',
    templateUrl: 'add-note-modal.component.html',
    styleUrls: ['./add-note-modal.styles.scss'],
    providers: [ErrorService]
})

export class AddNoteDialogComponent implements   ModalComponent<AddNoteDialogContext> {
    context: AddNoteDialogContext;
    userCreationTimestamp: number;
    textAreaNote: string;
    showNotesOnOpen: boolean;
    @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

    constructor(public dialog: DialogRef<AddNoteDialogContext>) {
        this.context = dialog.context;
    }

    ngOnInit() {
        this.showNotesOnOpen = this.context.showNotesOnOpen;
        if (this.context.notes) {
            this.textAreaNote = this.context.notes;
        }
        this.userCreationTimestamp = Date.now();
    }

    checkForErrors() {
        this.modalErrorComponent.removeDpFieldError('admin.notes.addJournalNote');
        if (this.textAreaNote) {
            this.textAreaNote = this.textAreaNote.trim();
        }
        if (this.textAreaNote == "" || this.textAreaNote == null || this.textAreaNote == undefined) {
            this.modalErrorComponent.createDPFieldError('admin.notes.addJournalNote');
        }
    }

    cancelDialog() {
        this.dialog.close();
    }

    closeDialog() {
        this.checkForErrors();
        if (!this.modalErrorComponent.anyErrorExist()) {
            let noteData = new JournalNote();
            noteData.userCreationTimestamp = this.userCreationTimestamp;
            noteData.noteBody = this.textAreaNote;
            this.dialog.close({noteData: noteData, showNotesOnOpen: this.showNotesOnOpen});

        }
    }
}
