import {AUTH_OPERATION} from '../shared-main/authorization/authorization-keys';

export const AccessGuardKey = [
    {
        path    : '/main/tabs/matters',
        authKey : [AUTH_OPERATION.MATTERS]
    },
    {
        path    : '/main/projects/',
        authKey : [AUTH_OPERATION.PROJECTS]
    },
    {
        path    : '/main/contacts/',
        authKey : [AUTH_OPERATION.CONTACT_MANAGEMENT, AUTH_OPERATION.MANAGE_GLOBAL_CONTACTS]
    },
    {
        path    : '/main/admin/',
        authKey : [AUTH_OPERATION.ADMINISTRATION, AUTH_OPERATION.CUSTOMER_ACCOUNT_LIST]
    },
    {
        path    : '/main/accountadmin/',
        authKey : [AUTH_OPERATION.ADMINISTRATION]
    },
    {
        path    : '/main/billing/',
        authKey : [AUTH_OPERATION.CUSTOMER_BILLING_INFORMATION]
    },
    {
        path    : '/main/events/',
        authKey : [AUTH_OPERATION.EVENTS]
    },
    {
        path    : '/main/events/',
        authKey : [AUTH_OPERATION.MANAGE_ADJUDICATION]
    }
];

export class AccessGuardHelper {

    static getAccessGuardKey(path : string) : any[] {
        let authKey : any[] = [];
        let accessGuards : any = AccessGuardKey.filter( accessGuardKeys => path.indexOf(String(accessGuardKeys.path)) > -1);
        if(accessGuards && accessGuards.length) {
            accessGuards.forEach((accessGuard)=>{
                authKey = accessGuard.authKey;
            });
        }
        return authKey;

    }

}
