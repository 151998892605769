import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class Form4Deposit extends BaseEntity {

    id : number;
    signingSolicitorName : string;
    signingSolicitorCapacity : string;
    trusteeClass : string;
    phoneOption : string;
    phone : string;
    paragraph2PreferredWording : string;
    optionalFooters : string[]; // upto 6 lines with 160 chars each
    eSignatureImageId : number;
    eSignatureImageName : string;
    paperSize : string;

    constructor(form4Deposit? : Form4Deposit) {
        super(form4Deposit);
        if(form4Deposit && form4Deposit.optionalFooters) {
            this.optionalFooters = [];
            form4Deposit.optionalFooters.forEach(s => {this.optionalFooters.push(s)});
        }
    }
}
