import {DocumentProfile} from './../../document-profile';
import {soaDropDowns} from './../soa/soa-drop-downs';
import {Component, DoCheck, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ContactDefaultEnvelopeData, DocumentProfileMisc} from './document-profile-misc';
import {SelectItem} from 'primeng/api';
import {DocumentProfileService} from '../document-profile.service';
import {CustomKeyCodesEnum} from '../../../../common';
import {SESSION_STORAGE_KEYS} from '../../../../shared/session-storage-keys';
import {TabsService} from '../../../../core/tabs.service';
import {ActivatedRoute} from '@angular/router';
import {AdminTab} from '../../../admin-tab';
import {Account} from './../../../accounts/shared/account';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {Subscription} from 'rxjs/Subscription';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {AccountService} from '../../../accounts/account.service';
import {PROVINCE_CODES} from '../../../../matters/shared/user-province';
import {dropDowns} from '../../../../matters/shared/matter-drop-downs';

@Component({
    selector: 'dp-document-profile-misc',
    templateUrl: 'document-profile-misc.component.html'
})

@FocusFirstElementDecorator()
@AutoUnsubscribe()
export class DocumentProfileMiscComponent implements OnInit, DoCheck {

    documentProfile : DocumentProfile;
    misc: DocumentProfileMisc;

    miscDefault: DocumentProfileMisc;
    addressFormat : SelectItem[];
    yesNoOptions : SelectItem[];
    defaultTextPolarisMortgageOptions : SelectItem[];
    declaredOrSwornOptions: SelectItem[];
    titleNoInPurchaserOption: SelectItem[];
    defaultAddressOfService: SelectItem[];
    account: Account;
    copyDefaultProfileEnabled : boolean = false;

    saveSubscription : Subscription;
    routeSubscription : Subscription;
    routeParentSubscription : Subscription;

    contactDefaultMaleEnvelopeSalutations : SelectItem[] = soaDropDowns.contactDefaultMaleEnvelopeSalutations;
    contactDefaultFemaleEnvelopeSalutations : SelectItem[] = soaDropDowns.contactDefaultFemaleEnvelopeSalutations;
    contactDefaultCoupleEnvelopeSalutations : SelectItem[] = soaDropDowns.contactDefaultCoupleEnvelopeSalutations;
    contactDefaultMaleDears : SelectItem[] = soaDropDowns.contactDefaultMaleDears;
    contactDefaultFemaleDears : SelectItem[] = soaDropDowns.contactDefaultFemaleDears;
    contactDefaultCoupleDears : SelectItem[] = soaDropDowns.contactDefaultCoupleDears;

    constructor(public formBuilder: FormBuilder , public route : ActivatedRoute,
                public tabsStateService: TabsService, public documentProfileService: DocumentProfileService, public accountService : AccountService) {
    }

    get activeAdminTab() : AdminTab {
        return this.tabsStateService.activeTab as AdminTab;
    }

    initializeAccount() : void {
        if(this.activeAdminTab.account) {
            this.account = this.activeAdminTab.account;
        } else {
                let loggedUserId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
                this.accountService.getAccount(loggedUserId).subscribe((account : Account) => {
                    this.account = new Account(account);
                });
            }
    }

    public initializeDocumentProfile() {
        if(this.activeAdminTab) {
            this.documentProfile = this.activeAdminTab.documentProfile;
            this.misc = this.activeAdminTab.documentProfile.miscDocumentProfile;
            this.copyDefaultProfileEnabled = this.misc.id == null || this.misc.sameAsDefaultProfileFlag;
            if(this.getMiscDocumentProfile()) {
                if(!this.getMiscDocumentProfile().contactDefaultEnvelopeData){
                    this.getMiscDocumentProfile().contactDefaultEnvelopeData = new ContactDefaultEnvelopeData();
                }
            }

        }
    }

    ngOnInit(): void {

        this.initializeDocumentProfile();


        this.routeParentSubscription = this.route.parent
                                          .params
                                          .subscribe(() => {
                                            this.initializeDocumentProfile();
                                          });

        this.routeSubscription = this.route
                                     .params
                                      .subscribe(() => {
                                        // listens for params changes  when it's not a parent component
                                        this.initializeAccount();
                                      });

        this.addressFormat = soaDropDowns.ADDRESSFORMAT;
        this.yesNoOptions = soaDropDowns.YESNOOPTIONS;
        this.defaultTextPolarisMortgageOptions = soaDropDowns.DEFAULTTEXTPOLARISMORTGAGEOPTIONS;
        this.declaredOrSwornOptions =  soaDropDowns.DECLAREDORSWORNOPTIONS;
        this.defaultAddressOfService = soaDropDowns.DEFAULTADDRESSOFSERVICE;
        this.titleNoInPurchaserOption = soaDropDowns.TITLENOINPURCHASEROPTION;

        if (this.misc.sameAsDefaultProfileFlag) {
            this.fillMiscFromDefaultProfile();
        } else {
            // Set Defaults
            if (this.documentProfile.provinceCode === PROVINCE_CODES.SASKATCHEWAN ||
                this.documentProfile.provinceCode === PROVINCE_CODES.MANITOBA){
                if (this.misc.titleNoInPurchaserOption == null){
                    this.misc.titleNoInPurchaserOption = 'VENDORTITLENO';
                }
                if (this.misc.specifyTitleNoOther == null){
                    this.misc.specifyTitleNoOther = 'New Issuing';
                }
                if (this.misc.defaultAddressOfService == null){
                    this.misc.defaultAddressOfService = 'COLAWFIRM';
                }
            }
        }
    }

    getMiscDocumentProfile() :  DocumentProfileMisc {
        return this.misc && this.misc.sameAsDefaultProfileFlag &&  this.miscDefault ? this.miscDefault : this.misc ;
    }

    preventInput(event) {
        switch(event.keyCode) {
            case 101: //e
            case 46: //.
            event.preventDefault();
            break;
        }
    }

    checkInput(event) {
        console.log(event);
        if(event < 0 || event > 99){
            this.getMiscDocumentProfile().charactePerLineSOA = this.activeAdminTab.documentProfileRef.miscDocumentProfile.charactePerLineSOA;
        }
    }

    sameAsDefaultProfileOnchange(event: any): void {

        // console.log(this.profile.firmDocumentProfile.sameAsDefaultProfileFlag);

        if (event) {
            this.fillMiscFromDefaultProfile();
        }
        else{
            if(this.miscDefault)
            {
                if(this.copyDefaultProfileEnabled ) {
                    let idBeforeCopy = this.misc.id;
                    Object.assign(this.misc, this.miscDefault,{id: idBeforeCopy});
                    this.copyDefaultProfileEnabled = false;
                }
            }
            this.misc.sameAsDefaultProfileFlag = false;
        }
    }

    public fillMiscFromDefaultProfile(): void {
        let id = this.account? this.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        this.documentProfileService.getDefaultProfileForAccount(id, this.documentProfile.provinceCode).subscribe((profile: DocumentProfile) => {
            this.miscDefault = profile.miscDocumentProfile;
            this.misc.sameAsDefaultProfileFlag = true;
            // Set Defaults
            if (this.documentProfile.provinceCode === PROVINCE_CODES.SASKATCHEWAN ||
                this.documentProfile.provinceCode === PROVINCE_CODES.MANITOBA){
                if (this.miscDefault.titleNoInPurchaserOption == null){
                    this.miscDefault.titleNoInPurchaserOption = 'VENDORTITLENO';
                }
                if (this.miscDefault.specifyTitleNoOther == null){
                    this.miscDefault.specifyTitleNoOther = 'New Issuing';
                }
                if (this.miscDefault.defaultAddressOfService == null){
                    this.miscDefault.defaultAddressOfService = 'COLAWFIRM';
                }
            }
        });
    }

    enforceNumericRules($event) : void {
        let value = $event.target.value;
        let inputChar = String.fromCharCode($event.charCode);
        const pattern = /[0-9\ ]/;
        // Mozilla Fix , backspace is invoking keypress event in mozilla.
        if($event.keyCode != CustomKeyCodesEnum.backspace)
        {
            let max = +($event.target.max);
            let inputInt = +(value);

            //check only numeric are allowed
            if (!pattern.test(inputChar)) {
                $event.preventDefault();
            }
            else if(value.length > 2)
            {
                // not more than 2 digits.
                $event.preventDefault();
            }
            else if(!isNaN(max) && !isNaN(inputInt) && inputInt > max && inputInt < 1)
            {
                // should not exceed max value given in target or min value of 1
                $event.preventDefault();
            }
        }
    }

    get isDirty(): Boolean{
        return this.documentProfile.dirty;
    }

    ngDoCheck(): void {
        if(!this.misc.equals(this.activeAdminTab.documentProfileRef.miscDocumentProfile)) {
            this.documentProfile.dirty = true;
        }
    }

    get documentProfileName(): string{
        if (this.documentProfile && this.documentProfile.profileName)
            return this.documentProfile.profileName;
        else
            return '';
    }

    save():void{
        if(this.activeAdminTab && this.activeAdminTab.documentProfileComponent ) {

            this.activeAdminTab.documentProfile.miscDocumentProfile = this.misc;

            this.saveSubscription = this.activeAdminTab.documentProfileComponent.saveDocProfile()
                                        .subscribe((saved) => {
                                            if(saved.valueOf())
                                                this.initializeDocumentProfile();
                                                this.documentProfile.dirty = false;
                                        });
        }
   }

    ngOnDestroy() {}
    ngAfterViewInit() {}

    get vendorWarrantyOptions(): SelectItem[]{
        return dropDowns.vendorWarrantyOptions;
    }
}

