import {Address} from './address';
import {Telephone} from './telephone';
import {JurisdictionDepartment} from '../../admin/jurisdiction-departments/jurisdiction-departments';
import {Department} from './department';
import {
    jurisdictionDepartmentsGeneral,
    jurisdictionDepartmentsLandRegistrationOffice,
    jurisdictionDepartmentsLandRegistryOffice,
    jurisdictionDepartmentsLandTitlesOffice,
    jurisdictionDepartmentsSections,
    jurisdictionSecondLandTitlesOffice
} from './section';
import {BasicUserInfo} from './basic-user-info';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ContactFieldsDiff} from './contact-field-diff';
import {ProvinceCode} from '../../admin/accounts/shared/account-province';
import {
    Combined_Hydro_Water_Department,
    Hydro_Department,
    Water_Department,
    WaterHydroDeptType_IS_COMBINED,
    WaterHydroDeptType_IS_SEPARATE
} from '../../shared-main/constants';

export const JurisdictionPropertyLabel = {
    jurisdictionName    : 'Jurisdiction Name',
    comments            : 'Comments',
    activeFlag          : 'Is record active?',
    CITY                : 'CITY',
    MUNICIPALITY        : 'MUNICIPALITY'
};

export type FormOneType = 'YES' | 'NO';

export class ProcessJurisdictionActions {
    public static readonly SAVE : ProcessJurisdictionAction = "SAVE";
    public static readonly CANCEL : ProcessJurisdictionAction = "CANCEL";
}

export type ProcessJurisdictionAction = "SAVE" | "CANCEL";

export class Jurisdiction {
    id: number;
    isDirty: boolean;
    city: City;
    jurisdictionName: string;
    provinceCode: ProvinceCode;
    registryOffice: RegistryOffice;
    titleOffice: RegistryOffice; //used by NB
    instanceName : string;
    waterHydroDeptType : string;
    departments : Department[];
    private : boolean;
    proxyForGlobal : boolean;
    proxyEdited : boolean;
    globalJurisdictionId? : number;
    locked : boolean;
    lockedByUser : BasicUserInfo = null;
    municipalDeedTransferTaxRate : number;
    lastUpdatedTimeStamp: Date;
    //Immutable version of lastUpdatedTimeStamp, used for optimistic locking by server side.
    lastUpdatedTimeStampStr: string;
    formOne: FormOneType = 'NO';
    sourceAdjudicationRequestId : number;
    comments: string
    activeFlag : string;

    constructor(jurisdiction?: Jurisdiction) {
        this.instanceName = 'Jurisdiction';
        this.jurisdictionName = '';
        if(jurisdiction) {
            for (let prop in jurisdiction) {
                if (jurisdiction.hasOwnProperty(prop)) {
                    this[prop] = jurisdiction[prop];
                }
            }

            if(jurisdiction.city) {
                this.city = new City(jurisdiction.city);
            }

            if(jurisdiction.registryOffice) {
                this.registryOffice = new RegistryOffice(jurisdiction.registryOffice);
            }

            if(jurisdiction.titleOffice) {
                this.titleOffice = new RegistryOffice(jurisdiction.titleOffice);
            }

            this.departments = [];
            if (Array.isArray(jurisdiction.departments)) {
                jurisdiction.departments.forEach((item: Department) => {
                    let department: Department = new Department(item);
                    this.departments.push(department);
                });
            }

            this.lockedByUser = jurisdiction.lockedByUser ? new BasicUserInfo(jurisdiction.lockedByUser) : null;
        } else {
            this.departments = [];
            if(!this.private){
                this.private = true;
            }
        }

        if(!this.waterHydroDeptType && this.provinceCode === 'ON') {
            this.waterHydroDeptType = WaterHydroDeptType_IS_SEPARATE;
        }



        if (!this.city) {
            this.city = new City();
        }

        //Move it from ContactJurisdictionGeneralComponent to here to avoid to enable save button
        if (!this.municipalDeedTransferTaxRate){
            this.municipalDeedTransferTaxRate = 0.00;
        }
        if (!this.activeFlag){
            this.activeFlag = "Y_n";
        }

    }

    equals(other : Jurisdiction) : boolean {
        // console.log("Jurisdiction.equals(), this:", JSON.stringify(this, this.replacer), ", other:", JSON.stringify(other, this.replacer));
        return JSON.stringify(this, this.replacer) === JSON.stringify(other, this.replacer);
    }

    isPrivateCopySameAsGlobal(other : Jurisdiction) : boolean {
        // console.log("Jurisdiction.isPrivateCopySameAsGlobal(), this:", JSON.stringify(this, this.privateCopySameAsGlobalReplacer), ", other:", JSON.stringify(other, this.privateCopySameAsGlobalReplacer));
        return JSON.stringify(this, this.privateCopySameAsGlobalReplacer) === JSON.stringify(other, this.privateCopySameAsGlobalReplacer);
    }

    isPrivateCopySameAsGlobalWithoutDepartments(other : Jurisdiction) : boolean {
        // console.log("Jurisdiction.privateCopySameAsGlobalReplacerWithoutDepartments(), this:", JSON.stringify(this, this.privateCopySameAsGlobalReplacerWithoutDepartments), ", other:", JSON.stringify(other, this.privateCopySameAsGlobalReplacer));
        return JSON.stringify(this, this.privateCopySameAsGlobalReplacerWithoutDepartments) === JSON.stringify(other, this.privateCopySameAsGlobalReplacerWithoutDepartments);
    }

    privateCopySameAsGlobalReplacer(key, val) : any {
        if(key !== "isDirty" && key !== "isValid" && key !== "errorMessage" && key !== "proxyEdited" ) {
            return val;
        }
    }

    privateCopySameAsGlobalReplacerWithoutDepartments(key, val) : any {
        if(key !== "isDirty" && key !== "isValid" && key !== "errorMessage" && key !== "proxyEdited" && key !== "departments" ) {
            return val;
        }
    }

    //This method is to list the properties which should be ignored in stringify
    replacer(key, val) : any {
        if(key !== "isDirty" && key !== "isValid" && key !== "errorMessage") {
            //since this method is used indirectly for testing whether the jurisdiction has been changed (enable save button)
            //for fields with dp-currency or dp-percentage, the data can be formatted to string like '8.00' and model also get update
            //(when onblur from the field) and original data from server still be 8
            //in order to prevent Save button enabled due to the difference between '8.00' and 8 when compare the current jurisdiction and original data from server,
            // we change value string to number (field type is number)
            if(key == 'municipalDeedTransferTaxRate' || key == 'fee'){
                return +val;//force string to number
            }
            return val;
        }
    }

    // builds visible jurisdictions' department sections and not jurisdictions' departments
    buildJurisdictionDepartmentSections(jurisdictionDepartments : JurisdictionDepartment[], provinceCode: ProvinceCode) {
        if(Array.isArray(jurisdictionDepartments))
        {
            jurisdictionDepartmentsSections.unshift(jurisdictionDepartmentsGeneral);
            jurisdictionDepartmentsSections.splice(1);
            if (this.provinceCode === 'ON') {
                jurisdictionDepartmentsSections.push(jurisdictionDepartmentsLandRegistryOffice);
            } else if (this.provinceCode === 'AB' || this.provinceCode === 'MB' || this.provinceCode === 'SK') {
                jurisdictionDepartmentsSections.push(jurisdictionDepartmentsLandTitlesOffice);
            } else if (this.provinceCode === 'NS'){
                jurisdictionDepartmentsSections.push(jurisdictionDepartmentsLandRegistrationOffice);
            } else if (this.provinceCode === 'NB'){
                //for NB, In addition to the 'Land Registry office' Sub-tab a 'Land Titles Office' Sub-tab is also available
                jurisdictionDepartmentsSections.push(jurisdictionDepartmentsLandRegistryOffice);
                jurisdictionDepartmentsSections.push(jurisdictionSecondLandTitlesOffice);
            }

            jurisdictionDepartments
                .filter( dep => dep.provinceCode === provinceCode)
                .forEach((item : JurisdictionDepartment) => {

                    let skip: boolean = false;
                    let title: string = item.departmentName;

                    if (this.waterHydroDeptType && this.waterHydroDeptType === WaterHydroDeptType_IS_COMBINED) {
                        skip = item.departmentName === Hydro_Department;
                        if (item.departmentName === Water_Department) {
                            title = Combined_Hydro_Water_Department;
                        }
                    }

                    if (!skip) {
                        jurisdictionDepartmentsSections.push({title: title, route: `department/${item.departmentPriority}`, active: false});
                    }
                }
            );
        }
    }

    get isGlobal() : boolean {
        return !this.private;
    }

    get isOwnedBySystemAccount() : boolean {
        return this.isGlobal || this.isProxySameAsGlobal;
    }

    get isProxySameAsGlobal() : boolean {
        return this.proxyForGlobal && !this.proxyEdited;
    }

    get isProxyCopyOfGlobal() : boolean {
        return this.proxyForGlobal && this.proxyEdited;
    }

    get lockedByCurrentUser() : boolean {
        return !this.locked;
    }

    get lockedByOtherUser(): boolean {
        const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        return (this.lockedByUser && this.lockedByUser.id !== Number(userId));
    }

    get lockUserName() : string {
        return this.lockedByUser && this.lockedByUser.fullName;
    }

    get isWaterHydroDeptTypeCombined(): boolean{
        return this.waterHydroDeptType === WaterHydroDeptType_IS_COMBINED;
    }

    get isWaterHydroDeptTypeSeparate(): boolean{
        return this.waterHydroDeptType === WaterHydroDeptType_IS_SEPARATE;
    }

    static copyJurisdictionData(sourceJurisdiction: Jurisdiction, targetJurisdiction: Jurisdiction): void {
        targetJurisdiction.jurisdictionName = sourceJurisdiction.jurisdictionName;
        targetJurisdiction.provinceCode = sourceJurisdiction.provinceCode;
        targetJurisdiction.comments = sourceJurisdiction.comments;
        targetJurisdiction.registryOffice = sourceJurisdiction.registryOffice;
        targetJurisdiction.titleOffice = sourceJurisdiction.titleOffice;
        targetJurisdiction.waterHydroDeptType = sourceJurisdiction.waterHydroDeptType;
        targetJurisdiction.city.name = sourceJurisdiction.city.name;
        targetJurisdiction.city.municipality.name = sourceJurisdiction.city.municipality.name;
        targetJurisdiction.municipalDeedTransferTaxRate = sourceJurisdiction.municipalDeedTransferTaxRate;
        targetJurisdiction.activeFlag = sourceJurisdiction.activeFlag;
    }

    determineJurisdictionDiff(checkFields : string[], updatedJurisdiction : Jurisdiction, currentJurisdiction : Jurisdiction) : any {
        let diffs : ContactFieldsDiff[] = [];
        if(!Array.isArray(checkFields) || !updatedJurisdiction) {
            return diffs;
        }

        checkFields.forEach(property => {
            if(updatedJurisdiction.hasOwnProperty(property)) {
                if(!currentJurisdiction || !currentJurisdiction.hasOwnProperty(property)) {
                    if(updatedJurisdiction[property]) {
                        diffs.push(this.createJurisdictionFieldsDiff(JurisdictionPropertyLabel[property],
                            this.changeValueToLabel(property, updatedJurisdiction),
                            ''));
                    }
                } else if(updatedJurisdiction[property] != currentJurisdiction[property]) {
                    diffs.push(this.createJurisdictionFieldsDiff(JurisdictionPropertyLabel[property],
                        this.changeValueToLabel(property, updatedJurisdiction),
                        this.changeValueToLabel(property, currentJurisdiction)));
                }
            }else {
                if(property == 'CITY') {
                    const nameDiffs : ContactFieldsDiff[]
                        = this.determineCityNameDiff(updatedJurisdiction && updatedJurisdiction.city, currentJurisdiction && currentJurisdiction.city);
                    if(Array.isArray(nameDiffs) && nameDiffs.length) {
                        diffs.push(...nameDiffs);
                    }
                }
                if(property == 'MUNICIPALITY'){
                    const nameDiffs : ContactFieldsDiff[]
                        = this.determineMunicipalityNameDiff(updatedJurisdiction && updatedJurisdiction.city && updatedJurisdiction.city.municipality, currentJurisdiction && currentJurisdiction.city && currentJurisdiction.city.municipality);
                    if(Array.isArray(nameDiffs) && nameDiffs.length) {
                        diffs.push(...nameDiffs);
                    }
                }
                if(property == 'REGISTRYOFFICE'){
                    const nameDiffs : ContactFieldsDiff[]
                        = RegistryOffice.determineRegistryOfficeDiff(updatedJurisdiction && updatedJurisdiction.registryOffice, currentJurisdiction && currentJurisdiction.registryOffice);
                    if(Array.isArray(nameDiffs) && nameDiffs.length) {
                        diffs.push(...nameDiffs);
                    }
                }
                if (updatedJurisdiction && updatedJurisdiction.provinceCode == 'NS' && property == 'DEEDTRANSFERTAXRATE'){
                    const municipalDeedTransferTaxRateDiffs : ContactFieldsDiff[]
                    = this.determineMunicipalDeedTransferTaxRateDiff(updatedJurisdiction && updatedJurisdiction.municipalDeedTransferTaxRate, currentJurisdiction && currentJurisdiction.municipalDeedTransferTaxRate);
                    diffs.push(...municipalDeedTransferTaxRateDiffs);
                }
            }

        });
        return diffs;
    }

    determineMunicipalDeedTransferTaxRateDiff(updatedMunicipalDeedTransferTaxRate : number, currentMunicipalDeedTransferTaxRate : number) : ContactFieldsDiff[]{
        let diffs : ContactFieldsDiff[] = [];
        if (!updatedMunicipalDeedTransferTaxRate && !currentMunicipalDeedTransferTaxRate){
            return diffs;
        }

        if (!updatedMunicipalDeedTransferTaxRate && currentMunicipalDeedTransferTaxRate) {
            diffs.push(this.createJurisdictionFieldsDiff('Municipal Deed Transfer Tax Rate', '', currentMunicipalDeedTransferTaxRate));
        }

        if (updatedMunicipalDeedTransferTaxRate && !currentMunicipalDeedTransferTaxRate) {
            diffs.push(this.createJurisdictionFieldsDiff('Municipal Deed Transfer Tax Rate', updatedMunicipalDeedTransferTaxRate, ''));
        }

        if (updatedMunicipalDeedTransferTaxRate && currentMunicipalDeedTransferTaxRate && updatedMunicipalDeedTransferTaxRate !== currentMunicipalDeedTransferTaxRate ) {
            diffs.push(this.createJurisdictionFieldsDiff('Municipal Deed Transfer Tax Rate', updatedMunicipalDeedTransferTaxRate, currentMunicipalDeedTransferTaxRate));
        }
        return diffs;
    }

    determineCityNameDiff(updatedCityName : City, currentCityName : City) : ContactFieldsDiff[] {
        let diffs : ContactFieldsDiff[] = [];
        if(!updatedCityName && !currentCityName) {
            return diffs;
        }

        if(!updatedCityName && currentCityName) {
            if(currentCityName.name) {
                diffs.push(this.createJurisdictionFieldsDiff('City Name', '', currentCityName.name));
            }
        }

        if(updatedCityName && !currentCityName) {
            if(updatedCityName.name) {
                diffs.push(this.createJurisdictionFieldsDiff('City Name', updatedCityName.name, ''));
            }
        }

        if(updatedCityName && currentCityName) {
            if(updatedCityName.name !== currentCityName.name) {
                diffs.push(this.createJurisdictionFieldsDiff('City Name', updatedCityName.name, currentCityName.name));
            }
        }

        return diffs;
    }

    determineMunicipalityNameDiff(updatedMunicipalityName : Municipality, currentMunicipalityName : Municipality) : ContactFieldsDiff[] {
        let diffs : ContactFieldsDiff[] = [];
        if(!updatedMunicipalityName && !currentMunicipalityName) {
            return diffs;
        }

        if(!updatedMunicipalityName && currentMunicipalityName) {
            if(currentMunicipalityName.name) {
                diffs.push(this.createJurisdictionFieldsDiff('Municipality Name', '', currentMunicipalityName.name));
            }
        }

        if(updatedMunicipalityName && !currentMunicipalityName) {
            if(updatedMunicipalityName.name) {
                diffs.push(this.createJurisdictionFieldsDiff('Municipality Name', updatedMunicipalityName.name, ''));
            }
        }

        if(updatedMunicipalityName && currentMunicipalityName) {
            if(updatedMunicipalityName.name !== currentMunicipalityName.name) {
                diffs.push(this.createJurisdictionFieldsDiff('Municipality Name', updatedMunicipalityName.name, currentMunicipalityName.name));
            }
        }
        return diffs;
    }



    createJurisdictionFieldsDiff(fieldName : string, updatedValue : any, globalValue : any) : ContactFieldsDiff {
        return ContactFieldsDiff.createContactFieldsDiff(fieldName, updatedValue, globalValue);
    }
    changeValueToLabel(property : string, jurisdiction : Jurisdiction) : string{
        let label : string = jurisdiction[property];
        return label;
    }

    hasAnyCopyOfGlobalDepartments(): boolean {
        if(Array.isArray(this.departments)){
            return this.departments.some(item=>item.isProxyCopyOfGlobal);
        }
        return false;
    }
}

export class City {
    id: number;
    name: string;
    municipality: Municipality;

    constructor(city?: City) {
        if(city) {
            for (let prop in city) {
                if (city.hasOwnProperty(prop)) {
                    this[prop] = city[prop];
                }

                if(city.municipality) {
                    this.municipality = new Municipality(city.municipality);
                }
            }
        }

        if (this.name == null) {
            this.name = '';
        }

        if (!this.municipality) {
            this.municipality = new Municipality();
        }
    }
}

export class Municipality {
    id: number;
    name: string;

    constructor(municipality?: Municipality) {
        if(municipality) {
            for (let prop in municipality) {
                if (municipality.hasOwnProperty(prop)) {
                    this[prop] = municipality[prop];
                }
            }
        }

        if (this.name == null) {
            this.name = '';
        }
    }
}

export class RegistryOffice {
    id: number;
    address: Address;
    fax: string;
    officeName: string;
    shortOfficeName: string;//Removes land registry and office number from the name and returns that.
    officeNumber: string;
    formattedOfficeNumber: string;//Adds zero in front if single digit office number.
    telephone: Telephone[];
    region: string;
    provinceCode: ProvinceCode;

    constructor(registryOffice?: RegistryOffice) {
        if(registryOffice) {
            for (let prop in registryOffice) {
                if (registryOffice.hasOwnProperty(prop)) {
                    this[prop] = registryOffice[prop];
                }
            }
            this.address = new Address(registryOffice.address);
            this.telephone = [];
            if(Array.isArray(registryOffice.telephone)) {
                for(let j : number = 0; j < registryOffice.telephone.length; j++) {
                    this.telephone[j] = new Telephone(registryOffice.telephone[j]);
                }
            }
        } else {
            this.address = new Address();
            // this.telephone = new Telephone();
        }

    }

    get officeNameAndNumber() {
        return this.shortOfficeName + " " + this.formattedOfficeNumber;
    }

    get workPhone(): string {
        if(this.telephone && this.telephone.length > 0){
            let phone = this.telephone.find(value => value.phoneTypeCode === 'WORK' );
            if(phone) {
                return phone.telephoneNumber;
            }
        }
        return '';
    }

    get writeTo1(): string{
        return this.address ? this.address.addressLine1 : null;
    }

    get writeTo2(): string{
        return this.address ? this.address.addressLine2 : null;
    }

    get writeTo3(): string {
        if (this.address) {
            if (!this.address.city
                && !this.address.provinceName) {
                return '';
            } else {
                return `${this.address.city || ''}, ${this.address.provinceName || ''} ${this.address.postalCode || ''}`;
            }
        }
    }

    formatAddress():string{
        let addressLines : string[] = [];
        let addressString : string='';
        addressLines.push(this.writeTo1);
        addressLines.push(this.writeTo2);
        addressLines.push(this.writeTo3);
        addressString = addressLines.map(item => item && item.trim()).join(' ');
        return addressString;
    }

    static determineRegistryOfficeDiff(updatedRegistryOffice : RegistryOffice, currentRegistryOffice : RegistryOffice) : ContactFieldsDiff[] {
        let diffs : ContactFieldsDiff[] = [];
        if(!updatedRegistryOffice && !currentRegistryOffice) {
            return diffs;
        }
        const updatedRegistryOfficeAddress = updatedRegistryOffice? updatedRegistryOffice.formatAddress() : '';
        const currentRegistryOfficeAddress = currentRegistryOffice? currentRegistryOffice.formatAddress() : '';

        if(!updatedRegistryOffice && currentRegistryOffice) {
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Land Titles Office', null, currentRegistryOffice.region));
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Office No.', null, currentRegistryOffice.officeNumber));
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Name', null, currentRegistryOffice.officeName));
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Write to', updatedRegistryOfficeAddress, currentRegistryOfficeAddress));
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Phone', null, currentRegistryOffice.workPhone));
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Fax', null, currentRegistryOffice.fax));

        }

        if(updatedRegistryOffice && !currentRegistryOffice) {
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Land Titles Office', updatedRegistryOffice.region, null));
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Office No.', updatedRegistryOffice.officeNumber, null));
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Name', updatedRegistryOffice.officeName, null));
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Write to', updatedRegistryOfficeAddress, currentRegistryOfficeAddress));
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Phone', updatedRegistryOffice.workPhone, null));
            diffs.push(ContactFieldsDiff.createContactFieldsDiff('Fax', updatedRegistryOffice.fax, null));
        }

        if(updatedRegistryOffice && currentRegistryOffice) {
            if(updatedRegistryOffice.region != currentRegistryOffice.region) {
                diffs.push(ContactFieldsDiff.createContactFieldsDiff('Land Titles Office', updatedRegistryOffice.region, currentRegistryOffice.region));
            }
            if((updatedRegistryOffice.provinceCode != <ProvinceCode>'AB' || updatedRegistryOffice.provinceCode != <ProvinceCode>'AB') && (updatedRegistryOffice.officeNumber != currentRegistryOffice.officeNumber)) {
                diffs.push(ContactFieldsDiff.createContactFieldsDiff('Office No.', updatedRegistryOffice.officeNumber, currentRegistryOffice.officeNumber));
            }
            if(updatedRegistryOffice.officeName != currentRegistryOffice.officeName) {
                diffs.push(ContactFieldsDiff.createContactFieldsDiff('Name', updatedRegistryOffice.officeName, currentRegistryOffice.officeName));
            }
            if(updatedRegistryOfficeAddress != currentRegistryOfficeAddress) {
                diffs.push(ContactFieldsDiff.createContactFieldsDiff('Write to', updatedRegistryOfficeAddress, currentRegistryOfficeAddress));
            }
            if(updatedRegistryOffice.workPhone != currentRegistryOffice.workPhone) {
                diffs.push(ContactFieldsDiff.createContactFieldsDiff('Phone', updatedRegistryOffice.workPhone, currentRegistryOffice.workPhone));
            }
            if(updatedRegistryOffice.fax != currentRegistryOffice.fax) {
                diffs.push(ContactFieldsDiff.createContactFieldsDiff('Fax', updatedRegistryOffice.fax, currentRegistryOffice.fax));
            }
        }

        return diffs;
    }
}

