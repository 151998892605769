import { Injectable } from "@angular/core";
@Injectable()
export class EventReduceHelper {
    filterevent(data: any[]) {
        return data.reduce(
            (accumulator, current) => {
                if (current.eventDueDate) {
                    if (!accumulator.some((x: { eventDueDate: any; clientReLine: any; eventType: string; }) => x.eventDueDate == current.eventDueDate &&
                        x.clientReLine == current.clientReLine &&
                        x.eventType == "WORKITEMTASK")
                    ) {
                        accumulator.push(current);
                    }
                    return accumulator;
                }
                else if (current.startDate) {
                    if (!accumulator.some((x: { startDate: any; clientReLine: any; }) => x.startDate == current.startDate &&
                        x.clientReLine == current.clientReLine)
                    ) {
                        accumulator.push(current);
                    }
                    return accumulator;
                }

            }, []
        );
    }
}
