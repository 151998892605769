///<reference path="teranet-order-map-response.ts"/>
import {Injectable} from '@angular/core';
import {HttpClient} from '../../core';
import {Logger} from '@nsalaun/ng-logger';
import {Observable} from 'rxjs/Rx';
import {teranetApi} from './teranet-constants';
import {TeranetUser} from './teranet-user';
import {TeranetDocket} from './teranet-docket';
import {TeranetParcelRegisterSearchRequest} from './teranet-parcel-register-search-request';
import {TeranetParcelRegisterSearchResponse} from './teranet-parcel-register-search-response';
import {TeranetDocketRequest} from './teranet-docket-request';
import {TeranetParcelRegisterPurchaseRequest} from './teranet-parcel-register-purchase-request';
import {ParcelRegister} from './parcel-register';
import {TeranetOrderMapRequest} from './teranet-order-map-request';
import {TeranetOrderMapResponse} from './teranet-order-map-response';
import {currentMatter} from '../../matters/shared/current-matter';
import {TeranetRequestInstrumentResponse} from './teranet-request-instrument-response';
import {TeranetRequestInstrumentRequest} from './teranet-request-instrument-request';
import {TeranetOrderInstrumentResponse} from './teranet-order-instrument-response';
import {TeranetOrderInstrumentRequest} from './teranet-order-instrument-request';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {TeranetParcelMapDetail} from './teranet-parcel-map';
import {TeranetAddInstrumentRequest} from './teranet-add-instrument-request';
import {TeranetCourierInstrumentRequest} from './teranet-courier-instrument-request';
import {TeranetDocument} from './teranet-document';
import {EnforcementOffice} from './enforcement-office';
import {TeranetWritSearchRequest} from './teranet-writ-search-request';
import {TeranetWritSearchResponse} from './teranet-writ-search-response';
import {TeranetWritNumberSearchRequest} from './teranet-writ-number-search-request';
import {Writ} from './property-writ';
import {WritExecution} from './property-writ-execution';
import {
    BillingTransactionInfoResponse,
    TeranetViewDocketSummaryRequest,
    TeranetViewDocketSummaryResponse
} from './teranet-view-docket-summary';


@Injectable()
export class TeranetService {
    constructor(private http: HttpClient, private logger: Logger) { }
    private cachedEnforcementOffices: EnforcementOffice[] = [];

    getEnforcementOffices(): Observable<EnforcementOffice[]> {

        if(this.cachedEnforcementOffices.length == 0) {
            let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
            return this.http.get(teranetApi.enforcementOffices.replace("{userId}", userId))
                       .map((res) => {
                           this.cachedEnforcementOffices = [];
                           let data = res['EnforcementOffices'];
                           this.cachedEnforcementOffices = data.map(item => {return new EnforcementOffice(item)});
                           return this.cachedEnforcementOffices;
                       });
        } else {
            return Observable.of(this.cachedEnforcementOffices);
        }

    }

    getTeranetUsers(): Observable<TeranetUser[]> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url: string = teranetApi.teranetUsers(userId);
        return this.http.get(url)
                   .map((res) => {
                       let teranetUsers:TeranetUser[] = [];
                       let data = res['TeranetUsers'];
                       data.forEach(item => {
                           teranetUsers.push(new TeranetUser(item))
                       });
                       return teranetUsers;
                   });
    }

    saveTeranetUser(teranetUser : TeranetUser) : Observable<TeranetUser>{
        if(teranetUser.id){
            return this.editTeranetUser(teranetUser);
        }else{
            return this.createTeranetUser( teranetUser);
        }
    }

    deleteTeranetUser(teranetUser : TeranetUser): Observable<any> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url: string = `${teranetApi.teranetUsers(userId)}/${teranetUser.id}`;
        return this.http.delete(url);
    }

    createTeranetUser(teranetUser : TeranetUser): Observable<TeranetUser> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url: string = teranetApi.teranetUsers(userId);
        return this.http.post(url, JSON.stringify(teranetUser))
                   .map((res) => {
                       return new TeranetUser(res['TeranetUser']);
                   });
    }

    editTeranetUser(teranetUser : TeranetUser): Observable<TeranetUser> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url: string = `${teranetApi.teranetUsers(userId)}/${teranetUser.id}`;
        return this.http.put(url, JSON.stringify(teranetUser))
                   .map((res) => {
                       return new TeranetUser(res['TeranetUser']);
                   });
    }

    getTeranetBalance(teranetUser : TeranetUser): Observable<TeranetUser> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url: string = teranetApi.teranetUserBalance(userId, teranetUser.id);
        return this.http.post(url, JSON.stringify(teranetUser))
                   .map((res) => {
                       return new TeranetUser(res['TeranetUser']);
                   });
    }

    getTeranetForgotPasswordLink() : Observable<string> {
        let url: string = teranetApi.teranetForgotPasswordLink;
        return this.http.get(url)
                   .map((res) => {
                       return res['ForgotPasswordLink'];
                   });
    }

    changePassword(teranetUser : TeranetUser): Observable<TeranetUser> {
        let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
        let url: string = teranetApi.teranetChangePassword(userId, teranetUser.id);

        return this.http.post(url, JSON.stringify(teranetUser))
            .map((res) => {
                return new TeranetUser(res['TeranetUser']);
            });
    }

    createDocket(teranetDocket : TeranetDocket): Observable<TeranetDocket> {
        let url: string = teranetApi.teranetDockets(teranetDocket.matterId);

        return this.http.post(url, JSON.stringify(teranetDocket))
            .map((res) => {
                return new TeranetDocket(res['TeranetDocket']);
            });
    }

    getDocket(matterId : number): Observable<TeranetDocket> {
        let url: string = teranetApi.spinDocket(matterId);

        return this.http.get(url)
                   .map((res) => {
                       return new TeranetDocket(res['TeranetDocket']);
                   });
    }

    searchParcelRegister(teranetSearchRequest: TeranetParcelRegisterSearchRequest, excludeLockScreen? : boolean): Observable<TeranetParcelRegisterSearchResponse> {
        let url: string = teranetApi.teranetSearchParcelRegister
            .replace('{matterId}', teranetSearchRequest.matterId.toString())
            .replace('{propertyId}', '0');

        return this.http.post(url, JSON.stringify(teranetSearchRequest),excludeLockScreen)
                   .map((res) => {
                       return new TeranetParcelRegisterSearchResponse(res['SearchParcelRegister']);
                   });
    }

    searchParcelRegisterByOwnerName(teranetSearchRequest: TeranetParcelRegisterSearchRequest): Observable<TeranetParcelRegisterSearchResponse> {
        let url: string = teranetApi.teranetSearchParcelRegisterByOwnerName
            .replace('{matterId}', teranetSearchRequest.matterId.toString())
            .replace('{propertyId}', '0');

        return this.http.post(url, JSON.stringify(teranetSearchRequest))
            .map((res) => {
                return new TeranetParcelRegisterSearchResponse(res['SearchParcelRegister']);
            });
    }

    purchaseParcelRegister(teranetPurchaseRequest: TeranetParcelRegisterPurchaseRequest, excludeLockScreen? : boolean): Observable<ParcelRegister> {
        let url: string = teranetApi.teranetPurchaseParcelRegister.replace('{matterId}', ''+ currentMatter.value.id);

        return this.http.post(url, JSON.stringify(teranetPurchaseRequest),excludeLockScreen)
                   .map((res) => {
                       return new ParcelRegister(res['PurchaseParcelRegister']);
                   });
    }

    associateDocketWithMatter(teranetDocket : TeranetDocket): Observable<TeranetDocket> {

        let url: string = `${teranetApi.teranetDockets(teranetDocket.matterId)}/teraviewDocketIdentifier`;

        return this.http.post(url, JSON.stringify(teranetDocket))
            .map((res) => {
                return new TeranetDocket(res['TeranetDocket']);
            });
    }

    searchDocket(teranetDocketRequest : TeranetDocketRequest): Observable<TeranetDocket[]> {
        let url: string = teranetApi.teranetDocketSearch(teranetDocketRequest.matterId);

        return this.http.post(url, JSON.stringify(teranetDocketRequest))
            .map((res) => {
                let teranetDocketResult: TeranetDocket[] = [];
                let data = res['TeranetDockets'];
                data.forEach(item => {
                    teranetDocketResult.push(new TeranetDocket(item))
                });
                return teranetDocketResult;
            });
    }

    orderInstrument(teranetOrderInstruemntRequest: TeranetOrderInstrumentRequest) : Observable<TeranetOrderInstrumentResponse> {
        let url: string = teranetApi.teranetOrderInstrument.replace('{matterId}', ''+ currentMatter.value.id);
        return this.http.post(url, JSON.stringify(teranetOrderInstruemntRequest))
                   .map((res) => {
                       let teranetOrderInstrumentResponse : TeranetOrderInstrumentResponse = new TeranetOrderInstrumentResponse();
                       teranetOrderInstrumentResponse.successfulOrders = [] ;
                       teranetOrderInstrumentResponse.failedOrders = [];
                       let successfulOrders = res['SuccessfulOrders'];
                       if(successfulOrders && successfulOrders.length)
                       {
                           successfulOrders.forEach(item => {
                               teranetOrderInstrumentResponse.successfulOrders.push(new TeranetDocument(item));
                           });
                       }

                       let failedOrders = res['FailedOrders'];
                       if(failedOrders && failedOrders.length)
                       {
                           failedOrders.forEach(item => {
                               teranetOrderInstrumentResponse.failedOrders.push(item);
                           });
                       }

                       return teranetOrderInstrumentResponse;
                   });
    }

    orderMap(order: TeranetOrderMapRequest): Observable<TeranetOrderMapResponse> {
        let url: string = teranetApi.teranetOrderMap.replace('{matterId}', ''+ currentMatter.value.id);

        return this.http.post(url, JSON.stringify(order))
            .map((rslt) => {
                return new TeranetOrderMapResponse(rslt['OrderMap']);
            });

    }

    getMapUrl(parcelId: number, mapId: number) : string {
        return teranetApi.teranetViewMap
            .replace('{matterId}', ''+ currentMatter.value.id)
            .replace('{parcelId}', ''+ parcelId)
            .replace('{mapId}', ''+ mapId)
            .replace('{sessionId}', ''+sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
    }

    getMapDetails(parcelId: number, mapId: number): Observable<TeranetParcelMapDetail> {
        let url: string = teranetApi.teranetGetMapDetails.replace('{matterId}', ''+ currentMatter.value.id)
            .replace('{parcelId}', ''+ parcelId)
            .replace('{mapId}', ''+ mapId);

        return this.http.get(url).map((res) => {
            return res['ViewMapDetails'];
        });
    }

	requestInstrument(request: TeranetRequestInstrumentRequest) : Observable<TeranetRequestInstrumentResponse> {
        let url: string = teranetApi.teranetRequestInstrument.replace('{matterId}', ''+ currentMatter.value.id);
        return this.http.post(url, JSON.stringify(request))
            .map((res) => {
                return new TeranetRequestInstrumentResponse(res['OrderExceptionInstrument']);
            });
    }

    requestToCourier(request: TeranetCourierInstrumentRequest, parcelId: number) : Observable<ParcelRegister> {
        let url: string = teranetApi.teranetCourierInstrument.replace('{matterId}', ''+ currentMatter.value.id).replace('{parcelId}', ''+ parcelId);;
        return this.http.post(url, JSON.stringify(request))
            .map((res) => {
                return new ParcelRegister(res['ParcelRegister']);
            });
    }

    checkStatusInstruments(parcelId: number, teranetUser : TeranetUser) : Observable<ParcelRegister> {
        let url: string = teranetApi.teranetCheckStatusInstruments.replace('{matterId}', ''+ currentMatter.value.id).replace('{parcelId}', ''+ parcelId);
        return this.http.post(url, JSON.stringify(teranetUser))
            .map((res) => {
                return new ParcelRegister(res['ParcelRegister']);
            });
    }

    addTeranetInstrument(teranetAddInstrumentRequest: TeranetAddInstrumentRequest): Observable<ParcelRegister> {
        let url: string = teranetApi.teranetAddInstrument.replace('{matterId}', ''+ currentMatter.value.id);
        return this.http.post(url, JSON.stringify(teranetAddInstrumentRequest))
            .map((res) => {
                return new ParcelRegister(res['ParcelRegister']);
            });
    }

    downloadTeranetDocumentAndDisplay(matterId: number, documentId: number) {
        let url: string = teranetApi.downloadTeranetDocument.replace('{matterId}', ''+matterId);
        url = url.replace('{documentId}', ''+documentId);
        url = url.replace('{sessionId}', ''+sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
        console.log("downloadTeranetDocumentAndDisplay url:", url);
        window.open(url, "_blank");
    }

    writNameSearch(writSearchRequest: TeranetWritSearchRequest): Observable<TeranetWritSearchResponse> {
        let url: string = '';
        if(writSearchRequest.writSearchType === "REDO_SEARCH") {
            url = teranetApi.writRedoSearch.replace('{matterId}', ''+writSearchRequest.matterId);
        }else {
            url = teranetApi.writNameSearch.replace('{matterId}', ''+writSearchRequest.matterId);
        }
        return this.http.post(url, JSON.stringify(writSearchRequest))
                   .map((res) => {
                       let teranetWritSearchResponse: TeranetWritSearchResponse = new TeranetWritSearchResponse();
                       teranetWritSearchResponse.successfulWrits = [] ;
                       teranetWritSearchResponse.failedOrders = [];
                       teranetWritSearchResponse.newExecutions = [];
                       teranetWritSearchResponse.removedExecutions = [];
                       let successfulOrders = res['SuccessfulWrits'];
                       if(successfulOrders && successfulOrders.length)
                       {
                           successfulOrders.forEach(item => {
                               teranetWritSearchResponse.successfulWrits.push(new Writ(item));
                           });
                       }

                       let failedOrders = res['FailedOrders'];
                       if(failedOrders && failedOrders.length)
                       {
                           failedOrders.forEach(item => {
                               teranetWritSearchResponse.failedOrders.push(item);
                           });
                       }

                       let removedExecutions = res['RemovedExecutions'];
                       if(removedExecutions && removedExecutions.length)
                       {
                           removedExecutions.forEach(item => {
                               teranetWritSearchResponse.removedExecutions.push(item);
                           });
                       }

                       let newExecutions = res['NewExecutions'];
                       if(newExecutions && newExecutions.length)
                       {
                           newExecutions.forEach(item => {
                               teranetWritSearchResponse.newExecutions.push(item);
                           });
                       }

                       let updatedWrit = res['UpdatedWrit'];
                       if(updatedWrit) {
                           teranetWritSearchResponse.updatedWrit = new Writ(updatedWrit);
                       }
                       return teranetWritSearchResponse;
                   });
    }

    orderWrits(writNumberSearchRequest: TeranetWritNumberSearchRequest): Observable<TeranetWritSearchResponse> {
        let url: string = teranetApi.orderWrits.replace('{matterId}', ''+ currentMatter.value.id);
        return this.http.post(url, JSON.stringify(writNumberSearchRequest))
            .map((res) => {
                let teranetWritSearchResponse: TeranetWritSearchResponse = new TeranetWritSearchResponse();
                teranetWritSearchResponse.successfulWritExecutions = [] ;
                teranetWritSearchResponse.failedOrders = [];
                let successfulOrders = res['SuccessfulWritExecutions'];
                if(successfulOrders && successfulOrders.length)
                {
                    successfulOrders.forEach(item => {
                        teranetWritSearchResponse.successfulWritExecutions.push(new WritExecution(item));
                    });
                }
                let failedOrders = res['FailedOrders'];
                if(failedOrders && failedOrders.length)
                {
                    failedOrders.forEach(item => {
                        teranetWritSearchResponse.failedOrders.push(item);
                    });
                }
                return teranetWritSearchResponse;
            });
    }

    writNumberSearch(writNumberSearchRequest: TeranetWritNumberSearchRequest): Observable<Writ> {
        let url: string = teranetApi.writNumberSearch.replace('{matterId}', ''+ currentMatter.value.id);

        return this.http.post(url, JSON.stringify(writNumberSearchRequest))
            .map((res) => {
                return new Writ(res['UserAddedWrit']);
            });
    }

    viewDocketSummary(teranetViewDocketSummaryRequest: TeranetViewDocketSummaryRequest) : Observable<TeranetViewDocketSummaryResponse[]> {
        let url: string = teranetApi.viewDocketSummary(teranetViewDocketSummaryRequest.matterId);
        return this.http.post(url, JSON.stringify(teranetViewDocketSummaryRequest))
                   .map((res) => {
                       let docketSummary:TeranetViewDocketSummaryResponse[];
                       let data = res['BillingTransactions'];
                       docketSummary = data.map(item=>new TeranetViewDocketSummaryResponse(item));
                       return docketSummary;
                   });
    }

    viewBillingInformation(matterId : number) : Observable<BillingTransactionInfoResponse[]> {
        let url: string = teranetApi.viewBillingInformation.replace('{matterId}', ''+matterId);
        return this.http.get(url)
                   .map((res) => {
                       let billingInfo:BillingTransactionInfoResponse[];
                       let data = res['UnityBillingTransactions'];
                       billingInfo = data.map(item => new BillingTransactionInfoResponse(item));
                       return billingInfo;
                   });
    }

    // getDocket(matterId : number)  : Observable<TeranetDocket> {
    //     let url: string = teranetApi.viewDocketSummary(teranetViewDocketSummaryRequest.matterId);
    //     return this.http.post(url, JSON.stringify(teranetViewDocketSummaryRequest))
    //                .map((res) => {
    //                    let docketSummary:TeranetViewDocketSummaryResponse[];
    //                    let data = res['BillingTransactions'];
    //                    docketSummary = data.map(item=>new TeranetViewDocketSummaryResponse(item));
    //                    return new;
    //                });
    // }
}
