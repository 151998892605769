import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {SoaRealtyTaxAdjustment} from '../modals/realty-tax/soa-realty-tax-adjustment';
import {BaseTaxAdjustmentOn, ProjectTaxAdjustmentConfig} from '../../../projects/project-adjustments/project-tax-adjustment-config';
import moment from 'moment';
import {Matter} from '../../shared';
import {SoaRealtyTaxAdjustmentUtil} from '../modals/realty-tax/soa-realty-tax-adjustment-util';

export class StatementAdjustmentDisplayRealtyTaxBuilder {
    static  getDisplayItems(item: SoaRealtyTaxAdjustment , mainBuilder: StatementAdjustmentDisplayBuilder ): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        let provinceCode = mainBuilder.matter && mainBuilder.matter.provinceCode ? mainBuilder.matter.provinceCode : null;
        let occupancyDate = SoaRealtyTaxAdjustmentUtil.getOccupancyDateForRealtyTax(mainBuilder.matter);
        let totalCondoExpense = SoaRealtyTaxAdjustmentUtil.totalCondominiumExpenses(mainBuilder.matter);
        let baseTaxAdjustment: ProjectTaxAdjustmentConfig = SoaRealtyTaxAdjustmentUtil.getBaseTaxAdjustment(mainBuilder.matter);

        sadItemDetails.push(mainBuilder.getNewItemLine(`${this.previousYearTotalRealtyTaxLabel(item , mainBuilder , baseTaxAdjustment)}`, '' ));
        if(baseTaxAdjustment && this.isRealtyTaxEstimated(baseTaxAdjustment)){
            sadItemDetails.push(mainBuilder.getNewItemLine(`Purchaser's share for ${item.noOfDaysLeft} days` ,  ``));
        }else{
            let shareAtPercentage = ((provinceCode === 'ON' || provinceCode === 'AB') && baseTaxAdjustment && baseTaxAdjustment.taxAdjustmentType === BaseTaxAdjustmentOn.EqualDivisionAmongUnits) ?
                (baseTaxAdjustment.numberOfUnits == 0 ? Number(0 ).toFixed(1):((1 / Number(baseTaxAdjustment.numberOfUnits) * 100)).toFixed(1)) :
                totalCondoExpense.toFixed(1);
            sadItemDetails.push(mainBuilder.getNewItemLine(` Purchaser's share at ${shareAtPercentage}% of ${mainBuilder.getCurrency(item.previousYearTotalTaxes)} ` ,
                ``));
        }
        sadItemDetails.push(mainBuilder.getNewItemLine(`${this.occupancyDateLabel(item , baseTaxAdjustment , occupancyDate)}`, `${item.creditAmount > 0 ? mainBuilder.getCurrency(item.creditAmount) : ''}` ));

        //mainBuilder.getCurrency(item.soaExpenseAdjustment.calculateEstimatedTax()
        sadItemDetails.push(mainBuilder.getNewItemLine(`${item.creditAmount > 0 ? 'Credit Vendor' : 'No Adjustment'}`, ``));

        return sadItemDetails;
    }

    static previousYearTotalRealtyTaxLabel(soaRealtyTax : SoaRealtyTaxAdjustment ,  mainBuilder: StatementAdjustmentDisplayBuilder , baseTaxAdjustment : ProjectTaxAdjustmentConfig):string{
        if(baseTaxAdjustment && this.isRealtyTaxEstimated(baseTaxAdjustment)){
            return 'Estimated ' + soaRealtyTax.realtyTaxAdjustmentYear + ' Taxes ' +  mainBuilder.getCurrency(soaRealtyTax.previousYearTotalTaxes);
        }else{
            return soaRealtyTax.realtyTaxAdjustmentYear + ' Total Realty Taxes ' + mainBuilder.getCurrency(soaRealtyTax.previousYearTotalTaxes);
        }
    }
    static isRealtyTaxEstimated(baseTaxAdjustment : ProjectTaxAdjustmentConfig):boolean {
        return ( baseTaxAdjustment.taxAdjustmentType === BaseTaxAdjustmentOn.percentageOfUnitPurchasePrice ||
             baseTaxAdjustment.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfUnitPurchasePriceIncludingCredits)
    }
    static occupancyDateLabel(soaRealtyTax : SoaRealtyTaxAdjustment , baseTaxAdjustment : ProjectTaxAdjustmentConfig, occupancyDate : string){
        let occupancyDateStr = this.isValidDate(occupancyDate) ?  moment(occupancyDate, "YYYY/MM/DD").format('MMMM DD, YYYY') : '???';
        let occupancyDateLabel = '(Occupancy : ' + occupancyDateStr +')';
        if(baseTaxAdjustment && this.isRealtyTaxEstimated(baseTaxAdjustment)){
            return occupancyDateLabel
        }else{
            return 'for ' + soaRealtyTax.noOfDaysLeft + ' days ' + occupancyDateLabel;
        }
    }
    static isValidDate(occupancyDate : string ): boolean{
        let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
        return pattern.test(occupancyDate);
    }

    static totalCondominiumExpenses(matter: Matter):number{
        if(matter.isMatterProvinceON){
            return Number(matter.matterPropertyWithCondo.condominiumTotalSharePercentage);
        }else {
            return Number(matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes);
        }

    }

}
