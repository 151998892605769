import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {SoaWater} from '../water-soa';

export class StatementAdjustmentDisplayWaterBuilder {

    static getDisplayItems(soAdj: SoaWater, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        let dayText: string = 'days';
        let isPaysForDateOfClosingVendor: boolean =   mainBuilder.paysForDateOfClosing==='VENDOR';

        if (!soAdj.isWaterSupplyTypeFlatRate) {
            if (soAdj.isWaterSupplyTypeMetered) {
                sadItemDetails.push(mainBuilder.getNewItemLine('METERED - No Adjustment', ''));
            } else if (soAdj.isWaterSupplyTypeWellWater) {
                sadItemDetails.push(mainBuilder.getNewItemLine('Supplied by well - No Adjustment', ''));
            }
        }
        else {
            // Flat Rate Water
            sadItemDetails.push(mainBuilder.getNewItemLine('Current Bill', mainBuilder.getCurrency(soAdj.currentWaterBillAmount)));

            if (soAdj.isAdjustFor()) {
                sadItemDetails.push(mainBuilder.getNewItemLine(`${soAdj.adjustType} calculated at ${mainBuilder.getPercentage(soAdj.percentage)}`,
                    mainBuilder.getCurrency(soAdj.currentWaterBillAmount * soAdj.percentage / 100)));
            }
            if (!soAdj.isFullAmountPaid()) {
                sadItemDetails.push(mainBuilder.getNewItemLine(`${mainBuilder.vendorLabel} has paid`,
                    mainBuilder.getCurrency(soAdj.paidTowardsCurrentBilling)));
                sadItemDetails.push(mainBuilder.getNewItemLine(`for period commencing ${mainBuilder.getDate(soAdj.commencementDate)}`, ''));
            }

            if (soAdj.isFullAmountPaid()) {
                sadItemDetails.push(mainBuilder.getNewItemLine(`Paid by ${mainBuilder.vendorLabel} for period`, ''));
                sadItemDetails.push(mainBuilder.getNewItemLine(`commencing ${mainBuilder.getDate(soAdj.commencementDate)}`, ''));
            }

            if (soAdj.getVendorShareDays(mainBuilder.adjustmentDate, isPaysForDateOfClosingVendor) < 2) {
                dayText = 'day';
            }
            sadItemDetails.push(mainBuilder.getNewItemLine(`${mainBuilder.vendorLabel}'s share for ${soAdj.getVendorShareDays(mainBuilder.adjustmentDate, isPaysForDateOfClosingVendor)}  ${dayText}`,
                soAdj.getVendorsShare(mainBuilder.adjustmentDate, isPaysForDateOfClosingVendor) < 0 ? mainBuilder.cannotCalculateAmount : mainBuilder.getCurrency(soAdj.getVendorsShare(mainBuilder.adjustmentDate, isPaysForDateOfClosingVendor)),
                                                           undefined, '10140.WATER.vendorShareAmount'));

            sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${soAdj.needToCreditVendor(mainBuilder.adjustmentDate) ? mainBuilder.vendorLabel : mainBuilder.purchaserLabel}`, ''));
        }

        return sadItemDetails;
    }
}
