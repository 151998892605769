import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '../shared/dialog/dialog.service';
import {LendingInstitution} from './lending-institution';
import {DialogRef} from 'ngx-modialog-7';
import {ContactService} from '../shared-main/contact.service';
import {ModalErrorComponent} from '../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../shared/error-handling/error-service';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';

declare var jQuery : any;

export class LendingInstitutionsModalContext extends BSModalContext {
    sourceTab: string;
    isReLinkingMode: boolean;
    lenders: LendingInstitution[]=[];
}

@Component({
    selector     : 'lending-institutions-modal-content',
    templateUrl  : 'lending-institutions.modal.component.html',

    providers : [DialogService, ErrorService]
})


export class LendingInstitutionsModalComponent implements OnInit{

    @ViewChild('modalErrorComponent') modalErrorComponent : ModalErrorComponent;

    filteredCopyOfLenders : LendingInstitution[] =[];
    selectedLenderIndex: number = -1;
    lenderSearchText: string = '';
    matchedLenderIndex: number = 0;
    context: LendingInstitutionsModalContext;

    constructor(public dialog: DialogRef<LendingInstitutionsModalContext>,
                public dialogService: DialogService , public contactService : ContactService){
        this.context = dialog.context;
    }

    ngOnInit() : void {
        this.filteredCopyOfLenders=this.lenders;
    }

    get lenders(): LendingInstitution[] {
        return this.context.lenders;
    }

    get showDoNotLinkButton(): boolean {
        return !this.context.isReLinkingMode;
    }

    get headerMessage(): string {
        if(this.context.isReLinkingMode) {
            return "Please select the Computershare - Lender combination whose forms you wish to use for this mortgagee. Or select Cancel to continue seeing" +
                " forms for all Computershare partners"
        } else {
            return "Unity has matched the company name entered to multiple Lending institutions.  In order to access a lender's forms, where available," +
                " please choose one of the matched lenders and press select.  Press Do Not Link to close without linking this mortgagee to any lenders forms."
        }
    }

    updateSelectedItem(index: number): void {
        if(this.selectedLenderIndex != index){
            this.selectedLenderIndex = index;
            jQuery('#lenderName-' + index).focus();
        }
    }

    isRowSelected(index: number): boolean {
        return this.selectedLenderIndex === index;
    }

    onSelectLender() : void {
        this.modalErrorComponent.removeAllDpFieldError();
        let selectedLender: LendingInstitution;
        if(this.selectedLenderIndex > -1 && this.selectedLenderIndex < this.lenders.length) {
            selectedLender = this.filteredCopyOfLenders[this.selectedLenderIndex];
            this.dialog.close({action : "OK",selectedLender:selectedLender });
        }
    }

    doNotLinkLender(): void {
        this.dialog.close({action : "NOT_LINK"});
    }

    onCancel(): void {
        this.dialog.close({action : "Cancel"});
    }

    onKeyPress(event : KeyboardEvent) {
        if(event.keyCode === 40 //Down arrow key
            || event.keyCode === 38 //Up arrow key
            || event.keyCode === 13 //Enter key
        ) {
            this.handleArrowKeys(event);
        }
    }

    handleArrowKeys(event) {
        event.preventDefault();
        if (event.keyCode === 40) { //Down arrow key
            if(this.selectedLenderIndex < this.filteredCopyOfLenders.length) {
                this.updateSelectedItem(this.selectedLenderIndex + 1);
            }
        } else if (event.keyCode === 38) { //Up arrow key
            if(this.selectedLenderIndex > 0) {
                this.updateSelectedItem(this.selectedLenderIndex - 1);
            }
        } else if (event.keyCode === 13) {
            this.onSelectLender();
        }
    }


    locateLender() {
        if(this.lenderSearchText.length > 0) {
            this.filteredCopyOfLenders =this.lenders.filter(p => (p.institutionName.toLowerCase().indexOf(this.lenderSearchText.toLowerCase()) !== -1)
                || (p.alternateName.toLowerCase().indexOf(this.lenderSearchText.toLowerCase()) !== -1));
            //this.updateSelectedItem(0);
        }else{
            this.filteredCopyOfLenders =this.lenders;
        }
    }


}
