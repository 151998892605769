import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

import {messages} from '../common';
import {AuthenticationService} from '../core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReferenceDataService} from '../shared-main/reference-data/reference-data.service';
import {UtilsService} from '../main/utils.service';
import {AuthorizationService} from '../shared-main/authorization/authorization-service';
import {DppmCookieService} from '../main/dppm-cookie.service';
import {COOKIE_KEYS} from '../shared/cookie-keys';
import {LockScreenService} from '../core/lock-screen.service';
import {User} from '../matters/shared/user';
import {TabsService} from '../core/tabs.service';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {FocusFirstElementDecorator} from '../shared-main/focus-first-element-decorator';
import {UUIDService} from '../main/uuid.service';
import {AcceptTermsConditions} from './first-login/accept-terms-conditions/accept-terms-conditions';
import {AcceptTermsConditionsService} from './first-login/accept-terms-conditions/accept-terms-conditions.service';
import {appRoutesUrlParts} from '../app.routes';
import {DppmAppComponent} from '../app.component';
import {UserStateService} from '../shared-main/user-state/user-state.service';
import {StaffProfilesService} from '../admin/staff-profiles/staff-profiles.service';
import {HttpClient} from '@angular/common/http';


@Component({
    selector: 'dp-login-form',
    templateUrl: 'login.component.html',
    styleUrls:[
        './unity-login.styles.scss'
    ],
})

/**
 * Component responsible for authentication of the user
 *  and redirects to the appropriate component
 */
@FocusFirstElementDecorator()
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    // readonly validationMessages: Object = messages.loginPage;

    user: User = new User();
    errorMsg: string;
    submitted: boolean = false;
    // if forgotCredentialsFlag is true redirect to ForgotPassword component
    forgotCredentialsFlag: boolean = false;
    apiVersion: string;
    serverVersion: string;
    loginTimeout: any;
    privacyPolicyText: string;

    public readonly formTypeId: string = "loginPage";

    constructor(
        public fb: FormBuilder,
        public router: Router,
        public activatedRoute : ActivatedRoute,
        public cookieService: DppmCookieService,
        public authenticationService: AuthenticationService,
        public authorizationService: AuthorizationService,
        public referenceDataService: ReferenceDataService,
        public utilsService: UtilsService,
        public tabsStateService : TabsService,
        public lockScreenService : LockScreenService,
        public userStateService : UserStateService,
        public staffProfilesService : StaffProfilesService,
        public uuidService : UUIDService,
        public acceptTermsConditionsService : AcceptTermsConditionsService,
        private http: HttpClient,
    ) {
    }

    ngOnInit(): void {

        this.cleanUpBrowserSession();
        this.buildForm();
        // Commenting this line to remove any anonymous call for Appcues.
        // The only place to call appcues is in MainComponent with the logged in user information
        // (<any>window).Appcues.anonymous();
        this.activatedRoute
            .params
            .subscribe((params : Params) => {
                if(params["timeout"] == 1){
                    this.errorMsg = messages[this.formTypeId].sessionExpired ;
                }
                if(params["timeout"] == 2){
                    this.errorMsg = messages[this.formTypeId].sessionTimedOut;
                }
                if(params["timeout"] == 3){
                    this.errorMsg = messages[this.formTypeId].suspended;
                }

            });

        this.utilsService.getApiVersion().subscribe(
            data => {
                if(data && data.APPLICATIONINFO && data.APPLICATIONINFO.version && data.APPLICATIONINFO.appEnv) {
                    this.apiVersion = data.APPLICATIONINFO.version + " / " + data.APPLICATIONINFO.appEnv;
                    this.serverVersion =data.APPLICATIONINFO.version;
                }

            },
            error => {}
        );

        this.loadPrivacyPolicy();
    }





    public cleanUpBrowserSession() : void {
        this.staffProfilesService.cleanCachedLoggedInStaffProfile();
        this.authorizationService.removeAuthenticatedUser();
        this.tabsStateService.clearAllTabs();
        this.userStateService.clearUserStat();
        sessionStorage.clear();
    }

    buildForm(): void {
        let publicAccountId = this.cookieService.getCookie(COOKIE_KEYS.rememberMeAccountId) ? this.cookieService.getCookie(COOKIE_KEYS.rememberMeAccountId) : "";
        let loginId = this.cookieService.getCookie(COOKIE_KEYS.rememberMeUserId) ? this.cookieService.getCookie(COOKIE_KEYS.rememberMeUserId) : "";
        this.loginForm = this.fb.group({
            publicAccountId: [publicAccountId, [Validators.required]],
            loginId: [loginId, [Validators.required]],
            password: ['', [
                Validators.required,
                Validators.maxLength(100),
            ]],
            rememberMe: [!!(publicAccountId && loginId)]
        });
    }

    /**
     * Submit user credentials.
     */
    login(form: FormGroup): void {
        // event.preventDefault();
        this.removeErrorMsg();
        this.submitted = true;
        // if form is invalid return
        if (!form.valid) {
            return;
        }
        form.value.loginId = form.value.loginId.trim();
        form.value.publicAccountId  = form.value.publicAccountId.trim();

        // form is valid and authenticate user
        this.cleanUpBrowserSession();
        this.lockScreenService.lockForUpdate = true;
        let userAgentId = this.cookieService.getUserAgentId(form.value.loginId , form.value.publicAccountId );
        form.value.agentId = userAgentId? userAgentId.agentId : null;
        this.authenticationService.login(form.value).subscribe((data: boolean) => {
                if (data) {
                    //On success authentication initializing reference data cache
                    this.submitted = false;
                    let user = new User(form.value);
                    this.cookieService.setRememberMeOptions(user);

                    // true means login successful and response have loggedInUser information
                    // if last attempted url is present in authenticateService redirect to same
                    // otherwise redirect to application's default url
                    let sessionUser : any  = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
                    if(sessionUser  && sessionUser != null)
                    {
                        let authenticatedUser = new User(JSON.parse(sessionUser));
                        if(authenticatedUser.authChallengeRequired){
                            this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.trustedPhoneNumber}`]);
                        }
                        else {
                            if(authenticatedUser.passwordChangeRequired){
                                this.router.navigate(['first-login/setup-password']);
                            }
                            else {
                                this.authorizationService.initializationAfterLogin(this.referenceDataService, this.uuidService);
                                this.navigateToStep(authenticatedUser);
                            }

                        }
                    }
                } else {
                    // login successful but response doesn't have loggedInUser information
                    // should never come here api must return the loggedInUser information
                    // stay on login page and display error
                    this.lockScreenService.lockForUpdate = false;
                }
            },
            (error) => {
                // authentication is unsuccessful
                // display error message on login page
                this.lockScreenService.lockForUpdate = false;
                if(error.errorCode == 'app.accountLockedOut'){
                    this.errorMsg = messages[this.formTypeId].suspended;
                } else {
                    this.errorMsg = messages[this.formTypeId].general;
                }
                // this.loginTimeout = setTimeout(() => {
                //     this.removeErrorMsg();
                // }, 4000);

            });
    }

    navigateToStep(authenticatedUser: User){
        if(authenticatedUser.caslAccepted){
            this.checkTermsAndConditionsVersionUptoDate(authenticatedUser);
        } else {
            this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.acceptCasl}`]);
        }
    }


    removeErrorMsg(){
        if(this.loginTimeout){
            clearTimeout(this.loginTimeout);
        }
            this.errorMsg = null;
    }

    checkTermsAndConditionsVersionUptoDate(user: User){
        this.acceptTermsConditionsService.getTermsAndConditions().subscribe(
            (res: AcceptTermsConditions) => {
                if(res){
                    let termsAcceptedDate = res.createdDate;
                    if(termsAcceptedDate <= user.termsAcceptedDate)
                    {
                        this.authorizationService.navigateToGetRedirectUrl();
                    }
                    else {
                        this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.termsAndConditions}`]);
                    }
                }

            },
            (error) =>{
                this.errorMsg = messages[this.formTypeId].internal;
                // this.loginTimeout = setTimeout(() => {
                //     this.removeErrorMsg();
                // }, 4000);
            }
        );
    }

    /**
     * When user click on forgot my credentials links.
     */
    activateCredentialsFlag() {
        this.forgotCredentialsFlag = true;
    }

    /**
     * When user click on back to login link after resetting credentials
     */
    resetForgotCredentialsFlag(event) {
        this.forgotCredentialsFlag = false;
    }

    loadPrivacyPolicy() {
        this.http.get('/assets/static/privacy-policy/privacy-policy.html', ({ responseType: 'text' }))
            .subscribe((res)=>{
                if(res){
                    this.privacyPolicyText = res;
                }
            });
    }

    ngAfterViewInit() {}
    /**
     * Retrieves the application ui and api version
     */

}
