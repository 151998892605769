import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Address} from '../../matters/shared/address';
import {ProvinceCode} from '../accounts/shared/base-province';

export class TrustAccount extends BaseEntity {
    id: number;
    mortgageeName : string;
    mortgageeMailingAddress : Address;
    customerAccountId : number;
    mortgageeId : number;
    institutionNumber : string ; //max 3 digits, padded with 0 if less
    transitNumber : string ; //max 5 digits, padded with 0 if less
    receiveMortgageFundsCode: string;
    trustAccountKey: string;
    trustAccountName: string; //max 75
    trustAccountNumber: string; // max 15
    chequePayableTo: string; //max 75
    comments : string; //max 250
    provinceCode: ProvinceCode;
    projectId: number;
    contactName: string;
    faxNumber: string;
    phoneNumber: string;
    defaultForProject: boolean; // default account for form4 deposits in projects

    constructor(trustAccount?: TrustAccount) {
        super(trustAccount);
        if(trustAccount){
            this.mortgageeMailingAddress = new Address(trustAccount.mortgageeMailingAddress);
        } else {
            this.mortgageeMailingAddress = new Address();
        }
    }
}
