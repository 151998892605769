import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class ManitobaLTTTier extends BaseEntity{
    description : string;
    tierMaximum : number;
    rate : number;
    minus : number;

    calculatedTaxValue : number;
}
