import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {HttpClient} from '../../core';
import {Observable} from 'rxjs';
import {StewartAssystUser} from '../telus/stewart-assyst-user';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {fctApi, FctDealContext} from './fct-constants';
import {FCTUser} from './fct-user';
import {SolicitorMortgageInstruction} from '../telus/solicitor-mortgage-instruction';
import {StewartMortgageInstruction} from '../telus/stewart-mortgage-instruction';
import {StewartAssystMortgageInstructionData} from '../telus/stewart-assyst-mortgage-instruction-data';
import {Matter} from '../../matters/shared';
import {SubmitFileResponse} from './submit-file-response';
import {MatterTitleInsurance} from '../../matters/shared/matter-title-insurance';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {LockScreenService} from '../../core/lock-screen.service';
import {TitleInsuranceTitle} from '../constants';
import Utils from '../utils';
import {Mortgage} from '../../matters/shared/mortgage';
import {SendTxnDataResponse} from './send-txn-data-response';
import {FctLenderChanges} from './fct-lender-changes';
import {FctGeneratedDocument} from './fct-generated-document';
import {FctEmpGeneratedDocument} from './fct-emp-generated-document';
import {FctBusinessRules} from './fct-business-rules';
import moment from 'moment';
import {FctLenderChangesStatusRequest} from './fct-lender-changes.-status-request';
import {SendLenderChangesStatusResponse} from './send-lender-changes-status-response';


@Injectable()
export class FCTService {

    constructor(private http: HttpClient,
                private lockScreenService: LockScreenService,
                private errorService: ErrorService,
                private logger: Logger) {
    }

    getFCTUser(): Observable<FCTUser> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.fctUser.replace('{accountId}', accountId);
        return this.http.get(url)
            .map((res) => {
                return new FCTUser(res['FCTUser']);
            });
    }

    validateUser(fctUser: FCTUser): Observable<FCTUser> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.validateFctUser.replace('{accountId}', accountId);
        return this.http.post(url, JSON.stringify(fctUser))
            .map((res) => {
                return new StewartAssystUser(res['FCTUser']);
            });
    }

    deleteFCTUnityUser(fctUser: FCTUser): Observable<any> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.deleteFCTUser.replace('{userId}', '' + fctUser.id).replace('{accountId}', accountId);
        return this.http.delete(url);
    }

    getFCTForgotPswdUrl(): Observable<String> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.forgotUrl.replace('{accountId}', accountId);
        return this.http.get(url)
            .map((res) => {
                return res['FCTUrl'];
            });
    }

    getFCTMyProfile(fctUser: FCTUser): Observable<string> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.getMyProfile.replace('{userId}', '' + fctUser.id).replace('{accountId}', accountId);
        return this.http.get(url)
            .map((res) => {
                return res['FCTProfileUrl'];
            });
    }

    getFctUsers(): Observable<FCTUser[]> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.getFctUsers.replace('{accountId}', accountId);
        return this.http.get(url)
            .map((res) => {
                return (res['FctUsers']).map((item) => {
                    return new FCTUser(item);
                });
            });
    }

    getNewMortgageInstructions(solicitorIds: number[]): Observable<SolicitorMortgageInstruction[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.getNewMortgageInstructions.replace('{accountId}', accountId) + `?solicitorIds=${solicitorIds}`;
        return this.http.get(url)
            .map((res) => {
                return (res['FCTMortgageInstructions']).map((item) => {
                    return new SolicitorMortgageInstruction(item);
                });
            });
    }

    getDealData(solicitorId: number, dealId: string, status?: string): Observable<StewartMortgageInstruction> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.getDealData.replace('{accountId}', accountId).replace('{solicitorId}', "" + solicitorId).replace('{dealId}', "" + dealId);
        if (status) {
            url = url + `?status=${status}`;
        }
        return this.http.get(url)
            .map((res) => {
                return new StewartMortgageInstruction(res['StewartAssystMortgageInstruction']);
            });
    }

    refreshDealStatus(solicitorId: number, dealId: string , lockScreen? : boolean): Observable<StewartMortgageInstruction> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.refreshDealStatus.replace('{accountId}', accountId).replace('{solicitorId}', "" + solicitorId).replace('{dealId}', "" + dealId);
        return this.http.get(url , true)
            .map((res) => {
                this.errorService.removeDpThirdPartyNotification('integrations.fct.validCredentials.required');
                return new StewartMortgageInstruction(res['StewartAssystMortgageInstruction']);
            }).catch(e => {
                this.handleErrors(e);
                return Observable.of(null);
            });
    }

    handleErrors(e) : void {
        if(e.errorCode === 'app.fctUserNotFoundForSolicitor') {
            this.errorService.addDpThirdPartyNotification(DPError.createDPError('integrations.fct.validCredentials.required'));
        } else {
            this.errorService.addDpThirdPartyNotification(DPError.createCustomDPError(e.errorCode, e.message, 'ERROR', 'ERROR' ));
        }
    }

    getLenderChanges(solicitorId: number, dealId: string , lockScreen? : boolean): Observable<FctLenderChanges> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.lenderChanges.replace('{accountId}', accountId).replace('{solicitorId}', "" + solicitorId).replace('{dealId}', "" + dealId);
        return this.http.get(url , lockScreen)
            .map((res) => {
                return new FctLenderChanges(res['fctLenderChanges']);
            });
    }

    getFctBusinessRules(fiNumber: string) {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.fctBusinessRules.replace('{accountId}', accountId).replace('{fiNumber}', fiNumber);
        return this.http.get(url)
            .map((res) => {
                return new FctBusinessRules(res['FCTBusinessRules']);
            });
    }

    acceptDeal(solicitorId: number, dealId: string, dealData: StewartAssystMortgageInstructionData): Observable<StewartMortgageInstruction> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.acceptDeal.replace('{accountId}', accountId).replace('{solicitorId}', "" + solicitorId).replace('{dealId}', "" + dealId);
        return this.http.post(url, dealData)
            .map((res) => {
                return new StewartMortgageInstruction(res['FctAcceptedDeal']);
            });
    }

    declineDeal(solicitorId: number, dealId: string, reasonToDecline: string): Observable<string> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.declineDeal.replace('{accountId}', accountId).replace('{solicitorId}', "" + solicitorId).replace('{dealId}', "" + dealId) + `?reason=${reasonToDecline}`;
        return this.http.post(url, null)
            .map((res) => {
                return res['FctDeletedDeal'];
            });
    }

    submitFctFileRequest(matter: Matter, solicitorId: number): Observable<SubmitFileResponse> {
        if (matter == null || solicitorId == null) {
            return Observable.empty();
        }
        let url: string = fctApi.fctSubmitFile.replace('{solicitorId}', "" + solicitorId).replace('{matterId}', "" + matter.id);
        return this.http.post(url, matter.id)
            .map((response) => {
                return new SubmitFileResponse(response['FctSubmitFile']);
            });
    }

    sendFctEmpTxnDataRequest(matter: Matter, solicitorId: number, fctEmpMortgageId: number): Observable<SendTxnDataResponse> {
        if (matter == null || solicitorId == null||fctEmpMortgageId == null) {
            return Observable.empty();
        }
        let url: string = fctApi.getFctEmpSendTxnDataApi(matter.id, solicitorId, fctEmpMortgageId);
        return this.http.post(url, matter.id)
            .map((response) => {
                return new SendTxnDataResponse(response['FctEmpSendTxnData']);
            });
    }

    getSolicitorLinkedFctUser(solicitorId: number, lockScreen?: boolean): Observable<String> {
        if (solicitorId == null) {
            return Observable.of(null);
        }
        let url: string = fctApi.fctUserBySolicitor.replace('{solicitorId}', "" + solicitorId);
        return this.http.get(url, lockScreen ? lockScreen : false)
            .map((res) => {
                return res['FctUserByContact'];
            }).catch(e => {
                if (e.errorCode === 'app.fctUserNotFound') {
                    return Observable.of(null);
                }
            });
    }


    getFCTRedirectUrl(context): Observable<string> {

        // context - PAYOUTSTMT (payout link )or  LLCINFO (info and signup)
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

        let url: string = fctApi.redirectUrl.replace('{accountId}', accountId).replace('{context}', context);
        return this.http.get(url)
            .map((res) => {
                return res['FCTUrl'];
            });
    }

    getFctRedirectUrlInDealContext(context: FctDealContext, solicitorId: number, transactionId: string): Observable<string> {

        // DEAL CONTEXT (LLCDEAL or LLCDEALRO)
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

        let url: string = fctApi.redirectUrlInDealContext.replace('{accountId}', accountId)
            .replace('{solicitorId}', ""+solicitorId)
            .replace('{context}', context)
            .replace('{transactionId}', transactionId);
        return this.http.get(url)
            .map((res) => {
                return res['FCTUrl'];
            });
    }

    getFCTContactUsUrl(): Observable<string> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.redirectUrl.replace('{accountId}', accountId).replace('{context}', 'LLCCONTACTUS');
        return this.http.get(url)
            .map((res) => {
                return res['FCTUrl'];
            });
    }

    getFctStatus(matter: Matter): Observable<MatterTitleInsurance> {
        let url: string = fctApi.getFctStatus(matter.id + '', matter.solicitor.contact.id + '', matter.matterTitleInsurance.dealId);
        return this.http.get(url)
            .map((res) => {
                return new MatterTitleInsurance(res['FctGetStatusResponse']);
            });
    }

    validateCredentialsForFct(matter: Matter): Observable<boolean> {
        if (matter.selectedSolicitorId) {
            return this.getSolicitorLinkedFctUser(matter.selectedSolicitorId).map((fctUserName: string) => {
                this.errorService.removeDpMissingFieldErrorsByPartialKey('integrations.fct.validCredentials.required');
                if (!matter.selectedSolicitorId || !fctUserName) {
                    this.errorService.addDpMissingFieldError(DPError.createDPError("integrations.fct.validCredentials.required"));
                    return false;
                } else {
                    return true;
                }
            });
        } else {
            return Observable.of(false);
        }
    }

    updateFctLenderChanges(solicitorId: number, dealId: string , fctLenderChangesStatusRequest : FctLenderChangesStatusRequest): Observable<SendLenderChangesStatusResponse> {
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url: string = fctApi.lenderChanges.replace('{accountId}', accountId).replace('{solicitorId}', "" + solicitorId).replace('{dealId}', "" + dealId);
        return this.http.post(url , fctLenderChangesStatusRequest)
            .map((res) => {
                return new SendLenderChangesStatusResponse(res['FctSendLenderChangesStatus']);
            });
    }

    updateFctStatus(matter: Matter, isMatterOpening: boolean): Observable<MatterTitleInsurance> {

        if (matter && matter.id && matter.solicitor && matter.solicitor.contact && matter.solicitor.contact.id && matter.matterTitleInsurance && matter.matterTitleInsurance.dealId) {
            this.errorService.removeDpThirdPartyNotification('notification.fctTitle.invalid');
            this.errorService.removeDpThirdPartyNotification('notification.fctTitle.status');
            if (!isMatterOpening) {
                this.lockScreenService.lockForUpdate = true;
            }
            return this.getFctStatus(matter)
                .map((result: MatterTitleInsurance) => {
                    matter.matterTitleInsurance = result;
                    matter.titleInsuranceBuildMortgagePolicies();
                    if (!isMatterOpening) {
                        this.updateThirdPartyFctStatusNotification(result);
                    }

                    if (matter && matter.soaTrustLedgerCollection) {
                        matter.soaTrustLedgerCollection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
                    }
                    if(matter.secondarySoaSheetsCollection){
                        matter.secondarySoaSheetsCollection.forEach( collection =>{
                            collection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
                        })
                    }

                    return result;
                }).catch(() => {
                    this.updateThirdPartyFctInvalidNotification();
                    return Observable.of(null);
                }).finally(() => {
                    if (!isMatterOpening) {
                        this.lockScreenService.lockForUpdate = false;
                    }
                });

        } else {
            console.log('Can not create url for getFctStatus');
            return Observable.of(null);
        }
    }

    downloadDocument(matter: Matter, documentName: string): Observable<any> {
        let url: string = fctApi.downloadDocument.replace('{matterId}', "" + matter.id).replace('{solicitorId}', '' + matter.selectedSolicitorId).replace('{dealId}', matter.matterTitleInsurance.dealId).replace('{documentName}', documentName);
        return this.http.get(url);
    }

    deleteAllTitleInsuranceDocuments(matter: Matter): Observable<any> {
        let url: string = fctApi.deleteTitleInsuranceDocuments.replace('{matterId}', "" + matter.id).replace('{solicitorId}', '' + matter.selectedSolicitorId).replace('{dealId}', matter.matterTitleInsurance.dealId);
        return this.http.delete(url);
    }

    updateThirdPartyFctStatusNotification(matterTitleInsurance: MatterTitleInsurance) {
        if (matterTitleInsurance) {
            //if (!matterTitleInsurance.isOrderStatusCancelled()) { //DPPMP-21460
                let message = matterTitleInsurance.invoiceOrderStatus ? "<span class='boldfont line-height-1'>" + matterTitleInsurance.invoiceOrderStatus + ":</span>" : '';
                message = message + " (" + moment(Date.now()).format('MMM DD, YYYY hh:mm A') + ")";
                this.errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.fctTitle.status', message, TitleInsuranceTitle.FCT, "NOTIFICATION"));
            //}
        }
    }

    updateThirdPartyFctInvalidNotification() {
        let message = 'The title insurance order status could not be retrieved. Please visit the FCT page from the Title Insurance tab.';
        message = message + " (" + moment(Date.now()).format('MMM DD, YYYY hh:mm A') + ")";
        this.errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.fctTitle.invalid', message, TitleInsuranceTitle.FCT, "NOTIFICATION"));
    }

    checkDownloadsAvailable(matterTitleInsurance: MatterTitleInsurance): void {
        if (matterTitleInsurance.isFCTDocumentsPresent() && !matterTitleInsurance.isFCTDownloadsCompleted()) {
            let message = "New Document(s) available";
            this.errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.fctTitle.documentsAvailable', message, 'FCT Title Insurance', "NOTIFICATION", null, null, 'M220001'));
        }
    }

    addNotifications(empMortgage: Mortgage, matter: Matter): void {
        let mortgageIndex = matter.mortgages.findIndex(item => item.id == empMortgage.id);
        this.errorService.removeDpThirdPartyNotification('notification.fct.noteIndicator.'+empMortgage.id);
        this.errorService.removeDpThirdPartyNotification('notification.fct.lenderChange.'+empMortgage.id);
        // this.errorService.removeDpThirdPartyNotification('notification.fct.documentDownload.'+empMortgage.id);

        if (empMortgage.stewartAssystMortgageInstruction && empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData &&
            empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.fctStatusData && empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.fctStatusData.lenderNotes) {

            this.errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.fct.noteIndicator.'+empMortgage.id, 'New note(s) available',
                empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.provider + ' - ' + Utils.getOrdinal(mortgageIndex + 1) + ' Mortgage', "NOTIFICATION" , null ,null,'M40004' ,null , empMortgage.id));
        }

        if (empMortgage.stewartAssystMortgageInstruction && empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData &&
            empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.fctStatusData && empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.fctStatusData.lenderChanges) {

            this.errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.fct.lenderChange.'+empMortgage.id, 'New lender change(s) available',
                empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.provider + ' - ' + Utils.getOrdinal(mortgageIndex + 1) + ' Mortgage', "NOTIFICATION" , null ,null,'M40005' ,null , empMortgage.id));
        }
    }

    //US17871, 'Document Available For Download Notification' only needed After refreshStatus from MatterOpening
    addDocumentAvailableForDownloadNotification(empMortgage: Mortgage, matter: Matter): void{
        let mortgageIndex = matter.mortgages.findIndex(item => item.id == empMortgage.id);
        this.errorService.removeDpThirdPartyNotification('notification.fct.documentDownload.'+empMortgage.id);
        if (empMortgage.stewartAssystMortgageInstruction
            && empMortgage.stewartAssystMortgageInstruction.isFctDealInCompletedStatus
            && empMortgage.stewartAssystMortgageInstruction.areDocumentsAvailableToDownload
            && empMortgage.stewartAssystMortgageInstruction.hasOutstandingDocumentsForDownload) {
            //if FCT EMP deal is complete and there is NEW/UPDATED documents, then show the notification
            let fctProviderName: string = empMortgage.stewartAssystMortgageInstruction.provider &&
                empMortgage.stewartAssystMortgageInstruction.provider.toUpperCase();//should be FCT-LLC or FCT-MMS
            this.errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.fct.documentDownload.' +
                empMortgage.id, 'Document(s) available for download',
                `${fctProviderName} - ${Utils.getOrdinal(mortgageIndex + 1)} Mortgage`, "NOTIFICATION" , null ,
                null,'M40003' ,null , empMortgage.id, null,null, empMortgage));
        }
    }

    getFctEmpDocuments(matterId: number, solicitorId: number, dealId: string , lockScreen? : boolean): Observable<FctEmpGeneratedDocument[]> {
        let url: string = fctApi.empDocuments
            .replace('{matterId}', matterId.toString())
            .replace('{solicitorId}', solicitorId.toString())
            .replace('{dealId}', dealId.toString());
        return this.http.get(url , lockScreen)
            .map((res) => {
                let fctDocs: FctEmpGeneratedDocument[] = [];
                let data = res['FctEmpDocuments'];
                data.forEach(item => {
                    fctDocs.push(new FctEmpGeneratedDocument(item));
                });
                return fctDocs;
            });
    }

    deleteAllEmpDocuments(matterId: number, solicitorId: number, dealId: string): Observable<any> {
        let url: string = fctApi.empDocuments
            .replace('{matterId}', matterId.toString())
            .replace('{solicitorId}', solicitorId.toString())
            .replace('{dealId}', dealId.toString());
        return this.http.delete(url);
    }

    getFctEmpDocument(matterId: number, solicitorId: number, dealId: string , docId: number, lockScreen? : boolean): Observable<FctGeneratedDocument> {
        let url: string = fctApi.empDocument
            .replace('{matterId}', matterId.toString())
            .replace('{solicitorId}', solicitorId.toString())
            .replace('{dealId}', dealId.toString())
            .replace('{documentId}', docId.toString());
        return this.http.get(url , lockScreen)
            .map((res) => {
                return new FctGeneratedDocument(res['FctEmpDocument']);
            });
    }
}
