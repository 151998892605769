import {Injectable} from '@angular/core';
import {HttpClient} from '../../core/httpClient.service';
import {api} from '../../common/api';
import {TrustAccount} from './trust-account';
import {Observable} from 'rxjs';
import {Utils} from '../../matters/shared';
import {Logger} from '@nsalaun/ng-logger';
import {matterApi} from '../../matters/shared/matter-api';
import {Contact} from '../../matters/shared/contact';
import {matterResponseKey} from '../../matters/shared/matter-response-key';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ProvinceCode} from '../accounts/shared/account-province';

/**
 *  Trust Accounts service defines all api calls for Trust Accounts component
 */
@Injectable()
export class TrustAccountsService {

    constructor(private http : HttpClient, private logger: Logger) { }

    getTrustAccounts(accountId: number, forProvince?: ProvinceCode, projectId?: number) : Observable<TrustAccount[]> {

       let provinceFilter: string = forProvince ? `filter=provinceCode_EQ_${forProvince}&filterType=ALL` : ``;
       let accountTypeFilter: string = projectId && projectId>0 ? `accountType=PROJECT&projectId=${projectId}` : `accountType=ACCOUNT`;

       accountTypeFilter = forProvince ? `&` + accountTypeFilter : accountTypeFilter;

       let url: string = `${api}/accounts/${accountId}/trustAccounts?${provinceFilter}${accountTypeFilter}`;

        this.logger.debug("getAllTrustAccounts, url=", url);
        return this.http.get(url)
            .map((res) => {
                let data = res['TrustAccounts'];
                if(Array.isArray(data)) {
                    return data.map(item => {
                        return new TrustAccount(item);
                    });
                }else {
                    return [];
                }
            });
    }

    saveTrustAccount(accountId: number, values : any) : Observable<TrustAccount> {
        this.logger.debug("saveUserDefinedFields, values=", values);
        if(values.id){
            let url: string = `${api}/accounts/${accountId}/trustAccounts/${values.id}`;
            return this.http.put(url, values)
                .map((res) => {
                    return new TrustAccount(res['TrustAccount']);
                });
        } else {
            let url: string = `${api}/accounts/${accountId}/trustAccounts`;
            return this.http.post(url, values)
                .map((res) => {
                    return new TrustAccount(res['TrustAccount']);
                });
        }
    }

    deleteTrustAccount(accountId: number, trustAccount : TrustAccount): Observable<any> {

        let url: string = `${api}/accounts/${accountId}/trustAccounts/${trustAccount.id}`;
        return this.http.delete(url);
    }

    getMortgageeByInstitutionNoAndTransitNo (institutionNo : string, transitNo : string) : Observable<Contact[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

        const filterUrl : string
            = `?contactType=MORTGAGEE&filter=ALLinstitutionNo_EQ_${Utils.escapeSearchText(institutionNo)},transitNo_EQ_${Utils.escapeSearchText(transitNo)},depositsAccepted_EQ_TRUE`;

        return this.http.get(`${matterApi.contactsList(accountId)}${filterUrl}`)
                   .map((res) => {
                       const data = res[matterResponseKey.contacts];
                       let mortgagees = [];
                       if(Array.isArray(data)) {
                           mortgagees = data.map(item=> new Contact(item));
                       }
                       return mortgagees;
                   });
    }

    async getDefaultFirmAccountOnProject(projectId : number , provinceCode : ProvinceCode) : Promise<TrustAccount>{
        let projectTrustAccounts : TrustAccount[] = await this.getTrustAccounts(Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId)), provinceCode, projectId).toPromise();
        let defaultExists = projectTrustAccounts.find(acct =>  acct.defaultForProject);
        if(defaultExists) {
            return Promise.resolve(defaultExists) ;
        }else{
            return Promise.resolve(null) ;
        }
    }
}
