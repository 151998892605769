import {DocumentTypeType} from '../../document-production/document';

export class  DocumentIdentificationData {

    constructor(did?: DocumentIdentificationData) {

        if (did) {
            for (let prop in did) {
                if (did.hasOwnProperty(prop)) {
                    this[prop] = did[prop];
                }
            }
            this.identificationRecordIds = [];
            if(Array.isArray(did.identificationRecordIds)) {
                for(let i : number = 0; i < did.identificationRecordIds.length; i++) {
                    this.identificationRecordIds[i] = did.identificationRecordIds[i];
                }
            }
        }
    }


    matterId: number;
    identificationRecordIds: number[] = [];

    templateName : string;
    fileName: string;
    templateId: number;
    documentIds: DocumentIdentificationDataDocId[] = [];
    requestedFileTypes: DocumentTypeType[] = [];
    docProfileFileType : DocumentTypeType; // identification docs need WORD or WORDPERFECT here
}

export class  DocumentIdentificationDataDocId {
    documentId : number;
    fileType : DocumentTypeType;
}
