import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {DPError} from '../shared/error-handling/dp-error';
import {ErrorDirectiveMapping} from '../shared/error-handling/error-directive-mapping';
import {ErrorService} from '../shared/error-handling/error-service';

interface ErrorValidatorData {
    identifier: string;
    containerId: string;
    isSaveError : boolean ;
}

interface ErrorValidatorContext {
    isEmail: boolean;
    atLeastOneEmail: boolean;
    elementFunction: any;
    data: ErrorValidatorData;
}

@Directive({
    selector: '[dp-error-validator]',
    host: {
        '[class.fa]': 'setErrorIcons()',
        '(blur)': 'revalidate($event)'
    }
})
export class ErrorValidator {
    private static emailAddressPattern: RegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    public static areEmailAddressesValid(emails: string, atLeastOneEmail?: boolean): boolean {
        const emailList : string[] = emails.split(';').map(e => e.trim()).filter(e => e !== '');
        return atLeastOneEmail
                ? emailList.some(e => ErrorValidator.emailAddressPattern.test(e))
                : emailList.every(e => ErrorValidator.emailAddressPattern.test(e));
    }

    @Input('dp-error-validator') validatorInput: ErrorValidatorContext;
    @Input() fieldKey: string;
    @Input() fieldCode: string;

    private parentElement: Element;

    constructor(private errorService: ErrorService, private renderer: Renderer2, private elementRef: ElementRef) {
        this.parentElement = elementRef.nativeElement ? this.renderer.parentNode(elementRef.nativeElement) : null;
    }

    /*
     * Add or remove icons on validation fields based on current field and save errors
     */
    setErrorIcons(): void {
        const dpError: DPError = this.findError();
        if (this.parentElement) {
            if (dpError) {
                if (dpError.errorType == 'WARNING') {
                    this.renderer.addClass(this.parentElement, 'fa');
                    this.renderer.addClass(this.parentElement, 'fa-exclamation-triangle');
                    this.renderer.addClass(this.parentElement, 'warning-glyph');
                    this.renderer.removeClass(this.parentElement, 'fa-times-circle');
                    this.renderer.removeClass(this.parentElement, 'error-glyph');
                }
                else {
                    this.renderer.addClass(this.parentElement, 'fa');
                    this.renderer.addClass(this.parentElement, 'fa-times-circle');
                    this.renderer.addClass(this.parentElement, 'error-glyph');
                    this.renderer.removeClass(this.parentElement, 'fa-exclamation-triangle');
                    this.renderer.removeClass(this.parentElement, 'warning-glyph');
                }
            }
            else {
                this.renderer.removeClass(this.parentElement, 'fa');
                this.renderer.removeClass(this.parentElement, 'fa-exclamation-triangle');
                this.renderer.removeClass(this.parentElement, 'warning-glyph');
                this.renderer.removeClass(this.parentElement, 'fa-times-circle');
                this.renderer.removeClass(this.parentElement, 'error-glyph');
            }
        }
    }

    private findError(): DPError {
        if (this.fieldKey) {
            const elementKey: string = this.validatorInput.data ? `${this.fieldKey}_${this.elementRef.nativeElement.id}` : this.fieldKey;
            let dpError = this.errorService.getDPError(elementKey);
            if (!dpError) {
                const key: string = this.validatorInput.data ? `${this.fieldKey}_INVALID_${this.elementRef.nativeElement.id}` : this.fieldKey + '_INVALID';
                dpError = this.errorService.getDPError(key);
            }
            //Try going after the suffixed error if the one bound directly to the key is not found
            return dpError ? dpError : this.errorService.getDPError(elementKey + ".MISSING");
        }
        return null;
    }

    /*
     * Revalidate field if possible
     */
    revalidate(event): void {
        if (this.validatorInput && this.fieldKey) {
            if (this.validatorInput.elementFunction) {
                this.revalidateByElementFunction(event);
            } else if (this.validatorInput.isEmail) {
                this.revalidateEmail(event);
            }
        }
    }

    private revalidateByElementFunction(event: any): void {
        const fieldId: string = this.validatorInput.data ? event.target.id : null;
        const containerId : string = this.validatorInput.data ? this.validatorInput.data.containerId : null;
        if (this.validatorInput.elementFunction(this.validatorInput.data ? this.validatorInput.data.identifier : null)) {
            this.validatorInput.data && this.validatorInput.data.isSaveError ? this.errorService.removeDpSaveError(this.fieldKey, fieldId) :  this.errorService.removeDpFieldError(this.fieldKey, fieldId);
        } else if (this.hasMapping()) {
            this.validatorInput.data && this.validatorInput.data.isSaveError ? this.errorService.addDpSaveError(DPError.createDPError(this.fieldKey, fieldId, containerId , this.fieldCode)) :  this.errorService.addDpFieldError(DPError.createDPError(this.fieldKey, fieldId, containerId , this.fieldCode));
        }
    };

    private revalidateEmail(event): void {
        const fieldId: string = this.validatorInput.data ? event.target.id : null;
        const containerId : string = this.validatorInput.data ? this.validatorInput.data.containerId : null;
        if (!event.target.value || ErrorValidator.areEmailAddressesValid(event.target.value, this.validatorInput.atLeastOneEmail)) {
            this.errorService.removeDpFieldError(this.fieldKey, fieldId);
        } else if (this.hasMapping()) {
            this.errorService.addDpFieldError(DPError.createDPError(this.fieldKey, fieldId, containerId , this.fieldCode));
        }
    }

    private hasMapping(): boolean {
        return ErrorDirectiveMapping[this.fieldKey] || ErrorDirectiveMapping[this.fieldKey + '.INVALID'] || ErrorDirectiveMapping[this.fieldKey + '.MISSING'] || (this.fieldKey && ErrorDirectiveMapping[this.fieldKey.replace(/\.\d*\./g, ".#.")]);
    }
}
