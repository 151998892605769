import {Tab} from '../shared/tabbing/tab';
import {ErrorTab} from '../shared/error-handling/error-tab';
import {Jurisdiction, RegistryOffice} from '../matters/shared/jurisdiction';
import {Section} from '../matters/shared/section';
import {JurisdictionDepartment} from '../admin/jurisdiction-departments/jurisdiction-departments';
import * as _ from 'lodash';
import {Department} from '../matters/shared/department';
import {JurisdictionSectionedComponent} from './jurisdiction-sectioned.component';

export class JurisdictionTab extends Tab {

    constructor(json? : JurisdictionTab) {
        super(json);
        if(json) {
            json.jurisdiction && (this.jurisdiction = new Jurisdiction(json.jurisdiction));
            json.jurisdictionRef && (this.jurisdictionRef = new Jurisdiction(json.jurisdictionRef));
        }
    }

    jurisdictionSectionedComponent : JurisdictionSectionedComponent;

    subRoute : string;
    subRouteId: number;

    errorTab: ErrorTab;

    jurisdictionSection : Section[];

    jurisdiction : Jurisdiction;
    jurisdictionRef : Jurisdiction;
    // The jurisdictionRef is getting refreshed when user traverse through different topics so we are keeping backendCopy on tab instead of component.
    // Gradually we will move code to tab copy.
    backendJurisdictionCopy: Jurisdiction;
    /** this is not jurisdictions' departments but rather the list of available departments (from config) for a jurisdiction; it includes all config departments for all provinces for a brand new jurisdiction*/
    jurisdictionConfigDepartments : JurisdictionDepartment[];
    registryOffices : RegistryOffice[];
    departmentPriority : number;
    isJurisdictionReadOnly: boolean = false;
    isCreatedForModal : boolean = false;

    static createJurisdictionTab(jurisdiction : Jurisdiction, routeBase : string, subRoute? : string, subRouteId? : number) : JurisdictionTab {
        const _tab : any = {
            id                          : jurisdiction && jurisdiction.id || -(new Date()).getTime(),
            jurisdiction                : jurisdiction,
            jurisdictionRef             : _.cloneDeep(jurisdiction),
            backendJurisdictionCopy     : jurisdiction.id ? new Jurisdiction(jurisdiction) : null,
            tabType                     : 'jurisdiction',
            type                        : 'jurisdiction',
            route                       : routeBase,
            subRoute                    : subRoute,
            subRouteId                  : subRouteId,
        };
        let tab : JurisdictionTab = new JurisdictionTab(_tab);
        return tab;
    }

    get title() : string {
        if (this.jurisdiction){
            return this.jurisdiction.jurisdictionName;
        }
    }

    set title(title : string) {
        //Ignore,
    }

    get department() : Department {
        if(this.jurisdiction && this.jurisdiction.departments && this.jurisdiction.departments.length){
            let department = this.jurisdiction.departments.find( department => department.departmentPriority == this.departmentPriority);
            return department ? department : null;
        } else {
            return null;
        }
    }

    get routeParams(): string[] {
        if (this.subRoute && this.subRouteId) {
            return [this.route, this.id.toString(), this.subRoute, this.subRouteId.toString()];
        } else if(this.subRoute) {
            return [this.route, this.id.toString(), this.subRoute];
        }else {
            return [this.route, this.id.toString()];
        }
    }

    isLocked() : boolean {
        // return this.contact.locked;
        return false;
    }

    isDirty(): boolean {
        if (!this.jurisdiction) {
            return false;
        }

        if (!this.jurisdiction.isDirty) {
            this.jurisdiction.isDirty = !this.jurisdiction.equals(this.jurisdictionRef);
        }

        return this.jurisdiction.isDirty;
    }

    deCoupleUIComponent() : void {
        this.jurisdictionSectionedComponent = null;
    }
}
