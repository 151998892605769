import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class StgDocument extends BaseEntity{
    documentId: number;
    description: string;
    dateGenerated: string;
    name: string;
    url: string;
    downloadStatus: string;
}
