import {Injectable} from '@angular/core';
import {HttpClient} from '../../core/httpClient.service';
import {api} from '../../common/api';
import {UserDefinedField} from '../../shared-main/user-defined-field/user-defined-field';
import {Observable} from 'rxjs';
import {Utils} from '../../matters/shared';
import {ProvinceCode} from '../accounts/shared/account-province';

/**
 *  Additional Details service defines all api calls for Additional Details component
 */
@Injectable()
export class UserDefinedFieldsService {

    constructor(private http : HttpClient) { }

    getUserDefinedFields(accountId: string, matterType: string, topicName: string) : Observable<UserDefinedField[]> {
        let url: string = `${api}/accounts/${accountId}/userDefinedFields?filter=matterType_EQ_${matterType},topicName_EQ_${Utils.escapeSearchText(topicName)}`;
        //console.log("getUserDefinedFields, url=", url);
        return this.http.get(url)
            .map((res) => {
                let data = res['UserDefinedFields'];
                if(Array.isArray(data)) {
                    return data.map(item => {
                        return new UserDefinedField(item);
                    });
                }else {
                    return [];
                }
            });
    }

    getAllUserDefinedFields(accountId: string, provinceCode?: ProvinceCode) : Observable<UserDefinedField[]> {
        let url: string = `${api}/accounts/${accountId}/userDefinedFields`;

        if(provinceCode) {
            url += '?filter=provinceCode_EQ_'+provinceCode;
        }
        //console.log("getAllUserDefinedFields, url=", url);
        return this.http.get(url)
            .map((res) => {
                let data = res['UserDefinedFields'];
                if(Array.isArray(data)) {
                    return data.map(item => {
                        return new UserDefinedField(item);
                    });
                }else {
                    return [];
                }
            });
    }

    saveUserDefinedFields(accountId: string, values : any) {
        let url: string = `${api}/accounts/${accountId}/userDefinedFields?removeOld=false`;
        console.log("saveUserDefinedFields, values=", values);
        return this.http.post(url, values)
            .map((res) => {
                return res;
            })
    }
}
