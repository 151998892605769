//This is a constant class with all the custom pick list keys
export const customPickListKey = {

    FeeQuotes: 'FEEQUOTES',

    SpecialComments: 'SPECIALCOMMENTS',

    ReferredBy: 'REFERREDBY',

    IdentificationDocumentType: 'IDENTIFICATION_DOCUMENT_TYPE',

    MortgageAdvanceList: 'MORTGAGE_ADVANCE_LIST',

    StatusCertificatePosition: 'POSITION',

    OtherCategoryName: 'OTHER_CATEGORY_NAME',

    PlaintiffList: 'PLAINTIFF_LIST'
};
