import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class VendorCommission extends BaseEntity {
    // TODO: Confirm server side if it needs id
    // id : number;

    commissionPayableToVendorBroker : number;
    vendorBrokerCommission : number;
    vendorBrokerPercentageOfCommission : number;
    vendorHstOnCommission : number;
    vendorTotalCommissionInclHst : number;

    constructor(vendorCommission? : VendorCommission) {
        super(vendorCommission);
    }
}
