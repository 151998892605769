import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {DpBooleanValue} from '../../shared/dp-boolean';
import {Deposit} from '../../shared/deposit';


export class SoAdjForm4Charge extends BaseEntity {

    id : number;
    textReferencingAgreement : string;
    numberOfForm4Issued : number;
    overrideCalculatedValue : boolean;
    costPerForm4 : number;
    adjustedFor : string; // HST or GST
    adjustedForType : DpBooleanValue; // YES or NO
    percentageAdjustedFor : number;
    adjustForPst : DpBooleanValue;  // YES or NO
    percentageAdjustedForPst : number;

    get calculateForm4SubTotal(): number {
        if(this.costPerForm4 && this.numberOfForm4Issued) {
            return Number(this.costPerForm4) * Number(this.numberOfForm4Issued);
        } else {
            return 0;
        }
    }

    get calculateTaxPercent() : number{
        let taxRate: number = 0;
        if(this.adjustedFor=='HST') {
            if(this.adjustedForType == 'YES') {
                taxRate = taxRate + Number(this.percentageAdjustedFor) + Number(this.percentageAdjustedForPst);
            }
        } else {
            if(this.adjustedForType=='YES'){
                taxRate = taxRate + Number(this.percentageAdjustedFor);
            }
            if(this.adjustForPst=='YES'){
                taxRate = taxRate + Number(this.percentageAdjustedForPst);
            }
        }
        return taxRate;
    }

    get calculateTaxAmount() : number {
        return Number(this.calculateForm4SubTotal) * (Number(this.calculateTaxPercent)/100);
    }

    get calculateForm4Total(): number {
        // credit vendor amount
        return Number(this.calculateTaxAmount) + Number(this.calculateForm4SubTotal);
    }

    get taxAmountLabel(): string {
        if(this.adjustedFor=='HST') {
            return this.adjustedForType == 'YES' ? 'HST calculated at ' + this.percentageAdjustedFor  + '%': '';
        } else {
            if(this.adjustedForType=='YES' && this.adjustForPst=='YES'){
                return 'PST and GST';
            } else if(this.adjustedForType=='YES' && this.adjustForPst=='NO'){
                return 'GST calculated at ' + this.calculateTaxPercent + '%';
            } else if(this.adjustedForType=='NO' && this.adjustForPst=='YES'){
                return 'PST calculated at ' + this.calculateTaxPercent + '%';
            } else {
                return '';
            }
        }
    }

    get adjustedForTypeLabel(): string{
        if(this.adjustedFor=='HST') {
            return 'HST';
        } else {
            return 'GST';
        }
    }

    getNumberOfDepositsWithForm4MarkedAsSent(deposits:Deposit[]) : number {
        let depositsWithForm4Sent : Deposit[] =deposits.filter(item => item.form4Sent);
        return depositsWithForm4Sent.length;
    }


}
