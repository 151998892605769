import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {InterestRate} from './interest-rate';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class InterestRateList extends  BaseEntity{

    interestRates: InterestRate[];

    provinceCode: ProvinceCode;
}
