import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TitlePlusValidationResponse} from './title-plus-validation-response';

export class TitlePlusCreateDealResponse extends BaseEntity{

    IssuedOn:string;
    Status :string;
    StatusDescription:string;
    HasValidationErrors:boolean;
    version:number;
    orderId:string;
    orderStatus:string;
    dealNumber:string;
    validationResult : TitlePlusValidationResponse;


}
