import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventTypeDisplay'
})
export class EventTypeDisplayPipe implements PipeTransform {
  constructor() {}

  transform(value: any) {
    let text = String(value);
    text = text.toUpperCase();
    if(text == "WORKITEMTASK")
    {
        text = "TASK"
    }
    return text;
  }
}
