export class NetAdvance {
    constructor(netadvanceitem?: NetAdvance) {

        if (netadvanceitem) {
            for (let prop in netadvanceitem) {
                if (netadvanceitem.hasOwnProperty(prop)) {
                    this[prop] = netadvanceitem[prop];
                }
            }
        }
    }
    name: string = '';
    amount: number = 0;
}
