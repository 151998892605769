import {Component, NgZone, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {DialogService} from '../../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import {StatementAdjustmentConfig} from './statement-adjustment-config';
import * as _ from 'lodash';
import {Account} from '../accounts/shared/account';
import {dropDowns} from '../shared/admin-drop-down';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {AccountService} from '../accounts/account.service';
import {StatementConfigService} from '../shared/statement-config.service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {CkEditorUtil} from '../../matters/shared/ckeditor-util';
import {ProvinceCode} from '../accounts/shared/account-province';
import {MortgagePrecedentComponent} from '../../matters/mortgages/mortgage/precedent/mortgage-precedent.component';
import {PrecedentTypes} from '../../matters/shared/mortgage-precedent-type';
import {soAdjProjectFooterDropdown, soAdjProjectFooters} from '../../matters/statement-adjustment/model/so-adj-drop-downs';
import {TabsService} from '../../core';
import {ProjectTab} from '../../projects/shared/project-tab';
import {projectConsts, StatementAdjParameter} from '../../projects/shared/project-consts';
import {getCalculationMethodOptions} from '../../matters/shared/matter-drop-downs';
import Utils from '../../shared-main/utils';

declare var CKEDITOR : any;

class StatementAdjustmentConfigModalContext extends BSModalContext {
    account : Account;
    matterStatementAdjustmentConfigList : StatementAdjustmentConfig[];
    projectStatementAdjustmentConfigList : StatementAdjustmentConfig[];
    matterNo : string;
    projectRecordNo: string;
    isModalOpenFromMatter: boolean;
    provinceCode: ProvinceCode;
    isProjectSale : boolean;
    isProject : boolean;
    isCondoProjectSale : boolean;
}


@Component({
               selector    : 'dp-statement-adjustment-config',
               templateUrl : 'statement-adjustment-config.component.html',

           })


export class StatementAdjustmentConfigComponent implements   ModalComponent<StatementAdjustmentConfigModalContext> {

    context : StatementAdjustmentConfigModalContext;
    conditionList : SelectItem[];
    availabilityList : any[] = [];
    editor : any;
    clickListener : Function;
    account : Account;
    isEmptyDescription : boolean = true;
    statementAdjustmentConfigList : StatementAdjustmentConfig[] = [];
    mergeCodeOptions : any[];


    constructor(public dialog : DialogRef<StatementAdjustmentConfigModalContext>,
                public accountService : AccountService,
                public soaConfigService : StatementConfigService,
                public router : Router,
                public dialogService : DialogService,
                public renderer : Renderer2,
                public ngZone : NgZone,
                public tabService: TabsService) {
        this.context = dialog.context;

        this.clickListener = renderer.listen('document', 'click', () => {
            this.closeAllEditor();
        });
    }
    addToDataPropagationCommands(cmdType: string, cmd?: any, onlyOneNeededForSameCmdType: boolean = false, parameter? : any) {
        if (this.context.isProject) {
            let activeProjectTab = this.tabService && this.tabService.activeTab as ProjectTab;
            // We are only adding the command type for now because we are not able to add the ProjectDataPropagationService to the component
            //TODO try to add the ProjectDataPropagationService service to the component
            activeProjectTab.addToDataPropagationCommands(cmdType,cmd,onlyOneNeededForSameCmdType,parameter);
        }
    }

    get provinceCode(): ProvinceCode {
        return this.context.provinceCode;
    }

    matterRecordNo() : string {
        return this.context.matterNo ? this.context.matterNo : '';
    }

    projectRecordNo() : string {
        return this.context.projectRecordNo ? this.context.projectRecordNo : '';
    }

    get selectedIndex() : number {
        return _.findIndex(this.statementAdjustmentConfigList, statementAdjustmentConfig => statementAdjustmentConfig.isSelected);
    }

    addStatementAdjustmentConfig(event) : void {
        this.isEmptyDescription = true;
        let rowIndex = this.statementAdjustmentConfigList.findIndex(statementAdjustmentConfig => statementAdjustmentConfig.isSelected);
        this.statementAdjustmentConfigList.splice(Number(rowIndex)+1, 0, this.createStatementAdjustmentConfig(event));
    }

    createStatementAdjustmentConfig(event) : StatementAdjustmentConfig {
        this.closeAllEditor();
        let statementAdjustmentConfig = new StatementAdjustmentConfig();
        statementAdjustmentConfig.description = ' ';
        statementAdjustmentConfig.instanceType = this.isModalOpenFromMatter() ? "matterSoaFooter" : "accountSoaFooter";
        statementAdjustmentConfig.condition = dropDowns.conditionType[0].value;
        statementAdjustmentConfig.showEditor = true;
        statementAdjustmentConfig.applyByDefault = false;
        statementAdjustmentConfig.provinceCode = this.provinceCode;
        if (this.isProjectOrProjectSale()){
            statementAdjustmentConfig.footerProgressionStatusAvailability = soAdjProjectFooters.applyToBoth.value;
        }
        if (this.context.isProject){
            statementAdjustmentConfig.applyByDefault = true;
        }
        setTimeout(() => {
            this.setUpEditor(statementAdjustmentConfig);
        }, 0);
        this.rowSelected(statementAdjustmentConfig);
        event.stopPropagation();
        return statementAdjustmentConfig;
    }

    deleteStatementAdjustmentConfig(selectedStatementAdjustmentConfig : StatementAdjustmentConfig) : void {
        this.dialogService.confirm('Confirmation', 'Are you sure you would like to delete this footer?', false, 'Delete').subscribe(res => {
            if(res) {
                let selectedStatementAdjustmentConfigFound = this.statementAdjustmentConfigList.findIndex(statementAdjustmentConfig => statementAdjustmentConfig == selectedStatementAdjustmentConfig);
                if(selectedStatementAdjustmentConfigFound > -1) {
                    let statementAdjParameter = new StatementAdjParameter();
                    statementAdjParameter.adjustmentId = selectedStatementAdjustmentConfig.id;
                    this.statementAdjustmentConfigList.splice(selectedStatementAdjustmentConfigFound, 1);
                    if (this.context.isProject) {
                        this.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.statementAdjustmentFooterDelete, undefined, true, statementAdjParameter);
                    }
                }

            }
        });


    }


    setUpEditor(key : StatementAdjustmentConfig) : void {
        if(!this.editor) {
            this.editor = CKEDITOR.replace(key.identifier.toString(), {
                on     : {
                    instanceReady : function(ev) {
                        this.focus();
                    }
                },
                height : '100px'
            });

            CkEditorUtil.setUpEditor(this.editor);
            this.editor.setData(key.description);
            this.setButtonStatus(key);
            this.editor.on('change', () => {
                this.ngZone.run(() => {
                    key.description = this.editor.getData().trim();
                    this.setButtonStatus(key);
                });
            });
        }
    }


    ngOnInit() {
        this.mergeCodeOptions= getCalculationMethodOptions.slice(0);
        this.mergeCodeOptions.splice(0,1);
        this.mergeCodeOptions.unshift({label: 'Merge Code', value: 'MERGE_CODE'})
        this.conditionList = dropDowns.conditionType;
        if(this.context.account) {
            this.account = new Account(this.context.account);
            this.initAdjustmentConfig();
        } else {
            let loggedUserId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
            this.accountService.getShallowAccount(loggedUserId).subscribe((account : Account) => {
                this.account = new Account(account);
                this.initAdjustmentConfig();

            });

        }

        this.context.isProjectSale = this.context.isProjectSale ? this.context.isProjectSale : false;
        this.context.isProject = this.context.isProject ? this.context.isProject : false;
        if (this.context.isProjectSale || this.context.isProject) {
            for (let i: number = 0; i < soAdjProjectFooterDropdown.length; i++) {
                if (i == 0 || i == 3 || ((i == 1 || i == 2) && this.context.isCondoProjectSale)) {
                    this.availabilityList.push( {label : soAdjProjectFooterDropdown[i].label, value : soAdjProjectFooterDropdown[i].value });
                }
            }

            if (!this.context.isCondoProjectSale) {
                this.availabilityList[0].label = 'No';
                this.availabilityList[1].label = 'Yes';
            }
        }

     /*   this.soaConfigService
                    .getSystemStatementAdjustmentConfig(this.provinceCode)
                    .subscribe((statementAdjustmentConfigList : StatementAdjustmentConfig[]) => {
                        this.statementAdjustmentConfigList = statementAdjustmentConfigList;
                        this.statementAdjustmentConfigList.forEach(item => item.id = null);
                    });*/
    }

    isModalOpenFromMatter() : boolean {
        return (this.context.isModalOpenFromMatter && this.context.isModalOpenFromMatter === true)
    }

    initAdjustmentConfig() : void {
        if(this.isModalOpenFromMatter())
        {
            this.statementAdjustmentConfigList = this.context.matterStatementAdjustmentConfigList;
            if(this.statementAdjustmentConfigList.length > 0)
            {
                this.statementAdjustmentConfigList.map(function(item) {
                                                           item.isSelected = false;
                                                       }
                );
                this.statementAdjustmentConfigList[0].isSelected = true;
            }
        }
        else{
            let id = this.account ? this.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
            this.soaConfigService
                .getStatementAdjustmentConfig(id, this.provinceCode)
                .subscribe((statementAdjustmentConfigList : StatementAdjustmentConfig[]) => {
                    this.statementAdjustmentConfigList = statementAdjustmentConfigList;
                    if(this.statementAdjustmentConfigList.length > 0)
                    {
                        this.statementAdjustmentConfigList[0].isSelected = true;
                    }
                });

        }


    }

    openEditor(key : StatementAdjustmentConfig) : void {
        if (this.context.isProjectSale && key.projectFooterId>0){
            this.rowSelected(key);
            return;
        }
        this.rowSelected(key);
        this.closeAllEditor();
        key.showEditor = true;
        setTimeout(() => {
            this.setUpEditor(key);
        }, 0);
    }

    closeAllEditor() : void {
        this.copyEditorValue();
        this.statementAdjustmentConfigList.map(function(config) {
            config.showEditor = false;
        });
        if(this.editor) {
            this.editor.destroy();
            this.editor = undefined;
        }
    }

    copyEditorValue() : void {
        let statementAdjustmentConfigFound = this.statementAdjustmentConfigList.find(item => item.showEditor);
        if(this.editor && statementAdjustmentConfigFound) {
            statementAdjustmentConfigFound.description = this.editor.getData();
        }
    }

    stopPropogationOnEditorClick(event , key : StatementAdjustmentConfig) : void {
        if(!key.showEditor)
        {
            this.openEditor(key);
        }
        event.stopPropagation();
    }

    rowSelected(key : StatementAdjustmentConfig) : void {
        this.statementAdjustmentConfigList.map(function(config) {
            config.isSelected = false;
        });
        key.isSelected = true;
    }

    scrollIntoView(index: number) : void {
        let elementId = `row_${index}`;
        Utils.scrollIntoView(elementId, 'sticky-table-soa',null, 'table-wrapper');
    }

    moveUp() : void {
        let selectedStatementAdjustmentConfigIndex : number = _.findIndex(this.statementAdjustmentConfigList, statementAdjustmentConfig => statementAdjustmentConfig.isSelected);
        if(selectedStatementAdjustmentConfigIndex > 0) {
            let upStatementAdjustmentConfig : StatementAdjustmentConfig = this.statementAdjustmentConfigList[selectedStatementAdjustmentConfigIndex - 1];
            this.statementAdjustmentConfigList[selectedStatementAdjustmentConfigIndex - 1] = this.statementAdjustmentConfigList[selectedStatementAdjustmentConfigIndex];
            this.statementAdjustmentConfigList[selectedStatementAdjustmentConfigIndex] = upStatementAdjustmentConfig;
            this.scrollIntoView(selectedStatementAdjustmentConfigIndex-1);
        }
    }

    moveDown() : void {
        let selectedStatementAdjustmentConfigIndex : number = _.findIndex(this.statementAdjustmentConfigList, statementAdjustmentConfig => statementAdjustmentConfig.isSelected);
        if(selectedStatementAdjustmentConfigIndex > -1 && selectedStatementAdjustmentConfigIndex != this.statementAdjustmentConfigList.length - 1) {
            let downStatementAdjustmentConfig : StatementAdjustmentConfig = this.statementAdjustmentConfigList[selectedStatementAdjustmentConfigIndex + 1];
            this.statementAdjustmentConfigList[selectedStatementAdjustmentConfigIndex + 1] = this.statementAdjustmentConfigList[selectedStatementAdjustmentConfigIndex];
            this.statementAdjustmentConfigList[selectedStatementAdjustmentConfigIndex] = downStatementAdjustmentConfig;
            this.scrollIntoView(selectedStatementAdjustmentConfigIndex+1);
        }
    }

    reinstateProjectLevelFooters(){
        if (this. context.projectStatementAdjustmentConfigList && this.context.projectStatementAdjustmentConfigList.length>0){
            for (let i : number = 0; i < this.context.projectStatementAdjustmentConfigList.length; i++){
                if (this.context.projectStatementAdjustmentConfigList[i].applyByDefault) {
                    let localFooter : StatementAdjustmentConfig = new StatementAdjustmentConfig(this.context.projectStatementAdjustmentConfigList[i]);
                    localFooter.id = undefined;
                    localFooter.projectFooterId = this.context.projectStatementAdjustmentConfigList[i].id;
                    this.statementAdjustmentConfigList.push(localFooter);
                }
            }
        }

    }

    reset() : void {
        let dialogMessage = this.isModalOpenFromMatter() ? 'Do you wish to reset the footers to default ones as defined "Go To/Configuration/Statement of' +
                                                         ' Adjustments"? <br> If you proceed then any changes you have made will be discarded' : 'Do you wish to reset the footers to default ones as defined by Unity' +
                                                         ' Software? <br> If you proceed then any changes you have made will be discarded';
        this.dialogService.confirm('Confirmation', dialogMessage , false, 'Reset').subscribe(res => {
            if(res) {
                if(this.isModalOpenFromMatter()){
                    if (this.isProjectOrProjectSale()){
                        this.statementAdjustmentConfigList.splice(0);
                    };
                    if (!this.context.isProjectSale) {
                        let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
                        this.soaConfigService
                            .getStatementAdjustmentConfig(id, this.provinceCode)
                            .subscribe((statementAdjustmentConfigList: StatementAdjustmentConfig[]) => {
                                this.statementAdjustmentConfigList = statementAdjustmentConfigList;
                                this.statementAdjustmentConfigList.map(function (item) {
                                    item.id = null;
                                    item.footerProgressionStatusAvailability = soAdjProjectFooters.applyToBoth.value;
                                });
                            });
                    }
                    if(this.context.isProject){
                        this.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.statementAdjustmentFooterReset, undefined, true);
                    }else if(this.context.isProjectSale){
                        this.reinstateProjectLevelFooters();
                    }
                }else{
                    this.soaConfigService
                        .getSystemStatementAdjustmentConfig(this.provinceCode)
                        .subscribe((statementAdjustmentConfigList : StatementAdjustmentConfig[]) => {
                            this.statementAdjustmentConfigList = statementAdjustmentConfigList;
                            this.statementAdjustmentConfigList.map(function(item) {
                                item.id = null;
                            });
                        });
                }

            }
        });
    }


    ok() : void {
        this.closeAllEditor();
        if(this.isModalOpenFromMatter())
        {
            this.dialog.close(this.statementAdjustmentConfigList);
        }
        else{
            let id = this.account ? this.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
            this.soaConfigService
                .updateStatementAdjustmentConfig(this.statementAdjustmentConfigList, id, this.provinceCode)
                .subscribe(
                    (res) => {
                        this.dialog.close();
                    },
                    () => {}
                );
        }


    }

    close() : void {
        this.dialog.close();
    }

    insertPrecedent(type : string, key : StatementAdjustmentConfig) {
        if(this.context.isProject) {
            this.precedentCoreAction('INSERT', type, key);
        }
    }

    saveAsPrecedent(type : string, key : StatementAdjustmentConfig) {
        if(this.context.isProject) {
            this.precedentCoreAction('SAVE', type, key);
        }
    }

    precedentCoreAction(precedentAction : string, type : string, key : StatementAdjustmentConfig) {

        this.dialogService.content({
            content       : MortgagePrecedentComponent,
            context       : {
                additionalClause : this.editor.getData(),
                action           : precedentAction,
                caller           : PrecedentTypes.STATEMENT_ADJUSTMENT_FOOTER
            },
            onFulfillment : (result) => {
                if(result) {
                    if(result.action === 'ok') {//if  modal is closed after save
                        if(precedentAction === 'INSERT') {
                            key.description = key.description ? key.description + result.additionalClause: result.additionalClause;
                            if (this.editor){
                                this.editor.setData(key.description)
                            } else {
                                this.openEditor(key);
                            }
                        } else {
                        //    For now, do nothing
                        }
                    }
                }
            }
        });

    }

    setButtonStatus(selectedStatementAdjustmentConfig : StatementAdjustmentConfig){
        this.isEmptyDescription = selectedStatementAdjustmentConfig.description.trim() === "";
    }

    isReadOnlyFooter(selectedStatementAdjustmentConfig : StatementAdjustmentConfig) : boolean{
        return this.context.isProjectSale && selectedStatementAdjustmentConfig.projectFooterId>0;
    }

    isProjectOrProjectSale(): boolean{
        return this.context.isProject || this.context.isProjectSale;
    }

    onMergeCodeChange(event: any ,  key : StatementAdjustmentConfig) : void {
        if (this.editor && event.target && event.target.value != 'MERGE_CODE'){
            this.editor.insertText('<<'+event.target.value+'>>');
            event.target.value = 'MERGE_CODE';
        }
    }
}
