import {Directive, EventEmitter, Output} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
               selector: '[ngModel][dp-consideration-F8]',
               host: {
                   '(keyup.f8)': 'onInputChange($event)'
               }
           })
export class ConsiderationF8Directive {

    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

    constructor(
        private model: NgModel) {
    }


    onInputChange($event): void{

        if($event.target.value && $event.target.value == 'BLANK')
        {
            this.model.viewToModelUpdate('0');
            this.ngModelChange.emit('0');
            this.model.valueAccessor.writeValue('$0.00');
        }
        else{
            this.model.viewToModelUpdate(null);
            this.ngModelChange.emit(null);
            this.model.valueAccessor.writeValue('BLANK');
        }
    }
}
