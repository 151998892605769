import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {TarionWarrantyEnrolmentPeriod} from '../model/tarion-warranty-enrolment-period';
import {SoAdjHCRAFee} from '../model/so-adj-hcra-fee';
import {SalePriceAdjustment} from '../sale-price-adjustment/sale-price-adjustment';

export class StatementAdjustmentDisplayHcraFeeBuilder {

    static getDisplayItems(soaAdj: SoAdjHCRAFee, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        if (soaAdj.builderRegistrationNumber && soaAdj.builderRegistrationNumber.length > 0){

            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Registration number of Vendor: ${soaAdj.builderRegistrationNumber}`,
                ''
            ));
        }

        if (soaAdj.enrolmentNumber && soaAdj.enrolmentNumber.length > 0){

            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Enrolment number of subject property: ${soaAdj.enrolmentNumber}`,
                ''
            ));
        }

        if (soaAdj.applicableSection && soaAdj.applicableSection.length > 0){

            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Pursuant to section ${soaAdj.applicableSection} of Agreement`,
                ''
            ));
        }
        let enrolmentFee: number = StatementAdjustmentDisplayHcraFeeBuilder.calculatedEnrolmentFee(soaAdj, mainBuilder );
        let enrolmentFeePlusTax: number = StatementAdjustmentDisplayHcraFeeBuilder.calculatedEnrolmentFeePlusTax(soaAdj, mainBuilder);

        sadItemDetails.push(mainBuilder.getNewItemLine(
             `Enrolment fee`  ,
            `${mainBuilder.getCurrency(enrolmentFee)}`
        ));

        if (toBeUsedOnModal) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${soaAdj.taxType}`,
                `${mainBuilder.getCurrency(enrolmentFeePlusTax - enrolmentFee)}`
            ));
        }
        else{
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${soaAdj.taxType} calculated at ${mainBuilder.getPercentage(soaAdj.appliedTax)}`,
                `${mainBuilder.getCurrency(enrolmentFeePlusTax - enrolmentFee)}`, undefined, `HCRA.HST`
            ));

        }

        if (!toBeUsedOnModal) { // modal will handle credit line differently
            if (soaAdj.creditType == 'NO_ADJUSTMENT') {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `No Adjustment`,
                    ``
                ));
            } else {
                let creditLabel = soaAdj.creditType == 'VENDOR' ? mainBuilder.vendorLabel : mainBuilder.purchaserLabel;
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `Credit ${creditLabel}`,
                    ``, undefined, `HCRA.Credit.${creditLabel}`
                ));
            }
        }

        return sadItemDetails;
    }

    static calculateAdjustmentAmount(salePriceAdj: SalePriceAdjustment,soaAdj: SoAdjHCRAFee, mainBuilder: StatementAdjustmentDisplayBuilder): number {
        if (salePriceAdj.isSalePriceFormatINCLUSIVE()){ // “Format of Sale Price adjustment” field is IN (‘Credit Vendor = HST-inclusive price in Agreement’ or ‘Sale Price in Agreement’)
            return StatementAdjustmentDisplayHcraFeeBuilder.calculatedEnrolmentFeePlusTax(soaAdj, mainBuilder);
        }
        if(soaAdj.amountAdditionalConsiderationToVendor == 'NO'){
            return StatementAdjustmentDisplayHcraFeeBuilder.calculatedEnrolmentFeePlusTax(soaAdj, mainBuilder);
        }
        if(soaAdj.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'){
            return StatementAdjustmentDisplayHcraFeeBuilder.calculatedEnrolmentFeeTax(soaAdj, mainBuilder);
        }
        return 0;
    }

    static calculatedEnrolmentFeeTax(soaAdj: SoAdjHCRAFee, mainBuilder: StatementAdjustmentDisplayBuilder ): number {

        let enrolmentFee: number = StatementAdjustmentDisplayHcraFeeBuilder.calculatedEnrolmentFee(soaAdj, mainBuilder);

        if (soaAdj.taxType == 'GST'){
            return  Number(enrolmentFee * soaAdj.gstRate / 100);
        }
        return Number(enrolmentFee * soaAdj.hstRate / 100);
    }

    static calculatedEnrolmentFeePlusTax(soaAdj: SoAdjHCRAFee, mainBuilder: StatementAdjustmentDisplayBuilder): number {

        let enrolmentFee: number = StatementAdjustmentDisplayHcraFeeBuilder.calculatedEnrolmentFee(soaAdj, mainBuilder );

        if (soaAdj.taxType == 'GST'){
            return Number(enrolmentFee) + Number(enrolmentFee * soaAdj.gstRate / 100);
        }
        return Number(enrolmentFee) + Number(enrolmentFee * soaAdj.hstRate / 100);
    }

    static calculatedEnrolmentFee(soaAdj: SoAdjHCRAFee, mainBuilder: StatementAdjustmentDisplayBuilder): number {
        let selectedPeriod: TarionWarrantyEnrolmentPeriod = mainBuilder.hcraFeeEnrolmentPeriods.find( twep => twep.id == soaAdj.enrolmentPeriodId);
        if (selectedPeriod && selectedPeriod.rates && selectedPeriod.rates.length){
           return selectedPeriod.rates[0].fee
        }
        return 0;
    }

}
