import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatterParticipant} from '../../shared/matter-participant';
import {PurchaserCapacity} from './purchaser-capacity';
import {
    purchaserCapacityDropdown,
    purchaserCapacityDropdownOptions
} from "../../../shared-main/province-based-dropdowns";
import {PROVINCE_CODES} from "../../shared/user-province";

@Component({
    selector   : 'dp-capacityShare',
    templateUrl: 'capacity-share.component.html'
})

export class CapacityShareComponent implements OnInit {
    @Input() purchaserId: number;
    @Input() matterParticipant: MatterParticipant;
    @Input() readOnly: boolean;
    @Input() provinceCode?: string;
    @Input() isPurchaserSharePercentage: boolean;
    @Output() updateCapacity = new EventEmitter();


    ngOnInit(){
        if(this.provinceCode && this.provinceCode === PROVINCE_CODES.BRITISH_COLOMBIA && !this.matterParticipant.purchaserCapacity){
            this.matterParticipant.purchaserCapacity = 'UNSPECIFIED_CAPACITY';
        }
    }

    get purchaserCapacityOptions(): any[] {
        if (this.provinceCode){
            return purchaserCapacityDropdown[this.provinceCode];
        } else {
            return purchaserCapacityDropdownOptions.purchaserCapacityDropdownOptionsDefault
        }
    }

    onChange(): void{
        this.updateCapacity.emit();
    }

    dynamicF9ShareTenure = () => {
        if(this.provinceCode && this.provinceCode == 'AB'){
            return 'F9 = as to an undivided % interest';
        } else {
            return '';
        }
    };

    updateShare() : void {
        if(this.provinceCode && (this.provinceCode == 'AB' || this.provinceCode == 'MB' || this.provinceCode == 'SK')&& !this.readOnly ){
            this.matterParticipant.purchaserShare = 'as to an undivided % interest';
        }
    }

    get fractionalInterestNumerator() : string{
        if (this.matterParticipant.purchaserShare){
            let fractionalInterest = this.matterParticipant.purchaserShare.split("/");
            return fractionalInterest[0] ? fractionalInterest[0] : "";
        }
        return "";
    }

    get fractionalInterestDenominator() : string{
        if (this.matterParticipant.purchaserShare){
            let fractionalInterest = this.matterParticipant.purchaserShare.split("/");
            return fractionalInterest[1] ? fractionalInterest[1] : "";
        }
        return "";
    }

    set fractionalInterestNumerator(numerator : string) {
        if (this.provinceCode && this.provinceCode == 'BC') {
            this.updatePurchaserShareWithFractionalInterest(numerator + "/" + this.fractionalInterestDenominator);
        }
    }

    set fractionalInterestDenominator(denominator : string) {
        if (this.provinceCode && this.provinceCode == 'BC') {
            this.updatePurchaserShareWithFractionalInterest(this.fractionalInterestNumerator + "/" + denominator);
        }
    }

    updatePurchaserShareWithFractionalInterest(fractionalInterest : string){
        if (fractionalInterest != "/"){
            this.matterParticipant.purchaserShare = fractionalInterest
        } else {
            this.matterParticipant.purchaserShare = "";
        }
    }

    saveUserEntryShare() : void {
        this.matterParticipant.previousPurchaserShareValue = this.matterParticipant.purchaserShare;
        this.onChange();
    }

}
