import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ProgressionStatus} from '../statement-adjustment/statement-adjustment';

export const taxRateTypeValues = {
    soAccount : 'SOACCOUNT',
    matter: 'MATTER',
    soAdjustment : 'SOADJUSTMENT',
    supplementalTask : 'SUPPLEMENTAL_TASK'
};

export class MatterTaxRate extends BaseEntity {
    hstRate : number;
	provincialHstRate: number;
	federalHstRate: number;
	progressionStatus: string;
	taxRateType: string;
    matterSupplementalTaskCategoryId: number;
    matterSoaSheetId : number;


	isSoAccountTaxRate() : boolean {
	    return this.taxRateType == taxRateTypeValues.soAccount;
    }

    isSoAdjustmentTaxRate() : boolean {
        return this.taxRateType == taxRateTypeValues.soAdjustment;
    }

    isSupplementalTaskTaxRate() : boolean {
        return this.taxRateType == taxRateTypeValues.supplementalTask;
    }

    isInterim() : boolean {
	    return this.progressionStatus == ProgressionStatus.INTERIM;
    }

    isFinal() : boolean {
        return this.progressionStatus == ProgressionStatus.FINAL;
    }

}
