import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {DocumentProductionService} from './document-production.service';
import {Logger} from '@nsalaun/ng-logger';
import {ActivatedRoute} from '@angular/router';
import {Contact, ContactService, GetGlobalSaveModelService, matterApi, MatterUtil, Utils} from '../shared';
import {HttpClient} from '../../core/httpClient.service';
import {TabsService} from '../../core/tabs.service';
import {currentMatter} from '../shared/current-matter';
import {Matter} from '../shared/matter';
import {DialogService} from '../../shared/dialog/dialog.service';
import {DocumentTemplate} from './document-template';
import {AppConfig} from '../../shared-main/app-configuration';
import {WindowRef} from '../../shared/window.ref';
import {DocumentProductionTemplateComponent} from './document-production-template.component';
import {Document, DocumentTypeType, GeneratedDocumentTypeType, MatterDocumentMetadata} from './document';
import {TeranetService} from '../../shared-main/teranet/teranet-service';

import {DpCopyAndRenameModalComponent} from './dp-copy-rename-modal.component';

import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {DocumentUtilityService} from './document-utility.service';
import {AuthorizationService} from '../../shared-main/authorization/authorization-service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Subscription} from 'rxjs';
import {DpDefaultSorter} from '../../shared-main/dp-default-sorter.component';
import {ExistingFile, FileUploadModal, FileUploadModalContext} from './upload/file-upload-modal.component';
import {CustomKeyCodesEnum, messages, SESSION_ID_REQ_PARAM} from '../../common';
import {LockScreenService} from '../../core/lock-screen.service';
import {Project} from '../../projects/shared/project';
import {MatterTab} from '../matter-tab';
import {SharedDocumentsPackage} from './shared-documents-package';
import {ApplicationError} from '../../core';
import {SharedDocument} from '../../share-documents/shared-document';
import {ShareDocsUsersModalComponent} from './share-docs/share-docs-users-modal.component';
import {ShareDocsUserModalResult} from './share-docs/share-docs-user-modal-result';
import {SharedDocumentsSummaryItem} from './shared-documents-summary-item';
import {userAccountProfilesApi} from '../../admin/shared/user-account-profiles-api';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {MatterService} from '../matter.service';
import {EmailFieldService, EmailKeys, EmailTypes} from '../../shared-main/email-field/email-field-service';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {RevokeDocumentsModalComponent} from '../matter-list/modals/revoke-documents-modal.component';
import {ProjectTab} from '../../projects/shared/project-tab';
import * as _ from 'lodash';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {EventService} from '../../event/event.service';
import {SigningEnvelope} from '../../../app/event/signing-envelope';
import {MatterPollingService} from '../../../app/core/matter-polling.service';
import {DpDirtyCheckService} from '../../../app/shared-main/dp-dirty-check.service';
import {ITreeOptions, KEYS, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode} from '@circlon/angular-tree-component';
import {Subject} from 'rxjs/Rx';
import {distinctUntilChanged} from 'rxjs/operators';
import {debounceTime} from 'rxjs/internal/operators';
import {DigitalSignaturePlatformLabel, DOCUMENT_UD_DIR_MARKER_FILE_NAME} from '../../../app/shared-main/constants';
import {UserConfigurationService} from '../../shared-main/user-configuration.service';
import {GlobalLogger} from '../../core/global-logger';
import {CirfHelperService} from '../shared/cirf/cirf-helper.service';
import { CustomEventModalComponent } from '../../../app/event/custom-event/custom-event.modal.component';

declare var jQuery: any;


export class DocumentWrapper {
    documentId: number;
    generatedDocumentType: GeneratedDocumentTypeType;
}

const DocumentTypes = [
    {label: 'Teranet Connect', value: 'TERANET_CONNECT'},
    {label: 'Forms', value: 'FORMS'},
    {label: 'Assyst Real Estate', value: 'STEWART_ASSYST'},
    {label: 'Chicago Title', value: 'CHICAGO_TITLE'},
    {label: 'Stewart Title', value: 'STEWART_TITLE'},
    {label: 'FCT Title Insurance', value: 'FCT'},
    {label: 'FCT-LLC', value: 'FCT_LLC'},
    {label: 'FCT-MMS', value: 'FCT_MMS'},
    {label: 'Produced', value: 'PRODUCED'},
    {label: 'Extended Workflows', value: 'SUPPLEMENTALTASK'},
    {label: 'User Uploaded', value: 'USER_UPLOADED'},
    {label: 'Client Uploaded', value: 'CLIENT_UPLOADED'},
    {label: 'Broker Uploaded', value: 'BROKER_UPLOADED'},
    {label: 'Realtor Uploaded', value: 'REALTOR_UPLOADED'},
    {label: 'Shared Document', value: 'SHARED'},
    {label: 'Signed Document', value: 'THIRD_PARTY_SIGNED'},
    {label: 'TitlePLUS', value: 'TITLE_PLUS'}

];

const DocumentTypesLC = [
    {label: 'Teranet Connect', value: 'TERANET_CONNECT'},
    {label: 'Forms', value: 'FORMS'},
    {label: 'Unity® Lender Centre', value: 'STEWART_ASSYST'},
    {label: 'Chicago Title', value: 'CHICAGO_TITLE'},
    {label: 'Stewart Title', value: 'STEWART_TITLE'},
    {label: 'FCT Title Insurance', value: 'FCT'},
    {label: 'FCT-LLC', value: 'FCT_LLC'},
    {label: 'FCT-MMS', value: 'FCT_MMS'},
    {label: 'Produced', value: 'PRODUCED'},
    {label: 'Supplemental Task', value: 'SUPPLEMENTALTASK'},
    {label: 'User Uploaded', value: 'USER_UPLOADED'},
    {label: 'Client Uploaded', value: 'CLIENT_UPLOADED'},
    {label: 'Broker Uploaded', value: 'BROKER_UPLOADED'},
    {label: 'Realtor Uploaded', value: 'REALTOR_UPLOADED'},
    {label: 'Shared Document', value: 'SHARED'},
    {label: 'Signed Document', value: 'THIRD_PARTY_SIGNED'},
    {label: 'TitlePLUS', value: 'TITLE_PLUS'}

];

export const docStatus = {
    COMPLETED: 'COMPLETED',
    UPLOADED: 'UPLOADED',
};

export type MatterDocSubTabType = 'MATTER_DOCUMENTS' | 'SHARED_DOCUMENTS';

@FocusFirstElementDecorator()
@AutoUnsubscribe()
@Component({
    selector: 'dp-matter-documents-for-this-matter',
    templateUrl: 'documents-for-this-matter.component.html',
    styleUrls: [
        './document-production-template.component.scss'
    ],
    providers: [DocumentProductionService]
})

export class DocumentsForThisMatterComponent implements OnInit {
    @ViewChild('sortDocumentName') sortDocumentName: DpDefaultSorter;
    @ViewChild('sortDescription') sortDescription: DpDefaultSorter;
    @ViewChild('sortLastUpdatedTimeStamp') sortLastUpdatedTimeStamp: DpDefaultSorter;
    @ViewChild('sortGeneratedDocumentType') sortGeneratedDocumentType: DpDefaultSorter;
    @ViewChild('sortInitials') sortInitials: DpDefaultSorter;
    @ViewChild(DocumentProductionTemplateComponent) public documentProductionTemplate: DocumentProductionTemplateComponent;
    @ViewChild('fileUploadInput') fileUploadInput: ElementRef;
    @Input() isProjectDocument: boolean = false;
    @Input() isMaterOpening: boolean = false;
    @Input() project: Project = null;
    @Input() projectMatter: Matter;
    @Input() signingEnvelope: SigningEnvelope;
    @Input() documentsViewOption: string;
    @Input() treeModel: any;
    @Output() autoShareChangeEvent = new EventEmitter();
    @Output() docUploadEvent = new EventEmitter();
    @Output() docAvailable = new EventEmitter();
    @Input() customEventMatter : Matter;
    ACCEPTED_FILE_EXTENSIONS = '*.bmp, *.doc, *.docx, *.gif, *.jpeg, *.jpg, *.pdf, *.png, *.txt, *.tiff, *.xls, *.xlsx, *.xml, *.wpd, *.tif, *.msg, *.eml';
    AUTO_SHARE_FILE_EXTENSIONS: string[] = ['*.doc', '*.docx', '*.pdf', '*.wpd'];
    @Input() showWizardFields : boolean = false;

    matterIdSubscription: Subscription;
    documentsSubscription: Subscription;
    convertToPDFSubscription: Subscription;
    getDocGenTemplateSubscription: Subscription;
    getDocsSubscrpition: Subscription;

    actionButtonFlag: boolean = false;
    clickListener: Function;

    //TODO: what is this used for?
    topTable: string = "180px";

    matterDocuments: Document[] = [];

    //Refactoring
    @ViewChild('mf') dataTableMf: any;
    sortQuery: string = 'fileName';
    sortType: string = 'ASC';
    typeFilter: string = '';
    filteredRows: Document[] = [];
    documentTemplateIds: any[] = [];
    selectedDocuments: DocumentWrapper[] = [];
    checkBoxAllTemplate: boolean = false;
    matterTemplates: DocumentTemplate[] = [];
    /**
     * Flag for keeping track when we're in the process of loading documents, as some logic on the page relies on the documents list (and their state) and
     * should be disabled when the list is not completely loaded
     */
    documentsLoadInProgress: boolean = false;

    scheduler: number = 0;

    alreadySentToConnectRecipientEmail: string [] = [];
    alreadySentToUnityRecipients: boolean = false;
    subTabSelected: MatterDocSubTabType = 'MATTER_DOCUMENTS';
    sharedDocSummaryList: SharedDocumentsSummaryItem[] = [];
    sharedDocumentsPackages: SharedDocumentsPackage[];
    sharedDocsAvailable: boolean = false;
    sharedDocsSelectedIndex: number = -1;

    matterDocumentsHash: string = '';
    lawClerkSolicitorList: Contact[];

    documentNodes: any[] = [];
    options: ITreeOptions = {
        allowDrag: (node) => {
            return node.data && node.data.isNodeEditable;
        },
        allowDrop: (element, {parent}) => {
            let children : any[] = parent.data.isFolder ?  parent.data.children : parent.parent.data.children;
            return parent.data && parent.data.isNodeEditable
                && (!element || (element.data && element.data.isNodeEditable  && !children.some(item => element.data.isFolder && item.isFolder && item.name == element.data.name)
                && !children.some(item => !element.data.isFolder && element.data.document && !item.isFolder && item.document
                        && item.document.documentName == element.data.document.documentName)));
        },
        actionMapping: {
            mouse: {
                dragOver: (tree: TreeModel, node: TreeNode, $event: any) => {
                    if (!node.isExpanded) {
                        TREE_ACTIONS.EXPAND(tree, node, $event);
                    }
                },
                drop: (tree: TreeModel, node: TreeNode, $event: any, {from, to}) => {
                    if (!node.data.isFolder) {
                        to.parent = to.parent.parent;
                    }
                    if (!from && $event.dataTransfer && $event.dataTransfer.files && $event.dataTransfer.files.length > 0) {
                        this.initiateUpload($event.dataTransfer.files, to.parent.data);
                    } else {
                        if (from.data.isFolder && to.parent.children) {
                            to.index = to.parent.children.length;
                        }
                        TREE_ACTIONS.MOVE_NODE(tree, node, $event, {from, to});
                        TREE_ACTIONS.FOCUS(this.tree.treeModel, node, $event);
                    }


                }
            },
            keys: {
                [KEYS.SPACE]: (tree: TreeModel, node: TreeNode, $event: any) => {
                    if (node.data && node.data.isNodeEditable) {
                        if (!node.data.isFolder) {
                            this.selectDocument(node.data.document, $event);
                        } else if (node.data.isFolder && !node.data.isEdited && node.data.path && node.data.path.trim() != "") {
                            node.data.isEdited = true;
                            node.data.nameCopy = node.data.name;
                            setTimeout(() => {
                                jQuery("#nodeId_" + node.id).focus();
                            })
                        }
                    }
                },
                120: (tree: TreeModel, node: TreeNode, $event: any) => {
                    if (node.data.isFolder && !node.data.isEdited && node.data && node.data.isNodeEditable) {
                        this.addSubFolder(node, $event);
                    }
                },
                46: (tree: TreeModel, node: TreeNode, $event: any) => {
                    if (node.data && node.data.isNodeEditable) {
                        this.removeSubFolder(node, $event);
                    }
                }
            }
        },
        allowDragoverStyling: true,
        levelPadding: 20,
        useVirtualScroll: false,
        animateExpand: true,
        scrollOnActivate: true,
        animateSpeed: 30,
        animateAcceleration: 1.2,
    };

    @ViewChild(TreeComponent)
    private tree: TreeComponent;
    private searchSub$ = new Subject<string>();
    searchText: string;

    constructor(public documentProductionService: DocumentProductionService,
                public childGetsFromParent: GetGlobalSaveModelService,
                public logger: Logger,
                public dialogService: DialogService,
                public route: ActivatedRoute,
                public http: HttpClient,
                public renderer: Renderer2,
                public tabService: TabsService,
                public appConfig: AppConfig,
                public cirfHelperService: CirfHelperService,
                public viewContainerRef: ViewContainerRef,
                public window: WindowRef,
                public teranetService: TeranetService,
                public documentUtility: DocumentUtilityService,
                public lockScreenService: LockScreenService,
                public authorizationService: AuthorizationService,
                public userConfigurationService : UserConfigurationService,
                public matterService: MatterService,
                public emailFieldService: EmailFieldService,
                public contactService: ContactService,
                public eventService: EventService,
                public matterPollingService: MatterPollingService,
                public dpDirtyCheckService: DpDirtyCheckService,
                public globalLogger: GlobalLogger,
                public httpClient: HttpClient) {

        this.clickListener = renderer.listen('document', 'click', (event) => {
            this.resetActionButtonFlag();

        });
    }


    ngOnInit(): void {
        if (this.isMaterOpening && this.documentsViewOption) {
            this.documentViewMode = this.documentsViewOption;
        }
        if (!this.matterIdSubscription) {
            this.matterIdSubscription = this.childGetsFromParent.getItem$
                .subscribe(() => {
                    this.getComponentStateAttributes();
                    this.loadPage();
                    this.emailFieldService.matter = this.currentMatter;
                });
        }

        if (!this.isMaterOpening) {
            if (this.currentMatter && this.currentMatter.id <= 0 && this.currentMatter.referralId) { // this should only happen on a new referral import as referralId is not saved on db
                this.matterService.callOperationAfterMatterSaved(this.currentMatter, () => {
                });
            }
            if (this.currentMatter && this.currentMatter.isDigitalSignPlatformSet()) {
                this.getLawClerkSolicitorList();
            }
        }

        this.searchSub$.pipe(
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe(() => {
            this.onTypeChange();
        });
    }

    getLawClerkSolicitorList() {
        this.lawClerkSolicitorList = [];
        let obsSolicitorLawClerks: any[] = [];
        obsSolicitorLawClerks.push(this.contactService.getUpdatedSolicitorLawClerkList("SOLICITOR"));
        obsSolicitorLawClerks.push(this.contactService.getUpdatedSolicitorLawClerkList("LAWCLERK"));
        obsSolicitorLawClerks.push(this.contactService.getUpdatedSolicitorLawClerkList("OTHER"));
        forkJoin(obsSolicitorLawClerks).subscribe((results) => {
            if(results) {
                results.forEach((result: Contact[]) => {
                    this.lawClerkSolicitorList.push(...(result || []));
                })
            }
        });
    }

    loadPage(): void {
        if(this.currentMatter && this.currentMatter.isOpportunityMatter()){
            this.refreshDocuments();
        } else {
            this.getDocGenTemplateSubscription = this.documentProductionService.getDocGenTemplates(this.selectedMatterId, 'ALL')
                .subscribe((templates: DocumentTemplate[]) => {
                    this.matterTemplates = templates;
                    this.refreshDocuments();
                });
        }

        this.checkSharedDocAvailability();
    }

    async checkSharedDocAvailability(): Promise<void> {
        //Need to load sharedDocumentsPackages to use it later on to send warning logs in case of sharing duplicate files
        await this.loadSharedDocuments();
        if (Array.isArray(this.sharedDocumentsPackages)) {
            this.sharedDocsAvailable = this.sharedDocumentsPackages.length > 0;
        }
    }

    refreshDocuments(postLoadAction: Function = null): void {
        this.topTable = "220px";
        if (this.isMaterOpening) {
            this.matterDocumentsHash = '';
            this.loadDocuments(postLoadAction);
        } else {
            this.startPolling(postLoadAction);
        }


    }

    public startPolling(postLoadAction: Function = null): void {
        this.matterDocumentsHash = ''; // Reset the value to force pulling the documents at east once
        this.loadDocuments(postLoadAction);
        clearInterval(this.scheduler);
        this.scheduler = window.setInterval(() => {
            this.loadDocuments(postLoadAction);
        }, 2000);
    }

    public sortDescriptionText(): void {
        this.sortDescription.sort();
    }

    public resetActionButtonFlag(): void {
        this.actionButtonFlag = false;
    }

    get selectedMatterId(): number {
        if (this.isProjectDocument) {
            return this.project.templateMatterId ? this.project.templateMatterId : 0;
        } else {
            return this.currentMatter ? this.currentMatter.id : 0;
        }
    }

    private async checkNeedToUpdateDocs(): Promise<boolean> {
        const matterDocumentMetadata: MatterDocumentMetadata = await this.documentProductionService.getDocumentsMetaData(this.currentMatter && this.currentMatter.id).toPromise();
        if (!matterDocumentMetadata) {
            return true;
        } else if (matterDocumentMetadata.aggregateDocumentsStatusHash != this.matterDocumentsHash) {
            this.matterDocumentsHash = matterDocumentMetadata.aggregateDocumentsStatusHash;
            return true;
        }
        return false;
    };

    private readonly loadDocuments = async (postLoadAction: Function = null) => {
        const needsDocsUpdate: boolean = await this.checkNeedToUpdateDocs();
        if (!needsDocsUpdate) {
            return;
        }
        this.documentsLoadInProgress = true;
        this.documentsSubscription = this.documentProductionService.getDocuments(this.selectedMatterId)
            .subscribe((matterDocs: Document[]) => {
                let documents: Document[] = [];
                matterDocs.forEach((doc: Document) => {
                    if (!doc.description && doc.templateId && this.matterTemplates) {
                        let correspondingTemplate = this.matterTemplates.find(template => template.docGenTemplateId == doc.templateId);
                        doc.description = correspondingTemplate ? correspondingTemplate.description : '';
                    }
                    documents.push(doc);
                });

                this.matterDocuments = documents;
                this.filteredRows = this.filterRows();
                this.generateFolderStructure(false);
                this.documentsLoadInProgress = false;
                this.docAvailable.emit();
                if (postLoadAction) {
                    postLoadAction();
                }
            });
    };

    startVirusScanStatusCheck() {
        this.startPolling();
    }


    public focusRow(index) {
        jQuery('tr').removeClass("active-hover");

        //TODO: investigate why we need a timeout
        setTimeout(() => {
            let targetRow = jQuery('tbody tr:eq(' + index + ')');
            targetRow.addClass("active-hover");
            targetRow.focus();
        }, 150);
    }

    get currentMatter(): Matter {
        if (this.isProjectDocument) {
            return this.projectMatter;
        }
        else if(this.isMaterOpening && this.customEventMatter) {
            return this.customEventMatter;
        }
        else {
            return currentMatter.value;
        }
    }

    get rows(): Document[] {
        if (this.filteredRows.length > 0) {
            return this.filteredRows.filter(items => !items.isUdDirMarketTxtFile());
        }
        return [];
    }

    selectAllTemplate(): void {
        if (this.filteredRows && this.checkBoxAllTemplate) {
            this.selectedDocuments = [];
            this.filteredRows.forEach(template => {
                if (!template.isInfected && !template.isVirusScanPending) {
                    if (this.documentUtility.mapStatus(template.status, false) === 'Available') {
                        this.selectedDocuments.push(this.createWrapperForDocument(template));
                    }
                }
            });

        } else {
            this.selectedDocuments = [];
        }
        this.setComponentStateAttributes();
    }

    createWrapperForDocument(document: Document): DocumentWrapper {
        let wrapper: DocumentWrapper = new DocumentWrapper();
        wrapper.documentId = document.id;
        wrapper.generatedDocumentType = document.generatedDocumentType;
        return wrapper;
    }


    selectDocument(document: Document, event): void {

        if (!this.isDocumentSelected(document)) {
            this.selectedDocuments.push(this.createWrapperForDocument(document));
        } else {
            (<any>this.selectedDocuments).remove(this.selectedDocuments.find(item => {
                return item.documentId == document.id;
            }));
            this.checkBoxAllTemplate = false;
        }
    }

    isDocumentSelected(document: Document): boolean {
        let selectedIndex = this.selectedDocuments.findIndex(item => item.documentId == document.id);
        return selectedIndex != -1;
    }

    //TODO:Currently the PRODUCED Document type is null, in the future it will have the valid document type, This below condition for PRODUCED need to be fixed.
    onTypeChange() {
        this.filteredRows = this.filterRows();
        this.generateFolderStructure(true);
        setTimeout(() => {
            if (this.tree && this.tree.treeModel) {
                if ((this.searchText && this.searchText.trim() != "") || this.typeFilter) {
                    this.tree.treeModel.filterNodes((node) => {
                        return (node.data.isFolder && this.isChildrenVisible(node.data.children)) || (node.data.document && this.filteredRows.some(item => item.id == node.data.document.id));

                    });
                }
                this.tree.treeModel.expandAll();
            }
        }, 0)
    }


    public filterRows(): Document[] {
        if (this.isMaterOpening) {
            let filteredMatterDocuments = this.matterDocuments.filter(item => item.isPdf || item.isWordOrWordPerfect)
            if (this.signingEnvelope && this.signingEnvelope.envelopeDocuments.length) {
                return filteredMatterDocuments.filter(fd => this.signingEnvelope.envelopeDocuments.filter(doc => !!doc.sentTimeStamp && !doc.isDocumentDeleted()).map(ed => {
                    return ed.sourceDocumentId
                }).indexOf(fd.id) < 0);
            } else {
                return filteredMatterDocuments;
            }
        }
        if (this.searchText && this.searchText.trim() != "") {
            return this.matterDocuments.filter(item => ((!this.typeFilter || item.generatedDocumentType === this.typeFilter) && item.documentName && item.documentName.toUpperCase().indexOf(this.searchText.toUpperCase()) > -1));
        } else if (this.typeFilter) {
            return this.matterDocuments.filter(item => item.generatedDocumentType === this.typeFilter);
        } else {
            return this.matterDocuments;
        }
    }


    downloadMultipleDocumentsForMatter(): void {
        this.documentProductionService.downloadZip(this.currentMatter, this.selectedDocuments);

    }

    getSelectedDocumentNameList(): string[] {
        let documentNameList: string[] = [];
        if (this.filteredRows && this.filteredRows.length > 0 && this.selectedDocuments && this.selectedDocuments.length > 0) {
            documentNameList = this.filteredRows.filter(row => this.selectedDocuments.find(selectedDocument => selectedDocument.documentId === row.id))
                .map(item => item.documentName);
        }
        return documentNameList;
    }

    openShareDocsUsersModal(selectedMatterDocuments: Document[]) {
        this.dialogService.content({
            content: ShareDocsUsersModalComponent,
            context: {
                matter: this.currentMatter,
                alreadySentToConnectRecipientEmail: this.alreadySentToConnectRecipientEmail,
                alreadySentToUnityRecipients: this.alreadySentToUnityRecipients,
                documentNameList: this.getSelectedDocumentNameList(),
            },
            onFulfillment: (result) => {
                if (result && result.shareDocumentPackage) {
                    let sharedDocumentsPackage: SharedDocumentsPackage = result.shareDocumentPackage;
                    sharedDocumentsPackage.documents = selectedMatterDocuments.map(doc => {
                        let sharedDocType = doc.isThirdPartyDocument ? "THIRD_PARTY" : 'UNITY';
                        return new SharedDocument({
                            id: doc.id,
                            sharedDocumentType: sharedDocType,
                            name: doc.documentName,
                            originalName: doc.originalDocumentName
                        } as SharedDocument);
                    });

                    this.documentProductionService.generateShareDocsPackage(sharedDocumentsPackage).subscribe(shareDocumentPackageUpdated => {
                            this.logDuplicateSharingIfExist(sharedDocumentsPackage, shareDocumentPackageUpdated);
                            this.updateSharedDocumentPackages(shareDocumentPackageUpdated);
                            this.sharedDocsAvailable = true;
                            if (sharedDocumentsPackage.unityRecipientIds.length > 0) {
                                this.displayDocShareResults(result.modalResult, `${messages.sharedDocumentsMessages.unityMessageSent}`);
                            } else {
                                if (shareDocumentPackageUpdated) {
                                    let selectedPrecedentDescription;
                                    const baseUrl = this.cirfHelperService.getUnityConnectBaseUrl(shareDocumentPackageUpdated.guid);
                                    if (result.modalResult && result.modalResult.selectedPrecedent) {
                                        selectedPrecedentDescription = result.modalResult.selectedPrecedent.description;
                                    }

                                    let subject: string = this.emailFieldService.getMailSubject(this.currentMatter.isCustomMatter() ? EmailKeys.matterOpening : result.modalResult.emailKey, result.modalResult.sentToUnityUserRecipients ? null : EmailTypes.isShareDoc);

                                    // recipientDearName only in UI for temperately save the contact dear information.
                                    // So use sharedDocumentsPackage.recipientDearName to replace  shareDocumentPackageUpdated.recipientName
                                    this.emailFieldService.openLocalEmailClient(shareDocumentPackageUpdated.connectRecipientEmail, subject, this.createShareDocumentEmailBody(sharedDocumentsPackage.recipientDearName, baseUrl, selectedPrecedentDescription)).subscribe(
                                        () => {
                                            this.displayDocShareResults(result.modalResult, `${messages.sharedDocumentsMessages.emailSent}`);
                                        }
                                    )
                                } else {
                                    this.displayDocShareResults(result.modalResult, `${messages.sharedDocumentsMessages.emailSent}`);
                                }

                            }
                        }, (error: ApplicationError) => {
                            this.dialogService.confirmDialog('Document Sharing Error', 'Unexpected error happened on Server, please try later or contact the Administrator', true, 'OK');
                        }
                    );

                }
            }

        });

    }

    saveMatterBeforeOpenShareDocsUsersModal(selectedMatterDocuments: Document[]): void {
        if (this.currentMatter.dirty) {
            this.dialogService.confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save').subscribe(res => {
                if (res && res == true) {
                    let matterTab = this.tabService.activeTab as MatterTab;
                    if (matterTab && matterTab.matterComponent) {
                        let subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
                            if (result) {
                                this.openShareDocsUsersModal(selectedMatterDocuments);
                                subscription.unsubscribe();
                            }
                        });
                    }
                }
            });
        } else {
            this.openShareDocsUsersModal(selectedMatterDocuments);
        }
    }

    shareDocumentsForMatter(sharedDocumentsResult?: ShareDocsUserModalResult): void {
        let acceptedShareableTypes: DocumentTypeType[] = ['WORD', 'PDF', 'WORDPERFECT', 'TXT', 'EXCEL', 'IMAGE', 'XML', 'EMAIL'];
        let selectedMatterDocuments: Document[] = this.selectedDocuments.map(doc => this.matterDocuments.find(md => md.id == doc.documentId));
        if (!!selectedMatterDocuments.find(doc => acceptedShareableTypes.indexOf(doc.documentType) < 0)) {
            this.dialogService.confirmDialog('Document Sharing Error', "Selected file type cannot be shared with other users.",
                true, 'OK');
            return;
        }

        if (sharedDocumentsResult) {
            if (sharedDocumentsResult.sentToConnectRecipientEmail) {
                this.alreadySentToConnectRecipientEmail.push(sharedDocumentsResult.sentToConnectRecipientEmail);
            }
            if (sharedDocumentsResult.sentToUnityUserRecipients) {
                this.alreadySentToUnityRecipients = sharedDocumentsResult.sentToUnityUserRecipients;
            }
        } else {
            this.alreadySentToConnectRecipientEmail = [];
            this.alreadySentToUnityRecipients = false;
        }

        this.saveMatterBeforeOpenShareDocsUsersModal(selectedMatterDocuments);
    }

    displayDocShareResults(sharedDocumentsResult: ShareDocsUserModalResult, msg: string): void {
        if (sharedDocumentsResult) {
            if (sharedDocumentsResult.lastRecipient) {
                this.dialogService.confirmDialog('Document Sharing', msg, true, 'OK');
            } else {
                this.dialogService.confirm('Document Sharing', `${msg}<br>${messages.sharedDocumentsMessages.anotherRecipient}`, false, 'Yes', 'No').subscribe(
                    (res) => {
                        if (res) {
                            setTimeout(() => {
                                    this.shareDocumentsForMatter(sharedDocumentsResult);
                                },
                                500);
                        }
                    }
                );
            }
        }
    }

    createShareDocumentEmailBody(recipientName: string, link: string, selectedPrecedentDesc ?: string): string {
        //If recipientName is null, it should change '' to avoid "Dear null"
        let emailBody = 'Dear' + (recipientName ? ' ' + recipientName : '') + ',' + '\n\n';
        if (selectedPrecedentDesc) {
            emailBody += this.formatPrecedentToRemoveRichTextTags(selectedPrecedentDesc) + '\n\n';
        } else {
            emailBody += 'Documents for the above transaction have been shared with you.\n\n'
                + 'Please click on the link below to access the shared documents. '
                + 'If you have not previously, you will need to register through UnityC, your secure connection to Canadian law offices. '
                + 'Once you have registered, you can log in to UnityC to:\n\n'
                + '       - Connect with Canadian law offices\n'
                + '       - Provide information about files\n'
                + '       - Share documents\n'
                + '       - Get status updates\n\n';
        }

        emailBody += link + '\n\n'
            + 'The UnityC link is only for the benefit of the email recipient. Please do not forward or otherwise distribute this email.';
        return emailBody;
    }

    // As per Andrei's  discussion with LOB we are removing Rich Text Editor Tags [Bold , Underline, Paragraph and Italic] from email content.
    //HTML tags not supported in Email - https://tools.ietf.org/html/rfc6068
    formatPrecedentToRemoveRichTextTags(precedentContent: string) {
        return precedentContent.replace(/<strong>/gi, '').replace(/<\/strong>/gi, '')  //Bold Tag
            .replace(/<u>/gi, '').replace(/<\/u>/gi, '') // Underline tag
            .replace(/<em>/gi, '').replace(/<\/em>/gi, '') // Italic Tags
            .replace(/&nbsp;/gi, ' ') // Replacing with space
            .replace(/<p>/gi, '').replace(/<\/p>/gi, '').trim(); // Paragraph Tag
    }

    openFileInNewWindow(doc: Document) {
        this.updateSharedDocumentFields(doc);
        if (doc.hasBarcode) {

            this.dialogService
                .confirm('', messages.documentProduction.downloadPdfWithBarcode, true, 'Continue')
                .subscribe(res => {
                    if (res) {
                        this.documentProductionService.openFileInNewWindow(this.selectedMatterId, Number(doc.id), doc.isPdf, true);
                    }
                });
        } else {
            this.documentProductionService.openFileInNewWindow(this.selectedMatterId, Number(doc.id), doc.isPdf, true);
        }
    }

    openThirdPartyFile(docId: number) {
        this.teranetService.downloadTeranetDocumentAndDisplay(this.selectedMatterId, Number(docId));
    }

    clickedDocumentListRow(e, l) {
        jQuery('tr').removeClass('active-hover');
    }

    //Updating the Shared Document field on call to open the document.
    // Backend updates and field as well
    updateSharedDocumentFields(document: Document) {
        if (document.isSharedDocument) {
            document.lastViewedTimeStamp = new Date().getTime().toString();
        }
    }

    burgerMenuForThisMatterClicked(clickedMenuOption: string, row?: Document, nodeData?: any): void {

        switch (clickedMenuOption) {
            case 'Open':
                if (row && row.id && row.documentName) {
                    if (row.isThirdPartyDocument) {
                        this.openThirdPartyFile(row.id);
                    } else {
                        if (row.isWordOrWordPerfect) {
                            this.openWordForEdit('' + row.id, row.documentName);
                        } else if (row.isPdf || row.isTxt || row.isXml || row.isExcel || row.isImage || row.isEmail) {
                            this.openFileInNewWindow(row);
                        }
                    }
                }
                return;
            case 'Delete':
                if (row && row.id) {
                    this.deleteDocument(row, nodeData);
                }
                return;
            /*case 'Download':

                if(row && row.id) {
                    if(row.isThirdPartyDocument) {
                        this.openThirdPartyFile(row.id);
                    } else {
                        this.downloadFileInNewWindow(row);
                    }
                }
                return;*/
            case 'Edit Details':
                if (row && row.id && row.documentName) {
                    this.copyOrRenameDocument(row, 'EDIT_DETAILS');
                }
                return;
            case 'Rename':
                if (row && row.id && row.documentName) {
                    this.copyOrRenameDocument(row, 'RENAME');
                }
                return;
            case 'Copy':
                if (row && row.id && row.documentName) {
                    this.copyOrRenameDocument(row, 'COPY', nodeData);
                }
                return;
            case 'Convert to PDF':
                this.convertToPDF(row);
                return;
            case 'Copy Location To Clipboard':
                this.documentProductionService.copyFileNameToClipboard(row.id, this.selectedMatterId, row.documentName);
                let message = '<b>File Location Copied to Clipboard</b><br><br><br>' +
                    ' File Location : ' + row.documentName + '<br><br>' +
                    'Note that this link expires upon logoff from Unity';
                this.dialogService.confirm('Information', message, true, 'OK', null, true).subscribe(res => {
                });
                return;
            default:
                return;
        }

    }

    convertToPDF(row: any): void {
        this.lockScreenService.lockForUpdate = true;
        this.convertToPDFSubscription = this.documentProductionService.convertToPDF(this.currentMatter.id, row.id)
            .finally(() => this.lockScreenService.lockForUpdate = false)
            .subscribe(res => {
                this.startVirusScanStatusCheck();
            });
    }

    openWordForEdit(documentId: string, documentName: string): void {
        this.documentProductionService.getDocument(this.selectedMatterId, documentId)
            .subscribe(data => {
                let isLockedByUser: boolean = data.isLockedByUser;
                if (isLockedByUser) {
                    let documentType: string = this.isWordPerfectFile(documentName) ? 'Word Perfect' : 'MS-Word';
                    this.dialogService.confirmDialog('Document Production Error', "Document " + documentName + " is already open in " + documentType + ". Please select" +
                        " document from task bar.", true, 'OK');
                } else {
                    if (this.isWordPerfectFile(documentName)) {
                        this.documentProductionService.openDocumentForEdit(documentId, documentName, "WORDPERFECT");
                    } else {
                        this.documentProductionService.openDocumentForEdit(documentId, documentName);
                    }
                }
            });
    }

    downloadDocument(row): void {
        if (row.isPdf && row.generatedDocumentType && row.isThirdPartyDocument) {
            this.openThirdPartyFile(row.id);
        } else {
            this.documentProductionService.downloadWordOrOpenPDFFile(this.selectedMatterId, row).subscribe(res => {
                console.log(res);
            });
        }
    }

    deleteDocument(row: Document, nodeData: any): void {
        let errMsg = "Not able to delete the file as it is open for editing. Please close the Word document and try again.";

        //Calculate the focus index after delete.
        // We normally focus on the next document in the list, which will be at the deleted document's index after the deletion
        let index = this.matterDocuments.findIndex((r: Document) => r.id == row.id);

        let postLoadAction: Function = () => {
            if (index > this.matterDocuments.length - 1) {
                //If the deleted document was the last one in the list, we focus on the previous one
                index = this.matterDocuments.length - 1;
            }
            if (index >= 0) {
                this.focusRow(index);
            } else {
                //Focus on the filter if no documents left
                document.getElementById("typeFilter").focus();
            }
        };


        this.dialogService.confirm('Confirmation', 'Are you sure you would like to delete this file?', false, 'Delete').subscribe(res => {
            if (res && res == true) {
                let treeNodeParent: TreeNode;
                if(this.tree && this.tree.treeModel && nodeData) {
                   let  treeNode = this.tree.treeModel.getNodeById(nodeData.id);
                    if(treeNode && treeNode.parent){
                        treeNodeParent = treeNode.parent;
                    }
                }
                this.documentProductionService.deleteFile(this.selectedMatterId, '' + row.id).subscribe(res2 => {
                        this.refreshDocuments(postLoadAction);
                        if(treeNodeParent){
                            this.updateDeleteBurgerMenuForNode(treeNodeParent.data);
                        }
                    }, error => {
                        if (error.errorCode == "app.recordLocked") {
                            this.dialogService.confirm('Error', errMsg, true).subscribe(res3 => {

                            });
                        }
                    }
                );


            }
        });
    }

    copyOrRenameDocument(row, action, nodeData?: any): void {

        this.dialogService.content({
            content: DpCopyAndRenameModalComponent,
            context: {
                document: row,
                matterId: this.selectedMatterId,
                action: action,
                matterDocuments: this.matterDocuments,
                nodeData: nodeData,
                documentNodes : this.documentNodes
            },
            onFulfillment: (result) => {
                let index = this.matterDocuments.findIndex((r: any) => r.id === row.id);
                if (result) {
                    if (action == "RENAME") {
                        this.matterDocuments[index].documentName = result.newFileName;
                        this.updateSortingAfterAction();
                    }
                    if (action == "EDIT_DETAILS") {
                        this.matterDocuments[index].documentName = result.newFileName;
                        this.matterDocuments[index].description = result.description;
                        this.updateSortingAfterAction();
                    }
                    if (action == "COPY") {

                        if (result.res && result.res.MatterDocument) {
                            let matterDocument = new Document(result.res.MatterDocument);
                            this.matterDocuments.push(matterDocument);
                            this.updateSortingAfterAction();

                            let copyDocumentIndex = 0;
                            if (this.dataTableMf) {
                                copyDocumentIndex = this.dataTableMf.data.findIndex(item => item.id == matterDocument.id);
                            }
                            this.focusRow(copyDocumentIndex);
                        } else {
                            let postLoadAction: Function = () => {
                                let index = this.matterDocuments.findIndex((r: any) => r.documentName == result.newFileName);
                                this.focusRow(index);
                            };
                            this.refreshDocuments(postLoadAction);

                        }
                        this.startVirusScanStatusCheck();
                    }

                } else {
                    this.focusRow(index);
                }

            }
        });


    }


    public updateSortingAfterAction(): void {
        if (this.sortDocumentName.active) {
            this.sortDocumentName.customSort();
        } else if (this.sortDescription.active) {
            this.sortDescription.customSort();
        } else if (this.sortGeneratedDocumentType.active) {
            this.sortGeneratedDocumentType.customSort();
        } else if (this.sortInitials.active) {
            this.sortInitials.customSort();
        } else if (this.sortLastUpdatedTimeStamp.active) {
            this.sortLastUpdatedTimeStamp.customSort();
        }
    }


    getBurgerMenuItemsForThisMatter(doc: Document) {
        let items: string[] = ['Open'];
        if (doc && !doc.isSharedDocument) {
            items.push('Copy Location To Clipboard');
            if (doc.writeProtected) {
                // items.push('Download');
            } else {
                if (doc.isForms) {
                    items.push('Delete');
                } else {

                    this.isProjectDocument ? items.push('Copy', 'Delete', 'Edit Details') : items.push('Copy', 'Delete', 'Rename');
                }
            }
            if (doc.isWordOrWordPerfect && !doc.isThirdPartyDocument) {
                items.push('Convert to PDF');
            }
            if(doc.isThirdPartyDocument){
                items.push('Copy');
            }
        }
        else if(doc && doc.isSharedDocument){
            items.push('Copy');
        }
        return items;
    }

    getDisabledItemsForThisMatter(doc: Document) {
        if (this.isMatterDisabled()) {
            return this.isProjectDocument ? ['Copy', 'Delete', 'Edit Details', 'Convert to PDF', 'Copy Location To ClipboardL'] : ['Copy', 'Delete', 'Rename', 'Convert to PDF', 'Copy Location To Clipboard'];
        } else if (doc.isInfected || doc.isVirusScanPending) {
            return this.isProjectDocument ? ['Open', 'Copy', 'Edit Details', 'Convert to PDF', 'Copy Location To Clipboard'] : ['Open', 'Copy', 'Rename', 'Convert to PDF', 'Copy Location To Clipboard'];
        } else if (doc.isTPRPdfFile) {
            return this.isProjectDocument ? ['Copy', 'Delete', 'Edit Details', 'Convert to PDF', 'Copy Location To Clipboard'] : ['Copy', 'Delete', 'Rename', 'Convert to PDF', 'Copy Location To Clipboard'];
        } else {
            return [];
        }
    }

    isUploadDisabled(): boolean {
        if (this.isProjectDocument && this.project) {
            let projectTab = this.tabService && this.tabService.activeTab as ProjectTab;
            return projectTab && projectTab.isProject() && !projectTab.isAnchorTab() && projectTab.isProjectReadOnly(this.authorizationService) || this.documentsLoadInProgress;
        } else {
            return this.documentsLoadInProgress || this.isMatterDisabled() || this.currentMatter.id <= 0;
        }
    }

    fileBrowseUpload(): void {
        let uploadFilesControl = this.fileUploadInput.nativeElement;
        if (!(uploadFilesControl.value && uploadFilesControl.files && uploadFilesControl.files.length > 0)) {
            return;
        }
        this.initiateUpload(this.fileUploadInput.nativeElement.files);
    }


    initiateUpload(files: FileList, node?: any) {
        if (!this.isUploadDisabled()) {
            //Loading the documents one more time before passing them to the upload control, as we need the latest locking status from the server
            this.getDocsSubscrpition = this.documentProductionService.getDocuments(this.selectedMatterId)
                .subscribe((matterDocs: Document[]) => {
                    let existingFiles: ExistingFile[] = [];
                    if (node && node.path) {
                        existingFiles = node.children.filter(ch => ch.document).map(nd => {
                            return nd.document
                        }).filter(file => !file.isSharedDocument).map((doc: Document) => {
                            return {
                                name: doc.documentName,
                                lastUpdatedTimeStamp: doc.lastUpdatedTimeStamp,
                                isOpen: doc.isDocumentOpened,
                                isProtected: doc.writeProtected || doc.isTeranetConnectDocument,
                                generatedDocType: doc.generatedDocumentType // 3rd party doc like TPR can also be uploaded. Need to let backend know this type.
                            } as ExistingFile;
                        });
                    } else {
                        //Package the information about the documents in ExistingFile[] format (the common structure the upload control works with)
                        existingFiles = matterDocs.filter(file => !file.isSharedDocument).map((doc: Document) => {
                            return {
                                name: doc.documentName,
                                lastUpdatedTimeStamp: doc.lastUpdatedTimeStamp,
                                isOpen: doc.isDocumentOpened,
                                isProtected: doc.writeProtected || doc.isTeranetConnectDocument,
                                generatedDocType: doc.generatedDocumentType // 3rd party doc like TPR can also be uploaded. Need to let backend know this type.
                            } as ExistingFile;
                        });
                    }


                    this.dialogService.content({
                        content: FileUploadModal,
                        context: {
                            filesSelectedForUpload: files,
                            uploadUrl: matterApi.uploadDocument.replace('{matterId}', '' + this.currentMatter.id),
                            existingFiles: existingFiles,
                            acceptedFileExtensions: this.ACCEPTED_FILE_EXTENSIONS,
                            maxFileSize: this.maxFileSizeInMb,
                            uploadOptions: {subpath: (node ? node.path : undefined)}
                        } as FileUploadModalContext,
                        onFulfillment: () => {
                            this.fileUploadInput.nativeElement.value = '';
                            this.startVirusScanStatusCheck();
                            this.docUploadEvent.emit();
                        },
                        onRejection: () => {
                            this.fileUploadInput.nativeElement.value = '';
                            this.refreshDocuments();
                        }

                    });

                });
        }
    }

    get acceptedFileExtensions(): string {
        return this.ACCEPTED_FILE_EXTENSIONS.replace(/\*/g, '');
    }

    get maxFileSizeInMb(): number {
        return this.appConfig.maxUploadedFileSizeInMb;
    }

    tableKeyCommands(event): void {

        let charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));

        if (charCode === CustomKeyCodesEnum.Down) {
            event.preventDefault();

            this.keyCommandtoHiliteNextMatter(event);
        }
        if (charCode === CustomKeyCodesEnum.Up) {
            event.preventDefault();

            // hilite prev matter with up arrow
            this.keyCommandtoHilitePreviousMatter(event);
        }
    }

    keyCommandtoHiliteNextMatter(event) {

        if (document.activeElement.className === "toggleBurger") {
            jQuery(event.target).closest('tr').next('tr').focus();
        } else {
            jQuery(document.activeElement).next('tr').focus();
        }
    }

    keyCommandtoHilitePreviousMatter(event) {

        if (document.activeElement.className === "toggleBurger") {
            jQuery(event.target).closest('tr').prev('tr').focus();
        } else {
            jQuery(document.activeElement).prev('tr').focus();
        }
    }

    displayFriendlyDocumentType(row: Document): string {
        if (row.isThirdPartySigned) {
            return row.getThirdPartySignedLabel();
        }
        let returnValue: string = row.generatedDocumentType;

        if (returnValue) {
            DocumentTypesLC.map(function (item) {
                if (item.value == returnValue) {
                    return returnValue = item.label;
                }
            });
        }
        return returnValue;
    }

    //temporarily, we tell whether the file is wordPerfect or not based on the file extension
    isWordPerfectFile(filename: string) {
        return filename && filename.toLowerCase().endsWith(".wpd");
    }

    getWordDocsIcon(docName: string) {
        if (this.isWordPerfectFile(docName)) {
            return "assets/img/wp.jpg";
        }
        return "assets/img/word.jpg";
    }

    ngOnDestroy() {
        clearInterval(this.scheduler);
        this.resetComponentStateAttributes();
    }

    ngAfterViewInit() {

    }

    get matterTab(): MatterTab {
        return this.tabService.activeTab as MatterTab;
    }

    setComponentStateAttributes(): void {
        if (this.matterTab) {
            this.matterTab.matterDocumentsAllTemplatesCheckBox = this.checkBoxAllTemplate;
        }
    }

    getComponentStateAttributes(): void {
        if (this.matterTab) {
            this.checkBoxAllTemplate = this.matterTab.matterDocumentsAllTemplatesCheckBox;
        }
    }

    resetComponentStateAttributes(): void {
        if (this.matterTab) {
            this.matterTab.matterDocumentsAllTemplatesCheckBox = false;
        }
    }

    sortPackagesData() {
        if (this.sharedDocumentsPackages && this.sharedDocumentsPackages.length) {
            this.sharedDocumentsPackages = _.sortBy(this.sharedDocumentsPackages,
                (item: SharedDocumentsPackage) => {
                    return item.recipientName.toLowerCase();
                });
            this.sharedDocumentsPackages.forEach((sharedDocumentsPackage: SharedDocumentsPackage) => {
                if (sharedDocumentsPackage.documents && sharedDocumentsPackage.documents.length) {
                    sharedDocumentsPackage.documents = _.sortBy(sharedDocumentsPackage.documents,
                        (item: SharedDocument) => {
                            return item.name.toLowerCase();
                        });
                }

            });
        }
    }

    async loadSharedDocuments(): Promise<void> {
        this.sharedDocumentsPackages = await this.documentProductionService.getSharedDocumentPackageSummary(this.selectedMatterId).toPromise();
        this.sortPackagesData();
        if (Array.isArray(this.sharedDocumentsPackages)) {
            // console.table(sharedDocPckgSummaryList);
            this.sharedDocSummaryList = [];

            this.sharedDocumentsPackages.forEach((docPckg: SharedDocumentsPackage) => {
                let sdsiName: SharedDocumentsSummaryItem = new SharedDocumentsSummaryItem();
                sdsiName.recipient = docPckg.recipientName;
                sdsiName.recipientRole = docPckg.connectRecipientRole;
                sdsiName.id = docPckg.id;
                sdsiName.guid = docPckg.guid;
                sdsiName.isRecipientName = true;
                sdsiName.packageStatus = docPckg.status;
                this.sharedDocSummaryList.push(sdsiName);
                sdsiName.burgerMenu = [];
                sdsiName.packageSharingStatus = docPckg.packageSharingStatus;
                BurgerMenuExtendedItem.createMenuItem(sdsiName.burgerMenu, 'REVOKE_ALL_DOCUMENTS', 'Revoke All Documents', this.revokeDocuments);

                docPckg.documents.forEach((doc: SharedDocument) => {
                    let sdsiDoc: SharedDocumentsSummaryItem = new SharedDocumentsSummaryItem();
                    sdsiDoc.id = docPckg.id;
                    sdsiDoc.documentId = doc.id;
                    sdsiDoc.sourceDocumentId = doc.sourceDocumentId;
                    sdsiDoc.fileName = doc.name;
                    sdsiDoc.description = doc.description;
                    sdsiDoc.lastViewedTimeStamp = doc.lastViewedTimeStamp;
                    sdsiDoc.lastSharedTimeStamp = doc.lastSharedTimeStamp;
                    sdsiDoc.visible = true;
                    sdsiDoc.guid = docPckg.guid;
                    sdsiDoc.burgerMenu = [];
                    sdsiDoc.isDocSharingRevoked = doc.sharingRevoked;
                    BurgerMenuExtendedItem.createMenuItem(sdsiDoc.burgerMenu, 'OPEN', 'Open', this.openDocument);
                    if (!doc.sharingRevoked) {
                        BurgerMenuExtendedItem.createMenuItem(sdsiDoc.burgerMenu, 'REVOKE_THIS_DOCUMENT', 'Revoke This Document', this.revokeDocuments);
                    }
                    this.sharedDocSummaryList.push(sdsiDoc);
                });
            });

        }
    }

    onRowClick(itm: SharedDocumentsSummaryItem, rowIndex: number, $event): void {
        this.sharedDocsSelectedIndex = rowIndex;
        if (itm && itm.isRecipientName) {
            itm.expanded = !itm.expanded;
            this.sharedDocSummaryList.filter(sds => sds.id === itm.id && !sds.isRecipientName && !sds.isRecipientRole)
                .forEach(sds => sds.visible = !sds.visible);
        }
    }

    openDocument = (itm: SharedDocumentsSummaryItem) => {
        this.openSharedDoc(itm);
    };

    openSharedDoc(itm: SharedDocumentsSummaryItem): void {
        if (itm.fileName.toLowerCase().endsWith('pdf')) {
            let dwnldEndpoint: string = userAccountProfilesApi.downloadSharedDocument
                    .replace('{packageGuid}', itm.guid)
                    .replace('{documentId}', itm.documentId.toString())
                + `?${SESSION_ID_REQ_PARAM}=${sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId)}&updateLastViewedDate=false`;
            window.open(dwnldEndpoint, "_blank");
        }
    }

    onFileDropped(files: NgxFileDropEntry[]) {
        jQuery('.documents-for-this-matter').find('.drop-zone').removeClass('drop-zone-change');
        jQuery('.documents-for-this-matter').find('.drop-zone').removeClass('drop-zone-overlay');
        jQuery('.documents-for-this-matter').find('#dragAndDropTextArea').removeClass("drag-drop-text-area-hidden").addClass("drag-drop-text-area-visible");
        let fileList: any = [];
        if (!this.isMatterDisabled()) {
            for (const droppedFile of files) {
                if (droppedFile.fileEntry.isFile) {
                    const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                    fileEntry.file((file: File) => {
                        console.log(droppedFile.relativePath, file);
                        fileList.push(file);
                    });
                }
            }
            this.initiateUpload(fileList);
        }
    }

    public fileOver() {
        jQuery('.documents-for-this-matter').find('.drop-zone').addClass('drop-zone-overlay');
        jQuery('.documents-for-this-matter').find('#dragAndDropTextArea').removeClass("drag-drop-text-area-visible").addClass("drag-drop-text-area-hidden");
    }

    public fileLeave() {
        jQuery('.documents-for-this-matter').find('.drop-zone').removeClass('drop-zone-change');
        jQuery('.documents-for-this-matter').find('.drop-zone').removeClass('drop-zone-overlay');
        jQuery('.documents-for-this-matter').find('#dragAndDropTextArea').removeClass("drag-drop-text-area-hidden").addClass("drag-drop-text-area-visible");
    }

    public docTableDragOver(event) {
        jQuery('.documents-for-this-matter').find('.drop-zone').addClass('drop-zone-change');
        event.preventDefault();
        event.stopPropagation();
    }

    selectAutoShare(event, document: Document): void {
        if (this.project != null) {
            document.autoShared = event.srcElement.checked;
            this.documentProductionService.toggleProjectDocumentAutoShare(this.project.id, this.selectedMatterId, document).subscribe(() => {
                this.autoShareChangeEvent.emit(this.matterDocuments);
            });
        }
    }

    isAutoShareVisible(doc: Document): boolean {
        if (doc) {
            let fileType = '*' + doc.documentName.substring(doc.documentName.lastIndexOf('.')).toLowerCase();
            return !!this.AUTO_SHARE_FILE_EXTENSIONS.find((ext: string) => ext == fileType);
        }
        return false;
    }

    clickSharingBurgerMenu(clickedMenuOption: BurgerMenuExtendedItem, sharedDocumentsSummaryItem: SharedDocumentsSummaryItem) {
        if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
            clickedMenuOption.action(sharedDocumentsSummaryItem);
        }
    }

    clickFolderNodeBurgerMenu(clickedMenuOption: BurgerMenuExtendedItem, nodeData: any) {
        if (clickedMenuOption.key) {
            if (clickedMenuOption.key == 'ADD') {
                this.addSubFolder(nodeData, clickedMenuOption);
            }
            if (clickedMenuOption.key == 'RENAME') {
                if (nodeData.data.path && nodeData.data.path.trim() != "") {
                    nodeData.data.isEdited = true
                    nodeData.data.nameCopy = nodeData.data.name;
                    setTimeout(() => {
                        jQuery("#nodeId_" + nodeData.id).focus();
                    })
                }
            }
            if (clickedMenuOption.key == 'DELETE') {
                this.removeSubFolder(nodeData, clickedMenuOption);
            }
        }
    }

    revokeDocuments = (sharedDocumentsSummaryItem: SharedDocumentsSummaryItem) => {
        let sharedDocumentsPackage = this.sharedDocumentsPackages.find(pckg => pckg.id == sharedDocumentsSummaryItem.id);
        if (sharedDocumentsPackage) {
            this.dialogService.content({
                content: RevokeDocumentsModalComponent,
                context: {
                    sharedDocumentsPackage: sharedDocumentsPackage,
                    documentId: sharedDocumentsSummaryItem.documentId
                },
                onFulfillment: (result) => {
                    if (result && result.revokeSharing) {
                        result.revokeSharing.revokeSharingMessageBody = 'Dear ' + sharedDocumentsPackage.recipientName + '\n' + result.revokeSharing.revokeSharingMessageBody;
                        result.revokeSharing.revokeSharingMessageSubject = this.emailFieldService.getMailSubject(EmailKeys.matterOpening);
                        if (sharedDocumentsSummaryItem.documentId) {
                            this.documentProductionService.revokeDocument(result.revokeSharing, sharedDocumentsSummaryItem.guid, sharedDocumentsSummaryItem.documentId)
                                .subscribe(() => {
                                    this.loadSharedDocuments();
                                });
                        } else {
                            if (this.currentMatter.isProjectSale) {
                                result.revokeSharing.keepSharedProjectDocuments = true;
                            }
                            this.documentProductionService.revokePackage(result.revokeSharing, sharedDocumentsSummaryItem.guid)
                                .subscribe(() => {
                                    this.loadSharedDocuments();
                                });
                        }
                    }
                }
            });
        }

    };

    sharingOrDownloadDocumentsDisabled(): boolean {
        return (this.selectedDocuments.length === 0 ||
            this.selectedDocuments.some(selectedDoc => {
                let matterDoc = this.matterDocuments.find(document => document.id === selectedDoc.documentId);
                return matterDoc && matterDoc.isVirusScanPending;
            })
        );
    }

    openFile(row: Document) {
        if (!this.isMaterOpening) {
            if (row.isThirdPartyDocument) {
                this.openThirdPartyFile(row.id);
            } else {
                if (row.isWordOrWordPerfect) {
                    this.openWordForEdit(String(row.id), row.documentName);
                } else {
                    this.openFileInNewWindow(row);
                }
            }
        }
    }
F
    isSharedDocumentOutOfDate(item: SharedDocumentsSummaryItem): boolean {
        if (item.lastSharedTimeStamp && item.documentId && item.sourceDocumentId && this.rows && this.rows.length) {
            let sourceDocument: Document = this.rows.find(doc => doc.id == item.sourceDocumentId);
            if (sourceDocument && sourceDocument.lastUpdatedTimeStamp) {
                return sourceDocument.lastUpdatedTimeStamp > Number(item.lastSharedTimeStamp);
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    saveMatterIfDirtyAndOpenAppointment(){
        if (this.currentMatter.dirty) {
            this.dialogService.confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save').subscribe(res => {
                if (res && res == true) {
                    const matterTab = this.tabService.activeTab as MatterTab;
                    if (matterTab && matterTab.matterComponent) {
                        const subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
                            if (result) {
                                this.openAppointment();
                                subscription.unsubscribe();
                            }
                        });
                    }
                } else {
                    console.log('Matter not saved');
                }
            });
        } else {
            this.openAppointment();
        }
    }

    openAppointment() {
        if (this.currentMatter && this.currentMatter.isDigitalSignPlatformSet()) {

            if (this.filteredRows.filter(row => this.selectedDocuments.find(selectedDocument => selectedDocument.documentId === row.id)).some(doc => !doc.isWordOrWordPerfect && !doc.isPdf)) {
                this.dialogService.confirm('Error', 'Only pdf, word or word perfect documents can be submitted to DocuSign', true).subscribe();
                return;
            }

            const scheduledForList: any[] = [{
                label: '',
                value: 'N/A'
            }];
            this.lawClerkSolicitorList
                .forEach((item) => {
                        scheduledForList.push(
                            {
                                label: item.contactName.surnameLastFullName,
                                value: item.id,
                                initials: item.initials,
                                mobileNumber: item.getMobileNumber()
                            }
                        );
                    }
                );
            this.dialogService.content({
                content: CustomEventModalComponent,
                context: {
                    matter: this.currentMatter,
                    scheduledForList: scheduledForList,
                    selectedDocumentWrappers: this.selectedDocuments,
                    treeModel: this.tree ? this.tree.treeModel : undefined,
                    documentViewMode: this.documentViewMode

                },
                fullScreen: true,
                onFulfillment: (result) => {
                    if (this.tabService.activeTab.isMatter()) {
                        if (result.event) {
                            //console.table(result.event);
                            this.currentMatter.matterEvents.push(result.event);
                        }
                        (this.tabService.activeTab as MatterTab).removeDocuSignInProgressPolling();
                        this.matterPollingService.startDocuSignStatusPolling((this.tabService.activeTab as MatterTab));
                    }
                }
            });
        }
    }

    isDocumentSelectionReadOnly(): boolean {
        return this.signingEnvelope && this.signingEnvelope.isEnvelopeSent()
    }

    isChildToReservedThirdPartyFolder(path : string) : boolean {
      return  path && ((path.indexOf('/') > -1 && this.isReservedThirdPartyFolderName(path.split('/')[0])) || (this.isReservedThirdPartyFolderName(path)))
    }

    isReservedThirdPartyFolderName(folderName: string) {
        return folderName && folderName.trim().toUpperCase() == 'THIRDPARTY';
    }

    isReservedSharedFolderName(subpath : string) {
        return subpath  &&  subpath.toUpperCase().startsWith('SHARED');
    }

    isReservedFolder(name : string,subpath : string) : boolean {
       return this.isReservedThirdPartyFolderName(name) || this.isReservedSharedFolderName(subpath) || this.isChildToReservedThirdPartyFolder(subpath);
    }


    createNode(name: string, children: any[], icon: string, isFolder: boolean, document: Document, level?: number, path?: string, markerDocument?: Document,): any {
        let node: any = {};
        node.name = name;
        node.children = children;
        node.icon = icon
        node.isFolder = isFolder;
        node.document = document;
        node.isEdited = false;
        node.burgerMenuNodeItems = [];
        if (isFolder) {
            node.path = children && children.length > 0 && children[0].document ? children[0].document.subpath : path;
            if(this.isReservedThirdPartyFolderName(name) || this.isChildToReservedThirdPartyFolder(node.path)){
                node.name = this.documentUtility.mapReservedFolderName(name);
            }
            node.markerDocument = markerDocument;
            let menuItemA: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
            menuItemA.text = 'Add SubFolder';
            menuItemA.key = 'ADD'
            node.burgerMenuNodeItems.push(menuItemA);
            if (node.path && node.path.trim() != "") {
                let menuItemR: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
                menuItemR.text = 'Rename';
                menuItemR.key = 'RENAME';
                node.burgerMenuNodeItems.push(menuItemR);
                let menuItemD: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
                menuItemD.text = 'Delete';
                menuItemD.key = 'DELETE';
                menuItemD.isDisabled = node.children && children.length > 0
                node.burgerMenuNodeItems.push(menuItemD);
            }
            node.isNodeEditable = !this.isReservedFolder(name , node.path) && !this.isMaterOpening;
        } else {
            node.isNodeEditable =  !this.isReservedFolder(name , document.subpath) && !this.isMaterOpening;
        }

        if (level != undefined) {
            let width = (this.getFolderStructureTableCellOneWidth() - (level * 20));
            node.tableCellOneCssClass = width;
        }

        return node;
    }


    generateFolderStructure(refreshStructure: boolean): void {
        let folderStructureDocuments = this.isMaterOpening ? this.filteredRows : this.matterDocuments;
        if (folderStructureDocuments.some(d => !!d.subpath)) {
            let children: any[] = [];
            folderStructureDocuments.filter(d => !d.subpath).forEach(item => {
                if (!item.isUdDirMarketTxtFile()) {
                    children.push(this.createNode(item.documentName, [], 'fas fa-file', false, item, 0));
                }
            })
            let markerDocument = folderStructureDocuments.find(d => !d.subpath && d.isUdDirMarketTxtFile());
            let rootNode = this.createNode(this.currentMatter.matterRecordNumber, children, 'fa fa-folder', true, undefined, undefined, undefined, markerDocument);
            rootNode.isExpanded = true;
            let subPathNodeList = folderStructureDocuments.filter(d => !!d.subpath);
            let subPathGroupedBy = _.chain(subPathNodeList).groupBy("subpath").map(function (v, i) {
                return {
                    folder: i.indexOf('/') > -1 ? i.split('/')[i.split('/').length - 1] : i,
                    path: i,
                    level: i.split('/').length,
                    documents: v
                }
            }).sort((a, b) => (a.level > b.level) ? 1 : -1).value();
            subPathGroupedBy.forEach((subPathGroup) => {
                let folders = subPathGroup.path.split('/');
                if (folders) {
                    folders.pop();
                    let currentFolder = rootNode;
                    folders.forEach(folderName => {
                        let availableFolder = currentFolder.children.find(obj => obj.name == this.documentUtility.mapReservedFolderName(folderName));
                        if (availableFolder) {
                            currentFolder = availableFolder;
                        } else {
                            let subPath = subPathGroup.path.substring(0, subPathGroup.path.indexOf(folderName) + folderName.length);
                            let childNode = this.createNode(folderName, [], 'fa fa-folder', true, undefined, undefined, subPath);
                            currentFolder.children.push(childNode);
                            currentFolder = childNode;

                        }
                    });
                    let children: any[] = [];
                    subPathGroup.documents.forEach(item => {
                        if (!item.isUdDirMarketTxtFile()) {
                            children.push(this.createNode(item.documentName, [], 'fas fa-file', false, item, subPathGroup.level));
                        }
                    });
                    let markerDocument = subPathGroup.documents.find(d => d.isUdDirMarketTxtFile());
                    currentFolder.children.push(this.createNode(subPathGroup.folder, children, 'fa fa-folder', true, undefined, undefined, subPathGroup.path, markerDocument));
                    this.updateDeleteBurgerMenuForNode(currentFolder);
                }
            });
            this.refreshFolderStructure(refreshStructure, rootNode);

        } else {
            let children: any[] = [];
            folderStructureDocuments.forEach(item => {
                if (!item.isUdDirMarketTxtFile()) {
                    children.push(this.createNode(item.documentName, [], 'fas fa-file', false, item, 0));
                }
            });
            let markerDocument = folderStructureDocuments.find(d => !d.subpath && d.isUdDirMarketTxtFile());
            let rootNode = this.createNode(this.currentMatter.matterRecordNumber, children, 'fa fa-folder', true, undefined, undefined, undefined, markerDocument);
            this.refreshFolderStructure(refreshStructure, rootNode);
        }

    }


    refreshFolderStructure(refreshStructure: boolean, rootNode: any): void {
        let previousRootNode;
        if (this.documentNodes && this.documentNodes.length > 0) {
            previousRootNode = this.documentNodes[0];
        }
        if (refreshStructure || !previousRootNode) {
            this.documentNodes = [];
            this.documentNodes.push(rootNode);
            setTimeout(() => {
                if (this.tree && this.tree.treeModel && this.tree.treeModel.nodes && this.tree.treeModel.nodes.length) {
                    let rootTreeNode = this.tree.treeModel.getNodeById(this.tree.treeModel.nodes[0].id);
                    if (rootTreeNode) {
                        TREE_ACTIONS.EXPAND(this.tree.treeModel, rootTreeNode, this.tree.event)
                    }
                    if (this.isMaterOpening && this.treeModel) {
                        this.treeModel.expandedNodes.forEach(expandedNode => {
                            let currentTreeNode = this.tree.treeModel.getNodeBy(node => !!node.data && !!expandedNode.data && !!expandedNode.data.path && node.data.path == expandedNode.data.path);
                            if (currentTreeNode) {
                                TREE_ACTIONS.EXPAND(this.tree.treeModel, currentTreeNode, this.tree.event)
                            }

                        })
                    }
                }
            }, 100)
        } else {
            this.applyNodeDiff(previousRootNode, rootNode);
            if (this.tree) {
                this.tree.treeModel.update();
            }
        }
    }


    onMoveNode($event) {
        if ($event.node.isFolder) {
            this.updateSubFolderForFolder($event.node, $event.to.parent.path);
        } else {
            this.updateSubFolderForDocument($event.node, $event.to.parent.path)
        }
        this.updateDeleteBurgerMenuForNode($event.to.parent);
        this.updateDeleteBurgerMenuForNode($event.from.parent);
    }

    updateDeleteBurgerMenuForNode(node: any): void {
        if (node && node.burgerMenuNodeItems) {
            let burgerMenuNodeItem = node.burgerMenuNodeItems.find(item => item.key == 'DELETE');
            if (burgerMenuNodeItem) {
                burgerMenuNodeItem.isDisabled = node.children && node.children.length > 0
            }
        }
    }

    updateSubFolderForDocument(nodeDocument: any, subPath: string): void {
        if (nodeDocument && nodeDocument.document) {
            nodeDocument.document.subpath = subPath ? subPath : '';
            let width = this.getFolderStructureTableCellOneWidth() - ((subPath ? subPath.split('/').length : 0) * 20);
            nodeDocument.tableCellOneCssClass = width;
            let document = this.matterDocuments.find(rw => rw.id == nodeDocument.document.id);
            if (document) {
                document.subpath = nodeDocument.document.subpath;
                this.documentProductionService.updateDocumentSubFolder(document, this.currentMatter);
            }
        }
    }

    updateSubFolderForFolder(nodeDocument: any, subPath: string): void {
        nodeDocument.path = subPath && subPath.trim() != "" ? (subPath + '/' + nodeDocument.name) : nodeDocument.name;
        if (nodeDocument.markerDocument) {
            nodeDocument.markerDocument.subpath = nodeDocument.path;
            this.documentProductionService.updateDocumentSubFolder(nodeDocument.markerDocument, this.currentMatter);
        }
        this.updateChildrenForSubFolder(nodeDocument);
    }

    updateChildrenForSubFolder(nodeDocument: any): void {
        if (nodeDocument) {
            if (nodeDocument.markerDocument && nodeDocument.markerDocument.subpath !== nodeDocument.path) {
                nodeDocument.markerDocument.subpath = nodeDocument.path;
                this.documentProductionService.updateDocumentSubFolder(nodeDocument.markerDocument, this.currentMatter);
            }
            if (nodeDocument.children && nodeDocument.children.length) {
                nodeDocument.children.forEach(item => {
                    if (item.isFolder) {
                        this.updateSubFolderForFolder(item, nodeDocument.path);
                    } else {
                        this.updateSubFolderForDocument(item, nodeDocument.path);
                    }
                });
            }
        }
    }

    renameSubFolder(node: any, event): void {
        if (!!node.data.isEdited) {
            node.data.isEdited = false;
            let message;
            if (!node.data.name || node.data.name.trim() == "") {
                message = "You need to provide Folder name"
            } else if (!Utils.isValidWindowsFileName(node.data.name)) {
                message = "Folder name provided is invalid \n Folder name can't contain any of following characters '\\ / : * ? \" < > |'"
            } else if (node.parent && node.parent.data && node.parent.data.children && node.parent.data.children.some(item => !!item.isFolder && item.name.toUpperCase() == node.data.name.toUpperCase() && node.data.id != item.id)) {
                message = "The destination already contains a folder named " + "'" + node.data.name + "'."
            } else if (node.data.path && node.data.path.indexOf('/') < 0 && this.isReservedSharedFolderName(node.data.name)) {
                message = "Folder name provided is invalid \n Folder name cannot start with reserved keywords 'Shared'"
            }
            else if (node.data.path && node.data.path.indexOf('/') < 0 && this.isReservedThirdPartyFolderName(node.data.name)) {
                message = "Folder name provided is invalid \n Folder name can't be reserved keyword 'ThirdParty'"
            }
            if (message) {
                let hideCancelBtn: boolean = node.data.children && node.data.children.length > 0;
                this.dialogService.confirm('Error', message, hideCancelBtn, 'Ok', 'Delete').subscribe(
                    (res) => {
                        node.data.name = node.data.nameCopy;
                        if (res) {
                            node.data.isEdited = true;
                            setTimeout(() => {
                                jQuery("#nodeId_" + node.id).focus();
                            })
                        } else if (!node.data.name || node.data.name.trim() == "") {
                            this.removeSubFolderNode(node, event);
                        }
                    }
                )
            } else {
                if (node.data.path) {
                    if (node.data.path.indexOf('/') > -1) {
                        node.data.path = node.data.path.substring(0, node.data.path.lastIndexOf('/')) + '/' + node.data.name;
                    } else {
                        node.data.path = node.data.name;
                    }
                }
                if (node.data.isFolder && !node.data.markerDocument && (!node.data.children || node.data.children.length == 0)) {
                    this.createMakerFileForNewSubFolder(node.data);
                } else {
                    this.updateChildrenForSubFolder(node.data);
                }

            }
        }
    }

    createMakerFileForNewSubFolder(node: any): void {
        let content = "";
        let blob = new Blob([content], {
            type: "text/plain;charset=utf-8"
        });
        let udDirMarkerTxt = new File([blob], DOCUMENT_UD_DIR_MARKER_FILE_NAME);
        let formData = new FormData();
        formData.append('uploadedFile', udDirMarkerTxt, DOCUMENT_UD_DIR_MARKER_FILE_NAME);
        formData.append('subpath', node.path);
        let uploadUrl = matterApi.uploadDocument.replace('{matterId}', '' + this.currentMatter.id);
        this.httpClient.uploadFiles(uploadUrl, formData).subscribe((res: any) => {
            if (res && res['MatterDocument']) {
                node.markerDocument = new Document(res['MatterDocument']);
            }
        });
    }


    addSubFolder(selectedNode: any, event): void {
        let path = selectedNode.data.path ? selectedNode.data.path + '/NewFolder' : 'NewFolder';
        let node = this.createNode('', [], 'fa fa-folder', true, undefined, undefined, path);
        node.isEdited = true;
        selectedNode.data.children.push(node);
        this.updateDeleteBurgerMenuForNode(selectedNode.data);
        if (this.tree) {
            this.tree.treeModel.update();
            let updateNode = this.tree.treeModel.getNodeById(selectedNode.id);
            TREE_ACTIONS.EXPAND(this.tree.treeModel, updateNode, event);
            let addedNode = this.tree.treeModel.getNodeById(node.id);
            if (addedNode) {
                addedNode.setIsSelected(true);

            }
        }
        setTimeout(() => {
            jQuery("#nodeId_" + node.id).focus();
        })
    }

    removeSubFolder(selectedNode: any, event?): void {
        if (selectedNode.data.children.length == 0) {
            this.dialogService.confirm('Confirmation', 'Are you sure you would like to delete this subFolder?', false, 'Delete').subscribe(res => {
                if (res) {
                    this.removeSubFolderNode(selectedNode, event);
                }
            });
        }
    }

    removeSubFolderNode(selectedNode: any, event?): void {
        let parentId = selectedNode.parent.id;
        let childIndex = selectedNode.parent.data.children.findIndex(item => item.id == selectedNode.id);
        if (childIndex > -1) {
            if (selectedNode.data && selectedNode.data.markerDocument) {
                this.documentProductionService.deleteFile(this.selectedMatterId, '' + selectedNode.data.markerDocument.id).subscribe(res => {
                });
            }
            selectedNode.parent.data.children.splice(childIndex, 1);
            if (this.tree) {
                this.tree.treeModel.update();
                let parentNode: TreeNode = this.tree.treeModel.getNodeById(parentId);
                if (parentNode) {
                    parentNode.setIsSelected(true);
                    this.updateDeleteBurgerMenuForNode(parentNode.data);
                }
            }
        }
    }

    selectNode(selectedNode: any, event): void {
        if (selectedNode.data && this.tree && (!selectedNode.data.name || selectedNode.data.name.trim() == "")) {
            TREE_ACTIONS.SELECT(this.tree.treeModel, selectedNode, event);
        }

    }


    applyNodeDiff(currentNode: any, updatedNode: any): void {
        if (updatedNode.children) {
            updatedNode.children.forEach(node => {
                if (node.isFolder) {
                    let isChildFound = currentNode.children.find(cnc => cnc.isFolder && ((cnc.name == node.name) || (cnc.markerDocument && node.markerDocument && cnc.markerDocument.id == node.markerDocument.id)));
                    if (isChildFound) {
                        isChildFound.name = node.name;
                        isChildFound.path = node.path;
                        isChildFound.isNodeEditable = node.isNodeEditable
                        this.applyNodeDiff(isChildFound, node);
                    } else {
                        currentNode.children.push(node);
                        this.updateDeleteBurgerMenuForNode(currentNode);

                    }
                } else if (node.document) {
                    let isChildFound = currentNode.children.find(cnc => cnc.document && cnc.document.id == node.document.id);
                    if (isChildFound) {
                        isChildFound.name = node.document.name;
                        isChildFound.document = node.document;
                        isChildFound.tableCellOneCssClass = node.tableCellOneCssClass;
                        isChildFound.isNodeEditable = node.isNodeEditable;
                    } else {
                        currentNode.children.unshift(node);
                        this.updateDeleteBurgerMenuForNode(currentNode);
                    }
                }
            });

            let deletedNodes = currentNode.children.filter(item => (item.isFolder && updatedNode.children.filter(unc => unc.isFolder).map(uc => {
                return uc.name
            }).indexOf(item.name) < 0) || (!item.isFolder && item.document && updatedNode.children.filter(unc => !unc.isFolder && unc.document).map(uc => {
                return uc.document.id
            }).indexOf(item.document.id) < 0))
            deletedNodes.forEach(dn => {
                let index = currentNode.children.findIndex(cn => cn.id == dn.id);
                currentNode.children.splice(index, 1)
            })

        }
    }

    applyFilter() {
        this.searchSub$.next(this.searchText)
    }


    isChildrenVisible(children: any[]): boolean {
        if (children.some(node => node.document && this.filteredRows.some(item => item.id == node.document.id))) {
            return true
        } else if (children.some(node => node.isFolder)) {
            let isChildrenVisible = false;
            let childrenList = children.filter(item => item.isFolder);
            for (let i = 0; i < childrenList.length; i++) {
                if (this.isChildrenVisible(childrenList[i].children)) {
                    isChildrenVisible = true;
                    break;
                }
            }
            return isChildrenVisible;
        } else {
            return false;
        }
    }

    getFolderStructureTableCellOneWidth(): number {
        if(jQuery('bs-modal-container').length > 0){
            let maxWidthCss = jQuery(jQuery('bs-modal-container .folder-table .table-dp-cell-1')[0]).css('max-width');
            return maxWidthCss ? parseInt(maxWidthCss, 10) : 285;
        }
        if (jQuery('.table-dp-cell-1') && jQuery('.table-dp-cell-1').length) {
            let maxWidthCss = jQuery(jQuery('.table-dp-cell-1')[0]).css('max-width');
            return maxWidthCss ? parseInt(maxWidthCss, 10) : 285;
        }else if(jQuery('.table-dp-cell-1-alt').length > 0) {
            let maxWidthCss = jQuery(jQuery('.table-dp-cell-1-alt')[0]).css('max-width')
            return maxWidthCss ? parseInt(maxWidthCss, 10) : 285
        }
        else {
                return 285;
        }

    }

    get documentViewMode() : string {
        return this.userConfigurationService.documentViewModeForMatterDocument;
    }

    set documentViewMode(value: string) {
        this.userConfigurationService.documentViewModeForMatterDocument = value;
    }

    updateDocumentViewMode() : void {
        this.userConfigurationService.updateUserConfiguration();
    }

    logDuplicateSharingIfExist(requestedSharedPackage: SharedDocumentsPackage, updatedSharedPackage : SharedDocumentsPackage) : void {
        if(updatedSharedPackage && updatedSharedPackage.documents && updatedSharedPackage.documents.length
            && requestedSharedPackage && requestedSharedPackage.documents && requestedSharedPackage.documents.length
            && this.sharedDocumentsPackages && this.sharedDocumentsPackages.length){
            let requestedSharedDocumentsIds: number[] = requestedSharedPackage.documents.map(doc=>doc.id);
            //Only test duplicates for the newly shared files to avoid creating duplicate warnings
            let updatedSharedDocuments = updatedSharedPackage.documents.filter(doc => !!requestedSharedDocumentsIds.find(id=>id == doc.sourceDocumentId));
            updatedSharedDocuments.forEach((document)=>{
                let sharingWith = updatedSharedPackage.recipientName;
                if(this.isDuplicateExists(document, sharingWith)){
                    //Since the output shared files are always PDFs, we need to log the original file name
                    let originalDoc = requestedSharedPackage.documents.find(doc=>doc.id == document.sourceDocumentId);
                    if(originalDoc){
                        this.globalLogger.logPreviouslySharedDocument(document, originalDoc.name, sharingWith,  this.currentMatter.id);
                    }
                }
            })
        }
    }

    isDuplicateExists(newSharedDocument: SharedDocument, recipientName: string) : boolean {
        let existingSharedDocumentsPackage: SharedDocumentsPackage = this.sharedDocumentsPackages.find(sharingPackage=>sharingPackage.recipientName == recipientName);
        if(existingSharedDocumentsPackage){
            return existingSharedDocumentsPackage.documents.some(doc=>
                doc.name && newSharedDocument.name
                && doc.name.toLowerCase() == newSharedDocument.name.toLowerCase()
                && doc.sourceDocumentId != newSharedDocument.sourceDocumentId
            );
        } else {
            return false;
        }
    }

    updateSharedDocumentPackages(updatedshareDocumentPackage: SharedDocumentsPackage) : void {
        if(!this.sharedDocumentsPackages){
            this.sharedDocumentsPackages = [];
        }
        let existingIndex =  this.sharedDocumentsPackages.findIndex(sdp => sdp.id == updatedshareDocumentPackage.id);
        if(existingIndex != -1){
            this.sharedDocumentsPackages[existingIndex] = updatedshareDocumentPackage;
        } else {
            this.sharedDocumentsPackages.push(updatedshareDocumentPackage);
        }
    }

    get signingPlatformLabel() : string {
        if(this.currentMatter && (this.currentMatter.isDigitalSignPlatformSet())){
            return DigitalSignaturePlatformLabel[this.currentMatter.digitalSignaturePlatform];
        }
        return '';
    }

    isMatterDisabled() : boolean {
        return MatterUtil.isMatterDisabled(this.currentMatter, this.matterTab, this.authorizationService, this.tabService);
    }

}

