import {ErrorService} from '../../shared/error-handling/error-service';
import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';


class AccountingModalContext extends BSModalContext {

}
@Component({
    selector: 'dp-accounting-modal-content',
    templateUrl : 'accounting.modal.component.html',
    styleUrls: ['./accounting.modal.scss'],
    providers   : [ErrorService]
})
export class AccountingModalComponent implements OnInit, AfterViewInit, ModalComponent<AccountingModalContext> {
    context : AccountingModalContext;
    @ViewChild('accountingModalClose') accountingModalClose!: ElementRef<HTMLButtonElement>;
    constructor(public dialog : DialogRef<AccountingModalContext>) {
        this.context = dialog.context;
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.accountingModalClose.nativeElement.focus();
    }

    goToLink(url: string) {
        this.dialog.close();
        window.open(url, "_blank");
    }

    close(): void {
        this.dialog.close();

    }


    beforeDismiss(): boolean {
        return true;
    }

    beforeClose(): boolean {
        return false;
    }

}
