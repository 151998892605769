import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Borrower} from '../telus/stewart-assyst-mortgage-instruction-data';

export class FctLenderChanges extends BaseEntity{

    lenderRequestId : number;
    lenderChanges : LenderChange[] = [];
    lenderDocuments : boolean;

    constructor(t?: FctLenderChanges) {
        super(t);

        if(t)
        {
            this.lenderChanges = [];
            if (Array.isArray(t.lenderChanges)) {
                for (let i: number = 0; i < t.lenderChanges.length; ++i) {
                    this.lenderChanges[i] = new LenderChange(t.lenderChanges[i]);
                }

            }
        }

    }
}

export class LenderChange extends BaseEntity{

    id : string;
    tag : string;
    language : string;
    newValue : string;
    previousValue : string;
    actionable : boolean;
    label : string;
    status : string = 'BLANK';
    jsonPath : string;
    matterValue : string;
    matterXpath : string;
    matterObjectType : string;
    matterValueList : any[] = [];
    updateRequired : boolean = false;
    matterParticipantId : number;
    borrower: Borrower;


    constructor(t?: LenderChange) {
        super(t);

    }

    isLenderChangeList() : boolean {
        return (this.matterObjectType == 'List');
    }

    isChangeStatusAccept() : boolean {
        return (this.status == 'ACCEPT');
    }


    isStatusBlank() : boolean{
        return (this.status  == 'BLANK');
    }

    isNewValueBlank() : boolean{
        return (this.newValue  == '');
    }


    isPreviousValueBlank() : boolean{
        return (this.previousValue  == '');
    }

    isMortgagorChange() : boolean {
        return (this.tag && this.tag.indexOf('Mortgagors')> -1);
    }

    isPropertyChanged() : boolean {
        return (this.tag == '/Properties/Property');
    }

    isGuarantorChange() : boolean {
        return (this.tag && this.tag.indexOf('Guarantors')> -1);
    }

    isMortgagePriorityChanged() : boolean {
        return (this.tag == '/Properties/Property/MortgagePriority')
    }

    isLegalDescriptionChanged() : boolean {
        return (this.tag == '/Properties/Property/LegalDescription')
    }

    isMortgagorPhoneChanged() : boolean {
        return (this.tag == '/Mortgagors/Mortgagor/Phone' || this.tag == '/Guarantors/Guarantor/Phone');
    }

    isMortgagorBussinessPhoneChanged() : boolean {
        return (this.tag == '/Mortgagors/Mortgagor/BusinessPhone' || this.tag == '/Guarantors/Guarantor/BusinessPhone');
    }

    isGuarantorAddressChanged() : boolean {
        return (this.tag && this.tag.indexOf('/Guarantors/Guarantor/Address/')> -1);
    }

    isMortgagorSigningOfficerChanged() : boolean {
        if (this.jsonPath != null) {
            return (this.tag && (this.jsonPath.indexOf('FirstName') > -1 || this.jsonPath.indexOf('MiddleName') > -1 || this.jsonPath.indexOf('LastName') > -1));
        } else {
            return false;
        }
    }

    isEstateTypeChanged() : boolean {
        return (this.tag == '/Properties/Property/EstateType')
    }

    isMortgagorAddressChanged() : boolean {
        return (this.tag && this.tag.indexOf('/Mortgagors/Mortgagor/Address') > -1)
    }

     isMortgagorContactChanged() : boolean {
        return (this.jsonPath && this.jsonPath.indexOf('$.Mortgagors') > -1 )
    }

    //based on agreement with FCT, use jsonPath instead of tag
    //"jsonPath": "$.Guarantors[?(@.id ==1025)]" - for remove one guarantor
    //"jsonPath":"$.Guarantors" -- for bulk remove all the guarantors (more than one at a time)
    isGuarantorsContactChanged() : boolean {
        return (this.jsonPath && this.jsonPath.indexOf('$.Guarantors') > -1 )
    }

    isChangeForNew() : boolean {
        return (!this.newValue  && !this.previousValue);
    }

    isChangeForDelete() : boolean {
        return (!this.newValue  && this.previousValue != undefined);
    }

    isPropertyAddressChanged() : boolean {
        return (this.tag && this.tag.indexOf('/Properties/Property/Address') > -1)
    }

    isPropertyIdentificationNumberChanged() : boolean {
        return ((this.tag && this.tag.indexOf('/Properties/Property/PropertyIdentificationNumbers/Number/PropertyIdentificationNumber') > -1) || (this.jsonPath && this.jsonPath.indexOf('PropertyIdentificationNumbers.PropertyIdentificationNumber.Number') > -1)
        )
    }

    isClosingDateChanged() : boolean {
        return (this.tag && this.tag.indexOf('/Mortgage/ClosingDate') > -1)
    }

    isMortgagorFirstNameChanged() : boolean {
        return (this.tag && (this.tag.indexOf('/Mortgagors/Mortgagor/FirstName') > -1 || this.tag.indexOf('/Guarantors/Guarantor/FirstName') > -1));
    }

    isMortgagorMiddleNameChanged() : boolean {
        return (this.tag && (this.tag.indexOf('/Mortgagors/Mortgagor/MiddleName') > -1 || this.tag.indexOf('/Guarantors/Guarantor/MiddleName') > -1));
    }

    isMortgagorOrgnaizationChanged() : boolean {
        return (this.tag && (this.tag.indexOf('/Mortgagors/Mortgagor/CompanyName') > -1 || this.tag.indexOf('/Guarantors/Guarantor/CompanyName') > -1));
    }

    isMortgagorLastNameChanged() : boolean {
        return (this.tag && (this.tag.indexOf('/Mortgagors/Mortgagor/LastName') > -1 || this.tag.indexOf('/Guarantors/Guarantor/LastName') > -1));
    }

    isMortgagorContactFirstNameChanged() : boolean {
        return (this.tag && (this.jsonPath.indexOf('/Mortgagors/Mortgagor/Contact/FirstName') > -1 || this.tag.indexOf('/Guarantors/Guarantor/Contact/FirstName') > -1))
    }

    isMortgagorContactLastNameChanged() : boolean {
        return (this.tag && (this.tag.indexOf('/Mortgagors/Mortgagor/Contact/LastName') > -1 || this.tag.indexOf('/Guarantors/Guarantor/Contact/LastName') > -1))
    }

}

export const LenderChnagesXpathConfig = {
    xPathConfigs: [
        {lenderTag: '/Mortgage/ClosingDate', matterXPath : '$.matterCloseDate' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Properties/Property/Address/UnitNumber', matterXPath : 'matterProperties[0].address.parsedUnitNo' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Properties/Property/Address/StreetNumber', matterXPath : 'matterProperties[0].address.parsedStreetNumber' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Properties/Property/Address/StreetAddress1', matterXPath : 'matterProperties[0].address.parsedStreetName' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Properties/Property/Address/StreetAddress2', matterXPath : 'matterProperties[0].address.parsedStreetAddress' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Properties/Property/Address/Country', matterXPath : 'matterProperties[0].address.country' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Properties/Property/Address/City', matterXPath : 'matterProperties[0].address.city' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Properties/Property/Address/PostalCode', matterXPath : 'matterProperties[0].address.postalCode' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Properties/Property/LegalDescription', matterXPath : 'matterProperties[0].propertyTaxesSummary' , updateRequired : false , objectType : 'Object' , callBackFunction : 'getPropertyDescription'},
        {lenderTag: '/Properties/Property/EstateType', matterXPath : 'matterProperties[0].interestEstate' , updateRequired : false , objectType : 'Object'},
        {lenderTag: '/Properties/Property/PropertyIdentificationNumbers/Number/PropertyIdentificationNumber', matterXPath : '$.matterProperties[*].pin' , updateRequired : false , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Address/UnitNumber', matterXPath : 'matterContactInfo.preClosingAddress.parsedUnitNo' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Address/StreetNumber', matterXPath : 'matterContactInfo.preClosingAddress.parsedStreetNumber' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Address/StreetAddress1', matterXPath : 'matterContactInfo.preClosingAddress.parsedStreetName' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Address/StreetAddress2', matterXPath : 'matterContactInfo.preClosingAddress.parsedStreetAddress' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Address/Country', matterXPath : 'matterContactInfo.preClosingAddress.country' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Address/City', matterXPath : 'matterContactInfo.preClosingAddress.city' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Address/PostalCode', matterXPath : 'matterContactInfo.preClosingAddress.postalCode' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Properties/Property/OtherEstateTypeDescription', matterXPath : 'matterProperties[0].interestEstate' , updateRequired : false , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/FirstName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.firstName' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/MiddleName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.middleName' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/LastName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.lastName' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Phone', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.telephone[*]' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/BusinessPhone', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.telephone[*]' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Contact/FirstName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer1,$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer2', updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Contact/MiddleName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer1,$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer2', updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/Contact/LastName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer1,$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer2', updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/FirstName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.firstName' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/MiddleName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.middleName' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/LastName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.lastName' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Phone', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.telephone[*]' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Contact/FirstName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer1,$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer2', updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Contact/LastName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer1,$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer2', updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Address/UnitNumber', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.serviceAddress.parsedUnitNo' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Address/StreetNumber', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.serviceAddress.parsedStreetNumber' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Address/StreetAddress1', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.serviceAddress.parsedStreetName' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Address/StreetAddress2', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.serviceAddress.parsedStreetAddress' , updateRequired : true, objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Address/Country', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.serviceAddress.country' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Address/City', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.serviceAddress.city' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Address/PostalCode', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.serviceAddress.postalCode' , updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Contact/FirstName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer1,$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer2', updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Contact/MiddleName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer1,$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer2', updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/Contact/LastName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer1,$.matterParticipants[?(@.matterParticipantId == id)].contact.signingOfficer2', updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Mortgagors/Mortgagor/CompanyName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.organizationName', updateRequired : true , objectType : 'Object'},
        {lenderTag: '/Guarantors/Guarantor/CompanyName', matterXPath : '$.matterParticipants[?(@.matterParticipantId == id)].contact.organizationName', updateRequired : true , objectType : 'Object'}

    ]
}
