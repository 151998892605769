export class PrecedentTypes {
    static readonly MORTGAGE: string = 'MORTGAGE';
    static readonly REQUISITION: string = 'REQUISITION';
    static readonly PURCHASE_UNDERTAKING: string = 'PURCHASE_UNDERTAKING';
    static readonly REPORT_TO_PURCHASER: string = 'REPORT_TO_PURCHASER';
    static readonly EREG_ESCROW: string = 'EREG_ESCROW';
    static readonly EREG_STATEMENT_PROVISIONS: string = 'EREG_STATEMENT_PROVISIONS';
    static readonly STATEMENT_ADJUSTMENT_FOOTER ='STATEMENT_ADJUSTMENT_FOOTER';
    static readonly MESSAGE: string = 'MESSAGE';
    static readonly CIRF: string = 'CIRF';
    static readonly NOTIFICATION: string = 'NOTIFICATION';


}
