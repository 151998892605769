import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {GeneralLedgerAccountExplanation} from './general-ledger-account-explanation';
import {GeneralLedgerAccountCode} from './general-ledger-account-code';
import {CosmolexExpenseCode} from './cosmolex-expense-code';
import {EsiLawAccountCode} from './esiLaw-account-code';
import {EsiLawAccountExplanation} from './esiLaw-account-explanation';

export class AccountNationalSetting extends  BaseEntity {
    id: number;
    generalLedgerAccountCodes : GeneralLedgerAccountCode[] = [];
    generalLedgerAccountExplanations : GeneralLedgerAccountExplanation[] = [];
    cosmolexExpenseCodes : CosmolexExpenseCode[]=[];
    accountingSoftware : string;
    hstOnComplianceFees: string;
    selectMatterNumberType : string;
    esiLawAccountCodes: EsiLawAccountCode[]=[];
    esiLawAccountExplanations: EsiLawAccountExplanation[]=[];


    constructor(accountNationalSetting? : AccountNationalSetting) {
        super(accountNationalSetting);

        if(accountNationalSetting) {
            if(Array.isArray(accountNationalSetting.generalLedgerAccountCodes)) {
                for(let i : number = 0; i < accountNationalSetting.generalLedgerAccountCodes.length; ++i) {
                    this.generalLedgerAccountCodes[i] = new GeneralLedgerAccountCode(accountNationalSetting.generalLedgerAccountCodes[i]);

                }
            }
            if(Array.isArray(accountNationalSetting.generalLedgerAccountExplanations)) {
                for(let i : number = 0; i < accountNationalSetting.generalLedgerAccountExplanations.length; ++i) {
                    this.generalLedgerAccountExplanations[i] = new GeneralLedgerAccountExplanation(accountNationalSetting.generalLedgerAccountExplanations[i]);
                    let generalLedgerAccountCode =  this.generalLedgerAccountCodes.find(code => code.id == this.generalLedgerAccountExplanations[i].generalLedgerAccountCodeId);
                    if(generalLedgerAccountCode){
                        this.generalLedgerAccountExplanations[i].accountNumber = generalLedgerAccountCode.accountNumber;
                    }


                }
            }
            if(Array.isArray(accountNationalSetting.cosmolexExpenseCodes)) {
                for(let i : number = 0; i < accountNationalSetting.cosmolexExpenseCodes.length; ++i) {
                    this.cosmolexExpenseCodes[i] = new CosmolexExpenseCode(accountNationalSetting.cosmolexExpenseCodes[i]);

                }
            }
            if(Array.isArray(accountNationalSetting.esiLawAccountCodes)) {
                for(let i : number = 0; i < accountNationalSetting.esiLawAccountCodes.length; ++i) {
                    this.esiLawAccountCodes[i] = new EsiLawAccountCode(accountNationalSetting.esiLawAccountCodes[i]);

                }
            }
            if(Array.isArray(accountNationalSetting.esiLawAccountExplanations)) {
                for(let i : number = 0; i < accountNationalSetting.esiLawAccountExplanations.length; ++i) {
                    this.esiLawAccountExplanations[i] = new EsiLawAccountExplanation(accountNationalSetting.esiLawAccountExplanations[i]);
                    let esiLawAccountCode =  this.esiLawAccountCodes.find(code => code.id == this.esiLawAccountExplanations[i].esiLawAccountCodeId);
                    if(esiLawAccountCode){
                        this.esiLawAccountExplanations[i].accountNumber = esiLawAccountCode.accountNumber;
                    }
                }
            }
        }
    }
}
