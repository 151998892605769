import {Directive, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
    selector  : '[multi-email-validator]',
    providers : [
        {provide : NG_VALIDATORS, useExisting : forwardRef(() => MultiEmailValidatorDirective), multi : true}
    ]
})
export class MultiEmailValidatorDirective implements Validator {
    /**
     * This method is called whenever a keyin happens to the corresponding textbox.
     * if returns null, validation succeeds and the textbox is set to valid status.
     * If returns something else, validation fails. The mail icon for the textbox can
     * be shown/hidden based on the textbox status.
     *
     * Example to show/hide mail icon:
     * <input
     *      ... ...
     *      type="text"
     *      #email="ngModel"
     *      multi-email-validator>
     * <i class="fa fa-envelope [ngClass]="{'hide': email.invalid || email.pristine}"></i>
     */
    validate(control : AbstractControl) : {[key : string] : any} {
        if(!control.value) {
            return null;
        }

        const pattern : RegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        let errorMessages : string[] = [];
        let emails: string[] = control.value.split(';');
        let hasValidEmail: boolean = false;

        for (let email of emails) {
            email = email.trim();
            if (email !== "") {
                if (!pattern.test(email)) {
                    errorMessages.push(`${email} is invalid`);
                } else {
                    hasValidEmail = true;
                }
            }
        }

        if(errorMessages.length == 0 || hasValidEmail) {
            return null; // Valid
        } else {
            return {
                email : {
                    valid : false,
                    errors : errorMessages
                }
            };
        }
    }
}
