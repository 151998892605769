import {ErrorService} from '../../shared/error-handling/error-service';
import {Component, OnInit} from '@angular/core';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {LandingWidgetsService} from '../../../../projects/landing-widgets/src/lib/landing-widgets.service';
import {DppmCookieService} from '../dppm-cookie.service';
import moment from 'moment';
import {AppConfig} from '../../shared-main/app-configuration';
import {CloseGuard, DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';


class WelcomePagerModalContext extends BSModalContext {

}
@Component({
               selector    : 'dp-welcome-page-modal',
               templateUrl : 'welcome-page.modal.component.html',
    styleUrls: ['./welcome-page.modal.scss'],
               providers   : [ErrorService]
           })
@FocusFirstElementDecorator()
export class WelcomePageModalComponent implements OnInit,   ModalComponent<WelcomePagerModalContext> , CloseGuard {
    context : WelcomePagerModalContext;
    contentHtml : any;
    contButtonDisabled : boolean = true;
    maxButtonDisabledCounter : number = 0;
    scheduler: any;
    constructor(public dialog : DialogRef<WelcomePagerModalContext> , private service: LandingWidgetsService, private cookieService : DppmCookieService,public appConfig: AppConfig,
    ) {
        this.context = dialog.context;
        dialog.setCloseGuard(this);
    }

    ngOnInit() {
        if(!this.service.getIsConveyancingUser()){
         if(this.cookieService.getMarketingVideoWatchTimeStamp() && this.appConfig.replayMarketingVideoDurationInHours > 0){
             const duration = moment.duration(  moment(Date.now()).diff(moment(Number(this.cookieService.getMarketingVideoWatchTimeStamp()))));
             const hours = duration.asHours();
             if(!isNaN(Number(hours)) && hours < this.appConfig.replayMarketingVideoDurationInHours){
                 this.contButtonDisabled = false;
             }
         }
         else if(this.appConfig.replayMarketingVideoDurationInHours == 0){
             this.contButtonDisabled = false;
         }
         if(this.contButtonDisabled) {
             this.scheduler = setInterval(() => {
                 this.maxButtonDisabledCounter++;
                 if (!this.contButtonDisabled || this.maxButtonDisabledCounter > 6) {
                     this.cookieService.setMarketingVideoWatchTimeStamp();
                     this.contButtonDisabled = false;
                     clearInterval(this.scheduler);
                 }
             }, 5000);
         }
        }
        else{
            this.contButtonDisabled = false;
        }

    }

    close() : void {
        this.dialog.close();
    }

    enableWPContinueBtn(isVideoCompleted : boolean) : void {
        if(isVideoCompleted && !this.service.getIsConveyancingUser()){
            this.contButtonDisabled = false;
            this.cookieService.setMarketingVideoWatchTimeStamp();
            clearInterval(this.scheduler);
        }
    }

    beforeDismiss(): boolean {
        return true;
    }

    beforeClose(): boolean {
        return false;
    }

}
