import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class TeranetAddress extends BaseEntity {
    streetNumber: string;
    streetName: string;
    streetSuffix: string;
    unitType: string;
    unitNo: string;
    adminArea: string;

    public getFullAddress(): string {
        return (this.streetNumber ? this.streetNumber : '')
        + (this.streetName ? " " + this.streetName : '')
        + (this.unitType ? ", " + this.unitType : '')
        + (this.unitNo ? " " + this.unitNo : '')
        + (this.adminArea ? ", " + this.adminArea : '');
    }

    public isAddressEmpty(): boolean {
        return ((!this.streetNumber || this.streetNumber.trim().length === 0)
            && (!this.streetName || this.streetName.trim().length === 0)
            && (!this.unitType || this.unitType.trim().length === 0)
            && (!this.unitNo || this.unitNo.trim().length === 0)
            && (!this.adminArea || this.adminArea.trim().length === 0));
    }
}
