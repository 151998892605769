import {Injectable} from '@angular/core';
import {Cirf} from './cirf';
import {MatterParticipant} from '../matter-participant';
import {CirfMatterData} from './cirf-matter-data';
import {Matter} from '../matter';
import {DocumentProfile} from '../../../admin/document-profile/document-profile';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {DocumentProfileService} from '../../../admin/document-profile/document-profile-edit/document-profile.service';
import {Precedent} from '../precedent';
import {PrecedentTypes} from '../mortgage-precedent-type';
import {PrecedentService} from '../../mortgages/mortgage/precedent/precedent.service';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {Utils} from '../index';
import {AppConfig} from '../../../shared-main/app-configuration';
import {ApplicationError} from '../../../core';
import {EmailFieldService, EmailKeys, EmailTypes} from '../../../shared-main/email-field/email-field-service';
import {OpportunitiesService} from '../../../opportunities/opportunities.service';
import {CirfConfigService} from '../../../shared-main/cirf-config/cirf-config.service';

@Injectable()
export class CirfHelperService {
    constructor(public documentProfileService: DocumentProfileService,
                public precedentService: PrecedentService,
                public dialogService: DialogService,
                public appConfig: AppConfig ,
                public emailFieldService : EmailFieldService,
                public opportunitiesService: OpportunitiesService) {

    }

    getPrimaryClient(matter: Matter) : MatterParticipant {
        return matter.mainClients && matter.mainClients.length ? matter.mainClients.find(mp=>mp.primary) : null;
    }


    getDocumentProfileLawFirmName(documentProfile: DocumentProfile, defaultDocumentProfile: DocumentProfile): string {
        if (documentProfile && documentProfile.firmDocumentProfile && documentProfile.firmDocumentProfile.sameAsDefaultProfileFlag
            && defaultDocumentProfile && defaultDocumentProfile.firmDocumentProfile) {
            return defaultDocumentProfile.firmDocumentProfile.firmName;
        }
        else if (documentProfile && documentProfile.firmDocumentProfile) {
            return documentProfile.firmDocumentProfile.firmName;
        }
        else {
            return undefined;
        }
    }

    async getDocumentProfile(matter: Matter) : Promise<DocumentProfile>{
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        return await this.documentProfileService.getById(matter.documentProfileId, accountId, false, matter).toPromise();
    }

    async getDefaultDocumentProfile() : Promise<DocumentProfile>{
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        return await this.documentProfileService.getDefaultProfileForAccountFromCache(accountId).toPromise();
    }

    async updateCirfWithMatterDataAndSave(matter: Matter, cirf: Cirf, participant?: MatterParticipant): Promise<Cirf> {
        if (cirf) {

            // old workflow
            cirf = await this.updateCirfWithMatterData(matter, cirf, participant);
            try {
                const cirfUpdated: Cirf = await this.opportunitiesService.generateCIRFPackage(cirf).toPromise();
                return Promise.resolve(cirfUpdated);
            } catch (e) {
                this.generateCirfErrorMessage(e);
            }
        }
        return Promise.resolve(null);
    }

    async updateCirfWithMatterData(matter: Matter, cirf: Cirf, participant?: MatterParticipant): Promise<Cirf>{
        if(cirf){
            cirf.clientEmail = participant ? participant.contact.firstEmail : matter.matterContactInfo && matter.matterContactInfo.firstEmail;
            if (matter.isCustomMatter()) {
                this.emailFieldService.matter = matter;
                this.emailFieldService.key = EmailKeys.matterOpening;
            }
            if (participant) {
                cirf.clientName = participant.contact ? participant.contact.firstLastNames : null;
            } else {
                let primaryClinet = this.getPrimaryClient(matter);
                cirf.clientName = primaryClinet && primaryClinet.contact  ? primaryClinet.contact.firstLastNames : null;
            }

            if(cirf.cirfMatterData == null){
                cirf.cirfMatterData = new CirfMatterData();
            }

            cirf.cirfMatterData.matterType = matter.matterType;
            cirf.cirfMatterData.customMatterTypeCode = matter.customMatterTypeName;
            cirf.cirfMatterData.customMatterTypeDesc = matter.customMatterTypeDesc;
            cirf.cirfMatterData.provinceCode = matter.provinceCode;
            let documentProfile = await this.getDocumentProfile(matter);
            let defaultDocumentProfile = await this.getDefaultDocumentProfile();

            cirf.mapIntroductoryInfo(matter, this.getDocumentProfileLawFirmName(documentProfile, defaultDocumentProfile));

            if (participant) {
                cirf.matterParticipantId = participant.matterParticipantId;
                cirf.mapMatterParticipantsToCirfClientInfo([participant], true);
            } else {
                cirf.mapMatterParticipantsToCirfClientInfo(matter.mainClients, false);
                cirf.mapMatterContactInfoToCirfMatterContactInfo(matter);
                cirf.mapMatterInsuranceInfoToCirfInsuranceContactInfo(matter);
            }
            cirf.cleanUpCirf();
        }
        return cirf;
    }

    async getCirfPrecedentTextByMatterType(matter: Matter, precedentId?: number) : Promise<string> {
        //Get precedents from backend
        let cirfPrecedentDescription: string = '';
        // on resending an email we could potentially work with a precedent that has been marked as deleted
        let precedents: Precedent[] = await this.precedentService.getAllPrecedents().toPromise();
        if (precedents) {
            const cirfPrecedent: Precedent = (precedentId) ?
                precedents.find(precedent => precedent.id === precedentId) :
                precedents.find(precedent => precedent.precedentType === PrecedentTypes.CIRF && matter.derivedMatterType === precedent.precedentName);
            if (cirfPrecedent) {
                cirfPrecedentDescription = cirfPrecedent.description;
            }
        }
        return cirfPrecedentDescription;
    }

    sendCirfEmail(matter: Matter, clientEmail: string, cirfGuid: string, lawFirmName: string, matterType: string, cirfPrecedentText: string, dearText: string) {
        this.emailFieldService.matter = matter;
        let subject = this.emailFieldService.getMailSubject(EmailKeys.matterOpening, EmailTypes.isCirf);
        const baseUrl = this.getUnityConnectBaseUrl(cirfGuid);
        this.emailFieldService.openLocalEmailClient(clientEmail, subject, this.createCIRFEmailBody(baseUrl, cirfPrecedentText, dearText)).subscribe();
    }

    getUnityConnectBaseUrl(guid: string): string {
        let baseUrl;
        const utils = new Utils();
        if (this.appConfig && this.appConfig.unityConnectBaseUrl) {
            baseUrl = this.appConfig.unityConnectBaseUrl + '?ct=' + guid;
        } else if (!utils.isIE()) {
            //TODO: WE NEED TO REMOVE THIS URL SHOULD BE COMING FROM SERVER NOT CLIENT SIDE....
            const parsedUrl = new URL(window.location.href);
            if (parsedUrl && parsedUrl.origin) {
                baseUrl = parsedUrl.origin + '/connect?ct=' + guid;
            }
        }
        return baseUrl;
    }

    createCIRFEmailBody(link : string, cirfPrecedentText : string, dearText: string) : string {

        let emailBody = 'Dear ' + dearText + '\n\n'
            + cirfPrecedentText +'\n\n'
            + link +'\n\n'
            +'The UnityC link is only for the benefit of the email recipient. Please do not forward or otherwise distribute this email.'

        return emailBody;
    }

    async prepareAndSendCirfEmail(cirf: Cirf, matter: Matter, precedentId: number, participant?: MatterParticipant): Promise<boolean> {
        let cirfPrecedentText : string = await this.getCirfPrecedentTextByMatterType(matter, precedentId);
        cirfPrecedentText += participant ? '\n\nOnly your personal details are being requested.' : '';
        let primaryClient = this.getPrimaryClient(matter);
        let contact = participant ? participant && participant.contact : primaryClient && primaryClient.contact;
        let dearText  = contact && contact.dear ? contact.dear : '';
        let contactEmail : string = participant ? participant.contact && participant.contact.firstEmail : matter.matterContactInfo && matter.matterContactInfo.firstEmail;
        if(!contact || !contactEmail){
            this.dialogService.confirm('Error', 'A ' + (participant ? '' : 'primary ') + 'contact with a valid email address is required before initiating this request.',
                true, 'Ok',).subscribe();
            return false;

        } else if(cirf.isActive &&  cirf.clientEmail.toLowerCase() != contactEmail.toLowerCase()){
            this.dialogService.confirm('Error', 'You must cancel the original CIRF request before re-sending to a new email address.', true, 'Ok',).subscribe();
            return false;
        }
        else {
            this.sendCirfEmail(matter, cirf.clientEmail, cirf.guid, cirf.cirfMatterData.firmName, matter.derivedMatterType, cirfPrecedentText, dearText);
            return true;
        }
    }

    getParticipantForCirf(cirf: Cirf , matter: Matter) : MatterParticipant {
        let participant: MatterParticipant = null;
        if(cirf.isParticipantCirf() && cirf.matterParticipantId && matter && matter.mainClients && matter.mainClients.length){
            participant = matter.mainClients.find(client => client.matterParticipantId == cirf.matterParticipantId);
        }
        return participant;
    }

    generateCirfErrorMessage(e: ApplicationError) {
        let defaultError = errorCodes.find(err => err.code == 'default');
        let errorMessage = defaultError.msg;
        if(e && e.errorCode){
            let error = errorCodes.find(err => err.code == e.errorCode);
            if(error){
                errorMessage = error.msg;
            }
        }
        this.dialogService.confirmDialog('Error', errorMessage, true, 'OK');
    }

    static isFieldHidden(configFieldName: string, fieldValue: string, cirfConfigService: CirfConfigService,  isUdf?: boolean) : boolean {
        if(isUdf) {
            return !fieldValue && cirfConfigService.isUDFHidden(configFieldName);
        } else {
            return !fieldValue && cirfConfigService.isFieldHidden(configFieldName);
        }

    }
}

export const errorCodes  = [
    {code : 'app.cirf.email.cannot.change' , msg : 'CIRF request cannot be resent if the email address has changed.'},
    {code : 'app.cirf.matter.cirf.sent' , msg : 'A full CIRF has already been sent to this client\'s email address.'},
    {code : 'app.cirf.participant.cirf.sent' , msg : 'An individual CIRF has already been sent to the primary contact email address.'},
    {code : 'default' , msg : 'Unexpected error happened on Server, please try later or contact the Administrator.'},

];
