import {SoaFee} from '../../shared-main/statement-account/fee-config/soa-fees';
import {DisbursementConfig} from '../../shared-main/statement-account/disbursement-config/disbursement-config';
import {MatterType} from '../../matters/shared/matter-specific-type';
import {BasicUserInfo} from '../../matters/shared';


export type TemplateTypes ='BASE'| 'CLONE'| 'CUSTOM';
export class SoaTemplate {

    id : number
    fees : SoaFee[];
    disbursementsConfig : DisbursementConfig[];
    templateName : string;
    active : boolean;
    defaultTemplate : boolean;
    matterType : MatterType;
    lastUpdateTimeStamp : number;
    userName : string;
    templateType : TemplateTypes;
    feeCalculatedOnInclusivePriceSelection: boolean;
    feeCalculatedOnInclusivePriceValue: number;
    //Created to replace matterType field for accommodating customMatter type Templates.
    applicableMatterTypeCode:string;

    //Behind the scene we are creating new version of all templates even if user has modified one, since requirement is to show the modified By User and timestamp  only for modified template
    // so these fields are added for individual template. Updated on Backend.
    templateModifiedTimeStamp: number;
    templateModifiedBy:BasicUserInfo;

    //Variable to inform backend about template has been modified or not.
    templateModified: boolean = false; //only on UI

    constructor(soa? : SoaTemplate) {
        for(let prop in soa) {
            if(soa.hasOwnProperty(prop)) {
                this[prop] = soa[prop];
            }
        }
        this.fees = [];
        if(soa && Array.isArray(soa.fees)) {
            for(let i : number = 0; i < soa.fees.length; ++i) {
                this.fees[i] = new SoaFee(soa.fees[i]);

            }
        }
        this.disbursementsConfig = [];
        this.initalizeDisbursements(soa);

    }

    isCustomSoaTemplate():boolean{
        return this.templateType == 'CUSTOM';
    }

    initalizeDisbursements(soa? : SoaTemplate){
        if(soa && Array.isArray(soa.disbursementsConfig)) {
            for(let i : number = 0; i < soa.disbursementsConfig.length; ++i) {

                if (soa.disbursementsConfig[i]) { // if disbursement_config_order has gaps nulls will be filled in
                    this.disbursementsConfig.push(new DisbursementConfig(soa.disbursementsConfig[i]));
                }
            }
        }
    }


}
