import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {Component, OnInit} from '@angular/core';
import {UnSavedChangesComponent} from '../../shared-main/unsavedchanges.guard';
import {Observable} from 'rxjs/Observable';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../../shared-main/supplemental-task-category/supplemental-task-category-drop-downs';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DPError} from '../../shared/error-handling/dp-error';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ApplicationError} from '../../core/application-error';
import {ErrorDirectiveMapping} from '../../shared/error-handling/error-directive-mapping';
import {TabsService} from '../../core/tabs.service';
import {SupplementalTaskService} from '../../shared-main/supplemental-task-category/supplemental-task-service';
import {SupplementalTaskCategory} from '../../shared-main/supplemental-task-category/supplemental-task-category';
import {CategoryField} from '../../shared-main/supplemental-task-category/category-field';
import {Account} from '../accounts/shared/account';
import {ManageMasterSupplementalTaskDocumentsModalComponent} from './manage-master-supplemental-task-documents.modal.component';
import {DocumentProfileTemplateFolder} from '../document-profile/document-profile-edit/template-folder/document-profile-template-folder';
import {AccountService} from '../accounts/account.service';
import {CategoryTemplate} from '../../shared-main/supplemental-task-category/category-template';
import {ConfigTab} from '../../shared/tabbing/config-tab';
import {MatterTypeInfo} from '../shared/matter-type-info';


@Component({
    selector: 'dp-customize-supplemental-task-categories',
    templateUrl: './supplemental-task-category.component.html',
    styleUrls: [
        './supplemental-task-category.component.scss'
    ]

})

@FocusFirstElementDecorator()
export class SupplementalTaskCategoryComponent implements OnInit, UnSavedChangesComponent {


    constructor(public supplementalTaskService: SupplementalTaskService, public errorService: ErrorService, public dialogService: DialogService, public tabsService : TabsService, public accountService:AccountService) {
    }

    setUnsavedChangesFormMessage(): string {
        return "There are unsaved changes in Extended Workflows Category, Do you wish to save before proceeding ?";
    }

    unsavedChanges(): Observable<boolean> | boolean {
        return true;
    }

    supplementalTaskCategoryNotModified: SupplementalTaskCategory;

    account: Account;
    yesNoOptions: SelectItem[];
    userDefinedFieldOptions: SelectItem[];
    matterTypes: any[] = dropDowns.MATTER_TYPES;
    errorKey: string;
    categoryNameReadOnly : boolean=false;
    templateFolders : DocumentProfileTemplateFolder[] = [];

    ngOnInit() {

        this.userDefinedFieldOptions = dropDowns.UserDefinedFieldOptions;
        this.yesNoOptions = dropDowns.YESNO;
        this.populateDefaultValues();
        this.buildPreDefinedFields();
        this.supplementalTaskCategory.isDirty = false;
        this.supplementalTaskCategoryNotModified = new SupplementalTaskCategory(this.supplementalTaskCategory);
        this.disableCategoryName();

        if(this.activeAdminTab && this.activeAdminTab.account) {
            this.activeAdminTab.supplementalTaskCategoryComponent = this;
            this.account = new Account(this.activeAdminTab.account);
        }
        this.initTemplateFolder();
        this.loadMatterTypeDetails();


    }

    async loadMatterTypeDetails() {
        this.matterTypes = [];
        let allMatterTypes : MatterTypeInfo[] = await this.accountService.getMatterTypesDetails(this.account).toPromise();
        if(allMatterTypes && allMatterTypes.length){
            allMatterTypes.forEach((matterType: MatterTypeInfo)=>{
                this.matterTypes.push({label : matterType.matterTypeDescription, value : matterType.matterTypeCode});
            });
        }
    }

    initTemplateFolder() {
        this.accountService.getTemplateFolders(String(this.account.id), this.supplementalTaskCategory.provinceCode).subscribe(
            (data : DocumentProfileTemplateFolder[]) => {
                this.templateFolders = data;
            }
        );
    }

    getCategoryFilesInFolder(folderID:number) : CategoryTemplate[] {
        return this.supplementalTaskCategory.categoryTemplates.filter(item => item.templateFolderId === folderID);
    }

    populateDefaultValues(){
        if(!this.supplementalTaskCategory.autoInsert){
            this.supplementalTaskCategory.autoInsert='NO';
        }
        if(!this.supplementalTaskCategory.applicableMatterTypeCodes || this.supplementalTaskCategory.applicableMatterTypeCodes.length == 0){
            this.supplementalTaskCategory.applicableMatterTypeCodes=['PURCHASE', 'SALE', 'MORTGAGE'];
        }
    }

    disableCategoryName()  {
        this.categoryNameReadOnly =  this.supplementalTaskCategory.id &&  this.supplementalTaskCategory.id > 0;
    }

    buildPreDefinedFields() {
        if (!this.supplementalTaskCategory.categoryFields) {
            this.supplementalTaskCategory.categoryFields=[];
        }

        this.supplementalTaskService.defaultCategoryFields.forEach((defaultField: CategoryField) => {
            let fieldIndex = this.supplementalTaskCategory.categoryFields.findIndex(categoryField => categoryField.fieldDefaultLabel == defaultField.fieldDefaultLabel);
            if(fieldIndex < 0) {
                this.supplementalTaskCategory.categoryFields.push(new CategoryField(defaultField));
            }
        });
    }

    getDefaultFieldLabel(row: CategoryField) {
        return row.fieldDefaultLabel
    }

    getDefaultField(row: CategoryField) {
        return row.fieldLabel
    }

    addNewUserDefinedField() {
        let categoryField = new CategoryField();
        categoryField.enabled= true;
        categoryField.fieldDataType = 'N_A';
        categoryField.fieldType = 'USER_DEFINED_FIELD';
        this.supplementalTaskCategory.categoryFields.push(categoryField);
    }

    updateMultiSelectFilters(obj: string[], searchType: string): void {
        // console.log(obj);
        if (searchType === 'type') {
            this.supplementalTaskCategory.applicableMatterTypeCodes = obj;
        }

    }

    saveAll() : void {
        this.saveChanges().subscribe();
    }

    saveChanges() : Observable<boolean>{
        this.checkErrors();
        if (!this.errorService.hasErrors()) {
            this.supplementalTaskCategory.categoryFields = this.supplementalTaskCategory.categoryFields
                .filter(cf => (cf.fieldDataType != 'N_A') );
            return this.createSupplementaryTaskCategories();

        }else{
            this.errorService.openErrorFooterNotification();
            return Observable.of(false);
        }
    }


    public createSupplementaryTaskCategories():  Observable<boolean> {
        let id = this.account ? this.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        return this.supplementalTaskService.saveSupplementalTaskCategory(id, this.supplementalTaskCategory)
            .flatMap(
                (data: SupplementalTaskCategory) => {
                    this.supplementalTaskCategory = data;
                    this.buildPreDefinedFields();
                    this.supplementalTaskCategoryNotModified = new SupplementalTaskCategory(this.supplementalTaskCategory);
                    return Observable.of(true);
                }).catch(
                (error: ApplicationError) => {
                    console.log(error);
                    this.createApiError(error);
                    return Observable.of(false);

                });
    }

    ngDoCheck(): void {

        if (this.supplementalTaskCategoryNotModified && this.supplementalTaskCategory && !this.supplementalTaskCategory.isDirty) {
            this.supplementalTaskCategory.isDirty = !this.supplementalTaskCategoryNotModified.equals(this.supplementalTaskCategory)
        }
    }

    checkErrors(): void {
        this.errorService.clearAllSaveErrors();
        this.errorService.removeAllDpFieldError();
        if(!this.supplementalTaskCategory.isCustomizedDefaultTaskCategory){
            this.validatePreDefinedFieldLabelOnSave();
            this.validateUserDefinedFieldLabelOnSave();
            this.validateTickerGroupSubFieldsOnSave();
        }
        if (!this.supplementalTaskCategory.categoryName) {
            this.errorService.addDpSaveError(DPError.createDPError('supplementalTaskCategory.categoryName'));
        }
        if (this.supplementalTaskCategory.applicableMatterTypeCodes && !(this.supplementalTaskCategory.applicableMatterTypeCodes.length > 0)) {
            this.errorService.addDpSaveError(DPError.createDPError('supplementalTaskCategory.appliesTo'));
        }
        if (!this.supplementalTaskCategory.isCustomizedDefaultTaskCategory &&
            !(this.supplementalTaskCategory.categoryFields.filter(
                cf => (cf.fieldDataType !== 'N_A' && cf.fieldType === 'USER_DEFINED_FIELD') || cf.enabled).length > 0)) {
            this.errorService.addDpSaveError(DPError.createDPError('supplementalTaskCategory.categoryFields'));
        }
    }

    createApiError(error): void {
        let errorDirectiveMapping = ErrorDirectiveMapping[this.errorKey];
        if(error.fieldErrors && error.fieldErrors.length > 0 ) {
            error.fieldErrors.forEach((error) => {
                this.errorService.addDpSaveError(DPError.createCustomDPError('none', error.message? error.message : error.code,
                    errorDirectiveMapping ? errorDirectiveMapping.ERROR_TOPIC : "Extended Workflow Category",
                    errorDirectiveMapping ? errorDirectiveMapping.ERROR_TYPE : "ERROR"));
            });
        } else if(error.errorCode  === 'app.cannotPassID') {
            this.errorService.addDpSaveError(DPError.createDPError("supplementalTaskCategory.INVALID"));
        }
    }

    onChange(): void {
        this.supplementalTaskCategory.isDirty = true;
    }

    onTicklerGroupCheck(event): void {
        this.onChange();
        this.supplementalTaskCategory.ticklerGroup.subFields.forEach((subField: CategoryField) => {
                subField.enabled = event;
            });
    }

    deleteUserDefinedField(selectedCategoryField: CategoryField) {
        this.dialogService.confirm('Confirmation', 'Are you sure you want to delete this row?', false, 'Delete').subscribe(res => {
            if (res == true) {
                if (this.supplementalTaskCategory.categoryFields) {
                    let rowIndex = this.supplementalTaskCategory.categoryFields.findIndex(categoryField => categoryField == selectedCategoryField);
                    this.supplementalTaskCategory.categoryFields.splice(rowIndex, 1);
                }

            }
        });
    }

    get supplementalTaskCategory(): SupplementalTaskCategory {
        return this.activeAdminTab && this.activeAdminTab.supplementalTaskCategory;
    }

    get isPayBillOrDebtsCategory() : boolean{
        return this.supplementalTaskCategory && this.supplementalTaskCategory.isPayBillOrDebts;
    }

    get isIdVerificationOfNonClientCategory() : boolean{
        return this.supplementalTaskCategory && this.supplementalTaskCategory.isIdVerificationOfNonClient;
    }

    get activeAdminTab() : ConfigTab {
        return this.tabsService.activeTab as ConfigTab;
    }

    set supplementalTaskCategory(supplementalTaskCategory : SupplementalTaskCategory) {
        if (this.activeAdminTab) {
            this.activeAdminTab.supplementalTaskCategory = supplementalTaskCategory;
        }
    }

    validatePreDefinedFieldLabelOnSave() : void {
        let index=0;
        this.supplementalTaskCategory.preDefinedFields.forEach(categoryField =>{
            if(categoryField.enabled){
                if(categoryField && categoryField.fieldLabel) {
                    // this.errorService.removeDpFieldError('genderChangeProhibited');
                    this.errorService.removeDpFieldError('supplementalTaskCategory.preDefinedField.' + index);

                } else {
                    // alert('Log Error using Hardik error service');
                    const dpError : DPError = DPError.createDPError('supplementalTaskCategory.preDefinedField');

                    dpError.errorElementKey = 'supplementalTaskCategory.preDefinedField.' + index;
                    dpError.errorMessage = dpError.errorMessage ? dpError.errorMessage.replace(/\.$/," ") + " " + categoryField.fieldDefaultLabel + "." : dpError.errorMessage + " " + categoryField.fieldDefaultLabel + ".";
                    this.errorService.addDpFieldError(dpError);
                }
                index= index+1;
            }
        });

    }

    validateTickerGroupSubFieldsOnSave() : void {
        if(this.supplementalTaskCategory.ticklerGroup && this.supplementalTaskCategory.ticklerGroup.enabled){
            let index=0;
            this.supplementalTaskCategory.modifiableSubFieldsOfTickerGroup.forEach(subField =>{
                if(subField && subField.fieldLabel) {
                    // this.errorService.removeDpFieldError('genderChangeProhibited');
                    this.errorService.removeDpFieldError('supplementalTaskCategory.tickleGroupSubFields.' + index);

                } else {
                    // alert('Log Error using Hardik error service');
                    const dpError : DPError = DPError.createDPError('supplementalTaskCategory.tickleGroupSubFields');

                    dpError.errorElementKey = 'supplementalTaskCategory.tickleGroupSubFields.' + index;
                    dpError.errorMessage = dpError.errorMessage ? dpError.errorMessage.replace(/\.$/," ") + " " + subField.fieldDefaultLabel + "." : dpError.errorMessage + " " + subField.fieldDefaultLabel + ".";
                    this.errorService.addDpFieldError(dpError);
                }
                index= index+1;
            });
        }

    }

    isPreDefinedFieldReadOnly(value : CategoryField) : boolean{
        let result : boolean = false;
        let field : CategoryField = new CategoryField(value);
        if(this.isPayBillOrDebtsCategory){
            result = field.isFieldTypeAccountNumber || field.isFieldTypeDetails;
        }
        // console.log(">> isReadOnlyField for " + field.fieldDefaultLabel + " ? " + result);
        return result;
    }

    isUserDefinedFieldReadOnly(value : CategoryField){
        let field : CategoryField = new CategoryField(value);
        if(this.supplementalTaskCategory && this.supplementalTaskCategory.categoryName == 'FORECLOSURE'){
            return field.fieldDefaultLabel == 'REQUEST_TRANSMISSION_FORM_5.1_BOX_2';
        }
        return false;
    }



    validateUserDefinedFieldLabelOnSave() : void {
        let index=0;
        this.supplementalTaskCategory.userDefinedFields.forEach(categoryField =>{
            if(categoryField.fieldDataType != 'N_A'){
                if(categoryField && categoryField.fieldLabel) {
                    // this.errorService.removeDpFieldError('genderChangeProhibited');
                    this.errorService.removeDpFieldError('supplementalTaskCategory.userDefinedField.' + index);

                } else {
                    // alert('Log Error using Hardik error service');
                    const dpError : DPError = DPError.createDPError('supplementalTaskCategory.userDefinedField');

                    dpError.errorElementKey = 'supplementalTaskCategory.userDefinedField.' + index;
                    dpError.errorMessage = dpError.errorMessage ? dpError.errorMessage.replace(/\.$/," ") + " " + (index+1) + "." : dpError.errorMessage + " " + (index+1) + ".";
                    this.errorService.addDpFieldError(dpError);
                }
                index= index+1;
            }
        });

    }

    openMaintainMastersDialog(folder: DocumentProfileTemplateFolder) {
        this.dialogService.content({
                                       content       : ManageMasterSupplementalTaskDocumentsModalComponent,
                                       context       : {
                                           supplementalTaskCategory: this.supplementalTaskCategory,
                                           templateFolder: folder,
                                           account: this.account
                                       },
                                       onFulfillment : (result : any) => {
                                               if(result=='save'){
                                                   this.onChange();
                                               }
                                       }
                                   });
    }

    ngAfterViewInit() {}

}
