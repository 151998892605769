import {MatterParticipant} from '../../shared/matter-participant';
import {Contact} from '../../shared/contact';
import {MatterParticipantRole} from '../../shared/matter-participant-role-types';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {Matter} from '../../shared/matter';
import {MatterTypesValue} from '../../shared/index';

export class PurchaserFamilyLawAct {
    static gendersWithVisibleFamilyAct : string[] = ['QUESTION', 'MALE', 'FEMALE', 'MALEPOA', 'FEMALEPOA']; //TODO: this can come from a config
    // static gendersOptionsVisibleFamilyAct : string[] = ['QUESTION', 'MALE', 'FEMALE', 'MALEPOA', 'FEMALEPOA', 'ESTATE']; //TODO: this can come from a config
    // DPPMP-30221
    // The issue reason is Estate doesn't have fla. So Estate can't save spouse information. Remove Estate from spouse drop down options.
    static gendersOptionsVisibleFamilyAct : string[] = ['QUESTION', 'MALE', 'FEMALE', 'MALEPOA', 'FEMALEPOA']; //TODO: this can come from a config


    static buildSpouseNameOptions(matterParticipant : MatterParticipant, matterParticipantRole : MatterParticipantRole, matter : Matter) : any[] {
        let spouseNameOptions : any[] = [{label : '', value : undefined}];
        let items : MatterParticipant[];
        if(matterParticipantRole == matter.mainClientType){
            items = matter.mainClients || [];
        } else {
            //Defaulting to old typed logic, to be cleaned up
            switch (matterParticipantRole) {
                //TODO: investigate the applicability for different matter types
                case 'VENDOR':
                    items = matter.vendors || [];
                    break;
                case 'PURCHASER':
                default :
                    items = matter.purchasers || [];
                    break;
            }
        }
        let length : number = items.length;
        for(let i : number = 0; i < length; i++) {
            const item : MatterParticipant = items[i];
            const contact : Contact = item.contact;

            if(item.matterParticipantId !== matterParticipant.matterParticipantId
                    && this.isFamilyLawActOptionsVisibleForParticipant(item)) {
                spouseNameOptions.push({
                                           value : item.matterParticipantId,
                                           label : contact.gender === 'ESTATE'
                                               ? `Estate of ${contact.surnameLastFullName}`
                                               : contact.surnameLastFullName
                                       });
            }
        }
        return spouseNameOptions;
    }



    static isSingleFamilyLawAct(matterParticipant : MatterParticipant, matterParticipantRole : MatterParticipantRole, matter : Matter) {
        const spouseNameOptions : any = this.buildSpouseNameOptions(matterParticipant, matterParticipantRole, matter);
        return !Array.isArray(spouseNameOptions)
            || spouseNameOptions.length === 0
            || (spouseNameOptions.length === 1 && !spouseNameOptions[0].value);
    }

    // static getFamilyLawActAlerts(selectedClientPurchaser : any) {
    static getFamilyLawActAlerts(matterParticipant : MatterParticipant, matterParticipantRole : MatterParticipantRole, matter : Matter, provinceCode?: string) {
        let alerts : string[] = [];
        if(matterParticipant) {
            alerts = matterParticipant.getFlaAlerts(matter, this.isSingleFamilyLawAct(matterParticipant, matterParticipantRole, matter), provinceCode);
        }
        return alerts;
    }

    static isFamilyLawActVisibleForParticipant(participant : MatterParticipant, isOtherParty: boolean, provinceCode: ProvinceCode, numberOfParticipants: number, matterType: string) : boolean {
        if(matterType == MatterTypesValue.OPPORTUNITY || matterType == MatterTypesValue.WILL){
            return false;
        }
        //First checking is FLA tab visible based on province requirement. For AB FLA tab is not visible if it's other party tab or number of main clients > 1
        if((isOtherParty  && provinceCode == 'MB' ) || (provinceCode == 'AB' && (isOtherParty || numberOfParticipants > 1)) || provinceCode == 'BC') {
            return false;
        }

        if(participant && participant.contact) {
            const participantGender : string = participant.contact.gender;
            if((matterType == MatterTypesValue.PURCHASE || matterType == MatterTypesValue.SALE ) && provinceCode == 'ON' && participant.isContactGenderEstate() ){
                return true;
            } else if(provinceCode == 'AB' && participant.isContactGenderEstate()) {
                return true;
            } else {
                return this.gendersWithVisibleFamilyAct.indexOf(participantGender) >= 0;
            }

        } else {
            return false;
        }
    }

    static isFamilyLawActOptionsVisibleForParticipant(participant : MatterParticipant) : boolean {
        if(participant && participant.contact) {
            const participantGender : string = participant.contact.gender;
            return this.gendersOptionsVisibleFamilyAct.indexOf(participantGender) >= 0;
        } else {
            return false;
        }
    }

}
