import {SoAdjFinalFee} from './so-adj-final-fee';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import Utils from '../../../shared-main/utils';

export class SoAdjFinalEarlyPossessionFee  extends SoAdjFinalFee{

    static getDefault(considerationTaxes: ConsiderationTaxes): SoAdjFinalEarlyPossessionFee {
        let newAdj: SoAdjFinalEarlyPossessionFee = new SoAdjFinalEarlyPossessionFee();

        newAdj.useAmountFromInterim = "NO";
        newAdj.amount = 0;
        newAdj.purchaserHasPaidFee = "AND_INCLUDING_MONTH_OF_CLOSING";

        newAdj.applyTax = true;
        newAdj.taxType = considerationTaxes.rateType;
        newAdj.hstRate = considerationTaxes.hstRate;

        return newAdj;
    }

    // F4 = this.finalAdjustAsDate
    // E17 = this.paidOn
    getOtherEnterOwnDateAmount(interimAdjustAsDate: string, finalAdjustAsDate: string, ratePerDaysInAdjustAsAt: number): number {
        let derivedMonthsDateToWhichPurchaserHasOccupancyFeesUntilFinal: number = this.purchaserHasPaidFee === 'NO_OCCUPANCY_FEES_EVER_PAID' ?
            Utils.getDateDiffMonths(interimAdjustAsDate, finalAdjustAsDate) :
            Utils.getDateDiffMonths(this.paidOn, finalAdjustAsDate) ; // E23
        let derivedLeftOverDaysBetweenFinalAndEntered: number = Utils.getDay(finalAdjustAsDate) - 1 - Utils.getDay(this.paidOn); // E24
        derivedLeftOverDaysBetweenFinalAndEntered = Math.max(derivedLeftOverDaysBetweenFinalAndEntered, 0); // DPPMP-35654 => =MAX((DAY(F4)-1-DAY(E17)),0)
        let derivedDaysDateToWhichPurchaserHasOccupancyFeesBalanceOfMonth: number = Utils.getDateDiff( this.paidOn, Utils.lastDayOfMonthFromDate(this.paidOn)); // E27
        let daysInEnteredDateMonth: number = Utils.getDaysInMonth(this.paidOn); // H26
        let ratePerDaysInEnteredDateMonth: number = Number(this.amountWithTax) / Number(daysInEnteredDateMonth); // J26

        if (Utils.getDay(finalAdjustAsDate) < Utils.getDay(this.paidOn)) {
            return derivedMonthsDateToWhichPurchaserHasOccupancyFeesUntilFinal * this.amountWithTax
                + this.getDerivedDaysOccupiedInMonthOfOccupancy(finalAdjustAsDate) * ratePerDaysInAdjustAsAt
                + ratePerDaysInEnteredDateMonth * derivedDaysDateToWhichPurchaserHasOccupancyFeesBalanceOfMonth;
        } else {
            return derivedMonthsDateToWhichPurchaserHasOccupancyFeesUntilFinal * this.amountWithTax
                + ratePerDaysInAdjustAsAt * derivedLeftOverDaysBetweenFinalAndEntered;
        }
    }

}
