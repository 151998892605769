import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class FctFiData extends BaseEntity{
    id: number;
    fiNumber: string;
    fiName: string;
    instanceType: string;


    constructor(f?: FctFiData) {
        super(f);
    }
}
