import {Component} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {ErrorService} from '../../shared/error-handling/error-service';
import {TeranetUser} from '../../shared-main/teranet/teranet-user';
import {TeranetRequestInstrumentResponse} from '../../shared-main/teranet/teranet-request-instrument-response';
import {TeranetRequestInstrumentSubmissionState} from '../../shared-main/teranet/teranet-constants';
import {Instrument} from '../../shared-main/teranet/instrument';

declare var jQuery : any;

class TeranetInstrumentRequestModalContext extends BSModalContext {
    teranetUser: TeranetUser;
    requestInstrumentResponse: TeranetRequestInstrumentResponse;
    instrument: Instrument;
}
@Component({
               selector    : 'dp-teranet-instrument-rquest-modal',
               templateUrl : 'teranet-instrument-request.modal.component.html',
               providers   : [ErrorService]
           })
export class TeranetInstrumentRequestModal implements   ModalComponent<TeranetInstrumentRequestModalContext> {
    context : TeranetInstrumentRequestModalContext;
    requestModalText: String;

    constructor(public dialog : DialogRef<TeranetInstrumentRequestModalContext>) {
        this.context = dialog.context;

    }

    ngOnInit() {
        const submissionState: TeranetRequestInstrumentSubmissionState = this.context.requestInstrumentResponse.submissionState;
        const instrumentNumber: string = this.context.instrument.instrumentNumber;

        switch(submissionState) {
            case TeranetRequestInstrumentSubmissionState.SUCCESS_1:
            case TeranetRequestInstrumentSubmissionState.SUCCESS_2:
                this.requestModalText="Your request was successfully sent.  Please wait 48 hours then select the \"Check Status of Unavailable Instruments\" from the 'Action' button.";
                break;
            case TeranetRequestInstrumentSubmissionState.INVALID:
                this.requestModalText="The Instrument No. " + instrumentNumber + " is invalid.  Please ensure this is the correct instrument number or contact Teranet at 416.360.1190 or 1.800.208.5263.";
                break;
            case TeranetRequestInstrumentSubmissionState.ZERO_PAGES:
                this.requestModalText="The Instrument No. "+ instrumentNumber + " has zero pages.  Please ensure this is the correct instrument number or contact Teranet at 416.360.1190 or 1.800.208.5263.";
                break;
            default:
                break;
        }

    }


    close(): void {
        this.dialog.close({action: "OK"});
    }

    proceed(): void {

    }
}
