import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';
import {YesNoType, YesNoTypes} from '../../../../shared-main/constants';
import {creditVendorTaxRebateShowTypes} from '../../model/so-adj-drop-downs';

export class SoAdjCreditVendorTaxRebate extends BaseEntity {
    id : number;
    adjustmentHeading : string;
    amount : number;
    adjustedFor : string;
    isAdjusted : YesNoType;
    percentage : number;
    showAdjustmentAs : string;
    constructor(soAdjCreditVendorTaxRebate? : SoAdjCreditVendorTaxRebate) {
        super(soAdjCreditVendorTaxRebate);
        if(!soAdjCreditVendorTaxRebate) {
            this.isAdjusted = YesNoTypes.NO;
        }
    }

    getTotalRebatePortionTaxLabel() : string{
        if(this.adjustedFor === 'GST') {
            return `GST calculated at ${this.percentage}%`
        } else {
            return `HST calculated at ${this.percentage}%`
        }
    }

    isPartOfSalePrice() : boolean {
        return this.showAdjustmentAs === creditVendorTaxRebateShowTypes.partOfSalePrice.value;
    }

    isSeparateAdjustment() : boolean {
        return this.showAdjustmentAs === creditVendorTaxRebateShowTypes.separateAdjustment.value;
    }

    isSeparateAdjustmentSilent() : boolean {
        return this.showAdjustmentAs === creditVendorTaxRebateShowTypes.separateAdjustmentSilent.value;
    }

    isAdjustedEqualYes() : boolean {
        return this.isAdjusted === YesNoTypes.YES;
    }
}
