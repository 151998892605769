import {BaseEntity} from '../../../shared/BaseEntity/base-entity';


export class AuthChallenge extends BaseEntity {
    authChallengeCode: string ;
    agentId: string;
    phoneNumber: string;
}

export class AuthChallengePhone extends BaseEntity {
    id: number;
    telephoneNumber: string;
}
