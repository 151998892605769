import {Component, OnInit} from '@angular/core';
import {AcceptTermsConditionsService} from './accept-terms-conditions.service';
import {AuthenticationService} from '../../../core/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {Logger} from '@nsalaun/ng-logger';
import {AuthorizationService} from '../../../shared-main/authorization/authorization-service';
import {AcceptTermsConditions, AcceptTermsConditionsDTO} from './accept-terms-conditions';
import {LockScreenService} from '../../../core/lock-screen.service';
import {UtilsService} from '../../../main/utils.service';

@Component({
               selector    : 'dp-accept-terms-conditions',
               templateUrl : './accept-terms-conditions.component.html',
               styleUrls      : [
                   '../../unity-login.styles.scss'
               ]
           })

export class AcceptTermsConditionsComponent implements OnInit {

    termsAndConditions: string;
    accepted: boolean = false;

    constructor(public acceptTermsConditionsService : AcceptTermsConditionsService,
                public authenticationService : AuthenticationService,
                public authorizationService: AuthorizationService,
                public router : Router,
                public dialogService : DialogService,
                public activatedRoute : ActivatedRoute,
                public lockScreenService : LockScreenService,
                public utilsService: UtilsService,
                public logger : Logger) {}


    ngOnInit() {
        this.lockScreenService.lockForUpdate = false;
        this.acceptTermsConditionsService.getTermsAndConditions().subscribe(
            (res: AcceptTermsConditions) => {
                if(res){
                    console.log(res);
                    this.termsAndConditions = res.termsAndConditions;
                }

            }
        );
    }

    submit() {

        if(this.accepted){
            this.logger.info("acceptTermsConditions | submit()");
            this.lockScreenService.lockForUpdate = true;
            let sessionUser  = sessionStorage.getItem(SESSION_STORAGE_KEYS.authenticatedUser);
            if(sessionUser  && sessionUser != null) {
                let acceptTermsConditionsDTO = new AcceptTermsConditionsDTO();
                acceptTermsConditionsDTO.userId = Number(JSON.parse(sessionUser).userId);
                this.acceptTermsConditionsService.setupTermsAndConditions(acceptTermsConditionsDTO).subscribe(
                    (res: any) => {
                        this.authorizationService.navigateToGetRedirectUrl();
                    },
                    (err) => {
                        this.lockScreenService.lockForUpdate = false;
                        this.logger.info("setupTermsAndConditions | submit() | err -res = ", err);
                        let  errorMessage : string = "";
                        if(err &&  err.fieldErrors && err.fieldErrors.length > 0) {
                            err.fieldErrors.forEach((error) =>{
                                errorMessage = errorMessage.concat(error.message).concat("\n");
                            });
                        }else{
                            errorMessage = err.message;
                        }
                        this.dialogService.confirm('Error', errorMessage, true);


                    }
                );

            } else {
                this.logger.info("acceptTermsConditionsForm | submit() | sessionUser invalid!");
            }

        }

    }

}

