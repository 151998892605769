import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {messages} from '../../common';

@Component({
    selector: 'dp-form-field',
    templateUrl: 'form-field.component.html'
           })
export class FormFieldComponent implements OnInit {
    public submittedForm: boolean = false;

    @Input() fieldId: string;
    @Input() formTypeId: string;
    @Input() set submitted(value: boolean) {
    this.submittedForm = value;
        this.checkFormFieldValues();
    }
    @Input() rootForm: AbstractControl;

    public errMsg: string;
    public control: AbstractControl;
    public closed: boolean;


    ngOnInit(): void {
        this.closed = true;
        if (this.rootForm) {


            this.control = this.rootForm.get(this.fieldId);
            //   console.log(this.control);

            if (this.control) {
                this.control.valueChanges
                    .subscribe((data: any) => this.onValueChanged(data));
            }
        } else {
          //  console.log("FormField:onInit(skipped):", this.fieldId);
        }
    }

    onValueChanged(data: any) {
        this.checkFormFieldValues();
    }

    checkFormFieldValues(){
        this.errMsg = '';
        if (messages[this.formTypeId] && messages[this.formTypeId][this.fieldId]) {
            const msgs: Object = messages[this.formTypeId][this.fieldId];
            const control: AbstractControl = this.control;
            if(control) {
                this.closed = control.valid;
            }
            if (((control && control.dirty && !control.valid)|| (control && this.submittedForm))) {
                // console.log("control.errors:", this.fieldId, control.errors);
                for (const key in control.errors) {
                    if (control.errors.hasOwnProperty(key)) {
                        this.errMsg += msgs[key] + ' ';
                    }
                }
            }
        }
    }

    hasErrors(): boolean {
        return this.control.invalid;
    }

    getErrors(): string {
        // console.log("getErrors:", this.fieldId);
        return this.errMsg;
    }
}
