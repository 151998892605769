import {DPError} from '../error-handling/dp-error';

export class  DPNotificationTab {

    notifications : DPError[] = [];


    constructor(notificationTab? : DPNotificationTab) {
        if(notificationTab) {
            for(let prop in notificationTab) {
                if(notificationTab.hasOwnProperty(prop)) {
                    this[prop] = notificationTab[prop];
                }
            }
            this.notifications = [];
            if(Array.isArray(notificationTab.notifications)) {
                for(let i : number = 0; i < notificationTab.notifications.length; ++i) {
                    this.notifications[i] = new DPError(notificationTab.notifications[i]);
                }

            }

        }

    }
}


