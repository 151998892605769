// Messages for form field validations
export const messages = {

    matterOpening : {
        'matterNo'           : 'Matter No. is required',
        // Todo a better sulotion with dynamic value
        'dupplicateMatterNo' : 'Matter No. already exists on a Purchase Matter and cannot be reused',
    },

    //TODO: this should not be in the messages constant
    insurerValues : [
        {
            'label': 'Stewart Title Guaranty Company',
            'class': 'stewart',
            'value': 'Stewart Title Guaranty Company',
            organizationName: 'Stewart Title Guaranty Company',
            applicableProvinces: ['ON', 'AB', 'MB', 'NB', 'BC']
        },
        {
            'label': 'FCT',
            'class': 'fct',
            'value': 'FCT',
            organizationName: 'FCT',
            applicableProvinces: ['ON', 'AB', 'MB', 'NB', 'BC']
        },
        {
            'label': 'Chicago Title Insurance Canada',
            'class': 'chicago',
            'value': 'Chicago Title Insurance Company',
            organizationName: 'Chicago Title Insurance Company',
            applicableProvinces: ['ON', 'AB',  'BC']
        },
        {
            'label': 'TitlePLUS',
            'class': 'titlePlus',
            'value': 'TitlePLUS',
            organizationName: 'TitlePLUS',
            applicableProvinces: ['ON']
        },
        {
            'label': 'Other Title Insurer',
            'class': 'othertitle',
            'value': 'Other Title Insurer',
            organizationName: null,
            applicableProvinces: ['ON', 'AB', 'SK', 'MB', 'NS', 'NB', 'BC']
        },
        {
            'label': 'Transaction Is Not Title Insured',
            'class': 'transnotsecure',
            'value': 'Transaction Is Not Title Insured',
            organizationName: null,
            applicableProvinces: ['ON', 'AB', 'SK', 'MB', 'NS', 'NB', 'BC']
        },
        {
            'label': 'Not Yet Determined',
            'class': 'notyetdetermined',
            'value': '',
            organizationName: null,
            applicableProvinces: ['ON', 'AB', 'SK', 'MB', 'NS', 'NB', 'BC']
        }
    ],


    property : {
        'pinNo'        : 'Pin No is required',
        'price'        : 'Purchase Price is required',
        'invalidPrice' : 'Purchase Price must be a numeric entry'
    },

    matter : {
        'selectMatterTypeError'              : 'Please select at least one Matter Type',
        'fileInactiveMessage'                : 'If "Is File inactive" field is YES  then "Inactive Date" field is mandatory',
        'offerorDeletionConfirmationMessage' : 'Do you wish to delete this offeror?',
        'witnessDeletionConfirmationMessage'  : 'Do you wish to delete this Witness?',
        'produceDebitNoticeUnAvailable': 'Billing of this matter has been delayed. The Debit Notice is not yet available.',
        'resetMatterUnAvailable': 'Billing of this matter has been delayed. Matter cannot be reset.',
        'documentsOpen' : 'Unable to Reset matter because you are currently editing some of its documents.  Please close and click reset again.'
    },

    addNewIndividualClient : {
        'surnameError'       : 'Surname is mandatory',
        'canadaZipcodeError' : ` Canada zip code should be in format "X1X 1X1" ' +
                                 'where X is a letter and 1 is a digit.'`,
        'otherZipcodeError'  : 'Please provide a valid zipcode.',
        'name'               : 'Name is mandatory',
        'deceased'           : "Surname of Deceased is mandatory",
        'fax'                : 'Please enter  a valid fax number.'
    },

    documentProduction : {
        'selectTemplateError' : 'Please select at least one Template to proceed.',
        'downloadPdfWithBarcode' : 'The selected file contains a dynamic barcode.  After making your changes, you will need to save the file to your system.  The file can then be uploaded on the Matter Documents topic.'
    },

    loginPage         : {
        publicAccountId : {
            required : 'Account Number is required',
        },
        loginId          : {
            required : 'User ID is required',
        },
        password        : {
            required       : 'Password is required',
            minlength      : 'Password needs to be at least 4 characters',
            maxlength      : 'Password needs to be maximum 100 characters',
            customPassword : 'Password cannot start with a number',
        },
        general         : 'The credentials entered are not correct. Please verify your entry and try again, or contact your Customer Administrator',
        internal        : 'Unexpected error happened on Server, please try later or contact the Administrator',
        locked : "Your credentials have been locked.  Please contact your administrator",
        suspended: "Your User ID has been either suspended or locked. Please contact your Customer Administrator.",
        sessionExpired : 'Another session has already been started in a different browser and as a result this session has been expired.',
        sessionTimedOut : 'Your session has timed out. Please log in again.'
    },
    matterOpeningPage : {
        fileNo : {
            required : 'File no is required',
        },
    },

    mortgage : {
        mailingAddress      : 'Mailing Address',
        solicitorAddress    : "Solicitor's address",
        lawFirmTrashMessage : 'Firm and Solicitor information will be removed from this matter'
    },

    mortgageTerm : {
        deleteConfirmation : 'Are you sure you would like to delete this guarantor?'
    },

    newAccountMandatory : {
        'mandatoryFields' : 'One or more required Account Profile fields are incomplete. Please complete all required fields.',
        'accountIdExists' : 'This Account ID already exists'
    },

    newAccountEmailFormat : {
        'emailFormat' : 'Please provide Email in correct format'
    },

    newFirmMandatory : {
        'mandatoryFields'    : 'One or more required Firm Profile fields are incomplete.  Please complete all required fields.',
        'barristerSolicitor' : 'Please select barrister & solicitor type'
    },

    userProfile : {
        'userIdExists'              : 'The selected User ID already exists.',
        'unsavedChangesFormMessage' : "There are unsaved changes in Create User Profile, Do you wish to save before proceeding?"
    },

    staffProfiles : {
        'userIdExists'              : 'The selected User ID already exists.',
        'unsavedChangesFormMessage' : "There are unsaved changes in Create Staff Profile, Do you wish to save before proceeding?"
    },

    firstLogin      : {
        'passwordBothFields'      : 'Password must be entered in both Password fields',
        'threeQuestionsMandatory' : 'Three questions and answers are mandatory',
        'passwordBothFieldsSame'  : 'Re-enter Password must match Password'
    },
    FORGOT_PASSWORD : {
        "RESET_LINK_SENT"       : "A Password Reset link has been sent to your email account.",
        "CREDENTIALS_INCORRECT" : "The credentials entered are not correct. Please verify your entry and try again, or contact your Customer Administrator.",
        "CREDENTIALS_LOCKED"    : "Your credentials are locked.  Please contact your administrator.",
        "NO_TRUSTED_PHONE"      : "No Trusted Phone Number on file. Please contact your administrator."
    },
    requisition : {
        deleteConfirmation : 'Are you sure you would like to delete this Requisition?'
    },
    undertaking : {
        deleteConfirmation : 'Are you sure you would like to delete this undertaking?',
        stopConfirmationRequisition : 'Unable to delete this undertaking because it is derived from a requisition for which the status is set to "Accept Undertaking"',
        stopConfirmationMortgage: 'Unable to delete this undertaking because it is derived from data in "Existing Mortgage(s)" indicating that this mortgage is to be discharged',
        permittedRegistrationFromMortgageConfirmation: 'Unable to delete this permitted registration because it is derived from an existing mortgage that is to be assumed or remain.',
        cancelConfirmation : 'Are you sure?  You will lose all changes.',
    },
    declaration : {
        cancelConfirmation : 'Are you sure?  You will lose all changes.',
    },
    passwordErrorMessages : {
        'pwd8CharacterLimit' : "Password must be at least 8 characters in length or greater",
        'pwdSpecialCharacter' : "No spaces allowed. Allowed characters are: A to Z, a to z, 0 to 9 and special characters",
        'pwd1UpperCaseLetter' : "Password must have at least one uppercase letter",
        'pwd1Number' : "Password must have at least one number",
        'pwd1SpecialCharacter' : "Password must have at least one special character",
        'pwd2SameCharacter' : "Password cannot have more than 2 consecutive characters being the same letter, number or special character",
        'pwdSamePassword' : "Re-enter Password must match New Password",

    },

    supplementalTask : {
        cancelConfirmation : 'Are you sure you would like to discard all changes?',
    },

    teranet : {
        previouslyRequested: {
            title: "Teranet Connect &trade;: Instrument/Plan Previously Requested",
            text: "The selected instrument/plan was previously requested on DATE_PLACEHOLDER. Requests for instruments will be loaded within approximately 24 hours. Requests for plans will be loaded within approximately 48 hours.<br><br>Do you wish to proceed to request this instrument/plan again?"
        },
        requestInstrument: {
            title: "Teranet Connect &trade;: Instrument/Plan not Currently Available",
            text: "The selected instrument/plan is not currently available in Teranet's database. A request may be submitted for this instrument/plan to be loaded into Teranet's database. Requests for instruments will be loaded within approximately 24 hours. Requests for plans will be loaded within approximately 48 hours.<br><br>Do you wish to proceed to request this instrument/plan?"
        },
        downloadAll: {
            title: "Teranet Connect &trade;: Download All Components of this Instrument",
            text: 'Please note that not all components of this instrument have been selected. If you require other components of this instrument at a later time, you will be charged again for such subsequently ordered components. To select all components of the instruments for this download, click the "select All" button below or click the checkbox beside the instrument number.'
        },
        purchaseParcelWaitMessage: {
            title: 'INFORMATION',
            text: 'Please wait while we retrieve the parcel from Teranet Connect. Parcels with many instruments will take longer.'
        }
    },

    sharedDocumentsMessages  : {
        unityMessageSent: 'Documents have been shared with the selected recipient.',
        emailSent: 'An email was opened in your email client.  Please make sure you complete and send before continuing.',
        anotherRecipient : "Do you wish to share the selected document(s) with another recipient?",
        serverError: 'Unexpected error happened on Server, please try later or contact the Administrator'
    },

    mailToErrorMessages : {
        CIRF: 'The email message to be sent is too long. Please shorten the CIRF precedent for this matter type.',
        sharedDocs: 'The email message to be sent is too long.  Please shorten \'<msg_prcdnt>\' message precedent.',
        informInvitee: 'The email message to be sent is too long.  Please shorten Message precedent for this matter.',
        couldNotFindTaskOrSharedPackage: 'Could not find a shared package or a task assigned to selected client.'
    },

    inviteeMessages :{
        emailSent: 'An email was opened in your email client.  Please make sure you complete and send before continuing.',
        anotherInvitee : "Do you wish to email another invitee?"
    },
    appointments : {
        missingMobileNumber : 'Cell Phone is required:',
        missingMobileNumberNote : 'Note, for contacts where a Cell Phone field is not available, ' +
            'you may need to remove the contact as an Invitee or disable two-factor authentication in your Syngrafii account.',
    },

};
