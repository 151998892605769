import {CondominiumPlan} from './condominium-plan';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {UUID} from 'angular2-uuid';

export class CondominiumExpense extends BaseEntity {

    id : number;
    unitNumber : string;
    levelNumber : string;
    pinNumber : string;
    assessmentAccountNumber : string;
    planNumber : string;
    lincNumber : string;
    noUndividedShare : string;
    autoIncrementUnitNumber: boolean; //UI Only
    autoIncrementLevelNumber: boolean; //UI Only
    autoIncrementPinNumber: boolean; //UI Only

    rollNumber : string;
    clientSideIdentifier : number;
    condominiumPlan : CondominiumPlan;
    condominiumExpense : Number;
    condominiumUnitType : string;

    //Project Fields
    sharePercentage: string; //used for PS matter, value range 0.000000000 - 99.999999999
    autoCalcInd: boolean;
    idNumber: string;
    overrideDescInd: boolean;
    teraviewLegalDescOverriddenType: string;
    teraviewLegalDesc: string;

    constructor(condominiumExpense? : CondominiumExpense) {
        super(condominiumExpense);
        if(condominiumExpense) {
            if(condominiumExpense.clientSideIdentifier) {
                this.clientSideIdentifier = parseFloat(condominiumExpense.clientSideIdentifier.toString());
            }
        }
        else {
            let uidStr = UUID.UUID();
            if(uidStr) {
                let repStr = uidStr.replace(/\D/g, '');
                this.clientSideIdentifier = this.toFixed(Number(repStr));
            }
            this.noUndividedShare=  '0.000000';
            this.sharePercentage = '0.000000000';
            this.autoCalcInd = false;
            this.overrideDescInd = false;
        }
    }

    //This method returns true if any of the field in this object is populated. As we are creating placeholders in UI therefore we need this method to find
    //the objects which are touched by user.
    isNonEmptyObject() : boolean {
        return !!(this.unitNumber && this.unitNumber.length > 0
        || this.levelNumber && this.levelNumber.length > 0
        || this.pinNumber && this.pinNumber.length > 0
        || this.assessmentAccountNumber && this.assessmentAccountNumber.length > 0
        || this.condominiumExpense && this.condominiumExpense > 0
        || this.condominiumUnitType && this.condominiumUnitType.length > 0
        || this.lincNumber && this.lincNumber.length > 0);
    }

    isEmptyUnitOrLevel(): boolean{
        //return !this.unitNumber || !this.levelNumber || (this.unitNumber && this.unitNumber.length==0) || (this.levelNumber && this.levelNumber.length ==0);
        return (this.unitNumber?this.levelNumber.length ==0: true) || (this.levelNumber?this.levelNumber.length ==0: true);
    }



    isLegalDescriptionApplyLegalDescEasement() : boolean {
        return this.teraviewLegalDescOverriddenType == 'APPLY_LEGAL_DESC_AND_EASEMENT';
    }
    isLegalDescriptionOverrideFull() : boolean {
        return this.teraviewLegalDescOverriddenType == 'PROVIDE_ENTIRE_LEGAL_DESC';
    }
    isLegalDescriptionOverrideAppendedBelow() : boolean {
        return this.teraviewLegalDescOverriddenType == 'PROVIDE_EASEMENT_TO_BE_APPENDED';
    }
    isLegalDescriptionOverrideAppendedAbove() : boolean {
        return this.teraviewLegalDescOverriddenType == 'EASEMENT_APPENDED_TO_PROVIDED_INFO';
    }
    isLegalDescriptionOverrideAppendedInBetween() : boolean {
        return this.teraviewLegalDescOverriddenType == 'INSERT_PROVIDED_EASEMENT_IN_BETWEEN';
    }
}
