import {HttpClient} from '../../core/httpClient.service';
import {Observable} from 'rxjs/Rx';
import {api} from '../../common/api';
import {CustomDPDateCode} from './dp-date-code';
import {Injectable} from '@angular/core';
import {ProvinceCode} from '../accounts/shared/account-province';

@Injectable()
export class CustomDPDateCodeService {

    constructor(private http: HttpClient) {
    }

    getAllCustomDPDateCode(accountId: string, provinceCode?: ProvinceCode) : Observable<CustomDPDateCode[]> {
        let url: string = `${api}/customerAccounts/${accountId}/customDPDateCodes`;
        if(provinceCode) {
            url += `?provinceCode=${provinceCode}`
        }
        //console.log("getAllUserDefinedFields, url=", url);
        return this.http.get(url)
            .map((res) => {
                let data = res['CustomDPDateCode'];
                if(Array.isArray(data)) {
                    return data.map(item => {
                        return new CustomDPDateCode(item);
                    });
                }else {
                    return [];
                }
            });
    }

    updateCustomDPDateCode(customDPDateCode : CustomDPDateCode[], accountId : string) {
        let url: string = `${api}/customerAccounts/${accountId}/customDPDateCodes`;
        return this.http.post(url, JSON.stringify(customDPDateCode))
            .map((res) =>  {
                let data = res['CustomDPDateCode'];
                if(Array.isArray(data)) {
                    return data.map(item => {
                        return new CustomDPDateCode(item);
                    });
                }else {
                    return [];
                }
            });
    }
}
