import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {Component, OnInit} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {DialogService} from '../../shared/dialog/dialog.service';


export class StickyNotesModalContext extends BSModalContext {

    notes : string;
    hideDeleteButton: boolean;
}
@Component({
               selector    : 'dp-sticky-notes-modal',
               templateUrl : 'sticky-notes.modal.component.html'
           })
export class StickyNotesModal implements OnInit, ModalComponent<StickyNotesModalContext>{

    context: StickyNotesModalContext;
    stickyNotes : string;
    isDirty : boolean = false;

    constructor(public dialog: DialogRef<StickyNotesModalContext>,  public dialogService : DialogService){
        this.context = dialog.context;
    }

    ngOnInit() : void {
         this.stickyNotes = this.context.notes;
    }

    ok() : void {
        this.dialog.close({'status' : 'OK', 'notes' : this.stickyNotes});
    }

    delete() : void  {
        this.dialogService.confirm('Confirmation', 'Do you wish to delete this sticky note?', false,).subscribe(res => {
            if (res) {
                this.dialog.close({'status': 'DELETE', 'notes': ''});
            }
        });
    }

    close() : void {
        if(this.isDirty)
        {
            this.dialogService.confirm('Confirmation', 'Do you wish to save your changes?', false, 'Save', "Don't Save").subscribe(res => {
                if(res) {
                    this.ok()
                }
                else{
                    this.dialog.close();
                }
            })
        }else{
            this.dialog.close();
        }


    }

}
