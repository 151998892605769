import {Mortgage} from '../mortgage';
import {ERegStatus, ERegType} from '../../../shared-main/constants';
import {Matter} from '../matter';
import {EFormType, EFormTypes} from '../../../shared-main/alto/alto-eform-request';
import {AltoEForm, RegistrationTypes} from '../../../shared-main/alto/alto-eform';
import {ERegistrationForm} from '../../forms/eregistration/eregistrationform';
import {ERegistrationJson} from '../../forms/eregistration/eregistrationjson';
import * as _ from 'lodash';

export class MatterEFormUtil {

    static createERegChargeForms(matter: Matter, mortgage: Mortgage) {
        if (matter) {
            if (matter.isMatterProvinceON && matter.isRegistrationElectronic() && mortgage.isUnityNewMortgage() || mortgage.isEmpMortgage()) {
                MatterEFormUtil.createERegChargeForm(matter, ERegType.EREG_MORTGAGE, mortgage);
            }
            if (matter.isMatterProvinceON && matter.isRegistrationElectronic() && (matter.isSale || matter.isMortgage) && mortgage.isExistingMortgage() && (mortgage.isMortgageDispositionDischarged() || mortgage.isMortgageDispositionBridgeFinancing())) {
                MatterEFormUtil.createERegChargeForm(matter, ERegType.EREG_DISCHARGE, mortgage);
            }
        }
    }

    static createTPRMortgageEForms(matter: Matter, mortgage: Mortgage) {
        if (matter && mortgage) {
            if (mortgage.mortgageType == 'NEW' && matter.isMatterProvinceMB) {
                MatterEFormUtil.createTPRMortgageEForm(matter, EFormTypes.CAVEAT_AOR, mortgage);
            }
        }
    }

    static createTPRMortgageEForm(matter: Matter, eFormType: EFormType, mortgage: Mortgage) {
        if (matter) {
            if (matter.altoEForms && matter.altoEForms.length > 0) {
                let tprTransferFound = matter.altoEForms.find(item => item && item.eFormType === eFormType && item.registrationType === RegistrationTypes.TPR
                    && item.mortgageId === mortgage.id);
                if (!tprTransferFound) {
                    let tprMortgageEForm: AltoEForm = new AltoEForm();
                    tprMortgageEForm.registrationType = RegistrationTypes.TPR;
                    tprMortgageEForm.eFormType = eFormType;
                    tprMortgageEForm.mortgageId = mortgage.id;
                    matter.altoEForms.push(tprMortgageEForm);
                }
            } else {
                matter.altoEForms = [];
                let tprMortgageEForm: AltoEForm = new AltoEForm();
                tprMortgageEForm.registrationType = RegistrationTypes.TPR;
                tprMortgageEForm.eFormType = eFormType;
                tprMortgageEForm.mortgageId = mortgage.id;
                matter.altoEForms.push(tprMortgageEForm);
            }
        }
    }

    static createERegChargeForm(matter: Matter, eRegType: string, mortgage: Mortgage) {
        if (matter) {
            if (matter.eRegistrationForms && matter.eRegistrationForms.length > 0) {
                let eRegChargeFound = matter.eRegistrationForms.find(item => item && item.eregistrationType === eRegType
                    && item.mortgageId === mortgage.id);
                if (!eRegChargeFound) {
                    let eRegCharge: ERegistrationForm = new ERegistrationForm();
                    eRegCharge.eregistrationType = eRegType;
                    eRegCharge.eregistrationStatus = ERegStatus.EREG_INCOMPLETE;
                    eRegCharge.eregistrationData = new ERegistrationJson();
                    eRegCharge.mortgageId = mortgage.id;
                    matter.eRegistrationForms.push(eRegCharge);
                }
            } else {
                matter.eRegistrationForms = [];
                let eRegCharge: ERegistrationForm = new ERegistrationForm();
                eRegCharge.eregistrationType = eRegType;
                eRegCharge.eregistrationStatus = ERegStatus.EREG_INCOMPLETE;
                eRegCharge.eregistrationData = new ERegistrationJson();
                eRegCharge.mortgageId = mortgage.id;
                matter.eRegistrationForms.push(eRegCharge);
            }
        }
    }

    static createTransferOfLandEForm(matter: Matter) {
        if (matter) {
            matter.altoEForms = [];
            let transferOfLandEForm: AltoEForm = new AltoEForm();
            transferOfLandEForm.documentTypeDescription = 'Transfer of Land';
            transferOfLandEForm.registrationType = RegistrationTypes.ALTO;
            transferOfLandEForm.documentType = 'E_TFLA';
            transferOfLandEForm.eFormType = EFormTypes.TRANSFER_OF_LAND;
            matter.altoEForms.push(transferOfLandEForm);
        }
    }

    static createERegTransferForm(matter: Matter) {
        if (matter) {
            matter.eRegistrationForms = [];
            let eRegTransfer: ERegistrationForm = new ERegistrationForm();
            eRegTransfer.eregistrationType = ERegType.EREG_TRANSFER;
            eRegTransfer.eregistrationStatus = ERegStatus.EREG_INCOMPLETE;
            eRegTransfer.eregistrationData = new ERegistrationJson();
            matter.eRegistrationForms.push(eRegTransfer);
        }
    }

    static createTPRTransferEForm(matter: Matter) {
        if (matter) {
            if (matter.altoEForms && matter.altoEForms.length > 0) {
                let tprTransferFound = matter.altoEForms.find(item => item && item.eFormType === EFormTypes.TRANSFER && item.registrationType === RegistrationTypes.TPR);
                if (!tprTransferFound) {
                    let transferOfLandEForm: AltoEForm = new AltoEForm();
                    transferOfLandEForm.registrationType = RegistrationTypes.TPR;
                    transferOfLandEForm.eFormType = EFormTypes.TRANSFER;
                    matter.altoEForms.push(transferOfLandEForm);
                }
            } else {
                matter.altoEForms = [];
                let transferOfLandEForm: AltoEForm = new AltoEForm();
                transferOfLandEForm.registrationType = RegistrationTypes.TPR;
                transferOfLandEForm.eFormType = EFormTypes.TRANSFER;
                matter.altoEForms.push(transferOfLandEForm);
            }
        }
    }

    static removeEregistrationFormByMortgage(matter: Matter, mortgageId: number): void {
        if (matter) {
            if (matter.eRegistrationForms) {
                let eRegistrationForm = matter.eRegistrationForms.find(item => item.mortgageId == mortgageId);
                if (eRegistrationForm) {
                    (<any>matter.eRegistrationForms).remove(eRegistrationForm);
                }
            }
            if (Array.isArray(matter.altoEForms)) {
                _.remove(matter.altoEForms, (altoEForm: AltoEForm) => {
                    return altoEForm.mortgageId == mortgageId;
                });
            }
        }
    }
}
