import {ErrorTab} from '../shared/error-handling/error-tab';
import {DocServicesComponent} from './doc-services.component';
import {Account} from '../admin/accounts/shared/account';
import {SupplementalTaskCategory} from '../shared-main/supplemental-task-category/supplemental-task-category';
import {SupplementalTaskCategoryComponent} from '../admin/supplemental-task-categories/supplemental-task-category.component';
import {Observable} from 'rxjs/index';
import {ConfigTab} from '../shared/tabbing/config-tab';

export class DocServicesTab extends ConfigTab {

    constructor(json? : DocServicesTab) {
        super(json);
        if(json) {
            for(let prop in json) {
                if(json.hasOwnProperty(prop)) {
                    this[prop] = json[prop];
                }
            }
            this.errorTab = new ErrorTab(json.errorTab);
            json.account && (this.account = new Account(json.account));
        }

    }

    docServicesComponent: DocServicesComponent;
    errorTab: ErrorTab;
    supplementalTaskCategory: SupplementalTaskCategory;
    supplementalTaskCategoryComponent: SupplementalTaskCategoryComponent;
    deCoupleUIComponent() : void {
        this.docServicesComponent = null;
    }

    createSupplementalTaskCategoryTab(supplementalTaskCategory: SupplementalTaskCategory,  account: Account) : DocServicesTab {
        let tab :  DocServicesTab = new  DocServicesTab();
        tab.tabType = 'docServices';
        tab.account = account;
        tab.type ="Extended Workflows Category";
        tab.supplementalTaskCategory = supplementalTaskCategory;
        tab.id = supplementalTaskCategory.id || -(new Date()).getTime();;
        tab.route = 'main/doc-services/supplemental-task-category';
        return tab;
    }


    isDirty() : boolean {
        let dirty : boolean = false;
        dirty = this.hasDirtyModel();
        return dirty;
    }

    public hasDirtyModel(): boolean {
        //TODO: This will be extended to take into account all the possible model elements for different admin tabs
        return (this.account && this.account.isDirty) ||  (this.supplementalTaskCategory && this.supplementalTaskCategory.isDirty) ;
    }

    get title() : string {
         if(this.supplementalTaskCategory &&  this.supplementalTaskCategory.categoryName) {
            return this.supplementalTaskCategory.categoryName.toString();
        }
    }
    set title(title : string) {
        //Ignore
    }
    delegateSave(): Observable<boolean> | boolean {
        //TODO: this should be handled polymorphically, through different admin tab subclasses

        if(this.supplementalTaskCategoryComponent){
            return this.supplementalTaskCategoryComponent.saveChanges();
        }
    }



}
