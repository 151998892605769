import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Matter} from '../../matters/shared/matter';
import {UserDefinedField} from './user-defined-field';
import {MatterTopicKey} from '../constants';
import {currentMatter} from '../../matters/shared/current-matter';

@Component({
               selector: 'dp-user-defined-fields',
               templateUrl: './user-defined-field.components.html'
           })
export class UserDefinedFieldComponent implements OnInit {
    @Input() topicName: MatterTopicKey;
    @Input() mortgageId: number;
    @Output() onChange: EventEmitter<UserDefinedField> = new EventEmitter<UserDefinedField>();

    ngOnInit(): void {
        //console.log("UserDefinedFieldComponent, mortgageId = ", this.mortgageId + ` topic = ${this.topicName}`);
    }

    emitChange() : void {
        this.onChange.emit();
    }

    get matter() : Matter{
        return currentMatter.value;
    }

    onFieldValueChange(udf){
        udf.dirty=true;
        //console.log("udf=", udf);
    }

    isUdfVisible(udf){
        let ret = true;
        let isMortgageTopic = (udf.topicName == "MORTGAGES_MORTGAGEE" || udf.topicName == "MORTGAGES_TERMS" || udf.topicName =="MORTGAGES_REPORT");
        if((udf.fieldValue == "" || udf.fieldValue == null) && !udf.active && !udf.dirty){
            ret = false;
        }
        return ret;
    }
    public isHeaderVisible() : boolean {
        return (currentMatter.value.matterUserDefinedFields && currentMatter.value.getProvinceAwaredUserDefinedFields(this.topicName).length > 0);
    }
}
