import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class ChicagoInvoiceItem extends BaseEntity{

    id: number;
    quantity: number;
    amount: number;
    itemTotal: number;
    typeOfPolicy: string;
    typeOfPolicyFr: string;
    pstVal: number;
    gstVal: number;
    hstVal: number;
    qstVal: number;
    invoiceType: string;

    constructor(chicagoInvoiceItem?: ChicagoInvoiceItem) {
        super(chicagoInvoiceItem);
    }

    get total() : number{
        return Number(this.itemTotal) + Number(this.pstVal);
    }
}
