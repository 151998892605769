import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementAdjustmentAmountTypes, StatementAdjustmentKey} from '../statement-adjustment-constants';
import Utils from '../../../shared-main/utils';
import {SoAdjPurchasePricePercentage} from '../model/so-adj-purchase-price-percentage';
import {PERCENT_OF_OPTIONS} from '../../../shared-main/constants';
import {Matter} from '../../shared';
import {SalePriceAdjustment} from '../sale-price-adjustment/sale-price-adjustment';
import {DpBooleanValueTypes} from '../../shared/dp-boolean';

export class StatementAdjustmentDisplayPurchasePricePercentageBuilder {
    static getDisplayItems(soAdjPurchasePricePercentage: SoAdjPurchasePricePercentage, closingDate: string, mainBuilder: StatementAdjustmentDisplayBuilder, taxRateType : string, toBeUsedOnModal: boolean): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        if (!soAdjPurchasePricePercentage) {
            return sadItemDetails;
        }

        if (soAdjPurchasePricePercentage.items && !toBeUsedOnModal) {
            soAdjPurchasePricePercentage.items.forEach(itm => {
                sadItemDetails.push(mainBuilder.getNewItemLine(itm.heading,''));
            });
        }

        let creditAmount = this.getCreditAmount(soAdjPurchasePricePercentage, mainBuilder);

        sadItemDetails.push(mainBuilder.getNewItemLine(
            `Cost`,
            mainBuilder.getCurrency(creditAmount)
        ));

        if (Utils.convertToBoolean(soAdjPurchasePricePercentage.applyTax)) {
            if (taxRateType!== "GST" && soAdjPurchasePricePercentage.taxType == taxRateType) {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `${taxRateType} calculated at ${mainBuilder.getPercentage(soAdjPurchasePricePercentage.hstRate) }`,
                    mainBuilder.getCurrency(creditAmount * soAdjPurchasePricePercentage.hstRate / 100)
                ));
            }

            if (soAdjPurchasePricePercentage.taxType == "GST") {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `GST calculated at ${mainBuilder.getPercentage(soAdjPurchasePricePercentage.gstRate) }`,
                    mainBuilder.getCurrency(creditAmount * soAdjPurchasePricePercentage.gstRate / 100)
                ));
            }

            if (Utils.convertToBoolean(soAdjPurchasePricePercentage.applyPst)) {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `PST calculated at ${mainBuilder.getPercentage(soAdjPurchasePricePercentage.pstRate) }`,
                    mainBuilder.getCurrency(creditAmount * soAdjPurchasePricePercentage.pstRate / 100)
                ));
            }
        }




        if(toBeUsedOnModal){
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Credit ` + (soAdjPurchasePricePercentage.creditType == StatementAdjustmentAmountTypes.VENDOR ? mainBuilder.vendorLabel : mainBuilder.purchaserLabel),
                mainBuilder.getCurrency(soAdjPurchasePricePercentage.getTotalAmount()),
                soAdjPurchasePricePercentage.creditType === StatementAdjustmentAmountTypes.VENDOR
                )
            );
        } else {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Credit ` + (soAdjPurchasePricePercentage.creditType == StatementAdjustmentAmountTypes.VENDOR ? mainBuilder.vendorLabel : mainBuilder.purchaserLabel),'' )
            );
        }



        return sadItemDetails;
    }

    static getCreditAmount(localAdjustment: SoAdjPurchasePricePercentage, mainBuilder: StatementAdjustmentDisplayBuilder) : number {
        let percentOfAmount = this.getPercentOfAmount(localAdjustment, mainBuilder);
        let amount = localAdjustment.percent * percentOfAmount /100 ;
        if(Number(localAdjustment.percentToMaxOf) > 0){
            amount = amount <= Number(localAdjustment.percentToMaxOf) ? amount : Number(localAdjustment.percentToMaxOf);
        }
        localAdjustment.creditAmount = amount;
        return amount;
    }

    static getPercentOfAmount(localAdjustment: SoAdjPurchasePricePercentage, mainBuilder: StatementAdjustmentDisplayBuilder) : number {
        let amount = 0;
        switch (localAdjustment.percentOf) {
            case PERCENT_OF_OPTIONS.salePrice : amount = this.getAgreementSalePrice(mainBuilder.matter);break;
            case PERCENT_OF_OPTIONS.adjSalePrice : amount = this.getNetSalePrice(mainBuilder);break;
            case PERCENT_OF_OPTIONS.deposit : amount = this.getStatementOfAdjustment(StatementAdjustmentKey.DEPOSIT, mainBuilder.matter);break;
            case PERCENT_OF_OPTIONS.interestOnDeposits : amount = this.getInterestOnDeposit(mainBuilder.matter);break;
            case PERCENT_OF_OPTIONS.interestOnDepositInterest : amount = this.getInterestOnDepositInterest(mainBuilder.matter); break;
        }
        return amount;
    }

    static getAgreementSalePrice(matter : Matter) : number {
        let salePriceAdjustment : SalePriceAdjustment = matter.considerationLtt.salePriceAdjustment;
        if(salePriceAdjustment){
            return salePriceAdjustment.agreementSalePrice;
        } else {
            return 0;
        }
    }

    static getNetSalePrice(mainBuilder: StatementAdjustmentDisplayBuilder) : number {
        let salePriceAdjustment : SalePriceAdjustment = mainBuilder.matter.considerationLtt.salePriceAdjustment;
        if(salePriceAdjustment){
            if(salePriceAdjustment.netOutHstFromHSTSalePrice==DpBooleanValueTypes.NO){
                return salePriceAdjustment.agreementSalePrice;
            } else {
                return salePriceAdjustment.totalNetSalePrice(mainBuilder.federalHstRate, mainBuilder.provinceHstRate)
            }

        } else {
            return 0;
        }
    }


    /*static getInterestOnDeposit(matter: Matter) : number {
        let adjustment = matter.statementOfAdjustments.find(item => item.itemKey == StatementAdjustmentKey.INTEREST_ON_DEPOSIT_CONDO);
        if(adjustment){
            return adjustment.amount;
        } else {
            let otherSheetAdjustments = matter.isAdjustmentStatusModeFinal ? matter._interimAdjustments : matter._finalAdjustments;
            adjustment = otherSheetAdjustments.find(item => item.itemKey == StatementAdjustmentKey.INTEREST_ON_DEPOSIT_CONDO);
            if(adjustment){
                return adjustment.amount;
            } else {
                return 0;
            }
        }
    }*/

    static getInterestOnDeposit(matter: Matter) : number {
        let statementAdjustmentKey: string;
        if (matter.isMatterProvinceAB){
            statementAdjustmentKey = StatementAdjustmentKey.INTEREST_ON_DEPOSIT_UNDER_OLD_CONDO;
        }else{
            statementAdjustmentKey = StatementAdjustmentKey.INTEREST_ON_DEPOSIT_CONDO;
        }
        let adjustment = matter.findFirstAdjustmentByKey(statementAdjustmentKey,matter.isAdjustmentStatusModeFinal);
        if (adjustment){
            return adjustment.amount;
        }else{
            return 0;
        }
    }

    static getInterestOnDepositInterest(matter: Matter) : number{
        let adjustment = matter.findFirstAdjustmentByKey(StatementAdjustmentKey.INTEREST_ON_DEPOSIT_INTEREST_CONDO);
        if (adjustment){
            return adjustment.amount;
        }else{
            return 0;
        }
    }

    static getStatementOfAdjustment(statementAdjustmentKey: string, matter: Matter) : number {
        //let adjustment = matter.statementOfAdjustments.find(item => item.itemKey == statementAdjustmentKey);
        let adjustment = matter.findFirstAdjustmentByKeyInDefaultAdjustments(statementAdjustmentKey);
        if(adjustment){
            return adjustment.amount;
        } else {
            return 0;
        }
    }
}
