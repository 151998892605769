import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {CirfUdf} from './cirf-udf';

export class CirfIdentification extends BaseEntity {
    constructor(cirfIdentification?: CirfIdentification) {
        super(cirfIdentification);

        if (cirfIdentification && Array.isArray(cirfIdentification.identificationUdfs)) {
            this.identificationUdfs = cirfIdentification.identificationUdfs.map(cirfUdf => new CirfUdf(cirfUdf));
        }
    }

    identificationTypeCode: string;
    identificationNumber: string;
    placeOfIssue: string;
    expiryDate: string;
    country: string;
    identificationUdfs : CirfUdf[] = [];


    udfNotes(): string {
        let note = '';
        if (Array.isArray(this.identificationUdfs)) {
            this.identificationUdfs.forEach(udf => {
                note += udf.fieldValue ? `${udf.fieldLabel}: ${udf.fieldValue}\n` : '';
            });
        }
        return note;
    }

    indexOfFirstNonNullUdfField(): number {
        for(let i=0; i < this.identificationUdfs.length; i++){
            if(this.identificationUdfs[i].fieldValue){
                return  i;
            }
        }
        return 0;
    }

}
