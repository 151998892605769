import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {Component, OnInit} from '@angular/core';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {DialogRef} from 'ngx-modialog-7';

export class SimpleCredentialsModalContext extends BSModalContext {
    thirdParty: string;
    userName: string;
    password: string;
}

@Component({
    selector    : 'dp-simple-credentials-modal-content',
    templateUrl : 'simple-credentials.modal.component.html',
    providers   : [ErrorService]
})

export class SimpleCredentialsModalComponent implements OnInit {

    context: SimpleCredentialsModalContext;

    constructor(public dialog : DialogRef<SimpleCredentialsModalContext>){
        this.context = dialog.context;

        // ToDo Remove this after ALTO credentials requirements are completed
        this.context.thirdParty = 'ALTO';

    }

    ngOnInit(): void {
    }

    get okBtnDisabled(): boolean {
        return !this.context.userName || !this.context.password;
    }

    ok(): void{
        this.dialog.close(this.context);
    }

    close(): void{
        this.dialog.close();
    }
}
