import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogRef} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {Matter} from '../../matters/shared/matter';
import {EventData, EventLocation, EventType, TypeOfEventStatuseValue} from '../event-data';
import {SelectItem} from 'primeng/api';
import {ErrorService} from '../../shared/error-handling/error-service';
import {StatusBarService} from '../../shared-main/status-bar.service';
import {eventDropDowns} from '../event-dropdown';
import {EventService} from '../event.service';
import {DPError} from '../../shared/error-handling/dp-error';
import {EventDateRangeTypes, EventTypes, StaffAvailabilityValues} from '../event.constants';
import {Contact, ContactService, Telephone, Utils as Utils2} from '../../matters/shared';
import {DialogService} from '../../shared/dialog/dialog.service';
import moment from 'moment';
import {InviteeWrapper} from './invitee-wrapper';
import {AppointmentUtil} from './appointment-util';
import {ThirdPartyIntegrationService} from '../../shared-main/third-party/third-party-integration.service';
import {AccountIntegrationUser} from '../../admin/account-integeration/account-integration-user';
import {AccountIntegrationCredential, AccountIntegrationTypes} from '../../admin/account-integeration/account-integration-credential';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {InformInviteeModalComponent} from './inform-invitee.modal.component';
import * as _ from 'lodash';
import {SigningEnvelope, SyngrafiiPackageType} from '../../event/signing-envelope';
import {DocusignPortalModalComponent} from '../../event/custom-event/docusign-portal.modal.component';
import {DocumentsForThisMatterComponent, DocumentWrapper} from '../../matters/document-production/documents-for-this-matter.component';
import {EnvelopeDocument} from '../../event/envelope-document';
import Utils from '../../../app/shared-main/utils';
import {Document} from '../../matters/document-production/document';
import {DigitalSignaturePlatformLabel} from '../../shared-main/constants';
import {ApplicationError} from '../../core';
import {MeetingParticipant} from '../meeting-participant';
import {DPPMMultiSelectComponent} from '../../shared/checkbox/multi-select.component';
import {SyngrafiiPortalModalComponent} from './syngrafii-portal.modal.component';
import {EventAvailabilityState} from '../event-list-state';
import {EventSchedulingModalComponent} from './event-scheduling.modal.component';
import {SyngrafiiAccountConfiguration} from '../syngrafii-account-configuration';
import {messages} from '../../common/messages';
import {RemoteSigningConfigurationService} from '../../admin/remote-signing-configuration/remote-signing-configuration.service';


export class CustomEventModalContext extends BSModalContext {
    matter: Matter;
    scheduledForList: any;
    selectedDocumentWrappers: DocumentWrapper[];
    eventData: EventData;
    documentViewMode : string;
    treeModel : any;
    startDate: Date;
    openFromEventList : boolean;
}

@Component({
    selector: 'dp-custom-event-modal',
    templateUrl: 'custom-event.modal.component.html',
    styleUrls: [
        './custom-event.modal.component.scss'
    ],
    providers: [ErrorService, StatusBarService]
})


export class CustomEventModalComponent implements OnInit {
    context: CustomEventModalContext;
    eventStatusOptions: SelectItem[];
    eventLocationOptions: SelectItem[];
    eventDocsStatusOptions: SelectItem[];
    staffAvailabilityOptions: SelectItem[];
    utils = new Utils2();
    dirty: boolean;
    eventSubtypeOptions: SelectItem[];
    filteredEventSubtypeOptions: any[];
    eventInvitees: InviteeWrapper[];
    meetingParticipants: MeetingParticipant[];
    selectedMeetingParticipantsIds : string[] = [];
    staffInvitees: InviteeWrapper[];
    selectedStaffInviteesIds : string[] = [];
    staffMeetingParticipants: MeetingParticipant[];
    zoomCredentialOptions: SelectItem[] = [];
    zoomAccountOptions: SelectItem[] = [];
    msTeamsAccountOptions: SelectItem[] = [];
    googleAccountOptions: SelectItem[] = [];
    lastInvitee: InviteeWrapper = undefined;
    alreadyEmailedInvitees: InviteeWrapper[] = [];
    eventData: EventData;
    isNew: boolean;
    isAttendeesShutterExpanded: boolean = false;
    documentAccordion: boolean = false;
    convertToDigitalSigningFlag : boolean = false; //tracks when user selects Sign with DocuSign/Syngrafii
    defaultSyngrafiiPackageType: SyngrafiiPackageType;
    auditVideoLinks: string[] = [];
    solicitorContacts: Contact[];
    syngrafiAccountConfiguration:SyngrafiiAccountConfiguration;
    syngrafiiCredentialOptions: SelectItem[] = [];

    @ViewChild('documentsForThisMatterComponent') documentsForThisMatterComponent: DocumentsForThisMatterComponent;
    @ViewChild('multiSelectBox') dPPMMultiSelectComponent : DPPMMultiSelectComponent;

    constructor(public dialog: DialogRef<CustomEventModalContext>,
        public eventService: EventService,
        public errorService: ErrorService,
        public dialogService: DialogService,
        public thirdPartyIntegrationService: ThirdPartyIntegrationService,
        public remoteSigningConfigurationService: RemoteSigningConfigurationService,
        public contactService: ContactService) {
        this.context = dialog.context;
    }

    checkAnyDeletedParticipantsReUsed(){
        if((this.eventData.deletedMeetingParticipants && this.eventData.deletedMeetingParticipants.length > 0)
            && (this.meetingParticipants && this.meetingParticipants.length > 0)){
            this.eventData.deletedMeetingParticipants.forEach(deleteItem=>{
                let meetingParticipant : MeetingParticipant
                    = this.meetingParticipants.find(meetingParticipant=> meetingParticipant.participantIdOnMatter == deleteItem.participantIdOnMatter);
                if(meetingParticipant && (meetingParticipant.id != deleteItem.id)){
                    meetingParticipant.id = deleteItem.id;
                }
            })
        }
    }

    ngOnInit() {
        this.eventStatusOptions = eventDropDowns.eventStatusOptions;
        this.eventSubtypeOptions = eventDropDowns.eventSubtypeOptions;
        this.staffAvailabilityOptions = eventDropDowns.staffStatusOptions;
        if (this.context.eventData) {
            this.eventData = new EventData(this.context.eventData);
        } else {
            this.isNew = true;
            this.createNewAppointment();
            this.isAttendeesShutterExpanded = true;
        }
        if (this.context.selectedDocumentWrappers && this.context.selectedDocumentWrappers.length) {
            this.eventData.signingDigitally = true;
            this.documentAccordion = true;
            this.createOrUpdateDigitalSigningEnvelope();
            this.eventData.signingEnvelope.envelopeDocuments = [];
            this.context.selectedDocumentWrappers.forEach(dc => {
                this.createEnvelopeDocument(dc);
            })
            this.setDefaultPackageType();
        }

        if(!this.eventData.scheduledForSolicitorId){
            let selectedSolicitor: any;
            if (this.context.matter && this.context.matter.selectedSolicitorId ) {
                selectedSolicitor = this.context.scheduledForList.find(item => item.value == this.context.matter.selectedSolicitorId);
            }
            if(this.isStaffEvent()){
                let currentUserContactId = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.userContactId));
                selectedSolicitor = this.context.scheduledForList.find(item => item.value == currentUserContactId);
            }
            if (selectedSolicitor) {
                this.eventData.scheduledForSolicitorId = selectedSolicitor.value;
            }
        }


        this.contactService.getSolicitorLawClerkList().subscribe((contacts: Contact[]) => {
            this.solicitorContacts = contacts;
        });

        this.initializeEventLocationOptions();
        if(this.isMatterEvent()){
            this.initializeDocumentStatusOptions();
            this.getSyngrafiiDownloadLinks();
            this.loadMeetingParticipants();
        }
        this.loadStaffMeetingParticipants()
        this.loadSyngrafiiAccountConfiguration();

    }

    async loadSyngrafiiAccountConfiguration(): Promise<void>{
        if(this.eventData && this.eventData.isDigitalSignPlatformSyngrafii() && this.eventData.virtualSigningAccountIntegrationId){
            await this.getSyngrafiiAccountConfiguration();
        }
    }

    private createEnvelopeDocument(dc: DocumentWrapper): void {
        let envelopeDocument = new EnvelopeDocument();
        envelopeDocument.sharedDocumentType = Document.isThirdPartyDocumentType(dc.generatedDocumentType) ? "THIRD_PARTY" : 'UNITY';
        envelopeDocument.sourceDocumentId = dc.documentId;
        envelopeDocument.requiredAction = 'CREATED';
        this.eventData.signingEnvelope.envelopeDocuments.push(envelopeDocument);
    }

    initializeDocumentStatusOptions() {
        if (this.eventData.isAppointment()) {
            this.eventDocsStatusOptions = eventDropDowns.eventDocsStatusOptions;
            if (!this.eventData.eventDocsStatus) {
                this.eventData.eventDocsStatus = "DOCS_PENDING";
            }
        }
    }

    initializeEventLocationOptions() {
        this.eventLocationOptions = Array.from(eventDropDowns.eventLocationOptions);

        if (!this.eventData.eventLocation) {
            this.eventData.eventLocation = 'IN_PERSON';
        }

        if(this.isSameStartAndEndDate()){
            //check if Zoom account credential exists before showing Zoom as a meeting option
            this.zoomCredentialOptions = [];
            this.thirdPartyIntegrationService.getAccountIntegrationCredentials(AccountIntegrationTypes.zoom, this.getAccountId())
                .subscribe((accountIntegrationCredentials: AccountIntegrationCredential[]) => {
                    if (accountIntegrationCredentials && accountIntegrationCredentials.length) {
                        this.eventLocationOptions.push({ label: 'Zoom', value: 'ZOOM' });
                        if (accountIntegrationCredentials.length > 1) {
                            this.zoomCredentialOptions.push({ label : '', value : ''});
                        }
                        accountIntegrationCredentials.forEach((accountIntegrationCredential: AccountIntegrationCredential) => {
                            this.zoomCredentialOptions.push({label: accountIntegrationCredential.credentialLabel, value: accountIntegrationCredential.id});
                        });

                        let accessGrantId: number = this.eventData.virtualMeetingAccountIntegrationId;
                        if (!accessGrantId) {
                            accessGrantId = this.zoomCredentialOptions[0].value;
                        }

                        this.getZoomAccountUsers(accessGrantId);
                    }
                });
            this.thirdPartyIntegrationService.getAccountIntegrationCredentials(AccountIntegrationTypes.msTeams, this.getAccountId())
                .subscribe((accountIntegrationCredentials: AccountIntegrationCredential[]) => {
                    if (accountIntegrationCredentials && accountIntegrationCredentials.length) {
                        this.eventLocationOptions.push({ label: 'MS Teams', value: 'MS_TEAMS' });
                        this.getMSTeamsAccountUsers();
                    }
                });
            this.thirdPartyIntegrationService.getAccountIntegrationCredentials(AccountIntegrationTypes.googleMeet, this.getAccountId())
                .subscribe((accountIntegrationCredentials: AccountIntegrationCredential[]) => {
                    if (accountIntegrationCredentials && accountIntegrationCredentials.length) {
                        this.eventLocationOptions.push({ label: 'Google Meet', value: 'GOOGLE_MEET' });
                        this.getGoogleAccountUsers();
                    }
                });
            this.syngrafiiCredentialOptions = [];
            this.thirdPartyIntegrationService.getAccountIntegrationCredentials(AccountIntegrationTypes.syngrafii, this.getAccountId())
                .subscribe((accountIntegrationCredentials: AccountIntegrationCredential[]) => {
                    if (accountIntegrationCredentials && accountIntegrationCredentials.length) {
                        if (accountIntegrationCredentials.length > 1) {
                            this.syngrafiiCredentialOptions.push({ label : '', value : ''});
                        } else if(accountIntegrationCredentials.length == 1 && this.eventData && !this.eventData.virtualSigningAccountIntegrationId) {
                            this.eventData.virtualSigningAccountIntegrationId = accountIntegrationCredentials[0].id;
                            this.loadSyngrafiiAccountConfiguration();
                        }
                        accountIntegrationCredentials.forEach((accountIntegrationCredential: AccountIntegrationCredential) => {
                            this.syngrafiiCredentialOptions.push({label: accountIntegrationCredential.credentialLabel, value: accountIntegrationCredential.id});
                        });
                    }
                });

        }
    }

    //get all Zoom users associated with this customer's account.
    getZoomAccountUsers(accessGrantId: number): void {
       if (accessGrantId) {
           this.zoomAccountOptions = [];
           this.thirdPartyIntegrationService.getUsersByAccessGrant(AccountIntegrationTypes.zoom, accessGrantId).subscribe(
               (user: AccountIntegrationUser[]) => {
                   user.forEach((user: AccountIntegrationUser) => {
                       this.zoomAccountOptions.push({ label: user.fullName, value: user.id });
                   });
                   this.zoomAccountOptions = _.sortBy(this.zoomAccountOptions, ['label']);
                   if (this.zoomAccountOptions.length == 1) {
                       this.eventData.externalUserId = this.zoomAccountOptions[0].value;
                       this.eventData.externalUserName = this.zoomAccountOptions[0].label;
                   }
               }, err => {
                   // When user token is expired
                   this.zoomAccountOptions = [];
               });
       }
    }

    getMSTeamsAccountUsers(): void {
        this.msTeamsAccountOptions = [];
        this.thirdPartyIntegrationService.getUsers(AccountIntegrationTypes.msTeams).subscribe(
            (user: AccountIntegrationUser[]) => {
                user.forEach((user: AccountIntegrationUser) => {
                    if (!user.isNullOrEmpty(user.lastName)) {
                        this.msTeamsAccountOptions.push({ label: user.fullName, value: user.id });
                    }
                });
            });
    }

    getGoogleAccountUsers(): void {
        this.googleAccountOptions = [];
        this.thirdPartyIntegrationService.getUsers(AccountIntegrationTypes.googleMeet).subscribe(
            (user: AccountIntegrationUser[]) => {
                user.forEach((user: AccountIntegrationUser) => {
                    if (!user.isNullOrEmpty(user.displayName)) {
                        this.googleAccountOptions.push({ label: user.displayName, value: user.id });
                    }
                });
                this.googleAccountOptions = _.sortBy(this.googleAccountOptions, ['label']);
            });

    }

    getAccountId(): number {
        return Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId));
    }

    loadMeetingParticipants() {
        this.meetingParticipants = AppointmentUtil.loadMeetingParticipants(this.context.matter, this.eventData.meetingParticipants);
        // remove invitees that are not on the matter anymore
        if (this.eventData && this.eventData.meetingParticipants && this.eventData.meetingParticipants.length) {
            this.eventData.meetingParticipants = this.eventData.meetingParticipants.filter(meetingParticipant =>
                meetingParticipant.isStaffParticipant() ||
                (this.meetingParticipants.findIndex(mp => Number(mp.participantIdOnMatter) == meetingParticipant.participantIdOnMatter) > -1));
        } else {
            this.convertInviteeParticipantIdsToMeetingParticipants();
        }
        if(this.eventMatterParticipants && this.eventMatterParticipants.length){
            this.selectedMeetingParticipantsIds = this.eventMatterParticipants.map(mp=>String(mp.id));
        }
        this.checkAnyDeletedParticipantsReUsed();
        this.loadMatterInvitees();
    }

    loadStaffMeetingParticipants() : void {
        //Remove the N/A option from scheduledForList
        let staffList = this.context.scheduledForList.filter(item=>item.value!='N/A');
        if(this.eventData && this.eventData.scheduledForSolicitorId){
            //Remove the scheduledForSolicitorId option from available staff option
            staffList = staffList.filter(item=>item.value != this.eventData.scheduledForSolicitorId);
        }
        this.staffMeetingParticipants = AppointmentUtil.loadStaffMeetingParticipants(staffList, this.eventData.meetingParticipants);
        if (this.eventStaffParticipants && this.eventStaffParticipants.length) {
            this.selectedStaffInviteesIds = this.eventStaffParticipants.map(mp=>String(mp.id));
        }
        this.loadStaffInvitees();
    }

    // converts inviteeParticipantIds to meetingParticipants to take care of existing appoitnments
    convertInviteeParticipantIdsToMeetingParticipants() : void {
        AppointmentUtil.convertInviteeParticipantIdsToMeetingParticipants(this.eventData, this.meetingParticipants);
    }

    loadMatterInvitees() {
        this.eventInvitees = AppointmentUtil.createEventInviteesFromMeetingParticipants(this.meetingParticipants);
        if (this.eventInvitees && this.eventInvitees.length > 1){
            this.eventInvitees.unshift({
                label: 'All Clients',
                secondLabel: '   ',
                value: '-1',
                email : ''
            });
        }
    }

    loadStaffInvitees() {
        this.staffInvitees = AppointmentUtil.createEventInviteesFromMeetingParticipants(this.staffMeetingParticipants);
        if (this.staffInvitees && this.staffInvitees.length > 1){
            this.staffInvitees.unshift({
                label: 'All Staff',
                secondLabel: '',
                value: '-1',
                email : ''
            });
        }
    }

    createNewAppointment() {
        this.eventData = new EventData();
        if (this.isMatterEvent()) {
            this.eventData.matterId = this.matterId;
            this.eventData.matterType = this.context.matter.matterType;
            this.eventData.matterRecordNumber = this.context.matter.matterRecordNumber;
            this.eventData.clientReLine = this.context.matter.clientReLine;
            this.eventData.showStaffAvailabilityAs = StaffAvailabilityValues.Busy;
        } else {
            this.eventData.showStaffAvailabilityAs = StaffAvailabilityValues.OutOfOffice;
            if(this.context.startDate){
                this.eventData.startDate = moment(this.context.startDate).format("YYYY/MM/DD");
                this.eventData.endDate = this.eventData.startDate;
            }
        }
        this.eventData.eventType = <EventType>EventTypes.APPOINTMENT;
        this.eventData.eventStatus = TypeOfEventStatuseValue.UNSPECIFIED;
        this.eventData.allDayEvent = false;
        this.eventData.eventDocsStatus = "DOCS_PENDING";
        this.loadEventTimes();

    }

    loadEventTimes(): void {
        if (this.eventData) {
            this.eventData.startTime = moment().add('m', 30 - moment().minute() % 30).format('hh:mm A');
            this.eventData.endTime = moment().add('m', 60 - moment().minute() % 30).format('hh:mm A');
        }

    }

    dateTimeChange(event: any): void {
        this.markDirty();
        if (this.eventData) {
            this.eventData.startDate = event.startDate;
            this.eventData.endDate = event.endDate;
            this.eventData.startTime = event.startTime;
            this.eventData.endTime = event.endTime;
            this.eventData.allDayEvent = event.allDayEvent;
        }
        this.initializeEventLocationOptions();

    }

    async checkInviteeIsPreviouslyDeleted(): Promise<boolean>{
        let doNotContinueSave = false;
        let previouslyDeletedMeetingParticipants : MeetingParticipant[] = this.isAnySelectedInviteesPreviouslyDeleted();
        if(previouslyDeletedMeetingParticipants && previouslyDeletedMeetingParticipants.length  > 0){
            let response: any = await this.dialogService.confirm('Confirmation',this.getInviteeIsPreviouslyDeletedWarnMsg(previouslyDeletedMeetingParticipants) ,
                                                                 false, 'Yes', null, true,null, null,null, null, null, 'NO', 'No').toPromise();
            if (response == 'NO') { // Click "No" button
                //Unselect Invitees are previously deleted
                previouslyDeletedMeetingParticipants.forEach(item=> {
                    //item.id +'' for changing number to string
                    let index : number = this.selectedMeetingParticipantsIds.findIndex(selectedInvitee => selectedInvitee == (item.id +''));
                    if(index > -1) {
                        this.selectedMeetingParticipantsIds.splice(index, 1);
                    }
                    let indexMeetingParticipant : number = this.eventData.meetingParticipants.findIndex(meetingParticipant => meetingParticipant.id == item.id);
                    if(indexMeetingParticipant > -1) {
                        this.eventData.meetingParticipants.splice(indexMeetingParticipant, 1);
                    }
                });
                //this.selectedMeetingParticipantsIds is the "multiSelectDataSelected" output variable of invitees DPPMMultiSelectComponent.
                // It records the selected Meeting Participants Ids of invitees.
                // At the beginning, only do splice, the UI view didn't unselect invitees which are previously deleted.
                // We need to call refreshSelectedValues to update the UI view.
                if(this.dPPMMultiSelectComponent) {
                    this.dPPMMultiSelectComponent.refreshSelectedValues();
                }

                doNotContinueSave = true;
            } else if(!response) { // Click "Cancel" button
                doNotContinueSave = true;
            } else { // Click "Yes" button
                // Do nothing and continue saveEvent
            }
        }
        return doNotContinueSave;
    }

    isAnySelectedInviteesPreviouslyDeleted(): MeetingParticipant[]{
        let previouslyDeletedMeetingParticipants : MeetingParticipant[] = [];
        if(this.eventData.meetingParticipants && this.eventData.deletedMeetingParticipants
            && this.eventData.meetingParticipants.length > 0 && this.eventData.deletedMeetingParticipants.length) {
            this.eventData.meetingParticipants.forEach(meetingParticipant => {
                if(this.eventData.deletedMeetingParticipants.find(deletedMeetingParticipant => deletedMeetingParticipant.id == meetingParticipant.id)) {
                    previouslyDeletedMeetingParticipants.push(meetingParticipant);
                }
            });
        }
        return previouslyDeletedMeetingParticipants;
    }

    getInviteeIsPreviouslyDeletedWarnMsg(previouslyDeletedMeetingParticipants : MeetingParticipant[]): string {
        let warnMsg: string = '';
        if(previouslyDeletedMeetingParticipants && previouslyDeletedMeetingParticipants.length > 0) {
            let nameList: string[] = previouslyDeletedMeetingParticipants.map(item=>item.name);
            let formattedName: string = nameList.length < 2
                ? nameList.join(', ')
                : nameList.slice(0, -1).join(', ') + ' and ' + nameList.slice(-1);

            warnMsg = formattedName + ', '
                + (previouslyDeletedMeetingParticipants.length > 1 ? 'the invitees' : 'the invitee')
                + ' you are attempting to add to the DocuSign envelope was previously deleted as a recipient in the envelope.'
                + ' DocuSign will not display the signing line for that recipient in documents already included in the envelope.'
                + ' We suggest that you delete the current DocuSign envelop and create a new one.';
            warnMsg += '\n\n';
            warnMsg += 'Do you wish to continue adding the invitee as a recipient?'
        }

        return warnMsg;
    }

    /**
     * Create a new event or update existing event in back-end
     *
     * @param ignoreConflicts - force a create or update of appointment even if there is a conflict for that time slot
     * @param isConfirmDialogRequired - check whether to show the confirmation message after creating/updating the appointment
     * @param sendSyngrafiiPackage - a flag for sending the syngrafii package to receptients
     * @return true if save was successful or false if there was an error (or cancelled)
     */
    async saveEvent(ignoreConflicts?: boolean, isConfirmDialogRequired: boolean = true, sendSyngrafiiPackage?:boolean): Promise<boolean> {
        if (this.eventData.isCancelled() && (this.eventData.isZoomMeeting() || this.eventData.isMSTeamsMeeting() || this.eventData.isGoogleMeeting()) && this.eventData.externalMeetingId) {

            let warningMsg = 'Do you wish to cancel this appointment and delete the  '+ this.getLocationLabel() + ' meeting?';
            let proceedWithCancel: boolean = await this.dialogService.confirm('Confirmation',warningMsg , false, 'Proceed').toPromise();
            if (!proceedWithCancel) {
                return;
            } else {
                this.clearExternalMeetingInfo();
            }
        }

        try {
            this.createOrUpdateDigitalSigningEnvelope();
            this.eventData.clientOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.updateTimestamps();
            if (this.eventData.id) {
                this.eventData = await this.eventService.updateEvent(this.matterId, this.eventData, ignoreConflicts, sendSyngrafiiPackage).toPromise();
                this.constructEventMeetingParticipants();
                this.updateModalData();
                if (this.convertToDigitalSigningFlag && this.eventData.signingEnvelope && this.eventData.signingEnvelope.thirdPartyEnvelopeId) {
                    this.convertToDigitalSigningFlag = false;
                    if (this.isDigitalSignPlatformDocuSign()) {
                        this.openDocuSignPortal();
                    } else if (this.isDigitalSignPlatformSyngrafii() && !sendSyngrafiiPackage) {
                        this.openSyngrafiiPortal();
                    }
                } else if (isConfirmDialogRequired) {
                    this.dialogService.confirm('INFORMATION', 'Appointment Updated Successfully', true);
                }

            } else {
                this.eventData = await this.eventService.createEvent(this.matterId, this.eventData, ignoreConflicts).toPromise();
                this.constructEventMeetingParticipants();
                this.updateModalData();
                if(this.eventData.signingEnvelope && this.eventData.signingEnvelope.thirdPartyEnvelopeId && this.isDigitalSignPlatformDocuSign()) {
                    this.openDocuSignPortal();
                }if(this.eventData.signingEnvelope && this.eventData.signingEnvelope.thirdPartyEnvelopeId && this.isDigitalSignPlatformSyngrafii()) {
                    this.openSyngrafiiPortal();
                } else if(isConfirmDialogRequired) {
                    this.dialogService.confirm('INFORMATION', 'Appointment Added Successfully', true);
                }
            }
             return true;
        } catch (error) {
            if (error.errorCode == 'app.appointment.conflict') {
                let message = this.buildConflictMessage(error.message);
                let proceedWithSave: boolean = await this.dialogService.confirm('WARNING', message, false, 'Proceed').toPromise();
                if (proceedWithSave) {
                    let isSaved: boolean = await this.saveEvent(true, true, sendSyngrafiiPackage);
                    return isSaved;
                }
            } else {
                this.handleErrorMessages(error);
            }
            //save was not successful
            return false;
        }
    }

    buildConflictMessage(commaSeparatedIds: string) : string {
        let message = 'The following individual(s) has/have a conflicting appointment.  Do you wish to proceed?<br>';
        if(commaSeparatedIds){
            let conflictIds : string[] = commaSeparatedIds.split(',');
            if(conflictIds && conflictIds.length){
                conflictIds.forEach((id)=>{
                    let scheduledFor = this.context.scheduledForList.find(item=>item.value == Number(id));
                    if(scheduledFor){
                        message += '<br>' + scheduledFor.label;
                    }
                })
            }
        }
        return message;
    }

    //Update eventData.meetingParticipants array with the UI only fields.
    constructEventMeetingParticipants() : void {
        if(this.eventData && this.eventData.meetingParticipants && this.eventData.meetingParticipants.length){
            let meetingParticipants : MeetingParticipant[] = [];
            let allMeetingParticipants : MeetingParticipant[] = [];
            this.eventData.meetingParticipants.forEach((existingMp)=>{
                if(this.meetingParticipants && this.meetingParticipants.length){
                    allMeetingParticipants = [...this.meetingParticipants];
                }
                if(this.staffMeetingParticipants && this.staffMeetingParticipants.length){
                    allMeetingParticipants = [...allMeetingParticipants, ...this.staffMeetingParticipants];
                }

                let meetingParticipant = allMeetingParticipants.find(mp=>mp.id == existingMp.id);
                if(meetingParticipant){
                    meetingParticipants.push(meetingParticipant);
                }
            });
            this.eventData.meetingParticipants = meetingParticipants;
        }
    }

    //uses the startDate/endDate and startTime/endTime to populate the timestamp values (ISO8601 format) before saving appointment
    updateTimestamps(): void {
        if (this.eventData.startDate && this.eventData.startTime) {
            let startMoment: moment.Moment = moment(this.eventData.startDate + ' ' + this.eventData.startTime, 'YYYY/MM/DD hh:mm A');
            this.eventData.startTimestamp = startMoment.toISOString();
        }

        if (this.eventData.endDate && this.eventData.endTime) {
            let endMoment: moment.Moment = moment(this.eventData.endDate + ' ' + this.eventData.endTime, 'YYYY/MM/DD hh:mm A');
            this.eventData.endTimestamp = endMoment.toISOString();
        }
    }

    handleErrorMessages(error: ApplicationError): void {
        if (error.errorCode == 'app.appointment.matterEventVersionField') {
            let errorMessage = 'Another user has updated this appointment.  Please reopen this appointment to see those changes.' +
                '<br><br>Note that you will have to reapply your updates.';
            this.dialogService.confirm('ERROR', errorMessage, true);
        } else {
            this.errorService.addDpSaveError(DPError.createCustomDPError(error.errorCode, (error.errorCode ? error.errorCode + ': ' : '') + error.message, null, "ERROR"));
        }
    }

    updateDocuSignEnvelope(id: number): void {
        let envelopeDocument = this.eventData.signingEnvelope.envelopeDocuments.find(ed => ed.sourceDocumentId == id);
        if (envelopeDocument) {
            envelopeDocument.requiredAction = 'UPDATED';
            this.markDirty();
        }
        this.dialogService.confirm('Information', `The ${this.signingPackageLabel} will be updated with the updated document upon saving of the appointment`, true);

    }

    createOrUpdateDigitalSigningEnvelope(): void {
        if (this.eventData.signingDigitally) {
            if (!this.eventData.signingEnvelope) {
                this.eventData.signingEnvelope = new SigningEnvelope();
                this.eventData.signingEnvelope.digitalSigningPlatform = this.context.matter.digitalSignaturePlatform;
                this.eventData.signingEnvelope.envelopSubject = this.eventData.eventDescription;
                this.eventData.signingEnvelope.envelopeDocuments = [];
            }
            if (this.selectedDocumentWrapperList().length > 0) {
                this.selectedDocumentWrapperList().forEach(dc => {
                    let envelopeDocument = this.eventData.signingEnvelope.envelopeDocuments.find(ed => ed.sourceDocumentId == dc.documentId);
                    if (!envelopeDocument) {
                        this.createEnvelopeDocument(dc);
                    }
                    else if (envelopeDocument.isDocumentDeleted()) {
                        envelopeDocument.requiredAction = null;
                    }
                })
            }
        } else {
            this.eventData.signingEnvelope = undefined;
        }
    }

    async openSyngrafiiPortal(): Promise<void> {
        if (this.dirty || this.selectedDocumentWrapperList().length > 0) {
            let response: any = await this.dialogService.confirmUnsavedChange(true, undefined, false).toPromise();
            if (response == 'SAVE') {
                this.validateFields();
                if (this.hasNoErrors()) {
                    let isSaved = await this.saveEvent();
                    if (isSaved) {
                        this.getSyngrafiiViewUrlAndOpenPortal();
                    }
                }
            }
        } else {
            this.getSyngrafiiViewUrlAndOpenPortal();
        }

    }

    async openDocuSignPortal(): Promise<void> {
        if (this.dirty || this.selectedDocumentWrapperList().length > 0) {
            let response: any = await this.dialogService.confirmUnsavedChange(true, undefined, false).toPromise();
            if (response == 'SAVE') {
                this.validateFields();
                if (this.hasNoErrors()) {
                    let isSaved = await this.saveEvent();
                    if (isSaved) {
                        this.getDocuSignViewUrlAndOpenPortal();
                    }
                }
            }
        } else {
            this.getDocuSignViewUrlAndOpenPortal();
        }

    }

    getDocuSignViewUrlAndOpenPortal(): void {
        if (this.eventData.signingEnvelope && this.eventData.signingEnvelope.thirdPartyEnvelopeId) {
            this.eventService.getDocuSignUrl(this.matterId, this.eventData.id).subscribe(
                url => {
                    if (url) {
                        url = url + "&showHeaderActions=false&showBackButton=false";
                        this.dialogService.content({
                            content: DocusignPortalModalComponent,
                            fullScreen: true,
                            widthXl: true,
                            context: {
                                title: "DocuSign",
                                securedUrl: url
                            },
                            onFulfillment: (res) => {
                                if (res && res.event == 'Send') {
                                    this.sendDocuSignEnvelope();
                                }
                            }
                        });
                    }
                }
            )
        }
    }

    getSyngrafiiViewUrlAndOpenPortal(): void {
        if (this.eventData.signingEnvelope && this.eventData.signingEnvelope.thirdPartyEnvelopeId) {
            this.eventService.getSyngrafiiUrl(this.matterId, this.eventData.id).subscribe(
                url => {
                    if (url) {
                        this.dialogService.content({
                            content: SyngrafiiPortalModalComponent,
                            fullScreen: true,
                            widthXl: true,
                            context: {
                                title: "Syngrafii",
                                securedUrl: url
                            },
                            onFulfillment: (res) => {
                                //no action needed after closing Syngrafii portal
                            }
                        });
                    }
                }
            )
        }
    }

    updateModalData(): void {
        this.dirty = false;
        this.isNew = false;
        this.context.eventData = this.eventData;
        this.updateDigitalSignaturePlatformForDocuments(true);
    }

    removeVirtualSigningAccountIntegrationId() {
        if(!this.isSyngrafii() && this.isNew) {
            this.eventData.virtualSigningAccountIntegrationId = null;
        }
    }

    async validateAndSave(): Promise<void> {
        this.removeVirtualSigningAccountIntegrationId();
        this.validateFields();
        if (!this.hasNoErrors()) {
            return;
        }

        await this.saveEvent();
    }

    validateFields(): void {
        this.errorService.removeAllDpFieldError();
        this.errorService.clearAllSaveErrors();

        if(!!this.eventData.signingDigitally && this.isSyngrafii() && !this.eventData.virtualSigningAccountIntegrationId && this.syngrafiiCredentialOptions && this.syngrafiiCredentialOptions.length > 1){
            this.errorService.addDpSaveError(DPError.createCustomDPError('customEvent.eventData.accountSyngrafiiCredential',
                'Selection of a Syngrafii Account is required when signing documents with Syngrafii.', null, 'ERROR'));
        }

        if (this.eventData.isDigitalSignPlatformSyngrafii()) {
            if (this.context.matter && this.context.matter.selectedSolicitorId ) {
                let selectedSolicitor = this.context.scheduledForList.find(item => item.value == this.context.matter.selectedSolicitorId);
                // Use !! to filter ""
                if (selectedSolicitor && !!selectedSolicitor.mobileNumber && !Telephone.isPhoneValid(selectedSolicitor.mobileNumber)) {
                    this.errorService.addDpFieldError(DPError.createCustomDPError('solicitor_phone','Matter Opening> Invalid cell phone number for ' + selectedSolicitor.label, null, 'ERROR'));
                }
            }

            if(this.eventMatterParticipants && this.eventMatterParticipants.length){
                // Use !! to filter ""
                let participantWithInvalidPhones = this.eventMatterParticipants.filter( mp => !!mp.mobileNumber && !Telephone.isPhoneValid(mp.mobileNumber));
                if (participantWithInvalidPhones.length) {
                    participantWithInvalidPhones.map(mp => {
                        this.errorService.addDpFieldError(DPError.createCustomDPError( 'signers_ ' + mp.id,mp.tabLabel + '> Invalid cell phone number for ' + mp.name,  null, 'ERROR'));
                    })
                }
            }
        }

        if (!this.eventData.startDate) {
            this.errorService.addDpFieldError(DPError.createDPError('dpDateTimePicker.startDate.customEvent.eventData'));
        }
        if(!this.eventData.endDate) {
            this.errorService.addDpFieldError(DPError.createDPError('dpDateTimePicker.endDate.customEvent.eventData'));
        }
        if(!Utils.isValidDate(this.eventData.startDate)) {
            this.errorService.addDpFieldError(DPError.createDPError('dpDateTimePicker.startDate.customEvent.eventData.INVALID'));
        }
        if(!Utils.isValidDate(this.eventData.endDate)) {
            this.errorService.addDpFieldError(DPError.createDPError('dpDateTimePicker.endDate.customEvent.eventData.INVALID'));
        }
        if(Utils.getDateDiff(this.eventData.startDate, this.eventData.endDate) < 0) {
            this.errorService.addDpFieldError(DPError.createCustomDPError('dpDateTimePicker.endDate.customEvent.eventData',
                'End date must be greater than or equal to the Start date', null, 'ERROR'));
        }
        if (!this.eventData.scheduledForSolicitorId || this.eventData.scheduledForSolicitorId.toString() === 'N/A') {
            this.errorService.addDpFieldError(DPError.createDPError('customEvent.eventData.scheduledFor'));
        }
        if (!this.eventData.eventDescription) {
            this.errorService.addDpFieldError(DPError.createDPError('customEvent.eventData.eventDescription'));
        }
        if(this.eventData.signingDigitally){
            if(!this.eventMatterParticipants || this.eventMatterParticipants && !this.eventMatterParticipants.length){
                this.errorService.addDpFieldError(DPError.createDPError('customEvent.eventData.invitees'));
            } else {
                if(this.isDigitalSignPlatformSyngrafii() && this.anyMeetingParticipantsWithDuplicateEmails()){
                    this.errorService.addDpFieldError(DPError.createDPError('customEvent.eventData.inviteesDuplicateEmailsForSyngrafii'));
                }
                if (this.anyMeetingParticipantsWithoutEmails()) {
                    this.errorService.addDpFieldError(DPError.createDPError('customEvent.eventData.inviteesWithoutValidEmails'));
                }
            }
        }
        if(this.eventData.isDigitalSignPlatformSyngrafii() && (this.isMobileNumberMissingInMeetingParticipant() || this.isMobileNumberMissingInScheduledForSolicitor()) && this.isTwoFactorAuthenticationEnabled()){
            this.errorService.addDpFieldError(DPError.createCustomDPError('none',
                this.createErrorMessageForParticipantMissingCellPhone(), null, 'ERROR',
                null,null, null, null, null, null, null, null, true));
        }

        //User/Account Id required if Zoom/Microsoft/Google meeting is selected.
        if (this.eventData.isAppointment() && !this.eventData.externalUserId) {
            if (this.eventData.eventLocation == 'ZOOM') {
                this.errorService.addDpFieldError(DPError.createDPError('customEvent.eventData.zoomUserId'));
            } else if (this.eventData.eventLocation == 'MS_TEAMS') {
                this.errorService.addDpFieldError(DPError.createDPError('customEvent.eventData.msUserId'));
            } else if (this.eventData.eventLocation == 'GOOGLE_MEET') {
                this.errorService.addDpFieldError(DPError.createDPError('customEvent.eventData.googleAccount'));
            }
        }
        if (this.eventData && this.eventData.signingDigitally &&
            this.eventData.signingEnvelope && this.eventData.signingEnvelope.envelopeDocuments.filter(doc => !doc.isDocumentDeleted()).length == 0 && this.selectedDocumentWrapperList().length == 0) {
            let errorKey = this.isDigitalSignPlatformDocuSign() ? 'matter.appointment.docuSign.document.required' : 'matter.appointment.syngrafii.document.required';
            this.errorService.addDpFieldError(DPError.createDPError(errorKey));
        }
    }

    isMobileNumberMissingInMeetingParticipant():boolean{
        return this.eventData.meetingParticipants.filter(participant => participant.meetingParticipantType != 'STAFF_PARTICIPANT').some(meetingParticipant => Utils.isEmptyString(meetingParticipant.mobileNumber));
    }

    isMobileNumberMissingInScheduledForSolicitor(): boolean{
        let scheduledForSolicitorData = this.getScheduledForSolicitorDetails(this.eventData.scheduledForSolicitorId);
        return  scheduledForSolicitorData && Utils.isEmptyString(scheduledForSolicitorData.mobileNumber)
    }

    async getSyngrafiiAccountConfiguration():Promise<void>{
        const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        this.syngrafiAccountConfiguration = await this.eventService.getSyngrafiiDefaultConfiguration(accountId, this.eventData && this.eventData.virtualSigningAccountIntegrationId).toPromise();
    }

    isTwoFactorAuthenticationEnabled(): boolean {
        if(this.eventData.isPackageTypeSequential()){
            return this.syngrafiAccountConfiguration && this.syngrafiAccountConfiguration.Sequential
                && !!this.syngrafiAccountConfiguration.Sequential.securityCode;
        }else{
            return this.syngrafiAccountConfiguration && this.syngrafiAccountConfiguration.video_closing_room
                && !!this.syngrafiAccountConfiguration.video_closing_room.securityCode;
        }
    }

    createErrorMessageForParticipantMissingCellPhone():string{
        let errorMessage:string ='';
        this.eventData.meetingParticipants.filter(participant => participant.meetingParticipantType != 'STAFF_PARTICIPANT').forEach(participant => {
            if(!participant.mobileNumber){
                errorMessage = errorMessage +  participant.name +',  ' ;
            }
        });
        let selectedSolicitorDetails = this.getScheduledForSolicitorDetails(this.eventData.scheduledForSolicitorId);
        if(selectedSolicitorDetails && Utils.isEmptyString(selectedSolicitorDetails.mobileNumber)){
            errorMessage = errorMessage +  selectedSolicitorDetails.label +', ' ;
        }
        errorMessage = errorMessage.substring(0,errorMessage.lastIndexOf(','))+'. ' + '<br>';

        return `${messages.appointments.missingMobileNumber} ${errorMessage}${messages.appointments.missingMobileNumberNote}`;
    }

    getScheduledForSolicitorDetails(selectedSolicitorId :number):any{
        return this.context.scheduledForList.find(data => data.value == selectedSolicitorId);
    }







    hasNoErrors(): boolean {
        return this.errorService.hasNoErrors();
    }

    close() {
        if (this.dirty) {
            this.dialogService
                .confirmUnsavedChange(true)
                .subscribe((response: any) => {
                    if (response == "DONT_SAVE") {
                        this.dialog.close({ event: this.context.eventData });
                    } else if (response == "SAVE") {
                        this.validateAndSave();
                    }
                });
        } else {
            this.dialog.close({ event: this.context.eventData });
        }

    }

    scheduledForChanged(): void {
        if (this.eventData.scheduledForSolicitorId) {
            this.errorService.removeDpFieldError('customEvent.eventData.scheduledFor');
            if(this.eventData.meetingParticipants && this.eventData.meetingParticipants.length && (<any>this.eventData.scheduledForSolicitorId) != 'N/A'){
                // Remove selected scheduledForSolicitorId from meetingParticipants list
                let selectedScheduledFor = this.eventData.meetingParticipants.find(item=>item.participantIdOnMatter == this.eventData.scheduledForSolicitorId);
                if(selectedScheduledFor){
                    (<any>this.eventData.meetingParticipants).remove(selectedScheduledFor);
                }

            }
        }
        this.loadStaffMeetingParticipants();
        this.markDirty();
    }

    setUserName(event: any, eventLocation: EventLocation): void {
        let selectedUser: SelectItem;
        if (eventLocation == 'ZOOM') {
            selectedUser = this.zoomAccountOptions.find(zoomAccount => zoomAccount.value == event);
        } else if (eventLocation == 'MS_TEAMS') {
            selectedUser = this.msTeamsAccountOptions.find(msAccount => msAccount.value == event);
        } else if (eventLocation == 'GOOGLE_MEET') {
            selectedUser = this.googleAccountOptions.find(msAccount => msAccount.value == event);
        }
        this.eventData.externalUserName = selectedUser && selectedUser.label;
        this.markDirty();
    }

    updateMeetingLink(event: any) {
        //clear manually entered meeting link if switching from 'Other'
        if (this.eventData.eventLocation == 'OTHER' && event != 'OTHER') {
            this.eventData.meetingLink = null;
        }

        if (event == 'ZOOM' && !this.eventData.virtualMeetingAccountIntegrationId && this.zoomCredentialOptions && this.zoomCredentialOptions.length) {
            this.eventData.virtualMeetingAccountIntegrationId = this.zoomCredentialOptions[0].value;
        }

        this.markDirty();
    }

    //Validate appointment, save appointment (if necessary), export appointment, and close modal
    async exportAppointment() {
        if (!this.eventData.id || this.dirty) {
            this.validateFields();
            if (!this.hasNoErrors()) {
                return;
            }

            let isSaved = await this.saveEvent();

            if (isSaved) {
                this.eventService.exportEvent(this.context.matter && this.matterId, this.eventData.id);
                this.close();
            }
        } else {
            this.eventService.exportEvent(this.matterId, this.eventData.id);
            this.close();
        }
    }

    get matterId() : number {
        return this.context && this.context.matter && this.context.matter.id;
    }

    markDirty(): void {
        this.dirty = true;
    }

    getSaveLabel(): string {
        if (this.isNew) {
            return 'Create Appt';
        } else {
            return 'Update Appt';
        }
    }

    getModalTitle(): string {
        if (!(this.eventData && this.eventData.startDate && this.eventData.startTime && this.eventData.scheduledForSolicitorId)) {
            return 'New Appointment';
        } else {
            return `${moment(this.eventData.startDate).format('MMMM D YYYY')} - ${this.eventData.startTime} - ${this.getScheduledForContactInitials(this.eventData.scheduledForSolicitorId)}`
        }
    }

    getScheduledForContactInitials(id: number): string {
        if (id) {
            let scheduledFor = this.context.scheduledForList.find(item => item.value == id);
            return scheduledFor ? scheduledFor.initials : '';
        } else {
            return '';
        }
    }

    onEventSubtypeDropDownClick = () => this.filteredEventSubtypeOptions = this.eventSubtypeOptions.map(item => item.label);

    filterEventSubtype(event) {
        this.filteredEventSubtypeOptions = this.eventSubtypeOptions.map(item => item.label).filter(label => label && label.toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    }

    updateEventInviteeSelection(event: any[]): void {
        if (this.eventData && Array.isArray(event)) {
            setTimeout(()=>{
                this.eventData.meetingParticipants = this.eventStaffParticipants; // Remove all matter meeting participants
                this.selectedMeetingParticipantsIds = [];
                event.forEach((invitee)=>{
                    this.addInviteeToMeetingParticipants(Number(invitee), this.meetingParticipants, this.selectedMeetingParticipantsIds);
                });
                // "All clients" should only act on all contacts that are clients and not all matter participants
                if (event.indexOf('-1') > -1) {
                    this.eventInvitees
                        .filter(invitee => invitee.secondLabel.indexOf('Client') > -1)
                        .forEach(invitee => {
                            this.addInviteeToMeetingParticipants(Number(invitee.value), this.meetingParticipants, this.selectedMeetingParticipantsIds);
                        });
                }
            }, 200); //Timeout is required to unselect "All Client" option
        }
        this.markDirty();
    }

    updateEventStaffInviteeSelection(event: any[]): void {
        if (this.eventData && Array.isArray(event)) {
            setTimeout(()=>{
                this.eventData.meetingParticipants = this.eventMatterParticipants; // Remove all staff meeting participants
                this.selectedStaffInviteesIds = [];
                event.forEach((invitee)=>{
                    this.addInviteeToMeetingParticipants(Number(invitee), this.staffMeetingParticipants, this.selectedStaffInviteesIds);
                });
                // "All Staff"
                if (event.indexOf('-1') > -1) {
                    this.staffInvitees
                        .forEach(invitee => {
                            this.addInviteeToMeetingParticipants(Number(invitee.value), this.staffMeetingParticipants, this.selectedStaffInviteesIds);
                        });
                }
            }, 200); //Timeout is required to unselect "All Staff" option
        }
        this.markDirty();
    }

    addInviteeToMeetingParticipants(inviteeId: number, meetingParticipants: MeetingParticipant[], selectedIds: string[]) : void {
        if (!this.eventData.meetingParticipants.find(mp=>mp.id == inviteeId)) {
            let meetingParticipant = meetingParticipants.find(mp=>mp.id == inviteeId);
            if(meetingParticipant){
                this.eventData.meetingParticipants.push(meetingParticipant);
                selectedIds.push(String(meetingParticipant.id));
            }
        }
    }

    get selectedParticipantsList(): string {
        return this.eventData ?  AppointmentUtil.selectedParticipantsList(this.eventMatterParticipants, this.eventInvitees) : '';
    }

    get selectedStaffListTitle(): string {
        return this.eventData ? AppointmentUtil.selectedParticipantsList(this.eventStaffParticipants, this.staffInvitees) : '';
    }

    async informInvitee(): Promise<void> {
        if (this.isInviteeSelected) {
            this.validateFields();
            if (!this.hasNoErrors()) {
                return;
            }
            await this.saveEvent(false, false);
            this.openInformInviteeModal();
        }
    }

    get isInviteeSelected(): boolean {
        return !!this.eventData.meetingParticipants && this.eventData.meetingParticipants.length > 0;
    }


    openInformInviteeModal() {
        let eventInvitees: InviteeWrapper[] = AppointmentUtil.createEventInviteesFromMeetingParticipants(this.eventData.meetingParticipants);
        if (this.alreadyEmailedInvitees.length > 0) {
            eventInvitees = eventInvitees.filter(invitee => this.alreadyEmailedInvitees.indexOf(invitee) < 0);
        }
        let selectedSolicitor = this.context.scheduledForList.find(item => item.value === this.eventData.scheduledForSolicitorId);
        this.dialogService.content({
            content: InformInviteeModalComponent,
            context: {
                appointment: this.eventData,
                eventInvitees: eventInvitees,
                matter: this.context.matter,
                scheduledForSolicitor: selectedSolicitor ? selectedSolicitor.label : '',
                alreadyEmailedInvitees: this.alreadyEmailedInvitees
            },
            onFulfillment: (result) => {
                if (result) {
                    if (result.emailToAnotherInvitee == 'TRUE') {
                        this.informInvitee();
                    } else {
                        this.alreadyEmailedInvitees = [];
                    }
                }
            }
        });
    }

    async sendDocuSignEnvelope(): Promise<void> {
        if (this.eventData.id && this.eventData.signingEnvelope && this.eventData.signingEnvelope.isEnvelopeCreated()) {
            this.eventData.signingEnvelope.envelopeStatus = 'SENT';
            this.eventData = await this.eventService.updateEvent(this.matterId, this.eventData,).toPromise();
            this.updateModalData();
        }
    }

    isSendEnvelopeBtnVisible(): boolean {
        return this.eventData && this.eventData.signingEnvelope && !!this.eventData.signingEnvelope.thirdPartyEnvelopeId
            && !this.eventData.signingEnvelope.isEnvelopeSent()
    }

    isSaveToCalendarDisabled(): boolean {
        return String(this.eventData.scheduledForSolicitorId) == 'N/A';
    }

    toggleAttendeesShutter(): void {
        this.isAttendeesShutterExpanded = !this.isAttendeesShutterExpanded;
    }

    getAttendeesText(): string {
        let attendeesText = '';
        if (this.eventData && this.eventData.scheduledForSolicitorId && String(this.eventData.scheduledForSolicitorId) != 'N/A') {
            let scheduledFor = this.context.scheduledForList.find(item => item.value == this.eventData.scheduledForSolicitorId);
            attendeesText += scheduledFor ? `Scheduled For: ${scheduledFor.label}; ` : '';
        }
        if (this.eventData && this.eventStaffParticipants && this.eventStaffParticipants.length) {
            attendeesText += `Invited Staff: ${this.selectedStaffListTitle} `;
        }
        if (this.eventData && this.eventMatterParticipants && this.eventMatterParticipants.length) {
            attendeesText += `Invited Parties: ${this.selectedParticipantsList}`;
        }
        return attendeesText;
    }

    selectedDocumentNameList(): string {
        return this.documentsForThisMatterComponent && this.documentsForThisMatterComponent.getSelectedDocumentNameList().length > 0 ?
            this.documentsForThisMatterComponent.getSelectedDocumentNameList().join(',') : '';

    }

    selectedDocumentList(): number[] {
        return this.documentsForThisMatterComponent && this.documentsForThisMatterComponent.selectedDocuments ? this.documentsForThisMatterComponent.selectedDocuments.map(doc => {
            return doc.documentId
        }) : []

    }

    getDocuSignDocList(): Document[] {
        return this.documentsForThisMatterComponent && this.eventData.signingEnvelope ? this.documentsForThisMatterComponent.matterDocuments.filter(doc => this.eventData.signingEnvelope.envelopeDocuments.filter(doc => !!doc.sentTimeStamp && !doc.isDocumentDeleted()).map(ed => {
            return ed.sourceDocumentId
        }).indexOf(doc.id) > -1) : [];
    }

    getEnvelopeDocument(id: number): EnvelopeDocument {
        return this.eventData.signingEnvelope ? this.eventData.signingEnvelope.envelopeDocuments.find(doc => doc.sourceDocumentId == id) : undefined;
    }

    getDocument(id: number): Document {
        return this.documentsForThisMatterComponent ? this.documentsForThisMatterComponent.matterDocuments.find(doc => doc.id == id) : undefined
    }

    isDocNewVersionAvailable(id: number): boolean {
        return this.eventData.signingEnvelope && this.getEnvelopeDocument(id) && this.getDocument(id) && this.eventData.signingEnvelope.isEnvelopeCreated() && !this.getEnvelopeDocument(id).isDocumentUpdated() && this.getDocument(id).lastUpdatedTimeStamp > this.getEnvelopeDocument(id).sentTimeStamp;
    }

    get isEnvelopeSent(): boolean {
        return this.eventData && this.eventData.signingEnvelope && this.eventData.signingEnvelope.isEnvelopeSent()
    }

    get isEnvelopeStatusCompleted(): boolean {
        return this.eventData && this.eventData.signingEnvelope && this.eventData.signingEnvelope.isEnvelopeCompleted()
    }

    selectedDocumentWrapperList(): DocumentWrapper[] {
        return this.documentsForThisMatterComponent ? this.documentsForThisMatterComponent.selectedDocuments : [];
    }

    updateSelectedDocumentsForThisMatter(): void {
        if (this.documentsForThisMatterComponent) {
            this.documentsForThisMatterComponent.selectedDocuments = [];
            if (this.eventData.signingEnvelope) {
                let documents = this.documentsForThisMatterComponent.matterDocuments.filter(doc => this.eventData.signingEnvelope.envelopeDocuments.filter(doc => !doc.sentTimeStamp).map(ed => {
                    return ed.sourceDocumentId
                }).indexOf(doc.id) > -1);
                documents.forEach(item => {
                    this.documentsForThisMatterComponent.selectedDocuments.push(this.documentsForThisMatterComponent.createWrapperForDocument(item));
                })
            }
        }
    }

    updateDigitalSignaturePlatformForDocuments(clearSelectedDocument: boolean): void {
        if (this.documentsForThisMatterComponent) {
            let filteredMatterDocuments = this.documentsForThisMatterComponent.matterDocuments.filter(item => item.isWordOrWordPerfect || item.isPdf)
            if (this.eventData.signingEnvelope && this.eventData.signingEnvelope.envelopeDocuments.length) {
                filteredMatterDocuments = filteredMatterDocuments.filter(fd => this.eventData.signingEnvelope.envelopeDocuments.filter(doc => !!doc.sentTimeStamp && !doc.isDocumentDeleted()).map(ed => {
                    return ed.sourceDocumentId
                }).indexOf(fd.id) < 0);
            } else {
                filteredMatterDocuments;
            }
            this.documentsForThisMatterComponent.filteredRows = filteredMatterDocuments;
            this.documentsForThisMatterComponent.generateFolderStructure(false);
            if (clearSelectedDocument) {
                this.documentsForThisMatterComponent.selectedDocuments = [];
            }
        }
    }

    updateEnvelope(isDigitalSigned: boolean): void {
        if (isDigitalSigned) {
            this.convertToDigitalSigningFlag = true;
            this.documentAccordion = true;
            if (!this.eventData.signingEnvelope) {
                this.eventData.signingEnvelope = new SigningEnvelope();
                this.eventData.signingEnvelope.digitalSigningPlatform = this.context.matter.digitalSignaturePlatform;
                this.eventData.signingEnvelope.envelopSubject = this.eventData.eventDescription;
                this.eventData.signingEnvelope.envelopeDocuments = [];
            }
            this.setDefaultPackageType();
            this.loadSyngrafiiAccountConfiguration();
        }

    }

    async setDefaultPackageType(): Promise<void> {
        if (this.isDigitalSignPlatformSyngrafii()) {
            if (!this.defaultSyngrafiiPackageType) {
                let remoteSigningConfiguration = await this.remoteSigningConfigurationService.getRemoteSigningDefault(this.getAccountId() + '', this.context.matter && this.context.matter.provinceCode).toPromise();
                if (remoteSigningConfiguration) {
                    this.defaultSyngrafiiPackageType = remoteSigningConfiguration.defaultSyngrafiiPackageType;
                }
            }

            this.updatePackageType(this.defaultSyngrafiiPackageType);
        }
    }

    getLocationLabel(){
        let location: string;
        this.eventLocationOptions.forEach(element => {
            if (element.value == this.eventData.eventLocation) {
                location = element.label;
            }
        });
        return location;
    }

    async deleteMeeting(): Promise<void> {

        let proceedWithDelete: boolean = await this.dialogService.confirm('Confirmation', 'Do you wish to delete this ' + this.getLocationLabel() + ' meeting?', false, 'Proceed').toPromise();
        if (!proceedWithDelete) {
            return;
        }

        this.clearExternalMeetingInfo();
        this.markDirty();
    }

    private clearExternalMeetingInfo() {
        this.eventData.eventLocation = 'IN_PERSON';
        this.eventData.meetingLink = null;
        this.eventData.externalUserId = null;
        this.eventData.externalUserName = null;
        this.eventData.externalMeetingId = null;
    }

    get isDocumentSelectionDirty(): boolean {
        return this.documentsForThisMatterComponent && this.eventData.signingEnvelope && this.documentsForThisMatterComponent.selectedDocuments.length > 0
    }

    removeEnvelopeDocument(id: number): void {
        if (this.eventData.signingEnvelope && this.eventData.signingEnvelope.envelopeDocuments) {
            let envelopeDocument = this.eventData.signingEnvelope.envelopeDocuments.find(doc => doc.sourceDocumentId == id);
            if (envelopeDocument) {
                this.dialogService.confirm('Confirmation', `Are you sure you would like to remove this document from ${this.signingPackageLabel}?`, false, 'Delete').subscribe(res => {
                    if (res) {
                        envelopeDocument.requiredAction = 'DELETED';
                        this.updateDigitalSignaturePlatformForDocuments(false);
                        this.markDirty();
                    }
                });

            }
        }
    }

    get signingPlatformLabel() : string {
        if(this.context.matter && (this.context.matter.isDigitalSignPlatformSet())){
            if(this.eventData && this.eventData.signingEnvelope && this.eventData.signingEnvelope.digitalSigningPlatform){
                return DigitalSignaturePlatformLabel[this.eventData.signingEnvelope.digitalSigningPlatform];
            } else {
                return DigitalSignaturePlatformLabel[this.context.matter.digitalSignaturePlatform];
            }
        }
        return '';
    }

    isSyngrafii(): boolean {
        return this.signingPlatformLabel == DigitalSignaturePlatformLabel.SYNGRAFII;
    }

    get signingPackageLabel() : string {
        return this.signingPlatformLabel ? (this.signingPlatformLabel  + (this.isDigitalSignPlatformDocuSign()? ' Envelope' : ' Package')) : '';
    }

    async sendSyngrafiiPackageToRecipients() : Promise<void> {
        if (this.dirty) {
            let response: any = await this.dialogService.confirmUnsavedChange(true).toPromise();
            if (response == 'SAVE') {
                this.validateFields();
                if (this.hasNoErrors()) {
                    this.saveEvent(false, false, true);
                }
            }
        } else {
            this.saveEvent(false, false, true);
        }
    }

    isEnvelopeCreated() : boolean {
        return this.isThirdPartyEnvelopeId() && this.getSigningEnvelope().isEnvelopeCreated();
    }

    getSigningEnvelope(): SigningEnvelope{
        return this.eventData && this.eventData.signingEnvelope;

    }

    isSyngrafiiStatusVisible(): boolean{
        return this.isThirdPartyEnvelopeId()
        && (this.getSigningEnvelope().isEnvelopeCreated() || this.getSigningEnvelope().isEnvelopeSent() || this.getSigningEnvelope().isEnvelopeCompleted());
    }

    isDigitalSignPlatformDocuSign() : boolean {
        if(this.eventData && this.eventData.signingEnvelope && this.eventData.signingEnvelope.digitalSigningPlatform){
            return this.eventData.isDigitalSignPlatformDocuSign();
        } else {
            return this.context.matter && this.context.matter.isDigitalSignPlatformDocuSign();
        }

    }

    isDigitalSignPlatformSyngrafii() : boolean {
        if(this.eventData && this.eventData.signingEnvelope && this.eventData.signingEnvelope.digitalSigningPlatform){
            return this.eventData.isDigitalSignPlatformSyngrafii();
        } else {
            return this.context.matter && this.context.matter.isDigitalSignPlatformSyngrafii();
        }

    }

    anyMeetingParticipantsWithDuplicateEmails() : boolean {
        if(this.eventData && this.eventMatterParticipants && this.eventMatterParticipants.length){
            let meetingParticipantsWithEmails = this.eventMatterParticipants.filter(item=> !!item.email);
            if(meetingParticipantsWithEmails && meetingParticipantsWithEmails.length){
                let solicitorContact: Contact = this.solicitorContacts.find(contact => contact.id == this.eventData.scheduledForSolicitorId);
                let uniqueEmails = _.uniq(meetingParticipantsWithEmails.map(item=> item.email.toLowerCase()));
                if (solicitorContact && solicitorContact.email && uniqueEmails.some(email => email == solicitorContact.email.toLowerCase())) {
                    return true; //solicitor email matches one of the participants
                }
                return uniqueEmails.length != meetingParticipantsWithEmails.length;
            } else {
                return false;
            }

        } else {
            return false;
        }
    }

    get sendSyngrafiiBtnText() : string {
        return this.eventData && this.eventData.signingEnvelope && this.eventData.signingEnvelope.isEnvelopeSent() ? 'Syngrafii Package Sent'
            : this.eventData.signingEnvelope.isEnvelopeCompleted() ? 'Syngrafii Package Completed' : 'Send Syngrafii Package to Recipients';
    }

    updatePackageType(packageType: SyngrafiiPackageType) {
        if (!this.isThirdPartyEnvelopeId()) {
            this.eventData.signingEnvelope.packageType = packageType;
        }
    }

    isPackageTypeSequential(): boolean {
        return this.eventData && this.eventData.isPackageTypeSequential();
    }

    isPackageTypeVirtualSigningRoom(): boolean {
        return this.eventData && this.eventData.isPackageTypeVirtualSigningRoom();
    }

    isThirdPartyEnvelopeId(): boolean {
        return this.getSigningEnvelope() && !!this.getSigningEnvelope().thirdPartyEnvelopeId;
    }

    getSyngrafiiDownloadLinks() : void {
        if (this.isPackageTypeVirtualSigningRoom() && this.getSigningEnvelope().isEnvelopeCompleted()) {
            this.eventService.getSyngrafiiDownloadLinks(this.matterId, this.eventData.id)
                .subscribe((links: string[]) => {
                    this.auditVideoLinks = links;
                }
            );
        }
    }

    delete() {
        this.dialogService.confirm('Confirmation', 'Deleting this appointment will also delete any Signing Package/Envelope associated with this appointment.  You might still incur costs levied by the Virtual Signing business partner.  Do you wish to delete this appointment?', false, 'Yes').subscribe(res => {
            if(res && res == true) {
                this.eventService.deleteEvent(this.matterId, this.eventData.id).subscribe(res => {
                    this.dialog.close({event: this.eventData, action: 'DELETE'});
                });
            }
        });
    }

    isDigitalSigningAvailable() : boolean {
        return ((this.context.matter && this.context.matter.isDigitalSignPlatformSet()) || this.isThirdPartyEnvelopeId())
            && this.isSameStartAndEndDate();
    }

    isSameStartAndEndDate() : boolean {
        return this.eventData &&
            ( (this.eventData.startDate && this.eventData.endDate && this.eventData.startDate==this.eventData.endDate)
                || (!this.eventData.startDate && !this.eventData.endDate)
            );
    }

    isMultipleDateAvailable() : boolean {
        return !this.eventData.signingDigitally && !this.isPackageTypeVirtualSigningRoom() && !this.eventData.isVirtualMeeting();
    }

    get eventStaffParticipants () : MeetingParticipant[] {
        if(this.eventData && this.eventData.meetingParticipants && this.eventData.meetingParticipants.length){
            return this.eventData.meetingParticipants.filter(mp=>mp.isStaffParticipant() && mp.participantIdOnMatter!=this.eventData.scheduledForSolicitorId);
        }
        return [];
    }

    get eventMatterParticipants () : MeetingParticipant[] {
        if(this.eventData && this.eventData.meetingParticipants && this.eventData.meetingParticipants.length){
            return this.eventData.meetingParticipants.filter(mp=>!mp.isStaffParticipant());
        }
        return [];
    }

    isAvailableForDelete(): boolean {
        return !this.isNew && !(this.isEnvelopeSent || this.isEnvelopeStatusCompleted);
    }

    private anyMeetingParticipantsWithoutEmails() {
        if(this.eventData && this.eventMatterParticipants && this.eventMatterParticipants.length){
            let meetingParticipantsWithoutEmails = this.eventMatterParticipants.filter(item=> !item.email);
            if(meetingParticipantsWithoutEmails && meetingParticipantsWithoutEmails.length) {
                return true;
            } else {
                let invalidEmails = this.eventMatterParticipants.filter(item => !Utils.validateEmail(item.email));
                return !!(invalidEmails && invalidEmails.length);
            }
        }
        return false;
    }

    isStaffEvent() : boolean {
        return !this.context.matter;
    }

    isMatterEvent() : boolean {
        return !!this.context.matter;
    }

    isShowZoomCredentials(): boolean {
        return this.zoomCredentialOptions && this.zoomCredentialOptions.length > 1;
    }

    isShowSyngrafiiCredentials(): boolean {
        return this.syngrafiiCredentialOptions && this.syngrafiiCredentialOptions.length > 1;
    }

    openSchedulingAssistant() : void {
        let eventAvailabilityState = new EventAvailabilityState();
        eventAvailabilityState.eventDateRange = EventDateRangeTypes.DAY;
        eventAvailabilityState.eventStartDate = this.eventData.startDate;
        eventAvailabilityState.eventEndDate = this.eventData.endDate;
        eventAvailabilityState.eventStartTime = this.eventData.startTime;
        eventAvailabilityState.eventEndTime = this.eventData.endTime;
        eventAvailabilityState.openInModal = true;
        eventAvailabilityState.isOpenForScheduleAssistant = true;
        eventAvailabilityState.subject = this.eventData.eventDescription;
        eventAvailabilityState.isMultipleDateAvailable = this.isMultipleDateAvailable();
        let resourceDataList : any[] = [];
        let selectedSolicitor = this.context.scheduledForList.find(item => item.value == this.eventData.scheduledForSolicitorId);
        if(selectedSolicitor){
            resourceDataList.push({'contactId' : selectedSolicitor.value , 'resourceName' : selectedSolicitor.label})
        }
        if(this.eventData.meetingParticipants) {
            this.eventData.meetingParticipants.filter(mp => mp.meetingParticipantType == 'STAFF_PARTICIPANT').forEach(item => {
                resourceDataList.push({'contactId': item.participantIdOnMatter, 'resourceName': item.name})
            });
        }
        eventAvailabilityState.resourceData = resourceDataList;
        this.dialogService.content({
            content: EventSchedulingModalComponent,
            widthXl : true,
            context: {
                eventAvailabilityState: eventAvailabilityState,
            },
            onFulfillment: (res) => {
                if(res){
                    this.eventData.startDate = res.eventStartDate;
                    this.eventData.endDate  = res.eventStartDate;
                    this.eventData.startTime = res.eventStartTime;
                    this.eventData.endTime  = res.eventEndTime;
                    this.markDirty();
                    this.initializeEventLocationOptions();
                }

            }
        });
    }

    onSyngrafiiCredentialChange(){
        this.markDirty();
        this.loadSyngrafiiAccountConfiguration();
    }
}
