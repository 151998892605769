import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {TaxesPaidDuringOccupancyUtil} from '../modals/taxes-paid-during-occupancy/taxes-paid-during-occupancy-util';
import Utils from '../../../shared-main/utils';
import {SoaRealtyTaxAdjustmentUtil} from '../modals/realty-tax/soa-realty-tax-adjustment-util';

export class StatementAdjustmentDisplayTaxesPaidDuringOccupancy {

    static   getDisplayItems(taxPortion: number, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        //let closingDate = mainBuilder.matter.getClosingDate();
        let occupancyDate = SoaRealtyTaxAdjustmentUtil.getOccupancyDateForRealtyTax(mainBuilder.matter);

        sadItemDetails.push(mainBuilder.getNewItemLine(`Tax Portion of occupancy payment ${mainBuilder.getCurrency(taxPortion)}`, ``));

        sadItemDetails.push(mainBuilder.getNewItemLine(`Unit share from ${Utils.getFormattedDate(occupancyDate, "MMMM DD, YYYY", "??????????")})`,
            ``));

        sadItemDetails.push(mainBuilder.getNewItemLine(`to final closing (${TaxesPaidDuringOccupancyUtil.calculateDateDiffForTaxPortion(mainBuilder.matter)} days)`,
            mainBuilder.getCurrency(TaxesPaidDuringOccupancyUtil.calculateShareForTaxPortion(taxPortion, mainBuilder.matter))));

        sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${mainBuilder.purchaserLabel}`, ``));

        return sadItemDetails;
    }
}
