import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {WindowRef} from '../../../app/shared/window.ref';

declare var jQuery : any;

class SyngrafiiPortalContext extends BSModalContext {
    title: string
    securedUrl : string;
}
@Component({
               selector    : 'dp-syngrafii-portal-modal',
               templateUrl : 'syngrafii-portal.modal.component.html',
           })
export class SyngrafiiPortalModalComponent implements OnInit, ModalComponent<SyngrafiiPortalContext> {
    context : SyngrafiiPortalContext;
    syngrafiiUrl: SafeUrl;

    showCloseButton: boolean = true;

    @ViewChild('syngrafiiFrame') syngrafiiFrame: ElementRef;

    constructor(public dialog : DialogRef<SyngrafiiPortalContext>,
                public sanitizer: DomSanitizer,public winRef: WindowRef)
    {
        this.context = dialog.context;

    }

    ngOnInit() {
        this.syngrafiiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.securedUrl);
        this.handleSyngrafiiMessages();

    }

    handleSyngrafiiMessages(): void {
        window.addEventListener("message", (event) => {
            if (!event.data) return;
            // Handle action
            switch (event.data.action) {
                case "package_open":
                    //Close button is needed in case of error while opening portal (eg. cookies disabled).
                    //Once package is opened successfully, hide close button so user uses close button in portal.
                    this.showCloseButton = false;
                    break;
                case "package_close":
                    this.close();
                    break;
                case "session_expired":
                    this.showCloseButton = true;
                    // User session has expired due to inactivity
                    break;
                default:
                    break;
            }
        }, false);
    }

    close(): void {
        this.dialog.close();
    }
}
