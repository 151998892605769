import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class PublicHoliday extends BaseEntity{
    id: number;
    holidayDate: Date;
    name: string;
    applicableProvinces: string[];
    comments: string;
}




