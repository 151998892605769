import {api, SESSION_ID_REQ_PARAM} from '../../common/api';

export const tprTemplatesKey = {

    tprTemplateFiles : 'DocumentTemplateFiles',
    tprTemplateFile : 'DocumentTemplateFile',
    tprTemplate : 'DocumentTemplate'

};

export const tprTemplatesApi = {

    tprXmlTemplates : `${api}/documentServices/tprXmlDocumentTemplates`,
    downloadTprXmlTemplate : `${api}/documentServices/tprXmlDocumentTemplate/{tprXmlDocumentTemplateFileId}/download?${SESSION_ID_REQ_PARAM}={sessionId}`,
    tprXmlTemplate : `${api}/documentServices/tprXmlDocumentTemplate/{tprXmlDocumentTemplateId}`,
    deleteTprXmlTemplate : `${api}/documentServices/tprXmlDocumentTemplate/{tprXmlDocumentTemplateFileId}`,
    uploadTprXmlTemplate : `${api}/documentServices/tprXmlDocumentTemplate/upload`,
    contactUrl: `${api}/integrations/tpr/contactUrl`,
    tprLink: `${api}/integrations/tpr/tprLink`,
};
