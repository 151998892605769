import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {ErrorService} from '../../shared/error-handling/error-service';
import {EmailFieldService, EmailKeys} from '../../shared-main/email-field/email-field-service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {InviteeWrapper} from './invitee-wrapper';
import {EventData} from '../event-data';
import {DpEmailDirective} from '../../shared-main/email.directive';
import {messages} from '../../common';
import {EventService} from '../event.service';
import {Precedent} from '../../matters/shared/precedent';
import {PrecedentService} from '../../matters/mortgages/mortgage/precedent/precedent.service';
import {Matter, Utils as EmailUtils} from '../../matters/shared';
import {PrecedentTypes} from '../../matters/shared/mortgage-precedent-type';
import Utils from '../../shared-main/utils';
import {DPError} from '../../shared/error-handling/dp-error';
import {MISSING_EMAIL} from '../../shared-main/constants';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';


class InformInviteeModalContext extends BSModalContext {
    eventInvitees: InviteeWrapper[];
    appointment: EventData;
    matter:Matter;
    scheduledForSolicitor: string;
    alreadyEmailedInvitees: InviteeWrapper[];

}

const AllInviteesLabel = 'All Invitees';


@Component({
    selector: 'inform-invitee-modal-content',
    templateUrl: 'inform-invitee.modal.component.html',
    styleUrls: [
        './custom-event.modal.component.scss'
    ],
    providers: [ErrorService, EmailFieldService]
})
export class InformInviteeModalComponent implements ModalComponent<InformInviteeModalContext>, AfterViewInit{

    context: InformInviteeModalContext;
    selectedInvitees : InviteeWrapper[];
    selectedInviteeId:number;
    event : EventData;
    messagePrecedents: Precedent[]=[];
    selectedPrecedent:any;
    seletedPrecedentId : number;
    eventInvitees: InviteeWrapper[];
    @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

    constructor(public dialog: DialogRef<InformInviteeModalContext>,
                public emailFieldService : EmailFieldService,
                public eventService: EventService,
                public messagePrecedentService: PrecedentService,public errorService: ErrorService,
                public dialogService: DialogService
    ) {
        this.context = dialog.context;
    }

    ngOnInit() {
        this.emailFieldService.matter = this.context.matter;
        this.event = this.context.appointment;
        this.eventInvitees = this.context.eventInvitees
            ?  this.context.eventInvitees.map(item=> {
                    if (!item.email) {
                        item.label += MISSING_EMAIL;
                    }
                    return item;
                })
            :[];
        this.getMessagePrecedents();
        if(!this.selectedInvitees){
            this.selectedInvitees = []
        }
        if (this.context.eventInvitees && this.context.eventInvitees.length > 1){
            this.context.eventInvitees.unshift({
                label: AllInviteesLabel,
                secondLabel: '   ',
                value: 'ALL',
                email : ''
            });
        }

    }

    updatePrecedent(precedentId){
        let precedent : Precedent = this.messagePrecedents.find(precedent => precedent.id == precedentId);
        if(precedent){
            this.selectedPrecedent = precedent;
        }else{
            this.selectedPrecedent = undefined;
        }

    }


    get precedentText(){
        return this.selectedPrecedent ? this.selectedPrecedent.description:'';
    }

    async getMessagePrecedents() : Promise<void> {
        //Get precedents from backend
        let precedents : Precedent[]= await this.messagePrecedentService.getPrecedents().toPromise();
        if(precedents){
            this.messagePrecedents = precedents.filter(precedent => precedent.precedentType === PrecedentTypes.MESSAGE);
        }
    }

    ngAfterViewInit(): void {

    }
    close(): void {
        this.dialog.close();
    }


    isInviteesListValid() : boolean {

        let selectedInvitees = this.getSelectedInviteesWithoutAll();
        let invaidInvitees = selectedInvitees.filter(invitee => !invitee.email || !Utils.validateEmail(invitee.email));
        if(invaidInvitees && invaidInvitees.length){
            let errorMessage = 'The following invitee(s) do not have a valid email address<br><br>';
            invaidInvitees.forEach((invitee)=>{
                errorMessage += invitee.label + '<br>';
            });
            errorMessage += '<br>Please send the invitations individually';
            this.dialogService.confirm('ERROR', errorMessage, true, 'OK', '', true);
        }
        return invaidInvitees && !invaidInvitees.length;
    }

    getSelectedInviteesWithoutAll() : InviteeWrapper[] {
        return this.selectedInvitees ? this.selectedInvitees.filter(invitee =>invitee.value != 'ALL') : [];
    }

    checkError(){
        this.modalErrorComponent.removeAllDpSaveError();
        let missEmailInvitees = Array.isArray(this.selectedInvitees) ? this.selectedInvitees.filter(item=>!item.email) : [];
        if(missEmailInvitees && missEmailInvitees.length> 0){
            this.errorService.addDpSaveError(DPError.createDPError('invitee.document.assignment.miss.email'));
        }
    }

    initiateEmailForInvitee(){
        this.checkError();
        if(this.errorService.hasErrors()){
            return;
        }
        let selectedInvitees = this.getSelectedInviteesWithoutAll();
        if(selectedInvitees && selectedInvitees.length && this.isInviteesListValid()){
            let icsFileLink :string = this.eventService.getUrlForIcsFile(this.context.matter.id, this.event.inviteGuid);
            let subject: string = 'Meeting with '+ this.context.scheduledForSolicitor + ' Re: ' +this.emailFieldService.getMailSubject( EmailKeys.matterOpening );
            let recipientEmail: string = selectedInvitees.map(invitee=>invitee.email).join(';')
            let mailTo = 'mailto:' + recipientEmail;
            let inviteesLabel = selectedInvitees.map(invitee=>invitee.label).join(', ');
            let mailToUrl = 'mailto:' + recipientEmail + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(this.createInviteeEmailBody(inviteesLabel, icsFileLink, this.selectedPrecedent));
            if (EmailUtils.checkIfMailToUrlIsOverLimit(mailToUrl, true)){
                //System should display the confirmation ...Message Body should be copied to clipboard ctrl + v should paste it into the email
                this.emailFieldService.openLocalEmailClient(recipientEmail, subject,this.createInviteeEmailBody(inviteesLabel, icsFileLink, this.selectedPrecedent)).subscribe();
            } else {
                DpEmailDirective.initiateSendWithLocalEmailClient(mailToUrl);
                this.context.alreadyEmailedInvitees.push(...selectedInvitees);
                this.displayInviteeMessages(`${messages.inviteeMessages.emailSent}`)
            }
        }

    }

    createInviteeEmailBody(recipientName: string, link: string, selectedPrecedent ?: Precedent): string {
        //If recipientName is null, it should change '' to avoid "Dear null"
        let emailBody = 'Dear' + (recipientName ? ' ' + recipientName : '') + ',' + '\n\n';
        if (selectedPrecedent) {
            emailBody += this.formatPrecedentToRemoveRichTextTags(selectedPrecedent.description) + '\n\n';
        }else{
            emailBody += `You are invited to a ${this.event && this.event.eventDescription ? this.event.eventDescription+' ' : ''}meeting. `
                + 'Please click on the link below to add this appointment to your calendar.\n\n';
        }
        emailBody += 'Click here to add this appointment. '+ link +'\n\n' ;

        if(this.event.isZoomMeeting() || this.event.isMSTeamsMeeting()){
            emailBody += 'Use this link to join the meeting - ' + this.event.meetingLink;
        }
        return emailBody;
    }

    formatPrecedentToRemoveRichTextTags(precedentContent: string) {
        return precedentContent.replace(/<strong>/gi, '').replace(/<\/strong>/gi, '')  //Bold Tag
            .replace(/<u>/gi, '').replace(/<\/u>/gi, '') // Underline tag
            .replace(/<em>/gi, '').replace(/<\/em>/gi, '') // Italic Tags
            .replace(/&nbsp;/gi, ' ') // Replacing with space
            .replace(/<p>/gi, '').replace(/<\/p>/gi, '').trim(); // Paragraph Tag
    }

    displayInviteeMessages(msg: string): void {
        let eventInvitees : InviteeWrapper[] = this.context.eventInvitees.filter(invitee => this.context.alreadyEmailedInvitees.indexOf(invitee) < 0);
        if(eventInvitees.length > 0){
            this.dialogService.confirm('Inform Invitee', `${msg}<br>${messages.inviteeMessages.anotherInvitee}`, false, 'Yes', 'No').subscribe(
                (res) => {
                    if (res) {
                        setTimeout(() => {
                                this.dialog.close({emailToAnotherInvitee : "TRUE"})
                            },
                            500);
                    }else {
                        this.dialog.close();
                    }

                }
            );
        }else{
            this.dialogService.confirm('Inform Invitee', msg, true, 'OK').subscribe((res) => {
                    this.dialog.close({emailToAnotherInvitee : "FALSE"});
                });
        }

    }

    get selectedInviteeList(): string {
        let selectedInvitees = this.getSelectedInviteesWithoutAll();
        if (selectedInvitees && selectedInvitees.length) {
            return 'Selected Invitees: ' +  selectedInvitees.map(invitee=>invitee.label).join(', ');
        }
        if (this.selectedInvitees) {
            return `No invitees selected`;
        }
        return '';
    }

    updateInviteeSelection(selectedIds: string[]) : void {
        this.selectedInvitees = [];
        if(this.isAllInviteesSelected(selectedIds)){
            this.selectedInvitees = [...this.context.eventInvitees];
        } else {
            if(selectedIds && selectedIds.length){
                selectedIds.forEach((id)=>{
                    let selectedInvitee = this.context.eventInvitees.find(invitee=>invitee.value == id);
                    this.selectedInvitees.push(selectedInvitee);
                })
            }
        }
    }

    isAllInviteesSelected(selectedIds: string[]) : boolean {
        if(selectedIds && selectedIds.length){
            return !!selectedIds.find(id=>id == 'ALL');
        } else {
            return false;
        }
    }

    getMultiSelectDataSelected() : string[] {
        return this.selectedInvitees && this.selectedInvitees.length ? this.selectedInvitees.map(invitee=>String(invitee.value)) : [];
    }


}
