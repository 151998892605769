import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {SoAdjFinalOccupancyFee} from '../model/so-adj-final-occupancy-fee';
import {StatementAdjustmentDisplayFinalBaseFeeBuilder} from './statement-adjustment-display-final-base-fee-builder';

export class StatementAdjustmentDisplayFinalOccupancyFeeBuilder {

    static getDisplayItems(soAdj: SoAdjFinalOccupancyFee, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[]{
       return StatementAdjustmentDisplayFinalBaseFeeBuilder.getDisplayItems(soAdj, toBeUsedOnModal, mainBuilder, 'occupancy');
    }
}
