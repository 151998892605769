import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {StgFieldState} from './stewart-title-field';

export class StgMessage extends BaseEntity{
    message: string;
    errorCode: string;
    iconLink: string;
    fieldStates: StgFieldState[] = [];

    constructor(t?: StgMessage) {
        super(t);
        if(t) {
            if(t.fieldStates) {
                for(let f of t.fieldStates) {
                    this.fieldStates.push(new StgFieldState(f));
                }
            }
        }
    }
}
