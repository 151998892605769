import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementAdjustment} from '../statement-adjustment';
import {StatementAdjustmentAmountTypes} from '../statement-adjustment-constants';
import Utils from '../../../shared-main/utils';
import {CondominiumExpense} from '../../property-teranet/unit-level-plan/condominium-expense';

export class StatementAdjustmentDisplayCommonExpenseBuilder {

    static  getDisplayItems(item: StatementAdjustment , mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        let provinceCode = mainBuilder.matter && mainBuilder.matter.provinceCode ? mainBuilder.matter.provinceCode : null;

        if(mainBuilder.matter.isCondoCorporation && (mainBuilder.matter.templateForProject || mainBuilder.matter.isProjectSale) && mainBuilder.matter.isMatterProvinceON && item.soaCommonExpense.isFormatOfAdjustmentItemize(mainBuilder.matter.matterPropertyWithCondo.condominiumExpenses)){
            let condoUnitLevels : CondominiumExpense[] = item.soaCommonExpense.getUnitLevelExpenses(mainBuilder.matter.matterPropertyWithCondo.condominiumExpenses);
            condoUnitLevels.forEach(unit => {
                let unitText: string = `Unit${unit.unitNumber ? unit.unitNumber : ''}, Level${unit.levelNumber ? unit.levelNumber : ''} ${unit.condominiumUnitType ? '(' + unit.condominiumUnitType + ')' : ''}`;
                sadItemDetails.push(mainBuilder.getNewItemLine(unitText,`${mainBuilder.getCurrency(Number(unit.condominiumExpense))}`));
            })
        }

        sadItemDetails.push(mainBuilder.getNewItemLine(`Monthly ${item.getCommonExpenseLabel(provinceCode)}`,
            `${mainBuilder.getCurrency(item.soaCommonExpense.commonExpenseAmount)}`));

        if (item.soaCommonExpense.isExpenseAdjusted) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${item.soaCommonExpense.commonExpenseAdjustedFor} calculated at ${mainBuilder.addDecimalsToPercent(item.soaCommonExpense.adjustPercentage)}%`,
                mainBuilder.getCurrency(item.soaCommonExpense.calculateEstimatedTax())
            ));
        }

        let commonExpensePaidLabel = mainBuilder.getCommonExpensePaidLabel(item.soaCommonExpense, item.isOccupancyCommonExpenseAdjustment());
        if (commonExpensePaidLabel && commonExpensePaidLabel.length > 1) {
            let idx = commonExpensePaidLabel.indexOf("for");
            if (idx > -1) {
                sadItemDetails.push(mainBuilder.getNewItemLine(`${commonExpensePaidLabel.slice(0, idx + 3).trim()}`, ``));
                sadItemDetails.push(mainBuilder.getNewItemLine(`${commonExpensePaidLabel.slice(idx + 3).trim()}`, ``));
            }
            else {
                sadItemDetails.push(mainBuilder.getNewItemLine(`${commonExpensePaidLabel}`, ``));
            }
        }

        let shareAmount = mainBuilder.getCurrency(item.soaCommonExpense.calculateVendorShare(mainBuilder.adjustmentDate, mainBuilder.matter.isPaysForDateOfClosingVendor));

        let days: number = item.soaCommonExpense.getElapsedDays(mainBuilder.adjustmentDate);
        let daysText = Utils.getDaysLabel(days);

        if(item.isOccupancyCommonExpenseAdjustment()) {
            sadItemDetails.push(mainBuilder.getNewItemLine(`${mainBuilder.purchaserLabel}'s share for ${item.soaCommonExpense.getElapsedDaysLabel(mainBuilder.adjustmentDate, mainBuilder.matter.isPaysForDateOfClosingVendor)} ${daysText}`, shareAmount, undefined, 'CommonExpenses.Share'));
        } else {
            sadItemDetails.push(mainBuilder.getNewItemLine(`${mainBuilder.vendorLabel}'s share for ${item.soaCommonExpense.getElapsedDaysLabel(mainBuilder.adjustmentDate, mainBuilder.matter.isPaysForDateOfClosingVendor)} ${daysText}`, shareAmount, undefined, 'CommonExpenses.Share'));
        }


        if (!(item.soaCommonExpense.getElapsedDays(mainBuilder.adjustmentDate, mainBuilder.matter.isPaysForDateOfClosingVendor) == 0 && item.soaCommonExpense.isCommonExpensePaid === 'NO')) {
            sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${(item.soaCommonExpense.adjustmentCreditType(mainBuilder.adjustmentDate, mainBuilder.matter.isPaysForDateOfClosingVendor) == StatementAdjustmentAmountTypes.VENDOR ? mainBuilder.vendorLabel : mainBuilder.purchaserLabel)}`, ``));
        }
        else {
            sadItemDetails.push(mainBuilder.getNewItemLine(`No Adjustment`, ``));
        }

        return sadItemDetails;
    }
}
