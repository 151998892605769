import {NetAdvance} from './net-advance';
import {dropDowns} from '../mortgages/mortgage/dropdown-options';
import {Mortgage} from './mortgage';
import {TrustLedgerMatter} from '../trust-ledger/trust-ledger-matter';
import {ZERO_INTEREST} from '../../shared-main/constants';
import {DpBooleanValueTypes} from './dp-boolean';
import Utils from '../../shared-main/utils';

export class MortgageTerm {
    id : number;
    regularPayments : string;
    interestOnly : string;
    variableInterestRate : string;
    variableRate : string;
    collateralMortgage : string;
    taxesPaidThroughMortgage : string;
    other : string;
    otherText : string;
    alternateFrequency : string;
    assignmentOfRents : string;
    generalSecurityAgreement : string;
    principal : number;
    titleInsurancePrincipal : number;
    interest : string;
    registeredInterest : string;
    calculated : string;
    collateral : string;
    sctNo : string;
    sctNoOverridden : boolean;
    insuranceAmount : string;
    paymentFrequency : string;
    paymentsDue : string;
    interestAdjustmentDate : string;
    firstPaymentDate : string;
    maturityDate : string;
    regularPayment : number;
    regularPaymentCalculated : number;
    regularPaymentAmortizationPeriod : number;
    regularPaymentAmount : number;
    regularPaymentCompoundPeriod : string;
    regularPaymentFrequency : string;
    regularPaymentAutoCalculated : boolean;
    taxPortion : number;
    otherPayment : number;
    total : number;
    alternatePaymentFrequency : string;
    alternatePaymentsDue : string;
    alternateFirstPaymentDate : string;
    alternateRegularPayment : number;
    alternateRegularPaymentCalculated : number;
    alternateTaxPortion : number;
    alternateOtherPayment : number;
    alternateTotal : number;
    alternatePaymentAmortizationPeriod : number;
    alternatePaymentAmount : number;
    alternatePaymentCompoundPeriod : string;
    alternatePaymentFrequencyPrincipalAmount : string;
    alternatePaymentAutoCalculated : boolean;
    totalNetAdvance : number;
    calculationMethod : string;
    netAdvanceItems : NetAdvance[];
    sctFiledBy: string;
    semiAnnualEquivalent: string;
    box6AdditionalProvisions: string;
    mortgageDescription: string;
    loanDescription: string;
    primeIsDefinedAs: string;


    constructor(mortgageTerm? : MortgageTerm) {
        if(mortgageTerm) {
            for(let prop in mortgageTerm) {
                if(mortgageTerm.hasOwnProperty(prop)) {
                    this[prop] = mortgageTerm[prop];
                }
            }
            if(!Array.isArray(this.netAdvanceItems)) {
                this.netAdvanceItems = [];
            } else {
                for(let i : number = 0; i < this.netAdvanceItems.length; i++) {
                    this.netAdvanceItems[i] = new NetAdvance(this.netAdvanceItems[i]);
                }
            }
            this.formatInterest();
            // console.log("<> value of interest: " + this.interest)
        } else {
            this.interest = ZERO_INTEREST;
            this.semiAnnualEquivalent = '0.00000';
            this.insuranceAmount = 'FULL_INSURABLE_VALUE';
        }

    }

    formatInterest(){
        if(this.interest || typeof this.interest == 'number'){
            let value = String(this.interest);
            let requiredDecimalLength : number = 5;
            if(value.indexOf(".") > -1 && value.split(".")[1].length < requiredDecimalLength) {
                let decimallength = value.split(".")[1].length;
                for(let i = 0; i < (requiredDecimalLength - decimallength); i++) {
                    this.interest += '0';
                }
            }
            if(value.indexOf(".") > -1 && value.split(".")[0].length < 1){
                this.interest = '0' + this.interest;
            }
            if(value.indexOf(".") < 0){
                this.interest += '.00000';
            }
        }else{
            // the following line is commented out, because if user set the interest to Nil and save we don't want to convert it to 0 automatically.
            // this.interest='0.00000';
        }

    }

    calculateTotal() : void {
        this.total = Number(this.regularPayment ? this.regularPayment : 0) + Number(this.otherPayment ? this.otherPayment : 0) + Number(this.taxPortion ? this.taxPortion : 0);
    }

    calculateAltTotal() : void {
        this.alternateTotal = Number(this.alternateRegularPayment ? this.alternateRegularPayment : 0) + Number(this.alternateTaxPortion ? this.alternateTaxPortion : 0) + Number(this.alternateOtherPayment ? this.alternateOtherPayment : 0);
    }

    cleanUpMortgageTerm(mortgage: Mortgage = null, trustLedger: TrustLedgerMatter = null) : void {
        if(this.regularPayments && (this.regularPayments == DpBooleanValueTypes.N_y || this.regularPayments == DpBooleanValueTypes.NO)) {
            this.paymentFrequency = undefined;
            this.paymentsDue = undefined;
            this.firstPaymentDate = undefined;
            this.regularPayment = undefined;
            this.taxPortion = undefined;
            this.total = undefined;
            this.alternateFrequency = undefined;
            this.alternatePaymentFrequency = undefined;
            this.alternatePaymentsDue = undefined;
            this.alternateFirstPaymentDate = undefined;
            this.alternateRegularPayment = undefined;
            this.alternateTaxPortion = undefined;
            this.alternateOtherPayment = undefined;
            this.alternateTotal = undefined;
            this.alternatePaymentAmortizationPeriod = undefined;
            this.alternatePaymentAmount = undefined;
            this.alternatePaymentCompoundPeriod = undefined;
            this.alternatePaymentFrequencyPrincipalAmount = undefined;
            this.alternatePaymentAutoCalculated = undefined;
            this.regularPaymentAmortizationPeriod = undefined;
            this.regularPaymentAmount = undefined;
            this.regularPaymentCompoundPeriod = undefined;
            this.regularPaymentFrequency = undefined;
            this.regularPaymentAutoCalculated = undefined;

        }
        else {
            if(this.regularPaymentAutoCalculated && this.regularPaymentCalculated)
            {
                this.regularPayment = this.regularPaymentCalculated;
            }
            if(this.taxesPaidThroughMortgage == 'N_y' || this.taxesPaidThroughMortgage == 'NO') {
                this.taxPortion = undefined;
                this.calculateTotal();
            }

            if(this.other == 'N_y' || this.other == 'NO') {
                this.otherText = undefined;
                this.calculateTotal();
            }

            if((this.other == 'N_y' || this.other == 'NO') && (this.taxesPaidThroughMortgage == 'N_y' || this.taxesPaidThroughMortgage == 'NO')) {
                this.total = undefined;
                this.calculateTotal();
            }

            if(this.alternateFrequency == 'N_y' || this.alternateFrequency == 'NO') {
                this.alternatePaymentFrequency = undefined;
                this.alternatePaymentAmortizationPeriod = undefined;
                this.alternatePaymentFrequencyPrincipalAmount = undefined;
                this.alternatePaymentsDue = undefined;
                this.alternateFirstPaymentDate = undefined;
                this.alternateRegularPayment = undefined;
                this.alternateTaxPortion = undefined;
                this.alternateOtherPayment = undefined;
                this.alternateTotal = undefined;
            }
            else {
                if(this.taxesPaidThroughMortgage == 'N_y' || this.taxesPaidThroughMortgage == 'NO') {
                    this.alternateTaxPortion = undefined;
                    this.calculateAltTotal();
                }

                if(this.other == 'N_y' || this.other == 'NO') {
                    this.alternateOtherPayment = undefined;
                    this.calculateAltTotal();
                }

                if((this.other == 'N_y' || this.other == 'NO') && (this.taxesPaidThroughMortgage == 'N_y' || this.taxesPaidThroughMortgage == 'NO')) {
                    this.alternateTotal = undefined;
                    this.calculateAltTotal();
                }
                if(this.alternatePaymentAutoCalculated && this.alternateRegularPaymentCalculated)
                {
                    this.alternateRegularPayment = this.alternateRegularPaymentCalculated;
                }
            }
            if(!mortgage){
                return;
            }
            if(mortgage.isEmpMortgage()) {
                if(trustLedger){
                    //user can update the value from trust ledger page
                    this.totalNetAdvance = trustLedger.itemValue;
                }else{
                    //if trustLedger not exists, for example, user not visit the trust Ledger page even once :(
                    //get the value from instruction
                    if(mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.netAdvance) {
                        this.totalNetAdvance = Number(mortgage.stewartAssystMortgageInstruction.netAdvance);
                    }
                }
            }else{
                //populate the totalNetAdvance value accordingly
                // console.log("@@ Before cleanup, the total net advance value : " + this.totalNetAdvance);
                if (this.isCalculationMethodFullAmount()) {
                    this.totalNetAdvance = this.principal;
                } else if (this.isCalculationMethodFSpecificAmount()) {
                    //the value is populated correctly, no need to do anything
                } else if (this.isCalculationMethodAdvance()) {
                    //the value is populated correctly, no need to do anything
                } else if (this.isCalculationMethodManual()) {
                    if(trustLedger) {
                        //user can update the value from trust ledger page
                        this.totalNetAdvance = trustLedger.itemValue;
                    }else{
                        //if user didn't visit the trust ledger page yet
                        //get the principal value
                        this.totalNetAdvance = this.principal;
                    }
                } else if (this.isCalculationMethodNoAdvance()) {
                    this.totalNetAdvance = null;
                }
                // console.log("@@ After cleanup, the total net Advance value : " + this.totalNetAdvance);
            }
        }

        if(this.variableInterestRate == 'N_y' || this.variableInterestRate == 'NO') {
            this.variableRate = undefined;
        }
        else {
            this.interest = ZERO_INTEREST;
        }

        if(this.collateralMortgage === 'N_y' || this.collateralMortgage === 'NO'){
            this.collateral  = undefined;
        }
    }

    isCalculationMethodManual() : boolean {
        return (!this.calculationMethod || this.calculationMethod === 'MANUAL_EDIT');
    }

    isCalculationMethodFSpecificAmount() : boolean {
        return (this.calculationMethod === 'SPECIFY_AMOUNT');
    }

    isCalculationMethodNoAdvance() : boolean {
        return (this.calculationMethod === 'NO_ADVANCE');
    }

    isCalculationMethodFullAmount() : boolean {
        return (this.calculationMethod === 'FULL_AMOUNT');
    }

    isCalculationMethodAdvance() : boolean {
        return (this.calculationMethod === 'CALCULATE_ADVANCE');
    }

    isCalculationMethodNil() : boolean {
        return (this.calculationMethod === 'NO_ADVANCE');
    }

    isAssignmentOfRents(): boolean {
        return (this.assignmentOfRents === 'YES');
    }

    getTrustLedgerMortgageValue() : number {
        if(this.isCalculationMethodNoAdvance()) {
            return 0;
        }
        else if(this.isCalculationMethodAdvance() || this.isCalculationMethodFSpecificAmount()) {
           return this.totalNetAdvance;
        }
        else if(this.principal) {
           return this.principal;
        }
        else{
            return 0;
        }
    }

    reCalculateNetAdvance() : number {
        let subtotal: number = 0;
        let principal: number = this.principal? this.principal : 0;
        let netAdvanceItems : NetAdvance[] = this.netAdvanceItems? this.netAdvanceItems : [];
        for (let i = 0; i < netAdvanceItems.length; i++) {
            subtotal = Number(subtotal) + Number(netAdvanceItems[i].amount);
        }

        let total: number = Number(principal) + Number(subtotal);
        if(isNaN(total)){
            return Number(0);
        } else {
            return Number(total);
        }
    }

    get uiLabel() {
        let correspondingOption = dropDowns.calculated.find(opt => opt.value == this.calculated);
        return correspondingOption ? correspondingOption.label : this.calculated;
    }

    set uiLabel(label : string) {
        let correspondingOption = dropDowns.calculated.find(opt => opt.label == label);
        this.calculated = correspondingOption ? correspondingOption.value : label;
    }

    isMaturityDateOnDemand(): boolean{
        return Utils.isDateOnDemand(this.maturityDate);
    }

    getNetAdvanceAmount() : number{
        if(this.isCalculationMethodAdvance() || this.isCalculationMethodFSpecificAmount() ){
            return this.totalNetAdvance
        }
        else if(this.isCalculationMethodNil()){
            return 0;
        }
        else{
            return this.principal;
        }
    }

    isInterestOnlyYes() : boolean {
        return this.interestOnly == DpBooleanValueTypes.YES;
    }

    isInsuranceAmountOther(): boolean {
        return this.insuranceAmount == 'OTHER';
    }

}
