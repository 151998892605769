export interface CookieKeys {
    rememberMeUserId: string;
    rememberMeAccountId: string;
    agentId: string;
    dayAtGlanceAppointmentView : string;
    marketingVideoWatchTimeStamp : string;
}

export const COOKIE_KEYS : CookieKeys = {
    rememberMeUserId      : 'rememberMeUserId',
    rememberMeAccountId   : 'rememberMeAccountId',
    agentId: 'agentId',
    dayAtGlanceAppointmentView : 'dayAtGlanceAppointmentView',
    marketingVideoWatchTimeStamp : 'marketingVideoWatchTimeStamp'
};
