import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../../core';
import {matterApi, matterResponseKey, Utils} from '../../matters/shared';
import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {SpecialComment} from './special-comment';
import * as _ from 'lodash';
import {customPickListKey} from './custom-pick-list-key';
import {SESSION_STORAGE_KEYS} from '../session-storage-keys';
import {ReferredByWrapper} from '../../matters/shared/referred-by-wrapper';

export const PER_PAGE_FOR_REFERRED = 50;
@Injectable()
export class CustomPickListService {
    utils;
    constructor(private http: HttpClient, private logger: Logger) {
        this.utils = new Utils();
    }

    // get specialComments/ FeeQuotes for auto complete search
    // also filter them based on search input value
    getPickListAutocomplete(query: string, type: string): Observable<SpecialComment[]> {
        if (query.trim() === '') {
            let specialComments: SpecialComment[] = [];
            let specialCommentsWithDummy = this.addDummyPickListItem(specialComments, type);
            let observable = Observable.create((observer) => {
                setTimeout(() => {
                    observer.next(specialCommentsWithDummy);
                }, 10);
            });
            return observable;
        } else {
            let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
            let baseUrl = matterApi.accountCustomPickLists(accountId);
            let pagination = this.isPaginationRequired(type);
            return this.http.get(`${baseUrl}?type=${type}&page=1&per_page=25&paginationRequired=${pagination}`)
                .map(res => {
                    let data: any[] = res[matterResponseKey.customPickLists];
                    return this.searchInList(query, data, type);
                });
        }
    }

    searchInList(query, data, type): SpecialComment[] {
        let filtered: any[] = [];
        let queryLowerCase = query ? query.trim().toLowerCase() : '';
        filtered = data
            ? data.filter((item: any) => {
                    if (item['customPickListItemValue'] &&
                        item['customPickListItemValue'].toLowerCase().indexOf(queryLowerCase) === 0) {
                        return true;
                    }
                })
            :[];

        let dummyAddedFront = this.addDummyPickListItem(filtered, type);
        return dummyAddedFront;
    }

    searchForReferral(query, data) : Observable<SpecialComment[]> {
        return Observable.of(this.searchInList(query, data, customPickListKey.ReferredBy));
    }

     isPaginationRequired(type : string) {
        return type != customPickListKey.IdentificationDocumentType ? true : false;

    }

    addDummyPickListItem(data, type) {

        let specialComment: SpecialComment = new SpecialComment();

        if (type === customPickListKey.FeeQuotes) {
            specialComment.customPickListItemValue = `Edit Fee Quotes List`;
        } else if (type === customPickListKey.SpecialComments) {
            specialComment.customPickListItemValue = `Edit Special Comments List`;
        } else if (type === customPickListKey.ReferredBy) {
            specialComment.customPickListItemValue = `Edit Referred By List`;
        } else if (type == customPickListKey.IdentificationDocumentType) {
            specialComment.customPickListItemValue = `Edit ID Type List`;
        } else if (type == customPickListKey.StatusCertificatePosition) {
            specialComment.customPickListItemValue = `Edit Position List`;
        } else if (type == customPickListKey.OtherCategoryName) {
            specialComment.customPickListItemValue = `Edit Other Category List`;
        } else if (type == customPickListKey.PlaintiffList) {
            specialComment.customPickListItemValue = `Edit Plaintiff List`;
        }
        data.unshift(specialComment);
        return data;
    }

    // Get special comments/ feeQuotes for dropdown
    // when user clicks on drop down icon in special comment
    // no need to filter based on input
    // but sort based on sequence number
    getPickListDropdown(type: string, noEditPicklist?: boolean): Observable<SpecialComment[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let baseUrl = matterApi.accountCustomPickLists(accountId);
        let pagination = this.isPaginationRequired(type);
        return this.http.get(`${baseUrl}?type=${type}&page=1&per_page=25&paginationRequired=${pagination}`)
            .map((res) => {
                let response = res[matterResponseKey.customPickLists];
                let responseSorted = _.orderBy(response, ['customPickListItemSequence'], ['asc']);
                if(noEditPicklist){
                    return responseSorted;
                } else {
                    let dummyAddedFront = this.addDummyPickListItem(responseSorted, type);
                    return dummyAddedFront;
                }

            });
    }

    getReferrelPickListDropdown(type: string, noEditPicklist?: boolean, page: number = 1): Observable<SpecialComment[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let baseUrl = matterApi.accountCustomPickLists(accountId);
        let pagination = this.isPaginationRequired(type);
        let perPage = PER_PAGE_FOR_REFERRED;
        let pageNo = page;
        return this.http.get(`${baseUrl}?type=${type}&page=${pageNo}&per_page=${perPage}&paginationRequired=${pagination}`)
            .map((res) => {
                let response = res[matterResponseKey.customPickLists];
                let responseSorted = _.orderBy(response, ['customPickListItemSequence'], ['asc']);
                if(noEditPicklist){
                    return responseSorted;
                } else {
                    let dummyAddedFront = this.addDummyPickListItem(responseSorted, type);
                    return dummyAddedFront;
                }

            });
    }

    // Get special comment/ FeeQuotes in custom pick list component
    // It will get special comments or fee Quotes based on type
    getCustomPickList(type): Observable<any[]> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let baseUrl = matterApi.accountCustomPickLists(accountId);
        return this.http.get(`${baseUrl}?type=${type}`)
            .map((res) => {
                return res[matterResponseKey.customPickLists];
            });
    }

    // add new comment
    addNewComment(comment: SpecialComment): Observable<SpecialComment> {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        let url = matterApi.accountCustomPickLists(accountId);
        return this.http.post(url, comment)
            .map((res) => {
                return res;
            });
    }

    update(id: number, comment: SpecialComment): Observable<SpecialComment> {
        return this.http.put(`${matterApi.customPickLists}/${id}`, comment)
            .map((res) => {
                return res;
            });
    }

    delete(id: number): Observable<SpecialComment> {
        return this.http.delete(`${matterApi.customPickLists}/${id}`)
            .map((res) => {
                return res;
            });
    }

    async getAllReferralsForReferredByWrapper(referredByWrapper: ReferredByWrapper){
        let res: SpecialComment[] = [];
        if(referredByWrapper) {
            res = await this.getReferrelPickListDropdown(customPickListKey.ReferredBy, referredByWrapper.ignoreEditReferredByListItem ? true : referredByWrapper.pageNo != 1, referredByWrapper.pageNo).toPromise();
            if (Array.isArray(res) && res.length > 0) {
                referredByWrapper.refferedByDataOptions.push(...res);
                if (res.length >= PER_PAGE_FOR_REFERRED) {
                    referredByWrapper.pageNo++;
                    await this.getAllReferralsForReferredByWrapper(referredByWrapper);
                }
            }
        }
    }

}
