import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {appRouting, appRoutingProviders} from './app.routes';
import {DppmAppComponent} from './app.component';
import {MainModule} from './main';
import {LoginModule} from './login';
import {GlobalErrorHandler} from './core/global-error-handler';
import {ErrorService} from './shared/error-handling/error-service';
import {LockScreenService} from './core/lock-screen.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NoContentModule} from './no-content/no-content.module';
import {UUIDService} from './main/uuid.service';
import {UserConfigurationService} from './shared-main/user-configuration.service';
import {DppmAppLoaderComponent} from './app-loader.component';
import {AppLoaderService} from './core/app-loader-service';
import {UtilsService} from './main/utils.service';
import {AuthenticationService, HttpClient} from './core';
import {UserStateService} from './shared-main/user-state/user-state.service';
import {GlobalLogger} from './core/global-logger';
import {Level, NgLoggerModule} from '@nsalaun/ng-logger';
import {NotifierModule} from 'angular-notifier';
import {customNotifierOptions} from './shared-main/constants';
import {NgxFileDropModule} from 'ngx-file-drop';
import {RouteReuseStrategy} from '@angular/router';
import {CustomRouteReuseStrategy} from './core/custom-route-reuse-strategy';
import {ProjectDocSharingNotifierService} from './projects/shared/project-doc-sharing-notifier.service';
import {MatterRestrictedPeriodService} from './core/matter-restricted-period.service';
import {NotificationOptOutService} from './main/notification-opt-out.service';
import {MaterialModule} from './MaterialModule';
import {DppmCookieService} from './main/dppm-cookie.service';
import {CookieService} from 'ngx-cookie-service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InputTextModule} from 'primeng/inputtext';
import {TooltipModule} from 'primeng/tooltip';
import {AuthConfig, OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {FeatureService} from './feature-service';
import {AccountSelectionInterceptor} from './shared-main/account-selection/account-selection-interceptor';
import {KEYCLOAK_DOMAIN} from './common';
export const authCodeFlowConfig: AuthConfig = {
    issuer: `${KEYCLOAK_DOMAIN}/auth/realms/dyedurham`,
    redirectUri: window.location.origin,
    clientId: 'unity-spa',
    responseType: 'code',
    scope: 'openid profile email',
    logoutUrl: `${KEYCLOAK_DOMAIN}/auth/realms/dyedurham/protocol/openid-connect/logout/backchannel-logout`,
    postLogoutRedirectUri: 'http://dyedurham.ca',
    showDebugInformation: true,
};

export function loadApplicationConfigAndTryAuth(http: HttpClient, featureService: FeatureService, oauthService: OAuthService, authConfigService: AuthenticationService) {
    return async () => {
        try {
            await http.get(`/api/feature-flags`).toPromise()
                .then((response) => featureService.setFeatureFlags(response.Features));

            if (!featureService.isSSOEnable) {
                return Promise.resolve();
            }
            // The first call what we received from "dashboard" application should have selectedAccountId parameter
            // We need to store this parameter because when login starts we will be redirected to the keycloak
            const url = new URL(window.location.href);
            let selectedAccountId = url.searchParams.get('selectedAccountId');

            // If we haven't this parameter that means we have been redirected from keycloak
            if (selectedAccountId == null) {
                selectedAccountId = localStorage.getItem("selectedAccountId");
            }

            localStorage.setItem("selectedAccountId", selectedAccountId);
            oauthService.configure(authCodeFlowConfig);
            await oauthService.loadDiscoveryDocumentAndLogin();

            if (oauthService.hasValidAccessToken()) {
                oauthService.setupAutomaticSilentRefresh();
                localStorage.setItem('public-account-id', localStorage.getItem("selectedAccountId"));

                await authConfigService.getAuthenticatedUser().toPromise();
                await authConfigService.navigateToGetRedirectUrl();
            }

            return Promise.resolve();

        } catch (e) {
            console.log(e);
            featureService.disableSSOWhenFail();

            return Promise.resolve()
        }
    }
}

// Main module of dppm app where all other module will be inserted
// it hold all the sub module and common dependencies.
@NgModule({
  imports: [
     BrowserAnimationsModule,
     FormsModule,
     HttpClientModule,
      OAuthModule.forRoot({
          resourceServer: {
              allowedUrls: ['/api'],
              sendAccessToken: true
          }
      }),
     NoContentModule,
     appRouting,
     InputTextModule,
     LoginModule,
     MainModule,
     MaterialModule,
      NgxFileDropModule,
      TooltipModule,
      NgLoggerModule.forRoot(Level.LOG),
      NotifierModule.withConfig(customNotifierOptions)

  ],
  declarations: [
    DppmAppComponent,
    DppmAppLoaderComponent
  ],
  providers: [
      {
          provide: HTTP_INTERCEPTORS,
          useClass: AccountSelectionInterceptor,
          multi: true
      },
      { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
      {
          provide: APP_INITIALIZER,
          useFactory: loadApplicationConfigAndTryAuth,
          deps: [HttpClient, FeatureService, OAuthService, AuthenticationService],
          multi: true
      },
      OAuthService,
      AuthenticationService,
      GlobalLogger,
      HttpClient,
      UserConfigurationService,
      FeatureService,
      appRoutingProviders,
      ErrorService,
      LockScreenService,
      UUIDService,
      AppLoaderService,
      UtilsService,
      UserStateService,
      MatterRestrictedPeriodService,
      ProjectDocSharingNotifierService,
      NotificationOptOutService,
      CookieService,
      DppmCookieService,

  ],
  bootstrap: [DppmAppComponent]
})
export class AppModule { }

