import {StatementAdjustment} from '../statement-adjustment';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';

export class StatementAdjustmentDisplayPurchasePriceDeferredPortionBuilder {

    static  getDisplayItems(item: StatementAdjustment , mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        sadItemDetails.push(mainBuilder.getNewItemLine(`Credit Purchaser`,''));

        return sadItemDetails;
    }
}
