import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor() { }


    private getToken(){
        if(sessionStorage.getItem("dppm_tokens")) {
            let sessionArrayString = sessionStorage.getItem("dppm_tokens");
            let tokenArray = Array.from(JSON.parse(sessionArrayString));

            return tokenArray[tokenArray.length - 1];
        }
        else{
            return  null;
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const getToken: string = String(this.getToken());
        if(getToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: getToken
                },
                url: request.url
            });
        }
        return next.handle(request);
    }
}

