import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {MatterInstallmentPayment} from './matter-payment-installation';

export class PurchaserReport extends BaseEntity {

    constructor(purchaserReport? : PurchaserReport) {
        super(purchaserReport);
        this.matterPaymentInstallments = [];
        if(purchaserReport) {
              if(Array.isArray(purchaserReport.matterPaymentInstallments)) {
                for(let mc of purchaserReport.matterPaymentInstallments) {
                    this.matterPaymentInstallments.push(new MatterInstallmentPayment(mc));
                }
            }
            if(purchaserReport.titleNumbers) {
                this.titleNumbers = purchaserReport.titleNumbers;
            }
        } else {
            this.initializeTitleNumbers();
        }


    }

    id : number;
    transferRegistrationDate : string;
    asInstrumentNo : string;
    commonExpensesPaymentDue : string;
    commonExpensesPaidAmount : number;

    //todo : backend changes update them
    detailsIncludedOfUnfulfilledUndertaking : string;
    isPropertyTaxInfoInReport : string;
    moniesPaidFromTrustForTaxes : string;

    mostRecentTaxBill : string;
    reFuturePayments : string;
    supplementaryTaxBill : string;
    contactTaxDepartment : string;
    detailsOfPermittedRegistrations : string;
    vendorPaidEntireTaxBill : string;
    taxPaymentAmount : number;

    //todo : backend changes add them
    qualificationsAffectingTitle : string;
    titleInsurancePolicy : string;

    matterPaymentInstallments : MatterInstallmentPayment[];

    provideDetailedExplanationOfSoAdj : string;
    titleNumbers: string[] = [];

    isPropertyTaxInfoInReportYes() : boolean {
        return (this.isPropertyTaxInfoInReport == 'YES')
    }

    isMostRecentTaxBillAvailable() : boolean {
        return (this.mostRecentTaxBill != '??????' && this.mostRecentTaxBill != null)
    }

    isMoniesPaidFromTrustForTaxesYes() : boolean {
        return (this.moniesPaidFromTrustForTaxes == 'YES')
    }

    isReFuturePaymentsReportingLetter() : boolean {
        return (this.reFuturePayments == 'REPORTING_LETTER')
    }

    initializeTitleNumbers() : void {
        for(let i=0;i<16;i++){
            this.titleNumbers.push('');
        }
    }


}
