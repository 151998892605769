import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class FctEmpGeneratedDocument extends BaseEntity {

    documentId: number;

    documentType: string;

    documentDate: string;

    originator: string;

    isGenerated: boolean;

    documentName: string;

}

