import {Injectable} from '@angular/core';
import {COOKIE_KEYS} from '../shared/cookie-keys';
import {User} from '../matters/shared/user';
import {UserAgentId} from '../login/user-agent-id';
import {CookieService} from 'ngx-cookie-service';


@Injectable()
export class DppmCookieService {

    constructor(private cookieService : CookieService) {}

    setRememberMeOptions(user: User) : void {
        if(user.rememberMe) {
            this.cookieService.set(COOKIE_KEYS.rememberMeUserId, user.loginId, 365 ,'/', null, true ,'Strict' );
            this.cookieService.set(COOKIE_KEYS.rememberMeAccountId, user.publicAccountId, 365 ,'/', null, true ,'Strict');
        } else {
            this.cookieService.delete(COOKIE_KEYS.rememberMeUserId,'/', null);
            this.cookieService.delete(COOKIE_KEYS.rememberMeAccountId,'/', null);
        }
    }

    setDayAtGlanceAppointmentView(option : string) : void {
        this.cookieService.set(COOKIE_KEYS.dayAtGlanceAppointmentView, option, 365 ,'/',null, true ,'Strict');
    }

    getCookie(key: string) : string {
        return this.cookieService.get(key);
    }

    setAgentId(token) : void {
        this.cookieService.set(COOKIE_KEYS.agentId, token, 365 ,'/', null, true ,'Strict');
    }

    setMarketingVideoWatchTimeStamp() : void {
        this.cookieService.set(COOKIE_KEYS.marketingVideoWatchTimeStamp,Date.now().toString(), 365 ,'/', null, true ,'Strict');
    }

    getMarketingVideoWatchTimeStamp() : string {
        return this.cookieService.get(COOKIE_KEYS.marketingVideoWatchTimeStamp);
    }

    getAgentIdArray(username: string , publicAccountId: string) : UserAgentId[] {
        let agentIdArr: UserAgentId[] = [];
        try {
            let arr = JSON.parse(this.getCookie(COOKIE_KEYS.agentId));
            arr.forEach((item)=>{
                agentIdArr.push(new UserAgentId(item));
            });
            return agentIdArr;

        } catch (e) {
            let agentId = this.getCookie(COOKIE_KEYS.agentId);
            if(agentId){
                //if agentId is a string
                let userAgentId = new UserAgentId();
                userAgentId.publicAccountId = publicAccountId;
                userAgentId.username = username;
                userAgentId.agentId = agentId;
                agentIdArr.push(userAgentId);
                this.saveAgentIdArray(agentIdArr);
            }
            return agentIdArr;
        }
    }

    saveAgentIdArray(agentIdArray : UserAgentId[]) : void {
        this.setAgentId(JSON.stringify(agentIdArray));
    }

    getUserAgentId(username: string , publicAccountId: string) : UserAgentId {
        let agentIdArr = this.getAgentIdArray(username, publicAccountId);
        return agentIdArr && agentIdArr.length ? agentIdArr.find(item=> item.username.toLowerCase()==username.toLowerCase() && item.publicAccountId.toLowerCase()==publicAccountId.toLowerCase()) : null;
    }

    saveUserAgentId(publicAccountId: string , username: string, agentId: string) : void {
        let userAgentId = new UserAgentId();
        userAgentId.publicAccountId = publicAccountId;
        userAgentId.username = username;
        userAgentId.agentId = agentId;

        let agentIdArr = this.getAgentIdArray(username, publicAccountId);
        if(agentIdArr && agentIdArr.length){
            let foundUserAgentId = agentIdArr.find(item=> item.username==username && item.publicAccountId==publicAccountId);
            if(foundUserAgentId){
                foundUserAgentId.agentId = agentId;
            } else {
                agentIdArr.push(userAgentId);
            }
        } else {
            agentIdArr.push(userAgentId);
        }
        this.saveAgentIdArray(agentIdArr);
    }
}


