import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';
import {DpBooleanValueTypes} from '../../../../matters/shared/dp-boolean';
import {EnvelopeSalutationFormats, EnvelopeSalutationFormatType} from './EnvelopeSalutationFormats';

export class DocumentProfileMisc extends BaseEntity{

    constructor(DocumentProfileMisc?: DocumentProfileMisc) {
        super(DocumentProfileMisc);
        if (DocumentProfileMisc) {
            for (let prop in DocumentProfileMisc) {
                if (DocumentProfileMisc.hasOwnProperty(prop)) {
                    this[prop] = DocumentProfileMisc[prop];
                }
            }
            this.contactDefaultEnvelopeData = new ContactDefaultEnvelopeData(DocumentProfileMisc.contactDefaultEnvelopeData);
        }else{
            this.contactDefaultEnvelopeData = new ContactDefaultEnvelopeData();
            this.contactDefaultEnvelopeData.maleSalutation  = EnvelopeSalutationFormats.MR_FLFN_LN;
            this.contactDefaultEnvelopeData.femaleSalutation = EnvelopeSalutationFormats.MS_FLFN_LN;
            this.contactDefaultEnvelopeData.coupleSalutation = EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MS_FFLFN_FLN;
            this.contactDefaultEnvelopeData.maleDear = EnvelopeSalutationFormats.MR_LN;
            this.contactDefaultEnvelopeData.femaleDear = EnvelopeSalutationFormats.MS_LN;
            this.contactDefaultEnvelopeData.coupleDear = EnvelopeSalutationFormats.MR_AMP_MS_MLN;
        }
    }

    id: number;
    sourceMiscDocumentProfileId;
    sameAsDefaultProfileFlag: boolean;
    excludeMineralParcels:boolean;
    miscAddressFormatCode: string;
    charactersInSigningLine: number;
    autoInsertFileNoFlag: boolean;
    autoCreateTeleFileFlag: boolean;
    defaultDearFieldsCode: string;
    twoSigningLinesFlag: boolean;
    statutoryDeclarationsFlag: boolean;
    bindTheCorporationFlag: boolean;
    boldCorpNamesFlag: boolean;
    defaultTextPolarisMortgageCode: string;
    declaredOrSwornCode: string;
    autoCompletePlanningActFlag: boolean;
    wpPrintCommand: string;
    charactePerLineSOA: number;
    characterPerLineTrustLedger: number;
    displayJurisdictionSalesTax: DpBooleanValueTypes;
    useSideBySideFlag: boolean;
    closingDateGreaterThan: number;
    tabKeyStopsFlag: boolean;
    notesTopicFlag: boolean;
    underscoreFlag: boolean;
    titleNoInPurchaserOption: string;
    specifyTitleNoOther: string;
    defaultAddressOfService: string;
    uffiWarrantyCode: string;
    interestRateSummary: string;
    contactDefaultEnvelopeData: ContactDefaultEnvelopeData;
}

export class ContactDefaultEnvelopeData extends BaseEntity {
    maleSalutation: EnvelopeSalutationFormatType;
    femaleSalutation: EnvelopeSalutationFormatType;
    coupleSalutation: EnvelopeSalutationFormatType;
    maleDear: EnvelopeSalutationFormatType;
    femaleDear: EnvelopeSalutationFormatType;
    coupleDear: EnvelopeSalutationFormatType;
}
