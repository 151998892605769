import {constValues} from '../../shared/const-values';
import Utils from '../../../shared-main/utils';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {
    ItemizedAdjustmentType,
    ItemizedAutomaticallyInsertTotal,
    ItemizedCreditDivideTotalType,
    ItemizedCreditToType,
    SalePriceAdjustment
} from './sale-price-adjustment';
import {SalePriceAdjustmentHeading} from '../../consideration-ltt/sale-price-adjustment-heading';
import {OntarioSalePriceAdjustmentConstants} from './sale-price-adjustment-constants';
import {ConsiderationTaxes} from '../../../matters/consideration-ltt/consideration-taxes';
import {ItemizedCreditToVendorPurchaserItem} from './itemized-credit-to-vendor-purchaser-item';
import {ProgressionStatus} from '../statement-adjustment';


export type  NetOutHstSalePriceType =  'NO' | 'YES_FACTOR_IN_HST_REBATE' | 'YES_DIVIDE_SALE_PRICE';
export class SalePriceAdjustmentBase implements SalePriceAdjustment {

    id : number;

    netOutHstFromHSTSalePrice : NetOutHstSalePriceType = 'NO';

    salePriceAdjustmentFormat : string;

    agreementSalePrice : number = 0;

    chattelsIncludedInSalePrice : number = 0;

    finalAdditionalConsiderationsInclHst : number = 0;

    interimAdditionalConsiderationsInclHst : number = 0;

    finalAdditionalRebatePurchaserNotEligible : number = 0;

    interimAdditionalRebatePurchaserNotEligible : number = 0;

    creditsToPurchaserInclHst : number = 0;

    buyDowns : string;

    otherItems : number = 0;

    federalPortion : boolean = true;

    provincialPortion : boolean = true;

    finalAdditionalVendorConsidNotEligibleForTaxRebate : number = 0;

    interimAdditionalVendorConsidNotEligibleForTaxRebate : number = 0;

    finalAdditionalVendorConsidNotEligibleForTaxRebatePlusTax: number = 0;

    interimAdditionalVendorConsidNotEligibleForTaxRebatePlusTax: number = 0;

    totalConsiderationWithExtraTax: number = 0;

    salePriceAdjustmentHeadings : SalePriceAdjustmentHeading[] = [];

    priceOfLand : number = 0;

    provinceCode: ProvinceCode;

    readonly divideSalePriceFactor: number = 1.13;

    infoOnly: boolean = false;

    excludeFromTax: number = 0.00;

    isProjectHstReductionInSalePriceAdjustment: boolean = true;

    divideTotal: ItemizedCreditDivideTotalType;
    creditItems:ItemizedCreditToVendorPurchaserItem [] = [];
    creditsTo: ItemizedCreditToType;
    adjustmentType: ItemizedAdjustmentType;
    automaticallyInsertTotal: ItemizedAutomaticallyInsertTotal;
    adjustmentHeading: string;

    _matterAdjustmentStatusMode : string  = ProgressionStatus.FINAL;

    constructor( adjustmentMode : string, salePriceAdjustment? : SalePriceAdjustment, provinceCode?: ProvinceCode) {
        if (salePriceAdjustment) {
            for (let prop in salePriceAdjustment) {
                if (salePriceAdjustment.hasOwnProperty(prop)) {
                    this[prop] = salePriceAdjustment[prop];
                }
            }
            this.salePriceAdjustmentHeadings = [];
            if (Array.isArray(salePriceAdjustment.salePriceAdjustmentHeadings)) {
                salePriceAdjustment.salePriceAdjustmentHeadings.forEach((adjustmentHeading) => {
                    this.salePriceAdjustmentHeadings.push(new SalePriceAdjustmentHeading(adjustmentHeading));
                });

            }
            this.creditItems = [];
            if (Array.isArray(salePriceAdjustment.creditItems)) {
                salePriceAdjustment.creditItems.forEach((creditItem) => {
                    this.creditItems.push(new ItemizedCreditToVendorPurchaserItem(creditItem));
                });

            }
        } else {
            this.provinceCode = provinceCode;
        }
        this._matterAdjustmentStatusMode = adjustmentMode;
    }

    resetOnNotInclusivePrice(resetSalePriceAdjustmentFormat: boolean) : void {
        if (resetSalePriceAdjustmentFormat) {
            this.salePriceAdjustmentFormat = undefined;
        }
        this.chattelsIncludedInSalePrice = 0;
        this.chattelsIncludedInSalePrice = 0;
        this.additionalConsiderationsInclHst = 0;
        this.creditsToPurchaserInclHst = 0;
        this.creditsToPurchaserInclHst = 0;
        this.buyDowns = undefined;
        this.otherItems = 0;
        this.federalPortion = true;
        this.provincialPortion = true;

    }

    isInclusivePrice() : boolean {
        return (this.netOutHstFromHSTSalePrice == constValues.netOutHst.YES_FACTOR_IN_HST_REBATE
            || this.netOutHstFromHSTSalePrice == constValues.netOutHst.YES_DIVIDE_SALE_PRICE);
    }

    isDivideSalePrice() : boolean {
        return (this.netOutHstFromHSTSalePrice == constValues.netOutHst.YES_DIVIDE_SALE_PRICE);
    }

    isFactorInHstRebate() : boolean {
        return (this.netOutHstFromHSTSalePrice == constValues.netOutHst.YES_FACTOR_IN_HST_REBATE);
    }

    isNotInclusivePrice(): boolean {
        return ! this.isInclusivePrice();
    }

    total(excludeAdditionalConsiderationsInclHst: boolean = false,
          excludeCreditsToPurchaserInclHst: boolean = false,
          excludeBuyDowns: boolean = false, excludeFromTax: boolean = false
         ) : number {
        let ttl: number = (Number(this.agreementSalePrice)
            - (excludeFromTax ? 0 : (this.isProjectHstReductionInSalePriceAdjustment ? 0 : Number(this.excludeFromTax)))
            + (excludeAdditionalConsiderationsInclHst ? 0 : Number(this.additionalConsiderationsInclHst)))
            - (excludeCreditsToPurchaserInclHst? 0 : Number(this.creditsToPurchaserInclHst));

        if (this.buyDowns) {
            if(excludeBuyDowns){
                return Number(ttl);
            } else {
                return Number( ttl - Number(this.otherItems));
            }
        } else {
            return Number(ttl);
        }
    }

    totalPriceWithTax(){
        return Number(this.total() + (this.isProjectHstReductionInSalePriceAdjustment ? 0 : Number(this.excludeFromTax)));
    }

    /**
     * Any considerations or buyDowns or credits To Purchaser ?
     * @returns {boolean}
     */
    isTotalAdjusted(): boolean {
        return Number(
            Number(this.additionalConsiderationsInclHst) +
            Number(this.creditsToPurchaserInclHst) +
            Number((this.buyDowns) ? Number(this.otherItems) : 0)
            // Number(this.additionalVendorConsidNotEligibleForTaxRebate) +
            // Number(this.additionalVendorConsidNotEligibleForTaxRebatePlusTax)
        ) > 0;
    }

    // HST

    calculateDerivedNetSalePrice(excludeAdditionalConsiderationsInclHst: boolean = false,
                                 excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false) : number {
        return 0;
    }

    /**
     * This is different from calculateHstPortion due to the fact that salePrice adjustment does not calculate
     * the HST/GST portion when netOutHstSalePriceType = 'NO' but some adjustments do 'cause yeah
     * @param rate
     */
    calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(rate: number, federalHstRate: number, provinceHstRate: number): number {
        if (this.netOutHstFromHSTSalePrice === 'NO') {
            let netSalePriceExcludeAdditionalVendorConsid: number = this.totalNetSalePrice(federalHstRate, provinceHstRate, true);
            return Utils.roundCurrency(rate * netSalePriceExcludeAdditionalVendorConsid / 100);
        } else {
            return Utils.roundCurrency(this.calculateHstPortion(rate));
        }
    }

    calculateHstPortion(hstPortion : number, isTaxOut? : boolean, returnAsIs?: boolean , excludeAdditionalConsiderationsInclHst: boolean = false,
                        excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false) : number {
        let result: number = 0;
        if(isTaxOut){
            result = Number(this.agreementSalePrice) * (hstPortion / 100);
        } else if(this.isDivideSalePrice()) {
            result = Number( Number((this.total(excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax) / (this.divideSalePriceFactor * 100)).toFixed(4)) * hstPortion);
        }
        else if ( this.isFactorInHstRebate()) {
            result = this.calculateDerivedNetSalePrice(excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax) * (hstPortion / 100);
        }

        if(returnAsIs) {
            return result; // rounding is not needed to avoid extra penny in certain cases
        }
        else {
            return Utils.roundCurrencyTCStyle(result); // rounding is needed to avoid extra penny in certain cases
        }
    }

    totalRebate(provinceHstRate : number , federalHstRate : number) : number{
        return Number((provinceHstRate ? this.calculateProvincialRebate(provinceHstRate) : 0))+Number((federalHstRate ? this.calculateRebate(federalHstRate) : 0));
    }

    calculateTotalRebatePortionTax(provinceHstRate : number , federalHstRate : number, salePrice: number, taxRate : number) : number {
        return 0;
    }

    totalRebatePortionWithTax(provinceHstRate : number , federalHstRate : number, salePrice: number, taxRate : number) : number {
        return 0;
    }

    calculateRebate(hstPortion : number , excludeAdditionalConsiderationsInclHst: boolean = false,
                    excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false) : number {
        return 0;
    }

    calculateFederalRebatePortion(federalTaxRate: number, netSalePrice: number) : number{
        return 0;
    }

    calculateOntarioRebatePortion(ontarioTaxRate: number, salePrice: number) : number{
        if(!this.netOutHstFromHSTSalePrice){
            this.netOutHstFromHSTSalePrice = 'NO';
        }

        return 0;
    }

    totalRebatePortion(ontarioTaxRate: number, federalTaxRate: number, salePrice: number): number{
        return Utils.roundCurrencyTCStyle(this.calculateFederalRebatePortion(federalTaxRate, salePrice)) +
            Utils.roundCurrencyTCStyle(this.calculateOntarioRebatePortion(ontarioTaxRate, salePrice));
    }

    calculateProvincialRebate(provinceHstPortion : number, isTaxOut? : boolean , excludeAdditionalConsiderationsInclHst: boolean = false,
                              excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false) : number {
        return 0;
    }

    totalNetSalePrice(federalHstPortion: number, provinceHstPortion: number, excludeAdditionalVendorConsid: boolean = false , excludeAdditionalConsiderationsInclHst: boolean = false,
                      excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false): number {
        let result: number = this.total(excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax);
        let currValue: number = Number(this.calculateHstPortion(federalHstPortion ,undefined, undefined, excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax));
        result = Utils.roundCurrency(result - currValue);

        // currValue = Number(this.excludeFromTax);
        // result = Utils.roundCurrency(result - currValue);

        currValue = Number(this.calculateHstPortion(provinceHstPortion  ,undefined, undefined,excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax));
        result = Utils.roundCurrency(result - currValue);

        currValue = Number(this.calculateRebate(federalHstPortion ,excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax));
        result = Utils.roundCurrency(result + currValue);

        currValue = Number(this.calculateProvincialRebate(provinceHstPortion ,undefined,excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax));
        result = Utils.roundCurrency(result + currValue);
        if (!excludeAdditionalVendorConsid) {
            currValue = Number(this.additionalVendorConsidNotEligibleForTaxRebate);
            result = Utils.roundCurrency(result + currValue);
            currValue = Number(this.additionalVendorConsidNotEligibleForTaxRebatePlusTax);
            result = Utils.roundCurrency(result + currValue);
        }
        return result;
    }


    lessHstComponentValue(federalHstPortion : number, provinceHstPortion : number, excludeAdditionalVendorConsid? : boolean, excludeAdditionalConsiderationsInclHst? : boolean) : number {
        return 0;
    }

    getSalePrice(provinceHstRateSlab : ConsiderationTaxes) : string {
        if(!this.isInclusivePrice() ||  ((this.isFactorInHstRebate() || this.isDivideSalePrice()) && this.isSalePriceFormatINCLUSIVE()))
        {
            return parseFloat((Math.round(Number(this.agreementSalePrice) * 100) / 100).toString()).toFixed(2);
        }
        else{
            let temp =  Number((this.totalNetSalePrice((provinceHstRateSlab ?
                provinceHstRateSlab.hstFederalPortion : 0),(provinceHstRateSlab ? provinceHstRateSlab.hstProvincialPortion : 0))));
           return parseFloat((Math.round(temp * 100) / 100).toString()).toFixed(2);
        }
    }

    totalWithoutTaxOrRebatesButWithConsiderations(): number{
        return Number(this.total()) +
            Number(this.additionalVendorConsidNotEligibleForTaxRebate) +
            Number(this.additionalVendorConsidNotEligibleForTaxRebatePlusTax);
    }

    calculateRealEstateNSP(federalHstPortion : number, provinceHstPortion : number, excludeAdditionalVendorConsid: boolean = false , excludeAdditionalConsiderationsInclHst: boolean = false,
                           excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false) : number {
        return this.chattelsIncludedInSalePrice > 0
            ?
            Number(this.totalNetSalePrice(federalHstPortion, provinceHstPortion, excludeAdditionalVendorConsid, excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax)
                       + this.additionalRebatePurchaserNotEligible - this.chattelsIncludedInSalePrice)
            : 0;
    }

    isSalePriceFormatNSP() : boolean {
        return (this.salePriceAdjustmentFormat === 'CREDIT_VENDOR_NET_SALE_PRICE');
    }

    isSalePriceFormatINCLUSIVE() : boolean {
        // this displays as "Sale Price In Agreement" when Net out HST from HST inclusive sale price? = NO
        return (this.salePriceAdjustmentFormat === 'CREDIT_VENDOR_INCLUSIVE');
    }

    federalRebatePortion() : number {
        return OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION;
    }

    totalTaxOutHST(fedRate, provRate): number {

        return  Number(this.calculateHstPortion(fedRate, true)) + Number(this.calculateHstPortion(provRate, true));
    }

    totalTaxOutHSTRebate(fedRate, provRate): number {
        return  Number(this.totalTaxOutHST(fedRate, provRate)) * Number(this.federalRebatePortion());
    }

    taxOutCreditVendor(fedRate, provRate): number{
        let hstp =  Number(this.totalTaxOutHST(fedRate, provRate));
        let hsthp =  Number(this.totalTaxOutHSTRebate(fedRate, provRate));

        return  Number(this.agreementSalePrice) + hstp - hsthp;
    }

    showRebateFederalPortion(): boolean{
        return this.federalPortion && (this.netOutHstFromHSTSalePrice === 'YES_FACTOR_IN_HST_REBATE');
    }

    showRebateProvincialPortion(): boolean{
        return this.provincialPortion && (this.netOutHstFromHSTSalePrice === 'YES_FACTOR_IN_HST_REBATE');
    }

    getExtrasCost(salePriceAdjustmentHeadings : SalePriceAdjustmentHeading[]) : number {
        let extras = 0;
        if(!this.isInclusivePrice() && salePriceAdjustmentHeadings && salePriceAdjustmentHeadings.length){
            salePriceAdjustmentHeadings.forEach((heading)=>{
                extras += Number(heading.cost);
            })
        }
        return extras;
    }

    getTotalConsiderationCost(salePriceAdjustmentHeadings : SalePriceAdjustmentHeading[]) : number {
        return Number(this.agreementSalePrice) + Number(this.getExtrasCost(salePriceAdjustmentHeadings));
    }


    divizer() : string {
        return '';
    }


    get additionalVendorConsidNotEligibleForTaxRebate() : number {
        return this.isMatterAdjustmentModeFinal() ? this.finalAdditionalVendorConsidNotEligibleForTaxRebate : this.interimAdditionalVendorConsidNotEligibleForTaxRebate;
    }


    get additionalVendorConsidNotEligibleForTaxRebatePlusTax() : number {
        return this.isMatterAdjustmentModeFinal() ? this.finalAdditionalVendorConsidNotEligibleForTaxRebatePlusTax : this.interimAdditionalVendorConsidNotEligibleForTaxRebatePlusTax;
    }


    get additionalConsiderationsInclHst() : number {
        return this.isMatterAdjustmentModeFinal() ? this.finalAdditionalConsiderationsInclHst : this.interimAdditionalConsiderationsInclHst;
    }


    get additionalRebatePurchaserNotEligible() : number {
        return this.isMatterAdjustmentModeFinal() ?  this.finalAdditionalRebatePurchaserNotEligible : this.interimAdditionalRebatePurchaserNotEligible;
    }


    set additionalVendorConsidNotEligibleForTaxRebate(value : number)  {
        (this.isMatterAdjustmentModeFinal() ? this.finalAdditionalVendorConsidNotEligibleForTaxRebate= value : this.interimAdditionalVendorConsidNotEligibleForTaxRebate = value);
    }


    set additionalVendorConsidNotEligibleForTaxRebatePlusTax(value : number)  {
        this.isMatterAdjustmentModeFinal() ? this.finalAdditionalVendorConsidNotEligibleForTaxRebatePlusTax= value : this.interimAdditionalVendorConsidNotEligibleForTaxRebatePlusTax = value;
    }


    set additionalConsiderationsInclHst(value : number) {
        this.isMatterAdjustmentModeFinal() ? this.finalAdditionalConsiderationsInclHst= value : this.interimAdditionalConsiderationsInclHst = value;
    }


    set additionalRebatePurchaserNotEligible(value : number) {
        this.isMatterAdjustmentModeFinal() ?  this.finalAdditionalRebatePurchaserNotEligible= value : this.interimAdditionalRebatePurchaserNotEligible = value;
    }


    get matterAdjustmentStatusMode() : string {
        return  this._matterAdjustmentStatusMode ? this._matterAdjustmentStatusMode : ProgressionStatus.FINAL;
    }

    set matterAdjustmentStatusMode(mode: string) {
        if(mode){
            this._matterAdjustmentStatusMode = mode;
        }

    }

    isMatterAdjustmentModeFinal() : boolean {
        return (this.matterAdjustmentStatusMode == ProgressionStatus.FINAL);
    }

    hasAdditionalConsiderations(): boolean {
        return this.additionalVendorConsidNotEligibleForTaxRebate > 0 ||
               this.additionalVendorConsidNotEligibleForTaxRebatePlusTax > 0 ||
               this.additionalConsiderationsInclHst > 0;
    }

    hasAdditionalVendorConsiderationsFromAdj(existingAdjWithVendorConsiderationEligibleForTaxRebate: boolean): boolean{
        return this.additionalVendorConsidNotEligibleForTaxRebate > 0 ||
            this.additionalVendorConsidNotEligibleForTaxRebatePlusTax > 0 ||
            (this.additionalConsiderationsInclHst > 0 && existingAdjWithVendorConsiderationEligibleForTaxRebate); // additionalConsiderationsInclHst is reused for adjustments that have vendor consideration eligible for tax rebate

    }
    getTotalConsiderationSubjectToPST(salePriceAdjustmentHeadings : SalePriceAdjustmentHeading[]): number{
        return 0;
    }

    calculateProvincialRebatePortionSubjectToPST(provincialTaxRate: number): number{
        return 0;
    }

}
