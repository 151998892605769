import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {CategoryFieldDataType, CategoryFieldType} from '../../shared-main/constants';

export class CategoryField extends BaseEntity{
    id: number;
    fieldType: CategoryFieldType;
    fieldDataType: CategoryFieldDataType;
    fieldLabel: string;
    fieldDefaultLabel: string;
    enabled: boolean;
    subFields: CategoryField[] = [];

    constructor(categoryField?: CategoryField) {
        super(categoryField);
        if(categoryField) {
            for(let prop in categoryField) {
                if(categoryField.hasOwnProperty(prop)) {
                    this[prop] = categoryField[prop];
                }
            }
            this.subFields = [];
            if(Array.isArray(categoryField.subFields)) {
                for(let i: number = 0; i < categoryField.subFields.length; i++) {
                    this.subFields[i] = new CategoryField(categoryField.subFields[i]);
                }
            }
        }
    }

     isUserDefinedField() : boolean {
         return (this.fieldType == "USER_DEFINED_FIELD");
    }

    isFieldDataTypeNotDefined() : boolean {
        return  this.fieldDataType === 'N_A';
    }

    get isFieldTypeAccountNumber() : boolean{
        return this.fieldType == 'ACCOUNT_NUMBER';
    }

    get isFieldTypeDetails() : boolean{
        return this.fieldType == 'DETAILS';
    }

}
