import {Directive} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
    selector: '[ngModel][dp-alpha-only]',
    host: {
        '(input)': 'onInput($event)'
    }
})
export class AlphaOnlyDirective {

    constructor(private model: NgModel) {
    }

    onInput() {
        if (this.model.value) {
            let val = this.model.value.replace(/[^A-Za-z]/g, '');
            this.model.reset(val);
        }
    }
}
