import {LowercaseDirective} from './lowercase.directive';
import {LowercaseDirectiveAlpha} from './lowercase-alphaonly.directive';
import {UppercaseDirectiveAlpha} from './uppercase-alphaonly.directive';
import {PreserveCursorPositionDirective} from './preserve-cursor-position.directive';
import {OnlyNumericDirective} from './onlynumeric.directive';
import {LincNumberDirective} from './lincnumber.directive';
import {KeyupDropDownDirective} from './keyupdropdown.directive';
import {UnSavedChangesGuard} from './unsavedchanges.guard';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DataTableModule} from '@pascalhonegger/ng-datatable';

import {
    AddressFormComponent,
    AutoMoveNextOnMaxlengthDirective,
    CapitalizedInput,
    CurrencyDirective,
    MatterNoFilterDirective,
    NumberOnly,
    OtherContactInfoFieldsComponent,
    PinFormatDirective,
    TrimmedInput,
    UppercasedInput,
    UppercaseDirective
} from '../shared-main';
import {NoWhiteSpaceDirective} from './no-white-space.directive';
import {AccordionModule} from 'primeng/accordion';

import {TypeCodesService} from './type-codes.service';
import {AlphanumericOnly} from './alphanumeric-only.directive';
import {AlphanumericSpaceOnly} from './alphanumeric-space-only.directive';
import {AlphaOnlyDirective} from './alpha-only.directive';
import {DateFormatPipe} from './date-format.pipe';
import {DpDefaultSorter} from './dp-default-sorter.component';
import {ContactService} from './contact.service';
import {UserProfilesService} from './user-profiles.service';
import {DPPMCreditCard} from './creditcard.directive';
import {MultiPinFormatDirective} from './multiplepin-format.directive';
import {DefaultCurrencyDirective} from './default-currency.directive';
import {MultiEmailValidatorDirective} from './multi-email-validator.directive';
import {DpBurgerMenuComponent} from './burger-menu/burger-menu.component';
import {TempAccountService} from './address-Form/temp-account.service';
import {UserAccessDirective} from './user-acess.directive';
import {AuthorizationService} from './authorization/authorization-service';
import {TabIndexIgnoreDirective} from './tab-index-ignore.directive';
import {ErrorValidator} from './error-validator';
import {DisableAutoCompleteItemDirective} from './disable-auto-complete-item-directive';
import {DPErrorModule} from '../shared/error-handling/error.module';
import {StatusBarHelpDirective} from './status-bar-help.directive';
import {DpPlaceholderDirective} from './dp-placeholder.directive';
import {DpPrintableDialogDirective} from './dp-print-dialog.directive';
import {ConsiderationF8Directive} from './consideration-f8-directive';
import {AccordianTabAnchorFixDirective} from './accordian-tab-anchor-fix.directive';
import {CustomPickListModalComponent} from '../shared/modal/custom-pick-list-modal.component';
import {AutoCompleteCustomPickListComponent} from '../shared/modal/autocomplete-custom-pick-list.component';
import {CustomPickListService} from '../shared/modal/custom-pick-list.service';
import {UserDefinedFieldService} from './user-defined-field/user-defined-field-service';
import {UserDefinedFieldComponent} from './user-defined-field/user-defined-field.component';
import {ModalF9HelpModule} from '../shared/modal-f9-help/modal-f9-help.module';
import {StatusBarService} from './status-bar.service';
import {DocumentProfileCache} from './document-profile-cache.service';
import {DPPMAccordionModule} from '../shared/accordion/accordion.module';
import {ReadOnlyDirective} from './read-only-input';
import {AddressSearchService} from './address-Form/address-search.service';
import {SupplementalTaskService} from './supplemental-task-category/supplemental-task-service';
import {FieldCodeDirective} from './field-code-directive';
import {StatementAdjustmentConfigComponent} from '../admin/statement-adjustment/statement-adjustment-config.component';
import {DPPMCheckBoxModule} from '../shared/checkbox/checkbox.module';
import {ReadOnlyBlockDirective} from './read-only-block';
import {TeranetService} from './teranet/teranet-service';
import {StickyTableHeaderDirective} from './sticky-table-header';
import {DefaultNumberDirective} from './default-numeric.directive';
import {PercentageDirective} from './percentage.directive';
import {DpDropDownComponent} from './dropdown-menu/dropdown-menu.component';
import {DocumentUtilityService} from '../matters/document-production/document-utility.service';
import {CleanUpMatterDataGuard} from './cleanUpMatterData.guard';
import {TelusService} from './telus/telus-service';
import {FileUploadComponent} from '../shared/file-upload/file-upload-component';
import {OptionsButtonComponent} from './options-button/options-button.component';
import {DpTableKeyCommandsDirective} from './dp-table-key-commands';
import {ManageFolderTemplatesModalComponent} from '../admin/document-profile/document-profile-edit/template-folder/manage-folder-templates-modal/manage-folder-templates.modal';
import {EditDocumentTemplateDetailsComponent} from '../admin/document-profile/document-profile-edit/template-folder/edit-document-template-details-modal/edit-document-template-details.modal';
import {DpEmailDirective} from './email.directive';
import {FileUploadModal} from '../matters/document-production/upload/file-upload-modal.component';
import {StewartTitleService} from './stewart-title/stewart-title-service';
import {DetailedDatePipe} from '../matters/shared/detailed-date.pipe';
import {StewartTitleAuthenticationService} from './stewart-title/stewart-title-authentication-service';
import {AddNoteDialogComponent} from '../admin/account-notes/add-note-modal.component';
import {StickyNotesModal} from './sticky-notes/sticky-notes.modal.component';
import {FCTService} from './fct/fct-service';
import {DpTableExcelStyleDirective} from './dp-table-excel-style';
import {ManageDocumentProductionCategoriesModalComponent} from '../matters/document-production/manage-document-production-categories.modal/manage-document-production-categories.modal.component';
import {DocumentProductionService} from '../matters/document-production/document-production.service';
import {ManageDocumentProductionCategoryModalComponent} from '../matters/document-production/manage-document-production-categories.modal/manage-document-production-category.modal.component';
import {ManageMasterSupplementalTaskDocumentsModalComponent} from '../admin/supplemental-task-categories/manage-master-supplemental-task-documents.modal.component';
import {SupplementalTaskCategoriesComponent} from '../admin/supplemental-task-categories/supplemental-task-categories.component';
import {SupplementalTaskCategoryComponent} from '../admin/supplemental-task-categories/supplemental-task-category.component';
import {ManageExportTemplatesModal} from '../admin/export-templates/manage-export-templates/manage-export-templates.modal.component';
import {ImportDoProcessTemplateModalComponent} from '../admin/document-profile/document-profile-edit/template-folder/manage-folder-templates-modal/import-doprocess-template.modal.component';
import {ImportDoProcessTemplateConfirmationModalComponent} from '../admin/document-profile/document-profile-edit/template-folder/import-doprocess-template/import-doprocess-template-confirm.modal.component';
import {EditExportTemplateDetailsComponent} from '../admin/export-templates/edit-export-template-details/edit-export-template-details.modal';
import {DpProvinceFieldLabel} from './dp-province-label';
import {DpShowByProvince} from '../matters/shared/dp-show-by-province';
import {ALTOService} from './alto/alto-service';
import {DpRadioEventDirective} from './radio-button-event-directive';
import {DpProjectShowByProvince} from '../projects/shared/dp-project-show-by-province';
import {EditTprTemplateDetailsComponent} from '../doc-services/manage-tpr-templates/edit-tpr-template-details/edit-tpr-template-details.modal';
import {ManageTprTemplatesModal} from '../doc-services/manage-tpr-templates/manage-tpr-templates/manage-tpr-templates.modal.component';
import {TprTemplatesService} from '../doc-services/manage-tpr-templates/tpr-templates.service';
import {TeraviewConfigModal} from '../admin/docket/teraview-config.modal.component';
import {DpCopyAndRenameModalComponent} from '../matters/document-production/dp-copy-rename-modal.component';
import {DocumentsForThisMatterComponent} from '../matters/document-production';
import {TrustAccountsModal} from '../admin/trust-account/trust-accounts-modal.component';
import {AddEditTrustAccountModal} from '../admin/trust-account/add-edit-trust-account-modal.component';
import {SoaFeesComponent} from './statement-account/fee-config/soa-fees.component';
import {DisbursementConfigComponent} from './statement-account/disbursement-config/disbursement-config.component';
import {dpSortableColumn} from './dp-sortable-column';
import {dpSortableColumnExt} from './dp-sortable-column-ext';

import {DelayedValueChangeDirective} from './delayed-value-change.directive';
import {MatterHelperService} from './matter-helper.service';
import {UppercasePreserveCursorPositionDirective} from './uppercase-preserve-cursor-position.directive';
import {DecimalDirective} from './decimal.directive';
import {PhoneNumberDirective} from './phoneNumber.directive';
import {NgxFileDropModule} from 'ngx-file-drop';
import {AutoresizableMultilineInput} from '../shared-main/multiline-input.directive';
import {PresentCirfModalComponent} from './opportunities/present-cirf-modal.component';
import {EventCalendarComponent} from '../event/event-calendar/event-calendar.component';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {DragAndDropModule} from 'angular-draggable-droppable';
import {ProcessReferralModal} from '../opportunities/opportunities-list/process-referral-modal.component';
import {DeclineShareDocsModalComponent} from '../opportunities/opportunities-list/decline-share-docs-modal.component';
import {ShareDocumentsModalComponent} from '../matters/matter-list/modals/share-documents-modal.component';
import {MailParseService} from '../matters/document-production/mail-parse.service';
import {DocumentProductionLoggerService} from '../matters/document-production/document-production-logger.service';
import {MassUpdateProgressModalComponent} from '../matters/mass-update-progress/mass-update-progress-modal.component';
import {ResponsibleStaffComponent} from './responsible-staff/responsible-staff.component';
import {CustomMatterTypeService} from '../admin/custom-matter-types/custom-matter-type.service';
import {ThirdPartyIntegrationService} from './third-party/third-party-integration.service';
import {ChecklistTemplateService} from '../admin/checklist-templates/checklist-template.service';
import {MatterWorkItemsUtilsService} from '../matters/matter-overview/matter-work-items-utils.service';
import {MatterNotificationUtilService} from '../matters/matter-notification-service';
import {TreeModule} from '@circlon/angular-tree-component';
import {UnityConnectImportAttachmentComponent} from '../matters/purchaser/cirf/cirf-import-data/unity-connect-import-attachment.component';
import {OpportunitiesService} from '../opportunities/opportunities.service';
import {DpStickyNotesComponent} from './dp-sticky-notes/dp-sticky-notes.component';
import {EventAvailabilityViewComponent} from '../event/event-list/event-avaliablity-view/event-availability-view.component';
import {EventService} from '../event/event.service';
import {PopoverDirective} from '../event/popover/popover.directive';
import {Overlay} from '@angular/cdk/overlay';
import {PopoverService} from '../event/popover/popover.service';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {TitlePlusService} from './title-plus/title-plus-service';
import {DpCirfFieldVisibilityDirective} from './cirf-config/dp-cirf-field-visibility.directive';
import {DocumentProfileAutoCompleteSelectComponent} from './document-profile-auto-complete-select/document-profile-auto-complete-select.component';
import {MatterNotificationConfigModalComponent} from '../admin/manage-messaging-notifications/matter-notification-config/matter-notification-config.modal.component';
import {CustomThirdpartyModalComponentModal} from '../shared/modal/custom-third-party.modal.component';
import {EmpPortalAccessService} from '../integrations/mortgage-deal/emp-portal-access.service';
import {MaterialModule} from '../MaterialModule';
import {TCOLService} from './tcol/tcol-service';
import {SharedModule} from 'primeng/api';
import {AutoCompleteModule} from 'primeng/autocomplete';


@NgModule({
              imports         : [
                  CommonModule,
                  FormsModule,
                  SharedModule,
                  AccordionModule,
                  DPErrorModule,
                  ModalF9HelpModule,
                  AutoCompleteModule,
                  DPPMAccordionModule,
                  DPPMCheckBoxModule,
                  DataTableModule,
                  MaterialModule,
                  NgxFileDropModule,
                  DragAndDropModule,
                  CalendarModule.forRoot({
                      provide: DateAdapter,
                      useFactory: adapterFactory
                  }),
                  TreeModule,
                  InfiniteScrollModule

              ],
              declarations    : [
                  ManageDocumentProductionCategoriesModalComponent,
                  ManageDocumentProductionCategoryModalComponent,
                  AddressFormComponent,
                  DocumentProfileAutoCompleteSelectComponent,
                  OtherContactInfoFieldsComponent,
                  UserDefinedFieldComponent,
                  PinFormatDirective,
                  CurrencyDirective,
                  TrimmedInput,
                  AutoresizableMultilineInput,
                  DelayedValueChangeDirective,
                  CapitalizedInput,
                  UppercasedInput,
                  KeyupDropDownDirective,
                  UppercaseDirective,
                  UppercaseDirectiveAlpha,
                  LowercaseDirective,
                  LowercaseDirectiveAlpha,
                  PreserveCursorPositionDirective,
                  UppercasePreserveCursorPositionDirective,
                  AutoMoveNextOnMaxlengthDirective,
                  MatterNoFilterDirective,
                  OnlyNumericDirective,
                  LincNumberDirective,
                  DecimalDirective,
                  PhoneNumberDirective,
                  NoWhiteSpaceDirective,
                  DpPlaceholderDirective,
                  DpPrintableDialogDirective,
                  NumberOnly,
                  AlphanumericOnly,
                  AlphanumericSpaceOnly,
                  AlphaOnlyDirective,
                  DateFormatPipe,
                  DpDefaultSorter,
                  DPPMCreditCard,
                  MultiPinFormatDirective,
                  DefaultCurrencyDirective,
                  MultiEmailValidatorDirective,
                  DpBurgerMenuComponent,
                  UserAccessDirective,
                  TabIndexIgnoreDirective,
                  AccordianTabAnchorFixDirective,
                  DisableAutoCompleteItemDirective,
                  ErrorValidator,
                  StatusBarHelpDirective,
                  ConsiderationF8Directive,
                  CustomPickListModalComponent,
                  AutoCompleteCustomPickListComponent,
                  ReadOnlyDirective,
                  FieldCodeDirective,
                  StatementAdjustmentConfigComponent,
                  ReadOnlyBlockDirective,
                  StickyTableHeaderDirective,
                  DefaultNumberDirective,
                  PercentageDirective,
                  DpDropDownComponent,
                  FileUploadComponent,
                  OptionsButtonComponent,
                  DpTableKeyCommandsDirective,
                  ManageFolderTemplatesModalComponent,
                  ImportDoProcessTemplateModalComponent,
                  ImportDoProcessTemplateConfirmationModalComponent,
                  EditDocumentTemplateDetailsComponent,
                  DpEmailDirective,
                  FileUploadModal,
                  DetailedDatePipe,
                  AddNoteDialogComponent,
                  StickyNotesModal,
                  DpTableExcelStyleDirective,
                  ManageMasterSupplementalTaskDocumentsModalComponent,
                  SupplementalTaskCategoriesComponent,
                  SupplementalTaskCategoryComponent,
                  ManageExportTemplatesModal,
                  EditExportTemplateDetailsComponent,
                  DpProvinceFieldLabel,
                  DpShowByProvince,
                  DpProjectShowByProvince,
                  DpRadioEventDirective,
                  ManageTprTemplatesModal,
                  EditTprTemplateDetailsComponent,
                  TeraviewConfigModal,
                  DpCopyAndRenameModalComponent,
                  DocumentsForThisMatterComponent,
                  TrustAccountsModal,
                  AddEditTrustAccountModal,
                  SoaFeesComponent,
                  DisbursementConfigComponent,
                  dpSortableColumn,
                  dpSortableColumnExt,
                  PresentCirfModalComponent,
                  EventCalendarComponent,
                  ProcessReferralModal,
                  UnityConnectImportAttachmentComponent,
                  DeclineShareDocsModalComponent,
                  ShareDocumentsModalComponent,
                  MassUpdateProgressModalComponent,
                  ResponsibleStaffComponent,
                  DpStickyNotesComponent,
                  EventAvailabilityViewComponent,
                  PopoverDirective,
                  DpCirfFieldVisibilityDirective,
                  MatterNotificationConfigModalComponent,
                  CustomThirdpartyModalComponentModal,
              ],
              providers       : [
                  DocumentProductionService,
                  DocumentProductionLoggerService,
                  MailParseService,
                  TypeCodesService,
                  UnSavedChangesGuard,
                  CleanUpMatterDataGuard,
                  ContactService,
                  UserProfilesService,
                  TempAccountService,
                  AuthorizationService,
                  CustomPickListService,
                  StatusBarService,
                  DocumentProfileCache,
                  UserDefinedFieldService,
                  AddressSearchService,
                  SupplementalTaskService,
                  TeranetService,
                  TelusService,
                  FCTService,
                  TCOLService,
                  StewartTitleService,
                  TitlePlusService,
                  DocumentUtilityService,
                  StickyTableHeaderDirective,
                  DefaultNumberDirective,
                  StewartTitleAuthenticationService,
                  ALTOService,
                  TprTemplatesService,
                  MatterHelperService,
                  CustomMatterTypeService,
                  ThirdPartyIntegrationService,
                  ChecklistTemplateService,
                  MatterWorkItemsUtilsService,
                  MatterNotificationUtilService,
                  OpportunitiesService,
                  EventService,

                  Overlay ,
                  PopoverService,
                  EmpPortalAccessService

              ],
    exports: [
        AddressFormComponent,
        DocumentProfileAutoCompleteSelectComponent,
        OtherContactInfoFieldsComponent,
        UserDefinedFieldComponent,
        PinFormatDirective,
        CurrencyDirective,
        TrimmedInput,
        AutoresizableMultilineInput,
        DelayedValueChangeDirective,
        CapitalizedInput,
        UppercasedInput,
        UppercaseDirective,
        UppercaseDirectiveAlpha,
        LowercaseDirective,
        LowercaseDirectiveAlpha,
        PreserveCursorPositionDirective,
        UppercasePreserveCursorPositionDirective,
        AutoMoveNextOnMaxlengthDirective,
        MatterNoFilterDirective,
        NoWhiteSpaceDirective,
        OnlyNumericDirective,
        LincNumberDirective,
        DecimalDirective,
        PhoneNumberDirective,
        DpPlaceholderDirective,
        DpPrintableDialogDirective,
        NumberOnly,
        AlphanumericOnly,
        AlphanumericSpaceOnly,
        AlphaOnlyDirective,
        DateFormatPipe,
        KeyupDropDownDirective,
        DpDefaultSorter,
        DPPMCreditCard,
        MultiPinFormatDirective,
        DefaultCurrencyDirective,
        MultiEmailValidatorDirective,
        DpBurgerMenuComponent,
        UserAccessDirective,
        TabIndexIgnoreDirective,
        DisableAutoCompleteItemDirective,
        ErrorValidator,
        StatusBarHelpDirective,
        ConsiderationF8Directive,
        CustomPickListModalComponent,
        AutoCompleteCustomPickListComponent,
        ReadOnlyDirective,
        FieldCodeDirective,
        StatementAdjustmentConfigComponent,
        ReadOnlyBlockDirective,
        StickyTableHeaderDirective,
        DefaultNumberDirective,
        PercentageDirective,
        DpDropDownComponent,
        FileUploadComponent,
        OptionsButtonComponent,
        DpTableKeyCommandsDirective,
        ManageFolderTemplatesModalComponent,
        ImportDoProcessTemplateModalComponent,
        ImportDoProcessTemplateConfirmationModalComponent,
        EditDocumentTemplateDetailsComponent,
        DpEmailDirective,
        DetailedDatePipe,
        DpTableExcelStyleDirective,
        ManageExportTemplatesModal,
        DpProvinceFieldLabel,
        DpShowByProvince,
        DpProjectShowByProvince,
        DpRadioEventDirective,
        DocumentsForThisMatterComponent,
        SoaFeesComponent,
        DisbursementConfigComponent,
        dpSortableColumn,
        dpSortableColumnExt,
        EventCalendarComponent,
        ResponsibleStaffComponent,
        UnityConnectImportAttachmentComponent,
        DpStickyNotesComponent,
        EventAvailabilityViewComponent,
        PopoverDirective,
        DpCirfFieldVisibilityDirective,
        MatterNotificationConfigModalComponent,


    ],
              entryComponents : [
                  ManageDocumentProductionCategoriesModalComponent,
                  ManageDocumentProductionCategoryModalComponent,
                  StatementAdjustmentConfigComponent,
                  ManageFolderTemplatesModalComponent,
                  ImportDoProcessTemplateModalComponent,
                  ImportDoProcessTemplateConfirmationModalComponent,
                  EditDocumentTemplateDetailsComponent,
                  FileUploadModal,
                  AddNoteDialogComponent,
                  StickyNotesModal,
                  ManageMasterSupplementalTaskDocumentsModalComponent,
                  ManageExportTemplatesModal,
                  EditExportTemplateDetailsComponent,
                  ManageTprTemplatesModal,
                  EditTprTemplateDetailsComponent,
                  TeraviewConfigModal,
                  DpCopyAndRenameModalComponent,
                  TrustAccountsModal,
                  AddEditTrustAccountModal,
                  PresentCirfModalComponent,
                  ProcessReferralModal,
                  UnityConnectImportAttachmentComponent,
                  DeclineShareDocsModalComponent,
                  ShareDocumentsModalComponent,
                  MassUpdateProgressModalComponent,
                  MatterNotificationConfigModalComponent,
                  CustomThirdpartyModalComponentModal

              ]
          })
export class SharedMainModule { }
