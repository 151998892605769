

export const dropDowns = {

    CategoryFieldType : [
        { value: 'NAME_ADDRESS', label: 'Name & Address' },
        { value: 'ACCOUNT_NUMBER', label: 'Account Number' },
        { value: 'COMMENCEMENT_DATE', label: 'Commencement Date' },
        { value: 'DETAILS', label: 'Details' },
        { value: 'FEE_AMOUNT', label: 'Fee/Amount' },
        { value: 'NOTES_1', label: 'Notes 1' },
        { value: 'NOTES_2', label: 'Notes 2' },
        { value: 'NOTES_3', label: 'Notes 3' },
        { value: 'TICKLER_DATE', label: 'Follow up/Tickler Fields' },
        { value: 'TICKLER_NOTES', label: 'Follow up/Tickler Notes' },
        { value: 'HAS_TASK_COMPLETED', label: 'Has workflow been completed?' },
        { value: 'DATE_TASK_COMPLETED', label: 'Date workflow completed' },
        { value: 'TICKLER_PER', label: 'Per' },
        { value: 'TASK_COMPLETION_NOTES', label: 'Workflow Completion notes' },
        { value: 'USER_DEFINED_FIELD', label: 'Additional Details' },
        { value: 'TICKLER_GROUP', label: 'Include Follow up / Tickler Fields' },
    ],

    CategoryFieldDataType : [
        { value: 'DERIVED', label: 'DERIVED' },
        { value: 'YES_NO', label: 'YES_NO' },
        { value: 'NO_YES', label: 'NO_YES' },
        { value: 'TEXT', label: 'TEXT' },
        { value: 'DATE', label: 'DATE' },
        { value: 'AMOUNT', label: 'AMOUNT' },
        { value: 'NUMBER', label: 'NUMBER' },
        { value: 'CONTACT', label: 'CONTACT' },
        { value: 'TICKER_INFO', label: 'TICKER_INFO' },
        { value: 'COMPOSITE', label: 'COMPOSITE' }

    ],

    appliesToOptions : [
        { value: 'SALE', label: 'Sale' },
        { value: 'PURCHASE', label: 'Purchase' },
        { value: 'MORTGAGE', label: 'Mortgage' }

    ],

    YESNO : [
        {label : 'Yes', value : 'YES'},
        {label : 'No', value : 'NO'}
    ],

    UserDefinedFieldOptions : [
        {label : 'N/A', value : 'N_A'},
        {label : 'Yes/No', value : 'YES_NO'},
        {label : 'No/Yes', value : 'NO_YES'},
        {label : 'Text', value : 'TEXT'},
        {label : 'DD/MM/YYYY', value : 'DATE'},
        {label : '$ Amount', value : 'AMOUNT'},
        {label : 'Number', value : 'NUMBER'}
    ],

    MATTER_TYPES  : [
        {label : 'PURCHASE', value : 'PURCHASE'},
        {label : 'SALE', value : 'SALE'},
        {label : 'MORTGAGE', value : 'MORTGAGE'}
    ],



};
