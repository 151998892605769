import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {Component} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {User} from '../../matters/shared';
import {ErrorService} from '../../shared/error-handling/error-service';
import {EmailFieldService} from '../../shared-main/email-field/email-field-service';
import {OpportunityView} from './opportunity-view';
import {DialogService} from '../../shared/dialog/dialog.service';
import Utils from '../../shared-main/utils';
import {ModalResult} from '../../shared-main/enums';

class DeclineShareDocsModalContext extends BSModalContext {
    opportunityView: OpportunityView;

}


@Component({
    selector: 'dp-decline-share-docs-modal-content',
    templateUrl: './decline-share-docs-modal.component.html',
    providers: [ErrorService, EmailFieldService]
})
export class DeclineShareDocsModalComponent implements ModalComponent<DeclineShareDocsModalContext> {

    context: DeclineShareDocsModalContext;
    message: string;

    constructor(public dialog: DialogRef<DeclineShareDocsModalContext>,

                public dialogService: DialogService) {
        this.context = dialog.context;
    }
    ngOnInit(): void {
        this.message = this.declineMessageDefaultText + this.unityMessageBlock;

    }

    get declineMessageDefaultText (): string{
        return 'Please be advised that our firm is not currently representing any parties to this transaction.';
    }

    get unityMessageBlock(): string {
        let sessionUser: User = Utils.getUserFromSession();
        return '\n\n' + sessionUser.unitySignatureBlock;
    }

    close(): void {
        this.dialog.close(ModalResult.Cancel);
    }

    sendDeclineMessage(){
        this.dialog.close({message: this.message});
    }

    get staticText(){
        return  'When "Send" is selected the following message will be sent to the sharing firm.';
    }

}
