import {Directive} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
    selector: '[ngModel][dp-lincnumber]',
    host: {
        '(keypress)': 'onInputChange($event.target.value)',
        '(paste)': 'onPaste($event)'
    }
})
export class LincNumberDirective {

    constructor(private model: NgModel) { }

    onPaste($event: ClipboardEvent) {
        $event.preventDefault();
        if($event.clipboardData){
            let lincNumber: string = $event.clipboardData.getData('text/plain');
            this.onInputChange(lincNumber);
        }
    }

    onInputChange(value) {
        let lincNumber = value.replace(/[\W_]+/g, '');
        const lincNumberLength = lincNumber.length;
        if (lincNumberLength === 0) {
            lincNumber = '';
        }
        else if (lincNumberLength < 4) {
            lincNumber = lincNumber.replace(/^(\w{0,4})/, '$1');
        }
        else if (lincNumberLength < 7) {
            lincNumber = lincNumber.replace(/^(\w{0,4})(\w{0,3})/, '$1 $2')
        }
        else if (lincNumberLength < 10) {
            lincNumber = lincNumber.replace(/^(\w{0,4})(\w{0,3})(\w{0,3})/, '$1 $2 $3');
        }
        else if (lincNumberLength < 13) {
            lincNumber = lincNumber.replace(/^(\w{0,4})(\w{0,3})(\w{0,3})(\w{0,3})/, '$1 $2 $3 $4');
        }
        else {
            lincNumber = lincNumber.substring(0, 199);
            lincNumber = lincNumber.replace(/^(\w{0,4})(\w{0,3})(\w{0,3})(\w{0,3})/, '$1 $2 $3 $4');
        }
        this.model.control.setValue(lincNumber.substring(0, 199), { emitEvent: false });
    }
}
