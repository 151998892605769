import {FieldCodeService} from '../../shared-main/field-code.service';
import {FieldCodeMapping, FieldMetaData} from '../../matters/shared/field-code-mapping';
import {Matter} from '../../matters/shared';
import {Utils} from '../../matters/shared/utils';


declare var jQuery: any;

export class FieldCodeHarvestUtil {

    showFeildCode: boolean = false;

    constructor(private fieldCodeService?: FieldCodeService) {

    }

    onFocusFieldCode(target, route): void {
        let fieldMetaData = this.generateFieldMetaData(target, route);
        if (fieldMetaData && this.fieldCodeService) {
            this.fieldCodeService.onFocusFieldCode(fieldMetaData);
        }
    }

    removeFieldCodeLabelsFromMatter(): void {
        jQuery('.matters-content').find("span.field-code-span").each(function (j, element) {
            jQuery(element).remove();
        });
    }


    showFieldCodeLabelOnMatter(route: string, matter: Matter): void {
        this.removeFieldCodeLabelsFromMatter();
        let fieldCodeHarvestUtilReference = this;
        let pageElements = document.querySelectorAll('input,select');

        for (var i = 0; i < pageElements.length; i++) {
            if (fieldCodeHarvestUtilReference.fieldCodeService) {
                let elementMetaData = fieldCodeHarvestUtilReference.generateFieldMetaData(pageElements[i], route);
                if (elementMetaData) {
                    let fieldCode = fieldCodeHarvestUtilReference.fieldCodeService.getFieldCode(elementMetaData);
                    if (!fieldCode && elementMetaData.elementIdentifierXpath && elementMetaData.elementIdentifierXpath.indexOf('DP-USER-DEFINED-FIELDS') > -1 && matter) {
                        let activeApplicableSection = matter.applicableSections.find(item => !!item.active);
                        if (activeApplicableSection && activeApplicableSection.shortCutKey) {
                            let udfIndex = elementMetaData.elementIdentifierXpath.split('/').slice(-1).pop().split('DP-');
                            if (udfIndex && udfIndex.length > 1 && udfIndex[1])
                                fieldCode = '?' + activeApplicableSection.shortCutKey + 'U/' + udfIndex[1];
                        }
                    }

                    if (fieldCode) {
                        if (fieldCode && fieldCode.indexOf('?') > -1 && matter && !!matter.matterTypeCode) {
                            fieldCode = fieldCode.replace("?", matter.isCustomMatter() ? 'C' : (matter.isProjectSale ? 'Z' : matter.matterTypeCode.charAt(0)));
                        }
                        if (fieldCode && fieldCode.indexOf('#') > -1 && matter && elementMetaData.elementMortgageIndex != undefined) {
                            fieldCode = fieldCode.replace("#", elementMetaData.elementMortgageIndex);
                        }

                        let parent = jQuery(pageElements[i]).parent();
                        if (jQuery(parent).hasClass('partial-dates')) {
                            jQuery("<span class='field-code-span field-code-span-date'>" + fieldCode + "</span>").insertAfter(jQuery(parent));
                        }
                        else if (jQuery(pageElements[i]).hasClass('ui-autocomplete-input')) {
                            jQuery("<span class='field-code-span'>" + fieldCode + "</span>").insertAfter(jQuery(pageElements[i]).closest("p-autocomplete")[0]);
                        }
                        else {
                            jQuery("<span class='field-code-span'>" + fieldCode + "</span>").insertAfter(jQuery(pageElements[i]));
                        }
                    }
                    else if (!fieldCode && elementMetaData.elementIdentifierXpath && elementMetaData.elementIdentifierXpath.indexOf('DP-MATTER-STATEMENT-ADJUSTMENT') > -1 && matter && !matter.isProjectSale) {
                        if (!jQuery('.so-adj-table tr:first-child span').hasClass('field-code-adjustment')) {
                            let counter = 5;
                            jQuery('.so-adj-table tr').each(function () {
                                fieldCode = '?N/' + counter;
                                if (fieldCode && fieldCode.indexOf('?') > -1 && matter && !!matter.matterTypeCode) {
                                    fieldCode = fieldCode.replace("?", matter.isCustomMatter() ? 'C' : (matter.isProjectSale ? 'Z' : matter.matterTypeCode.charAt(0)));
                                }
                                jQuery("<span class='field-code-span field-code-adjustment'>" + fieldCode + "</span>").insertAfter(jQuery(this).find('td:first-child'));
                                counter = counter + 1;
                            });
                        }
                    }
                }
            }
        }
    }


    getXpath(target ,isOriginalXpath? : boolean): string {
        var position,
            $node = target,
            nodeName = $node.prop('tagName'),
            $sibSameNameAndSelf = $node.siblings(nodeName).addBack(),
            steps = [],
            $parent = $node.parent(),
            parentName = $parent.prop('tagName');

        position = ($sibSameNameAndSelf.length > 1) ? '[' + ($sibSameNameAndSelf.index($node) + 1) + ']' : '';
        steps.push(nodeName + position);
        let counter = 0;
        let isXpathContianerFound = false;
        while ($parent.length == 1 && parentName !== 'BODY' && !isXpathContianerFound && parentName !== '#document' && counter < 500) {
            $sibSameNameAndSelf = $parent.siblings(parentName).addBack();
            position = ($sibSameNameAndSelf.length > 1) ? '[' + ($sibSameNameAndSelf.index($parent) + 1) + ']' : '';
            steps.push(parentName + position);
            $parent = $parent.parent();
            isXpathContianerFound = $parent.hasClass('xpath-container');
            if ($parent.hasClass('xpath-child-container') && !isOriginalXpath) {
                // below logic is only invoked when you have multiple element scenarios
                // example purchasers in purchaser tab
                // xpath-parent-container class need to be added to outside div
                // xpath-child-container need to add to all child div after parent container
                var child = $parent;
                var parent = child.closest(".xpath-parent-container");
                var children = parent.find(".xpath-child-container");
                var childIndex = children.index(child);
                var index = Number(childIndex) + 1;
                steps.push('DP-' + index);
            }
            parentName = $parent.prop('tagName');
            counter++;
        }
        return '/' + steps.reverse().join('/');
    }

    // Method Generated Field Level meta data about element
    generateFieldMetaData(element: any, route: string): FieldMetaData {
        let utils = new Utils();
        // currently we are only doing harvesting for input and select elements only
        if (element.tagName == "SELECT" || element.tagName == "INPUT") {
            let fieldMetaData = new FieldMetaData();
            // we do filtering over to filter any dynamic ids
            if (!(/\d/g).test(element.id)) {
                fieldMetaData.elementId = element.id;
            }
            fieldMetaData.elementName = element.name
            fieldMetaData.elementValue = element.value
            fieldMetaData.elementXpath = this.getXpath(jQuery(element));
            fieldMetaData.elementOriginalXpath = this.getXpath(jQuery(element) , true);
            // In case user defined fields and contact info fields we are going to use
            // dynamic id since they are ordered according to list index ...
            if (fieldMetaData.elementXpath.indexOf('DP-USER-DEFINED-FIELDS') > -1
                || fieldMetaData.elementXpath.indexOf('DP-OTHER-CONTACT-INFO-FIELDS') > -1 || fieldMetaData.elementXpath.indexOf('DP-COMPLIANCE') > -1) {
                fieldMetaData.elementId = element.id;
            }


            // Here we first we try to go parent prev sibling it is not label then
            // we search for label in that sibling container
            if (jQuery(element).parent().prev()) {
                if (jQuery(element).parent().prev().prop("tagName") == 'LABEL') {
                    fieldMetaData.elementLabel = jQuery(element).parent().prev()[0].textContent
                }
                else if (jQuery(element).parent().prev().find("label").length > 0) {
                    fieldMetaData.elementLabel = jQuery(element).parent().prev().find("label")[0].textContent
                }
            }

           let activeMortgageTab = jQuery(fieldMetaData.elementXpath.split('/')[1]).find('.dppm-mortgage-custom-tab li.active');
            if(activeMortgageTab && activeMortgageTab.length > 0){
                fieldMetaData.elementActionList.push( { action : 'tab' ,xpath: this.getXpath(jQuery(activeMortgageTab[0]), true), identifier :jQuery(activeMortgageTab[0]).find('a').text()});
                let mortgageLink = jQuery(fieldMetaData.elementXpath.split('/')[1]).find('.mortgage-link li.active');
                if(mortgageLink && mortgageLink.length > 0){
                    fieldMetaData.elementActionList.push( { action : 'tab' ,xpath: this.getXpath(jQuery(mortgageLink[0]), true), identifier :jQuery(mortgageLink[0]).find('span').text()});
                }
            }
            let shutterList : any[] = [];
            if(jQuery(element).parents('.shutter-bg') && jQuery(element).parents('.shutter-bg').length > 0) {
                shutterList.push(...jQuery(element).parents('.shutter-bg'));
            }
            if(shutterList && shutterList.length > 0){
                [].concat(shutterList).reverse().forEach( item =>{
                    let isAccordionShutter  = jQuery(item).parent().hasClass('dp-accordion-shutter');
                    if(isAccordionShutter){
                        let shutterElement = jQuery(item).find('.accordion-wrapper');
                        fieldMetaData.elementActionList.push( { action : 'shutter' ,xpath: this.getXpath(jQuery(shutterElement[0]), true), identifier :jQuery(shutterElement[0]).find('.control-label').text()  });
                    }
                    else {
                        let shutterElement = jQuery(item).find('.shutter-arrow');
                        if (shutterElement && shutterElement.length > 0) {
                            fieldMetaData.elementActionList.push({
                                action: 'shutter',
                                xpath: this.getXpath(jQuery(shutterElement[0]), true),
                                identifier: jQuery(shutterElement[0]).next().find('.control-label').text()
                            });
                        }
                    }
                    let customActiveTabAElement  = jQuery(item).find('.dppm-custom-tab li.active');
                    let customActiveTabMAElement  = jQuery(item).find('.dppm-mortgage-custom-tab li.active');
                    if(customActiveTabAElement && customActiveTabAElement.length > 0){
                        let texta = jQuery(customActiveTabAElement).find('a').text();
                        let textspan = jQuery(customActiveTabAElement).find('span').text();
                        fieldMetaData.elementActionList.push( { action : 'tab' ,xpath: this.getXpath(jQuery(customActiveTabAElement[0]), true) ,identifier : texta ? texta : textspan});
                    }
                    else if(customActiveTabMAElement && customActiveTabMAElement.length > 0){
                        let text = jQuery(customActiveTabAElement).find('a').text();
                        fieldMetaData.elementActionList.push( { action : 'tab' ,xpath: this.getXpath(jQuery(customActiveTabMAElement[0]), true) ,identifier : jQuery(customActiveTabMAElement).text() });
                    }
                })
            }

            // generating robust IdentifierXpath to be used when we match elements to give dp code
            // we are currently filter all path expect path given through over component selector tags
            fieldMetaData.elementIdentifierXpath = fieldMetaData.elementXpath.split('/').filter(item => (item.indexOf('DP') > -1 || item.indexOf('P-AUTOCOMPLETE') > -1) && item.indexOf('DP-ACCORDION') < 0).join('/')


            if (fieldMetaData.elementXpath.indexOf('DP-AUTOCOMPLETE-CUSTOM-PICK-LIST') > -1) {
                // Here we handle dp custom auto complete scenarios to extract labels
                // Since auto complete do have dynamic ids and name given by prime ng auto complete
                if (jQuery(element).closest("dp-autocomplete-custom-pick-list").parent().prev()) {
                    if (jQuery(element).closest("dp-autocomplete-custom-pick-list").parent().prev().prop("tagName") == 'LABEL') {
                        fieldMetaData.elementLabel = jQuery(element).closest("dp-autocomplete-custom-pick-list").parent().prev()[0].textContent
                    }
                    else if (jQuery(element).closest("dp-autocomplete-custom-pick-list").parent().prev().find("label").length > 0) {
                        fieldMetaData.elementLabel = jQuery(element).closest("dp-autocomplete-custom-pick-list").parent().prev().find("label")[0].textContent
                    }
                }

            }
            else if (fieldMetaData.elementXpath.indexOf('P-AUTOCOMPLETE') > -1 && jQuery(element).closest("p-autocomplete").length > 0) {
                // Here we handle prime ng auto complete scenarios
                // we try to extract name given by parent component to auto complete and use that as identifier
                if (jQuery(element).closest("p-autocomplete").length > 0 && jQuery(element).closest("p-autocomplete")[0].attributes['name']) {
                    fieldMetaData.elementAutoSuggestIdentifier = jQuery(element).closest("p-autocomplete")[0].attributes['name'].value;
                }
                else if (jQuery(element).closest("p-autocomplete").length > 0 && jQuery(element).closest("p-autocomplete")[0].attributes['id']) {
                    fieldMetaData.elementAutoSuggestIdentifier = jQuery(element).closest("p-autocomplete")[0].attributes['id'].value;
                }

                if (jQuery(element).closest("p-autocomplete").parent().prev()) {
                    if (jQuery(element).closest("p-autocomplete").parent().prev().prop("tagName") == 'LABEL') {
                        fieldMetaData.elementLabel = jQuery(element).closest("p-autocomplete").parent().prev()[0].textContent
                    }
                    else if (jQuery(element).closest("p-autocomplete").parent().prev().find("label").length > 0) {
                        fieldMetaData.elementLabel = jQuery(element).closest("p-autocomplete").parent().prev().find("label")[0].textContent
                    }
                }
            }
            else if (fieldMetaData.elementXpath.indexOf('DP-PARTIAL-DATE') > -1) {
                // Here we handle partial date scenarios
                // we try to extract xpath id which are given by parent component as field key to partial date component and use that as identifier
                if (jQuery(element).closest("dp-partial-date").length > 0 && jQuery(element).closest("dp-partial-date")[0].attributes['xpathid']) {
                    fieldMetaData.elementPartialDateIdentifier = jQuery(element).closest("dp-partial-date")[0].attributes['xpathid'].value;
                }
                // Since auto complete do have dynamic ids and name given by prime ng auto complete
                if (jQuery(element).closest("dp-partial-date").parent().prev()) {
                    if (jQuery(element).closest("dp-partial-date").parent().prev().prop("tagName") == 'LABEL') {
                        fieldMetaData.elementLabel = jQuery(element).closest("dp-partial-date").parent().prev()[0].textContent
                    }
                    else if (jQuery(element).closest("dp-partial-date").parent().prev().find("label").length > 0) {
                        fieldMetaData.elementLabel = jQuery(element).closest("dp-partial-date").parent().prev().find("label")[0].textContent
                    }
                }

            }
            else if (fieldMetaData.elementXpath.indexOf('DP-ADDRESS-FORM') > -1) {
                // Here we handle Address scenarios
                // we try to extract xpath id which are given by parent component as address summary label to address component and use that as identifier
                if (jQuery(element).closest("dp-address-form").length > 0 && jQuery(element).closest("dp-address-form")[0].attributes['xpathid']) {
                    fieldMetaData.elementAddressIdentifier = jQuery(element).closest("dp-address-form")[0].attributes['xpathid'].value;
                }
            }

            else if (fieldMetaData.elementIdentifierXpath.indexOf('DP-MORTGAGES') > -1 && route.indexOf("/mortgage/") > -1) {
                let mortgageIndex = route.split('/').slice(-1).pop();
                fieldMetaData.elementMortgageIndex = Number(mortgageIndex) + 1;
                // let elementIdentifierXpathIndex = fieldMetaData.elementIdentifierXpath.split('/').findIndex(item => item == 'DP-MORTGAGES');
                // if (elementIdentifierXpathIndex > -1) {
                //     let modifiedElementIdentifierXpath = fieldMetaData.elementIdentifierXpath.split('/');
                //     modifiedElementIdentifierXpath.splice(elementIdentifierXpathIndex + 1, 0, 'DP-' + mortgageIndex);
                //     fieldMetaData.elementIdentifierXpath = modifiedElementIdentifierXpath.join('/');
                //     fieldMetaData.elementMortgageIndex = Number(mortgageIndex) + 1;
                // }
            }

            // Here we try to see at least one of identifier is available
            // if not we will not be able to find dp code for those who do not have any metadata to work with ....
            if (!utils.isEmptyString(fieldMetaData.elementId) || !utils.isEmptyString(fieldMetaData.elementName) || !utils.isEmptyString(fieldMetaData.elementLabel)
                || !utils.isEmptyString(fieldMetaData.elementAutoSuggestIdentifier)
                || !utils.isEmptyString(fieldMetaData.elementAddressIdentifier)
                || !utils.isEmptyString(fieldMetaData.elementPartialDateIdentifier)
            ) {
                if (route) {
                    fieldMetaData.elementRoute = route.split('/').filter(item => (!(/\d/g).test(item) || ((/\d/g).test(item) && item.length < 3))).join('/')
                }

                return fieldMetaData;
            }
            else {
                return undefined;
            }
        }
        else {
            return undefined;
        }

    }


    generateFieldCodeObjectMap(matter: Matter, route: string): void {
        let fieldCodeObjectMap = [];
        let pageElements = document.querySelectorAll('input,select');
        for (var i = 0; i < pageElements.length; i++) {
            let fieldMetaData: FieldMetaData = this.generateFieldMetaData(pageElements[i], route);
            if (fieldMetaData) {
                let fieldCodeMap = new FieldCodeMapping();
                fieldCodeMap.fieldMetaData = fieldMetaData;
                fieldCodeMap.fieldMetaDataJson = JSON.stringify(fieldMetaData);
                fieldCodeMap.fieldValue = (pageElements[i] as any).value;
                if (fieldMetaData.elementXpath.indexOf('DP-PARTIAL-DATE') > -1 && (!!fieldMetaData.elementPartialDateIdentifier || !!fieldMetaData.elementLabel) && !!fieldMetaData.elementValue) {
                    let fieldCodeObject = fieldCodeObjectMap.find(item => item.fieldMetaData && (item.fieldMetaData.elementPartialDateIdentifier == fieldMetaData.elementPartialDateIdentifier
                        || item.fieldMetaData.elementLabel == fieldMetaData.elementLabel));
                    if (fieldCodeObject) {
                        fieldCodeObject.fieldMetaData.elementName = fieldCodeMap.fieldMetaData.elementName + '-' + fieldCodeObject.fieldMetaData.elementName;
                        fieldCodeObject.fieldMetaData.elementValue = fieldCodeMap.fieldMetaData.elementValue + '-' + fieldCodeObject.fieldMetaData.elementValue;
                        fieldCodeObject.fieldValue = fieldCodeObject.fieldMetaData.elementValue;
                    }
                    else {
                        fieldCodeObjectMap.push(fieldCodeMap);
                    }
                }
                else if (!!fieldMetaData.elementValue) {
                    fieldCodeObjectMap.push(fieldCodeMap);
                }
            }
        }
        if (fieldCodeObjectMap && fieldCodeObjectMap.length > 0 && this.fieldCodeService){
            let matterObj;
            if (matter) {
                matterObj = new Matter(matter);
                matterObj.soaTrustLedgerCollection.matter = undefined;
            }
            this.fieldCodeService.updateFieldCodeMapping(fieldCodeObjectMap, matterObj).subscribe(res => {
                if (!!res) {
                    this.removeFieldCodeLabelsFromMatter();
                    this.showFieldCodeLabelOnMatter(route, matter);
                }
            })

        }
    }
}

