import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Address} from '../../matters/shared/address';

export type EstateStatusType = 'TESTATE' | 'INTESTATE';

export const EstateStatusTypeValue = {
    TESTATE : 'TESTATE',
    INTESTATE: 'INTESTATE'
}

export type TrusteeType = 'INDIVIDUAL' | 'CORPORATION' | 'PUBLIC_GUARDIAN';


export class TeranetPropertyOwner extends BaseEntity {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    capacityCode: string;
    share: string;
    address: Address;
    genderType: string;
    estateStatus: EstateStatusType;
    estateTrustees: EstateTrustee[];
}

export class EstateTrustee extends BaseEntity {
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
    corporationName: string;
    trusteeType: TrusteeType;
    pursuantToPowerOfAttorney: boolean;
    toProvideEvidence: boolean;

    get displayName() : string {
        return this.fullName ? this.fullName : this.corporationName;
    }
}
