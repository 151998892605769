import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {SyngrafiiPackageType} from '../../event/signing-envelope';

export const AccountIntegrationTypes  = {
    zoom:'ZOOM',
    msTeams:'MS_TEAMS',
    googleMeet:'GOOGLE_MEET',
    docuSign: 'DOCU_SIGN',
    syngrafii: 'SYNGRAFII'
};
export type AccountIntegrationType = 'ZOOM' | 'MS_TEAMS'| 'GOOGLE_MEET' | 'DOCU_SIGN' | 'SYNGRAFII';

export class AccountIntegrationCredential extends BaseEntity {
    id: number;
    userName: string;
    integrationType: AccountIntegrationType;
    defaultSyngrafiiPackageType: SyngrafiiPackageType;
    credentialLabel: string;

}
