import {Authority} from './authority';
import {AUTHORIZATION_KEYS} from './authorization-keys';

export class AuthenticatedUser {
    userName: string;
    customerAccountId: number;
    publicAccountId: string;
    userId: string;
    customerAccountName: string;
    documentProfileId: number;
    contactId : number;
    role: string[] = [];
    grantedAuthorities: Array<Authority> = [];
    conveyancingCustomer: string;
    //registrationStatus : string;

    constructor(authenticatedUser? : AuthenticatedUser) {
        if(authenticatedUser) {
            for(let prop in authenticatedUser) {
                if(authenticatedUser.hasOwnProperty(prop)) {
                    this[prop] = authenticatedUser[prop];
                }
            }

            if(Array.isArray(authenticatedUser.grantedAuthorities)) {
                for(let i : number = 0; i < authenticatedUser.grantedAuthorities.length; ++i) {
                    this.grantedAuthorities[i] = new Authority(authenticatedUser.grantedAuthorities[i]);
                }
            }
        }

    }

    /**
     * This is a factory method for creating authenticated user from authorization response.
     * @param authResponse
     * @returns {AuthenticatedUser}
     */
    static createAuthenticatedUserFromApiResponse(authResponse : any): AuthenticatedUser {
        let authenticatedUser: AuthenticatedUser;
        if(authResponse) {
            authenticatedUser = new AuthenticatedUser();
            authenticatedUser.userName = authResponse.username;
            authenticatedUser.customerAccountId = authResponse.customerAccountId;
            authenticatedUser.customerAccountName = authResponse.customerAccountName;
            authenticatedUser.publicAccountId = authResponse.publicAccountId;
            authenticatedUser.contactId = authResponse.contactId;
            authenticatedUser.userId = authResponse.id;
            authenticatedUser.conveyancingCustomer = authResponse.conveyancingCustomer
            //authenticatedUser.registrationStatus = authResponse.registrationStatus;
            //Getting role from auth response
            if(Array.isArray(authResponse.authorities)) {
                // Now the role of user can have multiple-roles.
                // For example, the role of user can be ROLE_FULLY_AUTHENTICATED_USER and ROLE_SYSTEM_ADMINISTRATOR at the same time
                //Finding the role of user in authorities. As spring put both role & permissions in same collection.
                let authorityObjs : any[] = authResponse.authorities.filter(authorityObj => authorityObj.authority.startsWith(AUTHORIZATION_KEYS.ROLE_PREFIX));
                // let authorityObj : any[] = _.find(authResponse.authorities, function(authorityObj: any) {
                //     return authorityObj.authority.startsWith(AUTHORIZATION_KEYS.ROLE_PREFIX);
                // });
                if(Array.isArray(authorityObjs) && authorityObjs.length > 0) {
                    authorityObjs.forEach(item => {
                        authenticatedUser.role.push(item.authority);
                    });
                } else {
                    console.log("!!!Can not find the AUTHORIZATION_KEYS.ROLE_PREFIX")
                }

                authResponse.authorities.forEach(authorityObj => {
                    //if authority is not a role then add it to the granted authority
                    if(!authorityObj.authority.startsWith('ROLE_')) {
                        authenticatedUser.addToGrantedAuthorities(authorityObj.authority);
                    }
                })
            }
        }
        return authenticatedUser;
    }

    /**
     * This method adds the authority returned by server side into grantedAuthorities array.
     * Server side returns the authority in format of ADMINISTRATION/USER_MANAGEMENT/manage_user_setup-CREATE
     * so it splits the  string with hyphen and creates Authority object with operation & privilege
     * @param authorityRes
     */
    addToGrantedAuthorities(authorityRes: string): void {
        const splittedAuthorityRes: string[] = authorityRes.split(AUTHORIZATION_KEYS.AUTHORITY_SEPARATOR);
        let authority: Authority = new Authority();
        authority.operation = splittedAuthorityRes[0];
        authority.privilege = splittedAuthorityRes[1];
        this.grantedAuthorities.push(authority);
    }
}
