import {Directive, ElementRef} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
    selector: '[ngModel][dp-multi-pinFormat]'
})

export class MultiPinFormatDirective {

    constructor(private model: NgModel,
                private element: ElementRef) {
        this.model.valueChanges.subscribe(this.onValueChanges);
    }

    private onValueChanges = (newValue: any): void => {
        if(!newValue) {
            return;
        }
        this.model.viewToModelUpdate(newValue);

        let multiPin = newValue.split(",");
        let newModelValue : string;
        if(multiPin.length > 0)
        {
          for(let i=0; i< multiPin.length ; i++)
          {
              let newViewValue = this.getViewvalue(multiPin[i].toString().trim());
              newModelValue = newModelValue ? newModelValue+' , '+newViewValue: newViewValue;
          }
        }
         else {
            newModelValue = this.getViewvalue(newValue.toString().trim());
        }
        this.model.control.setValue(newModelValue, {emitEvent: false, emitViewToModelChange: false});

        }



    private getViewvalue(modelValue : string) : string {
        let viewValue: string;
        if (modelValue.length >= 5) {
            viewValue = modelValue.slice(0, 5) + '-' + modelValue.slice(5);
        }else if(modelValue.length > 0){
            viewValue = modelValue; //allowed partial pin value for PS matter
        }
        return viewValue;
    }
}
