import {AfterViewInit, Directive, ElementRef, Input, Renderer2, ViewContainerRef} from '@angular/core';

declare var jQuery : any;

@Directive({
               selector : '[dp-read-only-block]'

           })
export class ReadOnlyBlockDirective implements AfterViewInit {

    @Input() elementRefComp : ElementRef;
    @Input('dp-read-only-block') dpReadOnly : boolean = true;

    constructor(private elementRef : ElementRef, private renderer : Renderer2, private viewContainerRef : ViewContainerRef) {
    }


    ngAfterViewInit() {
        this.addReadOnlyBlock();
    }

    preventEvent = (event)=>{
        let readonly = event.currentTarget.classList.contains('readOnlyAccess');
        if(readonly && !event.currentTarget.classList.contains('not-disabled') ) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    addReadOnlyBlock() : void {
        if(this.dpReadOnly) {
            this.renderer.addClass(this.elementRef && this.elementRef.nativeElement, 'readOnlyAccess');
            if(this.elementRefComp && this.elementRefComp.nativeElement.querySelector('.readOnlyAccess')) {
                this.elementRefComp.nativeElement.querySelector('.readOnlyAccess')
                    .addEventListener('keyup', function(event) {
                        let readonly = event.currentTarget.classList.contains('readOnlyAccess');
                        if(event.keyCode == 120 && readonly) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    }, true);

                this.elementRefComp.nativeElement.querySelector('.readOnlyAccess')
                    .addEventListener('keydown', function(event) {
                        let readonly = event.currentTarget.classList.contains('readOnlyAccess');
                        if((event.keyCode == 120 || event.keyCode == 13 || event.keyCode == 32 || event.keyCode == 40) && readonly &&
                            (!readonly.classList || !readonly.classList.contains('not-disabled')) ) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    }, true);

                this.elementRefComp.nativeElement.querySelector('.readOnlyAccess')
                    .addEventListener('cut paste', this.preventEvent, true);

                this.elementRefComp.nativeElement.querySelector('.readOnlyAccess')
                    .addEventListener('mousedown', function(event){
                        event.srcElement.autocomplete = 'off';
                    }, true);

                this.elementRefComp.nativeElement.querySelector('.readOnlyAccess')
                    .addEventListener('mousedown', function(event) {
                        let readonly = event.currentTarget.classList.contains('readOnlyAccess');
                        if(event.target.tagName == 'SELECT' && readonly && !readonly.classList.contains('not-disabled')) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    }, true);
            }
            jQuery('.readOnlyAccess').on('keydown', 'input,textarea', function(event) {
                let readonly = event.delegateTarget.classList.contains('readOnlyAccess');
                if(!readonly || event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 9 || event.keyCode ==27 || (event.ctrlKey == true && event.keyCode == 65) || (event.ctrlKey == true && event.keyCode == 67)) {
                    // allow keys to pass
                } else {
                    event.preventDefault();
                    event.stopPropagation();
                }
            });
            jQuery('.readOnlyAccess').on('keydown', 'select', function(event) {
                let readonly = event.delegateTarget.classList.contains('readOnlyAccess');
                if(!readonly || event.keyCode == 9 || event.keyCode ==27 || (event.ctrlKey == true && event.keyCode == 65) || (event.ctrlKey == true && event.keyCode == 67)) {
                    // allow keys to pass
                } else {
                    event.preventDefault();
                    event.stopPropagation();
                }
            });
            jQuery('.readOnlyAccess').on('click', 'select, input[type="checkbox"], input[type="button"], button, span', function(event) {
                    let input: any = jQuery(this);
                    if (input && input[0] && input[0].classList && input[0].classList.contains('not-disabled')) {
                        input.prop("disabled", false);
                    } else {
                        let readonly = event.delegateTarget.classList.contains('readOnlyAccess');
                        input.prop("disabled", readonly);
                        if (readonly && event.keyCode != 27) {
                            event.preventDefault();
                        }
                    }
                }
            )
            jQuery('.readOnlyAccess').on('focus', 'select, input[type="checkbox"], input[type="button"], button', function(event) {
                    let input: any = jQuery(this);
                    if (input && input[0] && input[0].classList && input[0].classList.contains('not-disabled')) {
                        input.prop("disabled", false);
                    } else {
                        let readonly = event.delegateTarget.classList.contains('readOnlyAccess');
                        input.prop("disabled", readonly);
                        if (readonly && event.keyCode != 27) {
                            event.preventDefault();
                        }
                    }
                }
            )
            jQuery('.readOnlyAccess').on('change', 'input[type="radio"]', function(event) {
                                             let input : any = jQuery(this);
                                             let readonly = event.delegateTarget.classList.contains('readOnlyAccess');
                                             input.prop("disabled", readonly);
                                             if(readonly && event.keyCode !=27) {
                                                 event.preventDefault();
                                             }


                                         }
            )
        }
        else {
            jQuery('.readOnlyAccess button,input[type="button"]').prop('disabled', false);
            this.renderer.removeClass(this.elementRef.nativeElement, 'readOnlyAccess');
        }
    }

    ngOnChanges() {
        this.addReadOnlyBlock();
    }


}
