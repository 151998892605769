import {BaseEntity} from '../../shared/BaseEntity/base-entity';


export class SoaExportData extends BaseEntity{

    id:number;
    soaExportConfigId:number;
    itemName:string;
    itemValue:number;
    itemType:string;
    itemKey:string;
    expenseCode:string;
    glCode:string;

    constructor(soa? : SoaExportData) {
        super(soa);
    }

}
