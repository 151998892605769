import {FinalPurchaserHasPaidFeeType} from './so-adj-final-fee';
import {SoAdjWithTax} from './so-adj-with-tax';
import {StatementOfAdjustmentCreditType} from '../modals/adjustment-base.modal.component';
import {Matter} from '../../shared';
import {Project} from '../../../projects/shared/project';
import {OccupancyFeesCalculatedBasedOn} from '../../../projects/project-condo/project-condo';
import Utils from '../../../shared-main/utils';
import {Utils as Utils2} from '../../shared/utils';
import {StatementAdjustment} from '../statement-adjustment';
import moment from 'moment';
import {SoAdjInterimFee} from './so-adj-interim-fee';
import {PROVINCE_CODES} from '../../shared/user-province';

export type TotalOccupancyFeeTotalType = 'OCCUPANCY_FEES' | 'INTEREST' | 'COMMON_EXPENSES' | 'TAXES' | 'CONDOMINIUM_FEES';

const UnknownDate: string = "???????";

export class SoAdjTotalOccupancyFee extends SoAdjWithTax{

    id: number;

    totalType: TotalOccupancyFeeTotalType;

    creditTo: StatementOfAdjustmentCreditType;

    useAmountFrom: boolean;

    amount: number;

    purchaserHasPaidFee: FinalPurchaserHasPaidFeeType;

    paidFrom: string;

    paidTo: string;

    // not saved in db, used here just for calculations
    matterOccupancyDate: string = '1950/01/01';
    adjustAsAtClosingDate: string;
    finalOccupancyEndDate: string;
    isProjectOccFeeBasedOnPhantomMortgage: boolean;

    constructor(soAdjTotalOccupancyFee?: SoAdjTotalOccupancyFee ) {
        super(soAdjTotalOccupancyFee);
        if (!soAdjTotalOccupancyFee){
            this.totalType = 'OCCUPANCY_FEES';
            this.creditTo = 'PURCHASER';
            this.useAmountFrom = false;
            this.purchaserHasPaidFee = 'AND_INCLUDING_MONTH_OF_CLOSING';
            this.applyTax = false;
        }
    }

    get useAmountFromBoolean(): boolean {
        return Utils.convertToBoolean(this.useAmountFrom);
    }

    get amountPlusTax(): number {
        return Number(this.amount)  + this.getHstOrGstValueForAmount(this.amount);
    }

    updateFromMatterAndProject(matter: Matter, project: Project): void{
        if (matter) {
            if (matter.isProjectSale || matter.isPurchaseMatterLinkedProjectSale()) {
                this.matterOccupancyDate = matter.isMatterProvinceAB ? matter.occupancyDate :  matter.requisitionDate;
            }
            else {
                this.matterOccupancyDate = matter.requisitionDate ? matter.requisitionDate : matter.matterCloseDate; // E6
            }
            this.adjustAsAtClosingDate = matter.adjustAsAtClosingDateFlag  === 'SPECIFY' ? matter.adjustAsAtClosingDate : matter.matterCloseDate; // E8
            this.updatePurchasePaidDates(matter);
            this.finalOccupancyEndDate = "1900/01/01";
            if (Array.isArray(matter.finalStatementAdjustments) && matter.finalStatementAdjustments.length > 0) {
                let soaAdjFinalOcc: StatementAdjustment = matter.finalStatementAdjustments.find(soAdj => soAdj.isFinalOccupancyFee());
                if (soaAdjFinalOcc && soaAdjFinalOcc.soAdjFinalOccupancyFee){
                    this.finalOccupancyEndDate = soaAdjFinalOcc.soAdjFinalOccupancyFee.paidOn;
                }
            }

            if (Utils.convertToBoolean(this.useAmountFrom)) {
                this.amount = 0;
                if (Array.isArray(matter.interimStatementAdjustments) && matter.interimStatementAdjustments.length > 0) {
                    if (matter.project && matter.project.isNonCondoPotlON){
                        //To do: matter.allStatementAdjustments.find should be replaced by function searching for first adjustment in ordered adjustments
                        let interimAdj: StatementAdjustment = matter.allStatementAdjustments.find((soAdj: StatementAdjustment) => soAdj.isInterimEarlyPossessionFee());
                        if (interimAdj && interimAdj.soAdjInterimEarlyPossessionFee) {
                            //this.amount = this.getAmountFromEarlyPossessionFee(interimAdj.soAdjInterimEarlyPossessionFee);
                            this.amount = this.getAmountFromInterim(interimAdj.soAdjInterimEarlyPossessionFee);
                        }
                    }else{
                        // To do: matter.allStatementAdjustments.find should be replaced by function searching for first adjustment in ordered adjustments
                        let soaAdjInterimOcc: StatementAdjustment = matter.interimStatementAdjustments.find(soAdj => soAdj.isInterimOccupancyFee());
                        if (soaAdjInterimOcc && soaAdjInterimOcc.soAdjInterimOccupancyFee) {
                            this.amount = this.getAmountFromInterim(soaAdjInterimOcc.soAdjInterimOccupancyFee);
                        }
                    }
                }
            }

        }
        this.isProjectOccFeeBasedOnPhantomMortgage = project && project.projectCondo && project.projectCondo.occupancyFeesCalculatedBasedOn === OccupancyFeesCalculatedBasedOn.phantomMortgage;
    }

    getStartDate(): string { // F23
        switch (this.purchaserHasPaidFee) {
            case'AND_INCLUDING_MONTH_OF_CLOSING':
            case'END_OF_MONTH_PRECEDING_CLOSING':
            case'OTHER_ENTER_OWN_DATE':
            case'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT':
                return this.matterOccupancyDate;
            case'OTHER_ENTER_OWN_START_AND_END_DATES':
            case'OTHER_ENTER_OWN_START_DATE_TO_AND_INCLUDING_MONTH_OF_CLOSING':
            case'OTHER_ENTER_OWN_START_DATE_TO_END_OF_MONTH_PRECEDING_CLOSING':
            case'OTHER_ENTER_OWN_START_DATE_TO_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT':
                return this.paidFrom;
            default:
                return UnknownDate;
        }
    }

    getEndDate(): string { // F23
        switch (this.purchaserHasPaidFee) {
            case'AND_INCLUDING_MONTH_OF_CLOSING':
                return Utils.lastDayOfMonthFromDate(this.adjustAsAtClosingDate);
            case'END_OF_MONTH_PRECEDING_CLOSING':
                return Utils.lastDayOfPreviousMonthFromDate(this.adjustAsAtClosingDate);
            case'OTHER_ENTER_OWN_DATE':
                return this.paidFrom;
            case'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT':
                return this.paidTo;
            case'OTHER_ENTER_OWN_START_AND_END_DATES':
                return this.paidTo;
            case'OTHER_ENTER_OWN_START_DATE_TO_AND_INCLUDING_MONTH_OF_CLOSING':
                return Utils.lastDayOfMonthFromDate(this.adjustAsAtClosingDate);
            case'OTHER_ENTER_OWN_START_DATE_TO_END_OF_MONTH_PRECEDING_CLOSING':
                return Utils.lastDayOfPreviousMonthFromDate(this.adjustAsAtClosingDate);
            case'OTHER_ENTER_OWN_START_DATE_TO_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT':
                return this.paidTo;
            default:
                return UnknownDate;
        }
    }

    getAmountFromInterim(soaAdjInterimOcc: SoAdjInterimFee): number {
        if (soaAdjInterimOcc){
            switch (this.totalType){
                case "OCCUPANCY_FEES":  return Number(soaAdjInterimOcc.total);
                case "INTEREST":        return Number(soaAdjInterimOcc.mortgageInterestTotal);
                case "CONDOMINIUM_FEES" :
                case "COMMON_EXPENSES":
                    return Number(soaAdjInterimOcc.commonExpenseTotal);
                case "TAXES":           return Number(soaAdjInterimOcc.taxesTotal);
            }
        }
        return 0;
    }

    isOutOfRange(): boolean{
        return (!Utils.isValidDate(this.getStartDate()) && this.purchaserHasPaidFee !== 'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT') ||
            (Utils.getDateDiff(this.getStartDate(), this.getEndDate()) < 0);
    }

    getTotalComponentAmountStr(): string {
        if (this.getCreditAmount() > 0){
            if (this.isOutOfRange()){
                return "??????????.??";
            }
            return Utils.formattedCurrencyValue(this.getCreditAmount());
        }
        return "??????????.??";
    }

    getTotalComponentLine(): string{
        switch (this.purchaserHasPaidFee) {
            case'AND_INCLUDING_MONTH_OF_CLOSING':
            case'END_OF_MONTH_PRECEDING_CLOSING':
            case'OTHER_ENTER_OWN_DATE':
            case'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT':
                return 'from occupancy to ' + Utils2.formatDate(this.getEndDate(), UnknownDate);
            case'OTHER_ENTER_OWN_START_AND_END_DATES':
            case'OTHER_ENTER_OWN_START_DATE_TO_AND_INCLUDING_MONTH_OF_CLOSING':
            case'OTHER_ENTER_OWN_START_DATE_TO_END_OF_MONTH_PRECEDING_CLOSING':
            case'OTHER_ENTER_OWN_START_DATE_TO_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT':
                return `from ${Utils2.formatDate(this.getStartDate(), UnknownDate)} to ${Utils2.formatDate(this.getEndDate(), UnknownDate)}`;
            default:
                return UnknownDate;
        }
    }

    isFirstFourOptions(): boolean{
        switch (this.purchaserHasPaidFee) {
            case'AND_INCLUDING_MONTH_OF_CLOSING':
            case'END_OF_MONTH_PRECEDING_CLOSING':
            case'OTHER_ENTER_OWN_DATE':
            case'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT':
                return true;
            case'OTHER_ENTER_OWN_START_AND_END_DATES':
            case'OTHER_ENTER_OWN_START_DATE_TO_AND_INCLUDING_MONTH_OF_CLOSING':
            case'OTHER_ENTER_OWN_START_DATE_TO_END_OF_MONTH_PRECEDING_CLOSING':
            case'OTHER_ENTER_OWN_START_DATE_TO_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT':
                return false;
            default:
                return false;
        }
    }

    // C32
    getCreditAmount(): number{
        let startDate: string = this.getStartDate(); // N6
        let endDate: string = this.getEndDate(); // N7
        let endDateCheck = (Utils.getDateDiff(this.getEndDate(), this.adjustAsAtClosingDate) > 0); // N8
        let sameMonthAndYear = (Utils.getMonth(startDate) === Utils.getMonth(endDate) && Utils.getYear(startDate) === Utils.getYear(endDate)); // N9
        let temporaryEndDate = Utils.lastDayOfMonthFromDate(endDate); // N10
        let daysInThisMonthEndDate = Utils.getDay(temporaryEndDate); // N11
        let amountToBeProrated = Utils.roundCurrency(this.amountPlusTax); // N12
        let tmm = Utils.getMonth(startDate); //N13
        let tyyyy = Utils.getYear(startDate); //N14
        let checkIfDecember = (tmm === 12); //N15
        let newMonth = checkIfDecember ? 1 : tmm + 1; // N16
        let newYear = checkIfDecember ? tyyyy + 1 : tyyyy; // N17
        let temporaryStartDate = this.isFirstFourOptions() ? Utils.getDateFromNumber(Number(newYear), newMonth, 1) : startDate; // N18

        let temporaryEndDate2 = endDate; // N19
        if (Utils.getDay(startDate) == 1 && Utils.getDay(endDate) == daysInThisMonthEndDate &&
            (this.purchaserHasPaidFee === 'OTHER_ENTER_OWN_DATE' || this.purchaserHasPaidFee === 'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT' || this.purchaserHasPaidFee === 'OTHER_ENTER_OWN_START_AND_END_DATES')){
            temporaryEndDate2 = Utils.getDateFromNumber(Number(Utils.getYear(endDate)), Utils.getMonth(endDate), 1);
        } else if (Utils.getDay(startDate) == 1 && Utils.getDay(endDate) == daysInThisMonthEndDate){
            temporaryEndDate2 = Utils.addMomentDays(temporaryEndDate, 1);
        }
        let numberOfElapsedMonths = 0; //N20
        if (Utils.getDateDiff(startDate, temporaryEndDate2) > 0){
            numberOfElapsedMonths = Utils.getDateDiffMonths(startDate, temporaryEndDate2);
        }
        let daysInThisMonthStartDate = Utils.getDay(Utils.lastDayOfMonthFromDate(startDate)); // N21
        let tdd = Utils.getDay(startDate); // N22
        let dailyRateStartMonth = amountToBeProrated / daysInThisMonthStartDate; // N23
        let dailyRateEndMonth = amountToBeProrated / daysInThisMonthEndDate; // N24
        let valueForMonths = Utils.roundCurrency(amountToBeProrated * numberOfElapsedMonths); //N25
        let valueForStartDays = 0; //N26
        if (Utils.getDay(startDate) != 1){
            valueForStartDays = Utils.roundCurrency((daysInThisMonthStartDate - Utils.getDay(startDate) + 1 ) * dailyRateStartMonth);
        }
        // =IF(AND(DAY(N7)<>N11,OR(D22=PickList!C4,D22=PickList!C5,D22=PickList!C6,D22=PickList!C7,D22=PickList!C8,D22=PickList!C9)),ROUND(DAY(N7)*N24,2),0)
        let valueForEndDdays = 0; //N27
        if (Utils.getDay(endDate) !== daysInThisMonthEndDate && (this.purchaserHasPaidFee !== 'AND_INCLUDING_MONTH_OF_CLOSING' && this.purchaserHasPaidFee !== 'END_OF_MONTH_PRECEDING_CLOSING')){
            valueForEndDdays = Utils.roundCurrency(Utils.getDay(endDate) * dailyRateEndMonth);
        }
        // =IF(AND(DAY(N6)<>1, DAY(N7)<>N11, DAY(N6)<=DAY(N7), OR(D22=PickList!C4,D22=PickList!C5,D22=PickList!C6)),     SUM(N25:N27)-N12,   SUM(N25:N27))
        let total = 0; //N28
        if ((Utils.getDay(startDate) !== 1 && Utils.getDay(endDate) !== daysInThisMonthEndDate && Utils.getDay(startDate) <= Utils.getDay(endDate)) &&
            (this.purchaserHasPaidFee === 'OTHER_ENTER_OWN_DATE' || this.purchaserHasPaidFee === 'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT' || this.purchaserHasPaidFee === 'OTHER_ENTER_OWN_START_AND_END_DATES')){
            total = valueForMonths + valueForStartDays + valueForEndDdays - amountToBeProrated;
        } else{
            total = valueForMonths + valueForStartDays + valueForEndDdays;
        }

        return total;
    }

    getDescription(isProjectOccFeeBasedOnPhantomMortgage: boolean, provinceCode : string): string{
        switch (this.totalType) {
            case "OCCUPANCY_FEES":
                return 'OCCUPANCY FEES' + (this.creditTo === 'PURCHASER' ? ' PAID BY PURCHASER' : ' CHARGEABLE TO PURCHASER');
            case "INTEREST":
                return (isProjectOccFeeBasedOnPhantomMortgage && provinceCode != PROVINCE_CODES.ALBERTA ? 'MORTGAGE INTEREST' : 'INTEREST') + (this.creditTo === 'PURCHASER' ? ' PAID DURING OCCUPANCY' : ' ACCRUED DURING OCCUPANCY');
            case "COMMON_EXPENSES":
                return 'COMMON EXPENSES' + (this.creditTo === 'PURCHASER' ? ' PAID DURING OCCUPANCY' : ' DURING OCCUPANCY');
            case "CONDOMINIUM_FEES":
                return 'CONDOMINIUM FEES' + (this.creditTo === 'PURCHASER' ? ' PAID DURING OCCUPANCY' : ' DURING OCCUPANCY');
            case "TAXES":
                return 'TAXES' + (this.creditTo === 'PURCHASER' ? ' PAID DURING OCCUPANCY' : ' ACCRUED DURING OCCUPANCY');
            default :
                return '';
        }
    }

    getMonthlyLine(isProjectOccFeeBasedOnPhantomMortgage: boolean, provinceCode : string): string{
        switch (this.totalType) {
            case "OCCUPANCY_FEES":
                return 'Monthly occupancy fees';
            case "INTEREST":
                return `Monthly ${isProjectOccFeeBasedOnPhantomMortgage && provinceCode != PROVINCE_CODES.ALBERTA ? 'mortgage interest' : 'interest'} component of occupancy fees`;
            case "COMMON_EXPENSES":
                return `Monthly common expense component of occupancy fees`;
            case "CONDOMINIUM_FEES":
                return `Monthly condominium fees component of occupancy fees`;
            case "TAXES":
                return `Monthly tax component of occupancy fees`;
            default :
                return '';
        }
    }

    getTotalLine(isProjectOccFeeBasedOnPhantomMortgage: boolean,  provinceCode : string): string{
        let aVerb: string = this.creditTo === 'PURCHASER' ? ' paid' : ' accrued';
        switch (this.totalType) {
            case "OCCUPANCY_FEES":
                return `Total occupancy fees ${aVerb}`;
            case "INTEREST":
                return `Total ${isProjectOccFeeBasedOnPhantomMortgage && provinceCode != PROVINCE_CODES.ALBERTA ? 'mortgage interest' : 'interest'} component ${aVerb}`;
            case "COMMON_EXPENSES":
                return `Total common expense component ${aVerb}`;
            case "CONDOMINIUM_FEES":
                return `Total condominium fees component ${aVerb}`;
            case "TAXES":
                return `Total tax component ${aVerb}`;
            default :
                return '';
        }
    }

    updatePurchasePaidDates(matter : Matter): void {
        let dateToBeUpdate: string;
        let finalOccupanyFeeSOA = matter.finalStatementAdjustments.find(item => item.isFinalOccupancyFee());
        if (finalOccupanyFeeSOA && matter.getFinalClosingDate()) {
            if (finalOccupanyFeeSOA.soAdjFinalOccupancyFee.purchaserHasPaidFee == 'AND_INCLUDING_MONTH_OF_CLOSING'
                || finalOccupanyFeeSOA.soAdjFinalOccupancyFee.purchaserHasPaidFee == 'END_OF_MONTH_PRECEDING_CLOSING' || finalOccupanyFeeSOA.soAdjFinalOccupancyFee.purchaserHasPaidFee == 'NO_OCCUPANCY_FEES_EVER_PAID') {
                dateToBeUpdate = moment(matter.getFinalClosingDate()).subtract(1, 'months').endOf('month').format('YYYY/MM/DD');
            }
            if (finalOccupanyFeeSOA.soAdjFinalOccupancyFee.purchaserHasPaidFee == 'OTHER_ENTER_OWN_DATE' && finalOccupanyFeeSOA.soAdjFinalOccupancyFee.paidOn) {
                dateToBeUpdate = finalOccupanyFeeSOA.soAdjFinalOccupancyFee.paidOn;
            }
        }
        if (dateToBeUpdate && (this.purchaserHasPaidFee == 'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT'
            || this.purchaserHasPaidFee == 'OTHER_ENTER_OWN_START_DATE_TO_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT')){
            this.paidTo = dateToBeUpdate;
        }

    }
}
