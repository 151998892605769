import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';

declare var jQuery : any;

@Component({
               selector: 'dp-multi-select',
               templateUrl: './multi-select.component.html'
           })
export class DPPMMultiSelectComponent implements OnInit, AfterViewInit  {
    // If show "focus-first" clase
    @Input('isFocusFirstVisible') isFocusFirstVisible : boolean = false;

    // data object to pass - label : value
    @Input('multiSelectData') multiSelectData : any[];

    // any pre-selected values to be initially checked
    @Input('multiSelectDataSelected') multiSelectDataSelected : any[];

    // root name and id for checkbox box inputs and labels with appended index value
    @Input('inputName') inputName : string;

    // if id and label need to be different then pass label value in it otherwise use above parameter 'inputName'
    @Input('inputLabel') inputLabel : string;

    // label displayed in the multi-select
    @Input('title') title : string;

    // label displayed when there is multiple items selected
    @Input('multiSelectedTitle') multiSelectedTitle : string = '';

    // if list style true it changes the dropdown to a visible list
    @Input('listStyle') listStyle : boolean = false;

    // display values shown in the multi-select in the title
    @Input('showLabelInTitle') showLabelInTitle : boolean;

    // applies only to Lawyers and Clerks multiselect to split the initials from first name and last name  label
    @Input('splitLabelLawyersAndClerks') splitLabelLawyersAndClerks : boolean = false;

    //To select All if multiSelectDataSelected is empty
    @Input('defaultToAll') defaultToAll : boolean = true;

    //To disable the multiSelect component
    @Input('disabled') disabled : boolean = false;

    // value for "All" selection
    @Input('allValue') allValue: string|number = 'ALL';

    // event to emit the selected values
    @Output() updateMultiSelect = new EventEmitter();

    selectedValues:  string[] = [];
    isDropDownMenuOpened : boolean = false;

    constructor() {
    }

    ngOnInit(): void {
        this.refreshSelectedValues(this.defaultToAll);

        if (this.selectedValues.findIndex(item => item === 'ALL') > -1) {
            this.selectedValues = this.multiSelectData.filter(item => item.value !== 'ALL').map(item => item.value);
            this.selectedValues.push('ALL');
        }
    }

    ngOnChanges(changes: SimpleChanges){
        let currentValue : string[] = changes && changes['multiSelectDataSelected'] && changes['multiSelectDataSelected'].currentValue;
        let previousValue : string[] = changes && changes['multiSelectDataSelected'] && changes['multiSelectDataSelected'].previousValue;
        if (currentValue && currentValue.length == 1 && currentValue[0] === 'ALL') {
            //Here doing setTimeOut for allowing parent component to fetch all the drop-down options from backend, so below logic can run.
            setTimeout(() => {
            this.selectedValues = this.multiSelectData.filter(item => item.value !== 'ALL').map(item => item.value);
            this.selectedValues.push('ALL');
                console.log(">> set the selectedValue to the full value list, size = " + this.selectedValues.length);
                this.updateMultiSelect.emit(this.selectedValues);
            }, 1);
        } else {
            this.selectedValues = [];
            if(Array.isArray(this.multiSelectDataSelected)) {
                for(let i = 0; i < this.multiSelectDataSelected.length; i++){

                    this.selectedValues.push(this.multiSelectDataSelected[i]);
                }
            }
        }

        if(currentValue && currentValue.length > 0) {
            if(!currentValue.includes("ALL")){
                if(currentValue && currentValue.includes("ALL_ACTIVE_LAWYERS")
                    && (!previousValue || !previousValue.includes("ALL_ACTIVE_LAWYERS"))) {
                        this.selectedValues = this.multiSelectData.filter(item => !item.label.includes('inactive') && item.value != "N/A" && item.value != "ALL").map(item => item.value);
                        this.updateMultiSelect.emit(this.selectedValues);
                }

                if(currentValue && currentValue.includes("ALL_ACTIVE_LAWCLERKS")
                    &&(!previousValue || !previousValue.includes("ALL_ACTIVE_LAWCLERKS"))){
                        this.selectedValues = this.multiSelectData.filter(item => !item.label.includes('inactive') && item.value != "N/A" && item.value != "ALL").map(item => item.value);
                        this.updateMultiSelect.emit(this.selectedValues);
                }

                if (currentValue && currentValue.includes("ALL_ACTIVE_ASSIGNEES")
                    && (!previousValue || !previousValue.includes("ALL_ACTIVE_ASSIGNEES"))) {
                        setTimeout(() => {
                            this.selectedValues = this.multiSelectData.filter(item => !item.label.includes('inactive') && item.value != "N/A" && item.value != "ALL").map(item => item.value);
                        }, 1000)
                }
            }
        }

    }

    get allLabel() : string {
        if(Array.isArray(this.multiSelectData) && this.multiSelectData.length > 0){
            return this.multiSelectData[0].label;
        }
    }

    refreshSelectedValues(defaultToAll? : boolean) : void {
        this.selectedValues = [];

        if(Array.isArray(this.multiSelectDataSelected)) {
            for(let i = 0; i < this.multiSelectDataSelected.length; i++){

                this.selectedValues.push(this.multiSelectDataSelected[i]);
            }
        }

        if(this.selectedValues.length<1 && defaultToAll){
            for(let i = 0; i < this.multiSelectData.length; i++){
                this.selectedValues.push(this.multiSelectData[i].value);
            }
        }
    }

    ngAfterViewInit() {}

    inputNameIsProvince() : boolean {
        return this.inputName === 'province';
    }

    get inputNameIsTask() : boolean {
        return this.inputName === 'tasks';
    }


    dataFilter(n) : void {
        if (!this.isSelected(n)) {
            /// if  not already selected we add it to list
            if(n === this.allValue){
                this.selectedValues  = [];
                for(let i = 0; i < this.multiSelectData.length; i++){
                    if(!this.multiSelectData[i].disabled){
                        this.selectedValues.push(this.multiSelectData[i].value);
                    }
                }
            }
            if(this.isSelected(this.allValue)){
                this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m === this.allValue), 1);
            }

            if(n === "ALL_ACTIVE_LAWYERS") {
                this.selectedValues  = [];
                this.selectedValues = this.multiSelectData.filter(item => !item.label.includes('inactive') && item.value != "N/A").map(item => item.value);
                if(this.isSelected("ALL_ACTIVE_LAWYERS")){
                    this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m === this.allValue), 1);
                    this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m === "ALL_ACTIVE_LAWYERS"), 1);
                }
            }

            if(n === "ALL_ACTIVE_LAWCLERKS") {
                this.selectedValues  = [];
                this.selectedValues = this.multiSelectData.filter(item => !item.label.includes('inactive') && item.value != "N/A").map(item => item.value);
                if(this.isSelected("ALL_ACTIVE_LAWCLERKS")){
                    this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m === this.allValue), 1);
                    this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m === "ALL_ACTIVE_LAWCLERKS"), 1);
                }
            }

            if(n === "ALL_ACTIVE_ASSIGNEES") {
                this.selectedValues  = [];
                this.selectedValues = this.multiSelectData.filter(item => !item.label.includes('inactive') && item.value != "N/A").map(item => item.value);
                if(this.isSelected("ALL_ACTIVE_ASSIGNEES")){
                    this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m === this.allValue), 1);
                    this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m === "ALL_ACTIVE_ASSIGNEES"), 1);
                }
            }

            this.selectedValues.push(n);

        } else {
            // if  selected then we remove it from list
            this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m == n), 1);
            // remove also the ALL selection
            if(this.isSelected(this.allValue)){
                this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m === this.allValue), 1);
            }
            if(n === this.allValue || n === "ALL_ACTIVE_LAWYERS" || n === "ALL_ACTIVE_LAWCLERKS" || n === "ALL_ACTIVE_ASSIGNEES"){
                this.selectedValues  = [];
            }
        }
        this.updateMultiSelect.emit(this.selectedValues);
    }

    isSelected(n: string|number): boolean {
        return !!this.selectedValues.find((m: string) => m == n);
    }

    get listSelectedValues() :string{
        let list: string = '';
        for(let i = 0; i < this.selectedValues.length; i++){
            if(i==(this.selectedValues.length-1)) {
                list = list + this.selectedValues[i];
            } else {
                list = list + this.selectedValues[i] + ", ";
            }
        }
        return list;
    }

    get lookupLabelValue() :string {
        let n = this.selectedValues[0];
        let index = this.multiSelectData.findIndex((m : any) => m.value == n);
        let label = (index >= 0) ? this.multiSelectData[index].label : "";
        if(this.splitLabelLawyersAndClerks == true) {
            // if(label!='Not Assigned') {
            label = this.splitLabel(label);
            // }
        }

        return label;
    }

    splitLabel(l) : string {
        let array = l.split(' ');
        let name = array[1] + ' ' + array[2];
        if(array.length<3){
            return l;
        } else {
            return name;
        }

    }

    openDropDownMenu(e){

        // jQuery(e.target).next('ul').addClass('dropdown-open');
        this.isDropDownMenuOpened= true;
    }

    closeDropDownMenu(e){

        // jQuery('.dropdown-menu').removeClass('dropdown-open');
        this.isDropDownMenuOpened= false;
    }

    toggleDropDown(e){

        if(this.isDropDownMenuOpened){
            this.isDropDownMenuOpened = false;
        } else {
            this.isDropDownMenuOpened = true;
        }

    }

    gotoNextFocus(e){

        jQuery(e.target.firstElementChild).focus();
    }

    blurTarget(e){
        /// close the menu when tabbed on last item in list
        this.isDropDownMenuOpened = false;
        jQuery(e.target).blur();
    }

    preventDefaultArrowKeys(e){

        if(e.keyCode==38 || e.keyCode==40){
            e.preventDefault();
        }
    }

    getInputLabel(): string {
        if(this.inputLabel && this.inputLabel.length > 0) {
            return this.inputLabel.trim();
        } else {
            return this.inputName;
        }
    }
    isDisabled(disableItem?: boolean): boolean{
        return ((this.listStyle && this.disabled )|| !!disableItem);
    }
}
