import {ToBeDefinedComponent} from './first-login/toBeDefined/toBedefined.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SocialLinksComponent} from '../shared/social-links/social-links.component';
import {ForgotCredentialsComponent, ForgotPasswordComponent, LoginComponent} from '../login';
import {FormFieldsModule} from '../shared/form-field/form-field.module';
import {DPPMCheckBoxModule} from '../shared/checkbox/checkbox.module';
import {MaterialModule} from '../MaterialModule';
import {SetupPasswordComponent} from './first-login/setup-password/setup-password.component';
import {RegisterComponent} from './first-login/register/register.component';
import {PasswordValidator} from './first-login/setup-password/password-validator.directive';
import {EqualValidator} from './first-login/setup-password/equal-validator.directive';
import {SharedMainModule} from '../shared-main';
import {RegisterService} from './first-login/register/register.service';
import {RouterModule} from '@angular/router';
import {NoContentModule} from '../no-content/no-content.module';
import {BootstrapModalModule} from 'ngx-modialog-7/plugins/bootstrap';
import {ModalModule} from 'ngx-modialog-7';
import {DialogService} from '../shared/dialog/dialog.service';
import {MDDialogsModule} from '../shared/dialog/dialogs.module';
//import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {AcceptTermsConditionsComponent} from './first-login/accept-terms-conditions/accept-terms-conditions.component';
import {AcceptTermsConditionsService} from './first-login/accept-terms-conditions/accept-terms-conditions.service';
import {CaslAcceptanceComponent} from './first-login/casl-acceptance/casl-acceptance.component';
import {TrustedPhoneNumberComponent} from './first-login/trusted-phone-number/trusted-phone-number.component';
import {TrustedPhoneNumberService} from './first-login/trusted-phone-number/trusted-phone-number.service';

/**
 * Login Module
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NoContentModule,
        ReactiveFormsModule,
        MaterialModule,
        FormFieldsModule,
        DPPMCheckBoxModule,
        SharedMainModule,
        RouterModule,
        ModalModule.forRoot(),
        BootstrapModalModule,
        MDDialogsModule
    ],
    declarations: [
        ForgotCredentialsComponent,
        ForgotPasswordComponent,
        SocialLinksComponent,
        LoginComponent,
        SetupPasswordComponent,
        RegisterComponent,
        ToBeDefinedComponent,
        PasswordValidator,
        EqualValidator,
        AcceptTermsConditionsComponent,
        CaslAcceptanceComponent,
        TrustedPhoneNumberComponent
    ],
    exports: [],
    providers: [
        RegisterService,
        DialogService,
        AcceptTermsConditionsService,
        TrustedPhoneNumberService
    ]
})
export class LoginModule {
}


