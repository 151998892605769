import {Injectable} from '@angular/core';
import {HttpClient} from '../../../core';
import {Logger} from '@nsalaun/ng-logger';
import {AccountNotificationConfig} from './account-notification-config';
import {Observable} from 'rxjs/Rx';
import {accountApi} from '../../accounts/shared/account-api';


@Injectable()
export class AssignmentNotificationConfigService {

    constructor(private http: HttpClient, private logger: Logger) {
    }


    updateAccountNotificationConfig(accountNotificationConfig: AccountNotificationConfig, accountId: string): Observable<AccountNotificationConfig> {
        let url = accountApi.accountNotificationConfig(accountId);
        return this.http.put(url, JSON.stringify(accountNotificationConfig))
            .map((res) => {
                let data = res['AccountNotificationConfig'];
                return new AccountNotificationConfig(data);
            });
    }

    getAccountNotificationConfig(accountId: string): Observable<AccountNotificationConfig> {
        let url = accountApi.accountNotificationConfig(accountId);
        return this.http.get(url)
            .map((res ) => {
                let data = res['AccountNotificationConfig'];
                return new AccountNotificationConfig(data);
            });
    }
}
