import {Component, OnInit} from '@angular/core';
import {StaffProfiles} from '../../../admin/staff-profiles/staff-profiles';
import {Router} from '@angular/router';
import {Logger} from '@nsalaun/ng-logger';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {StaffProfilesService} from '../../../admin/staff-profiles/staff-profiles.service';
import {ApplicationError} from '../../../core';
import {CaslInfo} from '../../../matters/shared/casl-info';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {commonLabels} from '../../../common/common-labels';
import {appRoutesUrlParts} from '../../../app.routes';
import {LockScreenService} from '../../../core/lock-screen.service';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {AuthorizationService} from '../../../shared-main/authorization/authorization-service';
import {ReferenceDataService} from '../../../shared-main/reference-data/reference-data.service';
import {UUIDService} from '../../../main/uuid.service';
import {UtilsService} from '../../../main/utils.service';

@Component({
               selector    : 'dp-casl-acceptance',
               templateUrl : './casl-acceptance.component.html',
               styleUrls      : [
                    '../../unity-login.styles.scss'
                ]
           })
@AutoUnsubscribe()
export class CaslAcceptanceComponent implements OnInit {

    currentUser: string;
    currentUserStaffProfile: StaffProfiles;
    staffProfilesOptions: StaffProfiles[] = [];
    getCurrentStaffProfileSub: Subscription;
    // getStaffProfileSub: Subscription;
    saveStaffProfileSub: Subscription;
    commonLabels = commonLabels;


    constructor(
                public staffProfilesService: StaffProfilesService,
                public router: Router,
                public authorizationService: AuthorizationService,
                public referenceDataService: ReferenceDataService,
                public uuidService : UUIDService,
                public lockScreenService : LockScreenService,
                public dialogService : DialogService,
                public utilsService: UtilsService,
                public logger: Logger) {
    }

    ngOnInit(): void {
        this.lockScreenService.lockForUpdate = false;

        this.currentUser = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);

        if (this.currentUser) {
            this.getCurrentStaffProfileSub = this.staffProfilesService.getLoggedInStaffProfile().subscribe(
                (staffProfiles: StaffProfiles) => {
                    if (staffProfiles) {
                        this.currentUserStaffProfile = staffProfiles;
                        this.staffProfilesOptions.push(staffProfiles);
                        if (!this.currentUserStaffProfile.caslInfo) {
                            this.currentUserStaffProfile.caslInfo = new CaslInfo();
                        }
                        if (!this.currentUserStaffProfile.caslInfo.caslLastModifiedDate) {
                            this.currentUserStaffProfile.caslInfo.caslLastModifiedDate = new Date();
                        }
                        if (!this.currentUserStaffProfile.caslInfo.caslLastModifiedBy) {
                            this.currentUserStaffProfile.caslInfo.caslLastModifiedBy = this.currentUserStaffProfile.surnameLastFullName;
                        }
                        if (!this.currentUserStaffProfile.caslInfo.caslLastModifiedById ) {
                            this.currentUserStaffProfile.caslInfo.caslLastModifiedById = +sessionStorage.getItem(SESSION_STORAGE_KEYS.userContactId);
                        }
                        if (!this.currentUserStaffProfile.caslInfo.caslRequestedByName) {
                            this.currentUserStaffProfile.caslInfo.caslRequestedByName = this.currentUserStaffProfile.surnameLastFullName;
                        }
                        if (!this.currentUserStaffProfile.caslInfo.caslRequestedById ) {
                            this.currentUserStaffProfile.caslInfo.caslRequestedById = +sessionStorage.getItem(SESSION_STORAGE_KEYS.userContactId);
                        }

                        if ( this.currentUserStaffProfile.caslInfo.caslRequestedById && this.currentUserStaffProfile.caslInfo.caslRequestedByName ) {
                          let existingStaffProfile: StaffProfiles = new StaffProfiles();
                          existingStaffProfile.id = this.currentUserStaffProfile.caslInfo.caslRequestedById;
                          existingStaffProfile.userName = this.currentUserStaffProfile.caslInfo.caslRequestedByName;
                          this.staffProfilesOptions.push(existingStaffProfile);
                        }
                    }
                });


        }
    }

    next(): void {

        let navigateNext: string = `./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.termsAndConditions}`;

        if (this.currentUser) {
            this.lockScreenService.lockForUpdate = true;
            this.saveStaffProfileSub = this.staffProfilesService.saveMyProfile(this.currentUserStaffProfile)
                .subscribe(
                    (staffProfiles: StaffProfiles) => {
                        this.authorizationService.setUpUserStateService(staffProfiles.user.defaultProvinceCode, staffProfiles.user.getEnabledUserProvinceCodes());
                        this.authorizationService.initializationAfterLogin(this.referenceDataService, this.uuidService);
                        this.router.navigate([navigateNext]);
                    },
                    (e: ApplicationError) => {
                        this.lockScreenService.lockForUpdate = false;
                        this.logger.info("casl-acceptance | next() | error = ", e);
                        //this.router.navigate([navigateNext]);
                        this.dialogService.confirm('Error', e.errorMessage, true);
                    }
                );
        }
        else {
            this.router.navigate([navigateNext]);
        }
    }

    get isCaslInfoAvailable(): boolean {
        return (this.currentUserStaffProfile.caslInfo.caslEmailOptOut !== undefined && this.currentUserStaffProfile.caslInfo.caslEmailOptOut !== null);
    }

     get caslRequestedBy() : string {
        if(this.staffProfilesOptions && this.currentUserStaffProfile && this.currentUserStaffProfile.caslInfo && this.currentUserStaffProfile.caslInfo.caslRequestedById){
           let staffProfile = this.staffProfilesOptions.find( sp => sp.id == this.currentUserStaffProfile.caslInfo.caslRequestedById);
           return  staffProfile ? staffProfile.surnameLastFullName : '';
        }else{
            return '';
        }
    }

    updateReqFields(): void {
        this.currentUserStaffProfile.caslInfo.caslRequestedByName = this.currentUserStaffProfile.surnameLastFullName;
        this.currentUserStaffProfile.caslInfo.caslRequestedById = +sessionStorage.getItem(SESSION_STORAGE_KEYS.userContactId);
        this.currentUserStaffProfile.caslInfo.caslLastModifiedBy = this.currentUserStaffProfile.surnameLastFullName;
        this.currentUserStaffProfile.caslInfo.caslLastModifiedById = +sessionStorage.getItem(SESSION_STORAGE_KEYS.userContactId);
        this.currentUserStaffProfile.caslInfo.caslLastModifiedDate = new Date();
    }

    ngOnDestroy() {}

}
