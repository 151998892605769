import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {StatementAdjustmentDisplayBuilder} from '../statement-adjustment/builders/statement-adjustment-display-builder';

export class StatementOfAdjustmentDisplay extends  BaseEntity{

    id: number;
    display: string;
    //This filed is used by Backend only, will not be displayed in the  UI.
    rawData: string;

    updateData(statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder): void {
        // clearing all identifiers as backend does not need them and comparing cached matters on UI might get false results as identifiers are changing
        BaseEntity.clearAllIdentifiers(statementAdjustmentDisplayUtil.statementAdjustmentDisplayItems);
        BaseEntity.clearAllIdentifiers(statementAdjustmentDisplayUtil.statementOfAdjustmentBalanceDisplayItems);

        this.display = statementAdjustmentDisplayUtil.toJSON();
        this.rawData = JSON.stringify(statementAdjustmentDisplayUtil.prepareAdjustmentsRawData());
    }
}
