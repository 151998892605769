import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {Observable, Subject, Subscription} from '../../../../../node_modules/rxjs';
import {HttpClient} from '../../../core';
import {MailParseService} from '../mail-parse.service';
import {FocusFirstElementDecorator} from "../../../shared-main/focus-first-element-decorator";

export class FileUploadModalContext extends BSModalContext {
    filesSelectedForUpload: FileList;
    uploadUrl: string;
    existingFiles: ExistingFile[];
    acceptedFileExtensions: string;
    maxFileSize: number;
    uploadOptions: any;
    staggerXmlUpload?: boolean; //Load all XML files first, before proceeding with the other files
    applicableProvince: string;
    uploadExportTemplate: boolean;
}

export enum UploadStatus {
    IN_PROGRESS,
    COMPLETE,
    CANCELLED,
    ERROR
}

export interface ExistingFile {
    name: string;
    lastUpdatedTimeStamp : number;
    isProtected : boolean;
    isOpen : boolean;
    generatedDocType? : string // added to support upload of 3rd party files (TPR for now)
}

@FocusFirstElementDecorator()
@Component({
               selector    : 'dp-file-upload-modal-content',
               templateUrl : './file-upload-modal.component.html',
               styleUrls      : [
                   './file-upload-modal.component.scss'
               ],
               providers: [ErrorService]
           })
export class FileUploadModal implements   ModalComponent<FileUploadModalContext>, OnInit, OnDestroy {
    @ViewChild('modalErrorComponent') modalErrorComponent : ModalErrorComponent;

    public context : FileUploadModalContext;

    /** Files that, if uploaded, will replace existing files. The user will select which ones of these files will actually be uploaded */
    replacingFiles : File[];
    allReplacingSelected: boolean = false;

    /** List of issues with the files selected for upload. The files indicated by these issues will not be uploaded. */
    preUploadValidationIssues : any[]; //[{fileName: 'file.doc', description: 'Too big'}]

    /** The files (among the selected set) that can be uploaded and don't overwrite existing ones */
    newFiles : File[];

    /** The files that end up being uploaded (the ones with no conflict and the selected replacing ones) */
    uploadedFiles : any[]; // {fileName: 'file.doc', status: UploadStatus}

    //User selection from the files that would replace existing ones
    selectedReplacingFiles: string[] = [];

    readonly uploadRequestsInProgress : Map<string, Subscription>;

    readonly extensions : string[];

    replacedFileDates: Map<string, any>;


    constructor(public dialog : DialogRef<FileUploadModalContext>,
                public mailParseService : MailParseService,
                public httpClient: HttpClient) {
        this.context = dialog.context;
        this.uploadRequestsInProgress = new Map<string, Subscription>();
        this.extensions = this.context.acceptedFileExtensions.split(',').map((extension: string) => extension.trim());


    }

    ngOnInit() : void {

        (async () => {
            await this.buildFilesLists(this.context.filesSelectedForUpload);
            if(!this.isUserUploadInitiationRequired() && this.newFiles && this.newFiles.length > 0){
                this.performUpload();
            } else {
                this.replacedFileDates = new Map<string, any>();
                this.replacingFiles.forEach(file => {
                    this.replacedFileDates.set(file.name, this.findReplacedDocument(file).lastUpdatedTimeStamp);
                });
            }
        })();

    }

    ngOnDestroy() : void {
    }

    toggleReplacementFileInclusion(fileName: string){
        if(this.isSelected(fileName)){
            (<any>this.selectedReplacingFiles).remove(fileName);
        } else {
            this.selectedReplacingFiles.push(fileName);
        }
        if(this.selectedReplacingFiles.length == 0){
            this.allReplacingSelected = false;
        }
        if(this.selectedReplacingFiles.length == this.replacingFiles.length){
            this.allReplacingSelected = true;
        }
    }

    public isSelected(fileName : string) {
        return this.selectedReplacingFiles.indexOf(fileName) >= 0;
    }

    beforeClose() : boolean | Promise<boolean> {
        return undefined;
    }

    beforeDismiss() : boolean | Promise<boolean> {
        return undefined;
    }

    cancel() : void {
        this.dialog.close(this.uploadedFiles);
    }

    cancelUploadInProgress() : void {
        this.uploadRequestsInProgress.forEach((inProgressRequest: Subscription, key: string) => {
            inProgressRequest.unsubscribe();
            this.recordUploadResult(key, UploadStatus.CANCELLED);
        });
        this.uploadRequestsInProgress.clear();
    }

    close() : void {
        this.dialog.close(this.uploadedFiles);
    }

    ok() : void {
        this.dialog.close(this.uploadedFiles);
    }

    /**
     * We can proceed with upload if there's no upload in progress AND
     * (we have new files available for upload OR we have at least one replacement file selected)
     */
    isProceedWithUploadActive() : boolean {
       return !this.isUploadInProgress() && (this.newFiles && this.newFiles.length > 0 || this.selectedReplacingFiles && this.selectedReplacingFiles.length > 0);
    }

    isUploadInProgress(): boolean {
        return this.uploadRequestsInProgress && this.uploadRequestsInProgress.size > 0;
    }

    onlyValidationErrorsToDisplay() : boolean {
        return this.preUploadValidationIssues && this.preUploadValidationIssues.length > 0 &&
            this.replacingFiles && this.replacingFiles.length == 0 &&
            this.newFiles && this.newFiles.length == 0;
    }

    /**
     * User initiation is required for the upload operation if there are any errors to be displayed or if some of the selected files replace existing ones
     * @returns {boolean}
     */
    isUserUploadInitiationRequired(): boolean {
        return this.replacingFiles && this.replacingFiles.length > 0 ||
            this.preUploadValidationIssues && this.preUploadValidationIssues.length > 0;
    }

    getFileUploadOptions() : Map<string, any> {
        if(this.context.uploadOptions) {
            let options = new Map<string, any>();
            options.set("isInclude", this.context.uploadOptions.includeFilesUpload);
            if(this.context.uploadOptions.description){
                options.set("description", this.context.uploadOptions.description);
            }
            if(this.context.uploadOptions.subpath){
                options.set("subpath", this.context.uploadOptions.subpath);
            }
            options.set("documentCategoriesIds", []);
            options.set("matterTypes", []);
            return options;
        } else {
            return null;
        }
    }

    getExportTemplateFileUploadOptions(): Map<string, any> {
            let options = new Map<string, any>();
            options.set("description", '');
            options.set("isPublic", false);
            options.set("applicableProvince", this.context.applicableProvince);
            return options;

    }



    performUpload() : void {
        //TODO: this might be enhanced to have per file options (description and/or categories). For now we only
        //set the include type of the upload
        let options: any;
        if(this.context.uploadExportTemplate){
            options = this.getExportTemplateFileUploadOptions();
        } else {
            options = this.getFileUploadOptions();
        }

        this.uploadedFiles = [];
        let uploadQueue: File[] = [];
        if(this.replacingFiles){
            uploadQueue.push(...this.replacingFiles.filter(file => this.isSelected(file.name)));
        }
        if(this.newFiles){
            uploadQueue.push(...this.newFiles);
        }
        if(this.context.staggerXmlUpload){
            let xmlFiles: File[] = uploadQueue.filter(file => this.getFileExtension(file) == 'xml');
            xmlFiles.forEach(file => this.uploadFile(file, options));

            let nonXmlFiles: File[] = uploadQueue.filter(file => this.getFileExtension(file) != 'xml');
            nonXmlFiles.forEach(file => this.uploadFile(file, options, true));

        } else {
            uploadQueue.forEach(file => this.uploadFile(file, options));
        }
    }


    public getFileExtension(file) {
        return file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    }

    toggleAllReplacingFiles() : void {
        this.allReplacingSelected = !this.allReplacingSelected;
        if(this.allReplacingSelected) {
            this.selectedReplacingFiles = this.replacingFiles.map(file => file.name);
        } else {
            this.selectedReplacingFiles = [];
        }
    }

    recordUploadResult(fileName: string, status: UploadStatus, message?: string, documentId?: number){
        if(!this.uploadedFiles){
            this.uploadedFiles = [];
        }
        let statusRecord = this.uploadedFiles.find(status => status.fileName == fileName);
        if(statusRecord){
            statusRecord.status = status;
            statusRecord.message = message;
            if(documentId){
                statusRecord.documentId = documentId;
            }
        } else {
            this.uploadedFiles.push({fileName: fileName, status: status, message: message, documentId: documentId});
        }
    }

    get uploadPercentage() : number {
        if(!this.uploadedFiles){
            return -1;
        }
        let completedOrFailedCount = this.uploadedFiles.filter(item => item.status != UploadStatus.IN_PROGRESS).length;
        return Math.round(100 * completedOrFailedCount / this.uploadedFiles.length);
    }

    get uploadInProgress(): boolean {
        return !!(this.uploadedFiles && this.uploadedFiles.find(item => item.status == UploadStatus.IN_PROGRESS));
    }

    get numberOfUploadErrors(): number {
        return this.uploadedFiles.filter(item => this.isFailed(item.status)).length;
    }

    get numberOfSuccessfullyUploadedFiles(): number {
        return this.uploadedFiles.filter(item => this.isComplete(item.status)).length;
    }


    isComplete(status: UploadStatus): boolean {
        return status == UploadStatus.COMPLETE;
    }

    isFailed(status : UploadStatus) : boolean {
        return status == UploadStatus.CANCELLED || status == UploadStatus.ERROR;
    }


    getFailureInfo(status: UploadStatus, message?: string): string {
        if(status == UploadStatus.ERROR) {
            return message ? message: 'Failed to upload';
        } else {
            return 'Cancelled by user';
        }
    }

    public uploadFile(file: File, options: Map<string, any>, waitForXml?: boolean) {
        let documentId: number;
        //Old school retry if we need to wait until the corresponding XML file is uploaded
        if(waitForXml && this.uploadedFiles && this.uploadedFiles.length > 0){
            let correspondingXmlName = file.name.substring(0, file.name.lastIndexOf('.')).toLowerCase() + '.xml';
            let xmlStatus = this.uploadedFiles.find(uploadStatus => uploadStatus.fileName.toLowerCase() == correspondingXmlName);
            if(xmlStatus && xmlStatus.status == UploadStatus.IN_PROGRESS){
                //Found corresponding XML in progress, try again in a bit
                setTimeout(() => {
                    this.uploadFile(file, options, waitForXml);
                }, 500);
                return;
            }
        }

        //We're clear here, initiate the upload

        this.recordUploadResult(file.name, UploadStatus.IN_PROGRESS);
        documentId = null;
        let uploadInProgress = this.submitFileUpload(this.context.uploadUrl, file, options)
            .finally( () => {
                this.uploadRequestsInProgress.delete(file.name);
            }).subscribe(
                event => {
                    documentId = event && event.SpinDocument && event.SpinDocument.id;
                    console.log(event);
                            },
                (err) => {
                    console.log("Upload Error:", err);
                    let errMessage = err.summary ? err.summary : '';
                    switch (err.errorCode) {
                        case 'app.PdfDocumentContainsCreditCardNumber':
                            errMessage = 'The pdf document not uploaded as it contains credit card information';
                            break;
                        case 'app.WordDocumentContainsCreditCardNumber':
                            errMessage = 'The MS-Word document not uploaded as it contains credit card information';
                            break;
                        case 'app.WordPerfectDocumentContainsCreditCardNumber':
                            errMessage = 'The WordPerfect document not uploaded as it contains credit card information';
                            break;
                        case 'app.DocumentContainsCreditCardNumber':
                            errMessage = 'The document not uploaded as it contains credit card information';
                            break;
                        case 'app.EncryptedDocumentCannotBeCheckedForCCNumbers':
                            errMessage = 'Upload rejected, the system is unable to inspect the file for the presence of a credit card'
                            break;
                        case 'app.cannotReplaceLockedDocument':
                            errMessage = 'Upload failed, locked document cannot be replaced';
                            break;
                        case 'app.defaultTemplateCannotBeReplaced':
                            errMessage = 'You cannot replace this template. Please upload with a different template name.';
                            break;
                        case 'app.invalidContentSpinDocument':
                            errMessage = err.message + ', so upload failed';
                            break;
                        case 'app.cannotReplaceSpinDocument':
                            errMessage = err.message + ', so upload failed';
                            break;
                        case 'app.cannotBeEmptySpinDocument':
                            errMessage = err.message + ', so upload failed';
                            break;
                        case 'app.cannotExceedMaxLimitSpinDocument':
                            errMessage = err.message + ', so upload failed';
                            break;
                        case 'app.invalidExtensionSpinDocument':
                            errMessage = err.message + ', so upload failed';
                            break;
                        case 'app.invalidExtensionTprDocument':
                            errMessage = err.message + ', so upload failed';
                            break;
                        case 'app.masterCondoTprDocument':
                            errMessage = err.message;
                            break;
                        case 'app.invalidTprDocument':
                            errMessage = err.message + ', so upload failed';
                            break;
                        default:
                            break;
                    }

                    this.recordUploadResult(file.name, UploadStatus.ERROR, errMessage);
                }, () => {
                    this.recordUploadResult(file.name, UploadStatus.COMPLETE, null, documentId);
                }
            );

        this.uploadRequestsInProgress.set(file.name, uploadInProgress);

    }

    public async buildFilesLists(files : FileList) {
        this.preUploadValidationIssues = [];
        this.replacingFiles = [];
        this.newFiles = [];
        this.uploadedFiles = null;

        for (let i = 0; i < files.length; i++) {
            let file : File = files[i];
            let uploadIssue : string = await this.determineUploadIssue(file);
            if(uploadIssue){
                this.preUploadValidationIssues.push({fileName: file.name, description: uploadIssue});
            } else if (this.findReplacedDocument(file)){
                //We've already checked for locking issues, so we're adding this to the list of replaced files if found
                this.replacingFiles.push(file);
            } else {
                this.newFiles.push(file);
            }

        }
    }

    public async determineUploadIssue(file : File) : Promise<string> {

        let fileType = '*' + file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        if(!this.extensions.find((ext: string) => ext == fileType)){
            if (fileType == '*.wp' ) {
                return this.getOldWordPerfectFileMsg(file.name);
            }
            return `File type not supported for upload. Supported files are ${this.context.acceptedFileExtensions}.`;
        }

        let replacedDoc = this.findReplacedDocument(file);
        if(replacedDoc) {
            if(replacedDoc.isOpen) {
                return 'File ' + file.name + ' is open. Please close the document before initiating the upload.';
            }
            if(replacedDoc.isProtected) {
                return 'File is protected and cannot be replaced.';
            }
        }


        if(file.size > 1024 * 1024 * this.context.maxFileSize) { //TODO: proper value here
            return `File exceeding the ${this.context.maxFileSize} MB limit.`;
        }

        if (fileType == '*.wpd' ) {
            let isOldWordPerfectFileVersion = await this.isOldWordPerfectFileVersion(file).toPromise();
            if (isOldWordPerfectFileVersion) {
                return this.getOldWordPerfectFileMsg(file.name);
            }
        }

    }

    private getOldWordPerfectFileMsg(fileName: string): string {
        return `Template ${fileName} is an older WordPerfect file format. Please convert the template to a current version.`;
    }

    private isOldWordPerfectFileVersion(file: File): Observable<boolean> {

        const WP5_MAJOR_VERSION = 0; // for WordPerfect 5.0, 5.1 and 5.2
        const WP6_MAJOR_VERSION = 2; // for WordPerfect 6 and higher

        let returnSubject = new Subject<boolean>();
        let fileReader = new FileReader();
        fileReader.onloadend = (evt) => {

            let arr = new Uint8Array(fileReader.result as ArrayBuffer);

            let isOldWordPerfectFile = (arr[10] === WP5_MAJOR_VERSION);

            returnSubject.next(isOldWordPerfectFile);
            returnSubject.complete();
        };

        fileReader.readAsArrayBuffer(file.slice(0, 12));

        return returnSubject;
    }

    public findReplacedDocument(file : File) : ExistingFile {
        return this.context.existingFiles.find(existingFile => existingFile && existingFile.name && existingFile.name.toLowerCase() == file.name.toLowerCase());
    }


    submitFileUpload(url: string, file: File, options: Map<string, any>): Observable<any> {

        let formData = new FormData();
        formData.append('uploadedFile', file, file.name);
        if(options) {
            options.forEach((value: any, key: string) => {
                formData.append(key, value);
            });
        }

        // special handling for TPR docs
        let existingFile = this.context.existingFiles.find(existingFile => existingFile.name === file.name);
        if (existingFile && existingFile.generatedDocType === 'TPR')  {
            formData.append('thirdPartyDocType', existingFile.generatedDocType);
        }

        //Get description from email file
        if(this.isEmailFile(file)){
            let uploadSubject = new Subject<any>();
            let  getEmailSubjectSubscription = Observable.fromPromise(this.mailParseService.getEmailSubject(file));
            getEmailSubjectSubscription
                .subscribe((result)=>{
                    let description = result;
                    if(description){
                        formData.append('description', description);
                    }
                    this.httpClient.uploadFiles(url, formData).subscribe((res)=>{
                        uploadSubject.next(res);
                        uploadSubject.complete();
                    });
            });
            return uploadSubject;

        } else {
            return this.httpClient.uploadFiles(url, formData);
        }

    }

    isEmailFile(file : File) : boolean {
        let fileType = '*' + file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        return fileType == '*.msg' || fileType == '*.eml';
    }
}

