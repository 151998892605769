import {MatterProperty} from '../../shared/matter-property';
import * as _ from 'lodash';
import {CondominiumExpense} from './condominium-expense';
import {dropDowns} from '../../shared/matter-drop-downs';
import {CondominiumPlan} from './condominium-plan';
import {condoPlanTypeDropDownOptions} from '../../../shared-main/province-based-dropdowns';
import Utils from '../../../shared-main/utils';
import {constValues} from '../../shared/const-values';
import {Matter} from '../../shared';
import {ErrorDirectiveMapping} from '../../../shared/error-handling/error-directive-mapping';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {Project} from '../../../projects/shared/project';
import {CustomUnitType} from '../../../projects/project-condo/project-condo';
import {SelectItem} from 'primeng/api';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {PROVINCE_CODES} from '../../shared/user-province';


export class UnitLevelPlanUtil {

    static  generateUnitLevelPlanForEReg(unitLevelPlan : MatterProperty[]) : string {
        let unitLevelPlanStrEReg : string = '';
        if(unitLevelPlan && unitLevelPlan.length){
            if(unitLevelPlan[0].condominiumExpenses && unitLevelPlan[0].condominiumExpenses.length){
                unitLevelPlanStrEReg += unitLevelPlan[0].condominiumExpenses[0].unitNumber? 'U' + unitLevelPlan[0].condominiumExpenses[0].unitNumber : '';
                unitLevelPlanStrEReg += unitLevelPlan[0].condominiumExpenses[0].levelNumber? 'L' + unitLevelPlan[0].condominiumExpenses[0].levelNumber : '';
            }
        }
        return unitLevelPlanStrEReg;
    }

    static getUnitLevelStrAB(condominiumExpenses : CondominiumExpense[], addUndividedSharesCountInDescription : boolean = false) : string {
        let unitArray : string[] = [];
        if(Array.isArray(condominiumExpenses)) {
            condominiumExpenses.forEach(condominiumExpense => {
                let unitLevelStr : string = "";
                if(condominiumExpense.unitNumber || condominiumExpense.levelNumber) {
                    if(condominiumExpense.unitNumber && !condominiumExpense.levelNumber) {
                        if (addUndividedSharesCountInDescription && parseFloat(condominiumExpense.noUndividedShare)>0)
                        {
                            unitLevelStr = 'Unit ' + condominiumExpense.unitNumber + ' and '+ Utils.formatSharesValue(parseFloat(condominiumExpense.noUndividedShare)) + ' Undivided One Ten Thousandth shares in the common property';
                        }else{
                            unitLevelStr = 'Unit ' + condominiumExpense.unitNumber;
                        }
                    }
                    else if(!condominiumExpense.unitNumber && condominiumExpense.levelNumber) {
                        unitLevelStr = 'Level ' + condominiumExpense.levelNumber;
                    }
                    else {
                        unitLevelStr = 'Unit ' + condominiumExpense.unitNumber + ', Level ' + condominiumExpense.levelNumber;
                    }
                    unitArray.push(unitLevelStr);
                }
            });
        }
        if (addUndividedSharesCountInDescription){
            return unitArray.join(', ');
        }else{
            return unitArray.length < 2 ? unitArray.join(', ') : unitArray.slice(0,-1).join(', ') +' and ' + unitArray.slice(-1);
        }
    }

    static  generateUnitLevelPlanForCondominiumExpense(condominiumExpense : CondominiumExpense, matterProperty: MatterProperty, provinceCode: string) : string {
        console.log(matterProperty);
        console.log(condominiumExpense);
        let unitLevelPlanStr : string[] = [];
        if(condominiumExpense){
            if(condominiumExpense.unitNumber){
                unitLevelPlanStr.push('Unit ' + condominiumExpense.unitNumber);
            }
            if(condominiumExpense.levelNumber){
                unitLevelPlanStr.push('Level ' + condominiumExpense.levelNumber);
            }
            if(matterProperty.condominiumPlans && matterProperty.condominiumPlans.length == 1){
                let condoPlanTypeOptions = condoPlanTypeDropDownOptions[provinceCode];
                let condominiumUnitType = condoPlanTypeOptions.find((type)=>{
                    return type.value == matterProperty.condominiumPlans[0].condominiumPlanType;
                });
                let str = '';
                str += matterProperty.condominiumJurisdiction ? matterProperty.condominiumJurisdiction + ' ' : '';
                str += condominiumUnitType.label;
                str += matterProperty.condominiumPlans[0].condominiumPlanNumber ? ' No. ' + matterProperty.condominiumPlans[0].condominiumPlanNumber : '';
                unitLevelPlanStr.push(str);
            } else {
                if(condominiumExpense.planNumber){
                    unitLevelPlanStr.push(condominiumExpense.planNumber);
                }
            }

        }
        return unitLevelPlanStr.join(', ');
    }

    /*
      startsWithUnit = true:  is the preferred style for commute with STG TI for AB province
     */
    static  generateUnitLevelPlan(unitLevelPlan : MatterProperty , provinceCode : string, startsWithUnit: boolean = false, addUndividedSharesCountInDescription : boolean = false) : string {

        let unitLevelPlanStr : string;
        let planSeparatorAB = ' and ';
        if (addUndividedSharesCountInDescription){
            planSeparatorAB = ', ';
        }

        if(unitLevelPlan.condominiumPlans.length > 1) {
            // plan is mandatory
            let planNumberList = _.uniqBy(_.filter(unitLevelPlan.condominiumExpenses, unitLevelPlan => unitLevelPlan.planNumber != undefined), 'planNumber');
            for(let i = 0; i < planNumberList.length; i++) {
                if(planNumberList[i].planNumber) {

                    let unitLevelPlanList = _.filter(unitLevelPlan.condominiumExpenses, unitLevelPlan => unitLevelPlan.planNumber == planNumberList[i].planNumber);
                    if(unitLevelPlanList.length > 0) {
                        let unitLevelStr : string = "";

                        if(provinceCode  == 'AB')
                        {
                            unitLevelStr = UnitLevelPlanUtil.getUnitLevelStrAB(unitLevelPlanList,addUndividedSharesCountInDescription);

                            let planNumber  = '';
                            if(unitLevelPlanList[0].condominiumPlan)
                            {
                                let condoPlanTypeOptions = condoPlanTypeDropDownOptions[provinceCode];
                                let condominiumUnitType = condoPlanTypeOptions.find((type)=>{
                                    return type.value == unitLevelPlanList[0].condominiumPlan.condominiumPlanType;
                                });
                                planNumber = (condominiumUnitType ? this.generateCondominiumPlanDescriptionForAB(condominiumUnitType.value) : '')+' '+(unitLevelPlanList[0].condominiumPlan.condominiumPlanNumber
                                        ? unitLevelPlanList[0].condominiumPlan.condominiumPlanNumber : '??????????')
                            }

                            if(unitLevelPlanStr && unitLevelStr != "" && i == planNumberList.length - 1) {
                                unitLevelPlanStr += startsWithUnit ?  (planSeparatorAB + unitLevelStr + ', ' + planNumber) :  (' and ' + planNumber + ' ' + unitLevelStr);
                            }
                            else if(!unitLevelPlanStr && unitLevelStr != "") {
                                unitLevelPlanStr = startsWithUnit ?  (unitLevelStr + ', ' + planNumber) : (planNumber + ' ' + unitLevelStr);
                            }
                            else if(unitLevelPlanStr && unitLevelStr != "") {
                                unitLevelPlanStr += startsWithUnit ? (', ' + unitLevelStr + ', ' + planNumber) : (', ' + planNumber + ' ' + unitLevelStr);
                            }
                        }
                        else{
                            for(let i = 0; i < unitLevelPlanList.length; i++) {
                                if(unitLevelPlanList[i].unitNumber || unitLevelPlanList[i].levelNumber) {
                                    unitLevelStr = unitLevelStr + (unitLevelPlanList[i].unitNumber ? 'Unit ' + unitLevelPlanList[i].unitNumber + ', ' : '');
                                    unitLevelStr = unitLevelStr + (unitLevelPlanList[i].levelNumber ? 'Level ' + unitLevelPlanList[i].levelNumber + ', ' : '');
                                }
                            }

                            if(unitLevelPlanStr && unitLevelStr != "" && i == planNumberList.length - 1) {
                                unitLevelPlanStr = unitLevelPlanStr + ' and ' + unitLevelStr + unitLevelPlanList[0].planNumber;
                            }
                            else if(!unitLevelPlanStr && unitLevelStr != "") {
                                unitLevelPlanStr = unitLevelStr + unitLevelPlanList[0].planNumber;
                            }
                            else if(unitLevelPlanStr && unitLevelStr != "") {
                                unitLevelPlanStr = unitLevelPlanStr + ', ' + unitLevelStr + unitLevelPlanList[0].planNumber;
                            }
                        }




                    }
                }
            }
        }
        else if(unitLevelPlan.condominiumPlans.length > 0) {
            if(provinceCode  == 'AB') {
                unitLevelPlanStr = UnitLevelPlanUtil.getUnitLevelStrAB(unitLevelPlan.condominiumExpenses,addUndividedSharesCountInDescription);
            } else {
                for(let i = 0; i < unitLevelPlan.condominiumExpenses.length; i++) {

                    if(unitLevelPlan.condominiumExpenses[i].unitNumber || unitLevelPlan.condominiumExpenses[i].levelNumber) {

                        let unitLevelStr : string = "";

                        if(unitLevelPlan.condominiumExpenses[i].unitNumber && !unitLevelPlan.condominiumExpenses[i].levelNumber) {
                            unitLevelStr = 'Unit ' + unitLevelPlan.condominiumExpenses[i].unitNumber;
                        }
                        else if(!unitLevelPlan.condominiumExpenses[i].unitNumber && unitLevelPlan.condominiumExpenses[i].levelNumber) {
                            unitLevelStr = 'Level ' + unitLevelPlan.condominiumExpenses[i].levelNumber;
                        }
                        else {
                            unitLevelStr = 'Unit ' + unitLevelPlan.condominiumExpenses[i].unitNumber + ', Level ' + unitLevelPlan.condominiumExpenses[i].levelNumber;
                        }

                        if(unitLevelPlanStr && i == unitLevelPlan.condominiumExpenses.length - 1) {
                            unitLevelPlanStr = unitLevelPlanStr + ' and ' + unitLevelStr;
                        }
                        else if(!unitLevelPlanStr) {
                            unitLevelPlanStr = unitLevelStr;
                        }
                        else if(unitLevelPlanStr) {
                            unitLevelPlanStr = unitLevelPlanStr + ', ' + unitLevelStr;
                        }
                    }
                }
            }
            //unitLevelPlanStr = unitLevelPlanStr ? unitLevelPlanStr + (unitLevelPlan.condominiumPlans[0].condominiumPlanNumber ? ', ' + unitLevelPlan.condominiumPlans[0].condominiumPlanNumber : '') : '';
            let condoPlanTypeOptions = condoPlanTypeDropDownOptions[provinceCode];
            let condominiumUnitType = condoPlanTypeOptions.find((type)=>{
                return type.value == unitLevelPlan.condominiumPlans[0].condominiumPlanType;
            });
            if(provinceCode  == 'AB') {
                if(unitLevelPlanStr){
                    const planPartValue: string =  this.generateCondoPlanDescWithNumberForAB(condominiumUnitType.label, unitLevelPlan.condominiumPlans[0].condominiumPlanNumber);
                    if(startsWithUnit){
                        unitLevelPlanStr = unitLevelPlanStr + ', ' + planPartValue;
                    }else{
                        unitLevelPlanStr = planPartValue + ' ' + unitLevelPlanStr;
                    }
                }else{
                    unitLevelPlanStr = '';
                }
/*                unitLevelPlanStr = unitLevelPlanStr ? condominiumUnitType.label
                                                      + (unitLevelPlan.condominiumPlans[0].condominiumPlanNumber ? ' No. ' + unitLevelPlan.condominiumPlans[0].condominiumPlanNumber : '')
                                                      + ' ' + unitLevelPlanStr
                                                    : '';*/

            } else if(provinceCode  == 'NB' || provinceCode  == 'NS'){
                unitLevelPlanStr = [unitLevelPlanStr, unitLevelPlan.condominiumCorporationName].filter(Boolean).join(", ");
            } else {
                unitLevelPlanStr = unitLevelPlanStr ? unitLevelPlanStr + ', ' +
                                                    (unitLevelPlan.condominiumJurisdiction? unitLevelPlan.condominiumJurisdiction : '')
                                                    + ' ' + condominiumUnitType.label +
                                                    (unitLevelPlan.condominiumPlans[0].condominiumPlanNumber ? ' No. ' + unitLevelPlan.condominiumPlans[0].condominiumPlanNumber : '') : '';

            }


        }

        return unitLevelPlanStr;
    }


    static generatePinNumber(unitLevelPlan : MatterProperty) : string {

        let pinNumberStr : string;

        for(let i = 0; i < unitLevelPlan.condominiumExpenses.length; i++) {

            if(unitLevelPlan.condominiumExpenses[i].pinNumber) {
                pinNumberStr = pinNumberStr ? pinNumberStr + ', ' + unitLevelPlan.condominiumExpenses[i].pinNumber.replace("-", "") : unitLevelPlan.condominiumExpenses[i].pinNumber.replace("-", "");
            }
        }
        return pinNumberStr ? pinNumberStr : '';
    }

    static generateAssessmentAccountNumberSummary(unitLevelPlan : MatterProperty) : string {

        let assessmentAccountNumberSummary : string;
        unitLevelPlan.condominiumExpenses.filter(item => item.assessmentAccountNumber).forEach( inp => {
            assessmentAccountNumberSummary = assessmentAccountNumberSummary ? assessmentAccountNumberSummary + ', ' +
                                                                            inp.assessmentAccountNumber : inp.assessmentAccountNumber;
        });
        return assessmentAccountNumberSummary ? assessmentAccountNumberSummary : '';
    }

    static generateLincNumber(unitLevelPlan : MatterProperty) : string {
       return unitLevelPlan.condominiumExpenses ? unitLevelPlan.condominiumExpenses.filter(ce => !Utils.isEmptyString(ce.lincNumber)).map( item =>{
         return item.lincNumber
        }).join(', ') : '';
    }

    static generateRollNumber(unitLevelPlan : MatterProperty) : string {
        return unitLevelPlan.condominiumExpenses ? unitLevelPlan.condominiumExpenses.filter(ce => !Utils.isEmptyString(ce.rollNumber)).map( item =>{
            return item.rollNumber
        }).join(', ') : '';
    }


    static generateUnitLevelPlanFromExp(condominiumExpenses : CondominiumExpense , provinceCode : string, matter : Matter, matterProperty : MatterProperty) : string {
        let str : string[] = [];

        if(condominiumExpenses.unitNumber)
        {
            str.push('Unit '+condominiumExpenses.unitNumber);
        }
        if(condominiumExpenses.levelNumber)
        {
            str.push('Level '+condominiumExpenses.levelNumber);
        }

        let jurisdictionPlanType : string[] = [];

        if(matter.matterPropertyWithCondo.condominiumJurisdiction){
            jurisdictionPlanType.push(''+ matter.matterPropertyWithCondo.condominiumJurisdiction)
        }

        if(condominiumExpenses.condominiumPlan && condominiumExpenses.condominiumPlan.condominiumPlanNumber && condominiumExpenses.condominiumPlan.condominiumPlanNumber.indexOf('????') < 0)
        {
            let condoPlanType : any = _.find(condoPlanTypeDropDownOptions[provinceCode], condoPlanTypeObj => condoPlanTypeObj.value === condominiumExpenses.condominiumPlan.condominiumPlanType);
            jurisdictionPlanType.push(condoPlanType ? condoPlanType.label+'' : '');
            jurisdictionPlanType.push('No. '+condominiumExpenses.condominiumPlan.condominiumPlanNumber);
        }
        else
        {
            let condominiumPlan : CondominiumPlan = this.getCondominiumPlanByPlanNumber(matter , condominiumExpenses.planNumber, matterProperty );
            if(condominiumPlan) {
                let condoPlanType: any = _.find(condoPlanTypeDropDownOptions[provinceCode], condoPlanTypeObj => condoPlanTypeObj.value === condominiumPlan.condominiumPlanType);
                jurisdictionPlanType.push(condoPlanType ? condoPlanType.label + '' : '');
                condominiumPlan.condominiumPlanNumber ? jurisdictionPlanType.push('No. ' + condominiumPlan.condominiumPlanNumber) : '';
            }

        }
        str.push(jurisdictionPlanType.join(' '));
        return str.join(', ');
    }

    static isOverrideDescIndVisible(matter: Matter) : boolean {
        return matter.registrationMethodCode == constValues.registrationMethodsType.electronic;
    }

    static getPlanNumber(condominiumPlan : CondominiumPlan, matter: Matter): string {
        return (matter.isMatterProvinceAB ? '' : this.getJurisdictionInitials(matter)) + this.getCondoByValue(condominiumPlan.condominiumPlanType, matter) + ' No. ' + (condominiumPlan.condominiumPlanNumber
            ? condominiumPlan.condominiumPlanNumber : '??????????')
    }

    //needed because condominiumJurisdiction in matterProperty from Unit/Level/Plan modal can be different than matter.matterPropertyWithCondo.condominiumJurisdiction
    static getPlanNumberUsingMatterProperty(condominiumPlan: CondominiumPlan, matter: Matter, matterProperty: MatterProperty): string {
        return (matter.isMatterProvinceAB ? '' : this.getJurisdictionInitialsByMatterProperty(matterProperty)) + this.getCondoByValue(condominiumPlan.condominiumPlanType, matter) + ' No. ' + (condominiumPlan.condominiumPlanNumber
            ? condominiumPlan.condominiumPlanNumber : '??????????');
    }

    static getCondominiumPlanByPlanNumber(matter : Matter ,planNumber:string, matterProperty : MatterProperty): CondominiumPlan {
        if(planNumber && planNumber.includes('No.')){
            let jurisdictionString = this.getJurisdictionInitials(matter);
            let condominiumPlanNumberArr :string[] = planNumber.split('No.');
            let condoPlanType = condominiumPlanNumberArr[0].replace(jurisdictionString, '').trim();
            return  matterProperty.condominiumPlans.find(item => item.condominiumPlanType === this.getCondoByType(condoPlanType, matter).trim());
        }
        return matterProperty.condominiumPlans[0];
    }

    static getCondoByType(type : string, matter : Matter) : string {
        let condoPlanType : any = _.find(condoPlanTypeDropDownOptions[matter.provinceCode], condoPlanTypeObj => condoPlanTypeObj.type === type);
        return condoPlanType ? condoPlanType.value + ' ' : '';
    }

    static getJurisdictionInitials(matter: Matter) : string {
        return matter.matterPropertyWithCondo.condominiumJurisdiction ? this.getJurisdictionInitialsByMatterProperty(matter.matterPropertyWithCondo) : '';
    }

    static getJurisdictionInitialsByMatterProperty(matterProperty: MatterProperty) : string {
        return matterProperty.condominiumJurisdiction ? matterProperty.condominiumJurisdiction.match(/\b(\w)/g).join('').toUpperCase() : '';
    }

    static getCondoByValue(type : string, matter: Matter) : string {

        let condoPlanType : any = _.find(condoPlanTypeDropDownOptions[matter.provinceCode], condoPlanTypeObj => condoPlanTypeObj.value === type);
        return condoPlanType ? condoPlanType.type + ' ' : '';
    }

    static addCustomPinWarning(elementValue, errorElementKey, index, modalErrorComponent: ModalErrorComponent) : void {
        let eventPattern = new RegExp("^[0-9]{5}-[0-9]{4}$");
        let modelPattern = new RegExp("^[0-9]{9}$");
        let errorDirectiveMapping = ErrorDirectiveMapping[errorElementKey];
        if(elementValue &&(!eventPattern.test(elementValue) && !modelPattern.test(elementValue))) {
            let errorMessage : string =  errorDirectiveMapping.ERROR_MESSAGE;
            if( errorMessage &&  errorMessage.indexOf('Pin') > -1)
            {
                errorMessage = errorMessage.replace('Pin','Pin'+(index+1));
            }
            modalErrorComponent.createCustomDPFieldError(errorElementKey + index, errorMessage,
                errorDirectiveMapping.ERROR_TOPIC, errorDirectiveMapping.ERROR_TYPE);
        }
        else{
            modalErrorComponent.removeDpFieldError(errorElementKey + index);
        }
    }

    static validatePinNB(matter: Matter, elementValue, errorElementKey, index, modalErrorComponent: ModalErrorComponent, requiredPidLength: number) : void {
        if(matter.isMatterProvinceNB){
            if(elementValue && elementValue.length != requiredPidLength){
                let errorDirectiveMapping = ErrorDirectiveMapping[errorElementKey];
                let extraProvinceText = matter.isMatterProvinceNB ? 'digits' : 'characters (NS9999999)';
                let errorMessage = `PID Number ${index+1} is invalid. PID must be ${requiredPidLength} ${extraProvinceText}`;
                modalErrorComponent.createCustomDPFieldError(errorElementKey + index, errorMessage,
                    errorDirectiveMapping.ERROR_TOPIC, errorDirectiveMapping.ERROR_TYPE);
            } else {
                modalErrorComponent.removeDpFieldError(errorElementKey + index);
            }
        }
    }

    static updateTotalShare(matterProperty: MatterProperty) : void {
        matterProperty.calculateTotalShare();
    }

    static updateCondominiumExpense(index : number, matterProperty: MatterProperty, project: Project) : void {
        if(project && project.projectAdjustmentConfig) {
            matterProperty.condominiumExpenses[index].condominiumExpense =
                Number(matterProperty.condominiumExpenses[index].sharePercentage) * project.projectAdjustmentConfig.totalMonthlyCommonExpenses / 100;
            this.updateTotalExpenses(matterProperty);
        }

    }

    static updateTotalExpenses(matterProperty: MatterProperty) : void {
        matterProperty.calculateTotalExpenses();
    }

    static loadProjectCondoUnitTypes(project: Project) : SelectItem[] {
        let condoUnitType = [];
        let option = {label : '', value : ''};
        condoUnitType.push(option);
        if(project && project.projectCondo && project.projectCondo.customUnitTypes && project.projectCondo.customUnitTypes.length){
            project.projectCondo.customUnitTypes.forEach((customUnitType: CustomUnitType)=>{
                option = {label : customUnitType.unitType, value : customUnitType.unitType};
                condoUnitType.push(option);
            })
        }
        return condoUnitType;
    }

    static overrideCondoDescChange(index: number, matterProperty: MatterProperty){
        if(!matterProperty.condominiumExpenses[index].overrideDescInd){
            matterProperty.condominiumExpenses[index].teraviewLegalDescOverriddenType = null;
            matterProperty.condominiumExpenses[index].teraviewLegalDesc = null;
        } else if(matterProperty.condominiumExpenses[index].overrideDescInd && !matterProperty.condominiumExpenses[index].teraviewLegalDescOverriddenType){
            matterProperty.condominiumExpenses[index].teraviewLegalDescOverriddenType = dropDowns.teraviewLegalDescOverriddenTypes[0].value;
        }
    }

    static initializeCondoPlan(matterProperty: MatterProperty, matter: Matter) : void {
        matterProperty.condominiumPlans = [];
        matterProperty.condominiumPlans.push(this.createNewCondominiumPlan(matter, matter.isMatterProvinceAB ? 'CONVENTIONAL_CONDOMINIUM_PLAN'  : 'CONDOMINIUM_PLAN'));
    }

    static createNewCondominiumPlan(matter: Matter, type? : string ) : CondominiumPlan {

        let condominiumPlan = new CondominiumPlan();
        condominiumPlan.condominiumPlanType = type ? type : (matter.isMatterProvinceAB ? 'CONVENTIONAL_CONDOMINIUM_PLAN' : 'STANDARD_CONDOMINIUM_PLAN');
        return condominiumPlan;
    }

    static initializeCondoExpense(matterProperty: MatterProperty) : void {
        matterProperty.condominiumExpenses = [];
        matterProperty.condominiumExpenses.push(this.createCondominiumExpense());
        matterProperty.condominiumExpenses.push(this.createCondominiumExpense());
        matterProperty.condominiumExpenses.push(this.createCondominiumExpense());
    }

    static createCondominiumPlanObject(matterProperty: MatterProperty, matter: Matter) : void {

        for(let i = 0; i < matterProperty.condominiumExpenses.length; i++) {
            if(matterProperty.condominiumExpenses[i].planNumber) {
                matterProperty.condominiumExpenses[i].condominiumPlan = this.getCondominiumPlan(matterProperty.condominiumExpenses[i].planNumber, matterProperty, matter);
            }
            matter.calculateTeraviewLegalDesc(matterProperty.condominiumExpenses[i], matterProperty);
        }
    }

    static getCondominiumPlan(planNumber : string, matterProperty: MatterProperty, matter: Matter) : CondominiumPlan {
        let condominiumPlan : CondominiumPlan = null;
        if(planNumber) {
            condominiumPlan = matterProperty.condominiumPlans
                .find(condoPlan => Utils.buildCondoPlanNumber(condoPlan, matter.isMatterProvinceAB, matterProperty.condominiumJurisdiction) == planNumber);
        }
        return condominiumPlan;
    }

    static createCondominiumExpense() : CondominiumExpense {

        let condominiumExpense = new CondominiumExpense();
        condominiumExpense.condominiumUnitType = '';
        condominiumExpense.condominiumExpense = 0;
        condominiumExpense.lincNumber = '';
        condominiumExpense.noUndividedShare = '0.000000';
        condominiumExpense.rollNumber = '';
        return condominiumExpense;
    }

    static teraviewLegalDescOverriddenTypeChanged(condominiumExpense: CondominiumExpense, matter: Matter, matterProperty: MatterProperty) : void {
        if(matter.isProjectSale && matter.isMatterProvinceON){
            condominiumExpense.teraviewLegalDesc = '';
            matter.updateTeraviewLegalDescs(condominiumExpense, matterProperty);
        }
    }

    static checkOverrideDescription(matterProperty: MatterProperty) : void {
        if(matterProperty && matterProperty.condominiumExpenses && matterProperty.condominiumExpenses.length){
            matterProperty.condominiumExpenses.forEach((condominiumExpense)=>{
                if(!condominiumExpense.overrideDescInd){
                    condominiumExpense.teraviewLegalDescOverriddenType = null;
                    condominiumExpense.teraviewLegalDesc = '';
                }
            });
        }
    }

    static updatePlanNumber(matterProperty: MatterProperty, matter: Matter, isMassOpen: boolean) : void {
        if (!matterProperty){
            return;
        }
        //The condominium plan is set into condominiumExpense object via "Plan number" drop down but that drop down shows up only if there are more than one
        // condominium plan. Therefore, in case of single condominium plan we are explicitly populating plan number in condominium expense.
        if(matterProperty.condominiumPlans && matterProperty.condominiumPlans.length == 1 && matterProperty.condominiumPlans[0].condominiumPlanNumber)
        {   //Single condo plan exists therefore getting the plan number from first condominium plan
            for(let i = 0; i < matterProperty.condominiumExpenses.length; i++) {
                //Populating plan number for only those rows in which user has filled some information
                if(matterProperty.condominiumExpenses[i].isNonEmptyObject()) {
                    if (isMassOpen) {
                        matterProperty.condominiumExpenses[i].planNumber = this.getPlanNumber(matterProperty.condominiumPlans[0], matter);
                    } else { // required for unit/level/plan modal
                        matterProperty.condominiumExpenses[i].planNumber = this.getPlanNumberUsingMatterProperty(matterProperty.condominiumPlans[0], matter, matterProperty);
                    }

                }
            }
        } else {
            //If more than one condominium plans exist then using the condominium plan which should be already set into condominiumExpense object via "Plan
            // number" drop down
            for(let i = 0; i < matterProperty.condominiumExpenses.length; i++) {
                if(matterProperty.condominiumExpenses[i].condominiumPlan && matterProperty.isCondominiumPlanExisting(matterProperty.condominiumExpenses[i].condominiumPlan)) {
                    if (isMassOpen) {
                        matterProperty.condominiumExpenses[i].planNumber = this.getPlanNumber(matterProperty.condominiumExpenses[i].condominiumPlan, matter);
                    } else { // required for unit/level/plan modal
                        matterProperty.condominiumExpenses[i].planNumber = this.getPlanNumberUsingMatterProperty(matterProperty.condominiumExpenses[i].condominiumPlan, matter, matterProperty);
                    }

                }
            }
        }
    }

    static updateDPFieldErrors(modalErrorComponent: ModalErrorComponent, matterProperty: MatterProperty) : void {
        modalErrorComponent.removeAllDpFieldError();
        for(let i = 0; i < matterProperty.condominiumExpenses.length; i++) {
            if(matterProperty.condominiumExpenses[i].pinNumber)
            {
                this.addCustomPinWarning(matterProperty.condominiumExpenses[i].pinNumber, 'matter.propertyteranet.pin', i, modalErrorComponent);
            }
        }
    }

    static generateCondominiumPlanDescriptionForAB(condominiumUnitTypeValue : string) : string {

        if (condominiumUnitTypeValue === 'CONVENTIONAL_CONDOMINIUM_PLAN') {
            return 'Condominium Plan ';
        } else if (condominiumUnitTypeValue === 'BARE_LAND_PLAN') {
            return 'Bare Land Condominium Plan ';
        }
        return '';
    }

    static checkLincAndRollNumber(modalErrorComponent: ModalErrorComponent, matterProperty: MatterProperty, provinceCode: ProvinceCode) : void {
        if(provinceCode === PROVINCE_CODES.ALBERTA){
            modalErrorComponent.removeDpSaveError('matter.subjectProperty.unitLevelPlan.rollNumber');
            let rollNumbers: string[] = matterProperty.condominiumExpenses.map(condoExpense => {return condoExpense.rollNumber; }).filter(Boolean);

            modalErrorComponent.removeDpSaveError('matter.subjectProperty.unitLevelPlan.lincNumber');
            let lincNumbers: string[] = matterProperty.condominiumExpenses.map(condoExpense => {return condoExpense.lincNumber; }).filter(Boolean);

            if (rollNumbers.length > 1){
                let duplicates: string[] = rollNumbers.filter((rollNumber: string, idx: number) => rollNumbers.lastIndexOf(rollNumber) !== idx);
                if (Array.isArray(duplicates) && duplicates.length > 0){
                    modalErrorComponent.createCustomDPSaveError(`matter.subjectProperty.unitLevelPlan.rollNumber`,
                                                                `Duplicated roll numbers: ${duplicates.join(', ')}`,
                                                                'Roll Number', "ERROR");
                }
            }
            if (lincNumbers.length > 1){
                let duplicates: string[] = lincNumbers.filter((lincNumber: string, idx: number) => lincNumbers.lastIndexOf(lincNumber) !== idx);
                if (Array.isArray(duplicates) && duplicates.length > 0){
                    modalErrorComponent.createCustomDPSaveError(`matter.subjectProperty.unitLevelPlan.lincNumber`,
                                                                `Duplicated LINC numbers: ${duplicates.join(', ')}`,
                                                                'LINC Number', "ERROR");
                }
            }
        }
    }

    static generateCondoPlanDescWithNumberForAB(condominiumPlanTypeLabel : string, condominiumPlanNumber: string) : string {

        if (!condominiumPlanNumber) {
            return condominiumPlanTypeLabel;
        }

        if (condominiumPlanTypeLabel === 'Conventional Condominium') {
            return 'Condominium Plan ' + condominiumPlanNumber;
        } else if (condominiumPlanTypeLabel === 'Bare Land Condominium') {
            return condominiumPlanTypeLabel + ' Plan ' + condominiumPlanNumber;
        }
        return '';
    }
}
