import {api} from '../../common/api';

/** Application endpoint urls
 */
export const mortgageeEnclosureApi = {

    getMortgageeEnclosure :`${api}/accounts/{accountId}/accountMortgageeEnclosures/`,
    putMortgageeEnclosure :`${api}/accounts/{accountId}/province/{provinceCode}/accountMortgageeEnclosures/`,

};
