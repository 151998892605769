import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-chatairobo',
  templateUrl: 'chat-AI-robo.component.html',
  styleUrls: ['chat-AI-robo.component.scss'],
})
export class ChatAIRoboComponent implements OnInit {
  @Output() showChatbot = new EventEmitter<boolean>();
  @Output() showTermsConditions = new EventEmitter<boolean>();
  @Input() isChatbotWndCloseBtnClicked: boolean;
  @Input() isTnCClosBtnClicked: boolean;
  @Input() userAcceptedTnC: boolean;

  roboClickFlag: boolean = false;
  chatbotFlag: boolean = false;
  termsnConditionFlag: boolean = false;
  isTnCAcceptedByUser: boolean = false;

  constructor(public utilsService: UtilsService) { }

  ngOnInit(): void {
    if (!this.userAcceptedTnC) {
      this.ShowTnCComponentOnLoad();
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.isTnCClosBtnClicked && !change.isTnCClosBtnClicked.firstChange) {
      this.roboClickFlag = change.isTnCClosBtnClicked.currentValue
        ? false
        : this.roboClickFlag;
    }
    if (
      change.isChatbotWndCloseBtnClicked &&
      !change.isChatbotWndCloseBtnClicked.firstChange
    ) {
      this.roboClickFlag = change.isChatbotWndCloseBtnClicked.currentValue
        ? false
        : this.roboClickFlag;
    }
  }

  ShowTnCComponentOnLoad() {
    this.roboClickFlag = true;
    this.termsnConditionFlag = true;
    this.showTermsConditions.emit(this.termsnConditionFlag);
  }

  RoboIconClick() {
    this.roboClickFlag = !this.roboClickFlag;
    this.isTnCAcceptedByUser = this.isTnCAcceptedByUser
      ? this.isTnCAcceptedByUser
      : this.userAcceptedTnC
        ? true
        : false;
    if (this.isTnCAcceptedByUser) {
      this.chatbotFlag = this.isChatbotWndCloseBtnClicked
        ? true
        : !this.chatbotFlag;
      this.showChatbot.emit(this.chatbotFlag);
    } else {
      this.termsnConditionFlag = this.isTnCClosBtnClicked
        ? true
        : !this.termsnConditionFlag;
      this.showTermsConditions.emit(this.termsnConditionFlag);
    }
  }
}