import {Directive, ElementRef, Renderer2, SimpleChanges} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {CustomKeyCodesEnum} from '../common/key-code-enum';
import {WindowRef} from '../shared/window.ref';

declare var jQuery : any;

@Directive({
    selector: '[dp-table-key-commands]'
})

export class DpTableKeyCommandsDirective {
    constructor(private el: ElementRef, private logger : Logger, private renderer: Renderer2, private window : WindowRef) {


    }


    activeIndexFromTabKey : number = -1;
    lastVisitedRowIndex : number = -1;
    previousIndexFromTabKey : number = -1;

    tableRowCount: number =  0;

    /*
    @HostListener('keydown', ['$event'])
    keyEvent(event: KeyboardEvent) {

        console.log(event);


    }
*/

    ngOnInit(){



    }

    ngAfterViewInit(): void{
        jQuery(this.el.nativeElement).addClass("table-key-commands");
        jQuery('.table-key-commands').attr("tabindex","-1");

        setTimeout(()=>{

        this.initTableAttributes();


        },1000);
    }

    initTableAttributes(){

        this.tableRowCount = this.calculateTableRowCount();


        jQuery('.table-key-commands tbody tr').attr("tabindex","0");

        jQuery('.table-key-commands').on('keydown',  (e) => {
            this.tableKeyCommands(e);
        });
        jQuery(".table-key-commands").on('focus click', 'tr', (e) => {

            if(e.type=="focusin"){
                this.setActiveIndex(e);
            } else if(e.type=="click") {

                this.onSelectedChange(e);

            }
        });
    }


    ngOnChanges(changes: SimpleChanges) {
        console.log("ngOnChanges | changes:", changes);


    }

    calculateTableRowCount() : number {
        let tableRowCount  = jQuery('.table-key-commands tbody tr:last').index() + 1;
        if(!isNaN(tableRowCount)) {
            return tableRowCount;
        } else {
            return 0;
        }
    }

    tableKeyCommands(event) : void {

        let charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));
        if(charCode === CustomKeyCodesEnum.SpaceBar) {
            event.preventDefault();
            // select row with spacebar key

            this.keyCommandtoSelectRow(event);
        }
        if(charCode === CustomKeyCodesEnum.Tab) {
            if(event.target.className == "toggleBurger") {

                this.activeIndexFromTabKey = -1;
                event.preventDefault();
                this.removeClassFromRow(event);
            }
        }
        if(charCode === CustomKeyCodesEnum.Down) {
            event.preventDefault();
            if(event.shiftKey) {
                /// select next row with shift + down arrow
                this.keyCommandtoSelectNextRow(event);
            } else {
                // hilite next row with down arrow
                this.keyCommandtoHiliteNextRow(event);
            }
        }
        if(charCode === CustomKeyCodesEnum.Up) {
            event.preventDefault();
            if(event.shiftKey) {
                /// select prev row with shift + up arrow
                this.keyCommandtoSelectPrevRow(event);
            } else {
                // hilite prev row with up arrow
                this.keyCommandtoHilitePreviousRow(event);

            }
        }
    }

    keyCommandtoSelectRow(event) {

        if(this.activeIndexFromTabKey < (this.tableRowCount)) {
            this.onSelectedChange(event);
        }

    }




    keyCommandtoSelectNextRow(event) {
        if(this.activeIndexFromTabKey < this.tableRowCount) {
            let startIndex : number = this.activeIndexFromTabKey;
            let endIndex : number = startIndex + 2;
            this.keyCommandtoHiliteNextRow(event);
            this.selectMultipleRows(startIndex, endIndex);
        }
    }


    keyCommandtoHiliteNextRow(event) {

        if(this.activeIndexFromTabKey < this.tableRowCount) {
            if(document.activeElement.className === "toggleBurger") {
                jQuery(event.target).closest('tr').next('tr').focus();
            } else {
                jQuery(document.activeElement).next('tr').focus();
            }
        }

    }


    keyCommandtoHilitePreviousRow(event) {
        if(this.activeIndexFromTabKey > -1) {
            if(document.activeElement.className === "toggleBurger") {
                jQuery(event.target).closest('tr').prev('tr').focus();
            } else {
                jQuery(document.activeElement).prev('tr').focus();
            }
        }
    }

    keyCommandtoSelectPrevRow(event) {
        if(this.activeIndexFromTabKey > -1) {
            let startIndex : number = this.activeIndexFromTabKey + 1;
            let endIndex : number = this.activeIndexFromTabKey;
            this.keyCommandtoHilitePreviousRow(event);
            this.selectMultipleRows(startIndex, endIndex);
        }
    }

    setActiveIndex(e) {

        this.previousIndexFromTabKey = this.activeIndexFromTabKey;
        this.activeIndexFromTabKey = Number(e.currentTarget.rowIndex) - 1;
        this.lastVisitedRowIndex = Number(e.currentTarget.rowIndex) - 1;
    }

    addClassToRow(e) {
        // when you focus on burger menu in row we add background color to row
        jQuery(e.target).closest('tr').addClass("active-hover");
    }

    removeClassFromRow(e) {
        // when you blur on burger menu in row we remove background color to row
        jQuery(e.target).closest('tr').removeClass("active-hover");
    }



    selectMultipleRows(startIndex, endIndex) {

        if(startIndex < endIndex) {
            for(var i = startIndex; i < endIndex; i++) {
                // cycle through from start to end Index and add to selected matters list if they are not opened locked or selected already

                jQuery('tbody tr:eq(' + (i)+ ')').addClass('selected');
            }
        } else {
            for(var i = startIndex; i > (endIndex - 1); i--) {
                // cycle through from start to end Index and add to selected matters list if they are not opened locked or selected already

                jQuery('tbody tr:eq(' + (i-1)+ ')').addClass('selected');
            }
        }
    }

    addRemoveSelectedRowClass(rowIndex){
        let selector:any = jQuery('.table-key-commands tbody tr:nth-child(' + rowIndex + ')');
            if(selector.hasClass('selected')) {
                selector.removeClass('selected');
            } else {
                selector.addClass('selected');
            }

    }

    onSelectedChange(event) {
        //No need to preventDefault or stopPropagation on click event as that may cause some fields like checkbox to stop working
        if(event.type != "click"){
            event.preventDefault();
            event.stopPropagation();
        }

        // click from checkbox focus on row
        console.log(event.target.tagName+'  '+event.type+' '+event.currentTarget);
        if(event.target.tagName === "LABEL") {
            jQuery(event.target).closest('tr').focus();

        }

        if(this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {
            jQuery(event.target).closest('tr').focus();
        }


        let selectedRow:number = -1;
        if(event.type=='click'){
            selectedRow = jQuery(event.currentTarget).index() + 1;
        } else {
            selectedRow = jQuery(event.target).index() + 1;
        }


        let startIndex:number = this.previousIndexFromTabKey;
        let endIndex:number = selectedRow;


        if(event.shiftKey && jQuery('.table-key-commands tr').hasClass('selected')) {

            this.selectMultipleRows(startIndex, endIndex);
            // fixes a bug from shift click that causes text to hilite, removes selected text
            this.window.nativeWindow.getSelection().removeAllRanges();
            if(this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {

                jQuery(event.target).closest('tr').focus();
            }

            //    }
        } else {

                if(event.target.tagName === "LABEL" || event.ctrlKey === true) {
                    /// if event.target label -- checkbox was clicked not the row.
                    this.addRemoveSelectedRowClass(selectedRow);
                } else {
                    // row was clicked instead so we remove all selected matters and only selected the one row
                    if(!jQuery('.table-key-commands tbody tr:nth-child(' + selectedRow + ')').hasClass('selected')) {
                        jQuery('.table-key-commands tr').removeClass("selected");
                    }
                    this.addRemoveSelectedRowClass(selectedRow);
                }


        }
    }



}
