import {Mortgage, MortgageSource, MortgageType} from '../mortgage';
import {Address} from '../address';
import {DpBooleanValueTypes} from '../dp-boolean';
import {MAX_PRIORITY_ALLOWED, MortgageAction, MortgageDispositionType, ZERO_INTEREST} from '../../../shared-main/constants';
import {Matter,} from '../matter';
import {dischargeRegisteredByOptions} from '../../mortgages/mortgage/dropdown-options';
import {ErrorService} from '../../../shared/error-handling/error-service';
import * as _ from 'lodash';
import {MatterCleanUpUtil} from './matter-clean-up-util';
import {MortgageSoAdjService} from '../../../shared-main/mortgage-so-adj.service';
import {StewartMortgageInstruction} from '../../../shared-main/telus/stewart-mortgage-instruction';
import {MortgageTerm} from '../mortgage-term';
import {MatterUtil} from '../matter-util';
import {Contact} from '../contact';
import {ContactInstanceType} from '../contact-instance-type';
import {AddressTypes} from '../address-types';
import {provinceBasedFinancingTypeDropDown} from '../../../shared-main/province-based-dropdowns';
import {SelectItem} from 'primeng/api';

export class MatterMortgageUtil {

    static createMortgage(matter: Matter, mortgageType: MortgageType, mortgageSource: MortgageSource = 'UNITY', newMortgagePriority?: number): Mortgage {
        let address = new Address();
        address.addressTypeCode = 'MAILING';
        let mortgage: Mortgage = new Mortgage(<Mortgage>{
            id: matter.generateMortgageId(),
            insured: DpBooleanValueTypes.N_y,
            automaticallyUpdateSOA: DpBooleanValueTypes.Y_n,
            acting: DpBooleanValueTypes.Y_n,
            includeAuthorizeSignOfficer: DpBooleanValueTypes.N_y,
            mortgageeType: matter.isTemplateMatterForMassUpdate ? "" : "INSTITUTION",
            mortgageCorrespondenceType: null,
            addresses: [address],
            loanNo: null,
            commitmentDate: "",
            branchOrTransitNo: null,
            instructionsReceived: DpBooleanValueTypes.N_y,
            preliminaryReportSubmitted: DpBooleanValueTypes.N_y,
            requestForFundsSubmitted: DpBooleanValueTypes.N_y,
            finalReportSubmitted: DpBooleanValueTypes.N_y,
            lawFirmName: "",
            mortgageType: mortgageType,
            mortgageSource: mortgageSource,
            guarantorPartyToMortgage: true,
            mortgagePriority: newMortgagePriority,
            deductMonies: 'QUESTION',
            lienHoldback: DpBooleanValueTypes.N_y
        });

        if (matter.isProjectSale) {
            mortgage.autoIncludeVtbMortgageAmount = DpBooleanValueTypes.Y_n;
        }

        mortgage.autoCreateVtbMortgageAdjustment = DpBooleanValueTypes.N_y;

        if (mortgageType === 'EXISTING') {
            //Changing the default value as per JR - https://doprocess.atlassian.net/browse/DPPMP-52220
            mortgage.mortgageDispositionType = MortgageDispositionType.DISCHARGED;
            mortgage.mortgageCorrespondenceType = 'MORTGAGEE';
            mortgage.undertakingDirty = true;
        } else {
            // As DocGen requirement, set loanType value as "BACK_TO_VENDOR " for the VTB mortgage of sale matter
            if (matter.isSale) {
                mortgage.loanType = matter.isProjectSale && matter.isMatterProvinceAB ? "NEW" : "BACK_TO_VENDOR";
            } else {
                mortgage.loanType = "ARRANGED";
            }
        }

        // If sale matter and mortgageType is NEW then default the mortgagee type to PRIVATE lender
        if (matter.isSale && mortgage.isUnityNewMortgage()) {
            mortgage.mortgageeType = 'PRIVATE_LENDER';
        }

        matter.updateMortgageeInterestOnNotedPolicy(mortgage);
        matter.createTPRMortgageEForms(mortgage);
        matter.createERegChargeForms(mortgage);
        return mortgage;
    }

    static addNewExistingMortgage(matter: Matter): Mortgage {
        const existingMortgage: Mortgage = MatterMortgageUtil.createMortgage(matter, 'EXISTING');
        if (matter.isMatterProvinceAB) {
            existingMortgage.dischargeRegisteredBy = dischargeRegisteredByOptions.find(item => item.value === 'OUR_FIRM').value;
        }
        existingMortgage.financingType = 'Mortgage';
        existingMortgage.mortgageCorrespondenceType = matter.isTemplateMatterForMassUpdate ? "" : "MORTGAGEE";
        existingMortgage.statementForInformation = "Y_n";
        existingMortgage.collateralMortgage = "N_y";
        matter.existingMortgages.push(existingMortgage);
        return existingMortgage;
    }

    static addNewVtbMortgage(matter: Matter): Mortgage {
        console.log(">> create new Vtb mortgage for matter %s", matter.matterRecordNumber)
        const newVtbMortgage: Mortgage = MatterMortgageUtil.createMortgage(matter, 'NEW');
        // Based on Edmund's decision, add to the largest priority until 16, then more added Vtg Mortgage's priority is undefined, this case will be handled in future story
        const maxPriorityInUsed = matter.getMaxPriorityUsedByNewMortgage();
        newVtbMortgage.mortgagePriority = maxPriorityInUsed < MAX_PRIORITY_ALLOWED ? maxPriorityInUsed + 1 : undefined;
        matter.mortgages.push(newVtbMortgage);
        return newVtbMortgage;
    }

    static deleteExistingMortgage(matter: Matter, selectedMortgage: Mortgage, errorService?: ErrorService): void {
        if (selectedMortgage && errorService) {
            errorService.removeErrorsInGroup(selectedMortgage.groupId);
        }
        let mortgageIndex: number = _.findIndex(matter.existingMortgages, mortgage => mortgage === selectedMortgage);
        if (mortgageIndex >= 0) {
            matter.existingMortgages.splice(mortgageIndex, 1);

            //Deletion of mortgage can cause re-ordering of remaining mortgages.
            //Any mortgages at the deletion index or higher need to have their undertakings re-created
            //also need to delete the existing errors related to affected undertaking fields
            for (let i: number = mortgageIndex; i < matter.existingMortgages.length; i++) {
                matter.existingMortgages[i].undertakingDirty = true;
                if (errorService) {
                    errorService.removeErrorsInGroup(matter.existingMortgages[i].getDischargedMortgageGroupId());
                }
                const undertaking = matter.matterUndertakings.find(undertaking => undertaking.id == matter.existingMortgages[i].undertakingId);
                if (undertaking && errorService) {
                    errorService.removeErrorsInGroup(undertaking.groupId);
                }
            }

            //check if mortgage being deleted has a related undertaking and delete it
            const undertaking = matter.matterUndertakings.find(undertaking => undertaking.id == selectedMortgage.undertakingId);
            if (undertaking) {
                if (errorService) {
                    errorService.removeErrorsInGroup(undertaking.groupId);
                }
                (<any>matter.matterUndertakings).remove(undertaking);
            }

            //check if mortgage being deleted has a related permitted registration and delete it
            const permittedRegistration = matter.permittedRegistrations.find(permittedRegistration => permittedRegistration.id == selectedMortgage.permittedRegistrationId);
            if (permittedRegistration) {
                (<any>matter.permittedRegistrations).remove(permittedRegistration);
            }
        }

        MatterCleanUpUtil.cleanUpMortgageParticipant(matter);
    }

    static deleteExistingMortgageWithCleanup(matter: Matter, deletedIndex: number, mortgage: Mortgage, errorService?: ErrorService) {
        if (matter.isMatterProvinceAB && mortgage && mortgage.isMortgageDispositionDischarged() && mortgage.mortgagePayout && mortgage.mortgagePayout.payoutEqualsTrustBalance && matter.soaTrustLedgerCollection) {
            //make PayToYouTL editable
            matter.soaTrustLedgerCollection.makePayToYouTLEditable();
        }
        matter.deleteExistingMortgage(mortgage, errorService);
        matter.removeEregistrationFormByMortgage(mortgage.id);
        if (matter.soaTrustLedgerCollection) {
            matter.soaTrustLedgerCollection.updateExistingMortgageIndex(deletedIndex);
            matter.soaTrustLedgerCollection.updatePaidDischargeFeeIndex(deletedIndex);
            matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
        }
    }

    static deleteVtbMortgage(matter: Matter, vtbMortgage: Mortgage, mortgageSoAdjService: MortgageSoAdjService) {
        console.log(">> will delete VtgMortgage with id %i and do the cleanup ...", (vtbMortgage && vtbMortgage.id));
        let deletedIndex: number = matter.mortgages.findIndex(item => item == vtbMortgage);
        let deletedMortgagePriority = vtbMortgage.mortgagePriority;
        if (deletedIndex > -1) {
            matter.deleteMortgage(vtbMortgage);
            if (matter.soaTrustLedgerCollection) {
                matter.soaTrustLedgerCollection.updateMortgageIndex(deletedIndex);
                if(matter.secondarySoaSheetsCollection){
                    matter.secondarySoaSheetsCollection.forEach( collection =>{
                        collection.updateMortgageIndex(deletedIndex);
                    })
                }
                matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
                if(matter.secondarySoaSheetsCollection){
                    matter.secondarySoaSheetsCollection.forEach( collection =>{
                        collection.updateERegAndRegisterCharges();
                    })
                }
            }
            matter.updateTitleInsurance(MortgageAction.DELETE, deletedMortgagePriority, vtbMortgage);
            mortgageSoAdjService.rearrangeAdjustments(matter);
            mortgageSoAdjService.updateStatementOfAdjustment(matter);
        }
    }

    static mapMortgageInstructionToMortgage(matter: Matter, mortgage: Mortgage, mortgageInstruction: StewartMortgageInstruction): void {

        mortgage.stewartAssystMortgageInstruction = mortgageInstruction;
        mortgage.loanNo = mortgageInstruction.lenderRefNo;
        mortgage.insured = mortgageInstruction.mortgageInsured ?  mortgageInstruction.mortgageInsured : DpBooleanValueTypes.NO;
        if (!mortgage.mortgageTerm) {
            mortgage.mortgageTerm = new MortgageTerm();
        }
        if(!mortgage.mortgageTerm.calculationMethod) {
            mortgage.mortgageTerm.calculationMethod = 'MANUAL_EDIT';
        }

        const isLenderCMHCFromFCT: boolean = mortgageInstruction && mortgageInstruction.isFctInstruction && mortgageInstruction.isLenderCMHC;
        const isBlankMortgageTypeFromFCT: boolean = mortgageInstruction && mortgageInstruction.isFctInstruction && !mortgageInstruction.mortgageInstructionData.mortgageType;

        if (!isLenderCMHCFromFCT && matter.provinceCode === 'MB') {
            if (mortgageInstruction.mortgageInstructionData && mortgageInstruction.mortgageInstructionData.collateralMortgage) {
                mortgage.mortgageTerm.regularPayments = DpBooleanValueTypes.NO;
            } else  if (isBlankMortgageTypeFromFCT) {
                //if mortgageType is blank, regular payment will be blank too, this is the unique value from emp FCT
                mortgage.mortgageTerm.regularPayments = '';
            } else{//mortgageType is conventional, reguarPayments set to 'YES'
                mortgage.mortgageTerm.regularPayments = DpBooleanValueTypes.YES;
            }
        }
        else {
            // Initialize the regularPayments & alternateFrequency under mortgage term to produce the DP Codes under Regular Payment field Values. -- DPPMP-22329
            mortgage.mortgageTerm.regularPayments = isLenderCMHCFromFCT ? DpBooleanValueTypes.NO : DpBooleanValueTypes.YES;
        }

        if(!mortgage.mortgageTerm.alternateFrequency){
            mortgage.mortgageTerm.alternateFrequency = DpBooleanValueTypes.N_y;
        }

        mortgage.mortgageTerm.principal = mortgageInstruction.principalAmount;

        if (!isNaN(Number(mortgageInstruction.netAdvance))) {
            mortgage.mortgageTerm.totalNetAdvance = Number(mortgageInstruction.netAdvance);
        }

        if (isLenderCMHCFromFCT) {
            mortgage.mortgageTerm.variableInterestRate = DpBooleanValueTypes.N_y;
        } else if (mortgageInstruction.variableRate(matter.provinceCode)
            && (mortgageInstruction.variableRate(matter.provinceCode).toUpperCase() == DpBooleanValueTypes.YES
                || mortgageInstruction.variableRate(matter.provinceCode).toUpperCase() == DpBooleanValueTypes.NO)) {
            mortgage.mortgageTerm.variableInterestRate = mortgageInstruction.variableRate(matter.provinceCode).toUpperCase();
        }

        if (mortgage.mortgageTerm.variableInterestRate == DpBooleanValueTypes.YES) {
            if (matter.isMatterProvinceMB) {
                if (mortgageInstruction.getRegisteredInterestRate(matter.provinceCode) && !mortgage.mortgageTerm.variableRate) {
                    mortgage.mortgageTerm.variableRate = mortgageInstruction.getRegisteredInterestRate(matter.provinceCode);
                }
            } else if (mortgageInstruction.interestRate && !mortgage.mortgageTerm.variableRate) {
                mortgage.mortgageTerm.variableRate = mortgageInstruction.interestRate;
            }
        } else {
            if (mortgage.mortgageTerm.interest == null || !(Number(mortgage.mortgageTerm.interest) > 0)) {
                if (matter.isMatterProvinceMB) {
                    if (mortgageInstruction.mortgageInstructionData && !isNaN(Number(mortgageInstruction.mortgageInstructionData.interestRateForManitobaRegistration))) {
                        mortgage.mortgageTerm.interest = mortgageInstruction.mortgageInstructionData.interestRateForManitobaRegistration;
                    }
                } else if (!isNaN(Number(mortgageInstruction.interestRate))) {
                    mortgage.mortgageTerm.interest = mortgageInstruction.interestRate;
                }
            }
        }

        if (mortgage.mortgageTerm.registeredInterest == undefined) { // this field at Emp allows override
            mortgage.mortgageTerm.registeredInterest = isLenderCMHCFromFCT ? ZERO_INTEREST : mortgageInstruction.getInterestRateForRegistrationByProvince(matter.provinceCode);
        }

        mortgage.mortgageTerm.interestOnly = ((mortgageInstruction && mortgageInstruction.isFctInstruction) || !mortgageInstruction.interestOnly) ? DpBooleanValueTypes.N_y : mortgageInstruction.interestOnly.toUpperCase();
        mortgage.mortgageTerm.paymentFrequency = isLenderCMHCFromFCT ? '' : MatterUtil.getUnityPaymentFrequencyFromThirdParty(mortgageInstruction.paymentFrequency);
        mortgage.mortgageTerm.paymentsDue = isLenderCMHCFromFCT ? '' : mortgageInstruction.paymentsDue;

        mortgage.mortgageTerm.interestAdjustmentDate = mortgageInstruction.interestAdjustmentDateValue;

        mortgage.mortgageTerm.firstPaymentDate = mortgageInstruction.firstPaymentDateValue;

        // the existing Matter (with EMP) will refresh the maturityDate based on the following new logic when open the matter through this method
        mortgage.mortgageTerm.maturityDate = mortgageInstruction.maturityDateValue;
        mortgage.mortgageTerm.taxPortion = mortgageInstruction.taxesAsRegistered;
        mortgage.mortgageTerm.otherPayment = mortgageInstruction.otherAsRegistered;
        mortgage.mortgageTerm.total = mortgageInstruction.totalAsRegistered;
        mortgage.mortgageTerm.alternatePaymentFrequency = mortgageInstruction.paymentFrequencyAlternate;
        mortgage.mortgageTerm.alternatePaymentsDue = mortgageInstruction.paymentsDueAlternate;
        mortgage.mortgageTerm.alternateTaxPortion = mortgageInstruction.taxesAlternate;
        mortgage.mortgageTerm.alternateOtherPayment = mortgageInstruction.otherAlternate;
        mortgage.mortgageTerm.alternateTotal = mortgageInstruction.totalAlternate;

        if (mortgage.mortgageTerm.calculated == undefined) { // this field at Emp allows override
            mortgage.mortgageTerm.calculated = isLenderCMHCFromFCT ? '' : MatterUtil.getUnityInterestCalculatedFromThirdParty(mortgageInstruction.interestCalculated);
        }

        mortgage.mortgageTerm.regularPaymentAmortizationPeriod = mortgageInstruction.loanAmortization;

        if (!mortgage.mortgageTerm.sctNoOverridden) {
            mortgage.mortgageTerm.sctNo = mortgageInstruction.standardChargeTermNo;
        }
        mortgage.mortgageTerm.regularPayment = mortgageInstruction.principalAndInterestAsRegistered;
        mortgage.mortgageTerm.alternateRegularPayment = mortgageInstruction.principalAndInterestAlternate;
        if (mortgageInstruction.insurancePremiumIncluded == '1') {
            mortgage.mortgageTerm.otherText = 'Insurance Premium';
            mortgage.mortgageTerm.other = 'Yes';
        } else {
            mortgage.mortgageTerm.other = 'No';
        }

        mortgage.lenderReline = mortgageInstruction.mortgagee;
        mortgage.mortgageTerm.sctFiledBy = mortgageInstruction.mortgagee;
        if (mortgageInstruction.mortgageInstructionData) {
            mortgage.mortgageTerm.collateralMortgage = mortgageInstruction.mortgageInstructionData.collateralMortgage ? DpBooleanValueTypes.YES : DpBooleanValueTypes.N_y;
        }
    }


    static mapStewartAssystMortgageInstructionToMortgagee(matter: Matter, mortgage: Mortgage, mortgageInstruction: StewartMortgageInstruction): void {
        if (mortgageInstruction.mortgagee) {
            let mortgageeContact = new Contact();
            mortgageeContact.contactType = 'MORTGAGEE';
            mortgageeContact.gender = 'CORPORATION';
            mortgageeContact.instanceType = ContactInstanceType.ORGANIZATION;
            mortgageeContact.canadianResidentFlag = "Y_n";
            mortgageeContact.partyRole = 'OTHER_PARTY';
            mortgageeContact.activeFlag = 'Y_n';
            mortgageeContact.privateFlag = true;
            mortgageeContact.mapStrToOrganization(mortgageInstruction.mortgagee);
            mortgageeContact.workPhone = mortgageInstruction.contactPhone;
            mortgageeContact.faxPhone = mortgageInstruction.mortgageeBranchFax;
            // dischargeFax number from originating branch will be copied
            mortgageeContact.dischargeFax = mortgageInstruction.originatingBranchFax;
            // Transit Number needs to be copied from Servicing branch.
            mortgageeContact.transitNo = mortgageInstruction.servicingBranchTransit;
            let mailingAddress = new Address();
            mailingAddress.mapEMPAddress(mortgageInstruction.mortgageeAddress);
            mailingAddress.addressTypeCode = AddressTypes.mailing;
            mortgageeContact.address.push(mailingAddress);
            let serviceAddress = new Address();
            serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
            serviceAddress.sameAsAddressTypeCode = AddressTypes.mailing;
            mortgageeContact.address.push(serviceAddress);
            let reportAddress = new Address();
            reportAddress.mapEMPAddress(mortgageInstruction.servicingBranchAddress);
            reportAddress.addressTypeCode = AddressTypes.reportAddress;
            mortgageeContact.address.push(reportAddress);
            matter.setMatterParticipant(mortgageeContact, false, 'MORTGAGEE', mortgage.id);
        }

        if (mortgageInstruction.contactName) {
            let attentionContact = new Contact();
            attentionContact.contactType = 'PERSON';
            attentionContact.gender = 'QUESTION';
            attentionContact.instanceType = ContactInstanceType.PERSON;
            attentionContact.canadianResidentFlag = "Y_n";
            attentionContact.partyRole = 'OTHER_PARTY';
            attentionContact.activeFlag = 'Y_n';
            attentionContact.mapStrToContactName(mortgageInstruction.contactName);
            matter.setMatterParticipant(attentionContact, false, 'MORTGAGEE_ATTENTION', mortgage.id);
        }

    }

    //Mapping Teranet Connect (Third Party Data) Instruments to Mortgage Financing Type in unity from Import Data.
    static mapTeranetInstrumentTypeToMortgageFinancingType(instrumentType: string, provinceCode : string): string{
        let financingTypeOption ='';
        switch(instrumentType.toUpperCase()){
            case 'CAUTION OF AGREEMENT OF PURCHASE AND SA' :
                financingTypeOption = 'CAUTION_OF_AGREEMENT_OF_PURCHASE_AND_SALE' ;
                break;
            //Different Charge types from Teranet Connect are mapped to Charge in Unity.
            case  'CHARGE (ASSUMED, AMENDED TERMS)' :
            case  'CHARGE' :
            case  'CHARGE (ASSUMED, EXISTING TERMS)' :
            case  'CHARGE (POWER OF SALE)' :
            case  'CHARGE BY PARTNERSHIP' :
            case  'CHARGE BY RELIGIOUS ORGANIZATION':
                financingTypeOption = 'CHARGE';
                break;
            default:
                financingTypeOption = instrumentType;
                break;
        }
        let financingTypeItemFound : SelectItem = provinceBasedFinancingTypeDropDown[provinceCode].find(item => item.value == financingTypeOption);
        return financingTypeItemFound ? financingTypeItemFound.label : '';
    }


}
