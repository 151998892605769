import {Injectable} from '@angular/core';
import {HttpClient} from '../core/httpClient.service';
import {Observable} from 'rxjs';
import {api} from '../common/api';
import {TitleInsuranceConfiguration} from './title-insurance-configuration';

@Injectable()
export class TitleInsuranceConfigurationService {

    constructor(private http: HttpClient) { }

    getTitleInsuranceConfiguration(accountId: string): Observable<TitleInsuranceConfiguration> {
        const url = `${api}/accounts/${accountId}/titleInsuranceConfigurations`;
        return this.http.get(url)
            .map((response) => {
                const titleInsuranceConfiguration = new TitleInsuranceConfiguration(response['TitleInsuranceConfig']);
                return titleInsuranceConfiguration;            });
    }

    updateTitleInsuranceConfiguration(accountId: string, configuration: TitleInsuranceConfiguration): Observable<TitleInsuranceConfiguration> {
        const url = `${api}/accounts/${accountId}/titleInsuranceConfigurations/${configuration.id}`;
        return this.http.put(url, configuration)
            .map((response) => {
                const titleInsuranceConfiguration = new TitleInsuranceConfiguration(response['TitleInsuranceConfig']);
                return titleInsuranceConfiguration;            });
    }

    createTitleInsuranceConfiguration(accountId: string, configuration: TitleInsuranceConfiguration): Observable<TitleInsuranceConfiguration> {
        const url = `${api}/accounts/${accountId}/titleInsuranceConfigurations`;
        return this.http.post(url, configuration)
            .map((response) => {
                const titleInsuranceConfiguration = new TitleInsuranceConfiguration(response['TitleInsuranceConfig']);
                return titleInsuranceConfiguration;
            });
    }
}
