import {MortgageInstructionDecorator} from './mortgage-instruction-decorator';
import {StewartMortgageInstruction} from '../../shared-main/telus/stewart-mortgage-instruction';
import {
    Milestone,
    StewartAssystDocument,
    StewartAssystMortgageInstructionData
} from '../../shared-main/telus/stewart-assyst-mortgage-instruction-data';

export class FctMortgageInstructionDecorator implements MortgageInstructionDecorator {
    formattedExpireTiming(mortgageInstruction : StewartMortgageInstruction) : string {
        return mortgageInstruction.expiresIn;
    }
    hasOnlyNewFileStatus(mortgageInstruction : StewartMortgageInstruction) : boolean {
        return true;
    }

    acceptedOnDate(mortgageInstructionData: StewartAssystMortgageInstructionData): string {
        if (mortgageInstructionData.fctStatusData && mortgageInstructionData.fctStatusData.milestones) {
            let milestone: Milestone = mortgageInstructionData.fctStatusData.milestones.find(value => value.id === "4");
            if (milestone) {
                return milestone.completedDateTime;
            }
        }
        return null;
    }

    isMortgageInstructionCancelled(mortgageInstructionData: StewartAssystMortgageInstructionData): boolean {
       return (mortgageInstructionData && mortgageInstructionData.dealStatus && mortgageInstructionData.dealStatus === 'CANCELLED');
    }

    getPrelim(mortgageInstructionData: StewartAssystMortgageInstructionData): string {
        if(mortgageInstructionData && mortgageInstructionData.rffRequired) {
            if (mortgageInstructionData.fctStatusData && mortgageInstructionData.fctStatusData.milestones) {
                let milestone: Milestone = mortgageInstructionData.fctStatusData.milestones.find(value => value.id === "6");
                return milestone && milestone.completedDateTime ? 'Yes' : 'No';
            }
        }
        return 'N/A';
    }

    getFinal(mortgageInstructionData: StewartAssystMortgageInstructionData): string {
        if (mortgageInstructionData && mortgageInstructionData.fctStatusData && mortgageInstructionData.fctStatusData.milestones) {
            let milestone: Milestone = mortgageInstructionData.fctStatusData.milestones.find(value => value.id === "11");
            return milestone && milestone.completedDateTime ? 'Yes' : 'No';
        }
        return 'No';
    }

    areDocumentsAvailableToDownload(mortgageInstruction: StewartMortgageInstruction): boolean {
        return (mortgageInstruction
            && mortgageInstruction.mortgageInstructionData
            && mortgageInstruction.mortgageInstructionData.fctStatusData
            && mortgageInstruction.mortgageInstructionData.fctStatusData.status === 'COMPLETED');
    }

    downloadableDocuments(mortgageInstructionData: StewartAssystMortgageInstructionData): StewartAssystDocument[] {
        return mortgageInstructionData.documents;
    }
}
