import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataTable, SortEvent} from '@pascalhonegger/ng-datatable';

/**
 * This is a modified version of
 *
 * https://github.com/mariuszfoltak/angular2-datatable/blob/master/src/DefaultSorter.ts
 *
 * that implements sorting arrows as required.
 */

@Component({
    selector: "dp-default-sorter",
    template: `
        <style>
            .default-sort {
                display: inline-block;
                position: absolute;
            }
            div.glyphicon {
                display: block;
            }
            .dont-sort{
                position:relative;
                top: -2px;
            }
            .dont-singular {
                        position: absolute !important;
                        margin-top: -13px;
                        margin-left: 0px;
                        top: auto;
            }
            .dont-sort span {display: inline !important;}
            .dont-sort b {
                font-size: 10px;
                margin-left: 2px;
            }
        </style>


            <div style="display: inline-block;" *ngIf="singleState">
            <ng-content></ng-content>
            <span *ngIf="isSortedByMeAsc && !isSortedByDefault &&  !defaultSort" class="glyphicon glyphicon-triangle-top" aria-hidden="true"></span>
            <span *ngIf="isSortedByMeDesc && ( !isSortedByDefault ||  !defaultSort )" class="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span>


            <span *ngIf="isSortedByDefault && defaultSort" class="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span>
            <span *ngIf="isSortedByMeAsc && defaultSort && !isSortedByDefault" class="glyphicon glyphicon-triangle-top" aria-hidden="true"></span>

            <span class="default-sort" *ngIf="!isSortedByMeAsc && !isSortedByMeDesc && !isSortedByDefault"></span>
           <div class="inline dont-sort dont-singular" *ngIf="!isSortedByMeAsc && !isSortedByMeDesc && !isSortedByDefault && defaultSort && active"><span class="glyphicon glyphicon-stop"
            aria-hidden="true"></span><b class="no-wrap position-absolute top-3">Not sorted</b></div>
            </div>


             <a style="cursor: pointer" (click)="sort()" class="text-nowrap"  *ngIf="!singleState">
                    <ng-content></ng-content>
                    <span *ngIf="isSortedByMeAsc && (!isSortedByDefault || !defaultSort)" class="glyphicon glyphicon-triangle-top" aria-hidden="true"></span>
                    <span *ngIf="isSortedByMeDesc && (!isSortedByDefault || !defaultSort)" class="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span>
                    <div class="inline dont-sort" *ngIf="isSortedByDefault && defaultSort && active"><span class="glyphicon glyphicon-stop"
                    aria-hidden="true"></span><b>Don't
                    sort</b></div>
                    <span class="default-sort" *ngIf="!isSortedByMeAsc && !isSortedByMeDesc && !isSortedByDefault">
                        <div class="glyphicon glyphicon-triangle-top" aria-hidden="true"></div>
                        <div class="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></div>
                    </span>
             </a>
      `

})
export class DpDefaultSorter implements OnInit {
    @Input("by") sortBy: string;
    // default sort for having 3rd option with sorting called "not sorted"
    @Input("defaultSort") defaultSort: string;
    // for showing only one sort option at a time
    @Input("singleState") singleState: boolean = false;
    @Input() sortExternal : boolean;
    @Output() sortOrder : EventEmitter<string> = new EventEmitter<string>();


    isSortedByMeAsc: boolean = false;
    isSortedByMeDesc: boolean = false;
    isSortedByDefault: boolean = false;



    public constructor(public mfTable: DataTable) {
    }

    public ngOnInit(): void {
        this.mfTable.onSortChange.subscribe((event: SortEvent) => {
            this.isSortedByMeAsc = (event.sortBy == this.sortBy && event.sortOrder == "asc");
            this.isSortedByMeDesc = (event.sortBy == this.sortBy && event.sortOrder == "desc");
            this.isSortedByDefault = (event.sortBy == this.sortBy && event.sortOrder == "desc");
           /* if(this.defaultSort!="") {
                this.isSortedByDefault = (event.sortBy == this.defaultSort && event.sortOrder == "asc");
            } else {
                this.isSortedByDefault = false;
            }*/
        });
    }

    static activeInstance: DpDefaultSorter;

    set active(isActive: boolean){
        DpDefaultSorter.activeInstance = isActive ? this : null;
    }

    get active() {
        return DpDefaultSorter.activeInstance == this;
    }

    customSort() : void {
        DpDefaultSorter.activeInstance = this;
        if (this.isSortedByMeAsc) {
            this.mfTable.setSort(this.sortBy, "desc");
            this.mfTable.setSort(this.sortBy, "asc");
        } else {
            this.mfTable.setSort(this.sortBy, "asc");
            this.mfTable.setSort(this.sortBy, "desc");

        }
    }

    sort( ) {
        DpDefaultSorter.activeInstance = this;
        if(this.sortExternal){
            if (this.isSortedByMeAsc) {
                this.sortOrder.emit("desc");
                this.isSortedByMeDesc = true;
                this.isSortedByMeAsc = false;
            } else {
                this.sortOrder.emit("asc")
                this.isSortedByMeDesc = false;
                this.isSortedByMeAsc = true;
            }
        }
        else if(this.defaultSort){

            if(this.isSortedByDefault)
            {
                this.mfTable.setSort(this.defaultSort, "asc");
            }
            else if (this.isSortedByMeAsc) {
                this.mfTable.setSort(this.sortBy, "desc");
            }
            else {
              this.mfTable.setSort(this.sortBy, "asc");
            }

        }else {
            if (this.isSortedByMeAsc) {
                this.mfTable.setSort(this.sortBy, "desc");
            } else {
                this.mfTable.setSort(this.sortBy, "asc");

            }

        }

    }
}
