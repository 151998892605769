import {api, SESSION_ID_REQ_PARAM} from '../../common/api';

export const userAccountProfilesApi = {

    userProfiles: `${api}/accounts/{accountId}/userProfiles`,

    statuses: `${api}/reference/userStatusTypes`,

    accessProfileTypes: `${api}/accounts/{accountId}/accessProfiles/profileTypes`,

    accessProfiles : `${api}/accounts/{accountId}/accessProfiles`,

    accessProfileUserProfiles : `${api}/accounts/{accountId}/accessProfiles/{accessProfileId}/userProfiles`,

    copyProfiles : `${api}/accounts/{accountId}/accessProfiles/{accessProfileId}/copyAccessProfile`,

    users : `${api}/accounts/{accountId}/users`,

    setupPassword : `${api}/register/setupPassword`,

    resetPassword : `${api}/register/resetPassword`,

    resendLoginLink : `${api}/register/accounts/`,

    unassignedContacts : `${api}/accounts/*/unassignedContacts`,

    accounts : `${api}/accounts/`,

    forgotPassword : `${api}/register/resetPasswordLink`,

    accessProfileStatusTypes : `${api}/reference/accessProfileStatusTypes`,

    permissionSets : `${api}/accounts/{accountId}/accessProfiles/permissionSets/{accessProfileType}`,

    staffProfiles: `${api}/accounts/{accountId}/staffProfiles/`,

    loggedInUsers: `${api}/accounts/{accountId}/loggedIn/staffProfiles`,

    logUserOut: `${api}/accounts/{accountId}/staffProfiles/{id}/logout`,

    loggedInStaffProfiles: `${api}/users/currentUser/myProfile`,

    credentials : `${api}/users/currentUser/credentials`,

    info: `${api}/users/currentUser/info`,

    loginId : `${api}/users/currentUser/loginId/`,

    updateLoginParams : `${api}/authorize/updateLoginParams`,

    userConfiguration: `${api}/users/currentUser/configuration`,

    getTermsAndConditions: `${api}/reference/termsAndConditions`,

    setupTermsAndConditions: `${api}/accounts/{accountId}/users/currentUser/termsAndConditions`,

    requestAuthChallenge: `${api}/users/currentUser/authChallenges`,

    verifyAuthChallenge: `${api}/users/currentUser/authChallenges/verify`,

    verifyAuthChallengeAndSave: `${api}/users/currentUser/authChallenges/update`,

    authPhone: `${api}/users/currentUser/authPhone/{authPhoneId}`,

    userByContactId: `${api}/accounts/{accountId}/contacts/{contactId}/user`,

    userMessages : `${api}/users/{userId}/messages`,

    userMessagesUpdate : `${api}/users/{userId}/messages/{id}`,

    sharedPackageFromUnityMessage : `${api}/users/{userId}/messages/{unityMessageId}/sharedDocumentsPackage`,

    getTimeRemaining: `${api}/users/currentUser/currentSession/getTimeRemaining`,

    relogin : `${api}/authorize/reLogin`,

    getSharedDocuments : `${api}/unity/sharedDocumentsPackages/`,

    downloadSharedDocument  :  `${api}/unity/sharedDocumentsPackages/{packageGuid}/documents/{documentId}/download`,

    getSharedDocPackageFirmLogo: `${api}/unity/sharedDocumentsPackages/{packageGuid}/files/{fileId}/download?${SESSION_ID_REQ_PARAM}={sessionId}`,

    declineSharedDocumentsPackage : `${api}/unity/sharedDocumentsPackages/{packageGuid}/decline`,

    reOpenSharedDocumentsPackage : `${api}/unity/sharedDocumentsPackages/{packageGuid}/reOpen`,

    processSharedDocumentsPackage : `${api}/unity/sharedDocumentsPackages/{packageGuid}/process`,

    acknowledgeDeclinedSharedDocumentsPackage : `${api}/unity/sharedDocumentsPackages/{packageGuid}/acknowledgeDecline`,

    setReceiveSharedDocumentsPackage : `${api}/unity/sharedDocumentsPackages/{packageGuid}/receive`,

    getAllStaffProfileContacts: `${api}/accounts/{accountId}/staffContacts`,

    getLawyerMove(contactId: number): string { return `${api}/dataMove/lawyer/${contactId}/tasks`; },

    //test code
    dataMoveTaskScheduler: `${api}/maintenance/dataMoveTaskScheduler`,
};
