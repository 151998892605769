import {Matter, MatterParticipant, User, Utils} from './index';
import {Utils as SharedUtils} from './utils';
import {dropDowns as dropDownOption} from '../mortgages/mortgage/dropdown-options';
import {DpBooleanValueTypes} from './dp-boolean';
import moment from 'moment';
import {TeranetDocket} from '../../shared-main/teranet/teranet-docket';
import {ERegStatus, MortgageAction} from '../../shared-main/constants';
import {Mortgage} from './mortgage';
import {MortgageSoAdjService} from '../../shared-main/mortgage-so-adj.service';
import {VendorsSolicitorComponent} from '../vendors-solicitor';
import {ContactInfo} from './contact-info';
import {ContactInfoInstanceTypes} from './contact-info-instance-types';
import {AddressTypes} from './address-types';
import {MatterTopic} from './matter-topic';
import {JournalNote, NotesList} from '../../admin/account-notes/account-notes';
import {SESSION_STORAGE_KEYS} from '../../shared';
import * as _ from 'lodash';
import {MatterTab} from '../matter-tab';
import {AuthorizationService} from '../../shared-main/authorization/authorization-service';
import {TabsService} from '../../core';
import {provinceSectionLabels, SubjectPropertyTitle} from '../../shared-main/province-based-dropdowns';
import {AUTH_OPERATION} from '../../shared-main/authorization/authorization-keys';
import {ApplicablePayoutInstitutions, PayoutStatement} from '../../shared-main/telus/payout-statement';
import {AppConfig} from '../../shared-main/app-configuration';

export class MatterUtil {

    static getFullName(contactName): string {
        // console.log('getFullName');
        let lName: string = contactName.lastName;
        let fName: string = contactName.firstName;
        let mName: string = contactName.middleName;
        let fullName: string;

        // format for the name: firstName middleName, lastName
        // if (fName && fName != null  && fName !== 'null') {
        // null is not recommanded in typescript
        if (fName) {
            fullName = fName;
        }

        if (mName) {
            if (fullName) {
                fullName = `${fullName} ${mName}`;
            } else {
                fullName = mName;
            }
        }

        if (lName &&  lName !== 'null')  {
            if (fullName) {
                fullName = `${fullName}, ${lName}`;
            } else {
                fullName = lName;
            }
        }
        return fullName;
    }

    static getOccupancyDate(matter: Matter): string {
        if(matter){
            if(matter.templateForProject){
                //for project, occupancyDate is same as Closing date
                return matter.matterCloseDate;
            }else{
                if(matter.isMatterProvinceAB){
                    return matter.occupancyDate;
                }else{
                    const isOccupancyDateInUse: boolean =
                        SharedUtils.showByProvinceAndMatterType('matter.matterOpening.occupancyDate', matter.provinceCode, matter.matterTypeCode);
                    return isOccupancyDateInUse ? matter.occupancyDate : matter.requisitionDate;
                }

            }
        }
        return undefined;
    }

    //convert the calculated field value from ThirdParty to Unity Mortgage -> term -> interest Calculated
    static getUnityInterestCalculatedFromThirdParty(interestCalculated: string) : string {
        if (interestCalculated && dropDownOption.calculated) {
            let calculatedDropDownOptions = dropDownOption.calculated;
            let calculationPeriod : any =  calculatedDropDownOptions.find(item => item.label && item.label.replace(/[, ]+/g,"")
                .trim().toUpperCase() == interestCalculated.replace(/[, ]+/g, "").trim().toUpperCase());
            return calculationPeriod ?  calculationPeriod.value : interestCalculated;
        }
        return "";
    }

    //convert the calculated field value from ThirdParty to Unity Mortgage -> term -> paymentFrequency
    static getUnityPaymentFrequencyFromThirdParty(paymentFrequency: string) : string {
        if (paymentFrequency && dropDownOption.paymentFrequency ) {
            let paymentFrequencyDropDownOptions = dropDownOption.paymentFrequency;
            //Remove - to do the compare to FCT and TELUS
            let paymentFrequencyPeriod : any =  paymentFrequencyDropDownOptions.find(item => item.label && item.label.replace(/[\x2D]+/g,"")
                .trim().toUpperCase() == paymentFrequency.replace(/[\x2D]+/g, "").trim().toUpperCase());
            return paymentFrequencyPeriod ?  paymentFrequencyPeriod.label : paymentFrequency;
        }
        return "";
    }

    static initializeCopiedMatter(targetMatter: Matter, resetMatterRecordNo: boolean) : Matter{
        targetMatter.billingTransactions = [];
        if(resetMatterRecordNo){
            targetMatter.matterRecordNumber = null;
            targetMatter.fileNumber = null;
            targetMatter.accountingNumber = null;
            targetMatter.teraviewDocketId = null;
            targetMatter.teranetDocket = new TeranetDocket();
        }
        targetMatter.matterStatus = 'DEFAULT_ACTIVE';
        targetMatter.reset = false;
        targetMatter.appointmentScheduledFlag = DpBooleanValueTypes.N_y;
        targetMatter.overrideLegalDescription = false;
        targetMatter.soaTrustLedgerCollection = null;
        targetMatter.secondarySoaSheetsCollection = [];
        targetMatter.lastUpdatedTimeStamp = null;
        targetMatter.updatedTimeStamp = null;
        targetMatter.updatedByUser = null;
        targetMatter.lockedByUser = null;
        targetMatter.tempIdForNewMatter = -(new Date()).getTime();
        targetMatter.fileOpenDate = moment(new Date()).format("YYYY/MM/DD");
        targetMatter.matterLink = null;
        //need to reset the copied eregistrationForm status, since we not copy the xml over to new matter,
        //so we reset the status to incomplete
        if(Array.isArray(targetMatter.eRegistrationForms) && targetMatter.eRegistrationForms.length > 0){
            targetMatter.eRegistrationForms.forEach(form => {
                form.eregistrationStatus = ERegStatus.EREG_INCOMPLETE;
            })
        }

        return targetMatter;

    }

    static reAssignPriorityPurchaseMatter(matter: Matter) : void {
        if(matter.isPurchase) {
            matter.mortgages.forEach((item , index) =>{
                item.mortgagePriority = Number(index) +1;
            });
        }
    }

    static updateMatterAfterChangingPriority(matter: Matter, deletedMortgagePriority: number, mortgageSoAdjService: MortgageSoAdjService, mortgage?: Mortgage) : void {
        matter.dirty = true;
        this.reAssignPriorityPurchaseMatter(matter);
        if(mortgage){
            matter.updateTitleInsurance(MortgageAction.DELETE, deletedMortgagePriority, mortgage);
        }
        mortgageSoAdjService.rearrangeAdjustments(matter);
        mortgageSoAdjService.updateStatementOfAdjustment(matter);
        matter.updateFirstMortgageRelatedFields();
        matter.updateMortgageRegistrationFee();
        if (matter.soaTrustLedgerCollection) {
            matter.soaTrustLedgerCollection.updateTrustLedgeMortgageLinesName();
        }
    }

    static updateOtherSideClientMatterFields(templateMatter: Matter, targetMatter: Matter, vendorsSolicitorComponent: VendorsSolicitorComponent, ignoreContactInfo?: boolean) {

        if (templateMatter && targetMatter) {
            this.updateOtherPartiesCapacity(templateMatter, targetMatter);
            if(!ignoreContactInfo){
                this.updateOtherPartyContactInfo(templateMatter, targetMatter, vendorsSolicitorComponent);
            }
        }
    }

    static updateOtherPartyContactInfo(templateMatter: Matter, targetMatter: Matter, vendorsSolicitorComponent: VendorsSolicitorComponent) {
        if (templateMatter.otherPartyContactInfo) {
            if (!targetMatter.otherPartyContactInfo) {
                const tmpContactInfo: ContactInfo = new ContactInfo();
                tmpContactInfo.contactInfoType = targetMatter.otherPartyContactInfoType;
                tmpContactInfo.instanceType = ContactInfoInstanceTypes.contactInfo;
                targetMatter.contactInfo.push(tmpContactInfo);
            }

            if (templateMatter.otherPartyContactInfo.reline) {
                targetMatter.otherPartyContactInfo.reline = templateMatter.otherPartyContactInfo.reline;
            }

            if (templateMatter.otherPartyContactInfo &&
                templateMatter.otherPartyContactInfo.residingAtSubjectProperty &&
                templateMatter.otherPartyContactInfo.residingAtSubjectProperty != DpBooleanValueTypes.Y_n) {
                targetMatter.otherPartyContactInfo.residingAtSubjectProperty = templateMatter.otherPartyContactInfo.residingAtSubjectProperty;
            }


            if (!Array.isArray(targetMatter.otherPartyContactInfo.addresses)) {
                targetMatter.otherPartyContactInfo.addresses = [];
            }
            let serviceAddress = targetMatter.otherPartyContactInfo.serviceAddress;
            if (templateMatter.otherPartyContactInfo.serviceAddress.addressLine1) {
                serviceAddress.addressLine1 = templateMatter.otherPartyContactInfo.serviceAddress.addressLine1;
            }
            if (templateMatter.otherPartyContactInfo.serviceAddress.addressLine2) {
                serviceAddress.addressLine2 = templateMatter.otherPartyContactInfo.serviceAddress.addressLine2;
            }
            if (templateMatter.otherPartyContactInfo.serviceAddress.city) {
                serviceAddress.city = templateMatter.otherPartyContactInfo.serviceAddress.city;
            }
            if (templateMatter.otherPartyContactInfo.serviceAddress.postalCode) {
                serviceAddress.postalCode = templateMatter.otherPartyContactInfo.serviceAddress.postalCode;
            }
            if (templateMatter.otherPartyContactInfo.serviceAddress.provinceName) {
                serviceAddress.provinceName = templateMatter.otherPartyContactInfo.serviceAddress.provinceName;
                serviceAddress.provinceCode = templateMatter.otherPartyContactInfo.serviceAddress.provinceCode;
            }
            if (templateMatter.otherPartyContactInfo.serviceAddress.country) {
                serviceAddress.country = templateMatter.otherPartyContactInfo.serviceAddress.country;
            }
            //AB only Field: if checkbox is checked, then do the propagation
            if (templateMatter.isMatterProvinceAB && templateMatter.outOfProvinceVendorExecDocsAt){
                targetMatter.outOfProvinceVendorExecDocsAt = templateMatter.outOfProvinceVendorExecDocsAt;
                vendorsSolicitorComponent.changeOutOfProvince();
            }
            serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
            serviceAddress.id = null;
            serviceAddress.setAddressHash();
            // targetMatter.otherPartyContactInfo.addresses.push(serviceAddress);


            if (templateMatter.vendorExecDocsAt) {
                targetMatter.vendorExecDocsAt = templateMatter.vendorExecDocsAt;
            }

            if (templateMatter.documentsExecutedAtJurisdictionId) {
                targetMatter.documentsExecutedAtJurisdictionId = templateMatter.documentsExecutedAtJurisdictionId;
            }

            if (templateMatter.docsExecutedOn) {
                targetMatter.docsExecutedOn = templateMatter.docsExecutedOn;
            }

            if (templateMatter.otherPartyContactInfo.dear) {
                targetMatter.otherPartyContactInfo.dear = templateMatter.otherPartyContactInfo.dear;
            }

            if (templateMatter.otherPartyContactInfo.fileNumber) {
                targetMatter.otherPartyContactInfo.fileNumber = templateMatter.otherPartyContactInfo.fileNumber;
            }

            if (templateMatter.otherPartyContactInfo.lawClerkFaxPhone) {
                targetMatter.otherPartyContactInfo.lawClerkFaxPhone = templateMatter.otherPartyContactInfo.lawClerkFaxPhone;
            }

            if (templateMatter.otherPartyContactInfo.lawClerkWorkPhone) {
                targetMatter.otherPartyContactInfo.lawClerkWorkPhone = templateMatter.otherPartyContactInfo.lawClerkWorkPhone;
            }

            if (templateMatter.otherPartyContactInfo.lawClerkCellPhone) {
                targetMatter.otherPartyContactInfo.lawClerkCellPhone = templateMatter.otherPartyContactInfo.lawClerkCellPhone;
            }

            if (templateMatter.otherPartyContactInfo.lawClerkEmail) {
                targetMatter.otherPartyContactInfo.lawClerkEmail = templateMatter.otherPartyContactInfo.lawClerkEmail;
            }

            if (templateMatter.otherPartyContactInfo.lawClerkId) {
                targetMatter.otherPartyContactInfo.lawClerkId = templateMatter.otherPartyContactInfo.lawClerkId;
            }

            if (templateMatter.otherPartyContactInfo.lawClerkName) {
                targetMatter.otherPartyContactInfo.lawClerkName = templateMatter.otherPartyContactInfo.lawClerkName;
                vendorsSolicitorComponent.selectedLawClerk = templateMatter.otherPartyContactInfo.lawClerkName;
            }
        }
    }


    static updateOtherPartiesCapacity(templateMatter: Matter, targetMatter: Matter) {

        if (templateMatter.otherPartiesCapacity != 'UNSPECIFIED_CAPACITY') {
            if (templateMatter.otherPartiesCapacity == 'JOINT_TENANTS'
                || templateMatter.otherPartiesCapacity == 'TENANTS_IN_COMMON_SPLIT_SHARES'
                || templateMatter.otherPartiesCapacity == 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES') {
                if (targetMatter.otherSideClients && targetMatter.otherSideClients.length > 1) {
                    targetMatter.otherPartiesCapacity = templateMatter.otherPartiesCapacity;
                }
            } else {
                if(templateMatter.otherPartiesCapacity){
                    targetMatter.otherPartiesCapacity = templateMatter.otherPartiesCapacity;
                }
            }
        }
    }

    static AddToTopicNotes(matter: Matter, matterTopicKey: string , notes) : void {
        if(notes){
            if(!matter.matterTopics){
                matter.matterTopics = [];
            }
            let matterTopic = matter.matterTopics.find(matterTopic=>matterTopic.matterTopicKey == matterTopicKey);
            if(!matterTopic){
                matterTopic = new MatterTopic();
                matterTopic.matterTopicKey = matterTopicKey;
                matterTopic.topicStatus = 'QUESTION';
                matterTopic.topicNote = '';
                matter.matterTopics.push(matterTopic);
            }
            if(!matterTopic.topicNote){
                matterTopic.topicNote = ''; // To avoid having 'null' at the start of the notes.
            }
            matterTopic.topicNote += notes
        }
    }

    static getNoOfUnitsLabel(matter: Matter): string{
        let ret: string  = '';
        if(matter && matter.matterPropertyWithCondo){
            ret = matter.matterPropertyWithCondo.titleInsurancePropertyType == 'ROOMING_STUDENT_HOUSING' ? 'No. of Rooms *' : 'No. of Units *';
        }

        return ret;
    }

    static addNote(matter: Matter, sessionStorage: Storage, noteData: JournalNote): User{
        let loggedInUser: User;
        if(sessionStorage) {
            loggedInUser = new User(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user)));
        }

        if(matter && noteData) {
            let jn: JournalNote = new JournalNote();
            jn.noteBody = noteData.noteBody;
            //save the time when we hit add new instead of the time when user save/close the note modal
            //and do not use field name createdTimeStamp: this is the internal field name for trackiing the DB creation time of the note record
            jn.userCreationTimestamp = noteData.userCreationTimestamp;
            jn.createdByUser = loggedInUser;
            if (!matter.notesList) {
                matter.notesList = new NotesList();
                matter.notesList.noteType = 'MATTER';
            }
            matter.notesList.notes.push(jn);
            matter.notesList.notes = _.orderBy(matter.notesList.notes, ['userCreationTimestamp'], ['desc']);
        }

        return loggedInUser;
    }

    static updateMatterReadyFlag(matter: Matter) : void {
        if(matter.isMatterClosed() || matter.isFileInactive() || matter.matterFlagged){
            matter.isMatterReady = false;
        }
    }

    static isMatterDisabled(matter: Matter, activeMatterTab: MatterTab, authorizationService: AuthorizationService, tabsService: TabsService) : boolean {
        return (activeMatterTab && activeMatterTab.isMatter() && this.isReadOnlyAccessToMatter(matter, authorizationService) )
            || (activeMatterTab && activeMatterTab.isOpportunityMatter() && this.isOpportunitiesReadOnlyAccessPermission(authorizationService))
            || tabsService.isLinkedMatterDirty()
            || this.isMatterProjectLocked(matter, tabsService)
            || (matter && (matter.hasBillingFailed() || matter.inaccessible || matter.locked || matter.refunded));
    }

    static isReadOnlyAccessToMatter(matter: Matter, authorizationService: AuthorizationService) : boolean {
        if(matter){
            if(matter.isProjectSale){
                return !authorizationService.hasFullAccessToProjectMatters();
            }else{
                return !authorizationService.hasFullAccessToConveyancingMatters();
            }
        }
        return true;
    }

    static isOpportunitiesReadOnlyAccessPermission(authorizationService: AuthorizationService): boolean {
        return authorizationService.isOpportunitiesReadOnlyAccess();
    }

    static isMatterProjectLocked(matter: Matter, tabsService: TabsService) : boolean {
        return tabsService.isMatterProjectLocked(matter);
    }

    static resetAllMortgageesMyClientFlag(matter: Matter) : void {
        matter.mortgages.forEach((mortgage)=>{
            matter.getMortgageeOrPrivateLender(mortgage).forEach((matterParticipant : MatterParticipant) => {
                if(matterParticipant) {
                    matterParticipant.myClient = false;
                }
            });
        });
    }

    static setMortgageeAsMyClient(mortgagee : MatterParticipant, matter: Matter) : void {
        if(mortgagee && !mortgagee.myClient) {
            this.resetAllMortgageesMyClientFlag(matter);
            mortgagee.myClient = true;
            matter.reCalculateMatterLenderReLine();
        }
    }


    static setFirstMortgageeMyClientFlag(matter: Matter) : void {
        if(matter.isMortgageMatterAndMortgageePrimary()){
            let mortgages : Mortgage[] = matter.isMatterTypeDischarge?matter.existingMortgages: matter.mortgages;
            for(let mortgage of mortgages) {
                let matterParticipants = matter.getMortgageeOrPrivateLender(mortgage);
                if(matterParticipants && matterParticipants.length){
                    this.setMortgageeAsMyClient(matterParticipants[0], matter);
                    break;
                }
            }
        }
    }

    static  getMaxMatterParticipantPriority(matter): number {
        let max: number = 0;
        let mainClients = matter ? matter.mainClients : [];
        mainClients.forEach(item=>{
            if(item.matterParticipantPriority >= 0 && item.matterParticipantPriority > max){
                max = item.matterParticipantPriority
            }
        })

        return max;
    }

    static getSubjectProperty(matter : Matter, title: string){
        if(matter) {
            if (matter.isOpportunityMatter()) {
                return title;
            } else if (matter.isTemplateMatterForMassUpdate && matter.isMatterProvinceON) {
                return SubjectPropertyTitle.Subject_Property;
            } else {
                return provinceSectionLabels.propertyTeranet[matter.provinceCode];
            }
        } else {
            return title;
        }

    }

    static  isPayOutVisible(matter : Matter, mortgage : Mortgage , payoutStatement : PayoutStatement, authorizationService : AuthorizationService ,appConfig  : AppConfig) : boolean {
        return this.isAssystPayoutApplicable(matter, mortgage, authorizationService,appConfig) && !this.isAssystPayouButtonDisabled(payoutStatement);
    }

   static  isAssystPayoutApplicable(matter : Matter, mortgage : Mortgage , authorizationService : AuthorizationService ,appConfig  : AppConfig): boolean {
        return !matter.isPurchase && mortgage.isExistingMortgage() && mortgage.isMortgageDispositionDischarged() && mortgage.isMortgageeAnInstitution()
            && this.isLendingInstitutionApplicable(matter, mortgage) && this.hasTelusAssystAccess(authorizationService) && appConfig.isAssystPayoutEnabled;
    }

   static hasTelusAssystAccess(authorizationService : AuthorizationService) : boolean {
        return authorizationService.hasAccess(AUTH_OPERATION.TELUS_ASSYST_INTEGRATIONS);
    }

    static isLendingInstitutionApplicable(matter : Matter ,  mortgage : Mortgage ): boolean{
        return  matter.matterParticipants.some(mp => mp.mortgageId && mp.mortgageId == mortgage.id
            && ApplicablePayoutInstitutions.some(applicableInstitution => applicableInstitution.institutionNo == mp.contact.institutionNo));
    }

    static isAssystPayouButtonDisabled(payoutStatement : PayoutStatement):boolean {
        return payoutStatement &&  !payoutStatement.isPayoutCancellationAllowed();
    }

    static isMatterEditPostClosingDatePassed(closingDate :string):boolean {
        let disablePostClosedMattersEdit : boolean =  sessionStorage.getItem(SESSION_STORAGE_KEYS.disablePostClosedMattersEdit) == 'true';
        if(!!disablePostClosedMattersEdit){
            let disableMatterEditAfterClosingDays = sessionStorage.getItem(SESSION_STORAGE_KEYS.disableMatterEditAfterClosingDays);
            let currentDate= moment().format('YYYY/MM/DD');
            let differenceInDays : number = Utils.getDateDiff(closingDate, currentDate);
            //let numberOfDays = (moment(this.interestCalculatedTo, "YYYY/MM/DD").diff(moment(this.interestCalculatedFrom, "YYYY/MM/DD"), 'years'));
            return disableMatterEditAfterClosingDays && differenceInDays >= Number(disableMatterEditAfterClosingDays);
        }
        return false;
    }

    static isMatterEditingDisabledAfterPostClosingDate(matter : Matter):boolean{
        return  matter && !matter.isCustomMatter()&& !matter.isProjectSale && !matter.isMatterTypeDischarge && matter.isMatterEditingDisabled && this.isMatterEditPostClosingDatePassed(matter.matterCloseDate)
    }

    static capitalizeWord(message: string): string {
        return _.capitalize(message);
    }
}

// To make more Tree shakable utility functions can be exported directly

/**
 * Checks if the value is an empty object, string, collection, map, or set.
 *
 * @param obj The value / Object to check
 * @returns {boolean} Returns `true` if `value` is empty, else `false`.
 */
export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;

/**
 * Uses Lodash isEqual function to performs a deep comparison between two values to determine if they are equivalent.
 * Object are compared by their own, not inherited, enumerable properties
 *
 * @param a Value to compare
 * @param b The other value to compare
 * @returns  true if the two values are equal, else false).
 */
export const isEqual =  (a: any,b: any) => _.isEqual(a,b)
