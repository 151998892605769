export class ConfigurationOption {
    id: number;
    fieldName: string;
    description: string;
    optionOrder: number;

    constructor(configurationOption: ConfigurationOption) {
        this.id = configurationOption.id;
        this.fieldName = configurationOption.fieldName;
        this.description = configurationOption.description;
        this.optionOrder = configurationOption.optionOrder;
    }

}
