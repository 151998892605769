import {AccountService} from '../../../../accounts/account.service';
import {DocumentProfileService} from '../../document-profile.service';
import {AppConfig} from '../../../../../shared-main/app-configuration';
import {DocumentProfileTemplateFolder} from '../document-profile-template-folder';
import {DialogRef} from 'ngx-modialog-7';
import {DialogService} from '../../../../../shared/dialog/dialog.service';
import {Component, OnInit} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DocumentTemplate, DocumentTemplateFile} from '../../../../../matters/document-production/document-template';
import {SESSION_STORAGE_KEYS} from '../../../../../shared/session-storage-keys';
import {ApplicationError} from '../../../../../core';
import {ImportTemplate} from '../../../../../matters/document-production/import-doprocess-template';
import {Account} from '../../../../accounts/shared/account';

export class ImportDoProcessTemplateConfirmationModalContext extends BSModalContext {
    targetAccountFileFolder: DocumentProfileTemplateFolder;
    selectedDocumentTemplateFiles : DocumentTemplateFile[];
    //existingDocumentTemplates : DocumentTemplateFile[];
    account : Account;
    existingTemplatesToCopy : DocumentTemplateFile[];
    importTemplate : ImportTemplate;


}

@Component({
    selector: 'import-doprocess-template-confirm-modal-content',
    templateUrl: './import-doprocess-template-confirm.modal.component.html'
})
export class ImportDoProcessTemplateConfirmationModalComponent implements OnInit {

    context: ImportDoProcessTemplateConfirmationModalContext;


    constructor(public dialog: DialogRef<ImportDoProcessTemplateConfirmationModalContext>,
                public documentProfileService: DocumentProfileService,
                public accountService: AccountService,
                public dialogService: DialogService,
                public appConfig: AppConfig) {
        this.context = dialog.context;

    }

    close() {
        this.dialog.close();
    }

    ngOnInit() {
    }


    get accountId(): string{
        return this.context.account ? this.context.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }



    get documentTemplateIds() : number[]{
        let documentTemplateIds = [];
        this.context.selectedDocumentTemplateFiles.map(documentTemplateFile => this.documentTemplateIds.push(documentTemplateFile.documentTemplate.docGenTemplateId));
        return documentTemplateIds;
    }

    copyTemplates(){
        this.documentProfileService.copyDocumentTemplatesToAccountFileFolder(this.accountId ,this.context.targetAccountFileFolder.id ,this.context.importTemplate)
            .subscribe((res: DocumentTemplate[]) => {
                this.close();
                },
                (error: ApplicationError) => {
                    //this.showTokenDialogBox(error.message);
                });
    }



}
