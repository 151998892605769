import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';

import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {Observable, Subject} from 'rxjs';
import {Matter} from '../matters/shared/matter';

import {ModalErrorComponent} from '../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../shared/error-handling/error-service';
import {MatterService} from '../matters/matter.service';
import {MatterProperty} from '../matters/shared/matter-property';
import {UserStateService} from '../shared-main/user-state/user-state.service';
import {ModalResult} from '../shared-main/enums';
import {DialogService} from '../shared/dialog/dialog.service';


export class LinkSharedDocumentsContext extends BSModalContext {

    sharedDocumentPackageId: number;
    matter : Matter;
    isNotificationMessage? : boolean;
}


@Component({
    selector: 'dp-link-shared-documents-modal',
    templateUrl: 'link-shared-documents-modal.component.html',
    styleUrls: [
        './link-shared-documents-modal.component.scss'
    ],
    providers   : [ErrorService]

})
export class LinkSharedDocumentsModalComponent implements OnInit, ModalComponent<LinkSharedDocumentsContext> {

    context: LinkSharedDocumentsContext;
    searchTermStreamMatter = new Subject<string>();
    matterSearchLoading = false;
    autoCompleteEmptyFlag: any;
    matterList: any;
    linkedMatter: Matter = new Matter();
    matterSerachText: any;
    userProvinces: string[] = [];
    @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

    constructor(public dialog: DialogRef<LinkSharedDocumentsContext> , public matterService: MatterService, public userStateService: UserStateService, public dialogService: DialogService, ) {
        this.context = dialog.context;
    }

    ngOnInit(): void {
        this.userProvinces = this.userStateService.getSortedEnabledUserProvinceCodes();

        // AutoComplete
        this.searchTermStreamMatter
            .switchMap((term: string) => {
                this.matterSearchLoading = true;
                if (term.trim() === '') {
                    this.autoCompleteEmptyFlag = true;
                    let observable = Observable.create((observer) => {
                        setTimeout(() => {
                            observer.next();
                        }, 10);
                    });

                    return observable;

                } else {
                    this.autoCompleteEmptyFlag = false;

                    return this.matterService.getMattersToLinkSharedDocuments(term, this.userProvinces);

                }
            })
            .subscribe(
                (matterList: Matter[]) => {
                    this.matterSearchLoading = false;
                    if (matterList) {
                        this.matterList = matterList.map(item => new Matter(item));
                    }

                },
                error => {
                    this.matterSearchLoading = false;
                }
            );

    }

    // Matter Search

    search(event): void {
        let entered: string = event.query;
        this.searchTermStreamMatter.next(entered);
    }

    dataSelectedMatter(matter: Matter): void {
        this.modalErrorComponent.removeAllDpSaveError();
        this.linkedMatter = new Matter(matter);
    }

    close(): void {
        this.dialog.close({action:  ModalResult.Cancel});
    }
    //In Progress
    linkMatter(): void{
        if (this.linkedMatter.locked) {
            let matterLockedByUserName = (this.linkedMatter.lockedByUser && this.linkedMatter.lockedByUser.firstName ? this.linkedMatter.lockedByUser.firstName : '') + " " + (this.linkedMatter.lockedByUser && this.linkedMatter.lockedByUser.lastName ? this.linkedMatter.lockedByUser.lastName : '');
            this.dialogService.confirm('Information', 'The selected matter is currently locked  by ' + matterLockedByUserName, true, 'OK').subscribe(res => {
                if (res) {
                    // Do Nothing
                }
            });
        }else{
            this.matterService.linkSharedDocumentsToMatter(this.context.sharedDocumentPackageId, this.linkedMatter.id).subscribe(() => {
                this.dialog.close({action: ModalResult.OK , matterLinked : this.linkedMatter});
            });
        }
    }

    removeSelectedMatter(): void {
        this.linkedMatter = new Matter();
        this.matterSerachText = '';
        this.modalErrorComponent.removeAllDpSaveError();
    }

    getMatterPropertyAddress(matterProperties: MatterProperty[]): string {
        let primaryAddress = '';
        if (matterProperties && matterProperties.length > 0) {
            let matterProperty = matterProperties[0];
            if (matterProperty && matterProperty.address.primaryAddress === true && matterProperty.address.addressHash != null) {
                primaryAddress = (matterProperty.address.addressText === null ? ' ' : matterProperty.address.addressText);
            }
        }
        return primaryAddress;
    }

}
