import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {dropDowns, matterApi, matterResponseKey} from '../matters/shared';
import {DropdownModel} from './dropdown-model';
import {HttpClient} from '../core';
import {Province, provinces} from './province';

@Injectable()
export class TypeCodesService {

    constructor(private httpClient: HttpClient) { }

    getGenders(): Observable<DropdownModel[]> {

        return this.httpClient
            .get(matterApi.genderCode)
            .map((response) => {
                return response[matterResponseKey.gender];
            })
            .map((codes: string[]) => {

                let genders: DropdownModel[] = [{ label: '??????', value: 'QUESTION' }];

                for (let i: number = 1; i < codes.length; i++) {
                    genders.push({ label: dropDowns.addClientGenderTypeLabel[codes[i]], value: codes[i] });
                }

                return genders;
            });
    }

    findProvinceByCodeOrName(query: string): Province {

        let found: Province = provinces.find((p: Province) => {
            return p.code.toLowerCase() === query.toLowerCase() || p.name.toLowerCase() === query.toLowerCase();
        });

        return found;
    }
}
