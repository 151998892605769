import {apiSpin, apiTeranet, SESSION_ID_REQ_PARAM} from '../../common/api';

export const teranetApi = {

    teranetSearchParcelRegister           : `${apiTeranet}/matters/{matterId}/properties/{propertyId}/searchParcelRegister` ,
    teranetSearchParcelRegisterByOwnerName: `${apiTeranet}/matters/{matterId}/properties/{propertyId}/searchByOwner`,

    teranetPurchaseParcelRegister   : `${apiTeranet}/matters/{matterId}/parcelRegisters/purchase`,
    teranetOrderInstrument          : `${apiTeranet}/matters/{matterId}/orderInstruments`,
    teranetOrderMap                 : `${apiTeranet}/matters/{matterId}/orderMap`,
    teranetViewMap                  : `${apiTeranet}/matters/{matterId}/documents/parcels/{parcelId}/maps/{mapId}/view?${SESSION_ID_REQ_PARAM}={sessionId}`,
    teranetGetMapDetails            : `${apiTeranet}/matters/{matterId}/parcels/{parcelId}/maps/{mapId}`,
    teranetRequestInstrument        : `${apiTeranet}/matters/{matterId}/exceptionInstruments/order`,
    teranetCourierInstrument        : `${apiTeranet}/matters/{matterId}/parcelRegisters/{parcelId}/courier`,
    teranetCheckStatusInstruments   : `${apiTeranet}/matters/{matterId}/parcelRegisters/{parcelId}/checkInstrumentStatus`,
    downloadTeranetDocument         : `${apiTeranet}/matters/{matterId}/documents/{documentId}/view?${SESSION_ID_REQ_PARAM}={sessionId}`,
    teranetAddInstrument            : `${apiTeranet}/matters/{matterId}/instruments`,
    writNumberSearch                : `${apiTeranet}/matters/{matterId}/writByNumber/order`,
    orderWrits                      : `${apiTeranet}/matters/{matterId}/writExecutions/order`,
    viewBillingInformation          : `${apiTeranet}/matters/{matterId}/unityBilling/transactions`,
    writRedoSearch                  : `${apiTeranet}/matters/{matterId}/orderWrits/redo`,
    writNameSearch                  : `${apiTeranet}/matters/{matterId}/orderWritsByName`,

    enforcementOffices              : `${apiTeranet}/users/{userId}/enforcementOffices`,
    teranetForgotPasswordLink       : `${apiTeranet}/users/forgotPasswordLink`,

    teranetUsers(userId): string { return `${apiTeranet}/users/${userId}/teranetUsers`; },
    teranetUserBalance(userId: string, teranetUserId: number)   : string {return `${apiTeranet}/users/${userId}/teranetUsers/${teranetUserId}/balance`; },
    teranetChangePassword(userId: string, teranetUserId: number): string {return `${apiTeranet}/users/${userId}/teranetUsers/${teranetUserId}/changePassword`; },


    teranetDockets(matterId: number)        : string { return `${apiTeranet}/matters/${matterId}/dockets`; } ,
    viewDocketSummary(matterId: number)     : string { return `${apiTeranet}/matters/${matterId}/dockets/docketSummary`; },
    teranetDocketSearch(matterId: number)   : string { return `${apiTeranet}/matters/${matterId}/dockets/search`; },

    spinDocket(matterId: number)        : string { return `${apiSpin}/matters/${matterId}/docket`; } ,

};

export enum TeranetRequestInstrumentSubmissionState {
    INSTRUMENT_FOUND = 0, //if instrument is found and no exception request needed to be submitted
    SUCCESS_1 = 1, //Exception request successfully submitted. Both SUCCESS_1 AND SUCCESS_2 are treated the same.
    SUCCESS_2 = 2, //Exception request successfully submitted. Both SUCCESS_1 AND SUCCESS_2 are treated the same.
    INVALID = -1,
    ZERO_PAGES = -2,
};

export const teranetConnectLinks = {
    fees: "http://www.teraview.ca/wp-content/uploads/2017/12/Transaction_Pricing_20171106.pdf",
    faq: "http://www.doprocess.com/pdfs/TeraConFaq.pdf",
    myTeranetConnect: "https://my.teranetconnect.ca"
};


