import Utils from '../../../shared-main/utils';
import {SoAdjTenancyPrepaid} from '../model/so-adj-tenancy-prepaid';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {SoAdjTenancyPrepaidUtil} from '../model/so-adj-tenancy-prepaid-util';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {InterestDateCalendarYearItem} from '../../../shared-main/interest-date-calendar-year-item';
import {provinceBasedFieldLabels} from '../../../shared-main/province-based-field-labels';

export class StatementAdjustmentDisplayTenancyPrepaidBuilder {

    static getDisplayItems(soaAdj: SoAdjTenancyPrepaid, closingDate: string, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder, taxRateType: string): StatementOfAdjustmentDisplayItemLine[] {
        soaAdj.provinceCode = mainBuilder.matter.provinceCode;
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        let isPaysForDateOfClosingVendor: boolean = mainBuilder.paysForDateOfClosing === 'VENDOR';
        let prePaidRentLabel = mainBuilder.matter && mainBuilder.matter.provinceCode ? provinceBasedFieldLabels.get('matter.soa.prePaidRent', mainBuilder.matter.provinceCode) : 'Pre-paid rent';

        if (!toBeUsedOnModal) {
            sadItemDetails.push(mainBuilder.getNewItemLine(`${prePaidRentLabel} amount`, mainBuilder.getCurrency(soaAdj.prepaidRentAmount)));
        }

        if (soaAdj.applyTaxBoolean) {

            if (soaAdj.taxType == taxRateType) {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `${taxRateType} calculated at ${mainBuilder.getPercentage(soaAdj.hstRate) }`,
                    mainBuilder.getCurrency(soaAdj.hstOrGstValueFromPrepaidRentAmount)
                ));
            }

            if(mainBuilder.matter && !mainBuilder.matter.isMatterProvinceAB){
                if (soaAdj.taxType == "GST") {
                    sadItemDetails.push(mainBuilder.getNewItemLine(
                        `GST calculated at ${mainBuilder.getPercentage(soaAdj.gstRate) }`,
                        mainBuilder.getCurrency(soaAdj.hstOrGstValueFromPrepaidRentAmount)
                    ));
                }
            }

            if (soaAdj.applyPstBoolean) {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `PST calculated at ${mainBuilder.getPercentage(soaAdj.pstRate) }`,
                    mainBuilder.getCurrency(soaAdj.pstValueFromPrepaidRentAmount)
                ));
            }
        }

        if (soaAdj.receiveCreditWithInterestBoolean){
            if (soaAdj.performInterestCalculationBoolean){

                if (soaAdj.calculationMethod == 'FLAT_INTEREST_RATE'){
                    let calculatedInterestAmount: number = 0;
                    let items : InterestDateCalendarYearItem[] = SoAdjTenancyPrepaidUtil.calculateRatesForFlatInterestRate(soaAdj.interestCalculatedFromDate, closingDate, soaAdj.interestRate, soaAdj.prepaidRentAmount,isPaysForDateOfClosingVendor);
                    items.forEach( itm => calculatedInterestAmount += itm.rateAmount);

                    sadItemDetails.push(mainBuilder.getNewItemLine(
                        `Interest at ${mainBuilder.getPercentage(soaAdj.interestRate)} calculated`,
                        ''
                    ));

                    sadItemDetails.push(mainBuilder.getNewItemLine(
                        `from ${ Utils.isNotValidDate(soaAdj.interestCalculatedFromDate) ? mainBuilder.invalidDate : mainBuilder.getDate(soaAdj.interestCalculatedFromDate)} to`,
                        ''
                    ));

                    sadItemDetails.push(mainBuilder.getNewItemLine(
                        `${mainBuilder.getDate(closingDate)} (${soaAdj.getNumberOfDaysElapsed(closingDate, isPaysForDateOfClosingVendor)} days)`,
                        Utils.isNotValidDate(soaAdj.interestCalculatedFromDate) ? mainBuilder.cannotCalculateAmount : mainBuilder.getCurrency(calculatedInterestAmount),
                        undefined, '10140.TENANCY_PREPAID.accruedInterest'
                    ));

                }else{

                    // use Ontario Residential Tenancies Act
                    let items : InterestDateCalendarYearItem[] = SoAdjTenancyPrepaidUtil.calculateRatesBasedOnOntarioResidentialTenanciesAct(soaAdj.interestCalculatedFromDate, closingDate,
                        soaAdj.prepaidRentAmount, mainBuilder.rentInterestRates, isPaysForDateOfClosingVendor);
                    let calculatedInterestAmount: number = 0;
                    items.forEach( itm => calculatedInterestAmount += itm.rateAmount);

                    // for some reason the details are different on the modal screen than on statement of adjustment
                    if (toBeUsedOnModal || soaAdj.printingFormat == 'FINAL_AMOUNT_ONLY'){
                        sadItemDetails.push(mainBuilder.getNewItemLine(
                            `Accrued interest`,
                            ''
                        ));

                        sadItemDetails.push(mainBuilder.getNewItemLine(
                            `from ${ Utils.isNotValidDate(soaAdj.interestCalculatedFromDate) ? mainBuilder.invalidDate : mainBuilder.getDate(soaAdj.interestCalculatedFromDate)} to`,
                            ''
                        ));

                        let accruedInterest = Utils.isNotValidDate(soaAdj.interestCalculatedFromDate) ? mainBuilder.cannotCalculateAmount : mainBuilder.getCurrency(calculatedInterestAmount);
                        sadItemDetails.push(mainBuilder.getNewItemLine(
                            `${mainBuilder.getDate(closingDate)} (${soaAdj.getNumberOfDaysElapsed(closingDate, isPaysForDateOfClosingVendor)} days)`, accruedInterest,
                            undefined, '10140.TENANCY_PREPAID.accruedInterest'
                        ));
                    }
                    else{
                        // items should start with last period as first item
                        let itemizedCounter: number = 0;
                        items.reverse().forEach( itm => {
                            sadItemDetails.push(mainBuilder.getNewItemLine(
                                // Jan 1/18-Dec 31/18: 999 days @ 1.23%  ---> no space between dates (probably to make it fit on SOA preview)
                                `${mainBuilder.shortFormattedDate(itm.fromDate)}-${mainBuilder.shortFormattedDate(itm.toDate)}: ${itm.numberOfDays} days @ ${mainBuilder.getPercentage(itm.rate)}`,
                                mainBuilder.getCurrency(itm.rateAmount),
                                undefined, '10140.TENANCY_PREPAID.accruedInterest' + itemizedCounter++
                            ));
                        });
                    }
                }
            }
            else{
                if (Utils.isNotValidDate(soaAdj.interestCalculatedFromDate)) {
                    sadItemDetails.push(mainBuilder.getNewItemLine( soaAdj.interestRate && soaAdj.interestRate > 0 ?
                        `Accrued interest at ${mainBuilder.getPercentage(soaAdj.interestRate)}` : `Accrued interest`
                        ,  mainBuilder.getCurrency(soaAdj.interestAmount)
                    ));
                } else{

                    sadItemDetails.push(mainBuilder.getNewItemLine(
                        soaAdj.interestRate && soaAdj.interestRate > 0 ?
                            `Accrued interest at ${mainBuilder.getPercentage(soaAdj.interestRate)}` : `Accrued interest`
                        ,''
                    ));

                    sadItemDetails.push(mainBuilder.getNewItemLine(
                        `from ${mainBuilder.getDate(soaAdj.interestCalculatedFromDate)} to`,
                        ''
                    ));

                    sadItemDetails.push(mainBuilder.getNewItemLine(
                        `${mainBuilder.getDate(closingDate)} (${soaAdj.getNumberOfDaysElapsed(closingDate,isPaysForDateOfClosingVendor)} days)`,
                        mainBuilder.getCurrency(soaAdj.interestAmount),
                        undefined, '10140.TENANCY_PREPAID.accruedInterest'
                    ));
                }
            }
        }

        sadItemDetails.push(mainBuilder.getNewItemLine(
            `Credit ${mainBuilder.purchaserLabel}`,
            ''
        ));
        return sadItemDetails;
    }
}
