import {Matter} from '../../shared';
import {MatterParticipant} from '../../shared/matter-participant';

export class ERegistrationJson {

    isInstrumentExsMortgage : boolean;
    instrumentNo : string;
    documentType : string;
    documentName : string;
    actingFor : string;
    feePayment : string;
    statement10 : boolean;
    statement29 : boolean;
    partyToAddressForService : ParticipantInfo[]=[];
    matterPropertiesInfo : MatterPropertyInfo[] = [];
    mortgageInfo: MortgageInfo;
    mortgageRegistrationNo: string;
    overrideMortgageRegistrationNo: boolean;
    propertyPortion : string;


    constructor(eRegContent? : ERegistrationJson) {
        if(eRegContent) {
            for(let prop in eRegContent) {
                if(eRegContent.hasOwnProperty(prop)) {
                    this[prop] = eRegContent[prop];
                }
            }
            this.partyToAddressForService  = [];
            if(Array.isArray(eRegContent.partyToAddressForService)) {
                for(let i : number = 0; i < eRegContent.partyToAddressForService.length; i++) {
                    this.partyToAddressForService[i] = new ParticipantInfo(eRegContent.partyToAddressForService[i]);
                }
            }
            this.matterPropertiesInfo  = [];
            if(Array.isArray(eRegContent.matterPropertiesInfo)) {
                for(let i : number = 0; i < eRegContent.matterPropertiesInfo.length; i++) {
                    this.matterPropertiesInfo[i] = new MatterPropertyInfo(eRegContent.matterPropertiesInfo[i]);
                }
            }
            if(eRegContent.mortgageInfo)
            {
                this.mortgageInfo = new MortgageInfo(eRegContent.mortgageInfo);
            }

        }
        else {
            this.isInstrumentExsMortgage = true;
        }
    }

    //this method is to make sure the partyToAddressForService only refer to the valid matterParticipant
    //since before when we delete the existing matterParticipant from unity, we didn't remove the corresponding partyToAddressForService record
    cleanupParticipantInfo(matter: Matter){
        if(matter && Array.isArray(matter.matterParticipants) && matter.matterParticipants.length > 0){
            const mpIds: number[] = matter.matterParticipants.map((mp: MatterParticipant) => mp.matterParticipantId)
            this.partyToAddressForService = this.partyToAddressForService.filter(info => mpIds.findIndex(id => id == info.participantId) > -1);
        }else{
            this.partyToAddressForService = [];
        }
    }

    isDocumentTypePowerOfSale() : boolean {
        return (this.documentType == 'TRANSFER_POWER_SALE')
    }

    isDocumentTypeNoticeOfChargeOfLease() : boolean {
        return (this.documentType == 'NOTICE_OF_CHARGE')
    }

    isDocumentTypeApplicationDeleteHousingDevelopmentLien() : boolean {
        return (this.documentType == 'APPLICATION_DELETE')
    }

    isDocumentTypeDischargeOfCondo() : boolean {
        return (this.documentType == 'DISCHARGE_OF_CONDO')
    }

}

export class ParticipantInfo {
    participantId : number
    isAddressOverridden : boolean;

    public _addressForService : string;

    constructor(participantAddress? : ParticipantInfo) {
        if(participantAddress) {
            for(let prop in participantAddress) {
                if(participantAddress.hasOwnProperty(prop)) {
                    this[prop] = participantAddress[prop];
                }
            }
        }
    }

    get addressForService(): string {
        return this._addressForService;
    }

    set addressForService(value: string) {
        this._addressForService = value;
        if(this._addressForService){
            this._addressForService = this._addressForService;
        }
    }
}

export class MortgageInfo {

    mortgageId : number;
    payment:string;
    interestRate: string;
    registeredInterest: string;
    calculationPeriod : string;
    paymentDate : string;
    balanceDueDate: string;
    interestAdjDate:string;
    firstPaymentDate:string;
    lastPaymentDate:string;
    additionalProvisions: string;

    paymentDueState:string;
    interestAdjDateState: string;
    firstPaymentDateState:string;
    lastPaymentDateState:string;
    interestRateState: string;
    calculationPeriodState : string;
    paymentDateState:string;
    balanceDueDateState:string;

    constructor(mortgageInfo? : MortgageInfo) {
        if(mortgageInfo) {
            for(let prop in mortgageInfo) {
                if(mortgageInfo.hasOwnProperty(prop)) {
                    this[prop] = mortgageInfo[prop];
                }
            }

        }

    }


}


export class MatterPropertyInfo {
    clientSideIdentifier : number;
    pin: string;
    intresetEstate : string;
    isLegalDescriptionEnabled : boolean;
    acceptLegalDescription : boolean;
    legalDescription : string;
    acceptAddress : boolean;
    streetNo : string;
    streetName : string;
    streetNoSuffix : string;


    constructor(matterPropertyInfo? : MatterPropertyInfo) {
        if(matterPropertyInfo) {
            for(let prop in matterPropertyInfo) {
                if(matterPropertyInfo.hasOwnProperty(prop)) {
                    this[prop] = matterPropertyInfo[prop];
                }
            }

        }
    }

    get address() : string {
        let addressStr : string[] = [];
        if(this.streetNoSuffix)
        {
            addressStr.push(this.streetNoSuffix)
        }
        if(this.streetNo)
        {
            addressStr.push(this.streetNo)
        }
        if(this.streetName)
        {
            addressStr.push(this.streetName)
        }
        return addressStr.join(', ').replace(',,','');
    }
}
