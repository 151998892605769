import {ProgressionStatus, StatementAdjustment, StatementAdjustmentOrder} from '../../statement-adjustment/statement-adjustment';
import Utils from '../../../shared-main/utils';
import {Matter} from '../matter';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {DPError} from '../../../shared/error-handling/dp-error';
import {
    StatementAdjustmentAmountTypes,
    StatementAdjustmentConstants,
    StatementAdjustmentHSTTypes,
    StatementAdjustmentKey
} from '../../statement-adjustment/statement-adjustment-constants';
import {StatementAdjustmentUtil} from '../../statement-adjustment/statement-adjustment-util';
import {UUIDUtil} from '../../../main/uuid-util';
import {DocumentProfile} from '../../../admin/document-profile/document-profile';
import {StatementOfAdjustmentHeading} from '../../statement-adjustment/model/statement-adjustment-heading';
import {creditVendorTaxRebateShowTypes, soAdjProjectFooters} from '../../statement-adjustment/model/so-adj-drop-downs';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {Tax_RATE} from '../../../shared-main/province-based-dropdowns';
import {MatterTax} from '../property-taxes';
import {provinceBasedFieldLabels} from '../../../shared-main/province-based-field-labels';
import {ProjectTaxAdjustmentConfig} from '../../../projects/project-adjustments/project-tax-adjustment-config';
import {InterimTaxMultiplier} from '../../../shared-main/constants';
import {MatterService} from '../../matter.service';
import {HstSalePrice} from '../../statement-adjustment/model/hst-sale-price-soa';
import {SoAdjTenancyPrepaid, StatementOfAdjustmentPayable} from '../../statement-adjustment/model';
import {ParcelLegalDescription} from '../parcel-legal-description';
import {MatterProperty} from '../matter-property';
import {SoaCommonExpense} from '../../statement-adjustment/model/common-expense-soa';
import {SalePriceAdjustment} from '../../statement-adjustment/sale-price-adjustment/sale-price-adjustment';
import {StatementAdjustmentDisplayBuilder} from '../../statement-adjustment/builders/statement-adjustment-display-builder';
import {SoaRealtyTaxAdjustmentUtil} from '../../statement-adjustment/modals/realty-tax/soa-realty-tax-adjustment-util';
import {DpBooleanValueTypes} from '../dp-boolean';
import {ConsiderationLtt} from '../../consideration-ltt/consideration-ltt';
import {SESSION_STORAGE_KEYS} from '../../../shared';
import {StatementAdjustmentConfig} from '../../../admin/statement-adjustment/statement-adjustment-config';
import {StatementConfigService} from '../../../admin/shared/statement-config.service';
import { TaxRateService } from '../../consideration-ltt/tax-rate.service';

export class MatterStatementAdjustmentUtil {

    static isItemOutOfRange(matter: Matter, soaItem: StatementAdjustment): boolean {
        if (matter && soaItem) {
            let closingDate = soaItem.isAdjustmentStatusFinal() ? matter.getClosingDateForFinalAdj() : matter.getClosingDateForInterimAdj();
            if (soaItem.isInterest() && soaItem.soAdjInterestCal && !soaItem.soAdjInterestCal.isValidAdjustmentDate(closingDate, matter)) {
                soaItem.isOutOfRange = true;
                soaItem.outOfRangeAdjustmentName = "Interest Calculation Adjustment";
                return true;
            }
            if (soaItem.isInterestOnLumpSum() && soaItem.soAdjInterestCal && (!soaItem.soAdjInterestCal.interestCalculatedFrom || !soaItem.soAdjInterestCal.interestCalculatedTo || !soaItem.soAdjInterestCal.isValidAdjustmentDate(closingDate, matter))) {
                soaItem.isOutOfRange = true;
                soaItem.outOfRangeAdjustmentName = "Interest Calculation Adjustment";
                return true;
            }
            if (this.isValidAdjustmentMode(matter,soaItem) && soaItem.isInterestOnDeferredMoniesDuringOccupancy() && soaItem.soAdjInterestCal && !soaItem.soAdjInterestCal.isValidAdjustmentDateForDeferredMonies(closingDate)) {
                soaItem.isOutOfRange = true;
                soaItem.outOfRangeAdjustmentName = "Interest on Deferred Monies During Occupancy Adjustment";
                return true;
            }
            if (soaItem.isTotalDuringOccupancyPeriodAdjustment() && soaItem.soAdjExpenseOccupancyPeriod && soaItem.soAdjExpenseOccupancyPeriod.isOutOfRange(closingDate, matter.soAdjExpenseOccupancyPeriodOccupancyDate)) {
                soaItem.isOutOfRange = true;
                soaItem.outOfRangeAdjustmentName = soaItem.soAdjExpenseOccupancyPeriod.adjustmentHeading;
                return true;
            }
            if (soaItem.isWater() && soaItem.soaWater && !soaItem.soaWater.isValidAdjustmentDate(closingDate)) {
                soaItem.isOutOfRange = true;
                soaItem.outOfRangeAdjustmentName = "Water Adjustment";
                return true;
            }
            if (soaItem.isOtherProrated() && soaItem.soAdjOtherProrated &&
                (
                    (soaItem.soAdjOtherProrated.getDateDiff(closingDate, soaItem.soAdjOtherProrated.commencementDate) > 0) ||
                    (soaItem.soAdjOtherProrated.getDateDiff(soaItem.soAdjOtherProrated.expiryDate, closingDate) > 0) ||
                    (soaItem.soAdjOtherProrated.isNotValidDate(closingDate)))) {
                soaItem.isOutOfRange = true;
                soaItem.outOfRangeAdjustmentName = "Other Prorated Adjustment";
                return true;
            }
            if (soaItem.isHomeOwnersResidentFee() && soaItem.soAdjHomeOwnersResidentFee &&
                (
                    (soaItem.soAdjHomeOwnersResidentFee.getDateDiff(closingDate, soaItem.soAdjHomeOwnersResidentFee.commencementDate) > 0) ||
                    ((matter.isPaysForDateOfClosingPurchaser && soaItem.soAdjHomeOwnersResidentFee.getDateDiff(soaItem.soAdjHomeOwnersResidentFee.expiryDate, closingDate) > 1)
                        || (matter.isPaysForDateOfClosingVendor && soaItem.soAdjHomeOwnersResidentFee.getDateDiff(soaItem.soAdjHomeOwnersResidentFee.expiryDate, closingDate) > 0)) ||
                    (soaItem.soAdjHomeOwnersResidentFee.isNotValidDate(closingDate)))) {
                soaItem.isOutOfRange = true;
                soaItem.outOfRangeAdjustmentName = "Homeowners Resident's Fee";
                return true;
            }
            if (soaItem.isTenancyCurrent() && soaItem.soAdjTenancyCurrent &&
                (
                    (soaItem.soAdjTenancyCurrent.getDateDiff(closingDate, soaItem.soAdjTenancyCurrent.commencementDate) > 0) ||
                    (soaItem.soAdjTenancyCurrent.getDateDiff(soaItem.soAdjTenancyCurrent.endDate, closingDate) > 0) ||
                    (soaItem.soAdjTenancyCurrent.isNotValidDate(closingDate)))) {
                soaItem.isOutOfRange = true;
                soaItem.outOfRangeAdjustmentName = "Tenancy Adjustment";
                return true;
            }
            if (soaItem.isTenancyPrepaid() && soaItem.soAdjTenancyPrepaid) {

                let validFromDate: boolean = !Utils.isNotValidDate(soaItem.soAdjTenancyPrepaid.interestCalculatedFromDate);
                let dateDiff: number = Utils.getDateDiff(closingDate, soaItem.soAdjTenancyPrepaid.interestCalculatedFromDate);
                let outOfRange: boolean = false;

                if (soaItem.soAdjTenancyPrepaid.receiveCreditWithInterestBoolean) {

                    if (soaItem.soAdjTenancyPrepaid.performInterestCalculationBoolean) {
                        outOfRange = (dateDiff >= 0 || !validFromDate);
                    } else {
                        outOfRange = (validFromDate && dateDiff >= 0);
                    }
                    if (outOfRange) {
                        soaItem.isOutOfRange = true;
                        soaItem.outOfRangeAdjustmentName = "Tenancy Adjustment";
                        return true;
                    }
                }
            }
            if (soaItem.isAssumedMortgage() && soaItem.soAdjAssumedMortgage &&
                (
                    soaItem.soAdjAssumedMortgage.combinedOrSeparate == 'SEPARATE' &&
                    (Utils.getDateDiff(closingDate, soaItem.soAdjAssumedMortgage.principalAsAtDate) >= 0 ||
                        Utils.isNotValidDate(closingDate)))) {
                soaItem.isOutOfRange = true;
                soaItem.outOfRangeAdjustmentName = "Assumed Mortgage";
                return true;
            }
            if (soaItem.isFinalOccupancyFee()) {
                if ((Utils.isNotValidDate(matter.getClosingDateForFinalAdj())) ||
                    (Utils.isNotValidDate(matter.getClosingDateForInterimAdj()) && soaItem.soAdjFinalOccupancyFee && soaItem.soAdjFinalOccupancyFee.areCalculationsDependentOnInterimAdjustAsDate())) {
                    soaItem.outOfRangeAdjustmentName = soaItem.description;
                    soaItem.isOutOfRange = true;
                    return true;
                }
            }
            if (soaItem.isFinalEarlyPossessionFee()) {
                if ((Utils.isNotValidDate(matter.getClosingDateForFinalAdj())) ||
                    (Utils.isNotValidDate(matter.getClosingDateForInterimAdj()) && soaItem.soAdjFinalEarlyPossessionFee && soaItem.soAdjFinalEarlyPossessionFee.areCalculationsDependentOnInterimAdjustAsDate())) {
                    soaItem.outOfRangeAdjustmentName = soaItem.description;
                    soaItem.isOutOfRange = true;
                    return true;
                }
            }
            if (soaItem.isTaxesBeginningOfYearUntilOccupancy()) {
                if (matter.isMatterProvinceAB) {
                    if (Utils.isNotValidDate(matter.occupancyDate) && matter.isProjectSale) {
                        soaItem.outOfRangeAdjustmentName = soaItem.description;
                        soaItem.isOutOfRange = true;
                        return true;
                    }
                } else {
                    if (Utils.isNotValidDate(matter.requisitionDate) && matter.isProjectSale) {
                        soaItem.outOfRangeAdjustmentName = soaItem.description;
                        soaItem.isOutOfRange = true;
                        return true;
                    }
                }

            }
            if (soaItem.isInterestOnDepositCondo() && soaItem.soAdjInterestOnDepositCondo) {
                if (soaItem.soAdjInterestOnDepositCondo.isDepositDateInValid(matter, soaItem.isAdjustmentStatusFinal())) {
                    soaItem.outOfRangeAdjustmentName = soaItem.description;
                    soaItem.isOutOfRange = true;
                    return true;
                }
            }

            if (soaItem.isInterestOnDepositInterestCondo() && soaItem.soAdjInterestOnDepositCondo) {
                if (soaItem.soAdjInterestOnDepositCondo.isInterestOnDepositInterestOutOfRange(matter, soaItem.isAdjustmentStatusFinal())) {
                    soaItem.outOfRangeAdjustmentName = soaItem.description;
                    soaItem.isOutOfRange = true;
                    return true;
                }
            }

            if (soaItem.isTotalOccupancyFee() && soaItem.soAdjTotalOccupancyFee) {
                if (soaItem.soAdjTotalOccupancyFee.isOutOfRange()) {
                    soaItem.outOfRangeAdjustmentName = soaItem.description;
                    soaItem.isOutOfRange = true;
                    return true;
                }
            }
            soaItem.isOutOfRange = false;
        }
        return false;
    }

    static isValidAdjustmentMode(matter: Matter , soaItem:StatementAdjustment ): boolean{
        return matter.adjustmentStatusMode == soaItem.adjustmentStatus;
    }

    static checkOutOfRangeWarning(matter: Matter, errorService: ErrorService) {

        if (matter && matter.allStatementAdjustments && errorService) {
            let nonFocusedSheetAdjustments = matter.isSelectedAdjustmentStatusModeFinal ? matter.interimStatementAdjustments : matter.finalStatementAdjustments;
                nonFocusedSheetAdjustments.forEach(item => {
                    let adjustmentName = item.outOfRangeAdjustmentName;
                    let error = errorService.getDPError(adjustmentName + "-OutOfRangeKey."+item.adjustmentStatus);
                    if (error) {
                        errorService.removeDpFieldError(adjustmentName + "-OutOfRangeKey."+item.adjustmentStatus);
                    }
                });

            let focusedSheetAdjustments = matter.isSelectedAdjustmentStatusModeFinal ? matter.finalStatementAdjustments : matter.interimStatementAdjustments;
            focusedSheetAdjustments.forEach(item => {
                let initValue = item.isOutOfRange;
                let adjustmentName = item.outOfRangeAdjustmentName;
                if (!initValue && MatterStatementAdjustmentUtil.isItemOutOfRange(matter, item) && !!adjustmentName) {
                    errorService.addDpFieldError(DPError.createCustomDPError(adjustmentName + "-OutOfRangeKey."+item.adjustmentStatus, adjustmentName + " is out of range", 'Statement of Adjustments', 'WARNING'));
                } else if (initValue && !MatterStatementAdjustmentUtil.isItemOutOfRange(matter, item) && !!adjustmentName) {
                    errorService.removeDpFieldError(adjustmentName + "-OutOfRangeKey."+item.adjustmentStatus);
                } else if (initValue && MatterStatementAdjustmentUtil.isItemOutOfRange(matter, item) && !!adjustmentName) {
                    let error = errorService.getDPError(adjustmentName + "-OutOfRangeKey."+item.adjustmentStatus);
                    if (!error) {
                        errorService.addDpFieldError(DPError.createCustomDPError(adjustmentName + "-OutOfRangeKey."+item.adjustmentStatus, adjustmentName + " is out of range", 'Statement of Adjustments', 'WARNING'));
                    }
                } else if (!initValue && !MatterStatementAdjustmentUtil.isItemOutOfRange(matter, item) && !!adjustmentName) {
                    let error = errorService.getDPError(adjustmentName + "-OutOfRangeKey."+item.adjustmentStatus);
                    if (error) {
                        errorService.removeDpFieldError(adjustmentName + "-OutOfRangeKey."+item.adjustmentStatus);
                    }
                }
            });

        }
    }

    static  createStatementAdjustmentOrder(matter: Matter, adjustment: StatementAdjustment, selectedStatementAdjustmentOrderObject?: StatementAdjustmentOrder): void {
        if (matter) {
            let statementAdjustmentOrders = adjustment.isAdjustmentStatusFinal() ? matter.finalStatementAdjustmentOrders : matter.interimStatementAdjustmentOrders;
            let exisitingStatementAdjustmentOrderIndex = statementAdjustmentOrders.findIndex(soaOrder => soaOrder.adjustmentId == adjustment.id);
            if (exisitingStatementAdjustmentOrderIndex > -1) {
                statementAdjustmentOrders.splice(exisitingStatementAdjustmentOrderIndex, 1);
            }
            if (selectedStatementAdjustmentOrderObject && selectedStatementAdjustmentOrderObject.adjustmentIndex > -1) {
                // once we find parent object we need update all below adjustment index ;


                let updateOrderAjustments = statementAdjustmentOrders.filter(item => item.adjustmentIndex > selectedStatementAdjustmentOrderObject.adjustmentIndex).sort((a, b) => Number(a.adjustmentIndex) < Number(b.adjustmentIndex) ? -1 : Number(a.adjustmentIndex) > Number(b.adjustmentIndex) ? 1 : 0);
                if (updateOrderAjustments && updateOrderAjustments.length > 0) {
                    updateOrderAjustments.map(item => {
                        item.adjustmentIndex = item.adjustmentIndex + 1;
                    });
                }
                // After index are update we can created a new order for this un applied adjustment and push in order array
                let statementAdjustmentOrder = new StatementAdjustmentOrder();
                statementAdjustmentOrder.adjustmentId = adjustment.id;
                statementAdjustmentOrder.adjustmentIndex = selectedStatementAdjustmentOrderObject.adjustmentIndex + 1;
                statementAdjustmentOrder.sourceProjectAdjustmentId = adjustment.sourceProjectAdjustmentId;
                statementAdjustmentOrders.push(statementAdjustmentOrder);

            } else {

                let maxAdjustmentIndex = Math.max(...(statementAdjustmentOrders.length > 0 ? statementAdjustmentOrders.map(item => {
                    return item.adjustmentIndex;
                }) : [0]));
                let statementAdjustmentOrder = new StatementAdjustmentOrder();
                statementAdjustmentOrder.adjustmentId = adjustment.id;
                statementAdjustmentOrder.adjustmentIndex = maxAdjustmentIndex + 1;
                statementAdjustmentOrder.sourceProjectAdjustmentId = adjustment.sourceProjectAdjustmentId;
                statementAdjustmentOrders.push(statementAdjustmentOrder);
            }
        }
    }

    /**
     * Create new interim adjustment (deposit for now) if not exists, then copy data from the "final" side.
     * */
    static createSalePriceInterimAdjustmentAndSyncWithFinal(matter: Matter): void {
        if (matter) {

            let interimSalePriceAdjIndex = matter.interimStatementAdjustments.findIndex(soAdj => StatementAdjustmentKey.SALE_PRICE == soAdj.itemKey);

            let finalSalePriceAdj = StatementAdjustmentUtil.findByKey(matter.finalStatementAdjustments, StatementAdjustmentKey.SALE_PRICE);
            let newInterimSalePriceAdj = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode, finalSalePriceAdj);
            newInterimSalePriceAdj.clearAllIds();
            newInterimSalePriceAdj.id = UUIDUtil.getUUID();
            newInterimSalePriceAdj.adjustmentStatus = ProgressionStatus.INTERIM;
            if (interimSalePriceAdjIndex > -1) {
                matter.interimStatementAdjustments.splice(interimSalePriceAdjIndex, 1, newInterimSalePriceAdj);
            } else {
                matter.interimStatementAdjustments.push(newInterimSalePriceAdj);
            }
        }
    }

    static createDepositInterimAdjustmentAndSyncWithFinal(matter: Matter): void {
        if (matter) {
            let interimDepositAdjIndex = matter.interimStatementAdjustments.findIndex(soAdj => StatementAdjustmentKey.DEPOSIT == soAdj.itemKey);
            let finalDepositAdj = StatementAdjustmentUtil.findByKey(matter.finalStatementAdjustments, StatementAdjustmentKey.DEPOSIT);
            let newInterimDepositAdj = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode, finalDepositAdj);
            newInterimDepositAdj.clearAllIds();
            newInterimDepositAdj.id = UUIDUtil.getUUID();
            newInterimDepositAdj.adjustmentStatus = ProgressionStatus.INTERIM;
            if (interimDepositAdjIndex > -1) {
                matter.interimStatementAdjustments.splice(interimDepositAdjIndex, 1, newInterimDepositAdj);
            } else {
                matter.interimStatementAdjustments.push(newInterimDepositAdj);
            }
        }
    }

    static  createStatementAdjustmentHeading(matter: Matter, documentProfile: DocumentProfile, isProjectSale: boolean, replaceExisting: boolean = false): void {
        if (matter && documentProfile != null && documentProfile.statementOfAdjustmentsProfile != null && (!matter.statementOfAdjustmentHeading || replaceExisting)) {
            matter.statementOfAdjustmentHeading = new StatementOfAdjustmentHeading();
            matter.statementOfAdjustmentHeading.statementOfAdjustmentHeader = documentProfile.statementOfAdjustmentsProfile.soaHeading;
            matter.statementOfAdjustmentHeading.interimStatementOfAdjustmentHeader = 'INTERIM ' + documentProfile.statementOfAdjustmentsProfile.soaHeading;
            matter.statementOfAdjustmentHeading.purchaserHeading = documentProfile.statementOfAdjustmentsProfile.otherHeadingsPurchaser;
            matter.statementOfAdjustmentHeading.purchaserDataCode = documentProfile.statementOfAdjustmentsProfile.otherHeadingsPurchaserDataCode;
            matter.statementOfAdjustmentHeading.vendorHeading = documentProfile.statementOfAdjustmentsProfile.otherHeadingsVendor;
            matter.statementOfAdjustmentHeading.vendorDataCode = documentProfile.statementOfAdjustmentsProfile.otherHeadingsVendorDataCode;
            matter.statementOfAdjustmentHeading.adjustDateHeading = documentProfile.statementOfAdjustmentsProfile.otherHeadingsAdjustDate;
            matter.statementOfAdjustmentHeading.adjustDateDataCode = documentProfile.statementOfAdjustmentsProfile.otherHeadingsAdjustDateDataCode;
            matter.statementOfAdjustmentHeading.addressHeading = documentProfile.statementOfAdjustmentsProfile.otherHeadingsAddress;
            matter.statementOfAdjustmentHeading.addressDataCode = documentProfile.statementOfAdjustmentsProfile.otherHeadingsAddressDataCode;
            matter.statementOfAdjustmentHeading.rightColumnListsCreditsTo = documentProfile.statementOfAdjustmentsProfile.rightColumnListsCreditsTo;
            matter.statementOfAdjustmentHeading.leftColumnListsCreditsTo = documentProfile.statementOfAdjustmentsProfile.leftColumnListsCreditsTo;
            matter.statementOfAdjustmentHeading.printInUpperCase = true;
            matter.statementOfAdjustmentHeading.printInBold = true;
            matter.statementOfAdjustmentHeading.underline = true;
            matter.statementOfAdjustmentHeading.lastLineCreditVendorOrCreditPurchaser = true;
            matter.statementOfAdjustmentHeading.followAdjustmentWithBlankLine = true;
            matter.statementOfAdjustmentHeading.printDollarSignsOnlyForFirstCreditOfEachColumn = true;
            matter.statementOfAdjustmentHeading.doNotPrintDollarSignsInSubtotalColumn = true;
            matter.statementOfAdjustmentHeading.adoptProjectHeading = isProjectSale;
        }
    }

    static updateTaxAdjustmentsAfterSalePriceAdjustmentUpdate(matter: Matter, interimTaxMultiplier: number) {

        if (matter) {
            // For project matters: filter all tax adjustments in interim and final soa and update with new tax calculation
            let interimTaxAdjustments: StatementAdjustment[] = matter.interimStatementAdjustments.filter(item => item.isAnyPropertyTaxes());

            if (interimTaxAdjustments && interimTaxAdjustments.length > 0) {
                interimTaxAdjustments.forEach(item => {
                    if (item.matterTax && item.matterTax.isAdjustmentBasedOnPercentageOfTotalTaxesApplied()) {
                        matter.updatePropertyTaxForProject(item.matterTax, matter.projectTaxAdjustmentConfigInterim);
                        if (item.isTaxesBeginningOfYearUntilOccupancy()) {
                            item.amount = item.matterTax.calculateCreditAmountForTaxBeginningOfYearUntilOcc(matter.projectTaxAdjustmentConfigInterim, interimTaxMultiplier, matter.adjustmentDateForTaxBeginningOfYearUntilOcc, matter.isPaysForDateOfClosingVendor, matter.isProjectProportionateShare);
                        } else {
                            item.amount = item.matterTax.calculateVendorShareDifference(interimTaxMultiplier, matter.getClosingDate(), matter.isPaysForDateOfClosingVendor, matter.isInterimProjectProportionateShare, matter.provinceCode);
                        }
                    }
                });
            }

            let finalTaxAdjustments: StatementAdjustment[] = matter.finalStatementAdjustments.filter(item => item.isAnyPropertyTaxes());
            if (finalTaxAdjustments && finalTaxAdjustments.length > 0) {
                finalTaxAdjustments.forEach(item => {
                    if (item.matterTax && item.matterTax.isAdjustmentBasedOnPercentageOfTotalTaxesApplied()) {
                        matter.updatePropertyTaxForProject(item.matterTax, matter.projectTaxAdjustmentConfigFinal);
                        if (item.isTaxesBeginningOfYearUntilOccupancy()) {
                            item.amount = item.matterTax.calculateCreditAmountForTaxBeginningOfYearUntilOcc(matter.projectTaxAdjustmentConfigFinal, interimTaxMultiplier, matter.adjustmentDateForTaxBeginningOfYearUntilOcc, matter.isPaysForDateOfClosingVendor, matter.isProjectProportionateShare);
                        } else {
                            item.amount = item.matterTax.calculateVendorShareDifference(interimTaxMultiplier, matter.getClosingDate(), matter.isPaysForDateOfClosingVendor, matter.isFinalProjectProportionateShare, matter.provinceCode);
                        }
                    }
                });
            }
        }
    }

    static updateCreditVendorTaxRebateAdjustment(matter: Matter) {
        if (matter) {
            matter.allStatementAdjustments
                .filter(item => item.soAdjCreditVendorTaxRebate != undefined && item.isCreditVendorTaxRebateAdjustment())
                .forEach(soAdj => {
                    if (matter.considerationLtt && matter.considerationLtt.salePriceAdjustment) {
                        if (soAdj.soAdjCreditVendorTaxRebate.showAdjustmentAs === creditVendorTaxRebateShowTypes.separateAdjustment.value) {
                            soAdj.amount = matter.considerationLtt.salePriceAdjustment.totalRebatePortionWithTax(
                                matter.soaProvincialHst,
                                matter.soaFederalHst,
                                matter.getSalePriceForTaxAdjustments(matter.soaFederalHst, matter.soaProvincialHst),
                                soAdj.soAdjCreditVendorTaxRebate.isAdjusted === 'YES' ? soAdj.soAdjCreditVendorTaxRebate.percentage : null);
                        } else {
                            soAdj.amount = null;
                        }

                        soAdj.soAdjCreditVendorTaxRebate.amount
                            = matter.considerationLtt.salePriceAdjustment.totalRebatePortion(
                            matter.soaProvincialHst,
                            matter.soaFederalHst,
                            matter.getSalePriceForTaxAdjustments(matter.soaFederalHst, matter.soaProvincialHst));

                    } else {
                        soAdj.amount = null;
                        soAdj.soAdjCreditVendorTaxRebate.amount = null;
                    }
                });
            matter.updateAdditionalRebatePurchaserNotEligible();
        }
    }

    static onSalePriceUpdate(matter: Matter, result: any, considerationTaxes: ConsiderationTaxes[]): void {
        if (matter) {
            let ontarioTaxRateSlab: ConsiderationTaxes;
            let torontoTaxRateSlab: ConsiderationTaxes;
            let provinceHstRateSlab: ConsiderationTaxes;
            let soaConsiderationTaxes: ConsiderationTaxes;
            if (considerationTaxes && considerationTaxes.length > 0) {
                ontarioTaxRateSlab = TaxRateService.getLatestOntarioTaxRateSlabFromConsiderationTaxes(
                    considerationTaxes
                );
                torontoTaxRateSlab = TaxRateService.getLatestTorontoTaxRateSlabFromConsiderationTaxes(
                    considerationTaxes
                );
                provinceHstRateSlab = considerationTaxes.find(item => item.instanceType == Tax_RATE.HST_RATE);
                soaConsiderationTaxes = matter.getSoaConsiderationTaxes(considerationTaxes);
            }
            if (result.priceType) {
                matter.matterPropertyWithCondo.purchasePriceType = result.priceType;
                matter.hideShowSalePriceSoA(result.priceType);
            }
            if (result.salePrice != undefined && result.priceType) {

                // matter.matterPropertyWithCondo.purchasePrice = result.priceType && result.priceType == 'SALE_PRICE_AS_IN_SOA' ? result.salePriceSoa : result.salePrice;
                const price: number = result.priceType && result.priceType == 'SALE_PRICE_AS_IN_SOA' ? result.salePriceSoa : result.salePrice;
                matter.matterPropertyWithCondo.updatePurchasePrice(price, matter);

            }
            if (result.salePriceSoa != undefined) {
                matter.matterPropertyWithCondo.soaPurchasePrice = result.salePriceSoa;

            }
            if (result.overrideValueOfParcels != undefined) {
                matter.matterPropertyWithCondo.overrideValueOfParcels = result.overrideValueOfParcels;
            }
            matter.autoInsertHst = result.autoInsertHst;



            if (matter.considerationLtt) {
                matter.recalculateConsiderationLTTBasedOnSalePrice(soaConsiderationTaxes, ontarioTaxRateSlab, torontoTaxRateSlab);
                matter.updateLTTOnStatementOfAccount();
                matter.considerationLtt.salePriceAdjustment = result.salePriceAdjustment;


            }
            if(!matter.isSelectedAdjustmentStatusModeFinal){
                matter.adjustmentStatusMode = ProgressionStatus.INTERIM;
                if (matter.considerationLtt) {
                    matter.createUpdateSalePriceAdjustment(soaConsiderationTaxes, true);
                }
                let salePriceSA: StatementAdjustment = matter.getSalePriceStatementAdjustment();
                if (provinceHstRateSlab && salePriceSA) {
                    MatterStatementAdjustmentUtil.createUpdateHstAdjustments(matter, result.salePriceSoa, provinceHstRateSlab.hstFederalPortion, provinceHstRateSlab.hstProvincialPortion);
                }
                matter.adjustmentStatusMode = ProgressionStatus.FINAL;
            }
            if (matter.considerationLtt) {
                matter.createUpdateSalePriceAdjustment(soaConsiderationTaxes);
            }
            if (provinceHstRateSlab) {
                MatterStatementAdjustmentUtil.createUpdateHstAdjustments(matter, result.salePriceSoa, provinceHstRateSlab.hstFederalPortion, provinceHstRateSlab.hstProvincialPortion);
            }
            matter.calculateStatementAdjustment();

            matter.updateConsiderationDataForAb(result.affidavitTobeSignedBy);
            if (matter.isMatterProvinceSK && matter.pstRebateFormNewHomeMatterForm && matter.pstRebateFormNewHomeMatterForm.newHomePstRebateForm) {
                matter.pstRebateFormNewHomeMatterForm.newHomePstRebateForm.updateNewHomePstRebateForm(matter.matterPropertyWithCondo, matter.considerationLtt.salePriceAdjustment, provinceHstRateSlab.hstFederalPortion, provinceHstRateSlab.hstProvincialPortion, matter);
            }
        }
    }

    static createUpdateAdjustmentPropertyTaxes(matter: Matter, statementAdjustmentAvailable?: StatementAdjustment, propertyTaxByRollNumber ?: MatterTax, rollNumber ?: string, infoOnly?: boolean): void {
        let statementAdjustment: StatementAdjustment;
        let propertyTaxes: MatterTax;
        let descriptionLabel: string;

        if (!propertyTaxByRollNumber) {
            propertyTaxes = statementAdjustmentAvailable && statementAdjustmentAvailable.matterTax && !statementAdjustmentAvailable.isOriginPropertyTaxes() ?
                statementAdjustmentAvailable.matterTax : matter.matterPropertyWithCondo.matterTax;
        } else {
            propertyTaxes = propertyTaxByRollNumber;
        }

        if (statementAdjustmentAvailable) {
            statementAdjustment = statementAdjustmentAvailable;
        } else {
            statementAdjustment = matter.statementOfAdjustments.find(item => item.isOriginPropertyTaxes() && item.propertyMatterTaxId === propertyTaxes.id);
        }
        if (rollNumber && propertyTaxes) {
            descriptionLabel = propertyTaxes.hasPaidTaxesUnderTIPP == 'YES' ? matter.getTaxTypeLabel(propertyTaxes.taxType) + ' (TIPP)' + ' - ' + rollNumber : matter.getTaxTypeLabel(propertyTaxes.taxType) + ' - ' + rollNumber;
            // descriptionLabel = matter.getTaxTypeLabel(propertyTaxes.taxType) + ' - ' + rollNumber;
        } else if (propertyTaxes) {
            descriptionLabel = propertyTaxes.hasPaidTaxesUnderTIPP && propertyTaxes.hasPaidTaxesUnderTIPP == 'YES' ? matter.getTaxTypeLabel(propertyTaxes.taxType) + ' (' + provinceBasedFieldLabels.get('matter.soa.property.taxes.tippOrTipps', matter.provinceCode) + ')'
                : matter.getTaxTypeLabel(propertyTaxes.taxType);
        }

        let vendorProportionWillHavePaid: number;
        if (propertyTaxes) {
            vendorProportionWillHavePaid = propertyTaxes.adjustmentBasedOnPercentageOfTotalTaxes == 'YES'  ? propertyTaxes.vendorWillHavePaid * (propertyTaxes.purchaserPortionOfTotalTax / 100) : propertyTaxes.vendorWillHavePaid;
        }

        if (propertyTaxes && propertyTaxes.taxType && (propertyTaxes.vendorShareAmount != undefined && propertyTaxes.vendorShareAmount != null)
            && (vendorProportionWillHavePaid != undefined && vendorProportionWillHavePaid != null)) {
            if (propertyTaxes.vendorShareAmount >= vendorProportionWillHavePaid) {
                if (statementAdjustment) {
                    statementAdjustment.description = descriptionLabel;
                    statementAdjustment.amount = (propertyTaxes.vendorShareAmount - vendorProportionWillHavePaid);
                    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;

                } else {

                    statementAdjustment = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode);
                    statementAdjustment.itemKey = StatementAdjustmentKey.PROPERTY_TAX;
                    statementAdjustment.propertyMatterTaxId = propertyTaxes.id;
                    statementAdjustment.description = descriptionLabel;
                    statementAdjustment.amount = (propertyTaxes.vendorShareAmount - vendorProportionWillHavePaid);
                    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
                    statementAdjustment.addedFlag = false;
                    statementAdjustment.adjustmentStatus = matter.adjustmentStatusMode ? matter.adjustmentStatusMode : ProgressionStatus.FINAL;
                    matter.statementOfAdjustments.push(statementAdjustment);
                }

            } else if (vendorProportionWillHavePaid > propertyTaxes.vendorShareAmount) {
                if (statementAdjustment) {
                    statementAdjustment.description = descriptionLabel;
                    statementAdjustment.amount = (vendorProportionWillHavePaid - propertyTaxes.vendorShareAmount);
                    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;

                } else {
                    statementAdjustment = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode);
                    statementAdjustment.itemKey = StatementAdjustmentKey.PROPERTY_TAX;
                    statementAdjustment.propertyMatterTaxId = propertyTaxes.id;
                    statementAdjustment.description = descriptionLabel;
                    statementAdjustment.amount = (vendorProportionWillHavePaid - propertyTaxes.vendorShareAmount);
                    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
                    statementAdjustment.addedFlag = false;
                    statementAdjustment.adjustmentStatus = matter.adjustmentStatusMode ? matter.adjustmentStatusMode : ProgressionStatus.FINAL;
                    matter.statementOfAdjustments.push(statementAdjustment);
                }
            }

            if (statementAdjustment.isTaxesBeginningOfYearUntilOccupancy()) {
                let ptac: ProjectTaxAdjustmentConfig = statementAdjustment.isAdjustmentStatusFinal() ? matter.projectTaxAdjustmentConfigFinal : matter.projectTaxAdjustmentConfigInterim;
                statementAdjustment.amount = statementAdjustment.matterTax.calculateCreditAmountForTaxBeginningOfYearUntilOcc(ptac, InterimTaxMultiplier, matter.adjustmentDateForTaxBeginningOfYearUntilOcc, matter.isPaysForDateOfClosingVendor, matter.isProjectProportionateShare);
            }
        } else if (propertyTaxes && propertyTaxes.baseTaxAdjustmentTaxBillType === 'NOT_ASSESSED') {
            if (statementAdjustment) {
                statementAdjustment.description = descriptionLabel;
                if (statementAdjustment.addedFlag == undefined) {
                    statementAdjustment.addedFlag = false;
                }
                statementAdjustment.amount = 0;
                statementAdjustment.itemKey = StatementAdjustmentKey.PROPERTY_TAX;
            } else {
                statementAdjustment = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode);
                statementAdjustment.description = descriptionLabel;
                statementAdjustment.propertyMatterTaxId = propertyTaxes.id;
                statementAdjustment.addedFlag = false;
                statementAdjustment.itemKey = StatementAdjustmentKey.PROPERTY_TAX;
                statementAdjustment.adjustmentStatus = matter.adjustmentStatusMode ? matter.adjustmentStatusMode : ProgressionStatus.FINAL;
                matter.statementOfAdjustments.push(statementAdjustment);
            }
        }
        if (infoOnly !== undefined && infoOnly !== null && statementAdjustment) {
            statementAdjustment.infoOnly = infoOnly;
            if (statementAdjustment.infoOnly) {
                statementAdjustment.amount = 0;
            }
        }
    }

    // populate the field value related to SoA for DocGen
    static updateStatementOfAdjustment(matter: Matter, matterService: MatterService) {

        if (matter) {
            if (matter.adjustAsAtClosingDateFlag == null) {
                matter.adjustAsAtClosingDateFlag = 'CLOSING_DATE';
            }
            matter.adjustments = [];
            if (matter.isProjectSale) {
                matter.adjustments.push(...matter._finalAdjustments.filter(item => !!item.applyToAdjustmentRecord));
                matter.adjustments.push(...matter._interimAdjustments.filter(item => !!item.applyToAdjustmentRecord));

                if (matter.interimStatementAdjustmentOrders) {
                    let cleanUpInterimStatementAdjustmentOrders = matter.interimStatementAdjustmentOrders.filter(item => !!item.adjustmentId &&
                        !(matter._interimAdjustments.concat(matter._interimAdjustmentsUnApplied).some(adj => !!adj.id && adj.id == item.adjustmentId)));
                    cleanUpInterimStatementAdjustmentOrders.forEach(item => {
                        let index = matter.interimStatementAdjustmentOrders.findIndex(adjOrder => adjOrder.adjustmentId == item.adjustmentId);
                        matter.interimStatementAdjustmentOrders.splice(index, 1);
                    });
                    matter.interimStatementAdjustmentOrders.sort((a, b) => Number(a.adjustmentIndex) < Number(b.adjustmentIndex) ? -1 : Number(a.adjustmentIndex) > Number(b.adjustmentIndex) ? 1 : 0).forEach((item, index) => {
                        let interimAdjustment = matter._interimAdjustments.find(adj => adj.id == item.adjustmentId);
                        item.adjustmentIndex = index;
                        if (!interimAdjustment) {
                            item.adjustmentId = undefined;
                        }
                    });
                }

                if (matter.finalStatementAdjustmentOrders) {
                    let cleanUpFinalStatementAdjustmentOrders = matter.finalStatementAdjustmentOrders.filter(item => !!item.adjustmentId &&
                        !(matter._finalAdjustments.concat(matter._finalAdjustmentsUnApplied).some(adj => !!adj.id && adj.id == item.adjustmentId)));
                    cleanUpFinalStatementAdjustmentOrders.forEach(item => {
                        let index = matter.finalStatementAdjustmentOrders.findIndex(adjOrder => adjOrder.adjustmentId == item.adjustmentId);
                        matter.finalStatementAdjustmentOrders.splice(index, 1);
                    });
                    matter.finalStatementAdjustmentOrders.sort((a, b) => Number(a.adjustmentIndex) < Number(b.adjustmentIndex) ? -1 : Number(a.adjustmentIndex) > Number(b.adjustmentIndex) ? 1 : 0).forEach((item, index) => {
                        let finalAdjustment = matter._finalAdjustments.find(adj => adj.id == item.adjustmentId);
                        item.adjustmentIndex = index;
                        if (!finalAdjustment) {
                            item.adjustmentId = undefined;
                        }
                    });
                }

                matter.interimStatementOfAdjustmentsOrder = matter.interimStatementAdjustmentOrders ? JSON.stringify(matter.interimStatementAdjustmentOrders) : undefined;
                matter.finalStatementOfAdjustmentsOrder = matter.finalStatementAdjustmentOrders ? JSON.stringify(matter.finalStatementAdjustmentOrders) : undefined;
            } else {
                matter.adjustments.push(...(matter._finalAdjustments || []));
                matter.adjustments.push(...(matter._interimAdjustments || []));
            }


            if (matterService) {
                matterService.updateStatementAdjustmentDisplayItems(matter);
            }
        }
    }

    static getStatementOfAdjustmentPayableTo(matter: Matter, documentProfile?: DocumentProfile, defaultDocProfile?: DocumentProfile): string {
        if (matter) {
            if (matter.isPurchase) {
                let vendor = matter.otherPartyLawFirm;
                if (vendor && vendor.contact) {
                    return vendor.contact.legalFirmName + ', in trust';
                }
            }

            if (matter.isSale) {
                if (documentProfile != null) {
                    if (documentProfile.statementOfAdjustmentsProfile) {
                        if (documentProfile.statementOfAdjustmentsProfile.sameAsDefaultProfileFlag) {
                            if (!defaultDocProfile) {
                                return null;
                            }
                            if (defaultDocProfile && defaultDocProfile.statementOfAdjustmentsProfile && defaultDocProfile.statementOfAdjustmentsProfile.balanceDueOnClosing) {
                                return defaultDocProfile.statementOfAdjustmentsProfile.balanceDueOnClosing;
                            }
                        } else if (documentProfile.statementOfAdjustmentsProfile.balanceDueOnClosing) {
                            return documentProfile.statementOfAdjustmentsProfile.balanceDueOnClosing;
                        }
                    }

                    if (documentProfile.firmDocumentProfile) {
                        let firmName = null;
                        if (documentProfile.firmDocumentProfile.sameAsDefaultProfileFlag) {
                            if (!defaultDocProfile) {
                                return null;
                            }
                            if (defaultDocProfile && defaultDocProfile.firmDocumentProfile && defaultDocProfile.firmDocumentProfile.firmName) {
                                firmName = defaultDocProfile.firmDocumentProfile.firmName;
                            }
                        } else if (documentProfile.firmDocumentProfile.firmName) {
                            firmName = documentProfile.firmDocumentProfile.firmName;
                        }
                        if (firmName) {
                            return firmName + ', in trust';
                        }
                    }
                }
            }
        }
        return "";

    }

    static resetStatementOfAdjustmentPayable(matter: Matter, docProfile?: DocumentProfile, defaultDocProfile ?: DocumentProfile) {
        if (matter) {
            if (matter.isSale && !docProfile) {
                return; // for a SALE matter without a docProfile payableTo is not initialized properly
            }

            if (matter.statementOfAdjustmentPayable == undefined || matter.statementOfAdjustmentPayable == null) {
                matter.statementOfAdjustmentPayable = new StatementOfAdjustmentPayable();
                matter.statementOfAdjustmentPayable.excludePrefix = false;
                matter.statementOfAdjustmentPayable.excludeSuffix = false;
            }
            // if lawfirm get deleted, then need to reset the payableTo value for Purchase/Sale
            matter.statementOfAdjustmentPayable.payableTo = matter.getStatementOfAdjustmentPayableTo(docProfile, defaultDocProfile);
        }
    }


    /**
     * creates or updates automatic HST adjustments
     * @param {number} salePrice
     * @param {number} federalHstRate
     * @param {number} provinceHstRate
     */
    static createAutomaticHstAdjustment(matter: Matter, salePrice: number, federalHstRate: number, provinceHstRate: number): void {
        if (matter && matter.statementOfAdjustments && matter.statementOfAdjustments.length > 0 && matter.autoInsertHst &&
            matter.considerationLtt && matter.considerationLtt.salePriceAdjustment
            && matter.considerationLtt.salePriceAdjustment.isInclusivePrice()) {
            // HST Sale Price
            if (matter.considerationLtt.salePriceAdjustment.isSalePriceFormatNSP()) {
                let statementOfAdjustment = matter.statementOfAdjustments.find(item => item.isHstAdjustment() && item.hstSalePrice && item.hstSalePrice.isHstSalePrice() && !item.addedFlag);
                if (statementOfAdjustment) {
                    statementOfAdjustment.amount = matter.calculateHstSalePriceAdjAmount(salePrice, federalHstRate, provinceHstRate);
                    if (statementOfAdjustment.amount === 0) {
                        statementOfAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
                    }
                    else {
                        statementOfAdjustment.statementOfAdjustmentCreditType = statementOfAdjustment.hstSalePrice.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
                    }
                }
                else {
                    let hstSalePriceSoa = new HstSalePrice(matter.taxRateByProvinceForHSTSalePrice());
                    hstSalePriceSoa.soaFormatType = 'SHOW_AS_ONE_ADJUSTMENT';
                    hstSalePriceSoa.creditType = matter.statementOfAdjustmentHeading ? matter.statementOfAdjustmentHeading.rightColumnListsCreditsTo : '';
                    hstSalePriceSoa.isVendorSelected = true;
                    hstSalePriceSoa.hstType = StatementAdjustmentHSTTypes.HST_SALE_PRICE;

                    let statementAdjustment = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode);
                    statementAdjustment.itemKey = StatementAdjustmentKey.HST_ADJUSTMENT;
                    statementAdjustment.hstSalePrice = hstSalePriceSoa;
                    statementAdjustment.amount = matter.calculateHstSalePriceAdjAmount(salePrice, federalHstRate, provinceHstRate);
                    statementAdjustment.statementOfAdjustmentCreditType = hstSalePriceSoa.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
                    statementAdjustment.addedFlag = false;
                    statementAdjustment.adjustmentStatus = matter.adjustmentStatusMode ? matter.adjustmentStatusMode : ProgressionStatus.FINAL;
                    matter.statementOfAdjustments.push(statementAdjustment);
                    if(matter.isProjectSale){
                        matter.createStatementAdjustmentOrder(statementAdjustment);
                    }

                }

            }
            else {
                let statementOfAdjustment = matter.statementOfAdjustments.find(item => item.isHstAdjustment() && item.hstSalePrice && item.hstSalePrice.isHstSalePrice() && !item.addedFlag);
                if (statementOfAdjustment) {
                    matter.removeStatementAdjustment(statementOfAdjustment);
                }
            }
            // HST Rebate
            if (!matter.eligibleForAutoCreationOfHstRebateSoa()) {
                let autoCreatedHstRebateSoa = matter.statementOfAdjustments.find(item => matter.isAutoCreatedHstRebateSoa(item));
                if (autoCreatedHstRebateSoa) {
                    matter.removeStatementAdjustment(autoCreatedHstRebateSoa);
                }
            } else {
                let autoCreatedHstRebateSoa = matter.statementOfAdjustments.find(item => matter.isAutoCreatedHstRebateSoa(item));
                if (autoCreatedHstRebateSoa) {
                    //update the existing autoCreated HstRebate Soa
                    autoCreatedHstRebateSoa.amount = matter.considerationLtt.salePriceAdjustment.totalRebate(provinceHstRate, federalHstRate);
                    if (autoCreatedHstRebateSoa.amount === 0) {
                        autoCreatedHstRebateSoa.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
                    }
                    else {
                        autoCreatedHstRebateSoa.statementOfAdjustmentCreditType = autoCreatedHstRebateSoa.hstSalePrice && autoCreatedHstRebateSoa.hstSalePrice.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
                    }
                }
                else {
                    //auto create the HstRebate Soa
                    let hstSalePriceSoa = new HstSalePrice(matter.taxRateByProvinceForHSTSalePrice());
                    hstSalePriceSoa.soaFormatType = 'SHOW_AS_ONE_ADJUSTMENT';
                    hstSalePriceSoa.creditType = matter.statementOfAdjustmentHeading ? matter.statementOfAdjustmentHeading.leftColumnListsCreditsTo : '';
                    hstSalePriceSoa.isVendorSelected = false;
                    hstSalePriceSoa.hstType = StatementAdjustmentHSTTypes.HST_REBATE;

                    let autoCreatedHstRebateSoa = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode);
                    autoCreatedHstRebateSoa.itemKey = StatementAdjustmentKey.HST_ADJUSTMENT;

                    autoCreatedHstRebateSoa.hstSalePrice = hstSalePriceSoa;
                    autoCreatedHstRebateSoa.amount = matter.considerationLtt.salePriceAdjustment.totalRebate(provinceHstRate, federalHstRate);
                    autoCreatedHstRebateSoa.statementOfAdjustmentCreditType = hstSalePriceSoa.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
                    autoCreatedHstRebateSoa.addedFlag = false; //this Soa is not added manually
                    autoCreatedHstRebateSoa.adjustmentStatus = matter.adjustmentStatusMode ? matter.adjustmentStatusMode : ProgressionStatus.FINAL;
                    matter.statementOfAdjustments.push(autoCreatedHstRebateSoa);
                    if(matter.isProjectSale){
                        matter.createStatementAdjustmentOrder(autoCreatedHstRebateSoa);
                    }
                }
            }
        }
        else {
            let statementOfAdjustment = matter.statementOfAdjustments.find(item => item.isHstAdjustment() && item.hstSalePrice && item.hstSalePrice.isHstSalePrice() && !item.addedFlag);
            if (statementOfAdjustment) {
                matter.removeStatementAdjustment(statementOfAdjustment);
            }
            let autoCreatedHstRebateSoa = matter.statementOfAdjustments.find(item => matter.isAutoCreatedHstRebateSoa(item));
            if (autoCreatedHstRebateSoa) {
                matter.removeStatementAdjustment(autoCreatedHstRebateSoa);
            }
        }
    }

    static updateManualHstAdjustments(matter: Matter, salePrice: number, federalHstRate: number, provinceHstRate: number): void {
        if (matter) {
            matter.updateHstSalePriceAdjustment(matter.matterProvincialHst, matter.matterFederalHst);

            if (matter.statementOfAdjustments && matter.statementOfAdjustments.length > 0 && matter.autoInsertHst &&
                matter.considerationLtt && matter.considerationLtt.salePriceAdjustment
                && matter.considerationLtt.salePriceAdjustment.isInclusivePrice()) {
                // HST Sale Price
                if (matter.considerationLtt.salePriceAdjustment.isSalePriceFormatNSP()) {
                    matter.statementOfAdjustments.filter(item => item.isHstAdjustment() && item.hstSalePrice && item.hstSalePrice.isHstSalePrice() && item.addedFlag)
                        .forEach(item => {
                            item.amount = matter.calculateHstSalePriceAdjAmount(salePrice, federalHstRate, provinceHstRate);
                            if (item.amount === 0) {
                                item.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
                            } else {
                                item.statementOfAdjustmentCreditType = item.hstSalePrice.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
                            }

                        });
                }

                // HST Rebate
                if (!matter.considerationLtt.salePriceAdjustment.isDivideSalePrice()) {
                    matter.statementOfAdjustments.filter(item => item.isHstAdjustment() && item.hstSalePrice && item.hstSalePrice.isHstRebate() && item.addedFlag)
                        .forEach(item => {
                            item.amount = matter.considerationLtt.salePriceAdjustment.totalRebate(provinceHstRate, federalHstRate);
                            if (item.amount === 0) {
                                item.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
                            } else {
                                item.statementOfAdjustmentCreditType = item.hstSalePrice.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
                            }

                        });
                }
            }
        }
    }

    /**
     * creates/updates HST Automatic adjustments AND updates Manual HST adjustments
     * @param {number} salePrice
     * @param {number} federalHstRate
     * @param {number} provinceHstRate
     */
    static createUpdateHstAdjustments(matter: Matter, salePrice: number, federalHstRate: number, provinceHstRate: number) {
        MatterStatementAdjustmentUtil.createAutomaticHstAdjustment(matter, salePrice, federalHstRate, provinceHstRate);
        MatterStatementAdjustmentUtil.updateManualHstAdjustments(matter, salePrice, federalHstRate, provinceHstRate);
    }

    static findFirstAdjustmentByKey(matter: Matter, key: string, sortFinalAdjustmentsFirs? : boolean, progressionStatus? : string): StatementAdjustment {

        let foundAdjustments = [];
        let allStatementAdjustments = [];
        let allStatementAdjustmentOrders = [];
        if (!progressionStatus) {
            allStatementAdjustments = matter.interimStatementAdjustments.concat(matter.finalStatementAdjustments);
            allStatementAdjustmentOrders = matter.interimStatementAdjustmentOrders.concat(matter.finalStatementAdjustmentOrders);
        } else if (progressionStatus == ProgressionStatus.FINAL) {
            allStatementAdjustments = matter.finalStatementAdjustments.slice();
            allStatementAdjustmentOrders = matter.finalStatementAdjustmentOrders.slice();
        } else {
            allStatementAdjustments = matter.interimStatementAdjustments.slice();
            allStatementAdjustmentOrders = matter.interimStatementAdjustmentOrders.slice();
        }
        if (matter.isProjectSale) {
            allStatementAdjustments.filter(adj => adj.itemKey === key).forEach(soa => {
                let statementAdjustmentOrder: StatementAdjustmentOrder = allStatementAdjustmentOrders.find(order => order.adjustmentId === soa.id);
                if (statementAdjustmentOrder) {
                    foundAdjustments.push({adjustmentIndex: statementAdjustmentOrder.adjustmentIndex, statementAdjustment: soa});
                }
            });
            if (foundAdjustments.length > 0) {
                foundAdjustments.sort((a, b) => {
                    if (!progressionStatus) {
                        if (a.statementAdjustment.isAdjustmentStatusFinal() === b.statementAdjustment.isAdjustmentStatusFinal()) {
                            return Number(a.adjustmentIndex) - Number(b.adjustmentIndex);
                        } else {
                            if (!sortFinalAdjustmentsFirs) {
                                return a.statementAdjustment.isAdjustmentStatusFinal() ? 1 : -1;
                            } else {
                                return a.statementAdjustment.isAdjustmentStatusFinal() ? -1 : 1;
                            }
                        }
                    } else {
                        return Number(a.adjustmentIndex) - Number(b.adjustmentIndex);
                    }
                });
                return foundAdjustments[0].statementAdjustment;
            } else {
                return null;
            }
        } else {
            return allStatementAdjustments.find(adj => adj.itemKey === key);
        }
    }

    static  findFirstAdjustmentByKeyInDefaultAdjustments(matter: Matter, key: string): StatementAdjustment {
        let foundAdjustments = [];
        let allStatementAdjustments = matter.statementOfAdjustments.slice();
        let allStatementAdjustmentOrders = [];
        if (matter.isAdjustmentStatusModeFinal) {
            allStatementAdjustmentOrders = matter.finalStatementAdjustmentOrders.slice();
        } else {
            allStatementAdjustmentOrders = matter.interimStatementAdjustmentOrders.slice();
        }
        if (matter.isProjectSale) {
            allStatementAdjustments.filter(adj => adj.itemKey === key).forEach(soa => {
                let statementAdjustmentOrder: StatementAdjustmentOrder = allStatementAdjustmentOrders.find(order => order.adjustmentId === soa.id);
                if (statementAdjustmentOrder) {
                    foundAdjustments.push({adjustmentIndex: statementAdjustmentOrder.adjustmentIndex, statementAdjustment: soa});
                }
            });
            if (foundAdjustments.length > 0) {
                foundAdjustments.sort((a, b) => {
                    return Number(a.adjustmentIndex) - Number(b.adjustmentIndex);
                });
                return foundAdjustments[0].statementAdjustment;
            } else {
                return null;
            }
        } else {
            return allStatementAdjustments.find(adj => adj.itemKey === key);
        }
    }

    static createUpdateAdjustmentCommonExp(matter: Matter, parcelLegalDescription?: ParcelLegalDescription, skCondoFee?: number,doNotCreateAdjustment? : boolean): void {
        let commonExpensePaid: string = 'YES';
        let adjustedHst: string = 'NO';
        let paysForClosingDays: String = 'PURCHASER';
        let closingDateFromMatter: string;
        if (matter.matterCloseDate) {
            let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
            if (pattern.test(matter.matterCloseDate)) {
                closingDateFromMatter = matter.matterCloseDate;
            }

        }
        let matterProperty: MatterProperty = matter.matterPropertyWithCondo;
        if (matterProperty) {
            let statementAdjustment: StatementAdjustment;
            if (matter.isMatterProvinceMB && matterProperty.separateCommonElementFeeAdj && parcelLegalDescription) {
                statementAdjustment = matter.statementOfAdjustments.find(item => item.isCommonExpenseAdjustmentFromProperty() && item.parcelLegalDescriptionId == parcelLegalDescription.id);
            } else {
                statementAdjustment = matter.statementOfAdjustments.find(item => item.isCommonExpenseAdjustmentFromProperty());
            }

            if (statementAdjustment) {
                if (matterProperty.isPropertyCondominium()) {
                    if (matter.isMatterProvinceMB) {
                        if (matterProperty.separateCommonElementFeeAdj && parcelLegalDescription) {
                            statementAdjustment.soaCommonExpense.commonExpenseAmount = Number(parcelLegalDescription.commonElementFees ? parcelLegalDescription.commonElementFees : 0);
                        } else {
                            statementAdjustment.soaCommonExpense.commonExpenseAmount = Number(matterProperty.calcCondominiumFeesFromTitleRollNumbers());
                        }
                        statementAdjustment.amount = statementAdjustment.soaCommonExpense.calculateVendorShareDifference(matter.getClosingDate(),matter.isPaysForDateOfClosingVendor);

                    } else {
                        statementAdjustment.soaCommonExpense.commonExpenseAmount = Number(matterProperty.condominiumTotalExpenses);
                    }

                }
                else {
                    matter.removeStatementAdjustment(statementAdjustment);
                }
            }
            else {
                if (matterProperty.isPropertyCondominium() && !doNotCreateAdjustment) {
                    let condominiumTotalExpenses: Number;
                    if (matter.isMatterProvinceMB) {
                        if (matterProperty.separateCommonElementFeeAdj && parcelLegalDescription) {
                            condominiumTotalExpenses = parcelLegalDescription.commonElementFees ? parcelLegalDescription.commonElementFees : 0;
                        } else {
                            condominiumTotalExpenses = matterProperty.calcCondominiumFeesFromTitleRollNumbers();
                        }

                    } else {
                        condominiumTotalExpenses = matterProperty.condominiumTotalExpenses == undefined ? 0 : matterProperty.condominiumTotalExpenses;
                    }

                    let hstCommonExpense: number = 0;
                    if (adjustedHst == 'YES') {
                        hstCommonExpense = ((Number(condominiumTotalExpenses) * Number(matter.matterHst)) / 100);
                    }
                    let totalExpense = (Number(condominiumTotalExpenses) + Number(hstCommonExpense));
                    let vendorShare = 0.00;
                    if (closingDateFromMatter && closingDateFromMatter != "") {

                        let elapsedDays: number = Number(matter.getElapsedDays(closingDateFromMatter));
                        if (paysForClosingDays == 'VENDOR') {
                            elapsedDays = Number(elapsedDays) + 1;
                        }
                        let nrOfDaysMonth: number = Number(matter.getNumberofDaysMonth(closingDateFromMatter));
                        if (!isNaN(nrOfDaysMonth) && nrOfDaysMonth !== 0) {
                            vendorShare = ((elapsedDays) / nrOfDaysMonth * totalExpense);
                        }
                    }

                    let sce: SoaCommonExpense = new SoaCommonExpense();
                    sce.adjustPercentage = matter.matterHst.toString();
                    sce.commonExpenseAdjustedFor = matter.matterTaxType;
                    sce.commonExpenseAmount = Number(condominiumTotalExpenses);
                    sce.expenseType = StatementAdjustmentKey.COMMON_EXPENSE;
                    sce.isCommonExpenseAdjusted = 'NO';
                    sce.isCommonExpensePaid = commonExpensePaid;

                    let parcelLegalDescriptionId = parcelLegalDescription ? parcelLegalDescription.id : null;
                    let commonExpensForAdj: number = skCondoFee ? skCondoFee : totalExpense;
                    if (!matter.templateForProject && commonExpensForAdj != 0) {
                        if (commonExpensePaid == 'YES') {
                            matter.addAdjustment(matter.adjustmentStatusMode, StatementAdjustmentConstants.COMMON_EXPENSE, (totalExpense - vendorShare), StatementAdjustmentKey.COMMON_EXPENSE, StatementAdjustmentAmountTypes.VENDOR, sce, null, null, parcelLegalDescriptionId);
                        }
                        else if (commonExpensePaid == 'NO') {
                            matter.addAdjustment(matter.adjustmentStatusMode, StatementAdjustmentConstants.COMMON_EXPENSE, vendorShare, StatementAdjustmentKey.COMMON_EXPENSE, StatementAdjustmentAmountTypes.PURCHASER, sce, null, null, parcelLegalDescriptionId);
                        }
                    }

                }
            }
        }
    }

    static getHstSalePriceAdjustmentAmount(matter: Matter, hstSalePriceSoa: HstSalePrice, salePriceAdjustment: SalePriceAdjustment, provinceHstRate : number , federalHstRate : number ): number {
        if (!hstSalePriceSoa || !salePriceAdjustment) {
            return 0;
        }
        if (hstSalePriceSoa.isHstRebate()) {
            if (salePriceAdjustment.isNotInclusivePrice() && hstSalePriceSoa.isRateTypePST()) {
                return salePriceAdjustment.calculateProvincialRebatePortionSubjectToPST(provinceHstRate);
            } else {
                return salePriceAdjustment.totalRebate(provinceHstRate, federalHstRate)
            }

        } else {
            if (matter.isMatterProvinceMB || matter.isMatterProvinceSK) {
                /*
               if(hstSalePriceSoa.isRateTypePST()){
                    return Utils.roundCurrencyTCStyle((matter.getSalePriceBasedOnAdjustment(federalHstRate,provinceHstRate,hstSalePriceSoa.isRateTypePST()) * provinceHstRate) / 100);
                }else{
                    return Utils.roundCurrency(salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(federalHstRate, federalHstRate, provinceHstRate));
                }
                */
                // should use same logic as the HstSalePriceAdjustment modal component => creditAmount()
                const salePrice: number = matter.getSalePriceBasedOnAdjustment(federalHstRate, provinceHstRate, (hstSalePriceSoa.isRateTypePST()));
                return Utils.roundCurrencyTCStyle(salePrice * (hstSalePriceSoa.isRateTypePST() ? provinceHstRate : federalHstRate) / 100);
            }
            if (salePriceAdjustment.isNotInclusivePrice() && matter.isMatterProvinceAB) {
                return Utils.roundCurrencyTCStyle((salePriceAdjustment.getTotalConsiderationCost(salePriceAdjustment.salePriceAdjustmentHeadings) * provinceHstRate) / 100)
                    + Utils.roundCurrencyTCStyle((salePriceAdjustment.getTotalConsiderationCost(salePriceAdjustment.salePriceAdjustmentHeadings) * federalHstRate) / 100);
            }else if(matter.isMatterProvinceNB || matter.isMatterProvinceNS) {
                const salePrice: number = matter.getSalePriceBasedOnAdjustment(federalHstRate, provinceHstRate, (hstSalePriceSoa.isRateTypePST()));
                return Utils.roundCurrencyTCStyle((salePrice * (provinceHstRate + federalHstRate) / 100));
            } else {
                return Utils.roundCurrency(salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(provinceHstRate, federalHstRate, provinceHstRate))
                    + Utils.roundCurrency(salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(federalHstRate, federalHstRate, provinceHstRate));

            }
        }
    }

    static updateAdjustments(matter: Matter, adjustmentDate: string, statementAdjustmentDisplayUtil?: StatementAdjustmentDisplayBuilder): void {
        // updatePropertyTax();
        matter.updateMatterPropertyWithCondoAdjustmentAmount(matter.isPaysForDateOfClosingVendor);
        matter.updatePropertyTaxesOnSOAOnClosingDate(matter.isPaysForDateOfClosingVendor);
        matter.recalculateTaxesPaidDuringOccupancy();
        matter.recalculateForm4Charges();
        // matter.updateSoaCommonExpenses()
        matter.statementOfAdjustments.filter(item => item.isOccupancyCommonExpenseAdjustment() || item.isCommonExpenseAdjustment() || item.isPOTLCommonExpenseAdjustment())
            .forEach((item: StatementAdjustment) => {
                if (item.isCommonExpenseAdjustment()) {
                    item.amount = item.soaCommonExpense.calculateVendorShareDifference(adjustmentDate, matter.isPaysForDateOfClosingVendor);
                    item.statementOfAdjustmentCreditType = item.soaCommonExpense.adjustmentCreditType(adjustmentDate, matter.isPaysForDateOfClosingVendor);
                } else {
                    if (item.isPOTLCommonExpenseAdjustment() && item.soaCommonExpense && item.soaCommonExpense.usePotlCommonExpenseAmount === 'YES') {
                        item.soaCommonExpense.commonExpenseAmount = Number(matter.matterPropertyWithCondo.commonExpenses);
                    }
                    item.amount = item.soaCommonExpense.calculateVendorShareDifference(adjustmentDate,matter.isPaysForDateOfClosingVendor);
                    item.statementOfAdjustmentCreditType = item.soaCommonExpense.adjustmentCreditType(adjustmentDate);
                }
            });
        // updateSoaWater()
        matter.statementOfAdjustments.filter(item => item.isWater())
            .forEach((item: StatementAdjustment) => {
                if (item.soaWater && item.soaWater.isWaterSupplyTypeFlatRate) {
                    item.statementOfAdjustmentCreditType = item.soaWater.getSoaCreditType(adjustmentDate, matter.isPaysForDateOfClosingVendor);
                    item.amount = item.soaWater.getCredit(adjustmentDate, matter.isPaysForDateOfClosingVendor);
                }
            });
        // updateAssumedMortgage()
        matter.recalculateAllAssumedMortgage();

        // update Tenancy Current
        matter.recalculateAllTenancyCurrent();


        // update Tenancy Prepaid
        matter.statementOfAdjustments.filter(item => item.isTenancyPrepaid())
            .forEach((item: StatementAdjustment) => {

                let localSoAdjTenancyPrepaid: SoAdjTenancyPrepaid = matter.updateCalculatedInterestForPrePaidTenancy(item.soAdjTenancyPrepaid, statementAdjustmentDisplayUtil);
                item.soAdjTenancyPrepaid = new SoAdjTenancyPrepaid(localSoAdjTenancyPrepaid);
                item.amount = item.soAdjTenancyPrepaid.totalCreditPurchaser;

            });

        matter.statementOfAdjustments.filter(item => item.isInterestOnDeferredMoniesDuringOccupancy())
            .forEach((item: StatementAdjustment) => {
                item.soAdjInterestCal.applyUpdatesBasedOnProjectSettings(matter, matter.project);
            });

        // update Interest Calculation
        matter.statementOfAdjustments.filter(item => (item.isInterest() || item.isInterestOnLumpSum() || item.isInterestOnDeferredMoniesDuringOccupancy()))
            .forEach((item: StatementAdjustment) => {
                item.soAdjInterestCal.applyMatterUpdatesOnInterestCalculationPeriod(matter);
                item.amount = item.soAdjInterestCal.calculateSoAInterest(matter.provinceCode, matter.isPaysForDateOfClosingVendor, item.isInterestOnDeferredMoniesDuringOccupancy());
            });


        // update Other Pro-rated
        matter.statementOfAdjustments.filter(item => item.isOtherProrated())
            .forEach((item: StatementAdjustment) => {
                item.amount = item.soAdjOtherProrated.getCreditAmount(matter.getClosingDate(), null, matter.paysForDateOfClosing)
            });

        let elapsedDays = Number(matter.getElapsedDays(matter.getClosingDate()));
        let numberOfDaysInMonth = Number(matter.getNumberofDaysMonth(matter.getClosingDate()));

        // InterimEarlyPossessionFee
        matter.statementOfAdjustments.filter(item => item.isInterimEarlyPossessionFee())
            .forEach((item: StatementAdjustment) => {
                item.updateInterimEarlyPossessionFeeOnClosingDateChange(elapsedDays, numberOfDaysInMonth);
            });

        matter.recalculateAllFinalEarlyPossessionFee();

        // InterimOccupancyFee
        matter.recalculateAllInterimOccupancyFee(matter.project);

        //Taxes Paid During Occupancy
        matter.recalculateAllTaxesPaidDuringOccupancy();


        matter.recalculateAllFinalOccupancyFee();
        SoaRealtyTaxAdjustmentUtil.updateRealtyTaxAdjustmentOnDateChange(matter);
        SoaRealtyTaxAdjustmentUtil.updateRealtyTaxesPaidByVendor(matter);

        // updateIntersetOnDepositInterimAndFinal
        StatementAdjustmentUtil.updateInterestOnDepositInterimAndFinal(matter);
        StatementAdjustmentUtil.updateInterestOnDepositUnderOldCondo(matter);
    }

    static createUpdateAdjustmentPropertyTaxesByRollNumber(matter: Matter, infoOnly?: boolean): void {
        if (matter) {
            matter.matterProperties.forEach(matterProperty => {
                if (matterProperty.separateTaxAdjustmentByRollNumber && matterProperty.matterTaxesByRollNumber && matterProperty.matterTaxesByRollNumber.length > 0) {

                    if (matter.isMatterProvinceMB) {
                        matterProperty.matterTaxesByRollNumber.forEach((mt) => {
                            matterProperty.parcelLegalDescriptions.forEach(parcelLegalDescription => {
                                parcelLegalDescription.rollNumbers.forEach(rn => {
                                    let rollNumberOrder = ParcelLegalDescription.getTitleRollNumberOrder(matterProperty.parcelLegalDescriptions, rn, parcelLegalDescription);
                                    if (mt.rollNumberOrder === rollNumberOrder) {
                                        matter.createUpdateAdjustmentPropertyTaxes(null, mt, rn, infoOnly);
                                    }
                                });
                            });
                        });
                    } else {
                        if (matterProperty.isCondominium === DpBooleanValueTypes.YES) {

                            matterProperty.matterTaxesByRollNumber.forEach((item) => {
                                if (matterProperty.condominiumExpenses && item.rollNumberOrder < matterProperty.condominiumExpenses.length) {
                                    matter.createUpdateAdjustmentPropertyTaxes(null, item, matterProperty.condominiumExpenses[item.rollNumberOrder].rollNumber, infoOnly);
                                }
                            })
                        } else {
                            matterProperty.matterTaxesByRollNumber.forEach((item) => {
                                if (item.rollNumberOrder < matter.matterProperties.length) {
                                    matter.createUpdateAdjustmentPropertyTaxes(null, item, matter.matterProperties[item.rollNumberOrder].rollNumber.city, infoOnly)
                                }
                            })
                        }
                    }
                }
            });
        }
    }

    static createNewConsiderationLtt(matter: Matter): void {
        if (matter) {
            matter.considerationLtt = new ConsiderationLtt(matter.adjustmentStatusMode, null, matter.provinceCode);
            matter.considerationLtt.lttRateBasedOnType = 'QUESTION';
            matter.considerationLtt.inTableBelowType = matter.isMatterProvinceON && matter.isProjectSale ? null : 'AMOUNT_IN_A_EQUALS_AMOUNT_F';
            matter.considerationLtt.completionOfTaxInfoType = matter.isMatterProvinceON && matter.isProjectSale ? 'LINE_A_EQUAL_NET_SALE_PRICE' : null;
            matter.considerationLtt.lttRefundEntitlementOntario = 'N/y';
            matter.considerationLtt.lttRefundEntitlementToronto = 'N/y';
            matter.considerationLtt.applyCityOfTorontoLandTransferTax = 'QUESTION';
            matter.considerationLtt.percentageClaimed = '100.00';
            if(matter.isMatterProvinceON && matter.isProjectSale){
                matter.considerationLtt.lttRateBasedOnType = 'QUESTION';
                matter.considerationLtt.lttAffidavitExecutedBy = 'CLIENT';
                matter.considerationLtt.isMatterParticipantSpouseSelected = null;
            } else {
                if (matter.matterParticipantSpouse().length == 2) {
                    if (matter.purchasers.length > 2) {
                        matter.considerationLtt.lttAffidavitExecutedBy = 'SOLICITOR';
                    } else {

                        if ((matter.considerationLtt.isMatterParticipantSpouseSelected == undefined || !matter.considerationLtt.isMatterParticipantSpouseSelected)) {
                            matter.considerationLtt.isMatterParticipantSpouseSelected = true;
                            matter.considerationLtt.lttAffidavitExecutedBy = 'BOTH_SPOUSES';
                        } else if (!matter.considerationLtt.lttAffidavitExecutedBy) {
                            matter.considerationLtt.lttAffidavitExecutedBy = 'BOTH_SPOUSES';
                        } else {
                            matter.considerationLtt.isMatterParticipantSpouseSelected = true;
                            matter.considerationLtt.lttAffidavitExecutedBy = 'BOTH_SPOUSES';
                        }
                    }

                } else {

                    if (matter.purchasers.length > 2) {
                        matter.considerationLtt.lttAffidavitExecutedBy = 'SOLICITOR';
                    } else {
                        if ((matter.considerationLtt.isMatterParticipantSpouseSelected == undefined || matter.considerationLtt.isMatterParticipantSpouseSelected)) {
                            matter.considerationLtt.isMatterParticipantSpouseSelected = false;
                            matter.considerationLtt.lttAffidavitExecutedBy = 'CLIENT';
                        } else if (!matter.considerationLtt.lttAffidavitExecutedBy) {
                            matter.considerationLtt.lttAffidavitExecutedBy = 'CLIENT';
                        } else {
                            matter.considerationLtt.isMatterParticipantSpouseSelected = false;
                        }
                    }
                }
            }
        }
    }

    static isAnyAdjustmentWithFirstAdditionalConsideration(matter: Matter , checkInterimAndFinal? : boolean): boolean {
        return matter.isExtrasSubjectToGSTExists() ||
            StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate((checkInterimAndFinal ? matter.allStatementAdjustments : matter.statementOfAdjustments)) ||
            StatementAdjustmentUtil.isHCRAAdjAvailableAndEligibleForTaxRebate((checkInterimAndFinal ? matter.allStatementAdjustments : matter.statementOfAdjustments)) ||
            StatementAdjustmentUtil.isOtherFixedWithAdditionalConsiderationToVendorEligibleForTaxRebate((checkInterimAndFinal ? matter.allStatementAdjustments : matter.statementOfAdjustments)) ||
            StatementAdjustmentUtil.anyOtherFixedAdjustmentsWithFirstConsideration((checkInterimAndFinal ? matter.allStatementAdjustments : matter.statementOfAdjustments));
    }

    static isAnyAdjustmentWithSecondAdditionalConsideration(matter: Matter, checkInterimAndFinal? : boolean): boolean {
        return matter.isExtrasSubjectToGSTExists() ||
            StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate((checkInterimAndFinal ? matter.allStatementAdjustments : matter.statementOfAdjustments)) ||
            StatementAdjustmentUtil.isHCRAAdjAvailableAndNotEligibleForTaxRebate((checkInterimAndFinal ? matter.allStatementAdjustments : matter.statementOfAdjustments)) ||
            StatementAdjustmentUtil.isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebate((checkInterimAndFinal ? matter.allStatementAdjustments : matter.statementOfAdjustments));
    }

    static isAnyAdjustmentWithThirdAdditionalConsideration(matter: Matter, checkInterimAndFinal? : boolean): boolean {
        return matter.isExtrasSubjectToGSTExists() ||
            StatementAdjustmentUtil.isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebatePlusTax((checkInterimAndFinal ? matter.allStatementAdjustments : matter.statementOfAdjustments));
    }

    static generateMissingStatementOfAdjustmentsOrder(matter: Matter) : void {
        if(matter && matter.isProjectSale){
            if(matter.finalStatementAdjustments && matter.finalStatementAdjustments.length){
                let missingFinalStatementAdjustment = matter.finalStatementAdjustments
                    .filter(soa => !(matter.finalStatementAdjustmentOrders && matter.finalStatementAdjustmentOrders.find(item => item.adjustmentId == soa.id)));
                console.log(missingFinalStatementAdjustment);
                if(missingFinalStatementAdjustment && missingFinalStatementAdjustment.length) {
                    missingFinalStatementAdjustment.forEach((soa)=>{
                        matter.createStatementAdjustmentOrder(soa);
                    })
                }
            }

            if(matter.interimStatementAdjustments && matter.interimStatementAdjustments.length){
                let missingInterimStatementAdjustment = matter.interimStatementAdjustments
                    .filter(soa => !(matter.interimStatementAdjustmentOrders && matter.interimStatementAdjustmentOrders.find(item => item.adjustmentId == soa.id)));
                console.log(missingInterimStatementAdjustment);
                if(missingInterimStatementAdjustment && missingInterimStatementAdjustment.length) {
                    missingInterimStatementAdjustment.forEach((soa)=>{
                        matter.createStatementAdjustmentOrder(soa);
                    })
                }
            }
        }
    }

    static async setDefaultStatementOfAdjustmentFooters(matter: Matter, soaConfigService: StatementConfigService) : Promise<void>{
        if (matter.matterStatementOfAdjustmentFooters.length == 0) {
            let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
            let statementAdjustmentConfigList: StatementAdjustmentConfig[] = await (soaConfigService.getStatementAdjustmentConfig(id, matter.provinceCode)).toPromise();
            if(statementAdjustmentConfigList && statementAdjustmentConfigList.length) {
                matter.matterStatementOfAdjustmentFooters = statementAdjustmentConfigList;
                matter.matterStatementOfAdjustmentFooters.map(function (item) {
                    item.id = null;
                    item.instanceType = 'matterSoaFooter';
                    item.footerProgressionStatusAvailability = soAdjProjectFooters.applyToBoth.value;
                });
            }
        }
    }

}

