import {Directive} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
    selector: '[ngModel][dp-uppercase-alpha]',
    host: {
        '(input)': 'onInput($event)'
    }
})
export class UppercaseDirectiveAlpha {

    constructor(private model: NgModel) {
    }

    onInput() {
        if (this.model.value) {
            let val = this.model.value.replace(/[^A-Za-z]/g, '');
            this.model.reset(val);
            this.model.valueAccessor.writeValue(val.toUpperCase());
        }
    }
}
