//This class contains the authority
export class Authority {
    operation: string;
    privilege: string;


    constructor(authority?: Authority) {
        if(authority) {
            for(let prop in authority) {
                if(authority.hasOwnProperty(prop)) {
                    this[prop] = authority[prop];
                }
            }
        }
    }
}
