import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {StatusBarMessages} from './status-bar-messages';

/**
 *  This service provides a help text in the Status Bar on field focus
**/
@Injectable()
export class StatusBarService {

    currentHelpText: string

    constructor(private logger: Logger) { }

    /**
     *  This method is called by application at startup.
     *  It loads the mapping field-key to help-text.
     */
    initialize(): void {
        this.currentHelpText = "";
        console.log("Status Bar Service initializing..")
    }

    /**
     *  Method that handles onFocus for the specified field
     */
    onFocus(fieldKey : string): void {
        //handle onFocus for the specified field
        if(StatusBarMessages[fieldKey]) {
            this.currentHelpText = StatusBarMessages[fieldKey]["STATUS_BAR_HELP"]
        }
        console.log(">>> focusin : ", fieldKey, this.currentHelpText)

    }

    dynamicHelpFocus(dynamicMessage : string) : void {
        this.currentHelpText = dynamicMessage;
    }

    /**
     *  Method that handles onFocusOut for the specified field
     */
    onFocusOut(fieldKey : string): void {
        //handle onFocusOut for the specified field
        this.currentHelpText = "";
        console.log("<<< focusout :", fieldKey)
    }
}
