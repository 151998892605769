import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {CloseGuard, DialogRef, ModalComponent} from 'ngx-modialog-7';
import {Component, OnInit} from '@angular/core';
import {ProjectMatterUpdateTransaction} from '../shared/project-matter-update-transaction';
import {ErrorService} from '../../shared/error-handling/error-service';
import {Utils} from '../../../app/matters/shared/utils';
import {MassUpdateTypes} from '../../../app/projects/shared/project';
import {ProjectAdjustmentImport} from '../../../app/shared-main/constants';


export class MassUpdateProgressModalContext extends BSModalContext {
    projectMatterUpdateTransaction: ProjectMatterUpdateTransaction;
}

@Component({
    selector: 'dp-mass-update-progress-modal-content',
    templateUrl: './mass-update-progress-modal.component.html',
    providers: [ErrorService]
})
export class MassUpdateProgressModalComponent implements ModalComponent<MassUpdateProgressModalContext>, OnInit , CloseGuard {

    public context: MassUpdateProgressModalContext;
    matterUpdateAction: string = 'Matter Update';

    get projectMatterUpdateTransaction(): ProjectMatterUpdateTransaction {
        return this.context.projectMatterUpdateTransaction;
    }

    getProgressMessage(): string {
        let msg: string = '';
        if ((this.successfulMattersCount + this.failedMatterCount) == this.totalMattersCount) {
            msg = this.projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' has completed.';
        } else {
            msg = this.projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' in progress. Please wait this might take a little while.';
        }
        if(this.isProjectMatterUpdateTransactionImportAdj){
            return msg + '\nTotal lines processed' +
                ' are ' + (this.successfulMattersCount + this.failedMatterCount) +' of ' + this.totalMattersCount + ' (successful: ' + this.successfulMattersCount + ', failed: ' + this.failedMatterCount + ')';
        } else {
            return msg + '\nTotal matters processed' +
                ' are ' + this.successfulMattersCount +' of ' + this.totalMattersCount + ' (successful: ' + this.successfulMattersCount + ', failed: ' + this.failedMatterCount + ')';
        }

    }

    get isProjectMatterUpdateTransactionImportAdj(): boolean {
        return (this.projectMatterUpdateTransaction && this.projectMatterUpdateTransaction.isProjectMatterUpdateActionMassUpdate() && this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateType == MassUpdateTypes.IMPORT_MATTER_ADJ_INF);
    }

    get processedMattersCount() : number {
        return this.projectMatterUpdateTransaction.processedMattersCount;
    }

    get successfulMattersCount(): number {
        if (this.isProjectMatterUpdateTransactionImportAdj && this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData && this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data
        ) {
            let successfullyFileNos = this.projectMatterUpdateTransaction.targetMatters.filter(matter => this.projectMatterUpdateTransaction.successfullySendToProcess.indexOf(matter.id) > -1).map(item => {
                return item.fileNumber;
            });
            let errorMessageLines = this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.errorMessages.map(em => {
                return em.line;
            });
            if (this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.uploadOption == ProjectAdjustmentImport.DEPOSIT_ADJUSTMENT_INFORMATION) {
                let deposits = [].concat.apply([], Array.from(this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.filteredDeposits).filter(item => successfullyFileNos.indexOf(item[0]) > -1).map(fd => {
                    return fd[1];
                }));
                return deposits.filter(dp => errorMessageLines.indexOf(dp.line) < 0).length;
            } else if (this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.uploadOption == ProjectAdjustmentImport.SALE_PRICE_ADJUSTMENT_INFORMATION) {
                let salePriceRecords = this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.salePriceDataToBeProcessed.filter(item => successfullyFileNos.indexOf(item.matterRecordNumber) > -1);
                return salePriceRecords.filter(dp => errorMessageLines.indexOf(dp.line) < 0).length;
            } else {
                return this.projectMatterUpdateTransaction.successfulMattersCount;
            }

        } else {
            return this.projectMatterUpdateTransaction.successfulMattersCount;
        }
    }

    get failedMatterCount(): number {
        if (this.isProjectMatterUpdateTransactionImportAdj) {
            return this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.errorMessages.length;
        } else {
            return this.projectMatterUpdateTransaction.failedMatterCount;
        }
    }

    get projectMatterUpdateFailed() : boolean{
        return (!this.projectMatterUpdateTransaction.showProjectMatterUpdateStop && this.failedMatterCount > 0);
    }

    get totalMattersCount() {
        if (this.isProjectMatterUpdateTransactionImportAdj && this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData && this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data
        ) {
            let errorMessageLines = this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.errorMessages.map(em => {
                return em.line;
            });
            if (this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.uploadOption == ProjectAdjustmentImport.DEPOSIT_ADJUSTMENT_INFORMATION) {
                let deposits = [].concat.apply([], Array.from(this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.filteredDeposits).map(fd => {
                    return fd[1];
                }));
                return deposits.filter(dp => errorMessageLines.indexOf(dp.line) < 0).length + errorMessageLines.length;
            } else if (this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.uploadOption == ProjectAdjustmentImport.SALE_PRICE_ADJUSTMENT_INFORMATION) {
                let salePriceRecords = this.projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data.salePriceDataToBeProcessed;
                return salePriceRecords.filter(dp => errorMessageLines.indexOf(dp.line) < 0).length + errorMessageLines.length;
            } else {
                return this.projectMatterUpdateTransaction.totalMattersCount;
            }

        } else {
            return this.projectMatterUpdateTransaction.totalMattersCount;
        }


    }

    constructor(public dialog: DialogRef<MassUpdateProgressModalContext>) {
        this.context = dialog.context;
        dialog.setCloseGuard(this);
    }

    ngOnInit(): void {
        this.matterUpdateAction = Utils.convertStringToTitleCase(this.projectMatterUpdateTransaction.projectMatterUpdateMessageAction);
    }

    get uploadPercentage(): number {
        let percentage: number;
        if(this.isProjectMatterUpdateTransactionImportAdj){
            let completedOrFailedCount = this.successfulMattersCount + this.failedMatterCount;
            percentage = Math.round(100 * completedOrFailedCount / this.totalMattersCount);
        } else {
            percentage = Math.round(100 * this.successfulMattersCount / this.totalMattersCount);
        }

        //In beginning it takes little bit extra time to process first matter so faking it to show some progress
        return percentage < 10 ? 10 : percentage;
    }

    isTransactionFinished() {
        return this.projectMatterUpdateTransaction.isTransactionFinished();
    }

    stopMassUpdate(): void {
        this.projectMatterUpdateTransaction.stopMatterUpdate();
        this.dialog.close();
    }

    ok(): void {
        this.dialog.close();
    }

    beforeDismiss(): boolean {
        return true;
    }

    beforeClose(): boolean {
        return false;
    }
}

