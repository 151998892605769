import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {StatementAdjustmentKey} from '../../statement-adjustment-constants';

export class SoaExpenseAdjustment extends BaseEntity{
    id : number;
    numberOfMonthsCommonExpenses : number;
    taxType: string; //HST | GST
    applyTax: string; //YES | NO
    infoOnly: boolean;
    adjustPercentage : number;
    taxAmount: number;
    creditAmount : number;
    textReferencingAgreement:string;
    monthlyAmountType: string;
    additionalMonthlyAmount : number
    expenseType : string;

    constructor(soaExpenseAdjustment? : SoaExpenseAdjustment) {
        super(soaExpenseAdjustment);
        if (!soaExpenseAdjustment) {
            this.numberOfMonthsCommonExpenses = 0;
            this.additionalMonthlyAmount = 0;

        }
    }

    appliedRate(considerationTaxes  :ConsiderationTaxes) : number {
        return considerationTaxes.hstRate ;
    }

    appliedTaxRate(considerationTaxes  :ConsiderationTaxes) : string {
        return this.isTaxRateGst(considerationTaxes) ? 'GST' : considerationTaxes.rateType;
    }

    isTaxRateGst(considerationTaxes  :ConsiderationTaxes) : boolean {
        return (considerationTaxes.provinceDefaultTaxRate == 'GST');
    }

    isAdjustmentTypeSaleIncentive(): boolean{
        return this.expenseType == StatementAdjustmentKey.SALES_INCENTIVE;
    }
    isAdditionalAmountApplicable(): boolean {
        return this.monthlyAmountType !== 'COMMON_EXPENSE_AMOUNT';
    }
    isOtherAmountSelected(): boolean{
        return this.monthlyAmountType === 'OTHER_AMOUNT';
    }




}
