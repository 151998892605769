import {StatementAdjustmentAmountTypes, StatementAdjustmentExpenseTypes, StatementAdjustmentKey} from './statement-adjustment-constants';
import {MatterTax} from '../shared/property-taxes';
import {HstSalePrice} from './model/hst-sale-price-soa';
import {SoaCommonExpense} from './model/common-expense-soa';
import {SoaWater} from './water-soa';
import {SoaHeat} from './model/heat-soa';
import {SalePriceAdjustment} from './sale-price-adjustment/sale-price-adjustment';
import {InterestSoa} from './interest-soa';
import {
    SoAdjAssumedMortgage,
    SoAdjOtherFixed,
    SoAdjOtherProrated,
    SoAdjTarionWarranty,
    SoAdjTaxOther,
    SoAdjTenancyCurrent,
    SoAdjTenancyPrepaid,
    SoAdjVTBMortgage
} from './model';
import {UUIDUtil} from '../../main/uuid-util';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';
import {SalePriceAdjustmentFactory} from './sale-price-adjustment/sale-price-adjustment-factory';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {SoaExpenseAdjustment} from './modals/reserve-fund/soa-expense-adjustment';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {SoAdjInterimOccupancyFee} from './model/so-adj-interim-occupancy-fee';
import {SoaRealtyTaxAdjustment} from './modals/realty-tax/soa-realty-tax-adjustment';
import {SoAdjInterimEarlyPossessionFee} from './model/so-adj-interim-early-possession-fee';
import {SoAdjFinalOccupancyFee} from './model/so-adj-final-occupancy-fee';
import {SoAdjFinalEarlyPossessionFee} from './model/so-adj-final-early-possession-fee';
import {StatementAdjustmentUtil} from './statement-adjustment-util';
import {Matter} from '../shared';
import {SoAdjInterestOnDepositCondo} from './so-adj-interest-on-deposit-condo';
import {SoAdjExpenseOccupancyPeriod} from './modals/total-during-occupancy-period/so-adj-expense-occupancy-period';
import {SoAdjForm4Charge} from './model/so-adj-form-4-charge';
import {SoAdjHeading} from './model/soa-adj-custom-format';
import {Project} from '../../projects/shared/project';
import {SoAdjTotalOccupancyFee} from './model/so-adj-total-occupancy-fee';
import {SoAdjPurchasePriceDeferredPortion} from './model/so-adj-purchase-price-deferred-portion';
import {SoAdjCreditVendorTaxRebate} from './modals/credit-vendor-tax-rebate/so-adj-credit-vendor-tax-rebate';
import {SoAdjComponent} from './model/so-adj-component';
import {SoAdjPurchasePricePercentage} from './model/so-adj-purchase-price-percentage';
import {SoAdjConsiderationPaidOnInterimClosing} from './modals/consideration-paid-on-interim-closing/so-adj-consideration-paid-on-interim-closing';
import {SoAdjAmountPaidOnInterimClosing} from './modals/amount-paind-on-interim-closing/so-adj-amount-paid-on-interim-closing';
import {ItemizedCreditToVendorPurchaserONStrategy} from './sale-price-adjustment/itemized-credit-to-vendor-purchaser-on-strategy';
import {ItemizedCreditToVendorPurchaserABStrategy} from './sale-price-adjustment/itemized-credit-to-vendor-purchaser-ab-strategy';
import {SoAdjHCRAFee} from './model/so-adj-hcra-fee';
import {SoAdjVacantOccupancyTax} from './model/so-adj-vacant-occupancy-tax';


export class StatementAdjustment  extends  BaseEntity{

    id : number;
    static clientAssignedIdentifierEntity: boolean = true;//Marker property for indicating client side assigns the ID to this entity
    description : string;
    isSelected : boolean ;
    isOutOfRange: boolean;
    outOfRangeAdjustmentName: string;
    addedFlag : boolean;
    amount : number;
    itemKey : string;
    statementOfAdjustmentCreditType : string;
    matterTax : MatterTax;
    propertyMatterTaxId:number;
    hstSalePrice : HstSalePrice;
    soaCommonExpense: SoaCommonExpense;
    soaExpenseAdjustment: SoaExpenseAdjustment;
    soAdjExpenseOccupancyPeriod: SoAdjExpenseOccupancyPeriod;
    soAdjPurchasePriceDeferredPortion: SoAdjPurchasePriceDeferredPortion;
    soAdjCreditVendorTaxRebate: SoAdjCreditVendorTaxRebate;
    soAdjConsiderationPaidOnInterimClosing: SoAdjConsiderationPaidOnInterimClosing;
    soAdjAmountPaidOnInterimClosing: SoAdjAmountPaidOnInterimClosing;
    soaRealtyTaxAdjustment : SoaRealtyTaxAdjustment;
    soaWater : SoaWater;
    soaHeat : SoaHeat;
    soAdjInterestCal : InterestSoa;
    soAdjTaxOther : SoAdjTaxOther;
    salePriceAdjustmentHSTFactor : SalePriceAdjustment;
    soAdjOtherFixed: SoAdjOtherFixed;
    soAdjOtherFixedPayableOnOccupancy: SoAdjOtherFixed;
    soAdjOtherProrated: SoAdjOtherProrated;
    soAdjOtherProratedOnPercentageInterest: SoAdjOtherFixed;
    soAdjPurchasePricePercentage: SoAdjPurchasePricePercentage;
    soAdjTenancyCurrent: SoAdjTenancyCurrent;
    soAdjTenancyPrepaid: SoAdjTenancyPrepaid;
    soAdjAssumedMortgage: SoAdjAssumedMortgage;
    soAdjTarionWarranty: SoAdjTarionWarranty;
    soAdjHCRAFee: SoAdjHCRAFee;
    soAdjVacantOccupancyTax: SoAdjVacantOccupancyTax;
    soAdjVTBMortgage: SoAdjVTBMortgage;
    soAdjHomeOwnersResidentFee: SoAdjOtherProrated;
    soAdjInterimOccupancyFee: SoAdjInterimOccupancyFee;
    soAdjInterimEarlyPossessionFee: SoAdjInterimEarlyPossessionFee;
    soAdjFinalOccupancyFee: SoAdjFinalOccupancyFee;
    soAdjTotalOccupancyFee: SoAdjTotalOccupancyFee;
    soAdjFinalEarlyPossessionFee: SoAdjFinalEarlyPossessionFee;
    soAdjInterestOnDepositCondo : SoAdjInterestOnDepositCondo;
    soAdjItemizedCreditToVendorPurchaser: ItemizedCreditToVendorPurchaserONStrategy | ItemizedCreditToVendorPurchaserABStrategy;
    soAdjHeading :SoAdjHeading;
    soAdjForm4Charge: SoAdjForm4Charge;
    soAdjComponent: SoAdjComponent;
    taxPortion: number; // for SoAdjTaxesPaidDuringOccupancy
    provinceCode: ProvinceCode;
    salePriceAdjustmentHeadingId : number;
    parcelLegalDescriptionId : number;
    adjustmentStatus : string;
    fieldCode : number;
    matterId : number;
    applyToAdjustmentRecord : boolean = true; // a null value here is used on mass update to imply "no change" needed
    sourceProjectAdjustmentId : number;
    infoOnly: boolean;
    linkId : number;

    constructor( adjustmentMode : string,provinceCode: ProvinceCode, soa?: StatementAdjustment, ignoreIds?:boolean) {
        super();
        this.provinceCode = provinceCode;
        if (soa) {
            for (let prop in soa) {
                if (soa.hasOwnProperty(prop)) {
                    this[prop] = soa[prop];
                }
            }
            if (soa.matterTax) {
                this.matterTax = new MatterTax(soa.matterTax);
            }
            if (soa.hstSalePrice) {
                this.hstSalePrice = new HstSalePrice(soa.hstSalePrice.rateType ,soa.hstSalePrice);
            }
            if (soa.soaCommonExpense) {
                this.soaCommonExpense = new SoaCommonExpense(soa.soaCommonExpense);
            }
            if (soa.soaExpenseAdjustment) {
                this.soaExpenseAdjustment = new SoaExpenseAdjustment(soa.soaExpenseAdjustment);
            }
            if (soa.soAdjExpenseOccupancyPeriod) {
                this.soAdjExpenseOccupancyPeriod = new SoAdjExpenseOccupancyPeriod(soa.soAdjExpenseOccupancyPeriod);
            }
            if(soa.soaRealtyTaxAdjustment){
                this.soaRealtyTaxAdjustment = new SoaRealtyTaxAdjustment(soa.soaRealtyTaxAdjustment);
            }
            if (soa.soaWater) {
                this.soaWater = new SoaWater(soa.soaWater);
            }
            if (soa.soaHeat) {
                this.soaHeat = new SoaHeat(soa.soaHeat);
            }
            if (soa.soAdjTaxOther) {
                this.soAdjTaxOther = new SoAdjTaxOther(soa.soAdjTaxOther);
            }
            if (soa.soAdjInterestCal) {
                this.soAdjInterestCal = new InterestSoa(soa.soAdjInterestCal);
            }
            if (soa.soAdjOtherFixed) {
                this.soAdjOtherFixed = new SoAdjOtherFixed(soa.soAdjOtherFixed);
            }
            if (soa.soAdjOtherFixedPayableOnOccupancy) {
                this.soAdjOtherFixedPayableOnOccupancy = new SoAdjOtherFixed(soa.soAdjOtherFixedPayableOnOccupancy);
            }
            if (soa.soAdjOtherProrated) {
                this.soAdjOtherProrated = new SoAdjOtherProrated(soa.soAdjOtherProrated);
            }
            if (soa.soAdjHomeOwnersResidentFee) {
                this.soAdjHomeOwnersResidentFee = new SoAdjOtherProrated(soa.soAdjHomeOwnersResidentFee);
            }
            if (soa.soAdjTenancyCurrent){
                this.soAdjTenancyCurrent = new SoAdjTenancyCurrent(soa.soAdjTenancyCurrent);
            }
            if (soa.soAdjTenancyPrepaid){
                this.soAdjTenancyPrepaid = new SoAdjTenancyPrepaid(soa.soAdjTenancyPrepaid);
            }
            if (soa.soAdjAssumedMortgage){
                this.soAdjAssumedMortgage = new SoAdjAssumedMortgage(soa.soAdjAssumedMortgage);
            }
            if (soa.soAdjTarionWarranty){
                this.soAdjTarionWarranty = new SoAdjTarionWarranty(soa.soAdjTarionWarranty);
            }
            if (soa.soAdjHCRAFee){
                this.soAdjHCRAFee = new SoAdjHCRAFee(soa.soAdjHCRAFee);
            }
            if (soa.soAdjVacantOccupancyTax){
                this.soAdjVacantOccupancyTax = new SoAdjVacantOccupancyTax(soa.soAdjVacantOccupancyTax);
            }
            if (soa.soAdjVTBMortgage){
                this.soAdjVTBMortgage = new SoAdjVTBMortgage(soa.soAdjVTBMortgage);
            }
            if (soa.soAdjInterimOccupancyFee) {
                this.soAdjInterimOccupancyFee = new SoAdjInterimOccupancyFee(soa.soAdjInterimOccupancyFee);
            }
            if (soa.soAdjInterimEarlyPossessionFee) {
                this.soAdjInterimEarlyPossessionFee = new SoAdjInterimEarlyPossessionFee(soa.soAdjInterimEarlyPossessionFee);
            }
            if (soa.salePriceAdjustmentHSTFactor) {
                this.salePriceAdjustmentHSTFactor = SalePriceAdjustmentFactory.getSalePriceAdjustment(adjustmentMode, provinceCode, soa.salePriceAdjustmentHSTFactor);
            }
            if (soa.soAdjFinalOccupancyFee) {
                this.soAdjFinalOccupancyFee = new SoAdjFinalOccupancyFee(soa.soAdjFinalOccupancyFee);
            }
            if (soa.soAdjTotalOccupancyFee) {
                this.soAdjTotalOccupancyFee = new SoAdjTotalOccupancyFee(soa.soAdjTotalOccupancyFee);
            }
            if (soa.soAdjFinalEarlyPossessionFee) {
                this.soAdjFinalEarlyPossessionFee = new SoAdjFinalEarlyPossessionFee(soa.soAdjFinalEarlyPossessionFee);
            }
            if (soa.soAdjInterestOnDepositCondo) {
                this.soAdjInterestOnDepositCondo = new SoAdjInterestOnDepositCondo(soa.soAdjInterestOnDepositCondo);
            }
            if (soa.soAdjHeading) {
                this.soAdjHeading = new SoAdjHeading(soa.soAdjHeading);
            }
            if (soa.soAdjForm4Charge) {
                this.soAdjForm4Charge = new SoAdjForm4Charge(soa.soAdjForm4Charge);
            }
            if (soa.soAdjComponent) {
                this.soAdjComponent = new SoAdjComponent(soa.soAdjComponent);
            }

            if(soa.soAdjPurchasePriceDeferredPortion){
                this.soAdjPurchasePriceDeferredPortion = new SoAdjPurchasePriceDeferredPortion(soa.soAdjPurchasePriceDeferredPortion);
            }
            if (soa.soAdjCreditVendorTaxRebate) {
                this.soAdjCreditVendorTaxRebate = new SoAdjCreditVendorTaxRebate(soa.soAdjCreditVendorTaxRebate);
            }
            if (soa.soAdjConsiderationPaidOnInterimClosing) {
                this.soAdjConsiderationPaidOnInterimClosing = new SoAdjConsiderationPaidOnInterimClosing(soa.soAdjConsiderationPaidOnInterimClosing);
            }
            if (soa.soAdjItemizedCreditToVendorPurchaser) {
                this.soAdjItemizedCreditToVendorPurchaser = SalePriceAdjustmentFactory.getItemizedCreditToVendorPurchaserAdjustment(adjustmentMode ,provinceCode, soa.soAdjItemizedCreditToVendorPurchaser);
            }
            if (soa.soAdjOtherProratedOnPercentageInterest) {
                this.soAdjOtherProratedOnPercentageInterest = new SoAdjOtherFixed(soa.soAdjOtherProratedOnPercentageInterest);
            }
            if (soa.soAdjPurchasePricePercentage) {
                this.soAdjPurchasePricePercentage = new SoAdjPurchasePricePercentage(soa.soAdjPurchasePricePercentage);
            }

            else {
                if (this.hstSalePrice) {
                    this.createDefaultSalePriceAdjustmentHSTFactor(adjustmentMode);
                }
            }

            if(ignoreIds) {
                for(let prop in this){
                    if(this.hasOwnProperty(prop) && this[prop] instanceof Object && this[prop].hasOwnProperty('id')){
                        this[prop]['id'] = null;
                    }
                }
                if (this.matterTax){
                    this.matterTax.id = UUIDUtil.getUUID();
                }

            }
        }


        if(!this.id) {
            this.id = UUIDUtil.getUUID();
        }

    }

    isCreditVendor() : boolean {
        return (this.statementOfAdjustmentCreditType  == StatementAdjustmentAmountTypes.VENDOR)
    }
    isCreditPurchaser() : boolean {
        return (this.statementOfAdjustmentCreditType  == StatementAdjustmentAmountTypes.PURCHASER)
    }
    isDepositAdjustment() : boolean {
        return (this.itemKey == StatementAdjustmentKey.DEPOSIT);
    }

    isSalePrice() : boolean {
        return (this.itemKey == StatementAdjustmentKey.SALE_PRICE);
    }

    isSalePriceAdjustmentHeading() : boolean {
        return (this.itemKey == StatementAdjustmentKey.SALE_PRICE_ADJUSTMENT_HEADING);
    }

    isInterest() : boolean {
        return (this.itemKey == StatementAdjustmentKey.INTEREST);
    }

    isInterestOnDepositCondo() : boolean {
        return (this.itemKey == StatementAdjustmentKey.INTEREST_ON_DEPOSIT_CONDO);
    }
    isInterestOnDepositInterestCondo() : boolean {
        return (this.itemKey == StatementAdjustmentKey.INTEREST_ON_DEPOSIT_INTEREST_CONDO);
    }
    isInterestOnDepositUnderOldCondo() : boolean {
        return (this.itemKey == StatementAdjustmentKey.INTEREST_ON_DEPOSIT_UNDER_OLD_CONDO);
    }

    isItemizedCreditToVendorPurchaser() : boolean {
        return (this.itemKey == StatementAdjustmentKey.ITEMIZED_CREDIT_TO_VENDOR_PURCHASER);
    }

    isComponentAdjustment() : boolean {
        return (this.itemKey == StatementAdjustmentKey.COMPONENT_ADJUSTMENT);
    }

    isInterestOnLumpSum() : boolean {
        return (this.itemKey == StatementAdjustmentKey.INTEREST_ON_LUMP_SUM);
    }

    isInterestOnDeferredMoniesDuringOccupancy() : boolean {
        return (this.itemKey == StatementAdjustmentKey.INTEREST_ON_DEFERRED_MONIES_DURING_OCCUPANCY);
    }

    isOtherFixed() : boolean {
        return (this.itemKey == StatementAdjustmentKey.OTHER_FIXED);
    }

    isOtherFixedPayableOnOccupancy() : boolean {
        return (this.itemKey == StatementAdjustmentKey.OTHER_FIXED_PAYABLE_ON_OCCUPANCY);
    }

    isOtherProrated() : boolean {
        return (this.itemKey == StatementAdjustmentKey.OTHER_PRORATED);
    }

    isOtherProratedOnPercentageInterest() : boolean {
        return (this.itemKey == StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST);
    }

    isPercentageOfPurchasePrice() : boolean {
        return (this.itemKey == StatementAdjustmentKey.PURCHASE_PRICE_PERCENTAGE);
    }

    isSalePriceMinusNetSalePrice() : boolean {
        return (this.itemKey == StatementAdjustmentKey.SALE_PRICE_MINUS_NET_SALE_PRICE);
    }

    isHomeOwnersResidentFee(): boolean {
        return (this.itemKey == StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE);
    }

    isTenancyCurrent() : boolean {
        return (this.itemKey == StatementAdjustmentKey.TENANCY_CURRENT);
    }

    isTenancyPrepaid() : boolean {
        return (this.itemKey == StatementAdjustmentKey.TENANCY_PREPAID);
    }

    isHstAdjustment() : boolean {
        return (this.itemKey == StatementAdjustmentKey.HST_ADJUSTMENT);
    }

    isCreditVendorTaxRebateAdjustment() : boolean {
        return (this.itemKey == StatementAdjustmentKey.CREDIT_VENDOR_TAX_REBATE);
    }

    isCommonExpenseAdjustment(): boolean {
        return (this.itemKey == StatementAdjustmentExpenseTypes.COMMON_EXPENSE);
    }

    isTotalDuringOccupancyPeriodAdjustment(): boolean {
        return (this.itemKey == StatementAdjustmentKey.OCCUPANCY_PERIOD);
    }

    isConsiderationPaidOnInterimClosing(): boolean {
        return (this.itemKey == StatementAdjustmentKey.CONSIDERATION_PAID_ON_INTERIM_CLOSING);
    }

    isAmountPaidOnInterimClosing(): boolean {
        return (this.itemKey == StatementAdjustmentKey.AMOUNT_PAID_ON_INTERIM_CLOSING);
    }

    isPurchasePriceDeferredPortionAdjustment(): boolean {
        return (this.itemKey == StatementAdjustmentKey.PURCHASE_PRICE_DEFERRED_PORTION);
    }

    isReserveFundAdjustment(): boolean {
        return (this.itemKey == StatementAdjustmentKey.RESERVE_FUND);
    }

    isSalesIncentiveAdjustment(): boolean {
        return (this.itemKey == StatementAdjustmentKey.SALES_INCENTIVE);
    }

    isPreviousYearRealtyTaxAdjustment(): boolean {
        return (this.itemKey == StatementAdjustmentKey.REALTY_TAXES);
    }

    isTaxesPaidByVendorAdjustment(): boolean {
        return (this.itemKey == StatementAdjustmentKey.REALTY_TAXES_PAID_BY_VENDOR);
    }

    isCommonExpenseAdjustmentFromProperty(): boolean {
        return (this.itemKey == StatementAdjustmentExpenseTypes.COMMON_EXPENSE && !this.addedFlag);
    }

    isPOTLCommonExpenseAdjustment(): boolean {
        return (this.itemKey == StatementAdjustmentExpenseTypes.POTL_COMMON_EXPENSE);
    }

    isOccupancyCommonExpenseAdjustment(): boolean {
        return (this.itemKey == StatementAdjustmentExpenseTypes.OCCUPANCY_FEES);
    }

    isWater() : boolean {
        return (this.itemKey == StatementAdjustmentKey.WATER);
    }

    isHeat() : boolean {
        return (this.itemKey == StatementAdjustmentKey.HEAT);
    }

    isHSTOtherFactor() : boolean {
        return (this.itemKey == StatementAdjustmentKey.FACTOR_IN_HST_REBATE);
    }

    isOriginPropertyTaxes() : boolean{
       return  !this.addedFlag && this.isAnyPropertyTaxes() ;
    }

    isAnyPropertyTaxes() : boolean{
        return this.itemKey == StatementAdjustmentKey.PROPERTY_TAX || this.itemKey == StatementAdjustmentKey.TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY;
    }

    isAdjOfTypeTaxBeginningOfYearUntilOcc(): boolean {
        return (this.itemKey === StatementAdjustmentKey.TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY);
    }

    isPropertyTax() : boolean{
        return this.itemKey == StatementAdjustmentKey.PROPERTY_TAX
    }

    isRealtyTax(matterTax? : MatterTax) : boolean{
        if(matterTax){
            return  matterTax.taxType == StatementAdjustmentKey.REALTY_TAXES;
        }else{
            return this.matterTax && this.matterTax.taxType == StatementAdjustmentKey.REALTY_TAXES;
        }

    }

    isPOTLCommonExpense(): boolean{
        return (this.soaCommonExpense.expenseType == StatementAdjustmentExpenseTypes.POTL_COMMON_EXPENSE);
    }

    isOccupancyCommonExpense(): boolean{
        return (this.soaCommonExpense.expenseType == StatementAdjustmentExpenseTypes.OCCUPANCY_FEES);
    }

    isStandardCommonExpense(): boolean{
        return (this.soaCommonExpense.expenseType == StatementAdjustmentExpenseTypes.COMMON_EXPENSE);
    }

    isTaxOther() : boolean {
        return (this.itemKey == StatementAdjustmentKey.TAX_OTHER);
    }

    isAssumedMortgage(): boolean {
        return (this.itemKey == StatementAdjustmentKey.ASSUMED_MORTGAGE);
    }

    isTarionWarranty(): boolean {
        return (this.itemKey == StatementAdjustmentKey.TARION_WARRANTY);
    }

    isHCRAFee(): boolean {
        return (this.itemKey == StatementAdjustmentKey.HCRA_FEE);
    }

    isVacantOccupancyTax(): boolean {
        return (this.itemKey == StatementAdjustmentKey.VACANT_OCCUPANCY_TAX);
    }


    isTarionWarrantyWithAdditionalConsiderationToVendorEligibleForTaxRebate(): boolean {
        return (this.itemKey == StatementAdjustmentKey.TARION_WARRANTY && this.soAdjTarionWarranty && this.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE');
    }

    isHCRAWithAdditionalConsiderationToVendorEligibleForTaxRebate(): boolean {
        return (this.itemKey == StatementAdjustmentKey.HCRA_FEE && this.soAdjHCRAFee && this.soAdjHCRAFee.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE');
    }

    isTarionWithEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(): boolean {
        return (this.itemKey == StatementAdjustmentKey.TARION_WARRANTY && this.soAdjTarionWarranty
        && this.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE' && this.soAdjTarionWarranty.isAdjustmentPaidByVendorOnInterimClosing == 'YES');
    }

    isHCRAWithAdditionalConsiderationToVendorNotEligibleForTaxRebate(): boolean {
        return (this.itemKey == StatementAdjustmentKey.HCRA_FEE && this.soAdjHCRAFee && this.soAdjHCRAFee.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE');
    }

    isTarionWarrantyWithAdditionalConsiderationToVendorNotEligibleForTaxRebate(): boolean {
        return (this.itemKey == StatementAdjustmentKey.TARION_WARRANTY && this.soAdjTarionWarranty && this.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE');
    }

    isTarioNotEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(): boolean {
        return (this.itemKey == StatementAdjustmentKey.TARION_WARRANTY && this.soAdjTarionWarranty
        && this.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'
        && this.soAdjTarionWarranty.isAdjustmentPaidByVendorOnInterimClosing == 'YES');
    }

    isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebatePlusTax(): boolean {
        return ((this.itemKey == StatementAdjustmentKey.OTHER_FIXED && this.soAdjOtherFixed && this.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX')
            ||(this.itemKey == StatementAdjustmentKey.OTHER_FIXED_PAYABLE_ON_OCCUPANCY      && this.soAdjOtherFixedPayableOnOccupancy && this.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX')
            ||(this.itemKey == StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST && this.soAdjOtherProratedOnPercentageInterest && this.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX')
        );
    }

    isOtherFixedWithNotEligibleForTaxRebatePlusTaxAndAdjustmentPaidOnInterimClosingYes(): boolean {
        return ((this.itemKey == StatementAdjustmentKey.OTHER_FIXED && this.soAdjOtherFixed  && this.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX'
                                                                                             && this.soAdjOtherFixed.adjustmentPaidOnInterimClosing == 'YES')
            ||(this.itemKey == StatementAdjustmentKey.OTHER_FIXED_PAYABLE_ON_OCCUPANCY       && this.soAdjOtherFixedPayableOnOccupancy && this.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX'
                                                                                             && this.soAdjOtherFixedPayableOnOccupancy.adjustmentPaidOnInterimClosing == 'YES')
            ||(this.itemKey == StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST  && this.soAdjOtherProratedOnPercentageInterest && this.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX'
                                                                                             && this.soAdjOtherProratedOnPercentageInterest .adjustmentPaidOnInterimClosing == 'YES')
        );
    }

    isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebate(): boolean {
        return ((this.itemKey == StatementAdjustmentKey.OTHER_FIXED && this.soAdjOtherFixed && this.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE')
            ||(this.itemKey == StatementAdjustmentKey.OTHER_FIXED_PAYABLE_ON_OCCUPANCY      && this.soAdjOtherFixedPayableOnOccupancy && this.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE')
            ||(this.itemKey == StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST && this.soAdjOtherProratedOnPercentageInterest && this.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE')
        );
    }

    isOtherFixedWithNotEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(): boolean {
        return ((this.itemKey == StatementAdjustmentKey.OTHER_FIXED && this.soAdjOtherFixed && this.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'
                                                                                            && this.soAdjOtherFixed.adjustmentPaidOnInterimClosing == 'YES')
            ||(this.itemKey == StatementAdjustmentKey.OTHER_FIXED_PAYABLE_ON_OCCUPANCY      && this.soAdjOtherFixedPayableOnOccupancy && this.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'
                                                                                            && this.soAdjOtherFixedPayableOnOccupancy.adjustmentPaidOnInterimClosing == 'YES')
            ||(this.itemKey == StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST && this.soAdjOtherProratedOnPercentageInterest && this.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'
                                                                                            && this.soAdjOtherProratedOnPercentageInterest .adjustmentPaidOnInterimClosing == 'YES')
        );
    }

    isOtherFixedWithAdditionalConsiderationToVendorEligibleForTaxRebate(): boolean {
        return ((this.itemKey == StatementAdjustmentKey.OTHER_FIXED && this.soAdjOtherFixed && this.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
            || (this.itemKey == StatementAdjustmentKey.OTHER_FIXED_PAYABLE_ON_OCCUPANCY     && this.soAdjOtherFixedPayableOnOccupancy && this.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
            || (this.itemKey == StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST && this.soAdjOtherProratedOnPercentageInterest && this.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
        );
    }

    isOtherFixedWithEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(): boolean {
        return ((this.itemKey == StatementAdjustmentKey.OTHER_FIXED && this.soAdjOtherFixed  && this.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'
                                                                                             && this.soAdjOtherFixed.adjustmentPaidOnInterimClosing == 'YES')
            || (this.itemKey == StatementAdjustmentKey.OTHER_FIXED_PAYABLE_ON_OCCUPANCY      && this.soAdjOtherFixedPayableOnOccupancy && this.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'
                                                                                             && this.soAdjOtherFixedPayableOnOccupancy.adjustmentPaidOnInterimClosing == 'YES')
            || (this.itemKey == StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST && this.soAdjOtherProratedOnPercentageInterest && this.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'
                                                                                             && this.soAdjOtherProratedOnPercentageInterest .adjustmentPaidOnInterimClosing == 'YES')
        );
    }


    isVTBMortgage(): boolean {
        return (this.itemKey == StatementAdjustmentKey.VTB_MORTGAGE);
    }

    isReserveForVTBMtg() : boolean {
        return (this.itemKey == StatementAdjustmentKey.RESERVE_FOR_VTB_MTG);
    }

    isInterimOccupancyFee(): boolean{
        return (this.itemKey === StatementAdjustmentKey.INTERIM_OCCUPANCY_FEE);
    }

    isInterimEarlyPossessionFee(): boolean{
        return (this.itemKey === StatementAdjustmentKey.INTERIM_EARLY_POSSESSION_FEE);
    }

    isFinalOccupancyFee(): boolean{
        return (this.itemKey === StatementAdjustmentKey.FINAL_OCCUPANCY_FEE);
    }

    isTotalOccupancyFee(): boolean{
        return (this.itemKey === StatementAdjustmentKey.TOTAL_OCCUPANCY_FEE);
    }

    isFinalEarlyPossessionFee(): boolean {
        return (this.itemKey === StatementAdjustmentKey.FINAL_EARLY_POSSESSION_FEE);
    }

    isTaxesBeginningOfYearUntilOccupancy(): boolean {
        return (this.itemKey === StatementAdjustmentKey.TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY);
    }

    isTaxesPaidDuringOccupancy(): boolean{
        return (this.itemKey === StatementAdjustmentKey.TAXES_PAID_DURING_OCCUPANCY);
    }

    isForm4Charges(): boolean{
        return (this.itemKey === StatementAdjustmentKey.FORM_4_CHARGE);
    }

    ignorePrintHeadingInUpperCaseSetting(): boolean{
        return this.isComponentAdjustment() || this.isOtherFixed();
    }

    getCommonExpenseLabel(provinceCode? : string) : string{
        if(this.soaCommonExpense && this.soaCommonExpense.isStandardCommonExpense())
        {
            return provinceCode? provinceBasedFieldLabels.get('matter.soa.commonExpenses',provinceCode) : 'Common Expenses';
        }
        else if(this.soaCommonExpense && this.soaCommonExpense.isPOTLCommonExpense())
        {
            return 'POTL common expenses';
        }else if(this.soaCommonExpense && this.soaCommonExpense.isOccupancyCommonExpense())
        {
            return 'Occupancy Fees';
        }
        else{
            return '';
        }
    }

    getTotalDuringOccupancyPeriodLabel() : string{
        return  'Common Expenses';
    }

    updateFromCommonExpense(closingDate: string,isPaysForDateOfClosingVendor? : boolean) {
        if (this.soaCommonExpense) {
            this.statementOfAdjustmentCreditType = this.soaCommonExpense.adjustmentCreditType(closingDate, isPaysForDateOfClosingVendor);
            if (!this.infoOnly) {
                this.amount = this.soaCommonExpense.calculateVendorShareDifference(closingDate, isPaysForDateOfClosingVendor);
            }else{
                this.amount = 0;
            }
        }
    }

    updateInterimEarlyPossessionFeeOnClosingDateChange(elapsedDays: number , numberOfDaysInMonth: number) {
        if(this.soAdjInterimEarlyPossessionFee){
            this.amount = this.soAdjInterimEarlyPossessionFee.getAdjustmentAmount(elapsedDays, numberOfDaysInMonth);
            this.description = "Interim Early Possession Fees";
            this.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
            this.infoOnly = this.soAdjInterimEarlyPossessionFee.infoOnly;
        }

    }

    updateInterimOccupancyFeeOnClosingDateChange(elapsedDays : number , numberOfDaysInMonth: number, matter: Matter, project: Project) {
        if (this.soAdjInterimOccupancyFee) {
            this.soAdjInterimOccupancyFee.setAutomaticCommonExpenseValues(matter);
            this.soAdjInterimOccupancyFee.setAutomaticMortgageValues(matter, project);
            this.soAdjInterimOccupancyFee.setAutomaticTaxesValues(matter, project);
            this.amount = this.soAdjInterimOccupancyFee.getAdjustmentAmount(elapsedDays, numberOfDaysInMonth, matter.isPaysForDateOfClosingPurchaser, matter.provinceCode);
            this.description = this.soAdjInterimOccupancyFee.getDescription();
            this.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
            this.infoOnly = this.soAdjInterimOccupancyFee.infoOnly;
        }
    }

    updateFinalOccupancyFeeOnClosingDateChange(occupancyDate: string, adjustAsAtClosingDateInterim: string, adjustAsAtClosingDate: string,isPaysForDateOfClosingPurchaser? : boolean, provinceCode ?: string) {
        if (this.soAdjFinalOccupancyFee) {
            this.amount = this.soAdjFinalOccupancyFee.getCreditAmount(occupancyDate, adjustAsAtClosingDateInterim, adjustAsAtClosingDate, isPaysForDateOfClosingPurchaser, provinceCode);
            this.description = "Occupancy Fees";
            if (this.amount >= 0) {
                this.statementOfAdjustmentCreditType = this.soAdjFinalOccupancyFee.getCreditType();
            }
            else{
                this.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
            }
        }
    }

    updateTotalOccupancyFeeOnClosingDateChange(matter: Matter, project: Project) {
        if (this.soAdjTotalOccupancyFee) {
            this.soAdjTotalOccupancyFee.updateFromMatterAndProject(matter, project);
            this.amount = this.soAdjTotalOccupancyFee.getCreditAmount();
            this.description = this.soAdjTotalOccupancyFee.getDescription(this.soAdjTotalOccupancyFee.isProjectOccFeeBasedOnPhantomMortgage, matter.provinceCode);
            this.statementOfAdjustmentCreditType = this.soAdjTotalOccupancyFee.creditTo;
        }
    }

    updateFinalEarlyPossessionFeeOnClosingDateChange(occupancyDate: string, adjustAsAtClosingDateInterim: string, adjustAsAtClosingDate: string) {
        if (this.soAdjFinalEarlyPossessionFee) {
            this.amount = this.soAdjFinalEarlyPossessionFee.getCreditAmount(occupancyDate, adjustAsAtClosingDateInterim, adjustAsAtClosingDate);
            this.description = "Final Early Possession Fees";
            if (this.amount >= 0) {
                this.statementOfAdjustmentCreditType = this.soAdjFinalEarlyPossessionFee.getCreditType();
            }
            else{
                this.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
            }
        }
    }

    createDefaultSalePriceAdjustmentHSTFactor( adjustmentMode : string){
        this.salePriceAdjustmentHSTFactor = SalePriceAdjustmentFactory.getSalePriceAdjustment(adjustmentMode ,this.provinceCode);
        this.salePriceAdjustmentHSTFactor.agreementSalePrice = this.hstSalePrice.creditAmount;
        this.salePriceAdjustmentHSTFactor.netOutHstFromHSTSalePrice = 'NO';
        this.salePriceAdjustmentHSTFactor.salePriceAdjustmentFormat = 'CREDIT_VENDOR_NET_SALE_PRICE';
        this.salePriceAdjustmentHSTFactor.federalPortion = true;
        this.salePriceAdjustmentHSTFactor.provincialPortion = true;
    }

    canBeCopied(): boolean{
        return this.isAnyPropertyTaxes() || this.isHstAdjustment() || this.isCreditVendorTaxRebateAdjustment() || this.isCommonExpenseAdjustment() || this.isPOTLCommonExpenseAdjustment()
            || this.isOccupancyCommonExpenseAdjustment() || this.isTotalDuringOccupancyPeriodAdjustment() || this.isConsiderationPaidOnInterimClosing()
            || this.isWater() || this.isHeat() || this.isTaxOther() || this.isHSTOtherFactor() || this.isCommonExpenseAdjustmentFromProperty()
            || this.isInterest() || this.isInterestOnLumpSum() || this.isInterestOnDeferredMoniesDuringOccupancy() || this.isOtherFixed() || this.isOtherFixedPayableOnOccupancy() || this.isOtherProrated() || this.isTenancyCurrent() || this.isTenancyPrepaid()
            || this.isAssumedMortgage() || this.isTarionWarranty() || this.isHomeOwnersResidentFee() || this.isReserveFundAdjustment() || this.isSalesIncentiveAdjustment()
            || this.isPreviousYearRealtyTaxAdjustment() || this.isInterimEarlyPossessionFee() || this.isInterimOccupancyFee() || this.isFinalOccupancyFee() || this.isFinalEarlyPossessionFee()
            || this.isTaxesPaidByVendorAdjustment() || this.isSalePriceMinusNetSalePrice() || this.isInterestOnDepositCondo() || this.isTaxesPaidDuringOccupancy()
            || this.isInterestOnDepositInterestCondo() || this.isForm4Charges() || this.isPurchasePriceDeferredPortionAdjustment()
            || this.isTotalOccupancyFee() || this.isOtherProratedOnPercentageInterest() || this.isPercentageOfPurchasePrice() || this.isAmountPaidOnInterimClosing() || this.isInterestOnDepositUnderOldCondo() ||this.isComponentAdjustment() ||this.isHCRAFee() || this.isVacantOccupancyTax();
    }

    canBeRemoved(): boolean{
        return this.isInterest() || this.isInterestOnLumpSum() || this.isInterestOnDeferredMoniesDuringOccupancy() || this.isAnyPropertyTaxes() || this.isHstAdjustment() || this.isCommonExpenseAdjustment() ||
            this.isOccupancyCommonExpenseAdjustment() || this.isTotalDuringOccupancyPeriodAdjustment() || this.isPOTLCommonExpenseAdjustment() || this.isWater() || this.isHeat()
            || this.isTaxOther() || this.isHSTOtherFactor() || this.isOtherFixed() || this.isOtherFixedPayableOnOccupancy() || this.isOtherProrated() || this.isTenancyCurrent()
            || this.isTenancyPrepaid() || this.isAssumedMortgage() || this.isTarionWarranty() || this.isHomeOwnersResidentFee() || this.isReserveFundAdjustment() || this.isSalesIncentiveAdjustment()
            || this.isPreviousYearRealtyTaxAdjustment() || this.isTaxesPaidByVendorAdjustment() || this.isSalePriceMinusNetSalePrice() || this.isInterestOnDepositCondo() || this.isTaxesPaidDuringOccupancy()
            || this.isInterestOnDepositInterestCondo() || this.isPurchasePriceDeferredPortionAdjustment() || this.isCreditVendorTaxRebateAdjustment() || this.isConsiderationPaidOnInterimClosing() ||
            (this.isInterimEarlyPossessionFee() ) ||
            (this.isInterimOccupancyFee() ) ||
            (this.isFinalEarlyPossessionFee()) ||
            (this.isFinalOccupancyFee() ) ||
            (this.isForm4Charges() ) ||
            this.isItemizedCreditToVendorPurchaser() || this.isOtherProratedOnPercentageInterest() || this.isComponentAdjustment() ||
            this.isTotalOccupancyFee() || this.isPercentageOfPurchasePrice() || this.isReserveForVTBMtg() || this.isVTBMortgage() || this.isAmountPaidOnInterimClosing() || this.isInterestOnDepositUnderOldCondo() ||this.isHCRAFee() || this.isVacantOccupancyTax();
    }

    isAdjustmentStatusFinal() : boolean {
        return (this.adjustmentStatus  != ProgressionStatus.INTERIM);
    }

    isProjectAdjustmentNotAppliedOnMatter() : boolean {
        return (!this.applyToAdjustmentRecord && this.applyToAdjustmentRecord != undefined && this.isAdjustmentSourceFromProject);
    }

    get isAdjustmentSourceFromProject(): boolean{
        return this.sourceProjectAdjustmentId != undefined;
    }

    updateFinalTaxPortionFromInterimOccAdj(matter : Matter) : void {
        let taxInterimOccupancyAdj =  StatementAdjustmentUtil.findByKey(matter.interimStatementAdjustments,  StatementAdjustmentKey.INTERIM_OCCUPANCY_FEE);
        this.taxPortion = this.getTaxPortionOfOccupancyFeesValue(matter, taxInterimOccupancyAdj)

    }

    getTaxPortionOfOccupancyFeesValue(matter : Matter,taxInterimOccupancyAdj : StatementAdjustment) : number {
        if(taxInterimOccupancyAdj && taxInterimOccupancyAdj.soAdjInterimOccupancyFee && taxInterimOccupancyAdj.soAdjInterimOccupancyFee.taxesTotal && taxInterimOccupancyAdj.soAdjInterimOccupancyFee.taxesTotal > 0){
            return taxInterimOccupancyAdj.soAdjInterimOccupancyFee.taxesTotal * 12;
        } else if(this.taxPortion){
            return this.taxPortion;
        } else {
            return 0;
        }
    }
}

export const ProgressionStatus = {
    INTERIM :'INTERIM',
    FINAL : 'FINAL'
};

//export type ProgressionStatusType = ProgressionStatus.INTERIM | ProgressionStatus.FINAL;
//export type ProgressionStatusType = 'INTERIM' | 'FINAL';
export type ProgressionStatusType = typeof ProgressionStatus.INTERIM | typeof ProgressionStatus.FINAL;


export class StatementAdjustmentOrder {


    constructor(statementAdjustmentOrder?: StatementAdjustmentOrder) {


        if (statementAdjustmentOrder) {
            for (let prop in statementAdjustmentOrder) {
                if (statementAdjustmentOrder.hasOwnProperty(prop)) {
                    this[prop] = statementAdjustmentOrder[prop];
                }
            }
        }
    }
    adjustmentIndex : number;
    adjustmentId : number;
    sourceProjectAdjustmentId : number;
}
