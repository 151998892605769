import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {Matter} from '../../../matters/shared';
import {StatementAdjustment} from '../statement-adjustment';
import {ADJUSTMENT_SUBITEMS_FULL_DISPLAY_THRESTHOLD, ADJUSTMENT_SUBITEMS_PARTIAL_TOP_DISPLAY_CNT} from '../../../shared-main/constants';

//Interest on Deposits under new Condo Act
export class StatementAdjustmentDisplayInterestDeposit {

    static  getDisplayItems(statementAdjustment: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder , matter : Matter): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        let interestDepositList  = [];
        if(statementAdjustment.soAdjInterestOnDepositCondo.isInterestOnDepositInterestAdjustment()) {
            interestDepositList = statementAdjustment.soAdjInterestOnDepositCondo.generateInterestOnDepositInterestForCondo(matter, statementAdjustment.isAdjustmentStatusFinal());
        }else if(statementAdjustment.soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()){
            interestDepositList = statementAdjustment.soAdjInterestOnDepositCondo.generateInterestOnDepositForOldCondo(matter, statementAdjustment.isAdjustmentStatusFinal());
        } else {
            interestDepositList = statementAdjustment.soAdjInterestOnDepositCondo.generateInterestOnDepositForCondo(matter, statementAdjustment.isAdjustmentStatusFinal());
        }

        if(statementAdjustment.soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()){
            sadItemDetails.push(...this.displayAdjustmentDescriptionsForOldCondo(statementAdjustment,mainBuilder));
        }

        if(!statementAdjustment.soAdjInterestOnDepositCondo.isPrintingFormatFinalAmount()) {
            /*
               Note: list could contains the header row and optional non header row, they should displayed as one group
                January 1, 2021 deposit ($101.00)  --> Header row
                Jan 01/21-Feb 18/21 49 days @ 3.14%0.43  --> optional row
             */
            const headerRowCnt: number = interestDepositList.filter(row => row.header).length;
            if(headerRowCnt >= ADJUSTMENT_SUBITEMS_FULL_DISPLAY_THRESTHOLD){
             //display the first 8 header items with the non-header item
                let headerRowCnt: number = 0;
                for(const interestRow of interestDepositList){
                    if (interestRow.header) {
                        if( ++headerRowCnt > ADJUSTMENT_SUBITEMS_PARTIAL_TOP_DISPLAY_CNT ){ break; }
                        const text = interestRow.text + (interestRow.headerAmount ? (' (' + mainBuilder.getCurrency(interestRow.headerAmount) + ')') : '')
                        sadItemDetails.push(mainBuilder.getNewItemLine(text, ''));
                    } else {
                        sadItemDetails.push(mainBuilder.getNewItemLine(interestRow.text, mainBuilder.getCurrency(interestRow.amount)));
                    }
                }
             //display three lines of dot
                [1,2,3].forEach(_ => sadItemDetails.push(mainBuilder.getNewItemLine(`.`, ``)));
             //display the last header item with its' optional non-header item
                const lastHeaderRowIdx = interestDepositList.map(row => row.header).lastIndexOf(true);
                interestDepositList.slice(lastHeaderRowIdx).forEach(interestRow => {
                    if (interestRow.header) {
                        const text = interestRow.text + (interestRow.headerAmount ? (' (' + mainBuilder.getCurrency(interestRow.headerAmount) + ')') : '')
                        sadItemDetails.push(mainBuilder.getNewItemLine(text, ''));
                    } else {
                        sadItemDetails.push(mainBuilder.getNewItemLine(interestRow.text, mainBuilder.getCurrency(interestRow.amount)));
                    }
                });
            }else{
                interestDepositList.forEach(interestRow => {
                    if (interestRow.header) {
                        const text = interestRow.text + (interestRow.headerAmount ? (' (' + mainBuilder.getCurrency(interestRow.headerAmount) + ')') : '')
                        sadItemDetails.push(mainBuilder.getNewItemLine(text, ''));
                    } else {
                        sadItemDetails.push(mainBuilder.getNewItemLine(interestRow.text, mainBuilder.getCurrency(interestRow.amount)));
                    }
                });
            }
        }
        sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${mainBuilder.purchaserLabel}`, ''));

        return sadItemDetails;
    }

    static displayAdjustmentDescriptionsForOldCondo(statementAdjustment: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder) : StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        if(statementAdjustment.soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()){
            statementAdjustment.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.forEach(item => {
                if(this.isAmountExistsInDescription(item.adjustmentDescription)){
                    let adjustmentDesAndAmount = item.adjustmentDescription.split(':');
                    sadItemDetails.push(mainBuilder.getNewItemLine(` ${adjustmentDesAndAmount[0]}`, mainBuilder.getCurrency(Number(adjustmentDesAndAmount[1]))));
                }else{
                    sadItemDetails.push(mainBuilder.getNewItemLine(` ${item.adjustmentDescription}`, ''));
                }
            })
        }
        return sadItemDetails;

    }

    static isAmountExistsInDescription(adjustmentDesc : string): boolean {
        return adjustmentDesc.indexOf(":") > -1;
    }
}
