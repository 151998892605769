import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class FctIdType extends BaseEntity{
    id: number;
    fiNumber: string;
    idType: string;

    constructor(f?: FctIdType) {
        super(f);
    }
}
