import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export type SignedByType = 'CLIENT' | 'SOLICITOR';
export type LandBeingTransferredType = 'FOR_THE_TRUE_AND_ACTUAL_CONSIDERATION' | 'AS_A_GIFT_OR_WITHOUT_CONSIDERATION';
export type NoOutstandingTaxesAsOfType = 'END_OF_YEAR' | 'DATE_OF_ACQUIRING_TITLE';
export type EndOfYearType = 'PREVIOUS' | 'CURRENT';
export type AddressForMailingType = 'PURCHASER' | 'SUBJECT_PROPERTY' | 'FIRST_MORTGAGE' | 'OTHER';

export class Form54 extends BaseEntity {

    form54SignedBy: SignedByType;

    landBeingTransferred: LandBeingTransferredType;

    noOutstandingTaxesOrPenaltiesAsOf: NoOutstandingTaxesAsOfType;
    noOutstandingTaxesEndOfYear: EndOfYearType;

    addressForMailing: AddressForMailingType;

    realPropertyTransferTaxActExemptionSection: string;
    generalRegulationExemptionSection: string;

    setDefaults(): void {
        this.form54SignedBy = 'SOLICITOR';
        this.landBeingTransferred = 'FOR_THE_TRUE_AND_ACTUAL_CONSIDERATION';
        this.noOutstandingTaxesOrPenaltiesAsOf = 'END_OF_YEAR';
        this.noOutstandingTaxesEndOfYear = 'PREVIOUS';
        this.addressForMailing = 'PURCHASER';
    }

    isDefaultValues(): boolean {
        return this.form54SignedBy == 'SOLICITOR' && this.landBeingTransferred == 'FOR_THE_TRUE_AND_ACTUAL_CONSIDERATION' && this.noOutstandingTaxesOrPenaltiesAsOf == 'END_OF_YEAR'
        && this.noOutstandingTaxesEndOfYear == 'PREVIOUS' && this.addressForMailing == 'PURCHASER' && !this.realPropertyTransferTaxActExemptionSection && !this.generalRegulationExemptionSection;
    }

    setLandBeingTransferred(landBeingTransferred: LandBeingTransferredType) {
        this.landBeingTransferred = landBeingTransferred;
    }

    isTrueAndActualConsideration(): boolean {
        return this.landBeingTransferred == 'FOR_THE_TRUE_AND_ACTUAL_CONSIDERATION';
    }

    isAsAGift(): boolean {
        return this.landBeingTransferred == 'AS_A_GIFT_OR_WITHOUT_CONSIDERATION';
    }

    setNoOutstandingTaxes(noOutstandingTaxesAsOf: NoOutstandingTaxesAsOfType) {
        this.noOutstandingTaxesOrPenaltiesAsOf = noOutstandingTaxesAsOf;
    }

    isEndOfYear(): boolean {
        return this.noOutstandingTaxesOrPenaltiesAsOf == 'END_OF_YEAR';
    }

    isDateOfAcquiringTitle(): boolean {
        return this.noOutstandingTaxesOrPenaltiesAsOf == 'DATE_OF_ACQUIRING_TITLE';
    }

    setAddressForMailing(addressForMailing: AddressForMailingType) {
        this.addressForMailing = addressForMailing;
    }

    isPurchaserAddress(): boolean {
        return this.addressForMailing == 'PURCHASER';
    }

    isSubjectPropertyAddress(): boolean {
        return this.addressForMailing == 'SUBJECT_PROPERTY';
    }

    isFirstMortgageAddress(): boolean {
        return this.addressForMailing == 'FIRST_MORTGAGE';
    }

    isOtherAddress(): boolean {
        return this.addressForMailing == 'OTHER';
    }

}
