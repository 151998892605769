import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {YesNoType} from '../../../shared-main/constants';


export type  ComponentAdjCreditType =  'VENDOR' | 'PURCHASER' | 'NO_ADJUSTMENT' | 'SILENT';

export class ComponentAdjCreditTypeValue {
    public static readonly NO_ADJUSTMENT  = "NO_ADJUSTMENT";
    public static readonly SILENT = "SILENT";
    public static readonly PURCHASER = "PURCHASER";
    public static readonly VENDOR = "VENDOR";
}

export class SoAdjComponent extends BaseEntity {
    creditType:ComponentAdjCreditType;
    useAdjustmentHeading: YesNoType;
    adjustmentHeading: string;
    creditNoteCalculationMethod: string;
    creditNoteAmount: number;
    items:ComponentAdjustmentItem[] = [];
    id: number;

    constructor(soAdj?: SoAdjComponent) {
        super(soAdj);

        if(soAdj && Array.isArray(soAdj.items)){
            this.items = soAdj.items.map(item => {return new ComponentAdjustmentItem(item);});
        }
    }

    getCreditNoteHeading(): string {
        if(this.creditType == 'NO_ADJUSTMENT') {
            return 'No Adjustment'
        } else if(this.creditType == 'PURCHASER') {
            return 'Credit Purchaser';
        } else if(this.creditType == 'VENDOR') {
            return 'Credit Vendor';
        } else {
            return '';
        }
    }

    get isTemplateCodeUsedForCreditNoteCalculation(): boolean {
        return this.creditNoteCalculationMethod != 'MANUAL_ENTRY';
    }

    /**
     * This method extracts the templates codes used in component Adjustment
     * @param {string[]} templateCods
     */
    extractTemplateCodesFromComponentAdj(): string[] {
        let templateCodes: string[] = [];
        if(this.isTemplateCodeUsedForCreditNoteCalculation) {
            templateCodes.push(this.creditNoteCalculationMethod);
        }

        this.items.filter(item => item.isTemplateCodeUsedForCalculation)
           .forEach(item => templateCodes.push(item.calculationMethod))

        return templateCodes;
    }
}
export class ComponentAdjustmentItem  extends BaseEntity{
    itemName: string;
    calculationMethod: string;
    amount: number;

    get isTemplateCodeUsedForCalculation(): boolean {
        return this.calculationMethod != 'MANUAL_ENTRY';
    }
}
