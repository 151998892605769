import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

// import { DateFormatter } from '@angular/common/src/facade/intl';

@Pipe({
    name: 'detaileddate'
})
export class DetailedDatePipe implements PipeTransform {

    transform(value: any, args: string[]): string {

        if (!value || value === '' || value === '//') {
            return '';
        }

        let segments: string[] = value.split('/');

        if (segments[0] === '' && segments[1] === '' && segments[2] === '') {
            return value;
        }
        let day: string = segments[2];
        let month: string = segments[1];
        let year: string = segments[0];

        if (segments[1] !== '') {
            month = moment().month(+segments[1] - 1).format('MMM');
        } else {
            month = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
        }

        if (segments[2] == '') {
            day = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
        }
        if (segments[0] == '') {
            year = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
        }
        let template: string = `${month} ${day}, ${year}`;
        return template;
    }
}
