import {Component, OnInit} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {EventAvailabilityState} from '../event-list-state';

declare var jQuery: any;

class EventSchedulingContext extends BSModalContext {
    eventAvailabilityState: EventAvailabilityState;
}

@Component({
    selector: 'dp-event-scheduling',
    templateUrl: 'event-scheduling.modal.component.html',
})
export class EventSchedulingModalComponent implements OnInit, ModalComponent<EventSchedulingContext> {
    context: EventSchedulingContext;


    constructor(public dialog: DialogRef<EventSchedulingContext>
    ) {
        this.context = dialog.context;

    }

    ngOnInit() {

    }

    ok(): void {
        this.dialog.close( this.context.eventAvailabilityState);
    }

    close(): void {
        this.dialog.close();
    }

    dateTimeChange(event: any): void {
        if ( this.context.eventAvailabilityState) {
            let eventAvailabilityState = new EventAvailabilityState(this.context.eventAvailabilityState);
            eventAvailabilityState.eventStartDate = event.startDate;
            eventAvailabilityState.eventEndDate  = event.endDate;
            eventAvailabilityState.eventStartTime = event.startTime;
            eventAvailabilityState.eventEndTime  = event.endTime;
            this.context.eventAvailabilityState = eventAvailabilityState;
        }
    }
}
