import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {SoAdjWithTax} from './so-adj-with-tax';
import {AdditionalVendorConsiderationType} from './so-adj-tarion-warranty';
import {SalePriceAdjustment} from '../sale-price-adjustment/sale-price-adjustment';
import Utils from '../../../shared-main/utils';

export class OtherFixedCreditTypes {
    public static readonly NO_ADJUSTMENT  = "NO_ADJUSTMENT";
    public static readonly SILENT = "SILENT";
    public static readonly PURCHASER = "PURCHASER";
    public static readonly VENDOR = "VENDOR";
}
export class SoAdjOtherFixed extends SoAdjWithTax {

    id: number;

    creditType: string; // Purchaser, Vendor, No Adjustment, Silent
    adjustmentHeading: string;
    amountHeading: string; // 'Cost'|'Amount'|'Fee'|'Total'|'Charge'|'heading'
    amount: number;
    adjustmentPaidOnInterimClosing:string; //'N/y', 'Yes' or 'No'

    amountAdditionalConsiderationToVendor: AdditionalVendorConsiderationType;
    infoOnly: boolean;

    condominiumTotalSharePercentage: number;

    items: SoAdjOtherFixedItem[] = [];

    constructor(copyFrom?: SoAdjOtherFixed) {
        super(copyFrom);

        if(copyFrom && Array.isArray(copyFrom.items)){
            this.items = copyFrom.items.map(cpy => {return new SoAdjOtherFixedItem(cpy);});
        }
    }

    get hstAmount(): number{
        return Utils.roundCurrency(this.amount * this.hstRate/100);
    }

    get gstAmount(): number{
        return Utils.roundCurrency(this.amount * this.gstRate/100);
    }

    get pstAmount(): number{
        return Utils.roundCurrency(this.amount * this.pstRate/100);
    }

    get totalAmountPurchaser(): number{
        if (this.creditType == OtherFixedCreditTypes.NO_ADJUSTMENT || this.creditType == OtherFixedCreditTypes.SILENT){
            return 0;
        }
        return Utils.roundCurrency(Number(this.amount) + this.getHstOrGstValueForAmount(this.amount) + this.getPstValueForAmount(this.amount));
    }


    getHstOrGstValueForAmount(amount: number, provinceCode ? : string): number{
        if(provinceCode){
            this.provinceCode = provinceCode;
        }
        if (this.applyTaxBoolean) {
            if(this.provinceCode == 'AB'){
                return this.hstAmount;
            } else {
                if (this.taxType == "GST" ) {
                    return this.gstAmount;
                } else {
                    return this.hstAmount;
                }
            }
        }
        return 0;
    }

    getPstValueForAmount(amount: number): number{
        if (this.applyPstBoolean){
            return this.pstAmount
        }
        return 0;
    }

    totalAmountVendor(spa: SalePriceAdjustment): number {
        if (this.creditType == OtherFixedCreditTypes.NO_ADJUSTMENT || this.creditType == OtherFixedCreditTypes.SILENT){
            return 0;
        }
        if (spa && spa.isSalePriceFormatINCLUSIVE()) {
            return Utils.roundCurrency(Number(this.amount) + this.getHstOrGstValueForAmount(this.amount) + this.getPstValueForAmount(this.amount));
        } else {
            if (this.amountAdditionalConsiderationToVendor) {
                switch (this.amountAdditionalConsiderationToVendor) {
                    case "NO":
                        return Utils.roundCurrency(Number(this.amount) + this.getHstOrGstValueForAmount(this.amount) + this.getPstValueForAmount(this.amount));
                    case "YES_NOT_ELIGIBLE_FOR_REBATE":
                        return Utils.roundCurrency(this.getHstOrGstValueForAmount(this.amount) + this.getPstValueForAmount(this.amount));
                    case "YES_ELIGIBLE_FOR_REBATE":
                        if(['SK', 'MB'].indexOf(spa.provinceCode) > -1 && this.applyPstBoolean){
                            return Utils.roundCurrency(this.getPstValueForAmount(this.amount));
                        }
                        return 0;
                    case "YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX":
                        return 0;
                    default :
                        return 0;
                }
            }
        }
        return this.totalAmount;
    }

    get totalAmount(): number{
        if (this.creditType == OtherFixedCreditTypes.NO_ADJUSTMENT || this.creditType == OtherFixedCreditTypes.SILENT){
            return 0;
        }
        return Utils.roundCurrency(Number(this.amount) + this.getHstOrGstValueForAmount(this.amount) + this.getPstValueForAmount(this.amount));
    }

    getPercentageInterestHstAmount(condominiumTotalSharePercentage: number): number{
        let amnt: number = Number(this.getAmountForPercentageInterest(condominiumTotalSharePercentage));
        return this.getHstOrGstValueForAmount(amnt);
    }

    getPercentageInterestGstAmount(condominiumTotalSharePercentage: number): number{
        let amnt: number = Number(this.getAmountForPercentageInterest(condominiumTotalSharePercentage));
        return this.getHstOrGstValueForAmount(amnt);
    }

    getPercentageInterestPstAmount(condominiumTotalSharePercentage: number): number{
        let amnt: number = Number(this.getAmountForPercentageInterest(condominiumTotalSharePercentage));
        return this.getPstValueForAmount(amnt);
    }

    getAmountForPercentageInterest(condominiumTotalSharePercentage: number): number{
        return Number(this.amount) * Number(condominiumTotalSharePercentage) / 100;
    }

    getTotalAmountPurchaserForPercentageInterest(condominiumTotalSharePercentage: number): number{
        if (this.creditType == OtherFixedCreditTypes.NO_ADJUSTMENT || this.creditType == OtherFixedCreditTypes.SILENT){
            return 0;
        }
        let amnt: number = Utils.roundNumber(Number(this.getAmountForPercentageInterest(condominiumTotalSharePercentage)), 2);
        return amnt + this.getHstOrGstValueForAmount(amnt) + this.getPstValueForAmount(amnt);
    }

    getTotalAmountVendorForPercentageInterest(condominiumTotalSharePercentage: number, spa: SalePriceAdjustment): number{
        if (this.creditType == OtherFixedCreditTypes.NO_ADJUSTMENT || this.creditType == OtherFixedCreditTypes.SILENT){
            return 0;
        }

        if (spa && spa.isSalePriceFormatINCLUSIVE()) {
            return this.getTotalAmountPurchaserForPercentageInterest(condominiumTotalSharePercentage);
        } else {
            if (this.amountAdditionalConsiderationToVendor) {
                switch (this.amountAdditionalConsiderationToVendor) {
                    case "NO":
                        return this.getTotalAmountPurchaserForPercentageInterest(condominiumTotalSharePercentage);
                    case "YES_NOT_ELIGIBLE_FOR_REBATE":
                        return this.getPercentageInterestHstAmount(condominiumTotalSharePercentage) + this.getPercentageInterestPstAmount(condominiumTotalSharePercentage);
                    case "YES_ELIGIBLE_FOR_REBATE":
                            return this.getPercentageInterestPstAmount(condominiumTotalSharePercentage);
                    case "YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX":
                        return 0;
                    default :
                        return 0;
                }
            }
        }
        return this.getTotalAmountPurchaserForPercentageInterest(condominiumTotalSharePercentage);
    }

    get taxLabel() : string{
        let taxLabel : string = 'HST';
        if( this.taxType === 'GST') {
            if(this.applyTaxBoolean) {
                taxLabel = 'GST';
            }

            if(!this.applyTaxBoolean && this.applyPstBoolean) {
                taxLabel = 'PST';
            }
        }

        return taxLabel;
    }
}

export class SoAdjOtherFixedItem extends BaseEntity {
    id: number;

    heading: string;
    amount: number;

    constructor(copyFrom?: SoAdjOtherFixedItem) {
        super(copyFrom);
    }
}
