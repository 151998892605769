import {EventType} from './event-data';

export enum EventDateMoveDirection {
    DATE_MOVE_BACKWARD,
    DATE_MOVE_FORWARD
};


export class EventDateRangeTypes {
    static readonly NEXT30DAYS: string = 'next30days';
    static readonly DAY: string = 'days';
    static readonly WEEK: string = 'weeks';
    static readonly MONTH: string = 'months';
}

export class EventTypes {
    static readonly CLOSING: EventType  = 'CLOSING';
    static readonly OCCUPANCY: EventType = 'OCCUPANCY';
    static readonly REQUISITIONS: EventType = 'REQUISITION';
    static readonly SUPPLEMENTAL_TASKS: EventType  = 'SUPPLEMENTAL';
    static readonly UNDERTAKING : EventType = 'UNDERTAKING';
    static readonly APPOINTMENT : EventType = 'APPOINTMENT';
    static readonly HOLDBACK : EventType = 'HOLDBACK';
    static readonly WORKITEMTASK: EventType = 'WORKITEMTASK';
}

export class AppointmentSubtypes {
    static readonly SIGN_DOCUMENTS_LABEL: string = 'Sign Documents';
    static readonly SIGN_DOCUMENTS_VALUE: string = 'SIGN_DOCUMENTS';

    static readonly CLIENT_MEETING_LABEL: string = 'Client Meeting';
    static readonly CLIENT_MEETING_VALUE: string = 'CLIENT_MEETING';
}

export type StaffAvailabilityType = 'TENTATIVE' | 'BUSY' | 'OUT_OF_OFFICE';

export const StaffAvailabilityValues  = {
    Tentative : <StaffAvailabilityType>'TENTATIVE',
    Busy : <StaffAvailabilityType>'BUSY',
    OutOfOffice : <StaffAvailabilityType>'OUT_OF_OFFICE'
};
