import {api, SESSION_ID_REQ_PARAM} from '../../../common/api';

/** Application endpoint urls
 */
export const documentProfilesApi = {

    documentProfiles(accountId: string): string { return `${api}/accounts/${accountId}/documentProfiles/`; },
    systemDocumentProfiles(accountId: string, provinceCode : string): string { return `${api}/accounts/${accountId}/systemDocumentProfiles/provinces/${provinceCode}`; },
    //defaultDocumentProfiles :`${api}/documentProfiles?}&filter=defaultProfileFlag_EQ_TRUE`,
    //TODO: this should be under the document profile, too (so we can easily guard based on accessing user/profile
    docTemplateFiles : `${api}/accountFileFolders/{id}/documentTemplateFiles`,
    systemDocTemplateFiles : `${api}/public/documentTemplateFiles`,
    copyDocTemplateFiles : `${api}/accounts/{accountId}/accountFileFolders/{accountFileFolderId}/copyTemplates`,
    docTemplate : `${api}/accountFileFolders/{id}/documentTemplates`,
    downloadTemplate : `${api}/accountFileFolders/{accountFileFolderId}/documentTemplateFiles/{documentTemplateFileId}/download?${SESSION_ID_REQ_PARAM}={sessionId}`,
    downloadTemplateFiles : `${api}/accountFileFolders/{accountFileFolderId}/documentTemplateFiles/zip`,
    downloadSystemDocumentTemplate : `${api}/public/documentTemplateFiles/{documentTemplateFileId}/download?${SESSION_ID_REQ_PARAM}={sessionId}`,
    customerFileUpload: `${api}/accounts/{accountId}/customerFile/upload`,
    updateDocumentProfileStatus: `${api}/accounts/{accountId}/documentProfiles/{id}/status`

};

export const documentProfileBaseUrl: string = 'main/admin/home/document-profiles';
export const accountsDocumentProfileBaseUrl: string = 'main/accountadmin/account/';
