export interface SessionStorageKeys {

    openTabs: string;
    accountId : string;
    legalFirmId : string;
    userId : string;
    authorization : string;
    userLogin : string;
    defaultProvinceCode:string;
    accountDefaultProvinceCode: string;
    userDocumentProfileId : string;
    user : string;
    authenticatedUser: string;
    sessionId: string;
    userContactId: string;
    userInitiatedResetsAllowed : string;
    //defaultDocumentProfileId: string;
    tokens : string;
    timezoneOffset: string;
    fctEnabled: string;
    disablePostClosedMattersEdit:string;
    disableMatterEditAfterClosingDays:string;
    isConveyancingUser:string;
    accountDefaultSSO:string;
}

//ToDo: The user information is duplicated in local storage in three keys 'registerUser', 'user', 'authenticatedUser'. Should have only one key for the user.
export const SESSION_STORAGE_KEYS : SessionStorageKeys = {

    openTabs              : 'openTabs',
    accountId             : 'dppm_accountId',
    legalFirmId           : 'dppm_legalFirmId',
    userId                : 'dppm_userId',
    authorization         : 'Authorization',
    userLogin             : 'dppm_userlogin',
    defaultProvinceCode   : 'defaultProvinceCode',
    accountDefaultProvinceCode : 'accountDefaultProvinceCode',
    userDocumentProfileId : 'dppm_user_document_profile_id',
    user                  : 'user',
    authenticatedUser     : 'authenticatedUser',
    sessionId             : 'dpsessionid',
    userContactId         : 'dpUserContactid',
    userInitiatedResetsAllowed : 'userInitiatedResetsAllowed',
    //defaultDocumentProfileId : 'dppm_default_document_profile_id',
    tokens                : 'dppm_tokens',
    timezoneOffset        : 'x-dppmApp-timezone-offset',
    fctEnabled            : 'fctEnabled',
    disablePostClosedMattersEdit: 'disablePostClosedMattersEdit',
    disableMatterEditAfterClosingDays: 'disableMatterEditAfterClosingDays',
    isConveyancingUser     : 'isConveyancingUser',
    accountDefaultSSO      :'accountDefaultSSO'
};
