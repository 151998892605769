import {CommonContactComponent} from './common-contact.component';
import {Contact} from './../matters/shared/contact';
import {Tab} from '../shared/tabbing/tab';
import {ErrorTab} from '../shared/error-handling/error-tab';
import {LawFirmContactType} from './contact-list.component';
import {Jurisdiction} from '../matters/shared/jurisdiction';
import {LendingInstitution} from './lending-institution';

/**

 */

export class ContactListState {
    tableData : Contact[];
    tableJurisdictionData : Jurisdiction[];
    contactKey : string;
    searchText : string;
    lastSearchText : string;
    searchType : string;
    accessScopeType: string;
    mortgagesAcceptedType: string;
    selectedProvinces:  string[];
    clientStatus: string;
    selectedLawFirmContactType: LawFirmContactType;
    sortQuery: string;
    sortType: string;
    pageNo: number;
    pageSize: number;
    scrollY: number;
    selectedContacts : Contact[];
    selectedJurisdictionContacts : Jurisdiction[];
    selectedCategories: string[];
}


export class ContactTab extends Tab {


    constructor(json? : ContactTab) {
        super(json);
        if(json) {
            this.contact = new Contact(json.contact);
            this.errorTab = new ErrorTab(json.errorTab);
        }
        // this.equals = (other: ContactTab) => this.contactSubType === other.contactSubType;
    }

    equals(other : ContactTab) : boolean {
        return super.equals(other) && this.contactSubType === other.contactSubType;
    }

    contact : Contact;

    commonContactComponent : CommonContactComponent;

    contactSubType : string;

    contactKey : string;

    subRoute : string;
    subRouteId: number;

    errorTab: ErrorTab;

    contactListState  : ContactListState;

    selectedLenderInstitution : LendingInstitution;

    associatedMattersSortType: string;
    associatedMattersSortQuery: string;
    associatedOpportunitiesSortType: string;
    associatedOpportunitiesSortQuery: string;
    contactsAssociatedMatterOpportunityLengthMap:Map<number,number> = new Map();

    // The contactUntouched is getting refreshed when user traverse through different topics and re-enter ngAfterViewInit
    // so we are keeping backendCopy on tab instead of component.
    // Gradually we will move code to tab copy.
    backendContactCopy: Contact;

    static createContactTab(contact : Contact, routeBase : string, contactKey? : string, subRoute? : string, subRouteId? : number) : ContactTab {
        const _tab : any = {
            id                     : contact.id || -(new Date()).getTime(),
            tabType                : 'contact',
            type                   : 'contact',
            // title : contactKey,
            contactKey             : contactKey ? contactKey : contact.contactType,
            contact                : contact,
            backendContactCopy     : contact.id ? new Contact(contact) : null,
            contactSubType         : contact.contactType,
            route                  : routeBase,
            subRoute               : subRoute,
            subRouteId             : subRouteId,
        };
        let tab : ContactTab = new ContactTab(_tab);
        return tab;
    }

    get title() : string {
        if(this.contact.isIndividual || this.contact.isEstate){
            return this.contact.fullName;
        } else if (this.contact.isLawFirm) {
            return this.contact.legalFirmName;
        } else {
            return this.contact.organizationName;
        }
    }

    set title(title : string) {
        //Ignore,
    }

    get routeParams(): string[] {
        if (this.subRoute && this.subRouteId) {
            return [this.route, this.id.toString(), this.subRoute, this.subRouteId.toString()];
        } else if(this.subRoute) {
            return [this.route, this.id.toString(), this.subRoute];
        }else {
            return [this.route, this.id.toString()];
        }
    }

    isLocked() : boolean {
        return this.contact.locked;
    }

    isDirty() : boolean {
        // Let contact save button and tab lab keep the same condition
        return  !this.contact.isReadOnly && this.contact.isDirty;
    }

    deCoupleUIComponent() : void {
        this.commonContactComponent = null;
    }

    getContactAssociatedMatterAndOpportunityTotalLength(contactId: number): string{
        let result : string = '';
        if ( this.contactsAssociatedMatterOpportunityLengthMap && this.contactsAssociatedMatterOpportunityLengthMap.get(contactId)){
            result = ` (${this.contactsAssociatedMatterOpportunityLengthMap.get(contactId)})`;
        }
        return result;
    }

}
