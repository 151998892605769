/**
 *  This service provides Field Codes for the Project Statement of Adjustments to be unique across the Project and it's associated Matters
 **/
import {Injectable} from '@angular/core';
import {ProjectSoAdjFieldCode} from '../projects/shared/project-soa-adj-field-code';
import {TabsService} from '../core';
import {MatterTab} from '../matters/matter-tab';
import {Tab} from '../shared/tabbing';
import {ProjectTab} from '../projects/shared/project-tab';
import {staticFieldCodes} from './constants';
import {ProgressionStatus, StatementAdjustment} from '../matters/statement-adjustment/statement-adjustment';
import {Matter} from '../matters/shared';

@Injectable()

export class SoajFieldCodeService  {

    constructor(public tabsService: TabsService) { }

    get isProject(): boolean {
        return (this.activeTab && this.activeTab.isProject());
    }

    get isProjectSaleMatter() : boolean {
        return (this.activeTab && this.activeTab.isMatter() && (<MatterTab>this.activeTab).matter && (<MatterTab>this.activeTab).matter.isProjectSale);
    }

    get activeTab() : Tab {
        return this.tabsService && this.tabsService.activeTab;
    }

    get projectSoAdjFieldCodes() : number[] {
        if(this.isProject){
            let projectTab = (<ProjectTab>this.activeTab);
            return projectTab && projectTab.projectSoAdjFieldCodes ? projectTab.projectSoAdjFieldCodes : [];
        } else if(this.isProjectSaleMatter){
            let matterTab = (<MatterTab>this.activeTab);
            return matterTab && matterTab.projectSoAdjFieldCodes ? matterTab.projectSoAdjFieldCodes : [];
        } else {
            return [];
        }
    }

    get matterSoAdjFieldCodes() : number[] {
        if(this.isProject){
            let projectTab = (<ProjectTab>this.activeTab);
            return projectTab && projectTab.matterSoAdjFieldCodes ? projectTab.matterSoAdjFieldCodes : [];
        } else if(this.isProjectSaleMatter){
            let matterTab = (<MatterTab>this.activeTab);
            return matterTab && matterTab.matterSoAdjFieldCodes ? matterTab.matterSoAdjFieldCodes : [];
        } else {
            return [];
        }
    }

    private getFirstAvailableFieldCode() : number {
        for(let i=7; i<=103 ; i++){
            if(this.isFieldCodeAvailable(i)){
                return i;
            }
        }
        for(let i=2100; i<=2999 ; i++){
            if(this.isFieldCodeAvailable(i)){
                return i;
            }
        }
    }

    private isFieldCodeAvailable(fieldCode: number) : boolean {
        let available = this.activeTab.projectSoAdjFieldCodes.every(fc => fc != fieldCode );
        if(available){
            available = this.activeTab.matterSoAdjFieldCodes.every(fc => fc != fieldCode );
        }
        return available
    }

    private addToFieldCodeList(fieldCode: number):void {
        if(fieldCode && (this.isProject || this.isProjectSaleMatter)){
            if(this.isProject){
                this.activeTab.projectSoAdjFieldCodes.push(fieldCode);
            } else {
                this.activeTab.matterSoAdjFieldCodes.push(fieldCode);
            }
        }

    }

    public mapFieldCodesResponse(projectSoAdjFieldCodes : ProjectSoAdjFieldCode[]) : number[] {
        let soaFieldCodes = [];
        projectSoAdjFieldCodes.forEach((projectSoAdjFieldCode)=>{ soaFieldCodes.push(projectSoAdjFieldCode.fieldCode); });
        return soaFieldCodes;
    }

    public removeFromFieldCodeList(fieldCode):void {
        if(fieldCode && (this.isProject || this.isProjectSaleMatter)){
            fieldCode = Number(fieldCode);
            if(this.isProject){
                (<any>this.activeTab.projectSoAdjFieldCodes).remove(fieldCode);
            } else {
                (<any>this.activeTab.matterSoAdjFieldCodes).remove(fieldCode);
            }
        }

    }

    public requestAdjustmentFieldCode(): number {
        if (this.isProject || this.isProjectSaleMatter) {
            let firstAvailableFieldCode = this.getFirstAvailableFieldCode();
            this.addToFieldCodeList(firstAvailableFieldCode);
            return firstAvailableFieldCode;
        }

    }

    generateMissingSoajFieldCodes() : void {
        if(this.isProjectSaleMatter && this.activeTab){
            let matter = (<MatterTab>this.activeTab).matter;
            if(matter) {
                let allAdjustments = [...matter.interimStatementAdjustments, ...matter.finalStatementAdjustments];
                allAdjustments.forEach((soa) => {
                    if (!soa.fieldCode) {
                        if (soa.isDepositAdjustment()) {
                            soa.fieldCode = staticFieldCodes.depositAdjustment;
                        } else if (soa.isSalePrice()) {
                            soa.fieldCode = staticFieldCodes.salePriceAdjustment;
                        } else if (soa.isSalePriceAdjustmentHeading()) {
                            // don't set the fieldCode for this one
                        } else {
                            this.assignFieldCode(soa, matter);
                        }
                    }
                });
            }
        }
    }

    assignFieldCode(soa: StatementAdjustment, matter: Matter) : void {
        soa.fieldCode = this.requestAdjustmentFieldCode();
        //Search if it has Linked Adjustment
        if(soa.linkId){
            //Get the other sheet adjustments
            let adjustments = soa.adjustmentStatus == ProgressionStatus.FINAL ? matter.interimStatementAdjustments : matter.finalStatementAdjustments;
            let linkedAdj = adjustments.find(adj=>adj.linkId == soa.linkId);
            if(linkedAdj) {
                linkedAdj.fieldCode = soa.fieldCode;
            }
         }
    }

}
