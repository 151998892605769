import {Attribute, Directive, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
               selector  : '[equal][formControlName],[equal][formControl],[equal][ngModel]',
               providers : [
                   {provide : NG_VALIDATORS, useExisting : forwardRef(() => EqualValidator), multi : true}
               ]
           })
export class EqualValidator implements Validator {
    constructor(@Attribute('equal') public equal : string) {}

    validate(c : AbstractControl) : {[key : string] : any} {
        // self value (e.g. retype password)
        let v = c.value;

        // control value (e.g. password)
        let e = c.root.get(this.equal);

        // value not equal
        if(e && v !== e.value) {
            return {
                equal : false
            }
        }
        return null;
    }
}
