export const StatementAdjustmentCredit ={
    VENDOR         : 'Credit Vendor',
    PURCHASER      : 'Credit Purchaser'
}

export const StatementAdjustmentConstants = {
    SALE_PRICE         : 'Sale Price',
    DEPOSIT            : 'Deposit',
    COMMON_EXPENSE     : 'Common Expenses'
}

export const StatementAdjustmentAmountTypes = {
    VENDOR         : 'VENDOR',
    PURCHASER      : 'PURCHASER',
    NO_ADJUSTMENT  : 'NO_ADJUSTMENT',
    INFO_ONLY      : "INFO_ONLY"
}
export const StatementAdjustmentHSTTypes = {
    HST_SALE_PRICE : 'HST_SALE_PRICE',
    HST_REBATE : 'HST_REBATE',
    HST_OTHER_FACTOR : 'HST_OTHER_FACTOR'
}
export const StatementAdjustmentExpenseTypes = {
    COMMON_EXPENSE : 'COMMON_EXPENSE',
    POTL_COMMON_EXPENSE : 'POTL_COMMON_EXPENSE',
    OCCUPANCY_FEES : 'OCCUPANCY_FEES'
}
export const StatementAdjustmentKey = {
    SALE_PRICE              : 'SALE_PRICE',
    PROPERTY_TAX            : 'PROPERTY_TAX',
    REALTY_TAXES            : 'REALTY_TAXES',
    DEPOSIT                 : 'DEPOSIT',
    HST_ADJUSTMENT          : 'HST_ADJUSTMENT',
    COMMON_EXPENSE          : 'COMMON_EXPENSE',
    OCCUPANCY_PERIOD        : 'OCCUPANCY_PERIOD',
    RESERVE_FUND            : 'RESERVE_FUND',
    SALES_INCENTIVE         : 'SALES_INCENTIVE',
    WATER                   : 'WATER',
    HEAT                    : 'HEAT',
    TAX_OTHER               : 'TAX_OTHER',
    FACTOR_IN_HST_REBATE    : 'FACTOR_IN_HST_REBATE',
    INTEREST                : 'INTEREST',
    OTHER_FIXED             : 'OTHER_FIXED',
    OTHER_PRORATED          : 'OTHER_PRORATED',
    OTHER_PRORATED_ON_PERCENTAGE_INTEREST: 'OTHER_PRORATED_ON_PERCENTAGE_INTEREST',
    PURCHASE_PRICE_PERCENTAGE: 'PURCHASE_PRICE_PERCENTAGE',
    TENANCY_CURRENT         : 'TENANCY_CURRENT',
    TENANCY_PREPAID         : 'TENANCY_PREPAID',
    ASSUMED_MORTGAGE        : 'ASSUMED_MORTGAGE',
    TARION_WARRANTY         : 'TARION_WARRANTY',
    HCRA_FEE         : 'HCRA_FEE',
    VACANT_OCCUPANCY_TAX :'VACANT_OCCUPANCY_TAX',
    VTB_MORTGAGE            : 'VTB_MORTGAGE',
    HOME_OWNERS_RESIDENT_FEE: 'HOME_OWNERS_RESIDENT_FEE',
    SALE_PRICE_ADJUSTMENT_HEADING : 'SALE_PRICE_ADJUSTMENT_HEADING',
    SALE_PRICE_MINUS_NET_SALE_PRICE : 'SALE_PRICE_MINUS_NET_SALE_PRICE',
    OTHER_FIXED_PAYABLE_ON_OCCUPANCY : 'OTHER_FIXED_PAYABLE_ON_OCCUPANCY',
    COMPONENT_ADJUSTMENT : 'COMPONENT_ADJUSTMENT',
    INTEREST_ON_LUMP_SUM    : 'INTEREST_ON_LUMP_SUM',
    INTEREST_ON_DEFERRED_MONIES_DURING_OCCUPANCY :   'INTEREST_ON_DEFERRED_MONIES_DURING_OCCUPANCY',
    INTERIM_OCCUPANCY_FEE   : 'INTERIM_OCCUPANCY_FEE',
    INTERIM_EARLY_POSSESSION_FEE   : 'INTERIM_EARLY_POSSESSION_FEE',
    FINAL_OCCUPANCY_FEE     : 'FINAL_OCCUPANCY_FEE',
    TOTAL_OCCUPANCY_FEE     : 'TOTAL_OCCUPANCY_FEE',
    REALTY_TAXES_PAID_BY_VENDOR  : 'REALTY_TAXES_PAID_BY_VENDOR',
    FINAL_EARLY_POSSESSION_FEE   : 'FINAL_EARLY_POSSESSION_FEE',
    TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY : 'TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY',
    TAXES_PAID_DURING_OCCUPANCY     : 'TAXES_PAID_DURING_OCCUPANCY',
    INTEREST_ON_DEPOSIT_CONDO : 'INTEREST_ON_DEPOSIT_CONDO',
    INTEREST_ON_DEPOSIT_UNDER_OLD_CONDO : 'INTEREST_ON_DEPOSIT_UNDER_OLD_CONDO',
    INTEREST_ON_DEPOSIT_INTEREST_CONDO : 'INTEREST_ON_DEPOSIT_INTEREST_CONDO',
    FORM_4_CHARGE : 'FORM_4_CHARGE',
    ITEMIZED_CREDIT_TO_VENDOR_PURCHASER : 'ITEMIZED_CREDIT_TO_VENDOR_PURCHASER',
    PURCHASE_PRICE_DEFERRED_PORTION : 'PURCHASE_PRICE_DEFERRED_PORTION',
    CREDIT_VENDOR_TAX_REBATE : 'CREDIT_VENDOR_TAX_REBATE',
    CONSIDERATION_PAID_ON_INTERIM_CLOSING : 'CONSIDERATION_PAID_ON_INTERIM_CLOSING',
    AMOUNT_PAID_ON_INTERIM_CLOSING : 'AMOUNT_PAID_ON_INTERIM_CLOSING',
    RESERVE_FOR_VTB_MTG : 'RESERVE_FOR_VTB_MTG',
};

export enum DepositOnOccupancyCalculatedBasedOn {
    UNADJUSTED_BALANCE = 'UNADJUSTED_BALANCE',
    BDOC_IN_INTERIM_SOA = 'BDOC_IN_INTERIM_SOA'
}

export const ItemizedCreditAdjustmentMessage = {
    VENDOR_CONSIDERATION_REMOVE_MSG : 'You have disabled the automatic insertion of the credit to the Vendor in the sale price adjustment. ' +
    'Note that the existing credit to the Vendor, in the amount of <TOTAL_CREDITS>, has been reset to zero'
};

export  const RESERVE_FOR_VTB_MTG_TEXT = 'Field Code is reserved for VTB Mortgage';
