import {StatementAdjustment} from '../statement-adjustment';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';

export class StatementAdjustmentDisplayComponentBuilder {

    static  getDisplayItems(statementAdjustment: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        if(statementAdjustment.soAdjComponent.items) {
            statementAdjustment.soAdjComponent.items.forEach( (item, index) => {
                if(index == 0 && statementAdjustment.soAdjComponent.useAdjustmentHeading == 'NO') {
                    //skipping the 1st row
                   // sadItemDetails.push(mainBuilder.getNewItemLine(item.itemName, mainBuilder.getCurrency(item.amount), null, null, null, null, true));
                } else {
                    sadItemDetails.push(mainBuilder.getNewItemLine(item.itemName, mainBuilder.getCurrency(item.amount, 2, true)));
                }

            })
        }

        if(statementAdjustment.soAdjComponent.creditType == 'PURCHASER' || statementAdjustment.soAdjComponent.creditType == 'VENDOR') {
            statementAdjustment.amount = statementAdjustment.soAdjComponent.creditNoteAmount;
        } else {
            statementAdjustment.amount = 0;
        }

        sadItemDetails.push(mainBuilder.getNewItemLine(statementAdjustment.soAdjComponent.getCreditNoteHeading(), ''));

        return sadItemDetails;
    }
}
