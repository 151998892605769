import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {StgInvoiceOrderStatus} from './stewart-title-invoice-order-status';
import {StgMessage} from './stewart-title-message';

export class SubmitFileResponse extends BaseEntity{
    messages: StgMessage[] = [];
    fileNo: string;
    fileUrl: string;
    isValid: boolean;
    eCFee: string;
    isLocked: boolean;
    requiresOrderUpdate: boolean;
    isReadOnly: boolean;
    invoiceOrderDate: boolean;
    isFlagged: boolean;
    policySalesTax: number;
    invoiceOrderStatus: StgInvoiceOrderStatus;


    constructor(t?: SubmitFileResponse) {
        super(t);
        if(t) {
            if(t.messages) {
                for(let f of t.messages) {
                    this.messages.push(new StgMessage(f));
                }
            }
        }
    }


}
