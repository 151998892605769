import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {SoAdjInterimFee} from './so-adj-interim-fee';
import {Matter} from '../../shared';
import {Project} from '../../../projects/shared/project';
import {BaseTaxAdjustmentOn} from '../../../projects/project-adjustments/project-tax-adjustment-config';
import {OccupancyFeesCalculatedBasedOn} from '../../../projects/project-condo/project-condo';
import {StatementAdjustmentUtil} from '../statement-adjustment-util';


export type CalculationModeType = 'MANUAL' | 'AUTOMATIC';
export const MAX_INTEREST_PERCENTAGE : number = 99.999999999;
export const DEFAULT_INTEREST_PERCENTAGE : number = 0.000000000;


export class SoAdjInterimOccupancyFee extends  SoAdjInterimFee {

    mortgageInterestType: CalculationModeType;

    commonExpenseType: CalculationModeType;

    taxesType: CalculationModeType;

    getDescription(): string {
        return "Interim Occupancy Fees";
    }


    static getDefault(considerationTaxes: ConsiderationTaxes , project? : Project): SoAdjInterimOccupancyFee {
        let newAdj: SoAdjInterimOccupancyFee = new SoAdjInterimOccupancyFee();

        newAdj.calculateFor = 'THIS_MONTH_ONLY';

        newAdj.mortgageInterestType = 'MANUAL';
        newAdj.mortgageInterest = 0;
        newAdj.mortgageInterestPercentage = 0.0;
        newAdj.mortgageInterestTotal = 0;
        newAdj.postDatedChequesToCommence = 2;

        newAdj.commonExpenseType = (project && project.isPOTL && !project.isCondo  ? 'MANUAL' :'AUTOMATIC');
        newAdj.commonExpense = 0;
        newAdj.commonExpensePercentage = 0.0;
        newAdj.commonExpenseTotal = 0;
        newAdj.postDatedChequesToCommence = 2;

        newAdj.taxesType = 'MANUAL';
        newAdj.taxes = 0;
        newAdj.taxesPercentage = 0.0;
        newAdj.taxesTotal = 0;
        newAdj.postDatedChequesToCommence = 2;

        newAdj.applyTax = false;
        newAdj.taxType = considerationTaxes.rateType;
        newAdj.hstRate = considerationTaxes.hstRate;

        newAdj.postDatedChequesToCommence = 0;

        return newAdj;
    }

    isCommonExpenseTypeAutomatic(): boolean {
        return this.commonExpenseType === 'AUTOMATIC';
    }

    isTaxTypeAutomatic(): boolean {
        return this.taxesType === 'AUTOMATIC';
    }

    setAutomaticMortgageValues(matter: Matter, project: Project): void {

        if (matter && project && this.mortgageInterestType == 'AUTOMATIC') {

            if(matter.isMatterProvinceAB){
                this.mortgageInterestPercentage = MAX_INTEREST_PERCENTAGE;
                this.mortgageInterest = matter.closingDatePayment && matter.closingDatePayment.lateInterestAmountTotal ?  matter.closingDatePayment.lateInterestAmountTotal : 0;
            }else{
                if (project.projectCondo && project.projectCondo.occupancyFeesCalculatedBasedOn === OccupancyFeesCalculatedBasedOn.phantomMortgage
                    || (!project.isCondo && project.isPOTL)) {
                    // Mortgage Automatic

                    if (matter && Array.isArray(matter.mortgages) && matter.mortgages.length > 0) {
                        this.mortgageInterestPercentage = Number(matter.mortgages[0].mortgageTerm.interest); //  The field is populated with the value of the "Interest" field on the First Mortage
                        this.mortgageInterest = Number(matter.mortgages[0].mortgageTerm.principal); // => The field is populated with the value of the "Principal" field on the First Mortage
                    } else {
                        this.mortgageInterestPercentage = 0;
                        this.mortgageInterest = 0;
                    }
                } else { // Interest Automatic
                    if (project.projectCondo.isInterestRateChargeableOnPurchaseMoniesNo) {
                        this.mortgageInterestPercentage = matter.interestRateOnDeferredPurchaseMoniesFinal;
                    } else {
                        this.mortgageInterestPercentage = project.projectCondo.interestRateChargeableOnPurchaseMonies;
                    }
                    this.mortgageInterest = matter.extraDepositConfig.deferredPurchaseMoniesAmount;
                }
            }
            this.recalculateMortgageInterestTotal();
            StatementAdjustmentUtil.updateInterestOnDeferredMoniesDuringOccupancy(matter, project);
        }

    }

    setAutomaticCommonExpenseValues(matter: Matter): void {
        if (matter && this.commonExpenseType == 'AUTOMATIC') {
            this.commonExpenseTotal = Number(matter.matterPropertyWithCondo.condominiumTotalExpenses);
            this.recalculateCommonExpenseTotal();
        }
    }

    setAutomaticTaxesValues(matter: Matter, project: Project): void{

        if ( matter && project && this.taxesType == 'AUTOMATIC') {

            let taxAdjustmentType: string = project.activeInterimTaxAdjustment.taxAdjustmentType;
            if (taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfTaxesForProject) {
                this.taxesPercentage = matter.isMatterProvinceAB ?  Number(matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes) :  Number(matter.matterPropertyWithCondo.condominiumTotalSharePercentage);
                this.taxes = project.activeInterimTaxAdjustment.totalTaxes;
            } else if (taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfUnitPurchasePrice) {
                this.taxesPercentage = project.activeInterimTaxAdjustment.unitPurchasePrice; //Percentage of unit purchase price
                this.taxes = matter.considerationLtt.salePriceAdjustment.agreementSalePrice;
            } else if (taxAdjustmentType == BaseTaxAdjustmentOn.EqualDivisionAmongUnits) {
                this.taxesPercentage = project.activeInterimTaxAdjustment.numberOfUnits == 0 ? 0 : Number(100 / project.activeInterimTaxAdjustment.numberOfUnits);
                this.taxes = project.activeInterimTaxAdjustment.totalTaxes;
            } else if (taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfUnitPurchasePriceIncludingCredits) {
                this.taxesPercentage = project.activeInterimTaxAdjustment.unitPurchasePrice; //Percentage of unit purchase price
                this.taxes = this.getAmountForPercentageOfUnitPurchasePriceIncludingCredits(matter);
            }
            this.recalculateTaxesTotal();
        }
    }

    getAmountForPercentageOfUnitPurchasePriceIncludingCredits(matter: Matter): number{
        let total: number = 0;
        if (matter.considerationLtt.salePriceAdjustment.isNotInclusivePrice()){
            total += Number(matter.considerationLtt.salePriceAdjustment.agreementSalePrice);
        }
        else {
            total += Number(matter.considerationLtt.salePriceAdjustment.totalPriceWithTax());
        }
        // we're not sure as of Oct25/2019 if this is needed.
        matter.allStatementAdjustments.forEach( adj => {
            if (adj.isOtherFixedPayableOnOccupancy() && matter.considerationLtt.salePriceAdjustment.isInclusivePrice()) {
                total += Number(adj.amount); }
        });
        return total;
    }

    recalculateCommonExpenseTotal() {
        if (this.commonExpensePercentage >= 0 && this.commonExpense >= 0 && this.commonExpenseType !== 'AUTOMATIC') {
            this.commonExpenseTotal = Number(((this.commonExpensePercentage / 100) * this.commonExpense / 12).toFixed(2));
        }

    }

}

