import {Component, Input, OnInit, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {DocumentProductionService} from './document-production.service';
import {Logger} from '@nsalaun/ng-logger';
import {CustomKeyCodesEnum, messages} from '../../common';
import {ActivatedRoute} from '@angular/router';
import * as _ from 'lodash';
import {SelectItem} from 'primeng/api';
import {GetGlobalSaveModelService} from '../shared/get-global-save-model.service';
import {matterApi} from '../shared/matter-api';
import {MatterUtil} from '../shared/matter-util';
import {User} from '../shared/user';
import {StopCodesComponent} from './stop-codes/stop-codes.component';
import {DocumentProductionData, ProducibleDocType} from './document-production-data';
import {FileUploadComponent} from '../../shared/file-upload/file-upload-component';
import {HttpClient} from '../../core/httpClient.service';
import {TabsService} from '../../core/tabs.service';
import {Subscription} from 'rxjs';
import {Matter} from '../shared/matter';
import {ContentDialogConfig, DialogService} from '../../shared/dialog/dialog.service';
import {MatterTab} from '../matter-tab';
import {DocumentTemplate} from './document-template';
import {PROJECT_SALE_MATTER_TYPE, SourceTab} from '../../shared-main/constants';
import {Document, MatterDocumentMetadata} from './document';
import {DpModal} from './dp.modal.component';
import {AppConfig} from '../../shared-main/app-configuration';
import {WindowRef} from '../../shared/window.ref';
import {TeranetService} from '../../shared-main/teranet/teranet-service';
import {DocumentUtilityService} from './document-utility.service';
import {AuthorizationService} from '../../shared-main/authorization/authorization-service';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {ManageDocumentProductionCategoriesModalComponent} from './manage-document-production-categories.modal/manage-document-production-categories.modal.component';
import {DocumentTemplateCategory} from './document-template-category';
import {ManageFolderTemplatesModalComponent} from '../../admin/document-profile/document-profile-edit/template-folder/manage-folder-templates-modal/manage-folder-templates.modal';
import {DpDropDownComponent} from '../../shared-main/dropdown-menu/dropdown-menu.component';
import {LockScreenService} from '../../core/lock-screen.service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {UserConfigurationService} from '../../shared-main/user-configuration.service';
import {DocumentProductionDefaultCategory} from '../shared/user-config';
import {DocumentTemplateMergeComponent} from './document-template-merge.component';
import {MatterService} from '../matter.service';
import {DocumentProductionUtil} from '../shared/document-production/document-production-util';
import {Observable} from 'rxjs/Observable';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {ZendeskRequestType} from './zendesk/zendesk-request';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {DpDefaultSorter} from '../../shared-main/dp-default-sorter.component';
import {Subject} from 'rxjs/Subject';
import {DocumentProductionLoggerService} from './document-production-logger.service';
import {MatterTypeInfo} from '../../admin/shared/matter-type-info';
import {AccountService} from '../../admin/accounts/account.service';
import {MatterTypeUtil} from '../../shared-main/matter-type/matter-type-util';
import {NgModel} from '@angular/forms';


declare var jQuery : any;

@Component({
               selector    : 'dp-document-production-template',
               templateUrl : './document-production-template.component.html',
               providers   : [DocumentProductionService]
           })
@FocusFirstElementDecorator()
@AutoUnsubscribe()

export class DocumentProductionTemplateComponent implements OnInit {
    @Input() supTask : boolean;
    /**
     * Encapsulate the matter input with gettes and setters so we can react to a new value being injected into the component
     */
    _matter : Matter;
    @Input() set matter(matter : Matter) {
        if(matter && matter != this._matter) {
            this._matter = matter;
            this.getComponentStateAttributes();
            this.documentProductionLoggerService.resetTemplateLogger();
            this.refreshTemplates();

        }
    }

    get matter() : Matter {
        return this._matter;
    }

    /* Templates Rows come from the parent component. (DocumentProductionComponent) Or (MatterSupplementalTaskDocumentComponent) */
    _rows: any[] = [];
    @Input()
    set rows(rows: any[]) {
        this._rows = rows;
        if (this.supTask) {
            this.page();
        }
    }

    get rows(): any[] {
        if (this.filteredRows.length > 0) {
            return this.filteredRows;
        }
        return [];
    }

    /* documentTemplates come from the parent component. (DocumentProductionComponent) */
    _documentTemplates: DocumentTemplate[] = [];
    @Input()
    set documentTemplates(documentTemplates: DocumentTemplate[]) {
        if (this._documentTemplates && !this._documentTemplates.length) {
            this._documentTemplates = documentTemplates;
            this.loadCategories();
        }
    }

    get documentTemplates(): DocumentTemplate[] {
        return this._documentTemplates;
    }

    @Input() loadDocumentTemplatesFunction : Function;
    @Input() sourceTabName : SourceTab;
    @Input() selection : string;
    @Input() matterSupplementalTaskCategoryId : number;
    @ViewChild(FileUploadComponent) public fileUpload : FileUploadComponent;
    @ViewChild('mf') dataTableMf : any;
    @ViewChild('search', {read: NgModel}) searchControl: NgModel;
    @Input() matterDocuments : any[] = []; // Represents a row in a table: {template: DocumentTemplate, document: Document}
    @Input() tabServiceInstance? : TabsService;
    @Input() usedInModal : boolean = false;

    accountFileFolderSubscription: Subscription;
    userConfigurationServiceSubscription: Subscription;
    allDocumentsSelected: boolean;
    filteredRows: any[] = [];
    message: string;
    scheduler: any;
    documentProfileType: ProducibleDocType;


    //This array keeps the track of templates selected for document generation.
    // For multi-priority templates we also need mortgageIndex therefore we are putting template Id concatenated with mortgageIndex in this array and it is
    // splitted before sending to server.
    documentTemplateIds : string[] = [];
    //This is the input variable for stop codes popup
    documentProductionData : DocumentProductionData;
    isInclude : boolean = false;

    burgerMenuItems : string[] = ['Produce', 'Open Produced Document'];

    actionButtonFlag : boolean = false;
    clickListener : Function;

    matchedTemplateIndex : number = 0;

    sortQuery : string = 'fileName';
    sortType : string = 'ASC';
    categories : DocumentTemplateCategory[] = [];
    categoryFilter : number ;
    defaultCat: number;
    typeFilter : string = 'All Files';

    topTable : string = "180px";

    activeIndexFromTabKey : number = -1;
    previousIndexFromTabKey : number = -1;
    lastVisitedRowIndex : number = -1;
    activeIdFromTabKey : string = '';
    outputTypeOptions : SelectItem[];
    burgerMenuOptions  : SelectItem[];
    outputType : ProducibleDocType;
    isMatterReadOnlyAccess : boolean = false;
    accountFileFolderId: number;
    lockedByUser : User  = new User();

    matterDocumentsHash: string='';
    matterTypeInfos: MatterTypeInfo[] = [];

    searchText : string;
    lastSearchText : string;
    searchTile : string =  "The list of templates within the selected category will be filtered to those whose name or description contains the entered text."
    constructor(public documentProductionService : DocumentProductionService,
                public childGetsFromParent : GetGlobalSaveModelService,
                public matterService : MatterService,
                public logger : Logger,
                public dialogService : DialogService,
                public route : ActivatedRoute,
                public http : HttpClient,
                public renderer : Renderer2,
                public tabService : TabsService,
                public appConfig : AppConfig,
                public viewContainerRef : ViewContainerRef,
                public window : WindowRef,
                public teranetService : TeranetService,
                public lockScreenService : LockScreenService,
                public userConfigurationService : UserConfigurationService,
                public documentUtility: DocumentUtilityService,
                public documentProductionLoggerService: DocumentProductionLoggerService,
                public accountService: AccountService,
                public authorizationService : AuthorizationService) {


        this.clickListener = renderer.listen('document', 'click', (event) => {
            this.resetActionButtonFlag();

        });
    }

    get tabServiceInstanceForMatter() : TabsService {
        return this.tabServiceInstance ? this.tabServiceInstance : this.tabService;
    }

    ngOnInit() : void {
        this.loadMatterTypeDetails();
        if(this.matter && this.matter.id && this.matter.id > 0) {
            this.accountFileFolderSubscription = this.getAccountFileFolder().subscribe((matterDocumentProfile: DocumentProfile) => {
                if (matterDocumentProfile && matterDocumentProfile.accountFileFolder) {
                    // TODO : this variable should not be component level better to have getter
                    this.documentProfileType = matterDocumentProfile.accountFileFolder.producibleDocType;
                    this.accountFileFolderId = matterDocumentProfile.accountFileFolder.id;

                    this.initDocumentProudctionTemplate();
                }
            });
        }else{
            this.saveMatterAndInitComponent();
        }
    }

    loadMatterTypeDetails() {
        this.accountService.getMatterTypesDetails()
            .subscribe((result: MatterTypeInfo[])=>{
                this.matterTypeInfos = result;
            });
    }

    initDocumentProudctionTemplate() : void {
        this.burgerMenuOptions = this.getBurgerMenuItems();
        let outputTypeOptions = this.getOutputMenuItems();
        this.outputTypeOptions = [];
        outputTypeOptions.forEach(item =>{
            this.outputTypeOptions.push(item);
        })
        let option = this.outputTypeOptions.find(item => item.value == this.documentProfileType);
        this.outputType = option ? option.value : this.documentProfileType;
        this.isMatterReadOnlyAccess = this.authorizationService.isMatterReadOnlyAccess();
        this.createLockedByObject();

    }

    /* Construct the categories from documentTemplates by getting the unique categories with the matter type */
    loadCategories() {
        this.categories = [];
        if (this.documentTemplates && this.documentTemplates.length) {
            this.documentTemplates.forEach(item => {
                for (let documentTemplateCategory of item.documentTemplateCategories) {
                    if (!this.categories.find(cat => cat.id == documentTemplateCategory.id)) {
                        if (documentTemplateCategory.applicableMatterTypeCode == this.matterTypeCode) {
                            this.categories.push(documentTemplateCategory);
                        }
                    }
                }
            });
            this.sortCategories();
            if(this.categoryFilter){
                this.loadFilteredRows();
                this.page();
            } else {
                this.loadDefaultCategory().subscribe((result) => {
                    if (result) {
                        this.loadFilteredRows();
                        this.page();
                    }
                });
            }

        }
    }

    loadDefaultCategory(): Observable<boolean> {
        let subject = new Subject<boolean>();
        this.userConfigurationServiceSubscription = this.userConfigurationService.getUserConfiguration().subscribe(data => {
            let documentProductionDefaultCategories = data.documentProductionDefaultCategories;
            let docProdDefaultCat = documentProductionDefaultCategories.find(cat => {
                //It's not really the matter type (where we don't have a value for project sale) but the UI label for indexing some settings
                if (this.matter && this.matter.isProjectSale) {
                    return cat.matterType == PROJECT_SALE_MATTER_TYPE;
                } else {
                    return cat.matterType == this.matterTypeCode;
                }
            });
            if (docProdDefaultCat) {
                let defaultCat = this.categories.find(item => item.categoryDescription == docProdDefaultCat.defaultCategory);
                if (defaultCat) {
                    this.defaultCat = defaultCat.id;
                } else {
                    this.defaultCatInit();
                }
            } else {
                this.defaultCatInit();
            }
            this.categoryFilter = this.defaultCat;
            subject.next(true);
            subject.complete();
        });
        return subject;
    }

    /*
     * In case of parent component is MatterSupplementalTaskDocumentComponent, filteredRows = rows
     * In case of parent component is Document Production, we filter the templates with the user selected documentTemplateCategory
     */
    loadFilteredRows(): void {
        if (this.supTask) {
            this.filteredRows = this._rows;
        } else {
            this.filteredRows = this._rows.filter((row) => {
                // following statement excludes the templates which have row.template.documentTemplateCategories with no content
                if (row.template && row.template.documentTemplateCategories && row.template.documentTemplateCategories.length > 0) {
                    return row.template.documentTemplateCategories.find((cat) => {
                        return cat.id == this.categoryFilter
                    });
                }

            });
        }

        this.filterBySearchText();
    }

    filterBySearchText(){
        if(this.searchText && this.searchText.trim() != "") {
            this.filteredRows = this.filteredRows && this.filteredRows.filter(item=>{
                return item
                    && item.template && ((item.template.fileName && item.template.fileName.toUpperCase().includes(this.searchText.toUpperCase()))
                                         ||(item.template.description && item.template.description.toUpperCase().includes(this.searchText.toUpperCase())))
                    });

            if (this.searchText != this.lastSearchText) {
                this.lastSearchText = this.searchText;
            }
        }
    }

    createLockedByObject(){
        // create this object to insert into document template when user clicks to open document and server response is lagging.
         let lockedBySessionUser : User = JSON.parse((sessionStorage.getItem(SESSION_STORAGE_KEYS.user)));
         this.lockedByUser.firstName = lockedBySessionUser.firstName;
         this.lockedByUser.lastName = lockedBySessionUser.lastName;
         this.lockedByUser.id = lockedBySessionUser.id;
         this.lockedByUser.username = lockedBySessionUser.username;
    }

    defaultCatInit(): void {
        let defaultCat = this.categories.find(item=>{return item.defaultCategory});
        if(defaultCat){
            this.defaultCat = defaultCat.id;
        }
    }

    sortCategories(): void {
        this.categories = this.categories.sort((a: DocumentTemplateCategory,b: DocumentTemplateCategory) => {
            if(a.defaultCategory) {
                return -1;
            }
            else {
                return (a.categoryDescription.toLowerCase() < b.categoryDescription.toLocaleLowerCase() && !b.defaultCategory ) ? -1 : 1;
            }
        });
    }


    ngOnDestroy() : void {
        clearInterval(this.scheduler);
        this.resetComponentStateAttributes();
        this.documentProductionLoggerService.resetTemplateLogger();
    }

    isMatterDocument() : boolean {
        return (this.selection == 'matterDocuments')
    }

    ngAfterViewInit() : void {
        setTimeout(function() {
            // DPPMP-27029: default to asc sorting
            jQuery('#sortFileName').trigger("click");
        }, 500);
        if (this.searchControl) {
            this.searchControl.valueChanges.debounceTime(500).subscribe(this.onSearchTextChange);
        }
    }

    onSearchTextChange = () => {
        if (this.searchText != this.lastSearchText) {
            this.lastSearchText = this.searchText;
            this.page();
        }
    };

    refreshTemplates(refreshFlag?: boolean) : void {
        if(refreshFlag){
            this._documentTemplates = [];
            this.loadDocumentTemplatesFunction('ALL');
        }
        this.topTable = "220px";
        if(!this.supTask){
            this.loadCategories();
        } else {
            this.startPulling();
        }
    }

    checkVirusScanStatus() {
        if (!this.lockScreenService.lockForUpdate) {
            this.lockScreenService.lockForUpdate = true;
        }
        this.documentProductionService.getDocGenTemplates(this.matter && this.matter.id, 'ALL', false, this.matter && this.matter.documentProfileId)
            .subscribe((data) => {
                let file = _.find(data, (docTempFile: DocumentTemplate) => docTempFile.isVirusScanPending);
                if (file) {
                    setTimeout(() => {
                        this.checkVirusScanStatus();
                    }, 2000);
                } else {
                    this.lockScreenService.lockForUpdate = false;
                    this.refreshTemplates(true);
                }
            });
    }

    getAccountFileFolder() : Observable<DocumentProfile>{
        return  this.matterService.getMatterDocumentProfile(this.matter).map(matterDocumentProfile => {
            return matterDocumentProfile;
        });
    }

    // This method will be called to get all document templates for the matter
    page() : void {
        this.loadFilteredRows();
        this.startPulling();

    }


    // on row clicked
    rowClicked(event : Event, index : number) : Window {

        this.matchedTemplateIndex = index;

        // download the document
        const target : Element = <Element>event.target;
        let documentType : string = target.getAttribute('alt');
        let documentName : string = target.getAttribute('data-documentName');
        let documentId : string = target.getAttribute('data-id');

        let wordDocType:string = target.getAttribute('data-documentType');

        if(documentName!="INFECTED") {
            if(documentType === 'word Icon') {
                this.openWordForEdit(documentId, documentName, wordDocType, event);
                return window;
            }

            if(documentType === 'PDF Icon') {
                this.openPdfFile(documentId);
                return window;
            }
        }

    }

    //temporarily, we tell whether the file is wordPerfect or not based on the file extension
    isWordPerfectFile(filename: string){
        return filename && filename.toLowerCase().endsWith(".wpd");
    }

    getWordDocsIcon(docName: string){
        if(this.isWordPerfectFile(docName)){
            return "assets/img/wp.jpg";
        }
        return "assets/img/word.jpg";
    }

    addLockingToDocumentAfterClicked(documentId : string){

        let index= this.rows.findIndex(row => (row.word && row.word.id)? (documentId == row.word.id) : false);
        if(index>=0) {
            this.rows[index].word.lockedByUser = this.lockedByUser;
        }

    }

    launchAlreadyOpenErrorModal(documentName:string){
        console.log("This document is already opened......");
        let documentType: string = this.isWordPerfectFile(documentName) ? 'Word Perfect' : 'MS-Word';
        this.dialogService.confirmDialog('Document Production Error', "Document " + documentName + " is already open in " + documentType + ". Please select" +
            " document from task bar.", true, 'OK');
    }

    openWordForEdit(documentId : string, documentName : string, documentType: string, event) : void {
        let index: number = this.rows.findIndex(row => (row.word && row.word.id)? (documentId == row.word.id) : false);
        if(index < 0){
            console.warn("openWordForEdit failed: No document found for {documentId: %s, documentName: %s} in the dcoumentList", documentId, documentName);
            return ;
        }
        let isLockedByUser: boolean = this.rows[index].word && this.rows[index].word.lockedByUser;
        if (isLockedByUser) {
            this.launchAlreadyOpenErrorModal(documentName);
        } else {
            this.addLockingToDocumentAfterClicked(documentId);
            this.documentProductionService.getDocument(this.matter && this.matter.id, documentId)
                .subscribe(data => {
                    isLockedByUser  = data.isLockedByUser;
                    if(isLockedByUser) {
                        this.launchAlreadyOpenErrorModal(documentName);
                    } else {

                        if(this.isWordPerfectFile(documentName)) {
                            this.documentProductionService.openDocumentForEdit(documentId, documentName, "WORDPERFECT");

                        } else {
                            this.documentProductionService.openDocumentForEdit(documentId, documentName);
                        }
                    }

                });
        }


        event.stopPropagation();
        event.preventDefault();
    }

    openPdfFile(doc : any) {

        this.documentProductionService.downloadFileInNewWindow(this.matter && this.matter.id, Number(doc));
    }

    clickRow(event, i, row) {
        if(this.matter && !this.matter.locked && !row.template.isInfected && !row.template.isVirusScanPending) {
            if(event.target.parentElement.className != "toggleBurger") {
                this.closeBurgerMenu();
                if(this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {
                    this.setActiveIndex(i, row.template.templateIdWithMortgageOrAffidavitIndex);
                }
                if(event.target.tagName === "LABEL") {
                    jQuery(event.target).closest('tr').focus();

                }

                this.selectToProduce(row.template.templateIdWithMortgageOrAffidavitIndex, this.isSelected(row.template.templateIdWithMortgageOrAffidavitIndex), event)

            }
        }
        this.rowClicked(event, i);
        if (!this.isSelected(row.template.templateIdWithMortgageOrAffidavitIndex)) {
            this.allDocumentsSelected = false;
        }
    }

    closeBurgerMenu() {
        if (DpDropDownComponent) {
            jQuery('tr').removeClass("active-hover");
            DpDropDownComponent.activeInstance = null;
        }
    }

    // On check box check/ un-check add or remove template ids,
    //  which are selected to produce documents
    selectToProduce(id : string, value : boolean, event) : void {
    event.preventDefault();
    event.stopPropagation();

     if(this.activeIndexFromTabKey > -1 && !this.rows[this.activeIndexFromTabKey].template.isInfected && !this.rows[this.activeIndexFromTabKey].template.isVirusScanPending) {
         // if checked, add id if not present
         // if un-checked remove id
            if(this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {

                jQuery(event.target).closest('tr').focus();

            }
            if(event.shiftKey && this.documentTemplateIds.length > 0) {

                /// select multiple matters using shift key we get starting point (startIndex) of last selected matter
                //  have current matter (endIndex) that was clicked to cycle through adding them to selectedMatters list
                let startIndex = this.previousIndexFromTabKey + 1;
                let endIndex = this.activeIndexFromTabKey + 1;

                this.selectMultipleContacts(startIndex, endIndex);
                // fixes a bug from shift click that causes text to hilite, removes selected text
                this.window.nativeWindow.getSelection().removeAllRanges();
                if(this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {

                    jQuery(event.target).closest('tr').focus();
                }

                //    }
            } else {
                if(event.shiftKey && event.type == "click") {

                    let startIndex = this.previousIndexFromTabKey + 1;
                    let endIndex = this.activeIndexFromTabKey + 1;
                    if(startIndex < endIndex) {
                        startIndex = startIndex - 1;
                    }


                    this.selectMultipleContacts(startIndex, endIndex);
                    this.window.nativeWindow.getSelection().removeAllRanges();
                    if(this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {

                        jQuery(event.target).closest('tr').focus();
                    }
                } else {

                    if(!value) {
                        let index : number = this.documentTemplateIds.indexOf(id);
                        if(index < 0) {
                            if(event.target.tagName === "LABEL" || event.ctrlKey === true
                                || event.target.tagName.indexOf('DP-CHECKBOX') != -1
                                || event.target.className.indexOf('check-box-column') != -1 ) {
                                //clicked from checkbox
                                this.documentTemplateIds[this.documentTemplateIds.length] = id;
                            } else {
                                //clicked from row
                                this.documentTemplateIds = [];
                                this.documentTemplateIds[this.documentTemplateIds.length] = id;
                            }
                        }
                    } else {
                        _.remove(this.documentTemplateIds, (n : string) => n === id);
                    }
                }
            }
        }
        this.setComponentStateAttributes();
    }

    selectMultipleContacts(startIndex, endIndex) {
        if(startIndex < endIndex) {
            for(var i = startIndex; i < endIndex; i++) {
                // cycle through from start to end Index and add to selected matters list if they are not opened locked or selected already
                let index : number = this.documentTemplateIds.indexOf(i);
                if(index < 0) {
                    this.documentTemplateIds.push(this.rows[i].template.templateIdWithMortgageOrAffidavitIndex);
                }
            }
        } else {
            for(var i = startIndex; i > (endIndex - 1); i--) {
                // cycle through from start to end Index and add to selected matters list if they are not opened locked or selected already
                let index : number = this.documentTemplateIds.indexOf(i);
                if(index < 0) {
                    this.documentTemplateIds.push(this.rows[i - 1].template.templateIdWithMortgageOrAffidavitIndex);
                }
            }
        }
        this.setComponentStateAttributes();
    }

    getindexofSelectedContact(id) : number {
        let index = _.findIndex(this.rows, function(o) {
            console.log(o);
            return o.document.id == id;

        });
        return index;
    }

    isSelected(templateId : string) : boolean {

        return !!this.documentTemplateIds.find((id : string) => id === templateId);
    }

    setActiveIndex(i, id : string) {
        this.previousIndexFromTabKey = this.activeIndexFromTabKey;
        this.activeIndexFromTabKey = i;
        this.lastVisitedRowIndex = i;
        this.activeIdFromTabKey = id;
        //console.log("activeIndex" + this.previousIndexFromTabKey + "-" + this.activeIndexFromTabKey + "-" + i);

    }

    get matterTypeCode() : string {
        return this.matter && this.matter.derivedMatterType;
    }

    onKeyPress(event : KeyboardEvent) {
        if(event.keyCode === 113 && this.documentTemplateIds.length > 0  //F2 key
        && !this.isMatterDisabled()) {
            this.produceDocument();
        }
    }

    // on click of produce button from document generation page
    produceDocument(row? : any) : void {
        let docTemplateIds : any = [];
        if(row && row.template)
        {
            let templateId: string = row.template.templateIdWithMortgageOrAffidavitIndex;
            docTemplateIds.push(templateId);
        }
        else{
            docTemplateIds = this.documentTemplateIds;
        }
        this.resetActionButtonFlag();
        this.burgerMenuClicked('produceDocumentButton');
        this.message = '';

        // if no row is selected display message for select at least one record
        if(docTemplateIds.length === 0) {
            this.message = messages.documentProduction.selectTemplateError;
        } else {
            //Submit request to produce document without stop codes
            this.doMatterSaveAndProduceDocument(docTemplateIds.sort());
        }

    }

     saveMatterAndInitComponent(): void {
        this.dialogService.confirm('Confirmation', 'In order to see the list of available templates and hence, to produce documents; the record must first be saved.', false, 'Save').subscribe(res => {
            if (res && res == true) {
                let matterTab = this.tabServiceInstanceForMatter.activeTab as MatterTab;
                if (matterTab && matterTab.matterComponent) {
                    matterTab.matterComponent.validateAndSaveMatterAndSubscribe();
                }
                this.reInitAfterMatterSaved();
            } else {
                console.log("Cancel Save New Matter");
            }
        });
    }

    reInitAfterMatterSaved():void{
        if(this.matter && this.matter.id && this.matter.id > 0){
            this.ngOnInit();
        }else{
            setTimeout(()=> this.reInitAfterMatterSaved(), 50);
        }
    }

    doMatterSaveAndProduceDocument(docTemplateIds : any[]) : void {

        if(this.matter && (this.matter.dirty || this.matter.isDraftMatter())) {
            if(this.matter.dirty){
                this.dialogService.confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save').subscribe(res => {
                    if(res && res == true && this.tabServiceInstanceForMatter) {
                        this.saveMatterAndProduceDocuments(docTemplateIds);
                    } else {
                        console.log("cancel");
                    }
                });
            } else  {
                this.saveMatterAndProduceDocuments(docTemplateIds);
            }

        } else {

            /*
             Check if any of these id's has a validationFlag and matter is not ballanced then display the error message.
             This will be moved to a service
            */
            if (this.validateMatterBeforeProducingDocument(this.matter, docTemplateIds)) {
                this.submitProduceDocumentRequest(DocumentProductionData.of(this.matter && this.matter.id, docTemplateIds, this.outputType) , docTemplateIds);
            }
        }
    }

    saveMatterAndProduceDocuments(docTemplateIds : any[]) {
        // force adjustment mode synchronization so all SOAdj calculations before saving matter are correct
        if (this.matter.selectedProgressionStatus !== this.matter.adjustmentStatusMode){
            this.matter.updateStatusMode(this.matter.selectedProgressionStatus);
        }
        let matterTab = this.tabServiceInstanceForMatter.activeTab as MatterTab;
        if(matterTab && matterTab.matterComponent) {
            let subscription : Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result : boolean) => {
                if(result) {
                    this.validateMatterAndProduceDocuments(docTemplateIds);
                    subscription.unsubscribe();

                }
            });
        }
    }

    validateMatterAndProduceDocuments(docTemplateIds: any[] , validationSubject? : Subject<boolean>) {
        /*
        * In case of supplemental task modal, we don't get the updated matter after saving it.
        * So we have to manually mark the matter instance as clean
        */
        this.markMatterClean();
        if (this.validateMatterBeforeProducingDocument(this.matter, docTemplateIds)) {
            this.submitProduceDocumentRequest(DocumentProductionData.of(this.matter && this.matter.id, docTemplateIds, this.outputType), docTemplateIds , undefined, validationSubject);
        }
        else if(validationSubject){
            validationSubject.next(false);
        }
    }

    markMatterClean() {
        this._matter.dirty = false;
    }

    /*
     Check if any of these id's has a validationFlag and matter is not ballanced
     This needs to be moved to a common place
    */
    validateMatterBeforeProducingDocument(matter : Matter, selectedTemplateIds : string []) : boolean {

        let documentTemplates : DocumentTemplate[] = this._rows.map(item => item.template);

        // Check if any of these id's has a validationFlag and matter is not balanced then display the error message.
        for(let id of selectedTemplateIds) {

            let documentTemplate : DocumentTemplate =  documentTemplates.find((template : DocumentTemplate) => template.docGenTemplateId === parseInt(id) );
            let currentMortgageIndex = null;
            if(documentTemplate.mortgageIndex){
                let idArray = id.split('-');
                if(idArray && idArray.length > 1){
                    currentMortgageIndex = Number(idArray[1]);
                }
            }
            let isMatterValidForProduction =  this.documentProductionService.validateMatterBeforeProducingDocument(documentTemplate, currentMortgageIndex, matter, this.supTask, this.dialogService, true);
            if(!isMatterValidForProduction){
                return isMatterValidForProduction; //Only return if false;
            }
        }
       return true;
    }

    displayErrorMessage(errorText : string) : void {
        this.dialogService.confirm('Error', errorText, true, 'OK').subscribe(res => {
        })
    }


    // This method checks that document is generated with given template id. In case of multi-priority template or affidavit execution template,
    // the template id will be same but mortgageIndex/affidavitIndex will be different therefore it uses that too for comparision if given.
    isDocumentGeneratedWithGivenTemplate(document: Document, templateId: number, mortgageIndex: number, affidavitIndex: number, holdbackIndex: number): boolean {
        if (mortgageIndex != null && !isNaN(mortgageIndex)) {
            return document.templateId === templateId && document.currentMortgageIndex === mortgageIndex;
        } else if (affidavitIndex != null && !isNaN(affidavitIndex)) {
            return document.templateId === templateId && document.currentAffidavitIndex === affidavitIndex;
        } else if (holdbackIndex != null && !isNaN(holdbackIndex)) {
            return document.templateId === templateId && document.currentHoldbackIndex === holdbackIndex;
        } else {
            return document.templateId === templateId;
        }
    }

    public startPullingProducedDocuments(docTemplateIds? : any[]) : Observable<boolean> {
        let dialogRef  = this.dialogService.confirmDialog('Documents', 'Document is being generated. Please wait.', true, 'Cancel');
        let producedDocTemplateIds: any[] = [];
        let isDocumentProduceCompleted = new Subject<boolean>();
        let checkProduceDocumentIntervalID = window.setInterval(() => {
            this.documentProductionService.getDocuments(this.matter && this.matter.id).subscribe(data => {
                data.forEach((document : Document) => {
                    docTemplateIds.forEach( templateId =>{
                    let templateIdArr : string[] = templateId.split("-");
                    let currentAffidavitIdx: number = null;
                    let currentMortgageIdx: number = null;
                    let currentHoldbackIndex: number = null;
                    if (document.currentAffidavitIndex != null) {
                        currentAffidavitIdx = parseInt(templateIdArr[1]);
                    } else if (document.currentHoldbackIndex != null) {
                        currentHoldbackIndex = parseInt(templateIdArr[1]);
                    } else {
                        currentMortgageIdx = parseInt(templateIdArr[1]);
                    }
                    if(this.isDocumentGeneratedWithGivenTemplate(document, parseInt(templateIdArr[0]), currentMortgageIdx, currentAffidavitIdx, currentHoldbackIndex) && this.isDocumentProduceReady(document)) {
                        producedDocTemplateIds.push(templateId);
                    }
                });
            });
        });
        if(producedDocTemplateIds.length == docTemplateIds.length) {
            window.clearInterval(checkProduceDocumentIntervalID);
            dialogRef.close();
            isDocumentProduceCompleted.next(true);
        }
        }, 2000)
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                window.clearInterval(checkProduceDocumentIntervalID);
                isDocumentProduceCompleted.next(false);
            }
        });
        return isDocumentProduceCompleted;
    }

    /**
     * This method submits the request to produce document to backend. It can be called from two places
     * document generation page and on close of stop code popup.
     * @param documentProductionData
     */
    public submitProduceDocumentRequest(documentProductionData : DocumentProductionData , docTemplateIds? : any[], reqFromStopCodeDialog: boolean = false , validationSubject? : Subject<boolean>) {
        //this.documentProductionInProgress = true;
        this.documentProductionService.getDocuments(this.matter && this.matter.id).subscribe(data => {
            let errors : string[] = [];
            data.forEach((document : Document) => {
                (docTemplateIds &&  docTemplateIds.length > 0 ? docTemplateIds : this.documentTemplateIds).forEach(id => {
                    let templateIdArr : string[] = id.split("-");
                    let currentAffidavitIdx: number = null;
                    let currentMortgageIdx: number = null;
                    let currentHoldbackIndex: number = null;
                    if (document.currentAffidavitIndex != null) {
                        currentAffidavitIdx = parseInt(templateIdArr[1]);
                    } else if (document.currentHoldbackIndex != null) {
                        currentHoldbackIndex = parseInt(templateIdArr[1]);
                    } else {
                        currentMortgageIdx = parseInt(templateIdArr[1]);
                    }
                    if(this.isDocumentGeneratedWithGivenTemplate(document, parseInt(templateIdArr[0]), currentMortgageIdx, currentAffidavitIdx, currentHoldbackIndex)) {
                        if(!this.isDocumentProduceReady(document)) {
                            if(this.isDocumentSubmitted(document) && !reqFromStopCodeDialog) {
                                errors.push("Document production of " + this.getTemplateName(id) + " still in progress");
                            }
                            else if(document.isWordOrWordPerfect && templateIdArr && templateIdArr.length > 0 && documentProductionData.isTemplateRequestedWordOrWordPerfect(templateIdArr[0]) && document.isDocumentOpened) {
                                errors.push("The document " + document.documentName + " is currently opened by " + document.lockedByUser.loginId +
                                            " and cannot be overwritten. Please ask " + document.lockedByUser.loginId + " to close document and try again.");
                            }
                        }
                    }
                });
            });

            if(errors.length > 0) {
                this.dialogService.content({
                                               content       : DpModal,
                                               context       : {
                                                   errors : errors
                                               },
                                               onFulfillment : (result) => {
                                               }
                                           });
                if(validationSubject){
                    validationSubject.next(false);
                }
                //this.dialogService.confirm('Error', errors.join(" "), true);
            }
            else {
                // web service to produce document for template ids
                if (this.matter && this.matter.secondarySoaSheets && this.matter.secondarySoaSheets.length > 0){
                    let documentTemplates: DocumentTemplate[] = [];
                    if(documentProductionData.templates && this.rows) {
                        documentTemplates = this.rows.filter(tp => !!tp.template && documentProductionData.templates.map(dc => {
                            return dc.templateId
                        }).indexOf(tp.template.docGenTemplateId) > -1).map( dt => { return dt.template} );
                    }
                    if(documentTemplates && documentTemplates.some( tp =>  tp.isTemplateFundSum)){
                        this.dialogService.confirm('Warning', 'The Funds Summary will only reflect the details from the primary Statement of Account.', true, 'OK').subscribe(res => {
                            this.submitDocumentProduction(documentProductionData , validationSubject);
                        });
                    }
                    else{
                        this.submitDocumentProduction(documentProductionData, validationSubject);
                    }
                } else {
                    this.submitDocumentProduction(documentProductionData, validationSubject);
                }
            }
        });
    }

    private submitDocumentProduction(documentProductionData : DocumentProductionData , validationSubject? : Subject<boolean>) : void {
        this.documentProductionService.produceDocument(documentProductionData, this.matterSupplementalTaskCategoryId, true)
            .subscribe(
                (response : DocumentProductionData) => {
                    //if there are any templates exist in the response then open stop codes popup
                    if(response.templates && response.templates.length > 0) {
                        this.documentProductionData = response;
                        this.openStopCodesPopup(validationSubject);
                    } else {
                        //If there are no templates in response that means all templates are processed successfully so start polling
                        if(!this.keepChecks) {
                            this.documentTemplateIds = [];
                            this.setComponentStateAttributes();
                        }
                        if(validationSubject){
                            validationSubject.next(true);
                        }
                    }
                    // Reset the value to force pulling the documents at least once to get the submitted status
                    this.matterDocumentsHash = '';
                });
    }

    private async checkNeedToUpdateDocs(): Promise<boolean> {
        const matterDocumentMetadata: MatterDocumentMetadata = await this.documentProductionService.getDocumentsMetaData(this.matter && this.matter.id).toPromise();
        if(!matterDocumentMetadata){
            return true;
        }else if(matterDocumentMetadata.aggregateDocumentsStatusHash != this.matterDocumentsHash){
            this.matterDocumentsHash = matterDocumentMetadata.aggregateDocumentsStatusHash;
            return true;
        }
        return false;
    };

    public async updateDocuments() :  Promise<void> {

        const needsDocsUpdate : boolean = await this.checkNeedToUpdateDocs();
        if(!needsDocsUpdate){
            return;
        }

        this.documentProductionService.getDocuments(this.matter && this.matter.id, true) //returned documents can mixed with docs from STC page and document
            // production page
            .subscribe(data => {
                //get the documents with same matterSupplementalTaskCategoryId either with value (from STC) or null (from document Production page)
                let documents = data.filter(doc => doc.matterSupplementalTaskCategoryId == this.matterSupplementalTaskCategoryId);

                documents.forEach((doc: Document) => {

                    for(let i = 0; i < this.rows.length; i++) {
                        //Updating the html row if document doesn't already exist in table or it has been updated.
                        if(this.isDocumentGeneratedWithGivenTemplate(doc, this.rows[i].template.docGenTemplateId, this.rows[i].template.mortgageIndex,
                            this.rows[i].template.affidavitIndex, this.rows[i].template.holdbackIndex)
                        ) {
                            if (doc.isWord || doc.isWordPerfect) {
                                this.rows[i].word = doc;
                            }
                            else if(doc.isPdf) {
                                this.rows[i].pdf = doc;
                            }
                        }
                    }
                    this.addToMatterDocuments(doc);

                }, this);
            });
    }


    //As there are multiple functions which is adding documents to this array('matterDocuments') due to that duplicates docs are getting added,
    //therefore created this method that defensively checks if document doesn't already exist in array then add it else replaces it.
    public addToMatterDocuments(doc : Document) : void {
        doc.description = this.documentUtility.getDocDescription(doc.templateId, this.rows);
        let existingDoc : Document = _.find(this.matterDocuments, existingDoc => existingDoc.id == doc.id);
        if(existingDoc) {
            //If document exists and it has been updated since last time then change it in document table
            if(existingDoc.lastUpdatedTimeStamp < doc.lastUpdatedTimeStamp) {
                let existingDocIndex : number = _.findIndex(this.matterDocuments, existingDoc => existingDoc.id == doc.id);
                this.matterDocuments[existingDocIndex] = doc;
            }
        } else {
            this.matterDocuments.push(doc);
        }
    }

    //As we need to update the document lock status as soon as it is edited/closed by other user therefore we are doing continuously polling.
    //In R1 we will look for a better solution to achieve it
    public startPulling() : void {
        this.matterDocumentsHash = ''; // Reset the value to force pulling the documents at east once
        this.updateDocuments();
        clearInterval(this.scheduler);
        this.scheduler = setInterval(() => {
            this.updateDocuments();
        }, 2000);
    }

    public openStopCodesPopup(validationSubject? : Subject<boolean>) {
        const contentModalConfig : ContentDialogConfig = {
            content       : StopCodesComponent,
            context       : {
                documentProductionData : this.documentProductionData,
                viewContainerRef       : this.viewContainerRef
            },
            onFulfillment : (result : any) => {
                if(result) {
                    //Submit request to produce document with the document production data populated with stop codes
                    this.submitProduceDocumentRequest(this.documentProductionData, null, true , validationSubject);
                }
            }

        };
        this.dialogService.content(contentModalConfig);
    }

    public resetActionButtonFlag() : void {
        this.actionButtonFlag = false;
    }

    public toggleActionButtonFlag(event) : void {
        // stopPropagation mouse click listener so they don't interrupt these functions
        setTimeout(() => this.actionButtonFlag = true, 0);
        this.actionButtonFlag = !this.actionButtonFlag;
    }

    get docTypeDisplayName(): string {
        return DocumentProductionUtil.getDocTypeDisplayName(this.documentProfileType);
    }

    getDisabledItems(row: any){
        let disabledMenuItems = [];
        let outputText: string = this.docTypeDisplayName;
        if(this.isMatterDisabled() || row.template.isInfected  || row.template.isVirusScanPending){
            disabledMenuItems.push('Produce');
        }

        if(this.isMatterDisabled() || !(row.word && row.word.status == 'COMPLETED') ||
            row.template.isInfected || row.template.isVirusScanPending || row.word.isInfected || row.word.isVirusScanPending) {
            disabledMenuItems.push('Open Produced ' + outputText +' Document');
        }
        if(this.isMatterReadOnlyAccess || this.matter && this.matter.locked || !(row.pdf && row.pdf.status == 'COMPLETED') || row.template.isInfected || row.template.isVirusScanPending) {
            disabledMenuItems.push('Open Produced PDF Document');
        }

        if(this.isMatterDisabled() || !(row.word && row.word.status == 'COMPLETED') ||
            row.template.isInfected || row.template.isVirusScanPending || row.word.isInfected || row.word.isVirusScanPending) {
            disabledMenuItems.push('Convert to PDF');
        }

        return disabledMenuItems;
    }

    getOutputMenuItems(): SelectItem[] {
        return DocumentProductionUtil.getOutputMenuItems(this.documentProfileType);
    }

    getBurgerMenuItems() {
        let tempBM : any = [];
        let outputText: string = this.docTypeDisplayName;;
        tempBM.push('Produce');
        tempBM.push('Open Produced ' + outputText + ' Document');
        tempBM.push('Open Produced PDF Document');
        tempBM.push('Convert to PDF');
        return tempBM;
    }


    burgerMenuClicked(clickedMenuOption : string, row? : any) : void {
        let outputText: string = this.docTypeDisplayName;;
        switch(clickedMenuOption) {
            case 'Produce':
                this.produceDocument(row);
                break;
            case 'Open Produced ' + outputText +' Document':
                if(row && row.word && row.word.id && row.word.documentName) {
                    this.openWordForEdit(row.word.id, row.word.documentName, row.word.documentType, event);
                }
                break;
            case 'Open Produced PDF Document':
                if(row && row.pdf && row.pdf.id && row.pdf.documentName && row.pdf.isPdf) {
                    this.openPdfFile(row.pdf.id);
                }
                break;
            case 'Convert to PDF':
                if(row && row.word && row.word.id && row.word.documentName) {
                    this.convertToPDF(row);
                }
                break;
            default:
                return;
        }
    }

    convertToPDF(row: any): void{
        this.lockScreenService.lockForUpdate = true;
        this.documentProductionService.convertToPDF(this.matter && this.matter.id, row.word.id)
            .finally(() => this.lockScreenService.lockForUpdate = false)
            .subscribe(res =>
               { });
    }

    openProducedDocs($event ) : void {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        var trident = ua.indexOf("Trident/");
        var edge = ua.indexOf("Edge/");

        if(msie > 0 || trident > 0 || edge > 0) // If Internet Explorer
        {
            for(let i = 0; i < this.documentTemplateIds.length; i++) {
                let row = this.rows.find((row : any) => row.template.templateIdWithMortgageOrAffidavitIndex === this.documentTemplateIds[i]);
                if(row && row.document && row.document.id && row.document.documentName) {
                    setTimeout(() =>
                                   this.openWordForEdit(row.document.id, row.document.documentName, row.document.documentType, event), 1000 * i);
                }
            }
        }
        else  // If another browser
        {
            this.dialogService.confirm('Information', 'Opening Multiple Documents at once is only available in Internet Explorer. ' +
                                       'Please select and open each document individually.', true);
        }
    }

    areAllSelectedDocumentsCompleted() : boolean {
        let allDocs = this.rows;
        let selectedDocIds = this.documentTemplateIds;
        let selectedDocs = allDocs.filter(doc => selectedDocIds.indexOf(doc.template.docGenTemplateId) >= 0);
        return selectedDocs.filter(doc => !doc.document || doc.document.status !== "COMPLETED").length === 0;
    }

    multiSelectDocuments(isCheckBoxSelected : boolean) : void {
        this.documentTemplateIds = [];
        if(isCheckBoxSelected){
            for(let i = 0; i < this.rows.length; i++) {
                if(!this.rows[i].template.isInfected && !this.rows[i].template.isVirusScanPending){
                    this.documentTemplateIds.push(this.rows[i].template.templateIdWithMortgageOrAffidavitIndex);
                }
            }
        }

        this.setComponentStateAttributes();
    }

    isDocumentProduceReady(document: Document) {
        //Issue 26257, document template from different STC should not affect each other
        if(document.matterSupplementalTaskCategoryId != this.matterSupplementalTaskCategoryId){
            return true;
        }
        let status = this.documentUtility.mapStatus(document.status, false);
        return ((status === 'Available' || status === '' || status === 'Failed' || status === 'Self-referencing-include-file') && !document.lockedByUser);
    }

    isDocumentSubmitted(document : Document) : boolean {
        let status = this.documentUtility.mapStatus(document.status, false);
        return status === 'Submitted';
    }

    getTemplateName(templateId : string) : string {
        let templateName = "";
        this.rows.forEach(row => {
            if(row.template.docGenTemplateId == templateId) {
                templateName = row.template.fileName;
            }
        });
        return templateName;
    }

    onCategoryChange($event) {
        console.log("onCategoryChange: ", $event);
        this.allDocumentsSelected = false;
        this.multiSelectDocuments(false);
        this.page();
    }

    tableKeyCommands(event): void {

        this.onKeyPress(event);

        let charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));

        if(charCode === CustomKeyCodesEnum.Enter) {
            // open matter from enter key
            // Commenting this feature since now you can open word and pdf from row
            //this.keyCommandtoOpenMatter(event);
        }
        if(charCode === CustomKeyCodesEnum.SpaceBar) {

            event.preventDefault();

            // select matter with spacebar key
            this.keyCommandtoSelectMatter(event);
        }

        if(charCode === CustomKeyCodesEnum.Down) {
            event.preventDefault();
            if(event.shiftKey) {
                /// select next matter with shift + down arrow
                this.keyCommandtoSelectNextMatter(event);
            } else {
                // hilite next matter with down arrow
                this.keyCommandtoHiliteNextMatter(event);

            }
        }
        if(charCode === CustomKeyCodesEnum.Up) {
            event.preventDefault();


            if(event.shiftKey) {
                /// select prev matter with shift + up arrow
                this.keyCommandtoSelectPrevMatter(event);
            } else {
                // hilite prev matter with up arrow
                this.keyCommandtoHilitePreviousMatter(event);

            }
        }
    }

    keyCommandtoSelectMatter(event) {
        if(this.activeIndexFromTabKey < (this.rows.length)) {

            this.selectToProduce(this.activeIdFromTabKey, this.isSelected(this.activeIdFromTabKey), event);
            //  this.rowClicked(event,this.activeIndexFromTabKey);
        }
    }

    keyCommandtoOpenMatter(event) {
        this.documentTemplateIds = [];
        this.documentTemplateIds[this.documentTemplateIds.length] = this.activeIdFromTabKey;
        this.selectToProduce(this.activeIdFromTabKey, this.isSelected(this.activeIdFromTabKey), event);

        if(this.rows[this.activeIndexFromTabKey] && this.rows[this.activeIndexFromTabKey].document) {
            let documentId: string = this.rows[this.activeIndexFromTabKey].document.id;
            let documentName: string = this.rows[this.activeIndexFromTabKey].document.documentName;
            let documentType: string = this.rows[this.activeIndexFromTabKey].document.documentType;
            this.openWordForEdit(documentId, documentName, documentType, event);
        } else {
            this.rowClicked(event, this.activeIndexFromTabKey);
        }
        this.setComponentStateAttributes();
    }


    keyCommandtoSelectNextMatter(event) {
        this.keyCommandtoSelectMatter(event);
        if(this.documentTemplateIds.length > 0 && this.lastVisitedRowIndex < (this.rows.length - 1)) {

            if(document.activeElement.className === "toggleBurger") {
                jQuery(event.target).closest('tr').next('tr').focus();
            } else {
                jQuery(document.activeElement).next('tr').focus();
            }
            if(this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {

                this.clickRow(event, this.activeIndexFromTabKey + 1, this.rows[this.activeIdFromTabKey]);
                this.keyCommandtoHiliteNextMatter(event);
            } else {
                this.keyCommandtoSelectMatter(event);
            }

        }
    }


    keyCommandtoHiliteNextMatter(event) {
        if(this.activeIndexFromTabKey < this.rows.length) {
            if(document.activeElement.className === "toggleBurger") {
                jQuery(event.target).closest('tr').next('tr').focus();
            } else {
                jQuery(document.activeElement).next('tr').focus();
            }
        }
    }


    keyCommandtoHilitePreviousMatter(event) {
        if(this.activeIndexFromTabKey > -1) {
            if(document.activeElement.className === "toggleBurger") {
                jQuery(event.target).closest('tr').prev('tr').focus();
            } else {
                jQuery(document.activeElement).prev('tr').focus();
            }
        }
    }

    keyCommandtoSelectPrevMatter(event) {
        this.keyCommandtoSelectMatter(event);
        if(this.lastVisitedRowIndex > -1) {
            if(document.activeElement.className === "toggleBurger") {
                jQuery(event.target).closest('tr').prev('tr').focus();
            } else {
                jQuery(document.activeElement).prev('tr').focus();
            }
            if(this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {
                //  this.rowClicked(event, (this.lastVisitedRowIndex-1));

                this.clickRow(event, this.activeIndexFromTabKey - 1, this.rows[this.activeIdFromTabKey]);
                this.keyCommandtoHilitePreviousMatter(event);
            } else {
                let lastVisitedTemplate : DocumentTemplate = this.rows[this.lastVisitedRowIndex].template;
                this.selectToProduce(lastVisitedTemplate.templateIdWithMortgageOrAffidavitIndex,
                                     this.isSelected(lastVisitedTemplate.templateIdWithMortgageOrAffidavitIndex), event);
                // this.rowClicked(event, this.lastVisitedRowIndex);
            }

        }
    }

    addClassToRow(e) {
        // when you focus on burger menu in row we add background color to row
        jQuery(e.target).closest('tr').addClass("active-hover");
    }

    removeClassFromRow(e) {
        // when you blur on burger menu in row we remove background color to row
        jQuery(e.target).closest('tr').removeClass("active-hover");

    }

    manageCategories() : void {
        this.dialogService.content({
                       content       : ManageDocumentProductionCategoriesModalComponent,
                       context       : {
                           matter                           : this.matter

                       },
                       onFulfillment : (result : any) => {
                           if(result && result.documentTemplateCategories){
                               this.refreshTemplates(true);
                           }
                       }
                   });
    }

    manageTemplates() : void {
        this.dialogService.content({
                   content       : ManageFolderTemplatesModalComponent,
                   context       : {
                       accountFileFolder    : null,
                       documentProfileId    : this.matter && this.matter.documentProfileId,
                       account              : null,
                       matter               : this.matter,
                       isMatterReadOnlyAccess : this.isMatterDisabled()
                   },
                   onFulfillment : (result) => {
                       this.checkVirusScanStatus();

                   },
                   fullScreen    : false
               });
    }

    zendeskRequest(requestType: ZendeskRequestType){

        this.documentProductionService.zendeskUrl(requestType).subscribe(zendeskUrl => {
            window.open(zendeskUrl,'_blank');
        });
    }

    isDefaultCatSelected() : boolean {
        return this.defaultCat == this.categoryFilter;
    }

    toggleDefaultCat() : void {
        if(!this.isDefaultCatSelected()){
            this.defaultCat = this.categoryFilter;
            this.updateDefaultCategory();
        }
    }

    updateDefaultCategory(): void {
        let documentProductionDefaultCategoy: DocumentProductionDefaultCategory;
        if(this.userConfigurationService.userConfig.documentProductionDefaultCategories.length){
            documentProductionDefaultCategoy = this.userConfigurationService.userConfig.documentProductionDefaultCategories.find(cat=>{
                if(this.matter && this.matter.isProjectSale){
                    return cat.matterType == PROJECT_SALE_MATTER_TYPE;
                }else {
                    return cat.matterType == this.matterTypeCode;
                }
            });
            if(!documentProductionDefaultCategoy){
                documentProductionDefaultCategoy = new DocumentProductionDefaultCategory();
                if(this.matter && this.matter.isProjectSale){
                    documentProductionDefaultCategoy.matterType = PROJECT_SALE_MATTER_TYPE;
                } else {
                    documentProductionDefaultCategoy.matterType = this.matterTypeCode;
                }
                this.setDefaultCategory(documentProductionDefaultCategoy);
                this.userConfigurationService.userConfig.documentProductionDefaultCategories.push(documentProductionDefaultCategoy);
            }
            else{
                this.setDefaultCategory(documentProductionDefaultCategoy);
            }

        }
        else{
            documentProductionDefaultCategoy = new DocumentProductionDefaultCategory();
            if(this.matter && this.matter.isProjectSale){
                documentProductionDefaultCategoy.matterType = PROJECT_SALE_MATTER_TYPE;
            } else {
                documentProductionDefaultCategoy.matterType = this.matterTypeCode;
            }
            this.setDefaultCategory(documentProductionDefaultCategoy);
            this.userConfigurationService.userConfig.documentProductionDefaultCategories.push(documentProductionDefaultCategoy);
        }
        this.userConfigurationService.updateUserConfiguration();
    }

    setDefaultCategory(documentProductionDefaultCategoy : DocumentProductionDefaultCategory): void {
        let defaultCat = this.categories.find(cat=>{
            return cat.id == this.defaultCat;
        });
        if(defaultCat){
            documentProductionDefaultCategoy.defaultCategory = defaultCat.categoryDescription;
        }
    }

    produceMerge() : void {
        if(this.documentTemplateIds && this.documentTemplateIds.length > 1)
        {
            if (this.validateMatterBeforeProducingDocument(this.matter, this.documentTemplateIds)) {
                let selectedRows = this.rows.filter( item  => this.documentTemplateIds.indexOf(item.template.templateIdWithMortgageOrAffidavitIndex) > -1);
                if(selectedRows && selectedRows.length > 1)
                {
                    this.matterService.callOperationAfterMatterSaved(this.matter, ()=>{
                        /*
                        * In case of supplemental task modal, we don't get the updated matter after saving it.
                        * So we have to manually mark the matter instance as clean
                        */
                        this.markMatterClean();
                        let templateList : DocumentTemplate[] = selectedRows.map( item => {
                            return item.template;
                        });

                        if (this.matter && this.matter.secondarySoaSheets && this.matter.secondarySoaSheets.length > 0){
                            if(templateList && templateList.some( tp =>  tp.isTemplateFundSum)) {
                                this.dialogService.confirm('Warning', 'The Funds Summary will only reflect the details from the primary Statement of Account.', true, 'OK').subscribe(res => {
                                    this.openMergeDocumentModal(templateList);
                                });
                            }
                            else{
                                this.openMergeDocumentModal(templateList);
                            }
                        }
                        else{
                            this.openMergeDocumentModal(templateList);
                        }

                    });
                }
            }
        }
    }

    openMergeDocumentModal(templateList : any[]) : void {
        this.dialogService.content({
            content       : DocumentTemplateMergeComponent,
            context       : {
                selectedMatter            : this.matter,
                templateIdList            : templateList,
                outputType                : this.outputType,
                supplementalTaskCategoryId: this.matterSupplementalTaskCategoryId
            },
            onFulfillment : (result) => {
            }
        });
    }

    sort(event : Event, sorter : DpDefaultSorter) : void {
        event.preventDefault();
        event.stopPropagation();
        if(sorter) {
            sorter.sort();
        }
    }

    // DPPMP-30814
    retrieveDocumentDataSet(event: Event,row: any) : void {
        // clear existing data first
        this.renderer.setProperty(event.target, 'data-documentFields', null);
        this.renderer.setProperty(event.target, 'data-documentData', null);

        let documentId = row.pdf.id ? row.pdf.id : row.word.id;
        const api = matterApi.documentDataSet(this.matter && this.matter.id, documentId);
        this.http.get(api, false).subscribe(data => {
            this.renderer.setProperty(event.target, 'data-documentFields', data);
            this.renderer.setProperty(event.target, 'data-documentData', JSON.stringify(data));
        });
    }

    get matterTab() : MatterTab {
        return this.tabService.activeTab as MatterTab;
    }

    // Saves the select all checkbox status and selected template Ids to the matterTab to help with switching tabs
    setComponentStateAttributes() : void {
        if(this.matterTab){
            this.matterTab.documentProductionSelectedDocumentIds = this.documentTemplateIds;
            this.matterTab.documentProductionMultiSelectStatus = this.allDocumentsSelected;
        }
    }

    // Gets the select all checkbox status and selected template Ids from the matterTab
    getComponentStateAttributes() : void {
        if(this.matterTab) {
            this.documentTemplateIds = this.matterTab.documentProductionSelectedDocumentIds;
            this.allDocumentsSelected = this.matterTab.documentProductionMultiSelectStatus;
        }
    }

    // clears the select all checkbox status and selected template Ids in the matterTab
    resetComponentStateAttributes(): void {
        if(this.matterTab){
            this.matterTab.documentProductionSelectedDocumentIds = [];
            this.matterTab.documentProductionMultiSelectStatus = false;
        }
    }

    get isGetDataSetDisplayed(): boolean{
        return this.appConfig && this.appConfig.automationFeaturesAvailable;
    }

    hasTemplateAndCategoryManagementPermission(): boolean{
        return this.authorizationService.hasTemplateAndCategoryManagementFullAccess();
    }

    get isRtfProfile(): boolean {
        return this.documentProfileType && this.documentProfileType === 'RTF';
    }

    getCategoryDescription(documentTemplateCategory: DocumentTemplateCategory) : string {
        return MatterTypeUtil.getDocumentCategoryDescription(documentTemplateCategory, this.matterTypeInfos);
    }

    get keepChecks() : boolean {
        return this.userConfigurationService.keepCheckmarkAfterProducingDocument;
    }

    set keepChecks(value: boolean) {
        this.userConfigurationService.keepCheckmarkAfterProducingDocument = value;
    }

    updateKeepChecks() : void {
        this.userConfigurationService.updateUserConfiguration();
    }

    isMatterDisabled() : boolean {
        return MatterUtil.isMatterDisabled(this.matter, this.matterTab, this.authorizationService, this.tabService);
    }

}
