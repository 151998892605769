import {Injectable} from '@angular/core';
import {Matter} from './shared/matter';
import {Observable} from 'rxjs/Observable';
import {TemplateCodeValues} from './shared/evaluate-template-codes';
import {matterApi} from './shared/matter-api';
import {HttpClient} from '../core';
import {MatterService} from './matter.service';
import {StatementAdjustment} from './statement-adjustment/statement-adjustment';

import {TabsService} from '../core/tabs.service';
import {Utils} from './shared/utils';
import {BaseEntity} from '../shared/BaseEntity/base-entity';

@Injectable()
export class TemplateCodeService {

    constructor(private http: HttpClient,
                private matterService: MatterService,
                private tabsService: TabsService,) {

    }

    private _cachedTemplateCodesValues: Map<Matter, TemplateCodeValues> = new Map<Matter, TemplateCodeValues>();

    // this is to evaluate codes on given matter.
    evaluateTemplateCodes(matter: Matter, templateCodes: string[]): Observable<TemplateCodeValues> {
        let url: string = matterApi.evaluateCodes;
        let clonedMatter = new Matter(matter);
        clonedMatter.cleanUpMatterBeforeSaving(this.matterService);
        BaseEntity.clearAllIdentifiers(clonedMatter);

        let templateCodesRequest: TemplateCodeValues = new TemplateCodeValues();
        templateCodesRequest.matter = clonedMatter;
        templateCodesRequest.templateCodes = templateCodes;

        let cachedKeys: Matter[] = Array.from(this._cachedTemplateCodesValues.keys());
        let keyOfCachedValue: Matter = cachedKeys.find( mt => mt.id === templateCodesRequest.matter.id && matter.selectedProgressionStatus === templateCodesRequest.matter.selectedProgressionStatus);
        if (keyOfCachedValue){
            if ( JSON.stringify(keyOfCachedValue) === JSON.stringify(templateCodesRequest.matter)){
                return Observable.of(this._cachedTemplateCodesValues.get(keyOfCachedValue));
            }
        }
        return this.http.post(url, templateCodesRequest)
                   .map((res) => {
                       let result: TemplateCodeValues = new TemplateCodeValues(res['TemplateCodeValues']);
                       this._cachedTemplateCodesValues.set(clonedMatter, result);
                       return result;
                   });
    }

    async calculateMatterTemplateCodes(matter: Matter): Promise<void> {
        let templateCodesInMatter: string[] = matter.getTemplateCodesUsedInMatter();


        if(templateCodesInMatter.length > 0) {
            let templateCodeResponse: TemplateCodeValues = await this.evaluateTemplateCodes(matter, templateCodesInMatter).toPromise();
            this.updateTemplateCodesInMatter(matter, templateCodeResponse);
        }
    }

    updateTemplateCodesInMatter(matter: Matter, templateCodeResponse: TemplateCodeValues): void {
        this.updateTemplateCodesInAdjustments(matter, templateCodeResponse);
    }

    updateTemplateCodesInAdjustments(matter: Matter, templateCodeResponse: TemplateCodeValues): void {
        matter.statementOfAdjustments.filter(soa => soa.isComponentAdjustment()).forEach(soa => {
            this.updateTemplateCodesInComponentAdj(soa, templateCodeResponse);
        });
    }

    updateTemplateCodesInComponentAdj(soa: StatementAdjustment, templateCodeResponse: TemplateCodeValues): void {
        if(soa.soAdjComponent.isTemplateCodeUsedForCreditNoteCalculation && templateCodeResponse.hasCode(soa.soAdjComponent.creditNoteCalculationMethod)) {
            soa.soAdjComponent.creditNoteAmount = Utils.convertAmountToNumberWithSign(templateCodeResponse.getValueByTemplateCode(soa.soAdjComponent.creditNoteCalculationMethod));
            soa.amount = soa.soAdjComponent.creditNoteAmount;
        }

        soa.soAdjComponent.items.filter(item => item.isTemplateCodeUsedForCalculation && templateCodeResponse.hasCode(item.calculationMethod))
           .forEach(item => {
               item.amount = Utils.convertAmountToNumberWithSign(templateCodeResponse.getValueByTemplateCode(item.calculationMethod));
           })
    }

    /* The below methods commented as approach to reEvaluate template codes has changed. Now we are evaluating all the codes every time regardless the
     dependent fields have changed or not.

    get activeMatterTab(): MatterTab {
        return this.tabsService && this.tabsService.activeTab as MatterTab;
    }

    /**
     * This method re-calculates the DP/Field codes used in matter. It checks which code's data has been changed since last time and only re-calculates those.
     * This method should be called from all the points where input fields for any of DP code used in matter can be modified.
     * @param {Matter} matter
     * @returns {Promise<void>}
     */

    /*async reEvaluateTemplateCodes(matter: Matter): Promise<void> {
        let referenceMatter: Matter = new Matter(this.activeMatterTab.docGenReferenceMatter);
        this.updateDocGenReferenceMatter(matter);
        let codesRequireReEvaluation: string[] = [];
        let templateCodesInMatter: string[] = matter.getTemplateCodesUsedInMatter();
        if(!referenceMatter) {
            codesRequireReEvaluation.push(...templateCodesInMatter);
        } else {

            templateCodesInMatter.forEach(templateCode => {
                if(templateCode == '<<10153>>' && this.hasDataChangedForDPCode10153(matter, referenceMatter)) {
                    codesRequireReEvaluation.push(templateCode);
                }
            })
        }

        if(codesRequireReEvaluation.length > 0) {
            let templateCodeResponse: TemplateCodeValues = await this.evaluateTemplateCodes(matter, codesRequireReEvaluation).toPromise();
            this.updateTemplateCodesInMatter(matter, templateCodeResponse);
        }
    }




    /**
     * This method is for calculating the template codes for component adjustments. It doesn't compare the data with previous matter state but rather forces
     * recalculation of DP codes used in component adjustments. It is used when adjustments are propagated from project to matter or one sheet to another sheet.
     * @param {Matter} matter
     * @returns {Promise<void>}
     */
    /*async evaluateAdjustmentTemplateCodes(soa: StatementAdjustment, matter: Matter): Promise<void> {
        if(soa.isComponentAdjustment()) {
            await this.evaluateComponentAdjustmentTemplateCodes(soa, matter);
        }
    }

    async evaluateComponentAdjustmentTemplateCodes(soa: StatementAdjustment, matter: Matter): Promise<void> {
        let templateCodes: string[] = soa.soAdjComponent.extractTemplateCodesFromComponentAdj();
        templateCodes = _.uniq(templateCodes);

        if(templateCodes.length > 0) {
            let templateCodeResponse: TemplateCodeValues = await this.evaluateTemplateCodes(matter, templateCodes).toPromise();
            this.updateTemplateCodesInComponentAdj(soa, templateCodeResponse);
        }
    }

    public updateDocGenReferenceMatter(matter: Matter) {
        let matterTab = this.tabsService.activeTab as MatterTab;
        matterTab.docGenReferenceMatter = new Matter(matter);
    }


    hasDataChangedForDPCode10153(matter: Matter, referenceMatter: Matter): boolean {
        if(matter.considerationLtt && matter.considerationLtt.salePriceAdjustment &&
            (!referenceMatter.considerationLtt || !referenceMatter.considerationLtt.salePriceAdjustment)) {
            return true;
        } else if((!matter.considerationLtt || !matter.considerationLtt.salePriceAdjustment) &&
            referenceMatter.considerationLtt && !referenceMatter.considerationLtt.salePriceAdjustment) {
            return true;
        } else if(matter.considerationLtt && matter.considerationLtt.salePriceAdjustment &&
            referenceMatter.considerationLtt && referenceMatter.considerationLtt.salePriceAdjustment) {
            if(matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice != referenceMatter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice
                //We can de-normalize net sale price
                || matter.considerationLtt.salePriceAdjustment.totalNetSalePrice(matter.soaFederalHst, matter.soaProvincialHst)
                != referenceMatter.considerationLtt.salePriceAdjustment.totalNetSalePrice(matter.soaFederalHst, matter.soaProvincialHst)
            ) {
                return true;
            }
        }

        return false;
    }*/
}
