const isFunction = fn => typeof fn === "function";

const doUnsubscribe = subscription => {
    subscription &&
    isFunction(subscription.unsubscribe) &&
    subscription.unsubscribe();
};

const doUnsubscribeIfArray = subscriptionsArray => {
    Array.isArray(subscriptionsArray) &&
    subscriptionsArray.forEach(doUnsubscribe);
};

export function AutoUnsubscribeDecorator({
                                    blackList = [],
                                    arrayName = "",
                                    event = "ngOnDestroy"
                                } = {}) {
    return function(constructor: Function) {
        const original = constructor.prototype[event];

        constructor.prototype[event] = function() {
            console.log('AutoUnsubscribeDecorator : '+event);
            if (arrayName) {
                doUnsubscribeIfArray(this[arrayName]);
                isFunction(original) && original.apply(this, arguments);
                return;
            }

            for (let propName in this) {
               if (blackList.indexOf(propName)) continue;

                const property = this[propName];
                doUnsubscribe(property);
            }

            isFunction(original) && original.apply(this, arguments);
        };
    };
}
