import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ResidentialRentalRebateForm} from '../forms/eregistration/modal/residential-rental-rebate-form';
import {NewHomePstRebateForm} from '../forms/eregistration/modal/pst-rebate-form-new-home-modal/new-home-pst-rebate-form';

export class TypeOfMatterFormValue {
    public static readonly RESIDENTIAL_RENTAL_REBATE_FORM : TypeOfMatterForm = "RESIDENTIAL_RENTAL_REBATE_FORM";
    public static readonly NEW_HOME_PST_REBATE_FORM : TypeOfMatterForm = "NEW_HOME_PST_REBATE_FORM";
}

export type TypeOfMatterForm = 'RESIDENTIAL_RENTAL_REBATE_FORM' | 'NEW_HOME_PST_REBATE_FORM';

export class MatterForm  extends  BaseEntity {

    id: number;
    formType: TypeOfMatterForm;
    residentialRentalRebateForm : ResidentialRentalRebateForm;
    newHomePstRebateForm: NewHomePstRebateForm;

    constructor( matterForm?: MatterForm) {
        super(matterForm);
        if(matterForm) {
            if(matterForm.residentialRentalRebateForm) {
                this.residentialRentalRebateForm = new ResidentialRentalRebateForm(matterForm.residentialRentalRebateForm);
            }
            if(matterForm.newHomePstRebateForm) {
                this.newHomePstRebateForm = new NewHomePstRebateForm(matterForm.newHomePstRebateForm);
            }
        }

    }
}
