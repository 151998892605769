import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import Utils from '../../../shared-main/utils';
import {Matter} from '../../shared';
import {Mortgage} from '../../shared/mortgage';
import {DpBooleanValueTypes} from '../../shared/dp-boolean';

export class SoAdjVTBMortgage extends BaseEntity {

    id: number;
    mortgageId: number;

    heading: string;
    firstInput: string;
    secondInput: string;
    infoOnly : boolean;

    getAdjustmentDescription(mortgagePriority: number, matter?: Matter, mortgage?: Mortgage): string {
        if(matter && mortgage && mortgage.isLoanTypeArranged() && mortgage.autoCreateVtbMortgageAdjustment == DpBooleanValueTypes.YES ){
            let mortgageeName = matter.getMortgageeName(mortgage, true);
            let description = `${Utils.getLongOrdinal(mortgagePriority).toUpperCase()} MORTGAGE ${mortgageeName ? ' - '+mortgageeName : ''}`;
            return description.substring(0,38);
        } else {
            return `${Utils.getLongOrdinal(mortgagePriority).toUpperCase()} MORTGAGE BACK TO VENDOR`;
        }

    }
}
