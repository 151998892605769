import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {currentMatter} from './current-matter';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

/**
 * Selectively enable a field only for a subset of matter types (controlled through the fieldRestrictions definition below).
 * This directive will be enhanced to take into account other matter specific information, apart from the type (province being one likely candidate).
 *
 */
@Directive({selector: '[dpIfApplicableForMatter]'})
@AutoUnsubscribe()
export class DpIfApplicableForMatter implements OnInit {

    private viewCreated: boolean;
    private conditionedField: string;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef
                ) {
    }

    ngOnInit(): void {
        if (this.conditionedField) {
            this.enableSection();
        }
    }


    /**
     * To be used when conditioning an input field that
     * @param {string} fieldCode
     */
    @Input()
    set fieldCode(fieldCode: string) {
        if (fieldCode) {
            this.conditionedField = fieldCode;
            this.enableSection();
        }

    }

    private enableSection() {
        let fieldCode = this.conditionedField;
        //Only hide the field if it's listed among the restricted fields and the matter type is not among the options

        let matterTypeCode = currentMatter.value.matterType ? currentMatter.value.matterType.charAt(0) : '';
        let purchaseTypeCode = currentMatter.value.isPurchase ? 'p' : matterTypeCode;
        if(currentMatter.value.isCustomMatter()){
            matterTypeCode = 'c'; //Lower case 'c'
            purchaseTypeCode = 'P';
        }

        if (DpIfApplicableForMatter.fieldRestrictions[fieldCode] &&
            (DpIfApplicableForMatter.fieldRestrictions[fieldCode].indexOf(matterTypeCode) >= 0 || DpIfApplicableForMatter.fieldRestrictions[fieldCode].indexOf(purchaseTypeCode) >= 0))  {
            if (!this.viewCreated) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.viewCreated = true;
            }
        } else {
            this.viewContainer.clear();
            this.viewCreated = false;
        }
    }

    @Input()
    set dpIfApplicableForMatter(fieldCode: string) {
        if (fieldCode) {
            this.conditionedField = fieldCode;
            this.enableSection();
        }
    }

    /*
     To differentiate between Custom and Purchase matters
     Use P (big alphabet P - which is currently default for Purchase matter) to denote it is applicable to Custom and Normal Purchase matter.
     Use p (small alphabet p) to denote it is only specific to Purchase matter and NOT Custom matter
     Use c (small alphabet c) to denote it is applicable to Custom matter only
     Examples:
        - Where you need to show for both Purchase and Custom matters only - 'key' : 'P'
        - Where you need to show for Purchase matters only - 'key' : 'p'
        - Where you need to show for Custom matters only - 'key' : 'c'
    */

    static fieldRestrictions: any = {
        'matter.matterOpening.requisitionDate': 'PS',
        'matter.matterOpening.requisitionSubmitted': 'PM',
        'matter.matterOpening.occupancyDate': 'P',
        'matter.matterOpening.dateOfAgrtPS': 'PS',
        'matter.matterOpening.realEstateAgent': 'P',
        'matter.matterOpening.agentEmail': 'P',
        'matter.matterOpening.transactionTitleInsured': 'PM',
        'matter.matterOpening.actingOnBehalfOf': 'M',
        'matter.matterOpening.vendorReLine': 'P',
        'matter.matterOpening.purchaserReLine': 'S',
        'matter.matterOpening.lenderReLine': 'M',
        'matter.mainClient.titleDetails': 'PM',
        'matter.mainClient.capacity': 'PM',
        'matter.mainClient.offeror': 'P',
        'matter.mainClient.clientRe': 'PS',
        'matter.mainClient.borrowerRe': 'M',
        'matter.mainClient.subjectPropertyResidence.P': 'P',
        'matter.mainClient.subjectPropertyResidence.S': 'S',
        'matter.mainClient.subjectPropertyResidence.M': 'M',
        'matter.mainClient.preClosingAddress': 'P',
        'matter.mainClient.uffi': 'SM',

        'matter.otherParty.subjectProperty': 'S',
        'matter.otherParty.purchasersCapacity': 'S',
        'matter.otherParty.directDeposit': 'PM',
        'matter.otherParty.directDeposit.label.P': 'P',
        'matter.otherParty.directDeposit.label.M': 'M',
        'matter.otherParty.titleDetails': 'S',
        'matter.otherParty.otherPartySearch': 'PSO',
        'matter.otherParty.solicitorTitle.p': 'p', //Purchase but NOT Custom matters
        'matter.otherParty.solicitorTitle.S': 'S',
        'matter.otherParty.solicitorTitle.M': 'M',
        'matter.otherParty.solicitorTitle.c': 'c', //Only for Custom matters
        'matter.otherParty.solicitorTitle.O': 'O',
        'matter.otherParty.capacity': 'S',

        'matter.propertyTeranet.purchaseIsOf.PS': 'PS',
        'matter.propertyTeranet.purchaseIsOf.M': 'M',
        'matter.propertyTeranet.propertyType': 'PM',
        //'matter.propertyTeranet.propertyTax'            : 'PS',
        'matter.subjectProperty.titleNumber.PM': 'PM',
        'matter.subjectProperty.titleNumber.S': 'S',
    };

    ngOnDestroy() {
    }

    ngAfterViewInit() {
    }
}
