import {DirectionReFundType} from '../matters/direction-re-funds/direction-re-funds';
import {AddressDropdowns} from './address-Form/drop-downs';
import {NotifierOptions} from 'angular-notifier';
import {CashOnClosingDateType, OverrideMatterStatusType} from '../matters/shared/matter-specific-type';

/**
 * Created by unasir01 on 7/17/2017.
 */
export const CustomTeraviewDocumentNameFields = [
    { path : 'matterRecordNumber' , label : 'Matter No'},
    {  path : 'documentType' ,label: 'Document Type'  , key : 'DOC_TYPE'},
    {  path : 'teraviewDocketIdentifier' ,label: 'Docket ID', key: 'TERANET_DOCKET'},
    {  path : 'clientReLine' ,label: "Client RE: Line"},
    {  path : 'otherPartyContactInfo.reline' ,label: "Other Party RE: Line", key : 'MORTGAGE'},
    {  path : 'matterProperties' ,label: 'Unit and Level' , key : 'UNIT_LEVEL'},
    {  path : 'matterPropertyWithCondo.address.addressLine1' ,label: 'Address Line 1'},
    {  path : 'matterPropertyWithCondo.address.addressLine2' ,label: 'Address Line 2'},
    {  path : 'fileNumber' ,label: 'File No'},
    {  path : 'lawClerkInitials' ,label: 'Law Clerk Initials'},
    {  path : 'solicitorInitials' ,label: 'Solicitors Initials'},
    {  path : 'matterCloseDate' ,label: 'Closing Date' , key : 'CLOSING_DATE'},
];

//This is used for UI label
export const ON_DEMAND_LABEL: string = 'On Demand';
export const ON_DEMAND_VALUE: string = 'ON_DEMAND';

export const IGNORE_FIELDS = new Map<string, string[]>();

export const AffidavitTobeSignedByOptionsValue = {
    CLIENT    : 'CLIENT',
    SOLICITOR : 'SOLICITOR'
}

export const FlaStatementType = {
    MATTER_PARTICIPANT_SPOUSE : 'MATTER_PARTICIPANT_SPOUSE',
    CONSENTED_SPOUSE: 'CONSENTED_SPOUSE',
    CONSENTED_SPOUSE_REGISTERED_IN_LTO: 'CONSENTED_SPOUSE_REGISTERED_IN_LTO',
    INTERSPOUSAL_AGREEMENT: 'INTERSPOUSAL_AGREEMENT',
    AUTHORIZED_BY_COURT: 'AUTHORIZED_BY_COURT',
    JUDGMENT_FOR_DAMAGES_BY_SPOUSE: 'JUDGMENT_FOR_DAMAGES_BY_SPOUSE',
    WE_ARE_NOT_SPOUSES                                      : 'WE_ARE_NOT_SPOUSES',
    NOT_MATRIMONIAL_HOME                                    : 'NOT_MATRIMONIAL_HOME',
    SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_SEPARATION_AGREEMENT : 'SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_SEPARATION_AGREEMENT',
    SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER      : 'SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER',
    DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME         :  'DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME',
    NOT_OCCUPIED_BY_ANY_SHAREHOLDER                         : 'NOT_OCCUPIED_BY_ANY_SHAREHOLDER',
    MARITAL_STATUS_NB                                       : 'MARITAL_STATUS_NB',
    OTHER                                                   : 'OTHER'
}

export const DuplicateType = {
    DUPLICATE_PINS : 'DUPLICATE_PINS',
    DUPLICATE_PLAN_BLOCK_LOTS: 'DUPLICATE_PLAN_BLOCK_LOTS',
    DUPLICATE_STREET_ADDRESSES: 'DUPLICATE_STREET_ADDRESSES',
    DUPLICATE_UNIT_LEVEL: 'DUPLICATE_UNIT_LEVEL'
}

export const Constants = {

    NO_RESULTS_FOUND      : 'No records available',
    ADD_NEW_RECORD        : '+ Add new record for ',
    ADD_NEW_RECORD_MORTGAGEE : '+ Add new record',
    ADD_NEW_RECORD_VENDOR : 'Add new Vendor to this Matter',
    REAL_ESTATE_AGENT     : 'Real Estate Agent',
    REAL_ESTATE_BROKER    : 'Real Estate Broker',
    SURVEYOR              : 'Surveyor',
    PURCHASER             : 'Purchaser',
    OFFEROR               : 'Offeror',
    VENDOR                : 'Vendor',
    MORTGAGE_BROKER       : 'Mortgage Broker',
    DISPLAYNANME          : 'displayName',
    ADD_NEW_RECORD_ATTENTION : 'Add new record',
    STATUS_CERTIFICATE_REQUEST : 'Status Certificate Request',
    MORTGAGE_DEPT         : 'Mortgage Department',
    MORTGAGE_DISCHARGE    : 'Mortgage Discharge',
    RESIDENCE_ASSOCIATION : 'Residents\' Associations',

    // DPPMP-19081: default to EST for UI time adjustment for date/time values from integration
    //              used only when server-side value is not available
    DEFAULT_SERVER_TIMEZONE_OFFSET : "-05:00"

};


export const FlaErrorValues = {
    fieldIndexId : 'clientFieldIndex',
    flaMatterParticipantSpouseFieldId  : 'matterParticipantSpouse_',
    flaSpouseNameFieldId : 'spouseName_',
    flaSpouseNotAllowedFieldKey :  '.matterParticipantSpouseNotAllowed',
    flaSpouseNameRequiredFieldKey :  '.spouseNameRequired',
    flaSpouseNotAllowedFieldKeyNB :  '.matterParticipantSpouseNotAllowedNB',
    flaSpouseNameRequiredFieldKeyNB :  '.spouseNameRequiredNB',
    spouseOwnerNotAllowedFieldKey :  '.matterParticipantSpouseNotAllowedSKMB',
    spouseOwnerNameRequiredFieldKey : '.spouseNameRequiredSKMB'
};

export const MainClientFlaErrorValues = {
    containerId : 'matterPurchaserContainer',
    fieldPrefix : 'matter.mainClient',
};

export const OtherSideFlaErrorValues = {
    containerId : 'matterOtherPartyContainer',
    fieldPrefix : 'matter.otherParty',
};


export const MainSideDeveloperFlaErrorValues = {
    containerId : 'matterMainSideDeveloperContainer',
    fieldPrefix : 'matter.mainSideDeveloper',
};

export class MatterParticipantClientTypes {
    public static readonly MAIN_CLIENT : MatterParticipantClientType = "MAIN_CLIENT";
    public static readonly OTHER_CLIENT : MatterParticipantClientType = "OTHER_CLIENT";
    public static readonly DEVELOPER : MatterParticipantClientType = "DEVELOPER";
}

export type MatterParticipantClientType = 'MAIN_CLIENT' | 'OTHER_CLIENT' | 'DEVELOPER' ;


export const SnapshotBurgerMenuActions = {

    DELETE                              : 'Delete',
    MOVE_DOWN                           : 'Move Down',
    MOVE_UP                             : 'Move Up',
    REVERT_TO_GLOBAL                    : 'Revert to Global',
    REPLACE_MATTER_WITH_SOURCE_CONTACT  : 'Update contact with most recent changes',
    ADD_SPOUSE                          : 'Add Spouse',
    SEND_PARTICIPANT_CIRF               : 'Send CIRF',
    SEND_PARTICIPANT_WELCOME_PACKAGE    : 'Send Welcome Package',
    REVIEW_PROCESS_INTAKE               : 'Review / Process CIRF Intake',
    CANCEL_REQUEST                      : 'Cancel CIRF Request',
    RESEND_CIRF_EMAIL                   : 'Re-send CIRF email',
    RESEND_CIRF_WELCOME_EMAIL           : 'Re-send Welcome Email',
    RE_OPEN_REQUEST                     : 'Re-Open CIRF Request',
    CIRF_INFO                           : 'CIRF Info',
    CONVERT_CONTACT                     : 'Convert to Full Contact'
};

export const CLEAR_FLAG_WITHOUT_UPDATING_MATTER_CONFIRMATION_MSG = "You will not be able to edit this contact's information on this matter. For future changes, please delete this contact and add them again with the most recent information.";
export const CLEAR_FLAG_WITHOUT_UPDATING_MATTER_CONFIRMATION_HEADING = 'Warning: Contact information out of sync';

export const ResendLoginEmailSuccessValues = {

    RESENT                              : '1st Login Email has been resent',
    NotResentAsLoggedIn                 : '1st Login Email not resent as user has logged in',
};

export const BurgerMenuPropertyTeranetParcel = {

    DELETE                              : 'Delete',
    EDIT                                :  'Edit'
};

export type MatterTopicKey = 'OVERVIEW' | 'MATTER_OPENING' | 'PURCHASER' | 'VENDOR' |'MORTGAGOR' | 'PURCHASER_SOLICITOR' | 'VENDOR_SOLICITOR' |
 'OTHER_SOLICITOR' | 'PROPERTY' | 'CONSIDERATION_LTT' | 'COMPLIANCE' | 'EXISTING_MORTGAGE' | 'MORTGAGES_MORTGAGEE' | 'MORTGAGES_TERMS' |
 'MORTGAGES_REPORT' | 'REAL_ESTATE_BROKER' | 'CONDO_CORPORATION' | 'FIRE_INSURANCE'| 'REQUISITIONS' | 'UNDERTAKINGS'| 'DIRECTION_RE_FUNDS' |
 'STATEMENT_OF_ADJUSTMENT' | 'STATEMENT_OF_ACCOUNT' | 'TRUST_LEDGER'  | 'REPORT_TO_PURCHASER' | 'EXECUTION_AFFIDAVITS' | 'SUPPLEMENTAL_TASKS'
    | 'FORMS' | 'MATTER_DOCUMENTS' | 'DOCUMENT_PRODUCTION' | 'TITLE_INSURANCE' | 'NOTES' | 'ASSOCIATED_FILES'
    | 'FAMILY' | 'ASSETS' | 'CAPACITY_ASSESSMENT' | 'PARTIES_ROLES' | 'RESIDUE_TRUSTS' | 'VISUAL_DISTRIBUTION' | 'ESTATE_PLAN' | 'CLIENT_HOMEWORK'
    | 'MATTER_CLOSING' | 'FORM_EDITOR' | 'CLIENT_INFO';

export type ProjectTopicKey = 'PROJECT_DETAILS' | 'CONDO' | 'ADJUSTMENTS' | 'DOCUMENTS_AND_REGISTRATION' |
    'DEPOSIT_MANAGER' | 'PROJECT_DOCUMENTS' | 'STATEMENT_OF_ADJUSTMENT' | 'STATEMENT_OF_ACCOUNT' | 'FORM_4_DEPOSITS' | 'NOTES';

export const PurchaseTopicDropdowns = [
    { label: '', value: '' },
    { label: 'Matter Opening', value: 'MATTER_OPENING' },
    { label: 'Purchasers', value: 'PURCHASER' },
    { label: 'Vendor & Solicitor', value: 'VENDOR_SOLICITOR' },
    { label: 'Property/Teranet', value: 'PROPERTY' },
    { label: 'Consideration &LTT', value: 'CONSIDERATION_LTT' },
    { label: 'Compliance', value: 'COMPLIANCE' },
    { label: 'Mortgages - Mortgagee', value: 'MORTGAGES_MORTGAGEE' },
    { label: 'Mortgages - Terms', value: 'MORTGAGES_TERMS' },
    { label: 'Mortgages - Report', value: 'MORTGAGES_REPORT' },
    { label: 'Condo Corporation', value: 'CONDO_CORPORATION' },
    { label: 'Fire Insurance', value: 'FIRE_INSURANCE' },
    { label: 'Requisitions', value: 'REQUISITIONS' },
    { label: 'Undertakings Received', value: 'UNDERTAKINGS' },
    { label: 'Statement of Adjustments', value: 'STATEMENT_OF_ADJUSTMENT' },
    { label: 'Statement of Account', value: 'STATEMENT_OF_ACCOUNT' },
    { label: 'Trust Ledger', value: 'TRUST_LEDGER' },
    { label: 'Report to Purchaser', value: 'REPORT_TO_PURCHASER' },
    { label: 'Executions Affidavits', value: 'EXECUTION_AFFIDAVITS' }
];

export const PurchaseMatterTopics={
    TAB_A_TOPIC : 'Matter Opening',
    TAB_B_TOPIC : 'Purchasers',
    TAB_C_TOPIC : 'Vendors & Solicitor',
    TAB_L_TOPIC : 'Undertakings Received'
};

export const SaleMatterTopics={
    TAB_A_TOPIC : 'Matter Opening',
    TAB_B_TOPIC : 'Vendors',
    TAB_C_TOPIC : 'Purchasers & Solicitor',
    TAB_L_TOPIC : 'Undertakings Given'
};

export const MortgageMatterTopics={
    TAB_A_TOPIC : 'Matter Opening',
    TAB_B_TOPIC : 'Mortgagors',
    TAB_C_TOPIC : 'Other Solicitor',
    TAB_L_TOPIC : 'Undertakings'
};

export const OpportunityMatterTopics={
    TAB_A_TOPIC : 'Opportunity Detail',

};

export const SaleTopicDropdowns = [
    { label: '', value: '' },
    { label: 'Matter Opening', value: 'MATTER_OPENING' },
    { label: 'Vendors', value: 'VENDOR' },
    { label: 'Purchasers & Solicitor', value: 'PURCHASER_SOLICITOR' },
    { label: 'Property/Teranet', value: 'PROPERTY' },
    { label: 'Compliance', value: 'COMPLIANCE' },
    { label: 'Existing Mortgage', value: 'EXISTING_MORTGAGE' },
    { label: 'VTB Mortgage(s) - Morgagee', value: 'MORTGAGES_MORTGAGEE' },
    { label: 'VTB Mortgage(s) - Terms', value: 'MORTGAGES_TERMS' },
    { label: 'VTB Mortgage(s) - Report', value: 'MORTGAGES_REPORT' },
    { label: 'Real Estate Broker / Commission', value: 'REAL_ESTATE_BROKER' },
    { label: 'Condo Corporation', value: 'CONDO_CORPORATION' },
    { label: "Purchaser's Fire Insurance", value: 'FIRE_INSURANCE' },
    { label: 'Undertakings Given', value: 'UNDERTAKINGS' },
    { label: 'Statement of Adjustments', value: 'STATEMENT_OF_ADJUSTMENT' },
    { label: 'Direction Re Funds', value: 'DIRECTION_RE_FUNDS' },
    { label: 'Statement of Account', value: 'STATEMENT_OF_ACCOUNT' },
    { label: 'Trust Ledger', value: 'TRUST_LEDGER' },
    { label: 'Response to Requisitions', value: 'REQUISITIONS' },
    { label: 'Executions Affidavits', value: 'EXECUTION_AFFIDAVITS' }
];
export const MortgageTopicDropdowns = [
    { label: '', value: '' },
    { label: 'Matter Opening', value: 'MATTER_OPENING' },
    { label: 'Mortgagors', value: 'MORTGAGOR' },
    { label: 'Other Solicitor', value: 'OTHER_SOLICITOR' },
    { label: 'Property/Teranet', value: 'PROPERTY' },
    { label: 'Compliance', value: 'COMPLIANCE' },
    { label: 'Existing Mortgage', value: 'EXISTING_MORTGAGE' },
    { label: 'New Mortgage - Mortgagee', value: 'MORTGAGES_MORTGAGEE' },
    { label: 'New Mortgage - Terms', value: 'MORTGAGES_TERMS' },
    { label: 'New Mortgages - Report', value: 'MORTGAGES_REPORT' },
    { label: 'Condo Corporation', value: 'CONDO_CORPORATION' },
    { label: 'Fire Insurance', value: 'FIRE_INSURANCE' },
    { label: 'Requisitions', value: 'REQUISITIONS' },
    { label: 'Undertakings Given or Received', value: 'UNDERTAKINGS' },
    { label: 'Statement of Account', value: 'STATEMENT_OF_ACCOUNT' },
    { label: 'Trust Ledger Statement', value: 'TRUST_LEDGER' },
    { label: 'Executions Affidavits', value: 'EXECUTION_AFFIDAVITS' }
];

export const DischargeTopicDropdowns = [
    { label: '', value: '' },
    { label: 'Matter Opening', value: 'MATTER_OPENING' },
    { label: 'Mortgagors', value: 'MORTGAGOR' },
    { label: 'Property/Teranet', value: 'PROPERTY' },
    { label: 'Existing Mortgage', value: 'EXISTING_MORTGAGE' },
    { label: 'Undertakings Given or Received', value: 'UNDERTAKINGS' },
    { label: 'Statement of Account', value: 'STATEMENT_OF_ACCOUNT' },
    { label: 'Trust Ledger Statement', value: 'TRUST_LEDGER' },

];
export const CustomMatterTopicDropdowns = [
    { label: '', value: '' },
    { label: 'Matter Opening', value: 'MATTER_OPENING' },
    { label: 'My Clients', value: 'PURCHASER' },
    { label: 'Other Side', value: 'VENDOR_SOLICITOR' },
    { label: 'Statement of Account', value: 'STATEMENT_OF_ACCOUNT' }
];

export const WillTopicDropdowns = [
    { label: '', value: '' },
    { label: 'Matter Opening', value: 'MATTER_OPENING' },
    { label: 'Client Info', value: 'CLIENT_INFO' },
    { label: 'Parties & Roles - Executors & Trustees', value: 'EXECUTORS' },
    { label: 'Parties & Roles - Children', value: 'CHILDREN' },
    { label: 'Parties & Roles - Other Parties', value: 'PARTIES_OTHER' },
    { label: 'Assets, Debts & Gifts - Personal Belongings', value: 'PERSONAL_BELONGINGS' },
    { label: 'Assets, Debts & Gifts - Jointly Held', value: 'JOINTLY_HELD' },
    { label: 'Assets, Debts & Gifts - Properties', value: 'ASSET_PROPERTIES' },
    { label: 'Assets, Debts & Gifts - Specific Assets', value: 'SPECIFIC_ASSETS' },
    { label: 'Assets, Debts & Gifts - Specific Legacy', value: 'SPECIFIC_LEGACY' },
    { label: 'Assets, Debts & Gifts - Designated Beneficiaries', value: 'DESIGNATED_BENEFICIARIES' },
    { label: 'Assets, Debts & Gifts - Loans', value: 'LOANS' },
    { label: 'Residue and Trusts - Distribution', value: 'RESIDUE_TRUSTS' },
    { label: 'Residue and Trusts - Common Disaster', value: 'COMMON_DISASTER' },
    { label: 'Statement of Account', value: 'STATEMENT_OF_ACCOUNT' }
];

export const MORTGAGE_PAYMENT_FREQUENCY = {
    MONTHLY : {
        numberOfPaymentsPerYear     : 12,
        numberOfPaymentsForSchedule : 12, // Monthly
        factor                      : 0
    },


    BI_WEEKLY_ACCELERATED : {

        numberOfPaymentsPerYear     : 12, // Bi-Weekly Accelerated (26 pmts) set to monthly then take 1/2
        numberOfPaymentsForSchedule : 26,
        factor                      : .5
    },

    BI_WEEKLY_REGULAR : {
        numberOfPaymentsPerYear     : 26,  // Bi-Weekly Regular (26 pmts)
        numberOfPaymentsForSchedule : 26,
        factor                      : 0
    },

    BI_WEEKLY : {
        numberOfPaymentsPerYear     : 26,  // Bi-Weekly Regular (26 pmts)
        numberOfPaymentsForSchedule : 26,
        factor                      : 0
    },

    WEEKLY_ACCELERATED : {
        numberOfPaymentsPerYear     : 12,  // Weekly Accelerated (52 pmts) set to monthly then take 1/4
        numberOfPaymentsForSchedule : 52,
        factor                      : .25
    },


    WEEKLY_REGULAR : {
        numberOfPaymentsPerYear     : 52,  // Weekly Regular (52 pmts)
        numberOfPaymentsForSchedule : 52,
        factor                      : 0
    },

    WEEKLY : {
        numberOfPaymentsPerYear     : 52,  // Weekly Regular (52 pmts)
        numberOfPaymentsForSchedule : 52,
        factor                      : 0
    },

    QUARTERLY : {
        numberOfPaymentsPerYear     : 4,  // Quarterly
        numberOfPaymentsForSchedule : 4,
        factor                      : 0
    },

    SEMI_MONTHLY : {
        numberOfPaymentsPerYear     : 12,  // Quarterly
        numberOfPaymentsForSchedule : 24,
        factor                      : .5
    }

};

export type CategoryFieldType = 'NAME_ADDRESS' | 'ACCOUNT_NUMBER' | 'COMMENCEMENT_DATE' |'DETAILS' | 'FEE_AMOUNT'
    | 'NOTES_1' |'NOTES_2' |'NOTES_3' |'TICKLER_DATE' |'TICKLER_NOTES' |
    'HAS_TASK_COMPLETED' | 'DATE_TASK_COMPLETED' | 'TICKLER_PER' | 'TASK_COMPLETION_NOTES' |
    'USER_DEFINED_FIELD' | 'TICKLER_GROUP' | 'DIRECTION_REFUNDS_BALANCE_DUE' | 'DIRECTION_REFUNDS_BALANCE_PAYABLE';

export  type  CategoryFieldDataType = 'DERIVED' | 'YES_NO' |'NO_YES' | 'TEXT' | 'DATE' | 'AMOUNT' | 'NUMBER' |
    'CONTACT' | 'TICKER_INFO' | 'COMPOSITE' | 'N_A';

export type  SourceTab = 'MATTER_SUPPLEMENTAL_TASK' | 'DOCUMENT_PRODUCTION';

export type SupplementalTaskAction = 'New' | 'Copy' | 'Documents' | 'Edit';


// GuarantorLastNameFieldCodes for 8 Mortgages and all 8 mortgages contains 3 guarantors field codes
// Added only 3 codes (dummy) for Testing Purpose

export  const GuarantorLastNameFieldCodes = [
    ['M40001','M40002','M40003'] ,['M40011','M40012','M40013'],['M40021','M40022','M40023'] ,  ['M40031','M40032','M40033'] ,['M40041','M40042','M40043'],['M40051','M40052','M40053'] ,
    ['M40061','M40062','M40063'],['M40061','M40062','M40063']
]

// OffersGenderChangeFieldCodes for contains 3 offerors gender change field codes
// Added only 3 codes (dummy) for Testing Purpose
export  const OffersGenderChangeFieldCodes = [
    'P20002','P20012','P20022']

export const booleanYesNoDropDowns = {
    BooleanYesNo : [
        {value: true, label: 'Yes'},
        {value: false, label: 'No'}
    ],
    YesNoOnly: [
        {label: 'Yes', value: 'YES' },
        {label : 'No', value : 'NO'}
    ],
    YesNo_DefaultYes : [
        {label : 'Y/n', value : 'Y_n'},
        {label : 'Yes', value : 'YES'},
        {label : 'No', value : 'NO'}
    ],

    NoYes_DefaultNo : [
        {label : 'N/y', value : 'N_y'},
        {label : 'Yes', value : 'YES'},
        {label : 'No', value : 'NO'}
    ],

    YesNo_DefaultEmpty:[
        {label : '', value : '???'},
        {label: 'Yes', value: 'YES' },
        {label: 'No', value: 'NO' }
    ],

    YesNo_DefaultBlank:[
        {label : '', value : ''},
        {label: 'Yes', value: 'YES' },
        {label: 'No', value: 'NO' }
    ],

    YesNoNA_DefaultBlank:[
        {label : '', value : ''},
        {label: 'Yes', value: 'YES' },
        {label: 'No', value: 'NO' },
        {label: 'N/A', value: 'N/A' }
    ],
    BeforeAfter_DefaultBefore : [
        {label : 'Before', value : 'BEFORE'},
        {label : 'After', value : 'AFTER'}
    ],
    ClosingOccupancy_DefaultClosing : [
        {label : 'Closing Date', value : 'CLOSING_DATE'},
        {label : 'Occupancy Date', value : 'OCCUPANCY_DATE'}
    ],

};

export const OnAccessibleWarningLevelDefaultValue = 200;
export const WarningLevelDefaultValue = 100;

export const warningLevelOptions = [30, 100, 200, 300, 500];
export const transactionCreditsOptions = [1000, 2500, 5000, 10000];


export const ERegType = {

    EREG_TRANSFER    : 'E_REG_TRANSFER',
    EREG_MORTGAGE : 'E_REG_MORTGAGE',
    EREG_DISCHARGE : 'E_REG_DISCHARGE',
}

export const MortgageAction = {
    ADD : 'ADD',
    DELETE : 'DELETE',
    CHANGE_PRIORITY : 'CHANGE_PRIORITY'
}


export const ERegStatus = {
    EREG_INCOMPLETE    : 'INCOMPLETE',
    EREG_CREATED    : 'COMPLETED',
    EREG_READY : 'GENERATED',
    EREG_UPLOADED : 'UPLOADED',
};

export const ALTOEFormStatus = {
    EFORM_INCOMPLETE    : 'INCOMPLETE',
    EFORM_READY : 'READY',
    EFORM_CREATED    : 'COMPLETED',
    EFORM_SENT : 'SENT',
};

export const TPRFormStatus = {
    EFORM_INCOMPLETE    : 'INCOMPLETE',
    EFORM_READY : 'READY',
    EFORM_CREATED    : 'COMPLETED',
    EFORM_SENT : 'SENT',
};

export const KeyCode ={
    Backspace : 8,
    Tab : 9,
    Enter : 13,
    Esc : 27,
    Space : 32,

    UpArrow : 38,
    DownArrow : 40

}

export const PhoneMortgageeSourceType = {
    SAME_AS_MORTGAGEE    : 'SAME_AS_MORTGAGEE',
    MANUALLY_ENTERED     : 'MANUALLY_ENTERED',
}

export const PhoneCondoSourceType = {
    SAME_AS_CONDO_CORPORATION    : 'SAME_AS_CONDO_CORPORATION',
    MANUALLY_ENTERED             : 'MANUALLY_ENTERED',
}

export const PhoneManagementSourceType = {
    SAME_AS_MANAGEMENT_COMPANY    : 'SAME_AS_MANAGEMENT_COMPANY',
    MANUALLY_ENTERED              : 'MANUALLY_ENTERED',
}

export const PhoneMortgageBrokerSourceType = {
    SAME_AS_MORTGAGE_BROKER       : 'SAME_AS_MORTGAGE_BROKER',
    MANUALLY_ENTERED              : 'MANUALLY_ENTERED',
}

export const PhoneResidenceAssociationSourceType = {
    SAME_AS_RESIDENCE_ASSOCIATION    : 'SAME_AS_RESIDENCE_ASSOCIATION',
    MANUALLY_ENTERED                 : 'MANUALLY_ENTERED',
}

export const PhoneSurveyorSourceType = {
    SAME_AS_SURVEYOR    : 'SAME_AS_SURVEYOR',
    MANUALLY_ENTERED    : 'MANUALLY_ENTERED',
}

export const EmailMortgageeSourceType = {
    SAME_AS_MORTGAGEE    : 'SAME_AS_MORTGAGEE',
    MANUALLY_ENTERED     : 'MANUALLY_ENTERED',
}

export const EmailCondoSourceType = {
    SAME_AS_CONDO_CORPORATION    : 'SAME_AS_CONDO_CORPORATION',
    MANUALLY_ENTERED             : 'MANUALLY_ENTERED',
}

export const EmailManagementSourceType = {
    SAME_AS_MANAGEMENT_COMPANY    : 'SAME_AS_MANAGEMENT_COMPANY',
    MANUALLY_ENTERED              : 'MANUALLY_ENTERED',
}

export const EmailMortgageBrokerSourceType = {
    SAME_AS_MORTGAGE_BROKER      : 'SAME_AS_MORTGAGE_BROKER',
    MANUALLY_ENTERED             : 'MANUALLY_ENTERED',
}

export const EmailResidenceAssociationSourceType = {
    SAME_AS_RESIDENCE_ASSOCIATION    : 'SAME_AS_RESIDENCE_ASSOCIATION',
    MANUALLY_ENTERED                 : 'MANUALLY_ENTERED',
}

export const EmailSurveyorSourceType = {
    SAME_AS_SURVEYOR    : 'SAME_AS_SURVEYOR',
    MANUALLY_ENTERED    : 'MANUALLY_ENTERED',
}

export const KeyCodesActiveForTable = [KeyCode.DownArrow, KeyCode.UpArrow, KeyCode.Enter];


export const UnityBillingTransactionTypes = {
    SEARCH_COST    : [{type : 'PURCHASE_PARCEL_REGISTER'}, {type : 'COURIER_INSTRUMENT'}, {type : 'ORDER_MAP'}, {type : 'ORDER_INSTRUMENT'}],
    EXECUTION_COST : [{type : 'ORDER_WRIT_BY_NUMBER'}, {type : 'ORDER_WRIT_BY_NAME'}, {type : 'ORDER_WRIT_EXECUTION'}, {type : 'REDO_WRIT_SEARCH'}]
}

export const MortgageDispositionType = {
    DISCHARGED          : 'DISCHARGED',
    ASSUMED             : 'ASSUMED',
    BRIDGE_FINANCING    : 'BRIDGE_FINANCING',
    REMAIN              : 'REMAIN',
    BLANK               : 'QUESTION'
}

export const dischargeRegisteredByType = {
    MORTGAGEE : 'MORTGAGEE',
    OUR_FIRM : 'OUR_FIRM',
    BUYER_LAWYER_NOTARY : 'BUYER_LAWYER_NOTARY'
}

export const DirectionReFundTypes = {
    BALANCE_OF_FUNDS_PAYABLE_TO : 'BALANCE_OF_FUNDS_PAYABLE_TO' as DirectionReFundType,
    FUNDS_PAYABLE_TO            : 'FUNDS_PAYABLE_TO' as DirectionReFundType
}

export const DirectionReFundBalanceBurgerMenuTypes = {
    DELETE              : 'DELETE',
    LAW_FIRM            : 'LAW_FIRM',
    VENDOR              : 'VENDOR'
}

export const DirectionReFundsBurgerMenuTypes = {
    ADD                               : 'ADD',
    DELETE                            : 'DELETE',
    LAW_FIRM                          : 'LAW_FIRM',
    VENDOR                            : 'VENDOR',
    EXISTING_MORTGAGE                 : 'EXISTING_MORTGAGE',
    CHEQUE_PAYABLE_TO                 : 'CHEQUE_PAYABLE_TO',
    TOTAL_FEE                         : 'TOTAL_FEE',
    VENDOR_COMMISSION_PAYABLE         : 'VENDOR_COMMISSION_PAYABLE',
    PURCHASER_COMMISSION_PAYABLE      : 'PURCHASER_COMMISSION_PAYABLE',
    TARION                            : 'TARION',
    SALE_PRICE_ADJUSTMENT             : 'SALE_PRICE_ADJUSTMENT',
    INTERIM_OCCUPANCY_FEES            : 'INTERIM_OCCUPANCY_FEES',
    INTERIM_OCCUPANCY_FEES_AND_TARION : 'INTERIM_OCCUPANCY_FEES_AND_TARION',
    RESERVE_FUND                      : 'RESERVE_FUND',
    PRORATE_AMOUNT                    : 'PRORATE_AMOUNT',
    LATE_CLOSING_INTEREST             : 'Late Closing Interest',
    OTHER_SUPPLEMENTAL_TASK           : 'Other Extended Workflows',
    TAX_DEPARTMENT                    : 'Tax Department'
};

export const MaxfundsPayableToItems = 20;
export const RevertToGlobalMessage =     'Proceed to Revert to Global. '
                                       + 'Please note that this will revert all the contact information in this record to our global information and formatting. '
                                       + 'You will no longer have any private information or formatting changes available. '
                                       + 'You will, however, benefit from future global contact information updates for this record.'
export const BrokerCommissionPageLabels = {
    Balance_Of_Commission_Owing : 'Balance of Commission owing',
    Excess_Deposit_Refundable_To_Vendor : 'Excess deposit refundable to Vendor',
    Commission_Payable_To_Vendor_Broker : 'Commission payable to Vendor\'s broker',
    Commission_Payable_To_Purchaser_Broker : 'Commission payable to Purchaser\'s broker'
}

export const SolicitorReportAddressDdString = [AddressDropdowns.sameAsSolicitor, AddressDropdowns.sameAsService, AddressDropdowns.manuallyEntered];
export const SolicitorServiceAddressDdString = [AddressDropdowns.sameAsSolicitor, AddressDropdowns.manuallyEntered];
export const SolicitorAddressDdString = [AddressDropdowns.sameAsFirm, AddressDropdowns.manuallyEntered];
export const ReportAddressDdString = [AddressDropdowns.sameAsMailing, AddressDropdowns.sameAsService, AddressDropdowns.manuallyEntered];
export const ServiceAddressDdString = [AddressDropdowns.sameAsMailing, AddressDropdowns.manuallyEntered];

export const DefaultMatterListFilterName = {
    ALL_MATTERS : 'All Matters',
    MY_RECENT : 'My Recent' ,
}

export const DefaultOpportunityListFilterName = {
    ALL_OPPORTUNITIES : 'All Opportunities',
    MY_RECENT : 'My Recent' ,
}

export const EXCEL_TAB_STOP_CLASS_NAME = 'excel-tab-stop';

export const TitleInsuranceTitle = {
    FCT : 'FCT Title Insurance',
}

export const ThirdPartyDocumentStatus = {
    NEW: 'NEW',
    DOWNLOADED: 'DOWNLOADED',
    UPDATED: 'UPDATED'
};

export enum ThirdPartyProvider {
    CHICAGO_TITLE,
    STEWART_TITLE,
    FCT,
    STEWART_ASSYST
}

export enum ThirdPartyFeature {
    TITLE_INSURANCE,
    ELECTRONIC_MORTGAGE
}

export type ThemeStyle = 'dp-white' | 'dp-ivory' | 'dp-mist' | 'dp-light-grey' | 'dp-light-sky' | 'dp-light-violet' | 'dp-pale-rose';

export const DPThemes = [
    { name: 'Default',  class: 'dp-light-grey' },
    { name: 'White',  class: 'dp-white' },
    { name: 'Pale Pink',  class: 'dp-pale-rose' },
    { name: 'Pale Sky',  class: 'dp-light-sky' },
    { name: 'Pale Plum',  class: 'dp-light-violet' },
    { name: 'Pale Teal',  class: 'dp-ivory' },
];

export type ColorStyle = 'dp-black' | 'dp-grey' | 'dp-red' | 'dp-green' | 'dp-blue' | 'dp-teal';

export const FontColors = [
    { name: 'Default',  class: 'dp-grey' },
    { name: 'Black',  class: 'dp-black' },
    { name: 'Primary',  class: 'dp-red' },
    { name: 'Sky Blue',  class: 'dp-blue' },
    { name: 'Plum',  class: 'dp-green' },  //Can't change the name because it's saved in the DB
    { name: 'Teal',  class: 'dp-teal' },

];

export const DpBold = 'dp-bold';

export class CondoManagedTypeConstValue {
    public static readonly QUESTION : CondoManagedType = "QUESTION";
    public static readonly SELF_MANAGED : CondoManagedType = "SELF_MANAGED";
    public static readonly MANAGEMENT_COMPANY : CondoManagedType = "MANAGEMENT_COMPANY";
}

export const LawSocietyMemberDirectoryLinks = {
    'ON' : 'https://lso.ca/public-resources/finding-a-lawyer-or-paralegal/lawyer-and-paralegal-directory',
    'AB': 'https://lsa.memberpro.net/main/body.cfm',
    'SK': 'https://lss.alinityapp.com/WebClient/registrantdirectory.aspx',
    'MB': 'http://www.lawsociety.mb.ca/for-the-public/lawyer-lookup/',
    'NB': 'https://secure.lawsociety-barreau.nb.ca/memberdirectory/Search.aspx',
    'NS': 'https://members.nsbs.org/LawyerSearch',
    'BC': 'https://www.lawsociety.bc.ca/lsbc/apps/lkup/mbr-search.cfm'
}

export const NOTARY_SOCIETY_MEMBER_DIRECTORY_LINK = 'https://www.bcnotaryassociation.ca/find/';

export type CondoManagedType = 'QUESTION' | 'SELF_MANAGED' | 'MANAGEMENT_COMPANY';

export const DEFAULT_POSSESSION_TIME = '12:00 p.m.';

export const ActingForValues = {
    QUESTION: 'QUESTION',
    MORTGAGOR: 'MORTGAGOR',
    MORTGAGEE: 'MORTGAGEE',
    BOTH_MORTGAGOR_PRIMARY: 'BOTH_MORTGAGOR_PRIMARY',
    BOTH_MORTGAGEE_PRIMARY: 'BOTH_MORTGAGEE_PRIMARY',
    PURCHASER_ONLY: 'PURCHASER_ONLY',
    PURCHASER_VENDOR: 'PURCHASER_VENDOR',
    VENDOR_ONLY: 'VENDOR_ONLY',
    VENDOR_PURCHASER: 'VENDOR_PURCHASER',
    VENDOR_MORTGAGEE: 'VENDOR_MORTGAGEE',
    VENDOR_PURCHASER_MORTGAGEE: 'VENDOR_PURCHASER_MORTGAGEE'
}

export class CashOnClosingDateValues {
    public static readonly NIL : CashOnClosingDateType = "NIL";
    public static readonly CASH_TO_CLOSE : CashOnClosingDateType = "CASH_TO_CLOSE";
    public static readonly CASH_DIFFERENCE : CashOnClosingDateType = "CASH_DIFFERENCE";
    public static readonly BALANCE_TO_CLOSE : CashOnClosingDateType = "BALANCE_TO_CLOSE";
    public static readonly BALANCE_OF_DOWN_PAYMENT : CashOnClosingDateType = "BALANCE_OF_DOWN_PAYMENT";
}

export const CHICAGO_TITLE_APPLICABLE_PROVINCES = ['ON', 'AB', 'NS', 'NB', 'BC']; //TODO : exclude the province code(SK,MB), if it has no Chicago Title Functionality.

export const ParcelTitleNoOfIndividualShares = "0.000000";

export const PROJECT_SALE_MATTER = "Project Sale";

export const PROJECT_SALE_MATTER_OPTION_VALUE = "PROJECT SALE";

export const PROJECT_SALE_MATTER_TYPE = "PROJECT_SALE";

export enum OCCUPANCY_FEES_CALCULATED_BASED_ON {
    deferredPurchaseMonies = 'PURCHASE_MONIES',
    phantomMortgage = 'PHANTOM_MORTGAGE'
};

export const staticFieldCodes = {
    salePriceAdjustment : 5,
    depositAdjustment : 6
};

export const OUT_OF_SYNC_PRE_MSG = "This contact's information has been updated and cannot be edited. ";
export const OUT_OF_SYNC_UNDERLINE_MSG = "View current contact details here";
export const OUT_OF_SYNC_POSTFIX_MSG = " and refer to the burger menu for options.";

export const DEPOSIT_PAID_ON_OCCUPANCY = 'Deposit paid on occupancy';

export const MATTER_RECORD_NUMBER_MAX_LENGTH = 25;

export const FILE_NO_MAX_LENGTH = 50;

export const MAX_NUM_OF_UNIT = 6;

export type OpenDepositAction = "ADD_NEW" | "EDIT" | "DELETE" | "MARK_FORM_4_SENT" | "PRINT_FORM_4";

export const ZERO_INTEREST = '0.00000';

export const ProjectAdjustmentImport = {
    DEPOSIT_ADJUSTMENT_INFORMATION : 'DEPOSIT_ADJUSTMENT_INFORMATION',
    SALE_PRICE_ADJUSTMENT_INFORMATION : 'SALE_PRICE_ADJUSTMENT_INFORMATION'
}

export const InterimTaxMultiplier: number = 2;

export class YesNoTypes {
    public static readonly YES : YesNoType = "YES";
    public static readonly NO : YesNoType = "NO";
}
export type YesNoType = 'YES' | 'NO';

export const PERCENT_OF_OPTIONS = {
    salePrice : 'SALE_PRICE',
    adjSalePrice : 'ADJ_SALE_PRICE',
    deposit : 'DEPOSIT',
    interestOnDeposits : 'INTEREST_ON_DEPOSITS',
    interestOnDepositInterest : 'INTEREST_ON_DEPOSIT_INTEREST'
}

export const customNotifierOptions: NotifierOptions = {
    position: {
        horizontal: {
            position: 'left',
            distance: 0
        },
        vertical: {
            position: 'bottom',
            distance: 50,
            gap: 10
        }
    },
    behaviour: {
        autoHide: 3000,
        onClick: false,
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
    },
    animations: {
        enabled: true,
        show: {
            preset: 'slide',
            speed: 500,
            easing: 'ease'
        },
        hide: {
            preset: 'fade',
            speed: 300,
            easing: 'ease',
            offset: 50
        },
        shift: {
            speed: 300,
            easing: 'ease'
        },
        overlap: 150
    }

}


export const templateCodes = {
    manualAmountCodes : [
        {label: '10112 : Deposit amount', value: '10112'},
        {label: '10153 : Sale price, including GST/HST', value: '10153'},
        {label: '10160:  Chattels comprising part of GST/HST-inclusive sale price', value: '10160'},
        {label: '10154 : Additional Consideration, including GST/HST', value: '10154'},
        {label: '10155 : Credit to purhcaser, including GST/HST', value: '10155'},
        {label: '10148 : Reduction to GST/HST', value: '10148'},
        {label: '10152 : Buydowns, etc.', value: '10152'},
        {label: '10212 : Sum of adjustments flagged as additional consideration to Vendor (not eligible for GST/HST Rebate)', value: '10212'}
    ],
    derivedAmountCodes : [
        {label: '10156 : (Sale price, including GST/HST) + (Additional Consideration, incl. GST/HST) - (Credits to Purchaser)', value: '10156'},
        {label: '10157 : Net or GST/HST-exclusive Sale Price (after adjusting sale price for Credits to P & V and Buydown Amt)', value: '10157'},
        {label: '10169 : Net or GST/HST-exclusive Sale Price (ignoring Credits to P & V and Buydown Amt)', value: '10169'},
        {label: '10206 : amount of sale price as it appears in Section B of the GST/HST Rebate Form', value: '10206'},
        {label: '10215 : Sale Price including GST/HST + sum of adjustments flagged as add\'l consid. To Vendor (not eligible for GST/HST Rebate)', value: '10215'},
        {label: '10259 :  Sum of 10215 + sum of all "Itemized Credits', value: '10259'},
        {label: '10162 : GST/HST on Sale Price', value: '10162'},
        {label: '10165 : (GST/HST on Sale Price) - (GST/HST Rebate)', value: '10165'},
        {label: '10158 : GST/HST Rebate', value: '10158'},
        {label: '10159 : (Net or GST/HST-exclusive Sale Price as per #10157) + (GST/HST Rebate)', value: '10159'},
        {label: '10166 : (Net or GST/HST-exclusive Sale Price as per #10157) + (GST/HST Rebate) - (Value of Chattels)', value: '10166'},
        {label: '10167 : Net or GST/HST-exclusive Sale Price (after adjusting sale price for Credits to P & V and Buydown Amt)', value: '10167'},
        {label: '10221 : HST Federal Portion', value: '10221'},
        {label: '10222 : HST Ontario Portion', value: '10222'},
        {label: '10223 : HST Rebate Federal Portion', value: '10223'},
        {label: '10224 : HST Rebate Ontario Portion', value: '10224'},
        {label: '10225 : RST Transitional Rebate', value: '10225'},
        {label: '10226 : HST Rebate Federal Portion + HST Rebate Ontario Portion', value: '10226'}
    ],
    interimOccupanyAdjCodes : [
        {label: '10177 : Principal amount of mortgage (as set out in the Interim Occupancy Fee adjustment)', value: '10177'},
        {label: '10180 : Component on Interim Occupancy Fee attributable to Mortgage Interest', value: '10180'},
        {label: '10181 : Component on Interim Occupancy Fee attributable to Common Expenses', value: '10181'},
        {label: '10182 : Component on Interim Occupancy Fee attributable to Taxes', value: '10182'},
        {label: '10183 : Total Monthly Occupancy Fee (the sum of #10180 + #10181 + #10182)', value: '10183'},
        {label: '10201 : GST/HST, if applicable, on #10183', value: '10201'},
        {label: '10185 : Purchaser\'s share of Occupancy Fees for month of closing (as per Interim Occup. Fee adj.)', value: '10185'},
        {label: '10187 : Credit to vendor re Occupancy Fees (as determined in the Interim Occupancy Fee adjustment)', value: '10187'}
    ],
    otherAmountCodes : [
        {label: '10030 : Balance Due on Closing, PROVIDED THAT it is payable to the vendor', value: '10030', doNotApplyFor: 'creditNote'},
        {label: '10035 : (Common Expenses) + (Reserve Fund)', value: '10035'},
        {label: '10036 : (GST/HST on sale price) - (GST/HST Rebate) + (TARION Enrolment)', value: '10036' },
        {label: '10037 : The result of: (#10030 - #10035 - #10036)', value: '10037'},
        {label: '10121 : Balance Due on Closing, PROVIDED THAT it is payable to the purchaser', value: '10121', doNotApplyFor: 'creditNote'},
        {label: '10038 : If BDOC is payable to purchaser AND there\'s a VTB 1st Mtg: (Principal of VTB Mtg) - (BDOC)', value: '10038', doNotApplyFor: 'creditNote'},
        {label: '10188 : Amount of monthly Common Expenses (as specified in the "Units & Levels" dialog)', value: '10188'},
        {label: '10189 : Amount of Reserve Fund (as specified in the Reserve Fund adjustment)', value: '10189'},
        {label: '10192 : (BDOC) + (Principal of VTB Mortgages) (BDOC payable to Purchaser is treated as a negative no.)', value: '10192', doNotApplyFor: 'creditNote'},
        {label: '10193 : If there is a tax adjustment based on "Estimated Final" bill, the amount of such estimated bill', value: '10193'},
        {label: '10031 : From Direction re Funds: Amount payable to payee in top field', value: '10031'},
        {label: '10198 : Per Diem Interest: Balance Due on Closing * VTB interest rate / 365', value: '10198'}
    ]
};

export const deliveryOfCashToCloseValues = {
    depositedIntoYourAccount : 'have deposited the cash to close into your account',
    encloseHerewith: 'enclose herewith a certified cheque'
};

export const taxRateOpenFromValues = {
    statementOfAdjustment : 'statementOfAdjustment',
    statementOfAccount : 'statementOfAccount'
};

export const nonProjectSaleAdjustmentFooterMessages = {
    CONSIDERATION_TYPE1:          'Adjustment constitutes Additional Consideration eligible for {matterTaxType} Rebate in the Sale Price Adjustment',
    CONSIDERATION_TYPE2_NO_HST:   'Adjustment constitutes Additional Consideration in the Sale Price Adjustment',
    CONSIDERATION_TYPE2_WITH_HST: 'Adjustment constitutes Additional Consideration (not eligible for {matterTaxType} Rebate) in the Sale Price Adjustment',
    CONSIDERATION_TYPE3:          'Adjustment constitutes Additional Consideration in the Sale Price Adjustment',
};

export const MatterOverviewStatusTypesValue = {
    FLAGGED: 'FLAGGED' as OverrideMatterStatusType,
    MATTER_INACTIVE: 'MATTER_INACTIVE'  as OverrideMatterStatusType,
    MATTER_CLOSED: 'MATTER_CLOSED'  as OverrideMatterStatusType,
    MATTER_PAST_CLOSING_DATE: 'MATTER_PAST_CLOSING_DATE'  as OverrideMatterStatusType,
    MATTER_IN_PROGRESS: 'MATTER_IN_PROGRESS'  as OverrideMatterStatusType,
    MATTER_READY: 'MATTER_READY'  as OverrideMatterStatusType
}

export const MatterStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
}

export const MatterClosingStatus = {
    QUESTION: 'QUESTION',
    PENDING: 'PENDING',
    ESCROW: 'ESCROW',
    IN_FUNDS: 'IN_FUNDS',
    POST_CLOSING: 'POST_CLOSING',
    OCCUPANCY: 'OCCUPANCY'
}

export const DigitalSignaturePlatform = {
    SYNGRAFII: 'SYNGRAFII',
    DOCU_SIGN: 'DOCU_SIGN'
}

export const DigitalSignaturePlatformLabel = {
    SYNGRAFII: 'Syngrafii',
    DOCU_SIGN: 'DocuSign'
};

export const MatterActionRequiredFilters = {
    OUTSTANDING_UNDERTAKINGS                : 'OUTSTANDING_UNDERTAKINGS',
    UNRESOLVED_REQUISITIONS                 : 'UNRESOLVED_REQUISITIONS',
    INCOMPLETE_MATTER_TOPICS                : 'INCOMPLETE_MATTER_TOPICS',
    ATTENTION_MATTER_TOPICS                 : 'ATTENTION_MATTER_TOPICS',
    INCOMPLETE_PRELIMINARY_REPORT_MORTGAGES : 'INCOMPLETE_PRELIMINARY_REPORT_MORTGAGES',
    INCOMPLETE_FINAL_REPORT_MORTGAGES       : 'INCOMPLETE_FINAL_REPORT_MORTGAGES',
    NOT_RELEASED_MATTER_HOLDBACKS           : 'NOT_RELEASED_MATTER_HOLDBACKS',
    OUTSTANDING_TASKS                       : 'OUTSTANDING_TASKS',
    OVERDUE_TASKS                           : 'OVERDUE_TASKS'

}

export const TaskStatusFilter = {
    incompleteNoDueDate: 'INCOMPLETE_NO_DUE_DATE',
    incomplete: 'INCOMPLETE',
    overdue  : 'OVERDUE',
    completed  : 'COMPLETED'
}

//Per US DPPMP-33339 , If it is an Ontario matter, the GST524 Ontario Rebate Schedule no longer appears as a separate item.
export const RemovedFormTemplate = 'GST524 Ontario Rebate Schedule';
export const GST_HST_RESIDENTIAL_RENTAL_REBATE = "GST/HST New Residential Rental Property Rebate Application";
export const GST_HST_RESIDENTIAL_RENTAL_REBATE_FILE_NAME = "GST-HST New Residential Rental Property Rebate Application.pdf";
export const PST_REBATE_FORM_FOR_NEW_HOME = "PST Rebate for New Home Construction";

export const EREG_LENDER_FORM_NAME = 'Lender Documents (including Bank Reports) in Forms Library';

export const creationStages = {
    draft: 'DRAFT',
    full: 'FULL'
};

export const BillingStatus = {
    UNBILLED: 'UNBILLED',
    BILLED: 'BILLED',
    PREBILLED: 'PREBILLED'
};

export const MAX_PRIORITY_ALLOWED: number = 16;

export const ModalAction = {
    OK: 'OK',
    CANCEL: 'Cancel',

};
export const CustomModalAction = {
    WIZARD_OWNER_IMPORT_DATA_ISSUE: 'WIZARD_OWNER_IMPORT_DATA_ISSUE',
};
export const WaterHydroDeptType_IS_COMBINED: string = "COMBINED";
export const WaterHydroDeptType_IS_SEPARATE: string = "SEPARATE";

export const Combined_Hydro_Water_Department: string = "Hydro/Water Dep't";
export const Water_Department: string = "Water Department";
export const Hydro_Department: string = "Hydro Department";


export const ADJUSTMENT_SUBITEMS_FULL_DISPLAY_THRESTHOLD = 14;
export const ADJUSTMENT_SUBITEMS_PARTIAL_TOP_DISPLAY_CNT = 8;

export const ReferredByTypeValues = {
    MANUAL_ENTRY: 'MANUAL_ENTRY',
    CLIENT: 'CLIENT',
    REAL_ESTATE_BROKER: 'REAL_ESTATE_BROKER',
    REAL_ESTATE_AGENT: 'REAL_ESTATE_AGENT',
    SURVEYOR: 'SURVEYOR',
    CONDO_CORP: 'CONDO_CORP',
    INSURANCE_BROKER: 'INSURANCE_BROKER',
    MANAGE_COMP: 'MANAGE_COMP',
    MORTGAGE_BROKER: 'MORTGAGE_BROKER',
    MORTGAGEE: 'MORTGAGEE',
    SOLICITOR: 'SOLICITOR'
}

export const CLOSING_DAY_FILTER_MAXLENGTH = 3;

export const DOCUMENT_UD_DIR_MARKER_FILE_NAME = '.ud-dir-marker.txt';

export const TemplateProductionClass = {
    REGULAR: 'REGULAR',
    MULTI_CLIENT: 'MULTI_CLIENT'
}

export const SELECTED_ALL = 'ALL';
export const SELECTED_NOT_ASSIGNED = 'N/A';

export const TitleMr = 'Mr. ';
export const TitleMs = 'Ms. ';
export const TitleMrs = 'Mrs. ';
export const TitleMiss = 'Miss ';
export const SirAmpMadam = 'Sir & Madam';
export const SirAndMadam = 'Sir and Madam';
export const MadamAmpSir = 'Madam & Sir';
export const MadamAndSir = 'Madam and Sir';
export const Sirs = 'Sirs';
export const Madams = 'Madams';
export const AndPlusSpace = 'and ';
export const AmpPlusSpace = '& ';

export const MISSING_EMAIL = ' - Missing Email Address';
export const OPPORTUNITY_MESSAGE = 'This {$type} has already been {$action}, please refresh the page';

export const EXTRA_HEIGHT = 15;

export enum WillWitnessIds {
    WITNESS1 = 'witness1',
    WITNESS2 = 'witness2',
}
