import {SoAdjFinalFee} from './so-adj-final-fee';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';

export class SoAdjFinalOccupancyFee extends SoAdjFinalFee{

    static getDefault(considerationTaxes: ConsiderationTaxes): SoAdjFinalOccupancyFee {
        let newAdj: SoAdjFinalOccupancyFee = new SoAdjFinalOccupancyFee();

        newAdj.useAmountFromInterim = "NO";
        newAdj.amount = 0;
        newAdj.purchaserHasPaidFee = "AND_INCLUDING_MONTH_OF_CLOSING";

        newAdj.applyTax = false;
        newAdj.taxType = considerationTaxes.rateType;
        newAdj.hstRate = considerationTaxes.hstRate;

        return newAdj;
    }
}
