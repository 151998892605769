import {MatterTypeInfo} from '../../admin/shared/matter-type-info';
import {DocumentTemplateCategory} from '../../matters/document-production/document-template-category';

const systemMatterTypes : string [] = ['PURCHASE', 'SALE', 'MORTGAGE','DISCHARGE'];

export class MatterTypeUtil {

    static isCustomeMatterType(typeCode: string) : boolean {
        return !systemMatterTypes.find(type=>type==typeCode);
    }

    static getMatterTypeDescription(typeCode: string, matterTypeInfos: MatterTypeInfo[]) : string {
        if(matterTypeInfos && matterTypeInfos.length){
            let matterType = matterTypeInfos.find(mt=>mt.matterTypeCode == typeCode);
            return matterType ? matterType.matterTypeDescription : '';
        } else {
            return '';
        }
    }

    // Output 'PURCHASE,SALE,MORTGAGE and then CustomMatterCodes sorted alphabetically'
    static sortMatterTypes(matterTypes: string[]): string[] {
        let systemTypes : string[] = [];
        this.addToListIfExist(matterTypes, systemTypes, 'PURCHASE');
        this.addToListIfExist(matterTypes, systemTypes, 'SALE');
        this.addToListIfExist(matterTypes, systemTypes, 'MORTGAGE');
        this.addToListIfExist(matterTypes, systemTypes, 'DISCHARGE');

        let customTypes : string[] = matterTypes.filter(type=>this.isCustomeMatterType(type));
        const sortedCustomTypes = customTypes.sort((a: string, b: string) => {
            return (a > b ) ? -1 : 1;
        });
        return [...systemTypes, ...sortedCustomTypes];
    }

    static addToListIfExist(inputStrings: string[], outputStrings: string[], stringToBeMoved: string) : void {
        let foundString = inputStrings.find(type=>type==stringToBeMoved);
        if(foundString ){
            outputStrings.push(foundString);
        }
    }

    static getDocumentCategoryDescription(documentTemplateCategory: DocumentTemplateCategory, matterTypeInfos: MatterTypeInfo[]) : string {
        if(documentTemplateCategory.defaultCategory){
            return `_All ${this.getMatterTypeDescription(documentTemplateCategory.applicableMatterTypeCode, matterTypeInfos)} Documents`;
        } else {
            return documentTemplateCategory.categoryDescription;
        }
    }

    static convertMatterTypeCodesOrDescriptionToString(typeCodes: string[], matterTypeInfos: MatterTypeInfo[], shortVersion: boolean): string {

        let sortedMatterTypeCodes: string[] = this.sortMatterTypes(typeCodes);
        if (shortVersion) {
            let shortVersionArr: string[] = [];
            sortedMatterTypeCodes.forEach((code)=>{
                shortVersionArr.push(this.isCustomeMatterType(code) ? code : code.charAt(0));
            });
            return shortVersionArr.join(', ');
        }
        let longVersionArr: string[] = [];
        sortedMatterTypeCodes.forEach((code)=>{
            longVersionArr.push(this.getMatterTypeDescription(code, matterTypeInfos));
        });
        return longVersionArr.join(', ');
    }
}
