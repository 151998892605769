import {AddressDropdowns} from '../../../shared-main/address-Form/drop-downs';


export const dropDowns = {

    statusAccountFilter: [
        { label: 'All', value: 'all' },
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Inactive', value: 'INACTIVE' }
    ],

    accountStatus: [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Inactive', value: 'INACTIVE' }
    ],

    barristerSolicitorType: [
        { label: ' ', value: ' ' },
        { label: 'Barrister & Solicitor', value: 'BARRISTER_SOLICITOR' },
        { label: 'Barristers & Solicitors', value: 'BARRISTERS_SOLICITORS' }
    ],

    barristerSolicitorTypeForBC: [
        { label: ' ', value: ' ' },
        { label: 'Barrister & Solicitor', value: 'BARRISTER_SOLICITOR' },
        { label: 'Barristers & Solicitors', value: 'BARRISTERS_SOLICITORS' },
        { label: 'Lawyer', value: 'LAWYER' },
        { label: 'Lawyers', value: 'LAWYERS' },
        { label: 'Notary Public', value: 'NOTARY_PUBLIC' },
        { label: 'Notaries Public', value: 'NOTARIES_PUBLIC' }
    ],

    documentProcessorTypes: [
        { label: 'MS-Word', value: 'MS_WORD' },
        { label: 'WordPerfect', value: 'WORD_PERFECT' }
    ],

    documentProcessorTypesBC: [
        { label: 'Rich Text Format', value: 'RICH_TEXT_FORMAT' }
    ],

    accountType: [
        { label: ' ', value: 'QUESTION' },
        { label: 'Law Firm', value: 'LAW_FIRM' },
        { label: 'Freelance Paralegal', value: 'FREELANCE_PARALEGAL' },
        { label: 'College', value: 'COLLEGE' },
        { label: 'Accountant', value: 'ACCOUNTANT' },
        { label: 'Municipality', value: 'MUNICIPALITY' },
        { label: 'Business Partner', value: 'BUSINESS_PARTNER' },
        { label: 'Lender', value: 'LENDER' },
        { label: 'Builder', value: 'BUILDER' },
        { label: 'Real Estate Agent', value: 'REAL_ESTATE_AGENT' },
        { label: 'Mortgage Broker', value: 'MORTGAGE_BROKER' },
        { label: 'Notary', value: 'NOTARY' },
        { label: 'Other', value: 'OTHER' }
    ],

    genders : [
        { label: ' ', value: 'QUESTION' },
        { label: 'Male', value: 'MALE' },
        { label: 'Female', value: 'FEMALE' },
    ],

    paymentMethods : [
        { label: 'Credit Card', value: 'CREDIT_CARD' },
        { label: 'Invoice', value: 'INVOICE' },
    ],

    accessibleProvinces : [
        { label: 'Ontario', value: 'ON'},
        { label: 'Alberta', value: 'AB'},
        { label: 'British Columbia', value: 'BC'},
        { label: 'Manitoba', value: 'MB'},
        { label: 'New Brunswick', value: 'NB'},
        { label: 'Nova Scotia', value: 'NS'},
        { label: 'Saskatchewan', value: 'SK'},
    ],

    yesNoOptions : [
        { label: 'N/y ', value: 'N_y' },
        { label: 'Yes', value: 'YES' },
        { label: 'No', value: 'NO' }

    ],

    yesNoOnlyOptions : [
        { label: 'Yes', value: true },
        { label: 'No', value: false }

    ],

    yesNoOnlyBooleanValueOptions : [
        { label: 'Yes', value: 'YES' },
        { label: 'No', value: 'NO' }

    ],

    applicationNameOptions : [
        { label: 'The Conveyancer', value: 'THE_CONVEYANCER' },
        { label: 'Convey.ca/Projects', value: 'CONVEY_CA_OR_PROJECTS' },
        { label: 'Estate-A-Base', value: 'ESTATE_A_BASE' },
        { label: 'FastCompany', value: 'FAST_COMPANY' },
        { label: 'WillBuilder', value: 'WILL_BUILDER' },
        { label: 'Unity', value: 'UNITY_CONVEYANCING' },
        { label: 'Unity Projects', value: 'UNITY_PROJECTS' },
        { label: 'UnityDrive', value: 'UNITY_DRIVE' },
        { label: 'Unity Will', value: 'UNITY_WILL' }
    ],

    applicationStatusOptions : [
        { label: 'DISCONTINUE', value: 'DISCONTINUE' },
        { label: 'EXISTING', value: 'EXISTING' },
        { label: 'NEW', value: 'NEW' },
        { label: 'REACTIVATED', value: 'REACTIVATED' }

    ],

    trustLetterFormatOptions: [
        { label: ' ', value: 'QUESTION' },
        { label: 'North (Edmonton)', value: 'NORTH' },
        { label: 'South (Calgary)', value: 'SOUTH' }
    ],
    notaryOrCommissionerOptions: [
        {label : 'Neither', value : 'NONE'},
        {label : 'Notary', value : 'NOTARY'},
        {label : 'Commissioner', value : 'COMMISSIONER'}
    ],

    accountAddressCopyOptions: [
        AddressDropdowns.sameAsMailing,
        AddressDropdowns.manuallyEntered
    ],

    SharingMessageOptions: [
        { label: 'System Message Only ', value:  "SYSTEM_MESSAGE_ONLY" },
        { label: 'System Message and Email', value:  "SYSTEM_MESSAGE_AND_EMAIL" }
    ],

    triggerEventOptions : [
        {label : 'In Progress', value : 'STARTED'},
        {label : 'Closed', value : 'FINISHED'},
    ],
};
