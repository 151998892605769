import {SoAdjOtherProrated, StatementOfAdjustmentDisplayItemLine} from '../model';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementAdjustmentAmountTypes} from '../statement-adjustment-constants';
import Utils from '../../../shared-main/utils';

export class StatementAdjustmentDisplayOtherProratedBuilder {
    static getDisplayItems(soAdjOtherProrated: SoAdjOtherProrated, closingDate: string, mainBuilder: StatementAdjustmentDisplayBuilder, taxRateType : string, statementAdjustmentKey?: string): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        if (!soAdjOtherProrated) {
            return sadItemDetails;
        }

        if (soAdjOtherProrated.commencementDate && soAdjOtherProrated.commencementDate.length > 0) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `From ${mainBuilder.formattedDate(soAdjOtherProrated.commencementDate, true)}`,
                ''
            ));
        }

        if (soAdjOtherProrated.commencementDate && soAdjOtherProrated.commencementDate.length > 0) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `to ${mainBuilder.formattedDate(soAdjOtherProrated.expiryDate, true)}`,
                mainBuilder.getCurrency(soAdjOtherProrated.amountToBeProrated)
            ));
        }

        if (Utils.convertToBoolean(soAdjOtherProrated.applyTax)) {
            if (taxRateType!== "GST" && soAdjOtherProrated.taxType == taxRateType) {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `${taxRateType} calculated at ${mainBuilder.getPercentage(soAdjOtherProrated.hstRate) }`,
                    mainBuilder.getCurrency(soAdjOtherProrated.amountToBeProrated * soAdjOtherProrated.hstRate / 100)
                ));
            }

            if (soAdjOtherProrated.taxType == "GST") {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `GST calculated at ${mainBuilder.getPercentage(soAdjOtherProrated.gstRate) }`,
                    mainBuilder.getCurrency(soAdjOtherProrated.amountToBeProrated * soAdjOtherProrated.gstRate / 100)
                ));
            }

            if (Utils.convertToBoolean(soAdjOtherProrated.applyPst)) {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `PST calculated at ${mainBuilder.getPercentage(soAdjOtherProrated.pstRate) }`,
                    mainBuilder.getCurrency(soAdjOtherProrated.amountToBeProrated * soAdjOtherProrated.pstRate / 100)
                ));
            }
        }

        sadItemDetails.push(mainBuilder.getNewItemLine(
            `${mainBuilder.vendorLabel} has ` + (soAdjOtherProrated.direction == 'PAYABLE' ? 'paid' : 'received'),
            mainBuilder.getCurrency(soAdjOtherProrated.vendorHasReceivedTotal)
        ));

        let vendorsShare: number = soAdjOtherProrated.getVendorsShare(closingDate, statementAdjustmentKey, mainBuilder.paysForDateOfClosing);
        let isPaysForDateOfClosingVendor : boolean = mainBuilder.paysForDateOfClosing == 'VENDOR';
        let days: number = soAdjOtherProrated.getNumberOfDaysElapsed(closingDate);
        if(isPaysForDateOfClosingVendor){
            days++;
        }
        let daysText = Utils.getDaysLabel(days);
        sadItemDetails.push(mainBuilder.getNewItemLine(
                `${mainBuilder.vendorLabel}'s share for ${soAdjOtherProrated.getNumberOfDaysElapsedStr(days)} ${daysText}`,
                vendorsShare >= 0 ? mainBuilder.getCurrency(vendorsShare) : mainBuilder.cannotCalculateAmount,
                undefined, '10140.OTHER_PRORATED.vendorShareAmount'
            ));

        let creditType: string = soAdjOtherProrated.getCreditType(closingDate, statementAdjustmentKey, mainBuilder.paysForDateOfClosing);

        sadItemDetails.push(mainBuilder.getNewItemLine(
            `Credit ` + (creditType == StatementAdjustmentAmountTypes.VENDOR ? mainBuilder.vendorLabel : mainBuilder.purchaserLabel),
            ''
            )
        );

        return sadItemDetails;
    }
}
