import {DEFAULT_POSSESSION_TIME} from './constants';

export const StatusBarMessages = {

    "matter.propertyteranet.pin" : {
        STATUS_BAR_HELP: "",
    },

    "matter.matterOpening.matterNo" : {
        STATUS_BAR_HELP : "",
    },

    "matter.matterOpening.purchaseSaleAgreementDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.supplementalTask.dateField.supplementalTask" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.supplementalTask.dateField.tickerDateField" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.supplementalTask.dateField.tickerTaskCompletionDateField" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.mainClient.matterContactInfo.email" : {
        STATUS_BAR_HELP: "",
    },

    "matter.mainClient.matterContactInfo.preClosingAddress.postalCode" : {
        STATUS_BAR_HELP: "",
    },

    "matter.matterOpening.uniqueMatterNo" : {
        STATUS_BAR_HELP: "F9 = Change Matter No.",
    },
    "contact.companyName" : {
        STATUS_BAR_HELP: "",
    },

    "contact.uniqueCompanyName" : {
        STATUS_BAR_HELP: "",
    },

    "contact.address.postalCode" : {
        STATUS_BAR_HELP: "",
    },

    "matter.matterOpening.orderNumber" : {
        STATUS_BAR_HELP: "",
    },

    "matter.matterOpening.fileNumber" : {
        STATUS_BAR_HELP: "F9 = Matter No",
    },

    "matter.matterOpening.fileOpeningDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.matterOpening.lawClerk" : {
        STATUS_BAR_HELP: "F9 = Current User",
    },

    "matter.matterOpening.occupancyDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.matterOpening.adjustmentDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.matterOpening.requisitionDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.matterOpening.matterCloseDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.matterOpening.releaseDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.matterOpening.matterPossessionTime" : {
        STATUS_BAR_HELP: `F9 = ${DEFAULT_POSSESSION_TIME}`,
    },

    "matter.matterOpening.purchaserExecDocsDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.matterOpening.solicitor" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.solicitorName" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.clientsReLine" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.vendorReLine" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.purchaserReLine" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.lenderReLine" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.realEstateBroker" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.realEstateAgent" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.agentEmail" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.referredByType" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.referredByName" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.feeQuote" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.teraviewDocketIdentifier" : {
        STATUS_BAR_HELP: "F9 = toggle",
    },
    "matter.matterOpening.registrationMethod" : {
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.executingDocsAt" : { //TODO: this can be split in different keys if the message is to be different per matter type
        STATUS_BAR_HELP: "",
    },
    "matter.matterOpening.transactionTitleInsuredCode" : {
        STATUS_BAR_HELP: "Press F9 to select title insurance company",
    },

    "matter.matterOpening.fileInactiveDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "matter.mainClient.envelopeSalutation" : {
        STATUS_BAR_HELP: "F9 = toggle",
    },

    "matter.mainClient.dearText" : {
        STATUS_BAR_HELP: "F9 = toggle",
    },
    "matter.mainClient.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "matter.mainClient.offeror.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "matter.mainClient.vendor.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "matter.mainClient.titleDeedName" : {
        STATUS_BAR_HELP: "F9 = Populate surname from the client",
    },
    "property.address.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "mortgage.address.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "privateLender.mailing.address.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "mortgage.serviceAddress.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "mortgage.reportAddress.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "mortgage.term.guarantor.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "spouse.business.address.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "property.partLot" : {
        STATUS_BAR_HELP: "F9 = toggle",
    },
    "property.beingPart" : {
        STATUS_BAR_HELP: "F9 = toggle",
    },
    "property.section" : {
        STATUS_BAR_HELP: "F9 = Section",
    },
    "property.unitLevelPlan" : {
        STATUS_BAR_HELP: "F9 = Edit Legal Description, amount of Common Expenses, etc.",
    },
    "property.rollNumber" : {
        STATUS_BAR_HELP: "F9 = NOT ASSIGNED/MULTIPLE",
    },
    "property.surveyDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "property.propertyTaxes" : {
        STATUS_BAR_HELP: "Press F9 to edit property taxes",
    },
    "property.purchasePrice" : {
        STATUS_BAR_HELP: "Press F9 to edit purchase price",
    },
    "property.deposit" : {
        STATUS_BAR_HELP: "Press F9 to edit the deposit amount",
    },
    "property.easementRightOfWay" : {
        STATUS_BAR_HELP: "F9 = SUBJECT TO INSTRUMENT NO(S)",
    },
    "considerationLtt.moniesPaidOrToBePaidIn" : {
        STATUS_BAR_HELP: "F9 = Enter/edit sale price",
    },
    "considerationLtt.remainderOfLandUsedFor" : {
        STATUS_BAR_HELP: "F9 = commercial",
    },
    "matter.mainClient.dateFundsDeposited" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "idVerification.nameOnID" :{
        STATUS_BAR_HELP: "F9 = toggle to override name on ID",
    },
    "idVerification.dob" :{
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "idVerification.expiryDate" :{
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.otherParty.dateFundsDeposited" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    //It will add the help text when BA gets it.
    "matter.otherPartyContactInfo.dear" : {
        STATUS_BAR_HELP: "",
    },
    //It will add the help text when BA gets it.
    "matter.developerContactInfo.dear" : {
        STATUS_BAR_HELP: "",
    },
    "matter.mainClient.matterContactInfo.postClosingAddressIs" : {
        STATUS_BAR_HELP: "F9 to select Adress option",
    },
    "matter.mainClient.matterContactInfo.postClosingAddress.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "matter.mainClient.matterContactInfo.postClosingAddress.city" : {
        STATUS_BAR_HELP: "F9 to default Post Closing City, Province and Country",
    },

    "matter.mainClient.matterContactInfo.postClosingAddress.province" : {
        STATUS_BAR_HELP: "F9 to default Post Closing Province and Country",
    },

    "matter.mainClient.matterContactInfo.postClosingAddress.country" : {
        STATUS_BAR_HELP: "F9 = Canada",
    },
    "matter.mainClient.matterContactInfo.preClosingAddress.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "condoCorporation.mailingAddress.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "fireInsuranceContactInfo.address.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "matter.fireInsurance.shutter" : {
        STATUS_BAR_HELP: "F9 = toggle shutter",
    },
    "condoCorporation.dear" : {
        STATUS_BAR_HELP: "F9 = Sir or Madam",
    },
    "matter.fireInsuranceContactInfo.dear" : {
        STATUS_BAR_HELP: "F9 = Sir or Madam",
    },
    "matter.fireInsurance.expiryDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "purchaserReport.qualification" : {
        STATUS_BAR_HELP: "F9 = edit text to be incorporation, verbatim, into report",
    },
    "purchaserReport.qualificationNS" : {
        STATUS_BAR_HELP: "F9 = Benefits & Burdens from Undertakings Received topic",
    },
    "matter.mainClient.matterContactInfo.preClosingAddress.city" : {
        STATUS_BAR_HELP: "F9 to default  Pre Closing City, Province and Country",
    },

    "matter.mainClient.matterContactInfo.preClosingAddress.province" : {
        STATUS_BAR_HELP: "F9 to default Pre Closing Province and Country",
    },

    "matter.mainClient.matterContactInfo.preClosingAddress.country" : {
        STATUS_BAR_HELP: "F9 = Canada",
    },

    "matter.statementOfAccount.description" : {
        STATUS_BAR_HELP: "Edit description as desired",
    },

    "mortgage.commitmentDate": {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

    "mortgage.interimEnclosures": {
        STATUS_BAR_HELP: "Press F9 to edit list of enclosures to be provided with final report",
    },
    "mortgage.mortgageContactInfo.dear": {
        STATUS_BAR_HELP: "F9 = Sir or Madam",
    },
    "mortgage.term.principal": {
        STATUS_BAR_HELP: "F9 = Edit Net Advance for Trust Ledger",
    },
    "mortgage.term.interest": {
        STATUS_BAR_HELP: "If Nil, press F9 / Press F9 to return to original format",
    },
    "mortgage.term.sctNo": {
        STATUS_BAR_HELP: "F9 = 'several'",
    },
    "mortgage.term.otherText": {
        STATUS_BAR_HELP: "F9 = Life Insurance Premium",
    },
    "mortgage.term.regularPayment": {
        STATUS_BAR_HELP: "Press F9 to set calculation options",
    },
    "mortgage.term.alternateRegularPayment": {
        STATUS_BAR_HELP: "Press F9 to set calculation options",
    },
    "mortgage.term.altPaymentDate": {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "mortgage.report.additionalClause": {
        STATUS_BAR_HELP: "F9 = edit additional clauses to be incorporated, verbatim, into report(s)",
    },
    "mortgage.report.finalReportEnclosures": {
        STATUS_BAR_HELP: "Press F9 to edit list of enclosures to be provided with final report",
    },
    "admin.soaDocumentProfile.wordingDirectionFunds": {
        STATUS_BAR_HELP: "F9 = reinstate default wording",
    },
    "admin.soaDocumentProfile.headingReportVendor": {
        STATUS_BAR_HELP: "F9 = reinstate default wording",
    },
    "admin.soaDocumentProfile.rightColumnListsCreditsTo": {
        STATUS_BAR_HELP: "F9 = reinstate default wording",
    },
    "admin.soaDocumentProfile.leftColumnListsCreditsTo": {
        STATUS_BAR_HELP: "F9 = reinstate default wording",
    },
    "admin.miscDocumentProfile.charactersInSigningLine":{
        STATUS_BAR_HELP: "The recommended value is 57",
    },
    "matter" : {
        CREATE: "Matter created",
        UPDATE: "Matter saved"
    },
    "project" : {
        CREATE: "Project created",
        UPDATE: "Project saved"
    },

    "contact" : {
        CREATE: "Contact created",
        UPDATE: "Contact saved"
    },

    "accessProfile" : {
        CREATE: "Access profile created",
        UPDATE: "Access profile saved",
        COPIED_SUCCESSFUL: "Access profile successfully copied to all customer accounts",
        COPIED_FAIL: "Access profile cannot be copied to customer accounts, please contact system administrator",
    },

    "account" : {
        CREATE: "Account created",
        UPDATE: "Account saved"
    },

    "legalFirm" : {
        CREATE: "Legal firm created",
        UPDATE: "Legal firm saved"
    },

    "defaultFields" : {
        UPDATE: "Default fields saved"
    },

    "docket" : {
        UPDATE: "Docket saved"
    },

    "documentProfile" : {
        CREATE: "Document profile created",
        UPDATE: "Document profile saved",
        DELETE: "Document profile deleted"
    },

    "mortgageeEnclosureConfiguration": {
        UPDATE: "Mortgagee enclosure saved",
    },

    "statementOfAccount" : {
        UPDATE: "Statement of account saved"
    },

    "trustLedger" : {
        UPDATE: "Trust Ledger saved"
    },

    "user" : {
        CREATE: "User created",
    },

    "address.country" : {
        STATUS_BAR_HELP: "F9 = CANADA",
    },

    "matter.propertyTeranet.confirmationNoSection" : {
        STATUS_BAR_HELP: "Enter confirmation number for the 5.0.1 form submission",
    },

    "matter.considerationLtt.nrstTaxAmount" : {
        STATUS_BAR_HELP: "Ontario Non-Resident Speculation Tax Amount",
    },

    "matter.considerationLtt.nrstReceiptNo" : {
        STATUS_BAR_HELP: "NRST Remittance Receipt No.",
    },

    "matter.requisitionResponse.requisitionLetterDate" : {
        STATUS_BAR_HELP: "Enter the date (dd/mm/yyyy) of the Purchaser's requisition letter. F9 = Calendar",
    },

    "matter.requisitionResponse.receivedOnTime" : {
        STATUS_BAR_HELP: "Use cursor keys to toggle to desired response.",
    },

    "matter.requisitionResponse.requisitionDate" : {
        STATUS_BAR_HELP: "Enter the Title Search date (dd/mm/yyyy) as found in the Agreement of Purchase and Sale. F9 = Calendar",
    },

    "matter.requisitionResponse.responseSent" : {
        STATUS_BAR_HELP: "Use cursor keys to toggle to desired response.",
    },

    "matter.requisitionResponse.rteditor" : {
        STATUS_BAR_HELP: "Enter full text of response to requisitions.",
    }
    ,
    "account.trialExpiryDate": {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.statementAdjustment.interestFromDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.statementAdjustment.interestToDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.requisition.requisitionDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "modal.undertaking.followupDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "modal.undertaking.dateOfInstrument" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "modal.undertaking.dateOfRegistration" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.existingMortgage.amountPayable" : {
        STATUS_BAR_HELP: "Press F9 to edit discharge details",
    },
    "mortgage.mortgagePayout.interestDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "mortgage.mortgagePayout.principalAt" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "mortgage.mortgagePayout.principalTo" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "mortgage.mortgageEmp.signageDate" : {
        STATUS_BAR_HELP: 'Check to set mortgage signing date to same as field "PA/36" in "Matter Opening" Tab. Uncheck to manually enter date.',
    },
    "matter.soadj.assumed.mortgage.principal.date.empty" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.soadj.other.prorated.commencement.date.empty" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.soadj.other.prorated.expiry.date.empty" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.soadj.final.occupancy.fee.paidOn.date.empty": {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.soadj.expense.occupancy.period.total.effective.from.date.empty": {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.soadj.expense.occupancy.period.total.effective.till.date.empty": {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.soa.soawater.commencementdate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.soa.soawater.enddate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.undertaking-details-modal.subject" : {
        STATUS_BAR_HELP: "Enter subject of undertaking",
    },
    "matter.undertaking-details-modal.description" : {
        STATUS_BAR_HELP: "Commencing with the word \"to\", draft the wording of the undertaking",
    },
    "matter.propertTeranet.fiscalYearEndDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.broker.dear" : {
        STATUS_BAR_HELP: `F9 = Broker Dear`,
    },
    "project.generalInfo.declarationRegDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "project.projectCondo.declarationRegDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "project.docsReg.hstPeriodFrom" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "project.docsReg.hstPeriodTo" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "project.docsReg.dateOfSignature" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "dpDateTimePicker.startDate.customEvent.eventData" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matter.otherParty.docsExecutedOn": {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "massUpdate.adjustmentDate.dateToAdjust":{
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "mortgageAdvance.advanceDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "matterHoldback.releaseDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "forms.form9.percentageOfTotalLoan" : {
        STATUS_BAR_HELP: "F9 = 100%",
    },
    "forms.form9.percentageValueMortgaged" : {
        STATUS_BAR_HELP: "F9 = 100%",
    },
    "modal.residentialRentalRebateForm.dateApplicationSigned" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "modal.residentialRentalRebateForm.relevantDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "modal.residentialRentalRebateForm.gstHstFrom" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "modal.residentialRentalRebateForm.gstHstTo" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "modal.judgementModal.judgementDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "modal.judgementModal.judgementFillingDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "modal.newHomePstRebateForm.dataEntryDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "importDL.birthDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },
    "importDL.expryDate" : {
        STATUS_BAR_HELP: "F9 = Calendar",
    },

};
