import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {BurgerMenuExtendedItem} from '../../matters/shared/burger-menu-extended-item';
import {CirfType} from '../../matters/shared/cirf/cirf';
import {
    OpportunityMatterSourceValue,
    OpportunityMatterStatusType,
    OpportunityMatterStatusValue
} from '../../matters/shared/matter-specific-type';

export type OpportunityType = 'CIRF' | 'REFERRAL' | 'SHARE' | 'OPPORTUNITY' ;
export const opportunityViewStatus = {
    CANCELLED : 'CANCELLED',
    EXPIRED : 'EXPIRED',
    INPROGRESS : 'INPROGRESS',
    OUTSTANDING : 'OUTSTANDING',
    PROCESSED : 'PROCESSED',
    SUBMITTED : 'SUBMITTED',
    RECEIVED : 'RECEIVED',
    DRAFT : 'DRAFT',
    DECLINED : 'DECLINED',
    DECLINED_SHARE : 'DECLINED_SHARE', //maintained only on UI
    DECLINED_REFERRAL : 'DECLINED_REFERRAL', //maintained only on UI
    ACCEPTED : 'ACCEPTED',
    READ : 'READ',
    UPDATED : 'UPDATED',
    REVOKED: 'REVOKED',
    DECLINE_ACKNOWLEDGED: 'DECLINE_ACKNOWLEDGED',
    CLOSED_CONVERTED: 'CLOSED_CONVERTED',
    CLOSED_INACTIVE: 'CLOSED_INACTIVE',
    QUALIFYING: 'QUALIFYING',
    QUOTED: 'QUOTED',
    BLANK:'BLANK'

};

export class OpportunityView extends BaseEntity{
    id: number;
    guid: string;
    matterId: number;
    matterRecordNumber: string;
    matterReline: string;
    lawyerInitials: string;
    lawClerkInitials: string;
    status: string;
    createdOn: string;
    lastUpdateOn: string;
    type: string;
    clientEmail: string;
    clientName: string;
    burgerMenu: BurgerMenuExtendedItem[] = [];
    opportunityType: OpportunityType;
    originatorName : string;
    cirfType: CirfType;
    packageFromConnect: boolean;
    opportunitySource:string;
    opportunityStatus: OpportunityMatterStatusType;
    opportunityMatterId: number;
    unread: boolean;



    get displayOpportunityType(){
        return this.opportunityType == 'OPPORTUNITY' ? this.displayOpportunitySource : this.opportunityType;
    }

    get displayOpportunitySource():string{
        switch (this.opportunitySource) {
            case OpportunityMatterSourceValue.blank: return  'OPPORTUNITY';
            case OpportunityMatterSourceValue.generalInquiry: return 'GENERAL INQUIRY';
            case OpportunityMatterSourceValue.requestForQuote: return 'REQUEST FOR QUOTE';
            case OpportunityMatterSourceValue.internallyIdentified: return 'INTERNALLY IDENTIFIED';
            case OpportunityMatterSourceValue.referral: return 'REFERRAL';
            case OpportunityMatterSourceValue.conveyancingInstructions: return 'CONVEYANCING INSTRUCTIONS'

            default: return '';
        }

    }

    statusUnread(row) {
        if ((row.status === 'SUBMITTED' || row.status === 'REVOKED' || (row.opportunityType === 'SHARE' && row.status === 'DECLINED')) && row.unread) {
            return true;
        }
    }


    statusDisplay(opportunityType : OpportunityType): string {
        switch (this.status) {
            case opportunityViewStatus.CANCELLED: return  'Cancelled';
            case opportunityViewStatus.EXPIRED: return 'Expired';
            case opportunityViewStatus.INPROGRESS: return 'Client Entering Data';
            case opportunityViewStatus.OUTSTANDING: return  'Outstanding';
            case opportunityViewStatus.PROCESSED: return 'Processed';
            case opportunityViewStatus.SUBMITTED: return 'Received';
            case opportunityViewStatus.RECEIVED: return 'Received';
            case opportunityViewStatus.DRAFT: return 'Draft';
            case opportunityViewStatus.DECLINED :
                return (opportunityType == 'SHARE' ?   'Declined-Share' :  'Declined-Referral' );
            case opportunityViewStatus.ACCEPTED: return 'Accepted';
            case opportunityViewStatus.READ: return 'Read';
            case opportunityViewStatus.UPDATED: return 'Updated';
            case opportunityViewStatus.REVOKED: return 'Revoked';
            case opportunityViewStatus.DECLINE_ACKNOWLEDGED: return 'Decline Acknowledged';
            case opportunityViewStatus.CLOSED_CONVERTED:return 'Closed Converted';
            case opportunityViewStatus.CLOSED_INACTIVE: return  'Closed Inactive';
            case opportunityViewStatus.QUALIFYING: return  'Open/Qualifying';
            case opportunityViewStatus.QUOTED: return  'Quoted';
            case opportunityViewStatus.BLANK: return  'Opportunity';

            default: return '';
        }
    }

    isPreferredOpportunity() : boolean {
        return this.status == opportunityViewStatus.RECEIVED ||
            this.status == opportunityViewStatus.OUTSTANDING ||
            this.status == opportunityViewStatus.SUBMITTED || this.status == opportunityViewStatus.DECLINED ||
            this.status == opportunityViewStatus.REVOKED;
    }

    isCirf() : boolean {
        return this.opportunityType == 'CIRF';
    }
    isReferral() : boolean {
        return this.opportunityType == 'REFERRAL';
    }
    isShare() : boolean {
        return this.opportunityType == 'SHARE';
    }
    isCreatedFromOpportunity() : boolean {
        return this.opportunityType == 'OPPORTUNITY';
    }

    isShareDeclined() : boolean {
        return this.status == opportunityViewStatus.DECLINED;
    }

    isOpportunityClosedDuplicate(): boolean {
        return this.opportunityStatus == OpportunityMatterStatusValue.closedDuplicate;
    }

    //Referred/Shared By/Sent To
    getOriginatorNameForDisplay(): string {
        let text: string = this.originatorName ? this.originatorName : '';
        if (this.isMatterCirf() && this.originatorName) {
            text+=' (Full)';
        } else if (this.isParticipantCirf()) {
            text+=' (Individual)';
        }
        return text;
    }

    get opportunityTypeForDisplay() : string{
        return this.isShareDeclined() ? 'DECLINED SHARE' : this.opportunityType;
    }

    isMatterCirf(): boolean {
        return this.isCirf() && this.cirfType == 'MATTER';
    }

    isParticipantCirf(): boolean {
        return this.isCirf() && this.cirfType == 'PARTICIPANT';
    }

}
