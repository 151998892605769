export class FlaStatement{

   constructor (flaStatement?: FlaStatement) {
       if(flaStatement) {
           for (let prop in flaStatement) {
               if (flaStatement.hasOwnProperty(prop)) {
                   this[prop] = flaStatement[prop];
               }
           }
           
           this.inCompatibleStatements = [];
           for (let i: number =0; i < flaStatement.inCompatibleStatements.length; i++) {
               this.inCompatibleStatements[i] = flaStatement.inCompatibleStatements[i]
           }
       }
   } 

    flaTypeCode: string;
    flaTypeDescription: string;
    inCompatibleStatements: string[];
}
