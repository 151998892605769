import {Address} from './address';
import {Contact} from './contact';
import {Jurisdiction, RegistryOffice} from './jurisdiction';
import {Surveyor} from './surveyor';
import {RollNumber} from './roll-number';
import {CondominiumPlan} from '../property-teranet/unit-level-plan/condominium-plan';
import {CondominiumExpense} from '../property-teranet/unit-level-plan/condominium-expense';
import {DpBooleanValue, DpBooleanValueTypes} from './dp-boolean';
import {Deposit} from './deposit';
import {MatterTax} from './property-taxes';
import * as _ from 'lodash';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {constValues} from './const-values';
import {dropDowns} from './matter-drop-downs';
import {UUID} from 'angular2-uuid';
import {ParcelLegalDescription} from './parcel-legal-description';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';
import {Matter} from './matter';
import {Utils as SharedUtils} from './utils';
import {ZERO_INTEREST} from '../../shared-main/constants';
import {LandTransferTaxRate} from './land-transfer-tax-rate';
import {Form54} from './form54';
import Utils from '../../shared-main/utils';

export type MatterPropertyDescriptionType = 'PLAN_BLOCK_LOT' | 'METES_AND_BOUNDS';
export type FarmlandEvidenceType = 'NOT_FARM_LAND' | 'FARM_LAND_TO_BE_MORTGAGED' | 'OTHER';
export type PropertyTypeType = 'RESIDENTIAL' | 'COMMERCIAL' | 'COMMERCIAL_RESIDENTIAL';

export class MatterProperty extends BaseEntity {

    constructor(mp? : MatterProperty) {
        super(mp);
        if(mp) {
            if(mp.clientSideIdentifier)
            {
                this.clientSideIdentifier = parseFloat(mp.clientSideIdentifier.toString());
            }

            this.address = new Address(mp.address);
            this.contact = new Contact(mp.contact);

            if(mp.jurisdiction) {
                this.jurisdiction = new Jurisdiction(mp.jurisdiction);
            }

            if(mp.registryOffice) {
                this.registryOffice = new RegistryOffice(mp.registryOffice)
            }

            if(mp.matterTax)
            {
                this.matterTax = new MatterTax(mp.matterTax);
            }

            this.matterTaxesByRollNumber = [];

            if(mp.matterTaxesByRollNumber) {
                let sortedMatterTaxesByRollNumber = _.sortBy(mp.matterTaxesByRollNumber , ['rollNumberOrder'] );
                for(let cp of sortedMatterTaxesByRollNumber) {
                    this.matterTaxesByRollNumber.push(new MatterTax(cp));
                }
            }

            this.condominiumPlans = [];

            if(mp.condominiumPlans) {
                for(let cp of mp.condominiumPlans) {
                    this.condominiumPlans.push(new CondominiumPlan(cp));
                }
            }

            this.importedParcelRegisterIds = [];
            if(mp.importedParcelRegisterIds) {
                mp.importedParcelRegisterIds.forEach(s => {this.importedParcelRegisterIds.push(s)});
            }

            this.condominiumExpenses = [];

            if(mp.condominiumExpenses) {
                for(let ud of mp.condominiumExpenses) {
                    this.condominiumExpenses.push(new CondominiumExpense(ud));
                }
            }
            if(mp.rollNumber)
            {
                this.rollNumber = new RollNumber(mp.rollNumber);
            }

            this.parcelLegalDescriptions = [];
            if(mp.parcelLegalDescriptions) {
                for(let cp of mp.parcelLegalDescriptions) {
                    this.parcelLegalDescriptions.push(new ParcelLegalDescription(cp));
                }
            }

            this.deposits = [];
            if(Array.isArray(mp.deposits) && mp.deposits.length > 0){
                this.deposits = mp.deposits.map(d => new Deposit(d));
            }

            if (mp.form54) {
                this.form54 = new Form54(mp.form54);
            }
        }
        else {
            this.condominiumPlans = [];
            this.importedParcelRegisterIds = [];
            this.condominiumExpenses = [];
            this.parcelLegalDescriptions = [];
            this.deposits = [];
            this.farmlandEvidence = 'NOT_FARM_LAND';
            this.legalDescriptionSummary = '';
            this.propertyTaxesSummaryOption = false;
            this.addressOption = false;
            this.autoInsertTaxAdjustment = true;
            this.isPinValid = true;
            let uidStr = UUID.UUID();
            if(uidStr) {
                let repStr = uidStr.replace(/\D/g, '');
                this.clientSideIdentifier = this.toFixed(Number(repStr));
            }
        }

        if(this.isPinValid == null) {
            this.isPinValid = true;
        }

        if(this.soaPurchasePrice == null) {
            this.soaPurchasePrice = 0;
        }

        if(this.rollNumber == null) {
            this.rollNumber = RollNumber.createDefaultRollNumber();
        }


    }

    id : number;
    clientSideIdentifier : number;
    address : Address;
    purchaseIsOfCode : string;
    interestEstate : string;

    occupancyStatusDeclarationRequired : DpBooleanValue;

    // New Fields For Alberta
    specify:string;
    titleNumber : string;
    landTitleOfficeLocation:string;
    lincNumber : string;
    nonCondoLincNumber : string;
    isVendorToSupplyRPR:DpBooleanValue;
    rprApplicable:boolean;
    residentAssociation:string;
    annualFee : number;
    fiscalYearEndDate:string;
    instrumentNumber:string;
    purchaserShareAmount:number;
    block: string;
    lot : string;
    propertyDescriptionType:MatterPropertyDescriptionType;
    exceptionType:DpBooleanValue;
    exceptionTypeDescription:string;
    shortLegalDescription:string;
    fullLegalDescription:string; // for MB this is used as a backup for imported legal description summary

    separateTaxAdjustmentByRollNumber:boolean;
    matterTaxesByRollNumber:MatterTax[]; // as of Feb26 2020 this gets loaded on backend from this.matterTax and it is used on StatementAdjustmentDisplayBuilder to determine calculations => it needs to be in synch with this.matterTax
    autoInsertTaxAdjustment: boolean;


    //End of New Fields For Alberta

    //New Fields for SK
    //condominiumType : string;
    //condominiumPlanNumber: string;
    totalValueOfParcels: number;
    overrideValueOfParcels: boolean;
    condominiumFees: number;
    zoningMemorandum:string;
    rentalAgreements: string;
    titleRegistrationFee: number;
    mortgageRegistrationFee: number;
    occupancy:string;
    //End of new fields for SK

    //New Fields for MB
    farmlandEvidence : string;
    farmlandSection : string;
    parcelLegalDescriptions : ParcelLegalDescription[];
    landTransferTax : number;
    landTransferTaxRate : LandTransferTaxRate;
    legalDescriptionSummary : string;
    separateCommonElementFeeAdj: boolean;
    //End of new fields for MB

    //New Fields for NB
    surveyInCurrentOwnersName : string;
    changeInPropertyAfterSurvey : string;
    assessmentAccountNumberSummary : string;
    condoAssessmentAccountNumberSummary : string; //UI only
    nonCondoAssessmentAccountNumberSummary : string; //UI only
    assessedValue: number;
    landTransferTaxRateId: number;
    form54: Form54;

    //End of New Fields for NB

    //New Fields for NS
    heatType : string;
    hasPropertyMigrated : DpBooleanValue;
    recordingMortgage: number;
    recordingDeed: number;
    //End of new fields for NS

    registryOrLt : string;
    isTitleSearchPerformed : string;
    contact : Contact;
    surveyor : Surveyor;
    rollNumber : RollNumber;
    nonCondoRollNumber : string;
    rollNumberType : string;
    jurisdiction : Jurisdiction;
    registryOffice : RegistryOffice;
    registryOfficeName : string;
    surveyDate : string;
    pin : string;
    condoPinNumber : string;
    nonCondoPinNumber : string;
    city : string;
    purchasePrice : any;
    isCondominium : DpBooleanValue;
    isParcelOfTiedLand : DpBooleanValue;
    nameOfCondominiumPlan : string;
    percentageInterest : string;
    commonExpenses : Number;
    unitLevelPlan : string;
    condominiumJurisdiction : string;
    condominiumCorporationName : string;
    condominiumTotalExpenses : Number;
    condominiumTotalSharePercentage: Number;
    condominiumPlans : CondominiumPlan[];
    condominiumExpenses : CondominiumExpense[];
    partLot : string;
    onPlan : string;
    plan : string;
    beingPart : string;
    propertyTaxesSummary : string;
    matterPropertyCode : string;
    propertyTaxesSummaryOption : boolean;
    addressOption : boolean;
    isPinValid : boolean;
    depositAmount : number;
    multipleDeposit : string;
    payDepositOutOfTrust: string;
    adjustmentFormat : string;
    deposits : Deposit[];
    purchasePriceType : string;
    soaPurchasePrice : number;
    propertyTax : string;
    matterTax : MatterTax;
    newHomeWarranty : string;
    builderNumber : string;
    propertyNumber : string;
    mobileHomeBrand : string;
    mobileHomeModel : string;
    mobileHomeYear : string;
    mobileHomeSerial : string;
    mobileHomeRegistration : string;
    section501Information : string;
    titleInsurancePropertyType : string; // populated only when a title insurer is selected
    propertyType : PropertyTypeType; // only for NB and NS limited to 'RESIDENTIAL, COMMERCIAL, COMMERCIAL_RESIDENTIAL for now
    numOfUnits : string;
    titleInsuranceNumOfUnits : string;
    parcel : string;
    section : string;
    easementRightOfWay : string;
    municipality : string;
    lastTransferNumber : string;
    lastInstrumentNumber : string;
    isException : string;
    homeOwnerPolicyAmount: number;
    affidavitTobeSignedBy: string;
    byPassUnitValidation : boolean;
    autoCalculateCommonExpense : boolean;
    projectName: string; //For Project Sale Matter
    percentageShareOfTotalRealtyTaxes : string //For AB Project Sale Matter

    overrideDescription: boolean;
    descriptionOverriddenType: string;

    importedParcelRegisterIds : number[];
    lastImportedParcelRegisterId : number; // DPPMP-36118

    //interestToBenefitParcel, interestToBurndenParcel and subjectToCovenantsAndConditions for NB
    interestToBenefitParcel : DpBooleanValue;
    interestToBurndenParcel : DpBooleanValue;
    subjectToCovenantsAndConditions : DpBooleanValue;
    mortgageId:number;
    isNRDTTax : DpBooleanValue;
    nRDTTaxPercentage : string;
    nRDTTaxAssessedValue: number;
    nRDTTax: any;

    public static createDefaultMatterProperty() : MatterProperty {
        let propertyModel : MatterProperty = new MatterProperty();

        propertyModel.purchaseIsOfCode = 'QUESTION';
        propertyModel.interestEstate = 'FEE_SIMPLE';
        propertyModel.matterPropertyCode = 'QUESTION';
        propertyModel.registryOrLt = constValues.registryLt.landTitles;
        propertyModel.isTitleSearchPerformed = 'N/y';
        propertyModel.purchasePrice = 0;
        propertyModel.depositAmount = 0;
        propertyModel.isCondominium = 'N_y';
        propertyModel.isParcelOfTiedLand='N_y';
        propertyModel.occupancyStatusDeclarationRequired = 'N_y';
        propertyModel.commonExpenses=0;
        propertyModel.percentageInterest=ZERO_INTEREST;
        propertyModel.titleInsurancePropertyType = 'QUESTION';
        propertyModel.numOfUnits = '';
        propertyModel.titleInsuranceNumOfUnits = '';
        propertyModel.isNRDTTax = 'N_y';
        let address = new Address();
        address.addressTypeCode = 'MAILING';
        address.primaryAddress = true;
        address.isAddressValid = true;

        propertyModel.address = address;
        propertyModel.autoInsertTaxAdjustment= true;

        propertyModel.rollNumber = RollNumber.createDefaultRollNumber();

        propertyModel.heatType = 'GAS';
        propertyModel.hasPropertyMigrated = 'N_y';

        propertyModel.interestToBenefitParcel = 'N_y';
        propertyModel.interestToBurndenParcel = 'N_y';
        propertyModel.subjectToCovenantsAndConditions = 'N_y';
        propertyModel.purchasePriceType =  'SALE_PRICE_AS_IN_SOA';
        propertyModel.nRDTTaxPercentage = '100.00';
        return propertyModel;
    }

    removeNonOccupancyDeposits(): void {
        this.deposits = this.deposits.filter(d => d.paidOnOccupancy);
    }

    removeAllDepositsIncludingOccupancy(): void {
        this.deposits = [];
    }

    get depositsExcludePayOnOccupancy(): Deposit[]{
        if(Array.isArray(this.deposits) && this.deposits.length > 0){
            return this.deposits.filter(d => !d.paidOnOccupancy);
        }
        return [];
    }

    get occupancyDeposit(): Deposit {
        return this.deposits && this.deposits.find(d => d.paidOnOccupancy);
    }

    calculateTotalExpenses() : void {
        this.condominiumTotalExpenses = 0;
        for(let i = 0; i < this.condominiumExpenses.length; i++) {
            if(this.condominiumExpenses[i].condominiumExpense) {
                this.condominiumTotalExpenses = Number(this.condominiumTotalExpenses) + Number(this.condominiumExpenses[i].condominiumExpense);
            }
        }
        this.condominiumTotalExpenses = Number(this.condominiumTotalExpenses.toFixed(2));
    }

    calculateTotalShare() : void {
        this.condominiumTotalSharePercentage = 0;
        for(let i = 0; i < this.condominiumExpenses.length; i++) {
            if(this.condominiumExpenses[i].sharePercentage) {
                this.condominiumTotalSharePercentage = Number(this.condominiumTotalSharePercentage) + Number(this.condominiumExpenses[i].sharePercentage);
            }
        }
        this.condominiumTotalSharePercentage = Number(this.condominiumTotalSharePercentage.toFixed(9));
    }


    removeCondominiumPlan(condominiumPlan : CondominiumPlan) : void {
        if(condominiumPlan) {
            this.condominiumExpenses
                .filter(item => item.condominiumPlan && item.condominiumPlan.identifier == condominiumPlan.identifier)
                .forEach(condoExpense => {condoExpense.condominiumPlan = undefined; condoExpense.planNumber = undefined;});

            let condominiumPlanIndex: number = _.findIndex(this.condominiumPlans, condoPlan => condoPlan === condominiumPlan);
            this.condominiumPlans.splice(condominiumPlanIndex, 1);
        }
    }

    removeCondominiumExpense(condominiumExpense : CondominiumExpense) : void {
        let condominiumExpensesIndex : number = _.findIndex(this.condominiumExpenses, condominiumExpensesObj => condominiumExpensesObj === condominiumExpense);
        this.condominiumExpenses.splice(condominiumExpensesIndex, 1);
    }

    isCondominiumPlanEmpty() : boolean {
        return (!this.condominiumPlans || this.condominiumPlans.length == 0);
    }

    isCondominiumPlanExisting(condominiumPlan : CondominiumPlan) : boolean {
        let selectedCondominiumPlanList : CondominiumPlan[] = _.filter(this.condominiumPlans, condominiumPlansObj => condominiumPlansObj === condominiumPlan);
        return selectedCondominiumPlanList.length > 0

    }

    isCondominiumExpenseEmpty() : boolean {
        return (!this.condominiumExpenses || this.condominiumExpenses.length == 0);
    }

    isPropertyCondominium() : boolean {
        return (this.isCondominium == 'YES');
    }

    isPropertyParcelOfTiedLand() : boolean {
        return (this.isParcelOfTiedLand == 'YES');
    }

    isCondoCleanWorkAsSave() {
        this.partLot = '';
        this.plan = '';
        this.beingPart = '';
        this.onPlan = '';
        this.nonCondoPinNumber = null;
        this.nonCondoAssessmentAccountNumberSummary = null;
    }

    isNotCondoCleanWorkAsSave() {
        this.condominiumExpenses = [];
        this.condominiumJurisdiction = null;
        this.condominiumCorporationName = null;
        this.condominiumPlans = [];
        this.condominiumTotalExpenses = 0;
        this.unitLevelPlan = '';
        this.condoPinNumber = null;
        this.condoAssessmentAccountNumberSummary = null;
    }


    isEmptyAdditionalMatterProperty() : boolean {

        if(!this.isCondominium || this.isCondominium == null) {
            return ((!this.pin || this.pin == null || this.pin.toString().trim() == "")
            && (!this.propertyTaxesSummary || this.propertyTaxesSummary == null || this.propertyTaxesSummary.toString().trim() == "")
            && this.address.isEmpty);
        }
        else {
            return false;
        }
    }

    isPurchaserPriceTypeSOA() : boolean {
        return (this.purchasePriceType == 'SALE_PRICE_AS_IN_SOA')
    }
    getTippLabel(provinceCode : string) : string {
        let result: string;1
        result = provinceBasedFieldLabels.get('matter.soa.property.taxes.tippOrTipps', provinceCode);
        if (result) {
            if (this.matterTax.hasPaidTaxesUnderTIPP == DpBooleanValueTypes.YES) {
                result =  result + '/';
            } else {
                result = 'not ' + result + '/';
            }
        }
        return result;
    }

    updatePropertyTaxes(propertyTax : MatterTax , formattedPropertyTax : string , amountPaidFromTrustAccount ? :string, provinceCode ?: string) : void {
        let tippLabel : string = '';
        if(propertyTax){
            this.matterTax = propertyTax;
            if (provinceCode){
                tippLabel = this.getTippLabel(provinceCode);
            }
            if(formattedPropertyTax){
                this.propertyTax = tippLabel + formattedPropertyTax;
            }
        }
        this.updateMatterTaxesByRollNumberFromPropertyTaxes();
    }

    // mapping entities on backend does this: matterPropertyDto.getMatterTaxesByRollNumber().add(matterPropertyDto.getMatterTax());
    updateMatterTaxesByRollNumberFromPropertyTaxes(): void{
        if (Array.isArray(this.matterTaxesByRollNumber) && this.matterTaxesByRollNumber.length > 0){
            this.matterTaxesByRollNumber = this.matterTaxesByRollNumber.map(
                    (mtbrn: MatterTax) => mtbrn.id === this.matterTax.id ? new MatterTax(this.matterTax) : mtbrn
            );
        }
    }
/*
    updatePropertyTaxes(propertyTax : MatterTax , formattedPropertyTax : string , amountPaidFromTrustAccount ? :string, provinceCode ?: string) : void {
        let tippLabel = 'TIPP';
        if(provinceCode && provinceCode == 'SK'){
            tippLabel = 'TIPPS';
        }
        if(propertyTax)
        {
            this.matterTax = propertyTax;
            if(this.matterTax.hasPaidTaxesUnderTIPP){
                if(this.matterTax.hasPaidTaxesUnderTIPP == DpBooleanValueTypes.N_y){
                    this.propertyTax = '???/'+ formattedPropertyTax;
                }else if(this.matterTax.hasPaidTaxesUnderTIPP == DpBooleanValueTypes.NO){
                    this.propertyTax = tippLabel+'/'+ formattedPropertyTax;
                }else{
                    this.propertyTax = tippLabel+'/'+ formattedPropertyTax;
                }
                if(this.matterTax.payOutOfTrustAccount == 'YES' && amountPaidFromTrustAccount){
                    this.propertyTax = this.propertyTax + '/'+amountPaidFromTrustAccount;
                }
            }else{
                this.propertyTax = formattedPropertyTax;
            }

        }
    }
*/

    resetPropertyTaxes() : void {
        this.matterTax = undefined;
        this.propertyTax = undefined;
    }

    resetPropertyTaxesByRollNumber() : void {
        this.matterTaxesByRollNumber = [];
        this.propertyTax ='';
        this.matterTax = undefined;
    }


    get depositNameAvailableDeposits() : Deposit[] {
       return this.deposits && this.deposits.length > 0 ? this.deposits.filter(item => item.depositName && item.depositName.trim() != '' && !item.paidOnOccupancy) : [];
    }

    get depositsPaidOnOccupancy(): Deposit[] {
        return this.deposits && this.deposits.length > 0 ? this.deposits.filter(item => item.depositName && item.depositName.trim() != '' && item.paidOnOccupancy) : [];
    }

    isDepositOneItemizedAdjustment() : boolean {
        return (this.adjustmentFormat == 'ONE_ITEM_ADJ');
    }

    isPurchaseTypeVacantLand() : boolean {
        return (this.purchaseIsOfCode == 'VACANT_LAND');
    }

    isPurchaseTypeNewBuilderHome() : boolean {
        return (this.purchaseIsOfCode == 'NEW_BUILDER_HOME');
    }

    isPurchaseTypeResaleHome() : boolean {
        return (this.purchaseIsOfCode == 'RESALE_HOME');
    }

    isPurchaseTypeFarmLand() : boolean {
        return (this.purchaseIsOfCode == 'FARM_LAND');
    }

    isPurchaseTypeMobileHome() : boolean {
        return (this.purchaseIsOfCode == 'MOBILE_HOME');
    }

    isSalePriceInSoa() : boolean {
        return (!this.purchasePriceType || this.purchasePriceType == 'SALE_PRICE_AS_IN_SOA');
    }

    get concatenatedRollNumber() : string {
        return this.rollNumber ? _.filter([this.rollNumber.city, this.rollNumber.municipality, this.rollNumber.map, this.rollNumber.subdivision,
                                           this.rollNumber.parcel, this.rollNumber.portion, this.rollNumber.jurisdiction]).join(" ") : "";
    }

    get formattedPin(): string {
        let formattedPin : string;
        if(this.pin) {
            formattedPin  = this.pin.replace("-", "");
        }
        if (formattedPin && formattedPin.length >= 5) {
            formattedPin = formattedPin.slice(0, 5) + '-' + formattedPin.slice(5);
        }
        return formattedPin;
    }

    isPropertyTypeMultiUnit() : boolean{
        return (this.propertyType == 'RESIDENTIAL' || this.propertyType == 'COMMERCIAL_RESIDENTIAL');
    }

    isTitleInsurancePropertyTypeMultiUnit() : boolean{
        return (this.titleInsurancePropertyType == 'RESIDENTIAL_MULTI_UNIT' || this.titleInsurancePropertyType == 'MULTI_FAMILY_UNITS'
            || this.titleInsurancePropertyType == 'ROOMING_STUDENT_HOUSING' );
    }

    isPropertyTypeRoomingHouse() : boolean{
        return this.titleInsurancePropertyType == 'ROOMING_STUDENT_HOUSING';
    }

    isPurchaseCommercial() : boolean {
        return (this.purchaseIsOfCode == 'COMMERCIAL_PROPERTY')
    }

    isPurchaseIsOfCodeBlank() : boolean{
        return !this.purchaseIsOfCode || this.purchaseIsOfCode == 'QUESTION';
    }

    get interestEstateLabel() : string {
       let dropDownObj = dropDowns.interestEstate.find(item => item.value == this.interestEstate);
       return dropDownObj ? dropDownObj.label : '';
    }

    isUnitLevelPlanExpection() : boolean {
        return this.isException == 'YES';
    }

    isPlanBlockLot() : boolean {
        return this.propertyDescriptionType == 'PLAN_BLOCK_LOT';
    }

    isMetesAndBound() : boolean {
        return this.propertyDescriptionType == 'METES_AND_BOUNDS';
    }

    isExceptionForCondo() : boolean {
        return this.exceptionType == 'YES';
    }

    isCondoPropertyTypeResidentialTownHouse() : boolean{
        return (this.isCondominium && this.titleInsurancePropertyType=='RESIDENTIAL_CONDO_TOWNHOUSE');
    }


    isTitleInsuranceBlanketPropertyCondo() : boolean{
        return (this.titleInsurancePropertyType=='RESIDENTIAL_CONDO' || this.titleInsurancePropertyType=='RESIDENTIAL_CONDO_TOWNHOUSE');
    }

    isCondoPropertyTypeResidentialTownHouseVacantLand() : boolean{
        return (this.isCondominium && this.titleInsurancePropertyType=='RESIDENTIAL_VACANT_LAND_CONDO');
    }

    isCondoTypePlanConventional() : boolean {
        return (this.isCondominium && this.condominiumType=='CONVENTIONAL_CONDOMINIUM_PLAN');
    }

    get parcelTextDescription() : string {
        return this.isCondoTypePlanConventional() ? 'Condo Plan #'+ (this.condominiumPlanNumber ? this.condominiumPlanNumber :'' ): 'Bare Land Condo Plan #'+(this.condominiumPlanNumber ? this.condominiumPlanNumber :'' );
    }


    get allParcelTitlesCnt(): number{
        let totalTitles: number = 0;
        if(Array.isArray(this.parcelLegalDescriptions) && this.parcelLegalDescriptions.length > 0){
            this.parcelLegalDescriptions.forEach(parcel => {
                totalTitles += Array.isArray(parcel.parcelTitles) ? parcel.parcelTitles.length : 0;
            })
        }
        return totalTitles;
    }

    updatePurchasePrice(price: number, matter: Matter){
        if(price){
            this.purchasePrice = price;
            if(matter){
               matter.updateTitleRegistrationFee();
            }
        }
    }

    formatLegalDescriptionSummary(): void{
        if (this.overrideDescription){
            return; // don't override user's changes
        }
        if (this.isPropertyCondominium()) {
            if (this.importedParcelRegisterIds && this.importedParcelRegisterIds.length > 0){
                this.legalDescriptionSummary = this.fullLegalDescription; // revert to what was imported already and saved into fullLegalDescription at the moment when import got completed
            }
        }else{
            this.legalDescriptionSummary = '';
            if (this.parcelLegalDescriptions && this.parcelLegalDescriptions.length) {
                if(this.parcelLegalDescriptions.length == 1){
                    this.legalDescriptionSummary = `${this.parcelLegalDescriptions[0].memo ? this.parcelLegalDescriptions[0].memo + '\n\n' : ''}`;
                } else {
                    this.parcelLegalDescriptions.forEach((titleRollNumber, index) => {
                        this.legalDescriptionSummary += `PARCEL ${SharedUtils.numberToWord(index + 1)} (TITLE NO. ${titleRollNumber.parcelNumber}):\n\n${titleRollNumber.memo ? titleRollNumber.memo + '\n\n' : ''}`;
                    });
                }
            }
        }
    }

    /*
    updateLegalDescriptionSummary(condoCorporation : MatterParticipant) : void {
        this.legalDescriptionSummary = '';
        this.parcelLegalDescriptions.forEach((titleRollNumber)=>{
            this.legalDescriptionSummary += `UNIT ${titleRollNumber.unitNumber? titleRollNumber.unitNumber : ''}` +
            ` CONDOMINIUM PLAN ${this.condominiumPlanNumber ? this.condominiumPlanNumber :  ''} ${this.registryOfficeName? this.getInitials(this.registryOfficeName) : ''} TOGETHER WITH AN UNDIVIDED\n` +
            `${titleRollNumber.percentageInterest}% INTEREST IN THE COMMON ELEMENTS AS APPURTENANT THERETO\n` +
            `SUBJECT TO ALL ENTRIES SET OUT ON THE TITLE OF\n` +
            `${condoCorporation && condoCorporation.contact ? condoCorporation.contact.organizationName : ''}\n\n`;
        });
        this.legalDescriptionSummary = this.legalDescriptionSummary.toUpperCase();
        return this.legalDescriptionSummary;
    }*/

    getInitials(txt: string) : string {
        return txt && txt.split(" ").map(str=>str[0]).join('');
    }

    get condominiumType(){
        return this.condominiumPlan().condominiumPlanType
    }
    set condominiumType(condominiumType : string){
        let condominiumPlan = this.condominiumPlan();
        condominiumPlan.condominiumPlanType = condominiumType;

    }

    get condominiumPlanNumber(){
        return this.condominiumPlan().condominiumPlanNumber
    }

    set condominiumPlanNumber(condominiumPlanNumber : string){
        let condominiumPlan = this.condominiumPlan();
        condominiumPlan.condominiumPlanNumber = condominiumPlanNumber;
    }

    condominiumPlan() : CondominiumPlan {
        if(!this.condominiumPlans || this.condominiumPlans.length === 0){
            let condominiumPlan = new CondominiumPlan();
            this.condominiumPlans.push(condominiumPlan);
            return condominiumPlan;
        }else{
            return this.condominiumPlans[0];
        }

    }

    getNewTitleRollNumberOrder(): number {
        return this.parcelLegalDescriptions && this.parcelLegalDescriptions.length ? this.parcelLegalDescriptions.length + 1 : 1;
    }

    updateTitleRollNumber(matter: Matter, addNewTitleRollNumberRequest: boolean, oldParcelLegalDescription: ParcelLegalDescription, newParcelLegalDescription: ParcelLegalDescription, linkedMatterTaxToBeRemoved: number[], added: number) : boolean{
        let retAddNewTitleRollNumberRequest : boolean = addNewTitleRollNumberRequest;
        if (oldParcelLegalDescription) {
            this.updateMBTitleRollNumber(matter, oldParcelLegalDescription, newParcelLegalDescription, linkedMatterTaxToBeRemoved, added);
            let index = this.parcelLegalDescriptions.findIndex(item => item == oldParcelLegalDescription);
            if (index > -1) {
                this.parcelLegalDescriptions[index] = newParcelLegalDescription;
            }

        } else {
            if (!this.parcelLegalDescriptions) {
                this.parcelLegalDescriptions = [];
            }
            this.parcelLegalDescriptions.push(newParcelLegalDescription);
            retAddNewTitleRollNumberRequest = false;
        }
        this.createUpdateMBAdjustmentCommonExp(matter);
        this.formatLegalDescriptionSummary();
        return retAddNewTitleRollNumberRequest;
    }

    updateMBTitleRollNumber(matter: Matter, oldParcelLegalDescription: ParcelLegalDescription, newParcelLegalDescription: ParcelLegalDescription, linkedMatterTaxToBeRemoved: number[], added: number): void {
        if (matter && matter.matterPropertyWithCondo && matter.isMatterProvinceMB && oldParcelLegalDescription && newParcelLegalDescription) {
            if (oldParcelLegalDescription.rollNumbers && newParcelLegalDescription.rollNumbers) {
                if (added > 0) {
                    let nextParcelLegalDescriptionIdx = this.parcelLegalDescriptions.findIndex((parcelLegalDescription) => parcelLegalDescription.id === oldParcelLegalDescription.id) + 1;
                    if (nextParcelLegalDescriptionIdx < this.parcelLegalDescriptions.length) {

                        let rolNoOrder: number = ParcelLegalDescription.getTitleRollNumberOrder(this.parcelLegalDescriptions, this.parcelLegalDescriptions[nextParcelLegalDescriptionIdx].rollNumbers[0], this.parcelLegalDescriptions[nextParcelLegalDescriptionIdx]);

                        let matterTaxes: MatterTax[] = matter.matterPropertyWithCondo.matterTaxesByRollNumber.filter(item => item.rollNumberOrder >= rolNoOrder);
                        if (matterTaxes && matterTaxes.length > 0) {
                            matterTaxes.forEach(matterPropertyTax => matterPropertyTax.rollNumberOrder = matterPropertyTax.rollNumberOrder + added);
                        }
                        // fill in the gap for newly added items (always at the end of parcelDescription.rollNumbers)
                        for(let i=0; i < added; i++){
                            let matterTax = new MatterTax();
                            matterTax.initializeForRollNumber(null, matter.provinceCode, matter.getClosingDate());
                            matterTax.rollNumberOrder = rolNoOrder + i;
                            matter.matterPropertyWithCondo.matterTaxesByRollNumber.push(matterTax);
                        }
                    }
                }
                if(linkedMatterTaxToBeRemoved){
                    linkedMatterTaxToBeRemoved.forEach( id => {
                        let matterTaxIdx = matter.matterPropertyWithCondo.matterTaxesByRollNumber.findIndex(matterTAx => matterTAx.id == id);
                        if (matterTaxIdx > -1) {
                            matter.matterPropertyWithCondo.matterTaxesByRollNumber.splice(matterTaxIdx, 1);
                            let matterTax: MatterTax = matter.matterPropertyWithCondo.matterTaxesByRollNumber[matterTaxIdx];
                            if (matterTax) {
                                matter.matterPropertyWithCondo.matterTaxesByRollNumber
                                    .filter(matterPropertyTax => matterPropertyTax.rollNumberOrder >= matterTax.rollNumberOrder)
                                    .forEach(matterPropertyTax => matterPropertyTax.rollNumberOrder = matterPropertyTax.rollNumberOrder - 1);
                            }
                        }
                    });
                }
                matter.removeDeprecatedAdjustmentsPropertyTaxByRollNumber();
            }
        }
    }

    createUpdateMBAdjustmentCommonExp(matter : Matter): void {
        if (this.separateCommonElementFeeAdj) {
            this.calcCondominiumFeesFromTitleRollNumbers();
            if(this.parcelLegalDescriptions){
                this.parcelLegalDescriptions.forEach((item) => {
                    matter.createUpdateAdjustmentCommonExp(item);
                });
            }

        } else {
            matter.createUpdateAdjustmentCommonExp();
        }
    }

    deleteRollNumber = (titleRollNumber: ParcelLegalDescription, matter : Matter) => {
        this.removeRollNumberMatterTax(matter, titleRollNumber);
        (<any>this.parcelLegalDescriptions).remove(titleRollNumber);
        this.formatLegalDescriptionSummary();
        if (this.separateCommonElementFeeAdj) {
            this.calcCondominiumFeesFromTitleRollNumbers();
            let commonExpensestatementOfAdjustment = matter.statementOfAdjustments.find(item => item.parcelLegalDescriptionId == titleRollNumber.id);
            if (commonExpensestatementOfAdjustment) {
                (<any>matter.statementOfAdjustments).remove(commonExpensestatementOfAdjustment);
            }
            if (this.parcelLegalDescriptions.length == 1) {
                this.separateCommonElementFeeAdj = false;
            }
        } else {
            matter.createUpdateAdjustmentCommonExp();
        }
    };

    removeRollNumberMatterTax(matter: Matter, parcelLegalDescription: ParcelLegalDescription): void {
        matter.removeDeprecatedAdjustmentsPropertyTaxByRollNumber();
        let startNumberOrder = ParcelLegalDescription.getTitleRollNumberOrder(matter.matterPropertyWithCondo.parcelLegalDescriptions, parcelLegalDescription.rollNumbers[0], parcelLegalDescription);
        let endNumberOrder = ParcelLegalDescription.getTitleRollNumberOrder(matter.matterPropertyWithCondo.parcelLegalDescriptions, parcelLegalDescription.rollNumbers[parcelLegalDescription.rollNumbers.length -1], parcelLegalDescription);
        for (let i: number = startNumberOrder; i <= endNumberOrder; i++) {
            matter.removeMatterTaxeByRollNumber(i);
        }
        let matterTaxes: MatterTax[] = matter.matterPropertyWithCondo.matterTaxesByRollNumber.filter(item => item.rollNumberOrder > endNumberOrder);
        if (matterTaxes && matterTaxes.length > 0) {
            matterTaxes.forEach(matterPropertyTax => matterPropertyTax.rollNumberOrder = matterPropertyTax.rollNumberOrder - (endNumberOrder - startNumberOrder + 1));
        }
        //matter.resetSeparateTaxAdjustmentByRollNumber();
    }

    isAllowedToAddParcelDescription() : boolean {
        return !this.parcelLegalDescriptions || this.parcelLegalDescriptions.length < 100;
    }

    calcCondominiumFeesFromTitleRollNumbers() : Number {
        this.condominiumFees = 0;
        if(this.isPropertyCondominium() && this.parcelLegalDescriptions && this.parcelLegalDescriptions.length){
            this.parcelLegalDescriptions.forEach((titleRollNumber)=>{
                this.condominiumFees += Number(titleRollNumber.commonElementFees? titleRollNumber.commonElementFees : 0);
            });
        }
        return this.condominiumFees;
    }

    isLegalDescriptionApplyLegalDescEasement() : boolean {
        return this.descriptionOverriddenType == 'APPLY_LEGAL_DESC_AND_EASEMENT';
    }
    isLegalDescriptionOverrideFull() : boolean {
        return this.descriptionOverriddenType == 'PROVIDE_ENTIRE_LEGAL_DESC';
    }
    isLegalDescriptionOverrideAppendedBelow() : boolean {
        return this.descriptionOverriddenType == 'PROVIDE_EASEMENT_TO_BE_APPENDED';
    }
    isLegalDescriptionOverrideAppendedAbove() : boolean {
        return this.descriptionOverriddenType == 'EASEMENT_APPENDED_TO_PROVIDED_INFO';
    }
    isLegalDescriptionOverrideAppendedInBetween() : boolean {
        return this.descriptionOverriddenType == 'INSERT_PROVIDED_EASEMENT_IN_BETWEEN';
    }

    getROfWCovenants(): string{
        const parts: string[] = [this.interestToBenefitParcel == DpBooleanValueTypes.YES ? 'Interest to Benefit' : null,
            this.interestToBurndenParcel == DpBooleanValueTypes.YES ? 'Interest to Burden' : null,
            this.subjectToCovenantsAndConditions == DpBooleanValueTypes.YES ? 'Covenants' : null];
        const retString = parts.filter(Boolean).join(" + ");
        return retString;
    }

    get depositsWithoutNSForNS() : Deposit[] {
        return this.deposits ? this.deposits.filter(item => !item.isNSF() && !item.isNH()) : [];
    }
    get depositsWithoutNSForNSorDepositOnOcc() : Deposit[] {
        return this.deposits ? this.deposits.filter(item => !item.isNSF() && !item.isNH() && !item.paidOnOccupancy) : [];
    }

    get isPayDepositOutOfTrust(): boolean{
        return this.payDepositOutOfTrust == DpBooleanValueTypes.YES;
    }

    get isResaleHome(): boolean {
        return this.purchaseIsOfCode === 'RESALE_HOME';
    }

    get isNewHomeFromBuilder(): boolean {
        return this.purchaseIsOfCode === 'NEW_BUILDER_HOME';
    }

    get isVacantLand(): boolean {
        return this.purchaseIsOfCode === 'VACANT_LAND';
    }

    get isOther(): boolean {
        return this.purchaseIsOfCode === 'OTHER';
    }

    get isCommercialProperty(): boolean{
        return this.purchaseIsOfCode === 'COMMERCIAL_PROPERTY';
    }

    get isFarmLand(): boolean{
        return this.purchaseIsOfCode === 'FARM_LAND';
    }

    get isMobileHome(): boolean{
        return this.purchaseIsOfCode === 'MOBILE_HOME';
    }
    get isDepositAdjustmentSingle(): boolean{
        return this.multipleDeposit == 'NO';
    }

    get isRegistryOrLtIsLandTitle():boolean {
        return this && this.registryOrLt == 'LANDTITLES';
    }

    calculateNRDTTax() {
        let mainCalValue : any = this.purchasePrice;
        let nRDTTaxPercentage : any = ZERO_INTEREST;
        let fix5Percent : number = 5;

        if(this.isNRDTTax === 'YES'){
            if(this.nRDTTaxAssessedValue < Number(this.purchasePrice)){
                mainCalValue = this.purchasePrice;
            }
            else if(this.nRDTTaxAssessedValue > Number(this.purchasePrice)){
                mainCalValue = this.nRDTTaxAssessedValue;
            }

            nRDTTaxPercentage =   Number(this.nRDTTaxPercentage)/100;

            fix5Percent = fix5Percent/100;
            this.nRDTTax = Number(mainCalValue) * fix5Percent * nRDTTaxPercentage;
            this.nRDTTax = Utils.roundCurrency(this.nRDTTax);
        }
    }

}
