import {ConfigurationOptionValue} from './configuration-option-value';

//This class contains the configuration options for one configuration type
export class Configuration {
    constructor(configuration: Configuration) {
        this.configurationType = configuration.configurationType;

        if(configuration.configurationOptionValues) {
            for(let configurationOptionValue of configuration.configurationOptionValues) {
                this.configurationOptionValues.push(new ConfigurationOptionValue(configurationOptionValue));
            }
        }
    }
    configurationType: string;
    configurationOptionValues: Array<ConfigurationOptionValue> = [];

    getConfigurationValueByProperty(propertyName: string): ConfigurationOptionValue {
        if(this.configurationOptionValues.length > 0) {
            for(let configurationOptionValue of this.configurationOptionValues) {
                if(configurationOptionValue.configurationOption.fieldName == propertyName) {
                    return configurationOptionValue;
                }
            }
        }
        return null;
    }
}
