import {MatterTitleInsurance} from '../../matters/shared/matter-title-insurance';
import {TitlePlusValidationResponse} from './title-plus-validation-response';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class TitlePlusDealOrderStatusResponseDto extends BaseEntity {
    titlePlusDealOrderStatusResponse: TitlePlusDealOrderStatusResponse;
    matterTitleInsurance: MatterTitleInsurance;
    status: string;
    statusMessage: string;
}

export class TitlePlusDealOrderStatusResponse {
    validationResult : TitlePlusValidationResponse;
}
