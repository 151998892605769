import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../core';
import {Contact, matterApi, matterResponseKey} from '../matters/shared';
import {api} from '../common/api';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {Jurisdiction} from '../matters/shared/jurisdiction';
import {Subject} from 'rxjs/Subject';
import {ApplicationError} from '../core/application-error';
import {DPError} from '../shared/error-handling/dp-error';
import {ErrorService} from '../shared/error-handling/error-service';
import {GlobalLogger, LogLevelTypes} from '../core/global-logger';


@Injectable()
export class ContactQueryService {

    //This list keeps the http request handlers by contact Id. It is used to sequence the http requests for a contact.
    private contactRequestHandlers : Array<ContactRequestHandler> = [];

    constructor(private httpClient : HttpClient,
                public globalLogger : GlobalLogger,
                private errorService : ErrorService) { }
    getContactForOpeningTab(contactId : number, subContact? : Contact, subRoute? : string) : Observable<any> {
        let url = `${matterApi.contacts}/${contactId}`;

        return this.invokeContactRequestSequentially(contactId, url)
                   .map((contact : Contact) => {
                       //Now UI use "localGender" to show gender information , Backend uses "gender" to pass gender information
                       contact.localGender = contact.gender;
                       //this.updateContactLockFlag(contact);
                       return {
                           contact    : contact,
                           subContact : subContact,
                           subRoute   : subRoute
                       };
                   });
    }

    unlockContactHierarchy(id : number) : Observable<Contact> {
        return this.invokeContactRequestSequentially(id, `${matterApi.contacts}/${id}/unlock?fullUnlock=true`);
    }

    unlockContact(id : number) : Observable<Contact> {
        return this.invokeContactRequestSequentially(id, `${matterApi.contacts}/${id}/unlock?fullUnlock=false`);
    }

    //This method invokes all the contact requests through common request handler and makes sure they are called in a sequential order.
    invokeContactRequestSequentially(id : number, url : string, knownDisplayName? : string, hideContactNotFoundError? : boolean) : Observable<Contact> {
        // console.log( url +  new Date().getTime());

        if (!id) {
            let stackTrace: string = JSON.stringify(new Error().stack);
            this.globalLogger.log(LogLevelTypes.WARN,'ContactQueryService NULL_CONTACT_ID_REQUEST invoked with invalid id: ' + id, stackTrace);
            return Observable.of(null);
        } else {
            let contactRequestHandler = this.contactRequestHandlers.find(value => value.contactId === id);
            if(!contactRequestHandler) {
                contactRequestHandler = new ContactRequestHandler();
                contactRequestHandler.httpClient = this.httpClient;
                contactRequestHandler.contactId = id;
                contactRequestHandler.knownDisplayName = knownDisplayName;
                this.contactRequestHandlers.push(contactRequestHandler);
            }
            contactRequestHandler.hideContactNotFoundError = hideContactNotFoundError;
            return contactRequestHandler.submitRequest(url, this.errorService);
        }


    }


    getContactForMatter(id : number, knownDisplayName? : string, hideContactNotFoundError?: boolean) : Observable<Contact> {
        let url: string = matterApi.contactForMatter(id);
        return this.invokeContactRequestSequentially(id, url, knownDisplayName, hideContactNotFoundError).map((contact : Contact) => {
            //Now UI use "localGender" to show gender information , Backend uses "gender" to pass gender information
            contact.localGender = contact.gender;
            return contact;
        });
    }

    getOtherSideLawClerkById(id : number) : Observable<Contact> {
        let url: string = matterApi.otherSideLawClerkContact(id);
        return this.httpClient
                .get(url)
                .map((res ) => {
                    return new Contact(res[matterResponseKey.contact]);
                });
    }

    // getContactForNewAdjudication(id : number, subContact? : Contact, subRoute? : string) : Observable<any> {
    //     let url: string = adjudicationApi.contactForAdjudication(id);
    //     return this.httpClient.get(url).map((res ) => {
    //         //Now UI use "localGender" to show gender information , Backend uses "gender" to pass gender
    //         const contact : Contact = new Contact(res[matterResponseKey.contact]);
    //         contact.localGender = contact.gender;
    //         return {
    //             contact    : contact,
    //             subContact : subContact,
    //             subRoute   : subRoute
    //         };
    //     });
    //
    // }

    //Note: This method should not be used when fetching a contact inside a matter, as this method locks the contact. Contact should not be locked when it
    // is used in a matter therefore use the other method "getContactForMatter" that doesn't lock it.
    getContact(id : number, knownDisplayName? : string) : Observable<Contact> {
        return this.invokeContactRequestSequentially(id, `${matterApi.contacts}/${id}?partialLock=true`, knownDisplayName).map((contact : Contact) => {
            //Now UI use "localGender" to show gender information , Backend uses "gender" to pass gender information
            contact.localGender = contact.gender;
            return contact;
        });
    }

    getGlobalContact(id : number): Observable<Contact> {

        let url: string = `${api}/contacts/${id}/globalContact`;

        return this.httpClient
                   .get(url)
                   .map((res ) => {
                       return new Contact(res[matterResponseKey.contact]);
                   });
    }

    createContact(contact : Contact, accountId? : string, skipFieldsDataError: boolean = false) : Observable<Contact> {
        if(!accountId) {
            accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        }
        let url = matterApi.createContacts.replace('*', accountId);
        return this.httpClient.post(url, JSON.stringify(contact), undefined, skipFieldsDataError)
                   .map((res ) => {
                       return new Contact(res[matterResponseKey.contact]);
                   });
    }

    createJurisdiction(jurisdiction : Jurisdiction) : Observable<any> {
        const url = matterApi.jurisdictions;
        return this.httpClient.post(url, JSON.stringify(jurisdiction))
                   .map((res ) => {
                       return new Jurisdiction(res[matterResponseKey.jurisdiction]);
                   });
    }

    updateJurisdiction(jurisdiction : Jurisdiction) : Observable<any> {
        const url = `${matterApi.jurisdictions}/${jurisdiction.id}`;
        return this.httpClient.put(url, JSON.stringify(jurisdiction))
                   .map((res ) => {
                       return new Jurisdiction(res[matterResponseKey.jurisdiction]);
                   });
    }

    getContactIdsHavingActiveUser(contactIds: number[] , includeLockedStatus? : boolean) : Observable<number[]> {
        if(Array.isArray(contactIds)) {
            let url = `${matterApi.filterByActiveUsers}?contactIds=` + contactIds.join(',');
            if(includeLockedStatus != undefined){
                url = url+`&includeLockedStatus=${includeLockedStatus}`;
            }
            return this.httpClient.get(url).map((res) => {
                let data : number[] = res["ContactIdsHavingActiveUser"];
                return data;
            })
        } else {
            return Observable.of(null);
        }

    }

}

/*
 The ContactRequestHandler is used to make contact related http calls. It makes sure that all the http calls are processed sequentially. As we can have
 same contact opened from multiple tabs and each tab sends multiple requests like get, lock, unlock etc. In case, if user opens the multiple tabs and flips
 between them randomly then multiple requests are sent to backend for the same contact and they are full-filled in random order which is causing some
 locking related issues. In order to fix it we are channeling all the contact https calls through this handler which will check if there is any other
 request in progress for same contact then it will queue the next one and execute it only after getting the response for first one.
 */
class ContactRequestHandler {
    contactId : number;
    knownDisplayName : string;
    hideContactNotFoundError: boolean;
    lastSubmittedRequest : ContactHttpRequest;
    httpClient : HttpClient;


    submitRequest(url : string, errorService : any) : Observable<Contact> {
        let newRequest = new ContactHttpRequest(url, errorService);
        console.log("Submitting new request url " + url + ", at timestamp " + newRequest.timeStamp + " and current pending request is " + this.lastSubmittedRequest);
        if(this.lastSubmittedRequest) {
            console.log("A prior request is in progress " + this.lastSubmittedRequest.timeStamp + " , queueing new request" + newRequest.timeStamp + " after" +
                        " that.");
            this.lastSubmittedRequest.delayedReturnNotifier.finally(() => {
                console.log("Previous request has finished so moving to the next submitted request " + newRequest.timeStamp);
                newRequest.execute(this);
            }).subscribe();
            this.lastSubmittedRequest = newRequest;
        } else {
            console.log("No prior request exists so submitting to backend directly for " + newRequest.timeStamp);
            newRequest.execute(this);
            console.log("Adding first element to the queue for request " + newRequest.timeStamp);
            this.lastSubmittedRequest = newRequest;
        }

        console.log("Returning new request " + newRequest.timeStamp);
        return this.lastSubmittedRequest.delayedReturnNotifier.asObservable();
    }
}

//This is the wrapper class for contact http request.
class ContactHttpRequest {
    private _url;
    private _errorService;
    private readonly _timeStamp : number;
    private readonly _delayedReturnNotifier : Subject<Contact>;

    constructor(url : string,
                errorService : ErrorService) {
        this._timeStamp = new Date().getMilliseconds();
        this._delayedReturnNotifier = new Subject<Contact>();
        this._url = url;
    this._errorService = errorService;
    }

    get timeStamp() : number {
        return this._timeStamp;
    }

    toString(): string {
        return `TimeStamp=${this._timeStamp};URL=${this._url}`;
    }

    /**
     * This is the observable that the handler returned to the calling code, which is returning the Contact after all the previous requests have been
     * completed
     * @returns {Subject<Contact>}
     */
    get delayedReturnNotifier() : Subject<Contact> {
        return this._delayedReturnNotifier;
    }

    execute(contactRequestHandler : ContactRequestHandler) : void {
        console.log("submitting new request to backend " + this.timeStamp);
        contactRequestHandler.httpClient.get(this._url).finally(() => {
            if(contactRequestHandler.lastSubmittedRequest == this) {
                console.log("This is the last pending request in the queue, so we nullify it (no pending after this stage): for " + this.timeStamp);
                contactRequestHandler.lastSubmittedRequest = null;
            }
        }).subscribe((res ) => {
                         console.log("response from backend for request " + this.timeStamp);
                         let contact : Contact = new Contact(res[matterResponseKey.contact]);
                         if(contactRequestHandler.lastSubmittedRequest == this) {
                             console.log("This is the last pending request in the queue, so we nullify it (no pending after this stage): for " + this.timeStamp);
                             contactRequestHandler.lastSubmittedRequest = null;
                         }
                         this.delayedReturnNotifier.next(contact);
                         this.delayedReturnNotifier.complete();
                         console.log("The current in-progress request has completed successfully for " + this.timeStamp);

                     },
                     (error) => {
                         console.log("The current in-progress request has failed for " + this.timeStamp);
                         this.handleApiErrors(error, this._errorService, contactRequestHandler.knownDisplayName, contactRequestHandler.hideContactNotFoundError);
                         this.delayedReturnNotifier.error(error);
                     }
        );

    }

    private handleApiErrors(error: ApplicationError, errorService : ErrorService, knownDisplayName? : string, hideContactNotFoundError?: boolean): void {
        if(error.errorCode === 'app.ContactNotFound') {
            if(!hideContactNotFoundError) {
                if(knownDisplayName) {
                        errorService.addDpSaveError(DPError.createCustomDPError(`app.contactNotFound."${knownDisplayName}"`,
                                                                                `The profile for "${knownDisplayName}" has been updated. Please remove and add the updated contact profile to continue.`, 'Error', 'ERROR'));
                }
                else {
                        errorService.addDpSaveError(DPError.createDPError('app.ContactNotFound'));
                }
            }
        }
    }
}

