import {Injectable} from '@angular/core';
import {HttpClient} from '../../core';
import {Account} from '../accounts/shared/account';
import {Observable} from 'rxjs';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {CustomMatterType} from './custom-matter-type';
import {customMatterTypeApi} from './custom-matter-type-config-api';

@Injectable()
export class CustomMatterTypeService {
    constructor(private http : HttpClient) { }

    getAllCustomMatterTypes(account? : Account) : Observable<CustomMatterType[]> {
        let accountId ='';
        if(!account) {
            accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
        }
        else{
            accountId = account.id.toString();
        }
        let url = customMatterTypeApi.getAllCustomMatterTypesApi.replace('{accountId}',accountId);
        return this.http.get(url)
                   .map((res) => {
                       let data = res['CustomMatterTypes'];
                       if(Array.isArray(data)) {
                           return data.map(item => {
                               return new CustomMatterType(item);
                           });
                       } else {
                           return [];
                       }
                   });
    }

    saveAllCustomMatterTypes(customMatterTypes : CustomMatterType[], account : Account) : Observable<CustomMatterType[]> {
        let url = customMatterTypeApi.saveAllCustomMatterTypesApi.replace('{accountId}', account.id.toString());
        return this.http.post(url, customMatterTypes)
                   .map((res) => {
                       let data = res['CustomMatterTypes'];
                       if(Array.isArray(data)) {
                           return data.map(item => {
                               return new CustomMatterType(item);
                           });
                       } else {
                           return [];
                       }
                   });
    }

    async getFilterdCustomMatterTypes(account? : Account): Promise<CustomMatterType[]> {
        let result : CustomMatterType[] = await this.getAllCustomMatterTypes(account).toPromise();
        if(result && result.length){
            return  result.filter( cmt => !cmt.reserved && !cmt.deleted); // do not show reserved and deleted ones
        }
    }
}
