export enum CustomKeyCodesEnum {
    Enter = 13,
    Escape = 27,
    Up = 38,
    Down = 40,
    Delete = 46,
    M = 77,
    P = 80,
    S = 83,
    Shift = 16,
    Ctrl = 17,
    Tab = 9,
    SpaceBar = 32 ,
    backspace = 8,
    F8 = 119,
    F9 = 120,
    PageUp = 33,
    PageDown = 34
}
