import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalF9HelpComponent} from './modal-f9-help.component';

@NgModule({
imports: [
CommonModule,
FormsModule,
ReactiveFormsModule,
],
declarations: [
    ModalF9HelpComponent,
],
exports: [
    ModalF9HelpComponent
],
providers: [
]
})
export class ModalF9HelpModule { }
