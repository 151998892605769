import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {Address} from '../address';
import {YesNoType} from '../../../shared-main/constants';
import {CirfUdf} from './cirf-udf';

export class CirfMatterContactInfo extends BaseEntity {
    clientCapacity: string;
    postClosingAddress : Address;
    preClosingAddress : Address;
    residingAtSubjectProperty : YesNoType;
    matterContactInfoUdfs : CirfUdf[] = [];

    constructor(cirfMatterContactInfo?: CirfMatterContactInfo) {
        super(cirfMatterContactInfo);
        if(cirfMatterContactInfo){
            if(cirfMatterContactInfo.preClosingAddress){
                this.preClosingAddress = new Address(cirfMatterContactInfo.preClosingAddress);
            }
            if(cirfMatterContactInfo.postClosingAddress){
                this.postClosingAddress = new Address(cirfMatterContactInfo.postClosingAddress);
            }

            if(Array.isArray(cirfMatterContactInfo.matterContactInfoUdfs)){
                this.matterContactInfoUdfs = cirfMatterContactInfo.matterContactInfoUdfs.map(cirfUdf => new CirfUdf(cirfUdf));
            }
        }
    }

    udfNotes(): string {
        let note = '';
        if (Array.isArray(this.matterContactInfoUdfs)) {
            this.matterContactInfoUdfs.forEach(udf => {
                note += udf.fieldValue ? `${udf.fieldLabel}: ${udf.fieldValue}\n` : '';
            });
        }
        return note;
    }


}
