import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class MatterCalculatedAmount extends BaseEntity {
    id : number;
    soaFeesTotal: number;
    soaFeesHst: number;
    soaHstDisbursementTotal: number;
    soaHstDisbursementHstAmount: number;
    soaNonHstDisbursementTotal: number;
    soaGrandTotal: number;
    soaBalanceOwing: number;
    soaTotalHst: number;
    brokerPercentageOfCommissionOn: number;
    vendorDeposit: number;
    purchaserDeposit: number;
}
