import {dropDowns} from './account-drop-downs';
import {CommissionPaidToType} from '../../../matters/shared/matter-specific-type';

export type ProvinceCode = 'ON' | 'AB' | 'BC' | 'MB' | 'NB' | 'NS' | 'SK';
export class AccountProvince {

    constructor(accountProvince? : AccountProvince) {
        if(accountProvince)
        {
            for (let prop in accountProvince) {
                if (accountProvince.hasOwnProperty(prop)) {
                    this[prop] = accountProvince[prop];
                }
            }
        }

    }

    id: number;
    provinceCode: ProvinceCode;
    saleCommissionPaidTo: CommissionPaidToType;
    enabled: boolean = false;

    jurisdictionId : Number;
    jurisdictionName : string;

    get provinceName() : string {
        let ProvinceOptions = dropDowns.accessibleProvinces;
        let provinceOption = ProvinceOptions.find(province => province.value === this.provinceCode);
        return provinceOption ? provinceOption.label : '';
    }

}
