import {BaseEntity} from '../shared/BaseEntity/base-entity';
import {UnityMessage} from './unity-message';
import {BurgerMenuExtendedItem} from '../matters/shared/burger-menu-extended-item';

export const SortEffectiveDate = 'effectiveDate';
export const SortMessageSubject = 'messageSubject';
export const SortCategory = 'category';

export class UserMessage extends BaseEntity {
    constructor(userMessage?: UserMessage) {
        super(userMessage);
        if(userMessage && userMessage.unityMessage)
        {
            this.unityMessage = new UnityMessage(userMessage.unityMessage)
        }

    }

    id : number;
    userId : string;
    unityMessage : UnityMessage;
    isSelected : boolean = false;
    read : Date;
    burgerMenu: BurgerMenuExtendedItem[] = [];

    get isUserMessageRead() : boolean{
        return !!this.read;
    }



}
