import {Tab} from '../shared/tabbing/tab';
import {ErrorTab} from '../shared/error-handling/error-tab';
import {ShareDocumentsComponent} from './share-documents.component';
import {SharedDocumentsPackage} from '../matters/document-production/shared-documents-package';

export class ShareDocumentsTab extends Tab {
    constructor(json? : ShareDocumentsTab) {
        super(json);
        if(json) {
           this.errorTab = new ErrorTab(json.errorTab);
           this.shareDocumentPackage = new SharedDocumentsPackage(json.shareDocumentPackage);
        }
    }

    shareDocumentsComponent : ShareDocumentsComponent;
    errorTab: ErrorTab;
    shareDocumentPackage : SharedDocumentsPackage


    static createShareDocumentsTab(shareDocumentPackage : SharedDocumentsPackage) : ShareDocumentsTab {
        let tab :  ShareDocumentsTab = new ShareDocumentsTab();
        tab.tabType = 'shareDocuments';
        tab.type = 'Share Documents';
        tab.id =  shareDocumentPackage.id;
        tab.route =  'main/messages/share-documents/' + tab.id;
        tab. shareDocumentPackage = shareDocumentPackage;
        return tab;
    }

    get routeParams(): string[] {
        return [this.route];
    }

    deCoupleUIComponent() : void {
        this.shareDocumentsComponent = null;
    }

    //So far we don't know if it is need isDirty();

    //So far we don't know what is the title;
}
