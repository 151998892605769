import {Injectable} from '@angular/core';
import {AuthenticatedUser} from './authenticated-user';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {Authority} from './authority';
import {AUTH_OPERATION, AUTH_PERMISSION, AUTH_ROLE} from './authorization-keys';
import * as _ from 'lodash';
import {User} from '../../matters/shared/user';
import {UUIDUtil} from '../../main/uuid-util';
import {UUIDService} from '../../main/uuid.service';
import {ReferenceDataService} from '../reference-data/reference-data.service';
import {UserStateService} from '../user-state/user-state.service';
import {UserConfigurationService} from '../user-configuration.service';
import {Router} from '@angular/router';
import {AppLoaderService} from '../../core/app-loader-service';


@Injectable()
export class AuthorizationService {

    private _authenticatedUser: AuthenticatedUser = new AuthenticatedUser(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.authenticatedUser)));

    constructor(public router: Router, private userStateService: UserStateService, private userConfigurationService: UserConfigurationService , private appLoaderService : AppLoaderService) {
    }

    get authenticatedUser(): AuthenticatedUser {
        if(!this._authenticatedUser || this._authenticatedUser.grantedAuthorities.length === 0){
            this._authenticatedUser = new AuthenticatedUser(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.authenticatedUser)));
        }
        return this._authenticatedUser;
    }


    set authenticatedUser(value : AuthenticatedUser) {
        this._authenticatedUser = value;
    }

    /**
     * This method checks that logged in user has access to the operation. Access can be of any type read, write etc
     * User role & authorities are cached so if multiple user login on same browser instance then they will be stale.
     * @param operationEnum
     * @returns {boolean}
     */
    hasAccess(operationEnum: AUTH_OPERATION): boolean {
        let authority: Authority;
        const operationVal = AUTH_OPERATION[operationEnum];
        if(this.authenticatedUser) {
            authority = _.find(this.authenticatedUser.grantedAuthorities, function(authority: Authority) {
                return authority.operation.indexOf(String(operationVal)) > -1;
            });
        }
        return !!authority;
    }

    isMatterReadOnlyAccess() : boolean {
        return !this.hasPermission(AUTH_OPERATION.MATTERS ,AUTH_PERMISSION.CREATE);
    }


    isProjectReadOnlyAccess() : boolean {
        return !this.hasPermission(AUTH_OPERATION.MANAGE_PROJECTS, AUTH_PERMISSION.CREATE);
    }

    hasReadAccessToConveyancingMatter(): boolean{
        return this.hasReadAccess(AUTH_OPERATION.CONVEYANCING);
    }

    hasFullAccessToConveyancingMatters(): boolean{
        return this.hasReadAccess(AUTH_OPERATION.CONVEYANCING) && this.hasCreateAccess(AUTH_OPERATION.CONVEYANCING) && this.hasUpdateAccess(AUTH_OPERATION.CONVEYANCING);
    }

    hasReadOrFullAccessToProjectMatter() : boolean {
        return this.hasReadAccessToProjectMatters() || this.hasFullAccessToProjectMatters();
    }

    hasReadAccessToProjectMatters(): boolean{
        return this.hasReadAccess(AUTH_OPERATION.PROJECT_MATTERS);
    }

    hasFullAccessToProjectMatters(): boolean {
        return this.hasReadAccess(AUTH_OPERATION.PROJECT_MATTERS) && this.hasCreateAccess(AUTH_OPERATION.PROJECT_MATTERS) && this.hasUpdateAccess(AUTH_OPERATION.PROJECT_MATTERS);
    }

    isContactReadOnlyAccess() : boolean {
        return !(this.hasPermission(AUTH_OPERATION.CONTACT_MANAGEMENT ,AUTH_PERMISSION.CREATE) || this.hasPermission(AUTH_OPERATION.MANAGE_GLOBAL_CONTACTS ,AUTH_PERMISSION.CREATE));
    }

    isEventReadOnlyAccess() : boolean {
        return !this.hasPermission(AUTH_OPERATION.EVENTS ,AUTH_PERMISSION.CREATE);
    }

    hasFullAccessToEvents() : boolean {
        return this.hasPermission(AUTH_OPERATION.EVENTS ,AUTH_PERMISSION.CREATE);
    }

    hasFullAccessToProjects(): boolean {
        return this.hasPermission(AUTH_OPERATION.MANAGE_PROJECTS , AUTH_PERMISSION.CREATE);
    }

    hasCreateAccess(authOperation: AUTH_OPERATION): boolean{
        return this.hasPermission(authOperation, AUTH_PERMISSION.CREATE) ;
    }

    hasUpdateAccess(authOperation: AUTH_OPERATION): boolean{
        return this.hasPermission(authOperation, AUTH_PERMISSION.UPDATE) ;
    }

    hasReadAccess(authOperation: AUTH_OPERATION): boolean{
        return this.hasPermission(authOperation, AUTH_PERMISSION.READ);
    }

    /**
     * This method checks that authenticated user has the given role.
     * User role & authorities are cached so if multiple user login on same browser instance then they will be stale.
     * @param roleEnum
     * @returns {boolean}
     */
    hasRole(roleEnum: AUTH_ROLE): boolean {
        const roleVal = AUTH_ROLE[roleEnum];
        return !!(this.authenticatedUser && this.authenticatedUser.role && this.authenticatedUser.role.find(item=>item === roleVal));
    }

    /**
     * This method checks that logged in user has given permission on the operation
     * @param operationEnum
     * @param permissionEnum
     * @returns {boolean}
     */
    hasPermission(operationEnum: AUTH_OPERATION, permissionEnum: AUTH_PERMISSION): boolean {
        let authority: Authority;
        const operationVal = AUTH_OPERATION[operationEnum];
        const permissionVal = AUTH_PERMISSION[permissionEnum];
        if(this.authenticatedUser) {
            authority = _.find(this.authenticatedUser.grantedAuthorities, function(authority: Authority) {
                return authority.operation.indexOf(String(operationVal)) > -1 && permissionVal === authority.privilege;
            });
        }
        return !!authority;
    }

    /**
     * This method checks that logged in user has given  "Full Access" to the "Chicago Title Integration" Permission Set on the operation
     * @returns {boolean}
     */
    hasChicagoTitleIntegrationFullAccess(): boolean {
        return this.hasPermission(AUTH_OPERATION.CHICAGO_TITLE_INTEGRATIONS ,AUTH_PERMISSION.CREATE)
            && this.hasPermission(AUTH_OPERATION.CHICAGO_TITLE_INTEGRATIONS ,AUTH_PERMISSION.READ)
            && this.hasPermission(AUTH_OPERATION.CHICAGO_TITLE_INTEGRATIONS ,AUTH_PERMISSION.UPDATE)
            && this.hasPermission(AUTH_OPERATION.CHICAGO_TITLE_INTEGRATIONS ,AUTH_PERMISSION.DELETE);
    }

    hasTitlePlusIntegrationFullAccess(): boolean {
        return this.hasPermission(AUTH_OPERATION.TITLE_PLUS_INTEGRATIONS ,AUTH_PERMISSION.CREATE)
            && this.hasPermission(AUTH_OPERATION.TITLE_PLUS_INTEGRATIONS ,AUTH_PERMISSION.READ)
            && this.hasPermission(AUTH_OPERATION.TITLE_PLUS_INTEGRATIONS ,AUTH_PERMISSION.UPDATE)
            && this.hasPermission(AUTH_OPERATION.TITLE_PLUS_INTEGRATIONS ,AUTH_PERMISSION.DELETE);
    }

    hasManageDataMoveUtilityFullAccess(): boolean {
        return this.hasPermission(AUTH_OPERATION.MANAGE_DATA_MOVE_UTILITY ,AUTH_PERMISSION.CREATE)
            && this.hasPermission(AUTH_OPERATION.MANAGE_DATA_MOVE_UTILITY ,AUTH_PERMISSION.READ)
            && this.hasPermission(AUTH_OPERATION.MANAGE_DATA_MOVE_UTILITY ,AUTH_PERMISSION.UPDATE)
            && this.hasPermission(AUTH_OPERATION.MANAGE_DATA_MOVE_UTILITY ,AUTH_PERMISSION.DELETE);
    }
    /**
     * This method checks that logged in user has given  "Full Access" to the "Fct Integration" Permission Set on the operation
     * @returns {boolean}
     */
    hasFctTitleIntegrationFullAccess(): boolean {
        return this.hasPermission(AUTH_OPERATION.FCT_INTEGRATIONS ,AUTH_PERMISSION.CREATE)
            && this.hasPermission(AUTH_OPERATION.FCT_INTEGRATIONS ,AUTH_PERMISSION.READ)
            && this.hasPermission(AUTH_OPERATION.FCT_INTEGRATIONS ,AUTH_PERMISSION.UPDATE)
            && this.hasPermission(AUTH_OPERATION.FCT_INTEGRATIONS ,AUTH_PERMISSION.DELETE);
    }

    removeAuthenticatedUser(): void {
        if (this.authenticatedUser) {
            this.authenticatedUser = null;
        }
    }

    getDefaultSelectedLandingPageUrl(selectedLandingPage) {
        if (this.hasAccess(AUTH_OPERATION.EVENTS) && selectedLandingPage === "EVENTS") {
            return './main/events/list';
        }
        if (this.hasAccess(AUTH_OPERATION.MATTERS) && selectedLandingPage === "MATTER") {
            return './main/tabs/matters';
        }
        if (this.hasAccess(AUTH_OPERATION.EVENTS) && selectedLandingPage === "DAYATGLANCE") {
            return './main/events/dayAtGlance';
        }
    }

    async navigateToGetRedirectUrl() {
        if(this.appLoaderService.currentStateRoute && this.appLoaderService.currentStateRoute.indexOf('redirect') > -1){
            if (this.hasAccess(AUTH_OPERATION.MATTERS) && this.appLoaderService.currentStateRoute.indexOf('main/tabs/matters') > -1 ) {
                this.router.navigateByUrl(this.appLoaderService.currentStateRoute);
            }
            else{
                this.router.navigate(["./main/messages/list"],{queryParams: {landing: true}});
            }
        }
        else {
            let url = await this.getRedirectUrl();
            this.router.navigate([url], {queryParams: {landing: !!url && url.indexOf('main/events/list') < 0}});
        }
    }

    async getRedirectUrl(): Promise<string> {
        if (this.checkForUserMustReadMessages()) {
            return './main/messages/list';
        }
        if (this.hasRole(AUTH_ROLE.ROLE_SYSTEM_ADMINISTRATOR)) {
            return './main/admin/list';
        } else if (this.hasRole(AUTH_ROLE.ROLE_SYSTEM_USER)) {
            let userConfigService = await this.userConfigurationService.getUserConfiguration().toPromise();
            if (userConfigService.configValues.selectedLandingPage) {
                this.getDefaultSelectedLandingPageUrl(userConfigService.configValues.selectedLandingPage);
            }
            if (this.hasAccess(AUTH_OPERATION.account_list_view) ||
                this.hasAccess(AUTH_OPERATION.GENERAL_ACCOUNT_MAINTENANCE) ||
                this.hasAccess(AUTH_OPERATION.CUSTOMER_ACCOUNT_LIST)) {
                return './main/admin/list';
            }
            if (this.hasAccess(AUTH_OPERATION.CONTACT_MANAGEMENT)) {
                return './main/contacts/list';
            }
            if (this.hasAccess(AUTH_OPERATION.forms)) {
                return './main/doc-services';
            }
            return './main';
        } else {
          let userConfigService = await this.userConfigurationService.getUserConfiguration().toPromise();
          if (userConfigService.configValues.selectedLandingPage === "EVENTS") {
            return './main/events/list';
          }
          if (userConfigService.configValues.selectedLandingPage === "MATTER") {
            return './main/tabs/matters';
          }
          if (userConfigService.configValues.selectedLandingPage === "DAYATGLANCE") {
            return './main/events/dayAtGlance';
          }
          else {
            return './main';
          }
        }
    }

    checkForUserMustReadMessages() : boolean{
        let navigateToMessages  = false;
        let sessionUser : any  = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
        if(sessionUser  && sessionUser != null) {
            let authenticatedUser = new User(JSON.parse(sessionUser));
            navigateToMessages = authenticatedUser.mustReadMessageExist;
        }
        return navigateToMessages;
    }


    initializationAfterLogin(referenceDataService : ReferenceDataService, uuidService: UUIDService) : void {
        //This is a one-time initialization to inject the uuidService into UUIDUtil at login.
        UUIDUtil.setService(uuidService);
        UUIDUtil.requestUUIDs();
        referenceDataService.initializeReferenceData();
    }

    setUpUserStateService(defaultProvinceCode: string, userProvinces: string[]): void {
        this.userStateService.defaultProvinceCode = defaultProvinceCode;
        sessionStorage.setItem(SESSION_STORAGE_KEYS.defaultProvinceCode, defaultProvinceCode);
        this.userStateService.setEnabledUserProvinceCodes(userProvinces);
    }

    userHasAccessToOntarioFeatures(): boolean{
        return this.userStateService.getEnabledUserProvinceCodes().indexOf('ON') > -1;
    }

    /**
     * This method checks that logged in user has given  "Full Access" to the "Chicago Title Integration" Permission Set on the operation
     * @returns {boolean}
     */
    hasFieldCodeCreateAndUpdateAccess(): boolean {
        return this.hasPermission(AUTH_OPERATION.FIELD_CODE_UI_MAPPING ,AUTH_PERMISSION.CREATE)
            && this.hasPermission(AUTH_OPERATION.FIELD_CODE_UI_MAPPING ,AUTH_PERMISSION.UPDATE)

    }

    hasAdminAccess() : boolean {
        if(this.hasRole(AUTH_ROLE.ROLE_SYSTEM_USER)){
            return false;
        }
        if(this.hasRole(AUTH_ROLE.ROLE_SYSTEM_ADMINISTRATOR)){
            return this.hasAccess(AUTH_OPERATION.DOPROCESS_ACCOUNT_MANAGEMENT);
        }
        else{
            return (this.hasAccess(AUTH_OPERATION.configure_teraview) ||
                this.hasAccess(AUTH_OPERATION.GENERAL_ACCOUNT_MAINTENANCE)
            );
        }

    }

    //TEMPLATE_AND_CATEGORY_MANAGEMENT either has No Access OR Full Access
    hasTemplateAndCategoryManagementFullAccess(): boolean {
        return this.hasPermission(AUTH_OPERATION.TEMPLATE_AND_CATEGORY_MANAGEMENT ,AUTH_PERMISSION.CREATE)
            && this.hasPermission(AUTH_OPERATION.TEMPLATE_AND_CATEGORY_MANAGEMENT ,AUTH_PERMISSION.READ)
            && this.hasPermission(AUTH_OPERATION.TEMPLATE_AND_CATEGORY_MANAGEMENT ,AUTH_PERMISSION.UPDATE)
            && this.hasPermission(AUTH_OPERATION.TEMPLATE_AND_CATEGORY_MANAGEMENT ,AUTH_PERMISSION.DELETE);
    }

    hasFullAccessToOpportunities(): boolean{
        return this.hasReadAccess(AUTH_OPERATION.OPPORTUNITIES) && this.hasCreateAccess(AUTH_OPERATION.OPPORTUNITIES) && this.hasUpdateAccess(AUTH_OPERATION.OPPORTUNITIES);
    }

    isOpportunitiesReadOnlyAccess() : boolean {
        return !this.hasPermission(AUTH_OPERATION.OPPORTUNITIES ,AUTH_PERMISSION.CREATE);
    }
}
