import {HttpClient} from '../../../core';
import {userAccountProfilesApi} from '../../shared/user-account-profiles-api';
import {LawyerMove} from './lawyer-move';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';


@Injectable()
export class LawyerMoveService {
    constructor(private http: HttpClient) {
    }

    lawyerMove(lawyerMove: LawyerMove): Observable<any> {
        // We have a dialog to handle error so we skip Fields Data Error report
        return this.http.post(userAccountProfilesApi.getLawyerMove(lawyerMove && lawyerMove.lawyerContactId), JSON.stringify(lawyerMove), null, true)
            .map((res) => {
                return res['DataMoveTask'];
            });
    }

    executeLawyerMove(): Observable<any> {
        return this.http.post(userAccountProfilesApi.dataMoveTaskScheduler, null)
            .map((res) => {
                return res['DataMoveTask'];
            });
    }
}
