import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetUser} from './teranet-user';
import {ParcelRegister} from './parcel-register';
import {Instrument} from './instrument';
import {TeranetDocument} from './teranet-document';
import {Writ} from './property-writ';
import {WritExecution} from './property-writ-execution';


export class TeranetDocket extends BaseEntity{
    teranetUser : TeranetUser;
    docketSystemId : number;
    teraviewDocketIdentifier : string;
    teraviewDocketName : string;
    matterId : number;
    matterDocketId : number;
    docketStatus:string;
    parcelRegisters: ParcelRegister[] = [];
    ownerImportedFromSpin: boolean;
    primarySpinOwnerParcelRegisterId: number;
    propertyImportedFromSpin: boolean;
    firstSpinPropertyParcelRegisterId: number;

    constructor(t?: TeranetDocket) {
        super(t);
        if(t) {
            if(t.parcelRegisters) {
                for(let f of t.parcelRegisters) {
                    this.parcelRegisters.push(new ParcelRegister(f));
                }
            }
        }else {
            this.ownerImportedFromSpin = false;
            this.propertyImportedFromSpin = false;
        }
    }

    getPrimaryInstrument(instrumentNumber: string): Instrument {
        let instruments: Instrument [] =this.getAllInstruments().filter(value => {
            return value.instrumentNumber === instrumentNumber
        }).sort((a, b) => a.id - b.id);

        return instruments.length > 0 ? instruments[0] : null;
    }

    getAllInstruments(): Instrument[] {
        let instruments: Instrument[]=[];
        this.parcelRegisters.forEach(parcelRegister => {
            instruments = instruments.concat(parcelRegister.instruments);
            parcelRegister.adjacentParcels.forEach(adjacentParcelRegister => {
                instruments = instruments.concat(adjacentParcelRegister.instruments);
            })
        });
        return instruments;
    }

    getAllDocuments(): TeranetDocument[] {
        let documents: TeranetDocument[] = [];
        this.getAllInstruments().forEach(instrument => {
            if(instrument.instrumentDocument) {
                documents.push(instrument.instrumentDocument);
            }
            documents = documents.concat(instrument.attachments);
        });
        return documents;
    }

    //This method returns all searched writs associated with this docket
    getAllSearchedWrits(): Writ[] {
        let writs: Writ[] = [];
        this.parcelRegisters.forEach(parcelRegister => {
            writs = writs.concat(parcelRegister.searchedWrits);
        });
        return writs;
    }

    //If same party exists in multiple parcel registers then they share the data like certificate or execution among them. Therefore, we keep the
    // certificate and executions with primary writ and use that for showing the data in all parcel register.
    getPrimaryWrit(selectedWrit: Writ) {
        let writs : Writ[] = this.getAllSearchedWrits().filter(writ => writ.partyNameHash === selectedWrit.partyNameHash
            && writ.writCertificate && writ.enforcementOffice == selectedWrit.enforcementOffice);
        return writs.length > 0 ? writs[0] : selectedWrit;
    }

    getPrimaryParcelRegister(instrumentNumber: string) {
        let instrumentObj: Instrument;
        instrumentObj = this.getPrimaryInstrument(instrumentNumber);
        return this.parcelRegisters.find(pr => !!pr.instruments.find(instrument => instrument.id == instrumentObj.id));
    }

    //This method retrieves the primary document for given instrument
    getPrimaryDocumentForInstrument(instrument: Instrument, allDocuments?: TeranetDocument[]) {
        //In case if instrument doesn't have a document (which should not happen except for some bad records) then returning a blank document so code
        // doesn't break
        if(instrument.instrumentDocument) {
            return this.getPrimaryDocument(instrument.instrumentDocument.documentNumber, allDocuments);
        } else {
            return new TeranetDocument();
        }
    }

    //If same instrument or attachment exits in multiple pins then document is shared among them, which means if PDF is downloaded under one PIN then it will
    // show up on the other pins too that has same instrument/attachment. Therefore, we always work with the primary document, though each
    // instrument/attachment has it's own document but that is shallow, the actual data exists only for primary document.
    getPrimaryDocument(documentNumber : string, allDocuments?: TeranetDocument[]) : TeranetDocument {
        let documents : TeranetDocument[] = allDocuments ? allDocuments : this.getAllDocuments();
        documents = documents.filter(teranetDocument => teranetDocument.documentNumber === documentNumber).sort((a, b) => a.id - b.id);
        return documents.length > 0 ? documents[0] : new TeranetDocument();
    }

    replaceUpdatedDocument(document: TeranetDocument): void {
        this.parcelRegisters.forEach(parcelRegister => {
            this.updateParcelRegister(parcelRegister, document);

            parcelRegister.adjacentParcels.forEach(adj =>{
                this.updateParcelRegister(adj,document);
            });
        })
    }

    private updateParcelRegister(parcelRegister, document: TeranetDocument) {
        parcelRegister.instruments.forEach(instrument => {
            if (instrument.instrumentDocument && instrument.instrumentDocument.id === document.id) {
                instrument.instrumentDocument = document;
            } else {
                let index = instrument.attachments.findIndex(value => value.id === document.id);
                if (index >= 0)
                    instrument.attachments[index] = document;
            }
        })
    }

    updateWritExecutions(writExecution: WritExecution): void {
        this.getAllSearchedWrits().forEach(writ => {
            if(writ.id === writExecution.writId) {
                let index = writ.writExecutions.findIndex(value => value.id === writExecution.id);
                if(index >= 0) {
                    writ.writExecutions[index] = writExecution;
                }
            }
        });
    }

    getParcelRegisterById(parcelRegisterId: number): ParcelRegister {
        return this.parcelRegisters.find(parcelRegister => parcelRegister.id == parcelRegisterId);
    }

    getParcelRegisterByLegalDescription(legalDescription: string): ParcelRegister {
        return this.parcelRegisters.find(parcelRegister => parcelRegister.propertyDescription == legalDescription);
    }

}
