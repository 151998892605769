import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {StatementAdjustmentKey} from '../statement-adjustment-constants';
import {SoaExpenseAdjustment} from '../modals/reserve-fund/soa-expense-adjustment';

export class StatementAdjustmentDisplayReserveFundBuilder {
    static  getDisplayItems(item: SoaExpenseAdjustment , mainBuilder: StatementAdjustmentDisplayBuilder , soaCommonExpenseAmount : number): StatementOfAdjustmentDisplayItemLine[] {
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        let provinceCode = mainBuilder.matter && mainBuilder.matter.provinceCode ? mainBuilder.matter.provinceCode : null;

        if(item.expenseType === StatementAdjustmentKey.SALES_INCENTIVE && item.textReferencingAgreement){
            sadItemDetails.push(mainBuilder.getNewItemLine(`${item.textReferencingAgreement}` , '' ));
        }

        sadItemDetails.push(mainBuilder.getNewItemLine(`Based on ${mainBuilder.removeTrailingZeros(item.numberOfMonthsCommonExpenses)} times` , '' ));
        //sadItemDetails.push(mainBuilder.getNewItemLine(`common expenses of` ,  mainBuilder.getCurrency(soaCommonExpenseAmount)));
        sadItemDetails.push(mainBuilder.getNewItemLine(`${this.getCommonExpenseLabel(item , provinceCode)}` ,  mainBuilder.getCurrency(soaCommonExpenseAmount)));


        if (item.applyTax == 'YES') {
            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${item.taxType} calculated at ${mainBuilder.addDecimalsToPercent(item.adjustPercentage)}%`,mainBuilder.getCurrency(item.taxAmount)  )
            );
        }
        //mainBuilder.getCurrency(item.soaExpenseAdjustment.calculateEstimatedTax()
        sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${item.expenseType == StatementAdjustmentKey.RESERVE_FUND ? 'Vendor' : 'Purchaser'}`, ``));

        return sadItemDetails;
    }

    static getCommonExpenseLabel(item: SoaExpenseAdjustment , provinceCode :string):string{
        return  provinceCode == 'AB' ? 'monthly condominium fees of' : 'monthly common expenses of';
    }
}
