import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {AppLoaderService} from './app-loader-service';
import {AppConfig} from '../shared-main/app-configuration';
import {ReferenceDataService} from '../shared-main/reference-data/reference-data.service';

/**
 * AuthGuard service is used to allow access on host, only if the authentication is already present
 * If authentication is not yet done, AuthGuard service saves the last attempted url in
 * AuthenticationService's redirectUrl, and redirects to Login page
 */
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private appLoaderService : AppLoaderService,
        private appConfig : AppConfig,
        private referenceDataService : ReferenceDataService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        // if user is authenticated allow access to url
        if (this.authenticationService.checkCredentials()) {
            return true;
        }

        // if user is not authenticated, update the current attempted url in AuthenticationService
        // which could be used to redirect after login
        this.authenticationService.redirectUrl = state.url;

        if(state.url && state.url.indexOf('moduleLoading') > -1)
        {
            // Case 1 if module is loading then we redirect ot no content so user see loading page ....
            this.router.navigate(['/no-content']);
        }
        else if(!this.appLoaderService.isLoaderInitialize && !this.appLoaderService.isAotOn){

            // Case 2 if Loader is not Initialize then we save current state url and then navigate app loader page....

            this.appLoaderService.currentStateRoute = state.url;
            this.router.navigate(['/app-loading']);
        }
        else if(state.url && state.url.indexOf('login') > -1){

            // Case 3 if Loader is Initialize and user is not authorize then any login links we allow user redirect since they might static links....
            return true;
        }
        else{
            // Case 4 in case of any unauthentication or any other issue we redirect to login ....
            this.router.navigate(['/login']);
        }

        return false;
    }
}
