import {CategoryField} from './category-field';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {CategoryFieldDataType, CategoryFieldType} from '../../shared-main/constants';
import {CategoryTemplate} from './category-template';
import {ProvinceCode} from '../../admin/accounts/shared/account-province';
import {provinceBasedFieldLabels} from '../province-based-field-labels';

//export  type  MatterType = 'PURCHASE' | 'SALE' |'MORTGAGE' ;
export class SupplementalTaskCategory extends BaseEntity {
    id: number;
    categoryName: string;
    provinceCode: ProvinceCode;
    autoInsert: string;
    customerAccountId: number;
    applicableMatterTypeCodes: string[] = [];
    lastUpdatedTimeStamp: Date;
    isDirty : boolean = false;
    categoryFields: CategoryField[]=[];
    categoryTemplates : CategoryTemplate[]=[];
    categoryKey: string;


    constructor(taskCategory? : SupplementalTaskCategory) {
        super(taskCategory);
        if(taskCategory) {
            for(let prop in taskCategory) {
                if(taskCategory.hasOwnProperty(prop)) {
                    this[prop] = taskCategory[prop];
                }
            }
        }
    }


    static createCategoryFieldRecord(fieldType? : CategoryFieldType, fieldDataType? : CategoryFieldDataType,
                                     fieldLabel? : string ,fieldDefaultLabel? : string  ): CategoryField {
        let categoryField = new CategoryField();

        categoryField.fieldType=fieldType;
        categoryField.fieldDataType=fieldDataType;
        categoryField.fieldLabel = fieldLabel;
        categoryField.fieldDefaultLabel=fieldDefaultLabel;

        return categoryField;
    }

    static createTicklerGroup(fieldType? : CategoryFieldType, fieldDataType? : CategoryFieldDataType,
                              fieldLabel? : string ,fieldDefaultLabel? : string): CategoryField {
        let categoryField = this.createCategoryFieldRecord(fieldType, fieldDataType, fieldLabel, fieldDefaultLabel);

        categoryField.subFields = [];

        let tickleDate = SupplementalTaskCategory.createCategoryFieldRecord('TICKLER_DATE','DATE','Follow up/Tickler Date','Follow up/Tickler Date');
        let tickleNotes = SupplementalTaskCategory.createCategoryFieldRecord('TICKLER_NOTES','TEXT','Workflow for above date(to appear in calendar)','Follow up/Tickler Notes');
        let hasTaskCompleted = SupplementalTaskCategory.createCategoryFieldRecord('HAS_TASK_COMPLETED','NO_YES','Has workflow been completed?','Has workflow been completed?');
        let dateTaskCompleted = SupplementalTaskCategory.createCategoryFieldRecord('DATE_TASK_COMPLETED','DATE','Date workflow completed','Date workflow completed');
        let ticklePer = SupplementalTaskCategory.createCategoryFieldRecord('TICKLER_PER','TEXT','Per','Per');
        let taskCompletionNotes = SupplementalTaskCategory.createCategoryFieldRecord('TASK_COMPLETION_NOTES','TEXT','Workflow Completion notes','Workflow Completion notes');

        categoryField.subFields.push(tickleDate);
        categoryField.subFields.push(tickleNotes);
        categoryField.subFields.push(hasTaskCompleted);
        categoryField.subFields.push(dateTaskCompleted);
        categoryField.subFields.push(ticklePer);
        categoryField.subFields.push(taskCompletionNotes);

        return categoryField;
    }

    get userDefinedFields() : Array<CategoryField> {
        if(!this.categoryFields) {
            return [];
        }
        return this.categoryFields.filter(cp => cp.fieldType === 'USER_DEFINED_FIELD');
    }

    get preDefinedFields() : Array<CategoryField> {
        if(!this.categoryFields) {
            return [];
        }
        return this.categoryFields.filter(cp => cp.fieldType !== 'USER_DEFINED_FIELD' && cp.fieldType !== 'TICKLER_GROUP');
    }

    get ticklerGroup(): CategoryField {
        if(!this.categoryFields) {
            return null;
        }
        return this.categoryFields.find(cp => cp.fieldType === 'TICKLER_GROUP');
    }

    get modifiableSubFieldsOfTickerGroup(): Array<CategoryField> {
        if(!this.ticklerGroup) {
            return [];
        }
        return this.ticklerGroup.subFields.filter(sub => sub.fieldType === 'TICKLER_DATE' || sub.fieldType === 'TICKLER_NOTES');
    }

    get isCustomizedDefaultTaskCategory(): boolean {
        let customizedDefaultTaskCategories = provinceBasedFieldLabels.get('supplemental.task.category.hideCategoryFields', this.provinceCode);
        return this.categoryName && customizedDefaultTaskCategories.indexOf(this.categoryName) > -1;
    }

    get isPayBillOrDebts() : boolean{
        return this.categoryKey && this.categoryKey == 'PAY_BILLS_OR_DEBTS';
    }

    get isIdVerificationOfNonClient() : boolean{
        return this.categoryKey && this.categoryKey == 'ID_VERIFICATION_OF_NON_CLIENT';
    }
}
