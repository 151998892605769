import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {CirfBrokerInfo} from './cirf-broker-info';
import {CirfUdf} from './cirf-udf';

export class CirfInsuranceDetail extends BaseEntity {
    companyName: string;
    policyNumber: string;
    insuranceBroker: CirfBrokerInfo = new CirfBrokerInfo();
    insuranceUdfs : CirfUdf[] = [];

    constructor(cirfInsuranceDetail?: CirfInsuranceDetail) {
        super(cirfInsuranceDetail);
        if(cirfInsuranceDetail) {
            if(cirfInsuranceDetail.insuranceBroker) {
                this.insuranceBroker = new CirfBrokerInfo(cirfInsuranceDetail.insuranceBroker);
            }
            if(Array.isArray(cirfInsuranceDetail.insuranceUdfs)){
                this.insuranceUdfs = cirfInsuranceDetail.insuranceUdfs.map(cirfUdf => new CirfUdf(cirfUdf));
            }
        }
    }

    udfNotes(): string {
        let note = '';
        if (Array.isArray(this.insuranceUdfs)) {
            this.insuranceUdfs.forEach(udf => {
                note += udf.fieldValue ? `${udf.fieldLabel}: ${udf.fieldValue}\n` : '';
            });
        }
        return note;
    }

    // deprecated
    indexOfFirstNonNullUdfField(): number {
        for(let i=0; i < this.insuranceUdfs.length; i++){
            if(this.insuranceUdfs[i].fieldValue){
                return  i;
            }
        }
        return 0;
    }

}
