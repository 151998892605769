import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import Utils from '../../shared-main/utils';
import {UUIDUtil} from '../../main/uuid-util';
import {MatterParticipant} from '../../../app/matters/shared/matter-participant';
import {MatterNotificationConfig} from '../../admin/manage-messaging-notifications/matter-notification-config/matter-notification-config';

export type WorkItemTaskStatusType = 'NOT_STARTED' | 'INPROGRESS' | 'ON_HOLD' |'COMPLETED' | 'ASSIGNED';

export const WorkItemTaskStatusValues = {
    notStarted: <WorkItemTaskStatusType>'NOT_STARTED',
    inProgress: <WorkItemTaskStatusType>'INPROGRESS',
    onHold: <WorkItemTaskStatusType>'ON_HOLD',
    assigned: <WorkItemTaskStatusType>'ASSIGNED',
    completed: <WorkItemTaskStatusType>'COMPLETED',

};

export const TaskStatusOptions = [
    {label : 'Not Started', value : WorkItemTaskStatusValues.notStarted},
    {label : 'In Progress', value : WorkItemTaskStatusValues.inProgress},
    {label : 'On Hold', value : WorkItemTaskStatusValues.onHold},
    {label : 'Completed', value : WorkItemTaskStatusValues.completed},
];


export const AssignedTaskStatusOptions = [
    {label : 'Assigned', value : WorkItemTaskStatusValues.assigned},
    {label : 'Completed', value : WorkItemTaskStatusValues.completed}
];
export class MatterWorkItem extends BaseEntity {
    name : string;
    order: number;
    matterWorkItemTasks: MatterWorkItemTask[] = [];
    tasksNotificationConfig: MatterNotificationConfig[] = [];
    expanded: boolean = true; //UI Only
    isEditMode: boolean; //UI Only
    isSelected: boolean; //UI Only

    constructor(workItem? : MatterWorkItem) {
        super(workItem);
        if(workItem){
            this.matterWorkItemTasks = [];
            if(Array.isArray(workItem.matterWorkItemTasks)){
                workItem.matterWorkItemTasks.forEach((task)=>{
                    this.matterWorkItemTasks.push(new MatterWorkItemTask(task));
                });
            }
            this.tasksNotificationConfig = [];
            if (workItem.tasksNotificationConfig) {
                workItem.tasksNotificationConfig.forEach((item) => {
                    this.tasksNotificationConfig.push(new MatterNotificationConfig(item));
                });
            }
        }
    }

    get notStartedTasks() : MatterWorkItemTask[] {
        return this.matterWorkItemTasks? this.matterWorkItemTasks.filter(task=>task.isStatusNotStarted()) : [];
    }
    get inProgressTasks() : MatterWorkItemTask[] {
        return this.matterWorkItemTasks? this.matterWorkItemTasks.filter(task=>task.isStatusInProgress()) : [];
    }
    get completedTasks() : MatterWorkItemTask[] {
        return this.matterWorkItemTasks? this.matterWorkItemTasks.filter(task=>task.isStatusCompleted()) : [];
    }
    get numberOfTasks() : number {
        return this.matterWorkItemTasks ? this.matterWorkItemTasks.length : 0;
    }
    get numberOfCompletedTasks() : number {
        return this.completedTasks ? this.completedTasks.length : 0;
    }
    get numberOfOverdueTasks() : number {
        return this.matterWorkItemTasks? this.matterWorkItemTasks.filter(task=>task.isOverDue()).length : 0;
    }
    isAnyNotificationActive(taskId : number) : boolean {
        return this.tasksNotificationConfig && this.tasksNotificationConfig.some(item => taskId && !!item.active && item.taskIdentifier == taskId);
    }

}

export class MatterWorkItemTask extends BaseEntity {
    id : number;
    static clientAssignedIdentifierEntity: boolean = true; //Marker property for indicating client side assigns the ID to this entity
    name: string;
    status: WorkItemTaskStatusType;
    dueDate: string;
    completedDate: string;
    completedByUserId: number;
    completedByUserInitials: string;
    visible: boolean = true; //UI Only
    isEditMode: boolean; //UI Only
    isSelected: boolean; //UI Only
    assignedToParticipantId : number;
    assignedToParticipantEmail : string;
    inviteGuid : string;


    constructor(task?: MatterWorkItemTask) {
        super(task);
        if(!task){
            this.id = UUIDUtil.getUUID();
        }
    }

    isStatusNotStarted() : boolean {
        return this.status == WorkItemTaskStatusValues.notStarted;
    }
    isStatusInProgress() : boolean {
        return this.status == WorkItemTaskStatusValues.inProgress;
    }
    isStatusCompleted() : boolean {
        return this.status == WorkItemTaskStatusValues.completed;
    }
    isAssigned() : boolean {
        return this.status == WorkItemTaskStatusValues.assigned;
    }

    isAssignedToClient() : boolean {
        return !!this.assignedToParticipantEmail;
    }

    isAssignedToStaff() : boolean {
        return !!this.assignedToParticipantId;
    }


    isOverDue() : boolean {
        return !this.isStatusCompleted() && this.dueDate ?  Utils.isDateBeforeCurrentDate(this.dueDate) : false;
    }

    get dueDateString() : string {
        return this.dueDate;
    }


    assignTaskToClient(matterParticipant : MatterParticipant, email: string, inviteGuid : string) : void {
        if(!this.isAssigned() && matterParticipant && matterParticipant.contact){
            this.status = WorkItemTaskStatusValues.assigned;
            this.assignedToParticipantEmail = email;
            this.inviteGuid = inviteGuid;
            this.assignedToParticipantId = matterParticipant.matterParticipantId;
        }
    }

    assignTaskToStaff(contactId : number) : void {
        if(!this.isAssigned()){
            this.status = WorkItemTaskStatusValues.assigned;
            this.assignedToParticipantId = contactId;

        }
    }

    unAssignTask() : void {
        if(this.isAssigned() || this.isStatusCompleted()){
            this.status = WorkItemTaskStatusValues.notStarted;
            this.assignedToParticipantEmail = undefined;
            this.assignedToParticipantId = undefined;
            this.inviteGuid = undefined;
        }
    }

    get taskNotificationStatus() : string {
        if(this.status == 'ASSIGNED'){
            return  'INPROGRESS'
        }
        else{
            return  this.status
        }

    }

}
