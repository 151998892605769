import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import Utils from '../../../shared-main/utils';
import {PROVINCE_CODES} from '../../shared/user-province';

export type InterimOccupancyFeeCalculationForType = 'THIS_MONTH_ONLY' | 'THIS_MONTH_AND_NEXT_MONTH';

export class SoAdjInterimFee extends BaseEntity{

    id: number;

    calculateFor: InterimOccupancyFeeCalculationForType;

    mortgageInterestPercentage: number;

    mortgageInterest: number;

    mortgageInterestTotal: number;

    commonExpensePercentage: number;

    commonExpense: number;

    commonExpenseTotal: number;

    taxesPercentage: number;

    taxes: number;

    taxesTotal: number;

    taxType: string;

    applyTax: boolean;

    hstRate: number;

    postDatedChequesToCommence: number;

    infoOnly: boolean;

    get total(): number {
        return Utils.roundCurrency(Number(this.mortgageInterestTotal)) + Utils.roundCurrency(Number(this.commonExpenseTotal) ) + Utils.roundCurrency(Number(this.taxesTotal));
    }

    get totalPlusTax(): number {
        if (Utils.convertToBoolean(this.applyTax)) {
            return Utils.roundCurrency(Number(this.total + (this.total * this.hstRate / 100)));
        }
        return this.total;
    }

    get nextMonthFees(): number {
        return this.totalPlusTax;
    }

    getPurchaserShare(elapsedDays: number, numberOfDaysInMonth: number, isPaysForDateOfClosingPurchaser ?: boolean, provinceCode ?: string  ): number {
        let numberOfDaysForPurchaserShare : number  = provinceCode == PROVINCE_CODES.ALBERTA && isPaysForDateOfClosingPurchaser ? this.numberOfDaysForPurchasersShare(elapsedDays, numberOfDaysInMonth) +1 : this.numberOfDaysForPurchasersShare(elapsedDays, numberOfDaysInMonth);
        return  Utils.roundCurrency(Utils.roundCurrency(this.totalPlusTax) * (numberOfDaysForPurchaserShare) / numberOfDaysInMonth);
    }


    numberOfDaysForPurchasersShare(elapsedDays: number, numberOfDaysInMonth: number): number{
        return numberOfDaysInMonth - elapsedDays;
    }

    getCreditVendor(elapsedDays: number, numberOfDaysInMonth: number, isPaysForDateOfClosingPurchaser ?: boolean, provinceCode ?: string): number {
        return (this.calculateFor === 'THIS_MONTH_AND_NEXT_MONTH' ? this.nextMonthFees : 0) + this.getPurchaserShare(elapsedDays, numberOfDaysInMonth, isPaysForDateOfClosingPurchaser, provinceCode);
    }

    getAdjustmentAmount(elapsedDays: number, numberOfDaysInMonth: number, isPaysForDateOfClosingPurchaser ?: boolean, provinceCode ?: string): number {
        if (Utils.convertToBoolean(this.infoOnly)) {
            return 0;
        }
        return  this.getCreditVendor(elapsedDays, numberOfDaysInMonth,isPaysForDateOfClosingPurchaser, provinceCode);
    }

    recalculateMortgageInterestTotal() {
        if (this.mortgageInterestPercentage >= 0 && this.mortgageInterest >= 0) {
            this.mortgageInterestTotal = Number(((this.mortgageInterestPercentage/100) * this.mortgageInterest / 12).toFixed(2));
        }
    }

    recalculateCommonExpenseTotal() {
        if (this.commonExpensePercentage >= 0 && this.commonExpense >= 0) {
            this.commonExpenseTotal = Number(((this.commonExpensePercentage / 100) * this.commonExpense / 12).toFixed(2));
        }
    }

    recalculateTaxesTotal() {
        if (this.taxesPercentage >= 0 && this.taxes >= 0) {
            this.taxesTotal = Number(((this.taxesPercentage / 100) * this.taxes / 12).toFixed(2));
        }
    }

}
