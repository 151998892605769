import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {SoAdjAssumedMortgage} from '../model/so-adj-assumed-mortgage';
import {InterestDateCalendarYearItem} from '../../../shared-main/interest-date-calendar-year-item';
import Utils from '../../../shared-main/utils';
import {InterestRateUtils} from '../../../shared-main/interest-rate-utils';

export class StatementAdjustmentDisplayAssumedMortgageBuilder {
    static getDisplayItems(soaAdj: SoAdjAssumedMortgage, closingDate: string, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[]{
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

        if (soaAdj.combinedOrSeparate == 'COMBINED'){

            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Principal and Interest as at ${mainBuilder.getDate(closingDate, true)}` ,
                mainBuilder.getCurrency(soaAdj.principalAmount)
            ));

        } else {

            sadItemDetails.push(mainBuilder.getNewItemLine(
                `Principal as at ${mainBuilder.getDate(soaAdj.principalAsAtDate, true)}` ,
                mainBuilder.getCurrency(soaAdj.principalAmount)
            ));

            let calculatedInterest: number = 0;

            if (soaAdj.performInterestCalculationBoolean) {
                if (!Utils.isNotValidDate(soaAdj.principalAsAtDate)) {
                    let items: InterestDateCalendarYearItem[] = [];
                    let isPaysForDateClosingVendor: boolean =  mainBuilder.paysForDateOfClosing == "VENDOR";
                    items.push(...InterestRateUtils.calculateInterestPerCalendarYear(soaAdj.principalAsAtDate, closingDate, soaAdj.interestRate, soaAdj.principalAmount, isPaysForDateClosingVendor));
                    soaAdj.totalCreditPurchaser = Number(soaAdj.principalAmount);
                    items.forEach((itm: InterestDateCalendarYearItem) => calculatedInterest += itm.rateAmount);
                }

                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `Interest at ${mainBuilder.getPercentage(soaAdj.interestRate)} calculated`,
                    ''
                ));
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `from ${mainBuilder.getDate(soaAdj.principalAsAtDate,true)} to`,
                    ''
                ));
            }
            else {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `Accrued interest from`,
                    ''
                ));
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    `${mainBuilder.getDate(soaAdj.principalAsAtDate, true)} to`,
                    ''
                ));
                calculatedInterest = Number(soaAdj.interestAmount);
            }

            sadItemDetails.push(mainBuilder.getNewItemLine(
                `${mainBuilder.getDate(closingDate)} (${soaAdj.getNumberOfDaysElapsed(closingDate, mainBuilder.matter.isPaysForDateOfClosingVendor)} days)`,
                Utils.isNotValidDate(soaAdj.principalAsAtDate) ? mainBuilder.cannotCalculateAmount : mainBuilder.getCurrency(calculatedInterest)
            ));
        }

        sadItemDetails.push(mainBuilder.getNewItemLine(
            `Credit ${mainBuilder.purchaserLabel}`,
            ''
        ));

        return sadItemDetails;
    }
}
