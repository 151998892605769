import {DPError} from './dp-error';

export class  ErrorTab {

    fieldErrors : DPError[] = [];
    saveErrors : DPError[] = [];

    constructor(errorTab? : ErrorTab) {
        if(errorTab) {
            for(let prop in errorTab) {
                if(errorTab.hasOwnProperty(prop)) {
                    this[prop] = errorTab[prop];
                }
            }
            this.fieldErrors = [];
            if(Array.isArray(errorTab.fieldErrors)) {
                for(let i : number = 0; i < errorTab.fieldErrors.length; ++i) {
                    this.fieldErrors[i] = new DPError(errorTab.fieldErrors[i]);
                }

            }
            this.saveErrors = [];
            if(Array.isArray(errorTab.saveErrors)) {
                for(let i : number = 0; i < errorTab.saveErrors.length; ++i) {
                    this.saveErrors[i] = new DPError(errorTab.saveErrors[i]);
                }

            }
        }

    }
}
