import {ErrorTab} from './error-tab';
import {MissingFieldTab} from '../missing-fields/missing-field-tab';
import {DPNotificationTab} from './dp-notification-tab';

export class  DpFooterNotification {

    notificationHeader : string;
    isActive : boolean = false;
    errorTab : ErrorTab;
    missingFieldTab : MissingFieldTab;
    dpThirdPartyNotificationTab : DPNotificationTab;
    dpFooterType : string;


    constructor(footerNotification? : DpFooterNotification) {
        if(footerNotification) {
            for (let prop in footerNotification) {
                if (footerNotification.hasOwnProperty(prop)) {
                    this[prop] = footerNotification[prop];
                }
            }
        }
    }

   errorCount() : number {
        return (this.errorTab && this.errorTab.fieldErrors ? this.errorTab.fieldErrors.length : 0) + (this.errorTab && this.errorTab.saveErrors ? this.errorTab.saveErrors.length : 0);
   }

   missingFieldCount() : number {
        return (this.missingFieldTab && this.missingFieldTab.missingFieldErrors ? this.missingFieldTab.missingFieldErrors.length : 0);
   }

    dpThirdPartyNotificationCount() : number{
        return (this.dpThirdPartyNotificationTab && this.dpThirdPartyNotificationTab.notifications ? this.dpThirdPartyNotificationTab.notifications.length : 0);
    }

   notificationCount() : number{
        if(this.isErrorType())
        {
            return this.errorCount();
        }
        else if(this.isMissingFieldType())
        {
            return this.missingFieldCount();
        }
        else if(this.isThirdPartyNotificationType())
        {
            return this.dpThirdPartyNotificationCount();
        }
        else{
            return 0;
        }
    }

    isMissingFieldType() : boolean {
        return (this.dpFooterType == 'EREG' || this.dpFooterType == 'THIRDPARTY' || this.dpFooterType == 'ALTOEFORMS')
    }

    isThirdPartyNotificationType() : boolean {
        return (this.dpFooterType == 'THIRDPARTYNOTIFICATIONS')
    }

    isErrorType() : boolean {
        return (this.dpFooterType == 'ERROR')
    }

}

export const DpFooterTypes = {
    ERROR         : 'ERROR',
    EREG         : 'EREG',
    THIRDPARTY : 'THIRDPARTY',
    THIRDPARTYNOTIFICATIONS : 'THIRDPARTYNOTIFICATIONS',
    ALTOEFORMS   : 'ALTOEFORMS'
};


