import {TrustLedgerConfig} from './config/trust-ledger-config';
import {Matter} from '../../matters/shared/matter';
import {MatterType} from '../../matters/shared/index';

export class TrustLedger {

    id : number;
    allF9Values : boolean;
    lessInterestAdjustment : boolean;
    trustLedgerEntries : boolean;
    trustLedgerExpenditures : boolean;
    cashShortfall : string;
    trustLedgersConfig : TrustLedgerConfig[];
    dirty : boolean;

    constructor(trustLedger? : TrustLedger) {
        for(let prop in trustLedger) {
            if(trustLedger.hasOwnProperty(prop)) {
                this[prop] = trustLedger[prop];
            }
        }
        this.trustLedgersConfig = [];
        if(trustLedger && Array.isArray(trustLedger.trustLedgersConfig)) {
            for(let i : number = 0; i < trustLedger.trustLedgersConfig.length; ++i) {
                this.trustLedgersConfig[i] = new TrustLedgerConfig(trustLedger.trustLedgersConfig[i]);

            }
        }
    }

    initializeTrustLedger(systemTrustLedger : TrustLedger, matterType: MatterType) : void {
        this.trustLedgersConfig = this.trustLedgersConfig.filter(tl=>tl.matterType != matterType);
        let systemTrustLedgerConfig = systemTrustLedger.trustLedgersConfig.filter(tl=>tl.matterType == matterType);
        for(let i = 0; i < systemTrustLedgerConfig.length; i++) {
            let trustLedgerConfig = new TrustLedgerConfig(systemTrustLedgerConfig[i]);
            if(!trustLedgerConfig.code && trustLedgerConfig.identifier)
            {
                trustLedgerConfig.code = trustLedgerConfig.identifier.toString();
            }
            this.trustLedgersConfig.push(trustLedgerConfig);
        }
    }

    getTrustLedgerListByMatterType(matterType: MatterType) : TrustLedgerConfig[] {

        return this.trustLedgersConfig.filter(trustLedger => !trustLedger.deleted && trustLedger.matterType === matterType);
    }

    getTrustLedgerList(matter: Matter) : TrustLedgerConfig[] {
        if(matter) {
            return this.trustLedgersConfig.filter(trustLedger => !trustLedger.deleted &&
                (!!matter.isMatterTypeDischarge ? trustLedger.matterType == 'DISCHARGE' : trustLedger.matterType === matter.matterType));
        }
        else{
            return this.trustLedgersConfig.filter(trustLedger => !trustLedger.deleted);
        }
    }

}
