import {Component, OnInit, ViewChild} from '@angular/core';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../../matters/shared/matter-drop-downs';
import {UserStateService} from '../../shared-main/user-state/user-state.service';
import {Referral} from '../../matters/shared/referral/referral';
import {ReferralForm} from '../../matters/shared/referral/referral-form';
import {DPError} from '../../shared/error-handling/dp-error';
import {Matter} from '../../matters/shared/matter';
import {AssignToMatter} from '../../integrations/mortgage-deal/view-deal-summary.component';
import {Observable, Subject} from 'rxjs';
import {MatterService} from '../../matters/matter.service';
import {TabsService} from '../../core/tabs.service';
import {ApplicationError, FieldError} from '../../core/application-error';
import {MatterProperty} from '../../matters/shared/matter-property';
import {AuthorizationService} from '../../shared-main/authorization/authorization-service';

declare var jQuery: any;

export class ProcessReferralModalContext extends BSModalContext {
    referral: Referral;
    summaryOnly: boolean; //only display the summary and not allow create/update of any matters
    lockMatterValidation: boolean; //Check for matter lock
    jumpToAttachments: boolean
}

@Component({
    selector: 'dp-process-referral-modal',
    templateUrl: './process-referral-modal.component.html',
    styleUrls: [
        './process-referral-modal.component.scss'
    ],
    providers: [DialogService, ErrorService]
})

@FocusFirstElementDecorator()
export class ProcessReferralModal implements OnInit, ModalComponent<ProcessReferralModalContext> {
    referralAccepted: boolean = false;
    context: ProcessReferralModalContext;
    matterTypes: SelectItem[] = [];
    @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
    userProvinces: string[] = [];
    matterProvince: string;
    matterType: string;
    matterRecordNumber: string;
    matterFileNumber: string;
    matterAccountingNumber: string;

    matter: Matter = new Matter();
    assignToMatter: AssignToMatter = 'ASSIGN_TO_EXISTING_MATTER';
    matterSearchText: any;
    isMatterReadOnlyAccess: boolean = false;
    matterSearchLoading = false;
    matterList: any;
    searchTermStreamMatter = new Subject<string>();
    autoCompleteEmptyFlag: any;
    selectedTabIndex: number = 0;

    constructor(public dialog: DialogRef<ProcessReferralModalContext>,
                public matterService: MatterService,
                public userStateService: UserStateService,
                public tabsStateService : TabsService,
                public errorService: ErrorService,
                public authorizationService : AuthorizationService,
                public dialogService: DialogService) {
        this.context = dialog.context;
    }

    ngOnInit(): void {
        this.userProvinces = this.userStateService.getSortedEnabledUserProvinceCodes();

        if (this.userProvinces.length) { //default to first province
            this.matterProvince = this.userProvinces[0];
        }

        if (this.referralForm.transactionType == 'PURCHASE') {
            this.matterType = 'PURCHASE';
        } else if (this.referralForm.transactionType == 'SALE') {
            this.matterType = 'SALE';
        }else if (this.referralForm.transactionType == 'REFINANCE') {
            this.matterType = 'MORTGAGE';
        }
        this.matterTypes = dropDowns.matterTypesPSM;

        this.initSearchMatter();

        this.selectedTabIndex = this.context.jumpToAttachments ? 1 : 0;
    }

    get referral(): Referral {
        return this.context.referral;
    }

    get referralForm(): ReferralForm {
        return this.context.referral.referralForm;
    }

    get isReferralToBeReprocessed(){
        return this.referral.status == 'ACCEPTED' && this.referral.assignedMatterId && this.referral.assignedMatterId > 0;
    }

    initSearchMatter() {
        // AutoComplete
        this.searchTermStreamMatter
            .switchMap((term: string) => {
                this.matterSearchLoading = true;
                if (term.trim() === '') {
                    this.autoCompleteEmptyFlag = true;
                    let observable = Observable.create((observer) => {
                        setTimeout(() => {
                            observer.next();
                        }, 10);
                    });

                    return observable;

                } else {
                    this.autoCompleteEmptyFlag = false;

                    return this.matterService.getMattersForUpdatingFromReferral(term, this.userProvinces);

                }
            })
            .subscribe(
                (matterList: Matter[]) => {
                    this.matterSearchLoading = false;
                    if (matterList) {
                        this.matterList = matterList.map(item => new Matter(item));
                    }

                },
                error => {
                    this.matterSearchLoading = false;
                }
            );
    }
    isSummaryOnly(): boolean {
        return this.context.summaryOnly;
    }


    decline(): void {
        this.dialogService.confirm('Confirmation', 'Are you sure you would like to decline this referral?', false, 'Decline').subscribe(res => {
            if (res) {
                this.dialog.close({action: 'decline'});
            }
        });
    }

    acceptDeal(): void {
        this.referralAccepted = true;
    }


    closeModal(): void {
        this.dialog.close();
    }

    hasFullAccessToMatter() : boolean {
        if(this.matter){
            if(this.matter.isProjectSale){
                return this.authorizationService.hasFullAccessToProjectMatters();
            } else {
                return this.authorizationService.hasFullAccessToConveyancingMatters();
            }
        } else {
            return this.authorizationService.hasFullAccessToConveyancingMatters();
        }

    }

    createMatter(): void {
        this.errorService.clearAllSaveErrors();
        if(!this.hasFullAccessToMatter()){
            this.errorService.addDpSaveError(
                DPError.createCustomDPError("matter.need.select.newMatter.noAccess",
                    'Edit access to Matters is required. Please contact your Administrator.',  "", "ERROR"));
            return;
        }


        if (!this.matterRecordNumber) {
            this.errorService.addDpSaveError(DPError.createCustomDPError("matter.matterOpening.matterNo", "Matter No. is required.", "", "ERROR"));
        }

        if (this.errorService.hasNoErrors()) {
            this.dialog.close({ action: 'accept',
                                      matterProvince: this.matterProvince,
                                      matterType: this.matterType,
                                      matterRecordNumber: this.matterRecordNumber,
                                      matterFileNumber: this.matterFileNumber,
                                      matterAccountingNumber: this.matterAccountingNumber});
        }

    }

    isPurchaseOrSale(): boolean {
        return this.referralForm.transactionType == 'PURCHASE' || this.referralForm.transactionType == 'SALE';
    }

    isPurchaseOrMortgage(): boolean {
        return this.referralForm.transactionType == 'PURCHASE' || this.referralForm.transactionType == 'REFINANCE';
    }

    getTransactionType(): string {
        if (this.referralForm.transactionType == 'PURCHASE') {
            return 'Purchase';
        } else if (this.referralForm.transactionType == 'SALE') {
            return 'Sale';
        }

        return 'N/A';
    }

    getPurchaseOption(): string {
        if (this.referralForm.purchaseType == 'NEW_HOME') {
            return 'New Home'
        } else if (this.referralForm.purchaseType == 'RESALE') {
            return 'Resale'
        } else {
            return '';
        }
    }

    //Copy Matter No. to File No. and Accounting No. if they are blank.
    populateFileAccounting(): void {
        if (this.matterRecordNumber) {
            if (!this.matterFileNumber) {
                this.matterFileNumber = this.matterRecordNumber;
            }

            if (!this.matterAccountingNumber) {
                this.matterAccountingNumber = this.matterRecordNumber;
            }
        }
    }

    removeSelectedMatter(): void {
        this.matter = new Matter();
        this.matterSearchText = '';
        this.modalErrorComponent.removeAllDpSaveError();
    }

    // Matter Search
    matterSearch(event): void {
        let entered: string = event.query;
        this.searchTermStreamMatter.next(entered);
    }

    dataSelectedMatter(matter: Matter): void {
        this.modalErrorComponent.removeAllDpSaveError();
        this.matter = new Matter(matter);
    }

    isMatterOpen(id : number) : boolean {
        return this.tabsStateService.isMatterTabOpen(id);
    }

    public handleApiErrors(error: any): void {
        if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
            error.fieldErrors.forEach((fieldError: FieldError) => {
                this.errorService.addDpSaveError(DPError.createCustomDPError(fieldError.errorCode, fieldError.errorCode + ":" + fieldError.message, null, "ERROR"));
            });
        } else {
            this.errorService.addDpSaveError(DPError.createCustomDPError(error.errorCode, error.errorCode + ":" + error.errorMessage, null, "ERROR"));
        }
    }



    updateExistingMatter(reprocessReferralMatterId ?: number): void {
        this.errorService.clearAllSaveErrors();

        if(!this.hasFullAccessToMatter()){
            this.errorService.addDpSaveError(
                DPError.createCustomDPError("matter.need.select.newMatter.noAccess",
                    'Edit access to Matters is required. Please contact your Administrator.',  "", "ERROR"));
            return;
        }



        if(reprocessReferralMatterId){
            this.matter.id = reprocessReferralMatterId;
        }

        if(this.matter.id == undefined || this.matter.id <= 0){
            //user didn't select any existing matter
            this.errorService.addDpSaveError(
                DPError.createCustomDPError("matter.need.select.existingMatter", "Please search and select a matter",  "", "ERROR"));

        }

        if(this.errorService.hasErrors()){
            return;
        }

        this.matterService.getMatter(this.matter.id)
            .subscribe((matter: Matter) => {
                this.matter = new Matter(matter);
                if(!this.context.lockMatterValidation && this.errorService.hasNoErrors()){
                    this.dialog.close({ action: 'accept',  existingMatter: this.matter });

                } else {
                    let isMatterLockedOrOpen = this.matterService.isMatterLockedOrOpen(this.matter, this.tabsStateService, this.errorService);
                    if(!isMatterLockedOrOpen && this.errorService.hasNoErrors()){
                        this.dialog.close({ action: 'accept', existingMatter: this.matter });
                    }
                }

            }, (error: ApplicationError) => {
                this.handleApiErrors(error);
            });

    }

    getMatterPropertyAddress(matterProperties: MatterProperty[]): string {
        let primaryAddress = '';
        if (matterProperties && matterProperties.length > 0) {
            let matterProperty = matterProperties[0];
            if (matterProperty && matterProperty.address.primaryAddress === true && matterProperty.address.addressHash != null) {
                primaryAddress = (matterProperty.address.addressText === null ? ' ' : matterProperty.address.addressText);
            }
        }
        return primaryAddress;
    }

    toggleUpdateOrCreateAssignedMatterOptions (assignToMatter: AssignToMatter) {
        this.assignToMatter = assignToMatter;
        this.modalErrorComponent.removeAllDpSaveError();
        this.matter = new Matter();
        this.matterSearchText = '';
        this.userProvinces = this.userStateService.getSortedEnabledUserProvinceCodes();
        if (assignToMatter == 'ASSIGN_TO_NEW_MATTER') {

            setTimeout(() => {
                jQuery('#matterType').focus();
            }, 50);
        } else {
            setTimeout(() => {
                jQuery("#matterSearch .ui-autocomplete-input").focus();
            }, 50);
        }

    }

    clearMatterFieldIfNotSelected() {
        if(this.matterSearchText && this.matter && !this.matter.matterRecordNumber) {
            this.matterSearchText = null;
        }
    }

}
