import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Contact} from '../shared/contact';
import {SpouseRelationship} from '../../contact/spouse-relationship';

export type SpouseType = 'AS_A_PURCHASER' | 'As_A_CONSENTING' | 'AS_A_FORMER_PURCHASER' | 'As_A_FORMER_CONSENTING' ;

export class SpouseTypes{
    public static readonly AS_A_PURCHASER : SpouseType = "AS_A_PURCHASER";
    public static readonly As_A_CONSENTING : SpouseType = "As_A_CONSENTING";
    public static readonly AS_A_FORMER_PURCHASER : SpouseType = "AS_A_FORMER_PURCHASER";
    public static readonly As_A_FORMER_CONSENTING : SpouseType = "As_A_FORMER_CONSENTING";
}

export class SuggestedSpouse extends BaseEntity  {
    spouseRelationship : SpouseRelationship;
    familyLawActStatementType : string;
    spouseType: SpouseType; //For NB

    get spouse() : Contact{
        return this.spouseRelationship && this.spouseRelationship.spouse;
    }
}
