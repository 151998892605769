import {ChangeDetectorRef,Component, HostListener, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UtilsService} from './main/utils.service';

import {LockScreenService} from './core/lock-screen.service';
import {AppLoaderService} from './core/app-loader-service';
import {HttpClient, TokenRefreshAction} from './core';
import {ConfirmPasswordModalComponent} from './main/confirm-password-modal/confirm-password.modal.component';
import {DialogService} from './shared/dialog/dialog.service';
import {Subject} from 'rxjs/Subject';
import {FeatureService} from "./feature-service";


@Component({
    selector: 'dppm-app',
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'app.component.html',
    providers   : [UtilsService]


})

// this component will be loaded when user hit the dppmapp url.
// this component is the entry point to the ddpm app.
// it is the master page where all other page will be inculded.
export class DppmAppComponent implements OnInit  {


    loadingMessage : string  = '';

    passwordRefreshRequester = new Subject<TokenRefreshAction>();
    moduleLoaderUpdated = new Subject<void>();


    constructor(
                public router : Router,
                public utilsService : UtilsService  ,
                public lockScreenService : LockScreenService,
                public renderer : Renderer2,
                public httpClient : HttpClient,
                public dialogService: DialogService,
                public featuresService: FeatureService,
                public appLoaderService : AppLoaderService,
                private changeDetector: ChangeDetectorRef) {
        httpClient.passwordRefreshRequester = this.passwordRefreshRequester;
        this.dialogService.rendererRef = this.renderer;
    }

    ngOnInit(){
        let dndBgImagePathStyle = sessionStorage.getItem('dndBgImagePathStyle');
        if(dndBgImagePathStyle){
            this.utilsService.dndBgImagePathStyle =  dndBgImagePathStyle;
        }

        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    (<any>window).Appcues && (<any>window).Appcues.page();
                }
            });

     if (!this.featuresService.isSSOEnable) {
         this.registerForPasswordRefresh();
     }

        this.appLoaderService.setLoaderUpdatedSubject(this.moduleLoaderUpdated);
        this.moduleLoaderUpdated.subscribe(() => { this.changeDetector.detectChanges(); });
    }

    registerForPasswordRefresh() : void {
        this.passwordRefreshRequester.subscribe((tokenRefreshAction: TokenRefreshAction)=>{
            //saves the lockscreen status
            let lockScreen = this.lockScreenService.lockForUpdate;
            if(lockScreen){
                //Unlock the screen
                this.lockScreenService.lockForUpdate = false;
            }
            this.dialogService.content({
                                           content       : ConfirmPasswordModalComponent,
                                           context       : {
                                               oldToken: tokenRefreshAction.oldToken,
                                               fullLoginFlag: tokenRefreshAction.fullLoginFlag
                                           },
                                           modalGrid     : 5,
                                           passwordModal: true,
                                           onFulfillment : async (res) => {
                                               if(res){
                                                   // let isNewBuild: boolean = await this.isNewBuild(); //Check to see if there is a new build
                                                   // if(!isNewBuild){
                                                       if(res.fullLoginFlag){
                                                           // Reacquire locks for all the opened tabs
                                                           await this.httpClient.postReloginHandler();
                                                       }
                                                       tokenRefreshAction.newTokenSuccessAction(res.newToken);
                                                       if(lockScreen){
                                                           //Lock the screen back
                                                           this.lockScreenService.lockForUpdate = true;
                                                       }
                                                   // } else {
                                                       //Logout the user
                                                       // this.httpClient.sessionExpired();
                                                   // }
                                               }

                                           },
                                           onRejection : () => {
                                               this.httpClient.sessionExpired();
                                            }
                                       });

        });
    }

    get percentageLoading() : number {
       return this.appLoaderService ? (this.appLoaderService.percentageLoading > 5 ? this.appLoaderService.percentageLoading : 10) : 10;
    }

    get progressBarLoading() : number {
        return  Number(100 - this.percentageLoading);
    }

    get isLoaderInitialize() : boolean {
        return this.appLoaderService ? this.appLoaderService.isLoaderInitialize : false;
    }

    get isAllModuleLoaded() : boolean {
        return this.appLoaderService ? this.appLoaderService.isAllModuleLoaded : false;
    }


    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Lock screen doesn't prevent submit buttons from being clicked by pressing the enter key on the keyboard
    // The following prevents key press when the lock screen is on.
    @HostListener('window:keydown', ['$event'])
    @HostListener('window:keyPress', ['$event'])
    onKeyDown(event: KeyboardEvent) : void {
        if(this.lockScreenService.lockForUpdate){
            event.preventDefault();
            event.stopPropagation();
        }
    }

}

