import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {EFormType, EFormTypes} from './alto-eform-request';

const uiFormTypes = {
    UFORM : 'uForm' ,
    TOL : 'eTFLA'
} ;

export type RegistrationType = 'TPR' | 'ALTO';
export const RegistrationTypes :any = { TPR : 'TPR' , ALTO : 'ALTO'};

export class AltoEForm extends BaseEntity{

    eFormType: EFormType;

    documentType: string;

    documentTypeDescription : string;

    eFormIdentifier: string;

    lastUpdated: string;

    id: number;

    lastUpdatedDate: string;

    sentDate: number;

    sent: boolean;

    registrationType:RegistrationType;

    mortgageId: number;

    isUniversalForm() : boolean {
        return this.eFormType == EFormTypes.UNIVERSAL_FORM;
    }

    isTOL() : boolean {
        return this.eFormType == EFormTypes.TRANSFER_OF_LAND;
    }

    get altoFormName() : string {
        if(this.isUniversalForm()){
            return `${uiFormTypes.UFORM} - ${this.documentTypeDescription} (${this.documentType})`;
        }
        if(this.isTOL()){
            return `${this.documentTypeDescription} (${uiFormTypes.TOL})`;
        }
        //More types to come in the future stories
        return '';
    }

    isCreated() : boolean {
        return this.eFormIdentifier && !this.sent;
    }
}
