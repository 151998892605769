import moment from 'moment';
import {EventData} from './event-data';
import {EventTypes} from './event.constants';
import {Contact} from '../matters';

const colors: any = {
    undertaking: {
        primary: '#EF6E3E',
        secondary: '#EF6E3E'
    },
    supplemental: {
        primary: '#1F95D3',
        secondary: '#1F95D3'
    },
    task: {
        primary: '#1FA345',
        secondary: '#1FA345'
    },
    occupancy: {
        primary: '#8D64AA',
        secondary: '#8D64AA'
    },
    closing: {
        primary: '#FEB10D',
        secondary: '#FEB10D'
    },
    closing_opportunity: {
        primary: '#CCCCFF',
        secondary: '#CCCCFF'
    },
    requisition: {
        primary: '#37766D',
        secondary: '#37766D'
    },
    appointment: {
        primary: '#C9567F',
        secondary: '#C9567F'
    },
    holdback: {
        primary: '#42f55d',
        secondary: '#42f55d'
    }
};

export default class  EventUtils {
    constructor() { }

    static  formatDateForCalendar(dt) : Date {
        let formatDate = dt.split('/');
        return new Date(formatDate[1]+'-'+formatDate[2]+'-'+formatDate[0]);
    }
    static  getColorForEventCalendar(eventType, isOpt) : any {
        switch(eventType) {
            case EventTypes.CLOSING:
                if(isOpt){
                    return colors.closing_opportunity;
                } else {
                    return colors.closing;
                }
            case EventTypes.OCCUPANCY:
                return colors.occupancy;
            case EventTypes.SUPPLEMENTAL_TASKS:
                return colors.supplemental;
            case EventTypes.WORKITEMTASK:
                return colors.task;
            case EventTypes.REQUISITIONS:
                return colors.requisition;
            case EventTypes.UNDERTAKING:
                return colors.undertaking;
            case EventTypes.APPOINTMENT:
                return colors.appointment;
            case EventTypes.HOLDBACK:
                return colors.holdback;
            default:
                return colors.closing;
        }

    }

    static getMatterTypeDisplayShortName(eventData : EventData) {
        if(!eventData || !eventData.matterType){
            return '';
        } else {
            if(eventData.isCustomMatter()){
                return eventData.customMatterTypeName;
            }else {
                if (eventData.isOpportunityType()) {
                    return 'Oppt';
                }
                return eventData.isProjectSale ? 'P'+ eventData.matterType.charAt(0) : eventData.isDischargeMatter()? 'DCH' : eventData.matterType.charAt(0);
            }
        }

    }

    static getMatterTypeShortName(matterType : string) {
        return (matterType == null) ? '' : matterType.charAt(0);
    }

    static formatDateUsingMoment(dt){
        return moment(dt).format("MM-DD-YYYY")
    }

    static getMatterRecordNumber(eventData : EventData, isShorterProjectRecordCharacterLength?:boolean) {
        let projectRecordCharacterLength : number = isShorterProjectRecordCharacterLength ? 8 : 15;
        let matterRecordCharacterLength: number = 12;
        let maxTotalCombinedCharacterLength: number = 27;
        if (eventData.isProjectSale && eventData.projectRecordNumber && eventData.matterRecordNumber) {
            if (eventData.projectRecordNumber.length > projectRecordCharacterLength) {
                if(eventData.matterRecordNumber.length > matterRecordCharacterLength){
                    return eventData.projectRecordNumber.substr(0,projectRecordCharacterLength) + '...-' + eventData.matterRecordNumber.substr(0,matterRecordCharacterLength) + '...';
                } else {
                    return eventData.projectRecordNumber.substr(0,projectRecordCharacterLength) + '...-' + eventData.matterRecordNumber;
                }
            } else {
                if(eventData.projectRecordNumber.length + eventData.matterRecordNumber.length <= maxTotalCombinedCharacterLength){
                    return eventData.projectRecordNumber + '-' + eventData.matterRecordNumber;
                } else {
                    return eventData.projectRecordNumber + '-' + eventData.matterRecordNumber.substr(0,maxTotalCombinedCharacterLength - eventData.projectRecordNumber.length) + '...';
                }
            }
        } else {
            return eventData.matterRecordNumber;
        }
    }


    static getSolicitorInitials(event: EventData, lawClerkSolicitorList : Contact[]): string {
        if (event.isWorItemTaskAssigned()) {
            if(lawClerkSolicitorList && event.scheduledForSolicitorId){
                let contact = lawClerkSolicitorList.find(item => item.id == event.scheduledForSolicitorId);
                return contact && contact.initials ? contact.initials : '';
            }
            else{
                return  'Client'
            }

        } else {
            return '';
        }

    }
}
