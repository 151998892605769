import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetPropertyOwner} from './teranet-property-owner';
import {Instrument} from './instrument';
import {TeranetParcelMap} from './teranet-parcel-map';
import {Writ} from './property-writ';
import {ParcelImage} from './teranet-parcel-image';
import {ContactNameUtil} from '../../matters/shared/contact-name-util';
import {PropertyDetail} from './property-detail';
import {SpinImportData} from './spin-import-data';
import {TprImportData} from './tpr-import-data';
import {IscImportData} from './isc-import-data';

export class ParcelRegister extends BaseEntity {
    id: number;
    pin: string;
    matterDocketId: number;
    lro: string;
    registrationType: string;
    status: string;
    preparedFor: string;
    propertyDescription: string;
    estate: string;
    qualifier: string;
    propertyRemarks: string;
    recently: string;
    pinCreationDate: string;
    titleQualifiers: string[] = [];
    propertyOwners: TeranetPropertyOwner[] = [];
    instruments: Instrument[] = [];
    //This collection contains the searched writs. By default writs are only displayed on frontend and they are persisted to backend only if they have been
    // searched.
    searchedWrits: Writ[] = [];
    adjacentParcels: ParcelRegister[] = [];
    certificateDate: string;
    creationDate: string;
    samePinIndex : number;
    parcelCreatedTimestamp: string;
    registryOffice: string;
    deletedInstrumentsIncluded: boolean;
    mapWithNoRoads: TeranetParcelMap;
    mapWithRoads: TeranetParcelMap;
    adjacentParcelType: string;
    //This property is only maintained on frontend for remembering user selection to show deleted instruments for each parcel register.
    showDeletedInstruments: boolean = false;
    address: string;
    parcelImage: ParcelImage;
    isAdjacentParcel: boolean = false;

    ownersFullName: string[] = [];
    adjacentParcelAnalysisAvailable: boolean = false;

    titleNumber: string;
    propertyDetails: PropertyDetail[];

    spinImportData : SpinImportData;
    tprImportData : TprImportData;
    iscImportData : IscImportData;
    parcelType: string;
    parcelRegisterType: string;
    parcelNo:string;

    constructor(p? : ParcelRegister) {
        super(p);
        if(p) {
            if(p.titleQualifiers) {
                for(let o of p.titleQualifiers) {
                    this.titleQualifiers.push(o);
                }
            }

            if(p.propertyOwners) {
                for(let o of p.propertyOwners) {
                    this.propertyOwners.push(new TeranetPropertyOwner(o));
                    this.ownersFullName.push(o.fullName);
                }
            }

            if(p.instruments) {
                for(let o of p.instruments) {
                    this.instruments.push(new Instrument(o));
                }
            }

            if(p.adjacentParcels) {
                for(let o of p.adjacentParcels) {
                    this.adjacentParcels.push(new ParcelRegister(o));
                    this.adjacentParcels[this.adjacentParcels.length - 1].isAdjacentParcel = true;
                    this.adjacentParcels[this.adjacentParcels.length - 1].showDeletedInstruments = true;
                    // adjacent parcel register are always ordered with deleted instruments
                }
                this.updAdjAnalysisAvail();
            }
            if(p.searchedWrits) {
                for(let o of p.searchedWrits) {
                    this.searchedWrits.push(new Writ(o));
                }
            }

        }
    }

    updAdjAnalysisAvail() : void {
        if (this.adjacentParcels && this.adjacentParcels != null && this.instruments && this.instruments != null) {
            for (let adjP of this.adjacentParcels) {
                if (adjP.instruments) {
                    adjP.instruments
                        .filter(adjInstr => adjInstr.isInstrumentOfTypeTransfer)
                        .forEach(adjInstr => {

                            this.instruments
                                .filter(instr => instr.isInstrumentOfTypeTransfer)
                                .forEach(instr => {

                                    if (ContactNameUtil.surnamesMatch(adjInstr.partiesFrom, instr.partiesFrom) ||
                                        ContactNameUtil.surnamesMatch(adjInstr.partiesTo, instr.partiesFrom) ||
                                        ContactNameUtil.surnamesMatch(adjInstr.partiesFrom, instr.partiesTo) ||
                                        ContactNameUtil.surnamesMatch(adjInstr.partiesTo, instr.partiesTo)) {
                                        adjInstr.adjacentParcelAnalysisAvailable = true;
                                        instr.adjacentParcelAnalysisAvailable = true;
                                        adjP.adjacentParcelAnalysisAvailable = true;
                                        this.adjacentParcelAnalysisAvailable = true;
                                    }
                                });
                        });
                }
            }
        }
    }

    isRecentlyCondo() : boolean {
        return (this.recently && this.recently.trim().toLowerCase() == 'condominium');
    }

    //This makes sense on the adjacent parcels to indicate if the ParcelRegister data has been ordered or not
    get ordered(): boolean{
        return this.parcelCreatedTimestamp && this.parcelCreatedTimestamp != null;
    }

    getInstrumentsByType(type: string) : Instrument[] {
        return this.instruments.filter(value => value.type === type);
    }

    isPropertyCondo(provinceCode : string) : boolean {
        if(provinceCode === 'AB') {
            return this.spinImportData && this.spinImportData.condominium;
        }else if(provinceCode === 'SK') {
            return this.iscImportData && this.iscImportData.condominium;
        }else {
        return ((this.isRecentlyCondo() || (this.propertyDescription && this.propertyDescription.search(/CONDOMINIUM/i) > -1))
        && this.propertyDescription && this.propertyDescription.search(/COMMON ELEMENTS CONDO/i) < 0 && this.propertyDescription.search(/COMMON ELEMENT CONDO/i) < 0)
    }
    }

    get hasMap():boolean{
        return (this.mapWithRoads   && this.mapWithRoads.id   && this.mapWithRoads.id > 0) ||
               (this.mapWithNoRoads && this.mapWithNoRoads.id && this.mapWithNoRoads.id > 0);
    }

    get isRegistryProperty(): boolean {
        return this.registrationType === "R";
    }

    instrumentsNonDeletedChargeType() : Instrument[] {
        return (this.instruments ?  this.instruments.filter(item => item.nonDeletedChargeType()) : []);
    }

    instrumentsNonDeletedNonTransferType() : Instrument[] {
        return (this.instruments ?  this.instruments.filter(item => item.nonDeletedNonTransferType()) : []);
    }

    getSpinShortLegalDescription(): string {
        if (this.propertyDetails && this.propertyDetails.length) {
            return this.propertyDetails.map(item => item.shortDescription).join(', ');
        }

        return '';
    }

    isTprParcelRegister(): boolean {
        return this.parcelRegisterType && this.parcelRegisterType == 'TPR';
    }

}
