import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import moment from 'moment';
import {Matter} from '../../matters/shared';
import {Deposit} from '../../matters/shared/deposit';
import {MortgageUtil} from '../../shared-main/mortgage-utility';
import Utils from '../../shared-main/utils';
import {InterestRate} from './model/interest-rate';


export type DepositInterestAdjustmentType = 'INTEREST_ON_DEPOSITS' | 'INTEREST_ON_DEPOSITS_INTEREST' | 'INTEREST_ON_DEPOSIT_UNDER_OLD_CONDO' ;
export class SoAdjInterestOnDepositCondoItem extends BaseEntity {
    adjustmentDescription : string;
}

export class SoAdjInterestOnDepositCondo extends BaseEntity {

    id : number;
    titleOnAdjustment : string;
    depositAmount : number;
    depositsInterestFromDateType : string;
    printingFormat : string;
    infoOnly: boolean;
    adjustmentType: DepositInterestAdjustmentType;//Indicates what type of adjustment it is
    //Field for AB
    depositAmountToBe : string;
    interestCalculationPeriod: string;
    interestCalculatedFromDate:string;
    interestCalculatedToDate:string;
    interestOnDepositCondoItems : SoAdjInterestOnDepositCondoItem[] =[];

    constructor(soAdjInterestOnDepositCondo? : SoAdjInterestOnDepositCondo) {
        super(soAdjInterestOnDepositCondo);
        if (soAdjInterestOnDepositCondo) {
            this.interestOnDepositCondoItems = [];
            if (Array.isArray(soAdjInterestOnDepositCondo.interestOnDepositCondoItems)) {
                for(let adjDesc of  soAdjInterestOnDepositCondo.interestOnDepositCondoItems) {
                    this.interestOnDepositCondoItems.push(new SoAdjInterestOnDepositCondoItem(adjDesc));
                }
            }
        }

    }



    isPrintingFormatFinalAmount() : boolean {
        return (this.printingFormat == 'FINAL_AMOUNT_ONLY')
    }

    getStartDateForInterestOnDepositsForOldCondo(matter : Matter, date ?: string) : void {
        if(this.interestCalculationPeriod == 'INTERIM_CLOSING_TO_ADJ_DATE' || this.interestCalculationPeriod == 'COMMENCING_INTERIM_CLOSING_DATE' ){
               this.interestCalculatedFromDate =  matter.matterOccupancyDate;
        }else if (this.interestCalculationPeriod == 'COMMENCING_ADJ_DATE'){
            this.interestCalculatedFromDate = matter.getClosingDate();
        }else if(this.interestCalculationPeriod == 'ENDING_ADJ_DATE' || this.interestCalculationPeriod == 'ENTER_DATES' || this.interestCalculationPeriod == 'ENDING_INTERIM_CLOSING_DATE' ){
            this.interestCalculatedFromDate = date ? date : '';
        }
    }



    getEndDateForInterestOnDepositsForOldCondo(matter : Matter , date ?: string): void {
        if(this.interestCalculationPeriod == 'INTERIM_CLOSING_TO_ADJ_DATE' || this.interestCalculationPeriod == 'ENDING_ADJ_DATE' ){
            this.interestCalculatedToDate =  matter.getClosingDate();
        }else if (this.interestCalculationPeriod == 'COMMENCING_ADJ_DATE' || this.interestCalculationPeriod == 'ENTER_DATES' || this.interestCalculationPeriod == 'COMMENCING_INTERIM_CLOSING_DATE' ){
            this.interestCalculatedToDate = date ? date : '';
        }else if( this.interestCalculationPeriod == 'ENDING_INTERIM_CLOSING_DATE' ){
            this.interestCalculatedToDate = matter.matterOccupancyDate;
        }
    }



    getStartDateForInterestOnDepositsInterest(matter : Matter): string {
        let depositDate;
        if(matter && (matter.isProjectSale || (matter.isPurchase && matter.matterLink && matter.isMatterProvinceAB))) {
            // If it is ProjectSale with AB there is no requisitionDate, it has occupancyDate.
            depositDate = matter.isMatterProvinceAB ? matter.occupancyDate : matter.requisitionDate;
        }else if(matter){
            depositDate  = moment(matter.createdTimeStamp).format('YYYY/MM/DD');
        }
        return depositDate;
    }

    getEndDateForInterestOnDepositsInterest(matter : Matter): string {
        return matter.getFinalClosingDate();
    }


    getUpdatedDateOfField(matter : Matter) : string {
        let interestCalculatedFrom : string = undefined;
        if(this.depositsInterestFromDateType == 'INTERIM_CLOSING_AS_SPECIFIED_IN_OCCUPANCY_DATE'){
            if(matter && matter.isProjectSale) {
                interestCalculatedFrom = matter.requisitionDate;
            }else if(matter){
                interestCalculatedFrom  = moment(matter.createdTimeStamp).format('YYYY/MM/DD');
            }
        }
        else  if(this.depositsInterestFromDateType == 'INTERIM_CLOSING_AS_SPECIFIED_IN_INTERIM_CALCULATED_AS_AT_DATE'){
            interestCalculatedFrom = matter.getInterimClosingDate();
        }
        else  if(this.depositsInterestFromDateType == 'FINAL_CLOSING_AS_SPECIFIED_IN_CLOSING_DATE'){
            if(matter && matter.isProjectSale) {
                interestCalculatedFrom = matter.matterCloseDate;
            }else if(matter){
                interestCalculatedFrom  = moment(matter.createdTimeStamp).format('YYYY/MM/DD');
            }
        }
        else  if(this.depositsInterestFromDateType == 'FINAL_CLOSING_AS_SPECIFIED_IN_FINAL_CALCULATED_AS_AT_DATE' && matter){
            interestCalculatedFrom = matter.getFinalClosingDate();
        }
        return interestCalculatedFrom;
    }

    generateInterestOnDepositForOldCondo(matter : Matter , isAdjustmentModeFinal : boolean) : any[] {
        let interestRowList = [];
        this.getStartDateForInterestOnDepositsForOldCondo(matter, this.interestCalculatedFromDate);
        this.getEndDateForInterestOnDepositsForOldCondo(matter, this.interestCalculatedToDate);
        let startDate = this.interestCalculatedFromDate;
        if(this.depositAmountToBe == 'TOTAL_DEPOSITS'){
            let depositAdjustment = matter.statementOfAdjustments.find(item => item.isDepositAdjustment());
            if(depositAdjustment){
                this.depositAmount = depositAdjustment.amount;
            }
        }
        //sync the deposit amount displayed in adjustmentDescription (in case deposit amount is changed in Deposit Adjustment)
        if(this.interestOnDepositCondoItems.length > 0){
            let adjustmentDescription = this.interestOnDepositCondoItems[0].adjustmentDescription;
            if(adjustmentDescription.indexOf(":") > -1){
                this.interestOnDepositCondoItems[0].adjustmentDescription = adjustmentDescription.replace(adjustmentDescription
                    .substring(adjustmentDescription.indexOf(":")+1).trim(),""+this.depositAmount);
            }
        }

        let deposit: Deposit = new Deposit();
        deposit.depositAmount = ""+this.depositAmount;

        let startDateDescription = '';
        if(Utils.isValidDate(startDate)) {
            startDateDescription = moment(startDate, "YYYY/MM/DD").format('MMMM DD, YYYY');
        }

        deposit.depositName = startDateDescription + ' deposit interest';

        interestRowList.push(...this.calculateInterestOnDeposit(matter.interestRates, deposit,  startDate, this.interestCalculatedToDate));
        this.removeHeaderDepositAmountForOldCondo(interestRowList);
        return interestRowList;
    }
    removeHeaderDepositAmountForOldCondo(interestRowList : any[]): void{
        let indexOfDepositAmountRow = interestRowList.findIndex(item => !!item.header);
        if(indexOfDepositAmountRow > -1){
            interestRowList.splice(indexOfDepositAmountRow ,1);
        }
    }

    //This method calculates the interest on the interest calculated for all the deposits
    generateInterestOnDepositInterestForCondo(matter : Matter , isAdjustmentModeFinal : boolean) : any[] {
        let depositInterestAdjustment = (matter.isProvinceABWithProject || (matter.isPurchase && matter.matterLink && matter.isMatterProvinceAB))
            ? matter.firstInterestOnDepositUnderOldCondoSoa
            : matter.allStatementAdjustments.find(item => item.isInterestOnDepositCondo());
        let interestRowList = [];
        if(depositInterestAdjustment) {
            let startDate = this.getStartDateForInterestOnDepositsInterest(matter);
            let deposit: Deposit = new Deposit();
            if((matter.isProvinceABWithProject || (matter.isPurchase && matter.matterLink && matter.isMatterProvinceAB))){
                deposit.depositAmount = depositInterestAdjustment.amount + '';
            } else {
                deposit.depositAmount = depositInterestAdjustment.soAdjInterestOnDepositCondo.getTotalInterestOnDeposit(matter , isAdjustmentModeFinal) + '';
            }

            let startDateDescription = '';
            if(Utils.isValidDate(startDate)) {
                startDateDescription = moment(startDate, "YYYY/MM/DD").format('MMMM DD, YYYY');
            }

            deposit.depositName = startDateDescription + ' deposit interest';

            interestRowList.push(...this.calculateInterestOnDeposit(matter.interestRates, deposit,  startDate, this.getEndDateForInterestOnDepositsInterest(matter)))
        }
        return interestRowList;
    }

    //This method calculates the interest on all the deposits
    generateInterestOnDepositForCondo(matter : Matter , isAdjustmentModeFinal : boolean, onlyTheOldestOne: boolean = false) : any[] {
        let depositAdjustment = matter.statementOfAdjustments.find(item => item.isDepositAdjustment());
        let interestRowList = [];
        if(depositAdjustment) {

            let depositList = matter.matterPropertyWithCondo  ? (isAdjustmentModeFinal ? matter.matterPropertyWithCondo.depositsWithoutNSForNS :  matter.matterPropertyWithCondo.depositsWithoutNSForNSorDepositOnOcc): [];

            if (depositList && depositList.length > 0) {
                let depositOldestDate = depositList[0].depositDate;
                depositList.forEach(item => {

                    if (item.depositDate) {

                        let depositDate = item.depositDate;
                        let closingDate = this.getUpdatedDateOfField(matter);

                        if (onlyTheOldestOne){
                            if ((moment(depositDate).isSameOrBefore(moment(depositOldestDate)))){
                                depositOldestDate = depositDate;
                                interestRowList = [];
                                interestRowList.push(...this.calculateInterestOnDeposit(matter.interestRates, item, depositDate, closingDate));
                            }
                        } else {
                            interestRowList.push(...this.calculateInterestOnDeposit(matter.interestRates, item, depositDate, closingDate));
                        }
                    }

                });

            }
        }
        return interestRowList;
    }

    calculateInterestOnDeposit(interestRates : InterestRate[], deposit: Deposit, depositDateStr: string, closingDateStr: string): any[] {
        interestRates = interestRates.sort((val1, val2) => {
            return <any>new Date(val1.effectiveFrom) - <any> new Date(val2.effectiveFrom);
        });
        let interestRowList = [];

        if (!deposit.depositAmount) {
            deposit.depositAmount = "0"
        }

        let interestText = (deposit.depositName ? deposit.depositName + ' ' : '');
        let interestRow = {
            text: interestText,
            amount: undefined,
            header: true,
            headerAmount: deposit.depositAmount
        };
        interestRowList.push(interestRow);

        let depositDate = depositDateStr && Utils.isValidDate(depositDateStr) ? moment(depositDateStr) : undefined;
        let closingDate = closingDateStr && Utils.isValidDate(closingDateStr) ? moment(closingDateStr) : undefined;

        if (depositDate && closingDate) {
            interestRates.forEach((interestRate, index) => {
                let nextInterestRate: InterestRate;
                if (index + 1 < interestRates.length) {
                    nextInterestRate = interestRates[index + 1]
                }
                let startingPoint;
                let endingPoint;
                if (nextInterestRate) {
                    if (depositDate.isSameOrBefore(moment(interestRate.effectiveFrom))) {
                        startingPoint = (moment(interestRate.effectiveFrom));
                    }
                    else if (depositDate.isSameOrAfter(moment(interestRate.effectiveFrom)) && depositDate.isSameOrBefore(moment(nextInterestRate.effectiveFrom))) {
                        startingPoint = depositDate;
                    }
                    if (closingDate.isSameOrBefore(moment(nextInterestRate.effectiveFrom))
                        && closingDate.isSameOrAfter(moment(interestRate.effectiveFrom))) {
                        //endingPoint = closingDate.subtract(1, "days");
                        endingPoint = closingDate;
                    }
                    else if (closingDate.isSameOrAfter(moment(nextInterestRate.effectiveFrom))) {
                        //Get the End date until the interest rate is effective (A day before the Interest Rate EffectiveFrom)
                        endingPoint = moment(nextInterestRate.effectiveFrom).subtract(1, "days");

                    }
                }
                else {

                    if (depositDate.isSameOrBefore(moment(interestRate.effectiveFrom))) {
                        startingPoint = (moment(interestRate.effectiveFrom));
                    }
                    else if (depositDate.isAfter(moment(interestRate.effectiveFrom))) {
                        startingPoint = depositDate;
                    }

                    if (closingDate.isSameOrAfter(moment(interestRate.effectiveFrom))) {
                        //endingPoint = closingDate.subtract(1, "days");
                        endingPoint = closingDate;
                    }

                }
                if (startingPoint && endingPoint) {
                    let dateDifference = endingPoint.diff(startingPoint, 'days');
                    let interestAmount = 0;
                    if (dateDifference >= 0) {
                        //To include the last day in case it is not closing date, since date difference exclude the last day
                        if(!endingPoint.isSame(closingDate)){
                            dateDifference = dateDifference +1;
                        }
                        interestAmount = MortgageUtil.calculateInterest(startingPoint.format("YYYY/MM/DD"), endingPoint.format("YYYY/MM/DD"), Number(deposit.depositAmount), interestRate.interestRate,  !endingPoint.isSame(closingDate));
                        let interestText = startingPoint.format('MMM DD/YY') + '-' + (endingPoint.isSame(closingDate)? endingPoint.subtract(1, "days").format('MMM DD/YY') : endingPoint.format('MMM DD/YY')) + '  ' + dateDifference + ' days' + ' @ ' + interestRate.interestRate + '%';
                        let interestTableItem = startingPoint.format("MMMM DD, YYYY") + '_' + endingPoint.format("MMMM DD, YYYY") + '_' + interestRate.interestRate;
                        let interestRow = {text: interestText, amount: interestAmount, interestTableItem: interestTableItem};
                        interestRowList.push(interestRow);
                    }

                }
            });
        }

        return interestRowList;
    }



    getTotalInterestOnDepositForOnDepositForOldCondo(matter : Matter , isAdjustmentModeFinal : boolean) : number  {
        return this.generateInterestOnDepositForOldCondo(matter , isAdjustmentModeFinal).filter(item => item.amount != undefined).reduce((sum, i) => {
            return sum + (i.amount)
        }, 0);
    }

    getTotalInterestOnDeposit(matter : Matter , isAdjustmentModeFinal : boolean) : number {
        if(this.isInterestOnDepositInterestAdjustment()) {
            return this.generateInterestOnDepositInterestForCondo(matter , isAdjustmentModeFinal).filter(item => item.amount != undefined).reduce((sum, i) => {
                return sum + (i.amount)
            }, 0);
        }else if(this.isInterestOnDepositUnderOldCondoAdjustment()){
            return this.getTotalInterestOnDepositForOnDepositForOldCondo(matter , isAdjustmentModeFinal);
        }
        else {
            return this.generateInterestOnDepositForCondo( matter , isAdjustmentModeFinal).filter(item => item.amount != undefined).reduce((sum, i) => {
                return sum + (i.amount)
            }, 0);
        }

    }

    isDepositDateInValid(matter : Matter , isAdjustmentModeFinal : boolean) : boolean {
        let interestCalculatedFrom = this.getUpdatedDateOfField(matter);
        let depositList = matter && matter.matterPropertyWithCondo  ? (isAdjustmentModeFinal ? matter.matterPropertyWithCondo.depositsWithoutNSForNS :  matter.matterPropertyWithCondo.depositsWithoutNSForNSorDepositOnOcc): [];
      return  !interestCalculatedFrom || !Utils.isValidDate(interestCalculatedFrom) || (interestCalculatedFrom && Utils.isValidDate(interestCalculatedFrom) && depositList   && depositList.some(item => item.depositDate != undefined &&  Utils.isValidDate(item.depositDate) &&  moment(item.depositDate).isAfter( moment(interestCalculatedFrom))));
    }

    isInterestOnDepositInterestOutOfRange(matter : Matter , isAdjustmentModeFinal : boolean) : boolean {
        let startDate = this.getStartDateForInterestOnDepositsInterest(matter);
        let endDate = this.getEndDateForInterestOnDepositsInterest(matter);
        return !startDate || !Utils.isValidDate(startDate) || (Utils.isValidDate(startDate) && ((!endDate || !Utils.isValidDate(endDate)) || (Utils.isValidDate(endDate) &&  moment(startDate).isAfter( moment(endDate)))));
    }

    areDatesInValidForInterestOnDepositInterest(matter : Matter , isAdjustmentModeFinal : boolean) : boolean {
        let startDate = this.getStartDateForInterestOnDepositsInterest(matter);
        let endDate = this.getEndDateForInterestOnDepositsInterest(matter);
        return !startDate || !Utils.isValidDate(startDate) || (Utils.isValidDate(startDate) && ((!endDate || !Utils.isValidDate(endDate)) || (Utils.isValidDate(endDate) &&  moment(startDate).isSameOrAfter( moment(endDate)))));
    }

    areDatesInValidForInterestOnDepositUnderOldCondo(matter : Matter , isAdjustmentModeFinal : boolean) : boolean {
        let startDate = this.interestCalculatedFromDate;
        let endDate = this.interestCalculatedToDate;
        return !startDate || !Utils.isValidDate(startDate) || (Utils.isValidDate(startDate) && ((!endDate || !Utils.isValidDate(endDate))
            || (Utils.isValidDate(endDate) &&  (matter.isProjectSale ? moment(startDate).isSameOrAfter( moment(endDate)) : moment(startDate).isAfter(moment(endDate))))));
    }

    isInterestOnDepositInterestAdjustment(): boolean {
        return this.adjustmentType == 'INTEREST_ON_DEPOSITS_INTEREST';
    }

    isInterestOnDepositAdjustment(): boolean {
        return this.adjustmentType == 'INTEREST_ON_DEPOSITS';
    }

    isInterestOnDepositUnderOldCondoAdjustment(): boolean {
        return this.adjustmentType == 'INTEREST_ON_DEPOSIT_UNDER_OLD_CONDO';
    }
}
