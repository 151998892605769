import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {SoAdjVTBMortgage, StatementOfAdjustmentDisplayItemLine} from '../model';

export class StatementAdjustmentDisplayVTBMortgageBuilder {
    static getDisplayItems(soaAdj: SoAdjVTBMortgage, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[]{
        let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
        let principalAmount: number = mainBuilder.matter.getMortgagePrincipalByMortgageId(soaAdj.mortgageId);

        if (! toBeUsedOnModal && principalAmount > 0){

            if (soaAdj.firstInput && soaAdj.firstInput.length > 0) {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    soaAdj.firstInput,
                    ''
                ));
            }

            if (soaAdj.secondInput && soaAdj.secondInput.length > 0) {
                sadItemDetails.push(mainBuilder.getNewItemLine(
                    soaAdj.secondInput,
                    ''
                ));
            }
        }

        sadItemDetails.push(mainBuilder.getNewItemLine(
            `Credit ${mainBuilder.purchaserLabel}`,
            ''
        ));

        return sadItemDetails;
    }
}
