import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Telephone} from '../../matters/shared/telephone';
import {AddressTypes} from '../../matters/shared/address-types';
import {projectConsts} from './project-consts';
import {TelephoneTypes} from '../../matters/shared/telephone-types';
import {Address} from '../../matters/shared/address';
import moment from 'moment';

export class DocumentRegistration extends BaseEntity {

    registryOrLt : string;
    registrationMethod: string;
    transferDocumentType: string;
    chargeDocumentType: string;
    createXmlForVtbMtg: string;
    dischargeForExistingMtgs: string;
    ignoreCreditsInDeedConsideration: string;
    isTransferFromThirdParty: string;
    docsForDirection: string;
    docsForSoa: string;
    docsForTl: string;
    buildNameAndAddressSource: string;
    buiderLegalName: string;
    builderNumber: string;
    builderRt: string;
    builderAddress: Address;
    builderTelephone: Telephone;
    hstPeriodIsCalendarMonthOfFinalClosing: boolean;
    hstPeriodFrom: Date;
    formattedHstPeriodFrom: string;
    hstPeriodTo: Date;
    formattedHstPeriodTo: string;
    authroizedSigningOfficer: string;
    title: string;
    dateOfSignature: Date;
    formattedDateOfSignature: string;


    constructor(docReg?: DocumentRegistration) {
        super(docReg);
        if (docReg) {
            for (let prop in docReg) {
                if (docReg.hasOwnProperty(prop)) {
                    this[prop] = docReg[prop];
                }

                if(docReg.hstPeriodFrom){
                    this.hstPeriodFrom = docReg.hstPeriodFrom;
                    this.formattedHstPeriodFrom = moment(docReg.hstPeriodFrom).format("YYYY/MM/DD");
                }
                if(docReg.hstPeriodTo){
                    this.hstPeriodTo = docReg.hstPeriodTo;
                    this.formattedHstPeriodTo = moment(docReg.hstPeriodTo).format("YYYY/MM/DD");
                }
                if(docReg.dateOfSignature){
                    this.dateOfSignature = docReg.dateOfSignature;
                    this.formattedDateOfSignature = moment(docReg.dateOfSignature).format("YYYY/MM/DD");
                }
            }
        }else{
            this.registryOrLt = projectConsts.registryLt.landTitles;
            this.registrationMethod = projectConsts.registrationMethods.ELECTRONIC;
            this.transferDocumentType = projectConsts.transferDocumentTypes.TRANSFER;
            this.chargeDocumentType = projectConsts.chargeDocumentTypes.CHARGE;
            this.createXmlForVtbMtg = projectConsts.yesNoValues.Yn;
            this.dischargeForExistingMtgs = projectConsts.yesNoValues.Ny;
            this.ignoreCreditsInDeedConsideration = projectConsts.yesNoValues.Ny;
            this.isTransferFromThirdParty = projectConsts.yesNoValues.Ny;
            this.docsForDirection = projectConsts.yesNoValues.Ny;
            this.docsForSoa = projectConsts.yesNoValues.Ny;
            this.docsForTl = projectConsts.yesNoValues.Ny;
            this.buildNameAndAddressSource = projectConsts.builderNameAddressSources.SAME_AS_VENDOR_TAB;
            this.hstPeriodIsCalendarMonthOfFinalClosing = false;
            this.builderAddress = new Address();
            this.builderAddress.addressTypeCode = AddressTypes.mailing;
            this.builderAddress.provinceCode = projectConsts.provinces.ON;
            this.builderAddress.country = projectConsts.countries.CANADA;
            this.builderTelephone = new Telephone();
            this.builderTelephone.phoneTypeCode = TelephoneTypes.work;
            this.formattedHstPeriodFrom = null;
            this.formattedHstPeriodTo = null;
            this.formattedDateOfSignature = null;
        }
    }

    isRegistrationMethodElectronic(): boolean{
        return this.registrationMethod == projectConsts.registrationMethods.ELECTRONIC;
    }

    isTransferFromThirdPartySelected(): boolean{
        return this.isTransferFromThirdParty === projectConsts.yesNoValues.YES;
    }

    isDischargeForExistingMtgs(): boolean{
        return this.dischargeForExistingMtgs == projectConsts.yesNoValues.YES || this.dischargeForExistingMtgs == projectConsts.yesNoValues.Yn;
    }

}
