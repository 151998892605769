import {AfterViewInit, Component, NgZone, ViewChild} from '@angular/core';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogRef, ModalComponent} from 'ngx-modialog-7';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Matter, User} from '../../shared';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {ConnectRecipientRoleType, SharedDocumentsPackage, SharedDocumentsPackageType} from '../shared-documents-package';
import {MatterParticipant} from '../../shared/matter-participant';
import {Contact} from '../../shared/contact';
import {EmailFieldService, EmailKeys} from '../../../shared-main/email-field/email-field-service';
import {ContactQueryService} from '../../../contact/contact-query.service';
import {Observable} from 'rxjs';
import {matterParticipantRoleLabels, MatterParticipantRoleTypes} from '../../shared/matter-participant-role-types';
import {
    EmailCondoSourceType,
    EmailManagementSourceType,
    EmailMortgageBrokerSourceType,
    EmailMortgageeSourceType,
    MISSING_EMAIL
} from '../../../shared-main/constants';
import {ShareDocsUserModalResult} from './share-docs-user-modal-result';
import {Mortgage} from '../../shared/mortgage';
import Utils from '../../../shared-main/utils';
import {AutoComplete} from 'primeng/autocomplete';
import {PrecedentService} from '../../mortgages/mortgage/precedent/precedent.service';
import {Precedent} from '../../shared/precedent';
import {PrecedentTypes} from '../../shared/mortgage-precedent-type';
import {DocumentUtilityService} from '../document-utility.service';
import {DocumentProductionUtil} from '../../shared/document-production/document-production-util';
import {ContactName} from '../../shared/contact-name';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {DPError} from '../../../shared/error-handling/dp-error';

class DpShareDocsUsersModalContext extends BSModalContext {
    matter: Matter;
    alreadySentToConnectRecipientEmail: string [];
    alreadySentToUnityRecipients: boolean;
    documentNameList: string [];
}

export class Label {
    contactName: string;
    typeName: string;
}

export interface SelectRecipientItem {
    label: Label;
    value: Contact;
    connectRecipientRoleType : ConnectRecipientRoleType;
    emailKey : string;
    missingEmail: boolean;
}

@AutoUnsubscribe()
@Component({
    selector: 'dp-share-docs-users-modal-content',
    templateUrl: './share-docs-users-modal.component.html',
    styleUrls: ['./share-docs-users-modal.styles.scss'],
    providers: [ErrorService, EmailFieldService]
})
export class ShareDocsUsersModalComponent implements ModalComponent<DpShareDocsUsersModalContext>, AfterViewInit {
    @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
    @ViewChild('recipientAutoComplete') recipientAutoComplete: AutoComplete;
    context: DpShareDocsUsersModalContext;
    recipientList: SelectRecipientItem[] = [];
    selectedRecipientContact: Contact;
    solicitorMPList: MatterParticipant[] = [];
    solicitorSourceContactList: Contact[] = [];
    otherPartyLawClerkContact: Contact;
    subject: string;
    message: string;

    unitySolicitorsAndLawClerksList: number[] = [];
    //Sometime selectedRecipient is SelectRecipientItem, and sometime selectedRecipient is string(contactName)
    selectedRecipient: any = null;
    selectedRecipientName: string;
    selectedRecipientRole: string;
    selectedRecipientEmailKey: string;
    isEmailMissing: boolean;
    selectedConnectRecipientRoleType: ConnectRecipientRoleType;
    messagePrecedents: Precedent[]=[];
    selectedPrecedent:any;

    constructor(public dialog: DialogRef<DpShareDocsUsersModalContext>,
                public emailFieldService: EmailFieldService,
                public contactQueryService: ContactQueryService,
                public documentUtility: DocumentUtilityService,
                public ngZone: NgZone,
                public errorService: ErrorService,
                public messagePrecedentService: PrecedentService,
                public dialogService: DialogService
    ) {
        this.context = dialog.context;
    }

    ngOnInit() {
        this.emailFieldService.matter = this.context.matter;
        this.recipientList.push({label : null, value : null,connectRecipientRoleType : null,emailKey : null, missingEmail: false});
        //this.message = this.unityMessageBlock;

        this.addParticipantsToRecipients();
        this.getMessagePrecedents();
    }

    updateEditorMessage(): void{
        if(this.isUnitySolicitorOrLawClerk()){
            this.message ='';
            if(this.selectedPrecedent && this.selectedPrecedent != ''){
                let messagePrecedent = this.getSelectedPrecedent();
                if(messagePrecedent){
                    this.message = messagePrecedent.description;
                }
            }
            this.message += this.unityMessageBlock;
        }
    }

    get precedentText(){
        return this.selectedPrecedent ?this.getSelectedPrecedent().description:'';
    }

    getSelectedPrecedent(){
       return this.messagePrecedents.find(item => item.id == this.selectedPrecedent) ;
    }

    get unityMessageBlock(): string {
        let sessionUser: User = Utils.getUserFromSession();
        return '\n\n' + sessionUser.unitySignatureBlock;
    }

    async getMessagePrecedents() : Promise<void> {
        //Get precedents from backend
        let precedents : Precedent[]= await this.messagePrecedentService.getPrecedents().toPromise();
        if(precedents){
            this.messagePrecedents = precedents.filter(precedent => precedent.precedentType === PrecedentTypes.MESSAGE);
        }
    }

    ngAfterViewInit(): void {

    }

    // The order is MainClients, OtherSide Solicitor, Other Side Law Clerk, Mortgage solicitors, Mortgagees, mortgage brokers,
    // Condo Corporation, Management Company, Insurance Broker, Insurance Company, Other Side parties
    async addParticipantsToRecipients(): Promise<void> {
        // all the other participants without active user would be sent an email
        //All Clients
        this.addMainClientsToRecipients();

        // Active solicitors/lawClerks and no active solicitors/lawClerks are different ways to add into recipients
        // Firstly need to get the active solicitors/lawClerks from backend
        // With active users solicitors, use source contact(it also needs to get them from back end);  Without active users solicitors, use snapshot contact;
        // The solicitor/lawClerks without active user would be treated the same as all the other participants.  In that they are sent an email
        // The solicitor/lawClerks with active user send a Unity message.
        await this.addSolicitorsAndLawClerksListToRecipients();

        // All Mortgagees
        this.addMortgageesToRecipients();

        // Mortgage broker
        this.addMortgageBrokersToRecipients();

        this.addListingBrokerAgentToRecipients();

        this.addListingBrokerCompanyToRecipients();

        this.addPurchaserBrokerAgentToRecipients();

         this.addPurchaserBrokerCompanyToRecipients();

        // Condo Corporation
        this.addCondoCorporationToRecipients();

        // Management Company for a Condo Corporation
        this.addManagementCompanyToRecipients();

        // Broker
        this.addBrokerToRecipients();

        // Insurer
        this.addInsurerToRecipients();

        //All Other side parties
        this.addOtherSideClientsToRecipients();

        this.addRealEstateAgent();

        this.addRealEstateBroker();

    }

    addParticipantToRecipient(matterParticipant: MatterParticipant, title: string,connectRecipientRoleType : ConnectRecipientRoleType, emailKey?: string) {
        if (matterParticipant && matterParticipant.contact ) {
            let label: Label = this.getRecipientLabel(this.getRecipientContactName(matterParticipant), title);
            this.addToRecipientList(label, matterParticipant.contact,connectRecipientRoleType, emailKey);
        }
        this.addChildMatterParticipants(matterParticipant, connectRecipientRoleType, emailKey);
    }

    addMainClientsToRecipients() {
        //All Clients (Topic B) are listed first - primary client first followed by any other clients in alphabetic order.
        this.context.matter.mainClients
            .sort((a, b) => {
                if (a.primary) {
                    return -1;
                } else if (b.primary) {
                    return 1;
                } else {
                    //Although fullName should null, it still checks in case there is bad data
                    let aLowerCaseName: string = a.contact && a.contact.fullName && a.contact.fullName.toLowerCase();
                    let bLowerCaseName: string = b.contact && b.contact.fullName && b.contact.fullName.toLowerCase();
                    if (!aLowerCaseName) {
                        return 1;
                    } else if (!bLowerCaseName) {
                        return -1;
                    } else {
                        if (aLowerCaseName < bLowerCaseName) {
                            return -1;
                        } else if (aLowerCaseName == bLowerCaseName) {
                            return 0;
                        } else {
                            return 1;
                        }
                    }
                }
            }).forEach(item => this.addParticipantToRecipient(item, item.primary ? ('Primary ' + this.context.matter.mainClientTitle) : this.context.matter.mainClientTitle,'CLIENT', EmailKeys.matterOpening),);
    }

    addOtherSideClientsToRecipients() {
        this.context.matter.otherSideClients
            .forEach(item => this.addParticipantToRecipient(item, item.primary ? ('Primary ' + this.context.matter.otherPartyTitle) : this.context.matter.otherPartyTitle,'VENDOR', EmailKeys.matterOpening));
    }

    addListingBrokerAgentToRecipients(){
        if (this.context.matter && this.context.matter.isSale && this.context.matter.matterParticipants) {
            this.context.matter.matterParticipants
                .filter(mp => mp.isRealEstateAgent)
                .forEach(mp =>
                    this.addParticipantToRecipient(mp, 'Listing Real Estate Agent', 'REAL_ESTATE_AGENT', EmailKeys.matterOpening)
                );
        }
    }


    addListingBrokerCompanyToRecipients(){
        if (this.context.matter && this.context.matter.isSale && this.context.matter.matterParticipants) {
            this.context.matter.matterParticipants
                .filter(mp => mp.isRealEstateBroker)
                .forEach(mp =>
                    this.addParticipantToRecipient(mp, 'Listing Real Estate Broker', 'REAL_ESTATE_BROKER',  EmailKeys.matterOpening)
                );
        }
    }


    addPurchaserBrokerAgentToRecipients(){
        if (this.context.matter && this.context.matter.isSale && this.context.matter.matterParticipants) {
            this.context.matter.matterParticipants
                .filter(mp => mp.isOtherPartyRealEstateAgent)
                .forEach(mp =>
                    this.addParticipantToRecipient(mp, 'Purchaser Real Estate Agent', 'OTHER_PARTY_REAL_ESTATE_AGENT',  EmailKeys.matterOpening)
                );
        }
    }


    addPurchaserBrokerCompanyToRecipients(){
        if (this.context.matter && this.context.matter.isSale && this.context.matter.matterParticipants) {
            this.context.matter.matterParticipants
                .filter(mp => mp.isOtherPartyRealEstateBroker)
                .forEach(mp =>
                    this.addParticipantToRecipient(mp, 'Purchaser Real Estate Broker', 'OTHER_PARTY_REAL_ESTATE_BROKER',  EmailKeys.matterOpening)
                );
        }
    }

    addRealEstateAgent() {
        if (this.context.matter && this.context.matter.isPurchase && this.context.matter.matterParticipants) {
            this.context.matter.matterParticipants
                .filter(mp => mp.isRealEstateAgent)
                .forEach(mp =>
                    this.addParticipantToRecipient(mp, 'Purchaser Real Estate Agent', 'REAL_ESTATE_AGENT',EmailKeys.matterOpening)
                );
        }
    }

    async addRealEstateBroker(): Promise<void> {
        if (this.context.matter && this.context.matter.isPurchase && this.context.matter.selectedBrokerId) {
            const realEstateBroker: Contact  = await (this.contactQueryService.getContactForMatter(this.context.matter.selectedBrokerId)).toPromise();
            if (realEstateBroker && realEstateBroker.firstEmail) {
                this.addContactToRecipients(realEstateBroker, 'Purchaser Real Estate Broker', 'REAL_ESTATE_BROKER', null, EmailKeys.matterOpening);
            }
        }
    }

    addContactToRecipients(contact: Contact, title: string, connectRecipientRoleType : ConnectRecipientRoleType, extraName?: string, emailKey?: string ) {
        if (contact && contact.firstEmail && Utils.validateEmail(contact.firstEmail)) {
            let label: Label = this.getRecipientLabel(contact.getOrganizationNameOrFullName(), title);
            label.contactName = extraName ? label.contactName + extraName : label.contactName;
            this.addToRecipientList(label, contact, connectRecipientRoleType, emailKey);
        }
    }

    addToRecipientList(label: Label, contact: Contact, connectRecipientRoleType : ConnectRecipientRoleType, emailKey?: string): void {
        if (contact && label && this.context.alreadySentToConnectRecipientEmail && this.context.alreadySentToConnectRecipientEmail.indexOf(contact.firstEmail) < 0) {
            let missingEmail: boolean = !(contact.firstEmail && Utils.validateEmail(contact.firstEmail));
            label.contactName = (contact.firstEmail && Utils.validateEmail(contact.firstEmail))? label.contactName : (label.contactName + MISSING_EMAIL);
            this.recipientList.push({label : label, value : contact, connectRecipientRoleType : connectRecipientRoleType, emailKey : emailKey, missingEmail: missingEmail});
        }
    }

    getRecipientContactName(matterParticipant: MatterParticipant) {
        if (matterParticipant && matterParticipant.contact) {
            return matterParticipant.contact.getOrganizationNameOrFullName();
        }
        return '';
    }

    getRecipientLabel(contactName: string, typeName: string): Label {
        return {contactName: contactName, typeName: typeName};
    }

    getRecipientName(contactName: string, typeName: string): string {
        if (typeName && typeName[0] === '&') {
            return `${contactName}  ${typeName}`;
        }
        return typeName ? contactName + ' - ' + typeName : contactName;
    }

    addInsurerToRecipients() {
        this.addParticipantToRecipient(this.context.matter.insurerMatterParticipant, 'Insurer','INSURER', EmailKeys.fireInsurance);
    }

    addBrokerToRecipients() {
        this.addParticipantToRecipient(this.context.matter.brokerMatterParticipant, 'Broker','BROKER', EmailKeys.fireInsurance);
    }

    // addRealEstateAgentToRecipients() {
    //     this.addParticipantToRecipient(this.context.matter.contact, 'RealEstateAgent');
    // }
    //
    // addRealEstateBrokerToRecipients() {
    //     this.addParticipantToRecipient(this.context.matter.realEstateBroker, 'RealEstateBroker');
    // }
    //
    // addPurchaserRealEstateAgentToRecipients() {
    //     this.addParticipantToRecipient(this.context.matter.purchaserRealEstateAgent, 'PurchaserRealEstateAgentAgent');
    // }
    //
    // addPurchaserRealEstateBrokerToRecipients() {
    //     this.addParticipantToRecipient(this.context.matter.purchaserRealEstateBroker, 'PurchaserRealEstateBroker');
    // }

    addCondoCorporationToRecipients() {
        const matterParticipant: MatterParticipant = this.context.matter.condoCorporation;
        let attentionMP: MatterParticipant;
        if (matterParticipant) {
            if (matterParticipant.contact.isSelfManaged()) {
                attentionMP = this.context.matter.findMatterParticipant(MatterParticipantRoleTypes.CONDO_CORPORATION_ATTENTION);
            }
            this.addCondoManagementCompanyToRecipients(matterParticipant, attentionMP);
        }
    }

    addManagementCompanyToRecipients() {
        const condoCorporation: MatterParticipant = this.context.matter.condoCorporation;
        // Only save matter,  the MANAGEMENT_COMPANY  matterParticipant will be removed if it is redundancy
        if (condoCorporation && condoCorporation.contact && (condoCorporation.contact.selfManagedManagementCompanyType == 'MANAGEMENT_COMPANY')) {
            let attentionMP: MatterParticipant;
            const managementCompany: MatterParticipant = this.context.matter.managementCompany;
            if (managementCompany) {
                attentionMP = this.context.matter.findMatterParticipant(MatterParticipantRoleTypes.MANAGEMENT_COMPANY_ATTENTION);
                this.addCondoManagementCompanyToRecipients(managementCompany, attentionMP);
            }

        }
    }

    addCondoManagementCompanyToRecipients(matterParticipant: MatterParticipant, attentionMP: MatterParticipant) {
        let title: string = 'Management Company';
        if (matterParticipant) {
            if (attentionMP) {
                //Because the attention email may be SAME_AS_CONDO_CORPORATION or SAME_AS_MANAGEMENT_COMPANY
                // and avoid to mess up the original data, do a copied and put the parent email to the attention email
                let attentionContact: Contact = new Contact(attentionMP.contact);
                if (attentionMP.matterParticipantRole == MatterParticipantRoleTypes.CONDO_CORPORATION_ATTENTION) {
                    title = 'Condo Corporation';
                    if (attentionContact.email == EmailCondoSourceType.SAME_AS_CONDO_CORPORATION) {
                        attentionContact.email = matterParticipant.contact.firstEmail;
                    }
                } else {
                    if (attentionContact.email == EmailManagementSourceType.SAME_AS_MANAGEMENT_COMPANY) {
                        attentionContact.email = matterParticipant.contact.firstEmail;
                    }
                }
                this.addContactToRecipients(attentionContact, title,'CONDO_CORPORATION', null , EmailKeys.matterOpening);
            } else {
                if (matterParticipant.matterParticipantRole == MatterParticipantRoleTypes.CONDO_CORPORATION) {
                    title = 'Condo Corporation';
                }
                this.addParticipantToRecipient(matterParticipant, title,'CONDO_CORPORATION', EmailKeys.matterOpening);
            }
        }
    }

    getMortgageTitle(mortgage: Mortgage): string {
        if (mortgage && mortgage.isExistingMortgage() && this.context.matter.existingMortgages && this.context.matter.existingMortgages.length > 0) {
            return this.getExistingMortgageTitle(mortgage);
        }
        else if (mortgage.mortgagePriority) {
            return Utils.getOrdinal(mortgage.mortgagePriority) + ' Mortgage ';
        }
        else {
            return '';
        }
    }


    getExistingMortgageTitle(mortgage: Mortgage): string {
        let index = this.context.matter.existingMortgages.findIndex(item => item.id == mortgage.id);
        if (index > -1) {
            return Utils.getOrdinal(index+1) + ' Existing Mortgage ';
        } else {
            return '';
        }
    }

    addMortgageesToRecipients() {
        this.context.matter.mortgages.concat(this.context.matter.existingMortgages)
            .slice()
            .sort((a: Mortgage, b: Mortgage) => a.mortgagePriority - b.mortgagePriority)
            .forEach(mortgage => {
                // Only save matter,  the mortgagee will be removed if it is redundancy
                if (!mortgage.isMortgageePrivateLender()) {
                    let mortgageeMP = this.context.matter.getMatterParticipantByRoleAndMortgage('MORTGAGEE', mortgage);
                    if (mortgageeMP) {
                        let attentionMP: MatterParticipant;
                        // Only save matter,  the attention will be removed if it is redundancy
                        // The attention of mortgagee is saved in MatterParticipant
                        if (mortgage.isMortgageeAnInstitution() && !this.context.matter.isLawFirmVisible(mortgage)) {
                            attentionMP = this.context.matter.getMatterParticipantByRoleAndMortgage('MORTGAGEE_ATTENTION', mortgage);
                        }
                        if (attentionMP) {
                            //Because the attention email may be SAME_AS_MORTGAGEE and avoid to mess up the original data,
                            // do a copied and put the parent email to the attention email
                            let attentionContact: Contact = new Contact(attentionMP.contact);
                            if (attentionContact.email == EmailMortgageeSourceType.SAME_AS_MORTGAGEE) {
                                attentionContact.email = mortgageeMP.contact.firstEmail;
                            }
                            this.addContactToRecipients(attentionContact, this.getMortgageTitle(mortgage) + 'Mortgagee','MORTGAGEE', null , EmailKeys.mortgagee);
                        } else {
                            this.addParticipantToRecipient(mortgageeMP, this.getMortgageTitle(mortgage) + 'Mortgagee','MORTGAGEE', EmailKeys.mortgagee);
                        }
                    }
                }
                else if (mortgage.isMortgageePrivateLender()) {
                    let privateLenderMP = this.context.matter.getMatterParticipantByRoleAndMortgage('PRIVATE_LENDER', mortgage);
                    if (privateLenderMP) {
                        this.addParticipantToRecipient(privateLenderMP, this.getMortgageTitle(mortgage) + 'Mortgagee','MORTGAGEE',  EmailKeys.mortgagee);
                    }
                }
            });
        this.recipientList.map(item => item.value && item.value.contactType == 'SOLICITOR');
    }


    addMortgageBrokersToRecipients() {
        this.context.matter.mortgages
            .slice()
            .sort((a: Mortgage, b: Mortgage) => a.mortgagePriority - b.mortgagePriority)
            .forEach(mortgage => {
                let mortgageBrokerMP = this.context.matter.getMortgageBroker(mortgage);
                if (mortgageBrokerMP) {
                    let attentionContact: Contact;
                    // The attention of Mortgage Broker is Not saved in MatterParticipant
                    // It saves the attention id(subContact id) into mortgage.mortgageBrokerAttentionId
                    // We need use mortgageBrokerAttentionId to find the attention in subContact list
                    if (mortgage.mortgageBrokerAttentionId) {
                        attentionContact = mortgageBrokerMP.contact
                            && mortgageBrokerMP.contact.subContacts
                            && mortgageBrokerMP.contact.subContacts.find(item => item.id == mortgage.mortgageBrokerAttentionId);
                    }

                    if (attentionContact) {
                        //Because the attention email may be SAME_AS_MORTGAGE_BROKER and avoid to mess up the original data,
                        // do a copied and put the parent email to the attention email
                        attentionContact = new Contact(attentionContact);
                        if (attentionContact.email == EmailMortgageBrokerSourceType.SAME_AS_MORTGAGE_BROKER) {
                            attentionContact.email = mortgageBrokerMP.contact.firstEmail;
                        }
                        this.addContactToRecipients(attentionContact, (this.getMortgageTitle(mortgage) + 'Mortgage Broker'),'MORTGAGE_BROKER',null  ,EmailKeys.mortgagee);
                    } else {
                        this.addParticipantToRecipient(mortgageBrokerMP, (this.getMortgageTitle(mortgage) + 'Mortgage Broker'),'MORTGAGE_BROKER',EmailKeys.mortgagee);
                    }
                }

            });
    }

    async getSolicitorsLists(): Promise<void> {
        let solicitorMP: MatterParticipant = this.context.matter.otherPartySolicitor;
        if (solicitorMP && solicitorMP.contact && solicitorMP.contact.sourceContactId) {
            this.solicitorMPList.push(solicitorMP);
        }
        if (Array.isArray(this.context.matter.mortgages) && this.context.matter.mortgages.length > 0) {
            this.context.matter.mortgages
                .slice()
                .sort((a: Mortgage, b: Mortgage) => a.mortgagePriority - b.mortgagePriority)
                .forEach(mortgage => {
                    let mortgageSolicitorMP = this.context.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', mortgage);
                    if (mortgageSolicitorMP && mortgageSolicitorMP.contact && mortgageSolicitorMP.contact.sourceContactId) {
                        this.solicitorMPList.push(mortgageSolicitorMP);
                    }
                });
        }
        let getContactObservables: Observable<any>[];
        let solicitorsList: number[]
            = this.solicitorMPList.map(item => item.contact.sourceContactId);

        //It is better backend to have a new API which one call can sends back all contacts
        if (solicitorsList && solicitorsList.length > 0) {
            getContactObservables = solicitorsList.map((contactId: number) => this.contactQueryService.getContactForMatter(contactId));
            if (getContactObservables) {
                const sourceContacts: Contact[] = await Observable.forkJoin(getContactObservables).toPromise();
                if (Array.isArray(sourceContacts)) {
                    sourceContacts.forEach((res: any) => {
                        this.solicitorSourceContactList.push(res);
                    });
                }
            }
        }
    }

    // Active solicitors/lawClerks and no active solicitors/lawClerks are different ways to add into recipients
    // Firstly need to get the active solicitors/lawClerks from backend
    // With active users solicitors, use source contact(it also needs to get them from back end);  Without active users solicitors, use snapshot contact;
    // The solicitor/lawClerks without active user would be treated the same as all the other participants.  In that they are sent an email
    // The solicitor/lawClerks with active user send a Unity message.
    async addSolicitorsAndLawClerksListToRecipients(): Promise<void> {
        let contactIds: number[] = [];
        await this.getOtherPartyLawClerk();
        await this.getSolicitorsLists();

        //this.getSolicitorsLists() builds this.solicitorsSourceIdList
        if (this.solicitorMPList && this.solicitorMPList.length > 0) {
            contactIds = this.solicitorSourceContactList.filter(item => item.sourceContactId).map(item => item.sourceContactId);
        }

        if (this.otherPartyLawClerkContact && this.otherPartyLawClerkContact.sourceContactId) {
            contactIds.push(this.otherPartyLawClerkContact.sourceContactId);
        }

        if (contactIds && contactIds.length > 0) {
            this.unitySolicitorsAndLawClerksList = await this.contactQueryService.getContactIdsHavingActiveUser(contactIds , true).toPromise();
        }

        //Add solicitors to recipients
        this.addOtherSideSolicitorToRecipients();
        //Add law clerk to recipients
        this.addLawClerksToRecipients();
        //Add mortgage solicitor to recipients
        this.addMortgageSolicitorToRecipients();
    }

    addLawClerksToRecipients() {
        // let name : string;
        if (this.otherPartyLawClerkContact) {
            // name = this.getRecipientLabel(this.otherPartyLawClerkContact.getOrganizationNameOrFullName(), this.otherPartyLawClerkTitle);
            if (this.unitySolicitorsAndLawClerksList && this.unitySolicitorsAndLawClerksList.find(id => id === this.otherPartyLawClerkContact.sourceContactId)) {
                if (this.bothOtherPartyLawClerkAndSolicitorAreActiveUnityUsers()) {
                    // do not add the other party law clerk as it will be added as part of the other solicitor
                } else {
                    if (!this.context.alreadySentToUnityRecipients) {
                        this.addContactToRecipients(this.otherPartyLawClerkContact, `at ${this.context.matter.otherPartyTitle.toLowerCase()} law firm (Unity User))`, 'OTHER_PARTY_LAW_CLERK', null , EmailKeys.vendorsSolicitor);
                    }
                }
            } else {
                if (this.otherPartyLawClerkContact.firstEmail && Utils.validateEmail(this.otherPartyLawClerkContact.firstEmail)) {
                    this.addContactToRecipients(this.otherPartyLawClerkContact, `${this.context.matter.otherPartyTitle.toLowerCase()} ${this.otherPartyLawClerkTitle} (not a Unity User)`,'OTHER_PARTY_LAW_CLERK', null , EmailKeys.vendorsSolicitor);
                }
            }
        }
    }

    get otherPartyLawClerkTitle(): string {
        return this.context.matter.isMatterProvinceON ? 'Law Clerk' : 'Legal Assistant';
    }

    get otherPartyLawClerkUnityUserName(): string {
        if (this.isOtherPartyLawClerkAUnityUser) {
            return this.getRecipientName(this.otherPartyLawClerkContact.getOrganizationNameOrFullName(), '');
        }
        return undefined;
    }

    addSolicitorToRecipients(solicitorMP: MatterParticipant, title: string, connectRecipientRoleType : ConnectRecipientRoleType , extraName?: string) {
        let sourceContact = this.solicitorSourceContactList.find(source => source.id == solicitorMP.contact.sourceContactId);
        if (sourceContact) {
            if (this.unitySolicitorsAndLawClerksList.find(id => id == sourceContact.sourceContactId)) {
                if (!this.context.alreadySentToUnityRecipients) {
                    this.addContactToRecipients(sourceContact, title, connectRecipientRoleType, extraName, EmailKeys.vendorsSolicitor);
                }
            } else {
                if (solicitorMP.contact.firstEmail && Utils.validateEmail(solicitorMP.contact.firstEmail)) {
                    this.addContactToRecipients(solicitorMP.contact, title, connectRecipientRoleType, extraName, EmailKeys.vendorsSolicitor);
                }
            }
        }

    }

    addOtherSideSolicitorToRecipients() {
        if (Array.isArray(this.solicitorMPList) && this.solicitorMPList.length > 0) {
            this.solicitorMPList.forEach(item => {
                let title: string;
                let extraName: string;
                if (item.matterParticipantRole == MatterParticipantRoleTypes.OTHERPARTY_SOLICITOR) {

                    if (this.bothOtherPartyLawClerkAndSolicitorAreActiveUnityUsers()) {
                        // title = `& ${this.otherPartyLawClerkUnityUserName} at ${this.context.matter.otherPartyTitle.toLowerCase()} law firm (Unity User)`;
                        extraName = `& ${this.otherPartyLawClerkUnityUserName}`;
                        title = ` at ${this.context.matter.otherPartyTitle.toLowerCase()} law firm (Unity User)`;
                    } else if (this.isOtherPartySolicitorAUnityUser) {
                        title = ` at ${this.context.matter.otherPartyTitle.toLowerCase()} law firm (Unity User)`;
                    } else {
                        title = `${this.context.matter.otherPartyTitle.toLowerCase()} solicitor (not a Unity User)`;
                    }
                    this.addSolicitorToRecipients(item, title, 'OTHER_PARTY_SOLICITOR',extraName);
                }

            });
        }
    }

    addMortgageSolicitorToRecipients() {
        if (Array.isArray(this.solicitorMPList) && this.solicitorMPList.length > 0) {
            this.solicitorMPList.forEach(item => {
                let title: string;
                if (item.matterParticipantRole == MatterParticipantRoleTypes.MORTGAGE_SOLICITOR) {

                    title = 'Solicitor';
                    const mortgage: Mortgage = this.context.matter.mortgages.find(mortgage => mortgage.id == item.mortgageId);
                    if (mortgage) {
                        title = this.getMortgageTitle(mortgage) + title;

                    }
                    this.addSolicitorToRecipients(item, title,'MORTGAGE_SOLICITOR');
                }
            });
        }
    }

    get otherPartySolicitorContact(): Contact {
        if (this.context && this.context.matter && this.context.matter.otherPartySolicitor) {
            return this.solicitorSourceContactList.find((item) => item.id === this.context.matter.otherPartySolicitor.contact.sourceContactId);
        }
        return null;
    }

    get otherPartyLawFirmName(): string {
        if (this.context.matter.otherPartyLawFirm && this.context.matter.otherPartyLawFirm.contact) {
            return this.context.matter.otherPartyLawFirm.contact.displayName;
        }
        return '';
    }

    async getOtherPartyLawClerk(): Promise<void> {
        if (this.context.matter.otherPartyContactInfo) {
            if (this.context.matter.otherPartyContactInfo.lawClerkId) {
                this.otherPartyLawClerkContact = await (this.contactQueryService.getOtherSideLawClerkById(this.context.matter.otherPartyContactInfo.lawClerkId)).toPromise();
            } else {
                this.otherPartyLawClerkContact = new Contact();
                this.otherPartyLawClerkContact.email = this.context.matter.otherPartyContactInfo.lawClerkEmail;
                this.otherPartyLawClerkContact.contactName = new ContactName();
                this.otherPartyLawClerkContact.contactName.lastName = this.context.matter.otherPartyContactInfo.lawClerkName;
            }
        }
    }

    checkMissingEmail() {
        if(this.selectedRecipient && this.isEmailMissing){
            this.errorService.addDpSaveError(DPError.createDPError('share.document.assignment.missing.email'));
        }
    }


async share(): Promise<void> {
        this.modalErrorComponent.removeAllDpSaveError();
        this.checkMissingEmail();
        if(this.errorService.hasErrors()){
            return;
        }
        if (!this.selectedRecipientContact || DocumentProductionUtil.isMultipleDocumentWithSameNameWithoutExtension(this.context.documentNameList)) {
            if(!this.selectedRecipientContact){
                this.modalErrorComponent.createCustomDPSaveError('matter.documentProduction.sharedocs.receipient', 'Recipient is required.', '', 'ERROR');
            }
            if(DocumentProductionUtil.isMultipleDocumentWithSameNameWithoutExtension(this.context.documentNameList)){
                this.modalErrorComponent.createCustomDPSaveError('matter.documentProduction.sharedocs.duplicateDocuments',
                    'You have selected multiple documents with the same file name - '+DocumentProductionUtil.fetchDuplicateDocumentsWithoutExtensionForSharing(this.context.documentNameList), '', 'ERROR');
            }

        } else {
            if (this.isUnitySolicitorOrLawClerk()) {
                this.initializeSharedDocumentsPackage(this.selectedRecipientContact, 'UNITY');
            }
            // else if(this.selectedConnectRecipientRoleType == 'OTHER_PARTY_LAW_CLERK' && (!this.unitySolicitorsAndLawClerksList  || (this.unitySolicitorsAndLawClerksList && !this.unitySolicitorsAndLawClerksList.find(id => id === this.otherPartyLawClerkContact.sourceContactId)))) {
            //     this.modalErrorComponent.createCustomDPSaveError('matter.documentProduction.sharedocs.otherPartyLawClerk.notActive', 'Selected recipient '+this.selectedRecipientName +' is not an active Unity user', '', 'ERROR');
            // }
            else {
                const contactCanBeUsedForEmail: boolean = await this.validateSelectedContact();
                if (contactCanBeUsedForEmail) {
                    this.initializeSharedDocumentsPackage(this.selectedRecipientContact, 'CONNECT', this.getSelectedPrecedent(), this.selectedRecipientEmailKey);
                }
            }
        }
    }

    async validateSelectedContact(): Promise<boolean> {
        let locked: boolean = false;
        let stale: boolean = false;
        if (this.selectedRecipientContact && this.selectedRecipientContact.sourceContactId) {
            let sourceContact: Contact = await this.contactQueryService.getContactForMatter(this.selectedRecipientContact.sourceContactId).toPromise();
            if(sourceContact){
                if (sourceContact.locked) {
                    this.modalErrorComponent.createCustomDPSaveError('modal.shared.documents.locked.contact', `The recipients contact record is currently locked by ${sourceContact.lockUserName}.`, '', 'ERROR');
                    locked = true;
                }
                if (this.selectedRecipientContact.isStaleContact(sourceContact)) {
                    this.modalErrorComponent.createDPSaveError('modal.shared.documents.stale.contact');
                    stale = true;
                }
            }
        }
        return !(locked || stale);
    }

    initializeSharedDocumentsPackage(contact: Contact, packageType: SharedDocumentsPackageType, selectedPrecedent ? : Precedent, emailKey ? : string) {
        if (contact) {
            let sharedDocumentsPackage = new SharedDocumentsPackage();
            let result = new ShareDocsUserModalResult();
            sharedDocumentsPackage.matterId = this.context.matter.id;
            sharedDocumentsPackage.packageType = packageType;
            // recipientDearName just for temperately save dear information
            //When sending an email, we can use the contact dear information
            //if there is a dear field and it is not empty use the contents of the dear field. Else use their name (firstname middlename lastname).
            sharedDocumentsPackage.recipientDearName = contact.dear ? contact.dear : contact.contactFullNameStartWithFirstName;
            sharedDocumentsPackage.recipientName = this.selectedRecipientName;
            sharedDocumentsPackage.connectRecipientRole = this.selectedRecipientRole;
            sharedDocumentsPackage.connectRecipientRoleType = this.selectedConnectRecipientRoleType;
            if (this.isUnitySolicitorOrLawClerk()) {
                sharedDocumentsPackage.unityRecipientIds.push(contact.sourceContactId);
                if (this.bothOtherPartyLawClerkAndSolicitorAreActiveUnityUsers() && sharedDocumentsPackage.connectRecipientRoleType === 'OTHER_PARTY_SOLICITOR') {
                    sharedDocumentsPackage.unityRecipientIds.push(this.otherPartyLawClerkContact.sourceContactId);
                }
                sharedDocumentsPackage.unitySharingMessageSubject = this.subject;
                sharedDocumentsPackage.unitySharingMessageText = this.message;
                result.sentToUnityUserRecipients = true;
            } else {
                sharedDocumentsPackage.connectRecipientEmail = contact.firstEmail;
                result.sentToConnectRecipientEmail = contact.firstEmail;
                if(selectedPrecedent) {
                    result.selectedPrecedent = selectedPrecedent;
                }
                if(emailKey){
                   result.emailKey = emailKey;
                }
            }
            result.lastRecipient = (this.recipientList.filter(rec => rec.value).length <= 1);
            this.dialog.close({shareDocumentPackage: sharedDocumentsPackage, modalResult: result});
        }
    }

    close(): void {
        this.dialog.close();
    }

    isSubjectVisible(): boolean {
        return this.isUnitySolicitorOrLawClerk();
    }

    isMessageVisible(): boolean {
        return this.isUnitySolicitorOrLawClerk();
    }

    onRecipientChange() {
        if (this.isUnitySolicitorOrLawClerk()) {
            let mp: MatterParticipant = this.solicitorMPList.find(mp => mp.contact.sourceContactId == this.selectedRecipientContact.sourceContactId);
            if (mp && (mp.matterParticipantRole == MatterParticipantRoleTypes.MORTGAGE_SOLICITOR)) {
                this.subject = this.emailFieldService.getMailSubject(EmailKeys.mortgagee);
            } else {
                this.subject = this.emailFieldService.getMailSubject(EmailKeys.vendorsSolicitor);
            }
            this.updateEditorMessage();
        }
        this.modalErrorComponent.removeAllDpSaveError();
    }

    isUnitySolicitorOrLawClerk(): boolean {
        return this.selectedRecipientContact && this.unitySolicitorsAndLawClerksList
            && !!this.unitySolicitorsAndLawClerksList.find(id => id == this.selectedRecipientContact.sourceContactId);
    }

    bothOtherPartyLawClerkAndSolicitorAreActiveUnityUsers(): boolean {
        if (this.otherPartyLawClerkContact && this.otherPartySolicitorContact) {
            return (this.unitySolicitorsAndLawClerksList) &&
                (this.unitySolicitorsAndLawClerksList.some(id => id === this.otherPartyLawClerkContact.sourceContactId)) &&
                (this.unitySolicitorsAndLawClerksList.some(id => id === this.otherPartySolicitorContact.sourceContactId));
        }
        return false;
    }


    get isOtherPartyLawClerkAUnityUser(): boolean {
        if (this.otherPartyLawClerkContact) {
            if (this.unitySolicitorsAndLawClerksList && this.unitySolicitorsAndLawClerksList.find(id => id === this.otherPartyLawClerkContact.sourceContactId)) {
                return true;
            }
        }
        return false;
    }

    get isOtherPartySolicitorAUnityUser(): boolean {
        if (this.otherPartySolicitorContact) {
            if (this.unitySolicitorsAndLawClerksList && this.unitySolicitorsAndLawClerksList.find(id => id === this.otherPartySolicitorContact.sourceContactId)) {
                return true;
            }
        }
        return false;
    }

    ngOnDestroy() {
    }

    refreshRecipientList = () => {
        console.log('refreshRecipientList');
        //If we don't refresh recipientList, the drop down list won't be showed up
        this.recipientList = this.recipientList.slice();
    };

    dataSelectedRecipient(): void {
        this.errorService.removeAllDpFieldError();
        if (this.selectedRecipient) {
            this.selectedRecipientContact = this.selectedRecipient.value;
            this.selectedRecipientName = this.selectedRecipient.label && this.selectedRecipient.label.contactName;
            this.selectedRecipientRole = this.selectedRecipient.label && this.selectedRecipient.label.typeName;
            this.selectedConnectRecipientRoleType = this.selectedRecipient.connectRecipientRoleType;
            this.selectedRecipientEmailKey = this.selectedRecipient.emailKey;
            this.isEmailMissing = this.selectedRecipient.missingEmail;
            this.onRecipientChange();
            setTimeout(() => {
                this.selectedRecipient = this.selectedRecipient.label
                    ? this.getRecipientName(this.selectedRecipient.label.contactName, this.selectedRecipient.label.typeName)
                    : '';
            }, 0);
        }
    }

    /**
     *  Click Space key, it alway show dropdown list no matter if the ngModel of p-autoComplete has value
     *  Select an item, click enter key p-autoComplete select this item automatically
     *  Use p-autoComplete to simulate a normal selection
     * @param event
     */
    displayRecipients(event: any) {
        event.stopImmediatePropagation();
        this.refreshRecipientList();
        this.recipientAutoComplete.show();
    }

    addChildMatterParticipants(parentMatterParticipant: MatterParticipant, connectRecipientRoleType : ConnectRecipientRoleType, emailKey?: string) : void {
        if(parentMatterParticipant){
            let childMatterParticipants = this.context.matter.getChildSigners(parentMatterParticipant);
            if(childMatterParticipants && childMatterParticipants.length){
                childMatterParticipants.forEach((mp)=>{
                    if(mp.contact && mp.contact.firstEmail ) {
                        let label: Label = this.getAdditionalRecipientLabel(this.getRecipientContactName(mp),matterParticipantRoleLabels[mp.matterParticipantRole],  this.getRecipientContactName(parentMatterParticipant));
                        this.addToRecipientList(label, mp.contact, connectRecipientRoleType, emailKey);
                    }
                });
            }
        }

    }

    getAdditionalRecipientLabel(contactName: string, roleLabel: string, parentName: string): Label {
        return {contactName: contactName, typeName: parentName + '-' + roleLabel};
    }
}
